import React from 'react';

import { map } from 'lodash';
import styled from 'styled-components';

import { Button } from '@component/button/Button';
import { DropdownMenu } from '@component/menu/DropdownMenu';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';

const Container = styled.div`
  width: 300px;
`;

const MenuItemContainer = styled(({ isSmallPadding, ...rest }) => <Button {...rest} />)`
  display: flex;
  height: 50px;
  align-items: center;
  && {
    padding: ${({ isSmallPadding }) => (isSmallPadding ? '0px 14px' : '0px 30px')};
    justify-content: flex-start;
  }
`;

const MenuOptionTitle = styled(({ color, ...rest }) => <span {...rest} />)`
  font-size: ${FontSize.Content}px;
  color: ${({ color }: { color?: string }) => color ?? Color.GRAY_DARK};
  margin-left: 28px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export interface Option {
  title: string;
  icon: React.ReactElement;
  color?: string;
  action: () => void;
  testId?: string;
}

interface ActionsMenuProps {
  anchor: HTMLElement | undefined;
  onClose: () => void;
  options: Option[];
}

export const ActionsMenu: React.FC<ActionsMenuProps> = (props) => {
  const actionWrapper = (action: () => void) => () => {
    props.onClose();
    return action();
  };
  return (
    <DropdownMenu anchor={props.anchor} onClose={props.onClose}>
      <Container id="actions_menu">
        <Options>
          {map(props.options, (option) => (
            <MenuOptionWithIcon {...option} action={actionWrapper(option.action)} key={option.title} />
          ))}
        </Options>
      </Container>
    </DropdownMenu>
  );
};

export const MenuOptionWithIcon: React.FC<
  Option & {
    isSmallPadding?: boolean;
  }
> = (props) => (
  <MenuItemContainer id="option" onClick={props.action} isSmallPadding={!!props.isSmallPadding}>
    {props.icon}
    <MenuOptionTitle id="name" color={props.color}>
      {props.title}
    </MenuOptionTitle>
  </MenuItemContainer>
);

import React, { useEffect, useState } from 'react';

import { forEach } from 'lodash';
import { useDispatch } from 'react-redux';

import { getFileExtension, trimFileExtension } from '@common/helper';
import { ConversationAttachment } from '@common/model';
import { fetchConversationDocumentFile } from '@common/redux/epic/DocumentsEpic';
import { Text } from '@component/text';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';

import {
  AttachmentLinkButton,
  AttachmentsContainerTitle,
  AttachmentsSeparator,
  AttachmentsWrapper,
  MessageBubbleDivider,
} from './ConversationStyles';

interface AttachmentsProps {
  isFromCurrentUser: boolean;
  setDocument?: React.Dispatch<React.SetStateAction<ConversationAttachmentObject>>;
  attachments?: ConversationAttachment[];
  conversationId: string;
  messageId?: string;
  hasMessage: boolean;
}

export interface ConversationAttachmentObject {
  document: any;
  file: string;
  name: string;
  docId: string;
  messageId: string;
  conversationId: string;
  extension: string;
}

export const ConversationAttachments: React.FC<AttachmentsProps> = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [docName, setDocName] = useState('');
  const [docId, setDocId] = useState('');

  const documentFileData = useSelector((state) => state.documents.viewDocument.documentFileData);

  useEffect(() => {
    if (isDownloading) {
      if (documentFileData && documentFileData?.id === docId) {
        if (props.setDocument) {
          props.setDocument({
            document: documentFileData,
            file: documentFileData.file,
            name: docName,
            docId: docId,
            messageId: props.messageId ?? '',
            conversationId: props.conversationId,
            extension: documentFileData.extension,
          });
        }
        setIsDownloading(false);
      }
    }
  }, [isDownloading, docId, docName, documentFileData]);
  const dispatch = useDispatch();

  const viewAttachment = (id: string, name: string, messageId: string) => {
    setDocName(name);
    setDocId(id);

    const extension = getFileExtension(name);

    if (props.setDocument) {
      props.setDocument({
        document: documentFileData,
        file: '',
        name: '',
        docId: '',
        messageId: props.messageId ?? '',
        conversationId: props.conversationId,
        extension: extension,
      });
    }

    dispatch(fetchConversationDocumentFile(props.conversationId, messageId, id, extension));
    setIsDownloading(true);
  };

  const truncateAttachmentName = (name: string) => {
    return name.length > 24 ? `${name.substring(0, 21)}...` : name;
  };
  const displayListOfAttachments = () => {
    const content: JSX.Element[] = [];

    let attachmentLink: JSX.Element;
    if (props.attachments)
      forEach(props.attachments, (attachment) => {
        attachmentLink = (
          <>
            <Text style={{ color: Color.ORANGE_MAIN, marginBottom: '6px' }} id="attachment">
              <AttachmentLinkButton
                onClick={() => {
                  if (attachment.id && attachment.name && props.messageId) {
                    viewAttachment(attachment.id, attachment.name, props.messageId);
                  }
                }}
                id="attachment_link"
              >
                {attachment.name ? truncateAttachmentName(trimFileExtension(attachment.name)) : ''}
              </AttachmentLinkButton>
            </Text>
            {props.hasMessage ? <AttachmentsSeparator /> : null}
          </>
        );

        content.push(attachmentLink);
      });

    return content;
  };
  return (
    <>
      {props.hasMessage ? <MessageBubbleDivider /> : null}
      <AttachmentsWrapper
        id="message_attachments"
        isFromCurrentUser={props.isFromCurrentUser}
        hasText={props.hasMessage}
      >
        <AttachmentsContainerTitle>
          <Text
            style={{
              alignSelf: 'center',
              color: Color.LIGHT_BLACK,
              fontSize: '12px',
            }}
            id="attachments_title"
          >
            {t(T.common_conversations_attachmentsDialog_title)}
          </Text>
        </AttachmentsContainerTitle>
        {displayListOfAttachments()}
      </AttachmentsWrapper>
    </>
  );
};

import React, { useEffect } from 'react';

import { map } from 'lodash';
import { useLocation } from 'react-router-dom';

import { Conversation } from '@common/model/Conversation';
import { usePushPanel } from '@component/panel/PanelHooks';
import { InfiniteScrollView } from '@component/scrollView';

import { ConversationCard } from './Conversation';
import { LoadConversationsOriginUI } from './LoadConversations';

interface Props {
  scrollRef: React.RefObject<HTMLInputElement>;
  conversationId: string | undefined;
  conversations: Conversation[];
  isLoading: boolean;
  isLastPage?: boolean;
  loadMore?: () => void;
  originUI: LoadConversationsOriginUI;
}

export const ConversationList: React.FC<Props> = (props) => {
  const pushPanel = usePushPanel('/chat/');

  const location = useLocation();

  const hasMore = props.isLastPage !== undefined ? !props.isLastPage : false;

  useEffect(() => {
    if (
      props.originUI !== LoadConversationsOriginUI.MessagingWidget &&
      !props.isLoading &&
      props.conversations.length === 1 &&
      !location.pathname.includes('chat')
    ) {
      pushPanel(props.conversations[0].conversationId);
    }
  }, []);

  const loadMore = () => {
    if (props.loadMore && hasMore && !props.isLoading) {
      props.loadMore();
    }
  };

  return (
    <InfiniteScrollView
      id="conversation_list"
      loadMore={loadMore}
      hasMore={hasMore}
      rootRef={props.scrollRef}
      isLoading={props.isLoading}
    >
      {map(props.conversations, (conversation) => (
        <ConversationCard
          key={conversation.conversationId}
          numberOfNewMessages={conversation.unreadMessagesCount}
          isSelected={props.conversationId === conversation.conversationId}
          onClick={() => {
            if (props.conversationId !== conversation.conversationId) {
              pushPanel(conversation.conversationId);
            }
          }}
          conversation={conversation}
        />
      ))}
    </InfiniteScrollView>
  );
};

export const stateSpecific = {
  AB: {
    name: 'Alberta',
  },
  AK: {
    name: 'Alaska',
  },
  AL: {
    name: 'Alabama',
  },
  AR: {
    name: 'Arkansas',
  },
  AZ: {
    name: 'Arizona',
  },
  BC: {
    name: 'British Columbia',
  },
  CA: {
    name: 'California',
  },
  CO: {
    name: 'Colorado',
  },
  CT: {
    name: 'Connecticut',
  },
  DC: {
    name: 'District of Columbia',
  },
  DE: {
    name: 'Delaware',
  },
  FL: {
    name: 'Florida',
  },
  GA: {
    name: 'Georgia',
  },
  HI: {
    name: 'Hawaii',
    hide: 'yes',
  },
  IA: {
    name: 'Iowa',
  },
  ID: {
    name: 'Idaho',
  },
  IL: {
    name: 'Illinois',
  },
  IN: {
    name: 'Indiana',
  },
  KS: {
    name: 'Kansas',
  },
  KY: {
    name: 'Kentucky',
  },
  LA: {
    name: 'Louisiana',
  },
  MA: {
    name: 'Massachusetts',
  },
  MB: {
    name: 'Manitoba',
  },
  MD: {
    name: 'Maryland',
  },
  ME: {
    name: 'Maine',
  },
  MI: {
    name: 'Michigan',
  },
  MN: {
    name: 'Minnesota',
  },
  MO: {
    name: 'Missouri',
  },
  MS: {
    name: 'Mississippi',
  },
  MT: {
    name: 'Montana',
  },
  MX: {
    name: 'Mexico',
    hide: 'yes',
  },
  NB: {
    name: 'New Brunswick',
  },
  NC: {
    name: 'North Carolina',
  },
  ND: {
    name: 'North Dakota',
  },
  NE: {
    name: 'Nebraska',
  },
  NH: {
    name: 'New Hampshire',
  },
  NJ: {
    name: 'New Jersey',
  },
  NL: {
    name: 'Newfoundland and Labrador',
  },
  NM: {
    name: 'New Mexico',
  },
  NS: {
    name: 'Nova Scotia',
  },
  NT: {
    name: 'Northwest Territories',
  },
  NU: {
    name: 'Nunavut',
  },
  NV: {
    name: 'Nevada',
  },
  NY: {
    name: 'New York',
  },
  OH: {
    name: 'Ohio',
  },
  OK: {
    name: 'Oklahoma',
  },
  ON: {
    name: 'Ontario',
  },
  OR: {
    name: 'Oregon',
  },
  PA: {
    name: 'Pennsylvania',
  },
  PE: {
    name: 'Prince Edward Island',
  },
  QC: {
    name: 'Québec',
  },
  RI: {
    name: 'Rhode Island',
  },
  SC: {
    name: 'South Carolina',
  },
  SD: {
    name: 'South Dakota',
  },
  SK: {
    name: 'Saskatchewan',
  },
  TN: {
    name: 'Tennessee',
  },
  TX: {
    name: 'Texas',
  },
  UT: {
    name: 'Utah',
  },
  VA: {
    name: 'Virginia',
  },
  VT: {
    name: 'Vermont',
  },
  WA: {
    name: 'Washington',
  },
  WI: {
    name: 'Wisconsin',
  },
  WV: {
    name: 'West Virginia',
  },
  WY: {
    name: 'Wyoming',
  },
  YT: {
    name: 'Yukon',
  },
};

import React, { CSSProperties } from 'react';

import { Field, FieldType } from '@component/panels/basePanel/loadDetails/profitCalculator/Field';
import { NumberValidationInput } from '@component/textInput';

interface TextInputBoxProps {
  field: Field;
  placeholder: string;
  isDisabled?: boolean;
  onChangeText: (text: string) => void;
  isInFocus: boolean;
  onFocus?: (fieldType: FieldType) => () => void;
  onBlur?: () => void;
  style?: CSSProperties;
}

const TextInputBox: React.FC<TextInputBoxProps> = (props: TextInputBoxProps) => {
  const isFieldValid = props.field.isValid();
  const isRequiredToFill = props.field.isRequiredToFill();

  return (
    <NumberValidationInput
      label={props.placeholder}
      placeholder={props.isInFocus ? props.field.defaultText : props.placeholder}
      onFocus={props.onFocus ? props.onFocus(props.field.type) : undefined}
      onChange={props.onChangeText}
      onBlur={props.onBlur}
      value={props.field.text ?? ''}
      disabled={props.isDisabled}
      error={isFieldValid && !isRequiredToFill ? '' : 'error'}
      style={props.style}
    />
  );
};

export default TextInputBox;

import React, { useRef } from 'react';

import styled from 'styled-components';

import { useMapImage } from '@common/helper';
import { ImageBase64 } from '@component/image';
import { Loader } from '@component/loader';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { t, T } from '@translate';
import icons from '@util/iconsConstants';

const MAP_ASPECT_RATIO = 3;

const MapContainer = styled(({ height, containerRef, ...rest }) => <div ref={containerRef} {...rest} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-sizing: border-box;
  background-color: ${Color.GRAY_LIGHT};
  overflow: hidden;
  height: ${({ height }) => height}px;
`;

const MapImage = styled(ImageBase64)`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const MapLoader = styled(Loader)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
`;

const ErrorMessage = styled.span`
  color: ${Color.GRAY_STONE};
  font-size: ${FontSize.ContentDetail}px;
`;

export const CircleContainerOverlay = styled.div`
  position: absolute;
  width: 100%;
`;
export const CircleContainer = styled.div`
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: ${Color.WHITE};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.27);
  height: 34px;
  width: 34px;
  border-radius: 24px;
  margin-bottom: 17px;
  margin-right: 18px;
  text-align: center;
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
`;

interface Props {
  loadId: string;
  onClick: () => void;
}

export const MapRoutes: React.FC<Props> = (props) => {
  const [mapImage, isLoading] = useMapImage(props.loadId, undefined);
  const containerRef = useRef<HTMLDivElement>(null);

  const containerHeight = (containerRef.current?.clientWidth ?? 0) / MAP_ASPECT_RATIO;

  const content = () => {
    if (isLoading) {
      return <MapLoader />;
    }
    if (mapImage === '') {
      return <ErrorMessage>{t(T.common_errors_mapDataFailed)}</ErrorMessage>;
    }
    return <MapImage onClick={props.onClick} image={mapImage ?? ''} alt="routes map" />;
  };

  return (
    <div style={{ position: 'relative' }}>
      <MapContainer containerRef={containerRef} height={containerHeight}>
        {content()}
      </MapContainer>
      <CircleContainerOverlay>
        <CircleContainer onClick={props.onClick}>
          <img id="icon" style={{ verticalAlign: 'center', padding: 5 }} src={icons.zoom} />
        </CircleContainer>
      </CircleContainerOverlay>
    </div>
  );
};

import styled from 'styled-components';

import { Button, CircularProgress } from '@material-ui/core';

import { ThemeProps, withTheme } from '@style/WithTheme';

export const StyledProgress = withTheme()(styled(CircularProgress)`
  && {
    color: ${(props: ThemeProps) => props.theme.palette.primary.contrastText};
    max-height: 20px;
    max-width: 20px;
  }
`);

export const IconButtonDiv = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ThemedButton = styled(Button)`
  && {
    margin: 0 !important;
    letter-spacing: 0.08929em;
    min-width: 64px;
    min-height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 2px;

    > span {
      white-space: nowrap;
    }

    > span svg {
      margin-right: 8px;
      margin-left: -6px;
    }

    > span div svg {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
`;

export const PrimaryButton = withTheme()(styled(ThemedButton)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.primaryButton.normalBackground};
    border: none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    color: ${(props: ThemeProps) => props.theme.palette.primaryButton.text};
    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.primaryButton.hoverBackground};
    }
    &:active {
      background-color: ${(props: ThemeProps) => props.theme.palette.primaryButton.pressedBackground};
    }
    &:disabled {
      background-color: ${(props: ThemeProps) => props.theme.palette.disabled.main};
      color: ${(props: ThemeProps) => props.theme.palette.disabled.dark};
      box-shadow: none;
    }
  }
`);

export const SecondaryButton = withTheme()(styled(ThemedButton)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.secondaryButton.normalBackground};
    border: solid 1px;
    border-color: ${(props: ThemeProps) => props.theme.palette.secondaryButton.border};
    color: ${(props: ThemeProps) => props.theme.palette.secondaryButton.text};

    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.secondaryButton.hoverBackground};
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.secondaryButton.borderHover};
    }
    &:active {
      background-color: ${(props: ThemeProps) => props.theme.palette.secondaryButton.pressedBackground};
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.secondaryButton.borderPressed};
    }
    &:disabled {
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.disabled.main};
      color: ${(props: ThemeProps) => props.theme.palette.disabled.dark};
    }
  }
`);

export const PrimaryHeaderButton = withTheme()(styled(ThemedButton)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.primaryHeaderButton.normalBackground};
    border: none;
    color: ${(props: ThemeProps) => props.theme.palette.primaryHeaderButton.text};

    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.primaryHeaderButton.hoverBackground};
    }
    &:active {
      background-color: ${(props: ThemeProps) => props.theme.palette.primaryHeaderButton.pressedBackground};
    }
    &:disabled {
      background-color: ${(props: ThemeProps) => props.theme.palette.disabled.main};
      color: ${(props: ThemeProps) => props.theme.palette.disabled.dark};
    }
  }
`);

export const SecondaryHeaderButton = withTheme()(styled(ThemedButton)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.secondaryHeaderButton.normalBackground};
    border: solid 1px;
    border-color: ${(props: ThemeProps) => props.theme.palette.secondaryHeaderButton.border};
    color: ${(props: ThemeProps) => props.theme.palette.secondaryHeaderButton.text};

    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.secondaryHeaderButton.hoverBackground};
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.secondaryHeaderButton.borderHover};
    }
    &:active {
      background-color: ${(props: ThemeProps) => props.theme.palette.secondaryHeaderButton.pressedBackground};
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.secondaryHeaderButton.borderPressed};
    }
    &:disabled {
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.disabled.main};
      color: ${(props: ThemeProps) => props.theme.palette.disabled.dark};
    }
  }
`);
export const UnlockButton = withTheme()(styled(ThemedButton)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.unlockButton.normalBackground};
    border: solid 1px;
    border-color: ${(props: ThemeProps) => props.theme.palette.unlockButton.border};
    color: ${(props: ThemeProps) => props.theme.palette.unlockButton.text};

    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.unlockButton.hoverBackground};
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.unlockButton.borderHover};
    }
    &:active {
      background-color: ${(props: ThemeProps) => props.theme.palette.unlockButton.pressedBackground};
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.unlockButton.borderPressed};
    }
    &:disabled {
      border: solid 1px;
      border-color: ${(props: ThemeProps) => props.theme.palette.disabled.main};
      color: ${(props: ThemeProps) => props.theme.palette.disabled.dark};
    }
  }
`);

export const TextOnlyButton = styled(Button)`
  background: transparent;
  font-weight: bold;
`;

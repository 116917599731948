import { cloneDeep, map } from 'lodash';

import { UserEditDetails, UserSettingsDetails } from '@/model';
import { t, T } from '@translate';

export enum Filter {
  AlertsSound = 'alerts_sound',
  ManagePlan = 'manage_plan',
  Profile = 'profile',
  BusinessProfile = 'business_profile',
  Company = 'company',
  Users = 'users',
  Dispatcher = 'dispatchers',
  BillingSummary = 'billing_summary',
  PaymentHistory = 'payment_history',
  PaymentMethod = 'payment_method',
  Notifications = 'notifications',
  MileageAndRouting = 'mileage_and_routing',
  SearchResultsSettings = 'search_results_settings',
  ManageFuelMileage = 'manage_fuel_mileage',
  PrivacyControl = 'privacy_control',
  LoginSessions = 'login_sessions',
  Integrations = 'integrations',
  Integrators = 'integrators',
  TermsOfService = 'terms_of_service',
  PrivacyPolicy = 'privacy_policy',
  OpneSourceLiscence = 'open_source_liscence',
  AddUser = 'add_user',
  EditUser = 'edit_user',
  EditDispatcher = 'edit_dispatcher',
  Type = 'type',
  PrivateLoadBoard = 'private_load_board',
  PrivateLoadBoardAddMember = 'add_members',
  ExportingLoadDetails = 'exporting_load_details',
  Standard = 'standard',
  Premium = 'premium',
  PremiumPlus = 'premium_plus',
  AddOn = 'add_on',
  MyTruck = 'my_truck',
  NewTruck = 'new_truck',
  EditTruck = 'edit_truck',
  EditBusinessProfile = 'edit_business_profile',
  ResetPassword = 'reset_password',
  LoadVisibilityRules = 'load_visibility_rules',
  CreateLoadVisibilityRuleset = 'create_rule_set',
  EditLoadVisibilityRuleset = 'edit_rule_set',
  OnboardedCarriers = 'onboarded_carriers',
  EditOnboardedCarriers = 'edit_carriers',
  ReviewInvalidCarriers = 'review_carriers',
  CostPerDay = 'cost_per_day',
  EditCostPerDay = 'edit_cost_per_day',
  PostingContacts = 'posting_contacts',
  AddContact = 'add_contact',
  EditContact = 'edit_contact',
}
export enum Section {
  Membership = 'membership',
  Account = 'account',
  Billing = 'billing',
  Preference = 'preference',
  Permissions = 'permissions',
  Legal = 'legal',
}

export enum TypeUsers {
  All = 'All',
  Admin = 'Admin',
  User = 'User',
}

export const UserSortingTypes = [
  {
    id: TypeUsers.All,
    key: TypeUsers.All,
    value: TypeUsers.All,
    displayValue: t(T.settings_account_types_all),
  },
  {
    id: TypeUsers.Admin,
    key: TypeUsers.Admin,
    value: TypeUsers.Admin,
    displayValue: t(T.settings_account_types_admin),
  },
  {
    id: TypeUsers.User,
    key: TypeUsers.User,
    value: TypeUsers.User,
    displayValue: t(T.settings_account_types_user),
  },
];

export const editUsers = (newUser: UserEditDetails, users: UserSettingsDetails[]) => {
  const updatedUsers = cloneDeep(users);
  map(users, (user, index) => {
    if (user.id === newUser.id) {
      updatedUsers[index].firstName = newUser.firstName;
      updatedUsers[index].lastName = newUser.lastName;
      updatedUsers[index].email = newUser.email;
      updatedUsers[index].phone = newUser.phone;
      updatedUsers[index].isAdmin = newUser.isAdmin;
    }
  });

  return updatedUsers;
};

import React from 'react';

import { displayCurrencyOrDash } from '@common/formatter';
import { getPricePerMileRate } from '@common/helper';
import { LOADBOARD_HELP_RATE_CHECK } from '@common/info';
import { Load, RateCheck } from '@common/model';
import { formatDollarsPerMile } from '@common/redux/epic/rateCheck/RateCheckHelper';
import { RateCheckPostedRateDifference } from '@component/panels/basePanel/loadDetails/marketRates/RateCheckPostedRateDifference';
import {
  AlignBaselineRow,
  ContentDetailText,
  FullWidthColumn,
  InfoIcon,
  LoadDetailsContainer,
  ModalTitleBold,
  RateCheckDataInfo,
  RateCheckDataValue,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { RateConfirmation } from '@component/rateCheck/RateConfirmation';
import { MinMaxBox } from '@page/rateCheck/MinMaxBox';
import { useRateCheckMinMax } from '@page/rateCheck/RateCheckHelper';
import { RateCheckHistoryWithSize } from '@page/rateCheck/RateCheckHistory';
import { Color } from '@style/Color';
import { BorderRadius } from '@style/StyleConstants';
import { t, T } from '@translate';
import icons from '@util/iconsConstants';

interface ComponentProps {
  loadDetails: Load | undefined;
  rateCheck: RateCheck;
  loadID: string;
  isOpened: boolean;
}

export const RateCheckHeader: React.FC<ComponentProps> = (props) => {
  const { openingPopup, closingPopup } = usePopup();

  const minMax = useRateCheckMinMax(props.rateCheck);

  const handleInfoClick = () =>
    openingPopup({
      body: (
        <BasePopup
          title={t(T.common_marketRate_rateCheck_title)}
          buttons={[
            <PopupPrimaryButton
              testID={t(T.common_membership_learnMore)}
              key={t(T.common_membership_learnMore)}
              label={t(T.common_membership_learnMore)}
              action={() => window.open(LOADBOARD_HELP_RATE_CHECK, '_blank')}
            />,
            <PopupOkButton key={t(T.common_ok)} testID={t(T.common_ok)} action={closingPopup} />,
          ]}
        >
          {t(T.common_infoModal_rateCheck_subtitle)}
        </BasePopup>
      ),
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });

  let postedRate: string | undefined;
  let rateDiff: number | undefined;
  let numericPostedRate: number | undefined;
  const { loadDetails, rateCheck } = props;
  if (loadDetails) {
    postedRate = displayCurrencyOrDash(getPricePerMileRate(loadDetails), true, {
      suffix: t(T.common_per_miles_short),
    });
    numericPostedRate = getPricePerMileRate(loadDetails);
    if (rateCheck && (numericPostedRate !== undefined || rateCheck.avgRatePerMile !== undefined)) {
      rateDiff = (numericPostedRate ?? 0) - (rateCheck.avgRatePerMile ?? 0);
    }
  }
  return (
    <LoadDetailsContainer>
      <FullWidthColumn>
        <AlignBaselineRow>
          <RateCheckDataValue>
            <ModalTitleBold id="avg_rate_value">{formatDollarsPerMile(props.rateCheck.avgRatePerMile)}</ModalTitleBold>
          </RateCheckDataValue>
          <RateCheckDataInfo>
            <ContentDetailText id="avg_rate_label">{t(T.findLoads_findLoads_averageRateByCheck)}</ContentDetailText>
            <InfoIcon
              id="info"
              src={icons.info_small}
              alt="info"
              onClick={handleInfoClick}
              style={{ margin: '0 0 auto 5px' }}
            />
          </RateCheckDataInfo>
        </AlignBaselineRow>
        <RateCheckPostedRateDifference
          numericPostedRate={numericPostedRate}
          postedRate={postedRate}
          rateDiff={rateDiff}
        />
        <RateConfirmation
          loadID={props.loadID}
          rateCheck={props.rateCheck}
          style={{ borderRadius: BorderRadius.Button, borderColor: Color.GRAY_SMOKE }}
        >
          <MinMaxBox
            average={minMax.average}
            max={minMax.max}
            min={minMax.min}
            style={{ backgroundColor: Color.WHITE, borderRadius: BorderRadius.Button, borderColor: Color.GRAY_SMOKE }}
            isSmall={false}
          />
        </RateConfirmation>
        {props.isOpened ? (
          <RateCheckHistoryWithSize
            id={props.loadID}
            loadID={props.loadID}
            isSquare={true}
            rateCheck={props.rateCheck}
          />
        ) : null}
      </FullWidthColumn>
    </LoadDetailsContainer>
  );
};

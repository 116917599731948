import React, { useState } from 'react';

import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components';

import { Backdrop } from '@component/backdrop/Backdrop';
import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { Promotion } from '@page/dashboard/Promotion';
import { Color } from '@style/Color';

import { PanelTrackingProvider } from './WindowScrollViewTrackingContext';

const DesktopPageWithPanels = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${Color.GRAY_SMOKE};
`;

/** Defines a page meant to include one or more 'panels'
 */
export const PageWithPanels: React.FC<{ showPromotion?: boolean }> = ({ showPromotion = true, children }) => {
  const isWindowScrollMode = useIsWindowScrollMode();
  const [totalWidth, setTotalWidth] = useState(isWindowScrollMode ? window.innerWidth : 0);

  return (
    <PanelTrackingProvider availableWidth={totalWidth}>
      {isWindowScrollMode ? (
        <>{children}</>
      ) : (
        <DesktopPageWithPanels>
          <ReactResizeDetector
            handleWidth={true}
            refreshMode={'throttle'}
            refreshRate={350}
            onResize={(width) => setTotalWidth(width)}
          />
          {children}
          {showPromotion ? <Promotion availableWidth={totalWidth} /> : null}
          <Backdrop />
        </DesktopPageWithPanels>
      )}
    </PanelTrackingProvider>
  );
};

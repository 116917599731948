import React from 'react';

import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import { UnlockLink } from '@component/panels/basePanel/loadDetails/generalInfo//UnlockLink';
import { DetailRow } from '@component/panels/basePanel/loadDetails/generalInfo/LoadDetailsInfoBar';
import { T, t } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';
import { useSelector } from '@util/hooks';

interface DrivingTimeProps {
  drivingTime: string;
}

export const DrivingTimeInfo: React.FC<DrivingTimeProps> = (props) => {
  const isMapTollsAvailable = useSelector((state) => state.user.accesses.isMapTollsAvailable);

  const value = isMapTollsAvailable ? (
    props.drivingTime || NO_INFORMATION_SYMBOL
  ) : (
    <UnlockLink popupType={UnlockFeaturePopupType.DrivingTime} />
  );

  return <DetailRow id="drivingTime" title={t(T.postLoads_DrivingTime)} value={value} />;
};

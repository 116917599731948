import { BaseClient } from '@common/client/BaseClient';
import { addArchivingHeader } from '@common/helper/FlowIDHeaderHelper';
import {
  CreateUserTripRequest,
  Segments,
  TripLoadsDetails,
  TripRouteDetails,
  TripTypeCategory,
  UserTripResponse,
} from '@common/model/TripBuilder';
import { MapRequest, MapResponse, TripLoads } from '@common/redux/epic/TripBuilderEpic';

export class TripBuilderClient extends BaseClient {
  createNewTrip$ = (request: CreateUserTripRequest) =>
    this.api.post$<UserTripResponse>(`/loadplanner/v1/carrier/trips`, request);
  fetchUserTrips$ = () => this.api.get$<{ trips: UserTripResponse[] }>(`/loadplanner/v1/carrier/trips`, []);
  deleteUserTrip$ = (id: string) => this.api.delete$<undefined>(`/loadplanner/v1/carrier/trips/${id}`, {});
  fetchSegments$ = (id: string) => this.api.get$<Segments>(`loadplanner/v1/carrier/trips/${id}/segments`, {});
  fetchLoads$ = (request: { id: string; type?: TripTypeCategory }) =>
    this.api.get$<TripLoads>(
      `/loadplanner/v1/carrier/trips/${request.id}/search`,
      {
        type: request.type,
      },
      { timeout: 90000 }
    );
  fetchTripLoadsDetails = (request: { id: string; loadIds: string[] }) =>
    this.api.post$<TripLoadsDetails>(`/loadplanner/v1/carrier/trips/${request.id}/legs`, { loadIds: request.loadIds });
  fetchRouteLoadDetails$ = (request: { id: string; loadIds: string[] }) =>
    this.api.post$<TripRouteDetails>(`/loadplanner/v1/carrier/trips/${request.id}/route`, { loadIds: request.loadIds });
  fetchMap$ = (mapRequest: MapRequest) =>
    this.api.post$<MapResponse>(
      `/loads/routemap`,
      {
        originAddress: mapRequest.originAddress,
        loadIds: mapRequest.loadIds,
        destinationAddress: mapRequest.destinationAddress,
      },
      { params: { thumbnail: mapRequest.thumbnail, aspectRatio: mapRequest.aspectRatio }, responseType: 'arraybuffer' }
    );
  fetchMiniMap$ = (mapRequest: MapRequest) =>
    this.api.post$<MapResponse>(
      `/loads/routemap`,
      {
        originAddress: mapRequest.originAddress,
        loadIds: mapRequest.loadIds,
        destinationAddress: mapRequest.destinationAddress,
      },
      { params: { thumbnail: mapRequest.thumbnail, aspectRatio: mapRequest.aspectRatio }, responseType: 'arraybuffer' }
    );
  addLoad$ = (request: { id: string; loadId: string }) =>
    this.api.post$<UserTripResponse>(`/loadplanner/v1/carrier/trips/${request.id}/loads`, { id: request.loadId });
  removeLoad$ = (request: { id: string; loadId: string }) =>
    this.api.delete$<UserTripResponse>(`loadplanner/v1/carrier/trips/${request.id}/loads/${request.loadId}`, {});
  removeLoads$ = (request: { id: string; loadIds: string[] }) =>
    this.api.post$<UserTripResponse>(`loadplanner/v1/carrier/trips/${request.id}/loads/delete`, {
      loadIds: request.loadIds,
    });
  hideLoad$ = (request: { loadId: string; id: string }) =>
    this.api.mergePatch$<{}>(`/loads/${request.loadId}/userdata`, { isHidden: true }, addArchivingHeader(undefined));
}

import { clone, forEach, map, values } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$, Observable } from 'rxjs';

import { ApiResponse123 } from '@common/api';
import { compareIds } from '@common/helper';
import {
  createDefaultDocumentsRequest,
  DateRange,
  Document,
  DOCUMENT_TYPE_ALL,
  DocumentFilters,
  DOCUMENTS_LIST_REQUEST_LIMIT_DEFAULT,
  DocumentsSortingOption,
  DocumentType,
  FolderIdentifier,
  SortDirection,
} from '@common/model';
import { createAction } from '@common/redux/Base';
import {
  createActionKey,
  createPaginatedListReducer,
  PaginatedListState,
} from '@common/redux/epic/PaginatedListReducer';
import { createMergedReducer } from '@common/redux/ReduxHelper';
import { t, T } from '@translate';

export const DOCUMENTS_STORE_KEY = 'documents';

export interface TypeDataCount {
  type?: string;
  count?: number;
}

export interface PeriodDataCount {
  year?: number;
  month?: number;
  count?: number;
}

/**
 * Defines each unique usage of a Documents List in the app.
 * Each unique usage must be used at most once at a time
 * in a navigation stack to avoid conflicts in redux
 * state (i.e. bugs)
 */
// For example, in Load Search, the user may have DEFAULT
// and LOADSEARCH_BACKHAULS in the navigation stack at the
// same time.
// In Messages, they may have STANDALONE_LOADDETAILS and
// LOADSEARCH_BACKHAULS, or LOADSEARCH_BACKHAULS and
// CONVERSATION_ATTACHMENTS, etc
// In More, DEFAULT and CONVERSATION_ATTACHMENTS, etc.
// and so on.
export enum DocumentsContext {
  DEFAULT = 'default',
  LOADSEARCH_BACKHAULS = 'loadsearchBackhauls',
  STANDALONE_LOADDETAILS = 'loadDetailsStandalone',
  REQUEST_BUILDER = 'requestBuilder',
  CONVERSATION_ATTACHMENTS = 'conversationAttachments',
  COMPANY_SEARCH = 'companySearch',
}

export interface DocumentsMetadata {
  totalResultCount: number;
  queryTime: number;
  typeDataCounts?: TypeDataCount[];
  periodDataCounts?: PeriodDataCount[];
}

export interface DocumentsResponse {
  documents?: Document[];
  metadata?: DocumentsMetadata;
}

interface DocumentListState extends PaginatedListState<Document, DocumentFilters> {
  entries: Document[]; // overwrite PaginatedListState
}

export type DocumentListsState = Record<DocumentsContext, DocumentListState>;

export const createDefaultDocumentFilters = (): DocumentFilters => ({
  offset: 0,
  limit: DOCUMENTS_LIST_REQUEST_LIMIT_DEFAULT,
  sorting: DocumentsSortingOption.date,
  date: undefined,
  documentType: { enumName: DOCUMENT_TYPE_ALL, description: t(T.common_all) },
  sortDirection: SortDirection.Descending,
});

const createDefaultDocumentListState = (): DocumentListState => ({
  isLoading: false,
  fetchRequest: createDefaultDocumentFilters(),
  entries: [],
  isRefreshing: false,
  isLoadingMore: false,
  didLoadingFail: false,
  isLastResult: false,
});

export const createDefaultDocumentListsState = (): DocumentListsState => ({
  [DocumentsContext.DEFAULT]: createDefaultDocumentListState(),
  [DocumentsContext.LOADSEARCH_BACKHAULS]: createDefaultDocumentListState(),
  [DocumentsContext.STANDALONE_LOADDETAILS]: createDefaultDocumentListState(),
  [DocumentsContext.REQUEST_BUILDER]: createDefaultDocumentListState(),
  [DocumentsContext.CONVERSATION_ATTACHMENTS]: createDefaultDocumentListState(),
  [DocumentsContext.COMPANY_SEARCH]: createDefaultDocumentListState(),
});

interface DocumentsListsStoreState {
  [DOCUMENTS_STORE_KEY]: {
    documentLists: DocumentListsState;
  };
}

enum DocumentListReducerAction {
  setFetchRequest = 'setFetchRequest',
  clearDocuments = 'clearDocuments',
  updateSortingOption = 'updateSortingOption',
  updateFolderFilter = 'updateFolderFilter',
  updateDateFilter = 'updateDateFilter',
  updateTypeFilter = 'updateTypeFilter',
  toggleSortOrder = 'toggleSortOrder',
  resetDocumentFilters = 'resetDocumentFilters',
  fetchEntriesResponse = 'fetchEntriesResponse',
  fetchDocuments = 'fetchDocuments',
  fetchMoreDocuments = 'fetchMoreDocuments',
}

// ensures typing is recognized by typescript
const createDocumentListActionLookup = <T extends { [key in DocumentListReducerAction]: any }>(factory: T) => {
  return factory;
};

// ensures typing is recognized by typescript
const createDocumentListLookup = <T extends { [key in DocumentsContext]: any }>(factory: T) => {
  return factory;
};

/**
 * Document LIST Reducer
 *
 * Creates actions, reducer, and epic for a Document List State: a redux state to hold
 * a paginated list of documents
 * @param reducerKey unique key to (among other things) distinguish actions of this
 * document list from other document lists
 */
const createDocumentListStateReducer = (reducerKey: string) => {
  const paginationReducer = createPaginatedListReducer<
    Document,
    DocumentFilters,
    DocumentsResponse,
    DocumentsListsStoreState,
    DocumentListState
  >({
    reducerKey: reducerKey,
    getPaginatedListStateFromStoreState: (store) =>
      store.documents.documentLists[reducerKey as keyof DocumentListsState],
    getEntriesFromResponse: (payload: { documents: Document[] }) => payload.documents,
    areEntriesEqual: compareIds,
    defaultEntries: [],
  });

  const ACTION_KEY = createActionKey(reducerKey);

  const setFetchRequestAction = createAction<DocumentFilters>(`${ACTION_KEY}SET_FETCH_REQUEST`);
  const clearDocumentsAction = createAction(`${ACTION_KEY}SET_FETCH_REQUEST`);
  const updateSortingOptionAction = createAction<DocumentsSortingOption>(`${ACTION_KEY}UPDATE_SORTING_OPTION`);
  const updateFolderFilterAction = createAction<FolderIdentifier | undefined>(`${ACTION_KEY}UPDATE_FOLDER_FILTER`);
  const updateDateFilterAction = createAction<DateRange | undefined>(`${ACTION_KEY}UPDATE_DATE_FILTER`);
  const updateTypeFilterAction = createAction<DocumentType>(`${ACTION_KEY}UPDATE_TYPE_FILTER`);
  const toggleSortOrderAction = createAction(`${ACTION_KEY}TOGGLE_SORT_ORDER`);
  const resetDocumentFiltersAction = createAction(`${ACTION_KEY}RESET_DOCUMENT_FILTERS`);

  const { fetchEntries, fetchMoreEntries, fetchEntriesResponse } = paginationReducer.actions;

  const actions = createDocumentListActionLookup({
    [DocumentListReducerAction.setFetchRequest]: setFetchRequestAction.action,
    [DocumentListReducerAction.clearDocuments]: () => clearDocumentsAction.action(undefined),
    [DocumentListReducerAction.updateSortingOption]: updateSortingOptionAction.action,
    [DocumentListReducerAction.updateFolderFilter]: updateFolderFilterAction.action,
    [DocumentListReducerAction.updateDateFilter]: updateDateFilterAction.action,
    [DocumentListReducerAction.updateTypeFilter]: updateTypeFilterAction.action,
    [DocumentListReducerAction.toggleSortOrder]: () => toggleSortOrderAction.action(undefined),
    [DocumentListReducerAction.resetDocumentFilters]: () => resetDocumentFiltersAction.action(undefined),
    [DocumentListReducerAction.fetchEntriesResponse]: fetchEntriesResponse,
    [DocumentListReducerAction.fetchDocuments]: (filters?: DocumentFilters) =>
      fetchEntries(filters ? createDefaultDocumentsRequest(filters) : undefined),
    [DocumentListReducerAction.fetchMoreDocuments]: fetchMoreEntries,
  });

  const documentListStateReducer = createMergedReducer(
    createDefaultDocumentListState(),
    [
      setFetchRequestAction.addCase((state, action) => {
        state.fetchRequest = action.data;
      }),
      clearDocumentsAction.addCase((state) => {
        state.entries = [];
      }),
      updateSortingOptionAction.addCase((state, action) => {
        state.fetchRequest.sorting = action.data;
        state.fetchRequest.offset = 0;
        state.fetchRequest.limit = DOCUMENTS_LIST_REQUEST_LIMIT_DEFAULT;
      }),
      updateFolderFilterAction.addCase((state, action) => {
        state.fetchRequest.folder = action.data;
      }),
      updateDateFilterAction.addCase((state, action) => {
        if (!action.data) {
          state.fetchRequest.date = undefined;
        } else {
          state.fetchRequest.date = action.data;
        }
        state.fetchRequest.offset = 0;
        state.fetchRequest.limit = DOCUMENTS_LIST_REQUEST_LIMIT_DEFAULT;
      }),
      updateTypeFilterAction.addCase((state, action) => {
        state.fetchRequest.documentType = action.data;
        state.fetchRequest.offset = 0;
        state.fetchRequest.limit = DOCUMENTS_LIST_REQUEST_LIMIT_DEFAULT;
      }),
      toggleSortOrderAction.addCase((state) => {
        state.fetchRequest.sortDirection =
          state.fetchRequest.sortDirection === SortDirection.Ascending
            ? SortDirection.Descending
            : SortDirection.Ascending;
      }),
      resetDocumentFiltersAction.addCase((state) => {
        state.fetchRequest = createDefaultDocumentFilters();
      }),
    ],
    paginationReducer.reducer
  );

  return {
    createMergedEpic$: paginationReducer.createMergedEpic$,
    actions: actions,
    reducer: documentListStateReducer,
  };
};

/**
 * Document LISTS Reducer
 *
 * Creates actions, reducer, and epic for a Document Lists State, which holds
 * multiple instances of a DocumentListState in the form:
 *  {
 *    [DocumentList.DEFAULT]: DocumentListState,
 *    ...
 *  }
 */
export const createDocumentListsReducer = () => {
  const documentLists = createDocumentListLookup({
    [DocumentsContext.DEFAULT]: createDocumentListStateReducer(DocumentsContext.DEFAULT),
    [DocumentsContext.LOADSEARCH_BACKHAULS]: createDocumentListStateReducer(DocumentsContext.LOADSEARCH_BACKHAULS),
    [DocumentsContext.STANDALONE_LOADDETAILS]: createDocumentListStateReducer(DocumentsContext.STANDALONE_LOADDETAILS),
    [DocumentsContext.REQUEST_BUILDER]: createDocumentListStateReducer(DocumentsContext.REQUEST_BUILDER),
    [DocumentsContext.CONVERSATION_ATTACHMENTS]: createDocumentListStateReducer(
      DocumentsContext.CONVERSATION_ATTACHMENTS
    ),
    [DocumentsContext.COMPANY_SEARCH]: createDocumentListStateReducer(DocumentsContext.COMPANY_SEARCH),
  });

  /**
   * DocumentList reducer actions grouped by context
   * -----------------------------------------------
   * i.e.
   * {
   *  default: {
   *    setFetchRequest,
   *    ...
   *  },
   *  loadsearchBackhauls: {
   *    setFetchRequest,
   *    ...
   *  },
   *  ...
   * }
   * -----------------------------------------------
   * Useful for class components mapDispatchToProps
   */
  const actionsLookup = createDocumentListLookup({
    [DocumentsContext.DEFAULT]: documentLists.default.actions,
    [DocumentsContext.LOADSEARCH_BACKHAULS]: documentLists.loadsearchBackhauls.actions,
    [DocumentsContext.STANDALONE_LOADDETAILS]: documentLists.loadDetailsStandalone.actions,
    [DocumentsContext.REQUEST_BUILDER]: documentLists.requestBuilder.actions,
    [DocumentsContext.CONVERSATION_ATTACHMENTS]: documentLists.conversationAttachments.actions,
    [DocumentsContext.COMPANY_SEARCH]: documentLists.companySearch.actions,
  });

  /**
   * DocumentList reducer actions mapped to proper context by
   * prefixed context parameter
   * -----------------------------------------------
   * i.e.
   * {
   *  setFetchRequest: (context, ...(setFetchRequest's params)) => SetFetchRequest Action,
   *  clearDocuments: (context, ...(clearDocuments's params)) => ClearDocuments Action,
   *  ...
   * }
   * -----------------------------------------------
   * Useful for functional components
   */
  const actionsMap = createDocumentListActionLookup({
    setFetchRequest: (context: DocumentsContext, ...args: Parameters<typeof actionsLookup.default.setFetchRequest>) =>
      actionsLookup[context].setFetchRequest(...args),
    clearDocuments: (context: DocumentsContext, ...args: Parameters<typeof actionsLookup.default.clearDocuments>) =>
      actionsLookup[context].clearDocuments(...args),
    updateSortingOption: (
      context: DocumentsContext,
      ...args: Parameters<typeof actionsLookup.default.updateSortingOption>
    ) => actionsLookup[context].updateSortingOption(...args),
    updateFolderFilter: (
      context: DocumentsContext,
      ...args: Parameters<typeof actionsLookup.default.updateFolderFilter>
    ) => actionsLookup[context].updateFolderFilter(...args),
    updateDateFilter: (context: DocumentsContext, ...args: Parameters<typeof actionsLookup.default.updateDateFilter>) =>
      actionsLookup[context].updateDateFilter(...args),
    updateTypeFilter: (context: DocumentsContext, ...args: Parameters<typeof actionsLookup.default.updateTypeFilter>) =>
      actionsLookup[context].updateTypeFilter(...args),
    toggleSortOrder: (context: DocumentsContext, ...args: Parameters<typeof actionsLookup.default.toggleSortOrder>) =>
      actionsLookup[context].toggleSortOrder(...args),
    resetDocumentFilters: (
      context: DocumentsContext,
      ...args: Parameters<typeof actionsLookup.default.resetDocumentFilters>
    ) => actionsLookup[context].resetDocumentFilters(...args),
    fetchEntriesResponse: (
      context: DocumentsContext,
      ...args: Parameters<typeof actionsLookup.default.fetchEntriesResponse>
    ) => actionsLookup[context].fetchEntriesResponse(...args),
    fetchDocuments: (context: DocumentsContext, ...args: Parameters<typeof actionsLookup.default.fetchDocuments>) =>
      actionsLookup[context].fetchDocuments(...args),
    fetchMoreDocuments: (
      context: DocumentsContext,
      ...args: Parameters<typeof actionsLookup.default.fetchMoreDocuments>
    ) => actionsLookup[context].fetchMoreDocuments(...args),
  });

  const documentlistsReducer = (state: DocumentListsState, action: Action): DocumentListsState => {
    // If a documentList is modified, return a new state object as well, so that the parent reducer
    // sees there was a state change.
    // If we don't do this, document list actions will not modify the redux state.
    const modifiedState = clone(state);
    let didSomeContextChange = false;
    forEach(values(DocumentsContext), (contextKey) => {
      const modifiedDocumentContextState = documentLists[contextKey].reducer(state[contextKey], action);
      if (modifiedDocumentContextState !== state[contextKey]) {
        modifiedState[contextKey] = modifiedDocumentContextState;
        didSomeContextChange = true;
      }
    });
    if (didSomeContextChange) {
      return modifiedState;
    } else {
      return state;
    }
  };

  /**
   * Reducer
   */
  const documentListsStateReducer = createMergedReducer<DocumentListsState>(
    createDefaultDocumentListsState(),
    [],
    documentlistsReducer
  );

  /**
   * Epic
   */
  const createdMergedEpic$ = (
    fetchEntries$: (request: DocumentFilters) => Observable<ApiResponse123<DocumentsResponse>>,
    action$: ActionsObservable<Action>,
    state$: StateObservable<DocumentsListsStoreState>
  ) => {
    return merge$(...map(documentLists, (list) => list.createMergedEpic$(fetchEntries$, action$, state$)));
  };

  return {
    createMergedEpic$: createdMergedEpic$,
    actions: actionsMap,
    actionsLookup: actionsLookup,
    reducer: documentListsStateReducer,
  };
};

/**
 * Applies specified modifier on each list state in the provided DocumentListsState
 * and returns new DocumentListsState with modified list states.
 */
export const modifyAllDocumentListStates = (
  documentListsState: DocumentListsState,
  modifier: (documentListState: DocumentListState) => DocumentListState
): DocumentListsState => {
  const modifiedDocumentListsState = clone(documentListsState);
  forEach(values(DocumentsContext), (documentContext) => {
    modifiedDocumentListsState[documentContext] = modifier(documentListsState[documentContext]);
  });
  return modifiedDocumentListsState;
};

/**
 * Applies specified Document[] modifier on each list state's document list in the provided
 * DocumentListsState and returns new DocumentListsState with list states having modified document lists.
 */
export const modifyAllDocumentLists = (
  documentListsState: DocumentListsState,
  modifier: (documents: Document[]) => Document[]
): DocumentListsState => {
  const modifiedDocumentListsState = clone(documentListsState);
  forEach(values(DocumentsContext), (documentContext) => {
    modifiedDocumentListsState[documentContext] = {
      ...modifiedDocumentListsState[documentContext],
      entries: modifier(documentListsState[documentContext].entries),
    };
  });
  return modifiedDocumentListsState;
};

/**
 * Executes specified side-effect for each DocumentListState in the provided DocumentListsState
 */
export const forEachDocumentListState = (
  documentListsState: DocumentListsState,
  operator: (documentContext: DocumentsContext, documentListState: DocumentListState) => void
) => {
  forEach(values(DocumentsContext), (documentContext) =>
    operator(documentContext, documentListsState[documentContext])
  );
};

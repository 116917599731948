import React from 'react';

import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import { Panel, PanelHeader, PanelSize } from '@component/panel';
import { usePCMilerPanelBackLocation } from '@component/panel/PanelHooks';
import { PCMilerRoute } from '@component/pcmilerRoute/PCMilerRoute';
import { T, t } from '@translate';

interface ComponentProps {
  layer?: number;
  panelOffset?: number;
}

type Props = ComponentProps & RouteComponentProps<{ loadMap: string; loadID: string }>;

const CommunicationPCMilerMapComponent: React.FC<Props> = (props: Props) => {
  const closeDetailsPanel = usePCMilerPanelBackLocation();
  const history = useHistory();
  const isStandalone = history.location.search === '';
  let panelOffset = 0;
  if (props.panelOffset) {
    panelOffset = props.panelOffset;
  } else if (isStandalone) {
    panelOffset = 2;
  }

  if (!props.match.params.loadMap) {
    return null;
  }

  const closePanel = () => {
    if (closeDetailsPanel) {
      history.push(closeDetailsPanel);
    }
  };
  return (
    <Panel id={'pc_miler_panel'} size={PanelSize.medium} layer={props.layer ? props.layer : 3} offset={panelOffset}>
      <PanelHeader label={t(T.common_pcMiler_title)} hasCloseButton={true} onClose={closePanel} />
      <PCMilerRoute />
    </Panel>
  );
};

export const CommunicationPCMilerMap = withRouter(CommunicationPCMilerMapComponent);

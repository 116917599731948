import { forEach } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$, Observable, of as of$ } from 'rxjs';
import { flatMap as flatMap$, map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { ApiErrorCode } from '@common/api/ApiErrorCode';
import { SettingsClient } from '@common/client/SettingsClient';
import {
  AlertSettings,
  Config,
  EmailVerificationSettings,
  Flag,
  RoutesSettings,
  SettingsChangeFeedResponse,
  SystemSetting,
  SystemSettingResultResponseAction,
  TruckLocatorSettings,
  UpdateSystemSetting,
  UserSettings,
} from '@common/model';
import { createApiAction, createApiActionWithFetchData, Response, ResponseAction } from '@common/redux/Base';
import { simpleApiEpicToAction, standardApiEpic } from '@common/redux/epic/EpicHelper';
import {
  DEFAULT_TL_SETTINGS,
  initialSystemSettingsState,
  isSystemSettingConfig,
  RSResponse,
  SETTINGS_REDUCER_KEY,
  SettingsState,
  TLSettingsResponse,
} from '@common/redux/epic/SettingsStateHelper';
import { createMergedReducer } from '@common/redux/ReduxHelper';
import { parseValueFrom } from '@common/util/parser/ParserUtils';

interface PartialStoreState {
  [SETTINGS_REDUCER_KEY]: SettingsState;
}

const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS';
const FETCH_ALERT_SETTINGS = 'FETCH_ALERT_SETTINGS';
const FETCH_EMAIL_VERIFICATION_SETTINGS = 'FETCH_EMAIL_VERIFICATION_SETTINGS';

const FETCH_ROUTES_SETTINGS = 'FETCH_ROUTES_SETTINGS';
const ROUTES_SETTINGS_FETCHED = 'ROUTES_SETTINGS_FETCHED';
const UPDATE_ROUTES_SETTINGS = 'UPDATE_ROUTES_SETTINGS';
const ROUTES_SETTINGS_UPDATED = 'ROUTES_SETTINGS_UPDATED';

const FETCH_TL_SETTINGS = 'FETCH_TL_SETTINGS';
const TL_SETTINGS_FETCHED = 'TL_SETTINGS_FETCHED';
const UPDATE_TL_SETTINGS = 'UPDATE_TL_SETTINGS';
const USER_SETTINGS_FETCHED = 'USER_SETTINGS_FETCHED';
const ALERT_SETTINGS_FETCHED = 'ALERT_SETTINGS_FETCHED';
const EMAIL_VERIFICATION_SETTINGS_FETCHED = 'EMAIL_VERIFICATION_SETTINGS_FETCHED';
const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';

export type UserSettingsResponse = Response<UserSettings>;
export type AlertSettingsResponse = Response<AlertSettings>;
export type EmailVerificationSettingsResponse = Response<EmailVerificationSettings>;

interface RoutesSettingsResponse extends Action {
  settings: RSResponse;
}

export interface TruckLocatorResponse extends Action {
  settings: TLSettingsResponse;
}

interface FetchUserSettingsAction extends Action {
  forceRefresh: boolean;
}

interface FetchAlertSettingsAction extends Action {
  forceRefresh: boolean;
}

interface AlertSettingsActionResponse extends Action {
  response: AlertSettingsResponse;
}

interface UpdateSettings<T> extends Action {
  settings: Partial<T>;
}

interface UserSettingsActionResponse extends Action {
  response: UserSettingsResponse;
}

interface EmailVerificationSettingsActionResponse extends Action {
  response: EmailVerificationSettingsResponse;
}

interface UpdateRoutesSettings extends Action {
  settings: RoutesSettings;
}

type UpdateTLSettings = UpdateSettings<TruckLocatorSettings>;
type UpdateWizardSettings = UpdateSettings<UserSettings>;

export const fetchRoutesSettings = (): Action => ({
  type: FETCH_ROUTES_SETTINGS,
});

export const fetchUserSettings = (forceRefresh: boolean = false): FetchUserSettingsAction => ({
  type: FETCH_USER_SETTINGS,
  forceRefresh: forceRefresh,
});

export const fetchAlertSettings = (forceRefresh: boolean = false): FetchAlertSettingsAction => ({
  type: FETCH_ALERT_SETTINGS,
  forceRefresh: forceRefresh,
});

export const fetchEmailVerificationSettings = (): Action => ({
  type: FETCH_EMAIL_VERIFICATION_SETTINGS,
});

export const fetchTruckLocatorSettings = (): Action => ({
  type: FETCH_TL_SETTINGS,
});

export const updateRoutesSettings = (settings: RoutesSettings): UpdateRoutesSettings => ({
  type: UPDATE_ROUTES_SETTINGS,
  settings: settings,
});
export const updateTruckLocatorSettings = (settings: Partial<TruckLocatorSettings>): UpdateTLSettings => ({
  type: UPDATE_TL_SETTINGS,
  settings: settings,
});

export const updateWizardSettings = (settings: Partial<UserSettings>): UpdateWizardSettings => ({
  type: UPDATE_USER_SETTINGS,
  settings: settings,
});

const getSystemSettingAction = createApiActionWithFetchData<SystemSetting, unknown>('GET_SYSTEM_SETTING');
export const getSystemSetting = getSystemSettingAction.fetchAction;
const updateSystemSettingAction = createApiActionWithFetchData<UpdateSystemSetting, {}>('UPDATE_SYSTEM_SETTING');
export const updateSystemSetting = updateSystemSettingAction.fetchAction;
const syncAllSystemSettingsAction = createApiAction<{ shouldUpdateOnly: boolean }, SettingsChangeFeedResponse>(
  'SYNC_ALL_SYSTEM_SETTINGS'
);
export const syncAllSystemSettings = (shouldUpdateOnly: boolean = false) =>
  syncAllSystemSettingsAction.fetchAction({ shouldUpdateOnly: shouldUpdateOnly });

const routesSettingsResponse = (response: RSResponse): RoutesSettingsResponse => ({
  type: ROUTES_SETTINGS_FETCHED,
  settings: response,
});

const tlSettingsResponse = (response: TLSettingsResponse): TruckLocatorResponse => ({
  type: TL_SETTINGS_FETCHED,
  settings: response,
});
const userSettingsResponse = (response: UserSettingsResponse): UserSettingsActionResponse => ({
  type: USER_SETTINGS_FETCHED,
  response: response,
});

const alertSettingsResponse = (response: AlertSettingsResponse): AlertSettingsActionResponse => ({
  type: ALERT_SETTINGS_FETCHED,
  response: response,
});

const emailVerificationSettingsResponse = (
  response: EmailVerificationSettingsResponse
): EmailVerificationSettingsActionResponse => ({
  type: EMAIL_VERIFICATION_SETTINGS_FETCHED,
  response: response,
});

const updateRouteSettings = (state: SettingsState, action: RoutesSettingsResponse) => {
  state.routesSettings = action.settings;
  state.isLoading = false;
};

const isActionPermissionDeniedOrUnverifiedEmail = <T>(action: ResponseAction<T>) => {
  return (
    !action.response.success &&
    action.response.error &&
    (action.response.error.code === ApiErrorCode.EMAIL_NOT_VERIFIED ||
      action.response.error.code === ApiErrorCode.ACTION_PERMISSION_DENIED)
  );
};

const userSettingsFetched = (state: SettingsState, action: UserSettingsActionResponse): SettingsState => {
  if (isActionPermissionDeniedOrUnverifiedEmail(action)) {
    return {
      ...state,
      isEmailVerified: false,
      isLoadingUserSettings: false,
    };
  }
  return {
    ...state,
    userSettings: action.response.payload || {
      hasCompany: false,
      hasTruck: false,
      liveLoadRefreshInterval: 30,
    },
    isEmailVerified: true, //If no pending action is triggered, then email is verified.
    isLoadingUserSettings: false,
  };
};

const emailVerificationSettingsFetched = (
  state: SettingsState,
  action: EmailVerificationSettingsActionResponse
): SettingsState => {
  if (isActionPermissionDeniedOrUnverifiedEmail(action)) {
    return {
      ...state,
      isEmailVerified: false,
      isLoadingEmailVerificationSettings: false,
    };
  }
  return {
    ...state,
    isEmailVerified: action.response.payload?.emailIsValidated,
    isLoadingEmailVerificationSettings: false,
  };
};

const initialState: SettingsState = {
  isLoading: false,
  isLoadingUserSettings: false,
  isLoadingAlertSettings: false,
  isLoadingEmailVerificationSettings: false,
  isLoadingSystemSetting: false,
  systemSetting: initialSystemSettingsState,
  changeFeedToken: undefined,
};

const updateSystemSettingState = (state: SettingsState, setting: SystemSetting, value: unknown) => {
  if (value === undefined || value === null) {
    return;
  }
  // Note: If state.systemSetting[setting] is not defined, we default to the setting's initial state.
  // In practice, it should always be defined, but since this code is crucial, we implemented this
  // failsafe to at least prevent a crash from occurring if something goes very wrong.
  if (isSystemSettingConfig(setting)) {
    const existingSystemConfigState = state.systemSetting[setting] ?? initialSystemSettingsState[setting];
    state.systemSetting = {
      ...state.systemSetting,
      [setting]: {
        ...existingSystemConfigState,
        value: {
          ...existingSystemConfigState.value,
          ...safeSpread(value),
        },
      },
    };
  } else {
    state.systemSetting[setting] = {
      ...(state.systemSetting[setting] ?? initialSystemSettingsState[setting]),
      value: !!value,
    };
  }
};

export const settingsReducer = createMergedReducer<SettingsState>(initialState, [
  getSystemSettingAction.initiateCase((state, action) => {
    state.systemSetting[action.data].isLoading = true;
  }),
  getSystemSettingAction.completeCase((state, action) => {
    if (action.fetchData) {
      const typedAction = action as SystemSettingResultResponseAction;
      state.systemSetting[typedAction.fetchData].isLoading = false;
      if (typedAction.response.success) {
        updateSystemSettingState(state, typedAction.fetchData, typedAction.response.payload?.value);
      }
    }
  }),

  updateSystemSettingAction.initiateCase((state, action) => {
    state.systemSetting[action.data.key].isUpdating = true;
    state.systemSetting[action.data.key].wasUpdated = undefined;
  }),
  updateSystemSettingAction.completeCase((state, action) => {
    if (action.fetchData?.key) {
      state.systemSetting[action.fetchData.key].isUpdating = false;
      state.systemSetting[action.fetchData.key].wasUpdated = action.response.success;
      if (action.response.success) {
        state.systemSetting[action.fetchData.key].value = action.fetchData.value;
      }
    }
  }),
  syncAllSystemSettingsAction.initiateCase((state) => {
    state.isLoadingSystemSetting = true;
  }),
  syncAllSystemSettingsAction.completeCase((state, action) => {
    state.isLoadingSystemSetting = false;
    if (action.response.success) {
      state.changeFeedToken = action.response.payload.changeFeedToken;
      forEach(action.response.payload.items, ({ name, value }) => {
        const systemSetting = parseValueFrom(name, Flag) ?? parseValueFrom(name, Config);
        if (systemSetting) {
          updateSystemSettingState(state, systemSetting, value);
        }
      });
    }
  }),
  {
    [FETCH_ROUTES_SETTINGS]: (state) => {
      state.isLoading = true;
    },
    [UPDATE_ROUTES_SETTINGS]: (state) => {
      state.isLoading = true;
    },
    [ROUTES_SETTINGS_FETCHED]: updateRouteSettings,
    [ROUTES_SETTINGS_UPDATED]: updateRouteSettings,
    [FETCH_TL_SETTINGS]: (state) => {
      state.truckLocatorSettings = undefined;
      state.isLoading = true;
    },
    [TL_SETTINGS_FETCHED]: (state, action: TruckLocatorResponse) => {
      state.truckLocatorSettings = action.settings;
      state.isLoading = false;
    },
    [UPDATE_TL_SETTINGS]: (state) => {
      state.truckLocatorUpdatedSettings = undefined;
      state.isLoading = true;
    },
    [FETCH_USER_SETTINGS]: (state) => {
      state.userSettings = undefined;
      state.isLoadingUserSettings = true;
    },
    [USER_SETTINGS_FETCHED]: userSettingsFetched,
    [UPDATE_USER_SETTINGS]: (state, action: UpdateWizardSettings) => {
      const userSettings: UserSettings = { ...state.userSettings, ...action.settings } as UserSettings;
      state.userSettings = userSettings;
    },
    [FETCH_ALERT_SETTINGS]: (state) => {
      state.alertSettings = undefined;
      state.isLoadingAlertSettings = true;
    },
    [ALERT_SETTINGS_FETCHED]: (state, action: AlertSettingsActionResponse) => {
      state.alertSettings = action.response.payload;
      state.isLoadingAlertSettings = false;
    },
    [FETCH_EMAIL_VERIFICATION_SETTINGS]: (state) => {
      state.isLoadingEmailVerificationSettings = true;
    },
    [EMAIL_VERIFICATION_SETTINGS_FETCHED]: emailVerificationSettingsFetched,
    // TEMPORARY FIX --------------
    // to be removed by MOB-6142
    LOGIN_USER: (state) => {
      state.changeFeedToken = undefined;
      state.systemSetting = initialSystemSettingsState;
    },
    EXTERNAL_LOGIN_USER: (state) => {
      state.changeFeedToken = undefined;
      state.systemSetting = initialSystemSettingsState;
    },
    // -------------- TEMPORARY FIX
  },
]);

const fetchTLSettingsAction$ = (action$: ActionsObservable<Action>, client: SettingsClient) =>
  standardApiEpic<TruckLocatorSettings>(
    action$,
    FETCH_TL_SETTINGS,
    client.fetchTruckLocatorSettings$,
    tlSettingsResponseSuccess,
    () =>
      tlSettingsResponse({
        success: true,
        recordOnServer: false,
        payload: DEFAULT_TL_SETTINGS,
      })
  );

const handleRoutesSettingsResponse = (response: ApiResponse123<RoutesSettings>) => {
  return response.result(
    (data) =>
      routesSettingsResponse({
        success: true,
        payload: data,
      }),
    (error) =>
      routesSettingsResponse({
        success: false,
        error: error,
      })
  );
};
const fetchRoutesSettingsAction$ = (action$: ActionsObservable<Action>, client: SettingsClient) =>
  action$.ofType(FETCH_ROUTES_SETTINGS).pipe(
    mergeMap$(() => {
      return client.fetchRoutesSettings$().pipe(
        map$((response: ApiResponse123<RoutesSettings>) => {
          return handleRoutesSettingsResponse(response);
        })
      );
    })
  );

const updateRoutesSettingsAction$ = (action$: ActionsObservable<Action>, client: SettingsClient) =>
  action$.ofType(UPDATE_ROUTES_SETTINGS).pipe(
    mergeMap$((action: UpdateRoutesSettings) => {
      return client.updateRoutesSettings$(action.settings).pipe(
        map$((response: ApiResponse123<RoutesSettings>) => {
          return handleRoutesSettingsResponse(response);
        })
      );
    })
  );

const updateTLSettingsAction$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialStoreState>,
  client: SettingsClient
) =>
  action$.ofType(UPDATE_TL_SETTINGS).pipe(
    mergeMap$((action: UpdateTLSettings) => {
      const truckLocatorState = state$.value.settings?.truckLocatorSettings;

      const settings = getUpdatedTruckLocatorSettings(truckLocatorState, action.settings);

      const updateTruckLocatorSettings$ = (hasSettings: boolean): Observable<TruckLocatorResponse> =>
        client.updateTruckLocatorSettings$(settings, hasSettings).pipe(
          flatMap$((response) => {
            if (response.success) {
              return of$(tlSettingsResponseSuccess(response as ApiResponseSuccess<TruckLocatorSettings>));
            }
            const error = response as ApiError;
            if (hasSettings && error.httpStatus === 404) {
              return updateTruckLocatorSettings$(false);
            }
            return of$(tlSettingsResponse({ success: false, recordOnServer: false, error: error }));
          })
        );

      return updateTruckLocatorSettings$(true);
    })
  );

const getUpdatedTruckLocatorSettings = (
  truckLocatorState: TLSettingsResponse | undefined,
  settings: Partial<TruckLocatorSettings>
) => {
  const recordOnServer = !!truckLocatorState?.recordOnServer;
  if (recordOnServer) {
    return settings;
  }
  // When (we think) the server contains no settings yet,
  // merge with what we currently have in the client
  // before creating (PUTting) a new record
  // TODO: It's possible that there is already a record that was created in the meantime (from another window or device)
  // in which case we will override the other settings. Need to find a better alternative. [BP]
  // TODO: generalize all settings-related logic so it's easy to re-use for all types of settings.
  const settingsOnClient = truckLocatorState?.payload;
  return { ...settingsOnClient, ...settings };
};

const tlSettingsResponseSuccess = (response: ApiResponseSuccess<TruckLocatorSettings>) =>
  tlSettingsResponse({
    success: true,
    recordOnServer: true,
    payload: { ...DEFAULT_TL_SETTINGS, ...response.data },
  });

const userSettingsEpic$ = (client: SettingsClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction<UserSettings>(
    action$,
    FETCH_USER_SETTINGS,
    (action: FetchUserSettingsAction) => client.fetchUserSettings(action.forceRefresh),
    (response) => userSettingsResponse(response)
  );

const alertSettingsEpic$ = (client: SettingsClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction<AlertSettings>(
    action$,
    FETCH_ALERT_SETTINGS,
    (action: FetchAlertSettingsAction) => client.fetchAlertSettings$(action.forceRefresh),
    (response) => alertSettingsResponse(response)
  );

const emailVerificationSettingsEpic$ = (client: SettingsClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction<EmailVerificationSettings>(
    action$,
    FETCH_EMAIL_VERIFICATION_SETTINGS,
    () => client.fetchEmailVerificationSettings$(),
    (response) => emailVerificationSettingsResponse(response)
  );

export const createSettingsEpic = (api: Api) => {
  const client = new SettingsClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<PartialStoreState>) =>
    merge$(
      fetchTLSettingsAction$(action$, client),
      fetchRoutesSettingsAction$(action$, client),
      updateTLSettingsAction$(action$, state$, client),
      updateRoutesSettingsAction$(action$, client),
      userSettingsEpic$(client, action$),
      alertSettingsEpic$(client, action$),
      emailVerificationSettingsEpic$(client, action$),
      getSystemSettingAction.createEpic$(action$, client.getSettingByName$),
      updateSystemSettingAction.createEpic$(action$, (data) => client.putSettingsByName$(data.key, data.value)),
      syncAllSystemSettingsAction.createEpic$(action$, ({ shouldUpdateOnly }) =>
        client.getSettingsChangeFeed$(
          shouldUpdateOnly ? state$.value[SETTINGS_REDUCER_KEY]?.changeFeedToken : undefined
        )
      )
    );
};

const safeSpread = (value: unknown) => (value !== undefined && typeof value === 'object' ? value : {});

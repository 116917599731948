import styled, { css } from 'styled-components';

import { Chip } from '@material-ui/core';

import { THEME } from '@style/Theme';
import { MediaQueries } from '@util/MediaQueries';

export const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-width: 30px;
`;

export const WrapperIconSearch = styled(WrapperIcon)`
  align-items: center;
`;

export const Icon = styled.img`
  min-width: 24px;
  height: 24px;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
`;

export const WrapperDestination = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  width: 100%;
`;

export const WrapperDestinationSearch = styled.div`
  ${WrapperDestination} margin-left: 12px;
  text-transform: capitalize;
  width: calc(100% - 36px);
`;

export const Destination = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const WrapperTextDestination = styled.div`
  display: inline-grid;
  align-items: center;
`;

export const DestinationContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
`;

export const Radius = styled.span`
  font-size: 12px;
  color: ${THEME.palette.text.secondary};
  white-space: nowrap;
  margin-bottom: 3px;
`;

export const WrapperFilters = styled.div`
  && {
    display: inline-grid;
    max-width: 100%;
  }
`;

export const Backhauls = styled.span`
  && {
    font-size: 14px;
    font-weight: bold;
    ${(props: { backhauls: number; isClickable: boolean }) =>
      props.backhauls > 0 &&
      props.isClickable &&
      css`
        ${ActiveText};
      `};
  }
`;

const ActiveText = css`
  && {
    color: ${THEME.palette.secondary.main};
  }
`;

export const CustomChips = styled(Chip)`
  && {
    background: ${THEME.palette.primary.light};
    height: 22px;
    font-size: 12px;
    color: ${THEME.palette.text.primary};
    margin: 8px 8px 0 0;
    cursor: pointer;
  }
`;

const TextEnd = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Filters = css`
  font-size: 12px;
`;

const TextDestination = css`
  font-size: 16px;
  font-weight: bold;
`;

const ActiveCard = css`
  && {
    background: ${THEME.palette.background.dim};
    border-bottom: 2px solid ${THEME.palette.background.accent};
  }
`;

export const ChipFilter = styled.span`
  ${TextEnd};
  ${Filters};
  text-transform: initial;
`;

export const DestinationText = styled.span`
  ${TextEnd};
  ${TextDestination};
  align-self: end;
  font-size: 14px;
`;

export const DateFilter = styled.div`
  font-size: 12px;
  margin-right: 5px;
`;

export const MainContainer = styled.div`
  && {
    display: flex;
    min-height: 52px;
    background: ${THEME.palette.background.paper};
    color: ${THEME.palette.text.primary};
    padding-top: 19px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 15px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${THEME.palette.decorator.border2};
    cursor: ${(props: { onClick?: () => void }) => (props.onClick ? 'pointer' : 'auto')};
    justify-content: space-between;
  }
`;

export const SmallMainContainer = styled.div`
  display: flex;
  min-height: 32px;
  flex: 1;
  justify-content: space-between;
  background: ${THEME.palette.background.paper};
  color: ${THEME.palette.text.primary};
  padding: 16px;
  cursor: ${(props: { onClick?: () => void }) => (props.onClick ? 'pointer' : 'auto')};
`;

export const Container = styled.div`
  > :hover {
    background: ${THEME.palette.background.grayLight};
  }
  width: 340px;

  @media ${MediaQueries.lt_lg} {
    width: 340px;
  }

  @media ${MediaQueries.lt_mobile} {
    width: 100%;
  }

  @media ${MediaQueries.xxs} {
    width: 320px;
  }
`;

export const activeCardCss = css`
  ${ActiveCard};
`;

export const MainContainerWithActive = styled(MainContainer)`
  ${(props: { isActive: boolean }) => (props.isActive ? activeCardCss : '')};
  /*Override main container */
  && {
    padding-top: 11px;
  }
`;

export const MainContainerWithActiveUnsave = styled(MainContainer)`
  ${(props: any) =>
    props.activeSearch === props.idSearch &&
    css`
      ${ActiveCard};
    `};
`;

export const MainContainerBackhauls = styled(MainContainer)`
  && {
    min-height: 56px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    background: ${THEME.palette.background.grayLight};
  }
`;

export const MainContainerPostTruck = styled(MainContainer)`
  && {
    padding: 0;
    min-height: 130px;
    flex-direction: column;
    display: flex;
    flex: 1;
  }
`;

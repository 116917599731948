import React, { useLayoutEffect, useRef } from 'react';

import { noop } from 'lodash';

import { FragmentHolder } from './FragmentHolder';

interface Props {
  style?: React.CSSProperties;
  fragmentHolder: FragmentHolder;
}

/**
 * React Portal helper to use another React component as the portal target instead
 * of using a DOM element outside of React.
 *
 * FragmentPortalDestination is where the components physically appear in the DOM
 *
 * Installs the divElement to the DOM on component mount,
 * uninstalls it when unmounting (in a way that can be reused)
 * all while preserving the divElement reference
 * If fragment already installed elsewhere, it will not install it here,
 * but once uninstalled from elsewhere, it will then install it here.
 */
export const FragmentPortalDestination: React.FC<Props> = ({ style, fragmentHolder }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInstalled = useRef(false);

  useLayoutEffect(() => {
    if (isFragmentInDOM(fragmentHolder) || ref.current === null) {
      return noop;
    }

    ref.current.appendChild(fragmentHolder.fragment);
    isInstalled.current = true;

    return () => {
      if (ref.current === null || !isInstalled.current) {
        return;
      }

      fragmentHolder.fragment = document.createDocumentFragment();
      ref.current.removeChild(fragmentHolder.divElement);
      fragmentHolder.fragment.appendChild(fragmentHolder.divElement);
      isInstalled.current = false;
    };
  }, [fragmentHolder, fragmentHolder.fragment]);

  return <div id="portaldest" style={style} ref={ref} />;
};

/** returns true if divElement is known to be installed anywhere in the DOM (may not be in this FragmentPortalDestination) */
export const isFragmentInDOM = (fragmentHolder: FragmentHolder) => fragmentHolder.fragment.childElementCount === 0;

import React, { forwardRef, SyntheticEvent } from 'react';

import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

import { default as MoreMenuSVG } from './more-menu.svg?react';

const ContainerButton = styled(IconButton)`
  && {
    height: 36px;
    width: 36px;
    padding: 0px;
  }
`;

interface Props {
  onClick: (event: SyntheticEvent) => void;
  className?: string;
}

export const MoreMenuButton = forwardRef((props: Props, ref: React.LegacyRef<HTMLDivElement>) => (
  <div ref={ref}>
    <ContainerButton id="more" className={props.className} onClick={props.onClick}>
      <MoreMenuSVG />
    </ContainerButton>
  </div>
));

import memoizeOne from 'memoize-one';

import { FolderType } from '@common/model';
import { DocumentsContext } from '@common/redux/epic/DocumentListEpic';
import { DefaultEncoder } from '@common/util/encoder/URLDefaultEncoder';
import { parseBoolean, parseString } from '@common/util/parser/ParserUtils';

export interface MyDocumentsRoute {
  id?: string;
  folderName?: string;
  type?: FolderType;
  loadID?: string;
  origin?: string;
  destination?: string;
  typeDocumentFolder?: string;
  documentType?: string;
  dateStart?: string;
  dateEnd?: string;
  documentId?: string;
  documentName?: string;
  redirectLoadDetailsPath?: string;
  redirectCommunicationPath?: string;
  redirectLoadDetailsSearch?: string;
  isFromMyLoads?: boolean;
  context?: DocumentsContext;
}

class MyDocumentsEncoder extends DefaultEncoder<MyDocumentsRoute> {
  getVocabulary() {
    return {
      id: 'id',
      fn: 'folderName',
      t: 'type',
      lid: 'loadID',
      o: 'origin',
      d: 'destination',
      tdf: 'typeDocumentFolder',
      dt: 'documentType',
      ds: 'dateStart',
      de: 'dateEnd',
      did: 'documentId',
      dn: 'documentName',
      rldp: 'redirectLoadDetailsPath',
      rlcm: 'redirectCommunicationPath',
      rlds: 'redirectLoadDetailsSearch',
      frml: 'isFromMyLoads',
      ctx: 'context',
    };
  }
  convertUrlSearchParamsToObject = memoizeOne((urlParams: string): MyDocumentsRoute => {
    const rawObject = this.convertUrlParamsToObjectRequest(urlParams);

    return {
      id: parseString(rawObject.id),
      folderName: parseString(rawObject.folderName),
      type: parseString(rawObject.type) as FolderType | undefined,
      loadID: parseString(rawObject.loadID),
      origin: parseString(rawObject.origin),
      destination: parseString(rawObject.destination),
      typeDocumentFolder: parseString(rawObject.typeDocumentFolder),
      documentType: parseString(rawObject.documentType),
      dateStart: parseString(rawObject.dateStart),
      dateEnd: parseString(rawObject.dateEnd),
      documentId: parseString(rawObject.documentId),
      documentName: parseString(rawObject.documentName),
      redirectLoadDetailsPath: parseString(rawObject.redirectLoadDetailsPath),
      redirectCommunicationPath: parseString(rawObject.redirectCommunicationPath),
      redirectLoadDetailsSearch: parseString(rawObject.redirectLoadDetailsSearch),
      isFromMyLoads: parseBoolean(rawObject.isFromMyLoads),
      context: parseString(rawObject.context) as DocumentsContext | undefined,
    };
  });
}

export const myDocumentsEncoder = new MyDocumentsEncoder();

import styled from 'styled-components';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import THEME from '@style/Theme';

export const defaultBorder = `1px solid ${Color.GRAY_SMOKE}`;
export const errorBorder = `2px solid ${Color.RED_APPLE}`;

export const PanelWrapper = styled(ExpansionPanel)`
  && {
    margin: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: none;
    background: ${(props: any) => props.blockedUser && THEME.palette.background.orangeSmoke};
  }
`;

export const PanelWrapperTransparent = styled(PanelWrapper)`
  && {
    background: transparent;
    box-shadow: none;
  }
`;

interface PanelTitleProps {
  hasError?: boolean;
  shouldHideBorder?: boolean;
  expanded?: boolean;
  centerAlignItems?: boolean;
}

export const PanelTitle = styled(ExpansionPanelSummary)`
  && {
    padding: 15px;

    min-height: unset !important;

    > div {
      display: flex;
      align-items: ${(props: PanelTitleProps) => (props.centerAlignItems ? 'center' : 'flex-start')};
      margin: 0;
      .title-data {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        span {
          font-family: overpass;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .title-icon {
        height: 24px;
        width: 24px;
        object-fit: contain;
        margin-right: 12px;
      }
    }
  }
`;

export const ArrowIcon = styled.img`
  padding: 0 5px 0 0 !important;
`;

export const PanelTitleTransparent = styled(PanelTitle)`
  && {
    background: transparent;
    padding: 0;
    box-shadow: none;
    border-bottom: none;
  }
`;

export const PanelDetails = styled(ExpansionPanelDetails)`
  && {
    background: ${Color.GRAY_LIGHT};
    padding: 0;
    min-height: unset !important;
  }
`;

export const PanelDetailsTransparent = styled(ExpansionPanelDetails)`
  && {
    background: transparent;
    padding: 0;
    min-height: unset !important;
    margin-top: 15px;
  }
`;
export const PanelDetailsTransparentNoMargin = styled(ExpansionPanelDetails)`
  && {
    background: transparent;
    padding: 0;
    min-height: unset !important;
  }
`;

export const RightButton = styled.span`
  && {
    padding: 0 !important;
    display: flex;
    font-weight: ${THEME.text.link.fontWeight};
    color: ${Color.ORANGE_MAIN};
  }
`;

export const LockIcon = styled.img`
  && {
    display: flex;
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
`;

export const Title = styled.span`
  && {
    font-weight: ${THEME.text.header1Tab.fontWeight};
    font-size: ${FontSize.Content}px;
    color: ${Color.GRAY_DARK};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Subtitle = styled.span`
  && {
    font-size: ${FontSize.ContentDetail}px;
    color: ${Color.GRAY_STONE};
  }
`;

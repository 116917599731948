import common from '../common/src/translation/english';

export default {
  en: {
    translation: {
      common: common,
      updateEmail: 'To update your email, call',
      reset_password: 'To reset your password',
      IE_Warning: 'Internet Explorer is not currently supported. Please upgrade to Microsoft Edge or change browser.',
      Meta_Description:
        'Load board solutions to help professional truckers find truck loads, find available freight, post their truck availability to move more truck freight.',
      Go_to_detail: 'Go to detail',
      My_Account: 'My Account',
      Message_Center: 'Message Center',
      Alerts: 'Alerts',
      Help: 'Help',
      current: 'CURRENT',
      Settings: 'Settings',
      Logout: 'Logout',
      Submit: 'Submit',
      Create: 'Create',
      Click_Here: 'click here',
      Version: 'Version',
      Number: 'Number',
      StreetName: 'Street name',
      Or: 'or',
      Of: 'of',
      Ok: 'Ok',
      Saved: 'Saved',
      Contact: 'Contact',
      Calculate: 'Calculate',
      PhoneNumber: 'Phone number',
      PleaseCall: 'Please call',
      Show: 'Show',
      Invalid_Format: 'Invalid format',
      Dismiss: 'Dismiss',
      Extension_Short: 'ext. {{extension}}',
      Time: 'Time',
      To: 'to',
      Upgrade: 'upgrade.',
      Date: 'Date',
      email: 'email',
      name: 'Name',
      open123LB: 'See all in 123Loadboard',
      showNumber: 'Show number',
      textfield: {
        Show_Password: 'show',
        Hide_Password: 'hide',
      },
      altText: {
        icon: 'icon',
        control: 'control',
      },
      authenticatedRoute: {
        upgradePlan: {
          title: 'To access this feature, please ',
          link: 'upgrade your plan.',
        },
        restrictedRibbonPopUp: {
          title: 'To activate this feature, please ',
          request: 'send a request ',
          content: ' or call ',
          contact: ' 877-875-5301 ext. 2.',
        },
        restrictedRibbonPopUpCarrier: {
          title: 'To activate this feature, please ',
          request: 'send a request ',
          content: ' to our support team. ',
        },
      },
      sessionPausedPopUp: {
        title: 'Session paused',
        content: 'Due to inactivity, your session has been paused. Please click anywhere to continue.',
      },
      contactSupportPhone: '(877) 875-5301',
      error: {
        generic_api_message: 'Something went wrong. If the error persist, please contact tech support.',
        title: 'Oops, something went wrong.',
        network_server_error:
          'An internal server error or bad connectivity occurred. Please reload to try again. If the error persist, please contact tech support.',
        message_retry: 'Something went wrong. You may retry now or at a later time.',
        message_error: 'An internal server error occurred. Please try again.',
        invalid_address: 'The address cannot be located, please enter a valid address.',
        genericErrorTitle: 'An error has occured',
        genericErrorMessage: 'We are experiencing technical difficulties, please try again.',
        genericContactSupport: 'If the problem persists, please contact support at',
        connectionErrorMessage: 'Experiencing connectivity issues. If the problem persists, please contact support at',
        try_again_error: 'An error occurred. Please try again.',
        failed_to_load: 'Failed to load',
        retry: 'Retry',
        reload_label: 'Reload the page',
        try_again: 'Try again',
        validation: {
          email: 'Not a valid email address.',
          phone: 'Phone number must be 10 digits long.',
          undefinedPhone: 'Phone number is required.',
          invalidPhone: 'The phone number is not valid.',
          invalidEmail: 'Email is not valid.',
          dropoffAfterPickup: 'Drop off date must be after Pick up date.',
          invalidExt: 'Ext is too long',
          contactDataError: '*Phone or email are required.',
          invalidLink: 'The link is not valid',
        },
        limitReached: 'Daily complimentary usage reached!',
      },
      unit: {
        money: '{{sign}}{{currency}}{{value}}',
        year: '{{count}} year',
        year_plural: '{{count}} years',
        month: '{{count}} month',
        month_plural: '{{count}} months',
        day: '{{count}} day',
        day_plural: '{{count}} days',
        mile: '{{value}} mile',
        mi: '{{value}} mi',
        mile_plural: '{{value}} miles',
        mile_per_gallon_short: '{{value}} MPG',
        per_mile: '{{value}} / mile',
        per_mi: '{{value}}/mi',
        per_gal: '{{value}}/gal',
        pounds: '{{value}} lbs',
        pound: '{{value}} lb',
        feet: '{{value}} ft',
        feet_symbol: "{{value}}'",
      },
      Header: {
        Dashboard: 'Dashboard',
        Find_Loads: 'Find Loads',
        Search_Loads: 'Search Loads',
        Load_Availability: 'Load availability',
        Trip_Builder: 'Trip builder',
        New_Trip: 'New trip',
        Add_A_Load: 'Add a load',
        Load_Planner: 'Load Planner',
        Post_Loads: 'Post Loads',
        Manage_Loads: 'Manage Loads',
        Post_Single_Load: 'Post Single Load',
        Post_Multiple_Loads: 'Post Multiple Loads',
        Upload_Posts: 'Upload Posts',
        Export_Posts: 'Export Posts',
        Find_Trucks: 'Find Trucks',
        Integration_Services: 'Integration Services',
        HTML_Embed_Code: 'HTML Embed Code',
        Tools: 'Tools',
        Rate_Check: 'Rate Check',
        MyDocs: 'MyDocs',
        Mileage_Map_Tolls: 'Map, routing, and tolls',
        Mileage_Calculator: 'Mileage Calculator',
        Credit_Report: 'Credit Report',
        Carrier_Monitor: 'Carrier Monitor',
        Search_Directory: 'Search Directory',
        Hot_Leads: 'Hot Leads',
        SearchDocs: 'SearchDocs',
        Affiliated_Services: 'Affiliated Services',
        Get_Paid_Faster: 'Get Paid Faster',
        Save_At_The_Pump: 'Save At The Pump',
        Roadside_Assistance: 'Roadside Assistance',
        Trucking_Authority: 'Trucking Authority',
        Resources: 'Resources',
        Reefer_Rates: 'Reefer Rates',
        FMCSA_Lookups: 'FMCSA Search',
        Fuel_Prices: 'Fuel Prices',
        Border_Wait_Times: 'Border Wait Times',
        My_Loadboard: 'My Loadboard',
        Potential_Loads: 'Potential Loads',
        Booked_Loads: 'Booked Loads',
        Companies: 'Companies',
        Company_Search: 'Company Search',
        Blocked_Hidden: 'Blocked / Hidden',
        Favorites: 'Favorites',
        Bids: 'Bids',
        Load_Bids: 'Load Bids',
        Alerts: 'Alerts',
        Manage_Alerts: 'Manage Alerts',
        Alert_Settings: 'Alert Settings',
        Saved_Searches: 'Saved Searches',
        Truck_Locator: 'Truck Locator',
        Internal_Truck_Locator: 'Internal Truck Locator',
        My_Account: 'My Account',
        TMS_Integration: 'TMS Integration',
        Messages: 'Messages',
        Help: 'Help',
        Settings: 'Settings',
        Main_Menu: 'Main Menu',
        Sign_Up: 'Sign Up',
        Login: 'Login',
        Logout: 'Logout',
        Posting_Aid: 'Posting Aid',
      },
      footer: {
        Copyright: '© {{year}} 123Loadboard',
        Visit_Website: 'Visit Website',
        Terms_Of_Service: 'Terms of Service',
        Privacy_Policy: 'Privacy Policy',
        Address: '© 123Loadboard | 1321 Upland Drive, Suite 1120 | Houston, TX, 77043, USA',
        ContactUs: 'If you have any questions, please contact us',
        SupportEmail: 'support@123loadboard.com',
      },
      confirmationDialog: {
        Cancel: 'Cancel',
        Confirm: 'Confirm',
        LearnHow: 'Learn how',
        broker: 'broker',
      },
      findLoads: {
        shareFeedback: 'Share feedback',
        charsLeft: '{{value}} characters left',
        feedbackAcknowledge: 'Thank you for your feedback.',
        feedbackPlaceholder:
          'We review your feedback to improve our products. Please don’t share personal data or confidential or proprietary information with your feedback.',
        share: 'Share',
        skip: 'Skip',
        invalidSearchQuery: 'Invalid search query',
        findLoads: {
          any: 'Any',
          anyFt: 'Any ft',
          anyLbs: 'Any lbs',
          anyType: 'Any type',
          anywhere: 'Anywhere',
          all: 'All',
          usa: 'USA',
          canada: 'Canada',
          header: 'Find loads',
          tabSearch: 'New search',
          tabRecent: 'Recent',
          tabSaved: 'Saved',
          clear: 'Clear',
          truckApplied: 'Truck filters applied',
          availableCapacity: 'Available capacity',
          loadSize: 'Load size',
          length: 'Length',
          weight: 'Weight',
          myActions: 'My actions',
          sortBy: 'Sort by',
          reset: 'Reset',
          selectDates: 'Select dates',
          selectMax: 'Select a maximum 15',
          trailerType: 'Truck/Trailer type',
          types: 'Types',
          quickSelect: 'Quick select',
          popular: 'Popular',
          other: 'Other',
          home: 'Home',
          homeLocation: 'Choose your home',
          homeLocationLabel: 'Home location',
          currentLocationLabel: 'Current location',
          cityStateOrZip: 'City, state or zip',
          dropOff: 'Drop off',
          dropOffLocation: 'Drop off location',
          pickUp: 'Pick up',
          pickupLocation: 'Pickup location',
          pickupDate: 'Pickup date',
          radius: 'Radius (mi)',
          setHomeLocation: 'Click here to set your home location',
          excludeLoadsWithoutLength: 'Exclude loads without length',
          excludeLoadsWithoutWeight: 'Exclude loads without weight',
          called: 'Called',
          note: 'Note',
          favoriteCompany: 'Favorite company',
          asterisk: ' *',
          ok: 'Ok',
          stepsStatusBar: [
            'Load available',
            'Booked',
            'Scheduled for pickup',
            'Pickup complete',
            'Load en route',
            'Delivered',
          ],
          statusBar: {
            phrase1: 'Ready to book this load?',
            phrase2:
              'If you book this load, select this status and these Load details will be available to you for future reference.',
            phrase3: 'Did you confirm the load details?',
            phrase4: 'Did you pickup the load?',
            phrase5: 'Want a fuel advance for this load?',
            phrase6: 'Are you en route?',
            phrase7: 'Did you deliver the load?',
            phrase8: 'Want to get paid faster for this load?',
            uploadYourRateConfirmation: 'Upload your Rate Confirmation',
            uploadYourBolLumpersEtc: 'Upload your BOL, lumpers, etc.',
            uploadYourProofOfDeliveryPod: 'Upload your Proof of Delivery (POD)',
            postTruck: 'Post truck if you need a backhaul.',
          },
          contact: 'Contact: ',
          ref: 'Ref. #:',
          fuelAdvance: 'Fuel advance',
          sent: 'Sent:',
          tripLengthOptions: ['Short: 0 - 200 mi', 'Medium: 201 - 500 mi', 'Regional: 501 - 1000 mi', 'Long: 1001+ mi'],
          noSearches: 'No {{param}} searches',
          youHaveNoSearches: 'You have no {{param}} searches',
          noSavedSearches: 'No saved searches',
          haveNoSavedSearches: 'You have no saved searches',
          noRecentSearches: 'No recent searches',
          haveNoRecentSearches: 'You have no recent searches',
          noLoads: 'No exact loads found',
          haveNoLoads: 'Please refine your criteria or see similar loads.',
          editLoadSearch: 'Edit search',
          statesProvinces: 'States, Provinces',
          statesProvincesRegionsMap: 'States, Provinces, Regions (Map)',
          statesProvincesRegions: 'States, Provinces, Regions',
          stateProvince: 'State, Province',
          selectRegions: 'Select regions',
          selectUSARegions: 'Select USA regions',
          selectCanadaRegions: 'Select Canada regions',
          regions: 'Regions',
          usaRegions: 'USA Regions',
          canadaRegions: 'Canada Regions',
          selectRegionsOrStates: 'Select region(s) or state(s)',
          selectState: 'Select state',
          selectProvince: 'Select province',
          tl: 'TL',
          ltl: 'LTL',
          Miles: 'Miles',
          perMile: '/mile($)',
          perGal: '/gal ($)',
          Rate: 'Rate($)',
          MPG: 'MPG',
          tollCost: 'Toll cost ($)',
          dispatchFee: 'Dispatch fee',
          otherFee: 'Other fees ($)',
          applyDiscount: 'Apply fuel discount',
          discountValue: '(up to $0.40/gal)',
          getFuelCard: 'Get fuel card',
          applyDeadheadMiles: 'Apply deadhead miles',
          totalProfit: 'Total profit',
          averageRateByCheck: 'Avg. rate by Rate Check',
          postedRate: 'Posted rate',
          difference: 'Difference',
          saveUpTo: ' to save up to ${{value}}',
          fuelSurchargeIncl: 'Fuel surcharge included',
          popUps: {
            blockCompany: {
              title: 'Block company',
              content: 'Blocking this company will hide all their loads from your results.',
              button_cancel: 'Cancel',
              button_block: 'Block',
            },
            sentRequest: {
              title: 'Request sent',
              content:
                'We’ve forwarded your information and relevant documents to Thunder Funding. An expert from Thunder Funding will be contacting you shortly about this load.',
            },
            notSentRequest: {
              title: 'Request not sent',
              pleaseVisitContent: 'Please visit',
            },
            unBookLoad: {
              title: `Uncheck "Booked" status`,
              content: {
                warning: `Are you sure you want to uncheck the "Booked" status on this load?`,
                text1: 'This action does not cancel any bookings you have made directly with the broker.',
                text2: `If you uncheck the status from this load, you may not be able to view the load details and it will be removed from the "My loads" section.`,
                text3: 'Any documents attached to this load will be moved to the ‘Unsorted’ folder.',
              },
              button_no: 'No',
              button_yes: 'Yes',
            },
            paidFaster: {
              title: 'Get paid faster request',
              content: {
                warning:
                  '123Loadboard is partnered with Thunder Funding to provide you with immediate access to cash. Quick Pay\n' +
                  '            always keeps you rolling and you no longer have to bear the burden of front-loading fixed costs over a\n' +
                  '            lengthy duration.',
                text: 'By requesting to get paid faster, a representative from Thunder Funding will be contacting you.',
              },
            },
            fuelAdvanced: {
              title: 'Fuel advance request',
              content: {
                warning: '123Loadboard is partnered with Thunder Funding to provide fuel advances 7 days a week.',
                variant_1: '$10 - advances under $500',
                variant_2: '$20 - advances $500 - $2000',
                variant_3: '$25 - advances over $2000',
                text: 'By requesting a fuel advance, a representative from Thunder Funding will be contacting you.',
              },
            },
            clearRecent: {
              title: 'Remove recent searches',
              content: 'Are you sure you want to remove your recent searches?',
              button_cancel: 'Cancel',
              button_remove: 'Remove all',
            },
            featureRequest: {
              title: 'Activate broker / shipper features',
              content:
                'This feature is only available to brokers and shippers. To gain access, please submit a request.',
              featureAvailableForBrokersAndShippers: 'This feature is only available to brokers and shippers.',
              gainAccess: 'To gain access, please submit a request or call ',
              button: 'Submit Request',
            },
            featureRequestCarrier: {
              title: 'Activate feature',
              content: 'Please submit a request to gain access to this feature.',
              button: 'Submit Request',
            },
            featureSubmit: {
              title: 'Request sent',
              content:
                'Your request to access broker/shipper feature has been submitted and our Posting team will reach out to you shortly.',
              contentCarrier:
                'Your request to access this feature has been submitted and our Support team will reach out to you shortly.',
              button: 'OK',
            },
            featureSubmitFail: {
              title: 'Request has failed to send',
              content: 'Your request to access broker/shipper feature has failed to send, please try again.',
              contentCarrier: 'Your request to access this carrier feature has failed to send, please try again.',
            },
            bidHeader: {
              label: 'Before you bid, please ensure that you meet these requirements.',
            },
            bidDetails: {
              bidCompanyName: 'Company: ',
              bidCompanyDOT: 'DOT#: ',
              bidEquipmentType: 'Trailer type: ',
              bidEquipmentSpecs: 'Trailer specs: ',
              bidEquipmentCommodity: 'Item: ',
              bidEquipmentNotes: 'Notes: ',
            },
            regionsInfo: {
              title: 'Regions allow you to search many states at once. Our regions include:',
            },
            smallTruckLoads: {
              title: 'Small truck loads',
              content:
                'To find the best results for smaller trucks, we recommend that you select the following equipment types:',
            },
          },
          pleaseEnterValues: 'Please enter values',
          fieldsInvalid: 'An entry has an invalid value',
          fieldsRequiredOrInvalid: 'An entry is required or has an invalid value',
          Linehaul_Revenue: 'Linehaul revenue',
          Toll_Costs: 'Toll costs',
          Fuel_Costs: 'Estimated fuel costs',
          Additional_Costs: 'Additional costs',
          Fuel_Costs_Short: 'Est. fuel costs',
          Estimated_Revenue: 'Estimated revenue',
          Estimated_Revenue_Short: 'Est. revenue',
          company_added_onboarded: 'Company added to onboarded',
          company_removed_onboarded: 'Company removed from onboarded',
          removeFavorite: 'Remove favorite',
          tagAsOnboarded: 'Tag as onboarded',
          removeOnboardedTag: 'Remove onboarded tag',
          blockCompany: 'Block company',
          multiple: 'Multiple',
          backhauls: 'Backhauls',
          backhaulLoads: 'Backhaul loads',
          unblock: 'Unblock',
          loadDetailsNotShownForBlockedCompany: 'Load details not shown for blocked company',
          searchNewArea: 'Search this area',
          email: 'Email',
          facebook: 'Facebook',
          x: 'X',
          maxSelectionCount: 'You selected the maximum number of locations',
          selectMaxiumum: 'Select a maximum {{value}}',
          newLoads: 'New loads',
          setLocationToSearch: 'Set location to search',
          loadSizeDescription: '“TL” and “LTL” are only available when Length and Weight are set to “Any”.',
          selectStatesMap: {
            title: 'States, Provinces (Map)',
            warning: {
              selectOneState: 'Select at least one state',
            },
          },
        },
        loads: {
          header: 'Loads',
          close: 'close',
          saved: 'saved',
          save: 'save',
          savedSearch: 'Saved search',
          saveSearch: 'Save search',
          map: 'Map',
          clusterLoads: 'Cluster loads',
          foundLoads: 'Found {{value}} loads',
          mapError: {
            noLoadsFound: 'No results found, try panning or zooming the map',
          },
        },
        loadDetails: {
          header: 'Load details',
          address: 'Address',
          company: 'Company',
          companyLoads: 'Company loads',
          viewLoads: 'View loads',
          companyLoadsDescription: 'Find currently available loads posted by this company only.',
          MCNumber: 'MC number',
          DOTNumber: 'DOT number',
          companyType: 'Company type:',
          website: 'Website',
          member: 'TIA Member',
          getPaidFaster: 'Get paid faster',
          yes: 'Yes',
          no: 'No',
          CreditReport: 'Credit report',
          CreditScore: 'Credit score',
          DaysToPay: 'Days-to-pay',
          noMatches: '-',
          notAvailable: 'Not available',
          notProvided: 'not provided',
          notAvailableInPlan: 'Not available in your plan',
          loading: 'Loading details...',
          preApproved: 'Pre-approved by',
          factorable: 'Factorable',
          pcMiler: 'PC*MILER',
          googleMap: 'Google map',
          shareLoad: 'Share this load via:',
          help: 'Help',
          unlock: 'Unlock',
          unavailableForOffline: 'Unavailable for offline loads',
          unavailableForAssigned: 'Unavailable for assigned loads',
          unavailableForDelivered: 'Unavailable for delivered loads',
          copyToClipboard: 'Copy to clipboard',
          myNote: 'My note',
          saveNote: 'Save note',
          loadNote: 'Load note',
          permissions: {
            modal: {
              title: 'This feature is not available in your plan',
              message: 'Please contact our support team.',
              callSupport: 'Call support',
            },
          },
          onBoardedPopUp: {
            header: 'Tag as onboarded',
            content:
              'If you are already onboarded with this company, all of their loads will be tagged for your own reference and filtering needs.',
            button: 'Yes, I’m onboarded',
          },
          emailFormPopup: {
            header: 'Contact form',
            subtitle: 'Fill out the form below and it will be sent to the broker/shipper.',
            placeholder: 'Comments',
            submitText: 'Send',
            options: {
              title: 'Choose one of these options or place a bid:',
              option1: 'Is this load available?',
              option2: 'What is the rate for this load?',
              option3: 'What are the dimensions of this load?',
              option4: 'Can I get onboarded with your company?',
              placeholder: 'Place a bid($)',
            },
            bidAmount: 'Bid amount: {{bid}}$',
          },
          emailConfirmPopup: {
            header: 'Contact form',
            title: 'Thank you',
            subtitle: {
              sentTo: 'Your email form has been sent to {{companyName}}.',
              note: 'Please note that calling is a more reliable way to contact brokers/shippers.',
            },
            submitText: 'Continue',
          },
          editCompanyPopup: {
            title: 'Edit details',
            content: 'Please update company name or DOT#',
            companyName: 'Company name',
            dot: 'DOT#',
          },
          routeDirectionsRequestError: 'Error in loading routes.',
          stop: 'Stop {{value}}: ',
          extraStops: 'Extra stops: {{value}}',
        },
      },
      loadAvailability: {
        region: { title: 'Regions', header: 'Select region' },
        resultPanel: { outbound: 'Outbound', intrastate: 'Intrastate', inbound: 'Inbound' },
        noLoadFound: {
          title: 'No loads found',
          subtitle: 'Please choose another state or toggle between outbound, inbound and intrastate.',
        },
        errorScreen: {
          title: 'Something went wrong',
          subtitle:
            'Sorry for the inconvenience. We’ll be back up and running as fast as possible. Please try again later.',
        },
        Multiple: 'Multiple',
        type: 'Trailer type:',
        regionTitle: 'Region:',
        densityList: 'Density list',
        densityMap: {
          title: 'Density map',
          showResults: 'Show load results',
        },
        mapColorKey: {
          high: 'High (loads are available)',
          medium: 'Medium (loads are slightly limited)',
          low: 'Low (loads are limited)',
        },
      },
      tripBuilder: {
        empty: 'N/A',
        age: 'Age',
        pickUp: 'Pick up',
        pickUpLocation: 'Pick up location',
        dropOffLocation: 'Drop off location',
        title: 'Trip',
        equip: 'Equip',
        size: 'Size',
        company: 'Company',
        status: 'Status',
        miles: 'Miles',
        time: 'Time',
        revenues: 'Revenues',
        rate: 'Rate',
        rates: 'Rates',
        revenue: 'Revenue',
        fuel: 'Fuel',
        tolls: 'Tolls',
        profits: 'Profits',
        profit: 'Profit',
        added: 'Added',
        modifyTrip: 'Modify trip',
        feedbackTitle: 'Trip builder feedback',
        feedbackSubtitle: `We'd love your feedback about the new trip builder. It helps us make 123Loadboard better.`,
        tripDelete_SnackBar_Message: 'Trip deleted',
        tripCreated_SnackBar_Message: 'Trip created',
        loadAdd_SnackBar_Message: 'Load added',
        loadHide_SnackBar_Message: 'Load hidden',
        loadRemove_SnackBar_Message: 'Load removed',
        tripBuilder: {
          loadingScreenTitle: 'Processing request ...',
          loadingScreenDescription:
            'Please wait ... it can take up to 20-30 seconds to get your results. Our system is analyzing possible routes.',
        },
        newTrip: {
          loadByLoadLabel: 'Load-by-load',
          loadByLoadDescription: 'Build your own trip load-by-load',
          entireTripLabel: 'Entire trip',
          entireTripDescription: 'Automatically builds an entire trip for you.',
          tripParameters: 'Trip parameters',
          excludedStatesOptional: 'Excluded states (optional)',
          excludedStates: 'Excluded states',
          excludedStatesSubHeader: 'Only select states you do not want to travel to.',
          maximumLoads: 'Maximum loads per trip',
          popup: {
            popupHeader: 'Result layout',
            popupDescription1: 'Load-by-load allows you to build your own trip one load at a time.',
            popupDescription2: 'Entire trip automatically builds a trip by selecting loads matching your parameters.',
          },
        },
        addLoad: {
          totalLegs: `{{loadsCount}} load(s) for leg number {{legCount}}.`,
          nextLeg: `Next leg: {{count}} Load(s)`,
          noResultsHeader: 'No results found',
          loadRemoved: 'Load removed',
          noResultsDescription: 'There are no loads currently matching your criteria. Please try again later.',
          popup: {
            header: 'Add load',
            description1:
              'If you have already booked this load, you may include the booked rate to make profit calculations more accurate.',
            subHeader: 'Confirm booked rate (optional)',
            radioFlatRate: 'Flat rate',
            radioRatePerMile: 'Rate per mile',
            inputPlaceHolder: 'Booked rate({{currency}})',
            description2:
              'Adding this load to your trip does not automatically book it. To book this load, please contact the load poster.',
            addButton: 'Add load',
            deleteLoadTitle1: 'Delete load',
            deleteLoadTitle2: 'Deleting this load will permanently remove it from your results.',
            deleteLoadTitle3: 'Select any reasons why you want to delete this load:',
            deleteLoadItem1: 'Not Interested',
            deleteLoadItem2: 'Already booked (load is not available)',
            deleteLoadItem3: 'Low pay rate',
            deleteLoadItem4: 'Incorrect information',
            deleteLoadItem5: 'Other',
            deleteLoadConfirm: 'Delete load',
            removeLoadConfirm: 'Remove load',
            removeLoad:
              'In order to remove this load, please first remove all loads that come after this one in the trip order.',
          },
        },
        trip: {
          contentHeader: 'Your trip details',
          foundTrips: `Found {{count}} option(s)`,
          nextTrip: 'Next option',
          prevTrip: 'Prev option',
          contentSubHeader: 'Build your trip by adding the loads that work for you.',
          tripOptionsSubHeader: 'Add more loads to your trip from the following options.',
          profitPerTrip: 'Total profit from added loads',
          profitPerOption: 'Trip option summary',
          profitPerDay: 'Profit per day',
          loads: 'Load(s)',
          mileage: 'Mileage',
          tripDays: 'Trip days',
          deadhead: 'Deadhead',
          added: 'Added',
          continueTripBtn: 'Continue building trip',
          incompleteTrip:
            'Your trip is not complete. Continue building this trip by adding more loads until you reach your final destination.',
          completeTrip: `Your trip is all set! If you haven't already, you should contact the load posters to book these loads.`,
          popup: {
            profitPerDayPopupInfoHeader: 'Profit per day',
            profitPerDayPopupInfo1:
              'Your daily profits are calculated by collecting rate data for all loads in your trip and subtracting estimated fuel and toll costs.',
            profitPerDayPopupInfo2: 'Mileage, toll, and Deadhead data is collected via PC*Miler.',
            profitPerDayPopupInfo3: 'Revenue and profit data is collected via Rate Check.',
            profitPerDayPopupInfo4: 'Fuel cost is collected daily based on current State prices.',
            deleteTripTitle: 'Delete this trip',
            deleteTripP1: 'You will no longer have access to the details for the selected loads.',
            deleteTripP2: 'If you need access to these loads later, you should mark them as "Booked".',
            removeLoadTitle: 'Remove this load',
            removeLoadP1: 'Are you sure you want to remove this load from your trip?',
          },
        },
      },
      postLoads: {
        allCompanyLoads: 'All company loads',
        poster: 'Poster',
        pendingVerification: {
          title: 'Account under review',
          subtitle:
            'Your account is currently under review, please allow 1 business day for the review process to complete.',
          thankYouForSignUp: 'Thank you for signing up with 123Loadboard!',
          yourAccount: 'Your account is ',
          currentlyUnderReview: 'currently under review',
          underReviewContent: ', please allow one business day for the review process to complete.',
          uponApprovalContent:
            'Upon approval, you will receive an email providing you full access to post loads and reach our carrier network on the #1 rated load board app.',
        },
        ChangeContact: 'Change contact',
        Anywhere: 'Anywhere',
        MyLoads: 'My loads',
        Loads: 'Loads',
        PostSingle: 'Post single',
        PostMultiple: 'Post multiple',
        postMultipleBanner: {
          content1:
            "You can save time by posting multiple loads at once via uploading files directly, sending files in through FTP, or having us scrape your website's available loads. Please visit our ",
          instructionsPage: 'instructions page',
          content2: ' for more details. Contact us at ',
          content3: ', ext. 2 for assistance.',
        },
        NoDate: '--',
        Status: 'Status',
        SwitchToPreviousVersion: 'Switch to previous version',
        Online: 'Online',
        Offline: 'Offline',
        Working: 'Working loads',
        Completed: 'Completed loads',
        Assigned: 'Assigned',
        Delivered: 'Delivered',
        LoadDetails: 'Load details',
        ItemCommodity: 'Item/Commodity',
        AverageRate: 'Average rate',
        RefreshAgeMessage: 'Load age refreshed',
        TruckLocatorTitle: 'Truck locator',
        TruckLocatorSubTitle: 'View carriers nearby',
        Views_Count: 'Views: {{count}}',
        TotalLoadViews: '{{displayedViews}} of {{viewsCount}} total load views',
        Miles: '{{value}} mi',
        perMile: '{{value}}/per mile',
        StrengthValue: '{{value}}%',
        RefreshAgeMessageBulk: 'Loads refreshed',
        UpdatePickupDateMessageBulk: 'Load dates/times refreshed',
        LoadsDeleteMessageBulk: 'Loads deleted',
        LoadsStatusChangeMessageBulk: 'Load statuses updated',
        LoadPostedSuccessfully: 'Load posted successfully',
        ClonedLoadPosted: 'Cloned load posted',
        LoadEdited: 'Load edited',
        SpecsOptional: 'Specs (Optional)',
        Select: 'Select',
        fillMandatoryFields: '*Please fill out all mandatory fields.',
        attachDocuments: '*Please attach 1 or more documents.',
        bookingOptions: {
          title: 'Booking options',
          phone: 'Phone',
          email: 'Email',
          messaging: 'Messaging',
          bidding: 'Bidding',
          bookNow: 'Book now',
          manageOnboardLink: 'Manage onboarded carriers',
        },
        duplicateRefNumberTitle: 'Duplicate reference number',
        duplicateRefNumberMessage:
          'A load with the same reference number already exists. Please enter a different reference number or leave the field blank.',
        collapsibleMenu: {
          locationAndDates: 'Location and dates',
          equipmentDetails: 'Equipment details',
          loadInfo: 'Load info',
          tripRateAndDistance: 'Trip rate and distance',
          postingContact: 'Posting contact',
        },
        manipulation: {
          SetOffline: 'Set offline',
          SetOnline: 'Set online',
          RefreshAge: 'Refresh age',
          Edit: 'Edit',
          Delete: 'Delete',
          Clone: 'Clone',
          messages: 'Messages',
        },
        feedbackContent:
          'We’d love your feedback about the new load posting tool. It helps us make 123Loadboard better.',
        popUps: {
          error: {
            button: 'OK',
            title: 'Error',
            content: 'An internal server error occurred. Please try again.',
          },
          postStrength: {
            title: 'Post strength',
            content: 'The greater your post strength, the more exposure your load post will receive.',
            paragraph: ' Strength is determined based on the amount of details provided.',
            pickUpTimeStrength: 'Pick up time: 5%',
            dropOffDateStrength: 'Drop off date: 15%',
            dropOffTimeStrength: 'Drop off time: 5%',
            lengthStrength: 'Length: 20%',
            weightTimeStrength: 'Weight: 20%',
            paymentTimeStrength: 'Pay ($): 35%',
            button: 'OK',
          },
          updateStatus: {
            SetOnlineContent: 'Are you sure you want to set load “Online”?',
            SetOfflineContent: 'Are you sure you want to set load “Offline”?',
            SnackBar_Message: 'Load status updated',
          },
          deleteLoad: {
            title: 'Delete load',
            content: 'For easy reposting, we suggest changing the status to “offline” instead of deleting.',
            deleteButton: 'Delete',
            cancelButton: 'Cancel',
            SnackBar_Message: 'Load deleted',
          },
          bulkDeleteLoads: {
            title: 'Delete loads',
            content: 'For easy reposting, we suggest changing the status to “offline” instead of deleting.',
            deleteButton: 'Delete',
            cancelButton: 'Cancel',
          },
          viewerInfo: {
            lastViewedAge: '{{lastViewedAge}} ago',
            contact: 'Contact',
            company: 'Company',
            phone: 'Phone',
            insuranceOnFile: 'Insurance on File',
            Common: 'Common',
            Contract: 'Contract',
            USDOTNumber: 'USDOT Number',
            MCNumber: 'MC Number',
          },
        },
        Contact: 'Contact',
        Tl: 'TL',
        Ltl: 'LTL',
        PickUpDate: 'Pickup date',
        PickUpTime: 'Pickup time',
        DropOffDate: 'Drop off date',
        DropOffTime: 'Drop off time',
        PickUp: 'Pick up',
        DropOff: 'Drop off',
        AvailableCapacity: 'Available capacity',
        TrailerSpecifications: 'Trailer Specifications',
        TrailerType: 'Trailer type',
        LoadSize: 'Load size',
        ExcludeLoadsWithoutLength: 'Exclude loads without length',
        ExcludeLoadsWithoutWeight: 'Exclude loads without weight',
        PickupLocation: 'Pickup location',
        DropOffLocation: 'Drop off location',
        Multiple: 'Multiple',
        Post: 'Post',
        PostLoad: 'Post load',
        EditLoad: 'Edit load',
        CloneLoad: 'Clone load',
        ClearAll: 'Clear',
        PrefillFrom: 'Pre-fill from recent posts',
        RecentPosts: 'Recent Posts',
        Reset: 'Reset',
        Update: 'Update',
        Low: 'Low',
        Average: 'Average',
        Distance: 'Distance (mi)',
        Rate: 'Flat rate ($)',
        DrivingTime: 'Driving time',
        UpgradePlan: 'Upgrade plan',
        Unlock: 'Unlock',
        TeamDriven: 'Team driven',
        Quantity: 'Quantity of loads',
        AddExtraStops: 'Add extra stops +',
        AddEquipments: 'Add equipment +',
        ExtraStops: 'Extra stop(s)',
        ContactName: 'Contact name',
        Name: 'Name',
        PostStrength: 'Post strength',
        Phone: 'Phone',
        Ext: 'Ext.',
        Email: 'Email',
        High: 'High',
        dropOff: 'Drop off',
        pickUp: 'Pick up',
        totalTrip: 'Total trip',
        pcMiler: 'PC*Miler',
        trailerType: 'Trailer type',
        trailerSpecifications: 'Trailer Specifications',
        availableCapacity: 'Available capacity',
        loadSize: 'Load size',
        length: 'Length (ft)',
        weight: 'Weight (lbs)',
        averageRateByCheck: 'Average rate by rate check',
        ref: 'Ref. #',
        itemCommodity: 'Item/Commodity',
        moreOptions: 'More options',
        additionalLoadDetails: 'Additional load details',
        PostedPanel: {
          PostedHeaderLabel: '{{status}} loads',
          SelectedLoads: '{{quantity}} selected.',
          AllSelectedLoads: 'All {{quantity}} selected',
          SelectAllLoads: 'Select all {{quantity}}',
          NoOnlineLoads: 'No online loads',
          NoOfflineLoads: 'No offline loads',
          NoAssignedLoads: 'No assigned loads',
          NoDeliveredLoads: 'No delivered loads',
          NoOnlineLoadsSubtitle: 'When you post loads you’ll see them here',
          NoOfflineLoadsSubtitle: 'Create loads as drafts, and post them when you’re ready.',
          NoAssignedLoadsSubtitle: 'Mark loads as assigned when you have confirmed the booking.',
          NoDeliveredLoadsSubtitle:
            'Mark loads as delivered when you have confirmed they have reached their destination.',
          PickupDate: 'Pickup date',
          RefreshAge: 'Refresh age',
          Status: 'Status',
          FreeTextFilter: 'Filter by location, ref#, equip, size',
          Ref: 'Ref#',
        },
        SortByPanel: {
          SortBy: 'Sort by',
          Reset: 'Reset',
        },
        StatusPanel: {
          Status: 'Status',
          SelectStatus: 'Select status',
          Online: 'Online',
          Offline: 'Offline',
        },
        errors: {
          InvalidDistance: 'Invalid distance. Please refer to the PC*Miler total trip.',
        },
        loadTemplates: {
          pageTitle: 'Pre-fill from recent',
          buttonTitle: 'Pre-fill',
          notFound: {
            title: 'No recent posts',
            subtitle: 'Post loads to use this pre-fill option.',
          },
        },
        loadAssigned: 'Some load details cannot be modified when a load is assigned.',
      },
      carriers: {
        Carriers: 'Carriers',
        contactInfo: 'Contact info',
        filters: {
          Onboarded: 'Onboarded',
          Favorite: 'Favorite',
          BlockedHidden: 'Blocked/Hidden',
        },
        filterLabel: 'Filter by name',
        NotFound: {
          favorite: {
            title: 'No favorites saved',
            content:
              'To save a company as your favorite, click “Add to favorites” when viewing a carrier in Truck Locator.',
          },
          blocked: {
            title: 'No companies hidden',
            content: 'To hide a company, click “Hide” when viewing a carrier in Truck Locator.',
          },
          onboarded: {
            title: 'No onboarded companies',
            content:
              'To mark a company that you’ve already onboarded with, click “Tag as onboarded” when viewing a carrier in Truck Locator.',
          },
        },
        menuOptions: {
          favorite: 'Favorite',
          remove_favorite: 'Remove favorite',
          mark_onboarded: 'Mark as onboarded',
          unmark_onboarded: 'Unmark onboarded',
          remove_blocked: 'Remove blocked',
        },
        carrierUpdate: {
          carrierFavorited: 'Carrier added to your favorites',
          carrierUnfavorited: 'Carrier removed from your favorites',
          carrierBlocked: 'Carrier is now blocked',
          carrierUnblocked: 'Carrier is unblocked',
          carrierOnboarded: 'Carrier marked as onboarded',
          carrierUnonboarded: 'Carrier removed from onboarded',
        },
      },
      companies: {
        Companies: 'Companies',
        contactInfo: 'Contact info',
        filters: {
          Onboarded: 'Onboarded',
          Favorite: 'Favorite',
          BlockedHidden: 'Blocked/Hidden',
        },
        filterLabel: 'Filter by name',
        NotFound: {
          favorite: {
            title: 'No favorites saved',
            content: 'To save a company as your favorite, click the more option icon when viewing a load.',
          },
          blocked: {
            title: 'No companies blocked',
            content: 'To block a company from your results, click the more option icon when viewing a load.',
          },
          onboarded: {
            title: 'No onboarded companies',
            content:
              'To mark a company that you’ve already onboarded with, click the more option icon when viewing a load.',
          },
        },
        menuOptions: {
          favorite: 'Favorite',
          remove_favorite: 'Remove favorite',
          mark_onboarded: 'Mark as onboarded',
          unmark_onboarded: 'Unmark onboarded',
          remove_blocked: 'Remove blocked',
        },
      },
      modalPanels: {
        Actions: {
          Reset: 'Reset',
          ClearAll: 'Clear all',
          Clear: 'Clear',
        },
        DatesTime: {
          PickUpDatesHeader: 'Pickup dates',
          PickUpDateHeader: 'Pickup date',
          DropOffDateHeader: 'Drop off date',
          DropOffDatesHeader: 'Drop off dates',
          PickUpTimeHeader: 'Pickup time',
          DropOffTimeHeader: 'Drop off time',
          MaxSelectionDatesCount: 'You selected the maximum number of dates',
          SelectMaximum: 'Select a maximum {{value}}',
        },
        LocationPicker: {
          PickUpHeader: 'Pickup location',
          DropOffHeader: 'Drop off location',
          Pickup: 'Pick up',
          DropOff: 'Drop off',
          RecentLocations: 'Recent locations',
          CityStateZip: 'City, state or zip',
          CityOrZip: 'City or zip',
          WarningMessage: 'Selected {{selectedCount}} of maximum {{maxCount}} locations',
        },
        SortBy: {
          Header: 'Sort by',
        },
        Status: {
          Header: 'Status',
          SelectStatus: 'Select status',
          Online: 'Online',
          Offline: 'Offline',
        },
        TrailerType: {
          Header: 'Trailer type',
        },
        TrailerSpecifications: {
          Header: 'Trailer specifications',
        },
        TimePicker: {
          Label: 'Time',
          Error: 'Invalid time format',
        },
        Filter: {
          Filters: 'Filters',
        },
      },
      permissions: {
        location: {
          unavailable: {
            title: 'Location permission unavailable',
            content: "It's not possible to determine your location at this time.",
          },
          denied: {
            title: 'Location permission denied',
            content: "Please enable location permissions in your browser's preferences.",
          },
          timeout: {
            title: 'Location permission timed out',
            content: 'Timeout while getting location.',
          },
        },
      },
      dashboard: {
        CarrierHeader: 'Hi {{name}}, enjoy our carrier dashboard.',
        BrokerHeader: "Hi {{name}}, let's move some loads today.",
        My_Posted_Loads: 'My Posted Loads',
        Find_Loads: 'Find Loads',

        My_Loads_Post: 'My Loads Post',
        Mileage_Calculator: 'Mileage Calculator',
        Profit_Calculator: 'Profit Calculator',
        Save_At_The_Pump: 'Save at the Pump',
        Find_Trucks: 'Find Trucks',
        My_Documents: 'My Documents',
        Get_Paid_Faster: 'Get Paid Faster',
        Carriers: 'Carriers',
        Companies: 'Companies',
        Load_Details: 'Load details',
        Search_Alerts: 'Search alerts',
        Search_Loads: 'Search loads',
        Load_Alerts: 'Load alerts',
        Messages: 'Messages',
        Posted_Truck: 'Posted trucks',
        Online_Loads: 'Online loads',
        Offline_Loads: 'Offline loads',
        Live: 'Live',
        Search: 'Search',
        PostLoad: 'Post load',
        View: 'View',
        ViewAll: 'View all',
        workingLoadWidget: {
          header: 'Working load',
          loadStatus: 'Load status:',
          loadDocuments: 'Load documents:',
          notFound: {
            title: 'Book a load',
            subtitle: 'To see results, find a load and mark it as "booked".',
          },
        },
        recentSearchesWidget: {
          header: 'Recent searches',
          notFound: {
            title: 'Load searches',
            subtitle: 'To see results, start searching for loads.',
          },
        },
        myLoadsWidget: {
          header: 'My loads',
          totalLoadsButtonLabel: 'Total loads',
          contactedLoadsButtonLabel: 'Contacted',
          savedLoadsButtonLabel: 'Saved',
          bookedLoadsButtonLabel: 'Booked',
          pickedUpLoadsButtonLabel: 'Picked up',
          deliveredLoadsButtonLabel: 'Delivered',
        },
        lastPostedLoadWidget: {
          header: 'Last posted load',
          findCapacity: 'Find capacity for this load',
          postStrength: 'Post strength:',
          notFound: {
            title: 'No load posted',
            subtitle: 'To see results, post a load.',
          },
        },
        recentlyPostedWidget: {
          header: 'Recently posted',
          notFound: {
            title: 'No load posts',
            subtitle: 'To see results, start posting loads.',
          },
        },
        userMessages: {
          message_1: 'Hi {{name}}, the highway is calling.',
          message_2: "Hi {{name}}, let's fill that truck.",
          message_3: "Hi {{name}}, mo' miles mo' money.",
          message_4: 'Hi {{name}}, get your load on!',
          message_5: 'Hi {{name}}, no highway is too long.',
          message_6: 'Hi {{name}}, drive like no one is watching.',
          message_7: "Hi {{name}}, these loads aren't gonna haul themselves.",
          message_8: "Hi {{name}}, let's get that truck on the road!",
          message_9: 'Hi {{name}}, up and haul’em!',
          message_10: "Hi {{name}}, let's get rolling.",
          message_11: 'Hi {{name}}, run a search, load up, hit the gas.',
          message_12: "Hi {{name}}, let's get those wheels on the ground.",
          message_13: "Hi {{name}}, let's get trucked up!",
          message_14: 'Hi {{name}}, one of these loads will be right up your alley.',
          message_15: 'Hi {{name}}, we’ve got loads for you.',
        },
        metricsWidget: {
          loadsPostedPerMonth: 'Loads posted per month',
          loadsDeliveredPerMonth: 'Loads delivered per month',
          mostPostedOn: 'Most posted on',
          mostBookedOn: 'Most booked on',
          busiestDay: 'Busiest day',
          legendLoadsPostedText: 'Loads posted in {{month}}',
          legendLoadsDeliveredText: 'Loads delivered in {{month}}',
          monthlyLoads: 'Monthly loads',
        },
      },
      exportPosts: {
        Export_Posts: 'Export Posts',
        Description:
          'We offer various methods to export your loads to other load boards, carriers, etc. Please refer to the options below. Need assistance?',
        Contact: 'Contact 877-875-5301',
        Extension: 'ext. 2',
        exportByFtp: {
          Title: 'Export your loads by FTP',
          Content:
            'Our system can export your loads to any FTP address you specify. Whenever you post, edit or delete a load, our system will send an update to the FTP addresses listed below.',
          Successful_Save: 'FTP connected successfully',
          Successful_Delete: 'FTP deleted successfully',
          Save_Error: 'Something went wrong. Please verify the information entered and try again.',
          Is_Required: 'is required',
          Close_Ftp_Dialog_Title: 'All unsaved content will be lost',
          Close_Ftp_Dialog_Message: 'If you leave the current form, you will lose all newly entered information.',
          Unchanged_Password_Placeholder: 'Unchanged',
          ftp: {
            Ftp_Name: 'FTP Name (Description)',
            Condensed_Ftp_Name: 'FTP Name',
            Last_Transmission: 'Last Transmission',
            Connection: 'Connection',
            Valid: 'Valid',
            Invalid: 'Invalid',
            Protocol: 'Protocol',
            Encryption: 'Encryption',
            Remote_Directory: 'Remote Directory',
            Host: 'Host',
            Port: 'Port',
            Username: 'Username',
            Password: 'Password',
            Enable_Daily_Transmission: 'Enable daily full list transmission per setup',
            Required_Fields: 'Required fields',
            Delete: 'Delete',
            Cancel: 'Cancel',
            Ok: 'Ok',
            protocol: {
              Ftp: 'FTP - File Transfer Protocol',
            },
            encryption: {
              Plain_Ftp: 'Only use plain FTP',
              Implicit_Ftp_Over_Tls: 'Require implicit FTP over TLS',
              Explicit_Ftp_Over_Tls: 'Require explicit FTP over TLS',
              Explicit_Ftp_Over_Tls_If_Available: 'Use explicit FTP over TLS is available',
            },
          },
        },
        exportToCSV: {
          Title: 'Export to CSV File',
          Content: 'You can export all your available loads as a CSV file to easily send them by email, fax, etc.',
          Button: 'CSV File',
        },
        urlScrape: {
          Title: 'URL Scrape',
          Content:
            'Other load boards can post your available loads by scraping a specific URL that we created for your company. Copy the URL and request that they scrape the website every couple of minutes to have your loads posted.',
          Button: 'Copy URL',
          SnackBar_Message: 'URL copied to clipboard.',
        },
        sharePostedLoads: {
          title:
            'Other load boards will be able to automatically post your loads by scraping the following URL(s) that contains your available loads. Copy and send them the following URL to scrape every couple of minutes and your loads will automatically be posted.',
          header: 'Share posted loads',
          exportPostsResults: 'Export posts results',
          exportPostsTableView: 'Export Posts Table View',
          legacyHeader: 'Share posted loads (legacy)',
          pausedStatus: 'Paused',
          expirationUnlimited: 'Unlimited',
          allCompany: 'All company',
          URL: 'URL',
          editURL: 'Edit URL',
          newURL: 'New URL',
          enableUrlExpirationDate: 'Enable “URL expiration date”',
          listedLoads: 'Listed loads',
          pauseUrlScrapeTitle:
            'Pause this URL scrape. Anyone you have shared this URL with will no longer have access until it is unpaused.',
          urlExpirationDate: 'URL expiration date',
          urlAdded: 'URL added',
          urlEdited: 'URL edited',
          urlDeleted: 'URL deleted',
          deletedURLPopup: {
            title: 'Delete URL',
            warning: 'Anyone you have shared this URL with will no longer have access.',
            deleteQuestion: 'Are you sure that you want to delete this link?',
          },
          limitReachedPopup: {
            title: 'URLs limit reached',
            limit: 'There is a limit of 20 URLs.',
            suggestion: 'Please delete any previous URLs before adding a new one.',
          },
          datePickerWarnings: {
            datePassed: 'The date has passed. Please enter a valid date',
            dateMustBeIncluded: 'Date must be included',
            invalidYear: 'Year must be 4 digits (ie. 2000)',
            dateMustExist: 'Date does not exist. Please enter a valid date.',
            enterValidDate: 'Please enter a valid date',
          },
          loadsTable: {
            loading: 'Loading...',
            notFound: 'Load data for this URL cannot be accessed at this time.',
            copyright:
              'Copyright © 123Loadboard. All rights reserved. Any unauthorized copying, publication or reproduction of the\n' +
              '        content of this website is strictly prohibited and constitutes an infringement of copyright. If you would like\n' +
              '        permission to use this content, please contact 123Loadboard at 8778755301, ext. 2.',
            header: {
              pickupDate: 'Pickup date',
              originCity: 'Origin city',
              originState: 'Ori. state',
              originZip: 'Ori. zip code',
              pickupTime: 'Pickup time',
              destinationCity: 'Destination city',
              destinationState: 'Dest. state',
              destinationZip: 'Dest. zip code',
              deliveryDate: 'Delivery date',
              deliveryTime: 'Delivery time',
              approximateMiles: 'Approx. miles',
              equipment: 'Equip.',
              equipmentInfo: 'Equip. info',
              size: 'Size',
              length: 'Length (ft)',
              weight: 'Weight (lbs)',
              quantity: 'Qty.',
              stops: 'Stops',
              teamLoad: 'Team load',
              commodity: 'Commodity',
              notes: 'Notes',
              rate: 'Rate ($)',
              contact: 'Contact',
              contactPhone: 'Contact phone',
              ext: 'Ext.',
              contactEmail: 'Contact email',
              postRef: 'Post ref.',
            },
          },
        },
      },
      loadGeneric: {
        pickupDate: 'Pick up date',
        distance: 'Distance',
        deadhead: 'deadhead',
        equipment: 'Equipment',
        loadSize: 'Load size',
        postedRate: 'Posted rate',
        backhauls: 'Backhauls',
        rateCheck: 'Rate check',
        length: 'Length',
        weight: 'Weight',
        refNumber: 'Ref. #',
        item: 'Item',
        qtyStops: 'Qty/Stops',
        dropOffDate: 'Drop off date',
        moreInfo: 'More details',
        postedAge: 'Posted age',
        company: 'Company',
        avgRate: 'avg. rate',
        averageRate: 'Average rate',
        anywhere: 'Anywhere',
        anywhereUsa: 'USA',
        anywhereCanada: 'Canada',
        currentLocation: 'Current location',
        currentLocationUnknown: 'Current location unknown',
      },
      loadSorting: {
        title: 'Sort by',
        deadhead: 'Shortest deadhead',
        pickupDate: 'Pickup date',
        age: 'Posted age',
        shortestDistance: 'Shortest distance',
        longestDistance: 'Longest distance',
        postedRate: 'Highest posted rate',
        ratePerMile: 'Rate per mile',
      },
      uploadPosts: {
        Upload_Posts: 'Upload Posts',
        Instructions: 'Instructions',
        Drop_Area_Label: 'Drag & Drop your file here',
        Dropbox_Btn_Label: 'Select file',
        Extension: 'ext. 2',
        uploadCSV: {
          Title: 'Upload file',
          Content:
            'Send us your Excel or CSV file to easily post your loads. Please ensure that your file meets our file guidelines above.',
          Feedback: 'File uploaded successfully',
          Feedback_New_Upload:
            'File uploaded successfully.\nIt will be processed as soon as our processor is updated to parse its format.',
          Error:
            'Something went wrong. Please verify that the file meets our file guidelines (see above) and try again.',
        },
        newUploadCsv: {
          Title: 'File uploaded successfully',
          Message:
            "It seems that you are new to the Upload Posts tool.\n\nWe will set up your account and contact you when it's ready. Once that's done, you'll be able to upload files and our system will automatically read and post your available loads.",
          contact: 'For more information, contact ',
        },
        sendByFTP: {
          Title: 'Send Loads by FTP',
          Content:
            'If you would like to send your loads via FTP, you can request FTP login details using the button below.',
          Button: 'request FTP access',
          Feedback: 'FTP request submitted successfully',
        },
        scrapeWebsite: {
          Title: 'Scrape Website',
          Content:
            'If you have available loads listed on your company website, we can scrape these loads every few minutes so they appear in your account on 123Loadboard. If interested, let us know the website URL where we may find your available loads.',
          Textfield_Label: 'URL',
          Button: 'Submit',
          ErrorSnackbar: 'Invalid URL submitted',
          ErrorMsg: 'Invalid input. Make sure the URL is correct',
          EmptyMsg: 'Please enter a URL',
          Feedback: 'URL submitted successfully',
        },
      },
      tmsIntegration: {
        TMS_Integration: 'TMS Integration',
        Description:
          'For your convenience, 123Loadboard is integrated with over 30 Transportation Management Softwares (TMS) that can automatically send your load postings to us.',
        Subtitle: 'Integrate with your company’s TMS',
        Content:
          'Let our qualified staff integrate with your company’s TMS so that your load postings appear on our website in real-time at absolutely no charge.',
        Textfield: 'Transportation Management Software (TMS)',
        Company_List_Description:
          'Here’s a small sample of the TMS companies we are integrated with. Don’t see your TMS listed,',
        Contact: 'contact',
        Contact_Extension: 'ext. 2',
        Invalid_Input_Message: 'Invalid format. The TMS should be at least 2 characters long.',
        snackbar: {
          Successful_Message: 'TMS successfully submitted',
          Dismiss: 'Dismiss',
        },
      },
      htmlEmbed: {
        HtmlEmbed: 'HTML Embed Code',
        Description:
          'Display your posted loads on your own company website in real-time. Copy and paste the HTML Code below into your website. Need assistance?',
        Contact: 'Contact 877-875-5301',
        Textfield_Label: 'HTML code',
        Paper_Title: 'Display your loads on your website',
        Textfield_Description: 'Copy and paste the following HTML code into your own company website. ',
        Version_Description:
          '3 versions to choose from. You can edit the code to control the background color, width and height.',
        Copy_HTML: 'copy html code',
        Version_1: 'Version 1 (default)',
        Version_2: 'Version 2',
        Version_3: 'Version 3',
        Copy_Message: 'HTML code copied to clipboard.',
      },
      aspxEmbed: {
        loadAvailability: {
          title: 'Load Availability',
        },
        alerts: {
          title: 'Alerts',
        },
        messages: {
          title: 'Messages',
        },
        loadPlanner: {
          title: 'Load Planner',
        },
        myLoads: {
          title: 'My Loads',
        },
        myLoadsPost: {
          title: 'Post',
        },
        myCompanies: {
          title: 'Companies',
        },
        mileageCalculator: {
          title: 'Mileage Calculator',
        },
        myDocs: {
          title: 'My Documents',
        },
        creditReport: {
          title: 'Credit Report',
        },
        searchDirectory: {
          title: 'Search Directory',
        },
        hotLeads: {
          title: 'Hot Leads',
        },
        settings: {
          title: 'Settings',
        },
        managerUsers: {
          title: 'Manage Users',
        },
        membershipProfile: {
          title: 'Membership Profile',
        },
        paymentHistory: {
          title: 'Payment History',
        },
        paymentOptions: {
          title: 'Payment Options',
        },
        generalSettings: {
          title: 'General Settings',
        },
        toolsSettings: {
          title: 'Tools Settings',
        },
        alertsSettings: {
          title: 'Alerts Settings',
        },
        companyWebsite: {
          title: 'Company Website',
        },
        accountSetup: {
          title: 'Welcome back',
        },
        accountNotice: {
          title: 'Message',
        },
        accountRedir: {
          title: 'Account',
        },
        help: {
          title: 'Help Center',
        },
      },
      truckPost: {
        TruckPost: 'Post Truck',
        Announcement: {
          Title: 'Truck Posting is available on our mobile app',
          Item1: 'To post your truck, open the 123Loadboard app and click on Post.',
          Item2: 'You can set up a real-time truck post as well as schedule trucks for future dates and locations.',
          Item3: 'Brokers will send you load alerts directly to your phone.',
          Item4: 'Download our mobile app to post your truck.',
        },
      },
      truckLocator: {
        tutorial: {
          load: {
            step1: {
              title: 'Find Trucks Matching Your Loads',
              content1:
                'Find truck capacity matching your load by sending alerts directly to carriers’ mobile devices.',
              content2: 'You can access this overview tour at any time by clicking this icon ',
            },
            step2: {
              title: 'Find Specific Loads',
              content: 'You can filter your loads quickly to find exactly what you want.',
            },
            step3: {
              title: 'Locate Carriers',
              content: 'Find truck capacity matching your load by sending alerts directly to carriers’ mobile devices.',
            },
          },
          truck: {
            step1: {
              title: 'Customize Your Search',
              content: 'Refine your search with available filters to locate carriers that meet your needs.',
            },
            step2: {
              title: 'Viewing Options',
              content:
                'You’re currently on the {{viewName}} view. Change your view by toggling between Map and List to easily manage and contact carriers.',
            },
            step3: {
              title: 'Send Instant Notifications',
              content: 'Alert selected carriers of your load by sending a private notification to their mobile device.',
            },
            step4: {
              title: 'Match Your Next Load',
              content: 'Return to your load list and continue matching your loads with available carriers.',
            },
          },
          tooltip_title: 'Start Truck Locator Tour',
        },
        Post_Loads: 'Post Loads',
        Truck_Locator: 'Truck Locator',
        Alert_Carriers: 'Alert Carriers',
        Manage_hidden: 'Manage hidden',
        AddToFavorites: 'Add to favorites',
        RemoveFromFavorites: 'Remove from favorites',
        Favorite: 'Favorite',
        TagAsOnboarded: 'Tag as onboarded',
        RemoveOnboardedTag: 'Remove onboarded tag',
        Onboarded: 'Onboarded',
        Request_Tracking: 'Request tracking',
        No_Load_Title: 'You don’t have any loads posted',
        No_Load_Message: 'Start posting loads to find trucks',
        PostEdit: 'Post of Edit loads in',
        ManageLoads: 'Manage Loads',
        Find_Trucks: 'Find Trucks',
        Section: 'section',
        SearchLabel: 'Filter by pickup or destination',
        SearchLabelCompanyName: 'Search by company name',
        Hide: 'Hide',
        Refresh: 'Refresh',
        Loads_Fail: 'Loads could not be loaded.',
        Load_No_Longer_Available: 'Load no longer available',
        Load_No_Longer_Available_Explain: 'Load has expired or was removed from the system',
        Map_Icon_Label: 'Map',
        List_Icon_Label: 'List',
        Back_To_Load: 'Back to Load',
        Back_To_Loads: 'Back to Loads',
        verified: '\t(Verified)',
        notVerified: '\t(Not Verified)',
        stepper: {
          Select_Load: 'Select Load',
          Locate_Truck: 'Locate Truck',
          Send_Alert: 'Send Alert',
        },
        fmcsaAuthority: {
          type: {
            common: 'Common',
            contract: 'Contract',
            broker: 'Broker',
          },
          status: {
            active: 'Active',
            inactive: 'Inactive',
          },
        },
        filter: {
          Title: 'Filters',
          Radius: 'Radius',
          Favorite: 'Favorite',
          Onboarded: 'Onboarded',
          GPS_Verified: 'GPS Verified',
          ActiveAuthority: 'Active authority (MC#)',
          Applied: 'applied',
        },
        truckTable: {
          sentMessages: 'Your company sent {{messagesCount}} messages to this carrier recently.',
          Exact_Matches: 'exact matches',
          Anonymous_Matches: 'exact matches - anonymous',
          Filtered_Out_Matches: 'Filtered out matches',
          Non_Matches: 'non matches',
          Alert_This_Carrier: 'Alert this carrier',
          No_Results: 'No results found, please refine your search to allow the results to display.',
          carrierDetails: {
            Title: 'Carrier Details',
            Authority: 'Authority',
            AuthorityNumbers: 'Authority Numbers',
            CargoAuth: 'Cargo Authorization',
            Property: 'Property',
            Passengers: 'Passengers',
            HouseholdGoods: 'Household goods',
            Private: 'Private',
            Enterprise: 'Enterprise',
            AppPending: 'App. pending',
            RevocationPending: 'Revocation pending',
            Insurance: 'Insurance',
            BIPDInsurance: 'BIPD Insurance',
            HHGCargoInsurance: 'HHG Cargo Insurance',
            BondInsurance: 'Bond Insurance',
            Required: 'Required',
            Filed: 'Filed',
            BIPDPrimary: 'BIPD/Primary',
            Underwriter: 'Underwriter',
            Type: 'Type',
            PolicyNum: 'Policy number',
            EffectiveDate: 'Effective date',
            BIPDClass: 'BIPD Class',
            AlertSent: 'Alert sent {{time}}',
            phoneVerificationPopupText:
              'Phone verification is done at the users request by confirming that they have access to the phone number provided.',
            dotVerificationPopupText:
              'DOT# verification is done by confirming that the user has access to the company phone number listed with the FMCSA.',
          },
        },
        alertDialog: {
          Description: 'Your load will be sent to {{count}} carrier',
          Description_plural: 'Your load will be sent to {{count}} carriers',
          Payrate: 'Pay rate',
          Unit: 'Unit',
          Message_To_Carrier: 'Message to carrier',
          Send: 'Send',
          Cancel: 'Cancel',
          Clear: 'Clear',
          EntireTrip: 'Entire Trip',
          PerMile: 'Per Mile',
          Is_Required: 'is required',
          Send_Error: 'Something went wrong. Please try again later or contact',
          Send_Success: 'Your load info has been successfully sent to {{count}} carrier.',
          Send_Success_plural: 'Your load info has been successfully sent to {{count}} carriers.',
        },
        hideDialog: {
          Title: 'Hide Companies',
          Message: 'This will hide all trucks from the selected companies.',
        },
        loadTable: {
          My_Loads: 'My Loads',
          Just_Now: 'just now',
        },
        map: {
          Map: 'Map',
          legend: {
            Exact_Matches: 'Exact Matches',
            Anonymous_Matches: 'Anonymous Matches',
            Unselected_Matches: 'Unselected Matches',
            Filtered_Out_Matches: 'Filtered out Matches',
            Non_Matches: 'Non Matches',
            show: 'show',
            hide: 'hide',
          },
          path_not_found: 'No route found between origin and destination',
        },
        error: {
          title: "Couldn't retrieve trucks",
          message: 'Oops, there was an issue loading trucks for your load.',
          button: 'Back to loads',
          noExactMatches:
            'There were no trucks found matching your load. Please adjust your load post or try again later.',
        },
      },
      postingAid: {
        Posting_Aid: 'Posting Aid Software',
        Instructions: 'Instructions',
        Description:
          'Posting Aid is a free program that assists you in uploading and automatically posting your loads to 123Loadboard. Posting Aid captures and posts continuous changes made throughout the day. Need assistance?',
        Extension: 'ext. 2',
        Download_Button: 'Posting Aid',
        WatchVideo: 'Watch Video.',
        step1: {
          Title: 'Getting started',
          Content: 'Download and install the software.',
        },
        step2: {
          Title: 'Easy setup',
          Step1: 'Open the Posting Aid software',
          step2: {
            Content: 'Configure sections as follows:',
            StepA: 'Select file or folder to monitor',
            StepB: "Select file type or keep as 'any'",
            StepC: 'Enter your 123Loadboard login details',
            StepD: 'Enter your FTP username and password provided by 123Loadboard',
          },
          Step3: 'Start the Posting Aid on the first section.',
        },
        step3: {
          Title: "You're Done!",
          Content:
            'Posting Aid is now set up to monitor the file or folder you specified and will continuously upload and post changes made throughout the day.',
          Note: 'NOTE: The file is uploaded within 15 seconds.',
        },
      },
      fuelPrices: {
        Title: 'Fuel Prices',
        Description: 'Stay up to date with fuel prices and energy trends.',
        Box1Title: 'Weekly On-Highway Diesel Prices',
        BOX1Button: 'VIEW FUEL PRICES',
        Box2Title: 'Energy updates provided by',
        Box2Description:
          'The U.S. Energy Information Administration (EIA) is a principal agency of the U.S. Federal Statistical System responsible for collecting, analyzing, and disseminating energy information to promote sound policymaking, efficient markets, and public understanding of energy and its interaction with the economy and the environment.',
      },
      fmcsaSearch: {
        FMCSA_Search: 'FMCSA Search',
        Description: 'Review and monitor current operating authority, insurance status and safety ratings.',
        LicensingInsurance: 'Licensing and Insurance',
        SAFER: 'Safety and Fitness Electronic Records (SAFER) System',
        SearchFmcsa: 'Search FMCSA',
        SearchSAFER: 'Search SAFER',
        USDOT_Title: 'United States Department of Transportation',
        USDOT_Description:
          'The United States Department of Transportation (USDOT or DOT) is a federal Cabinet department of the U.S. government concerned with transportation.',
      },
      getPaidFaster: {
        title: 'Get paid for your load instantly!',
        description:
          'Start factoring loads today and get paid much faster by keeping your trucks rolling. Free fuel cards will reduce overall expenses plus fuel advances will allow you to buy fuel and deliver your freight on time.',
        sidePanel: {
          title: 'Exclusive Factoring Partner',
          description:
            "Thunder Funding offers the most complete range of funding services for freight companies. From Owner Operators to Fleet Owners, they've got you covered.",
        },
        form: {
          description:
            '123Loadboard has secured an exclusive partnership with Thunder Funding to provide you with immediate access to cash through the most innovative factoring program in the trucking industry. With Fuel Advances available 7 days a week, Thunder Funding always keeps you rolling.',
          listTitle: 'KEY BENEFITS',
          listItems: {
            i1: 'Invoice Factoring',
            i2: 'Working Capital Loans',
            i3: 'Free Fuel Cards',
            i4: 'Fuel Lines of Credit',
            i5: 'Fuel Advances 7 days a week',
            i6: 'Rush and Same Day Funding',
            i7: 'Free Credit Checking Service',
            i8: 'Free Online Account Management',
          },
          inputsTitle: 'Complete the form to get paid faster',
          button: 'Get Started',
          contactText: 'By clicking get started, a representative from Thunder Funding will be contacting you.',
        },
        done: {
          title: "Thank you! We've received your request.",
          description: 'An expert from Thunder Funding will contact you soon.',
        },
        already: {
          text1: 'You previously submitted a request to get started with factoring.',
          text2a: "If you haven't been contacted yet, ",
          text2b: 'resubmit here',
          text2c: ' or call Thunder Funding.',
          callButton: 'CALL 877-873-8357',
        },
      },
      truckingAuthority: {
        Trucking_Authority: 'Trucking Authority',
        title: 'Get help with your trucking authority',
        description:
          'Trucking starts with authority. Make sure that you have the proper documentation to keep your truck moving.',
        sidePanel: {
          title: 'Exclusive Trucking Authority Partner',
          description:
            'Evilsizor & Associates is a full-service authority company with over 30 years of experience specializing in all things paperwork when dealing with the FMCSA and USDOT.',
        },
        form: {
          description:
            'Looking to file your own authority, request name changes, file BOCs and more... our trucking authority partner can help.',
          inputsTitle: 'Complete the form to speak to an authority expert',
          button: 'SUBMIT',
          contactText: 'By clicking SUBMIT, a representative from Evilsizor & Associates will be contacting you.',
        },
        done: {
          title: "Thank you! We've received your request.",
          description: 'An expert from Evilsizor & Associates will contact you soon.',
        },
        already: {
          title: 'Reliable document management to get you on the road',
          text1: 'You previously submitted a request for a free consultation.',
          text2a: "If you haven't been contacted yet, ",
          text2b: 'resubmit here',
          text2c: ' or call Evilsizor & Associates.',
          callButton: 'CALL 303-482-2965',
        },
      },
      fuelCard: {
        title: 'Introducing the 123Loadboard Fuel Card',
        description:
          'Fuel accounts for nearly 40% of your operating expenses. Using a fuel card for your trucking business is a great way to reduce overall expenses and allows you to purchase fuel at the cash price or even at a discounted price rather than using a credit card and over paying at the pump for credit pricing.',
        sidePanel: {
          title: 'Exclusive Fuel Card',
          description: 'Powered by Thunder Funding and EFS.',
        },
        form: {
          description: 'No sign-up fees, monthly charges, or annual fees.',
          listTitle: 'KEY BENEFITS',
          listItems: {
            i1: 'Free to enroll, no set-up or monthly fees',
            i2: 'Huge discounts of up to 60 cents per gallon',
            i3: 'Fuel lines of credit',
            i4: 'IFTA tax reporting',
            i5: 'Accepted at over 14,000 locations nationwide',
          },
          inputsTitle: 'Complete the form to get your fuel card',
          button: 'GET FUEL CARD',
          contactText: 'By clicking Get Fuel Card, a representative from Thunder Funding will be contacting you.',
        },
        done: {
          title: "Thank you! We've received your request.",
          description: 'An expert from Thunder Funding will contact you soon.',
        },
        already: {
          text1: 'You previously submitted a request to get a fuel card.',
          text2a: "If you haven't been contacted yet, ",
          text2b: 'resubmit here',
          text2c: ' or call Thunder Funding.',
          callButton: 'CALL 877-873-8357',
        },
      },
      backhauls: {
        Backhauls: 'Backhauls',
        possibleLoads: '{{value}} possible loads',
        possibleLoad: '1 possible load',
      },
      rateCheck: {
        history: 'Rate history',
        Rate_Check: 'Rate Check',
        Average_Rate: '{{value}}/mi average rate',
        Description:
          'Estimate the average load rate per mile and your load revenue. Instantly get mileage, routing and toll costs (addon required).',
        About_Title: 'ABOUT RATE CHECK',
        Error_Message: 'Please select a city from the drop-down list',
        About:
          'Rate data is collected from hundreds of small to medium sized carriers. Rates are in USD and are updated daily for most lanes in the Continental US.',
        No_Rate_Check:
          'Rate Check data is not available for the search criteria entered. Where rate data is limited, such as in Canada, our system may produce higher/lower rates. We are continuously working on improving our algorithms so that rate data is more accurate.',
        Note: 'This is not the actual rate but rather the average rate per mile provided by Rate Check. The fuel surcharge may or may not be included in the revenue.',
        routeBox: {
          Title: 'Enter Route',
          Origin: 'Origin - City or Zip',
          Destination: 'Destination - City or Zip',
          Van: 'Van',
          Reefer: 'Reefer',
          Flatbed: 'Flatbed',
          Button: 'get rate',
          GetRate: 'Get rate',
        },
        revenueBox: {
          Route: 'Route:',
          Distance: 'Distance',
          Rate_Per_Mile: 'Avg. rate (USD) / mile',
          Fuel_Surcharge: 'Fuel Surcharge',
          Linehaul_Revenue: 'Linehaul Revenue',
          Toll_Costs: 'Toll Costs',
          Fuel_Costs: 'Estimated Fuel Costs',
          Additional_Costs: 'Additional Costs',
          Fuel_Costs_Short: 'Est. Fuel Costs',
          Estimated_Revenue: 'Estimated Revenue',
          Estimated_Revenue_Short: 'Est. Revenue',
          Included: 'Included',
          Low: 'Low',
          Average: 'Average',
          High: 'High',
          View_Loads: 'View loads',
        },
      },
      marketRates: {
        loadToTruckRatioTitle: 'Loads per ',
        searchForm: {
          marketRates: 'Market rates',
          title: 'Select a lane and equipment type to see the market rate.',
          getRate: 'Get rate',
          notFoundForBroker:
            'Market rate data can help you price your freight by providing you with historical rate data for specified lanes and equipment types.',
          notFoundForCarrier:
            'The Market Rates tool can help you find rate data and determine profitability for specific lanes based on your equipment type.',
          originLabel: 'Origin - city or zip',
          destinationLabel: 'Destination - city or zip',
        },
        widgets: {
          city: 'City',
          county: 'County',
          state: 'State',
          days: 'days',
          month: 'month',
          months: 'months',
          year: 'year',
          past: 'Past',
          selectDataRange: 'Select data range',
          averageRateAnalysis: 'Average rate check for past',
          expediteAll: {
            poweredBy: 'Powered by Expedite All',
            brokerToCarrierLTLRate: 'Broker-to-Carrier rate for ',
            title: 'Expedite All',
            estimatedRate: '{{rate}}/mi estimated rate',
            estRateByExpedite: '{{rate}}/mi est. rate by Expedite All',
            info: 'Expedite All provides market rate data, offering accurate rate benchmarks derived from real-time industry data to help you compare lane rates and optimize your pricing strategy.',
          },
          brokerToCarrierRates: {
            title: 'Broker-to-Carrier rate for past',
            totalTrip: 'Total trip: {{miles}} mi',
            fuelCost: 'Incl. trip fuel cost: {{fuelCost}}',
            titleCarrier: 'Carrier spot rate',
            subtitle: 'Recommended rate (incl. fuel)',
            basedOn: 'Based on',
            reports: 'reports',
          },
          capacityFinder: {
            title: 'Capacity finder',
            subtitle: 'Contact posted trucks and carriers searching for loads on this lane.',
            numberOfCarriers: '{{number}} Carrier(s)',
          },
          fuelAndTollCosts: {
            estFuelCost: 'Est. fuel cost',
            tollCost: 'Toll costs',
            trip: 'Trip',
            drivingTime: 'Driving time',
            estimatedProfit: 'Estimated profit',
          },
          marginCalculator: {
            title: 'Margin calculator',
            rate: 'Rate ($)',
            margin: 'Margin %',
            targetRate: 'Target rate ($)',
          },
          rateCheck: {
            dataRange: 'Data range: ',
            suggestedRate: 'Suggested rate',
            backhaulAverageRate: 'Backhaul average rate',
          },
          acceptancePoint: {
            title: 'Acceptance point',
            subtitle: 'Determine your negotiation range and find the rate acceptance point for this lane.',
            calculatorTitle: 'Acceptance point calculator',
            reset: 'Reset',
            postingRateLabel: 'Posting rate ($)',
            estimatedCarrierOfferLabel: 'Est. carrier offer ($)',
            shipperChargeLabel: 'Shipper charge ($)',
            acceptancePointLabel: 'Acceptance point ($)',
            profitMarginLabel: 'Total profit margin of shipper charge:',
            above: 'above',
            below: 'below',
            profitMarginDifference: '{{percent}}% {{helperText}} acceptance point',
            cards: {
              postingRate: {
                title: 'Posting rate',
                text: 'Posting rate is the price you intend to post your load at. The default entry is the average market rate. You may enter your own value if you already know the rate you plan to post.',
              },
              estimatedCarrierOffer: {
                title: 'Est. carrier offer',
                text: 'This is the estimated rate desired by the carrier. If you have an offer from a carrier, you can enter it to calculate how acceptable it is for your business.',
              },
              shipperCharge: {
                title: 'Shipper charge',
                text: 'Shipper charge is the rate you will be charging the shipper to move loads on this lane. You may enter your own shipper charge to increase the accuracy of this tool.',
              },
              acceptancePoint: {
                title: 'Acceptance point',
                text: 'The acceptance point is the mutually acceptable rate for a load on this lane. You may enter your own acceptance point to calculate your profit margin when charging the shipper.',
              },
            },
            graph: {
              postingRateLabel: 'Posting rate:',
              acceptancePointLabel: 'Acceptance point:',
              shipperChargeLabel: 'Shipper charge:',
              estimatedCarrierOfferLabel: 'Est. carrier offer:',
              belowPostingRate: 'below posting rate',
              abovePostingRate: 'above posting rate',
            },
          },
          negotiationStrength: {
            title: 'Negotiation strength',
            unavailableText:
              'Posting strength data for the selected equipment type and lane is unavailable at this time.',
            unavailableTextCarrier:
              'Negotiation strength data for the selected equipment type and lane is unavailable at this time.',
            lowSection: {
              title: 'Low',
              part1: 'Negotiation strength for this lane is  ',
              part2: ', so you may have to offer a higher rate.',
              part2Carrier: ', so you may have to accept a lower rate.',
            },
            mediumSection: {
              title: 'Medium',
              part1: 'Negotiation strength for this lane is  ',
              part2: ', so you may need to offer close to the average rate.',
              part2Carrier: ', so you may need to accept a rate closer to the average.',
            },
            highSection: {
              title: 'High',
              part1: 'Negotiation strength for this lane is  ',
              part2: ', so you can justify a lower rate.',
              part2Carrier: ', so you can justify a higher rate.',
            },
          },
          historicalRates: {
            title: 'Historical rates',
            legendTitle: 'Historical market rates for {{month}}',
          },
          rateAnalysis: {
            title: 'Rate analysis',
          },
          loadsWidgets: {
            pickupLoadsTitle: 'Pickup location loads',
            backhaulsLoadsTitle: 'Backhaul loads',
            forDays: 'for {{days}}',
            toLocation: 'to {{location}}',
            viewLoads: 'View loads',
          },
        },
        popups: {
          rateHistory: {
            title: 'Rate history',
            content:
              'Rate history can help you predict trends and price your freight based on data from the past year.',
          },
          rateCheck: {
            title: 'Rate check',
            content:
              'Rate check provides the average market rate to help compare lane rates before posting loads and better estimate your load revenues.',
          },
          suggestedRate: {
            title: 'Suggested rate',
            content:
              'Suggested rate can help you determine how to price your freight to increase load acceptance by comparing the average rate with other posted loads leaving the pick up location.',
          },
          rateDifference: {
            title: 'Backhaul rate difference',
            content:
              'Backhaul rate difference can be used to price your freight based on whether the carrier will find higher or lower priced loads at the drop off location.',
          },
          rateReports: {
            title: 'Rate reports',
            content: 'Reports are generated by rate data collected based on the entered lane and equipment type.',
          },
          negotiationStrength: {
            title: 'Negotiation strength',
            content:
              'Negotiation strength is based on the amount of loads going to the selected destination today and the rates they are posted at.',
          },
          historicalRates: {
            title: 'Historical rates',
            content:
              'Historical data can provide you with a good understanding of recent market activity, seasonal trends, and help with planning and forecasting.',
          },
          rateAnalysis: {
            title: 'Rate analysis',
            content:
              'Rate analysis provides you with the high, medium, and low rates based on the current year and can help determine fair rates based on this year’s market conditions.',
          },
        },
      },
      capacityFinder: {
        title: 'Capacity finder',
        customizingResults: 'Customizing your results',
        titleCapitalized: 'Capacity Finder',
        originLocationPlaceholder: 'Pickup - city or zip',
        destinationLocationPlaceholder: 'Drop off - city or zip',
        findButton: 'Find',
        emptyPageSubtitle:
          'Capacity finder can help you find available carriers to haul your freight via their posted trucks and load search data.',
        notFoundTitle: 'No matches found',
        notFoundSubtitle: 'No matches found. Please change your search parameters or adjust your filters.',
        foundCarriers: 'Found {{quantity}} carriers',
        carrierDetailsEquipmentsTitle: 'Equipment(s)',
        carrierDetailsDestinationsTitle: 'Desired destination(s)',
        searchType: 'Search',
        postedTruckType: 'Posted truck',
        labels: {
          age: 'Age',
          deadhead: 'Deadhead',
          equipment: 'Equipment',
          capacity: 'Capacity',
          desiredRate: 'Desired rate',
          phone: 'Phone',
          nextAvailable: 'Next available',
        },
        sorting: {
          type: 'Type',
          resultType: 'Result type',
          age: 'Age',
          company: 'Company',
          rating: 'Rating',
          deadhead: 'Deadhead',
          equipment: 'Equipment',
          size: 'Size',
          availableCapacity: 'Available capacity',
          availableCapacityShort: 'Avail. capacity',
          desiredRate: 'Desired rate',
          phone: 'Phone',
          nextAvailable: 'Next available',
          nextAvailableShort: 'Next avail.',
        },
        filtering: {
          onboardedCarriers: 'Onboarded carriers',
          favoriteCarriers: 'Favorite carriers',
          gpsVerified: 'GPS verified',
          activeAuthority: 'Active authority (MC#)',
          age: 'Age: {{age}}h',
          pickupRadiusWithMi: 'Pickup radius: {{radius}}mi',
          dropoffRadiusWithMi: 'Drop off radius: {{radius}}mi',
          pickupRadius: 'Pickup radius',
          dropoffRadius: 'Drop off radius',
          ltlOnly: 'LTL only',
        },
        popups: {
          missingOnboardedCarriers: {
            title: 'Onboarded carriers',
            content: 'To filter by onboarded carriers, you need to add their DOT#s to your onboarded carriers list.',
          },
        },
      },
      myCarrierPortal: {
        onboard: 'Onboard',
        onboarded: 'Onboarded',
        addFavorite: 'Add to favorites',
        favorite: 'Favorite',
        riskAssessment: 'MCP risk assessment',
        integrationRequired: 'Integration required',
        integration: {
          title: 'MCP integration setup',
          to: 'To ',
          onboardedCarriers: 'see onboarded carriers and ',
          description: 'view risk assessments, an integration with MyCarrierPortal is required.',
          pointList1: "If you don't have a MyCarrierPortal account, you can ",
          pointList2: 'To link your account, generate MyCarrierPortal integration credentials ',
          pointList3: 'Return here and proceed to enter credentials.',
          signupLink: 'sign up here ',
          here: 'here ',
          proceed: 'Proceed to Link Account',
          termsConditions:
            'Please note that the terms and conditions of MyCarrierPortal may differ from 123Loadboard. The website will open in a new tab. 123Loadboard is not responsible for any content on third party websites.',
          credentialsDescription:
            'Enter the integration credentials you created with MyCarrierPortal to complete your integration.',
          userName: 'MCP integration user name',
          password: 'MCP integration password',
          linkAccount: 'Link Account',
          back: 'Back',
          incorrectCredentialsMessage:
            'The provided integration credentials are not valid. Please verify your credentials and try again.',
        },
        risklevel: {
          titleLow: 'Risk level: Low',
          titleMedium: 'Risk level: Medium',
          titleReview: 'Risk level: Review',
          titleFail: 'Risk level: Fail',
          low: 'Low: ',
          medium: 'Medium: ',
          review: 'Review: ',
          fail: 'Fail: ',
          descriptionLow: 'This carrier has a low risk level and is likely qualified to haul for you.',
          descriptionMedium: 'This carrier has a medium risk level and is likely qualified to haul for you.',
          descriptionReview: 'This carrier is likely not qualified to haul for you. Proceed with caution.',
          descriptionFail: 'This carrier is not qualified to haul for you and should not be contacted.',
          error: 'Risk Level assessment is not available at the moment. Please try again later.',
        },
        onboarding: {
          onboard: 'Onboard carrier',
          onboardWith: 'Onboard with {{company}} to add them to your carrier network.',
          start: 'Start onboarding',
          pending: 'Onboard pending',
          pendingdescription:
            'An onboarding request has been sent through MyCarrierPortal. When {{carrier}} has completed the onboarding process, they will automatically be added to your Onboarded carriers.',
          description:
            'To onboard this carrier, an onboarding request with instructions will be sent via MyCarrierPortal to their associated email address.',
          description2:
            'When the carrier has successfully completed the onboarding process, they will automatically be added to your Onboarded carriers.',
          send: 'Send request',
          unsuccessful: 'Onboarding unsuccessful',
          onboardingFailed:
            "The onboarding failed because this carrier's risk assessment score is above your threshold. You may log into your MyCarrierPortal for more options.",
        },
      },
      profitCalculator: {
        title: 'Profit calculator',
        pleaseEnterValues: 'Please enter values',
        linehaulRevenue: 'Linehaul revenue',
        miles: 'Miles',
        mile: 'mile',
        rate: 'Rate',
        estimatedFuelCosts: 'Estimated fuel costs',
        mpg: 'MPG',
        gal: 'gal',
        applyFuelDiscount: 'Apply fuel discount (up to $0.40/gal)',
        getFuelCard: 'Get fuel card',
        additionalCosts: 'Additional costs',
        tollCost: 'Toll cost',
        dispatchFee: 'Dispatch fee',
        otherFee: 'Other fee',
        totalProfit: 'Total profit',
        mi: 'mi',
        fieldsRequiredOrInvalid: 'An entry is required or has an invalid value',
        fieldsInvalid: 'An entry has an invalid value',
        accessibility: 'Profit calculator. Press to calculate you profit.',
        reset: 'Reset',
      },
      borderWaitTimes: {
        BorderWaitTimes: 'Border Wait Times',
        Description:
          'Get information as to the waiting times at the various Canadian/US Border crossing points. Each border location updates its estimated wait times hourly.',
        USA_Title: 'USA Border Wait Times',
        Canada_Title: 'Canada Border Wait Times',
        ViewUSA: 'View USA',
        ViewCanada: 'View Canada',
      },
      privateNetwork: {
        title: 'Private network',
        addNumber: 'Add USDOT#',
        addNumberInstructions:
          'Add companies to your private network by adding one or more USDOT# on individual lines or separated by commas.',
        noValidNumbersSubmitted: 'No valid numbers were submitted',
        allNumbersInvalid: 'all failed validation',
        fromTotal: 'From {{count}} USDOT# record',
        fromTotal_plural: 'From {{count}} USDOT# records',
        duplicatesRemoved: '{{count}} duplicate removed',
        duplicatesRemoved_plural: '{{count}} duplicates removed',
        failedValidation: '{{invalid}} failed validation',
        successfullyAdded: '{{number}} USDOT# added to your private network successfully.',
        validationFailed: 'Validation failed',
        review: 'Review',
        filterByNumber: 'Filter by USDOT #',
        foundNumbers: 'Found {{number}} USDOT#',
        issueGettingBoard: 'There was an issue getting your load board',
        errorSubmitting: 'There was an error submitting your request',
        usDot: 'USDOT#',
        pageCount: '{{resultsStartNumber}} - {{resultsEndNumber}} of {{listSize}} USDOT#',
        serverError: 'Server error',
        deleteAll: 'Delete all',
        deleteAllText: 'Are you sure you want to delete all?',
        postPrivately: 'Add companies to your private network to post your loads privately.',
        allResultsFiltered: 'All the results were filtered',
      },
      reeferRates: {
        Reefer_Rates: 'Reefer Rates',
        Source: 'Source:',
        GovLinkName: 'U.S. Department of Agriculture’s Agricultural Marketing Service',
      },
      roadsideAssist: {
        Roadside_Assistance: 'Roadside Assistance',
        Title: 'Emergency roadside assistance',
        Description:
          'Ensure you keep your truck moving. Get heavy-duty emergency roadside assistance for commercial vehicles.',
        MembershipBenefit: 'KEY BENEFITS',
        Benefits: {
          Towing: 'Towing Assistance',
          Towing2: '(up to 50 miles per disablement)',
          FlatTire: 'Flat Tire Assistance',
          FlatTire2: '(up to $150 per occurrence)',
          TireRepl: 'Tire Replacement Assistance',
          TireRepl2: '(up to $100 per disablement)',
          TireRepair: 'Tire Repair Assistance',
          TireRepair2: '(up to $100 per occurrence)',
          Mechanic: 'Mobile Mechanic Service',
          Mechanic2: '(up to $100 for service call)',
          Oil: 'Oil, Build and Water Delivery Service',
          Extriction: 'Extrication / Winching Assistance',
          Fuel: 'Fuel Delivery Service',
          Fuel2: '(up to 30 gallons of fuel)',
          KeyAssist: 'Lock-out/Replacement Key Assistance',
          KeyAssist2: '(up to $100)',
          JumpStart: 'Jump-starts',
          JumpStart2: '(up to $150 per occurrence)',
          PullStart: 'Pull Start',
          PullStart2: '(up to $100 per occurrence)',
          HeavyDuty: 'Extended Heavy Duty Warranty',
          Battery: 'Battery Assistance',
          Battery2: '(up to $100 for service call)',
          Parts: 'Replacement Part Delivery',
          Parts2: '(up to $100 per occurrence)',
        },
        done: {
          title: "Thank you! We've received your request.",
          description: 'An expert from Rig Nation will contact you soon.',
        },
        already: {
          title: 'Emergency roadside assistance',
          text1: 'You previously submitted a request for roadside assistance.',
          text2a: "If you haven't been contacted yet, ",
          text2b: 'resubmit here',
          text2c: ' or call Rig Nation.',
          callButton: 'CALL 800-336-5909',
        },
        CompleteForm: 'Complete the form to speak a roadside assistance expert',
        form_button: 'SUBMIT',
        ExclusiveRoadside: 'Exclusive roadside assistance partner',
        Pricing_Description: 'Specializing in commercial truck roadside & extended warranty.',
        View_Terms: 'View Terms',
        Get_Roadside_Assistance: 'Get Roadside Assistance',
        Roadside_assistance_provided_by: 'Roadside assistance provided by',
        form_contactText: 'By clicking SUBMIT, a representative from Rig Nation will be contacting you.',
      },
      searchDirectory: {
        title: 'Search directory',
        contactDetails: 'Contact details',
        email: 'E-mail',
        subtitle: 'Find companies and information relevant to your business needs.',
        searchFor: 'Search for',
        carriers: 'Carriers',
        brokers: 'Brokers',
        shippers: 'Shippers',
        companyName: 'Company name',
        location: 'Location',
        mandatoryFields: '*Please fill out all mandatory fields.',
        search: 'Search',
        companyDetails: {
          title: 'Company details',
          businessType: 'Business type',
          carrierMC: 'Carrier MC#',
          brokerMC: 'Broker MC#',
          SCAC: 'SCAC code',
          interstate: 'Interstate#',
          street: 'Street',
          tel: 'Tel',
          tollFree: 'Toll-free',
          fax: 'Fax',
          website: 'Website',
          viewOnMap: 'View company on map',
          businessInfo: {
            title: 'Business background info',
            associationCertification: 'Association & certification',
            yearFounded: 'Year founded',
            companyProfile: 'Company profile',
          },
          safety: {
            title: 'Safety',
            subtitle: 'Crashes',
            fatal: 'Fatal',
            injury: 'Injury',
            tow: 'Tow',
            empty: 'N/A',
          },
          creditRating: 'Credit rating',
        },
        notFound: {
          title: 'No results found',
          content: 'Please adjust your search criteria and try again.',
          button: 'Edit search',
        },
        certification: {
          tia: 'TIA',
          ocfp: 'OCFP',
        },
        upgradePopup: {
          title: 'Search Directory',
          content1:
            'The Search Directory allows you to quickly look up companies that use 123Loadboard. Being informed about the companies you work with, or would like to work with is essential to success in the transportation industry. The Search Directory is updated daily, so you can be sure that the information provided is accurate and up-to-date.',
          content2: 'Search Directory is available as part of the Premium Plus membership plan.',
          learnMore: 'Learn more',
        },
      },
      dropBox: {
        File_Too_Big: "Invalid file size. The file shouldn't be empty nor be over 4MB.",
        Invalid_FeedBack: 'Invalid file submitted',
      },
      shareLoad: {
        title: 'Load Details',
        promo: {
          unlockFullPotential: 'Unlock Full Potential',
          switchToMainView: 'SWITCH TO MAIN VIEW',
          loginPromoText:
            'Log in to place a bid, book this load, or message the poster. Compare market rates, find backhauls, plan your route, and use smart tools to optimize your trip.',
          logIn: 'LOG IN',
          findMoreLoads: 'Find More Loads Like This',
          exploreMoreText:
            'Explore similar loads with full details, compare opportunities, and take action. Log in to bid, book, or message the poster directly and get the loads you need for your business.',
          logInToSeeMore: 'LOG IN TO SEE MORE LOADS',
        },
        description: 'This load was shared with you.',
        loadInformation: {
          Title: 'Load Information',
          Distance: 'Distance',
          Pickup_Date: 'Pickup Date(s)',
          Equipment_Type: 'Equipment Type (Spec.)',
          Size: 'Size',
          Weight: 'Weight',
          Length: 'Length',
          Posted_Rate: 'Posted rate',
        },
        dispatch: {
          Title: 'Dispatch',
          Contact_Name: 'Contact Name',
          Telephone: 'Telephone',
          Extension: 'Ext',
          Reference_Number: 'Load Reference #',
        },
        company: {
          Title: 'Company',
        },
        Route: {
          Title: 'Mileage, Routing & Toll Cost Information',
          Total_Mileage: 'Total Mileage',
          Toll_Costs: 'Toll Costs',
          Driving_Time: 'Driving Time',
          Not_Found: 'Routing information not found for this load',
          Disclaimer:
            'Credit information provided by TransCredit and ThunderFunding. Licensing, authority, and insurance information provided by the FMCSA and other sources. Disclaimer: While every effort has been made to ensure that the information contained on this site/app is correct and up-to-date at the time of posting, 123Loadboard, TransCredit, ThunderFunding, PC*Miler, its partners, affiliates and/or subsidiaries shall not be liable for any damages of any kind resulting from use of the information contained herein. 123Loadboard does not lay any claims to the accuracy of the credit ratings, days-to-pay, licensing, authority, mileage, and/or insurance information listed and in no way is the broker, shipper, carrier, owner-operator bound to it. No person should place reliance on information derived from this site/app where such reliance may result in loss, damage or injury. You may choose to make additional credit checks. If you feel that the information posted contains errors, please contact us.',
        },
        creditRating: {
          Title: 'Credit Rating',
          No_Credit_Ratings: 'There is no credit rating available at the moment for this company.',
        },
        upgradeMembership: {
          text1: 'Please ',
          link: 'upgrade',
          text2: ' your membership.',
        },
        upgradeMembershipLoggedOff: {
          text1:
            'The 123Loadboard user sharing this load does not have access to this tool. This feature is available as part of the Premium plan. Please sign in into your account to see these details or ',
          link: 'upgrade',
          text2: ' your membership.',
        },
      },
      verifyEmail: {
        title: 'Verify your email',
        header: {
          successful: 'Email verified',
          expired: 'Verification link expired',
          input: 'Email verification',
          notFound: 'Verification code invalid',
          failed: 'Email verification failed',
        },
        subheader: {
          topMessage: 'We sent an email to activate your account, please check your email {{email}}',
          successful: 'Your email has been successfully verified.',
          failed:
            'Your email address was not verified. Click the button below or call our Customer Support line at (877) 875-5301.',
        },
        content: {
          successful: 'You can now sign in with your 123loadboard account.',
          expired: 'This verification link has expired.\nPlease request a new one to access your account.',
          input:
            'Enter your verification code below. If you require further assistance, call our Customer Support at 877-875-5301',
          notFound:
            'Please check your verification code. If you require further assistance, call our Customer Support at 877-875-5301',
          bottomMessage: 'If you do not receive an email within the next few minutes, be sure to',
          checkSpam: 'check your spam folder or call',
          desiredTime: '(9am-6pm M-F)',
          resendMessage: 'or click here to',
          resendEmail: 'Resend email',
        },
        submitButton: {
          text: 'I’m verified',
        },
        codeInputLabel: 'Verification code',
        validate: 'Validate',
        note: '* If you forgot your login details,',
        signIn: 'Sign in with another account',
        popups: {
          linkSent: {
            title: 'Verification link sent',
            text: 'A verification email has been sent to {{email}}. Please check your inbox and/or your spam/email folder.',
          },
          failedToSend: {
            title: 'Failed to send verification link',
            text: 'Sorry, there was a problem sending the email. Please try again.',
          },
          notVerified: {
            title: 'Email is not verified',
            text: 'Sorry, this email address is not verified yet. Please check your email again.',
          },
        },
      },
      verifiedLogin: {
        redirectMessage: 'If you are not redirected, please',
        divertToAppMessage: {
          header: 'Sign in to 123Loadboard',
          content: 'We’ve detected that you signed up on a mobile device.\nPlease open the mobile app to continue.',
        },
      },
      unsubscribeAssistant: {
        title: 'Unsubscribe assistant',
        currentPlanTitle: 'Your current plan: ',
        currentPlanDiscount: '(you pay ${{priceWithDiscount}} for a ${{defaultPlanPrice}} plan per month)',
        currentPlanFullPrice: '(you pay ${{price}} per month)',
        currentPlanInfo:
          'Your load statuses, saved searches, availability to brokers/shippers, and load documents stored with 123Loadboard will be affected by unsubscribing.',
        reason: 'Please indicate the reason you are unsubscribing.',
        cost: 'Cost',
        issue: 'Issue with loads',
        difficulty: 'Difficulty of use',
        missingFunctionality: 'Missing functionality',
        notUsing: 'Not using it',
        somethingElse: 'Something else',
        trialExtensionFree: 'EXTEND YOUR FREE TRIAL FOR {{promotionDays}} DAYS',
        trialExtension: ' in addition to the {{daysToExpire}} days you have left.',
        extensionLink: 'Extend my trial',
        continueUnsubscribe: 'No thanks, I still want to ',
        unsubscribeLink: 'unsubscribe.',
        costHeader: 'We understand everybody is on a budget.',
        costBody:
          'Our membership plans are very competitive and can be tailored to meet your needs. You might be paying for features you don’t really need. ' +
          '123Loadboard offers more cost-effective packages that can help you trim the fat. \n\n Contact us and inquire about some of our other packages that might work better for you.',
        missingFunctionalityHeader: 'Not so fast, we might have what you’re looking for.',
        missingFunctionalityBody:
          'Not finding what you’re looking for? A live chat with one of our Customer Support Specialists might point you in the right direction.',
        issueTitle1: 'Can’t find any good paying loads?',
        issueBody1a:
          'Sometimes a broker/shipper may not offer the best rates, or shipping rates have taken a bit of a dive this week. For these issues, we have the ',
        issueBody1b: 'Rate Check ',
        issueBody1c:
          'tool that can help increase your negotiation strength as well as keep you on top of pricing trends in the industry using the latest data. All of our loads also feature a ',
        issueBody1d: 'Backhaul ',
        issueBody1e: 'tool that can help you get back loaded and maximize your earning potential.',
        issueTitle2: 'Can’t book any of the loads I found?',
        issueBody2a:
          'The trucking industry is very competitive and dynamic; some days a load can be gone in the blink of an eye! Loads on 123Loadboard are updated in real-time, so make sure you call loads immediately. Also, posting your truck is a way to get around this with our ',
        issueBody2b: 'Load Alerts',
        issueBody2c:
          ' tool. When you post your truck on 123Loadboard, you will get alerted to loads matching your truck and location. Another way to keep your truck loaded is by using our ',
        issueBody2d: 'Search Alerts',
        issueBody2e:
          '. Have a route you run all the time? You can receive search alerts specifically for that lane whenever a new load is posted.',
        issueTitle3: 'Can’t find a load that matches my truck?',
        issueBody3a: 'Having trouble finding loads? We have many different ways to find freight. The ',
        issueBody3b: 'Load Planner',
        issueBody3c: ' tool can help you go from Point A to Point B and deliver a separate load along the way.',
        issueContactSection:
          'Our Customer Support Specialists can give you advice on how you can optimize your search filters, post a truck, set search alerts, and get you the loads you need for your truck.',
        difficultyHeader: 'Facing problems? We have solutions to help you out.',
        notUsingItHeader:
          '123Loadboard gets you loads across the country to keep your wheels rolling. Let us show you how.',
        somethingElseHeader:
          'Tell us why you want to unsubscribe. We’ll be happy to get in touch with you as soon as possible to resolve any issues you may have.',
        watchTheVideoBody: 'Check out our Overview video to see how 123Loadboard can help you to grow your business.',
        joinFreeWebinarDifficulty:
          'We host weekly live video tutorials with our resident Board Strategists that can help you out of any jam. Join one and see what 123Loadboard can truly offer.',
        joinFreeWebinarNotUsing:
          'Our Board Strategists host regular live presentations to guide you through any questions you may have about the board and show you how it can benefit you.',
        contactSupportBody: 'Want to talk to a real person to solve any difficulties you have? We’re here to help.',
        confirmCheckboxUnsubscribedServices:
          'I understand that my account will be unsubscribed from the service and my load statuses and truck availability for brokers/shippers may be affected, along with my saved searches. ',
        confirmCheckboxAffectDocuments: 'I understand that my documents may be affected by unsubscribing. ',
        confirmCheckboxOngoingDiscounts: 'I understand that any ongoing discounts ',
        confirmCheckboxOngoingDiscountsDetail: '(you pay ${{price}} for a ${{fullPrice}} plan per month) ',
        confirmCheckboxOngoingDiscountsLost: 'will be lost upon reactivating your membership in the future.',
        cardPopupTitle: 'Do you really want to unsubscribe?',
        cardPopupHeader: 'By unsubscribing, you acknowledge the following:',
        trialExtensionTitle: 'Trial Extension',
        unauthorizedHeader: 'Unsubscribe failed',
        unauthorizedMessage:
          'Only administrators are permitted to make changes to 123Loadboard accounts. Please contact your account administrator for further assistance.',
        trialExtensionSent: 'Trial extension request has been sent!',
        trialExtensionMessage:
          'Our Staff has been notified that you requested an extension to your free trial. You will receive confirmation shortly.',
        contactSupportTitle: 'Customer support',
        contactSupportHeader: 'Contact support',
        contactSupportMessage:
          'Our Customer Support Team is available from 9:00 AM - 5:00 PM EST. Monday to Friday to help you and answer any questions you may have.',
        contactSupportPhone: '(877) 875-5301',
        contactSupportTimeLabel: 'Time',
        contactSupportSchedule: 'Need to schedule a call?',
        contactSupportScheduleMessage:
          'Please select a time (9:00 AM - 5:00 PM EST. Monday to Friday) for one of our Customer Support Representatives to contact you.',
        customerSupportSent: 'Thank you',
        customerSupportMessage:
          'Your call request has been submitted and one of our Customer Support Representatives will contact you between {{time}} EST.',
        detailIssueTitle: 'What would make you come back?',
        unsubscribeFinalizedTitle: 'Unsubscribe completed',
        unsubscribeFinalizedH1: 'We are confirming that:',
        unsubscribeFinalizedMessage0: 'Your request to unsubscribe was successfully submitted.',
        unsubscribeFinalizedMessage1:
          'We have removed your credit card and billing information. There will be no further charges on your account.',
        unsubscribeFinalizedH2: 'Your account will be downgraded to a free “Basic account”',
        unsubscribeFinalizedEmptyDowngradeDate: '.',
        unsubscribeFinalizedH2a: ' effective ',
        unsubscribeFinalizedMessage2:
          'This gives you limited access to load searching, rate check, truck posting, and any documents stored in your account. Your account will only be available through the mobile app. If you haven’t already, please download our mobile app to use your basic membership.',
        unsubscribeFinalizedMessageUpgrade: 'Looking to upgrade your account, ',
        unsubscribeFinalizedMessageUpgradeLink: 'click here',
        unsubscribeBannerMessage:
          'You have unsubscribed from your membership. You will continue to have access until {{date}} . If you wish to re-actvate your account, ',
        radioIssue1: 'Increase load quantity',
        radioIssue2: 'Higher paying loads',
        radioIssue3: 'Accurate load info',
        radioIssue4: 'Hot shot loads',
        radioIssue5: 'Other',
        detailDifficultyTitle: 'What can we improve that would make you come back?',
        detailMissingTitle: 'What features can we add that would make you come back?',
        detailNotUsingTitle: 'Why did you stop using the load board?',
        radioNotUsing1: 'Leased onto a company',
        radioNotUsing2: 'Truck out of service',
        radioNotUsing3: 'Going out of business',
        radioNotUsing4: 'Retiring',
        radioNotUsing5: 'Other',
        detailSomethingElseTitle: 'Where are you getting your loads now?',
        radioSomethingElse1: 'DAT',
        radioSomethingElse2: 'Truckstop',
        radioSomethingElse3: 'Dispatcher',
        radioSomethingElse4: 'Brokers',
        radioSomethingElse5: 'Another load board',
        radioSomethingElse6: 'Other',
        unsubscribeFailedTitle: 'Something went wrong - but don’t worry!',
        unsubscribeFailedDescription:
          'There was a problem processing your request to unsubscribe. Our Customer Support team will unsubscribe the account for you and you will receive an email shortly confirming this.',
        unsubscribeFailedFurtherQuestions:
          'If you have any questions or require further assistance, please contact our Customer Support team at ',
        joinFreeWebinar: 'Join free webinar',
        watchTheVideo: 'Watch the video',
        openLiveChat: 'Open live chat',
        contactSupport: 'Contact support',
        buttonNext: 'Next',
        buttonContinue: 'Continue',
        buttonDontUnsubscribe: "Don't unsubscribe",
        buttonUnsubscribe: 'Unsubscribe',
        buttonSubmit: 'Submit',
        buttonOK: 'OK',
        comment: 'Comment',
        phone: 'Phone',
        retentionPromo: {
          title: "Wait, don't leave!",
          content:
            "You're a very valuable member and we truly appreciate you being a part of 123Loadboard. Please give us another chance with a  ",
          contentMore: ' on your membership for the next {{period}}.',
          monthlyDiscount: '{{amount}} monthly discount',
          accept: 'Accept {{amount}} discount',
          applied: 'Discount applied',
          thanksForStaying: 'Thanks for staying!',
          confirmationText: 'Accepting our offer and giving us another shot means a lot to us. Your ',
          confirmationTextMore: ' has been applied to your membership for the next {{period}}.',
          discount: '{{amount}} discount',
          nextBillingText: 'Your discount is now activated and will apply to your next billing on {{billingDate}}.',
        },
        reasonsToStay: {
          title: 'Top reasons to keep your 123Loadboard membership',
          reason1: {
            text: 'Saves you hundreds of dollars per year compared to other boards, keeping money in your wallet.',
          },
          reason2: {
            text1: 'Access to many ',
            link: 'tools and services ',
            text2: 'the other guys nickel-and-dime you for.',
          },
          reason3: {
            text1: 'Our ',
            link: 'Customer Success Team ',
            text2: 'helps members find the loads they are looking for.',
          },
          continueUsing: {
            text1: 'Would you like to ',
            link1: 'continue using ',
            text2: '123Loadboard or would you still like to ',
            link2: 'unsubscribe',
            text3: '?',
          },
        },
        manageSubscription: 'Manage subscription',
        applePopup:
          'Your 123Loadboard account is managed through the App Store. To make changes to your subscription, please do so through the App Store settings on your Apple device settings.',
        openSettings: 'Open settings',
      },
      settings: {
        title: 'Settings',
        account: {
          title: 'Account',
          profile: 'Profile',
          updateProfile: {
            snackBarSuccess: 'Profile Updated',
            snackBarFailure: 'Profile failed to update',
          },
          editBusinessProfile: 'Edit business profile',
          editMyProfile: 'Edit my profile',
          company: 'Company',
          users: 'Users',
          postingContact: 'Posting contact',
          postingContacts: 'Posting contacts',
          dispatchers: {
            addDispatcher: 'Add dispatcher',
            noDispatcher: 'No dispatcher on your account',
            description: 'You have no dispatcher contact details assigned. Please add some to your account.',
            info: 'Info',
            updateDispatcher: 'If you wish to update your Dispatcher contact info, please call ',
          },
          type: 'Type',
          types: {
            all: 'All',
            admin: 'Admin',
            user: 'User',
          },
          user: {
            addUser: 'Add User',
            noUser: 'No users on your account',
            description: 'You have no user contact details assigned. Please add some to your account.',
            sendRequest: 'Send request',
            deleteUser: {
              title: 'Delete user',
              message: 'Are you sure you want to delete this user?',
              messageCanNotDelete:
                'You cannot delete your own user profile. Please call (877) 875-5301 for assistance.',
            },
            editUser: 'Edit User',
            updateUser: {
              snackBarSuccess: 'User Updated',
              snackBarFailure: 'User failed to update',
              description: 'Please contact your administrator to make changes to this account',
            },
            type: 'Type: {{value}}',
            requestToAddUser: {
              title: 'Request sent',
              message:
                'We have received your request to add a user to your account. You will receive a confirmation email when the user is added.',
            },
            lastLogin: 'Last login: {{date}}',
          },
          companyPanel: {
            snackBarSuccess: 'Company Updated',
            snackBarFailure: 'Company failed to update',
          },
        },
        businessProfile: {
          dot: 'DOT#',
          title: 'Business profile',
          addressTitle: 'Address:',
          companyPhoneTitle: 'Company phone:',
          companyNameTitle: 'Company name:',
          dotNumberTitle: 'DOT#:',
          mcNumberTitle: 'MC#:',
          onboardingUrl: {
            title: 'Onboarding link:',
            buttonLabelAdd: 'Add link',
            buttonLabelEdit: 'Edit link',
            buttonLabelEditOnboarded: 'Edit onboarded carriers',
            popupTitleAdd: 'Add onboarding link',
            popupTitleEdit: 'Edit onboarding link',
            popupContent:
              'Please enter your carrier onboarding link. This link will be available on all of your posted loads for carriers not on your onboarded carrier list.',
            fieldLabel: 'Onboarding link *',
            linkAdded: 'Link added',
            messages: {
              success: 'Onboarding link successfully uploaded',
              failure: 'Oops. Something went wrong',
            },
          },
          dotBusinessProfileOption: {
            noDot: {
              title: 'Use FMCSA company details',
              text: 'Please enter your DOT# above to activate FMCSA company details.',
            },
            noInformationAvailable: {
              title: 'Use FMCSA company details',
              text: 'We were unable to find information for the entered DOT#.',
            },
            customProfile: {
              title: 'Use custom company details',
            },
            volpeProfile: {
              title: 'Use FMCSA company details',
            },
          },
        },
        dotVerification: {
          title: 'DOT# verification',
          tooltip: "DOT# verification: Confirms user's access to the FMCSA-listed company phone number.",
          carrierNotVerified: 'Carrier DOT# not verified. Consider this when reaching out.',
          verifyYourDot: 'Verify your DOT# ',
          betterOpportunities: ' for better opportunities with brokers and shippers.',
          dotNumberVerified: 'DOT# verified',
          verifyDotNumber: 'Verify DOT#',
          verifyYourDotNumber: 'Verify your DOT#',
          pleaseEnterDot: 'Please enter DOT#.',
          phoneListPanelText:
            'Brokers and shippers prefer to work with Carriers that have verified their DOT#. You can verify your DOT# via phone confirmation. Please select a phone number that you have access to.',
          phoneListPanelSelectNumber: 'Select phone number',
          verificationCodeWillBeSentTo: 'You can verify your DOT# with a confirmation code that will be sent to',
          selectActivationMethod: 'Please select your preferred activation method.',
          selectVerificationMethodTitle: 'Select verification method',
          enterCode: 'Enter code',
          errorPanel: {
            title: 'Verification error',
            unableToFindByDot: 'We were unable to find information for the entered DOT#:',
            makeSureDotEntered: 'Please make sure your DOT# is entered correctly or try again later.',
          },
          resultPanel: {
            successfullyVerified: 'Your DOT number has been successfully verified.',
          },
        },
        billing: {
          title: 'Billing',
          billingSummary: 'Billing Summary',
          summary: {
            billingCycle: 'Billing cycle',
            nextBillingDate: 'Next billing date',
            membershipFee: 'Membership fee',
            membershipBilledBy: 'Billed by',
            membershipBilledByAppStore: 'App Store',
            addOn: 'Add-ons',
            currentCost: 'Current membership cost',
            monthly: 'Monthly',
            yearly: 'Yearly',
          },
          paymentHistory: 'Payment history',
          datesFilterTitle: 'Filter by dates',
          invoiceTitle: 'Invoice {{invoiceNo}}',
          paymentMethod: 'Payment method',
          invoicesNotFound: 'No invoices',
          editPaymentMethod: 'Edit payment method',
          membershipPlan: {
            noPermissions: `You don't have the necessary permissions to make changes.`,
            pleaseContactAccountOwner: 'Please contact the account owner',
            changesToMembership: 'to request changes to this membership.',
            noPermissionsToEditPaymentMethod: `You don't have necessary permissions to edit payment method. Please contact the account owner`,
            requestChanges: 'to request changes.',
            ifYouRequireAssistance: 'If you require additional assistance, please contact Support at',
            phoneNumber: '877-875-5301',
            workTime: '(Mon-Fri 8am-6pm EST).',
          },
          paymentDeclineNotice: {
            title: 'Payment failure',
            expiringSoon:
              'We were unable to process payments for an overdue invoice on your account. Please make a payment to keep full access to your account.',
            nearingExpiration:
              'We were unable to process payments for an overdue invoice on your account. Please make a payment within the next ',
            nearingExpirationSecond: 'to keep full access to your account.',
            expired:
              'We were unable to process payments for an overdue invoice on your account. Your membership is currently suspended. Please provide a new form of payment to restore full access to your account.',
            hardDecline:
              'Your card ending in {{card}} cannot be processed. Please replace card or correct card information.',
            softDecline:
              'There was an issue processing your card ending in {{card}}. Please make sure that the card has funds available or provide a new payment method. We will reattempt the transaction at a later date.',
          },
        },
        preferences: {
          title: 'Preferences',
          loadVisibilityRules: 'Load visibility rules',
          notifications: 'Notifications',
          emailNotificationSelection: 'Select the emails that you wish to receive.',
          alertsSound: {
            title: 'Alerts sound',
            description: 'Choose which alerts play a sound when new content arrives.',
            options: {
              messageAlerts: 'Message alerts',
            },
            updateSuccess: 'Alerts sound was updated',
            updateFail: 'Alerts sound was not updated',
          },
          messages: {
            title: 'Messages',
            browserNotification: 'Browser notifications settings',
            disablingTitle: 'Disabling notifications',
            disablingMessage: 'In order to turn off notifications, please ',
            disablingMessageEnd: ' for browser-specific instructions.',
          },
          updates: {
            title: 'Updates',
            announcements: 'Announcements (new features, etc)',
            newsletters: 'Newsletters (members news, etc)',
            promotions: 'Promotions (offers, discounts, etc)',
            snackBarSuccess: 'Notifications updated successfully',
            snackBarFailure: 'Notifications failed to update',
          },
          alertsLeads: {
            title: 'Alerts/Leads',
            loadTruckMatching: 'Instant load/truck matching',
          },
          mileageAndRouting: {
            title: 'Mileage and routing',
            basic: 'Basic Options',
            advance: 'Advanced options',
            vehicleDimension: 'Vehicle dimension',
            saveSettings: 'Apply and save settings',
            success: 'Updated successfully',
            error: 'Failed to update',
            routeMethod: {
              title: 'Route method',
              shortest: 'Shortest',
              practical: 'Practical',
            },
            tolls: {
              title: 'Tolls',
              avoid: 'Avoid tolls (if possible)',
              included: 'Tolls can be included',
              tollCost: 'Toll costs',
              axles: 'By axles',
              weight: 'By weight',
              null: 'Null',
            },
            mapDetail: {
              title: 'Map detail level',
            },
            legend: {
              title: 'Legend on the map',
              show: 'Show legend',
              hide: "Don't show legend",
            },
            units: {
              title: 'Units',
              english: 'English',
              metric: 'Metric',
            },
            dimension: {
              height: 'Height',
              length: 'Length',
              feet: 'Feet',
              inch: 'Inches',
              meters: 'Meters',
              invalidHeight: 'Invalid height',
              invalidLength: 'Invalid length',
              widthMeter: 'Width in Meters',
              widthInch: 'Width in Inches',
              meterOrLess: '2.44 or Less',
              meterOrMore: '2.59 or More',
              inchOrLess: '96" or Less',
              inchOrMore: '102" or More',
              weightLbs: 'Weight in Lbs',
              weightKgs: 'Weight in Kgs',
            },
            hazmat: {
              title: 'Hazmat',
              type: 'Hazmat type',
              general: 'General',
              caustic: 'Caustic',
              explosive: 'Explosives',
              none: 'None',
              flammable: 'Flammable',
              inhalants: 'Inhalants',
              radioactive: 'Radioactive',
            },
            border: {
              international: 'International',
              border: 'Cross-over borders',
            },
          },
          searchResults: {
            title: 'Search results',
            averageRateHeader: 'Rate Check',
            averageRateLabel: 'RATE CHECK',
            checkboxLabel: 'Display average rate information on load results',
            snackBarSuccess: 'Rate Check setting updated successfully',
            snackBarFailure: 'Rate Check setting failed to update',
          },
          manageFuelMileage: 'Manage fuel mileage (MPG)',
          mpg: {
            description: 'Enter your MPG for more accurate mileage and income estimates',
            snackBarSuccess: 'Your MPG was updated successfully',
            snackBarFailure: 'Your MPG failed to update',
            title: 'MPG',
          },

          privacyControl: 'Privacy control',
          exportingLoadDetails: {
            title: 'Exporting load details',
            exportLoadDetails: 'Export load details',
            description: 'Select your exporting options',
            cota: 'COTA',
            export: 'Export',
            exportingToCota: 'Allow load exporting to COTA',
            popUpCota: {
              part1:
                'COTA is a dispatching software that helps you manage your load from pickup to delivery and includes features like tracking and invoicing.',
              part2: 'Please visit ',
              part3: 'www.cotasystem.com',
              part4: ' for more information.',
              part5: 'To learn more about how 123Loadboard works with COTA, please visit our ',
              part6: 'FAQ.',
            },
            snackBarSuccess: 'Exporting load details settings updated successfully',
            snackBarFailure: 'Exporting load details settings failed to update',
          },
          hotLeads: {
            title: 'Hot leads',
            snackBarSuccess: 'Hot leads updated successfully',
            snackBarFailure: 'Hot leads failed to update',
          },
        },
        legal: {
          title: 'Legal',
          termsOfService: 'Terms of service',
          privacyPolicy: 'Privacy policy',
          openSourceLicenses: 'Open source licenses',
        },
        receiveHotLeadCalls: 'Receive calls from brokers and shippers based on your search data',
        postingContacts: {
          postingContact: 'Posting contact',
          postingContacts: 'Posting contacts',
          addContact: 'Add contact',
          editContact: 'Edit contact',
          addNew: 'Add new',
          postingContactInfo: 'Posting contact info',
          default: 'Default',
          pleaseContactPostingDepartment: 'Please contact our Posting department',
          toMakeChangesToDefaultContact: 'to make changes to the default posting contact.',
          manageYourPostingContacts: 'Manage your posting contacts',
          postingDepartmentPhone: '877-875-5301 ext.2',
          popups: {
            delete: {
              title: 'Delete Posting Contact',
              warning: 'Are you sure that you want to delete this posting contact?',
            },
            manageContacts: {
              title: 'Manage posting contacts',
              content:
                'Posting Contacts are the point-of-contact for carriers inquiring about your posted loads. This section allows you to quickly create, edit, and assign specific Posting Contacts to loads.',
              warning: 'Any changes to Posting Contacts do not apply to loads already posted.',
            },
          },
          snackbars: {
            contactAdded: 'Posting contact added',
            contactUpdated: 'Posting contact updated',
            contactDeleted: 'Posting contact deleted',
          },
        },
      },
      intg: {
        clients: {
          empty: 'No client applications',
          emptyParagraph:
            'The client application list is currently empty. To create a client application, please contact the integrations team at integrations@123loadboard.com',
        },
        client: {
          notFound: 'Client not found',
          logo: {
            popupTitle: 'Remove logo',
            popupContent: 'Are you sure you want to remove your logo?',
            infoTitle: 'Client Logo Info',
            infoText1:
              "The client logo will be displayed to your customers when they try to login to our platform through your application. Click on the 'Try it out!' button to see this page.",
            infoText2: 'The logo will be cropped to 4:3 and should have a width around 1024px.',
          },
          nameInfoTitle: 'Client Name Info',
          nameInfoText1:
            'The client name will be displayed to your customers when they try to login to our platform through your application.',
          nameFieldPrompt: 'Please fill out the field below to edit this client application name.',
          nameTitle: 'Client application name',
          nameSaved: 'Client name saved',
          invalidName: 'Invalid client name',
          manageURIsPrompt: 'Add or remove your redirect URI(s)',
          removeURILabel: 'Delete redirect URI',
          removeURIPrompt: 'Are you sure you want to delete this redirect URI?',
          uriRemoved: 'URI removed',
          cannotRemoveLastURI: 'You cannot delete the only URI. Please add a new one if you want to delete this one.',
          uriFieldPrompt: 'Please enter the redirect URI you would like to add',
          uriFieldLabel: 'URI path',
          uriAdded: 'New URI added',
          invalidURI: 'Invalid redirect URI',
          regenerateLabel: 'Regenerate credentials',
          regneratePrompt: 'Are you sure you want to regenerate your credentials?',
          regenerateSuccess: 'Client credentials regenerated',
          secretCopied: 'Client secret copied to clipboard',
        },
      },
      menu: {
        account: {
          title: 'Account',
          signin: 'Sign in',
          register: 'Sign up',
        },
        carrier: 'Carrier',
        broker: 'Broker/Shipper',
        brokerSmall: 'Broker \n Shipper',
        integrator: 'Integrator',
        companies: {
          myCompanies: 'Companies',
        },
        main: {
          title: 'Main',
          dashboard: 'Dashboard',
          alerts: 'Alerts',
          messages: 'Messages',
        },
        environments: {
          title: 'Clients',
          dev: 'Dev',
          live: 'Live',
        },
        loads: {
          title: 'Loads',
          findLoads: 'Find loads',
          loadAvailability: 'Load availability',
          tripBuilder: 'Trip builder',
          loadPlanner: 'Load planner',
          myLoads: 'My loads',
          myCompanies: 'My companies',
        },
        myLoads: {
          title: 'My Loads',
          post: 'Post Loads',
          uploadPosts: 'Upload Posts',
          exportPosts: 'Export Posts',
        },
        myTrucks: {
          title: 'My Trucks',
          post: 'Post truck',
        },
        findTrucks: {
          title: 'Find Trucks',
          search: 'Truck locator',
          myCarriers: 'Carriers',
        },
        tools: {
          title: 'Tools',
          profitCalculator: 'Profit calculator',
          rateCheck: 'Rate check',
          carrierMonitor: 'Carrier Monitor',
          hotLeads: 'Hot Leads',
          mileageCalculator: 'Mileage calculator',
          myDocuments: 'My Documents',
        },
        services: {
          title: 'Services',
          getPaidFaster: 'Get paid faster',
          saveAtThePump: 'Save at the pump',
          roadsideAssistance: 'Roadside assistance',
          truckingAuthority: 'Trucking authority',
        },
        integrationServices: {
          title: 'Integration Services',
          tmsIntegration: 'TMS Integration',
          htmlEmbed: 'HTML Embed Code',
          postingAid: 'Posting Aid Software',
        },
        resources: {
          title: 'Resources',
          fuelPrices: 'Fuel prices',
          fmcsaSearch: 'FMCSA search',
          creditReport: 'Credit report',
          searchDirectory: 'Search directory',
          reeferRates: 'Reefer rates',
          borderWaitTimes: 'Border wait times',
        },
        more: {
          title: 'More',
          settings: 'Settings',
          help: 'Help',
          logout: 'Logout',
        },
        mileageCalculatorPanel: {
          originLabel: 'Origin',
          destinationLabel: 'Destination',
          addStop: 'Add stops',
          editStop: 'Edit stop',
          Stops: 'Stops',
          Stop: 'Stop {{index}}: ',
          Origin: 'Origin: ',
          Dest: 'Dest: ',
          csvFileName: 'StateMileageReport',
          routeInfo: 'Route info',
          iftaReport: 'IFTA report',
          Start: 'Start: ',
          End: 'End: ',
          totalDistance: 'Total distance: ',
          totalDrivingTime: 'Total driving time: ',
          totalTollCost: 'Total toll cost: ',
          stateMileageReport: 'State by state mileage report',
          profitCalculator: 'Profit calculator',
          profitInfo: 'Profit $-',
          departureFrom: 'Departure from: ',
          arriavalTo: 'Arrival to: ',
          upgradePlan: 'Upgrade your plan',
          downloadCSV: 'Download state mileage report (IFTA)',
          interactiveMap: 'Interactive map',
        },
      },
      errors: {
        not_found: {
          Title: 'Page Not Found',
          Message: "It seems we can't find the page you're looking for.",
          Sub_Message: 'Either something went wrong, or the page no longer exists.',
        },
        no_component: {
          Title: "Page couldn't load",
          Message: "It seems we can't load this page at this moment.",
          Sub_Message: 'Try to reload the page. If the problem persist, please contact support.',
          Button: 'Reload page',
        },
        systemEncounteredError: 'Our system encountered an error.',
      },
      snackbars: {
        companyFavorited: 'Company added to your favorites',
        companyUnfavorited: 'Company removed from your favorites',
        companyBlocked: 'Company is now blocked',
        companyUnblocked: 'Company is unblocked',
        companyOnboarded: 'Company marked as onboarded',
        companyUnonboarded: 'Company removed from onboarded',
      },
      feed: {
        Feed: 'Feed',
        Videos: 'Videos',
        Help: 'Help',
        comingSoon: {
          title: 'Coming soon',
          subtitle0: 'We are currently working on this video tutorial, stay tuned!',
          subtitle1: 'In the meantime, please ',
          subtitle2: ' or check out our help page.',
          linkText: 'watch our other videos',
        },
      },
      postedLoadStatus: {
        assigned: {
          title: 'Assigned',
          description: 'Assign this load to a carrier.',
          assignedTo: 'Assigned to DOT#: ',
        },
        delivered: {
          title: 'Delivered',
          description: 'Mark this load as "Delivered" for future reference.',
        },
        unassigned: 'Unassigned',
        load_assigned: 'Load assigned',
        popup: {
          title: 'Assign carrier',
          content: 'Please enter the Carrier’s DOT number to assign this load.',
          dot: 'DOT#',
          assign: 'Assign',
          success: 'Load assigned',
          unable: 'Unable to assign',
          multiple: 'This load cannot be assigned as it has multiple quantities.',
        },
        unassign: {
          title: 'Unassign',
          question: 'Are you sure you want to unassign this load, or would you like to assign a new carrier?',
          options: 'If you’d still like to unassign the load, please choose one of the following options: ',
          online: 'Unassign and post online',
          offline: 'Unassign and post offline',
          delete: 'Unassign and delete',
        },
      },
      accountSetup: {
        title: 'Hello, {{name}}.',
        description: 'Get immediate access to your account by selecting a plan and entering your billing details.',
        brokerDescription:
          'Your account status is currently inactive.\n\nGet immediate access to your account by entering your billing details.',
        promoCode: {
          title: '{{daysCount}} days free',
          titleDescription: 'Then ${{price}} per month',
          addPromoCode: 'Add promo code',
          description: 'Please enter your promo code',
          label: 'Promo code',
          details1: 'You unlocked a {{daysCount}} day trial and {{percent}}% discount',
          details2: 'You unlocked a {{daysCount}} day trial',
          details3: 'You unlocked a {{percent}}% discount',
          invalidCodeError: 'Invalid promo code',
          invalidCodeForPlan: 'This promo code is available only for {{plans}} plan(s)',
        },
        setPlanAccessError: {
          message1: 'Your account status has been put on hold.',
          message2: 'Your monthly payment was unable to be charged.',
          message3: 'You can get immediate access to your account by updating your billing information.',
        },
        changePlan: 'Change plan',
        subtotal: 'Subtotal',
        totalDay: 'Total due today',
        totalAfterTrial: 'Total after trial/per month',
        totalAfterPromo: 'Monthly total after promo',
        notAdded: 'Not added',
        trialDescription:
          'After your trial ends, you will be charged ${{price}} per month starting {{date}}. You may unsubscribe at any time.',
        user: 'User',
        activate: 'Activate',
        refresh: 'Refresh',
        permissionFailed: 'Failed to process payment',
        fetchingDataFailed: 'Server connection error',
        fetchingDataFailedPhone: '877-875-5301 ext. 2',
        fetchingDataFailedDescription:
          'An error occurred while loading your user information. Please refresh the page to try again.',
        fetchingDataFailedContact:
          'If you’re still experiencing issues reactivating your account, please contact Support at',
        needHelpText: 'Need help with your account? Call us',
        accountStatus: 'Account status',
        accessDeniedPermission1:
          'Access to 123Loadboard is currently unavailable. You don’t have the necessary permissions to edit payment method. Please notify the account owner',
        accessDeniedPermission2: 'to update the payment method.',
        editPayment: 'Enter payment details',
        tax: 'Tax',
        errors: {
          status: 'A reactivation error has occurred, to reactivate please call support',
          invoice: 'There was an error processing your payment',
        },
        statusMessage: {
          MobileSignUpInProgress: {
            title: 'Access denied for free Basic accounts',
            description:
              'Access to the 123Loadboard website is only available with a paid membership. Please select a plan below.',
          },
          SignUpInProgress: {
            title: 'Failed to process billing information',
            description: 'Your billing information was not processed correctly, please try again.',
          },
          PendingPayment: {
            title: 'Your payment failed to process',
            description:
              'We were not able to process your payment, access to the 123Loadboard website is only available with a paid membership. Please update your billing information.',
          },
          Expired: {
            title: 'Your account is currently closed.',
            description:
              'Access to the 123Loadboard website is only available with a paid membership. Please select a plan below.',
          },
          OnHold: {
            title: 'Your account is currently closed.',
            description: 'To reopen your account, please re-enter your billing information below.',
          },
          BillingOnHold: {
            title: 'Your account is currently closed.',
            description: 'Please update your billing information.',
          },
          SecondaryUserNonAdmin: {
            title: 'Failed to process payment',
            description: 'To gain access, please ask your account Administrator to update the billing information.',
          },
          NoBillingStatus: {
            title: 'Your account is currently closed.',
            description:
              'Access to the 123Loadboard website is only available with a paid membership. Please select a plan below.',
          },
        },
        reactivateActionResult: {
          success: 'Membership plan was activated',
          fail: 'Membership plan activation failed',
        },
      },
      loadVisibilityRules: {
        customRule: 'Custom rule: ',
        checkboxPostWithVisibilityRules: 'Post with visibility rules',
        changeRule: 'Change rule',
        postWithVisibilityRules: 'Post with visibility rules',
        postFormContent: 'Load visibility rules allow you to control which carriers can see your loads.',
        postFormMoreContent:
          'Restrict the audience to onboarded carriers only, or use criteria like insurance amount or authority age to limit load visibility.',
        anyCarrier: 'Any carrier',
        ofAuthority: ' {{value}} of authority',
        valueInsurance: ' {{value}} insurance',
        onboarded: ' onboarded',
        withAtLeast: 'with at least',
        andAtLeast: 'and at least',
        atPostingTime: 'At posting time,',
        willBeVisibleTo: 'the load will be visible to:',
        willAlsoBeVisibleTo: 'the load will also be visible to:',
        afterPostingTime: 'after posting, the load will',
        beVisibleTo: 'be visible to:',
        also: 'also',
        carrierWhoIsOnboarded: 'Any carrier who is',
        default: 'Default',
        emptyContent:
          'Load visibility rules allow you to control which carriers can see your loads based on criteria like insurance level, onboarding status, authority age, etc.\n\nYou can also set up cascading load visibility rules to, over time, expand the range of carriers able to see your loads.\n\nThese load visibility rules can be applied when posting loads manually.',
        content: 'Load visibility rules control which carriers can see your freight.',
        moreContent: 'Start by setting up some rules here. You may then apply these rules when posting loads.',
        makeDefault: 'Make default',
        addRule: 'Add rule',
        createNewRule: 'Create new rule',
        addNextCondition: 'Add next condition',
        typeOfCarriers: 'Type of carriers',
        allCarriers: 'All Carriers',
        onboardedType: 'Onboarded',
        minInsuranceField: 'Min. Insurance ($)',
        minAuthorityAge: 'Min. Authority age',
        years: 'Year(s)',
        months: 'Month(s)',
        deleteFinalRuleContent: 'Removing this condition will delete this rule.',
        deleteRuleContent: 'This will not affect currently posted loads.',
        editRule: 'Edit rule',
        deleteRuleset: 'Delete rule',
        deleteRulesetContent: 'Deleting will remove all created conditions within this rule.',
        days: 'Days',
        minutes: 'Minutes',
        hours: 'Hours',
        ruleSaved: 'Rule saved',
        ruleRemoved: 'Rule removed',
        ruleUpdated: 'Rule updated',
        timeInDay: '{{day}} day ',
        timeInDays: '{{day}} days ',
        timeInHour: '{{hour}} hour ',
        timeInHours: '{{hour}} hours ',
        timeInMins: '{{mins}} minutes',
        timeInMin: '{{mins}} minute',
        ageInyear: '{{year}} year ',
        ageInMonth: '{{months}} month',
        ageInyears: '{{year}} years ',
        ageInMonths: '{{months}} months',
        onboardedCarriers: 'Onboarded carriers',
        onboardedCarriersContent:
          'To select this option, you must set up your onboarded carriers list.\n\nYou may set up your onboarded carriers list at any time in Settings.',
        setupCarriers: 'Set up onboarded carriers list',
        dateAtTime: ' {{date}} at {{time}}, ',
        visibleTo: 'visible to:',
        since: 'Since',
        on: 'On',
        defaultRuleContent: 'You may create custom load visibility rules to control which carriers can see your loads.',
        assignRule: 'Assign rule',
        createRule: 'Create rule',
        assignRulePopupContent:
          'Load visibility rules must be created before they can be assigned.\n\nRules can be created at any time through the Load visibility rules section in Settings.\n\nYou will lose all entered information for this load if you choose to create a load visibility rule at this time.',
        infoPopupContent:
          'Load visibility rules allow you to control which carriers can see your loads based on criteria like insurance level, onboarding status, authority age, etc.\n\nYou can also set up cascading load visibility rules to, over time, expand the range of carriers able to see your loads.',
        selectRule: 'Select rule',
        selectRulePopup:
          'This load will be visible to any carriers using 123Loadboard.\n\nIf you wish, you may select a load visibility rule to apply to this load.',
        timerFromCurrentTime: 'Set a timer for your new rule. This timer will start from the current time.',
        offlineLoadWarning:
          'Load visibility rules are paused for Offline loads. Posting the load Online will reactivate these rules.',
        addCondition: 'Add condition',
        editCondition: 'Edit condition',
        createRuleInfoSection:
          'If you apply this rule to a load, only carriers matching these conditions will be able to see it.',
        conditionIndex: 'Condition #{{index}}',
        addNextConditionInfo: '* New conditions will be applied in addition to previous ones.',
        ruleTimerInfoOne: 'This condition will become active ',
        ruleTimerInfoTwo: ' after load has been posted.',
        ruleTimerInfoTwoAlt: ' after changes are applied.',
        manageRules: '* You can manage rules in Settings > Load visibility rules.',
      },
      onboardedCarriers: {
        source: 'Source',
        manageOnboardedCarriers:
          'Manage your manually entered DOT numbers on 123Loadboard. Modify DOT numbers from other sources at the respective source.',
        addOrRemove: 'Add/Remove',
        addUSdot: 'Add USDOT#',
        deleteAll: 'Delete all',
        deleteAllText: 'Are you sure you want to delete all?',
        emptyPanelTitle: 'Add your onboarded carriers',
        successfullyAdded: '{{number}} USDOT# added to your Onboarded carriers list successfully.',
        filterTitle: 'Filter by USDOT#',
        emptyPanelsubtitle:
          'Add onboarded carriers to your network by entering in their USDOT numbers.\n\nWhen you post loads, you may prioritize visibility of your loads to only your onboarded carriers.',
        editPanelContent:
          'Add companies to your Onboarded carrier list by adding one or more USDOT# on individual lines or separated by commas. Deleting a USDOT# removes the company from your list.',
        warningMessageTotal: 'From {{total}} USDOT# records',
        warningMessageDuplicates: '{{count}} duplicates removed',
        warningMessageInvalid: '{{failedCount}} failed validation.',
        usdotSubmission: 'USDOT# submission',
        editListUpdated: 'Your Onboarded carriers list has been updated successfully.',
        textFieldTitle:
          'Add companies to your Onboarded carrier list by adding one or more USDOT# on individual lines or separated by commas. Deleting a USDOT# removes the company from your list.',
        usDotLabel: 'USDOT#',
        validationFailed: 'Validation failed',
        review: 'Review',
        listUpdated: 'List updated',
      },
      welcomebackCampaign: {
        welcomeback: 'Welcome back',
        title: 'While you were away, we heard your feedback and made big improvements.',
        welcomeBackTitle: 'Welcome back - Reactivate your membership with a 50% discount!',
        tableTitle: 'Based on your search history, we have these loads available for you now.',
        promoCode: 'Promo code:',
        origin: 'Origin',
        destination: 'Destination',
        pickupDate: 'Pickup date',
        equipment: 'Equipment',
        size: 'Size',
        Length: 'Length',
        weight: 'Weight',
        reactivateAccount: 'Reactivate account to view details',
        reactivateAccountForLoads: 'Reactivate account to see more loads',
        promoTitle: 'Navigate the road to success with 123Loadboard - Now at half the price',
        promoText:
          'Exclusive Back-on-Board offer: save 50% on any plan for your first 3 months. Use this promo code to unlock your exclusive offer.',
        unlockOfferBtn: 'Unlock offer now',
        moreBrokerIntgTitle: 'More broker integrations than ever before',
        moreBrokerIntgSubtitle:
          "Now more than ever, it's important to put all the chances on your side. Expand your network and find more loads.",
        marketRatesTitle: 'New and improved market rates',
        marketRatesSubtitle:
          'Give yourself the edge. Information is power. Know what the market is like and have the upper hand when negotiating rates.',
        betterLoads: 'Better loads',
        betterLoadsSubtitle:
          "Don't waste precious time finding loads. A growing number of loads now come with rates, with the option to book, bid, or send instant messages directly through your app.",
        abridgedPromoTitle: 'Take a look for yourself!',
      },
      locationRequest: {
        title: 'Location request',
        content: 'This will send a request for {{name}} to send their current location.',
        label: 'Request location',
        success: 'Request sent',
        failure: 'Request failed',
        sentLocationSuccessfully: 'Location sent',
      },
    },
  },
};

import React from 'react';

import styled from 'styled-components';

import { displayCurrencyOrDash } from '@common/formatter';
import { CargoChiefRate } from '@common/model';
import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import { DetailRow } from '@component/panels/basePanel/loadDetails/generalInfo/LoadDetailsInfoBar';
import { UnlockLink } from '@component/panels/basePanel/loadDetails/generalInfo/UnlockLink';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';
import { useSelector } from '@util/hooks';

interface CargoChiefRateInfoProps {
  isLoadLocationCanada: boolean;
  isEquipmentTypeSupported: boolean;
  cargoChiefRate?: CargoChiefRate;
  isLoading?: boolean;
}

const Text = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  font-style: italic;
  color: ${Color.GRAY_STONE};
`;

export const CargoChiefRateInfo: React.FC<CargoChiefRateInfoProps> = (props) => {
  const isCargoChiefRateAvailable = useSelector((state) => state.user.accesses.isCargoChiefRateAvailable);

  const cargoChiefRatePerMile =
    props.cargoChiefRate?.allInCost && props.cargoChiefRate?.laneMiles
      ? displayCurrencyOrDash(props.cargoChiefRate.allInCost / props.cargoChiefRate.laneMiles, true, {
          suffix: t(T.common_per_miles_short),
        })
      : undefined;

  const renderCargoChiefUnlockLink = () => {
    return <UnlockLink popupType={UnlockFeaturePopupType.CargoChiefRate} />;
  };

  return (
    <DetailRow
      id="cargo_chief_rate"
      title={t(T.common_marketRate_cargoChief_title)}
      subValue={props.isEquipmentTypeSupported && !props.isLoadLocationCanada ? cargoChiefRatePerMile : undefined}
      value={
        props.isLoading ? (
          <Text>{t(T.findLoads_loadDetails_loading)}</Text>
        ) : (
          roleBasedRateContent(
            props.isEquipmentTypeSupported,
            props.isLoadLocationCanada,
            isCargoChiefRateAvailable,
            renderCargoChiefUnlockLink,
            props.cargoChiefRate?.allInCost
          )
        )
      }
    />
  );
};

const roleBasedRateContent = (
  isEquipmentTypeSupported: boolean,
  isLoadLocationCanada: boolean,
  isAllowed: boolean,
  renderUnlockLink: () => JSX.Element,
  value?: number
) => {
  if (!isEquipmentTypeSupported) {
    return <Text>{t(T.common_marketRate_notAvailableTrailer)}</Text>;
  }

  if (isLoadLocationCanada) {
    return <Text>{t(T.common_marketRate_notAvailableOutsideUS)}</Text>;
  }

  if (isAllowed) {
    return value ? displayCurrencyOrDash(value, false) : <Text>{t(T.common_marketRate_notAvailableAtThisTime)}</Text>;
  }

  if (isEquipmentTypeSupported) {
    return renderUnlockLink();
  }

  return <>{NO_INFORMATION_SYMBOL}</>;
};

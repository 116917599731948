import { clone, filter, find, findIndex, map } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { CompanyUserData, TrucksClient } from '@common/client';
import { Geolocation, Truck, TruckLklSearchRequest, TruckRequest, VerificationPointsTaskStatus } from '@common/model';
import { BaseState, Response } from '@common/redux/Base';
import { Matches, splitTrucks, TruckFilters } from '@common/sort';
import { contactIdsToCompanyIds, TruckUpdateAction, TruckUpdateActionTypes } from '@util/TruckDataHelper';

export enum CarrierDetailsTabs {
  Authority = 'Authority',
  CarrierRating = 'CarrierRating',
  Company = 'Company',
}

const FETCH_TRUCKS = 'FETCH_TRUCKS';
const FETCH_TRUCKS_LKL = 'FETCH_TRUCKS_LKL';
const TRUCKS_FETCHED = 'TRUCKS_FETCHED';
const TRUCKS_LKL_FETCHED = 'TRUCKS_LKL_FETCHED';
const UPDATE_TRUCKS = 'UPDATE_TRUCKS';
const SET_SELECTED_TRUCK = 'SET_SELECTED_TRUCK';
const SET_OPEN_CARRIER_DETAILS_TABS = 'SET_OPEN_CARRIER_DETAILS_TABS';

interface TruckLklResponseAction {
  geolocation?: Geolocation;
  trucks: Truck[];
}

export type TruckResponse = Response<Matches>;

interface FetchTruck extends Action {
  request: TruckRequest;
  isInternal: boolean;
}

interface FetchTruckLkl extends Action {
  request: TruckLklSearchRequest;
}

interface FetchTruckResponse extends Action {
  trucks: TruckResponse;
  filters?: TruckFilters;
}

interface FetchTruckLklResponse extends Action {
  trucks: TruckResponse;
  geolocation?: Geolocation;
  filters?: TruckFilters;
}

interface UpdateTrucksAction extends Action {
  updateAction: TruckUpdateAction;
}

interface SetSelectedTruckAction extends Action {
  selectedTruck: Truck | undefined;
}
interface SetOpenCarrierDetailsTabsAction extends Action {
  setting: Partial<CarrierDetailsTabsState>;
}

/** @param isInternal to denote the request is from Internal Truck locator */
export function fetchTrucks(request: TruckRequest, isInternal: boolean): FetchTruck {
  return {
    type: FETCH_TRUCKS,
    request: request,
    isInternal: isInternal,
  };
}

export function fetchTrucksLkl(request: TruckLklSearchRequest): FetchTruckLkl {
  return {
    type: FETCH_TRUCKS_LKL,
    request: request,
  };
}

export const updateTrucks = (updateAction: TruckUpdateAction): UpdateTrucksAction => ({
  type: UPDATE_TRUCKS,
  updateAction: updateAction,
});

export const setSelectedTruck = (truck: Truck | undefined): SetSelectedTruckAction => ({
  type: SET_SELECTED_TRUCK,
  selectedTruck: truck,
});

export const setOpenCarrierDetailsTabs = (
  setting: Partial<CarrierDetailsTabsState>
): SetOpenCarrierDetailsTabsAction => ({
  type: SET_OPEN_CARRIER_DETAILS_TABS,
  setting: setting,
});

type CarrierDetailsTabsState = Record<CarrierDetailsTabs, boolean>;
export interface TruckState extends BaseState {
  trucks?: TruckResponse;
  selectedTruck?: Truck;
  openCarrierDetailsTabs: CarrierDetailsTabsState;
  lklGeolocation?: Geolocation;
}

const initialTruckState: TruckState = {
  isLoading: false,
  openCarrierDetailsTabs: {
    Authority: false,
    CarrierRating: false,
    Company: false,
  },
};

const fetchTrucksResponse = (response: TruckResponse, filters?: TruckFilters): FetchTruckResponse => ({
  type: TRUCKS_FETCHED,
  trucks: response,
  filters: filters,
});

const fetchTrucksLklResponse = (response: TruckResponse, geolocation?: Geolocation): FetchTruckLklResponse => ({
  type: TRUCKS_LKL_FETCHED,
  trucks: response,
  geolocation: geolocation,
});

export const trucksReducer = (state: TruckState = initialTruckState, action: Action): TruckState => {
  switch (action.type) {
    case TRUCKS_FETCHED: {
      const truckResponseAction = action as FetchTruckResponse;
      return {
        ...state,
        trucks: truckResponseAction.trucks,
        isLoading: false,
      };
    }
    case TRUCKS_LKL_FETCHED: {
      const truckResponseAction = action as FetchTruckLklResponse;
      return {
        ...state,
        trucks: truckResponseAction.trucks,
        lklGeolocation: truckResponseAction.geolocation,
        isLoading: false,
      };
    }
    case FETCH_TRUCKS_LKL:
    case FETCH_TRUCKS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_TRUCKS: {
      const updateAction = (action as UpdateTrucksAction).updateAction;
      const trucks = state.trucks?.payload;

      if (!trucks) {
        return state;
      }

      const updatedCompanies =
        updateAction.type === TruckUpdateActionTypes.Onboard
          ? contactIdsToCompanyIds(updateAction.updated, trucks.truckList)
          : updateAction.updated;

      const updatedTrucks = {
        truckList: updateTruckList(updateAction.type, updatedCompanies, trucks.truckList),
        anonymousList: updateTruckList(updateAction.type, updatedCompanies, trucks.anonymousList),
        nonList:
          updateAction.type === TruckUpdateActionTypes.Hide
            ? updateTruckList(updateAction.type, updatedCompanies, trucks.nonList)
            : trucks.nonList,
      };

      return state.trucks
        ? {
            ...state,
            trucks: {
              ...state.trucks,
              payload: updatedTrucks,
            },
          }
        : state;
    }
    case SET_SELECTED_TRUCK: {
      const setSelectedTruckAction = action as SetSelectedTruckAction;
      return {
        ...state,
        selectedTruck: setSelectedTruckAction.selectedTruck,
      };
    }
    case SET_OPEN_CARRIER_DETAILS_TABS: {
      const setOpenTabsAction = action as SetOpenCarrierDetailsTabsAction;
      return {
        ...state,
        openCarrierDetailsTabs: {
          ...state.openCarrierDetailsTabs,
          ...setOpenTabsAction.setting,
        },
      };
    }
    default:
      return state;
  }
};

const updateTruckList = (
  actionType: TruckUpdateActionTypes,
  updatedCompanies: CompanyUserData<boolean>[],
  truckList: Truck[]
): Truck[] => {
  switch (actionType) {
    case TruckUpdateActionTypes.Favorite:
    case TruckUpdateActionTypes.Onboard:
      return map(truckList, (truck) => {
        const userData = find(updatedCompanies, (company) => company.id === truck.companyGuid);
        if (userData) {
          const updatedTruck = clone(truck);
          if (actionType === TruckUpdateActionTypes.Favorite) {
            updatedTruck.isFavoriteCarrier = userData.value;
          } else {
            updatedTruck.onboarded = userData.value;
          }
          return updatedTruck;
        }
        return truck;
      });
    case TruckUpdateActionTypes.Hide:
      return filter(
        truckList,
        (truck) => findIndex(updatedCompanies, (company) => company.id === truck.companyGuid) === -1
      );
    default:
      return truckList;
  }
};

const fetchTruckEpic$ = (client: TrucksClient, action$: ActionsObservable<Action>) =>
  action$.ofType(FETCH_TRUCKS).pipe(
    mergeMap$((fetchTruckRequest) => {
      const truckRequest = fetchTruckRequest as FetchTruck;
      return client.fetchTrucksSearch$(truckRequest.request, truckRequest.isInternal).pipe(
        map$((response: ApiResponse123<Truck[]>) => {
          if (response.success) {
            const successResp = response as ApiResponseSuccess<Truck[]>;
            const matches = splitTrucks(successResp.data ? successResp.data : []);
            return fetchTrucksResponse({ success: response.success, payload: matches }, truckRequest.request.filters);
          } else {
            const error = response as ApiError;
            return fetchTrucksResponse({ success: response.success, error: error });
          }
        })
      );
    })
  );

const fetchTruckLklEpic$ = (client: TrucksClient, action$: ActionsObservable<Action>) =>
  action$.ofType(FETCH_TRUCKS_LKL).pipe(
    mergeMap$((fetchTruckRequest) => {
      const truckRequest = (fetchTruckRequest as FetchTruckLkl).request;
      return client.fetchTrucksSearchLkl$(truckRequest).pipe(
        map$((response: ApiResponse123<TruckLklResponseAction>) => {
          if (response.success) {
            const successResp = response as ApiResponseSuccess<TruckLklResponseAction>;
            const matches = splitTrucks(successResp.data?.trucks ? successResp.data.trucks : []);
            return fetchTrucksLklResponse(
              { success: response.success, payload: matches },
              successResp.data?.geolocation
            );
          } else {
            const error = response as ApiError;
            return fetchTrucksLklResponse({ success: response.success, error: error });
          }
        })
      );
    })
  );

export const createTruckEpic = (api: Api) => {
  const client = new TrucksClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(fetchTruckEpic$(client, action$), fetchTruckLklEpic$(client, action$));
};

export const isPhoneVerified = (verificationStatus?: VerificationPointsTaskStatus) =>
  verificationStatus ? verificationStatus === VerificationPointsTaskStatus.COMPLETED : false;

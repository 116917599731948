import { AuthorityInfo } from './Company';
import { VerificationPointsTaskStatus } from './VerificationPoints';

export enum FMCSAAuthority {
  Common = 'Common',
  Contract = 'Contract',
  Broker = 'Broker',
  CommonRevocation = 'CommonAuthorityRevocation',
  ContractRevocation = 'ContractAuthorityRevocation',
  BrokerRevocation = 'BrokerAuthorityRevocation',
}

export interface CarrierInfo {
  id?: string;
  name?: string;
  companyName?: string;
  phone?: string;
  phoneExtension?: string;
  insuranceOnFile?: string;
  commonAuthority?: string;
  contract?: string;
  docketNumber?: string;
  usdotNumber?: string;
  mcs150FormDate?: string;
  isCarrierIdentityVerified?: boolean; //returns true if DOT verified
  truckCount?: number;
  totalFmcsaInspections?: number;
  phoneVerificationStatus?: VerificationPointsTaskStatus;
}

export interface ContactBrokerInfo {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  phone: string;
  phoneExtension: string;
  brokerAuthority?: AuthorityInfo;
  docketNumber?: string;
  usdotNumber?: string;
  companyCategory?: string;
  isCarrierIdentityVerified?: boolean; //returns true if DOT verified
  phoneVerificationStatus?: VerificationPointsTaskStatus;
}

import React from 'react';

import THEME from '@style/Theme';

import { StyledButton, StyledCheckbox, StyledLabelAfter, StyledLongLabel } from './ControlStyles';

export interface CheckboxResponse {
  isChecked: boolean;
  name: string;
  value: string;
}

interface Props {
  id: string;
  label?: string;
  value?: string;
  name?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  handleChange?: (value: CheckboxResponse) => void;
  labelPosition?: 'before' | 'after';
  labelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  checkboxStyle?: React.CSSProperties;
  spanStyle?: React.CSSProperties;
  indeterminate?: boolean;
  checkboxTabIndex?: number;
  autoFocus?: boolean;
}

export const Checkbox: React.FC<Props> = (props) => {
  const { label, value, name, isDisabled, isChecked, indeterminate, labelStyle, containerStyle, checkboxStyle } = props;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    props.handleChange?.({ isChecked: !isChecked, name: name ?? '', value: value ?? '' });
  };

  const labelStylesIfLabelIsNotAfter =
    props.labelPosition !== 'after' ? { justifyContent: 'space-between', alignSelf: 'stretch' } : undefined;
  const labelStyles = { ...labelStyle, ...labelStylesIfLabelIsNotAfter };

  return (
    <StyledButton
      style={containerStyle}
      id={props.id}
      variant="outlined"
      color="primary"
      disabled={isDisabled}
      onClick={handleClick}
      autoFocus={props.autoFocus}
    >
      <StyledLabelAfter id="label" theme={THEME} style={labelStyles}>
        {props.labelPosition !== 'after' ? <span style={{ paddingTop: 3, ...props.spanStyle }}>{label}</span> : null}
        <StyledCheckbox
          id="check"
          name={name}
          value={value}
          disabled={isDisabled}
          color="primary"
          checked={isChecked}
          indeterminate={indeterminate}
          style={checkboxStyle}
          tabIndex={props.checkboxTabIndex}
        />
        {props.labelPosition === 'after' ? <span style={{ paddingTop: 3, ...props.spanStyle }}>{label}</span> : null}
      </StyledLabelAfter>
    </StyledButton>
  );
};

export const CheckboxLongLabel: React.FC<Props> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.handleChange) {
      const { name, value, checked } = event.target;
      props.handleChange({ isChecked: checked, name: name, value: value });
    }
  };

  const { label, value, name, isDisabled, isChecked, indeterminate } = props;
  return (
    <StyledButton style={{ margin: '8px 0' }} id={props.id} variant="outlined" color="primary" disabled={isDisabled}>
      <StyledLongLabel id="hot_lead_label" style={{ margin: '16px' }} theme={THEME}>
        {label}
        <StyledCheckbox
          style={{ marginLeft: '16px' }}
          id="check"
          name={name}
          value={value}
          disabled={isDisabled}
          color="primary"
          checked={isChecked}
          onChange={handleChange}
          indeterminate={indeterminate}
        />
      </StyledLongLabel>
    </StyledButton>
  );
};

Checkbox.defaultProps = { labelPosition: 'before' };

import React, { useMemo, useRef } from 'react';

import { map } from 'lodash';

import { Load } from '@common/model';
import { LoadDetailsGeneralInfo } from '@component/panels/basePanel/loadDetails/generalInfo/LoadDetailsGeneralInfo';
import { LoadDetailsMoreInfo } from '@component/panels/basePanel/loadDetails/LoadDetailsMoreInfo';
import { LoadContactCallbackProps } from '@component/panels/findLoads/loadDetailsPanel/buttons/ContactButtons';
import { LoadDetailsExportSection } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsExportSection';
import { LoadData, Row } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { showVhubBookPopup } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPopups';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { useScrollIntoView } from '@component/scrollView/scrollUtil';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

interface LoadDetailsDataProps extends LoadContactCallbackProps {
  load: Load;
  width: number | undefined;
  profitPerDay?: number;
  isOriginMessaging: boolean;
  isOriginBids: boolean;
  openProfitCalculator: () => void;
}

export const LoadDetailsData = (props: LoadDetailsDataProps) => {
  const searchLoads = useSelector((state) => state.loadSearch.loads);
  const similarLoads = useSelector((state) => state.loadSearch.similarLoads);
  const backhaulsLoads = useSelector((state) => state.backhauls.loads);
  const loadInfo = useSelector((state) => props.load && state.loadInfo.loadInfoMap.get(props.load.id));

  const notesRef = useRef<HTMLDivElement>(null);

  const scrollIntoView = useScrollIntoView();
  const popupContext = usePopup();

  const similarLoadsList = useMemo(() => map(similarLoads, (similarLoad) => similarLoad.load), [similarLoads]);

  const handleManualExtraStopClick = () => scrollIntoView(notesRef.current);
  const openVhubBookPopup = () => showVhubBookPopup(popupContext, props.load);

  if (props.width === undefined) {
    return null;
  }

  const { dispatchName, postReference, notes, numberOfStops, numberOfLoads, commodity } = props.load;
  return (
    <LoadData width={props.width}>
      <LoadDetailsGeneralInfo
        loadDetails={props.load}
        searchLoads={searchLoads}
        backhaulsLoads={backhaulsLoads}
        similarLoads={similarLoadsList}
        currentLoadId={props.load.id}
        profitPerDay={props.profitPerDay}
        onManualExtraStopClick={handleManualExtraStopClick}
        openProfitCalculator={props.openProfitCalculator}
      />
      <Row>
        <LoadDetailsMoreInfo
          dispatchName={dispatchName}
          commodity={commodity}
          postReference={postReference}
          numberOfLoads={numberOfLoads}
          notes={notes}
          numberOfStops={numberOfStops}
          commodityLabel={t(T.common_load_Item)}
          loadDetails={props.load}
          loadInfo={loadInfo}
          notesRef={notesRef}
          isOriginMessaging={props.isOriginMessaging}
          isOriginBids={props.isOriginBids}
          isBroker={false}
          onVhubClick={openVhubBookPopup}
          onCallClick={props.onCallClick}
          onEmailClick={props.onEmailClick}
          onInteractiveBidClick={props.onInteractiveBidClick}
          onVendorBidClick={props.onVendorBidClick}
          onBookNowClick={props.onBookNowClick}
        />
      </Row>
      <LoadDetailsExportSection loadDetails={props.load} />
    </LoadData>
  );
};

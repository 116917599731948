import React from 'react';

import { includes } from 'lodash';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Grid } from '@material-ui/core';

import { Routes } from '@/router/Routes';
import {
  LOADBOARD_HELP_CARGO_CHIEF,
  LOADBOARD_HELP_MARKET_RATES,
  LOADBOARD_HELP_RATE_CHECK,
} from '@common/info/ExternalLink';
import { MembershipType, UnlockFeaturePopupType } from '@common/model/UserPlan';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupCallButton, PopupLabelButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { Text, TextStyle } from '@component/text/Text';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import { MediaQueries } from '@util/MediaQueries';

import CargoChiefFeature from './cargo-chief-screenshot.png';
import { PopupSizes, usePopup } from './PopupTrackingContext';
import RateCheckFeature from './rate-check-screenshot.png';

const mediumSizePopups = [
  UnlockFeaturePopupType.CargoChiefRate,
  UnlockFeaturePopupType.RateCheck,
  UnlockFeaturePopupType.MarketRates,
  UnlockFeaturePopupType.SearchDirectory,
];

interface UnlockFeaturePopupProps {
  popupType?: UnlockFeaturePopupType;
  onClickManagePlan: () => void;
  onClickAddOn: () => void;
  isAddOnsAvailable: boolean;
}

interface DetailedPopupContentProps {
  icon: string;
  title: JSX.Element | string;
  testID: string;
}

const StyledText = styled(Text)`
  @media ${MediaQueries.lt_md} {
    padding: 20px 5px 0 5px;
  }
`;

const StyledImageContainer = styled(Grid)`
  display: flex;
  @media ${MediaQueries.lt_md} {
    justify-content: center;
  }
`;

const managePlanButtonProps = {
  testID: 'manage_plan_button',
  key: t(T.common_upgradePlanPopup_button),
  label: t(T.common_upgradePlanPopup_button),
};

const learnMoreButtonProps = {
  testID: 'learn_more_button',
  key: t(T.common_membership_learnMore),
  label: t(T.common_membership_learnMore),
};

const getContent = (popupType?: UnlockFeaturePopupType) => {
  switch (popupType) {
    case UnlockFeaturePopupType.CreditRating:
      return t(T.common_upgradePlanPopup_creditRating);

    case UnlockFeaturePopupType.MileageCalculator:
      return t(T.common_upgradePlanPopup_mileageCalculator);

    case UnlockFeaturePopupType.DrivingTime:
      return t(T.common_upgradePlanPopup_drivingTime);

    default:
      return '';
  }
};

const UnlockFeaturePopup: React.FC<UnlockFeaturePopupProps> = (props) => {
  switch (props.popupType) {
    case UnlockFeaturePopupType.CreditRating:
    case UnlockFeaturePopupType.MileageCalculator:
    case UnlockFeaturePopupType.DrivingTime:
      return (
        <BasePopup
          title={t(T.common_upgradePlanPopup_title)}
          buttons={[
            <PopupPrimaryButton
              {...managePlanButtonProps}
              key={managePlanButtonProps.key}
              action={props.onClickManagePlan}
            />,
          ]}
        >
          {getContent(props.popupType)}
        </BasePopup>
      );

    case UnlockFeaturePopupType.RateCheck:
      return <RateCheckPopup onClickManagePlan={props.onClickManagePlan} isAddOnsAvailable={props.isAddOnsAvailable} />;

    case UnlockFeaturePopupType.CargoChiefRate:
      return <CargoChiefRatePopup onClickAddOn={props.onClickAddOn} />;

    case UnlockFeaturePopupType.MarketRates:
      return (
        <MarketRatesAccessFailedPopup
          onClickManagePlan={props.onClickManagePlan}
          isAddOnsAvailable={props.isAddOnsAvailable}
        />
      );

    case UnlockFeaturePopupType.SearchDirectory:
      return <SearchDirectoryAccessFailedPopup onClickManagePlan={props.onClickManagePlan} />;

    default:
      return <></>;
  }
};

const RateCheckPopup: React.FC<{ onClickManagePlan: () => void; isAddOnsAvailable: boolean }> = ({
  onClickManagePlan,
  isAddOnsAvailable,
}) => {
  return (
    <BasePopup
      title={t(T.common_marketRate_rateCheck_title)}
      buttons={[
        <PopupPrimaryButton
          {...learnMoreButtonProps}
          key={learnMoreButtonProps.key}
          action={() => window.open(LOADBOARD_HELP_RATE_CHECK, '_blank')}
        />,
        <PopupPrimaryButton {...managePlanButtonProps} key={managePlanButtonProps.key} action={onClickManagePlan} />,
      ]}
    >
      <DetailedPopupContent
        testID="rate_check_feature"
        title={`${t(T.common_marketRate_rateCheck_popupContent)}\n\n ${
          isAddOnsAvailable
            ? t(T.common_marketRate_rateCheck_popupContentDetails)
            : t(T.common_marketRate_rateCheck_popupContentDetailsIOSUser)
        }`}
        icon={RateCheckFeature}
      />
    </BasePopup>
  );
};

export const CargoChiefRatePopup: React.FC<{ onClickAddOn?: () => void }> = ({ onClickAddOn }) => {
  const isMembershipTypeApple = useSelector((state) => state.userPlan.membershipType === MembershipType.Apple);

  const cargoChiefPopupButtons = [
    <PopupPrimaryButton
      {...learnMoreButtonProps}
      key={learnMoreButtonProps.key}
      action={() => window.open(LOADBOARD_HELP_CARGO_CHIEF, '_blank')}
    />,
  ];

  if (isMembershipTypeApple) {
    cargoChiefPopupButtons.push(
      <PopupCallButton
        testID="contact_support"
        key={t(T.findLoads_loadDetails_permissions_modal_callSupport)}
        label={t(T.findLoads_loadDetails_permissions_modal_callSupport)}
      />
    );
  } else {
    cargoChiefPopupButtons.push(
      <PopupPrimaryButton
        testID="add_ons_button"
        key={t(T.common_upgradePlanPopup_addOns)}
        label={t(T.common_upgradePlanPopup_addOns)}
        action={onClickAddOn}
      />
    );
  }

  return (
    <BasePopup title={t(T.common_marketRate_cargoChief_title)} buttons={cargoChiefPopupButtons}>
      <DetailedPopupContent
        testID="cargo_chief_rate_feature"
        title={
          <Text id="text" textStyle={TextStyle.TextContentDetail}>
            {t(T.common_marketRate_cargoChief_popupContent)}
            <Text id="bold_text" style={{ fontWeight: 'bold' }} textStyle={TextStyle.TextContentDetail}>
              {t(T.common_marketRate_cargoChief_popupContentBold)}
            </Text>
            {t(T.common_marketRate_cargoChief_popupContent2)}
          </Text>
        }
        icon={CargoChiefFeature}
      />
    </BasePopup>
  );
};

const SearchDirectoryAccessFailedPopup: React.FC<{ onClickManagePlan: () => void }> = ({ onClickManagePlan }) => {
  return (
    <BasePopup
      title={t(T.searchDirectory_upgradePopup_title)}
      buttons={[
        <PopupLabelButton
          testID="manage_plan_button"
          key="manage_plan_button"
          action={onClickManagePlan}
          label={t(T.common_upgradePlanPopup_button)}
        />,
      ]}
    >
      <div style={{ paddingBottom: 15 }}>
        <Text textStyle={TextStyle.TextContentDetail} id="search_directory_access_content1">
          {t(T.searchDirectory_upgradePopup_content1)}
        </Text>
      </div>
      <div style={{ paddingBottom: 15 }}>
        <Text textStyle={TextStyle.TextContentDetail} id="search_directory_access_content2">
          {t(T.searchDirectory_upgradePopup_content2)}
        </Text>
      </div>
    </BasePopup>
  );
};

const DetailedPopupContent: React.FC<DetailedPopupContentProps> = (props) => {
  return (
    <Grid id={props.testID} container={true} justify="center">
      <StyledImageContainer item={true} xs={12} sm={12} md={6}>
        <img width={250} id={`${props.testID}_img`} src={props.icon} />
      </StyledImageContainer>
      <Grid justify="center" style={{ display: 'flex' }} item={true} xs={12} sm={12} md={6}>
        <StyledText id={`${props.testID}_text`} textStyle={TextStyle.TextContentDetail}>
          {props.title}
        </StyledText>
      </Grid>
    </Grid>
  );
};

export const useUnlockFeaturePopup = () => {
  const popupContext = usePopup();
  const history = useHistory();
  const isAddOnsAvailable = useSelector((state) => state.userPlan.membershipType !== MembershipType.Apple);

  const onClickManagePlan = (popupType?: UnlockFeaturePopupType) => {
    if (popupContext) {
      popupContext.closingPopup();
    }

    if (popupType === UnlockFeaturePopupType.SearchDirectory) {
      history.push({
        pathname: `${generatePath(Routes.MORE_SETTINGS, {
          filter: Filter.ManagePlan,
          subroutes: Filter.PremiumPlus,
        })}/`,
      });
    } else if (isAddOnsAvailable) {
      history.push(Routes.MORE_SETTINGS_MANAGE_PLAN);
    } else {
      history.push(generatePath(Routes.MORE_SETTINGS, { filter: Filter.ManagePlan, subroutes: Filter.PremiumPlus }));
    }
  };

  const onClickAddOn = () => {
    if (popupContext) {
      popupContext.closingPopup();
    }
    history.push(generatePath(Routes.MORE_SETTINGS, { filter: Filter.ManagePlan, subroutes: Filter.AddOn }));
  };

  return (popupType?: UnlockFeaturePopupType) => {
    popupContext.openingPopup({
      body: (
        <UnlockFeaturePopup
          popupType={popupType}
          onClickAddOn={onClickAddOn}
          onClickManagePlan={() => onClickManagePlan(popupType)}
          isAddOnsAvailable={isAddOnsAvailable}
        />
      ),
      width: includes(mediumSizePopups, popupType) ? PopupSizes.MEDIUM + Spacing.PanelSide : PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };
};

const MarketRatesAccessFailedPopup: React.FC<{ onClickManagePlan: () => void; isAddOnsAvailable: boolean }> = ({
  onClickManagePlan,
  isAddOnsAvailable,
}) => {
  return (
    <BasePopup
      title={t(T.common_marketRate_title)}
      buttons={[
        <PopupLabelButton
          testID="learn_more_button"
          key="learn_more_button"
          action={() => window.open(LOADBOARD_HELP_MARKET_RATES, '_blank')}
          label={t(T.common_membership_learnMore)}
        />,
        <PopupLabelButton
          testID="manage_plan_button"
          key="manage_plan_button"
          action={onClickManagePlan}
          label={t(T.common_upgradePlanPopup_button)}
        />,
      ]}
    >
      <div style={{ paddingBottom: 15 }}>
        <Text textStyle={TextStyle.TextContentDetail} id="market_rates_access_content1">
          {t(T.common_marketRate_ratesAccess_content1)}
        </Text>
      </div>
      <div style={{ paddingBottom: 15 }}>
        <Text textStyle={TextStyle.TextContentDetail} id="market_rates_access_content2">
          {t(T.common_marketRate_ratesAccess_content2)}
        </Text>
      </div>

      <Text textStyle={TextStyle.TextContentDetail} id="market_rates_access_content3">
        {isAddOnsAvailable
          ? t(T.common_marketRate_ratesAccess_content3)
          : t(T.common_marketRate_ratesAccess_content3IOSUsers)}
      </Text>
    </BasePopup>
  );
};

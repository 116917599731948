import React from 'react';

import styled from 'styled-components';

import { Button } from '@component/button/Button';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  && {
    min-width: auto;
    font-size: ${FontSize.Content}px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

interface Props {
  onClear: () => void;
  disabled: boolean | undefined;
}

export const ClearButton: React.FC<Props> = ({ onClear, disabled }) => (
  <ButtonWrapper>
    <StyledButton id="clear_button" onClick={onClear} disabled={disabled}>
      {t(T.common_clear)}
    </StyledButton>
  </ButtonWrapper>
);

import React, { Component } from 'react';

import { filter, get, isEqual, map } from 'lodash';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom';
import { Action, bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import { StoreState } from '@/reduxStore/Store';
import { BackhaulsLoadSearchQuery, Load, LoadBackhaul, LoadSearchRequest } from '@common/model';
import { fetchLoadBackhauls, LoadInfo } from '@common/redux/epic/LoadInfoEpic';
import {
  backhaulsActions,
  LoadBackhaulsState,
  loadSearchQueryFromBackhaulsQuery,
} from '@common/redux/epic/loadSearch/LoadSearchBackhaulsEpic';
import { BackhaulCard } from '@component/cards/BackhaulCard';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Panel, PanelHeader, PanelSize } from '@component/panel';
import { FindLoadsRouteParams } from '@page/findLoads/RouteParams';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { decodeMatchParams } from '@util/UrlParamsHelper';

export const BACKHAULS_SEARCH = 'BACKHAULS_SEARCH';
export const BACKHAULS_LAYER = 4;

const StyledContent = withTheme()(styled.div`
  box-shadow: 0px 2px 14px 0px ${(props: ThemeProps) => props.theme.palette.background.smoke};
  background-color: ${(props: ThemeProps) => props.theme.palette.background.white};
`);

interface ComponentProps {
  closeBackhaulsPanel: () => void;
  onLoadClick?: (backhaulID: string) => void;
  selectedSearch: LoadSearchRequest | undefined;
  path: string;
  currentLoad: Load | undefined;
}

interface ReduxProps {
  loadInfo: Map<string, LoadInfo>;
  state: LoadBackhaulsState;
}

interface DispatchProps {
  setSelectedSearch: (search: LoadSearchRequest | undefined) => void;
  backhaulsSetQuery: (query: BackhaulsLoadSearchQuery) => void;
  fetchLoadBackhauls: (loadID: string, fromNamedSearchId: string) => void;
}
type Props = ComponentProps &
  ReduxProps &
  DispatchProps &
  RouteComponentProps<{ loadID: string; backhaulSearchID: string; backhaulLoadID: string }>;

interface ComponentState {
  backhauls: LoadBackhaul[];
}

class BackhaulsPanelComponent extends Component<Props, ComponentState> {
  state: ComponentState = {
    backhauls: [],
  };

  static getDerivedStateFromProps(props: Props, state: ComponentState) {
    if (props.loadInfo && props.currentLoad) {
      const newBackhaulsData = props.loadInfo.get(props.currentLoad.id);

      if (newBackhaulsData && newBackhaulsData.backhauls && newBackhaulsData.backhauls.payload) {
        return {
          backhauls: newBackhaulsData.backhauls.payload,
        };
      }
    }

    return state;
  }

  componentDidUpdate(prevProps: Props, prevState: ComponentState) {
    const selectedSearchID = get(this.props.selectedSearch, 'id');
    const match = decodeMatchParams<FindLoadsRouteParams>(this.props.location.pathname, this.props.path);
    const prevMatch = decodeMatchParams<FindLoadsRouteParams>(prevProps.location.pathname, prevProps.path);
    if (
      (this.props.location.pathname !== prevProps.location.pathname &&
        match?.params.backhaulSearchID !== prevMatch?.params.backhaulSearchID) ||
      !isEqual(this.state.backhauls, prevState.backhauls)
    ) {
      const backhaul = filter(this.state.backhauls, (bh) => bh.id === match?.params?.backhaulSearchID);
      if (backhaul) {
        if (backhaul[0] && backhaul[0].count && backhaul[0].count > 0) {
          this.props.setSelectedSearch(loadSearchQueryFromBackhaulsQuery(backhaul[0].query));
        }
      }
    }
    if (!isEqual(prevProps.selectedSearch?.id, this.props.selectedSearch?.id) && selectedSearchID) {
      this.props.fetchLoadBackhauls(this.props.match.params.loadID, selectedSearchID);
    }
  }

  handleActiveClick = (backhaul: LoadBackhaul) => () => {
    if (backhaul && backhaul.count && backhaul.count > 0) {
      this.props.backhaulsSetQuery(backhaul.query);
      this.props.setSelectedSearch({
        ...loadSearchQueryFromBackhaulsQuery(backhaul.query),
        id: backhaul.id,
      });
      const { backhaulLoadID, ...params } = this.props.match.params;

      if (this.props.onLoadClick) {
        this.props.onLoadClick(backhaul.id);
      } else {
        this.props.history.push({
          pathname: `${generatePath(this.props.path, { ...params, backhaulSearchID: backhaul.id })}/`,
          search: this.props.location.search,
        });
      }
    }
  };

  handleBackClick = () => {
    this.props.closeBackhaulsPanel();
  };

  render() {
    const { backhauls } = this.state;
    const match = decodeMatchParams<FindLoadsRouteParams>(this.props.location.pathname, this.props.path);
    return (
      <Panel id="backhauls" layer={BACKHAULS_LAYER} offset={0} size={PanelSize.small}>
        <PanelHeader label={t(T.findLoads_findLoads_backhauls)} hasCloseButton={true} onClose={this.handleBackClick} />
        <StyledContent>
          {map(backhauls, (item: any) => {
            return (
              <BackhaulCard
                key={item.id}
                destinationFrom={item.from}
                destinationTo={item.to}
                backhauls={item.count}
                handleActiveSearch={this.handleActiveClick(item)}
                activeSearch={item.id}
                idSearch={Number(match?.params.backhaulSearchID)}
              />
            );
          })}
          {backhauls.length === 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} key={0}>
              <LoadingSpinner />
            </div>
          ) : null}
        </StyledContent>
      </Panel>
    );
  }
}

const mapStateToProps = (state: StoreState): ReduxProps => {
  return {
    loadInfo: state.loadInfo.loadInfoMap,
    state: state.backhauls,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      setSelectedSearch: backhaulsActions.setSelectedSearch,
      backhaulsSetQuery: (query: BackhaulsLoadSearchQuery) => backhaulsActions.backhaulsSetQuery(query, undefined),
      fetchLoadBackhauls: fetchLoadBackhauls,
    },
    dispatch
  );

export const BackhaulsPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme()(BackhaulsPanelComponent)));

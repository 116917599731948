import React, { useState } from 'react';

import styled from 'styled-components';

import { Button, MenuItem } from '@material-ui/core';
import { default as ExpandMoreIcon } from '@material-ui/icons/KeyboardArrowDown';

import { UIMenuLayout } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { history } from '@/reduxStore/History';
import { Routes } from '@/router/Routes';
import { DropdownMenuSelect } from '@component/menu/DropdownMenuSelect';
import { PrefLabel } from '@component/menu/menuStyles';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { FontSize, Spacing } from '@style/StyleConstants';
import { withTheme } from '@style/WithTheme';
import { t, T } from '@translate';

const MenuPreferenceWrapper = styled<{ isOpen: boolean }>(({ isOpen, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  height: 64px;
  min-height: 64px;
  overflow: hidden;
  ${({ isOpen }) =>
    isOpen
      ? `
    align-items: flex-end;
    padding-top: ${Spacing.InterListElement * 2}px;
    padding-bottom: 0px;
    padding-right: ${Spacing.ScreenSide}px;
    padding-left: ${Spacing.InterListElement}px;
  `
      : `
    align-items: inherit;
    padding-top: ${Spacing.InterListElement}px;
    padding-bottom: ${Spacing.InterListElement}px;
    padding-left: ${Spacing.InterListElement}px;
    padding-right: ${Spacing.InterListElement}px;
  `};
`;

const MenuIcon = withTheme()(styled(ExpandMoreIcon)`
  padding-left: ${Spacing.InterElementHorizontal}px;
  padding-bottom: 3px;
  align-self: flex-end;
  color: orange;
`);

const MenuIconSmall = withTheme()(styled(ExpandMoreIcon)`
  color: orange;
  font-size: ${FontSize.Content}px;
`);

interface MenuPreferenceProps {
  setMenuLayout: (menuLayout: UIMenuLayout) => void;
  menuLayout: string;
  isMenuOpen: boolean;
  isIntegrator?: boolean;
  onClose: () => void;
}

export const MenuPreferenceSelect: React.FC<MenuPreferenceProps> = (props) => {
  const [menuAnchor, setMenuAnchor] = useState(undefined);
  const { popup, closingPopup } = usePopup();

  const toggleMenuLayout = (itemClicked: string) => {
    if (props.menuLayout === itemClicked) {
      setMenuAnchor(undefined);
    } else if (props.menuLayout === UIMenuLayout.Carrier) {
      props.onClose();
      props.setMenuLayout(UIMenuLayout.BrokerShipper);
      goDashboardIfNotAlreadyThere();
    } else if (props.menuLayout === UIMenuLayout.BrokerShipper) {
      props.onClose();
      props.setMenuLayout(UIMenuLayout.Carrier);
      goDashboardIfNotAlreadyThere();
    }
    props.onClose();
    setMenuAnchor(undefined);
  };

  const handleMenuClick = (e: React.BaseSyntheticEvent) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(undefined);
  };

  const onSelectMenuItem = (menuItem: UIMenuLayout) => {
    if (popup) {
      closingPopup();
    }
    toggleMenuLayout(menuItem);
  };

  return (
    <MenuPreferenceWrapper isOpen={props.isMenuOpen}>
      <Button id="toggle_menu" key="menu" variant="outlined" color="secondary" size="small" onClick={handleMenuClick}>
        {props.isMenuOpen ? (
          <>
            <PrefLabel opened={true}>{getPrefLabel(props.menuLayout, props.isMenuOpen)}</PrefLabel>
            <MenuIcon />
          </>
        ) : (
          <PrefLabel opened={false}>
            {getPrefLabel(props.menuLayout, props.isMenuOpen)} <MenuIconSmall />
          </PrefLabel>
        )}
      </Button>
      <DropdownMenuSelect anchor={menuAnchor} onClose={handleMenuClose}>
        <MenuItem id="menu_carrier" onClick={() => onSelectMenuItem(UIMenuLayout.Carrier)}>
          {t(T.menu_carrier)}
        </MenuItem>
        <MenuItem id="menu_broker" onClick={() => onSelectMenuItem(UIMenuLayout.BrokerShipper)}>
          {t(T.menu_broker)}
        </MenuItem>
        {/* TODO re-enable Integrator link once the final site is up  DEV-3486  [BP] */}
        {/*props.isIntegrator ? (
          <MenuItem id="menu_integrator" onClick={() => window.open(INTEGRATIONS_PORTAL_LINK, '_blank')}>
            {t(T.menu_integrator)}
          </MenuItem>
        ) : null*/}
      </DropdownMenuSelect>
    </MenuPreferenceWrapper>
  );
};

const getPrefLabel = (menuLayout: string, opened?: boolean) => {
  if (menuLayout === UIMenuLayout.Carrier) {
    return t(T.menu_carrier);
  }
  if (menuLayout === UIMenuLayout.BrokerShipper && !opened) {
    return t(T.menu_brokerSmall);
  }
  return t(T.menu_broker);
};

const goDashboardIfNotAlreadyThere = () => {
  if (!/^\/?$/.test(window.location.pathname)) {
    history.push(Routes.DASHBOARD);
  }
};

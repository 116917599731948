import styled from 'styled-components';

import { Load } from '@common/model';
import { MainButton } from '@component/buttons/MainButton';

export interface ContactButtonProps {
  load?: Load;
  isFullSize: boolean;
  isLabelHidden?: boolean;
}
export const ContactsButton = styled(MainButton)`
  max-width: ${(props: ContactButtonProps) => (!props.isFullSize ? '48px' : 'undefined')};
  min-width: 48px !important;
  flex: 1;
  > span {
    line-height: 18px;
  }
`;

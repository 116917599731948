import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

import { LoadConversationsOriginUI } from '@page/communication/LoadConversations';
import { Color } from '@style/Color';

import { default as AttachIcon } from './icon-attach-plus-o.svg';
import { default as AttachIconSelected } from './icon-attach-plus.svg';

export const SendAttachment: React.FC<{
  conversationID: string;
  handleAttachmentMenu: (isOpen: boolean) => void;
  isAttachmentsMenuOpen: boolean;
  originUI?: LoadConversationsOriginUI;
}> = (props) => {
  const [isAttachmentsMenuOpen, setIsAttachmentsMenuOpen] = useState(props.isAttachmentsMenuOpen);
  const onClick = () => {
    const isOpen = !isAttachmentsMenuOpen;
    if (props.originUI === LoadConversationsOriginUI.MessagingWidget) {
      setIsAttachmentsMenuOpen(false);
    } else {
      setIsAttachmentsMenuOpen(isOpen);
    }
    props.handleAttachmentMenu(isOpen);
  };
  useEffect(() => {
    setIsAttachmentsMenuOpen(props.isAttachmentsMenuOpen);
  }, [props.isAttachmentsMenuOpen]);

  return (
    <AttachmentButton onClick={onClick}>
      <img
        src={isAttachmentsMenuOpen ? AttachIcon : AttachIconSelected}
        alt="Attachment icon"
        style={{ paddingRight: 2, marginLeft: -8, alignSelf: 'center' }}
      />
    </AttachmentButton>
  );
};

const AttachmentButton = styled(IconButton)`
  && {
    padding: 0px !important;
    &:hover {
      background-color: ${Color.TRANSPARENT_0};
      filter: brightness(85%);
    }
  }
`;

import React from 'react';

import { APPSTORE_LINK, GOOGLEPLAY_LINK } from '@common/info';

import appStore_logo from './images/appstore.svg';
import googlePlay_logo from './images/googleplay.svg';
import { ComplexIconLink, ComplexLogo, ComplexLogoContainer } from './MobileStoreLinkStyle';

const getComplexLogoButton = (logoSrc: string, href: string, id: string) => (
  <ComplexIconLink id={id} href={href} target="_blank">
    <ComplexLogo src={logoSrc} />
  </ComplexIconLink>
);

interface LogoLinkProps {
  page_Id_Prefix: string;
}

export const MobileStoreLink: React.SFC<LogoLinkProps> = (props) => (
  <>
    <ComplexLogoContainer>
      {getComplexLogoButton(appStore_logo, APPSTORE_LINK, props.page_Id_Prefix + '_appstore_logo_link')}
      {getComplexLogoButton(googlePlay_logo, GOOGLEPLAY_LINK, props.page_Id_Prefix + '_googleplay_logo_link')}
    </ComplexLogoContainer>
  </>
);

import { find, forEach, identity, throttle } from 'lodash';

import { NO_INFORMATION_SYMBOL } from '@common/formatter/Constants';

// Void function
export const voidFunction = () => {
  /**/
};
export const getEnumKey = (stringEnum: any) => (value: string) =>
  find(Object.keys(stringEnum), (key) => stringEnum[key] === value);

export const throttledOnPress = (onPress?: (...args: any[]) => void, delay: number = 800) =>
  throttle(
    (...args: any[]) => {
      if (onPress) {
        onPress(...args);
      }
    },
    delay,
    { leading: true, trailing: false }
  );

export const findMatch: <T, K>(collection: T[], iteratee: (item: T) => K) => K | undefined = (collection, iteratee) => {
  let match;

  forEach(collection, (item) => {
    const foundMatch = iteratee(item);
    if (foundMatch) {
      match = foundMatch;
      return false;
    }

    return true;
  });

  return match;
};

export const doIfValue =
  <T, S>(transformer: (value: S) => T) =>
  (value: S | undefined) =>
    value !== undefined ? transformer(value) : undefined;

export const dashIfReturnsUndefined =
  <T, S>(transformer: (value: S) => T | undefined) =>
  (value: S) =>
    transformer(value) ?? NO_INFORMATION_SYMBOL;

export const dashIfUndefined = dashIfReturnsUndefined(identity) as <T>(arg: T | undefined) => NonNullable<T>; // TS thinks the above function can return undefined, so we have to cast here

export const addSuffix =
  (suffix: string) =>
  (str: string | number | undefined): string | undefined =>
    str ? `${str}${suffix}` : undefined;

/**
 * where undefined is the same as empty string and false, return whether two optional values (either both
 * string | undefined or both boolean | undefined) are equal
 */
export const areOptionalValuesSemanticallyEqual = <T extends string | boolean>(a: T | undefined, b: T | undefined) =>
  (a && b && a === b) || (!a && !b);

export const valueOrEmptyString = <T>(value: T) => value ?? '';

export const MY_POSTED_LOADS_REQUEST_LIMIT_APPENDING = 10;

export const MY_POSTED_LOADS_REQUEST_FIELDS_DEFAULT = `origin,destination,originLocation,destinationLocation,pickupDateTimes,pickupDateTime,equipments,loadSize,mileage,length,weight,rate{amount},id,computedMileage,status,age,poster{name, metaData},metadata{hasPrivateNote, isGoneCounter, messagesSentCounter, messagesReadCounter, userdata },pricePerMile,postReference`;

export const MY_POSTED_LOADS_REQUEST_FIELDS_WITH_POSTER = `${MY_POSTED_LOADS_REQUEST_FIELDS_DEFAULT},posterContactName`;

export const POSTED_LOAD_DETAIL_REQUEST_FIELDS_DEFAULT =
  'id, ' +
  'guid, ' +
  'status, ' +
  'computedMileage, ' +
  'age, ' +
  'fetchedAt, ' +
  'isFromAutorefresh, ' +
  'created, ' +
  'poster, ' +
  'rateCheck, ' +
  'metadata { ' +
  'hasPrivateNote, ' +
  'isGoneCounter, ' +
  'messagesSentCounter, ' +
  'messagesReadCounter, ' +
  'userdata },' +
  'postReference, ' +
  'numberOfLoads, ' +
  'originLocation, ' +
  'destinationLocation, ' +
  'origin, ' +
  'destination, ' +
  'pickupDateTime, ' +
  'pickupDateTimes, ' +
  'deliveryDateTime, ' +
  'equipments, ' +
  'loadSize, ' +
  'mileage, ' +
  'length, ' +
  'weight, ' +
  'rate, ' +
  'numberOfStops, ' +
  'commodity, ' +
  'notes, ' +
  'privateLoadNote,' +
  'dispatchPhone, ' +
  'dispatchName, ' +
  'dispatchEmail, ' +
  'contactName, ' +
  'contactPhone, ' +
  'contactEmail, ' +
  'sortEquipCode, ' +
  'pricePerMile, ' +
  'teamDriving, ' +
  'conversation, ' +
  'rateNegotiations, ' +
  'extraStops, ' +
  'vendorInfo, ' +
  'vHub, ' +
  'posterMetadata, ' +
  'visibility';

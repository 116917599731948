import i18next, { TranslationFunction } from 'i18next';

import english from './english';
import french from './french';
import spanish from './spanish';

const translate = i18next
  // .use(XHR)  // TODO: use the server so only the user language is loaded
  // TODO: is there a way to split the strings not only by language, but also by pages (or group of pages)?
  .init({
    // react i18next special options (optional)
    react: {
      wait: false,
      // bindI18n: 'languageChanged loaded',
      // bindStore: 'added removed',
      nsMode: 'default',
    },
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: 'en', // 'en' | 'es' | 'fr'
    // Using simple hardcoded resources for simple example
    resources: {
      ...english,
      ...french,
      ...spanish,
    },
  });

export const t: TranslationFunction = (key, options) => translate.t(key, options);

import React from 'react';

import { displaySingleStringValueFor } from '@common/helper/LocationPickerHelper';
import { HomeLocation, LocationType } from '@common/model';
import { IconActionButton } from '@component/buttons';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

interface Props {
  onClick: () => void;
  onEdit: () => void;
}

export const HomeLocationButton: React.FC<Props> = (props) => {
  const homeLocation = useSelector((state) => state.loadSearchSettings.homeLocation);

  return (
    <IconActionButton
      id="home"
      title={t(T.findLoads_findLoads_home)}
      mainIcon={icons.home}
      controlIcon={icons.edit}
      subTitle={getHomeLocationValue(homeLocation)}
      onControlClick={props.onEdit}
      onClick={props.onClick}
    />
  );
};

const getHomeLocationValue = (homeLocation: HomeLocation | undefined) => {
  if (homeLocation !== undefined) {
    switch (homeLocation.type) {
      case LocationType.CITY:
      case LocationType.GEOLOCATION:
      case LocationType.ZIP:
        return displaySingleStringValueFor(homeLocation, true);
    }
  }
  return t(T.findLoads_findLoads_setHomeLocation);
};

import styled from 'styled-components';

import { FormControl, MenuItem, Select } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';

import { ThemeProps, withTheme } from '@style/WithTheme';

export const StyledSelectWrapper = withTheme()(styled(FormControl)`
  && {
    border: 1px solid ${(props: ThemeProps) => props.theme.palette.select.borderColor};
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    overflow: hidden;
    width: 100%;
    font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
    & > div {
      font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
      height: 54px;
      ::before {
        border-color: ${(props: ThemeProps) => props.theme.palette.select.borderColor} !important;
      }
    }
    & > div {
      background-color: ${(props: ThemeProps & FormControlProps) =>
        props.disabled
          ? props.theme.palette.inputField.disabledBackground
          : props.theme.palette.select.backgroundColor};
      & > div {
        & :first-child {
          background-color: ${(props: ThemeProps & FormControlProps) =>
            props.disabled
              ? props.theme.palette.inputField.disabledBackground
              : props.theme.palette.select.backgroundColor};
        }
      }
    }
    & > label {
      font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
      color: ${(props: ThemeProps & FormControlProps) =>
        props.error ? props.theme.palette.textField.error : props.theme.palette.text.secondary};
    }
    &:focus > label {
      color: ${(props: ThemeProps & FormControlProps) =>
        props.error ? props.theme.palette.textField.error : props.theme.palette.primary.main};
    }
  }
`);

export const StyledSelect = withTheme()(styled(Select)`
  && {
    :hover {
      background-color: ${(props: ThemeProps & FormControlProps) =>
        props.disabled
          ? props.theme.palette.inputField.disabledBackground
          : props.theme.palette.select.backgroundColor};
    }
    & svg {
      margin-right: 8px;
    }
    & > div {
      & > div {
        padding-top: ${(props: any) => (props.label ? '27px' : '10px')};
      }
    }
  }
`);

export const StyledMenuItem = withTheme()(styled(MenuItem)`
  && {
    :hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.select.hoverColor};
    }
    :focus {
      background-color: ${(props: ThemeProps) => props.theme.palette.primary.main};
    }
  }
`);

import { find } from 'lodash';

import { TruckFilters } from '@common/sort';
import { DefaultEncoder } from '@common/util/encoder/URLDefaultEncoder';
import { parseBoolean, parseNumber } from '@common/util/parser/ParserUtils';

export const DEFAULT_RADIUS_OPTION = 100;
const MAX_RADIUS_OPTION = 300;
const MIN_RADIUS_OPTION = 25;
export const RADIUS_OPTIONS: number[] = [MIN_RADIUS_OPTION, 50, 100, 150, 200, MAX_RADIUS_OPTION];

const DEFAULT_TRUCK_FILTERS: TruckFilters = {
  radius: DEFAULT_RADIUS_OPTION,
  favorite: false,
  gpsVerified: false,
  onboarded: false,
  activeAuthority: false,
};

export class LocateTruckEncoder extends DefaultEncoder<Partial<TruckFilters>> {
  getVocabulary() {
    return {
      r: 'radius',
      f: 'favorite',
      o: 'onboarded',
      g: 'gpsVerified',
      a: 'activeAuthority',
    };
  }

  convertUrlParamsToFilters = (params: string): TruckFilters => {
    const obj = this.convertUrlParamsToObjectRequest(params);
    return {
      radius: getRadiusOption(parseNumber(obj.radius) ?? DEFAULT_TRUCK_FILTERS.radius),
      favorite: parseBoolean(obj.favorite) ?? DEFAULT_TRUCK_FILTERS.favorite,
      onboarded: parseBoolean(obj.onboarded) ?? DEFAULT_TRUCK_FILTERS.onboarded,
      gpsVerified: parseBoolean(obj.gpsVerified) ?? DEFAULT_TRUCK_FILTERS.gpsVerified,
      activeAuthority: parseBoolean(obj.activeAuthority) ?? DEFAULT_TRUCK_FILTERS.activeAuthority,
    };
  };

  convertFiltersToUrlParams = (filters: TruckFilters) =>
    this.convertObjectRequestToUrlParams({
      radius: filters.radius === DEFAULT_TRUCK_FILTERS.radius ? undefined : filters.radius,
      favorite: filters.favorite === DEFAULT_TRUCK_FILTERS.favorite ? undefined : filters.favorite,
      onboarded: filters.onboarded === DEFAULT_TRUCK_FILTERS.onboarded ? undefined : filters.onboarded,
      gpsVerified: filters.gpsVerified === DEFAULT_TRUCK_FILTERS.gpsVerified ? undefined : filters.gpsVerified,
      activeAuthority:
        filters.activeAuthority === DEFAULT_TRUCK_FILTERS.activeAuthority ? undefined : filters.activeAuthority,
    });
}

const getRadiusOption = (radius: number | null | undefined) => {
  if (radius === null || radius === undefined) {
    return DEFAULT_RADIUS_OPTION;
  } else if (radius >= MAX_RADIUS_OPTION) {
    return MAX_RADIUS_OPTION;
  } else if (radius <= MIN_RADIUS_OPTION) {
    return MIN_RADIUS_OPTION;
  }
  return find(RADIUS_OPTIONS, (radiusOption) => radiusOption >= radius) || MAX_RADIUS_OPTION;
};

import React, { useMemo } from 'react';

import { find, isUndefined } from 'lodash';

import { Table } from '@material-ui/core';

import { getMCLink, getUSDOTLink } from '@common/info';
import { AuthorityInfo, CompanyAuthorityInfo, CompanyLicensingInfo, FMCSAAuthorityStatus } from '@common/model';
import { FMCSAAuthority } from '@common/model/Carrier';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import {
  LinkRow,
  TextContentDetailDark,
  TextContentDetailDarkBold,
  TitleRow,
  ValueRow,
} from '@component/panels/companyDetails/CompanyDetailsRows';
import { default as AuthorityIcon } from '@component/panels/companyDetails/icon-authority.svg';
import {
  AuthorityCell,
  AuthorityRow,
  AuthorityTableHead,
  CenteringContainer,
  Container,
  DataWrapper,
} from '@component/panels/truckLocator/carrierDetails/CarrierDetailsPanelStyles';
import { T, t } from '@translate';
import { COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT, NO_INFORMATION_SYMBOL } from '@util/Constants';

interface Props {
  mcNumber?: number | string;
  usdotNumber?: number | string;
  handleChange: () => void;
  isOpened: boolean;
  authorities?: CompanyAuthorityInfo;
  licensing?: CompanyLicensingInfo;
  isLoading?: boolean;
  shouldHideRevocationPending?: boolean;
}

export const Authority: React.FC<Props> = (props) => {
  const isAuthorityAvailable = useMemo(
    () => props.licensing || props.authorities,
    [props.licensing, props.authorities]
  );
  const subtitle = generateSubtitle(props.authorities?.authorities);
  return (
    <ExpansionPanel
      id="authority_info"
      titleText={t(T.truckLocator_truckTable_carrierDetails_Authority)}
      isOpened={props.isOpened}
      handleClick={props.handleChange}
      subtitleText={isAuthorityAvailable ? subtitle : t(T.common_marketRate_notAvailable)}
      isBlocked={!isAuthorityAvailable}
      panelTitleStyle={{ height: COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT }}
      icon={AuthorityIcon}
    >
      <DataWrapper>
        {props.isLoading ? (
          <CenteringContainer>
            <LoadingSpinner />
          </CenteringContainer>
        ) : (
          <>
            <Container isWithoutTopLine={true}>
              <TitleRow id="authority_numbers" title={t(T.truckLocator_truckTable_carrierDetails_AuthorityNumbers)} />
              <LinkRow
                id="MC"
                title={t(T.common_conversations_contactInfo_mc)}
                rightValue={props.mcNumber}
                linkFormat={getMCLink}
              />
              <LinkRow
                id="USDOT"
                title={t(T.common_conversations_contactInfo_usDot)}
                rightValue={props.usdotNumber}
                linkFormat={getUSDOTLink}
              />
            </Container>
            <Container>
              <TitleRow id="cargo_auth" title={t(T.truckLocator_truckTable_carrierDetails_CargoAuth)} />
              <ValueRow
                id="property"
                title={t(T.truckLocator_truckTable_carrierDetails_Property)}
                rightValue={getBooleanLabel(props.licensing?.property)}
              />
              <ValueRow
                id="passengers"
                title={t(T.truckLocator_truckTable_carrierDetails_Passengers)}
                rightValue={getBooleanLabel(props.licensing?.passenger)}
              />
              <ValueRow
                id="household_goods"
                title={t(T.truckLocator_truckTable_carrierDetails_HouseholdGoods)}
                rightValue={getBooleanLabel(props.licensing?.householdGoods)}
              />
              <ValueRow
                id="private"
                title={t(T.truckLocator_truckTable_carrierDetails_Private)}
                rightValue={getBooleanLabel(props.licensing?.private)}
              />
              <ValueRow
                id="enterprise"
                title={t(T.truckLocator_truckTable_carrierDetails_Enterprise)}
                rightValue={getBooleanLabel(props.licensing?.enterprise)}
              />
            </Container>
            <Container>
              <AuthorityTable
                authorities={props.authorities?.authorities}
                shouldHideRevocationPending={props.shouldHideRevocationPending}
              />
            </Container>
          </>
        )}
      </DataWrapper>
    </ExpansionPanel>
  );
};

export const getBooleanLabel = (value: boolean | undefined) => {
  if (isUndefined(value)) {
    return NO_INFORMATION_SYMBOL;
  }

  return value ? t(T.common_yes) : t(T.common_no);
};

interface AuthorityTableProps {
  authorities: AuthorityInfo[] | undefined;
  shouldHideRevocationPending?: boolean;
}

const AuthorityTable = ({ authorities, shouldHideRevocationPending }: AuthorityTableProps) => {
  const commonAuthority = find(authorities, (auth) => auth.type === FMCSAAuthority.Common);
  const contractAuthority = find(authorities, (auth) => auth.type === FMCSAAuthority.Contract);
  const brokerAuthority = find(authorities, (auth) => auth.type === FMCSAAuthority.Broker);
  const commonAuthorityRevocation = find(authorities, (auth) => auth.type === FMCSAAuthority.CommonRevocation);
  const contractAuthorityRevocation = find(authorities, (auth) => auth.type === FMCSAAuthority.ContractRevocation);
  const brokerAuthorityRevocation = find(authorities, (auth) => auth.type === FMCSAAuthority.BrokerRevocation);

  return (
    <Table style={{ marginTop: 7 }}>
      <AuthorityTableHead>
        {Cell(t(T.truckLocator_truckTable_carrierDetails_Authority), true)}
        {Cell(t(T.common_search_status), undefined, true)}
        {Cell(t(T.truckLocator_truckTable_carrierDetails_AppPending), undefined, true)}
        {shouldHideRevocationPending
          ? null
          : Cell(t(T.truckLocator_truckTable_carrierDetails_RevocationPending), undefined, true)}
      </AuthorityTableHead>
      <AuthorityRow key={FMCSAAuthority.Common}>
        {Cell(FMCSAAuthority.Common)}
        {Cell(commonAuthority?.status, true, true)}
        {Cell(getBooleanLabel(commonAuthority?.pending), true, true)}
        {shouldHideRevocationPending ? null : Cell(getBooleanLabel(commonAuthorityRevocation?.pending), true, true)}
      </AuthorityRow>
      <AuthorityRow key={FMCSAAuthority.Contract}>
        {Cell(FMCSAAuthority.Contract)}
        {Cell(contractAuthority?.status, true, true)}
        {Cell(getBooleanLabel(contractAuthority?.pending), true, true)}
        {shouldHideRevocationPending ? null : Cell(getBooleanLabel(contractAuthorityRevocation?.pending), true, true)}
      </AuthorityRow>
      <AuthorityRow key={FMCSAAuthority.Broker}>
        {Cell(FMCSAAuthority.Broker)}
        {Cell(brokerAuthority?.status, true, true)}
        {Cell(getBooleanLabel(brokerAuthority?.pending), true, true)}
        {shouldHideRevocationPending ? null : Cell(getBooleanLabel(brokerAuthorityRevocation?.pending), true, true)}
      </AuthorityRow>
    </Table>
  );
};

const Cell = (content?: string, bold?: boolean, isCentered?: boolean) => {
  const value = !isUndefined(content) && content !== FMCSAAuthorityStatus.None ? content : NO_INFORMATION_SYMBOL;
  return (
    <AuthorityCell isCentered={isCentered}>
      {bold ? (
        <TextContentDetailDarkBold>{value}</TextContentDetailDarkBold>
      ) : (
        <TextContentDetailDark>{value}</TextContentDetailDark>
      )}
    </AuthorityCell>
  );
};

const generateSubtitle = (authorities?: AuthorityInfo[] | undefined) => {
  const hasAnyActiveAuth = authorities?.some((auth) => auth.status === FMCSAAuthorityStatus.Active);
  if (hasAnyActiveAuth) {
    return t(T.common_active);
  }
  if (!hasAnyActiveAuth && authorities?.some((auth) => auth.status === FMCSAAuthorityStatus.Inactive)) {
    return t(T.truckLocator_fmcsaAuthority_status_inactive);
  }
  return NO_INFORMATION_SYMBOL;
};

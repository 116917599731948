import { map } from 'lodash';

import { createDefaultLoadSearchRequest, serverRFCDateToYYYYMMDD } from '@common/helper';
import { convertBaseLocationToLoadLocation, convertToOriginLocation } from '@common/helper/LocationHelper';
import { ANYWHERE_LOCATION, EquipmentType, LoadSearchRequest, TruckAvailability } from '@common/model';

export const convertTruckAvailabilityToLoadSearch = (truck: TruckAvailability): LoadSearchRequest => {
  const origin = convertToOriginLocation(convertBaseLocationToLoadLocation(truck.originLocation));

  const destination = convertBaseLocationToLoadLocation(truck.destinationLocations[0]) ?? ANYWHERE_LOCATION;

  const equipmentTypes = [];
  if (truck.equipmentType !== EquipmentType.None) {
    equipmentTypes.push(truck.equipmentType);
  }

  const pickupDates = map(truck.dates, (date: string) => serverRFCDateToYYYYMMDD(date));

  const baseLoadSearchRequest = createDefaultLoadSearchRequest();
  return {
    ...baseLoadSearchRequest,
    origin: origin,
    destination: destination,
    weight: truck.weight,
    length: truck.length,
    minLength: 0,
    minWeight: 0,
    equipmentTypes: equipmentTypes,
    pickupDates: pickupDates,
    loadSize: truck.size,
  };
};

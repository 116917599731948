import React, { useImperativeHandle, useRef } from 'react';

import { get, includes } from 'lodash';

import { LoadDetailsTabs } from '@/model';
import { isLoadCalled } from '@common/helper';
import { Load, LoadSearchRequest } from '@common/model';
import { convertLoadToCompanySearchRequest } from '@common/redux/epic/loadSearch/CompanySearchHelper';
import { Backhauls } from '@component/panels/basePanel/loadDetails/Backhauls';
import { CompanyInfo } from '@component/panels/basePanel/loadDetails/Company';
import { CompanyLoads } from '@component/panels/basePanel/loadDetails/CompanyLoads';
import { CreditRating } from '@component/panels/basePanel/loadDetails/CreditRating';
import { LoadDocuments } from '@component/panels/basePanel/loadDetails/LoadDocuments';
import { MarketRates } from '@component/panels/basePanel/loadDetails/marketRates/MarketRates';
import { Mileage } from '@component/panels/basePanel/loadDetails/Mileage';
import { Note } from '@component/panels/basePanel/loadDetails/Notes';
import { ProfitCalculator } from '@component/panels/basePanel/loadDetails/profitCalculator/ProfitCalculator';
import { LoadContactCallbackProps } from '@component/panels/findLoads/loadDetailsPanel/buttons/ContactButtons';
import { ResultViewType } from '@component/panels/findLoads/searchPanel/SearchPanelHelper';
import { isPanelSingleColumn } from '@component/panelSizeDetector/PanelSizeDetector';
import { useDelayedScrollIntoView } from '@component/scrollView';
import { StatusBar } from '@component/statusBar/StatusBar';
import { useSelector } from '@util/hooks';

interface Props extends LoadContactCallbackProps {
  load: Load;
  selectedSearch: LoadSearchRequest | undefined;
  width: number;
  viewType: ResultViewType;

  statusBarRef: React.RefObject<HTMLDivElement>;

  openedTabs: LoadDetailsTabs[];
  isProfitCalculatorOpened: boolean;

  isFromMyLoads: boolean | undefined;
  isBackhaulsDisabled: boolean | undefined;
  isOriginMessaging: boolean;
  isOriginBids: boolean;

  shouldShowPost: boolean;

  setIsProfitCalculatorOpened: (opened: boolean) => void;
  onGetProfitPerDay: (profitPerDay: number) => void;

  onMapClick: () => void;
  onPostClick: () => void;
  onViewLoadsClick: (() => void) | undefined;

  openBackhaulsPanel: (id: string) => void;

  onStatusBarExpand: () => void;
  onTabChange: (tab: LoadDetailsTabs, status: boolean) => void;
}

export interface LoadDetailsExpansionPanelsRef {
  scrollTo: () => void;
}

export const LoadDetailsExpansionPanels = React.forwardRef<LoadDetailsExpansionPanelsRef, Props>((props, ref) => {
  const loadInfo = useSelector((state) => props.load && state.loadInfo.loadInfoMap.get(props.load.id));

  const totalProfitRef = useRef<HTMLDivElement | null>(null);
  const expansionPanelRef = useRef<HTMLDivElement | null>(null);

  const isSingleColumn = isPanelSingleColumn(props.width ?? 0);

  const scrollIntoView = useDelayedScrollIntoView();

  useImperativeHandle<LoadDetailsExpansionPanelsRef, LoadDetailsExpansionPanelsRef>(
    ref,
    () => ({
      scrollTo: () => {
        scrollIntoView(totalProfitRef?.current, 'start', 'smooth', expansionPanelRef?.current?.offsetTop ?? 0);
      },
    }),
    [expansionPanelRef?.current?.offsetTop, totalProfitRef?.current?.offsetTop]
  );

  return (
    <>
      <StatusBar
        statusBarRef={props.statusBarRef}
        currentLoad={props.load}
        isOriginMessaging={props.isOriginMessaging}
        isOriginBids={props.isOriginBids}
        isLoadCalled={isLoadCalled(props.load)}
        width={props.width}
        isOpenDetails={includes(props.openedTabs, LoadDetailsTabs.Stepper)}
        onExpand={props.onStatusBarExpand}
        isFromMyLoads={props.isFromMyLoads}
        shouldShowPost={props.shouldShowPost && props.viewType !== ResultViewType.Backhaul}
        onPostClick={props.onPostClick}
        onCallClick={props.onCallClick}
        onEmailClick={props.onEmailClick}
        onInteractiveBidClick={props.onInteractiveBidClick}
        onVendorBidClick={props.onVendorBidClick}
        onBookNowClick={props.onBookNowClick}
      />
      <LoadDocuments
        load={props.load}
        handleChange={props.onTabChange}
        isOpened={includes(props.openedTabs, LoadDetailsTabs.LoadDocuments)}
        isFromMyLoads={props.isFromMyLoads}
      />
      <div ref={expansionPanelRef}>
        <ProfitCalculator
          totalProfitRef={totalProfitRef}
          loadInfo={loadInfo}
          load={props.load}
          handleChange={(tab, status) => {
            props.setIsProfitCalculatorOpened(false);
            props.onTabChange(tab, status);
          }}
          isOpened={props.isProfitCalculatorOpened || includes(props.openedTabs, LoadDetailsTabs.ProfitCalculator)}
          onGetProfitPerDay={props.onGetProfitPerDay}
        />
      </div>
      <MarketRates
        loadInfo={loadInfo}
        load={props.load}
        handleChange={props.onTabChange}
        isOpened={includes(props.openedTabs, LoadDetailsTabs.MarketRates)}
      />
      {props.viewType === ResultViewType.LoadSearch && !props.isBackhaulsDisabled ? (
        <Backhauls
          openBackhaulsPanel={props.openBackhaulsPanel}
          handleChange={props.onTabChange}
          isOpened={includes(props.openedTabs, LoadDetailsTabs.Backhauls)}
          loadInfo={loadInfo}
          selectedSearch={props.selectedSearch}
          shouldDisplayPostTruck={true}
          onPostClick={props.onPostClick}
          load={props.load}
        />
      ) : null}
      <CompanyInfo
        poster={props.load.poster}
        handleChange={props.onTabChange}
        isOpened={includes(props.openedTabs, LoadDetailsTabs.Company)}
      />
      <CreditRating
        posterId={props.load.poster?.id}
        loadID={props.load.id}
        handleChange={props.onTabChange}
        isOpened={includes(props.openedTabs, LoadDetailsTabs.CreditRating)}
      />
      {props.onViewLoadsClick ? (
        <CompanyLoads
          isOpened={includes(props.openedTabs, LoadDetailsTabs.CompanyLoads)}
          handleChange={props.onTabChange}
          onViewLoadsClick={props.onViewLoadsClick}
          companySearchRequest={convertLoadToCompanySearchRequest(props.load)}
        />
      ) : null}
      <Mileage
        load={props.load}
        onMapClick={props.onMapClick}
        handleChange={props.onTabChange}
        isOpened={includes(props.openedTabs, LoadDetailsTabs.Mileage)}
      />
      <Note
        loadInfo={loadInfo}
        companyId={props.load.id}
        note={props.load && get(props.load, 'metadata.userdata.note')}
        isLoadDetailsSingleColumn={isSingleColumn}
      />
    </>
  );
});

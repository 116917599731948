import { createDefaultLoadSearchRequest } from '@common/helper';
import { LoadSearchRequest } from '@common/model';
import { createAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const UPDATE_SEARCH_FORM_DATA = 'UPDATE_SEARCH_FORM_DATA';
const UPDATE_SEARCH_NAME = 'UPDATE_SEARCH_NAME';
const SET_SEARCH_FORM_PREFILLED = 'SET_SEARCH_FORM_PREFILLED';

export interface LoadSearchFormState {
  loadSearch: LoadSearchRequest;
  searchName?: string;
  isPrefilled: boolean;
}

const initialState: LoadSearchFormState = {
  loadSearch: createDefaultLoadSearchRequest(),
  isPrefilled: false,
};

const updateSearchFormDataAction = createAction<LoadSearchRequest>(UPDATE_SEARCH_FORM_DATA);
const updateSearchNameDataAction = createAction<string | undefined>(UPDATE_SEARCH_NAME);
const setSearchFormPrefilledAction = createAction<boolean>(SET_SEARCH_FORM_PREFILLED);

export const updateSearchFormData = updateSearchFormDataAction.action;
export const updateSearchNameData = updateSearchNameDataAction.action;
export const setSearchFormPrefilled = setSearchFormPrefilledAction.action;

export const loadSearchFormReducer = createMergedReducer<LoadSearchFormState>(initialState, [
  updateSearchFormDataAction.addCase((state, action) => {
    state.loadSearch = action.data;
  }),
  updateSearchNameDataAction.addCase((state, action) => {
    state.searchName = action.data;
  }),
  setSearchFormPrefilledAction.addCase((state, action) => {
    state.isPrefilled = action.data;
  }),
]);

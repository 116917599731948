import React, { ReactNode, RefObject } from 'react';

import { isFunction, isNumber } from 'lodash';
import styled from 'styled-components';

import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { BackPanelContext } from '@component/panel/BackPanelContext';
import { AreaScrollView } from '@component/scrollView/AreaScrollView';
import { NonScrollView } from '@component/scrollView/NonScrollView';
import { Color } from '@style/Color';
import { useIsMobileView, useUniqueId } from '@util/hooks';

import { getPanelLayout } from './panelLayout';
import { PanelSize } from './PanelSize';
import { usePanelTracking } from './WindowScrollViewTrackingContext';

export const PANEL_LIGHT_BACKGROUND = Color.WHITE;
export const PANEL_SHADOW_BACKGROUND = Color.GRAY_LIGHT;

interface ComponentProps {
  id: string;
  layer: number;
  size: PanelSize;
  offset: number;
  scrollRef?: RefObject<HTMLDivElement>;
  panelRef?: RefObject<HTMLElement>;
  backgroundColor?: string;
  /** Minimum size of panel in pixel (defaults to PanelWidth.Small) */
  minSize?: number;

  /** true to make the panel content fill the remaining screen height.
   * This will turn off any vertical scrolling.
   * This is meant for displaying special components like a map that
   * does not have an internal height and have their own panning system.
   * (NOTE: watch for scrolling quirks in iOS in this case)
   *
   * Most panels should not use this.
   */
  contentHeightFill?: boolean;
  shouldRemoveShadow?: boolean;
}

/** Top panel 'div' */
const PanelComponent = styled.div`
  top: 0;
  min-height: 100%;
`;

/** Panel scrolling area. Responsible for the Panel vertical height. May contain header/footers */
const PanelAreaScrollView = styled(AreaScrollView)`
  min-height: 100%;
  min-width: 100%;
  width: 100%;
`;

export const Panel: React.FC<ComponentProps> = ({
  id,
  layer,
  size,
  offset,
  contentHeightFill,
  scrollRef,
  backgroundColor,
  children,
  panelRef,
  minSize,
  shouldRemoveShadow,
}) => {
  const isMobileView = useIsMobileView();
  const isWindowScrollMode = useIsWindowScrollMode();
  const uid = useUniqueId();

  // FIXME: Temporary workaround until we get a managed Panel system (MEM-1965) [BP]
  const windowScrollViewTracking = usePanelTracking(uid, offset, layer, size, id);

  const isHighest = windowScrollViewTracking.isHighest(offset, layer);
  const isBackPanel = isMobileView && isWindowScrollMode && !isHighest;

  if (windowScrollViewTracking.availableWidth === 0) {
    // do not render panels that are not visible in mobileView.
    // (Else it causes issues with header/footers window scroll mode)
    return null;
  }

  const { position, width } = getPanelLayout(
    offset,
    size,
    windowScrollViewTracking.availableWidth,
    isMobileView,
    minSize
  );

  let content = children;
  /**
   * To access the current "width" in the <Panel without having to add SizeDetector,
   * see reference in CarrierInformation.tsx
   */
  if (isFunction(children)) {
    const childFunction = children as (props: { width?: number }) => ReactNode;
    content = childFunction({ width: isNumber(width) ? width : windowScrollViewTracking.availableWidth });
  }

  return (
    <>
      <BackPanelContext.Provider value={{ isBackPanel: isBackPanel, isHighest: isHighest }}>
        <PanelComponent
          id={id}
          style={{
            display: isBackPanel ? 'none' : 'block',
            backgroundColor: backgroundColor ?? PANEL_LIGHT_BACKGROUND,
            position: isWindowScrollMode ? 'relative' : 'absolute',
            left: position,
            width: width,
            zIndex: layer * 10,
            boxShadow: !isMobileView && !shouldRemoveShadow ? '2px 0px 5px 6px rgba(0, 0, 0, 0.20)' : undefined,
          }}
          innerRef={panelRef}
        >
          {contentHeightFill ? (
            <NonScrollView id="panelfillarea">{content}</NonScrollView>
          ) : (
            <PanelAreaScrollView
              id="panelscrollarea"
              scrollRef={scrollRef}
              style={{
                position: isWindowScrollMode ? 'relative' : 'absolute',
                bottom: isWindowScrollMode ? 'auto' : 0,
              }}
            >
              {content}
            </PanelAreaScrollView>
          )}
        </PanelComponent>
      </BackPanelContext.Provider>
    </>
  );
};

import React, { useEffect, useRef } from 'react';

import { first } from 'lodash';
import memoizeOne from 'memoize-one';
import { useDispatch } from 'react-redux';
import { generatePath, RouteComponentProps, useHistory, useParams } from 'react-router-dom';

import NoConversations from '@/icons/no_conversations.svg';
import { NestedRoute, UnitedRoute } from '@/router/RouteGroup';
import { Routes } from '@/router/Routes';
import { decrementViewingBidsCount, incrementViewingBidsCount } from '@common/redux/epic/bids/BidsEpic';
import { DefaultEncoder } from '@common/util/encoder/URLDefaultEncoder';
import { parseString } from '@common/util/parser/ParserUtils';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { PageWithPanels, Panel, PANEL_SHADOW_BACKGROUND, PanelSize } from '@component/panel';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { CreateNewTruckPost } from '@component/panels/truckPost/CreateNewTruckPost';
import { BidsLoadDetails } from '@page/bids/BidsLoadDetails';
import BidsPanel from '@page/bids/BidsPanel';
import { BidsPCMilerMap } from '@page/bids/BidsPCMilerMap';
import BidsTabsPanel from '@page/bids/BidsTabsPanel';
import { useIsBiddingEnabled, useIsVendorBidsListEnabled } from '@page/bids/BidStatusHelper';
import BidsFilterPanel from '@page/bids/filters/BidsFilterPanel';
import { BiddingSummary } from '@page/bids/summary/BiddingSummary';
import { BidSummaryPanel } from '@page/bids/summary/BidSummaryPanel';
import { useSelector } from '@util/hooks';

import { BidsPanelHeader } from './BidsPanelHeader';

export interface BidsRouteParamsProps {
  tab: BidsTabs;
  edit?: string;
  bidID?: string;
  offerID?: string;
  loadID?: string;
  otherPartyUserID?: string;
}

export enum BidsTabs {
  Bids = 'bids',
  InteractiveBids = 'interactive-bids',
}

export const BID_ID_INDEX = 4;
export const BID_LOAD_ID_INDEX = 5;

export const BIDS_SUMMARY_PANEL_PATH = 'bids/interactive-bids/summary';

export const bidIDFromRouteProps = (routeProps: RouteComponentProps<BidsRouteParamsProps>) => {
  return routeProps.match.params.bidID;
};

export const bidLoadIDFromRouteProps = (routeProps: RouteComponentProps<BidsRouteParamsProps>) => {
  return routeProps.match.params.loadID;
};

export const offerViewFromRouteProps = (routeProps: RouteComponentProps<BidsRouteParamsProps>) => {
  return routeProps.match.params.edit;
};

const BidsPanelRouting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCarrier = useIsCarrier();
  const params = useParams<BidsRouteParamsProps>();

  const is123BiddingEnabled = useIsBiddingEnabled();
  const isVendorBidsListEnabled = useIsVendorBidsListEnabled();

  const loadDetailsSearchParams = bidsEncoder.convertUrlSearchParamsToObject(history.location.search);
  const isCommunicationsOrigin = bidsEncoder.convertUrlSearchParamsToObject(
    history.location.search
  ).redirectCommunicationPath;

  const isStandalone =
    loadDetailsSearchParams?.redirectLoadDetailsPath === undefined && isCommunicationsOrigin === undefined;

  const panelsLayer = isStandalone ? 0 : 10;

  const panelSize = PanelSize.small;

  const scrollContainerRef = useRef<HTMLInputElement>(null);

  const baseUrl = `/bids/${isCarrier ? ':tab(bids|interactive-bids)?' : ''}`;

  useEffect(() => {
    if (!is123BiddingEnabled) {
      dispatch(incrementViewingBidsCount());
    }
    return () => {
      if (!is123BiddingEnabled) {
        dispatch(decrementViewingBidsCount());
      }
    };
  }, []);

  const paramsBidId = params.bidID;
  const paramsLoadId = params.loadID;
  const paramsOfferId = params.offerID;

  const otherPartyId = useSelector((state) => state.bids.loadBids.listAll.carrierId);
  const otherPartyUserID = otherPartyId ?? params.otherPartyUserID;

  const bidIds = useSelector((state) => state.bids.loadBids.listAll.ids);
  const topBidId = first(bidIds);

  const updatedBid = useSelector((state) => state.bids.bidSummaries.summaries.get(topBidId ?? paramsBidId ?? ''));
  const newBid = useSelector((state) => state.bids.loadBids.entities.get(topBidId ?? paramsBidId ?? ''));

  const redirectCommunicationPath = bidsEncoder.convertUrlSearchParamsToObject(
    history.location.search
  ).redirectCommunicationPath;

  const isBidSummaryOpenAndUpdated =
    (paramsBidId || paramsOfferId) && (paramsBidId !== topBidId || paramsOfferId !== topBidId);

  const didReceiveANewBid = topBidId !== undefined && (updatedBid || newBid);

  const isNewBidFromCurrentContact =
    ((otherPartyUserID === updatedBid?.otherParty?.contactId || isCarrier) && paramsLoadId === updatedBid?.load?.id) ||
    (updatedBid && updatedBid.offerMadeByMe);

  useEffect(() => {
    if (didReceiveANewBid && isBidSummaryOpenAndUpdated && isNewBidFromCurrentContact && updatedBid) {
      const pathnameValues =
        paramsOfferId || paramsBidId
          ? {
              tab: BidsTabs.InteractiveBids,
              summary: 'summary',
              bidID: topBidId,
              loadID: updatedBid.load?.id,
              offerID: topBidId,
              edit: 'offer',
            }
          : {
              tab: BidsTabs.InteractiveBids,
              summary: 'summary',
              bidID: topBidId,
              loadID: updatedBid.load?.id,
              edit: 'offer',
            };

      if (
        isCarrier ||
        (updatedBid &&
          ((!isCarrier && otherPartyUserID === updatedBid?.otherParty?.contactId) ||
            (!isCarrier && updatedBid.offerMadeByMe)))
      ) {
        history.push({
          pathname: `${generatePath(Routes.BIDS, pathnameValues)}`,
          search:
            history.location.search ??
            bidsEncoder.convertObjectRequestToUrlParams({
              loadId: paramsLoadId,
              redirectCommunicationPath: redirectCommunicationPath,
            }),
        });
      }
    }
  }, [updatedBid, newBid, topBidId]);

  if (!is123BiddingEnabled && !isVendorBidsListEnabled) {
    return <NotFoundPanel title="" imageSection={<img id="image" src={NoConversations} alt="error" />} />;
  }

  const closeAndRedirect = () => {
    if (loadDetailsSearchParams.redirectLoadDetailsPath) {
      history.push({
        pathname: `${loadDetailsSearchParams.redirectLoadDetailsPath}`,
        search: loadDetailsSearchParams.redirectLoadDetailsSearch?.substring(1),
      });
    }
  };

  const isBrokerOrFindLoads = loadDetailsSearchParams.redirectLoadDetailsPath !== undefined || !isCarrier;

  const isInteractive =
    history.location.pathname.includes('interactive-bids') ||
    loadDetailsSearchParams.redirectLoadDetailsPath !== undefined ||
    !isCarrier;

  const mainPanel = () => {
    if (!isCarrier || (isCarrier && !isStandalone) || (isCarrier && !isVendorBidsListEnabled)) {
      // Interactive bids only
      return (
        <Panel
          id="bids"
          size={panelSize}
          layer={isStandalone ? panelsLayer : panelsLayer - 1}
          offset={0}
          scrollRef={scrollContainerRef}
          backgroundColor={PANEL_SHADOW_BACKGROUND}
        >
          <BidsPanelHeader isStandalone={isStandalone} closeAndRedirect={closeAndRedirect} />
          <BidsPanel isInteractive={isInteractive} scrollRef={scrollContainerRef} />
        </Panel>
      );
    } else if (isCarrier && isVendorBidsListEnabled && !is123BiddingEnabled) {
      // Vendor bids only
      return (
        <Panel
          id="bids"
          size={panelSize}
          layer={isStandalone ? panelsLayer : panelsLayer - 1}
          offset={0}
          scrollRef={scrollContainerRef}
          backgroundColor={PANEL_SHADOW_BACKGROUND}
        >
          <BidsPanelHeader isStandalone={isStandalone} closeAndRedirect={closeAndRedirect} />
          <BidsPanel isInteractive={false} scrollRef={scrollContainerRef} />
        </Panel>
      );
    } else {
      // Vendor and interactive bid tabs
      return <BidsTabsPanel layer={isStandalone ? panelsLayer : panelsLayer - 1} isStandalone={isStandalone} />;
    }
  };
  return (
    <PageWithPanels>
      <UnitedRoute baseUrl={`${baseUrl}/:tab(bids|interactive-bids)/`}>
        <NestedRoute key="rate_negotiation" path={'/'}>
          {mainPanel()}
        </NestedRoute>

        <NestedRoute key="bidding_summary" path={'/:summary(summary)/:bidID/:loadID/'}>
          <BiddingSummary layer={panelsLayer} panelOffset={1} isCarrier={isCarrier} />
        </NestedRoute>

        <UnitedRoute
          key="bid_edit_offer_base"
          baseUrl={`/bids/:tab(bids|interactive-bids)/:summary(summary)/:bidID/:loadID/${
            isBrokerOrFindLoads
              ? ':edit(edit|offer|counteroffer|new)/:offerID/:otherPartyUserID?'
              : ':edit(edit|offer|counteroffer|new)?/:offerID?/'
          }`}
        >
          <NestedRoute key="bid_edit_offer" path={'/'}>
            <BidSummaryPanel layer={panelsLayer} isCarrier={isCarrier} panelOffset={isCarrier ? 1 : 2} />
          </NestedRoute>
          <NestedRoute key="bids_details" path={'/:loadDetails(load-details)/'}>
            <BidsLoadDetails layer={panelsLayer} isInteractive={isInteractive} />
          </NestedRoute>
          <CreateNewTruckPost baseUrl={Routes.BIDS} layer={panelsLayer + 1} />
          <NestedRoute key="bids_pc_miler_map" path={'/:loadMap(pc-miler-map)/'}>
            <BidsPCMilerMap />
          </NestedRoute>
        </UnitedRoute>
      </UnitedRoute>
      <NestedRoute key="bids_filter_panel" path={'/bids/:tab(bids|interactive-bids)/:filter(filter)/'}>
        <BidsFilterPanel layer={panelsLayer} panelOffset={0} />
      </NestedRoute>
    </PageWithPanels>
  );
};

export default BidsPanelRouting;

export interface BidsRoute {
  bidId?: string;
  loadId?: string;
  origin?: string;
  carrierId?: string;
  otherPartyUserID?: string;
  summary?: string;
  redirectCommunicationPath?: string;
  redirectLoadDetailsPath?: string;
  redirectLoadDetailsSearch?: string;
}

class BidsEncoder extends DefaultEncoder<BidsRoute> {
  convertUrlSearchParamsToObject = memoizeOne((urlParams: string): BidsRoute => {
    const rawObject = this.convertUrlParamsToObjectRequest(urlParams);

    return {
      bidId: parseString(rawObject.bidId),
      loadId: parseString(rawObject.loadId),
      origin: parseString(rawObject.origin),
      carrierId: parseString(rawObject.carrierId),
      redirectCommunicationPath: parseString(rawObject.redirectCommunicationPath),
      redirectLoadDetailsPath: parseString(rawObject.redirectLoadDetailsPath),
      redirectLoadDetailsSearch: parseString(rawObject.redirectLoadDetailsSearch),
    };
  });
}

export const bidsEncoder = new BidsEncoder();

import styled from 'styled-components';

import { Tabs as MaterialTabs } from '@material-ui/core';

import { Color } from '@style/Color';

export const Tabs = styled(MaterialTabs)`
  && {
    background-color: ${Color.WHITE};
  }
`;

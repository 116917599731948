import { endsWith, isEqual, replace } from 'lodash';
import QueryString from 'qs';
import { generatePath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

export const usePushPanel = (path: string) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  return (pathParam: string = '', searchParams: {} = {}) => {
    let paramsString = '';
    if (!isEqual(searchParams, {})) {
      paramsString = `&${QueryString.stringify(searchParams)}`;
    }
    let pathname = `${match?.url}${path}`;
    if (pathParam) {
      pathname = `${pathname}/${pathParam}/`;
    }
    history.push({
      pathname: formatURL(pathname),
      search: `${location.search}${paramsString}`,
    });
  };
};

export const usePushPanelWithPathParams = () => {
  const history = useHistory();
  const location = useLocation();

  return (pathParam: string = '', params: {} = {}) => {
    history.push({
      pathname: `${generatePath(formatURL(pathParam), params)}`,
      search: `${location.search}`,
    });
  };
};

export const usePanelBackLocation = (basePath: string | undefined) => {
  const location = useLocation();
  const history = useHistory();

  return (search?: string | undefined) => {
    if (basePath) {
      let url = location.pathname || '';

      const baseIndex = url.lastIndexOf(basePath);
      url = `${url.substr(0, baseIndex)}`;

      history.push({
        pathname: formatURL(url),
        search: search ?? location.search,
      });
    }
  };
};

//This is exclusively for the PC Miler panel in load details!!!
//While accessing nested communication load details, the route is
//a bit complex and it ends up messing with the PC miler route
//Here we manipulate the path and search fields to store and rebuild
//when we are opening and closing the PC Miler panel
export const usePCMilerPushPanel = (path: string, specialPathname: string) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  return (pathParam: string = '', params: {} = {}) => {
    let paramsString = '';
    if (!isEqual(params, {})) {
      paramsString = `&${QueryString.stringify(params)}`;
    }
    let pathname = `${match?.url}${path}`;

    if (pathname.startsWith(`/${specialPathname}`)) {
      if (pathParam) {
        pathname = `${pathname}/${pathParam}/`;
      }
      history.push({
        pathname: formatURL(pathname),
      });
    } else {
      if (pathParam) {
        pathname = `${pathname}${pathParam}/`;
      }
      const nestedPath = `${path}/${specialPathname}${pathname.split(specialPathname)[1]}`;
      const baseNestedURL = `${pathname.split(specialPathname)[0]}`;

      history.push({
        pathname: formatURL(`${nestedPath}`),
        search: `${baseNestedURL}${location.search}${paramsString}`,
      });
    }
  };
};

export const usePCMilerPanelBackLocation = () => {
  const match = useRouteMatch();
  const location = useLocation();

  let url = match?.url || '';

  url = `${url.replace('pc-miler-map/', '')}`;

  const baseNestedURL = location.search.split('/?')[0].replace('?', '');
  const search = location.search.split('/?')[1];
  url = `${baseNestedURL}${url}`;

  return {
    pathname: formatURL(url),
    search: search,
  };
};
//end of PC Miler specific push/back panels location

const formatURL = (url: string): string => {
  if (url.includes('//')) {
    return formatURL(replace(url, '//', '/'));
  }
  if (endsWith(url, '/')) {
    return url;
  }
  return `${url}/`;
};

//!!think twice if using this, Avoid usage if possible.!!
//This function is a patch fix for FindLoads, since it does not follow regular routing.
//remove it and use `usePushPanel` once that part is reworked.
export const usePatchedPushPanel = (path: string, pathToRemove: string) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  return (pathParam: string = '', params: {} = {}) => {
    let paramsString = '';
    let url = match?.url || '';

    const pathIndex = url.lastIndexOf(path);
    if (pathIndex !== -1) {
      return;
    }
    if (!isEqual(params, {})) {
      paramsString = `&${QueryString.stringify(params)}`;
    }
    const baseIndex = url.lastIndexOf(pathToRemove);
    if (baseIndex !== -1) {
      url = `${url.substr(0, baseIndex)}`;
    }
    let pathname = `${url}${path}`;
    if (pathParam) {
      pathname = `${pathname}/${pathParam}/`;
    }
    history.push({
      pathname: formatURL(pathname),
      search: `${location.search}${paramsString}`,
    });
  };
};

import { Observable, of as of$ } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ApiError, ApiResponse123 } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';
import { Ftp, ModifiedFtp, NewFtp } from '@common/model';

export class FtpClient extends BaseClient {
  fetchFtps$ = (): Observable<ApiResponse123<Ftp[]>> => this.api.get$<Ftp[]>('/contacts/postingftp', {});

  postFtp$ = (ftp: NewFtp): Observable<ApiResponse123<Ftp[]>> => {
    const postFtpResponse$: Observable<ApiResponse123<void>> = this.api.post$<void>('/contacts/postingftp', ftp);
    return postFtpResponse$.pipe(
      mergeMap((postFtpResponse: ApiResponse123<void>): Observable<ApiResponse123<Ftp[]>> => {
        if (postFtpResponse.success) {
          return this.fetchFtps$();
        }
        const error = postFtpResponse as ApiError;
        return of$(error);
      })
    );
  };

  patchFtp$ = (ftp: ModifiedFtp): Observable<ApiResponse123<Ftp[]>> => {
    const { id, ...ftpData } = ftp;
    const patchFtpResponse$: Observable<ApiResponse123<void>> = this.api.patch$<void>(
      `/contacts/postingftp/${id}`,
      ftpData,
      { headers: { 'content-type': 'application/merge-patch+json' } }
    );
    return patchFtpResponse$.pipe(
      mergeMap((patchFtpResponse: ApiResponse123<void>): Observable<ApiResponse123<Ftp[]>> => {
        if (patchFtpResponse.success) {
          return this.fetchFtps$();
        }

        const error = patchFtpResponse as ApiError;
        return of$(error);
      })
    );
  };

  deleteFtp$ = (ftpId: string) => this.api.delete$<void>(`/contacts/postingftp/${ftpId}`, {});
}

import React, { useMemo } from 'react';

import styled from 'styled-components';

import { BookingStatus, BrokerResponseSpeed, Load } from '@common/model';
import { Banner, BannerType } from '@component/banner/Banner';
import { FontLineHeight, FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

const BannerContent = styled.p`
  font-size: ${FontSize.ContentDetail}px;
  line-height: ${FontLineHeight.ContentDetail}px;
  margin-top: 5px;
`;

const useBannerTypeFromStatus = (status: BookingStatus | undefined) =>
  useMemo(() => {
    switch (status) {
      case BookingStatus.Accepted:
        return BannerType.Success;
      case BookingStatus.Pending:
        return BannerType.Warning;
      case BookingStatus.Rejected:
        return BannerType.Error;
      default:
        return undefined;
    }
  }, [status]);

const useTitleAndMessageFromStatus = (
  load: Load | undefined,
  status: BookingStatus | undefined,
  brokerResponseSpeed: BrokerResponseSpeed | undefined
) => {
  const userEmail = useSelector((state) => state.user.profile?.payload?.email);
  return useMemo(() => {
    switch (status) {
      case BookingStatus.Accepted:
        return {
          title: t(T.common_bookNow_banner_confirmedTitle),
          message: t(T.common_bookNow_banner_confirmed, {
            company: load?.poster?.name,
            email: userEmail,
          }),
        };
      case BookingStatus.Pending:
        return {
          title: t(T.common_bookNow_banner_pendingTitle),
          message:
            brokerResponseSpeed === BrokerResponseSpeed.Fast
              ? t(T.common_bookNow_banner_pendingShort)
              : t(T.common_bookNow_dialog_pendingLong),
        };
      case BookingStatus.Rejected:
        return {
          title: t(T.common_bookNow_banner_rejectedTitle),
          message: t(T.common_bookNow_banner_rejected),
        };
      default:
        return {};
    }
  }, [load, status, brokerResponseSpeed, userEmail]);
};

export const BookNowBanner = ({ load }: { load: Load | undefined }) => {
  const bookNowStatus = load?.bookNow?.bookingStatus;
  const brokerResponseSpeed = load?.bookNow?.responseSpeed;

  const bannerType = useBannerTypeFromStatus(bookNowStatus);
  const bannerContent = useTitleAndMessageFromStatus(load, bookNowStatus, brokerResponseSpeed);

  return bookNowStatus !== undefined && bannerType ? (
    <Banner
      bannerType={bannerType}
      title={bannerContent.title}
      content={<BannerContent>{bannerContent.message}</BannerContent>}
      isBookNowBanner={true}
    />
  ) : null;
};

import React from 'react';

import { difference, forEach, includes, isEmpty, some } from 'lodash';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StoreState } from '@/reduxStore/Store';
import { ApiError } from '@common/api';
import { BillingStatus, MembershipStatus, RibbonMessage, Roles } from '@common/model';
import { fetchUser } from '@common/redux/epic/UserEpic';
import { RibbonMessageType } from '@common/redux/reducer/TopRibbonReducer';
import { isCarrier } from '@component/menu/NavigationMenuHelper';
import { FixedHeader } from '@component/scrollView';
import { RibbonBase } from '@component/topRibbon';
import { t, T } from '@translate';
import { hasLoginCookies } from '@webApi/CookiesHelper';
import { redirectToLogin, setEnforcedAuthentication } from '@webApi/ResponseHandler';

import {
  isAccountSetupRequiredForBillingStatus,
  isAccountSetupRequiredForMembershipStatus,
  PAYWALL_ALLOWED_ROUTES,
  PAYWALL_BLOCKED_ROUTES,
} from './RouteHelper';
import { Routes } from './Routes';

const ALL_ROLES = 'all';
type AuthorizedRoles = Roles[][] | 'all';

interface AuthenticatedRouteProps extends RouteProps {
  requiresAuthentication?: boolean;
  needsProfile?: boolean;
  /** Roles to allow access to the route.
   * Specify the list roles or group of roles that allow the route.
   * Either entry will allow the route. To enforce multiple roles simultaneously use a sub-array of roles.
   *
   * For example:
   *
   * `authorizedRoles={[[Roles.Loadposter, Roles.Sendloadalerts]]}`
   * ...will allow the route if LoadPoster AND Sendloadalerts
   *
   * `authorizedRoles={[[Roles.Loadplanner],[Roles.Loadplanner_beta]]}`
   * ...will allow the route if Loadplanner OR Loadplanner_beta
   *
   * `authorizedRoles={[[Roles.internal],[Roles.member,Roles.Admin]]}`
   * ...will allow the route if internal OR ( member AND Admin )
   *
   */
  authorizedRoles?: AuthorizedRoles;
  shouldShowUpgradeLinkIfRestricted?: boolean;
  computedMatch?: {
    params: any;
    isExact: boolean;
    path: string;
    url: string;
  };
}

interface RoleRestrictedPageMessageProps {
  pageName?: string;
  accessRequestPhoneExtension?: number;
}

interface PropsFromRedux {
  shouldLoadProfile: boolean;
  userRoles?: Roles[];
  isCarrier: boolean;
  paymentPromptRequired: boolean;
  membershipStatus?: MembershipStatus;
  isMembershipDataFetched: boolean;
  membershipError?: ApiError;
  billingStatus?: BillingStatus;
  isUserProfileDataFetched: boolean;
  isCancellingMembership?: boolean;
}

interface DispatchProps {
  fetchUser: (redirectUnauthorized: boolean) => void;
}

type Props = AuthenticatedRouteProps & PropsFromRedux & DispatchProps & RoleRestrictedPageMessageProps;

class AuthenticatedRouteComponent extends Route<Props> {
  static defaultProps = {
    requiresAuthentication: true,
    needsProfile: false,
    authorizedRoles: ALL_ROLES as AuthorizedRoles,
  };

  render() {
    const requiresAuthentication = this.props.requiresAuthentication ? true : false;
    setEnforcedAuthentication(requiresAuthentication);

    let message: RibbonMessage[] | undefined;
    if (!this.props.computedMatch || !this.props.computedMatch.isExact) {
      return null;
    }

    const currentRoute = this.props.location?.pathname;
    // check if path is Email verification page - this takes first priority
    const isEmailVerificationPage = currentRoute === Routes.VERIFY_EMAIL_BLOCKER_PAGE;
    // check if the current route is allowed for paywall case
    const isPaywallAllowedRoute =
      some(PAYWALL_ALLOWED_ROUTES, (route) => includes(currentRoute, route)) &&
      !some(PAYWALL_BLOCKED_ROUTES, (route) => includes(currentRoute, route));
    if (
      this.props.isMembershipDataFetched &&
      this.props.membershipStatus &&
      !this.props.membershipError &&
      requiresAuthentication &&
      !isPaywallAllowedRoute &&
      !isEmailVerificationPage
    ) {
      const shouldUserChangeMembershipPlan = isAccountSetupRequiredForMembershipStatus(this.props.membershipStatus);
      if (shouldUserChangeMembershipPlan) {
        return <Redirect to={generatePath(Routes.MORE_SETTINGS_ACCOUNT_SETUP_NEW)} />;
      }
    }

    if (
      this.props.isUserProfileDataFetched &&
      this.props.isMembershipDataFetched &&
      (isAccountSetupRequiredForBillingStatus(this.props.billingStatus, this.props.isCancellingMembership) ||
        ((this.props.billingStatus === undefined || this.props.billingStatus === BillingStatus.None) &&
          this.props.membershipStatus === MembershipStatus.InTrial)) &&
      !isPaywallAllowedRoute &&
      !isEmailVerificationPage
    ) {
      return <Redirect to={generatePath(Routes.MORE_SETTINGS_ACCOUNT_SETUP_NEW)} />;
    }

    // redirect to the pending approval page for new brokers account that are pending verification
    if (
      !includes(currentRoute, Routes.MORE_SETTINGS_ACCOUNT_VERIFICATION) &&
      !isEmailVerificationPage &&
      !isPaywallAllowedRoute &&
      requiresAuthentication &&
      this.props.isUserProfileDataFetched &&
      !this.props.isCarrier &&
      this.props.isMembershipDataFetched &&
      !this.props.membershipError &&
      this.props.membershipStatus === MembershipStatus.Potential
    ) {
      return <Redirect to={Routes.MORE_SETTINGS_ACCOUNT_VERIFICATION} />;
    }

    const shouldRedirectToPaywall = !isPaywallAllowedRoute && this.props.paymentPromptRequired;

    if (
      requiresAuthentication &&
      shouldRedirectToPaywall &&
      this.props.isMembershipDataFetched &&
      !this.props.isCancellingMembership
    ) {
      return <Redirect to={Routes.MORE_SETTINGS_ACCOUNT_REDIR} />;
    }

    if (hasLoginCookies()) {
      if (this.props.shouldLoadProfile) {
        this.props.fetchUser(requiresAuthentication);
      }
    } else {
      if (requiresAuthentication) {
        redirectToLogin();
        return null;
      }
    }
    let isPageRestricted = false;
    if (this.props.location && this.props.userRoles && this.props.authorizedRoles) {
      isPageRestricted = !areUserRolesAuthorized(this.props.userRoles, this.props.authorizedRoles);
      this.props.location.state = {
        ...this.props.location.state,
        isPageRestricted: isPageRestricted,
      };
    }

    if (!this.props.isCarrier) {
      message = [
        { message: t(T.authenticatedRoute_restrictedRibbonPopUp_title), isPhoneLink: false },
        { message: t(T.authenticatedRoute_restrictedRibbonPopUp_request), isPhoneLink: false, isRequestLink: true },
        { message: t(T.authenticatedRoute_restrictedRibbonPopUp_content), isPhoneLink: false },
        { message: t(T.authenticatedRoute_restrictedRibbonPopUp_contact), isPhoneLink: true },
      ];
    } else {
      message = [
        { message: t(T.authenticatedRoute_restrictedRibbonPopUpCarrier_title), isPhoneLink: false },
        {
          message: t(T.authenticatedRoute_restrictedRibbonPopUpCarrier_request),
          isPhoneLink: false,
          isRequestLink: true,
        },
        { message: t(T.authenticatedRoute_restrictedRibbonPopUpCarrier_content), isPhoneLink: false },
      ];
    }

    if (this.props.shouldShowUpgradeLinkIfRestricted) {
      message = [
        { message: t(T.authenticatedRoute_upgradePlan_title), isPhoneLink: false },
        {
          message: t(T.authenticatedRoute_upgradePlan_link),
          isPhoneLink: false,
          isSettingsLink: true,
        },
      ];
    }

    return (
      <>
        <FixedHeader>
          {isPageRestricted ? (
            <RibbonBase
              id="restrictedmessage"
              isDismissable={false}
              isOpened={true}
              messageType={RibbonMessageType.Warning}
              withBackdrop={true}
              messages={message}
              path={this.props.path}
            />
          ) : null}
        </FixedHeader>
        {super.render()}
      </>
    );
  }
}

const areUserRolesAuthorized = (userRoles: Roles[], authorizedRoles: AuthorizedRoles) => {
  let isAuthorized = false;

  if (authorizedRoles === ALL_ROLES) {
    return true;
  } else {
    forEach(authorizedRoles, (authorizedRolesGroup: Roles[]) => {
      if (difference(authorizedRolesGroup, userRoles).length === 0) {
        isAuthorized = true;
      }
    });
  }

  return isAuthorized;
};

const mapStateToProps = (state: StoreState, ownProps: AuthenticatedRouteProps): PropsFromRedux => {
  const props: PropsFromRedux = {
    shouldLoadProfile: false,
    paymentPromptRequired: false,
    isCarrier: isCarrier(state.user.userType, state.applicationSettings.uiMenuLayout),
    isMembershipDataFetched: state.userPlan.isMembershipDataFetched,
    isUserProfileDataFetched: !!state.user.profile?.success,
    membershipError: state.userPlan.membershipError,
    membershipStatus: state.userPlan.membershipData?.status,
    isCancellingMembership: state.userPlan.membershipData?.isCancelling,
  };

  if (ownProps.needsProfile && (!state.user || isEmpty(state.user))) {
    props.shouldLoadProfile = true;
  }

  if (state.user && state.user.profile && state.user.profile.payload) {
    props.userRoles = state.user.profile.payload.roles;
    props.paymentPromptRequired = state.user.profile.payload.paymentPromptRequired;
    props.billingStatus = state.user.profile?.payload?.billingProfileStatus;
  }

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      fetchUser: (redirectUnauthorized: boolean) => fetchUser(redirectUnauthorized),
    },
    dispatch
  );

export const AuthenticatedRoute = connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRouteComponent);

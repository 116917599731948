import { compact, filter, includes, isNil, map, replace, sortBy, split, toString, uniqWith } from 'lodash';
import moment, { Moment } from 'moment';

import { monthNames } from '@common/formatter/Dates';
import { doIfValue } from '@common/helper/Functions';
import { formatNumberDecimals, formatTo2Digit } from '@common/helper/NumberHelper';

// @FIXME:  THIS REQUIRES A GOOD CLEANUP OF "FORMATS". (MEM-2086)
//  The API server only generates 2 main formats, yet I see a lot of variations here. It's a real mess.
//  Nomenclature is not precise (word ISO used not correctly).
//  "ISO-8601" uses a "T" to separate the time component, not a space, it always uses double digits. colons are optional.
//  Each format is related to a "domain".  Example of domains:
//  - formats used by the server
//  - formats for the various date / time display. (reduce this to a minimum).
//  - formats to feed very specific libraries (like a calendar picker for example)
//  we typically use strings in our data structures. (the downside is it's slower because we need to re-parse to format it)
//  reduce the number of unecessary "reparsing/format/reparsing".

const NUMBER_OF_MINUTES_IN_ONE_HOUR = 60;

const NUMBER_OF_SECONDS_IN_ONE_MIN = 60;
const NUMBER_OF_SECONDS_IN_ONE_HOUR = 3600;
const NUMBER_OF_SECONDS_IN_ONE_DAY = 86400;
export const MILLISECONDS_IN_ONE_DAY = NUMBER_OF_SECONDS_IN_ONE_DAY * 1000;

export enum TimePeriod {
  AM = 'AM',
  PM = 'PM',
}

export interface TimeObject {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function compareDates(first: Date, second: Date): boolean {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

//Format date to MM/DD
export const formatDateToMMDD = (date: Date): string => {
  let dateStr = '';
  dateStr += `${formatTo2Digit(date.getMonth() + 1)}/${formatTo2Digit(date.getDate())}`;
  return dateStr;
};

//Format date received from the backend to Month_String, DDD formating style.
export function formatDateStringToMMMDD(date: string): string {
  const dateStrings = split(date, ' ');
  if (dateStrings.length > 2) {
    return `${dateStrings[2]} ${dateStrings[1]}`;
  }
  return '';
}

//Format date to Year-Month-Day formating style.
export function formatDateToYMD(date: Date): string {
  let dateStr = '';
  dateStr += `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  return dateStr;
}

export const formatAsServerDate = (year: number, month: number, date: number) =>
  formatDateToYMD(new Date(year, month, date));

export const formatDateToMMMDD = (date: Date): string => {
  const dateString = date.toDateString();
  return dateString.substr(4, 6);
};

export const formatMinutesToHoursAndMinutes = (numberOfMinutes: number): string => {
  const leftoverMinutes = numberOfMinutes % NUMBER_OF_MINUTES_IN_ONE_HOUR;
  const numberOfFullHours = (numberOfMinutes - leftoverMinutes) / NUMBER_OF_MINUTES_IN_ONE_HOUR;

  return `${numberOfFullHours}h ${formatTo2Digit(leftoverMinutes)}m`;
};

export const secondsToTimeObject = (seconds: number) => {
  const days = Math.floor(seconds / NUMBER_OF_SECONDS_IN_ONE_DAY);
  const hours = Math.floor((seconds % NUMBER_OF_SECONDS_IN_ONE_DAY) / NUMBER_OF_SECONDS_IN_ONE_HOUR);
  const mins = Math.floor((seconds % NUMBER_OF_SECONDS_IN_ONE_HOUR) / NUMBER_OF_SECONDS_IN_ONE_MIN);
  const sec = seconds % NUMBER_OF_SECONDS_IN_ONE_MIN;

  const timeObj: TimeObject = { days: days, hours: hours, minutes: mins, seconds: sec };

  return timeObj;
};

export const simplifyDateString = (date: string) => {
  const dateData = date.split(' ');
  if (dateData.length > 2) {
    const simplifiedDay = +dateData[1];
    const simplifiedDate = `${dateData[0]} ${dateData[2]} ${simplifiedDay}`;
    return simplifiedDate;
  }
  return '—';
};
/**
 * Takes a moment date function and converts into a function that takes a either a string, date or moment
 */
const acceptAllDateTypes = (transformer: (date: Moment) => string) => (arg: string | Moment | Date) =>
  transformer(typeof arg === 'string' || arg instanceof Date ? moment(arg) : arg);

export const formatDateHHMMA = (date: string): string => moment(date).format('h:mm a');

export const formatDateMMMDDYYYY = (date: string): string => moment(date).format('MMM DD, YYYY');

export const formatDateDotSeparated = (date: string): string => moment(date).format('MM.DD.YYYY');

export const formatDateDotSeparatedWithTime = (date: string | Moment): string =>
  moment(date).format('MM.DD.YYYY hh:mm A');

export const formatDateDDDMMdd = acceptAllDateTypes((date: Moment) => date.format('ddd, MMM DD'));

export const formatDateMMMdd = (date?: string): string => moment(date).format('MMM DD');

export const formatDateTimeMMMDDhhmma = (date?: string): string => moment(date).format('MMM DD, hh:mm A');

export const formatDateTimeMMMDDYYYYhmma = (date?: string): string => moment(date).format('MMM DD, YYYY, h:mm A');

export const formatDateYYYYMMDD = (date: string | Date): string => formatMomentYYYYMMDD(moment(date).utc());

export const getTodayDateYYYYMMDD = () => formatMomentYYYYMMDD(moment());

export const formatMomentYYYYMMDD = (moment: Moment) => moment.format('YYYY-MM-DD');

export const formatDateMMDDYYYY = (date: string | Date): string => moment(date).utc().format('MM/DD/YYYY');

export const formatDateHHmm = (date: string) => moment(date).format('HH:mm');

export const formatDateDDDll = acceptAllDateTypes((date: Moment) => date.format('ddd, ll'));

export const formatDateYYYYMMDDFromNow = doIfValue(
  acceptAllDateTypes((date: Moment) => {
    if (Math.abs(date.diff(moment(), 'hours')) <= 36) {
      return date.calendar();
    }
    return formatDateDDDMMdd(date);
  })
);

export const formatTimeFromNow = doIfValue(
  acceptAllDateTypes((date: Moment) => {
    if (date === null || date === undefined) {
      return '';
    }

    if (Math.abs(date.diff(moment(), 'hours')) < 1) {
      return `${Math.abs(date.diff(moment(), 'minutes'))}m`;
    } else if (Math.abs(date.diff(moment(), 'hours')) < 24) {
      return `${Math.abs(date.diff(moment(), 'hours'))}h`;
    }

    return `${Math.abs(date.diff(moment(), 'days'))}d`;
  })
);

export const formatTimeFromNowInYears = (date: string) => {
  if (date === null) {
    return NaN;
  }
  return Math.abs(moment(date).diff(moment(), 'years'));
};

// Dates must be in format "Wed, 27 May 2020 01:00:00 GMT"
export const formatDateYYYYMMDDTHHmm = (date: string) => {
  const isTimeSet = includes(date, 'GMT');
  const dateMoment = isTimeSet ? moment(date).utc() : moment(date);
  return dateMoment.format('YYYY-MM-DDTHH:mm');
};

export const formatDateMMMD = (date: string) => moment(date).format('MMM D');

/** Converts a RFC date coming from the API server to YYYY-MM-DD format.
 *
 * This field specifies a DATE only.  The server still sends a time but uses the GMT timezone (wrongly).
 * Example:   Wed, 24 Apr 2019 00:00:00 GMT
 * We do not need the time, but it can alter the date.
 * In other words, we need to interpret it as "UTC" (even this is not UTC/GMT technically).
 */
export const serverRFCDateToYYYYMMDD = (date: string) => moment(date).utc().format('YYYY-MM-DD');

export const serverRFCDateToYYYYMMDDAndSortChronologically = (dates: string[]) =>
  sortBy(
    map(dates, (date: string) => serverRFCDateToYYYYMMDD(date)),
    (date: string) => date
  );

export const isDateExceding = (date: string | undefined, numberOfDays: number) => {
  if (!date) {
    return false;
  }
  return moment().utc().diff(moment(date).utc().add(numberOfDays, 'day'), 'h') >= 0;
};
/**
 * In some cases, the server returns an EDT (Montreal) date and time with a GMT tag (incorrectly).
 * In these cases, we need to treat the received date+time as EDT (which will then
 * converted to the user local timezone once displaying it)
 */
const serverGMTRFCToLocalTZ = (date: string) => moment(replace(date, 'GMT', 'EDT'));

/**
 * The server returns an EDT (Montreal) date and time with a GMT tag (incorrectly).
 * Sometimes we need to treat the received date+time in a way to display the time "as is"
 * (value must be the same on the screen).
 */
export const serverGMTRFCToUserLocalTZ = (date: string) => moment(replace(date, 'GMT', ''));

export const serverGMTRFCToLocalTZAndFormatISO = (date: string) =>
  serverGMTRFCToLocalTZ(date).format('YYYY-MM-DD HH:mm:ss');

/**
 * Checks if date string matches current day in user's local time zone.
 * (Passing in 'day' checks for equivalence of year, month, and day.)
 */
export const isToday = (date: string) => moment().isSame(date, 'day');

export const parseAPIDateWithoutTime = (apiDateStr: string) => {
  const date = new Date(apiDateStr);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const parseMomentDate = (
  input?: moment.MomentInput,
  format?: moment.MomentFormatSpecification,
  strict?: boolean
): Moment | undefined => validOrUndefinedMoment(moment(input, format, strict));

export const validOrUndefinedMoment = (momentObj: Moment) => (momentObj.isValid() ? momentObj : undefined);

/** Merge selected dates with a time for the server to read (ISO without TZ YYYY-MM-DDTHH:mm for pickup/dropoff date+time*/
export const convertDateAndTimeListToDateTimeIsoFormat = (dates: string[], time?: string): string[] =>
  compact(map(dates, (date) => parseMomentDateTime(date, time)?.format('YYYY-MM-DDTHH:mm') ?? false));

const parseMomentDateTime = (date: string, time?: string): Moment | undefined =>
  parseMomentDate(date + ' ' + time ?? '', ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD']);

/** @return undefined if invalid date */
export const convert12HoursFormatTo24HoursFormat = (time: string, format?: string) => {
  const momentDate = parseMomentDate(time, ['h:mm A']);
  return momentDate ? momentDate.format(format ?? 'HH:mm') : undefined;
};

export const convert24HoursFormatTo12HoursFormat = (time: string) => moment(time, 'HH:mm').format('h:mm A');

/** standard time formating for display (12 hours typically) */
export const formatTime = (dateObj: Moment) => dateObj.format('h:mm A');

/** Detect if this date has a time component.
 * This should work for both ISO, RFC, 24 hours or 12 hours.
 * Once "parsed" by momentjs there is no concept of "no time",
 * the only way is to directly test the input string for a time pattern.
 * @return false on undefined date
 */
export const isTimePresentInDate = (dateStr: string | undefined) =>
  isNil(dateStr) ? false : /[0-9]{1,2}:[0-9]{2}:[0-9]{2}/.test(dateStr);

/** extract the Time portion from DateTime string if present
 * @return undefined if invalid DateTime, undefined dateTime or if there is no time component */
export const extractTimeFromDateTimeIfPresent = (dateTime: string | undefined) => {
  if (!isTimePresentInDate(dateTime)) {
    return undefined;
  }

  return parseMomentDate(dateTime, 'DD MMM YYYY HH:mm:SS')?.format('HH:mm');
};

/** Removes the time component from a datetime string (coming from the API server) if midnight.
 * You can then use "isTimePresentInDate()" or "extractTimeFromDateTimeIfPresent()".
 */
export const stripTimeIfMidnight = (dateTime: string | undefined): string | undefined => {
  if (isNil(dateTime)) {
    return undefined;
  }

  if (!isTimePresentInDate(dateTime)) {
    return dateTime;
  }

  const momentDate = parseMomentDate(dateTime, 'ddd, DD MMM YYYY HH:mm:SS');

  if (isNil(momentDate)) {
    // format unexpected: pass-through the value without processing
    return dateTime;
  }

  if (momentDate.hours() > 0 || momentDate.minutes() > 0) {
    // not midnight, keep the date as is (not looking at seconds)
    return dateTime;
  }

  return momentDate.format('ddd, DD MMM YYYY');
};

export const convertISOToRFCAndStripTimeIfMidnight = (date: string) => {
  const momentDate = parseMomentDate(date, 'YYYY-MM-DDTHH:mm');
  if (momentDate) {
    return stripTimeIfMidnight(`${momentDate.format('ddd, DD MMM YYYY HH:mm:SS')} GMT`); // replicate format returned by API
  }
  return date;
};

export const uniqDates = (dates: string[]) => uniqWith(dates, (first, second) => moment(first).isSame(second, 'day'));

export const todayInUtcIsoString = () => parseMomentDateTime(moment(Date.now()).utc().toISOString())?.toISOString(true);

export const currentDateIfExpired = (dates: string[], returnToday: boolean = true) => {
  const today = todayInUtcIsoString();

  if (dates.length === 0 || !today) {
    return [];
  }

  const unexpiredDates = filter(dates, (date) => moment(date).isSameOrAfter(today));
  if (unexpiredDates.length > 0) {
    return unexpiredDates;
  } else {
    return returnToday ? [today] : [];
  }
};

/** This function compares date and time. This is not good to compare date alone ie: 2023-07-25 */
export const isDateExpired = (date: string | undefined): boolean => {
  if (!date) {
    return true;
  }

  const today = todayInUtcIsoString();
  return !moment(date).isSameOrAfter(today);
};

/** This function compares date only. Use this to compare date alone ie: 2023-07-25 */
export const isDateInThePast = (date: string | undefined): boolean => {
  if (!date) {
    return true;
  }

  const incomingDate = parseMomentDateTime(date);

  if (!incomingDate) {
    return true;
  }

  const today = moment();
  return incomingDate.isBefore(today, 'day');
};

export const formatSecondsToDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.round((seconds % 3600) / 60);
  return `${hours}h ${minutes < 10 ? 0 : ''}${minutes}m`;
};

export const formatRideTimeToDuration = (rideTime: string) => {
  const time = replace(rideTime, ':', 'h '); // api always returns hh:mm
  return `${time}m`;
};

export const expirationMonthsToDisplay = map(
  monthNames,
  (month, index) => `${index < 9 ? '0' : ''}${index + 1} - ${month}`
);

export const expirationYears = ((): string[] => {
  const currentYear = moment.utc().year();
  const years = [];
  for (let i = currentYear; i < currentYear + 20; i += 1) {
    years.push(`${i}`);
  }
  return years;
})();

export const formatEpochMsToTimeDate = (epochMs: number) => {
  const day = new Date(epochMs);
  const momentDay = parseMomentDate(day);
  return momentDay?.format('h:mm a, MMM DD');
};

export const formatDateWithDecrementMinutes = (interval: number | undefined) => {
  if (interval === undefined) {
    return undefined;
  }
  const dateNow = new Date();
  const pastDate = new Date(dateNow.getTime() - interval * 60000).toISOString();
  return pastDate;
};

export const formatDurationHHmm = (interval: { before: Date; after: Date } | number): string | undefined => {
  let milliseconds = 0;

  if (typeof interval === 'number') {
    milliseconds = interval;
  } else {
    if (interval.after?.getTime() && interval.before?.getTime()) {
      milliseconds = Math.abs(interval.after.getTime() - interval.before.getTime());
    } else {
      return undefined;
    }
  }

  const hours = Math.floor(milliseconds / (60 * 60 * 1000));
  const rest = milliseconds % (60 * 60 * 1000);
  const minutes = Math.floor(rest / (60 * 1000));

  return `${toString(hours)}h${toString(minutes)}m`;
};

export const isTimeStampBeforeNow = (dateTime: string) => {
  const now = moment(Date.now()).utc().toISOString();

  return validOrUndefinedMoment(moment(dateTime)) ? moment(dateTime).isBefore(now) : undefined;
};

export const addDaysHoursMinsToCurrentTimestampUTC = (days: number = 0, hours: number = 0, mins: number = 0) => {
  return moment().add(days, 'days').add(hours, 'hours').add(mins, 'minutes').utc().toISOString();
};

export const convertUtcToLocalTimestamp = (utcTimestamp: string) => {
  return validOrUndefinedMoment(moment.utc(utcTimestamp)) ? moment.utc(utcTimestamp).local().toISOString() : undefined;
};

export const convertTimeStampToDaysHoursMins = (value: string) => {
  const timeStamp = moment(value);
  const duration = moment.duration(timeStamp.diff(moment(Date.now())));

  return { days: duration.days(), hours: duration.hours(), mins: duration.minutes() };
};

export const getDifferenceInMilliseconds = (future: string, current?: string) => {
  return moment(future).diff(moment(current), 'milliseconds');
};

export const getMonthIndex = (epoch: number | string | undefined) => {
  const date = epoch ? new Date(epoch) : new Date();
  return date.getMonth() % 12;
};

export const formatSecondsToAge = (seconds?: number) => {
  if (!seconds) {
    return '';
  }
  const parsedSeconds = moment.duration(seconds, 'seconds');
  const parsedHours = parsedSeconds.asHours();
  if (parsedHours < 1) {
    return `${formatNumberDecimals(parsedSeconds.asMinutes(), 0, 0)}m`;
  }
  if (parsedHours < 24) {
    return `${formatNumberDecimals(parsedHours, 0, 0)}h`;
  }
  return `${formatNumberDecimals(parsedSeconds.asDays(), 0, 0)}d`;
};

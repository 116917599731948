import React from 'react';

import { FMCSAAuthorityStatus, Truck } from '@common/model';
import { CheckboxChip } from '@component/input';
import { LoadingProgress } from '@component/progress';
import { Text } from '@component/text/Text';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';

interface TruckChipProps {
  id: string;
  truck: Truck;
  onClick: () => void;
  isDisabled?: boolean;
}

// @FIXME: In the latest version of Material-UI, the chips have a disabled UI state.
// When Material-UI is updated, these should be updated to use the disabled prop.
export const FavoriteChip: React.FC<TruckChipProps> = (props) => (
  <CheckboxChip
    id={`${props.id}_info_favorite_button`}
    isChecked={props.truck.isFavoriteCarrier}
    clickable={!props.isDisabled}
    label={props.truck.isFavoriteCarrier ? t(T.truckLocator_Favorite) : t(T.truckLocator_AddToFavorites)}
    onClick={props.isDisabled ? undefined : props.onClick}
  />
);

export const circularProgress = (id: string, isLoading: boolean) =>
  isLoading ? <LoadingProgress id={id} small={true} /> : null;

const AuthorityStatusRow: React.FC<{
  id: string;
  label: string;
  status: FMCSAAuthorityStatus | undefined;
  isLastRow?: boolean;
}> = (props) => {
  let status = NO_INFORMATION_SYMBOL;
  let textColor: string | undefined;
  switch (props.status) {
    case FMCSAAuthorityStatus.Active:
      status = t(T.truckLocator_fmcsaAuthority_status_active);
      textColor = Color.GREEN_MAIN;
      break;
    case FMCSAAuthorityStatus.Inactive:
      status = t(T.truckLocator_fmcsaAuthority_status_inactive);
      textColor = Color.RED_APPLE;
      break;
  }
  return (
    <Text id={props.id} style={{ marginBottom: props.isLastRow ? 0 : 10 }}>
      {`${props.label}: `}
      <Text id={`${props.id}_status`} style={textColor ? { color: textColor } : undefined}>
        {status}
      </Text>
    </Text>
  );
};

export const AuthorityStatusRows: React.FC<{
  id: string;
  truck: Truck;
}> = (props) => (
  <>
    <AuthorityStatusRow
      id={`${props.id}_common`}
      label={t(T.truckLocator_fmcsaAuthority_type_common)}
      status={props.truck.commonAuthorityStatus}
    />
    <AuthorityStatusRow
      id={`${props.id}_contract`}
      label={t(T.truckLocator_fmcsaAuthority_type_contract)}
      status={props.truck.contractAuthorityStatus}
    />
    <AuthorityStatusRow
      id={`${props.id}_broker`}
      label={t(T.truckLocator_fmcsaAuthority_type_broker)}
      status={props.truck.brokerAuthorityStatus}
      isLastRow={true}
    />
  </>
);

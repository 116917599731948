import React, { BaseSyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { ListItemText, MenuItem } from '@material-ui/core';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { markAllBidsAsViewed } from '@common/redux/epic/bids/BidsEpic';
import { MoreMenuButton } from '@component/buttons/MoreMenuButton';
import { DropdownMenu } from '@component/menu/DropdownMenu';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { PanelHeader } from '@component/panel';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton } from '@component/popup/PopupButtons';
import { PopupContentSection } from '@component/popup/PopupContentSections';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text } from '@component/text';
import { useIsBiddingEnabled, useIsVendorBidsListEnabled } from '@page/bids/BidStatusHelper';
import { FontSize, FontWeight } from '@style/StyleConstants';
import { T, t } from '@translate';
import { usePrevious, useSelector } from '@util/hooks';

interface BidsHeaderProps {
  isStandalone?: boolean;
  closeAndRedirect?: () => void;
  subheader?: JSX.Element;
}

export const BidsPanelHeader: React.FC<BidsHeaderProps> = (props) => {
  const dispatch = useDispatch();
  const { closingPopup, openingPopup } = usePopup();
  const isBiddingEnabled = useIsBiddingEnabled();
  const isVendorBidsListEnabled = useIsVendorBidsListEnabled();
  const isCarrier = useIsCarrier();

  const [menuAnchor, setMenuAnchor] = useState(undefined);
  const markAsViewedState = useSelector((state) => state.bids.markAllBidsAsViewed);
  const prevMarkAsViewedIsLoading = usePrevious(markAsViewedState.isLoading);

  useEffect(() => {
    if (!markAsViewedState.isLoading && prevMarkAsViewedIsLoading) {
      if (markAsViewedState.wereAllMarkedAsViewed) {
        dispatch(showSnackbar({ message: t(T.common_bids_markAllAsViewed_success) }));
      } else {
        dispatch(showSnackbar({ message: t(T.common_bids_markAllAsViewed_failure) }));
      }
    }
  }, [markAsViewedState]);

  const handleMenuClick = (event: BaseSyntheticEvent) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMarkAllBidsAsViewed = () => {
    dispatch(markAllBidsAsViewed());
  };

  const onClickInfo = useCallback(() => {
    let dialogMessage = '';
    if (isBiddingEnabled && isVendorBidsListEnabled) {
      dialogMessage = `${t(T.common_bids_infoDialog_message_introBothActive)}${t(
        T.common_bids_infoDialog_message_vendor
      )}${t(T.common_bids_infoDialog_message_interactiveBothActive)}`;
    } else if (isBiddingEnabled) {
      dialogMessage = t(T.common_bids_infoDialog_message_interactiveOnly);
    } else if (isVendorBidsListEnabled) {
      dialogMessage = t(T.common_bids_infoDialog_message_vendor);
    }

    openingPopup({
      body: (
        <BasePopup
          title={t(T.common_bids_infoDialog_title)}
          onClose={closingPopup}
          withoutContentPadding={true}
          buttons={[<PopupOkButton testID={t(T.common_ok)} key={t(T.common_ok)} action={closingPopup} />]}
        >
          <PopupContentSection>
            <Text
              id="info_popup_text_1"
              style={{
                fontFamily: `${FontWeight.Regular}`,
                fontSize: `${FontSize.Content}px`,
              }}
            >
              {dialogMessage}
            </Text>
          </PopupContentSection>
        </BasePopup>
      ),
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  }, [isBiddingEnabled, isVendorBidsListEnabled]);

  const menuOptions = useMemo(() => {
    const closeMenu = (callback: () => void) => () => {
      setMenuAnchor(undefined);
      callback();
    };
    const menuItems = [];
    if (isBiddingEnabled) {
      menuItems.push(
        <MenuItem id="mark_bids_viewed" key="mark_bids_viewed" onClick={closeMenu(handleMarkAllBidsAsViewed)}>
          <ListItemText id="mark_bids_viewed_title" primary={t(T.common_bids_markAllAsViewed_title)} />
        </MenuItem>
      );
    }
    if (isCarrier) {
      menuItems.push(
        <MenuItem id="about_bids" key="about_bids" onClick={closeMenu(onClickInfo)}>
          <ListItemText id="about_bids_title" primary={t(T.common_bids_infoDialog_menuOption)} />
        </MenuItem>
      );
    }
    return menuItems;
  }, [isBiddingEnabled, isCarrier, onClickInfo]);

  return (
    <PanelHeader
      style={{ padding: '0 6px' }}
      label={t(T.common_bids_title)}
      hasCloseButton={!props.isStandalone}
      onClose={props.closeAndRedirect}
      actionElement={<MoreMenuButton ref={menuAnchor} onClick={handleMenuClick} />}
      subheader={props.subheader}
    >
      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(undefined)}>
        {menuOptions}
      </DropdownMenu>
    </PanelHeader>
  );
};

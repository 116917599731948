import { first } from 'lodash';

import { Patch } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';
import {
  AlertSettings,
  AppThemeAPI,
  AppThemeSettings,
  EmailVerificationSettings,
  HomeLocation,
  LoadSearchSettings,
  LocationType,
  RoutesSettings,
  SettingsChangeFeedResponse,
  TruckLocatorSettings,
  UserSettings,
  WebViewPages,
} from '@common/model';
import { DeviceSettings } from '@common/model/DeviceSettings';
import { SystemSettingResponse } from '@common/model/SystemSettings';

export class SettingsClient extends BaseClient {
  fetchTruckLocatorSettings$ = () => this.api.get$<TruckLocatorSettings>('/settings/trucklocator', {});
  updateTruckLocatorSettings$ = (settings: Partial<TruckLocatorSettings>, hadSettings: boolean) =>
    hadSettings
      ? this.api.mergePatch$<TruckLocatorSettings>('/settings/trucklocator', settings)
      : this.api.put$<TruckLocatorSettings>('/settings/trucklocator', settings);

  fetchRoutesSettings$ = () => this.api.get$('/settings/routes', {});
  updateRoutesSettings$ = (settings: RoutesSettings) =>
    this.api.patch$<RoutesSettings>('/settings/routes', settings, {
      headers: { 'content-type': 'application/merge-patch+json' },
    });

  fetchLoadSearchSettings$ = () => this.api.get$<LoadSearchSettings>('/settings/loadsearch', {});
  createLoadSearchSettings$ = (loadSearchSettings: LoadSearchSettings) =>
    this.api.post$<LoadSearchSettings>('/settings/loadsearch', loadSearchSettings);
  setHomeLocation$ = (location: HomeLocation, hadSettings: boolean) =>
    hadSettings
      ? this.api.patch$<LoadSearchSettings>('/settings/loadsearch', locationPatchFor(location))
      : this.api.post$<LoadSearchSettings>('/settings/loadsearch', locationPostFor(location));
  fetchDeviceSetting$ = (fields: string) => this.api.get$<DeviceSettings>('/settings', { fields: fields });
  setAppTheme$ = (mainTheme: AppThemeAPI) =>
    this.api.mergePatch$<AppThemeSettings>('/settings/theme', { mainTheme: mainTheme });
  fetchAppTheme$ = () => this.api.get$<AppThemeSettings>('/settings/theme', {});
  fetchUserSettings = (
    forceRefreshToken: boolean = false,
    params: {} = { fields: 'hasCompany,hasTruck,emailIsValidated,liveLoadRefreshInterval' }
  ) => this.api.get$<UserSettings>('/settings', params, undefined, { forceRefreshToken: forceRefreshToken });
  fetchAlertSettings$ = (
    forceRefreshToken: boolean = false,
    params: {} = { fields: 'isSearchAlertsActive,searchAlertIntervalInSeconds' }
  ) => this.api.get$<AlertSettings>('/settings', params, undefined, { forceRefreshToken: forceRefreshToken });
  fetchEmailVerificationSettings$ = (params: {} = { fields: 'emailIsValidated' }) =>
    this.api.get$<EmailVerificationSettings>('/settings', params, undefined, { forceRefreshToken: true });
  setUiLayout$ = (uiLayout: string, hadSettings: boolean, webViewPage?: WebViewPages) =>
    hadSettings
      ? this.api.mergePatch$<LoadSearchSettings>('/settings/loadsearch', updateUi(uiLayout, webViewPage))
      : this.api.post$<LoadSearchSettings>('/settings/loadsearch', updateUi(uiLayout, webViewPage));

  getSettingByName$ = (setting: string) => this.api.get$<SystemSettingResponse>(`/settings/self/byname/${setting}`, {});
  putSettingsByName$ = <T>(name: string, data: T) =>
    this.api.put$<{}>(`/settings/self/byname/${name}`, { value: data, deviceAgnostic: true });
  getSettingsChangeFeed$ = (changeFeedToken: string | undefined) =>
    this.api.get$<SettingsChangeFeedResponse>('/settings/self/changefeed', { changeFeedToken: changeFeedToken });
}

const locationValueFor = (location: HomeLocation) => {
  switch (location.type) {
    case LocationType.CITY: {
      return { city: location.city, state: first(location.states), zipCode: '' };
    }
    case LocationType.ZIP: {
      return { zipCode: location.zipCode, city: location.city, state: first(location.states) };
    }
    case LocationType.GEOLOCATION: {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
        city: location.city,
        state: first(location.states),
        zipCode: '',
      };
    }
  }
  return undefined;
};

const updateUi = (uiLayout: string, webViewPage?: WebViewPages) => {
  if (!webViewPage) {
    return { appUiLayout: uiLayout };
  }
  switch (webViewPage) {
    case WebViewPages.FindLoads: {
      return { webUiLayout: uiLayout };
    }
    case WebViewPages.MyLoads: {
      return { webMyLoadsUiLayout: uiLayout };
    }
    case WebViewPages.LoadAvailability: {
      return { webAvailabilityUiLayout: uiLayout };
    }
    case WebViewPages.BrokerLoads: {
      return { webBrokerUiLayout: uiLayout };
    }
  }
  return undefined;
};

const locationPatchFor = (location: HomeLocation) => {
  const locationValue = locationValueFor(location);
  const updates: Array<Patch<any>> = [{ op: 'replace', path: '/homeLocationType', value: location.type }];
  switch (location.type) {
    case LocationType.ZIP:
    case LocationType.CITY:
      updates.push({ op: 'replace', path: '/homeAddress', value: locationValue });
      updates.push({ op: 'replace', path: '/homeGeolocation', value: {} });
      break;
    case LocationType.GEOLOCATION:
      updates.push({ op: 'replace', path: '/homeGeolocation', value: locationValue });
      updates.push({ op: 'replace', path: '/homeAddress', value: {} });
      break;
    default:
      return [];
  }
  return updates;
};

const locationPostFor = (location: HomeLocation) => {
  const locationValue = locationValueFor(location);
  const locationPost: any = {
    homeLocationType: location.type,
    homeAddress: undefined,
    homeGeolocation: undefined,
  };
  switch (location.type) {
    case LocationType.ZIP:
    case LocationType.CITY:
      locationPost.homeAddress = locationValue;
      break;
    case LocationType.GEOLOCATION:
      locationPost.homeGeolocation = locationValue;
      break;
    default:
      return undefined;
  }
  return locationPost;
};

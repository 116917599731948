import { cloneDeep, findIndex, map, remove } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { LoadVisibilityRulesClient } from '@/client';
import { LoadVisibilityRuleset, LoadVisibilityRulesetResponse } from '@/model/LoadVisibilityRules';
import { Api } from '@common/api';
import { createAction, createApiAction, createApiActionWithFetchData, EmptyResponse } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

import { convertRulesetFromServer } from './LoadVisibilityRulesHelper';

export interface LoadVisibilityRulesState {
  rulesets: LoadVisibilityRuleset[];
  ruleset: LoadVisibilityRuleset;
  isLoading: boolean;
  wasRulesetCreated?: boolean;
  wasRulesetUpdated?: boolean;
  wasRulesetDeleted?: boolean;
  didFetchFail?: boolean;
}

const initialState: LoadVisibilityRulesState = {
  isLoading: false,
  rulesets: [],
  ruleset: { id: '', rules: [] },
};

const clearSelectedRulesetAction = createAction<undefined>('CLEAR_SELECTED_RULE_SET');
const fetchAllRulesetsAction = createApiAction<undefined, { rulesets: LoadVisibilityRulesetResponse[] }>(
  'FETCH_ALL_POSTING_RULE_SETS'
);
const fetchRulesetAction = createApiAction<string, LoadVisibilityRulesetResponse>('FETCH_POSTING_RULE_SET');
const createRulesetAction = createApiAction<LoadVisibilityRuleset, LoadVisibilityRulesetResponse>(
  'CREATE_POSTING_RULE_SET'
);
const deleteRulesetAction = createApiActionWithFetchData<string, EmptyResponse>('DELETE_POSTING_RULE_SET');
const updateRulesetAction = createApiActionWithFetchData<LoadVisibilityRuleset, LoadVisibilityRulesetResponse>(
  'UPDATE_POSTING_RULE_SET'
);

export const fetchAllRulesets = () => fetchAllRulesetsAction.fetchAction(undefined);
export const fetchRuleset = (id: string) => fetchRulesetAction.fetchAction(id);
export const createRuleset = (request: LoadVisibilityRuleset) => createRulesetAction.fetchAction(request);
export const deleteRuleset = (id: string) => deleteRulesetAction.fetchAction(id);
export const updateRuleset = (request: LoadVisibilityRuleset) => updateRulesetAction.fetchAction(request);
export const clearSelectedRuleset = () => clearSelectedRulesetAction.action(undefined);

export const loadVisibilityRulesReducer = createMergedReducer(initialState, [
  clearSelectedRulesetAction.addCase((state) => {
    state.ruleset = { id: '', rules: [] };
  }),

  createRulesetAction.initiateCase((state) => {
    state.isLoading = true;
    state.wasRulesetCreated = undefined;
  }),
  createRulesetAction.completeCase((state, action) => {
    state.isLoading = false;
    state.wasRulesetCreated = action.response.success;
    if (action.response.success) {
      state.rulesets.push(convertRulesetFromServer(action.response.payload));
    }
  }),

  fetchAllRulesetsAction.initiateCase((state) => {
    state.isLoading = true;
    state.didFetchFail = undefined;
  }),
  fetchAllRulesetsAction.completeCase((state, action) => {
    state.isLoading = false;
    if (action.response.success) {
      state.rulesets = map(action.response.payload.rulesets, convertRulesetFromServer);
    } else {
      state.didFetchFail = true;
    }
  }),

  fetchRulesetAction.initiateCase((state) => {
    state.ruleset.isLoading = true;
  }),
  fetchRulesetAction.completeCase((state, action) => {
    state.ruleset.isLoading = false;
    if (action.response.success) {
      state.ruleset = convertRulesetFromServer(action.response.payload);
    }
  }),

  updateRulesetAction.initiateCase((state) => {
    state.ruleset.isLoading = true;
    state.wasRulesetUpdated = undefined;
  }),
  updateRulesetAction.completeCase((state, action) => {
    state.ruleset.isLoading = false;
    state.wasRulesetUpdated = action.response.success;

    if (action.fetchData && action.response.success) {
      const newRuleset = convertRulesetFromServer(action.response.payload);
      const newRulesetList = cloneDeep(state.rulesets);
      const index = findIndex(state.rulesets, (ruleSet) => ruleSet.id === action.fetchData?.id);
      if (index !== -1) {
        newRulesetList[index] = newRuleset;
      }

      if (action.fetchData.isDefault !== state.ruleset.isDefault) {
        const prevDefaultRulesetIndex = state.rulesets.findIndex((ruleset) => ruleset.isDefault);
        if (prevDefaultRulesetIndex > -1) {
          newRulesetList[prevDefaultRulesetIndex].isDefault = false;
        }
      }

      state.rulesets = newRulesetList;
      state.ruleset = newRuleset;
    }
  }),

  deleteRulesetAction.initiateCase((state) => {
    state.ruleset.isLoading = true;
    state.wasRulesetDeleted = undefined;
  }),
  deleteRulesetAction.completeCase((state, action) => {
    state.ruleset.isLoading = false;
    state.wasRulesetDeleted = action.response.success;

    if (action.fetchData && action.response.success) {
      remove(state.rulesets, (ruleSet) => ruleSet.id.toString() === action.fetchData);
      state.ruleset = initialState.ruleset;
    }
  }),
]);

export const createLoadVisibilityRulesEpic = (api: Api) => {
  const client = new LoadVisibilityRulesClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      createRulesetAction.createEpic$(action$, client.createRuleset$),
      fetchAllRulesetsAction.createEpic$(action$, client.getAllRulesets$),
      fetchRulesetAction.createEpic$(action$, client.getRuleset$),
      updateRulesetAction.createEpic$(action$, client.modifyRuleset$),
      deleteRulesetAction.createEpic$(action$, client.deleteRuleset$)
    );
};

import { map } from 'lodash';

import { displayCurrencyOrDash, NO_INFORMATION_SYMBOL } from '@common/formatter';
import { formatRideTimeToDuration, stringifyLocation } from '@common/helper';
import {
  ExtraStopLocationType,
  Load,
  LoadExtraStop,
  MileageRoutesPostRequest,
  StopsLocation,
  SuggestionTypes,
} from '@common/model';
import { RouteDirections } from '@common/model/PCMiler';

export const getSummaryRoadInfo = (load: Load | undefined, directions: RouteDirections | undefined) => ({
  origin: stringifyLocation(load?.originLocation?.address).locationName,
  destination: stringifyLocation(load?.destinationLocation?.address).locationName,
  mileage: directions?.rideMiles || '',
  time: directions?.rideTime ? formatRideTimeToDuration(directions?.rideTime) : NO_INFORMATION_SYMBOL, // api always returns hh:mm
  tollCost: displayCurrencyOrDash(directions?.tollCosts),
});

export const convertLoadToMileageRoutesPostRequest = (load: Load): MileageRoutesPostRequest => {
  const stopsLocations: StopsLocation[] = [];
  stopsLocations.push({
    address: {
      type: load.originLocation?.type as SuggestionTypes,
      city: load.originLocation?.address?.city,
      state: load.originLocation?.address?.state,
      zipCode: load.originLocation?.address?.zipCode,
      zip: load.originLocation?.address?.zipCode,
    },
  });
  map(load.extraStops, (stop) => {
    stopsLocations.push({
      address: {
        type: getStopsLocationTypeFromExtraStop(stop),
        city: stop.address?.city,
        state: stop.address?.state,
        zipCode: stop.address?.zipCode,
        zip: stop.address?.zipCode,
      },
    });
  });
  stopsLocations.push({
    address: {
      type: load.destinationLocation?.type as SuggestionTypes,
      city: load.destinationLocation?.address?.city,
      state: load.destinationLocation?.address?.state,
      zipCode: load.destinationLocation?.address?.zipCode,
      zip: load.destinationLocation?.address?.zipCode,
    },
  });
  return {
    stops: stopsLocations,
  };
};

const getStopsLocationTypeFromExtraStop = (stop: LoadExtraStop) => {
  switch (stop.type) {
    case ExtraStopLocationType.ZipCode:
      return SuggestionTypes.ZipCode;
    case ExtraStopLocationType.Geolocation:
      return SuggestionTypes.Geolocation;
    case ExtraStopLocationType.CityState:
      return SuggestionTypes.CityState;
    default:
      return undefined;
  }
};

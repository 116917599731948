import React, { useEffect, useMemo, useState } from 'react';

import { map } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import { Button } from '@material-ui/core';

import ErrorIcon from '@/icons/icon_error_block.svg';
import InfoIcon from '@/icons/icon_info.svg';
import SuccessIcon from '@/icons/icon_success.svg';
import WarningIcon from '@/icons/icon_warning.svg';
import { StoreState } from '@/reduxStore/Store';
import { Routes } from '@/router/Routes';
import { useSelector } from '@/util/hooks';
import { GENERAL_CONTACT_PHONE_NUMBER } from '@common/info';
import { RibbonMessage } from '@common/model';
import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import { requestBrokerFeature } from '@common/redux/epic/FeedbackEpic';
import { closeRibbon, openRibbon, RibbonMessageType } from '@common/redux/reducer/TopRibbonReducer';
import { PhoneNumber, UpgradeLink } from '@component/contact';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { useUnlockFeaturePopup } from '@component/popup/UnlockFeaturePopup';
import { Text } from '@component/text/Text';
import { Color } from '@style/Color';
import { MENU_TOP_BAR_ZINDEX } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { t, T } from '@translate';

const DialogBackdrop = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ERROR_DIV_MAX_HEIGHT = 230;
export const TOP_RIBBON_ID = 'Top_Ribbon';

interface RibbonDivProps {
  messageType: RibbonMessageType;
  dismissed: boolean;
}

export const RibbonDiv = withTheme()(styled.div`
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: ${ERROR_DIV_MAX_HEIGHT}px;
  background-color: ${(props: RibbonDivProps) => colorForMessageType(props.messageType)};
  z-index: ${MENU_TOP_BAR_ZINDEX};
`);

export const RibbonTextDiv = withTheme()(styled.div`
  margin: 17px 0px 17px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex: 1;

  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.md}px) {
    margin: 20px 0px 20px 0px;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    margin: 25px 8px 8px 0px;
    flex-direction: column;
    align-items: flex-end;
  }
`);

export const DismissButton = withTheme()(styled(Button)`
  && {
    width: 94px;
    height: 36px;
    margin: 0px 16px 0px 95px;
    @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
      margin: 21px 0px 0px 95px;
    }
  }
`);

export const ImageIcon = withTheme()(styled.img`
  && {
    height: 30px;
    width: 30px !important;
    min-width: 30px !important;
    margin: 24px;
    box-sizing: content-box;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`);

interface TopRibbonProps {
  messages?: RibbonMessage[];
  messageType: RibbonMessageType;
  isOpened: boolean;
  isDismissable: boolean;
  withBackdrop?: boolean;
  closeRibbon?: () => void;
  path?: string | string[];
}

interface TopRibbonActionProps {
  openRibbon: (msg: RibbonMessage[], type: RibbonMessageType) => void;
  closeRibbon: () => void;
}

class TopRibbonComponent extends React.Component<TopRibbonProps & TopRibbonActionProps> {
  render() {
    return this.props.isOpened ? <RibbonBase id={TOP_RIBBON_ID} {...this.props} /> : null;
  }
}

export const RibbonBase: React.FC<TopRibbonProps & { id: string }> = ({
  id,
  withBackdrop,
  isOpened,
  messageType,
  isDismissable,
  closeRibbon,
  messages,
  path,
}) => {
  return (
    <>
      {withBackdrop ? <DialogBackdrop /> : null}
      <RibbonDiv id={id} dismissed={!isOpened} messageType={messageType}>
        <ImageIcon id={`${id}_not_listed_icon`} src={iconForMessageType(messageType)} style={{ borderRadius: '0px' }} />
        <RibbonTextDiv id={`${id}_text_div`}>
          <RenderTexts id={`${id}_text_div`} messages={messages} path={path} />
          {isDismissable ? (
            <DismissButton id={`${id}_text_div_dismiss_button`} onClick={closeRibbon}>
              {t(T.Dismiss)}
            </DismissButton>
          ) : undefined}
        </RibbonTextDiv>
      </RibbonDiv>
    </>
  );
};

const RenderTexts: React.FC<{ id: string; messages?: RibbonMessage[]; path?: string | string[] }> = ({
  id,
  messages,
  path,
}) => {
  const { openingPopup, closingPopup } = usePopup();
  const dispatch = useDispatch();
  const isBroker = !useIsCarrier();
  const openUnlockPopup = useUnlockFeaturePopup();
  const response = useSelector((state) => state.feedbackState.response);
  const isLoading = useSelector((state) => state.feedbackState.isBrokerFeatureRequestLoading);
  const [wasRequestSent, setWasRequestSent] = useState(false);

  useEffect(() => {
    if (wasRequestSent && !isLoading) {
      if (response.success) {
        handleSubmitSuccess();
      } else {
        handleSubmitFailure();
      }
      setWasRequestSent(false);
    }
  }, [isLoading, response]);

  const submitRequestFeature = () => {
    closingPopup();
    dispatch(requestBrokerFeature(isBroker));
    setWasRequestSent(true);
  };

  const handleRequestClick = () => {
    openingPopup({
      body: (
        <BasePopup
          title={
            isBroker
              ? t(T.findLoads_findLoads_popUps_featureRequest_title)
              : t(T.findLoads_findLoads_popUps_featureRequestCarrier_title)
          }
          buttons={[
            <PopupPrimaryButton
              testID={t(T.common_ok)}
              key={t(T.common_ok)}
              label={t(T.findLoads_findLoads_popUps_featureRequest_button)}
              action={submitRequestFeature}
            />,
          ]}
        >
          {isBroker
            ? t(T.findLoads_findLoads_popUps_featureRequest_content)
            : t(T.findLoads_findLoads_popUps_featureRequestCarrier_content)}
        </BasePopup>
      ),
      isDismissible: false,
      width: PopupSizes.SMALL,
    });
  };

  const handleSubmitSuccess = () => {
    openingPopup({
      body: (
        <BasePopup
          title={t(T.findLoads_findLoads_popUps_featureSubmit_title)}
          buttons={[
            <PopupPrimaryButton
              testID={t(T.common_ok)}
              key={t(T.common_ok)}
              label={t(T.findLoads_findLoads_popUps_featureSubmit_button)}
              action={closingPopup}
            />,
          ]}
        >
          {isBroker
            ? t(T.findLoads_findLoads_popUps_featureSubmit_content)
            : t(T.findLoads_findLoads_popUps_featureSubmit_contentCarrier)}
        </BasePopup>
      ),
      isDismissible: false,
      width: PopupSizes.SMALL,
    });
  };

  const handleSubmitFailure = () => {
    openingPopup({
      body: (
        <BasePopup
          title={t(T.findLoads_findLoads_popUps_featureSubmitFail_title)}
          buttons={[
            <PopupPrimaryButton
              testID={t(T.common_ok)}
              key={t(T.common_ok)}
              label={t(T.findLoads_findLoads_popUps_featureSubmit_button)}
              action={closingPopup}
            />,
          ]}
        >
          {isBroker
            ? t(T.findLoads_findLoads_popUps_featureSubmitFail_content)
            : t(T.findLoads_findLoads_popUps_featureSubmitFail_contentCarrier)}
        </BasePopup>
      ),
      isDismissible: false,
      width: PopupSizes.SMALL,
    });
  };

  const popupType = useMemo<UnlockFeaturePopupType>(() => {
    if (path === Routes.RESOURCES_SEARCH_DIRECTORY) {
      return UnlockFeaturePopupType.SearchDirectory;
    }
    if (path === Routes.TOOLS_MARKET_RATES_CARRIER) {
      return UnlockFeaturePopupType.MarketRates;
    }
    return UnlockFeaturePopupType.MileageCalculator;
  }, [path]);

  return (
    <div>
      {map(messages, (message: RibbonMessage, index: number) => {
        if (message.isPhoneLink) {
          return (
            <PhoneNumber
              id={`${id}_item_${index}_phone_number`}
              phoneNumberValue={GENERAL_CONTACT_PHONE_NUMBER}
              phoneNumberString={message.message}
              key={message.message}
            />
          );
        } else if (message.isSettingsLink) {
          return (
            <UpgradeLink
              id={`${id}_item_${index}_link_to_upgrade`}
              linkLabel={message.message}
              onClick={() => openUnlockPopup(popupType)}
              wrap={false}
              isLarge={false}
              key={message.message}
            />
          );
        } else if (message.isRequestLink) {
          return (
            <UpgradeLink
              id={`${id}_item_${index}_link_to_request_broker`}
              linkLabel={message.message}
              onClick={() => {
                handleRequestClick();
              }}
              wrap={false}
              isLarge={false}
              key={message.message}
            />
          );
        } else {
          return (
            <Text id={id + '_item_' + index + '_message'} key={message.message}>
              {message.message}
            </Text>
          );
        }
      })}
    </div>
  );
};

const iconForMessageType = (type: RibbonMessageType) => {
  switch (type) {
    case RibbonMessageType.Error:
      return ErrorIcon;
    case RibbonMessageType.Warning:
      return WarningIcon;
    case RibbonMessageType.Success:
      return SuccessIcon;
    default:
      return InfoIcon;
  }
};

const colorForMessageType = (type: RibbonMessageType) => {
  switch (type) {
    case RibbonMessageType.Error:
      return Color.RED_PINK;
    case RibbonMessageType.Warning:
      return Color.ORANGE_FADED;
    case RibbonMessageType.Success:
      return Color.GREEN_LIGHT;
    default:
      return Color.BLUE_LIGHT;
  }
};
const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators(
    {
      openRibbon: openRibbon,
      closeRibbon: closeRibbon,
    },
    dispatch
  );

const mapStateToProps = (state: StoreState): TopRibbonProps => {
  if (state.topRibbon) {
    return {
      isOpened: state.topRibbon.isOpened,
      messages: state.topRibbon.messages,
      messageType: state.topRibbon.messageType,
      isDismissable: state.topRibbon.messageType !== RibbonMessageType.Error,
    };
  }
  return { isOpened: false, messageType: RibbonMessageType.Warning, isDismissable: true };
};

export const TopRibbon = connect<TopRibbonProps, TopRibbonActionProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(TopRibbonComponent);

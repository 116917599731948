import { RefObject, useEffect, useRef } from 'react';

import { noop } from 'lodash';

import { useIsKeyboardOpen } from './KeyboardOpen';

const INIT_SCROLL_TRACKING = {
  lastScroll: 0,
  anchorPos: 0,
  isTouching: false,
  requestAnimationFrameId: 0,
  touchendDetectTimerId: 0,
};

/** Implements collabsible headers for a nested div scroll.
 *
 * Behavior:
 * - The collapsible movement of the header is synchronized with the scroll.
 * - Upon scroll stop, the header animate to be fully visible or fully hidden (unless near the top).
 */
export const useScrollingDivCollapsibleLogic = (
  collapsibleRef: RefObject<HTMLDivElement>,
  scrollingDivRef: RefObject<HTMLDivElement>
) => {
  const scrollTracking = useRef(INIT_SCROLL_TRACKING);
  const scrollTrack = scrollTracking.current;
  const getScrollPos = () => scrollingDivRef.current?.scrollTop ?? 0;
  const newHeaderPosition = () => Math.min(scrollTracking.current.anchorPos, getScrollPos());

  useEffect(() => {
    const scrollingDiv = scrollingDivRef.current;

    if (!scrollingDiv) {
      return noop;
    }

    const { onScroll } = collapsibleLogic(scrollTrack, collapsibleRef, newHeaderPosition, getScrollPos);

    scrollingDiv.addEventListener('scroll', onScroll);
    return () => {
      scrollingDiv.removeEventListener('scroll', onScroll);
    };
  }, []);
};

/** Implements collabsible headers for full window scroll.
 *
 * Behavior:
 * - The collapsible movement of the header is synchronized with the scroll.
 * - Upon scroll stop, the header animate to be fully visible or fully hidden (unless near the top).
 * - Within the same gesture the header will not "reappear". User has to lift the finger to make the header jump.
 * - Support for a synthetic scroll positions when faking a scroll position
 */
export const useWindowCollapsibleLogic = (
  collapsibleRef: RefObject<HTMLDivElement>,
  overrideScrollPosition: RefObject<number | undefined>
) => {
  const scrollTracking = useRef(INIT_SCROLL_TRACKING);
  const scrollTrack = scrollTracking.current;

  const { isKeyboardOpen } = useIsKeyboardOpen();
  const getScrollPos = () => overrideScrollPosition.current ?? window.pageYOffset;
  const newHeaderPosition = () => (isKeyboardOpen ? 0 : Math.min(scrollTrack.anchorPos - getScrollPos(), 0));

  useEffect(() => {
    const { onScroll, detectScrollEnd } = collapsibleLogic(
      scrollTrack,
      collapsibleRef,
      newHeaderPosition,
      getScrollPos
    );

    const cancelTouchEndDetection = () => {
      if (scrollTrack.touchendDetectTimerId > 0) {
        clearTimeout(scrollTrack.touchendDetectTimerId);
        scrollTrack.touchendDetectTimerId = 0;
      }
    };

    const detectTouchEnd = () => {
      // Failsafe in case the touchend is never fired (affects some Android phones mostly).
      cancelTouchEndDetection();
      scrollTrack.touchendDetectTimerId = setTimeout(onTouchEnd, 6000) as unknown as number;
    };

    const onTouchStartMove = () => {
      scrollTrack.isTouching = true;
      detectTouchEnd();
    };

    const onTouchEnd = (event?: TouchEvent) => {
      cancelTouchEndDetection();
      scrollTrack.isTouching = (event?.touches?.length ?? 0) > 0;
      detectScrollEnd();
    };

    if (isKeyboardOpen) {
      onTouchEnd();
      return noop;
    }

    if (overrideScrollPosition.current !== undefined) {
      // when faking a position (i.e. sidemenu showing), stop events
      return noop;
    }

    window.addEventListener('scroll', onScroll);
    window.addEventListener('touchstart', onTouchStartMove);
    window.addEventListener('touchmove', onTouchStartMove);
    window.addEventListener('touchend', onTouchEnd);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('touchstart', onTouchStartMove);
      window.removeEventListener('touchmove', onTouchStartMove);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, [overrideScrollPosition.current, isKeyboardOpen]);
};

const collapsibleLogic = (
  scrollTrack: typeof INIT_SCROLL_TRACKING,
  collapsibleRef: RefObject<HTMLDivElement>,
  newHeaderPosition: () => number,
  getScrollPos: () => number
) => {
  // collapsible headers processing
  const onScroll = () => {
    processScroll();
    detectScrollEnd();
  };

  const processScroll = (animate?: boolean) => {
    // this needs to be fast: avoids as much logic and reflows as possible.
    const headerStyle = collapsibleRef.current?.style;
    if (headerStyle) {
      const pos = newHeaderPosition();

      const transform = `translateY(${pos}px)`;
      if (animate) {
        headerStyle.transition = 'transform 200ms ease-in';
      } else {
        headerStyle.transition = '';
      }
      headerStyle.webkitTransform = transform;
      headerStyle.transform = transform;
    }
  };

  const repositionAfterScroll = () => {
    const divElem = collapsibleRef.current;
    if (!divElem) {
      return;
    }

    const scrollPos = getScrollPos();
    const divHeight = divElem.offsetHeight;
    const halfDivHeight = divHeight / 2;
    let anchorPos = scrollTrack.anchorPos;
    if (scrollPos > anchorPos + halfDivHeight || (anchorPos < halfDivHeight && scrollPos < divHeight)) {
      // Header is not visible or more than half hidden. Hide it completely (if possible)
      // (unless it's close to the top)
      anchorPos = Math.max(scrollPos - divHeight, 0);
    } else {
      // At least half of the header is visible. Show it completely.
      anchorPos = scrollPos;
    }

    if (anchorPos != scrollTrack.anchorPos) {
      scrollTrack.anchorPos = anchorPos;
      // reposition header
      processScroll(true);
    }
  };

  const detectScrollEnd = () => {
    if (scrollTrack.requestAnimationFrameId > 0) {
      cancelAnimationFrame(scrollTrack.requestAnimationFrameId);
      scrollTrack.requestAnimationFrameId = 0;
    }
    if (scrollTrack.isTouching) {
      return;
    }
    const scroll = getScrollPos();
    scrollTrack.lastScroll = scroll;
    scrollTrack.requestAnimationFrameId = requestAnimationFrame(onDetectScrollEnd);
  };

  const onDetectScrollEnd = () => {
    // wait 2 frames without scroll events
    scrollTrack.requestAnimationFrameId = requestAnimationFrame(() => {
      const scroll = getScrollPos();

      if (scrollTrack.lastScroll === scroll) {
        scrollTrack.requestAnimationFrameId = 0;
        repositionAfterScroll();
        return;
      }

      detectScrollEnd();
    });
  };

  return { onScroll: onScroll, detectScrollEnd: detectScrollEnd };
};

import React from 'react';

import { default as MessageIconOff } from '@/icons/icon-message-off.svg';
import { isLoadMessaged } from '@common/helper';
import { ContactButtonProps, ContactsButton } from '@component/buttons/ContactsButton';
import { usePushPanel } from '@component/panel/PanelHooks';
import { UnreadNotificationCount } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

import { default as MessageIcon } from '../icon_message.svg';

interface Props extends ContactButtonProps {
  path: string;
  shouldCropLabel: boolean;
}

export const MessageButton: React.FC<Props> = (props) => {
  const pushCommunicationPanel = usePushPanel('/communication/');
  const isCommunicationsEnabled = useSelector((state) => state.communication.isCommunicationsEnabled);
  const unreadCount = props.load?.conversation?.unreadConversationCount ?? 0;
  const buttonTitle =
    !props.isFullSize || props.isLabelHidden
      ? undefined
      : isLoadMessaged(props.load) && !props.shouldCropLabel
        ? t(T.common_conversations_buttonMessageAgain)
        : t(T.common_conversations_button);
  if (!isCommunicationsEnabled) {
    return null;
  }
  return (
    <ContactsButton
      id="messages"
      isFullSize={props.isFullSize}
      icon={{ regular: isLoadMessaged(props.load) ? MessageIcon : MessageIconOff }}
      title={buttonTitle}
      onClick={() => {
        pushCommunicationPanel();
      }}
      iconAccessory={unreadCount > 0 ? <UnreadNotificationCount>{unreadCount}</UnreadNotificationCount> : null}
    />
  );
};

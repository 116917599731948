import { filter, find, includes, join, map, reduce, slice, some, sortBy, split, trim } from 'lodash';
import memoizeOne from 'memoize-one';

import {
  Equipment,
  EquipmentCode,
  EquipmentSpecification,
  EquipmentSpecificationCode,
  EquipmentType,
  RateCheckEquipmentTypes,
  SimplifiedEquipment,
} from '@common/model';
import { parseArrayOfValuesFrom } from '@common/util/parser/ParserUtils';
import { T, t } from '@translate';

export const stringifyEquipments = (equipments: SimplifiedEquipment[]): string[] =>
  map(trimEquiments(equipments), stringifyEquipment);

export const stringifyEquipment = (equipment: SimplifiedEquipment) => {
  const hasSpecifications =
    equipment.equipmentSpecifications !== EquipmentSpecification.None && equipment.equipmentSpecifications !== '';
  const hasType = equipment.equipmentType !== EquipmentType.None && equipment.equipmentType !== '';

  const type = hasType ? equipment.equipmentType : hasSpecifications ? equipment.equipmentSpecifications : '';
  const specifications = hasType && hasSpecifications ? ` (${equipment.equipmentSpecifications})` : '';

  return type + specifications;
};

const trimEquiments = (equipments: SimplifiedEquipment[]) =>
  filter(equipments, (equipment: SimplifiedEquipment) => {
    return (
      (equipment.equipmentSpecifications !== EquipmentSpecification.None && equipment.equipmentSpecifications !== '') ||
      (equipment.equipmentType !== EquipmentType.None && equipment.equipmentType !== '')
    );
  });

export const mapEquipmentsToCode = (equipments: Equipment[] | undefined): string => {
  if (equipments === undefined || equipments.length <= 0) {
    return '-';
  }
  const filteredEquipments = filter(equipments, (equipment) => equipment.equipmentType !== EquipmentType.None);

  return join(map(filteredEquipments, equipmentToCode), ', ');
};

export const mapEquipmentsTypeToCode = (equipmentTypes: EquipmentType[] | undefined): string => {
  if (equipmentTypes === undefined || equipmentTypes.length <= 0) {
    return '-';
  }
  return join(sortBy(map(equipmentTypes, (equipment) => EquipmentCode[equipment])), ', ');
};

export const mapEquipmentTypeToCode = (equipmentType: EquipmentType): string => {
  return EquipmentCode[equipmentType];
};

export const equipmentToCode = (equipment: Equipment) => {
  if (!equipment.equipmentSpecifications || equipment.equipmentSpecifications === EquipmentSpecification.None) {
    if (!equipment.equipmentType || equipment.equipmentType === EquipmentType.None) {
      return '';
    }
    return EquipmentCode[equipment.equipmentType];
  }
  const specifications = equipmentSpecificationsFrom(equipment.equipmentSpecifications);
  const code = reduce(
    specifications,
    (codes, spec) => `${codes}${EquipmentSpecificationCode[spec]}, `,
    `${EquipmentCode[equipment.equipmentType]} (`
  );

  return `${code.substring(0, code.length - 2)})`;
};

export const equipmentToChip = (equipment: Equipment, shouldSliceSpecifications = true) => {
  if (!equipment.equipmentType || equipment.equipmentType === EquipmentType.None) {
    return undefined;
  }
  if (!equipment.equipmentSpecifications || equipment.equipmentSpecifications === EquipmentSpecification.None) {
    return EquipmentCode[equipment.equipmentType];
  }
  const specifications = map(
    equipmentSpecificationsFrom(equipment.equipmentSpecifications),
    (spec) => EquipmentSpecificationCode[spec]
  );

  let specificationsString = '';
  if (specifications.length > 5 && shouldSliceSpecifications) {
    specificationsString = ` (${join(slice(specifications, 0, 5), ', ')}...)`;
  } else if (specifications.length > 0) {
    specificationsString = ` (${join(specifications, ', ')})`;
  }

  return `${EquipmentCode[equipment.equipmentType]}${specificationsString}`;
};

export const equipmentSpecificationsFrom = (specifications: string | undefined): EquipmentSpecification[] => {
  if (!specifications) {
    return [];
  }
  const specs = map(split(specifications, ','), (spec) => trim(spec));
  return parseArrayOfValuesFrom(specs, EquipmentSpecification) || [];
};

export const codeStringsToEquipmentSpecifications = memoizeOne((codes: string[]): EquipmentSpecification[] =>
  reduce(
    codes,
    (specifications, code) => {
      const equipmentSpec = find(
        Object.keys(EquipmentSpecificationCode),
        (key) => EquipmentSpecificationCode[key as keyof typeof EquipmentSpecificationCode] === code
      );
      if (!equipmentSpec) {
        return specifications;
      }

      return [...specifications, EquipmentSpecification[equipmentSpec as keyof typeof EquipmentSpecification]];
    },
    []
  )
);

export const equipmentSpecificationsToCodeStrings = memoizeOne((specifications: EquipmentSpecification[]): string[] =>
  map(specifications, (specification) => EquipmentSpecificationCode[specification])
);

export const equipmentSpecificationsToString = memoizeOne((specifications: EquipmentSpecification[]) =>
  join(specifications, ', ')
);

export const priorityEquipmentAvailableForRateCheck = (equipments: EquipmentType[]) => {
  if (includes(equipments, EquipmentType.Van)) {
    return EquipmentType.Van;
  }
  if (includes(equipments, EquipmentType.StepDeck)) {
    return EquipmentType.StepDeck;
  }
  if (includes(equipments, EquipmentType.Flatbed)) {
    return EquipmentType.Flatbed;
  }
  if (includes(equipments, EquipmentType.Reefer)) {
    return EquipmentType.Reefer;
  }
  return undefined;
};

export const equipmentToCodeArray = (equipments: Equipment[]) =>
  reduce(
    equipments,
    (equipmentArray, equipment) => {
      if (!equipment.equipmentType || equipment.equipmentType === EquipmentType.None) {
        return equipmentArray;
      }
      let specCodes;
      if (equipment.equipmentSpecifications && equipment.equipmentSpecifications !== EquipmentType.None) {
        specCodes = map(
          split(equipment.equipmentSpecifications, ', '),
          (spec: keyof typeof EquipmentSpecificationCode) => EquipmentSpecificationCode[spec]
        );
      }
      return [
        ...equipmentArray,
        {
          equipmentCode: EquipmentCode[equipment.equipmentType],
          specCodes: specCodes && specCodes.length > 0 ? specCodes : undefined,
        },
      ];
    },
    []
  );

export const EquipmentTypeString: {
  [key in Exclude<EquipmentType, EquipmentType.All | EquipmentType.None>]: string;
} = {
  Van: t(T.common_load_equipmentType_Van),
  Reefer: t(T.common_load_equipmentType_Reefer),
  PowerOnly: t(T.common_load_equipmentType_PowerOnly),
  Container: t(T.common_load_equipmentType_Container),
  HopperBottom: t(T.common_load_equipmentType_HopperBottom),
  Tanker: t(T.common_load_equipmentType_Tanker),
  DoubleDrop: t(T.common_load_equipmentType_DoubleDrop),
  Flatbed: t(T.common_load_equipmentType_Flatbed),
  Landoll: t(T.common_load_equipmentType_Landoll),
  LowBoy: t(T.common_load_equipmentType_LowBoy),
  Maxi: t(T.common_load_equipmentType_Maxi),
  RemovableGooseneck: t(T.common_load_equipmentType_RemovableGooseneck),
  StepDeck: t(T.common_load_equipmentType_StepDeck),
  Auto: t(T.common_load_equipmentType_Auto),
  AnimalCarrier: t(T.common_load_equipmentType_AnimalCarrier),
  BoatHauler: t(T.common_load_equipmentType_BoatHauler),
  HeavyHaulers: t(T.common_load_equipmentType_HeavyHaulers),
  MovingVan: t(T.common_load_equipmentType_MovingVan),
  MiniVan: t(T.common_load_equipmentType_MiniVan),
  CargoVan: t(T.common_load_equipmentType_CargoVan),
  BoxTruck: t(T.common_load_equipmentType_BoxTruck),
  Sprinter: t(T.common_load_equipmentType_Sprinter),
  DumpTruck: t(T.common_load_equipmentType_DumpTruck),
  PilotCars: t(T.common_load_equipmentType_PilotCars),
};

export const EQUIPMENTS_FOR_RATE_CHECK: RateCheckEquipmentTypes[] = [
  EquipmentType.Flatbed,
  EquipmentType.Reefer,
  EquipmentType.Van,
  EquipmentType.StepDeck,
];

export const isEquipmentTypeSupportedForRateCheck = (equipments?: Equipment[]) => {
  if (!equipments?.length) {
    return false;
  }

  return some(equipments, (equipment) => includes(EQUIPMENTS_FOR_RATE_CHECK, equipment.equipmentType));
};

export const isRateCheckEquipmentType = (equipment: EquipmentType): equipment is RateCheckEquipmentTypes =>
  includes(EQUIPMENTS_FOR_RATE_CHECK, equipment);

export const EQUIPMENTS_FOR_LTL_MARKET_RATES = [EquipmentType.BoxTruck, EquipmentType.CargoVan];

export type LTLEquipmentTypes = EquipmentType.BoxTruck | EquipmentType.CargoVan;

export enum ExpediteAllEquipmentType {
  BoxTruck = 'StraightBoxTruck',
  CargoVan = 'CargoVan',
}

const equipmentTypeToExpediteAll: Partial<Record<EquipmentType, ExpediteAllEquipmentType>> = {
  [EquipmentType.BoxTruck]: ExpediteAllEquipmentType.BoxTruck,
  [EquipmentType.CargoVan]: ExpediteAllEquipmentType.CargoVan,
};

export const convertEquipmentTypeToExpediteAll = (value: EquipmentType): ExpediteAllEquipmentType | undefined => {
  return equipmentTypeToExpediteAll[value];
};

import styled from 'styled-components';

import { Text } from '@component/text/Text';
import { Color } from '@style/Color';
import { FontFamily, FontSize, FontWeight, px, Spacing } from '@style/StyleConstants';
import { THEME } from '@style/Theme';

export const createBorder = () => {
  return `1px solid ${THEME.palette.background.smoke}`;
};

export const Title = styled(Text)`
  font-family: ${FontFamily.Main};
  font-weight: ${FontWeight.Regular};
  color: ${Color.GRAY_DARK};
  font-size: 20px;
  line-height: 30px;
  margin-bottom: ${px(Spacing.InterElementVertical)};
`;

export const PageText = styled(Text)`
  line-height: 24px;
`;

export const PageTextBoldRed = styled(PageText)`
  color: ${Color.RED_APPLE};
  font-weight: bold;
`;

export const PageTextBold = styled(PageText)`
  font-weight: bold;
`;

export const MileageRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${px(Spacing.InterElementVertical)};
`;

export const MileageColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  background: ${THEME.palette.background.paper};
  color: ${THEME.palette.text.primary};
  border-bottom: ${createBorder()};
  @media only screen and (max-width: 700px) {
    && {
      flex-direction: column;
    }
  }
`;

export const Divider = styled.div`
  border-bottom: ${createBorder()};
`;

export const DividerSmall = styled.div`
  @media only screen and (max-width: 700px) {
    && {
      border-bottom: ${createBorder()};
    }
  }
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const DropDowCard = styled.div`
  background: ${THEME.palette.background.paper};
  color: ${THEME.palette.text.primary};
`;

export const ExpansionPanelSection = styled.div`
  background: ${THEME.palette.background.paper};
  color: ${THEME.palette.text.primary};
`;

export const PcMilerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const SummaryRouteInfo = styled(PcMilerContent)`
  border-right: ${createBorder()};
`;

export const SummaryRouteTitle = styled(Text)`
  font-size: 16px;
  color: ${Color.GRAY_STONE};
  margin-bottom: 8px;
`;

export const SectionTitle = styled.div`
  margin: 8px 16px;
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const SpecialLink = styled.a`
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
  display: flex;
  cursor: pointer;
  margin-bottom: 2px;
  padding: 0 15px;
  height: 78px;
  text-decoration: none;
  background-color: ${Color.WHITE};

  .arrow-icon {
    padding: 0 5px 0 0;
    align-self: center;
  }

  .text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
    font-family: ${FontFamily.Main};
    font-weight: ${FontWeight.SemiBold};
  }

  .icon {
    height: 26px;
    width: 26px;
    object-fit: contain;
    align-self: top;
    margin-right: 20px;
    margin-top: 14px;
  }

  .subtitle {
    font-size: 14px;
    color: ${Color.GRAY_STONE};
    font-weight: ${FontWeight.Regular};
  }
`;

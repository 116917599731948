import React from 'react';

import styled from 'styled-components';

import { default as logo } from '@component/menu/icon/logo.svg';
import { ContentSize } from '@style/StyleConstants';

interface AppLogoImgProps {
  size: ContentSize;
  isInHeader?: boolean;
  width?: number;
}

const AppLogoImg = styled.img`
  height: ${(props: AppLogoImgProps) => (props.isInHeader ? 'auto' : AppLogoHeight[props.size])};
  width: ${(props: AppLogoImgProps) => (props.isInHeader ? AppLogoHeaderWidth[props.size] : AppLogoWidth[props.size])};
  box-sizing: border-box;
`;

export const AppLogo: React.FC<Partial<AppLogoImgProps>> = (props) => (
  <AppLogoImg
    id="app_logo"
    src={logo}
    size={props.size !== undefined ? props.size : ContentSize.Normal}
    isInHeader={props.isInHeader}
  />
);

const AppLogoHeight = {
  [ContentSize.ExtraSmall]: '25px',
  [ContentSize.Small]: '35px',
  [ContentSize.Normal]: '50px',
};

const AppLogoWidth = {
  [ContentSize.ExtraSmall]: '104px',
  [ContentSize.Small]: '145.6px',
  [ContentSize.Normal]: '208px',
};

const AppLogoHeaderWidth = {
  [ContentSize.ExtraSmall]: '130px',
  [ContentSize.Small]: '160px',
  [ContentSize.Normal]: '195px',
};

import { map, max, min } from 'lodash';

export interface BaseCoordinates {
  x: number;
  y: number;
}

export interface SimpleMapsCoordinates extends BaseCoordinates {
  x2: number;
  y2: number;
}

export interface ProjectCoordinates extends BaseCoordinates {
  lat: number;
  lng: number;
}

export interface ProjectRules {
  condition: string;
  points: number[];
}

export interface SimpleMapsInfo {
  map_name: string;
  initial_view: SimpleMapsCoordinates;
  state_bbox_array: { [stateName: string]: SimpleMapsCoordinates };
  paths: { [stateName: string]: string };
  names: { [stateName: string]: string };
  proj: string;
  proj_rules: ProjectRules[];
  proj_coordinates: ProjectCoordinates[];
}

export interface Region {
  [id: string]: { states: string[]; name: string };
}

export interface SimpleMapsData {
  main_settings:
    | {
        initial_zoom?: string;
      }
    | any;
  regions: Region;
  locations: any;
  labels: any;
  state_specific: {
    [stateName: string]: { name: string; color?: string; hover_color?: string; zoomable?: boolean };
  };
}

export const getStatesBboxUnion = (statesArray: SimpleMapsCoordinates[]) => {
  const xa: number[] = [];
  const x2a: number[] = [];
  const y2a: number[] = [];
  const ya: number[] = [];
  map(statesArray, (state) => {
    xa.push(state.x);
    x2a.push(state.x2);
    ya.push(state.y);
    y2a.push(state.y2);
  });
  const x = min(xa);
  const x2 = max(x2a);
  const y = min(ya);
  const y2 = max(y2a);
  return {
    x: x,
    x2: x2,
    y: y,
    y2: y2,
  };
};

import styled from 'styled-components';

import { Chip, withStyles } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip';

import { Color } from '@style/Color';
import { ThemeProps, withTheme } from '@style/WithTheme';

export const CHIP_ITEM_HEIGHT = 22;

export const ChipWithoutPadding = withStyles({
  label: {
    padding: '0px 8px',
  },
})(Chip);
export const StyledTextChip = withTheme()(styled(Chip)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.primary.light};
    font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
    font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
    line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
    color: ${(props: ThemeProps) => props.theme.text.body.color};
    height: ${CHIP_ITEM_HEIGHT}px;
    min-width: 37px;
    cursor: pointer;
  }
`);

export const StyledTextChipWithoutPadding = withTheme()(styled(ChipWithoutPadding)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.primary.light};
    font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
    font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
    line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
    color: ${(props: ThemeProps) => props.theme.text.body.color};
    height: ${CHIP_ITEM_HEIGHT}px;
    min-width: 36px;
    cursor: pointer;
  }
`);

export const StyledDateChip = withTheme()(styled(Chip)`
  && {
    background-color: ${(props: ThemeProps & ChipProps) =>
      props.clickable ? props.theme.palette.primary.light : props.theme.palette.disabled.main};
    font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
    font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
    line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
    color: ${(props: ThemeProps & ChipProps) =>
      props.clickable ? props.theme.text.body.color : props.theme.palette.disabled.dark};
    height: ${CHIP_ITEM_HEIGHT}px;
    min-width: 51px;
    cursor: ${(props: ChipProps) => (props.clickable ? 'pointer' : 'default')};
  }
`);

export const StyledTimeChip = withTheme()(StyledDateChip);

export const StyledActionChipLight = withTheme()(styled(ChipWithoutPadding)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.background.white};
    font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
    font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
    line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
    color: ${(props: ThemeProps) => props.theme.text.body.color};
    height: 30px;
  }
`);
export const StyledActionChipGreen = withTheme()(styled(ChipWithoutPadding)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.primary.light};
    color: ${(props: ThemeProps) => props.theme.palette.primary.main};
    font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
    font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
    line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
    height: 30px;
    :hover {
      background-color: ${Color.GREEN_LIGHT};
      filter: brightness(90%);
    }
  }
`);

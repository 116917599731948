import { startsWith, trim } from 'lodash';

import { COUNTRY_CODE } from '@common/info/ContactInfo';
import { Phone } from '@common/model';
import { t, T } from '@translate';

import { areOptionalValuesSemanticallyEqual } from './Functions';

export const formatPhoneNumber = (phoneNumber: string) => {
  let rawPhoneNumber = phoneNumber;
  if (!rawPhoneNumber || rawPhoneNumber.length < 7) {
    return rawPhoneNumber;
  }
  if (startsWith(rawPhoneNumber, COUNTRY_CODE)) {
    rawPhoneNumber = rawPhoneNumber.substring(COUNTRY_CODE.length);
  }
  return `(${rawPhoneNumber.substring(0, 3)}) ${rawPhoneNumber.substring(3, 6)}-${rawPhoneNumber.substring(
    6,
    rawPhoneNumber.length
  )}`;
};

export const formatPhoneNumberWithExtension = (phoneNumber = '', extension = '') => {
  if (trim(phoneNumber) === '') {
    return '';
  }
  const formattedPhone = formatPhoneNumber(phoneNumber);
  const extensionText = trim(extension) !== '' ? ` ${t(T.common_phone_ext)} ${extension}` : '';
  return `${formattedPhone}${extensionText}`;
};

export const phoneNumberWithoutAreaCode = (phone?: Phone): string => {
  if (phone && phone.number) {
    if (phone.number.length > 10) {
      if (startsWith(phone.number, '1')) {
        return phone.number.substring(1, phone.number.length);
      }
      if (startsWith(phone.number, '+1')) {
        return phone.number.substring(2, phone.number.length);
      }
    }
    return phone.number;
  }
  return '';
};

export const addCountryCodeIfNecessary = (phone: string) => {
  if (startsWith(phone, '+1')) {
    return phone;
  }
  if (startsWith(phone, '1')) {
    return `+${phone}`;
  }
  return `+1${phone}`;
};

export const arePhonesDefinedAndMatching = (phoneA: Phone | undefined, phoneB: Phone | undefined) =>
  phoneA &&
  phoneB &&
  phoneA.number === phoneB.number &&
  areOptionalValuesSemanticallyEqual(phoneA.extension, phoneB.extension);

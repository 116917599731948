import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TextField, withStyles } from '@material-ui/core';

import { addNote, LoadInfo } from '@common/redux/epic/LoadInfoEpic';
import { MultipurposeButton } from '@component/buttons';
import { MyNoteTitle, Row } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { SimpleButtonWrapper } from '@page/unsubscribeAssistant/UnsubscribeStylesHelper';
import { Spacing } from '@style/StyleConstants';
import { THEME } from '@style/Theme';
import { T, t } from '@translate';

const DEFAULT_MAX_FIELD_LENGTH = 255;

const SaveButtonWrapper = styled(SimpleButtonWrapper)`
  && {
    align-self: right;
    width: 120px;
    margin-bottom: ${Spacing.TwoSpaces};
  }
`;

interface Props {
  loadInfo: LoadInfo | undefined;
  companyId: string;
  note: string | undefined;
  isLoadDetailsSingleColumn: boolean;
}

const StyledTextInput = withStyles({
  root: {
    width: '100%',
    border: `1px solid ${THEME.palette.textInput.borderColor}`,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    backgroundColor: THEME.palette.textInput.backgroundColor,
    borderRadius: '4px 4px 0 0',
    '& div': {
      backgroundColor: `${THEME.palette.textInput.backgroundColor} !important`,
      '&:before': {
        borderColor: `${THEME.palette.textInput.borderColor} !important`,
      },
    },
    '& label': {
      marginTop: '4px',
    },
    '& textarea': {
      overflow: 'hidden',
    },
  },
})(TextField);

const NoteWrapper = styled(({ isLoadDetailsSingleColumn, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${({ isLoadDetailsSingleColumn }) =>
    isLoadDetailsSingleColumn ? '5px 15px 15px 15px' : '8px 0px 15px 0px'};
  background: ${THEME.palette.background.paper};
`;

export const Note: React.FC<Props> = (props) => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  useEffect(() => setValue(props.note ?? ''), []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value.length <= DEFAULT_MAX_FIELD_LENGTH) {
      setValue(event.target.value);
    }
  };

  const saveNote = () => {
    dispatch(addNote(props.companyId, value ?? ''));
  };

  const isChanged = value !== props.note && !(isEmpty(value) && isEmpty(props.note));

  return (
    <Row>
      <MyNoteTitle id="note_title">{t(T.findLoads_loadDetails_myNote)}</MyNoteTitle>
      <NoteWrapper id="note" isLoadDetailsSingleColumn={props.isLoadDetailsSingleColumn}>
        <StyledTextInput
          id="text_input"
          label={'Add a note'}
          margin="dense"
          value={value}
          onChange={handleChange}
          variant="filled"
          multiline={true}
        />
        <SaveButtonWrapper>
          <MultipurposeButton
            id="save_note"
            variant={'contained'}
            text={t(T.findLoads_loadDetails_saveNote)}
            color={'primary'}
            isLoading={props.loadInfo?.isLoadingNote}
            onClick={saveNote}
            disabled={!isChanged}
          />
        </SaveButtonWrapper>
      </NoteWrapper>
    </Row>
  );
};

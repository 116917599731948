import React, { ReactNode, useContext } from 'react';

import styled from 'styled-components';

import { DESKTOP_BORDER_WIDTH } from '@component/main/DesktopThinBlackFrame';
import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { useIsMobileView } from '@util/hooks';

import { BlockFooterPortalDestination, BlockHeaderPortalDestination, HeaderHeight } from './FixedAreaPortalDestination';
import { FixedAreaRenderProvider } from './FixedAreaRenderContext';

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

interface Props {
  id?: string;
  style?: React.CSSProperties;
  children: ReactNode;
}

/** Defines a non-scrollable area meant to fill its container with a special component like a map
 * (that have their own scrolling/panning system)
 *
 * Use FixedHeader/FixedFooter components to define items outside.
 *
 * @FIXME: (MEM-2012) this is hardcoding the top/bottom position in windowScrollMode (had not the time to fix this).
 * That means that additional header and footers (TopBanner in particular) will hide the
 * top/bottom part of the content (i.e. the map's close button might not be visible for instance).
 * Fixing this probably means to propagate up the fact that we want to disable scrolling completely
 * (i.e. a new scrolling mode) and generate proper markup for this case. Might even go as far
 * as changing the meta tags to disallow page scaling(?).
 */
export const NonScrollView: React.FC<Props> = ({ id, style, children }) => {
  const isWindowScrollMode = useIsWindowScrollMode();
  const isMobileView = useIsMobileView();
  const { headerHeight } = useContext(HeaderHeight);

  return isWindowScrollMode ? (
    <div
      id={id}
      style={{
        position: 'fixed',
        top: isMobileView ? headerHeight : DESKTOP_BORDER_WIDTH,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        ...style,
      }}
    >
      {children}
    </div>
  ) : (
    <div
      id={id}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        ...style,
      }}
    >
      <FixedAreaRenderProvider>
        <BlockHeaderPortalDestination includesCollapsible={true} />
        <ChildrenContainer>{children}</ChildrenContainer>
        <BlockFooterPortalDestination />
      </FixedAreaRenderProvider>
    </div>
  );
};

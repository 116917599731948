import React from 'react';

import { flatMap, map } from 'lodash';
import Popover, { PopoverPlace } from 'react-popover';

import { List } from '@material-ui/core';

import { Location } from '@common/model';
import { Text, TextDisplay, TextStyle } from '@component/text';
import { POPUP_ZINDEX } from '@style/StyleConstants';
import { t, T } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';
import { formatTruckLocation } from '@util/Formatter';

// import './PopupTruckDestination.css';

interface Props {
  id: string;
  destinations?: Location[];
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  open: boolean;
  popupLocation: PopoverPlace;
}
const TruckDestinations: React.SFC<Props> = (props: Props) => {
  const { onClick, open } = props;
  if (!props.destinations || props.destinations.length <= 0) {
    return <Text id={props.id}>{NO_INFORMATION_SYMBOL}</Text>;
  }

  //We want to count them here because there might be nested states.
  let destinationAmount = 0;
  const destinations = flatMap(props.destinations, (destination, index) =>
    map(formatTruckLocation(destination), (formatedDestination) => {
      destinationAmount += 1;
      return (
        <React.Fragment key={formatedDestination}>
          <Text id={props.id + '_item_' + index} textStyle={TextStyle.Default}>
            {formatedDestination}
          </Text>
          <br />
        </React.Fragment>
      );
    })
  );

  if (destinationAmount <= 2) {
    return <div> {destinations} </div>;
  }
  const destinationElements = <List style={{ maxHeight: 150, overflow: 'auto' }}>{destinations} </List>;

  const title = (
    <Text id={props.id + '_title'} textStyle={TextStyle.TableHeader} display={TextDisplay.InlineBlock}>
      {t(T.common_load_Popup_Destinations)}
    </Text>
  );

  return (
    <div id={props.id} onClick={onClick} style={{ cursor: 'pointer' }}>
      <Popover
        isOpen={open}
        preferPlace={props.popupLocation}
        place={props.popupLocation}
        body={[title, destinationElements]}
        style={{ zIndex: POPUP_ZINDEX }}
      >
        <Text id={props.id + 'text'} textStyle={TextStyle.Default} display={TextDisplay.InlineBlock} noWrap={true}>
          {t(T.common_load_Popup_Multiple)}
        </Text>
      </Popover>
    </div>
  );
};

export { TruckDestinations };

import React from 'react';

import SimpleBar from 'simplebar-react';
import styled from 'styled-components';

import { Link } from '@material-ui/core';

import { NewBadge } from '@component/badge/NewBadge';
import { OpenedProps } from '@component/menu/menuTypes';
import { Color } from '@style/Color';
import { ElementSize, FontFamily, FontLineHeight, FontSize, FontWeight } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';

import { NavLink } from './NavLink';

export const DESKTOP_MENU_TRANSITION_MS = 150;
export const DESKTOP_MENU_TRANSITION_TIMINGFUNC = 'cubic-bezier(0.765, 0.005, 0.755, 0.605)';

export const MENU_HEADER_HEIGHT = 60;
export const MENU_ICON_SIZE = 30;

const BaseText = styled.div`
  font-family: ${FontFamily.Main};
  color: ${Color.GRAY_LIGHT};
`;

export const MenuSimpleBar = withTheme()(styled<OpenedProps & React.ComponentProps<typeof SimpleBar>>(
  ({ opened, ...rest }) => <SimpleBar {...rest} />
)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${ElementSize.HamburgerMenuHeight}px);
  overflow-y: auto;
  overflow-x: hidden;
  .simplebar-scrollbar::before {
    background-color: #fff;
    left: ${({ opened }) => (opened ? `3` : `7`)}px;
    right: ${({ opened }) => (opened ? `2` : `1`)}px;
  }
  .simplebar-scrollbar.simplebar-visible::before {
    opacity: 0.3;
  }
`);

export const UserProfile = styled<OpenedProps & { id: string; isDisplayingToggle?: boolean }>(
  ({ opened, isDisplayingToggle, ...rest }) => <BaseText {...rest} />
)`
  display: flex;
  flex-direction: row;
  padding-top: ${({ isDisplayingToggle }) => (isDisplayingToggle ? 0 : 20)}px;
  padding-bottom: ${({ opened }) => (opened ? 10 : 20)}px;
  border-bottom: 2px ${Color.GREEN_MAIN} solid;
  max-height: ${({ opened }) => (opened ? 120 : 88)}px;
  transition: max-height ${DESKTOP_MENU_TRANSITION_MS}ms ${DESKTOP_MENU_TRANSITION_TIMINGFUNC};
  overflow: visible;
  position: relative;
`;

export const UserAvatar = styled(BaseText)`
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  width: 74px;
  min-width: 74px;
`;

export const UserInfoText = styled(BaseText)`
  display: flex;
  flex-direction: column;
  min-width: 226px;
`;

export const UserName = styled<OpenedProps & React.HTMLAttributes<HTMLDivElement>>(({ opened, ...rest }) => (
  <BaseText {...rest} />
))`
  font-size: ${FontSize.Content}px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  margin-right: 18px;
  word-break: break-all;
  cursor: pointer;
`;

export const UserContact = styled<OpenedProps & React.HTMLAttributes<HTMLDivElement>>(({ opened, ...rest }) => (
  <BaseText {...rest} />
))`
  margin-top: 1px;
  font-size: ${FontSize.ContentDetail}px;
  line-height: 22px;
  margin-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  cursor: pointer;
`;

export const PrefLabel = styled<OpenedProps>(({ opened, ...rest }) => <div {...rest} />)`
  color: ${Color.WHITE};
  font-size: ${({ opened }) => (opened ? FontSize.ModalTitle : FontSize.Body)}px;
  line-height: ${({ opened }) => (opened ? FontLineHeight.ModalTitle : FontLineHeight.Body)}px;
  font-family: ${FontFamily.Main};
  font-weight: ${FontWeight.SemiBold};
  text-transform: none;
  text-align: center;
  transition: line-height, font-size ${DESKTOP_MENU_TRANSITION_MS}ms ${DESKTOP_MENU_TRANSITION_TIMINGFUNC};
  margin-bottom: ${({ opened }) => (!opened ? '-12px' : '')};
`;

export const HeaderLabel = styled<OpenedProps>(({ opened, ...rest }) => <div {...rest} />)`
  color: ${Color.WHITE};
  font-size: ${({ opened }) => (opened ? FontSize.ModalTitle : FontSize.Body)}px;
  line-height: ${({ opened }) => (opened ? FontLineHeight.ModalTitle : FontLineHeight.Body)}px;
  font-family: ${FontFamily.Main};
  font-weight: ${FontWeight.SemiBold};
  text-transform: none;
  text-align: ${({ opened }) => (opened ? 'left' : 'center')};
  margin-left: ${({ opened }) => (opened ? '15px' : '0')};
  transition: line-height, font-size ${DESKTOP_MENU_TRANSITION_MS}ms ${DESKTOP_MENU_TRANSITION_TIMINGFUNC};
  max-width: 100%;
`;

export const SvgContainer = styled.div`
  height: 24px;
  z-index: 1;
`;

export const MenuNavLink = styled(NavLink)`
  && {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    text-decoration: none;
    position: relative;
    background-color: ${Color.GRAY_MAGNET};
    font-weight: ${FontWeight.Regular};
  }

  svg {
    stroke: ${Color.GRAY_LIGHT};
    fill: ${Color.GRAY_LIGHT};
  }

  &:hover {
    svg {
      stroke: ${Color.GRAY_STONE};
      fill: ${Color.GRAY_STONE};
    }
    div {
      color: ${Color.GRAY_STONE};
    }
  }

  &.active {
    color: black;
    div {
      color: ${Color.ORANGE_MAIN};
      font-weight: ${FontWeight.SemiBold};
    }
    svg {
      stroke: ${Color.GRAY_LIGHT};
      fill: ${Color.GRAY_LIGHT};
    }
  }
  &.active::before {
    display: block;
    background-color: ${Color.ORANGE_MAIN};
    width: 34px;
    height: 34px;
    border-radius: 17px;
    position: absolute;
    left: 15px;
    top: 6px;
    content: ' ';
  }
`;

export const OldLink = styled(Link)`
  && {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    justify-items: center;
    align-items: center;
    text-decoration: none;
    background-color: ${Color.GRAY_MAGNET};
  }

  && svg {
    stroke: ${Color.GRAY_LIGHT};
    fill: ${Color.GRAY_LIGHT};
  }

  &&:hover {
    text-decoration: none;
    svg {
      stroke: ${Color.GRAY_STONE};
      fill: ${Color.GRAY_STONE};
    }
    div {
      color: ${Color.GRAY_STONE};
    }
  }
`;

export const NavLabel = styled.div`
  margin-left: 25px;
  font-size: ${FontSize.Content}px;
  color: white;
  line-height: 44px;
  font-family: ${FontFamily.Main};
  white-space: nowrap;
`;

export const CopyrightText = styled(Link)`
  && {
    font-size: ${FontSize.Body}px;
    font-family: ${FontFamily.Main};
    font-weight: ${FontWeight.Regular};
    color: ${Color.GRAY_LIGHT};
    text-align: center;
  }
`;

export const VersionFooter = styled.div`
  font-size: ${FontSize.TinyBody}px;
  font-family: ${FontFamily.Main};
  font-weight: normal;
  color: ${Color.GRAY_STONE};
  opacity: 0.6;
  text-align: center;
  margin-top: 0px;
  margin-bottom: -10px;
`;

export const Header = withTheme()(styled.div`
  display: flex;
  flex-direction: row;
  height: ${MENU_HEADER_HEIGHT}px;
  min-height: ${MENU_HEADER_HEIGHT}px;
`);

export const AppLogoContainer = withTheme()(styled.button`
  display: flex;
  align-items: center;
  background: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
`);

export const SideMenuIconButton = styled.button`
  background: inherit;
  cursor: pointer;
  border: none;
  padding: 0 22px;
  height: 60px;
  outline: none; /* chrome user agent stylesheet has outline on :focus */
`;

export const Footer = styled.div<OpenedProps>`
  display: block;
  text-align: center;
  margin-top: 53px;
  transition: opacity, height ${DESKTOP_MENU_TRANSITION_MS}ms ${DESKTOP_MENU_TRANSITION_TIMINGFUNC};
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  height: ${({ opened }) => (opened ? '100%' : '0')};
  font-family: ${FontFamily.Main};
  font-size: ${FontSize.Body}px;
  color: ${Color.GRAY_LIGHT};
  line-height: 22px;
  min-width: 300px;
  padding-bottom: 30px;
`;

export const FooterLogoContainer = withTheme()(styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`);

export const FooterLogo = styled.img`
  height: 40px !important;
`;

export const FooterIconLink = styled.a`
  padding: 0 5px;
`;

export const StyledLink = styled(Link)`
  && {
    font-size: ${FontSize.Body}px;
    color: ${Color.GRAY_LIGHT};
    line-height: 22px;
  }
  &&:hover {
    text-decoration: none;
    color: ${Color.GRAY_STONE};
  }
`;

export const MenuLabel = styled(NavLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NotificationCount = withTheme()(styled(BaseText)<{
  opened: boolean;
  hasNotifications: boolean;
  isMessageWidget?: boolean;
  isConversationList?: boolean;
}>`
  display: inline-block;
  font-size: 13px;

  line-height: ${(props) => (!props.isMessageWidget ? '24px' : '')};
  width: ${(props) => (!props.isMessageWidget ? '30px' : '')};
  height: ${(props) => (!props.isMessageWidget ? '22px' : '')};

  color: ${Color.GRAY_LIGHT} !important;
  margin: auto 20px;
  opacity: ${(props) => (props.hasNotifications ? 1 : 0)};
  transition: opacity 500ms ease;
  text-align: center;
  background-color: ${Color.RED_APPLE};
  border: ${(props) => (props.isConversationList ? `solid 1px ${Color.RED_APPLE}` : `solid 1px ${Color.GRAY_SMOKE}`)};
  border-radius: 17px;
  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    margin: 0;
    transition: opacity 500ms ease, top ${DESKTOP_MENU_TRANSITION_MS}ms ${DESKTOP_MENU_TRANSITION_TIMINGFUNC};
    position: absolute;
    right: ${({ opened }) => (opened ? 21 : 5)}px;
    top: ${({ opened }) => (opened ? 'auto' : 2)}px;
    z-index: 1;
  }
  justify-content: flex-end;
`);

export const IconsContainer = styled.div`
  display: flex;
  margin: 20px 34px 20px 34px;
  justify-content: space-between;
`;

export const MenuItemNewBadge = styled(NewBadge)`
  position: absolute;
  right: ${(props: { opened: boolean }) => (props.opened ? 21 : 5)}px;
  top: ${(props: { opened: boolean }) => (props.opened ? 'auto' : '2px')};
  align-self: center;
`;

export const RedDot = styled.div`
  width: 9px;
  height: 9px;
  align-self: center;
  margin-right: 4px;
  border: 1px solid white;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: ${Color.RED_APPLE};
`;

export const BadgeOverlay = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const NavLinkLabel = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: ${FontSize.SmallBody}px;
  color: ${Color.WHITE};
  margin-top: -4px;
  background-color: ${Color.GRAY_MAGNET};
`;

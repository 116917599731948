// Layout and Style-related constants
export enum FontFamily {
  Main = 'OverPass, sans-serif',
}

export enum FontWeight {
  /** Italic found in Light only */
  Light = 300,
  Regular = 400, // same as 'normal'
  SemiBold = 600,
  Bold = 700,
}

export enum FontSize {
  LargeTitle = 36, // H1
  MediumTitle = 32, // H2
  Header = 24,
  MediumHeader = 22,
  ModalTitle = 20, // Title
  Content = 16, // Subtitle
  ContentDetail = 14, // Subtitle
  Body = 12, // body
  SmallBody = 11, // body 3
  TinyBody = 10,
  Caption = 9, // caption
}

export enum FontLineHeight {
  LargeTitle = 40, // H1
  MediumTitle = 24, // H2
  LargeHeader = 34,
  Header = 32,
  ModalTitle = 30, // Title
  Content = 24, // Subtitle
  ContentDetail = 21, // Subtitle
  Body = 18, // body
  SmallBody = 16, // body 3
  Caption = 11, // caption
  ButtonLabel = 14, // Action Button Label
}

export enum BorderRadius {
  loadAvailabilityCircle = 34,
  CloseModal = 20,
  ChoiceChip = 17,
  InputChip = 12,
  ToolButton = 8,
  Widget = 10,
  Button = 4,
  LoadMarker = 15,
  Tiny = 2,
}

export enum Spacing {
  ScreenSide = 16,
  ScreenSideWide = 24,
  ElementPaddingHorizontal = 12,
  ElementPaddingHorizontalWide = 48,
  ElementPaddingVertical = 12,
  InterElementVertical = 8,
  InterElementHorizontal = 8,
  ListIconHorizontal = 7,
  InterListElement = 4,
  InterListElementSmall = 2,
  TitleTopDistance = 24,
  HorizontalBigDistance = 24,
  InterSection = 16,
  DoubleInterSection = 32,
  ImageElement = 48,
  PanelSide = 35,
  TwoSpaces = 6,
  LargePaddingHorizontal = 20,
  LargePaddingVertical = 20,
  XLHorizontalPadding = 80,
  Mark = 10,
}

export enum BorderWidth {
  Thin = 1,
  Medium = 2,
}

export enum ElementSize {
  Dot = 8,
  Icon = 24,
  CheckboxArea = 36,
  Checkbox = 18,
  TextIcon = 16,
  loadingSpinnerSize = 20,
  BadgeIconWidth = 20,
  BadgeIconHeight = 16,
  ButtonWidthShort = 64,
  ButtonWidthMedium = 114,
  ButtonWidthLarge = 150,
  ButtonHeight = 44,
  ButtonWidth = 260,
  ListRowHeight = 60,
  FooterHeight = 56,
  HeaderHeight = 56,
  MobileHeaderHeight = 60,
  HamburgerMenuHeight = 60,
  ActionChipHeight = 31,
  LoadSearchCardHeight = 177,
  OverflowMenuItemWidth = 150,
  TruckPostingCardHeight = 152,
  DialogTextWidth = 102,
  StickyButtonHeight = 57,
  ActionLoadRowHeight = 50,
  LargeStatusBarButtonWidth = 170,
  SmallStatusBarButtonWidth = 105,
  MyLoadsStatusButtonHeight = 67,
  LoadViewCountHeight = 30,
  SmallIcon = 8,
  RateCheckMinMax = 48,
  RateCheckConfirmation = 43,
  RateCheckFeedbackInput = 120,
  OptionBar = 30,
  LoadListItemHeight = 44,
  TextField = 310,
  CheckBoxSize = 50,
  ActionBar = 70,
  ActionBarSmall = 48,
  MapColorKey = 55,
  FilterChipMaxWidth = 200,
  ActionButtonWidth = 56,
  DensityMapStateMobileCardHeight = 160,
  MediumButtonHeight = 32,
  TripButtonHeight = 30,
}

export enum Opacity {
  clear = 1,
  lightest = 0.9,
  overlay = 0.85,
  light = 0.6,
  translucent = 0.5,
  blocked = 0,
}

export enum Opacity {
  CheckboxDisabled = 0.7,
  Enabled = 1,
}

export enum LoaderSize {
  small = 20,
  medium = 50,
  large = 100,
}

export enum ContentSize {
  ExtraSmall,
  Small,
  Normal,
}

export const POPUP_ZINDEX = 1299; // cannot be more than 1300

export const BANNER_ZINDEX = POPUP_ZINDEX - 1;

export const MENU_ZINDEX = POPUP_ZINDEX - 1;
export const MOBILE_MENU_ZINDEX = MENU_ZINDEX - 1;

export const FEED_POPUP_HEADER_ZINDEX = MENU_ZINDEX - 1;
export const FEED_POPUP_ZINDEX = FEED_POPUP_HEADER_ZINDEX - 1;

export const MENU_TOP_BAR_ZINDEX = FEED_POPUP_ZINDEX - 1;

/** Appends 'px'.
 *
 * In React Inline styles, you do not need this as it's already automatic as per React docs.
 *
 * In styled-components, you can append it directly in the template string.
 */
export const px = (amount: number) => `${amount}px`;

import React from 'react';

import { CalendarGroup } from './CalendarGroup';
import { StatesCalendar } from './StatesCalendar';

export const MAX_SELECTION_COUNT = 25;
const DEFAULT_NUMBER_OF_MONTHS_DISPLAYED = 3;

export enum PickerType {
  States = 'States',
  Dates = 'Dates',
}

export interface MonthType {
  year: number;
  month: number;
}

interface Props {
  type: PickerType;
  date?: MonthType;
  selectedItems: string[];
  onChange: (values: string[]) => void;
  maxCount?: number;
  numberOfMonthsDisplayed?: number;
  style?: React.CSSProperties;
}

export const Calendar: React.FC<Props> = ({
  maxCount,
  selectedItems,
  date,
  onChange,
  numberOfMonthsDisplayed,
  type,
  style,
}) => {
  if (type === PickerType.States) {
    return <StatesCalendar onChange={onChange} selectedItems={selectedItems} maxCount={maxCount} style={style} />;
  }
  if (type === PickerType.Dates && date) {
    return (
      <CalendarGroup
        date={date}
        onChange={onChange}
        numberOfMonthsDisplayed={
          numberOfMonthsDisplayed !== undefined ? numberOfMonthsDisplayed : DEFAULT_NUMBER_OF_MONTHS_DISPLAYED
        }
        selectedItems={selectedItems}
        maxCount={maxCount}
        style={style}
      />
    );
  }
  return null;
};

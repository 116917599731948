import { useEffect, useMemo } from 'react';

import { concat, find, map } from 'lodash';
import { useDispatch } from 'react-redux';

import {
  convertEquipmentTypeToExpediteAll,
  EQUIPMENTS_FOR_LTL_MARKET_RATES,
  EQUIPMENTS_FOR_RATE_CHECK,
} from '@common/helper';
import {
  Address,
  EquipmentType,
  ExpediteAllLocation,
  ExpediteAllRateRequest,
  Flag,
  Load,
  Location,
} from '@common/model';
import { fetchRateCheck } from '@common/redux/epic/LoadInfoEpic';
import { fetchExpediteAllRate } from '@common/redux/epic/MarketRatesEpic';
import { convertAddressToExpediteAllLocation } from '@page/marketRates/marketRates/SearchFormHelper';
import { useSelector } from '@util/hooks';

export const useShouldDisplayExpediteAll = (equipmentTypes: EquipmentType[]) => {
  const isExpediteAllEnabled = useSelector(
    (state) => state.settings.systemSetting[Flag.ExpediteAllPostLoadEnabled].value
  );
  const { equipment, isExpediteAllType } = findFirstCompatibleBrokerRateEquipment(equipmentTypes);

  return {
    isEnabled: isExpediteAllEnabled,
    rateEquipment: equipment,
    isExpediteAllType: isExpediteAllType,
  };
};

export const useRateForPostedLoad = (load: Load | undefined) => {
  const dispatch = useDispatch();
  const equipmentTypes = useMemo(() => map(load?.equipments, (item) => item.equipmentType), [load?.equipments]);
  const { isEnabled, isExpediteAllType, rateEquipment } = useShouldDisplayExpediteAll(equipmentTypes);

  useEffect(() => {
    if (load?.id) {
      if (isEnabled && isExpediteAllType) {
        const expediteRequest = createExpediteAllRateRequest(
          load?.originLocation,
          load?.destinationLocation,
          rateEquipment
        );

        if (expediteRequest) {
          dispatch(fetchExpediteAllRate(expediteRequest));
        }
      } else {
        dispatch(fetchRateCheck(load.id));
      }
    }
  }, [load?.id]);

  return { shouldShowExpediteRate: isEnabled && isExpediteAllType, equipment: rateEquipment };
};

export const useExpediteAllRate = (
  pickUpAddress: Address | undefined,
  dropOffAddress: Address | undefined,
  equipmentTypes: EquipmentType[]
) => {
  const dispatch = useDispatch();
  const { isEnabled, isExpediteAllType, rateEquipment } = useShouldDisplayExpediteAll(equipmentTypes);

  useEffect(() => {
    const origin = convertAddressToExpediteAllLocation(pickUpAddress);
    const destination = convertAddressToExpediteAllLocation(dropOffAddress);
    const equipmentType =
      isExpediteAllType && rateEquipment ? convertEquipmentTypeToExpediteAll(rateEquipment) : undefined;

    if (isEnabled && isExpediteAllType && origin && destination && equipmentType) {
      dispatch(fetchExpediteAllRate({ origin: origin, destination: destination, truckType: equipmentType }));
    }
  }, [rateEquipment, pickUpAddress, dropOffAddress]);

  return { shouldShowExpediteRate: isEnabled && isExpediteAllType };
};

const convertLocationToExpediteAllLocation = (location: Location | undefined): ExpediteAllLocation | undefined => {
  if (location && location.geolocation) {
    const { latitude, longitude } = location.geolocation;
    return {
      city: location.address?.city,
      state: location.address?.state,
      country: location.address?.country,
      latitude: latitude,
      longitude: longitude,
    };
  }
  return undefined;
};

const createExpediteAllRateRequest = (
  originLocation: Location | undefined,
  destinationLocation: Location | undefined,
  equipmentType?: EquipmentType
): ExpediteAllRateRequest | undefined => {
  const origin = convertLocationToExpediteAllLocation(originLocation);
  const destination = convertLocationToExpediteAllLocation(destinationLocation);
  const equipment = equipmentType ? convertEquipmentTypeToExpediteAll(equipmentType) : undefined;

  if (origin && destination && equipment) {
    return { origin: origin, destination: destination, truckType: equipment };
  }
  return undefined;
};

const compatibilityList = concat(
  map(EQUIPMENTS_FOR_LTL_MARKET_RATES, (item) => ({ item: item, isExpedite: true })),
  map(EQUIPMENTS_FOR_RATE_CHECK, (item) => ({ item: item, isExpedite: false }))
);

/**
 * Finds the first equipment in `loadEquipments` that is compatible with either of two
 * lists, `EQUIPMENTS_FOR_LTL_MARKET_RATES` or `EQUIPMENTS_FOR_RATE_CHECK`.
 * Prioritizes the order in `loadEquipments`.
 * */
const findFirstCompatibleBrokerRateEquipment = (equipments?: EquipmentType[]) => {
  if (equipments) {
    for (const equipment of equipments) {
      const match = find(compatibilityList, ({ item }) => item === equipment);
      if (match) {
        return { equipment: match.item, isExpediteAllType: match.isExpedite };
      }
    }
  }

  return { equipment: undefined, isExpediteAllType: false };
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Api, ApiResponse123 } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';
import { BaseState, Response } from '@common/redux/Base';
import { createReducer } from '@common/redux/ReduxHelper';

export interface SystemTimeData {
  systemTime?: any;
}

export type SystemTimeResponse = Response<SystemTimeData>;

interface SystemTimeDataResponse extends Action {
  response: SystemTimeResponse;
}

export interface SystemTimeState extends BaseState {
  isLoading: boolean;
  systemTime?: string;
  systemTimeReceivedAt?: number;
}

const initialState: SystemTimeState = {
  isLoading: false,
};

//create reducer begin
const createSystemTimeReducer = () => {
  //@FIXME  use createApiAction to avoid boilerplate code
  const actionTypes = {
    FETCH_SYSTEM_TIME: 'FETCH_SYSTEM_TIME',
    SYSTEM_TIME_FETCHED: 'SYSTEM_TIME_FETCHED',
  };

  const actions = {
    fetchSystemTime: (): Action => ({
      type: actionTypes.FETCH_SYSTEM_TIME,
    }),
    systemTimeDataResponse: (response: SystemTimeResponse): SystemTimeDataResponse => ({
      type: actionTypes.SYSTEM_TIME_FETCHED,
      response: response,
    }),
  };

  const systemTimeReducer = createReducer(initialState, {
    [actionTypes.FETCH_SYSTEM_TIME]: (state) => {
      state.isLoading = true;
      state.systemTime = undefined;
      state.systemTimeReceivedAt = undefined;
    },
    [actionTypes.SYSTEM_TIME_FETCHED]: (state, action: SystemTimeDataResponse) => {
      state.isLoading = false;
      state.systemTime = action.response.payload?.systemTime;
      state.systemTimeReceivedAt = Date.now();
    },
  });

  return { actions: actions, reducer: systemTimeReducer, actionTypes: actionTypes };
};
//create reducer end

const systemTime = createSystemTimeReducer();
export const systemTimeReducer = systemTime.reducer;

export const fetchSystemTime = systemTime.actions.fetchSystemTime;
export const systemTimeDataResponse = systemTime.actions.systemTimeDataResponse;

export const createSystemTimeEpic = (api: Api) => {
  const client = new SystemTimeClient(api);

  const fetchSystemTime$ = (action$: ActionsObservable<Action>, client: SystemTimeClient) =>
    action$.ofType(systemTime.actionTypes.FETCH_SYSTEM_TIME).pipe(
      mergeMap(() => {
        return client.getSystemTime$().pipe(
          map((response: ApiResponse123<SystemTimeData>) => {
            return response.result(
              (data) =>
                systemTimeDataResponse({
                  success: true,
                  payload: data,
                }),
              (error) =>
                systemTimeDataResponse({
                  success: false,
                  error: error,
                })
            );
          })
        );
      })
    );

  return (action$: ActionsObservable<Action>) => merge$(fetchSystemTime$(action$, client));
};

export class SystemTimeClient extends BaseClient {
  getSystemTime$ = () => this.api.get$('/system/info/time', {});
}

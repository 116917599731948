import { Handler } from 'leaflet';
import { Map, MapProps } from 'react-leaflet';

import './gestureHandlingPlugin/leaflet-gesture-handling.js';

import './gestureHandlingPlugin/leaflet-gesture-handling.css';

export interface GestureHandlingProps extends MapProps {
  gestureHandlingEnabled?: boolean;
}

export class GestureHandlingMap extends Map<GestureHandlingProps> {
  componentDidMount() {
    if (super.componentDidMount) {
      super.componentDidMount();
    }

    if (this.props.gestureHandlingEnabled) {
      ((this.leafletElement as any).gestureHandling as Handler).enable();
    }
  }

  componentDidUpdate(prevProps: GestureHandlingProps) {
    if (super.componentDidUpdate) {
      // biome-ignore lint/style/noArguments: framework-level logic, need to use 'arguments' here
      super.componentDidUpdate.apply(this, arguments);
    }

    if (this.props.gestureHandlingEnabled !== prevProps.gestureHandlingEnabled) {
      if (this.props.gestureHandlingEnabled) {
        ((this.leafletElement as any).gestureHandling as Handler).enable();
      } else {
        ((this.leafletElement as any).gestureHandling as Handler).disable();
      }
    }
  }
}

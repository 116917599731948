/** Immutable-like LRU Cache
 *
 * NOTE: this is a not a real Immutable. This is only a 'shallow' Immutable
 * strictly used to play nice with the basic usage of Redux Store
 * Only the "root" structure will be different so strict equality should see
 * a change, but old copies will get mutated since the internal Map is shared when calling
 * set() or moveToLastUsed().
 * The rationale is the rather small footprint compared to using an OrderedMap from immutable.js.
 * We can achieve a very small footpring due to the use of a ES2015 Map.
 * (might require a Map polyfill for old browsers, i.e. polyfill.io).
 *
 * @author bernard
 */
export class ImmutableLRUMap<K, V> {
  /** max size */
  private readonly max: number;

  /** Uses the Map's ability to iterate in *insertion order*
   *  making first item from an iteration the next one to delete essentially.
   */
  private readonly map: Map<K, V>;

  /** @param map the map parameter is only meant for internal use */
  constructor(maxSize: number, map?: Map<K, V>) {
    this.max = maxSize;
    this.map = map || new Map<K, V>();
  }

  get(key: K): V | undefined {
    return this.map.get(key);
  }

  moveToLastUsed(key: K): ImmutableLRUMap<K, V> {
    let cache = this.map;
    const item: V | undefined = cache.get(key);
    if (!item) {
      return this;
    }

    // if already last, no need to recreate a new instance
    if (key === cache.keys().next().value) {
      return this;
    }

    cache = new Map(cache);

    // re-insert the entry so it becomes "last" (insertion order)
    cache.delete(key);
    cache.set(key, item);

    return this._new(cache);
  }

  set(key: K, val: V): ImmutableLRUMap<K, V> {
    let cache = this.map;

    cache = new Map(cache);
    cache = cache.set(key, val);

    if (cache.size > this.max) {
      // remove oldest
      cache.delete(cache.keys().next().value);
    }

    return this._new(cache);
  }

  private _new(cache: Map<K, V>): ImmutableLRUMap<K, V> {
    return new ImmutableLRUMap<K, V>(this.max, cache);
  }
}

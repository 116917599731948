import React from 'react';

import { IconButton } from '@material-ui/core';

import { Content, CustomIcon, Message, Submit } from '@component/snack/SnackbarContentStyle';
import { t, T } from '@translate';

interface Props {
  message: string;
  variant: string;

  onClose: () => void;
}

class SnackbarContent extends React.Component<Props> {
  render() {
    const { message, onClose, variant, ...other } = this.props;

    return (
      <Content
        aria-describedby="client-snackbar"
        message={
          <Message id="client-snackbar">
            <CustomIcon />
            {message}
          </Message>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <Submit>{t(T.common_ok)}</Submit>
          </IconButton>,
        ]}
        {...other}
      />
    );
  }
}

export default SnackbarContent;

import React, { useEffect, useState } from 'react';

import { findIndex, map } from 'lodash';
import styled from 'styled-components';

import { Chip } from '@material-ui/core';

import { Radio } from '@component/control';
import { Color } from '@style/Color';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';

export interface ChipItem {
  label: string;
  id: string;
}

interface Props {
  handleChange?: (value: string) => void;
  items: ChipItem[];
  value?: string;
  backgroundColor?: string;
  isRadioChip?: boolean;
}

type StyledProps = { isSelected: boolean; backgroundColor: string } & ThemeProps;

export const StyledChoiceChipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-right: ${Spacing.ElementPaddingHorizontal}px;
  }
  > :last-child {
    margin-right: 0;
  }
`;

// augmenting specificity with && to allow styling material-ui component
export const StyledChoiceChip = withTheme()(styled(({ isSelected, backgroundColor, ...rest }) => <Chip {...rest} />)`
  && {
    background-color: ${(props: StyledProps) =>
      props.isSelected ? props.theme.palette.primary.light : props.backgroundColor};
    font-family: ${(props: StyledProps) => props.theme.text.subtitleRegular.fontFamily};
    font-size: ${(props: StyledProps) => props.theme.text.subtitleRegular.fontSize};
    line-height: ${(props: StyledProps) => props.theme.text.subtitleRegular.lineHeight};
    color: ${(props: StyledProps) => props.theme.text.subtitleRegular.color};
    height: ${ElementSize.CheckboxArea}px;
    & > span {
      padding: 0 ${Spacing.DoubleInterSection}px 0 ${Spacing.DoubleInterSection}px;
    }
    border: 1px solid ${Color.GRAY_LAVA};
  }
`);

export const ChoiceChips: React.FC<Props> = ({ items, value, backgroundColor, isRadioChip, handleChange }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (value) {
      setPosition(findIndex(items, (item) => item.label === value));
    }
  }, [value]);

  const handleChipClick = (position: number, data: string) => () => {
    setPosition(position);
    if (handleChange !== undefined) {
      handleChange(data);
    }
  };

  return (
    <StyledChoiceChipsWrapper>
      {map(items, (item, index) =>
        isRadioChip ? (
          <>
            <Radio
              id={item.id}
              aria-selected={index === position}
              key={index}
              onClick={handleChipClick(index, item.label)}
              isChecked={index === position}
              label={''}
              value={''}
              name={''}
            />
            {item.label}
          </>
        ) : (
          <StyledChoiceChip
            id={item.id}
            aria-selected={index === position}
            key={index}
            label={item.label}
            onClick={handleChipClick(index, item.label)}
            isSelected={index === position}
            backgroundColor={backgroundColor ?? Color.GRAY_LIGHT}
          />
        )
      )}
    </StyledChoiceChipsWrapper>
  );
};

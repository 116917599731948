import { capitalize, findKey, first, map, reduce, split, toString, trim } from 'lodash';

import { displayCurrencyOrNothing, NO_INFORMATION_SYMBOL } from '@common/formatter';
import { secondsToTimeObject } from '@common/helper/DateHelper';
import { EquipmentTypeString, mapEquipmentsTypeToCode } from '@common/helper/EquipmentHelper';
import { getPricePerMileRate } from '@common/helper/LoadHelper';
import { formatNumber } from '@common/helper/NumberHelper';
import {
  DestLocation,
  EquipmentType,
  Load,
  LoadLocation,
  LoadSearchRequest,
  LocationType,
  MAX_LOAD_SEARCH_TRUCK_WEIGHT,
  MAX_PLUS_TRUCK_LENGTH,
  MAX_TRUCK_LENGTH,
  MAX_TRUCK_WEIGHT,
  MIN_LOAD_SEARCH_TRUCK_LENGTH,
  MIN_LOAD_SEARCH_TRUCK_WEIGHT,
  OriginLocation,
} from '@common/model';
import { t, T } from '@translate';

export const CURRENT_LOCATION_TEXT = t(T.loadGeneric_currentLocation);

/** @TODO / REMOVE ME : The name is not descriptive enough, I would infer that it merely converts undefined to empty string or to zero.
 * This will show a dash if the number is zero (intended??). It is not clear at all.
 * @deprecated use `formatNumberOrDash(treatZeroAsUndefined(num))` instead.
 */
export const stringifyNumber = (num: number | undefined): string => {
  if (num) {
    return formatNumber(num);
  }
  return NO_INFORMATION_SYMBOL;
};

/** @param units will be appended only if number is not undefined */
export const formatNumberOrDash = (num: number | undefined, units: string = ''): string =>
  num === undefined ? NO_INFORMATION_SYMBOL : formatNumber(num) + units;

export const formatStringOrDash = (value: string | undefined): string =>
  value === undefined || trim(value) === '' ? NO_INFORMATION_SYMBOL : value;

export const truncString = (str: string, length: number) => `${str.trim().substr(0, length - 3)}...`;
export const truncStringIfNeeded = (str: string, max: number) => {
  if (str.length > max) {
    return truncString(str, max);
  }
  return str;
};

export const MINUTE = 60;
export const HOUR = 60 * MINUTE;

export const shortStringifyAge = (age: number) => {
  const { days, hours, minutes, seconds } = secondsToTimeObject(age);
  if (days > 0 || hours >= 8) {
    return '8h+';
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  if (minutes > 0) {
    return `${minutes}m`;
  }
  return `${seconds}s`;
};

export const stringifyDrivingTime = (seconds: number, excludeSeconds?: boolean, excludeDays?: boolean) => {
  const timeObj = secondsToTimeObject(seconds);
  const convertedDaysToHours = timeObj.days * 24 + timeObj.hours;

  if (timeObj.days > 0) {
    if (!excludeDays) {
      return `${timeObj.days}d ${timeObj.hours}h ${timeObj.minutes}m`;
    } else {
      return `${convertedDaysToHours}h ${timeObj.minutes}m`;
    }
  }

  if (timeObj.hours > 0) {
    if (timeObj.minutes > 0) {
      return `${timeObj.hours}h ${timeObj.minutes}m`;
    } else {
      return `${timeObj.hours}h`;
    }
  }

  if (timeObj.minutes > 0) {
    if (!excludeSeconds) {
      return `${timeObj.minutes}m ${timeObj.seconds}s`;
    } else {
      return `${timeObj.minutes}m`;
    }
  }

  if (!excludeSeconds) {
    return `${timeObj.seconds}s`;
  }
  return null;
};

export const getLoadLocationString = (loadAddress: string | LoadLocation | undefined): string => {
  if (!loadAddress) {
    return '';
  }

  if (typeof loadAddress === 'string') {
    return loadAddress;
  }

  switch (loadAddress.type) {
    case LocationType.STATE:
      return loadAddress.states ? loadAddress.states.join(', ') : '';
    case LocationType.ANY:
      return 'Anywhere';
    case LocationType.ANY_CA:
      return 'Anywhere Canada';
    case LocationType.ANY_US:
      return 'Anywhere USA';
    case LocationType.GEOLOCATION: {
      let geolocationString = '';
      if (!loadAddress.city && (!loadAddress.states || !first(loadAddress.states))) {
        geolocationString = loadAddress.formattedAddress || '';
      } else {
        geolocationString =
          (loadAddress.city ? `${convertCityLocationFormatToCapitalize(loadAddress.city)}, ` : '') +
          (first(loadAddress.states) || '');
      }

      if (geolocationString === '') {
        geolocationString = CURRENT_LOCATION_TEXT;
      }
      return geolocationString;
    }
    case LocationType.CITY:
      return `${convertCityLocationFormatToCapitalize(loadAddress.city)}, ${first(loadAddress.states) || ''}`;
    case LocationType.ZIP:
      return (
        (loadAddress.zipCode ? loadAddress.zipCode : '') +
        (loadAddress.zipCode && loadAddress.city ? ' ' : '') +
        (convertCityLocationFormatToCapitalize(loadAddress.city) || '')
      );
    default:
      return '';
  }
};

export const getDeadHead = (load: Load | undefined): number | undefined =>
  load?.metadata?.userdata?.originDeadhead?.value;

export const getDestinationDeadHead = (load: Load | undefined): number | undefined =>
  load?.metadata?.userdata?.destinationDeadhead?.value;

export const valueForDestLocation = (location?: LoadLocation): DestLocation | undefined => {
  if (
    !location ||
    (location.type === LocationType.STATE && location.states.length === 0) ||
    (location.type === LocationType.CITY && location.states.length === 0)
  ) {
    return undefined;
  }
  return location;
};

export const valueForOriginLocation = (location?: LoadLocation): OriginLocation | undefined => {
  if (!location) {
    return undefined;
  }
  switch (location.type) {
    case LocationType.CITY:
      if (location.states.length === 0) {
        return undefined;
      }
      break;
    case LocationType.STATE:
      if (location.states.length === 0) {
        return undefined;
      }
      break;
  }
  return location as OriginLocation;
};

export const formatEquipmentTypes = (trailerTypes: EquipmentType[]) =>
  trailerTypes.length > 0 ? mapEquipmentsTypeToCode(trailerTypes) : t(T.common_load_anyEquipmentType);

export const formatLength = (length: (number | undefined)[], shouldUseNonRangedDimensions = false) => {
  if (length.length < 1) {
    return '';
  }
  if (shouldUseNonRangedDimensions && length[1] !== undefined) {
    return `${length[1]} ${t(T.common_feet)}`;
  }
  if (
    (length[0] === undefined || length[0] === MIN_LOAD_SEARCH_TRUCK_LENGTH) &&
    (length[1] === undefined || length[1] === MAX_PLUS_TRUCK_LENGTH)
  ) {
    return `${t(T.common_any)} ${t(T.common_feet)}`;
  }
  if (length[1] === undefined) {
    return `${t(T.common_truck_spec_length, { min: length[0], max: MAX_TRUCK_LENGTH })}+`;
  }

  return t(T.common_truck_spec_length, { min: length[0], max: length[1] });
};

export const formatTripLength = (maxLength?: number) => {
  if (maxLength !== undefined) {
    return `${formatNumber(maxLength)} ${t(T.common_feet)}`;
  } else {
    return `${t(T.common_any)} ${t(T.common_feet)}`;
  }
};

export const formatWeight = (weight: (number | undefined)[], shouldUseNonRangedDimensions = false) => {
  if (weight.length < 1) {
    return '';
  }
  if (shouldUseNonRangedDimensions && weight[1] !== undefined) {
    return `${formatNumberOrDash(weight[1])} ${t(T.common_lbs)}`;
  }
  if (
    (weight[0] === undefined || weight[0] === MIN_LOAD_SEARCH_TRUCK_WEIGHT) &&
    (weight[1] === undefined || weight[1] === MAX_LOAD_SEARCH_TRUCK_WEIGHT)
  ) {
    return `${t(T.common_any)} ${t(T.common_lbs)}`;
  }
  if (weight[1] === undefined) {
    return `${t(T.common_truck_spec_weight, {
      min: formatNumberOrDash(weight[0]),
      max: formatNumberOrDash(MAX_TRUCK_WEIGHT),
    })}+`;
  }

  return t(T.common_truck_spec_weight, { min: formatNumberOrDash(weight[0]), max: formatNumberOrDash(weight[1]) });
};

export const formatTripWeight = (maxWeight?: number) => {
  if (maxWeight !== undefined) {
    return `${formatNumber(maxWeight)} ${t(T.common_lbs)}`;
  } else {
    return `${t(T.common_any)} ${t(T.common_lbs)}`;
  }
};

export const formatRadius = (request: LoadSearchRequest) => {
  if (request.origin.radius === 0 || request.origin.radius === undefined) {
    if (request.destination.radius === 0 || request.destination.radius === undefined) {
      return null;
    } else {
      return `${t(T.common_load_Radius)} ${t(T.common_any)}/${request.destination.radius} ${t(T.common_miles_short)}`;
    }
  } else {
    if (request.destination.radius === 0 || request.destination.radius === undefined) {
      return `${t(T.common_load_Radius)} ${request.origin.radius}/${t(T.common_any)} ${t(T.common_miles_short)}`;
    } else {
      return `${t(T.common_load_Radius)} ${request.origin.radius}/${request.destination.radius} ${t(
        T.common_miles_short
      )}`;
    }
  }
};

export const equipmentTypeToString = (type: EquipmentType): string =>
  EquipmentTypeString[type as keyof typeof EquipmentTypeString];

export const stringToEquipmentType = (type: string): EquipmentType =>
  findKey(EquipmentTypeString, (eq) => eq === type) as EquipmentType;

export const ratePerMileFormatted = (load: Load) =>
  displayCurrencyOrNothing(getPricePerMileRate(load), true, { suffix: t(T.common_per_miles_short) });

export const formatNumberOrEmptyString = (num: number | undefined): string =>
  num === undefined || num === 0 ? '' : toString(num);

export const formatNumberOrZero = (num: number | undefined): number => (num === undefined ? 0 : num);

export const calculateLinehaulRevenue = (state: any): number => {
  const { linehaulMileage, avgRate } = state;
  const revenue = linehaulMileage * avgRate;
  return !isNaN(revenue) ? revenue : 0;
};

export const calculateFuelCosts = (state: any): number => {
  const { fuelMileage = 0, mpgRate = 0, discount = 0, perGal = 0 } = state;
  const fuelCosts = (fuelMileage / mpgRate) * (perGal - discount);
  return !isNaN(fuelCosts) && isFinite(fuelCosts) ? fuelCosts : 0;
};

export const calculateAdditionalCosts = (state: any, linehaulRevenue: number, isPercent: boolean): number => {
  const { tollCost = 0, dispatchFee = 0, otherFee = 0 } = state;
  let additionalCosts;
  if (isPercent) {
    additionalCosts = tollCost + linehaulRevenue * (dispatchFee ? dispatchFee / 100 : dispatchFee) + otherFee;
  } else {
    additionalCosts = tollCost + dispatchFee + otherFee;
  }
  return !isNaN(additionalCosts) ? additionalCosts : 0;
};

export const convertCityLocationFormatToCapitalize = (city: string) =>
  reduce(map(split(city, ' '), capitalize), (a, b) => a + ' ' + b);

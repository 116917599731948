import { concat, defaultTo, filter, findIndex, map, uniqWith } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError } from '@common/api';
import { TruckAlertsClient } from '@common/client/TruckAlertsClient';
import {
  createDefaultMessagesRequest,
  Message,
  MessageRequest,
  MESSAGES_REQUEST_LIMIT_APPENDING,
  MESSAGES_REQUEST_LIMIT_DEFAULT,
  MESSAGES_REQUEST_OFFSET_DEFAULT,
  MessagesByLoad,
  MessagesResult,
} from '@common/model';
import { Response } from '@common/redux/Base';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';
import { createReducer } from '@common/redux/ReduxHelper';

export const TRUCK_ALERTS_REDUCER_KEY = 'truckAlerts';

const FETCH_UNREAD_TRUCK_ALERTS_COUNT = 'FETCH_UNREAD_TRUCK_ALERTS_COUNT';
const FETCH_TRUCK_ALERTS = 'FETCH_TRUCK_ALERTS';
const FETCH_MORE_TRUCK_ALERTS = 'FETCH_MORE_TRUCK_ALERTS';
const FETCH_LOAD_INFO_TRUCK_ALERTS = 'FETCH_LOAD_INFO_TRUCK_ALERTS';
const UNREAD_TRUCK_ALERTS_COUNT_FETCHED = 'UNREAD_TRUCK_ALERTS_COUNT_FETCHED';
const TRUCK_ALERTS_FETCHED = 'TRUCK_ALERTS_FETCHED';
const LOAD_INFO_TRUCK_ALERTS_FETCHED = 'LOAD_INFO_TRUCK_ALERTS_FETCHED';
const READ_ALERT = 'READ_ALERT';
const DELETE_ALERT = 'DELETE_ALERT';
const UPDATE_LOAD_ALERT_RESPONSE = 'UPDATE_LOAD_ALERT_RESPONSE';
const NEW_TRUCK_ALERT = 'NEW_TRUCK_ALERT';
const NEW_TRUCK_ALERTS_FETCHED = 'NEW_TRUCK_ALERTS_FETCHED';

type MessagesResponse = Response<MessagesResult>;

type MessagesCountResponse = Response<number>;

interface PartialStateLoadAlerts {
  [TRUCK_ALERTS_REDUCER_KEY]: TruckAlertsState;
}

interface FetchLoadMessagesAction extends Action {
  loadID: string;
}

interface UpdateMessageAction extends Action {
  messageID: string;
}

interface ReadMessageAction extends UpdateMessageAction {
  isRead: boolean;
}

interface LoadMessagesResponseAction extends Action {
  messagesResult: MessagesResult;
  loadID: string;
}

interface FetchMessagesResponseAction extends Action {
  messagesResponse: MessagesResponse;
}

interface FetchUnreadCountResponseAction extends Action {
  unreadMessages: number;
  updateNewMessagesTimestamp: number;
}

const fetchMessagesResponse = (response: MessagesResponse): FetchMessagesResponseAction => ({
  type: TRUCK_ALERTS_FETCHED,
  messagesResponse: response,
});

const fetchNewMessagesResponse = (response: MessagesResponse): FetchMessagesResponseAction => ({
  type: NEW_TRUCK_ALERTS_FETCHED,
  messagesResponse: response,
});

const fetchMessageCountResponse = (response: MessagesCountResponse): FetchUnreadCountResponseAction => ({
  type: UNREAD_TRUCK_ALERTS_COUNT_FETCHED,
  unreadMessages: defaultTo(response.payload, -1),
  updateNewMessagesTimestamp: Date.now(),
});

export const fetchUnreadTruckAlertsCount = (): Action => ({ type: FETCH_UNREAD_TRUCK_ALERTS_COUNT });

const fetchLoadMessagesResponse = (loadID: string) => (data: MessagesResult) => ({
  type: LOAD_INFO_TRUCK_ALERTS_FETCHED,
  messagesResult: data,
  loadID: loadID,
});

const readMessageError = () => (error: ApiError) => ({
  success: false,
  type: UPDATE_LOAD_ALERT_RESPONSE,
  error: error,
});

const loadMessageError = (loadID: string) => (error: ApiError) => ({
  success: false,
  type: LOAD_INFO_TRUCK_ALERTS_FETCHED,
  error: error,
  messagesResult: { messages: [], resultCount: 0, unreadMessagesCount: 0 },
  loadID: loadID,
});

export const fetchMessages = (): Action => ({
  type: FETCH_TRUCK_ALERTS,
});

export const fetchMoreMessages = (): Action => ({ type: FETCH_MORE_TRUCK_ALERTS });

export const fetchLoadInfoMessages = (loadID: string): FetchLoadMessagesAction => ({
  type: FETCH_LOAD_INFO_TRUCK_ALERTS,
  loadID: loadID,
});

export const readMessage = (messageID: string, isRead: boolean = true): ReadMessageAction => ({
  type: READ_ALERT,
  messageID: messageID,
  isRead: isRead,
});

export const deleteMessage = (messageID: string): UpdateMessageAction => ({
  type: DELETE_ALERT,
  messageID: messageID,
});

export const newTruckAlert = (): Action => ({
  type: NEW_TRUCK_ALERT,
});

const messageUpdated = (): Action => ({
  type: UPDATE_LOAD_ALERT_RESPONSE,
});

export interface TruckAlertsState {
  messagesRequest: MessageRequest;
  messages?: Message[]; // undefined: error, empty: user has no messages
  loadInfoMessages: MessagesByLoad;
  resultCount: number;
  unreadMessagesCount: number;
  isLoadingMessages: boolean;
  isLastResult: boolean;
  isLoadingMore: boolean;
  updateNewMessagesTimestamp: number;
}

const initialState: TruckAlertsState = {
  messagesRequest: createDefaultMessagesRequest(),
  isLoadingMessages: false,
  resultCount: 0,
  unreadMessagesCount: 0,
  loadInfoMessages: new Map(),
  isLastResult: false,
  isLoadingMore: false,
  updateNewMessagesTimestamp: 0,
};

// This epic was renamed from MessagesEpic to TruckAlertsEpic to better describe its
// purpose and reduce confusion with Communication related code. Note that not all
// properties have been renamed, so you may see 'messages' used in places.
// There will be a larger refactor in the future.
//
// Be careful updating this reducer to use createReducer or using produce as this use immer and was updated. Check immer+maps before any work.
export const truckAlertsReducer = createReducer(initialState, {
  [READ_ALERT]: (state: TruckAlertsState, action: ReadMessageAction) => {
    if (state.messages) {
      const index = findIndex(state.messages, (message) => {
        return message.id === action.messageID;
      });
      if (index !== -1) {
        const isPreviouslyRead = state.messages[index].read;
        state.messages[index].read = action.isRead;

        if (action.isRead && !isPreviouslyRead && state.unreadMessagesCount > 0) {
          state.unreadMessagesCount--;
        } else if (!action.isRead && isPreviouslyRead) {
          state.unreadMessagesCount++;
        }
      }
    }
  },
  [DELETE_ALERT]: (state: TruckAlertsState, action: UpdateMessageAction) => {
    if (state.messages) {
      const deletedMessageID = action.messageID;
      const indexDeletedMessage = findIndex(state.messages, (message) => message.id === deletedMessageID);
      if (indexDeletedMessage !== -1) {
        const isDeletedMessageRead = state.messages[indexDeletedMessage].read;
        const filteredMessages = filter(state.messages, (message: Message) => message.id !== deletedMessageID);
        let newUnreadMessagesCount = state.unreadMessagesCount;
        if (!isDeletedMessageRead && state.unreadMessagesCount > 0) {
          newUnreadMessagesCount = state.unreadMessagesCount - 1;
        }
        return {
          ...state,
          messages: filteredMessages,
          unreadMessagesCount: newUnreadMessagesCount,
          messagesRequest: {
            ...state.messagesRequest,
            // reduce offset so we don't miss a result from server
            // on next additional fetch
            offset: state.messagesRequest.offset - 1,
          },
        };
      }
    }
    return state;
  },
  [FETCH_TRUCK_ALERTS]: (state: TruckAlertsState) => ({
    ...state,
    messagesRequest: {
      ...state.messagesRequest,
      limit: MESSAGES_REQUEST_LIMIT_DEFAULT,
      offset: MESSAGES_REQUEST_OFFSET_DEFAULT,
    },
    isLoadingMessages: true,
  }),
  [FETCH_MORE_TRUCK_ALERTS]: (state: TruckAlertsState) => ({
    ...state,
    isLoadingMore: true,
    messagesRequest: {
      ...state.messagesRequest,
      limit: MESSAGES_REQUEST_LIMIT_APPENDING,
      offset:
        state.messagesRequest.offset === MESSAGES_REQUEST_OFFSET_DEFAULT
          ? MESSAGES_REQUEST_LIMIT_DEFAULT
          : state.messagesRequest.offset + MESSAGES_REQUEST_LIMIT_APPENDING,
    },
  }),
  [TRUCK_ALERTS_FETCHED]: (state: TruckAlertsState, action: FetchMessagesResponseAction) => {
    const messagesResult = action.messagesResponse.payload;
    const wasInitialRequest = state.messagesRequest.offset === MESSAGES_REQUEST_OFFSET_DEFAULT;
    if (messagesResult) {
      const newMessages = wasInitialRequest
        ? messagesResult.messages
        : uniqWith(
            concat(state.messages || [], messagesResult.messages),
            (message1: Message, message2: Message) => message1.id === message2.id
          );
      return {
        ...state,
        isLoadingMessages: false,
        isLoadingMore: false,
        messages: newMessages,
        unreadMessagesCount: messagesResult.unreadMessagesCount,
        updateNewMessagesTimestamp: Date.now(),
        resultCount: messagesResult.resultCount,
        isLastResult: messagesResult.messages.length < state.messagesRequest.limit,
      };
    }
    return {
      ...state,
      isLoadingMessages: false,
      isLoadingMore: false,
    };
  },
  [LOAD_INFO_TRUCK_ALERTS_FETCHED]: (state: TruckAlertsState, action: LoadMessagesResponseAction) => {
    const { messagesResult } = action;
    // Keep the load messages in a key value map, where the key is the loadID
    const newLoadInfoMessages = new Map(state.loadInfoMessages);
    newLoadInfoMessages.set(action.loadID, messagesResult.messages);
    return { ...state, loadInfoMessages: newLoadInfoMessages };
  },
  [UNREAD_TRUCK_ALERTS_COUNT_FETCHED]: (
    state: TruckAlertsState = initialState,
    action: FetchUnreadCountResponseAction
  ) => {
    if (action.unreadMessages >= 0) {
      return {
        ...state,
        unreadMessagesCount: action.unreadMessages,
        updateNewMessagesTimestamp: action.updateNewMessagesTimestamp,
      };
    }
    return state;
  },
  [NEW_TRUCK_ALERTS_FETCHED]: (state: TruckAlertsState = initialState, action: FetchMessagesResponseAction) => {
    if (action.messagesResponse.payload) {
      const newMessages = uniqWith(
        concat(action.messagesResponse.payload.messages, state.messages || []),
        (message1: Message, message2: Message) => message1.id === message2.id
      );
      return {
        ...state,
        unreadMessagesCount: state.unreadMessagesCount + action.messagesResponse.payload?.unreadMessagesCount,
        updateNewMessagesTimestamp: Date.now(),
        messages: newMessages,
        messagesRequest: {
          ...state.messagesRequest,
          offset: newMessages.length,
        },
      };
    }
    return state;
  },
});

const fetchMessages$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialStateLoadAlerts>,
  truckAlertsClient: TruckAlertsClient
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_TRUCK_ALERTS,
    () => {
      const request = state$.value.truckAlerts.messagesRequest;
      return truckAlertsClient.fetchTruckAlerts$(request);
    },
    fetchMessagesResponse
  );

const fetchMoreMessages$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialStateLoadAlerts>,
  truckAlertsClient: TruckAlertsClient
) =>
  action$.ofType(FETCH_MORE_TRUCK_ALERTS).pipe(
    mergeMap$(() => {
      if (state$.value.truckAlerts.isLastResult) {
        return of$(
          fetchMessagesResponse({
            success: true,
            payload: {
              messages: [],
              resultCount: state$.value.truckAlerts.resultCount,
              unreadMessagesCount: state$.value.truckAlerts.unreadMessagesCount,
            },
          })
        );
      }
      const request = state$.value.truckAlerts.messagesRequest;
      return truckAlertsClient.fetchTruckAlerts$(request).pipe(
        map$((response) => {
          return response.result(
            (data) =>
              fetchMessagesResponse({
                success: true,
                payload: data,
              }),
            (error) =>
              fetchMessagesResponse({
                success: false,
                error: error,
              })
          );
        })
      );
    })
  );

const fetchNewMessages$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialStateLoadAlerts>,
  truckAlertsClient: TruckAlertsClient
) =>
  simpleApiEpicToAction(
    action$,
    NEW_TRUCK_ALERT,
    () => {
      return truckAlertsClient.fetchUnreadTruckAlerts$(
        { limit: MESSAGES_REQUEST_LIMIT_DEFAULT, offset: MESSAGES_REQUEST_OFFSET_DEFAULT },
        state$.value.truckAlerts.updateNewMessagesTimestamp
      );
    },
    fetchNewMessagesResponse
  );

const fetchUnreadTruckAlertsCount$ = (action$: ActionsObservable<Action>, truckAlertsClient: TruckAlertsClient) =>
  simpleApiEpicToAction(
    action$,
    FETCH_UNREAD_TRUCK_ALERTS_COUNT,
    () => truckAlertsClient.fetchTruckAlertsCount$(),
    fetchMessageCountResponse
  );

const fetchLoadInfoMessages$ = (action$: ActionsObservable<Action>, truckAlertsClient: TruckAlertsClient) =>
  action$
    .ofType(FETCH_LOAD_INFO_TRUCK_ALERTS)
    .pipe(
      mergeMap$((action: FetchLoadMessagesAction) =>
        truckAlertsClient
          .fetchLoadInfoAlerts$(action.loadID)
          .pipe(
            map$((response) =>
              response.result(fetchLoadMessagesResponse(action.loadID), loadMessageError(action.loadID))
            )
          )
      )
    );

const readMessage$ = (action$: ActionsObservable<Action>, truckAlertsClient: TruckAlertsClient) =>
  action$
    .ofType(READ_ALERT)
    .pipe(
      mergeMap$((action: ReadMessageAction) =>
        truckAlertsClient
          .readAlert$(action.messageID, action.isRead)
          .pipe(map$((response) => response.resultWithoutData(messageUpdated, readMessageError())))
      )
    );

const deleteMessage$ = (action$: ActionsObservable<Action>, truckAlertsClient: TruckAlertsClient) =>
  action$
    .ofType(DELETE_ALERT)
    .pipe(
      mergeMap$((action: UpdateMessageAction) =>
        truckAlertsClient
          .deleteAlert$(action.messageID)
          .pipe(map$((response) => response.resultWithoutData(messageUpdated, readMessageError())))
      )
    );

export const createTruckAlertsEpic = <T extends PartialStateLoadAlerts>(api: Api) => {
  const truckAlertsClient = new TruckAlertsClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<T>) => {
    return merge$(
      ...map([fetchLoadInfoMessages$, readMessage$, fetchUnreadTruckAlertsCount$, deleteMessage$], (func) =>
        func(action$, truckAlertsClient)
      ),
      fetchMessages$(action$, state$, truckAlertsClient),
      fetchMoreMessages$(action$, state$, truckAlertsClient),
      fetchNewMessages$(action$, state$, truckAlertsClient)
    );
  };
};

import React, { createContext, useCallback, useContext, useRef } from 'react';

import { noop } from 'lodash';

import { useForceUpdate } from '@common/util/hooks';
import { FragmentHolder } from '@component/fragmentPortal';

export interface FixedArea {
  /** unique id to avoid collisions in transient mount/unmount cases */
  uid: string;
  /** if true, for headers: disappear when scrolling down and re-appear when scrolling back up */
  collapseOnScroll: boolean;
  /** DOM fragment for this header/footer */
  fragmentHolder: FragmentHolder;
}

interface FixedAreaWithOrder extends FixedArea {
  /** the display order */
  index: number;
}

export interface FixedAreaWithKey extends FixedArea {
  key: string;
}

/** Implementation note: Contains the list of header/footers to display in this render context.
 *  Display order is based on the keys. Collapsible entries can be rendered as a separate group.
 */
export type FixedAreaEntries = { [key: string]: FixedAreaWithKey & FixedAreaWithOrder };

interface FixedAreaRenderContext {
  headers: FixedAreaEntries;
  footers: FixedAreaEntries;
  updateHeader: (key: string, header: FixedArea | undefined) => void;
  updateFooter: (key: string, footer: FixedArea | undefined) => void;
}

/** Context to manage list of headers and footers.
 */
const fixedAreaRenderContext = createContext<FixedAreaRenderContext>({
  headers: {},
  footers: {},
  updateHeader: noop,
  updateFooter: noop,
});

export const useFixedAreaRenderContext = () => useContext(fixedAreaRenderContext);

// Note: cannot use isEqual() here because one side can be a FixedAreaWithKey.
// Also only want to shallow-equal the FragmentDocument
const isAreaEqual = (a: FixedArea | undefined, b: FixedArea | undefined) =>
  (a === undefined && b === undefined) ||
  (a !== undefined &&
    b !== undefined &&
    a.uid === b.uid &&
    a.collapseOnScroll === b.collapseOnScroll &&
    a.fragmentHolder === b.fragmentHolder &&
    a.fragmentHolder?.fragment === b.fragmentHolder?.fragment);

const updateAreas = (key: string, prevAreas: FixedAreaEntries, area: FixedAreaWithOrder | undefined) => {
  const newAreas = { ...prevAreas };
  if (area === undefined) {
    delete newAreas[key];
  } else {
    newAreas[key] = { ...area, key: key };
  }
  return newAreas;
};

export const FixedAreaRenderProvider: React.FC = ({ children }) => {
  const forceUpdate = useForceUpdate();
  const headers = useRef<FixedAreaEntries>({});
  const footers = useRef<FixedAreaEntries>({});
  const headerIndex = useRef<number>(1);
  const footerIndex = useRef<number>(1);

  const updateHeader = useCallback(updateEntries(headers, headerIndex, forceUpdate), [headers, headerIndex]);
  const updateFooter = useCallback(updateEntries(footers, footerIndex, forceUpdate), [footers, footerIndex]);

  return (
    <fixedAreaRenderContext.Provider
      value={{
        headers: headers.current,
        footers: footers.current,
        updateHeader: updateHeader,
        updateFooter: updateFooter,
      }}
    >
      {children}
    </fixedAreaRenderContext.Provider>
  );
};

const updateEntries =
  (entries: React.MutableRefObject<FixedAreaEntries>, index: React.MutableRefObject<number>, update: () => void) =>
  (key: string, entry: FixedArea | undefined) => {
    const curEntry = entries.current[key];

    if (!entry) {
      entries.current = updateAreas(key, entries.current, undefined);
      if (curEntry) {
        update();
      }
      return;
    }

    entries.current = updateAreas(key, entries.current, { ...entry, index: index.current });
    ++index.current;

    if (!isAreaEqual(curEntry, entry)) {
      update();
    }
  };

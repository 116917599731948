import React from 'react';

import styled from 'styled-components';

import { default as EmptyConversation } from '@/icons/empty-folder.svg';
import { MultipurposeButton } from '@component/buttons';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

const ButtonContainer = styled.div`
  display: flex;
  width: ${ElementSize.ButtonWidth}px;
  margin-top: ${Spacing.ScreenSide}px;
`;

interface Props {
  onClick: () => void;
}

export const NoUnreadMessagesPanel: React.FC<Props> = (props) => (
  <NotFoundPanel
    title={t(T.common_conversations_empty_unreadTitle)}
    subtitle={t(T.common_conversations_empty_unreadSubtitle)}
    imageSection={<img id="image" src={EmptyConversation} alt="error" />}
    controlSection={
      <ButtonContainer>
        <MultipurposeButton
          id="unblock"
          variant={'outlined'}
          color={'secondary'}
          text={t(T.common_conversations_empty_viewAllMessages)}
          onClick={props.onClick}
        />
      </ButtonContainer>
    }
  />
);

import React from 'react';

import { startsWith } from 'lodash';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';

interface Props extends NavLinkProps {
  to: string;
  tooltip?: string;
}

export const NavLink: React.FC<Props> = (props: Props) => {
  if (startsWith(props.to, 'https://')) {
    const { to, target, id, style, className, tooltip, children } = props;
    return (
      <a id={id} key={id} title={tooltip} href={to} target={target || '_blank'} className={className} style={style}>
        {children}
      </a>
    );
  }

  return <RouterNavLink key={props.id} title={props.tooltip} {...props} />;
};

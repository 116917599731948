import { BaseClient } from '@common/client/BaseClient';
import { MCPCarrierAssessment, MCPLinkAccount, MCPVerificationResponse, SendMCPVerificationData } from '@common/model';

export class MyCarrierPortalClient extends BaseClient {
  sendMcpDotVerification$ = (params: SendMCPVerificationData) =>
    this.api.post$<MCPVerificationResponse>(`/mycarrierpackets/sendDotVerification`, params);

  sendOnboardingRequest$ = (contactGuid: string) =>
    this.api.post$<{}>(`/mycarrierpackets/sendOnboardingRequest`, {
      contactId: contactGuid,
    });
  linkMcpAccount$ = (params: MCPLinkAccount) => this.api.post$<{}>(`/mycarrierpackets/linkAccount`, params);
  getCarrierRiskAssessment$ = (contactGuid: string) =>
    this.api.post$<MCPCarrierAssessment>(`/mycarrierpackets/getCarrierDetails`, {
      contactId: contactGuid,
    });
}

import { isEqual, toNumber } from 'lodash';
import memoizeOne from 'memoize-one';

import { LoadLocationCity, LocationSuggestion, LocationType, StorageKey } from '@common/model';
import {
  getAdditionalCosts,
  getFuelCosts,
  getProfitPerMile,
  getTotalProfit,
} from '@common/util/ProfitCalculatorHelper';
import {
  createField,
  Field,
  FieldType,
  removeComma,
} from '@component/panels/basePanel/loadDetails/profitCalculator/Field';
import { safeGetItem, safeSetItem } from '@util/LocalDataStorage';

const DEFAULT_COST_PER_GALLON_DISCOUNT = '0.40';

export interface ProfitCalculatorState {
  miles: string | undefined;
  costPerMile: string | undefined;
  rate: string | undefined;
  mpg: string | undefined;
  costPerGallon: string | undefined;
  costPerGallonDiscount: string | undefined;
  isFuelDiscountChecked: boolean;
  tollCost: string | undefined;
  dispatchFee: string | undefined;
  otherFee: string | undefined;
  isDispatchFeeFlatAmount: boolean;
  focusedField: FieldType | undefined;
  deadheadMiles: string | undefined;
  isDeadheadMilesChecked: boolean;
}

export interface CalculatorDataToPersist {
  costPerGallonDiscount: string;
  isFuelDiscountChecked: boolean;
  isDeadheadMilesChecked: boolean;
  dispatchFee: string;
  isDispatchFeeFlatAmount: boolean;
}

const getFields: (state: ProfitCalculatorState) => { [field: string]: Field } = (state) => ({
  miles: createField(FieldType.Miles, state.miles),
  costPerMile: createField(FieldType.CostPerMile, state.costPerMile),
  rate: createField(FieldType.Rate, state.rate),
  mpg: createField(FieldType.MPG, state.mpg),
  costPerGallon: createField(FieldType.CostPerGallon, state.costPerGallon),
  costPerGallonDiscount: createField(FieldType.CostPerGallonDiscount, state.costPerGallonDiscount),
  tollCost: createField(FieldType.TollCost, state.tollCost),
  dispatchFee: createField(FieldType.DispatchFee, state.dispatchFee, state.isDispatchFeeFlatAmount),
  otherFee: createField(FieldType.OtherFee, state.otherFee),
  deadheadMiles: createField(FieldType.DeadheadMiles, state.deadheadMiles),
});

export const otherFeeChange =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>, state: ProfitCalculatorState) =>
  (otherFee: string) => {
    const otherFeeField = createField(FieldType.OtherFee, otherFee);
    if (!otherFeeField.doesAllowToType()) {
      return;
    }
    setState({ ...state, otherFee: otherFeeField.text });
  };

export const dispatchFeeTypeChange =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) => (type: string) =>
    setState((prevState: ProfitCalculatorState) => {
      const isDispatchFeeFlatAmount = type === '$';
      const dispatchFeeField = createField(FieldType.DispatchFee, prevState.dispatchFee, isDispatchFeeFlatAmount);
      persistCalculatorData({ isDispatchFeeFlatAmount: isDispatchFeeFlatAmount });
      return {
        ...prevState,
        isDispatchFeeFlatAmount: isDispatchFeeFlatAmount,
        dispatchFee: dispatchFeeField.normalisedText(),
      };
    });

export const dispatchFeeChange =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) => (dispatchFee: string) =>
    setState((prevState: ProfitCalculatorState) => {
      const dispatchFeeField = createField(FieldType.DispatchFee, dispatchFee, prevState.isDispatchFeeFlatAmount);
      if (!dispatchFeeField.doesAllowToType()) {
        return prevState;
      }
      persistCalculatorData({ dispatchFee: dispatchFeeField.text });
      return { ...prevState, dispatchFee: dispatchFeeField.text };
    });

export const tollCostChange =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>, state: ProfitCalculatorState) =>
  (tollCost: string) => {
    const tollCostField = createField(FieldType.TollCost, tollCost);
    if (!tollCostField.doesAllowToType()) {
      return;
    }
    setState({ ...state, tollCost: tollCostField.text });
  };

export const rateChange =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) => (rate?: string) => {
    const rateField = createField(FieldType.Rate, rate);
    if (!rateField.doesAllowToType()) {
      return;
    }
    setState((prevState: ProfitCalculatorState) => {
      const fields = getFields(prevState);
      const costPerMile = fields.miles.isEmpty()
        ? ''
        : String(Number(rateField.normalisedText()) / fields.miles.value());
      if (!costPerMile || isEqual(costPerMile, '0')) {
        return {
          ...prevState,
          rate: rateField.text,
          costPerMile: '',
          miles: prevState.miles ?? '',
        };
      } else {
        return {
          ...prevState,
          rate: rateField.text,
          costPerMile: createField(FieldType.CostPerMile, costPerMile).normalisedText(),
        };
      }
    });
  };

export const costPerMileChange =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) => (costPerMile: string) => {
    const costPerMileField = createField(FieldType.CostPerMile, costPerMile);
    if (!costPerMileField.doesAllowToType()) {
      return;
    }
    setState((prevState: ProfitCalculatorState) => {
      const fields = getFields(prevState);
      const rate = fields.miles.isEmpty()
        ? prevState.rate
        : String(Number(costPerMileField.normalisedText()) * fields.miles.value());
      if (!rate || isEqual(rate, '0')) {
        return {
          ...prevState,
          costPerMile: costPerMileField.text,
          rate: undefined,
          miles: prevState.miles ?? '',
        };
      } else {
        return {
          ...prevState,
          costPerMile: costPerMileField.text,
          rate: createField(FieldType.Rate, rate).normalisedText(),
        };
      }
    });
  };

export const milesChange =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>, onMilesChange?: () => void) =>
  (miles: string) => {
    const milesField = createField(FieldType.Miles, miles);
    if (!milesField.doesAllowToType()) {
      return;
    }
    setState((prevState: ProfitCalculatorState) => {
      const fields = getFields(prevState);
      if (onMilesChange) {
        onMilesChange();
      }
      if (fields.costPerMile.value()) {
        const newRate = String(milesField.value() * fields.costPerMile.value());
        return {
          ...prevState,
          miles: milesField.text,
          rate: createField(FieldType.Rate, newRate).normalisedText(),
        };
      } else if (fields.rate.value()) {
        const newCostPerMileValue = fields.rate.value() / milesField.value();
        const newCostPerMile = isFinite(newCostPerMileValue) ? String(newCostPerMileValue) : '';
        return {
          ...prevState,
          miles: milesField.text,
          costPerMile: createField(FieldType.Rate, newCostPerMile).normalisedText(),
        };
      } else {
        return {
          ...prevState,
          miles: milesField.text,
          rate: undefined,
          costPerMile: '',
        };
      }
    });
  };

export const costPerGallonDiscountChange =
  (state: ProfitCalculatorState, setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) =>
  (costPerGallonDiscount: string) => {
    const costPerGallonDiscountField = createField(FieldType.CostPerGallonDiscount, costPerGallonDiscount);
    if (!costPerGallonDiscountField.doesAllowToType()) {
      return;
    }
    persistCalculatorData({ costPerGallonDiscount: costPerGallonDiscountField.text });
    setState({ ...state, costPerGallonDiscount: costPerGallonDiscountField.text });
  };

export const deadheadMilesChange =
  (state: ProfitCalculatorState, setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) =>
  (deadheadMiles: string) => {
    const deadheadMilesField = createField(FieldType.DeadheadMiles, deadheadMiles);
    if (!deadheadMilesField.doesAllowToType()) {
      return;
    }
    setState({ ...state, deadheadMiles: deadheadMilesField.text });
  };

export const mpgChange = (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) => (mpg: string) => {
  const mpgField = createField(FieldType.MPG, mpg);
  if (!mpgField.doesAllowToType()) {
    return;
  }
  setState((state) => ({ ...state, mpg: mpgField.text }));
};

export const costPerGallonChange =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) => (costPerGallon: string) => {
    const costPerGallonField = createField(FieldType.CostPerGallon, costPerGallon);
    if (!costPerGallonField.doesAllowToType()) {
      return;
    }

    setState((state) => ({ ...state, costPerGallon: costPerGallonField.text }));
  };

export const fieldFocus =
  (setState: React.Dispatch<React.SetStateAction<ProfitCalculatorState>>) => (fieldType: FieldType) => () =>
    setState((prevState) => {
      const fields = getFields(prevState);
      const text = fields[fieldType].text !== undefined ? removeComma(fields[fieldType].text ?? '') : undefined;
      return {
        ...prevState,
        [fieldType]: text,
        focusedField: fieldType,
      };
    });

export const isFieldStatesEqual = memoizeOne(
  (s1: ProfitCalculatorState, s2: ProfitCalculatorState) =>
    removeComma(s1.miles ?? '') === removeComma(s2.miles ?? '') &&
    removeComma(s1.costPerMile ?? '') === removeComma(s2.costPerMile ?? '') &&
    removeComma(s1.mpg ?? '') === removeComma(s2.mpg ?? '') &&
    removeComma(s1.costPerGallon ?? '') === removeComma(s2.costPerGallon ?? '') &&
    removeComma(s1.costPerGallonDiscount ?? '') === removeComma(s2.costPerGallonDiscount ?? '') &&
    s1.isFuelDiscountChecked === s2.isFuelDiscountChecked &&
    removeComma(s1.tollCost ?? '') === removeComma(s2.tollCost ?? '') &&
    removeComma(s1.dispatchFee ?? '') === removeComma(s2.dispatchFee ?? '') &&
    removeComma(s1.otherFee ?? '') === removeComma(s2.otherFee ?? '') &&
    s1.isDispatchFeeFlatAmount === s2.isDispatchFeeFlatAmount &&
    removeComma(s1.deadheadMiles ?? '') === removeComma(s2.deadheadMiles ?? '') &&
    s1.isDeadheadMilesChecked === s2.isDeadheadMilesChecked
);

const initialPersistedCalculatorData: CalculatorDataToPersist = {
  costPerGallonDiscount: DEFAULT_COST_PER_GALLON_DISCOUNT,
  isFuelDiscountChecked: false,
  dispatchFee: '',
  isDispatchFeeFlatAmount: false,
  isDeadheadMilesChecked: false,
};

export const persistCalculatorData = (update: Partial<CalculatorDataToPersist>) => {
  const persistedData = safeGetItem(StorageKey.ProfitCalculator);
  const parsedPersistedData: CalculatorDataToPersist =
    persistedData !== undefined ? JSON.parse(persistedData) : initialPersistedCalculatorData;
  safeSetItem(
    StorageKey.ProfitCalculator,
    JSON.stringify({ ...parsedPersistedData, ...update } as CalculatorDataToPersist)
  );
};

export const getPersistedCalculatorData = (): CalculatorDataToPersist | undefined => {
  const persistedData = safeGetItem(StorageKey.ProfitCalculator);
  if (persistedData === undefined) {
    return undefined;
  }
  return JSON.parse(persistedData);
};

export const locationMatchToLoadLocationCity = (locationSuggestion: LocationSuggestion): LoadLocationCity => {
  const mainLocationInformation = {
    states: locationSuggestion.state ? [locationSuggestion.state] : [],
    city: locationSuggestion.city ?? '',
  };
  return {
    ...mainLocationInformation,
    type: LocationType.CITY,
  };
};

export const getCosts = (state: ProfitCalculatorState) => {
  const miles = toNumberWithComma(state.miles);
  const costPerGallon = toNumberWithComma(state.costPerGallon);
  const dispatchFee = toNumberWithComma(state.dispatchFee);
  const rate = toNumberWithComma(state.rate);
  const tollCost = toNumberWithComma(state.tollCost);
  const otherFee = toNumberWithComma(state.otherFee);
  const deadheadMiles = toNumberWithComma(state.deadheadMiles);

  const fuelCosts = getFuelCosts(
    toNumber(state.mpg ?? ''),
    toNumber(state.isDeadheadMilesChecked ? miles + deadheadMiles : miles),
    toNumber(costPerGallon),
    toNumber(state.costPerGallonDiscount ?? ''),
    state.isFuelDiscountChecked
  );

  const fuelCostsForDeadheadMiles = getFuelCosts(
    toNumber(state.mpg ?? ''),
    toNumber(deadheadMiles),
    toNumber(costPerGallon),
    toNumber(state.costPerGallonDiscount ?? ''),
    state.isFuelDiscountChecked
  );

  const additionalCosts = getAdditionalCosts(
    toNumber(dispatchFee),
    toNumber(rate),
    toNumber(tollCost),
    toNumber(otherFee),
    state.isDispatchFeeFlatAmount
  );

  const totalProfit = getTotalProfit(rate, fuelCosts, additionalCosts);

  const profitPerMile = getProfitPerMile(totalProfit, miles);

  return {
    fuelCosts: fuelCosts,
    additionalCosts: additionalCosts,
    totalProfit: totalProfit,
    profitPerMile: profitPerMile,
    rate: rate,
    miles: miles,
    fuelCostsForDeadheadMiles: fuelCostsForDeadheadMiles,
  };
};

export const toNumberWithComma = (valueWithComma: string | undefined) => {
  return toNumber(removeComma(valueWithComma ?? ''));
};

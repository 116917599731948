import { BillingIssue } from '@common/model';
import { T, t } from '@translate';

export enum BillingIssueBannerTracking {
  BannerDisplayed = 'BannerDisplayed',
  BannerClicked = 'BannerClicked',
}

export interface BillingIssueBannerContent {
  message: string;
  action?: string;
  messageLastPart?: string;
}

export const generateBillingIssueBannerContent = (
  isAdmin: boolean,
  billingIssue?: BillingIssue,
  expirationDate?: string,
  isCancelling?: boolean
): BillingIssueBannerContent => {
  if (isCancelling) {
    return {
      message: `${t(T.common_paymentDeclinedBanner_unsubscribedMessage)} ${
        expirationDate ? t(T.common_paymentDeclinedBanner_accessUntil, { date: expirationDate }) : ''
      } ${t(T.common_paymentDeclinedBanner_reactivate)}`,
      action: `${t(T.common_paymentDeclinedBanner_unsubscribeBannerMessageLink)}`,
    };
  }

  if (isAdmin) {
    switch (billingIssue) {
      case BillingIssue.AccountExpired:
        return {
          message: t(T.common_paymentDeclinedBanner_accountExpired_message),
          action: t(T.common_paymentDeclinedBanner_actions_fixNow),
        };
      case BillingIssue.AccountExpiringSoon:
        return {
          message: t(T.common_paymentDeclinedBanner_accountExpiringSoon_message),
          action: t(T.common_paymentDeclinedBanner_actions_fixNow),
        };
      case BillingIssue.HardDecline:
        return {
          message: t(T.common_paymentDeclinedBanner_hardDecline_messageFirstPart),
          action: t(T.common_paymentDeclinedBanner_actions_here),
          messageLastPart: expirationDate
            ? t(T.common_paymentDeclinedBanner_hardDecline_messageLastPart, { date: expirationDate })
            : null,
        };
      case BillingIssue.SoftDecline:
        return {
          message: t(T.common_paymentDeclinedBanner_softDecline_messageFirstPart),
          action: t(T.common_paymentDeclinedBanner_actions_here),
          messageLastPart: expirationDate
            ? t(T.common_paymentDeclinedBanner_softDecline_messageLastPart, { date: expirationDate })
            : null,
        };

      default:
        return {
          message: t(T.common_paymentDeclinedBanner_generic_message),
          action: t(T.common_paymentDeclinedBanner_actions_viewDetails),
        };
    }
  }

  switch (billingIssue) {
    case BillingIssue.AccountExpired:
      return {
        message: t(T.common_paymentDeclinedBanner_accountExpired_message),
      };
    case BillingIssue.AccountExpiringSoon:
      return {
        message: t(T.common_paymentDeclinedBanner_accountExpiringSoon_messageNonAdmin),
      };
    case BillingIssue.HardDecline:
      return {
        message: t(T.common_paymentDeclinedBanner_hardDecline_messageNonAdmin),
      };
    case BillingIssue.SoftDecline:
      return {
        message: t(T.common_paymentDeclinedBanner_softDecline_messageNonAdmin),
      };

    default:
      return {
        message: t(T.common_paymentDeclinedBanner_generic_message),
      };
  }
};

import React, { useEffect } from 'react';

import { BasePopup } from '@component/popup/BasePopup';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

export const SessionExpiredPopUp: React.FC = () => {
  const { openingPopup } = usePopup();
  const { isUserIdle, isPageHidden } = useSelector((state) => state.userActivity);

  useEffect(() => {
    if (isUserIdle && !isPageHidden) {
      openingPopup({
        body: <BasePopup title={t(T.sessionPausedPopUp_title)}>{t(T.sessionPausedPopUp_content)}</BasePopup>,
        width: PopupSizes.SMALL,
        isDismissible: true,
        hasCloseButton: true,
      });
    }
  }, [isUserIdle, isPageHidden]);

  return null;
};

import React, { useEffect, useState } from 'react';

import { isEmpty, toNumber } from 'lodash';
import { useDispatch } from 'react-redux';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { useSelector } from '@/util/hooks';
import { getValidDOT } from '@common/helper/VendorLoadHelper';
import { updateCompanyInfo } from '@common/redux/epic/CompanyEpic';
import { fetchUser } from '@common/redux/epic/UserEpic';
import { TextField } from '@component/input/textField/CustomTextField';
import {
  FormContainer,
  PopUpButton,
  TextContainer,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { BasePopup } from '@component/popup/BasePopup';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text';
import { T, t } from '@translate';

interface Props {
  title?: string;
  onSuccess?: () => void;
}

export const EnterDOT: React.FC<Props> = (props) => {
  const { closingPopup } = usePopup();
  const [dot, setDot] = useState('');
  const [DOTSubmitted, setDOTSubmitted] = useState(false);
  const [isFieldEmpty, setIsFieldEmpty] = useState(false);
  const company = useSelector((state) => state.companyState.company);
  const wasUpdatedSuccessfully = useSelector((state) => state.companyState.updateCompany.updatedSuccessfully);
  const isLoading = useSelector((state) => state.companyState.updateCompany.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && DOTSubmitted) {
      closingPopup();
      if (wasUpdatedSuccessfully) {
        dispatch(fetchUser(true, true));
        if (props.onSuccess) {
          props.onSuccess();
        }
      } else {
        dispatch(showSnackbar({ message: t(T.common_vendorBid_dotPopUp_updateStatus_failure) }));
      }
      setDOTSubmitted(false);
    }
  }, [isLoading, DOTSubmitted, wasUpdatedSuccessfully]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDot = getValidDOT(event.target.value);
    if (formattedDot !== undefined) {
      setDot(formattedDot);
    }
  };

  const onClickSubmit = () => {
    if (isEmpty(dot)) {
      setIsFieldEmpty(true);
    } else if (company) {
      setDOTSubmitted(true);
      dispatch(updateCompanyInfo({ ...company, usdotNumber: toNumber(dot) }));
    }
  };

  return (
    <BasePopup title={t(T.common_vendorBid_dotPopUp_title)} onClose={closingPopup}>
      <TextContainer>
        <Text id="enter_dot_title" textStyle={TextStyle.BodyLarge}>
          {props.title ? props.title : t(T.common_vendorBid_dotPopUp_info)}
        </Text>
      </TextContainer>
      <FormContainer>
        <TextField
          id="dot_field"
          label={t(T.common_vendorBid_dotPopUp_textFieldLabel)}
          style={{ marginBottom: '16px' }}
          value={dot}
          onChange={onChange}
          error={isFieldEmpty}
        />
        <PopUpButton
          id="submit_dot"
          variant="contained"
          color="primary"
          text={t(T.common_vendorBid_dotPopUp_button)}
          isLoading={DOTSubmitted}
          onClick={onClickSubmit}
        />
      </FormContainer>
    </BasePopup>
  );
};

import { BaseClient } from '@common/client/BaseClient';
import { Avatar } from '@common/redux/epic/AvatarEpic';

export interface UploadAvatarData {
  mimeType: string;
  file: Blob | Uint8Array;
}

export class AvatarClient extends BaseClient {
  fetchAvatar$ = (imageSize: string) =>
    this.api.get$<string>(`/profile/avatar/?imageType=${imageSize}`, {}, { responseType: 'arraybuffer' });
  fetchOtherUserAvatar$ = (id: string) =>
    this.api.get$<string>(`/profile/${id}/avatar?imageType=Small`, {}, { responseType: 'arraybuffer' });
  uploadAvatar$ = (uploadParams: UploadAvatarData) => {
    return this.api.put$<Avatar>('/profile/avatar', uploadParams.file, {
      headers: { 'Content-Type': uploadParams.mimeType },
    });
  };
  deleteAvatar$ = () => this.api.delete$<undefined>(`/profile/avatar`, {});
}

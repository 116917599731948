import { join } from 'lodash';

import { t, T, tEquipments } from '@/translate';
import { secondsToTimeObject, TimeObject } from '@common/helper/DateHelper';
import { stringifyEquipments } from '@common/helper/EquipmentHelper';
import { Equipment } from '@common/model/Equipment';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';

export const LOCATION_LENGHT_LIMIT_LARGE = 15;
export const LOCATION_LENGHT_LIMIT_SMALL = 15;
export const EQUIPMENT_LENGHT_LIMIT_LARGE = 18;
export const EQUIPMENT_LENGHT_LIMIT_SMALL = 12;

export enum TableSizeState {
  small,
  medium,
  large,
  unknown,
}

export const stringifyTimeObj = (timeObj: TimeObject) => {
  let timeString = '';
  if (timeObj.days > 0) {
    timeString += timeObj.days + t(T.common_time_abbreviation_Days) + ' ';
  }
  if (timeObj.hours > 0) {
    timeString += timeObj.hours + t(T.common_time_abbreviation_Hours) + ' ';
  }
  if (timeObj.minutes > 0) {
    timeString += timeObj.minutes + t(T.common_time_abbreviation_Minutes);
  }
  return timeString;
};

export const stringifyAge = (age: number, justNowMessage: string = NO_INFORMATION_SYMBOL) => {
  if (age <= 0) {
    return justNowMessage;
  }
  if (age < 60) {
    return `${age}${t(T.common_time_abbreviation_Seconds)}`;
  }
  return stringifyTimeObj(secondsToTimeObject(age));
};

export function stringifyLoadEquipment(equipments: Equipment[], limit: number, stringifyAll: boolean = false): string {
  if (stringifyAll) {
    return join(stringifyEquipments(tEquipments(equipments)), ', ');
  } else if (equipments.length > 0) {
    let equipmentString: string = join(stringifyEquipments(tEquipments(equipments)), ', ');
    if (equipmentString.length > limit) {
      equipmentString = equipmentString.substring(0, limit) + '...';
    }
    return equipmentString;
  }
  return NO_INFORMATION_SYMBOL;
}

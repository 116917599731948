import React, { PropsWithChildren, ReactNode, useState } from 'react';

import { map, reduce } from 'lodash';
import styled from 'styled-components';

import { CHIP_ITEM_HEIGHT } from '@component/chip/ChipStyle';
import { ThemeProps, withTheme } from '@style/WithTheme';

import { useItemsWidthMeasurer } from './ItemsWidthMeasurer';
import { WidthMeasurer } from './WidthMeasurer';

const MAX_WIDTH_OVERFLOW_COUNT = 26;

interface Props<T> {
  id: string;
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
  customOverflowComponent?: JSX.Element;
  customMaxCounterWidth?: number;
  containerStyle?: React.CSSProperties;
  itemsContainerStyle?: React.CSSProperties;
}

const Counter = withTheme()(styled.div`
  color: ${(props: ThemeProps) => props.theme.palette.multiSelect.labelColor};
  font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
  font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
  line-height: ${CHIP_ITEM_HEIGHT}px;
`);

const Container = styled(WidthMeasurer)`
  display: flex;
  width: 100%;
`;

const ItemsContainer = styled.div`
  display: flex;
`;

export const OverflowRenderer = <T,>(props: PropsWithChildren<Props<T>>) => {
  const { items, renderItem, customOverflowComponent, customMaxCounterWidth, containerStyle, itemsContainerStyle } =
    props;
  const [itemWidths, renderItemsMeasurement] = useItemsWidthMeasurer(items, renderItem);
  const [availableWidth, setAvailableWidth] = useState(0);

  const placeableItems = reduce(
    items,
    ({ placeableItems, availableWidth }, item, index) => {
      // get(index) should always return a number here, but if
      // for whatever reason it returns undefined, set the item
      // width to more than what's available so it does not get rendered.
      const width = itemWidths[index] || availableWidth + 1;
      // if it's the last item, every item before it was placed, and it fits, display it
      // otherwise, check if item and a potential '+X' can fit
      const isLastItem = index === items.length - 1 && items.length === placeableItems.length + 1;
      const remainingWidth = isLastItem
        ? availableWidth
        : availableWidth - (customMaxCounterWidth ?? MAX_WIDTH_OVERFLOW_COUNT);
      return remainingWidth >= width
        ? {
            placeableItems: [...placeableItems, item],
            availableWidth: availableWidth - width,
          }
        : { placeableItems: placeableItems, availableWidth: availableWidth };
    },
    {
      placeableItems: [],
      availableWidth: availableWidth,
    }
  ).placeableItems;

  const renderCountNumber = (count: number) =>
    count > 0 ? (customOverflowComponent ?? <Counter id="hidden_chips_count">+{count}</Counter>) : null;

  return (
    <Container style={containerStyle} onMeasure={setAvailableWidth}>
      <ItemsContainer style={itemsContainerStyle}>
        {renderItemsMeasurement()}
        {map(placeableItems, renderItem)}
      </ItemsContainer>
      {renderCountNumber(items.length - placeableItems.length)}
    </Container>
  );
};

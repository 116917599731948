import { find, join, last, map, reduce, slice, some, split } from 'lodash';
import memoizeOne from 'memoize-one';

import { CustomFolder, Document, DocumentType } from '@common/model';
import { ConversationAttachment } from '@common/model/Conversation';
import { t, T } from '@translate';

const ATTACHED_FILES_LIMIT_IN_MB = 25;

export const documentTypeToString =
  (documentTypes: DocumentType[]) =>
  (enumName: string): string => {
    const documentType = find(documentTypes, (type) => type.enumName === enumName);
    return documentType && documentType.description ? documentType.description : t(T.common_all);
  };

export const loadInfoFromFolderIdentifier = (folderIdentifier: CustomFolder) =>
  `${folderIdentifier.origCity}, ${folderIdentifier.origState} > ` +
  `${folderIdentifier.destCity}, ${folderIdentifier.destState}`;

const byteToMB = (sizeInByte: number) => sizeInByte / 1000000;

export const doDocumentsExceedSizeLimitForSending = (documents: Document[]) =>
  reduce(documents, (size, document) => size + byteToMB(document.fileSize ?? 0), 0) > ATTACHED_FILES_LIMIT_IN_MB;

export const mapDocumentsToConversationAttachment = (documents: Document[]): ConversationAttachment[] =>
  map(documents, (document) => ({
    id: document.id,
    // API does not store an attachment's file extension
    // we append it to the display name here since it is necessary
    // later when the user views the attachment
    name: `${document.displayName}.${document.extension}`,
  }));

export const trimFileExtension = (fileName: string) => join(slice(split(fileName, '.'), 0, -1), '.');

export const getFileExtension = (fileName: string, defaultExtension: string = '') => {
  const fileNameParts = split(fileName, '.');
  if (fileNameParts.length > 1) {
    const extension = last(fileNameParts);
    if (extension) {
      return extension;
    }
  }
  return defaultExtension;
};

export const hasRateConfirmation = memoizeOne((documents: Document[]) =>
  some(documents, (document) => document.type === 'RateConfirmation')
);

export const hasBOLorLumpers = memoizeOne((documents: Document[]) =>
  some(documents, (document) => document.type === 'BillOfLading' || document.type === 'LumperReceipt')
);

export const hasProofOfDelivery = memoizeOne((documents: Document[]) =>
  some(documents, (document) => document.type === 'ProofOfDelivery')
);

export const getFileActionTitle = (isFileAttached: boolean): string =>
  isFileAttached ? t(T.common_manage) : t(T.common_upload);

import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { default as CheckIcon } from '@/icons/check.svg';
import { ExpandableSections, setExpandedSection } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { ApiErrorCode } from '@common/api';
import { Flag, MCPRiskAssessment } from '@common/model';
import { resetMCPApiError } from '@common/redux/epic/MyCarrierPortalEpic';
import { useDidUpdate } from '@common/util/hooks';
import { MultipurposeButton, MultipurposeButtonProps } from '@component/buttons';
import { AccordionInfoStatus, ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import {
  CenteringContainer,
  Container,
  DataWrapper,
} from '@component/panels/truckLocator/carrierDetails/CarrierDetailsPanelStyles';
import { ApiErrorPopup } from '@component/popup/ApiErrorPopup';
import { BasePopup } from '@component/popup/BasePopup';
import { MCPOnboardingPopUp } from '@component/popup/myCarrierPortal/MCPOnboardingPopup';
import { SetupIntegrationInfoPopup } from '@component/popup/myCarrierPortal/SetupIntegrationInfoPopup';
import { PopupOkButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT } from '@util/Constants';
import { useSelector } from '@util/hooks';

import { default as MCPLogo } from './logo-mcp.svg';
import { default as FailRisk } from './risk-fail.svg';
import { default as LowRisk } from './risk-low.svg';
import { default as MediumRisk } from './risk-medium.svg';
import { default as ReviewRisk } from './risk-review.svg';

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Divider = styled.div`
  margin: 20px;
  border-top: 1px solid ${Color.GRAY_SMOKE};
  width: 100%;
`;

const LegendText = styled(Text)`
  line-height: 24px;
`;

interface Props {
  isOnboarded?: boolean;
  contactGuid?: string;
}

export const RiskAssessmentExpansionPanel = ({ isOnboarded, contactGuid }: Props) => {
  const dispatch = useDispatch();
  const { openingPopup, closingPopup } = usePopup();
  const onboardingState = useSelector((state) => state.myCarrierPortal.onboarding);
  const linkingState = useSelector((state) => state.myCarrierPortal.linking);
  const carrierRiskAssessment = useSelector((state) => state.myCarrierPortal.carrierRiskAssessment);
  const isLoading = useSelector((state) => state.myCarrierPortal.isLoadingRiskAssessment);
  const isPanelExpanded = useSelector(
    (state) => state.applicationSettings.expandedSections.CarrierDetailsRiskAssessment
  );
  const isMCPEnabled = useSelector((state) => state.settings.systemSetting[Flag.MyCarrierPortalBrokerEnabled].value);
  const didCarrierFailRiskAssessment =
    onboardingState.onboardingError?.code === ApiErrorCode.MCP_ONBOARDING_CARRIER_FAILED_RISK_ASSESSMENT;

  const riskConfig = generateRiskLevelConfig(carrierRiskAssessment);

  const toggleExpansionPanel = () => {
    dispatch(setExpandedSection(ExpandableSections.CarrierDetailsRiskAssessment, !isPanelExpanded));
  };

  const displayIntegrationPopup = () =>
    openingPopup({
      body: <SetupIntegrationInfoPopup isOnboarded={!!isOnboarded} contactGuid={contactGuid} />,
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });

  const onCloseFailedRiskAssessmentPopup = () => {
    dispatch(resetMCPApiError());
    closingPopup();
  };

  useDidUpdate(() => {
    if (didCarrierFailRiskAssessment) {
      openingPopup({
        body: (
          <BasePopup
            title={t(T.myCarrierPortal_onboarding_unsuccessful)}
            onClose={onCloseFailedRiskAssessmentPopup}
            buttons={[<PopupOkButton testID={'ok'} key={'ok'} action={onCloseFailedRiskAssessmentPopup} />]}
          >
            {t(T.myCarrierPortal_onboarding_onboardingFailed)}
          </BasePopup>
        ),
        width: PopupSizes.SMALL,
      });
    }
  }, [didCarrierFailRiskAssessment]);

  if (!isMCPEnabled) {
    return null;
  }
  const status =
    linkingState.isBrokerAccountLinkedToMcp === false ? AccordionInfoStatus.OFFLINE : AccordionInfoStatus.AVAILABLE;
  return (
    <>
      <ExpansionPanel
        id="MCP_risk_assessment"
        icon={MCPLogo}
        titleText={t(T.myCarrierPortal_riskAssessment)}
        isOpened={isPanelExpanded}
        handleClick={toggleExpansionPanel}
        isBlocked={linkingState.isBrokerAccountLinkedToMcp === false}
        customOfflineText={t(T.myCarrierPortal_integrationRequired)}
        subtitleText={riskConfig.title}
        status={isLoading ? AccordionInfoStatus.LOADING : status}
        panelTitleStyle={{ height: COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT }}
        rightButtonProps={{
          id: 'unlock',
          title: t(T.findLoads_loadDetails_unlock),
          onClick: displayIntegrationPopup,
        }}
      >
        <DataWrapper>
          {isLoading ? (
            <CenteringContainer style={{ alignItems: 'center' }}>
              <LoadingSpinner />
            </CenteringContainer>
          ) : (
            <StyledContainer isWithoutTopLine={true}>
              <>
                {riskConfig.imgSrc ? (
                  <>
                    <img src={riskConfig.imgSrc} alt="riskLevel" />
                    <Divider />
                    <LegendText id="low" style={{ paddingBottom: 16 }} textStyle={TextStyle.SubtitleRegular}>
                      <LegendText id="low_title" textStyle={TextStyle.SubtitleBold}>
                        {t(T.myCarrierPortal_risklevel_low)}
                      </LegendText>
                      {t(T.myCarrierPortal_risklevel_descriptionLow)}
                    </LegendText>
                    <LegendText id="medium" style={{ paddingBottom: 16 }} textStyle={TextStyle.SubtitleRegular}>
                      <LegendText id="medium_title" textStyle={TextStyle.SubtitleBold}>
                        {t(T.myCarrierPortal_risklevel_medium)}
                      </LegendText>
                      {t(T.myCarrierPortal_risklevel_descriptionMedium)}
                    </LegendText>
                    <LegendText id="review" style={{ paddingBottom: 16 }} textStyle={TextStyle.SubtitleRegular}>
                      <LegendText id="review_title" textStyle={TextStyle.SubtitleBold}>
                        {t(T.myCarrierPortal_risklevel_review)}
                      </LegendText>
                      {t(T.myCarrierPortal_risklevel_descriptionReview)}
                    </LegendText>
                    <LegendText id="fail" style={{ paddingBottom: 16 }} textStyle={TextStyle.SubtitleRegular}>
                      <LegendText id="fail_title" textStyle={TextStyle.SubtitleBold}>
                        {t(T.myCarrierPortal_risklevel_fail)}
                      </LegendText>
                      {t(T.myCarrierPortal_risklevel_descriptionFail)}
                    </LegendText>
                  </>
                ) : (
                  <Text id="error" textStyle={TextStyle.Default}>
                    {t(T.myCarrierPortal_risklevel_error)}
                  </Text>
                )}
              </>
            </StyledContainer>
          )}
        </DataWrapper>
      </ExpansionPanel>
      {onboardingState.onboardingError && !didCarrierFailRiskAssessment ? (
        <ApiErrorPopup error={onboardingState.onboardingError} onClose={() => dispatch(resetMCPApiError())} />
      ) : null}
      {linkingState.linkError && linkingState.linkError.code !== ApiErrorCode.MCP_INVALID_CREDENTIALS ? (
        <ApiErrorPopup error={linkingState.linkError} onClose={() => dispatch(resetMCPApiError())} />
      ) : null}
    </>
  );
};

const generateOnboardButtonConfig = (
  isOnboarded: boolean,
  isOnboardingRequestSent: boolean
): MultipurposeButtonProps => {
  const buttonStyle = { borderRadius: 16, paddingBottom: 3, maxWidth: 'max-content', fontSize: FontSize.ContentDetail };
  if (isOnboarded) {
    return {
      id: 'onboarded',
      text: t(T.myCarrierPortal_onboarded),
      disabled: true,
      icon: CheckIcon,
      style: { ...buttonStyle, color: Color.GRAY_TEXT },
      iconStyle: { paddingRight: 5, paddingBottom: 2 },
    };
  }
  if (isOnboardingRequestSent) {
    return {
      id: 'onboard_pending',
      text: t(T.myCarrierPortal_onboarding_pending),
      style: { ...buttonStyle, color: Color.GRAY_TEXT },
      disabled: true,
    };
  }
  return {
    id: 'onboard',
    text: t(T.myCarrierPortal_onboard),
    style: { ...buttonStyle, color: Color.GRAY_4, backgroundColor: 'white', border: `1px solid #C4C4C4` },
  };
};

interface ButtonProps {
  isOnboarded: boolean;
  contactGuid?: string;
}

export const MCPOnboardingButton = ({ isOnboarded, contactGuid }: ButtonProps) => {
  const { openingPopup } = usePopup();
  const linkingState = useSelector((state) => state.myCarrierPortal.linking);
  const onboardingState = useSelector((state) => state.myCarrierPortal.onboarding);

  const onboardingRequest = () => {
    openingPopup({
      body:
        linkingState.isBrokerAccountLinkedToMcp && contactGuid ? (
          <MCPOnboardingPopUp contactGuid={contactGuid} />
        ) : (
          <SetupIntegrationInfoPopup isOnboarded={isOnboarded} contactGuid={contactGuid} />
        ),
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  };

  if (linkingState.isBrokerAccountLinkedToMcp === undefined) {
    return null;
  }

  return (
    <MultipurposeButton
      {...generateOnboardButtonConfig(
        onboardingState.isOnboarded || isOnboarded,
        onboardingState.isOnboardingRequestSent
      )}
      variant="contained"
      size="medium"
      onClick={onboardingRequest}
    />
  );
};

interface RiskLevelConfig {
  title: string;
  imgSrc?: string;
}

const generateRiskLevelConfig = (carrierRiskAssessment?: MCPRiskAssessment): RiskLevelConfig => {
  switch (carrierRiskAssessment) {
    case MCPRiskAssessment.LOW:
      return {
        imgSrc: LowRisk,
        title: t(T.myCarrierPortal_risklevel_titleLow),
      };
    case MCPRiskAssessment.MODERATE:
      return {
        imgSrc: MediumRisk,
        title: t(T.myCarrierPortal_risklevel_titleMedium),
      };
    case MCPRiskAssessment.REVIEW:
      return {
        imgSrc: ReviewRisk,
        title: t(T.myCarrierPortal_risklevel_titleReview),
      };
    case MCPRiskAssessment.FAIL:
      return {
        imgSrc: FailRisk,
        title: t(T.myCarrierPortal_risklevel_titleFail),
      };
    default:
      return {
        title: t(T.common_marketRate_notAvailableAtThisTime),
      };
  }
};

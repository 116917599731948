import React, { useEffect, useMemo, useState } from 'react';

import { forEach, get, map } from 'lodash';
import { useDispatch } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components';

import { default as ExpandMoreIcon } from '@material-ui/icons/KeyboardArrowDown';

import { fetchCarrierMapHistory } from '@/reduxStore/epic/CarrierEpic';
import { ActionIcon } from '@component/icon/ActionIcon';
import { SIMPLEMAPS_MAPINFO } from '@component/panels/basePanel/searchPanel/findLoadsSimpleMap/MapInfo';
import { getRegionByName, getRegionIdByName } from '@component/panels/basePanel/searchPanel/findLoadsSimpleMap/Regions';
import { RESIZE_REFRESH_RATE } from '@component/panelSizeDetector/PanelSizeDetector';
import { SimpleMap, useSimpleMaps } from '@component/simplemap/SimpleMap';
import { stateBboxArray } from '@component/simplemap/StateBboxArray';
import { LoadSearchRegion } from '@page/findLoads/RouteParams';
import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { getStatesBboxUnion } from '@util/helper/SimpleMapsHelper';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

import { SIMPLEMAPS_CARRIER_MAPDATA } from './CarrierMapData';

interface Props {
  contactGuid: string | undefined;
  isPanelSingleColumn: boolean;
}

const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 1px;
  border-color: ${Color.GRAY_SMOKE};
  border-bottom-style: solid;
`;

const CountrySelectionBar = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  width: 102px;
  height: 40px;
`;

const SelectCountryButton = styled.div`
  padding: 20px 0 0 20px;
  color: ${Color.ORANGE_MAIN};
`;

const CarrierMapContainer = styled.div`
  display: flex;
  align-self: center;
  height: 300px;
  min-width: 300px;
  flex: 1;
  flex-direction: column;
`;
const MapDescription = withTheme()(styled.label`
  font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
  font-size: 12px;
  line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
  color: ${(props: ThemeProps) => props.theme.palette.background.stone};
  margin-top: 10px;
  align-self: center;
`);

const MenuIcon = withTheme()(styled(ExpandMoreIcon)`
  padding-left: ${Spacing.InterListElement}px;
  align-self: flex-end;
  color: ${Color.GRAY_DARK};
`);

export const CarrierHistoryStatesMap: React.FC<Props> = ({ contactGuid, isPanelSingleColumn }) => {
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState(LoadSearchRegion.USA);
  const [containerWidth, setContainerWidth] = useState(0);

  const selectedRegion = useMemo(() => getRegionByName(selectedCountry), [selectedCountry]);
  const selectedRegionId = useMemo(() => getRegionIdByName(selectedCountry), [selectedCountry]);
  const carriersMapHistory = useSelector((store) => store.carriers.carrierMapInfo.statesHistory);

  const selectedStates =
    carriersMapHistory !== undefined ? map(carriersMapHistory, (historyItem) => historyItem.state) : [];

  const {
    simpleMapInit,
    simpleMapApi,
    simpleMapsData,
    simpleMapsInfo,
    isSimpleMapReadyToRender,
    setIsSimpleMapsReadyToRender,
  } = useSimpleMaps(SIMPLEMAPS_CARRIER_MAPDATA, SIMPLEMAPS_MAPINFO);

  useEffect(() => {
    if (contactGuid) {
      dispatch(fetchCarrierMapHistory(contactGuid));
    }
  }, [contactGuid]);

  useEffect(() => {
    simpleMapsData.main_settings.initial_zoom = selectedRegionId;
    simpleMapsData.main_settings.div = 'selectStatesMap';
    simpleMapsData.regions = selectedRegion;

    const selectedRegionBboxArray = map((selectedRegion as any)[selectedRegionId].states, (state) =>
      get(stateBboxArray, state)
    );
    simpleMapsInfo.initial_view = getStatesBboxUnion(selectedRegionBboxArray);

    simpleMapInit(simpleMapsData, simpleMapsInfo);
    const simpleMapsAPI = simpleMapApi();

    if (isSimpleMapReadyToRender) {
      simpleMapsAPI.load();
    }
  }, [selectedCountry, isSimpleMapReadyToRender]);

  useEffect(() => {
    if (isSimpleMapReadyToRender) {
      forEach(carriersMapHistory, (historyItem) => {
        if (historyItem.state) {
          // For now we are only considering the US + Alaska map, in case we have data for Alaska,
          // we convert the state value (AK) into the map component state that shows Alaska in the US (AK_USA)
          const stateToUse = historyItem.state === 'AK' ? 'AK_USA' : historyItem.state;
          if (historyItem.levelPercent && simpleMapsData.state_specific[stateToUse]) {
            simpleMapsData.state_specific[stateToUse].color = findColourForLevelPercent(historyItem.levelPercent);

            simpleMapApi().refresh_state(stateToUse);
          }
        }
      });
    }
  }, [selectedStates, isSimpleMapReadyToRender]);

  const onCountryChange = () => {
    setSelectedCountry(selectedCountry === LoadSearchRegion.USA ? LoadSearchRegion.Canada : LoadSearchRegion.USA);
  };

  return (
    <MainContainer style={{ flexDirection: isPanelSingleColumn ? 'column' : 'row' }}>
      <CountrySelectionBar onClick={onCountryChange}>
        <SelectCountryButton>{selectedCountry}</SelectCountryButton>
        <MenuIcon />
      </CountrySelectionBar>
      <CarrierMapContainer>
        <ReactResizeDetector
          handleWidth={true}
          refreshMode="debounce"
          refreshRate={RESIZE_REFRESH_RATE}
          refreshOptions={{ leading: false }}
          onResize={(width) => setContainerWidth(width)}
        />
        <SimpleMap
          id={simpleMapsData.main_settings.div}
          initialView={simpleMapsInfo.initial_view}
          panelWidth={containerWidth}
          isSimpleMapsReadyToRender={isSimpleMapReadyToRender}
          setIsSimpleMapsReadyToRender={setIsSimpleMapsReadyToRender}
          selectedRegion={selectedRegion}
          backgroundColor={Color.WHITE}
          isCarrierHistoryMap={true}
          isPanelSingleColumn={isPanelSingleColumn}
        />
        <MapDescription style={{ fontStyle: 'italic', marginLeft: isPanelSingleColumn ? 0 : '-90px' }}>
          <ActionIcon
            id="info"
            alt="info"
            src={icons.info_small_gray}
            size={14}
            style={{ margin: '0 2px 2px 5px', cursor: 'default' }}
            tabIndex={-1}
          />
          {t(T.common_truck_Carrier_History_Map)}
        </MapDescription>
      </CarrierMapContainer>
    </MainContainer>
  );
};

const findColourForLevelPercent = (levelPercent: number) => {
  if (levelPercent >= 0.875) {
    return Color.MAP_GREEN_0875_1;
  } else if (levelPercent >= 0.75) {
    return Color.MAP_GREEN_0750_0875;
  } else if (levelPercent >= 0.625) {
    return Color.MAP_GREEN_0625_0750;
  } else if (levelPercent >= 0.5) {
    return Color.MAP_GREEN_0500_0625;
  } else if (levelPercent >= 0.375) {
    return Color.MAP_GREEN_0375_0500;
  } else if (levelPercent >= 0.25) {
    return Color.MAP_GREEN_0250_0375;
  } else if (levelPercent >= 0.125) {
    return Color.MAP_GREEN_0125_0250;
  }

  return Color.MAP_GREEN_0_0125;
};

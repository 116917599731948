import { concat, curry, filter, map, reduce, slice } from 'lodash';

import { Load, LoadSearchSort, LoadSortCategory, LoadSortDirection, LoadStatus } from '@common/model';
import { LoadSort } from '@common/sort';
import { SortCategory, SortOrder } from '@common/sort/Sort';

//============================================================================
// Notes on Load Merging
//----------------------------------------------------------------------------
//
// Due to potential sort inconsistencies between the server and client (due to
// float rounding errors), avoid sorting what was already sorted.
//
// API may occasionally return duplicate results, so do not assume perfect data
//

const getLoadsMap = (loads: Load[]) =>
  reduce(loads, (loadsMap: Record<string, Load>, load) => ({ ...loadsMap, [load.id]: load }), {});

const updateLoad = (existingLoad: Load, updatedLoad: Load) =>
  updatedLoad.status === LoadStatus.Online
    ? updatedLoad
    : // deleted loads do not come with any populated fields,
      // so DO NOT use them to overwrite existing data
      { ...existingLoad, status: updatedLoad.status };

/**
 * Merges existing load list with latest data received from API
 * @param currentLoads existing list of loads
 * @param refreshedLoads updates to existing loads and new loads to be inserted into currentLoads
 * @param nextLoads new loads to be appended to our list (pagination)
 */
export const mergeLoadLists = (
  currentLoads: Load[] = [],
  refreshedLoads: Load[] = [],
  nextLoads: Load[] = [],
  sortOrder: LoadSearchSort[] = []
): Load[] => {
  const refreshedLoadsWithAutoRefreshFlag = map(refreshedLoads, (load) => ({ ...load, isFromAutorefresh: true }));
  const refreshedLoadsMap = getLoadsMap(refreshedLoadsWithAutoRefreshFlag);
  const nextLoadsMap = getLoadsMap(nextLoads);

  // Iterate through the existing loads list and grab updated load data for each existing load
  // from refreshedLoads or nextLoads -- if found.
  // To avoid duplication, if updated load data is found in refreshedLoads or nextLoads,
  // remove the load from refreshedLoads/nextLoads after overwriting existing load (i.e. always
  // prefer a load's pre-existing index in the list)
  const { currentLoadsRefreshed, refreshedLoadsToInsert, loadsToAppend } = reduce(
    currentLoads,
    (acc, existingLoad) => {
      const currentLoadsRefreshed = acc.currentLoadsRefreshed;
      let refreshedLoadsToInsert = acc.refreshedLoadsToInsert;
      let loadsToAppend = acc.loadsToAppend;

      let updatedLoad: Load | undefined = undefined;

      const matchingRefreshedLoad = refreshedLoadsMap[existingLoad.id];
      if (matchingRefreshedLoad) {
        updatedLoad = updateLoad(existingLoad, matchingRefreshedLoad);
        refreshedLoadsToInsert = filter(refreshedLoadsToInsert, (load) => load.id !== existingLoad.id);
      }

      const matchingNewLoad = nextLoadsMap[existingLoad.id];
      if (matchingNewLoad) {
        updatedLoad = updateLoad(existingLoad, matchingNewLoad);
        loadsToAppend = filter(loadsToAppend, (load) => load.id !== existingLoad.id);
      }

      currentLoadsRefreshed.push(updatedLoad ?? existingLoad);

      return {
        currentLoadsRefreshed: currentLoadsRefreshed,
        refreshedLoadsToInsert: refreshedLoadsToInsert,
        loadsToAppend: loadsToAppend,
      };
    },
    {
      currentLoadsRefreshed: [] as Load[],
      refreshedLoadsToInsert: refreshedLoadsWithAutoRefreshFlag,
      loadsToAppend: nextLoads,
    }
  );

  // Remove any "new" refreshed loads that aren't Online (theoretically there should only
  // be Online loads in refreshedLoadsToInsert -- this is a safety check to avoid crashes)
  const loadsToInsert = filter(refreshedLoadsToInsert, (load) => load.status === LoadStatus.Online);

  const categories = map(
    sortOrder,
    (sort): SortCategory<LoadSortCategory> => ({
      category: sort.field,
      order: sort.direction === LoadSortDirection.Asc ? SortOrder.ASC : SortOrder.DESC,
    })
  );

  // Directly append loads from pagination to the existing list (prefer API's sorting as much as possible)
  // But insert auto-refresh loads into the list following the given sorting option (we have no other choice)
  return mergeSortedArrays(
    [...currentLoadsRefreshed, ...loadsToAppend],
    loadsToInsert,
    curry(LoadSort.compare)(categories)
  );
};

/** Merge two sorted arrays using the O(n) merge algorithm. Returns a new array instance.
 *
 * @param compare returns -1 if item1 is smaller than item2, 1 if item1 is bigger than item2 and 0 if equals
 */
export const mergeSortedArrays = <T>(
  list1: T[] = [],
  list2: T[] = [],
  compare: (item1: T, item2: T) => number
): T[] => {
  let index1 = 0;
  let index2 = 0;

  const list: T[] = [];

  while (index1 < list1.length && index2 < list2.length) {
    if (compare(list1[index1], list2[index2]) <= 0) {
      list.push(list1[index1]);
      index1 += 1;
    } else {
      list.push(list2[index2]);
      index2 += 1;
    }
  }

  return concat(list, slice(list1, index1), slice(list2, index2));
};

import { ApiErrorCode } from './';

export const enum PendingActionType {
  display = 'Display',
  logout = 'Logout',
  refreshToken = 'RefreshToken',
  linkAccount = 'LinkAccount',
}

export enum LinkType {
  None = 'None',
  Web = 'Web',
  API = 'API',
  OS = 'OS',
  App = 'App',
}

export interface Link {
  title: string;
  url?: string;
  type: LinkType;
  displayAsDismiss?: boolean;
}

export interface PendingAction {
  title: string;
  message: string;
  links: Link[];
  name: PendingActionType;
  code: ApiErrorCode;
}

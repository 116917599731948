export interface InboxMessage {
  guid: string;
  sentOn: string;
  content: string;
  type: MessageType;
  subject: string;
  deleted: boolean;
  read: boolean;
  contactId?: number;
}

export enum MessageType {
  Announcement = 'Announcement',
  Notice = 'Notice',
  ServiceRelated = 'ServiceRelated',
  Promotion = 'Promotion',
  Newsletter = 'Newsletter',
}

export enum MessageAction {
  delete = 'Deleted',
  deleteForever = 'DeletedForever',
  active = 'Active',
  read = 'Read',
}

export interface GetMessagesResponse {
  hasMore: boolean;
  items: InboxMessage[];
  token: string;
}

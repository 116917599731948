import React, { useEffect } from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { Provider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'simplebar/dist/simplebar.min.css';

import { MuiThemeProvider } from '@material-ui/core';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { history } from '@/reduxStore/History';
import { persistor, store } from '@/reduxStore/Store';
import { Routing } from '@/router/Routing';
import { ReduxRegistryConnect } from '@common/redux/ReduxRegistry';
import { FeedController } from '@component/feed/FeedController';
import { FeedPopup, FeedPopupMob } from '@component/feed/FeedPopup';
import { InitialLoading } from '@component/main/InitialLoading';
import { MainLayout } from '@component/main/MainLayout';
import { TopMenuNotificationDot } from '@component/main/TopMenuNotificationDot';
import { DesktopMenu } from '@component/menu/DesktopMenu';
import { MobileMenu } from '@component/menu/MobileMenu';
import { ConnectionErrorPopup } from '@component/popup/ConnectionErrorPopup';
import { MaintenancePopup } from '@component/popup/MaintenancePopup';
import { Popup } from '@component/popup/Popup';
import { PopupProvider } from '@component/popup/PopupTrackingContext';
import { SessionExpiredPopUp } from '@component/popup/SessionExpiredPopUp';
import { ScreenSharing } from '@component/screenSharing/ScreenSharing';
import { FixedHeader } from '@component/scrollView';
import Snackbar from '@component/snack/Snackbar';
import { TopRibbon } from '@component/topRibbon';
import '@page/aspxPage/AspxSelfEmbedProtection';
import THEME from '@style/Theme';
import { t, T } from '@translate';
import { AppConfig } from '@util/AppConfig';
import { hideMainSpinner } from '@util/MainSpinnerHelper';
import {
  DESCRIPTION_META,
  OG_DESCRIPTION_META,
  OG_TITLE_META,
  TITLE_META,
  TWITTER_DESCRIPTION_META,
  TWITTER_TITLE_META,
} from '@util/Metadata';

import { appDependencies, DependencyProvider } from './AppDependencies';
import { isMessagingWidgetView } from './MessagingWidgetApp';
import { reactPlugin } from './util/trackers/AppInsights';

const isMessagingWidget = isMessagingWidgetView();

const App: React.FC = () => {
  useEffect(() => {
    hideMainSpinner();
  }, []);

  return (
    <>
      <Helmet>
        <title>{AppConfig.NAME}</title>
        <meta name={TITLE_META} content={AppConfig.NAME} />
        <meta name={TWITTER_TITLE_META} property={TWITTER_TITLE_META} content={AppConfig.NAME} />
        <meta name={OG_TITLE_META} property={OG_TITLE_META} content={AppConfig.NAME} />
        <meta name={DESCRIPTION_META} content={t(T.Meta_Description)} />
        <meta name={TWITTER_DESCRIPTION_META} property={TWITTER_DESCRIPTION_META} content={t(T.Meta_Description)} />
        <meta name={OG_DESCRIPTION_META} property={OG_DESCRIPTION_META} content={t(T.Meta_Description)} />
      </Helmet>

      <Provider store={store} context={ReactReduxContext}>
        <ReduxRegistryConnect>
          <PersistGate persistor={persistor}>
            <DependencyProvider container={appDependencies}>
              <ConnectedRouter history={history} context={ReactReduxContext}>
                <MuiThemeProvider theme={THEME}>
                  <InitialLoading />
                  <PopupProvider>
                    <MainLayout
                      hideHeaders={isMessagingWidget}
                      menuButtonDecoration={<TopMenuNotificationDot />}
                      topRightComponent={<FeedController />}
                      mobileMenuChildren={<MobileMenuChildren />}
                      desktopMenuChildren={<DesktopMenuChildren />}
                    >
                      <FixedHeader>
                        <TopRibbon />
                      </FixedHeader>
                      <Routing />
                      <ConnectionErrorPopup />
                      <SessionExpiredPopUp />
                      <MaintenancePopup />
                      <ScreenSharing />
                      <Popup />
                    </MainLayout>
                  </PopupProvider>
                  <Snackbar />
                </MuiThemeProvider>
              </ConnectedRouter>
            </DependencyProvider>
          </PersistGate>
        </ReduxRegistryConnect>
      </Provider>
    </>
  );
};

const MobileMenuChildren: React.FC = () => (
  <>
    <MobileMenu />
    <FeedPopupMob />
  </>
);

const DesktopMenuChildren: React.FC = () => (
  <>
    <DesktopMenu />
    <FeedPopup />
  </>
);

export default withAITracking(reactPlugin, App);

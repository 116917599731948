import styled from 'styled-components';

import { CancelableChip } from '@component/chip/CancelableChip';
import { SetAlertButton } from '@component/panels/findLoads/searchResultPanel/SetAlertButton';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { ThemeProps } from '@style/WithTheme';

export const LoadsHeader = styled.div`
  width: 100%;
`;

export const HeaderIconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props: ThemeProps & { isOrange: boolean | undefined }) => props.theme.text.lightText.fontSize};
  color: ${(props: ThemeProps & { isOrange: boolean | undefined }) =>
    props.isOrange ? Color.ORANGE_MAIN : props.theme.text.lightText.color};
  font-weight: normal;
  line-height: 0;
  cursor: pointer;
  position: relative;
`;

export const MapButton = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${(props: ThemeProps) => props.theme.palette.secondary.main};
  margin-left: 9px;
  cursor: pointer;
`;

export const ResultQauntity = styled.div`
  padding: 8px 12px;
  font-size: ${(props: ThemeProps) => props.theme.text.lightText.fontSize};
  color: ${(props: ThemeProps) => props.theme.text.lightText.color};
  background-color: ${Color.GRAY_LIGHT};
`;

export const ResultTotalCount = styled.span`
  color: ${Color.LIGHT_BLACK};
  font-size: ${FontSize.Content}px;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${Color.GRAY_SMOKE};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  padding-top: 0;
  margin-top: 26px;
  button {
    border-width: 2px;
    font-weight: 700;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  height: 50px;
  padding: 13px 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: ${Color.GRAY_SMOKE};
  box-sizing: border-box;
`;

export const ResultAndFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 6px 8px 14px;
  background-color: ${Color.GRAY_GRADIENT_LIGHT};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const ChipsLine = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterChip = styled(CancelableChip)`
  && {
    margin-right: 4px;
    margin-top: 4px;
  }
`;

export const FooterButtonsContainer = styled.div`
  display: flex;
  right: 0;
  position: absolute;
  bottom: 0;
  justify-content: flex-end;
  padding: 16px;
  box-sizing: border-box;
`;

export const SetAlertFooterButton = styled(SetAlertButton)`
  width: 120px;
  height: 44px;
  && {
    margin-left: 8px;
    border-radius: 22px;
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GRAY_SMOKE};
    &:hover {
      background-color: ${Color.GRAY_LIGHT};
    }
  }
`;

export const NewLoadsHeaderButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

import React from 'react';

import { findIndex, reduce, round, slice } from 'lodash';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps, withRouter } from 'react-router';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { setSelectedTruck } from '@/reduxStore/epic/TruckEpic';
import { StoreState } from '@/reduxStore/Store';
import { Routes, TruckLocatorRoutes } from '@/router/Routes';
import {
  formatDateStringToMMMDD,
  formatMileage,
  formatPhoneNumber,
  formatSecondsToAge,
  loadSizeToString,
} from '@common/helper';
import { EquipmentCode, LoadSize, Truck, VerificationPointsTaskStatus } from '@common/model';
import { RatingBadge, RatingBadgeSmall } from '@component/badge/RatingBadge';
import { VerificationInfoBadge } from '@component/badge/VerificationBadge';
import { StyledCheckCircle } from '@component/panels/truckLocator/carrierDetails/CarrierDetailsPanelStyles';
import { TableSizeState } from '@component/table/loadTable/LoadTableHelper';
import { MediumCellDiv } from '@component/table/loadTable/LoadTableStyles';
import {
  CenteredCellDiv,
  DenseCell,
  ExpandMore,
  NonMatchesMargin,
  StyledCheckbox,
  StyledRow,
  ThemedCell,
  VerticalCellColumn,
  VerticalCellRow,
} from '@component/table/truckTable/TruckTableStyles';
import { Text, TextStyle } from '@component/text/Text';
import { t, T } from '@translate';
import { HIDDEN_PHONE_NUMBER, NO_INFORMATION_SYMBOL } from '@util/Constants';
import { formatDistance } from '@util/Formatter';
import { GA, TrackingCategory } from '@util/trackers/GoogleAnalyticsTracker';
import { displayLengthAndWeight } from '@util/TruckDataHelper';

const MAX_DATES_DISPLAY_XLARGE = 6;
const MAX_DATES_DISPLAY_LARGE = 2;
const MAX_DATES_DISPLAY_SMALL = 1;
const MAX_COMPANY_LENGTH_LARGE = 14;
const MAX_COMPANY_LENGTH_MEDIUM = 10;
const MAX_COMPANY_LENGTH_SMALL = 20;
const MAX_EQUIPMENT_LENGTH = 14;
const MAX_AVAILABLE_CAPACITY_LENGTH_LARGE = 18;
const MAX_AVAILABLE_CAPACITY_LENGTH_MEDIUM = 12;

interface Props {
  id: string;
  item: Truck;
  selected: boolean;
  onSelect: (truck: Truck) => void;
  disabled?: boolean;
  sizeState: TableSizeState;
  selectedLoadMileage?: number;
  shouldHideMultiSelect?: boolean;
}

interface ReduxProps {
  isCompanyActionLoading: boolean;
  shouldDisableActions: boolean;
}

interface State {
  isExpanded: boolean;
}

interface DispatchProps {
  setSelectedTruckAction: (truck: Truck | undefined) => void;
}

type TruckElementProps = Props &
  ReduxProps &
  DispatchProps &
  RouteComponentProps<{ truckID: string; truckLocatorType: string }>;

class TruckElementComponent extends React.Component<TruckElementProps, State> {
  state = {
    isExpanded: false,
  };

  onSelection = () => {
    this.props.onSelect(this.props.item);
  };

  onExpand = () => {
    if (!this.state.isExpanded) {
      GA.track(TrackingCategory.UserAction, 'Truck Locator - Expand truck list item');
    }
    this.setState({ isExpanded: !this.state.isExpanded });
    this.props.setSelectedTruckAction(undefined);
    this.props.history.replace({
      pathname: `${generatePath(Routes.FINDTRUCKS_LOCATETRUCK, {
        ...this.props.match.params,
        truck: 'viewtruck',
        truckID: this.props.item.id,
      })}/`,
      search: this.props.history.location.search,
    });
    this.props.setSelectedTruckAction(this.props.item);
  };

  render() {
    const props = this.props;
    return (
      <React.Fragment>
        <StyledRow
          key={props.item.id}
          vertical={props.sizeState === TableSizeState.small ? 1 : 0}
          isSelected={props.selected ? 1 : 0}
          hover={!props.disabled}
          hasBorder={1}
        >
          {props.sizeState !== TableSizeState.small
            ? this.renderCellsLarge(
                `${props.id}_large`,
                props.item,
                getCalculatedDesireRate(props.item.amount, props.selectedLoadMileage),
                props.sizeState === TableSizeState.medium
              )
            : this.renderCellsSmall(`${props.id}_small`, props.item)}
          {props.sizeState === TableSizeState.medium ? null : (
            <DenseCell
              padding={'none'}
              onClick={!props.disabled ? this.onExpand : undefined}
              clickable={props.disabled ? 0 : 1}
            >
              {props.disabled ? undefined : <ExpandMore />}
            </DenseCell>
          )}
        </StyledRow>
      </React.Fragment>
    );
  }

  private renderCellsLarge = (id: string, truck: Truck, desiredRate: number | undefined, isTabletSize: boolean) => {
    const formattedPhone = formatPhoneNumber(truck.anonymous ? HIDDEN_PHONE_NUMBER : (truck.contactPhone ?? ''));
    const isPhoneVerified = truck.contactPhoneVerificationStatus === VerificationPointsTaskStatus.COMPLETED;
    return (
      <>
        <ThemedCell padding={'none'}>{this.renderCheckbox(id, false)}</ThemedCell>
        {this.renderCell(`${id}_age`, formatSecondsToAge(truck.age), false, undefined, !this.props.disabled)}
        {this.renderCell(
          `${id}_company`,
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {truck.anonymous
              ? t(T.common_postTruck_AnonymousPost_header)
              : trimString(truck.companyName, getCompanyNameMaxLength(this.props))}
            <VerificationInfoBadge
              testID="dot_verification_badge"
              isVerified={truck.isCarrierIdentityVerified}
              style={{ margin: '-4px 0 0 4px' }}
            />
          </div>,
          false,
          truck.anonymous
            ? t(T.common_postTruck_AnonymousPost_header)
            : getTooltip(truck.companyName, getCompanyNameMaxLength(this.props)),
          !this.props.disabled
        )}
        {this.renderRating(`${id}_rating`, truck)}
        {this.truckAlertSent(
          `${id}_alert_sent`,
          truck,
          this.props.match.params.truckLocatorType === TruckLocatorRoutes.InternalTruckLocator
        )}
        {this.renderCell(
          `${id}_deadhead`,
          formatDistance(truck.deadhead, true),
          false,
          undefined,
          !this.props.disabled
        )}
        {this.renderCell(
          `${id}_equipment`,
          isTabletSize
            ? EquipmentCode[truck.equipmentTypes as keyof typeof EquipmentCode]
            : trimString(truck.equipmentTypes, MAX_EQUIPMENT_LENGTH),
          false,
          getTooltip(truck.equipmentTypes, MAX_EQUIPMENT_LENGTH),
          !this.props.disabled
        )}
        {this.renderCell(
          `${id}_size`,
          loadSizeToString(truck.loadSize as LoadSize),
          false,
          undefined,
          !this.props.disabled
        )}
        {this.renderCell(
          `${id}_available_capacity`,
          trimString(
            displayLengthAndWeight(truck.availableLength, truck.availableWeight),
            isTabletSize ? MAX_AVAILABLE_CAPACITY_LENGTH_MEDIUM : MAX_AVAILABLE_CAPACITY_LENGTH_LARGE
          ),
          false,
          undefined,
          !this.props.disabled
        )}
        {isTabletSize
          ? null
          : this.renderCell(
              `${id}_amount`,
              desiredRate ? `${t(T.common_dollar_sign)}${formatMileage(desiredRate)}` : '',
              false,
              undefined,
              !this.props.disabled
            )}
        {this.renderCell(
          `${id}_phone`,
          <>
            {isTabletSize && isPhoneVerified ? trimString(formattedPhone, 8) : formattedPhone}
            {truck.contactPhoneVerificationStatus === VerificationPointsTaskStatus.COMPLETED ? (
              <VerificationInfoBadge
                testID="phone_verification_badge"
                isVerified={true}
                style={{ marginTop: 0, marginLeft: 4 }}
              />
            ) : null}
          </>,
          false,
          undefined,
          !this.props.disabled
        )}
        {this.renderCell(
          `${id}_available`,
          displayDates(isTabletSize ? MAX_DATES_DISPLAY_SMALL : MAX_DATES_DISPLAY_LARGE, truck.availableDates),
          false,
          undefined,
          !this.props.disabled
        )}
      </>
    );
  };

  private renderCellsSmall = (id: string, truck: Truck) => {
    return (
      <>
        {this.renderCheckbox(id, true)}
        <ThemedCell
          padding={'none'}
          colSpan={1}
          onClick={!this.props.disabled ? this.onExpand : undefined}
          clickable={this.props.disabled ? 0 : 1}
        >
          <MediumCellDiv centered={false} style={{ marginTop: 15 }}>
            <VerticalCellRow
              title={
                truck.anonymous
                  ? t(T.common_postTruck_AnonymousPost_header)
                  : getTooltip(truck.companyName, getCompanyNameMaxLength(this.props))
              }
              clickable={this.props.disabled ? 0 : 1}
              style={{ justifyContent: 'flex-start' }}
            >
              <Text id={`${id}_company`} textStyle={TextStyle.MidResult} noWrap={true}>
                {truck.anonymous
                  ? t(T.common_postTruck_AnonymousPost_header)
                  : trimString(truck.companyName, getCompanyNameMaxLength(this.props))}
              </Text>
              <VerificationInfoBadge
                testID="dot_verification_badge"
                isVerified={truck.isCarrierIdentityVerified}
                style={{ margin: '-4px 0 0 4px' }}
              />
              {truck.rating?.starRating ? <RatingBadgeSmall testID={id} rating={truck.rating.starRating} /> : null}
            </VerticalCellRow>
            <VerticalCellRow>
              <VerticalCellColumn>
                <Text id={`${id}_age_header`} textStyle={TextStyle.Note}>
                  {t(T.common_load_Age)}
                </Text>
                <Text id={`${id}_age`} textStyle={TextStyle.MidResult}>
                  {formatSecondsToAge(truck.age)}
                </Text>
              </VerticalCellColumn>
              <VerticalCellColumn>
                <Text id={`${id}_alert_sent_header`} textStyle={TextStyle.Note}>
                  {t(T.common_truck_Alert_Sent)}
                </Text>
                <Text id={`${id}_alert_sent`} textStyle={TextStyle.MidResult}>
                  {this.alertSentIcon(`${id}_alert_sent`, truck)}
                </Text>
              </VerticalCellColumn>
            </VerticalCellRow>
            <VerticalCellRow>
              <VerticalCellColumn>
                <Text id={`${id}_deadhead_header`} textStyle={TextStyle.Note}>
                  {t(T.common_truck_Deadhead_Short)}
                </Text>
                <Text id={`${id}_deadhead`} textStyle={TextStyle.MidResult} noWrap={true}>
                  {formatDistance(truck.deadhead)}
                </Text>
              </VerticalCellColumn>
              <VerticalCellColumn>
                <Text id={`${id}_equipment_header`} textStyle={TextStyle.Note}>
                  {t(T.common_truck_Equipment)}
                </Text>
                <Text id={`${id}_equipment`} textStyle={TextStyle.MidResult}>
                  {truck.equipmentTypes ? truck.equipmentTypes : NO_INFORMATION_SYMBOL}
                </Text>
              </VerticalCellColumn>
            </VerticalCellRow>
            <VerticalCellRow>
              <VerticalCellColumn>
                <Text id={`${id}_phone_header`} textStyle={TextStyle.Note} noWrap={true}>
                  {t(T.common_phone)}
                </Text>
                <Text id={`${id}_phone`} textStyle={TextStyle.MidResult} noWrap={true}>
                  {formatPhoneNumber(truck.anonymous ? HIDDEN_PHONE_NUMBER : (truck.contactPhone ?? ''))}
                  {truck.contactPhoneVerificationStatus === VerificationPointsTaskStatus.COMPLETED ? (
                    <VerificationInfoBadge
                      testID="phone_verification_badge"
                      isVerified={truck.contactPhoneVerificationStatus === VerificationPointsTaskStatus.COMPLETED}
                      style={{ margin: '-5px 0 0 4px' }}
                    />
                  ) : null}
                </Text>
              </VerticalCellColumn>
            </VerticalCellRow>
            <VerticalCellRow>
              <VerticalCellColumn style={{ flexBasis: '100%' }}>
                <Text id={`${id}_available_header`} textStyle={TextStyle.Note} noWrap={true}>
                  {t(T.common_truck_Next_Available)}
                </Text>
                <Text id={`${id}_available`} textStyle={TextStyle.MidResult} noWrap={false}>
                  {displayDates(MAX_DATES_DISPLAY_XLARGE, truck.availableDates)}
                </Text>
              </VerticalCellColumn>
            </VerticalCellRow>
          </MediumCellDiv>
        </ThemedCell>
      </>
    );
  };

  private renderCheckbox = (id: string, marginOnNothing: boolean = false) => {
    if (this.props.disabled || this.props.shouldHideMultiSelect) {
      if (marginOnNothing) {
        return <NonMatchesMargin />;
      }
      return undefined;
    }
    return (
      <CenteredCellDiv>
        <StyledCheckbox id={`${id}_checkbox`} onClick={this.onSelection} checked={this.props.selected} />
      </CenteredCellDiv>
    );
  };

  private renderCell = (
    id: string,
    content?: React.ReactNode,
    styleChange: boolean = false,
    tooltip?: string,
    expandOnClick: boolean = false
  ) => {
    return (
      <ThemedCell
        padding={'none'}
        title={tooltip}
        onClick={expandOnClick ? this.onExpand : undefined}
        clickable={expandOnClick ? 1 : 0}
      >
        <Text
          id={id}
          textStyle={
            styleChange ? (this.state.isExpanded ? TextStyle.BigLink : TextStyle.MidResult) : TextStyle.MidResult
          }
          noWrap={true}
        >
          {content ? content : NO_INFORMATION_SYMBOL}
        </Text>
      </ThemedCell>
    );
  };

  private renderRating = (id: string, truck: Truck) => {
    const rating = truck.rating?.starRating;
    return (
      <ThemedCell id={id} padding="none">
        {rating ? <RatingBadge rating={rating} testID={id} /> : NO_INFORMATION_SYMBOL}
      </ThemedCell>
    );
  };

  private alertSentIcon = (id: string, truck: Truck) =>
    truck.notificationInfo && truck.notificationInfo.messagesReceived > 0 ? (
      <StyledCheckCircle id={`${id}_sent`} disabled={this.props.disabled ? 1 : 0} style={{ marginLeft: 0 }} />
    ) : (
      <div style={{ marginLeft: 4 }}>{NO_INFORMATION_SYMBOL}</div>
    );

  private truckAlertSent = (id: string, truck: Truck, isInternal: boolean) => {
    const messagesCount = truck?.notificationInfo?.recentMessagesCountFromCompany;
    return (
      <ThemedCell
        id={id}
        title={messagesCount ? t(T.truckLocator_truckTable_sentMessages, { messagesCount: messagesCount }) : undefined}
        padding={'none'}
        onClick={!this.props.disabled ? this.onExpand : undefined}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {this.alertSentIcon(id, truck)}
          {isInternal && messagesCount ? (
            <Text id={`${id}_count`} style={{ paddingLeft: 5, display: 'block' }} textStyle={TextStyle.MidResult}>
              ({messagesCount})
            </Text>
          ) : null}
        </div>
      </ThemedCell>
    );
  };
}

const displayDates = (limit: number, dates?: string[]) => {
  if (!dates || dates.length <= 0) {
    return t(T.common_truck_Now);
  }

  let tooLong = false;
  let datesToDisplay = dates;

  if (limit && dates.length > limit) {
    datesToDisplay = slice(dates, 0, limit);
    tooLong = true;
  }

  let parsedDates: string = reduce(
    datesToDisplay,
    (prev: string, nextDate: string) => `${prev}, ${formatDateStringToMMMDD(nextDate)}`,
    ''
  );

  if (tooLong) {
    parsedDates += '...';
  }

  return parsedDates.substring(2, parsedDates.length);
};

const getCompanyNameMaxLength = (props: TruckElementProps) => {
  if (props.sizeState === TableSizeState.small) {
    return MAX_COMPANY_LENGTH_SMALL;
  }
  if (props.sizeState === TableSizeState.medium) {
    return MAX_COMPANY_LENGTH_MEDIUM;
  }
  return MAX_COMPANY_LENGTH_LARGE;
};

export const trimString = (name: string, limit: number) => {
  if (name && name.length > limit) {
    return `${name.substring(0, limit)}...`;
  } else {
    return name;
  }
};

export const getTooltip = (name: string, limit: number) => {
  if (name && name.length > limit) {
    return name;
  } else {
    return undefined;
  }
};

const mapStateToProps = (state: StoreState, ownProps: Props): ReduxProps => ({
  isCompanyActionLoading: isCompanyActionLoading(
    state.companyState.isLoading || state.truckState.isLoading,
    state.truckLocatorState.trucks,
    ownProps.item.id
  ),
  shouldDisableActions: state.companyState.isLoading,
});

const isCompanyActionLoading = memoizeOne(
  (isLoading: boolean, trucks: Truck[], currentTruckId: string) =>
    isLoading && findIndex(trucks, (truck) => truck.id === currentTruckId) !== -1
);

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      setSelectedTruckAction: (truck: Truck) => setSelectedTruck(truck),
    },
    dispatch
  );

export const TruckElement = connect(mapStateToProps, mapDispatchToProps)(withRouter(TruckElementComponent));

export const getCalculatedDesireRate = (perMileRate?: number, mileage?: number): number | undefined =>
  perMileRate && mileage ? round(perMileRate * round(mileage)) : undefined;

import { EMPTY as empty$, Observable, of as of$ } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { setConnectionError, setLoginFailed } from '@/reduxStore/epic/ConnectionErrorEpic';
import Logout from '@/router/Logout';
import { Routes } from '@/router/Routes';
import {
  ApiError,
  ApiResponse123,
  ApiResponseSuccess,
  is401,
  is4xxError,
  isRefreshTokenPendingAction,
} from '@common/api';
import { ReduxRegistry } from '@common/redux/ReduxRegistry';
import { AppConfig } from '@util/AppConfig';
import * as CookieService from '@webApi/CookiesHelper';
import TokenRefresher from '@webApi/TokenRefresher';

type Response$<T> = Observable<ApiResponse123<T>>;

let wasRedirectedToEmailVerification = false;

/** This depends if the current "page" is a public page that
 *  absolutely wants authentication or else jump to the login page.
 *
 * true: redirect to login if access token+refreshing token failed.
 * false: stay on the page even if all attempt at refreshing the token failed "normally"
 *
 * setEnforcedAuthentication is called by AuthenticatedRoute which is the place where we "know"
 * what the page requires.  Luckily, this is mounted as part of the main javascript chunk so it will
 * execute before any API networking call returns, in particular, before /refreshToken returns.
 * While a lot of pages are under an AuthenticatedRoute, some are only under a simple Route.
 * In this case the default value (false) will prevail.
 */
let enforcedAuthentication = false;

export const setEnforcedAuthentication = (enforceAuth: boolean) => {
  enforcedAuthentication = enforceAuth;
};

export const handleResponse$ = <T>(
  response$: Response$<T>,
  allowUnauthenticated: boolean,
  forceRefreshToken: boolean = false
): Response$<T> =>
  handleWithRefreshTokenIfNeeded$(response$, allowUnauthenticated, forceRefreshToken).pipe(mergeMap(redirectIfNeeded$));

export const refreshTokenIfNeeded$ = (): Observable<any> =>
  handleWithRefreshTokenIfNeeded$(of$(new ApiResponseSuccess(200, null)), true);

const handleWithRefreshTokenIfNeeded$ = <T>(
  response$: Response$<T>,
  allowUnauthenticated: boolean,
  forceRefreshToken: boolean = false
): Response$<T> => {
  if (!CookieService.hasLoginCookies()) {
    return allowUnauthenticated ? response$ : of$(new ApiError(401, -1, 'User is not logged in'));
  }
  if (CookieService.isAccessTokenExpired() || TokenRefresher.isInProgress() || forceRefreshToken) {
    return handleWithRefreshToken$(response$);
  }
  return response$.pipe(
    mergeMap((response) =>
      is401(response) || isRefreshTokenPendingAction(response) ? handleWithRefreshToken$(response$) : of$(response)
    )
  );
};

const handleWithRefreshToken$ = <T>(response$: Response$<T>): Response$<T> =>
  TokenRefresher.getRefreshedToken$().pipe(
    mergeMap((tokenResponse) => {
      if (tokenResponse.ok || !CookieService.isAccessTokenExpired()) {
        return response$;
      }
      if (tokenResponse.status && is4xxError(tokenResponse.status)) {
        return of$(new ApiError(401, -1, 'Refreshing Token failed'));
      }
      return connectionError$();
    })
  );

const isAccountDeactivate = <T>(response: ApiResponse123<T>) =>
  response instanceof ApiError && response.code === 4030004;

const redirectIfNeeded$ = <T>(response: ApiResponse123<T>) => {
  if (is401(response)) {
    if (enforcedAuthentication) return redirectToLogin$();
    else {
      ReduxRegistry.dispatch(setLoginFailed(true));
      return of$(response);
    }
  }
  if (isAccountDeactivate(response)) {
    return redirectToAccountActivationIfNeeded$(response);
  }
  return of$(response);
};

const redirectToLogin$ = () => {
  if (!Logout.isProcessingLogout) {
    //@FIXME: patch implemented for MEM-1679. logout being called more than once while in the logout process, improve this fix.
    redirectToLogin();
  }
  // Returning empty observable since the page will be redirected.
  return empty$;
};

const redirectToAccountActivationIfNeeded$ = <T>(response: ApiResponse123<T>) => {
  const emailVerificationPageHref = `${window.location.origin}${Routes.VERIFY_EMAIL_BLOCKER_PAGE}`;
  const isOnEmailVerificationPage = window.location.href === emailVerificationPageHref;
  if (!isOnEmailVerificationPage && !wasRedirectedToEmailVerification) {
    wasRedirectedToEmailVerification = true;
    window.location.href = emailVerificationPageHref;
    // Returning empty observable since the page will be redirected.
    return empty$;
  }
  return of$(response);
};

const connectionError$ = () => {
  ReduxRegistry.dispatch(setConnectionError(true));
  // Returning empty observable since the page will be reloaded.
  return empty$;
};

export const redirectToLogin = () => {
  window.location.href = AppConfig.getLoginLink();
};

import React, { useEffect } from 'react';

import { generatePath } from 'react-router';
import styled from 'styled-components';

import { Card, CardContent, Divider } from '@material-ui/core';

import { default as RightArrowIconSVG } from '@/icons/icon-arrow-right.svg?react';
import { Routes } from '@/router/Routes';
import { displayCurrency } from '@common/formatter';
import { formatDay, getDriveTime, getProfitPercentage, getProfitPerDay } from '@common/util/CostPerDayHelper';
import {
  CostPerDayNotAvailableBadge,
  CostPerDayTitle,
  LinkButton,
} from '@component/panels/basePanel/loadDetails/costPerDay/CostPerDayUIHelper';
import { Text, TextStyle } from '@component/text';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { getCostPerDayTitleData, useCostPerDay, useGetWorkDays } from '@util/helper/CostPerDayHelper';

const CPD_PATH = `${generatePath(Routes.MORE_SETTINGS, { filter: Filter.CostPerDay })}/`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const PriceContainer = styled(Flex)`
  flex-direction: column;
  gap: 10px;
`;

const PriceText = styled(Text)`
  font-size: 22px;
  font-weight: bold;
`;

const CardFooter = styled(Flex)`
  padding: ${Spacing.ElementPaddingHorizontal}px;
`;

const ContentFooter = styled(Flex)`
  justify-content: center;
  padding: 8px 12px 10px 12px;
`;

const CardHeader = styled(Flex)`
  padding: ${Spacing.ElementPaddingVertical}px;
  padding-bottom: 8px;
  justify-content: space-between;
`;

interface CostPerDayCardWrapper {
  driveTime: number;
  totalProfit: number;
  miles: number;
  fuelCosts: number;
  additionalCosts: number;
  rate: number;
  deadheadMiles: number | undefined;
  hasRateData: boolean;
  onGetProfitPerDay: (profitPerDay: number) => void;
}

export const CostPerDayCard = (props: CostPerDayCardWrapper) => {
  const costPerDay = useCostPerDay();
  const workDays = useGetWorkDays(
    props.driveTime + (props.deadheadMiles ? getDriveTime(props.driveTime, props.miles, props.deadheadMiles) : 0)
  );
  const profitPerDay = getProfitPerDay(props.totalProfit, workDays);

  useEffect(() => {
    // profitPerDay is calculated using the ProfitCalculator state, which is component-specific, not in Redux.
    // Prop drilling used here, to sync value between parent (LoadDetailsPanel) and another child (LoadDetailsInfoBar)
    props.onGetProfitPerDay(profitPerDay);
  }, [profitPerDay]);

  if (!props.hasRateData) {
    return <CostPerDayNotAvailableBadge />;
  }

  const isHalfDayHaul = workDays <= 0.5;
  const costPerDayValue = isHalfDayHaul ? costPerDay.value / 2 : costPerDay.value;
  const profitPerDayValue = isHalfDayHaul ? profitPerDay / 2 : profitPerDay;
  // Trip rate = Cost Per Day x Work days + (Fuel price + toll cost + dispatch cost)
  const tripRate = costPerDayValue * workDays + (props.fuelCosts + props.additionalCosts);
  const percentage = getProfitPercentage(costPerDayValue, profitPerDayValue);

  const { color, title, iconStyle, percentageText } = getCostPerDayTitleData(percentage);
  const costPerDayText = displayCurrency(costPerDayValue, false);
  const profitPerDayText = displayCurrency(profitPerDayValue, false);
  const totalProfitText = displayCurrency(props.totalProfit, false);

  return (
    <Card style={{ margin: Spacing.ScreenSide }}>
      <CardContent style={{ padding: 0 }}>
        <CardHeader>
          <CostPerDayTitle
            percentageText={percentageText}
            isSetupComplete={costPerDay.isSetupComplete}
            iconStyle={iconStyle}
            title={title}
            color={color}
          />
          <LinkButton id="edit" to={CPD_PATH}>
            {t(T.common_costPerDay_card_edit)}
          </LinkButton>
        </CardHeader>
        <div style={{ padding: Spacing.ElementPaddingVertical, paddingTop: 0 }}>
          <Text id="cost_per_day_content" textStyle={TextStyle.SubtitleNormalWeight}>
            {t(T.common_costPerDay_card_description, {
              maxDays: formatDay(workDays),
              totalProfit: totalProfitText,
            })}
          </Text>
          <div style={{ marginTop: '10px', marginBottom: '5px' }}>
            <Text
              textStyle={TextStyle.TextContentDetail}
              id="per_day_profit"
              style={{ textAlign: 'center', display: 'block' }}
            >
              {isHalfDayHaul
                ? t(T.common_costPerDay_card_profitValuePerHalfDay, { profit: profitPerDayText })
                : `${totalProfitText} / ${formatDay(workDays)} = ${profitPerDayText} ${t(
                    T.common_costPerDay_card_profitPerDay
                  ).toLowerCase()}`}
            </Text>
          </div>
        </div>
        <Divider />
        <ContentFooter>
          <PriceContainer>
            <Text id="profit_per_day_text">
              {isHalfDayHaul ? t(T.common_costPerDay_card_profitPerHalfDay) : t(T.common_costPerDay_card_profitPerDay)}
            </Text>
            <PriceText id="profit_per_day_amount">{profitPerDayText}</PriceText>
          </PriceContainer>
          <RightArrowIconSVG style={{ alignSelf: 'end', marginBottom: '2px', ...iconStyle }} />
          <PriceContainer>
            <Text id="cost_per_day_text">
              {isHalfDayHaul ? t(T.common_costPerDay_card_costPerHalfDay) : t(T.common_costPerDay_card_costPerDay)}
            </Text>
            {costPerDay.isSetupComplete ? (
              <PriceText style={{ color: color }} id="cost_per_day_amount">
                {costPerDayText}
              </PriceText>
            ) : (
              <LinkButton id="setup" style={{ fontSize: 22, marginTop: 0, lineHeight: '19px' }} to={CPD_PATH}>
                {t(T.common_costPerDay_card_setup)}
              </LinkButton>
            )}
          </PriceContainer>
        </ContentFooter>
        {costPerDay.isSetupComplete ? (
          <>
            <Divider />
            <Footer percentage={percentage} tripRate={tripRate} miles={props.miles} rate={props.rate} />
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};

interface FooterProps {
  percentage: number;
  tripRate: number;
  rate: number;
  miles: number;
}

const Footer = (props: FooterProps) => {
  let footerConfig = {
    color: Color.RED_APPLE,
    title: t(T.common_costPerDay_card_notProfitableload),
  };
  if (props.percentage > -10) {
    footerConfig = {
      color: Color.BLUE_TEXT,
      title: t(T.common_costPerDay_card_partialProfitabLeLoad),
    };
  }
  if (props.percentage > 0) {
    footerConfig = {
      color: Color.GREEN_MAIN,
      title: t(T.common_costPerDay_card_profitableLoad),
    };
  }

  return (
    <CardFooter>
      <Text id="cost_per_day_footer">
        <Text
          textStyle={TextStyle.Subtitle2Bold}
          id="cost_per_day_footer_title"
          style={{ color: footerConfig.color, lineHeight: '18pt' }}
        >
          {footerConfig.title}
        </Text>
        <Text textStyle={TextStyle.SubtitleNormalWeight} id="cost_per_day_footer_info">
          {t(T.common_costPerDay_card_footerLoadInfo, {
            tripRate: displayCurrency(props.tripRate, false),
            tripRatePerMile: displayCurrency(props.tripRate / props.miles),
            postedRate: displayCurrency(props.rate, false),
            postedRatePerMile: displayCurrency(props.rate / props.miles),
          })}
        </Text>
      </Text>
    </CardFooter>
  );
};

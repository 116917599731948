export interface LoadSearchSort {
  direction: LoadSortDirection;
  field: LoadSortCategory;
}
export enum LoadSortDirection {
  Asc = 'Ascending',
  Desc = 'Descending',
}

export enum LoadSortCategory {
  ORIGIN = 'Origin',
  ORIGIN_CITY = 'OrigCity',
  ORIGIN_STATE = 'OrigState',
  ORIGIN_STATE_CITY = 'OrigStateCity',
  ORIGIN_ZIPCODE = 'OrigZipCode',
  ORIGIN_RADIUS = 'OrigRadius',
  DESTINATION = 'Destination',
  DESTINATION_CITY = 'DestCity',
  DESTINATION_STATE = 'DestState',
  DESTINATION_STATE_CITY = 'DestStateCity',
  DESTINATION_ZIPCODE = 'DestZipCode',
  DESTINATION_RADIUS = 'DestRadius',
  PICKUP_DATE = 'PickupDate',
  MESSAGES_SENT = 'messagesSentCounter',
  EQUIPMENT = 'Equipment',
  AGE = 'PostedDate',
  BROKER_LAST_UPDATE_AGE = 'LastUpdateAge',
  ID = 'LoadId',
  SIZE = 'Size',
  LENGTH = 'Length',
  WEIGHT = 'Weight',
  COMPANY = 'Company',
  DEADHEAD = 'DeadHead',
  MILEAGE = 'Mileage',
  AMOUNT = 'Amount',
  CREDIT_RATING = 'CreditRating',
  DAYS_TO_PAY = 'DaysToPay',
  TRANS_CREDIT_RATING = 'TransCreditRating',
  TRANS_CREDIT_DAYS_TO_PAY = 'TransCreditDaysToPay',
  PRICE_PER_MILE = 'PricePerMile',
  AVERAGE_RATE = 'AverageRate',
  POST_REFERENCE = 'PostReference',
}

export enum LoadSortField {
  DeadheadMiles,
  PickupDate,
  PostedAge,
  TripDistance,
  TripRate,
  RatePerMile,
  AverageRate,
  Weight,
  Length,
  CompanyName,
  CreditScore,
  DaysToPay,
  OriginStateCity,
  DestinationStateCity,
  Size,
  Equipment,
  LastUpdateAge,
  PostReference,
}

export const loadSortFor = (field: LoadSortField): LoadSearchSort | undefined => {
  switch (field) {
    case LoadSortField.DeadheadMiles:
      return undefined;
    case LoadSortField.PickupDate:
      return { direction: LoadSortDirection.Asc, field: LoadSortCategory.PICKUP_DATE };
    case LoadSortField.PostedAge:
      return { direction: LoadSortDirection.Asc, field: LoadSortCategory.AGE };
    case LoadSortField.TripDistance:
      return { direction: LoadSortDirection.Asc, field: LoadSortCategory.MILEAGE };
    case LoadSortField.TripRate:
      return { direction: LoadSortDirection.Desc, field: LoadSortCategory.AMOUNT };
    case LoadSortField.RatePerMile:
      return { direction: LoadSortDirection.Desc, field: LoadSortCategory.PRICE_PER_MILE };
    case LoadSortField.AverageRate:
      return { direction: LoadSortDirection.Desc, field: LoadSortCategory.AVERAGE_RATE };
    case LoadSortField.Weight:
      return { direction: LoadSortDirection.Desc, field: LoadSortCategory.WEIGHT };
    case LoadSortField.Length:
      return { direction: LoadSortDirection.Desc, field: LoadSortCategory.LENGTH };
    case LoadSortField.CompanyName:
      return { direction: LoadSortDirection.Asc, field: LoadSortCategory.COMPANY };
    case LoadSortField.CreditScore:
      return { direction: LoadSortDirection.Desc, field: LoadSortCategory.CREDIT_RATING };
    case LoadSortField.DaysToPay:
      return { direction: LoadSortDirection.Desc, field: LoadSortCategory.DAYS_TO_PAY };
    case LoadSortField.OriginStateCity:
      return { direction: LoadSortDirection.Asc, field: LoadSortCategory.ORIGIN_STATE_CITY };
    case LoadSortField.DestinationStateCity:
      return { direction: LoadSortDirection.Asc, field: LoadSortCategory.DESTINATION_STATE_CITY };
    case LoadSortField.Size:
      return { direction: LoadSortDirection.Asc, field: LoadSortCategory.SIZE };
    case LoadSortField.Equipment:
      return { direction: LoadSortDirection.Asc, field: LoadSortCategory.EQUIPMENT };
    default:
      return undefined;
  }
};

export const postedLoadSortFor = (field: LoadSortField): LoadSearchSort | undefined => {
  if (field === LoadSortField.LastUpdateAge || field === LoadSortField.PostedAge) {
    return { direction: LoadSortDirection.Asc, field: LoadSortCategory.BROKER_LAST_UPDATE_AGE };
  }
  if (field === LoadSortField.PostReference) {
    return { direction: LoadSortDirection.Desc, field: LoadSortCategory.POST_REFERENCE };
  }
  return loadSortFor(field);
};

export const sortFieldFor = (sort?: LoadSearchSort): LoadSortField => {
  if (!sort) {
    return LoadSortField.DeadheadMiles;
  }
  switch (sort.field) {
    case LoadSortCategory.PICKUP_DATE:
      return LoadSortField.PickupDate;
    case LoadSortCategory.AGE:
      return LoadSortField.PostedAge;
    case LoadSortCategory.MILEAGE:
      return LoadSortField.TripDistance;
    case LoadSortCategory.AMOUNT:
      return LoadSortField.TripRate;
    case LoadSortCategory.PRICE_PER_MILE:
      return LoadSortField.RatePerMile;
    case LoadSortCategory.AVERAGE_RATE:
      return LoadSortField.AverageRate;
    case LoadSortCategory.LENGTH:
      return LoadSortField.Length;
    case LoadSortCategory.WEIGHT:
      return LoadSortField.Weight;
    case LoadSortCategory.COMPANY:
      return LoadSortField.CompanyName;
    case LoadSortCategory.CREDIT_RATING:
      return LoadSortField.CreditScore;
    case LoadSortCategory.DAYS_TO_PAY:
      return LoadSortField.DaysToPay;
    case LoadSortCategory.ORIGIN_STATE_CITY:
      return LoadSortField.OriginStateCity;
    case LoadSortCategory.DESTINATION_STATE_CITY:
      return LoadSortField.DestinationStateCity;
    case LoadSortCategory.EQUIPMENT:
      return LoadSortField.Equipment;
    case LoadSortCategory.SIZE:
      return LoadSortField.Size;
    default:
      return LoadSortField.DeadheadMiles;
  }
};

export const postedLoadsSortFieldFor = (sort?: LoadSearchSort): LoadSortField => {
  if (!sort || sort.field === LoadSortCategory.BROKER_LAST_UPDATE_AGE || sort.field === LoadSortCategory.AGE) {
    return LoadSortField.LastUpdateAge;
  } else if (sort.field === LoadSortCategory.POST_REFERENCE) {
    return LoadSortField.PostReference;
  } else {
    return sortFieldFor(sort);
  }
};

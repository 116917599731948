import { push } from 'connected-react-router';
import { cloneDeep, compact, find, first, get, isEmpty, isNil, map } from 'lodash';
import { generatePath } from 'react-router';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { concat as concat$, from as from$, iif as if$, merge as merge$, Observable, of as of$, Subscriber } from 'rxjs';
import { filter as filter$, map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { DispatcherPostResponse } from '@/model';
import { blockCarriersBulkAction, favoriteCarriersBulkAction } from '@/reduxStore/epic/CarrierEpic';
import { deleteDispatcherAction, editDispatcherAction } from '@/reduxStore/epic/PostingContactsEpic';
import {
  fetchBrokerLoadsSummary,
  fetchPostedLoads,
  POSTED_LOAD_DELETED,
  PostedLoadDeletedAction,
  REFRESH_LOAD_AGE_RESPONSE,
  RefreshedLoadAgeAction,
  refreshOfflineLoadsList,
  SET_POSTED_LOAD_STATUS_RESPONSE,
  setPostedLoadProgressAction,
  UpdatedLoadStatusAction,
  updateSingleLoad,
} from '@/reduxStore/epic/PostLoadEpic';
import {
  CLONE_LOAD_FULFILLED,
  EDIT_LOAD_FULFILLED,
  LoadActionResponse,
  POST_LOAD_FULFILLED,
  PostLoadFormFieldsState,
  setLastUsedPostingContact,
  setSelectedPostingContact,
  setValidationFields,
} from '@/reduxStore/epic/PostLoadFormEpic';
import { updateTrucks } from '@/reduxStore/epic/TruckEpic';
import { history } from '@/reduxStore/History';
import { mapApiErrorFieldsToFormValidationFields } from '@/reduxStore/reducer/FormValidationReducer';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { triggerTruckAction, TruckAction } from '@/reduxStore/reducer/TruckReducer';
import { StoreState } from '@/reduxStore/Store';
import { Routes } from '@/router/Routes';
import { Api } from '@common/api';
import { GeocodingClient } from '@common/client/GeocodingClient';
import { UserPlanManagementClient } from '@common/client/UserPlanManagementClient';
import { serverRFCDateToYYYYMMDD, valueForOriginLocation } from '@common/helper';
import { LoadAction, loadGeolocationFrom, LoadUpdate, UpdateCategory } from '@common/helper/LoadHelper';
import { MYLOADS_FILTERS_LIST } from '@common/helper/MyLoadsHelper';
import {
  EquipmentType,
  Geolocation,
  INITIAL_SEARCH_LIMIT,
  LoadAvailabilitySortCategory,
  LoadLocation,
  LoadProgress,
  LoadSearchGeoResponse,
  LocationType,
  PayRateUnit,
  PostsFilter,
  SearchMetadataType,
  SetPostedProgressRequest,
} from '@common/model';
import { LOAD_FEEDBACK_CATEGORY } from '@common/model/LoadFeedbackRequest';
import { EmptyResponse, ResponseAction } from '@common/redux/Base';
import { addVendorBid } from '@common/redux/epic/bids/VendorBidsEpic';
import {
  BlockedCompanyResponseAction,
  BROKER_FAVORITED,
  BROKER_HIDDEN,
  BROKER_ONBOARDED,
  CompanyResponseAction,
} from '@common/redux/epic/BrokerEpic';
import { CARRIER_FAVORITED, CARRIER_HIDDEN, CARRIER_ONBOARDED, getCompanyInfo } from '@common/redux/epic/CompanyEpic';
import {
  deleteFolderResponse,
  DOCUMENT_DELETED,
  DOCUMENT_FOLDER_UPDATED,
  DOCUMENT_TYPE_UPDATED,
  DocumentDeletedResponseAction,
  fetchCustomFolders,
  fetchDefaultFolders,
  fetchDocumentsCount,
} from '@common/redux/epic/DocumentsEpic';
import { clearInboxMessagesAction, markAsReadAction } from '@common/redux/epic/InboxEpic';
import {
  fetchLoadAvailabilities,
  refreshLoadCount as refreshLoadAvailabilityCount,
  searchLoads as searchLoadsAvailability,
  updateLastSearch as updateLastAvailabilitySearch,
  updateListSearch as updateListAvailabilitySearch,
  updateLoadAvailabilityLoads,
} from '@common/redux/epic/LoadAvailabilityEpic';
import { clearCurrentLoad, fetchLoadDetails, updateLoadDetails } from '@common/redux/epic/LoadDetailsEpic';
import { fetchRouteMap } from '@common/redux/epic/LoadDetailsMapEpic';
import { RECEIVE_LOAD_FEEDBACK_RESPONSE, ReceiveLoadFeedbackResponse } from '@common/redux/epic/LoadFeedbackEpic';
import {
  ADD_NOTE_FULFILLED,
  ADD_PRIVATE_LOAD_NOTE_FULFILLED,
  AddNoteResponseAction,
  AddPrivateLoadNoteResponseAction,
  BidOnVendorLoadResponseAction,
  BOOK_NOW_SENT,
  BookNowSentResponseAction,
  CALL_LOAD_FULFILLED,
  CalledLoadResponseAction,
  fetchLoadBackhauls,
  HIDE_LOAD_FULFILLED,
  HideLoadResponseAction,
  REMOVE_LOAD,
  SAVE_LOAD_FULFILLED,
  SaveLoadResponseAction,
  SEND_EMAIL_TO_BROKER_FULFILLED,
  SendEmailToBrokerResponseAction,
  SENT_BID_VENDOR_LOAD,
  SET_PROGRESS_FULFILLED,
  SetProgressResponseAction,
} from '@common/redux/epic/LoadInfoEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import {
  refreshCompanySearchLoadCount,
  searchLoads as fetchCompanySearchLoads,
  updateCompanySearchLoads,
  updateLastSearch as updateLastCompanySearch,
} from '@common/redux/epic/loadSearch/CompanySearchEpic';
import { PersistentSearch } from '@common/redux/epic/loadSearch/HelperFunctions';
import { backhaulsActions } from '@common/redux/epic/loadSearch/LoadSearchBackhaulsEpic';
import {
  refreshLoadCount,
  searchLoads,
  setSelectedSearch,
  updateLastSearch,
  updateListSearch,
  updateLoads,
} from '@common/redux/epic/loadSearch/LoadSearchEpic';
import {
  companyMapSearchActionKeys,
  companyMapSearchActions,
  mapSearchActionKeys,
  mapSearchActions,
} from '@common/redux/epic/loadSearch/MapSearchEpic';
import { fetchMyLoadsTotalCount, updateMyLoads } from '@common/redux/epic/MyLoadsEpic';
import { getTruckAvailabilities, getTruckAvailabilityCount } from '@common/redux/epic/PostTruckEpic';
import { updateTripLoads } from '@common/redux/epic/TripBuilderEpic';
import { UPLOAD_NEW_DOCUMENT_SENT } from '@common/redux/epic/UploadFileEpic';
import { fetchUserInboxMessageUpdated } from '@common/redux/epic/UserEpic';
import {
  fetchMembershipsData,
  fetchMembershipsDataAction,
  fetchUserPlansAction,
  fetchUserPlansWithoutPromoCode,
} from '@common/redux/epic/UserPlanManagementEpic';
import { DeleteSingleTruckResponseAction, TRUCK_DELETED } from '@common/redux/epic/UserTrucksEpic';
import { SubRoutes } from '@component/panels/truckPost/TruckPostLists';
import { loadSearchRequestEncoder } from '@page/findLoads/LoadSearchRequestEncoder';
import { FindLoadsRouteParams } from '@page/findLoads/RouteParams';
import { loadAvailabilityEncoder, LoadAvailabilityRouting } from '@page/loadAvailability/LoadAvailabilityEncoder';
import { getIsDensityMap } from '@page/loadAvailability/Utility';
import { PostLoadsRouteParams } from '@page/postLoads/RouteParams';
import { T, t } from '@translate';
import { TruckUpdateActionTypes } from '@util/TruckDataHelper';
import { decodeMatchParams } from '@util/UrlParamsHelper';

const updateHiddenCompanyLoads$ = (action$: ActionsObservable<Action>, state$: StateObservable<StoreState>) =>
  action$.ofType(BROKER_HIDDEN).pipe(
    mergeMap$((action: BlockedCompanyResponseAction) => {
      //if you delete blocked companies we need a refresh of the loads
      return if$(
        () => action.updated && action.updated.length > 0,
        Observable.create((observer: Subscriber<Action>) => {
          const posterUpdate = first(action.updated);
          if (!posterUpdate) {
            return;
          }
          const loadUpdate: LoadUpdate = {
            category: UpdateCategory.BLOCKED,
            posterID: posterUpdate.id,
            isBlocked: posterUpdate.value,
          };
          const loadAvailabilityObject = loadAvailabilityEncoder.convertUrlParamsToLoadAvailabilityRouting(
            history.location.search
          );
          const loadAvailabilityParams = decodeMatchParams<LoadAvailabilityRouting>(
            history.location.pathname,
            Routes.LOADS_LOAD_AVAILABILITY
          );
          const lastSearchRequestForMap = loadAvailabilityParams
            ? state$.value.loadAvailability.lastSearchRequest
            : state$.value.loadSearch.lastSearchRequest;
          observer.next(searchLoads());
          observer.next(fetchCompanySearchLoads());
          observer.next(backhaulsActions.searchLoads());
          observer.next(mapSearchActions.searchLoads());
          observer.next(companyMapSearchActions.searchLoads());
          observer.next(updateMyLoads(loadUpdate));
          // in MyLoads and Notifications, it's possible to unblock a poster
          // from load details. this action ensures the load details UI reflects
          // the change (unblock)
          observer.next(updateLoadDetails(LoadSearchType.LoadSearch, loadUpdate));
          observer.next(updateLoadDetails(LoadSearchType.Backhaul, loadUpdate));
          observer.next(updateLoadDetails(LoadSearchType.CompanySearch, loadUpdate));
          observer.next(updateLoadDetails(LoadSearchType.Posted, loadUpdate));
          loadUpdate.shouldRemoveLoad = true;
          observer.next(updateLoads(loadUpdate));
          observer.next(updateLoadAvailabilityLoads(loadUpdate));
          observer.next(updateCompanySearchLoads(loadUpdate));
          observer.next(backhaulsActions.updateBackhaulLoads(loadUpdate));
          observer.next(updateMyLoads({ ...loadUpdate, shouldRemoveLoad: false }));
          observer.next(mapSearchActions.updateMapSearchLoads(loadUpdate));
          observer.next(companyMapSearchActions.updateMapSearchLoads(loadUpdate));
          observer.next(refreshLoadCount(state$.value.loadSearch.listSearchRequest));
          observer.next(refreshCompanySearchLoadCount(state$.value.companySearch.listSearchRequest));
          observer.next(backhaulsActions.refreshBackhaulsLoadCount(state$.value.backhauls.lastSearchRequest));
          observer.next(mapSearchActions.refreshMapSearchLoadCount(state$.value.mapSearch.lastSearchRequest));
          observer.next(
            companyMapSearchActions.refreshMapSearchLoadCount(state$.value.companyMapSearch.lastSearchRequest)
          );
          observer.next(mapSearchActions.fetchGeoLoads(lastSearchRequestForMap));
          observer.next(companyMapSearchActions.fetchGeoLoads(state$.value.companyMapSearch.lastSearchRequest));
          observer.next(refreshLoadAvailabilityCount(state$.value.loadAvailability.listSearchRequest));
          if (state$.value.loadDetails.currentLoad) {
            if (state$.value.loadAvailability.selectedSearch?.id) {
              observer.next(
                fetchLoadBackhauls(
                  state$.value.loadDetails.currentLoad.id,
                  state$.value.loadAvailability.selectedSearch.id
                )
              );
            } else if (state$.value.loadSearch.selectedSearch?.id) {
              observer.next(
                fetchLoadBackhauls(state$.value.loadDetails.currentLoad.id, state$.value.loadSearch.selectedSearch.id)
              );
            }
          }
          if (loadAvailabilityObject?.region && loadAvailabilityObject?.equipmentType) {
            observer.next(
              fetchLoadAvailabilities(
                loadAvailabilityObject?.equipmentType ?? [EquipmentType.All],
                loadAvailabilityObject?.region ?? LoadAvailabilitySortCategory.USA
              )
            );
          }
        })
      );
    })
  );

const addVendorBidAfterBidSubmission$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(SENT_BID_VENDOR_LOAD).pipe(
    mergeMap$((responseAction: BidOnVendorLoadResponseAction) =>
      if$(
        () => (responseAction.response.success && responseAction.response.payload ? true : false),
        of$(
          addVendorBid({
            ...responseAction.request,
            loadId: responseAction.loadID,
            createdOn: responseAction.response.payload?.submittedOn ?? '',
            rate: { amount: responseAction.request.amount, type: PayRateUnit.Flat },
          })
        )
      )
    )
  );

const updateLoads$ = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(
      SAVE_LOAD_FULFILLED,
      RECEIVE_LOAD_FEEDBACK_RESPONSE,
      BROKER_FAVORITED,
      BROKER_ONBOARDED,
      ADD_NOTE_FULFILLED,
      ADD_PRIVATE_LOAD_NOTE_FULFILLED,
      SET_PROGRESS_FULFILLED,
      REMOVE_LOAD,
      SEND_EMAIL_TO_BROKER_FULFILLED,
      SENT_BID_VENDOR_LOAD,
      BOOK_NOW_SENT,
      CALL_LOAD_FULFILLED,
      HIDE_LOAD_FULFILLED,
      setPostedLoadProgressAction.responseType
    )
    .pipe(
      mergeMap$((action: Action) => {
        let loadUpdate: LoadUpdate | undefined;
        switch (action.type) {
          case SAVE_LOAD_FULFILLED: {
            loadUpdate = {
              loadID: (action as LoadAction).loadID,
              category: UpdateCategory.SAVED,
              isSaved: (action as SaveLoadResponseAction).didSave,
            };
            break;
          }
          case HIDE_LOAD_FULFILLED: {
            const didHide = (action as HideLoadResponseAction).didHide;
            loadUpdate = {
              loadID: (action as LoadAction).loadID,
              category: UpdateCategory.HIDDEN,
              isHidden: didHide,
            };
            break;
          }
          case BROKER_FAVORITED: {
            const favoriteAction = action as CompanyResponseAction;
            const favorited = first(favoriteAction.updated);
            if (favorited) {
              loadUpdate = {
                category: UpdateCategory.FAVORITE,
                posterID: favorited.id,
                isFavorited: favorited.value,
              };
            }
            break;
          }
          case BROKER_ONBOARDED: {
            const onboardedAction = action as CompanyResponseAction;
            const onboarded = first(onboardedAction.updated);
            if (onboarded) {
              loadUpdate = {
                category: UpdateCategory.ONBOARDED,
                posterID: onboarded.id,
                isOnboarded: onboarded.value,
              };
            }
            break;
          }
          case ADD_NOTE_FULFILLED: {
            loadUpdate = {
              loadID: (action as LoadAction).loadID,
              category: UpdateCategory.NOTE,
              note: (action as AddNoteResponseAction).note,
            };
            break;
          }
          case ADD_PRIVATE_LOAD_NOTE_FULFILLED: {
            const privateNoteAction = action as AddPrivateLoadNoteResponseAction;
            loadUpdate = {
              loadID: (action as LoadAction).loadID,
              category: UpdateCategory.PRIVATELOADNOTE,
              privateLoadNote: privateNoteAction.privateLoadNote,
            };
            break;
          }
          case SET_PROGRESS_FULFILLED: {
            const updateAction = action as SetProgressResponseAction;
            if (updateAction.response.success) {
              loadUpdate = {
                loadID: updateAction.loadID,
                category: UpdateCategory.PROGRESS,
                progress: updateAction.progress,
                isSaved: false,
              };
            }
            break;
          }
          case REMOVE_LOAD: {
            const updateAction = action as LoadAction;
            loadUpdate = {
              loadID: updateAction.loadID,
              category: UpdateCategory.HIDDEN,
              isHidden: true,
            };
            break;
          }
          case SEND_EMAIL_TO_BROKER_FULFILLED: {
            const sendEmailAction = action as SendEmailToBrokerResponseAction;
            loadUpdate = {
              loadID: sendEmailAction.loadID,
              category: UpdateCategory.EMAILED,
              isEmailed: sendEmailAction.response.success,
            };
            break;
          }
          case SENT_BID_VENDOR_LOAD: {
            const vendorBidAction = action as BidOnVendorLoadResponseAction;
            if (vendorBidAction.response.success) {
              loadUpdate = {
                loadID: vendorBidAction.loadID,
                category: UpdateCategory.VENDOR_BID_SENT,
                bid: {
                  amount: vendorBidAction.request.amount,
                  submittedOn: vendorBidAction.response.payload?.submittedOn ?? '',
                },
              };
            }
            break;
          }
          case BOOK_NOW_SENT: {
            const bookNowSentAction = action as BookNowSentResponseAction;
            loadUpdate = {
              loadID: bookNowSentAction.loadID,
              category: UpdateCategory.BOOK_NOW_SENT,
              bookNow: bookNowSentAction.response.payload,
              errorCode: bookNowSentAction.response.error?.code,
            };
            break;
          }
          case CALL_LOAD_FULFILLED: {
            const calledLoadAction = action as CalledLoadResponseAction;
            loadUpdate = {
              loadID: calledLoadAction.loadID,
              category: UpdateCategory.CALLED,
              isCalled: calledLoadAction.isCalled,
            };
            break;
          }

          case setPostedLoadProgressAction.responseType: {
            const updateAction = action as ResponseAction<EmptyResponse, SetPostedProgressRequest>;
            if (updateAction.fetchData && !updateAction.response.error) {
              loadUpdate = {
                loadID: updateAction.fetchData.loadID,
                category: UpdateCategory.POSTED_PROGRESS,
                progress: updateAction.fetchData.progress,
                dotNo: updateAction.fetchData.dotNo,
              };
            }
            break;
          }

          //We only have 2 cases, so it's fine. if we add more update types, then remember to add here.
          default: {
            const updateAction = action as ReceiveLoadFeedbackResponse;
            if (updateAction.category === LOAD_FEEDBACK_CATEGORY.CALLED) {
              loadUpdate = {
                loadID: updateAction.loadID,
                category: UpdateCategory.CALLED,
                isCalled: true,
              };
            }
            if (updateAction.category === LOAD_FEEDBACK_CATEGORY.PROGRESS) {
              loadUpdate = {
                loadID: updateAction.loadID,
                category: UpdateCategory.PROGRESS,
                progress: updateAction.value as LoadProgress,
              };
            }
          }
        }

        return if$(
          () => !isNil(loadUpdate),
          from$(
            //Load update validated above. be carefull not removing the isNil
            [
              updateLoadDetails(LoadSearchType.LoadSearch, loadUpdate as LoadUpdate),
              updateLoadDetails(LoadSearchType.Backhaul, loadUpdate as LoadUpdate),
              updateLoadDetails(LoadSearchType.CompanySearch, loadUpdate as LoadUpdate),
              updateLoadDetails(LoadSearchType.Posted, loadUpdate as LoadUpdate),
              updateLoads(loadUpdate as LoadUpdate),
              updateLoadAvailabilityLoads(loadUpdate as LoadUpdate),
              backhaulsActions.updateBackhaulLoads(loadUpdate as LoadUpdate),
              updateMyLoads(loadUpdate as LoadUpdate),
              mapSearchActions.updateMapSearchLoads(loadUpdate as LoadUpdate),
              updateCompanySearchLoads(loadUpdate as LoadUpdate),
              companyMapSearchActions.updateMapSearchLoads(loadUpdate as LoadUpdate),
              updateTripLoads(loadUpdate as LoadUpdate),
            ]
          )
        );
      })
    );

const loadCreated$ = (action$: ActionsObservable<Action>, state$: StateObservable<StoreState>) =>
  action$.ofType(POST_LOAD_FULFILLED).pipe(
    mergeMap$((action: LoadActionResponse) => {
      if (action.response.success) {
        return of$(
          showSnackbar({
            message: t(T.postLoads_LoadPostedSuccessfully),
          }),
          fetchBrokerLoadsSummary(state$.value.loadPost.includeAllCompanyLoads),
          push({ pathname: action.returnUrl })
        );
      } else {
        if (action.response.error?.fieldErrors !== undefined) {
          const validationFields = mapApiErrorFieldsToFormValidationFields<PostLoadFormFieldsState>(
            action.response.error.fieldErrors
          );
          return of$(setValidationFields(validationFields));
        }
        return of$();
      }
    })
  );

const loadCloned$ = (action$: ActionsObservable<Action>, state$: StateObservable<StoreState>) =>
  action$.ofType(CLONE_LOAD_FULFILLED).pipe(
    mergeMap$((action: LoadActionResponse) => {
      if (action.response.success) {
        return of$(
          fetchPostedLoads(),
          fetchBrokerLoadsSummary(state$.value.loadPost.includeAllCompanyLoads),
          showSnackbar({
            message: t(T.postLoads_ClonedLoadPosted),
          }),
          push({ pathname: action.returnUrl, search: history.location.search })
        );
      } else {
        if (action.response.error?.fieldErrors !== undefined) {
          const validationFields = mapApiErrorFieldsToFormValidationFields<PostLoadFormFieldsState>(
            action.response.error.fieldErrors
          );
          return of$(setValidationFields(validationFields));
        }
        return of$();
      }
    })
  );

const loadEdited$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(EDIT_LOAD_FULFILLED).pipe(
    mergeMap$((action: LoadActionResponse) => {
      if (action.response.success) {
        const match = decodeMatchParams<PostLoadsRouteParams>(action.returnUrl, Routes.LOADS_MY_LOADS_POST);
        const actions: Action[] = [
          showSnackbar({
            message: t(T.postLoads_LoadEdited),
          }),
          updateSingleLoad(action.response.payload),
          push({ pathname: action.returnUrl, search: history.location.search }),
        ];
        if (match?.params?.loadID && match.params.actionType === 'online') {
          actions.push(fetchLoadDetails(LoadSearchType.Posted, match.params.loadID));
          if (action.shouldFetchRouteMap) {
            actions.push(fetchRouteMap(match.params.loadID, undefined));
          }
        }
        if (match?.params.actionType === 'offline') {
          actions.push(refreshOfflineLoadsList());
        }
        return from$(actions);
      } else {
        if (action.response.error?.fieldErrors !== undefined) {
          const validationFields = mapApiErrorFieldsToFormValidationFields<PostLoadFormFieldsState>(
            action.response.error.fieldErrors
          );
          return of$(setValidationFields(validationFields));
        }
        return of$();
      }
    })
  );

const updatePostedLoadStatus$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(SET_POSTED_LOAD_STATUS_RESPONSE).pipe(
    mergeMap$((action: UpdatedLoadStatusAction) => {
      if (action.response.success) {
        return of$(
          clearCurrentLoad(LoadSearchType.Posted),

          push({ pathname: action.redirectUrl })
        );
      }
      return of$();
    })
  );

const deletePostedLoad$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(POSTED_LOAD_DELETED).pipe(
    mergeMap$((action: PostedLoadDeletedAction) => {
      if (action.response.success) {
        return of$(
          clearCurrentLoad(LoadSearchType.Posted),

          push({ pathname: action.redirectUrl })
        );
      }
      return of$();
    })
  );

const updatePostedLoadAge$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(REFRESH_LOAD_AGE_RESPONSE).pipe(
    mergeMap$((action: RefreshedLoadAgeAction) => {
      if (action.response.success) {
        const updatedLoadID = get(action, ['response', 'payload', 'id']);
        const updatedLoadAge = get(action, ['response', 'payload', 'age']);
        const update: LoadUpdate = { loadID: updatedLoadID, category: UpdateCategory.AGE, age: updatedLoadAge };
        return of$(updateMyLoads(update), updateLoadDetails(LoadSearchType.Posted, update));
      }
      return of$();
    })
  );

const updateLastAndSelectedSearchOnMapSearch = (action$: ActionsObservable<Action>, geocodeClient: GeocodingClient) =>
  action$
    .ofType(
      mapSearchActionKeys.NEW_GEO_LOADS_FETCHED,
      mapSearchActionKeys.GEO_LOADS_FETCHED,
      companyMapSearchActionKeys.NEW_GEO_LOADS_FETCHED,
      companyMapSearchActionKeys.GEO_LOADS_FETCHED
    )
    .pipe(
      mergeMap$((action: ResponseAction<LoadSearchGeoResponse>) => {
        if (action.response.success && action.response.payload) {
          const requestParams = cloneDeep(action.response.payload.metadata.requestParams);
          const lastSearch = { ...requestParams, minOriginRadius: undefined };
          lastSearch.metadata.type = SearchMetadataType.Geo;
          lastSearch.metadata.limit = INITIAL_SEARCH_LIMIT;
          lastSearch.pickupDates = map(lastSearch.pickupDates, serverRFCDateToYYYYMMDD);
          lastSearch.isOnboarded = lastSearch.company?.isOnboarded;
          const payload = action.response.payload;
          return reverseGeocodeLocationIfNeeded$(
            { ...lastSearch.origin, type: LocationType.GEOLOCATION } as LoadLocation,
            geocodeClient
          ).pipe(
            mergeMap$((location: any) => {
              const persistentSearch = {
                id: payload.id,
                search: {
                  ...lastSearch,
                  origin: {
                    ...location,
                    city: location.city,
                    states: location.states,
                    radius: lastSearch.origin.radius,
                    type: location.type,
                  },
                },
              };
              const reversedGeocodeOrigin = valueForOriginLocation(location) || lastSearch.origin;
              reversedGeocodeOrigin.radius = lastSearch.origin.radius;
              const newSearch = {
                ...lastSearch,
                pickupDates: map(
                  lastSearch.pickupDates,
                  serverRFCDateToYYYYMMDD
                ) /* for some reason it gives full GMT time */,
                origin: reversedGeocodeOrigin,
              };
              const updateLastSearchFunc = updateLastSearch(newSearch);
              const updateLastAvailabilitySearchFunc = updateLastAvailabilitySearch(newSearch);
              const updateLastCompanySearchFunc = updateLastCompanySearch(newSearch);
              let result;
              if (action.type === mapSearchActionKeys.GEO_LOADS_FETCHED) {
                result = of$(updateListSearch(lastSearch), updateListAvailabilitySearch(lastSearch));
                if (payload.id) {
                  result = concat$(
                    result,
                    of$(
                      setSelectedSearch({
                        ...lastSearch,
                        id: payload.id,
                      })
                    )
                  );
                }
                return result;
              } else if (action.type === companyMapSearchActionKeys.GEO_LOADS_FETCHED) {
                return of$(updateLastCompanySearch(lastSearch));
              } else {
                result = of$(updateLastSearchFunc, updateLastAvailabilitySearchFunc, updateLastCompanySearchFunc);
                if (isEmpty(payload.groups)) {
                  result = concat$(result, of$(showSnackbar({ message: t(T.findLoads_loads_mapError_noLoadsFound) })));
                }
                if (location.city || location.states) {
                  result = concat$(
                    result,
                    of$(setSelectedSearch({ ...persistentSearch.search, id: persistentSearch.id }))
                  );
                }
                setUrlDataAfterNewMapSearch(persistentSearch);
              }
              return result;
            })
          );
        }
        return of$();
      })
    );

const setUrlDataAfterNewMapSearch = (persistentSearch: PersistentSearch) => {
  const filter = loadSearchRequestEncoder.convertObjectRequestToUrlParams({
    searchId: persistentSearch.id,
    metadata: persistentSearch.search.metadata,
    searchData: persistentSearch.search,
  });
  const findLoadsParams = decodeMatchParams<FindLoadsRouteParams>(history.location.pathname, Routes.LOADS_FINDLOADS);
  const loadAvailabilityParams = decodeMatchParams<FindLoadsRouteParams>(
    history.location.pathname,
    Routes.LOADS_LOAD_AVAILABILITY
  );
  const loadAvailabilityDensityParams = decodeMatchParams<FindLoadsRouteParams>(
    history.location.pathname,
    Routes.LOADS_LOAD_AVAILABILITY_MAP
  );
  const myLoadsParams = decodeMatchParams<FindLoadsRouteParams>(history.location.pathname, Routes.LOADS_MY_LOADS);
  const companiesParams = decodeMatchParams<FindLoadsRouteParams>(history.location.pathname, Routes.LOADS_COMPANIES);
  const isDensityMap = getIsDensityMap(history.location.pathname);
  const loadSearchObject = loadSearchRequestEncoder.convertUrlParamsToLoadSearchFilters(history.location.search);
  const newCompanySearchUrl = loadSearchRequestEncoder.convertObjectRequestToUrlParams({
    ...loadSearchObject,
    companySearchId: persistentSearch.id,
    companyMetadata: persistentSearch.search.metadata,
    companySearchData: persistentSearch.search,
  });
  if (findLoadsParams?.params) {
    history.push({
      pathname: `${generatePath(Routes.LOADS_FINDLOADS, {
        ...findLoadsParams?.params,
      })}/`,
      search: findLoadsParams.params.companySearchMap ? newCompanySearchUrl : filter,
    });
  }
  let params = {};
  const loadAvailabilityObject = loadAvailabilityEncoder.convertUrlParamsToLoadAvailabilityRouting(location.search);
  const newCompanySearchAvailabilityUrl = loadAvailabilityEncoder.convertObjectRequestToUrlParams({
    ...loadAvailabilityObject,
    companySearchId: persistentSearch.id,
    companySearchData: persistentSearch.search,
    companyMapData: undefined,
  });
  if (loadAvailabilityParams?.params && !isDensityMap) {
    params = loadAvailabilityParams.params;
    history.push({
      pathname: `${generatePath(Routes.LOADS_LOAD_AVAILABILITY, params)}/`,
      search: loadAvailabilityParams?.params.companySearchMap ? newCompanySearchAvailabilityUrl : filter,
    });
  }
  if (loadAvailabilityDensityParams?.params && isDensityMap) {
    params = loadAvailabilityDensityParams.params;
    history.push({
      pathname: `${generatePath(Routes.LOADS_LOAD_AVAILABILITY_MAP, params)}/`,
      search: loadAvailabilityDensityParams?.params.companySearchMap ? newCompanySearchAvailabilityUrl : filter,
    });
  }
  if (myLoadsParams?.params) {
    params = myLoadsParams.params;
    history.push({
      pathname: `${generatePath(Routes.LOADS_MY_LOADS, params)}/`,
      search: myLoadsParams?.params.companySearchMap ? newCompanySearchUrl : filter,
    });
  }
  if (companiesParams?.params) {
    params = companiesParams.params;
    history.push({
      pathname: `${generatePath(Routes.LOADS_COMPANIES, params)}/`,
      search: companiesParams?.params.companySearchMap ? newCompanySearchUrl : filter,
    });
  }
};

const reverseGeocodeLocationIfNeeded$ = (
  location: LoadLocation,
  geocodeClient: GeocodingClient
): Observable<LoadLocation> => {
  if (location.type === LocationType.GEOLOCATION) {
    const originGeolocation = new Geolocation(location.latitude, location.longitude);
    return geocodeClient.locationFor$(originGeolocation).pipe(
      map$((result) =>
        result.result(
          (data) => {
            return loadGeolocationFrom(originGeolocation, data);
          },
          () => {
            return location;
          }
        )
      )
    );
  }
  return of$(location);
};

const updateTrucks$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(CARRIER_FAVORITED, CARRIER_ONBOARDED, CARRIER_HIDDEN).pipe(
    mergeMap$((action: CompanyResponseAction) => {
      let updateTrucksAction;
      switch (action.type) {
        case CARRIER_FAVORITED:
          updateTrucksAction = updateTrucks({ type: TruckUpdateActionTypes.Favorite, updated: action.updated });
          break;
        case CARRIER_ONBOARDED:
          updateTrucksAction = updateTrucks({ type: TruckUpdateActionTypes.Onboard, updated: action.updated });
          break;
        case CARRIER_HIDDEN:
          updateTrucksAction = updateTrucks({ type: TruckUpdateActionTypes.Hide, updated: action.updated });
      }
      return of$(updateTrucksAction);
    })
  );

const updateTrucksAfterBulkCarriersActions$ = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(favoriteCarriersBulkAction.responseType, blockCarriersBulkAction.responseType)
    .pipe(map$(() => triggerTruckAction(TruckAction.REFRESH_TRUCKS)));

const updateMyLoadsTotalCounts$ = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(SAVE_LOAD_FULFILLED, SET_PROGRESS_FULFILLED, CALL_LOAD_FULFILLED, HIDE_LOAD_FULFILLED)
    .pipe(map$(() => fetchMyLoadsTotalCount(MYLOADS_FILTERS_LIST)));

const refreshLoadsAfterLoadHide$ = (action$: ActionsObservable<Action>, state$: StateObservable<StoreState>) =>
  action$.ofType(HIDE_LOAD_FULFILLED).pipe(
    mergeMap$(() => {
      const newCompanyMapLoadSearch = {
        ...state$.value.companyMapSearch.lastSearchRequest,
        minOriginRadius: undefined,
      };
      const loadAvailabilityObject = loadAvailabilityEncoder.convertUrlParamsToLoadAvailabilityRouting(
        history.location.search
      );
      const loadAvailabilityParams = decodeMatchParams<LoadAvailabilityRouting>(
        history.location.pathname,
        Routes.LOADS_LOAD_AVAILABILITY
      );
      const loadCompanyParams = decodeMatchParams<FindLoadsRouteParams>(
        history.location.pathname,
        Routes.LOADS_COMPANIES
      );
      const lastSearchRequestForMap = loadAvailabilityParams
        ? state$.value.loadAvailability.lastSearchRequest
        : state$.value.loadSearch.lastSearchRequest;

      const currentLoadId = loadCompanyParams
        ? state$.value.loadDetailsCompanySearch.currentLoad?.id
        : state$.value.loadDetails.currentLoad?.id;

      return from$(
        compact([
          refreshLoadCount(state$.value.loadSearch.listSearchRequest),
          refreshLoadAvailabilityCount(state$.value.loadAvailability.listSearchRequest),
          refreshCompanySearchLoadCount(state$.value.companySearch.listSearchRequest),
          companyMapSearchActions.refreshMapSearchLoadCount(state$.value.companyMapSearch.lastSearchRequest),
          backhaulsActions.refreshBackhaulsLoadCount(state$.value.backhauls.lastSearchRequest),
          backhaulsActions.searchLoads(),
          searchLoadsAvailability(),
          companyMapSearchActions.fetchGeoLoads(newCompanyMapLoadSearch),
          mapSearchActions.fetchGeoLoads(lastSearchRequestForMap),
          companyMapSearchActions.searchLoads(),
          mapSearchActions.searchLoads(),
          currentLoadId ? fetchLoadBackhauls(currentLoadId) : undefined,

          !!loadAvailabilityObject?.region && !!loadAvailabilityObject?.equipmentType
            ? fetchLoadAvailabilities(
                loadAvailabilityObject?.equipmentType ?? [EquipmentType.All],
                loadAvailabilityObject?.region ?? LoadAvailabilitySortCategory.USA
              )
            : undefined,
        ])
      );
    })
  );

const updateLoadFolder$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(RECEIVE_LOAD_FEEDBACK_RESPONSE).pipe(
    filter$((action: ReceiveLoadFeedbackResponse) => action.value === LoadProgress.LoadAvailable),
    mergeMap$((action: ReceiveLoadFeedbackResponse) => of$(deleteFolderResponse({ success: action.response.success })))
  );

const updatePostTruckCounter$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(TRUCK_DELETED).pipe(
    filter$((action: DeleteSingleTruckResponseAction) => action.response.payload?.override === true),
    mergeMap$(() => {
      const match = decodeMatchParams<{ filter: PostsFilter; subroutes: SubRoutes }>(
        history.location.pathname,
        Routes.MYTRUCKS_POST
      );
      return of$(getTruckAvailabilityCount(), getTruckAvailabilities(match?.params.filter));
    })
  );

const updateAfterDeletingDocuments$ = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(DOCUMENT_DELETED)
    .pipe(
      mergeMap$((action: DocumentDeletedResponseAction) =>
        action.response.success && action.response.payload
          ? of$(fetchDefaultFolders(), fetchCustomFolders(), fetchDocumentsCount())
          : of$()
      )
    );

const updateFolderCount$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(UPLOAD_NEW_DOCUMENT_SENT, DOCUMENT_FOLDER_UPDATED).pipe(
    mergeMap$((action: ResponseAction<any>) => {
      if (!action.response.success) {
        return of$();
      }
      if (action.type === DOCUMENT_FOLDER_UPDATED) {
        return of$(fetchDefaultFolders(), fetchCustomFolders());
      }
      return of$(fetchDefaultFolders(), fetchCustomFolders(), fetchDocumentsCount());
    })
  );

const updateDocumentTypeFolders$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(DOCUMENT_TYPE_UPDATED).pipe(mergeMap$(() => of$(fetchDocumentsCount())));

const fetchUserPlansWithoutPromoCode$ = (action$: ActionsObservable<Action>, state$: StateObservable<StoreState>) =>
  action$.ofType(fetchUserPlansAction.responseType).pipe(
    mergeMap$((action: ResponseAction<any>) => {
      if (
        action.response.success &&
        action.response.payload.promoCode &&
        isEmpty(state$.value.userPlan.lbPlansWithoutPromoCode)
      ) {
        return of$(fetchUserPlansWithoutPromoCode());
      }
      return of$();
    })
  );

const updateInboxUnreadCount$ = (action$: ActionsObservable<Action>, state$: StateObservable<StoreState>) =>
  action$.ofType(markAsReadAction.responseType, clearInboxMessagesAction.responseType).pipe(
    mergeMap$((action: ResponseAction<any>) => {
      if (action.response.success && state$.value.user.userAlerts && state$.value.user.userAlerts.payload) {
        const newCount =
          action.type === clearInboxMessagesAction.responseType ||
          state$.value.user.userAlerts.payload.messagesInfo.unreadMessagesCount === 0
            ? 0
            : state$.value.user.userAlerts.payload.messagesInfo.unreadMessagesCount - 1;
        return of$(fetchUserInboxMessageUpdated(newCount));
      }
      return of$();
    })
  );

const createMembershipForUser$ = (action$: ActionsObservable<Action>, membershipClient: UserPlanManagementClient) =>
  action$.ofType(fetchMembershipsDataAction.responseType).pipe(
    mergeMap$((action: ResponseAction<any>) => {
      if (action.response.error?.code === 4040129) {
        return membershipClient
          .createMembership$()
          .pipe(mergeMap$((response) => (response.success ? of$(fetchMembershipsData(), getCompanyInfo()) : of$())));
      }
      return of$();
    })
  );

const updatePostLoadBrokerInfoAfterPostingContactUpdate$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<StoreState>
) =>
  action$.ofType(editDispatcherAction.responseType).pipe(
    mergeMap$((action: ResponseAction<DispatcherPostResponse>) => {
      if (action.response.success && action.response.payload) {
        const updatedPostingContactId = action.response.payload.id;
        const lastUsedPostedContactId = state$.value.postLoadFields.lastUsedPostingContact?.id;
        const selectedPostedContactId = state$.value.postLoadFields.selectedPostingContact?.id;
        // if persisted posting contact and the one we selected from the list are the same and equals to updated
        if (
          updatedPostingContactId === lastUsedPostedContactId &&
          updatedPostingContactId === selectedPostedContactId
        ) {
          return of$(
            setLastUsedPostingContact(action.response.payload),
            setSelectedPostingContact(action.response.payload)
          );
        }
        // if persisted posting contact equals to updated posting contact
        if (updatedPostingContactId === lastUsedPostedContactId) {
          return of$(setLastUsedPostingContact(action.response.payload));
        }
        // if selected posting contact equals to updated posting contact
        if (updatedPostingContactId === selectedPostedContactId) {
          return of$(setSelectedPostingContact(action.response.payload));
        }
      }
      return of$();
    })
  );

const updatePostLoadBrokerInfoAfterPostingContactDelete$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<StoreState>
) =>
  action$.ofType(deleteDispatcherAction.responseType).pipe(
    mergeMap$((action: ResponseAction<DispatcherPostResponse>) => {
      if (action.response.success) {
        const deletedPostingContactId = action.fetchData;
        const selectedPostedContactId = state$.value.postLoadFields.selectedPostingContact?.id;
        const lastUsedPostedContact = state$.value.postLoadFields.lastUsedPostingContact;
        const defaultPostingContact = find(state$.value.postingContacts.entries, (entry) => entry.isDefaultForUser);
        // if persisted posting contact and the one we selected from the list are the same and equals to deleted
        if (
          deletedPostingContactId === lastUsedPostedContact?.id &&
          deletedPostingContactId === selectedPostedContactId
        ) {
          return of$(setLastUsedPostingContact(defaultPostingContact), setSelectedPostingContact(undefined));
        }
        // if persisted posting contact equals to deleted posting contact
        if (deletedPostingContactId === lastUsedPostedContact?.id) {
          return of$(setLastUsedPostingContact(defaultPostingContact));
        }
        // if selected posting contact equals to deleted posting contact
        if (deletedPostingContactId === selectedPostedContactId) {
          return of$(setSelectedPostingContact(lastUsedPostedContact));
        }
      }
      return of$();
    })
  );

export const createReducerSyncEpic$ = (api: Api) => {
  const geocodingClient = new GeocodingClient(api);
  const membershipClient = new UserPlanManagementClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<StoreState>) =>
    merge$(
      updateHiddenCompanyLoads$(action$, state$),
      updateLoads$(action$),
      updateLastAndSelectedSearchOnMapSearch(action$, geocodingClient),
      updatePostedLoadStatus$(action$),
      updateMyLoadsTotalCounts$(action$),
      deletePostedLoad$(action$),
      updatePostedLoadAge$(action$),
      loadCreated$(action$, state$),
      loadCloned$(action$, state$),
      loadEdited$(action$),
      updateTrucks$(action$),
      updateLoadFolder$(action$),
      updateAfterDeletingDocuments$(action$),
      updateFolderCount$(action$),
      updateDocumentTypeFolders$(action$),
      updateTrucksAfterBulkCarriersActions$(action$),
      updatePostTruckCounter$(action$),
      updateInboxUnreadCount$(action$, state$),
      refreshLoadsAfterLoadHide$(action$, state$),
      addVendorBidAfterBidSubmission$(action$),
      fetchUserPlansWithoutPromoCode$(action$, state$),
      createMembershipForUser$(action$, membershipClient),
      updatePostLoadBrokerInfoAfterPostingContactUpdate$(action$, state$),
      updatePostLoadBrokerInfoAfterPostingContactDelete$(action$, state$)
    );
};

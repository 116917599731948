import { BaseClient } from '@common/client/BaseClient';
import {
  PostedTrucksCountResponse,
  TruckAvailabilityResponse,
  TruckLklSearchRequest,
  TruckRequest,
  TruckWithAvailabilityResponse,
  UpdateUserTruck,
  UserTruck,
} from '@common/model';

export class TrucksClient extends BaseClient {
  fetchTrucksSearch$ = (request: TruckRequest, isInternal: boolean) =>
    this.api.post$('/trucks/search/loadid', {
      loadId: request.loadId,
      radius: request.filters.radius,
      internal: isInternal,
    });
  fetchTrucksSearchLkl$ = (request: TruckLklSearchRequest) =>
    this.api.post$('/trucks/search/lkl', {
      location: request.location,
      geolocation: request.geolocation,
      equipmentTypes: request.equipmentTypes,
      radius: request.radius,
    });
  fetchTrucks$ = (deviceId?: string) => this.api.get$<UserTruck[]>('/trucks', { deviceId: deviceId });
  fetchTruck$ = (id: string) => this.api.get$<UserTruck>(`/trucks/${id}`, {});
  createTruck$ = (truck: Partial<UpdateUserTruck>) =>
    this.api.post$<UserTruck>('/trucks', {
      ...truck,
      createAvailability: false,
      weightIsSet: true,
      lengthIsSet: true,
    });
  updateTruck$ = (truck: Partial<UpdateUserTruck>) =>
    this.api.mergePatch$<UserTruck>(`/trucks/${truck.id}`, { ...truck, weightIsSet: true, lengthIsSet: true });

  deleteTruck$ = (id: string, override: boolean = false) => this.api.delete$(`/trucks/${id}`, { override: override });

  fetchTruckAvailabilitiesCount = (deviceId: string) =>
    this.api.get$<PostedTrucksCountResponse>('/trucks/withavailabilities', {
      deviceId: deviceId,
      convertStatesToAnywhere: true,
      fields: 'trucks{availabilities{status,dates,type}}',
    });
  fetchTruckAvailabilities = (convertStatesToAnywhere: boolean = true, fields?: string) =>
    this.api.get$<TruckWithAvailabilityResponse>('/trucks/withavailabilities', {
      convertStatesToAnywhere: convertStatesToAnywhere,
      fields: fields,
    });
  fetchTruckAvailability = (availabilityID: string, convertStatesToAnywhere: boolean = true) =>
    this.api.get$(`/truckavailability/${availabilityID}`, { convertStatesToAnywhere: convertStatesToAnywhere });
  createTruckAvailability = (truckAvailability: TruckAvailabilityResponse, convertStatesToAnywhere: boolean = true) =>
    this.api.post$<TruckAvailabilityResponse>(
      `/trucks/${truckAvailability.truckId}/availability`,
      { ...truckAvailability, convertStatesToAnywhere: convertStatesToAnywhere },
      {}
    );
  updateTruckAvailability = (
    availabilityID: number,
    truckAvailability: TruckAvailabilityResponse,
    convertStatesToAnywhere: boolean = true
  ) =>
    this.api.put$<TruckAvailabilityResponse>(`/truckavailability/${availabilityID}`, {
      ...truckAvailability,
      convertStatesToAnywhere: convertStatesToAnywhere,
    });
  deleteTruckAvailability = (availabilityID: number) => this.api.delete$(`/truckavailability/${availabilityID}`, {});
}

import React, { useEffect } from 'react';

import { toNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { generatePath, RouteComponentProps } from 'react-router-dom';

import { LocationTypes } from '@/model';
import {
  clearPostAvailabilityForm,
  PostTruckAvailabilityForm,
  setPostAvailabilityPrefilled,
  updatePostAvailabilityForm,
} from '@/reduxStore/reducer/PostTruckAvailabilityReducer';
import { NestedRoute, UnitedRoute } from '@/router/RouteGroup';
import { useSelector } from '@/util/hooks';
import { convertBaseLocationToLoadLocation, convertRecentLocationToBaseLocation } from '@common/helper';
import {
  CountriesWithAnywhere,
  loadLocationFromCountrySelection,
  locationMatchToLoadLocation,
} from '@common/helper/LocationPickerHelper';
import {
  HomeLocation,
  LoadLocation,
  LocationSuggestion,
  LocationType,
  OriginLocation,
  PostsFilter,
  RecentLocation,
  RecentLocationType,
  UserTruck,
} from '@common/model';
import { usePanelBackLocation, usePushPanel } from '@component/panel/PanelHooks';
import { LocationPickerPanel } from '@component/panels/modalPanel/LocationPickerPanel';
import { MyTruck } from '@component/panels/myTruck/MyTruck';
import { AddTruck, EditTruck } from '@component/panels/myTruck/TruckForm';
import { Dates } from '@component/panels/pickerPanels/Dates';
import { EditPostedTruckForm, PostTruckForm } from '@component/panels/truckPost/PostTruckForm';
import { SubRoutes } from '@component/panels/truckPost/TruckPostLists';

export enum TruckPostViewTypes {
  DropOff = 'DropOff',
  PickUp = 'PickUp',
  DatesPicker = 'Dates',
  Home = 'Home',
  TruckPost = 'TruckPost',
  TruckList = 'TruckList',
}

interface Props {
  onClose?: () => void;
  baseUrl: string;
  layer?: number;
  offset?: number;
}

const NewTruckPost: React.FC<
  Props &
    RouteComponentProps<{
      filter: PostsFilter;
      subroutes: SubRoutes;
      truckID: string;
      postID: string;
      panels: string;
    }>
> = (props) => {
  const dispatch = useDispatch();

  const openPickupPanelPath = usePushPanel(`/${SubRoutes.PickUp}/`);
  const openDropOffPanelPath = usePushPanel(`/${SubRoutes.DropOff}/`);
  const closePickupPanelPath = usePanelBackLocation(`/${SubRoutes.PickUp}/`);
  const closeDropOffPanelPath = usePanelBackLocation(`/${SubRoutes.DropOff}/`);
  const openPanelPickUp = () => openPickupPanelPath();
  const openDropOff = () => openDropOffPanelPath();
  const closePanelPickUp = () => closePickupPanelPath();
  const closeDropOff = () => closeDropOffPanelPath();

  const postRequest = useSelector((state) => state.truckAvailabilityForm.truckAvailability);
  const layer = props.layer ? props.layer + 1 : 2;

  const onChangeView = (subPanel: SubRoutes) => {
    if (subPanel === SubRoutes.PickUp) {
      openPanelPickUp();
    } else {
      openDropOff();
    }
  };

  const backRedirect = () => props.history.goBack();

  const openNewTruckPanel = () => {
    props.history.push({
      pathname: `${generatePath(props.baseUrl, {
        ...props.match.params,
        panels: SubRoutes.NewMyTruck,
      })}/`,
      search: props.location.search,
    });
  };

  const openEditTruckPanel = (truckId: string) => {
    props.history.push({
      pathname: `${generatePath(props.baseUrl, {
        ...props.match.params,
        panels: SubRoutes.EditMyTruck,
        truckID: truckId,
      })}/`,
      search: props.location.search,
    });
  };

  const setPostRequest = (form: PostTruckAvailabilityForm) => {
    dispatch(updatePostAvailabilityForm.action(form));
  };

  const isPrefilled = useSelector((state) => state.truckAvailabilityForm.isPrefilled);
  useEffect(() => {
    if (!isPrefilled) {
      dispatch(clearPostAvailabilityForm.action(undefined));
    } else {
      dispatch(setPostAvailabilityPrefilled.action(false));
    }
  }, []);

  const handlePickUpLocationChange = (selectedItem: LocationSuggestion) => {
    const location = locationMatchToLoadLocation(selectedItem);
    if (location) {
      dispatch(updatePostAvailabilityForm.action({ ...postRequest, origin: location }));
      closePanelPickUp();
    }
  };

  const handleDestinationLocationChange = (selectedItem: LocationSuggestion) => {
    const location = locationMatchToLoadLocation(selectedItem);
    if (location) {
      dispatch(updatePostAvailabilityForm.action({ ...postRequest, destination: location }));
      closeDropOff();
    }
  };

  const handlePickUpRecentLocationChange = (recentLocation: RecentLocation) => {
    const location = convertBaseLocationToLoadLocation(convertRecentLocationToBaseLocation(recentLocation));
    dispatch(updatePostAvailabilityForm.action({ ...postRequest, origin: location as OriginLocation }));
    closePanelPickUp();
  };

  const handleDestinationRecentLocationChange = (recentLocation: RecentLocation) => {
    const location = convertBaseLocationToLoadLocation(convertRecentLocationToBaseLocation(recentLocation));
    dispatch(updatePostAvailabilityForm.action({ ...postRequest, destination: location as LoadLocation }));
    closeDropOff();
  };

  const handlePickUpHomeSuggestionChange = (homeLocation: HomeLocation) => {
    dispatch(updatePostAvailabilityForm.action({ ...postRequest, origin: homeLocation }));
    closePanelPickUp();
  };

  const handleDestinationHomeSuggestionChange = (homeLocation: HomeLocation) => {
    dispatch(updatePostAvailabilityForm.action({ ...postRequest, destination: homeLocation }));
    closeDropOff();
  };

  const handleDestinationStatesChange = (states: string[]) => {
    dispatch(
      updatePostAvailabilityForm.action({
        ...postRequest,
        destination: {
          type: LocationType.STATE,
          states: states,
        },
      })
    );
  };

  const handleCountryChange = (value: CountriesWithAnywhere) => {
    const newLocation = loadLocationFromCountrySelection(value);
    dispatch(updatePostAvailabilityForm.action({ ...postRequest, destination: newLocation }));
    closeDropOff();
  };

  const handleSelectTruck = (truck: UserTruck) => {
    setPostRequest({ ...postRequest, truck: truck, weight: truck.defaultWeight, length: truck.defaultLength });
  };

  const handleCloseAfterTruckSubmit = () => {
    props.history.push({
      pathname: `${generatePath(props.baseUrl, {
        ...props.match.params,
        filter: props.match.params.filter,
        subroutes: props.match.params.subroutes,
        postID: props.match.params.postID,
        panels: undefined,
      })}/`,
      search: props.location.search,
    });
  };

  const handleCloseTruckInUsePopup = () => {
    props.history.push({
      pathname: `${generatePath(props.baseUrl, {
        ...props.match.params,
        filter: props.match.params.filter,
        subroutes: undefined,
        postID: undefined,
        panels: undefined,
      })}/`,
      search: props.location.search,
    });
  };

  return (
    <UnitedRoute baseUrl={props.baseUrl}>
      <NestedRoute path={'/:subroutes(add-post)'}>
        <PostTruckForm
          onClose={props.onClose ?? props.history.goBack}
          changeView={onChangeView}
          postRequest={postRequest}
          updatePostRequest={setPostRequest}
          layer={props.layer}
          offset={props.offset}
        />
      </NestedRoute>
      <NestedRoute path={'/:subroutes(edit-post)/:postID'}>
        <EditPostedTruckForm
          onClose={props.onClose || props.history.goBack}
          changeView={onChangeView}
          postRequest={postRequest}
          updatePostRequest={setPostRequest}
          postID={toNumber(props.match.params.postID)}
        />
      </NestedRoute>
      <NestedRoute path={'/:subroutes(add-post|edit-post)/:postID?/:panels(drop-off)'}>
        <LocationPickerPanel
          onClose={closeDropOff}
          onSelectionChange={handleDestinationLocationChange}
          onRecentLocationsChange={handleDestinationRecentLocationChange}
          locationType={LocationTypes.DropOff}
          onHomeLocationChange={handleDestinationHomeSuggestionChange}
          onCurrentLocationChange={handleDestinationHomeSuggestionChange}
          layer={layer}
          offset={1}
          onStatesChange={handleDestinationStatesChange}
          location={postRequest.destination}
          onCountryChange={handleCountryChange}
        />
      </NestedRoute>
      <NestedRoute path={'/:subroutes(add-post|edit-post)/:postID?/:panels(pick-up)'}>
        <LocationPickerPanel
          onClose={closePanelPickUp}
          onSelectionChange={handlePickUpLocationChange}
          onRecentLocationsChange={handlePickUpRecentLocationChange}
          locationType={LocationTypes.Pickup}
          onHomeLocationChange={handlePickUpHomeSuggestionChange}
          onCurrentLocationChange={handlePickUpHomeSuggestionChange}
          layer={layer}
          offset={1}
          location={postRequest.origin}
          locationTypes={[RecentLocationType.City, RecentLocationType.ZipCode]}
        />
      </NestedRoute>
      <NestedRoute path={'/:subroutes(add-post|edit-post)/:postID?/:panels(dates)'}>
        <Dates
          backRedirect={backRedirect}
          offset={1}
          layer={layer}
          updateFormState={setPostRequest}
          formState={postRequest}
        />
      </NestedRoute>
      <NestedRoute path={'/:subroutes(add-post|edit-post)/:postID?/:panels(truck)'}>
        <MyTruck
          layer={layer}
          onClose={backRedirect}
          openSecondaryPanelWithId={openEditTruckPanel}
          openSecondaryPanel={openNewTruckPanel}
          setCurrentTruck={handleSelectTruck}
          isSelect={true}
        />
      </NestedRoute>
      <NestedRoute path={'/:subroutes(add-post|edit-post)/:postID?/:panels(new-my-truck)'}>
        <AddTruck
          layer={props.layer ? props.layer + 2 : 3}
          onClose={props.history.goBack}
          onCloseAfterSubmit={handleCloseAfterTruckSubmit}
          onSubmit={handleSelectTruck}
        />
      </NestedRoute>
      <NestedRoute path={'/:subroutes(add-post|edit-post)/:postID?/:panels(edit-my-truck)'}>
        <EditTruck
          layer={props.layer ? props.layer + 2 : 3}
          onClose={props.history.goBack}
          onCloseAfterSubmit={handleCloseAfterTruckSubmit}
          truckId={props.match.params.truckID}
          onSubmit={handleSelectTruck}
          onCloseTruckInUsePopup={handleCloseTruckInUsePopup}
        />
      </NestedRoute>
    </UnitedRoute>
  );
};

export const CreateNewTruckPost = withRouter(NewTruckPost);

import React, { Component, ComponentClass, Ref } from 'react';

import ReactResizeDetector from 'react-resize-detector';

export const withResizeDetectorAndRef = <Props extends {}>(WrappedComponent: ComponentClass<Props>) => {
  type ComposedComponentInstance = InstanceType<typeof WrappedComponent>;
  type WrapperComponentPropsWithForwardedRef = Props & {
    forwardedRef: Ref<ComposedComponentInstance>;
  };

  class ResizeDetectorHOC extends Component<WrapperComponentPropsWithForwardedRef> {
    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        <ReactResizeDetector handleHeight={true} handleWidth={true}>
          {/*We need a cast because:
            https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32355
            https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046 */}
          <WrappedComponent ref={forwardedRef} {...(rest as Props)} />
        </ReactResizeDetector>
      );
    }
  }

  return React.forwardRef<ComposedComponentInstance, Props>((props, ref) => {
    return <ResizeDetectorHOC {...props} forwardedRef={ref} />;
  });
};

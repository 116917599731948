import { addRadius, createLoadSearchMetadata, isLocationCountryOrAnywhere } from '@common/helper';
import { KeysSortByDeadhead, KeysSortByPickupDate } from '@common/helper/SortByKeys';
import {
  ANYWHERE_LOCATION,
  CompanyFilterType,
  Load,
  LoadSearchRequest,
  LocationType,
  OriginLocation,
  UserDataCompany,
} from '@common/model';

export const getSortByMatchingCompanySearchOrigin = (origin: OriginLocation) => {
  if (origin.type === LocationType.STATE || isLocationCountryOrAnywhere(origin)) {
    return {
      field: KeysSortByPickupDate.field,
      direction: KeysSortByPickupDate.direction,
    };
  } else {
    return {
      field: KeysSortByDeadhead.field,
      direction: KeysSortByDeadhead.direction,
    };
  }
};

export const convertLoadToCompanySearchRequest = (load: Load | undefined): LoadSearchRequest | undefined => {
  if (!load) {
    return undefined;
  }
  const companyIDs = load.poster?.id ? [load.poster?.id] : undefined;
  return addRadius({
    origin: ANYWHERE_LOCATION as OriginLocation,
    destination: ANYWHERE_LOCATION,
    company: {
      types: CompanyFilterType.All,
      name: load.poster?.name,
      companyIds: companyIDs,
    },
    pickupDates: [],
    metadata: createLoadSearchMetadata({ sortBy: KeysSortByPickupDate }),
  });
};

export const convertCompanyDataCompanySearchRequest = (
  company: UserDataCompany | undefined
): LoadSearchRequest | undefined => {
  if (!company) {
    return undefined;
  }
  const companyIDs = company?.id ? [company?.id] : undefined;

  return {
    origin: ANYWHERE_LOCATION as OriginLocation,
    destination: ANYWHERE_LOCATION,
    company: {
      types: CompanyFilterType.All,
      name: company.name,
      companyIds: companyIDs,
    },
    pickupDates: [],
    metadata: createLoadSearchMetadata({ sortBy: KeysSortByPickupDate }),
  };
};

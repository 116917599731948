import { AxiosRequestConfig } from 'axios';
import { Observable } from 'rxjs';

import { Api, ApiResponse123, ApiService } from '@common/api';
import { configureApi, defaultMemberApiHeaders, updateMemberApiHeaders } from '@webApi/ApiServiceHelper';

/**
 * API Service for our own API that never sends authentication headers,
 * even when user is signed in. Useful for 'isolating' some api requests so
 * that issues with the user's logged-in state (such as cookie contamination),
 * do not interfere with the request.
 */
export class AnonymousMemberApiService implements Api {
  private api: Api;
  constructor() {
    this.api = new ApiService('/api', { ...defaultMemberApiHeaders, '123LB-Omit-Auth': '1' }, configureApi);
  }

  get$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    return this.api.get$(url, params, config);
  };

  post$ = <T>(url: string, params: {} | null, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    return this.api.post$(url, params, config);
  };

  put$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    return this.api.put$(url, params, config);
  };

  delete$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    return this.api.delete$(url, params, config);
  };

  patch$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    return this.api.patch$(url, params, config);
  };

  mergePatch$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    return this.api.mergePatch$(url, params, config);
  };
}

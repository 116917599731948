export enum PhoneVerificationMethod {
  TextMessage = 'Sms',
  VoiceMessage = 'Call',
}

export interface CreatePhoneVerificationResponse {
  expiresIn: number;
  phoneGUID: string;
}

export enum VerificationPointsTaskStatus {
  NONE = 'None',
  NOT_STARTED = 'NotStarted',
  NOT_COMPLETED = 'NotCompleted',
  COMPLETED = 'Completed',
}

export enum VerificationPointsTask {
  PRIMARY_PHONE = 'primaryPhone',
  PROFILE = 'profile',
  PROFILE_AVATAR = 'avatar',
  LOCATION = 'location',
  TRUCK = 'truck',
  COMPANY_TYPE = 'companyType',
  DOCUMENT_TOOL_REVIEW = 'documentToolReviewed',
  MPG = 'mpg',
  DOT_NUMBER = 'dotNo',
}

export interface VerificationPointsTaskDisplayStatus {
  primaryPhoneHideCompleted?: boolean;
  profileHideCompleted?: boolean;
  locationHideCompleted?: boolean;
  truckHideCompleted?: boolean;
  companyTypeHideCompleted?: boolean;
  documentToolReviewHideCompleted?: boolean;
  mpgHideCompleted?: boolean;
  avatarHideCompleted?: boolean;
  dotNo?: boolean;
}

export enum NumberCategory {
  Primary = 'Primary',
  Mobile = 'Mobile',
  CompanyVerifiedPhone = 'CompanyVerifiedPhone',
}

export const VERIFICATION_POINTS_TOTAL_TASKS_NUMBER = 8;

export type VerificationPointsTasksStatuses = Record<VerificationPointsTask, VerificationPointsTaskStatus>;

export type VerificationPointsTasksStatusesPartial = Partial<VerificationPointsTasksStatuses>;
export type VerificationPointsTasksStatusesResponse = VerificationPointsTasksStatusesPartial &
  Partial<VerificationPointsTaskDisplayStatus>;

export type VerificationPointsReviewData = Partial<Record<VerificationPointsTask, string>>;

import React, { Component } from 'react';

import { StyledActionChipGreen, StyledActionChipLight } from '@component/chip/ChipStyle';

interface ComponentProps {
  id: string;
  text: string;
  onClick?: () => void;
  type: ChipTypes;
  style?: React.CSSProperties;
}

export enum ChipTypes {
  Light = 'Light',
  Green = 'Green',
}

export class ActionChip extends Component<ComponentProps> {
  render() {
    switch (this.props.type) {
      case ChipTypes.Light:
        return (
          <StyledActionChipLight
            style={this.props.style}
            id={this.props.id}
            label={this.props.text}
            onClick={this.props.onClick}
          />
        );
      case ChipTypes.Green:
        return (
          <StyledActionChipGreen
            style={this.props.style}
            id={this.props.id}
            label={this.props.text}
            onClick={this.props.onClick}
          />
        );
      default:
        return (
          <StyledActionChipGreen
            style={this.props.style}
            id={this.props.id}
            label={this.props.text}
            onClick={this.props.onClick}
          />
        );
    }
  }
}

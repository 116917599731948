import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { QuoteState } from '@common/redux/epic/QuoteEpic';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupNoButton, PopupOkButton, PopupYesButton } from '@component/popup/PopupButtons';
import { PopupSizes, PopupTracking } from '@component/popup/PopupTrackingContext';
import { T, t } from '@translate';
import { AppConfig } from '@util/AppConfig';

const PopupLink = styled(Link)`
  word-break: break-word;
`;
const SingleTextLine = styled.div`
  margin-bottom: 25px;
`;

const BoldSingleTextLine = styled.div`
  margin-bottom: 25px;
  font-weight: bold;
`;

export const showQuoteResponsePopup = (quote: QuoteState, popupContext: PopupTracking, visitLink: string) => {
  const { openingPopup, closingPopup } = popupContext;
  const requestStatus = quote.postQuoteResponse?.success;
  const popupTitle = requestStatus
    ? t(T.findLoads_findLoads_popUps_sentRequest_title)
    : t(T.findLoads_findLoads_popUps_notSentRequest_title);
  const errorMessage = quote.postQuoteResponse?.error?.message;
  const errorContent = (
    <>
      {errorMessage ?? t(T.error_generic_api_message)}
      <br />
      {t(T.findLoads_findLoads_popUps_notSentRequest_pleaseVisitContent)}
      <br />
      <PopupLink to={visitLink} target={'blank'}>
        {`${AppConfig.LBConfig.ASPRoot}${visitLink}`}
      </PopupLink>
    </>
  );
  const popupContent = requestStatus ? t(T.findLoads_findLoads_popUps_sentRequest_content) : errorContent;
  openingPopup({
    body: (
      <BasePopup title={popupTitle} buttons={[<PopupOkButton testID={'ok'} key={'ok'} action={closingPopup} />]}>
        {popupContent}
      </BasePopup>
    ),
    width: PopupSizes.SMALL,
    isDismissible: true,
    hasCloseButton: true,
  });
};

export const showUnBookPopup = (setStep: () => void, popupContext?: PopupTracking) => {
  if (popupContext) {
    const { openingPopup, closingPopup } = popupContext;
    openingPopup({
      body: (
        <BasePopup
          title={t(T.findLoads_findLoads_popUps_unBookLoad_title)}
          buttons={[
            <PopupNoButton testID={'no'} key={'no'} action={closingPopup} />,
            <PopupYesButton
              testID={'yes'}
              key={'yes'}
              action={() => {
                setStep();
                popupContext?.closingPopup();
              }}
            />,
          ]}
        >
          <>
            <SingleTextLine>{t(T.findLoads_findLoads_popUps_unBookLoad_content_warning)}</SingleTextLine>
            <BoldSingleTextLine>{t(T.findLoads_findLoads_popUps_unBookLoad_content_text1)}</BoldSingleTextLine>
            <SingleTextLine>{t(T.findLoads_findLoads_popUps_unBookLoad_content_text2)}</SingleTextLine>
            {t(T.findLoads_findLoads_popUps_unBookLoad_content_text3)}
          </>
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  }
};

export const showGetPaidFasterInfoPopup = (popupContext?: PopupTracking) => {
  if (popupContext) {
    const { closingPopup, openingPopup } = popupContext;
    openingPopup({
      body: (
        <BasePopup
          title={t(T.findLoads_findLoads_popUps_paidFaster_title)}
          buttons={[<PopupOkButton testID={'ok'} key={'ok'} action={closingPopup} />]}
        >
          <>
            {t(T.findLoads_findLoads_popUps_paidFaster_content_warning)} <br />
            <br /> {t(T.findLoads_findLoads_popUps_paidFaster_content_text)}
          </>
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  }
};

export const showFuelAdvanceInfoPopup = (popupContext?: PopupTracking) => {
  if (popupContext) {
    const { closingPopup, openingPopup } = popupContext;
    openingPopup({
      body: (
        <BasePopup
          title={t(T.findLoads_findLoads_popUps_fuelAdvanced_title)}
          buttons={[<PopupOkButton testID={'ok'} key={'ok'} action={closingPopup} />]}
        >
          <>
            {t(T.findLoads_findLoads_popUps_fuelAdvanced_content_warning)}
            <br />
            <br /> {t(T.findLoads_findLoads_popUps_fuelAdvanced_content_variant_1)} <br />
            {t(T.findLoads_findLoads_popUps_fuelAdvanced_content_variant_2)} <br />
            {t(T.findLoads_findLoads_popUps_fuelAdvanced_content_variant_3)} <br />
            <br /> {t(T.findLoads_findLoads_popUps_fuelAdvanced_content_text)}
          </>
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  }
};

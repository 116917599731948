import { cloneDeep, concat, isEqual, toUpper, uniqWith } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { iif as if$, merge as merge$, Observable, of as of$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { ApiResponse123 } from '@common/api';
import { BaseState, createResponseAction, ResponseAction } from '@common/redux/Base';

export const defaultRequestConstants: Readonly<FetchRequestConstants> = {
  limit: {
    default: 20,
    appending: 10,
  },
  offsetIncrement: 10,
};
interface FetchEntriesAction<Request> extends Action {
  request?: Request;
}

interface FetchRequestConstants {
  limit: {
    default: number;
    appending: number;
  };
  offsetIncrement: number;
}

export interface PaginatedRequest {
  offset: number;
  limit: number;
  fields?: string;
}

export interface PaginatedListState<Entry, FetchRequest = PaginatedRequest> extends BaseState {
  fetchRequest: FetchRequest;
  // entries undefined: no request ever sent or error (see didLoadingFail)
  // entries empty: user has no entries
  entries?: Entry[];
  didLoadingFail: boolean;
  isLoadingMore: boolean;
  isRefreshing: boolean;
  isLastResult: boolean;
}

interface PaginatedListReducerConfig<
  Entry,
  FetchRequest extends { limit: number; offset: number },
  FetchResponse,
  StoreState,
> {
  reducerKey: string;
  getPaginatedListStateFromStoreState?: (store: StoreState) => PaginatedListState<Entry, FetchRequest>;
  requestConstants?: FetchRequestConstants;
  getEntriesFromResponse: (response: FetchResponse) => Entry[];
  areEntriesEqual: (entry1: Entry, entry2: Entry) => boolean;
  transformFetchedEntries?: (entries: Entry[]) => Entry[];
  defaultEntries?: Entry[];
}
/**
 *
 * Creates a reducer that can be used with the react-native flatlist or PaginatedListView to give the infinite scrolling effect
 *
 * @param config.reducerKey Key used to prefix action names
 *
 * @param config.getPaginatedListStateFromStoreState Retrieves the PaginatedListState from the broader store state. If your redux state is structured like:
 * reduxState: {
 *   ...
 *   storeStateForEpicYoureWorkingOn: {
 *     ...some stuff...
 *     storeOfPaginatedList: PaginatedListState<...>
 *   }
 * }
 * your function would tell PaginatedListReducer how to retrieve storeOfPaginatedList from the broader store state.
 * yourReduxState.storeStateForEpicYoureWorkingOn.storeOfPaginatedList
 *
 * @param config.requestConstants Pagination constants
 *
 * @param config.getEntriesFromResponse function to retrieve the actual array of results from the response returned by the server
 * so if you expect a response object like:
 * {
 *  fancyMetadataString: string;
 *  results: Entry[];
 *  someOtherObject: ...;
 * }
 * from the server, this function would just take in an object of type FetchResponse and return
 * response.results
 *
 * @param config.areEntriesEqual Determines whether two Entries are equivalent or not
 *
 * @param config.transformFetchedEntries Optional - transformation to apply to Entries returned from the server, for instance if you
 * want to add a 'time fetched' to each entry
 *
 * @param config.defaultEntries Optional - The default value when fetching new entries
 */
//@FIXME: Complexity in this function is near the limit, so abstract it if any more work needs to be done here.
export const createPaginatedListReducer = <
  Entry,
  FetchRequest extends { limit: number; offset: number },
  FetchResponse,
  StoreState,
  ReducerState extends PaginatedListState<Entry, FetchRequest>,
>({
  reducerKey,
  getPaginatedListStateFromStoreState = (store) => (store as any)[reducerKey],
  requestConstants = defaultRequestConstants,
  getEntriesFromResponse,
  areEntriesEqual,
  transformFetchedEntries,
  defaultEntries,
}: PaginatedListReducerConfig<Entry, FetchRequest, FetchResponse, StoreState>) => {
  const actionKey = createActionKey(reducerKey);
  // Actions
  const actionTypes = {
    FETCH_ENTRIES: `${actionKey}FETCH_ENTRIES`,
    FETCH_MORE_ENTRIES: `${actionKey}FETCH_MORE_ENTRIES`,
    ENTRIES_FETCHED: createEntriesFetchedActionType(actionKey),
    LAST_RESULT_REACHED: `${actionKey}LAST_RESULT_REACHED`,
  };
  const actions = {
    fetchEntries: (request?: FetchRequest): FetchEntriesAction<FetchRequest> => ({
      type: actionTypes.FETCH_ENTRIES,
      request: request,
    }),
    fetchMoreEntries: (): Action => ({
      type: actionTypes.FETCH_MORE_ENTRIES,
    }),
    fetchEntriesResponse: createResponseAction<FetchResponse>(actionTypes.ENTRIES_FETCHED),
  };

  // Reducer
  const paginatedListReducer = (state: ReducerState, action: Action): ReducerState => {
    switch (action.type) {
      case actionTypes.FETCH_ENTRIES: {
        const request = (action as FetchEntriesAction<FetchRequest>).request;
        const isRefreshing = !request;
        return {
          ...state,
          isLoading: true,
          isRefreshing: isRefreshing,
          didLoadingFail: false,
          isLastResult: false,
          entries: isRefreshing ? state.entries : defaultEntries, // keep old results if refreshing.
          fetchRequest: request ?? { ...state.fetchRequest, limit: requestConstants.limit.default, offset: 0 },
        };
      }
      case actionTypes.FETCH_MORE_ENTRIES: {
        return {
          ...state,
          isLoadingMore: true,
        };
      }
      case actionTypes.ENTRIES_FETCHED: {
        const responseAction = action as ResponseAction<FetchResponse>;
        const response = responseAction.response;
        if (!isEqual(responseAction?.fetchData, state.fetchRequest)) {
          return state;
        }
        // treat 204 as success - MOB-6102
        if (response.error && response.error.httpStatus === 204) {
          return {
            ...state,
            isLoading: false,
            isRefreshing: false,
            isLoadingMore: false,
            entries: state.entries ?? [],
            didLoadingFail: false,
            isLastResult: true,
          };
        }
        if (!response.success || !response.payload) {
          return {
            ...state,
            isLoading: false,
            // if this is a fetch from nothing, mark that it failed so we can
            // display an error screen.
            didLoadingFail: !state.entries,
            isRefreshing: false,
            isLoadingMore: false,
          };
        }
        let receivedEntries = getEntriesFromResponse(response.payload);
        if (transformFetchedEntries) {
          receivedEntries = transformFetchedEntries(receivedEntries);
        }
        const newEntries = state.isRefreshing
          ? receivedEntries
          : uniqWith(concat(state.entries || [], receivedEntries), areEntriesEqual);
        return {
          ...state,
          entries: newEntries,
          isLoading: false,
          didLoadingFail: false,
          isRefreshing: false,
          isLoadingMore: false,
          isLastResult: receivedEntries.length < state.fetchRequest.limit,
        };
      }
      case actionTypes.LAST_RESULT_REACHED: {
        return {
          ...state,
          isLoading: false,
          isRefreshing: false,
          isLoadingMore: false,
          didLoadingFail: true,
          isLastResult: true,
        };
      }
      default:
        return state;
    }
  };

  // Epics
  const fetchEntriesEpic$ = (
    action$: ActionsObservable<Action>,
    state$: StateObservable<StoreState>,
    fetchEntries$: (request: FetchRequest) => Observable<ApiResponse123<FetchResponse>>
  ) =>
    action$.ofType(actionTypes.FETCH_ENTRIES).pipe(
      mergeMap$((action: FetchEntriesAction<FetchRequest>) => {
        const request = action.request ?? getPaginatedListStateFromStoreState(state$.value).fetchRequest;
        return fetchEntries$(request).pipe(
          map$((response) =>
            response.result(
              (data) =>
                actions.fetchEntriesResponse(
                  {
                    success: true,
                    payload: data,
                  },
                  request
                ),
              (error) =>
                actions.fetchEntriesResponse(
                  {
                    success: false,
                    error: error,
                  },
                  request
                )
            )
          )
        );
      })
    );

  const fetchMoreEntriesEpic$ = (
    action$: ActionsObservable<Action>,
    state$: StateObservable<StoreState>,
    fetchEntries$: (request: FetchRequest) => Observable<ApiResponse123<FetchResponse>>
  ) =>
    action$.ofType(actionTypes.FETCH_MORE_ENTRIES).pipe(
      mergeMap$(() => {
        const state = getPaginatedListStateFromStoreState(state$.value);
        state.fetchRequest = cloneDeep(state.fetchRequest);
        const { fetchRequest, isLastResult } = state;
        fetchRequest.limit = requestConstants.limit.appending;
        if (fetchRequest.offset === 0) {
          fetchRequest.offset = Math.max(requestConstants.limit.default, 0);
        } else {
          fetchRequest.offset = Math.max(fetchRequest.offset + requestConstants.offsetIncrement, 0);
        }
        return if$(
          () => isLastResult,
          of$({ type: actionTypes.LAST_RESULT_REACHED }),
          fetchEntries$(fetchRequest).pipe(
            map$((response) =>
              response.result(
                (data) =>
                  actions.fetchEntriesResponse(
                    {
                      success: true,
                      payload: data,
                    },
                    fetchRequest
                  ),
                (error) =>
                  actions.fetchEntriesResponse(
                    {
                      success: false,
                      error: error,
                    },
                    fetchRequest
                  )
              )
            )
          )
        );
      })
    );

  const createdMergedEpic$ = (
    fetchEntries$: (request: FetchRequest) => Observable<ApiResponse123<FetchResponse>>,
    action$: ActionsObservable<Action>,
    state$: StateObservable<StoreState>
  ) => {
    return merge$(
      fetchEntriesEpic$(action$, state$, fetchEntries$),
      fetchMoreEntriesEpic$(action$, state$, fetchEntries$)
    );
  };

  return {
    actionTypes: actionTypes,
    createMergedEpic$: createdMergedEpic$,
    actions: actions,
    reducer: paginatedListReducer,
  };
};

export const createActionKey = (reducerKey: string) => `${toUpper(reducerKey)}_`;

export const createEntriesFetchedActionType = (actionKey: string) => `${actionKey}ENTRIES_FETCHED`;

import { isNil, omitBy } from 'lodash';
import { generatePath } from 'react-router';

import { Routes } from '@/router/Routes';

export enum LoadStatus {
  Online = 'online',
  Offline = 'offline',
  Assigned = 'assigned',
  Delivered = 'delivered',
}

export enum PostType {
  Single = 'single',
}

export enum EditingModes {
  Clone = 'clone',
  Edit = 'edit',
}

export enum PostedLoadsPanelModals {
  SortBy = 'sortBy',
  Filter = 'filter',
  Status = 'status',
  PickUpDate = 'pickUpDate',
  View = 'view',
}

export enum FilterPanelPanelModals {
  PickUpLocation = 'pickUpLocation',
  PickUpDate = 'pickUpDate',
  DropOffLocation = 'dropOffLocation',
  DropOffDate = 'dropOffDate',
  TrailerType = 'trailerType',
  TrailerSpecification = 'trailerSpecification',
}

export enum PostLoadsPanelModals {
  PickUpLocation = 'pickUpLocation',
  PickUpDate = 'pickUpDate',
  PickUpTime = 'pickUpTime',
  DropOffLocation = 'dropOffLocation',
  DropOffDate = 'dropOffDate',
  DropOffTime = 'dropOffTime',
  TrailerType = 'trailerType',
  TrailerSpecification = 'trailerSpecification',
  RecentPost = 'recentPost',
  VisibilityRules = 'visibilityRules',
  EditRule = 'editRule',
}

enum PostingContactsModals {
  AddContact = 'add_contact',
  EditContact = 'edit_contact',
}

type Load = 'load';
type LoadMap = 'loadMap';
type PostingContacts = 'posting_contacts';

export interface PostLoadsRouteParams {
  actionType: LoadStatus | PostType;
  load: Load;
  loadID: string;
  loadMap: LoadMap;
  editing: EditingModes;
  postedPanelModals: PostedLoadsPanelModals;
  filterPanelModals: FilterPanelPanelModals;
  postPanelModals: PostLoadsPanelModals;
  ruleIndex?: string;
  postingContacts: PostingContacts;
  postingContactsModal: PostingContactsModals;
  contactID: string;
}

export const generateLoadPostingRoute = (matchParams: Partial<PostLoadsRouteParams>, baseUrl?: string) => {
  const params: { [paramName: string]: string | number | boolean } = omitBy(matchParams, isNil);
  if (params.loadID) {
    params.load = 'load';
  }
  return `${generatePath(baseUrl ?? Routes.LOADS_MY_LOADS_POST, params)}/`;
};

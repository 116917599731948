import React, { useEffect, useState } from 'react';

import { concat, filter, forEach, includes, map } from 'lodash';
import { useDispatch } from 'react-redux';

import { getBidsFilterOptions } from '@common/helper/BidsHelper';
import { BiddingRole, BidStatus } from '@common/model';
import { fetchBidSummaries } from '@common/redux/epic/bids/BidsEpic';
import { MultipurposeButton } from '@component/buttons';
import { Checkbox } from '@component/control/Checkbox';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { ActionButton, Panel, PANEL_SHADOW_BACKGROUND, PanelFooter, PanelHeader, PanelSize } from '@component/panel';
import { usePanelBackLocation } from '@component/panel/PanelHooks';
import { Text, TextStyle } from '@component/text/Text';
import { StyledGrid } from '@page/bids/style/BidsStyleHelper';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

const BidsFilterPanel: React.FC<{ layer: number; panelOffset: number }> = (props) => {
  const dispatch = useDispatch();
  const isCarrier = useIsCarrier();

  const selectedFilters = useSelector((state) => state.bids.bidSummaries.listAll.selectedFilters);
  const [filterState, setFilterState] = useState(selectedFilters ?? []);

  const filterOptions = getBidsFilterOptions(filterState ?? []);

  const closeLocation = usePanelBackLocation('/filter/');

  useEffect(() => {
    if (selectedFilters !== undefined) {
      setFilterState(selectedFilters);
    }
  }, [selectedFilters]);

  const closePanel = () => {
    closeLocation();
  };

  const onClear = () => setFilterState([]);

  const createHandleChange = (filterType: BidStatus) => () => {
    if (includes(filterState, filterType)) {
      setFilterState(filter(filterState, (status) => status !== filterType));
    } else {
      setFilterState(concat(filterState, filterType));
    }
  };

  const applyFilters = () => {
    const filtersForQuery: BidStatus[] | undefined = [];
    forEach(filterState, (filter) => filtersForQuery.push(filter));
    dispatch(fetchBidSummaries(isCarrier ? [BiddingRole.Carrier] : [BiddingRole.Broker], filtersForQuery));
    closeLocation();
  };

  return (
    <Panel
      id="bids_filter_panel"
      size={PanelSize.small}
      layer={props.layer + 1}
      offset={props.panelOffset}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        style={{ padding: '0 6px' }}
        label={t(T.common_filter)}
        hasCloseButton={true}
        onClose={() => closePanel()}
        actionElement={
          filterState.length > 0 ? <ActionButton onClick={onClear}>{t(T.common_clear)}</ActionButton> : undefined
        }
      ></PanelHeader>

      <StyledGrid>
        <Text id="filter_subtitle" textStyle={TextStyle.SubtitleRegular}>
          {t(T.common_bids_filterSubtitle)}
        </Text>
      </StyledGrid>

      {map(filterOptions, (filter) => (
        <Checkbox
          id={`checkbox_${filter.title}`}
          name={filter.title}
          handleChange={createHandleChange(filter.filterType)}
          isChecked={filter.isSelected}
          label={filter.title}
        ></Checkbox>
      ))}

      <PanelFooter>
        <MultipurposeButton
          id="apply_filters_button"
          variant={'contained'}
          text={filterState.length > 0 ? t(T.common_apply) : t(T.common_filter)}
          color={'primary'}
          onClick={applyFilters}
        />
      </PanelFooter>
    </Panel>
  );
};
export default BidsFilterPanel;

export const COUNTRY_CODE = '+1';

export const GENERAL_CONTACT_PHONE_NUMBER = `${COUNTRY_CODE}8778755301`;
export const GENERAL_CONTACT_PHONE_NUMBER_FORMATTED = '(877) 875-5301';
export const TRUCK_LOCATOR_ACCESS_REQUEST_EXTENSION = 3;

export const THUNDER_FUNDING_CONTACT_PHONE_NUMBER = `${COUNTRY_CODE}8778738357`;
export const RIG_NATION_CONTACT_PHONE_NUMBER = `${COUNTRY_CODE}8003365909`;
export const EVILSIZOR_CONTACT_PHONE_NUMBER = `${COUNTRY_CODE}3034822965`;
export const SUPPORT_EMAIL = 'support@123loadboard.com';

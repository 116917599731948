export interface RatingInfo {
  id: number;
  label: string;
  value: string;
  type: string;
  maxValue: string;
}

export enum RatingInfoType {
  CREDIT_SCORE = 0,
  DAYS_TO_PAY = 1,
  DAYS_TO_PAY_60 = 2,
  DAYS_TO_PAY_90 = 3,
  TRADE_EXPERIENCES = 4,
  TRADE_EXPERIENCES_60 = 5,
  TRADE_EXPERIENCES_90 = 6,
  LAST_PAYMENT_DATE = 7,
}

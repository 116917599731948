import React, { useEffect, useMemo, useState } from 'react';

import { toString } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Table, TableBody, TableCell } from '@material-ui/core';

import { CarrierDetailsTabs, isPhoneVerified, setOpenCarrierDetailsTabs } from '@/reduxStore/epic/TruckEpic';
import { NO_INFORMATION_SYMBOL, NO_INFORMATION_SYMBOL_LONG } from '@common/formatter';
import { formatDateMMDDYYYY, formatPhoneNumber } from '@common/helper';
import { getConversationStatusForTracking } from '@common/helper/ConversationHelper';
import { getMCLink } from '@common/info';
import { Flag } from '@common/model';
import { fetchCompaniesProfileAvatar } from '@common/redux/epic/AvatarEpic';
import { fetchCompaniesAvatar } from '@common/redux/epic/CompanyAvatarEpic';
import {
  ContactInfo,
  fetchBrokerInfo,
  fetchCarrierInfo,
  getCarrierRating,
  getCompanyAuthority,
  getCompanyAuthorityLicensing,
  getCompanyDetails,
  getCompanyInsurance,
} from '@common/redux/epic/CompanyEpic';
import { getCarrierRiskAssessment } from '@common/redux/epic/MyCarrierPortalEpic';
import { PhoneNumber } from '@component/contact';
import { defaultBorder } from '@component/expansionPanel/ExpansionPanelStyle';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { usePanelBackLocation } from '@component/panel/PanelHooks';
import { Authority } from '@component/panels/companyDetails/Authority';
import { CarrierHistoryStatesMap } from '@component/panels/companyDetails/CarrierHistoryStatesMap';
import { CarrierRatingExpansionPanel } from '@component/panels/companyDetails/CarrierRatingExpansionPanel';
import { Insurance } from '@component/panels/companyDetails/Insurance';
import { RiskAssessmentExpansionPanel } from '@component/panels/companyDetails/myCarrierPortal/RiskAssessmentExpansionPanel';
import {
  MCS150,
  renderCarrier,
  renderCompany,
  renderDotNumber,
  RenderPhoneNumber,
  showDotVerificationPopup,
  showPhoneVerificationPopup,
} from '@component/panels/truckLocator/carrierDetails/CarrierDetailsData';
import {
  CarrierDetailsColumn,
  CarrierDetailsContent,
  RowDetails,
} from '@component/panels/truckLocator/carrierDetails/CarrierDetailsPanelStyles';
import { isPanelSingleColumn, singleDoubleColumnSelect } from '@component/panelSizeDetector/PanelSizeDetector';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useIsMobileView, useSelector } from '@util/hooks';
import { useContentHeightMeasurer } from '@util/hooks/UseContentHeightMeasurer';
import { createCallLoadTrackData, track } from '@util/trackers/123lbTracker';

import { getContactInfoDetails, getStatusColor } from './ContactInfoDetails';
import {
  ContactAvatarAndName,
  ContactInfoColumn,
  ContactInfoLink,
  ContactInfoText,
  Container,
  getContactInitials,
  getLoadPosterName,
  ImagePlaceholder,
  SectionTitle,
  ShowPhoneButton,
} from './ContactInfoSections';

interface ContactInfoDataProps {
  companyId?: string;
  contactInfo?: ContactInfo;
  isViewingBroker: boolean;
  onPhoneClicked?: () => void;
}

interface ComponentProps {
  layer: number;
}

interface RouteParams {
  conversationID: string;
  contactID: string;
}

const StyledCarrierDetailsColumn = styled(CarrierDetailsColumn)`
  border-top: ${singleDoubleColumnSelect(defaultBorder, '0')};
`;

export const ConversationContactInfo: React.FC<ComponentProps> = (props) => {
  const { contactID, conversationID } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const closeInfoPanel = usePanelBackLocation('/usercontact/');
  const currentUserID = useSelector((state) => state.user.profile?.payload?.id);
  const currentCompanyID = useSelector((state) => state.user.profile?.payload?.companyId);
  const conversations = useSelector((state) => state.communication.conversations);
  const isLoadingConversations = useSelector((state) => state.communication.isLoadingConversations);
  const isMCPEnabled = useSelector((state) => state.settings.systemSetting[Flag.MyCarrierPortalBrokerEnabled].value);
  const contactInfo = useSelector((store) => store.contact.contacts.get(contactID));
  //Undefined means the info is not there yet (first load), so we check for !== false
  const isLoadingContact = useSelector((state) => state.contact.contacts.get(contactID)?.isLoading !== false);
  const { selectedCompany, authorities, licensing, insurance, isLoadingInsurance, isLoadingAuthority } = useSelector(
    (state) => state.companyState
  );

  const isMobileView = useIsMobileView();

  const conversationLoad = useMemo(() => {
    const conversation = conversations.get(conversationID);
    return conversation?.metadata?.load;
  }, [conversations]);

  const isViewingBrokerInfo =
    currentUserID !== conversationLoad?.poster?.id && currentCompanyID !== conversationLoad?.poster?.id;

  const { contentHeight: panelHeight, isMeasurementOver, Measurer } = useContentHeightMeasurer();
  const openTabs = useSelector((state) => state.truckState.openCarrierDetailsTabs);

  useEffect(() => {
    dispatch(fetchBrokerInfo(contactID));
    dispatch(fetchCarrierInfo(contactID));
    if (isMCPEnabled && !isViewingBrokerInfo) {
      dispatch(getCarrierRiskAssessment(contactID));
    }
  }, [contactID]);

  useEffect(() => {
    if (selectedCompany?.usdotNumber) {
      dispatch(getCarrierRating(toString(selectedCompany.usdotNumber)));
    }
  }, [selectedCompany?.usdotNumber]);

  const handleTabChange = (tab: CarrierDetailsTabs, status: boolean) => {
    dispatch(setOpenCarrierDetailsTabs({ [tab]: status }));
  };

  let content: JSX.Element;
  if (isLoadingConversations || isLoadingContact) {
    content = (
      <Container style={{ justifyContent: 'center', alignItems: 'center' }}>
        <LoadingSpinner />
      </Container>
    );
  } else {
    content = (
      <ContactInfoContent
        userID={currentUserID}
        loadID={conversationLoad?.id ?? ''}
        isViewingBroker={isViewingBrokerInfo}
      />
    );
  }

  const renderContentViewCarrierInfo = (width: number) => {
    if (isLoadingConversations || isLoadingContact) {
      return (
        <Container style={{ justifyContent: 'center', alignItems: 'center' }}>
          <LoadingSpinner />
        </Container>
      );
    }
    return (
      <>
        <CarrierHistoryStatesMap isPanelSingleColumn={isPanelSingleColumn(width)} contactGuid={contactID} />
        <CarrierDetailsContent width={width} style={{ minHeight: panelHeight }}>
          <StyledCarrierDetailsColumn width={width}>{content}</StyledCarrierDetailsColumn>
          {isMobileView ? null : <Measurer />}
          <StyledCarrierDetailsColumn width={width} style={{ flex: 1 }}>
            <CarrierRatingExpansionPanel
              dotNumber={toString(selectedCompany?.usdotNumber)}
              isOpened={openTabs.CarrierRating}
              handleChange={() => handleTabChange(CarrierDetailsTabs.CarrierRating, !openTabs.CarrierRating)}
            />
            {!isViewingBrokerInfo ? <RiskAssessmentExpansionPanel contactGuid={contactID} /> : null}
            <Authority
              mcNumber={selectedCompany?.mcNumber}
              usdotNumber={selectedCompany?.usdotNumber}
              authorities={authorities}
              licensing={licensing}
              isOpened={openTabs.Authority}
              handleChange={() => handleTabChange(CarrierDetailsTabs.Authority, !openTabs.Authority)}
              isLoading={isLoadingAuthority}
            />
            <Insurance
              insurance={insurance}
              isOpened={openTabs.Company}
              handleChange={() => handleTabChange(CarrierDetailsTabs.Company, !openTabs.Company)}
              isLoading={isLoadingInsurance}
            />
          </StyledCarrierDetailsColumn>
        </CarrierDetailsContent>
      </>
    );
  };

  return (
    <Panel
      id="contact_info_header"
      size={isViewingBrokerInfo ? PanelSize.small : PanelSize.medium}
      offset={2}
      layer={props.layer}
      contentHeightFill={isMobileView ? undefined : !isMeasurementOver}
      backgroundColor={isViewingBrokerInfo ? PANEL_SHADOW_BACKGROUND : undefined}
    >
      {({ width }: { width: number }) => (
        <>
          <PanelHeader
            label={
              getLoadPosterName(contactInfo?.broker?.firstName, contactInfo?.broker?.lastName) ??
              contactInfo?.carrier?.name
            }
            hasCloseButton={true}
            onClose={() => closeInfoPanel()}
          />
          {isViewingBrokerInfo ? content : renderContentViewCarrierInfo(width)}
        </>
      )}
    </Panel>
  );
};

const ContactInfoContent: React.FC<{
  userID: string | undefined;
  loadID: string;
  isViewingBroker: boolean;
}> = (props) => {
  const { contactID, conversationID } = useParams<RouteParams>();
  const contactInfo = useSelector((store) => store.contact.contacts.get(contactID));
  const company = useSelector((store) => store.companyState.selectedCompany);
  const conversations = useSelector((state) => state.communication.conversations);
  const conversationMessages = useSelector((state) => state.communication.conversationMessages.get(conversationID));
  const history = useHistory();
  const dispatch = useDispatch();

  const onPhoneClicked = () => {
    const conversationStatus = getConversationStatusForTracking(conversationMessages);
    const currentURL = `${history.location.pathname}${history.location.search}`;
    // this call won't show up in reactotron for some reason (any tel: link seems to disconnect reactotron)
    // so if you need to debug, use the browser console network tab
    track(createCallLoadTrackData(props.loadID, currentURL, conversationID, conversationStatus));
  };

  const chatContactInfo = useMemo(() => {
    const conversation = conversations.get(conversationID);
    const contactUser = conversation?.users.filter((user) => user.userId !== props.userID)[0];
    return contactUser;
  }, [conversationID, conversations]);

  useEffect(() => {
    if (chatContactInfo?.company) {
      dispatch(getCompanyDetails(chatContactInfo.company.companyId));
    }
    if (chatContactInfo?.company) {
      dispatch(getCompanyAuthority(chatContactInfo.company.companyId));
      dispatch(getCompanyAuthorityLicensing(chatContactInfo.company.companyId));
      dispatch(getCompanyInsurance(chatContactInfo.company.companyId));
    }
  }, [chatContactInfo?.company]);

  if (!contactID) {
    return null;
  }

  return (
    <Container>
      <ContactInfoData
        companyId={company?.id}
        contactInfo={contactInfo}
        isViewingBroker={props.isViewingBroker}
        onPhoneClicked={onPhoneClicked}
      />
      <LoadPosterCompanyData
        contactInfo={contactInfo}
        companyId={company?.id}
        isViewingBroker={props.isViewingBroker}
      />
    </Container>
  );
};

export const ContactInfoData: React.FC<ContactInfoDataProps> = ({ contactInfo, onPhoneClicked, isViewingBroker }) => {
  const [isShowingPhone, setIsShowingPhone] = useState(false);
  const contactInfoDetails = isViewingBroker ? contactInfo?.broker : contactInfo?.carrier;
  const userAvatar = useSelector(
    (state) => state.avatar.companiesProfileAvatarMap.get(contactInfoDetails?.id ?? '')?.companiesProfileAvatar
  );
  const dispatch = useDispatch();
  const popupContext = usePopup();

  const displayInfo = getContactInfoDetails(contactInfoDetails, isViewingBroker);
  const contactName = isViewingBroker
    ? getLoadPosterName(contactInfo?.broker?.firstName, contactInfo?.broker?.lastName)
    : contactInfo?.carrier?.name;

  useEffect(() => {
    if (contactInfoDetails?.id) {
      dispatch(fetchCompaniesProfileAvatar(contactInfoDetails.id));
    }
  }, [contactInfoDetails]);
  return (
    <Table>
      {isViewingBroker ? (
        <>
          <SectionTitle>{t(T.common_conversations_contactInfo_loadContact)}</SectionTitle>
          <ContactAvatarAndName
            contactName={contactName || NO_INFORMATION_SYMBOL_LONG}
            contactInitials={getContactInitials(isViewingBroker, contactInfo)}
            image={userAvatar}
          />
          <ContactInfoColumn
            label={t(T.common_conversations_contactInfo_phoneNumber)}
            value={
              isShowingPhone ? (
                <PhoneNumber
                  id="contact_phone_number"
                  phoneNumberString={formatPhoneNumber(displayInfo.phone)}
                  phoneNumberValue={displayInfo.phone}
                  onClick={onPhoneClicked}
                />
              ) : (
                <ShowPhoneButton onClick={() => setIsShowingPhone(true)}>
                  {t(T.common_conversations_contactInfo_showPhone)}
                </ShowPhoneButton>
              )
            }
          />
        </>
      ) : (
        <RowDetails>
          <TableCell colSpan={2}>
            {renderCarrier('carrier', false, userAvatar, contactName)}
            {
              <RenderPhoneNumber
                id={'phone'}
                onClick={showPhoneVerificationPopup(popupContext)}
                rawPhoneNumber={displayInfo.phone}
                isPhoneVerified={isPhoneVerified(contactInfo?.carrier?.phoneVerificationStatus)}
                analyticsActionSuffix={'Messages - contact info'}
              />
            }
          </TableCell>
        </RowDetails>
      )}
    </Table>
  );
};

const LoadPosterCompanyData: React.FC<ContactInfoDataProps> = ({ companyId, contactInfo, isViewingBroker }) => {
  const companyAvatar = useSelector(
    (state) => state.companyAvatar.companiesAvatarMap.get(companyId ?? '')?.companiesAvatar
  );

  const popupContext = usePopup();
  const dispatch = useDispatch();
  const contactInfoDetails = contactInfo?.broker ?? contactInfo?.carrier;
  const displayInfo = getContactInfoDetails(contactInfoDetails, isViewingBroker);

  useEffect(() => {
    if (!companyAvatar && companyId) {
      dispatch(fetchCompaniesAvatar(companyId));
    }
  }, [companyAvatar, companyId]);

  const isContactInfoVerified =
    contactInfo?.broker?.isCarrierIdentityVerified || contactInfo?.carrier?.isCarrierIdentityVerified;

  // if the carrier (without loadposter permission) is trying to view the broker's carrier info
  // it will receive a permission denied error, so contactInfo.carrier will be undefined
  const shouldDisplayBothRolesInfo =
    contactInfo?.carrier && contactInfo?.carrier.docketNumber !== contactInfo?.broker?.docketNumber;
  return (
    <>
      {renderCompany(
        'company',
        displayInfo.companyName ?? NO_INFORMATION_SYMBOL_LONG,
        companyAvatar ? companyAvatar : <ImagePlaceholder />,
        isContactInfoVerified
      )}
      <Table>
        <TableBody>
          <RowDetails>
            <TableCell>
              {renderDotNumber(
                'dot',
                showDotVerificationPopup(popupContext),
                displayInfo.usDotNumber,
                isContactInfoVerified
              )}
            </TableCell>
            <TableCell>
              <MCS150
                content={
                  contactInfo?.carrier?.mcs150FormDate
                    ? formatDateMMDDYYYY(contactInfo?.carrier?.mcs150FormDate)
                    : NO_INFORMATION_SYMBOL
                }
                labelStyle={{ marginBottom: 8, color: Color.GRAY_11 }}
              />
            </TableCell>
          </RowDetails>

          <RowDetails>
            <TableCell>
              {/* BROKER */}
              <ContactInfoColumn
                label={t(T.common_conversations_contactInfo_mc)}
                value={
                  displayInfo.mcNumber !== NO_INFORMATION_SYMBOL_LONG ? (
                    <ContactInfoLink link={getMCLink(displayInfo.mcNumber)}>{displayInfo.mcNumber}</ContactInfoLink>
                  ) : (
                    NO_INFORMATION_SYMBOL_LONG
                  )
                }
              />
            </TableCell>
          </RowDetails>

          {/* CARRIER */}
          {shouldDisplayBothRolesInfo ? (
            <RowDetails>
              <TableCell>
                <ContactInfoColumn
                  label={t(T.common_conversations_contactInfo_mc)}
                  value={
                    contactInfo?.carrier?.docketNumber !== NO_INFORMATION_SYMBOL_LONG ? (
                      <ContactInfoLink link={getMCLink(contactInfo?.carrier?.docketNumber ?? '')}>
                        {contactInfo?.carrier?.docketNumber}
                      </ContactInfoLink>
                    ) : (
                      NO_INFORMATION_SYMBOL_LONG
                    )
                  }
                />
                <ContactInfoColumn
                  label={t(T.common_conversations_contactInfo_authorityList)}
                  value={
                    <ContactInfoText style={{ color: getStatusColor(contactInfo?.carrier?.commonAuthority ?? '') }}>
                      {contactInfo?.carrier?.commonAuthority ?? NO_INFORMATION_SYMBOL_LONG}
                    </ContactInfoText>
                  }
                />
              </TableCell>
            </RowDetails>
          ) : null}

          <RowDetails>
            {!isViewingBroker ? (
              <>
                <TableCell>
                  <ContactInfoColumn
                    label={t(T.common_conversations_contactInfo_powerUnits)}
                    value={
                      <ContactInfoText style={{ color: Color.GRAY_6 }}>
                        {contactInfo?.carrier?.truckCount ?? NO_INFORMATION_SYMBOL_LONG}
                      </ContactInfoText>
                    }
                  />
                </TableCell>
                <TableCell>
                  <ContactInfoColumn
                    label={t(T.common_conversations_contactInfo_usCanInspections)}
                    value={
                      <ContactInfoText style={{ color: Color.GRAY_6 }}>
                        {contactInfo?.carrier?.totalFmcsaInspections ?? NO_INFORMATION_SYMBOL_LONG}
                      </ContactInfoText>
                    }
                  />
                </TableCell>
              </>
            ) : null}
          </RowDetails>
        </TableBody>
      </Table>
    </>
  );
};

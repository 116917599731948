import React, { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { CommunicationHandler } from '@/CommunicationHandler';
import { ReverseGeolocationResponse } from '@/reduxStore/epic/LocationEpic';
import { setPageHidden, setUserIdle } from '@/reduxStore/epic/UserActivityEpic';
import { updateSearchFormData } from '@/reduxStore/reducer/LoadSearchFormReducer';
import { currentDateIfExpired, HomeLocation } from '@common/helper';
import { useAlertPolling } from '@common/helper/AlertPolling';
import { valueForOriginLocation } from '@common/helper/LoadTextFormater';
import {
  BiddingRole,
  LoadLocation,
  LoadLocationCity,
  LoadSearchRequest,
  LocationType,
  OriginLocation,
} from '@common/model';
import { getBiddingSummary } from '@common/redux/epic/bids/BidsEpic';
import { getCompanyInfo } from '@common/redux/epic/CompanyEpic';
import { fetchAllSearches } from '@common/redux/epic/loadSearch/LoadSearchEpic';
import { fetchLoadSearchSettings } from '@common/redux/epic/LoadSearchSettingsEpic';
import { fetchAlertSettings, syncAllSystemSettings } from '@common/redux/epic/SettingsEpic';
import { fetchUnreadTruckAlertsCount } from '@common/redux/epic/TruckAlertsEpic';
import { fetchUser, fetchUserAlerts } from '@common/redux/epic/UserEpic';
import { fetchMembershipsData, fetchMembershipType } from '@common/redux/epic/UserPlanManagementEpic';
import { useDidUpdate } from '@common/util/hooks';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { useGetDefaultLoadboard } from '@page/settings/PrivateNetworkHelper';
import { useUserActivityManager } from '@util/helper/UserActivityManager';
import { onPageVisibilityStateChange, useAppIdle, useAppVisibilityChange, useSelector } from '@util/hooks';
import { useOpenedTabsControl } from '@util/hooks/OpenedTabsControl';

export const AuthenticatedInitialLoading: React.FC = () => {
  const dispatch = useDispatch();
  const isCarrier = useIsCarrier();

  const isUserIdle = useSelector((state) => state.userActivity.isUserIdle);
  const isProfileFetched = useSelector((state) => state.user.profileFetchedAt !== undefined);

  useUserActivity();
  useOpenedTabsControl();

  useGetDefaultLoadboard();

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchAlertSettings());
    dispatch(fetchUserAlerts());
    dispatch(fetchLoadSearchSettings());
    dispatch(fetchUnreadTruckAlertsCount());
    dispatch(fetchMembershipType());
    dispatch(getCompanyInfo());
    dispatch(fetchMembershipsData());
    dispatch(syncAllSystemSettings());
  }, []);

  useEffect(() => {
    if (isProfileFetched) {
      dispatch(getBiddingSummary(isCarrier ? BiddingRole.Carrier : BiddingRole.Broker));
    }
  }, [isProfileFetched]);

  useAlertPolling(!isUserIdle);

  useAppVisibilityChange((isVisible) => {
    onPageVisibilityStateChange(
      isVisible,
      () => {
        dispatch(setPageHidden(false));
        dispatch(fetchAlertSettings());
        dispatch(syncAllSystemSettings(true));
      },
      () => dispatch(setPageHidden(true))
    );
  });

  useUpdateSearchFormData();

  return (
    <>
      <CommunicationHandler />
    </>
  );
};

const useUserActivity = () => {
  const dispatch = useDispatch();
  const isIdle = useSelector((state) => state.userActivity.isUserIdle);
  const isBroker = !useIsCarrier();

  const onUserInactive = useCallback(() => {
    dispatch(setUserIdle(true));
  }, [dispatch]);

  const userActivityManager = useUserActivityManager(onUserInactive, 30000);

  useEffect(() => {
    if (!isBroker) {
      userActivityManager.setUserActivityInterval();
    }
    return () => {
      userActivityManager?.pauseUserActivityInterval();
    };
  }, [isBroker]);

  useAppIdle(() => {
    if (isIdle) {
      dispatch(setUserIdle(false));
      userActivityManager.setUserActivityInterval();
    }
    userActivityManager.setLastActivityStamp(Date.now());
  }, [isIdle]);
};

const useUpdateSearchFormData = () => {
  const dispatch = useDispatch();

  const lastSearch = useSelector((state) => state.loadSearch.lastSearchRequest);
  const homeLocation = useSelector((state) => state.loadSearchSettings.homeLocation);
  const reverseGeolocation = useSelector((state) => state.locationState.reverseGeolocation);
  const recentSearch = useSelector(
    (state) => state.loadSearch.searchesState.searches.entities[state.loadSearch.searchesState.searches.ui[0]]
  );
  const isPrefilled = useSelector((state) => state.loadSearchForm.isPrefilled);
  const selectedSearch = useSelector((state) => state.loadSearch.selectedSearch ?? state.loadSearch.lastSearchRequest);

  useEffect(() => {
    if (!recentSearch) {
      dispatch(fetchAllSearches());
    }
  }, []);

  useEffect(() => {
    if (!isPrefilled) {
      const origin = getOriginLocation(selectedSearch, reverseGeolocation, homeLocation);
      dispatch(
        updateSearchFormData({
          ...selectedSearch,
          origin: origin ?? selectedSearch.origin,
          pickupDates: currentDateIfExpired(selectedSearch.pickupDates, false),
        })
      );
    }
  }, [lastSearch]);

  useEffect(() => {
    if (!isPrefilled && recentSearch) {
      const origin = getOriginLocation(recentSearch.search, reverseGeolocation, homeLocation);
      dispatch(
        updateSearchFormData({
          ...recentSearch.search,
          origin: origin ?? recentSearch.search.origin,
          pickupDates: currentDateIfExpired(recentSearch.search.pickupDates, false),
        })
      );
    }
  }, [recentSearch]);

  const updateLocation = (origin: OriginLocation | undefined, destination?: LoadLocation) => {
    if (origin) {
      dispatch(
        updateSearchFormData({
          ...selectedSearch,
          origin: origin,
          destination: destination ?? selectedSearch.destination,
        })
      );
    }
  };

  useDidUpdate(() => {
    if (!isPrefilled && !valueForOriginLocation(selectedSearch.origin)) {
      updateLocation(getOriginFromReverseGeolocation(reverseGeolocation));
    }
  }, [reverseGeolocation]);

  useDidUpdate(() => {
    if (!isPrefilled && !valueForOriginLocation(selectedSearch.origin)) {
      updateLocation(getOriginFromHomeLocation(homeLocation));
    }
  }, [homeLocation]);
};

const getOriginLocation = (
  searchRequest: LoadSearchRequest,
  reverseGeolocation: ReverseGeolocationResponse | undefined,
  homeLocation: HomeLocation | undefined
) => {
  if (searchRequest.origin && valueForOriginLocation(searchRequest.origin)) {
    return searchRequest.origin;
  }
  return getOriginFromReverseGeolocation(reverseGeolocation) ?? getOriginFromHomeLocation(homeLocation);
};

const getOriginFromHomeLocation = (homeLocation: HomeLocation | undefined): OriginLocation | undefined => {
  if (homeLocation) {
    const { city, states } = homeLocation;
    if (city || (states && states.length > 0)) {
      return {
        type: LocationType.CITY,
        city: city,
        states: states,
      } as LoadLocationCity;
    }
  }
  return undefined;
};

const getOriginFromReverseGeolocation = (
  reverseGeolocation: ReverseGeolocationResponse | undefined
): OriginLocation | undefined => {
  if (reverseGeolocation && reverseGeolocation.success && reverseGeolocation.payload) {
    const { states, city } = reverseGeolocation.payload;
    return {
      type: LocationType.CITY,
      city: city,
      states: states,
    } as LoadLocationCity;
  }
  return undefined;
};

import React, { useEffect } from 'react';

import styled from 'styled-components';

import { Button, IconButton } from '@material-ui/core';
import { TypographyStyle } from '@material-ui/core/styles/createTypography';

import { Subtitle, Title } from '@component/expansionPanel/ExpansionPanelStyle';
import { Loader } from '@component/loader';
import { useBackPanelContext } from '@component/panel/BackPanelContext';
import { FixedHeader } from '@component/scrollView/FixedArea';
import { Color } from '@style/Color';
import { ElementSize, FontSize, Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { t, T } from '@translate';

import { default as CloseIcon } from './close.svg';
import { default as BackIcon } from './icons_back.svg';
import { default as RefreshIcon } from './refresh.svg';

export interface ComponentProps {
  hasCloseButton?: boolean;
  hasBackButton?: boolean;
  hasRefreshButton?: boolean;
  hasSubtitle?: boolean;
  label?: string | JSX.Element;
  subtitle?: JSX.Element | null | string;
  actionElement?: JSX.Element;
  actionText?: string;
  handleClick?: () => void;
  isActionLoading?: boolean;
  onClose?: () => void;
  onRefresh?: () => void;
  actionTextStyle?: TypographyStyle;
  backgroundColor?: string;
  shouldCloseOnEsc?: boolean;
  style?: React.CSSProperties;
  subheader?: JSX.Element;
}

type HeaderBackIconProps = Pick<ComponentProps, 'onClose' | 'hasBackButton' | 'hasCloseButton'>;
type ActionBarProps = Pick<
  ComponentProps,
  'actionElement' | 'handleClick' | 'actionText' | 'actionTextStyle' | 'isActionLoading'
>;
type HeaderRefreshIconProps = Pick<ComponentProps, 'onRefresh' | 'hasRefreshButton'>;
type HeaderProps = ComponentProps & ThemeProps;
type Props = ComponentProps;

const Header = withTheme()(styled.div`
  display: flex;
  background-color: ${(props: HeaderProps) =>
    props.backgroundColor ? props.backgroundColor : props.theme.palette.background.white};
  min-height: ${ElementSize.HeaderHeight}px;
  height: ${ElementSize.HeaderHeight}px;
  align-items: center;
  padding: 0 ${Spacing.ElementPaddingHorizontal}px;
  border-bottom: ${(props: ThemeProps) => `1px solid ${props.theme.palette.background.smoke}`};
`);

const ActionSection = withTheme()(styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: ${(props: ActionBarProps & ThemeProps) =>
    props.actionTextStyle ? props.actionTextStyle.fontSize : props?.theme?.text.lightText.fontSize};
  font-weight: ${(props: ActionBarProps & ThemeProps) =>
    props.actionTextStyle ? props.actionTextStyle.fontWeight : 'normal'};
  line-height: ${(props: ActionBarProps & ThemeProps) =>
    props.actionTextStyle ? props.actionTextStyle.lineHeight : 'auto'};
  color: ${(props: ActionBarProps & ThemeProps) => (props.actionTextStyle ? props.actionTextStyle.color : 'initial')};
  cursor: pointer;
`);

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: ${ElementSize.Icon}px;
  min-height: ${ElementSize.Icon}px;
`;

export const ActionButton = styled(Button)`
  && {
    font-size: ${FontSize.Content}px;
    color: ${Color.LIGHT_BLACK};
    text-transform: none;
  }
`;

export const HeaderLabel = withTheme()(styled.h1`
  color: ${THEME.text.title.color};
  font-size: ${THEME.text.title.fontSize};
  font-weight: ${THEME.text.title.fontWeight};
  margin: 0;
  line-height: ${THEME.text.title.lineHeight};
  text-overflow: ellipsis;
  overflow: hidden;
`);

export const HeaderLabelWithSub = styled(Title)`
  color: ${THEME.text.title.color};
  font-size: ${THEME.text.title.fontSize};
  font-weight: ${THEME.text.title.fontWeight};
  margin: 0;
  line-height: 20px;
  max-width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const HeaderSubtitle = styled(Subtitle)`
  && {
    color: ${THEME.text.title.color};
    font-size: ${THEME.text.subtitle2Regular.fontSize};
    font-weight: ${THEME.text.subtitle2Regular.fontWeight};
    margin: 0;
    line-height: 20px;
    max-width: 240px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

// augmenting specificity with && to allow styling material-ui component
export const HeaderButton = withTheme()(styled(IconButton)`
  && {
    align-self: center;
    margin: 0;
    width: ${ElementSize.CheckboxArea}px;
    height: ${ElementSize.CheckboxArea}px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`);

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 6px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
`;

const HeaderContent = styled.div`
  overflow: hidden;
  margin-left: 6px;
`;

const HeaderBackIcon: React.FC<HeaderBackIconProps> = ({ onClose, hasBackButton, hasCloseButton }) => {
  if (hasCloseButton) {
    return (
      <HeaderButton id="close" onClick={onClose}>
        <img src={CloseIcon} alt={t(T.findLoads_loads_close)} />
      </HeaderButton>
    );
  }
  if (hasBackButton) {
    return (
      <HeaderButton id="back" onClick={onClose}>
        <img src={BackIcon} alt="left" />
      </HeaderButton>
    );
  }
  return null;
};

const HeaderRefreshIcon: React.FC<HeaderRefreshIconProps> = ({ onRefresh, hasRefreshButton }) => {
  if (hasRefreshButton) {
    return (
      <HeaderButton id="refresh" onClick={onRefresh} style={{ marginLeft: 'auto' }}>
        <img src={RefreshIcon} alt="refresh" />
      </HeaderButton>
    );
  }
  return null;
};

export const ActionBar: React.FC<ActionBarProps> = (props) =>
  props.isActionLoading ? (
    <Loader />
  ) : (
    <ActionSection id="action" onClick={props.handleClick} {...props}>
      {props.actionElement ? <Action id="action">{props.actionElement}</Action> : null}
      {props.actionText ? (
        <span style={{ marginRight: Spacing.ElementPaddingHorizontal }}>{props.actionText}</span>
      ) : null}
    </ActionSection>
  );
const getHeaderLabel = (props: Props) => {
  if (props.hasSubtitle) {
    return <HeaderLabelWithSub id="title">{props.label}</HeaderLabelWithSub>;
  } else {
    return <HeaderLabel id="title">{props.label}</HeaderLabel>;
  }
};
export const PanelHeader: React.FC<Props> = (props) => {
  const { isHighest } = useBackPanelContext();

  useEffect(() => {
    const handleEscButtonClick = (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }
      if (event.code === 'Escape' && isHighest && props.shouldCloseOnEsc) {
        props.onClose?.();
      }
    };
    document.addEventListener('keydown', handleEscButtonClick);
    return () => document.removeEventListener('keydown', handleEscButtonClick);
  }, [isHighest]);

  const headerLabel = typeof props.label === 'string' ? getHeaderLabel(props) : props.label;

  const headerSubtitle =
    typeof props.subtitle === 'string' ? <HeaderSubtitle>{props.subtitle}</HeaderSubtitle> : props.subtitle;
  return (
    <FixedHeader>
      <Header id="panel_header" {...props}>
        <HeaderBackIcon
          onClose={props.onClose}
          hasBackButton={props.hasBackButton}
          hasCloseButton={props.hasCloseButton}
        />
        {props.label || props.subtitle ? (
          <TitleContainer>
            {headerLabel}
            {headerSubtitle}
          </TitleContainer>
        ) : null}
        <HeaderContent style={{ flex: props.label || props.subtitle ? '' : 1 }}>{props.children}</HeaderContent>
        {props.actionText || props.actionElement ? (
          <ActionBar
            actionElement={props.actionElement}
            handleClick={props.handleClick}
            actionText={props.actionText}
            actionTextStyle={props.actionTextStyle ?? THEME.text.subtitleRegular}
            isActionLoading={props.isActionLoading}
          />
        ) : null}
        <HeaderRefreshIcon onRefresh={props.onRefresh} hasRefreshButton={props.hasRefreshButton} />
      </Header>
      {props.subheader}
    </FixedHeader>
  );
};

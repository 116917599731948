import { AxiosRequestConfig } from 'axios';
import { reduce } from 'lodash';
import { Observable, of as of$ } from 'rxjs';
import { mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';
import { addArchivingHeader } from '@common/helper/FlowIDHeaderHelper';
import {
  BrokerPaginationRequest,
  CarrierFeedback,
  CarrierRating,
  CarrierSearchRequest,
  CarrierSearchResponse,
  CarrierStatus,
  Company,
  CompanyAuthorityInfo,
  CompanyInsuranceInfo,
  CompanyLicensingInfo,
  CompanyUserDataPayload,
  CompanyUserDataQuery,
  CompanyVerificationDetailsResponse,
  CreditInfo,
  UserDataCompany,
} from '@common/model';
import { SearchDirectoryCompanyRequest, SearchDirectoryCompanyResponse } from '@common/model/SearchDirectory';
import { EmptyResponse } from '@common/redux/Base';

export interface CompanyUserData<T> {
  id: string;
  value: T;
}

export class CompanyClient extends BaseClient {
  getCompanyInfo$ = () => this.api.get$<Company>('/companies', {});
  getCompanyDetails$ = (id: string) => this.api.get$<UserDataCompany>(`/companies/${id}`, {});
  getCompanyAuthority$ = (id: string) => this.api.get$<CompanyAuthorityInfo>(`/companies/${id}/authority`, {});
  getCompanyAuthorityLicensing$ = (id: string) =>
    this.api.get$<{ authorityInfo: CompanyAuthorityInfo; licensingInfo: CompanyLicensingInfo }>(
      `/companies/${id}/authorityAndLicensing`,
      {}
    );
  getCompanyInsurance$ = (id: string) => this.api.get$<CompanyInsuranceInfo>(`/companies/${id}/insurance`, {});
  getCompanyCredit$ = (id: string) => this.api.get$<CreditInfo>(`/companies/${id}/credit`, {});
  getCompanyUserData = (query: CompanyUserDataQuery, fields: string = 'all', request?: BrokerPaginationRequest) =>
    this.api.get$<CompanyUserDataPayload>('/companies/search/userdata', { fields: fields, ...query, ...request });
  updateCompanyInfo$ = (companyInfo: Company) => {
    if (companyInfo.id && companyInfo.id.length > 0) {
      return this.api.put$<Company>(`/companies/${companyInfo.id}`, companyInfo);
    } else {
      return this.api.post$<Company>('/companies', companyInfo);
    }
  };
  favoriteCarriers$ = (carriers: Array<CompanyUserData<boolean>>) =>
    performMultiplePatches$(this.api, '/favoriteCarrier', carriers, createCompanyEndpoint);
  favoriteBrokers$ = (brokers: Array<CompanyUserData<boolean>>, archivingFlowID: string | undefined) =>
    performMultiplePatches$(
      this.api,
      '/favoriteBroker',
      brokers,
      createCompanyEndpoint,
      addArchivingHeader(archivingFlowID)
    );
  onboardedBrokers$ = (brokers: Array<CompanyUserData<boolean>>, archivingFlowID: string | undefined) =>
    performMultiplePatches$(
      this.api,
      '/onboarded',
      brokers,
      createCompanyEndpoint,
      addArchivingHeader(archivingFlowID)
    );
  hideCarriers$ = (carriers: Array<CompanyUserData<boolean>>) =>
    performMultiplePatches$(this.api, '/hiddenCarrier', carriers, createCompanyEndpoint);
  hideBrokers$ = (brokers: Array<CompanyUserData<boolean>>, archivingFlowID: string | undefined) =>
    performMultiplePatches$(
      this.api,
      '/hiddenBroker',
      brokers,
      createCompanyEndpoint,
      addArchivingHeader(archivingFlowID)
    );
  addNotes$ = (id: string, note: string) => this.api.patch$(createCompanyEndpoint(id), payload('/note', note));
  onboardCarrier$ = (carriers: Array<CompanyUserData<boolean>>) =>
    performMultiplePatches$(this.api, '/onboarded', carriers, createCarrierEndpoint);
  searchCarriers$ = (query: CarrierSearchRequest, request?: BrokerPaginationRequest) =>
    this.api.get$<CarrierSearchResponse>('/carriers/search', { ...query, ...request });
  updateCarrier$ = (id: string, status: CarrierStatus) =>
    this.api.mergePatch$<EmptyResponse>(`/carriers/${id}/userdata`, status);
  updateCarrierFeedback$ = (id: string, feedback: CarrierFeedback) =>
    this.api.mergePatch$<EmptyResponse>(createCarrierEndpoint(id), feedback);
  updateCarriers$ = (contactIds: string[], status: CarrierStatus) =>
    this.api.mergePatch$<EmptyResponse>('/carriers/userdata', { contactIds: contactIds, patchDocument: status });
  updateCarriersFeedback$ = (contactIds: string[], feedback: CarrierFeedback) =>
    this.api.mergePatch$<EmptyResponse>('/carriers/feedbacks', { contactIds: contactIds, patchDocument: feedback });
  getSearchDirectoriesCompanies$ = (fetchRequest: SearchDirectoryCompanyRequest) =>
    this.api.get$<SearchDirectoryCompanyResponse>('/companies/search/directories', fetchRequest);
  setDirectoriesCompanyViewedStatus$ = (id: string) =>
    this.api.post$<EmptyResponse>(`/companies/${id}/directories/viewed`, {});
  fetchVolpeInformationByDotNumber$ = (dotNumber: string) =>
    this.api.get$<CompanyVerificationDetailsResponse>(`/companies/docketNumberRecordsByUsDot/${dotNumber}`, {});
  fetchCarrierRating$ = (dotNumber: string) =>
    this.api.get$<CarrierRating>(`/companies/${dotNumber}/carrierSource/rating`, {});
  patchCompanyDetails$ = (id: string, onboardingUrl: string) =>
    this.api.mergePatch$<{}>(`/companies/${id}`, { onboardingUrl: onboardingUrl });
}

const createCompanyEndpoint = (id: string) => `/companies/${id}/userdata`;
const createCarrierEndpoint = (id: string) => `/carriers/${id}/feedbacks`;
const payload = (url: string, value: any) => [{ op: 'replace', path: url, value: value }];
const performMultiplePatches$ = (
  api: Api,
  path: string,
  companies: Array<CompanyUserData<any>>,
  endPoint: (id: string) => string,
  config?: AxiosRequestConfig
) => {
  return reduce(
    companies,
    (callStack: Observable<any>, company) => {
      return callStack.pipe(mergeMap$(() => api.patch$(endPoint(company.id), payload(path, company.value), config)));
    },
    of$(null) /* we start with an empty event, then iterate over all calls */
  );
};

import React from 'react';

import { isUndefined } from 'lodash';
import styled from 'styled-components';

import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import { THEME } from '@style/Theme';

interface Props {
  id: string;
  isValid: boolean;
  text?: React.ReactNode;
  style?: React.CSSProperties;
}

const CalculationText = styled.div`
  margin-bottom: ${Spacing.ScreenSide}px;
  margin-top: ${Spacing.ElementPaddingVertical}px;
  font-size: ${THEME.text.subtitle2Regular.fontSize};
  display: flex;
  justify-content: center;
  color: ${Color.GRAY_DARK};
  align-self: center;
`;

const ErrorCalculationText = styled(CalculationText)`
  color: ${Color.RED_APPLE};
`;

export const Calculation: React.FC<Props> = ({ id, text, isValid, style }) => {
  return !isUndefined(text) ? (
    isValid ? (
      <CalculationText id={id} style={style}>
        {text}
      </CalculationText>
    ) : (
      <ErrorCalculationText style={style}>{text}</ErrorCalculationText>
    )
  ) : null;
};

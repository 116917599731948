import React from 'react';

interface Props {
  id?: string;
}

// This error icon is a component because it may be used when there is
// no internet connection, in which case a request for the svg will fail
export const ErrorIcon: React.FC<Props> = ({ id }) => (
  <svg id={id} xmlns="http://www.w3.org/2000/svg" width="112.7" height="120.7">
    <path
      fill="#FF9D1F"
      d="M28 0h55a28 28 0 0 1 29 28v55a28 28 0 0 1-29 29H64l-7 9-8-9H28A28 28 0 0 1 0 83V28A28 28 0 0 1 28 0z"
    />
    <g fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3">
      <path strokeLinejoin="round" d="M56 65.2a.7.7 0 1 0 .8.8.7.7 0 0 0-.8-.8h0" />
      <path d="M56 59.3V44.7" />
      <path strokeLinejoin="round" d="M59.2 32a3.5 3.5 0 0 0-6.3 0L34.3 69.7A3 3 0 0 0 37 74h38a3 3 0 0 0 2.7-4.3Z" />
    </g>
  </svg>
);

import React, { createContext, useContext, useState } from 'react';

import ReactResizeDetector from 'react-resize-detector';

import { PanelWidth } from '@component/panel/PanelSize';

interface ResizerProps {
  width: number;
}

type Props = Pick<
  React.ComponentProps<typeof ReactResizeDetector>,
  'skipOnMount' | 'refreshMode' | 'refreshRate' | 'refreshOptions'
>;

interface ResizerContext {
  width: number;
}

export const RESIZE_REFRESH_RATE = 1000;

export const ResizeContext = createContext<ResizerContext>({ width: 0 });
export const useResizeContext = () => useContext(ResizeContext);

export const PanelSizeDetector: React.FC<Props> = ({ children, ...rest }) => {
  const [panelWidth, setPanelWidth] = useState(0);

  const handleResize = (width: number) => {
    setPanelWidth(width);
  };

  return (
    <ResizeContext.Provider value={{ width: panelWidth }}>
      <ReactResizeDetector {...rest} handleHeight={false} handleWidth={true} onResize={handleResize} />
      {children}
    </ResizeContext.Provider>
  );
};

export const isPanelSingleColumn = (panelWidth: number) => panelWidth < PanelWidth.medium - 1;

export const singleDoubleColumnSelect = (singleColumn: string, doubleColumn: string) => (props: ResizerProps) =>
  isPanelSingleColumn(props.width) ? singleColumn : doubleColumn;

import React from 'react';

import { connect } from 'react-redux';

import { LoadDetailsTabs } from '@/model';
import { StoreState } from '@/reduxStore/Store';
import { formatPrice } from '@common/helper';
import { Load } from '@common/model';
import { RouteInfo } from '@common/model/PCMiler';
import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import { defaultLoadInfo } from '@common/redux/epic/LoadInfoEpic';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import {
  ExpansionPanelDataWrapper,
  SpecialLink,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import THEME from '@style/Theme';
import { t, T } from '@translate';
import icons from '@util/iconsConstants';

import { default as GoogleMapIcon } from './google map.svg';
import { default as MileageIcon } from './mileage.svg';
import { default as NewTabIcon } from './new window.svg';
import { default as PCMilerIcon } from './pc miler.svg';

export enum AccordionInfoStatus {
  LOADING,
  AVAILABLE,
  UNAVAILABLE,
  NOT_PERMITTED,
}

interface ComponentProps {
  onMapClick: () => void;
  load: Load;
  handleChange(tab: LoadDetailsTabs, status: boolean): void;
  isOpened: boolean;
}

interface ReduxProps {
  routeInfo?: RouteInfo;
  status: AccordionInfoStatus;
}

type Props = ReduxProps & ComponentProps;

class Milage extends React.Component<Props> {
  openMap = () => {
    this.props.onMapClick();
  };

  openGoogleMap = () => {
    const { load } = this.props;
    let point1;
    let point2;
    if (load.destinationLocation && load.destinationLocation.geolocation) {
      const { latitude, longitude } = load.destinationLocation.geolocation;
      point1 = [latitude, longitude];
    }
    if (load.originLocation && load.originLocation.geolocation) {
      const { latitude, longitude } = load.originLocation.geolocation;
      point2 = [latitude, longitude];
    }
    if (point1 && point2) {
      const url = `https://www.google.com/maps/dir/${point2[0]},+${point2[1]}/${point1[0]},+${point1[1]}`;
      window.open(url, '_blank');
    }
  };

  handleTabClick = () => {
    this.props.handleChange(LoadDetailsTabs.Mileage, !this.props.isOpened);
  };

  render() {
    const { routeInfo, status } = this.props;
    return (
      <ExpansionPanel
        id="mileage"
        titleText={t(T.common_load_mi_maps_tolls)}
        subtitleText={t(T.common_load_Toll_Cost, { value: formatPrice(routeInfo ? routeInfo.toll : 0) })}
        icon={MileageIcon}
        isOpened={this.props.isOpened}
        handleClick={this.handleTabClick}
        isBlocked={!routeInfo}
        status={status}
        unlockFeaturePopupType={UnlockFeaturePopupType.MileageCalculator}
      >
        <ExpansionPanelDataWrapper>
          <SpecialLink id="pc_miler" theme={THEME} onClick={this.openMap}>
            <img id="icon" src={PCMilerIcon} className="icon" />
            <div id="title" className="text">
              {t(T.findLoads_loadDetails_pcMiler)}
            </div>
            <img id="arrow" src={icons.chevronRight} alt=">" className="arrow-icon" />
          </SpecialLink>
          <SpecialLink id="google_map" theme={THEME} onClick={this.openGoogleMap}>
            <img id="icon" src={GoogleMapIcon} className="icon" alt={'maps'} />
            <div id="title" className="text">
              {t(T.findLoads_loadDetails_googleMap)}
            </div>
            <img id="arrow" src={NewTabIcon} alt=">" className="arrow-icon" />
          </SpecialLink>
        </ExpansionPanelDataWrapper>
      </ExpansionPanel>
    );
  }
}

const mapStateToProps = (state: StoreState, props: ComponentProps): ReduxProps => {
  let status: AccordionInfoStatus = AccordionInfoStatus.UNAVAILABLE;
  const loadInfo = state.loadInfo.loadInfoMap.get(props.load.id) || defaultLoadInfo;
  const isUserAllowed = state.user.accesses.isMapTollsAvailable;
  if (!isUserAllowed) {
    status = AccordionInfoStatus.NOT_PERMITTED;
  } else {
    if (loadInfo.isLoadingRouteInfo) {
      status = AccordionInfoStatus.LOADING;
    } else if (loadInfo.routeInfo) {
      status = AccordionInfoStatus.AVAILABLE;
    }
  }
  return {
    routeInfo: loadInfo.routeInfo,
    status: status,
  };
};

export const Mileage = connect(mapStateToProps)(Milage);

import { clone, filter, findIndex, map } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { from as from$, merge as merge$, of as of$ } from 'rxjs';
import { flatMap as flatMap$, map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123 } from '@common/api';
import { DocumentsClient } from '@common/client/DocumentsClient';
import { ViewType } from '@common/helper/DocumentSortingHelper';
import {
  CustomFolder,
  CustomFoldersSort,
  Document,
  DocumentSendType,
  DocumentType,
  DocumentTypes,
  DownloadState,
  FolderIdentifier,
  MyDocumentsSendRequest,
} from '@common/model';
import { createResponseAction, ResponseAction } from '@common/redux/Base';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';
import { createReducer } from '@common/redux/ReduxHelper';
import { t, T } from '@translate';

import {
  createDefaultDocumentFilters,
  createDefaultDocumentListsState,
  createDocumentListsReducer,
  DocumentListsState,
  DOCUMENTS_STORE_KEY,
  DocumentsContext,
  DocumentsMetadata,
  DocumentsResponse,
  modifyAllDocumentLists,
  modifyAllDocumentListStates,
} from './DocumentListEpic';
import { createFoldersReducer, FoldersState, initialFoldersState, updateCustomFolder } from './FoldersEpic';

export const FOLDERS_STORE_KEY = 'folders';
const FOLDERS_REDUCER_KEY = `${DOCUMENTS_STORE_KEY}_${FOLDERS_STORE_KEY}`;

const UPDATE_SEND_DOCUMENTS_FORM = 'UPDATE_SEND_DOCUMENTS_FORM';
const SET_SHOW_BROKER_AS_POSSIBLE_RECIPIENT = 'SET_SHOW_BROKER_AS_POSSIBLE_RECIPIENT';
const SEND_DOCUMENTS = 'SEND_DOCUMENTS';
const CHANGE_DOCUMENT_NAME = 'CHANGE_DOCUMENT_NAME';
const CHANGE_DOCUMENT_NAME_FULFILLED = 'CHANGE_DOCUMENT_NAME_FULFILLED';
const FETCH_DOCUMENT_TYPES = 'FETCH_DOCUMENT_TYPES';
const DOCUMENT_TYPES_FETCHED = 'DOCUMENT_TYPES_FETCHED';
const DOCUMENTS_SENT = 'DOCUMENTS_SENT';
const UPDATE_FOLDER_SORTING = 'UPDATE_FOLDER_SORTING';
const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DOCUMENT_DELETED = 'DOCUMENT_DELETED';
const INCREMENT_LOAD_DOCUMENT_COUNT = 'INCREMENT_LOAD_DOCUMENT_COUNT';
const UPDATE_DOCUMENT_TYPE = 'UPDATE_DOCUMENT_TYPE';
export const DOCUMENT_TYPE_UPDATED = 'DOCUMENT_TYPE_UPDATED';
const UPDATE_DOCUMENT_FOLDER = 'UPDATE_DOCUMENT_FOLDER';
const UPDATE_DOCUMENT_IN_FOLDER = 'UPDATE_DOCUMENT_IN_FOLDER';
export const DOCUMENT_FOLDER_UPDATED = 'DOCUMENT_FOLDER_UPDATED';
const FETCH_TOTAL_DOCUMENT_COUNT = 'FETCH_TOTAL_DOCUMENT_COUNT';
const TOTAL_DOCUMENT_COUNT_FETCHED = 'TOTAL_DOCUMENT_COUNT_FETCHED';
const FETCH_FOLDER_BY_ID = 'FETCH_FOLDER_BY_ID';
const FOLDER_BY_ID_FETCHED = 'FOLDER_BY_ID_FETCHED';
const RESET_CURRENT_FOLDER = 'RESET_CURRENT_FOLDER';
const FETCH_DOCUMENT_FILE = 'FETCH_DOCUMENT_FILE';
const FETCH_CONVERSATION_DOCUMENT_FILE = 'FETCH_CONVERSATION_DOCUMENT_FILE';
const RESET_SEND_DOCUMENT_FORM = 'RESET_SEND_DOCUMENT_FORM';
export const DOCUMENT_FILE_FETCHED = 'DOCUMENT_FILE_FETCHED';
export const DOCUMENT_FILE_SAVED = 'DOCUMENT_FILE_SAVED';
export const DOCUMENT_FILE_FETCH_ERROR = 'DOCUMENT_FILE_FETCH_ERROR';
const SET_CREATE_DOCUMENT_CONFIG = 'SET_CREATE_DOCUMENT_CONFIG';
const SET_VIEW_TYPE = 'SET_VIEW_TYPE';
const FETCH_DOCUMENTS_COUNT = 'FETCH_DOCUMENTS_COUNT';
const DOCUMENTS_COUNT_FETCHED = 'DOCUMENTS_COUNT_FETCHED';
const SET_VIEW_DOCUMENT_FILE_DATA = 'SET_VIEW_DOCUMENT_FILE_DATA';
const SET_EXPORTING_DOCUMENT_ID = 'SET_EXPORTING_DOCUMENT_ID';

export enum RecipientType {
  Company = 'Company',
  ThunderFunding = 'ThunderFunding',
  Other = 'Other',
}

interface SubStoreState {
  documents: DocumentsState;
}

enum UpdateOperation {
  DISPLAY_NAME = 'DISPLAY_NAME',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  DOCUMENT_FOLDER = 'DOCUMENT_FOLDER',
}

export enum DocumentsUpdateType {
  DELETE,
  UPDATE_FOLDER,
}

interface UpdateFormAction extends Action {
  info: Partial<SendDocumentsForm>;
}

interface SetShowBrokerAsPossibleRecipientAction extends Action {
  shouldShow: boolean;
}

interface SendDocumentsAction extends Action {
  infoToSend: SendDocumentsForm;
}

interface ChangeDocNameAction extends Action {
  newName: string;
  id: string;
}

interface FolderSortingAction extends Action {
  sorting: CustomFoldersSort;
}
interface SetCreateDocumentConfigAction extends Action {
  config: CreateDocumentConfig;
}

type CacheFile = (name: string, file: string, encoding?: string) => Promise<boolean>;

type DocumentTypesResponseAction = ResponseAction<DocumentTypes>;

interface UpdateDocTypeAction extends Action {
  id: string;
  newType: string;
}

export interface DocumentFileFetchAction extends Action {
  data: DocumentFileData;
}

export interface DocumentFetchErrorAction extends Action {
  data: { id: string };
}

interface UpdateDocFolderAction extends Action {
  documentID: string;
  newFolder: FolderIdentifier;
}
interface UpdateDocInFolderAction extends Action {
  documentID: string;
  newFolderName: string;
}

interface DeleteDocumentAction extends Action {
  id: string;
}

interface GetFolderByLoadAction extends Action {
  guid: string;
}

interface SaveDocumentAction extends Action {
  id: string;
}

interface FetchDocumentFileAction extends Action {
  id: string;
  extension: string;
}
interface FetchConversationDocumentFileAction extends Action {
  conversationId: string;
  messageId: string;
  docId: string;
  extension: string;
}

interface LoadDocumentCountIncrementAction extends Action {
  increment: number;
}

interface SetViewTypeAction extends Action {
  viewType: ViewType;
}

interface SetViewDocumentFileData extends Action {
  fileData: DocumentFileData | undefined;
}

interface SetExportingDocumentIDAction extends Action {
  documentID: string | undefined;
}

type DocumentsSentResponseAction = ResponseAction<{}>;
type DocumentResponseAction = ResponseAction<Document>;
type TypesResponseAction = ResponseAction<DocumentTypes>;

export type DocumentDeletedResponseAction = ResponseAction<{ id: string }>;
type TotalDocumentCountResponseAction = ResponseAction<DocumentsResponse>;
type FolderByIDResponseAction = ResponseAction<CustomFolder>;
type DocumentsCountFetchedResponse = ResponseAction<DocumentsResponse>;

const foldersRedux = createFoldersReducer<SubStoreState>(
  FOLDERS_REDUCER_KEY,
  (store: SubStoreState) => store[DOCUMENTS_STORE_KEY][FOLDERS_STORE_KEY].customFolders
);

const documentLists = createDocumentListsReducer();

export const fetchDefaultFolders = foldersRedux.actions.fetchDefaultFolders;
export const fetchCustomFolders = foldersRedux.actions.fetchEntries;
export const fetchMoreCustomFolders = foldersRedux.actions.fetchMoreEntries;
export const createFolder = foldersRedux.actions.createFolder;
export const changeFolderName = foldersRedux.actions.changeFolderName;
export const deleteFolder = foldersRedux.actions.deleteFolder;
export const deleteLoadFolder = foldersRedux.actions.deleteLoadFolder;
export const deleteFolderResponse = foldersRedux.actions.deleteFolderResponse;
export const updateFolder = foldersRedux.actions.updateFolder;

/**
 * DocumentList reducer actions grouped by context
 */
export const DocumentListAction = (documentsContext: DocumentsContext) => documentLists.actionsLookup[documentsContext];

// DocumentList reducer actions mapped to proper context by prefixed context parameter
export const setFetchRequest = documentLists.actions.setFetchRequest;
export const clearDocuments = documentLists.actions.clearDocuments;
export const updateSortingOption = documentLists.actions.updateSortingOption;
export const updateFolderFilter = documentLists.actions.updateFolderFilter;
export const updateDateFilter = documentLists.actions.updateDateFilter;
export const updateTypeFilter = documentLists.actions.updateTypeFilter;
export const toggleSortOrder = documentLists.actions.toggleSortOrder;
export const resetDocumentFilters = documentLists.actions.resetDocumentFilters;
export const fetchEntriesResponse = documentLists.actions.fetchEntriesResponse;
export const fetchDocuments = documentLists.actions.fetchDocuments;
export const fetchMoreDocuments = documentLists.actions.fetchMoreDocuments;

export const updateSendDocumentsForm = (documents: Partial<SendDocumentsForm>): UpdateFormAction => ({
  type: UPDATE_SEND_DOCUMENTS_FORM,
  info: documents,
});

export const setShowBrokerAsPossibleRecipient = (shouldShow: boolean): SetShowBrokerAsPossibleRecipientAction => ({
  type: SET_SHOW_BROKER_AS_POSSIBLE_RECIPIENT,
  shouldShow: shouldShow,
});

export const incrementLoadDocumentCount = (increment: number): LoadDocumentCountIncrementAction => ({
  type: INCREMENT_LOAD_DOCUMENT_COUNT,
  increment: increment,
});
export const resetSendForm = (): Action => ({ type: RESET_SEND_DOCUMENT_FORM });

export const sendDocuments = (formInfo: SendDocumentsForm): SendDocumentsAction => ({
  type: SEND_DOCUMENTS,
  infoToSend: formInfo,
});

export const changeDocName = (id: string, newName: string): ChangeDocNameAction => ({
  type: CHANGE_DOCUMENT_NAME,
  id: id,
  newName: newName,
});
export const updateDocType = (id: string, type: string): UpdateDocTypeAction => ({
  type: UPDATE_DOCUMENT_TYPE,
  id: id,
  newType: type,
});

export const updateDocFolder = (documentID: string, newFolder: FolderIdentifier): UpdateDocFolderAction => ({
  type: UPDATE_DOCUMENT_FOLDER,
  documentID: documentID,
  newFolder: newFolder,
});

export const updateDocInFolder = (documentID: string, newFolderName: string): UpdateDocInFolderAction => ({
  type: UPDATE_DOCUMENT_IN_FOLDER,
  documentID: documentID,
  newFolderName: newFolderName,
});

export const deleteDocument = (id: string): DeleteDocumentAction => ({ type: DELETE_DOCUMENT, id: id });

export const fetchDocumentTypes = (): Action => ({ type: FETCH_DOCUMENT_TYPES });

export const getTotalDocumentCount = (): Action => ({
  type: FETCH_TOTAL_DOCUMENT_COUNT,
});

export const getFolderByLoad = (guid: string): GetFolderByLoadAction => ({
  type: FETCH_FOLDER_BY_ID,
  guid: guid,
});
export const resetCurrentFolder = () => ({
  type: RESET_CURRENT_FOLDER,
});

export const documentFileSaved = (id: string): SaveDocumentAction => ({ type: DOCUMENT_FILE_SAVED, id: id });

export const fetchDocumentFile = (id: string, extension: string): FetchDocumentFileAction => ({
  type: FETCH_DOCUMENT_FILE,
  id: id,
  extension: extension,
});

export const fetchConversationDocumentFile = (
  conversationId: string,
  messageId: string,
  docId: string,
  extension: string
): FetchConversationDocumentFileAction => ({
  type: FETCH_CONVERSATION_DOCUMENT_FILE,
  conversationId: conversationId,
  messageId: messageId,
  docId: docId,
  extension: extension,
});

export const documentFileFetched = (data: DocumentFileData): DocumentFileFetchAction => ({
  type: DOCUMENT_FILE_FETCHED,
  data: data,
});

export const updateFolderSortingOption = (sorting: CustomFoldersSort): FolderSortingAction => ({
  type: UPDATE_FOLDER_SORTING,
  sorting: sorting,
});

export const setCreateDocumentConfig = (config: CreateDocumentConfig): SetCreateDocumentConfigAction => ({
  type: SET_CREATE_DOCUMENT_CONFIG,
  config: config,
});

export const resetCreateDocumentConfig = () =>
  setCreateDocumentConfig({
    shouldDisableFolderSelect: false,
    presetFolder: undefined,
    shouldPushToAttachDocument: false,
  });

export const setViewType = (viewType: ViewType): SetViewTypeAction => ({
  type: SET_VIEW_TYPE,
  viewType: viewType,
});

export const fetchDocumentsCount = (): Action => ({
  type: FETCH_DOCUMENTS_COUNT,
});

export const setViewDocumentFileData = (fileData: DocumentFileData | undefined): SetViewDocumentFileData => ({
  type: SET_VIEW_DOCUMENT_FILE_DATA,
  fileData: fileData,
});

export const setExportingDocumentID = (documentID: string | undefined): SetExportingDocumentIDAction => ({
  type: SET_EXPORTING_DOCUMENT_ID,
  documentID: documentID,
});

export interface SendDocumentsForm {
  recipientName: string;
  recipientCompanyName: string;
  recipientEmail: string | undefined;
  recipientFax: string;
  documents: Document[];
  selectedRecipient: RecipientType;
}

export interface DocumentsUpdate {
  mapIsLoading: Map<DocumentsUpdateType, boolean>;
  updateResult: {
    updateType?: DocumentsUpdateType;
    updatedSuccessfully?: boolean;
    updateTime: number;
  };
}
export interface CachedImageMap {
  [id: string]: DownloadState | undefined;
}

interface DocumentFileData {
  id: string;
  file: string;
  extension: string;
}

export interface CreateDocumentConfig {
  shouldDisableFolderSelect: boolean;
  presetFolder?: FolderIdentifier;
  shouldPushToAttachDocument: boolean;
}

export interface DocumentsState {
  sendDocuments: {
    formInfo: SendDocumentsForm;
    isSending: boolean;
    shouldShowBrokerAsPossibleRecipient: boolean;
    wasSentSuccessfully?: boolean;
    timeSent?: number;
  };
  viewDocument: {
    isLoadingDocumentData: boolean;
    documentCache: CachedImageMap;
    // below fields are used only in MEM as it does
    // not have a file cache library
    documentFileData: DocumentFileData | undefined;
    viewDocumentFileData: DocumentFileData | undefined;
  };
  loadDetailsDocuments: {
    loadDocumentCount: number;
    isLoadingLoadFolder: boolean;
    loadFolderIdentifier?: CustomFolder;
  };
  documentTypes: {
    types: DocumentType[];
    isLoading: boolean;
  };
  createDocument: CreateDocumentConfig;
  documentLists: DocumentListsState;
  exportDocument: {
    isExporting: boolean;
    documentID?: string;
  };
  // updates ---
  documentsUpdate: DocumentsUpdate;
  docNameUpdated: {
    wasUpdateSuccessful?: boolean;
    updateTime?: number;
    isLoading: boolean;
  };
  typeUpdated?: {
    wasUpdateSuccessful: boolean;
    updateTime: number;
  };
  // --- updates
  [FOLDERS_STORE_KEY]: FoldersState;
  totalDocumentCount: number | undefined;
  viewType: ViewType;
  metadata?: DocumentsMetadata;
}

const initialState: DocumentsState = {
  sendDocuments: {
    isSending: false,
    formInfo: {
      recipientName: '',
      recipientCompanyName: '',
      recipientEmail: '',
      recipientFax: '',
      documents: [],
      selectedRecipient: RecipientType.ThunderFunding,
    },
    shouldShowBrokerAsPossibleRecipient: false,
  },
  viewDocument: {
    documentCache: {},
    isLoadingDocumentData: false,
    documentFileData: undefined,
    viewDocumentFileData: undefined,
  },
  loadDetailsDocuments: {
    loadDocumentCount: 0,
    isLoadingLoadFolder: false,
  },
  documentTypes: {
    types: [],
    isLoading: false,
  },
  createDocument: {
    shouldDisableFolderSelect: false,
    shouldPushToAttachDocument: false,
  },
  documentLists: createDefaultDocumentListsState(),
  exportDocument: {
    isExporting: false,
  },
  documentsUpdate: {
    mapIsLoading: new Map<DocumentsUpdateType, boolean>(),
    updateResult: {
      updateTime: Date.now(),
    },
  },
  docNameUpdated: {
    isLoading: false,
  },
  [FOLDERS_STORE_KEY]: initialFoldersState,
  totalDocumentCount: undefined,
  viewType: ViewType.Folders,
};

const documentListsReducer = (state: DocumentsState, action: Action): DocumentsState => {
  const modifiedDocumentContextsState = documentLists.reducer(state.documentLists, action);
  if (modifiedDocumentContextsState !== state.documentLists) {
    return {
      ...state,
      documentLists: modifiedDocumentContextsState,
    };
  } else {
    return state;
  }
};

const documentsFolderSubReducer = (state: DocumentsState, action: Action): DocumentsState => {
  const modifiedFoldersState = foldersRedux.reducer(state[FOLDERS_STORE_KEY], action);
  if (modifiedFoldersState !== state[FOLDERS_STORE_KEY]) {
    return {
      ...state,
      [FOLDERS_STORE_KEY]: modifiedFoldersState,
    };
  } else {
    return state;
  }
};

export const documentsReducer = createReducer(
  initialState,
  {
    [UPDATE_SEND_DOCUMENTS_FORM]: (state, action: UpdateFormAction) => {
      state.sendDocuments.formInfo = { ...state.sendDocuments.formInfo, ...action.info };
    },
    [SET_SHOW_BROKER_AS_POSSIBLE_RECIPIENT]: (state, action: SetShowBrokerAsPossibleRecipientAction) => {
      state.sendDocuments.shouldShowBrokerAsPossibleRecipient = action.shouldShow;
    },
    [SEND_DOCUMENTS]: (state) => {
      state.sendDocuments.isSending = true;
    },
    [RESET_SEND_DOCUMENT_FORM]: (state) => {
      state.sendDocuments.formInfo = initialState.sendDocuments.formInfo;
    },
    [FETCH_DOCUMENT_TYPES]: (state) => {
      state.documentTypes.isLoading = true;
    },
    [CHANGE_DOCUMENT_NAME]: (state) => {
      state.docNameUpdated.isLoading = true;
    },
    [UPDATE_DOCUMENT_IN_FOLDER]: (state, action: UpdateDocInFolderAction) => {
      const modifiedDocumentLists = modifyAllDocumentLists(state.documentLists, (documentList) =>
        editDocumentDetails(documentList, action.newFolderName, action.documentID, UpdateOperation.DOCUMENT_TYPE)
      );
      state.documentLists = modifiedDocumentLists;
    },
    [DOCUMENTS_SENT]: (state, action: DocumentsSentResponseAction) => {
      state.sendDocuments.isSending = false;
      state.sendDocuments.wasSentSuccessfully = action.response.success;
      state.sendDocuments.timeSent = Date.now();
      if (state.sendDocuments.wasSentSuccessfully) {
        state.sendDocuments.formInfo = initialState.sendDocuments.formInfo;
      }
    },
    [DOCUMENTS_COUNT_FETCHED]: (state, action: DocumentsCountFetchedResponse) => {
      if (action.response.success) {
        state.metadata = action.response.payload?.metadata;
      } else {
        state.metadata = undefined;
      }
    },
    [CHANGE_DOCUMENT_NAME_FULFILLED]: (state: DocumentsState, action: DocumentResponseAction) => {
      state.docNameUpdated = {
        wasUpdateSuccessful: action.response.success,
        updateTime: Date.now(),
        isLoading: false,
      };
      const changedDocument = action.response.payload;
      if (changedDocument) {
        state.documentLists = modifyAllDocumentLists(state.documentLists, (documentList) =>
          editDocumentDetails(
            documentList,
            changedDocument.displayName,
            changedDocument.id,
            UpdateOperation.DISPLAY_NAME
          )
        );
        state.sendDocuments.formInfo.documents = map(state.sendDocuments.formInfo.documents, (document) =>
          document.id === changedDocument.id ? changedDocument : document
        );
      }
    },
    [DOCUMENT_TYPE_UPDATED]: (state, action: DocumentResponseAction) => {
      let modifiedDocumentLists = state.documentLists;
      if (action.response.payload) {
        const documentType = action.response.payload.type;
        const documentId = action.response.payload.id;
        modifiedDocumentLists = modifyAllDocumentLists(state.documentLists, (documentList) =>
          editDocumentDetails(documentList, documentType, documentId, UpdateOperation.DOCUMENT_TYPE)
        );
      }
      state.typeUpdated = { wasUpdateSuccessful: action.response.success, updateTime: Date.now() };
      state.documentLists = modifiedDocumentLists;
    },
    [UPDATE_DOCUMENT_FOLDER]: (state) => {
      state.documentsUpdate.mapIsLoading.set(DocumentsUpdateType.UPDATE_FOLDER, true);
    },
    [DOCUMENT_FOLDER_UPDATED]: (state: DocumentsState, action: DocumentResponseAction) => {
      const updateDocumentFolderResponse = action.response;
      state.documentsUpdate = generateNewDocumentsUpdate(
        state.documentsUpdate.mapIsLoading,
        DocumentsUpdateType.UPDATE_FOLDER,
        updateDocumentFolderResponse.success
      );
      if (updateDocumentFolderResponse.success && updateDocumentFolderResponse.payload) {
        const newDocument = updateDocumentFolderResponse.payload;
        const modifiedDocumentListsState = modifyAllDocumentListStates(state.documentLists, (documentListState) => {
          const modifiedDocumentListState = clone(documentListState);
          const isFilteredForFolder =
            documentListState.fetchRequest.folder && documentListState.fetchRequest.folder.name !== t(T.common_all);
          if (isFilteredForFolder) {
            // if a folder filter is currently set, then the document having its folder changed
            // will no longer fall under that filter and should be removed from the list
            modifiedDocumentListState.entries = filter(
              documentListState.entries,
              (document: Document) => document.id !== newDocument.id
            );
            modifiedDocumentListState.fetchRequest.offset = documentListState.fetchRequest.offset - 1;
          } else {
            // if no folder filter is set, then update the document in the list
            const documentIndex = findIndex(
              documentListState.entries,
              (document: Document) => document.id === newDocument.id
            );
            if (documentIndex !== -1) {
              const modifiedDocumentList = clone(documentListState.entries);
              modifiedDocumentList[documentIndex] = newDocument;
              modifiedDocumentListState.entries = modifiedDocumentList;
            }
          }
          return modifiedDocumentListState;
        });
        state.documentLists = modifiedDocumentListsState;
      }
    },
    [DOCUMENT_TYPES_FETCHED]: (state, action: DocumentTypesResponseAction) => {
      if (action.response.payload && action.response.payload.types) {
        state.documentTypes.types = action.response.payload.types;
        state.documentTypes.isLoading = false;
        return;
      }
      state.documentTypes.isLoading = false;
    },
    [DELETE_DOCUMENT]: (state) => {
      state.documentsUpdate.mapIsLoading.set(DocumentsUpdateType.DELETE, true);
      if (
        state.loadDetailsDocuments.loadFolderIdentifier &&
        state.loadDetailsDocuments.loadFolderIdentifier.fileCount !== undefined
      ) {
        state.loadDetailsDocuments.loadFolderIdentifier.fileCount =
          state.loadDetailsDocuments.loadFolderIdentifier.fileCount - 1;
      }
    },
    [DOCUMENT_DELETED]: (state: DocumentsState, action: DocumentDeletedResponseAction) => {
      state.documentsUpdate = generateNewDocumentsUpdate(
        state.documentsUpdate.mapIsLoading,
        DocumentsUpdateType.DELETE,
        action.response.success
      );
      if (action.response.success && action.response.payload) {
        // @TODO MOB-5751 payload does not have an ID...
        const deletedDocumentID = action.response.payload.id;
        state.sendDocuments.formInfo.documents = filter(
          state.sendDocuments.formInfo.documents,
          (document) => document.id !== deletedDocumentID
        );
        state.documentLists = modifyAllDocumentListStates(state.documentLists, (documentListState) => {
          const filteredDocumentList = filter(
            documentListState.entries,
            (document) => document.id !== deletedDocumentID
          );
          const didRemoveDocumentFromList = filteredDocumentList.length < documentListState.entries.length;
          if (didRemoveDocumentFromList) {
            const modifiedDocumentListState = clone(documentListState);
            modifiedDocumentListState.entries = filteredDocumentList;
            modifiedDocumentListState.fetchRequest.offset = documentListState.fetchRequest.offset - 1;
            return modifiedDocumentListState;
          } else {
            return documentListState;
          }
        });
        state.loadDetailsDocuments.loadDocumentCount -= 1;
      }
    },
    [TOTAL_DOCUMENT_COUNT_FETCHED]: (state, action: TotalDocumentCountResponseAction) => {
      const response = action.response.payload;
      if (response && response.metadata) {
        state.totalDocumentCount = response.metadata.totalResultCount;
      }
    },
    [INCREMENT_LOAD_DOCUMENT_COUNT]: (state, action: LoadDocumentCountIncrementAction) => {
      state.loadDetailsDocuments.loadDocumentCount += action.increment;
    },
    [FETCH_FOLDER_BY_ID]: (state) => {
      state.loadDetailsDocuments.isLoadingLoadFolder = true;
      state.loadDetailsDocuments.loadDocumentCount = 0;
      state.loadDetailsDocuments.loadFolderIdentifier = undefined;
    },
    [FOLDER_BY_ID_FETCHED]: (state, action: FolderByIDResponseAction) => {
      const folderByIDResponse = action.response.payload;
      state.loadDetailsDocuments.isLoadingLoadFolder = false;
      if (folderByIDResponse && folderByIDResponse.name) {
        state.loadDetailsDocuments.loadDocumentCount = folderByIDResponse.fileCount || 0;
        state.loadDetailsDocuments.loadFolderIdentifier = folderByIDResponse;
        state.folders.customFolders.entries = updateCustomFolder(
          folderByIDResponse,
          state.folders.customFolders.entries
        );

        return;
      }
      state.loadDetailsDocuments.loadDocumentCount = 0;
      state.loadDetailsDocuments.loadFolderIdentifier = undefined;
    },
    [RESET_CURRENT_FOLDER]: (state) => {
      state.loadDetailsDocuments.loadDocumentCount = 0;
      state.loadDetailsDocuments.loadFolderIdentifier = undefined;
    },
    [DOCUMENT_FILE_SAVED]: (state, action: SaveDocumentAction) => {
      state.viewDocument.documentCache[action.id] = DownloadState.success;
    },
    [DOCUMENT_FILE_FETCH_ERROR]: (state, action: DocumentFetchErrorAction) => {
      state.viewDocument.documentCache[action.data.id] = DownloadState.failure;
      state.viewDocument.isLoadingDocumentData = false;
    },
    [SET_CREATE_DOCUMENT_CONFIG]: (state, action: SetCreateDocumentConfigAction) => {
      state.createDocument = action.config;
    },
    [SET_VIEW_TYPE]: (state, action: SetViewTypeAction) => {
      state.viewType = action.viewType;
    },
    [FETCH_DOCUMENT_FILE]: (state) => {
      state.viewDocument.isLoadingDocumentData = true;
      state.viewDocument.documentFileData = undefined;
    },
    [FETCH_CONVERSATION_DOCUMENT_FILE]: (state) => {
      state.viewDocument.isLoadingDocumentData = true;
      state.viewDocument.documentFileData = undefined;
    },
    [DOCUMENT_FILE_FETCHED]: (state, action: DocumentFileFetchAction) => {
      state.viewDocument.isLoadingDocumentData = false;
      state.viewDocument.documentFileData = action.data;
    },
    [SET_VIEW_DOCUMENT_FILE_DATA]: (state, action: SetViewDocumentFileData) => {
      state.viewDocument.viewDocumentFileData = action.fileData;
    },
    [SET_EXPORTING_DOCUMENT_ID]: (state, action: SetExportingDocumentIDAction) => {
      state.exportDocument.isExporting = action.documentID !== undefined;
      state.exportDocument.documentID = action.documentID;
    },
  },
  documentsFolderSubReducer,
  documentListsReducer
);

const editDocumentDetails = (docs: Document[], value: string, id: string, operation: UpdateOperation) => {
  const index = findIndex(docs, (doc) => doc.id === id);
  const copydocs = clone(docs);
  if (index >= 0) {
    const updatedDoc = copydocs[index];
    switch (operation) {
      case UpdateOperation.DISPLAY_NAME: {
        updatedDoc.displayName = value;
        break;
      }
      case UpdateOperation.DOCUMENT_TYPE: {
        updatedDoc.type = value;
        break;
      }
      case UpdateOperation.DOCUMENT_FOLDER: {
        updatedDoc.folderName = value;
        break;
      }
    }
    copydocs[index] = updatedDoc;
  }
  return copydocs;
};

const changeDocNameEpics$ = (client: DocumentsClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction(
    action$,
    CHANGE_DOCUMENT_NAME,
    (action: ChangeDocNameAction) => client.setDocName$(action.id, action.newName),
    (response): DocumentResponseAction => ({ type: CHANGE_DOCUMENT_NAME_FULFILLED, response: response })
  );
const fetchTypesEpic$ = (client: DocumentsClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    FETCH_DOCUMENT_TYPES,
    client.fetchTypes$,
    (response): TypesResponseAction => ({ type: DOCUMENT_TYPES_FETCHED, response: response })
  );
};

const updateDocType$ = (client: DocumentsClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    UPDATE_DOCUMENT_TYPE,
    (action: UpdateDocTypeAction) => client.changeDocType$(action.id, action.newType),
    (response): DocumentResponseAction => createResponseAction<Document>(DOCUMENT_TYPE_UPDATED)(response)
  );
};

const updateDocFolder$ = (client: DocumentsClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction(
    action$,
    UPDATE_DOCUMENT_FOLDER,
    (action: UpdateDocFolderAction) => client.changeDocFolder$(action.documentID, action.newFolder),
    (response): DocumentResponseAction => createResponseAction<Document>(DOCUMENT_FOLDER_UPDATED)(response)
  );

const getDocumentTotal$ = (client: DocumentsClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction(
    action$,
    FETCH_TOTAL_DOCUMENT_COUNT,
    client.fetchDocumentTotal$,
    createResponseAction(TOTAL_DOCUMENT_COUNT_FETCHED)
  );

const getFolderByLoad$ = (client: DocumentsClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction(
    action$,
    FETCH_FOLDER_BY_ID,
    (action: GetFolderByLoadAction) => client.fetchFolderByLoad$(action.guid),
    createResponseAction(FOLDER_BY_ID_FETCHED)
  );
const cacheFile$ = (cacheFile: CacheFile | undefined, action$: ActionsObservable<Action>) =>
  action$
    .ofType(DOCUMENT_FILE_FETCHED)
    .pipe(
      mergeMap$((action: { data: { id: string; extension: string; file: string }; type: string }) =>
        cacheFile
          ? from$(cacheFile(`${action.data.id}.${action.data.extension}`, action.data.file)).pipe(
              mergeMap$(() => of$({ type: DOCUMENT_FILE_SAVED, id: action.data.id }))
            )
          : of$()
      )
    );

const sendDocumentsEpic$ = (client: DocumentsClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    SEND_DOCUMENTS,
    (action: SendDocumentsAction) => {
      let sendBy: DocumentSendType = DocumentSendType.email;
      if (action.infoToSend.recipientEmail) {
        if (action.infoToSend.recipientFax) {
          sendBy = DocumentSendType.both;
        }
      } else if (action.infoToSend.recipientFax) {
        sendBy = DocumentSendType.fax;
      }
      const request: MyDocumentsSendRequest = {
        recipientName: action.infoToSend.recipientName,
        recipientCompanyName: action.infoToSend.recipientCompanyName,
        documents: map(action.infoToSend.documents, (document) => document.id),
        recipientEmail: action.infoToSend.recipientEmail,
        recipientFax: action.infoToSend.recipientFax,
        sendToThunderFunding: action.infoToSend.selectedRecipient === RecipientType.ThunderFunding,
        sendBy: sendBy,
      };

      return client.sendDocuments$(request);
    },
    (response): DocumentsSentResponseAction => createResponseAction<{}>(DOCUMENTS_SENT)(response)
  );
};

const deleteDocumentEpic$ = (client: DocumentsClient, action$: ActionsObservable<Action>) =>
  action$.ofType(DELETE_DOCUMENT).pipe(
    flatMap$((action: DeleteDocumentAction) =>
      client.deleteDocument$(action.id).pipe(
        map$((response: ApiResponse123<{}>) => {
          if (response.success) {
            return createResponseAction<{}>(DOCUMENT_DELETED)({
              success: response.success,
              payload: { id: action.id },
            });
          } else {
            const error = response as ApiError;
            return createResponseAction<{}>(DOCUMENT_DELETED)({ success: false, error: error });
          }
        })
      )
    )
  );

const fetchDocumentFile$ = (client: DocumentsClient, action$: ActionsObservable<Action>) =>
  action$.ofType(FETCH_DOCUMENT_FILE).pipe(
    flatMap$((action: FetchDocumentFileAction) =>
      client.fetchDocumentFile$(action.id).pipe(
        map$((response: ApiResponse123<string>) =>
          response.result(
            (data) => documentFileFetched({ id: action.id, file: data, extension: action.extension }),
            (): DocumentFetchErrorAction => ({ type: DOCUMENT_FILE_FETCH_ERROR, data: { id: action.id } })
          )
        )
      )
    )
  );

const fetchConversationDocumentFile$ = (client: DocumentsClient, action$: ActionsObservable<Action>) =>
  action$.ofType(FETCH_CONVERSATION_DOCUMENT_FILE).pipe(
    flatMap$((action: FetchConversationDocumentFileAction) =>
      client.fetchConversationDocumentFile$(action.conversationId, action.messageId, action.docId).pipe(
        map$((response: ApiResponse123<string>) =>
          response.result(
            (data) => documentFileFetched({ id: action.docId, file: data, extension: action.extension }),
            (): DocumentFetchErrorAction => ({ type: DOCUMENT_FILE_FETCH_ERROR, data: { id: action.docId } })
          )
        )
      )
    )
  );

const fetchDocumentsCount$ = (client: DocumentsClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction(
    action$,
    FETCH_DOCUMENTS_COUNT,
    () => client.fetchDocuments$({ ...createDefaultDocumentFilters(), fields: 'metadata' }),
    (response): DocumentsCountFetchedResponse => ({ type: DOCUMENTS_COUNT_FETCHED, response: response })
  );

export const createDocumentsEpic = (api: Api, cacheFile?: CacheFile) => {
  const client = new DocumentsClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<SubStoreState>) =>
    merge$(
      documentLists.createMergedEpic$(client.fetchDocuments$, action$, state$),
      sendDocumentsEpic$(client, action$),
      changeDocNameEpics$(client, action$),
      getDocumentTotal$(client, action$),
      deleteDocumentEpic$(client, action$),
      fetchTypesEpic$(client, action$),
      updateDocType$(client, action$),
      getFolderByLoad$(client, action$),
      updateDocFolder$(client, action$),
      fetchDocumentFile$(client, action$),
      fetchConversationDocumentFile$(client, action$),
      cacheFile$(cacheFile, action$),
      fetchDocumentsCount$(client, action$),
      foldersRedux.createMergedEpic$(
        action$,
        state$,
        client.fetchDefaultFolders$,
        client.fetchCustomFolders$,
        client.createCustomFolder$,
        client.changeFolderName$,
        client.deleteFolder$,
        client.getCustomFolder$
      )
    );
};

const generateNewDocumentsUpdate = (
  mapIsLoading: Map<DocumentsUpdateType, boolean>,
  updateType: DocumentsUpdateType,
  wasSuccessful: boolean
): DocumentsUpdate => {
  mapIsLoading.delete(updateType);
  return {
    mapIsLoading: mapIsLoading,
    updateResult: {
      updateType: updateType,
      updatedSuccessfully: wasSuccessful,
      updateTime: Date.now(),
    },
  };
};

import { filter, join, map, sortBy } from 'lodash';

import { EquipmentCode, EquipmentSpecification, EquipmentType, SimplifiedEquipment } from '@common/model';

export const stringifyEquipments = (equipments: SimplifiedEquipment[]): string[] =>
  map(trimEquiments(equipments), stringifyEquipment);

export const stringifyEquipment = (equipment: SimplifiedEquipment) => {
  const hasSpecifications =
    equipment.equipmentSpecifications !== EquipmentSpecification.None && equipment.equipmentSpecifications !== '';
  const hasType = equipment.equipmentType !== EquipmentType.None && equipment.equipmentType !== '';
  const type = hasType ? equipment.equipmentType : hasSpecifications ? equipment.equipmentSpecifications : '';
  const specifications = hasType && hasSpecifications ? ` (${equipment.equipmentSpecifications})` : '';
  return type + specifications;
};

const trimEquiments = (equipments: SimplifiedEquipment[]) =>
  filter(equipments, (equipment: SimplifiedEquipment) => {
    return (
      (equipment.equipmentSpecifications !== EquipmentSpecification.None && equipment.equipmentSpecifications !== '') ||
      (equipment.equipmentType !== EquipmentType.None && equipment.equipmentType !== '')
    );
  });

export const mapEquipmentsTypeToCode = (equipmentTypes: EquipmentType[] | undefined): string => {
  if (equipmentTypes === undefined || equipmentTypes.length <= 0) {
    return '-';
  }
  return join(sortBy(map(equipmentTypes, (equipment) => EquipmentCode[equipment])), ', ');
};

export const equipmentSpecificationsTo = (specifications: EquipmentSpecification[]): string => {
  return join(specifications, ', ');
};

import React from 'react';

import { compact, isEmpty, join } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ExpandableSections, setExpandedSection } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { formatNumberWithZeroFormatting } from '@common/helper';
import {
  PanelDetailsTransparent,
  PanelTitleTransparent,
  PanelWrapperTransparent,
} from '@component/expansionPanel/ExpansionPanelStyle';
import { Cost } from '@component/panels/basePanel/loadDetails/profitCalculator/Cost';
import { areAllFieldsEmpty, Field, FieldType } from '@component/panels/basePanel/loadDetails/profitCalculator/Field';
import TextInputBox from '@component/panels/basePanel/loadDetails/profitCalculator/TextInputBox';
import { InputWrapper, TitleText } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { SelectElement } from '@component/select/Select';
import { withTheme } from '@style/WithTheme';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

import { Calculation } from './Calculation';

const ExpansionTitleContainer = withTheme()(styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`);

const ExpandIcon = styled.img`
  padding-right: 5px !important;
`;

interface AdditionalCostsProps {
  tollCostField: Field;
  dispatchFeeField: Field;
  otherFeeField: Field;
  rate: string | undefined;
  additionalCosts: number;
  isDispatchFeeFlatAmount: boolean;
  focusedField: FieldType | undefined;
  onTollCostChange: (text: string) => void;
  onDispatchFeeChange: (text: string) => void;
  onDispatchFeeTypeChange: (type: string) => void;
  onOtherFeeChange: (text: string) => void;
  onFocus: (fieldType: FieldType) => () => void;
  onEndEditing: (fieldType: FieldType) => () => void;
}

const AdditionalCosts: React.FC<AdditionalCostsProps> = (props: AdditionalCostsProps) => {
  const dispatch = useDispatch();
  const isAdditionalCostExpanded = useSelector(
    (state) => state.applicationSettings.expandedSections[ExpandableSections.ProfitCalculatorAdditionalCost]
  );

  const dispatchFee = props.isDispatchFeeFlatAmount
    ? formatNumberWithZeroFormatting(props.dispatchFeeField.value(), true)
    : `(${!isEmpty(props.rate) ? props.rate : 0} * ${formatNumberWithZeroFormatting(props.dispatchFeeField.value())}%)`;
  const tollCost = props.tollCostField.value() ? formatNumberWithZeroFormatting(props.tollCostField.value(), true) : '';
  const otherFee = props.otherFeeField.value() ? formatNumberWithZeroFormatting(props.otherFeeField.value(), true) : '';
  let additionalCostsCalculationText = `${join(
    compact([tollCost, dispatchFee, otherFee]),
    ' + '
  )} = ${formatNumberWithZeroFormatting(props.additionalCosts, true)}`;
  if (
    areAllFieldsEmpty(props.tollCostField, props.dispatchFeeField, props.otherFeeField) ||
    props.additionalCosts === 0
  ) {
    additionalCostsCalculationText = t(T.findLoads_findLoads_pleaseEnterValues);
  }

  const handleSectionClick = () => {
    dispatch(setExpandedSection(ExpandableSections.ProfitCalculatorAdditionalCost, !isAdditionalCostExpanded));
  };

  return (
    <PanelWrapperTransparent expanded={isAdditionalCostExpanded}>
      <PanelTitleTransparent onClick={handleSectionClick}>
        <ExpansionTitleContainer>
          <TitleText id="title">{t(T.findLoads_findLoads_Additional_Costs)}</TitleText>
          <Cost value={props.additionalCosts} />
        </ExpansionTitleContainer>
        <ExpandIcon
          id={`${isAdditionalCostExpanded ? 'collapse' : 'expand'}`}
          src={isAdditionalCostExpanded ? icons.collapse : icons.expand}
          alt=">"
        />
      </PanelTitleTransparent>
      <PanelDetailsTransparent>
        <div style={{ width: '100%' }}>
          <Calculation id="calculation_text" isValid={true} text={additionalCostsCalculationText} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InputWrapper id="toll_cost" style={{ marginBottom: '15px', display: 'flex', flex: 1 }}>
              <TextInputBox
                field={props.tollCostField}
                onChangeText={props.onTollCostChange}
                placeholder={t(T.findLoads_findLoads_tollCost)}
                isInFocus={props.focusedField === FieldType.TollCost}
                onFocus={props.onFocus}
                onBlur={props.onEndEditing(FieldType.TollCost)}
              />
            </InputWrapper>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InputWrapper id="dispatch_fee" style={{ margin: '0 15px 15px 0', display: 'flex', flex: 1 }}>
              <TextInputBox
                field={props.dispatchFeeField}
                onChangeText={props.onDispatchFeeChange}
                placeholder={t(T.findLoads_findLoads_dispatchFee)}
                isInFocus={props.focusedField === FieldType.DispatchFee}
                onFocus={props.onFocus}
                onBlur={props.onEndEditing(FieldType.DispatchFee)}
              />
            </InputWrapper>
            <InputWrapper style={{ width: '80px', marginBottom: '15px' }}>
              <SelectElement
                id="dispatch_fee_type"
                options={['%', '$']}
                label={''}
                onChange={props.onDispatchFeeTypeChange}
                value={props.isDispatchFeeFlatAmount ? '$' : '%'}
              />
            </InputWrapper>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InputWrapper id="other_fees" style={{ display: 'flex', flex: 1 }}>
              <TextInputBox
                field={props.otherFeeField}
                onChangeText={props.onOtherFeeChange}
                placeholder={t(T.findLoads_findLoads_otherFee)}
                isInFocus={props.focusedField === FieldType.OtherFee}
                onFocus={props.onFocus}
                onBlur={props.onEndEditing(FieldType.OtherFee)}
              />
            </InputWrapper>
          </div>
        </div>
      </PanelDetailsTransparent>
    </PanelWrapperTransparent>
  );
};

export default AdditionalCosts;

import { clone } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiErrorCode, ApiResponse123 } from '@common/api';
import { LoadsClient } from '@common/client';
import { VendorBidsClient } from '@common/client/VendorBidsClient';
import { LoadAction, LoadResponse } from '@common/helper';
import {
  BookNowResponse,
  CargoChiefRate,
  CreditRating,
  FuelPrice,
  GreenscreensRate,
  LoadBackhauls,
  LoadProgress,
  RateCheck,
} from '@common/model';
import { LoadCreditRatingsResponse } from '@common/model/LoadCreditRatingsResponse';
import { RouteInfo } from '@common/model/PCMiler';
import { SharedLoadMessage } from '@common/model/SharedLoadMessage';
import { PostVendorBidRequest, PostVendorBidResponse } from '@common/model/VendorBid';
import { EmptyResponse, Response } from '@common/redux/Base';

import { simpleApiEpicToAction } from './EpicHelper';
import { getLoadDetailsArchivingFlowID, LoadDetailsReducerKey } from './LoadDetailsEpic';

const FETCH_LOAD_BACKHAULS = 'FETCH_LOAD_BACKHAULS';
const LOAD_BACKHAULS_FETCHED = 'LOAD_BACKHAULS_FETCHED';
const FETCH_ROUTE_INFO = 'FETCH_ROUTE_INFO';
const LOAD_ROUTE_INFO_FETCHED = 'LOAD_ROUTE_INFO_FETCHED';
const FETCH_CREDIT_RATINGS = 'FETCH_CREDIT_RATINGS';
const LOAD_CREDIT_RATINGS_FETCHED = 'LOAD_CREDIT_RATINGS_FETCHED';
const FETCH_LOAD_RATE_CHECK = 'FETCH_LOAD_RATE_CHECK';

// @FIXME: Watch out, this action is also processed in RateCheckEpic for
// the rate check accordion in load details. To be fixed when load details
// is refactored with normalized data.
export const LOAD_RATE_CHECK_FETCHED = 'LOAD_RATE_CHECK_FETCHED';

const FETCH_LOAD_CARGOCHIEF_RATE = 'FETCH_LOAD_CARGOCHIEF_RATE';
const LOAD_CARGOCHIEF_RATE_FETCHED = 'LOAD_CARGOCHIEF_RATE_FETCHED';
const FETCH_LOAD_GREENSCREENS_RATE = 'FETCH_LOAD_GREENSCREENS_RATE';
const LOAD_GREENSCREENS_RATE_FETCHED = 'LOAD_GREENSCREENS_RATE_FETCHED';
const SAVE_LOAD = 'SAVE_LOAD';
export const REMOVE_LOAD = 'REMOVE_LOAD';
export const SAVE_LOAD_FULFILLED = 'SAVE_LOAD_FULFILLED';
const ADD_NOTE = 'ADD_NOTE';
export const ADD_NOTE_FULFILLED = 'ADD_NOTE_FULFILLED';
const ADD_PRIVATE_NOTE = 'ADD_PRIVATE_NOTE';
export const ADD_PRIVATE_LOAD_NOTE_FULFILLED = 'ADD_PRIVATE_LOAD_NOTE_FULFILLED';
const SET_PROGRESS = 'SET_PROGRESS';
export const SET_PROGRESS_FULFILLED = 'SET_PROGRESS_FULFILLED';
const CALL_LOAD = 'CALL_LOAD';
export const CALL_LOAD_FULFILLED = 'CALL_LOAD_FULFILLED';
const FETCH_SHARE_LOAD_URL = 'FETCH_SHARE_LOAD_URL';
const SHARE_LOAD_URL_FETCHED = 'SHARED_LOAD_URL_FETCHED';
const FETCH_FUEL_PRICE = 'FETCH_FUEL_PRICE';
const FUEL_PRICE_FETCHED = 'FUEL_PRICE_FETCHED';
const SEND_EMAIL_TO_BROKER = 'SEND_EMAIL_TO_BROKER';
export const SEND_EMAIL_TO_BROKER_FULFILLED = 'SEND_EMAIL_TO_BROKER_FULFILLED';
const CLEAR_EMAIL_SENDING_STATE = 'CLEAR_EMAIL_SENDING_STATE';
const CLEAR_SHARE_LOAD_URL_STATE = 'CLEAR_SHARE_LOAD_URL_STATE';
const PLACE_BID_ON_VENDOR_LOAD = 'BID_ON_VENDOR_LOAD';
export const SENT_BID_VENDOR_LOAD = 'SENT_BID_VENDOR_LOAD';
const SEND_BOOK_NOW = 'SEND_BOOK_NOW';
export const BOOK_NOW_SENT = 'BOOK_NOW_SENT';
const HIDE_LOAD = 'HIDE_LOAD';
export const HIDE_LOAD_FULFILLED = 'HIDE_LOAD_FULFILLED';

export const SET_LOAD_INFO_TYPE = 'SET_LOAD_INFO_TYPE';
type LoadRouteInfoResult = Response<RouteInfo>;
type LoadCreditRatingsResult = Response<LoadCreditRatingsResponse>;
type ShareLoadUrlResult = Response<SharedLoadMessage>;

export type LoadBackhaulsResult = Response<LoadBackhauls>;
export type LoadRateCheckResult = Response<RateCheck>;
type LoadCargoChiefRateResult = Response<CargoChiefRate>;
type LoadGreenscreensRateResult = Response<GreenscreensRate>;

type LoadFuelPriceResult = Response<FuelPrice>;

export enum LoadActionType {
  Save,
  Unsave,
  Note,
  PrivateLoadNote,
  VendorBidPlaced,
  BookNow,
  Hidden,
  Unhidden,
}

interface SetLoadInfoTypeAction extends Action {
  loadDetailsKey: string;
}

interface SavedLoadAction extends LoadAction {
  willSave: boolean;
}

export interface SaveLoadResponseAction extends LoadResponse {
  didSave: boolean;
}

export interface HideLoadResponseAction extends LoadResponse {
  didHide: boolean;
}

interface FetchLoadBackhaulsAction extends LoadAction {
  fromNamedSearchId?: string;
}

interface LoadBackhaulsResponseAction extends LoadAction {
  response: LoadBackhaulsResult;
}

interface LoadRouteInfoResponseAction extends LoadAction {
  response: LoadRouteInfoResult;
}

interface LoadCreditRatingsResponseAction extends LoadAction {
  response: LoadCreditRatingsResult;
}

export interface LoadRateCheckResponseAction extends LoadResponse {
  response: LoadRateCheckResult;
}

interface LoadCargoChiefRateResponseAction extends LoadResponse {
  response: LoadCargoChiefRateResult;
}

interface LoadGreenscreensRateResponseAction extends LoadResponse {
  response: LoadGreenscreensRateResult;
}

interface ShareAppUrlResponseAction extends LoadResponse {
  response: ShareLoadUrlResult;
}

interface AddNoteAction extends LoadAction {
  note: string;
}

export interface AddNoteResponseAction extends LoadResponse {
  note: string;
}

interface AddPrivateLoadNoteAction extends LoadAction {
  privateLoadNote: string;
}

export interface AddPrivateLoadNoteResponseAction extends LoadResponse {
  privateLoadNote: string;
}

interface SetProgressAction extends LoadAction {
  progress: LoadProgress;
}

export interface SetProgressResponseAction extends LoadAction {
  progress: LoadProgress;
  response: EmptyResponse;
}

interface CalledLoadAction extends LoadAction {
  isCalled: boolean;
}

interface SendEmailToBrokerAction extends LoadAction {
  comment: string;
  loadID: string;
}

interface FetchFuelPriceAction extends LoadAction {
  state: string;
  country?: string;
}

// this action must extend LoadAction since
// updateCacheData expects loadID, not loadId
interface BidOnVendorLoadAction extends LoadAction {
  bidSubmission: PostVendorBidRequest;
}

// this action must extend LoadAction since
// updateCacheData expects loadID, not loadId
export interface BidOnVendorLoadResponseAction extends LoadAction {
  response: Response<PostVendorBidResponse>;
  request: PostVendorBidRequest;
}

export interface BookNowSentResponseAction extends LoadAction {
  response: Response<BookNowResponse>;
}

export const setLoadInfoType = (loadDetailsKey: string): SetLoadInfoTypeAction => ({
  type: SET_LOAD_INFO_TYPE,
  loadDetailsKey: loadDetailsKey,
});

export const fetchLoadFuelPrice = (loadID: string, state: string, country?: string): FetchFuelPriceAction => ({
  type: FETCH_FUEL_PRICE,
  state: state,
  country: country,
  loadID: loadID,
});

interface LoadFuelPriceResponseAction extends LoadAction {
  response: LoadFuelPriceResult;
}

export interface CalledLoadResponseAction extends LoadAction {
  isCalled: boolean;
  response: EmptyResponse;
}

export interface SendEmailToBrokerResponseAction extends LoadAction {
  response: EmptyResponse;
}

export const fetchLoadBackhauls = (loadID: string, fromNamedSearchId?: string): FetchLoadBackhaulsAction => ({
  type: FETCH_LOAD_BACKHAULS,
  loadID: loadID,
  fromNamedSearchId: fromNamedSearchId,
});

export const fetchRouteInfo = (loadID: string): LoadAction => ({
  type: FETCH_ROUTE_INFO,
  loadID: loadID,
});

export const fetchCreditRatings = (loadID: string): LoadAction => ({
  type: FETCH_CREDIT_RATINGS,
  loadID: loadID,
});

export const fetchRateCheck = (loadID: string): LoadAction => ({
  type: FETCH_LOAD_RATE_CHECK,
  loadID: loadID,
});

export const fetchLoadCargoChiefRate = (loadID: string): LoadAction => ({
  type: FETCH_LOAD_CARGOCHIEF_RATE,
  loadID: loadID,
});

export const fetchLoadGreenscreensRate = (loadID: string): LoadAction => ({
  type: FETCH_LOAD_GREENSCREENS_RATE,
  loadID: loadID,
});

export const fetchSharedLoad = (loadID: string): LoadAction => ({
  type: FETCH_SHARE_LOAD_URL,
  loadID: loadID,
});

const loadFuelPriceResponse = (loadID: string, response: LoadFuelPriceResult): LoadFuelPriceResponseAction => ({
  type: FUEL_PRICE_FETCHED,
  response: response,
  loadID: loadID,
});

const loadBackhaulsResponse = (response: LoadBackhaulsResult, loadID: string): LoadBackhaulsResponseAction => ({
  type: LOAD_BACKHAULS_FETCHED,
  response: response,
  loadID: loadID,
});

const loadCreditRatingsResponse = (
  response: LoadCreditRatingsResult,
  loadID: string
): LoadCreditRatingsResponseAction => ({
  type: LOAD_CREDIT_RATINGS_FETCHED,
  response: response,
  loadID: loadID,
});

const loadRouteInfoResponse = (response: LoadRouteInfoResult, loadID: string): LoadRouteInfoResponseAction => ({
  type: LOAD_ROUTE_INFO_FETCHED,
  response: response,
  loadID: loadID,
});

const loadRateCheckResponse = (response: LoadRateCheckResult, loadID: string): LoadRateCheckResponseAction => ({
  type: LOAD_RATE_CHECK_FETCHED,
  response: response,
  loadID: loadID,
});
const loadCargoChiefRateResponse = (
  response: LoadCargoChiefRateResult,
  loadID: string
): LoadCargoChiefRateResponseAction => ({
  type: LOAD_CARGOCHIEF_RATE_FETCHED,
  response: response,
  loadID: loadID,
});
const loadGreenscreensRateResponse = (
  response: LoadGreenscreensRateResult,
  loadID: string
): LoadGreenscreensRateResponseAction => ({
  type: LOAD_GREENSCREENS_RATE_FETCHED,
  response: response,
  loadID: loadID,
});
const savedLoadResponse = (response: Response<{}>, didSave: boolean, loadID: string): SaveLoadResponseAction => ({
  type: SAVE_LOAD_FULFILLED,
  response: response,
  didSave: didSave,
  loadID: loadID,
});
const hiddenLoadResponse = (response: Response<{}>, didHide: boolean, loadID: string): HideLoadResponseAction => ({
  type: HIDE_LOAD_FULFILLED,
  response: response,
  didHide: didHide,
  loadID: loadID,
});
const callLoadResponse = (response: EmptyResponse, isCalled: boolean, loadID: string): CalledLoadResponseAction => ({
  type: CALL_LOAD_FULFILLED,
  response: response,
  isCalled: isCalled,
  loadID: loadID,
});
const sendEmailResponse = (response: EmptyResponse, loadID: string): SendEmailToBrokerResponseAction => ({
  type: SEND_EMAIL_TO_BROKER_FULFILLED,
  response: response,
  loadID: loadID,
});
const sharedLoadUrlResponse = (response: ShareLoadUrlResult, loadID: string): ShareAppUrlResponseAction => ({
  type: SHARE_LOAD_URL_FETCHED,
  loadID: loadID,
  response: response,
});
export const saveLoad = (loadID: string, willSave: boolean): SavedLoadAction => ({
  type: SAVE_LOAD,
  loadID: loadID,
  willSave: willSave,
});

export const hideLoad = (loadID: string): LoadAction => ({
  type: HIDE_LOAD,
  loadID: loadID,
});

export const addNote = (loadID: string, note: string): AddNoteAction => ({
  type: ADD_NOTE,
  loadID: loadID,
  note: note,
});
const AddNoteResponse = (loadID: string, note: string, response: Response<{}>): AddNoteResponseAction => ({
  type: ADD_NOTE_FULFILLED,
  response: response,
  loadID: loadID,
  note: note,
});
export const addPrivateLoadNote = (loadID: string, privateLoadNote: string): AddPrivateLoadNoteAction => ({
  type: ADD_PRIVATE_NOTE,
  loadID: loadID,
  privateLoadNote: privateLoadNote,
});
const AddPrivateLoadNoteResponse = (
  loadID: string,
  privateLoadNote: string,
  response: Response<{}>
): AddPrivateLoadNoteResponseAction => ({
  type: ADD_PRIVATE_LOAD_NOTE_FULFILLED,
  response: response,
  loadID: loadID,
  privateLoadNote: privateLoadNote,
});
export const setProgress = (loadID: string, progress: LoadProgress): SetProgressAction => ({
  type: SET_PROGRESS,
  loadID: loadID,
  progress: progress,
});
export const removeLoad = (loadID: string): LoadAction => ({
  type: REMOVE_LOAD,
  loadID: loadID,
});

export const bidOnVendorLoad = (submission: PostVendorBidRequest): BidOnVendorLoadAction => ({
  type: PLACE_BID_ON_VENDOR_LOAD,
  bidSubmission: submission,
  loadID: submission.loadId,
});

const bidOnVendorLoadResponse = (
  fetchAction: BidOnVendorLoadAction,
  response: Response<PostVendorBidResponse>
): BidOnVendorLoadResponseAction => ({
  type: SENT_BID_VENDOR_LOAD,
  response: response,
  request: fetchAction.bidSubmission,
  loadID: fetchAction.loadID,
});

export const bookLoad = (loadId: string): LoadAction => ({
  type: SEND_BOOK_NOW,
  loadID: loadId,
});

const bookLoadResponse = (fetchAction: LoadAction, response: Response<BookNowResponse>): LoadResponse => ({
  type: BOOK_NOW_SENT,
  response: response,
  loadID: fetchAction.loadID,
});

const setProgressResponse = (
  loadID: string,
  progress: LoadProgress,
  response: EmptyResponse
): SetProgressResponseAction => ({
  type: SET_PROGRESS_FULFILLED,
  loadID: loadID,
  progress: progress,
  response: response,
});
export const callLoad = (loadID: string, isCalled: boolean): CalledLoadAction => ({
  type: CALL_LOAD,
  loadID: loadID,
  isCalled: isCalled,
});
export const sendEmailToBroker = (loadID: string, comment: string): SendEmailToBrokerAction => ({
  type: SEND_EMAIL_TO_BROKER,
  loadID: loadID,
  comment: comment,
});
export const clearEmailSendingState = (loadID: string): LoadAction => ({
  type: CLEAR_EMAIL_SENDING_STATE,
  loadID: loadID,
});
export const clearShareLoadUrlState = (loadID: string): LoadAction => ({
  type: CLEAR_SHARE_LOAD_URL_STATE,
  loadID: loadID,
});

export interface LoadInfo {
  isLoadingBackhauls: boolean;
  isLoadingCreditRating: boolean;
  isLoadingRouteInfo: boolean;
  isLoadingRateCheck: boolean;
  isLoadingCargoChiefRate: boolean;
  isLoadingGreenscreensRate: boolean;
  isLoadingShareLoadUrl: boolean;
  isLoadingFuelPrice: boolean;
  backhauls?: LoadBackhaulsResult;
  routeInfo?: RouteInfo;
  sharedLoadMessage?: SharedLoadMessage;
  creditRatings?: CreditRating[];
  rateCheck?: LoadRateCheckResult;
  cargoChiefValues?: LoadCargoChiefRateResult;
  greenscreensValues?: LoadGreenscreensRateResult;
  fuelPrice?: LoadFuelPriceResult;
  isSavingLoad: boolean;
  isLoadingNote: boolean;
  isLoadingPrivateLoadNote: boolean;
  isEmailSentSuccessfully?: boolean;
  emailSendingError?: ApiError | undefined;
  loadSharingError?: ApiError | undefined;
  vendorBid?: VendorBidState;
  isProgressUpdating: boolean;
  progressUpdateTime?: number;
  isUpdatingSuccess?: boolean;
  isLoadingHideLoad: boolean;
  bookNow?: BookNowState;
}

export interface LoadInfoState {
  loadInfoMap: Map<string, LoadInfo>;
  loadUpdate?: {
    wasUpdatedSuccessfully: boolean;
    updateTime: number;
    updateType: LoadActionType;
  };
  loadDetailsKey: string;
}

export interface VendorBidState {
  isSending: boolean;
  isSentSuccessfully?: boolean;
  errorCode?: ApiErrorCode;
}
interface BookNowState {
  isSending: boolean;
  isSentSuccessfully?: boolean;
  isLoadUnavailable?: boolean;
  isNotFound?: boolean;
}

export const defaultLoadInfo: LoadInfo = {
  isLoadingBackhauls: false,
  isLoadingCreditRating: false,
  isLoadingRouteInfo: false,
  isLoadingRateCheck: false,
  isLoadingCargoChiefRate: false,
  isLoadingGreenscreensRate: false,
  isLoadingShareLoadUrl: false,
  isSavingLoad: false,
  isLoadingHideLoad: false,
  isLoadingNote: false,
  isLoadingPrivateLoadNote: false,
  isLoadingFuelPrice: false,
  isProgressUpdating: false,
  vendorBid: {
    isSending: false,
  },
};

// Helper type to enforce no extra properties
type __NoExtraProperties<T, U extends T> = U & { [K in Exclude<keyof U, keyof T>]?: never };
type NoExtraProperties<T> = __NoExtraProperties<T, T>;

type UpdateLoadInfo = (info: LoadInfo) => NoExtraProperties<Partial<LoadInfo>>;
const updateCacheData = (cache: Map<string, LoadInfo>, fetchAction: Action, update: UpdateLoadInfo) => {
  const loadID = (fetchAction as LoadAction).loadID;
  if (!loadID) {
    return cache;
  }
  const loadInfoMap = clone(cache);
  let loadInfo = loadInfoMap.get(loadID) || defaultLoadInfo;
  loadInfo = { ...loadInfo, ...update(loadInfo) };
  loadInfoMap.set(loadID, loadInfo);
  return loadInfoMap;
};

const initialState: LoadInfoState = {
  loadInfoMap: new Map(),
  loadDetailsKey: LoadDetailsReducerKey.LOAD_SEARCH,
};
//Be careful updating this reducer to use createReducer or using produce as this use immer and was updated. Check immer+maps before any work.
export const loadInfoReducer = (state: LoadInfoState = initialState, action: Action): LoadInfoState => {
  let updateLoadInfo: UpdateLoadInfo;
  const newState = clone(state);
  switch (action.type) {
    case SET_LOAD_INFO_TYPE: {
      return { ...state, loadDetailsKey: (action as SetLoadInfoTypeAction).loadDetailsKey };
    }
    case FETCH_FUEL_PRICE: {
      updateLoadInfo = () => ({
        isLoadingFuelPrice: true,
        fuelPrice: undefined,
      });
      break;
    }
    case FETCH_LOAD_BACKHAULS: {
      updateLoadInfo = () => ({
        isLoadingBackhauls: true,
        backhauls: undefined,
      });
      break;
    }
    case LOAD_BACKHAULS_FETCHED: {
      const responseAction = action as LoadBackhaulsResponseAction;
      const backhauls = responseAction.response;
      updateLoadInfo = () => ({
        isLoadingBackhauls: false,
        backhauls: backhauls,
      });
      break;
    }
    case FETCH_ROUTE_INFO: {
      updateLoadInfo = () => ({
        isLoadingRouteInfo: true,
        routeInfo: undefined,
      });
      break;
    }
    case LOAD_ROUTE_INFO_FETCHED: {
      const loadRouteInfoResponseAction = action as LoadRouteInfoResponseAction;
      const routeInfo = loadRouteInfoResponseAction.response.payload;
      updateLoadInfo = () => ({
        isLoadingRouteInfo: false,
        routeInfo: routeInfo,
      });
      break;
    }
    case FETCH_CREDIT_RATINGS: {
      updateLoadInfo = () => ({
        isLoadingCreditRating: true,
        creditRatings: undefined,
      });
      break;
    }
    case LOAD_CREDIT_RATINGS_FETCHED: {
      const loadCreditRatingsResponseAction = action as LoadCreditRatingsResponseAction;
      const wasCreditRatingsListSuccessfullyRetrieved = loadCreditRatingsResponseAction.response.success === true;
      const loadHasNoCreditRatings =
        loadCreditRatingsResponseAction.response.error &&
        loadCreditRatingsResponseAction.response.error.code === 4040010;
      if (wasCreditRatingsListSuccessfullyRetrieved || loadHasNoCreditRatings) {
        const creditRatings = loadCreditRatingsResponseAction.response.payload
          ? loadCreditRatingsResponseAction.response.payload.creditors
          : [];
        updateLoadInfo = () => ({
          isLoadingCreditRating: false,
          creditRatings: creditRatings,
        });
        break;
      }

      updateLoadInfo = () => ({
        isLoadingCreditRating: false,
        creditRatings: undefined,
      });
      break;
    }
    case FETCH_SHARE_LOAD_URL: {
      updateLoadInfo = () => ({
        sharedLoadMessage: undefined,
        isLoadingShareLoadUrl: true,
      });
      break;
    }
    case SHARE_LOAD_URL_FETCHED: {
      const shareLoadResponse = action as ShareAppUrlResponseAction;
      if (shareLoadResponse.response.success && shareLoadResponse.response.payload) {
        updateLoadInfo = () => ({
          isLoadingShareLoadUrl: false,
          sharedLoadMessage: shareLoadResponse.response.payload,
          loadSharingError: undefined,
        });
        break;
      } else {
        updateLoadInfo = () => ({
          isLoadingShareLoadUrl: false,
          sharedLoadMessage: undefined,
          loadSharingError: shareLoadResponse.response.error,
        });
      }
      break;
    }
    case CLEAR_SHARE_LOAD_URL_STATE: {
      updateLoadInfo = () => ({
        isLoadingShareLoadUrl: false,
        sharedLoadMessage: undefined,
        loadSharingError: undefined,
      });
      break;
    }
    case FETCH_LOAD_RATE_CHECK: {
      updateLoadInfo = () => ({
        isLoadingRateCheck: true,
        rateCheck: undefined,
      });
      break;
    }
    case LOAD_RATE_CHECK_FETCHED: {
      const loadRateCheckResponseAction = action as LoadRateCheckResponseAction;
      updateLoadInfo = () => ({
        isLoadingRateCheck: false,
        rateCheck: loadRateCheckResponseAction.response,
      });
      break;
    }
    case FETCH_LOAD_CARGOCHIEF_RATE: {
      updateLoadInfo = () => ({
        isLoadingCargoChiefRate: true,
        cargoChiefValues: undefined,
      });
      break;
    }
    case LOAD_CARGOCHIEF_RATE_FETCHED: {
      const responseAction = action as LoadCargoChiefRateResponseAction;
      updateLoadInfo = () => ({
        isLoadingCargoChiefRate: false,
        cargoChiefValues: responseAction.response,
      });
      break;
    }
    case FETCH_LOAD_GREENSCREENS_RATE: {
      updateLoadInfo = () => ({
        isLoadingGreenscreensRate: true,
        greenscreensValues: undefined,
      });
      break;
    }
    case LOAD_GREENSCREENS_RATE_FETCHED: {
      const responseAction = action as LoadGreenscreensRateResponseAction;
      updateLoadInfo = () => ({
        isLoadingGreenscreensRate: false,
        greenscreensValues: responseAction.response,
      });
      break;
    }
    case SAVE_LOAD: {
      updateLoadInfo = () => ({
        isSavingLoad: true,
      });
      break;
    }
    case SAVE_LOAD_FULFILLED: {
      const saveAction = action as SaveLoadResponseAction;
      updateLoadInfo = () => ({
        isSavingLoad: false,
      });
      newState.loadUpdate = {
        wasUpdatedSuccessfully: saveAction.response.success,
        updateTime: Date.now(),
        updateType: saveAction.didSave === false ? LoadActionType.Unsave : LoadActionType.Save,
      };
      break;
    }
    case HIDE_LOAD: {
      updateLoadInfo = () => ({
        isLoadingHideLoad: true,
      });
      break;
    }
    case HIDE_LOAD_FULFILLED: {
      const hideAction = action as HideLoadResponseAction;
      updateLoadInfo = () => ({
        isLoadingHideLoad: false,
      });
      newState.loadUpdate = {
        wasUpdatedSuccessfully: hideAction.response.success,
        updateTime: Date.now(),
        updateType: hideAction.didHide === false ? LoadActionType.Unhidden : LoadActionType.Hidden,
      };
      break;
    }
    case ADD_NOTE: {
      updateLoadInfo = () => ({
        isLoadingNote: true,
      });
      break;
    }
    case ADD_NOTE_FULFILLED: {
      const noteAction = action as AddNoteResponseAction;
      updateLoadInfo = () => ({
        isLoadingNote: false,
      });
      newState.loadUpdate = {
        wasUpdatedSuccessfully: noteAction.response.success,
        updateTime: Date.now(),
        updateType: LoadActionType.Note,
      };
      break;
    }
    case ADD_PRIVATE_NOTE: {
      updateLoadInfo = () => ({
        isLoadingPrivateLoadNote: true,
      });
      break;
    }
    case ADD_PRIVATE_LOAD_NOTE_FULFILLED: {
      const privateLoadNoteAction = action as AddPrivateLoadNoteResponseAction;
      updateLoadInfo = () => ({
        isLoadingPrivateLoadNote: false,
      });
      newState.loadUpdate = {
        wasUpdatedSuccessfully: privateLoadNoteAction.response.success,
        updateTime: Date.now(),
        updateType: LoadActionType.PrivateLoadNote,
      };
      break;
    }
    case FUEL_PRICE_FETCHED: {
      const loadFuelPriceResponseAction = action as LoadFuelPriceResponseAction;
      updateLoadInfo = () => ({
        isLoadingFuelPrice: false,
        fuelPrice: loadFuelPriceResponseAction.response,
      });
      break;
    }
    case CLEAR_EMAIL_SENDING_STATE:
    case SEND_EMAIL_TO_BROKER: {
      updateLoadInfo = () => ({
        isEmailSentSuccessfully: undefined,
        emailSendingError: undefined,
      });
      break;
    }
    case SEND_EMAIL_TO_BROKER_FULFILLED: {
      const loadFuelPriceResponseAction = action as SendEmailToBrokerResponseAction;
      updateLoadInfo = () => ({
        isEmailSentSuccessfully: loadFuelPriceResponseAction.response.success,
        emailSendingError: loadFuelPriceResponseAction.response.error,
      });
      break;
    }
    case PLACE_BID_ON_VENDOR_LOAD: {
      updateLoadInfo = () => ({
        vendorBid: { isSending: true, isSentSuccessfully: false },
      });
      break;
    }
    case SENT_BID_VENDOR_LOAD: {
      const vendorBidResponseAction = action as BidOnVendorLoadResponseAction;
      updateLoadInfo = () => ({
        vendorBid: {
          isSending: false,
          isSentSuccessfully: vendorBidResponseAction.response.success,
          errorCode: vendorBidResponseAction.response.error ? vendorBidResponseAction.response.error.code : undefined,
        },
      });
      break;
    }
    case SEND_BOOK_NOW: {
      updateLoadInfo = () => ({
        bookNow: { isSentSuccessfully: false, isSending: true },
      });
      break;
    }
    case BOOK_NOW_SENT: {
      const bookResponseAction = action as LoadResponse;
      updateLoadInfo = () => ({
        bookNow: {
          isSentSuccessfully: bookResponseAction.response.success,
          isSending: false,
          isLoadUnavailable: bookResponseAction.response.error?.code === ApiErrorCode.BOOK_NOW_LOAD_UNAVAILABLE,
          isNotFound: bookResponseAction.response.error?.code === ApiErrorCode.BOOK_NOW_NOT_FOUND_ERROR,
        },
      });
      break;
    }
    case SET_PROGRESS: {
      updateLoadInfo = () => ({
        isProgressUpdating: true,
      });
      break;
    }
    case SET_PROGRESS_FULFILLED: {
      const setProgressStatusAction = action as SetProgressResponseAction;
      updateLoadInfo = () => ({
        isProgressUpdating: false,
        isUpdatingSuccess: setProgressStatusAction.response.success,
        progressUpdateTime: Date.now(),
      });
      break;
    }
    default:
      return state;
  }
  return {
    ...newState,
    loadInfoMap: updateCacheData(newState.loadInfoMap, action, updateLoadInfo),
  };
};

type LoadInfoStateObservable = StateObservable<{ loadInfo: LoadInfoState }>;
const fetchLoadBackhauls$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_LOAD_BACKHAULS,
    (action: FetchLoadBackhaulsAction) =>
      loadsClient.fetchLoadBackhauls$(
        action.loadID,
        action.fromNamedSearchId,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: FetchLoadBackhaulsAction) => loadBackhaulsResponse(response, action.loadID)
  );

const fetchRouteInfo$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_ROUTE_INFO,
    (action: LoadResponse) =>
      loadsClient.fetchRouteInfo$(
        action.loadID,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: LoadResponse) => loadRouteInfoResponse(response, action.loadID)
  );

const fetchCreditRatings$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_CREDIT_RATINGS,
    (action: LoadResponse) =>
      loadsClient.fetchCreditRatings$(
        action.loadID,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: LoadResponse) => loadCreditRatingsResponse(response, action.loadID)
  );

const fetchRateCheck$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_LOAD_RATE_CHECK,
    (action: LoadResponse) =>
      loadsClient.fetchRateCheck$(
        action.loadID,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: LoadResponse) => loadRateCheckResponse(response, action.loadID)
  );

const fetchLoadCargoChiefRate$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_LOAD_CARGOCHIEF_RATE,
    (action: LoadResponse) =>
      loadsClient.fetchLoadCargoChiefRate$(
        action.loadID,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: LoadResponse) =>
      loadCargoChiefRateResponse({ ...response, payload: response.payload }, action.loadID)
  );

const fetchLoadGreenscreensRate$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_LOAD_GREENSCREENS_RATE,
    (action: LoadResponse) =>
      loadsClient.fetchLoadGreenscreensRate$(
        action.loadID,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: LoadResponse) =>
      loadGreenscreensRateResponse({ ...response, payload: response.payload }, action.loadID)
  );

const fetchShareLoadUrl$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_SHARE_LOAD_URL,
    (action: LoadResponse) =>
      loadsClient.getSharedLoadUrl$(
        action.loadID,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: LoadResponse) => sharedLoadUrlResponse(response, action.loadID)
  );

const saveLoad$ = (action$: ActionsObservable<Action>, loadsClient: LoadsClient, state$: LoadInfoStateObservable) =>
  action$.ofType(SAVE_LOAD).pipe(
    mergeMap$((action: SavedLoadAction) =>
      loadsClient
        .setSavedLoad$(
          action.loadID,
          action.willSave,
          getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
        )
        .pipe(
          map$((response: ApiResponse123<{}>) => {
            return response.resultWithoutData(
              () => savedLoadResponse({ success: response.success }, action.willSave, action.loadID),
              (error) => savedLoadResponse({ success: response.success, error: error }, action.willSave, action.loadID)
            );
          })
        )
    )
  );
const hideLoad$ = (action$: ActionsObservable<Action>, loadsClient: LoadsClient, state$: LoadInfoStateObservable) =>
  action$.ofType(HIDE_LOAD).pipe(
    mergeMap$((action: LoadAction) =>
      loadsClient
        .setHiddenLoad$(action.loadID, getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey))
        .pipe(
          map$((response: ApiResponse123<{}>) => {
            return response.resultWithoutData(
              () => hiddenLoadResponse({ success: response.success }, response.success, action.loadID),
              (error) =>
                hiddenLoadResponse({ success: response.success, error: error }, response.success, action.loadID)
            );
          })
        )
    )
  );

const sendEmailToBroker$ = (action$: ActionsObservable<Action>, loadsClient: LoadsClient) =>
  action$.ofType(SEND_EMAIL_TO_BROKER).pipe(
    mergeMap$((action: SendEmailToBrokerAction) =>
      loadsClient.sendEmailToBroker$(action.loadID, action.comment).pipe(
        map$((response: ApiResponse123<{}>) => {
          return response.resultWithoutData(
            () => sendEmailResponse({ success: true }, action.loadID),
            (error) => sendEmailResponse({ success: response.success, error: error }, action.loadID)
          );
        })
      )
    )
  );

const callLoad$ = (action$: ActionsObservable<Action>, loadsClient: LoadsClient, state$: LoadInfoStateObservable) =>
  action$.ofType(CALL_LOAD).pipe(
    mergeMap$((action: CalledLoadAction) =>
      loadsClient
        .setLoadCall$(
          action.loadID,
          action.isCalled,
          getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
        )
        .pipe(
          map$((response: ApiResponse123<{}>) => {
            return response.resultWithoutData(
              () => callLoadResponse({ success: response.success }, action.isCalled, action.loadID),
              (error) => callLoadResponse({ success: response.success, error: error }, action.isCalled, action.loadID)
            );
          })
        )
    )
  );

const addLoadNote$ = (action$: ActionsObservable<Action>, loadsClient: LoadsClient, state$: LoadInfoStateObservable) =>
  action$.ofType(ADD_NOTE).pipe(
    mergeMap$((action: AddNoteAction) =>
      loadsClient
        .setLoadNote$(
          action.loadID,
          action.note,
          getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
        )
        .pipe(
          map$((response: ApiResponse123<{}>) => {
            return response.resultWithoutData(
              () => AddNoteResponse(action.loadID, action.note, { success: response.success }),
              (error) => AddNoteResponse(action.loadID, action.note, { success: response.success, error: error })
            );
          })
        )
    )
  );

const addPrivateLoadNote$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  action$.ofType(ADD_PRIVATE_NOTE).pipe(
    mergeMap$((action: AddPrivateLoadNoteAction) =>
      loadsClient
        .setPrivateLoadNote$(
          action.loadID,
          action.privateLoadNote,
          getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
        )
        .pipe(
          map$((response: ApiResponse123<{}>) => {
            return response.resultWithoutData(
              () => AddPrivateLoadNoteResponse(action.loadID, action.privateLoadNote, { success: response.success }),
              (error) =>
                AddPrivateLoadNoteResponse(action.loadID, action.privateLoadNote, {
                  success: response.success,
                  error: error,
                })
            );
          })
        )
    )
  );

const placeVendorBid$ = (action$: ActionsObservable<Action>, vendorBidsClient: VendorBidsClient) =>
  simpleApiEpicToAction(
    action$,
    PLACE_BID_ON_VENDOR_LOAD,
    (action: BidOnVendorLoadAction) => vendorBidsClient.postVendorBid$(action.bidSubmission),
    (response, action: BidOnVendorLoadAction) => bidOnVendorLoadResponse(action, response)
  );

const bookNow = (action$: ActionsObservable<Action>, loadsClient: LoadsClient) =>
  simpleApiEpicToAction(
    action$,
    SEND_BOOK_NOW,
    (action: LoadAction) => loadsClient.sendBookNow$(action.loadID),
    (response, action: LoadAction) => bookLoadResponse(action, response)
  );

const setLoadProgress$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  action$.ofType(SET_PROGRESS).pipe(
    mergeMap$((action: SetProgressAction) =>
      loadsClient
        .setLoadProgress$(
          action.loadID,
          action.progress,
          getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
        )
        .pipe(
          map$((response: ApiResponse123<{}>) => {
            return response.resultWithoutData(
              () => setProgressResponse(action.loadID, action.progress, { success: response.success }),
              (error) =>
                setProgressResponse(action.loadID, action.progress, { success: response.success, error: error })
            );
          })
        )
    )
  );

const fetchFuelPrice$ = (
  action$: ActionsObservable<Action>,
  loadsClient: LoadsClient,
  state$: LoadInfoStateObservable
) =>
  simpleApiEpicToAction(
    action$,
    FETCH_FUEL_PRICE,
    (action: FetchFuelPriceAction) =>
      loadsClient.fetchFuelPrice$(
        action.state,
        action.country,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: LoadFuelPriceResponseAction) => loadFuelPriceResponse(action.loadID, response)
  );

export const createLoadInfoEpic = (api: Api, isLiveEnvironment: boolean) => {
  const loadsClient = new LoadsClient(api, isLiveEnvironment);
  const vendorBidsClient = new VendorBidsClient(api);
  return (action$: ActionsObservable<Action>, state$: LoadInfoStateObservable) => {
    return merge$(
      fetchLoadBackhauls$(action$, loadsClient, state$),
      fetchRouteInfo$(action$, loadsClient, state$),
      fetchCreditRatings$(action$, loadsClient, state$),
      fetchRateCheck$(action$, loadsClient, state$),
      fetchLoadCargoChiefRate$(action$, loadsClient, state$),
      fetchLoadGreenscreensRate$(action$, loadsClient, state$),
      saveLoad$(action$, loadsClient, state$),
      addLoadNote$(action$, loadsClient, state$),
      addPrivateLoadNote$(action$, loadsClient, state$),
      callLoad$(action$, loadsClient, state$),
      setLoadProgress$(action$, loadsClient, state$),
      fetchShareLoadUrl$(action$, loadsClient, state$),
      fetchFuelPrice$(action$, loadsClient, state$),
      sendEmailToBroker$(action$, loadsClient),
      placeVendorBid$(action$, vendorBidsClient),
      bookNow(action$, loadsClient),
      hideLoad$(action$, loadsClient, state$)
    );
  };
};

export const isStateUnavailableForFuelPrice = (state: string) => state === 'YT' || state === 'NU';

import React, { useEffect } from 'react';

import { find, map, startsWith } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BiddingRole } from '@common/model';
import { fetchMoreBidSummaries } from '@common/redux/epic/bids/BidsEpic';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { InfiniteScrollView } from '@component/scrollView';
import { BidCardListItem } from '@page/bids/BidCards';
import { BID_ID_INDEX, BidsRouteParamsProps } from '@page/bids/BidsPanelRouting';
import { useSelector } from '@util/hooks';

export enum BidsOriginUI {
  CommunicationPage = 'CommunicationPage',
  MyLoads = 'MyLoads',
  FindLoads = 'FindLoads',
  PostLoads = 'PostLoads',
}

interface BidsListProps {
  isStandalone: boolean;
  scrollRef: React.RefObject<HTMLInputElement>;
  isLoading?: boolean;
}

export const BidsList = (props: BidsListProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCarrier = useIsCarrier();
  const params = useParams<BidsRouteParamsProps>();

  const bidSummaryIds = useSelector((state) => state.bids.bidSummaries.listAll.ids);
  const { nextToken, isLastPage, selectedFilters } = useSelector((state) => state.bids.bidSummaries.listAll);

  const paramsBidId = history.location.pathname.split('/')[BID_ID_INDEX] ?? params.bidID;

  // hasMore => true -> isLastPage = false
  // hasMore => false -> isLastPage = true
  // hasMore => false -> isLastPage = undefined
  const hasMore = isLastPage === false;

  useEffect(() => {
    if (hasMore && !props.isLoading && paramsBidId && bidSummaryIds.indexOf(paramsBidId) === -1) {
      loadMore();
    }
  }, [hasMore, nextToken, selectedFilters]);

  const bids = useSelector((state) => state.bids.loadBids.listAll.ids);

  const bidsList = props.isStandalone ? bidSummaryIds : [find(bids, (bidID) => startsWith(bidID, paramsBidId))];

  const loadMore = () => {
    if (nextToken && hasMore) {
      dispatch(
        fetchMoreBidSummaries(nextToken, isCarrier ? [BiddingRole.Carrier] : [BiddingRole.Broker], selectedFilters)
      );
    }
  };

  return (
    <InfiniteScrollView
      id="bids_list"
      hasMore={hasMore}
      loadMore={loadMore}
      rootRef={props.scrollRef}
      isLoading={!!props.isLoading}
      hideLoader={!props.isStandalone}
    >
      {map(bidsList, (bidId) =>
        bidId !== undefined ? (
          <BidCardListItem
            key={`BidCardListItem_${bidId}`}
            newMessages={0}
            isSelected={paramsBidId === bidId}
            bidId={bidId}
            isStandalone={props.isStandalone}
          />
        ) : null
      )}
    </InfiniteScrollView>
  );
};

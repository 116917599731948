import React from 'react';

import { compose } from 'recompose';
import styled from 'styled-components';

import { Checkbox, Step, StepContent, StepLabel, Stepper, withStyles } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import { StepLabelProps } from '@material-ui/core/StepLabel';

import { MultipurposeButton, MultipurposeButtonProps } from '@component/buttons';
import { singleDoubleColumnSelect } from '@component/panelSizeDetector/PanelSizeDetector';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { THEME } from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { MediaQueries } from '@util/MediaQueries';

export const Container = withTheme()(styled.div`
  && {
    position: relative;
    height: 72px;
    background-color: ${(props: ThemeProps) => props.theme.palette.primary.light};
    cursor: pointer;
  }
`);

export const Wrapper = styled.div`
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
  width: ${singleDoubleColumnSelect('100%', 'initial')};
`;

export const LoadAvailable = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span`
  color: ${Color.GRAY_DARK};
  font-size: 16px;
  font-weight: bold;
`;

export const Description = styled.div`
  && {
    color: ${Color.GRAY_DARK};
    font-size: 12px;
    @media ${MediaQueries.xxs} {
      button {
        font-size: 11px;
      }
    }
  }
`;

export const LabelSent = styled(Description)`
  && {
    width: 40%;
    text-align: center;
  }
`;

export const WrapperLabelSent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ActionButton = styled<MultipurposeButtonProps & { isHeightAuto?: boolean }>(MultipurposeButton)`
  && {
    border: 0;
    padding: 0;
    height: ${({ isHeightAuto }) => (isHeightAuto ? 'auto' : undefined)};
    &:hover {
      border: 0;
    }
    &:disabled {
      border: 0;
    }
    font-size: ${FontSize.ContentDetail}px;
    margin-bottom: 12px;
  }
`;

export const ActionButtonBold = styled(ActionButton)`
  && {
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    flex: 0;
  }
`;

export const QontoStep = compose(
  withTheme(),
  withStyles({
    completed: {
      '& > div': {
        borderColor: Color.GREEN_MAIN,
      },
    },
  })
)(Step);

export const BottomStepper = withTheme()(
  withStyles({
    vertical: {
      backgroundColor: Color.GRAY_LIGHT,
      borderTop: `1px solid ${Color.GRAY_SMOKE}`,
      [THEME.breakpoints.down(426)]: {
        marginBottom: 0,
      },
      padding: '24px 16px',
    },
  })(Stepper)
);

export const QontoConnector = withStyles({
  root: {
    top: 5,
    left: '-45%',
    right: '55%',
  },
  lineHorizontal: {
    borderColor: '#98e1a8',
    borderTopWidth: 2,
  },
  line: {
    minHeight: 0,
    marginLeft: 2,
    marginRight: 2,
  },
  completed: {
    '& $line': {
      borderColor: Color.GREEN_MAIN,
    },
  },
})(StepConnector);

export const QontoConnectorVertical = withStyles({
  root: {
    marginTop: -8,
    marginLeft: 5,
    borderLeft: '2px solid #98e1a8',
    paddingLeft: 15,
    paddingRight: 0,
    marginBottom: -14,
    paddingTop: 1,
    paddingBottom: 0,
    [THEME.breakpoints.down(426)]: {
      paddingRight: 0,
    },
  },
  lineVertical: {
    height: 0,
  },
  last: {
    borderColor: 'transparent !important',
    '& div': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
})(StepContent);

export const StepperWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const TopStepper = styled(Stepper)`
  && {
    position: relative;
    background: transparent;
    cursor: pointer;
    align-items: center;
    padding: 27px 0 0 46px;
    flex: 1;
    top: -14px;

    img {
      cursor: pointer;
    }

    > div {
      height: 12px;
      > div {
        left: ${singleDoubleColumnSelect('-42%', '-42%')};
        right: ${singleDoubleColumnSelect('58%', '58%')};
        @media (max-width: 375px) {
          left: -40%;
          right: 59%;
        }
        @media ${MediaQueries.xxs} {
          left: -38%;
          right: 61%;
        }
      }
    }

    > :last-child {
      padding-right: 8px;
    }
  }
`;

export const ExpandImage = styled.img`
  padding: 0 20px 0 10px;
`;

export const ActiveStepLabel = styled<{ isUnavailableStep?: boolean } & StepLabelProps>(
  ({ isUnavailableStep, ...rest }) => <StepLabel {...rest} />
)`
  && {
    display: flex;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;

    > span {
      position: absolute;
      top: -21px;
      padding: 1px 6px;
      border-radius: 15px;
      background-color: ${({ isUnavailableStep }) => (isUnavailableStep ? Color.RED_APPLE : Color.GREEN_MAIN)};
      width: auto;
      z-index: 1;

      &:after {
        content: '';
        display: block;
        background: inherit;
        width: 6px;
        height: 6px;
        position: absolute;
        left: calc(50% - 3px);
        bottom: -2px;
        transform: matrix(0.7, 1, -0.7, 1, 0, 0);
      }

      > span {
        font-size: 9px;
        color: ${Color.WHITE};
        font-weight: bold;
        letter-spacing: 0;
        text-transform: uppercase;
      }
    }
  }
`;
export const StepDescriptionBlockLastElement = styled.div`
  && {
    padding: 11px 0 12px 2px;
    > div:first-child {
      width: 100%;
    }
  }
`;

export const StepDescriptionBlock = styled(StepDescriptionBlockLastElement)`
  && {
    border-bottom: 1px dashed ${Color.GRAY_SMOKE};
    margin-bottom: 20px;
  }
`;

export const Row = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const SubTitleContainer = styled.div`
  display: flex;
  margin-bottom: 12px !important;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepCheckbox = styled(Checkbox)`
  && {
    padding: 0;
  }
`;

export const CheckBoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UpdateStatus = styled.div`
  position: absolute;
  width: 100%;
  bottom: 8px;
  color: ${Color.GREEN_MAIN};
  font-size: ${FontSize.Body}px;
  text-align: center;
`;

export const Left = styled.div`
  flex: 6;
`;
export const Right = styled.div`
  display: flex;
  justify-content: center;
  flex: 4;
  button {
    margin-top: -10px;
  }
`;

export const ManageLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 44px;
  margin-top: 10px;
`;
export const ManageLineTitle = styled.span`
  font-size: ${FontSize.Body}px;
  color: ${Color.GRAY_DARK};
`;

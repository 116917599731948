import React, { KeyboardEventHandler } from 'react';

import styled from 'styled-components';

import { FormControl, FormHelperText } from '@material-ui/core';
import { FormHelperTextProps } from '@material-ui/core/FormHelperText';

import { formatDateMMMdd } from '@common/helper';
import { DateChip, TextChip, TextChipWithoutPadding } from '@component/chip';
import { TimeChip } from '@component/chip/TimeChip';
import { ActionIcon } from '@component/icon/ActionIcon';
import { OverflowRenderer } from '@component/overflowRenderer/OverflowRenderer';
import { Color } from '@style/Color';
import { BorderRadius, BorderWidth, ElementSize, px, Spacing } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';

const SELECT_DISABLED_HEIGHT = 54;

export enum OptionType {
  Date = 'Date',
  PlainText = 'PlainText',
  Time = 'Time',
  State = 'State',
}

export interface Option {
  type: OptionType;
  value: string;
}

interface Props {
  id: string;
  label: string;
  options: Option[];
  mainIcon?: string;
  control?: { icon: string; id: string };
  containerStyle?: React.CSSProperties;
  onClick?: () => void;
  onFocus?: () => void;
  onControlClick?: () => void;
  helperText?: React.ReactNode;
  error?: boolean;
  dataSet?: { [name: string]: string | undefined };
  disabled?: boolean;
  isFixedAlignment?: boolean;
  innerRef?: React.RefObject<HTMLElement>;
  onKeyDown?: KeyboardEventHandler;
  inputRef?: React.RefObject<HTMLButtonElement>;
}

export interface ContainerProps {
  error?: boolean;
  disabled?: boolean;
}

// CSS properties from MUI TextField
// recreates the 1px "inner" bottom border when disabled
const Container = withTheme()(styled.button<ContainerProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: ${(props: ThemeProps & ContainerProps) =>
    `${px(props.disabled ? 0 : 1)} solid ${props.theme.palette.multiSelect.borderColor}`};
  border-bottom-width: ${(props: ContainerProps) => (props.disabled ? px(BorderWidth.Thin) : px(BorderWidth.Medium))};
  border-bottom-color: ${(props: ThemeProps & ContainerProps) =>
    props.error
      ? props.theme.palette.textField.error
      : props.disabled
        ? Color.GRAY_3
        : props.theme.palette.multiSelect.borderColor};
  border-radius: ${px(BorderRadius.Button)} ${px(BorderRadius.Button)} 0 0;
  outline: ${(props: ContainerProps & ThemeProps) =>
    props.disabled ? `1px solid ${props.theme.palette.multiSelect.borderColor}` : 'none'};
  height: ${(props: ContainerProps) => px(props.disabled ? SELECT_DISABLED_HEIGHT : ElementSize.HeaderHeight)};
  max-height: ${(props: ContainerProps) => px(props.disabled ? SELECT_DISABLED_HEIGHT : ElementSize.HeaderHeight)};
  box-shadow: none;
  cursor: ${(props: ContainerProps) => (props.disabled ? 'default' : 'pointer')};
  transition: all 0.5s ease;
  padding: ${Spacing.InterElementVertical}px ${Spacing.InterElementHorizontal}px ${Spacing.InterElementVertical}px
    ${Spacing.ElementPaddingHorizontal}px;
  flex: 1;
  background-color: ${(props: ThemeProps & ContainerProps) =>
    props.disabled ? props.theme.palette.textField.disabledBackground : props.theme.palette.background.white};
  margin-top: ${(props: ContainerProps) => px(props.disabled ? 1 : 0)};
  &::after {
    left: 1px;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: ${BorderWidth.Medium}px solid ${Color.GREEN_MAIN};
    pointer-events: none;
    width: calc(100% - 2px);
  }
  &:focus::after {
    transform: scaleX(1);
    bottom: 1px;
  }
`);

const StyledFormControl = styled(FormControl)`
  align-self: stretch;
  flex: 1;
`;

//need to override color properties from material
const HelperText = withTheme()(styled<FormHelperTextProps>(FormHelperText)`
  && {
    color: ${(props: FormHelperTextProps & ThemeProps) =>
      props.error ? props.theme.palette.textField.error : props.theme.palette.textField.normalText} !important;
    overflow: hidden;
    margin-top: 2px;
    margin-left: ${px(Spacing.ElementPaddingHorizontal)};
    font-size: ${(props: ThemeProps) => props.theme.text.note.fontSize};
    line-height: 1.33;
    letter-spacing: normal;
    font-stretch: normal;
    font-family: Roboto;
    width: 100%;
  }
`);

const MainIcon = styled.img`
  margin-right: ${px(Spacing.ScreenSide)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  align-self: stretch;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  display: inline-block;
  flex: 1;
  overflow: hidden;
`;

const Header = withTheme()(styled.label<ContainerProps>`
  color: ${(props: ThemeProps & ContainerProps) =>
    props.error ? props.theme.palette.textField.error : props.theme.palette.multiSelect.labelColor};
  font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
  font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
  line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
`);

const TextArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  white-space: 'nowrap';
  overflow: 'hidden';
  text-overflow: 'ellipsis';
`;

export const MultiSelect: React.FC<Props> = (props) => {
  const chipForOption = (option: Option, index: number) => {
    switch (option.type) {
      case OptionType.Date:
        return (
          <DateChip
            id={`${props.id}_${index}`}
            text={formatDateMMMdd(option.value)}
            disabled={props.disabled}
            tabIndex={-1}
          />
        );
      case OptionType.Time:
        return <TimeChip id={`${props.id}_${index}`} text={option.value} disabled={props.disabled} tabIndex={-1} />;
      case OptionType.State:
        return <TextChipWithoutPadding id={`${props.id}_${index}`} text={option.value} tabIndex={-1} />;
      default:
        return <TextChip id={`${props.id}_${index}`} text={option.value} tabIndex={-1} />;
    }
  };

  const renderItem = (option: Option, index: number) => {
    return (
      <div
        style={{
          marginRight: `${Spacing.InterListElement}px`,
        }}
        key={index}
      >
        {chipForOption(option, index)}
      </div>
    );
  };

  return (
    <StyledFormControl
      style={{
        alignSelf: props.isFixedAlignment ? 'auto' : 'stretch',
        minWidth: props.isFixedAlignment ? '100%' : '50%',
      }}
      {...props.dataSet}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
    >
      <Container
        style={props.containerStyle}
        id={props.id}
        onClick={props.disabled ? undefined : props.onClick}
        onFocus={props.disabled ? undefined : props.onFocus}
        error={props.error}
        disabled={props.disabled}
        innerRef={props.inputRef}
      >
        {props.mainIcon ? <MainIcon id={`${props.id}_icon`} src={props.mainIcon} alt={t(T.altText_icon)} /> : null}
        <ContentContainer>
          <Content>
            <Header
              id="title"
              error={props.error}
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {props.label}
            </Header>
            <TextArea>
              <OverflowRenderer id={props.id} items={props.options} renderItem={renderItem} />
            </TextArea>
          </Content>
        </ContentContainer>
        {props.control && !props.disabled ? (
          <ActionIcon
            id={props.control.id}
            src={props.control.icon}
            alt={t(T.altText_control)}
            size={20}
            onClick={handleControlClick(props.onControlClick)}
            tabIndex={-1}
          />
        ) : null}
      </Container>
      {props.helperText ? <HelperText error={props.error}>{props.helperText}</HelperText> : null}
    </StyledFormControl>
  );
};

const handleControlClick = (onControlClick?: () => void) => (event?: React.FormEvent) => {
  event?.stopPropagation();
  if (onControlClick) {
    onControlClick();
  }
};

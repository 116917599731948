import { castArray } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { debounceTime as debounce$, flatMap as flatMap$, map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { Response } from '@common/redux/Base';

//--------------------------------------
// Epic boilerplate Helpers
//--------------------------------------

/** Implementation of a single Epic API Endpoint with onSuccess/onError callbacks
 *
 * In onSuccess/onError you can emit multiple action by returning an array.
 * You can also emit no action (not normal) by returning an empty array.
 */
export const standardApiEpic = <T extends {}>(
  action$: ActionsObservable<Action>,
  actionType: string,
  getRequest$: (action: Action) => Observable<ApiResponse123<T>>,
  onSuccess: (response: ApiResponseSuccess<T>, action: Action) => Action | Action[],
  onError: (error: ApiError, action: Action) => Action | Action[],
  debounceTime?: number
) => {
  const execution$ = mergeMap$((action: Action): Observable<Action> => {
    return getRequest$(action).pipe(
      flatMap$((response: ApiResponse123<T>) => {
        if (response.success) {
          return castArray(onSuccess(response as ApiResponseSuccess<T>, action));
        } else {
          return castArray(onError(response as ApiError, action));
        }
      })
    );
  });
  if (debounceTime) {
    return action$.ofType(actionType).pipe(debounce$(debounceTime), execution$);
  } else {
    return action$.ofType(actionType).pipe(execution$);
  }
};

/**  Implementation of a single Epic API Endpoint with 'fulfilled' actionCreators handling and optional success sideEffect */
export const simpleApiEpicToAction = <T extends {}>(
  action$: ActionsObservable<Action>,
  actionType: string,
  getRequest$: (action: Action) => Observable<ApiResponse123<T>>,
  fulfilledActionCreator: (response: Response<T>, action: Action) => Action,
  sideEffect?: (response: ApiResponseSuccess<T>) => void
) => {
  return action$.ofType(actionType).pipe(
    mergeMap$((action: Action): Observable<Action> => {
      return getRequest$(action).pipe(
        map$((response: ApiResponse123<T>) => {
          const successResp = response as ApiResponseSuccess<T>;
          if (response.success) {
            if (sideEffect) {
              sideEffect(successResp);
            }
            return fulfilledActionCreator({ success: true, payload: successResp.data }, action);
          } else {
            return fulfilledActionCreator({ success: false, error: response as ApiError }, action);
          }
        })
      );
    })
  );
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { TmsClient } from '@common/client';
import { Tms } from '@common/model';
import { BaseState, Response } from '@common/redux/Base';

const POST_TMS = 'POST_TMS';
const POST_TMS_FULFILLED = 'POST_TMS_FULFILLED';

export type TmsResponse = Response<{}>;

interface PostTms extends Action {
  tms: Tms;
}

interface PostTmsResponse extends Action {
  response: TmsResponse;
}

export function postTms(tms: Tms): PostTms {
  return {
    type: POST_TMS,
    tms: tms,
  };
}

export interface TmsState extends BaseState {
  response: TmsResponse;
}

const postTmsResponse = (response: TmsResponse): PostTmsResponse => ({
  type: POST_TMS_FULFILLED,
  response: response,
});

export const tmsReducer = (state = {}, action: Action) => {
  switch (action.type) {
    case POST_TMS_FULFILLED: {
      const tmsAction = action as PostTmsResponse;
      return {
        ...state,
        response: tmsAction.response,
        isLoading: false,
      };
    }
    case POST_TMS:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export const createTmsEpic = (api: Api) => {
  const tmsClient = new TmsClient(api);
  return (action$: ActionsObservable<PostTms>) =>
    action$.ofType(POST_TMS).pipe(
      mergeMap((payload) => {
        return tmsClient.postTms$(payload.tms).pipe(
          map((response: ApiResponse123<{}>) => {
            if (response.success) {
              const successResp = response as ApiResponseSuccess<{}>;
              return postTmsResponse({ success: response.success, payload: successResp.data });
            } else {
              const error = response as ApiError;
              return postTmsResponse({ success: response.success, error: error });
            }
          })
        );
      })
    );
};

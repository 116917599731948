import { compact, difference, differenceBy, find, intersection, map } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiErrorCode } from '@common/api';
import { UserPlanManagementClient } from '@common/client/UserPlanManagementClient';
import {
  LbPlanPackage,
  MembershipData,
  MembershipType,
  MembershipTypeResponse,
  PromoCodeInfo,
  RetentionPlanInfo,
  SelectedMembershipData,
  TaxRate,
  UpgradePlanError,
  UserPlan,
} from '@common/model/UserPlan';
import { ApiAction, createAction, createApiAction, createApiActionWithFetchData } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export const DEFAULT_PROMO_CODE = '50000';
export const WELCOME_BACK_PROMO_CODE = '50120';
export const DEFAULT_SELECTED_MEMBERSHIP_PLAN = 'Premium Plus';
export const BASIC_MEMBERSHIP_PLAN = 'Basic';

export const AVAILABLE_PROMO_CODES = ['50100', '50110', '50120', '50125'];

export const applyPromoCodeForUsersPlanAction = createApiActionWithFetchData<
  PromoCodeInfo | undefined,
  UserPlan,
  { appliedPromoCode: PromoCodeInfo }
>('APPLY_PROMO_CODE_FOR_USER_PLANS');
export const fetchUserPlansAction = createApiActionWithFetchData<
  PromoCodeInfo | undefined,
  UserPlan,
  { appliedPromoCode: PromoCodeInfo | undefined }
>('FETCH_USER_PLANS');
const fetchUserPlansWithoutPromoCodeAction = createApiAction<undefined, UserPlan>(
  'FETCH_USER_PLANS_WITHOUT_PROMO_CODE'
);
const postSelectAddOnAction = createApiAction<string[], {}>('POST_SELECT_ADD_ON');
const postDeselectAddOnAction = createApiAction<string[], {}>('POST_DESELECT_ADD_ON');
export const fetchMembershipsDataAction = createApiAction<undefined, MembershipData, {}>('FETCH_MEMBERSHIP_DATA');
const changeMembershipPlanAction = createApiAction<string, {}>('CHANGE_MEMBERSHIP_PLAN');
const fetchMembershipTypeAction = createApiAction<undefined, MembershipTypeResponse>('FETCH_MEMBERSHIP_TYPE');
const createMembershipAction = createApiAction<undefined, {}>('CREATE_MEMBERSHIP');
const getRetentionPlanAction = createApiAction<undefined, RetentionPlanInfo>('FETCH_RETENTION_PLAN');

const clearUserPlansAction = createAction<undefined>('CLEAR_USER_PLANS');
const addAddOnsToSelectedMembershipPlanAction = createAction<string[]>('ADD_ADDONS_TO_SELECTED_MEMBERSHIP_PLAN');
const removeAddOnsFromSelectedMembershipPlanAction = createAction<string[]>(
  'REMOVE_ADDONS_FROM_SELECTED_MEMBERSHIP_PLAN'
);
const changeSelectedMembershipPlanAction = createAction<string>('CHANGE_SELECTED_MEMBERSHIP_PLAN');

export const fetchUserPlans = (promoCodeInfo?: PromoCodeInfo) => fetchUserPlansAction.fetchAction(promoCodeInfo);
export const applyPromoCodeForUsersPlan = (promoCodeInfo: PromoCodeInfo) =>
  applyPromoCodeForUsersPlanAction.fetchAction(promoCodeInfo);
export const fetchUserPlansWithoutPromoCode = () => fetchUserPlansWithoutPromoCodeAction.fetchAction(undefined);
export const postSelectAddOn = (addOnData: string[]) => postSelectAddOnAction.fetchAction(addOnData);
export const postDeselectAddOn = (addOnData: string[]) => postDeselectAddOnAction.fetchAction(addOnData);
export const fetchMembershipsData = () => fetchMembershipsDataAction.fetchAction(undefined);
export const changeMembershipPlan = (planID: string) => changeMembershipPlanAction.fetchAction(planID);
export const fetchMembershipType = () => fetchMembershipTypeAction.fetchAction(undefined);
export const createMembership = () => createMembershipAction.fetchAction(undefined);
export const getRetentionPlan = () => getRetentionPlanAction.fetchAction(undefined);

export const clearUserPlans = () => clearUserPlansAction.action(undefined);
export const changeSelectedMembershipPlan = (planID: string) => changeSelectedMembershipPlanAction.action(planID);
export const addAddOnsToSelectedMembershipPlan = (addOnData: string[]) =>
  addAddOnsToSelectedMembershipPlanAction.action(addOnData);
export const removeAddOnsFromSelectedMembershipPlan = (addOnData: string[]) =>
  removeAddOnsFromSelectedMembershipPlanAction.action(addOnData);

type FetchPlansAction = ApiAction<PromoCodeInfo | undefined>;

export interface UserPlanState {
  isLoadingPlans: boolean;
  isLoadingDefaultPlans: boolean;
  lbPlans: LbPlanPackage[];
  lbPlansAvailableForPromoCode: LbPlanPackage[];
  lbPlansWithoutPromoCode: LbPlanPackage[];
  error?: {
    type: UpgradePlanError;
    time: number;
  };
  appliedPromoCode?: string;
  isLoadingData: boolean;
  membershipData?: MembershipData;
  isMembershipDataFetched: boolean;
  isChangingPlan: boolean;
  isPlanChangeSuccessful?: boolean;
  isLoadingAddOn: boolean;
  isAddOnAdded?: boolean;
  isAddOnRemoved?: boolean;
  membershipType?: MembershipType;
  isLoadingType: boolean;
  selectedPlan: SelectedMembershipData;
  taxRate?: TaxRate;
  membershipError?: ApiError;
  promoCodeIsAvailable: boolean;
  isPlanFetchSuccessful: boolean;
  isDefaultPlanFetchSuccessful: boolean;
  retentionPlan?: RetentionPlanInfo;
}

const initialState: UserPlanState = {
  isLoadingPlans: false,
  isLoadingDefaultPlans: false,
  lbPlans: [],
  lbPlansWithoutPromoCode: [],
  lbPlansAvailableForPromoCode: [],
  isLoadingData: false,
  isChangingPlan: false,
  isLoadingAddOn: false,
  isLoadingType: false,
  selectedPlan: {
    selectedAddOns: [],
  },
  isMembershipDataFetched: false,
  promoCodeIsAvailable: true,
  isPlanFetchSuccessful: false,
  isDefaultPlanFetchSuccessful: false,
};

export const userPlanReducer = createMergedReducer(initialState, [
  clearUserPlansAction.addCase((state) => {
    state.lbPlans = [];
  }),
  changeSelectedMembershipPlanAction.addCase((state, action) => {
    const currentPlan = find(state.lbPlans, (plan) => plan.guid === action.data);
    if (currentPlan) {
      state.selectedPlan = {
        ...state.selectedPlan,
        planName: currentPlan.name,
      };
    }
  }),

  applyPromoCodeForUsersPlanAction.initiateCase((state) => {
    state.isLoadingPlans = true;
    state.promoCodeIsAvailable = true;
  }),
  applyPromoCodeForUsersPlanAction.completeCase((state, action) => {
    state.isLoadingPlans = false;
    if (action.response.success) {
      state.lbPlansAvailableForPromoCode = action.response.payload.packages;
      updateSelectedPlanData(state, action.response.payload.packages);
      const selectedPlanName = state.selectedPlan.planName;
      const selectedPlanFromNewPackagesList = find(action.response.payload.packages, { name: selectedPlanName });
      if (selectedPlanFromNewPackagesList) {
        state.appliedPromoCode = action.appliedPromoCode?.promoCode;
        state.promoCodeIsAvailable = true;
      } else {
        state.promoCodeIsAvailable = false;
      }
    } else {
      state.error = {
        type:
          action.response.error.code === ApiErrorCode.UPGRADE_PROMO_CODE_INVALID
            ? UpgradePlanError.InvalidPromoCode
            : UpgradePlanError.Failed,
        time: Date.now(),
      };
    }
  }),

  fetchUserPlansAction.initiateCase((state) => {
    state.isLoadingPlans = true;
  }),
  fetchUserPlansAction.completeCase((state, action) => {
    state.isLoadingPlans = false;
    if (action.response.success) {
      state.isPlanFetchSuccessful = true;
      state.lbPlans = action.response.payload.packages;
      state.appliedPromoCode = action.appliedPromoCode?.promoCode;
      if (action.fetchData?.isToRemove) {
        updateSelectedPlanData(state, action.response.payload.packages);
      } else {
        state.taxRate = action.response.payload.taxRate;
      }
    } else {
      if (action.response.error.code === ApiErrorCode.UPGRADE_PROMO_CODE_INVALID) {
        state.error = {
          type: UpgradePlanError.InvalidPromoCode,
          time: Date.now(),
        };
        state.isPlanFetchSuccessful = true;
      } else {
        state.isPlanFetchSuccessful = false;
      }
    }
  }),
  fetchUserPlansWithoutPromoCodeAction.initiateCase((state) => {
    state.isLoadingDefaultPlans = true;
  }),
  fetchUserPlansWithoutPromoCodeAction.completeCase((state, action) => {
    state.isLoadingDefaultPlans = false;
    if (action.response.success) {
      state.isDefaultPlanFetchSuccessful = true;
      state.lbPlansWithoutPromoCode = action.response.payload.packages;
    } else {
      state.isDefaultPlanFetchSuccessful = false;
    }
  }),

  postSelectAddOnAction.initiateCase((state) => {
    state.isLoadingAddOn = true;
    state.isAddOnAdded = undefined;
  }),
  postSelectAddOnAction.completeCase((state, action) => {
    state.isLoadingAddOn = false;
    state.isAddOnAdded = action.response.success;
  }),

  postDeselectAddOnAction.initiateCase((state) => {
    state.isLoadingAddOn = true;
    state.isAddOnRemoved = undefined;
  }),
  postDeselectAddOnAction.completeCase((state, action) => {
    state.isLoadingAddOn = false;
    state.isAddOnRemoved = action.response.success;
  }),

  fetchMembershipsDataAction.initiateCase((state) => {
    state.isLoadingData = true;
    state.membershipData = undefined;
    state.isMembershipDataFetched = false;
  }),
  fetchMembershipsDataAction.completeCase((state, action) => {
    state.isLoadingData = false;
    if (action.response.success) {
      state.isMembershipDataFetched = true;
      state.membershipData = action.response.payload;
      state.membershipError = undefined;

      if (
        action.response.payload.isBroker &&
        action.response.payload.planName &&
        action.response.payload.planName !== BASIC_MEMBERSHIP_PLAN
      ) {
        state.selectedPlan.planName = action.response.payload.planName;
      } else {
        state.selectedPlan.planName =
          action.response.payload.package.name === BASIC_MEMBERSHIP_PLAN
            ? DEFAULT_SELECTED_MEMBERSHIP_PLAN
            : action.response.payload.package.name;
      }
    } else {
      state.membershipError = action.response.error;
      if (action.response.error.code === 4040129) {
        state.isMembershipDataFetched = false;
      } else {
        state.isMembershipDataFetched = true;
      }
    }
  }),

  changeMembershipPlanAction.initiateCase((state) => {
    state.isChangingPlan = true;
    state.isPlanChangeSuccessful = undefined;
  }),
  changeMembershipPlanAction.completeCase((state, action) => {
    state.isChangingPlan = false;
    state.isPlanChangeSuccessful = action.response.success;
  }),

  fetchMembershipTypeAction.initiateCase((state) => {
    state.isLoadingType = true;
  }),
  fetchMembershipTypeAction.completeCase((state, action) => {
    state.isLoadingType = false;
    if (action.response.success && action.response.payload) {
      state.membershipType = action.response.payload.membershipType;
    }
  }),

  getRetentionPlanAction.initiateCase((state) => {
    state.retentionPlan = undefined;
  }),
  getRetentionPlanAction.completeCase((state, action) => {
    if (action.response.success) {
      state.retentionPlan = action.response.payload;
    }
  }),

  addAddOnsToSelectedMembershipPlanAction.addCase((state, action) => {
    state.selectedPlan = {
      ...state.selectedPlan,
      selectedAddOns: [...state.selectedPlan.selectedAddOns, ...action.data],
    };
  }),
  removeAddOnsFromSelectedMembershipPlanAction.addCase((state, action) => {
    state.selectedPlan = {
      ...state.selectedPlan,
      selectedAddOns: difference(state.selectedPlan?.selectedAddOns, action.data),
    };
  }),
]);

const fetchUserPlans$ = (action$: ActionsObservable<Action>, client: UserPlanManagementClient) =>
  action$.ofType(fetchUserPlansAction.fetchType).pipe(
    mergeMap$((action: FetchPlansAction) =>
      client.getUserPlans$(action.data?.promoCode, action.data?.isToRemove ? false : true).pipe(
        map$((response) => ({
          ...fetchUserPlansAction.responseAction(response, action),
          appliedPromoCode: action.data,
        }))
      )
    )
  );

const applyPromoCodeForUsersPlan$ = (action$: ActionsObservable<Action>, client: UserPlanManagementClient) =>
  action$.ofType(applyPromoCodeForUsersPlanAction.fetchType).pipe(
    mergeMap$((action: FetchPlansAction) =>
      client
        .getUserPlans$(action.data?.promoCode, action.data?.promoCode || action.data?.isToRemove ? false : true)
        .pipe(
          map$((response) => ({
            ...applyPromoCodeForUsersPlanAction.responseAction(response, action),
            appliedPromoCode: action.data,
          }))
        )
    )
  );

export const createUserPlanManagementEpic = (api: Api) => {
  const client = new UserPlanManagementClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      applyPromoCodeForUsersPlan$(action$, client),
      fetchUserPlans$(action$, client),
      fetchUserPlansWithoutPromoCodeAction.createEpic$(action$, () => client.getUserPlans$(undefined, false)),
      postSelectAddOnAction.createEpic$(action$, client.postSelectAddOns$),
      postDeselectAddOnAction.createEpic$(action$, client.postDeselectAddOns$),
      fetchMembershipsDataAction.createEpic$(action$, client.fetchMembershipsData$),
      changeMembershipPlanAction.createEpic$(action$, client.changeMembershipPlan$),
      fetchMembershipTypeAction.createEpic$(action$, client.fetchMembershipType$),
      createMembershipAction.createEpic$(action$, client.createMembership$),
      getRetentionPlanAction.createEpic$(action$, client.getRetentionPlans$)
    );
};

const updateSelectedPlanData = (state: UserPlanState, packages: LbPlanPackage[]) => {
  const selectedPlanFromNewPackagesList = find(packages, { name: state.selectedPlan.planName });
  if (selectedPlanFromNewPackagesList) {
    state.selectedPlan.planName = selectedPlanFromNewPackagesList?.name;
    const newSelectedPlanAdditionAddOns = map(selectedPlanFromNewPackagesList?.additionalAddOns, (addOn) => addOn.name);
    const commonAddOnsCurrentlySelected = compact(
      intersection(newSelectedPlanAdditionAddOns, state.selectedPlan.selectedAddOns)
    );
    state.selectedPlan.selectedAddOns = commonAddOnsCurrentlySelected ?? [];
    const plansNotIncludedInPromoCode = differenceBy(state.lbPlans, packages, (plan) => plan.name);
    state.lbPlans = [...packages, ...plansNotIncludedInPromoCode];
  }
};

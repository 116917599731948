import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { IconButton, ListItemText, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { isBrokerBlocked, isBrokerFavorite, isOnboarded, loadProgressLabelMap } from '@common/helper';
import { Load, LoadProgress, LoadStatus } from '@common/model';
import { favoriteBrokers, hideBrokers, onboardBrokers } from '@common/redux/epic/BrokerEpic';
import { DropdownMenu } from '@component/menu/DropdownMenu';
import { ComponentProps as PanelHeaderProps, PanelHeader } from '@component/panel';
import { onboardedConfirmation } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPopups';
import { ResultViewType } from '@component/panels/findLoads/searchPanel/SearchPanelHelper';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupCancelButton, PopupDangerButton } from '@component/popup/PopupButtons';
import { PopupSizes, PopupTracking, usePopup } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { t, T } from '@translate';
import icons from '@util/iconsConstants';

const LoadDetailsPanelHeader = styled<{ isLoadUnavailable: boolean } & PanelHeaderProps>(
  ({ isLoadUnavailable, ...rest }) => <PanelHeader {...rest} />
)`
  background-color: ${({ isLoadUnavailable }) => (isLoadUnavailable ? Color.ORANGE_MARIGOLD : Color.WHITE)};
`;

const HeaderSubtitle = styled(({ isLoadUnavailable, ...rest }) => <span {...rest} />)`
  font-size: ${FontSize.Body}px;
  color: ${({ isLoadUnavailable }) => (isLoadUnavailable ? Color.RED_APPLE : Color.BLACK)};
`;

interface Props {
  load: Load | undefined;
  isLoadDetailsFetched: boolean;
  viewType: ResultViewType;
  onClose?: () => void;
}

export const Header = (props: Props) => {
  const isBlocked = isBrokerBlocked(props.load);
  const isFavorite = isBrokerFavorite(props.load);
  const isOnboard = isOnboarded(props.load);
  const loadProgress = props.isLoadDetailsFetched ? props.load?.metadata?.userdata?.progress : undefined;
  const isLoadUnavailable =
    props.isLoadDetailsFetched &&
    props.load?.status !== LoadStatus.Online &&
    loadProgress === LoadProgress.LoadAvailable;
  const [menuAnchor, setMenuAnchor] = useState(undefined);
  const dispatch = useDispatch();
  const popupContext = usePopup();

  const handleMenuClick = (e: React.BaseSyntheticEvent) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(undefined);
  };

  const handleFavoriteClick = () => {
    favoriteClick(props.load, dispatch);
    setMenuAnchor(undefined);
  };

  const onOnboardedClick = () => {
    onboardedClick(props.load, dispatch);
  };

  const handleTagAsOnboarded = () => {
    onboardedConfirmation(onOnboardedClick, popupContext);
  };

  const handleOnboardedClick = () => {
    const isOnboardedTaged = isOnboarded(props.load);
    if (isOnboardedTaged) {
      onOnboardedClick();
    } else {
      handleTagAsOnboarded();
    }
    setMenuAnchor(undefined);
  };

  const handleBlockClick = () => {
    blockClick(props.load, dispatch, popupContext, props.onClose, props.viewType);
    setMenuAnchor(undefined);
  };

  const subtitle = loadProgress ? (
    <HeaderSubtitle id="subtitle" isLoadUnavailable={isLoadUnavailable}>
      {`(${isLoadUnavailable ? t(T.common_load_loadUnavailable) : loadProgressLabelMap[loadProgress]})`}
    </HeaderSubtitle>
  ) : null;

  return (
    <LoadDetailsPanelHeader
      label={t(T.findLoads_loadDetails_header)}
      subtitle={subtitle}
      hasCloseButton={props.onClose !== undefined}
      onClose={props.onClose}
      isLoadUnavailable={isLoadUnavailable}
    >
      {!isBlocked &&
        props.load &&
        props.load.status !== LoadStatus.Deleted &&
        props.load.status !== LoadStatus.Offline && (
          <>
            <IconButton
              id="menu"
              key="menu"
              color="inherit"
              onClick={handleMenuClick}
              disabled={!props.isLoadDetailsFetched}
            >
              <MoreVert aria-controls="customized-menu" aria-haspopup="true" />
            </IconButton>
            <DropdownMenu anchor={menuAnchor} onClose={handleMenuClose}>
              <MenuItem id={`${isOnboard ? 'tag_onboarded' : 'remove_onboarded'}`} onClick={handleOnboardedClick}>
                <img id="icon" src={icons.onboardedOn} alt={'onboarded'} />
                <ListItemText
                  id="title"
                  primary={
                    isOnboard ? t(T.findLoads_findLoads_removeOnboardedTag) : t(T.findLoads_findLoads_tagAsOnboarded)
                  }
                />
              </MenuItem>
              <MenuItem id={`${isFavorite ? 'remove_favorite' : 'favorite'}`} onClick={handleFavoriteClick}>
                <img id="icon" src={icons.favorite} alt={'favorite'} />
                <ListItemText
                  id="title"
                  primary={
                    isFavorite ? t(T.findLoads_findLoads_removeFavorite) : t(T.findLoads_findLoads_favoriteCompany)
                  }
                />
              </MenuItem>
              <MenuItem id="block" onClick={handleBlockClick}>
                <img id="icon" src={icons.block} alt={'block'} />
                <ListItemText id="title" primary={t(T.findLoads_findLoads_blockCompany)} />
              </MenuItem>
            </DropdownMenu>
          </>
        )}
    </LoadDetailsPanelHeader>
  );
};

const blockClick = (
  load: Load | undefined,
  dispatch: Dispatch,
  popupContext: PopupTracking,
  onClose: (() => void) | undefined,
  viewType: ResultViewType
) => {
  if (load?.poster) {
    const { id } = load.poster;
    if (isBrokerBlocked(load)) {
      dispatch(hideBrokers([{ id: id, hide: false }], ResultViewType.Backhaul === viewType, undefined));
    } else {
      popupContext.openingPopup({
        body: (
          <BasePopup
            title={t(T.findLoads_findLoads_popUps_blockCompany_title)}
            buttons={[
              <PopupCancelButton testID={'cancel'} key={'cancel'} action={popupContext.closingPopup} />,
              <PopupDangerButton
                testID={'block'}
                key={'block'}
                label={t(T.findLoads_findLoads_popUps_blockCompany_button_block)}
                action={() => {
                  dispatch(hideBrokers([{ id: id, hide: true }], ResultViewType.Backhaul === viewType, undefined));
                  popupContext.closingPopup();
                  dispatch(
                    showSnackbar({
                      message: t(T.snackbars_companyBlocked),
                    })
                  );
                  onClose?.();
                }}
              />,
            ]}
          >
            {t(T.findLoads_findLoads_popUps_blockCompany_content)}
          </BasePopup>
        ),
        width: PopupSizes.SMALL,
        isDismissible: true,
        hasCloseButton: true,
      });
    }
  }
};

const favoriteClick = (load: Load | undefined, dispatch: Dispatch) => {
  const updatedIsFavorite = !isBrokerFavorite(load);
  if (load?.poster) {
    dispatch(favoriteBrokers([{ id: load.poster.id, favorite: updatedIsFavorite }], undefined));
    dispatch(
      showSnackbar({
        message: updatedIsFavorite ? t(T.snackbars_companyFavorited) : t(T.snackbars_companyUnfavorited),
      })
    );
  }
};

const onboardedClick = (load: Load | undefined, dispatch: Dispatch) => {
  const updatedIsOnboarded = !isOnboarded(load);
  if (load?.poster) {
    dispatch(onboardBrokers([{ id: load.poster.id, onboarded: updatedIsOnboarded }], undefined));
    dispatch(
      showSnackbar({
        message: updatedIsOnboarded
          ? t(T.findLoads_findLoads_company_added_onboarded)
          : t(T.findLoads_findLoads_company_removed_onboarded),
      })
    );
  }
};

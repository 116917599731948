import React, { useEffect, useMemo, useRef } from 'react';

import { shallowEqual, useSelector as baseUseSelector } from 'react-redux';

import { StoreState } from '@/reduxStore/Store';

/**
 * A wrapper function for the redux useSelector function so we don't have to import the whole StoreState into our components
 */
export const useSelector = <T>(selector: (storeState: StoreState) => T) => baseUseSelector<StoreState, T>(selector);

export const useReduxSelector = <T extends object>(
  selector: (storeState: StoreState) => T,
  equalityFn: (left: T, right: T) => boolean = shallowEqual
) => baseUseSelector<StoreState, T>(selector, equalityFn);

/**
 * Transform values from a selector in a memoized way.
 * @param selector redux selector just like one that you put into useSelector (strict equality)
 * @param transformer transformation function that takes selected item and returns mutated value
 * @param deps non selected dependencies you use inside of the transformer
 */
export const useReselect = <T, R>(
  selector: (storeState: StoreState) => T,
  transformer: (res: T) => R,
  deps: React.DependencyList = []
) => {
  const selectedItem = useSelector(selector);
  return useMemo(() => transformer(selectedItem), [selectedItem, ...deps]);
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const usePreviousWithInitialValue = <T>(value: T, initialValue: T) => {
  const ref = useRef<T>(initialValue);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

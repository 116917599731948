import { combineEpics } from 'redux-observable';

import { createCapacityFinderEpic } from '@/reduxStore/epic/CapacityFinderEpic';
import { createInboxMessageContentEpic } from '@/reduxStore/epic/InboxMessageContentEpic';
import { createLocationEpic$ } from '@/reduxStore/epic/LocationEpic';
import { createMileageCalculatorEpic } from '@/reduxStore/epic/MileageCalculatorEpic';
import { createPostingContactsEpic } from '@/reduxStore/epic/PostingContactsEpic';
import { createPostLoadEpic } from '@/reduxStore/epic/PostLoadEpic';
import { createPostLoadFormEpic$ } from '@/reduxStore/epic/PostLoadFormEpic';
import { createReducerSyncEpic$ } from '@/reduxStore/epic/ReducerSyncEpic';
import { createTruckEpic } from '@/reduxStore/epic/TruckEpic';
import { createUnsubscribeEpic } from '@/reduxStore/epic/UnsubscribeEpic';
import { createURLScrapeEpic } from '@/reduxStore/epic/URLScrapeEpic';
import { createUserSettingsEpic } from '@/reduxStore/epic/UserSettingsEpic';
import { createAvatarEpic } from '@common/redux/epic/AvatarEpic';
import { createBidsEpic } from '@common/redux/epic/bids/BidsEpic';
import { createVendorBidsEpic } from '@common/redux/epic/bids/VendorBidsEpic';
import { createBrokerEpic } from '@common/redux/epic/BrokerEpic';
import { createCarrierTrackingEpic } from '@common/redux/epic/CarrierTrackingEpic';
import { createCommunicationsEpic$ } from '@common/redux/epic/CommunicationEpic';
import { createCompanyAvatarEpic } from '@common/redux/epic/CompanyAvatarEpic';
import { createCompanyEpic } from '@common/redux/epic/CompanyEpic';
import { createDocumentsEpic } from '@common/redux/epic/DocumentsEpic';
import { createFeedbackEpic } from '@common/redux/epic/FeedbackEpic';
import { createFtpEpic } from '@common/redux/epic/FtpEpic';
import { createIdentityVerificationEpic } from '@common/redux/epic/IdentityVerificationEpic';
import { createInboxEpic } from '@common/redux/epic/InboxEpic';
import { createLoadAvailabilityEpic } from '@common/redux/epic/LoadAvailabilityEpic';
import { loadDetailsEpicCreator$ } from '@common/redux/epic/LoadDetailsEpic';
import { createLoadDetailsMapEpic } from '@common/redux/epic/LoadDetailsMapEpic';
import { createLoadFeedbackEpic } from '@common/redux/epic/LoadFeedbackEpic';
import { createLoadInfoEpic } from '@common/redux/epic/LoadInfoEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { createCompanySearchEpic } from '@common/redux/epic/loadSearch/CompanySearchEpic';
import { backhaulsEpicCreator$ } from '@common/redux/epic/loadSearch/LoadSearchBackhaulsEpic';
import { createLoadSearchEpic } from '@common/redux/epic/loadSearch/LoadSearchEpic';
import { companyMapSearchEpicCreator$, mapSearchEpicCreator$ } from '@common/redux/epic/loadSearch/MapSearchEpic';
import { createHomeLocationEpic } from '@common/redux/epic/LoadSearchSettingsEpic';
import { createLoadsEpic } from '@common/redux/epic/LoadsEpic';
import { createLocationMatchEpic } from '@common/redux/epic/LocationMatchEpic';
import { createMarketRatesEpic } from '@common/redux/epic/MarketRatesEpic';
import { createMyCarrierPortalEpic } from '@common/redux/epic/MyCarrierPortalEpic';
import { createMyLoadsEpic } from '@common/redux/epic/MyLoadsEpic';
import { createPaymentEpic } from '@common/redux/epic/PaymentEpic';
import { createPermissionsEpic } from '@common/redux/epic/PermissionsEpic';
import { createPostTruckEpic } from '@common/redux/epic/PostTruckEpic';
import { createQuotesEpic } from '@common/redux/epic/QuoteEpic';
import { createRateChecksEpic } from '@common/redux/epic/RateCheckEpic';
import { createRecentSearchLocationsEpic } from '@common/redux/epic/RecentSearchLocationsEpic';
import { createRouteEpic } from '@common/redux/epic/RoutesEpic';
import { createServiceCodeEpic } from '@common/redux/epic/ServiceCodeEpic';
import { createSettingsEpic } from '@common/redux/epic/SettingsEpic';
import { createSharedLoadEpic } from '@common/redux/epic/SharedLoadEpic';
import { createSystemTimeEpic } from '@common/redux/epic/SystemTimeEpic';
import { createTermsAndConditionsEpic } from '@common/redux/epic/TermsAndConditionsEpic';
import { createThunderFundingQuotesEpic } from '@common/redux/epic/ThunderFundingQuotesEpic';
import { createTmsEpic } from '@common/redux/epic/TmsEpic';
import { createTripBuilderEpic } from '@common/redux/epic/TripBuilderEpic';
import { createTruckAlertsEpic } from '@common/redux/epic/TruckAlertsEpic';
import { createUploadFileEpic } from '@common/redux/epic/UploadFileEpic';
import { createUserEpic } from '@common/redux/epic/UserEpic';
import { createUserPlanManagementEpic } from '@common/redux/epic/UserPlanManagementEpic';
import { createUserTrucksEpic } from '@common/redux/epic/UserTrucksEpic';
import { createVerifyPhoneEpic } from '@common/redux/epic/VerifyPhoneEpic';
import { AppConfig } from '@util/AppConfig';
import { AnonymousMemberApiService } from '@webApi/AnonymousMemberApiService';
import { MemberApiService } from '@webApi/MemberApiService';
import { createMembersEpic } from '@webApi/MembersEpic';
import { RawApiService } from '@webApi/RawApiService';

import { createCarrierEpic } from './epic/CarrierEpic';
import { createFeedEpic } from './epic/FeedEpic';
import { createLoadMetricEpic } from './epic/LoadMetricsEpic';
import { createLoadVisibilityRulesEpic } from './epic/LoadVisibilityRulesEpic';
import { createOnboardedCarriersEpic } from './epic/OnboardedCarriersEpic';
import { createPCMilerEpic } from './epic/PCMilerEpic';
import { createPrivateNetworkEpic } from './epic/PrivateNetworkEpic';
import { createSearchDirectoryCompanyEpic } from './epic/SearchDirectoryEpic';
import { createVerifyEmailEpic } from './epic/VerifyEmailEpic';
import { createWelcomebackCampaignEpic } from './epic/WelcomebackCampaignEpic';
import { epicMiddleware } from './Store';

const anonymousMemberApi = new AnonymousMemberApiService();
export const memberApi = new MemberApiService();
const rawApi = new RawApiService();
const isLiveEnvironment = AppConfig.LBConfig.lbEnvironment === 'live' || AppConfig.LBConfig.lbEnvironment === 'beta';

export const rootEpic = combineEpics<any>(
  // keep sorted
  backhaulsEpicCreator$(memberApi, isLiveEnvironment),
  companyMapSearchEpicCreator$(memberApi, isLiveEnvironment),
  createAvatarEpic(memberApi),
  createBidsEpic(memberApi),
  createBrokerEpic(memberApi),
  createCapacityFinderEpic(memberApi),
  createCarrierEpic(memberApi),
  createCarrierTrackingEpic(memberApi),
  createCommunicationsEpic$(memberApi),
  createCompanyAvatarEpic(memberApi),
  createCompanyEpic(memberApi),
  createCompanySearchEpic(memberApi, isLiveEnvironment),
  createDocumentsEpic(memberApi),
  createFeedEpic(rawApi),
  createFeedbackEpic(memberApi),
  createFtpEpic(memberApi),
  createHomeLocationEpic(memberApi),
  createIdentityVerificationEpic(memberApi),
  createInboxEpic(memberApi),
  createInboxMessageContentEpic(memberApi),
  createLoadAvailabilityEpic(memberApi, isLiveEnvironment),
  createLoadDetailsMapEpic(memberApi, isLiveEnvironment),
  createLoadFeedbackEpic(memberApi),
  createLoadInfoEpic(memberApi, isLiveEnvironment),
  createLoadMetricEpic(memberApi),
  createLoadSearchEpic(memberApi, isLiveEnvironment),
  createLoadVisibilityRulesEpic(memberApi),
  createLoadsEpic(memberApi, isLiveEnvironment),
  createLocationEpic$(memberApi),
  createLocationMatchEpic(memberApi),
  createMarketRatesEpic(memberApi, isLiveEnvironment),
  createMembersEpic(rawApi),
  createMileageCalculatorEpic(memberApi),
  createMyCarrierPortalEpic(memberApi),
  createMyLoadsEpic(memberApi, isLiveEnvironment),
  createOnboardedCarriersEpic(memberApi),
  createPCMilerEpic(memberApi, isLiveEnvironment),
  createPaymentEpic(memberApi),
  createPermissionsEpic(memberApi),
  createPostLoadEpic(memberApi),
  createPostLoadFormEpic$(memberApi),
  createPostTruckEpic(memberApi),
  createPostingContactsEpic(memberApi),
  createPrivateNetworkEpic(memberApi),
  createQuotesEpic(memberApi),
  createRateChecksEpic(memberApi),
  createRecentSearchLocationsEpic(memberApi),
  createReducerSyncEpic$(memberApi),
  createRouteEpic(memberApi),
  createSearchDirectoryCompanyEpic(memberApi),
  createServiceCodeEpic(rawApi, memberApi),
  createSettingsEpic(memberApi),
  createSharedLoadEpic(memberApi, isLiveEnvironment),
  createTripBuilderEpic(memberApi),
  createSystemTimeEpic(memberApi),
  createTermsAndConditionsEpic(memberApi),
  createThunderFundingQuotesEpic(memberApi),
  createTmsEpic(memberApi),
  createTruckAlertsEpic(memberApi),
  createTruckEpic(memberApi),
  createURLScrapeEpic(memberApi, anonymousMemberApi),
  createUnsubscribeEpic(memberApi),
  createUploadFileEpic(memberApi),
  createUserEpic(memberApi),
  createUserPlanManagementEpic(memberApi),
  createUserSettingsEpic(memberApi),
  createUserTrucksEpic(memberApi),
  createVendorBidsEpic(memberApi),
  createVerifyEmailEpic(memberApi, anonymousMemberApi),
  createVerifyPhoneEpic(memberApi),
  createWelcomebackCampaignEpic(rawApi),
  loadDetailsEpicCreator$(LoadSearchType.Backhaul, memberApi, isLiveEnvironment),
  loadDetailsEpicCreator$(LoadSearchType.CompanySearch, memberApi, isLiveEnvironment),
  loadDetailsEpicCreator$(LoadSearchType.LoadSearch, memberApi, isLiveEnvironment),
  loadDetailsEpicCreator$(LoadSearchType.Posted, memberApi, isLiveEnvironment),
  mapSearchEpicCreator$(memberApi, isLiveEnvironment)
);

epicMiddleware.run(rootEpic);

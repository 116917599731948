import React, { Component } from 'react';

import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { fetchLocation } from '@/reduxStore/epic/LocationEpic';
import { StoreState } from '@/reduxStore/Store';
import { ImmutableLRUMap } from '@common/helper';
import { displaySingleStringValueFor, locationMatchToLoadLocation } from '@common/helper/LocationPickerHelper';
import {
  Geolocation,
  HomeLocation,
  LoadGeolocation,
  LoadLocation,
  LocationSuggestion,
  LocationType,
} from '@common/model';
import { Response } from '@common/redux/Base';
import { setLocation } from '@common/redux/epic/LoadSearchSettingsEpic';
import { fetchLocationMatch, LocationMatchState } from '@common/redux/epic/LocationMatchEpic';
import { IconActionButton } from '@component/buttons';
import { MinHeightForDropdownList } from '@component/dropdown/DropdownList';
import { LocationDropdownList } from '@component/dropdown/LocationDropdownList';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import {
  showLocationDeniedDialog,
  showLocationTimeoutDialog,
  showLocationUnavailableDialog,
} from '@component/panels/findLoads/searchPanel/GeolocationPopups';
import { ViewType } from '@component/panels/findLoads/searchPanel/SearchPanelHelper';
import { StyledPaddedRow } from '@component/panels/pickerPanels/PickUpDropOffStyles';
import { PopupTracking, withPopups } from '@component/popup/PopupTrackingContext';
import { withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { getGeolocation } from '@util/GeolocationHelper';
import icons from '@util/iconsConstants';

interface DispatchProps {
  setLocation: (location: LoadLocation) => void;
  fetchLocationMatch: (query: string) => void;
  fetchCurrentLocation: (location: Geolocation) => void;
}

interface ReduxProps {
  location?: HomeLocation;
  locationMatches: ImmutableLRUMap<string, LocationMatchState>;
  isFetchingLocation: boolean;
  reverseGeolocation?: Response<LoadGeolocation>;
}

interface ComponentProps {
  forwardRedirect?(type: ViewType): void;
  suggestionClick(location: LoadLocation): void;
  toggleHomePick: () => void;
  popupContext?: PopupTracking;
  updateCurrentLocation?: boolean;
  layer?: number;
  offset?: number;
  shouldCloseOnEsc?: boolean;
}

interface ComponentState {
  locKey: string;
  isEditingLocation: boolean;
}

type Props = ComponentProps & ReduxProps & DispatchProps;

class HomePickComponent extends Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locKey: '',
      isEditingLocation: false,
    };
  }

  componentDidMount(): void {
    const { location } = this.props;
    if (location) {
      this.handleLocationAutocomplete(displaySingleStringValueFor(location, true));
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      !isEqual(this.props.reverseGeolocation, prevProps.reverseGeolocation) &&
      this.props.reverseGeolocation?.payload
    ) {
      this.props.setLocation(this.props.reverseGeolocation.payload);
      this.props.suggestionClick(this.props.reverseGeolocation.payload);
      this.props.toggleHomePick();
    }
  }

  handleLocationAutocomplete = (value: string) => {
    this.props.fetchLocationMatch(value);
    this.setState({ locKey: value });
  };
  handleLocationChange = (selectedItem: LocationSuggestion) => {
    const location = locationMatchToLoadLocation(selectedItem);
    if (location !== undefined) {
      this.handleSuggestionClick(location);
    }
  };
  handleSuggestionClick = (location: LoadLocation) => {
    if (location.type === LocationType.STATE) {
      return;
    }
    this.props.setLocation(location);
    this.props.suggestionClick(location);
  };
  handleResetTextClick = () => {
    this.setState({ locKey: '', isEditingLocation: false });
  };

  handleCurrentLocationClick = () => {
    getGeolocation({
      onSuccess: (location) => {
        this.props.fetchCurrentLocation(location);
      },
      onDenied: () => showLocationDeniedDialog(this.props.popupContext),
      onUnavailable: () => showLocationUnavailableDialog(this.props.popupContext),
      onTimeout: () => showLocationTimeoutDialog(this.props.popupContext),
    });
  };

  changeLocationEditing = (isEditingLocation: boolean) => () => {
    this.setState({ isEditingLocation: isEditingLocation });
  };

  render() {
    const { locKey, isEditingLocation } = this.state;
    return (
      <Panel
        id="home_pick"
        layer={this.props.layer ?? 0}
        offset={this.props.offset ?? 0}
        size={PanelSize.small}
        backgroundColor={PANEL_SHADOW_BACKGROUND}
      >
        <PanelHeader
          label={t(T.findLoads_findLoads_homeLocation)}
          hasBackButton={true}
          onClose={this.props.toggleHomePick}
          shouldCloseOnEsc={this.props.shouldCloseOnEsc}
        />
        <StyledPaddedRow id="home_location">
          <LocationDropdownList
            id="text_input"
            title={`${t(T.findLoads_findLoads_homeLocationLabel)}`}
            hasError={false}
            onSelectionChange={this.handleLocationChange}
            onInputValueChange={this.handleLocationAutocomplete}
            value={locKey}
            onClear={this.handleResetTextClick}
            withCustomScroll={true}
            isWithoutMargin={true}
            shouldIncludeStates={false}
            onFocus={this.changeLocationEditing(true)}
            onBlur={this.changeLocationEditing(false)}
          />
        </StyledPaddedRow>
        {!isEditingLocation ? (
          <IconActionButton
            id="current_location"
            title={`${t(T.findLoads_findLoads_currentLocationLabel)}`}
            mainIcon={icons.location}
            onClick={this.handleCurrentLocationClick}
          />
        ) : (
          <MinHeightForDropdownList />
        )}
      </Panel>
    );
  }
}

const mapStateToProps = (state: StoreState): ReduxProps => ({
  location: state.loadSearchSettings.homeLocation,
  locationMatches: state.locationMatches,
  isFetchingLocation: state.locationState.isFetchingLocation,
  reverseGeolocation: state.locationState.reverseGeolocation,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      setLocation: setLocation,
      fetchLocationMatch: fetchLocationMatch,
      fetchCurrentLocation: fetchLocation,
    },
    dispatch
  );

export const HomePick = connect(mapStateToProps, mapDispatchToProps)(withTheme()(withPopups(HomePickComponent)));

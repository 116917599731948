import React from 'react';

import styled from 'styled-components';

import { ActionChip, ChipTypes } from '@component/chip';
import { ConversationListViewType } from '@page/communication/ConversationListFilter';
import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

const FilterContainer = styled.div`
  display: flex;
  height: 50px;
  padding: 13px 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: ${Color.GRAY_SMOKE};
  box-sizing: border-box;
`;

const FilterChipContainer = styled.div`
  margin-right: ${Spacing.ElementPaddingHorizontal}px;
`;

export const ConversationListFilters: React.FC<{
  messagesView: ConversationListViewType;
  openFilterPanel: () => void;
}> = ({ messagesView, openFilterPanel }) => {
  return (
    <FilterContainer>
      <FilterChipContainer>
        <ActionChip
          id="view"
          type={ChipTypes.Green}
          text={t(T.common_viewKeys_view, {
            value:
              messagesView === ConversationListViewType.All
                ? t(T.common_conversations_filter_all)
                : t(T.common_conversations_filter_unread),
          })}
          onClick={openFilterPanel}
        />
      </FilterChipContainer>
    </FilterContainer>
  );
};

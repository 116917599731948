import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$ } from 'rxjs/internal/operators/map';
import { mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api } from '@common/api';
import { LoadsClient } from '@common/client';
import { treatZeroAsUndefined } from '@common/helper';
import { getFlowIDFrom } from '@common/helper/FlowIDHeaderHelper';
import {
  BaseLocation,
  DEFAULT_LOAD_SEARCH_RADIUS,
  Geolocation,
  GeolocationGroup,
  LoadSearchArchiving,
  LoadSearchGeoResponse,
  LoadSearchMetadata,
  LoadSearchRequest,
  LocationType,
  MapBounds,
  SearchMetadataType,
} from '@common/model';
import { ResultResponseAction } from '@common/redux/Base';
import {
  initialState as initialLoadSearchState,
  LoadSearchBaseState,
} from '@common/redux/epic/loadSearch/HelperFunctions';
import { createLoadSearch } from '@common/redux/epic/loadSearch/LoadSearchShared';
import { createReducer } from '@common/redux/ReduxHelper';

export const MAP_SEARCH_REDUCER_KEY = 'mapSearch';
export const COMPANY_MAP_SEARCH_REDUCER_KEY = 'companyMapSearch';

type LoadSearchMapResponseAction = ResultResponseAction<LoadSearchGeoResponse> & LoadSearchArchiving;

interface MapSearchAction extends Action {
  mapSearchBounds: MapBounds;
}

interface MapSearchGeoSearchAction extends Action {
  lastSearch: LoadSearchRequest;
  origin?: BaseLocation;
  shouldResetFlowID: boolean;
}

interface MapSearchGeoSearchByIdAction extends Action {
  id: string;
  metadata: LoadSearchMetadata;
  shouldResetFlowID: boolean;
}

export interface MapSearchState extends LoadSearchBaseState {
  mapSearchBounds?: MapBounds;
  pins: GeolocationGroup[];
  isNewSearch: boolean;
  center?: Geolocation;
  radius?: number;
  isLoadingPins: boolean;
  mapArchivingFlowID: string | undefined;
  lastMapSearchID?: string;
}

export const initialMapSearchState: Readonly<MapSearchState> = {
  ...initialLoadSearchState,
  pins: [],
  isNewSearch: false,
  isLoadingPins: false,
  mapArchivingFlowID: undefined,
};

const createMapSearchEpic = (key: string) => {
  //Actions
  const baseActionKey = `${key}_`;
  const actionsKeys = {
    CLEAR_LOADS: `${baseActionKey}CLEAR_LOADS`,
    SET_MAP_SEARCH_BOUNDS: `${baseActionKey}SET_MAP_SEARCH_BOUNDS`,
    FETCH_GEO_LOADS: `${baseActionKey}FETCH_GEO_LOADS`,
    FETCH_GEO_LOADS_ID: `${baseActionKey}FETCH_GEO_LOADS_ID`,
    GEO_LOADS_FETCHED: `${baseActionKey}GEO_LOADS_FETCHED`,
    NEW_GEO_LOADS_FETCHED: `${baseActionKey}NEW_GEO_LOADS_FETCHED`,
  };

  const mapSearch = createLoadSearch(key);

  const exportedActions = {
    clearLoads: (): Action => ({ type: actionsKeys.CLEAR_LOADS }),
    setMapSearchBounds: (mapSearchBounds: MapBounds): MapSearchAction => ({
      type: actionsKeys.SET_MAP_SEARCH_BOUNDS,
      mapSearchBounds: mapSearchBounds,
    }),
    fetchGeoLoads: (lastSearch: LoadSearchRequest, origin?: BaseLocation): MapSearchGeoSearchAction => ({
      type: actionsKeys.FETCH_GEO_LOADS,
      lastSearch: lastSearch,
      origin: origin,
      shouldResetFlowID: false,
    }),
    fetchGeoLoadsById: (
      id: string,
      metadata: LoadSearchMetadata,
      shouldResetFlowID: boolean = false
    ): MapSearchGeoSearchByIdAction => ({
      type: actionsKeys.FETCH_GEO_LOADS_ID,
      id: id,
      metadata: metadata,
      shouldResetFlowID: shouldResetFlowID,
    }),
    searchLoads: mapSearch.actions.searchLoads,
    nextSearchLoads: mapSearch.actions.nextSearchLoads,
    updateMapSearchLoads: mapSearch.actions.updateLoads,
    refreshMapSearchLoadCount: mapSearch.actions.refreshLoadCount,
    removeBlockedLoad: mapSearch.actions.removeBlockedLoad,
    viewClusterLoad: mapSearch.actions.viewLoad,
    toggleCountingSearches: mapSearch.actions.toggleCountingSearches,
    updateOriginLocation: mapSearch.actions.updateOriginLocation,
  };

  //Reducer
  const reducer = createReducer(
    initialMapSearchState,
    {
      [actionsKeys.FETCH_GEO_LOADS]: (state, action: MapSearchGeoSearchAction) => {
        state.isNewSearch = Boolean(action.origin);
        state.isPerformingSearch = true;
        state.isLoadingPins = true;
        state.radius = state.radius ? state.radius : action.lastSearch.origin.radius || DEFAULT_LOAD_SEARCH_RADIUS;
        state.mapArchivingFlowID = action.shouldResetFlowID ? undefined : state.mapArchivingFlowID;
      },
      [actionsKeys.FETCH_GEO_LOADS_ID]: (state, action: MapSearchGeoSearchByIdAction) => {
        state.isPerformingSearch = true;
        state.isLoadingPins = true;
        state.mapArchivingFlowID = action.shouldResetFlowID ? undefined : state.mapArchivingFlowID;
      },
      [actionsKeys.CLEAR_LOADS]: (state) => {
        state.loads = [];
      },
      [actionsKeys.SET_MAP_SEARCH_BOUNDS]: (state, action: MapSearchAction) => {
        state.mapSearchBounds = action.mapSearchBounds;
      },
      [actionsKeys.NEW_GEO_LOADS_FETCHED]: fetchedGeoloadsCase(true),
      [actionsKeys.GEO_LOADS_FETCHED]: fetchedGeoloadsCase(false),
    },
    (state: MapSearchState, action) => {
      const mapSearchState = mapSearch.reducer(state, action);
      if (mapSearchState) {
        return { ...state, ...mapSearchState };
      }
      return state;
    }
  );

  //Epic
  const searchGeoLoadsEpic$ = (
    action$: ActionsObservable<Action>,
    client: LoadsClient,
    state$: StateObservable<{ [reducerKey: string]: MapSearchState }>
  ) =>
    action$.ofType(actionsKeys.FETCH_GEO_LOADS).pipe(
      mergeMap$((action: MapSearchGeoSearchAction) => {
        const flowID = state$.value[key].mapArchivingFlowID;
        return client.searchGeoLoads$(updatedMapSearchWith(action.lastSearch, action.origin), flowID).pipe(
          map$((response) => {
            const flowID = getFlowIDFrom(response);
            return response.result(
              (data): LoadSearchMapResponseAction => ({
                type: action.origin ? actionsKeys.NEW_GEO_LOADS_FETCHED : actionsKeys.GEO_LOADS_FETCHED,
                response: { success: true, payload: data },
                flowID: flowID,
              }),
              (error): LoadSearchMapResponseAction => ({
                type: actionsKeys.GEO_LOADS_FETCHED,
                response: { success: false, error: error },
                flowID: flowID,
              })
            );
          })
        );
      })
    );

  const searchGeoLoadsByIdEpic$ = (
    action$: ActionsObservable<Action>,
    client: LoadsClient,
    state$: StateObservable<{ [reducerKey: string]: MapSearchState }>
  ) =>
    action$.ofType(actionsKeys.FETCH_GEO_LOADS_ID).pipe(
      mergeMap$((action: MapSearchGeoSearchByIdAction) => {
        const flowID = state$.value[key].mapArchivingFlowID;
        return client.searchGeoLoadsById$(action.id, { metadata: action.metadata }, flowID).pipe(
          map$((response) => {
            const flowID = getFlowIDFrom(response);
            return response.result(
              (data): LoadSearchMapResponseAction => ({
                type: actionsKeys.GEO_LOADS_FETCHED,
                response: { success: true, payload: data },
                flowID: flowID,
              }),
              (error): LoadSearchMapResponseAction => ({
                type: actionsKeys.GEO_LOADS_FETCHED,
                response: { success: false, error: error },
                flowID: flowID,
              })
            );
          })
        );
      })
    );

  const createEpic$ = (api: Api, isLiveEnvironment: boolean) => {
    const client = new LoadsClient(api, isLiveEnvironment);

    return (action$: ActionsObservable<Action>, state$: StateObservable<any>) =>
      merge$(
        mapSearch.createMergedEpic$(client, action$, state$),
        searchGeoLoadsEpic$(action$, client, state$),
        searchGeoLoadsByIdEpic$(action$, client, state$)
      );
  };

  return {
    reducer: reducer,
    createEpic$: createEpic$,
    actions: exportedActions,
    actionKeys: actionsKeys,
  };
};

const fetchedGeoloadsCase = (isNewSearch: boolean) => (state: MapSearchState, action: LoadSearchMapResponseAction) => {
  if (action.response.success) {
    const { id, groups, metadata } = action.response.payload;
    state.pins = groups;
    state.lastMapSearchID = id;
    state.center = metadata.center;
    state.radius = getRadius(metadata.radius, metadata.requestParams.origin.radius, state);
    state.lastSearchRequest = metadata.requestParams;
  }
  state.isPerformingSearch = false;
  state.mapArchivingFlowID = action.flowID;
  state.archivingFlowID = action.flowID;
  state.isLoadingPins = false;
  state.isNewSearch = isNewSearch;
};

export const updatedMapSearchWith = (
  lastSearch: LoadSearchRequest,
  origin: BaseLocation | undefined
): LoadSearchRequest => {
  return {
    ...lastSearch,
    metadata: {
      type: origin ? SearchMetadataType.GeoPanning : SearchMetadataType.Geo,
    },
    origin: origin
      ? {
          type: LocationType.GEOLOCATION,
          longitude: origin.geolocation ? origin.geolocation.longitude : 0,
          latitude: origin.geolocation ? origin.geolocation.latitude : 0,
          radius: origin.radius || lastSearch.origin.radius || DEFAULT_LOAD_SEARCH_RADIUS,
        }
      : lastSearch.origin,
  };
};

const getRadius = (
  requestMetadataRadius: number | undefined,
  originRadius: number | undefined,
  state: MapSearchState
) => {
  return treatZeroAsUndefined(requestMetadataRadius) ?? treatZeroAsUndefined(originRadius) ?? state.radius;
};

const mapSearch = createMapSearchEpic(MAP_SEARCH_REDUCER_KEY);
const companyMapSearch = createMapSearchEpic(COMPANY_MAP_SEARCH_REDUCER_KEY);

export const mapSearchReducer = mapSearch.reducer;
export const mapSearchEpicCreator$ = mapSearch.createEpic$;
export const mapSearchActions = mapSearch.actions;
export const mapSearchActionKeys = mapSearch.actionKeys;

export const companyMapSearchReducer = companyMapSearch.reducer;
export const companyMapSearchEpicCreator$ = companyMapSearch.createEpic$;
export const companyMapSearchActions = {
  ...companyMapSearch.actions,
  fetchGeoLoads: (searchRequest: LoadSearchRequest, origin?: BaseLocation) =>
    companyMapSearch.actions.fetchGeoLoads(searchRequest, origin),
};
export const companyMapSearchActionKeys = companyMapSearch.actionKeys;

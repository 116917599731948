import { Geolocation, GeolocationGroup } from '@common/model';
import { createArrayParser, createObjectParser, parseNumber } from '@common/util/parser/ParserUtils';
import { MapData } from '@page/findLoads/LoadSearchRequestEncoder';

export const parseGeolocation = createObjectParser<Geolocation | undefined>((object) => {
  const latitude = parseNumber(object.latitude);
  const longitude = parseNumber(object.longitude);
  if (latitude !== undefined && longitude !== undefined) {
    return new Geolocation(latitude, longitude);
  }
  return undefined;
});

export const parseMapPins = createArrayParser(
  createObjectParser<GeolocationGroup | undefined>((object) => {
    const count = parseNumber(object.count, { min: 0 });
    const geolocation = parseGeolocation(object.geolocation);
    if (count !== undefined && geolocation) {
      return {
        count: count,
        geolocation: geolocation,
      };
    }
    return undefined;
  })
);

export const parseMapData = createObjectParser<MapData | undefined>((object) => {
  const count = parseNumber(object.count, { min: 0 });
  const pins = parseMapPins(object.pins);
  if (count !== undefined && pins) {
    return {
      count: count,
      pins: pins,
    };
  }
  return undefined;
});

// Model for GET /loads
import {
  Address,
  BookNow,
  Company,
  CreditRating,
  Equipment,
  EquipmentType,
  Geolocation,
  LoadMetadata,
  Location,
  Phone,
  PosterMetadata,
  PostLoadVisibilityRulesList,
  Rate,
  RateCheck,
} from '@common/model';
import { Vendor } from '@common/model/VendorBid';
import { SearchResponsePayload } from '@common/redux/Base';

export interface LoadRateCheckPreview {
  status: RateCheckPreviewStatus;
  totalRate?: number;
  ratePerMile?: number;
}

// Add fields when required to both the response structure and LOAD_DETAIL_REQUEST_FIELDS_DEFAULT
export interface Load {
  id: string;
  guid?: string;
  status: LoadStatus;
  computedMileage?: number;
  age?: number;
  created?: string;
  poster?: Company;
  rateCheck?: RateCheck;
  rateCheckPreview?: LoadRateCheckPreview;
  metadata?: LoadMetadata;
  postReference?: string;
  posterContactName?: string;
  numberOfLoads?: number;
  originLocation?: Location;
  destinationLocation?: Location;
  pickupDateTime?: string;
  pickupDateTimes?: string[];
  deliveryDateTime?: string;
  equipments?: Equipment[];
  loadSize?: LoadSize;
  mileage?: number;
  length?: number;
  weight?: number;
  rate?: Rate;
  numberOfStops?: number;
  commodity?: string;
  notes?: string;
  privateLoadNote?: string;
  dispatchPhone?: Phone;
  dispatchName?: string;
  dispatchEmail?: string;
  contactName?: string;
  contactPhone?: Phone;
  contactEmail?: string;
  sortEquipCode?: string;
  pricePerMile?: number;
  teamDriving?: boolean;
  conversation?: {
    canInitiateConversation: boolean;
    unreadConversationCount?: number;
    sentConversationCount?: number;
    receivedConversationCount?: number;
  };
  extraStops?: LoadExtraStop[];
  vendorInfo?: VendorInfo;
  vHub?: VHub;
  rateNegotiations?: {
    status: LoadRateNegotiationsStatus;
    numberOfBids: number;
    numberOfUnreadBids: number;
  };
  canBookNow?: boolean;
  bookNow?: BookNow;
  posterMetadata?: PosterMetadata;
  visibility?: PostLoadVisibilityRulesList;
  onboardingUrl?: string;

  // local fields, set/updated by the client
  fetchedAt?: number;
  isFromAutorefresh?: boolean;
}

export interface PostedLoad extends Omit<Load, 'originLocation | destinationLocation'> {
  origin?: Location;
  destination?: Location;
}

export interface SharedLoad extends Load {
  creditRatings?: CreditRating[];
  access?: SharedLoadAccess;
}

export interface Loads extends SearchResponsePayload {
  loads: Load[];
}

export interface SimilarLoad {
  load: Load;
  nonMatching: NonMatchingLoad;
}

export interface NonMatchingLoad {
  fields: LoadFields[];
  equipments: EquipmentType[];
}

export interface LoadMatch {
  load: Load;
  nonMatching: NonMatchingLoad;
}

export interface SharedLoadAccess {
  rateCheck?: boolean;
  creditRatings?: boolean;
}

export interface LoadExtraStop {
  stopNo?: number;
  stopPurpose?: StopPurposeType;
  type?: ExtraStopLocationType;
  geolocation?: Geolocation;
  address?: Address;
}

export enum RateCheckPreviewStatus {
  Loading = 'loading',
  Available = 'available',
  UnsupportedEquipmentType = 'UnsupportedEquipmentType',
  NotAvailable = 'NotAvailable',
  NoRateCheckAccess = 'NoRateCheckAccess',
  Error = 'error',
}

export enum PayRateUnit {
  Flat = 'Flat',
  PerMile = 'PerMile',
}

export interface VendorInfo {
  vendorId?: Vendor;
  canBid?: boolean;
  bid?: VendorBidInfo;
}

export interface VendorBidInfo {
  submittedOn: string;
  amount: number;
}

export interface VHub {
  url?: string;
}

export type LoadWithStatusOnly = Pick<Load, 'id' | 'status'>;

export enum StopPurposeType {
  None = 'None',
  Pickup = 'Pickup',
  Dropoff = 'Dropoff',
  Both = 'Both',
}

export enum ExtraStopLocationType {
  None = 'None',
  CityState = 'CityState',
  ZipCode = 'ZipCode',
  Geolocation = 'Geolocation',
}

export enum LoadStatus {
  None = 'None',
  InProcess = 'InProcess',
  Online = 'Online',
  Deleted = 'Deleted',
  Assigned = 'Assigned',
  Delivered = 'Delivered',
  Offline = 'Offline',
  UIR = 'UIR',
}

export enum LoadSize {
  None = 'None',
  LTL = 'LTL',
  TL = 'TL',
}

export enum LoadFields {
  Length = 'Length',
  Weight = 'Weight',
  Mileage = 'Mileage',
  Origin = 'Origin',
  Size = 'Size',
  Destination = 'Destination',
  PickupDate = 'PickupDate',
}

export enum AspectRatio {
  Ratio2_1 = 'Ratio2_1',
  Ratio3_1 = 'Ratio3_1',
}

export enum LoadRateNegotiationsStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export enum LoadContactOption {
  Email,
  Call,
  Message,
  VendorBid,
  Bid,
  VhubBook,
  BookNow,
}

export const LOAD_DETAIL_REQUEST_FIELDS_DEFAULT =
  'id,' +
  'guid,' +
  'status,' +
  'computedMileage,' +
  'age,' +
  'created,' +
  'poster,' +
  'rateCheck,' +
  'rateCheckPreview,' +
  'metadata,' +
  'postReference,' +
  'numberOfLoads,' +
  'originLocation,' +
  'destinationLocation,' +
  'pickupDateTime,' +
  'pickupDateTimes,' +
  'deliveryDateTime,' +
  'equipments,' +
  'loadSize,' +
  'mileage,' +
  'length,' +
  'weight,' +
  'rate,' +
  'numberOfStops,' +
  'commodity,' +
  'notes,' +
  'privateLoadNote,' +
  'dispatchPhone,' +
  'dispatchName,' +
  'dispatchEmail,' +
  'contactName,' +
  'contactPhone,' +
  'contactEmail,' +
  'sortEquipCode,' +
  'pricePerMile,' +
  'teamDriving,' +
  'conversation,' +
  'extraStops,' +
  'vendorInfo,' +
  'vHub,' +
  'rateNegotiations,' +
  'canBookNow,' +
  'bookNow,' +
  'posterMetadata,' +
  'onboardingUrl';

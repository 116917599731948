import { authenticationToApiOptions } from '@common/api/ApiOptions';
import { BaseClient } from '@common/client/BaseClient';
import { Quote, QuoteListResponse, QuoteRequest, QuoteResult } from '@common/model';

export class QuoteClient extends BaseClient {
  fetchFactoringQuotes$ = (withAuthentication: boolean) =>
    this.api.get$<QuoteResult>(
      '/quotes/factoring/thunderfunding',
      {
        fields: 'quoteResponses{metadata{missingInfo}},metadata{totalResultCount}',
      },
      undefined,
      authenticationToApiOptions(withAuthentication)
    );

  postFactoringQuote$ = (quote: QuoteRequest, withAuthentication: boolean) => {
    return this.api.post$<Quote>(
      '/factoring/thunderfunding',
      quote,
      undefined,
      authenticationToApiOptions(withAuthentication)
    );
  };

  fetchRoadsideAssistQuotes$ = (withAuthentication: boolean) =>
    this.api.get$<QuoteResult>(
      '/roadsideassistance/rignation/quotes',
      {
        fields: 'quoteResponses{date,metadata{missingInfo}},metadata{totalResultCount}',
      },
      undefined,
      authenticationToApiOptions(withAuthentication)
    );

  postRoadsideAssistQuote$ = (quote: QuoteRequest, withAuthentication: boolean) => {
    return this.api.post$<Quote>(
      '/roadsideassistance/rignation/quotes',
      quote,
      undefined,
      authenticationToApiOptions(withAuthentication)
    );
  };

  fetchTruckingAuthorityQuotes$ = (withAuthentication: boolean) =>
    this.api.get$<QuoteResult>(
      '/authoritymanagement/evilsizor/quotes',
      {
        fields: 'quoteResponses{date,metadata{missingInfo}},metadata{totalResultCount}',
      },
      undefined,
      authenticationToApiOptions(withAuthentication)
    );

  postTruckingAuthorityQuote$ = (quote: QuoteRequest, withAuthentication: boolean) => {
    return this.api.post$<Quote>(
      '/authoritymanagement/evilsizor/quotes',
      quote,
      undefined,
      authenticationToApiOptions(withAuthentication)
    );
  };

  fetchFuelCardQuotes$ = (withAuthentication: boolean) =>
    this.api.get$<QuoteResult>(
      '/quotes/fuel/thunderfunding',
      {
        fields: 'quoteResponses{metadata{missingInfo}},metadata{totalResultCount}',
      },
      undefined,
      authenticationToApiOptions(withAuthentication)
    );

  postFuelCardQuote$ = (quote: QuoteRequest, withAuthentication: boolean) => {
    return this.api.post$<Quote>(
      '/quotes/fuel/thunderfunding',
      quote,
      undefined,
      authenticationToApiOptions(withAuthentication)
    );
  };

  postFuelAdvanceQuote$ = (quote: QuoteRequest, withAuthentication: boolean) => {
    return this.api.post$<Quote>(
      '/quotes/fueladvance/thunderfunding',
      quote,
      undefined,
      authenticationToApiOptions(withAuthentication)
    );
  };

  fetchMostRecentQuotes$ = (guid: string) => this.api.get$<QuoteListResponse>(`/loads/${guid}/quotes/mostrecent`, {});
}

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api, ApiError } from '@common/api';
import { LoadsClient } from '@common/client/LoadsClient';
import { AspectRatio } from '@common/model';
import { Response } from '@common/redux/Base';
import { standardApiEpic } from '@common/redux/epic/EpicHelper';

const FETCH_LOAD_DETAILS_MAP = 'FETCH_LOAD_DETAILS_MAP';
const LOAD_DETAILS_MAP_FETCHED = 'LOAD_DETAILS_MAP_FETCHED';

type RouteMapResult = Response<string>;

interface FetchWithLoadIDAction extends Action {
  loadID: string;
  archivingFlowID: string | undefined;
  aspectRatio: AspectRatio;
}

interface RouteMapResponseAction extends Action {
  response: RouteMapResult;
  loadID: string;
}

export const fetchRouteMap = (
  loadID: string,
  archivingFlowID: string | undefined,
  aspectRatio = AspectRatio.Ratio3_1
): FetchWithLoadIDAction => ({
  type: FETCH_LOAD_DETAILS_MAP,
  loadID: loadID,
  archivingFlowID: archivingFlowID,
  aspectRatio: aspectRatio,
});

const routeMapResponse = (loadID: string, response: RouteMapResult): RouteMapResponseAction => ({
  type: LOAD_DETAILS_MAP_FETCHED,
  loadID: loadID,
  response: response,
});

export interface LoadDetailsMapState {
  isLoading: boolean;
  map: string | undefined;
  loadID: string | undefined;
}

const initialState: LoadDetailsMapState = {
  map: undefined,
  loadID: undefined,
  isLoading: false,
};

export const loadDetailsMapReducer = (
  state: LoadDetailsMapState = initialState,
  action: Action
): LoadDetailsMapState => {
  switch (action.type) {
    case FETCH_LOAD_DETAILS_MAP: {
      return { ...state, map: undefined, loadID: undefined, isLoading: true };
    }
    case LOAD_DETAILS_MAP_FETCHED: {
      const routeMapResult = action as RouteMapResponseAction;
      if (routeMapResult.response.success && routeMapResult.response.payload) {
        return { ...state, map: routeMapResult.response.payload, loadID: routeMapResult.loadID, isLoading: false };
      }
      return { ...state, map: '', loadID: routeMapResult.loadID, isLoading: false };
    }
    default:
      return state;
  }
};

const fetchRouteMap$ = (loadsClient: LoadsClient, action$: ActionsObservable<Action>) =>
  standardApiEpic(
    action$,
    FETCH_LOAD_DETAILS_MAP,
    (action: FetchWithLoadIDAction) =>
      loadsClient.fetchRouteMap$(action.loadID, true, action.aspectRatio, action.archivingFlowID),
    (response, action: FetchWithLoadIDAction) => {
      if (response.data !== undefined) {
        return routeMapResponse(action.loadID, { success: true, payload: response.data });
      }
      return routeMapResponse(action.loadID, { success: false, error: new ApiError() });
    },
    (error, action: FetchWithLoadIDAction) => {
      return routeMapResponse(action.loadID, { success: false, error: error });
    }
  );

export const createLoadDetailsMapEpic = (api: Api, isLiveEnvironment: boolean) => {
  const loadsClient = new LoadsClient(api, isLiveEnvironment);
  return (action$: ActionsObservable<Action>) => merge$(fetchRouteMap$(loadsClient, action$));
};

import React from 'react';

import { includes, map, reduce } from 'lodash';
import { generatePath, Route, RouteProps } from 'react-router-dom';

import { BillingStatus, MembershipStatus } from '@common/model';
import { lazy } from '@component/lazyComponent';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';

import { NestedRoute, UnitedRoute } from './RouteGroup';
import { Routes } from './Routes';

export const generateRoutesFrom = (route: PageRoute) => {
  return <Route path={formatRoutePath(route.path)}>{renderRoute(route)}</Route>;
};

const renderRoute = (route: PageRoute | undefined) => {
  if (!route) {
    return null;
  }
  const formattedRoute = formatRoutePath(route.path);
  if (route.innerRoutes && route.innerRoutes.length > 0) {
    return (
      <UnitedRoute baseUrl={formattedRoute}>
        {route.component ? <NestedRoute path={'/'} component={lazy(() => route.component?.())} /> : null}
        {map(route.innerRoutes, (route) => renderRoute(route))}
      </UnitedRoute>
    );
  }
  return <NestedRoute path={formattedRoute} component={lazy(() => route.component?.())} />;
};

const formatRoutePath = (path: string) => {
  if (path && path[0] === '/') {
    return path;
  }
  return `/${path}`;
};

export type PageRouteParams = { [paramName: string]: string | undefined };

export interface PageRoute {
  path: string;
  component?: () => Promise<any>; //This promise should return a component. Having any as type to allow for lazy imports
  params?: PageRouteParams;
  routeProps?: RouteProps;
  innerRoutes?: PageRoute[];
}

export const generateRoutePathStringFrom = (pageRoute: PageRoute, useGeneratePath = true) => {
  const route = routeConverter(pageRoute, '', true);
  if (useGeneratePath) {
    return generatePath(route);
  }
  return route;
};

const routeConverter = (route: PageRoute, baseRoute: string, isFixed = false): string => {
  let cleanPath = route.path;
  if (cleanPath[0] === '/' && cleanPath.length > 1) {
    cleanPath = cleanPath.substring(1);
  }
  const templatePath = isFixed ? cleanPath : `:${cleanPath}(${cleanPath})?`;
  const stringRoute = `${baseRoute}/${templatePath}${paramsConverter(route.params)}`;
  if (route.innerRoutes) {
    return reduce(route.innerRoutes, (url, route) => routeConverter(route, url), stringRoute);
  }
  return stringRoute;
};

const paramsConverter = (params: PageRouteParams | undefined) => {
  if (!params) {
    return '';
  }
  let path = '';
  for (const key in params) {
    const value = params[key];
    let urlValue = '';
    if (value) {
      urlValue = `(${urlValue})`;
    }
    path = `${path}/:${key}${urlValue}?`;
  }
  return path;
};

export const PAYWALL_ALLOWED_ROUTES = [
  Routes.MORE_HELP_BASE_ROUTE,
  Routes.ANNOUNCEMENTS,
  Routes.MEMBERSHIPS_UNSUBSCRIBE,
  Routes.VERIFY_EMAIL_BLOCKER_PAGE,
  Routes.VERIFY_EMAIL,
  Routes.MORE_SETTINGS_ACCOUNT_SETUP_NEW,
  Routes.MORE_SETTINGS_BASE_ROUTE,
  `${Routes.MORE_SETTINGS_BASE_ROUTE}${Filter.BillingSummary}/`,
  `${Routes.MORE_SETTINGS_BASE_ROUTE}${Filter.PaymentHistory}/`,
  `${Routes.MORE_SETTINGS_BASE_ROUTE}${Filter.TermsOfService}/`,
  `${Routes.MORE_SETTINGS_BASE_ROUTE}${Filter.PrivacyPolicy}/`,
  Routes.TOOLS_GET_PAID_FASTER,
  Routes.TOOLS_SAVE_AT_THE_PUMP,
  Routes.TOOLS_ROADSIDE_ASSIST,
  Routes.TOOLS_TRUCKING_AUTHORITY,
];

export const PAYWALL_BLOCKED_ROUTES = [
  Routes.MORE_SETTINGS_MANAGE_PLAN,
  Routes.MORE_SETTINGS_PAYMENT_METHOD,
  Routes.MORE_SETTINGS_USER,
];

export const MEMBERSHIP_STATUS_LIST_FOR_ACCOUNT_SETUP = [
  MembershipStatus.OnHold,
  MembershipStatus.Expired,
  MembershipStatus.SignUpInProgress,
  MembershipStatus.MobileSignUpInProgress,
  MembershipStatus.PendingPayment,
];

export const BILLING_STATUS_LIST_FOR_ACCOUNT_SETUP = [
  BillingStatus.OnHold,
  BillingStatus.PaymentDeclined,
  BillingStatus.PromptAtLogin,
  BillingStatus.PromptAtTrialExpiry,
];

export const isAccountSetupRequiredForMembershipStatus = (membershipStatus: MembershipStatus) =>
  includes(MEMBERSHIP_STATUS_LIST_FOR_ACCOUNT_SETUP, membershipStatus);

export const isAccountSetupRequiredForBillingStatus = (
  billingStatus: BillingStatus | undefined,
  isCancellingMembership?: boolean
) => {
  if (isCancellingMembership && billingStatus === BillingStatus.OnHold) {
    return false;
  }
  return billingStatus ? includes(BILLING_STATUS_LIST_FOR_ACCOUNT_SETUP, billingStatus) : false;
};

import { GoToBidDetailsActionPayload } from './WidgetActionPayloads';

//============================================================================
// Widget Actions
//============================================================================
//
// See MessageWidget.ts for more details.
//
// Sample usage:
//
// // Determine the type, so ts will recognize params specific to that type.
// if (widgetActionFromAPI.type === WidgetActionType.GoToBidDetails) {
//    // Assuming GoTo Bid is sent only with a loadId:
//    widgetActionFromAPI.loadId; // present in widgetActionFromAPI
//    widgetActionFromAPI.conversationId; // not present
// } else if (widgetActionFromAPI.type === WidgetActionType.GoToMessage) {
//    // Assuming GoTo Message is sent only with a conversationId:
//    widgetActionFromAPI.conversationId; // present in widgetActionFromAPI
//    widgetActionFromAPI.loadId; // not present
// }
//
//----------------------------------------------------------------------------
// Add a new Action Type
//----------------------------------------------------------------------------
//
// 1. Add the new type to WidgetActionType
// 2. Create an ActionData type for your new action type:
//     type NewTypeWidgetActionData = WidgetActionPayload<
//       WidgetActionType.NewType,
//       NewTypeActionPayload // <-- defined in step 4
//     >;
// 3. Add your new ActionData to WidgetAction
//     export type WidgetAction = WidgetActions<
//       | ...existingActionDatas...
//       | NewTypeWidgetActionData
//     >;
// 4. Define NewTypeActionPayload in a separate file
//    This is the data sent with the action that is specific
//    to your new type.
//

export enum WidgetActionType {
  GoToBidDetails = 'GoToBidDetails',
  ShareOneTimeLocation = 'ShareOneTimeLocation',
  OpenHyperLink = 'OpenHyperLink',
  // Add new types of actions here, like:
  // Link = 'Link',
}

/**
 * Type to connect one WidgetActionType with all the
 * payloads it may be carrying.
 */
type WidgetActionPayload<ActionType, Payload> = {
  type: ActionType;
} & Payload;

//==== Widget Action Data ==============================================

export type GoToBidDetailsWidgetActionData = WidgetActionPayload<
  WidgetActionType.GoToBidDetails,
  GoToBidDetailsActionPayload
>;

export type ShareOneTimeLocationActionData = WidgetActionPayload<WidgetActionType.ShareOneTimeLocation, {}>;

export type OpenHyperLinkWidgetActionData = WidgetActionPayload<WidgetActionType.OpenHyperLink, {}>;

// Add new WidgetActionPayloads here

//==== end Widget Action Data ==========================================

export type WidgetActions<ActionData> = {
  url: string;
  label: string;
} & ActionData;

export type WidgetAction = WidgetActions<
  GoToBidDetailsWidgetActionData | ShareOneTimeLocationActionData | OpenHyperLinkWidgetActionData
>;

import {
  AddPrivateNetworkMembers,
  DeletePrivateNetworkMembers,
  FetchLoadBoardResponse,
  FetchPrivateNetworkMembers,
  InvalidMembersResponse,
  WhiteListResponse,
} from '@/model';
import { Api } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';

export class PrivateNetworkClient extends BaseClient {
  constructor(api: Api) {
    super(api);
  }
  getDefaultLoadBoard$ = () => this.api.get$<FetchLoadBoardResponse>('/loadboards/default', {});
  getWhiteList$ = (data: FetchPrivateNetworkMembers) =>
    this.api.get$<WhiteListResponse>(
      `/loadboards/${data.id}/whitelist/dots?sortOrder=${data.sortOrder}&sortBy=dotNumber`,
      {}
    );
  addWhiteListItems$ = (data: AddPrivateNetworkMembers) =>
    this.api.put$<InvalidMembersResponse>(`/loadboards/${data.id}/whitelist/dots`, {
      dotNumbers: data.validDotNumbers,
    });

  deleteWhiteListItems$ = (data: DeletePrivateNetworkMembers) =>
    this.api.post$<InvalidMembersResponse>(`/loadboards/${data.id}/whitelist/dots/delete`, {
      dotNumbers: data.usDotNumber,
    });
}

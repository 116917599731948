import styled from 'styled-components';

import { ThemeProps, withTheme } from '@style/WithTheme';

export const ComplexLogoContainer = withTheme()(styled.div`
  height: 40px;
  order: 2;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 33%;
  margin-right: 5px;

  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.md}px) {
    margin-right: 0px;
    order: 1;
  }
  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    flex-basis: 30%;
  }
`);

export const ComplexLogo = styled.img`
  height: 40px !important;
  height: 24px;
`;

export const IconLink = styled.a`
  display: flex;
  align-items: center;
`;

export const ComplexIconLink = styled(IconLink)`
  padding: 0 10px;
`;

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api } from '@common/api';
import { GeocodingClient } from '@common/client/GeocodingClient';
import { loadGeolocationFrom } from '@common/helper/LoadHelper';
import { Geolocation, LoadGeolocation } from '@common/model';
import { Response } from '@common/redux/Base';

const FETCH_GEOLOCATION = 'FETCH_GEOLOCATION';
const FETCH_GEOLOCATION_FULFILLED = 'FETCH_GEOLOCATION_FULFILLED';
//@TODO invalid interface LoadGeolocation is used, should be changed to ReverseGeolocationResult
export type ReverseGeolocationResponse = Response<LoadGeolocation>;

interface ReverseGeolocationAction extends Action {
  geolocation: Geolocation;
}

interface GeolocationReversedAction extends Action {
  response: ReverseGeolocationResponse;
}

export const fetchLocation = (geolocation: Geolocation): ReverseGeolocationAction => {
  return {
    type: FETCH_GEOLOCATION,
    geolocation: geolocation,
  };
};

export const geolocationReversedResponse = (response: ReverseGeolocationResponse): GeolocationReversedAction => ({
  type: FETCH_GEOLOCATION_FULFILLED,
  response: response,
});

export interface LocationState {
  isFetchingLocation: boolean;
  reverseGeolocation?: ReverseGeolocationResponse;
}

const initialState: LocationState = {
  isFetchingLocation: false,
};

export const locationReducer = (state: LocationState = initialState, action: Action): LocationState => {
  switch (action.type) {
    case FETCH_GEOLOCATION:
      return {
        ...state,
        reverseGeolocation: undefined,
        isFetchingLocation: true,
      };
    case FETCH_GEOLOCATION_FULFILLED: {
      const geolocationAction = action as GeolocationReversedAction;
      return {
        ...state,
        isFetchingLocation: false,
        reverseGeolocation: geolocationAction.response,
      };
    }
    default:
      return state;
  }
};

const reverseCurrentGeolocation$ = (action$: ActionsObservable<Action>, client: GeocodingClient) =>
  action$.ofType(FETCH_GEOLOCATION).pipe(
    mergeMap$((response: any) => {
      const geolocation = response.geolocation;
      return client.locationFor$(geolocation).pipe(
        map$((locationResponse) => {
          return locationResponse.result(
            (locations) =>
              geolocationReversedResponse({
                success: true,
                payload: loadGeolocationFrom(geolocation, locations),
              }),
            (error) => geolocationReversedResponse({ success: false, error: error })
          );
        })
      );
    })
  );

export const createLocationEpic$ = (api: Api) => {
  const geocodingClient = new GeocodingClient(api);
  return (action$: ActionsObservable<Action>) => merge$(reverseCurrentGeolocation$(action$, geocodingClient));
};

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components';

import { appendFeedLinks, appendVideoLinks, FeedSection, fetchFeed } from '@/reduxStore/epic/FeedEpic';
import { ComingSoon } from '@component/feed/ComingSoon';
import { ColorType, FeedPopupButtons } from '@component/feed/FeedPopupButtons';
import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { MenuSimpleBar } from '@component/menu/menuStyles';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { PageScroll } from '@component/scrollView';
import { useLinksAppending } from '@page/dashboard/FeedHooks';
import { Color } from '@style/Color';
import { ElementSize, FEED_POPUP_HEADER_ZINDEX, FEED_POPUP_ZINDEX } from '@style/StyleConstants';
import { AppConfig } from '@util/AppConfig';
import { changeRelativePathsToAbsolute } from '@util/helper/PathHelper';
import { useIsMobileView, useSelector } from '@util/hooks';

const POPUP_TRANSITION_MS = 250;
const MOBILE_MENU_TRANSITION_TIMING_FUNC = 'cubic-bezier(0.765, 0.005, 0.755, 0.605)';
const VIDEO_BASE_PATH = AppConfig.LBConfig.VideoFeedBaseUrl;
const FEED_BASE_PATH = AppConfig.LBConfig.FeedBaseUrl;
const POPUP_WIDTH = 450;
const EXTRA_SMALL_DEVICE_WIDTH = 340;

interface FeedPopupProps {
  isOpened: boolean;
  isMobileView: boolean;
  screenWidth?: number;
}

const Popup = styled.div`
  position: fixed;
  right: 0;
  width: ${POPUP_WIDTH}px;
  background-color: ${Color.WHITE};
  height: 100vh;
  z-index: ${FEED_POPUP_ZINDEX};
  transform: ${(props: FeedPopupProps) => (props.isOpened ? 'translateX(0%)' : 'translateX(100%)')};
  transition: all ${POPUP_TRANSITION_MS}ms ${MOBILE_MENU_TRANSITION_TIMING_FUNC};
`;

const MobileDrawerBaseDiv = styled.div`
  position: fixed;
  right: 0;
  overflow: hidden;
  width: ${(props: FeedPopupProps) => getPopupWidth(props)};
  transition: all ${POPUP_TRANSITION_MS}ms ${MOBILE_MENU_TRANSITION_TIMING_FUNC};
  background-color: ${Color.WHITE};
  z-index: ${FEED_POPUP_ZINDEX};
`;

const MobilePopupHeader = styled(MobileDrawerBaseDiv)`
  z-index: ${FEED_POPUP_HEADER_ZINDEX};
`;

const MobilePopupContent = styled(MobileDrawerBaseDiv)`
  position: absolute;
  padding-top: ${ElementSize.ListRowHeight}px;
  min-height: 100vh;
`;

const PopupContent = styled.div`
  background-color: ${Color.GRAY_LIGHT};
  height: 100%;
  min-height: 100vh;
  padding-bottom: ${(props: { isMobileView: boolean }) => (props.isMobileView ? 0 : ElementSize.ListRowHeight)}px;
`;

export const FeedPopup: React.FC = () => {
  const selectedFeedSection = useSelector((state) => state.feedState.selectedFeedSection);
  const isPopupOpened = selectedFeedSection !== undefined;

  return (
    <Popup isOpened={isPopupOpened} isMobileView={false}>
      <FeedPopupButtons colorType={ColorType.Light} isWithBorder={true} isWithButtonsAnimation={false} />
      <PageScroll>
        <FeedPopupContent />
      </PageScroll>
    </Popup>
  );
};

export const FeedPopupMob: React.FC = () => {
  const selectedFeedSection = useSelector((state) => state.feedState.selectedFeedSection);
  const isMobileView = useIsMobileView();
  const isWindowScrollMode = useIsWindowScrollMode();
  const simpleBarRef = useRef(null);
  const [width, setWidth] = useState<number>();
  const isPopupOpened = selectedFeedSection !== undefined;

  const onWidthChange = (newWidth: number) => {
    setWidth(newWidth);
  };

  // in a case if we have small device width we should remove title out of popup header to fit buttons with labels
  const shouldRemovePopupTitle = width ? width < EXTRA_SMALL_DEVICE_WIDTH : false;

  return (
    <>
      <ReactResizeDetector handleWidth={true} onResize={onWidthChange} />
      <MobilePopupHeader isOpened={isPopupOpened} isMobileView={isMobileView} screenWidth={width}>
        <FeedPopupButtons
          colorType={ColorType.Light}
          isWithBorder={true}
          isWithButtonsAnimation={false}
          shouldRemovePopupTitle={shouldRemovePopupTitle}
        />
      </MobilePopupHeader>
      <MobilePopupContent isOpened={isPopupOpened} isMobileView={isMobileView} screenWidth={width}>
        {isWindowScrollMode ? (
          <FeedPopupContent />
        ) : (
          <MenuSimpleBar innerRef={simpleBarRef} opened={isPopupOpened}>
            <FeedPopupContent />
          </MenuSimpleBar>
        )}
      </MobilePopupContent>
    </>
  );
};

const FeedPopupContent: React.FC = () => {
  const selectedFeedSection = useSelector((state) => state.feedState.selectedFeedSection);

  if (selectedFeedSection === FeedSection.Feed) {
    return <Feed />;
  }
  if (selectedFeedSection === FeedSection.Videos) {
    return <Videos />;
  }
  return null;
};

const Feed: React.FC = () => {
  const dispatch = useDispatch();
  const isMobileView = useIsMobileView();
  const isBroker = !useIsCarrier();

  const feed = useSelector((state) => state.feedState.feed);
  const shouldAppendFeedLinks = useSelector((state) => state.feedState.shouldAppendFeedLinks);
  const [isPathSetToAbsolute, setIsPathSetToAbsolute] = useState(false);

  useEffect(() => {
    setIsPathSetToAbsolute(false);
    dispatch(fetchFeed(isBroker));
  }, [isBroker]);

  useLayoutEffect(() => {
    if (feed) {
      setIsPathSetToAbsolute(
        changeRelativePathsToAbsolute(feed, ['src', 'href', 'icon', 'background'], FEED_BASE_PATH)
      );
    }
  }, [feed]);

  useLinksAppending(feed, shouldAppendFeedLinks, appendFeedLinks);

  const innerFeed = useMemo(
    () => (isPathSetToAbsolute && feed ? feed.body.innerHTML : ''),
    [feed, isPathSetToAbsolute]
  );

  return (
    <PopupContent isMobileView={isMobileView}>
      <div dangerouslySetInnerHTML={{ __html: feed && isPathSetToAbsolute ? innerFeed : '' }} />
    </PopupContent>
  );
};

const Videos: React.FC = () => {
  const videos = useSelector((state) => state.feedState.video);
  const shouldAppendVideoLinks = useSelector((state) => state.feedState.shouldAppendVideoLinks);
  const isMobileView = useIsMobileView();

  useLayoutEffect(() => {
    if (videos) {
      changeRelativePathsToAbsolute(videos, ['src', 'href', 'icon', 'background'], VIDEO_BASE_PATH);
    }
  }, [videos]);

  useLinksAppending(videos, shouldAppendVideoLinks, appendVideoLinks);

  const innerVideos = useMemo(() => (videos ? videos.body.innerHTML : ''), [videos]);

  return (
    <PopupContent isMobileView={isMobileView}>
      {videos ? <div dangerouslySetInnerHTML={{ __html: innerVideos }} /> : <ComingSoon />}
    </PopupContent>
  );
};

const getPopupWidth = (props: FeedPopupProps) => {
  if (props.isOpened) {
    if (props.screenWidth && props.screenWidth < POPUP_WIDTH) {
      return '100%';
    }
    return `${POPUP_WIDTH}px`;
  }
  return 0;
};

import { trim } from 'lodash';

import { UserInfo } from '@common/model';

export const getInitials = (user: Pick<UserInfo, 'firstName' | 'lastName'> | undefined) => {
  const firstName = trim(user?.firstName);
  const lastName = trim(user?.lastName);
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  } else if (firstName) {
    return `${firstName[0]}`;
  } else if (lastName) {
    return `${lastName[0]}`;
  }
  return '';
};

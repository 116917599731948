import { useMemo } from 'react';

import { reverse } from 'lodash';

import { getLoadBidOption, isLoadBooked, isLoadBookingRejected } from '@common/helper';
import { Flag, Load } from '@common/model';
import { LoadContactOption } from '@common/model/Load';
import { Vendor } from '@common/model/VendorBid';
import { useIsBiddingEnabled } from '@page/bids/BidStatusHelper';
import { useSelector } from '@util/hooks';

export const useContactOptions = (
  load: Load | undefined,
  isOriginMessaging: boolean,
  isOriginBids: boolean,
  isReversePriority?: boolean,
  shouldShowOptionsBeforeBid?: boolean
): LoadContactOption[] => {
  const isBiddingEnabled = useIsBiddingEnabled();
  const isCommunicationsEnabled = useSelector((state) => state.communication.isCommunicationsEnabled);
  const shouldHideContactBeforeParadeBid = useSelector(
    (state) => state.settings.systemSetting[Flag.HideParadeContact].value
  );
  const isOriginBidsOrMessaging = isOriginBids || isOriginMessaging;
  const hasMessageOption =
    !isOriginBidsOrMessaging && isCommunicationsEnabled && load?.conversation?.canInitiateConversation;
  const isBookNowEnabled = useSelector((state) => !state.settings.systemSetting[Flag.BookNowDisabled].value);

  return useMemo(() => {
    const options: LoadContactOption[] = [];
    if (!load) {
      return options;
    }

    if (load.vendorInfo?.vendorId === Vendor.VHub && load.vHub) {
      return [LoadContactOption.VhubBook];
    }

    if (!(load.vendorInfo?.vendorId === Vendor.Parade && shouldHideContactBeforeParadeBid && !load.vendorInfo?.bid)) {
      // No email button when origin is messaging (LB-1669)
      if (!hasMessageOption && load.dispatchEmail && !isOriginMessaging) {
        options.push(LoadContactOption.Email);
      }

      if (load.dispatchPhone) {
        options.push(LoadContactOption.Call);
      }

      if (hasMessageOption) {
        options.push(LoadContactOption.Message);
      }
    }

    const loadBidOption = getLoadBidOption(load, isOriginBidsOrMessaging, isBiddingEnabled);
    if (loadBidOption) {
      options.push(loadBidOption);
    }

    if (isBookNowEnabled && (load.canBookNow || load.bookNow) && !isLoadBooked(load) && !isLoadBookingRejected(load)) {
      options.push(LoadContactOption.BookNow);
    }

    return isReversePriority ? reverse(options) : options;
  }, [
    load?.vendorInfo,
    load?.vHub,
    load?.dispatchEmail,
    load?.dispatchPhone,
    load?.canBookNow,
    load?.bookNow,
    isOriginBids,
    isOriginMessaging,
    isReversePriority,
    shouldShowOptionsBeforeBid,
    shouldHideContactBeforeParadeBid,
    load?.rateNegotiations?.status,
    isBiddingEnabled,
    isCommunicationsEnabled,
    isBookNowEnabled,
    load?.metadata?.userdata?.progress,
  ]);
};

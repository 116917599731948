import React from 'react';

import { Tooltip, TooltipProps } from 'react-leaflet';
import styled from 'styled-components';

import { ThemeProps, withTheme } from '@style/WithTheme';

const MARKER_DIRECTION = 'top';

const StyledTooltip = withTheme()(styled(Tooltip)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.secondary.main};
    color: ${(props: ThemeProps) => props.theme.palette.secondary.contrastText};
    border: none;

    &:before {
      border-top-color: ${(props: ThemeProps) => props.theme.palette.secondary.main};
    }

    .title {
      font-size: 12px;
    }

    .content {
      font-size: 14px;
    }
  }
`);

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 7px;
`;

export interface SimpleTooltipProps extends TooltipProps {
  id: string;
  title: string;
  value?: string;
}

export const SimpleTooltip: React.SFC<SimpleTooltipProps> = (props) => {
  return (
    <StyledTooltip direction={MARKER_DIRECTION} {...props}>
      <ContentDiv>
        <span id={props.id + '_title'} className="title">
          {props.title}
        </span>
        <span id={props.id + '_content'} className="content">
          {props.value}
        </span>
      </ContentDiv>
    </StyledTooltip>
  );
};

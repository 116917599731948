import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { toggleMenu } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { default as IconMenu } from '@component/menu/icon/icon-menu.svg?react';
import {
  AppLogoContainer,
  DESKTOP_MENU_TRANSITION_MS,
  DESKTOP_MENU_TRANSITION_TIMINGFUNC,
  Header,
  MENU_ICON_SIZE,
  SideMenuIconButton,
} from '@component/menu/menuStyles';
import { Color } from '@style/Color';
import { ContentSize } from '@style/StyleConstants';
import { useWindowWidth } from '@util/hooks';
import { Width } from '@util/MediaQueries';

import { AppLogo } from './AppLogo';

const StyledIconMenu = styled(IconMenu)`
  transition: stroke ${DESKTOP_MENU_TRANSITION_MS} ${DESKTOP_MENU_TRANSITION_TIMINGFUNC};
`;

interface MenuHeaderProps {
  isExpanded: boolean;
}
export const getHeaderLogoSize = (width: number): ContentSize => {
  if (width < Width.headerSmallerLogo) {
    return ContentSize.ExtraSmall;
  } else if (width < Width.xs) {
    return ContentSize.Small;
  } else {
    return ContentSize.Normal;
  }
};
export const MenuHeader: React.FC<MenuHeaderProps> = ({ isExpanded }) => {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const logoSize = getHeaderLogoSize(width);
  return (
    <Header>
      <SideMenuIconButton id={'id_close_icon_button'} onClick={() => dispatch(toggleMenu())}>
        <StyledIconMenu
          width={MENU_ICON_SIZE}
          stroke={isExpanded ? Color.GRAY_STONE : Color.GRAY_LIGHT}
          fill={isExpanded ? Color.GRAY_STONE : Color.GRAY_LIGHT}
        />
      </SideMenuIconButton>
      <AppLogoContainer id="id_logo_button" onClick={() => dispatch(toggleMenu())}>
        <AppLogo size={logoSize} isInHeader={true} />
      </AppLogoContainer>
    </Header>
  );
};

import React, { useEffect, useRef } from 'react';

import { findIndex, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import { displayNameOfDocumentType } from '@common/helper/DocumentSortingHelper';
import { Document } from '@common/model';
import { updateAttachDocumentsForm } from '@common/redux/epic/CommunicationEpic';
import { DocumentsContext } from '@common/redux/epic/DocumentListEpic';
import { deleteDocument, fetchDocumentFile, updateSendDocumentsForm } from '@common/redux/epic/DocumentsEpic';
import { Button } from '@component/button/Button';
import { MenuOptionWithIcon, Option } from '@component/control/ActionsMenu';
import { ActionIcon } from '@component/icon/ActionIcon';
import { DropdownMenu } from '@component/menu/DropdownMenu';
import { default as CloseIcon } from '@component/panels/basePanel/loadDetails/close.svg';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { showDeleteDocumentPopup } from '@page/myDocuments/DeletePopups';
import { myDocumentsEncoder } from '@page/myDocuments/MyDocumentsEncoder';
import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { downloadBase64File } from '@util/FileHelper';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

import { default as AddFileSVG } from './add-file.svg?react';
import { default as ChangeFolderSVG } from './change-folder.svg?react';
import { default as ChangeTypeSVG } from './change-type.svg?react';
import { default as DeleteSVG } from './delete.svg?react';
import { default as DownloadSVG } from './download.svg?react';
import { default as EditSVG } from './edit.svg?react';
import { default as FolderSVG } from './folder.svg?react';
import { default as RenameSVG } from './rename.svg?react';
import { default as SelectSVG } from './select.svg?react';
import { default as SendSVG } from './send.svg?react';
import { default as ViewSVG } from './view.svg?react';

const Container = styled.div`
  width: 300px;
`;

const DocumentInfoContainer = styled(({ isSmallPadding, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: space-between;
  padding: ${({ isSmallPadding }) => (isSmallPadding ? '14px 14px 22px 14px' : '14px 30px 22px 30px')};
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
`;
const DocumentInfoContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DocumentTypeText = styled.span`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
  margin-bottom: 8px;
`;

const DocumentName = styled.span`
  font-size: ${FontSize.ModalTitle}px;
  color: ${Color.GRAY_DARK};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FolderContainer = styled(({ hasTopElement, isSmallPadding, ...rest }) => <div {...rest} />)`
  display: flex;
  padding-top: ${({ hasTopElement }) => (hasTopElement ? '10px' : '2px')};
  padding-bottom: 10px;
  padding-left: ${({ isSmallPadding }) => (isSmallPadding ? '14px' : '28px')};
  padding-right: ${({ isSmallPadding }) => (isSmallPadding ? '14px' : '16px')};
  align-items: center;
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
  flex: 1;
`;

const FolderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 156px;
  margin: 0px 8px 0px 16px;
`;

const FolderName = styled.span`
  font-size: ${FontSize.ModalTitle}px;
  color: ${Color.GRAY_DARK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoadOriginDestination = styled(FolderName)`
  font-size: ${FontSize.Body}px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

interface HeaderMenuActions {
  addNewDocument: () => void;
  editFolderName?: () => void;
  deleteFolder?: () => void;
}

interface DocumentMenuActions {
  attachToSend: () => void;
  rename: () => void;
  changeFolder: () => void;
  changeType: () => void;
  download: () => void;
  delete: () => void;
}

interface AttachedDocumentActions {
  viewDocument: () => void;
  removeAttachment: () => void;
}

interface ViewDocumentActions {
  removeAttachment: () => void;
}

interface SelectFolderActions {
  select: () => void;
  editFolder?: () => void;
  deleteFolder?: () => void;
}

interface LoadInfo {
  loadID: string;
  originDestination: string;
}

interface Props extends ActionsMenuContentProps {
  anchor: HTMLElement | undefined;
}

interface ActionsMenuContentProps {
  documentType?: string;
  documentName?: string;
  folderName?: string;
  options: Option[];
  loadInfo?: LoadInfo;
  hasCloseButton?: boolean;
  onClose: () => void;
}

export const headerMenuOptions = (actions: HeaderMenuActions): Option[] => {
  const options: Option[] = [
    {
      title: t(T.common_myDocuments_addNewDocument),
      icon: <AddFileSVG id="add" />,
      action: actions.addNewDocument,
    },
  ];
  if (actions.editFolderName) {
    options.push({
      title: t(T.common_myDocuments_editFolderName),
      icon: <EditSVG id="edit" />,
      action: actions.editFolderName,
    });
  }
  if (actions.deleteFolder) {
    options.push({
      title: t(T.common_myDocuments_deleteFolder),
      icon: <DeleteSVG id="delete" />,
      action: actions.deleteFolder,
      color: Color.RED_APPLE,
    });
  }
  return options;
};

export const documentMenuOptions = (actions: DocumentMenuActions): Option[] => [
  {
    title: t(T.common_myDocuments_attachToSendDocument),
    icon: <SendSVG id="send" />,
    action: actions.attachToSend,
    testId: 'attach_to_send',
  },
  {
    title: t(T.common_myDocuments_documentActionSheet_renameDocument),
    icon: <RenameSVG id="rename" />,
    action: actions.rename,
    testId: 'rename_document',
  },
  {
    title: t(T.common_myDocuments_documentActionSheet_changeFolder),
    icon: <ChangeFolderSVG id="change_folder" />,
    action: actions.changeFolder,
    testId: 'change_folder',
  },
  {
    title: t(T.common_myDocuments_documentActionSheet_changeType),
    icon: <ChangeTypeSVG id="change_type" />,
    action: actions.changeType,
    testId: 'change_type',
  },
  {
    title: t(T.common_myDocuments_documentActionSheet_downloadDocument),
    icon: <DownloadSVG id="download" />,
    action: actions.download,
    testId: 'download_document',
  },
  {
    title: t(T.common_myDocuments_documentActionSheet_deleteDocument),
    icon: <DeleteSVG id="delete" />,
    action: actions.delete,
    testId: 'delete_document',
  },
];

export const attachedDocumentOptions = (actions: AttachedDocumentActions) => [
  {
    title: t(T.common_myDocuments_viewDocument),
    icon: <ViewSVG id="view" />,
    action: actions.viewDocument,
  },
  {
    title: t(T.common_myDocuments_removeAttachment),
    icon: <DeleteSVG id="delete" />,
    action: actions.removeAttachment,
  },
];

export const viewDocumentOptions = (actions: ViewDocumentActions) => [
  {
    title: t(T.common_myDocuments_removeAttachment),
    icon: <DeleteSVG id="delete" />,
    action: actions.removeAttachment,
  },
];

export const selectFolderOptions = (actions: SelectFolderActions) => {
  const options: Option[] = [
    {
      title: t(T.common_myDocuments_documentActionSheet_select),
      icon: <SelectSVG id="select" />,
      action: actions.select,
    },
  ];
  if (actions.editFolder) {
    options.push({
      title: t(T.common_myDocuments_editFolderName),
      icon: <EditSVG id="edit" />,
      action: actions.editFolder,
    });
  }
  if (actions.deleteFolder) {
    options.push({
      title: t(T.common_myDocuments_deleteFolder),
      icon: <DeleteSVG id="delete" />,
      action: actions.deleteFolder,
      color: Color.RED_APPLE,
    });
  }

  return options;
};

export const ActionsMenu: React.FC<Props> = ({ anchor, ...rest }) => (
  <DropdownMenu anchor={anchor} onClose={rest.onClose}>
    <ActionsMenuContent {...rest} />
  </DropdownMenu>
);

export const ActionsMenuContent: React.FC<ActionsMenuContentProps> = (props) => {
  const history = useHistory();
  const urlParams = useParams();
  const documentTypes = useSelector((state) => state.documents.documentTypes.types);
  const documentType = props.documentType ? displayNameOfDocumentType(props.documentType, documentTypes) : undefined;

  const actionWrapper = (action: () => void) => () => {
    props.onClose();
    return action();
  };

  const openLoadDetails = () =>
    history.push({
      pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, { ...urlParams, loadID: props.loadInfo?.loadID }),
      search: history.location.search,
    });

  const folderInfoElement = props.folderName ? (
    <FolderContainer
      hasTopElement={Boolean(props.documentName && documentType)}
      isSmallPadding={!!props.hasCloseButton}
    >
      <FolderSVG id="folder" />
      <FolderInfoContainer>
        <FolderName id="name">{props.folderName}</FolderName>
        {props.loadInfo ? (
          <LoadOriginDestination id="address">{props.loadInfo.originDestination}</LoadOriginDestination>
        ) : null}
      </FolderInfoContainer>
      {props.loadInfo ? <img id="arrow_right" src={icons.arrowRight} /> : null}
    </FolderContainer>
  ) : null;

  const folderInfoHeaderElement =
    props.documentName && documentType ? (
      <DocumentInfoContainer isSmallPadding={!!props.hasCloseButton}>
        <DocumentInfoContainerColumn>
          <DocumentTypeText id="document_type">
            {t(T.common_myDocuments_documentActionSheet_title, {
              documentType: documentType,
            })}
          </DocumentTypeText>
          <DocumentName id="document_name">{props.documentName}</DocumentName>
        </DocumentInfoContainerColumn>
        {props.hasCloseButton ? (
          <ActionIcon
            id="popup_close"
            src={CloseIcon}
            alt="close"
            onClick={props.onClose}
            style={{ marginTop: '-5px' }}
          />
        ) : null}
      </DocumentInfoContainer>
    ) : null;

  return (
    <Container id="actions_menu" style={{ paddingBottom: props.hasCloseButton ? Spacing.LargePaddingVertical : 0 }}>
      {folderInfoHeaderElement}
      {props.loadInfo ? (
        <Button id="load" onClick={actionWrapper(openLoadDetails)} style={{ width: '100%' }}>
          {folderInfoElement}
        </Button>
      ) : (
        folderInfoElement
      )}
      <Options>
        {map(props.options, (option) => (
          <MenuOptionWithIcon
            {...option}
            action={actionWrapper(option.action)}
            key={option.title}
            isSmallPadding={!!props.hasCloseButton}
          />
        ))}
      </Options>
    </Container>
  );
};

export const useDocumentActions = (
  isDownloading: boolean,
  document: Document | undefined,
  onDownloadStart: () => void,
  onDownloadEnd: () => void
): DocumentMenuActions => {
  const history = useHistory();
  const urlParams = useParams();
  const isLoadingDocumentData = useSelector((state) => state.documents.viewDocument.isLoadingDocumentData);
  const prevIsLoadingDocumentData = useRef(isLoadingDocumentData);
  const documentFileData = useSelector((state) => state.documents.viewDocument.documentFileData);
  const documentCache = useSelector((state) => state.documents.viewDocument.documentCache);
  const myDocumentsSearchParams = myDocumentsEncoder.convertUrlSearchParamsToObject(history.location.search);
  const context = myDocumentsSearchParams.context ?? DocumentsContext.DEFAULT;

  const isCommunicationsAttachments = context === DocumentsContext.CONVERSATION_ATTACHMENTS;

  const communicationDocuments = useSelector((state) => state.communication.attachDocuments.selectedDocuments);
  const myDocsDocuments = useSelector((state) => state.documents.sendDocuments.formInfo.documents);

  const attachedDocuments = isCommunicationsAttachments ? communicationDocuments : myDocsDocuments;

  const popupContext = usePopup();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDownloading && prevIsLoadingDocumentData.current && !isLoadingDocumentData && document) {
      if (documentFileData?.id === document.id) {
        downloadBase64File(documentFileData.file, document.displayName, document.extension);
      } else if (documentCache[document.id]) {
        history.push({
          pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, {
            ...urlParams,
            failure: 'failed-document-download',
          }),
          search: history.location.search,
        });
      }
      onDownloadEnd();
    }
    prevIsLoadingDocumentData.current = isLoadingDocumentData;
  }, [isLoadingDocumentData, isDownloading, documentFileData, document, document ? documentCache[document.id] : null]);

  const attachDocumentToSend = () => {
    if (document) {
      const newAttachments =
        findIndex(attachedDocuments, (attachedDocument) => attachedDocument.id === document.id) === -1
          ? [...attachedDocuments, document]
          : attachedDocuments;

      dispatch(
        isCommunicationsAttachments
          ? updateAttachDocumentsForm({ selectedDocuments: [...newAttachments] })
          : updateSendDocumentsForm({ documents: [...newAttachments] })
      );

      history.push({
        pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, {
          ...urlParams,
          viewDocument: undefined,
          sendDocuments: 'send-documents',
        }),
        search: history.location.search,
      });
    }
  };

  const openRenameDocumentPanel = () => {
    if (document) {
      history.push({
        pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, { ...urlParams, renameDocument: 'rename-document' }),
        search: myDocumentsEncoder.convertObjectRequestToUrlParams({
          ...myDocumentsSearchParams,
          documentId: document.id,
          documentName: document.displayName,
        }),
      });
    }
  };

  const changeFolder = () => {
    if (document) {
      history.push({
        pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, {
          ...urlParams,
          select: 'folder',
        }),
        search: myDocumentsEncoder.convertObjectRequestToUrlParams({
          ...myDocumentsSearchParams,
          documentId: document.id,
        }),
      });
    }
  };

  const changeType = () => {
    if (document) {
      history.push({
        pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, {
          ...urlParams,
          select: 'type',
        }),
        search: myDocumentsEncoder.convertObjectRequestToUrlParams({
          ...myDocumentsSearchParams,
          documentType: document.type,
          documentId: document.id,
        }),
      });
    }
  };

  const download = () => {
    if (document) {
      onDownloadStart();
      dispatch(fetchDocumentFile(document.id, document.extension));
    }
  };

  const deleteDocumentAction = () => {
    if (document) {
      showDeleteDocumentPopup(popupContext, () => dispatch(deleteDocument(document.id)));
    }
  };

  return {
    attachToSend: attachDocumentToSend,
    delete: deleteDocumentAction,
    download: download,
    changeType: changeType,
    changeFolder: changeFolder,
    rename: openRenameDocumentPanel,
  };
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';

import { Api } from '@common/api';
import { SharedLoad, SharedLoadClient } from '@common/client/SharedLoadClient';
import { BaseState, Response } from '@common/redux/Base';

import { standardApiEpic } from './EpicHelper';

const FETCH_SHARED_LOAD = 'FETCH_SHARED_LOAD';
const FETCH_SHARED_LOAD_FULFILLED = 'FETCH_SHARED_LOAD_FULFILLED';

export interface SharedLoadResponse extends Response<SharedLoad> {
  isUnavailable: boolean;
}

interface FetchSharedLoad extends Action {
  token: string;
  isPoster: boolean | undefined;
}

interface FetchSharedLoadResponse extends Action {
  response: SharedLoadResponse;
}

export function fetchSharedLoad(token: string, isPoster?: boolean): FetchSharedLoad {
  return {
    type: FETCH_SHARED_LOAD,
    token: token,
    isPoster: isPoster,
  };
}

export interface SharedLoadState extends BaseState {
  response: SharedLoadResponse;
}

export type SharedLoadActions = FetchSharedLoad | FetchSharedLoadResponse;

export const fetchSharedLoadResponse = (response: SharedLoadResponse): FetchSharedLoadResponse => ({
  type: FETCH_SHARED_LOAD_FULFILLED,
  response: response,
});

export const sharedLoadReducer = (state = {}, action: SharedLoadActions) => {
  switch (action.type) {
    case FETCH_SHARED_LOAD_FULFILLED: {
      const sharedLoadAction = action as FetchSharedLoadResponse;
      return {
        ...state,
        response: sharedLoadAction.response,
        isLoading: false,
      };
    }
    case FETCH_SHARED_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export const createSharedLoadEpic = (api: Api, isLiveEnvironment: boolean) => {
  const sharedLoadClient = new SharedLoadClient(api, isLiveEnvironment);
  return (action$: ActionsObservable<SharedLoadActions>) =>
    standardApiEpic<SharedLoad>(
      action$,
      FETCH_SHARED_LOAD,
      (action: FetchSharedLoad) => sharedLoadClient.fetchSharedLoad$(action.token, action.isPoster),
      (successResponse) =>
        fetchSharedLoadResponse({
          payload: successResponse.data,
          success: true,
          isUnavailable: false,
        }),
      (error) =>
        fetchSharedLoadResponse({
          success: false,
          error: error,
          isUnavailable: error.httpStatus === 404 || error.httpStatus === 403,
        })
    );
};

import React from 'react';

import styled from 'styled-components';

import { Paper } from '@material-ui/core';

import { Text, TextStyle } from '@component/text';
import { withTheme } from '@style/WithTheme';

export const PaperInsideStretchContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const BottomButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

export const PaperURLContainer = styled.div`
  margin-top: 8px;
`;

export const StyledPaper = withTheme()(styled(Paper)`
  && {
    display: flex;
    flex-grow: 1;
    padding: 16px;
    flex-direction: column;
    align-items: stretch;
    box-shadow: ${(props: CustomPaperProps) => paperBoxShadow(props.elevation)};

    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      border-radius: 0px;
    }
  }
`);

const BlockStyledPaper = styled(StyledPaper)`
  && {
    display: block;
  }
`;

const PaperTitle = withTheme()(styled.div`
  margin-bottom: ${(props: { hasTitle: boolean }) => (props.hasTitle ? '16px' : '0')};
`);

const paperBoxShadow = (elevation: ElevationLevel) => {
  switch (elevation) {
    case 0:
      return 'none';
    case 1:
      return '0 3px 6px 0 rgba(0, 0, 0, 0.16)';
    default:
    case 2:
      return '0 3px 6px 0 rgba(0, 0, 0, 0.27)';
    case 3:
      return '0 3px 8px 0 rgba(0, 0, 0, 0.31)';
  }
};

type ElevationLevel = 0 | 1 | 2 | 3 | undefined;

export interface CustomPaperProps {
  id: string;
  paperTitle?: string;
  elevation?: ElevationLevel;
  allowCollapsedMargins?: boolean;
  redTitle?: boolean;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

export const CustomPaper: React.SFC<CustomPaperProps> = (props) => {
  const { redTitle, paperTitle, allowCollapsedMargins, ...paperProps } = props;
  const CustomStyledPaper = allowCollapsedMargins ? BlockStyledPaper : StyledPaper;
  return (
    <CustomStyledPaper {...paperProps}>
      <PaperTitle hasTitle={paperTitle ? true : false}>
        <Text
          id={`${props.id}_title`}
          textStyle={redTitle ? TextStyle.RedHeader3 : TextStyle.Header3}
          isDisabled={props.isDisabled}
        >
          {paperTitle}
        </Text>
      </PaperTitle>
      {props.children}
    </CustomStyledPaper>
  );
};

import React from 'react';

import { isUndefined } from 'lodash';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

import { NotificationCount } from '@component/menu/menuStyles';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { Color } from '@style/Color';
import { FontLineHeight, FontSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

import { default as ArrowRight } from './arrow-right.svg';

interface FilterItem {
  id: string;
  name: string;
}

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 50px;
`;

export const SectionTitle = styled.span`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
`;

// augmenting specificity with && to allow styling material-ui component
export const SectionItem = styled<ButtonProps & { isSelected: boolean }>(({ isSelected, ...rest }) => (
  <Button {...rest} />
))`
  && {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 67px;
    padding: 0 14px 0 18px;
    margin-bottom: 1px;
    background-color: ${({ isSelected }) => (isSelected ? Color.ORANGE_LIGHT : Color.WHITE)};
    border-bottom: ${({ isSelected }) => (isSelected ? '2px' : 0)} solid ${Color.ORANGE_MAIN};
    text-transform: none;
    border-radius: 0;
  }
`;

export const SectionItemName = styled.span`
  font-size: ${FontSize.Content}px;
  font-weight: bold;
  color: ${Color.GRAY_DARK};
`;

export const RightSection = styled.span`
  font-size: ${FontSize.Content}px;
  font-weight: bold;
  margin-right: 8px;
  color: ${Color.ORANGE_MAIN};
  padding-top: 3px;
`;

export const CurrentFilter = styled.span`
  height: 12px;
  margin-bottom: ${Spacing.InterListElement}px;
  margin-left: ${Spacing.TwoSpaces}px;
  line-height: ${FontLineHeight.ButtonLabel}px;
  font-size: ${FontSize.Caption}px;
  color: ${Color.WHITE};
  background-color: ${Color.GREEN_MAIN};
  border-radius: 2px;
  padding-left: ${Spacing.InterListElement}px;
  padding-right: ${Spacing.InterListElement}px;
`;

const Badge = styled(NotificationCount)`
  position: static;
  margin: 0;
`;

export const FilterSectionItem = (props: {
  filter: FilterItem;
  selectedFilter: string | undefined;
  onFilterSelect: (id: string) => void;
  count?: number;
  price?: string;
  currentFilter?: Filter;
  showAsBadge?: boolean;
}) => {
  return (
    <SectionItem
      id={props.filter.id}
      key={props.filter.id}
      isSelected={props.filter.id === props.selectedFilter}
      onClick={() => props.onFilterSelect(props.filter.id)}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SectionItemName>{props.filter.name}</SectionItemName>
        {props.filter.id === props.currentFilter ? <CurrentFilter>{t(T.current)}</CurrentFilter> : null}
      </div>
      <div style={{ display: 'flex' }}>
        {(!isUndefined(props.count) && !props.showAsBadge) || props.price ? (
          <RightSection style={props.price ? { color: Color.GRAY_DARK } : {}}>
            {isUndefined(props.count) ? props.price : props.count}
          </RightSection>
        ) : null}
        {!isUndefined(props.count) && props.showAsBadge ? (
          <Badge opened={true} hasNotifications={props.count > 0}>
            <span>{props.count}</span>
          </Badge>
        ) : null}
        {props.filter.id === Filter.ResetPassword ? null : <img id="arrow_right" src={ArrowRight} alt={'arrow'} />}
      </div>
    </SectionItem>
  );
};

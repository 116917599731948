import { useEffect } from 'react';

import { default as ReactGA } from 'react-ga4';

import { UserProfile } from '@common/model/UserProfile';
import { AppConfig } from '@util/AppConfig';
import { useSelector } from '@util/hooks';

if (AppConfig.LBConfig.lbEnvironment !== 'test') {
  let measurementID: string;
  if (AppConfig.LBConfig.lbEnvironment === 'live' || AppConfig.LBConfig.lbEnvironment === 'beta') {
    measurementID = 'G-T0BSX32BR3';
  } else {
    measurementID = 'G-CVRZRNL3Q5';
  }

  ReactGA.initialize(measurementID);
}

export enum TrackingCategory {
  UserAction = 'User_action',
  Message = 'message',
}

class AnalyticsTracker {
  track = (category: TrackingCategory, action: string, extraInfo?: string, triggeredByUser: boolean = true) => {
    ReactGA.event({
      category: category,
      action: `Members - ${action}`,
      label: extraInfo,
      nonInteraction: !triggeredByUser,
    });
  };
  setUser = (user: UserProfile) => {
    ReactGA.set({
      userId: user.id,
      companyGuid: user.companyId ? user.companyId : '',
      companyCity: user.companyCity ? user.companyCity : '',
      companyState: user.companyState ? user.companyState : '',
      companyCountry: user.companyCountry ? user.companyCountry : '',
    });
  };
}

export const useSetGoogleAnalyticsUser = () => {
  const userProfile = useSelector((state) => state.user.profile?.payload);
  useEffect(() => {
    if (userProfile) {
      GA.setUser(userProfile);
    }
  }, [
    userProfile?.id,
    userProfile?.companyId,
    userProfile?.companyCity,
    userProfile?.companyState,
    userProfile?.companyCountry,
  ]);
};

//Calling it GA as a shorthand when calling it GA.track(...)
const GA = new AnalyticsTracker();

export { GA };

import React, { Component } from 'react';

import { find, isEqual, map, times } from 'lodash';
import moment from 'moment';

import {
  StyledContent,
  StyledCurrentDayPicker,
  StyledDaysContainer,
  StyledDisabledPicker,
  StyledEmptyPicker,
  StyledPicker,
  StyledPickerTitle,
  StyledPickerWrapper,
  StyledSelectedPicker,
} from './CalendarStyles';

interface MonthType {
  year: number;
  month?: string;
}

interface ComponentProps {
  date: MonthType;
  selectedItems?: string[];
  handleSelect(date: string): void;
  handleRemove(date: string): void;
}

interface ComponentState {
  dates: DayData[];
  focusedDate: string;
}

interface DayData {
  name: string;
  date: string;
  day: string;
}

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export class DatesCalendar extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      dates: [],
      focusedDate: '',
    };
  }

  componentDidMount(): void {
    this.getAllDaysInMonth();
    this.setState({ focusedDate: moment().format('YYYY-MM-DD') });
  }

  componentDidUpdate() {
    if (this.state.focusedDate) {
      const element = document.querySelector<HTMLElement>(`[aria-label="${this.state.focusedDate}"]`);
      element?.focus();
    }
  }

  addSelectedClick = (date: string) => () => {
    const { handleSelect } = this.props;
    handleSelect(date);
    this.setState({ focusedDate: date });
  };

  removeSelectedClick = (date: string) => () => {
    const { handleRemove } = this.props;
    handleRemove(date);
    this.setState({ focusedDate: date });
  };

  handleKeyDown = (callback: () => void) => (event: React.KeyboardEvent) => {
    if (event.keyCode === 32) {
      const focusedElement = event.target as Element;
      const date = focusedElement.getAttribute('aria-label');
      this.setState({ focusedDate: date ?? '' });
      callback();
    }
  };

  renderCorrectPickers = () => {
    const { dates } = this.state;
    const { selectedItems: selectedDates } = this.props;
    const allDays = [];
    for (let i = 0, weekLength = days.length; i < dates.length; ) {
      for (let j = 0; j < weekLength; j += 1) {
        if (i >= dates.length) {
          break;
        }
        const { date, day, name } = dates[i];
        if (isEqual(days[j], name)) {
          i += 1;
          if (moment(date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'), 'day')) {
            allDays.push(<StyledDisabledPicker key={date}>{day}</StyledDisabledPicker>);
          } else {
            if (find(selectedDates, (value) => moment(date).isSame(value, 'day'))) {
              allDays.push(
                <StyledSelectedPicker
                  id="selected_day"
                  aria-label={date}
                  key={date}
                  onClick={this.removeSelectedClick(date)}
                  onKeyDown={this.handleKeyDown(this.removeSelectedClick(date))}
                  tabIndex={0}
                >
                  {day}
                </StyledSelectedPicker>
              );
            } else {
              if (moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'), 'day')) {
                allDays.push(
                  <StyledCurrentDayPicker
                    id="current_day"
                    aria-label={date}
                    key={date}
                    onClick={this.addSelectedClick(date)}
                    onKeyDown={this.handleKeyDown(this.addSelectedClick(date))}
                    tabIndex={0}
                  >
                    {day}
                  </StyledCurrentDayPicker>
                );
              } else {
                allDays.push(
                  <StyledPicker
                    id="day"
                    aria-label={date}
                    key={date}
                    onClick={this.addSelectedClick(date)}
                    onKeyDown={this.handleKeyDown(this.addSelectedClick(date))}
                    tabIndex={0}
                  >
                    {day}
                  </StyledPicker>
                );
              }
            }
          }
        } else {
          allDays.push(<StyledEmptyPicker key={date + j} />);
        }
      }
    }
    return allDays;
  };

  getAllDaysInMonth = () => {
    const { date } = this.props;
    const daysInMonth: DayData[] = [];
    const monthDate = moment(`${date.year}-${date.month}`, 'YYYY-MMM').startOf('month');
    times(monthDate.daysInMonth(), () => {
      daysInMonth.push({
        date: monthDate.format('YYYY-MM-DD'),
        name: monthDate.format('ddd'),
        day: monthDate.format('D'),
      });
      monthDate.add(1, 'day');
    });
    this.setState({ dates: daysInMonth });
  };

  render() {
    const { date } = this.props;
    return (
      <StyledPickerWrapper id="month">
        <StyledPickerTitle id="title">
          {date.month} {date.year}
        </StyledPickerTitle>
        <StyledDaysContainer id="weekdays">
          {map(days, (day) => (
            <div id="weekday" key={day}>
              {day}
            </div>
          ))}
        </StyledDaysContainer>
        <StyledContent id="days">{this.renderCorrectPickers()}</StyledContent>
      </StyledPickerWrapper>
    );
  }
}

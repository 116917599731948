import React from 'react';

import { round } from 'lodash';
import styled from 'styled-components';

import { Tooltip } from '@material-ui/core';

import { Text, TextStyle } from '@component/text';
import { Color } from '@style/Color';
import { FontFamily, FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';

import RatingIconSmall from './icon-rating-small.svg';
import RatingIcon from './icon-rating.svg';

const RatingTextSmall = styled.div`
  display: block;
  color: ${Color.WHITE};
  font-size: ${FontSize.Caption}px;
  font-family: ${FontFamily.Main};
  padding-left: 3px;
  margin-top: 1px;
`;

const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  border-radius: 2px;
  padding: 2px 4px;
  margin-bottom: 2px;
  background-color: ${Color.GREEN_MAIN};
  max-height: 14px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  cursor: pointer;
`;

export const RatingBadge: React.FC<{ rating: number; testID: string }> = ({ rating, testID }) => {
  return (
    <Tooltip title={t(T.common_ratings_ratingsPoweredBy)} placement="top">
      <Container>
        <img src={RatingIcon} height={12} width={12} />
        <Text id={`${testID}_value`} style={{ paddingLeft: 5, display: 'block' }} textStyle={TextStyle.MidResult}>
          {round(rating, 1)}
        </Text>
      </Container>
    </Tooltip>
  );
};

export const RatingBadgeSmall: React.FC<{ rating: number; testID: string }> = ({ rating, testID }) => {
  return (
    <RatingContainer>
      <img src={RatingIconSmall} height={10} width={10} />
      <RatingTextSmall id={`${testID}_rating_value`}>{round(rating, 1)}</RatingTextSmall>
    </RatingContainer>
  );
};

import React from 'react';

import styled from 'styled-components';

import { displayCurrencyOrDash } from '@common/formatter';
import { LOADBOARD_HELP_GREENSCREENS } from '@common/info';
import { default as GreenscreensGraph } from '@component/panels/basePanel/loadDetails/marketRates/greenscreens-data.svg';
import { ModalTitleBold } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupEntity, PopupSizes } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text/Text';
import { Color } from '@style/Color';
import { t, T } from '@translate';

interface Props {
  costLow?: number;
  costHigh?: number;
  costLowPerMile?: number;
  costHighPerMile?: number;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const GreenscreensRateContent: React.FC<Props> = ({ costLow, costHigh, costLowPerMile, costHighPerMile }) => {
  return (
    <>
      <Row>
        <ModalTitleBold id="green_min" style={{ color: Color.GREEN_TITLE, textAlign: 'center' }}>
          {displayCurrencyOrDash(costLow, false)}
        </ModalTitleBold>
        <ModalTitleBold id="green_max" style={{ color: Color.GREEN_TITLE, textAlign: 'center' }}>
          {displayCurrencyOrDash(costHigh, false)}
        </ModalTitleBold>
      </Row>

      <Row style={{ marginBottom: 20 }}>
        <Text textStyle={TextStyle.SubtitleNormalWeight} style={{ color: Color.LIGHT_BLACK }} id="green_min_rate">
          {displayCurrencyOrDash(costLowPerMile, true, {
            suffix: t(T.common_marketRate_greenscreens_valuePerMile),
          })}
        </Text>
        <Text textStyle={TextStyle.SubtitleNormalWeight} style={{ color: Color.LIGHT_BLACK }} id="green_max_rate">
          {displayCurrencyOrDash(costHighPerMile, true, {
            suffix: t(T.common_marketRate_greenscreens_valuePerMile),
          })}
        </Text>
      </Row>

      <img id="green_graph" src={GreenscreensGraph} alt="GreenscreensGraph" />

      <Row style={{ marginTop: 7 }}>
        <Text
          textStyle={TextStyle.Header3}
          style={{ color: Color.GRAY_21, minWidth: 35, textAlign: 'center' }}
          id="min"
        >
          {t(T.common_marketRate_greenscreens_min)}
        </Text>
        <Text
          textStyle={TextStyle.Header3}
          style={{ color: Color.GRAY_21, minWidth: 35, textAlign: 'center' }}
          id="max"
        >
          {t(T.common_marketRate_greenscreens_max)}
        </Text>
      </Row>
    </>
  );
};

export const handleGreenscreensInfoClick = (openingPopup: (popup: PopupEntity) => void, closingPopup: () => void) =>
  openingPopup({
    body: (
      <BasePopup
        title={t(T.common_marketRate_greenscreens_title)}
        buttons={[
          <PopupPrimaryButton
            testID="green_learn_more"
            key={t(T.common_membership_learnMore)}
            label={t(T.common_membership_learnMore)}
            action={() => window.open(LOADBOARD_HELP_GREENSCREENS, '_blank')}
          />,
          <PopupOkButton testID="green_ok" key={t(T.common_ok)} action={closingPopup} />,
        ]}
      >
        {t(T.common_marketRate_greenscreens_info)}
      </BasePopup>
    ),
    isDismissible: true,
    hasCloseButton: true,
    width: PopupSizes.SMALL,
  });

import React, { CSSProperties } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import { getLoadLocationString } from '@common/helper';
import { LoadLocation, OriginLocation } from '@common/model';
import THEME from '@style/Theme';
import { T, t } from '@translate';
import icons from '@util/iconsConstants';

import { Destination, DestinationContainer, DestinationText, Radius, WrapperTextDestination } from './CardsStyle';

export interface ComponentProps {
  origin: LoadLocation | OriginLocation | string;
  destination: LoadLocation | string;
  radiusFrom?: number;
  radiusTo?: number;
  style?: CSSProperties;
  styleText?: CSSProperties;
  originDescription?: React.ReactNode;
  destinationDescription?: React.ReactNode;
}

export class DestinationWithRadius extends React.Component<ComponentProps, {}> {
  renderDestination = (destination: OriginLocation | LoadLocation | string) => getLoadLocationString(destination);

  render() {
    const { origin, radiusFrom, radiusTo, destination, style, styleText, originDescription, destinationDescription } =
      this.props;
    return (
      <Destination style={{ alignItems: 'flex-start', ...style }}>
        <DestinationContainer>
          <Tooltip title={this.renderDestination(origin)} placement="top">
            <WrapperTextDestination>
              <DestinationText id="location_from" style={styleText}>
                {this.renderDestination(origin)}
              </DestinationText>
            </WrapperTextDestination>
          </Tooltip>
          {!!radiusFrom && (
            <Radius id="from_radius" theme={THEME}>
              {t(T.unit_mi, { value: radiusFrom.toLocaleString() })}
            </Radius>
          )}
          {originDescription}
        </DestinationContainer>
        <img id="right_arrow" src={icons.arrowRightGreen} alt={'arrowRightGreen'} style={{ marginTop: -4 }} />
        <DestinationContainer>
          <Tooltip title={this.renderDestination(destination)} placement="top">
            <WrapperTextDestination>
              <DestinationText id="location_to" style={styleText}>
                {this.renderDestination(destination)}
              </DestinationText>
            </WrapperTextDestination>
          </Tooltip>
          {!!radiusTo && (
            <Radius id="to_radius" theme={THEME}>
              {t(T.unit_mi, { value: radiusTo.toLocaleString() })}
            </Radius>
          )}
          {destinationDescription}
        </DestinationContainer>
      </Destination>
    );
  }
}

import { Action } from 'redux';

import { LoadSearchSort, LoadSortField, postedLoadSortFor } from '@common/model';
import { createReducer } from '@common/redux/ReduxHelper';

const SET_SORT_OPTION = 'SET_SORT_OPTION';
const SET_PICKUP_DATES = 'SET_PICKUP_DATES';
const SET_FREE_TEXT_FILTER = 'SET_FREE_TEXT_FILTER';

interface SetSortOptionAction extends Action {
  sortOption?: LoadSearchSort;
}

interface SetPickupDatesAction extends Action {
  pickupDateTimes: string[];
}

interface SetFreeTextFilterAction extends Action {
  filter: string;
}

export const setSortOption = (sortOption?: LoadSearchSort): SetSortOptionAction => ({
  type: SET_SORT_OPTION,
  sortOption: sortOption,
});

export const setPickupDates = (pickupDateTimes: string[]): SetPickupDatesAction => ({
  type: SET_PICKUP_DATES,
  pickupDateTimes: pickupDateTimes,
});

export const setFreeTextFilter = (filter: string): SetFreeTextFilterAction => ({
  type: SET_FREE_TEXT_FILTER,
  filter: filter,
});

export interface PostedLoadsPanelActionsState {
  selectedSortOption?: LoadSearchSort;
  pickupDateTimes: string[];
  textFilter: string;
}

const initialState: PostedLoadsPanelActionsState = {
  selectedSortOption: postedLoadSortFor(LoadSortField.LastUpdateAge),
  pickupDateTimes: [],
  textFilter: '',
};

export const postedLoadsPanelActionsReducer = createReducer(initialState, {
  [SET_SORT_OPTION]: (state, action: SetSortOptionAction) => {
    state.selectedSortOption = action.sortOption;
  },
  [SET_PICKUP_DATES]: (state, action: SetPickupDatesAction) => {
    state.pickupDateTimes = action.pickupDateTimes;
  },
  [SET_FREE_TEXT_FILTER]: (state, action: SetFreeTextFilterAction) => {
    state.textFilter = action.filter;
  },
});

import React, { useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

interface MeasureProps {
  Measurer: React.ComponentType;
  contentHeight: number;
  isMeasurementOver: boolean;
}

export const useContentHeightMeasurer = (): MeasureProps => {
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [isMeasurementOver, setIsMeasurementOver] = useState(false);

  const onWindowResize = () => {
    setIsMeasurementOver(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  const onResize = (width: number, height: number) => {
    if (!isMeasurementOver) {
      setIsMeasurementOver(true);
      setContentHeight(height);
    }
  };

  const Measurer: React.FC = () =>
    isMeasurementOver ? null : <ReactResizeDetector handleHeight={true} onResize={onResize} />;

  return { contentHeight: contentHeight, isMeasurementOver: isMeasurementOver, Measurer: Measurer };
};

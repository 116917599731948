import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Card, CardContent, Paper } from '@material-ui/core';

import { default as DollarIconSVG } from '@/icons/icon_dollar.svg?react';
import { default as UpArrowIconSVG } from '@/icons/icon_up_arrow.svg?react';
import { NO_INFORMATION_SYMBOL_LONG } from '@common/formatter';
import { Text, TextStyle } from '@component/text';
import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { getCostPerDayTitleData } from '@util/helper/CostPerDayHelper';

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled(Flex)`
  justify-content: center;
  gap: 5px;
`;

export const LinkButton = styled(Link)`
  margin-top: 3px;
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
  font-size: ${FontSize.Content}px;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: ${Color.ORANGE_DARK};
  }
`;

const CardHeader = styled(Flex)`
  padding: 5px 15px 5px 10px;
  justify-content: space-between;
`;

const BadgeContainer = styled(Paper)`
  margin: ${Spacing.ScreenSide}px;
  padding: 5px 15px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px !important;
`;

const CardTitle = styled(Text)`
  margin: auto;
  margin-top: 3px;
`;

const DefaultTitle = styled(Text)`
  color: ${Color.BLACK};
  padding-left: 5px;
`;

export const CostPerDayNotAvailableBadge = ({ onClick }: { onClick?: () => void }) => (
  <BadgeContainer onClick={onClick}>
    <TitleWrapper style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
      <DollarIconSVG fill={Color.GRAY_1} />
      <CardTitle textStyle={TextStyle.SubtitleNormalWeight} id="cost_per_day_reached">
        {t(T.common_costPerDay_card_notAvailable)}
      </CardTitle>
    </TitleWrapper>
  </BadgeContainer>
);

interface CostPerDayButtonProps {
  percentage: number;
  isSetupComplete: boolean;
  onClick?: () => void;
}

export const CostPerDayButton = (props: CostPerDayButtonProps) => {
  const { title, color, iconStyle, percentageText } = getCostPerDayTitleData(props.percentage);

  return (
    <Card
      role="button"
      onClick={props.onClick}
      style={{ margin: Spacing.ScreenSide, borderRadius: '20px', cursor: 'pointer' }}
    >
      <CardContent style={{ padding: 0 }}>
        <CardHeader>
          <CostPerDayTitle
            containerStyle={{ flexGrow: 1, justifyContent: 'flex-start' }}
            isSetupComplete={props.isSetupComplete}
            percentageText={percentageText}
            iconStyle={iconStyle}
            title={title}
            color={color}
          />
        </CardHeader>
      </CardContent>
    </Card>
  );
};

export const DefaultCostPerDayTitle = () => (
  <TitleWrapper style={{ flex: 1 }}>
    <DollarIconSVG aria-label="dollar" fill={Color.BLACK} />
    <CardTitle textStyle={TextStyle.SubtitleNormalWeight} id="cost_per_day_reached">
      {t(T.common_costPerDay_card_title)}:
      <DefaultTitle textStyle={TextStyle.Subtitle2Bold} id="cost_per_day_result">
        {NO_INFORMATION_SYMBOL_LONG}
      </DefaultTitle>
    </CardTitle>
  </TitleWrapper>
);

interface CostPerDayTitleProps {
  isSetupComplete: boolean;
  percentageText: string;
  iconStyle: React.CSSProperties;
  title: string;
  color: Color;
  containerStyle?: React.CSSProperties;
}

export const CostPerDayTitle = (props: CostPerDayTitleProps) => {
  if (!props.isSetupComplete) {
    return <DefaultCostPerDayTitle />;
  }

  return (
    <TitleWrapper style={props.containerStyle}>
      <UpArrowIconSVG style={props.iconStyle} aria-label="dollar" fill={props.color} />
      <div style={{ margin: 'auto', paddingTop: 2 }}>
        <CardTitle
          textStyle={TextStyle.Subtitle2Bold}
          id="cost_per_day_result"
          style={{ color: props.color, marginRight: 5 }}
        >
          {props.percentageText}
        </CardTitle>
        <CardTitle textStyle={TextStyle.SubtitleNormalWeight} id="cost_per_day_reached">
          {props.title}
        </CardTitle>
      </div>
    </TitleWrapper>
  );
};

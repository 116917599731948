import { includes, isPlainObject, pickBy, reduce, values } from 'lodash';

import { isNumber } from '@common/helper/NumberHelper';

export interface ParseableObject {
  [field: string]: unknown;
}

type ObjectParser<T> = (parseableObject: ParseableObject) => T;

const isParseableObject = (value: unknown): value is ParseableObject => isPlainObject(value);

/**
 * Creates a parser that takes a value of type unknown and attempts to convert it to an object using
 * the provided ObjectParser parser. If the value is an object, it's passed into the provided parser.
 * If the value is not an object, undefined is returned right away.
 * @param parser Any parser that attempts to convert a ParseableObject to a type T. Should return
 * undefined if conversion is not possible.
 */
export const createObjectParser =
  <T>(parser: ObjectParser<T>) =>
  (value: unknown) => {
    if (isParseableObject(value)) {
      return parser(value);
    }
    return undefined;
  };

/**
 * Creates a parser that takes a value of type unknown and attempts to parse from it an
 * array of type T with the provided type T parser. If the value is not an array
 * or it is an array but not all values in it are of type T, returns undefined.
 * Otherwise returns the parsed array.
 * @param typeParser Parser for single values of type T.
 */
export const createArrayParser =
  <T>(typeParser: (value: unknown) => T | undefined) =>
  (values: unknown): T[] | undefined => {
    if (Array.isArray(values)) {
      const numberOfEntries = values.length;
      const parsedEntries = reduce(
        values,
        (parsedValues, currentValue) => {
          const parsedValue = typeParser(currentValue);
          if (parsedValue !== undefined) {
            return [...parsedValues, parsedValue];
          } else {
            return parsedValues;
          }
        },
        [] as T[]
      );
      if (parsedEntries.length === numberOfEntries) {
        return parsedEntries;
      }
    }
    return undefined;
  };

export const parseString = (value: unknown) => {
  if (typeof value === 'string') {
    return value;
  }
  return undefined;
};

export const parseStringArray = createArrayParser(parseString);

export const parseNumber = (value: unknown, bounds?: { min?: number; max?: number }) => {
  if (typeof value === 'number' || typeof value === 'string') {
    if (isNumber(value)) {
      let parsedNumber = Number(value);
      if (bounds?.max !== undefined && parsedNumber > bounds.max) {
        parsedNumber = bounds.max;
      }
      if (bounds?.min !== undefined && parsedNumber < bounds.min) {
        parsedNumber = bounds.min;
      }
      return parsedNumber;
    }
  }
  return undefined;
};

export const parseBoolean = (value: unknown) => {
  if (value === 'true' || value === true) {
    return true;
  }
  if (value === 'false' || value === false) {
    return false;
  }
  return undefined;
};

export const parseValueIncludedIn = <T>(value: unknown, collection: T[]): T | undefined => {
  if (includes(collection, value)) {
    return value as T;
  }
  return undefined;
};

/**
 * Given a value of unknown type and an enum, verifies if value exists in enum, and, if so,
 * returns value as appropriate type or, if not, returns undefined.
 * @param value value to parse
 * @param collection enum
 */
export const parseValueFrom = <T>(value: unknown, collection: { [field: string]: T }): T | undefined =>
  reduce(
    values(collection),
    (chosenValue, collectionValue) => {
      if (collectionValue === value) {
        return value as T;
      }
      return chosenValue;
    },
    undefined // if there's no match, return undefined.
  );

/**
 * Given a value of unknown type and an enum, verifies if value is an array. If so,
 * returns an array of all elements within value that exist in the provided enum.
 * If not, returns undefined.
 * @param values value to parse
 * @param collection enum
 */
export const parseArrayOfValuesFrom = <T>(
  values: unknown,
  collection: { [field: string]: T }
): Array<T> | undefined => {
  if (Array.isArray(values)) {
    return reduce(
      values,
      (chosenValues, value) => {
        const parsedValue = parseValueFrom(value, collection);
        if (parsedValue !== undefined) {
          return [...chosenValues, parsedValue];
        }
        return chosenValues;
      },
      []
    );
  }
  return undefined;
};

export const pickDefinedProperties = <T extends object>(obj: T) => pickBy(obj, (param) => param !== undefined);

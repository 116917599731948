import React from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';
import { ElementSize, FontSize, px, Spacing } from '@style/StyleConstants';

import WarningIcon from './warning.svg';

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: ${px(ElementSize.HeaderHeight)};
  align-items: center;
  background-color: ${Color.ORANGE_WARNING};
  font-size: ${px(FontSize.Content)};
  padding: 0 20px 0 20px;
  box-sizing: border-box;
`;

const Message = styled.span`
  color: ${Color.GRAY_6};
  margin-left: ${px(Spacing.HorizontalBigDistance)};
`;

interface Props {
  message: string;
  className?: string;
}

export const Warning = (props: Props) => (
  <Container className={props.className}>
    <img src={WarningIcon} />
    <Message>{props.message}</Message>
  </Container>
);

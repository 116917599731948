import { defaultTo, includes, map } from 'lodash';

import { getEnumKey } from './Functions';

export enum MimeType {
  png = 'image/png',
  jpg = 'image/jpeg',
  jpeg = 'image/jpeg',
  jpg2 = 'image/jpg',
  pdf = 'application/pdf',
  bmp = 'image/bmp',
  gif = 'image/gif',
  svg = 'image/svg+xml',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  octet = 'application/octet-stream',
  json = 'application/json',
  formData = 'multipart/form-data',
  appleKeyNote = 'application/x-iwork-keynote-sffkey',
  applePages = 'application/x-iwork-pages-sffpages',
  appleNumbers = 'application/x-iwork-numbers-sffnumbers',
  csv = 'text/csv',
}

export const getMimeType = (extension: string): string =>
  defaultTo(MimeType[extension as keyof typeof MimeType], MimeType.octet);

const typesSupportedByUpload: Readonly<MimeType[]> = [
  MimeType.png,
  MimeType.jpeg,
  MimeType.pdf,
  MimeType.doc,
  MimeType.docx,
  MimeType.xls,
  MimeType.xlsx,
];

export const typesSupportedByDownload: Readonly<MimeType[]> = [
  ...typesSupportedByUpload,
  MimeType.bmp,
  MimeType.gif,
  MimeType.octet,
  MimeType.csv,
  MimeType.jpg2,
];

export const isFileSupportedByUpload = (mime: string) => includes(typesSupportedByUpload, mime);

export const extensionsSupportedByUpload = map(typesSupportedByUpload, getEnumKey(MimeType));

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { fetchCompaniesAvatar } from '@common/redux/epic/CompanyAvatarEpic';
import { default as AvatarIcon } from '@component/menu/icon/icon-add-avatar.svg';
import { useSelector } from '@util/hooks';

const ImageViewer = styled.img`
  height: 36px;
  width: 36px;
  border-radius: 6px;

  margin-right: 6px;
`;

const BidAvatarImageViewer = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 6px;
  border-radius: 20px;
  border: solid 1px #2e2e2e;
  padding: 4px 4px 4px 4px;
  align-self: baseline;
`;

interface Props {
  companyId?: string;
  shouldUsePlaceholderIcon?: boolean;
}

export const CompanyLogo: React.FC<Props> = (props) => {
  const companyAvatar = useSelector((state) => state.companyAvatar.companiesAvatarMap.get(props.companyId ?? ''));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companyAvatar && props.companyId) {
      dispatch(fetchCompaniesAvatar(props.companyId));
    }
  }, [companyAvatar, props.companyId]);

  if (companyAvatar?.companiesAvatar) {
    return <ImageViewer src={companyAvatar.companiesAvatar} />;
  } else if (props.shouldUsePlaceholderIcon) {
    return <BidAvatarImageViewer src={AvatarIcon} />;
  } else {
    return null;
  }
};

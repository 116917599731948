import React, { useEffect, useState } from 'react';

import { isEqual } from 'lodash';

import { Radio } from '@component/control';
import { StyledFormControl } from '@component/control/ControlStyles';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { Header } from '@component/panels/pickerPanels/PickUpDropOffStyles';
import { PanelSingleSection } from '@component/section';
import THEME from '@style/Theme';
import { t, T } from '@translate';

export enum ConversationListViewType {
  All = 'All',
  Unread = 'Unread',
}

const ViewsFilterBy = [
  { id: 0, title: t(T.common_conversations_filter_all), field: ConversationListViewType.All },
  { id: 1, title: t(T.common_conversations_filter_unread), field: ConversationListViewType.Unread },
];

interface ComponentProps {
  layer: number;
  offset: number;
  initialView: ConversationListViewType;
  onClose: (filter: ConversationListViewType) => void;
}

type Props = ComponentProps;

export const ConversationListFilter: React.FC<Props> = (props) => {
  const [value, setValue] = useState(ConversationListViewType.All);

  useEffect(() => {
    setValue(props.initialView);
  }, []);

  return (
    <Panel
      id="conversation_listview_filter"
      layer={props.layer}
      offset={props.offset}
      size={PanelSize.small}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        label={t(T.common_sortByKeys_views)}
        hasBackButton={true}
        onClose={() => {
          props.onClose(value);
        }}
      />
      <PanelSingleSection>
        <Header>{t(T.common_sortByKeys_views)}</Header>
        <StyledFormControl theme={THEME}>
          {ViewsFilterBy.map((option) => (
            <Radio
              id={option.field}
              name={option.field + option.id}
              value={option.title}
              label={option.title}
              key={option.id}
              isChecked={isEqual(option.field, value)}
              onClick={() => {
                setValue(option.field);
                props.onClose(option.field);
              }}
            />
          ))}
        </StyledFormControl>
      </PanelSingleSection>
    </Panel>
  );
};

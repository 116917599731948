import { createAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface UserActivityState {
  isUserInactive: boolean;
  isUserIdle: boolean;
  isPageHidden: boolean;
}

const initialUserActivityState: UserActivityState = {
  isUserInactive: false,
  isUserIdle: false,
  isPageHidden: false,
};

const setUserIdleAction = createAction<boolean>('SET_USER_IDLE');
const setPageHiddenAction = createAction<boolean>('SET_IS_PAGE_HIDDEN');

export const setUserIdle = (isUserIdle: boolean) => setUserIdleAction.action(isUserIdle);
export const setPageHidden = (isPageHidden: boolean) => setPageHiddenAction.action(isPageHidden);

export const userActivityReducer = createMergedReducer(initialUserActivityState, [
  setUserIdleAction.addCase((state, action) => {
    state.isUserIdle = action.data;
    state.isUserInactive = action.data || state.isPageHidden;
  }),
  setPageHiddenAction.addCase((state, action) => {
    state.isPageHidden = action.data;
    state.isUserInactive = action.data || state.isUserIdle;
  }),
]);

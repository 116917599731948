import React from 'react';

import { formatNumberWithZeroFormatting } from '@common/helper';
import { BoldText } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { Color } from '@style/Color';
import { t, T } from '@translate';

interface CostProps {
  value: number;
}

export const Cost: React.FC<CostProps> = (props) => {
  const isNegative = props.value < 0;
  return (
    <BoldText id="costs" style={{ color: isNegative ? Color.GREEN_MAIN : Color.RED_3, marginRight: '25px' }}>
      {t(T.unit_money, {
        value: formatNumberWithZeroFormatting(Math.abs(props.value), true),
        currency: '$',
        sign: `${isNegative ? '+' : '-'}`,
      })}
    </BoldText>
  );
};

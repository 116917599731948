import { compact, concat, forEach, isEmpty, map, reduce, replace, split, without } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { filter as filter$, map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import {
  AddPrivateNetworkMembers,
  DeletePrivateNetworkMembers,
  FetchLoadBoardResponse,
  FetchPrivateNetworkMembers,
  getLoadBoardType,
  InvalidMembersResponse,
  LoadBoardType,
  PrivateMemberSortDirection,
  WhiteListResponse,
} from '@/model';
import { Api, ApiError } from '@common/api';
import { ApiAction, createAction, createApiAction, createApiActionWithFetchData } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';
import { PrivateNetworkClient } from '@webApi/PrivateNetworkClient';

export interface LoadBoard {
  type: LoadBoardType;
  whiteList: NormalizedList;
  isLoading: boolean;
  error: ApiError | undefined;
  isDeletingItems: boolean;
  recentlyAddedNumbers: { invalid: string[]; valid: string[] };
  isAddingItems: boolean | undefined;
}

interface Checkable {
  isChecked: boolean;
}

export interface CheckableListItems {
  [id: string]: Checkable;
}

interface NormalizedList {
  ui: string[];
  entities: CheckableListItems;
}

export interface PrivateNetworkState {
  isLoadingLoadBoards: boolean;
  loadBoards: {
    [id: string]: LoadBoard | undefined;
  };
  defaultLoadBoardId: string | undefined;
  loadBoardError: ApiError | undefined;
}

interface UpdateCheckedStatus {
  id: string;
  usDotNumber: CheckableListItems;
}

export const getDefaultLoadBoard = createApiAction<undefined, FetchLoadBoardResponse>('FETCH_USER_LOAD_BOARDS');
export const addWhiteListItems = createApiActionWithFetchData<AddPrivateNetworkMembers, InvalidMembersResponse>(
  'ADD_WHITELIST_ITEMS'
);
export const getWhiteList = createApiActionWithFetchData<FetchPrivateNetworkMembers, WhiteListResponse>(
  'FETCH_WHITE_LIST'
);
export const deleteWhiteListItems = createApiActionWithFetchData<DeletePrivateNetworkMembers, InvalidMembersResponse>(
  'DELETE_WHITELIST_ITEMS'
);
export const changeCheckedStatus = createAction<UpdateCheckedStatus>('UPDATE_CHECKED_STATUS');
export const clearRecentlyAddedNumbers = createAction<string>('CLEAR_INVALID_NUMBERS');
export const clearLoadBoardError = createAction<string>('CLEAR_LOAD_BOARD_ERROR');
export const retryAddWhitelistItems = createAction<string>('RETRY_ADD_WHITE_LIST_ITEMS');
export const resetInvalidDotNumbers = createAction<string>('RESET_INVALID_DOT_NUMBERS');

export const initialState: PrivateNetworkState = {
  isLoadingLoadBoards: false,
  loadBoards: {},
  loadBoardError: undefined,
  defaultLoadBoardId: undefined,
};

export const privateNetworkReducer = createMergedReducer(initialState, [
  getDefaultLoadBoard.initiateCase((state) => {
    state.loadBoardError = undefined;
    state.isLoadingLoadBoards = true;
  }),

  getDefaultLoadBoard.completeCase((state, action) => {
    state.isLoadingLoadBoards = false;
    if (action.response.success) {
      state.loadBoards[action.response.payload.id] = {
        type: getLoadBoardType(action.response.payload.type),
        isLoading: false,
        whiteList: {
          ui: [],
          entities: {},
        },
        error: undefined,
        isDeletingItems: false,
        recentlyAddedNumbers: { valid: [], invalid: [] },
        isAddingItems: undefined,
      };
      state.defaultLoadBoardId = action.response.payload.id;
    } else {
      state.loadBoardError = action.response.error;
    }
  }),

  getWhiteList.initiateCase((state, action) => {
    const loadBoard = action.data?.id && state.loadBoards[action.data.id];
    if (loadBoard) {
      loadBoard.isLoading = true;
    }
  }),

  getWhiteList.completeCase((state, action) => {
    const loadBoard = action.fetchData?.id && state.loadBoards[action.fetchData.id];
    if (!loadBoard) {
      return;
    }
    loadBoard.isLoading = false;
    if (action.response.success) {
      loadBoard.whiteList = normalizeWhitelist(action.response.payload.dotNumbers, loadBoard.whiteList.entities);
    } else {
      loadBoard.error = action.response.error;
    }
  }),

  changeCheckedStatus.addCase((state, action) => {
    const loadBoard = state.loadBoards[action.data.id];

    if (loadBoard) {
      forEach(action.data.usDotNumber, (checkStatus, usDotNumber) => {
        loadBoard.whiteList.entities[usDotNumber] = checkStatus;
      });
    }
  }),

  deleteWhiteListItems.initiateCase((state, action) => {
    const loadBoard = action.data?.id && state.loadBoards[action.data.id];
    if (!loadBoard) {
      return;
    }

    loadBoard.isDeletingItems = true;
  }),

  deleteWhiteListItems.completeCase((state, action) => {
    const loadBoard = action.fetchData?.id && state.loadBoards[action.fetchData.id];

    if (!loadBoard) {
      return;
    }
    loadBoard.isDeletingItems = false;
    if (action.response.success) {
      loadBoard.whiteList.ui = without(loadBoard.whiteList.ui, ...(action.fetchData?.usDotNumber || []));
      forEach(action.fetchData?.usDotNumber, (id) => delete loadBoard.whiteList.entities[id]);
    } else {
      loadBoard.error = action.response.error;
    }
  }),

  addWhiteListItems.initiateCase((state, action) => {
    const loadBoard = action.data?.id && state.loadBoards[action.data.id];
    if (!loadBoard) {
      return;
    }
    if (isEmpty(action.data.validDotNumbers)) {
      loadBoard.isAddingItems = false;
    } else {
      loadBoard.isAddingItems = true;
    }
    loadBoard.recentlyAddedNumbers.valid = action.data.validDotNumbers;
    loadBoard.recentlyAddedNumbers.invalid = action.data.invalidDotNumbers;
  }),

  addWhiteListItems.completeCase((state, action) => {
    const loadBoard = action.fetchData?.id && state.loadBoards[action.fetchData.id];
    if (!loadBoard) {
      return;
    }
    loadBoard.isAddingItems = false;
    loadBoard.whiteList = { entities: {}, ui: [] };
    if (action.response.success) {
      loadBoard.recentlyAddedNumbers.invalid = concat(
        loadBoard.recentlyAddedNumbers.invalid,
        action.response.payload.invalidDotNumbers
      );
    } else {
      loadBoard.recentlyAddedNumbers.invalid = compact(
        concat(loadBoard.recentlyAddedNumbers.valid, loadBoard.recentlyAddedNumbers.invalid)
      );
      loadBoard.error = action.response.error;
    }
  }),

  clearLoadBoardError.addCase((state, action) => {
    const loadBoard = state.loadBoards[action.data];
    if (loadBoard) {
      loadBoard.error = undefined;
    }
  }),

  retryAddWhitelistItems.addCase((state, action) => {
    const loadBoard = state.loadBoards[action.data];
    if (loadBoard) {
      loadBoard.isAddingItems = undefined;
      loadBoard.error = undefined;
    }
  }),

  clearRecentlyAddedNumbers.addCase((state, action) => {
    const loadBoard = state.loadBoards[action.data];
    if (loadBoard) {
      loadBoard.isAddingItems = undefined;
      loadBoard.error = undefined;
      loadBoard.recentlyAddedNumbers = { valid: [], invalid: [] };
    }
  }),
]);

const normalizeWhitelist = (whiteList: string[], existingWhiteList: CheckableListItems): NormalizedList => ({
  ui: whiteList,
  entities: reduce(
    whiteList,
    (accumulator, dotNumber) => {
      accumulator[dotNumber] = { isChecked: existingWhiteList[dotNumber]?.isChecked || false };
      return accumulator;
    },
    {} as CheckableListItems
  ),
});

const deleteWhiteListItems$ = (action$: ActionsObservable<Action>, client: PrivateNetworkClient) =>
  action$
    .ofType(deleteWhiteListItems.fetchType)
    .pipe(
      mergeMap$((action: ApiAction<DeletePrivateNetworkMembers>) =>
        client
          .deleteWhiteListItems$(action.data)
          .pipe(
            mergeMap$((response) =>
              of$(
                deleteWhiteListItems.responseAction(response, action),
                getWhiteList.fetchAction({ id: action.data.id, sortOrder: PrivateMemberSortDirection.ascending })
              )
            )
          )
      )
    );

const addWhiteListItems$ = (action$: ActionsObservable<Action>, client: PrivateNetworkClient) =>
  action$.ofType(addWhiteListItems.fetchType).pipe(
    filter$((action: ApiAction<AddPrivateNetworkMembers>) => !isEmpty(action.data.validDotNumbers)),
    mergeMap$((action) =>
      client
        .addWhiteListItems$(action.data)
        .pipe(map$((response) => addWhiteListItems.responseAction(response, action)))
    )
  );

export const createPrivateNetworkEpic = (api: Api) => {
  const client = new PrivateNetworkClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      getDefaultLoadBoard.createEpic$(action$, client.getDefaultLoadBoard$),
      getWhiteList.createEpic$(action$, client.getWhiteList$),
      addWhiteListItems$(action$, client),
      deleteWhiteListItems$(action$, client)
    );
};

export const splitValue = (value: string): string[] => {
  const splitValues = split(value, /\n|,/g);
  const removedWhiteSpace = map(splitValues, (str) => replace(str, /\s/g, ''));
  const removedEmpty = compact(removedWhiteSpace);
  return removedEmpty;
};

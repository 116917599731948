import { LBHeaders } from '@common/api/ApiOptions';
import { BaseClient } from '@common/client/BaseClient';
import {
  CanMessageResponse,
  CommunicationStatus,
  Conversation,
  ConversationMessageResponse,
  ConversationResponse,
  ConversationsListRequest,
  ConversationsSummary,
  ConversationUserStatuses,
  LoadFieldsConversationOnly,
  LoadWithDataForConversation,
  MessagePayload,
  MessagesSummary,
} from '@common/model/Conversation';

export interface SendMessageResponse {
  messageId: string;
}
export class CommunicationClient extends BaseClient {
  negotiate$ = () => this.api.post$<{ accessToken: string; url: string }>('/conversations/negotiate', {});
  getConversationsSummary$ = () => this.api.get$<ConversationsSummary>('/conversations/summary', {});
  getMessagesSummary$ = () =>
    this.api.get$<MessagesSummary>('/conversations/summary', {
      computeUnreadMessages: true,
      computeUnreadConversations: false,
    });
  getConversations$ = (data: ConversationsListRequest) =>
    this.api.get$<ConversationResponse>('/conversations', {
      unread: data.unread,
      token: data.token,
      limit: data.limit,
    });
  getConversation$ = (conversationID: string) => this.api.get$<Conversation>(`/conversations/${conversationID}`, {});
  getLoadConversations$ = (loadID: string, appOrigin: string, inquireBroker: boolean) =>
    this.api.get$<ConversationResponse>(
      `/loads/${loadID}/conversations`,
      {
        inquireBroker: inquireBroker,
      },
      {
        headers: {
          [LBHeaders.Referrer]: appOrigin,
          [LBHeaders.FlowID]: '',
        },
      }
    );
  getCommunicationStatus$ = () => this.api.get$<CommunicationStatus>('conversations/status', {});
  getMessages$ = (conversationID: string) =>
    this.api.get$<ConversationMessageResponse>(`/conversations/${conversationID}/messages`, { recipients_info: true });
  sendMessage$ = (conversationID: string, message: MessagePayload) =>
    this.api.post$<SendMessageResponse>(`/conversations/${conversationID}/messages`, message);
  getUserStatus$ = (conversationID: string) =>
    this.api.get$<ConversationUserStatuses>(`/conversations/${conversationID}/user-statuses`, {});
  markMessageRead$ = (conversationID: string, messageID: string, isRead = true) =>
    this.api.mergePatch$<{}>(`/conversations/${conversationID}/messages/${messageID}/status`, { read: isRead });
  markAllMessagesAsRead$ = () => this.api.post$<{}>('/conversations/mark-as-read', {});
  getCanMessage$ = (userIDs: string[]) =>
    this.api.post$<CanMessageResponse>('conversations/can-message', { users: userIDs });

  getConversationLoadDetails$ = (loadId: string) =>
    this.api.get$<LoadWithDataForConversation>(`/loads/${loadId}`, { fields: LoadFieldsConversationOnly });
}

import React, { CSSProperties } from 'react';

import { first, last, split } from 'lodash';
import styled from 'styled-components';

import { Link } from '@material-ui/core';

import { NO_INFORMATION_SYMBOL_LONG } from '@common/formatter';
import { formatPhoneNumberWithExtension, getInitials } from '@common/helper';
import { Phone } from '@common/model';
import { ContactInfo } from '@common/redux/epic/CompanyEpic';
import { LinkButton, PhoneNumber } from '@component/contact/PhoneNumber';
import { AvatarTextWrapper, InitialsBubble } from '@component/conversation/ConversationStyles';
import { Subtitle } from '@component/expansionPanel/ExpansionPanelStyle';
import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';

export const ShowPhoneButton = styled(LinkButton)`
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
  font-weight: bold;
  font-size: 14px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${Spacing.ScreenSide}px;
  padding-top: ${Spacing.InterSection}px;
`;

export const ContactInfoText = styled(Subtitle)`
  font-size: ${FontSize.ContentDetail}px;
`;

export const ContactInfoNameText = styled.span`
  && {
    font-size: ${FontSize.ModalTitle}px;
    display: flex;
    align-items: center;
    margin-top: ${Spacing.InterElementVertical}px;
  }
`;

export const ContactInfoLink: React.FC<{ link?: string }> = (props) => (
  <Link href={props.link} color={'secondary'} noWrap={true} target={'_blank'} style={{ fontWeight: 'bold' }}>
    {props.children}
  </Link>
);

const LoadContactInfoSection = styled(Subtitle)`
  && {
    width: 110px;
    color: ${Color.GRAY_DARK};
  }
`;

const ContactInfoSection = styled(Subtitle)`
  && {
    color: ${Color.GRAY_11};
    margin-bottom: 8px;
  }
`;

const TextRow = styled.div`
  margin-bottom: ${Spacing.LargePaddingVertical}px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`;

const TextColumn = styled.div`
  margin-bottom: ${Spacing.LargePaddingVertical}px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

const SubtitleContainer = styled.span`
  && {
    font-size: ${FontSize.ContentDetail}px;
    color: ${Color.GRAY_STONE};
  }
`;

export const SectionTitle: React.FC<{ style?: CSSProperties }> = (props) => (
  <SubtitleContainer style={props.style}>{props.children}</SubtitleContainer>
);

const AvatarNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-bottom: ${Spacing.LargePaddingVertical}px;
`;

const ImageViewer = styled.img`
  max-width: 40px;
  max-height: 40px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
`;

const UserAvatarViewer = styled(ImageViewer)`
  border-radius: 20px;
  background-color: white;
`;

const CompanyAvatarViewer = styled(ImageViewer)`
  border-radius: 6px;
`;
const InitialsViewer = styled(InitialsBubble)`
  max-width: 40px;
  max-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 10px;
`;

export const ImagePlaceholder = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: 6px;
  background-color: ${Color.GRAY_LAVA};
  margin-right: 10px;
`;

const AvatarAndNameSection: React.FC<{ avatar: JSX.Element; name: string }> = (props) => {
  return (
    <AvatarNameContainer>
      {props.avatar}
      <ContactInfoNameText>{props.name}</ContactInfoNameText>
    </AvatarNameContainer>
  );
};

export const ContactAvatarAndName: React.FC<{ contactName: string; contactInitials: string; image?: string | null }> = (
  props
) => {
  return (
    <AvatarAndNameSection
      avatar={
        props.image ? (
          <UserAvatarViewer src={props.image} />
        ) : (
          <InitialsViewer>
            <AvatarTextWrapper>{props.contactInitials}</AvatarTextWrapper>
          </InitialsViewer>
        )
      }
      name={props.contactName}
    />
  );
};

export const CompanyAvatarAndName: React.FC<{ companyName: string; image?: string | null }> = ({
  companyName,
  image,
}) => (
  <AvatarAndNameSection
    avatar={image ? <CompanyAvatarViewer src={image} /> : <ImagePlaceholder />}
    name={companyName}
  />
);

export const LoadContactInfoRow: React.FC<{ label: string; value?: string | JSX.Element }> = (props) => {
  const displayedValue = props.value ?? NO_INFORMATION_SYMBOL_LONG;
  return (
    <TextRow>
      <LoadContactInfoSection>{props.label}</LoadContactInfoSection>
      <ContactInfoText>{displayedValue}</ContactInfoText>
    </TextRow>
  );
};
export const ContactInfoRow: React.FC<{ label: string; value?: string | JSX.Element }> = (props) => {
  const displayedValue = props.value ?? NO_INFORMATION_SYMBOL_LONG;
  return (
    <TextRow>
      <ContactInfoSection>{props.label}</ContactInfoSection>
      <ContactInfoText>{displayedValue}</ContactInfoText>
    </TextRow>
  );
};

export const ContactInfoColumn: React.FC<{ label: string; value?: string | JSX.Element }> = (props) => {
  const displayedValue = props.value ?? NO_INFORMATION_SYMBOL_LONG;
  return (
    <TextColumn>
      <ContactInfoSection>{props.label}</ContactInfoSection>
      <ContactInfoText>{displayedValue}</ContactInfoText>
    </TextColumn>
  );
};

export const ContactInfoTwoColumns: React.FC<{ left: string; right: JSX.Element }> = (props) => (
  <div style={{ flexDirection: 'row', display: 'flex' }}>
    <div>{props.left}</div>
    <div style={{ marginLeft: Spacing.HorizontalBigDistance }}>{props.right}</div>
  </div>
);

export const getLoadPosterName = (firstName?: string, lastName?: string) => {
  let name = '';
  if (firstName) {
    name = firstName;
    if (lastName) {
      name = `${name} ${lastName}`;
    }
  }
  return name;
};

export const getContactInitials = (isBroker: boolean, contactInfo?: ContactInfo) => {
  let firstName = contactInfo?.broker?.firstName ?? '';
  let lastName = contactInfo?.broker?.lastName ?? '';
  if (!isBroker) {
    const nameParts = split(contactInfo?.carrier?.name ?? '', ' ');
    if (nameParts.length > 1) {
      firstName = first(nameParts) ?? '';
      lastName = last(nameParts) ?? '';
    } else {
      firstName = first(nameParts) ?? '';
    }
  }
  return getInitials({ firstName: firstName, lastName: lastName });
};

export const getCompanyCityState = (city?: string, state?: string) => {
  let cityState = NO_INFORMATION_SYMBOL_LONG;
  if (state) {
    cityState = state;
    if (city) {
      cityState = `${city}, ${state}`;
    }
  }
  return cityState;
};

export const phoneNumberOrEmpty = (phone?: Phone, onPhoneClicked?: () => void) =>
  !phone ? (
    NO_INFORMATION_SYMBOL_LONG
  ) : (
    <PhoneNumber
      id="contact_phone_number"
      phoneNumberString={formatPhoneNumberWithExtension(phone.number, phone.extension)}
      phoneNumberValue={phone.number}
      onClick={onPhoneClicked}
    />
  );

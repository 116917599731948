import React from 'react';

import { isLoadEmailed } from '@common/helper';
import { ContactButtonProps, ContactsButton } from '@component/buttons/ContactsButton';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { T, t } from '@translate';

import { showEmailPopup } from '../LoadDetailsPopups';
import { default as EmailIcon } from './icon-email-load-off.svg';
import { default as CheckedEmailIcon } from './icon-email-load-on.svg';

export const EmailButton: React.FC<ContactButtonProps> = (props) => {
  const popupContext = usePopup();
  const openEmailPopup = () => showEmailPopup(popupContext, props.load);
  const isEmailed = isLoadEmailed(props.load);
  const emailIcon = isEmailed ? CheckedEmailIcon : EmailIcon;
  const buttonTitle =
    !props.isFullSize || props.isLabelHidden ? undefined : isEmailed ? t(T.common_emailSent) : t(T.common_email);
  return (
    <ContactsButton
      id="email"
      icon={{ regular: emailIcon, disabled: emailIcon }}
      isFullSize={props.isFullSize}
      title={buttonTitle}
      onClick={openEmailPopup}
    />
  );
};

import { range } from 'lodash';

import {
  BaseLocation,
  BaseLocationFromServer,
  EquipmentType,
  Geolocation,
  Location,
  MatchResult,
  VerificationPointsTaskStatus,
} from '@common/model';
import { SearchResponsePayload } from '@common/redux/Base';

import { LoadSize } from './Load';

export const INITIAL_TRUCK_AVAILABILITY_LIMIT = 20;
export const TRUCK_AVAILABILITY_LIMIT_APPENDING = 10;

export enum FMCSAAuthorityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  None = 'None', // No Authority
}

export interface Truck {
  id: string;
  contactId: string;
  lkl: Geolocation;
  equipmentTypes: string;
  truckAvailabilityTypeId: number;
  isAvailable: boolean;
  companyName: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone?: string;
  contactPhoneVerificationStatus?: VerificationPointsTaskStatus;
  enableLocationTracking: boolean;
  matchResult: MatchResult;
  companyGuid?: string;
  contactPhoneExt?: string;
  lklDateTime?: string;
  availableDates?: string[];
  availableWeight?: number;
  availableLength?: number;
  docketNumber?: number;
  dotNumber?: string;
  commonAuthorityStatus?: FMCSAAuthorityStatus;
  contractAuthorityStatus?: FMCSAAuthorityStatus;
  brokerAuthorityStatus?: FMCSAAuthorityStatus;
  bipdOnFile?: number;
  loadSize?: string;
  amount?: number;
  deadhead?: number;
  notificationInfo?: {
    messagesReceived: number;
    messagesRead: number;
    messagesContent?: string;
    messagesSentOn?: string;
    messagesReadTime?: string;
    /** only for internal truck locator (LB-1899) */
    recentMessagesCountFromCompany?: number;
  };
  isFavoriteCarrier: boolean;
  onboarded: boolean;
  destinations: Location[];
  anonymous?: boolean;
  authorityIssuance?: string;
  isCarrierIdentityVerified?: boolean;
  notes?: string;
  teamDriven?: boolean;
  age?: number;
  mcs150FormDate?: string;
  truckCount?: number;
  totalFmcsaInspections?: number;
  rating?: CarrierRating;
}

interface CarrierRating {
  reviewCount: number;
  starRating: number;
}

export interface UserTruckFromServer {
  id?: string;
  trackingDeviceId: string;
  trackingNumber: string;
  displayName: string;
  equipmentType: EquipmentType;
  defaultWeight?: number;
  defaultLength?: number;
  weightIsSet: boolean;
  lengthIsSet: boolean;
  status: InternalTruckStatus;
}

export interface UserTruck extends UserTruckFromServer {
  defaultWeight: number;
  defaultLength: number;
}

export type UpdateUserTruck = Omit<UserTruckFromServer, 'defaultLength' | 'defaultWeight'> & {
  defaultLength?: number | null;
  defaultWeight?: number | null;
};

export enum InternalTruckStatus {
  Online = 'Online',
  Offline = 'Offline',
}

export enum TruckAvailabilityType {
  Static = 'Static',
  Dynamic = 'Dynamic',
}

export enum TruckAvailabilityStatus {
  Inactive = 'Inactive',
  Active = 'Active',
  Deleted = 'Deleted',
  Expired = 'Expired',
  UIR = 'UIR',
}

interface BaseTruckAvailability {
  truckId: string;
  type: TruckAvailabilityType;
  notificationContactId?: string;
  dates: string[];
  size: LoadSize;
  minRate?: number | null;
  rateUnit?: string;
  weight?: number | null;
  length?: number | null;
  commodityInfo?: string;
  notes?: string;
  isVisible?: boolean;
  status?: TruckAvailabilityStatus;
  trackingDeviceId?: string;
  anonymous?: boolean;
  teamDriven?: boolean;
}

export interface TruckAvailabilityResponse extends BaseTruckAvailability {
  originLocation?: BaseLocationFromServer;
  destinationLocations: BaseLocationFromServer[];
  equipmentType?: EquipmentType;
  id?: number;
}

export interface TruckAvailability extends BaseTruckAvailability {
  originLocation?: BaseLocation;
  destinationLocations: BaseLocation[];
  equipmentType: EquipmentType;
  id: number;
  weight: number;
  length: number;
}

/**
 * Used when fetching minimal information about
 * user's posted trucks, to display a count
 * on the More page.
 */
export type TruckAvailabilityToCount = Pick<TruckAvailabilityResponse, 'status' | 'dates' | 'type'>;

export interface PostedTrucksCountResponse {
  trucks: Array<{
    id?: string;
    availabilities: TruckAvailabilityToCount[];
  }>;
}

export interface TruckWithAvailabilityResponse extends SearchResponsePayload {
  trucks: UserTruckWithAvailabilities[];
}

export interface UserTruckWithAvailabilities extends UserTruck {
  availabilities: TruckAvailabilityResponse[];
}

export interface TruckAvailabilityCounts {
  realTimeCount: number;
  scheduledCount: number;
  expiredCount: number;
  total: number;
}

export interface TruckAvailabilitiesPayload {
  items: TruckAvailability[];
  paginationToken: string;
  hasMore: boolean;
}

export interface AvailabilityTypes {
  types?: Types;
  statuses?: Statuses;
}

export enum Types {
  Static = 'Static',
  Dynamic = 'Dynamic',
}

export enum Statuses {
  Expired = 'Expired',
  Active = 'Active',
}

export enum PostsFilter {
  RealTimePosts = 'real-time',
  ScheduledPosts = 'scheduled',
  ExpiredPosts = 'expired',
}

export enum DeleteTruckError {
  TruckInUse = 409,
}

export enum UpdateTruckError {
  DuplicateName = 4090036,
}

export enum SaveTruckError {
  DuplicateName = 4090036,
}

export enum PostTruckAvailabilityError {
  TruckInUse = 409,
}

export interface PostTruckAddress {
  address: {
    streetAddress?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
}

enum TruckFilterTypes {
  Dynamic = 'Dynamic',
  Static = 'Static',
}

enum TruckFilterStatuses {
  Active = 'Active',
  Inactive = 'Inactive',
  Expired = 'Expired',
}

interface TruckFilterSearchQuery {
  types: TruckFilterTypes[];
  statuses: TruckFilterStatuses[];
}

export interface TruckAvailabilityDeleteRequest {
  searchQuery: Partial<TruckFilterSearchQuery>;
  excludeIds: string[];
}

export const createDeleteAllExpiredTrucksRequest = (): TruckAvailabilityDeleteRequest => ({
  excludeIds: [],
  searchQuery: {
    types: [TruckFilterTypes.Static],
    statuses: [TruckFilterStatuses.Expired],
  },
});

export const MIN_TRUCK_LENGTH = 1;
export const MIN_LOAD_SEARCH_TRUCK_LENGTH = 0;
export const MAX_TRUCK_LENGTH = 53;
export const MAX_TRUCK_NAME_LENGTH = 30;
/** Special value to mean no upper limit */
export const MAX_PLUS_TRUCK_LENGTH = MAX_TRUCK_LENGTH + 1;
export const lengthDomain = [MIN_TRUCK_LENGTH, MAX_PLUS_TRUCK_LENGTH];

export const MIN_TRUCK_WEIGHT = 1;
export const MIN_LOAD_SEARCH_TRUCK_WEIGHT = 0;
export const MAX_TRUCK_WEIGHT = 48000;
/** Special value to mean no upper limit */
export const MAX_LOAD_SEARCH_TRUCK_WEIGHT = MAX_TRUCK_WEIGHT + 1;
export const MAX_PLUS_TRUCK_WEIGHT = MAX_TRUCK_WEIGHT + 1000;
export const weightDomain = [1000, MAX_PLUS_TRUCK_WEIGHT];
export const weightDomain500 = [500, MAX_PLUS_TRUCK_WEIGHT];
export const weightDomainMinus500 = [MIN_TRUCK_WEIGHT, MAX_PLUS_TRUCK_WEIGHT];
export const LENGTH_SLIDER_OPTIONS = [...range(MIN_TRUCK_LENGTH, MAX_PLUS_TRUCK_LENGTH, 1), MAX_PLUS_TRUCK_LENGTH];

//These sliders options are used to create a range of (1, 500, 1000, 2000 etc)
export const WEIGHT_SLIDER_OPTIONS = [...range(1000, MAX_PLUS_TRUCK_WEIGHT, 1000), MAX_PLUS_TRUCK_WEIGHT];
export const WEIGHT_SLIDER_OPTIONS_500 = [...range(500, MAX_PLUS_TRUCK_WEIGHT / 2, 500), MAX_PLUS_TRUCK_WEIGHT];
export const WEIGHT_SLIDER_OPTIONS_MINUS_500 = [
  ...range(MIN_TRUCK_WEIGHT, MAX_PLUS_TRUCK_WEIGHT / 2, 499),
  MAX_PLUS_TRUCK_WEIGHT,
];

export const LOAD_SEARCH_LENGTH_SLIDER_OPTIONS = [
  ...range(MIN_LOAD_SEARCH_TRUCK_LENGTH, MAX_PLUS_TRUCK_LENGTH, 1),
  MAX_PLUS_TRUCK_LENGTH,
];

export const LOAD_SEARCH_WEIGHT_SLIDER_OPTIONS = [
  ...range(MIN_LOAD_SEARCH_TRUCK_WEIGHT, MAX_LOAD_SEARCH_TRUCK_WEIGHT, 1000),
  MAX_LOAD_SEARCH_TRUCK_WEIGHT,
];

import React from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';

import icons from '@util/iconsConstants';

interface ComponentProps {
  headerText: string;
  buttonText?: string;
  onPress?: () => void;
}

const WarningBannerContainer = styled.div`
  display: flex;
  background-color: ${Color.ORANGE_WARNING};
  border-bottom: 2px solid ${Color.ORANGE_DARK};
  justify-content: space-between;
  align-items: center;
  height: 56px;
`;

const BannerButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  padding: 0 ${Spacing.ScreenSide}px 0 0;
  width: fit-content;
  position: relative;
`;
const IconTextContainer = styled.div`
  justify-content: flex-start;
  display: flex;
  width: fit-content;
  align-items: center;
`;

const ActivateButton = styled.div`
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
`;

export const NotificationWarningBanner: React.FC<ComponentProps> = (props) => (
  <WarningBannerContainer id="warning_banner">
    <IconTextContainer>
      <div style={{ padding: `0 0 0 16px` }}>
        <img src={icons.warning} style={{ verticalAlign: 'top' }} />
      </div>

      <div id="warning_banner_header" style={{ paddingLeft: '4px', fontSize: FontSize.ContentDetail }}>
        {props.headerText}
      </div>
    </IconTextContainer>
    <BannerButtonContainer>
      <ActivateButton id="warning_banner_button" color="secondary" onClick={props.onPress}>
        {props.buttonText}
      </ActivateButton>
    </BannerButtonContainer>
  </WarningBannerContainer>
);

import React from 'react';

import styled from 'styled-components';

import { PhoneNumber } from '@component/contact';
import { Text, TextStyle } from '@component/text';
import { Color } from '@style/Color';
import { FontLineHeight, FontSize } from '@style/StyleConstants';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';

interface Props {
  id: string;
  title: string;
  isLargerHeight?: boolean;
}

interface ValueProps extends Props {
  rightValue?: string | JSX.Element | number;
  isLeftAlign?: boolean;
}

interface PhoneRowProps extends Props {
  phoneString?: string;
  isLeftAlign?: boolean;
}

interface LinkRowProps extends Props {
  linkFormat: (format: string | number) => string;
  rightValue?: string | number;
  isLeftTextAlign?: boolean;
}

const RowContainer = styled.div`
  word-wrap: break-word;
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  grid-template-columns: 40% 60%;
  grid-column-gap: 8px;
`;

const BaseText = styled.span`
  color: ${Color.GRAY_DARK};
  line-height: ${(props: { isLargerHeight?: boolean }) =>
    props.isLargerHeight ? FontLineHeight.LargeTitle : FontLineHeight.Header}px;
`;

export const TextContentDetailDark = styled(BaseText)`
  font-size: ${FontSize.ContentDetail}px;
`;

export const TextContentDetailDarkBold = styled(TextContentDetailDark)`
  font-weight: bold;
`;

export const ValueRow: React.FC<ValueProps> = (props: ValueProps) => {
  const { title, rightValue } = props;
  return (
    <RowContainer id={props.id}>
      <TextContentDetailDark id="title">{title}</TextContentDetailDark>
      <div>
        <div style={{ textAlign: 'right' }}>
          {rightValue ? (
            <TextContentDetailDarkBold>{rightValue}</TextContentDetailDarkBold>
          ) : (
            <TextContentDetailDarkBold>{NO_INFORMATION_SYMBOL}</TextContentDetailDarkBold>
          )}
        </div>
      </div>
    </RowContainer>
  );
};

export const LargeValueRow: React.FC<ValueProps> = (props: ValueProps) => {
  const { title, rightValue } = props;
  return (
    <>
      <TextContentDetailDark id="title" isLargerHeight={true}>
        {title}
      </TextContentDetailDark>
      <div style={{ textAlign: 'left' }}>
        {rightValue ? (
          <TextContentDetailDarkBold isLargerHeight={true}>{rightValue}</TextContentDetailDarkBold>
        ) : (
          <TextContentDetailDarkBold isLargerHeight={true}>{NO_INFORMATION_SYMBOL}</TextContentDetailDarkBold>
        )}
      </div>
    </>
  );
};

export const TitleRow: React.FC<Props> = (props: Props) => {
  const { title } = props;
  return (
    <RowContainer id={props.id}>
      <TextContentDetailDarkBold id="title" isLargerHeight={props.isLargerHeight} style={{ marginBottom: 10 }}>
        {title}
      </TextContentDetailDarkBold>
    </RowContainer>
  );
};

export const LinkRow: React.FC<LinkRowProps> = (props) => {
  let content: JSX.Element | string = NO_INFORMATION_SYMBOL;
  if (props.rightValue) {
    content = (
      <a
        id={props.id + '_link'}
        href={props.linkFormat(props.rightValue)}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecorationLine: 'none' }}
      >
        <Text
          id={props.id + '_link_text'}
          style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
          textStyle={TextStyle.Link}
        >
          {props.rightValue}
        </Text>
      </a>
    );
  }
  const getContent = () => (
    <>
      <TextContentDetailDark id="title" isLargerHeight={props.isLargerHeight}>
        {props.title}
      </TextContentDetailDark>
      <div style={{ textAlign: props.isLeftTextAlign ? 'left' : 'right' }}>{content}</div>
    </>
  );

  return props.isLeftTextAlign ? getContent() : <RowContainer id={props.id}>{getContent()}</RowContainer>;
};

export const PhoneRow: React.FC<PhoneRowProps> = (props) => {
  const phone = props.phoneString ? (
    <PhoneNumber
      id={props.id}
      phoneNumberString={props.phoneString}
      phoneNumberValue={props.phoneString}
      analyticsActionSuffix="Company Details - Company list item"
    />
  ) : (
    NO_INFORMATION_SYMBOL
  );
  const getContent = () => (
    <>
      <TextContentDetailDark id="title" isLargerHeight={props.isLargerHeight}>
        {props.title}
      </TextContentDetailDark>
      <div style={{ textAlign: props.isLeftAlign ? 'left' : 'right' }}>{phone}</div>
    </>
  );
  return props.isLeftAlign ? getContent() : <RowContainer id={props.id}>{getContent()}</RowContainer>;
};

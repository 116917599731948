import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { LoadDetailsTabs } from '@/model';
import { Load, LoadProgress } from '@common/model';
import { DocumentsContext } from '@common/redux/epic/DocumentListEpic';
import { getFolderByLoad } from '@common/redux/epic/DocumentsEpic';
import { setProgress } from '@common/redux/epic/LoadInfoEpic';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { ExpansionPanelDataWrapper } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import {
  showBookDialog,
  useOpenDocumentWithFolder,
} from '@component/panels/findLoads/loadDetailsPanel/ManageDocuments';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { myDocumentsEncoder } from '@page/myDocuments/MyDocumentsEncoder';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';

import { default as LoadDocumentsIcon } from './load-documents.svg';

export const LinkButton = styled.div`
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  margin: auto;
  margin-top: 10px;
  font-weight: bold;
`;

const TextContent = styled.div`
  color: ${Color.LIGHT_BLACK};
`;

interface Props {
  load: Load | undefined;
  handleChange(tab: LoadDetailsTabs, status: boolean): void;
  isOpened: boolean;
  isFromMyLoads?: boolean;
}

export const LoadDocuments: React.FC<Props> = (props) => {
  const folderIdentifier = useSelector((state) => state.documents.loadDetailsDocuments.loadFolderIdentifier);
  const isLoadingLoadFolder = useSelector((state) => state.documents.loadDetailsDocuments.isLoadingLoadFolder);

  const history = useHistory();
  const myDocumentsSearchParams = myDocumentsEncoder.convertUrlSearchParamsToObject(history.location.search);
  const context = myDocumentsSearchParams.context ?? DocumentsContext.DEFAULT;

  const openDocumentsWithFolder = useOpenDocumentWithFolder(props.isFromMyLoads, context);
  const shouldOpenMyDocuments = useRef(false);
  const popupContext = usePopup();
  const dispatch = useDispatch();
  const totalDocumentCount = folderIdentifier?.fileCount ?? 0;
  const [isCurrentLoadCount, setIsCurrentLoadCount] = useState(false);

  useEffect(() => {
    if (props.load?.id && folderIdentifier?.loadId) {
      setIsCurrentLoadCount(props.load?.id === folderIdentifier?.loadId);
    } else {
      setIsCurrentLoadCount(!folderIdentifier?.loadId);
    }
  }, [props.load?.id, folderIdentifier?.loadId]);

  useEffect(() => {
    if (props.load?.id) {
      dispatch(getFolderByLoad(props.load.id));
    }
  }, [props.load?.id, props.load?.metadata?.userdata?.progress]);

  useEffect(() => {
    if (folderIdentifier?.loadId && shouldOpenMyDocuments.current) {
      openDocumentsWithFolder(folderIdentifier);
      shouldOpenMyDocuments.current = false;
    }
  }, [folderIdentifier?.loadId]);

  if (!props.load) {
    return null;
  }

  const bookLoad = () => {
    if (props.load?.id) {
      dispatch(setProgress(props.load?.id, LoadProgress.Booked));
    }
  };

  const openFolderOrBook = () => {
    if (
      !props.load?.metadata?.userdata?.progress ||
      props.load?.metadata.userdata.progress === LoadProgress.LoadAvailable
    ) {
      showBookDialog(popupContext, bookLoad);
      shouldOpenMyDocuments.current = true;
    } else {
      openDocumentsWithFolder(folderIdentifier);
    }
  };

  const handleTabClick = () => {
    props.handleChange(LoadDetailsTabs.LoadDocuments, !props.isOpened);
  };

  return (
    <ExpansionPanel
      id="load_documents"
      titleText={t(T.common_loadDetails_loadDocuments_title)}
      subtitleText={getDocumentsCount(isLoadingLoadFolder, isCurrentLoadCount, totalDocumentCount)}
      icon={LoadDocumentsIcon}
      isOpened={props.isOpened}
      handleClick={handleTabClick}
    >
      <ExpansionPanelDataWrapper style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '15px' }}>
        <TextContent>{t(T.common_loadDetails_loadDocuments_description)}</TextContent>
        <LinkButton id={'save'} onClick={openFolderOrBook}>
          {t(T.common_loadDetails_loadDocuments_manageDocuments)}
        </LinkButton>
      </ExpansionPanelDataWrapper>
    </ExpansionPanel>
  );
};

const getDocumentsCount = (isLoading: boolean, isCurrentLoadFolder: boolean, totalDocumentCount: number) => {
  if (isLoading || !isCurrentLoadFolder) {
    return t(T.common_myDocuments_updateFolder_updating);
  }
  return totalDocumentCount == 1
    ? t(T.common_loadDetails_loadDocuments_oneFileAttached)
    : t(T.common_loadDetails_loadDocuments_filesAttached, {
        num: totalDocumentCount,
      });
};

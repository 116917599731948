import React, { useCallback } from 'react';

import { map, split, trim } from 'lodash';
import { equals } from 'lodash/fp';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { isMessagingWidgetView } from '@/MessagingWidgetApp';
import { setMenuLayout, UIMenuLayout } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { Routes } from '@/router/Routes';
import { Geolocation, UserType } from '@common/model';
import { GenericWidgetPayload } from '@common/model/messaging/MessageWidget';
import { WidgetActionType } from '@common/model/messaging/WidgetAction';
import { sendOneTimeLocationInConversation } from '@common/redux/epic/CarrierTrackingEpic';
import { MultipurposeButton } from '@component/buttons';
import {
  showLocationDeniedDialog,
  showLocationTimeoutDialog,
  showLocationUnavailableDialog,
} from '@component/panels/findLoads/searchPanel/GeolocationPopups';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupLabelButton, PopupOkButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text } from '@component/text';
import { BidsOriginUI } from '@page/bids/BidsList';
import { BIDS_SUMMARY_PANEL_PATH, bidsEncoder, BidsTabs } from '@page/bids/BidsPanelRouting';
import { useIsBiddingEnabled } from '@page/bids/BidStatusHelper';
import { Color } from '@style/Color';
import { FontFamily, FontSize, FontWeight } from '@style/StyleConstants';
import { T, t } from '@translate';
import { AppConfig } from '@util/AppConfig';
import { getGeolocation } from '@util/GeolocationHelper';
import { useSelector } from '@util/hooks';
import Linkify from '@util/linkify/Linkify';

import type { SendOneTimeLocationRequest } from '@common/model/OneTimeLocation';
import type { WidgetAction } from '@common/model/messaging/WidgetAction';

const WidgetMessageContainer = styled.div`
  border-radius: 13px;
  padding: 10px;
  width: fit-content;
`;

const ActionButton = styled(MultipurposeButton)`
  && {
    flex: 1;
    border-radius: 100px;
    font-size: 12px;
    height: 30px;
    width: 100%;
    margin-top: 10px;
  }
`;

const WidgetText = styled(Text)`
  line-height: 20px;
  font-size: 16px;
`;

const TextSpacer = styled.div`
  height: 6px;
`;

const ImageTextHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;
const ImageViewer = styled.img`
  max-width: 24px;
  max-height: 24px;
  height: 24px;
  width: 24px;
`;

const WidgetIconBubble = styled.div`
  font-family: ${FontFamily.Main};
  background-color: ${Color.GREEN_MAIN};
  height: 36px;
  width: 36px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`;

export interface GenericWidgetProps {
  widget: GenericWidgetPayload;
  otherPartyUserID?: string;
  sentBy?: string;
  conversationId: string;
}

interface WidgetActionProps {
  widgetAction: WidgetAction;
  conversationId: string;
  otherPartyUserID?: string;
  sentBy?: string;
}

export const GenericWidget: React.FC<GenericWidgetProps> = (props) => (
  <WidgetMessageContainer>
    <ImageTextHeader>
      <WidgetIconBubble>
        <ImageViewer src={props.widget?.iconUrl} />
      </WidgetIconBubble>
      <Text
        style={{ fontSize: FontSize.Content, fontWeight: FontWeight.Bold, alignSelf: 'center' }}
        id="message_header"
      >
        {props.widget?.title}
      </Text>
    </ImageTextHeader>
    {map(split(props.widget.message, '\n'), (message, index) => (
      <WidgetText key={index} style={{ color: Color.GRAY_DARK }} id="message">
        <TextSpacer />
        <Linkify>{trim(message)}</Linkify>
        <TextSpacer />
      </WidgetText>
    ))}
    {props.widget.actions
      ? map(props.widget.actions, (action, index) => (
          <WidgetActionButton
            key={index}
            widgetAction={action}
            conversationId={props.conversationId}
            otherPartyUserID={props.otherPartyUserID}
            sentBy={props.sentBy}
          />
        ))
      : null}
  </WidgetMessageContainer>
);

const WidgetActionButton: React.FC<WidgetActionProps> = (props) => {
  switch (props.widgetAction.type) {
    case WidgetActionType.ShareOneTimeLocation:
      return (
        <ShareOneTimeLocationActionButton
          sentBy={props.sentBy}
          conversationId={props.conversationId}
          label={props.widgetAction.label}
        />
      );
    case WidgetActionType.GoToBidDetails:
      return (
        <ViewBidActionButton
          bidId={props.widgetAction.bidId}
          label={props.widgetAction.label}
          loadId={props.widgetAction.loadId}
          conversationId={props.conversationId}
          otherPartyUserId={props.otherPartyUserID}
        />
      );
    case WidgetActionType.OpenHyperLink:
      return <HyperLinkButton url={props.widgetAction.url} label={props.widgetAction.label} />;
    default:
      return null;
  }
};

interface ViewBidButtonProps {
  bidId: string;
  label: string;
  loadId: string;
  conversationId: string;
  otherPartyUserId?: string;
}

const ViewBidActionButton = (props: ViewBidButtonProps) => {
  const { bidId, loadId, label, otherPartyUserId } = props;

  const dispatch = useDispatch();

  const history = useHistory();
  const isBiddingEnabled = useIsBiddingEnabled();

  const isMessagingWidget = isMessagingWidgetView();

  const isBrokerCarrier = useSelector((state) => state.user.userType === UserType.BrokerCarrier);
  const userCompanyId = useSelector((state) => state.user.profile?.payload?.companyId);

  const loadPosterId = useSelector(
    (state) => state.communication.conversations.get(props.conversationId)?.metadata?.load?.poster?.id
  );

  const isUserPosterOfLoad = equals(userCompanyId, loadPosterId);

  const onClick = useCallback(() => {
    if (isMessagingWidget) {
      window.open(
        `${AppConfig.LBConfig.MembersRoot}/${BIDS_SUMMARY_PANEL_PATH}/${bidId}/${loadId}/offer/${bidId}`,
        '_blank'
      );
    } else {
      if (isBrokerCarrier) {
        // explicitly handling BrokerCarrier case here, Menulayout will be selected automatically for other CompanyCategory types
        dispatch(setMenuLayout(isUserPosterOfLoad ? UIMenuLayout.BrokerShipper : UIMenuLayout.Carrier));
      }
      history.push({
        pathname: `${generatePath(Routes.BIDS, {
          bidID: bidId,
          edit: 'offer',
          loadID: loadId,
          offerID: bidId,
          origin: BidsOriginUI.CommunicationPage,
          otherPartyUserID: otherPartyUserId ?? ' ',
          summary: 'summary',
          tab: BidsTabs.InteractiveBids,
        })}`,
        search: bidsEncoder.convertObjectRequestToUrlParams({
          loadId: loadId,
          redirectCommunicationPath: history.location.pathname,
        }),
      });
    }
  }, [bidId, loadId, isUserPosterOfLoad, otherPartyUserId, isMessagingWidget]);

  return isBiddingEnabled ? (
    <ActionButton id="view_bid" text={label} variant="outlined" color="primary" onClick={onClick} />
  ) : null;
};

interface HyperLinkButtonProps {
  url: string;
  label: string;
}

const HyperLinkButton = (props: HyperLinkButtonProps) => {
  return (
    <ActionButton
      id="open_location_link"
      text={props.label}
      variant={'outlined'}
      color={'primary'}
      onClick={() => window.open(props.url, '_blank')}
    />
  );
};

interface ViewShareOneTimeLocationButtonProps {
  sentBy: string | undefined;
  conversationId: string;
  label: string;
}

const ShareOneTimeLocationActionButton = (props: ViewShareOneTimeLocationButtonProps) => {
  const popupContext = usePopup();
  const currentUserID = useSelector((state) => state.user.profile?.payload?.id);
  const dispatch = useDispatch();

  const shareCurrentLocation = (location: Geolocation) => {
    const request: SendOneTimeLocationRequest = {
      conversationId: props.conversationId,
      geolocation: location,
    };
    dispatch(sendOneTimeLocationInConversation(request));
  };

  const createPopupOkButton = (dismissDialog: () => void) => [
    <PopupOkButton testID={'ok'} key={'ok'} action={dismissDialog} />,
  ];

  const createPopupConfirmLocationShareButton = (dismissDialog: () => void) => [
    <PopupLabelButton
      label={t(T.confirmationDialog_Confirm)}
      testID={'confirm'}
      key={'confirm'}
      action={() => {
        getGeolocation({
          onSuccess: (location) => shareCurrentLocation(location),
          onDenied: () => showLocationDeniedDialog(popupContext),
          onUnavailable: () => showLocationUnavailableDialog(popupContext),
          onTimeout: () => showLocationTimeoutDialog(popupContext),
        });
        dismissDialog();
      }}
    />,
  ];

  const onPressShareLocation = () => {
    const { openingPopup, closingPopup } = popupContext;
    if (props.sentBy && props.sentBy !== currentUserID) {
      openingPopup({
        body: (
          <BasePopup
            title={t(T.common_conversations_locationRequest_confirmation_title)}
            buttons={createPopupConfirmLocationShareButton(closingPopup)}
          >
            {t(T.common_conversations_locationRequest_confirmation_content)}
          </BasePopup>
        ),
        width: PopupSizes.SMALL,
        isDismissible: true,
        hasCloseButton: true,
      });
    } else {
      openingPopup({
        body: (
          <BasePopup
            title={t(T.common_conversations_locationRequest_unavailable_title)}
            buttons={createPopupOkButton(closingPopup)}
          >
            {t(T.common_conversations_locationRequest_unavailable_content)}
          </BasePopup>
        ),
        width: PopupSizes.SMALL,
        isDismissible: true,
        hasCloseButton: true,
      });
    }
  };

  return (
    <ActionButton
      id="send_location"
      text={props.label}
      variant={'outlined'}
      color={'primary'}
      onClick={onPressShareLocation}
    />
  );
};

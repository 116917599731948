import React, { BaseSyntheticEvent, Component } from 'react';

import { Menu } from '@material-ui/core';

interface Props {
  anchor: HTMLElement | undefined;
  onClose: () => void;
  onBackdropClick?: (event: BaseSyntheticEvent) => void;
}

export class DropdownMenu extends Component<Props> {
  render() {
    const { anchor, onClose, onBackdropClick } = this.props;
    return (
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="customized-menu"
        anchorEl={anchor}
        keepMounted={true}
        open={!!anchor}
        onClose={onClose}
        onBackdropClick={onBackdropClick}
        disableAutoFocusItem={true}
        PaperProps={{
          style: {
            boxShadow: '0 2px 8px rgba(0,0,0, 0.2)',
          },
        }}
      >
        {this.props.children}
      </Menu>
    );
  }
}

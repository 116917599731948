import React from 'react';

import styled from 'styled-components';

import { displayCurrencyOrDash } from '@common/formatter';
import { DetailRow } from '@component/panels/basePanel/loadDetails/generalInfo/LoadDetailsInfoBar';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

const Text = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  font-style: italic;
  color: ${Color.GRAY_STONE};
`;

export const ExpediteAllRateInfo: React.FC = () => {
  const expediteAllRate = useSelector((state) => state.marketRates.expediteAllRate);
  const isLoading = useSelector((state) => state.marketRates.isLoadingExpediteAllRate);

  const ratePerMile = expediteAllRate?.estRatePerMile
    ? displayCurrencyOrDash(expediteAllRate.estRatePerMile, true, {
        suffix: t(T.common_per_miles_short),
      })
    : undefined;

  const rate =
    expediteAllRate?.estRate || expediteAllRate?.estRatePerMile ? (
      displayCurrencyOrDash(expediteAllRate?.estRate, false)
    ) : (
      <Text>{t(T.common_marketRate_notAvailableAtThisTime)}</Text>
    );

  return (
    <DetailRow
      id="expedite_all_rate"
      title={t(T.marketRates_widgets_expediteAll_title)}
      subValue={isLoading ? undefined : ratePerMile}
      value={isLoading ? <Text>{t(T.findLoads_loadDetails_loading)}</Text> : rate}
    />
  );
};

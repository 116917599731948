export enum T {
  /** "OK" */ common_ok = 'common.ok',
  /** "Cancel" */ common_cancel = 'common.cancel',
  /** "Yes" */ common_yes = 'common.yes',
  /** "No" */ common_no = 'common.no',
  /** "Remove" */ common_remove = 'common.remove',
  /** "." */ common_period = 'common.period',
  /** ":" */ common_colon = 'common.colon',
  /** "•" */ common_bulletPoint = 'common.bulletPoint',
  /** "=" */ common_equals = 'common.equals',
  /** "-" */ common_hyphon = 'common.hyphon',
  /** "ft" */ common_feet = 'common.feet',
  /** "lb" */ common_lb = 'common.lb',
  /** "lbs" */ common_lbs = 'common.lbs',
  /** "miles" */ common_miles = 'common.miles',
  /** "mi" */ common_miles_short = 'common.miles_short',
  /** "/mi" */ common_per_miles_short = 'common.per_miles_short',
  /** "$" */ common_dollar_sign = 'common.dollar_sign',
  /** "ext." */ common_phone_ext = 'common.phone_ext',
  /** "Error" */ common_error = 'common.error',
  /** "Active" */ common_active = 'common.active',
  /** "Warning" */ common_warning = 'common.warning',
  /** "N/A" */ common_notApplicable = 'common.notApplicable',
  /** "unknown" */ common_unknown = 'common.unknown',
  /** "enabled" */ common_enabled = 'common.enabled',
  /** "disabled" */ common_disabled = 'common.disabled',
  /** "All" */ common_all = 'common.all',
  /** "Any" */ common_any = 'common.any',
  /** "Or" */ common_or = 'common.or',
  /** "at" */ common_at = 'common.at',
  /** "Change" */ common_change = 'common.change',
  /** "Select" */ common_select = 'common.select',
  /** "Create" */ common_create = 'common.create',
  /** "Add" */ common_add = 'common.add',
  /** "Submit" */ common_submit = 'common.submit',
  /** "Resubmit" */ common_reSubmit = 'common.reSubmit',
  /** "Enter" */ common_enter = 'common.enter',
  /** "Get started" */ common_getStarted = 'common.getStarted',
  /** "Update" */ common_update = 'common.update',
  /** "Deactivate" */ common_deactivate = 'common.deactivate',
  /** "Try again" */ common_tryAgain = 'common.tryAgain',
  /** "Email" */ common_email = 'common.email',
  /** "Email sent" */ common_emailSent = 'common.emailSent',
  /** "Phone" */ common_phone = 'common.phone',
  /** "loading" */ common_loading = 'common.loading',
  /** "no data" */ common_noData = 'common.noData',
  /** "New" */ common_new = 'common.new',
  /** "Success" */ common_success = 'common.success',
  /** "Continue" */ common_continue = 'common.continue',
  /** "Anywhere" */ common_anywhere = 'common.anywhere',
  /** "Filter" */ common_filter = 'common.filter',
  /** "First name" */ common_firstName = 'common.firstName',
  /** "Last name" */ common_lastName = 'common.lastName',
  /** "Full name" */ common_fullName = 'common.fullName',
  /** "Verify" */ common_verify = 'common.verify',
  /** "Verified" */ common_verified = 'common.verified',
  /** "Not verified" */ common_notVerified = 'common.notVerified',
  /** "Password" */ common_password = 'common.password',
  /** "Save" */ common_save = 'common.save',
  /** "Address" */ common_address = 'common.address',
  /** "Address 2" */ common_address2 = 'common.address2',
  /** "City" */ common_city = 'common.city',
  /** "State/Province" */ common_stateProvince = 'common.stateProvince',
  /** "Zip/Postal code" */ common_zip_postal_code = 'common.zip_postal_code',
  /** "Country" */ common_country = 'common.country',
  /** "Dispatcher" */ common_dispatcher = 'common.dispatcher',
  /** "Apply" */ common_apply = 'common.apply',
  /** "Clear" */ common_clear = 'common.clear',
  /** "Clear all" */ common_clearAll = 'common.clearAll',
  /** "Top" */ common_top = 'common.top',
  /** "Set alert" */ common_setAlert = 'common.setAlert',
  /** "Remove alert" */ common_removeAlert = 'common.removeAlert',
  /** "Alert: On" */ common_alertOn = 'common.alertOn',
  /** "Alert: Off" */ common_alertOff = 'common.alertOff',
  /** "Off" */ common_Off = 'common.Off',
  /** "On" */ common_On = 'common.On',
  /** "More filters" */ common_moreFilters = 'common.moreFilters',
  /** "Documents" */ common_documents = 'common.documents',
  /** "Manage" */ common_manage = 'common.manage',
  /** "Attached" */ common_attached = 'common.attached',
  /** "Parameters" */ common_parameters = 'common.parameters',
  /** "With: {{value}}" */ common_withDimensions = 'common.withDimensions',
  /** "Loads with" */ common_loadsWith = 'common.loadsWith',
  /** "Remove all" */ common_removeAll = 'common.removeAll',
  /** "+" */ common_plusSign = 'common.plusSign',
  /** "=" */ common_equalitySign = 'common.equalitySign',
  /** "Edit" */ common_edit = 'common.edit',
  /** "Delete" */ common_delete = 'common.delete',
  /** "Hide" */ common_hide = 'common.hide',
  /** "Upload" */ common_upload = 'common.upload',
  /** "MC#" */ common_mc = 'common.mc',
  /** "USDOT#" */ common_usDot = 'common.usDot',
  /** "N/A" */ common_na = 'common.na',
  /** "Next" */ common_next = 'common.next',
  /** "Done" */ common_done = 'common.done',
  /** "Feedback" */ common_feedback = 'common.feedback',
  /** "day" */ common_day = 'common.day',
  /** "Cost-per-day" */ common_costPerDay_title = 'common.costPerDay.title',
  /** "CPD" */ common_costPerDay_abbreviation = 'common.costPerDay.abbreviation',
  /** "Activating Cost-per-day calculator will let you know quickly whether or not a load is a good value to you." */ common_costPerDay_description = 'common.costPerDay.description',
  /** "Cost-per-day calculator" */ common_costPerDay_calculatorText = 'common.costPerDay.calculatorText',
  /** "Cost-per-day calculator" */ common_costPerDay_bannerTitle = 'common.costPerDay.bannerTitle',
  /** "The value of a load isn't just about how many miles you drive, it's also about making sure all of your costs are covered (ie. truck costs, business costs, home costs, etc.)" */ common_costPerDay_bannerContent = 'common.costPerDay.bannerContent',
  /** "Your cost-per-day is the sum of your truck and home expenses divided by the number of days you would like to work." */ common_costPerDay_cardContentFirst = 'common.costPerDay.cardContentFirst',
  /** "In order to be profitable, each working day you will need to make more than:" */ common_costPerDay_cardContentSecond = 'common.costPerDay.cardContentSecond',
  /** "above cost per day" */ common_costPerDay_cpdAbove = 'common.costPerDay.cpdAbove',
  /** "below cost per day" */ common_costPerDay_cpdBelow = 'common.costPerDay.cpdBelow',
  /** "{{current}} of {{max}}" */ common_costPerDay_edit_of = 'common.costPerDay.edit.of',
  /** "Next" */ common_costPerDay_edit_next = 'common.costPerDay.edit.next',
  /** "Summarize your monthly truck expenses and make sure to include items such as your truck and trailer payments, insurance, maintenance costs, etc." */ common_costPerDay_edit_truckExpenses_description = 'common.costPerDay.edit.truckExpenses.description',
  /** "Summarize your" */ common_costPerDay_edit_truckExpenses_descriptionStart = 'common.costPerDay.edit.truckExpenses.descriptionStart',
  /** "monthly truck expenses" */ common_costPerDay_edit_truckExpenses_descriptionBold = 'common.costPerDay.edit.truckExpenses.descriptionBold',
  /** "and make sure to include items such as your truck and trailer payments, insurance, maintenance costs, etc." */ common_costPerDay_edit_truckExpenses_descriptionEnd = 'common.costPerDay.edit.truckExpenses.descriptionEnd',
  /** "* Fuel cost should not be included as it will be calculated on a load-by-load basis." */ common_costPerDay_edit_truckExpenses_disclaimer = 'common.costPerDay.edit.truckExpenses.disclaimer',
  /** "Truck image" */ common_costPerDay_edit_truckExpenses_imageAlt = 'common.costPerDay.edit.truckExpenses.imageAlt',
  /** "Truck expenses" */ common_costPerDay_edit_truckExpenses_input_label = 'common.costPerDay.edit.truckExpenses.input.label',
  /** "Summarize your monthly home expenses and make sure to include items such as your mortgage or rent, utility costs, car payments, insurance premiums, food costs, etc." */ common_costPerDay_edit_homeExpenses_description = 'common.costPerDay.edit.homeExpenses.description',
  /** "Summarize your" */ common_costPerDay_edit_homeExpenses_descriptionStart = 'common.costPerDay.edit.homeExpenses.descriptionStart',
  /** "monthly home expenses" */ common_costPerDay_edit_homeExpenses_descriptionBold = 'common.costPerDay.edit.homeExpenses.descriptionBold',
  /** "and make sure to include items such as your mortgage or rent, utility costs, car payments, insurance premiums, food costs, etc." */ common_costPerDay_edit_homeExpenses_descriptionEnd = 'common.costPerDay.edit.homeExpenses.descriptionEnd',
  /** "Home image" */ common_costPerDay_edit_homeExpenses_imageAlt = 'common.costPerDay.edit.homeExpenses.imageAlt',
  /** "Home expenses" */ common_costPerDay_edit_homeExpenses_input_label = 'common.costPerDay.edit.homeExpenses.input.label',
  /** "Enter the number of days you would like to work each month." */ common_costPerDay_edit_workDays_description = 'common.costPerDay.edit.workDays.description',
  /** "Enter the" */ common_costPerDay_edit_workDays_descriptionStart = 'common.costPerDay.edit.workDays.descriptionStart',
  /** "number of days" */ common_costPerDay_edit_workDays_descriptionBold1 = 'common.costPerDay.edit.workDays.descriptionBold1',
  /** "you would like to" */ common_costPerDay_edit_workDays_descriptionMiddle = 'common.costPerDay.edit.workDays.descriptionMiddle',
  /** "work each month." */ common_costPerDay_edit_workDays_descriptionBold2 = 'common.costPerDay.edit.workDays.descriptionBold2',
  /** "Calendar image" */ common_costPerDay_edit_workDays_imageAlt = 'common.costPerDay.edit.workDays.imageAlt',
  /** "Work days" */ common_costPerDay_edit_workDays_input_label = 'common.costPerDay.edit.workDays.input.label',
  /** "number of days" */ common_costPerDay_edit_workDays_input_errorText = 'common.costPerDay.edit.workDays.input.errorText',
  /** "Enter the average summary of loading and unloading hours per load." */ common_costPerDay_edit_loadingHours_description = 'common.costPerDay.edit.loadingHours.description',
  /** "Loading and unloading hours" */ common_costPerDay_edit_loadingHours_input_label = 'common.costPerDay.edit.loadingHours.input.label',
  /** "Enter the daily average amount of driving hours." */ common_costPerDay_edit_drivingHours_description = 'common.costPerDay.edit.drivingHours.description',
  /** "Driving hours per day" */ common_costPerDay_edit_drivingHours_input_label = 'common.costPerDay.edit.drivingHours.input.label',
  /** "driving hours" */ common_costPerDay_edit_drivingHours_input_errorText = 'common.costPerDay.edit.drivingHours.input.errorText',
  /** "Cost Per Day Reached" */ common_costPerDay_card_title = 'common.costPerDay.card.title',
  /** "Cost per day n/a - no rate provided" */ common_costPerDay_card_notAvailable = 'common.costPerDay.card.notAvailable',
  /** "This trip will take approximately {{maxDays}} and accounts for your total profit ({{totalProfit}}) after fuel and other expenses." */ common_costPerDay_card_description = 'common.costPerDay.card.description',
  /** "day" */ common_costPerDay_card_day = 'common.costPerDay.card.day',
  /** "days" */ common_costPerDay_card_days = 'common.costPerDay.card.days',
  /** "Edit" */ common_costPerDay_card_edit = 'common.costPerDay.card.edit',
  /** "Setup" */ common_costPerDay_card_setup = 'common.costPerDay.card.setup',
  /** "Profit per day" */ common_costPerDay_card_profitPerDay = 'common.costPerDay.card.profitPerDay',
  /** "Profit per half day" */ common_costPerDay_card_profitPerHalfDay = 'common.costPerDay.card.profitPerHalfDay',
  /** "Cost per day" */ common_costPerDay_card_costPerDay = 'common.costPerDay.card.costPerDay',
  /** "Cost per half day" */ common_costPerDay_card_costPerHalfDay = 'common.costPerDay.card.costPerHalfDay',
  /** "Yes, profitable load. " */ common_costPerDay_card_profitableLoad = 'common.costPerDay.card.profitableLoad',
  /** "To cover your daily costs, the trip rate should be {{tripRate}} ({{tripRatePerMile}}/mi). The base rate for this load is {{postedRate}} ({{postedRatePerMile}}/mile)." */ common_costPerDay_card_footerLoadInfo = 'common.costPerDay.card.footerLoadInfo',
  /** "No, not profitable. " */ common_costPerDay_card_notProfitableload = 'common.costPerDay.card.notProfitableload',
  /** "Partial, still a decent load. " */ common_costPerDay_card_partialProfitabLeLoad = 'common.costPerDay.card.partialProfitabLeLoad',
  /** "{{profit}} profit per half day" */ common_costPerDay_card_profitValuePerHalfDay = 'common.costPerDay.card.profitValuePerHalfDay',
  /** "Reset password" */ common_resetPassword_title = 'common.resetPassword.title',
  /** "Instructions will be emailed to \"{{email}}\"" */ common_resetPassword_content = 'common.resetPassword.content',
  /** "Cancel" */ common_resetPassword_button_cancel = 'common.resetPassword.button_cancel',
  /** "Reset password" */ common_resetPassword_button_reset = 'common.resetPassword.button_reset',
  /** "There was an error in reseting your password." */ common_resetPassword_toast_error = 'common.resetPassword.toast.error',
  /** "Instructions for resetting your password have been sent to {{email}}." */ common_resetPassword_toast_success = 'common.resetPassword.toast.success',
  /** "Month" */ common_dates_month = 'common.dates.month',
  /** "Year" */ common_dates_year = 'common.dates.year',
  /** "Day" */ common_dates_day = 'common.dates.day',
  /** "Today" */ common_dates_today = 'common.dates.today',
  /** "Yesterday" */ common_dates_yesterday = 'common.dates.yesterday',
  /** "This month" */ common_dates_thisMonth = 'common.dates.thisMonth',
  /** "Last month" */ common_dates_lastMonth = 'common.dates.lastMonth',
  /** "Older" */ common_dates_older = 'common.dates.older',
  /** "January" */ common_dates_monthNames_january = 'common.dates.monthNames.january',
  /** "February" */ common_dates_monthNames_february = 'common.dates.monthNames.february',
  /** "March" */ common_dates_monthNames_march = 'common.dates.monthNames.march',
  /** "April" */ common_dates_monthNames_april = 'common.dates.monthNames.april',
  /** "May" */ common_dates_monthNames_may = 'common.dates.monthNames.may',
  /** "June" */ common_dates_monthNames_june = 'common.dates.monthNames.june',
  /** "July" */ common_dates_monthNames_july = 'common.dates.monthNames.july',
  /** "August" */ common_dates_monthNames_august = 'common.dates.monthNames.august',
  /** "September" */ common_dates_monthNames_september = 'common.dates.monthNames.september',
  /** "October" */ common_dates_monthNames_october = 'common.dates.monthNames.october',
  /** "November" */ common_dates_monthNames_november = 'common.dates.monthNames.november',
  /** "December" */ common_dates_monthNames_december = 'common.dates.monthNames.december',
  /** "Jan" */ common_dates_monthNamesShort_january = 'common.dates.monthNamesShort.january',
  /** "Feb" */ common_dates_monthNamesShort_february = 'common.dates.monthNamesShort.february',
  /** "Mar" */ common_dates_monthNamesShort_march = 'common.dates.monthNamesShort.march',
  /** "Apr" */ common_dates_monthNamesShort_april = 'common.dates.monthNamesShort.april',
  /** "May" */ common_dates_monthNamesShort_may = 'common.dates.monthNamesShort.may',
  /** "Jun" */ common_dates_monthNamesShort_june = 'common.dates.monthNamesShort.june',
  /** "Jul" */ common_dates_monthNamesShort_july = 'common.dates.monthNamesShort.july',
  /** "Aug" */ common_dates_monthNamesShort_august = 'common.dates.monthNamesShort.august',
  /** "Sept" */ common_dates_monthNamesShort_september = 'common.dates.monthNamesShort.september',
  /** "Oct" */ common_dates_monthNamesShort_october = 'common.dates.monthNamesShort.october',
  /** "Nov" */ common_dates_monthNamesShort_november = 'common.dates.monthNamesShort.november',
  /** "Dec" */ common_dates_monthNamesShort_december = 'common.dates.monthNamesShort.december',
  /** "Sun" */ common_dates_dayNames_sunday = 'common.dates.dayNames.sunday',
  /** "Mon" */ common_dates_dayNames_monday = 'common.dates.dayNames.monday',
  /** "Tue" */ common_dates_dayNames_tuesday = 'common.dates.dayNames.tuesday',
  /** "Wed" */ common_dates_dayNames_wednesday = 'common.dates.dayNames.wednesday',
  /** "Thu" */ common_dates_dayNames_thursday = 'common.dates.dayNames.thursday',
  /** "Fri" */ common_dates_dayNames_friday = 'common.dates.dayNames.friday',
  /** "Sat" */ common_dates_dayNames_saturday = 'common.dates.dayNames.saturday',
  /** "Sun" */ common_dates_dayNamesShort_sunday = 'common.dates.dayNamesShort.sunday',
  /** "Mon" */ common_dates_dayNamesShort_monday = 'common.dates.dayNamesShort.monday',
  /** "Tue" */ common_dates_dayNamesShort_tuesday = 'common.dates.dayNamesShort.tuesday',
  /** "Wed" */ common_dates_dayNamesShort_wednesday = 'common.dates.dayNamesShort.wednesday',
  /** "Thu" */ common_dates_dayNamesShort_thursday = 'common.dates.dayNamesShort.thursday',
  /** "Fri" */ common_dates_dayNamesShort_friday = 'common.dates.dayNamesShort.friday',
  /** "Sat" */ common_dates_dayNamesShort_saturday = 'common.dates.dayNamesShort.saturday',
  /** "Create new trip" */ common_tripBuilder_tripBuilder_createNewTripButton = 'common.tripBuilder.tripBuilder.createNewTripButton',
  /** "{{count}} Load added to this trip" */ common_tripBuilder_tripBuilder_tripCardHeader01 = 'common.tripBuilder.tripBuilder.tripCardHeader01',
  /** "{{count}} Loads added to this trip" */ common_tripBuilder_tripBuilder_tripCardHeader02 = 'common.tripBuilder.tripBuilder.tripCardHeader02',
  /** "Trip {{value}} mi" */ common_tripBuilder_tripBuilder_tripCardTripText = 'common.tripBuilder.tripBuilder.tripCardTripText',
  /** "Plan your trip" */ common_tripBuilder_tripBuilder_planYourTrip = 'common.tripBuilder.tripBuilder.planYourTrip',
  /** "Trip builder allows you to plan ahead by building a trip with multiple loads along the way." */ common_tripBuilder_tripBuilder_planTripDescription = 'common.tripBuilder.tripBuilder.planTripDescription',
  /** "Trip Summary" */ common_tripBuilder_tripBuilder_tripSummary = 'common.tripBuilder.tripBuilder.tripSummary',
  /** "Deadhead" */ common_tripBuilder_tripBuilder_deadhead = 'common.tripBuilder.tripBuilder.deadhead',
  /** "Added" */ common_tripBuilder_tripBuilder_added = 'common.tripBuilder.tripBuilder.added',
  /** "Available" */ common_tripBuilder_tripBuilder_available = 'common.tripBuilder.tripBuilder.available',
  /** "Some added loads are unavailable, please remove them to continue building your trip." */ common_tripBuilder_tripBuilder_notAvailable = 'common.tripBuilder.tripBuilder.notAvailable',
  /** "Unavailable" */ common_tripBuilder_tripBuilder_unavailable = 'common.tripBuilder.tripBuilder.unavailable',
  /** "This load is no longer available, remove it to continue building your trip." */ common_tripBuilder_tripBuilder_loadUnavailable = 'common.tripBuilder.tripBuilder.loadUnavailable',
  /** "Map is not available for this trip." */ common_tripBuilder_tripBuilder_mapNotAvailable = 'common.tripBuilder.tripBuilder.mapNotAvailable',
  /** "Remove" */ common_tripBuilder_tripBuilder_remove = 'common.tripBuilder.tripBuilder.remove',
  /** "New trip" */ common_tripBuilder_newTrip_newTripHeader = 'common.tripBuilder.newTrip.newTripHeader',
  /** "Result layout" */ common_tripBuilder_newTrip_resultLayout = 'common.tripBuilder.newTrip.resultLayout',
  /** "Load-by-load" */ common_tripBuilder_newTrip_loadByLoadLabel = 'common.tripBuilder.newTrip.loadByLoadLabel',
  /** "Build your own trip load-by-load" */ common_tripBuilder_newTrip_loadByLoadDescription = 'common.tripBuilder.newTrip.loadByLoadDescription',
  /** "Entire trip" */ common_tripBuilder_newTrip_entireTripLabel = 'common.tripBuilder.newTrip.entireTripLabel',
  /** "Automatically builds an entire trip for you." */ common_tripBuilder_newTrip_entireTripDescription = 'common.tripBuilder.newTrip.entireTripDescription',
  /** "Trip parameters" */ common_tripBuilder_newTrip_tripParameters = 'common.tripBuilder.newTrip.tripParameters',
  /** "Origin point" */ common_tripBuilder_newTrip_originPoint = 'common.tripBuilder.newTrip.originPoint',
  /** "Destination" */ common_tripBuilder_newTrip_destination = 'common.tripBuilder.newTrip.destination',
  /** "Time" */ common_tripBuilder_newTrip_time = 'common.tripBuilder.newTrip.time',
  /** "Pickup time" */ common_tripBuilder_newTrip_pickupTimeHeader = 'common.tripBuilder.newTrip.pickupTimeHeader',
  /** "Drop off time" */ common_tripBuilder_newTrip_dropoffTimeHeader = 'common.tripBuilder.newTrip.dropoffTimeHeader',
  /** "Date " */ common_tripBuilder_newTrip_date = 'common.tripBuilder.newTrip.date',
  /** "Pickup date" */ common_tripBuilder_newTrip_pickupDateHeader = 'common.tripBuilder.newTrip.pickupDateHeader',
  /** "Drop off date" */ common_tripBuilder_newTrip_dropoffDateHeader = 'common.tripBuilder.newTrip.dropoffDateHeader',
  /** "Excluded states (optional)" */ common_tripBuilder_newTrip_excludedStatesOptional = 'common.tripBuilder.newTrip.excludedStatesOptional',
  /** "Excluded states" */ common_tripBuilder_newTrip_excludedStates = 'common.tripBuilder.newTrip.excludedStates',
  /** "Only select states you do not want to travel to." */ common_tripBuilder_newTrip_excludedStatesSubHeader = 'common.tripBuilder.newTrip.excludedStatesSubHeader',
  /** "Maximum loads per trip" */ common_tripBuilder_newTrip_maximumLoadsHeader = 'common.tripBuilder.newTrip.maximumLoadsHeader',
  /** "Result layout" */ common_tripBuilder_newTrip_popup_popupHeader = 'common.tripBuilder.newTrip.popup.popupHeader',
  /** "Load-by-load allows you to build your own trip one load at a time." */ common_tripBuilder_newTrip_popup_popupDescription1 = 'common.tripBuilder.newTrip.popup.popupDescription1',
  /** "Entire trip automatically builds a trip by selecting loads matching your parameters." */ common_tripBuilder_newTrip_popup_popupDescription2 = 'common.tripBuilder.newTrip.popup.popupDescription2',
  /** "See {{count}} loads" */ common_loadSearch_seeCountLoads = 'common.loadSearch.seeCountLoads',
  /** "No loads available" */ common_loadSearch_noLoadsAvailable = 'common.loadSearch.noLoadsAvailable',
  /** "(Find loads)" */ common_loadSearch_findLoads = 'common.loadSearch.findLoads',
  /** "Trip length" */ common_loadSearch_tripLength_title = 'common.loadSearch.tripLength.title',
  /** "Short: 0-200 mi" */ common_loadSearch_tripLength_short_option = 'common.loadSearch.tripLength.short_option',
  /** "Medium: 201-500 mi" */ common_loadSearch_tripLength_medium_option = 'common.loadSearch.tripLength.medium_option',
  /** "Regional: 501-1000 mi" */ common_loadSearch_tripLength_regional_option = 'common.loadSearch.tripLength.regional_option',
  /** "Long: 1001+ mi" */ common_loadSearch_tripLength_long_option = 'common.loadSearch.tripLength.long_option',
  /** "Short" */ common_loadSearch_tripLength_short = 'common.loadSearch.tripLength.short',
  /** "Regional" */ common_loadSearch_tripLength_regional = 'common.loadSearch.tripLength.regional',
  /** "Medium" */ common_loadSearch_tripLength_medium = 'common.loadSearch.tripLength.medium',
  /** "Long" */ common_loadSearch_tripLength_long = 'common.loadSearch.tripLength.long',
  /** "Any" */ common_loadSearch_tripLength_any = 'common.loadSearch.tripLength.any',
  /** "Custom" */ common_loadSearch_tripLength_custom = 'common.loadSearch.tripLength.custom',
  /** "Trip length is {{length}}. Press to change length." */ common_loadSearch_tripLength_accessibility_tripLength = 'common.loadSearch.tripLength.accessibility.tripLength',
  /** "Similar loads" */ common_loadSearch_similarLoads_title = 'common.loadSearch.similarLoads.title',
  /** "More potential loads available for you." */ common_loadSearch_similarLoads_subtitle = 'common.loadSearch.similarLoads.subtitle',
  /** "Clear searches" */ common_loadSearch_clearSearchesPopup_title = 'common.loadSearch.clearSearchesPopup.title',
  /** "Clearing all searches will also clear any search alerts you have set." */ common_loadSearch_clearSearchesPopup_all = 'common.loadSearch.clearSearchesPopup.all',
  /** "Only searches without search alerts set will be cleared." */ common_loadSearch_clearSearchesPopup_withoutAlerts = 'common.loadSearch.clearSearchesPopup.withoutAlerts',
  /** "Only searches with search alerts set will be cleared." */ common_loadSearch_clearSearchesPopup_withAlerts = 'common.loadSearch.clearSearchesPopup.withAlerts',
  /** "Clear" */ common_loadSearch_clearSearchesPopup_buttonLabel = 'common.loadSearch.clearSearchesPopup.buttonLabel',
  /** "New" */ common_load_New = 'common.load.New',
  /** "Load" */ common_load_Load = 'common.load.Load',
  /** "Alerts Sent" */ common_load_Alerts = 'common.load.Alerts',
  /** "Load Views" */ common_load_Views = 'common.load.Views',
  /** "Views: {{count}}" */ common_load_Views_Count = 'common.load.Views_Count',
  /** "View" */ common_load_View = 'common.load.View',
  /** "Pickup" */ common_load_Pickup = 'common.load.Pickup',
  /** "Destination" */ common_load_Destination = 'common.load.Destination',
  /** "Pickup Date" */ common_load_Pickup_Date = 'common.load.Pickup_Date',
  /** "Search results" */ common_load_Search_Results = 'common.load.Search_Results',
  /** "No loads found" */ common_load_No_Loads = 'common.load.No_Loads',
  /** "No matches, check filters" */ common_load_No_Matches = 'common.load.No_Matches',
  /** "No matches found" */ common_load_noMatchesFound = 'common.load.noMatchesFound',
  /** "Search" */ common_load_Search = 'common.load.Search',
  /** "Found {{quantity}} matches" */ common_load_Found_Loads = 'common.load.Found_Loads',
  /** "Found 1 match" */ common_load_Found_Load = 'common.load.Found_Load',
  /** "1 filter applied" */ common_load_Filter_Applied = 'common.load.Filter_Applied',
  /** "{{quantity}} filters applied" */ common_load_Filters_Applied = 'common.load.Filters_Applied',
  /** "{{quantity}} matches after filtering" */ common_load_Matches_after_filtering = 'common.load.Matches_after_filtering',
  /** "Found {{quantity}} backhaul loads" */ common_load_Found_Backhaul_Loads = 'common.load.Found_Backhaul_Loads',
  /** "Sort by" */ common_load_Sort_By = 'common.load.Sort_By',
  /** "Load no longer available" */ common_load_loadNoLongerAvailable = 'common.load.loadNoLongerAvailable',
  /** "View Details" */ common_load_View_Details = 'common.load.View_Details',
  /** "Save load" */ common_load_Save_Load = 'common.load.Save_Load',
  /** "Saved load" */ common_load_Saved_Load = 'common.load.Saved_Load',
  /** "Status updated" */ common_load_Status_updated = 'common.load.Status_updated',
  /** "Status is not updated" */ common_load_Status_not_updated = 'common.load.Status_not_updated',
  /** "Load is saved" */ common_load_Load_is_saved = 'common.load.Load_is_saved',
  /** "Load hidden" */ common_load_Load_hidden = 'common.load.Load_hidden',
  /** "Load unhidden" */ common_load_Load_unhidden = 'common.load.Load_unhidden',
  /** "Loads unhidden" */ common_load_Loads_unhidden = 'common.load.Loads_unhidden',
  /** "Load is unsaved" */ common_load_Load_is_unsaved = 'common.load.Load_is_unsaved',
  /** "Loads are unsaved" */ common_load_Loads_are_unsaved = 'common.load.Loads_are_unsaved',
  /** "Unhide load" */ common_load_hidePopup_title = 'common.load.hidePopup.title',
  /** "To view details for this load, please unhide it." */ common_load_hidePopup_content = 'common.load.hidePopup.content',
  /** "Share" */ common_load_Share = 'common.load.Share',
  /** "Print" */ common_load_Print = 'common.load.Print',
  /** "Hide" */ common_load_Hide = 'common.load.Hide',
  /** "Unhide" */ common_load_Unhide = 'common.load.Unhide',
  /** "Bid" */ common_load_Bid = 'common.load.Bid',
  /** "Call" */ common_load_Call = 'common.load.Call',
  /** "Call again" */ common_load_Call_again = 'common.load.Call_again',
  /** "Contacted" */ common_load_Contacted = 'common.load.Contacted',
  /** "Trailer" */ common_load_Trailer = 'common.load.Trailer',
  /** "More details" */ common_load_More_details = 'common.load.More_details',
  /** "Ref. #" */ common_load_Ref = 'common.load.Ref',
  /** "Item" */ common_load_Item = 'common.load.Item',
  /** "Qty/Stops" */ common_load_Qty_Stops = 'common.load.Qty_Stops',
  /** "Qty" */ common_load_Qty = 'common.load.Qty',
  /** "Extra stops" */ common_load_ExtraStops = 'common.load.ExtraStops',
  /** "Note" */ common_load_Note = 'common.load.Note',
  /** "*Private notes will only be visible to you. Carriers will not see this note." */ common_load_PrivateNoteMessage = 'common.load.PrivateNoteMessage',
  /** "Private note" */ common_load_PrivateNote = 'common.load.PrivateNote',
  /** "Profit calculator" */ common_load_Profit_Calculator = 'common.load.Profit_Calculator',
  /** "Profit {{sign}}${{profit}} ({{secondSign}}${{perMile}}/mi)" */ common_load_your_profit_from_load = 'common.load.your_profit_from_load',
  /** "Mileage, maps, and tolls" */ common_load_mi_maps_tolls = 'common.load.mi_maps_tolls',
  /** "Company name" */ common_load_Company_Name = 'common.load.Company_Name',
  /** "Company phone" */ common_load_Company_Phone = 'common.load.Company_Phone',
  /** "DOT #" */ common_load_DOT_number = 'common.load.DOT_number',
  /** "MC #" */ common_load_MC_number = 'common.load.MC_number',
  /** "Score: {{value}}" */ common_load_Credit_Score = 'common.load.Credit_Score',
  /** "${{value}} toll cost" */ common_load_Toll_Cost = 'common.load.Toll_Cost',
  /** "Shipper (MC# not required)" */ common_load_shipperMCNotRequired = 'common.load.shipperMCNotRequired',
  /** "Load unavailable" */ common_load_loadUnavailable = 'common.load.loadUnavailable',
  /** "{{orig}} to {{dest}}" */ common_load_origToDest = 'common.load.origToDest',
  /** "Desired destinations" */ common_load_Popup_Destinations = 'common.load.Popup.Destinations',
  /** "Multiple" */ common_load_Popup_Multiple = 'common.load.Popup.Multiple',
  /** "Anywhere" */ common_load_Popup_Anywhere = 'common.load.Popup.Anywhere',
  /** "Anywhere in Canada" */ common_load_Popup_AnywhereCA = 'common.load.Popup.AnywhereCA',
  /** "Anywhere in US" */ common_load_Popup_AnywhereUS = 'common.load.Popup.AnywhereUS',
  /** "Any type" */ common_load_anyEquipmentType = 'common.load.anyEquipmentType',
  /** "Equipment" */ common_load_Equipment = 'common.load.Equipment',
  /** "Van" */ common_load_equipmentType_Van = 'common.load.equipmentType.Van',
  /** "Reefer" */ common_load_equipmentType_Reefer = 'common.load.equipmentType.Reefer',
  /** "Power only" */ common_load_equipmentType_PowerOnly = 'common.load.equipmentType.PowerOnly',
  /** "Container" */ common_load_equipmentType_Container = 'common.load.equipmentType.Container',
  /** "Hopper bottom" */ common_load_equipmentType_HopperBottom = 'common.load.equipmentType.HopperBottom',
  /** "Tanker" */ common_load_equipmentType_Tanker = 'common.load.equipmentType.Tanker',
  /** "Double drop" */ common_load_equipmentType_DoubleDrop = 'common.load.equipmentType.DoubleDrop',
  /** "Flatbed" */ common_load_equipmentType_Flatbed = 'common.load.equipmentType.Flatbed',
  /** "All flatbed" */ common_load_equipmentType_AllFlatbed = 'common.load.equipmentType.AllFlatbed',
  /** "All van" */ common_load_equipmentType_AllVan = 'common.load.equipmentType.AllVan',
  /** "Landoll" */ common_load_equipmentType_Landoll = 'common.load.equipmentType.Landoll',
  /** "Low boy" */ common_load_equipmentType_LowBoy = 'common.load.equipmentType.LowBoy',
  /** "Maxi" */ common_load_equipmentType_Maxi = 'common.load.equipmentType.Maxi',
  /** "Removable gooseneck" */ common_load_equipmentType_RemovableGooseneck = 'common.load.equipmentType.RemovableGooseneck',
  /** "Step deck" */ common_load_equipmentType_StepDeck = 'common.load.equipmentType.StepDeck',
  /** "Auto" */ common_load_equipmentType_Auto = 'common.load.equipmentType.Auto',
  /** "Animal carrier" */ common_load_equipmentType_AnimalCarrier = 'common.load.equipmentType.AnimalCarrier',
  /** "Boat hauler" */ common_load_equipmentType_BoatHauler = 'common.load.equipmentType.BoatHauler',
  /** "Heavy haulers" */ common_load_equipmentType_HeavyHaulers = 'common.load.equipmentType.HeavyHaulers',
  /** "Moving van" */ common_load_equipmentType_MovingVan = 'common.load.equipmentType.MovingVan',
  /** "Mini van" */ common_load_equipmentType_MiniVan = 'common.load.equipmentType.MiniVan',
  /** "Cargo van" */ common_load_equipmentType_CargoVan = 'common.load.equipmentType.CargoVan',
  /** "Straight box truck" */ common_load_equipmentType_BoxTruck = 'common.load.equipmentType.BoxTruck',
  /** "Sprinter" */ common_load_equipmentType_Sprinter = 'common.load.equipmentType.Sprinter',
  /** "Dump truck" */ common_load_equipmentType_DumpTruck = 'common.load.equipmentType.DumpTruck',
  /** "Pilot cars" */ common_load_equipmentType_PilotCars = 'common.load.equipmentType.PilotCars',
  /** "None" */ common_load_equipmentSpecification_None = 'common.load.equipmentSpecification.None',
  /** "Air ride" */ common_load_equipmentSpecification_AirRide = 'common.load.equipmentSpecification.AirRide',
  /** "Blanket wrap" */ common_load_equipmentSpecification_BlanketWrap = 'common.load.equipmentSpecification.BlanketWrap',
  /** "B-Train" */ common_load_equipmentSpecification_BTrain = 'common.load.equipmentSpecification.BTrain',
  /** "Chains" */ common_load_equipmentSpecification_Chains = 'common.load.equipmentSpecification.Chains',
  /** "Chassis" */ common_load_equipmentSpecification_Chassis = 'common.load.equipmentSpecification.Chassis',
  /** "Conestoga" */ common_load_equipmentSpecification_Conestoga = 'common.load.equipmentSpecification.Conestoga',
  /** "Curtains" */ common_load_equipmentSpecification_Curtains = 'common.load.equipmentSpecification.Curtains',
  /** "Double" */ common_load_equipmentSpecification_Double = 'common.load.equipmentSpecification.Double',
  /** "E-Track" */ common_load_equipmentSpecification_ETrack = 'common.load.equipmentSpecification.ETrack',
  /** "Extendable" */ common_load_equipmentSpecification_Extendable = 'common.load.equipmentSpecification.Extendable',
  /** "Hazmat" */ common_load_equipmentSpecification_HazMat = 'common.load.equipmentSpecification.HazMat',
  /** "Hot shot" */ common_load_equipmentSpecification_HotShot = 'common.load.equipmentSpecification.HotShot',
  /** "Insulated" */ common_load_equipmentSpecification_Insulated = 'common.load.equipmentSpecification.Insulated',
  /** "Intermodal" */ common_load_equipmentSpecification_Intermodal = 'common.load.equipmentSpecification.Intermodal',
  /** "Lift gate" */ common_load_equipmentSpecification_LiftGate = 'common.load.equipmentSpecification.LiftGate',
  /** "Load ramps" */ common_load_equipmentSpecification_LoadRamps = 'common.load.equipmentSpecification.LoadRamps',
  /** "Load out" */ common_load_equipmentSpecification_LoadOut = 'common.load.equipmentSpecification.LoadOut',
  /** "Moving" */ common_load_equipmentSpecification_Moving = 'common.load.equipmentSpecification.Moving',
  /** "Open top" */ common_load_equipmentSpecification_OpenTop = 'common.load.equipmentSpecification.OpenTop',
  /** "Oversized" */ common_load_equipmentSpecification_Oversized = 'common.load.equipmentSpecification.Oversized',
  /** "Pallet exchange" */ common_load_equipmentSpecification_PalletExchange = 'common.load.equipmentSpecification.PalletExchange',
  /** "Plate trailer" */ common_load_equipmentSpecification_PlateTrailer = 'common.load.equipmentSpecification.PlateTrailer',
  /** "Side kit" */ common_load_equipmentSpecification_SideKit = 'common.load.equipmentSpecification.SideKit',
  /** "Tarps" */ common_load_equipmentSpecification_Tarps = 'common.load.equipmentSpecification.Tarps',
  /** "Team driver" */ common_load_equipmentSpecification_TeamDriver = 'common.load.equipmentSpecification.TeamDriver',
  /** "Temp controlled" */ common_load_equipmentSpecification_TempControlled = 'common.load.equipmentSpecification.TempControlled',
  /** "Triple" */ common_load_equipmentSpecification_Triple = 'common.load.equipmentSpecification.Triple',
  /** "Vented" */ common_load_equipmentSpecification_Vented = 'common.load.equipmentSpecification.Vented',
  /** "Walking floor" */ common_load_equipmentSpecification_WalkingFloor = 'common.load.equipmentSpecification.WalkingFloor',
  /** "All" */ common_load_equipmentSpecification_All = 'common.load.equipmentSpecification.All',
  /** "Size" */ common_load_Size = 'common.load.Size',
  /** "Length" */ common_load_Length = 'common.load.Length',
  /** "Weight" */ common_load_Weight = 'common.load.Weight',
  /** "Distance" */ common_load_Distance = 'common.load.Distance',
  /** "Radius" */ common_load_Radius = 'common.load.Radius',
  /** "Age" */ common_load_Age = 'common.load.Age',
  /** "All" */ common_load_equipmentCode_All = 'common.load.equipmentCode.All',
  /** "V" */ common_load_equipmentCode_Van = 'common.load.equipmentCode.Van',
  /** "R" */ common_load_equipmentCode_Reefer = 'common.load.equipmentCode.Reefer',
  /** "PO" */ common_load_equipmentCode_PowerOnly = 'common.load.equipmentCode.PowerOnly',
  /** "C" */ common_load_equipmentCode_Container = 'common.load.equipmentCode.Container',
  /** "HB" */ common_load_equipmentCode_HopperBottom = 'common.load.equipmentCode.HopperBottom',
  /** "T" */ common_load_equipmentCode_Tanker = 'common.load.equipmentCode.Tanker',
  /** "DD" */ common_load_equipmentCode_DoubleDrop = 'common.load.equipmentCode.DoubleDrop',
  /** "F" */ common_load_equipmentCode_Flatbed = 'common.load.equipmentCode.Flatbed',
  /** "LA" */ common_load_equipmentCode_Landoll = 'common.load.equipmentCode.Landoll',
  /** "LB" */ common_load_equipmentCode_LowBoy = 'common.load.equipmentCode.LowBoy',
  /** "MX" */ common_load_equipmentCode_Maxi = 'common.load.equipmentCode.Maxi',
  /** "RG" */ common_load_equipmentCode_RemovableGooseneck = 'common.load.equipmentCode.RemovableGooseneck',
  /** "SD" */ common_load_equipmentCode_StepDeck = 'common.load.equipmentCode.StepDeck',
  /** "Auto" */ common_load_equipmentCode_Auto = 'common.load.equipmentCode.Auto',
  /** "AC" */ common_load_equipmentCode_AnimalCarrier = 'common.load.equipmentCode.AnimalCarrier',
  /** "BH" */ common_load_equipmentCode_BoatHauler = 'common.load.equipmentCode.BoatHauler',
  /** "HH" */ common_load_equipmentCode_HeavyHaulers = 'common.load.equipmentCode.HeavyHaulers',
  /** "MV" */ common_load_equipmentCode_MovingVan = 'common.load.equipmentCode.MovingVan',
  /** "MiniVan" */ common_load_equipmentCode_MiniVan = 'common.load.equipmentCode.MiniVan',
  /** "CV" */ common_load_equipmentCode_CargoVan = 'common.load.equipmentCode.CargoVan',
  /** "SB" */ common_load_equipmentCode_BoxTruck = 'common.load.equipmentCode.BoxTruck',
  /** "Sprinter" */ common_load_equipmentCode_Sprinter = 'common.load.equipmentCode.Sprinter',
  /** "DT" */ common_load_equipmentCode_DumpTruck = 'common.load.equipmentCode.DumpTruck',
  /** "PilotCars" */ common_load_equipmentCode_PilotCars = 'common.load.equipmentCode.PilotCars',
  /** "None" */ common_load_equipmentCode_None = 'common.load.equipmentCode.None',
  /** "None" */ common_load_equipmentSpecificationCode_None = 'common.load.equipmentSpecificationCode.None',
  /** "A" */ common_load_equipmentSpecificationCode_AirRide = 'common.load.equipmentSpecificationCode.AirRide',
  /** "B" */ common_load_equipmentSpecificationCode_BlanketWrap = 'common.load.equipmentSpecificationCode.BlanketWrap',
  /** "BT" */ common_load_equipmentSpecificationCode_BTrain = 'common.load.equipmentSpecificationCode.BTrain',
  /** "CH" */ common_load_equipmentSpecificationCode_Chains = 'common.load.equipmentSpecificationCode.Chains',
  /** "CS" */ common_load_equipmentSpecificationCode_Chassis = 'common.load.equipmentSpecificationCode.Chassis',
  /** "CO" */ common_load_equipmentSpecificationCode_Conestoga = 'common.load.equipmentSpecificationCode.Conestoga',
  /** "C" */ common_load_equipmentSpecificationCode_Curtains = 'common.load.equipmentSpecificationCode.Curtains',
  /** "2" */ common_load_equipmentSpecificationCode_Double = 'common.load.equipmentSpecificationCode.Double',
  /** "ET" */ common_load_equipmentSpecificationCode_ETrack = 'common.load.equipmentSpecificationCode.ETrack',
  /** "E" */ common_load_equipmentSpecificationCode_Extendable = 'common.load.equipmentSpecificationCode.Extendable',
  /** "Z" */ common_load_equipmentSpecificationCode_HazMat = 'common.load.equipmentSpecificationCode.HazMat',
  /** "HS" */ common_load_equipmentSpecificationCode_HotShot = 'common.load.equipmentSpecificationCode.HotShot',
  /** "I" */ common_load_equipmentSpecificationCode_Insulated = 'common.load.equipmentSpecificationCode.Insulated',
  /** "N" */ common_load_equipmentSpecificationCode_Intermodal = 'common.load.equipmentSpecificationCode.Intermodal',
  /** "LG" */ common_load_equipmentSpecificationCode_LiftGate = 'common.load.equipmentSpecificationCode.LiftGate',
  /** "LR" */ common_load_equipmentSpecificationCode_LoadRamps = 'common.load.equipmentSpecificationCode.LoadRamps',
  /** "LO" */ common_load_equipmentSpecificationCode_LoadOut = 'common.load.equipmentSpecificationCode.LoadOut',
  /** "MV" */ common_load_equipmentSpecificationCode_Moving = 'common.load.equipmentSpecificationCode.Moving',
  /** "OT" */ common_load_equipmentSpecificationCode_OpenTop = 'common.load.equipmentSpecificationCode.OpenTop',
  /** "O" */ common_load_equipmentSpecificationCode_Oversized = 'common.load.equipmentSpecificationCode.Oversized',
  /** "X" */ common_load_equipmentSpecificationCode_PalletExchange = 'common.load.equipmentSpecificationCode.PalletExchange',
  /** "PL" */ common_load_equipmentSpecificationCode_PlateTrailer = 'common.load.equipmentSpecificationCode.PlateTrailer',
  /** "S" */ common_load_equipmentSpecificationCode_SideKit = 'common.load.equipmentSpecificationCode.SideKit',
  /** "T" */ common_load_equipmentSpecificationCode_Tarps = 'common.load.equipmentSpecificationCode.Tarps',
  /** "M" */ common_load_equipmentSpecificationCode_TeamDriver = 'common.load.equipmentSpecificationCode.TeamDriver',
  /** "TC" */ common_load_equipmentSpecificationCode_TempControlled = 'common.load.equipmentSpecificationCode.TempControlled',
  /** "3" */ common_load_equipmentSpecificationCode_Triple = 'common.load.equipmentSpecificationCode.Triple',
  /** "V" */ common_load_equipmentSpecificationCode_Vented = 'common.load.equipmentSpecificationCode.Vented',
  /** "WF" */ common_load_equipmentSpecificationCode_WalkingFloor = 'common.load.equipmentSpecificationCode.WalkingFloor',
  /** "All" */ common_load_equipmentSpecificationCode_All = 'common.load.equipmentSpecificationCode.All',
  /** "Onboarded companies" */ common_load_filter_onboardedCompanies = 'common.load.filter.onboardedCompanies',
  /** "Company preferences" */ common_load_filter_companyPreferences = 'common.load.filter.companyPreferences',
  /** "Favorite companies" */ common_load_filter_favoriteCompanies = 'common.load.filter.favoriteCompanies',
  /** "Credit rating (Transcredit)" */ common_load_filter_creditRating = 'common.load.filter.creditRating',
  /** "Days-to-pay (Transcredit)" */ common_load_filter_daysToPay = 'common.load.filter.daysToPay',
  /** "Credit rating" */ common_load_filter_creditRatingShort = 'common.load.filter.creditRatingShort',
  /** "Days-to-pay" */ common_load_filter_daysToPayShort = 'common.load.filter.daysToPayShort',
  /** "Shipper" */ common_load_filter_shipper = 'common.load.filter.shipper',
  /** "Load info" */ common_load_filter_loadInfo = 'common.load.filter.loadInfo',
  /** "Posted age" */ common_load_filter_postedAge = 'common.load.filter.postedAge',
  /** "With messaging" */ common_load_filter_withMessaging = 'common.load.filter.withMessaging',
  /** "With bidding" */ common_load_filter_withBidding = 'common.load.filter.withBidding',
  /** "Trailer specifications" */ common_load_filter_trailerSpecifications = 'common.load.filter.trailerSpecifications',
  /** "Commodity" */ common_load_filter_commodity = 'common.load.filter.commodity',
  /** "Posted rate" */ common_load_filter_postedRate = 'common.load.filter.postedRate',
  /** "Team drivers" */ common_load_filter_teamDrivers = 'common.load.filter.teamDrivers',
  /** "Load size" */ common_load_filter_loadSize = 'common.load.filter.loadSize',
  /** "TL" */ common_load_filter_tl = 'common.load.filter.tl',
  /** "LTL" */ common_load_filter_ltl = 'common.load.filter.ltl',
  /** "With weight" */ common_load_filter_withWeight = 'common.load.filter.withWeight',
  /** "With length" */ common_load_filter_withLength = 'common.load.filter.withLength',
  /** "Distance" */ common_load_filter_distance = 'common.load.filter.distance',
  /** "Trip length" */ common_load_filter_tripLength = 'common.load.filter.tripLength',
  /** "Trailer specs" */ common_load_filter_trailerSpecs = 'common.load.filter.trailerSpecs',
  /** "{{quantity}} result" */ common_load_filter_singleResult = 'common.load.filter.singleResult',
  /** "{{quantity}} results" */ common_load_filter_totalResult = 'common.load.filter.totalResult',
  /** "Select credit rating range" */ common_creditRating_selectCreditRating = 'common.creditRating.selectCreditRating',
  /** "Please select a range of credit scores from 0 to 100." */ common_creditRating_pleaseSelect = 'common.creditRating.pleaseSelect',
  /** "Select Days-to-pay range" */ common_creditRating_selectDaysToPay = 'common.creditRating.selectDaysToPay',
  /** "Please select your preferred range of days-to-pay from 0 to 65+." */ common_creditRating_pleaseSelectDaysToPay = 'common.creditRating.pleaseSelectDaysToPay',
  /** "Please select the trip length" */ common_tripLength_pleaseSelectTriplength = 'common.tripLength.pleaseSelectTriplength',
  /** "Select posted age range" */ common_postedAge_selectPostedAge = 'common.postedAge.selectPostedAge',
  /** "Please select the posted age of loads you want to see." */ common_postedAge_pleaseSelect = 'common.postedAge.pleaseSelect',
  /** "Load documents" */ common_loadDetails_loadDocuments_title = 'common.loadDetails.loadDocuments.title',
  /** "{{num}} files attached" */ common_loadDetails_loadDocuments_filesAttached = 'common.loadDetails.loadDocuments.filesAttached',
  /** "1 file attached" */ common_loadDetails_loadDocuments_oneFileAttached = 'common.loadDetails.loadDocuments.oneFileAttached',
  /** "Attach rate confirmation, BOL or other documents to this load for your personal reference and/or to send to the poster, Thunder Funding, factoring companies, etc." */ common_loadDetails_loadDocuments_description = 'common.loadDetails.loadDocuments.description',
  /** "Manage documents" */ common_loadDetails_loadDocuments_manageDocuments = 'common.loadDetails.loadDocuments.manageDocuments',
  /** "Update status" */ common_loadDetails_updateStatus = 'common.loadDetails.updateStatus',
  /** "Status" */ common_loadDetails_status = 'common.loadDetails.status',
  /** "Manage documents for this load" */ common_loadDetails_manageDocumentsForThisLoad = 'common.loadDetails.manageDocumentsForThisLoad',
  /** "Rate confirmation, BOL, etc." */ common_loadDetails_rateConfirmationBolEtc = 'common.loadDetails.rateConfirmationBolEtc',
  /** "Did you book this load?" */ common_loadDetails_didYouBook_title = 'common.loadDetails.didYouBook.title',
  /** "If so, click yes, and start uploading your documents." */ common_loadDetails_didYouBook_info = 'common.loadDetails.didYouBook.info',
  /** "Alert sent {{time}}" */ common_loadDetails_truckAlert_alertSent = 'common.loadDetails.truckAlert.alertSent',
  /** "Get onboarded" */ common_loadDetails_onboarding_title = 'common.loadDetails.onboarding.title',
  /** "Start onboarding" */ common_loadDetails_onboarding_start = 'common.loadDetails.onboarding.start',
  /** "Get onboarded with " */ common_loadDetails_onboarding_getOnboarded_start = 'common.loadDetails.onboarding.getOnboarded.start',
  /** " to increase your chance of booking this load. You may start the onboarding process at any time." */ common_loadDetails_onboarding_getOnboarded_end = 'common.loadDetails.onboarding.getOnboarded.end',
  /** "Get onboarded" */ common_loadDetails_onboarding_popup_title = 'common.loadDetails.onboarding.popup.title',
  /** "Continuing will redirect you to a new website to get onboarded with " */ common_loadDetails_onboarding_popup_message_start = 'common.loadDetails.onboarding.popup.message.start',
  /** ". This will open a new tab where you will continue with the onboarding process.\n\nPlease note the terms and conditions on this site may differ from 123Loadboard. 123Loadboard is not responsible for any content on third party websites." */ common_loadDetails_onboarding_popup_message_end = 'common.loadDetails.onboarding.popup.message.end',
  /** "Proceed" */ common_loadDetails_onboarding_popup_proceed = 'common.loadDetails.onboarding.popup.proceed',
  /** "Bids" */ common_bids_title = 'common.bids.title',
  /** "Interactive bids" */ common_bids_titleInteractive = 'common.bids.titleInteractive',
  /** "About bids" */ common_bids_infoDialog_menuOption = 'common.bids.infoDialog.menuOption',
  /** "About Bids" */ common_bids_infoDialog_title = 'common.bids.infoDialog.title',
  /** "The broker/shipper will respond to your bid via Call, Messaging, or Email. Your bidding history will be available for 2 weeks." */ common_bids_infoDialog_message_vendor = 'common.bids.infoDialog.message.vendor',
  /** "The broker/shipper will respond to your interactive bid here. Load posters will have the option to Accept or Reject your bid offer and may send counteroffers." */ common_bids_infoDialog_message_interactiveOnly = 'common.bids.infoDialog.message.interactiveOnly',
  /** "Depending on the load, bidding on loads will either be interactive or not:\n\nBids: " */ common_bids_infoDialog_message_introBothActive = 'common.bids.infoDialog.message.introBothActive',
  /** "\n\nInteractive bids: The broker/shipper will respond to your Bid within the “Interactive bids” section of the Bids tool. Load posters will have the option to Accept or Reject your bid offer and may send counteroffers." */ common_bids_infoDialog_message_interactiveBothActive = 'common.bids.infoDialog.message.interactiveBothActive',
  /** "Mark all bids as viewed" */ common_bids_markAllAsViewed_title = 'common.bids.markAllAsViewed.title',
  /** "All unviewed bids have been marked as viewed." */ common_bids_markAllAsViewed_success = 'common.bids.markAllAsViewed.success',
  /** "Failed to mark all unviewed bids as viewed." */ common_bids_markAllAsViewed_failure = 'common.bids.markAllAsViewed.failure',
  /** "Action required" */ common_bids_status_actionRequired = 'common.bids.status.actionRequired',
  /** "Confirmed" */ common_bids_status_confirmed = 'common.bids.status.confirmed',
  /** "Assigned" */ common_bids_status_assigned = 'common.bids.status.assigned',
  /** "Pending" */ common_bids_status_pending = 'common.bids.status.pending',
  /** "Rejected" */ common_bids_status_rejected = 'common.bids.status.rejected',
  /** "Withdrawn" */ common_bids_status_withdrawn = 'common.bids.status.withdrawn',
  /** "Unknown" */ common_bids_status_unknown = 'common.bids.status.unknown',
  /** "Accepted" */ common_bids_status_accepted = 'common.bids.status.accepted',
  /** "Expiration time" */ common_bids_expirationTime_title = 'common.bids.expirationTime.title',
  /** "Expiration time *" */ common_bids_expirationTime_label = 'common.bids.expirationTime.label',
  /** "Select expiration time, after which the bid will be automatically withdrawn." */ common_bids_expirationTime_message = 'common.bids.expirationTime.message',
  /** "Expiration time is required." */ common_bids_expirationTime_requiredError = 'common.bids.expirationTime.requiredError',
  /** "Bid offer will be withdrawn in" */ common_bids_expirationTime_bannerText = 'common.bids.expirationTime.bannerText',
  /** "Place bid" */ common_bids_placeBid_title = 'common.bids.placeBid.title',
  /** "Before you bid, please ensure that you meet these requirements." */ common_bids_placeBid_message = 'common.bids.placeBid.message',
  /** "Enter your bid amount below." */ common_bids_placeBid_enterBid = 'common.bids.placeBid.enterBid',
  /** "Edit offer" */ common_bids_editBid_title = 'common.bids.editBid.title',
  /** "Previous offer was placed on {{date}} for {{amount}}.\n\nIn order to edit this offer, fill in the form below." */ common_bids_editBid_message = 'common.bids.editBid.message',
  /** "Counteroffer" */ common_bids_placeCounterOffer_title = 'common.bids.placeCounterOffer.title',
  /** "The carrier placed a bid for {{amount}} on {{date}}.\n\nYou may submit your counteroffer by filling in the form below." */ common_bids_placeCounterOffer_message = 'common.bids.placeCounterOffer.message',
  /** "The load poster placed a bid for {{amount}} on {{date}}.\n\nYou may submit your counteroffer by filling in the form below." */ common_bids_placeCounterOffer_messageCarrier = 'common.bids.placeCounterOffer.messageCarrier',
  /** "Verify your DOT#" */ common_bids_dotVerification_popup_title = 'common.bids.dotVerification.popup.title',
  /** "Only users with a verified DOT# can place bids." */ common_bids_dotVerification_popup_content = 'common.bids.dotVerification.popup.content',
  /** "Verify now" */ common_bids_dotVerification_popup_button = 'common.bids.dotVerification.popup.button',
  /** "No bids placed" */ common_bids_empty_title = 'common.bids.empty.title',
  /** "When carriers submit bids, they will appear here." */ common_bids_empty_message_broker = 'common.bids.empty.message.broker',
  /** "When you place bids on loads with Interactive Bidding enabled, they will appear here." */ common_bids_empty_message_carrier = 'common.bids.empty.message.carrier',
  /** "Flat rate is too low." */ common_bids_validation_tooLow = 'common.bids.validation.tooLow',
  /** "New offer must be different value than previous offer." */ common_bids_validation_sameValue = 'common.bids.validation.sameValue',
  /** "Bidding summary" */ common_bids_biddingSummary_title = 'common.bids.biddingSummary.title',
  /** "Bid summary" */ common_bids_summary_title = 'common.bids.summary.title',
  /** "View load details" */ common_bids_summary_menu_viewLoadDetails = 'common.bids.summary.menu.viewLoadDetails',
  /** "When your bid has gotten a response, you can accept and confirm your availability." */ common_bids_summary_bannerMessage_carrier_placedBid = 'common.bids.summary.bannerMessage.carrier.placedBid',
  /** "Thank you for confirming your availability." */ common_bids_summary_bannerMessage_carrier_confirmed = 'common.bids.summary.bannerMessage.carrier.confirmed',
  /** "For personal reference, this load has been marked as “Assigned” by the load poster." */ common_bids_summary_bannerMessage_carrier_assigned = 'common.bids.summary.bannerMessage.carrier.assigned',
  /** "When carriers bid on this load, you can accept, reject, or provide a counteroffer." */ common_bids_summary_bannerMessage_broker_message = 'common.bids.summary.bannerMessage.broker.message',
  /** "other party" */ common_bids_summary_otherParty = 'common.bids.summary.otherParty',
  /** "Waiting for {{broker}} to accept or reject your {{amount}} bid." */ common_bids_summary_messages_sentOffer = 'common.bids.summary.messages.sentOffer',
  /** "{{broker}} has accepted your {{amount}} bid. Confirm your availability to haul this load." */ common_bids_summary_messages_sentOfferAccepted = 'common.bids.summary.messages.sentOfferAccepted',
  /** "{{broker}} has rejected your {{amount}} offer." */ common_bids_summary_messages_sentOfferRejected = 'common.bids.summary.messages.sentOfferRejected',
  /** "You have withdrawn your {{amount}} offer to {{broker}}" */ common_bids_summary_messages_sentOfferWithdrawn = 'common.bids.summary.messages.sentOfferWithdrawn',
  /** "{{broker}} has withdrawn their {{amount}} offer." */ common_bids_summary_messages_sentOfferWithdrawnByBroker = 'common.bids.summary.messages.sentOfferWithdrawnByBroker',
  /** "Waiting for {{broker}} to confirm." */ common_bids_summary_messages_receivedOfferAccepted = 'common.bids.summary.messages.receivedOfferAccepted',
  /** "You have rejected the {{amount}} offer by {{broker}}." */ common_bids_summary_messages_receivedOfferRejected = 'common.bids.summary.messages.receivedOfferRejected',
  /** "{{broker}} has counteroffered {{amount}}. Please review and respond." */ common_bids_summary_messages_receivedCounterOffer = 'common.bids.summary.messages.receivedCounterOffer',
  /** "You have confirmed your availability to haul {{broker}}'s load for {{amount}}." */ common_bids_summary_messages_carrier_confirmed = 'common.bids.summary.messages.carrier.confirmed',
  /** "{{broker}} has assigned this load to you for {{amount}}" */ common_bids_summary_messages_carrier_assigned = 'common.bids.summary.messages.carrier.assigned',
  /** "To complete this booking, please send the Rate Confirmation to this carrier." */ common_bids_summary_messages_broker_sendConfirmation = 'common.bids.summary.messages.broker.sendConfirmation',
  /** "{{broker}} has accepted your {{amount}} offer." */ common_bids_summary_messages_accepted = 'common.bids.summary.messages.accepted',
  /** "History" */ common_bids_summary_history = 'common.bids.summary.history',
  /** "Your Offer" */ common_bids_bidItem_titles_offer = 'common.bids.bidItem.titles.offer',
  /** "Load poster's counteroffer" */ common_bids_bidItem_titles_counterOffer = 'common.bids.bidItem.titles.counterOffer',
  /** "Load available" */ common_bids_bidItem_titles_available = 'common.bids.bidItem.titles.available',
  /** "Your Offer" */ common_bids_bidItem_titles_yourOffer = 'common.bids.bidItem.titles.yourOffer',
  /** "Your counteroffer" */ common_bids_bidItem_titles_yourCounteroffer = 'common.bids.bidItem.titles.yourCounteroffer',
  /** "Carrier's offer" */ common_bids_bidItem_titles_carrierOffer = 'common.bids.bidItem.titles.carrierOffer',
  /** "Load poster counter offer" */ common_bids_bidItem_titles_loadPostCounteroffer = 'common.bids.bidItem.titles.loadPostCounteroffer',
  /** "Could not load" */ common_bids_bidItem_failedToUpdate = 'common.bids.bidItem.failedToUpdate',
  /** "Place bid" */ common_bids_bidItem_actions_placeBid = 'common.bids.bidItem.actions.placeBid',
  /** "Confirm" */ common_bids_bidItem_actions_confirm = 'common.bids.bidItem.actions.confirm',
  /** "Accept" */ common_bids_bidItem_actions_accept = 'common.bids.bidItem.actions.accept',
  /** "Assign" */ common_bids_bidItem_actions_assign = 'common.bids.bidItem.actions.assign',
  /** "Counteroffer" */ common_bids_bidItem_actions_counter = 'common.bids.bidItem.actions.counter',
  /** "Reject" */ common_bids_bidItem_actions_reject = 'common.bids.bidItem.actions.reject',
  /** "Edit" */ common_bids_bidItem_actions_edit = 'common.bids.bidItem.actions.edit',
  /** "Cancel" */ common_bids_bidItem_actions_cancel = 'common.bids.bidItem.actions.cancel',
  /** "Waiting for load poster to respond to your offer.\n\nYou may edit your bid if necessary. Editing your bid will cause your previous bid to be withdrawn.\n\nIf for any reason you need to cancel this bid offer, please click “Cancel”." */ common_bids_bidItem_messages_sentOffer = 'common.bids.bidItem.messages.sentOffer',
  /** "Please respond to this offer. You may accept, submit a counteroffer, or reject." */ common_bids_bidItem_messages_receivedOffer = 'common.bids.bidItem.messages.receivedOffer',
  /** "{{broker}} has offered {{amount}}. You may accept, submit a counteroffer, or reject." */ common_bids_bidItem_messages_carrier_receivedOffer = 'common.bids.bidItem.messages.carrier.receivedOffer',
  /** "This load is available for you to place a bid." */ common_bids_bidItem_messages_carrier_available = 'common.bids.bidItem.messages.carrier.available',
  /** "{{broker}} has accepted your {{amount}} bid. Confirm your availability to haul this load." */ common_bids_bidItem_messages_carrier_sentOfferAccepted = 'common.bids.bidItem.messages.carrier.sentOfferAccepted',
  /** "Waiting for the load poster to confirm that the load is still available.\n\nIf for any reason you need to cancel this bid offer, please click “Cancel”." */ common_bids_bidItem_messages_carrier_receivedOfferAccepted = 'common.bids.bidItem.messages.carrier.receivedOfferAccepted',
  /** "You will be contacted by the load poster to complete the booking process." */ common_bids_bidItem_messages_carrier_confirmed_part1 = 'common.bids.bidItem.messages.carrier.confirmed.part1',
  /** "Note: Loads are time-sensitive, so please respond as quickly as possible. Otherwise, you may not be able to haul this load." */ common_bids_bidItem_messages_carrier_confirmed_part2 = 'common.bids.bidItem.messages.carrier.confirmed.part2',
  /** "Drive safely!" */ common_bids_bidItem_messages_carrier_assigned = 'common.bids.bidItem.messages.carrier.assigned',
  /** "Waiting for the carrier to confirm your counteroffer.\n\nYou may edit your counteroffer if necessary. Editing will cause your previous counteroffer to be withdrawn." */ common_bids_bidItem_messages_broker_sentCounteroffer = 'common.bids.bidItem.messages.broker.sentCounteroffer',
  /** "Your counteroffer was accepted by the carrier. Please confirm that the load is still available." */ common_bids_bidItem_messages_broker_sentOfferAccepted = 'common.bids.bidItem.messages.broker.sentOfferAccepted',
  /** "Waiting for {{otherParty}} to confirm their availability." */ common_bids_bidItem_messages_broker_receivedOfferAcceptedListCard = 'common.bids.bidItem.messages.broker.receivedOfferAcceptedListCard',
  /** "Waiting for confirmation from the carrier. Once the carrier has confirmed their availability, you can contact the carrier and complete this booking through your normal procedures.\n\nIf for any reason you need to cancel this bid offer, please click “Cancel”." */ common_bids_bidItem_messages_broker_receivedOfferAccepted = 'common.bids.bidItem.messages.broker.receivedOfferAccepted',
  /** "To complete this booking, contact the carrier through your normal procedures.\n\nYou may also send any attachments to this carrier at:" */ common_bids_bidItem_messages_broker_confirmed = 'common.bids.bidItem.messages.broker.confirmed',
  /** "For your own reference, please check the box when you have received the signed Rate Confirmation." */ common_bids_bidItem_messages_broker_assigned = 'common.bids.bidItem.messages.broker.assigned',
  /** "View" */ common_bids_buttons_view = 'common.bids.buttons.view',
  /** "Bid" */ common_bids_buttons_bid = 'common.bids.buttons.bid',
  /** "Failed to place bid" */ common_bids_error_failedToPlaceBid = 'common.bids.error.failedToPlaceBid',
  /** "An error occurred while sending a bid to the company." */ common_bids_error_sendingFailed = 'common.bids.error.sendingFailed',
  /** "There was an error processing this bid. Updating the app may resolve this issue." */ common_bids_error_statusUnknown = 'common.bids.error.statusUnknown',
  /** "Something went wrong. Please reload the page and try again." */ common_bids_error_somethingWentWrong = 'common.bids.error.somethingWentWrong',
  /** "Start receiving bids from carriers on your posted loads. Activating bidding will allow you to Accept, Reject, or Counteroffer any bids placed on your loads.\n\n Call " */ common_bids_disabled_message = 'common.bids.disabled.message',
  /** " to activate this free feature." */ common_bids_disabled_activate = 'common.bids.disabled.activate',
  /** "877-875-5301 ex 2" */ common_bids_disabled_phoneNumber = 'common.bids.disabled.phoneNumber',
  /** "Filtering may reduce results" */ common_bids_filterMessage = 'common.bids.filterMessage',
  /** "Please select to filter your results." */ common_bids_filterSubtitle = 'common.bids.filterSubtitle',
  /** "No matches" */ common_bids_noMatches_title = 'common.bids.noMatches.title',
  /** "Please refine your filter criteria" */ common_bids_noMatches_subtitle = 'common.bids.noMatches.subtitle',
  /** "Company" */ common_truck_Company = 'common.truck.Company',
  /** "Deadhead" */ common_truck_Deadhead_Short = 'common.truck.Deadhead_Short',
  /** "Deadhead Miles" */ common_truck_Deadhead_Long = 'common.truck.Deadhead_Long',
  /** "MC# / USDOT" */ common_truck_MC_USDOT = 'common.truck.MC_USDOT',
  /** "Authority (MC#)" */ common_truck_Authority = 'common.truck.Authority',
  /** "MCS-150 form date" */ common_truck_MCS150 = 'common.truck.MCS150',
  /** "MCS-150 form date refers to the last time this carrier modified their business data with the FMCSA. This date is based on the most recently submitted MCS-150 form." */ common_truck_MCS150_Popup = 'common.truck.MCS150_Popup',
  /** "Areas of operation based on this carrier’s load searches." */ common_truck_Carrier_History_Map = 'common.truck.Carrier_History_Map',
  /** "Next available" */ common_truck_Next_Available = 'common.truck.Next_Available',
  /** "Carrier" */ common_truck_Carrier = 'common.truck.Carrier',
  /** "Equipment" */ common_truck_Equipment = 'common.truck.Equipment',
  /** "Size" */ common_truck_Size = 'common.truck.Size',
  /** "Available Capacity" */ common_truck_Available_Capacity = 'common.truck.Available_Capacity',
  /** "Insurance (USD)" */ common_truck_Insurance = 'common.truck.Insurance',
  /** "Auth. insurance" */ common_truck_Auth_Insurance_Short = 'common.truck.Auth_Insurance_Short',
  /** "Auth. issuance" */ common_truck_Auth_Issuance_Short = 'common.truck.Auth_Issuance_Short',
  /** "Authority insurance" */ common_truck_Auth_Insurance_Long = 'common.truck.Auth_Insurance_Long',
  /** "Authority issuance" */ common_truck_Auth_Issuance_Long = 'common.truck.Auth_Issuance_Long',
  /** "Telephone number" */ common_truck_Phone_Number = 'common.truck.Phone_Number',
  /** "Alert sent" */ common_truck_Alert_Sent = 'common.truck.Alert_Sent',
  /** "Desired rate" */ common_truck_desiredRate = 'common.truck.desiredRate',
  /** "Today" */ common_truck_Now = 'common.truck.Now',
  /** "{{min}} ft - {{max}} ft" */ common_truck_spec_length = 'common.truck.spec.length',
  /** "{{min}} lbs - {{max}} lbs" */ common_truck_spec_weight = 'common.truck.spec.weight',
  /** "{{min}} lb - {{max}} lbs" */ common_truck_spec_small_weight = 'common.truck.spec.small_weight',
  /** "Post truck" */ common_postTruck_title = 'common.postTruck.title',
  /** "Post your truck and brokers/shippers will contact you with offers to haul their loads." */ common_postTruck_postTruckText = 'common.postTruck.postTruckText',
  /** "Find loads" */ common_postTruck_findLoadsLink = 'common.postTruck.findLoadsLink',
  /** "Posted trucks" */ common_postTruck_sectionTitle = 'common.postTruck.sectionTitle',
  /** "Post your truck so brokers and shippers with available loads can contact you with offers to haul their freight." */ common_postTruck_infoDialogContent = 'common.postTruck.infoDialogContent',
  /** "Real-time truck post" */ common_postTruck_truckPost_realTimePosts = 'common.postTruck.truckPost.realTimePosts',
  /** "Scheduled truck posts" */ common_postTruck_truckPost_scheduledPosts = 'common.postTruck.truckPost.scheduledPosts',
  /** "Expired truck posts" */ common_postTruck_truckPost_expiredPosts = 'common.postTruck.truckPost.expiredPosts',
  /** "Create new post" */ common_postTruck_truckPost_createNewPost = 'common.postTruck.truckPost.createNewPost',
  /** "Total trip: " */ common_postTruck_pcMiler_totalTripText = 'common.postTruck.pcMiler.totalTripText',
  /** "Average rate by rate check: " */ common_postTruck_avgRate_avgRateText = 'common.postTruck.avgRate.avgRateText',
  /** "{{value}}/mi" */ common_postTruck_avgRate_avgRateLabel = 'common.postTruck.avgRate.avgRateLabel',
  /** "Desired rate per mile" */ common_postTruck_minRate_title = 'common.postTruck.minRate.title',
  /** "/mile($)" */ common_postTruck_minRate_inputLabel = 'common.postTruck.minRate.inputLabel',
  /** "If you have a preferred rate-per-mile, you may receive more relevant load alerts sent by brokers and shippers by posting your truck with a desired rate." */ common_postTruck_minRate_info = 'common.postTruck.minRate.info',
  /** "This field cannot be over ${{max}}" */ common_postTruck_minRate_maxError = 'common.postTruck.minRate.maxError',
  /** "Additional info" */ common_postTruck_additionalInfo_title = 'common.postTruck.additionalInfo.title',
  /** "Enter any additional information or special instructions relating to the load that aren’t covered by the other fields." */ common_postTruck_additionalInfo_info = 'common.postTruck.additionalInfo.info',
  /** "Add a note" */ common_postTruck_additionalInfo_inputLabel = 'common.postTruck.additionalInfo.inputLabel',
  /** "Additional info from carrier" */ common_postTruck_additionalInfo_previewDescription = 'common.postTruck.additionalInfo.previewDescription',
  /** "Team driven" */ common_postTruck_teamDriven = 'common.postTruck.teamDriven',
  /** "My truck" */ common_postTruck_postForm_myTuck = 'common.postTruck.postForm.myTuck',
  /** "Available capacity" */ common_postTruck_postForm_availibleCapacity = 'common.postTruck.postForm.availibleCapacity',
  /** "Post truck" */ common_postTruck_postForm_post = 'common.postTruck.postForm.post',
  /** "Anonymous" */ common_postTruck_AnonymousPost_header = 'common.postTruck.AnonymousPost.header',
  /** "years" */ common_postTruck_AnonymousPost_years = 'common.postTruck.AnonymousPost.years',
  /** "<1 years" */ common_postTruck_AnonymousPost_lessThanOneYear = 'common.postTruck.AnonymousPost.lessThanOneYear',
  /** "Post anonymously" */ common_postTruck_AnonymousPost_AnonymousPopup_title = 'common.postTruck.AnonymousPost.AnonymousPopup.title',
  /** "Posting your truck anonymously reduces the amount of potentially unwanted calls and gives you the ability to act on the loads you want to haul." */ common_postTruck_AnonymousPost_AnonymousPopup_paragraph = 'common.postTruck.AnonymousPost.AnonymousPopup.paragraph',
  /** "The following details will be hidden from brokers and shippers:" */ common_postTruck_AnonymousPost_AnonymousPopup_listTitle = 'common.postTruck.AnonymousPost.AnonymousPopup.listTitle',
  /** "your name" */ common_postTruck_AnonymousPost_AnonymousPopup_listItemOne = 'common.postTruck.AnonymousPost.AnonymousPopup.listItemOne',
  /** "your company name" */ common_postTruck_AnonymousPost_AnonymousPopup_listItemTwo = 'common.postTruck.AnonymousPost.AnonymousPopup.listItemTwo',
  /** "MC# & DOT#" */ common_postTruck_AnonymousPost_AnonymousPopup_listItemThree = 'common.postTruck.AnonymousPost.AnonymousPopup.listItemThree',
  /** "your phone & email" */ common_postTruck_AnonymousPost_AnonymousPopup_listItemFour = 'common.postTruck.AnonymousPost.AnonymousPopup.listItemFour',
  /** "Real-time truck post" */ common_postTruck_noPostedTrucksScreen_realTime_title = 'common.postTruck.noPostedTrucksScreen.realTime.title',
  /** "Create a real-time truck post so you can receive load offers wherever you are." */ common_postTruck_noPostedTrucksScreen_realTime_subtitle = 'common.postTruck.noPostedTrucksScreen.realTime.subtitle',
  /** "Real-time truck posting is available on our mobile app only. Download our mobile app and assign this truck to your mobile device to activate Real-time truck posting." */ common_postTruck_noPostedTrucksScreen_realTime_goToApp = 'common.postTruck.noPostedTrucksScreen.realTime.goToApp',
  /** "Scheduled truck posts" */ common_postTruck_noPostedTrucksScreen_scheduled_title = 'common.postTruck.noPostedTrucksScreen.scheduled.title',
  /** "Create a scheduled truck post when you know you’re going to be somewhere and would like to receive load offers ahead of time." */ common_postTruck_noPostedTrucksScreen_scheduled_subtitle = 'common.postTruck.noPostedTrucksScreen.scheduled.subtitle',
  /** "Expired truck posts" */ common_postTruck_noPostedTrucksScreen_expired_title = 'common.postTruck.noPostedTrucksScreen.expired.title',
  /** "Truck posts that are past their posted date will appear here until their dates are changed or they are deleted." */ common_postTruck_noPostedTrucksScreen_expired_subtitle = 'common.postTruck.noPostedTrucksScreen.expired.subtitle',
  /** "New truck" */ common_postTruck_myTruck_newTruck = 'common.postTruck.myTruck.newTruck',
  /** "Add truck" */ common_postTruck_myTruck_addTruck = 'common.postTruck.myTruck.addTruck',
  /** "Edit truck" */ common_postTruck_myTruck_editTruck = 'common.postTruck.myTruck.editTruck',
  /** "Add new truck" */ common_postTruck_myTruck_addNewTruck = 'common.postTruck.myTruck.addNewTruck',
  /** "You currently have no trucks on your account. To begin posting your truck, please add one." */ common_postTruck_myTruck_noTrucksText = 'common.postTruck.myTruck.noTrucksText',
  /** "Truck specifications" */ common_postTruck_myTruck_truckSpacification = 'common.postTruck.myTruck.truckSpacification',
  /** "Truck name" */ common_postTruck_myTruck_truckName = 'common.postTruck.myTruck.truckName',
  /** "{{length}} lbs, {{weight}} ft" */ common_postTruck_myTruck_spec_cardSpec = 'common.postTruck.myTruck.spec.cardSpec',
  /** "{{type}} ({{name}})" */ common_postTruck_myTruck_spec_cardName = 'common.postTruck.myTruck.spec.cardName',
  /** "There was an error updating your truck specifications" */ common_postTruck_myTruck_toast_updateError = 'common.postTruck.myTruck.toast.updateError',
  /** "Truck specifications saved." */ common_postTruck_myTruck_toast_updateSuccess = 'common.postTruck.myTruck.toast.updateSuccess',
  /** "There was an error deleting your truck" */ common_postTruck_myTruck_toast_deleteError = 'common.postTruck.myTruck.toast.deleteError',
  /** "Truck deleted." */ common_postTruck_myTruck_toast_deleteSuccess = 'common.postTruck.myTruck.toast.deleteSuccess',
  /** "Truck was added successfully" */ common_postTruck_myTruck_toast_savedSuccess = 'common.postTruck.myTruck.toast.savedSuccess',
  /** "There was an error adding your truck" */ common_postTruck_myTruck_toast_savedError = 'common.postTruck.myTruck.toast.savedError',
  /** "Please choose a different name, the truck name you choose is already assigned to another truck." */ common_postTruck_myTruck_toast_nameError = 'common.postTruck.myTruck.toast.nameError',
  /** "Post updated" */ common_postTruck_snackBar_updated = 'common.postTruck.snackBar.updated',
  /** "Post failed to update" */ common_postTruck_snackBar_failedToUpdate = 'common.postTruck.snackBar.failedToUpdate',
  /** "Post deleted" */ common_postTruck_snackBar_postDeleted = 'common.postTruck.snackBar.postDeleted',
  /** "Post failed to delete" */ common_postTruck_snackBar_failedToDelete = 'common.postTruck.snackBar.failedToDelete',
  /** "Post saved" */ common_postTruck_snackBar_saved = 'common.postTruck.snackBar.saved',
  /** "Post failed to save" */ common_postTruck_snackBar_failedToSave = 'common.postTruck.snackBar.failedToSave',
  /** "All expired were deleted successfully" */ common_postTruck_snackBar_expiredPostsDeleted = 'common.postTruck.snackBar.expiredPostsDeleted',
  /** "Failed to delete expired trucks" */ common_postTruck_snackBar_expiredPostsFailedToDelete = 'common.postTruck.snackBar.expiredPostsFailedToDelete',
  /** "Truck in use" */ common_postTruck_popUps_truckInUseRealTime_title = 'common.postTruck.popUps.truckInUseRealTime.title',
  /** "This truck is already associated with another device. You can create a new truck post that will not affect other devices." */ common_postTruck_popUps_truckInUseRealTime_body1 = 'common.postTruck.popUps.truckInUseRealTime.body1',
  /** "If you post this truck, it will be associated to this device instead." */ common_postTruck_popUps_truckInUseRealTime_body2 = 'common.postTruck.popUps.truckInUseRealTime.body2',
  /** "Post" */ common_postTruck_popUps_truckInUseRealTime_button = 'common.postTruck.popUps.truckInUseRealTime.button',
  /** "Truck in use" */ common_postTruck_popUps_truckInUseDelete_title = 'common.postTruck.popUps.truckInUseDelete.title',
  /** "This truck has already been posted. Deleting this truck will also delete all postings it is associated with." */ common_postTruck_popUps_truckInUseDelete_body = 'common.postTruck.popUps.truckInUseDelete.body',
  /** "Delete" */ common_postTruck_popUps_truckInUseDelete_button = 'common.postTruck.popUps.truckInUseDelete.button',
  /** "Delete truck" */ common_postTruck_popUps_deleteTruck_title = 'common.postTruck.popUps.deleteTruck.title',
  /** "Are you sure you would like to delete the selected truck?" */ common_postTruck_popUps_deleteTruck_body = 'common.postTruck.popUps.deleteTruck.body',
  /** "Delete" */ common_postTruck_popUps_deleteTruck_button = 'common.postTruck.popUps.deleteTruck.button',
  /** "Delete truck post" */ common_postTruck_popUps_deleteTruckPost_title = 'common.postTruck.popUps.deleteTruckPost.title',
  /** "Are you sure you want to delete this truck post?" */ common_postTruck_popUps_deleteTruckPost_body = 'common.postTruck.popUps.deleteTruckPost.body',
  /** "Delete" */ common_postTruck_popUps_deleteTruckPost_button = 'common.postTruck.popUps.deleteTruckPost.button',
  /** "Truck name in use" */ common_postTruck_popUps_duplicateName_title = 'common.postTruck.popUps.duplicateName.title',
  /** "The display name is already associated to one of your trucks. Please choose another name for your truck." */ common_postTruck_popUps_duplicateName_body = 'common.postTruck.popUps.duplicateName.body',
  /** "Ok" */ common_postTruck_popUps_duplicateName_button = 'common.postTruck.popUps.duplicateName.button',
  /** "Clear all expired posts" */ common_postTruck_popUps_clearAll_title = 'common.postTruck.popUps.clearAll.title',
  /** "Are you sure you want to delete all your expired posts?" */ common_postTruck_popUps_clearAll_content = 'common.postTruck.popUps.clearAll.content',
  /** "Post truck if you need a backhaul." */ common_postTruck_loadDetails_action = 'common.postTruck.loadDetails.action',
  /** "Posted truck load alerts" */ common_postTruck_alerts_sectionTitle = 'common.postTruck.alerts.sectionTitle',
  /** "Load alerts about available loads are sent by brokers and shippers when they see your posted truck." */ common_postTruck_alerts_infoDialogContent = 'common.postTruck.alerts.infoDialogContent',
  /** "What is rate check?" */ common_infoModal_rateCheck_title = 'common.infoModal.rateCheck.title',
  /** "Rate check provides the average market rate to help compare lane rates before accepting loads and better estimate your load revenues." */ common_infoModal_rateCheck_subtitle = 'common.infoModal.rateCheck.subtitle',
  /** "What is PC*Miler?" */ common_infoModal_pcMiler_title = 'common.infoModal.pcMiler.title',
  /** "PC*Miler calculates trucking-specific mileage to provide carriers with the most accurate and reliable route data: allowing for better planning and no suprises along the way." */ common_infoModal_pcMiler_subtitle = 'common.infoModal.pcMiler.subtitle',
  /** "Load alerts" */ common_loadAlert_title = 'common.loadAlert.title',
  /** "No load alerts" */ common_loadAlert_emptyTitle = 'common.loadAlert.emptyTitle',
  /** "To receive alerts, be sure to post your truck." */ common_loadAlert_emptySubtitle = 'common.loadAlert.emptySubtitle',
  /** "Load alert" */ common_loadAlert_alertLoad = 'common.loadAlert.alertLoad',
  /** "Mark as unread" */ common_loadAlert_markUnread = 'common.loadAlert.markUnread',
  /** "Deadhead miles" */ common_sortByKeys_deadheadMiles = 'common.sortByKeys.deadheadMiles',
  /** "Deadhead" */ common_sortByKeys_origin = 'common.sortByKeys.origin',
  /** "Pickup date" */ common_sortByKeys_pickupDate = 'common.sortByKeys.pickupDate',
  /** "Date:{{date}}" */ common_sortByKeys_pickupDateWithDate = 'common.sortByKeys.pickupDateWithDate',
  /** "Trip distance" */ common_sortByKeys_distance = 'common.sortByKeys.distance',
  /** "Trip rate" */ common_sortByKeys_rate = 'common.sortByKeys.rate',
  /** "Rate per mile" */ common_sortByKeys_pricePerMile = 'common.sortByKeys.pricePerMile',
  /** "Age" */ common_sortByKeys_postedAge = 'common.sortByKeys.postedAge',
  /** "Views" */ common_sortByKeys_views = 'common.sortByKeys.views',
  /** "Weight" */ common_sortByKeys_weight = 'common.sortByKeys.weight',
  /** "Length" */ common_sortByKeys_length = 'common.sortByKeys.length',
  /** "Distance" */ common_sortByKeys_mileage = 'common.sortByKeys.mileage',
  /** "Posted Rate" */ common_sortByKeys_amount = 'common.sortByKeys.amount',
  /** "Rate Check" */ common_sortByKeys_rateCheck = 'common.sortByKeys.rateCheck',
  /** "Company name" */ common_sortByKeys_company = 'common.sortByKeys.company',
  /** "Credit score" */ common_sortByKeys_creditScore = 'common.sortByKeys.creditScore',
  /** "Days-to-pay" */ common_sortByKeys_daysToPay = 'common.sortByKeys.daysToPay',
  /** "Trip (mi)" */ common_sortByKeys_trip = 'common.sortByKeys.trip',
  /** "Rate ($)" */ common_sortByKeys_tripRate = 'common.sortByKeys.tripRate',
  /** "Equipment" */ common_sortByKeys_equipment = 'common.sortByKeys.equipment',
  /** "Size" */ common_sortByKeys_size = 'common.sortByKeys.size',
  /** "Weight (lb)" */ common_sortByKeys_weightLb = 'common.sortByKeys.weightLb',
  /** "Length (ft)" */ common_sortByKeys_lengthFt = 'common.sortByKeys.lengthFt',
  /** "Ref#" */ common_sortByKeys_ref = 'common.sortByKeys.ref',
  /** "Location" */ common_sortByKeys_searchDirectory_location = 'common.sortByKeys.searchDirectory.location',
  /** "USDOT#" */ common_sortByKeys_searchDirectory_USDOT = 'common.sortByKeys.searchDirectory.USDOT',
  /** "MC#" */ common_sortByKeys_searchDirectory_MCNO = 'common.sortByKeys.searchDirectory.MCNO',
  /** "Phone" */ common_sortByKeys_searchDirectory_phone = 'common.sortByKeys.searchDirectory.phone',
  /** "Card" */ common_viewKeys_cards = 'common.viewKeys.cards',
  /** "List" */ common_viewKeys_list = 'common.viewKeys.list',
  /** "Map" */ common_viewKeys_map = 'common.viewKeys.map',
  /** "View: {{value}}" */ common_viewKeys_view = 'common.viewKeys.view',
  /** "Age" */ common_search_age = 'common.search.age',
  /** "Pick up" */ common_search_pickUp = 'common.search.pickUp',
  /** "Pick up location" */ common_search_pickUpLocation = 'common.search.pickUpLocation',
  /** "Drop off location" */ common_search_dropOffLocation = 'common.search.dropOffLocation',
  /** "Trip" */ common_search_trip = 'common.search.trip',
  /** "Rate" */ common_search_rate = 'common.search.rate',
  /** "Avg. rate" */ common_search_avgRate = 'common.search.avgRate',
  /** "Equip" */ common_search_equip = 'common.search.equip',
  /** "Size" */ common_search_size = 'common.search.size',
  /** "Company" */ common_search_company = 'common.search.company',
  /** "Status" */ common_search_status = 'common.search.status',
  /** "Ft" */ common_search_ft = 'common.search.ft',
  /** "Lb" */ common_search_lb = 'common.search.lb',
  /** "Small truck loads" */ common_smallTruckLoadsDialog_title = 'common.smallTruckLoadsDialog.title',
  /** "To find the best results for smaller trucks, we recommend that you select the following equipment types:" */ common_smallTruckLoadsDialog_content = 'common.smallTruckLoadsDialog.content',
  /** "minutes" */ common_time_minutes = 'common.time.minutes',
  /** "1m" */ common_time_oneMinute = 'common.time.oneMinute',
  /** "y" */ common_time_abbreviation_Year = 'common.time.abbreviation.Year',
  /** "d" */ common_time_abbreviation_Days = 'common.time.abbreviation.Days',
  /** "h" */ common_time_abbreviation_Hours = 'common.time.abbreviation.Hours',
  /** "min" */ common_time_abbreviation_Minutes = 'common.time.abbreviation.Minutes',
  /** "m" */ common_time_abbreviation_MinutesShort = 'common.time.abbreviation.MinutesShort',
  /** "s" */ common_time_abbreviation_Seconds = 'common.time.abbreviation.Seconds',
  /** "mo" */ common_time_abbreviation_Month = 'common.time.abbreviation.Month',
  /** "yr" */ common_time_abbreviation_yearAlt = 'common.time.abbreviation.yearAlt',
  /** "Recent searches" */ common_recentSearch_title = 'common.recentSearch.title',
  /** "Search saved" */ common_savedSearches_searchSaved = 'common.savedSearches.searchSaved',
  /** "Search removed" */ common_savedSearches_savedSearchRemoved = 'common.savedSearches.savedSearchRemoved',
  /** "Alert saved" */ common_savedSearches_alertSaved = 'common.savedSearches.alertSaved',
  /** "Alert removed" */ common_savedSearches_alertRemoved = 'common.savedSearches.alertRemoved',
  /** "Unable to save" */ common_savedSearches_unableToSave = 'common.savedSearches.unableToSave',
  /** "Your search could not be saved. Please move the map to another location and try again." */ common_savedSearches_unableToSaveReason = 'common.savedSearches.unableToSaveReason',
  /** "Deleted search" */ common_namedSearch_deletedSearch = 'common.namedSearch.deletedSearch',
  /** "This search has been deleted in another window or device. Please create a new search to find more loads." */ common_namedSearch_searchDoesNotExist = 'common.namedSearch.searchDoesNotExist',
  /** "View search results" */ common_namedSearch_cardOption_view = 'common.namedSearch.cardOption.view',
  /** "Turn search alert \"{{action}}\"" */ common_namedSearch_cardOption_alert = 'common.namedSearch.cardOption.alert',
  /** "Edit search" */ common_namedSearch_cardOption_edit = 'common.namedSearch.cardOption.edit',
  /** "Delete search" */ common_namedSearch_cardOption_delete = 'common.namedSearch.cardOption.delete',
  /** "Views" */ common_namedSearch_view_title = 'common.namedSearch.view.title',
  /** "View: {{view}}" */ common_namedSearch_view_label = 'common.namedSearch.view.label',
  /** "All" */ common_namedSearch_view_option_all = 'common.namedSearch.view.option.all',
  /** "With alerts" */ common_namedSearch_view_option_withAlerts = 'common.namedSearch.view.option.withAlerts',
  /** "Without alerts" */ common_namedSearch_view_option_withoutAlerts = 'common.namedSearch.view.option.withoutAlerts',
  /** "Edit search" */ common_namedSearch_form_editSearchTitle = 'common.namedSearch.form.editSearchTitle',
  /** "New search" */ common_namedSearch_form_newSearchTitle = 'common.namedSearch.form.newSearchTitle',
  /** "Your available capacity is your equipment type and the size of the load you are looking to haul." */ common_namedSearch_form_availibleCapacityInfo = 'common.namedSearch.form.availibleCapacityInfo',
  /** "Name this search" */ common_namedSearch_form_searchNameLabel = 'common.namedSearch.form.searchNameLabel',
  /** "Search name (Optional)" */ common_namedSearch_form_searchNamePlaceholder = 'common.namedSearch.form.searchNamePlaceholder',
  /** "Search name" */ common_namedSearch_form_searchNameTitle = 'common.namedSearch.form.searchNameTitle',
  /** "You can easily manage your searches by giving them custom names." */ common_namedSearch_form_searchNameInfo = 'common.namedSearch.form.searchNameInfo',
  /** "Start finding loads" */ common_namedSearch_emptyPanel_title = 'common.namedSearch.emptyPanel.title',
  /** "This tool allows you to find and book loads to haul." */ common_namedSearch_emptyPanel_subtitle = 'common.namedSearch.emptyPanel.subtitle',
  /** "Set search alerts on search results pages." */ common_namedSearch_emptyAlertsPanel_subtitle = 'common.namedSearch.emptyAlertsPanel.subtitle',
  /** "You have no searches without alerts." */ common_namedSearch_emptyAlertsPanel_subtitleWithoutAlerts = 'common.namedSearch.emptyAlertsPanel.subtitleWithoutAlerts',
  /** "View all" */ common_namedSearch_emptyAlertsPanel_viewAll = 'common.namedSearch.emptyAlertsPanel.viewAll',
  /** "Create new search" */ common_namedSearch_createNewButton = 'common.namedSearch.createNewButton',
  /** "Search saved" */ common_namedSearch_searchSaved = 'common.namedSearch.searchSaved',
  /** "Search removed" */ common_namedSearch_searchRemoved = 'common.namedSearch.searchRemoved',
  /** "Alert saved" */ common_namedSearch_alertSaved = 'common.namedSearch.alertSaved',
  /** "Alert removed" */ common_namedSearch_alertRemoved = 'common.namedSearch.alertRemoved',
  /** "Saving your search allows you to quickly find results for lanes you run regularly and can help save time when returning to this page." */ common_namedSearch_saveSearchMessage = 'common.namedSearch.saveSearchMessage',
  /** "Save search" */ common_namedSearch_saveSearchPopup_title = 'common.namedSearch.saveSearchPopup.title',
  /** "Don't forget to save your search before leaving this page to quickly see results again when you return." */ common_namedSearch_saveSearchPopup_content = 'common.namedSearch.saveSearchPopup.content',
  /** "This field is required" */ common_errors_required = 'common.errors.required',
  /** "This field cannot contain any spaces" */ common_errors_noSpaces = 'common.errors.noSpaces',
  /** "This field must be at least {{length}} characters" */ common_errors_minLength = 'common.errors.minLength',
  /** "Please enter a valid email." */ common_errors_email = 'common.errors.email',
  /** "Please enter a valid 10-digit phone number" */ common_errors_phone = 'common.errors.phone',
  /** "Enter a valid value" */ common_errors_state = 'common.errors.state',
  /** "This field is required (Characters accepted: A-Z, 0-9)" */ common_errors_requiredAsciiOnly = 'common.errors.requiredAsciiOnly',
  /** "This field is required (Accepted: A-Z, 0-9, -, _)" */ common_errors_requiredAsciiAndHyphenOnly = 'common.errors.requiredAsciiAndHyphenOnly',
  /** "This field is required (Characters accepted: 0-9)" */ common_errors_requiredNumbersOnly = 'common.errors.requiredNumbersOnly',
  /** "Please enter a valid per mile rate" */ common_errors_requiredPrice = 'common.errors.requiredPrice',
  /** "Please enter a value under {{value}}" */ common_errors_lessThanValue = 'common.errors.lessThanValue',
  /** "Please enter a value greater than {{value}}" */ common_errors_greaterThanValue = 'common.errors.greaterThanValue',
  /** "Unable to open {{appName}} app" */ common_errors_unableToOpen = 'common.errors.unableToOpen',
  /** "{{appName}} app not supported" */ common_errors_notSupported = 'common.errors.notSupported',
  /** "This field cannot be empty. " */ common_errors_empty = 'common.errors.empty',
  /** "Please enter a value greater than {{value}}" */ common_errors_tooLow = 'common.errors.tooLow',
  /** "This field cannot be over {{max}} characters" */ common_errors_max = 'common.errors.max',
  /** "Please enter a valid number" */ common_errors_mustBeNumber = 'common.errors.mustBeNumber',
  /** "Enter a valid DOT #" */ common_errors_validDOT = 'common.errors.validDOT',
  /** "Map data failed to load" */ common_errors_mapDataFailed = 'common.errors.mapDataFailed',
  /** "Weight must be 132000 pounds or less" */ common_errors_weightErrorLbs = 'common.errors.weightErrorLbs',
  /** "Weight must be 59874 kilos or less" */ common_errors_weightErrorKgs = 'common.errors.weightErrorKgs',
  /** "Length must be bigger than 2.44m and less than 21m" */ common_errors_lengthErrorMetric = 'common.errors.lengthErrorMetric',
  /** "Height must be bigger than 1.53m and less than 4.2m" */ common_errors_heightErrorMetric = 'common.errors.heightErrorMetric',
  /** "Length must be between 8-69f" */ common_errors_lengthErrorFeet = 'common.errors.lengthErrorFeet',
  /** "Height must be between 5-14f" */ common_errors_heightErrorFeet = 'common.errors.heightErrorFeet',
  /** "Enter a valid MC #" */ common_errors_validMC = 'common.errors.validMC',
  /** "Invalid phone number" */ common_errors_invalidPhoneShortError = 'common.errors.invalidPhoneShortError',
  /** "Invalid number" */ common_errors_streetNumberError = 'common.errors.streetNumberError',
  /** "Invalid card number." */ common_errors_invalidCardError = 'common.errors.invalidCardError',
  /** "Credit card verification failed. Please review your information and try again." */ common_errors_genericCardError = 'common.errors.genericCardError',
  /** "Invalid expiry date." */ common_errors_invalidExpiry = 'common.errors.invalidExpiry',
  /** "Invalid security code." */ common_errors_invalidSecurityCode = 'common.errors.invalidSecurityCode',
  /** "Address verification failed." */ common_errors_avsFailed = 'common.errors.avsFailed',
  /** "{{fieldName}} must be between {{min}}-{{max}}." */ common_errors_betweenValues = 'common.errors.betweenValues',
  /** "URL must be valid and use https://" */ common_errors_invalidWebProtocol = 'common.errors.invalidWebProtocol',
  /** "How much do you expect this lane to pay?" */ common_rateCheck_confirmation_payAmount = 'common.rateCheck.confirmation.payAmount',
  /** "Is this rate accurate?" */ common_rateCheck_confirmation_isRateAccurate = 'common.rateCheck.confirmation.isRateAccurate',
  /** "Thank you for your feedback!" */ common_rateCheck_confirmation_thankYou = 'common.rateCheck.confirmation.thankYou',
  /** "Error submitting feedback" */ common_rateCheck_confirmation_errorSubmitting = 'common.rateCheck.confirmation.errorSubmitting',
  /** "Rate per mile" */ common_rateCheck_confirmation_ratePerMile = 'common.rateCheck.confirmation.ratePerMile',
  /** "Post truck" */ common_rateCheck_postTruck = 'common.rateCheck.postTruck',
  /** "Average rate check for {{month}}" */ common_rateCheck_legendTitle = 'common.rateCheck.legendTitle',
  /** "Premium" */ common_userRoles_premium = 'common.userRoles.premium',
  /** "Standard" */ common_userRoles_standard = 'common.userRoles.standard',
  /** "Basic" */ common_userRoles_basic = 'common.userRoles.basic',
  /** "Member" */ common_userRoles_member = 'common.userRoles.member',
  /** "Load available" */ common_loadProgress_loadAvailable = 'common.loadProgress.loadAvailable',
  /** "Booked" */ common_loadProgress_booked = 'common.loadProgress.booked',
  /** "Scheduled for pickup" */ common_loadProgress_scheduledForPickup = 'common.loadProgress.scheduledForPickup',
  /** "Pickup complete" */ common_loadProgress_pickupComplete = 'common.loadProgress.pickupComplete',
  /** "Load en route" */ common_loadProgress_loadEnRoute = 'common.loadProgress.loadEnRoute',
  /** "Delivered" */ common_loadProgress_delivered = 'common.loadProgress.delivered',
  /** "Search alert limit reached" */ common_loadAlerts_limitReached = 'common.loadAlerts.limitReached',
  /** "There is a maximum of 10 search alerts. Please remove a previous alert to set this one." */ common_loadAlerts_limitReachedContent = 'common.loadAlerts.limitReachedContent',
  /** "Found {{quantity}} loads, results may vary" */ common_loadAvailability_foundLoads = 'common.loadAvailability.foundLoads',
  /** "Results may vary" */ common_loadAvailability_resultsMayVary_title = 'common.loadAvailability.resultsMayVary.title',
  /** "blocked companies" */ common_loadAvailability_resultsMayVary_blockedCompanies = 'common.loadAvailability.resultsMayVary.blockedCompanies',
  /** "We display loads that are specific to your current profile setup and saved criteria. For example if you " */ common_loadAvailability_resultsMayVary_contentBeforeLink = 'common.loadAvailability.resultsMayVary.contentBeforeLink',
  /** ", their loads will be hidden from your results and may affect displayed load count." */ common_loadAvailability_resultsMayVary_contentAfterLink = 'common.loadAvailability.resultsMayVary.contentAfterLink',
  /** "USA" */ common_loadAvailability_regionTypes_USA = 'common.loadAvailability.regionTypes.USA',
  /** "Northeast" */ common_loadAvailability_regionTypes_Northeast = 'common.loadAvailability.regionTypes.Northeast',
  /** "Midwest" */ common_loadAvailability_regionTypes_Midwest = 'common.loadAvailability.regionTypes.Midwest',
  /** "South" */ common_loadAvailability_regionTypes_South = 'common.loadAvailability.regionTypes.South',
  /** "West" */ common_loadAvailability_regionTypes_West = 'common.loadAvailability.regionTypes.West',
  /** "Plains" */ common_loadAvailability_regionTypes_Plains = 'common.loadAvailability.regionTypes.Plains',
  /** "Other" */ common_loadAvailability_regionTypes_Other = 'common.loadAvailability.regionTypes.Other',
  /** "Canada" */ common_loadAvailability_regionTypes_Canada = 'common.loadAvailability.regionTypes.Canada',
  /** "US" */ common_loadAvailability_regionTypes_US = 'common.loadAvailability.regionTypes.US',
  /** "PC*MILER map" */ common_pcMiler_title = 'common.pcMiler.title',
  /** "Toll:" */ common_pcMiler_toll = 'common.pcMiler.toll',
  /** "Route info" */ common_pcMiler_routeInfo = 'common.pcMiler.routeInfo',
  /** "Summary road info" */ common_pcMiler_summaryRoadInfo_title = 'common.pcMiler.summaryRoadInfo.title',
  /** "Start: {{location}}" */ common_pcMiler_summaryRoadInfo_start = 'common.pcMiler.summaryRoadInfo.start',
  /** "End: {{location}}" */ common_pcMiler_summaryRoadInfo_end = 'common.pcMiler.summaryRoadInfo.end',
  /** "Total distance:" */ common_pcMiler_summaryRoadInfo_totalDistance = 'common.pcMiler.summaryRoadInfo.totalDistance',
  /** "Total driving time: {{time}}" */ common_pcMiler_summaryRoadInfo_totalDrivingTime = 'common.pcMiler.summaryRoadInfo.totalDrivingTime',
  /** "Total toll cost:" */ common_pcMiler_summaryRoadInfo_totalTollCost = 'common.pcMiler.summaryRoadInfo.totalTollCost',
  /** "PC*Miler routing" */ common_pcMiler_routing_title = 'common.pcMiler.routing.title',
  /** "Departure from:" */ common_pcMiler_routing_departureFrom = 'common.pcMiler.routing.departureFrom',
  /** "IFTA report" */ common_pcMiler_iftaReport = 'common.pcMiler.iftaReport',
  /** "State by state mileage report" */ common_pcMiler_stateByStateReport = 'common.pcMiler.stateByStateReport',
  /** "Download state mileage report (IFTA)" */ common_pcMiler_downloadIftaReport = 'common.pcMiler.downloadIftaReport',
  /** "(An app supporting CSV is required)" */ common_pcMiler_iftaReportDownloadWarning = 'common.pcMiler.iftaReportDownloadWarning',
  /** "Start: " */ common_pcMiler_summary_start = 'common.pcMiler.summary.start',
  /** "End: " */ common_pcMiler_summary_end = 'common.pcMiler.summary.end',
  /** "Total distance: " */ common_pcMiler_summary_totalDistance = 'common.pcMiler.summary.totalDistance',
  /** "Total driving time: " */ common_pcMiler_summary_totalDrivingTime = 'common.pcMiler.summary.totalDrivingTime',
  /** "Total toll cost: " */ common_pcMiler_summary_totalTollCost = 'common.pcMiler.summary.totalTollCost',
  /** "Stop {{value}}: " */ common_pcMiler_stop = 'common.pcMiler.stop',
  /** "Extra stops: {{value}}" */ common_pcMiler_extraStops = 'common.pcMiler.extraStops',
  /** "Stops" */ common_pcMiler_stops = 'common.pcMiler.stops',
  /** "Arrival to: " */ common_pcMiler_arrivalTo = 'common.pcMiler.arrivalTo',
  /** "Loads" */ common_myLoads_loads = 'common.myLoads.loads',
  /** "Recently viewed" */ common_myLoads_viewed = 'common.myLoads.viewed',
  /** "Hidden" */ common_myLoads_hidden = 'common.myLoads.hidden',
  /** "Potential loads" */ common_myLoads_potentialLoads = 'common.myLoads.potentialLoads',
  /** "Saved" */ common_myLoads_saved = 'common.myLoads.saved',
  /** "Called" */ common_myLoads_called = 'common.myLoads.called',
  /** "Contacted" */ common_myLoads_contacted = 'common.myLoads.contacted',
  /** "Working loads" */ common_myLoads_workingLoads = 'common.myLoads.workingLoads',
  /** "Booked" */ common_myLoads_booked = 'common.myLoads.booked',
  /** "Scheduled for pickup" */ common_myLoads_scheduledForPickup = 'common.myLoads.scheduledForPickup',
  /** "Pickup complete" */ common_myLoads_pickupComplete = 'common.myLoads.pickupComplete',
  /** "Load en route" */ common_myLoads_loadEnRoute = 'common.myLoads.loadEnRoute',
  /** "Completed loads" */ common_myLoads_completedLoads = 'common.myLoads.completedLoads',
  /** "Delivered" */ common_myLoads_delivered = 'common.myLoads.delivered',
  /** "Recently viewed loads" */ common_myLoads_headerTitles_viewedLoads = 'common.myLoads.headerTitles.viewedLoads',
  /** "Saved loads" */ common_myLoads_headerTitles_savedLoads = 'common.myLoads.headerTitles.savedLoads',
  /** "Called loads" */ common_myLoads_headerTitles_calledLoads = 'common.myLoads.headerTitles.calledLoads',
  /** "Booked loads" */ common_myLoads_headerTitles_bookedLoads = 'common.myLoads.headerTitles.bookedLoads',
  /** "Scheduled loads" */ common_myLoads_headerTitles_scheduledLoads = 'common.myLoads.headerTitles.scheduledLoads',
  /** "Pickup loads" */ common_myLoads_headerTitles_pickupLoads = 'common.myLoads.headerTitles.pickupLoads',
  /** "Delivered loads" */ common_myLoads_headerTitles_deliveredLoads = 'common.myLoads.headerTitles.deliveredLoads',
  /** "Contacted loads" */ common_myLoads_headerTitles_contactedLoads = 'common.myLoads.headerTitles.contactedLoads',
  /** "Hidden loads" */ common_myLoads_headerTitles_hiddenLoads = 'common.myLoads.headerTitles.hiddenLoads',
  /** "No viewed loads" */ common_myLoads_emptyResult_viewed_title = 'common.myLoads.emptyResult.viewed.title',
  /** "Start viewing loads by performing a load search and clicking on a load." */ common_myLoads_emptyResult_viewed_content = 'common.myLoads.emptyResult.viewed.content',
  /** "No hidden loads" */ common_myLoads_emptyResult_hidden_title = 'common.myLoads.emptyResult.hidden.title',
  /** "No saved loads" */ common_myLoads_emptyResult_saved_title = 'common.myLoads.emptyResult.saved.title',
  /** "Start saving loads by clicking the ‘Save Load’ link on the load details page." */ common_myLoads_emptyResult_saved_content = 'common.myLoads.emptyResult.saved.content',
  /** "No called loads" */ common_myLoads_emptyResult_called_title = 'common.myLoads.emptyResult.called.title',
  /** "Start calling loads by clicking the ‘Call’ button on the load details page." */ common_myLoads_emptyResult_called_content = 'common.myLoads.emptyResult.called.content',
  /** "No booked loads" */ common_myLoads_emptyResult_booked_title = 'common.myLoads.emptyResult.booked.title',
  /** "No scheduled loads" */ common_myLoads_emptyResult_scheduled_title = 'common.myLoads.emptyResult.scheduled.title',
  /** "No picked up loads" */ common_myLoads_emptyResult_picked_title = 'common.myLoads.emptyResult.picked.title',
  /** "No en route loads" */ common_myLoads_emptyResult_enRoute_title = 'common.myLoads.emptyResult.enRoute.title',
  /** "No delivered loads" */ common_myLoads_emptyResult_delivered_title = 'common.myLoads.emptyResult.delivered.title',
  /** "No contacted loads" */ common_myLoads_emptyResult_contacted_title = 'common.myLoads.emptyResult.contacted.title',
  /** "Loads will be listed here when you contact a company through the Load details page." */ common_myLoads_emptyResult_contacted_content = 'common.myLoads.emptyResult.contacted.content',
  /** "Update status on the load details page." */ common_myLoads_emptyResult_updateStatusContent = 'common.myLoads.emptyResult.updateStatusContent',
  /** "Remove loads" */ common_myLoads_deleteDialog_title = 'common.myLoads.deleteDialog.title',
  /** "Are you sure you want to unsave these loads?" */ common_myLoads_deleteDialog_content = 'common.myLoads.deleteDialog.content',
  /** "Unhide loads" */ common_myLoads_unhideLoads_title = 'common.myLoads.unhideLoads.title',
  /** "Are you sure you want to unhide these loads?" */ common_myLoads_unhideLoads_content = 'common.myLoads.unhideLoads.content',
  /** "Failed to remove" */ common_myLoads_deleteFailedDialog_title = 'common.myLoads.deleteFailedDialog.title',
  /** "Your loads were unable to be removed." */ common_myLoads_deleteFailedDialog_content = 'common.myLoads.deleteFailedDialog.content',
  /** "Remove avatar" */ common_avatar_avatarPopupTitle = 'common.avatar.avatarPopupTitle',
  /** "Are you sure you want to remove your avatar?" */ common_avatar_avatarPopupContent = 'common.avatar.avatarPopupContent',
  /** "COMPANY LOGO" */ common_avatar_companyAvatarPlaceholderText = 'common.avatar.companyAvatarPlaceholderText',
  /** "Add new document" */ common_myDocuments_addNewDocument = 'common.myDocuments.addNewDocument',
  /** "Add new folder" */ common_myDocuments_addNewFolder = 'common.myDocuments.addNewFolder',
  /** "Select" */ common_myDocuments_select = 'common.myDocuments.select',
  /** "Edit folder name" */ common_myDocuments_editFolderName = 'common.myDocuments.editFolderName',
  /** "Delete folder" */ common_myDocuments_deleteFolder = 'common.myDocuments.deleteFolder',
  /** "My documents" */ common_myDocuments_title = 'common.myDocuments.title',
  /** "My docs" */ common_myDocuments_titleShort = 'common.myDocuments.titleShort',
  /** "View load details" */ common_myDocuments_viewLoadDetails = 'common.myDocuments.viewLoadDetails',
  /** "Upload and manage your carrier documents, loads, and other documents securely." */ common_myDocuments_noDocuments = 'common.myDocuments.noDocuments',
  /** "Documents" */ common_myDocuments_emptyDocument_title = 'common.myDocuments.emptyDocument.title',
  /** "Start adding documents to see files here" */ common_myDocuments_emptyDocument_subTitle = 'common.myDocuments.emptyDocument.subTitle',
  /** "Try Again" */ common_myDocuments_tryAgain = 'common.myDocuments.tryAgain',
  /** "Sort by" */ common_myDocuments_sortBy = 'common.myDocuments.sortBy',
  /** "Uploaded" */ common_myDocuments_uploaded = 'common.myDocuments.uploaded',
  /** "Folder" */ common_myDocuments_folder = 'common.myDocuments.folder',
  /** "Type" */ common_myDocuments_type = 'common.myDocuments.type',
  /** "Date" */ common_myDocuments_date = 'common.myDocuments.date',
  /** "Filter by folder" */ common_myDocuments_filterByFolder = 'common.myDocuments.filterByFolder',
  /** "Add document" */ common_myDocuments_addDocument = 'common.myDocuments.addDocument',
  /** "Attach to send" */ common_myDocuments_attachToSendDocument = 'common.myDocuments.attachToSendDocument',
  /** "By date" */ common_myDocuments_byDate = 'common.myDocuments.byDate',
  /** "Alphabetical" */ common_myDocuments_alphabetical = 'common.myDocuments.alphabetical',
  /** "By folder" */ common_myDocuments_byFolder = 'common.myDocuments.byFolder',
  /** "By type" */ common_myDocuments_byType = 'common.myDocuments.byType',
  /** "Downloading" */ common_myDocuments_downloading = 'common.myDocuments.downloading',
  /** "Remove attachment" */ common_myDocuments_removeAttachment = 'common.myDocuments.removeAttachment',
  /** "Sent" */ common_myDocuments_sent = 'common.myDocuments.sent',
  /** "Folders" */ common_myDocuments_selectFolder_title = 'common.myDocuments.selectFolder.title',
  /** "Sort by" */ common_myDocuments_selectFolder_sort_title = 'common.myDocuments.selectFolder.sort.title',
  /** "Alphabetical" */ common_myDocuments_selectFolder_sort_name = 'common.myDocuments.selectFolder.sort.name',
  /** "Date" */ common_myDocuments_selectFolder_sort_lastAccessedOn = 'common.myDocuments.selectFolder.sort.lastAccessedOn',
  /** "Source" */ common_myDocuments_selectFolder_sort_hasLoad = 'common.myDocuments.selectFolder.sort.hasLoad',
  /** "Create new folder" */ common_myDocuments_createFolder_title = 'common.myDocuments.createFolder.title',
  /** "Use common naming convention when creating folders to easily browse." */ common_myDocuments_createFolder_description = 'common.myDocuments.createFolder.description',
  /** "Enter folder name" */ common_myDocuments_createFolder_placeholder = 'common.myDocuments.createFolder.placeholder',
  /** "Folder already exists" */ common_myDocuments_createFolder_response_alreadyExists_title = 'common.myDocuments.createFolder.response.alreadyExists.title',
  /** "A folder with this name already exists. Please enter another name." */ common_myDocuments_createFolder_response_alreadyExists_message = 'common.myDocuments.createFolder.response.alreadyExists.message',
  /** "New folder created" */ common_myDocuments_createFolder_response_success = 'common.myDocuments.createFolder.response.success',
  /** "Error creating new folder" */ common_myDocuments_createFolder_response_genericError = 'common.myDocuments.createFolder.response.genericError',
  /** "Edit folder" */ common_myDocuments_editFolder_title = 'common.myDocuments.editFolder.title',
  /** "Folder name was updated successfully" */ common_myDocuments_editFolder_toast_success = 'common.myDocuments.editFolder.toast.success',
  /** "Error updating folder name" */ common_myDocuments_editFolder_toast_error = 'common.myDocuments.editFolder.toast.error',
  /** "Folder was deleted successfully" */ common_myDocuments_editFolder_toast_successDelete = 'common.myDocuments.editFolder.toast.successDelete',
  /** "Error deleting folder" */ common_myDocuments_editFolder_toast_errorDelete = 'common.myDocuments.editFolder.toast.errorDelete',
  /** "Delete folder" */ common_myDocuments_editFolder_deleteDialog_title = 'common.myDocuments.editFolder.deleteDialog.title',
  /** "Are you sure you want to delete this folder?\n\nAny documents in this folder will be moved to the 'Unsorted' folder." */ common_myDocuments_editFolder_deleteDialog_content = 'common.myDocuments.editFolder.deleteDialog.content',
  /** "\n\nIf this folder is deleted, you may no longer be able to view Load details." */ common_myDocuments_editFolder_deleteDialog_contentFromMyLoaDetails = 'common.myDocuments.editFolder.deleteDialog.contentFromMyLoaDetails',
  /** "\n\nThe load associated with the folder will be unbooked." */ common_myDocuments_editFolder_deleteDialog_loadFolderContent = 'common.myDocuments.editFolder.deleteDialog.loadFolderContent',
  /** "Unable to edit folder name" */ common_myDocuments_editFolder_error_title = 'common.myDocuments.editFolder.error.title',
  /** "Please try again or come back later." */ common_myDocuments_editFolder_error_message = 'common.myDocuments.editFolder.error.message',
  /** "Rename" */ common_myDocuments_renameDoc_rename = 'common.myDocuments.renameDoc.rename',
  /** "Use common naming conventions when renaming files to easily browse." */ common_myDocuments_renameDoc_description = 'common.myDocuments.renameDoc.description',
  /** "Rename file button" */ common_myDocuments_renameDoc_accesibilityButton = 'common.myDocuments.renameDoc.accesibilityButton',
  /** "New Name" */ common_myDocuments_renameDoc_newName = 'common.myDocuments.renameDoc.newName',
  /** "File name successfully changed" */ common_myDocuments_renameDoc_toast_success = 'common.myDocuments.renameDoc.toast.success',
  /** "Error renaming file" */ common_myDocuments_renameDoc_toast_error = 'common.myDocuments.renameDoc.toast.error',
  /** "Updating..." */ common_myDocuments_updateFolder_updating = 'common.myDocuments.updateFolder.updating',
  /** "Document folder updated" */ common_myDocuments_updateFolder_success = 'common.myDocuments.updateFolder.success',
  /** "Updating folder was not successful" */ common_myDocuments_updateFolder_fail = 'common.myDocuments.updateFolder.fail',
  /** "View document" */ common_myDocuments_viewDocument = 'common.myDocuments.viewDocument',
  /** "Download document success" */ common_myDocuments_download_success = 'common.myDocuments.download.success',
  /** "Download document error" */ common_myDocuments_download_failure = 'common.myDocuments.download.failure',
  /** "Preview not available" */ common_myDocuments_fileNotSupported_title = 'common.myDocuments.fileNotSupported.title',
  /** "A preview is not available for files with the extension ({{extension}})." */ common_myDocuments_fileNotSupported_subTitle = 'common.myDocuments.fileNotSupported.subTitle',
  /** "The file type ({{extension}}) you are trying to view is no longer supported. \n\n To download this file, click on the ‘more actions’ icon." */ common_myDocuments_fileNotSupported_subTitleDownloadOnly = 'common.myDocuments.fileNotSupported.subTitleDownloadOnly',
  /** "Document error" */ common_myDocuments_documentError_title = 'common.myDocuments.documentError.title',
  /** "Preview is not available, the document is corrupted." */ common_myDocuments_documentError_subTitle = 'common.myDocuments.documentError.subTitle',
  /** "Certificate of Insurance" */ common_myDocuments_documentTypes_certificateOfInsurance = 'common.myDocuments.documentTypes.certificateOfInsurance',
  /** "W-9" */ common_myDocuments_documentTypes_w9 = 'common.myDocuments.documentTypes.w9',
  /** "New document" */ common_myDocuments_addBottomSheet_newDocument = 'common.myDocuments.addBottomSheet.newDocument',
  /** "Scan document" */ common_myDocuments_addBottomSheet_scanDocument = 'common.myDocuments.addBottomSheet.scanDocument',
  /** "Upload photo" */ common_myDocuments_addBottomSheet_uploadPhoto = 'common.myDocuments.addBottomSheet.uploadPhoto',
  /** "Upload file" */ common_myDocuments_addBottomSheet_uploadFile = 'common.myDocuments.addBottomSheet.uploadFile',
  /** "Document type: {{- documentType}}" */ common_myDocuments_documentActionSheet_title = 'common.myDocuments.documentActionSheet.title',
  /** "Attach to send" */ common_myDocuments_documentActionSheet_send = 'common.myDocuments.documentActionSheet.send',
  /** "Select" */ common_myDocuments_documentActionSheet_select = 'common.myDocuments.documentActionSheet.select',
  /** "View" */ common_myDocuments_documentActionSheet_view = 'common.myDocuments.documentActionSheet.view',
  /** "Rename" */ common_myDocuments_documentActionSheet_rename = 'common.myDocuments.documentActionSheet.rename',
  /** "Rename document" */ common_myDocuments_documentActionSheet_renameDocument = 'common.myDocuments.documentActionSheet.renameDocument',
  /** "Change folder" */ common_myDocuments_documentActionSheet_changeFolder = 'common.myDocuments.documentActionSheet.changeFolder',
  /** "Change type" */ common_myDocuments_documentActionSheet_changeType = 'common.myDocuments.documentActionSheet.changeType',
  /** "Download" */ common_myDocuments_documentActionSheet_download = 'common.myDocuments.documentActionSheet.download',
  /** "Download document" */ common_myDocuments_documentActionSheet_downloadDocument = 'common.myDocuments.documentActionSheet.downloadDocument',
  /** "Delete" */ common_myDocuments_documentActionSheet_delete = 'common.myDocuments.documentActionSheet.delete',
  /** "Delete document" */ common_myDocuments_documentActionSheet_deleteDocument = 'common.myDocuments.documentActionSheet.deleteDocument',
  /** "New document" */ common_myDocuments_documentCapture_title = 'common.myDocuments.documentCapture.title',
  /** "Save" */ common_myDocuments_documentCapture_backPopup_title = 'common.myDocuments.documentCapture.backPopup.title',
  /** "Your document is not saved. Would you like to save this document?" */ common_myDocuments_documentCapture_backPopup_message = 'common.myDocuments.documentCapture.backPopup.message',
  /** "Delete" */ common_myDocuments_documentCapture_deletePopup_title = 'common.myDocuments.documentCapture.deletePopup.title',
  /** "Are you sure you want to delete the selected files?" */ common_myDocuments_documentCapture_deletePopup_message = 'common.myDocuments.documentCapture.deletePopup.message',
  /** "The file could not be saved. Please try again." */ common_myDocuments_documentCapture_errorOnSave = 'common.myDocuments.documentCapture.errorOnSave',
  /** "Generating your document, please wait." */ common_myDocuments_documentCapture_saveInProgress = 'common.myDocuments.documentCapture.saveInProgress',
  /** "Document deleted" */ common_myDocuments_toast_deleteSuccess = 'common.myDocuments.toast.deleteSuccess',
  /** "Error in deleting document" */ common_myDocuments_toast_deleteError = 'common.myDocuments.toast.deleteError',
  /** "Something went wrong with the file picker" */ common_myDocuments_toast_filePickerError = 'common.myDocuments.toast.filePickerError',
  /** "File must be less than 10Mb" */ common_myDocuments_toast_fileTooBigError = 'common.myDocuments.toast.fileTooBigError',
  /** "Delete document" */ common_myDocuments_deleteDialog_title = 'common.myDocuments.deleteDialog.title',
  /** "Are you sure you want to delete this document?" */ common_myDocuments_deleteDialog_content = 'common.myDocuments.deleteDialog.content',
  /** "Folders" */ common_myDocuments_viewTypeOptions_folders = 'common.myDocuments.viewTypeOptions.folders',
  /** "Dates" */ common_myDocuments_viewTypeOptions_dates = 'common.myDocuments.viewTypeOptions.dates',
  /** "Types" */ common_myDocuments_viewTypeOptions_types = 'common.myDocuments.viewTypeOptions.types',
  /** "Error sending documents" */ common_myDocuments_error_sendDocuments = 'common.myDocuments.error.sendDocuments',
  /** "Our system encountered an error while sending the documents." */ common_myDocuments_error_sendDocumentSubtitle = 'common.myDocuments.error.sendDocumentSubtitle',
  /** "Failed to create document" */ common_myDocuments_error_createDocument = 'common.myDocuments.error.createDocument',
  /** "An error occurred and your document was not able to be created. Please try again later or choose a different file(s)" */ common_myDocuments_error_createDocumentSubtitle = 'common.myDocuments.error.createDocumentSubtitle',
  /** "An error occurred and your document was not able to be created. File Name should only contain alphanumeric characters." */ common_myDocuments_error_invalidCharactersDocumentSubtitle = 'common.myDocuments.error.invalidCharactersDocumentSubtitle',
  /** "Failed to rename document" */ common_myDocuments_error_renameDocument = 'common.myDocuments.error.renameDocument',
  /** "An error occurred and your document was not able to be renamed. Please try again later." */ common_myDocuments_error_renameDocumentSubtitle = 'common.myDocuments.error.renameDocumentSubtitle',
  /** "Download failed" */ common_myDocuments_error_downloadDocument = 'common.myDocuments.error.downloadDocument',
  /** "Please try again or come back later" */ common_myDocuments_error_downloadDocumentSubtitle = 'common.myDocuments.error.downloadDocumentSubtitle',
  /** "Upload failed" */ common_myDocuments_error_invalidImageTypePopUpTitle = 'common.myDocuments.error.invalidImageTypePopUpTitle',
  /** "The file you have selected failed to upload. Please try again or choose another file." */ common_myDocuments_error_invalidImageTypePopUpContent = 'common.myDocuments.error.invalidImageTypePopUpContent',
  /** "Sorry, there was an error receiving your selected photo." */ common_myDocuments_error_couldNotRetrievePhoto = 'common.myDocuments.error.couldNotRetrievePhoto',
  /** "New document" */ common_myDocuments_createDocument_newDocument = 'common.myDocuments.createDocument.newDocument',
  /** "To create a document, enter a name, choose a desired folder, and file type." */ common_myDocuments_createDocument_title = 'common.myDocuments.createDocument.title',
  /** "Create document" */ common_myDocuments_createDocument_buttonTitle = 'common.myDocuments.createDocument.buttonTitle',
  /** "Types" */ common_myDocuments_createDocument_types = 'common.myDocuments.createDocument.types',
  /** "Enter document name" */ common_myDocuments_createDocument_form_name = 'common.myDocuments.createDocument.form.name',
  /** "Document name" */ common_myDocuments_createDocument_form_nameSelected = 'common.myDocuments.createDocument.form.nameSelected',
  /** "Folder name" */ common_myDocuments_createDocument_form_folderName = 'common.myDocuments.createDocument.form.folderName',
  /** "Select folder" */ common_myDocuments_createDocument_form_folder = 'common.myDocuments.createDocument.form.folder',
  /** "Folder" */ common_myDocuments_createDocument_form_folderSelected = 'common.myDocuments.createDocument.form.folderSelected',
  /** "Select type" */ common_myDocuments_createDocument_form_type = 'common.myDocuments.createDocument.form.type',
  /** "Type" */ common_myDocuments_createDocument_form_typeSelected = 'common.myDocuments.createDocument.form.typeSelected',
  /** "Upload was successful" */ common_myDocuments_createDocument_toast_success = 'common.myDocuments.createDocument.toast.success',
  /** "Error uploading" */ common_myDocuments_createDocument_toast_error = 'common.myDocuments.createDocument.toast.error',
  /** "Update was successful" */ common_myDocuments_createDocument_toast_successUpdate = 'common.myDocuments.createDocument.toast.successUpdate',
  /** "Error updating" */ common_myDocuments_createDocument_toast_errorUpdate = 'common.myDocuments.createDocument.toast.errorUpdate',
  /** "Invalid file type selected " */ common_myDocuments_createDocument_toast_invalid = 'common.myDocuments.createDocument.toast.invalid',
  /** "Are you sure you want to cancel?" */ common_myDocuments_createDocument_cancelUpload_title = 'common.myDocuments.createDocument.cancelUpload.title',
  /** "Leaving this page while the upload is in progress will cancel it" */ common_myDocuments_createDocument_cancelUpload_content = 'common.myDocuments.createDocument.cancelUpload.content',
  /** "Leave" */ common_myDocuments_createDocument_cancelUpload_confirm = 'common.myDocuments.createDocument.cancelUpload.confirm',
  /** "Stay" */ common_myDocuments_createDocument_cancelUpload_dismiss = 'common.myDocuments.createDocument.cancelUpload.dismiss',
  /** "File type error" */ common_myDocuments_createDocument_fileTypeError_title = 'common.myDocuments.createDocument.fileTypeError.title',
  /** "The file uploader only accepts the following file types: {{fileTypes}}. \n\n Please select a valid file and try again." */ common_myDocuments_createDocument_fileTypeError_subTitle = 'common.myDocuments.createDocument.fileTypeError.subTitle',
  /** "Upload failed" */ common_myDocuments_createDocument_mimeTypeError_title = 'common.myDocuments.createDocument.mimeTypeError.title',
  /** "We weren’t able to process this file. Please select a different file or use our document scanner to upload another copy of this document." */ common_myDocuments_createDocument_mimeTypeError_subTitle = 'common.myDocuments.createDocument.mimeTypeError.subTitle',
  /** "Go back" */ common_myDocuments_createDocument_mimeTypeError_buttonTitle = 'common.myDocuments.createDocument.mimeTypeError.buttonTitle',
  /** "Error" */ common_myDocuments_createDocument_mimeTypeError_screenTitle = 'common.myDocuments.createDocument.mimeTypeError.screenTitle',
  /** "You may send attached documents to any recipient." */ common_myDocuments_sendDocuments_description = 'common.myDocuments.sendDocuments.description',
  /** "No attached files" */ common_myDocuments_sendDocuments_emptyList = 'common.myDocuments.sendDocuments.emptyList',
  /** "Attached files" */ common_myDocuments_sendDocuments_attachedFiles = 'common.myDocuments.sendDocuments.attachedFiles',
  /** "Send" */ common_myDocuments_sendDocuments_send = 'common.myDocuments.sendDocuments.send',
  /** "Send documents" */ common_myDocuments_sendDocuments_title = 'common.myDocuments.sendDocuments.title',
  /** "Complete the form below to send your operating documents by fax or email at no charge." */ common_myDocuments_sendDocuments_disclaimer = 'common.myDocuments.sendDocuments.disclaimer',
  /** "Documents to send" */ common_myDocuments_sendDocuments_documentsTitle = 'common.myDocuments.sendDocuments.documentsTitle',
  /** "{{count}} documents selected" */ common_myDocuments_sendDocuments_documentsSelected_plural = 'common.myDocuments.sendDocuments.documentsSelected_plural',
  /** "Send to:" */ common_myDocuments_sendDocuments_sendTo = 'common.myDocuments.sendDocuments.sendTo',
  /** "Recipient" */ common_myDocuments_sendDocuments_recipient = 'common.myDocuments.sendDocuments.recipient',
  /** "Recipient name" */ common_myDocuments_sendDocuments_recipientPlaceholder = 'common.myDocuments.sendDocuments.recipientPlaceholder',
  /** "Account Manager" */ common_myDocuments_sendDocuments_accountManager = 'common.myDocuments.sendDocuments.accountManager',
  /** "Company name" */ common_myDocuments_sendDocuments_company = 'common.myDocuments.sendDocuments.company',
  /** "Company name" */ common_myDocuments_sendDocuments_companyPlaceholder = 'common.myDocuments.sendDocuments.companyPlaceholder',
  /** "Send by email" */ common_myDocuments_sendDocuments_sendByEmail = 'common.myDocuments.sendDocuments.sendByEmail',
  /** "Email" */ common_myDocuments_sendDocuments_emailTitle = 'common.myDocuments.sendDocuments.emailTitle',
  /** "Email address" */ common_myDocuments_sendDocuments_emailPlaceholder = 'common.myDocuments.sendDocuments.emailPlaceholder',
  /** "Send by fax" */ common_myDocuments_sendDocuments_sendByFax = 'common.myDocuments.sendDocuments.sendByFax',
  /** "Recipient fax" */ common_myDocuments_sendDocuments_faxTitle = 'common.myDocuments.sendDocuments.faxTitle',
  /** "Fax #" */ common_myDocuments_sendDocuments_faxPlaceholder = 'common.myDocuments.sendDocuments.faxPlaceholder',
  /** "Fax" */ common_myDocuments_sendDocuments_fax = 'common.myDocuments.sendDocuments.fax',
  /** "Thunder Funding" */ common_myDocuments_sendDocuments_thunderFunding = 'common.myDocuments.sendDocuments.thunderFunding',
  /** "Get paid faster" */ common_myDocuments_sendDocuments_getPaidFaster = 'common.myDocuments.sendDocuments.getPaidFaster',
  /** "Other" */ common_myDocuments_sendDocuments_other = 'common.myDocuments.sendDocuments.other',
  /** "Your documents have been successfully sent" */ common_myDocuments_sendDocuments_sentDocumentsMessage = 'common.myDocuments.sendDocuments.sentDocumentsMessage',
  /** "Attach files" */ common_myDocuments_sendDocuments_attachFiles = 'common.myDocuments.sendDocuments.attachFiles',
  /** "There was an error sending your documents" */ common_myDocuments_sendDocuments_toast_updateError = 'common.myDocuments.sendDocuments.toast.updateError',
  /** "Documents sent" */ common_myDocuments_sendDocuments_toast_updateSuccess = 'common.myDocuments.sendDocuments.toast.updateSuccess',
  /** "An email address or fax number is required" */ common_myDocuments_sendDocuments_error_emailFaxEmpty = 'common.myDocuments.sendDocuments.error.emailFaxEmpty',
  /** "Go back to My Documents Page" */ common_myDocuments_sendDocuments_accessibility_continueToMainPage = 'common.myDocuments.sendDocuments.accessibility.continueToMainPage',
  /** "Continue" */ common_myDocuments_sendDocuments_button_continue = 'common.myDocuments.sendDocuments.button.continue',
  /** "No documents attached" */ common_myDocuments_sendDocuments_banner_noDocuments_title = 'common.myDocuments.sendDocuments.banner.noDocuments.title',
  /** "Please attach 1 or more documents you would like to send." */ common_myDocuments_sendDocuments_banner_noDocuments_content = 'common.myDocuments.sendDocuments.banner.noDocuments.content',
  /** "Attachment limit exceeded" */ common_myDocuments_sendDocuments_banner_limitExceeded_title = 'common.myDocuments.sendDocuments.banner.limitExceeded.title',
  /** "Too many files selected. Please remove some of these files and send them separately." */ common_myDocuments_sendDocuments_banner_limitExceeded_content = 'common.myDocuments.sendDocuments.banner.limitExceeded.content',
  /** "123Connect" */ common_conversations_title123Connect = 'common.conversations.title123Connect',
  /** "Messages" */ common_conversations_title = 'common.conversations.title',
  /** "Type here" */ common_conversations_typeHereBox = 'common.conversations.typeHereBox',
  /** "Message" */ common_conversations_button = 'common.conversations.button',
  /** "Message again" */ common_conversations_buttonMessageAgain = 'common.conversations.buttonMessageAgain',
  /** "Start your conversation with this company by asking some questions about this load or about their onboarding procedures. \n\nWe will automatically share the following information with your message:\n" */ common_conversations_newConversation_mainMessage = 'common.conversations.newConversation.mainMessage',
  /** "your name" */ common_conversations_newConversation_sharedName = 'common.conversations.newConversation.sharedName',
  /** "your company name" */ common_conversations_newConversation_sharedCompany = 'common.conversations.newConversation.sharedCompany',
  /** "MC# & DOT#" */ common_conversations_newConversation_sharedMC = 'common.conversations.newConversation.sharedMC',
  /** "your phone & email" */ common_conversations_newConversation_sharedPhoneEmail = 'common.conversations.newConversation.sharedPhoneEmail',
  /** "Start your conversation by typing in the text box below." */ common_conversations_newConversation_noLoadMessage = 'common.conversations.newConversation.noLoadMessage',
  /** "No messages" */ common_conversations_empty_title = 'common.conversations.empty.title',
  /** "No unread messages" */ common_conversations_empty_unreadTitle = 'common.conversations.empty.unreadTitle',
  /** "View all messages" */ common_conversations_empty_viewAllMessages = 'common.conversations.empty.viewAllMessages',
  /** "Messages that you have not read appear here." */ common_conversations_empty_unreadSubtitle = 'common.conversations.empty.unreadSubtitle',
  /** "123Connect helps you contact brokers faster and easier than ever - before you even need to pick up the phone!" */ common_conversations_empty_subtitleCarrier_part1 = 'common.conversations.empty.subtitleCarrier_part1',
  /** "You can start a conversation through Load Details when the Message button is available next to the Call button." */ common_conversations_empty_subtitleCarrier_part2 = 'common.conversations.empty.subtitleCarrier_part2',
  /** "After you start a conversation, new messages will appear here." */ common_conversations_empty_subtitleCarrier_part3 = 'common.conversations.empty.subtitleCarrier_part3',
  /** "Messages will appear here when a Carrier begins a conversation about one of your loads." */ common_conversations_empty_subtitleBroker = 'common.conversations.empty.subtitleBroker',
  /** "Conversation unavailable" */ common_conversations_unavailable_title = 'common.conversations.unavailable.title',
  /** "Sorry for the inconvenience, the conversation you attempted to open is no longer available." */ common_conversations_unavailable_subtitle = 'common.conversations.unavailable.subtitle',
  /** "Attachment(s)" */ common_conversations_attachmentsDialog_title = 'common.conversations.attachmentsDialog.title',
  /** "My documents" */ common_conversations_attachmentsDialog_options_select = 'common.conversations.attachmentsDialog.options.select',
  /** "Add new document" */ common_conversations_attachmentsDialog_options_add = 'common.conversations.attachmentsDialog.options.add',
  /** "Sorry, there was an error opening this attachment." */ common_conversations_viewAttachment_error = 'common.conversations.viewAttachment.error',
  /** "Select attachments" */ common_conversations_sendAttachments_pickerTitle = 'common.conversations.sendAttachments.pickerTitle',
  /** "Message" */ common_conversations_sendAttachments_messageTitle = 'common.conversations.sendAttachments.messageTitle',
  /** "Add message" */ common_conversations_sendAttachments_messagePlaceholder = 'common.conversations.sendAttachments.messagePlaceholder',
  /** "Send attachments" */ common_conversations_sendAttachments_sendAttachmentsTitle = 'common.conversations.sendAttachments.sendAttachmentsTitle',
  /** "Sorry, there was an error sending your attachments." */ common_conversations_sendAttachments_error = 'common.conversations.sendAttachments.error',
  /** " • Last online {{offlineTime}} ago" */ common_conversations_status_lastOnline = 'common.conversations.status.lastOnline',
  /** "All" */ common_conversations_filter_all = 'common.conversations.filter.all',
  /** "Unread" */ common_conversations_filter_unread = 'common.conversations.filter.unread',
  /** "Load details" */ common_conversations_chip_loadDetails = 'common.conversations.chip.loadDetails',
  /** "Load contact" */ common_conversations_chip_contactInfo = 'common.conversations.chip.contactInfo',
  /** "Online" */ common_conversations_chip_online = 'common.conversations.chip.online',
  /** "Offline" */ common_conversations_chip_offline = 'common.conversations.chip.offline',
  /** "Locate load" */ common_conversations_chip_locateLoad = 'common.conversations.chip.locateLoad',
  /** "Load contact" */ common_conversations_contactInfo_title = 'common.conversations.contactInfo.title',
  /** "Agent" */ common_conversations_contactInfo_agent = 'common.conversations.contactInfo.agent',
  /** "Carrier" */ common_conversations_contactInfo_carrier = 'common.conversations.contactInfo.carrier',
  /** "Phone" */ common_conversations_contactInfo_phoneNumber = 'common.conversations.contactInfo.phoneNumber',
  /** "Authority(MC#)" */ common_conversations_contactInfo_authorityList = 'common.conversations.contactInfo.authorityList',
  /** "Power units" */ common_conversations_contactInfo_powerUnits = 'common.conversations.contactInfo.powerUnits',
  /** "US/CAN inspections in the last 24 months" */ common_conversations_contactInfo_usCanInspections = 'common.conversations.contactInfo.usCanInspections',
  /** "Common" */ common_conversations_contactInfo_authorityTypes_common = 'common.conversations.contactInfo.authorityTypes.common',
  /** "Contract" */ common_conversations_contactInfo_authorityTypes_contract = 'common.conversations.contactInfo.authorityTypes.contract',
  /** "Broker" */ common_conversations_contactInfo_authorityTypes_broker = 'common.conversations.contactInfo.authorityTypes.broker',
  /** "Authority" */ common_conversations_contactInfo_authorityBroker = 'common.conversations.contactInfo.authorityBroker',
  /** "Auth. issuance" */ common_conversations_contactInfo_authIssuance = 'common.conversations.contactInfo.authIssuance',
  /** "MC#" */ common_conversations_contactInfo_mc = 'common.conversations.contactInfo.mc',
  /** "DOT#" */ common_conversations_contactInfo_usDot = 'common.conversations.contactInfo.usDot',
  /** "More details" */ common_conversations_contactInfo_moreDetails = 'common.conversations.contactInfo.moreDetails',
  /** "Company" */ common_conversations_contactInfo_company = 'common.conversations.contactInfo.company',
  /** "Authority" */ common_conversations_contactInfo_broker_authority = 'common.conversations.contactInfo.broker.authority',
  /** "City/State" */ common_conversations_contactInfo_cityState = 'common.conversations.contactInfo.cityState',
  /** "Contact info" */ common_conversations_contactInfo_loadContact = 'common.conversations.contactInfo.loadContact',
  /** "Load poster info" */ common_conversations_contactInfo_loadPoster = 'common.conversations.contactInfo.loadPoster',
  /** "Show number" */ common_conversations_contactInfo_showPhone = 'common.conversations.contactInfo.showPhone',
  /** "Carrier contact info" */ common_conversations_contactInfo_carrierInfo = 'common.conversations.contactInfo.carrierInfo',
  /** "Notifications turned off" */ common_conversations_notificationBanner_content = 'common.conversations.notificationBanner.content',
  /** "Activate" */ common_conversations_notificationBanner_activate = 'common.conversations.notificationBanner.activate',
  /** "Push notifications disabled" */ common_conversations_notificationBanner_deniedPopupTitle = 'common.conversations.notificationBanner.deniedPopupTitle',
  /** "Please allow for push notifications in your " */ common_conversations_notificationBanner_deniedPopupContent_part1 = 'common.conversations.notificationBanner.deniedPopupContent.part1',
  /** "browser settings" */ common_conversations_notificationBanner_deniedPopupContent_part2 = 'common.conversations.notificationBanner.deniedPopupContent.part2',
  /** ". 123Loadboard uses notifications to send alerts and communications." */ common_conversations_notificationBanner_deniedPopupContent_part3 = 'common.conversations.notificationBanner.deniedPopupContent.part3',
  /** "123Connect" */ common_conversations_disabled_broker_title = 'common.conversations.disabled.broker.title',
  /** "Keep communicating with Carriers using the tools and methods you prefer and never lose communication or miss a message with " */ common_conversations_disabled_broker_intro = 'common.conversations.disabled.broker.intro',
  /** "Adapts to your current communication methods." */ common_conversations_disabled_broker_reason1 = 'common.conversations.disabled.broker.reason1',
  /** "Exchange communications in real-time." */ common_conversations_disabled_broker_reason2 = 'common.conversations.disabled.broker.reason2',
  /** "Respond faster and do more in less time." */ common_conversations_disabled_broker_reason3 = 'common.conversations.disabled.broker.reason3',
  /** "Start communicating directly with Carriers about the loads you post. This allows you to quickly respond to multiple carriers easily and efficiently." */ common_conversations_disabled_broker_subtitle = 'common.conversations.disabled.broker.subtitle',
  /** "Call {{number}} ext. 2 to activate this free feature." */ common_conversations_disabled_broker_callText = 'common.conversations.disabled.broker.callText',
  /** "Messages Not Available" */ common_conversations_disabled_wholeFeature_title = 'common.conversations.disabled.wholeFeature.title',
  /** "This feature is not currently available." */ common_conversations_disabled_wholeFeature_subtitle = 'common.conversations.disabled.wholeFeature.subtitle',
  /** "There was an error sending your message. Please try again." */ common_conversations_errorSendingMessage = 'common.conversations.errorSendingMessage',
  /** "Sending messages is not available for this conversation." */ common_conversations_userHasMessagingDisabled = 'common.conversations.userHasMessagingDisabled',
  /** "Messages will be delivered via email if they are unread for more than 1 minute. Please call if the issue is urgent." */ common_conversations_userOfflineMessage = 'common.conversations.userOfflineMessage',
  /** "Mark all messages as read" */ common_conversations_markAllAsRead_title = 'common.conversations.markAllAsRead.title',
  /** "All unread messages have been marked as read." */ common_conversations_markAllAsRead_success = 'common.conversations.markAllAsRead.success',
  /** "Failed to mark all unread messages as read." */ common_conversations_markAllAsRead_failure = 'common.conversations.markAllAsRead.failure',
  /** "Current location" */ common_conversations_locationRequest_confirmation_title = 'common.conversations.locationRequest.confirmation.title',
  /** "Confirming will send your current location into this conversation." */ common_conversations_locationRequest_confirmation_content = 'common.conversations.locationRequest.confirmation.content',
  /** "Sending location unavailable" */ common_conversations_locationRequest_unavailable_title = 'common.conversations.locationRequest.unavailable.title',
  /** "Users responsible for the load are not able to send their location." */ common_conversations_locationRequest_unavailable_content = 'common.conversations.locationRequest.unavailable.content',
  /** "Location sent" */ common_conversations_locationRequest_sent = 'common.conversations.locationRequest.sent',
  /** "Request failed" */ common_conversations_locationRequest_failed = 'common.conversations.locationRequest.failed',
  /** "Bid" */ common_vendorBid_bid = 'common.vendorBid.bid',
  /** "Bid sent" */ common_vendorBid_bidSent = 'common.vendorBid.bidSent',
  /** "Before you bid, please ensure that you meet these requirements." */ common_vendorBid_placeBid_bidHeader = 'common.vendorBid.placeBid.bidHeader',
  /** "Place bid" */ common_vendorBid_placeBid_title = 'common.vendorBid.placeBid.title',
  /** "In order to submit this bid, fill in the form below." */ common_vendorBid_placeBid_information = 'common.vendorBid.placeBid.information',
  /** "Enter your total bid." */ common_vendorBid_placeBid_enter = 'common.vendorBid.placeBid.enter',
  /** "Posted rate:" */ common_vendorBid_placeBid_postedRate = 'common.vendorBid.placeBid.postedRate',
  /** "Flat rate ($) *" */ common_vendorBid_placeBid_flatRate = 'common.vendorBid.placeBid.flatRate',
  /** "Place bid" */ common_vendorBid_placeBid_submit = 'common.vendorBid.placeBid.submit',
  /** "Resubmit bid" */ common_vendorBid_placeBid_reSubmitBid = 'common.vendorBid.placeBid.reSubmitBid',
  /** "In order to resubmit this bid, fill in the form below." */ common_vendorBid_placeBid_previousBidPart1 = 'common.vendorBid.placeBid.previousBidPart1',
  /** "Your previous bid was placed on {{date}} for ${{amount}}." */ common_vendorBid_placeBid_previousBidPart2 = 'common.vendorBid.placeBid.previousBidPart2',
  /** "Company: " */ common_vendorBid_placeBid_companyName = 'common.vendorBid.placeBid.companyName',
  /** "DOT#: " */ common_vendorBid_placeBid_companyDOT = 'common.vendorBid.placeBid.companyDOT',
  /** "Trailer type: " */ common_vendorBid_placeBid_equipmentTypes = 'common.vendorBid.placeBid.equipmentTypes',
  /** "Trailer specs: " */ common_vendorBid_placeBid_equipmentSpecs = 'common.vendorBid.placeBid.equipmentSpecs',
  /** "Item: " */ common_vendorBid_placeBid_commodity = 'common.vendorBid.placeBid.commodity',
  /** "Notes: " */ common_vendorBid_placeBid_equipmentNotes = 'common.vendorBid.placeBid.equipmentNotes',
  /** "Submit a bid to reveal this company's phone number. Please tell the broker that you found this load on 123Loadboard." */ common_vendorBid_placeBid_submitBidCallMessage = 'common.vendorBid.placeBid.submitBidCallMessage',
  /** "Bid placed" */ common_vendorBid_bidPlaced_title = 'common.vendorBid.bidPlaced.title',
  /** "Your bid for this load has been sent to the company." */ common_vendorBid_bidPlaced_part1 = 'common.vendorBid.bidPlaced.part1',
  /** "You will be contacted directly if your bid is selected." */ common_vendorBid_bidPlaced_part2 = 'common.vendorBid.bidPlaced.part2',
  /** "Ok" */ common_vendorBid_bidPlaced_button = 'common.vendorBid.bidPlaced.button',
  /** "Failed to place bid" */ common_vendorBid_failedToPlaceBid_title = 'common.vendorBid.failedToPlaceBid.title',
  /** "An error occurred while sending a bid to the company." */ common_vendorBid_failedToPlaceBid_content = 'common.vendorBid.failedToPlaceBid.content',
  /** "An error occurred while making the request. This may occur if the load is already gone, or if your DOT# can not be found in the system. You may call the load poster for more information about this load." */ common_vendorBid_failedToPlaceBid_not_found = 'common.vendorBid.failedToPlaceBid.not_found',
  /** "The load is no longer available." */ common_vendorBid_failedToPlaceBid_unavailable = 'common.vendorBid.failedToPlaceBid.unavailable',
  /** "Not Approved" */ common_vendorBid_failedToPlaceBid_disqualified_title = 'common.vendorBid.failedToPlaceBid.disqualified.title',
  /** "The load poster has determined that you're not qualified to haul this load. This may happen if your authority is too new, or your insurance is not high enough.\n\nIf you believe this is an error, please contact the load poster directly." */ common_vendorBid_failedToPlaceBid_disqualified_content = 'common.vendorBid.failedToPlaceBid.disqualified.content',
  /** "Invalid DOT or MC number" */ common_vendorBid_failedToPlaceBid_invalidDotMC_title = 'common.vendorBid.failedToPlaceBid.invalidDotMC.title',
  /** "The DOT number cannot be the same as the MC number. Please adjust your company information and try again." */ common_vendorBid_failedToPlaceBid_invalidDotMC_content = 'common.vendorBid.failedToPlaceBid.invalidDotMC.content',
  /** "Required" */ common_vendorBid_dotPopUp_title = 'common.vendorBid.dotPopUp.title',
  /** "To place a bid, please enter your DOT#." */ common_vendorBid_dotPopUp_info = 'common.vendorBid.dotPopUp.info',
  /** "DOT # *" */ common_vendorBid_dotPopUp_textFieldLabel = 'common.vendorBid.dotPopUp.textFieldLabel',
  /** "Submit" */ common_vendorBid_dotPopUp_button = 'common.vendorBid.dotPopUp.button',
  /** "DOT # was updated" */ common_vendorBid_dotPopUp_updateStatus_success = 'common.vendorBid.dotPopUp.updateStatus.success',
  /** "DOT # failed to update" */ common_vendorBid_dotPopUp_updateStatus_failure = 'common.vendorBid.dotPopUp.updateStatus.failure',
  /** "Bid placed" */ common_vendorBid_bidItem_placedOn = 'common.vendorBid.bidItem.placedOn',
  /** "A bid for {{amount}} was placed on {{broker}}'s load." */ common_vendorBid_bidItem_message = 'common.vendorBid.bidItem.message',
  /** "No bids placed" */ common_vendorBid_empty_title = 'common.vendorBid.empty.title',
  /** "When you submit a bid on a load, those offers will appear here.\n\nBid offers will be available for 2 weeks after being placed." */ common_vendorBid_empty_message = 'common.vendorBid.empty.message',
  /** "Book now" */ common_bookNow_title = 'common.bookNow.title',
  /** "Booked" */ common_bookNow_booked = 'common.bookNow.booked',
  /** "Pending" */ common_bookNow_pending = 'common.bookNow.pending',
  /** "Booking pending" */ common_bookNow_bookingPending = 'common.bookNow.bookingPending',
  /** "Before you Book now, please ensure that you meet these requirements." */ common_bookNow_dialog_information = 'common.bookNow.dialog.information',
  /** "When you “Book now”, you agree that you can haul this load based on the provided load details at:" */ common_bookNow_dialog_content_part1 = 'common.bookNow.dialog.content.part1',
  /** "You will be contacted by the load poster shortly after booking this load." */ common_bookNow_dialog_content_part2 = 'common.bookNow.dialog.content.part2',
  /** "Book now to reveal this company’s phone number. Please tell the broker that you found this load on 123Loadboard." */ common_bookNow_dialog_submit = 'common.bookNow.dialog.submit',
  /** "The load poster will contact you shortly to confirm booking. This normally takes between 15 to 30 minutes." */ common_bookNow_dialog_pendingLong = 'common.bookNow.dialog.pendingLong',
  /** "The load poster will contact you shortly to confirm booking. This normally takes between 1 to 2 minutes." */ common_bookNow_dialog_pendingShort = 'common.bookNow.dialog.pendingShort',
  /** "You already booked this load on {{date}} at the posted rate {{amount}}. Please contact the load poster if necessary." */ common_bookNow_dialog_alreadyBooked = 'common.bookNow.dialog.alreadyBooked',
  /** "Booking unavailable" */ common_bookNow_dialog_unavailable = 'common.bookNow.dialog.unavailable',
  /** "The load is no longer available." */ common_bookNow_dialog_unavailable_content = 'common.bookNow.dialog.unavailable_content',
  /** "An error occurred while making the request. This may occur if the load is already gone, or if your DOT# can not be found in the system. You may call the load poster for more information about this load." */ common_bookNow_dialog_not_found = 'common.bookNow.dialog.not_found',
  /** "It’s too soon since you booked your last load, which can be found in the “My loads” section. Please try again later." */ common_bookNow_dialog_recentlyBooked = 'common.bookNow.dialog.recentlyBooked',
  /** "Booking request sent" */ common_bookNow_bookedSuccessfully = 'common.bookNow.bookedSuccessfully',
  /** "Verify your DOT#" */ common_bookNow_dotVerification_popup_title = 'common.bookNow.dotVerification.popup.title',
  /** "Only users with a verified DOT# can use \"Book now\" to book loads." */ common_bookNow_dotVerification_popup_content = 'common.bookNow.dotVerification.popup.content',
  /** "Verify now" */ common_bookNow_dotVerification_popup_button = 'common.bookNow.dotVerification.popup.button',
  /** "Booking request confirmed" */ common_bookNow_banner_confirmedTitle = 'common.bookNow.banner.confirmedTitle',
  /** "{{company}} will send a rate confirmation to {{email}} shortly." */ common_bookNow_banner_confirmed = 'common.bookNow.banner.confirmed',
  /** "Booking request pending" */ common_bookNow_banner_pendingTitle = 'common.bookNow.banner.pendingTitle',
  /** "The load poster will contact you shortly to confirm booking. This normally takes between 15 to 30 minutes." */ common_bookNow_banner_pendingLong = 'common.bookNow.banner.pendingLong',
  /** "The load poster will contact you shortly to confirm booking. This normally takes between 1 to 2 minutes." */ common_bookNow_banner_pendingShort = 'common.bookNow.banner.pendingShort',
  /** "Booking request rejected" */ common_bookNow_banner_rejectedTitle = 'common.bookNow.banner.rejectedTitle',
  /** "Your booking request has been rejected by the load poster." */ common_bookNow_banner_rejected = 'common.bookNow.banner.rejected',
  /** "You're leaving 123Loadboard" */ common_vHub_vHubMEMPopupHeader = 'common.vHub.vHubMEMPopupHeader',
  /** "You're leaving \n123Loadboard" */ common_vHub_vHubPopupHeader = 'common.vHub.vHubPopupHeader',
  /** "This Load will be booked through vHub. Please note that their terms and conditions may differ from 123Loadboard. The website will open in a new tab." */ common_vHub_vHubPopupDetails = 'common.vHub.vHubPopupDetails',
  /** "123Loadboard is not responsible for any content on third party websites." */ common_vHub_vHubPopupSubDetails = 'common.vHub.vHubPopupSubDetails',
  /** "Proceed" */ common_vHub_vHubPopupButtonLabel = 'common.vHub.vHubPopupButtonLabel',
  /** "Book" */ common_vHub_vHubBookWithButtonLabel = 'common.vHub.vHubBookWithButtonLabel',
  /** "Help" */ common_help_title = 'common.help.title',
  /** "FAQs" */ common_help_faq = 'common.help.faq',
  /** "Video tutorials" */ common_help_videoTutorials = 'common.help.videoTutorials',
  /** "Service code" */ common_help_serviceCode_serviceCodeTitle = 'common.help.serviceCode.serviceCodeTitle',
  /** "Generate code" */ common_help_serviceCode_generateCode = 'common.help.serviceCode.generateCode',
  /** "Service codes are a helpful way for our 123Loadboard support team to locate and troubleshoot accounts quicker. If you need technical assistance, please ensure that you are speaking to a representative and have your service code ready." */ common_help_serviceCode_description = 'common.help.serviceCode.description',
  /** "Screen sharing" */ common_help_screenSharing_title = 'common.help.screenSharing.title',
  /** "Generate code" */ common_help_screenSharing_generateCode = 'common.help.screenSharing.generateCode',
  /** "Screen sharing will allow a 123Loadboard representative to better help you with troubleshooting. To activate the screen share, please call our Customer Support and provide them with your sharing code." */ common_help_screenSharing_description = 'common.help.screenSharing.description',
  /** "End remote support\nsession" */ common_help_screenSharing_endSession_title = 'common.help.screenSharing.endSession.title',
  /** "Do you want to stop this screen sharing session?" */ common_help_screenSharing_endSession_description = 'common.help.screenSharing.endSession.description',
  /** "Support request" */ common_help_screenSharing_startSession_title = 'common.help.screenSharing.startSession.title',
  /** "A 123Loadboard support agent wants to start a screen sharing session with you. Do you accept?" */ common_help_screenSharing_startSession_description = 'common.help.screenSharing.startSession.description',
  /** "Reject" */ common_help_screenSharing_startSession_reject = 'common.help.screenSharing.startSession.reject',
  /** "Accept" */ common_help_screenSharing_startSession_accept = 'common.help.screenSharing.startSession.accept',
  /** "Are you sure you want to clear all announcements?" */ common_announcements_clearConfirmation = 'common.announcements.clearConfirmation',
  /** "Service Related" */ common_announcements_serviceRelated = 'common.announcements.serviceRelated',
  /** "Notice" */ common_announcements_notice = 'common.announcements.notice',
  /** "Announcements" */ common_announcements_announcements = 'common.announcements.announcements',
  /** "Announce-\nments" */ common_announcements_announcementsNewLine = 'common.announcements.announcementsNewLine',
  /** "Announcement" */ common_announcements_announcement = 'common.announcements.announcement',
  /** "Newsletter" */ common_announcements_newsletter = 'common.announcements.newsletter',
  /** "Promotion" */ common_announcements_promotion = 'common.announcements.promotion',
  /** "Announcements cleared successfully." */ common_announcements_clearSuccess = 'common.announcements.clearSuccess',
  /** "An error occurred while clearing announcements." */ common_announcements_clearFailure = 'common.announcements.clearFailure',
  /** "Announcement deleted successfully." */ common_announcements_deleteSuccess = 'common.announcements.deleteSuccess',
  /** "An error occurred while deleting the announcement." */ common_announcements_deleteFailure = 'common.announcements.deleteFailure',
  /** "No announcements available" */ common_announcements_emptyList_title = 'common.announcements.emptyList.title',
  /** "We didn't find anything to show here." */ common_announcements_emptyList_content = 'common.announcements.emptyList.content',
  /** "Phone verification" */ common_phoneVerification_title = 'common.phoneVerification.title',
  /** "Verify your primary phone" */ common_phoneVerification_creation_title = 'common.phoneVerification.creation.title',
  /** "The verification code will be sent to {{phone}}." */ common_phoneVerification_creation_content = 'common.phoneVerification.creation.content',
  /** "Select verification method" */ common_phoneVerification_creation_selectVerificationMethod = 'common.phoneVerification.creation.selectVerificationMethod',
  /** "Text message (SMS)" */ common_phoneVerification_creation_textMessage = 'common.phoneVerification.creation.textMessage',
  /** "Voice message" */ common_phoneVerification_creation_voiceMessage = 'common.phoneVerification.creation.voiceMessage',
  /** "Send {{type}} message" */ common_phoneVerification_creation_sendMessage = 'common.phoneVerification.creation.sendMessage',
  /** "voice" */ common_phoneVerification_creation_messageTypes_voice = 'common.phoneVerification.creation.messageTypes.voice',
  /** "text" */ common_phoneVerification_creation_messageTypes_text = 'common.phoneVerification.creation.messageTypes.text',
  /** "Your phone number has been verified. This will make your truck stand out when Brokers are looking for Carriers." */ common_phoneVerification_creation_success = 'common.phoneVerification.creation.success',
  /** "Cannot verify landline phone by text" */ common_phoneVerification_creation_error_landline_title = 'common.phoneVerification.creation.error.landline.title',
  /** "This phone number is a landline and cannot be verified by text, would you like to send the verification by voice message?" */ common_phoneVerification_creation_error_landline_content = 'common.phoneVerification.creation.error.landline.content',
  /** "Something went wrong" */ common_phoneVerification_creation_error_other = 'common.phoneVerification.creation.error.other',
  /** "Cannot send verification message to invalid number." */ common_phoneVerification_creation_error_wrongNumber = 'common.phoneVerification.creation.error.wrongNumber',
  /** "Too many attempts in a short period of time. Try again in a few minutes." */ common_phoneVerification_creation_error_tooManyAttempts = 'common.phoneVerification.creation.error.tooManyAttempts',
  /** "A problem occurred. Verify that the phone number is valid." */ common_phoneVerification_creation_error_badRequest = 'common.phoneVerification.creation.error.badRequest',
  /** "Verify your primary phone" */ common_phoneVerification_codeInput_title = 'common.phoneVerification.codeInput.title',
  /** "Enter the 6-digit code sent to you at {{phone}}" */ common_phoneVerification_codeInput_content = 'common.phoneVerification.codeInput.content',
  /** "Expires in {{countdown}} min" */ common_phoneVerification_codeInput_expiresIn = 'common.phoneVerification.codeInput.expiresIn',
  /** "Expired" */ common_phoneVerification_codeInput_expired = 'common.phoneVerification.codeInput.expired',
  /** "Resend code" */ common_phoneVerification_codeInput_resendCode = 'common.phoneVerification.codeInput.resendCode',
  /** "Verification code" */ common_phoneVerification_codeInput_verificationCode = 'common.phoneVerification.codeInput.verificationCode',
  /** "Verify" */ common_phoneVerification_codeInput_verify = 'common.phoneVerification.codeInput.verify',
  /** "Verification failed" */ common_phoneVerification_codeInput_invalidCode_title = 'common.phoneVerification.codeInput.invalidCode.title',
  /** "The code submitted was incorrect, please try again." */ common_phoneVerification_codeInput_invalidCode_content = 'common.phoneVerification.codeInput.invalidCode.content',
  /** "Code you entered has expired. Press back and try again." */ common_phoneVerification_codeInput_toast_expiredCode = 'common.phoneVerification.codeInput.toast.expiredCode',
  /** "Max attempts to check a verification code reached. Please wait until the current verification expires to generate a new code." */ common_phoneVerification_codeInput_toast_tooManyAttempts = 'common.phoneVerification.codeInput.toast.tooManyAttempts',
  /** "Phone verified" */ common_phoneVerification_success_header = 'common.phoneVerification.success.header',
  /** "Verified" */ common_phoneVerification_success_title = 'common.phoneVerification.success.title',
  /** "Your phone number has been successfully verified." */ common_phoneVerification_success_subtitle = 'common.phoneVerification.success.subtitle',
  /** "Card number" */ common_paymentMethod_cardNumber = 'common.paymentMethod.cardNumber',
  /** "CVV" */ common_paymentMethod_cvv = 'common.paymentMethod.cvv',
  /** "CID" */ common_paymentMethod_cid = 'common.paymentMethod.cid',
  /** "Zip/Postal code" */ common_paymentMethod_zip = 'common.paymentMethod.zip',
  /** "Card holder name" */ common_paymentMethod_cardName = 'common.paymentMethod.cardName',
  /** "Billing address" */ common_paymentMethod_billingAddress = 'common.paymentMethod.billingAddress',
  /** "The billing address is the address that appears on your credit card bill or bank statement." */ common_paymentMethod_billingAddressInfo = 'common.paymentMethod.billingAddressInfo',
  /** "Security Code" */ common_paymentMethod_securityCode = 'common.paymentMethod.securityCode',
  /** "Enter your current billing information to update your payment method. The existing card on file will be replaced." */ common_paymentMethod_screenInfo = 'common.paymentMethod.screenInfo',
  /** "Your payment method has been updated" */ common_paymentMethod_methodWasUpdated = 'common.paymentMethod.methodWasUpdated',
  /** "Other" */ common_paymentMethod_radioList_other = 'common.paymentMethod.radioList.other',
  /** "Expiration date" */ common_paymentMethod_expirationInfo_title = 'common.paymentMethod.expirationInfo.title',
  /** "You should be able to find this date on the front of your card, under the card number." */ common_paymentMethod_expirationInfo_description = 'common.paymentMethod.expirationInfo.description',
  /** "CVV" */ common_paymentMethod_CVVinfo_title = 'common.paymentMethod.CVVinfo.title',
  /** "A 3-digit code on your credit card, you can find this on the back of your card." */ common_paymentMethod_CVVinfo_description = 'common.paymentMethod.CVVinfo.description',
  /** "CID" */ common_paymentMethod_CIDinfo_title = 'common.paymentMethod.CIDinfo.title',
  /** "A 4-digit code located above your account number on the front of your card." */ common_paymentMethod_CIDinfo_description = 'common.paymentMethod.CIDinfo.description',
  /** "Membership" */ common_membership_title = 'common.membership.title',
  /** "Membership Plan" */ common_membership_membershipPlan = 'common.membership.membershipPlan',
  /** "Manage plans" */ common_membership_managePlan = 'common.membership.managePlan',
  /** "Key features" */ common_membership_keyFeatues = 'common.membership.keyFeatues',
  /** "${{price}}/month" */ common_membership_planPrice = 'common.membership.planPrice',
  /** "${{price}}/year" */ common_membership_planPricePerYear = 'common.membership.planPricePerYear',
  /** "Unlock this feature" */ common_membership_unlockThisFeature = 'common.membership.unlockThisFeature',
  /** "Standard" */ common_membership_plan_standard = 'common.membership.plan.standard',
  /** "Premium" */ common_membership_plan_premium = 'common.membership.plan.premium',
  /** "Premium Plus" */ common_membership_plan_premiumPlus = 'common.membership.plan.premiumPlus',
  /** "Your membership plan has been updated." */ common_membership_upgradeResultScreen_success_title = 'common.membership.upgradeResultScreen.success.title',
  /** "Continue" */ common_membership_upgradeResultScreen_success_button = 'common.membership.upgradeResultScreen.success.button',
  /** "There was a problem with your credit card." */ common_membership_upgradeResultScreen_failure_title = 'common.membership.upgradeResultScreen.failure.title',
  /** "Please check your payment method and try again." */ common_membership_upgradeResultScreen_failure_subtitle = 'common.membership.upgradeResultScreen.failure.subtitle',
  /** "Need help? Call {{number}}" */ common_membership_upgradeResultScreen_failure_help = 'common.membership.upgradeResultScreen.failure.help',
  /** "Try again" */ common_membership_upgradeResultScreen_failure_button = 'common.membership.upgradeResultScreen.failure.button',
  /** "Update plan" */ common_membership_upgradePlanTitle = 'common.membership.upgradePlanTitle',
  /** "After clicking " */ common_membership_upgradePlanFirstMessage = 'common.membership.upgradePlanFirstMessage',
  /** ", your plan will be updated to " */ common_membership_upgradePlanSecondMessage = 'common.membership.upgradePlanSecondMessage',
  /** "Grow your business by upgrading your plan." */ common_membership_section = 'common.membership.section',
  /** "Learn more" */ common_membership_learnMore = 'common.membership.learnMore',
  /** "Add-ons" */ common_membership_addOn_title = 'common.membership.addOn.title',
  /** "Additional features" */ common_membership_addOn_sectionTitle = 'common.membership.addOn.sectionTitle',
  /** "Available add-ons for your current plan" */ common_membership_addOn_panelTitle = 'common.membership.addOn.panelTitle',
  /** "Your Add-ons have been updated." */ common_membership_addOn_updated = 'common.membership.addOn.updated',
  /** "Please check your payment method and try again." */ common_membership_addOn_updateFailSub = 'common.membership.addOn.updateFailSub',
  /** "Unable to update" */ common_membership_addOn_popupTitle = 'common.membership.addOn.popupTitle',
  /** "The Add-on you have selected is already part of your current plan." */ common_membership_addOn_popupDetail = 'common.membership.addOn.popupDetail',
  /** "Open settings" */ common_membership_openSettings = 'common.membership.openSettings',
  /** "Manage subscription" */ common_membership_manageSubscription = 'common.membership.manageSubscription',
  /** "Your 123Loadboard account is managed through the App Store. To make changes to your subscription, please do so through the App Store settings on your Apple device settings." */ common_membership_openAppleManageSubscriptionSettings = 'common.membership.openAppleManageSubscriptionSettings',
  /** "Changes to your plan are effective upon confirmation from Apple, but may take some time to reflect on 123Loadboard." */ common_membership_applePlanUpgradeDelay = 'common.membership.applePlanUpgradeDelay',
  /** "Manage plan" */ common_upgradePlanPopup_button = 'common.upgradePlanPopup.button',
  /** "Add-ons" */ common_upgradePlanPopup_addOns = 'common.upgradePlanPopup.addOns',
  /** "This feature is not available in your plan" */ common_upgradePlanPopup_title = 'common.upgradePlanPopup.title',
  /** "Driving time is available as part of the Premium and Premium plus membership plans, or can be added with the PC*Miler add-on." */ common_upgradePlanPopup_drivingTime = 'common.upgradePlanPopup.drivingTime',
  /** "Rate Check is available as part of the Premium Plus membership plans, or can be added with Rate Check add-on." */ common_upgradePlanPopup_rateCheck = 'common.upgradePlanPopup.rateCheck',
  /** "Credit ratings are only available as part of the Premium and Premium Plus membership plans." */ common_upgradePlanPopup_creditRating = 'common.upgradePlanPopup.creditRating',
  /** "This feature is available as part of the Premium and Premium Plus membership plans, or can be added with the PC*Miler add-on." */ common_upgradePlanPopup_mileageCalculator = 'common.upgradePlanPopup.mileageCalculator',
  /** "Trip" */ common_profitCalculator_trip = 'common.profitCalculator.trip',
  /** "If you’re unsure of your trip mileage, enter your Pick up and Drop off locations to pre-fill your miles." */ common_profitCalculator_tripDescription = 'common.profitCalculator.tripDescription',
  /** "Fix now" */ common_paymentDeclinedBanner_actions_fixNow = 'common.paymentDeclinedBanner.actions.fixNow',
  /** "here" */ common_paymentDeclinedBanner_actions_here = 'common.paymentDeclinedBanner.actions.here',
  /** "View details" */ common_paymentDeclinedBanner_actions_viewDetails = 'common.paymentDeclinedBanner.actions.viewDetails',
  /** "Your membership has been downgraded to Basic because of overdue invoices. " */ common_paymentDeclinedBanner_accountExpired_message = 'common.paymentDeclinedBanner.accountExpired.message',
  /** "Your membership is about to be suspended because of an overdue invoice. " */ common_paymentDeclinedBanner_accountExpiringSoon_message = 'common.paymentDeclinedBanner.accountExpiringSoon.message',
  /** "Your membership is about to be suspended because of an overdue invoice. Please contact your account administrator." */ common_paymentDeclinedBanner_accountExpiringSoon_messageNonAdmin = 'common.paymentDeclinedBanner.accountExpiringSoon.messageNonAdmin',
  /** "We are unable to process payments on your account. " */ common_paymentDeclinedBanner_hardDecline_message = 'common.paymentDeclinedBanner.hardDecline.message',
  /** "There was an issue with processing your membership payment. Please update your billing information " */ common_paymentDeclinedBanner_hardDecline_messageFirstPart = 'common.paymentDeclinedBanner.hardDecline.messageFirstPart',
  /** "You have access until {{date}}, after that your account will be processed to close." */ common_paymentDeclinedBanner_hardDecline_messageLastPart = 'common.paymentDeclinedBanner.hardDecline.messageLastPart',
  /** "Your membership may be affected due to a billing issue." */ common_paymentDeclinedBanner_hardDecline_messageIOS = 'common.paymentDeclinedBanner.hardDecline.messageIOS',
  /** "We are unable to process payments on your account. Please contact your account administrator." */ common_paymentDeclinedBanner_hardDecline_messageNonAdmin = 'common.paymentDeclinedBanner.hardDecline.messageNonAdmin',
  /** "We were unable to process a payment on your account. " */ common_paymentDeclinedBanner_softDecline_message = 'common.paymentDeclinedBanner.softDecline.message',
  /** "There was an issue with processing your membership payment. Please update your billing information " */ common_paymentDeclinedBanner_softDecline_messageFirstPart = 'common.paymentDeclinedBanner.softDecline.messageFirstPart',
  /** "You have access until {{date}}, after that your account will be processed to close." */ common_paymentDeclinedBanner_softDecline_messageLastPart = 'common.paymentDeclinedBanner.softDecline.messageLastPart',
  /** "Your membership may be affected due to a billing issue." */ common_paymentDeclinedBanner_softDecline_messageIOS = 'common.paymentDeclinedBanner.softDecline.messageIOS',
  /** "We were unable to process a payment on your account. Please contact your account administrator." */ common_paymentDeclinedBanner_softDecline_messageNonAdmin = 'common.paymentDeclinedBanner.softDecline.messageNonAdmin',
  /** "You have a billing issue on file. " */ common_paymentDeclinedBanner_generic_message = 'common.paymentDeclinedBanner.generic.message',
  /** "click here" */ common_paymentDeclinedBanner_unsubscribeBannerMessageLink = 'common.paymentDeclinedBanner.unsubscribeBannerMessageLink',
  /** "You have unsubscribed from your membership." */ common_paymentDeclinedBanner_unsubscribedMessage = 'common.paymentDeclinedBanner.unsubscribedMessage',
  /** "You will continue to have access until {{date}}." */ common_paymentDeclinedBanner_accessUntil = 'common.paymentDeclinedBanner.accessUntil',
  /** "If you wish to re-activate your account, " */ common_paymentDeclinedBanner_reactivate = 'common.paymentDeclinedBanner.reactivate',
  /** "To make changes to your company information, please contact our posting department at " */ common_businessProfile_lockedCompany = 'common.businessProfile.lockedCompany',
  /** "DOT#" */ common_businessProfile_dot = 'common.businessProfile.dot',
  /** "Business profile" */ common_businessProfile_title = 'common.businessProfile.title',
  /** "Address:" */ common_businessProfile_addressTitle = 'common.businessProfile.addressTitle',
  /** "Company phone:" */ common_businessProfile_companyPhoneTitle = 'common.businessProfile.companyPhoneTitle',
  /** "Company name:" */ common_businessProfile_companyNameTitle = 'common.businessProfile.companyNameTitle',
  /** "DOT#:" */ common_businessProfile_dotNumberTitle = 'common.businessProfile.dotNumberTitle',
  /** "MC#:" */ common_businessProfile_mcNumberTitle = 'common.businessProfile.mcNumberTitle',
  /** "Use FMCSA company details" */ common_businessProfile_dotBusinessProfileOption_noDot_title = 'common.businessProfile.dotBusinessProfileOption.noDot.title',
  /** "Please enter your DOT# above to activate FMCSA company details." */ common_businessProfile_dotBusinessProfileOption_noDot_text = 'common.businessProfile.dotBusinessProfileOption.noDot.text',
  /** "Use FMCSA company details" */ common_businessProfile_dotBusinessProfileOption_noInformationAvailable_title = 'common.businessProfile.dotBusinessProfileOption.noInformationAvailable.title',
  /** "We were unable to find information for the entered DOT#." */ common_businessProfile_dotBusinessProfileOption_noInformationAvailable_text = 'common.businessProfile.dotBusinessProfileOption.noInformationAvailable.text',
  /** "Use custom company details" */ common_businessProfile_dotBusinessProfileOption_customProfile_title = 'common.businessProfile.dotBusinessProfileOption.customProfile.title',
  /** "Use FMCSA company details" */ common_businessProfile_dotBusinessProfileOption_volpeProfile_title = 'common.businessProfile.dotBusinessProfileOption.volpeProfile.title',
  /** "Add extension" */ common_dotVerification_addExtension = 'common.dotVerification.addExtension',
  /** "Please enter your extension below to continue DOT# verification." */ common_dotVerification_addExtensionText = 'common.dotVerification.addExtensionText',
  /** "Ext." */ common_dotVerification_ext = 'common.dotVerification.ext',
  /** "Remove extension" */ common_dotVerification_removeExtension = 'common.dotVerification.removeExtension',
  /** "Verification via SMS is not available for numbers with extensions. If you wish to verify via text message (SMS), remove the extension from this number." */ common_dotVerification_VerificationViaSMS = 'common.dotVerification.VerificationViaSMS',
  /** "This information is provided by FMCSA. To edit or update the information, " */ common_dotVerification_fmcsaBanner_text = 'common.dotVerification.fmcsaBanner.text',
  /** "contact FMCSA" */ common_dotVerification_fmcsaBanner_contactFMCSA = 'common.dotVerification.fmcsaBanner.contactFMCSA',
  /** "FMCSA" */ common_dotVerification_fmcsaBanner_button = 'common.dotVerification.fmcsaBanner.button',
  /** "Verify your DOT# {{dot}}" */ common_dotVerification_popup_title = 'common.dotVerification.popup.title',
  /** "Only users with a verified DOT# can use \"Book now\" to book loads." */ common_dotVerification_popup_content = 'common.dotVerification.popup.content',
  /** "Verify now" */ common_dotVerification_popup_button = 'common.dotVerification.popup.button',
  /** "To activate add-on, please enter your DOT#." */ common_dotVerification_popup_addOnTitle = 'common.dotVerification.popup.addOnTitle',
  /** "DOT# verification" */ common_dotVerification_title = 'common.dotVerification.title',
  /** "DOT# verified" */ common_dotVerification_dotNumberVerified = 'common.dotVerification.dotNumberVerified',
  /** "Verify DOT#" */ common_dotVerification_verifyDotNumber = 'common.dotVerification.verifyDotNumber',
  /** "Verify your DOT#" */ common_dotVerification_verifyYourDotNumber = 'common.dotVerification.verifyYourDotNumber',
  /** "Please enter DOT#." */ common_dotVerification_pleaseEnterDot = 'common.dotVerification.pleaseEnterDot',
  /** "Send request" */ common_dotVerification_sendRequest = 'common.dotVerification.sendRequest',
  /** "Brokers and shippers prefer to work with Carriers that have verified their DOT#. You can verify your DOT# via phone confirmation. Please select a phone number that you have access to." */ common_dotVerification_phoneListPanelText = 'common.dotVerification.phoneListPanelText',
  /** "Brokers and shippers prefer to work with Carriers that have verified their DOT#s. \n\nYou can verify your DOT# through your phone number(s) via SMS or voice confirmation" */ common_dotVerification_phoneListOptionPanelText = 'common.dotVerification.phoneListOptionPanelText',
  /** "; or you can verify through MyCarrierPortal." */ common_dotVerification_verifyThroughMCP = 'common.dotVerification.verifyThroughMCP',
  /** "Select preferred option" */ common_dotVerification_listPanelSelectOption = 'common.dotVerification.listPanelSelectOption',
  /** "You can verify your DOT# with a confirmation code that will be sent to" */ common_dotVerification_verificationCodeWillBeSentTo = 'common.dotVerification.verificationCodeWillBeSentTo',
  /** "Please select your preferred activation method." */ common_dotVerification_selectActivationMethod = 'common.dotVerification.selectActivationMethod',
  /** "Select verification method" */ common_dotVerification_selectVerificationMethodTitle = 'common.dotVerification.selectVerificationMethodTitle',
  /** "Enter code" */ common_dotVerification_enterCode = 'common.dotVerification.enterCode',
  /** "Verification error" */ common_dotVerification_errorPanel_title = 'common.dotVerification.errorPanel.title',
  /** "We were unable to find information\n for the entered DOT#:" */ common_dotVerification_errorPanel_unableToFindByDot = 'common.dotVerification.errorPanel.unableToFindByDot',
  /** "Please make sure your DOT# is\n entered correctly or try again later." */ common_dotVerification_errorPanel_makeSureDotEntered = 'common.dotVerification.errorPanel.makeSureDotEntered',
  /** "Your DOT number has been successfully verified." */ common_dotVerification_resultPanel_successfullyVerified = 'common.dotVerification.resultPanel.successfullyVerified',
  /** "MyCarrierPortal" */ common_dotVerification_myCarrierPortal_title = 'common.dotVerification.myCarrierPortal.title',
  /** "Instructions sent" */ common_dotVerification_myCarrierPortal_sent = 'common.dotVerification.myCarrierPortal.sent',
  /** "Verification instructions have been sent to " */ common_dotVerification_myCarrierPortal_instructionsSentTo = 'common.dotVerification.myCarrierPortal.instructionsSentTo',
  /** "This email was sent on " */ common_dotVerification_myCarrierPortal_emailSentOn = 'common.dotVerification.myCarrierPortal.emailSentOn',
  /** "Please open the email and follow the provided instructions. When this process is completed and we receive confirmation from MyCarrierPortal, your DOT# verification status will be updated." */ common_dotVerification_myCarrierPortal_instructions = 'common.dotVerification.myCarrierPortal.instructions',
  /** "If you haven't received an email, make sure to check your spam and deleted items folders for any items sent on " */ common_dotVerification_myCarrierPortal_instructions2 = 'common.dotVerification.myCarrierPortal.instructions2',
  /** "Verification Already Initiated" */ common_dotVerification_myCarrierPortal_verificationInitiated = 'common.dotVerification.myCarrierPortal.verificationInitiated',
  /** "Your verification request has been previously initiated from another source. Once MCP confirms your verification, your DOT number will be marked as verified." */ common_dotVerification_myCarrierPortal_verificationDescription = 'common.dotVerification.myCarrierPortal.verificationDescription',
  /** "Should you have any questions or require further assistance with the process, MCP's team is available to help you understand the process and provide any additional support you might need. You can contact them at (818) 453-8591 or email " */ common_dotVerification_myCarrierPortal_verificationdescription2 = 'common.dotVerification.myCarrierPortal.verificationdescription2',
  /** "help@assureassist.com" */ common_dotVerification_myCarrierPortal_MCPEmail = 'common.dotVerification.myCarrierPortal.MCPEmail',
  /** "Permissions" */ common_permissions_title = 'common.permissions.title',
  /** "Login Sessions" */ common_permissions_loginSessions = 'common.permissions.loginSessions',
  /** "Active login sessions" */ common_permissions_loginSessionsHeader = 'common.permissions.loginSessionsHeader',
  /** "{{platform}} currently logged in on {{userAgent}}" */ common_permissions_user = 'common.permissions.user',
  /** "Created: {{date}}" */ common_permissions_createdOn = 'common.permissions.createdOn',
  /** "Updated: {{date}}" */ common_permissions_updatedOn = 'common.permissions.updatedOn',
  /** "IP address: {{ip}}" */ common_permissions_ipAddress = 'common.permissions.ipAddress',
  /** "Attention, this is your current session." */ common_permissions_currentSessionWarning = 'common.permissions.currentSessionWarning',
  /** "You will be logged out of this device ({{device}}) until you log in again." */ common_permissions_logoutDialog = 'common.permissions.logoutDialog',
  /** "This integration will no longer be available until you reactivate." */ common_permissions_deactivateDialog = 'common.permissions.deactivateDialog',
  /** "Integrations" */ common_permissions_integrations = 'common.permissions.integrations',
  /** "Active integrations" */ common_permissions_integratorsHeader = 'common.permissions.integratorsHeader',
  /** "Activated on {{date}}" */ common_permissions_activatedOn = 'common.permissions.activatedOn',
  /** "Integration permission details" */ common_permissions_integrationsHeader = 'common.permissions.integrationsHeader',
  /** "Logged out successfully" */ common_permissions_snackbarLogoutSuccess = 'common.permissions.snackbarLogoutSuccess',
  /** "Logout unsuccessfull" */ common_permissions_snackbarLogoutFailure = 'common.permissions.snackbarLogoutFailure',
  /** "Successfully deactivated" */ common_permissions_snackbarDeactivationSuccess = 'common.permissions.snackbarDeactivationSuccess',
  /** "Deactivation unsuccessfull" */ common_permissions_snackbarDeactivationFailure = 'common.permissions.snackbarDeactivationFailure',
  /** "Market Rates" */ common_marketRate_marketRates = 'common.marketRate.marketRates',
  /** "Market rates" */ common_marketRate_title = 'common.marketRate.title',
  /** "Not available" */ common_marketRate_notAvailable = 'common.marketRate.notAvailable',
  /** "Available" */ common_marketRate_available = 'common.marketRate.available',
  /** "Not available at this time" */ common_marketRate_notAvailableAtThisTime = 'common.marketRate.notAvailableAtThisTime',
  /** "Not available for this trailer type" */ common_marketRate_notAvailableTrailer = 'common.marketRate.notAvailableTrailer',
  /** "Not available for locations outside the US" */ common_marketRate_notAvailableOutsideUS = 'common.marketRate.notAvailableOutsideUS',
  /** "{{rate}}/mi avg. rate by Rate Check" */ common_marketRate_byRateCheck = 'common.marketRate.byRateCheck',
  /** "{{rate}}/mi est. rate by Cargo Chief" */ common_marketRate_byCargoChief = 'common.marketRate.byCargoChief',
  /** "{{minRate}}/mi - {{maxRate}}/mi est. rate by GS" */ common_marketRate_byGS = 'common.marketRate.byGS',
  /** "Market rate is the price loads matching the lane and equipment type typically pay. This data can help with rate negotiation." */ common_marketRate_content = 'common.marketRate.content',
  /** "Cargo Chief" */ common_marketRate_cargoChief_title = 'common.marketRate.cargoChief.title',
  /** "Cargo Chief's market rate add-on will give you estimates based on the highest, middle, and lowest rates gathered from" */ common_marketRate_cargoChief_popupContent = 'common.marketRate.cargoChief.popupContent',
  /** " actual freight bills " */ common_marketRate_cargoChief_popupContentBold = 'common.marketRate.cargoChief.popupContentBold',
  /** "rather than quoted prices.\n\nThis data can be seen by adding the Cargo Chief add-on." */ common_marketRate_cargoChief_popupContent2 = 'common.marketRate.cargoChief.popupContent2',
  /** "{{rate}}/mi estimated rate" */ common_marketRate_cargoChief_estRate = 'common.marketRate.cargoChief.estRate',
  /** "Rate estimate" */ common_marketRate_cargoChief_rateEst = 'common.marketRate.cargoChief.rateEst',
  /** "Per mile" */ common_marketRate_cargoChief_perMile = 'common.marketRate.cargoChief.perMile',
  /** "Low est." */ common_marketRate_cargoChief_lowEst = 'common.marketRate.cargoChief.lowEst',
  /** "High est." */ common_marketRate_cargoChief_highEst = 'common.marketRate.cargoChief.highEst',
  /** "Estimate" */ common_marketRate_cargoChief_est = 'common.marketRate.cargoChief.est',
  /** "Cargo Chief's market rate estimates are based on the highest, middle, and lowest rates gathered from actual freight bills rather than quoted prices." */ common_marketRate_cargoChief_info = 'common.marketRate.cargoChief.info',
  /** "Greenscreens" */ common_marketRate_greenscreens_title = 'common.marketRate.greenscreens.title',
  /** "{{minRate}}/mi - {{maxRate}}/mi est. rate" */ common_marketRate_greenscreens_subtitle = 'common.marketRate.greenscreens.subtitle',
  /** "{{rate}} per mile" */ common_marketRate_greenscreens_valuePerMile = 'common.marketRate.greenscreens.valuePerMile',
  /** "MIN" */ common_marketRate_greenscreens_min = 'common.marketRate.greenscreens.min',
  /** "MAX" */ common_marketRate_greenscreens_max = 'common.marketRate.greenscreens.max',
  /** "Greenscreens provides market rate data gathered from a combination of quoted prices and confirmed rates." */ common_marketRate_greenscreens_info = 'common.marketRate.greenscreens.info',
  /** "Rate not available" */ common_marketRate_rateCheck_rateNotAvailable = 'common.marketRate.rateCheck.rateNotAvailable',
  /** "Rate data for this load is not available at this time. Please try again later." */ common_marketRate_rateCheck_rateNotAvailable_info = 'common.marketRate.rateCheck.rateNotAvailable_info',
  /** "Rate Check" */ common_marketRate_rateCheck_title = 'common.marketRate.rateCheck.title',
  /** "The Rate Check market rate will give you the highest, lowest, and average market rates with historical data based on posted rates. Many tools on 123Loadboard use Rate Check data to bring you the most accurate information available." */ common_marketRate_rateCheck_popupContent = 'common.marketRate.rateCheck.popupContent',
  /** "Rate Check is available as part of the Premium Plus membership plan, or can be added with Rate Check add-on." */ common_marketRate_rateCheck_popupContentDetails = 'common.marketRate.rateCheck.popupContentDetails',
  /** "Rate Check is available as part of the Premium Plus membership plan." */ common_marketRate_rateCheck_popupContentDetailsIOSUser = 'common.marketRate.rateCheck.popupContentDetailsIOSUser',
  /** "The Market rates tool gives you lane-specific rate data including historical rates, insights, and average rates." */ common_marketRate_ratesAccess_content1 = 'common.marketRate.ratesAccess.content1',
  /** "Rate Check is required to have access to the Market rates tool. Many tools on 123Loadboard use Rate Check data to bring you the most accurate information available." */ common_marketRate_ratesAccess_content2 = 'common.marketRate.ratesAccess.content2',
  /** "Rate Check is available as part of the Premium Plus membership plan or can be added with Rate Check add-on." */ common_marketRate_ratesAccess_content3 = 'common.marketRate.ratesAccess.content3',
  /** "Rate Check is available as part of the Premium Plus membership plan." */ common_marketRate_ratesAccess_content3IOSUsers = 'common.marketRate.ratesAccess.content3IOSUsers',
  /** "Error getting current location. Please try again" */ common_currentLocationError = 'common.currentLocationError',
  /** "Unknown" */ common_marketRankings_unknown = 'common.marketRankings.unknown',
  /** "Below avg." */ common_marketRankings_belowAverage = 'common.marketRankings.belowAverage',
  /** "Average" */ common_marketRankings_average = 'common.marketRankings.average',
  /** "Above avg." */ common_marketRankings_aboveAverage = 'common.marketRankings.aboveAverage',
  /** "Rating" */ common_ratings_title = 'common.ratings.title',
  /** "Not rated" */ common_ratings_notRated = 'common.ratings.notRated',
  /** "Based on {{count}} reviews" */ common_ratings_basedOnReviews = 'common.ratings.basedOnReviews',
  /** "Based on 1 review" */ common_ratings_basedOnReview = 'common.ratings.basedOnReview',
  /** "Safety Rating:" */ common_ratings_safetyRating = 'common.ratings.safetyRating',
  /** "Carrier Operation:" */ common_ratings_carrierOperation = 'common.ratings.carrierOperation',
  /** "powered by" */ common_ratings_poweredBy = 'common.ratings.poweredBy',
  /** "More details" */ common_ratings_moreDetails = 'common.ratings.moreDetails',
  /** "You're leaving 123Loadboard" */ common_ratings_popupTitle = 'common.ratings.popupTitle',
  /** "More detailed carrier ratings are available on CarrierSource. Please note that their terms and conditions may differ from 123Loadboard. The website will open in a new tab." */ common_ratings_popupInfo = 'common.ratings.popupInfo',
  /** "Proceed" */ common_ratings_proceed = 'common.ratings.proceed',
  /** "123Loadboard is not responsible for any content on third party websites." */ common_ratings_moreInfo = 'common.ratings.moreInfo',
  /** "Carrier Ratings powered by CarrierSource" */ common_ratings_ratingsPoweredBy = 'common.ratings.ratingsPoweredBy',
  /** "New to CarrierSource?" */ common_ratings_newToCarrierSource = 'common.ratings.newToCarrierSource',
  /** "Sign up now and seek reviews from brokers to strengthen your brand." */ common_ratings_signUpContent = 'common.ratings.signUpContent',
  /** "Sign up" */ common_ratings_signUpButton = 'common.ratings.signUpButton',
  /** "Up my score?" */ common_ratings_upMyScore = 'common.ratings.upMyScore',
  /** "To update your email, call" */ updateEmail = 'updateEmail',
  /** "To reset your password" */ reset_password = 'reset_password',
  /** "Internet Explorer is not currently supported. Please upgrade to Microsoft Edge or change browser." */ IE_Warning = 'IE_Warning',
  /** "Load board solutions to help professional truckers find truck loads, find available freight, post their truck availability to move more truck freight." */ Meta_Description = 'Meta_Description',
  /** "Go to detail" */ Go_to_detail = 'Go_to_detail',
  /** "My Account" */ My_Account = 'My_Account',
  /** "Message Center" */ Message_Center = 'Message_Center',
  /** "Alerts" */ Alerts = 'Alerts',
  /** "Help" */ Help = 'Help',
  /** "CURRENT" */ current = 'current',
  /** "Settings" */ Settings = 'Settings',
  /** "Logout" */ Logout = 'Logout',
  /** "Submit" */ Submit = 'Submit',
  /** "Create" */ Create = 'Create',
  /** "click here" */ Click_Here = 'Click_Here',
  /** "Version" */ Version = 'Version',
  /** "Number" */ Number = 'Number',
  /** "Street name" */ StreetName = 'StreetName',
  /** "or" */ Or = 'Or',
  /** "of" */ Of = 'Of',
  /** "Ok" */ Ok = 'Ok',
  /** "Saved" */ Saved = 'Saved',
  /** "Contact" */ Contact = 'Contact',
  /** "Calculate" */ Calculate = 'Calculate',
  /** "Phone number" */ PhoneNumber = 'PhoneNumber',
  /** "Please call" */ PleaseCall = 'PleaseCall',
  /** "Show" */ Show = 'Show',
  /** "Invalid format" */ Invalid_Format = 'Invalid_Format',
  /** "Dismiss" */ Dismiss = 'Dismiss',
  /** "ext. {{extension}}" */ Extension_Short = 'Extension_Short',
  /** "Time" */ Time = 'Time',
  /** "to" */ To = 'To',
  /** "upgrade." */ Upgrade = 'Upgrade',
  /** "Date" */ Date = 'Date',
  /** "email" */ email = 'email',
  /** "Name" */ name = 'name',
  /** "See all in 123Loadboard" */ open123LB = 'open123LB',
  /** "Show number" */ showNumber = 'showNumber',
  /** "show" */ textfield_Show_Password = 'textfield.Show_Password',
  /** "hide" */ textfield_Hide_Password = 'textfield.Hide_Password',
  /** "icon" */ altText_icon = 'altText.icon',
  /** "control" */ altText_control = 'altText.control',
  /** "To access this feature, please " */ authenticatedRoute_upgradePlan_title = 'authenticatedRoute.upgradePlan.title',
  /** "upgrade your plan." */ authenticatedRoute_upgradePlan_link = 'authenticatedRoute.upgradePlan.link',
  /** "To activate this feature, please " */ authenticatedRoute_restrictedRibbonPopUp_title = 'authenticatedRoute.restrictedRibbonPopUp.title',
  /** "send a request " */ authenticatedRoute_restrictedRibbonPopUp_request = 'authenticatedRoute.restrictedRibbonPopUp.request',
  /** " or call " */ authenticatedRoute_restrictedRibbonPopUp_content = 'authenticatedRoute.restrictedRibbonPopUp.content',
  /** " 877-875-5301 ext. 2." */ authenticatedRoute_restrictedRibbonPopUp_contact = 'authenticatedRoute.restrictedRibbonPopUp.contact',
  /** "To activate this feature, please " */ authenticatedRoute_restrictedRibbonPopUpCarrier_title = 'authenticatedRoute.restrictedRibbonPopUpCarrier.title',
  /** "send a request " */ authenticatedRoute_restrictedRibbonPopUpCarrier_request = 'authenticatedRoute.restrictedRibbonPopUpCarrier.request',
  /** " to our support team. " */ authenticatedRoute_restrictedRibbonPopUpCarrier_content = 'authenticatedRoute.restrictedRibbonPopUpCarrier.content',
  /** "Session paused" */ sessionPausedPopUp_title = 'sessionPausedPopUp.title',
  /** "Due to inactivity, your session has been paused. Please click anywhere to continue." */ sessionPausedPopUp_content = 'sessionPausedPopUp.content',
  /** "(877) 875-5301" */ contactSupportPhone = 'contactSupportPhone',
  /** "Something went wrong. If the error persist, please contact tech support." */ error_generic_api_message = 'error.generic_api_message',
  /** "Oops, something went wrong." */ error_title = 'error.title',
  /** "An internal server error or bad connectivity occurred. Please reload to try again. If the error persist, please contact tech support." */ error_network_server_error = 'error.network_server_error',
  /** "Something went wrong. You may retry now or at a later time." */ error_message_retry = 'error.message_retry',
  /** "An internal server error occurred. Please try again." */ error_message_error = 'error.message_error',
  /** "The address cannot be located, please enter a valid address." */ error_invalid_address = 'error.invalid_address',
  /** "An error has occured" */ error_genericErrorTitle = 'error.genericErrorTitle',
  /** "We are experiencing technical difficulties, please try again." */ error_genericErrorMessage = 'error.genericErrorMessage',
  /** "If the problem persists, please contact support at" */ error_genericContactSupport = 'error.genericContactSupport',
  /** "Experiencing connectivity issues. If the problem persists, please contact support at" */ error_connectionErrorMessage = 'error.connectionErrorMessage',
  /** "An error occurred. Please try again." */ error_try_again_error = 'error.try_again_error',
  /** "Failed to load" */ error_failed_to_load = 'error.failed_to_load',
  /** "Retry" */ error_retry = 'error.retry',
  /** "Reload the page" */ error_reload_label = 'error.reload_label',
  /** "Try again" */ error_try_again = 'error.try_again',
  /** "Not a valid email address." */ error_validation_email = 'error.validation.email',
  /** "Phone number must be 10 digits long." */ error_validation_phone = 'error.validation.phone',
  /** "Phone number is required." */ error_validation_undefinedPhone = 'error.validation.undefinedPhone',
  /** "The phone number is not valid." */ error_validation_invalidPhone = 'error.validation.invalidPhone',
  /** "Email is not valid." */ error_validation_invalidEmail = 'error.validation.invalidEmail',
  /** "Drop off date must be after Pick up date." */ error_validation_dropoffAfterPickup = 'error.validation.dropoffAfterPickup',
  /** "Ext is too long" */ error_validation_invalidExt = 'error.validation.invalidExt',
  /** "*Phone or email are required." */ error_validation_contactDataError = 'error.validation.contactDataError',
  /** "The link is not valid" */ error_validation_invalidLink = 'error.validation.invalidLink',
  /** "Daily complimentary usage reached!" */ error_limitReached = 'error.limitReached',
  /** "{{sign}}{{currency}}{{value}}" */ unit_money = 'unit.money',
  /** "{{count}} year" */ unit_year = 'unit.year',
  /** "{{count}} years" */ unit_year_plural = 'unit.year_plural',
  /** "{{count}} month" */ unit_month = 'unit.month',
  /** "{{count}} months" */ unit_month_plural = 'unit.month_plural',
  /** "{{count}} day" */ unit_day = 'unit.day',
  /** "{{count}} days" */ unit_day_plural = 'unit.day_plural',
  /** "{{value}} mile" */ unit_mile = 'unit.mile',
  /** "{{value}} mi" */ unit_mi = 'unit.mi',
  /** "{{value}} miles" */ unit_mile_plural = 'unit.mile_plural',
  /** "{{value}} MPG" */ unit_mile_per_gallon_short = 'unit.mile_per_gallon_short',
  /** "{{value}} / mile" */ unit_per_mile = 'unit.per_mile',
  /** "{{value}}/mi" */ unit_per_mi = 'unit.per_mi',
  /** "{{value}}/gal" */ unit_per_gal = 'unit.per_gal',
  /** "{{value}} lbs" */ unit_pounds = 'unit.pounds',
  /** "{{value}} lb" */ unit_pound = 'unit.pound',
  /** "{{value}} ft" */ unit_feet = 'unit.feet',
  /** "{{value}}'" */ unit_feet_symbol = 'unit.feet_symbol',
  /** "Dashboard" */ Header_Dashboard = 'Header.Dashboard',
  /** "Find Loads" */ Header_Find_Loads = 'Header.Find_Loads',
  /** "Search Loads" */ Header_Search_Loads = 'Header.Search_Loads',
  /** "Load availability" */ Header_Load_Availability = 'Header.Load_Availability',
  /** "Trip builder" */ Header_Trip_Builder = 'Header.Trip_Builder',
  /** "New trip" */ Header_New_Trip = 'Header.New_Trip',
  /** "Add a load" */ Header_Add_A_Load = 'Header.Add_A_Load',
  /** "Load Planner" */ Header_Load_Planner = 'Header.Load_Planner',
  /** "Post Loads" */ Header_Post_Loads = 'Header.Post_Loads',
  /** "Manage Loads" */ Header_Manage_Loads = 'Header.Manage_Loads',
  /** "Post Single Load" */ Header_Post_Single_Load = 'Header.Post_Single_Load',
  /** "Post Multiple Loads" */ Header_Post_Multiple_Loads = 'Header.Post_Multiple_Loads',
  /** "Upload Posts" */ Header_Upload_Posts = 'Header.Upload_Posts',
  /** "Export Posts" */ Header_Export_Posts = 'Header.Export_Posts',
  /** "Find Trucks" */ Header_Find_Trucks = 'Header.Find_Trucks',
  /** "Integration Services" */ Header_Integration_Services = 'Header.Integration_Services',
  /** "HTML Embed Code" */ Header_HTML_Embed_Code = 'Header.HTML_Embed_Code',
  /** "Tools" */ Header_Tools = 'Header.Tools',
  /** "Rate Check" */ Header_Rate_Check = 'Header.Rate_Check',
  /** "MyDocs" */ Header_MyDocs = 'Header.MyDocs',
  /** "Map, routing, and tolls" */ Header_Mileage_Map_Tolls = 'Header.Mileage_Map_Tolls',
  /** "Mileage Calculator" */ Header_Mileage_Calculator = 'Header.Mileage_Calculator',
  /** "Credit Report" */ Header_Credit_Report = 'Header.Credit_Report',
  /** "Carrier Monitor" */ Header_Carrier_Monitor = 'Header.Carrier_Monitor',
  /** "Search Directory" */ Header_Search_Directory = 'Header.Search_Directory',
  /** "Hot Leads" */ Header_Hot_Leads = 'Header.Hot_Leads',
  /** "SearchDocs" */ Header_SearchDocs = 'Header.SearchDocs',
  /** "Affiliated Services" */ Header_Affiliated_Services = 'Header.Affiliated_Services',
  /** "Get Paid Faster" */ Header_Get_Paid_Faster = 'Header.Get_Paid_Faster',
  /** "Save At The Pump" */ Header_Save_At_The_Pump = 'Header.Save_At_The_Pump',
  /** "Roadside Assistance" */ Header_Roadside_Assistance = 'Header.Roadside_Assistance',
  /** "Trucking Authority" */ Header_Trucking_Authority = 'Header.Trucking_Authority',
  /** "Resources" */ Header_Resources = 'Header.Resources',
  /** "Reefer Rates" */ Header_Reefer_Rates = 'Header.Reefer_Rates',
  /** "FMCSA Search" */ Header_FMCSA_Lookups = 'Header.FMCSA_Lookups',
  /** "Fuel Prices" */ Header_Fuel_Prices = 'Header.Fuel_Prices',
  /** "Border Wait Times" */ Header_Border_Wait_Times = 'Header.Border_Wait_Times',
  /** "My Loadboard" */ Header_My_Loadboard = 'Header.My_Loadboard',
  /** "Potential Loads" */ Header_Potential_Loads = 'Header.Potential_Loads',
  /** "Booked Loads" */ Header_Booked_Loads = 'Header.Booked_Loads',
  /** "Companies" */ Header_Companies = 'Header.Companies',
  /** "Company Search" */ Header_Company_Search = 'Header.Company_Search',
  /** "Blocked / Hidden" */ Header_Blocked_Hidden = 'Header.Blocked_Hidden',
  /** "Favorites" */ Header_Favorites = 'Header.Favorites',
  /** "Bids" */ Header_Bids = 'Header.Bids',
  /** "Load Bids" */ Header_Load_Bids = 'Header.Load_Bids',
  /** "Alerts" */ Header_Alerts = 'Header.Alerts',
  /** "Manage Alerts" */ Header_Manage_Alerts = 'Header.Manage_Alerts',
  /** "Alert Settings" */ Header_Alert_Settings = 'Header.Alert_Settings',
  /** "Saved Searches" */ Header_Saved_Searches = 'Header.Saved_Searches',
  /** "Truck Locator" */ Header_Truck_Locator = 'Header.Truck_Locator',
  /** "Internal Truck Locator" */ Header_Internal_Truck_Locator = 'Header.Internal_Truck_Locator',
  /** "My Account" */ Header_My_Account = 'Header.My_Account',
  /** "TMS Integration" */ Header_TMS_Integration = 'Header.TMS_Integration',
  /** "Messages" */ Header_Messages = 'Header.Messages',
  /** "Help" */ Header_Help = 'Header.Help',
  /** "Settings" */ Header_Settings = 'Header.Settings',
  /** "Main Menu" */ Header_Main_Menu = 'Header.Main_Menu',
  /** "Sign Up" */ Header_Sign_Up = 'Header.Sign_Up',
  /** "Login" */ Header_Login = 'Header.Login',
  /** "Logout" */ Header_Logout = 'Header.Logout',
  /** "Posting Aid" */ Header_Posting_Aid = 'Header.Posting_Aid',
  /** "© {{year}} 123Loadboard" */ footer_Copyright = 'footer.Copyright',
  /** "Visit Website" */ footer_Visit_Website = 'footer.Visit_Website',
  /** "Terms of Service" */ footer_Terms_Of_Service = 'footer.Terms_Of_Service',
  /** "Privacy Policy" */ footer_Privacy_Policy = 'footer.Privacy_Policy',
  /** "© 123Loadboard | 1321 Upland Drive, Suite 1120 | Houston, TX, 77043, USA" */ footer_Address = 'footer.Address',
  /** "If you have any questions, please contact us" */ footer_ContactUs = 'footer.ContactUs',
  /** "support@123loadboard.com" */ footer_SupportEmail = 'footer.SupportEmail',
  /** "Cancel" */ confirmationDialog_Cancel = 'confirmationDialog.Cancel',
  /** "Confirm" */ confirmationDialog_Confirm = 'confirmationDialog.Confirm',
  /** "Learn how" */ confirmationDialog_LearnHow = 'confirmationDialog.LearnHow',
  /** "broker" */ confirmationDialog_broker = 'confirmationDialog.broker',
  /** "Share feedback" */ findLoads_shareFeedback = 'findLoads.shareFeedback',
  /** "{{value}} characters left" */ findLoads_charsLeft = 'findLoads.charsLeft',
  /** "Thank you for your feedback." */ findLoads_feedbackAcknowledge = 'findLoads.feedbackAcknowledge',
  /** "We review your feedback to improve our products. Please don’t share personal data or confidential or proprietary information with your feedback." */ findLoads_feedbackPlaceholder = 'findLoads.feedbackPlaceholder',
  /** "Share" */ findLoads_share = 'findLoads.share',
  /** "Skip" */ findLoads_skip = 'findLoads.skip',
  /** "Invalid search query" */ findLoads_invalidSearchQuery = 'findLoads.invalidSearchQuery',
  /** "Any" */ findLoads_findLoads_any = 'findLoads.findLoads.any',
  /** "Any ft" */ findLoads_findLoads_anyFt = 'findLoads.findLoads.anyFt',
  /** "Any lbs" */ findLoads_findLoads_anyLbs = 'findLoads.findLoads.anyLbs',
  /** "Any type" */ findLoads_findLoads_anyType = 'findLoads.findLoads.anyType',
  /** "Anywhere" */ findLoads_findLoads_anywhere = 'findLoads.findLoads.anywhere',
  /** "All" */ findLoads_findLoads_all = 'findLoads.findLoads.all',
  /** "USA" */ findLoads_findLoads_usa = 'findLoads.findLoads.usa',
  /** "Canada" */ findLoads_findLoads_canada = 'findLoads.findLoads.canada',
  /** "Find loads" */ findLoads_findLoads_header = 'findLoads.findLoads.header',
  /** "New search" */ findLoads_findLoads_tabSearch = 'findLoads.findLoads.tabSearch',
  /** "Recent" */ findLoads_findLoads_tabRecent = 'findLoads.findLoads.tabRecent',
  /** "Saved" */ findLoads_findLoads_tabSaved = 'findLoads.findLoads.tabSaved',
  /** "Clear" */ findLoads_findLoads_clear = 'findLoads.findLoads.clear',
  /** "Truck filters applied" */ findLoads_findLoads_truckApplied = 'findLoads.findLoads.truckApplied',
  /** "Available capacity" */ findLoads_findLoads_availableCapacity = 'findLoads.findLoads.availableCapacity',
  /** "Load size" */ findLoads_findLoads_loadSize = 'findLoads.findLoads.loadSize',
  /** "Length" */ findLoads_findLoads_length = 'findLoads.findLoads.length',
  /** "Weight" */ findLoads_findLoads_weight = 'findLoads.findLoads.weight',
  /** "My actions" */ findLoads_findLoads_myActions = 'findLoads.findLoads.myActions',
  /** "Sort by" */ findLoads_findLoads_sortBy = 'findLoads.findLoads.sortBy',
  /** "Reset" */ findLoads_findLoads_reset = 'findLoads.findLoads.reset',
  /** "Select dates" */ findLoads_findLoads_selectDates = 'findLoads.findLoads.selectDates',
  /** "Select a maximum 15" */ findLoads_findLoads_selectMax = 'findLoads.findLoads.selectMax',
  /** "Truck/Trailer type" */ findLoads_findLoads_trailerType = 'findLoads.findLoads.trailerType',
  /** "Types" */ findLoads_findLoads_types = 'findLoads.findLoads.types',
  /** "Quick select" */ findLoads_findLoads_quickSelect = 'findLoads.findLoads.quickSelect',
  /** "Popular" */ findLoads_findLoads_popular = 'findLoads.findLoads.popular',
  /** "Other" */ findLoads_findLoads_other = 'findLoads.findLoads.other',
  /** "Home" */ findLoads_findLoads_home = 'findLoads.findLoads.home',
  /** "Choose your home" */ findLoads_findLoads_homeLocation = 'findLoads.findLoads.homeLocation',
  /** "Home location" */ findLoads_findLoads_homeLocationLabel = 'findLoads.findLoads.homeLocationLabel',
  /** "Current location" */ findLoads_findLoads_currentLocationLabel = 'findLoads.findLoads.currentLocationLabel',
  /** "City, state or zip" */ findLoads_findLoads_cityStateOrZip = 'findLoads.findLoads.cityStateOrZip',
  /** "Drop off" */ findLoads_findLoads_dropOff = 'findLoads.findLoads.dropOff',
  /** "Drop off location" */ findLoads_findLoads_dropOffLocation = 'findLoads.findLoads.dropOffLocation',
  /** "Pick up" */ findLoads_findLoads_pickUp = 'findLoads.findLoads.pickUp',
  /** "Pickup location" */ findLoads_findLoads_pickupLocation = 'findLoads.findLoads.pickupLocation',
  /** "Pickup date" */ findLoads_findLoads_pickupDate = 'findLoads.findLoads.pickupDate',
  /** "Radius (mi)" */ findLoads_findLoads_radius = 'findLoads.findLoads.radius',
  /** "Click here to set your home location" */ findLoads_findLoads_setHomeLocation = 'findLoads.findLoads.setHomeLocation',
  /** "Exclude loads without length" */ findLoads_findLoads_excludeLoadsWithoutLength = 'findLoads.findLoads.excludeLoadsWithoutLength',
  /** "Exclude loads without weight" */ findLoads_findLoads_excludeLoadsWithoutWeight = 'findLoads.findLoads.excludeLoadsWithoutWeight',
  /** "Called" */ findLoads_findLoads_called = 'findLoads.findLoads.called',
  /** "Note" */ findLoads_findLoads_note = 'findLoads.findLoads.note',
  /** "Favorite company" */ findLoads_findLoads_favoriteCompany = 'findLoads.findLoads.favoriteCompany',
  /** " *" */ findLoads_findLoads_asterisk = 'findLoads.findLoads.asterisk',
  /** "Ok" */ findLoads_findLoads_ok = 'findLoads.findLoads.ok',
  /** "Load available" */ findLoads_findLoads_stepsStatusBar_0 = 'findLoads.findLoads.stepsStatusBar.0',
  /** "Booked" */ findLoads_findLoads_stepsStatusBar_1 = 'findLoads.findLoads.stepsStatusBar.1',
  /** "Scheduled for pickup" */ findLoads_findLoads_stepsStatusBar_2 = 'findLoads.findLoads.stepsStatusBar.2',
  /** "Pickup complete" */ findLoads_findLoads_stepsStatusBar_3 = 'findLoads.findLoads.stepsStatusBar.3',
  /** "Load en route" */ findLoads_findLoads_stepsStatusBar_4 = 'findLoads.findLoads.stepsStatusBar.4',
  /** "Delivered" */ findLoads_findLoads_stepsStatusBar_5 = 'findLoads.findLoads.stepsStatusBar.5',
  /** "Ready to book this load?" */ findLoads_findLoads_statusBar_phrase1 = 'findLoads.findLoads.statusBar.phrase1',
  /** "If you book this load, select this status and these Load details will be available to you for future reference." */ findLoads_findLoads_statusBar_phrase2 = 'findLoads.findLoads.statusBar.phrase2',
  /** "Did you confirm the load details?" */ findLoads_findLoads_statusBar_phrase3 = 'findLoads.findLoads.statusBar.phrase3',
  /** "Did you pickup the load?" */ findLoads_findLoads_statusBar_phrase4 = 'findLoads.findLoads.statusBar.phrase4',
  /** "Want a fuel advance for this load?" */ findLoads_findLoads_statusBar_phrase5 = 'findLoads.findLoads.statusBar.phrase5',
  /** "Are you en route?" */ findLoads_findLoads_statusBar_phrase6 = 'findLoads.findLoads.statusBar.phrase6',
  /** "Did you deliver the load?" */ findLoads_findLoads_statusBar_phrase7 = 'findLoads.findLoads.statusBar.phrase7',
  /** "Want to get paid faster for this load?" */ findLoads_findLoads_statusBar_phrase8 = 'findLoads.findLoads.statusBar.phrase8',
  /** "Upload your Rate Confirmation" */ findLoads_findLoads_statusBar_uploadYourRateConfirmation = 'findLoads.findLoads.statusBar.uploadYourRateConfirmation',
  /** "Upload your BOL, lumpers, etc." */ findLoads_findLoads_statusBar_uploadYourBolLumpersEtc = 'findLoads.findLoads.statusBar.uploadYourBolLumpersEtc',
  /** "Upload your Proof of Delivery (POD)" */ findLoads_findLoads_statusBar_uploadYourProofOfDeliveryPod = 'findLoads.findLoads.statusBar.uploadYourProofOfDeliveryPod',
  /** "Post truck if you need a backhaul." */ findLoads_findLoads_statusBar_postTruck = 'findLoads.findLoads.statusBar.postTruck',
  /** "Contact: " */ findLoads_findLoads_contact = 'findLoads.findLoads.contact',
  /** "Ref. #:" */ findLoads_findLoads_ref = 'findLoads.findLoads.ref',
  /** "Fuel advance" */ findLoads_findLoads_fuelAdvance = 'findLoads.findLoads.fuelAdvance',
  /** "Sent:" */ findLoads_findLoads_sent = 'findLoads.findLoads.sent',
  /** "Short: 0 - 200 mi" */ findLoads_findLoads_tripLengthOptions_0 = 'findLoads.findLoads.tripLengthOptions.0',
  /** "Medium: 201 - 500 mi" */ findLoads_findLoads_tripLengthOptions_1 = 'findLoads.findLoads.tripLengthOptions.1',
  /** "Regional: 501 - 1000 mi" */ findLoads_findLoads_tripLengthOptions_2 = 'findLoads.findLoads.tripLengthOptions.2',
  /** "Long: 1001+ mi" */ findLoads_findLoads_tripLengthOptions_3 = 'findLoads.findLoads.tripLengthOptions.3',
  /** "No {{param}} searches" */ findLoads_findLoads_noSearches = 'findLoads.findLoads.noSearches',
  /** "You have no {{param}} searches" */ findLoads_findLoads_youHaveNoSearches = 'findLoads.findLoads.youHaveNoSearches',
  /** "No saved searches" */ findLoads_findLoads_noSavedSearches = 'findLoads.findLoads.noSavedSearches',
  /** "You have no saved searches" */ findLoads_findLoads_haveNoSavedSearches = 'findLoads.findLoads.haveNoSavedSearches',
  /** "No recent searches" */ findLoads_findLoads_noRecentSearches = 'findLoads.findLoads.noRecentSearches',
  /** "You have no recent searches" */ findLoads_findLoads_haveNoRecentSearches = 'findLoads.findLoads.haveNoRecentSearches',
  /** "No exact loads found" */ findLoads_findLoads_noLoads = 'findLoads.findLoads.noLoads',
  /** "Please refine your criteria or see similar loads." */ findLoads_findLoads_haveNoLoads = 'findLoads.findLoads.haveNoLoads',
  /** "Edit search" */ findLoads_findLoads_editLoadSearch = 'findLoads.findLoads.editLoadSearch',
  /** "States, Provinces" */ findLoads_findLoads_statesProvinces = 'findLoads.findLoads.statesProvinces',
  /** "States, Provinces, Regions (Map)" */ findLoads_findLoads_statesProvincesRegionsMap = 'findLoads.findLoads.statesProvincesRegionsMap',
  /** "States, Provinces, Regions" */ findLoads_findLoads_statesProvincesRegions = 'findLoads.findLoads.statesProvincesRegions',
  /** "State, Province" */ findLoads_findLoads_stateProvince = 'findLoads.findLoads.stateProvince',
  /** "Select regions" */ findLoads_findLoads_selectRegions = 'findLoads.findLoads.selectRegions',
  /** "Select USA regions" */ findLoads_findLoads_selectUSARegions = 'findLoads.findLoads.selectUSARegions',
  /** "Select Canada regions" */ findLoads_findLoads_selectCanadaRegions = 'findLoads.findLoads.selectCanadaRegions',
  /** "Regions" */ findLoads_findLoads_regions = 'findLoads.findLoads.regions',
  /** "USA Regions" */ findLoads_findLoads_usaRegions = 'findLoads.findLoads.usaRegions',
  /** "Canada Regions" */ findLoads_findLoads_canadaRegions = 'findLoads.findLoads.canadaRegions',
  /** "Select region(s) or state(s)" */ findLoads_findLoads_selectRegionsOrStates = 'findLoads.findLoads.selectRegionsOrStates',
  /** "Select state" */ findLoads_findLoads_selectState = 'findLoads.findLoads.selectState',
  /** "Select province" */ findLoads_findLoads_selectProvince = 'findLoads.findLoads.selectProvince',
  /** "TL" */ findLoads_findLoads_tl = 'findLoads.findLoads.tl',
  /** "LTL" */ findLoads_findLoads_ltl = 'findLoads.findLoads.ltl',
  /** "Miles" */ findLoads_findLoads_Miles = 'findLoads.findLoads.Miles',
  /** "/mile($)" */ findLoads_findLoads_perMile = 'findLoads.findLoads.perMile',
  /** "/gal ($)" */ findLoads_findLoads_perGal = 'findLoads.findLoads.perGal',
  /** "Rate($)" */ findLoads_findLoads_Rate = 'findLoads.findLoads.Rate',
  /** "MPG" */ findLoads_findLoads_MPG = 'findLoads.findLoads.MPG',
  /** "Toll cost ($)" */ findLoads_findLoads_tollCost = 'findLoads.findLoads.tollCost',
  /** "Dispatch fee" */ findLoads_findLoads_dispatchFee = 'findLoads.findLoads.dispatchFee',
  /** "Other fees ($)" */ findLoads_findLoads_otherFee = 'findLoads.findLoads.otherFee',
  /** "Apply fuel discount" */ findLoads_findLoads_applyDiscount = 'findLoads.findLoads.applyDiscount',
  /** "(up to $0.40/gal)" */ findLoads_findLoads_discountValue = 'findLoads.findLoads.discountValue',
  /** "Get fuel card" */ findLoads_findLoads_getFuelCard = 'findLoads.findLoads.getFuelCard',
  /** "Apply deadhead miles" */ findLoads_findLoads_applyDeadheadMiles = 'findLoads.findLoads.applyDeadheadMiles',
  /** "Total profit" */ findLoads_findLoads_totalProfit = 'findLoads.findLoads.totalProfit',
  /** "Avg. rate by Rate Check" */ findLoads_findLoads_averageRateByCheck = 'findLoads.findLoads.averageRateByCheck',
  /** "Posted rate" */ findLoads_findLoads_postedRate = 'findLoads.findLoads.postedRate',
  /** "Difference" */ findLoads_findLoads_difference = 'findLoads.findLoads.difference',
  /** " to save up to ${{value}}" */ findLoads_findLoads_saveUpTo = 'findLoads.findLoads.saveUpTo',
  /** "Fuel surcharge included" */ findLoads_findLoads_fuelSurchargeIncl = 'findLoads.findLoads.fuelSurchargeIncl',
  /** "Block company" */ findLoads_findLoads_popUps_blockCompany_title = 'findLoads.findLoads.popUps.blockCompany.title',
  /** "Blocking this company will hide all their loads from your results." */ findLoads_findLoads_popUps_blockCompany_content = 'findLoads.findLoads.popUps.blockCompany.content',
  /** "Cancel" */ findLoads_findLoads_popUps_blockCompany_button_cancel = 'findLoads.findLoads.popUps.blockCompany.button_cancel',
  /** "Block" */ findLoads_findLoads_popUps_blockCompany_button_block = 'findLoads.findLoads.popUps.blockCompany.button_block',
  /** "Request sent" */ findLoads_findLoads_popUps_sentRequest_title = 'findLoads.findLoads.popUps.sentRequest.title',
  /** "We’ve forwarded your information and relevant documents to Thunder Funding. An expert from Thunder Funding will be contacting you shortly about this load." */ findLoads_findLoads_popUps_sentRequest_content = 'findLoads.findLoads.popUps.sentRequest.content',
  /** "Request not sent" */ findLoads_findLoads_popUps_notSentRequest_title = 'findLoads.findLoads.popUps.notSentRequest.title',
  /** "Please visit" */ findLoads_findLoads_popUps_notSentRequest_pleaseVisitContent = 'findLoads.findLoads.popUps.notSentRequest.pleaseVisitContent',
  /** "Uncheck \"Booked\" status" */ findLoads_findLoads_popUps_unBookLoad_title = 'findLoads.findLoads.popUps.unBookLoad.title',
  /** "Are you sure you want to uncheck the \"Booked\" status on this load?" */ findLoads_findLoads_popUps_unBookLoad_content_warning = 'findLoads.findLoads.popUps.unBookLoad.content.warning',
  /** "This action does not cancel any bookings you have made directly with the broker." */ findLoads_findLoads_popUps_unBookLoad_content_text1 = 'findLoads.findLoads.popUps.unBookLoad.content.text1',
  /** "If you uncheck the status from this load, you may not be able to view the load details and it will be removed from the \"My loads\" section." */ findLoads_findLoads_popUps_unBookLoad_content_text2 = 'findLoads.findLoads.popUps.unBookLoad.content.text2',
  /** "Any documents attached to this load will be moved to the ‘Unsorted’ folder." */ findLoads_findLoads_popUps_unBookLoad_content_text3 = 'findLoads.findLoads.popUps.unBookLoad.content.text3',
  /** "No" */ findLoads_findLoads_popUps_unBookLoad_button_no = 'findLoads.findLoads.popUps.unBookLoad.button_no',
  /** "Yes" */ findLoads_findLoads_popUps_unBookLoad_button_yes = 'findLoads.findLoads.popUps.unBookLoad.button_yes',
  /** "Get paid faster request" */ findLoads_findLoads_popUps_paidFaster_title = 'findLoads.findLoads.popUps.paidFaster.title',
  /** "123Loadboard is partnered with Thunder Funding to provide you with immediate access to cash. Quick Pay\n            always keeps you rolling and you no longer have to bear the burden of front-loading fixed costs over a\n            lengthy duration." */ findLoads_findLoads_popUps_paidFaster_content_warning = 'findLoads.findLoads.popUps.paidFaster.content.warning',
  /** "By requesting to get paid faster, a representative from Thunder Funding will be contacting you." */ findLoads_findLoads_popUps_paidFaster_content_text = 'findLoads.findLoads.popUps.paidFaster.content.text',
  /** "Fuel advance request" */ findLoads_findLoads_popUps_fuelAdvanced_title = 'findLoads.findLoads.popUps.fuelAdvanced.title',
  /** "123Loadboard is partnered with Thunder Funding to provide fuel advances 7 days a week." */ findLoads_findLoads_popUps_fuelAdvanced_content_warning = 'findLoads.findLoads.popUps.fuelAdvanced.content.warning',
  /** "$10 - advances under $500" */ findLoads_findLoads_popUps_fuelAdvanced_content_variant_1 = 'findLoads.findLoads.popUps.fuelAdvanced.content.variant_1',
  /** "$20 - advances $500 - $2000" */ findLoads_findLoads_popUps_fuelAdvanced_content_variant_2 = 'findLoads.findLoads.popUps.fuelAdvanced.content.variant_2',
  /** "$25 - advances over $2000" */ findLoads_findLoads_popUps_fuelAdvanced_content_variant_3 = 'findLoads.findLoads.popUps.fuelAdvanced.content.variant_3',
  /** "By requesting a fuel advance, a representative from Thunder Funding will be contacting you." */ findLoads_findLoads_popUps_fuelAdvanced_content_text = 'findLoads.findLoads.popUps.fuelAdvanced.content.text',
  /** "Remove recent searches" */ findLoads_findLoads_popUps_clearRecent_title = 'findLoads.findLoads.popUps.clearRecent.title',
  /** "Are you sure you want to remove your recent searches?" */ findLoads_findLoads_popUps_clearRecent_content = 'findLoads.findLoads.popUps.clearRecent.content',
  /** "Cancel" */ findLoads_findLoads_popUps_clearRecent_button_cancel = 'findLoads.findLoads.popUps.clearRecent.button_cancel',
  /** "Remove all" */ findLoads_findLoads_popUps_clearRecent_button_remove = 'findLoads.findLoads.popUps.clearRecent.button_remove',
  /** "Activate broker / shipper features" */ findLoads_findLoads_popUps_featureRequest_title = 'findLoads.findLoads.popUps.featureRequest.title',
  /** "This feature is only available to brokers and shippers. To gain access, please submit a request." */ findLoads_findLoads_popUps_featureRequest_content = 'findLoads.findLoads.popUps.featureRequest.content',
  /** "This feature is only available to brokers and shippers." */ findLoads_findLoads_popUps_featureRequest_featureAvailableForBrokersAndShippers = 'findLoads.findLoads.popUps.featureRequest.featureAvailableForBrokersAndShippers',
  /** "To gain access, please submit a request or call " */ findLoads_findLoads_popUps_featureRequest_gainAccess = 'findLoads.findLoads.popUps.featureRequest.gainAccess',
  /** "Submit Request" */ findLoads_findLoads_popUps_featureRequest_button = 'findLoads.findLoads.popUps.featureRequest.button',
  /** "Activate feature" */ findLoads_findLoads_popUps_featureRequestCarrier_title = 'findLoads.findLoads.popUps.featureRequestCarrier.title',
  /** "Please submit a request to gain access to this feature." */ findLoads_findLoads_popUps_featureRequestCarrier_content = 'findLoads.findLoads.popUps.featureRequestCarrier.content',
  /** "Submit Request" */ findLoads_findLoads_popUps_featureRequestCarrier_button = 'findLoads.findLoads.popUps.featureRequestCarrier.button',
  /** "Request sent" */ findLoads_findLoads_popUps_featureSubmit_title = 'findLoads.findLoads.popUps.featureSubmit.title',
  /** "Your request to access broker/shipper feature has been submitted and our Posting team will reach out to you shortly." */ findLoads_findLoads_popUps_featureSubmit_content = 'findLoads.findLoads.popUps.featureSubmit.content',
  /** "Your request to access this feature has been submitted and our Support team will reach out to you shortly." */ findLoads_findLoads_popUps_featureSubmit_contentCarrier = 'findLoads.findLoads.popUps.featureSubmit.contentCarrier',
  /** "OK" */ findLoads_findLoads_popUps_featureSubmit_button = 'findLoads.findLoads.popUps.featureSubmit.button',
  /** "Request has failed to send" */ findLoads_findLoads_popUps_featureSubmitFail_title = 'findLoads.findLoads.popUps.featureSubmitFail.title',
  /** "Your request to access broker/shipper feature has failed to send, please try again." */ findLoads_findLoads_popUps_featureSubmitFail_content = 'findLoads.findLoads.popUps.featureSubmitFail.content',
  /** "Your request to access this carrier feature has failed to send, please try again." */ findLoads_findLoads_popUps_featureSubmitFail_contentCarrier = 'findLoads.findLoads.popUps.featureSubmitFail.contentCarrier',
  /** "Before you bid, please ensure that you meet these requirements." */ findLoads_findLoads_popUps_bidHeader_label = 'findLoads.findLoads.popUps.bidHeader.label',
  /** "Company: " */ findLoads_findLoads_popUps_bidDetails_bidCompanyName = 'findLoads.findLoads.popUps.bidDetails.bidCompanyName',
  /** "DOT#: " */ findLoads_findLoads_popUps_bidDetails_bidCompanyDOT = 'findLoads.findLoads.popUps.bidDetails.bidCompanyDOT',
  /** "Trailer type: " */ findLoads_findLoads_popUps_bidDetails_bidEquipmentType = 'findLoads.findLoads.popUps.bidDetails.bidEquipmentType',
  /** "Trailer specs: " */ findLoads_findLoads_popUps_bidDetails_bidEquipmentSpecs = 'findLoads.findLoads.popUps.bidDetails.bidEquipmentSpecs',
  /** "Item: " */ findLoads_findLoads_popUps_bidDetails_bidEquipmentCommodity = 'findLoads.findLoads.popUps.bidDetails.bidEquipmentCommodity',
  /** "Notes: " */ findLoads_findLoads_popUps_bidDetails_bidEquipmentNotes = 'findLoads.findLoads.popUps.bidDetails.bidEquipmentNotes',
  /** "Regions allow you to search many states at once. Our regions include:" */ findLoads_findLoads_popUps_regionsInfo_title = 'findLoads.findLoads.popUps.regionsInfo.title',
  /** "Small truck loads" */ findLoads_findLoads_popUps_smallTruckLoads_title = 'findLoads.findLoads.popUps.smallTruckLoads.title',
  /** "To find the best results for smaller trucks, we recommend that you select the following equipment types:" */ findLoads_findLoads_popUps_smallTruckLoads_content = 'findLoads.findLoads.popUps.smallTruckLoads.content',
  /** "Please enter values" */ findLoads_findLoads_pleaseEnterValues = 'findLoads.findLoads.pleaseEnterValues',
  /** "An entry has an invalid value" */ findLoads_findLoads_fieldsInvalid = 'findLoads.findLoads.fieldsInvalid',
  /** "An entry is required or has an invalid value" */ findLoads_findLoads_fieldsRequiredOrInvalid = 'findLoads.findLoads.fieldsRequiredOrInvalid',
  /** "Linehaul revenue" */ findLoads_findLoads_Linehaul_Revenue = 'findLoads.findLoads.Linehaul_Revenue',
  /** "Toll costs" */ findLoads_findLoads_Toll_Costs = 'findLoads.findLoads.Toll_Costs',
  /** "Estimated fuel costs" */ findLoads_findLoads_Fuel_Costs = 'findLoads.findLoads.Fuel_Costs',
  /** "Additional costs" */ findLoads_findLoads_Additional_Costs = 'findLoads.findLoads.Additional_Costs',
  /** "Est. fuel costs" */ findLoads_findLoads_Fuel_Costs_Short = 'findLoads.findLoads.Fuel_Costs_Short',
  /** "Estimated revenue" */ findLoads_findLoads_Estimated_Revenue = 'findLoads.findLoads.Estimated_Revenue',
  /** "Est. revenue" */ findLoads_findLoads_Estimated_Revenue_Short = 'findLoads.findLoads.Estimated_Revenue_Short',
  /** "Company added to onboarded" */ findLoads_findLoads_company_added_onboarded = 'findLoads.findLoads.company_added_onboarded',
  /** "Company removed from onboarded" */ findLoads_findLoads_company_removed_onboarded = 'findLoads.findLoads.company_removed_onboarded',
  /** "Remove favorite" */ findLoads_findLoads_removeFavorite = 'findLoads.findLoads.removeFavorite',
  /** "Tag as onboarded" */ findLoads_findLoads_tagAsOnboarded = 'findLoads.findLoads.tagAsOnboarded',
  /** "Remove onboarded tag" */ findLoads_findLoads_removeOnboardedTag = 'findLoads.findLoads.removeOnboardedTag',
  /** "Block company" */ findLoads_findLoads_blockCompany = 'findLoads.findLoads.blockCompany',
  /** "Multiple" */ findLoads_findLoads_multiple = 'findLoads.findLoads.multiple',
  /** "Backhauls" */ findLoads_findLoads_backhauls = 'findLoads.findLoads.backhauls',
  /** "Backhaul loads" */ findLoads_findLoads_backhaulLoads = 'findLoads.findLoads.backhaulLoads',
  /** "Unblock" */ findLoads_findLoads_unblock = 'findLoads.findLoads.unblock',
  /** "Load details not shown for blocked company" */ findLoads_findLoads_loadDetailsNotShownForBlockedCompany = 'findLoads.findLoads.loadDetailsNotShownForBlockedCompany',
  /** "Search this area" */ findLoads_findLoads_searchNewArea = 'findLoads.findLoads.searchNewArea',
  /** "Email" */ findLoads_findLoads_email = 'findLoads.findLoads.email',
  /** "Facebook" */ findLoads_findLoads_facebook = 'findLoads.findLoads.facebook',
  /** "X" */ findLoads_findLoads_x = 'findLoads.findLoads.x',
  /** "You selected the maximum number of locations" */ findLoads_findLoads_maxSelectionCount = 'findLoads.findLoads.maxSelectionCount',
  /** "Select a maximum {{value}}" */ findLoads_findLoads_selectMaxiumum = 'findLoads.findLoads.selectMaxiumum',
  /** "New loads" */ findLoads_findLoads_newLoads = 'findLoads.findLoads.newLoads',
  /** "Set location to search" */ findLoads_findLoads_setLocationToSearch = 'findLoads.findLoads.setLocationToSearch',
  /** "“TL” and “LTL” are only available when Length and Weight are set to “Any”." */ findLoads_findLoads_loadSizeDescription = 'findLoads.findLoads.loadSizeDescription',
  /** "States, Provinces (Map)" */ findLoads_findLoads_selectStatesMap_title = 'findLoads.findLoads.selectStatesMap.title',
  /** "Select at least one state" */ findLoads_findLoads_selectStatesMap_warning_selectOneState = 'findLoads.findLoads.selectStatesMap.warning.selectOneState',
  /** "Loads" */ findLoads_loads_header = 'findLoads.loads.header',
  /** "close" */ findLoads_loads_close = 'findLoads.loads.close',
  /** "saved" */ findLoads_loads_saved = 'findLoads.loads.saved',
  /** "save" */ findLoads_loads_save = 'findLoads.loads.save',
  /** "Saved search" */ findLoads_loads_savedSearch = 'findLoads.loads.savedSearch',
  /** "Save search" */ findLoads_loads_saveSearch = 'findLoads.loads.saveSearch',
  /** "Map" */ findLoads_loads_map = 'findLoads.loads.map',
  /** "Cluster loads" */ findLoads_loads_clusterLoads = 'findLoads.loads.clusterLoads',
  /** "Found {{value}} loads" */ findLoads_loads_foundLoads = 'findLoads.loads.foundLoads',
  /** "No results found, try panning or zooming the map" */ findLoads_loads_mapError_noLoadsFound = 'findLoads.loads.mapError.noLoadsFound',
  /** "Load details" */ findLoads_loadDetails_header = 'findLoads.loadDetails.header',
  /** "Address" */ findLoads_loadDetails_address = 'findLoads.loadDetails.address',
  /** "Company" */ findLoads_loadDetails_company = 'findLoads.loadDetails.company',
  /** "Company loads" */ findLoads_loadDetails_companyLoads = 'findLoads.loadDetails.companyLoads',
  /** "View loads" */ findLoads_loadDetails_viewLoads = 'findLoads.loadDetails.viewLoads',
  /** "Find currently available loads posted by this company only." */ findLoads_loadDetails_companyLoadsDescription = 'findLoads.loadDetails.companyLoadsDescription',
  /** "MC number" */ findLoads_loadDetails_MCNumber = 'findLoads.loadDetails.MCNumber',
  /** "DOT number" */ findLoads_loadDetails_DOTNumber = 'findLoads.loadDetails.DOTNumber',
  /** "Company type:" */ findLoads_loadDetails_companyType = 'findLoads.loadDetails.companyType',
  /** "Website" */ findLoads_loadDetails_website = 'findLoads.loadDetails.website',
  /** "TIA Member" */ findLoads_loadDetails_member = 'findLoads.loadDetails.member',
  /** "Get paid faster" */ findLoads_loadDetails_getPaidFaster = 'findLoads.loadDetails.getPaidFaster',
  /** "Yes" */ findLoads_loadDetails_yes = 'findLoads.loadDetails.yes',
  /** "No" */ findLoads_loadDetails_no = 'findLoads.loadDetails.no',
  /** "Credit report" */ findLoads_loadDetails_CreditReport = 'findLoads.loadDetails.CreditReport',
  /** "Credit score" */ findLoads_loadDetails_CreditScore = 'findLoads.loadDetails.CreditScore',
  /** "Days-to-pay" */ findLoads_loadDetails_DaysToPay = 'findLoads.loadDetails.DaysToPay',
  /** "-" */ findLoads_loadDetails_noMatches = 'findLoads.loadDetails.noMatches',
  /** "Not available" */ findLoads_loadDetails_notAvailable = 'findLoads.loadDetails.notAvailable',
  /** "not provided" */ findLoads_loadDetails_notProvided = 'findLoads.loadDetails.notProvided',
  /** "Not available in your plan" */ findLoads_loadDetails_notAvailableInPlan = 'findLoads.loadDetails.notAvailableInPlan',
  /** "Loading details..." */ findLoads_loadDetails_loading = 'findLoads.loadDetails.loading',
  /** "Pre-approved by" */ findLoads_loadDetails_preApproved = 'findLoads.loadDetails.preApproved',
  /** "Factorable" */ findLoads_loadDetails_factorable = 'findLoads.loadDetails.factorable',
  /** "PC*MILER" */ findLoads_loadDetails_pcMiler = 'findLoads.loadDetails.pcMiler',
  /** "Google map" */ findLoads_loadDetails_googleMap = 'findLoads.loadDetails.googleMap',
  /** "Share this load via:" */ findLoads_loadDetails_shareLoad = 'findLoads.loadDetails.shareLoad',
  /** "Help" */ findLoads_loadDetails_help = 'findLoads.loadDetails.help',
  /** "Unlock" */ findLoads_loadDetails_unlock = 'findLoads.loadDetails.unlock',
  /** "Unavailable for offline loads" */ findLoads_loadDetails_unavailableForOffline = 'findLoads.loadDetails.unavailableForOffline',
  /** "Unavailable for assigned loads" */ findLoads_loadDetails_unavailableForAssigned = 'findLoads.loadDetails.unavailableForAssigned',
  /** "Unavailable for delivered loads" */ findLoads_loadDetails_unavailableForDelivered = 'findLoads.loadDetails.unavailableForDelivered',
  /** "Copy to clipboard" */ findLoads_loadDetails_copyToClipboard = 'findLoads.loadDetails.copyToClipboard',
  /** "My note" */ findLoads_loadDetails_myNote = 'findLoads.loadDetails.myNote',
  /** "Save note" */ findLoads_loadDetails_saveNote = 'findLoads.loadDetails.saveNote',
  /** "Load note" */ findLoads_loadDetails_loadNote = 'findLoads.loadDetails.loadNote',
  /** "This feature is not available in your plan" */ findLoads_loadDetails_permissions_modal_title = 'findLoads.loadDetails.permissions.modal.title',
  /** "Please contact our support team." */ findLoads_loadDetails_permissions_modal_message = 'findLoads.loadDetails.permissions.modal.message',
  /** "Call support" */ findLoads_loadDetails_permissions_modal_callSupport = 'findLoads.loadDetails.permissions.modal.callSupport',
  /** "Tag as onboarded" */ findLoads_loadDetails_onBoardedPopUp_header = 'findLoads.loadDetails.onBoardedPopUp.header',
  /** "If you are already onboarded with this company, all of their loads will be tagged for your own reference and filtering needs." */ findLoads_loadDetails_onBoardedPopUp_content = 'findLoads.loadDetails.onBoardedPopUp.content',
  /** "Yes, I’m onboarded" */ findLoads_loadDetails_onBoardedPopUp_button = 'findLoads.loadDetails.onBoardedPopUp.button',
  /** "Contact form" */ findLoads_loadDetails_emailFormPopup_header = 'findLoads.loadDetails.emailFormPopup.header',
  /** "Fill out the form below and it will be sent to the broker/shipper." */ findLoads_loadDetails_emailFormPopup_subtitle = 'findLoads.loadDetails.emailFormPopup.subtitle',
  /** "Comments" */ findLoads_loadDetails_emailFormPopup_placeholder = 'findLoads.loadDetails.emailFormPopup.placeholder',
  /** "Send" */ findLoads_loadDetails_emailFormPopup_submitText = 'findLoads.loadDetails.emailFormPopup.submitText',
  /** "Choose one of these options or place a bid:" */ findLoads_loadDetails_emailFormPopup_options_title = 'findLoads.loadDetails.emailFormPopup.options.title',
  /** "Is this load available?" */ findLoads_loadDetails_emailFormPopup_options_option1 = 'findLoads.loadDetails.emailFormPopup.options.option1',
  /** "What is the rate for this load?" */ findLoads_loadDetails_emailFormPopup_options_option2 = 'findLoads.loadDetails.emailFormPopup.options.option2',
  /** "What are the dimensions of this load?" */ findLoads_loadDetails_emailFormPopup_options_option3 = 'findLoads.loadDetails.emailFormPopup.options.option3',
  /** "Can I get onboarded with your company?" */ findLoads_loadDetails_emailFormPopup_options_option4 = 'findLoads.loadDetails.emailFormPopup.options.option4',
  /** "Place a bid($)" */ findLoads_loadDetails_emailFormPopup_options_placeholder = 'findLoads.loadDetails.emailFormPopup.options.placeholder',
  /** "Bid amount: {{bid}}$" */ findLoads_loadDetails_emailFormPopup_bidAmount = 'findLoads.loadDetails.emailFormPopup.bidAmount',
  /** "Contact form" */ findLoads_loadDetails_emailConfirmPopup_header = 'findLoads.loadDetails.emailConfirmPopup.header',
  /** "Thank you" */ findLoads_loadDetails_emailConfirmPopup_title = 'findLoads.loadDetails.emailConfirmPopup.title',
  /** "Your email form has been sent to {{companyName}}." */ findLoads_loadDetails_emailConfirmPopup_subtitle_sentTo = 'findLoads.loadDetails.emailConfirmPopup.subtitle.sentTo',
  /** "Please note that calling is a more reliable way to contact brokers/shippers." */ findLoads_loadDetails_emailConfirmPopup_subtitle_note = 'findLoads.loadDetails.emailConfirmPopup.subtitle.note',
  /** "Continue" */ findLoads_loadDetails_emailConfirmPopup_submitText = 'findLoads.loadDetails.emailConfirmPopup.submitText',
  /** "Edit details" */ findLoads_loadDetails_editCompanyPopup_title = 'findLoads.loadDetails.editCompanyPopup.title',
  /** "Please update company name or DOT#" */ findLoads_loadDetails_editCompanyPopup_content = 'findLoads.loadDetails.editCompanyPopup.content',
  /** "Company name" */ findLoads_loadDetails_editCompanyPopup_companyName = 'findLoads.loadDetails.editCompanyPopup.companyName',
  /** "DOT#" */ findLoads_loadDetails_editCompanyPopup_dot = 'findLoads.loadDetails.editCompanyPopup.dot',
  /** "Error in loading routes." */ findLoads_loadDetails_routeDirectionsRequestError = 'findLoads.loadDetails.routeDirectionsRequestError',
  /** "Stop {{value}}: " */ findLoads_loadDetails_stop = 'findLoads.loadDetails.stop',
  /** "Extra stops: {{value}}" */ findLoads_loadDetails_extraStops = 'findLoads.loadDetails.extraStops',
  /** "Regions" */ loadAvailability_region_title = 'loadAvailability.region.title',
  /** "Select region" */ loadAvailability_region_header = 'loadAvailability.region.header',
  /** "Outbound" */ loadAvailability_resultPanel_outbound = 'loadAvailability.resultPanel.outbound',
  /** "Intrastate" */ loadAvailability_resultPanel_intrastate = 'loadAvailability.resultPanel.intrastate',
  /** "Inbound" */ loadAvailability_resultPanel_inbound = 'loadAvailability.resultPanel.inbound',
  /** "No loads found" */ loadAvailability_noLoadFound_title = 'loadAvailability.noLoadFound.title',
  /** "Please choose another state or toggle between outbound, inbound and intrastate." */ loadAvailability_noLoadFound_subtitle = 'loadAvailability.noLoadFound.subtitle',
  /** "Something went wrong" */ loadAvailability_errorScreen_title = 'loadAvailability.errorScreen.title',
  /** "Sorry for the inconvenience. We’ll be back up and running as fast as possible. Please try again later." */ loadAvailability_errorScreen_subtitle = 'loadAvailability.errorScreen.subtitle',
  /** "Multiple" */ loadAvailability_Multiple = 'loadAvailability.Multiple',
  /** "Trailer type:" */ loadAvailability_type = 'loadAvailability.type',
  /** "Region:" */ loadAvailability_regionTitle = 'loadAvailability.regionTitle',
  /** "Density list" */ loadAvailability_densityList = 'loadAvailability.densityList',
  /** "Density map" */ loadAvailability_densityMap_title = 'loadAvailability.densityMap.title',
  /** "Show load results" */ loadAvailability_densityMap_showResults = 'loadAvailability.densityMap.showResults',
  /** "High (loads are available)" */ loadAvailability_mapColorKey_high = 'loadAvailability.mapColorKey.high',
  /** "Medium (loads are slightly limited)" */ loadAvailability_mapColorKey_medium = 'loadAvailability.mapColorKey.medium',
  /** "Low (loads are limited)" */ loadAvailability_mapColorKey_low = 'loadAvailability.mapColorKey.low',
  /** "N/A" */ tripBuilder_empty = 'tripBuilder.empty',
  /** "Age" */ tripBuilder_age = 'tripBuilder.age',
  /** "Pick up" */ tripBuilder_pickUp = 'tripBuilder.pickUp',
  /** "Pick up location" */ tripBuilder_pickUpLocation = 'tripBuilder.pickUpLocation',
  /** "Drop off location" */ tripBuilder_dropOffLocation = 'tripBuilder.dropOffLocation',
  /** "Trip" */ tripBuilder_title = 'tripBuilder.title',
  /** "Equip" */ tripBuilder_equip = 'tripBuilder.equip',
  /** "Size" */ tripBuilder_size = 'tripBuilder.size',
  /** "Company" */ tripBuilder_company = 'tripBuilder.company',
  /** "Status" */ tripBuilder_status = 'tripBuilder.status',
  /** "Miles" */ tripBuilder_miles = 'tripBuilder.miles',
  /** "Time" */ tripBuilder_time = 'tripBuilder.time',
  /** "Revenues" */ tripBuilder_revenues = 'tripBuilder.revenues',
  /** "Rate" */ tripBuilder_rate = 'tripBuilder.rate',
  /** "Rates" */ tripBuilder_rates = 'tripBuilder.rates',
  /** "Revenue" */ tripBuilder_revenue = 'tripBuilder.revenue',
  /** "Fuel" */ tripBuilder_fuel = 'tripBuilder.fuel',
  /** "Tolls" */ tripBuilder_tolls = 'tripBuilder.tolls',
  /** "Profits" */ tripBuilder_profits = 'tripBuilder.profits',
  /** "Profit" */ tripBuilder_profit = 'tripBuilder.profit',
  /** "Added" */ tripBuilder_added = 'tripBuilder.added',
  /** "Modify trip" */ tripBuilder_modifyTrip = 'tripBuilder.modifyTrip',
  /** "Trip builder feedback" */ tripBuilder_feedbackTitle = 'tripBuilder.feedbackTitle',
  /** "We'd love your feedback about the new trip builder. It helps us make 123Loadboard better." */ tripBuilder_feedbackSubtitle = 'tripBuilder.feedbackSubtitle',
  /** "Trip deleted" */ tripBuilder_tripDelete_SnackBar_Message = 'tripBuilder.tripDelete_SnackBar_Message',
  /** "Trip created" */ tripBuilder_tripCreated_SnackBar_Message = 'tripBuilder.tripCreated_SnackBar_Message',
  /** "Load added" */ tripBuilder_loadAdd_SnackBar_Message = 'tripBuilder.loadAdd_SnackBar_Message',
  /** "Load hidden" */ tripBuilder_loadHide_SnackBar_Message = 'tripBuilder.loadHide_SnackBar_Message',
  /** "Load removed" */ tripBuilder_loadRemove_SnackBar_Message = 'tripBuilder.loadRemove_SnackBar_Message',
  /** "Processing request ..." */ tripBuilder_tripBuilder_loadingScreenTitle = 'tripBuilder.tripBuilder.loadingScreenTitle',
  /** "Please wait ... it can take up to 20-30 seconds to get your results. Our system is analyzing possible routes." */ tripBuilder_tripBuilder_loadingScreenDescription = 'tripBuilder.tripBuilder.loadingScreenDescription',
  /** "Load-by-load" */ tripBuilder_newTrip_loadByLoadLabel = 'tripBuilder.newTrip.loadByLoadLabel',
  /** "Build your own trip load-by-load" */ tripBuilder_newTrip_loadByLoadDescription = 'tripBuilder.newTrip.loadByLoadDescription',
  /** "Entire trip" */ tripBuilder_newTrip_entireTripLabel = 'tripBuilder.newTrip.entireTripLabel',
  /** "Automatically builds an entire trip for you." */ tripBuilder_newTrip_entireTripDescription = 'tripBuilder.newTrip.entireTripDescription',
  /** "Trip parameters" */ tripBuilder_newTrip_tripParameters = 'tripBuilder.newTrip.tripParameters',
  /** "Excluded states (optional)" */ tripBuilder_newTrip_excludedStatesOptional = 'tripBuilder.newTrip.excludedStatesOptional',
  /** "Excluded states" */ tripBuilder_newTrip_excludedStates = 'tripBuilder.newTrip.excludedStates',
  /** "Only select states you do not want to travel to." */ tripBuilder_newTrip_excludedStatesSubHeader = 'tripBuilder.newTrip.excludedStatesSubHeader',
  /** "Maximum loads per trip" */ tripBuilder_newTrip_maximumLoads = 'tripBuilder.newTrip.maximumLoads',
  /** "Result layout" */ tripBuilder_newTrip_popup_popupHeader = 'tripBuilder.newTrip.popup.popupHeader',
  /** "Load-by-load allows you to build your own trip one load at a time." */ tripBuilder_newTrip_popup_popupDescription1 = 'tripBuilder.newTrip.popup.popupDescription1',
  /** "Entire trip automatically builds a trip by selecting loads matching your parameters." */ tripBuilder_newTrip_popup_popupDescription2 = 'tripBuilder.newTrip.popup.popupDescription2',
  /** "{{loadsCount}} load(s) for leg number {{legCount}}." */ tripBuilder_addLoad_totalLegs = 'tripBuilder.addLoad.totalLegs',
  /** "Next leg: {{count}} Load(s)" */ tripBuilder_addLoad_nextLeg = 'tripBuilder.addLoad.nextLeg',
  /** "No results found" */ tripBuilder_addLoad_noResultsHeader = 'tripBuilder.addLoad.noResultsHeader',
  /** "Load removed" */ tripBuilder_addLoad_loadRemoved = 'tripBuilder.addLoad.loadRemoved',
  /** "There are no loads currently matching your criteria. Please try again later." */ tripBuilder_addLoad_noResultsDescription = 'tripBuilder.addLoad.noResultsDescription',
  /** "Add load" */ tripBuilder_addLoad_popup_header = 'tripBuilder.addLoad.popup.header',
  /** "If you have already booked this load, you may include the booked rate to make profit calculations more accurate." */ tripBuilder_addLoad_popup_description1 = 'tripBuilder.addLoad.popup.description1',
  /** "Confirm booked rate (optional)" */ tripBuilder_addLoad_popup_subHeader = 'tripBuilder.addLoad.popup.subHeader',
  /** "Flat rate" */ tripBuilder_addLoad_popup_radioFlatRate = 'tripBuilder.addLoad.popup.radioFlatRate',
  /** "Rate per mile" */ tripBuilder_addLoad_popup_radioRatePerMile = 'tripBuilder.addLoad.popup.radioRatePerMile',
  /** "Booked rate({{currency}})" */ tripBuilder_addLoad_popup_inputPlaceHolder = 'tripBuilder.addLoad.popup.inputPlaceHolder',
  /** "Adding this load to your trip does not automatically book it. To book this load, please contact the load poster." */ tripBuilder_addLoad_popup_description2 = 'tripBuilder.addLoad.popup.description2',
  /** "Add load" */ tripBuilder_addLoad_popup_addButton = 'tripBuilder.addLoad.popup.addButton',
  /** "Delete load" */ tripBuilder_addLoad_popup_deleteLoadTitle1 = 'tripBuilder.addLoad.popup.deleteLoadTitle1',
  /** "Deleting this load will permanently remove it from your results." */ tripBuilder_addLoad_popup_deleteLoadTitle2 = 'tripBuilder.addLoad.popup.deleteLoadTitle2',
  /** "Select any reasons why you want to delete this load:" */ tripBuilder_addLoad_popup_deleteLoadTitle3 = 'tripBuilder.addLoad.popup.deleteLoadTitle3',
  /** "Not Interested" */ tripBuilder_addLoad_popup_deleteLoadItem1 = 'tripBuilder.addLoad.popup.deleteLoadItem1',
  /** "Already booked (load is not available)" */ tripBuilder_addLoad_popup_deleteLoadItem2 = 'tripBuilder.addLoad.popup.deleteLoadItem2',
  /** "Low pay rate" */ tripBuilder_addLoad_popup_deleteLoadItem3 = 'tripBuilder.addLoad.popup.deleteLoadItem3',
  /** "Incorrect information" */ tripBuilder_addLoad_popup_deleteLoadItem4 = 'tripBuilder.addLoad.popup.deleteLoadItem4',
  /** "Other" */ tripBuilder_addLoad_popup_deleteLoadItem5 = 'tripBuilder.addLoad.popup.deleteLoadItem5',
  /** "Delete load" */ tripBuilder_addLoad_popup_deleteLoadConfirm = 'tripBuilder.addLoad.popup.deleteLoadConfirm',
  /** "Remove load" */ tripBuilder_addLoad_popup_removeLoadConfirm = 'tripBuilder.addLoad.popup.removeLoadConfirm',
  /** "In order to remove this load, please first remove all loads that come after this one in the trip order." */ tripBuilder_addLoad_popup_removeLoad = 'tripBuilder.addLoad.popup.removeLoad',
  /** "Your trip details" */ tripBuilder_trip_contentHeader = 'tripBuilder.trip.contentHeader',
  /** "Found {{count}} option(s)" */ tripBuilder_trip_foundTrips = 'tripBuilder.trip.foundTrips',
  /** "Next option" */ tripBuilder_trip_nextTrip = 'tripBuilder.trip.nextTrip',
  /** "Prev option" */ tripBuilder_trip_prevTrip = 'tripBuilder.trip.prevTrip',
  /** "Build your trip by adding the loads that work for you." */ tripBuilder_trip_contentSubHeader = 'tripBuilder.trip.contentSubHeader',
  /** "Add more loads to your trip from the following options." */ tripBuilder_trip_tripOptionsSubHeader = 'tripBuilder.trip.tripOptionsSubHeader',
  /** "Total profit from added loads" */ tripBuilder_trip_profitPerTrip = 'tripBuilder.trip.profitPerTrip',
  /** "Trip option summary" */ tripBuilder_trip_profitPerOption = 'tripBuilder.trip.profitPerOption',
  /** "Profit per day" */ tripBuilder_trip_profitPerDay = 'tripBuilder.trip.profitPerDay',
  /** "Load(s)" */ tripBuilder_trip_loads = 'tripBuilder.trip.loads',
  /** "Mileage" */ tripBuilder_trip_mileage = 'tripBuilder.trip.mileage',
  /** "Trip days" */ tripBuilder_trip_tripDays = 'tripBuilder.trip.tripDays',
  /** "Deadhead" */ tripBuilder_trip_deadhead = 'tripBuilder.trip.deadhead',
  /** "Added" */ tripBuilder_trip_added = 'tripBuilder.trip.added',
  /** "Continue building trip" */ tripBuilder_trip_continueTripBtn = 'tripBuilder.trip.continueTripBtn',
  /** "Your trip is not complete. Continue building this trip by adding more loads until you reach your final destination." */ tripBuilder_trip_incompleteTrip = 'tripBuilder.trip.incompleteTrip',
  /** "Your trip is all set! If you haven't already, you should contact the load posters to book these loads." */ tripBuilder_trip_completeTrip = 'tripBuilder.trip.completeTrip',
  /** "Profit per day" */ tripBuilder_trip_popup_profitPerDayPopupInfoHeader = 'tripBuilder.trip.popup.profitPerDayPopupInfoHeader',
  /** "Your daily profits are calculated by collecting rate data for all loads in your trip and subtracting estimated fuel and toll costs." */ tripBuilder_trip_popup_profitPerDayPopupInfo1 = 'tripBuilder.trip.popup.profitPerDayPopupInfo1',
  /** "Mileage, toll, and Deadhead data is collected via PC*Miler." */ tripBuilder_trip_popup_profitPerDayPopupInfo2 = 'tripBuilder.trip.popup.profitPerDayPopupInfo2',
  /** "Revenue and profit data is collected via Rate Check." */ tripBuilder_trip_popup_profitPerDayPopupInfo3 = 'tripBuilder.trip.popup.profitPerDayPopupInfo3',
  /** "Fuel cost is collected daily based on current State prices." */ tripBuilder_trip_popup_profitPerDayPopupInfo4 = 'tripBuilder.trip.popup.profitPerDayPopupInfo4',
  /** "Delete this trip" */ tripBuilder_trip_popup_deleteTripTitle = 'tripBuilder.trip.popup.deleteTripTitle',
  /** "You will no longer have access to the details for the selected loads." */ tripBuilder_trip_popup_deleteTripP1 = 'tripBuilder.trip.popup.deleteTripP1',
  /** "If you need access to these loads later, you should mark them as \"Booked\"." */ tripBuilder_trip_popup_deleteTripP2 = 'tripBuilder.trip.popup.deleteTripP2',
  /** "Remove this load" */ tripBuilder_trip_popup_removeLoadTitle = 'tripBuilder.trip.popup.removeLoadTitle',
  /** "Are you sure you want to remove this load from your trip?" */ tripBuilder_trip_popup_removeLoadP1 = 'tripBuilder.trip.popup.removeLoadP1',
  /** "All company loads" */ postLoads_allCompanyLoads = 'postLoads.allCompanyLoads',
  /** "Poster" */ postLoads_poster = 'postLoads.poster',
  /** "Account under review" */ postLoads_pendingVerification_title = 'postLoads.pendingVerification.title',
  /** "Your account is currently under review, please allow 1 business day for the review process to complete." */ postLoads_pendingVerification_subtitle = 'postLoads.pendingVerification.subtitle',
  /** "Thank you for signing up with 123Loadboard!" */ postLoads_pendingVerification_thankYouForSignUp = 'postLoads.pendingVerification.thankYouForSignUp',
  /** "Your account is " */ postLoads_pendingVerification_yourAccount = 'postLoads.pendingVerification.yourAccount',
  /** "currently under review" */ postLoads_pendingVerification_currentlyUnderReview = 'postLoads.pendingVerification.currentlyUnderReview',
  /** ", please allow one business day for the review process to complete." */ postLoads_pendingVerification_underReviewContent = 'postLoads.pendingVerification.underReviewContent',
  /** "Upon approval, you will receive an email providing you full access to post loads and reach our carrier network on the #1 rated load board app." */ postLoads_pendingVerification_uponApprovalContent = 'postLoads.pendingVerification.uponApprovalContent',
  /** "Change contact" */ postLoads_ChangeContact = 'postLoads.ChangeContact',
  /** "Anywhere" */ postLoads_Anywhere = 'postLoads.Anywhere',
  /** "My loads" */ postLoads_MyLoads = 'postLoads.MyLoads',
  /** "Loads" */ postLoads_Loads = 'postLoads.Loads',
  /** "Post single" */ postLoads_PostSingle = 'postLoads.PostSingle',
  /** "Post multiple" */ postLoads_PostMultiple = 'postLoads.PostMultiple',
  /** "You can save time by posting multiple loads at once via uploading files directly, sending files in through FTP, or having us scrape your website's available loads. Please visit our " */ postLoads_postMultipleBanner_content1 = 'postLoads.postMultipleBanner.content1',
  /** "instructions page" */ postLoads_postMultipleBanner_instructionsPage = 'postLoads.postMultipleBanner.instructionsPage',
  /** " for more details. Contact us at " */ postLoads_postMultipleBanner_content2 = 'postLoads.postMultipleBanner.content2',
  /** ", ext. 2 for assistance." */ postLoads_postMultipleBanner_content3 = 'postLoads.postMultipleBanner.content3',
  /** "--" */ postLoads_NoDate = 'postLoads.NoDate',
  /** "Status" */ postLoads_Status = 'postLoads.Status',
  /** "Switch to previous version" */ postLoads_SwitchToPreviousVersion = 'postLoads.SwitchToPreviousVersion',
  /** "Online" */ postLoads_Online = 'postLoads.Online',
  /** "Offline" */ postLoads_Offline = 'postLoads.Offline',
  /** "Working loads" */ postLoads_Working = 'postLoads.Working',
  /** "Completed loads" */ postLoads_Completed = 'postLoads.Completed',
  /** "Assigned" */ postLoads_Assigned = 'postLoads.Assigned',
  /** "Delivered" */ postLoads_Delivered = 'postLoads.Delivered',
  /** "Load details" */ postLoads_LoadDetails = 'postLoads.LoadDetails',
  /** "Item/Commodity" */ postLoads_ItemCommodity = 'postLoads.ItemCommodity',
  /** "Average rate" */ postLoads_AverageRate = 'postLoads.AverageRate',
  /** "Load age refreshed" */ postLoads_RefreshAgeMessage = 'postLoads.RefreshAgeMessage',
  /** "Truck locator" */ postLoads_TruckLocatorTitle = 'postLoads.TruckLocatorTitle',
  /** "View carriers nearby" */ postLoads_TruckLocatorSubTitle = 'postLoads.TruckLocatorSubTitle',
  /** "Views: {{count}}" */ postLoads_Views_Count = 'postLoads.Views_Count',
  /** "{{displayedViews}} of {{viewsCount}} total load views" */ postLoads_TotalLoadViews = 'postLoads.TotalLoadViews',
  /** "{{value}} mi" */ postLoads_Miles = 'postLoads.Miles',
  /** "{{value}}/per mile" */ postLoads_perMile = 'postLoads.perMile',
  /** "{{value}}%" */ postLoads_StrengthValue = 'postLoads.StrengthValue',
  /** "Loads refreshed" */ postLoads_RefreshAgeMessageBulk = 'postLoads.RefreshAgeMessageBulk',
  /** "Load dates/times refreshed" */ postLoads_UpdatePickupDateMessageBulk = 'postLoads.UpdatePickupDateMessageBulk',
  /** "Loads deleted" */ postLoads_LoadsDeleteMessageBulk = 'postLoads.LoadsDeleteMessageBulk',
  /** "Load statuses updated" */ postLoads_LoadsStatusChangeMessageBulk = 'postLoads.LoadsStatusChangeMessageBulk',
  /** "Load posted successfully" */ postLoads_LoadPostedSuccessfully = 'postLoads.LoadPostedSuccessfully',
  /** "Cloned load posted" */ postLoads_ClonedLoadPosted = 'postLoads.ClonedLoadPosted',
  /** "Load edited" */ postLoads_LoadEdited = 'postLoads.LoadEdited',
  /** "Specs (Optional)" */ postLoads_SpecsOptional = 'postLoads.SpecsOptional',
  /** "Select" */ postLoads_Select = 'postLoads.Select',
  /** "*Please fill out all mandatory fields." */ postLoads_fillMandatoryFields = 'postLoads.fillMandatoryFields',
  /** "*Please attach 1 or more documents." */ postLoads_attachDocuments = 'postLoads.attachDocuments',
  /** "Booking options" */ postLoads_bookingOptions_title = 'postLoads.bookingOptions.title',
  /** "Phone" */ postLoads_bookingOptions_phone = 'postLoads.bookingOptions.phone',
  /** "Email" */ postLoads_bookingOptions_email = 'postLoads.bookingOptions.email',
  /** "Messaging" */ postLoads_bookingOptions_messaging = 'postLoads.bookingOptions.messaging',
  /** "Bidding" */ postLoads_bookingOptions_bidding = 'postLoads.bookingOptions.bidding',
  /** "Book now" */ postLoads_bookingOptions_bookNow = 'postLoads.bookingOptions.bookNow',
  /** "Manage onboarded carriers" */ postLoads_bookingOptions_manageOnboardLink = 'postLoads.bookingOptions.manageOnboardLink',
  /** "Duplicate reference number" */ postLoads_duplicateRefNumberTitle = 'postLoads.duplicateRefNumberTitle',
  /** "A load with the same reference number already exists. Please enter a different reference number or leave the field blank." */ postLoads_duplicateRefNumberMessage = 'postLoads.duplicateRefNumberMessage',
  /** "Location and dates" */ postLoads_collapsibleMenu_locationAndDates = 'postLoads.collapsibleMenu.locationAndDates',
  /** "Equipment details" */ postLoads_collapsibleMenu_equipmentDetails = 'postLoads.collapsibleMenu.equipmentDetails',
  /** "Load info" */ postLoads_collapsibleMenu_loadInfo = 'postLoads.collapsibleMenu.loadInfo',
  /** "Trip rate and distance" */ postLoads_collapsibleMenu_tripRateAndDistance = 'postLoads.collapsibleMenu.tripRateAndDistance',
  /** "Posting contact" */ postLoads_collapsibleMenu_postingContact = 'postLoads.collapsibleMenu.postingContact',
  /** "Set offline" */ postLoads_manipulation_SetOffline = 'postLoads.manipulation.SetOffline',
  /** "Set online" */ postLoads_manipulation_SetOnline = 'postLoads.manipulation.SetOnline',
  /** "Refresh age" */ postLoads_manipulation_RefreshAge = 'postLoads.manipulation.RefreshAge',
  /** "Edit" */ postLoads_manipulation_Edit = 'postLoads.manipulation.Edit',
  /** "Delete" */ postLoads_manipulation_Delete = 'postLoads.manipulation.Delete',
  /** "Clone" */ postLoads_manipulation_Clone = 'postLoads.manipulation.Clone',
  /** "Messages" */ postLoads_manipulation_messages = 'postLoads.manipulation.messages',
  /** "We’d love your feedback about the new load posting tool. It helps us make 123Loadboard better." */ postLoads_feedbackContent = 'postLoads.feedbackContent',
  /** "OK" */ postLoads_popUps_error_button = 'postLoads.popUps.error.button',
  /** "Error" */ postLoads_popUps_error_title = 'postLoads.popUps.error.title',
  /** "An internal server error occurred. Please try again." */ postLoads_popUps_error_content = 'postLoads.popUps.error.content',
  /** "Post strength" */ postLoads_popUps_postStrength_title = 'postLoads.popUps.postStrength.title',
  /** "The greater your post strength, the more exposure your load post will receive." */ postLoads_popUps_postStrength_content = 'postLoads.popUps.postStrength.content',
  /** " Strength is determined based on the amount of details provided." */ postLoads_popUps_postStrength_paragraph = 'postLoads.popUps.postStrength.paragraph',
  /** "Pick up time: 5%" */ postLoads_popUps_postStrength_pickUpTimeStrength = 'postLoads.popUps.postStrength.pickUpTimeStrength',
  /** "Drop off date: 15%" */ postLoads_popUps_postStrength_dropOffDateStrength = 'postLoads.popUps.postStrength.dropOffDateStrength',
  /** "Drop off time: 5%" */ postLoads_popUps_postStrength_dropOffTimeStrength = 'postLoads.popUps.postStrength.dropOffTimeStrength',
  /** "Length: 20%" */ postLoads_popUps_postStrength_lengthStrength = 'postLoads.popUps.postStrength.lengthStrength',
  /** "Weight: 20%" */ postLoads_popUps_postStrength_weightTimeStrength = 'postLoads.popUps.postStrength.weightTimeStrength',
  /** "Pay ($): 35%" */ postLoads_popUps_postStrength_paymentTimeStrength = 'postLoads.popUps.postStrength.paymentTimeStrength',
  /** "OK" */ postLoads_popUps_postStrength_button = 'postLoads.popUps.postStrength.button',
  /** "Are you sure you want to set load “Online”?" */ postLoads_popUps_updateStatus_SetOnlineContent = 'postLoads.popUps.updateStatus.SetOnlineContent',
  /** "Are you sure you want to set load “Offline”?" */ postLoads_popUps_updateStatus_SetOfflineContent = 'postLoads.popUps.updateStatus.SetOfflineContent',
  /** "Load status updated" */ postLoads_popUps_updateStatus_SnackBar_Message = 'postLoads.popUps.updateStatus.SnackBar_Message',
  /** "Delete load" */ postLoads_popUps_deleteLoad_title = 'postLoads.popUps.deleteLoad.title',
  /** "For easy reposting, we suggest changing the status to “offline” instead of deleting." */ postLoads_popUps_deleteLoad_content = 'postLoads.popUps.deleteLoad.content',
  /** "Delete" */ postLoads_popUps_deleteLoad_deleteButton = 'postLoads.popUps.deleteLoad.deleteButton',
  /** "Cancel" */ postLoads_popUps_deleteLoad_cancelButton = 'postLoads.popUps.deleteLoad.cancelButton',
  /** "Load deleted" */ postLoads_popUps_deleteLoad_SnackBar_Message = 'postLoads.popUps.deleteLoad.SnackBar_Message',
  /** "Delete loads" */ postLoads_popUps_bulkDeleteLoads_title = 'postLoads.popUps.bulkDeleteLoads.title',
  /** "For easy reposting, we suggest changing the status to “offline” instead of deleting." */ postLoads_popUps_bulkDeleteLoads_content = 'postLoads.popUps.bulkDeleteLoads.content',
  /** "Delete" */ postLoads_popUps_bulkDeleteLoads_deleteButton = 'postLoads.popUps.bulkDeleteLoads.deleteButton',
  /** "Cancel" */ postLoads_popUps_bulkDeleteLoads_cancelButton = 'postLoads.popUps.bulkDeleteLoads.cancelButton',
  /** "{{lastViewedAge}} ago" */ postLoads_popUps_viewerInfo_lastViewedAge = 'postLoads.popUps.viewerInfo.lastViewedAge',
  /** "Contact" */ postLoads_popUps_viewerInfo_contact = 'postLoads.popUps.viewerInfo.contact',
  /** "Company" */ postLoads_popUps_viewerInfo_company = 'postLoads.popUps.viewerInfo.company',
  /** "Phone" */ postLoads_popUps_viewerInfo_phone = 'postLoads.popUps.viewerInfo.phone',
  /** "Insurance on File" */ postLoads_popUps_viewerInfo_insuranceOnFile = 'postLoads.popUps.viewerInfo.insuranceOnFile',
  /** "Common" */ postLoads_popUps_viewerInfo_Common = 'postLoads.popUps.viewerInfo.Common',
  /** "Contract" */ postLoads_popUps_viewerInfo_Contract = 'postLoads.popUps.viewerInfo.Contract',
  /** "USDOT Number" */ postLoads_popUps_viewerInfo_USDOTNumber = 'postLoads.popUps.viewerInfo.USDOTNumber',
  /** "MC Number" */ postLoads_popUps_viewerInfo_MCNumber = 'postLoads.popUps.viewerInfo.MCNumber',
  /** "Contact" */ postLoads_Contact = 'postLoads.Contact',
  /** "TL" */ postLoads_Tl = 'postLoads.Tl',
  /** "LTL" */ postLoads_Ltl = 'postLoads.Ltl',
  /** "Pickup date" */ postLoads_PickUpDate = 'postLoads.PickUpDate',
  /** "Pickup time" */ postLoads_PickUpTime = 'postLoads.PickUpTime',
  /** "Drop off date" */ postLoads_DropOffDate = 'postLoads.DropOffDate',
  /** "Drop off time" */ postLoads_DropOffTime = 'postLoads.DropOffTime',
  /** "Pick up" */ postLoads_PickUp = 'postLoads.PickUp',
  /** "Drop off" */ postLoads_DropOff = 'postLoads.DropOff',
  /** "Available capacity" */ postLoads_AvailableCapacity = 'postLoads.AvailableCapacity',
  /** "Trailer Specifications" */ postLoads_TrailerSpecifications = 'postLoads.TrailerSpecifications',
  /** "Trailer type" */ postLoads_TrailerType = 'postLoads.TrailerType',
  /** "Load size" */ postLoads_LoadSize = 'postLoads.LoadSize',
  /** "Exclude loads without length" */ postLoads_ExcludeLoadsWithoutLength = 'postLoads.ExcludeLoadsWithoutLength',
  /** "Exclude loads without weight" */ postLoads_ExcludeLoadsWithoutWeight = 'postLoads.ExcludeLoadsWithoutWeight',
  /** "Pickup location" */ postLoads_PickupLocation = 'postLoads.PickupLocation',
  /** "Drop off location" */ postLoads_DropOffLocation = 'postLoads.DropOffLocation',
  /** "Multiple" */ postLoads_Multiple = 'postLoads.Multiple',
  /** "Post" */ postLoads_Post = 'postLoads.Post',
  /** "Post load" */ postLoads_PostLoad = 'postLoads.PostLoad',
  /** "Edit load" */ postLoads_EditLoad = 'postLoads.EditLoad',
  /** "Clone load" */ postLoads_CloneLoad = 'postLoads.CloneLoad',
  /** "Clear" */ postLoads_ClearAll = 'postLoads.ClearAll',
  /** "Pre-fill from recent posts" */ postLoads_PrefillFrom = 'postLoads.PrefillFrom',
  /** "Recent Posts" */ postLoads_RecentPosts = 'postLoads.RecentPosts',
  /** "Reset" */ postLoads_Reset = 'postLoads.Reset',
  /** "Update" */ postLoads_Update = 'postLoads.Update',
  /** "Low" */ postLoads_Low = 'postLoads.Low',
  /** "Average" */ postLoads_Average = 'postLoads.Average',
  /** "Distance (mi)" */ postLoads_Distance = 'postLoads.Distance',
  /** "Flat rate ($)" */ postLoads_Rate = 'postLoads.Rate',
  /** "Driving time" */ postLoads_DrivingTime = 'postLoads.DrivingTime',
  /** "Upgrade plan" */ postLoads_UpgradePlan = 'postLoads.UpgradePlan',
  /** "Unlock" */ postLoads_Unlock = 'postLoads.Unlock',
  /** "Team driven" */ postLoads_TeamDriven = 'postLoads.TeamDriven',
  /** "Quantity of loads" */ postLoads_Quantity = 'postLoads.Quantity',
  /** "Add extra stops +" */ postLoads_AddExtraStops = 'postLoads.AddExtraStops',
  /** "Add equipment +" */ postLoads_AddEquipments = 'postLoads.AddEquipments',
  /** "Extra stop(s)" */ postLoads_ExtraStops = 'postLoads.ExtraStops',
  /** "Contact name" */ postLoads_ContactName = 'postLoads.ContactName',
  /** "Name" */ postLoads_Name = 'postLoads.Name',
  /** "Post strength" */ postLoads_PostStrength = 'postLoads.PostStrength',
  /** "Phone" */ postLoads_Phone = 'postLoads.Phone',
  /** "Ext." */ postLoads_Ext = 'postLoads.Ext',
  /** "Email" */ postLoads_Email = 'postLoads.Email',
  /** "High" */ postLoads_High = 'postLoads.High',
  /** "Drop off" */ postLoads_dropOff = 'postLoads.dropOff',
  /** "Pick up" */ postLoads_pickUp = 'postLoads.pickUp',
  /** "Total trip" */ postLoads_totalTrip = 'postLoads.totalTrip',
  /** "PC*Miler" */ postLoads_pcMiler = 'postLoads.pcMiler',
  /** "Trailer type" */ postLoads_trailerType = 'postLoads.trailerType',
  /** "Trailer Specifications" */ postLoads_trailerSpecifications = 'postLoads.trailerSpecifications',
  /** "Available capacity" */ postLoads_availableCapacity = 'postLoads.availableCapacity',
  /** "Load size" */ postLoads_loadSize = 'postLoads.loadSize',
  /** "Length (ft)" */ postLoads_length = 'postLoads.length',
  /** "Weight (lbs)" */ postLoads_weight = 'postLoads.weight',
  /** "Average rate by rate check" */ postLoads_averageRateByCheck = 'postLoads.averageRateByCheck',
  /** "Ref. #" */ postLoads_ref = 'postLoads.ref',
  /** "Item/Commodity" */ postLoads_itemCommodity = 'postLoads.itemCommodity',
  /** "More options" */ postLoads_moreOptions = 'postLoads.moreOptions',
  /** "Additional load details" */ postLoads_additionalLoadDetails = 'postLoads.additionalLoadDetails',
  /** "{{status}} loads" */ postLoads_PostedPanel_PostedHeaderLabel = 'postLoads.PostedPanel.PostedHeaderLabel',
  /** "{{quantity}} selected." */ postLoads_PostedPanel_SelectedLoads = 'postLoads.PostedPanel.SelectedLoads',
  /** "All {{quantity}} selected" */ postLoads_PostedPanel_AllSelectedLoads = 'postLoads.PostedPanel.AllSelectedLoads',
  /** "Select all {{quantity}}" */ postLoads_PostedPanel_SelectAllLoads = 'postLoads.PostedPanel.SelectAllLoads',
  /** "No online loads" */ postLoads_PostedPanel_NoOnlineLoads = 'postLoads.PostedPanel.NoOnlineLoads',
  /** "No offline loads" */ postLoads_PostedPanel_NoOfflineLoads = 'postLoads.PostedPanel.NoOfflineLoads',
  /** "No assigned loads" */ postLoads_PostedPanel_NoAssignedLoads = 'postLoads.PostedPanel.NoAssignedLoads',
  /** "No delivered loads" */ postLoads_PostedPanel_NoDeliveredLoads = 'postLoads.PostedPanel.NoDeliveredLoads',
  /** "When you post loads you’ll see them here" */ postLoads_PostedPanel_NoOnlineLoadsSubtitle = 'postLoads.PostedPanel.NoOnlineLoadsSubtitle',
  /** "Create loads as drafts, and post them when you’re ready." */ postLoads_PostedPanel_NoOfflineLoadsSubtitle = 'postLoads.PostedPanel.NoOfflineLoadsSubtitle',
  /** "Mark loads as assigned when you have confirmed the booking." */ postLoads_PostedPanel_NoAssignedLoadsSubtitle = 'postLoads.PostedPanel.NoAssignedLoadsSubtitle',
  /** "Mark loads as delivered when you have confirmed they have reached their destination." */ postLoads_PostedPanel_NoDeliveredLoadsSubtitle = 'postLoads.PostedPanel.NoDeliveredLoadsSubtitle',
  /** "Pickup date" */ postLoads_PostedPanel_PickupDate = 'postLoads.PostedPanel.PickupDate',
  /** "Refresh age" */ postLoads_PostedPanel_RefreshAge = 'postLoads.PostedPanel.RefreshAge',
  /** "Status" */ postLoads_PostedPanel_Status = 'postLoads.PostedPanel.Status',
  /** "Filter by location, ref#, equip, size" */ postLoads_PostedPanel_FreeTextFilter = 'postLoads.PostedPanel.FreeTextFilter',
  /** "Ref#" */ postLoads_PostedPanel_Ref = 'postLoads.PostedPanel.Ref',
  /** "Sort by" */ postLoads_SortByPanel_SortBy = 'postLoads.SortByPanel.SortBy',
  /** "Reset" */ postLoads_SortByPanel_Reset = 'postLoads.SortByPanel.Reset',
  /** "Status" */ postLoads_StatusPanel_Status = 'postLoads.StatusPanel.Status',
  /** "Select status" */ postLoads_StatusPanel_SelectStatus = 'postLoads.StatusPanel.SelectStatus',
  /** "Online" */ postLoads_StatusPanel_Online = 'postLoads.StatusPanel.Online',
  /** "Offline" */ postLoads_StatusPanel_Offline = 'postLoads.StatusPanel.Offline',
  /** "Invalid distance. Please refer to the PC*Miler total trip." */ postLoads_errors_InvalidDistance = 'postLoads.errors.InvalidDistance',
  /** "Pre-fill from recent" */ postLoads_loadTemplates_pageTitle = 'postLoads.loadTemplates.pageTitle',
  /** "Pre-fill" */ postLoads_loadTemplates_buttonTitle = 'postLoads.loadTemplates.buttonTitle',
  /** "No recent posts" */ postLoads_loadTemplates_notFound_title = 'postLoads.loadTemplates.notFound.title',
  /** "Post loads to use this pre-fill option." */ postLoads_loadTemplates_notFound_subtitle = 'postLoads.loadTemplates.notFound.subtitle',
  /** "Some load details cannot be modified when a load is assigned." */ postLoads_loadAssigned = 'postLoads.loadAssigned',
  /** "Carriers" */ carriers_Carriers = 'carriers.Carriers',
  /** "Contact info" */ carriers_contactInfo = 'carriers.contactInfo',
  /** "Onboarded" */ carriers_filters_Onboarded = 'carriers.filters.Onboarded',
  /** "Favorite" */ carriers_filters_Favorite = 'carriers.filters.Favorite',
  /** "Blocked/Hidden" */ carriers_filters_BlockedHidden = 'carriers.filters.BlockedHidden',
  /** "Filter by name" */ carriers_filterLabel = 'carriers.filterLabel',
  /** "No favorites saved" */ carriers_NotFound_favorite_title = 'carriers.NotFound.favorite.title',
  /** "To save a company as your favorite, click “Add to favorites” when viewing a carrier in Truck Locator." */ carriers_NotFound_favorite_content = 'carriers.NotFound.favorite.content',
  /** "No companies hidden" */ carriers_NotFound_blocked_title = 'carriers.NotFound.blocked.title',
  /** "To hide a company, click “Hide” when viewing a carrier in Truck Locator." */ carriers_NotFound_blocked_content = 'carriers.NotFound.blocked.content',
  /** "No onboarded companies" */ carriers_NotFound_onboarded_title = 'carriers.NotFound.onboarded.title',
  /** "To mark a company that you’ve already onboarded with, click “Tag as onboarded” when viewing a carrier in Truck Locator." */ carriers_NotFound_onboarded_content = 'carriers.NotFound.onboarded.content',
  /** "Favorite" */ carriers_menuOptions_favorite = 'carriers.menuOptions.favorite',
  /** "Remove favorite" */ carriers_menuOptions_remove_favorite = 'carriers.menuOptions.remove_favorite',
  /** "Mark as onboarded" */ carriers_menuOptions_mark_onboarded = 'carriers.menuOptions.mark_onboarded',
  /** "Unmark onboarded" */ carriers_menuOptions_unmark_onboarded = 'carriers.menuOptions.unmark_onboarded',
  /** "Remove blocked" */ carriers_menuOptions_remove_blocked = 'carriers.menuOptions.remove_blocked',
  /** "Carrier added to your favorites" */ carriers_carrierUpdate_carrierFavorited = 'carriers.carrierUpdate.carrierFavorited',
  /** "Carrier removed from your favorites" */ carriers_carrierUpdate_carrierUnfavorited = 'carriers.carrierUpdate.carrierUnfavorited',
  /** "Carrier is now blocked" */ carriers_carrierUpdate_carrierBlocked = 'carriers.carrierUpdate.carrierBlocked',
  /** "Carrier is unblocked" */ carriers_carrierUpdate_carrierUnblocked = 'carriers.carrierUpdate.carrierUnblocked',
  /** "Carrier marked as onboarded" */ carriers_carrierUpdate_carrierOnboarded = 'carriers.carrierUpdate.carrierOnboarded',
  /** "Carrier removed from onboarded" */ carriers_carrierUpdate_carrierUnonboarded = 'carriers.carrierUpdate.carrierUnonboarded',
  /** "Companies" */ companies_Companies = 'companies.Companies',
  /** "Contact info" */ companies_contactInfo = 'companies.contactInfo',
  /** "Onboarded" */ companies_filters_Onboarded = 'companies.filters.Onboarded',
  /** "Favorite" */ companies_filters_Favorite = 'companies.filters.Favorite',
  /** "Blocked/Hidden" */ companies_filters_BlockedHidden = 'companies.filters.BlockedHidden',
  /** "Filter by name" */ companies_filterLabel = 'companies.filterLabel',
  /** "No favorites saved" */ companies_NotFound_favorite_title = 'companies.NotFound.favorite.title',
  /** "To save a company as your favorite, click the more option icon when viewing a load." */ companies_NotFound_favorite_content = 'companies.NotFound.favorite.content',
  /** "No companies blocked" */ companies_NotFound_blocked_title = 'companies.NotFound.blocked.title',
  /** "To block a company from your results, click the more option icon when viewing a load." */ companies_NotFound_blocked_content = 'companies.NotFound.blocked.content',
  /** "No onboarded companies" */ companies_NotFound_onboarded_title = 'companies.NotFound.onboarded.title',
  /** "To mark a company that you’ve already onboarded with, click the more option icon when viewing a load." */ companies_NotFound_onboarded_content = 'companies.NotFound.onboarded.content',
  /** "Favorite" */ companies_menuOptions_favorite = 'companies.menuOptions.favorite',
  /** "Remove favorite" */ companies_menuOptions_remove_favorite = 'companies.menuOptions.remove_favorite',
  /** "Mark as onboarded" */ companies_menuOptions_mark_onboarded = 'companies.menuOptions.mark_onboarded',
  /** "Unmark onboarded" */ companies_menuOptions_unmark_onboarded = 'companies.menuOptions.unmark_onboarded',
  /** "Remove blocked" */ companies_menuOptions_remove_blocked = 'companies.menuOptions.remove_blocked',
  /** "Reset" */ modalPanels_Actions_Reset = 'modalPanels.Actions.Reset',
  /** "Clear all" */ modalPanels_Actions_ClearAll = 'modalPanels.Actions.ClearAll',
  /** "Clear" */ modalPanels_Actions_Clear = 'modalPanels.Actions.Clear',
  /** "Pickup dates" */ modalPanels_DatesTime_PickUpDatesHeader = 'modalPanels.DatesTime.PickUpDatesHeader',
  /** "Pickup date" */ modalPanels_DatesTime_PickUpDateHeader = 'modalPanels.DatesTime.PickUpDateHeader',
  /** "Drop off date" */ modalPanels_DatesTime_DropOffDateHeader = 'modalPanels.DatesTime.DropOffDateHeader',
  /** "Drop off dates" */ modalPanels_DatesTime_DropOffDatesHeader = 'modalPanels.DatesTime.DropOffDatesHeader',
  /** "Pickup time" */ modalPanels_DatesTime_PickUpTimeHeader = 'modalPanels.DatesTime.PickUpTimeHeader',
  /** "Drop off time" */ modalPanels_DatesTime_DropOffTimeHeader = 'modalPanels.DatesTime.DropOffTimeHeader',
  /** "You selected the maximum number of dates" */ modalPanels_DatesTime_MaxSelectionDatesCount = 'modalPanels.DatesTime.MaxSelectionDatesCount',
  /** "Select a maximum {{value}}" */ modalPanels_DatesTime_SelectMaximum = 'modalPanels.DatesTime.SelectMaximum',
  /** "Pickup location" */ modalPanels_LocationPicker_PickUpHeader = 'modalPanels.LocationPicker.PickUpHeader',
  /** "Drop off location" */ modalPanels_LocationPicker_DropOffHeader = 'modalPanels.LocationPicker.DropOffHeader',
  /** "Pick up" */ modalPanels_LocationPicker_Pickup = 'modalPanels.LocationPicker.Pickup',
  /** "Drop off" */ modalPanels_LocationPicker_DropOff = 'modalPanels.LocationPicker.DropOff',
  /** "Recent locations" */ modalPanels_LocationPicker_RecentLocations = 'modalPanels.LocationPicker.RecentLocations',
  /** "City, state or zip" */ modalPanels_LocationPicker_CityStateZip = 'modalPanels.LocationPicker.CityStateZip',
  /** "City or zip" */ modalPanels_LocationPicker_CityOrZip = 'modalPanels.LocationPicker.CityOrZip',
  /** "Selected {{selectedCount}} of maximum {{maxCount}} locations" */ modalPanels_LocationPicker_WarningMessage = 'modalPanels.LocationPicker.WarningMessage',
  /** "Sort by" */ modalPanels_SortBy_Header = 'modalPanels.SortBy.Header',
  /** "Status" */ modalPanels_Status_Header = 'modalPanels.Status.Header',
  /** "Select status" */ modalPanels_Status_SelectStatus = 'modalPanels.Status.SelectStatus',
  /** "Online" */ modalPanels_Status_Online = 'modalPanels.Status.Online',
  /** "Offline" */ modalPanels_Status_Offline = 'modalPanels.Status.Offline',
  /** "Trailer type" */ modalPanels_TrailerType_Header = 'modalPanels.TrailerType.Header',
  /** "Trailer specifications" */ modalPanels_TrailerSpecifications_Header = 'modalPanels.TrailerSpecifications.Header',
  /** "Time" */ modalPanels_TimePicker_Label = 'modalPanels.TimePicker.Label',
  /** "Invalid time format" */ modalPanels_TimePicker_Error = 'modalPanels.TimePicker.Error',
  /** "Filters" */ modalPanels_Filter_Filters = 'modalPanels.Filter.Filters',
  /** "Location permission unavailable" */ permissions_location_unavailable_title = 'permissions.location.unavailable.title',
  /** "It's not possible to determine your location at this time." */ permissions_location_unavailable_content = 'permissions.location.unavailable.content',
  /** "Location permission denied" */ permissions_location_denied_title = 'permissions.location.denied.title',
  /** "Please enable location permissions in your browser's preferences." */ permissions_location_denied_content = 'permissions.location.denied.content',
  /** "Location permission timed out" */ permissions_location_timeout_title = 'permissions.location.timeout.title',
  /** "Timeout while getting location." */ permissions_location_timeout_content = 'permissions.location.timeout.content',
  /** "Hi {{name}}, enjoy our carrier dashboard." */ dashboard_CarrierHeader = 'dashboard.CarrierHeader',
  /** "Hi {{name}}, let's move some loads today." */ dashboard_BrokerHeader = 'dashboard.BrokerHeader',
  /** "My Posted Loads" */ dashboard_My_Posted_Loads = 'dashboard.My_Posted_Loads',
  /** "Find Loads" */ dashboard_Find_Loads = 'dashboard.Find_Loads',
  /** "My Loads Post" */ dashboard_My_Loads_Post = 'dashboard.My_Loads_Post',
  /** "Mileage Calculator" */ dashboard_Mileage_Calculator = 'dashboard.Mileage_Calculator',
  /** "Profit Calculator" */ dashboard_Profit_Calculator = 'dashboard.Profit_Calculator',
  /** "Save at the Pump" */ dashboard_Save_At_The_Pump = 'dashboard.Save_At_The_Pump',
  /** "Find Trucks" */ dashboard_Find_Trucks = 'dashboard.Find_Trucks',
  /** "My Documents" */ dashboard_My_Documents = 'dashboard.My_Documents',
  /** "Get Paid Faster" */ dashboard_Get_Paid_Faster = 'dashboard.Get_Paid_Faster',
  /** "Carriers" */ dashboard_Carriers = 'dashboard.Carriers',
  /** "Companies" */ dashboard_Companies = 'dashboard.Companies',
  /** "Load details" */ dashboard_Load_Details = 'dashboard.Load_Details',
  /** "Search alerts" */ dashboard_Search_Alerts = 'dashboard.Search_Alerts',
  /** "Search loads" */ dashboard_Search_Loads = 'dashboard.Search_Loads',
  /** "Load alerts" */ dashboard_Load_Alerts = 'dashboard.Load_Alerts',
  /** "Messages" */ dashboard_Messages = 'dashboard.Messages',
  /** "Posted trucks" */ dashboard_Posted_Truck = 'dashboard.Posted_Truck',
  /** "Online loads" */ dashboard_Online_Loads = 'dashboard.Online_Loads',
  /** "Offline loads" */ dashboard_Offline_Loads = 'dashboard.Offline_Loads',
  /** "Live" */ dashboard_Live = 'dashboard.Live',
  /** "Search" */ dashboard_Search = 'dashboard.Search',
  /** "Post load" */ dashboard_PostLoad = 'dashboard.PostLoad',
  /** "View" */ dashboard_View = 'dashboard.View',
  /** "View all" */ dashboard_ViewAll = 'dashboard.ViewAll',
  /** "Working load" */ dashboard_workingLoadWidget_header = 'dashboard.workingLoadWidget.header',
  /** "Load status:" */ dashboard_workingLoadWidget_loadStatus = 'dashboard.workingLoadWidget.loadStatus',
  /** "Load documents:" */ dashboard_workingLoadWidget_loadDocuments = 'dashboard.workingLoadWidget.loadDocuments',
  /** "Book a load" */ dashboard_workingLoadWidget_notFound_title = 'dashboard.workingLoadWidget.notFound.title',
  /** "To see results, find a load and mark it as \"booked\"." */ dashboard_workingLoadWidget_notFound_subtitle = 'dashboard.workingLoadWidget.notFound.subtitle',
  /** "Recent searches" */ dashboard_recentSearchesWidget_header = 'dashboard.recentSearchesWidget.header',
  /** "Load searches" */ dashboard_recentSearchesWidget_notFound_title = 'dashboard.recentSearchesWidget.notFound.title',
  /** "To see results, start searching for loads." */ dashboard_recentSearchesWidget_notFound_subtitle = 'dashboard.recentSearchesWidget.notFound.subtitle',
  /** "My loads" */ dashboard_myLoadsWidget_header = 'dashboard.myLoadsWidget.header',
  /** "Total loads" */ dashboard_myLoadsWidget_totalLoadsButtonLabel = 'dashboard.myLoadsWidget.totalLoadsButtonLabel',
  /** "Contacted" */ dashboard_myLoadsWidget_contactedLoadsButtonLabel = 'dashboard.myLoadsWidget.contactedLoadsButtonLabel',
  /** "Saved" */ dashboard_myLoadsWidget_savedLoadsButtonLabel = 'dashboard.myLoadsWidget.savedLoadsButtonLabel',
  /** "Booked" */ dashboard_myLoadsWidget_bookedLoadsButtonLabel = 'dashboard.myLoadsWidget.bookedLoadsButtonLabel',
  /** "Picked up" */ dashboard_myLoadsWidget_pickedUpLoadsButtonLabel = 'dashboard.myLoadsWidget.pickedUpLoadsButtonLabel',
  /** "Delivered" */ dashboard_myLoadsWidget_deliveredLoadsButtonLabel = 'dashboard.myLoadsWidget.deliveredLoadsButtonLabel',
  /** "Last posted load" */ dashboard_lastPostedLoadWidget_header = 'dashboard.lastPostedLoadWidget.header',
  /** "Find capacity for this load" */ dashboard_lastPostedLoadWidget_findCapacity = 'dashboard.lastPostedLoadWidget.findCapacity',
  /** "Post strength:" */ dashboard_lastPostedLoadWidget_postStrength = 'dashboard.lastPostedLoadWidget.postStrength',
  /** "No load posted" */ dashboard_lastPostedLoadWidget_notFound_title = 'dashboard.lastPostedLoadWidget.notFound.title',
  /** "To see results, post a load." */ dashboard_lastPostedLoadWidget_notFound_subtitle = 'dashboard.lastPostedLoadWidget.notFound.subtitle',
  /** "Recently posted" */ dashboard_recentlyPostedWidget_header = 'dashboard.recentlyPostedWidget.header',
  /** "No load posts" */ dashboard_recentlyPostedWidget_notFound_title = 'dashboard.recentlyPostedWidget.notFound.title',
  /** "To see results, start posting loads." */ dashboard_recentlyPostedWidget_notFound_subtitle = 'dashboard.recentlyPostedWidget.notFound.subtitle',
  /** "Hi {{name}}, the highway is calling." */ dashboard_userMessages_message_1 = 'dashboard.userMessages.message_1',
  /** "Hi {{name}}, let's fill that truck." */ dashboard_userMessages_message_2 = 'dashboard.userMessages.message_2',
  /** "Hi {{name}}, mo' miles mo' money." */ dashboard_userMessages_message_3 = 'dashboard.userMessages.message_3',
  /** "Hi {{name}}, get your load on!" */ dashboard_userMessages_message_4 = 'dashboard.userMessages.message_4',
  /** "Hi {{name}}, no highway is too long." */ dashboard_userMessages_message_5 = 'dashboard.userMessages.message_5',
  /** "Hi {{name}}, drive like no one is watching." */ dashboard_userMessages_message_6 = 'dashboard.userMessages.message_6',
  /** "Hi {{name}}, these loads aren't gonna haul themselves." */ dashboard_userMessages_message_7 = 'dashboard.userMessages.message_7',
  /** "Hi {{name}}, let's get that truck on the road!" */ dashboard_userMessages_message_8 = 'dashboard.userMessages.message_8',
  /** "Hi {{name}}, up and haul’em!" */ dashboard_userMessages_message_9 = 'dashboard.userMessages.message_9',
  /** "Hi {{name}}, let's get rolling." */ dashboard_userMessages_message_10 = 'dashboard.userMessages.message_10',
  /** "Hi {{name}}, run a search, load up, hit the gas." */ dashboard_userMessages_message_11 = 'dashboard.userMessages.message_11',
  /** "Hi {{name}}, let's get those wheels on the ground." */ dashboard_userMessages_message_12 = 'dashboard.userMessages.message_12',
  /** "Hi {{name}}, let's get trucked up!" */ dashboard_userMessages_message_13 = 'dashboard.userMessages.message_13',
  /** "Hi {{name}}, one of these loads will be right up your alley." */ dashboard_userMessages_message_14 = 'dashboard.userMessages.message_14',
  /** "Hi {{name}}, we’ve got loads for you." */ dashboard_userMessages_message_15 = 'dashboard.userMessages.message_15',
  /** "Loads posted per month" */ dashboard_metricsWidget_loadsPostedPerMonth = 'dashboard.metricsWidget.loadsPostedPerMonth',
  /** "Loads delivered per month" */ dashboard_metricsWidget_loadsDeliveredPerMonth = 'dashboard.metricsWidget.loadsDeliveredPerMonth',
  /** "Most posted on" */ dashboard_metricsWidget_mostPostedOn = 'dashboard.metricsWidget.mostPostedOn',
  /** "Most booked on" */ dashboard_metricsWidget_mostBookedOn = 'dashboard.metricsWidget.mostBookedOn',
  /** "Busiest day" */ dashboard_metricsWidget_busiestDay = 'dashboard.metricsWidget.busiestDay',
  /** "Loads posted in {{month}}" */ dashboard_metricsWidget_legendLoadsPostedText = 'dashboard.metricsWidget.legendLoadsPostedText',
  /** "Loads delivered in {{month}}" */ dashboard_metricsWidget_legendLoadsDeliveredText = 'dashboard.metricsWidget.legendLoadsDeliveredText',
  /** "Monthly loads" */ dashboard_metricsWidget_monthlyLoads = 'dashboard.metricsWidget.monthlyLoads',
  /** "Export Posts" */ exportPosts_Export_Posts = 'exportPosts.Export_Posts',
  /** "We offer various methods to export your loads to other load boards, carriers, etc. Please refer to the options below. Need assistance?" */ exportPosts_Description = 'exportPosts.Description',
  /** "Contact 877-875-5301" */ exportPosts_Contact = 'exportPosts.Contact',
  /** "ext. 2" */ exportPosts_Extension = 'exportPosts.Extension',
  /** "Export your loads by FTP" */ exportPosts_exportByFtp_Title = 'exportPosts.exportByFtp.Title',
  /** "Our system can export your loads to any FTP address you specify. Whenever you post, edit or delete a load, our system will send an update to the FTP addresses listed below." */ exportPosts_exportByFtp_Content = 'exportPosts.exportByFtp.Content',
  /** "FTP connected successfully" */ exportPosts_exportByFtp_Successful_Save = 'exportPosts.exportByFtp.Successful_Save',
  /** "FTP deleted successfully" */ exportPosts_exportByFtp_Successful_Delete = 'exportPosts.exportByFtp.Successful_Delete',
  /** "Something went wrong. Please verify the information entered and try again." */ exportPosts_exportByFtp_Save_Error = 'exportPosts.exportByFtp.Save_Error',
  /** "is required" */ exportPosts_exportByFtp_Is_Required = 'exportPosts.exportByFtp.Is_Required',
  /** "All unsaved content will be lost" */ exportPosts_exportByFtp_Close_Ftp_Dialog_Title = 'exportPosts.exportByFtp.Close_Ftp_Dialog_Title',
  /** "If you leave the current form, you will lose all newly entered information." */ exportPosts_exportByFtp_Close_Ftp_Dialog_Message = 'exportPosts.exportByFtp.Close_Ftp_Dialog_Message',
  /** "Unchanged" */ exportPosts_exportByFtp_Unchanged_Password_Placeholder = 'exportPosts.exportByFtp.Unchanged_Password_Placeholder',
  /** "FTP Name (Description)" */ exportPosts_exportByFtp_ftp_Ftp_Name = 'exportPosts.exportByFtp.ftp.Ftp_Name',
  /** "FTP Name" */ exportPosts_exportByFtp_ftp_Condensed_Ftp_Name = 'exportPosts.exportByFtp.ftp.Condensed_Ftp_Name',
  /** "Last Transmission" */ exportPosts_exportByFtp_ftp_Last_Transmission = 'exportPosts.exportByFtp.ftp.Last_Transmission',
  /** "Connection" */ exportPosts_exportByFtp_ftp_Connection = 'exportPosts.exportByFtp.ftp.Connection',
  /** "Valid" */ exportPosts_exportByFtp_ftp_Valid = 'exportPosts.exportByFtp.ftp.Valid',
  /** "Invalid" */ exportPosts_exportByFtp_ftp_Invalid = 'exportPosts.exportByFtp.ftp.Invalid',
  /** "Protocol" */ exportPosts_exportByFtp_ftp_Protocol = 'exportPosts.exportByFtp.ftp.Protocol',
  /** "Encryption" */ exportPosts_exportByFtp_ftp_Encryption = 'exportPosts.exportByFtp.ftp.Encryption',
  /** "Remote Directory" */ exportPosts_exportByFtp_ftp_Remote_Directory = 'exportPosts.exportByFtp.ftp.Remote_Directory',
  /** "Host" */ exportPosts_exportByFtp_ftp_Host = 'exportPosts.exportByFtp.ftp.Host',
  /** "Port" */ exportPosts_exportByFtp_ftp_Port = 'exportPosts.exportByFtp.ftp.Port',
  /** "Username" */ exportPosts_exportByFtp_ftp_Username = 'exportPosts.exportByFtp.ftp.Username',
  /** "Password" */ exportPosts_exportByFtp_ftp_Password = 'exportPosts.exportByFtp.ftp.Password',
  /** "Enable daily full list transmission per setup" */ exportPosts_exportByFtp_ftp_Enable_Daily_Transmission = 'exportPosts.exportByFtp.ftp.Enable_Daily_Transmission',
  /** "Required fields" */ exportPosts_exportByFtp_ftp_Required_Fields = 'exportPosts.exportByFtp.ftp.Required_Fields',
  /** "Delete" */ exportPosts_exportByFtp_ftp_Delete = 'exportPosts.exportByFtp.ftp.Delete',
  /** "Cancel" */ exportPosts_exportByFtp_ftp_Cancel = 'exportPosts.exportByFtp.ftp.Cancel',
  /** "Ok" */ exportPosts_exportByFtp_ftp_Ok = 'exportPosts.exportByFtp.ftp.Ok',
  /** "FTP - File Transfer Protocol" */ exportPosts_exportByFtp_ftp_protocol_Ftp = 'exportPosts.exportByFtp.ftp.protocol.Ftp',
  /** "Only use plain FTP" */ exportPosts_exportByFtp_ftp_encryption_Plain_Ftp = 'exportPosts.exportByFtp.ftp.encryption.Plain_Ftp',
  /** "Require implicit FTP over TLS" */ exportPosts_exportByFtp_ftp_encryption_Implicit_Ftp_Over_Tls = 'exportPosts.exportByFtp.ftp.encryption.Implicit_Ftp_Over_Tls',
  /** "Require explicit FTP over TLS" */ exportPosts_exportByFtp_ftp_encryption_Explicit_Ftp_Over_Tls = 'exportPosts.exportByFtp.ftp.encryption.Explicit_Ftp_Over_Tls',
  /** "Use explicit FTP over TLS is available" */ exportPosts_exportByFtp_ftp_encryption_Explicit_Ftp_Over_Tls_If_Available = 'exportPosts.exportByFtp.ftp.encryption.Explicit_Ftp_Over_Tls_If_Available',
  /** "Export to CSV File" */ exportPosts_exportToCSV_Title = 'exportPosts.exportToCSV.Title',
  /** "You can export all your available loads as a CSV file to easily send them by email, fax, etc." */ exportPosts_exportToCSV_Content = 'exportPosts.exportToCSV.Content',
  /** "CSV File" */ exportPosts_exportToCSV_Button = 'exportPosts.exportToCSV.Button',
  /** "URL Scrape" */ exportPosts_urlScrape_Title = 'exportPosts.urlScrape.Title',
  /** "Other load boards can post your available loads by scraping a specific URL that we created for your company. Copy the URL and request that they scrape the website every couple of minutes to have your loads posted." */ exportPosts_urlScrape_Content = 'exportPosts.urlScrape.Content',
  /** "Copy URL" */ exportPosts_urlScrape_Button = 'exportPosts.urlScrape.Button',
  /** "URL copied to clipboard." */ exportPosts_urlScrape_SnackBar_Message = 'exportPosts.urlScrape.SnackBar_Message',
  /** "Other load boards will be able to automatically post your loads by scraping the following URL(s) that contains your available loads. Copy and send them the following URL to scrape every couple of minutes and your loads will automatically be posted." */ exportPosts_sharePostedLoads_title = 'exportPosts.sharePostedLoads.title',
  /** "Share posted loads" */ exportPosts_sharePostedLoads_header = 'exportPosts.sharePostedLoads.header',
  /** "Export posts results" */ exportPosts_sharePostedLoads_exportPostsResults = 'exportPosts.sharePostedLoads.exportPostsResults',
  /** "Export Posts Table View" */ exportPosts_sharePostedLoads_exportPostsTableView = 'exportPosts.sharePostedLoads.exportPostsTableView',
  /** "Share posted loads (legacy)" */ exportPosts_sharePostedLoads_legacyHeader = 'exportPosts.sharePostedLoads.legacyHeader',
  /** "Paused" */ exportPosts_sharePostedLoads_pausedStatus = 'exportPosts.sharePostedLoads.pausedStatus',
  /** "Unlimited" */ exportPosts_sharePostedLoads_expirationUnlimited = 'exportPosts.sharePostedLoads.expirationUnlimited',
  /** "All company" */ exportPosts_sharePostedLoads_allCompany = 'exportPosts.sharePostedLoads.allCompany',
  /** "URL" */ exportPosts_sharePostedLoads_URL = 'exportPosts.sharePostedLoads.URL',
  /** "Edit URL" */ exportPosts_sharePostedLoads_editURL = 'exportPosts.sharePostedLoads.editURL',
  /** "New URL" */ exportPosts_sharePostedLoads_newURL = 'exportPosts.sharePostedLoads.newURL',
  /** "Enable “URL expiration date”" */ exportPosts_sharePostedLoads_enableUrlExpirationDate = 'exportPosts.sharePostedLoads.enableUrlExpirationDate',
  /** "Listed loads" */ exportPosts_sharePostedLoads_listedLoads = 'exportPosts.sharePostedLoads.listedLoads',
  /** "Pause this URL scrape. Anyone you have shared this URL with will no longer have access until it is unpaused." */ exportPosts_sharePostedLoads_pauseUrlScrapeTitle = 'exportPosts.sharePostedLoads.pauseUrlScrapeTitle',
  /** "URL expiration date" */ exportPosts_sharePostedLoads_urlExpirationDate = 'exportPosts.sharePostedLoads.urlExpirationDate',
  /** "URL added" */ exportPosts_sharePostedLoads_urlAdded = 'exportPosts.sharePostedLoads.urlAdded',
  /** "URL edited" */ exportPosts_sharePostedLoads_urlEdited = 'exportPosts.sharePostedLoads.urlEdited',
  /** "URL deleted" */ exportPosts_sharePostedLoads_urlDeleted = 'exportPosts.sharePostedLoads.urlDeleted',
  /** "Delete URL" */ exportPosts_sharePostedLoads_deletedURLPopup_title = 'exportPosts.sharePostedLoads.deletedURLPopup.title',
  /** "Anyone you have shared this URL with will no longer have access." */ exportPosts_sharePostedLoads_deletedURLPopup_warning = 'exportPosts.sharePostedLoads.deletedURLPopup.warning',
  /** "Are you sure that you want to delete this link?" */ exportPosts_sharePostedLoads_deletedURLPopup_deleteQuestion = 'exportPosts.sharePostedLoads.deletedURLPopup.deleteQuestion',
  /** "URLs limit reached" */ exportPosts_sharePostedLoads_limitReachedPopup_title = 'exportPosts.sharePostedLoads.limitReachedPopup.title',
  /** "There is a limit of 20 URLs." */ exportPosts_sharePostedLoads_limitReachedPopup_limit = 'exportPosts.sharePostedLoads.limitReachedPopup.limit',
  /** "Please delete any previous URLs before adding a new one." */ exportPosts_sharePostedLoads_limitReachedPopup_suggestion = 'exportPosts.sharePostedLoads.limitReachedPopup.suggestion',
  /** "The date has passed. Please enter a valid date" */ exportPosts_sharePostedLoads_datePickerWarnings_datePassed = 'exportPosts.sharePostedLoads.datePickerWarnings.datePassed',
  /** "Date must be included" */ exportPosts_sharePostedLoads_datePickerWarnings_dateMustBeIncluded = 'exportPosts.sharePostedLoads.datePickerWarnings.dateMustBeIncluded',
  /** "Year must be 4 digits (ie. 2000)" */ exportPosts_sharePostedLoads_datePickerWarnings_invalidYear = 'exportPosts.sharePostedLoads.datePickerWarnings.invalidYear',
  /** "Date does not exist. Please enter a valid date." */ exportPosts_sharePostedLoads_datePickerWarnings_dateMustExist = 'exportPosts.sharePostedLoads.datePickerWarnings.dateMustExist',
  /** "Please enter a valid date" */ exportPosts_sharePostedLoads_datePickerWarnings_enterValidDate = 'exportPosts.sharePostedLoads.datePickerWarnings.enterValidDate',
  /** "Loading..." */ exportPosts_sharePostedLoads_loadsTable_loading = 'exportPosts.sharePostedLoads.loadsTable.loading',
  /** "Load data for this URL cannot be accessed at this time." */ exportPosts_sharePostedLoads_loadsTable_notFound = 'exportPosts.sharePostedLoads.loadsTable.notFound',
  /** "Copyright © 123Loadboard. All rights reserved. Any unauthorized copying, publication or reproduction of the\n        content of this website is strictly prohibited and constitutes an infringement of copyright. If you would like\n        permission to use this content, please contact 123Loadboard at 8778755301, ext. 2." */ exportPosts_sharePostedLoads_loadsTable_copyright = 'exportPosts.sharePostedLoads.loadsTable.copyright',
  /** "Pickup date" */ exportPosts_sharePostedLoads_loadsTable_header_pickupDate = 'exportPosts.sharePostedLoads.loadsTable.header.pickupDate',
  /** "Origin city" */ exportPosts_sharePostedLoads_loadsTable_header_originCity = 'exportPosts.sharePostedLoads.loadsTable.header.originCity',
  /** "Ori. state" */ exportPosts_sharePostedLoads_loadsTable_header_originState = 'exportPosts.sharePostedLoads.loadsTable.header.originState',
  /** "Ori. zip code" */ exportPosts_sharePostedLoads_loadsTable_header_originZip = 'exportPosts.sharePostedLoads.loadsTable.header.originZip',
  /** "Pickup time" */ exportPosts_sharePostedLoads_loadsTable_header_pickupTime = 'exportPosts.sharePostedLoads.loadsTable.header.pickupTime',
  /** "Destination city" */ exportPosts_sharePostedLoads_loadsTable_header_destinationCity = 'exportPosts.sharePostedLoads.loadsTable.header.destinationCity',
  /** "Dest. state" */ exportPosts_sharePostedLoads_loadsTable_header_destinationState = 'exportPosts.sharePostedLoads.loadsTable.header.destinationState',
  /** "Dest. zip code" */ exportPosts_sharePostedLoads_loadsTable_header_destinationZip = 'exportPosts.sharePostedLoads.loadsTable.header.destinationZip',
  /** "Delivery date" */ exportPosts_sharePostedLoads_loadsTable_header_deliveryDate = 'exportPosts.sharePostedLoads.loadsTable.header.deliveryDate',
  /** "Delivery time" */ exportPosts_sharePostedLoads_loadsTable_header_deliveryTime = 'exportPosts.sharePostedLoads.loadsTable.header.deliveryTime',
  /** "Approx. miles" */ exportPosts_sharePostedLoads_loadsTable_header_approximateMiles = 'exportPosts.sharePostedLoads.loadsTable.header.approximateMiles',
  /** "Equip." */ exportPosts_sharePostedLoads_loadsTable_header_equipment = 'exportPosts.sharePostedLoads.loadsTable.header.equipment',
  /** "Equip. info" */ exportPosts_sharePostedLoads_loadsTable_header_equipmentInfo = 'exportPosts.sharePostedLoads.loadsTable.header.equipmentInfo',
  /** "Size" */ exportPosts_sharePostedLoads_loadsTable_header_size = 'exportPosts.sharePostedLoads.loadsTable.header.size',
  /** "Length (ft)" */ exportPosts_sharePostedLoads_loadsTable_header_length = 'exportPosts.sharePostedLoads.loadsTable.header.length',
  /** "Weight (lbs)" */ exportPosts_sharePostedLoads_loadsTable_header_weight = 'exportPosts.sharePostedLoads.loadsTable.header.weight',
  /** "Qty." */ exportPosts_sharePostedLoads_loadsTable_header_quantity = 'exportPosts.sharePostedLoads.loadsTable.header.quantity',
  /** "Stops" */ exportPosts_sharePostedLoads_loadsTable_header_stops = 'exportPosts.sharePostedLoads.loadsTable.header.stops',
  /** "Team load" */ exportPosts_sharePostedLoads_loadsTable_header_teamLoad = 'exportPosts.sharePostedLoads.loadsTable.header.teamLoad',
  /** "Commodity" */ exportPosts_sharePostedLoads_loadsTable_header_commodity = 'exportPosts.sharePostedLoads.loadsTable.header.commodity',
  /** "Notes" */ exportPosts_sharePostedLoads_loadsTable_header_notes = 'exportPosts.sharePostedLoads.loadsTable.header.notes',
  /** "Rate ($)" */ exportPosts_sharePostedLoads_loadsTable_header_rate = 'exportPosts.sharePostedLoads.loadsTable.header.rate',
  /** "Contact" */ exportPosts_sharePostedLoads_loadsTable_header_contact = 'exportPosts.sharePostedLoads.loadsTable.header.contact',
  /** "Contact phone" */ exportPosts_sharePostedLoads_loadsTable_header_contactPhone = 'exportPosts.sharePostedLoads.loadsTable.header.contactPhone',
  /** "Ext." */ exportPosts_sharePostedLoads_loadsTable_header_ext = 'exportPosts.sharePostedLoads.loadsTable.header.ext',
  /** "Contact email" */ exportPosts_sharePostedLoads_loadsTable_header_contactEmail = 'exportPosts.sharePostedLoads.loadsTable.header.contactEmail',
  /** "Post ref." */ exportPosts_sharePostedLoads_loadsTable_header_postRef = 'exportPosts.sharePostedLoads.loadsTable.header.postRef',
  /** "Pick up date" */ loadGeneric_pickupDate = 'loadGeneric.pickupDate',
  /** "Distance" */ loadGeneric_distance = 'loadGeneric.distance',
  /** "deadhead" */ loadGeneric_deadhead = 'loadGeneric.deadhead',
  /** "Equipment" */ loadGeneric_equipment = 'loadGeneric.equipment',
  /** "Load size" */ loadGeneric_loadSize = 'loadGeneric.loadSize',
  /** "Posted rate" */ loadGeneric_postedRate = 'loadGeneric.postedRate',
  /** "Backhauls" */ loadGeneric_backhauls = 'loadGeneric.backhauls',
  /** "Rate check" */ loadGeneric_rateCheck = 'loadGeneric.rateCheck',
  /** "Length" */ loadGeneric_length = 'loadGeneric.length',
  /** "Weight" */ loadGeneric_weight = 'loadGeneric.weight',
  /** "Ref. #" */ loadGeneric_refNumber = 'loadGeneric.refNumber',
  /** "Item" */ loadGeneric_item = 'loadGeneric.item',
  /** "Qty/Stops" */ loadGeneric_qtyStops = 'loadGeneric.qtyStops',
  /** "Drop off date" */ loadGeneric_dropOffDate = 'loadGeneric.dropOffDate',
  /** "More details" */ loadGeneric_moreInfo = 'loadGeneric.moreInfo',
  /** "Posted age" */ loadGeneric_postedAge = 'loadGeneric.postedAge',
  /** "Company" */ loadGeneric_company = 'loadGeneric.company',
  /** "avg. rate" */ loadGeneric_avgRate = 'loadGeneric.avgRate',
  /** "Average rate" */ loadGeneric_averageRate = 'loadGeneric.averageRate',
  /** "Anywhere" */ loadGeneric_anywhere = 'loadGeneric.anywhere',
  /** "USA" */ loadGeneric_anywhereUsa = 'loadGeneric.anywhereUsa',
  /** "Canada" */ loadGeneric_anywhereCanada = 'loadGeneric.anywhereCanada',
  /** "Current location" */ loadGeneric_currentLocation = 'loadGeneric.currentLocation',
  /** "Current location unknown" */ loadGeneric_currentLocationUnknown = 'loadGeneric.currentLocationUnknown',
  /** "Sort by" */ loadSorting_title = 'loadSorting.title',
  /** "Shortest deadhead" */ loadSorting_deadhead = 'loadSorting.deadhead',
  /** "Pickup date" */ loadSorting_pickupDate = 'loadSorting.pickupDate',
  /** "Posted age" */ loadSorting_age = 'loadSorting.age',
  /** "Shortest distance" */ loadSorting_shortestDistance = 'loadSorting.shortestDistance',
  /** "Longest distance" */ loadSorting_longestDistance = 'loadSorting.longestDistance',
  /** "Highest posted rate" */ loadSorting_postedRate = 'loadSorting.postedRate',
  /** "Rate per mile" */ loadSorting_ratePerMile = 'loadSorting.ratePerMile',
  /** "Upload Posts" */ uploadPosts_Upload_Posts = 'uploadPosts.Upload_Posts',
  /** "Instructions" */ uploadPosts_Instructions = 'uploadPosts.Instructions',
  /** "Drag & Drop your file here" */ uploadPosts_Drop_Area_Label = 'uploadPosts.Drop_Area_Label',
  /** "Select file" */ uploadPosts_Dropbox_Btn_Label = 'uploadPosts.Dropbox_Btn_Label',
  /** "ext. 2" */ uploadPosts_Extension = 'uploadPosts.Extension',
  /** "Upload file" */ uploadPosts_uploadCSV_Title = 'uploadPosts.uploadCSV.Title',
  /** "Send us your Excel or CSV file to easily post your loads. Please ensure that your file meets our file guidelines above." */ uploadPosts_uploadCSV_Content = 'uploadPosts.uploadCSV.Content',
  /** "File uploaded successfully" */ uploadPosts_uploadCSV_Feedback = 'uploadPosts.uploadCSV.Feedback',
  /** "File uploaded successfully.\nIt will be processed as soon as our processor is updated to parse its format." */ uploadPosts_uploadCSV_Feedback_New_Upload = 'uploadPosts.uploadCSV.Feedback_New_Upload',
  /** "Something went wrong. Please verify that the file meets our file guidelines (see above) and try again." */ uploadPosts_uploadCSV_Error = 'uploadPosts.uploadCSV.Error',
  /** "File uploaded successfully" */ uploadPosts_newUploadCsv_Title = 'uploadPosts.newUploadCsv.Title',
  /** "It seems that you are new to the Upload Posts tool.\n\nWe will set up your account and contact you when it's ready. Once that's done, you'll be able to upload files and our system will automatically read and post your available loads." */ uploadPosts_newUploadCsv_Message = 'uploadPosts.newUploadCsv.Message',
  /** "For more information, contact " */ uploadPosts_newUploadCsv_contact = 'uploadPosts.newUploadCsv.contact',
  /** "Send Loads by FTP" */ uploadPosts_sendByFTP_Title = 'uploadPosts.sendByFTP.Title',
  /** "If you would like to send your loads via FTP, you can request FTP login details using the button below." */ uploadPosts_sendByFTP_Content = 'uploadPosts.sendByFTP.Content',
  /** "request FTP access" */ uploadPosts_sendByFTP_Button = 'uploadPosts.sendByFTP.Button',
  /** "FTP request submitted successfully" */ uploadPosts_sendByFTP_Feedback = 'uploadPosts.sendByFTP.Feedback',
  /** "Scrape Website" */ uploadPosts_scrapeWebsite_Title = 'uploadPosts.scrapeWebsite.Title',
  /** "If you have available loads listed on your company website, we can scrape these loads every few minutes so they appear in your account on 123Loadboard. If interested, let us know the website URL where we may find your available loads." */ uploadPosts_scrapeWebsite_Content = 'uploadPosts.scrapeWebsite.Content',
  /** "URL" */ uploadPosts_scrapeWebsite_Textfield_Label = 'uploadPosts.scrapeWebsite.Textfield_Label',
  /** "Submit" */ uploadPosts_scrapeWebsite_Button = 'uploadPosts.scrapeWebsite.Button',
  /** "Invalid URL submitted" */ uploadPosts_scrapeWebsite_ErrorSnackbar = 'uploadPosts.scrapeWebsite.ErrorSnackbar',
  /** "Invalid input. Make sure the URL is correct" */ uploadPosts_scrapeWebsite_ErrorMsg = 'uploadPosts.scrapeWebsite.ErrorMsg',
  /** "Please enter a URL" */ uploadPosts_scrapeWebsite_EmptyMsg = 'uploadPosts.scrapeWebsite.EmptyMsg',
  /** "URL submitted successfully" */ uploadPosts_scrapeWebsite_Feedback = 'uploadPosts.scrapeWebsite.Feedback',
  /** "TMS Integration" */ tmsIntegration_TMS_Integration = 'tmsIntegration.TMS_Integration',
  /** "For your convenience, 123Loadboard is integrated with over 30 Transportation Management Softwares (TMS) that can automatically send your load postings to us." */ tmsIntegration_Description = 'tmsIntegration.Description',
  /** "Integrate with your company’s TMS" */ tmsIntegration_Subtitle = 'tmsIntegration.Subtitle',
  /** "Let our qualified staff integrate with your company’s TMS so that your load postings appear on our website in real-time at absolutely no charge." */ tmsIntegration_Content = 'tmsIntegration.Content',
  /** "Transportation Management Software (TMS)" */ tmsIntegration_Textfield = 'tmsIntegration.Textfield',
  /** "Here’s a small sample of the TMS companies we are integrated with. Don’t see your TMS listed," */ tmsIntegration_Company_List_Description = 'tmsIntegration.Company_List_Description',
  /** "contact" */ tmsIntegration_Contact = 'tmsIntegration.Contact',
  /** "ext. 2" */ tmsIntegration_Contact_Extension = 'tmsIntegration.Contact_Extension',
  /** "Invalid format. The TMS should be at least 2 characters long." */ tmsIntegration_Invalid_Input_Message = 'tmsIntegration.Invalid_Input_Message',
  /** "TMS successfully submitted" */ tmsIntegration_snackbar_Successful_Message = 'tmsIntegration.snackbar.Successful_Message',
  /** "Dismiss" */ tmsIntegration_snackbar_Dismiss = 'tmsIntegration.snackbar.Dismiss',
  /** "HTML Embed Code" */ htmlEmbed_HtmlEmbed = 'htmlEmbed.HtmlEmbed',
  /** "Display your posted loads on your own company website in real-time. Copy and paste the HTML Code below into your website. Need assistance?" */ htmlEmbed_Description = 'htmlEmbed.Description',
  /** "Contact 877-875-5301" */ htmlEmbed_Contact = 'htmlEmbed.Contact',
  /** "HTML code" */ htmlEmbed_Textfield_Label = 'htmlEmbed.Textfield_Label',
  /** "Display your loads on your website" */ htmlEmbed_Paper_Title = 'htmlEmbed.Paper_Title',
  /** "Copy and paste the following HTML code into your own company website. " */ htmlEmbed_Textfield_Description = 'htmlEmbed.Textfield_Description',
  /** "3 versions to choose from. You can edit the code to control the background color, width and height." */ htmlEmbed_Version_Description = 'htmlEmbed.Version_Description',
  /** "copy html code" */ htmlEmbed_Copy_HTML = 'htmlEmbed.Copy_HTML',
  /** "Version 1 (default)" */ htmlEmbed_Version_1 = 'htmlEmbed.Version_1',
  /** "Version 2" */ htmlEmbed_Version_2 = 'htmlEmbed.Version_2',
  /** "Version 3" */ htmlEmbed_Version_3 = 'htmlEmbed.Version_3',
  /** "HTML code copied to clipboard." */ htmlEmbed_Copy_Message = 'htmlEmbed.Copy_Message',
  /** "Load Availability" */ aspxEmbed_loadAvailability_title = 'aspxEmbed.loadAvailability.title',
  /** "Alerts" */ aspxEmbed_alerts_title = 'aspxEmbed.alerts.title',
  /** "Messages" */ aspxEmbed_messages_title = 'aspxEmbed.messages.title',
  /** "Load Planner" */ aspxEmbed_loadPlanner_title = 'aspxEmbed.loadPlanner.title',
  /** "My Loads" */ aspxEmbed_myLoads_title = 'aspxEmbed.myLoads.title',
  /** "Post" */ aspxEmbed_myLoadsPost_title = 'aspxEmbed.myLoadsPost.title',
  /** "Companies" */ aspxEmbed_myCompanies_title = 'aspxEmbed.myCompanies.title',
  /** "Mileage Calculator" */ aspxEmbed_mileageCalculator_title = 'aspxEmbed.mileageCalculator.title',
  /** "My Documents" */ aspxEmbed_myDocs_title = 'aspxEmbed.myDocs.title',
  /** "Credit Report" */ aspxEmbed_creditReport_title = 'aspxEmbed.creditReport.title',
  /** "Search Directory" */ aspxEmbed_searchDirectory_title = 'aspxEmbed.searchDirectory.title',
  /** "Hot Leads" */ aspxEmbed_hotLeads_title = 'aspxEmbed.hotLeads.title',
  /** "Settings" */ aspxEmbed_settings_title = 'aspxEmbed.settings.title',
  /** "Manage Users" */ aspxEmbed_managerUsers_title = 'aspxEmbed.managerUsers.title',
  /** "Membership Profile" */ aspxEmbed_membershipProfile_title = 'aspxEmbed.membershipProfile.title',
  /** "Payment History" */ aspxEmbed_paymentHistory_title = 'aspxEmbed.paymentHistory.title',
  /** "Payment Options" */ aspxEmbed_paymentOptions_title = 'aspxEmbed.paymentOptions.title',
  /** "General Settings" */ aspxEmbed_generalSettings_title = 'aspxEmbed.generalSettings.title',
  /** "Tools Settings" */ aspxEmbed_toolsSettings_title = 'aspxEmbed.toolsSettings.title',
  /** "Alerts Settings" */ aspxEmbed_alertsSettings_title = 'aspxEmbed.alertsSettings.title',
  /** "Company Website" */ aspxEmbed_companyWebsite_title = 'aspxEmbed.companyWebsite.title',
  /** "Welcome back" */ aspxEmbed_accountSetup_title = 'aspxEmbed.accountSetup.title',
  /** "Message" */ aspxEmbed_accountNotice_title = 'aspxEmbed.accountNotice.title',
  /** "Account" */ aspxEmbed_accountRedir_title = 'aspxEmbed.accountRedir.title',
  /** "Help Center" */ aspxEmbed_help_title = 'aspxEmbed.help.title',
  /** "Post Truck" */ truckPost_TruckPost = 'truckPost.TruckPost',
  /** "Truck Posting is available on our mobile app" */ truckPost_Announcement_Title = 'truckPost.Announcement.Title',
  /** "To post your truck, open the 123Loadboard app and click on Post." */ truckPost_Announcement_Item1 = 'truckPost.Announcement.Item1',
  /** "You can set up a real-time truck post as well as schedule trucks for future dates and locations." */ truckPost_Announcement_Item2 = 'truckPost.Announcement.Item2',
  /** "Brokers will send you load alerts directly to your phone." */ truckPost_Announcement_Item3 = 'truckPost.Announcement.Item3',
  /** "Download our mobile app to post your truck." */ truckPost_Announcement_Item4 = 'truckPost.Announcement.Item4',
  /** "Find Trucks Matching Your Loads" */ truckLocator_tutorial_load_step1_title = 'truckLocator.tutorial.load.step1.title',
  /** "Find truck capacity matching your load by sending alerts directly to carriers’ mobile devices." */ truckLocator_tutorial_load_step1_content1 = 'truckLocator.tutorial.load.step1.content1',
  /** "You can access this overview tour at any time by clicking this icon " */ truckLocator_tutorial_load_step1_content2 = 'truckLocator.tutorial.load.step1.content2',
  /** "Find Specific Loads" */ truckLocator_tutorial_load_step2_title = 'truckLocator.tutorial.load.step2.title',
  /** "You can filter your loads quickly to find exactly what you want." */ truckLocator_tutorial_load_step2_content = 'truckLocator.tutorial.load.step2.content',
  /** "Locate Carriers" */ truckLocator_tutorial_load_step3_title = 'truckLocator.tutorial.load.step3.title',
  /** "Find truck capacity matching your load by sending alerts directly to carriers’ mobile devices." */ truckLocator_tutorial_load_step3_content = 'truckLocator.tutorial.load.step3.content',
  /** "Customize Your Search" */ truckLocator_tutorial_truck_step1_title = 'truckLocator.tutorial.truck.step1.title',
  /** "Refine your search with available filters to locate carriers that meet your needs." */ truckLocator_tutorial_truck_step1_content = 'truckLocator.tutorial.truck.step1.content',
  /** "Viewing Options" */ truckLocator_tutorial_truck_step2_title = 'truckLocator.tutorial.truck.step2.title',
  /** "You’re currently on the {{viewName}} view. Change your view by toggling between Map and List to easily manage and contact carriers." */ truckLocator_tutorial_truck_step2_content = 'truckLocator.tutorial.truck.step2.content',
  /** "Send Instant Notifications" */ truckLocator_tutorial_truck_step3_title = 'truckLocator.tutorial.truck.step3.title',
  /** "Alert selected carriers of your load by sending a private notification to their mobile device." */ truckLocator_tutorial_truck_step3_content = 'truckLocator.tutorial.truck.step3.content',
  /** "Match Your Next Load" */ truckLocator_tutorial_truck_step4_title = 'truckLocator.tutorial.truck.step4.title',
  /** "Return to your load list and continue matching your loads with available carriers." */ truckLocator_tutorial_truck_step4_content = 'truckLocator.tutorial.truck.step4.content',
  /** "Start Truck Locator Tour" */ truckLocator_tutorial_tooltip_title = 'truckLocator.tutorial.tooltip_title',
  /** "Post Loads" */ truckLocator_Post_Loads = 'truckLocator.Post_Loads',
  /** "Truck Locator" */ truckLocator_Truck_Locator = 'truckLocator.Truck_Locator',
  /** "Alert Carriers" */ truckLocator_Alert_Carriers = 'truckLocator.Alert_Carriers',
  /** "Manage hidden" */ truckLocator_Manage_hidden = 'truckLocator.Manage_hidden',
  /** "Add to favorites" */ truckLocator_AddToFavorites = 'truckLocator.AddToFavorites',
  /** "Remove from favorites" */ truckLocator_RemoveFromFavorites = 'truckLocator.RemoveFromFavorites',
  /** "Favorite" */ truckLocator_Favorite = 'truckLocator.Favorite',
  /** "Tag as onboarded" */ truckLocator_TagAsOnboarded = 'truckLocator.TagAsOnboarded',
  /** "Remove onboarded tag" */ truckLocator_RemoveOnboardedTag = 'truckLocator.RemoveOnboardedTag',
  /** "Onboarded" */ truckLocator_Onboarded = 'truckLocator.Onboarded',
  /** "Request tracking" */ truckLocator_Request_Tracking = 'truckLocator.Request_Tracking',
  /** "You don’t have any loads posted" */ truckLocator_No_Load_Title = 'truckLocator.No_Load_Title',
  /** "Start posting loads to find trucks" */ truckLocator_No_Load_Message = 'truckLocator.No_Load_Message',
  /** "Post of Edit loads in" */ truckLocator_PostEdit = 'truckLocator.PostEdit',
  /** "Manage Loads" */ truckLocator_ManageLoads = 'truckLocator.ManageLoads',
  /** "Find Trucks" */ truckLocator_Find_Trucks = 'truckLocator.Find_Trucks',
  /** "section" */ truckLocator_Section = 'truckLocator.Section',
  /** "Filter by pickup or destination" */ truckLocator_SearchLabel = 'truckLocator.SearchLabel',
  /** "Search by company name" */ truckLocator_SearchLabelCompanyName = 'truckLocator.SearchLabelCompanyName',
  /** "Hide" */ truckLocator_Hide = 'truckLocator.Hide',
  /** "Refresh" */ truckLocator_Refresh = 'truckLocator.Refresh',
  /** "Loads could not be loaded." */ truckLocator_Loads_Fail = 'truckLocator.Loads_Fail',
  /** "Load no longer available" */ truckLocator_Load_No_Longer_Available = 'truckLocator.Load_No_Longer_Available',
  /** "Load has expired or was removed from the system" */ truckLocator_Load_No_Longer_Available_Explain = 'truckLocator.Load_No_Longer_Available_Explain',
  /** "Map" */ truckLocator_Map_Icon_Label = 'truckLocator.Map_Icon_Label',
  /** "List" */ truckLocator_List_Icon_Label = 'truckLocator.List_Icon_Label',
  /** "Back to Load" */ truckLocator_Back_To_Load = 'truckLocator.Back_To_Load',
  /** "Back to Loads" */ truckLocator_Back_To_Loads = 'truckLocator.Back_To_Loads',
  /** "\t(Verified)" */ truckLocator_verified = 'truckLocator.verified',
  /** "\t(Not Verified)" */ truckLocator_notVerified = 'truckLocator.notVerified',
  /** "Select Load" */ truckLocator_stepper_Select_Load = 'truckLocator.stepper.Select_Load',
  /** "Locate Truck" */ truckLocator_stepper_Locate_Truck = 'truckLocator.stepper.Locate_Truck',
  /** "Send Alert" */ truckLocator_stepper_Send_Alert = 'truckLocator.stepper.Send_Alert',
  /** "Common" */ truckLocator_fmcsaAuthority_type_common = 'truckLocator.fmcsaAuthority.type.common',
  /** "Contract" */ truckLocator_fmcsaAuthority_type_contract = 'truckLocator.fmcsaAuthority.type.contract',
  /** "Broker" */ truckLocator_fmcsaAuthority_type_broker = 'truckLocator.fmcsaAuthority.type.broker',
  /** "Active" */ truckLocator_fmcsaAuthority_status_active = 'truckLocator.fmcsaAuthority.status.active',
  /** "Inactive" */ truckLocator_fmcsaAuthority_status_inactive = 'truckLocator.fmcsaAuthority.status.inactive',
  /** "Filters" */ truckLocator_filter_Title = 'truckLocator.filter.Title',
  /** "Radius" */ truckLocator_filter_Radius = 'truckLocator.filter.Radius',
  /** "Favorite" */ truckLocator_filter_Favorite = 'truckLocator.filter.Favorite',
  /** "Onboarded" */ truckLocator_filter_Onboarded = 'truckLocator.filter.Onboarded',
  /** "GPS Verified" */ truckLocator_filter_GPS_Verified = 'truckLocator.filter.GPS_Verified',
  /** "Active authority (MC#)" */ truckLocator_filter_ActiveAuthority = 'truckLocator.filter.ActiveAuthority',
  /** "applied" */ truckLocator_filter_Applied = 'truckLocator.filter.Applied',
  /** "Your company sent {{messagesCount}} messages to this carrier recently." */ truckLocator_truckTable_sentMessages = 'truckLocator.truckTable.sentMessages',
  /** "exact matches" */ truckLocator_truckTable_Exact_Matches = 'truckLocator.truckTable.Exact_Matches',
  /** "exact matches - anonymous" */ truckLocator_truckTable_Anonymous_Matches = 'truckLocator.truckTable.Anonymous_Matches',
  /** "Filtered out matches" */ truckLocator_truckTable_Filtered_Out_Matches = 'truckLocator.truckTable.Filtered_Out_Matches',
  /** "non matches" */ truckLocator_truckTable_Non_Matches = 'truckLocator.truckTable.Non_Matches',
  /** "Alert this carrier" */ truckLocator_truckTable_Alert_This_Carrier = 'truckLocator.truckTable.Alert_This_Carrier',
  /** "No results found, please refine your search to allow the results to display." */ truckLocator_truckTable_No_Results = 'truckLocator.truckTable.No_Results',
  /** "Carrier Details" */ truckLocator_truckTable_carrierDetails_Title = 'truckLocator.truckTable.carrierDetails.Title',
  /** "Authority" */ truckLocator_truckTable_carrierDetails_Authority = 'truckLocator.truckTable.carrierDetails.Authority',
  /** "Authority Numbers" */ truckLocator_truckTable_carrierDetails_AuthorityNumbers = 'truckLocator.truckTable.carrierDetails.AuthorityNumbers',
  /** "Cargo Authorization" */ truckLocator_truckTable_carrierDetails_CargoAuth = 'truckLocator.truckTable.carrierDetails.CargoAuth',
  /** "Property" */ truckLocator_truckTable_carrierDetails_Property = 'truckLocator.truckTable.carrierDetails.Property',
  /** "Passengers" */ truckLocator_truckTable_carrierDetails_Passengers = 'truckLocator.truckTable.carrierDetails.Passengers',
  /** "Household goods" */ truckLocator_truckTable_carrierDetails_HouseholdGoods = 'truckLocator.truckTable.carrierDetails.HouseholdGoods',
  /** "Private" */ truckLocator_truckTable_carrierDetails_Private = 'truckLocator.truckTable.carrierDetails.Private',
  /** "Enterprise" */ truckLocator_truckTable_carrierDetails_Enterprise = 'truckLocator.truckTable.carrierDetails.Enterprise',
  /** "App. pending" */ truckLocator_truckTable_carrierDetails_AppPending = 'truckLocator.truckTable.carrierDetails.AppPending',
  /** "Revocation pending" */ truckLocator_truckTable_carrierDetails_RevocationPending = 'truckLocator.truckTable.carrierDetails.RevocationPending',
  /** "Insurance" */ truckLocator_truckTable_carrierDetails_Insurance = 'truckLocator.truckTable.carrierDetails.Insurance',
  /** "BIPD Insurance" */ truckLocator_truckTable_carrierDetails_BIPDInsurance = 'truckLocator.truckTable.carrierDetails.BIPDInsurance',
  /** "HHG Cargo Insurance" */ truckLocator_truckTable_carrierDetails_HHGCargoInsurance = 'truckLocator.truckTable.carrierDetails.HHGCargoInsurance',
  /** "Bond Insurance" */ truckLocator_truckTable_carrierDetails_BondInsurance = 'truckLocator.truckTable.carrierDetails.BondInsurance',
  /** "Required" */ truckLocator_truckTable_carrierDetails_Required = 'truckLocator.truckTable.carrierDetails.Required',
  /** "Filed" */ truckLocator_truckTable_carrierDetails_Filed = 'truckLocator.truckTable.carrierDetails.Filed',
  /** "BIPD/Primary" */ truckLocator_truckTable_carrierDetails_BIPDPrimary = 'truckLocator.truckTable.carrierDetails.BIPDPrimary',
  /** "Underwriter" */ truckLocator_truckTable_carrierDetails_Underwriter = 'truckLocator.truckTable.carrierDetails.Underwriter',
  /** "Type" */ truckLocator_truckTable_carrierDetails_Type = 'truckLocator.truckTable.carrierDetails.Type',
  /** "Policy number" */ truckLocator_truckTable_carrierDetails_PolicyNum = 'truckLocator.truckTable.carrierDetails.PolicyNum',
  /** "Effective date" */ truckLocator_truckTable_carrierDetails_EffectiveDate = 'truckLocator.truckTable.carrierDetails.EffectiveDate',
  /** "BIPD Class" */ truckLocator_truckTable_carrierDetails_BIPDClass = 'truckLocator.truckTable.carrierDetails.BIPDClass',
  /** "Alert sent {{time}}" */ truckLocator_truckTable_carrierDetails_AlertSent = 'truckLocator.truckTable.carrierDetails.AlertSent',
  /** "Phone verification is done at the users request by confirming that they have access to the phone number provided." */ truckLocator_truckTable_carrierDetails_phoneVerificationPopupText = 'truckLocator.truckTable.carrierDetails.phoneVerificationPopupText',
  /** "DOT# verification is done by confirming that the user has access to the company phone number listed with the FMCSA." */ truckLocator_truckTable_carrierDetails_dotVerificationPopupText = 'truckLocator.truckTable.carrierDetails.dotVerificationPopupText',
  /** "Your load will be sent to {{count}} carrier" */ truckLocator_alertDialog_Description = 'truckLocator.alertDialog.Description',
  /** "Your load will be sent to {{count}} carriers" */ truckLocator_alertDialog_Description_plural = 'truckLocator.alertDialog.Description_plural',
  /** "Pay rate" */ truckLocator_alertDialog_Payrate = 'truckLocator.alertDialog.Payrate',
  /** "Unit" */ truckLocator_alertDialog_Unit = 'truckLocator.alertDialog.Unit',
  /** "Message to carrier" */ truckLocator_alertDialog_Message_To_Carrier = 'truckLocator.alertDialog.Message_To_Carrier',
  /** "Send" */ truckLocator_alertDialog_Send = 'truckLocator.alertDialog.Send',
  /** "Cancel" */ truckLocator_alertDialog_Cancel = 'truckLocator.alertDialog.Cancel',
  /** "Clear" */ truckLocator_alertDialog_Clear = 'truckLocator.alertDialog.Clear',
  /** "Entire Trip" */ truckLocator_alertDialog_EntireTrip = 'truckLocator.alertDialog.EntireTrip',
  /** "Per Mile" */ truckLocator_alertDialog_PerMile = 'truckLocator.alertDialog.PerMile',
  /** "is required" */ truckLocator_alertDialog_Is_Required = 'truckLocator.alertDialog.Is_Required',
  /** "Something went wrong. Please try again later or contact" */ truckLocator_alertDialog_Send_Error = 'truckLocator.alertDialog.Send_Error',
  /** "Your load info has been successfully sent to {{count}} carrier." */ truckLocator_alertDialog_Send_Success = 'truckLocator.alertDialog.Send_Success',
  /** "Your load info has been successfully sent to {{count}} carriers." */ truckLocator_alertDialog_Send_Success_plural = 'truckLocator.alertDialog.Send_Success_plural',
  /** "Hide Companies" */ truckLocator_hideDialog_Title = 'truckLocator.hideDialog.Title',
  /** "This will hide all trucks from the selected companies." */ truckLocator_hideDialog_Message = 'truckLocator.hideDialog.Message',
  /** "My Loads" */ truckLocator_loadTable_My_Loads = 'truckLocator.loadTable.My_Loads',
  /** "just now" */ truckLocator_loadTable_Just_Now = 'truckLocator.loadTable.Just_Now',
  /** "Map" */ truckLocator_map_Map = 'truckLocator.map.Map',
  /** "Exact Matches" */ truckLocator_map_legend_Exact_Matches = 'truckLocator.map.legend.Exact_Matches',
  /** "Anonymous Matches" */ truckLocator_map_legend_Anonymous_Matches = 'truckLocator.map.legend.Anonymous_Matches',
  /** "Unselected Matches" */ truckLocator_map_legend_Unselected_Matches = 'truckLocator.map.legend.Unselected_Matches',
  /** "Filtered out Matches" */ truckLocator_map_legend_Filtered_Out_Matches = 'truckLocator.map.legend.Filtered_Out_Matches',
  /** "Non Matches" */ truckLocator_map_legend_Non_Matches = 'truckLocator.map.legend.Non_Matches',
  /** "show" */ truckLocator_map_legend_show = 'truckLocator.map.legend.show',
  /** "hide" */ truckLocator_map_legend_hide = 'truckLocator.map.legend.hide',
  /** "No route found between origin and destination" */ truckLocator_map_path_not_found = 'truckLocator.map.path_not_found',
  /** "Couldn't retrieve trucks" */ truckLocator_error_title = 'truckLocator.error.title',
  /** "Oops, there was an issue loading trucks for your load." */ truckLocator_error_message = 'truckLocator.error.message',
  /** "Back to loads" */ truckLocator_error_button = 'truckLocator.error.button',
  /** "There were no trucks found matching your load. Please adjust your load post or try again later." */ truckLocator_error_noExactMatches = 'truckLocator.error.noExactMatches',
  /** "Posting Aid Software" */ postingAid_Posting_Aid = 'postingAid.Posting_Aid',
  /** "Instructions" */ postingAid_Instructions = 'postingAid.Instructions',
  /** "Posting Aid is a free program that assists you in uploading and automatically posting your loads to 123Loadboard. Posting Aid captures and posts continuous changes made throughout the day. Need assistance?" */ postingAid_Description = 'postingAid.Description',
  /** "ext. 2" */ postingAid_Extension = 'postingAid.Extension',
  /** "Posting Aid" */ postingAid_Download_Button = 'postingAid.Download_Button',
  /** "Watch Video." */ postingAid_WatchVideo = 'postingAid.WatchVideo',
  /** "Getting started" */ postingAid_step1_Title = 'postingAid.step1.Title',
  /** "Download and install the software." */ postingAid_step1_Content = 'postingAid.step1.Content',
  /** "Easy setup" */ postingAid_step2_Title = 'postingAid.step2.Title',
  /** "Open the Posting Aid software" */ postingAid_step2_Step1 = 'postingAid.step2.Step1',
  /** "Configure sections as follows:" */ postingAid_step2_step2_Content = 'postingAid.step2.step2.Content',
  /** "Select file or folder to monitor" */ postingAid_step2_step2_StepA = 'postingAid.step2.step2.StepA',
  /** "Select file type or keep as 'any'" */ postingAid_step2_step2_StepB = 'postingAid.step2.step2.StepB',
  /** "Enter your 123Loadboard login details" */ postingAid_step2_step2_StepC = 'postingAid.step2.step2.StepC',
  /** "Enter your FTP username and password provided by 123Loadboard" */ postingAid_step2_step2_StepD = 'postingAid.step2.step2.StepD',
  /** "Start the Posting Aid on the first section." */ postingAid_step2_Step3 = 'postingAid.step2.Step3',
  /** "You're Done!" */ postingAid_step3_Title = 'postingAid.step3.Title',
  /** "Posting Aid is now set up to monitor the file or folder you specified and will continuously upload and post changes made throughout the day." */ postingAid_step3_Content = 'postingAid.step3.Content',
  /** "NOTE: The file is uploaded within 15 seconds." */ postingAid_step3_Note = 'postingAid.step3.Note',
  /** "Fuel Prices" */ fuelPrices_Title = 'fuelPrices.Title',
  /** "Stay up to date with fuel prices and energy trends." */ fuelPrices_Description = 'fuelPrices.Description',
  /** "Weekly On-Highway Diesel Prices" */ fuelPrices_Box1Title = 'fuelPrices.Box1Title',
  /** "VIEW FUEL PRICES" */ fuelPrices_BOX1Button = 'fuelPrices.BOX1Button',
  /** "Energy updates provided by" */ fuelPrices_Box2Title = 'fuelPrices.Box2Title',
  /** "The U.S. Energy Information Administration (EIA) is a principal agency of the U.S. Federal Statistical System responsible for collecting, analyzing, and disseminating energy information to promote sound policymaking, efficient markets, and public understanding of energy and its interaction with the economy and the environment." */ fuelPrices_Box2Description = 'fuelPrices.Box2Description',
  /** "FMCSA Search" */ fmcsaSearch_FMCSA_Search = 'fmcsaSearch.FMCSA_Search',
  /** "Review and monitor current operating authority, insurance status and safety ratings." */ fmcsaSearch_Description = 'fmcsaSearch.Description',
  /** "Licensing and Insurance" */ fmcsaSearch_LicensingInsurance = 'fmcsaSearch.LicensingInsurance',
  /** "Safety and Fitness Electronic Records (SAFER) System" */ fmcsaSearch_SAFER = 'fmcsaSearch.SAFER',
  /** "Search FMCSA" */ fmcsaSearch_SearchFmcsa = 'fmcsaSearch.SearchFmcsa',
  /** "Search SAFER" */ fmcsaSearch_SearchSAFER = 'fmcsaSearch.SearchSAFER',
  /** "United States Department of Transportation" */ fmcsaSearch_USDOT_Title = 'fmcsaSearch.USDOT_Title',
  /** "The United States Department of Transportation (USDOT or DOT) is a federal Cabinet department of the U.S. government concerned with transportation." */ fmcsaSearch_USDOT_Description = 'fmcsaSearch.USDOT_Description',
  /** "Get paid for your load instantly!" */ getPaidFaster_title = 'getPaidFaster.title',
  /** "Start factoring loads today and get paid much faster by keeping your trucks rolling. Free fuel cards will reduce overall expenses plus fuel advances will allow you to buy fuel and deliver your freight on time." */ getPaidFaster_description = 'getPaidFaster.description',
  /** "Exclusive Factoring Partner" */ getPaidFaster_sidePanel_title = 'getPaidFaster.sidePanel.title',
  /** "Thunder Funding offers the most complete range of funding services for freight companies. From Owner Operators to Fleet Owners, they've got you covered." */ getPaidFaster_sidePanel_description = 'getPaidFaster.sidePanel.description',
  /** "123Loadboard has secured an exclusive partnership with Thunder Funding to provide you with immediate access to cash through the most innovative factoring program in the trucking industry. With Fuel Advances available 7 days a week, Thunder Funding always keeps you rolling." */ getPaidFaster_form_description = 'getPaidFaster.form.description',
  /** "KEY BENEFITS" */ getPaidFaster_form_listTitle = 'getPaidFaster.form.listTitle',
  /** "Invoice Factoring" */ getPaidFaster_form_listItems_i1 = 'getPaidFaster.form.listItems.i1',
  /** "Working Capital Loans" */ getPaidFaster_form_listItems_i2 = 'getPaidFaster.form.listItems.i2',
  /** "Free Fuel Cards" */ getPaidFaster_form_listItems_i3 = 'getPaidFaster.form.listItems.i3',
  /** "Fuel Lines of Credit" */ getPaidFaster_form_listItems_i4 = 'getPaidFaster.form.listItems.i4',
  /** "Fuel Advances 7 days a week" */ getPaidFaster_form_listItems_i5 = 'getPaidFaster.form.listItems.i5',
  /** "Rush and Same Day Funding" */ getPaidFaster_form_listItems_i6 = 'getPaidFaster.form.listItems.i6',
  /** "Free Credit Checking Service" */ getPaidFaster_form_listItems_i7 = 'getPaidFaster.form.listItems.i7',
  /** "Free Online Account Management" */ getPaidFaster_form_listItems_i8 = 'getPaidFaster.form.listItems.i8',
  /** "Complete the form to get paid faster" */ getPaidFaster_form_inputsTitle = 'getPaidFaster.form.inputsTitle',
  /** "Get Started" */ getPaidFaster_form_button = 'getPaidFaster.form.button',
  /** "By clicking get started, a representative from Thunder Funding will be contacting you." */ getPaidFaster_form_contactText = 'getPaidFaster.form.contactText',
  /** "Thank you! We've received your request." */ getPaidFaster_done_title = 'getPaidFaster.done.title',
  /** "An expert from Thunder Funding will contact you soon." */ getPaidFaster_done_description = 'getPaidFaster.done.description',
  /** "You previously submitted a request to get started with factoring." */ getPaidFaster_already_text1 = 'getPaidFaster.already.text1',
  /** "If you haven't been contacted yet, " */ getPaidFaster_already_text2a = 'getPaidFaster.already.text2a',
  /** "resubmit here" */ getPaidFaster_already_text2b = 'getPaidFaster.already.text2b',
  /** " or call Thunder Funding." */ getPaidFaster_already_text2c = 'getPaidFaster.already.text2c',
  /** "CALL 877-873-8357" */ getPaidFaster_already_callButton = 'getPaidFaster.already.callButton',
  /** "Trucking Authority" */ truckingAuthority_Trucking_Authority = 'truckingAuthority.Trucking_Authority',
  /** "Get help with your trucking authority" */ truckingAuthority_title = 'truckingAuthority.title',
  /** "Trucking starts with authority. Make sure that you have the proper documentation to keep your truck moving." */ truckingAuthority_description = 'truckingAuthority.description',
  /** "Exclusive Trucking Authority Partner" */ truckingAuthority_sidePanel_title = 'truckingAuthority.sidePanel.title',
  /** "Evilsizor & Associates is a full-service authority company with over 30 years of experience specializing in all things paperwork when dealing with the FMCSA and USDOT." */ truckingAuthority_sidePanel_description = 'truckingAuthority.sidePanel.description',
  /** "Looking to file your own authority, request name changes, file BOCs and more... our trucking authority partner can help." */ truckingAuthority_form_description = 'truckingAuthority.form.description',
  /** "Complete the form to speak to an authority expert" */ truckingAuthority_form_inputsTitle = 'truckingAuthority.form.inputsTitle',
  /** "SUBMIT" */ truckingAuthority_form_button = 'truckingAuthority.form.button',
  /** "By clicking SUBMIT, a representative from Evilsizor & Associates will be contacting you." */ truckingAuthority_form_contactText = 'truckingAuthority.form.contactText',
  /** "Thank you! We've received your request." */ truckingAuthority_done_title = 'truckingAuthority.done.title',
  /** "An expert from Evilsizor & Associates will contact you soon." */ truckingAuthority_done_description = 'truckingAuthority.done.description',
  /** "Reliable document management to get you on the road" */ truckingAuthority_already_title = 'truckingAuthority.already.title',
  /** "You previously submitted a request for a free consultation." */ truckingAuthority_already_text1 = 'truckingAuthority.already.text1',
  /** "If you haven't been contacted yet, " */ truckingAuthority_already_text2a = 'truckingAuthority.already.text2a',
  /** "resubmit here" */ truckingAuthority_already_text2b = 'truckingAuthority.already.text2b',
  /** " or call Evilsizor & Associates." */ truckingAuthority_already_text2c = 'truckingAuthority.already.text2c',
  /** "CALL 303-482-2965" */ truckingAuthority_already_callButton = 'truckingAuthority.already.callButton',
  /** "Introducing the 123Loadboard Fuel Card" */ fuelCard_title = 'fuelCard.title',
  /** "Fuel accounts for nearly 40% of your operating expenses. Using a fuel card for your trucking business is a great way to reduce overall expenses and allows you to purchase fuel at the cash price or even at a discounted price rather than using a credit card and over paying at the pump for credit pricing." */ fuelCard_description = 'fuelCard.description',
  /** "Exclusive Fuel Card" */ fuelCard_sidePanel_title = 'fuelCard.sidePanel.title',
  /** "Powered by Thunder Funding and EFS." */ fuelCard_sidePanel_description = 'fuelCard.sidePanel.description',
  /** "No sign-up fees, monthly charges, or annual fees." */ fuelCard_form_description = 'fuelCard.form.description',
  /** "KEY BENEFITS" */ fuelCard_form_listTitle = 'fuelCard.form.listTitle',
  /** "Free to enroll, no set-up or monthly fees" */ fuelCard_form_listItems_i1 = 'fuelCard.form.listItems.i1',
  /** "Huge discounts of up to 60 cents per gallon" */ fuelCard_form_listItems_i2 = 'fuelCard.form.listItems.i2',
  /** "Fuel lines of credit" */ fuelCard_form_listItems_i3 = 'fuelCard.form.listItems.i3',
  /** "IFTA tax reporting" */ fuelCard_form_listItems_i4 = 'fuelCard.form.listItems.i4',
  /** "Accepted at over 14,000 locations nationwide" */ fuelCard_form_listItems_i5 = 'fuelCard.form.listItems.i5',
  /** "Complete the form to get your fuel card" */ fuelCard_form_inputsTitle = 'fuelCard.form.inputsTitle',
  /** "GET FUEL CARD" */ fuelCard_form_button = 'fuelCard.form.button',
  /** "By clicking Get Fuel Card, a representative from Thunder Funding will be contacting you." */ fuelCard_form_contactText = 'fuelCard.form.contactText',
  /** "Thank you! We've received your request." */ fuelCard_done_title = 'fuelCard.done.title',
  /** "An expert from Thunder Funding will contact you soon." */ fuelCard_done_description = 'fuelCard.done.description',
  /** "You previously submitted a request to get a fuel card." */ fuelCard_already_text1 = 'fuelCard.already.text1',
  /** "If you haven't been contacted yet, " */ fuelCard_already_text2a = 'fuelCard.already.text2a',
  /** "resubmit here" */ fuelCard_already_text2b = 'fuelCard.already.text2b',
  /** " or call Thunder Funding." */ fuelCard_already_text2c = 'fuelCard.already.text2c',
  /** "CALL 877-873-8357" */ fuelCard_already_callButton = 'fuelCard.already.callButton',
  /** "Backhauls" */ backhauls_Backhauls = 'backhauls.Backhauls',
  /** "{{value}} possible loads" */ backhauls_possibleLoads = 'backhauls.possibleLoads',
  /** "1 possible load" */ backhauls_possibleLoad = 'backhauls.possibleLoad',
  /** "Rate history" */ rateCheck_history = 'rateCheck.history',
  /** "Rate Check" */ rateCheck_Rate_Check = 'rateCheck.Rate_Check',
  /** "{{value}}/mi average rate" */ rateCheck_Average_Rate = 'rateCheck.Average_Rate',
  /** "Estimate the average load rate per mile and your load revenue. Instantly get mileage, routing and toll costs (addon required)." */ rateCheck_Description = 'rateCheck.Description',
  /** "ABOUT RATE CHECK" */ rateCheck_About_Title = 'rateCheck.About_Title',
  /** "Please select a city from the drop-down list" */ rateCheck_Error_Message = 'rateCheck.Error_Message',
  /** "Rate data is collected from hundreds of small to medium sized carriers. Rates are in USD and are updated daily for most lanes in the Continental US." */ rateCheck_About = 'rateCheck.About',
  /** "Rate Check data is not available for the search criteria entered. Where rate data is limited, such as in Canada, our system may produce higher/lower rates. We are continuously working on improving our algorithms so that rate data is more accurate." */ rateCheck_No_Rate_Check = 'rateCheck.No_Rate_Check',
  /** "This is not the actual rate but rather the average rate per mile provided by Rate Check. The fuel surcharge may or may not be included in the revenue." */ rateCheck_Note = 'rateCheck.Note',
  /** "Enter Route" */ rateCheck_routeBox_Title = 'rateCheck.routeBox.Title',
  /** "Origin - City or Zip" */ rateCheck_routeBox_Origin = 'rateCheck.routeBox.Origin',
  /** "Destination - City or Zip" */ rateCheck_routeBox_Destination = 'rateCheck.routeBox.Destination',
  /** "Van" */ rateCheck_routeBox_Van = 'rateCheck.routeBox.Van',
  /** "Reefer" */ rateCheck_routeBox_Reefer = 'rateCheck.routeBox.Reefer',
  /** "Flatbed" */ rateCheck_routeBox_Flatbed = 'rateCheck.routeBox.Flatbed',
  /** "get rate" */ rateCheck_routeBox_Button = 'rateCheck.routeBox.Button',
  /** "Get rate" */ rateCheck_routeBox_GetRate = 'rateCheck.routeBox.GetRate',
  /** "Route:" */ rateCheck_revenueBox_Route = 'rateCheck.revenueBox.Route',
  /** "Distance" */ rateCheck_revenueBox_Distance = 'rateCheck.revenueBox.Distance',
  /** "Avg. rate (USD) / mile" */ rateCheck_revenueBox_Rate_Per_Mile = 'rateCheck.revenueBox.Rate_Per_Mile',
  /** "Fuel Surcharge" */ rateCheck_revenueBox_Fuel_Surcharge = 'rateCheck.revenueBox.Fuel_Surcharge',
  /** "Linehaul Revenue" */ rateCheck_revenueBox_Linehaul_Revenue = 'rateCheck.revenueBox.Linehaul_Revenue',
  /** "Toll Costs" */ rateCheck_revenueBox_Toll_Costs = 'rateCheck.revenueBox.Toll_Costs',
  /** "Estimated Fuel Costs" */ rateCheck_revenueBox_Fuel_Costs = 'rateCheck.revenueBox.Fuel_Costs',
  /** "Additional Costs" */ rateCheck_revenueBox_Additional_Costs = 'rateCheck.revenueBox.Additional_Costs',
  /** "Est. Fuel Costs" */ rateCheck_revenueBox_Fuel_Costs_Short = 'rateCheck.revenueBox.Fuel_Costs_Short',
  /** "Estimated Revenue" */ rateCheck_revenueBox_Estimated_Revenue = 'rateCheck.revenueBox.Estimated_Revenue',
  /** "Est. Revenue" */ rateCheck_revenueBox_Estimated_Revenue_Short = 'rateCheck.revenueBox.Estimated_Revenue_Short',
  /** "Included" */ rateCheck_revenueBox_Included = 'rateCheck.revenueBox.Included',
  /** "Low" */ rateCheck_revenueBox_Low = 'rateCheck.revenueBox.Low',
  /** "Average" */ rateCheck_revenueBox_Average = 'rateCheck.revenueBox.Average',
  /** "High" */ rateCheck_revenueBox_High = 'rateCheck.revenueBox.High',
  /** "View loads" */ rateCheck_revenueBox_View_Loads = 'rateCheck.revenueBox.View_Loads',
  /** "Loads per " */ marketRates_loadToTruckRatioTitle = 'marketRates.loadToTruckRatioTitle',
  /** "Market rates" */ marketRates_searchForm_marketRates = 'marketRates.searchForm.marketRates',
  /** "Select a lane and equipment type to see the market rate." */ marketRates_searchForm_title = 'marketRates.searchForm.title',
  /** "Get rate" */ marketRates_searchForm_getRate = 'marketRates.searchForm.getRate',
  /** "Market rate data can help you price your freight by providing you with historical rate data for specified lanes and equipment types." */ marketRates_searchForm_notFoundForBroker = 'marketRates.searchForm.notFoundForBroker',
  /** "The Market Rates tool can help you find rate data and determine profitability for specific lanes based on your equipment type." */ marketRates_searchForm_notFoundForCarrier = 'marketRates.searchForm.notFoundForCarrier',
  /** "Origin - city or zip" */ marketRates_searchForm_originLabel = 'marketRates.searchForm.originLabel',
  /** "Destination - city or zip" */ marketRates_searchForm_destinationLabel = 'marketRates.searchForm.destinationLabel',
  /** "City" */ marketRates_widgets_city = 'marketRates.widgets.city',
  /** "County" */ marketRates_widgets_county = 'marketRates.widgets.county',
  /** "State" */ marketRates_widgets_state = 'marketRates.widgets.state',
  /** "days" */ marketRates_widgets_days = 'marketRates.widgets.days',
  /** "month" */ marketRates_widgets_month = 'marketRates.widgets.month',
  /** "months" */ marketRates_widgets_months = 'marketRates.widgets.months',
  /** "year" */ marketRates_widgets_year = 'marketRates.widgets.year',
  /** "Past" */ marketRates_widgets_past = 'marketRates.widgets.past',
  /** "Select data range" */ marketRates_widgets_selectDataRange = 'marketRates.widgets.selectDataRange',
  /** "Average rate check for past" */ marketRates_widgets_averageRateAnalysis = 'marketRates.widgets.averageRateAnalysis',
  /** "Powered by Expedite All" */ marketRates_widgets_expediteAll_poweredBy = 'marketRates.widgets.expediteAll.poweredBy',
  /** "Broker-to-Carrier rate for " */ marketRates_widgets_expediteAll_brokerToCarrierLTLRate = 'marketRates.widgets.expediteAll.brokerToCarrierLTLRate',
  /** "Expedite All" */ marketRates_widgets_expediteAll_title = 'marketRates.widgets.expediteAll.title',
  /** "{{rate}}/mi estimated rate" */ marketRates_widgets_expediteAll_estimatedRate = 'marketRates.widgets.expediteAll.estimatedRate',
  /** "{{rate}}/mi est. rate by Expedite All" */ marketRates_widgets_expediteAll_estRateByExpedite = 'marketRates.widgets.expediteAll.estRateByExpedite',
  /** "Expedite All provides market rate data, offering accurate rate benchmarks derived from real-time industry data to help you compare lane rates and optimize your pricing strategy." */ marketRates_widgets_expediteAll_info = 'marketRates.widgets.expediteAll.info',
  /** "Broker-to-Carrier rate for past" */ marketRates_widgets_brokerToCarrierRates_title = 'marketRates.widgets.brokerToCarrierRates.title',
  /** "Total trip: {{miles}} mi" */ marketRates_widgets_brokerToCarrierRates_totalTrip = 'marketRates.widgets.brokerToCarrierRates.totalTrip',
  /** "Incl. trip fuel cost: {{fuelCost}}" */ marketRates_widgets_brokerToCarrierRates_fuelCost = 'marketRates.widgets.brokerToCarrierRates.fuelCost',
  /** "Carrier spot rate" */ marketRates_widgets_brokerToCarrierRates_titleCarrier = 'marketRates.widgets.brokerToCarrierRates.titleCarrier',
  /** "Recommended rate (incl. fuel)" */ marketRates_widgets_brokerToCarrierRates_subtitle = 'marketRates.widgets.brokerToCarrierRates.subtitle',
  /** "Based on" */ marketRates_widgets_brokerToCarrierRates_basedOn = 'marketRates.widgets.brokerToCarrierRates.basedOn',
  /** "reports" */ marketRates_widgets_brokerToCarrierRates_reports = 'marketRates.widgets.brokerToCarrierRates.reports',
  /** "Capacity finder" */ marketRates_widgets_capacityFinder_title = 'marketRates.widgets.capacityFinder.title',
  /** "Contact posted trucks and carriers searching for loads on this lane." */ marketRates_widgets_capacityFinder_subtitle = 'marketRates.widgets.capacityFinder.subtitle',
  /** "{{number}} Carrier(s)" */ marketRates_widgets_capacityFinder_numberOfCarriers = 'marketRates.widgets.capacityFinder.numberOfCarriers',
  /** "Est. fuel cost" */ marketRates_widgets_fuelAndTollCosts_estFuelCost = 'marketRates.widgets.fuelAndTollCosts.estFuelCost',
  /** "Toll costs" */ marketRates_widgets_fuelAndTollCosts_tollCost = 'marketRates.widgets.fuelAndTollCosts.tollCost',
  /** "Trip" */ marketRates_widgets_fuelAndTollCosts_trip = 'marketRates.widgets.fuelAndTollCosts.trip',
  /** "Driving time" */ marketRates_widgets_fuelAndTollCosts_drivingTime = 'marketRates.widgets.fuelAndTollCosts.drivingTime',
  /** "Estimated profit" */ marketRates_widgets_fuelAndTollCosts_estimatedProfit = 'marketRates.widgets.fuelAndTollCosts.estimatedProfit',
  /** "Margin calculator" */ marketRates_widgets_marginCalculator_title = 'marketRates.widgets.marginCalculator.title',
  /** "Rate ($)" */ marketRates_widgets_marginCalculator_rate = 'marketRates.widgets.marginCalculator.rate',
  /** "Margin %" */ marketRates_widgets_marginCalculator_margin = 'marketRates.widgets.marginCalculator.margin',
  /** "Target rate ($)" */ marketRates_widgets_marginCalculator_targetRate = 'marketRates.widgets.marginCalculator.targetRate',
  /** "Data range: " */ marketRates_widgets_rateCheck_dataRange = 'marketRates.widgets.rateCheck.dataRange',
  /** "Suggested rate" */ marketRates_widgets_rateCheck_suggestedRate = 'marketRates.widgets.rateCheck.suggestedRate',
  /** "Backhaul average rate" */ marketRates_widgets_rateCheck_backhaulAverageRate = 'marketRates.widgets.rateCheck.backhaulAverageRate',
  /** "Acceptance point" */ marketRates_widgets_acceptancePoint_title = 'marketRates.widgets.acceptancePoint.title',
  /** "Determine your negotiation range and find the rate acceptance point for this lane." */ marketRates_widgets_acceptancePoint_subtitle = 'marketRates.widgets.acceptancePoint.subtitle',
  /** "Acceptance point calculator" */ marketRates_widgets_acceptancePoint_calculatorTitle = 'marketRates.widgets.acceptancePoint.calculatorTitle',
  /** "Reset" */ marketRates_widgets_acceptancePoint_reset = 'marketRates.widgets.acceptancePoint.reset',
  /** "Posting rate ($)" */ marketRates_widgets_acceptancePoint_postingRateLabel = 'marketRates.widgets.acceptancePoint.postingRateLabel',
  /** "Est. carrier offer ($)" */ marketRates_widgets_acceptancePoint_estimatedCarrierOfferLabel = 'marketRates.widgets.acceptancePoint.estimatedCarrierOfferLabel',
  /** "Shipper charge ($)" */ marketRates_widgets_acceptancePoint_shipperChargeLabel = 'marketRates.widgets.acceptancePoint.shipperChargeLabel',
  /** "Acceptance point ($)" */ marketRates_widgets_acceptancePoint_acceptancePointLabel = 'marketRates.widgets.acceptancePoint.acceptancePointLabel',
  /** "Total profit margin of shipper charge:" */ marketRates_widgets_acceptancePoint_profitMarginLabel = 'marketRates.widgets.acceptancePoint.profitMarginLabel',
  /** "above" */ marketRates_widgets_acceptancePoint_above = 'marketRates.widgets.acceptancePoint.above',
  /** "below" */ marketRates_widgets_acceptancePoint_below = 'marketRates.widgets.acceptancePoint.below',
  /** "{{percent}}% {{helperText}} acceptance point" */ marketRates_widgets_acceptancePoint_profitMarginDifference = 'marketRates.widgets.acceptancePoint.profitMarginDifference',
  /** "Posting rate" */ marketRates_widgets_acceptancePoint_cards_postingRate_title = 'marketRates.widgets.acceptancePoint.cards.postingRate.title',
  /** "Posting rate is the price you intend to post your load at. The default entry is the average market rate. You may enter your own value if you already know the rate you plan to post." */ marketRates_widgets_acceptancePoint_cards_postingRate_text = 'marketRates.widgets.acceptancePoint.cards.postingRate.text',
  /** "Est. carrier offer" */ marketRates_widgets_acceptancePoint_cards_estimatedCarrierOffer_title = 'marketRates.widgets.acceptancePoint.cards.estimatedCarrierOffer.title',
  /** "This is the estimated rate desired by the carrier. If you have an offer from a carrier, you can enter it to calculate how acceptable it is for your business." */ marketRates_widgets_acceptancePoint_cards_estimatedCarrierOffer_text = 'marketRates.widgets.acceptancePoint.cards.estimatedCarrierOffer.text',
  /** "Shipper charge" */ marketRates_widgets_acceptancePoint_cards_shipperCharge_title = 'marketRates.widgets.acceptancePoint.cards.shipperCharge.title',
  /** "Shipper charge is the rate you will be charging the shipper to move loads on this lane. You may enter your own shipper charge to increase the accuracy of this tool." */ marketRates_widgets_acceptancePoint_cards_shipperCharge_text = 'marketRates.widgets.acceptancePoint.cards.shipperCharge.text',
  /** "Acceptance point" */ marketRates_widgets_acceptancePoint_cards_acceptancePoint_title = 'marketRates.widgets.acceptancePoint.cards.acceptancePoint.title',
  /** "The acceptance point is the mutually acceptable rate for a load on this lane. You may enter your own acceptance point to calculate your profit margin when charging the shipper." */ marketRates_widgets_acceptancePoint_cards_acceptancePoint_text = 'marketRates.widgets.acceptancePoint.cards.acceptancePoint.text',
  /** "Posting rate:" */ marketRates_widgets_acceptancePoint_graph_postingRateLabel = 'marketRates.widgets.acceptancePoint.graph.postingRateLabel',
  /** "Acceptance point:" */ marketRates_widgets_acceptancePoint_graph_acceptancePointLabel = 'marketRates.widgets.acceptancePoint.graph.acceptancePointLabel',
  /** "Shipper charge:" */ marketRates_widgets_acceptancePoint_graph_shipperChargeLabel = 'marketRates.widgets.acceptancePoint.graph.shipperChargeLabel',
  /** "Est. carrier offer:" */ marketRates_widgets_acceptancePoint_graph_estimatedCarrierOfferLabel = 'marketRates.widgets.acceptancePoint.graph.estimatedCarrierOfferLabel',
  /** "below posting rate" */ marketRates_widgets_acceptancePoint_graph_belowPostingRate = 'marketRates.widgets.acceptancePoint.graph.belowPostingRate',
  /** "above posting rate" */ marketRates_widgets_acceptancePoint_graph_abovePostingRate = 'marketRates.widgets.acceptancePoint.graph.abovePostingRate',
  /** "Negotiation strength" */ marketRates_widgets_negotiationStrength_title = 'marketRates.widgets.negotiationStrength.title',
  /** "Posting strength data for the selected equipment type and lane is unavailable at this time." */ marketRates_widgets_negotiationStrength_unavailableText = 'marketRates.widgets.negotiationStrength.unavailableText',
  /** "Negotiation strength data for the selected equipment type and lane is unavailable at this time." */ marketRates_widgets_negotiationStrength_unavailableTextCarrier = 'marketRates.widgets.negotiationStrength.unavailableTextCarrier',
  /** "Low" */ marketRates_widgets_negotiationStrength_lowSection_title = 'marketRates.widgets.negotiationStrength.lowSection.title',
  /** "Negotiation strength for this lane is  " */ marketRates_widgets_negotiationStrength_lowSection_part1 = 'marketRates.widgets.negotiationStrength.lowSection.part1',
  /** ", so you may have to offer a higher rate." */ marketRates_widgets_negotiationStrength_lowSection_part2 = 'marketRates.widgets.negotiationStrength.lowSection.part2',
  /** ", so you may have to accept a lower rate." */ marketRates_widgets_negotiationStrength_lowSection_part2Carrier = 'marketRates.widgets.negotiationStrength.lowSection.part2Carrier',
  /** "Medium" */ marketRates_widgets_negotiationStrength_mediumSection_title = 'marketRates.widgets.negotiationStrength.mediumSection.title',
  /** "Negotiation strength for this lane is  " */ marketRates_widgets_negotiationStrength_mediumSection_part1 = 'marketRates.widgets.negotiationStrength.mediumSection.part1',
  /** ", so you may need to offer close to the average rate." */ marketRates_widgets_negotiationStrength_mediumSection_part2 = 'marketRates.widgets.negotiationStrength.mediumSection.part2',
  /** ", so you may need to accept a rate closer to the average." */ marketRates_widgets_negotiationStrength_mediumSection_part2Carrier = 'marketRates.widgets.negotiationStrength.mediumSection.part2Carrier',
  /** "High" */ marketRates_widgets_negotiationStrength_highSection_title = 'marketRates.widgets.negotiationStrength.highSection.title',
  /** "Negotiation strength for this lane is  " */ marketRates_widgets_negotiationStrength_highSection_part1 = 'marketRates.widgets.negotiationStrength.highSection.part1',
  /** ", so you can justify a lower rate." */ marketRates_widgets_negotiationStrength_highSection_part2 = 'marketRates.widgets.negotiationStrength.highSection.part2',
  /** ", so you can justify a higher rate." */ marketRates_widgets_negotiationStrength_highSection_part2Carrier = 'marketRates.widgets.negotiationStrength.highSection.part2Carrier',
  /** "Historical rates" */ marketRates_widgets_historicalRates_title = 'marketRates.widgets.historicalRates.title',
  /** "Historical market rates for {{month}}" */ marketRates_widgets_historicalRates_legendTitle = 'marketRates.widgets.historicalRates.legendTitle',
  /** "Rate analysis" */ marketRates_widgets_rateAnalysis_title = 'marketRates.widgets.rateAnalysis.title',
  /** "Pickup location loads" */ marketRates_widgets_loadsWidgets_pickupLoadsTitle = 'marketRates.widgets.loadsWidgets.pickupLoadsTitle',
  /** "Backhaul loads" */ marketRates_widgets_loadsWidgets_backhaulsLoadsTitle = 'marketRates.widgets.loadsWidgets.backhaulsLoadsTitle',
  /** "for {{days}}" */ marketRates_widgets_loadsWidgets_forDays = 'marketRates.widgets.loadsWidgets.forDays',
  /** "to {{location}}" */ marketRates_widgets_loadsWidgets_toLocation = 'marketRates.widgets.loadsWidgets.toLocation',
  /** "View loads" */ marketRates_widgets_loadsWidgets_viewLoads = 'marketRates.widgets.loadsWidgets.viewLoads',
  /** "Rate history" */ marketRates_popups_rateHistory_title = 'marketRates.popups.rateHistory.title',
  /** "Rate history can help you predict trends and price your freight based on data from the past year." */ marketRates_popups_rateHistory_content = 'marketRates.popups.rateHistory.content',
  /** "Rate check" */ marketRates_popups_rateCheck_title = 'marketRates.popups.rateCheck.title',
  /** "Rate check provides the average market rate to help compare lane rates before posting loads and better estimate your load revenues." */ marketRates_popups_rateCheck_content = 'marketRates.popups.rateCheck.content',
  /** "Suggested rate" */ marketRates_popups_suggestedRate_title = 'marketRates.popups.suggestedRate.title',
  /** "Suggested rate can help you determine how to price your freight to increase load acceptance by comparing the average rate with other posted loads leaving the pick up location." */ marketRates_popups_suggestedRate_content = 'marketRates.popups.suggestedRate.content',
  /** "Backhaul rate difference" */ marketRates_popups_rateDifference_title = 'marketRates.popups.rateDifference.title',
  /** "Backhaul rate difference can be used to price your freight based on whether the carrier will find higher or lower priced loads at the drop off location." */ marketRates_popups_rateDifference_content = 'marketRates.popups.rateDifference.content',
  /** "Rate reports" */ marketRates_popups_rateReports_title = 'marketRates.popups.rateReports.title',
  /** "Reports are generated by rate data collected based on the entered lane and equipment type." */ marketRates_popups_rateReports_content = 'marketRates.popups.rateReports.content',
  /** "Negotiation strength" */ marketRates_popups_negotiationStrength_title = 'marketRates.popups.negotiationStrength.title',
  /** "Negotiation strength is based on the amount of loads going to the selected destination today and the rates they are posted at." */ marketRates_popups_negotiationStrength_content = 'marketRates.popups.negotiationStrength.content',
  /** "Historical rates" */ marketRates_popups_historicalRates_title = 'marketRates.popups.historicalRates.title',
  /** "Historical data can provide you with a good understanding of recent market activity, seasonal trends, and help with planning and forecasting." */ marketRates_popups_historicalRates_content = 'marketRates.popups.historicalRates.content',
  /** "Rate analysis" */ marketRates_popups_rateAnalysis_title = 'marketRates.popups.rateAnalysis.title',
  /** "Rate analysis provides you with the high, medium, and low rates based on the current year and can help determine fair rates based on this year’s market conditions." */ marketRates_popups_rateAnalysis_content = 'marketRates.popups.rateAnalysis.content',
  /** "Capacity finder" */ capacityFinder_title = 'capacityFinder.title',
  /** "Customizing your results" */ capacityFinder_customizingResults = 'capacityFinder.customizingResults',
  /** "Capacity Finder" */ capacityFinder_titleCapitalized = 'capacityFinder.titleCapitalized',
  /** "Pickup - city or zip" */ capacityFinder_originLocationPlaceholder = 'capacityFinder.originLocationPlaceholder',
  /** "Drop off - city or zip" */ capacityFinder_destinationLocationPlaceholder = 'capacityFinder.destinationLocationPlaceholder',
  /** "Find" */ capacityFinder_findButton = 'capacityFinder.findButton',
  /** "Capacity finder can help you find available carriers to haul your freight via their posted trucks and load search data." */ capacityFinder_emptyPageSubtitle = 'capacityFinder.emptyPageSubtitle',
  /** "No matches found" */ capacityFinder_notFoundTitle = 'capacityFinder.notFoundTitle',
  /** "No matches found. Please change your search parameters or adjust your filters." */ capacityFinder_notFoundSubtitle = 'capacityFinder.notFoundSubtitle',
  /** "Found {{quantity}} carriers" */ capacityFinder_foundCarriers = 'capacityFinder.foundCarriers',
  /** "Equipment(s)" */ capacityFinder_carrierDetailsEquipmentsTitle = 'capacityFinder.carrierDetailsEquipmentsTitle',
  /** "Desired destination(s)" */ capacityFinder_carrierDetailsDestinationsTitle = 'capacityFinder.carrierDetailsDestinationsTitle',
  /** "Search" */ capacityFinder_searchType = 'capacityFinder.searchType',
  /** "Posted truck" */ capacityFinder_postedTruckType = 'capacityFinder.postedTruckType',
  /** "Age" */ capacityFinder_labels_age = 'capacityFinder.labels.age',
  /** "Deadhead" */ capacityFinder_labels_deadhead = 'capacityFinder.labels.deadhead',
  /** "Equipment" */ capacityFinder_labels_equipment = 'capacityFinder.labels.equipment',
  /** "Capacity" */ capacityFinder_labels_capacity = 'capacityFinder.labels.capacity',
  /** "Desired rate" */ capacityFinder_labels_desiredRate = 'capacityFinder.labels.desiredRate',
  /** "Phone" */ capacityFinder_labels_phone = 'capacityFinder.labels.phone',
  /** "Next available" */ capacityFinder_labels_nextAvailable = 'capacityFinder.labels.nextAvailable',
  /** "Type" */ capacityFinder_sorting_type = 'capacityFinder.sorting.type',
  /** "Result type" */ capacityFinder_sorting_resultType = 'capacityFinder.sorting.resultType',
  /** "Age" */ capacityFinder_sorting_age = 'capacityFinder.sorting.age',
  /** "Company" */ capacityFinder_sorting_company = 'capacityFinder.sorting.company',
  /** "Rating" */ capacityFinder_sorting_rating = 'capacityFinder.sorting.rating',
  /** "Deadhead" */ capacityFinder_sorting_deadhead = 'capacityFinder.sorting.deadhead',
  /** "Equipment" */ capacityFinder_sorting_equipment = 'capacityFinder.sorting.equipment',
  /** "Size" */ capacityFinder_sorting_size = 'capacityFinder.sorting.size',
  /** "Available capacity" */ capacityFinder_sorting_availableCapacity = 'capacityFinder.sorting.availableCapacity',
  /** "Avail. capacity" */ capacityFinder_sorting_availableCapacityShort = 'capacityFinder.sorting.availableCapacityShort',
  /** "Desired rate" */ capacityFinder_sorting_desiredRate = 'capacityFinder.sorting.desiredRate',
  /** "Phone" */ capacityFinder_sorting_phone = 'capacityFinder.sorting.phone',
  /** "Next available" */ capacityFinder_sorting_nextAvailable = 'capacityFinder.sorting.nextAvailable',
  /** "Next avail." */ capacityFinder_sorting_nextAvailableShort = 'capacityFinder.sorting.nextAvailableShort',
  /** "Onboarded carriers" */ capacityFinder_filtering_onboardedCarriers = 'capacityFinder.filtering.onboardedCarriers',
  /** "Favorite carriers" */ capacityFinder_filtering_favoriteCarriers = 'capacityFinder.filtering.favoriteCarriers',
  /** "GPS verified" */ capacityFinder_filtering_gpsVerified = 'capacityFinder.filtering.gpsVerified',
  /** "Active authority (MC#)" */ capacityFinder_filtering_activeAuthority = 'capacityFinder.filtering.activeAuthority',
  /** "Age: {{age}}h" */ capacityFinder_filtering_age = 'capacityFinder.filtering.age',
  /** "Pickup radius: {{radius}}mi" */ capacityFinder_filtering_pickupRadiusWithMi = 'capacityFinder.filtering.pickupRadiusWithMi',
  /** "Drop off radius: {{radius}}mi" */ capacityFinder_filtering_dropoffRadiusWithMi = 'capacityFinder.filtering.dropoffRadiusWithMi',
  /** "Pickup radius" */ capacityFinder_filtering_pickupRadius = 'capacityFinder.filtering.pickupRadius',
  /** "Drop off radius" */ capacityFinder_filtering_dropoffRadius = 'capacityFinder.filtering.dropoffRadius',
  /** "LTL only" */ capacityFinder_filtering_ltlOnly = 'capacityFinder.filtering.ltlOnly',
  /** "Onboarded carriers" */ capacityFinder_popups_missingOnboardedCarriers_title = 'capacityFinder.popups.missingOnboardedCarriers.title',
  /** "To filter by onboarded carriers, you need to add their DOT#s to your onboarded carriers list." */ capacityFinder_popups_missingOnboardedCarriers_content = 'capacityFinder.popups.missingOnboardedCarriers.content',
  /** "Onboard" */ myCarrierPortal_onboard = 'myCarrierPortal.onboard',
  /** "Onboarded" */ myCarrierPortal_onboarded = 'myCarrierPortal.onboarded',
  /** "Add to favorites" */ myCarrierPortal_addFavorite = 'myCarrierPortal.addFavorite',
  /** "Favorite" */ myCarrierPortal_favorite = 'myCarrierPortal.favorite',
  /** "MCP risk assessment" */ myCarrierPortal_riskAssessment = 'myCarrierPortal.riskAssessment',
  /** "Integration required" */ myCarrierPortal_integrationRequired = 'myCarrierPortal.integrationRequired',
  /** "MCP integration setup" */ myCarrierPortal_integration_title = 'myCarrierPortal.integration.title',
  /** "To " */ myCarrierPortal_integration_to = 'myCarrierPortal.integration.to',
  /** "see onboarded carriers and " */ myCarrierPortal_integration_onboardedCarriers = 'myCarrierPortal.integration.onboardedCarriers',
  /** "view risk assessments, an integration with MyCarrierPortal is required." */ myCarrierPortal_integration_description = 'myCarrierPortal.integration.description',
  /** "If you don't have a MyCarrierPortal account, you can " */ myCarrierPortal_integration_pointList1 = 'myCarrierPortal.integration.pointList1',
  /** "To link your account, generate MyCarrierPortal integration credentials " */ myCarrierPortal_integration_pointList2 = 'myCarrierPortal.integration.pointList2',
  /** "Return here and proceed to enter credentials." */ myCarrierPortal_integration_pointList3 = 'myCarrierPortal.integration.pointList3',
  /** "sign up here " */ myCarrierPortal_integration_signupLink = 'myCarrierPortal.integration.signupLink',
  /** "here " */ myCarrierPortal_integration_here = 'myCarrierPortal.integration.here',
  /** "Proceed to Link Account" */ myCarrierPortal_integration_proceed = 'myCarrierPortal.integration.proceed',
  /** "Please note that the terms and conditions of MyCarrierPortal may differ from 123Loadboard. The website will open in a new tab. 123Loadboard is not responsible for any content on third party websites." */ myCarrierPortal_integration_termsConditions = 'myCarrierPortal.integration.termsConditions',
  /** "Enter the integration credentials you created with MyCarrierPortal to complete your integration." */ myCarrierPortal_integration_credentialsDescription = 'myCarrierPortal.integration.credentialsDescription',
  /** "MCP integration user name" */ myCarrierPortal_integration_userName = 'myCarrierPortal.integration.userName',
  /** "MCP integration password" */ myCarrierPortal_integration_password = 'myCarrierPortal.integration.password',
  /** "Link Account" */ myCarrierPortal_integration_linkAccount = 'myCarrierPortal.integration.linkAccount',
  /** "Back" */ myCarrierPortal_integration_back = 'myCarrierPortal.integration.back',
  /** "The provided integration credentials are not valid. Please verify your credentials and try again." */ myCarrierPortal_integration_incorrectCredentialsMessage = 'myCarrierPortal.integration.incorrectCredentialsMessage',
  /** "Risk level: Low" */ myCarrierPortal_risklevel_titleLow = 'myCarrierPortal.risklevel.titleLow',
  /** "Risk level: Medium" */ myCarrierPortal_risklevel_titleMedium = 'myCarrierPortal.risklevel.titleMedium',
  /** "Risk level: Review" */ myCarrierPortal_risklevel_titleReview = 'myCarrierPortal.risklevel.titleReview',
  /** "Risk level: Fail" */ myCarrierPortal_risklevel_titleFail = 'myCarrierPortal.risklevel.titleFail',
  /** "Low: " */ myCarrierPortal_risklevel_low = 'myCarrierPortal.risklevel.low',
  /** "Medium: " */ myCarrierPortal_risklevel_medium = 'myCarrierPortal.risklevel.medium',
  /** "Review: " */ myCarrierPortal_risklevel_review = 'myCarrierPortal.risklevel.review',
  /** "Fail: " */ myCarrierPortal_risklevel_fail = 'myCarrierPortal.risklevel.fail',
  /** "This carrier has a low risk level and is likely qualified to haul for you." */ myCarrierPortal_risklevel_descriptionLow = 'myCarrierPortal.risklevel.descriptionLow',
  /** "This carrier has a medium risk level and is likely qualified to haul for you." */ myCarrierPortal_risklevel_descriptionMedium = 'myCarrierPortal.risklevel.descriptionMedium',
  /** "This carrier is likely not qualified to haul for you. Proceed with caution." */ myCarrierPortal_risklevel_descriptionReview = 'myCarrierPortal.risklevel.descriptionReview',
  /** "This carrier is not qualified to haul for you and should not be contacted." */ myCarrierPortal_risklevel_descriptionFail = 'myCarrierPortal.risklevel.descriptionFail',
  /** "Risk Level assessment is not available at the moment. Please try again later." */ myCarrierPortal_risklevel_error = 'myCarrierPortal.risklevel.error',
  /** "Onboard carrier" */ myCarrierPortal_onboarding_onboard = 'myCarrierPortal.onboarding.onboard',
  /** "Onboard with {{company}} to add them to your carrier network." */ myCarrierPortal_onboarding_onboardWith = 'myCarrierPortal.onboarding.onboardWith',
  /** "Start onboarding" */ myCarrierPortal_onboarding_start = 'myCarrierPortal.onboarding.start',
  /** "Onboard pending" */ myCarrierPortal_onboarding_pending = 'myCarrierPortal.onboarding.pending',
  /** "An onboarding request has been sent through MyCarrierPortal. When {{carrier}} has completed the onboarding process, they will automatically be added to your Onboarded carriers." */ myCarrierPortal_onboarding_pendingdescription = 'myCarrierPortal.onboarding.pendingdescription',
  /** "To onboard this carrier, an onboarding request with instructions will be sent via MyCarrierPortal to their associated email address." */ myCarrierPortal_onboarding_description = 'myCarrierPortal.onboarding.description',
  /** "When the carrier has successfully completed the onboarding process, they will automatically be added to your Onboarded carriers." */ myCarrierPortal_onboarding_description2 = 'myCarrierPortal.onboarding.description2',
  /** "Send request" */ myCarrierPortal_onboarding_send = 'myCarrierPortal.onboarding.send',
  /** "Onboarding unsuccessful" */ myCarrierPortal_onboarding_unsuccessful = 'myCarrierPortal.onboarding.unsuccessful',
  /** "The onboarding failed because this carrier's risk assessment score is above your threshold. You may log into your MyCarrierPortal for more options." */ myCarrierPortal_onboarding_onboardingFailed = 'myCarrierPortal.onboarding.onboardingFailed',
  /** "Profit calculator" */ profitCalculator_title = 'profitCalculator.title',
  /** "Please enter values" */ profitCalculator_pleaseEnterValues = 'profitCalculator.pleaseEnterValues',
  /** "Linehaul revenue" */ profitCalculator_linehaulRevenue = 'profitCalculator.linehaulRevenue',
  /** "Miles" */ profitCalculator_miles = 'profitCalculator.miles',
  /** "mile" */ profitCalculator_mile = 'profitCalculator.mile',
  /** "Rate" */ profitCalculator_rate = 'profitCalculator.rate',
  /** "Estimated fuel costs" */ profitCalculator_estimatedFuelCosts = 'profitCalculator.estimatedFuelCosts',
  /** "MPG" */ profitCalculator_mpg = 'profitCalculator.mpg',
  /** "gal" */ profitCalculator_gal = 'profitCalculator.gal',
  /** "Apply fuel discount (up to $0.40/gal)" */ profitCalculator_applyFuelDiscount = 'profitCalculator.applyFuelDiscount',
  /** "Get fuel card" */ profitCalculator_getFuelCard = 'profitCalculator.getFuelCard',
  /** "Additional costs" */ profitCalculator_additionalCosts = 'profitCalculator.additionalCosts',
  /** "Toll cost" */ profitCalculator_tollCost = 'profitCalculator.tollCost',
  /** "Dispatch fee" */ profitCalculator_dispatchFee = 'profitCalculator.dispatchFee',
  /** "Other fee" */ profitCalculator_otherFee = 'profitCalculator.otherFee',
  /** "Total profit" */ profitCalculator_totalProfit = 'profitCalculator.totalProfit',
  /** "mi" */ profitCalculator_mi = 'profitCalculator.mi',
  /** "An entry is required or has an invalid value" */ profitCalculator_fieldsRequiredOrInvalid = 'profitCalculator.fieldsRequiredOrInvalid',
  /** "An entry has an invalid value" */ profitCalculator_fieldsInvalid = 'profitCalculator.fieldsInvalid',
  /** "Profit calculator. Press to calculate you profit." */ profitCalculator_accessibility = 'profitCalculator.accessibility',
  /** "Reset" */ profitCalculator_reset = 'profitCalculator.reset',
  /** "Border Wait Times" */ borderWaitTimes_BorderWaitTimes = 'borderWaitTimes.BorderWaitTimes',
  /** "Get information as to the waiting times at the various Canadian/US Border crossing points. Each border location updates its estimated wait times hourly." */ borderWaitTimes_Description = 'borderWaitTimes.Description',
  /** "USA Border Wait Times" */ borderWaitTimes_USA_Title = 'borderWaitTimes.USA_Title',
  /** "Canada Border Wait Times" */ borderWaitTimes_Canada_Title = 'borderWaitTimes.Canada_Title',
  /** "View USA" */ borderWaitTimes_ViewUSA = 'borderWaitTimes.ViewUSA',
  /** "View Canada" */ borderWaitTimes_ViewCanada = 'borderWaitTimes.ViewCanada',
  /** "Private network" */ privateNetwork_title = 'privateNetwork.title',
  /** "Add USDOT#" */ privateNetwork_addNumber = 'privateNetwork.addNumber',
  /** "Add companies to your private network by adding one or more USDOT# on individual lines or separated by commas." */ privateNetwork_addNumberInstructions = 'privateNetwork.addNumberInstructions',
  /** "No valid numbers were submitted" */ privateNetwork_noValidNumbersSubmitted = 'privateNetwork.noValidNumbersSubmitted',
  /** "all failed validation" */ privateNetwork_allNumbersInvalid = 'privateNetwork.allNumbersInvalid',
  /** "From {{count}} USDOT# record" */ privateNetwork_fromTotal = 'privateNetwork.fromTotal',
  /** "From {{count}} USDOT# records" */ privateNetwork_fromTotal_plural = 'privateNetwork.fromTotal_plural',
  /** "{{count}} duplicate removed" */ privateNetwork_duplicatesRemoved = 'privateNetwork.duplicatesRemoved',
  /** "{{count}} duplicates removed" */ privateNetwork_duplicatesRemoved_plural = 'privateNetwork.duplicatesRemoved_plural',
  /** "{{invalid}} failed validation" */ privateNetwork_failedValidation = 'privateNetwork.failedValidation',
  /** "{{number}} USDOT# added to your private network successfully." */ privateNetwork_successfullyAdded = 'privateNetwork.successfullyAdded',
  /** "Validation failed" */ privateNetwork_validationFailed = 'privateNetwork.validationFailed',
  /** "Review" */ privateNetwork_review = 'privateNetwork.review',
  /** "Filter by USDOT #" */ privateNetwork_filterByNumber = 'privateNetwork.filterByNumber',
  /** "Found {{number}} USDOT#" */ privateNetwork_foundNumbers = 'privateNetwork.foundNumbers',
  /** "There was an issue getting your load board" */ privateNetwork_issueGettingBoard = 'privateNetwork.issueGettingBoard',
  /** "There was an error submitting your request" */ privateNetwork_errorSubmitting = 'privateNetwork.errorSubmitting',
  /** "USDOT#" */ privateNetwork_usDot = 'privateNetwork.usDot',
  /** "{{resultsStartNumber}} - {{resultsEndNumber}} of {{listSize}} USDOT#" */ privateNetwork_pageCount = 'privateNetwork.pageCount',
  /** "Server error" */ privateNetwork_serverError = 'privateNetwork.serverError',
  /** "Delete all" */ privateNetwork_deleteAll = 'privateNetwork.deleteAll',
  /** "Are you sure you want to delete all?" */ privateNetwork_deleteAllText = 'privateNetwork.deleteAllText',
  /** "Add companies to your private network to post your loads privately." */ privateNetwork_postPrivately = 'privateNetwork.postPrivately',
  /** "All the results were filtered" */ privateNetwork_allResultsFiltered = 'privateNetwork.allResultsFiltered',
  /** "Reefer Rates" */ reeferRates_Reefer_Rates = 'reeferRates.Reefer_Rates',
  /** "Source:" */ reeferRates_Source = 'reeferRates.Source',
  /** "U.S. Department of Agriculture’s Agricultural Marketing Service" */ reeferRates_GovLinkName = 'reeferRates.GovLinkName',
  /** "Roadside Assistance" */ roadsideAssist_Roadside_Assistance = 'roadsideAssist.Roadside_Assistance',
  /** "Emergency roadside assistance" */ roadsideAssist_Title = 'roadsideAssist.Title',
  /** "Ensure you keep your truck moving. Get heavy-duty emergency roadside assistance for commercial vehicles." */ roadsideAssist_Description = 'roadsideAssist.Description',
  /** "KEY BENEFITS" */ roadsideAssist_MembershipBenefit = 'roadsideAssist.MembershipBenefit',
  /** "Towing Assistance" */ roadsideAssist_Benefits_Towing = 'roadsideAssist.Benefits.Towing',
  /** "(up to 50 miles per disablement)" */ roadsideAssist_Benefits_Towing2 = 'roadsideAssist.Benefits.Towing2',
  /** "Flat Tire Assistance" */ roadsideAssist_Benefits_FlatTire = 'roadsideAssist.Benefits.FlatTire',
  /** "(up to $150 per occurrence)" */ roadsideAssist_Benefits_FlatTire2 = 'roadsideAssist.Benefits.FlatTire2',
  /** "Tire Replacement Assistance" */ roadsideAssist_Benefits_TireRepl = 'roadsideAssist.Benefits.TireRepl',
  /** "(up to $100 per disablement)" */ roadsideAssist_Benefits_TireRepl2 = 'roadsideAssist.Benefits.TireRepl2',
  /** "Tire Repair Assistance" */ roadsideAssist_Benefits_TireRepair = 'roadsideAssist.Benefits.TireRepair',
  /** "(up to $100 per occurrence)" */ roadsideAssist_Benefits_TireRepair2 = 'roadsideAssist.Benefits.TireRepair2',
  /** "Mobile Mechanic Service" */ roadsideAssist_Benefits_Mechanic = 'roadsideAssist.Benefits.Mechanic',
  /** "(up to $100 for service call)" */ roadsideAssist_Benefits_Mechanic2 = 'roadsideAssist.Benefits.Mechanic2',
  /** "Oil, Build and Water Delivery Service" */ roadsideAssist_Benefits_Oil = 'roadsideAssist.Benefits.Oil',
  /** "Extrication / Winching Assistance" */ roadsideAssist_Benefits_Extriction = 'roadsideAssist.Benefits.Extriction',
  /** "Fuel Delivery Service" */ roadsideAssist_Benefits_Fuel = 'roadsideAssist.Benefits.Fuel',
  /** "(up to 30 gallons of fuel)" */ roadsideAssist_Benefits_Fuel2 = 'roadsideAssist.Benefits.Fuel2',
  /** "Lock-out/Replacement Key Assistance" */ roadsideAssist_Benefits_KeyAssist = 'roadsideAssist.Benefits.KeyAssist',
  /** "(up to $100)" */ roadsideAssist_Benefits_KeyAssist2 = 'roadsideAssist.Benefits.KeyAssist2',
  /** "Jump-starts" */ roadsideAssist_Benefits_JumpStart = 'roadsideAssist.Benefits.JumpStart',
  /** "(up to $150 per occurrence)" */ roadsideAssist_Benefits_JumpStart2 = 'roadsideAssist.Benefits.JumpStart2',
  /** "Pull Start" */ roadsideAssist_Benefits_PullStart = 'roadsideAssist.Benefits.PullStart',
  /** "(up to $100 per occurrence)" */ roadsideAssist_Benefits_PullStart2 = 'roadsideAssist.Benefits.PullStart2',
  /** "Extended Heavy Duty Warranty" */ roadsideAssist_Benefits_HeavyDuty = 'roadsideAssist.Benefits.HeavyDuty',
  /** "Battery Assistance" */ roadsideAssist_Benefits_Battery = 'roadsideAssist.Benefits.Battery',
  /** "(up to $100 for service call)" */ roadsideAssist_Benefits_Battery2 = 'roadsideAssist.Benefits.Battery2',
  /** "Replacement Part Delivery" */ roadsideAssist_Benefits_Parts = 'roadsideAssist.Benefits.Parts',
  /** "(up to $100 per occurrence)" */ roadsideAssist_Benefits_Parts2 = 'roadsideAssist.Benefits.Parts2',
  /** "Thank you! We've received your request." */ roadsideAssist_done_title = 'roadsideAssist.done.title',
  /** "An expert from Rig Nation will contact you soon." */ roadsideAssist_done_description = 'roadsideAssist.done.description',
  /** "Emergency roadside assistance" */ roadsideAssist_already_title = 'roadsideAssist.already.title',
  /** "You previously submitted a request for roadside assistance." */ roadsideAssist_already_text1 = 'roadsideAssist.already.text1',
  /** "If you haven't been contacted yet, " */ roadsideAssist_already_text2a = 'roadsideAssist.already.text2a',
  /** "resubmit here" */ roadsideAssist_already_text2b = 'roadsideAssist.already.text2b',
  /** " or call Rig Nation." */ roadsideAssist_already_text2c = 'roadsideAssist.already.text2c',
  /** "CALL 800-336-5909" */ roadsideAssist_already_callButton = 'roadsideAssist.already.callButton',
  /** "Complete the form to speak a roadside assistance expert" */ roadsideAssist_CompleteForm = 'roadsideAssist.CompleteForm',
  /** "SUBMIT" */ roadsideAssist_form_button = 'roadsideAssist.form_button',
  /** "Exclusive roadside assistance partner" */ roadsideAssist_ExclusiveRoadside = 'roadsideAssist.ExclusiveRoadside',
  /** "Specializing in commercial truck roadside & extended warranty." */ roadsideAssist_Pricing_Description = 'roadsideAssist.Pricing_Description',
  /** "View Terms" */ roadsideAssist_View_Terms = 'roadsideAssist.View_Terms',
  /** "Get Roadside Assistance" */ roadsideAssist_Get_Roadside_Assistance = 'roadsideAssist.Get_Roadside_Assistance',
  /** "Roadside assistance provided by" */ roadsideAssist_Roadside_assistance_provided_by = 'roadsideAssist.Roadside_assistance_provided_by',
  /** "By clicking SUBMIT, a representative from Rig Nation will be contacting you." */ roadsideAssist_form_contactText = 'roadsideAssist.form_contactText',
  /** "Search directory" */ searchDirectory_title = 'searchDirectory.title',
  /** "Contact details" */ searchDirectory_contactDetails = 'searchDirectory.contactDetails',
  /** "E-mail" */ searchDirectory_email = 'searchDirectory.email',
  /** "Find companies and information relevant to your business needs." */ searchDirectory_subtitle = 'searchDirectory.subtitle',
  /** "Search for" */ searchDirectory_searchFor = 'searchDirectory.searchFor',
  /** "Carriers" */ searchDirectory_carriers = 'searchDirectory.carriers',
  /** "Brokers" */ searchDirectory_brokers = 'searchDirectory.brokers',
  /** "Shippers" */ searchDirectory_shippers = 'searchDirectory.shippers',
  /** "Company name" */ searchDirectory_companyName = 'searchDirectory.companyName',
  /** "Location" */ searchDirectory_location = 'searchDirectory.location',
  /** "*Please fill out all mandatory fields." */ searchDirectory_mandatoryFields = 'searchDirectory.mandatoryFields',
  /** "Search" */ searchDirectory_search = 'searchDirectory.search',
  /** "Company details" */ searchDirectory_companyDetails_title = 'searchDirectory.companyDetails.title',
  /** "Business type" */ searchDirectory_companyDetails_businessType = 'searchDirectory.companyDetails.businessType',
  /** "Carrier MC#" */ searchDirectory_companyDetails_carrierMC = 'searchDirectory.companyDetails.carrierMC',
  /** "Broker MC#" */ searchDirectory_companyDetails_brokerMC = 'searchDirectory.companyDetails.brokerMC',
  /** "SCAC code" */ searchDirectory_companyDetails_SCAC = 'searchDirectory.companyDetails.SCAC',
  /** "Interstate#" */ searchDirectory_companyDetails_interstate = 'searchDirectory.companyDetails.interstate',
  /** "Street" */ searchDirectory_companyDetails_street = 'searchDirectory.companyDetails.street',
  /** "Tel" */ searchDirectory_companyDetails_tel = 'searchDirectory.companyDetails.tel',
  /** "Toll-free" */ searchDirectory_companyDetails_tollFree = 'searchDirectory.companyDetails.tollFree',
  /** "Fax" */ searchDirectory_companyDetails_fax = 'searchDirectory.companyDetails.fax',
  /** "Website" */ searchDirectory_companyDetails_website = 'searchDirectory.companyDetails.website',
  /** "View company on map" */ searchDirectory_companyDetails_viewOnMap = 'searchDirectory.companyDetails.viewOnMap',
  /** "Business background info" */ searchDirectory_companyDetails_businessInfo_title = 'searchDirectory.companyDetails.businessInfo.title',
  /** "Association & certification" */ searchDirectory_companyDetails_businessInfo_associationCertification = 'searchDirectory.companyDetails.businessInfo.associationCertification',
  /** "Year founded" */ searchDirectory_companyDetails_businessInfo_yearFounded = 'searchDirectory.companyDetails.businessInfo.yearFounded',
  /** "Company profile" */ searchDirectory_companyDetails_businessInfo_companyProfile = 'searchDirectory.companyDetails.businessInfo.companyProfile',
  /** "Safety" */ searchDirectory_companyDetails_safety_title = 'searchDirectory.companyDetails.safety.title',
  /** "Crashes" */ searchDirectory_companyDetails_safety_subtitle = 'searchDirectory.companyDetails.safety.subtitle',
  /** "Fatal" */ searchDirectory_companyDetails_safety_fatal = 'searchDirectory.companyDetails.safety.fatal',
  /** "Injury" */ searchDirectory_companyDetails_safety_injury = 'searchDirectory.companyDetails.safety.injury',
  /** "Tow" */ searchDirectory_companyDetails_safety_tow = 'searchDirectory.companyDetails.safety.tow',
  /** "N/A" */ searchDirectory_companyDetails_safety_empty = 'searchDirectory.companyDetails.safety.empty',
  /** "Credit rating" */ searchDirectory_companyDetails_creditRating = 'searchDirectory.companyDetails.creditRating',
  /** "No results found" */ searchDirectory_notFound_title = 'searchDirectory.notFound.title',
  /** "Please adjust your search criteria and try again." */ searchDirectory_notFound_content = 'searchDirectory.notFound.content',
  /** "Edit search" */ searchDirectory_notFound_button = 'searchDirectory.notFound.button',
  /** "TIA" */ searchDirectory_certification_tia = 'searchDirectory.certification.tia',
  /** "OCFP" */ searchDirectory_certification_ocfp = 'searchDirectory.certification.ocfp',
  /** "Search Directory" */ searchDirectory_upgradePopup_title = 'searchDirectory.upgradePopup.title',
  /** "The Search Directory allows you to quickly look up companies that use 123Loadboard. Being informed about the companies you work with, or would like to work with is essential to success in the transportation industry. The Search Directory is updated daily, so you can be sure that the information provided is accurate and up-to-date." */ searchDirectory_upgradePopup_content1 = 'searchDirectory.upgradePopup.content1',
  /** "Search Directory is available as part of the Premium Plus membership plan." */ searchDirectory_upgradePopup_content2 = 'searchDirectory.upgradePopup.content2',
  /** "Learn more" */ searchDirectory_upgradePopup_learnMore = 'searchDirectory.upgradePopup.learnMore',
  /** "Invalid file size. The file shouldn't be empty nor be over 4MB." */ dropBox_File_Too_Big = 'dropBox.File_Too_Big',
  /** "Invalid file submitted" */ dropBox_Invalid_FeedBack = 'dropBox.Invalid_FeedBack',
  /** "Load Details" */ shareLoad_title = 'shareLoad.title',
  /** "Unlock Full Potential" */ shareLoad_promo_unlockFullPotential = 'shareLoad.promo.unlockFullPotential',
  /** "SWITCH TO MAIN VIEW" */ shareLoad_promo_switchToMainView = 'shareLoad.promo.switchToMainView',
  /** "Log in to place a bid, book this load, or message the poster. Compare market rates, find backhauls, plan your route, and use smart tools to optimize your trip." */ shareLoad_promo_loginPromoText = 'shareLoad.promo.loginPromoText',
  /** "LOG IN" */ shareLoad_promo_logIn = 'shareLoad.promo.logIn',
  /** "Find More Loads Like This" */ shareLoad_promo_findMoreLoads = 'shareLoad.promo.findMoreLoads',
  /** "Explore similar loads with full details, compare opportunities, and take action. Log in to bid, book, or message the poster directly and get the loads you need for your business." */ shareLoad_promo_exploreMoreText = 'shareLoad.promo.exploreMoreText',
  /** "LOG IN TO SEE MORE LOADS" */ shareLoad_promo_logInToSeeMore = 'shareLoad.promo.logInToSeeMore',
  /** "This load was shared with you." */ shareLoad_description = 'shareLoad.description',
  /** "Load Information" */ shareLoad_loadInformation_Title = 'shareLoad.loadInformation.Title',
  /** "Distance" */ shareLoad_loadInformation_Distance = 'shareLoad.loadInformation.Distance',
  /** "Pickup Date(s)" */ shareLoad_loadInformation_Pickup_Date = 'shareLoad.loadInformation.Pickup_Date',
  /** "Equipment Type (Spec.)" */ shareLoad_loadInformation_Equipment_Type = 'shareLoad.loadInformation.Equipment_Type',
  /** "Size" */ shareLoad_loadInformation_Size = 'shareLoad.loadInformation.Size',
  /** "Weight" */ shareLoad_loadInformation_Weight = 'shareLoad.loadInformation.Weight',
  /** "Length" */ shareLoad_loadInformation_Length = 'shareLoad.loadInformation.Length',
  /** "Posted rate" */ shareLoad_loadInformation_Posted_Rate = 'shareLoad.loadInformation.Posted_Rate',
  /** "Dispatch" */ shareLoad_dispatch_Title = 'shareLoad.dispatch.Title',
  /** "Contact Name" */ shareLoad_dispatch_Contact_Name = 'shareLoad.dispatch.Contact_Name',
  /** "Telephone" */ shareLoad_dispatch_Telephone = 'shareLoad.dispatch.Telephone',
  /** "Ext" */ shareLoad_dispatch_Extension = 'shareLoad.dispatch.Extension',
  /** "Load Reference #" */ shareLoad_dispatch_Reference_Number = 'shareLoad.dispatch.Reference_Number',
  /** "Company" */ shareLoad_company_Title = 'shareLoad.company.Title',
  /** "Mileage, Routing & Toll Cost Information" */ shareLoad_Route_Title = 'shareLoad.Route.Title',
  /** "Total Mileage" */ shareLoad_Route_Total_Mileage = 'shareLoad.Route.Total_Mileage',
  /** "Toll Costs" */ shareLoad_Route_Toll_Costs = 'shareLoad.Route.Toll_Costs',
  /** "Driving Time" */ shareLoad_Route_Driving_Time = 'shareLoad.Route.Driving_Time',
  /** "Routing information not found for this load" */ shareLoad_Route_Not_Found = 'shareLoad.Route.Not_Found',
  /** "Credit information provided by TransCredit and ThunderFunding. Licensing, authority, and insurance information provided by the FMCSA and other sources. Disclaimer: While every effort has been made to ensure that the information contained on this site/app is correct and up-to-date at the time of posting, 123Loadboard, TransCredit, ThunderFunding, PC*Miler, its partners, affiliates and/or subsidiaries shall not be liable for any damages of any kind resulting from use of the information contained herein. 123Loadboard does not lay any claims to the accuracy of the credit ratings, days-to-pay, licensing, authority, mileage, and/or insurance information listed and in no way is the broker, shipper, carrier, owner-operator bound to it. No person should place reliance on information derived from this site/app where such reliance may result in loss, damage or injury. You may choose to make additional credit checks. If you feel that the information posted contains errors, please contact us." */ shareLoad_Route_Disclaimer = 'shareLoad.Route.Disclaimer',
  /** "Credit Rating" */ shareLoad_creditRating_Title = 'shareLoad.creditRating.Title',
  /** "There is no credit rating available at the moment for this company." */ shareLoad_creditRating_No_Credit_Ratings = 'shareLoad.creditRating.No_Credit_Ratings',
  /** "Please " */ shareLoad_upgradeMembership_text1 = 'shareLoad.upgradeMembership.text1',
  /** "upgrade" */ shareLoad_upgradeMembership_link = 'shareLoad.upgradeMembership.link',
  /** " your membership." */ shareLoad_upgradeMembership_text2 = 'shareLoad.upgradeMembership.text2',
  /** "The 123Loadboard user sharing this load does not have access to this tool. This feature is available as part of the Premium plan. Please sign in into your account to see these details or " */ shareLoad_upgradeMembershipLoggedOff_text1 = 'shareLoad.upgradeMembershipLoggedOff.text1',
  /** "upgrade" */ shareLoad_upgradeMembershipLoggedOff_link = 'shareLoad.upgradeMembershipLoggedOff.link',
  /** " your membership." */ shareLoad_upgradeMembershipLoggedOff_text2 = 'shareLoad.upgradeMembershipLoggedOff.text2',
  /** "Verify your email" */ verifyEmail_title = 'verifyEmail.title',
  /** "Email verified" */ verifyEmail_header_successful = 'verifyEmail.header.successful',
  /** "Verification link expired" */ verifyEmail_header_expired = 'verifyEmail.header.expired',
  /** "Email verification" */ verifyEmail_header_input = 'verifyEmail.header.input',
  /** "Verification code invalid" */ verifyEmail_header_notFound = 'verifyEmail.header.notFound',
  /** "Email verification failed" */ verifyEmail_header_failed = 'verifyEmail.header.failed',
  /** "We sent an email to activate your account, please check your email {{email}}" */ verifyEmail_subheader_topMessage = 'verifyEmail.subheader.topMessage',
  /** "Your email has been successfully verified." */ verifyEmail_subheader_successful = 'verifyEmail.subheader.successful',
  /** "Your email address was not verified. Click the button below or call our Customer Support line at (877) 875-5301." */ verifyEmail_subheader_failed = 'verifyEmail.subheader.failed',
  /** "You can now sign in with your 123loadboard account." */ verifyEmail_content_successful = 'verifyEmail.content.successful',
  /** "This verification link has expired.\nPlease request a new one to access your account." */ verifyEmail_content_expired = 'verifyEmail.content.expired',
  /** "Enter your verification code below. If you require further assistance, call our Customer Support at 877-875-5301" */ verifyEmail_content_input = 'verifyEmail.content.input',
  /** "Please check your verification code. If you require further assistance, call our Customer Support at 877-875-5301" */ verifyEmail_content_notFound = 'verifyEmail.content.notFound',
  /** "If you do not receive an email within the next few minutes, be sure to" */ verifyEmail_content_bottomMessage = 'verifyEmail.content.bottomMessage',
  /** "check your spam folder or call" */ verifyEmail_content_checkSpam = 'verifyEmail.content.checkSpam',
  /** "(9am-6pm M-F)" */ verifyEmail_content_desiredTime = 'verifyEmail.content.desiredTime',
  /** "or click here to" */ verifyEmail_content_resendMessage = 'verifyEmail.content.resendMessage',
  /** "Resend email" */ verifyEmail_content_resendEmail = 'verifyEmail.content.resendEmail',
  /** "I’m verified" */ verifyEmail_submitButton_text = 'verifyEmail.submitButton.text',
  /** "Verification code" */ verifyEmail_codeInputLabel = 'verifyEmail.codeInputLabel',
  /** "Validate" */ verifyEmail_validate = 'verifyEmail.validate',
  /** "* If you forgot your login details," */ verifyEmail_note = 'verifyEmail.note',
  /** "Sign in with another account" */ verifyEmail_signIn = 'verifyEmail.signIn',
  /** "Verification link sent" */ verifyEmail_popups_linkSent_title = 'verifyEmail.popups.linkSent.title',
  /** "A verification email has been sent to {{email}}. Please check your inbox and/or your spam/email folder." */ verifyEmail_popups_linkSent_text = 'verifyEmail.popups.linkSent.text',
  /** "Failed to send verification link" */ verifyEmail_popups_failedToSend_title = 'verifyEmail.popups.failedToSend.title',
  /** "Sorry, there was a problem sending the email. Please try again." */ verifyEmail_popups_failedToSend_text = 'verifyEmail.popups.failedToSend.text',
  /** "Email is not verified" */ verifyEmail_popups_notVerified_title = 'verifyEmail.popups.notVerified.title',
  /** "Sorry, this email address is not verified yet. Please check your email again." */ verifyEmail_popups_notVerified_text = 'verifyEmail.popups.notVerified.text',
  /** "If you are not redirected, please" */ verifiedLogin_redirectMessage = 'verifiedLogin.redirectMessage',
  /** "Sign in to 123Loadboard" */ verifiedLogin_divertToAppMessage_header = 'verifiedLogin.divertToAppMessage.header',
  /** "We’ve detected that you signed up on a mobile device.\nPlease open the mobile app to continue." */ verifiedLogin_divertToAppMessage_content = 'verifiedLogin.divertToAppMessage.content',
  /** "Unsubscribe assistant" */ unsubscribeAssistant_title = 'unsubscribeAssistant.title',
  /** "Your current plan: " */ unsubscribeAssistant_currentPlanTitle = 'unsubscribeAssistant.currentPlanTitle',
  /** "(you pay ${{priceWithDiscount}} for a ${{defaultPlanPrice}} plan per month)" */ unsubscribeAssistant_currentPlanDiscount = 'unsubscribeAssistant.currentPlanDiscount',
  /** "(you pay ${{price}} per month)" */ unsubscribeAssistant_currentPlanFullPrice = 'unsubscribeAssistant.currentPlanFullPrice',
  /** "Your load statuses, saved searches, availability to brokers/shippers, and load documents stored with 123Loadboard will be affected by unsubscribing." */ unsubscribeAssistant_currentPlanInfo = 'unsubscribeAssistant.currentPlanInfo',
  /** "Please indicate the reason you are unsubscribing." */ unsubscribeAssistant_reason = 'unsubscribeAssistant.reason',
  /** "Cost" */ unsubscribeAssistant_cost = 'unsubscribeAssistant.cost',
  /** "Issue with loads" */ unsubscribeAssistant_issue = 'unsubscribeAssistant.issue',
  /** "Difficulty of use" */ unsubscribeAssistant_difficulty = 'unsubscribeAssistant.difficulty',
  /** "Missing functionality" */ unsubscribeAssistant_missingFunctionality = 'unsubscribeAssistant.missingFunctionality',
  /** "Not using it" */ unsubscribeAssistant_notUsing = 'unsubscribeAssistant.notUsing',
  /** "Something else" */ unsubscribeAssistant_somethingElse = 'unsubscribeAssistant.somethingElse',
  /** "EXTEND YOUR FREE TRIAL FOR {{promotionDays}} DAYS" */ unsubscribeAssistant_trialExtensionFree = 'unsubscribeAssistant.trialExtensionFree',
  /** " in addition to the {{daysToExpire}} days you have left." */ unsubscribeAssistant_trialExtension = 'unsubscribeAssistant.trialExtension',
  /** "Extend my trial" */ unsubscribeAssistant_extensionLink = 'unsubscribeAssistant.extensionLink',
  /** "No thanks, I still want to " */ unsubscribeAssistant_continueUnsubscribe = 'unsubscribeAssistant.continueUnsubscribe',
  /** "unsubscribe." */ unsubscribeAssistant_unsubscribeLink = 'unsubscribeAssistant.unsubscribeLink',
  /** "We understand everybody is on a budget." */ unsubscribeAssistant_costHeader = 'unsubscribeAssistant.costHeader',
  /** "Our membership plans are very competitive and can be tailored to meet your needs. You might be paying for features you don’t really need. 123Loadboard offers more cost-effective packages that can help you trim the fat. \n\n Contact us and inquire about some of our other packages that might work better for you." */ unsubscribeAssistant_costBody = 'unsubscribeAssistant.costBody',
  /** "Not so fast, we might have what you’re looking for." */ unsubscribeAssistant_missingFunctionalityHeader = 'unsubscribeAssistant.missingFunctionalityHeader',
  /** "Not finding what you’re looking for? A live chat with one of our Customer Support Specialists might point you in the right direction." */ unsubscribeAssistant_missingFunctionalityBody = 'unsubscribeAssistant.missingFunctionalityBody',
  /** "Can’t find any good paying loads?" */ unsubscribeAssistant_issueTitle1 = 'unsubscribeAssistant.issueTitle1',
  /** "Sometimes a broker/shipper may not offer the best rates, or shipping rates have taken a bit of a dive this week. For these issues, we have the " */ unsubscribeAssistant_issueBody1a = 'unsubscribeAssistant.issueBody1a',
  /** "Rate Check " */ unsubscribeAssistant_issueBody1b = 'unsubscribeAssistant.issueBody1b',
  /** "tool that can help increase your negotiation strength as well as keep you on top of pricing trends in the industry using the latest data. All of our loads also feature a " */ unsubscribeAssistant_issueBody1c = 'unsubscribeAssistant.issueBody1c',
  /** "Backhaul " */ unsubscribeAssistant_issueBody1d = 'unsubscribeAssistant.issueBody1d',
  /** "tool that can help you get back loaded and maximize your earning potential." */ unsubscribeAssistant_issueBody1e = 'unsubscribeAssistant.issueBody1e',
  /** "Can’t book any of the loads I found?" */ unsubscribeAssistant_issueTitle2 = 'unsubscribeAssistant.issueTitle2',
  /** "The trucking industry is very competitive and dynamic; some days a load can be gone in the blink of an eye! Loads on 123Loadboard are updated in real-time, so make sure you call loads immediately. Also, posting your truck is a way to get around this with our " */ unsubscribeAssistant_issueBody2a = 'unsubscribeAssistant.issueBody2a',
  /** "Load Alerts" */ unsubscribeAssistant_issueBody2b = 'unsubscribeAssistant.issueBody2b',
  /** " tool. When you post your truck on 123Loadboard, you will get alerted to loads matching your truck and location. Another way to keep your truck loaded is by using our " */ unsubscribeAssistant_issueBody2c = 'unsubscribeAssistant.issueBody2c',
  /** "Search Alerts" */ unsubscribeAssistant_issueBody2d = 'unsubscribeAssistant.issueBody2d',
  /** ". Have a route you run all the time? You can receive search alerts specifically for that lane whenever a new load is posted." */ unsubscribeAssistant_issueBody2e = 'unsubscribeAssistant.issueBody2e',
  /** "Can’t find a load that matches my truck?" */ unsubscribeAssistant_issueTitle3 = 'unsubscribeAssistant.issueTitle3',
  /** "Having trouble finding loads? We have many different ways to find freight. The " */ unsubscribeAssistant_issueBody3a = 'unsubscribeAssistant.issueBody3a',
  /** "Load Planner" */ unsubscribeAssistant_issueBody3b = 'unsubscribeAssistant.issueBody3b',
  /** " tool can help you go from Point A to Point B and deliver a separate load along the way." */ unsubscribeAssistant_issueBody3c = 'unsubscribeAssistant.issueBody3c',
  /** "Our Customer Support Specialists can give you advice on how you can optimize your search filters, post a truck, set search alerts, and get you the loads you need for your truck." */ unsubscribeAssistant_issueContactSection = 'unsubscribeAssistant.issueContactSection',
  /** "Facing problems? We have solutions to help you out." */ unsubscribeAssistant_difficultyHeader = 'unsubscribeAssistant.difficultyHeader',
  /** "123Loadboard gets you loads across the country to keep your wheels rolling. Let us show you how." */ unsubscribeAssistant_notUsingItHeader = 'unsubscribeAssistant.notUsingItHeader',
  /** "Tell us why you want to unsubscribe. We’ll be happy to get in touch with you as soon as possible to resolve any issues you may have." */ unsubscribeAssistant_somethingElseHeader = 'unsubscribeAssistant.somethingElseHeader',
  /** "Check out our Overview video to see how 123Loadboard can help you to grow your business." */ unsubscribeAssistant_watchTheVideoBody = 'unsubscribeAssistant.watchTheVideoBody',
  /** "We host weekly live video tutorials with our resident Board Strategists that can help you out of any jam. Join one and see what 123Loadboard can truly offer." */ unsubscribeAssistant_joinFreeWebinarDifficulty = 'unsubscribeAssistant.joinFreeWebinarDifficulty',
  /** "Our Board Strategists host regular live presentations to guide you through any questions you may have about the board and show you how it can benefit you." */ unsubscribeAssistant_joinFreeWebinarNotUsing = 'unsubscribeAssistant.joinFreeWebinarNotUsing',
  /** "Want to talk to a real person to solve any difficulties you have? We’re here to help." */ unsubscribeAssistant_contactSupportBody = 'unsubscribeAssistant.contactSupportBody',
  /** "I understand that my account will be unsubscribed from the service and my load statuses and truck availability for brokers/shippers may be affected, along with my saved searches. " */ unsubscribeAssistant_confirmCheckboxUnsubscribedServices = 'unsubscribeAssistant.confirmCheckboxUnsubscribedServices',
  /** "I understand that my documents may be affected by unsubscribing. " */ unsubscribeAssistant_confirmCheckboxAffectDocuments = 'unsubscribeAssistant.confirmCheckboxAffectDocuments',
  /** "I understand that any ongoing discounts " */ unsubscribeAssistant_confirmCheckboxOngoingDiscounts = 'unsubscribeAssistant.confirmCheckboxOngoingDiscounts',
  /** "(you pay ${{price}} for a ${{fullPrice}} plan per month) " */ unsubscribeAssistant_confirmCheckboxOngoingDiscountsDetail = 'unsubscribeAssistant.confirmCheckboxOngoingDiscountsDetail',
  /** "will be lost upon reactivating your membership in the future." */ unsubscribeAssistant_confirmCheckboxOngoingDiscountsLost = 'unsubscribeAssistant.confirmCheckboxOngoingDiscountsLost',
  /** "Do you really want to unsubscribe?" */ unsubscribeAssistant_cardPopupTitle = 'unsubscribeAssistant.cardPopupTitle',
  /** "By unsubscribing, you acknowledge the following:" */ unsubscribeAssistant_cardPopupHeader = 'unsubscribeAssistant.cardPopupHeader',
  /** "Trial Extension" */ unsubscribeAssistant_trialExtensionTitle = 'unsubscribeAssistant.trialExtensionTitle',
  /** "Unsubscribe failed" */ unsubscribeAssistant_unauthorizedHeader = 'unsubscribeAssistant.unauthorizedHeader',
  /** "Only administrators are permitted to make changes to 123Loadboard accounts. Please contact your account administrator for further assistance." */ unsubscribeAssistant_unauthorizedMessage = 'unsubscribeAssistant.unauthorizedMessage',
  /** "Trial extension request has been sent!" */ unsubscribeAssistant_trialExtensionSent = 'unsubscribeAssistant.trialExtensionSent',
  /** "Our Staff has been notified that you requested an extension to your free trial. You will receive confirmation shortly." */ unsubscribeAssistant_trialExtensionMessage = 'unsubscribeAssistant.trialExtensionMessage',
  /** "Customer support" */ unsubscribeAssistant_contactSupportTitle = 'unsubscribeAssistant.contactSupportTitle',
  /** "Contact support" */ unsubscribeAssistant_contactSupportHeader = 'unsubscribeAssistant.contactSupportHeader',
  /** "Our Customer Support Team is available from 9:00 AM - 5:00 PM EST. Monday to Friday to help you and answer any questions you may have." */ unsubscribeAssistant_contactSupportMessage = 'unsubscribeAssistant.contactSupportMessage',
  /** "(877) 875-5301" */ unsubscribeAssistant_contactSupportPhone = 'unsubscribeAssistant.contactSupportPhone',
  /** "Time" */ unsubscribeAssistant_contactSupportTimeLabel = 'unsubscribeAssistant.contactSupportTimeLabel',
  /** "Need to schedule a call?" */ unsubscribeAssistant_contactSupportSchedule = 'unsubscribeAssistant.contactSupportSchedule',
  /** "Please select a time (9:00 AM - 5:00 PM EST. Monday to Friday) for one of our Customer Support Representatives to contact you." */ unsubscribeAssistant_contactSupportScheduleMessage = 'unsubscribeAssistant.contactSupportScheduleMessage',
  /** "Thank you" */ unsubscribeAssistant_customerSupportSent = 'unsubscribeAssistant.customerSupportSent',
  /** "Your call request has been submitted and one of our Customer Support Representatives will contact you between {{time}} EST." */ unsubscribeAssistant_customerSupportMessage = 'unsubscribeAssistant.customerSupportMessage',
  /** "What would make you come back?" */ unsubscribeAssistant_detailIssueTitle = 'unsubscribeAssistant.detailIssueTitle',
  /** "Unsubscribe completed" */ unsubscribeAssistant_unsubscribeFinalizedTitle = 'unsubscribeAssistant.unsubscribeFinalizedTitle',
  /** "We are confirming that:" */ unsubscribeAssistant_unsubscribeFinalizedH1 = 'unsubscribeAssistant.unsubscribeFinalizedH1',
  /** "Your request to unsubscribe was successfully submitted." */ unsubscribeAssistant_unsubscribeFinalizedMessage0 = 'unsubscribeAssistant.unsubscribeFinalizedMessage0',
  /** "We have removed your credit card and billing information. There will be no further charges on your account." */ unsubscribeAssistant_unsubscribeFinalizedMessage1 = 'unsubscribeAssistant.unsubscribeFinalizedMessage1',
  /** "Your account will be downgraded to a free “Basic account”" */ unsubscribeAssistant_unsubscribeFinalizedH2 = 'unsubscribeAssistant.unsubscribeFinalizedH2',
  /** "." */ unsubscribeAssistant_unsubscribeFinalizedEmptyDowngradeDate = 'unsubscribeAssistant.unsubscribeFinalizedEmptyDowngradeDate',
  /** " effective " */ unsubscribeAssistant_unsubscribeFinalizedH2a = 'unsubscribeAssistant.unsubscribeFinalizedH2a',
  /** "This gives you limited access to load searching, rate check, truck posting, and any documents stored in your account. Your account will only be available through the mobile app. If you haven’t already, please download our mobile app to use your basic membership." */ unsubscribeAssistant_unsubscribeFinalizedMessage2 = 'unsubscribeAssistant.unsubscribeFinalizedMessage2',
  /** "Looking to upgrade your account, " */ unsubscribeAssistant_unsubscribeFinalizedMessageUpgrade = 'unsubscribeAssistant.unsubscribeFinalizedMessageUpgrade',
  /** "click here" */ unsubscribeAssistant_unsubscribeFinalizedMessageUpgradeLink = 'unsubscribeAssistant.unsubscribeFinalizedMessageUpgradeLink',
  /** "You have unsubscribed from your membership. You will continue to have access until {{date}} . If you wish to re-actvate your account, " */ unsubscribeAssistant_unsubscribeBannerMessage = 'unsubscribeAssistant.unsubscribeBannerMessage',
  /** "Increase load quantity" */ unsubscribeAssistant_radioIssue1 = 'unsubscribeAssistant.radioIssue1',
  /** "Higher paying loads" */ unsubscribeAssistant_radioIssue2 = 'unsubscribeAssistant.radioIssue2',
  /** "Accurate load info" */ unsubscribeAssistant_radioIssue3 = 'unsubscribeAssistant.radioIssue3',
  /** "Hot shot loads" */ unsubscribeAssistant_radioIssue4 = 'unsubscribeAssistant.radioIssue4',
  /** "Other" */ unsubscribeAssistant_radioIssue5 = 'unsubscribeAssistant.radioIssue5',
  /** "What can we improve that would make you come back?" */ unsubscribeAssistant_detailDifficultyTitle = 'unsubscribeAssistant.detailDifficultyTitle',
  /** "What features can we add that would make you come back?" */ unsubscribeAssistant_detailMissingTitle = 'unsubscribeAssistant.detailMissingTitle',
  /** "Why did you stop using the load board?" */ unsubscribeAssistant_detailNotUsingTitle = 'unsubscribeAssistant.detailNotUsingTitle',
  /** "Leased onto a company" */ unsubscribeAssistant_radioNotUsing1 = 'unsubscribeAssistant.radioNotUsing1',
  /** "Truck out of service" */ unsubscribeAssistant_radioNotUsing2 = 'unsubscribeAssistant.radioNotUsing2',
  /** "Going out of business" */ unsubscribeAssistant_radioNotUsing3 = 'unsubscribeAssistant.radioNotUsing3',
  /** "Retiring" */ unsubscribeAssistant_radioNotUsing4 = 'unsubscribeAssistant.radioNotUsing4',
  /** "Other" */ unsubscribeAssistant_radioNotUsing5 = 'unsubscribeAssistant.radioNotUsing5',
  /** "Where are you getting your loads now?" */ unsubscribeAssistant_detailSomethingElseTitle = 'unsubscribeAssistant.detailSomethingElseTitle',
  /** "DAT" */ unsubscribeAssistant_radioSomethingElse1 = 'unsubscribeAssistant.radioSomethingElse1',
  /** "Truckstop" */ unsubscribeAssistant_radioSomethingElse2 = 'unsubscribeAssistant.radioSomethingElse2',
  /** "Dispatcher" */ unsubscribeAssistant_radioSomethingElse3 = 'unsubscribeAssistant.radioSomethingElse3',
  /** "Brokers" */ unsubscribeAssistant_radioSomethingElse4 = 'unsubscribeAssistant.radioSomethingElse4',
  /** "Another load board" */ unsubscribeAssistant_radioSomethingElse5 = 'unsubscribeAssistant.radioSomethingElse5',
  /** "Other" */ unsubscribeAssistant_radioSomethingElse6 = 'unsubscribeAssistant.radioSomethingElse6',
  /** "Something went wrong - but don’t worry!" */ unsubscribeAssistant_unsubscribeFailedTitle = 'unsubscribeAssistant.unsubscribeFailedTitle',
  /** "There was a problem processing your request to unsubscribe. Our Customer Support team will unsubscribe the account for you and you will receive an email shortly confirming this." */ unsubscribeAssistant_unsubscribeFailedDescription = 'unsubscribeAssistant.unsubscribeFailedDescription',
  /** "If you have any questions or require further assistance, please contact our Customer Support team at " */ unsubscribeAssistant_unsubscribeFailedFurtherQuestions = 'unsubscribeAssistant.unsubscribeFailedFurtherQuestions',
  /** "Join free webinar" */ unsubscribeAssistant_joinFreeWebinar = 'unsubscribeAssistant.joinFreeWebinar',
  /** "Watch the video" */ unsubscribeAssistant_watchTheVideo = 'unsubscribeAssistant.watchTheVideo',
  /** "Open live chat" */ unsubscribeAssistant_openLiveChat = 'unsubscribeAssistant.openLiveChat',
  /** "Contact support" */ unsubscribeAssistant_contactSupport = 'unsubscribeAssistant.contactSupport',
  /** "Next" */ unsubscribeAssistant_buttonNext = 'unsubscribeAssistant.buttonNext',
  /** "Continue" */ unsubscribeAssistant_buttonContinue = 'unsubscribeAssistant.buttonContinue',
  /** "Don't unsubscribe" */ unsubscribeAssistant_buttonDontUnsubscribe = 'unsubscribeAssistant.buttonDontUnsubscribe',
  /** "Unsubscribe" */ unsubscribeAssistant_buttonUnsubscribe = 'unsubscribeAssistant.buttonUnsubscribe',
  /** "Submit" */ unsubscribeAssistant_buttonSubmit = 'unsubscribeAssistant.buttonSubmit',
  /** "OK" */ unsubscribeAssistant_buttonOK = 'unsubscribeAssistant.buttonOK',
  /** "Comment" */ unsubscribeAssistant_comment = 'unsubscribeAssistant.comment',
  /** "Phone" */ unsubscribeAssistant_phone = 'unsubscribeAssistant.phone',
  /** "Wait, don't leave!" */ unsubscribeAssistant_retentionPromo_title = 'unsubscribeAssistant.retentionPromo.title',
  /** "You're a very valuable member and we truly appreciate you being a part of 123Loadboard. Please give us another chance with a  " */ unsubscribeAssistant_retentionPromo_content = 'unsubscribeAssistant.retentionPromo.content',
  /** " on your membership for the next {{period}}." */ unsubscribeAssistant_retentionPromo_contentMore = 'unsubscribeAssistant.retentionPromo.contentMore',
  /** "{{amount}} monthly discount" */ unsubscribeAssistant_retentionPromo_monthlyDiscount = 'unsubscribeAssistant.retentionPromo.monthlyDiscount',
  /** "Accept {{amount}} discount" */ unsubscribeAssistant_retentionPromo_accept = 'unsubscribeAssistant.retentionPromo.accept',
  /** "Discount applied" */ unsubscribeAssistant_retentionPromo_applied = 'unsubscribeAssistant.retentionPromo.applied',
  /** "Thanks for staying!" */ unsubscribeAssistant_retentionPromo_thanksForStaying = 'unsubscribeAssistant.retentionPromo.thanksForStaying',
  /** "Accepting our offer and giving us another shot means a lot to us. Your " */ unsubscribeAssistant_retentionPromo_confirmationText = 'unsubscribeAssistant.retentionPromo.confirmationText',
  /** " has been applied to your membership for the next {{period}}." */ unsubscribeAssistant_retentionPromo_confirmationTextMore = 'unsubscribeAssistant.retentionPromo.confirmationTextMore',
  /** "{{amount}} discount" */ unsubscribeAssistant_retentionPromo_discount = 'unsubscribeAssistant.retentionPromo.discount',
  /** "Your discount is now activated and will apply to your next billing on {{billingDate}}." */ unsubscribeAssistant_retentionPromo_nextBillingText = 'unsubscribeAssistant.retentionPromo.nextBillingText',
  /** "Top reasons to keep your 123Loadboard membership" */ unsubscribeAssistant_reasonsToStay_title = 'unsubscribeAssistant.reasonsToStay.title',
  /** "Saves you hundreds of dollars per year compared to other boards, keeping money in your wallet." */ unsubscribeAssistant_reasonsToStay_reason1_text = 'unsubscribeAssistant.reasonsToStay.reason1.text',
  /** "Access to many " */ unsubscribeAssistant_reasonsToStay_reason2_text1 = 'unsubscribeAssistant.reasonsToStay.reason2.text1',
  /** "tools and services " */ unsubscribeAssistant_reasonsToStay_reason2_link = 'unsubscribeAssistant.reasonsToStay.reason2.link',
  /** "the other guys nickel-and-dime you for." */ unsubscribeAssistant_reasonsToStay_reason2_text2 = 'unsubscribeAssistant.reasonsToStay.reason2.text2',
  /** "Our " */ unsubscribeAssistant_reasonsToStay_reason3_text1 = 'unsubscribeAssistant.reasonsToStay.reason3.text1',
  /** "Customer Success Team " */ unsubscribeAssistant_reasonsToStay_reason3_link = 'unsubscribeAssistant.reasonsToStay.reason3.link',
  /** "helps members find the loads they are looking for." */ unsubscribeAssistant_reasonsToStay_reason3_text2 = 'unsubscribeAssistant.reasonsToStay.reason3.text2',
  /** "Would you like to " */ unsubscribeAssistant_reasonsToStay_continueUsing_text1 = 'unsubscribeAssistant.reasonsToStay.continueUsing.text1',
  /** "continue using " */ unsubscribeAssistant_reasonsToStay_continueUsing_link1 = 'unsubscribeAssistant.reasonsToStay.continueUsing.link1',
  /** "123Loadboard or would you still like to " */ unsubscribeAssistant_reasonsToStay_continueUsing_text2 = 'unsubscribeAssistant.reasonsToStay.continueUsing.text2',
  /** "unsubscribe" */ unsubscribeAssistant_reasonsToStay_continueUsing_link2 = 'unsubscribeAssistant.reasonsToStay.continueUsing.link2',
  /** "?" */ unsubscribeAssistant_reasonsToStay_continueUsing_text3 = 'unsubscribeAssistant.reasonsToStay.continueUsing.text3',
  /** "Manage subscription" */ unsubscribeAssistant_manageSubscription = 'unsubscribeAssistant.manageSubscription',
  /** "Your 123Loadboard account is managed through the App Store. To make changes to your subscription, please do so through the App Store settings on your Apple device settings." */ unsubscribeAssistant_applePopup = 'unsubscribeAssistant.applePopup',
  /** "Open settings" */ unsubscribeAssistant_openSettings = 'unsubscribeAssistant.openSettings',
  /** "Settings" */ settings_title = 'settings.title',
  /** "Account" */ settings_account_title = 'settings.account.title',
  /** "Profile" */ settings_account_profile = 'settings.account.profile',
  /** "Profile Updated" */ settings_account_updateProfile_snackBarSuccess = 'settings.account.updateProfile.snackBarSuccess',
  /** "Profile failed to update" */ settings_account_updateProfile_snackBarFailure = 'settings.account.updateProfile.snackBarFailure',
  /** "Edit business profile" */ settings_account_editBusinessProfile = 'settings.account.editBusinessProfile',
  /** "Edit my profile" */ settings_account_editMyProfile = 'settings.account.editMyProfile',
  /** "Company" */ settings_account_company = 'settings.account.company',
  /** "Users" */ settings_account_users = 'settings.account.users',
  /** "Posting contact" */ settings_account_postingContact = 'settings.account.postingContact',
  /** "Posting contacts" */ settings_account_postingContacts = 'settings.account.postingContacts',
  /** "Add dispatcher" */ settings_account_dispatchers_addDispatcher = 'settings.account.dispatchers.addDispatcher',
  /** "No dispatcher on your account" */ settings_account_dispatchers_noDispatcher = 'settings.account.dispatchers.noDispatcher',
  /** "You have no dispatcher contact details assigned. Please add some to your account." */ settings_account_dispatchers_description = 'settings.account.dispatchers.description',
  /** "Info" */ settings_account_dispatchers_info = 'settings.account.dispatchers.info',
  /** "If you wish to update your Dispatcher contact info, please call " */ settings_account_dispatchers_updateDispatcher = 'settings.account.dispatchers.updateDispatcher',
  /** "Type" */ settings_account_type = 'settings.account.type',
  /** "All" */ settings_account_types_all = 'settings.account.types.all',
  /** "Admin" */ settings_account_types_admin = 'settings.account.types.admin',
  /** "User" */ settings_account_types_user = 'settings.account.types.user',
  /** "Add User" */ settings_account_user_addUser = 'settings.account.user.addUser',
  /** "No users on your account" */ settings_account_user_noUser = 'settings.account.user.noUser',
  /** "You have no user contact details assigned. Please add some to your account." */ settings_account_user_description = 'settings.account.user.description',
  /** "Send request" */ settings_account_user_sendRequest = 'settings.account.user.sendRequest',
  /** "Delete user" */ settings_account_user_deleteUser_title = 'settings.account.user.deleteUser.title',
  /** "Are you sure you want to delete this user?" */ settings_account_user_deleteUser_message = 'settings.account.user.deleteUser.message',
  /** "You cannot delete your own user profile. Please call (877) 875-5301 for assistance." */ settings_account_user_deleteUser_messageCanNotDelete = 'settings.account.user.deleteUser.messageCanNotDelete',
  /** "Edit User" */ settings_account_user_editUser = 'settings.account.user.editUser',
  /** "User Updated" */ settings_account_user_updateUser_snackBarSuccess = 'settings.account.user.updateUser.snackBarSuccess',
  /** "User failed to update" */ settings_account_user_updateUser_snackBarFailure = 'settings.account.user.updateUser.snackBarFailure',
  /** "Please contact your administrator to make changes to this account" */ settings_account_user_updateUser_description = 'settings.account.user.updateUser.description',
  /** "Type: {{value}}" */ settings_account_user_type = 'settings.account.user.type',
  /** "Request sent" */ settings_account_user_requestToAddUser_title = 'settings.account.user.requestToAddUser.title',
  /** "We have received your request to add a user to your account. You will receive a confirmation email when the user is added." */ settings_account_user_requestToAddUser_message = 'settings.account.user.requestToAddUser.message',
  /** "Last login: {{date}}" */ settings_account_user_lastLogin = 'settings.account.user.lastLogin',
  /** "Company Updated" */ settings_account_companyPanel_snackBarSuccess = 'settings.account.companyPanel.snackBarSuccess',
  /** "Company failed to update" */ settings_account_companyPanel_snackBarFailure = 'settings.account.companyPanel.snackBarFailure',
  /** "DOT#" */ settings_businessProfile_dot = 'settings.businessProfile.dot',
  /** "Business profile" */ settings_businessProfile_title = 'settings.businessProfile.title',
  /** "Address:" */ settings_businessProfile_addressTitle = 'settings.businessProfile.addressTitle',
  /** "Company phone:" */ settings_businessProfile_companyPhoneTitle = 'settings.businessProfile.companyPhoneTitle',
  /** "Company name:" */ settings_businessProfile_companyNameTitle = 'settings.businessProfile.companyNameTitle',
  /** "DOT#:" */ settings_businessProfile_dotNumberTitle = 'settings.businessProfile.dotNumberTitle',
  /** "MC#:" */ settings_businessProfile_mcNumberTitle = 'settings.businessProfile.mcNumberTitle',
  /** "Onboarding link:" */ settings_businessProfile_onboardingUrl_title = 'settings.businessProfile.onboardingUrl.title',
  /** "Add link" */ settings_businessProfile_onboardingUrl_buttonLabelAdd = 'settings.businessProfile.onboardingUrl.buttonLabelAdd',
  /** "Edit link" */ settings_businessProfile_onboardingUrl_buttonLabelEdit = 'settings.businessProfile.onboardingUrl.buttonLabelEdit',
  /** "Edit onboarded carriers" */ settings_businessProfile_onboardingUrl_buttonLabelEditOnboarded = 'settings.businessProfile.onboardingUrl.buttonLabelEditOnboarded',
  /** "Add onboarding link" */ settings_businessProfile_onboardingUrl_popupTitleAdd = 'settings.businessProfile.onboardingUrl.popupTitleAdd',
  /** "Edit onboarding link" */ settings_businessProfile_onboardingUrl_popupTitleEdit = 'settings.businessProfile.onboardingUrl.popupTitleEdit',
  /** "Please enter your carrier onboarding link. This link will be available on all of your posted loads for carriers not on your onboarded carrier list." */ settings_businessProfile_onboardingUrl_popupContent = 'settings.businessProfile.onboardingUrl.popupContent',
  /** "Onboarding link *" */ settings_businessProfile_onboardingUrl_fieldLabel = 'settings.businessProfile.onboardingUrl.fieldLabel',
  /** "Link added" */ settings_businessProfile_onboardingUrl_linkAdded = 'settings.businessProfile.onboardingUrl.linkAdded',
  /** "Onboarding link successfully uploaded" */ settings_businessProfile_onboardingUrl_messages_success = 'settings.businessProfile.onboardingUrl.messages.success',
  /** "Oops. Something went wrong" */ settings_businessProfile_onboardingUrl_messages_failure = 'settings.businessProfile.onboardingUrl.messages.failure',
  /** "Use FMCSA company details" */ settings_businessProfile_dotBusinessProfileOption_noDot_title = 'settings.businessProfile.dotBusinessProfileOption.noDot.title',
  /** "Please enter your DOT# above to activate FMCSA company details." */ settings_businessProfile_dotBusinessProfileOption_noDot_text = 'settings.businessProfile.dotBusinessProfileOption.noDot.text',
  /** "Use FMCSA company details" */ settings_businessProfile_dotBusinessProfileOption_noInformationAvailable_title = 'settings.businessProfile.dotBusinessProfileOption.noInformationAvailable.title',
  /** "We were unable to find information for the entered DOT#." */ settings_businessProfile_dotBusinessProfileOption_noInformationAvailable_text = 'settings.businessProfile.dotBusinessProfileOption.noInformationAvailable.text',
  /** "Use custom company details" */ settings_businessProfile_dotBusinessProfileOption_customProfile_title = 'settings.businessProfile.dotBusinessProfileOption.customProfile.title',
  /** "Use FMCSA company details" */ settings_businessProfile_dotBusinessProfileOption_volpeProfile_title = 'settings.businessProfile.dotBusinessProfileOption.volpeProfile.title',
  /** "DOT# verification" */ settings_dotVerification_title = 'settings.dotVerification.title',
  /** "DOT# verification: Confirms user's access to the FMCSA-listed company phone number." */ settings_dotVerification_tooltip = 'settings.dotVerification.tooltip',
  /** "Carrier DOT# not verified. Consider this when reaching out." */ settings_dotVerification_carrierNotVerified = 'settings.dotVerification.carrierNotVerified',
  /** "Verify your DOT# " */ settings_dotVerification_verifyYourDot = 'settings.dotVerification.verifyYourDot',
  /** " for better opportunities with brokers and shippers." */ settings_dotVerification_betterOpportunities = 'settings.dotVerification.betterOpportunities',
  /** "DOT# verified" */ settings_dotVerification_dotNumberVerified = 'settings.dotVerification.dotNumberVerified',
  /** "Verify DOT#" */ settings_dotVerification_verifyDotNumber = 'settings.dotVerification.verifyDotNumber',
  /** "Verify your DOT#" */ settings_dotVerification_verifyYourDotNumber = 'settings.dotVerification.verifyYourDotNumber',
  /** "Please enter DOT#." */ settings_dotVerification_pleaseEnterDot = 'settings.dotVerification.pleaseEnterDot',
  /** "Brokers and shippers prefer to work with Carriers that have verified their DOT#. You can verify your DOT# via phone confirmation. Please select a phone number that you have access to." */ settings_dotVerification_phoneListPanelText = 'settings.dotVerification.phoneListPanelText',
  /** "Select phone number" */ settings_dotVerification_phoneListPanelSelectNumber = 'settings.dotVerification.phoneListPanelSelectNumber',
  /** "You can verify your DOT# with a confirmation code that will be sent to" */ settings_dotVerification_verificationCodeWillBeSentTo = 'settings.dotVerification.verificationCodeWillBeSentTo',
  /** "Please select your preferred activation method." */ settings_dotVerification_selectActivationMethod = 'settings.dotVerification.selectActivationMethod',
  /** "Select verification method" */ settings_dotVerification_selectVerificationMethodTitle = 'settings.dotVerification.selectVerificationMethodTitle',
  /** "Enter code" */ settings_dotVerification_enterCode = 'settings.dotVerification.enterCode',
  /** "Verification error" */ settings_dotVerification_errorPanel_title = 'settings.dotVerification.errorPanel.title',
  /** "We were unable to find information for the entered DOT#:" */ settings_dotVerification_errorPanel_unableToFindByDot = 'settings.dotVerification.errorPanel.unableToFindByDot',
  /** "Please make sure your DOT# is entered correctly or try again later." */ settings_dotVerification_errorPanel_makeSureDotEntered = 'settings.dotVerification.errorPanel.makeSureDotEntered',
  /** "Your DOT number has been successfully verified." */ settings_dotVerification_resultPanel_successfullyVerified = 'settings.dotVerification.resultPanel.successfullyVerified',
  /** "Billing" */ settings_billing_title = 'settings.billing.title',
  /** "Billing Summary" */ settings_billing_billingSummary = 'settings.billing.billingSummary',
  /** "Billing cycle" */ settings_billing_summary_billingCycle = 'settings.billing.summary.billingCycle',
  /** "Next billing date" */ settings_billing_summary_nextBillingDate = 'settings.billing.summary.nextBillingDate',
  /** "Membership fee" */ settings_billing_summary_membershipFee = 'settings.billing.summary.membershipFee',
  /** "Billed by" */ settings_billing_summary_membershipBilledBy = 'settings.billing.summary.membershipBilledBy',
  /** "App Store" */ settings_billing_summary_membershipBilledByAppStore = 'settings.billing.summary.membershipBilledByAppStore',
  /** "Add-ons" */ settings_billing_summary_addOn = 'settings.billing.summary.addOn',
  /** "Current membership cost" */ settings_billing_summary_currentCost = 'settings.billing.summary.currentCost',
  /** "Monthly" */ settings_billing_summary_monthly = 'settings.billing.summary.monthly',
  /** "Yearly" */ settings_billing_summary_yearly = 'settings.billing.summary.yearly',
  /** "Payment history" */ settings_billing_paymentHistory = 'settings.billing.paymentHistory',
  /** "Filter by dates" */ settings_billing_datesFilterTitle = 'settings.billing.datesFilterTitle',
  /** "Invoice {{invoiceNo}}" */ settings_billing_invoiceTitle = 'settings.billing.invoiceTitle',
  /** "Payment method" */ settings_billing_paymentMethod = 'settings.billing.paymentMethod',
  /** "No invoices" */ settings_billing_invoicesNotFound = 'settings.billing.invoicesNotFound',
  /** "Edit payment method" */ settings_billing_editPaymentMethod = 'settings.billing.editPaymentMethod',
  /** "You don't have the necessary permissions to make changes." */ settings_billing_membershipPlan_noPermissions = 'settings.billing.membershipPlan.noPermissions',
  /** "Please contact the account owner" */ settings_billing_membershipPlan_pleaseContactAccountOwner = 'settings.billing.membershipPlan.pleaseContactAccountOwner',
  /** "to request changes to this membership." */ settings_billing_membershipPlan_changesToMembership = 'settings.billing.membershipPlan.changesToMembership',
  /** "You don't have necessary permissions to edit payment method. Please contact the account owner" */ settings_billing_membershipPlan_noPermissionsToEditPaymentMethod = 'settings.billing.membershipPlan.noPermissionsToEditPaymentMethod',
  /** "to request changes." */ settings_billing_membershipPlan_requestChanges = 'settings.billing.membershipPlan.requestChanges',
  /** "If you require additional assistance, please contact Support at" */ settings_billing_membershipPlan_ifYouRequireAssistance = 'settings.billing.membershipPlan.ifYouRequireAssistance',
  /** "877-875-5301" */ settings_billing_membershipPlan_phoneNumber = 'settings.billing.membershipPlan.phoneNumber',
  /** "(Mon-Fri 8am-6pm EST)." */ settings_billing_membershipPlan_workTime = 'settings.billing.membershipPlan.workTime',
  /** "Payment failure" */ settings_billing_paymentDeclineNotice_title = 'settings.billing.paymentDeclineNotice.title',
  /** "We were unable to process payments for an overdue invoice on your account. Please make a payment to keep full access to your account." */ settings_billing_paymentDeclineNotice_expiringSoon = 'settings.billing.paymentDeclineNotice.expiringSoon',
  /** "We were unable to process payments for an overdue invoice on your account. Please make a payment within the next " */ settings_billing_paymentDeclineNotice_nearingExpiration = 'settings.billing.paymentDeclineNotice.nearingExpiration',
  /** "to keep full access to your account." */ settings_billing_paymentDeclineNotice_nearingExpirationSecond = 'settings.billing.paymentDeclineNotice.nearingExpirationSecond',
  /** "We were unable to process payments for an overdue invoice on your account. Your membership is currently suspended. Please provide a new form of payment to restore full access to your account." */ settings_billing_paymentDeclineNotice_expired = 'settings.billing.paymentDeclineNotice.expired',
  /** "Your card ending in {{card}} cannot be processed. Please replace card or correct card information." */ settings_billing_paymentDeclineNotice_hardDecline = 'settings.billing.paymentDeclineNotice.hardDecline',
  /** "There was an issue processing your card ending in {{card}}. Please make sure that the card has funds available or provide a new payment method. We will reattempt the transaction at a later date." */ settings_billing_paymentDeclineNotice_softDecline = 'settings.billing.paymentDeclineNotice.softDecline',
  /** "Preferences" */ settings_preferences_title = 'settings.preferences.title',
  /** "Load visibility rules" */ settings_preferences_loadVisibilityRules = 'settings.preferences.loadVisibilityRules',
  /** "Notifications" */ settings_preferences_notifications = 'settings.preferences.notifications',
  /** "Select the emails that you wish to receive." */ settings_preferences_emailNotificationSelection = 'settings.preferences.emailNotificationSelection',
  /** "Alerts sound" */ settings_preferences_alertsSound_title = 'settings.preferences.alertsSound.title',
  /** "Choose which alerts play a sound when new content arrives." */ settings_preferences_alertsSound_description = 'settings.preferences.alertsSound.description',
  /** "Message alerts" */ settings_preferences_alertsSound_options_messageAlerts = 'settings.preferences.alertsSound.options.messageAlerts',
  /** "Alerts sound was updated" */ settings_preferences_alertsSound_updateSuccess = 'settings.preferences.alertsSound.updateSuccess',
  /** "Alerts sound was not updated" */ settings_preferences_alertsSound_updateFail = 'settings.preferences.alertsSound.updateFail',
  /** "Messages" */ settings_preferences_messages_title = 'settings.preferences.messages.title',
  /** "Browser notifications settings" */ settings_preferences_messages_browserNotification = 'settings.preferences.messages.browserNotification',
  /** "Disabling notifications" */ settings_preferences_messages_disablingTitle = 'settings.preferences.messages.disablingTitle',
  /** "In order to turn off notifications, please " */ settings_preferences_messages_disablingMessage = 'settings.preferences.messages.disablingMessage',
  /** " for browser-specific instructions." */ settings_preferences_messages_disablingMessageEnd = 'settings.preferences.messages.disablingMessageEnd',
  /** "Updates" */ settings_preferences_updates_title = 'settings.preferences.updates.title',
  /** "Announcements (new features, etc)" */ settings_preferences_updates_announcements = 'settings.preferences.updates.announcements',
  /** "Newsletters (members news, etc)" */ settings_preferences_updates_newsletters = 'settings.preferences.updates.newsletters',
  /** "Promotions (offers, discounts, etc)" */ settings_preferences_updates_promotions = 'settings.preferences.updates.promotions',
  /** "Notifications updated successfully" */ settings_preferences_updates_snackBarSuccess = 'settings.preferences.updates.snackBarSuccess',
  /** "Notifications failed to update" */ settings_preferences_updates_snackBarFailure = 'settings.preferences.updates.snackBarFailure',
  /** "Alerts/Leads" */ settings_preferences_alertsLeads_title = 'settings.preferences.alertsLeads.title',
  /** "Instant load/truck matching" */ settings_preferences_alertsLeads_loadTruckMatching = 'settings.preferences.alertsLeads.loadTruckMatching',
  /** "Mileage and routing" */ settings_preferences_mileageAndRouting_title = 'settings.preferences.mileageAndRouting.title',
  /** "Basic Options" */ settings_preferences_mileageAndRouting_basic = 'settings.preferences.mileageAndRouting.basic',
  /** "Advanced options" */ settings_preferences_mileageAndRouting_advance = 'settings.preferences.mileageAndRouting.advance',
  /** "Vehicle dimension" */ settings_preferences_mileageAndRouting_vehicleDimension = 'settings.preferences.mileageAndRouting.vehicleDimension',
  /** "Apply and save settings" */ settings_preferences_mileageAndRouting_saveSettings = 'settings.preferences.mileageAndRouting.saveSettings',
  /** "Updated successfully" */ settings_preferences_mileageAndRouting_success = 'settings.preferences.mileageAndRouting.success',
  /** "Failed to update" */ settings_preferences_mileageAndRouting_error = 'settings.preferences.mileageAndRouting.error',
  /** "Route method" */ settings_preferences_mileageAndRouting_routeMethod_title = 'settings.preferences.mileageAndRouting.routeMethod.title',
  /** "Shortest" */ settings_preferences_mileageAndRouting_routeMethod_shortest = 'settings.preferences.mileageAndRouting.routeMethod.shortest',
  /** "Practical" */ settings_preferences_mileageAndRouting_routeMethod_practical = 'settings.preferences.mileageAndRouting.routeMethod.practical',
  /** "Tolls" */ settings_preferences_mileageAndRouting_tolls_title = 'settings.preferences.mileageAndRouting.tolls.title',
  /** "Avoid tolls (if possible)" */ settings_preferences_mileageAndRouting_tolls_avoid = 'settings.preferences.mileageAndRouting.tolls.avoid',
  /** "Tolls can be included" */ settings_preferences_mileageAndRouting_tolls_included = 'settings.preferences.mileageAndRouting.tolls.included',
  /** "Toll costs" */ settings_preferences_mileageAndRouting_tolls_tollCost = 'settings.preferences.mileageAndRouting.tolls.tollCost',
  /** "By axles" */ settings_preferences_mileageAndRouting_tolls_axles = 'settings.preferences.mileageAndRouting.tolls.axles',
  /** "By weight" */ settings_preferences_mileageAndRouting_tolls_weight = 'settings.preferences.mileageAndRouting.tolls.weight',
  /** "Null" */ settings_preferences_mileageAndRouting_tolls_null = 'settings.preferences.mileageAndRouting.tolls.null',
  /** "Map detail level" */ settings_preferences_mileageAndRouting_mapDetail_title = 'settings.preferences.mileageAndRouting.mapDetail.title',
  /** "Legend on the map" */ settings_preferences_mileageAndRouting_legend_title = 'settings.preferences.mileageAndRouting.legend.title',
  /** "Show legend" */ settings_preferences_mileageAndRouting_legend_show = 'settings.preferences.mileageAndRouting.legend.show',
  /** "Don't show legend" */ settings_preferences_mileageAndRouting_legend_hide = 'settings.preferences.mileageAndRouting.legend.hide',
  /** "Units" */ settings_preferences_mileageAndRouting_units_title = 'settings.preferences.mileageAndRouting.units.title',
  /** "English" */ settings_preferences_mileageAndRouting_units_english = 'settings.preferences.mileageAndRouting.units.english',
  /** "Metric" */ settings_preferences_mileageAndRouting_units_metric = 'settings.preferences.mileageAndRouting.units.metric',
  /** "Height" */ settings_preferences_mileageAndRouting_dimension_height = 'settings.preferences.mileageAndRouting.dimension.height',
  /** "Length" */ settings_preferences_mileageAndRouting_dimension_length = 'settings.preferences.mileageAndRouting.dimension.length',
  /** "Feet" */ settings_preferences_mileageAndRouting_dimension_feet = 'settings.preferences.mileageAndRouting.dimension.feet',
  /** "Inches" */ settings_preferences_mileageAndRouting_dimension_inch = 'settings.preferences.mileageAndRouting.dimension.inch',
  /** "Meters" */ settings_preferences_mileageAndRouting_dimension_meters = 'settings.preferences.mileageAndRouting.dimension.meters',
  /** "Invalid height" */ settings_preferences_mileageAndRouting_dimension_invalidHeight = 'settings.preferences.mileageAndRouting.dimension.invalidHeight',
  /** "Invalid length" */ settings_preferences_mileageAndRouting_dimension_invalidLength = 'settings.preferences.mileageAndRouting.dimension.invalidLength',
  /** "Width in Meters" */ settings_preferences_mileageAndRouting_dimension_widthMeter = 'settings.preferences.mileageAndRouting.dimension.widthMeter',
  /** "Width in Inches" */ settings_preferences_mileageAndRouting_dimension_widthInch = 'settings.preferences.mileageAndRouting.dimension.widthInch',
  /** "2.44 or Less" */ settings_preferences_mileageAndRouting_dimension_meterOrLess = 'settings.preferences.mileageAndRouting.dimension.meterOrLess',
  /** "2.59 or More" */ settings_preferences_mileageAndRouting_dimension_meterOrMore = 'settings.preferences.mileageAndRouting.dimension.meterOrMore',
  /** "96\" or Less" */ settings_preferences_mileageAndRouting_dimension_inchOrLess = 'settings.preferences.mileageAndRouting.dimension.inchOrLess',
  /** "102\" or More" */ settings_preferences_mileageAndRouting_dimension_inchOrMore = 'settings.preferences.mileageAndRouting.dimension.inchOrMore',
  /** "Weight in Lbs" */ settings_preferences_mileageAndRouting_dimension_weightLbs = 'settings.preferences.mileageAndRouting.dimension.weightLbs',
  /** "Weight in Kgs" */ settings_preferences_mileageAndRouting_dimension_weightKgs = 'settings.preferences.mileageAndRouting.dimension.weightKgs',
  /** "Hazmat" */ settings_preferences_mileageAndRouting_hazmat_title = 'settings.preferences.mileageAndRouting.hazmat.title',
  /** "Hazmat type" */ settings_preferences_mileageAndRouting_hazmat_type = 'settings.preferences.mileageAndRouting.hazmat.type',
  /** "General" */ settings_preferences_mileageAndRouting_hazmat_general = 'settings.preferences.mileageAndRouting.hazmat.general',
  /** "Caustic" */ settings_preferences_mileageAndRouting_hazmat_caustic = 'settings.preferences.mileageAndRouting.hazmat.caustic',
  /** "Explosives" */ settings_preferences_mileageAndRouting_hazmat_explosive = 'settings.preferences.mileageAndRouting.hazmat.explosive',
  /** "None" */ settings_preferences_mileageAndRouting_hazmat_none = 'settings.preferences.mileageAndRouting.hazmat.none',
  /** "Flammable" */ settings_preferences_mileageAndRouting_hazmat_flammable = 'settings.preferences.mileageAndRouting.hazmat.flammable',
  /** "Inhalants" */ settings_preferences_mileageAndRouting_hazmat_inhalants = 'settings.preferences.mileageAndRouting.hazmat.inhalants',
  /** "Radioactive" */ settings_preferences_mileageAndRouting_hazmat_radioactive = 'settings.preferences.mileageAndRouting.hazmat.radioactive',
  /** "International" */ settings_preferences_mileageAndRouting_border_international = 'settings.preferences.mileageAndRouting.border.international',
  /** "Cross-over borders" */ settings_preferences_mileageAndRouting_border_border = 'settings.preferences.mileageAndRouting.border.border',
  /** "Search results" */ settings_preferences_searchResults_title = 'settings.preferences.searchResults.title',
  /** "Rate Check" */ settings_preferences_searchResults_averageRateHeader = 'settings.preferences.searchResults.averageRateHeader',
  /** "RATE CHECK" */ settings_preferences_searchResults_averageRateLabel = 'settings.preferences.searchResults.averageRateLabel',
  /** "Display average rate information on load results" */ settings_preferences_searchResults_checkboxLabel = 'settings.preferences.searchResults.checkboxLabel',
  /** "Rate Check setting updated successfully" */ settings_preferences_searchResults_snackBarSuccess = 'settings.preferences.searchResults.snackBarSuccess',
  /** "Rate Check setting failed to update" */ settings_preferences_searchResults_snackBarFailure = 'settings.preferences.searchResults.snackBarFailure',
  /** "Manage fuel mileage (MPG)" */ settings_preferences_manageFuelMileage = 'settings.preferences.manageFuelMileage',
  /** "Enter your MPG for more accurate mileage and income estimates" */ settings_preferences_mpg_description = 'settings.preferences.mpg.description',
  /** "Your MPG was updated successfully" */ settings_preferences_mpg_snackBarSuccess = 'settings.preferences.mpg.snackBarSuccess',
  /** "Your MPG failed to update" */ settings_preferences_mpg_snackBarFailure = 'settings.preferences.mpg.snackBarFailure',
  /** "MPG" */ settings_preferences_mpg_title = 'settings.preferences.mpg.title',
  /** "Privacy control" */ settings_preferences_privacyControl = 'settings.preferences.privacyControl',
  /** "Exporting load details" */ settings_preferences_exportingLoadDetails_title = 'settings.preferences.exportingLoadDetails.title',
  /** "Export load details" */ settings_preferences_exportingLoadDetails_exportLoadDetails = 'settings.preferences.exportingLoadDetails.exportLoadDetails',
  /** "Select your exporting options" */ settings_preferences_exportingLoadDetails_description = 'settings.preferences.exportingLoadDetails.description',
  /** "COTA" */ settings_preferences_exportingLoadDetails_cota = 'settings.preferences.exportingLoadDetails.cota',
  /** "Export" */ settings_preferences_exportingLoadDetails_export = 'settings.preferences.exportingLoadDetails.export',
  /** "Allow load exporting to COTA" */ settings_preferences_exportingLoadDetails_exportingToCota = 'settings.preferences.exportingLoadDetails.exportingToCota',
  /** "COTA is a dispatching software that helps you manage your load from pickup to delivery and includes features like tracking and invoicing." */ settings_preferences_exportingLoadDetails_popUpCota_part1 = 'settings.preferences.exportingLoadDetails.popUpCota.part1',
  /** "Please visit " */ settings_preferences_exportingLoadDetails_popUpCota_part2 = 'settings.preferences.exportingLoadDetails.popUpCota.part2',
  /** "www.cotasystem.com" */ settings_preferences_exportingLoadDetails_popUpCota_part3 = 'settings.preferences.exportingLoadDetails.popUpCota.part3',
  /** " for more information." */ settings_preferences_exportingLoadDetails_popUpCota_part4 = 'settings.preferences.exportingLoadDetails.popUpCota.part4',
  /** "To learn more about how 123Loadboard works with COTA, please visit our " */ settings_preferences_exportingLoadDetails_popUpCota_part5 = 'settings.preferences.exportingLoadDetails.popUpCota.part5',
  /** "FAQ." */ settings_preferences_exportingLoadDetails_popUpCota_part6 = 'settings.preferences.exportingLoadDetails.popUpCota.part6',
  /** "Exporting load details settings updated successfully" */ settings_preferences_exportingLoadDetails_snackBarSuccess = 'settings.preferences.exportingLoadDetails.snackBarSuccess',
  /** "Exporting load details settings failed to update" */ settings_preferences_exportingLoadDetails_snackBarFailure = 'settings.preferences.exportingLoadDetails.snackBarFailure',
  /** "Hot leads" */ settings_preferences_hotLeads_title = 'settings.preferences.hotLeads.title',
  /** "Hot leads updated successfully" */ settings_preferences_hotLeads_snackBarSuccess = 'settings.preferences.hotLeads.snackBarSuccess',
  /** "Hot leads failed to update" */ settings_preferences_hotLeads_snackBarFailure = 'settings.preferences.hotLeads.snackBarFailure',
  /** "Legal" */ settings_legal_title = 'settings.legal.title',
  /** "Terms of service" */ settings_legal_termsOfService = 'settings.legal.termsOfService',
  /** "Privacy policy" */ settings_legal_privacyPolicy = 'settings.legal.privacyPolicy',
  /** "Open source licenses" */ settings_legal_openSourceLicenses = 'settings.legal.openSourceLicenses',
  /** "Receive calls from brokers and shippers based on your search data" */ settings_receiveHotLeadCalls = 'settings.receiveHotLeadCalls',
  /** "Posting contact" */ settings_postingContacts_postingContact = 'settings.postingContacts.postingContact',
  /** "Posting contacts" */ settings_postingContacts_postingContacts = 'settings.postingContacts.postingContacts',
  /** "Add contact" */ settings_postingContacts_addContact = 'settings.postingContacts.addContact',
  /** "Edit contact" */ settings_postingContacts_editContact = 'settings.postingContacts.editContact',
  /** "Add new" */ settings_postingContacts_addNew = 'settings.postingContacts.addNew',
  /** "Posting contact info" */ settings_postingContacts_postingContactInfo = 'settings.postingContacts.postingContactInfo',
  /** "Default" */ settings_postingContacts_default = 'settings.postingContacts.default',
  /** "Please contact our Posting department" */ settings_postingContacts_pleaseContactPostingDepartment = 'settings.postingContacts.pleaseContactPostingDepartment',
  /** "to make changes to the default posting contact." */ settings_postingContacts_toMakeChangesToDefaultContact = 'settings.postingContacts.toMakeChangesToDefaultContact',
  /** "Manage your posting contacts" */ settings_postingContacts_manageYourPostingContacts = 'settings.postingContacts.manageYourPostingContacts',
  /** "877-875-5301 ext.2" */ settings_postingContacts_postingDepartmentPhone = 'settings.postingContacts.postingDepartmentPhone',
  /** "Delete Posting Contact" */ settings_postingContacts_popups_delete_title = 'settings.postingContacts.popups.delete.title',
  /** "Are you sure that you want to delete this posting contact?" */ settings_postingContacts_popups_delete_warning = 'settings.postingContacts.popups.delete.warning',
  /** "Manage posting contacts" */ settings_postingContacts_popups_manageContacts_title = 'settings.postingContacts.popups.manageContacts.title',
  /** "Posting Contacts are the point-of-contact for carriers inquiring about your posted loads. This section allows you to quickly create, edit, and assign specific Posting Contacts to loads." */ settings_postingContacts_popups_manageContacts_content = 'settings.postingContacts.popups.manageContacts.content',
  /** "Any changes to Posting Contacts do not apply to loads already posted." */ settings_postingContacts_popups_manageContacts_warning = 'settings.postingContacts.popups.manageContacts.warning',
  /** "Posting contact added" */ settings_postingContacts_snackbars_contactAdded = 'settings.postingContacts.snackbars.contactAdded',
  /** "Posting contact updated" */ settings_postingContacts_snackbars_contactUpdated = 'settings.postingContacts.snackbars.contactUpdated',
  /** "Posting contact deleted" */ settings_postingContacts_snackbars_contactDeleted = 'settings.postingContacts.snackbars.contactDeleted',
  /** "No client applications" */ intg_clients_empty = 'intg.clients.empty',
  /** "The client application list is currently empty. To create a client application, please contact the integrations team at integrations@123loadboard.com" */ intg_clients_emptyParagraph = 'intg.clients.emptyParagraph',
  /** "Client not found" */ intg_client_notFound = 'intg.client.notFound',
  /** "Remove logo" */ intg_client_logo_popupTitle = 'intg.client.logo.popupTitle',
  /** "Are you sure you want to remove your logo?" */ intg_client_logo_popupContent = 'intg.client.logo.popupContent',
  /** "Client Logo Info" */ intg_client_logo_infoTitle = 'intg.client.logo.infoTitle',
  /** "The client logo will be displayed to your customers when they try to login to our platform through your application. Click on the 'Try it out!' button to see this page." */ intg_client_logo_infoText1 = 'intg.client.logo.infoText1',
  /** "The logo will be cropped to 4:3 and should have a width around 1024px." */ intg_client_logo_infoText2 = 'intg.client.logo.infoText2',
  /** "Client Name Info" */ intg_client_nameInfoTitle = 'intg.client.nameInfoTitle',
  /** "The client name will be displayed to your customers when they try to login to our platform through your application." */ intg_client_nameInfoText1 = 'intg.client.nameInfoText1',
  /** "Please fill out the field below to edit this client application name." */ intg_client_nameFieldPrompt = 'intg.client.nameFieldPrompt',
  /** "Client application name" */ intg_client_nameTitle = 'intg.client.nameTitle',
  /** "Client name saved" */ intg_client_nameSaved = 'intg.client.nameSaved',
  /** "Invalid client name" */ intg_client_invalidName = 'intg.client.invalidName',
  /** "Add or remove your redirect URI(s)" */ intg_client_manageURIsPrompt = 'intg.client.manageURIsPrompt',
  /** "Delete redirect URI" */ intg_client_removeURILabel = 'intg.client.removeURILabel',
  /** "Are you sure you want to delete this redirect URI?" */ intg_client_removeURIPrompt = 'intg.client.removeURIPrompt',
  /** "URI removed" */ intg_client_uriRemoved = 'intg.client.uriRemoved',
  /** "You cannot delete the only URI. Please add a new one if you want to delete this one." */ intg_client_cannotRemoveLastURI = 'intg.client.cannotRemoveLastURI',
  /** "Please enter the redirect URI you would like to add" */ intg_client_uriFieldPrompt = 'intg.client.uriFieldPrompt',
  /** "URI path" */ intg_client_uriFieldLabel = 'intg.client.uriFieldLabel',
  /** "New URI added" */ intg_client_uriAdded = 'intg.client.uriAdded',
  /** "Invalid redirect URI" */ intg_client_invalidURI = 'intg.client.invalidURI',
  /** "Regenerate credentials" */ intg_client_regenerateLabel = 'intg.client.regenerateLabel',
  /** "Are you sure you want to regenerate your credentials?" */ intg_client_regneratePrompt = 'intg.client.regneratePrompt',
  /** "Client credentials regenerated" */ intg_client_regenerateSuccess = 'intg.client.regenerateSuccess',
  /** "Client secret copied to clipboard" */ intg_client_secretCopied = 'intg.client.secretCopied',
  /** "Account" */ menu_account_title = 'menu.account.title',
  /** "Sign in" */ menu_account_signin = 'menu.account.signin',
  /** "Sign up" */ menu_account_register = 'menu.account.register',
  /** "Carrier" */ menu_carrier = 'menu.carrier',
  /** "Broker/Shipper" */ menu_broker = 'menu.broker',
  /** "Broker \n Shipper" */ menu_brokerSmall = 'menu.brokerSmall',
  /** "Integrator" */ menu_integrator = 'menu.integrator',
  /** "Companies" */ menu_companies_myCompanies = 'menu.companies.myCompanies',
  /** "Main" */ menu_main_title = 'menu.main.title',
  /** "Dashboard" */ menu_main_dashboard = 'menu.main.dashboard',
  /** "Alerts" */ menu_main_alerts = 'menu.main.alerts',
  /** "Messages" */ menu_main_messages = 'menu.main.messages',
  /** "Clients" */ menu_environments_title = 'menu.environments.title',
  /** "Dev" */ menu_environments_dev = 'menu.environments.dev',
  /** "Live" */ menu_environments_live = 'menu.environments.live',
  /** "Loads" */ menu_loads_title = 'menu.loads.title',
  /** "Find loads" */ menu_loads_findLoads = 'menu.loads.findLoads',
  /** "Load availability" */ menu_loads_loadAvailability = 'menu.loads.loadAvailability',
  /** "Trip builder" */ menu_loads_tripBuilder = 'menu.loads.tripBuilder',
  /** "Load planner" */ menu_loads_loadPlanner = 'menu.loads.loadPlanner',
  /** "My loads" */ menu_loads_myLoads = 'menu.loads.myLoads',
  /** "My companies" */ menu_loads_myCompanies = 'menu.loads.myCompanies',
  /** "My Loads" */ menu_myLoads_title = 'menu.myLoads.title',
  /** "Post Loads" */ menu_myLoads_post = 'menu.myLoads.post',
  /** "Upload Posts" */ menu_myLoads_uploadPosts = 'menu.myLoads.uploadPosts',
  /** "Export Posts" */ menu_myLoads_exportPosts = 'menu.myLoads.exportPosts',
  /** "My Trucks" */ menu_myTrucks_title = 'menu.myTrucks.title',
  /** "Post truck" */ menu_myTrucks_post = 'menu.myTrucks.post',
  /** "Find Trucks" */ menu_findTrucks_title = 'menu.findTrucks.title',
  /** "Truck locator" */ menu_findTrucks_search = 'menu.findTrucks.search',
  /** "Carriers" */ menu_findTrucks_myCarriers = 'menu.findTrucks.myCarriers',
  /** "Tools" */ menu_tools_title = 'menu.tools.title',
  /** "Profit calculator" */ menu_tools_profitCalculator = 'menu.tools.profitCalculator',
  /** "Rate check" */ menu_tools_rateCheck = 'menu.tools.rateCheck',
  /** "Carrier Monitor" */ menu_tools_carrierMonitor = 'menu.tools.carrierMonitor',
  /** "Hot Leads" */ menu_tools_hotLeads = 'menu.tools.hotLeads',
  /** "Mileage calculator" */ menu_tools_mileageCalculator = 'menu.tools.mileageCalculator',
  /** "My Documents" */ menu_tools_myDocuments = 'menu.tools.myDocuments',
  /** "Services" */ menu_services_title = 'menu.services.title',
  /** "Get paid faster" */ menu_services_getPaidFaster = 'menu.services.getPaidFaster',
  /** "Save at the pump" */ menu_services_saveAtThePump = 'menu.services.saveAtThePump',
  /** "Roadside assistance" */ menu_services_roadsideAssistance = 'menu.services.roadsideAssistance',
  /** "Trucking authority" */ menu_services_truckingAuthority = 'menu.services.truckingAuthority',
  /** "Integration Services" */ menu_integrationServices_title = 'menu.integrationServices.title',
  /** "TMS Integration" */ menu_integrationServices_tmsIntegration = 'menu.integrationServices.tmsIntegration',
  /** "HTML Embed Code" */ menu_integrationServices_htmlEmbed = 'menu.integrationServices.htmlEmbed',
  /** "Posting Aid Software" */ menu_integrationServices_postingAid = 'menu.integrationServices.postingAid',
  /** "Resources" */ menu_resources_title = 'menu.resources.title',
  /** "Fuel prices" */ menu_resources_fuelPrices = 'menu.resources.fuelPrices',
  /** "FMCSA search" */ menu_resources_fmcsaSearch = 'menu.resources.fmcsaSearch',
  /** "Credit report" */ menu_resources_creditReport = 'menu.resources.creditReport',
  /** "Search directory" */ menu_resources_searchDirectory = 'menu.resources.searchDirectory',
  /** "Reefer rates" */ menu_resources_reeferRates = 'menu.resources.reeferRates',
  /** "Border wait times" */ menu_resources_borderWaitTimes = 'menu.resources.borderWaitTimes',
  /** "More" */ menu_more_title = 'menu.more.title',
  /** "Settings" */ menu_more_settings = 'menu.more.settings',
  /** "Help" */ menu_more_help = 'menu.more.help',
  /** "Logout" */ menu_more_logout = 'menu.more.logout',
  /** "Origin" */ menu_mileageCalculatorPanel_originLabel = 'menu.mileageCalculatorPanel.originLabel',
  /** "Destination" */ menu_mileageCalculatorPanel_destinationLabel = 'menu.mileageCalculatorPanel.destinationLabel',
  /** "Add stops" */ menu_mileageCalculatorPanel_addStop = 'menu.mileageCalculatorPanel.addStop',
  /** "Edit stop" */ menu_mileageCalculatorPanel_editStop = 'menu.mileageCalculatorPanel.editStop',
  /** "Stops" */ menu_mileageCalculatorPanel_Stops = 'menu.mileageCalculatorPanel.Stops',
  /** "Stop {{index}}: " */ menu_mileageCalculatorPanel_Stop = 'menu.mileageCalculatorPanel.Stop',
  /** "Origin: " */ menu_mileageCalculatorPanel_Origin = 'menu.mileageCalculatorPanel.Origin',
  /** "Dest: " */ menu_mileageCalculatorPanel_Dest = 'menu.mileageCalculatorPanel.Dest',
  /** "StateMileageReport" */ menu_mileageCalculatorPanel_csvFileName = 'menu.mileageCalculatorPanel.csvFileName',
  /** "Route info" */ menu_mileageCalculatorPanel_routeInfo = 'menu.mileageCalculatorPanel.routeInfo',
  /** "IFTA report" */ menu_mileageCalculatorPanel_iftaReport = 'menu.mileageCalculatorPanel.iftaReport',
  /** "Start: " */ menu_mileageCalculatorPanel_Start = 'menu.mileageCalculatorPanel.Start',
  /** "End: " */ menu_mileageCalculatorPanel_End = 'menu.mileageCalculatorPanel.End',
  /** "Total distance: " */ menu_mileageCalculatorPanel_totalDistance = 'menu.mileageCalculatorPanel.totalDistance',
  /** "Total driving time: " */ menu_mileageCalculatorPanel_totalDrivingTime = 'menu.mileageCalculatorPanel.totalDrivingTime',
  /** "Total toll cost: " */ menu_mileageCalculatorPanel_totalTollCost = 'menu.mileageCalculatorPanel.totalTollCost',
  /** "State by state mileage report" */ menu_mileageCalculatorPanel_stateMileageReport = 'menu.mileageCalculatorPanel.stateMileageReport',
  /** "Profit calculator" */ menu_mileageCalculatorPanel_profitCalculator = 'menu.mileageCalculatorPanel.profitCalculator',
  /** "Profit $-" */ menu_mileageCalculatorPanel_profitInfo = 'menu.mileageCalculatorPanel.profitInfo',
  /** "Departure from: " */ menu_mileageCalculatorPanel_departureFrom = 'menu.mileageCalculatorPanel.departureFrom',
  /** "Arrival to: " */ menu_mileageCalculatorPanel_arriavalTo = 'menu.mileageCalculatorPanel.arriavalTo',
  /** "Upgrade your plan" */ menu_mileageCalculatorPanel_upgradePlan = 'menu.mileageCalculatorPanel.upgradePlan',
  /** "Download state mileage report (IFTA)" */ menu_mileageCalculatorPanel_downloadCSV = 'menu.mileageCalculatorPanel.downloadCSV',
  /** "Interactive map" */ menu_mileageCalculatorPanel_interactiveMap = 'menu.mileageCalculatorPanel.interactiveMap',
  /** "Page Not Found" */ errors_not_found_Title = 'errors.not_found.Title',
  /** "It seems we can't find the page you're looking for." */ errors_not_found_Message = 'errors.not_found.Message',
  /** "Either something went wrong, or the page no longer exists." */ errors_not_found_Sub_Message = 'errors.not_found.Sub_Message',
  /** "Page couldn't load" */ errors_no_component_Title = 'errors.no_component.Title',
  /** "It seems we can't load this page at this moment." */ errors_no_component_Message = 'errors.no_component.Message',
  /** "Try to reload the page. If the problem persist, please contact support." */ errors_no_component_Sub_Message = 'errors.no_component.Sub_Message',
  /** "Reload page" */ errors_no_component_Button = 'errors.no_component.Button',
  /** "Our system encountered an error." */ errors_systemEncounteredError = 'errors.systemEncounteredError',
  /** "Company added to your favorites" */ snackbars_companyFavorited = 'snackbars.companyFavorited',
  /** "Company removed from your favorites" */ snackbars_companyUnfavorited = 'snackbars.companyUnfavorited',
  /** "Company is now blocked" */ snackbars_companyBlocked = 'snackbars.companyBlocked',
  /** "Company is unblocked" */ snackbars_companyUnblocked = 'snackbars.companyUnblocked',
  /** "Company marked as onboarded" */ snackbars_companyOnboarded = 'snackbars.companyOnboarded',
  /** "Company removed from onboarded" */ snackbars_companyUnonboarded = 'snackbars.companyUnonboarded',
  /** "Feed" */ feed_Feed = 'feed.Feed',
  /** "Videos" */ feed_Videos = 'feed.Videos',
  /** "Help" */ feed_Help = 'feed.Help',
  /** "Coming soon" */ feed_comingSoon_title = 'feed.comingSoon.title',
  /** "We are currently working on this video tutorial, stay tuned!" */ feed_comingSoon_subtitle0 = 'feed.comingSoon.subtitle0',
  /** "In the meantime, please " */ feed_comingSoon_subtitle1 = 'feed.comingSoon.subtitle1',
  /** " or check out our help page." */ feed_comingSoon_subtitle2 = 'feed.comingSoon.subtitle2',
  /** "watch our other videos" */ feed_comingSoon_linkText = 'feed.comingSoon.linkText',
  /** "Assigned" */ postedLoadStatus_assigned_title = 'postedLoadStatus.assigned.title',
  /** "Assign this load to a carrier." */ postedLoadStatus_assigned_description = 'postedLoadStatus.assigned.description',
  /** "Assigned to DOT#: " */ postedLoadStatus_assigned_assignedTo = 'postedLoadStatus.assigned.assignedTo',
  /** "Delivered" */ postedLoadStatus_delivered_title = 'postedLoadStatus.delivered.title',
  /** "Mark this load as \"Delivered\" for future reference." */ postedLoadStatus_delivered_description = 'postedLoadStatus.delivered.description',
  /** "Unassigned" */ postedLoadStatus_unassigned = 'postedLoadStatus.unassigned',
  /** "Load assigned" */ postedLoadStatus_load_assigned = 'postedLoadStatus.load_assigned',
  /** "Assign carrier" */ postedLoadStatus_popup_title = 'postedLoadStatus.popup.title',
  /** "Please enter the Carrier’s DOT number to assign this load." */ postedLoadStatus_popup_content = 'postedLoadStatus.popup.content',
  /** "DOT#" */ postedLoadStatus_popup_dot = 'postedLoadStatus.popup.dot',
  /** "Assign" */ postedLoadStatus_popup_assign = 'postedLoadStatus.popup.assign',
  /** "Load assigned" */ postedLoadStatus_popup_success = 'postedLoadStatus.popup.success',
  /** "Unable to assign" */ postedLoadStatus_popup_unable = 'postedLoadStatus.popup.unable',
  /** "This load cannot be assigned as it has multiple quantities." */ postedLoadStatus_popup_multiple = 'postedLoadStatus.popup.multiple',
  /** "Unassign" */ postedLoadStatus_unassign_title = 'postedLoadStatus.unassign.title',
  /** "Are you sure you want to unassign this load, or would you like to assign a new carrier?" */ postedLoadStatus_unassign_question = 'postedLoadStatus.unassign.question',
  /** "If you’d still like to unassign the load, please choose one of the following options: " */ postedLoadStatus_unassign_options = 'postedLoadStatus.unassign.options',
  /** "Unassign and post online" */ postedLoadStatus_unassign_online = 'postedLoadStatus.unassign.online',
  /** "Unassign and post offline" */ postedLoadStatus_unassign_offline = 'postedLoadStatus.unassign.offline',
  /** "Unassign and delete" */ postedLoadStatus_unassign_delete = 'postedLoadStatus.unassign.delete',
  /** "Hello, {{name}}." */ accountSetup_title = 'accountSetup.title',
  /** "Get immediate access to your account by selecting a plan and entering your billing details." */ accountSetup_description = 'accountSetup.description',
  /** "Your account status is currently inactive.\n\nGet immediate access to your account by entering your billing details." */ accountSetup_brokerDescription = 'accountSetup.brokerDescription',
  /** "{{daysCount}} days free" */ accountSetup_promoCode_title = 'accountSetup.promoCode.title',
  /** "Then ${{price}} per month" */ accountSetup_promoCode_titleDescription = 'accountSetup.promoCode.titleDescription',
  /** "Add promo code" */ accountSetup_promoCode_addPromoCode = 'accountSetup.promoCode.addPromoCode',
  /** "Please enter your promo code" */ accountSetup_promoCode_description = 'accountSetup.promoCode.description',
  /** "Promo code" */ accountSetup_promoCode_label = 'accountSetup.promoCode.label',
  /** "You unlocked a {{daysCount}} day trial and {{percent}}% discount" */ accountSetup_promoCode_details1 = 'accountSetup.promoCode.details1',
  /** "You unlocked a {{daysCount}} day trial" */ accountSetup_promoCode_details2 = 'accountSetup.promoCode.details2',
  /** "You unlocked a {{percent}}% discount" */ accountSetup_promoCode_details3 = 'accountSetup.promoCode.details3',
  /** "Invalid promo code" */ accountSetup_promoCode_invalidCodeError = 'accountSetup.promoCode.invalidCodeError',
  /** "This promo code is available only for {{plans}} plan(s)" */ accountSetup_promoCode_invalidCodeForPlan = 'accountSetup.promoCode.invalidCodeForPlan',
  /** "Your account status has been put on hold." */ accountSetup_setPlanAccessError_message1 = 'accountSetup.setPlanAccessError.message1',
  /** "Your monthly payment was unable to be charged." */ accountSetup_setPlanAccessError_message2 = 'accountSetup.setPlanAccessError.message2',
  /** "You can get immediate access to your account by updating your billing information." */ accountSetup_setPlanAccessError_message3 = 'accountSetup.setPlanAccessError.message3',
  /** "Change plan" */ accountSetup_changePlan = 'accountSetup.changePlan',
  /** "Subtotal" */ accountSetup_subtotal = 'accountSetup.subtotal',
  /** "Total due today" */ accountSetup_totalDay = 'accountSetup.totalDay',
  /** "Total after trial/per month" */ accountSetup_totalAfterTrial = 'accountSetup.totalAfterTrial',
  /** "Monthly total after promo" */ accountSetup_totalAfterPromo = 'accountSetup.totalAfterPromo',
  /** "Not added" */ accountSetup_notAdded = 'accountSetup.notAdded',
  /** "After your trial ends, you will be charged ${{price}} per month starting {{date}}. You may unsubscribe at any time." */ accountSetup_trialDescription = 'accountSetup.trialDescription',
  /** "User" */ accountSetup_user = 'accountSetup.user',
  /** "Activate" */ accountSetup_activate = 'accountSetup.activate',
  /** "Refresh" */ accountSetup_refresh = 'accountSetup.refresh',
  /** "Failed to process payment" */ accountSetup_permissionFailed = 'accountSetup.permissionFailed',
  /** "Server connection error" */ accountSetup_fetchingDataFailed = 'accountSetup.fetchingDataFailed',
  /** "877-875-5301 ext. 2" */ accountSetup_fetchingDataFailedPhone = 'accountSetup.fetchingDataFailedPhone',
  /** "An error occurred while loading your user information. Please refresh the page to try again." */ accountSetup_fetchingDataFailedDescription = 'accountSetup.fetchingDataFailedDescription',
  /** "If you’re still experiencing issues reactivating your account, please contact Support at" */ accountSetup_fetchingDataFailedContact = 'accountSetup.fetchingDataFailedContact',
  /** "Need help with your account? Call us" */ accountSetup_needHelpText = 'accountSetup.needHelpText',
  /** "Account status" */ accountSetup_accountStatus = 'accountSetup.accountStatus',
  /** "Access to 123Loadboard is currently unavailable. You don’t have the necessary permissions to edit payment method. Please notify the account owner" */ accountSetup_accessDeniedPermission1 = 'accountSetup.accessDeniedPermission1',
  /** "to update the payment method." */ accountSetup_accessDeniedPermission2 = 'accountSetup.accessDeniedPermission2',
  /** "Enter payment details" */ accountSetup_editPayment = 'accountSetup.editPayment',
  /** "Tax" */ accountSetup_tax = 'accountSetup.tax',
  /** "A reactivation error has occurred, to reactivate please call support" */ accountSetup_errors_status = 'accountSetup.errors.status',
  /** "There was an error processing your payment" */ accountSetup_errors_invoice = 'accountSetup.errors.invoice',
  /** "Access denied for free Basic accounts" */ accountSetup_statusMessage_MobileSignUpInProgress_title = 'accountSetup.statusMessage.MobileSignUpInProgress.title',
  /** "Access to the 123Loadboard website is only available with a paid membership. Please select a plan below." */ accountSetup_statusMessage_MobileSignUpInProgress_description = 'accountSetup.statusMessage.MobileSignUpInProgress.description',
  /** "Failed to process billing information" */ accountSetup_statusMessage_SignUpInProgress_title = 'accountSetup.statusMessage.SignUpInProgress.title',
  /** "Your billing information was not processed correctly, please try again." */ accountSetup_statusMessage_SignUpInProgress_description = 'accountSetup.statusMessage.SignUpInProgress.description',
  /** "Your payment failed to process" */ accountSetup_statusMessage_PendingPayment_title = 'accountSetup.statusMessage.PendingPayment.title',
  /** "We were not able to process your payment, access to the 123Loadboard website is only available with a paid membership. Please update your billing information." */ accountSetup_statusMessage_PendingPayment_description = 'accountSetup.statusMessage.PendingPayment.description',
  /** "Your account is currently closed." */ accountSetup_statusMessage_Expired_title = 'accountSetup.statusMessage.Expired.title',
  /** "Access to the 123Loadboard website is only available with a paid membership. Please select a plan below." */ accountSetup_statusMessage_Expired_description = 'accountSetup.statusMessage.Expired.description',
  /** "Your account is currently closed." */ accountSetup_statusMessage_OnHold_title = 'accountSetup.statusMessage.OnHold.title',
  /** "To reopen your account, please re-enter your billing information below." */ accountSetup_statusMessage_OnHold_description = 'accountSetup.statusMessage.OnHold.description',
  /** "Your account is currently closed." */ accountSetup_statusMessage_BillingOnHold_title = 'accountSetup.statusMessage.BillingOnHold.title',
  /** "Please update your billing information." */ accountSetup_statusMessage_BillingOnHold_description = 'accountSetup.statusMessage.BillingOnHold.description',
  /** "Failed to process payment" */ accountSetup_statusMessage_SecondaryUserNonAdmin_title = 'accountSetup.statusMessage.SecondaryUserNonAdmin.title',
  /** "To gain access, please ask your account Administrator to update the billing information." */ accountSetup_statusMessage_SecondaryUserNonAdmin_description = 'accountSetup.statusMessage.SecondaryUserNonAdmin.description',
  /** "Your account is currently closed." */ accountSetup_statusMessage_NoBillingStatus_title = 'accountSetup.statusMessage.NoBillingStatus.title',
  /** "Access to the 123Loadboard website is only available with a paid membership. Please select a plan below." */ accountSetup_statusMessage_NoBillingStatus_description = 'accountSetup.statusMessage.NoBillingStatus.description',
  /** "Membership plan was activated" */ accountSetup_reactivateActionResult_success = 'accountSetup.reactivateActionResult.success',
  /** "Membership plan activation failed" */ accountSetup_reactivateActionResult_fail = 'accountSetup.reactivateActionResult.fail',
  /** "Custom rule: " */ loadVisibilityRules_customRule = 'loadVisibilityRules.customRule',
  /** "Post with visibility rules" */ loadVisibilityRules_checkboxPostWithVisibilityRules = 'loadVisibilityRules.checkboxPostWithVisibilityRules',
  /** "Change rule" */ loadVisibilityRules_changeRule = 'loadVisibilityRules.changeRule',
  /** "Post with visibility rules" */ loadVisibilityRules_postWithVisibilityRules = 'loadVisibilityRules.postWithVisibilityRules',
  /** "Load visibility rules allow you to control which carriers can see your loads." */ loadVisibilityRules_postFormContent = 'loadVisibilityRules.postFormContent',
  /** "Restrict the audience to onboarded carriers only, or use criteria like insurance amount or authority age to limit load visibility." */ loadVisibilityRules_postFormMoreContent = 'loadVisibilityRules.postFormMoreContent',
  /** "Any carrier" */ loadVisibilityRules_anyCarrier = 'loadVisibilityRules.anyCarrier',
  /** " {{value}} of authority" */ loadVisibilityRules_ofAuthority = 'loadVisibilityRules.ofAuthority',
  /** " {{value}} insurance" */ loadVisibilityRules_valueInsurance = 'loadVisibilityRules.valueInsurance',
  /** " onboarded" */ loadVisibilityRules_onboarded = 'loadVisibilityRules.onboarded',
  /** "with at least" */ loadVisibilityRules_withAtLeast = 'loadVisibilityRules.withAtLeast',
  /** "and at least" */ loadVisibilityRules_andAtLeast = 'loadVisibilityRules.andAtLeast',
  /** "At posting time," */ loadVisibilityRules_atPostingTime = 'loadVisibilityRules.atPostingTime',
  /** "the load will be visible to:" */ loadVisibilityRules_willBeVisibleTo = 'loadVisibilityRules.willBeVisibleTo',
  /** "the load will also be visible to:" */ loadVisibilityRules_willAlsoBeVisibleTo = 'loadVisibilityRules.willAlsoBeVisibleTo',
  /** "after posting, the load will" */ loadVisibilityRules_afterPostingTime = 'loadVisibilityRules.afterPostingTime',
  /** "be visible to:" */ loadVisibilityRules_beVisibleTo = 'loadVisibilityRules.beVisibleTo',
  /** "also" */ loadVisibilityRules_also = 'loadVisibilityRules.also',
  /** "Any carrier who is" */ loadVisibilityRules_carrierWhoIsOnboarded = 'loadVisibilityRules.carrierWhoIsOnboarded',
  /** "Default" */ loadVisibilityRules_default = 'loadVisibilityRules.default',
  /** "Load visibility rules allow you to control which carriers can see your loads based on criteria like insurance level, onboarding status, authority age, etc.\n\nYou can also set up cascading load visibility rules to, over time, expand the range of carriers able to see your loads.\n\nThese load visibility rules can be applied when posting loads manually." */ loadVisibilityRules_emptyContent = 'loadVisibilityRules.emptyContent',
  /** "Load visibility rules control which carriers can see your freight." */ loadVisibilityRules_content = 'loadVisibilityRules.content',
  /** "Start by setting up some rules here. You may then apply these rules when posting loads." */ loadVisibilityRules_moreContent = 'loadVisibilityRules.moreContent',
  /** "Make default" */ loadVisibilityRules_makeDefault = 'loadVisibilityRules.makeDefault',
  /** "Add rule" */ loadVisibilityRules_addRule = 'loadVisibilityRules.addRule',
  /** "Create new rule" */ loadVisibilityRules_createNewRule = 'loadVisibilityRules.createNewRule',
  /** "Add next condition" */ loadVisibilityRules_addNextCondition = 'loadVisibilityRules.addNextCondition',
  /** "Type of carriers" */ loadVisibilityRules_typeOfCarriers = 'loadVisibilityRules.typeOfCarriers',
  /** "All Carriers" */ loadVisibilityRules_allCarriers = 'loadVisibilityRules.allCarriers',
  /** "Onboarded" */ loadVisibilityRules_onboardedType = 'loadVisibilityRules.onboardedType',
  /** "Min. Insurance ($)" */ loadVisibilityRules_minInsuranceField = 'loadVisibilityRules.minInsuranceField',
  /** "Min. Authority age" */ loadVisibilityRules_minAuthorityAge = 'loadVisibilityRules.minAuthorityAge',
  /** "Year(s)" */ loadVisibilityRules_years = 'loadVisibilityRules.years',
  /** "Month(s)" */ loadVisibilityRules_months = 'loadVisibilityRules.months',
  /** "Removing this condition will delete this rule." */ loadVisibilityRules_deleteFinalRuleContent = 'loadVisibilityRules.deleteFinalRuleContent',
  /** "This will not affect currently posted loads." */ loadVisibilityRules_deleteRuleContent = 'loadVisibilityRules.deleteRuleContent',
  /** "Edit rule" */ loadVisibilityRules_editRule = 'loadVisibilityRules.editRule',
  /** "Delete rule" */ loadVisibilityRules_deleteRuleset = 'loadVisibilityRules.deleteRuleset',
  /** "Deleting will remove all created conditions within this rule." */ loadVisibilityRules_deleteRulesetContent = 'loadVisibilityRules.deleteRulesetContent',
  /** "Days" */ loadVisibilityRules_days = 'loadVisibilityRules.days',
  /** "Minutes" */ loadVisibilityRules_minutes = 'loadVisibilityRules.minutes',
  /** "Hours" */ loadVisibilityRules_hours = 'loadVisibilityRules.hours',
  /** "Rule saved" */ loadVisibilityRules_ruleSaved = 'loadVisibilityRules.ruleSaved',
  /** "Rule removed" */ loadVisibilityRules_ruleRemoved = 'loadVisibilityRules.ruleRemoved',
  /** "Rule updated" */ loadVisibilityRules_ruleUpdated = 'loadVisibilityRules.ruleUpdated',
  /** "{{day}} day " */ loadVisibilityRules_timeInDay = 'loadVisibilityRules.timeInDay',
  /** "{{day}} days " */ loadVisibilityRules_timeInDays = 'loadVisibilityRules.timeInDays',
  /** "{{hour}} hour " */ loadVisibilityRules_timeInHour = 'loadVisibilityRules.timeInHour',
  /** "{{hour}} hours " */ loadVisibilityRules_timeInHours = 'loadVisibilityRules.timeInHours',
  /** "{{mins}} minutes" */ loadVisibilityRules_timeInMins = 'loadVisibilityRules.timeInMins',
  /** "{{mins}} minute" */ loadVisibilityRules_timeInMin = 'loadVisibilityRules.timeInMin',
  /** "{{year}} year " */ loadVisibilityRules_ageInyear = 'loadVisibilityRules.ageInyear',
  /** "{{months}} month" */ loadVisibilityRules_ageInMonth = 'loadVisibilityRules.ageInMonth',
  /** "{{year}} years " */ loadVisibilityRules_ageInyears = 'loadVisibilityRules.ageInyears',
  /** "{{months}} months" */ loadVisibilityRules_ageInMonths = 'loadVisibilityRules.ageInMonths',
  /** "Onboarded carriers" */ loadVisibilityRules_onboardedCarriers = 'loadVisibilityRules.onboardedCarriers',
  /** "To select this option, you must set up your onboarded carriers list.\n\nYou may set up your onboarded carriers list at any time in Settings." */ loadVisibilityRules_onboardedCarriersContent = 'loadVisibilityRules.onboardedCarriersContent',
  /** "Set up onboarded carriers list" */ loadVisibilityRules_setupCarriers = 'loadVisibilityRules.setupCarriers',
  /** " {{date}} at {{time}}, " */ loadVisibilityRules_dateAtTime = 'loadVisibilityRules.dateAtTime',
  /** "visible to:" */ loadVisibilityRules_visibleTo = 'loadVisibilityRules.visibleTo',
  /** "Since" */ loadVisibilityRules_since = 'loadVisibilityRules.since',
  /** "On" */ loadVisibilityRules_on = 'loadVisibilityRules.on',
  /** "You may create custom load visibility rules to control which carriers can see your loads." */ loadVisibilityRules_defaultRuleContent = 'loadVisibilityRules.defaultRuleContent',
  /** "Assign rule" */ loadVisibilityRules_assignRule = 'loadVisibilityRules.assignRule',
  /** "Create rule" */ loadVisibilityRules_createRule = 'loadVisibilityRules.createRule',
  /** "Load visibility rules must be created before they can be assigned.\n\nRules can be created at any time through the Load visibility rules section in Settings.\n\nYou will lose all entered information for this load if you choose to create a load visibility rule at this time." */ loadVisibilityRules_assignRulePopupContent = 'loadVisibilityRules.assignRulePopupContent',
  /** "Load visibility rules allow you to control which carriers can see your loads based on criteria like insurance level, onboarding status, authority age, etc.\n\nYou can also set up cascading load visibility rules to, over time, expand the range of carriers able to see your loads." */ loadVisibilityRules_infoPopupContent = 'loadVisibilityRules.infoPopupContent',
  /** "Select rule" */ loadVisibilityRules_selectRule = 'loadVisibilityRules.selectRule',
  /** "This load will be visible to any carriers using 123Loadboard.\n\nIf you wish, you may select a load visibility rule to apply to this load." */ loadVisibilityRules_selectRulePopup = 'loadVisibilityRules.selectRulePopup',
  /** "Set a timer for your new rule. This timer will start from the current time." */ loadVisibilityRules_timerFromCurrentTime = 'loadVisibilityRules.timerFromCurrentTime',
  /** "Load visibility rules are paused for Offline loads. Posting the load Online will reactivate these rules." */ loadVisibilityRules_offlineLoadWarning = 'loadVisibilityRules.offlineLoadWarning',
  /** "Add condition" */ loadVisibilityRules_addCondition = 'loadVisibilityRules.addCondition',
  /** "Edit condition" */ loadVisibilityRules_editCondition = 'loadVisibilityRules.editCondition',
  /** "If you apply this rule to a load, only carriers matching these conditions will be able to see it." */ loadVisibilityRules_createRuleInfoSection = 'loadVisibilityRules.createRuleInfoSection',
  /** "Condition #{{index}}" */ loadVisibilityRules_conditionIndex = 'loadVisibilityRules.conditionIndex',
  /** "* New conditions will be applied in addition to previous ones." */ loadVisibilityRules_addNextConditionInfo = 'loadVisibilityRules.addNextConditionInfo',
  /** "This condition will become active " */ loadVisibilityRules_ruleTimerInfoOne = 'loadVisibilityRules.ruleTimerInfoOne',
  /** " after load has been posted." */ loadVisibilityRules_ruleTimerInfoTwo = 'loadVisibilityRules.ruleTimerInfoTwo',
  /** " after changes are applied." */ loadVisibilityRules_ruleTimerInfoTwoAlt = 'loadVisibilityRules.ruleTimerInfoTwoAlt',
  /** "* You can manage rules in Settings > Load visibility rules." */ loadVisibilityRules_manageRules = 'loadVisibilityRules.manageRules',
  /** "Source" */ onboardedCarriers_source = 'onboardedCarriers.source',
  /** "Manage your manually entered DOT numbers on 123Loadboard. Modify DOT numbers from other sources at the respective source." */ onboardedCarriers_manageOnboardedCarriers = 'onboardedCarriers.manageOnboardedCarriers',
  /** "Add/Remove" */ onboardedCarriers_addOrRemove = 'onboardedCarriers.addOrRemove',
  /** "Add USDOT#" */ onboardedCarriers_addUSdot = 'onboardedCarriers.addUSdot',
  /** "Delete all" */ onboardedCarriers_deleteAll = 'onboardedCarriers.deleteAll',
  /** "Are you sure you want to delete all?" */ onboardedCarriers_deleteAllText = 'onboardedCarriers.deleteAllText',
  /** "Add your onboarded carriers" */ onboardedCarriers_emptyPanelTitle = 'onboardedCarriers.emptyPanelTitle',
  /** "{{number}} USDOT# added to your Onboarded carriers list successfully." */ onboardedCarriers_successfullyAdded = 'onboardedCarriers.successfullyAdded',
  /** "Filter by USDOT#" */ onboardedCarriers_filterTitle = 'onboardedCarriers.filterTitle',
  /** "Add onboarded carriers to your network by entering in their USDOT numbers.\n\nWhen you post loads, you may prioritize visibility of your loads to only your onboarded carriers." */ onboardedCarriers_emptyPanelsubtitle = 'onboardedCarriers.emptyPanelsubtitle',
  /** "Add companies to your Onboarded carrier list by adding one or more USDOT# on individual lines or separated by commas. Deleting a USDOT# removes the company from your list." */ onboardedCarriers_editPanelContent = 'onboardedCarriers.editPanelContent',
  /** "From {{total}} USDOT# records" */ onboardedCarriers_warningMessageTotal = 'onboardedCarriers.warningMessageTotal',
  /** "{{count}} duplicates removed" */ onboardedCarriers_warningMessageDuplicates = 'onboardedCarriers.warningMessageDuplicates',
  /** "{{failedCount}} failed validation." */ onboardedCarriers_warningMessageInvalid = 'onboardedCarriers.warningMessageInvalid',
  /** "USDOT# submission" */ onboardedCarriers_usdotSubmission = 'onboardedCarriers.usdotSubmission',
  /** "Your Onboarded carriers list has been updated successfully." */ onboardedCarriers_editListUpdated = 'onboardedCarriers.editListUpdated',
  /** "Add companies to your Onboarded carrier list by adding one or more USDOT# on individual lines or separated by commas. Deleting a USDOT# removes the company from your list." */ onboardedCarriers_textFieldTitle = 'onboardedCarriers.textFieldTitle',
  /** "USDOT#" */ onboardedCarriers_usDotLabel = 'onboardedCarriers.usDotLabel',
  /** "Validation failed" */ onboardedCarriers_validationFailed = 'onboardedCarriers.validationFailed',
  /** "Review" */ onboardedCarriers_review = 'onboardedCarriers.review',
  /** "List updated" */ onboardedCarriers_listUpdated = 'onboardedCarriers.listUpdated',
  /** "Welcome back" */ welcomebackCampaign_welcomeback = 'welcomebackCampaign.welcomeback',
  /** "While you were away, we heard your feedback and made big improvements." */ welcomebackCampaign_title = 'welcomebackCampaign.title',
  /** "Welcome back - Reactivate your membership with a 50% discount!" */ welcomebackCampaign_welcomeBackTitle = 'welcomebackCampaign.welcomeBackTitle',
  /** "Based on your search history, we have these loads available for you now." */ welcomebackCampaign_tableTitle = 'welcomebackCampaign.tableTitle',
  /** "Promo code:" */ welcomebackCampaign_promoCode = 'welcomebackCampaign.promoCode',
  /** "Origin" */ welcomebackCampaign_origin = 'welcomebackCampaign.origin',
  /** "Destination" */ welcomebackCampaign_destination = 'welcomebackCampaign.destination',
  /** "Pickup date" */ welcomebackCampaign_pickupDate = 'welcomebackCampaign.pickupDate',
  /** "Equipment" */ welcomebackCampaign_equipment = 'welcomebackCampaign.equipment',
  /** "Size" */ welcomebackCampaign_size = 'welcomebackCampaign.size',
  /** "Length" */ welcomebackCampaign_Length = 'welcomebackCampaign.Length',
  /** "Weight" */ welcomebackCampaign_weight = 'welcomebackCampaign.weight',
  /** "Reactivate account to view details" */ welcomebackCampaign_reactivateAccount = 'welcomebackCampaign.reactivateAccount',
  /** "Reactivate account to see more loads" */ welcomebackCampaign_reactivateAccountForLoads = 'welcomebackCampaign.reactivateAccountForLoads',
  /** "Navigate the road to success with 123Loadboard - Now at half the price" */ welcomebackCampaign_promoTitle = 'welcomebackCampaign.promoTitle',
  /** "Exclusive Back-on-Board offer: save 50% on any plan for your first 3 months. Use this promo code to unlock your exclusive offer." */ welcomebackCampaign_promoText = 'welcomebackCampaign.promoText',
  /** "Unlock offer now" */ welcomebackCampaign_unlockOfferBtn = 'welcomebackCampaign.unlockOfferBtn',
  /** "More broker integrations than ever before" */ welcomebackCampaign_moreBrokerIntgTitle = 'welcomebackCampaign.moreBrokerIntgTitle',
  /** "Now more than ever, it's important to put all the chances on your side. Expand your network and find more loads." */ welcomebackCampaign_moreBrokerIntgSubtitle = 'welcomebackCampaign.moreBrokerIntgSubtitle',
  /** "New and improved market rates" */ welcomebackCampaign_marketRatesTitle = 'welcomebackCampaign.marketRatesTitle',
  /** "Give yourself the edge. Information is power. Know what the market is like and have the upper hand when negotiating rates." */ welcomebackCampaign_marketRatesSubtitle = 'welcomebackCampaign.marketRatesSubtitle',
  /** "Better loads" */ welcomebackCampaign_betterLoads = 'welcomebackCampaign.betterLoads',
  /** "Don't waste precious time finding loads. A growing number of loads now come with rates, with the option to book, bid, or send instant messages directly through your app." */ welcomebackCampaign_betterLoadsSubtitle = 'welcomebackCampaign.betterLoadsSubtitle',
  /** "Take a look for yourself!" */ welcomebackCampaign_abridgedPromoTitle = 'welcomebackCampaign.abridgedPromoTitle',
  /** "Location request" */ locationRequest_title = 'locationRequest.title',
  /** "This will send a request for {{name}} to send their current location." */ locationRequest_content = 'locationRequest.content',
  /** "Request location" */ locationRequest_label = 'locationRequest.label',
  /** "Request sent" */ locationRequest_success = 'locationRequest.success',
  /** "Request failed" */ locationRequest_failure = 'locationRequest.failure',
  /** "Location sent" */ locationRequest_sentLocationSuccessfully = 'locationRequest.sentLocationSuccessfully',
}

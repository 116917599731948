import React, { useContext, useEffect, useLayoutEffect, useMemo } from 'react';

import { filter, find, first, isEmpty, values } from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { appendPromoLinks, clearPromo, fetchPromo } from '@/reduxStore/epic/FeedEpic';
import { getFeedMappingDataForUrl } from '@component/feed/FeedMappingHelper';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { Panel, PanelSize, panelTrackingContext, PanelWidth } from '@component/panel';
import { useClearLinksFromPreviousSource, useLinksAppending } from '@page/dashboard/FeedHooks';
import { Color } from '@style/Color';
import { AppConfig } from '@util/AppConfig';
import { changeRelativePathsToAbsolute } from '@util/helper/PathHelper';
import { useCsv, useIsMobileView, useSelector } from '@util/hooks';

const PROMO_BASE_PATH = AppConfig.LBConfig.PromoBaseUrl;
const PROMOTION_PANEL_DEBUG_ID = 'promo';

interface Props {
  availableWidth: number;
}

export const Promotion: React.FC<Props> = ({ availableWidth }) => {
  const dispatch = useDispatch();
  const shouldUsePromotionPanel = usePromotionPanel(availableWidth);
  const location = useLocation();
  const isCarrier = useIsCarrier();
  const mappingFile = useSelector((state) => state.feedState.mappingFile);
  const csvData = useCsv(mappingFile);

  const mappingData = useMemo(() => {
    return getFeedMappingDataForUrl(csvData, location.pathname);
  }, [csvData, location.pathname]);

  const promoSource = isCarrier ? mappingData?.carrierPromotionSourceRoute : mappingData?.brokerPromotionSourceRoute;

  useEffect(() => {
    if (shouldUsePromotionPanel && promoSource) {
      dispatch(fetchPromo(promoSource));
    } else {
      dispatch(clearPromo());
    }
  }, [shouldUsePromotionPanel, promoSource]);

  return shouldUsePromotionPanel ? (
    <Panel
      layer={0}
      offset={1}
      id={PROMOTION_PANEL_DEBUG_ID}
      size={PanelSize.full}
      backgroundColor={Color.TRANSPARENT_0}
      shouldRemoveShadow={true}
    >
      <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        {promoSource ? <PromoContent promoSource={promoSource} /> : null}
      </div>
    </Panel>
  ) : null;
};

const PromoContent: React.FC<{ promoSource: string }> = ({ promoSource }) => {
  const promo = useSelector((state) => state.feedState.promo);
  const shouldAppendPromoLinks = useSelector((state) => state.feedState.shouldAppendPromoLinks);

  useLayoutEffect(() => {
    if (promo) {
      changeRelativePathsToAbsolute(promo, ['src', 'href', 'icon', 'background'], PROMO_BASE_PATH);
    }
  }, [promo]);

  useClearLinksFromPreviousSource(shouldAppendPromoLinks, AppConfig.LBConfig.AzureHostingRootUrl);
  useLinksAppending(promo, shouldAppendPromoLinks, appendPromoLinks, promoSource);

  const innerPromo = useMemo(() => (promo ? promo.body.innerHTML : ''), [promo]);

  return <div dangerouslySetInnerHTML={{ __html: innerPromo }} />;
};

export const usePromotionPanel = (availableWidth: number) => {
  const panelTracking = useContext(panelTrackingContext);
  const isMobileView = useIsMobileView();
  if (isMobileView) {
    return false;
  }
  const panels = values(panelTracking.panels);
  const listOfOpenedPanelsWithoutPromo = filter(
    panels,
    (panel) => panel.offset !== 0 && panel.debugId !== PROMOTION_PANEL_DEBUG_ID
  );
  const firstOpenedPanel = first(panels);

  const isFirstPanelSmallAndOpened = firstOpenedPanel?.offset === 0 && firstOpenedPanel?.size === PanelSize.small;

  const containsXLargePanelOnTop =
    find(panels, (panel) => panel.size === PanelSize.xlarge && panel.layer === 0) !== undefined;

  //check if we have enough space to render promo panel
  const freeSpace = availableWidth - PanelWidth.small;
  const isEnoughSpace = freeSpace > PanelWidth.small && !containsXLargePanelOnTop;
  return isEnoughSpace && isFirstPanelSmallAndOpened && isEmpty(listOfOpenedPanelsWithoutPromo);
};

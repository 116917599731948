import { useRef } from 'react';

export interface FragmentHolder {
  /** tracks the single <div> DOM Element instance at all times */
  divElement: HTMLDivElement;

  /** DocumentFragment is used to temporarily "own" a divElement while it's not in the DOM.
   * Also used as a global semaphore: it's empty when the divElement is installed in DOM */
  fragment: DocumentFragment;
}

const createDocumentFragmentHolder = () => {
  const fragment = document.createDocumentFragment();
  const divElem = document.createElement('div');
  fragment.appendChild(divElem);
  return { divElement: divElem, fragment: fragment };
};

/**
 * React Portal helper to use another React component as the portal target instead
 * of using a DOM element outside of React.
 *
 * useFragmentHolder is used to obtain a new FragmentHolder to feed the FragmentPortalOrigin
 * and the FragmentPortalDestination. useFragmentHolder is meant to be called at the origin point and
 * transfered to the destination point.
 */
export const useFragmentHolder = () => useRef<FragmentHolder>(createDocumentFragmentHolder()).current;

import {
  BaseLoadSearchRequest,
  CA_PROVINCES,
  CompanyFilter,
  CompanyFilterType,
  EquipmentType,
  LoadLocation,
  LoadLocationAnywhere,
  LoadSearchMetadata,
  LoadSearchMileage,
  LoadSearchRequest,
  LoadSearchSort,
  LoadSize,
  LoadSortCategory,
  LoadSortDirection,
  LocationType,
  MapBounds,
  OriginLocation,
  SearchMetadataType,
  STATES,
} from '@common/model';
import {
  createArrayParser,
  createObjectParser,
  parseArrayOfValuesFrom,
  parseBoolean,
  parseNumber,
  parseString,
  parseStringArray,
  parseValueFrom,
  parseValueIncludedIn,
  pickDefinedProperties,
} from '@common/util/parser/ParserUtils';
import { lengthDomain, weightDomain } from '@util/TruckDataHelper';

const ALL_STATES = [...STATES, ...CA_PROVINCES];
const parseArrayOfStatesAndProvinces = createArrayParser((value: unknown) => parseValueIncludedIn(value, ALL_STATES));

export const parseOriginLocation = createObjectParser<OriginLocation | undefined>((object) => {
  if (object.type) {
    const states: string[] | undefined = parseArrayOfStatesAndProvinces(object.states);
    const radius: number | undefined = parseNumber(object.radius);
    const city: string | undefined = parseString(object.city);
    switch (object.type) {
      case LocationType.STATE: {
        if (states) {
          return {
            type: LocationType.STATE,
            states: states,
            ...pickDefinedProperties({
              radius: radius,
            }),
          };
        }
        break;
      }
      case LocationType.GEOLOCATION: {
        const latitude = parseNumber(object.latitude);
        const longitude = parseNumber(object.longitude);
        if (latitude !== undefined && longitude !== undefined) {
          return {
            type: LocationType.GEOLOCATION,
            latitude: latitude,
            longitude: longitude,
            ...pickDefinedProperties({
              radius: radius,
              states: states,
              formattedAddress: parseString(object.formattedAddress),
              city: city,
            }),
          };
        }
        break;
      }
      case LocationType.ZIP: {
        const zipCode = parseString(object.zipCode);
        if (zipCode && city !== undefined && states) {
          return {
            type: LocationType.ZIP,
            zipCode: zipCode,
            states: states,
            city: city,
            ...pickDefinedProperties({
              radius: radius,
            }),
          };
        }
        break;
      }
      case LocationType.CITY: {
        if (city !== undefined && states) {
          return {
            type: LocationType.CITY,
            city: city,
            states: states,
            ...pickDefinedProperties({
              radius: radius,
            }),
          };
        }
        break;
      }
      default:
        break;
    }
  }
  return undefined;
});

export const parseLoadLocationAnywhere = createObjectParser<LoadLocationAnywhere | undefined>((object) => {
  if (object.type) {
    switch (object.type) {
      case LocationType.ANY:
      case LocationType.ANY_CA:
      case LocationType.ANY_US:
        return {
          type: object.type,
          ...pickDefinedProperties({
            radius: parseNumber(object.radius),
          }),
        };
      default:
        break;
    }
  }
  return undefined;
});

export const parseLoadLocation = (value: unknown): LoadLocation | undefined => {
  const originLocation = parseOriginLocation(value);
  if (originLocation) {
    return originLocation;
  }
  const loadLocationAnywhere = parseLoadLocationAnywhere(value);
  if (loadLocationAnywhere) {
    return loadLocationAnywhere;
  }
  return undefined;
};

export const parseLoadSearchSort = createObjectParser<LoadSearchSort | undefined>((object) => {
  if (object.direction && object.field) {
    const direction = parseValueFrom(object.direction, LoadSortDirection);
    const field = parseValueFrom(object.field, LoadSortCategory);
    if (direction && field) {
      return {
        direction: direction,
        field: field,
      };
    }
  }
  return undefined;
});

export const parseLoadSearchMetadata = createObjectParser<LoadSearchMetadata | undefined>((object) => {
  if (object.type) {
    switch (object.type) {
      case SearchMetadataType.Regular:
      case SearchMetadataType.Refresh:
      case SearchMetadataType.Geo:
      case SearchMetadataType.GeoPanning:
      case SearchMetadataType.MapSelect:
        return {
          type: object.type,
          ...pickDefinedProperties({
            offset: parseNumber(object.offset),
            nextToken: parseString(object.nextToken),
            limit: parseNumber(object.limit),
            fields: parseString(object.fields),
            sortBy: parseLoadSearchSort(object.sortBy),
          }),
        };
      default:
        break;
    }
  }
  return undefined;
});

export const parseMapBounds = createObjectParser<MapBounds | undefined>((object) => {
  const minLatitude = parseNumber(object.minLatitude);
  const maxLatitude = parseNumber(object.maxLatitude);
  const minLongitude = parseNumber(object.minLongitude);
  const maxLongitude = parseNumber(object.maxLongitude);
  if (
    minLatitude !== undefined &&
    maxLatitude !== undefined &&
    minLongitude !== undefined &&
    maxLongitude !== undefined
  ) {
    return {
      minLatitude: minLatitude,
      maxLatitude: maxLatitude,
      minLongitude: minLongitude,
      maxLongitude: maxLongitude,
    };
  }
  return undefined;
});

export const parseCompany = createObjectParser<CompanyFilter | undefined>((object) => {
  const parsedTypes = parseString(object.types);
  const parsedName = parseString(object.name);
  const parsedCompanyIds = parseStringArray(object.companyIds);
  if (parsedTypes) {
    return {
      types: CompanyFilterType[parsedTypes as keyof typeof CompanyFilterType],
      name: parsedName,
      companyIds: parsedCompanyIds,
    };
  }
  return undefined;
});

export const parseBaseLoadSearchRequest = createObjectParser<Partial<BaseLoadSearchRequest>>((object) => {
  return pickDefinedProperties({
    equipmentTypes: parseArrayOfValuesFrom(object.equipmentTypes, EquipmentType),
    loadSize: parseValueFrom(object.loadSize, LoadSize),
    // optional booleans
    includeLoadsWithoutLength: parseBoolean(object.includeLoadsWithoutLength),
    includeLoadsWithoutWeight: parseBoolean(object.includeLoadsWithoutWeight),
    // optional numbers
    minWeight: parseNumber(object.minWeight, {
      min: weightDomain[0],
      max: weightDomain[1],
    }),
    weight: parseNumber(object.weight, {
      min: weightDomain[0],
      max: weightDomain[1],
    }),
    minLength: parseNumber(object.minLength, {
      min: lengthDomain[0],
      max: lengthDomain[1],
    }),
    length: parseNumber(object.length, {
      min: lengthDomain[0],
      max: lengthDomain[1],
    }),
    minMileage: parseNumber(object.minMileage, {
      min: LoadSearchMileage.MIN,
      max: LoadSearchMileage.MAX_REGIONAL + 1,
    }),
    maxMileage: parseNumber(object.maxMileage, {
      min: LoadSearchMileage.MAX_SHORT,
      max: LoadSearchMileage.MAX_REGIONAL,
    }),
  });
});

export const parseLoadSearchRequest = createObjectParser<LoadSearchRequest | undefined>((object) => {
  const { origin, destination, pickupDates, metadata, ...optionalParameters } = object;
  const parsedOrigin = parseOriginLocation(origin);
  const parsedDestination = parseLoadLocation(destination);
  const parsedPickupDates = parseStringArray(pickupDates) || [];
  const parsedMetadata = parseLoadSearchMetadata(metadata);
  if (parsedOrigin && parsedDestination && parsedPickupDates && parsedMetadata) {
    const requiredLoadSearchRequestParameters: LoadSearchRequest = {
      origin: parsedOrigin,
      destination: parsedDestination,
      pickupDates: parsedPickupDates,
      metadata: parsedMetadata,
    };
    const optionalLoadSearchRequestParameters: Partial<LoadSearchRequest> = pickDefinedProperties({
      ...parseBaseLoadSearchRequest(object),
      // optional objects
      originLatLongBounds: parseMapBounds(optionalParameters.originLatLongBounds),
      saveLocations: parseBoolean(optionalParameters.saveLocations),
      includeWithGreaterPickupDates: parseBoolean(optionalParameters.includeWithGreaterPickupDates),
      includeWithGreaterDeliveryDates: parseBoolean(optionalParameters.includeWithGreaterDeliveryDates),
      equipmentSpecifications: parseString(optionalParameters.equipmentSpecifications),
    });
    return { ...requiredLoadSearchRequestParameters, ...optionalLoadSearchRequestParameters };
  }
  return undefined;
});

export const parseCompanySearchRequest = createObjectParser<LoadSearchRequest | undefined>((object) => {
  const { origin, destination, pickupDates, metadata, company, ...optionalParameters } = object;
  const parsedOrigin = parseLoadLocation(origin);
  const parsedDestination = parseLoadLocation(destination);
  const parsedPickupDates = parseStringArray(pickupDates) || [];
  const parsedMetadata = parseLoadSearchMetadata(metadata);
  const parsedCompany = parseCompany(company);
  if (parsedOrigin && parsedDestination && parsedPickupDates && parsedMetadata) {
    const requiredLoadSearchRequestParameters: LoadSearchRequest = {
      origin: parsedOrigin as OriginLocation,
      destination: parsedDestination,
      pickupDates: parsedPickupDates,
      metadata: parsedMetadata,
      company: parsedCompany,
    };
    const optionalLoadSearchRequestParameters: Partial<LoadSearchRequest> = pickDefinedProperties({
      ...parseBaseLoadSearchRequest(object),
      // optional objects
      originLatLongBounds: parseMapBounds(optionalParameters.originLatLongBounds),
      saveLocations: parseBoolean(optionalParameters.saveLocations),
      includeWithGreaterPickupDates: parseBoolean(optionalParameters.includeWithGreaterPickupDates),
      includeWithGreaterDeliveryDates: parseBoolean(optionalParameters.includeWithGreaterDeliveryDates),
      equipmentSpecifications: parseString(optionalParameters.equipmentSpecifications),
    });
    return { ...requiredLoadSearchRequestParameters, ...optionalLoadSearchRequestParameters };
  }
  return undefined;
});

import React, { useMemo } from 'react';

import { map } from 'lodash';
import moment from 'moment';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components';
import { VictoryAxis, VictoryVoronoiContainer } from 'victory';

import {
  BaseRateCheckHistoryGraphComponentProps,
  chartPadding,
  formatMonthTicks,
  formatPriceTicks,
  MONTH_TICK_STYLE,
  PRICE_TICK_STYLE,
  useDomain,
  useOnActivated,
  useRateCheckHistory,
  VORONOI_CONTAINER_PROPS,
} from '@common/helper';
import { RateCheck, SizeProps } from '@common/model';
import {
  BaseChart,
  BaseRedLine,
  EmptyLabelComponent,
  renderGraphLine,
  renderLoadingOrNoDataLabels,
  useBaseRedLinePositionOnClick,
} from '@component/chart/BaseChart';
import { MinMaxBox } from '@page/marketRates/marketRates/widgets/MinMaxBox';
import { Color } from '@style/Color';
import { FontLineHeight, FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

import { RateCheckMinMax, useRateCheckMinMax, useRateCheckRequest } from './RateCheckHelper';

interface Props extends BaseRateCheckHistoryGraphComponentProps {
  isSquare?: boolean;
  rateCheck: RateCheck;
}

type RateCheckHistoryProps = Props & Partial<SizeProps> & { minMax: RateCheckMinMax };

const RateHistoryHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding-top: ${Spacing.Mark}px;
`;

const RateHistoryDate = styled.div`
  color: ${Color.GRAY_21};
  line-height: ${FontLineHeight.Body}px;
  font-size: ${FontSize.ContentDetail}px;
`;

export const RateCheckHistoryWithSize: React.FC<Props> = (props) => {
  const minMaxPerMile = useRateCheckMinMax(props.rateCheck);
  const currentYear = moment(minMaxPerMile.month).year();

  return (
    <>
      <RateHistoryHeader>
        <RateHistoryDate id="rate_history_widget_subtitle">
          {`${t(T.common_rateCheck_legendTitle, {
            month: formatMonthTicks(minMaxPerMile.month ?? moment().valueOf(), true),
          })} ${currentYear}`}
        </RateHistoryDate>
        <MinMaxBox
          id="rate_history_minmax_box"
          maxPerMile={minMaxPerMile.max ?? 0}
          averagePerMile={minMaxPerMile.average ?? 0}
          minPerMile={minMaxPerMile.min ?? 0}
        />
      </RateHistoryHeader>
      <div style={{ height: props.isSquare ? undefined : 350, width: '100%' }}>
        <ReactResizeDetector handleHeight={true} handleWidth={true}>
          {({ width, height }: SizeProps) => (
            <RateCheckHistory height={height} width={width} minMax={minMaxPerMile} {...props} />
          )}
        </ReactResizeDetector>
      </div>
    </>
  );
};

const RateCheckHistory: React.FC<RateCheckHistoryProps> = (props) => {
  const { minMax } = props;
  const minMaxProps = {
    setMax: minMax.setMax,
    setMin: minMax.setMin,
    setAverage: minMax.setAverage,
    setMonth: minMax.setMonth,
    resetRates: minMax.resetRates,
  };
  const request = useRateCheckRequest();

  const { max, average, high, low, isLoading } = useRateCheckHistory(props.id, request, props.loadID);
  const domain = useDomain(average, max, false);
  const onActivated = useOnActivated(average, { ...props, ...minMaxProps }, low, high);
  const { victoryEvents, setPoints, setLinePosition, linePositionAfterClick } = useBaseRedLinePositionOnClick(
    props.width,
    onActivated
  );
  const tickValues = useMemo(() => map(average, (data) => data.date), [average]);

  return (
    <BaseChart
      key={`${isLoading}`}
      keyProp={`${isLoading}`}
      domain={domain}
      width={props.width}
      height={props.isSquare && props.width ? props.width - chartPadding.left : props.height}
      padding={chartPadding}
      events={victoryEvents}
      containerComponent={
        <VictoryVoronoiContainer
          onActivated={setPoints}
          labelComponent={<EmptyLabelComponent setLinePosition={setLinePosition} />}
          {...VORONOI_CONTAINER_PROPS}
        />
      }
    >
      <VictoryAxis
        tickFormat={(tick) => formatMonthTicks(tick)}
        style={{ ...MONTH_TICK_STYLE, axis: { stroke: Color.GRAY_SMOKE }, grid: { stroke: 'transparent' } }}
        tickValues={tickValues}
      />
      <VictoryAxis
        tickFormat={formatPriceTicks}
        style={{
          ...PRICE_TICK_STYLE,
          axis: { stroke: 'transparent' },
          grid: { stroke: Color.GRAY_SMOKE, strokeDasharray: 0 },
        }}
        dependentAxis={true}
        crossAxis={false}
        tickCount={6}
      />
      {renderLoadingOrNoDataLabels(!low && !average && !high, isLoading, props.height, props.width)}
      {renderGraphLine(high, 'date', 'rate', Color.PASTEL_VIOLET)}
      {renderGraphLine(low, 'date', 'rate', Color.BLUE_WILD_YONDER)}
      {renderGraphLine(average, 'date', 'rate', Color.GREEN_JUNE_BUD)}
      {low || average || high ? (
        <BaseRedLine x={linePositionAfterClick} height={props.height} chartPadding={chartPadding} />
      ) : null}
    </BaseChart>
  );
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { LocationsClient } from '@common/client';
import { RecentLocation, RecentLocationType, RecentSearchLocationsResponse } from '@common/model';
import { createApiAction } from '@common/redux/Base';
import { createReducer } from '@common/redux/ReduxHelper';

const recentLocationsAction = createApiAction<RecentLocationType[] | undefined, RecentSearchLocationsResponse>(
  'FETCH_RECENT_LOCATIONS'
);

export const fetchRecentLocations = (locationType?: RecentLocationType[]) =>
  recentLocationsAction.fetchAction(locationType);

export interface RecentSearchLocationsState {
  recentLocations: RecentLocation[];
}

const initialState: RecentSearchLocationsState = {
  recentLocations: [],
};

export const recentSearchLocationsReducer = createReducer(
  initialState,
  recentLocationsAction.completeCase<RecentSearchLocationsState>((state, action) => {
    if (action.response.success && action.response.payload && action.response.payload.locations) {
      state.recentLocations = action.response.payload.locations;
    }
  })
);

export const createRecentSearchLocationsEpic = (api: Api) => {
  const locationsClient = new LocationsClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(recentLocationsAction.createEpic$(action$, locationsClient.fetchRecentLocations$));
};

import { concat, filter, includes } from 'lodash';

import { LoadDetailsTabs } from '@/model';
import { createAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

interface SetOpenedTabsAction {
  tabs: LoadDetailsTabs[];
  shouldAddTabs: boolean;
}

const setOpenedTabsAction = createAction<SetOpenedTabsAction>('SET_OPENED_TABS');

export const setOpenedTabs = (tabs: LoadDetailsTabs[], shouldAddTabs: boolean) =>
  setOpenedTabsAction.action({ tabs: tabs, shouldAddTabs: shouldAddTabs });

export interface LoadDetailsTabsState {
  openedLoadDetailsTabs: LoadDetailsTabs[];
}

const initialState: LoadDetailsTabsState = {
  openedLoadDetailsTabs: [],
};

export const loadDetailsTabsStateReducer = createMergedReducer(initialState, [
  setOpenedTabsAction.addCase((state, action) => {
    const { tabs, shouldAddTabs } = action.data;
    if (shouldAddTabs) {
      state.openedLoadDetailsTabs = concat(state.openedLoadDetailsTabs, tabs);
    } else {
      state.openedLoadDetailsTabs = filter(state.openedLoadDetailsTabs, (tab) => !includes(tabs, tab));
    }
  }),
]);

import React, { useEffect, useRef, useState } from 'react';

import { filter, join, map, split, toNumber, toString, uniq } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import { useSelector } from '@/util/hooks';
import { NO_INFORMATION_SYMBOL } from '@common/formatter';
import {
  formatDateTimeMMMDDhhmma,
  formatPrice,
  getStringifiedDisplayEquipmentList,
  getStringifiedEquipmentSpec,
  getStringifiedEquipmentSpecName,
  getStringifiedEquipmentType,
  validateBid,
} from '@common/helper';
import { getBidButtonAndTitledText, getValidBid, getWillResubmitBid } from '@common/helper/VendorLoadHelper';
import {
  Equipment,
  EquipmentSpecification,
  EquipmentType,
  Flag,
  LoadWithDataForBiddingOnly,
  VendorBidInfo,
} from '@common/model';
import { Vendor } from '@common/model/VendorBid';
import { postBid } from '@common/redux/epic/bids/BidsEpic';
import { bidOnVendorLoad } from '@common/redux/epic/LoadInfoEpic';
import { EXPIRATION_TIMES, ExpirationTime, getExpirationTime } from '@common/util/BidsTimeHelper';
import { VerificationBadge } from '@component/badge/VerificationBadge';
import { FValidatedTextInput, FValidatedTextInputRef } from '@component/input';
import { usePushPanel } from '@component/panel/PanelHooks';
import { EditCompanyPopup } from '@component/panels/findLoads/loadDetailsPanel/EditCompanyPopup';
import { InfoRibbon } from '@component/panels/findLoads/loadDetailsPanel/InfoRibbon';
import {
  FormContainer,
  OverlayContent,
  OverlayContentText,
  OverlayContentTextContainer,
  OverlayContentTitle,
  OverlayTextDetail,
  PopUpButton,
  TextContainer,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { FORCE_VERIFICATION_URL_QUERY } from '@component/panels/settings/company/BusinessProfileHelper';
import { BasePopup } from '@component/popup/BasePopup';
import { BidFailedPopUp } from '@component/popup/BidFailedPopup';
import { PopupContentSection } from '@component/popup/PopupContentSections';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { SelectElement } from '@component/select/Select';
import { Text, TextStyle } from '@component/text';
import { InlineTappableText } from '@component/text/TappableTextContent';
import { PostBidRateCheck } from '@page/bids/BidFormRateCheck';
import { bidsEncoder, BidsTabs } from '@page/bids/BidsPanelRouting';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { Color } from '@style/Color';
import { FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

const OverlaySubmissionContainer = styled.div`
  margin-left: ${Spacing.InterElementHorizontal};
  margin-bottom: ${Spacing.InterElementHorizontal};
`;

const BidDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
interface PlaceBidPopupProps {
  load: LoadWithDataForBiddingOnly | undefined;
}

export const PlaceBidPopup = (props: PlaceBidPopupProps) => {
  const bidInputRef = useRef<FValidatedTextInputRef | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const { closingPopup, openingPopup } = usePopup();

  const loadRate = props.load?.rate?.amount !== undefined ? toString(props.load.rate.amount) : NO_INFORMATION_SYMBOL;
  const [bid, setBid] = useState<string | ''>('');
  const [expirationTime, setExpirationTime] = useState<ExpirationTime | ''>('');
  const [isExpirationTimeInValid, setIsExpirationTimeInvalid] = useState(false);

  const [wasBidSent, setWasBidSent] = useState(false);
  const [bidWasSubmitted, setBidWasSubmitted] = useState(false);

  const vendorBidState = useSelector((state) =>
    props.load?.id !== undefined ? state.loadInfo.loadInfoMap.get(props.load.id)?.vendorBid : undefined
  );

  const userCompanyName = useSelector((state) => state.companyState?.company?.name);
  const userCompanyDOT = useSelector((state) => state.companyState?.company?.usdotNumber);

  const isBidV1Disabled = useSelector((state) => state.settings.systemSetting[Flag.BiddingDisabled].value);
  const isBidV2Disabled = useSelector((state) => state.settings.systemSetting[Flag.BiddingV2Disabled].value);
  const shouldShowExpirationDate = isBidV1Disabled && !isBidV2Disabled;

  // 123LB Rate negotiation
  const didPostBidSuccessfully = useSelector((state) => state.bids.postBid.didPostSuccessfully);
  const isPostingBid = useSelector((state) => state.bids.postBid.isPosting);
  const postedBidId = useSelector((state) => state.bids.postBid.postedBidId);

  const trailerType = props.load?.equipments;
  const willResubmitBid = getWillResubmitBid(props.load?.vendorInfo?.bid);
  const bidButtonAndTitledText = getBidButtonAndTitledText(willResubmitBid);

  const isVendorBid = props.load?.vendorInfo && props.load?.vendorInfo.vendorId !== Vendor.McLeod;
  const hasBid = !!props.load?.vendorInfo?.bid;

  const handlePlaceBid = () => {
    const isBidValid = bidInputRef?.current?.performValidation();

    if (expirationTime === '') {
      setIsExpirationTimeInvalid(true);
    }

    if (isBidValid && props.load?.id && bid) {
      if (isVendorBid && props.load.vendorInfo?.vendorId) {
        setBidWasSubmitted(true);

        dispatch(
          bidOnVendorLoad({
            loadId: props.load.id,
            amount: toNumber(bid),
            vendorId: props.load.vendorInfo.vendorId,
          })
        );
        setWasBidSent(true);
      } else {
        //123LB Rate Negotiation
        if (expirationTime !== '' || !shouldShowExpirationDate) {
          setBidWasSubmitted(true);

          dispatch(
            postBid({
              loadId: props.load?.id,
              price: toNumber(bid),
              expirationTime: expirationTime ? getExpirationTime(expirationTime) : undefined,
            })
          );
          setWasBidSent(true);
        }
      }
    }
  };

  const pushBidPanel = () => {
    if (props.load?.id && postedBidId) {
      history.push({
        pathname: `${generatePath(Routes.BIDS, {
          tab: BidsTabs.InteractiveBids,
          bidID: postedBidId,
          loadID: props.load?.id,
          offerID: postedBidId,
          summary: 'summary',
          edit: 'offer',
        })}`,
        search: bidsEncoder.convertObjectRequestToUrlParams({
          bidId: postedBidId,
          loadId: props.load?.id,
          redirectLoadDetailsPath: history.location.pathname,
          redirectLoadDetailsSearch: history.location.search,
        }),
      });
    }
  };

  useEffect(() => {
    if (wasBidSent) {
      if (!vendorBidState?.isSending && isVendorBid) {
        if (vendorBidState?.isSentSuccessfully) {
          setWasBidSent(false);
          closingPopup();
          openingPopup({ body: <VendorBidPlacedPopUp />, width: PopupSizes.SMALL });
        } else if (!vendorBidState?.isSentSuccessfully) {
          setWasBidSent(false);
          closingPopup();
          openingPopup({
            body: <BidFailedPopUp errorCode={vendorBidState?.errorCode} />,
            width: PopupSizes.SMALL,
          });
        }
      } else if (!isPostingBid && !isVendorBid) {
        if (didPostBidSuccessfully) {
          setWasBidSent(false);
          closingPopup();
          pushBidPanel();
        } else {
          setWasBidSent(false);
          closingPopup();
          openingPopup({ body: <BidFailedPopUp />, width: PopupSizes.SMALL });
        }
      }
    }
  }, [wasBidSent, vendorBidState, isPostingBid, didPostBidSuccessfully]);

  const renderRate = () => {
    return loadRate === NO_INFORMATION_SYMBOL ? (
      <Text id="posted_rate_1">{`${t(T.common_dollar_sign)}${loadRate}`}</Text>
    ) : (
      <InlineTappableText
        id="posted_rate_2"
        accent={true}
        onClick={() => {
          setBid(loadRate);
        }}
      >
        {`${t(T.common_dollar_sign)}${loadRate}`}
      </InlineTappableText>
    );
  };

  const editCompany = () => {
    closingPopup();
    openingPopup({
      body: <EditCompanyPopup load={props.load} />,
      width: PopupSizes.SMALL,
    });
  };

  return (
    <BasePopup title={bidButtonAndTitledText} onClose={closingPopup} withoutContentPadding={true}>
      <InfoRibbon headerLabel={t(T.findLoads_findLoads_popUps_bidHeader_label)}>
        <OverlayContent>
          <BidDetailsComponent
            detailsTitle={t(T.findLoads_findLoads_popUps_bidDetails_bidCompanyName)}
            detailsType={userCompanyName}
          />
          <SubmissionCompanyDetail
            submissionDOTTitle={t(T.findLoads_findLoads_popUps_bidDetails_bidCompanyDOT)}
            submissionCompanyDOT={userCompanyDOT}
            onEditCompany={editCompany}
          />
          <BidTrailerDetailsComponent
            detailsTitle={t(T.findLoads_findLoads_popUps_bidDetails_bidEquipmentType)}
            detailsType={trailerType}
          />
          <BidDetailsComponent
            detailsTitle={t(T.findLoads_findLoads_popUps_bidDetails_bidEquipmentCommodity)}
            detailsType={props.load?.commodity}
          />
          <BidDetailsComponent
            detailsTitle={t(T.findLoads_findLoads_popUps_bidDetails_bidEquipmentNotes)}
            detailsType={props.load?.notes}
          />
        </OverlayContent>
      </InfoRibbon>

      <PopupContentSection>
        <TextContainer id="posted_rate" style={{ marginBottom: '8px' }}>
          {t(T.common_vendorBid_placeBid_postedRate)} {renderRate()}
        </TextContainer>
        <Text id="rate_subtitle">{renderVendorBidForm(willResubmitBid, props.load?.vendorInfo?.bid)}</Text>
        <FormContainer style={{ marginRight: `${Spacing.InterSection}`, marginLeft: `${Spacing.InterSection}` }}>
          <FValidatedTextInput
            ref={bidInputRef}
            id="place_bid_input"
            label={t(T.common_vendorBid_placeBid_flatRate)}
            value={bid}
            onChange={(event) => {
              const formattedBid = getValidBid(event.target.value);
              if (formattedBid !== undefined) {
                setBid(formattedBid);
              }
            }}
            validator={validateBid}
            shouldIgnoreValidation={bidWasSubmitted}
            autoComplete="off"
          />
          <PostBidRateCheck load={props.load} onClickRateCheck={setBid} />
          {!isVendorBid && shouldShowExpirationDate ? (
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <TextContainer id="select_expiration_time_label">{t(T.common_bids_expirationTime_message)}</TextContainer>
              <SelectElement
                label={t(T.common_bids_expirationTime_label)}
                options={EXPIRATION_TIMES}
                id="expiration_time"
                onChange={(value: ExpirationTime) => {
                  setExpirationTime(value);
                  setIsExpirationTimeInvalid(false);
                }}
                value={expirationTime}
                error={isExpirationTimeInValid}
                helperText={t(T.common_bids_expirationTime_requiredError)}
              />
            </div>
          ) : undefined}
          <PopUpButton
            id="submit_bid"
            onClick={handlePlaceBid}
            variant={'contained'}
            text={bidButtonAndTitledText}
            color={'primary'}
            isLoading={wasBidSent}
          />
          {isVendorBid ? (
            <Text
              id="vendor_posted_call_message"
              style={{
                marginTop: Spacing.InterSection,
                marginBottom: 0,
                color: Color.GRAY_STONE,
                fontFamily: `${FontWeight.Regular}`,
                fontSize: `${FontSize.ContentDetail}`,
              }}
            >
              {hasBid ? null : t(T.common_vendorBid_placeBid_submitBidCallMessage)}
            </Text>
          ) : null}
        </FormContainer>
      </PopupContentSection>
    </BasePopup>
  );
};

export const BidDetailsComponent: React.FC<{ detailsTitle: string; detailsType: string | undefined }> = (props) => {
  if (props.detailsType === undefined || props.detailsType == '') {
    return null;
  }
  return (
    <OverlayContentTextContainer>
      <OverlayContentTitle>{props.detailsTitle} </OverlayContentTitle>
      <OverlayContentText style={{ width: '211px' }}>{props.detailsType}</OverlayContentText>
    </OverlayContentTextContainer>
  );
};

export const BidTrailerDetailsComponent: React.FC<{ detailsTitle: string; detailsType: Equipment[] | undefined }> = ({
  detailsTitle,
  detailsType,
}) => {
  if (detailsType === undefined || detailsType.length < 1) {
    return null;
  }
  return (
    <>
      <OverlayContentTextContainer>
        <OverlayContentTitle>{detailsTitle} </OverlayContentTitle>
        <BidDetailsContainer>
          {map(detailsType, ({ equipmentType, equipmentSpecifications }) => {
            const formattedEquipmentSpecifications = getStringifiedEquipmentSpecName(equipmentSpecifications);
            return (
              <OverlayContentText>
                {getStringifiedDisplayEquipmentList(equipmentType)}
                {formattedEquipmentSpecifications ? (
                  <OverlayTextDetail> ({formattedEquipmentSpecifications})</OverlayTextDetail>
                ) : null}
              </OverlayContentText>
            );
          })}
        </BidDetailsContainer>
      </OverlayContentTextContainer>
    </>
  );
};

const SubmissionCompanyDetail: React.FC<{
  submissionDOTTitle: string;
  submissionCompanyDOT: number | undefined | string;
  onEditCompany: () => void;
}> = (props) => {
  const isUsdotNumberVerified = useSelector((state) => state.user.profile?.payload?.isUsdotNumberVerified);
  const pushBusinessProfilePanel = usePushPanel(generatePath(Routes.MORE_SETTINGS, { filter: Filter.BusinessProfile }));
  const history = useHistory();

  const onClickVerificationBadge = () => {
    if (isUsdotNumberVerified) {
      pushBusinessProfilePanel();
    } else {
      history.push({
        pathname: generatePath(Routes.MORE_SETTINGS, { filter: Filter.BusinessProfile }),
        search: `${FORCE_VERIFICATION_URL_QUERY}=1`,
      });
    }
  };

  return (
    <OverlaySubmissionContainer
      style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', lineHeight: '18px' }}
    >
      <OverlayContentTitle>{props.submissionDOTTitle} </OverlayContentTitle>
      <OverlayContentText style={{ marginRight: 2 }}>{props.submissionCompanyDOT}</OverlayContentText>
      <VerificationBadge
        testID="dot_verification_badge"
        isVerified={isUsdotNumberVerified}
        onRedirect={onClickVerificationBadge}
        style={{ marginRight: Spacing.TwoSpaces, marginTop: -1 }}
      />
      <InlineTappableText id="company_edit_button" onClick={props.onEditCompany} accent={true}>
        {t(T.common_edit)}
      </InlineTappableText>
    </OverlaySubmissionContainer>
  );
};

//*** When it is required to return stringified Equipments Type with Equipment Code */
export const stringifyEquipmentsType = (equipments: Equipment[]): string =>
  join(
    uniq(
      map(
        filter(
          equipments,
          (equipment) => equipment.equipmentType !== EquipmentType.None && equipment.equipmentType !== undefined
        ),
        (equipment) => getStringifiedEquipmentType(equipment.equipmentType)
      )
    ),
    ', '
  );

//*** When it is required to return stringified Equipments Specification with Specification Code */
export const stringifyEquipmentsSpec = (equipments: Equipment[]) =>
  join(
    uniq(
      map(
        filter(
          equipments,
          (equipment) => equipment !== undefined && equipment.equipmentSpecifications !== EquipmentSpecification.None
        ),
        (equipment) =>
          join(
            map(split(equipment.equipmentSpecifications, ', '), (equipmentSpec) =>
              getStringifiedEquipmentSpec(equipmentSpec as unknown as EquipmentSpecification)
            ),
            ', '
          )
      )
    ),
    ', '
  );

const VendorBidPlacedPopUp: React.FC = () => {
  const { closingPopup } = usePopup();
  return (
    <BasePopup title={t(T.common_vendorBid_bidPlaced_title)} onClose={closingPopup}>
      <TextContainer>
        <Text id="replace_bid" textStyle={TextStyle.BodyLarge}>
          {t(T.common_vendorBid_bidPlaced_part1)}
        </Text>
      </TextContainer>
      <TextContainer>
        <Text id="replace_bid_info" textStyle={TextStyle.BodyLarge}>
          {t(T.common_vendorBid_bidPlaced_part2)}
        </Text>
      </TextContainer>
      <FormContainer>
        <PopUpButton
          id="submit_new_bid"
          onClick={() => {
            closingPopup();
          }}
          text={t(T.common_vendorBid_bidPlaced_button)}
          variant={'outlined'}
          color={'secondary'}
        />
      </FormContainer>
    </BasePopup>
  );
};

const renderVendorBidForm = (willResubmitBid: boolean, previousBid?: VendorBidInfo) => {
  const amount = toNumber(previousBid?.amount);
  return willResubmitBid ? (
    <>
      <TextContainer>
        <Text id="replace_bid" textStyle={TextStyle.BodyLarge}>
          {t(T.common_vendorBid_placeBid_previousBidPart1)}
        </Text>
      </TextContainer>
      <TextContainer>
        <Text id="replace_bid_info" textStyle={TextStyle.BodyLarge}>
          {t(T.common_vendorBid_placeBid_previousBidPart2, {
            date: formatDateTimeMMMDDhhmma(previousBid?.submittedOn),
            amount: formatPrice(amount),
          })}
        </Text>
      </TextContainer>
    </>
  ) : (
    <TextContainer>
      <Text id="place_bid_info" textStyle={TextStyle.BodyLarge}>
        {t(T.common_vendorBid_placeBid_enter)}
      </Text>
    </TextContainer>
  );
};

import { unregisterServiceWorker } from '@/registerServiceWorker';
import React from 'react';

export default class Logout extends React.Component {
  //@FIXME Added for ticket MEM-1679. componentWillUnmount is not calling. Mind this code if adding any logout popups or confirmations.
  static isProcessingLogout = false;
  componentDidMount() {
    //unregister service worker to clear firebase credentials
    //after login, the browser id will still be the same but receiving
    //messages sent to the new user
    unregisterServiceWorker();

    Logout.isProcessingLogout = true;
    window.location.reload();
  }

  componentWillUnmount() {
    Logout.isProcessingLogout = false;
  }
  render() {
    return null;
  }
}

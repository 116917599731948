import { concat, find, last } from 'lodash';
import moment from 'moment';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api, ApiError, ApiErrorCode } from '@common/api';
import { PaymentClient } from '@common/client/PaymentClient';
import {
  DEFAULT_INVOICE_REQUEST,
  Invoice,
  InvoiceListResponse,
  INVOICES_LIST_REQUEST_LIMIT_APPENDING,
  INVOICES_LIST_REQUEST_LIMIT_DEFAULT,
  INVOICES_LIST_REQUEST_OFFSET_INCREMENT,
  InvoicesFilters,
  InvoicesRequest,
  MembershipReactivateError,
  PaymentDecline,
  PaymentFormErrorType,
  PaymentInfo,
  PaymentMethodUpdateError,
  Period,
  PeriodResponse,
  PlanPaymentInfo,
  UpgradePlanPaymentResult,
} from '@common/model';
import { createAction, createApiAction, ResultResponseAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

interface PaymentHistoryRequest {
  accountId: string;
  fetchRequest: InvoicesRequest;
}

const planPayment = createApiAction<PlanPaymentInfo, {}>('PLAN_PAYMENT');
const reactivatePlanPaymentAction = createApiAction<PlanPaymentInfo, {}>('REACTIVATE_PLAN_PAYMENT');
const updatePaymentMethodAction = createApiAction<PaymentInfo, {}>('UPDATE_PAYMENT_METHOD');
const paymentHistory = createApiAction<PaymentHistoryRequest, InvoiceListResponse>('FETCH_PAYMENT_HISTORY');
const morePaymentHistory = createApiAction<PaymentHistoryRequest, InvoiceListResponse>('FETCH_MORE_PAYMENT_HISTORY');
const fetchPaymentDeclineInfoAction = createApiAction<string, PaymentDecline>('FETCH_PAYMENT_DECLINE_INFO');
const fetchYearsAction = createApiAction<string, PeriodResponse>('FETCH_YEARS');
const setSelectedYearAction = createAction<string>('SET_SELECTED_YEAR');
const clearPaymentResultAction = createAction<undefined>('CLEAR_PAYMENT_RESULT');
const clearPaymentMethodResultAction = createAction<undefined>('CLEAR_PAYMENT_METHOD_RESULT');

export const PLAN_UPGRADED_ACTION = planPayment.responseType;
export const confirmPlanPayment = planPayment.fetchAction;
export const reactivatePlanPayment = reactivatePlanPaymentAction.fetchAction;

export const clearPaymentResult = () => clearPaymentResultAction.action(undefined);
export const clearPaymentMethodResult = () => clearPaymentMethodResultAction.action(undefined);
export const setSelectedYear = (year: string) => setSelectedYearAction.action(year);

export const updatePaymentMethod = (paymentMethod: PaymentInfo) => updatePaymentMethodAction.fetchAction(paymentMethod);
export const fetchPaymentHistory = (accountId: string, fetchRequest: InvoicesRequest) =>
  paymentHistory.fetchAction({ accountId: accountId, fetchRequest: fetchRequest });
export const fetchMorePaymentHistory = (accountId: string, fetchRequest: InvoicesRequest) =>
  morePaymentHistory.fetchAction({ accountId: accountId, fetchRequest: fetchRequest });
export const fetchYears = (accountId: string) => fetchYearsAction.fetchAction(accountId);
export const fetchPaymentDeclineInfo = (accountId: string) => fetchPaymentDeclineInfoAction.fetchAction(accountId);

export interface PaymentState {
  payment: {
    isUpgrading: boolean;
    result?: UpgradePlanPaymentResult;
  };
  paymentMethod: {
    isLoading: boolean;
    isSuccessful?: boolean;
    errorType?: PaymentMethodUpdateError;
  };
  paymentHistory: {
    yearsList: Period[];
    selectedYear?: string;
    list: Invoice[];
    fetchRequest: InvoicesFilters;
    isLoading: boolean;
    isSuccessful?: boolean;
    error?: ApiError;
    isYearsLoading: boolean;
    isLastResult: boolean;
    isLoadingMore: boolean;
    listWasFetched: boolean;
    yearsWasFetched: boolean;
  };
  isLoadingPaymentDeclineInfo: boolean;
  paymentDecline?: PaymentDecline;
  reactivatePlan: {
    errorType?: PaymentFormErrorType;
    isLoading: boolean;
    isReactivatedSuccessful?: boolean;
  };
}

const initialState: PaymentState = {
  payment: {
    isUpgrading: false,
  },
  paymentMethod: {
    isLoading: false,
  },
  paymentHistory: {
    yearsList: [],
    list: [],
    fetchRequest: DEFAULT_INVOICE_REQUEST,
    isLastResult: false,
    isLoadingMore: false,
    isLoading: false,
    isYearsLoading: false,
    isSuccessful: false,
    listWasFetched: false,
    yearsWasFetched: false,
  },
  isLoadingPaymentDeclineInfo: false,
  reactivatePlan: {
    isLoading: false,
  },
};

export const paymentReducer = createMergedReducer(initialState, [
  clearPaymentResultAction.addCase((state) => {
    state.payment = initialState.payment;
  }),

  clearPaymentMethodResultAction.addCase((state) => {
    state.paymentMethod.isSuccessful = undefined;
    state.paymentMethod.errorType = undefined;
  }),

  setSelectedYearAction.addCase((state, action) => {
    state.paymentHistory.selectedYear = action.data;
    state.paymentHistory.fetchRequest = DEFAULT_INVOICE_REQUEST;
    state.paymentHistory.listWasFetched = false;
  }),

  paymentHistory.initiateCase((state) => {
    state.paymentHistory = {
      ...state.paymentHistory,
      isLoading: true,
      list: [],
      fetchRequest: DEFAULT_INVOICE_REQUEST,
      listWasFetched: true,
    };
  }),
  paymentHistory.completeCase((state, action) => fetchedPaymentHistoryReducer(state, action, true)),

  morePaymentHistory.initiateCase((state) => {
    state.paymentHistory.fetchRequest = {
      ...state.paymentHistory.fetchRequest,
      limit: INVOICES_LIST_REQUEST_LIMIT_APPENDING,
      offset:
        state.paymentHistory.fetchRequest.offset === 0
          ? INVOICES_LIST_REQUEST_LIMIT_DEFAULT
          : state.paymentHistory.fetchRequest.offset + INVOICES_LIST_REQUEST_OFFSET_INCREMENT,
    };
    state.paymentHistory.isLoadingMore = true;
  }),
  morePaymentHistory.completeCase((state, action) => fetchedPaymentHistoryReducer(state, action)),

  planPayment.initiateCase((state) => {
    state.payment.isUpgrading = true;
  }),
  planPayment.completeCase((state, action) => {
    state.payment.isUpgrading = false;
    if (action.response.success) {
      state.payment.result = UpgradePlanPaymentResult.Success;
    } else {
      if (action.response.error.httpStatus === 0) {
        state.payment.result = UpgradePlanPaymentResult.Unknown;
      } else {
        state.payment.result = UpgradePlanPaymentResult.Failed;
      }
    }
  }),

  updatePaymentMethodAction.initiateCase((state) => {
    state.paymentMethod = {
      isLoading: true,
      isSuccessful: undefined,
    };
  }),
  updatePaymentMethodAction.completeCase((state, action) => {
    state.paymentMethod = {
      isLoading: false,
      isSuccessful: action.response.success,
    };

    if (!action.response.success && action.response.error) {
      let type = PaymentMethodUpdateError.Unknown;
      switch (action.response.error.code) {
        case ApiErrorCode.CREDITCARD_EXPIRED:
          type = PaymentMethodUpdateError.InvalidExpiryDate;
          break;
        case ApiErrorCode.CREDITCARD_CVD_NOT_ACCEPTED:
          type = PaymentMethodUpdateError.InvalidSecurityCode;
          break;
        case ApiErrorCode.CREDITCARD_AVS_NOT_ACCEPTED:
          type = PaymentMethodUpdateError.AddressVerificationFailed;
          break;
        case ApiErrorCode.CREDITCARD_NUMBER_NOT_ACCEPTED:
          type = PaymentMethodUpdateError.InvalidCardNumber;
          break;
      }
      state.paymentMethod.errorType = type;
    }
  }),

  reactivatePlanPaymentAction.initiateCase((state) => {
    state.reactivatePlan = {
      isLoading: true,
      isReactivatedSuccessful: undefined,
      errorType: undefined,
    };
  }),
  reactivatePlanPaymentAction.completeCase((state, action) => {
    state.reactivatePlan = {
      isLoading: false,
      isReactivatedSuccessful: action.response.success,
      errorType: undefined,
    };

    if (!action.response.success && action.response.error) {
      let errorType;
      switch (action.response.error.code) {
        case ApiErrorCode.CREDITCARD_EXPIRED:
          errorType = PaymentMethodUpdateError.InvalidExpiryDate;
          break;
        case ApiErrorCode.CREDITCARD_CVD_NOT_ACCEPTED:
          errorType = PaymentMethodUpdateError.InvalidSecurityCode;
          break;
        case ApiErrorCode.CREDITCARD_AVS_NOT_ACCEPTED:
          errorType = PaymentMethodUpdateError.AddressVerificationFailed;
          break;
        case ApiErrorCode.CREDITCARD_NUMBER_NOT_ACCEPTED:
          errorType = PaymentMethodUpdateError.InvalidCardNumber;
          break;
        case ApiErrorCode.INVALID_MEMBERSHIP_STATUS:
          errorType = MembershipReactivateError.WrongStatus;
          break;
        case ApiErrorCode.REACTIVATION_CHARGE_INVOICE_ERROR:
          errorType = MembershipReactivateError.ReactivationChargeInvoiceError;
          break;
        default:
          errorType = PaymentMethodUpdateError.Unknown;
      }
      state.reactivatePlan.errorType = errorType;
    }
  }),

  fetchYearsAction.initiateCase((state) => {
    state.paymentHistory.isYearsLoading = true;
    state.paymentHistory.yearsWasFetched = false;
  }),
  fetchYearsAction.completeCase((state, action) => {
    state.paymentHistory.isYearsLoading = false;
    state.paymentHistory.yearsWasFetched = true;
    if (action.response.success) {
      state.paymentHistory.yearsList = action.response.payload.periods;
      const currentYear = moment().year().toString();
      state.paymentHistory.selectedYear =
        find(action.response.payload.periods, ({ date }) => date === currentYear)?.date ??
        last(action.response.payload.periods)?.date;
    }
  }),

  fetchPaymentDeclineInfoAction.initiateCase((state) => {
    state.isLoadingPaymentDeclineInfo = true;
  }),
  fetchPaymentDeclineInfoAction.completeCase((state, action) => {
    state.isLoadingPaymentDeclineInfo = false;
    if (action.response.success && action.response.payload) {
      state.paymentDecline = action.response.payload;
    }
  }),
]);

const fetchedPaymentHistoryReducer = (
  state: PaymentState,
  action: ResultResponseAction<InvoiceListResponse>,
  isInitial: boolean = false
) => {
  if (action.response.success) {
    const invoices = action.response.payload.invoices;
    state.paymentHistory.list = isInitial ? invoices : concat(state.paymentHistory.list, invoices);
    state.paymentHistory.isLastResult = invoices.length < state.paymentHistory.fetchRequest.limit;
  }
  state.paymentMethod.isSuccessful = action.response.success;
  state.paymentHistory.isLoading = false;
  state.paymentHistory.isLoadingMore = false;
  state.paymentHistory.fetchRequest = {
    ...state.paymentHistory.fetchRequest,
    limit: INVOICES_LIST_REQUEST_LIMIT_APPENDING,
    offset:
      state.paymentHistory.fetchRequest.offset === 0
        ? INVOICES_LIST_REQUEST_LIMIT_DEFAULT
        : state.paymentHistory.fetchRequest.offset + INVOICES_LIST_REQUEST_OFFSET_INCREMENT,
  };
};

export const createPaymentEpic = (api: Api) => {
  const client = new PaymentClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      planPayment.createEpic$(action$, client.sendPayment$),
      reactivatePlanPaymentAction.createEpic$(action$, client.reactivatePayment$),
      updatePaymentMethodAction.createEpic$(action$, client.updatePaymentCard$),
      fetchYearsAction.createEpic$(action$, client.getPaymentYears$),
      paymentHistory.createEpic$(action$, (data) => client.getPaymentHistory$(data.accountId, data.fetchRequest)),
      morePaymentHistory.createEpic$(action$, (data) => client.getPaymentHistory$(data.accountId, data.fetchRequest)),
      fetchPaymentDeclineInfoAction.createEpic$(action$, client.fetchPaymentDeclineInfo$)
    );
};

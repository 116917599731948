import { BaseClient } from '@common/client/BaseClient';
import { PostVendorBidRequest, PostVendorBidResponse, VendorBidsResponse } from '@common/model/VendorBid';

export class VendorBidsClient extends BaseClient {
  postVendorBid$ = (data: PostVendorBidRequest) =>
    this.api.post$<PostVendorBidResponse>(`/carrier/loads/${data.loadId}/vendor-bids`, {
      amount: data.amount,
      vendorId: data.vendorId,
    });

  getVendorBids$ = (nextToken?: string) =>
    this.api.get$<VendorBidsResponse>(`/carrier/vendor-bids`, { maxBidAgeSeconds: 1209600, token: nextToken }); // maxBidAge == 14 days, the FE shows vendor bids that are up to 2 weeks old
}

import React, { RefObject } from 'react';

import { map } from 'lodash';
import styled from 'styled-components';

import { Color } from '@style/Color';
import { px } from '@style/StyleConstants';
import { withTheme } from '@style/WithTheme';

interface Props {
  startPositionRef: RefObject<HTMLElement>;
  endPositionRef: RefObject<HTMLElement>;
  stopsPositionRef: RefObject<(HTMLElement | null)[]>;
}

interface DetailsSpacerProps {
  spacerHeight: number | undefined;
}

const DetailsSpacer = withTheme()(styled.div<DetailsSpacerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: ${(props) => props.spacerHeight && px(props.spacerHeight)};
`);

const Circle = styled.span`
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  border: 2px solid gray;
  background-color: ${Color.WHITE};
  z-index: 10;
  top: ${(props: { offsetTop?: number }) => props.offsetTop ?? 0}px;
`;

const Square = styled.span`
  height: 6px;
  width: 6px;
  border: 2px solid ${Color.ORANGE_MAIN};
`;

const Dots = styled.span`
  display: flex;
  flex: 1;
  border-left: 2px dotted gray;
  opacity: 0.5;
`;

export const LocationsSpacer: React.FC<Props> = ({ startPositionRef, endPositionRef, stopsPositionRef }) => {
  const getTitlesGap = () => {
    if (startPositionRef.current && endPositionRef.current) {
      return (
        endPositionRef.current.getBoundingClientRect().bottom -
        endPositionRef.current.getBoundingClientRect().height / 2 -
        startPositionRef.current.getBoundingClientRect().top
      );
    }
    return undefined;
  };
  const getStopCircleOffsetTop = (stopElement: HTMLElement | null) => {
    if (startPositionRef.current && stopElement) {
      return stopElement.getBoundingClientRect().top - startPositionRef.current.getBoundingClientRect().top;
    }
    return undefined;
  };
  return (
    <DetailsSpacer spacerHeight={getTitlesGap()}>
      <Circle />
      <Dots />
      {map(stopsPositionRef.current, (stopRef) => (
        <Circle offsetTop={getStopCircleOffsetTop(stopRef)} />
      ))}
      <Square />
    </DetailsSpacer>
  );
};

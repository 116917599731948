export enum MCPRiskAssessment {
  LOW = 'Low',
  MODERATE = 'Moderate',
  REVIEW = 'Review',
  FAIL = 'Fail',
  UNKNOWN = 'Unknown',
}

export interface SendMCPVerificationData {
  dotNumber: string | number;
  emailAddress: string;
}

export interface MCPVerificationResponse {
  verificationAlreadyInitiated: boolean;
  instructionsSent: boolean;
  emailSentDate?: string;
}

export interface MCPLinkAccount {
  username: string;
  password: string;
}

export interface MCPCarrierAssessment {
  isBrokerAccountLinkedToMcp: boolean;
  isOnboardingRequestSent: boolean;
  isCarrierOnboarded: boolean;
  riskAssessment: MCPRiskAssessment;
}

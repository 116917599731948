import React from 'react';

import styled from 'styled-components';

import { MultipurposeButton } from '@component/buttons';
import { T, t } from '@translate';

type OfferAction = () => void;

export const OfferButton = styled(MultipurposeButton)`
  && {
    border-radius: 100px;
    font-size: 12px;
    height: 30px;
    align-self: flex-start;
  }
`;

interface PendingOfferActionsProps {
  isLoadingAction: boolean;
  offerMadeByMe: boolean;
  onEdit: OfferAction;
  onAccept: OfferAction;
  onCounter: OfferAction;
  onCancel: OfferAction;
  onReject: OfferAction;
}

export const PendingOfferActions = (props: PendingOfferActionsProps) => {
  const { isLoadingAction, offerMadeByMe, onEdit, onAccept, onCounter, onCancel, onReject } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div>
        {offerMadeByMe ? (
          <OfferButton
            id="edit_offer"
            color="primary"
            onClick={onEdit}
            text={t(T.common_bids_bidItem_actions_edit)}
            variant="outlined"
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <OfferButton
              id="accept"
              color="primary"
              isLoading={isLoadingAction}
              onClick={isLoadingAction ? undefined : onAccept}
              spinnerSize={18}
              text={t(T.common_bids_bidItem_actions_accept)}
              variant="outlined"
            />
            <OfferButton
              id="counter_offer"
              color="primary"
              onClick={onCounter}
              text={t(T.common_bids_bidItem_actions_counter)}
              variant="outlined"
            />
          </div>
        )}
      </div>
      <div>
        {offerMadeByMe ? (
          <OfferButton
            id="cancel_button_withdraw"
            color="secondary"
            isLoading={isLoadingAction}
            onClick={isLoadingAction ? undefined : onCancel}
            spinnerSize={18}
            text={t(T.common_bids_bidItem_actions_cancel)}
            variant="outlined"
          />
        ) : (
          <OfferButton
            id="reject_button"
            color="secondary"
            isLoading={isLoadingAction}
            onClick={isLoadingAction ? undefined : onReject}
            spinnerSize={18}
            text={t(T.common_bids_bidItem_actions_reject)}
            variant="outlined"
          />
        )}
      </div>
    </div>
  );
};

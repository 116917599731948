import React, { useEffect } from 'react';

import { padStart } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Bid, BidStatus, BidSummary } from '@common/model';
import { fetchSystemTime } from '@common/redux/epic/SystemTimeEpic';
import { useCountdown } from '@common/util/BidsTimeHelper';
import { Banner, BannerType } from '@component/banner/Banner';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

const BoldText = styled.span`
  font-weight: bold;
`;

interface ExpirationTimeBannerProps {
  bidId: string;
}

export const ExpirationTimeBanner = (props: ExpirationTimeBannerProps) => {
  const dispatch = useDispatch();

  const mostRecentBid = useSelector((state) => state.bids.loadBids.entities.get(props.bidId));
  const mostRecentBidSummary = useSelector((state) => state.bids.bidSummaries.summaries.get(props.bidId));
  const { systemTime, systemTimeReceivedAt } = useSelector((state) => state.systemTime);

  useEffect(() => {
    dispatch(fetchSystemTime());
  }, []);

  let bid: Bid | BidSummary | undefined = mostRecentBidSummary;
  if (mostRecentBid && mostRecentBidSummary?.status !== mostRecentBid.status) {
    bid = mostRecentBid;
  }

  return bid?.expirationDate &&
    systemTime &&
    (bid.status === BidStatus.Pending || bid?.status === BidStatus.ActionRequired) ? (
    <Banner
      bannerType={BannerType.Warning}
      title={t(T.common_bids_expirationTime_title)}
      content={
        <div>
          <span style={{ marginRight: 5 }}>{t(T.common_bids_expirationTime_bannerText)}:</span>
          <TimeLeft
            expirationTime={bid.expirationDate}
            systemTime={systemTime}
            systemTimeReceivedAt={systemTimeReceivedAt ?? 0}
          />
        </div>
      }
    />
  ) : null;
};

interface TimeLeftProps {
  expirationTime: string;
  systemTime: string;
  systemTimeReceivedAt: number;
}

const TimeLeft = (props: TimeLeftProps) => {
  const { expirationTime, systemTime, systemTimeReceivedAt } = props;

  const { hours, minutes } = useCountdown(expirationTime, systemTime, systemTimeReceivedAt);

  if (hours > 0) {
    return (
      <BoldText>
        {hours}
        {t(T.common_time_abbreviation_Hours)} {padStart(minutes.toString(), 2, '0')}
        {t(T.common_time_abbreviation_MinutesShort)}
      </BoldText>
    );
  }

  if (minutes > 0) {
    return (
      <BoldText>
        {minutes}
        {t(T.common_time_abbreviation_MinutesShort)}
      </BoldText>
    );
  }

  return <BoldText>{t(T.common_time_oneMinute)}</BoldText>;
};

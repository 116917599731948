import {
  ShareableLoadList,
  ShareableLoadListResponse,
  ShareableLoadPatchRequest,
  ShareableLoadPostRequest,
  ShareableLoadView,
} from '@/model/URLScrape';
import { BaseClient } from '@common/client/BaseClient';
import { EmptyResponse } from '@common/redux/Base';

export class ShareableLoadsClient extends BaseClient {
  getShareableLoadList$ = () => this.api.get$<ShareableLoadListResponse>('/broker/loads/share', {});
  deleteShareableLoad$ = (id: string) => this.api.delete$<EmptyResponse>(`/broker/loads/share/${id}`, {});
  postShareableLoad$ = (request: ShareableLoadPostRequest) =>
    this.api.post$<ShareableLoadView>('/broker/loads/share', request);
  updateShareableLoad$ = ({ id, ...rest }: ShareableLoadPatchRequest) =>
    this.api.patch$<ShareableLoadView>(`/broker/loads/share/${id}`, rest);
  getSharedBrokerLoads$ = (id: string) => this.api.get$<ShareableLoadList>(`/broker/share/${id}/loads`, {});
}

import { useEffect, useState } from 'react';

import { isNumber, noop } from 'lodash';

const visualViewport = (window as any).visualViewport as {
  addEventListener?: (type: 'resize', fn: () => void) => void;
  removeEventListener?: (type: 'resize', fn: () => void) => void;
  height: number;
};

/**
 * Uses a draft browser api to see when the visible viewport changes to infer the keyboard is open, will also be triggered if the user uses pinch zooming
 */
export const useIsKeyboardOpen = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const scrollingHeight = document.scrollingElement?.clientHeight;
  const isKeyboardDetectable = 'visualViewport' in window && visualViewport;
  useEffect(() => {
    if (!isKeyboardDetectable) {
      return noop;
    }
    const keyboardHandler = () => {
      setIsKeyboardOpen(isNumber(scrollingHeight) && visualViewport.height < scrollingHeight * 0.9);
    };
    visualViewport.addEventListener?.('resize', keyboardHandler);
    return () => {
      visualViewport.removeEventListener?.('resize', keyboardHandler);
    };
  }, []);
  return { isKeyboardDetectable: isKeyboardDetectable, isKeyboardOpen: isKeyboardOpen };
};

interface LocationSuggestionCityState {
  type: SuggestionTypes.CityState;
  label: string;
  latitude: number;
  longitude: number;
  state: string;
  city: string; //TODO: Validate when used outside ratecheck. possibly might be null.
  stateUrlId: string;
  cityUrlId: string;
  zipCode: string;
}

interface LocationSuggestionState {
  type: SuggestionTypes.State;
  label: string;
  latitude: number;
  longitude: number;
  state: string;
  stateUrlId: string;
  city?: string;
  zipCode?: string;
}

interface LocationSuggestionZipCode {
  type: SuggestionTypes.ZipCode;
  label: string;
  latitude: number;
  longitude: number;
  state: string;
  city: string; //TODO: Validate when used outside ratecheck. possibly might be null.
  stateUrlId: string;
  cityUrlId: string;
  zipCode: string;
}

interface LocationSuggestionGeolocation {
  type: SuggestionTypes.Geolocation;
  label: string;
  latitude: number;
  longitude: number;
  state?: string;
  city?: string; //TODO: Validate when used outside ratecheck. possibly might be null.
  stateUrlId?: string;
  cityUrlId?: string;
  zipCode?: string;
}

export const enum SuggestionTypes {
  CityState = 'CityState',
  State = 'State',
  ZipCode = 'ZipCode',
  Geolocation = 'Geolocation',
}

export type LocationSuggestion =
  | LocationSuggestionCityState
  | LocationSuggestionState
  | LocationSuggestionZipCode
  | LocationSuggestionGeolocation;

import { DisplayOption } from '@common/helper/InterfaceHelper';

export type RegionsList = DisplayOption<string[]>[];

export const CanadaRegionOptions: RegionsList = [
  {
    displayValue: 'RE (NB, NL, NS, PE)',
    value: ['NB', 'NL', 'NS', 'PE'],
    key: 'RE',
    id: 'RE',
  },
  {
    displayValue: 'RC (ON, QC)',
    value: ['ON', 'QC'],
    key: 'RC',
    id: 'RC',
  },
  {
    displayValue: 'RW (AB, BC, MB, SK)',
    value: ['AB', 'BC', 'MB', 'SK'],
    key: 'RW',
    id: 'RW',
  },
  {
    displayValue: 'RN (YT, NT, NU)',
    value: ['YT', 'NT', 'NU'],
    key: 'RN',
    id: 'RN',
  },
];

export const USARegionOptions: RegionsList = [
  {
    displayValue: 'R0 (CT, ME, MA, NH, NJ, RI, VT)',
    value: ['CT', 'ME', 'MA', 'NH', 'NJ', 'RI', 'VT'],
    key: 'R0',
    id: 'R0',
  },
  {
    displayValue: 'R1 (DE, NY, PA)',
    value: ['DE', 'NY', 'PA'],
    key: 'R1',
    id: 'R1',
  },
  {
    displayValue: 'R2 (MD, NC, SC, VA, WV)',
    value: ['MD', 'NC', 'SC', 'VA', 'WV'],
    key: 'R2',
    id: 'R2',
  },
  {
    displayValue: 'R3 (AL, FL, GA, MS, TN)',
    value: ['AL', 'FL', 'GA', 'MS', 'TN'],
    key: 'R3',
    id: 'R3',
  },
  {
    displayValue: 'R4 (IN, KY, MI, OH)',
    value: ['IN', 'KY', 'MI', 'OH'],
    key: 'R4',
    id: 'R4',
  },
  {
    displayValue: 'R5 (IA, MN, MT, ND, SD, WI)',
    value: ['IA', 'MN', 'MT', 'ND', 'SD', 'WI'],
    key: 'R5',
    id: 'R5',
  },
  {
    displayValue: 'R6 (IL, KS, MO, NE)',
    value: ['IL', 'KS', 'MO', 'NE'],
    key: 'R6',
    id: 'R6',
  },
  {
    displayValue: 'R7 (AR, LA, OK, TX)',
    value: ['AR', 'LA', 'OK', 'TX'],
    key: 'R7',
    id: 'R7',
  },
  {
    displayValue: 'R8 (AZ, CO, ID, NV, NM, UT, WY)',
    value: ['AZ', 'CO', 'ID', 'NV', 'NM', 'UT', 'WY'],
    key: 'R8',
    id: 'R8',
  },
  {
    displayValue: 'R9 (CA, OR, WA, AK)',
    value: ['CA', 'OR', 'WA', 'AK'],
    key: 'R9',
    id: 'R9',
  },
];

export const UsaAndCanadaRegionOptions = [...USARegionOptions, ...CanadaRegionOptions];

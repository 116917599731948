import React, { useEffect, useMemo, useState } from 'react';

import { isNumber, isUndefined } from 'lodash';
import {
  Point,
  VictoryBoxPlot,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryThemeDefinition,
} from 'victory';
import { VictoryChartProps } from 'victory-chart';
import { PointProps } from 'victory-core';

import { displayCurrencyOrNothing } from '@common/formatter';
import { chartPadding, ChartPaddingBlockProps, defaultAnimation, useBaseRedLineYCoordinates } from '@common/helper';
import { Color } from '@style/Color';
import { FontFamily, FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';

const { material } = VictoryTheme;

const COLOR_SCALE_PROPS = { colorScale: [] };
const THEME: VictoryThemeDefinition = {
  ...material,
  chart: {
    ...COLOR_SCALE_PROPS,
    ...material.chart,
    padding: chartPadding as unknown as number,
  },
  axis: {
    ...COLOR_SCALE_PROPS,
    ...material.axis,
    style: {
      ...material.axis?.style,
      ticks: { stroke: Color.TRANSPARENT_0 },
      axis: { strokeWidth: 1, stroke: Color.BLACK },
      grid: { ...material.axis?.style?.grid, stroke: Color.GRAY_SMOKE },
      tickLabels: {
        ...material.axis?.style?.tickLabels,
        color: Color.GRAY_STONE,
        fontFamily: FontFamily.Main,
      },
    },
  },
  line: {
    ...COLOR_SCALE_PROPS,
    ...material.line,
    style: {
      ...material.line?.style,
      data: { ...material.line?.style?.data, strokeWidth: 1 },
    },
  },
};

interface Props extends VictoryChartProps {
  legend?: React.ReactNode;
  keyProp?: string;
}

export const BaseChart: React.FC<Props> = (props) => {
  const { legend, keyProp, ...chartProps } = props;
  const chartStyle = useMemo<{ parent: React.CSSProperties }>(
    () => ({
      parent: { height: 'auto', minWidth: '50px', userSelect: 'auto', touchAction: 'auto' },
    }),
    []
  );
  return (
    <div id="chart-container">
      {legend ? legend : null}
      <VictoryChart key={keyProp} theme={THEME} {...chartProps} style={chartProps.style ?? chartStyle}>
        {props.children}
      </VictoryChart>
    </div>
  );
};

export const BaseRedLine: React.FC<{ x?: number; height?: number; chartPadding: number | ChartPaddingBlockProps }> = (
  props
) => {
  const { y1, y2 } = useBaseRedLineYCoordinates(props.height, props.chartPadding);
  return <line stroke={Color.RED_APPLE} x1={props.x} x2={props.x} y1={y1} y2={y2} strokeWidth={1} />;
};

export const EmptyLabelComponent: React.FC<{ x?: number; setLinePosition: (x: number) => void }> = (props) => {
  useEffect(() => {
    if (props.x) {
      props.setLinePosition(Math.floor(props.x));
    }
  });
  if (props.x === undefined) {
    return null;
  }
  return <span />;
};

const PointWithInitialPosition: React.FC<PointProps & { setLinePosition: (x: number) => void }> = (props) => {
  const currentMonthIndex = new Date().getMonth();
  useEffect(() => {
    if (props.index === currentMonthIndex && props.x) {
      props.setLinePosition(props.x);
    }
  }, [currentMonthIndex, props.index, props.x]);
  return <Point {...props} />;
};

export const renderGraphLine = <T,>(
  data: T[] | undefined,
  x: Extract<keyof T, string>,
  y: Extract<keyof T, string>,
  color: string,
  setInitialLinePosition?: (x: number) => void
): React.ReactNode =>
  data ? (
    <VictoryGroup color={color}>
      <VictoryLine animate={defaultAnimation} data={data} x={x} y={y} style={{ data: { strokeWidth: 2 } }} />
      <VictoryScatter
        size={2}
        data={data}
        x={x}
        y={y}
        dataComponent={
          setInitialLinePosition ? <PointWithInitialPosition setLinePosition={setInitialLinePosition} /> : undefined
        }
      />
    </VictoryGroup>
  ) : null;

export const renderLoadingOrNoDataLabels = (
  shouldShowLabels: boolean,
  isLoading: boolean | undefined = false,
  height: number | undefined = 0,
  width: number | undefined = 0,
  labelFontSize?: number
): React.ReactNode =>
  shouldShowLabels ? (
    <VictoryLabel
      x={width / 2}
      y={height / 2}
      textAnchor={'middle'}
      dominant-baseline={'middle'}
      text={isLoading ? t(T.common_loading) : t(T.common_noData)}
      style={{ fill: Color.GRAY_STONE, fontFamily: FontFamily.Main, fontSize: labelFontSize ?? FontSize.Header }}
    />
  ) : null;

export const useBaseRedLinePositionOnClick = (
  width: number | undefined,
  onActivated: (points: { _x: number }[]) => void,
  id?: string
) => {
  const [linePosition, setLinePosition] = useState(0);
  const [linePositionAfterClick, setLinePositionAfterClick] = useState(0);
  const [points, setPoints] = useState<{ _x: number }[]>([]);

  useEffect(() => {
    if (width) {
      const newLinePosition = width - chartPadding.right;
      presetPosition(newLinePosition);
    }
  }, [width, id]);

  const presetPosition = (position: number | undefined) => {
    if (position) {
      setLinePosition(position);
      setLinePositionAfterClick(position);
    }
  };

  const events = [
    {
      target: 'parent',
      eventHandlers: {
        onClick: () => {
          setLinePositionAfterClick(linePosition);
          onActivated(points);
        },
      },
    },
  ];

  return {
    linePositionAfterClick: linePositionAfterClick,
    victoryEvents: events,
    setLinePosition: setLinePosition,
    setInitialLinePosition: presetPosition,
    setPoints: setPoints,
  };
};

const commonLabelProps = {
  style: {
    fontFamily: FontFamily.Main,
    fontSize: FontSize.Caption,
  },
  backgroundStyle: { fill: Color.GRAY_LIGHT },
  renderInPortal: true,
  backgroundPadding: 3,
  dx: -2,
};

export const renderBoxPlot = <T,>(
  boxPlotData: T[] | undefined,
  medianData: T[] | undefined,
  x: Extract<keyof T, string>,
  y: Extract<keyof T, string>
): React.ReactNode => {
  return boxPlotData ? (
    <VictoryGroup color={Color.GRAY_DARK}>
      <VictoryBoxPlot
        animate={defaultAnimation}
        x={x}
        y={y}
        whiskerWidth={15}
        boxWidth={0}
        data={boxPlotData}
        minLabels={!!boxPlotData}
        maxLabels={!!boxPlotData}
        medianLabels={!!boxPlotData}
        labelOrientation="top"
        maxLabelComponent={
          <VictoryLabel
            {...commonLabelProps}
            dy={-22}
            text={(rate) => labelValue(rate.datum?._max, rate.datum?._median)}
          />
        }
        medianLabelComponent={
          <VictoryLabel {...commonLabelProps} dy={-14} text={(rate) => labelValue(rate.datum?._median)} />
        }
        minLabelComponent={
          <VictoryLabel
            {...commonLabelProps}
            dy={14}
            text={(rate) => labelValue(rate.datum?._min, rate.datum?._median)}
          />
        }
      />
      <VictoryLine animate={defaultAnimation} data={medianData} x={x} y={y} style={{ data: { strokeWidth: 2 } }} />
      <VictoryScatter size={4} data={medianData} x={x} y={y} dataComponent={<Point />} />
    </VictoryGroup>
  ) : null;
};

const labelValue = (value?: number, median?: number) => {
  if (isUndefined(value) || !isNumber(value)) {
    return '';
  }
  // this is to hide min, max labels overlapping with median label
  if (!isUndefined(median) && value === median) {
    return '';
  }
  return displayCurrencyOrNothing(value, false);
};

import { useEffect, useState } from 'react';

import { interval, Observable } from 'rxjs';
import { map as map$, share } from 'rxjs/operators';

const timers: { [key: number]: Observable<number> } = {};

export const SYNCTIMER_STOPPED = 0;

const createTimer: (period: number) => Observable<number> = (period: number) =>
  interval(period * 1000)
    .pipe(map$(() => Date.now()))
    .pipe(share());

/**
 *
 * @param period period of interval. Use SYNCTIMER_STOPPED to not set interval.
 */

export const useSyncTimer = (period: number) => {
  const [timestamp, setTimestamp] = useState(Date.now());
  useEffect(() => {
    if (period === SYNCTIMER_STOPPED) {
      return undefined;
    }
    if (timers[period] === undefined) {
      timers[period] = createTimer(period);
    }
    const subscription = timers[period].subscribe(setTimestamp);
    return () => {
      subscription.unsubscribe();
    };
  }, [period]);
  return timestamp;
};

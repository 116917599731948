import React from 'react';

import { Text } from '@component/text/Text';

export interface CustomListItemProps {
  id: string;
}

export const CustomListItem: React.SFC<CustomListItemProps> = (props) => (
  <li>
    <Text id={props.id}>{props.children}</Text>
  </li>
);

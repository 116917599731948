import React from 'react';

import { BasePopup } from '@component/popup/BasePopup';
import { PopupCancelButton, PopupOkButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupSizes, PopupTracking } from '@component/popup/PopupTrackingContext';
import { T, t } from '@translate';

const ok = (dismissDialog: () => void) => [<PopupOkButton testID={'ok'} key={'ok'} action={dismissDialog} />];

export const showLocationUnavailableDialog = (popupContext?: PopupTracking) => {
  if (popupContext) {
    const { openingPopup, closingPopup } = popupContext;
    openingPopup({
      body: (
        <BasePopup title={t(T.permissions_location_unavailable_title)} buttons={ok(closingPopup)}>
          {t(T.permissions_location_unavailable_content)}
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  }
};

export const showLocationTimeoutDialog = (popupContext?: PopupTracking) => {
  if (popupContext) {
    const { openingPopup, closingPopup } = popupContext;
    openingPopup({
      body: (
        <BasePopup title={t(T.permissions_location_timeout_title)} buttons={ok(closingPopup)}>
          {t(T.permissions_location_timeout_content)}
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  }
};

export const showLocationDeniedDialog = (popupContext?: PopupTracking) => {
  if (popupContext) {
    const { openingPopup, closingPopup } = popupContext;
    openingPopup({
      body: (
        <BasePopup
          title={t(T.permissions_location_denied_title)}
          buttons={[
            <PopupCancelButton testID={'cancel'} key={'cancel'} action={closingPopup} />,
            <PopupPrimaryButton
              testID={'learn_how'}
              key={'learn_how'}
              label={t(T.confirmationDialog_LearnHow)}
              action={() => {
                closingPopup();
                window.open(
                  'https://help.123loadboard.com/hc/en-us/articles/360041237574-How-do-I-enable-my-location-in-my-browser-'
                );
              }}
            />,
          ]}
        >
          {t(T.permissions_location_denied_content)}
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  }
};

export enum LoadDetailsTabs {
  LoadDocuments = 'LoadDocuments',
  ProfitCalculator = 'ProfitCalculator',
  MarketRates = 'MarketRates',
  Backhauls = 'Backhauls',
  Company = 'Company',
  CreditRating = 'CreditRating',
  Mileage = 'Mileage',
  CompanyLoads = 'CompanyLoads',
  Stepper = 'Stepper',
  PostedStepper = 'PostedStepper',
}

import { cloneDeep, filter, findIndex, isEqual } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat as concat$, merge as merge$, Observable, of as of$ } from 'rxjs';
import { distinctUntilChanged, map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { PostLoadsClient } from '@/client';
import {
  BrokerLoadsSummaryResponseMetadata,
  BulkPatchLoadsFields,
  createDefaultLoadTemplatesRequest,
  LoadViewers,
  MY_POSTED_LOADS_REQUEST_FIELDS_DEFAULT,
  MY_POSTED_LOADS_REQUEST_FIELDS_WITH_POSTER,
  MY_POSTED_LOADS_REQUEST_LIMIT_APPENDING,
  PostedLoadStatus,
  PostLoadAddress,
  RateCheckRequest,
  ViewerInfo,
} from '@/model';
import { StoreState } from '@/reduxStore/Store';
import { Api, ApiError, ApiResponse123 } from '@common/api';
import {
  addFetchedTime,
  addLoadFetchedTime,
  createDefaultLoadPostSearchRequest,
  LoadBulkUpdate,
  UpdateCategory,
  updateLoad,
  updateLoadsListAfterBulkUpdate,
} from '@common/helper';
import {
  Address,
  LegacyPosterLoadSearchRequest,
  Load,
  LoadStatus,
  LoadTemplate,
  PostedLoad,
  PostedLoadProgress,
  PostedLoadSearchResponse as LoadSearchResponseModel,
  PosterLoadSearchPaginationRequest,
  PosterLoadSearchRequest,
  RateCheck,
  SetPostedProgressRequest,
} from '@common/model';
import { CarrierInfo } from '@common/model/Carrier';
import { LoadTemplateResponse } from '@common/model/LoadTemplates';
import {
  createApiAction,
  createApiActionWithFetchData,
  EmptyResponse,
  Response,
  ResponseAction,
  SearchResponseMetadata,
} from '@common/redux/Base';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';
import { getMergedPostedLoads } from '@common/redux/epic/loadSearch/LoadSearchReducer';
import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';
import { createReducer } from '@common/redux/ReduxHelper';
import { DeleteLoadTemplatesRequest, LoadTemplatesClient } from '@webApi/LoadTemplatesClient';

const FETCH_POSTED_LOADS = 'FETCH_POSTED_LOADS';
const FETCH_NEXT_POSTED_LOADS = 'FETCH_NEXT_POSTED_LOADS';
export const POSTED_LOADS_FETCHED = 'POSTED_LOADS_FETCHED';
const SET_POSTED_LOAD_STATUS = 'SET_POSTED_LOAD_STATUS';
export const SET_POSTED_LOAD_STATUS_RESPONSE = 'SET_POSTED_LOAD_STATUS_RESPONSE';
const UPDATE_LOAD_FAILED = 'UPDATE_LOAD_FAILED';
const CLEAR_LOAD_UPDATE = 'CLEAR_LOAD_UPDATE';
const DELETE_POSTED_LOAD = 'DELETE_POSTED_LOAD';
export const POSTED_LOAD_DELETED = 'POSTED_LOAD_DELETED';
const REFRESH_LOAD_AGE = 'REFRESH_LOAD_AGE';
export const REFRESH_LOAD_AGE_RESPONSE = 'REFRESH_LOAD_AGE_RESPONSE';
const FETCH_LOAD_VIEWERS = 'FETCH_LOAD_VIEWERS';
const LOAD_VIEWERS_FETCHED = 'LOAD_VIEWERS_FETCHED';
const FETCH_CARRIER_INFO = 'FETCH_CARRIER_INFO';
const CARRIER_INFO_FETCHED = 'CARRIER_INFO_FETCHED';
const MILEAGE_REQUEST = 'MILEAGE_REQUEST';
const MILEAGE_RESPONSE = 'MILEAGE_RESPONSE';
const FETCH_RATE_CHECK_INFO = 'FETCH_RATE_CHECK_INFO';
const RATE_CHECK_INFO_FETCHED = 'RATE_CHECK_INFO_FETCHED';
const REFRESH_OFFLINE_LOADS_LIST = 'REFRESH_OFFLINE_LOADS_LIST';
const CLEAR_LOAD_FETCH_ERRORS = 'CLEAR_LOAD_FETCH_ERRORS';
const TOGGLE_INCLUDE_COMPANY_LOADS = 'TOGGLE_INCLUDE_COMPANY_LOADS';

const fetchPostedLoadsMetadataAction = createApiAction<LegacyPosterLoadSearchRequest, SearchResponseMetadata>(
  'POSTED_LOADS_COUNT'
);
export const fetchLoadTemplatesAction = createApiAction<PaginatedRequest, LoadTemplateResponse>(
  'FETCH_RECENT_POSTED_LOADS'
);
export const deleteAllLoadTemplatesAction = createApiAction<DeleteLoadTemplatesRequest, EmptyResponse>(
  'DELETE_ALL_LOAD_TEMPLATES'
);

export const setPostedLoadProgressAction = createApiActionWithFetchData<SetPostedProgressRequest, EmptyResponse>(
  'SET_POSTED_LOAD_PROGRESS'
);

export const brokerLoadsSummaryAction = createApiAction<boolean | undefined, BrokerLoadsSummaryResponseMetadata>(
  'BROKER_LOADS_SUMMARY'
);

// @FIXME refactor to use createApiAction to reduce boilerplate

const UPDATE_LOADS_FAILED = 'UPDATE_LOADS_FAILED';
const UPDATE_LOADS_STATUS_FULFILLED = 'UPDATE_LOADS_STATUS_FULFILLED';
const UPDATE_LOADS_PICKUP_DATE_FULFILLED = 'UPDATE_LOADS_PICKUP_DATE_FULFILLED';
const UPDATE_LOADS_AGE_FULFILLED = 'UPDATE_LOADS_AGE_FULFILLED';
const DELETE_LOADS_FULFILLED = 'DELETE_LOADS_FULFILLED';
const UPDATE_LOADS_LIST = 'UPDATE_LOADS_LIST';
const UPDATE_LOADS_STATUS = 'UPDATE_LOADS_STATUS';
const UPDATE_LOADS_PICKUP_DATE = 'UPDATE_LOADS_PICKUP_DATE';
const UPDATE_LOAD = 'UPDATE_LOAD';
const UPDATE_LOADS = 'UPDATE_LOADS';
const UPDATE_LOADS_AGE = 'UPDATE_LOADS_AGE';
const VIEW_POSTED_LOAD = 'VIEW_POSTED_LOAD';
const CLEAR_RATE_CHECK = 'CLEAR_POST_LOADS_RATE_CHECK';
const CLEAR_TOTAL_MILEAGE = 'CLEAR_TOTAL_MILEAGE';

interface UpdateLoadsListAction extends Action {
  update: LoadBulkUpdate;
}

interface UpdateSingleLoadAction extends Action {
  load: Load | undefined;
}

interface UpdatePostedLoadsBaseAction extends Action {
  loadIds: string[];
  isExcluded: boolean;
}

interface UpdatePostedLoadsAction extends UpdatePostedLoadsBaseAction {
  patch: Partial<BulkPatchLoadsFields>;
}

interface UpdatePostedLoadsSearchQueryAction extends UpdatePostedLoadsAction {
  searchQuery: PosterLoadSearchRequest;
}

interface UpdatePostedLoadsBaseSearchQueryAction extends UpdatePostedLoadsBaseAction {
  searchQuery: PosterLoadSearchRequest;
}

interface UpdatePostedLoadsErrorAction extends Action {
  error: ApiError;
}

interface SearchPostedLoadsAction extends Action {
  searchData?: PosterLoadSearchRequest;
}

export interface FetchPostedLoadsResponseAction extends Action {
  response?: Response<LoadSearchResponseModel>;
  searchRequest?: PosterLoadSearchRequest;
  isLoadMoreFetch?: boolean;
}

interface LoadPaginationResponse {
  response: LoadResponse;
  lastSearchRequest: PosterLoadSearchRequest;
}

interface SetPostedLoadStatusAction extends Action {
  loadID: string;
  status: LoadStatus;
  redirectUrl: string;
}

interface DeletePostedLoadAction extends Action {
  loadID: string;
  redirectUrl: string;
}

export interface RefreshLoadAgeAction extends Action {
  loadID: string;
}

export interface RefreshedLoadAgeAction extends Action {
  response: Response<Load>;
  error?: ApiError;
}

export interface PostedLoadDeletedAction extends Action {
  loadID: string;
  response: EmptyResponse;
  redirectUrl: string;
}

export interface UpdatedLoadStatusAction extends Action {
  response: SetPostedLoadResponse;
  redirectUrl: string;
}

interface LoadViewersAction extends Action {
  loadID: string;
}

interface LoadViewersResponseAction extends Action {
  response: Response<LoadViewers>;
}

interface FetchCarrierInfoAction extends Action {
  carrierID: string;
}

interface CarrierInfoResponseAction extends Action {
  response: Response<CarrierInfo>;
}

export interface MileageRequestAction extends Action {
  stops: PostLoadAddress[];
}

interface MileageResponseAction extends Action {
  mileage?: number;
}

interface FetchRateCheckAction extends Action {
  rateCheckRequest: RateCheckRequest;
}

interface FetchedRateCheckAction extends Action {
  response: Response<RateCheck>;
}

interface UpdatePostedLoadErrorAction extends Action {
  error: ApiError;
}

interface ViewPostedLoadAction extends Action {
  loadID: string;
}

export type GetPostedLoadsTotalCountResponse = ResponseAction<SearchResponseMetadata> & { status: LoadStatus };
type LoadResponse = ApiResponse123<LoadSearchResponseModel>;
type SetPostedLoadResponse = Response<PostedLoadStatus>;

const postedLoadsResponse = (
  response?: Response<LoadSearchResponseModel>,
  searchRequest?: PosterLoadSearchRequest,
  isLoadMoreFetch?: boolean
): FetchPostedLoadsResponseAction => ({
  type: POSTED_LOADS_FETCHED,
  response: response,
  searchRequest: searchRequest,
  isLoadMoreFetch: isLoadMoreFetch,
});

const loadsUpdateFailed = (type: string, error: ApiError): UpdatePostedLoadsErrorAction => ({
  type: type,
  error: error,
});

const loadUpdateFailed = (type: string, error: ApiError): UpdatePostedLoadErrorAction => ({
  type: type,
  error: error,
});

export const clearLoadUpdate = () => ({
  type: CLEAR_LOAD_UPDATE,
});

export const clearLoadFetchErrors = () => ({
  type: CLEAR_LOAD_FETCH_ERRORS,
});

export const toggleIncludeCompanyLoads = () => ({
  type: TOGGLE_INCLUDE_COMPANY_LOADS,
});

export const updateLoadsStatus = (
  loadIds: string[],
  patch: Partial<BulkPatchLoadsFields>,
  isExcluded: boolean,
  searchQuery: PosterLoadSearchRequest
): UpdatePostedLoadsSearchQueryAction => ({
  type: UPDATE_LOADS_STATUS,
  loadIds: loadIds,
  patch: patch,
  isExcluded: isExcluded,
  searchQuery: searchQuery,
});

export const updateLoadsPickUpDates = (
  loadIds: string[],
  patch: Partial<BulkPatchLoadsFields>,
  isExcluded: boolean,
  searchQuery: PosterLoadSearchRequest
): UpdatePostedLoadsSearchQueryAction => ({
  type: UPDATE_LOADS_PICKUP_DATE,
  loadIds: loadIds,
  patch: patch,
  isExcluded: isExcluded,
  searchQuery: searchQuery,
});

export const viewPostedLoad = (loadID: string): ViewPostedLoadAction => ({
  type: VIEW_POSTED_LOAD,
  loadID: loadID,
});

export const clearRateCheck = () => ({
  type: CLEAR_RATE_CHECK,
});

export const clearTotalMileage = () => ({
  type: CLEAR_TOTAL_MILEAGE,
});

export const updateLoadsAge = (
  loadIds: string[],
  isExcluded: boolean,
  searchQuery: PosterLoadSearchRequest
): UpdatePostedLoadsBaseSearchQueryAction => ({
  type: UPDATE_LOADS_AGE,
  loadIds: loadIds,
  isExcluded: isExcluded,
  searchQuery: searchQuery,
});

export const updateSingleLoad = (load: Load | undefined): UpdateSingleLoadAction => ({
  type: UPDATE_LOAD,
  load: load,
});

export const updateLoadsList = (update: LoadBulkUpdate): UpdateLoadsListAction => ({
  type: UPDATE_LOADS_LIST,
  update: update,
});

export const setPostedLoadStatus = (
  loadID: string,
  status: LoadStatus,
  redirectUrl: string
): SetPostedLoadStatusAction => ({
  type: SET_POSTED_LOAD_STATUS,
  loadID: loadID,
  status: status,
  redirectUrl: redirectUrl,
});

export const deletePostedLoad = (loadID: string, redirectUrl: string) => ({
  type: DELETE_POSTED_LOAD,
  loadID: loadID,
  redirectUrl: redirectUrl,
});

export const refreshLoadAge = (loadID: string) => ({
  type: REFRESH_LOAD_AGE,
  loadID: loadID,
});

export const fetchLoadViewers = (loadID: string) => ({
  type: FETCH_LOAD_VIEWERS,
  loadID: loadID,
});

export const fetchCarrierInfo = (carrierID: string) => ({
  type: FETCH_CARRIER_INFO,
  carrierID: carrierID,
});

export const fetchPostedLoadsMetadata = fetchPostedLoadsMetadataAction.fetchAction;

export const fetchPostedLoads = (searchData?: PosterLoadSearchRequest): SearchPostedLoadsAction => ({
  type: FETCH_POSTED_LOADS,
  searchData: searchData,
});

export const fetchMorePostedLoads = () => ({
  type: FETCH_NEXT_POSTED_LOADS,
});

export const fetchTotalMileage = (stops: { address: Address }[]) => ({
  type: MILEAGE_REQUEST,
  stops: stops,
});

export const fetchRateCheck = (request: RateCheckRequest): FetchRateCheckAction => ({
  type: FETCH_RATE_CHECK_INFO,
  rateCheckRequest: request,
});

export const refreshOfflineLoadsList = (): Action => ({
  type: REFRESH_OFFLINE_LOADS_LIST,
});

export const fetchLoadTemplates = (loadTemplatesRequest: PaginatedRequest = createDefaultLoadTemplatesRequest()) =>
  fetchLoadTemplatesAction.fetchAction(loadTemplatesRequest);

export const deleteAllLoadTemplates = () => deleteAllLoadTemplatesAction.fetchAction({ excludeIds: [] });

export const setPostedLoadProgress = (loadID: string, progress: PostedLoadProgress, dotNo?: number) =>
  setPostedLoadProgressAction.fetchAction({ loadID: loadID, progress: progress, dotNo: dotNo });

export const fetchBrokerLoadsSummary = (includeAllCompanyLoads?: boolean) =>
  brokerLoadsSummaryAction.fetchAction(includeAllCompanyLoads);

export interface PostLoadState {
  onlineTotalLoadsCount: number | undefined;
  offlineTotalLoadsCount: number | undefined;
  assignedTotalLoadsCount: number | undefined;
  deliveredTotalLoadsCount: number | undefined;
  filterFormTotalLoadsCount: number | undefined;
  isLoadingOnlineOrOfflineLoadsCount: boolean;
  isLoadingLoadsCount: boolean;
  isLoadingLoads: boolean;
  isLoadingRateCheck: boolean;
  isLastResult: boolean;
  nextToken: string;
  lastSearchRequest: PosterLoadSearchRequest;
  loads: Load[];
  loadViewers?: ViewerInfo[];
  carrierInfo?: CarrierInfo;
  totalLoadsCount: number;
  totalPostMileage?: number;
  rateCheck?: RateCheck;
  bulkUpdateError: ApiError | undefined;
  updateStatusSuccess: boolean;
  deleteLoadsSuccess: boolean;
  updatePickupDateSuccess: boolean;
  updateAgeSuccess: boolean;
  singleUpdateError: ApiError | undefined;
  updateLoadStatusSuccess: boolean; // @FIXME merge these success flags into an enum? [BP/GL]
  updateLoadAgeSuccess: boolean;
  deleteLoadSuccess: boolean;
  loadFetchError: ApiError | undefined;
  loadCountFetchError: ApiError | undefined;
  isLoadingLoadTemplates: boolean;
  loadTemplates: LoadTemplate[];
  isLoadingLoadProgressUpdate: boolean;
  updateLoadProgressUpdateSuccess: boolean | undefined;
  updateLoadProgressUpdateError: ApiError | undefined;
  includeAllCompanyLoads: boolean;
}

const initialState: PostLoadState = {
  onlineTotalLoadsCount: undefined,
  offlineTotalLoadsCount: undefined,
  assignedTotalLoadsCount: undefined,
  deliveredTotalLoadsCount: undefined,
  filterFormTotalLoadsCount: undefined,
  isLoadingOnlineOrOfflineLoadsCount: false,
  isLoadingLoadsCount: false,
  isLoadingLoads: false,
  isLoadingRateCheck: false,
  isLastResult: false,
  nextToken: '',
  lastSearchRequest: createDefaultLoadPostSearchRequest(),
  loads: [],
  loadViewers: undefined,
  carrierInfo: undefined,
  totalLoadsCount: 0,
  totalPostMileage: undefined,
  bulkUpdateError: undefined,
  updateStatusSuccess: false,
  deleteLoadsSuccess: false,
  updatePickupDateSuccess: false,
  updateAgeSuccess: false,
  singleUpdateError: undefined,
  updateLoadStatusSuccess: false,
  updateLoadAgeSuccess: false,
  deleteLoadSuccess: false,
  loadFetchError: undefined,
  loadCountFetchError: undefined,
  isLoadingLoadTemplates: false,
  loadTemplates: [],
  isLoadingLoadProgressUpdate: false,
  updateLoadProgressUpdateSuccess: undefined,
  updateLoadProgressUpdateError: undefined,
  includeAllCompanyLoads: false,
};

/** Reducer receives a proxy state that translates all mutations into equivalent copy operations.*/
export const postLoadReducer = createReducer(initialState, {
  [brokerLoadsSummaryAction.fetchType]: (state: PostLoadState) => {
    state.isLoadingOnlineOrOfflineLoadsCount = true;
    state.loadCountFetchError = undefined;
  },
  [brokerLoadsSummaryAction.responseType]: (
    state: PostLoadState,
    action: ResponseAction<BrokerLoadsSummaryResponseMetadata>
  ) => {
    if (action.response.success) {
      state.assignedTotalLoadsCount = action.response.payload?.assigned;
      state.deliveredTotalLoadsCount = action.response.payload?.delivered;
      state.offlineTotalLoadsCount = action.response.payload?.offline;
      state.onlineTotalLoadsCount = action.response.payload?.online;
    } else {
      state.loadCountFetchError = action.response.error;
    }
    state.isLoadingOnlineOrOfflineLoadsCount = false;
  },
  [FETCH_POSTED_LOADS]: (state: PostLoadState, action: SearchPostedLoadsAction) => {
    if (action.searchData) {
      state.lastSearchRequest = cloneDeep(action.searchData);
    }

    state.isLoadingLoads = true;
    state.loads = [];
    state.isLastResult = false;
    state.nextToken = '';
    state.totalLoadsCount = 0;
    state.loadFetchError = undefined;
  },
  [fetchPostedLoadsMetadataAction.fetchType]: (state: PostLoadState) => {
    state.isLoadingLoadsCount = true;
    state.filterFormTotalLoadsCount = 0;
  },
  [fetchPostedLoadsMetadataAction.responseType]: (
    state: PostLoadState,
    action: ResponseAction<SearchResponseMetadata>
  ) => {
    state.isLoadingLoadsCount = false;
    if (action.response.payload) {
      state.filterFormTotalLoadsCount = action.response.payload.totalResultCount;
    }
  },
  [fetchLoadTemplatesAction.fetchType]: (state: PostLoadState) => {
    state.isLoadingLoadTemplates = true;
  },
  [fetchLoadTemplatesAction.responseType]: (state: PostLoadState, action: ResponseAction<LoadTemplateResponse>) => {
    state.isLoadingLoadTemplates = false;
    if (action.response.payload) {
      state.loadTemplates = action.response.payload.loadTemplates;
    }
  },
  [deleteAllLoadTemplatesAction.fetchType]: (state: PostLoadState) => {
    state.isLoadingLoadTemplates = true;
  },
  [deleteAllLoadTemplatesAction.responseType]: (state: PostLoadState, action: ResponseAction<EmptyResponse>) => {
    if (action.response.success) {
      state.loadTemplates = [];
    }
    state.isLoadingLoadTemplates = false;
  },
  [setPostedLoadProgressAction.fetchType]: (state: PostLoadState) => {
    state.isLoadingLoadProgressUpdate = true;
  },
  [setPostedLoadProgressAction.responseType]: (
    state: PostLoadState,
    action: ResponseAction<EmptyResponse, SetPostedProgressRequest>
  ) => {
    if (action.response.success) {
      state.updateLoadProgressUpdateSuccess = true;
    } else {
      state.updateLoadProgressUpdateSuccess = false;
      state.updateLoadProgressUpdateError = action.response.error;
    }
    state.isLoadingLoadProgressUpdate = false;
  },

  [FETCH_NEXT_POSTED_LOADS]: (state: PostLoadState) => {
    state.isLoadingLoads = true;
    state.loadFetchError = undefined;
  },
  [POSTED_LOADS_FETCHED]: (state: PostLoadState, action: FetchPostedLoadsResponseAction) => {
    const request = action.searchRequest || state.lastSearchRequest;

    if (
      isEqual(action.searchRequest?.statusFilter, state.lastSearchRequest.statusFilter) ||
      action.searchRequest?.statusFilter === undefined
    ) {
      if (!action.response || action.response.error) {
        state.isLoadingLoads = false;
        state.isLastResult = true;
      } else {
        state.isLoadingLoads = false;
        state.lastSearchRequest = request;

        if (action.response && action.response.success && action.response.payload) {
          const payload = {
            ...action.response.payload,
            loads: convertPostedLoadsToLoads(action.response.payload.loads),
          };
          const totalCount = action.isLoadMoreFetch ? state.totalLoadsCount : payload.totalCount;

          let newLoads: Load[];
          if (payload.hasMore || request.token !== '') {
            newLoads = getMergedPostedLoads(payload, state.loads);
          } else {
            newLoads = addFetchedTime(payload.loads);
          }
          state.isLastResult = !payload.hasMore;
          state.loads = newLoads;
          state.nextToken = payload.token ? payload.token : '';
          state.totalLoadsCount = totalCount ?? 0;
        } else {
          state.loads = [];
          state.isLastResult = false;
          state.nextToken = '';
          state.totalLoadsCount = 0;
        }
      }
      if (!action.response?.success && action.response?.error) {
        state.loadFetchError = action.response.error;
      }
    }
  },
  [SET_POSTED_LOAD_STATUS_RESPONSE]: (state: PostLoadState, action: UpdatedLoadStatusAction) => {
    const loadStatusActionData = action.response.payload;
    const updatedLoadID = loadStatusActionData?.id ?? null;
    const filteredLoads = updatedLoadID ? state.loads.filter((item) => item.id !== updatedLoadID) : state.loads;
    if (state.loads.length !== filteredLoads.length) {
      const totalLoadsCount = state.totalLoadsCount - 1;
      const status = loadStatusActionData?.status;

      state.totalLoadsCount = totalLoadsCount;

      if (status === LoadStatus.Online) {
        if (state.onlineTotalLoadsCount) {
          ++state.onlineTotalLoadsCount;
        }
        state.offlineTotalLoadsCount = totalLoadsCount;
      } else if (status === LoadStatus.Offline) {
        if (state.offlineTotalLoadsCount) {
          ++state.offlineTotalLoadsCount;
        }
        state.onlineTotalLoadsCount = totalLoadsCount;
      }
    }
    state.loads = filteredLoads;
    state.updateLoadStatusSuccess = true;
  },
  [POSTED_LOAD_DELETED]: (state: PostLoadState, action: PostedLoadDeletedAction) => {
    const deletedLoadID = action.loadID;
    const isDeleted = action.response.success;
    const filteredLoads = isDeleted ? state.loads.filter((item) => item.id !== deletedLoadID) : state.loads;
    if (state.loads.length !== filteredLoads.length) {
      state.totalLoadsCount = state.totalLoadsCount - 1;
    }
    state.loads = filteredLoads;
    state.deleteLoadSuccess = true;
  },
  [REFRESH_LOAD_AGE_RESPONSE]: (state: PostLoadState, action: RefreshedLoadAgeAction) => {
    if (action.response.success) {
      const refreshedLoad = action.response.payload;
      const updatedLoadID = refreshedLoad && refreshedLoad.id;
      const updatedLoadIndex = findIndex(state.loads, (item) => item.id === updatedLoadID);
      const updatedLoads = state.loads;
      const loadWithFetchedTime = addLoadFetchedTime(Date.now())(updatedLoads[updatedLoadIndex]);
      loadWithFetchedTime.age = 1;
      updatedLoads[updatedLoadIndex] = loadWithFetchedTime;
      state.loads = updatedLoads;
      state.updateLoadAgeSuccess = true;
    }
  },
  [CLEAR_LOAD_UPDATE]: (state: PostLoadState) => {
    state.updateLoadAgeSuccess = false;
    state.deleteLoadSuccess = false;
    state.updateLoadStatusSuccess = false;
    state.singleUpdateError = undefined;
  },
  [CLEAR_LOAD_FETCH_ERRORS]: (state: PostLoadState) => {
    state.loadCountFetchError = undefined;
    state.loadFetchError = undefined;
  },
  [UPDATE_LOAD_FAILED]: (state: PostLoadState, action: UpdatePostedLoadErrorAction) => {
    state.singleUpdateError = action.error;
  },
  [LOAD_VIEWERS_FETCHED]: (state: PostLoadState, action: LoadViewersResponseAction) => {
    state.loadViewers = action?.response?.payload?.viewers;
  },
  [FETCH_CARRIER_INFO]: (state: PostLoadState) => {
    state.carrierInfo = undefined;
  },
  [CARRIER_INFO_FETCHED]: (state: PostLoadState, action: CarrierInfoResponseAction) => {
    state.carrierInfo = action?.response?.payload;
  },
  [MILEAGE_RESPONSE]: (state: PostLoadState, action: MileageResponseAction) => {
    state.totalPostMileage = action.mileage;
  },
  [RATE_CHECK_INFO_FETCHED]: (state: PostLoadState, action: FetchedRateCheckAction) => {
    state.rateCheck = action?.response?.payload;
    state.isLoadingRateCheck = false;
  },
  [FETCH_RATE_CHECK_INFO]: (state: PostLoadState) => {
    state.rateCheck = undefined;
    state.isLoadingRateCheck = true;
  },
  [UPDATE_LOAD]: (state: PostLoadState, action: UpdateSingleLoadAction) => {
    if (action.load !== undefined) {
      const index = findIndex(state.loads, (load) => load.id === action.load?.id);
      if (index !== -1) {
        const { fetchedAt } = addLoadFetchedTime(Date.now())(action.load);
        state.loads[index] = {
          posterContactName: state.loads[index].posterContactName,
          ...action.load,
          fetchedAt: fetchedAt,
          age: action.load.age || 1,
        };
      }
    }
  },
  [UPDATE_LOADS_LIST]: (state: PostLoadState, action: UpdateLoadsListAction) => {
    switch (action.update.category) {
      case UpdateCategory.STATUS_BULK: {
        if (action.update.loadIDs.length === 0 && action.update.isExcluded) {
          state.totalLoadsCount = 0;
        } else {
          if (action.update.isExcluded) {
            const selectedLoadsCount = state.totalLoadsCount - action.update.loadIDs.length;
            state.totalLoadsCount = state.totalLoadsCount - selectedLoadsCount;
          } else {
            state.totalLoadsCount = state.totalLoadsCount - action.update.loadIDs.length;
          }
        }
        if (action.update.status === LoadStatus.Deleted) {
          state.deleteLoadsSuccess = true;
        } else {
          state.updateStatusSuccess = true;
        }
        break;
      }
      case UpdateCategory.PICKUP_DATES_BULK: {
        state.updatePickupDateSuccess = true;
        break;
      }
      case UpdateCategory.AGE_BULK: {
        state.updateAgeSuccess = true;
        break;
      }
    }
    updateLoadsListAfterBulkUpdate(state.loads, action.update);
  },
  [CLEAR_TOTAL_MILEAGE]: (state: PostLoadState) => {
    state.totalPostMileage = undefined;
  },
  [CLEAR_RATE_CHECK]: (state: PostLoadState) => {
    state.rateCheck = undefined;
  },
  [UPDATE_LOADS]: (state: PostLoadState) => {
    state.updateStatusSuccess = false;
    state.deleteLoadsSuccess = false;
    state.updatePickupDateSuccess = false;
    state.updateAgeSuccess = false;
    state.bulkUpdateError = undefined;
  },
  [UPDATE_LOADS_STATUS_FULFILLED]: (state: PostLoadState) => {
    state.updateStatusSuccess = true;
  },
  [DELETE_LOADS_FULFILLED]: (state: PostLoadState) => {
    state.deleteLoadsSuccess = true;
  },
  [UPDATE_LOADS_PICKUP_DATE_FULFILLED]: (state: PostLoadState) => {
    state.updatePickupDateSuccess = true;
  },
  [UPDATE_LOADS_AGE_FULFILLED]: (state: PostLoadState) => {
    state.updateAgeSuccess = true;
  },
  [UPDATE_LOADS_FAILED]: (state: PostLoadState, action: UpdatePostedLoadsErrorAction) => {
    state.bulkUpdateError = action.error;
  },
  [VIEW_POSTED_LOAD]: (state: PostLoadState, action: ViewPostedLoadAction) => {
    const viewedLoadIndex = findIndex(state.loads, (load) => load.id === action.loadID);
    if (viewedLoadIndex !== -1) {
      state.loads[viewedLoadIndex] = updateLoad(state.loads[viewedLoadIndex], {
        category: UpdateCategory.VIEWED,
        loadID: action.loadID,
        poster: undefined,
      });
    }
  },
  [REFRESH_OFFLINE_LOADS_LIST]: (state: PostLoadState) => {
    state.loads = filter(state.loads, (load) => load.status === LoadStatus.Offline);
  },
  [TOGGLE_INCLUDE_COMPANY_LOADS]: (state: PostLoadState) => {
    state.includeAllCompanyLoads = !state.includeAllCompanyLoads;
  },
});

const searchPostedLoadsEpic$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<StoreState>,
  client: PostLoadsClient
) =>
  action$
    .ofType(
      FETCH_POSTED_LOADS,
      UPDATE_LOADS_STATUS_FULFILLED,
      DELETE_LOADS_FULFILLED,
      UPDATE_LOADS_PICKUP_DATE_FULFILLED,
      UPDATE_LOADS_AGE_FULFILLED
    )
    .pipe(
      mergeMap$((action: SearchPostedLoadsAction) => {
        const lastSearchRequest: PosterLoadSearchRequest = state$.value.loadPost.lastSearchRequest;
        const request = action.searchData ?? lastSearchRequest;
        return client.getPostedLoads$(request).pipe(
          map$((response) => {
            return response.result(
              (data) =>
                postedLoadsResponse(
                  {
                    success: true,
                    payload: data,
                  },
                  action.searchData
                ),
              (error) =>
                postedLoadsResponse(
                  {
                    success: false,
                    error: error,
                  },
                  action.searchData
                )
            );
          })
        );
      })
    );

const searchNextPostedLoadsEpic$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<StoreState>,
  client: PostLoadsClient
) =>
  action$.pipe(
    distinctUntilChanged((prevAction, action) => prevAction.type === action.type),
    ofType(FETCH_NEXT_POSTED_LOADS),
    mergeMap$((): Observable<LoadPaginationResponse | undefined> => {
      const postState: PostLoadState = state$.value.loadPost;
      const includeAllCompanyLoads = postState.includeAllCompanyLoads;
      const lastSearch = cloneDeep(postState.lastSearchRequest);
      lastSearch.metadata.limit = MY_POSTED_LOADS_REQUEST_LIMIT_APPENDING;

      if (postState.isLastResult) {
        return of$(undefined);
      }

      lastSearch.metadata.nextToken = postState.nextToken;
      lastSearch.metadata.fields = includeAllCompanyLoads
        ? MY_POSTED_LOADS_REQUEST_FIELDS_WITH_POSTER
        : MY_POSTED_LOADS_REQUEST_FIELDS_DEFAULT;

      const searchRequest: PosterLoadSearchPaginationRequest = { token: postState.nextToken };

      return client
        .getPostedLoads$(searchRequest)
        .pipe(map$((response) => ({ response: response, lastSearchRequest: lastSearch })));
    }),
    mergeMap$((paginationResponse) => {
      let responseAction: FetchPostedLoadsResponseAction;
      if (!paginationResponse) {
        responseAction = postedLoadsResponse(undefined);
      } else {
        responseAction = paginationResponse.response.result(
          (data) => {
            return postedLoadsResponse(
              {
                success: true,
                payload: data,
              },
              paginationResponse.lastSearchRequest,
              true
            );
          },
          (error) =>
            postedLoadsResponse({
              success: false,
              error: error,
            })
        );
      }
      return of$(responseAction);
    })
  );

const setLoadStatus$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  simpleApiEpicToAction(
    action$,
    SET_POSTED_LOAD_STATUS,
    (action: SetPostedLoadStatusAction) => loadsClient.setLoadStatus$(action.loadID, action.status),
    (response, action: SetPostedLoadStatusAction) => {
      if (!response.success && response.error) {
        return loadUpdateFailed(UPDATE_LOAD_FAILED, response.error);
      }
      return {
        type: SET_POSTED_LOAD_STATUS_RESPONSE,
        response: response,
        redirectUrl: action.redirectUrl,
      };
    }
  );

const deletePostedLoad$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  simpleApiEpicToAction(
    action$,
    DELETE_POSTED_LOAD,
    (action: DeletePostedLoadAction) => loadsClient.deletePostedLoad$(action.loadID),
    (response, action: PostedLoadDeletedAction) => {
      if (!response.success && response.error) {
        return loadUpdateFailed(UPDATE_LOAD_FAILED, response.error);
      }
      return {
        type: POSTED_LOAD_DELETED,
        loadID: action.loadID,
        response: response,
        redirectUrl: action.redirectUrl,
      };
    }
  );

const refreshLoadAge$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  simpleApiEpicToAction(
    action$,
    REFRESH_LOAD_AGE,
    (action: RefreshLoadAgeAction) => loadsClient.refreshLoadAge$(action.loadID),
    (response) => {
      if (!response.success && response.error) {
        return loadUpdateFailed(UPDATE_LOAD_FAILED, response.error);
      }
      return { type: REFRESH_LOAD_AGE_RESPONSE, response: response };
    }
  );

const fetchLoadViewers$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  simpleApiEpicToAction(
    action$,
    FETCH_LOAD_VIEWERS,
    (action: LoadViewersAction) => loadsClient.fetchLoadViewers$(action.loadID),
    (response): LoadViewersResponseAction => ({ type: LOAD_VIEWERS_FETCHED, response: response })
  );

const fetchCarrierInfo$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  simpleApiEpicToAction(
    action$,
    FETCH_CARRIER_INFO,
    (action: FetchCarrierInfoAction) => loadsClient.fetchCarrierInfo$(action.carrierID),
    (response): CarrierInfoResponseAction => ({ type: CARRIER_INFO_FETCHED, response: response })
  );

const fetchTotalMileage$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  simpleApiEpicToAction(
    action$,
    MILEAGE_REQUEST,
    (action: MileageRequestAction) => loadsClient.fetchTotalMileage$(action.stops),
    (response): MileageResponseAction => ({ type: MILEAGE_RESPONSE, mileage: response?.payload?.mileage })
  );

const fetchRateCheck$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  simpleApiEpicToAction(
    action$,
    FETCH_RATE_CHECK_INFO,
    (action: FetchRateCheckAction) => loadsClient.fetchRateCheck$(action.rateCheckRequest),
    (response): FetchedRateCheckAction => ({ type: RATE_CHECK_INFO_FETCHED, response: response })
  );
const updateLoadsStatus$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<StoreState>,
  loadsClient: PostLoadsClient
) =>
  action$.ofType(UPDATE_LOADS_STATUS).pipe(
    mergeMap$((action: UpdatePostedLoadsSearchQueryAction) => {
      return updateLoadsWithExcludeCheck$(action, loadsClient).pipe(
        mergeMap$((response) => {
          return response.resultWithoutData(
            () => {
              const loadsUpdate = updateFulfilled(action, UpdateCategory.STATUS_BULK);
              const isDeleted = action.patch.status === LoadStatus.Deleted;
              let updateAction;
              if (loadsUpdate) {
                updateAction = updateLoadsList(loadsUpdate);
              } else {
                updateAction = isDeleted ? { type: DELETE_LOADS_FULFILLED } : { type: UPDATE_LOADS_STATUS_FULFILLED };
              }
              return of$(fetchBrokerLoadsSummary(state$.value.loadPost.includeAllCompanyLoads), updateAction, {
                type: UPDATE_LOADS,
              });
            },
            (error) => of$(loadsUpdateFailed(UPDATE_LOADS_FAILED, error))
          );
        })
      );
    })
  );

const updateLoadsPickupDate$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  action$.ofType(UPDATE_LOADS_PICKUP_DATE).pipe(
    mergeMap$((action: UpdatePostedLoadsSearchQueryAction) => {
      return updateLoadsWithExcludeCheck$(action, loadsClient).pipe(
        mergeMap$((response) => {
          return response.resultWithoutData(
            () => {
              const loadsUpdate = updateFulfilled(action, UpdateCategory.PICKUP_DATES_BULK);
              const updateAction = loadsUpdate
                ? updateLoadsList(loadsUpdate)
                : { type: UPDATE_LOADS_PICKUP_DATE_FULFILLED };
              return concat$(of$(updateAction), of$({ type: UPDATE_LOADS }));
            },
            (error) => of$(loadsUpdateFailed(UPDATE_LOADS_FAILED, error))
          );
        })
      );
    })
  );

const updateLoadsAge$ = (action$: ActionsObservable<Action>, loadsClient: PostLoadsClient) =>
  action$.ofType(UPDATE_LOADS_AGE).pipe(
    mergeMap$((action: UpdatePostedLoadsSearchQueryAction) => {
      return refreshLoadsWithExcludeCheck$(action, loadsClient).pipe(
        mergeMap$((response) => {
          return response.resultWithoutData(
            () => {
              const loadsUpdate = updateFulfilled(action, UpdateCategory.AGE_BULK);
              const updateAction = loadsUpdate ? updateLoadsList(loadsUpdate) : { type: UPDATE_LOADS_AGE_FULFILLED };
              return concat$(of$(updateAction), of$({ type: UPDATE_LOADS }));
            },
            (error) => of$(loadsUpdateFailed(UPDATE_LOADS_FAILED, error))
          );
        })
      );
    })
  );

const updateLoadsWithExcludeCheck$ = (
  action: UpdatePostedLoadsSearchQueryAction,
  loadsClient: PostLoadsClient
): Observable<ApiResponse123<EmptyResponse>> => {
  if (action.isExcluded) {
    return loadsClient.updateLoadsWithExcludeIds$(action.loadIds, action.patch, action.searchQuery);
  }
  return loadsClient.updateLoads$(action.loadIds, action.patch);
};

const refreshLoadsWithExcludeCheck$ = (
  action: UpdatePostedLoadsBaseSearchQueryAction,
  loadsClient: PostLoadsClient
): Observable<ApiResponse123<EmptyResponse>> => {
  if (action.isExcluded) {
    return loadsClient.updateLoadsAgeWithExcludeIds$(action.loadIds, action.searchQuery);
  }
  return loadsClient.updateLoadsAge$(action.loadIds);
};

const updateFulfilled = (
  updateResponseAction: UpdatePostedLoadsAction,
  updateCategory: UpdateCategory
): LoadBulkUpdate | undefined => {
  switch (updateCategory) {
    case UpdateCategory.PICKUP_DATES_BULK: {
      return {
        category: updateCategory,
        dates: updateResponseAction.patch.pickupDateTimes ?? [],
        loadIDs: updateResponseAction.loadIds,
        isExcluded: updateResponseAction.isExcluded,
      };
    }
    case UpdateCategory.STATUS_BULK: {
      return {
        category: updateCategory,
        status: updateResponseAction.patch.status ?? LoadStatus.None,
        loadIDs: updateResponseAction.loadIds,
        isExcluded: updateResponseAction.isExcluded,
      };
    }
    case UpdateCategory.AGE_BULK: {
      return {
        category: updateCategory,
        age: 1,
        loadIDs: updateResponseAction.loadIds,
        isExcluded: updateResponseAction.isExcluded,
      };
    }
    default:
      return undefined;
  }
};

const convertPostedLoadsToLoads = (postedLoads: PostedLoad[]): Load[] => {
  const loads: Load[] = [];

  postedLoads.map((postedLoad) => {
    const load: Load = postedLoad;
    load.originLocation = postedLoad.origin;
    load.destinationLocation = postedLoad.destination;

    load.pickupDateTime = postedLoad.pickupDateTimes?.[0] ? postedLoad.pickupDateTimes[0] : postedLoad.pickupDateTime;
    loads.push(load);
  });

  return loads;
};

export const createPostLoadEpic = <T extends { postLoad: PostLoadState }>(api: Api) => {
  const loadsClient = new PostLoadsClient(api);
  const loadTemplateClient = new LoadTemplatesClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<StoreState>) => {
    return merge$(
      searchPostedLoadsEpic$(action$, state$, loadsClient),
      searchNextPostedLoadsEpic$(action$, state$, loadsClient),
      setLoadStatus$(action$, loadsClient),
      deletePostedLoad$(action$, loadsClient),
      refreshLoadAge$(action$, loadsClient),
      fetchLoadViewers$(action$, loadsClient),
      fetchCarrierInfo$(action$, loadsClient),
      fetchTotalMileage$(action$, loadsClient),
      fetchRateCheck$(action$, loadsClient),
      updateLoadsStatus$(action$, state$, loadsClient),
      updateLoadsPickupDate$(action$, loadsClient),
      updateLoadsAge$(action$, loadsClient),
      fetchPostedLoadsMetadataAction.createSwitchEpic$(action$, loadsClient.getPostedLoadsMetadata$),
      fetchLoadTemplatesAction.createEpic$(action$, loadTemplateClient.fetchLoadTemplates$),
      deleteAllLoadTemplatesAction.createEpic$(action$, loadTemplateClient.deleteLoadTemplates$),
      setPostedLoadProgressAction.createEpic$(action$, loadsClient.setPostedLoadProgress$),
      brokerLoadsSummaryAction.createEpic$(action$, loadsClient.getBrokerLoadsSummary$)
    );
  };
};

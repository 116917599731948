import React, { useMemo } from 'react';

import { formatDateMMDDYYYY, formatPhoneNumberWithExtension } from '@common/helper';
import { CompanyInsuranceInfo, InsuranceCoverageInfo } from '@common/model';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { getBooleanLabel } from '@component/panels/companyDetails/Authority';
import { PhoneRow, TitleRow, ValueRow } from '@component/panels/companyDetails/CompanyDetailsRows';
import { default as InsuranceIcon } from '@component/panels/companyDetails/icon-insurance.svg';
import {
  CenteringContainer,
  Container,
  DataWrapper,
} from '@component/panels/truckLocator/carrierDetails/CarrierDetailsPanelStyles';
import { T, t } from '@translate';
import { COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT, NO_INFORMATION_SYMBOL } from '@util/Constants';

interface Props {
  insurance?: CompanyInsuranceInfo;
  handleChange: () => void;
  isOpened: boolean;
  isLoading?: boolean;
}
export const Insurance: React.FC<Props> = (props) => {
  const isInsuranceAvailable = useMemo(
    () => props.insurance?.coverages || props.insurance?.requirements,
    [props.insurance?.coverages, props.insurance?.requirements]
  );
  const bipdOnFile = displayInsuranceValue(props.insurance?.requirements?.bipdOnFile);
  return (
    <ExpansionPanel
      id="insurance_info"
      titleText={t(T.truckLocator_truckTable_carrierDetails_Insurance)}
      isOpened={props.isOpened}
      handleClick={props.handleChange}
      isBlocked={!isInsuranceAvailable}
      subtitleText={isInsuranceAvailable ? bipdOnFile : t(T.findLoads_loadDetails_notProvided)}
      panelTitleStyle={{ height: COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT }}
      icon={InsuranceIcon}
    >
      <DataWrapper>
        {props.isLoading ? (
          <CenteringContainer>
            <LoadingSpinner />
          </CenteringContainer>
        ) : (
          <>
            <Container isWithoutTopLine={true}>
              <TitleRow id="bipd_insurance" title={t(T.truckLocator_truckTable_carrierDetails_BIPDInsurance)} />
              <ValueRow
                id="bipd_required"
                title={t(T.truckLocator_truckTable_carrierDetails_Required)}
                rightValue={displayInsuranceValue(props.insurance?.requirements?.bipdRequired)}
              />
              <ValueRow
                id="bipd_filed"
                title={t(T.truckLocator_truckTable_carrierDetails_Filed)}
                rightValue={bipdOnFile}
              />
            </Container>
            <Container>
              <TitleRow
                id="hhg_cargo_insurance"
                title={t(T.truckLocator_truckTable_carrierDetails_HHGCargoInsurance)}
              />
              <ValueRow
                id="hhg_cargo_required"
                title={t(T.truckLocator_truckTable_carrierDetails_Required)}
                rightValue={getBooleanLabel(props.insurance?.requirements?.cargoRequired)}
              />
              <ValueRow
                id="hhg_cargo_filed"
                title={t(T.truckLocator_truckTable_carrierDetails_Filed)}
                rightValue={getBooleanLabel(props.insurance?.requirements?.cargoOnFile)}
              />
            </Container>
            <Container>
              <TitleRow id="bond_insurance" title={t(T.truckLocator_truckTable_carrierDetails_BondInsurance)} />
              <ValueRow
                id="bond_required"
                title={t(T.truckLocator_truckTable_carrierDetails_Required)}
                rightValue={getBooleanLabel(props.insurance?.requirements?.bondRequired)}
              />
              <ValueRow
                id="bond_filed"
                title={t(T.truckLocator_truckTable_carrierDetails_Filed)}
                rightValue={getBooleanLabel(props.insurance?.requirements?.bondOnFile)}
              />
            </Container>
            {props.insurance && props.insurance.coverages && props.insurance.coverages?.length > 0 ? (
              props.insurance.coverages.map((coverage) => (
                <div key={coverage.policyNumber}>
                  <CoverageSection coverage={coverage} />
                </div>
              ))
            ) : (
              <CoverageSection />
            )}
          </>
        )}
      </DataWrapper>
    </ExpansionPanel>
  );
};

const CoverageSection: React.FC<{ coverage?: InsuranceCoverageInfo }> = (props) => (
  <>
    <Container>
      <TitleRow id="bipd_primary" title={t(T.truckLocator_truckTable_carrierDetails_BIPDPrimary)} />
      <ValueRow
        id="bibd_primary_company"
        title={t(T.common_truck_Company)}
        rightValue={props.coverage?.insurerInfo?.name}
      />
      <ValueRow id="bibd_primary_contact" title={t(T.Contact)} rightValue={props.coverage?.insurerInfo?.attention} />
      <ValueRow
        id="bibd_primary_address"
        title={t(T.common_address)}
        rightValue={props.coverage?.insurerInfo?.address}
      />
      <PhoneRow
        id="bibd_primary_phone"
        title={t(T.common_phone)}
        phoneString={formatPhoneNumberWithExtension(
          props.coverage?.insurerInfo?.phone?.number,
          props.coverage?.insurerInfo?.phone?.extension
        )}
      />
    </Container>
    <Container>
      <TitleRow
        id={`${props.coverage?.insurerInfo?.name}_underwriter`}
        title={t(T.truckLocator_truckTable_carrierDetails_Underwriter)}
      />
      <ValueRow
        id={`${props.coverage?.insurerInfo?.name}_type`}
        title={t(T.truckLocator_truckTable_carrierDetails_Type)}
        rightValue={props.coverage?.type}
      />
      <ValueRow
        id={`${props.coverage?.insurerInfo?.name}_policy_number`}
        title={t(T.truckLocator_truckTable_carrierDetails_PolicyNum)}
        rightValue={props.coverage?.policyNumber}
      />
      <ValueRow
        id={`${props.coverage?.insurerInfo?.name}_date`}
        title={t(T.truckLocator_truckTable_carrierDetails_EffectiveDate)}
        rightValue={
          props.coverage?.effectiveDate ? formatDateMMDDYYYY(props.coverage.effectiveDate) : NO_INFORMATION_SYMBOL
        }
      />
    </Container>
  </>
);

const displayInsuranceValue = (value: number | undefined) => {
  if (value === undefined) {
    return NO_INFORMATION_SYMBOL;
  }
  return value > 0 ? `$${value}K` : '$0';
};

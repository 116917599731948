import { useCallback, useEffect } from 'react';

import { isUndefined } from 'lodash';

import { useFactory } from '@common/util/hooks';

type TimeoutIdType = ReturnType<typeof setTimeout>;

export class AutoRefreshManager {
  public interval: number; // Interval in seconds.
  private timeoutId?: TimeoutIdType;

  private onRefresh?: () => void;
  constructor(interval: number) {
    this.interval = interval;
  }

  public isActive = () => !isUndefined(this.timeoutId);

  public start = () => {
    if (!this.isActive()) {
      this.startTimer();
    }
  };

  public restart = () => {
    this.stop();
    this.start();
  };

  public stop = () => {
    if (this.isActive()) {
      clearTimeout(this.timeoutId as TimeoutIdType);
      this.timeoutId = undefined;
    }
  };

  public setCallback = (onRefresh: () => void) => {
    this.onRefresh = onRefresh;
  };

  private startTimer = () => {
    this.timeoutId = setTimeout(() => {
      if (this.isActive()) {
        this.onRefresh?.();
        this.startTimer();
      }
    }, this.interval * 1000);
  };
}

export const useAutoRefreshManager = (onRefresh: () => void, interval: number) => {
  const factory = useCallback(() => new AutoRefreshManager(interval), [interval]);
  const manager = useFactory<AutoRefreshManager>(
    factory,
    (prevManager, newManager) => {
      if (prevManager.isActive()) {
        newManager.start();
      }
    },
    (manager) => {
      manager.stop();
    }
  );
  manager.setCallback(onRefresh);
  return manager;
};

export const useStartAutoRefresh = (autoRefreshManager: AutoRefreshManager, start: boolean) => {
  useEffect(() => {
    if (start) {
      autoRefreshManager.start();
    } else {
      autoRefreshManager.stop();
    }
  }, [autoRefreshManager, start]);
};

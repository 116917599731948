import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { IdentityVerificationClient } from '@common/client/IdentityVerificationClient';
import { ContactsCompanyVerificationResponse, IdentityVerificationPhoneResponse, Phone } from '@common/model';
import { createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface IdentityVerificationState {
  isLoadingCompany: boolean;
  isLoadingPhone: boolean;
  companyIdentityResponse: ContactsCompanyVerificationResponse | undefined;
  phoneIdentityResponse: IdentityVerificationPhoneResponse | undefined;
}

const getIdentityVerificationCompanyAction = createApiAction<{}, ContactsCompanyVerificationResponse>(
  'GET_IDENTITY_VERIFICATION_COMPANY'
);
const addNewIdentityVerificationNumberAction = createApiAction<Phone, IdentityVerificationPhoneResponse>(
  'ADD_NEW_IDENTITY_VERIFICATION_NUMBER'
);

export const fetchIdentityVerificationCompany = () => getIdentityVerificationCompanyAction.fetchAction({});
export const addNewIdentityVerificationNumber = (phone: Phone) =>
  addNewIdentityVerificationNumberAction.fetchAction(phone);

const initialState: IdentityVerificationState = {
  isLoadingCompany: false,
  isLoadingPhone: false,
  companyIdentityResponse: undefined,
  phoneIdentityResponse: undefined,
};

export const identityVerificationReducer = createMergedReducer(initialState, [
  getIdentityVerificationCompanyAction.initiateCase((state: IdentityVerificationState) => {
    state.isLoadingCompany = true;
    state.companyIdentityResponse = undefined;
  }),
  getIdentityVerificationCompanyAction.completeCase((state, action) => {
    state.isLoadingCompany = false;
    if (action.response.success) {
      state.companyIdentityResponse = action.response.payload;
    }
  }),
  addNewIdentityVerificationNumberAction.initiateCase((state) => {
    state.isLoadingPhone = true;
  }),
  addNewIdentityVerificationNumberAction.completeCase((state, action) => {
    state.isLoadingPhone = false;
    if (action.response.success) {
      state.phoneIdentityResponse = action.response.payload;
    }
  }),
]);

export const createIdentityVerificationEpic = (api: Api) => {
  const client = new IdentityVerificationClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      getIdentityVerificationCompanyAction.createEpic$(action$, client.getIdentityVerificationCompany$),
      addNewIdentityVerificationNumberAction.createEpic$(action$, client.addIdentityVerificationPhoneNumber$)
    );
};

import React, { CSSProperties, useEffect, useRef } from 'react';

interface Props {
  className?: string;
  style?: CSSProperties;
  onMeasure: (width: number) => void;
}

export const WidthMeasurer: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return updateWidthAsync();
  }, [ref, ref?.current?.clientWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateWidthAsync);
    return () => window.removeEventListener('resize', updateWidthAsync);
  }, []);

  const updateWidth = () => {
    if (ref.current) {
      const bounds = ref.current.getBoundingClientRect();
      if (bounds?.width) {
        props.onMeasure(bounds?.width);
      }
    }
  };

  const updateWidthAsync = () => {
    const timer = setTimeout(updateWidth, 1);
    return () => clearTimeout(timer);
  };

  return (
    <div className={props.className} ref={ref} style={props.style}>
      {props.children}
    </div>
  );
};

import { ChangeEvent, ChangeEventHandler, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';

export const useDebouncedState = <T>(state: T, debounceTime?: number): [T, Dispatch<SetStateAction<T>>] => {
  const [debouncedState, setDebouncedState] = useState(state);

  const setState = useCallback(
    debounce((state: T) => setDebouncedState(state), debounceTime ?? 1000),
    [debounceTime]
  );

  useEffect(() => {
    return setState.cancel;
  }, []);

  return [debouncedState, setState];
};

type DebounceValueType = Array<string | number | boolean> | string | number | boolean | undefined;
type HandlerValueType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

export const useDebouncedInput = (
  value: DebounceValueType,
  onChange: ChangeEventHandler<HandlerValueType> | undefined,
  debounceTime: number
): [DebounceValueType, Dispatch<SetStateAction<ChangeEvent<HandlerValueType>>>] => {
  const [text, setText] = useState(value);
  const [debouncedEvent, setDebouncedEvent] = useDebouncedState<ChangeEvent<HandlerValueType> | undefined>(
    undefined,
    debounceTime
  );

  useEffect(() => {
    if (debouncedEvent !== undefined && onChange !== undefined) {
      onChange(debouncedEvent);
    }
  }, [debouncedEvent]);

  useEffect(() => {
    setText(value);
  }, [value]);

  const onChangeText = useCallback((event: ChangeEvent<HandlerValueType>) => {
    setText(event.target.value);
    event.persist();
    setDebouncedEvent(event);
  }, []);

  return [text, onChangeText];
};

import React from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';
import { BorderRadius, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import icons from '@util/iconsConstants';

const NEW_LOADS_HEIGHT = 40;
const NEW_LOADS_WIDTH = 126;
const NEW_LOADS_ARROW_SIDE_SIZE = 30;

const ShadowContainer = styled.div`
  box-shadow: 0px 3px 4px 0 rgba(0, 0, 0, 0.6);
  border-radius: ${BorderRadius.CloseModal}px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${NEW_LOADS_HEIGHT}px;
  width: ${NEW_LOADS_WIDTH}px;
`;

const Touchable = styled.div`
  background-color: ${Color.ORANGE_MAIN};
  padding-right: 6px;
  border-radius: ${BorderRadius.CloseModal}px;
  overflow: hidden;
`;

const ButtonTitle = styled.p`
  color: ${Color.WHITE};
  padding-top: ${Spacing.InterListElement}px;
`;

interface Props {
  onPress: () => void;
  isUp: boolean;
}

export const NewLoadsButton: React.FC<Props> = (props) => {
  const arrow = props.isUp ? icons.arrowUp : icons.arrowDown;
  const alt = props.isUp ? 'newLoadsUp' : 'newLoadsDown';
  return (
    <ShadowContainer>
      <Touchable onClick={props.onPress}>
        <ButtonContent>
          <img src={arrow} style={{ width: NEW_LOADS_ARROW_SIDE_SIZE, height: NEW_LOADS_ARROW_SIDE_SIZE }} alt={alt} />
          <ButtonTitle>{t(T.findLoads_findLoads_newLoads)}</ButtonTitle>
        </ButtonContent>
      </Touchable>
    </ShadowContainer>
  );
};

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { RateCheck, RateCheckFeedback } from '@common/model';
import { getRateCheckID } from '@common/redux/epic/rateCheck/RateCheckHelper';
import { Color } from '@style/Color';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { useSelector } from '@util/hooks';

import { RateCheckFeedbackResponse } from './RateCheckFeedbackResponse';
import { RateCheckIsAccurate } from './RateCheckIsAccurate';
import { RateConfirmationInput } from './RateConfirmationInput';

const RATECHECK_SUBMITTED_ANIMATION_DURATION = 300;

const RateCheckConfirmationContainer = styled.div`
  border: 1px solid ${Color.GRAY_11};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Color.GRAY_LIGHT};
  margin: ${Spacing.InterElementVertical}px 0;
  position: relative;
  z-index: 1;
`;

const AnimatedContainer = styled.div`
  transition: height ${RATECHECK_SUBMITTED_ANIMATION_DURATION}ms ease;
  will-change: height;
  height: ${({ height }: { height: number }) => height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

interface ComponentProps {
  loadID?: string;
  rateCheck: RateCheck;
  style?: React.CSSProperties;
}

export const RateConfirmation: React.FC<ComponentProps> = (props) => {
  const request = useSelector((state) => state.rateCheck.request);
  const rateCheckID = getRateCheckID(props.loadID, request);

  const feedback = useSelector<RateCheckFeedback | undefined>((state) => state.rateCheck.feedback[rateCheckID]);
  const [isEditingFeedback, setIsEditingFeedback] = useState(false);
  const height = useRateCheckHeightAnimation(isEditingFeedback);

  return (
    <div
      style={{
        height: ElementSize.RateCheckMinMax + ElementSize.RateCheckMinMax + Spacing.InterElementVertical,
        width: '100%',
      }}
    >
      <RateCheckConfirmationContainer style={props.style}>
        {props.children}
        <AnimatedContainer height={height}>
          {feedback && !feedback.isSubmitted && isEditingFeedback ? (
            <RateConfirmationInput
              rateCheckFeedback={feedback}
              rateCheckID={rateCheckID}
              loadID={props.loadID}
              rateCheck={props.rateCheck}
              setIsEditingFeedback={setIsEditingFeedback}
            />
          ) : null}
          {feedback && feedback.isSubmitted ? <RateCheckFeedbackResponse rateCheckFeedback={feedback} /> : null}
          {!feedback || feedback.isRateCheckAccurate === undefined ? (
            <RateCheckIsAccurate
              rateCheckID={rateCheckID}
              loadID={props.loadID}
              rateCheck={props.rateCheck}
              setIsEditingFeedback={setIsEditingFeedback}
            />
          ) : null}
        </AnimatedContainer>
      </RateCheckConfirmationContainer>
    </div>
  );
};

const useRateCheckHeightAnimation = (isEditingFeedback: boolean) => {
  const [height, setHeight] = useState<number>(ElementSize.RateCheckConfirmation);

  useEffect(() => {
    if (isEditingFeedback) {
      setTimeout(setHeight, 0, ElementSize.RateCheckFeedbackInput);
    } else {
      setTimeout(setHeight, 0, ElementSize.RateCheckConfirmation);
    }
  }, [isEditingFeedback]);

  return height;
};

import { join, map, split } from 'lodash';

import { Equipment, EquipmentSpecification, EquipmentType } from '@common/model/Equipment';
import { SimplifiedEquipment } from '@common/model/SimplifiedEquipment';
import { t } from '@translate';

const EQUIPMENT_TYPE_TRANSLATION_PREFIX = 'common.load.equipmentType.';
const EQUIPMENT_SPECIFICATION_TRANSLATION_PREFIX = 'common.load.equipmentSpecification.';

export const tEquipments = (equipments: Equipment[]) => {
  const translatedEquipments: SimplifiedEquipment[] = map(equipments, (equipment: Equipment) => {
    return {
      equipmentType: tEquipment(equipment.equipmentType),
      equipmentSpecifications: tEquipmentSpecifications(equipment.equipmentSpecifications as string),
    };
  });
  return translatedEquipments;
};

export const tEquipment = (equipmentKey: EquipmentType | EquipmentSpecification) => {
  if (equipmentKey === 'None') {
    return '';
  }
  if (equipmentKey in EquipmentType) {
    return t(EQUIPMENT_TYPE_TRANSLATION_PREFIX + equipmentKey);
  } else if (equipmentKey in EquipmentSpecification) {
    return t(EQUIPMENT_SPECIFICATION_TRANSLATION_PREFIX + equipmentKey);
  }
  return '';
};

export const tEquipmentSpecifications = (specifications: string) => {
  return join(
    map(split(specifications, ', '), (specification: EquipmentSpecification) => tEquipment(specification)),
    ', '
  );
};

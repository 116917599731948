import { replace } from 'lodash';

import { isNumber } from '@common/helper';
import { VerificationPointsTaskStatus } from '@common/model';
import { CarrierInfo, ContactBrokerInfo } from '@common/model/Carrier';
import { AuthorityInfo } from '@common/model/Company';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';

export enum FMCSAAuthority {
  Common = 'Common',
  Contract = 'Contract',
  Broker = 'Broker',
}

enum AuthorityType {
  Inactive = 'Inactive',
  Active = 'Active',
  None = 'None',
}

export interface ContactInfoDisplayData {
  phone: string;
  mcNumber: string;
  usDotNumber: string;
  nameLabel: string;
  companyName: string;
  phoneVerificationStatus?: VerificationPointsTaskStatus;
}

export const getContactInfoDetails = (
  contactInfo: ContactBrokerInfo | CarrierInfo | undefined,
  isBroker: boolean
): ContactInfoDisplayData => {
  const phone = contactInfo?.phone ?? NO_INFORMATION_SYMBOL;
  const clearedMcNumber = replaceMCSuffix(contactInfo?.docketNumber);

  const mcNumber = clearedMcNumber !== undefined && isNumber(clearedMcNumber) ? clearedMcNumber : NO_INFORMATION_SYMBOL;

  const usDotNumber = contactInfo?.usdotNumber ?? NO_INFORMATION_SYMBOL;

  const nameLabel = isBroker
    ? t(T.common_conversations_contactInfo_agent)
    : t(T.common_conversations_contactInfo_carrier);

  const companyName = contactInfo?.companyName ?? NO_INFORMATION_SYMBOL;

  return {
    phone: phone,
    mcNumber: mcNumber,
    usDotNumber: usDotNumber,
    nameLabel: nameLabel,
    companyName: companyName,
  };
};

const replaceMCSuffix = (mcNumber: string | undefined): string | undefined => {
  if (mcNumber === undefined) {
    return undefined;
  }
  return replace(mcNumber, 'MC', '');
};

export const getFMCSAAuthority = (authType: FMCSAAuthority, companyAuthority: AuthorityInfo[] | undefined) => {
  let status = '';
  if (companyAuthority) {
    switch (authType) {
      case FMCSAAuthority.Common:
      case FMCSAAuthority.Contract:
      case FMCSAAuthority.Broker:
        status = companyAuthority.filter((authority: AuthorityInfo) => authority.type === authType)[0]?.status;
        break;
      default:
        break;
    }
  }

  return status;
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case AuthorityType.Inactive:
      return Color.RED_APPLE;
    case AuthorityType.Active:
      return Color.GREEN_MAIN;
    default:
      return Color.GRAY_DARK;
  }
};

import { MessageWidget } from '@common/model/messaging/MessageWidget';

import { Load } from './Load';

export interface ConversationsSummary {
  unreadConversationsCount: number;
}
export interface MessagesSummary {
  unreadMessagesCount: number;
}

export interface ConversationResponse {
  token: string; // for pagination
  isLastPage: boolean;
  totalCount: number;
  conversations: Conversation[];
}
export const LoadFieldsConversationOnly = 'conversation';

export type LoadWithDataForConversation = Pick<Load, 'id' | 'conversation'>;

export interface Conversation {
  conversationId: string;
  unreadMessagesCount: number;
  mostRecentMessageDate: string;
  mostRecentMessageId?: string;
  createdBy: string;
  users: ConversationUser[];
  metadata?: { load?: Load };
}

export interface ConversationUser {
  userId: string;
  firstName: string;
  lastName: string;
  company: {
    companyId: string;
    name: string;
  };
}

export interface MessagePayload {
  clientMessageId: string | undefined;
  text?: string;
  documents?: ConversationAttachment[];
  users?: ConversationMessageUsers[];
}

export interface ConversationMessageResponse {
  token: string;
  isLastPage: boolean;
  conversationID: string;
  messages: ConversationMessage[];
}

export interface ConversationMessage {
  messageId: string;
  sentBy: string;
  sentAt: string;
  read: boolean;
  text: string;
  documents?: ConversationAttachment[];
  clientMessageId: string | undefined;
  users?: ConversationMessageUsers[];
  widget?: MessageWidget;
}

export interface ConversationMessageUsers {
  userId: string;
  readDate: number;
}

export interface ConversationAttachment {
  id: string;
  name: string;
}

export interface ConversationUserStatus {
  userId: string;
  online: boolean;
  mostRecentActivity?: string;
}
export interface ConversationUserStatuses {
  users: ConversationUserStatus[];
}

export interface NewMessageEvent {
  conversationId: string;
  messageId: string;
  sentBy: string;
  sentAt: string;
  text: string;
  documents?: ConversationAttachment[];
  clientMessageId: string | undefined;
  users?: ConversationMessageUsers[];
  widget?: MessageWidget;
}
export interface UnreadConversationCountUpdate {
  unreadConversationsCount: number;
}

export interface UnreadMessagesCountUpdate {
  unreadMessagesCount: number;
}

export interface MessageStatusChanged {
  conversationId: string;
  messageId: string;
  read?: boolean;
  users?: ConversationMessageUsers[];
}

export interface CommunicationStatus {
  online: boolean;
  killSwitch: boolean;
  disabled: boolean;
  brokerAcceptsLoadInquiries: boolean;
}

export interface ConversationsListRequest {
  token: string | undefined;
  limit: number | undefined;
  unread: boolean | undefined;
}

export interface UpdateMessagesStateEvent {
  messages?: MessageStatusChanged[];
}

export interface CanMessageResponse {
  canMessage: boolean;
}

export const INITIAL_CONVERSATIONS_LIMIT = 20;
export const PAGINATION_CONVERSATIONS_LIMIT = 10;

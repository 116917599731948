import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { closeMenu, openMenu } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { ViewField, WebViewPages, WebViews } from '@common/model';
import { Color } from '@style/Color';
import { MENU_ZINDEX } from '@style/StyleConstants';
import { useOnWindowResize, useSelector } from '@util/hooks';
import { Width } from '@util/MediaQueries';

import { MenuHeader } from './MenuHeader';
import { DESKTOP_MENU_TRANSITION_MS, DESKTOP_MENU_TRANSITION_TIMINGFUNC, MenuSimpleBar } from './menuStyles';
import { isWidthLargeEnoughForExpandedMenu, SideMenuContent } from './SideMenuContent';

const DesktopMenuDiv = styled<{ isExpanded: boolean }>(({ isExpanded, ...rest }) => <div {...rest} />)`
  position: fixed;
  left: 0;
  z-index: ${MENU_ZINDEX};
  display: flex;
  flex-direction: column;
  background-color: ${Color.MENU_BLACK};
  height: 100vh;
  max-width: 300px;
  transition: width ${DESKTOP_MENU_TRANSITION_MS}ms ${DESKTOP_MENU_TRANSITION_TIMINGFUNC};
  width: ${({ isExpanded }) => (isExpanded ? '300px' : '74px')};
  box-shadow: 0px 0px 5px 0px rgba(35, 35, 35, 0.8);
`;

export const DesktopMenu: React.FC = () => {
  const menuOpen = useSelector((store) => store.applicationSettings.menuOpen);

  useAutomaticOpenCloseMenuOnResize();

  return (
    <DesktopMenuDiv isExpanded={menuOpen}>
      <MenuHeader isExpanded={menuOpen} />
      <MenuSimpleBar opened={menuOpen}>
        <SideMenuContent isExpanded={menuOpen} />
      </MenuSimpleBar>
    </DesktopMenuDiv>
  );
};
const lastChosenView = (page: WebViewPages | undefined, viewChoices: WebViews) => {
  switch (page) {
    case WebViewPages.BrokerLoads: {
      return viewChoices.brokerLoads;
    }
    case WebViewPages.MyLoads: {
      return viewChoices.myLoads;
    }
    case WebViewPages.FindLoads: {
      return viewChoices.findLoads;
    }
    case WebViewPages.LoadAvailability: {
      return viewChoices.loadAvailability;
    }
    default: {
      return undefined;
    }
  }
};

const useAutomaticOpenCloseMenuOnResize = () => {
  const dispatch = useDispatch();
  const lastViewedPage = useSelector((state) => state.loadSearchSettings.lastViewedPage);
  const selectedViewOption = useSelector((state) => state.loadSearchSettings.viewOption);
  const selectedView = lastChosenView(lastViewedPage, selectedViewOption);

  const callback = useCallback(() => {
    if (
      isWidthLargeEnoughForExpandedMenu(
        selectedView && selectedView.field === ViewField.List ? Width.listSideMenuExansion : Width.sideMenuExpansion
      )
    ) {
      dispatch(openMenu());
    } else {
      dispatch(closeMenu());
    }
  }, [selectedView]);
  useOnWindowResize(callback, false);
};

import { cloneDeep, filter, map } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { iif as iif$, merge as merge$, of as of$ } from 'rxjs';
import {
  distinctUntilChanged,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  switchMap as switchMap$,
} from 'rxjs/operators';

import { LoadsClient } from '@common/client';
import { getFlowIDFrom } from '@common/helper/FlowIDHeaderHelper';
import { addLoadSearchFields, resetMetadata } from '@common/helper/LoadSearchRequestFactory';
import { SearchMetadataType } from '@common/model';
import { QueueActions } from '@common/redux/AsyncQueueMiddleware';
import { responseActionHandler } from '@common/redux/Base';
import { isValidSearch, LoadSearchBaseState } from '@common/redux/epic/loadSearch/HelperFunctions';
import {
  AutoRefreshResponseAction,
  createLoadSearchReducer,
  LoadCountAction,
  SearchLoadsAction,
  SearchLoadsByIdAction,
} from '@common/redux/epic/loadSearch/LoadSearchReducer';
import { FetchRateCheckPreviewAction } from '@common/redux/epic/rateCheck/RateCheckPreviewHelper';
import {
  getSettingsFromStateObservable,
  isRateCheckPreviewEnabled,
  SettingsState,
} from '@common/redux/epic/SettingsStateHelper';

export enum SearchReducerKey {
  LOAD_SEARCH = 'loadSearch',
  BACKHAULS = 'backhauls',
  MAP_SEARCH = 'mapSearch',
  LOAD_AVAILABILITY = 'loadAvailability',
  COMPANY_SEARCH = 'companySearch',
  COMPANY_SEARCH_BACKHAULS = 'companySearchBackhauls',
}

type LoadSearchSharedEpicStateObservable = StateObservable<{
  [reducerKey: string]: LoadSearchBaseState | SettingsState;
}>;

// ** reducerKey should match the key in your store, that's what we use to link the state observable and look at values,
// make sure they're matching.
export const createLoadSearch = (reducerKey: string) => {
  const loadSearchCommon = createLoadSearchReducer(reducerKey);
  const actionTypes = loadSearchCommon.actionTypes;
  const actions = loadSearchCommon.actions;

  const ACTIONS_BLOCKING_GROUP: QueueActions[] = [
    { initial: actionTypes.FETCH_LOADS, complete: actionTypes.LOADS_FETCHED },
    { initial: actionTypes.GET_NEXT_SEARCH_LOADS, complete: actionTypes.LOADS_FETCHED, overrideExisting: true },
    { initial: actionTypes.GET_NEXT_SEARCH_LOADS, complete: actionTypes.SIMILAR_LOADS_FETCHED, overrideExisting: true },
    { initial: actionTypes.GET_NEXT_SEARCH_LOADS, complete: actionTypes.LAST_RESULT, overrideExisting: true },
    { initial: actionTypes.FETCH_LOAD_COUNT, complete: actionTypes.LOAD_COUNT_FETCHED },
    { initial: actionTypes.FETCH_LOADS_BY_ID, complete: actionTypes.LOADS_FETCHED },
    { initial: actionTypes.SHOW_SIMILAR_LOADS, complete: actionTypes.SIMILAR_LOADS_FETCHED },
  ];
  const getLoadSearchState = (
    stateObservable: StateObservable<{ [reducerKey: string]: LoadSearchBaseState | SettingsState }>
  ) => stateObservable.value[reducerKey] as LoadSearchBaseState;

  const searchLoadsEpic$ = (
    action$: ActionsObservable<Action>,
    state$: LoadSearchSharedEpicStateObservable,
    client: LoadsClient
  ) =>
    action$.ofType(actionTypes.FETCH_LOADS).pipe(
      switchMap$((action: SearchLoadsAction) => {
        const searchState = getLoadSearchState(state$);
        let request = action.searchData !== undefined ? action.searchData : searchState.listSearchRequest;
        request = addLoadSearchFields(request);
        return client
          .searchLoads$(request, false, searchState.archivingFlowID, action.cancelGroup, action.originatedAppLocation)
          .pipe(
            map$((response) => {
              const flowID = getFlowIDFrom(response);
              return response.result(
                (data) => actions.loadsSearchResponse({ success: true, payload: data }, flowID, undefined),
                (error) => actions.loadsSearchResponse({ success: false, error: error }, flowID, undefined)
              );
            })
          );
      })
    );

  const searchLoadsByIdEpic$ = (action$: ActionsObservable<Action>, client: LoadsClient) =>
    action$.ofType(actionTypes.FETCH_LOADS_BY_ID).pipe(
      mergeMap$((action: SearchLoadsByIdAction) =>
        client.searchLoadsById$(action.id, addLoadSearchFields({ metadata: action.metadata }), false, undefined)
      ),
      map$((response) => response.resultDataResponse(actionTypes.LOADS_FETCHED))
    );

  const getNextSearchLoadsEpic$ = (
    action$: ActionsObservable<Action>,
    state$: LoadSearchSharedEpicStateObservable,
    client: LoadsClient
  ) =>
    action$.pipe(
      distinctUntilChanged((prevAction, action) => prevAction.type === action.type),
      ofType(actionTypes.GET_NEXT_SEARCH_LOADS),
      mergeMap$(() =>
        iif$(
          () => {
            const searchState = getLoadSearchState(state$);

            return searchState.isLastResult && (searchState.isLastSimilarResult || !searchState.showSimilarLoads);
          },
          of$({ type: actionTypes.LAST_RESULT }),
          iif$(
            () => getLoadSearchState(state$).isLastResult,
            client
              .searchSimilarLoads$(
                getLoadSearchState(state$).listSearchRequest,
                getLoadSearchState(state$).archivingFlowID
              )
              .pipe(map$((response) => response.resultDataResponse(actionTypes.SIMILAR_LOADS_FETCHED))),
            client
              .searchLoads$(
                addLoadSearchFields(getLoadSearchState(state$).listSearchRequest),
                false,
                getLoadSearchState(state$).archivingFlowID
              )
              .pipe(map$((response) => response.resultDataResponse(actionTypes.LOADS_FETCHED)))
          )
        )
      )
    );

  const fetchSimilarLoads$ = (
    action$: ActionsObservable<Action>,
    state$: LoadSearchSharedEpicStateObservable,
    loadsClient: LoadsClient
  ) =>
    action$.ofType(actionTypes.SHOW_SIMILAR_LOADS).pipe(
      mergeMap$(() => {
        const state = getLoadSearchState(state$);
        return loadsClient.searchSimilarLoads$(resetMetadata(state.listSearchRequest), state.archivingFlowID);
      }),
      map$((response): Action => {
        const flowID = getFlowIDFrom(response);
        return response.result(
          (data) =>
            actions.similarLoadsResponse(
              {
                success: true,
                payload: data,
              },
              flowID
            ),
          (error) =>
            actions.similarLoadsResponse(
              {
                success: false,
                error: error,
              },
              flowID
            )
        );
      })
    );

  const fetchLoadCount$ = (action$: ActionsObservable<Action>, loadsClient: LoadsClient) =>
    action$.ofType(actionTypes.FETCH_LOAD_COUNT).pipe(
      filter$((action: LoadCountAction) => isValidSearch(action.request)),
      switchMap$((action: LoadCountAction) => loadsClient.fetchLoadCount$(action.request)),
      map$((response) => response.resultDataResponse(actionTypes.LOAD_COUNT_FETCHED))
    );

  const refreshLoadCount$ = (action$: ActionsObservable<Action>, loadsClient: LoadsClient) =>
    action$.ofType(actionTypes.REFRESH_LOAD_COUNT).pipe(
      filter$((action: LoadCountAction) => isValidSearch(action.request)),
      mergeMap$((action: LoadCountAction) => loadsClient.fetchLoadCount$(action.request)),
      map$((response) => response.resultDataResponse(actionTypes.LOAD_COUNT_REFRESHED))
    );

  const autoRefresh$ = (
    action$: ActionsObservable<Action>,
    state$: LoadSearchSharedEpicStateObservable,
    loadsClient: LoadsClient
  ) =>
    action$.ofType(actionTypes.AUTO_REFRESH_LOADS).pipe(
      mergeMap$(() => {
        const storeState = getLoadSearchState(state$);
        const request = cloneDeep(storeState.listSearchRequest);
        request.metadata.type = SearchMetadataType.Refresh;
        request.metadata.limit = undefined;
        request.metadata.nextToken = storeState.nextToken;
        const id = storeState.selectedSearch?.id;
        if (id) {
          return loadsClient.searchLoadsById$(id, addLoadSearchFields(request), true, storeState.archivingFlowID).pipe(
            map$(
              (response): AutoRefreshResponseAction => ({
                ...response.resultDataResponse(actionTypes.AUTO_REFRESH_LOADS_COMPLETE),
                sentNextToken: request.metadata.nextToken,
              })
            )
          );
        }
        return loadsClient.searchLoads$(addLoadSearchFields(request), true, storeState.archivingFlowID).pipe(
          map$(
            (response): AutoRefreshResponseAction => ({
              ...response.resultDataResponse(actionTypes.AUTO_REFRESH_LOADS_COMPLETE),
              sentNextToken: request.metadata.nextToken,
            })
          )
        );
      })
    );

  const rateCheckPreviewEpic$ = (
    action$: ActionsObservable<Action>,
    state$: LoadSearchSharedEpicStateObservable,
    loadsClient: LoadsClient
  ) =>
    merge$(
      action$.ofType(actionTypes.LOADS_FETCHED, actionTypes.AUTO_REFRESH_LOADS_COMPLETE).pipe(
        mergeMap$(() => {
          if (!isRateCheckPreviewEnabled(getSettingsFromStateObservable(state$))) {
            return of$();
          }

          const storeState = getLoadSearchState(state$);

          const loadids = map(
            filter(storeState.loads, (load) => !load.rateCheckPreview),
            (load) => load.id
          );

          if (loadids.length === 0) {
            return of$();
          }

          return of$({
            type: actionTypes.FETCH_RATECHECK_PREVIEW,
            loadIds: loadids,
            forSimilarLoads: false,
          } as FetchRateCheckPreviewAction);
        })
      ),
      action$.ofType(actionTypes.SIMILAR_LOADS_FETCHED).pipe(
        mergeMap$(() => {
          if (!isRateCheckPreviewEnabled(getSettingsFromStateObservable(state$))) {
            return of$();
          }

          const storeState = getLoadSearchState(state$);
          const loadids = map(
            filter(storeState.similarLoads, (similarLoad) => !similarLoad.load.rateCheckPreview),
            (similarLoad) => similarLoad.load.id
          );

          if (loadids.length === 0) {
            return of$();
          }

          return of$({
            type: actionTypes.FETCH_RATECHECK_PREVIEW,
            loadIds: loadids,
            forSimilarLoads: true,
          } as FetchRateCheckPreviewAction);
        })
      ),
      action$.ofType(actionTypes.FETCH_RATECHECK_PREVIEW).pipe(
        mergeMap$((action: FetchRateCheckPreviewAction) => {
          const storeState = getLoadSearchState(state$);
          return loadsClient.fetchLoadRateCheckPreviews$(action.loadIds, storeState.archivingFlowID).pipe(
            map$((response) => ({
              ...responseActionHandler(actionTypes.RATECHECK_PREVIEW_COMPLETE, response),
              fetchData: action,
            }))
          );
        })
      )
    );

  const createdMergedEpic$ = (
    loadsClient: LoadsClient,
    action$: ActionsObservable<Action>,
    state$: LoadSearchSharedEpicStateObservable
  ) =>
    merge$(
      searchLoadsEpic$(action$, state$, loadsClient),
      searchLoadsByIdEpic$(action$, loadsClient),
      getNextSearchLoadsEpic$(action$, state$, loadsClient),
      fetchLoadCount$(action$, loadsClient),
      fetchSimilarLoads$(action$, state$, loadsClient),
      refreshLoadCount$(action$, loadsClient),
      autoRefresh$(action$, state$, loadsClient),
      rateCheckPreviewEpic$(action$, state$, loadsClient)
    );

  return {
    reducer: loadSearchCommon.reducer,
    createMergedEpic$: createdMergedEpic$,
    actionsQueue: ACTIONS_BLOCKING_GROUP,
    actions: actions,
    actionTypes: actionTypes,
    getLoadSearchBaseState: getLoadSearchState,
  };
};

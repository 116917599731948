export interface BaseFtp {
  status: ContactPostingFtpStatus;
  ftpName: string;
  ftpHost: string;
  ftpPort: number;
  username: string;
  ftpProtocol: FtpProtocol;
  ftpEncryption: FtpEncryption;
  remoteDirectory: string;
  dailyFullTransmission: boolean;
}

export interface NewFtp extends BaseFtp {
  password: string;
}

export interface ModifiedFtp extends BaseFtp {
  id: string;
  password?: string;
}

export interface Ftp extends BaseFtp {
  id: string;
  connectionValid?: boolean;
  connectionError?: string;
  lastTransmission?: string; // Doesn't exist yet, replace/change to required when feature is done
}

export enum FtpProtocol {
  None = 'None',
  Ftp = 'Ftp',
}

export enum FtpEncryption {
  None = 'None',
  PlainFtp = 'PlainFtp',
  ImplicitFtpOverTls = 'ImplicitFtpOverTls',
  ExplicitFtpOverTls = 'ExplicitFtpOverTls',
  ExplicitFtpOverTlsIfAvailable = 'ExplicitFtpOverTlsIfAvailable',
}

export enum ContactPostingFtpStatus {
  None = 'None',
  Active = 'Active',
  Deleted = 'Deleted',
  FailedWithRetry = 'FailedWithRetry',
  Failed = 'Failed',
}

export const MAX_FTP_NAME_LENGTH = 255;

import React from 'react';

import styled from 'styled-components';

import { displayCurrencyOrDash } from '@common/formatter';
import { LoadRateCheckPreview, RateCheckPreviewStatus } from '@common/model';
import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import { DetailRow } from '@component/panels/basePanel/loadDetails/generalInfo/LoadDetailsInfoBar';
import { UnlockLink } from '@component/panels/basePanel/loadDetails/generalInfo/UnlockLink';
import { useIsRateCheckPreviewEnabled } from '@page/rateCheck/RateCheckHelper';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

interface RateCheckInfoProps {
  isEquipmentTypeSupported: boolean;
  computedMileage?: number;
  rateCheckAvgRatePerMile?: number;
  rateCheckPreview?: LoadRateCheckPreview;
}

const Text = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  font-style: italic;
  color: ${Color.GRAY_STONE};
`;

export const RateCheckInfo: React.FC<RateCheckInfoProps> = (props) => {
  const rateCheckPreviewEnabled = useIsRateCheckPreviewEnabled();
  const isRateCheckAvailable = useSelector((state) => state.user.accesses.isRateCheckAvailable);

  const rateCheckRevenue =
    props.computedMileage && props.rateCheckAvgRatePerMile
      ? props.computedMileage * props.rateCheckAvgRatePerMile
      : undefined;
  const rateCheckAvgRatePerMile = props.rateCheckAvgRatePerMile
    ? displayCurrencyOrDash(props.rateCheckAvgRatePerMile, true, {
        suffix: t(T.common_per_miles_short),
      })
    : undefined;

  const renderRateCheckUnlockLink = () => {
    return <UnlockLink popupType={UnlockFeaturePopupType.RateCheck} />;
  };

  return (
    <DetailRow
      id="average_rate"
      title={t(T.common_marketRate_rateCheck_title)}
      subValue={renderRateCheckPerMileValue(
        props.rateCheckPreview?.ratePerMile,
        props.rateCheckPreview?.status,
        rateCheckPreviewEnabled,
        rateCheckAvgRatePerMile,
        props.isEquipmentTypeSupported
      )}
      value={renderAverageRateValue(
        props.isEquipmentTypeSupported,
        isRateCheckAvailable,
        props.rateCheckPreview?.totalRate,
        props.rateCheckPreview?.status,
        renderRateCheckUnlockLink,
        rateCheckPreviewEnabled,
        rateCheckRevenue
      )}
    />
  );
};

const renderAverageRateValue = (
  isEquipmentTypeSupported: boolean,
  isRateCheckAvailable: boolean,
  rateCheckPreviewTotalRate: number | undefined,
  rateCheckPreviewStatus: string | undefined,
  renderUpgradeLink: () => JSX.Element,
  rateCheckPreviewEnabled: boolean,
  loadDetailsRate?: number
) => {
  if (rateCheckPreviewEnabled) {
    if (rateCheckPreviewStatus === RateCheckPreviewStatus.UnsupportedEquipmentType) {
      return <Text>{t(T.common_marketRate_notAvailableTrailer)}</Text>;
    }
    if (rateCheckPreviewStatus === RateCheckPreviewStatus.NoRateCheckAccess) {
      return renderUpgradeLink();
    }
    return displayCurrencyOrDash(rateCheckPreviewTotalRate, false);
  }

  if (!isEquipmentTypeSupported) {
    return <Text>{t(T.common_marketRate_notAvailableTrailer)}</Text>;
  }

  if (!isRateCheckAvailable) {
    return renderUpgradeLink();
  }

  return loadDetailsRate ? (
    displayCurrencyOrDash(loadDetailsRate, false)
  ) : (
    <Text>{t(T.common_marketRate_notAvailableAtThisTime)}</Text>
  );
};

const renderRateCheckPerMileValue = (
  rateCheckPreviewRatePerMile: number | undefined,
  rateCheckPreviewStatus: string | undefined,
  rateCheckPreviewEnabled: boolean,
  loadDetailsRatePerMile: string | undefined,
  isEquipmentTypeSupported: boolean
) => {
  if (rateCheckPreviewEnabled) {
    if (
      rateCheckPreviewStatus === undefined ||
      rateCheckPreviewStatus === RateCheckPreviewStatus.Loading ||
      rateCheckPreviewStatus === RateCheckPreviewStatus.UnsupportedEquipmentType ||
      rateCheckPreviewStatus === RateCheckPreviewStatus.NoRateCheckAccess
    ) {
      return undefined;
    }
    return displayCurrencyOrDash(rateCheckPreviewRatePerMile, true, { suffix: t(T.common_per_miles_short) });
  }
  return isEquipmentTypeSupported ? loadDetailsRatePerMile : undefined;
};

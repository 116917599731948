import React, { RefObject } from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';

const SECTION_TOP_PADDING = 8;
const SECTION_BOTTOM_PADDING = 8;

const SECTION_SHADOW_HEIGHT = 8;

export const PANEL_SECTIONS_TOP_MARGING = 10;
export const PANEL_SECTIONS_BOTTOM_MARGING = 20;

interface ComponentProps {
  id?: string;
  /** First section of the panel. false to avoid top margin */
  isFirst?: boolean;
  /** Last section of the panel. false to avoid bottom margin */
  isLast?: boolean;
  sectionRef?: RefObject<HTMLSelectElement>;
  backgroundColor?: string;
}

export const SectionWithoutShadow = styled<ComponentProps>(
  ({ isFirst, isLast, sectionRef, backgroundColor, ...rest }) => <section ref={sectionRef} {...rest} />
)`
  position: relative;
  margin-top: ${({ isFirst }) => (isFirst ? PANEL_SECTIONS_TOP_MARGING : 0)}px;
  margin-bottom: ${({ isLast }) => (isLast ? PANEL_SECTIONS_BOTTOM_MARGING : 0)}px;
  padding-top: ${SECTION_TOP_PADDING}px;
  padding-bottom: ${SECTION_BOTTOM_PADDING + SECTION_SHADOW_HEIGHT}px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : Color.WHITE)};
  width: 100%;
`;

export const Section = styled<ComponentProps>(({ isFirst, isLast, sectionRef, ...rest }) => (
  <SectionWithoutShadow innerRef={sectionRef} {...rest} />
))`
  &::after {
    content: '';
    height: ${SECTION_SHADOW_HEIGHT}px;
    width: 100%;
    background: transparent linear-gradient(180deg, ${Color.GRAY_GRADIENT_DARK} 0%, ${Color.GRAY_GRADIENT_LIGHT} 100%)
      0% 0% no-repeat padding-box;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
`;

export const PanelSingleSection: React.FC = ({ children }) => (
  <Section isFirst={true} isLast={true}>
    {children}
  </Section>
);

import { findIndex, map, reduce, slice, split, toNumber } from 'lodash';
import memoizeOne from 'memoize-one';

export const CREDIT_RATING_ANY = {
  minTransCreditRating: 0,
  maxTransCreditRating: 0,
};

export const CREDIT_RATING_DAYS_TO_PAY_ANY = {
  minTransCreditDaysToPay: 0,
  maxTransCreditDaysToPay: 0,
};

export const POSTED_AGE_MINUTES = {
  minAge: 0,
  maxAge: 0,
};

export const isCreditRatingAny = (minTransCreditRating: number | undefined, maxTransCreditRating: number | undefined) =>
  minTransCreditRating === CREDIT_RATING_ANY.minTransCreditRating &&
  maxTransCreditRating === CREDIT_RATING_ANY.maxTransCreditRating;

export const isCreditRatingDaysToPayAny = (
  minTransCreditDaysToPay: number | undefined,
  maxTransCreditDaysToPay: number | undefined
) =>
  minTransCreditDaysToPay === CREDIT_RATING_DAYS_TO_PAY_ANY.minTransCreditDaysToPay &&
  maxTransCreditDaysToPay === CREDIT_RATING_DAYS_TO_PAY_ANY.maxTransCreditDaysToPay;

export const isPostedDateAny = (minAge: number | undefined, maxAge: number | undefined) =>
  minAge === POSTED_AGE_MINUTES.minAge && maxAge === POSTED_AGE_MINUTES.maxAge;

export interface CreditRatingRange {
  min: number;
  max: number;
}

export interface CreditRatingDaysToPayRange {
  min: number;
  max?: number;
}

export interface PostedAgeRange {
  min: number;
  max?: number;
}

export const CreditRatingRanges: CreditRatingRange[] = [
  { min: 0, max: 25 },
  { min: 25, max: 50 },
  { min: 50, max: 75 },
  { min: 75, max: 90 },
  { min: 90, max: 100 },
];

export const CreditRatingDaysToPayRanges: CreditRatingDaysToPayRange[] = [
  { min: 0, max: 25 },
  { min: 25, max: 35 },
  { min: 35, max: 45 },
  { min: 45, max: 55 },
  { min: 55, max: 65 },
  { min: 65 },
];

//the options in the BE are in seconds but we want to display in minutes
export const PostedAgeRanges: PostedAgeRange[] = [
  { min: 0, max: 5 * 60 },
  { min: 5 * 60, max: 10 * 60 },
  { min: 10 * 60, max: 30 * 60 },
  { min: 30 * 60, max: 60 * 60 },
  { min: 60 * 60 },
];

const indexOfRange = memoizeOne(
  <T extends CreditRatingRange | CreditRatingDaysToPayRange>(currentRange: T | undefined, ranges: T[]) => {
    if (!currentRange) {
      return -1;
    }
    return findIndex(ranges, (range) => currentRange.min === range.min && currentRange.max === range.max);
  }
);

export const indexOfRatingRange = (
  range: CreditRatingRange | undefined,
  ranges: CreditRatingRange[] = CreditRatingRanges
) => indexOfRange(range, ranges);

export const indexOfDaysToPayRange = (
  range: CreditRatingDaysToPayRange | undefined,
  ranges: CreditRatingDaysToPayRange[] = CreditRatingDaysToPayRanges
) => indexOfRange(range, ranges);

export const indexOfPostedAgeRange = (range: PostedAgeRange | undefined, ranges: PostedAgeRange[] = PostedAgeRanges) =>
  indexOfRange(range, ranges);

export const creditRatingRangesFromStrings = (stringValues: string[]) => map(stringValues, creditRatingFromString);

export const creditRatingFromString = (stringValue: string): CreditRatingRange => {
  const [min, max] = split(stringValue, '-');
  return { min: toNumber(min), max: toNumber(max) };
};

export const creditRatingRangesToStrings = (ranges: CreditRatingRange[]) => map(ranges, creditRatingRangeToString);

export const creditRatingRangeToString = (range: CreditRatingRange) => `${range.min}-${range.max}`;

export const creditRatingDaysToPayRangesToStrings = (ranges: CreditRatingDaysToPayRange[]) =>
  map(ranges, creditRatingDaysToPayRangeToString);

export const creditRatingDaysToPayRangeToString = (range: CreditRatingDaysToPayRange) =>
  range.max !== undefined ? `${range.min}-${range.max}` : `${range.min}+`;

export const postedAgeToPayRangeToStrings = (ranges: PostedAgeRange[]) => map(ranges, postedAgeToPayRangeToString);

export const postedAgeToPayRangeToString = (range: PostedAgeRange) =>
  range.max !== undefined ? `${range.min / 60}-${range.max / 60}` : `${range.min / 60}+`;

export const creditRatingStringValuesFromMinMax = (min: number, max: number): string[] =>
  reduce(
    CreditRatingRanges,
    (stringValues, range) =>
      range.min >= min && range.max <= max ? [...stringValues, creditRatingRangeToString(range)] : stringValues,
    []
  );

export const creditRatingDaysToPayStringValuesFromMinMax = (min: number, max: number | undefined): string[] =>
  reduce(
    CreditRatingDaysToPayRanges,
    (stringValues, range) =>
      range.min >= min && (max === undefined || (range.max !== undefined && range.max <= max))
        ? [...stringValues, creditRatingDaysToPayRangeToString(range)]
        : stringValues,
    []
  );

export const postedAgeStringValuesFromMinMax = (min: number, max: number | undefined): string[] =>
  reduce(
    PostedAgeRanges,
    (stringValues, range) =>
      range.min >= min && (max === undefined || (range.max !== undefined && range.max <= max))
        ? [...stringValues, postedAgeToPayRangeToString(range)]
        : stringValues,
    []
  );

export const creditRatingDaysToPayRangesFromStrings = (stringValue: string[]) =>
  map(stringValue, creditRatingDaysToPayRangeFromString);

export const creditRatingDaysToPayRangeFromString = (stringValue: string): CreditRatingDaysToPayRange => {
  if (stringValue.slice(stringValue.length - 1) === '+') {
    return { min: toNumber(stringValue.slice(0, stringValue.length - 1)) };
  }
  const [min, max] = split(stringValue, '-');
  return { min: toNumber(min), max: toNumber(max) };
};

export const postedAgeRangesFromStrings = (stringValue: string[]) => map(stringValue, postedAgeFromString);

export const postedAgeFromString = (stringValue: string): PostedAgeRange => {
  if (stringValue.slice(stringValue.length - 1) === '+') {
    return { min: toNumber(stringValue.slice(0, stringValue.length - 1)) * 60 };
  }
  const [min, max] = split(stringValue, '-');
  return { min: toNumber(min) * 60, max: toNumber(max) * 60 };
};

export const handleRangeChange = (
  range: CreditRatingRange,
  selectedRanges: CreditRatingRange[],
  setSelectedRange: (ranges: CreditRatingRange[]) => void
) => {
  const currentRangeIndex = indexOfRatingRange(range);
  if (currentRangeIndex === -1) {
    return;
  }
  const selectedFirstIndex = indexOfRatingRange(selectedRanges[0]);
  const selectedLastIndex = indexOfRatingRange(selectedRanges[selectedRanges.length - 1]);
  if (selectedFirstIndex === -1 || selectedLastIndex === -1) {
    setSelectedRange([range]);
    return;
  }
  if (currentRangeIndex < selectedFirstIndex) {
    setSelectedRange(slice(CreditRatingRanges, currentRangeIndex, selectedLastIndex + 1));
  } else if (currentRangeIndex > selectedLastIndex) {
    setSelectedRange(slice(CreditRatingRanges, selectedFirstIndex, currentRangeIndex + 1));
  } else if (selectedLastIndex - currentRangeIndex > currentRangeIndex - selectedFirstIndex) {
    setSelectedRange(slice(CreditRatingRanges, currentRangeIndex + 1, selectedLastIndex + 1));
  } else {
    setSelectedRange(slice(CreditRatingRanges, selectedFirstIndex, currentRangeIndex));
  }
};

export const handleDaysToPayRangeChange = (
  range: CreditRatingDaysToPayRange,
  selectedRanges: CreditRatingDaysToPayRange[],
  setSelectedRange: (ranges: CreditRatingDaysToPayRange[]) => void
) => {
  const currentRangeIndex = indexOfDaysToPayRange(range);
  if (currentRangeIndex === -1) {
    return;
  }
  const selectedFirstIndex = indexOfDaysToPayRange(selectedRanges[0]);
  const selectedLastIndex = indexOfDaysToPayRange(selectedRanges[selectedRanges.length - 1]);
  if (selectedFirstIndex === -1) {
    setSelectedRange([range]);
    return;
  } else if (selectedLastIndex === -1) {
    setSelectedRange([]);
    return;
  }

  if (currentRangeIndex < selectedFirstIndex) {
    setSelectedRange(slice(CreditRatingDaysToPayRanges, currentRangeIndex, selectedLastIndex + 1));
  } else if (currentRangeIndex > selectedLastIndex) {
    setSelectedRange(slice(CreditRatingDaysToPayRanges, selectedFirstIndex, currentRangeIndex + 1));
  } else if (selectedLastIndex - currentRangeIndex > currentRangeIndex - selectedFirstIndex) {
    setSelectedRange(slice(CreditRatingDaysToPayRanges, currentRangeIndex + 1, selectedLastIndex + 1));
  } else {
    setSelectedRange(slice(CreditRatingDaysToPayRanges, selectedFirstIndex, currentRangeIndex));
  }
};

export const handlePostedAgeChange = (
  range: PostedAgeRange,
  selectedRanges: PostedAgeRange[],
  setSelectedRange: (ranges: PostedAgeRange[]) => void
) => {
  const currentRangeIndex = indexOfPostedAgeRange(range);
  if (currentRangeIndex === -1) {
    return;
  }
  const selectedFirstIndex = indexOfPostedAgeRange(selectedRanges[0]);
  const selectedLastIndex = indexOfPostedAgeRange(selectedRanges[selectedRanges.length - 1]);
  if (selectedFirstIndex === -1) {
    setSelectedRange([range]);
    return;
  } else if (selectedLastIndex === -1) {
    setSelectedRange([]);
    return;
  }

  if (currentRangeIndex < selectedFirstIndex) {
    setSelectedRange(slice(PostedAgeRanges, currentRangeIndex, selectedLastIndex + 1));
  } else if (currentRangeIndex > selectedLastIndex) {
    setSelectedRange(slice(PostedAgeRanges, selectedFirstIndex, currentRangeIndex + 1));
  } else if (selectedLastIndex - currentRangeIndex > currentRangeIndex - selectedFirstIndex) {
    setSelectedRange(slice(PostedAgeRanges, currentRangeIndex + 1, selectedLastIndex + 1));
  } else {
    setSelectedRange(slice(PostedAgeRanges, selectedFirstIndex, currentRangeIndex));
  }
};

import React, { ReactNode, RefObject, useRef } from 'react';

import SimpleBar from 'simplebar-react';
import styled from 'styled-components';

import { useScrollingDivCollapsibleLogic } from './collapsibleLogic';
import {
  BlockFooterPortalDestination,
  BlockHeaderPortalDestination,
  CollapsibleHeaderPortalDestination,
} from './FixedAreaPortalDestination';
import { FixedAreaRenderProvider } from './FixedAreaRenderContext';

const AbsoluteFillSimpleBar = styled(SimpleBar)`
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  position: absolute;
`;

interface Props {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  scrollRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
}

/** Defines a scrollable area meant for an area of the UI smaller than the page with stylized scrollbars.
 * Use FixedHeader/FixedFooter components to define what is not to be scrolled (header or footer) respectively.
 */
export const DivScrollView: React.FC<Props> = ({ id, className, style, scrollRef, children }) => {
  const internalScrollRef = useRef<HTMLDivElement>(null);
  const collapsibleRef = useRef<HTMLDivElement>(null);

  const currentScrollRef = scrollRef ?? internalScrollRef;

  useScrollingDivCollapsibleLogic(collapsibleRef, currentScrollRef);

  return (
    <div
      id={id}
      className={className}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', ...style }}
    >
      <FixedAreaRenderProvider>
        <BlockHeaderPortalDestination includesCollapsible={false} />
        <div id="containerforsimplebar" style={{ flexGrow: 1, position: 'relative' }}>
          <AbsoluteFillSimpleBar scrollableNodeProps={{ ref: currentScrollRef }}>
            <CollapsibleHeaderPortalDestination collapsibleRef={collapsibleRef} />
            <div
              id="containerforscrollablecontent"
              className={'containerforscrollablecontent'}
              style={{ position: 'relative', zIndex: 0 }}
            >
              {children}
            </div>
          </AbsoluteFillSimpleBar>
        </div>
        <BlockFooterPortalDestination id="footer" />
      </FixedAreaRenderProvider>
    </div>
  );
};

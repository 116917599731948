import { includes, intersection } from 'lodash';
import memoizeOne from 'memoize-one';

import { Roles } from '@common/model';
import { t, T } from '@translate';

export const hasRoles = (currentRoles: Roles[] | undefined, validRoles: Roles[]) => {
  if (!currentRoles) {
    return false;
  }
  return intersection(currentRoles, validRoles).length !== 0;
};

export const getUserMemberType = memoizeOne((roles: Roles[] | undefined) => {
  if (includes(roles, Roles.Premium)) {
    return Roles.Premium;
  }
  if (includes(roles, Roles.Standard)) {
    return Roles.Standard;
  }
  if (includes(roles, Roles.Basic)) {
    return Roles.Basic;
  }
  if (includes(roles, Roles.Guest)) {
    return Roles.Guest;
  }
  return Roles.Member;
});

export const userRoleLocalizedText = (role: Roles | undefined) => {
  switch (role) {
    case Roles.Premium:
      return t(T.common_userRoles_premium);
    case Roles.Standard:
      return t(T.common_userRoles_standard);
    case Roles.Basic:
    case Roles.Guest:
      return t(T.common_userRoles_basic);
    default:
      return t(T.common_userRoles_member);
  }
};

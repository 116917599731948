import React from 'react';

import styled from 'styled-components';

import { RateCheckFeedback } from '@common/model';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Color } from '@style/Color';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

const RateCheckResponseContainer = styled.div`
  height: ${ElementSize.RateCheckConfirmation}px;
  overflow: hidden;
  justify-content: center;
  margin: auto 0;
  align-items: flex-start;
  display: flex;
  padding-top: ${Spacing.ElementPaddingVertical}px;
`;

export const RateCheckFeedbackResponse: React.FC<{ rateCheckFeedback?: RateCheckFeedback }> = (props) => {
  return (
    <RateCheckResponseContainer>
      {!props.rateCheckFeedback || props.rateCheckFeedback?.isLoading ? (
        <LoadingSpinner />
      ) : (
        <span
          style={{
            textAlign: 'center',
            color: props.rateCheckFeedback?.isSuccess ? undefined : Color.RED_APPLE,
          }}
        >
          {props.rateCheckFeedback?.isSuccess
            ? t(T.common_rateCheck_confirmation_thankYou)
            : t(T.common_rateCheck_confirmation_errorSubmitting)}
        </span>
      )}
    </RateCheckResponseContainer>
  );
};

import styled from 'styled-components';

import { StyledHeader, StyledRow } from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { withTheme } from '@style/WithTheme';

export const Header = styled(StyledHeader)`
  margin-left: 15px;
  margin-bottom: 0;
`;

export const StyledPaddedRow = withTheme()(styled(StyledRow)`
  padding: 16px 16px 18px 16px;
`);

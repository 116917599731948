import React from 'react';

import styled from 'styled-components';

import { displayCurrencyOrDash } from '@common/formatter';
import { LOADBOARD_HELP_CARGO_CHIEF } from '@common/info';
import { default as CargoChiefGraph } from '@component/panels/basePanel/loadDetails/marketRates/cargo-data.svg';
import { ModalTitleBold } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupEntity, PopupSizes } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text/Text';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { t, T } from '@translate';

const Label = styled(Text)`
  color: ${Color.GRAY_21};
  text-align: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FullWidthColumn = styled(Column)`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 8px;
`;

const ModalTitle = styled.span`
  font-size: ${FontSize.ModalTitle}px;
`;

interface Props {
  estimatedRate?: number;
  allInCostLow?: number;
  allInCost?: number;
  allInCostHigh?: number;
}

export const CargoChiefRateContent: React.FC<Props> = ({ estimatedRate, allInCostLow, allInCost, allInCostHigh }) => (
  <>
    <Row style={{ paddingTop: 0, paddingBottom: 20 }}>
      <Column style={{ marginRight: 20 }}>
        <ModalTitleBold id="rate_estimate_value" style={{ color: Color.GREEN_TITLE, textAlign: 'center' }}>
          {displayCurrencyOrDash(allInCost, false)}
        </ModalTitleBold>
        <Text textStyle={TextStyle.SubtitleNormalWeight} style={{ color: Color.LIGHT_BLACK }} id="rate_est_label">
          {t(T.common_marketRate_cargoChief_rateEst)}
        </Text>
      </Column>
      <Column>
        <ModalTitle id="rate_per_mile" style={{ fontWeight: 'normal', textAlign: 'center' }}>
          {displayCurrencyOrDash(estimatedRate)}
        </ModalTitle>
        <Text textStyle={TextStyle.SubtitleNormalWeight} style={{ color: Color.LIGHT_BLACK }} id="rate_per_mile_label">
          {t(T.common_marketRate_cargoChief_perMile)}
        </Text>
      </Column>
    </Row>
    <FullWidthColumn>
      <img id="cargo_chief_graph" src={CargoChiefGraph} alt="CargoChiefGraph" />
      <Row style={{ justifyContent: 'space-around' }}>
        <Column>
          <Text textStyle={TextStyle.Header3} style={{ color: Color.GRAY_21 }} id="low_est">
            {displayCurrencyOrDash(allInCostLow, false)}
          </Text>
          <Label textStyle={TextStyle.Body} id="low_est_label">
            {t(T.common_marketRate_cargoChief_lowEst)}
          </Label>
        </Column>
        <Column>
          <Text textStyle={TextStyle.Header3} style={{ color: Color.LIGHT_BLACK }} id="est">
            {displayCurrencyOrDash(allInCost, false)}
          </Text>
          <Label textStyle={TextStyle.Body} id="est_label">
            {t(T.common_marketRate_cargoChief_est)}
          </Label>
        </Column>
        <Column>
          <Text textStyle={TextStyle.Header3} style={{ color: Color.GRAY_21 }} id="high_est">
            {displayCurrencyOrDash(allInCostHigh, false)}
          </Text>
          <Label textStyle={TextStyle.Body} id="high_est_label">
            {t(T.common_marketRate_cargoChief_highEst)}
          </Label>
        </Column>
      </Row>
    </FullWidthColumn>
  </>
);

export const handleCargoChiefInfoClick = (openingPopup: (popup: PopupEntity) => void, closingPopup: () => void) =>
  openingPopup({
    body: (
      <BasePopup
        title={t(T.common_marketRate_cargoChief_title)}
        buttons={[
          <PopupPrimaryButton
            testID="cargo_learn_more"
            key={t(T.common_membership_learnMore)}
            label={t(T.common_membership_learnMore)}
            action={() => window.open(LOADBOARD_HELP_CARGO_CHIEF, '_blank')}
          />,
          <PopupOkButton testID="cargo_ok" key={t(T.common_ok)} action={closingPopup} />,
        ]}
      >
        {t(T.common_marketRate_cargoChief_info)}
      </BasePopup>
    ),
    isDismissible: true,
    hasCloseButton: true,
    width: PopupSizes.SMALL,
  });

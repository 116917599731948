import React from 'react';

import styled from 'styled-components';

import { shouldShow4DigitsAfterComma, show4DigitsAfterComma } from '@common/formatter';
import { formatNumberWithZeroFormatting } from '@common/helper';
import {
  Field,
  FieldType,
  isAnyFieldEmpty,
  isAnyFieldInvalid,
  isAnyFieldValueRequired,
} from '@component/panels/basePanel/loadDetails/profitCalculator/Field';
import TextInputBox from '@component/panels/basePanel/loadDetails/profitCalculator/TextInputBox';
import {
  BoldText,
  InputWrapper,
  LoadDetailsContainer,
  TitleText,
  TitleWrapper,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { Color } from '@style/Color';
import { t, T } from '@translate';

import { Calculation } from './Calculation';

interface LinehaulRevenueProps {
  milesField: Field;
  costPerMileField: Field;
  rateField: Field;
  focusedField: FieldType | undefined;
  onMilesChange: (text: string) => void;
  onCostPerMileChange: (text: string) => void;
  onRateChange: (text: string) => void;
  onFocus: (fieldType: FieldType) => () => void;
  onEndEditing: (fieldType: FieldType) => () => void;
}

const Sign = styled.span`
  margin: 0 10px;
`;

const LinehaulRevenue: React.FC<LinehaulRevenueProps> = (props: LinehaulRevenueProps) => {
  const costPerMileCalculationValue = shouldShow4DigitsAfterComma(props.costPerMileField.unformattedValue)
    ? show4DigitsAfterComma(props.costPerMileField.unformattedValue)
    : formatNumberWithZeroFormatting(props.costPerMileField.value(), true);
  let linehaulRevenueCalculationText = `${formatNumberWithZeroFormatting(
    props.milesField.value()
  )} * ${costPerMileCalculationValue} = ${formatNumberWithZeroFormatting(props.rateField.value())}`;
  let isLinehaulRevenueFieldsValid = true;

  if (isAnyFieldInvalid(props.milesField, props.costPerMileField, props.rateField)) {
    linehaulRevenueCalculationText = t(T.findLoads_findLoads_fieldsInvalid);
    isLinehaulRevenueFieldsValid = false;
  }
  if (isAnyFieldEmpty(props.milesField, props.costPerMileField, props.rateField)) {
    linehaulRevenueCalculationText = t(T.findLoads_findLoads_pleaseEnterValues);
  }
  if (isAnyFieldValueRequired(props.milesField, props.costPerMileField, props.rateField)) {
    linehaulRevenueCalculationText = t(T.findLoads_findLoads_fieldsRequiredOrInvalid);
    isLinehaulRevenueFieldsValid = false;
  }

  return (
    <LoadDetailsContainer id="linehaul_revenue" isWithoutTopLine={true}>
      <TitleWrapper>
        <TitleText id="title">{t(T.findLoads_findLoads_Linehaul_Revenue)}</TitleText>
        <BoldText
          id="revenue"
          style={{ color: props.rateField.normalisedText() !== '0' ? Color.GREEN_MAIN : Color.BLACK }}
        >
          {t(T.unit_money, {
            value: formatNumberWithZeroFormatting(props.rateField.value()),
            currency: '$',
          })}
        </BoldText>
      </TitleWrapper>
      <Calculation id="calculation_text" isValid={isLinehaulRevenueFieldsValid} text={linehaulRevenueCalculationText} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <InputWrapper id="miles_linehaul">
          <TextInputBox
            field={props.milesField}
            onChangeText={props.onMilesChange}
            placeholder={t(T.findLoads_findLoads_Miles)}
            isInFocus={props.focusedField === FieldType.Miles}
            onFocus={props.onFocus}
            onBlur={props.onEndEditing(FieldType.Miles)}
          />
        </InputWrapper>
        <Sign id="multiplication">*</Sign>
        <InputWrapper id="cost_per_mile">
          <TextInputBox
            field={props.costPerMileField}
            onChangeText={props.onCostPerMileChange}
            placeholder={t(T.findLoads_findLoads_perMile)}
            isInFocus={props.focusedField === FieldType.CostPerMile}
            onFocus={props.onFocus}
            onBlur={props.onEndEditing(FieldType.CostPerMile)}
          />
        </InputWrapper>
        <Sign id="equal">=</Sign>
        <InputWrapper id="rate">
          <TextInputBox
            field={props.rateField}
            onChangeText={props.onRateChange}
            placeholder={t(T.findLoads_findLoads_Rate)}
            isInFocus={props.focusedField === FieldType.Rate}
            onFocus={props.onFocus}
            onBlur={props.onEndEditing(FieldType.Rate)}
          />
        </InputWrapper>
      </div>
    </LoadDetailsContainer>
  );
};

export default LinehaulRevenue;

import React, { RefObject } from 'react';

import { cloneDeep, find, findIndex, isEqual, map } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { CircularProgress } from '@material-ui/core';
import StepLabel from '@material-ui/core/StepLabel';

import { showSnackbar, SnackbarProps } from '@/reduxStore/reducer/SnackbarReducer';
import { StoreState } from '@/reduxStore/Store';
import { Routes } from '@/router/Routes';
import { getLoadProgress } from '@common/helper';
import {
  getFileActionTitle,
  hasBOLorLumpers,
  hasProofOfDelivery,
  hasRateConfirmation,
} from '@common/helper/DocumentHelper';
import { loadProgressLabelMap } from '@common/helper/ProgressHelper';
import {
  CustomFolder,
  Document,
  DocumentFilters,
  LoadProgress,
  QuoteListResponse,
  QuoteRequest,
  QuoteType,
} from '@common/model';
import { Load, LoadStatus } from '@common/model/Load';
import { VhubEvent } from '@common/model/Tracking';
import { createDefaultDocumentFilters, DocumentsContext } from '@common/redux/epic/DocumentListEpic';
import { fetchDocuments } from '@common/redux/epic/DocumentsEpic';
import { LoadInfo, saveLoad, setProgress } from '@common/redux/epic/LoadInfoEpic';
import {
  fetchGetPaidFasterQuote,
  fetchMostRecentQuoteForLoad,
  LoadQuotesState,
  postFuelAdvanceQuote,
  postGetPaidFasterQuote,
  QuoteState,
} from '@common/redux/epic/QuoteEpic';
import { UserState } from '@common/redux/epic/UserEpic';
import { createVhubBookingTrackData } from '@common/util/123lbBaseTracker';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { Title } from '@component/expansionPanel/ExpansionPanelStyle';
import { LoadContactCallbackProps } from '@component/panels/findLoads/loadDetailsPanel/buttons/ContactButtons';
import { VhubBookWithPopup } from '@component/panels/findLoads/loadDetailsPanel/buttons/VhubBookWithButton';
import { PopupSizes, PopupTracking, withPopups } from '@component/popup/PopupTrackingContext';
import { default as StatusBarIcon } from '@component/statusBar/icon-status.svg';
import { ManageLine } from '@component/statusBar/ManageLine';
import {
  showFuelAdvanceInfoPopup,
  showGetPaidFasterInfoPopup,
  showQuoteResponsePopup,
  showUnBookPopup,
} from '@component/statusBar/StatusBarPopups';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import icons from '@util/iconsConstants';
import { track } from '@util/trackers/123lbTracker';

import { default as SelectedSVG } from './selected.svg?react';
import { StatusBarContactOptions } from './StatusBarContactOptions';
import {
  ActionButton,
  BottomStepper,
  CheckBoxRow,
  ColumnWrapper,
  Description,
  Label,
  LabelSent,
  Left,
  QontoConnector,
  QontoConnectorVertical,
  QontoStep,
  Right,
  Row,
  StepCheckbox,
  StepDescriptionBlock,
  SubTitleContainer,
  WrapperLabelSent,
} from './StatusBarStyle';
import { default as UnselectedSVG } from './unselected.svg?react';

interface DispatchProps {
  setProgress: (loadId: string, progress: LoadProgress) => void;
  fetchGetPaidFasterQuote: () => void;
  postFuelAdvanceQuote: (request: QuoteRequest) => void;
  postGetPaidFasterQuote: (quoteRequest: QuoteRequest) => void;
  fetchMostRecentQuoteForLoad: (id: string) => void;
  showSnackbar(snackbarContents: SnackbarProps): void;
  saveLoad: (loadID: string, save: boolean) => void;
  fetchDocuments: (context: DocumentsContext, filters: DocumentFilters) => void;
}

interface ReduxProps {
  user: UserState;
  isMenuOpen: boolean;
  fuelAdvanceQuote: QuoteState;
  getPaidFasterQuoteState: QuoteState;
  loadQuotes: LoadQuotesState;
  documents: Document[];
  loadFolderIdentifier: CustomFolder | undefined;
  loadInfo?: LoadInfo;
  isDocumentsLoading: boolean;
}

interface ComponentProps extends LoadContactCallbackProps {
  currentLoad: Load;
  isLoadCalled: boolean;
  width: number;
  isOpenDetails: boolean;
  onExpand: () => void;
  statusBarRef: RefObject<HTMLDivElement>;
  popupContext?: PopupTracking;
  isFromMyLoads?: boolean;
  shouldShowPost: boolean;
  onPostClick: () => void;
  isOriginMessaging: boolean;
  isOriginBids: boolean;
}

type Props = DispatchProps & ReduxProps & ComponentProps;

interface Step {
  id: string;
  label: string;
  value: LoadProgress;
  isChecked: boolean;
  isLoading: boolean;
}

interface State {
  activeStep: number;
  steps: Step[];
  preparedStep?: {
    step: number;
    value: boolean;
  };
}

class StatusBarComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeStep: -1,
      steps: [
        {
          label: t(T.findLoads_findLoads_stepsStatusBar_0),
          value: LoadProgress.LoadAvailable,
          isChecked: true,
          isLoading: false,
          id: 'load_available',
        },
        {
          label: t(T.findLoads_findLoads_stepsStatusBar_1),
          value: LoadProgress.Booked,
          isChecked: false,
          isLoading: false,
          id: 'booked',
        },
        {
          label: t(T.findLoads_findLoads_stepsStatusBar_3),
          value: LoadProgress.PickupComplete,
          isChecked: false,
          isLoading: false,
          id: 'pickup_complete',
        },
        {
          label: t(T.findLoads_findLoads_stepsStatusBar_5),
          value: LoadProgress.Delivered,
          isChecked: false,
          isLoading: false,
          id: 'delivered',
        },
      ],
    };
  }

  componentDidMount() {
    const { currentLoad } = this.props;
    this.props.fetchGetPaidFasterQuote();
    if (currentLoad) {
      this.props.fetchMostRecentQuoteForLoad(currentLoad.id);
    }
    this.updateActiveSteps();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      !isEqual(prevProps.fuelAdvanceQuote.isPostLoading, this.props.fuelAdvanceQuote.isPostLoading) &&
      !this.props.fuelAdvanceQuote.isPostLoading &&
      this.props.popupContext
    ) {
      showQuoteResponsePopup(this.props.fuelAdvanceQuote, this.props.popupContext, Routes.TOOLS_SAVE_AT_THE_PUMP);
    }
    if (
      !isEqual(prevProps.getPaidFasterQuoteState.isPostLoading, this.props.getPaidFasterQuoteState.isPostLoading) &&
      !this.props.getPaidFasterQuoteState.isPostLoading &&
      this.props.popupContext
    ) {
      showQuoteResponsePopup(this.props.getPaidFasterQuoteState, this.props.popupContext, Routes.TOOLS_GET_PAID_FASTER);
    }
    if (prevProps.currentLoad.metadata?.userdata?.progress !== this.props.currentLoad.metadata?.userdata?.progress) {
      this.updateActiveSteps();
    }
    if (this.props.loadFolderIdentifier && prevProps.loadFolderIdentifier?.id !== this.props.loadFolderIdentifier.id) {
      this.props.fetchDocuments(DocumentsContext.STANDALONE_LOADDETAILS, {
        ...createDefaultDocumentFilters(),
        folder: {
          id: this.props.loadFolderIdentifier.id,
          type: 'CustomFolder',
          name: this.props.loadFolderIdentifier.name,
        },
      });
    }
    if (this.props.loadInfo?.isProgressUpdating !== prevProps.loadInfo?.isProgressUpdating) {
      const cSteps = cloneDeep(this.state.steps);
      cSteps[this.state.activeStep].isLoading = this.props.loadInfo?.isProgressUpdating ?? false;
      this.setState({ steps: cSteps });
    }

    if (this.props.loadInfo?.progressUpdateTime !== prevProps.loadInfo?.progressUpdateTime) {
      this.props.showSnackbar({
        message: this.props.loadInfo?.isUpdatingSuccess
          ? t(T.common_load_Status_updated)
          : t(T.common_load_Status_not_updated),
      });
      this.updateActiveSteps();
    }
  }

  updateActiveSteps = () => {
    const progress = getLoadProgress(this.props.currentLoad);
    if (!progress) {
      this.setState({ activeStep: -1 });
      return;
    }
    const activeStep = findIndex(this.state.steps, (step) => step.value === progress);
    const cSteps = map(this.state.steps, (step, index) => ({ ...step, isChecked: index <= activeStep }));
    if (this.state.activeStep !== -1 && this.state.activeStep !== activeStep) {
      cSteps[this.state.activeStep].isLoading = false;
    }
    this.setState({ activeStep: activeStep, steps: cSteps });
  };

  sendFuelAdvanceRequest = () => {
    const user = getUserDataForQuotesRequest(this.props.user);
    if (user && this.props.currentLoad) {
      this.props.postFuelAdvanceQuote({
        ...user,
        loadId: this.props.currentLoad.id,
      });
    }
  };

  sendPayFasterRequest = () => {
    const user = getUserDataForQuotesRequest(this.props.user);
    if (user && this.props.currentLoad) {
      this.props.postGetPaidFasterQuote({
        ...user,
        loadId: this.props.currentLoad.id,
      });
    }
  };

  prepareStep = (step: number) => (e: React.SyntheticEvent, checked: boolean) => {
    const preparedStep = {
      step: step,
      value: checked,
    };
    if (this.state.steps.length === step + 1 || (this.state.steps[step + 1] && !this.state.steps[step + 1].isChecked)) {
      this.setState({ preparedStep: preparedStep }, () => {
        switch (step) {
          case 1: {
            if (checked) {
              this.setStep();
            } else {
              showUnBookPopup(this.setStep, this.props.popupContext);
            }
            break;
          }
          // fall through
          default: {
            this.setStep();
          }
        }
      });
    }
  };

  setStep = () => {
    const { preparedStep, steps } = this.state;
    const currentLoad = this.props.currentLoad;
    if (preparedStep) {
      const cState = cloneDeep(steps);
      cState[preparedStep.step].isChecked = preparedStep.value;
      this.setState({ activeStep: preparedStep.step, steps: cState });

      if (preparedStep.value) {
        this.props.setProgress(currentLoad.id, this.state.steps[preparedStep.step].value);
      } else {
        this.setState({ activeStep: preparedStep.step - 1 });
        this.props.setProgress(currentLoad.id, this.state.steps[preparedStep.step - 1].value);
      }
    }
  };

  QontoStepIcon = (orientation: string) => (props: { active: boolean; completed: boolean; icon: number }) => {
    const { completed, active, icon } = props;
    const isUnavailable = this.isUnavailableStep() && orientation === 'horizontal';

    const initialStyle = isEqual(orientation, 'horizontal')
      ? { marginTop: '0px', zIndex: 10 }
      : { marginTop: '-3px', zIndex: 10 };

    const style = {
      ...initialStyle,
      ...(completed &&
        icon === this.state.activeStep + 1 &&
        isEqual(orientation, 'horizontal') && { marginTop: '-3px' }),
      ...(completed &&
        icon === this.state.activeStep + 1 &&
        !isEqual(orientation, 'horizontal') && { margin: '-3px 0 0 -3px' }),
    };

    if (completed && icon === this.state.activeStep + 1) {
      return (
        <SelectedSVG id="circle_selected" style={style} fill={isUnavailable ? Color.RED_APPLE : Color.GREEN_MAIN} />
      );
    } else if (completed || (completed && !active)) {
      return <UnselectedSVG id="circle_unselected" style={style} />;
    } else {
      return <UnselectedSVG id="circle_inactive" stroke={Color.GREEN_INACTIVE} />;
    }
  };

  onVhubBookClick = () => {
    const currentLoad = this.props.currentLoad;
    track(createVhubBookingTrackData(VhubEvent.BookItWith, currentLoad.id));
    this.props.popupContext?.openingPopup({
      body: <VhubBookWithPopup loadID={currentLoad.id} vHubURL={this.props.currentLoad.vHub?.url} />,
      width: PopupSizes.MEDIUM,
    });
  };

  onPostClick = () => {
    this.props.onPostClick();
  };

  showPopUp = (type: string) => () => {
    if (type === 'pay') {
      showGetPaidFasterInfoPopup(this.props.popupContext);
    } else if (type === 'fuel') {
      showFuelAdvanceInfoPopup(this.props.popupContext);
    }
  };

  getStepContent = (index: number) => {
    const { fuelAdvanceQuote, getPaidFasterQuoteState, loadQuotes, isDocumentsLoading } = this.props;
    const { steps, activeStep } = this.state;
    const { dispatchName, postReference } = this.props.currentLoad;
    const fuelAdvanceRequestDate = getQuoteRequestDate(loadQuotes.loadQuotes, QuoteType.FuelAdvance);
    const payFasterRequestDate = getQuoteRequestDate(loadQuotes.loadQuotes, QuoteType.Factoring);
    switch (index) {
      case 0:
        return (
          <StepDescriptionBlock>
            <Row>
              <Left>
                <Description id="description" style={{ marginBottom: 25 }}>
                  {t(T.findLoads_findLoads_statusBar_phrase1)}
                </Description>
                <Description id="dispatch_name">
                  {t(T.findLoads_findLoads_contact)} <strong>{dispatchName ? dispatchName : '-'}</strong>
                </Description>
                <Description id="ref">
                  {t(T.findLoads_findLoads_ref)}
                  <strong>{postReference ? postReference : '-'}</strong>
                </Description>
              </Left>
              <Right>
                <ColumnWrapper style={{ marginTop: -12 }}>
                  <StatusBarContactOptions
                    load={this.props.currentLoad}
                    isOriginMessaging={this.props.isOriginMessaging}
                    isOriginBids={this.props.isOriginBids}
                    onInteractiveBidClick={this.props.onInteractiveBidClick}
                    onVendorBidClick={this.props.onVendorBidClick}
                    onCallClick={this.props.onCallClick}
                    onEmailClick={this.props.onEmailClick}
                    onVhubBook={this.onVhubBookClick}
                    onBookNowClick={this.props.onBookNowClick}
                  />
                </ColumnWrapper>
              </Right>
            </Row>
          </StepDescriptionBlock>
        );
      case 1:
        return (
          <StepDescriptionBlock>
            <Row style={{ marginBottom: 25 }}>
              <Left>
                {/* @TODO change price for booking*/}
                <Description id="question" style={{ marginRight: 25 }}>
                  {t(T.findLoads_findLoads_statusBar_phrase2)}
                </Description>
              </Left>
              <Right></Right>
            </Row>
            {this.props.shouldShowPost ? (
              <Row id="posttruck">
                <Left>
                  <Description id="description">{t(T.findLoads_findLoads_statusBar_postTruck)}</Description>
                </Left>
                <Right>
                  <ActionButton
                    id="post"
                    text={t(T.common_postTruck_postForm_post)}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={this.onPostClick}
                    isHeightAuto={true}
                  />
                </Right>
              </Row>
            ) : null}
            <ManageLine
              title={t(T.findLoads_findLoads_statusBar_uploadYourRateConfirmation)}
              isManageAvailable={activeStep >= 1}
              actionTitle={
                isDocumentsLoading
                  ? t(T.common_myDocuments_updateFolder_updating)
                  : getFileActionTitle(hasRateConfirmation(this.props.documents))
              }
              isFromMyLoads={this.props.isFromMyLoads}
            />
          </StepDescriptionBlock>
        );
      case 2:
        return (
          <StepDescriptionBlock>
            <Row>
              <Description id="question" style={{ marginBottom: 25 }}>
                {t(T.findLoads_findLoads_statusBar_phrase4)}
              </Description>
            </Row>
            <ManageLine
              title={t(T.findLoads_findLoads_statusBar_uploadYourBolLumpersEtc)}
              isManageAvailable={activeStep >= 2}
              actionTitle={
                isDocumentsLoading
                  ? t(T.common_myDocuments_updateFolder_updating)
                  : getFileActionTitle(hasBOLorLumpers(this.props.documents))
              }
              isFromMyLoads={this.props.isFromMyLoads}
            />
            <Row style={{ marginTop: 20 }}>
              <SubTitleContainer>
                <img id="dollar" style={{ marginRight: 8 }} src={icons.dollar} alt="dollar" />
                <Description id="fuel_advance" style={{ fontSize: '14px' }}>
                  {t(T.findLoads_findLoads_fuelAdvance)}
                </Description>
                <img
                  id="info"
                  style={{ marginLeft: 8, cursor: 'pointer' }}
                  src={icons.info_small}
                  alt="info_small"
                  onClick={this.showPopUp('fuel')}
                />
              </SubTitleContainer>
            </Row>
            <Row>
              <Left>
                <Description id="question_for_request">{t(T.findLoads_findLoads_statusBar_phrase5)}</Description>
              </Left>
              <Right>
                <ActionButton
                  id="fuel_advanced_send_request"
                  disabled={(steps[index] && !steps[index].isChecked) || fuelAdvanceQuote.isPostLoading}
                  text={'Send request'}
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={this.sendFuelAdvanceRequest}
                  isLoading={fuelAdvanceQuote.isPostLoading || loadQuotes.isLoading}
                />
              </Right>
            </Row>
            <Row>
              {fuelAdvanceRequestDate && (
                <WrapperLabelSent>
                  <LabelSent id="question" style={{ fontSize: '9px' }}>
                    {t(T.findLoads_findLoads_sent)} {moment(fuelAdvanceRequestDate).format('DD.MM.YYYY')}
                  </LabelSent>
                </WrapperLabelSent>
              )}
            </Row>
          </StepDescriptionBlock>
        );
      case 3:
        return (
          <StepDescriptionBlock>
            <Description id="question" style={{ marginBottom: 20 }}>
              {t(T.findLoads_findLoads_statusBar_phrase7)}
            </Description>
            <ManageLine
              title={t(T.findLoads_findLoads_statusBar_uploadYourProofOfDeliveryPod)}
              isManageAvailable={activeStep >= 3}
              actionTitle={
                isDocumentsLoading
                  ? t(T.common_myDocuments_updateFolder_updating)
                  : getFileActionTitle(hasProofOfDelivery(this.props.documents))
              }
              isFromMyLoads={this.props.isFromMyLoads}
            />
            <Row style={{ marginTop: 25 }}>
              <SubTitleContainer>
                <img id="dollar" style={{ marginRight: '8px' }} src={icons.dollar} alt="dollar" />
                <Description id="get_paid_faster" style={{ fontSize: '14px' }}>
                  {t(T.findLoads_loadDetails_getPaidFaster)}
                </Description>
                <img
                  id="info"
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  src={icons.info_small}
                  alt="info_small"
                  onClick={this.showPopUp('pay')}
                />
              </SubTitleContainer>
            </Row>
            <Row>
              <Left>
                <Description id="question_for_request">{t(T.findLoads_findLoads_statusBar_phrase8)}</Description>
              </Left>
              <Right>
                <ActionButton
                  id="get_paid_faster_send_request"
                  disabled={(steps[index] && !steps[index].isChecked) || getPaidFasterQuoteState.isPostLoading}
                  text={'Send request'}
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={this.sendPayFasterRequest}
                  isLoading={getPaidFasterQuoteState.isPostLoading || loadQuotes.isLoading}
                />
              </Right>
            </Row>
            {payFasterRequestDate && (
              <WrapperLabelSent>
                <LabelSent id="question" style={{ fontSize: '9px' }}>
                  {t(T.findLoads_findLoads_sent)} {moment(payFasterRequestDate).format('DD.MM.YYYY')}
                </LabelSent>
              </WrapperLabelSent>
            )}
          </StepDescriptionBlock>
        );
      default:
        return '';
    }
  };

  isUnavailableStep = () =>
    this.props.currentLoad?.status &&
    this.props.currentLoad.status !== LoadStatus.Online &&
    this.state.activeStep === 0;

  getCheckboxAvailability = (index: number) => {
    return !(this.state.steps[index - 1] && this.state.steps[index - 1].isChecked);
  };

  getStatusColor = (): Color => {
    const isUnavailable = this.isUnavailableStep();
    if (isUnavailable) {
      return Color.RED_APPLE;
    }
    if (this.state.activeStep === 0) {
      return Color.GREEN_MAIN;
    }
    return Color.GRAY_DARK;
  };

  getStatusTitle = () => {
    const loadProgress = this.props.currentLoad.metadata?.userdata?.progress
      ? loadProgressLabelMap[this.props.currentLoad.metadata?.userdata?.progress]
      : null;
    const loadProgressTitle = this.isUnavailableStep() ? t(T.common_load_loadUnavailable) : loadProgress;
    return (
      <Title>
        {t(T.common_loadDetails_status)}:
        <span style={{ paddingLeft: 4, color: this.getStatusColor() }}>{loadProgressTitle}</span>
      </Title>
    );
  };

  render() {
    const { activeStep, steps } = this.state;
    return (
      <ExpansionPanel
        id="status_bar"
        titleElement={this.getStatusTitle()}
        subtitleText={t(T.common_loadDetails_updateStatus)}
        icon={StatusBarIcon}
        isOpened={this.props.isOpenDetails}
        handleClick={this.props.onExpand}
        innerRef={this.props.statusBarRef}
      >
        {this.props.isOpenDetails ? (
          <BottomStepper
            id="bottom_stepper"
            activeStep={activeStep + 1}
            orientation="vertical"
            connector={<QontoConnector />}
          >
            {map(steps, (step, index) => {
              return (
                <QontoStep id={step.id} key={step.label} active={true}>
                  <StepLabel StepIconComponent={this.QontoStepIcon('vertical')}>
                    <Label>
                      {step.value === LoadProgress.LoadAvailable ? (
                        <span id="label">{t(T.common_getStarted)}</span>
                      ) : (
                        <CheckBoxRow>
                          <Left>
                            <span id="label">{step.label}</span>
                          </Left>
                          <Right>
                            {step.isLoading ? (
                              <CircularProgress size={24} color={'inherit'} />
                            ) : (
                              <StepCheckbox
                                id="checkbox"
                                color="primary"
                                onChange={this.prepareStep(index)}
                                disabled={this.getCheckboxAvailability(index)}
                                checked={step.isChecked}
                                value={step.label}
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                              />
                            )}
                          </Right>
                        </CheckBoxRow>
                      )}
                    </Label>
                  </StepLabel>
                  <QontoConnectorVertical style={{ ...(activeStep === index && { borderColor: '#98e1a8' }) }}>
                    {this.getStepContent(index)}
                  </QontoConnectorVertical>
                </QontoStep>
              );
            })}
          </BottomStepper>
        ) : (
          true
        )}
      </ExpansionPanel>
    );
  }
}

const getUserDataForQuotesRequest = (user: UserState) => {
  if (!user.profile?.payload) {
    return undefined;
  }
  const { firstName, lastName, email, companyCity, phone } = user.profile.payload;
  return {
    firstName: firstName,
    lastName: lastName,
    email: email,
    companyCity: companyCity,
    phone: { number: phone },
  };
};

const getQuoteRequestDate = (loadQuotes: QuoteListResponse, quoteType: QuoteType) => {
  const requestQuote = find(loadQuotes.quotes, (quote) => quote.type === quoteType);
  return requestQuote?.date;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      setProgress: (loadID: string, progress: LoadProgress) => setProgress(loadID, progress),
      fetchGetPaidFasterQuote: fetchGetPaidFasterQuote,
      postFuelAdvanceQuote: postFuelAdvanceQuote,
      postGetPaidFasterQuote: postGetPaidFasterQuote,
      fetchMostRecentQuoteForLoad: fetchMostRecentQuoteForLoad,
      showSnackbar: showSnackbar,
      saveLoad: saveLoad,
      fetchDocuments: fetchDocuments,
    },
    dispatch
  );

const mapStateToProps = (state: StoreState, props: ComponentProps): ReduxProps => {
  return {
    user: state.user,
    isMenuOpen: state.applicationSettings.menuOpen,
    fuelAdvanceQuote: state.fuelAdvanceQuote,
    getPaidFasterQuoteState: state.getPaidFasterQuoteState,
    loadQuotes: state.loadQuotes,
    documents: state.documents.documentLists.loadDetailsStandalone.entries,
    isDocumentsLoading: state.documents.documentLists.loadDetailsStandalone.isLoading,
    loadFolderIdentifier: state.documents.loadDetailsDocuments.loadFolderIdentifier,
    loadInfo: state.loadInfo.loadInfoMap.get(props.currentLoad.id),
  };
};

export const StatusBar = connect(mapStateToProps, mapDispatchToProps)(withPopups(StatusBarComponent));

import { useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';

/** debounced window resize event.
 */
export const useOnWindowResize = (callback: () => void, immediate: boolean = true) => {
  useEffect(() => {
    const onResizeEvent = debounce(callback, 50);

    window.addEventListener('resize', onResizeEvent);

    if (immediate) {
      onResizeEvent();
    }

    return () => {
      window.removeEventListener('resize', onResizeEvent);
    };
  }, [callback]);
};

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const callback = useCallback(() => setWindowWidth(window.innerWidth), []);
  useOnWindowResize(callback, false);

  return windowWidth;
};

export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const callback = useCallback(() => setWindowHeight(window.innerHeight), []);
  useOnWindowResize(callback, false);

  return windowHeight;
};

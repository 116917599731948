export enum PostedLoadProgress {
  Unassigned = 'Unassigned',
  Assigned = 'Assigned',
  Delivered = 'Delivered',
}

export interface PosterMetadata {
  progress?: PostedLoadProgress;
  assignedTo?: {
    dotNo: number;
  };
}

export interface SetPostedProgressRequest {
  loadID: string;
  progress: PostedLoadProgress;
  dotNo: number | undefined;
}

import { Deadhead } from './';

export interface LoadUserdata {
  isGone: boolean;
  isViewed: boolean;
  isLowPayRate: boolean;
  isHidden: boolean;
  isCalled: boolean;
  isEmailed: boolean;
  isMessaged: boolean;
  isContacted: boolean;
  isWrongInfo: boolean;
  isNotInterested: boolean;
  isSaved: boolean;
  originDeadhead?: Deadhead;
  destinationDeadhead?: Deadhead;
  note?: string;
  privateLoadNote?: string;
  progress?: LoadProgress;
}

export interface TripBuilderLoadUserdata {
  isContacted: boolean;
  isSaved: boolean;
  note?: string;
  progress?: LoadProgress;
}

export enum LoadProgress {
  LoadAvailable = 'LoadAvailable',
  Booked = 'Booked',
  ScheduledForPickup = 'ScheduledForPickup',
  PickupComplete = 'PickupComplete',
  LoadEnRoute = 'LoadEnRoute',
  Delivered = 'Delivered',
}

export enum SortExpression {
  MostRecentlyViewed = 'MostRecentlyViewed',
  LoadProgress = 'LoadProgress',
}

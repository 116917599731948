import { values } from 'lodash';

import { CostPerDaySettings } from '@common/model/CostPerDaySettings';
import { ProfitCalculatorSubMenuSetting } from '@common/model/ProfitCalculatorSettings';
import { ResultResponseActionWithFetchData } from '@common/redux/Base';

/**
 * API Settings Manager
 *
 * Often, for new or big features, we would like to have
 * a setting, controlled by API, to be able to adjust how the
 * app presents the feature or to disable the feature entirely.
 * This is for completed features, NOT in-development features.
 *
 * Follow the steps below to add a new setting
 *
 * For a new Flag:
 * 1. Add the new Flag to the Flag enum below
 * 2. (if applicable) Add the Flag to SystemSettingsMonitoredOnSignalR
 * 3. Add a default state for the Flag in initialSystemSettingsState in SettingsStateHelper.ts
 *
 * For a new Config:
 * 1. Add the new Config to the Config enum below
 * 2. (if applicable) Add the Config to SystemSettingsMonitoredOnSignalR
 * 3. Create a type for the Config's state (the data returned by API) and put it in SystemSettingsState
 * 4. Add a type for the Config to UpdateSystemSetting
 * 5. Add a default state for the Config in initialSystemSettingsState in SettingsStateHelper.ts
 */

/**
 * Simple on/off system setting
 */
export enum Flag {
  // NewFlag = 'key of system as sent to API',
  BiddingDisabled = 'bids.client.disabled',
  BiddingV2Disabled = 'bids-v2.1.client.disabled',
  BookNowDisabled = 'bookNow.client.disabled',
  BookingOptionsEnabled = 'demo.bookingoptions.client.enabled',
  BrokerAcceptsBids = 'bids.broker.acceptBids',
  CapacityFinderDisabled = 'capacityfinder.client.disabled',
  CargoChiefUserDisabled = 'cargoChief.client.user.disabled',
  CarrierOnboardingBannerEnabled = 'carrieronboarding.linkedredirect.carrier.enabled',
  DisplaySearchLoadCount = 'client.displaysearchloadcount',
  GreenscreensRateDisabled = 'greenscreens.client.disabled',
  HideParadeContact = 'parade.client.hideContactBeforeBid',
  IntegrationCotaEnabled = 'client.integration.cota',
  LoadRateCheckPreviewEnabled = 'load.ratecheckpreview.client.enabled',
  LoadRateCheckPreviewUserDisabled = 'load.ratecheckpreview.client.user.disabled',
  MessageSoundAlertsEnabled = 'client.message_alerts_enable',
  VendorBiddingDisabled = 'parade.bids.client.disabled', // named "parade.<...>" but affects all vendor bids
  TripBuilderEnabled = 'tripbuilder.client.enabled',
  LoadPlannerEnabled = 'loadplanner.client.enabled',
  LoadLocatorEnabled = 'loadlocator.123connect.client.enabled',
  BrokerOnboardingLinkUploadEnabled = 'carrieronboarding.linkupload.broker.enabled',
  LoadToTruckRatioEnabled = 'loadtotruckratio.client.enabled',
  MyCarrierPortalEnabled = 'mycarrierportal.client.enabled',
  MyCarrierPortalBrokerEnabled = 'brokermycarrierportal.client.enabled',
  ExpediteAllMarketRateEnabled = 'expediteallmarketrate.client.enabled',
  ExpediteAllPostLoadEnabled = 'expediteallpostload.client.enabled',
}

/**
 * Complex system setting
 */
export enum Config {
  // NewConfig = 'key of system as sent to API',
  MaintenancePopup = 'client.maintenancepopup',
  ProfitCalculatorSettings = 'profitcalculatorsettings',
  CostPerDaySettings = 'costperday',
}

export type SystemSetting = Config | Flag;

const FlagList = values(Flag);
export const ConfigList = values(Config);
export const SystemSettingList = [...FlagList, ...ConfigList];

/**
 * API sends an update message over signalR for any setting that changes, but we don't
 * need to react to real-time updates for all settings. This list is for settings that
 * require immediate action by the app when an update for them is received over signalR.
 */
export const SystemSettingsMonitoredOnSignalR: SystemSetting[] = [
  Config.MaintenancePopup,
  Flag.BiddingDisabled,
  Flag.BiddingV2Disabled,
  Flag.VendorBiddingDisabled,
  Flag.HideParadeContact,
  Flag.LoadRateCheckPreviewEnabled,
  Flag.LoadRateCheckPreviewUserDisabled,
  Flag.CargoChiefUserDisabled,
  Flag.GreenscreensRateDisabled,
  Flag.BookNowDisabled,
  Flag.TripBuilderEnabled,
  Flag.LoadPlannerEnabled,
  Flag.LoadLocatorEnabled,
  Flag.LoadToTruckRatioEnabled,
  Flag.MyCarrierPortalEnabled,
  Flag.MyCarrierPortalBrokerEnabled,
  Flag.ExpediteAllMarketRateEnabled,
  Flag.ExpediteAllPostLoadEnabled,
];

export interface SystemSettingResponse {
  value: unknown;
}

export type SystemSettingResultResponseAction = ResultResponseActionWithFetchData<
  SystemSettingResponse,
  SystemSetting
> & {
  fetchData: SystemSetting;
};

export interface MaintenancePopupSetting {
  idversion: number;
  enabled: boolean;
  title: string;
  description: string;
}

interface SystemSettingState<T> {
  value: T;
  isLoading?: boolean;
  isUpdating?: boolean;
  wasUpdated?: boolean;
}

type ToggleableSystemSettingState = SystemSettingState<boolean>;

type MaintenancePopupState = SystemSettingState<MaintenancePopupSetting | undefined>;
type ProfitCalculatorSettingsState = SystemSettingState<ProfitCalculatorSubMenuSetting>;
type CostPerDaySettingsState = SystemSettingState<CostPerDaySettings>;
// type NewConfigState = SystemSettingState<NewConfigStateModel>;

export type SystemSettingsState = Record<Flag, ToggleableSystemSettingState> & {
  // [Config.NewConfig]: NewConfigState;
  [Config.MaintenancePopup]: MaintenancePopupState;
  [Config.ProfitCalculatorSettings]: ProfitCalculatorSettingsState;
  [Config.CostPerDaySettings]: CostPerDaySettingsState;
};

type GetSystemSettingStateValueType<S extends SystemSettingState<any>> = S['value'];

interface UpdateSystemSettingPayload<Key extends SystemSetting> {
  key: Key;
  value: GetSystemSettingStateValueType<SystemSettingsState[Key]>;
}

export type UpdateSystemSetting =
  | UpdateSystemSettingPayload<Flag>
  // | UpdateSystemSettingPayload<Config.NewConfig>
  | UpdateSystemSettingPayload<Config.MaintenancePopup>
  | UpdateSystemSettingPayload<Config.ProfitCalculatorSettings>
  | UpdateSystemSettingPayload<Config.CostPerDaySettings>;

export interface SettingsChangeFeedResponse {
  changeFeedToken: string | undefined;
  items: { name: string; value: unknown }[];
}

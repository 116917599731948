import { parseInt as toInt } from 'lodash';
import Cookies from 'universal-cookie';

import { AppConfig } from '@util/AppConfig';
import { GenId } from '@util/GenId';

const cookie = new Cookies();

export const hasLoginCookies = () => username() != null;
const EXPIRY_COOKIE = `${AppConfig.LBConfig.CookiePrefix}123lb.a_exp_m`;
export const isAccessTokenExpired = () => {
  const expDate = toInt(getCookie(EXPIRY_COOKIE));
  return !(typeof expDate === 'number' && expDate > Date.now() + 60000);
};

export const setAccessTokenExpiry = (expiryTime: number) => {
  setCookie(`${AppConfig.LBConfig.CookiePrefix}123lb.a_exp_m`, Date.now() + expiryTime * 1000, '/', 86400 * 365 * 5);
};

export const username = () => {
  const cookieUsername = getCookie('logged_user_name');
  if (cookieUsername) {
    return cookieUsername.replace(/\+/g, ' ');
  }
  return null;
};

export const deviceID = () => {
  const key = '123lb.devid';
  let devid = getCookie(key);
  if (!devid) {
    devid = GenId.generateDeviceId();

    // set the cookie on the highest root domain.
    let hostname = window.location.hostname;
    const matches = /^.+(\.[^.]+\.[^.]+)$/.exec(hostname);
    if (matches) {
      hostname = matches[1];
    }
    setCookie(key, devid, '/', 86400 * 365 * 100, hostname);
  }
  return devid;
};

export const setSharedLoadIdForMap = (loadId: string) => {
  setCookie(AppConfig.LBConfig.CookiePrefix + '123lb.sharedloadid', loadId, '/', 86400 * 365 * 5);
};

const getCookie = (key: string) => {
  return cookie.get(key);
};

const setCookie = (key: string, value: any, path: string, maxAge: number, domain?: string) => {
  cookie.set(key, value, {
    path: path,
    maxAge: maxAge,
    domain: domain,
    sameSite: 'none',
    httpOnly: false,
    secure: true,
  });
};

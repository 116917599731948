import React from 'react';

import { round } from 'lodash';
import styled from 'styled-components';

import { displayCurrency, NO_INFORMATION_SYMBOL_LONG } from '@common/formatter';
import { treatUndefinedAsZero } from '@common/helper';
import { formatDollarsPerMile } from '@common/redux/epic/rateCheck/RateCheckHelper';
import { TextChip } from '@component/chip';
import { Text } from '@component/text/Text';
import { Color } from '@style/Color';
import { FontLineHeight, FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

const TEST_ID = 'market_rates';

export interface MinMaxComponentProps {
  id?: string;
  min?: number;
  max?: number;
  average?: number;
  minPerMile?: number;
  maxPerMile?: number;
  averagePerMile?: number;
  style?: React.CSSProperties;
}

interface LowHighColumnComponentProps {
  id: string;
  title: string;
  value: number | undefined;
  valuePerMile: number | undefined;
  color: Color;
  style?: React.CSSProperties;
  chipColor: string;
}

interface TextProps {
  color: Color;
}

const MinMaxContainer = styled.div`
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
`;

const MinMaxValueColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Spacing.Mark}px;
`;

const TopText = styled(Text)`
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Bold};
  line-height: ${FontLineHeight.ContentDetail}px;
  color: ${(props: TextProps) => props.color};
  margin-top: ${Spacing.TwoSpaces}px;
`;

const BottomText = styled(Text)`
  font-size: ${FontSize.Body}px;
  font-weight: ${FontWeight.Bold};
  line-height: ${FontLineHeight.SmallBody}px;
  color: ${(props: TextProps) => props.color};
`;

export const MinMaxBox: React.FC<MinMaxComponentProps> = (props) => (
  <MinMaxContainer id={props.id} style={props.style}>
    <MinMaxValue
      id={`${TEST_ID}_low_value`}
      title={t(T.rateCheck_revenueBox_Low)}
      value={props.min}
      valuePerMile={props.minPerMile}
      color={Color.LIGHT_BLACK}
      chipColor={Color.BLUE_WILD_YONDER}
    />
    <MinMaxValue
      id={`${TEST_ID}_average_value`}
      title={t(T.rateCheck_revenueBox_Average)}
      value={props.average}
      valuePerMile={props.averagePerMile}
      color={Color.LIGHT_BLACK}
      style={{ padding: `${Spacing.Mark}px 50px 0px` }}
      chipColor={Color.GREEN_JUNE_BUD}
    />
    <MinMaxValue
      id={`${TEST_ID}_high_value`}
      title={t(T.rateCheck_revenueBox_High)}
      value={props.max}
      valuePerMile={props.maxPerMile}
      color={Color.LIGHT_BLACK}
      chipColor={Color.PASTEL_VIOLET}
    />
  </MinMaxContainer>
);

const MinMaxValue: React.FC<LowHighColumnComponentProps> = (props) => (
  <MinMaxValueColumn style={props.style}>
    <TextChip id={props.title} text={props.title} style={{ background: props.chipColor }} />
    {props.value !== undefined ? (
      <TopText id={`${props.id}_${props.title}_flat_rate_number`} color={props.color}>
        {props.value ? displayCurrency(round(treatUndefinedAsZero(props.value)), false) : NO_INFORMATION_SYMBOL_LONG}
      </TopText>
    ) : null}
    {props.valuePerMile !== undefined ? (
      <BottomText
        id={`${props.id}_${props.title}_per_mile_number`}
        color={props.color}
        style={{ marginTop: props.value === undefined ? Spacing.TwoSpaces : 0 }}
      >
        {props.valuePerMile ? formatDollarsPerMile(props.valuePerMile) : NO_INFORMATION_SYMBOL_LONG}
      </BottomText>
    ) : null}
  </MinMaxValueColumn>
);

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { fetchCompaniesProfileAvatar } from '@common/redux/epic/AvatarEpic';
import { default as AvatarIcon } from '@component/menu/icon/icon-add-avatar.svg';
import { Color } from '@style/Color';
import { useSelector } from '@util/hooks';

const ImageViewer = styled.img`
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 20px;
  background-color: white;
  margin-right: 6px;
`;

const BidAvatarImageViewer = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 20px;
  border: solid 1px #2e2e2e;
  padding: 4px 4px 4px 4px;
  align-self: baseline;
`;

const InitailsBubble = styled.div`
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 20px;
  background-color: ${Color.GRAY_DARK};
  color: white;
  margin-right: 6px;
  border: solid transparent;
  align-items: center;
  font-size: 18px;
`;

interface Props {
  contactId?: string;
  initials?: string;
  shouldUsePlaceholderIcon?: boolean;
}

export const CompanyProfileLogo: React.FC<Props> = (props) => {
  const companyProfileAvatar = useSelector((state) =>
    state.avatar.companiesProfileAvatarMap.get(props.contactId ?? '')
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companyProfileAvatar && props.contactId) {
      dispatch(fetchCompaniesProfileAvatar(props.contactId));
    }
  }, [companyProfileAvatar, props.contactId]);

  if (props.shouldUsePlaceholderIcon && !companyProfileAvatar?.companiesProfileAvatar) {
    return <BidAvatarImageViewer src={AvatarIcon} />;
  } else if (props.contactId) {
    return companyProfileAvatar?.companiesProfileAvatar ? (
      <ImageViewer src={companyProfileAvatar.companiesProfileAvatar} />
    ) : (
      <InitailsBubble>{props.initials}</InitailsBubble>
    );
  } else {
    return null;
  }
};

import { reduce } from 'lodash';
import { Action, AnyAction } from 'redux';

import { CaseReducers, createReducer as TKcreateReducer } from '@reduxjs/toolkit';

// @TODO: Ensure always returning a state from a subreducer doesn't cause any bugs
type SubReducer<S = any, A extends Action = AnyAction> = (state: S | undefined, action: A) => S;

export const createReducer = <S, CR extends CaseReducers<S, Record<string, Action>>, SR extends SubReducer<S, Action>>(
  initialState: S,
  actionsMap: CR,
  ...subReducers: SR[]
) => {
  const reducers = [TKcreateReducer(initialState, actionsMap), ...subReducers];
  return (state: S, action: Action): S => {
    for (const reducer of reducers) {
      const newState = reducer(state, action);
      if (newState !== state) {
        return newState;
      }
    }
    return state;
  };
};

export const createMergedReducer = <S>(
  initialState: S,
  actionMaps: Array<CaseReducers<S, Record<string, Action>>>,
  ...subReducers: Array<SubReducer<S, Action>>
) =>
  createReducer(
    initialState,
    reduce(
      actionMaps,
      (acc, el) => {
        return { ...acc, ...el };
      },
      {}
    ),
    ...subReducers
  );

import { useState, useEffect } from 'react';
import helper from 'csvtojson';

export const useCsv = (csvFile: string | undefined) => {
  const [csvData, setCsvData] = useState<object[]>();

  useEffect(() => {
    if (csvFile) {
      helper()
        .fromString(csvFile)
        .then((csvRows) => {
          setCsvData(csvRows);
        });
    }
  }, [csvFile]);
  return csvData;
};

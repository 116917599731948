import React, { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import { ViewType } from '@common/helper/DocumentSortingHelper';
import { CustomFolder, LoadProgress } from '@common/model';
import { DocumentsContext } from '@common/redux/epic/DocumentListEpic';
import { getFolderByLoad } from '@common/redux/epic/DocumentsEpic';
import { setProgress } from '@common/redux/epic/LoadInfoEpic';
import { Button } from '@component/button/Button';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupNoButton, PopupYesButton } from '@component/popup/PopupButtons';
import { PopupSizes, PopupTracking, usePopup } from '@component/popup/PopupTrackingContext';
import { myDocumentsEncoder } from '@page/myDocuments/MyDocumentsEncoder';
import { Color } from '@style/Color';
import { FontSize, FontWeight } from '@style/StyleConstants';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

const FullWidthButton = styled(Button)`
  && {
    width: 100%;
    border-top: 1px solid ${Color.GRAY_SMOKE};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${Color.WHITE};
  padding: 12px 16px;
`;

const DocumentsText = styled.span`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
`;

const ContentHorizontalContainer = styled.div`
  display: flex;
  margin-top: 14px;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_DARK};
  font-weight: ${FontWeight.Bold};
`;

const Subtitle = styled.span`
  font-size: ${FontSize.Body}px;
  color: ${Color.GRAY_STONE};
`;

const CountArrowContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 8px;
`;

const Count = styled.span`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.ORANGE_MAIN};
  font-weight: ${FontWeight.Bold};
  margin-right: 8px;
`;

const DialogSubtitle = styled.span`
  font-size: ${FontSize.Content}px;
  color: ${Color.GRAY_DARK};
  white-space: pre-wrap;
`;

export const ManageDocuments: React.FC = () => {
  const load = useSelector((state) => state.loadDetailsBackhauls.currentLoad ?? state.loadDetails.currentLoad);
  const folderIdentifier = useSelector((state) => state.documents.loadDetailsDocuments.loadFolderIdentifier);
  const openDocumentsWithFolder = useOpenDocumentWithFolder();
  const shouldOpenMyDocuments = useRef(false);
  const popupContext = usePopup();
  const dispatch = useDispatch();

  useEffect(() => {
    if (load?.id) {
      dispatch(getFolderByLoad(load.id));
    }
  }, [load?.id, load?.metadata?.userdata?.progress]);

  useEffect(() => {
    if (folderIdentifier?.loadId && shouldOpenMyDocuments.current) {
      openDocumentsWithFolder(folderIdentifier);
      shouldOpenMyDocuments.current = false;
    }
  }, [folderIdentifier?.loadId]);

  if (!load) {
    return null;
  }

  const bookLoad = () => dispatch(setProgress(load.id, LoadProgress.Booked));

  const openFolderOrBook = () => {
    if (!load.metadata?.userdata?.progress || load.metadata.userdata.progress === LoadProgress.LoadAvailable) {
      showBookDialog(popupContext, bookLoad);
      shouldOpenMyDocuments.current = true;
    } else {
      openDocumentsWithFolder(folderIdentifier);
    }
  };

  return (
    <FullWidthButton id="manage_document" onClick={openFolderOrBook}>
      <Container>
        <DocumentsText id="documents">{t(T.common_documents)}</DocumentsText>
        <ContentHorizontalContainer>
          <TitleContainer>
            <Title id="title">{t(T.common_loadDetails_manageDocumentsForThisLoad)}</Title>
            <Subtitle id="subtitle">{t(T.common_loadDetails_rateConfirmationBolEtc)}</Subtitle>
          </TitleContainer>
          <CountArrowContainer>
            <Count id="count">{folderIdentifier?.fileCount ?? 0}</Count>
            <img id="arrow_right" src={icons.arrowRight} />
          </CountArrowContainer>
        </ContentHorizontalContainer>
      </Container>
    </FullWidthButton>
  );
};

export const showBookDialog = (popupContext: PopupTracking, bookAction: () => void) => {
  const { openingPopup, closingPopup } = popupContext;

  const book = () => {
    bookAction();
    if (popupContext) {
      popupContext.closingPopup();
    }
  };

  openingPopup({
    body: (
      <BasePopup
        title={t(T.common_loadDetails_didYouBook_title)}
        buttons={[
          <PopupNoButton testID="no" key="no" action={closingPopup} />,
          <PopupYesButton testID="yes" key="yes" action={book} />,
        ]}
      >
        <DialogSubtitle>{t(T.common_loadDetails_didYouBook_info)}</DialogSubtitle>
      </BasePopup>
    ),
    width: PopupSizes.SMALL,
    isDismissible: true,
    hasCloseButton: true,
  });
};

export const useOpenDocumentWithFolder = (isFromLoadDetails = false, context = DocumentsContext.DEFAULT) => {
  const history = useHistory();
  const urlParams = useParams<{ viewType?: string }>();
  return (folder: CustomFolder | undefined) => {
    if (!folder && context === DocumentsContext.CONVERSATION_ATTACHMENTS) {
      history.push({
        pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, {
          ...urlParams,
          loadID: undefined,
          viewType: ViewType.Folders,
        }),
        search: myDocumentsEncoder.convertObjectRequestToUrlParams({
          redirectCommunicationPath: !urlParams.viewType ? history.location.pathname : undefined,
          context: context,
        }),
      });
    } else if (!folder || !folder.origCity || !folder.origState || !folder.destCity || !folder.destState) {
      return;
    }
    if (urlParams.viewType) {
      history.push({
        pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, { ...urlParams, loadID: undefined }),
        search: history.location.search,
      });
    } else if (folder) {
      history.push({
        pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, { viewType: ViewType.Folders, documents: 'documents' }),
        search: myDocumentsEncoder.convertObjectRequestToUrlParams({
          id: folder.id,
          folderName: folder.name,
          type: 'CustomFolder',
          loadID: folder.loadId,
          origin: `${folder.origCity}, ${folder.origState}`,
          destination: `${folder.destCity}, ${folder.destState}`,
          redirectLoadDetailsPath: !urlParams.viewType ? history.location.pathname : undefined,
          redirectCommunicationPath: !urlParams.viewType ? history.location.pathname : undefined,
          redirectLoadDetailsSearch: !urlParams.viewType ? history.location.search : undefined,
          isFromMyLoads: isFromLoadDetails,
          context: context,
        }),
      });
    }
  };
};

import React from 'react';

import {
  PopupActionButtons,
  PopupContentSection,
  PopupSubtitleSection,
  PopupTitleSection,
} from '@component/popup/PopupContentSections';

interface Props {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  buttons?: React.ReactNode[];
  withoutContentPadding?: boolean;
  onClose?: () => void;
}

/**
 * This component represents a base content of a popup with specific styles
 * and can be used throughout the application to display popups
 * @param title Title of the popup, that is rendered in header section
 * @param subTitle Subtitle will be rendered in content section and it represents Bold center-aligned message inside of popup
 * @param buttons This is an array of buttons that will be displayed at the bottom of a popup (it is used for small popups in common)
 * @param onClose This callback will be fired then the popup is closing
 * @param withouContentPadding
 */
export const BasePopup: React.FC<Props> = (props) => {
  const { title, buttons, subTitle, children, withoutContentPadding, onClose } = props;
  return (
    <>
      <PopupTitleSection title={title} onClose={onClose} />
      <PopupContentSection withoutContentPadding={withoutContentPadding}>
        {subTitle ? <PopupSubtitleSection subtitle={subTitle} /> : null}
        {children}
      </PopupContentSection>
      <PopupActionButtons buttons={buttons} />
    </>
  );
};

import React from 'react';

import { MobileStoreLink } from '@/component/mobileStoreLink';
import { PostsFilter } from '@common/model';
import { CenteringContainer, NotFoundPanel, PageIcon } from '@component/panels/notFoundSearches/NotFoundPanel';
import { t, T } from '@translate';

import truckPost_logo_realtime from './no_truck_post.svg';
import truckPost_logo_expired from './no_truck_post_expired.svg';
import truckPost_logo_scheduled from './no_truck_post_scheduled.svg';

const PAGE_ID_PREFIX = 'truckPost';

export const NoTruckPost: React.FC<{ postsFilter?: PostsFilter }> = (props) => {
  const { title, subtitle, image } = getEmptyScreenMessages(props.postsFilter);
  return (
    <>
      <CenteringContainer>
        <NotFoundPanel
          imageSection={<PageIcon id={`${PAGE_ID_PREFIX}_icon`} src={image} />}
          title={title}
          subtitle={subtitle}
        />
      </CenteringContainer>
      {props.postsFilter === PostsFilter.RealTimePosts ? (
        <CenteringContainer>
          <MobileStoreLink page_Id_Prefix={PAGE_ID_PREFIX} />
        </CenteringContainer>
      ) : null}
    </>
  );
};

const getEmptyScreenMessages = (postsFilter?: PostsFilter): { title: string; subtitle: string; image: string } => {
  if (postsFilter === PostsFilter.RealTimePosts) {
    return {
      title: t(T.common_postTruck_noPostedTrucksScreen_realTime_title),
      subtitle: t(T.common_postTruck_noPostedTrucksScreen_realTime_goToApp),
      image: truckPost_logo_realtime,
    };
  } else if (postsFilter === PostsFilter.ScheduledPosts) {
    return {
      title: t(T.common_postTruck_noPostedTrucksScreen_scheduled_title),
      subtitle: t(T.common_postTruck_noPostedTrucksScreen_scheduled_subtitle),
      image: truckPost_logo_scheduled,
    };
  } else if (postsFilter === PostsFilter.ExpiredPosts) {
    return {
      title: t(T.common_postTruck_noPostedTrucksScreen_expired_title),
      subtitle: t(T.common_postTruck_noPostedTrucksScreen_expired_subtitle),
      image: truckPost_logo_expired,
    };
  }
  return {
    title: '',
    subtitle: '',
    image: '',
  };
};

import React from 'react';

import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components';

import { isAccountSetupRequiredForMembershipStatus } from '@/router/RouteHelper';
import { BillingIssue } from '@common/model';
import { useIsWindowScrollMode, useShouldDisplayHeader } from '@component/main/mainHooks';
import { useTrackResizingHeight } from '@component/scrollView';
import { BANNER_ZINDEX } from '@style/StyleConstants';
import { useSelector } from '@util/hooks';

import { BillingIssueBanner } from './BillingIssueBanner';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ChildContainer = styled.div`
  position: relative;
  flex-grow: 2;
  padding-top: ${(props: { paddingSize: string }) => props.paddingSize};
`;

export const GlobalBanner: React.FC = ({ children }) => {
  const isWindowScrollMode = useIsWindowScrollMode();
  const [bannerHeight, setBannerHeight] = useTrackResizingHeight();
  const shouldDisplayHeader = useShouldDisplayHeader();
  const membershipStatus = useSelector((state) => state.userPlan.membershipData?.status);
  const isCancellingMembership = useSelector((state) => state.userPlan.membershipData?.isCancelling);

  const billingIssue = useSelector((state) => state.user.profile?.payload?.billingIssue);
  const shouldUserChangeMembershipPlan = membershipStatus
    ? isAccountSetupRequiredForMembershipStatus(membershipStatus)
    : true;

  const isDisplayingBanner =
    shouldDisplayHeader &&
    !shouldUserChangeMembershipPlan &&
    ((billingIssue && billingIssue !== BillingIssue.None) || isCancellingMembership);

  if (!isDisplayingBanner) {
    return <>{children}</>;
  }

  return (
    <Container>
      <div style={{ width: '100%', zIndex: BANNER_ZINDEX, position: isWindowScrollMode ? 'fixed' : 'relative' }}>
        {isWindowScrollMode && <ReactResizeDetector handleHeight={true} onResize={setBannerHeight} />}
        <BillingIssueBanner billingIssue={billingIssue} />
      </div>
      <ChildContainer paddingSize={isWindowScrollMode ? `${bannerHeight}px` : '0px'}>{children}</ChildContainer>
    </Container>
  );
};

import React from 'react';

import styled from 'styled-components';

import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import { singleDoubleColumnSelect } from '@component/panelSizeDetector/PanelSizeDetector';
import { Color } from '@style/Color';
import { ElementSize, FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { MediaQueries } from '@util/MediaQueries';

const lineOrUndefined = (isWithoutTopLine = false) => (isWithoutTopLine ? undefined : `1px solid ${Color.GRAY_SMOKE}`);

export const DataWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
`;

export const Container = styled(({ isWithoutTopLine, hasBottomLine, ...rest }) => <div {...rest} />)`
  padding: 15px;
  border-top: ${({ isWithoutTopLine }) => lineOrUndefined(isWithoutTopLine)};
  border-bottom: ${({ hasBottomLine }) => lineOrUndefined(!hasBottomLine)};
`;

export const CenteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px 10px 15px;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
`;

export const AlertRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 2px;
  height: ${ElementSize.ActionLoadRowHeight}px;
  justify-content: end;
  background-color: ${Color.WHITE};
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
  @media ${MediaQueries.xxs} {
    padding: 0 6px 0 2px;
  }
`;

export const CarrierDetailsContent = styled.div`
  height: inherit;
  display: flex;
  flex-grow: 1;
  & > :first-child {
    border-right: ${singleDoubleColumnSelect('0', `1px solid ${THEME.palette.background.smoke}`)};
  }
  background: ${THEME.palette.background.paper};
  flex-direction: ${singleDoubleColumnSelect('column', 'initial')};
`;

export const CarrierDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${singleDoubleColumnSelect('auto', '50%')};
`;

export const PanelHeightWrapper = styled.div`
  .simplebar-content-wrapper {
    height: 100% !important;
  }
  .simplebar-content,
  .containerforscrollablecontent {
    height: inherit;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 230px;
  text-transform: uppercase;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  overflow: visible;
`;
export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0px;
  justify-content: flex-start;
`;

export const RowDetails = withTheme()(styled(TableRow)`
  && {
    padding: 0px !important;
    border-bottom: 0px;
    > td {
      padding: 0px !important;
      border: 0px;
      vertical-align: top;
      width: 50%;
    }
    > td:first-of-type {
      padding-right: 6px !important;
    }
  }
`);

export const AuthorityTableHead = withTheme()(styled(TableHead)`
  && {
    height: 35px;
    vertical-align: top;
  }
`);

export const AuthorityRow = withTheme()(styled(TableRow)`
  && {
    height: 32px;
  }
`);

export const AuthorityCell = withTheme()(styled(TableCell)`
  padding: 0px !important;
  border-bottom: none !important;
  width: 25%;
  vertical-align: top;
  text-align: ${(props: { isCentered?: boolean }) => (props.isCentered ? 'center' : 'left')} !important;
  > * {
    line-height: 18px !important;
  }
`);

export const StyledCheckCircle = withTheme()(styled(CheckCircle)`
  && {
    color: ${(props: ThemeProps & { disabled?: number }) =>
      props.disabled ? props.theme.palette.disabled.dark : props.theme.palette.status.confirmation};
    height: 18px;
    width: 18px;
    margin-left: 16px;
  }
`);

export const CheckMarkContainer = styled.div`
  padding-top: 3px;
  padding-right: 16px;
`;

export const TruckAlertMessageBar = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 70px;
  background-color: ${Color.ORANGE_MARIGOLD};
  border-bottom: 2px solid ${Color.ORANGE_MAIN};
  padding: 10px ${Spacing.ScreenSide}px;
  box-sizing: border-box;
`;

export const TruckAlertMessageInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

export const TruckAlertMessageSentTime = styled.div`
  margin-top: 3px;
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.BLACK};
`;

export const TruckAlertMessageText = styled.div`
  margin-top: ${Spacing.InterElementVertical}px;
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.BLACK};
`;

export const IconNameContainer = styled(({ hasBottomPadding, ...rest }) => <div {...rest} />)`
  display: flex;
  align-items: center;
  padding-top: ${Spacing.ElementPaddingVertical}px;
  padding-bottom: ${({ hasBottomPadding }) => (hasBottomPadding ? Spacing.ElementPaddingVertical : 0)}px;
  min-height: 40px;
`;

export const IconContainer = styled.span`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${Color.GRAY_DARK};
  font-size: 18px;
  margin-right: ${Spacing.InterElementHorizontal}px;
`;

const AvatarViewer = styled.img`
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-right: ${Spacing.InterElementHorizontal}px;
`;
export const CompanyAvatarViewer = styled(AvatarViewer)`
  border-radius: 10px;
`;
export const ProfileAvatarViewer = styled(AvatarViewer)`
  border-radius: 20px;
  background-color: ${Color.GRAY_DARK};
`;

import React from 'react';

import { map } from 'lodash';

import { NO_INFORMATION_SYMBOL } from '@common/formatter';
import { capitalizeCityName, formatRideTimeToDuration, isWithManualPostedExtraStops } from '@common/helper';
import { Load, LoadExtraStop, MileageData, RouteDirections, RoutesLocation } from '@common/model';
import { default as GoogleMapIcon } from '@component/panels/basePanel/loadDetails/google map.svg';
import { MapLink } from '@component/panels/companyDetails/MapLink';
import {
  Divider,
  DividerSmall,
  PageText,
  PageTextBold,
  PageTextBoldRed,
  StyledCard,
  SummaryRouteInfo,
  SummaryRouteTitle,
  Title,
} from '@component/panels/mileageCalculator/MileageCalculatorStyleSheet';
import { TextStyle } from '@component/text/Text';
import { t, T } from '@translate';

export const stopInformationFormat = (stop: RoutesLocation, withStreetAddress: boolean) => {
  let address;
  const city = capitalizeCityName(stop.city || '');
  if (stop.zipCode) {
    address = `${stop.zipCode} ${city}, ${stop.state}`;
  } else {
    address = `${city}, ${stop.state}`;
  }
  if (stop.streetAddress && withStreetAddress) {
    return `${stop.streetAddress}, ${address}`;
  }
  return address;
};

export const StopsList: React.FC<{
  stops: RoutesLocation[];
  origin: RoutesLocation;
  destination: RoutesLocation;
  load?: Load;
}> = (props) => (
  <>
    <PageText id="stop_information" textStyle={TextStyle.Subtitle2Regular}>
      <PageTextBold id="stop_label" textStyle={TextStyle.Subtitle2Regular}>
        {t(T.menu_mileageCalculatorPanel_Origin)}
      </PageTextBold>
      {stopInformationFormat(props.origin, true)}
    </PageText>
    <ManualStopsOrExtraStops
      stops={props.stops}
      extraStops={props.load?.extraStops}
      numberOfStops={props.load?.numberOfStops}
    />
    <PageText id="stop_information" textStyle={TextStyle.Subtitle2Regular}>
      <PageTextBold id="stop_label" textStyle={TextStyle.Subtitle2Regular}>
        {t(T.menu_mileageCalculatorPanel_Dest)}
      </PageTextBold>
      {stopInformationFormat(props.destination, true)}
    </PageText>
  </>
);

const ManualStopsOrExtraStops: React.FC<{
  stops: RoutesLocation[];
  extraStops: LoadExtraStop[] | undefined;
  numberOfStops: number | undefined;
}> = ({ extraStops, numberOfStops, stops }) => {
  const isWithManualExtraStops = isWithManualPostedExtraStops(extraStops, numberOfStops);
  if (isWithManualExtraStops) {
    return (
      <PageText id="manual_stops" textStyle={TextStyle.Subtitle2Regular}>
        <PageTextBold id="stops_label" textStyle={TextStyle.Subtitle2Regular}>
          {`${t(T.menu_mileageCalculatorPanel_Stops)}: `}
        </PageTextBold>
        {numberOfStops}
      </PageText>
    );
  }
  return (
    <>
      {map(stops, (stop, index) => (
        <PageText id="stop_information" key={index} textStyle={TextStyle.Subtitle2Regular}>
          <PageTextBold id="stop_label" key={index} textStyle={TextStyle.Subtitle2Regular}>
            {t(T.findLoads_loadDetails_stop, { value: index + 1 })}
          </PageTextBold>
          {stopInformationFormat(stop, true)}
        </PageText>
      ))}
    </>
  );
};

export const RouteSummary: React.FC<{
  mileageData: MileageData;
  routePayload: RouteDirections;
  load?: Load;
}> = (props) => (
  <SummaryRouteInfo>
    <StyledCard>
      <Title id="summary_road_info">{t(T.common_pcMiler_summaryRoadInfo_title)}</Title>
      <PageText id="start" textStyle={TextStyle.TextContentDetail} style={{ marginTop: '8px' }}>
        {t(T.menu_mileageCalculatorPanel_Start)}
        <PageTextBold id="start_location" textStyle={TextStyle.TextContentDetail}>
          {stopInformationFormat(props.routePayload?.origin, false)}
        </PageTextBold>
      </PageText>
      <PageText id="end" textStyle={TextStyle.TextContentDetail}>
        {t(T.menu_mileageCalculatorPanel_End)}
        <PageTextBold id="dest_location" textStyle={TextStyle.TextContentDetail}>
          {stopInformationFormat(props.routePayload?.destination, false)}
        </PageTextBold>
      </PageText>
      <PageText id="total_distance" style={{ marginTop: '21px' }} textStyle={TextStyle.TextContentDetail}>
        {t(T.menu_mileageCalculatorPanel_totalDistance)}
        <PageTextBold id="total_distance_value" textStyle={TextStyle.TextContentDetail}>
          {t(T.unit_mile_plural, { value: String(props.mileageData.rideMiles) })}
        </PageTextBold>
      </PageText>
      <PageText id="total_driving_time" textStyle={TextStyle.TextContentDetail}>
        {t(T.menu_mileageCalculatorPanel_totalDrivingTime)}
        <PageTextBold id="total_driving_value" textStyle={TextStyle.TextContentDetail}>
          {props.mileageData.rideTime ? formatRideTimeToDuration(props.mileageData.rideTime) : NO_INFORMATION_SYMBOL}
        </PageTextBold>
      </PageText>
      <PageText id="toll_cost" textStyle={TextStyle.TextContentDetail}>
        {`${t(T.common_pcMiler_summaryRoadInfo_totalTollCost)} `}
        <PageTextBoldRed id="toll_cost_amount" textStyle={TextStyle.TextContentDetail}>
          {props.mileageData.tollCosts}
        </PageTextBoldRed>
      </PageText>
    </StyledCard>
    <Divider />
    <StyledCard>
      {props.routePayload ? (
        <>
          <SummaryRouteTitle id="stops_section">{t(T.menu_mileageCalculatorPanel_Stops)}</SummaryRouteTitle>
          <StopsList
            stops={props.routePayload?.stops}
            origin={props.routePayload?.origin}
            destination={props.routePayload.destination}
            load={props.load}
          />
        </>
      ) : null}
    </StyledCard>
    <DividerSmall />
  </SummaryRouteInfo>
);

export const GoogleMapsLink: React.FC<{ route: RouteDirections; shouldShowStops: boolean }> = (props) => {
  const onMapClick = () => openGoogleMap(props.route, props.shouldShowStops);
  return (
    <MapLink
      onClick={onMapClick}
      title={t(T.findLoads_loadDetails_googleMap)}
      subtitle={t(T.menu_mileageCalculatorPanel_interactiveMap)}
      icon={GoogleMapIcon}
    />
  );
};

const openGoogleMap = (route: RouteDirections, shouldShowStops: boolean) => {
  const start = [route.origin.city, route.origin.state];
  const destination = [route.destination.city, route.destination.state];
  const stopsString = shouldShowStops ? map(route.stops, (stop) => `/${stop.city},+${stop.state}`) : '';
  if (start && destination) {
    const url = `https://www.google.com/maps/dir/${start[0]},+${start[1]}${stopsString}/${destination[0]},+${destination[1]}`;
    window.open(url, '_blank');
  }
};

import React from 'react';

import styled from 'styled-components';

import { default as Logo } from '@/component/popup/logo-mycarrierportal.png';
import { MainButton } from '@component/buttons';
import { default as Link } from '@component/panels/settings/company/dotVerification/icon-external-link.svg';
import { DescriptionText } from '@component/panels/settings/MPGPanel';
import { BasePopup } from '@component/popup/BasePopup';
import { LinkBrokerAccountPopup } from '@component/popup/myCarrierPortal/LinkBrokerAccountPopup';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { FontLineHeight, FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { MY_CARRIER_PORTAL, MY_CARRIER_PORTAL_INTG_TOOLS } from '@util/Link';

export const MCPPopupBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;

  > .container-padding {
    padding: 0px 16px;
  }
`;

const SignUpLink = styled.a`
  color: ${Color.ORANGE_MAIN};
  font-size: ${FontSize.Content}px;
  line-height: ${FontLineHeight.Content}px;
  cursor: pointer;
`;

const Note = styled.p`
  color: ${Color.GRAY_STONE};
  font-size: ${FontSize.Body}px;
  line-height: ${FontLineHeight.Body}px;
`;

interface Props {
  isOnboarded: boolean;
  contactGuid?: string;
}

export const SetupIntegrationInfoPopup = ({ isOnboarded, contactGuid }: Props) => {
  const { closingPopup, openingPopup } = usePopup();

  const openLinkAccountPopup = () => {
    closingPopup();
    openingPopup({
      body: <LinkBrokerAccountPopup isOnboarded={isOnboarded} contactGuid={contactGuid} />,
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  };

  return (
    <BasePopup title={t(T.myCarrierPortal_integration_title)} onClose={closingPopup} withoutContentPadding={true}>
      <MCPPopupBody>
        <div className="container-padding">
          <img src={Logo} style={{ width: 260 }} />
          <p style={{ marginBottom: 0, color: Color.GRAY_DARK }}>{`${t(T.myCarrierPortal_integration_to)}${
            !isOnboarded ? t(T.myCarrierPortal_integration_onboardedCarriers) : ''
          }${t(T.myCarrierPortal_integration_description)}`}</p>
          <ol>
            <li>
              <DescriptionText id="signup" style={{ color: Color.GRAY_DARK }}>
                {t(T.myCarrierPortal_integration_pointList1)}
                <SignUpLink onClick={() => window.open(MY_CARRIER_PORTAL, '_blank')}>
                  {t(T.myCarrierPortal_integration_signupLink)}
                </SignUpLink>
                <img src={Link} />
              </DescriptionText>
            </li>
            <p></p>
            <li>
              <DescriptionText id="linkCredentials" style={{ color: Color.GRAY_DARK }}>
                {t(T.myCarrierPortal_integration_pointList2)}
                <SignUpLink onClick={() => window.open(MY_CARRIER_PORTAL_INTG_TOOLS, '_blank')}>
                  {t(T.myCarrierPortal_integration_here)}
                </SignUpLink>
                <img src={Link} />
              </DescriptionText>
            </li>
            <p></p>
            <li style={{ color: Color.GRAY_DARK }}>{t(T.myCarrierPortal_integration_pointList3)}</li>
          </ol>
          <MainButton
            id="result"
            title={t(T.myCarrierPortal_integration_proceed)}
            onClick={openLinkAccountPopup}
            isLoading={false}
            style={{ width: '100%' }}
          />
          <Note>{t(T.myCarrierPortal_integration_termsConditions)}</Note>
        </div>
      </MCPPopupBody>
    </BasePopup>
  );
};

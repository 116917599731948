import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { LoadDetailsTabs } from '@/model';
import { StoreState } from '@/reduxStore/Store';
import { CreditRating as CreditRatingType } from '@common/model';
import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import { defaultLoadInfo, fetchCreditRatings, LoadInfo } from '@common/redux/epic/LoadInfoEpic';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { CreditActionButtons, CreditRatingData } from '@component/panels/companyDetails/CreditRating';
import {
  ExpansionPanelDataWrapper,
  LoadDetailsContainer,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { t, T } from '@translate';

import { default as CreditRatingIcon } from './credit rating.svg';

enum AccordionInfoStatus {
  LOADING,
  AVAILABLE,
  UNAVAILABLE,
  NOT_PERMITTED,
}

interface ComponentProps {
  loadID: string;
  handleChange(tab: LoadDetailsTabs, status: boolean): void;
  isOpened: boolean;
  posterId?: string;
}

interface ReduxProps {
  loadInfo: Map<string, LoadInfo>;
  status: AccordionInfoStatus;
}

interface DispatchProps {
  fetchCreditRatings(loadID: string): void;
}

type Props = ReduxProps & DispatchProps & ComponentProps;

interface ComponentState {
  creditRatings: CreditRatingType[] | undefined;
}

class Credit extends React.Component<Props, ComponentState> {
  state: ComponentState = {
    creditRatings: [],
  };

  static getDerivedStateFromProps(props: Props) {
    const info = props.loadInfo.get(props.loadID);
    if (info) {
      return {
        creditRatings: info.creditRatings,
      };
    }
    return null;
  }

  componentDidMount(): void {
    const { loadID } = this.props;
    this.props.fetchCreditRatings(loadID);
  }

  renderSubtitle = () => {
    const { creditRatings } = this.state;
    const value1 = get(creditRatings, '[0].ratingInfo[0].value', t(T.findLoads_loadDetails_noMatches));
    const value2 = get(creditRatings, '[1].ratingInfo[0].value', t(T.findLoads_loadDetails_noMatches));
    return `${value1} / ${value2}`;
  };

  redirectTo = (url: string) => () => {
    window.open(url, '_blank');
  };

  handleTabClick = () => {
    this.props.handleChange(LoadDetailsTabs.CreditRating, !this.props.isOpened);
  };

  render() {
    const { creditRatings } = this.state;
    return (
      <ExpansionPanel
        id="credit_rating"
        titleText={t(T.shareLoad_creditRating_Title)}
        subtitleText={t(T.common_load_Credit_Score, { value: this.renderSubtitle() })}
        icon={CreditRatingIcon}
        isBlocked={!creditRatings || creditRatings.length < 1}
        handleClick={this.handleTabClick}
        isOpened={this.props.isOpened}
        status={this.props.status}
        unlockFeaturePopupType={UnlockFeaturePopupType.CreditRating}
      >
        <ExpansionPanelDataWrapper>
          {creditRatings
            ? creditRatings.map((creditRating: CreditRatingType, index: number) => (
                <LoadDetailsContainer key={creditRating.creditor} isWithoutTopLine={index === 0 ? true : false}>
                  {renderCreditCompany(creditRating)}
                </LoadDetailsContainer>
              ))
            : null}
          <LoadDetailsContainer>
            <CreditActionButtons sourceId={this.props.posterId} />
          </LoadDetailsContainer>
        </ExpansionPanelDataWrapper>
      </ExpansionPanel>
    );
  }
}

const renderCreditCompany = (creditRating: CreditRatingType) => {
  const creditScore = creditRating.ratingInfo[0].value ?? t(T.findLoads_loadDetails_noMatches);
  const daysToPay = creditRating.ratingInfo[1].value ?? t(T.findLoads_loadDetails_noMatches);
  const logo = creditRating.logo?.max;
  return (
    <CreditRatingData
      id="trans_credit"
      logo={logo}
      logoAlt="credit_company_logo"
      scoreValue={creditScore}
      daysToPay={daysToPay}
    />
  );
};

const mapStateToProps = (state: StoreState, props: ComponentProps): ReduxProps => {
  let status: AccordionInfoStatus = AccordionInfoStatus.AVAILABLE;
  const loadInfo = props.loadID ? state.loadInfo.loadInfoMap.get(props.loadID) : defaultLoadInfo;
  const isUserAllowed = state.user.accesses.isCreditRatingAvailable;
  if (!isUserAllowed) {
    status = AccordionInfoStatus.NOT_PERMITTED;
  } else {
    if (loadInfo && loadInfo.isLoadingCreditRating) {
      status = AccordionInfoStatus.LOADING;
    } else if (loadInfo && !loadInfo.creditRatings) {
      status = AccordionInfoStatus.UNAVAILABLE;
    }
  }

  return {
    loadInfo: state.loadInfo.loadInfoMap,
    status: status,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      fetchCreditRatings: fetchCreditRatings,
    },
    dispatch
  );

export const CreditRating = connect(mapStateToProps, mapDispatchToProps)(Credit);

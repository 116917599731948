export interface Equipment {
  equipmentType: EquipmentType;
  equipmentSpecifications: string; //This can be multiple `EquipmentSpecification` separated by `,`
}

export enum EquipmentType {
  All = 'All',
  Van = 'Van',
  Reefer = 'Reefer',
  PowerOnly = 'PowerOnly',
  Container = 'Container',
  HopperBottom = 'HopperBottom',
  Tanker = 'Tanker',
  DoubleDrop = 'DoubleDrop',
  Flatbed = 'Flatbed',
  Landoll = 'Landoll',
  LowBoy = 'LowBoy',
  Maxi = 'Maxi',
  RemovableGooseneck = 'RemovableGooseneck',
  StepDeck = 'StepDeck',
  Auto = 'Auto',
  AnimalCarrier = 'AnimalCarrier',
  BoatHauler = 'BoatHauler',
  HeavyHaulers = 'HeavyHaulers',
  MovingVan = 'MovingVan',
  MiniVan = 'MiniVan',
  CargoVan = 'CargoVan',
  BoxTruck = 'BoxTruck',
  Sprinter = 'Sprinter',
  DumpTruck = 'DumpTruck',
  PilotCars = 'PilotCars',
  None = 'None',
}

export enum EquipmentSpecification {
  None = 'None',
  AirRide = 'AirRide',
  BlanketWrap = 'BlanketWrap',
  BTrain = 'BTrain',
  Chains = 'Chains',
  Chassis = 'Chassis',
  Conestoga = 'Conestoga',
  Curtains = 'Curtains',
  Double = 'Double',
  ETrack = 'ETrack',
  Extendable = 'Extendable',
  HazMat = 'HazMat',
  HotShot = 'HotShot',
  Insulated = 'Insulated',
  Intermodal = 'Intermodal',
  LiftGate = 'LiftGate',
  LoadRamps = 'LoadRamps',
  LoadOut = 'LoadOut',
  Moving = 'Moving',
  OpenTop = 'OpenTop',
  Oversized = 'Oversized',
  PalletExchange = 'PalletExchange',
  PlateTrailer = 'PlateTrailer',
  SideKit = 'SideKit',
  Tarps = 'Tarps',
  TeamDriver = 'TeamDriver',
  TempControlled = 'TempControlled',
  Triple = 'Triple',
  Vented = 'Vented',
  WalkingFloor = 'WalkingFloor',
  All = 'All',
}

export enum EquipmentCode {
  All = 'All',
  Van = 'V',
  Reefer = 'R',
  PowerOnly = 'PO',
  Container = 'C',
  HopperBottom = 'HB',
  Tanker = 'T',
  DoubleDrop = 'DD',
  Flatbed = 'F',
  Landoll = 'LA',
  LowBoy = 'LB',
  Maxi = 'MX',
  RemovableGooseneck = 'RG',
  StepDeck = 'SD',
  Auto = 'Auto',
  AnimalCarrier = 'AC',
  BoatHauler = 'BH',
  HeavyHaulers = 'HH',
  MovingVan = 'MV',
  MiniVan = 'MiniVan',
  CargoVan = 'CV',
  BoxTruck = 'SB',
  Sprinter = 'Sprinter',
  DumpTruck = 'DT',
  PilotCars = 'PilotCars',
  None = 'None',
}

export enum EquipmentSpecificationCode {
  None = 'None',
  AirRide = 'A',
  BlanketWrap = 'B',
  BTrain = 'BT',
  Chains = 'CH',
  Chassis = 'CS',
  Conestoga = 'CO',
  Curtains = 'C',
  Double = '2',
  ETrack = 'ET',
  Extendable = 'E',
  HazMat = 'Z',
  HotShot = 'HS',
  Insulated = 'I',
  Intermodal = 'N',
  LiftGate = 'LG',
  LoadRamps = 'LR',
  LoadOut = 'LO',
  Moving = 'MV',
  OpenTop = 'OT',
  Oversized = 'O',
  PalletExchange = 'X',
  PlateTrailer = 'PL',
  SideKit = 'S',
  Tarps = 'T',
  TeamDriver = 'M',
  TempControlled = 'TC',
  Triple = '3',
  Vented = 'V',
  WalkingFloor = 'WF',
  All = 'All',
}

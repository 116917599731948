import { ApiResponse, ApisauceInstance, CancelToken, CLIENT_ERROR, create, SERVER_ERROR } from 'apisauce';
import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelToken as AxiosCancelToken,
  CancelTokenSource,
} from 'axios';
import { Buffer } from 'buffer';
import { clone, forEach, includes, isEqual, some, toLower } from 'lodash';
import QueryString from 'qs';
import { defer as defer$, from as from$, Observable, of as of$, Subject } from 'rxjs';
import { mergeMap as mergeMap$, multicast as multicast$, refCount as refCount$ } from 'rxjs/operators';

import { ApiOptions, ErrorUri, LBHeaders, PendingAction, Request$ } from '@common/api';
import { MimeType, typesSupportedByDownload } from '@common/helper/FileHelper';
import { FieldError } from '@common/model';
import { AppErrorCode } from '@common/model/AppErrorCode';

import { ApiError, ApiResponse123, ApiResponseSuccess } from './ApiResponse';

interface CancelTokenConfiguration {
  cfg: AxiosRequestConfig;
  timeout: any; //NodeJS.Timer doesn't behave well between web / react native, still timeout has the same interface on both.
}

export type PatchOp = 'remove' | 'replace';

export interface Patch<T = string | number | boolean | undefined> {
  op: PatchOp;
  path: string;
  value: T;
}

export interface OffsetLimitRequest {
  offset?: number;
  limit?: number;
}

export type HttpRequest = <U>(
  url: string,
  data: any,
  axiosConfig?: AxiosRequestConfig,
  extraConfig?: ApiOptions
) => Request$<U>;
export const DuplicatedRequestErrorCode = -7875;
const APP_TIMEOUT_ERROR = 'APP_TIMEOUT_ERROR_123';

type ApiCall<T> = (config: AxiosRequestConfig) => Promise<ApiResponse<T>>;

export interface NetworkRetryManager {
  execute$: <T>(
    apiCall: () => Observable<ApiResponse123<T>>,
    maxRetries: number,
    config?: AxiosRequestConfig
  ) => Observable<ApiResponse123<T>>;
}

export interface Api {
  get$: HttpRequest;
  post$: HttpRequest;
  put$: HttpRequest;
  delete$: HttpRequest;
  patch$: HttpRequest;
  mergePatch$: HttpRequest;
}

/** Helper to overwrite the API Version for a specific endpoint */
export const withApiVersion = (version: string): AxiosRequestConfig => ({
  headers: { [LBHeaders.ApiVersion]: version },
});

/** Helper to set JSON Merge Patch (RFC7396) content type */
export const withMergePatch = (config?: AxiosRequestConfig): AxiosRequestConfig => ({
  ...config,
  headers: { 'Content-Type': 'application/merge-patch+json' },
});

const getCorrelationId = (response: AxiosResponse<any>) =>
  (response.headers as any)[toLower(LBHeaders.CorrelationId)] ?? undefined;

/** perform JSON.parse in an interceptor only if content-type is json.
 * Also handle when cannot parse the JSON data
 */
const createResponseInterceptor =
  (logger?: ApiLogger) =>
  (originalResponse: AxiosResponse<any>): AxiosResponse<any> => {
    const response = clone(originalResponse);
    if (!response.headers || !response.headers['content-type']) {
      return response;
    }

    const isHeaderMimeType = (...mimeTypes: Readonly<MimeType[]>) =>
      some(mimeTypes, (mime) => response.headers?.['content-type'] && includes(response.headers['content-type'], mime));

    if (isHeaderMimeType(MimeType.json)) {
      try {
        response.data = JSON.parse(response.data);
      } catch (exception) {
        if (logger) {
          logger({
            mustReport: false,
            httpStatus: originalResponse.status,
            title: 'Error parsing json response',
            exception: exception,
            extra: {
              url: response.config.url ?? undefined,
              correlationId: getCorrelationId(response),
            },
          });
        }
        // unparsable json data, leave it as null and reject the response
        // NOTE: rejecting will probably do nothing special because of apisauce, but
        // http status outside of normal range will yield an "UNKNOWN_ERROR" in apisauce
        // hopefully the ApiError will be handled correctly
        response.data = new ApiError(
          response.status,
          AppErrorCode.FATAL_ERROR,
          'Oops! something went wrong, if the issue persist, please contact support',
          'Oops! something went wrong.',
          exception
        );
        response.status = 600;
        response.statusText = 'Unknown Error';
      }
    } else if (isHeaderMimeType(...typesSupportedByDownload) && !isHeaderMimeType(MimeType.csv)) {
      try {
        response.data = new Buffer(response.data, 'binary').toString('base64');
      } catch (exception) {
        if (logger) {
          logger({
            mustReport: true,
            httpStatus: originalResponse.status,
            title: 'Error on parsing image response',
            exception: exception,
            extra: {
              url: response.config.url ?? undefined,
              correlationId: getCorrelationId(response),
            },
          });
        }
      }
    }

    return response;
  };

export type ApiLoggerDiagnosticData = Record<string, string | number | boolean | undefined | null>;

export type ApiLogger = (data: {
  title?: string;
  mustReport: boolean;
  url?: string;
  httpStatus: number;
  code?: number;
  errorResponse?: ApiResponse123<any>;
  exception?: any;
  extra?: ApiLoggerDiagnosticData;
}) => void;

interface CachedRequest {
  url: string;
  firstTimestamp: number;
  lastTimestamp: number;
  data: any;
  count: number;
  reportThreshold: number;
}

export class ApiService implements Api {
  private readonly api: ApisauceInstance;
  private readonly retrier?: NetworkRetryManager;
  cachedRequests: CachedRequest[] = [];
  logger?: ApiLogger;
  constructor(
    baseUrl: string,
    headers: {},
    setupApi?: (api: ApisauceInstance) => void,
    retrier?: NetworkRetryManager,
    logger?: ApiLogger
  ) {
    this.api = create({
      baseURL: baseUrl,
      headers: headers,
      // override the default transformRequest to do nothing instead of
      // the default JSON.parse() always (!) and without error handling (!)
      transformResponse: [(data) => data],
    });
    this.logger = logger;
    this.retrier = retrier;
    const parseAxiosResponseInterceptor = createResponseInterceptor(logger);
    this.api.axiosInstance.interceptors.response.use(parseAxiosResponseInterceptor, (error: any): AxiosError => {
      if (Axios.isCancel(error) && error.message !== APP_TIMEOUT_ERROR) {
        return error;
      }
      if (!error) {
        return {
          config: {},
          name: '',
          message: '',
          isAxiosError: false,
          toJSON: () => ({}),
        };
      }
      if (error.response && !isEqual(error.response, {})) {
        error.response = parseAxiosResponseInterceptor(error.response);
      }
      return error;
    });

    if (setupApi) {
      setupApi(this.api);
    }
  }

  get$: HttpRequest = (url, params, axiosConfig) =>
    this.methodWrapper(this.api.get, url, params, {
      paramsSerializer: (params) =>
        QueryString.stringify(params, {
          arrayFormat: 'brackets',
          encode: false,
        }),
      ...axiosConfig,
    });
  post$: HttpRequest = (url, params, axiosConfig) => this.methodWrapper(this.api.post, url, params, axiosConfig);
  put$: HttpRequest = (url, params, axiosConfig) => this.methodWrapper(this.api.put, url, params, axiosConfig);
  delete$: HttpRequest = (url, params, axiosConfig) => this.methodWrapper(this.api.delete, url, params, axiosConfig);
  patch$: HttpRequest = (url, params, axiosConfig) => this.methodWrapper(this.api.patch, url, params, axiosConfig);
  mergePatch$: HttpRequest = (url, params, axiosConfig) =>
    this.methodWrapper(this.api.patch, url, params, withMergePatch(axiosConfig));

  methodWrapper = (
    methodFunction: <T, U = T>(
      url: string,
      params?: {},
      axiosConfig?: AxiosRequestConfig
    ) => Promise<ApiResponse<T, U>>,
    url: string,
    params: any,
    axiosConfig: AxiosRequestConfig | undefined
  ): Observable<ApiResponse123<any>> => {
    const requestCache = this.cachedRequests;
    const filteredRequestCache: CachedRequest[] = [];
    let isDuplicateRequest = false;
    const currentTimestamp = Date.now();
    forEach(requestCache, (cachedRequest) => {
      if (currentTimestamp <= cachedRequest.lastTimestamp + 10000) {
        if (cachedRequest.url === url && isEqual(params, cachedRequest.data)) {
          isDuplicateRequest = true;
          const timestampDifference = Date.now() - cachedRequest.lastTimestamp;
          cachedRequest.lastTimestamp = Date.now();
          cachedRequest.count += 1;
          if (cachedRequest.count >= cachedRequest.reportThreshold) {
            const { data, ...diagnosticData } = cachedRequest;
            this.logger?.({
              mustReport: true,
              title: 'Duplicate requests within 10s',
              httpStatus: -1,
              extra: {
                ...diagnosticData,
                lastTimestampDifference: timestampDifference,
              },
              url: url,
              code: DuplicatedRequestErrorCode,
            });
            cachedRequest.reportThreshold *= 2;
          }
        }
        filteredRequestCache.push(cachedRequest);
      }
    });
    if (!isDuplicateRequest) {
      const time = Date.now();
      filteredRequestCache.push({
        url: url,
        data: params,
        count: 1,
        reportThreshold: 10,
        firstTimestamp: time,
        lastTimestamp: time,
      });
    }
    this.cachedRequests = filteredRequestCache;
    return this.executeRequest$((config: AxiosRequestConfig) => methodFunction(url, params, config), url, axiosConfig);
  };

  executeRequest$ = <T>(
    apiCall: ApiCall<T>,
    url: string,
    axiosConfig?: AxiosRequestConfig
  ): Observable<ApiResponse123<T>> => {
    const cancelSource: CancelTokenSource = CancelToken.source();
    const cancelID = `${Date.now()}${url}`;
    const executeRequest$ = (): Observable<ApiResponse123<T>> => {
      const cfgWithCancelToken = setCancelToken(cancelSource.token, axiosConfig);
      const canceler = cancelSource.cancel;
      return callApi$(apiCall, url, cfgWithCancelToken.cfg, canceler, cfgWithCancelToken.timeout);
    };
    let request$: Request$<T>;
    if (this.retrier) {
      request$ = this.retrier.execute$(executeRequest$, 1, axiosConfig);
    } else {
      request$ = executeRequest$();
    }
    request$.cancelID = cancelID;
    request$.cancel = cancelSource.cancel;
    return request$;
  };
}

// https://github.com/axios/axios/issues/647#issuecomment-322209906
// timeout is sometimes unreliable
const setCancelToken = (token: AxiosCancelToken, axiosConfig?: AxiosRequestConfig): CancelTokenConfiguration => {
  let timeout = 30000;
  const newConfig: AxiosRequestConfig = {
    cancelToken: token,
    ...axiosConfig,
  };

  timeout = newConfig.timeout || timeout;
  return {
    cfg: newConfig,
    timeout: timeout,
  };
};

const callApi$ = <T>(
  apiCall: ApiCall<T>,
  url: string,
  config: AxiosRequestConfig,
  cancel?: Canceler,
  timeout?: number
) => {
  let timeoutID: any | undefined; //NodeJS.Timer doesn't behave well between web / react native, still timeout has the same interface on both.
  if (cancel && timeout) {
    timeoutID = setTimeout(() => {
      cancel(APP_TIMEOUT_ERROR);
    }, timeout + 1000);
    //We add 1second here to prevent this to be called instead of the axios timeout. Otherwise the error will be cancel instead of a timeout.
  }

  //Defer will convert a hot observable to cold (promisse to observable)
  const deferedApiCall$ = defer$(() => from$(apiCall(config)).pipe(mergeMap$(createResponseHandler$(url, timeoutID))));
  //To avoid creating a new instance per subscription we multicast it to a new subject.
  return deferedApiCall$.pipe(
    multicast$(() => new Subject()),
    //Refcount means don't wait for a explicit connect() call, start emmiting once the first subscription is created.
    refCount$()
  ) as Observable<ApiResponse123<T>>;
};

const createResponseHandler$ =
  (url: string, timeoutID?: any) =>
  <T>(response: ApiResponse<any>): Observable<ApiResponse123<T>> => {
    // timeout has type any for the same reason as above (in callApi$)
    if (response && timeoutID) {
      clearTimeout(timeoutID);
    }
    const convertedResponse: ApiResponse123<T> = convertApiResponse(response);
    convertedResponse.url = url;
    return of$(convertedResponse);
  };

const Apisauce2AppErrorCode = {
  TIMEOUT_ERROR: AppErrorCode.TIMEOUT_ERROR,
  CONNECTION_ERROR: AppErrorCode.CONNECTION_ERROR,
  NETWORK_ERROR: AppErrorCode.NETWORK_ERROR,
  UNKNOWN_ERROR: AppErrorCode.UNKNOWN_ERROR,
  CANCEL_ERROR: AppErrorCode.CANCEL_ERROR,
  CLIENT_ERROR: AppErrorCode.CLIENT_ERROR,
  SERVER_ERROR: AppErrorCode.SERVER_ERROR,
};

export const convertApiResponse = <T>(response: ApiResponse<{}>): ApiResponse123<T> => {
  const httpStatus = response.status ? response.status : -1;
  if (response.ok) {
    return new ApiResponseSuccess(httpStatus, response.data as T, response.headers, response.config, response.duration);
  }
  const debugError = debugErrorFrom(response.originalError);
  if (response.problem === CLIENT_ERROR || response.problem === SERVER_ERROR) {
    const data = response.data as {
      code: number;
      title: string;

      message: string;

      actions?: PendingAction[];
      fieldErrors: FieldError[];

      error_description?: string;
      error_uri?: ErrorUri;
    };

    if (!data) {
      return new ApiError(
        httpStatus,
        -1,
        '',
        'No data',
        debugError,
        response.headers,
        response.config,
        response.duration
      );
    }

    const error = new ApiError(
      httpStatus,
      data.code,
      data.error_description || data.message,
      data.title,
      debugError,
      response.headers,
      response.config,
      response.duration,
      data.error_uri,
      data.fieldErrors
    );
    if (data.actions && data.actions.length > 0) {
      error.actions = data.actions;
    }

    return error;
  }

  // No response from Server, map the apisauce error
  return new ApiError(
    httpStatus,
    Apisauce2AppErrorCode[response.problem] || AppErrorCode.UNKNOWN_ERROR,
    response.problem,
    'Oops something happened',
    debugError,
    response.headers,
    response.config,
    response.duration
  );
};

const debugErrorFrom = (error: AxiosError) => {
  if (!error) {
    return {};
  }
  return {
    requestCorrelation: error.request?.headers ? error.request.headers : 'N/A',
    responseCorrelation: error.response?.headers ? error.response.headers : 'N/A',
    name: error.name,
    message: error.message,
    errorCode: error.code,
    error: error,
    url: error.config ? error.config.url : '',
    axiosHeaders: error.config ? error.config.headers : '',
    stack: error.stack,
  };
};

import { BaseClient } from '@common/client/BaseClient';
import { PostedLoadsUrl } from '@common/model';
import { FeedbackRequest } from '@common/model/FeedbackRequest';

export class FeedbackClient extends BaseClient {
  requestFTPIntegration$ = () => this.api.post$('/feedback/contactus/loadposting/ftp', {});
  requestURLScrape$ = (contactUsUrl: PostedLoadsUrl) =>
    this.api.post$('/feedback/contactus/loadposting/url', contactUsUrl);
  requestBrokerFeature$ = (isBroker: boolean) => this.api.post$('/feedback/contactus/requestBrokerFeatures', isBroker);
  requestCSVUpload$ = (data: FormData) => this.api.post$('/loads/file', data);
  requestFeedback$ = (feedbackRequest: FeedbackRequest) => this.api.post$('/feedback', feedbackRequest);
}

import styled from 'styled-components';

import Checkbox from '@material-ui/core/Checkbox';

import { singleDoubleColumnSelect } from '@component/panelSizeDetector/PanelSizeDetector';
import { Text } from '@component/text/Text';
import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';

export const ConfirmationCheckbox = styled(Checkbox)`
  && {
    align-self: baseline;
    padding-top: 11px;
    margin-left: -14px;
  }
`;

export const TrialExtensionFrame = styled.div`
  display: flex;
  flex-direction: ${singleDoubleColumnSelect('column', 'row')};
  background-color: ${Color.BLUE_AZURE};
  border: 1px solid ${Color.GRAY_SMOKE};
  text-align: center;
  align-items: center;
  padding: ${Spacing.InterSection}px;
  justify-content: space-evenly;
`;

export const DialogTitle = styled.div`
  margin: 16px 0 16px 16px;
`;

export const TitleText = styled.span`
  color: ${Color.GRAY_DARK};
  font-size: ${FontSize.Header}px;
  font-weight: bold;
  margin-bottom: ${Spacing.InterSection}px;
`;

export const ContentText = styled(Text)`
  font-size: ${FontSize.Content}px;
  line-height: 30px;
  font-weight: regular;
`;

export const ExtensionContentText = styled(Text)`
  font-size: ${FontSize.ModalTitle}px;
  line-height: 30px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${FontSize.Content}px;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: ${Spacing.InterSection}px;
`;

export const ExtensionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${singleDoubleColumnSelect('', '30px')};
  padding-right: ${singleDoubleColumnSelect('', '8px')};
  justify-content: space-between;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  padding-bottom: 8px;
`;

export const RadioTextContainer = withTheme()(styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    margin-left: -90px;
  }
  @media (max-width: 320px) {
    margin-left: -50px;
  }
`);

export const SingleButtonContainer = styled.div`
  display: flex;
  flex-direction: ${singleDoubleColumnSelect('column', 'row')};
  justify-content: left;
  max-width: 180px;
  padding-top: 8px;
  padding-bottom: ${Spacing.DoubleInterSection}px;

  flex-direction: column;
  height: ${singleDoubleColumnSelect('100px', '')};
  width: ${singleDoubleColumnSelect('auto', '')};
`;

export const TwoButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${singleDoubleColumnSelect('column', 'row')};
  max-width: 400px;
  height: ${singleDoubleColumnSelect('100px', '')};
  width: ${singleDoubleColumnSelect('100%', '')};
  padding-top: 8px;
  padding-bottom: 42px;
`;

//base card popup
export const TwoSmallButtonsContainer = withTheme()(styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 400px;
  padding-top: 8px;
  padding-bottom: 30px;

  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
    align-items: center;
    height: 100px;
    width: auto;
    margin-bottom: 24px;
  }
`);

export const SmallButtonWrapper = withTheme()(styled.div`
  display: flex;
  width: 180px;
  padding-top: ${Spacing.InterSection}px;
  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`);
//base card popup end

export const ThreeButtonsContainer = styled.div`
  display: flex;
  flex-direction: ${singleDoubleColumnSelect('column', 'row')};
  justify-content: space-between;
  max-width: 620px;
  padding-top: 8px;
  padding-bottom: 42px;
  height: ${singleDoubleColumnSelect('180px', '')};
  width: ${singleDoubleColumnSelect('100%', '')};
`;

export const ContinueUnsubscribeButtonWrapper = withTheme()(styled.div`
  display: flex;
  width: 180px;
  padding-top: ${Spacing.InterSection}px;
  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`);

export const ButtonWrapper = styled.div`
  display: flex;
  width: ${singleDoubleColumnSelect('100%', '180px')};
  justify-content: ${singleDoubleColumnSelect('space-between', '')};
  padding-top: ${Spacing.InterSection}px;
`;

export const SimpleButtonWrapper = withTheme()(styled.div`
  display: flex;
  width: 180px;
  padding-top: ${Spacing.InterSection}px;
  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.md}px) {
    justify-content: space-between;
    width: 100%;
  }
`);

export const ExtensionButtonWrapper = styled.div`
  width: 142px;
  display: flex;
  flex: none;
  margin-left: ${singleDoubleColumnSelect('', 'auto')};
  padding-left: ${singleDoubleColumnSelect('', '8px')};
  padding-top: ${singleDoubleColumnSelect('inherit', '')};
`;

export const Row = styled.div`
  border-bottom: 1px solid ${THEME.palette.background.smoke};
`;

export const SectionContainer = withTheme()(styled.div`
  padding: 15px 30px 0 30px;
`);

export const SectionPopupContainer = withTheme()(styled.div`
  padding: 10px 16px 0 16px;
  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    padding: 14px 8px 0 8px;
  }
`);

//difficultyofuse panel start
export const TwoSectionsContainer = styled.div`
  display: flex;
  flex-direction: ${singleDoubleColumnSelect('column', 'row')};
  justify-content: space-between;
  width: ${singleDoubleColumnSelect('auto', '100%')};
`;

export const ButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 42px;

  max-width: ${singleDoubleColumnSelect('', '450px')};
  width: ${singleDoubleColumnSelect('100%', '98%')};
`;

export const BigButtonWrapper = styled.div`
  display: flex;
  padding-top: ${Spacing.InterSection}px;
  max-width: ${singleDoubleColumnSelect('', '450px')};
  width: ${singleDoubleColumnSelect('100%', '98%')};
`;
//dificultyofuse panel end

//NotUsingIt panel
export const ThreeSectionsContainer = styled.div`
  display: flex;
  flex-direction: ${singleDoubleColumnSelect('column', 'row')};
  justify-content: space-between;
  width: auto;
`;

export const ButtonTextSmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${singleDoubleColumnSelect('', '300px')};
  padding-bottom: 42px;
  width: ${singleDoubleColumnSelect('100%', '90%')};
`;

export const MediumButtonWrapper = styled.div`
  display: flex;
  padding-top: ${Spacing.InterSection}px;
  max-width: ${singleDoubleColumnSelect('', '300px')};
  width: ${singleDoubleColumnSelect('100%', '90%')};
`;

import { Dictionary, isNil, map, pickBy } from 'lodash';

import { Patch, PatchOp } from '@common/api';

// @FIXME: This should be Dictionary<string | number | boolean | undefined> however TS can't cast this from an interface
// https://github.com/microsoft/TypeScript/issues/15300
type Request = Dictionary<any>;

export const createPatch = (oldRequest: Request, newRequest: Request) =>
  mapToPatch(pickChangedValues(oldRequest, newRequest));

const mapToPatch = (request: Request): Patch[] =>
  map(request, (value, key) => ({
    op: (isNil(value) ? 'remove' : 'replace') as PatchOp,
    path: `/${key}`,
    value: value,
  }));

const pickChangedValues = (oldRequest: Request, newRequest: Request) =>
  pickBy(newRequest, (_, key) => oldRequest[key] !== newRequest[key]);

export const METADATA_ONLY: Readonly<{ fields: string }> = { fields: 'metadata' };

import React from 'react';

import { isUndefined } from 'lodash';
import styled from 'styled-components';

import { onButtonClick } from '@component/button/CustomButton';
import { LoadingSpinnerOverlay } from '@component/loadingSpinner/LoadingSpinner';
import { ActionButton, FlexWrap } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { useUpdateSearchAlert } from '@component/panels/findLoads/searchPanel/SearchPanelHelper';
import { loadSearchRequestEncoder } from '@page/findLoads/LoadSearchRequestEncoder';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import { MediaQueries } from '@util/MediaQueries';

import { default as BellIconOff } from './alert-bell-off.svg';
import { default as BellIconOn } from './alert-bell-on.svg';

const SetAlertActionButton = styled(({ isAlert, ...rest }) => <ActionButton {...rest} />)`
  && {
    color: ${({ isAlert }) => (isAlert ? Color.ORANGE_MAIN : undefined)};
  }
  position: relative;
`;

const ActionButtonTitle = styled.span`
  padding-top: 2px;
  @media ${MediaQueries.xxs} {
    padding-top: 0;
  }
`;

interface Props {
  className?: string;
}

export const SetAlertButton: React.FC<Props> = (props) => {
  const updateSearchAlert = useUpdateSearchAlert();
  const { searches, ...searchesState } = useSelector((state) => state.loadSearch.searchesState);

  const loadSearchObject = loadSearchRequestEncoder.convertUrlParamsToObjectRequest(location.search);
  const searchId = loadSearchObject.searchId;

  if (
    searchesState.isLoadingSearches ||
    !searchId ||
    (!searchesState.isLoadingSearches && isUndefined(searches.entities[searchId]))
  ) {
    return null;
  }

  const search = searches.entities[searchId];
  return (
    <SetAlertActionButton
      id={search.hasAlert ? 'remove_alert' : 'set_alert'}
      className={props.className}
      onClick={onButtonClick(() => updateSearchAlert(searchId), search.isUpdatingAlert)}
      isAlert={search.hasAlert}
    >
      {search.isUpdatingAlert ? (
        <LoadingSpinnerOverlay />
      ) : (
        <FlexWrap>
          <img src={search.hasAlert ? BellIconOn : BellIconOff} />
          <ActionButtonTitle>{search.hasAlert ? t(T.common_alertOn) : t(T.common_alertOff)}</ActionButtonTitle>
        </FlexWrap>
      )}
    </SetAlertActionButton>
  );
};

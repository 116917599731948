import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';

import { DEFAULT_LOAD_SEARCH_FIELDS, MapBounds } from './LoadSearchRequest';
import { LoadProgress, SortExpression } from './LoadUserdata';

export interface MyLoadsRequest extends PaginatedRequest {
  isSaved?: boolean;
  isCalled?: boolean;
  isEmailed?: boolean;
  isContacted?: boolean;
  isBooked?: boolean;
  isGone?: boolean;
  isLowPayRate?: boolean;
  isHidden?: boolean;
  isWringInfo?: boolean;
  isNotInterested?: boolean;
  isNoted?: boolean;
  isHiddenAfterCall?: boolean;
  isViewed: boolean;
  progress?: LoadProgress;
  progressList?: LoadProgress[];
  sortExpression?: SortExpression;
  origLatLongBounds?: MapBounds;
}

export const createDefaultMyLoadsRequest = (): MyLoadsRequest => ({
  fields: DEFAULT_LOAD_SEARCH_FIELDS,
  isViewed: true,
  offset: 0,
  limit: MY_LOADS_REQUEST_LIMIT_DEFAULT,
});

export const createWorkingLoadsRequest = (): MyLoadsRequest => ({
  ...createDefaultMyLoadsRequest(),
  limit: 1,
  progressList: [
    LoadProgress.Booked,
    LoadProgress.ScheduledForPickup,
    LoadProgress.PickupComplete,
    LoadProgress.LoadEnRoute,
  ],
  sortExpression: SortExpression.LoadProgress,
});

export const MY_LOADS_REQUEST_OFFSET_INCREMENT = 10;

export const MY_LOADS_REQUEST_LIMIT_DEFAULT = 20;
export const MY_LOADS_REQUEST_LIMIT_APPENDING = 10;

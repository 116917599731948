import React, { createContext, useContext, useMemo, useState } from 'react';

import { noop } from 'lodash';

export enum PopupSizes {
  EXTRA_SMALL = 280,
  SMALL = 328,
  MEDIUM = 550,
  LARGE = 916,
  MENU_SIZE = 300,
}

export interface PopupEntity {
  body: React.ReactNode;
  isDismissible?: boolean;
  width: PopupSizes;
  onBackdropClick?: () => void;
  hasCloseButton?: boolean;
  hideBackdrop?: boolean;
}

export interface PopupTracking {
  popup?: PopupEntity;
  closingPopup: () => void;
  openingPopup: (popup: PopupEntity) => void;
}

const popupTrackingContext = createContext<PopupTracking>({
  popup: undefined,
  openingPopup: noop,
  closingPopup: noop,
});

/**
 * This provides popup contexts and track the state of a popup that should be displayed.
 * openingPopup will open the popup according to provided data (PopupEntity)
 * closingPopup will close currently opened popup
 * PopupEntity info:
 * @param body Is an actual content of a popup. It is recommended to use BasePopup.tsx for this,
 * because it has common styling, but can be any React.Node
 * @param width Is a width of a popup
 * @param isDismissible Is a flag that provides information if we can close popup by clicking outside, or not*/
const PopupTrackingProvider: React.FC = ({ children }) => {
  const [popup, setPopup] = useState<PopupEntity | undefined>(undefined);

  const context = useMemo(
    () => ({
      popup: popup,
      openingPopup: (popupEntity: PopupEntity) => {
        setPopup(popupEntity);
      },
      closingPopup: () => {
        setPopup(undefined);
      },
    }),
    [popup]
  );

  return <popupTrackingContext.Provider value={context}>{children}</popupTrackingContext.Provider>;
};

export const PopupProvider: React.FC = ({ children }) => {
  return <PopupTrackingProvider>{children}</PopupTrackingProvider>;
};

/**
 * This is used in App file to set context provider and actual Popup component*/

/**
 * It's purpose is to provide methods for opening and closing of a popup inside of react functional component*/
export const usePopup = () => {
  return useContext(popupTrackingContext);
};

/**
 * It's purpose is to provide methods for opening and closing of a popup inside of react class component*/
export const withPopups = <T extends {}>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    const popupContext = usePopup();

    return <Component popupContext={popupContext} {...props} />;
  };
};

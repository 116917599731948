import { assign, clone, cloneDeep, filter, map, merge, some } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError } from '@common/api';
import { ApiErrorCode } from '@common/api/ApiErrorCode';
import { LoadsClient } from '@common/client/LoadsClient';
import { uniqDates } from '@common/helper/DateHelper';
import { getFlowIDFrom } from '@common/helper/FlowIDHeaderHelper';
import {
  addLoadFetchedTime,
  hackToRevertAPI3330,
  LoadAction,
  LoadFieldAction,
  LoadUpdate,
  removeLoadDatesTimesIfMidnight,
  updateLoad,
  UpdateLoadAction,
  updateLoadActionCreator,
} from '@common/helper/LoadHelper';
import { Load, LOAD_DETAIL_REQUEST_FIELDS_DEFAULT, LoadSearchArchiving, RateCheckPreviewStatus } from '@common/model';
import { createAction, Response, responseActionHandler } from '@common/redux/Base';
import { createEpics, LoadSearchType } from '@common/redux/epic/loadSearch/LoadSearchHelper';
import {
  processRateCheckPreviewResponse,
  RateCheckPreviewResponseAction,
} from '@common/redux/epic/rateCheck/RateCheckPreviewHelper';
import {
  getSettingsFromStateObservable,
  isRateCheckPreviewEnabled,
  SettingsState,
} from '@common/redux/epic/SettingsStateHelper';
import { createMergedReducer, createReducer } from '@common/redux/ReduxHelper';

export enum LoadDetailsReducerKey {
  LOAD_SEARCH = 'loadDetails',
  BACKHAULS = 'loadDetailsBackhauls',
  COMPANY_SEARCH = 'loadDetailsCompanySearch',
  POSTED = 'postedLoadDetails', // For Web only
}

// used currently to get the loadID from reference and contactId
// since we don't have an api endpoint
// TODO: https://123loadboard.atlassian.net/browse/LB-361  Messaging Widgets for integrators TMS
const references = {
  '970b1909-3c99-4121-8dc1-4b0f2cd80f48': {
    '26583': 'f7646d11-05f2-48b5-99d6-9062e28ce8e9',
    '35446': '33a8e100-cfd0-49dc-8e2e-8d3aaaf3eec4',
    '52893': 'ff1fa2d2-c913-4486-b603-0c1b87010808',
    '72932': 'b677c216-715f-47cb-b73c-f31665c66ad1',
    '86717': '8494076a-ced8-48d9-81d0-8de721f7c3db',
  },
};

interface LoadDetailsActions {
  fetchLoadDetails: (loadID: string, fields?: string) => LoadAction;
  refreshLoadDetails: (loadID: string) => LoadAction;
  willShowLoadDetails: (
    load: Load,
    shouldResetLimit: boolean,
    archivingFlowID: string | undefined,
    overrideArchivingID: boolean
  ) => WillShowLoadDetailsAction;
  willShowLoadWithoutLoad: () => Action;
  updateLoadDetails: (update: LoadUpdate) => UpdateLoadAction;
  clearCurrentLoad: () => Action;
  setPreviousSelectedLoadID: (loadID: string | undefined) => SetPreviousSelectedLoadIDAction;
}

export interface LoadDetailsResponseAction extends Action {
  response: Response<Load>;
  loadID: string;
  httpStatus: number;
  archivingFlowID: string | undefined;
}

interface WillShowLoadDetailsAction extends Action, LoadSearchArchiving {
  load: Load;
  shouldResetLimit: boolean;
  overrideArchivingID: boolean;
}

interface SetPreviousSelectedLoadIDAction extends Action {
  loadID: string | undefined;
}

interface FetchSingleRateCheckPreviewAction extends Action {
  loadID: string;
}

export type LoadDetailsStatus = 'Unknown' | 'Loading' | 'Loaded' | 'Unavailable' | 'PermissionDenied' | 'Error';

interface BaseResponseState {
  status: LoadDetailsStatus;
}

interface ResponseStateUnknown extends BaseResponseState {
  status: 'Unknown';
}

interface ResponseStateLoading extends BaseResponseState {
  status: 'Loading';
}

interface ResponseStateLoaded extends BaseResponseState {
  status: 'Loaded';
}

interface ResponseStateUnavailable extends BaseResponseState {
  status: 'Unavailable';
}

interface ResponseStatePermissionDenied extends BaseResponseState {
  status: 'PermissionDenied';
}

interface ResponseStateError extends BaseResponseState {
  status: 'Error';
  message: string;
}

export type ResponseState =
  | ResponseStateUnknown
  | ResponseStateLoading
  | ResponseStateLoaded
  | ResponseStateUnavailable
  | ResponseStateError
  | ResponseStatePermissionDenied;

type LoadDetailsEpicStateObservable = StateObservable<{
  [reducerKey: string]: LoadDetailsState | SettingsState;
}>;

export interface LoadDetailsState {
  widgetLoadId?: string;
  currentLoad?: Load;
  hasReachedLimit: boolean;
  loadingIDs: string[];
  responseState: ResponseState;
  showRetry: boolean;
  previousSelectedLoadID: string | undefined;
  archivingFlowID: string | undefined;
}

const initialState: Readonly<LoadDetailsState> = {
  hasReachedLimit: false,
  loadingIDs: [],
  responseState: { status: 'Unknown' },
  showRetry: false,
  archivingFlowID: undefined,
  previousSelectedLoadID: undefined,
};

const getLoadDetailsState = (state$: StateObservable<any>, key: string) => state$.value[key] as LoadDetailsState;

const fetchLoadIdAction = createAction<{ referenceId: string; contactId: string }>('FETCH_LOAD_ID');
export const fetchLoadId = (referenceId: string, contactId: string) =>
  fetchLoadIdAction.action({ referenceId: referenceId, contactId: contactId });

const createLoadDetailsEpic = (key: string) => {
  //Actions
  const baseActionKey = `${key}_`;
  const actionsKeys = {
    FETCH_LOAD_DETAILS: `${baseActionKey}_FETCH_LOAD_DETAILS`,
    LOAD_DETAILS_FETCHED: `${baseActionKey}_LOAD_DETAILS_FETCHED`,
    REFRESH_LOAD_DETAILS: `${baseActionKey}_REFRESH_LOAD_DETAILS`,
    LOAD_DETAILS_REFRESHED: `${baseActionKey}_LOAD_DETAILS_REFRESHED`,
    WILL_SHOW_DETAILS: `${baseActionKey}_WILL_SHOW_DETAILS`,
    WILL_SHOW_DETAILS_WITHOUT_LOAD: `${baseActionKey}_WILL_SHOW_DETAILS_WITHOUT_LOAD`,
    UPDATE_LOAD_DETAILS: `${baseActionKey}_UPDATE_LOAD_DETAILS`,
    CLEAR_CURRENT_LOAD: `${baseActionKey}_CLEAR_CURRENT_LOAD`,
    SET_PREVIOUS_SELECTED_LOAD_ID: `${baseActionKey}_SET_PREVIOUS_SELECTED_LOAD_ID`,
    FETCH_SINGLERATECHECK_PREVIEW: `${baseActionKey}_FETCH_SINGLERATECHECK_PREVIEW`,
    SINGLERATECHECK_PREVIEW_COMPLETE: `${baseActionKey}_SINGLERATECHECK_PREVIEW_COMPLETE`,
  };

  const exportedActions: LoadDetailsActions = {
    fetchLoadDetails: (loadID: string, fields?: string): LoadFieldAction => ({
      type: actionsKeys.FETCH_LOAD_DETAILS,
      loadID: loadID,
      fields: fields ?? LOAD_DETAIL_REQUEST_FIELDS_DEFAULT,
    }),
    refreshLoadDetails: (loadID: string): LoadFieldAction => ({
      type: actionsKeys.REFRESH_LOAD_DETAILS,
      loadID: loadID,
      fields: LOAD_DETAIL_REQUEST_FIELDS_DEFAULT,
    }),
    willShowLoadDetails: (
      load: Load,
      shouldResetLimit: boolean = false,
      archivingFlowID: string | undefined,
      overrideArchivingID: boolean = true
    ): WillShowLoadDetailsAction => ({
      type: actionsKeys.WILL_SHOW_DETAILS,
      load: load,
      shouldResetLimit: shouldResetLimit,
      flowID: archivingFlowID,
      overrideArchivingID: overrideArchivingID,
    }),
    willShowLoadWithoutLoad: (): Action => ({
      type: actionsKeys.WILL_SHOW_DETAILS_WITHOUT_LOAD,
    }),
    updateLoadDetails: updateLoadActionCreator(actionsKeys.UPDATE_LOAD_DETAILS),
    clearCurrentLoad: (): Action => ({
      type: actionsKeys.CLEAR_CURRENT_LOAD,
    }),
    setPreviousSelectedLoadID: (loadID: string | undefined): SetPreviousSelectedLoadIDAction => ({
      type: actionsKeys.SET_PREVIOUS_SELECTED_LOAD_ID,
      loadID: loadID,
    }),
  };
  const loadDetailsResponse =
    (loadID: string, success: boolean, httpStatus: number, archivingFlowID: string | undefined) =>
    (response: Load | ApiError): LoadDetailsResponseAction => ({
      type: actionsKeys.LOAD_DETAILS_FETCHED,
      response: success
        ? { payload: response as Load, success: true }
        : { error: response as ApiError, success: false },
      loadID: loadID,
      httpStatus: httpStatus,
      archivingFlowID: archivingFlowID,
    });

  const loadDetailsUpdated = (state: LoadDetailsState, action: LoadDetailsResponseAction) => {
    const load = action.response.payload;

    state.showRetry = action.httpStatus <= 0;
    state.hasReachedLimit = false;
    state.loadingIDs = filter(state.loadingIDs, (id) => id !== action.loadID);
    state.responseState = { status: 'Loaded' };
    state.archivingFlowID = action.archivingFlowID;

    if (action.response.success && load) {
      state.currentLoad = getUpdatedCurrentLoad(state.currentLoad, load);
      return;
    }

    const error = action.response.error;
    if (error) {
      loadDetailsFailed(state, error, () => {
        state.responseState = { status: 'Error', message: error.message };
      });
    }
  };

  const loadDetailsRefreshed = (state: LoadDetailsState, action: LoadDetailsResponseAction) => {
    const load = action.response.payload;
    if (action.response.success && load) {
      state.showRetry = false;
      state.hasReachedLimit = false;
      state.responseState = { status: 'Loaded' };
      state.currentLoad = getUpdatedCurrentLoad(state.currentLoad, load);
      return;
    }
    const error = action.response.error;
    if (error) {
      loadDetailsFailed(state, error);
    }
  };

  const getUpdatedCurrentLoad = (prevCurrentLoad: Load | undefined, load: Load) => {
    const mergedLoad = mergeFetchedLoad(prevCurrentLoad, load);
    const currentLoad = addLoadFetchedTime(Date.now())(removeLoadDatesTimesIfMidnight(hackToRevertAPI3330(mergedLoad)));
    const pickupDateTimes = currentLoad.pickupDateTimes;
    if (pickupDateTimes) {
      return { ...currentLoad, pickupDateTimes: uniqDates(pickupDateTimes) };
    }
    return currentLoad;
  };

  const loadDetailsFailed = (state: LoadDetailsState, error: ApiError, unexpected?: () => void) => {
    if (error.code === ApiErrorCode.LOAD_LIMIT_REACHED) {
      state.hasReachedLimit = true;
      return;
    }
    if (error.code === ApiErrorCode.LOAD_NOT_FOUND) {
      state.currentLoad = undefined;
      state.responseState = { status: 'Unavailable' };
      return;
    }
    if (error.code === ApiErrorCode.PERMISSION_DENIED) {
      state.responseState = { status: 'PermissionDenied' };
      return;
    }
    unexpected?.();
  };

  const refreshLoadDetailsResponse =
    (loadID: string, success: boolean, httpStatus: number, archivingFlowID: string | undefined) =>
    (response: Load | ApiError): LoadDetailsResponseAction => ({
      type: actionsKeys.LOAD_DETAILS_REFRESHED,
      response: success
        ? { payload: response as Load, success: true }
        : { error: response as ApiError, success: false },
      loadID: loadID,
      httpStatus: httpStatus,
      archivingFlowID: archivingFlowID,
    });

  //Reducer
  const reducer = createReducer(initialState, {
    [actionsKeys.WILL_SHOW_DETAILS]: (state, action: WillShowLoadDetailsAction) => {
      state.currentLoad = action.load;
      if (action.shouldResetLimit) {
        state.hasReachedLimit = false;
      }
      if (action.overrideArchivingID) {
        state.archivingFlowID = action.flowID;
      }
    },
    [actionsKeys.WILL_SHOW_DETAILS_WITHOUT_LOAD]: (state) => {
      state.currentLoad = undefined;
      state.hasReachedLimit = false;
      state.archivingFlowID = undefined;
    },
    [actionsKeys.FETCH_LOAD_DETAILS]: (state, action: LoadAction) => {
      const loadingIds = clone(state.loadingIDs);
      loadingIds.push(action.loadID);
      state.loadingIDs = loadingIds;
      state.responseState = { status: 'Loading' };
    },
    [actionsKeys.LOAD_DETAILS_FETCHED]: loadDetailsUpdated,
    [actionsKeys.LOAD_DETAILS_REFRESHED]: loadDetailsRefreshed,
    [actionsKeys.UPDATE_LOAD_DETAILS]: (state, action: UpdateLoadAction) => {
      if (state.currentLoad) {
        state.currentLoad = clone(updateLoad(state.currentLoad, action.update));
      }
    },
    [actionsKeys.CLEAR_CURRENT_LOAD]: (state) => {
      state.currentLoad = undefined;
    },
    [actionsKeys.SET_PREVIOUS_SELECTED_LOAD_ID]: (state, action: SetPreviousSelectedLoadIDAction) => {
      state.previousSelectedLoadID = action.loadID;
    },
    [actionsKeys.SINGLERATECHECK_PREVIEW_COMPLETE]: (state, action: RateCheckPreviewResponseAction) => {
      if (action.response.success && action.response.payload && state.currentLoad) {
        const payload = action.response.payload;
        const stateLoadId = state.currentLoad.id;
        if (
          some(
            map([...(payload.rates ?? []), ...(payload.skippedLoads ?? [])], (rateInfo) => rateInfo.loadId),
            (loadId) => loadId === stateLoadId
          )
        ) {
          processRateCheckPreviewResponse(state.currentLoad, payload);
        }
      }
    },
  });

  const mergedReducer = createMergedReducer(
    initialState,
    [
      fetchLoadIdAction.addCase((state, action) => {
        const referenceId = action.data.referenceId;
        const contactId = action.data.contactId;
        state.widgetLoadId = (references as any)[contactId]?.[referenceId];
      }),
    ],
    reducer
  );

  //Epics
  const fetchLoadDetails$ = (
    action$: ActionsObservable<Action>,
    loadsClient: LoadsClient,
    state$: StateObservable<any>
  ) =>
    action$.ofType(actionsKeys.FETCH_LOAD_DETAILS).pipe(
      mergeMap$((action: LoadFieldAction) => {
        return loadsClient
          .fetchLoadDetails$(action.loadID, getLoadDetailsArchivingFlowID(state$, key), action.fields)
          .pipe(
            map$((response) => {
              const flowID = getFlowIDFrom(response);
              return response.result(
                loadDetailsResponse(action.loadID, true, response.httpStatus, flowID),
                loadDetailsResponse(action.loadID, false, response.httpStatus, flowID)
              );
            })
          );
      })
    );

  const refreshLoadDetails$ = (
    action$: ActionsObservable<Action>,
    loadsClient: LoadsClient,
    state$: StateObservable<any>
  ) =>
    action$.ofType(actionsKeys.REFRESH_LOAD_DETAILS).pipe(
      mergeMap$((action: LoadFieldAction) => {
        return loadsClient
          .fetchLoadDetails$(action.loadID, getLoadDetailsArchivingFlowID(state$, key), action.fields)
          .pipe(
            map$((response) => {
              const flowID = getFlowIDFrom(response);
              return response.result(
                refreshLoadDetailsResponse(action.loadID, true, response.httpStatus, flowID),
                refreshLoadDetailsResponse(action.loadID, false, response.httpStatus, flowID)
              );
            })
          );
      })
    );

  const singleRateCheckPreviewEpic$ = (
    action$: ActionsObservable<Action>,
    loadsClient: LoadsClient,
    state$: LoadDetailsEpicStateObservable
  ) =>
    merge$(
      action$.ofType(actionsKeys.LOAD_DETAILS_FETCHED).pipe(
        mergeMap$(() => {
          if (!isRateCheckPreviewEnabled(getSettingsFromStateObservable(state$))) {
            return of$();
          }

          const storeState = getLoadDetailsState(state$, key);
          if (
            !storeState.currentLoad ||
            (storeState.currentLoad.rateCheckPreview &&
              storeState.currentLoad.rateCheckPreview?.status !== RateCheckPreviewStatus.Loading)
          ) {
            // if we don't have a currentLoad (weird) or rateCheckPreview is already loaded, do nothing.
            return of$();
          }
          return of$({
            type: actionsKeys.FETCH_SINGLERATECHECK_PREVIEW,
            loadID: storeState.currentLoad.id,
          } as FetchSingleRateCheckPreviewAction);
        })
      ),
      action$.ofType(actionsKeys.FETCH_SINGLERATECHECK_PREVIEW).pipe(
        mergeMap$((action: FetchSingleRateCheckPreviewAction) => {
          const storeState = getLoadDetailsState(state$, key);
          return loadsClient.fetchLoadRateCheckPreviews$([action.loadID], storeState.archivingFlowID).pipe(
            map$((response) => ({
              ...responseActionHandler(actionsKeys.SINGLERATECHECK_PREVIEW_COMPLETE, response),
              fetchData: action,
            }))
          );
        })
      )
    );

  const createEpic$ = (api: Api, isLiveEnvironment: boolean) => {
    const client = new LoadsClient(api, isLiveEnvironment);

    return (action$: ActionsObservable<Action>, state$: StateObservable<any>) =>
      merge$(
        fetchLoadDetails$(action$, client, state$),
        refreshLoadDetails$(action$, client, state$),
        singleRateCheckPreviewEpic$(action$, client, state$)
      );
  };

  return {
    reducer: mergedReducer,
    createEpic$: createEpic$,
    actions: exportedActions,
    actionKeys: actionsKeys,
  };
};

const mergeFetchedLoad = (currentLoad: Load | undefined, fetchedLoad: Load) => {
  if (!currentLoad || currentLoad.id !== fetchedLoad.id) {
    // replace load completely if different id
    return cloneDeep(fetchedLoad);
  } else {
    // shallow merge (without mutation) for most fields in Load
    // Most subfields needs to be *replaced* instead of merged.
    // Phone, Rate, arrays like equipments/pickupDates, originLocation, destinationLocation, creditRatings
    // note, deliverydate, length, weight, postRef., rate can all be set to '' by the user
    // So when a value is deleted, the new fetchedload will not have the above keys,
    // When we assign the fetched load to curent load the previous values are preserved instead of undefined
    const loadBase = assign(
      {},
      {
        notes: undefined,
        privateLoadNote: undefined,
        deliveryDateTime: undefined,
        length: undefined,
        weight: undefined,
        postReference: undefined,
        rate: undefined,
      },
      fetchedLoad
    );
    const load = assign({}, currentLoad, loadBase);
    // deep merge of specific fields without mutation (unless both sides are undefined)
    if (load.poster !== undefined) {
      load.poster = merge({}, currentLoad.poster, fetchedLoad.poster);
    }
    if (load.metadata !== undefined) {
      load.metadata = merge({}, currentLoad.metadata, fetchedLoad.metadata);
    }
    load.dispatchEmail = fetchedLoad.dispatchEmail;
    load.dispatchPhone = fetchedLoad.dispatchPhone;
    // make sure everything is cloned
    return cloneDeep(load);
  }
};

const LoadDetailsEpics = createEpics(
  {
    [LoadSearchType.Backhaul]: LoadDetailsReducerKey.BACKHAULS,
    [LoadSearchType.LoadSearch]: LoadDetailsReducerKey.LOAD_SEARCH,
    [LoadSearchType.CompanySearch]: LoadDetailsReducerKey.COMPANY_SEARCH,
    [LoadSearchType.Posted]: LoadDetailsReducerKey.POSTED, // Only for Web
    [LoadSearchType.LoadAvailability]: LoadDetailsReducerKey.LOAD_SEARCH,
  },
  createLoadDetailsEpic
);

const loadDetails = LoadDetailsEpics[LoadSearchType.LoadSearch];
const loadDetailsBackhauls = LoadDetailsEpics[LoadSearchType.Backhaul];
const loadDetailsCompanySearch = LoadDetailsEpics[LoadSearchType.CompanySearch];

export const fetchLoadDetails = (type: LoadSearchType, loadId: string, fields?: string | undefined) =>
  LoadDetailsEpics[type].actions.fetchLoadDetails(loadId, fields);
export const clearCurrentLoad = (type: LoadSearchType) => LoadDetailsEpics[type].actions.clearCurrentLoad();
export const willShowLoadDetails = (
  type: LoadSearchType,
  load: Load,
  shouldResetLimit: boolean,
  archivingFlowID: string | undefined,
  overrideArchivingID: boolean = true
) => LoadDetailsEpics[type].actions.willShowLoadDetails(load, shouldResetLimit, archivingFlowID, overrideArchivingID);
export const setPreviousSelectedLoadID = (type: LoadSearchType, loadId: string | undefined) =>
  LoadDetailsEpics[type].actions.setPreviousSelectedLoadID(loadId);
export const refreshLoadDetails = (type: LoadSearchType, loadId: string) =>
  LoadDetailsEpics[type].actions.refreshLoadDetails(loadId);
export const updateLoadDetails = (type: LoadSearchType, update: LoadUpdate) =>
  LoadDetailsEpics[type].actions.updateLoadDetails(update);
export const willShowLoadWithoutLoad = (type: LoadSearchType) =>
  LoadDetailsEpics[type].actions.willShowLoadWithoutLoad();

export const loadDetailsReducer = (type: LoadSearchType) => LoadDetailsEpics[type].reducer;

export const loadDetailsEpicCreator$ = (type: LoadSearchType, api: Api, isLiveEnvironment: boolean) =>
  LoadDetailsEpics[type].createEpic$(api, isLiveEnvironment);

export const WILL_SHOW_DETAILS = loadDetails.actionKeys.WILL_SHOW_DETAILS;
export const WILL_SHOW_BACKHAUL_DETAILS = loadDetailsBackhauls.actionKeys.WILL_SHOW_DETAILS;
export const LOAD_DETAILS_FETCHED = loadDetails.actionKeys.LOAD_DETAILS_FETCHED;
export const BACKHAULS_LOAD_DETAILS_FETCHED = loadDetailsBackhauls.actionKeys.LOAD_DETAILS_FETCHED;
export const WILL_SHOW_COMPANY_SEARCH_DETAILS = loadDetailsCompanySearch.actionKeys.WILL_SHOW_DETAILS;
export const LOAD_DETAILS_COMPANY_SEARCH_FETCHED = loadDetailsCompanySearch.actionKeys.LOAD_DETAILS_FETCHED;

export const getLoadDetailsArchivingFlowID = (state$: StateObservable<any>, key: string) =>
  getLoadDetailsState(state$, key).archivingFlowID;

import React from 'react';

import { isUndefined } from 'lodash';

import { getRefreshRate, loadComputedAge, ONE_HOUR_IN_SECONDS } from '@common/helper';
import { Load } from '@common/model';

import { SYNCTIMER_STOPPED, useSyncTimer } from './SyncTimer';

interface ComponentProps {
  age?: number;
}

export const loadAgeProvider =
  <T extends { load: Load }>(Component: React.ComponentClass<ComponentProps & T> | React.FC<ComponentProps & T>) =>
  (props: T) => {
    const rate = getRefreshRate(props.load, Date.now());
    const period = rate < ONE_HOUR_IN_SECONDS ? rate : SYNCTIMER_STOPPED;
    const timestamp = useSyncTimer(period);
    const age = loadComputedAge(props.load, timestamp);

    return <Component {...props} age={!isUndefined(age) && age > 0 ? age : 0} />;
  };

import { createDefaultLoadSearchRequest } from '@common/helper';
import { DestLocation, EquipmentType, LoadSize, OriginLocation, UserTruck } from '@common/model';
import { createAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface PostTruckAvailabilityForm {
  truckId?: string;
  destination: DestLocation;
  origin: OriginLocation;
  pickupDates: string[];
  loadSize?: LoadSize;
  minWeight?: number;
  weight?: number;
  minLength?: number;
  length?: number;
  truck?: UserTruck;
  destRadius?: number;
  originRadius?: number;
  anonymous?: boolean;
  truckType?: EquipmentType;
  minRate?: string;
  notes?: string;
  teamDriven?: boolean;
}

export interface TruckAvailabilityFormState {
  truckAvailability: PostTruckAvailabilityForm;
  isPrefilled?: boolean;
  lastPostedOrigin?: OriginLocation;
}

const truckAvailabilityInitialForm: TruckAvailabilityFormState = {
  truckAvailability: createDefaultLoadSearchRequest(),
};

export const clearPostAvailabilityForm = createAction<undefined>('CLEAR_TRUCK_AVAILABILITY_FORM');
export const updatePostAvailabilityForm = createAction<PostTruckAvailabilityForm>('UPDATE_TRUCK_AVAILABILITY_FORM');
export const setPostAvailabilityPrefilled = createAction<boolean>('SET_TRUCK_AVAILABILITY_PREFILLED');
export const persistPostedOrigin = createAction<OriginLocation | undefined>('PERSIST_TRUCK_POSTED_ORIGIN');

export const postAvailabilityFormReducer = createMergedReducer<TruckAvailabilityFormState>(
  truckAvailabilityInitialForm,
  [
    clearPostAvailabilityForm.addCase((state) => {
      state.truckAvailability = createDefaultLoadSearchRequest();
      if (state.lastPostedOrigin !== undefined) {
        state.truckAvailability.origin = state.lastPostedOrigin;
      }
    }),
    updatePostAvailabilityForm.addCase((state, action) => {
      state.truckAvailability = action.data;
    }),
    setPostAvailabilityPrefilled.addCase((state, action) => {
      state.isPrefilled = action.data;
    }),
    persistPostedOrigin.addCase((state, action) => {
      state.lastPostedOrigin = action.data;
    }),
  ]
);

import React from 'react';

import THEME from '@style/Theme';

import { StyledButton, StyledLabel, StyledRadio } from './ControlStyles';

interface Props {
  id: string;
  label?: string;
  value: string;
  name: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  inLine?: boolean;
}

export const Radio: React.FC<Props> = ({
  label,
  value,
  name,
  isDisabled,
  isChecked,
  id,
  onClick,
  onChange,
  inLine,
  ...others
}) => {
  return (
    <StyledButton id={id} variant="outlined" color="primary" disabled={isDisabled} onClick={onClick}>
      <StyledLabel id="label" htmlFor={name} theme={THEME} inLine={inLine}>
        {!inLine && label}
        <StyledRadio
          id="selection"
          value={value}
          disabled={isDisabled}
          color="primary"
          checked={isChecked}
          {...others}
          onChange={(e: React.FormEvent<HTMLInputElement>) => onChange && onChange(e)}
          inLine={inLine}
        />
        {inLine && label}
      </StyledLabel>
    </StyledButton>
  );
};

import { filter, find, reduce, toUpper } from 'lodash';
import memoizeOne from 'memoize-one';

import {
  codeStringsToEquipmentSpecifications,
  equipmentSpecificationsFrom,
  equipmentSpecificationsToCodeStrings,
  equipmentSpecificationsToString,
  maxMileageRequestValue,
  renderMileageText,
  TRIP_LENGTH_ANY,
  tripLengthRangeToString,
} from '@common/helper';
import { CompanyFilter, CompanyFilterType, EquipmentSpecification, LoadSearchRequest, LoadSize } from '@common/model';
import { t, T } from '@translate';

import {
  CREDIT_RATING_ANY,
  CREDIT_RATING_DAYS_TO_PAY_ANY,
  creditRatingDaysToPayRangeFromString,
  creditRatingDaysToPayRangeToString,
  creditRatingDaysToPayStringValuesFromMinMax,
  creditRatingFromString,
  creditRatingRangeToString,
  creditRatingStringValuesFromMinMax,
  POSTED_AGE_MINUTES,
  postedAgeFromString,
  postedAgeStringValuesFromMinMax,
  postedAgeToPayRangeToString,
} from './CreditRatingHelper';

export interface SearchFilter {
  isOnboarded?: boolean;
  isFavoriteBroker?: boolean;
  company?: CompanyFilter;
  minAge?: number;
  maxAge?: number;
  hasMessaging?: boolean;
  hasBidding?: boolean;
  equipmentSpecifications?: string;
  hasCommodity?: boolean;
  hasRate?: boolean;
  hasTeam?: boolean;
  loadSize?: LoadSize;
  includeLoadsWithoutLength?: boolean;
  includeLoadsWithoutWeight?: boolean;
  minTransCreditRating?: number;
  maxTransCreditRating?: number;
  minTransCreditDaysToPay?: number;
  maxTransCreditDaysToPay?: number;
  minMileage?: number;
  maxMileage?: number;
}

export enum FilterType {
  OnboardedCompanies = 'onboarded_companies',
  FavoriteCompanies = 'favorite_companies',
  CreditRating = 'credit_rating',
  DaysToPay = 'days_to_pay',
  Shipper = 'shipper',
  TrailerSpecifications = 'trailer_specifications',
  PostedAge = 'posted_age',
  WithMessaging = 'with_messaging',
  WithBidding = 'with_bidding',
  Commodity = 'commodity',
  PostedRate = 'posted_rate',
  TeamDrivers = 'team_drivers',
  Tl = 'tl',
  Ltl = 'ltl',
  WithWeight = 'with_weight',
  WithLength = 'with_length',
  TripLength = 'trip_length',
}

export interface FilterOption {
  filterType: FilterType;
  title: string;
  testID: string;
  accessibilityLabel: string;
  isSelected: boolean;
  filterSpecificInput?: FilterOptionSpecificInput;
}

export interface FilterOptionSpecificInput {
  label: string;
  values: string[];
  customValuesString?: string;
  unit?: string;
  range?: string | undefined;
}

export interface FilterSection {
  id: string;
  title: string;
  options: FilterOption[];
}

export const SearchFilterDistanceSection = 'distance';
export const SearchFilterCompanyPreferencesSection = 'company_preferences';

export const sectionsFromSearchFilter = (searchFilter: SearchFilter): FilterSection[] => {
  const normalizedSearchFilter = normalizeSearch(searchFilter);

  return [
    {
      id: 'load_size',
      title: t(T.common_load_filter_loadSize),
      options: [
        {
          filterType: FilterType.Tl,
          title: t(T.common_load_filter_tl),
          testID: `${FilterType.Tl}_${Boolean(normalizedSearchFilter.loadSize)}`,
          accessibilityLabel: `${FilterType.Tl}_${Boolean(normalizedSearchFilter.loadSize)}`,
          isSelected: normalizedSearchFilter.loadSize === LoadSize.TL,
        },
        {
          filterType: FilterType.Ltl,
          title: t(T.common_load_filter_ltl),
          testID: `${FilterType.Ltl}_${Boolean(normalizedSearchFilter.loadSize)}`,
          accessibilityLabel: `${FilterType.Ltl}_${Boolean(normalizedSearchFilter.loadSize)}`,
          isSelected: normalizedSearchFilter.loadSize === LoadSize.LTL,
        },
        {
          filterType: FilterType.WithWeight,
          title: t(T.common_load_filter_withWeight),
          testID: `${FilterType.WithWeight}_${!normalizedSearchFilter.includeLoadsWithoutWeight}`,
          accessibilityLabel: `${FilterType.WithWeight}_${!normalizedSearchFilter.includeLoadsWithoutWeight}`,
          isSelected: normalizedSearchFilter.includeLoadsWithoutWeight === false,
        },
        {
          filterType: FilterType.WithLength,
          title: t(T.common_load_filter_withLength),
          testID: `${FilterType.WithLength}_${!normalizedSearchFilter.includeLoadsWithoutLength}`,
          accessibilityLabel: `${FilterType.WithLength}_${!normalizedSearchFilter.includeLoadsWithoutLength}`,
          isSelected: normalizedSearchFilter.includeLoadsWithoutLength === false,
        },
      ],
    },
    {
      id: SearchFilterDistanceSection,
      title: t(T.common_load_filter_distance),
      options: [
        {
          filterType: FilterType.TripLength,
          title: t(T.common_load_filter_tripLength),
          testID: `${FilterType.TripLength}_${Boolean(normalizedSearchFilter.minMileage?.valueOf)}`,
          accessibilityLabel: `${FilterType.TripLength}_${Boolean(normalizedSearchFilter.minMileage?.valueOf)}`,
          isSelected: normalizedSearchFilter.minMileage !== undefined,
          filterSpecificInput: {
            label: t(T.common_load_filter_tripLength),
            values:
              normalizedSearchFilter.minMileage !== undefined
                ? [renderMileageText(normalizedSearchFilter.minMileage, normalizedSearchFilter.maxMileage, true)]
                : [],
            customValuesString:
              normalizedSearchFilter.minMileage !== undefined
                ? renderMileageText(normalizedSearchFilter.minMileage, normalizedSearchFilter.maxMileage, true)
                : '',
            unit: t(T.common_miles_short),
            range:
              normalizedSearchFilter.minMileage !== undefined
                ? tripLengthRangeToString({
                    min: normalizedSearchFilter.minMileage,
                    max: normalizedSearchFilter.maxMileage,
                  })
                : '',
          },
        },
      ],
    },
    {
      id: 'company_preferences',
      title: t(T.common_load_filter_companyPreferences),
      options: [
        {
          filterType: FilterType.OnboardedCompanies,
          title: t(T.common_load_filter_onboardedCompanies),
          testID: `${FilterType.OnboardedCompanies}_${Boolean(normalizedSearchFilter.isOnboarded)}`,
          accessibilityLabel: `${FilterType.OnboardedCompanies}_${Boolean(normalizedSearchFilter.isOnboarded)}`,
          isSelected: Boolean(normalizedSearchFilter.company?.isOnboarded),
        },
        {
          filterType: FilterType.FavoriteCompanies,
          title: t(T.common_load_filter_favoriteCompanies),
          testID: `${FilterType.FavoriteCompanies}_${Boolean(normalizedSearchFilter.isFavoriteBroker)}`,
          accessibilityLabel: `${FilterType.FavoriteCompanies}_${Boolean(normalizedSearchFilter.isFavoriteBroker)}`,
          isSelected: Boolean(normalizedSearchFilter.isFavoriteBroker),
        },
        {
          filterType: FilterType.CreditRating,
          title: t(T.common_load_filter_creditRating),
          testID: `${FilterType.CreditRating}_${Boolean(normalizedSearchFilter.minTransCreditRating?.valueOf)}`,
          accessibilityLabel: `${FilterType.CreditRating}_${Boolean(
            normalizedSearchFilter.minTransCreditRating?.valueOf
          )}`,
          isSelected:
            normalizedSearchFilter.minTransCreditRating !== undefined &&
            normalizedSearchFilter.maxTransCreditRating !== undefined,
          filterSpecificInput: {
            label: t(T.common_load_filter_creditRatingShort),
            values:
              normalizedSearchFilter.minTransCreditRating !== undefined &&
              normalizedSearchFilter.maxTransCreditRating !== undefined
                ? creditRatingStringValuesFromMinMax(
                    normalizedSearchFilter.minTransCreditRating,
                    normalizedSearchFilter.maxTransCreditRating
                  )
                : [],
            range:
              normalizedSearchFilter.minTransCreditRating !== undefined && normalizedSearchFilter.maxTransCreditRating
                ? creditRatingRangeToString({
                    min: normalizedSearchFilter.minTransCreditRating,
                    max: normalizedSearchFilter.maxTransCreditRating,
                  })
                : '',
          },
        },
        {
          filterType: FilterType.DaysToPay,
          title: t(T.common_load_filter_daysToPay),
          testID: `${FilterType.DaysToPay}_${Boolean(normalizedSearchFilter.minTransCreditDaysToPay?.valueOf)}`,
          accessibilityLabel: `${FilterType.DaysToPay}_${Boolean(
            normalizedSearchFilter.minTransCreditDaysToPay?.valueOf
          )}`,
          isSelected: normalizedSearchFilter.minTransCreditDaysToPay !== undefined,
          filterSpecificInput: {
            label: t(T.common_load_filter_daysToPayShort),
            values:
              normalizedSearchFilter.minTransCreditDaysToPay !== undefined
                ? creditRatingDaysToPayStringValuesFromMinMax(
                    normalizedSearchFilter.minTransCreditDaysToPay,
                    normalizedSearchFilter.maxTransCreditDaysToPay
                  )
                : [],
            range:
              normalizedSearchFilter.minTransCreditDaysToPay !== undefined
                ? creditRatingDaysToPayRangeToString({
                    min: normalizedSearchFilter.minTransCreditDaysToPay,
                    max: normalizedSearchFilter.maxTransCreditDaysToPay,
                  })
                : '',
          },
        },
        {
          filterType: FilterType.Shipper,
          title: t(T.common_load_filter_shipper),
          testID: `${FilterType.Shipper}_${Boolean(
            normalizedSearchFilter.company?.types === CompanyFilterType.Shipper
          )}`,
          accessibilityLabel: `${FilterType.Shipper}_${Boolean(
            normalizedSearchFilter.company?.types === CompanyFilterType.Shipper
          )}`,
          isSelected: normalizedSearchFilter.company?.types === CompanyFilterType.Shipper,
        },
      ],
    },
    {
      id: 'load_info',
      title: t(T.common_load_filter_loadInfo),
      options: [
        {
          filterType: FilterType.PostedAge,
          title: t(T.common_load_filter_postedAge),
          testID: `${FilterType.PostedAge}_${Boolean(normalizedSearchFilter.equipmentSpecifications?.valueOf)}`,
          accessibilityLabel: `${FilterType.PostedAge}_${Boolean(
            normalizedSearchFilter.equipmentSpecifications?.valueOf
          )}`,
          isSelected: normalizedSearchFilter.minAge !== undefined,
          filterSpecificInput: {
            label: t(T.common_load_filter_postedAge),
            values:
              normalizedSearchFilter.minAge !== undefined
                ? postedAgeStringValuesFromMinMax(normalizedSearchFilter.minAge, normalizedSearchFilter.maxAge)
                : [],
            unit: t(T.common_time_abbreviation_Minutes),
            range:
              normalizedSearchFilter.minAge !== undefined
                ? postedAgeToPayRangeToString({
                    min: normalizedSearchFilter.minAge,
                    max: normalizedSearchFilter.maxAge,
                  })
                : '',
          },
        },
        /**  With Messaging Filter Option is to be hidden and possibly removed in the future to avoid potential user frustration.
         *   MOB-5874 & MEM-3002
         */
        // {
        //   filterType: FilterType.WithMessaging,
        //   title: t(T.common_load_filter_withMessaging),
        //   testID: `${FilterType.WithMessaging}_${Boolean(normalizedSearchFilter.hasMessaging)}`,
        //   accessibilityLabel: `${FilterType.WithMessaging}_${Boolean(normalizedSearchFilter.hasMessaging)}`,
        //   isSelected: Boolean(normalizedSearchFilter.hasMessaging),
        // },
        {
          filterType: FilterType.WithBidding,
          title: t(T.common_load_filter_withBidding),
          testID: `${FilterType.WithBidding}_${Boolean(normalizedSearchFilter.hasBidding)}`,
          accessibilityLabel: `${FilterType.WithBidding}_${Boolean(normalizedSearchFilter.hasBidding)}`,
          isSelected: Boolean(normalizedSearchFilter.hasBidding),
        },
        {
          filterType: FilterType.TrailerSpecifications,
          title: t(T.common_load_filter_trailerSpecifications),
          testID: `${FilterType.TrailerSpecifications}_${Boolean(normalizedSearchFilter.equipmentSpecifications)}`,
          accessibilityLabel: `${FilterType.TrailerSpecifications}_${Boolean(
            normalizedSearchFilter.equipmentSpecifications
          )}`,

          isSelected: normalizedSearchFilter.equipmentSpecifications !== undefined,
          filterSpecificInput: {
            label: t(T.common_load_filter_trailerSpecs),
            values: normalizedSearchFilter.equipmentSpecifications
              ? equipmentSpecificationsToCodeStrings(
                  equipmentSpecificationsFrom(normalizedSearchFilter.equipmentSpecifications)
                )
              : [],
          },
        },
        {
          filterType: FilterType.Commodity,
          title: t(T.common_load_filter_commodity),
          testID: `${FilterType.Commodity}_${Boolean(normalizedSearchFilter.hasCommodity)}`,
          accessibilityLabel: `${FilterType.Commodity}_${Boolean(normalizedSearchFilter.hasCommodity)}`,
          isSelected: Boolean(normalizedSearchFilter.hasCommodity),
        },
        {
          filterType: FilterType.PostedRate,
          title: t(T.common_load_filter_postedRate),
          testID: `${FilterType.PostedRate}_${Boolean(normalizedSearchFilter.hasRate)}`,
          accessibilityLabel: `${FilterType.PostedRate}_${Boolean(normalizedSearchFilter.hasRate)}`,
          isSelected: Boolean(normalizedSearchFilter.hasRate),
        },
        {
          filterType: FilterType.TeamDrivers,
          title: t(T.common_load_filter_teamDrivers),
          testID: `${FilterType.TeamDrivers}_${Boolean(normalizedSearchFilter.hasTeam)}`,
          accessibilityLabel: `${FilterType.TeamDrivers}_${Boolean(normalizedSearchFilter.hasTeam)}`,
          isSelected: Boolean(normalizedSearchFilter.hasTeam),
        },
      ],
    },
  ];
};

export const updateFilterValues = (searchFilter: SearchFilter, filterType: FilterType, values: string[]) => {
  switch (filterType) {
    case FilterType.TrailerSpecifications:
      return {
        ...searchFilter,
        equipmentSpecifications: values.length
          ? equipmentSpecificationsToString(codeStringsToEquipmentSpecifications(values))
          : undefined,
      };
    case FilterType.CreditRating:
      return {
        ...searchFilter,
        minTransCreditRating:
          values.length > 0 ? creditRatingFromString(values[0]).min : CREDIT_RATING_ANY.minTransCreditRating,
        maxTransCreditRating:
          values.length > 0
            ? creditRatingFromString(values[values.length - 1]).max
            : CREDIT_RATING_ANY.maxTransCreditRating,
      };
    case FilterType.DaysToPay:
      return {
        ...searchFilter,
        minTransCreditDaysToPay:
          values.length > 0
            ? creditRatingDaysToPayRangeFromString(values[0]).min
            : CREDIT_RATING_DAYS_TO_PAY_ANY.minTransCreditDaysToPay,
        maxTransCreditDaysToPay:
          values.length > 0
            ? creditRatingDaysToPayRangeFromString(values[values.length - 1]).max
            : CREDIT_RATING_DAYS_TO_PAY_ANY.maxTransCreditDaysToPay,
      };
    case FilterType.TripLength:
      return {
        ...searchFilter,
        minMileage: +(values[0] ?? TRIP_LENGTH_ANY.minMileage),
        maxMileage: maxMileageRequestValue(values[1]) ?? TRIP_LENGTH_ANY.maxMileage,
      };
    case FilterType.PostedAge:
      return {
        ...searchFilter,
        minAge: values.length > 0 ? postedAgeFromString(values[0]).min : POSTED_AGE_MINUTES.minAge,
        maxAge: values.length > 0 ? postedAgeFromString(values[values.length - 1]).max : POSTED_AGE_MINUTES.maxAge,
      };

    default:
      return searchFilter;
  }
};

export const emptySearchFilter = (searchFilter: SearchFilter) => ({
  isFavoriteBroker: undefined,
  isOnboarded: undefined,
  company: { ...searchFilter.company, types: CompanyFilterType.All, isOnboarded: undefined },
  equipmentSpecifications: undefined,
  hasCommodity: undefined,
  hasRate: undefined,
  hasTeam: undefined,
  loadSize: undefined,
  includeLoadsWithoutLength: undefined,
  includeLoadsWithoutWeight: undefined,
  minTransCreditRating: undefined,
  maxTransCreditRating: undefined,
  minTransCreditDaysToPay: undefined,
  maxTransCreditDaysToPay: undefined,
  minMileage: undefined,
  maxMileage: undefined,
  hasMessaging: undefined,
  hasBidding: undefined,
  minAge: undefined,
  maxAge: undefined,
});

export const selectedFiltersCount = memoizeOne((filterSections: FilterSection[]): number =>
  reduce(filterOptionsFromSection(filterSections), (count, option) => (option.isSelected ? count + 1 : count), 0)
);

export const filterOptionsFromSection = memoizeOne((filterSections: FilterSection[]): FilterOption[] =>
  reduce(filterSections, (combinedOptions, section) => [...combinedOptions, ...section.options], [])
);

export const selectedFilterOptions = memoizeOne((filterOptions: FilterOption[]): FilterOption[] =>
  filter(filterOptions, (option) => option.isSelected)
);

export const filterSpecificValuesByType = memoizeOne(
  (section: FilterSection[], filterType: FilterType | undefined): string[] => {
    if (!filterType) {
      return [];
    }
    const filterOption = find(filterOptionsFromSection(section), (option) => option.filterType === filterType);
    return filterOption?.filterSpecificInput?.values ?? [];
  }
);

const normalizeSearch = <T extends LoadSearchRequest | SearchFilter>(search: T): T => ({
  ...search,
  loadSize: search.loadSize && search.loadSize !== LoadSize.None ? toUpper(search.loadSize) : undefined,
  equipmentSpecifications:
    search.equipmentSpecifications !== EquipmentSpecification.None ? search.equipmentSpecifications : undefined,
});

export const isSearchRequestsFiltersEquivalent = (l: LoadSearchRequest, r: LoadSearchRequest) =>
  l.isOnboarded === r.isOnboarded &&
  l.isFavoriteBroker === r.isFavoriteBroker &&
  (l.company?.types === r.company?.types ||
    (l.company?.types === undefined && r.company?.types === CompanyFilterType.All) ||
    (l.company?.types === CompanyFilterType.All && r.company?.types === undefined)) &&
  l.equipmentSpecifications === r.equipmentSpecifications &&
  l.hasCommodity === r.hasCommodity &&
  l.hasRate === r.hasRate &&
  l.hasTeam === r.hasTeam &&
  l.loadSize === r.loadSize &&
  (l.includeLoadsWithoutLength === r.includeLoadsWithoutLength ||
    (l.includeLoadsWithoutLength !== false && r.includeLoadsWithoutLength !== false)) &&
  (l.includeLoadsWithoutWeight === r.includeLoadsWithoutWeight ||
    (l.includeLoadsWithoutWeight !== false && r.includeLoadsWithoutWeight !== false)) &&
  l.minTransCreditRating === r.minTransCreditRating &&
  l.maxTransCreditRating === r.maxTransCreditRating &&
  l.minTransCreditDaysToPay === r.minTransCreditDaysToPay &&
  l.maxTransCreditDaysToPay === r.maxTransCreditDaysToPay &&
  l.minMileage === r.minMileage &&
  l.maxMileage === r.maxMileage &&
  l.hasMessaging === r.hasMessaging &&
  l.hasBidding === r.hasBidding &&
  l.minAge === r.minAge &&
  l.maxAge === r.maxAge;

export const shouldShowItems = (filterType: FilterType) =>
  filterType === FilterType.TrailerSpecifications ||
  filterType === FilterType.PostedAge ||
  filterType === FilterType.CreditRating ||
  filterType === FilterType.DaysToPay ||
  filterType === FilterType.TripLength;

import React, { RefObject, useEffect, useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { LocationTypes } from '@/model';
import { fetchLocation } from '@/reduxStore/epic/LocationEpic';
import { setSelectedStates, toggleRegionsPicker } from '@/reduxStore/epic/LocationPickerEpic';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { treatUndefinedAsZero } from '@common/helper';
import { CountriesWithAnywhere } from '@common/helper/LocationPickerHelper';
import {
  LoadGeolocation,
  LoadLocation,
  LocationSuggestion,
  LocationType,
  RecentLocation,
  RecentLocationType,
} from '@common/model';
import { useDidUpdate } from '@common/util/hooks';
import CountryRadioGroup from '@component/coutryRadioGroup/CountryRadioGroup';
import { MinHeightForDropdownList } from '@component/dropdown/DropdownList';
import { LocationDropdownList } from '@component/dropdown/LocationDropdownList';
import { ApplyButtonFooter } from '@component/footers';
import { MultiSelect, OptionType } from '@component/multiSelect/MultiSelect';
import {
  showLocationDeniedDialog,
  showLocationTimeoutDialog,
  showLocationUnavailableDialog,
} from '@component/panels/findLoads/searchPanel/GeolocationPopups';
import { CurrentLocationButton } from '@component/panels/modalPanel/CurrentLocationButton';
import { HomeLocationButton } from '@component/panels/modalPanel/HomeLocationButton';
import { StatesPicker } from '@component/panels/modalPanel/StatesPicker';
import { StatesProvincesRegionsMapButton } from '@component/panels/modalPanel/StatesProvincesRegionsMapButton';
import { RecentSearchLocations } from '@component/panels/pickerPanels/RecentSearchLocations';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { useScrollIntoView } from '@component/scrollView';
import { SectionWithoutShadow } from '@component/section';
import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { formatStringsToOptions } from '@util/Formatter';
import { getGeolocation } from '@util/GeolocationHelper';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

const COUNTRIES = [
  { name: `${t(T.findLoads_findLoads_anywhere)}`, id: CountriesWithAnywhere.ANYWHERE },
  { name: `${t(T.findLoads_findLoads_usa)}`, id: CountriesWithAnywhere.US },
  { name: `${t(T.findLoads_findLoads_canada)}`, id: CountriesWithAnywhere.CA },
];

const ANYWHERE = [{ name: `${t(T.findLoads_findLoads_anywhere)}`, id: CountriesWithAnywhere.ANYWHERE }];

interface LocationInputProps {
  isLoadPostingForm?: boolean;
  isMobileView?: boolean;
}
const LocationInputWrapper = withTheme()(styled.div`
  padding: ${(props: ThemeProps & LocationInputProps) =>
    props.isMobileView
      ? 0
      : `${Spacing.ElementPaddingVertical}px ${Spacing.ScreenSide}px 18px ${Spacing.ScreenSide}px`};
  border-bottom: ${(props: ThemeProps & LocationInputProps) =>
    props.isLoadPostingForm ? 0 : `1px solid ${props.theme.palette.background.smoke}`};
  display: flex;
`);

const TextFieldSection = styled(SectionWithoutShadow)`
  background-color: ${Color.GRAY_LIGHT};
  padding-bottom: 0px;
`;

interface Props {
  locationType: LocationTypes;
  value: string;
  title?: string;
  placeholder?: string;
  states: string[];
  maxStatesCount?: number;
  recentLocationTypes?: RecentLocationType[];
  onlyShowAnywhere?: boolean;
  onValueChange: (value: string) => void;
  onSelectionChange: (location: LocationSuggestion) => void;
  onRecentLocationSelect?: (recentLocation: RecentLocation) => void;
  onStatesSelect?: (states: string[]) => void;
  onCountrySelect?: (country: CountriesWithAnywhere) => void;
  onCurrentLocationSelect?: (location: LoadLocation) => void;
  onHomeLocationSelect?: (location: LoadLocation) => void;
  onSelectStatesOnMapClick?: () => void;
  toggleHomePick: () => void;
  isMobileView?: boolean;
  isLoadPostingForm?: boolean;
  error?: boolean;
  helperText?: boolean | JSX.Element;
  inputRef?: RefObject<HTMLElement>;
  id?: string;
  initialValue?: string;
  shouldAvoidDownshiftStateResetOnBlur?: boolean;
  required?: boolean;
  inputStyle?: React.CSSProperties;
}

export const LocationPicker: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const homeLocation = useSelector((state) => state.loadSearchSettings.homeLocation);
  const selectedStates = useSelector((state) => state.locationPickerState.selectedStates);

  const shouldSetTrueDefaultAutofocus = isEmpty(props.states);

  const [shouldAutoFocus, setShouldAutofocus] = useState(shouldSetTrueDefaultAutofocus);
  const [isFocused, setIsFocused] = useState(false);

  const statesPickerRef = useRef<HTMLDivElement>(null);

  const scrollIntoView = useScrollIntoView();

  const onSelectCurrentLocation = useSelectCurrentLocation(props.onCurrentLocationSelect);

  const shouldShowStates = props.onStatesSelect !== undefined;
  const showContent = !isFocused || isEmpty(props.value);

  useEffect(() => {
    dispatch(setSelectedStates(props.states));
  }, [props.states]);

  const onClear = () => {
    props.onValueChange('');
    if (!isEmpty(selectedStates) && shouldShowStates) {
      dispatch(setSelectedStates([]));
      setShouldAutofocus(true);
    }
  };

  const onStatesChange = (states: string[]) => {
    dispatch(setSelectedStates(states));
    if (isEmpty(states)) {
      props.onValueChange('');
      setShouldAutofocus(false);
    }
  };

  const onApplyStates = () => {
    if (props.maxStatesCount && selectedStates.length > props.maxStatesCount) {
      props.onValueChange('');
      dispatch(toggleRegionsPicker(true));
      scrollIntoView(statesPickerRef.current);
      dispatch(showWarningSnackbar(treatUndefinedAsZero(selectedStates.length), props.maxStatesCount));
      return;
    }
    props.onStatesSelect?.(selectedStates);
  };

  const onHomeLocationClick = () => {
    if (homeLocation && homeLocation.states) {
      props.onHomeLocationSelect?.(homeLocation);
    }
  };

  const title =
    props.title ??
    (props.locationType === LocationTypes.Pickup
      ? t(T.modalPanels_LocationPicker_Pickup)
      : t(T.modalPanels_LocationPicker_DropOff));

  const placeholder =
    props.placeholder ??
    (shouldShowStates ? t(T.modalPanels_LocationPicker_CityStateZip) : t(T.modalPanels_LocationPicker_CityOrZip));

  return (
    <>
      <MinHeightForDropdownList isLoadPostingForm={props.isLoadPostingForm}>
        <TextFieldSection isFirst={false} isLast={false}>
          <LocationInputWrapper
            style={props.inputStyle}
            isLoadPostingForm={props.isLoadPostingForm}
            isMobileView={props.isMobileView}
          >
            {!isEmpty(selectedStates) ? (
              <MultiSelect
                id={props.id ?? 'location_picker'}
                label={title}
                options={formatStringsToOptions(selectedStates, OptionType.State)}
                control={{ icon: icons.close, id: 'location_picker_clear_icon' }}
                onControlClick={onClear}
                error={props.error}
                helperText={props.helperText}
              />
            ) : (
              <LocationDropdownList
                id={props.id ?? 'lc_picker'}
                title={title}
                isWithoutMargin={true}
                onSelectionChange={props.onSelectionChange}
                onInputValueChange={props.onValueChange}
                value={props.value}
                onClear={onClear}
                withCustomScroll={true}
                placeholder={placeholder}
                shouldIncludeStates={shouldShowStates}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                autoFocus={shouldAutoFocus && !props.isLoadPostingForm}
                hasError={props.error ?? false}
                inputRef={props.inputRef}
                initialValue={props.initialValue}
                shouldAvoidDownshiftStateResetOnBlur={props.shouldAvoidDownshiftStateResetOnBlur}
                required={props.required}
              />
            )}
          </LocationInputWrapper>
          {showContent && (
            <>
              {props.onCountrySelect && (
                <CountryRadioGroup
                  countries={props.onlyShowAnywhere ? ANYWHERE : COUNTRIES}
                  handleChange={props.onCountrySelect}
                />
              )}
              {props.onCurrentLocationSelect && <CurrentLocationButton onClick={onSelectCurrentLocation} />}
              {props.onHomeLocationSelect && (
                <HomeLocationButton
                  onClick={homeLocation ? onHomeLocationClick : props.toggleHomePick}
                  onEdit={props.toggleHomePick}
                />
              )}
              {props.onSelectStatesOnMapClick && (
                <StatesProvincesRegionsMapButton onClick={props.onSelectStatesOnMapClick} />
              )}
              {shouldShowStates && (
                <StatesPicker
                  ref={statesPickerRef}
                  states={selectedStates}
                  onChange={onStatesChange}
                  maxCount={props.maxStatesCount}
                />
              )}
              {props.onRecentLocationSelect ? (
                <RecentSearchLocations
                  onLocationSelect={props.onRecentLocationSelect}
                  locationTypes={props.recentLocationTypes}
                />
              ) : null}
            </>
          )}
        </TextFieldSection>
      </MinHeightForDropdownList>
      {!isEmpty(selectedStates) ? <ApplyButtonFooter onClick={onApplyStates} /> : null}
    </>
  );
};

const useSelectCurrentLocation = (onSelected: ((location: LoadLocation) => void) | undefined) => {
  const dispatch = useDispatch();
  const popupContext = usePopup();
  const currentLocation = useSelector((state) => state.locationState.reverseGeolocation?.payload);
  const error = useSelector((state) => state.locationState.reverseGeolocation?.error);
  const [wasSelected, setWasSelected] = useState(false);

  useDidUpdate(() => {
    if (error) {
      dispatch(showSnackbar({ message: t(T.common_currentLocationError) }));
    }
  }, [error]);

  useEffect(() => {
    if (currentLocation?.city && currentLocation?.states && wasSelected && onSelected) {
      const location: LoadGeolocation = {
        type: LocationType.GEOLOCATION,
        city: currentLocation?.city,
        states: currentLocation?.states,
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
      };
      onSelected?.(location);
    }
  }, [currentLocation, wasSelected]);

  return () => {
    setWasSelected(true);
    getGeolocation({
      onSuccess: (location) => dispatch(fetchLocation(location)),
      onDenied: () => showLocationDeniedDialog(popupContext),
      onUnavailable: () => showLocationUnavailableDialog(popupContext),
      onTimeout: () => showLocationTimeoutDialog(popupContext),
    });
  };
};

const showWarningSnackbar = (selectedCount: number, maxCount: number) =>
  showSnackbar({
    message: t(T.modalPanels_LocationPicker_WarningMessage, {
      selectedCount: selectedCount,
      maxCount: maxCount,
    }),
  });

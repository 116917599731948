import { ApisauceInstance } from 'apisauce';
import { AxiosRequestConfig } from 'axios';

import { LBHeaders } from '@common/api';
import { MimeType } from '@common/helper';
import { GenId } from '@util/GenId';
import { deviceID } from '@webApi/CookiesHelper';

import { AppVersion } from '../util/AppVersionHelper';

export const defaultMemberApiHeaders = {
  '123LB-Api-Version': '1.3',
  '123LB-MEM-User-Version': AppVersion,
  Accept: MimeType.json,
};

export const defaultIntgApiHeaders = {
  Accept: MimeType.json,
};

export const updateMemberApiHeaders = (axiosConfig?: AxiosRequestConfig) => {
  const customHeaders = {
    [LBHeaders.DeviceID]: deviceID(),
    '123LB-Correlation-Id': GenId.generateCorrelationId(),
  };
  let config: AxiosRequestConfig;
  if (axiosConfig) {
    axiosConfig.headers = { ...axiosConfig.headers, ...customHeaders };
    config = axiosConfig;
  } else {
    config = {
      headers: customHeaders,
    };
  }
  return config;
};

export const configureApi = (api: ApisauceInstance) => {
  if (process.env.NODE_ENV === 'development') {
    // build process will strip out unused code in production,
    // hence the special usage of dynamic import
    import('@util/ReactotronConfig').then(({ apisauce }) => {
      // Apisauce has a feature where you can attach a handler to watch
      // all requests/response flowing through your api.  You can hook this up:
      api.addMonitor(apisauce);
    });
  }
};

import React from 'react';

import { ApiErrorCode } from '@common/api';
import { getVendorBidFailedPopupConfig } from '@common/helper';
import {
  FormContainer,
  PopUpButton,
  TextContainer,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { BasePopup } from '@component/popup/BasePopup';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text';
import { T, t } from '@translate';

interface Props {
  errorCode?: ApiErrorCode;
}

export const BidFailedPopUp: React.FC<Props> = ({ errorCode }) => {
  const { closingPopup } = usePopup();

  const { title, content } = getVendorBidFailedPopupConfig(errorCode);

  return (
    <BasePopup title={title} onClose={closingPopup}>
      <TextContainer>
        <Text id="replace_bid" textStyle={TextStyle.BodyLarge}>
          {content}
        </Text>
      </TextContainer>
      <FormContainer>
        <PopUpButton
          id="submit_new_bid"
          onClick={closingPopup}
          text={t(T.common_ok)}
          variant={'outlined'}
          color={'secondary'}
        />
      </FormContainer>
    </BasePopup>
  );
};

import { toLower } from 'lodash';

export enum PrivateMemberSortDirection {
  ascending = 'ascending',
  descending = 'descending',
}

export const getLoadBoardType = (type: string): LoadBoardType =>
  toLower(type) === 'public' ? LoadBoardType.public : LoadBoardType.private;

export enum LoadBoardType {
  private = 'private',
  public = 'public',
}

interface ModifyPrivateNetwork {
  id: string;
}

export interface DeletePrivateNetworkMembers extends ModifyPrivateNetwork {
  usDotNumber: string[];
}

export interface AddPrivateNetworkMembers extends ModifyPrivateNetwork {
  validDotNumbers: string[];
  invalidDotNumbers: string[];
}

export interface InvalidMembersResponse {
  invalidDotNumbers: string[];
}

export interface FetchPrivateNetworkMembers extends ModifyPrivateNetwork {
  sortOrder: PrivateMemberSortDirection;
}

export interface FetchLoadBoardResponse extends ModifyPrivateNetwork {
  type: string;
}

export interface WhiteListResponse {
  dotNumbers: string[];
}

import { useLayoutEffect } from 'react';

import { forEach, includes } from 'lodash';
import { useDispatch } from 'react-redux';

const DATA_ATTRIBUTE_NAME = 'data-src';

// dataSource is required to flag links and scripts that should be removed from DOM in case if we go to other page
// and these scripts and links are not in use anymore
export const useLinksAppending = (
  htmlToAppend: Document | undefined,
  shouldAppendLinks: boolean,
  appendLinks: () => void,
  dataSource?: string
) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (shouldAppendLinks && htmlToAppend) {
      const head = document.getElementsByTagName('head')[0];

      //Append links from external HTML to our head
      const links = htmlToAppend.getElementsByTagName('link');
      //get list of links from head that were already appended before
      const headLinks = head.querySelectorAll(`link[${DATA_ATTRIBUTE_NAME}]`);
      forEach(links, (link) => {
        if (dataSource) {
          link.setAttribute(DATA_ATTRIBUTE_NAME, dataSource);
        }
        //check if we already have this link in head section
        const isExisting = doesElementExist(headLinks, link, 'href');
        if (!isExisting) {
          const clone = link.cloneNode();
          head.appendChild(clone);
        }
      });

      //Append scripts from external HTML to our head
      const scripts = htmlToAppend.getElementsByTagName('script');
      //get list of scripts from head that were already appended before
      const headScripts = head.querySelectorAll(`script[${DATA_ATTRIBUTE_NAME}]`);
      forEach(scripts, (script) => {
        //check if we already have this script in head section
        const isExisting = doesElementExist(headScripts, script, 'src');
        if (!isExisting) {
          const src = script.src;
          const script1 = document.createElement('script');
          script1.async = false;
          script1.innerText = script.innerText;
          script1.setAttribute(DATA_ATTRIBUTE_NAME, dataSource ?? '');
          if (src) {
            script1.src = src;
          }
          head.appendChild(script1);
        }
      });

      //Append styles from external HTML to our head
      const styles = htmlToAppend.getElementsByTagName('style');
      forEach(styles, (style) => {
        const clone = style.cloneNode();
        head.appendChild(clone);
      });
      dispatch(appendLinks());
    }
  }, [htmlToAppend, shouldAppendLinks]);
};

//this is designed to clear links to external css and scripts that are not in use
export const useClearLinksFromPreviousSource = (shouldAppendLinks: boolean, basePath: string) => {
  useLayoutEffect(() => {
    if (shouldAppendLinks) {
      const head = document.getElementsByTagName('head')[0];
      //get list of links from head that were already appended before
      const links = head.querySelectorAll(`link[${DATA_ATTRIBUTE_NAME}]`);
      links.forEach((link) => {
        const dataSrc = link.getAttribute(DATA_ATTRIBUTE_NAME);
        const href = link.getAttribute('href');
        //check if this link comes from our Azure storage and if source HTML is not the same as current
        if (includes(href, basePath) && dataSrc !== null) {
          head.removeChild(link);
        }
      });

      //get list of scripts from head that were already appended before
      const scripts = head.querySelectorAll(`script[${DATA_ATTRIBUTE_NAME}]`);
      scripts.forEach((script) => {
        const dataSrc = script.getAttribute(DATA_ATTRIBUTE_NAME);
        const src = script.getAttribute('src');
        if (includes(src, basePath) && dataSrc !== null) {
          head.removeChild(script);
        }
      });
    }
  });
};

//check if we already have element in a list
const doesElementExist = (sourceList: NodeListOf<Element>, element: Element, comparisonAttributeName: string) => {
  let foundResult = false;
  forEach(sourceList, (listElement) => {
    if (listElement.getAttribute(comparisonAttributeName) === (element as any)[comparisonAttributeName]) {
      foundResult = true;
    }
  });
  return foundResult;
};

import moment from 'moment';

import { formatMomentYYYYMMDD, getTodayDateYYYYMMDD } from '@common/helper';
import { RateCheckTimeSpan } from '@common/model';

interface BrokerLoadDaysMetric {
  postedLoadsCount: number;
}

interface BrokerLoadMetricsMetadata {
  totalPostedLoads: number;
}

interface BrokerLoadMetricsResults {
  sunday: BrokerLoadDaysMetric;
  monday: BrokerLoadDaysMetric;
  tuesday: BrokerLoadDaysMetric;
  wednesday: BrokerLoadDaysMetric;
  thursday: BrokerLoadDaysMetric;
  friday: BrokerLoadDaysMetric;
  saturday: BrokerLoadDaysMetric;
}

export interface BrokerLoadMetricsResponse {
  results?: BrokerLoadMetricsResults;
  metadata?: BrokerLoadMetricsMetadata;
}

interface CarrierLoadMetricsMetadata {
  totalBooked: number;
  totalDelivered: number;
}

interface CarrierLoadDaysMetric {
  booked: number;
  delivered: number;
}

interface CarrierLoadMetricsResults {
  sunday: CarrierLoadDaysMetric;
  monday: CarrierLoadDaysMetric;
  tuesday: CarrierLoadDaysMetric;
  wednesday: CarrierLoadDaysMetric;
  thursday: CarrierLoadDaysMetric;
  friday: CarrierLoadDaysMetric;
  saturday: CarrierLoadDaysMetric;
}

export interface CarrierLoadMetricsResponse {
  results?: CarrierLoadMetricsResults;
  metadata?: CarrierLoadMetricsMetadata;
}

export interface LoadMetricsHistoryRequest {
  startDate: string;
  endDate: string;
  timeSpan?: RateCheckTimeSpan;
  fields?: string;
}

export interface BrokerLoadMetricsHistoryResult {
  startDate: string;
  endDate: string;
  posted: number;
}

export interface BrokerLoadMetricsHistoryResponse {
  results: BrokerLoadMetricsHistoryResult[];
  timeSpan?: RateCheckTimeSpan;
}

export interface CarrierLoadMetricsHistoryResult {
  startDate: string;
  endDate: string;
  booked: number;
  delivered: number;
}

export interface CarrierLoadMetricsHistoryResponse {
  results: CarrierLoadMetricsHistoryResult[];
  timeSpan?: RateCheckTimeSpan;
}

export const createLoadMetricsHistoryRequest = (fields: string = 'all'): LoadMetricsHistoryRequest => ({
  startDate: formatMomentYYYYMMDD(moment().subtract(1, 'year')),
  endDate: getTodayDateYYYYMMDD(),
  timeSpan: RateCheckTimeSpan.Monthly,
  fields: fields,
});

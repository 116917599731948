import { compact, endsWith, find, first, includes, indexOf, map, max, reduce, replace, split, toLower } from 'lodash';
import picomatch from 'picomatch-browser';

const WILDCARD_SIGN = '*';
const DOUBLE_WILDCARD_SIGN = '**';

interface FeedMappingData {
  videoTabSourceRoute?: string;
  helpTabSourceRoute?: string;
  carrierPromotionSourceRoute?: string;
  brokerPromotionSourceRoute?: string;
  isFeedButtonEnabled?: boolean;
  isVideoButtonEnabled?: boolean;
  isHelpButtonEnabled?: boolean;
}

//this interface describes one row in csv mapping file
interface FeedMappingRow {
  page_url_pattern: string;
  embed_video_page_url: string;
  embed_help_page_url: string;
  carrier_promotion_content_url: string;
  broker_promotion_content_url: string;
  isFeedButtonEnabled: string;
  isVideoButtonEnabled: string;
  isHelpButtonEnabled: string;
  commentary?: string;
}

export const getFeedMappingDataForUrl = (csvRows: object[] | undefined, url: string): FeedMappingData | undefined => {
  if (!csvRows) {
    return undefined;
  }
  //in some cases double slash occurs in the url
  const urlWithoutDoubleSlash = replace(url, '//', '/');
  //trying to find exact matching of url pattern from file with application url
  const exactMatch = findExactRouteMatch(csvRows, urlWithoutDoubleSlash);
  if (!exactMatch) {
    //if there is no exact match trying to find all wildcard rules that match url
    const matchedWildcardRules = findWildcardRulesMatching(csvRows, urlWithoutDoubleSlash);
    //trying to find most suitable rule
    const mostSuitableRule = findMostSuitableRule(matchedWildcardRules, urlWithoutDoubleSlash);
    //getting csv file data by found rule
    const mostSuitableRuleMatch = find(csvRows, (row: FeedMappingRow) => row.page_url_pattern === mostSuitableRule);
    return getFeedMappingDataFromFeedMappingRow(mostSuitableRuleMatch as FeedMappingRow);
  } else {
    return getFeedMappingDataFromFeedMappingRow(exactMatch as FeedMappingRow);
  }
};

const findExactRouteMatch = (csvRows: object[], url: string) =>
  find(csvRows, (row: FeedMappingRow) => row.page_url_pattern === url);

const isWildcardRule = (url: string) => includes(url, `/${WILDCARD_SIGN}`) || includes(url, `/${DOUBLE_WILDCARD_SIGN}`);

const isWildcardRuleMatchingUrl = (url: string, wildcardRule: string) => picomatch.isMatch(url, wildcardRule);

const findWildcardRulesMatching = (csvRows: object[], url: string) =>
  reduce(
    csvRows,
    (result: string[], row: FeedMappingRow) => {
      if (!isWildcardRule(row.page_url_pattern)) {
        return result;
      }
      if (isWildcardRuleMatchingUrl(url, row.page_url_pattern)) {
        return [...result, row.page_url_pattern];
      }
      return result;
    },
    []
  );

const findMostSuitableRule = (matchedWildcardRules: string[], url: string) => {
  const urlChunks = compact(split(url, '/'));
  const matchedPositions = map(matchedWildcardRules, (rule) => {
    return getUpperChunkPosition(urlChunks, rule);
  });
  const indicesOfMostSuitableRules = reduce(
    matchedPositions,
    (rulePositions, element, index) => {
      if (element === max(matchedPositions)) {
        return [...rulePositions, index];
      }
      return rulePositions;
    },
    []
  );
  if (indicesOfMostSuitableRules.length === 1) {
    return matchedWildcardRules[indicesOfMostSuitableRules[0]];
  } else {
    //in some cases we can have 2 similar rules where the first with end with /* and the second with /**.
    //in comparison /* rule is more accurate than /**
    const mostSuitableRules = map(indicesOfMostSuitableRules, (index) => matchedWildcardRules[index]);
    const ruleWithSingleWildcard = find(mostSuitableRules, (rule) => endsWith(rule, `/${WILDCARD_SIGN}`));
    if (ruleWithSingleWildcard) {
      return ruleWithSingleWildcard;
    }
    const ruleWithDoubleWildcard = find(mostSuitableRules, (rule) => endsWith(rule, `/${DOUBLE_WILDCARD_SIGN}`));
    if (ruleWithDoubleWildcard) {
      return ruleWithDoubleWildcard;
    }
    return first(mostSuitableRules);
  }
};

const getUpperChunkPosition = (chunks: string[], rule: string) => {
  const ruleUrlChunks = compact(split(rule, '/'));
  return reduce(
    chunks,
    (position, chunk) => {
      const chunkPosition = indexOf(ruleUrlChunks, chunk);
      if (chunkPosition > position) {
        return chunkPosition;
      }
      return position;
    },
    -1
  );
};

const isButtonEnabled = (buttonFlagString: string) => {
  return toLower(buttonFlagString) === 'true';
};

/**
 * This function is getting data from csv file row to use further in the application */
const getFeedMappingDataFromFeedMappingRow = (row: FeedMappingRow | undefined) => {
  if (!row) {
    return undefined;
  }
  return {
    videoTabSourceRoute: row.embed_video_page_url,
    helpTabSourceRoute: row.embed_help_page_url,
    carrierPromotionSourceRoute: row.carrier_promotion_content_url,
    brokerPromotionSourceRoute: row.broker_promotion_content_url,
    isFeedButtonEnabled: isButtonEnabled(row.isFeedButtonEnabled),
    isVideoButtonEnabled: isButtonEnabled(row.isVideoButtonEnabled),
    isHelpButtonEnabled: isButtonEnabled(row.isHelpButtonEnabled),
  };
};

import { ContactSupportData, TrackingUnsubscribeData, TrialExtensionData, UnsubscribeData } from '@/model/Unsubscribe';
import { BaseClient } from '@common/client/BaseClient';

export class UnsubscribeClient extends BaseClient {
  requestTrialExtension$ = (data: TrialExtensionData) =>
    this.api.post$('/memberships/unsubscribe/requestTrialExtension', data);

  contactSupport$ = (data: ContactSupportData) => this.api.post$('/memberships/unsubscribe/scheduleCall ', data);

  unsubscribeRequest$ = (data: UnsubscribeData) => this.api.post$('/memberships/unsubscribe/finalize ', data);

  trackingUnsubscribeData$ = (data: TrackingUnsubscribeData) =>
    this.api.post$('/tracking/membership/unsubscribe ', data);

  // @TODO: remove since we moved this to SystemTimeEpic in common to make it available in MOB
  getSystemTime$ = () => this.api.get$('/system/info/time', {});
}

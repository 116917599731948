import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { WelcomebackCampaignClient } from '@/client/WelcomebackCampaignClient';
import { Api } from '@common/api';
import { SimplifiedEquipment } from '@common/model';
import { createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface SimpleLocation {
  city: string;
  state: string;
  country: string;
  deadhead: number;
}

export interface LoadPreview {
  id: string;
  origin: SimpleLocation;
  destination: SimpleLocation;
  equipments: SimplifiedEquipment[];
  loadSize: string;
  weight: number;
  length: number;
  pickupDates: string[];
}

const fetchLoadSearchPreviewAction = createApiAction<string, LoadPreview[]>('FETCH_LOAD_SEARCH_PREVIEW');

export const fetchLoadSearchPreview = (token: string) => fetchLoadSearchPreviewAction.fetchAction(token);

export interface WelcomebackCampaignState {
  loadSearchPreviewList: LoadPreview[];
  isFetchingLoads: boolean;
  didFetchLoads?: boolean;
}

const initialState: WelcomebackCampaignState = {
  loadSearchPreviewList: [],
  isFetchingLoads: false,
  didFetchLoads: undefined,
};

export const welcomebackCampaignReducer = createMergedReducer(initialState, [
  fetchLoadSearchPreviewAction.initiateCase((state) => {
    state.didFetchLoads = undefined;
    state.isFetchingLoads = true;
  }),
  fetchLoadSearchPreviewAction.completeCase((state, action) => {
    state.isFetchingLoads = false;
    state.didFetchLoads = action.response.success;
    if (action.response.success) {
      state.loadSearchPreviewList = action.response.payload;
    }
  }),
]);

export const createWelcomebackCampaignEpic = (api: Api) => {
  const client = new WelcomebackCampaignClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(fetchLoadSearchPreviewAction.createEpic$(action$, client.getLoadSearchPreview$));
};

import React, { Component, RefObject } from 'react';

import styled from 'styled-components';

import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';

export const MaterialTextButton = styled(Button)`
  && {
    font-weight: bold;
    font-size: ${FontSize.Content}px;
    color: ${Color.ORANGE_MAIN};
    text-transform: none;
  }
`;

interface Props extends ButtonProps {
  innerRef?: ((instance: any) => void) | RefObject<HTMLElement | SVGElement | Component>;
}

export const TextButton: React.FC<Props> = (props) => <MaterialTextButton {...props} disableRipple={true} />;

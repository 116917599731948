import { Geolocation } from '@common/model';

interface Actions {
  onSuccess: (location: Geolocation) => void;
  onDenied: () => void;
  onTimeout?: () => void;
  onUnavailable?: () => void;
}

export const getGeolocation = (actions: Actions) => {
  if (!('geolocation' in window.navigator)) {
    if (actions.onUnavailable) {
      actions.onUnavailable();
    }
    return;
  }

  window.navigator.geolocation.getCurrentPosition(
    (position) => {
      const { latitude, longitude } = position.coords;
      actions.onSuccess(new Geolocation(latitude, longitude));
    },
    (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        return actions.onDenied ? actions.onDenied() : undefined;
      }
      if (error.code === error.TIMEOUT) {
        return actions.onTimeout ? actions.onTimeout() : undefined;
      }
      return actions.onUnavailable ? actions.onUnavailable() : undefined;
    }
  );
};

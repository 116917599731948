import { defaultTo, join, toLower } from 'lodash';

import { getFlatRateAmount, getPricePerMileRate, stringifyEquipments } from '@common/helper';
import { Load, LoadSortCategory, Location } from '@common/model';

import { Sort } from './Sort';

class SortLoad extends Sort<Load, LoadSortCategory> {
  baseSortCategories = [
    LoadSortCategory.PICKUP_DATE,
    LoadSortCategory.ORIGIN,
    LoadSortCategory.AGE,
    LoadSortCategory.ID,
  ];

  valueForCategory = (category: string, load: Load, sortAsc: boolean) => {
    switch (category) {
      case LoadSortCategory.ORIGIN:
        return parseLocation(load.originLocation);
      case LoadSortCategory.ORIGIN_CITY:
        return parseLocationCity(load.originLocation);
      case LoadSortCategory.ORIGIN_STATE:
        return parseLocationState(load.originLocation);
      case LoadSortCategory.ORIGIN_ZIPCODE:
        return parseLocationZip(load.originLocation);
      case LoadSortCategory.DESTINATION:
        return parseLocation(load.destinationLocation);
      case LoadSortCategory.DESTINATION_CITY:
        return parseLocationCity(load.destinationLocation);
      case LoadSortCategory.DESTINATION_STATE:
        return parseLocationState(load.destinationLocation);
      case LoadSortCategory.DESTINATION_ZIPCODE:
        return parseLocationZip(load.destinationLocation);
      case LoadSortCategory.DESTINATION_RADIUS:
        return load.metadata && load.metadata.userdata && load.metadata.userdata.destinationDeadhead
          ? load.metadata.userdata.destinationDeadhead.value
          : undefined;
      case LoadSortCategory.PICKUP_DATE:
        return parseLoadDate(load);
      case LoadSortCategory.MESSAGES_SENT:
        return parseLoadMessageSent(load);
      case LoadSortCategory.EQUIPMENT:
        return load.sortEquipCode || parseLoadEquipment(load, sortAsc);
      case LoadSortCategory.AGE:
        return defaultTo(load.age, 0) - defaultTo(load.fetchedAt, 0);
      case LoadSortCategory.ID:
        return load.id;
      case LoadSortCategory.SIZE:
        return load.loadSize;
      case LoadSortCategory.LENGTH:
        return load.length;
      case LoadSortCategory.WEIGHT:
        return load.weight;
      case LoadSortCategory.COMPANY:
        return load.poster ? load.poster.name : undefined;
      case LoadSortCategory.ORIGIN_RADIUS:
      case LoadSortCategory.DEADHEAD:
        return load.metadata && load.metadata.userdata && load.metadata.userdata.originDeadhead
          ? load.metadata.userdata.originDeadhead.value
          : undefined;
      case LoadSortCategory.MILEAGE:
        return load.computedMileage;
      case LoadSortCategory.AMOUNT:
        return getFlatRateAmount(load);
      case LoadSortCategory.PRICE_PER_MILE:
        return getPricePerMileRate(load);
      case LoadSortCategory.CREDIT_RATING:
      case LoadSortCategory.DAYS_TO_PAY:
      case LoadSortCategory.TRANS_CREDIT_RATING:
      case LoadSortCategory.TRANS_CREDIT_DAYS_TO_PAY:
        return undefined;
    }
    return undefined;
  };

  categoryIsNumber = (category: string) => {
    switch (category) {
      case LoadSortCategory.DESTINATION_RADIUS:
      case LoadSortCategory.PICKUP_DATE:
      case LoadSortCategory.MESSAGES_SENT:
      case LoadSortCategory.AGE:
      case LoadSortCategory.LENGTH:
      case LoadSortCategory.WEIGHT:
      case LoadSortCategory.ORIGIN_RADIUS:
      case LoadSortCategory.DEADHEAD:
      case LoadSortCategory.MILEAGE:
      case LoadSortCategory.AMOUNT:
      case LoadSortCategory.CREDIT_RATING:
      case LoadSortCategory.DAYS_TO_PAY:
      case LoadSortCategory.TRANS_CREDIT_RATING:
      case LoadSortCategory.TRANS_CREDIT_DAYS_TO_PAY:
      case LoadSortCategory.PRICE_PER_MILE:
        return true;
    }
    return false;
  };
}

export const LoadSort: Sort<Load, LoadSortCategory> = new SortLoad();

function parseLocation(location: Location | undefined): string {
  let radius = 0;
  let city = '';
  let state = '';
  if (location) {
    if (location.radius) {
      radius = location.radius;
    }
    if (location.address) {
      city = location.address.city || '';
      state = location.address.state || '';
    }
  }
  return `${radius}${city}${state}`;
}

function parseLocationCity(location: Location | undefined): string | undefined {
  if (location && location.address && location.address.city) {
    return toLower(location.address.city);
  } else {
    return undefined;
  }
}

function parseLocationState(location: Location | undefined): string | undefined {
  if (location && location.address && location.address.state) {
    return toLower(location.address.state);
  } else {
    return undefined;
  }
}

function parseLocationZip(location: Location | undefined): string | undefined {
  if (location && location.address && location.address.zipCode) {
    return toLower(location.address.zipCode);
  } else {
    return undefined;
  }
}

function parseLoadDate(load: Load): number | undefined {
  if (load.pickupDateTime) {
    return new Date(load.pickupDateTime).getTime();
  } else {
    return undefined;
  }
}

function parseLoadMessageSent(load: Load): number {
  if (load.metadata && load.metadata.messagesSentCounter) {
    return load.metadata.messagesSentCounter;
  } else {
    return -1;
  }
}

function parseLoadEquipment(load: Load, sortAsc: boolean): string | undefined {
  if (load.equipments && load.equipments.length > 0) {
    const equipmentStr = join(stringifyEquipments(load.equipments), ', ');
    if (equipmentStr !== '') {
      return equipmentStr;
    }
  }
  if (sortAsc) {
    return '\uFFFF';
  } else {
    return '';
  }
}

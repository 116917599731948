import React, { useState } from 'react';

import { map, range } from 'lodash';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import styled from 'styled-components';
import 'core-js/proposals/promise-with-resolvers';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const BrowserPDFViewer = styled.object`
  border: 0;
`;

const VerticalSpace = styled.div`
  height: 4px;
`;

interface Props {
  data: string | undefined;
  width: number;
}

export const PDFViewer: React.FC<Props> = (props) => (
  <BrowserPDFViewer id="pdf" data={props.data} type="application/pdf" width="100%" height="100%">
    <FallbackPDFViewer data={props.data} width={props.width} />
  </BrowserPDFViewer>
);

export const FallbackPDFViewer: React.FC<Props> = (props) => {
  const [numPages, setNumPages] = useState(0);
  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: { numPages: number }) => {
    setNumPages(nextNumPages);
  };
  return (
    <Document file={props.data} onLoadSuccess={onDocumentLoadSuccess}>
      {map(range(numPages), (index) => (
        <React.Fragment key={`page_${index + 1}`}>
          <Page pageNumber={index + 1} width={props.width} />
          <VerticalSpace />
        </React.Fragment>
      ))}
    </Document>
  );
};

import { AxiosRequestConfig } from 'axios';
import { Observable } from 'rxjs';

import { Api, ApiOptions, ApiResponse123, ApiService, withMergePatch } from '@common/api';
import { configureApi, defaultMemberApiHeaders, updateMemberApiHeaders } from '@webApi/ApiServiceHelper';
import { handleResponse$ } from '@webApi/ResponseHandler';

const defaultApiOptions: ApiOptions = { shouldPreventLoginRedirect: false, allowUnauthenticated: false };

export class MemberApiService implements Api {
  private api: Api;
  constructor() {
    this.api = new ApiService('/api', defaultMemberApiHeaders, configureApi);
  }

  get$ = <T>(
    url: string,
    params: {},
    axiosConfig?: AxiosRequestConfig,
    options: ApiOptions = defaultApiOptions
  ): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    const response: Observable<ApiResponse123<T>> = this.api.get$(url, params, config);
    return handleResponse$(response, !!options.allowUnauthenticated, options.forceRefreshToken);
  };

  post$ = <T>(
    url: string,
    params: {} | null,
    axiosConfig?: AxiosRequestConfig,
    options: ApiOptions = defaultApiOptions
  ): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    const response: Observable<ApiResponse123<T>> = this.api.post$(url, params, config);
    return handleResponse$(response, !!options.allowUnauthenticated, options.forceRefreshToken);
  };

  put$ = <T>(
    url: string,
    params: {},
    axiosConfig?: AxiosRequestConfig,
    options: ApiOptions = defaultApiOptions
  ): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    const response: Observable<ApiResponse123<T>> = this.api.put$(url, params, config);
    return handleResponse$(response, !!options.allowUnauthenticated, options.forceRefreshToken);
  };

  delete$ = <T>(
    url: string,
    params: {},
    axiosConfig?: AxiosRequestConfig,
    options: ApiOptions = defaultApiOptions
  ): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    const response: Observable<ApiResponse123<T>> = this.api.delete$(url, params, config);
    return handleResponse$(response, !!options.allowUnauthenticated, options.forceRefreshToken);
  };

  patch$ = <T>(
    url: string,
    params: {},
    axiosConfig?: AxiosRequestConfig,
    options: ApiOptions = defaultApiOptions
  ): Observable<ApiResponse123<T>> => {
    const config = updateMemberApiHeaders(axiosConfig);
    const response: Observable<ApiResponse123<T>> = this.api.patch$(url, params, config);
    return handleResponse$(response, !!options.allowUnauthenticated, options.forceRefreshToken);
  };

  mergePatch$ = <T>(
    url: string,
    params: {},
    axiosConfig?: AxiosRequestConfig,
    options: ApiOptions = defaultApiOptions
  ): Observable<ApiResponse123<T>> => {
    const config = withMergePatch(updateMemberApiHeaders(axiosConfig));
    const response: Observable<ApiResponse123<T>> = this.api.patch$(url, params, config);
    return handleResponse$(response, !!options.allowUnauthenticated, options.forceRefreshToken);
  };
}

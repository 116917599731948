export enum AppErrorCode {
  // starts at 8000000 to avoid collisions with ErrorCodes from the API
  // Contrary to the API, these do NOT map the HTTP Statuses

  FATAL_ERROR = 8000000, // Unspecified error code (typically from an unexpected JS exception)

  // --- apisauce Network-related error codes ---
  TIMEOUT_ERROR = 8000001,
  CONNECTION_ERROR = 8000002,
  NETWORK_ERROR = 8000003,
  UNKNOWN_ERROR = 8000004,
  CANCEL_ERROR = 8000005,
  CLIENT_ERROR = 8000006,
  SERVER_ERROR = 8000007,
}

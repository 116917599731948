import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api } from '@common/api';
import { ApiErrorCode } from '@common/api/ApiErrorCode';
import { UploadDocumentData, UploadFileClient } from '@common/client/UploadFileClient';
import { Document } from '@common/model';
import { createResponseAction, ResponseAction } from '@common/redux/Base';

const UPLOAD_NEW_DOCUMENT = 'UPLOAD_NEW_DOCUMENT';
export const UPLOAD_NEW_DOCUMENT_SENT = 'UPLOAD_NEW_DOCUMENT_SENT';
const RESET_UPLOAD_STATE = 'RESET_UPLOAD_STATE';
const CLEAR_NEW_DOCUMENT = 'CLEAR_NEW_DOCUMENT';

interface UploadDocumentAction extends Action {
  documentMetadata: DocumentFileMetaData;
  cancelGroup?: string;
}

export type DocumentFileMetaData = UploadDocumentData;
export const uploadDocument = (documentMetadata: DocumentFileMetaData, cancelGroup?: string): UploadDocumentAction => {
  return {
    type: UPLOAD_NEW_DOCUMENT,
    documentMetadata: documentMetadata,
    cancelGroup: cancelGroup,
  };
};

export const resetUploadState = (): Action => ({ type: RESET_UPLOAD_STATE });

export const clearNewDocument = (): Action => ({ type: CLEAR_NEW_DOCUMENT });

export interface UploadState {
  isUploadingDocument: boolean;
  isUploadError: boolean | undefined;
  isFileInvalid: boolean;
  isFileNameInvalid: boolean;
  wasCancelled: boolean;
  cancelID?: string;
  newDocument: Document | undefined;
}

const initialState: UploadState = {
  isUploadingDocument: false,
  isUploadError: false,
  isFileInvalid: false,
  isFileNameInvalid: false,
  newDocument: undefined,
  wasCancelled: false,
};

export const uploadFileReducer = (state = initialState, action: Action): UploadState => {
  switch (action.type) {
    case UPLOAD_NEW_DOCUMENT:
      return {
        ...state,
        isUploadingDocument: true,
        isUploadError: undefined,
        newDocument: undefined,
      };
    case CLEAR_NEW_DOCUMENT: {
      return { ...state, newDocument: undefined };
    }
    case UPLOAD_NEW_DOCUMENT_SENT: {
      const sentDocAction = action as ResponseAction<Document>;
      const wasCancelled = (sentDocAction.response.error && sentDocAction.response.error.wasCancelled) || false;

      if (sentDocAction.response.success || wasCancelled) {
        const newState = {
          ...state,
          isUploadError: false,
          isUploadingDocument: false,
          isFileInvalid: false,
          isFileNameInvalid: false,
          wasCancelled: wasCancelled,
          newDocument: sentDocAction.response.payload,
        };
        if (sentDocAction.response.payload) {
          newState.newDocument = sentDocAction.response.payload;
        }
        return newState;
      } else {
        return {
          ...state,
          isUploadError: true,
          isUploadingDocument: false,
          isFileInvalid: sentDocAction.response.error
            ? sentDocAction.response.error.code === ApiErrorCode.INVALID_UPLOAD_FILE_TYPE
            : false,
          isFileNameInvalid: sentDocAction.response.error
            ? sentDocAction.response.error.code === ApiErrorCode.INVALID_CHARACTERS_FILENAME_TYPE
            : false,
        };
      }
    }
    case RESET_UPLOAD_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

const uploadFile$ = (client: UploadFileClient, action$: ActionsObservable<Action>) =>
  action$.ofType(UPLOAD_NEW_DOCUMENT).pipe(
    mergeMap$((action: UploadDocumentAction) =>
      client.uploadDocument$(action.documentMetadata, action.cancelGroup).pipe(
        map$((response) =>
          response.result(
            (data) =>
              createResponseAction(UPLOAD_NEW_DOCUMENT_SENT)({
                success: true,
                payload: data,
              }),
            (error) =>
              createResponseAction(UPLOAD_NEW_DOCUMENT_SENT)({
                success: false,
                error: error,
              })
          )
        )
      )
    )
  );

export const createUploadFileEpic = (api: Api) => {
  const client = new UploadFileClient(api);
  return (action$: ActionsObservable<Action>) => merge$(uploadFile$(client, action$));
};

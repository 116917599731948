import React from 'react';

import { debounce, toLower } from 'lodash';
import { generatePath } from 'react-router-dom';

import { history } from '@/reduxStore/History';
import { store } from '@/reduxStore/Store';
import { Routes } from '@/router/Routes';
import { AppConfig } from '@util/AppConfig';
import { checkAppVersion } from '@webApi/MembersEpic';

import { mapAspxUrlToReactUrl } from './AspxMapper';

import './embediframe.css';

interface Props {
  src: string;
}

interface State {
  aspxPageTitle?: string;
}

export class AspxEmbedIframe extends React.Component<Props, State> {
  state: State = {};

  timerId: any | undefined = undefined;

  currentIframeUri: string;

  nextIframeUri = '';

  divRef = React.createRef<HTMLDivElement>();

  onIframeMessageReceived = (e: MessageEvent) => {
    const data = e.data;
    console.log(data);
    if (data.source === 'members-react') {
      // accidentally hosting ourself in the iframe(!)
      // This could occur with an ASPX link or ASPX server redirect
      // to the react site (without proper link 'target').
      // TODO: add infinite loop protection
      // TODO: report this
      console.log(`Embed protection for: ${data.uri}`);
      window.location.href = data.uri;
      return;
    }

    if (data.source !== 'aspx-embed') {
      // this post message is not from us
      return;
    }

    switch (data.command) {
      case 'login': {
        // member-embed aspx missing Auth, then should be redirect to CMS Login page
        console.log(`aspx-embed not auth and need to redirect to CMS login: ${data.source}`);
        window.location.href = AppConfig.getLoginLink();
        break;
      }
      case '404': {
        // member-embed aspx 404, then should be redirect to members 404
        console.log(`aspx-embed 404 error and need to redirect to members 404 not-found page: ${data.source}`);
        window.location.href = Routes.NOTFOUND;
        break;
      }
      case 'embed-page-info': {
        // remember what is current in the iframe
        this.currentIframeUri = data.uri;

        const reactUrlString = mapAspxUrlToReactUrl(data.uri);

        const nextReactUrl = new URL(reactUrlString, window.location.protocol + window.location.hostname);
        const currentReactUrl = new URL(window.location.href);

        if (
          currentReactUrl.pathname !== nextReactUrl.pathname ||
          currentReactUrl.search !== nextReactUrl.search ||
          currentReactUrl.hash !== nextReactUrl.hash
        ) {
          // update main url if different
          history.push(reactUrlString, { aspxPageTitle: data.title });
        } else if (this.state.aspxPageTitle !== data.title) {
          history.replace(reactUrlString, { aspxPageTitle: data.title });
        }

        this.setState({
          aspxPageTitle: data.title,
        } as State);
        break;
      }
      case 'navigate': {
        if (data.navigate_route) {
          const url = generatePath(Routes[data.navigate_route as keyof typeof Routes]);
          if (data.navigate_action && data.navigate_action === 'replace') {
            history.replace(url);
          } else {
            history.push(url);
          }
        }
      }
    }
  };

  componentDidMount() {
    // embedded aspx page will update react page through postMessage()
    window.addEventListener('message', this.onIframeMessageReceived);

    this.renderIFrame(this.props.src);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onIframeMessageReceived);
  }

  /* debounce to detach from react's "late" updates. Just making sure we are updating to the latest value in a quick series of changes */
  updateIfNeeded = debounce(() => {
    this.timerId = undefined;
    // Because of that, we manually reload the iframe in shouldComponentUpdate directly
    // and control it ourselves.
    const currentIframeUrl = new URL(toLower(this.currentIframeUri), AppConfig.LBConfig.ASPEmbedRoot);
    const nextIframeUrl = new URL(toLower(this.nextIframeUri), AppConfig.LBConfig.ASPEmbedRoot);

    // IMPORTANT: must NOT reload the iframe if only the hash portion differ.
    if (currentIframeUrl.pathname !== nextIframeUrl.pathname || currentIframeUrl.search !== nextIframeUrl.search) {
      this.renderIFrame(this.nextIframeUri);
    }
  }, 15);

  shouldComponentUpdate(nextProps: Props) {
    this.nextIframeUri = nextProps.src;
    this.updateIfNeeded();

    // NOTE: React will sometimes render even if we return false.
    // (but it's avoided most of the time)
    return false;
  }

  renderIFrame(sourceUrl: string) {
    const divElem = this.divRef.current;

    if (!divElem) {
      return;
    }

    this.nextIframeUri = sourceUrl;
    this.currentIframeUri = sourceUrl;

    // We need to control when the iframe is updated.
    // React touches the iframe object and it causes it to reload which is not good.
    divElem.innerHTML = `<iframe
        id="aspxiframe"
        src="${sourceUrl}"
        marginHeight="0"
        marginWidth="0"
        frameBorder="0"
        class="iframe-loading"
        style="width:100%;height:100%"
        scrolling="yes"
        data-hj-allow-iframe=""
      ></iframe>`;

    // biome-ignore lint/style/noNonNullAssertion: want this to crash if it doesn't work
    document.getElementById('aspxiframe')!.onload = (event) => {
      if (event?.target) {
        (event.target as any).className = 'iframe-loaded';
      }
    };

    // perform checkAppVersion only on iframe re-renders.
    // use a setTimeout to avoid state change warnings in Render
    store.dispatch(checkAppVersion());
  }

  public render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          overflow: 'hidden',
          WebkitOverflowScrolling: 'touch',
        }}
        key="aspxiframe"
        id="aspxiframe"
        ref={this.divRef}
      />
    );
  }
}

import { clone, filter, findIndex, map, slice, toUpper } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$, Observable } from 'rxjs';

import { ApiErrorCode, ApiResponse123 } from '@common/api';
import { compareIds, serverGMTRFCToLocalTZAndFormatISO } from '@common/helper';
import {
  createDefaultCustomFoldersRequest,
  CUSTOM_FOLDERS_REQUEST_LIMIT_APPENDING,
  CUSTOM_FOLDERS_REQUEST_LIMIT_DEFAULT,
  CUSTOM_FOLDERS_REQUEST_OFFSET_INCREMENT,
  CustomFolder,
  CustomFoldersRequest,
  CustomFoldersResponse,
  DefaultFolder,
  DefaultFoldersResponse,
  NewCustomFolderRequest,
  NewCustomFolderResponse,
} from '@common/model';
import { createResponseAction, EmptyResponse, ResponseAction } from '@common/redux/Base';
import { createPaginatedListReducer, PaginatedListState } from '@common/redux/epic/PaginatedListReducer';

import { simpleApiEpicToAction } from './EpicHelper';

export type CustomFoldersState = PaginatedListState<CustomFolder, CustomFoldersRequest>;

export enum CreateFolderError {
  ALREADY_EXISTS,
  OTHER,
}

interface CreateFolderAction extends Action {
  request: NewCustomFolderRequest;
}

interface FetchDefaultFoldersAction extends Action {
  isRefreshing: boolean;
}
interface EditFolderNameAction extends Action {
  id: string;
  newName: string;
}
interface UpdateFolderAction extends Action {
  id: string;
}
interface DeleteFolderAction extends Action {
  id: string;
}

export interface CreateFolderResponse {
  success: boolean;
  time: number;
  error?: CreateFolderError;
  folder?: CustomFolder;
}

export interface FoldersState {
  defaultFolders: {
    defaultFolders: DefaultFolder[];
    isLoading: boolean;
    isRefreshing: boolean;
    didLoadingFail: boolean;
  };
  customFolders: CustomFoldersState;
  createFolderUpdate: {
    isLoading: boolean;
    response?: CreateFolderResponse;
  };
  editFolderNameUpdate: {
    isLoading: boolean;
    response?: CreateFolderResponse;
  };
  deleteFolderUpdate: {
    deletedFolderId?: string;
    response?: CreateFolderResponse;
  };
}

export const initialFoldersState: FoldersState = {
  defaultFolders: {
    defaultFolders: [],
    isLoading: false,
    isRefreshing: false,
    didLoadingFail: false,
  },
  customFolders: {
    fetchRequest: createDefaultCustomFoldersRequest(),
    isLoading: false,
    didLoadingFail: false,
    isLoadingMore: false,
    isRefreshing: false,
    isLastResult: false,
  },
  createFolderUpdate: {
    isLoading: false,
  },
  editFolderNameUpdate: {
    isLoading: false,
  },
  deleteFolderUpdate: {},
};

/**
 * Folders are a part of documents, so this reducer is defined here but
 * implemented in DocumentsEpic. This was done to reduce the complexity
 * of the DocumentsEpic file while still keeping things together in redux.
 * @param reducerKey String used to prefix folder actions.
 * @param getCustomFoldersStateFromStoreState Function which gets the
 * CustomFoldersState from the broader redux state (StoreState).
 * i.e. store.documents.folders.customFolders
 */
export const createFoldersReducer = <StoreState>(
  reducerKey: string,
  getCustomFoldersStateFromStoreState: (store: StoreState) => CustomFoldersState
) => {
  const customFoldersReducer = createPaginatedListReducer<
    CustomFolder,
    CustomFoldersRequest,
    CustomFoldersResponse,
    StoreState,
    CustomFoldersState
  >({
    reducerKey: reducerKey,
    getPaginatedListStateFromStoreState: getCustomFoldersStateFromStoreState,
    requestConstants: {
      limit: {
        default: CUSTOM_FOLDERS_REQUEST_LIMIT_DEFAULT,
        appending: CUSTOM_FOLDERS_REQUEST_LIMIT_APPENDING,
      },
      offsetIncrement: CUSTOM_FOLDERS_REQUEST_OFFSET_INCREMENT,
    },
    getEntriesFromResponse: (response) => response.customFolders,
    areEntriesEqual: compareIds,
    transformFetchedEntries: (entries: CustomFolder[]) =>
      map(entries, (entry: CustomFolder) => convertCustomFolderDatesToSortableStrings(entry)),
  });

  const actionKey = `${toUpper(reducerKey)}_`;

  // Actions
  const actionTypes = {
    FETCH_DEFAULT_FOLDERS: `${actionKey}FETCH_DEFAULT_FOLDERS`,
    DEFAULT_FOLDERS_FETCHED: `${actionKey}DEFAULT_FOLDERS_FETCHED`,
    CREATE_FOLDER: `${actionKey}CREATE_FOLDER`,
    RECEIVED_CREATE_FOLDER_RESPONSE: `${actionKey}RECEIVED_CREATE_FOLDER_RESPONSE`,
    EDIT_FOLDER_NAME: `${actionKey}EDIT_FOLDER_NAME`,
    FOLDER_NAME_EDITED: `${actionKey}FOLDER_NAME_EDITED`,
    DELETE_FOLDER: `${actionKey}DELETE_FOLDER`,
    DELETE_LOAD_FOLDER: `${actionKey}DELETE_LOAD_FOLDER`,
    FOLDER_DELETED: `${actionKey}FOLDER_DELETED`,
    UPDATE_FOLDER: `${actionKey}UPDATE_FOLDER`,
    FOLDER_UPDATED: `${actionKey}FOLDER_UPDATED`,
    ...customFoldersReducer.actionTypes,
  };
  const actions = {
    fetchDefaultFolders: (isRefreshing: boolean = false): FetchDefaultFoldersAction => ({
      type: actionTypes.FETCH_DEFAULT_FOLDERS,
      isRefreshing: isRefreshing,
    }),
    fetchDefaultFoldersResponse: createResponseAction<DefaultFoldersResponse>(actionTypes.DEFAULT_FOLDERS_FETCHED),
    createFolder: (folderName: string, loadID?: string): CreateFolderAction => ({
      type: actionTypes.CREATE_FOLDER,
      request: {
        name: folderName,
        loadId: loadID,
      },
    }),
    changeFolderName: (id: string, folderName: string): EditFolderNameAction => ({
      type: actionTypes.EDIT_FOLDER_NAME,
      id: id,
      newName: folderName,
    }),
    updateFolder: (id: string): UpdateFolderAction => ({
      type: actionTypes.UPDATE_FOLDER,
      id: id,
    }),
    deleteFolder: (id: string): DeleteFolderAction => ({ type: actionTypes.DELETE_FOLDER, id: id }),
    deleteLoadFolder: (id: string): DeleteFolderAction => ({ type: actionTypes.DELETE_LOAD_FOLDER, id: id }),
    deleteFolderResponse: createResponseAction<EmptyResponse>(actionTypes.FOLDER_DELETED),
    updateFolderResponse: createResponseAction<CustomFolder>(actionTypes.FOLDER_UPDATED),
    createFolderResponse: createResponseAction<CustomFolder>(actionTypes.RECEIVED_CREATE_FOLDER_RESPONSE),
    changedFolderNameResponse: createResponseAction<CustomFolder>(actionTypes.FOLDER_NAME_EDITED),
    ...customFoldersReducer.actions,
  };

  // Reducer
  const foldersReducer = (state: FoldersState, action: Action): FoldersState => {
    switch (action.type) {
      // Creating Custom Folder
      case actionTypes.CREATE_FOLDER: {
        return {
          ...state,
          createFolderUpdate: {
            isLoading: true,
            response: undefined,
          },
        };
      }
      case actionTypes.EDIT_FOLDER_NAME: {
        return {
          ...state,
          editFolderNameUpdate: {
            isLoading: true,
          },
        };
      }
      case actionTypes.RECEIVED_CREATE_FOLDER_RESPONSE: {
        const response = (action as ResponseAction<CustomFolder>).response;
        if (response.success && response.payload) {
          return {
            ...state,
            customFolders: {
              ...state.customFolders,
              entries: state.customFolders.entries
                ? [response.payload, ...state.customFolders.entries]
                : [response.payload],
            },
            createFolderUpdate: {
              isLoading: false,
              response: {
                success: true,
                time: Date.now(),
                error: undefined,
                folder: response.payload,
              },
            },
          };
        }
        const error =
          response.error && response.error.code === ApiErrorCode.FOLDER_ALREADY_EXISTS
            ? CreateFolderError.ALREADY_EXISTS
            : CreateFolderError.OTHER;
        return {
          ...state,
          createFolderUpdate: {
            isLoading: false,
            response: {
              success: false,
              time: Date.now(),
              error: error,
            },
          },
        };
      }
      // Default Folders
      case actionTypes.FETCH_DEFAULT_FOLDERS: {
        const isRefreshing = (action as FetchDefaultFoldersAction).isRefreshing;
        return {
          ...state,
          defaultFolders: {
            ...state.defaultFolders,
            isLoading: !isRefreshing,
            isRefreshing: isRefreshing,
            didLoadingFail: false,
          },
        };
      }
      case actionTypes.DEFAULT_FOLDERS_FETCHED: {
        const defaultFoldersResponse = (action as ResponseAction<DefaultFoldersResponse>).response;
        let didLoadingFail = false;
        let defaultFolders = state.defaultFolders.defaultFolders;
        if (defaultFoldersResponse.success && defaultFoldersResponse.payload) {
          defaultFolders = defaultFoldersResponse.payload.folders;
        } else if (!state.defaultFolders.isRefreshing) {
          didLoadingFail = true;
        }
        return {
          ...state,
          defaultFolders: {
            defaultFolders: defaultFolders,
            isLoading: false,
            isRefreshing: false,
            didLoadingFail: didLoadingFail,
          },
        };
      }
      case actionTypes.FOLDER_NAME_EDITED: {
        const customFoldersResponse = (action as ResponseAction<CustomFolder>).response;
        const error =
          customFoldersResponse.error && customFoldersResponse.error.code === ApiErrorCode.FOLDER_ALREADY_EXISTS
            ? CreateFolderError.ALREADY_EXISTS
            : CreateFolderError.OTHER;
        const payload = customFoldersResponse.payload;
        const customFolders = clone(state.customFolders);
        const newState = {
          ...state,
          customFolders: customFolders,
          editFolderNameUpdate: {
            isLoading: false,
            response: {
              success: customFoldersResponse.success,
              time: Date.now(),
              error: error,
            },
          },
        };
        if (payload) {
          newState.customFolders.entries = updateCustomFolder(payload, customFolders.entries);
        }
        return newState;
      }
      case actionTypes.DELETE_FOLDER:
      case actionTypes.DELETE_LOAD_FOLDER: {
        const { id } = action as DeleteFolderAction;
        return {
          ...state,
          customFolders: {
            ...state.customFolders,
            isRefreshing: true,
          },
          deleteFolderUpdate: {
            deletedFolderId: id,
          },
        };
      }
      case actionTypes.FOLDER_DELETED: {
        const customFolders = state.customFolders;
        const response = (action as ResponseAction<EmptyResponse>).response;
        const folderListWithoutDeletedElement = response.success
          ? filter(customFolders.entries, (folder) => folder.id !== state.deleteFolderUpdate.deletedFolderId)
          : customFolders.entries;
        return {
          ...state,
          customFolders: {
            ...customFolders,
            isRefreshing: false,
            entries: folderListWithoutDeletedElement,
          },
          deleteFolderUpdate: {
            response: {
              success: response.success,
              time: Date.now(),
            },
          },
        };
      }
      case actionTypes.FOLDER_UPDATED: {
        const customFolders = state.customFolders;
        const customFolder = (action as ResponseAction<CustomFolder>).response.payload;
        if (customFolder) {
          return {
            ...state,
            customFolders: {
              ...customFolders,
              isRefreshing: false,
              entries: updateCustomFolder(customFolder, customFolders.entries),
            },
          };
        }
        break;
      }
      // Custom folders
      default: {
        // If the custom folders state is modified, return a new state object as well so that
        // the parent reducer sees there was a state change.
        // If we don't do this, folder actions will not modify the redux state.
        const modifiedFoldersState = customFoldersReducer.reducer(state.customFolders, action);
        if (modifiedFoldersState !== state.customFolders) {
          return {
            ...state,
            customFolders: modifiedFoldersState,
          };
        } else {
          return state;
        }
      }
    }
    return state;
  };

  // Epics
  const createCustomFolderEpic$ = (
    action$: ActionsObservable<Action>,
    createCustomFolder$: (request: NewCustomFolderRequest) => Observable<ApiResponse123<NewCustomFolderResponse>>
  ) =>
    simpleApiEpicToAction(
      action$,
      actionTypes.CREATE_FOLDER,
      (action: CreateFolderAction) => createCustomFolder$(action.request),
      actions.createFolderResponse
    );
  const changeFolderNameEpic$ = (
    action$: ActionsObservable<Action>,
    changeCustomFolderName$: (id: string, name: string) => Observable<ApiResponse123<NewCustomFolderResponse>>
  ) =>
    simpleApiEpicToAction(
      action$,
      actionTypes.EDIT_FOLDER_NAME,
      (action: EditFolderNameAction) => changeCustomFolderName$(action.id, action.newName),
      actions.changedFolderNameResponse
    );

  const fetchDefaultFoldersEpic$ = (
    action$: ActionsObservable<Action>,
    fetchDefaultFolders$: () => Observable<ApiResponse123<DefaultFoldersResponse>>
  ) =>
    simpleApiEpicToAction(
      action$,
      actionTypes.FETCH_DEFAULT_FOLDERS,
      () => fetchDefaultFolders$(),
      actions.fetchDefaultFoldersResponse
    );
  const deleteFolderEpic$ = (
    action$: ActionsObservable<Action>,
    deleteFolder$: (id: string) => Observable<ApiResponse123<EmptyResponse>>
  ) =>
    simpleApiEpicToAction(
      action$,
      actionTypes.DELETE_FOLDER,
      (action: DeleteFolderAction) => deleteFolder$(action.id),
      actions.deleteFolderResponse
    );
  const getCustomFolderEpic$ = (
    action$: ActionsObservable<Action>,
    getCustomFolder$: (id: string) => Observable<ApiResponse123<CustomFolder>>
  ) =>
    simpleApiEpicToAction(
      action$,
      actionTypes.UPDATE_FOLDER,
      (action: UpdateFolderAction) => getCustomFolder$(action.id),
      actions.updateFolderResponse
    );

  const createdMergedEpic$ = (
    action$: ActionsObservable<Action>,
    state$: StateObservable<StoreState>,
    fetchDefaultFolders$: () => Observable<ApiResponse123<DefaultFoldersResponse>>,
    fetchCustomFolders$: (request: CustomFoldersRequest) => Observable<ApiResponse123<CustomFoldersResponse>>,
    newCustomFolder$: (request: NewCustomFolderRequest) => Observable<ApiResponse123<NewCustomFolderResponse>>,
    changeCustomFolderName$: (id: string, name: string) => Observable<ApiResponse123<NewCustomFolderResponse>>,
    deleteFolder$: (id: string) => Observable<ApiResponse123<EmptyResponse>>,
    getCustomFolder$: (id: string) => Observable<ApiResponse123<CustomFolder>>
  ) => {
    return merge$(
      fetchDefaultFoldersEpic$(action$, fetchDefaultFolders$),
      customFoldersReducer.createMergedEpic$(fetchCustomFolders$, action$, state$),
      createCustomFolderEpic$(action$, newCustomFolder$),
      changeFolderNameEpic$(action$, changeCustomFolderName$),
      deleteFolderEpic$(action$, deleteFolder$),
      getCustomFolderEpic$(action$, getCustomFolder$)
    );
  };

  return {
    actionTypes: actionTypes,
    createMergedEpic$: createdMergedEpic$,
    actions: actions,
    reducer: foldersReducer,
  };
};

const convertCustomFolderDatesToSortableStrings = (folder: CustomFolder) => {
  const newFolder = clone(folder);
  newFolder.createdOn = serverGMTRFCToLocalTZAndFormatISO(newFolder.createdOn);
  if (newFolder.modifiedOn) {
    newFolder.modifiedOn = serverGMTRFCToLocalTZAndFormatISO(newFolder.modifiedOn);
  }
  if (newFolder.lastAccessedOn) {
    newFolder.lastAccessedOn = serverGMTRFCToLocalTZAndFormatISO(newFolder.lastAccessedOn);
  }
  return newFolder;
};

/**
 * Will update the folder in place if it exists, otherwise it will append it to the list
 */
export const updateCustomFolder = (
  folderResponse: CustomFolder,
  entries: CustomFolder[] | undefined
): CustomFolder[] | undefined => {
  if (!entries) {
    return [folderResponse];
  }
  const index = findIndex(entries, (entry) => entry.id === folderResponse.id);
  if (index > -1) {
    return [...slice(entries, 0, index), folderResponse, ...slice(entries, index + 1, entries.length)];
  }
  return [...entries, folderResponse];
};

import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ExpandableSections, setExpandedSection } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { displayCurrencyOrDash } from '@common/formatter';
import { isEquipmentTypeSupportedForRateCheck } from '@common/helper';
import { CargoChiefRate as CargoChiefRateType, Equipment, UnlockFeaturePopupType } from '@common/model';
import { AccordionInfoStatus, ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { Subtitle } from '@component/expansionPanel/ExpansionPanelStyle';
import { default as CargoChiefIcon } from '@component/panels/basePanel/loadDetails/marketRates/cargochief-logo.png';
import {
  CargoChiefRateContent,
  handleCargoChiefInfoClick,
} from '@component/panels/basePanel/loadDetails/marketRates/CargoChiefRateComponent';
import { getRouteInfo } from '@component/panels/basePanel/loadDetails/marketRates/GreenscreensRate';
import { RateUnavailablePopup } from '@component/panels/basePanel/loadDetails/marketRates/RateUnavailablePopup';
import {
  ExpansionPanelDataWrapper,
  InfoIcon,
  LoadDetailsContainer,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text/Text';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

interface CargoChiefRateProps {
  isPermitted: boolean;
  isLoadLocationCanada: boolean;
  cargoChiefRate?: CargoChiefRateType;
  equipments?: Equipment[];
  isLoading: boolean;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FullWidthColumn = styled(Column)`
  width: 100%;
`;

export const CargoChiefRate: React.FC<CargoChiefRateProps> = (props) => {
  const dispatch = useDispatch();
  const popupContext = usePopup();
  const isPanelExpanded = useSelector(
    (state) => state.applicationSettings.expandedSections[ExpandableSections.CargoChiefRate]
  );

  const { cargoChiefRate, isLoadLocationCanada } = props;

  const isEquipmentTypeSupported = isEquipmentTypeSupportedForRateCheck(props.equipments);
  const routeInfo = getRouteInfo(cargoChiefRate?.laneMiles, props.equipments);
  const estimatedRate =
    cargoChiefRate?.allInCost && cargoChiefRate?.laneMiles
      ? cargoChiefRate.allInCost / cargoChiefRate.laneMiles
      : undefined;
  const isSupportedByCargoChief = isEquipmentTypeSupported && !isLoadLocationCanada;

  const getStatusAndSubtitle = () => {
    let status: AccordionInfoStatus = AccordionInfoStatus.AVAILABLE;
    let subtitle: string = displayCurrencyOrDash(estimatedRate, true, {
      suffix: t(T.common_marketRate_cargoChief_estRate),
    });

    if (!isSupportedByCargoChief) {
      status = AccordionInfoStatus.UNAVAILABLE;
      subtitle = !isEquipmentTypeSupported
        ? t(T.common_marketRate_notAvailableTrailer)
        : t(T.common_marketRate_notAvailableOutsideUS);
    } else {
      if (!props.isPermitted) {
        status = AccordionInfoStatus.NOT_PERMITTED;
        subtitle = t(T.common_membership_unlockThisFeature);
      } else {
        if (!cargoChiefRate) {
          status = AccordionInfoStatus.UNAVAILABLE;
          subtitle = t(T.common_marketRate_notAvailableAtThisTime);
        }
      }
    }

    if (props.isLoading) {
      status = AccordionInfoStatus.LOADING;
      subtitle = t(T.findLoads_loadDetails_loading);
    }

    return { status: status, subtitleText: subtitle };
  };

  const { status, subtitleText } = getStatusAndSubtitle();

  const handleTabClick = () => {
    if (status === AccordionInfoStatus.UNAVAILABLE) {
      if (isSupportedByCargoChief) {
        popupContext.openingPopup({
          body: <RateUnavailablePopup closingPopup={popupContext.closingPopup} />,
          width: PopupSizes.SMALL,
          isDismissible: true,
          hasCloseButton: true,
        });
      }
      return;
    }
    dispatch(setExpandedSection(ExpandableSections.CargoChiefRate, !isPanelExpanded));
  };

  return (
    <ExpansionPanel
      id="cargo_chief"
      icon={CargoChiefIcon}
      status={status}
      isOpened={isPanelExpanded}
      titleText={t(T.common_marketRate_cargoChief_title)}
      subtitleElement={
        <Subtitle
          id="cargo_chief_subtitle"
          style={{
            fontStyle: status === AccordionInfoStatus.AVAILABLE ? 'normal' : 'italic',
            // this to unset the padding-right: 32px from material-ui, only needed in case of longer subtitles
            marginRight: isLoadLocationCanada ? -30 : 0,
          }}
        >
          {subtitleText}
        </Subtitle>
      }
      handleClick={handleTabClick}
      unlockFeaturePopupType={UnlockFeaturePopupType.CargoChiefRate}
      headerBackground={isSupportedByCargoChief ? Color.GRAY_PANEL : Color.GRAY_LIGHT}
      shouldHideRightButton={status === AccordionInfoStatus.UNAVAILABLE}
      iconStyle={{ height: 50, width: 50, marginRight: 10 }}
      panelTitleStyle={{ height: 40 }}
      centerAlignTitleItems={true}
    >
      <ExpansionPanelDataWrapper>
        {props.isPermitted && status === AccordionInfoStatus.AVAILABLE && (
          <LoadDetailsContainer>
            <FullWidthColumn style={{ paddingTop: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text textStyle={TextStyle.TextContentDetail} style={{ color: Color.LIGHT_BLACK }} id="rate_est_title">
                  {t(T.common_marketRate_cargoChief_rateEst)}
                </Text>
                <InfoIcon
                  id="info"
                  alt="info"
                  src={icons.info_small}
                  style={{ margin: '1px 0 auto 5px' }}
                  onClick={() => handleCargoChiefInfoClick(popupContext.openingPopup, popupContext.closingPopup)}
                />
              </div>
              <Text
                textStyle={TextStyle.SubtitleNormalWeight}
                style={{ color: Color.GRAY_21, whiteSpace: 'pre-wrap' }}
                id="more_info"
              >
                {routeInfo}
              </Text>
              {cargoChiefRate && estimatedRate ? (
                <CargoChiefRateContent
                  allInCost={cargoChiefRate.allInCost}
                  allInCostLow={cargoChiefRate.allInCostLow}
                  allInCostHigh={cargoChiefRate.allInCostHigh}
                  estimatedRate={estimatedRate}
                />
              ) : null}
            </FullWidthColumn>
          </LoadDetailsContainer>
        )}
      </ExpansionPanelDataWrapper>
    </ExpansionPanel>
  );
};

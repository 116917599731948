import { forEach, includes } from 'lodash';
import { Action } from 'redux';

import { Config, Flag, SystemSettingsMonitoredOnSignalR } from '@common/model/SystemSettings';
import { getSystemSetting } from '@common/redux/epic/SettingsEpic';
import { parseValueFrom } from '@common/util/parser/ParserUtils';

export interface SettingUpdate {
  name: string;
}
export interface SettingsUpdate {
  settings: SettingUpdate[];
}

export const mapSettingsUpdateToActions = (update: SettingsUpdate) => {
  const actions: Action[] = [];
  forEach(update.settings, ({ name }) => {
    const systemSetting = parseValueFrom(name, Flag) ?? parseValueFrom(name, Config);
    if (systemSetting !== undefined && includes(SystemSettingsMonitoredOnSignalR, systemSetting)) {
      actions.push(getSystemSetting(systemSetting));
    }
  });
  return actions;
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { TermsAndConditionsClient } from '@common/client/TermsAndConditionsClient';
import { TermsAndConditions } from '@common/model/TermsAndConditions';
import { Response } from '@common/redux/Base';

import { standardApiEpic } from './EpicHelper';

const FETCH_TERMS_OF_SERVICE = 'FETCH_TERMS_OF_SERVICE';
const FETCH_PRIVACY_POLICY = 'FETCH_PRIVACY_POLICY';
const TERMS_OF_SERVICE_FETCHED = 'TERMS_OF_SERVICE_FETCHED';
const PRIVACY_POLICY_FETCHED = 'PRIVACY_POLICY_FETCHED';

type TermsandConditionsResponse = Response<TermsAndConditions>;

export const fetchTermsOfService = (): Action => ({ type: FETCH_TERMS_OF_SERVICE });

export const fetchPrivacyPolicy = (): Action => ({ type: FETCH_PRIVACY_POLICY });

interface TermsandConditionsResponseAction extends Action {
  termsandConditionsResponse: TermsandConditionsResponse;
}

export const termsofServiceResponse = (response: TermsandConditionsResponse): TermsandConditionsResponseAction => ({
  type: TERMS_OF_SERVICE_FETCHED,
  termsandConditionsResponse: response,
});

export const privacyPolicyResponse = (response: TermsandConditionsResponse): TermsandConditionsResponseAction => ({
  type: PRIVACY_POLICY_FETCHED,
  termsandConditionsResponse: response,
});

export interface TermsAndConditionsState {
  termsOfService: string;
  privacyPolicy: string;
  hasError: boolean;
  isLoadingTermsOfService: boolean;
  isLoadingPrivacyPolicy: boolean;
}
export const termsAndConditionsReducer = (
  state: TermsAndConditionsState = {
    termsOfService: '',
    privacyPolicy: '',
    isLoadingTermsOfService: false,
    isLoadingPrivacyPolicy: false,
    hasError: false,
  },
  action: Action
): TermsAndConditionsState => {
  switch (action.type) {
    case FETCH_TERMS_OF_SERVICE: {
      return { ...state, hasError: false, termsOfService: '', isLoadingTermsOfService: true };
    }
    case TERMS_OF_SERVICE_FETCHED: {
      const TACResponseAction = action as TermsandConditionsResponseAction;
      if (TACResponseAction.termsandConditionsResponse.payload) {
        return {
          ...state,
          hasError: false,
          isLoadingTermsOfService: false,
          termsOfService: TACResponseAction.termsandConditionsResponse.payload.text,
        };
      }
      if (TACResponseAction.termsandConditionsResponse.error) {
        return { ...state, hasError: true, isLoadingTermsOfService: false };
      }
      return state;
    }
    case FETCH_PRIVACY_POLICY: {
      return { ...state, hasError: false, privacyPolicy: '', isLoadingPrivacyPolicy: true };
    }
    case PRIVACY_POLICY_FETCHED: {
      const TACResponseAction = action as TermsandConditionsResponseAction;
      if (TACResponseAction.termsandConditionsResponse.payload) {
        return {
          ...state,
          hasError: false,
          isLoadingPrivacyPolicy: false,
          privacyPolicy: TACResponseAction.termsandConditionsResponse.payload.text,
        };
      }
      if (TACResponseAction.termsandConditionsResponse.error) {
        return { ...state, hasError: true, isLoadingPrivacyPolicy: false };
      }
      return state;
    }
    default:
      return state;
  }
};

const fetchTermsOfServiceEpic$ = (action$: ActionsObservable<Action>, client: TermsAndConditionsClient) =>
  standardApiEpic(
    action$,
    FETCH_TERMS_OF_SERVICE,
    () => client.fetchTermsOfService$(),
    (response) => {
      return termsofServiceResponse({ success: true, payload: response.data });
    },
    (error) => {
      return termsofServiceResponse({ success: false, error: error });
    }
  );

const fetchPrivacyPolicyEpic$ = (action$: ActionsObservable<Action>, client: TermsAndConditionsClient) =>
  standardApiEpic(
    action$,
    FETCH_PRIVACY_POLICY,
    () => client.fetchPrivacyPolicy$(),
    (response) => {
      return privacyPolicyResponse({ success: true, payload: response.data });
    },
    (error) => {
      return privacyPolicyResponse({ success: false, error: error });
    }
  );

export const createTermsAndConditionsEpic = (api: Api) => {
  const termsAndConditionsClient = new TermsAndConditionsClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchTermsOfServiceEpic$(action$, termsAndConditionsClient),
      fetchPrivacyPolicyEpic$(action$, termsAndConditionsClient)
    );
};

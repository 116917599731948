import React from 'react';

import styled from 'styled-components';

import { usePanelTrackingBackdropLayer } from '@component/panel/WindowScrollViewTrackingContext';
import { Color } from '@style/Color';

const LAYER_Z_INDEX_STEP = 10;
const BACKDROP_BEHIND_Z_INDEX = 5;

const StyledBackDrop = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${Color.GRAY_DARK};
  opacity: 0.6;
`;

export const Backdrop: React.FC = () => {
  const backdropLayer = usePanelTrackingBackdropLayer();

  return backdropLayer !== undefined && backdropLayer > 0 ? (
    <StyledBackDrop style={{ zIndex: backdropLayer * LAYER_Z_INDEX_STEP - BACKDROP_BEHIND_Z_INDEX }} />
  ) : null;
};

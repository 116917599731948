import { compact } from 'lodash';
import { generatePath } from 'react-router-dom';

import { Routes, TruckLocatorRoutes, urlForLoadSearch } from '@/router/Routes';
import { default as BorderWaitTimesIcon } from '@component/menu/icon/border-wait-times.svg?react';
import { default as CreditReportIcon } from '@component/menu/icon/credit-report.svg?react';
import { default as DashboardIcon } from '@component/menu/icon/dashboard.svg?react';
import { default as ExportPostsIcon } from '@component/menu/icon/export-posts.svg?react';
import { default as FmcsaSearchIcon } from '@component/menu/icon/fmcsa-search.svg?react';
import { default as FuelPricesIcon } from '@component/menu/icon/fuel-prices.svg?react';
import { default as GetPaidFasterIcon } from '@component/menu/icon/get-paid-faster.svg?react';
import { default as HelpIcon } from '@component/menu/icon/help-icon.svg?react';
import { default as HotLeadsIcon } from '@component/menu/icon/hot-leads.svg?react';
import { default as HtmlEmbedCodeIcon } from '@component/menu/icon/html-embed-code.svg?react';
import { default as BidsIcon } from '@component/menu/icon/icon-bid.svg?react';
import { default as AnnouncementsIcon } from '@component/menu/icon/icon-bullhorn.svg?react';
import { default as MessageIcon } from '@component/menu/icon/icon-message.svg?react';
import { default as LoadPlannerIcon } from '@component/menu/icon/load-planner.svg?react';
import { default as LoadAvailibilityIcon } from '@component/menu/icon/loadAvailability.svg?react';
import { default as LogoutIcon } from '@component/menu/icon/logout-icon.svg?react';
import { default as MileageCalculatorIcon } from '@component/menu/icon/mileage-calculator.svg?react';
import { default as MyCarriersIcon } from '@component/menu/icon/my-carriers.svg?react';
import { default as MyCompaniesIcon } from '@component/menu/icon/my-companies.svg?react';
import { default as MyDocumentsIcon } from '@component/menu/icon/my-documents.svg?react';
import { default as MyLoadsIcon } from '@component/menu/icon/my-loads.svg?react';
import { default as MyTruckPostIcon } from '@component/menu/icon/my-truck-post.svg?react';
import { default as PostIcon } from '@component/menu/icon/post.svg?react';
import { default as PostingAidIcon } from '@component/menu/icon/posting-aid.svg?react';
import { default as ProfitCalculatorIcon } from '@component/menu/icon/profit-calculator.svg?react';
import { default as RateCheckIcon } from '@component/menu/icon/rate-check.svg?react';
import { default as ReeferRatesIcon } from '@component/menu/icon/reefer-rates.svg?react';
import { default as RegisterIcon } from '@component/menu/icon/register.svg?react';
import { default as RoadsideAssistanceIcon } from '@component/menu/icon/roadside-assistance.svg?react';
import { default as SaveAtThePumpIcon } from '@component/menu/icon/save-at-the-pump.svg?react';
import { default as SearchDirectoryIcon } from '@component/menu/icon/search-directory.svg?react';
import { default as SearchTrucksIcon } from '@component/menu/icon/search-trucks.svg?react';
import { default as SearchIcon } from '@component/menu/icon/search.svg?react';
import { default as SettingsIcon } from '@component/menu/icon/settings.svg?react';
import { default as SignInIcon } from '@component/menu/icon/sign-in.svg?react';
import { default as TmsIntegrationIcon } from '@component/menu/icon/tms-integration.svg?react';
import { default as TruckingAuthorityIcon } from '@component/menu/icon/trucking-authority.svg?react';
import { default as UploadPostsIcon } from '@component/menu/icon/upload-posts.svg?react';
import { NavGroups, NavItem } from '@component/menu/menuTypes';
import { BidsTabs } from '@page/bids/BidsPanelRouting';
import { t, T } from '@translate';
import { AppConfig } from '@util/AppConfig';
import { SIGNUP_LINK } from '@util/Link';

const commonServices = [
  {
    id: 'menu_services_get_paid_faster_button',
    label: t(T.menu_services_getPaidFaster),
    href: Routes.TOOLS_GET_PAID_FASTER,
    icon: GetPaidFasterIcon,
  },
  {
    id: 'menu_services_save_at_the_pump_button',
    label: t(T.menu_services_saveAtThePump),
    href: Routes.TOOLS_SAVE_AT_THE_PUMP,
    icon: SaveAtThePumpIcon,
  },
  {
    id: 'menu_services_roadside_assistance_button',
    label: t(T.menu_services_roadsideAssistance),
    href: Routes.TOOLS_ROADSIDE_ASSIST,
    icon: RoadsideAssistanceIcon,
  },
  {
    id: 'menu_services_trucking_authority_button',
    label: t(T.menu_services_truckingAuthority),
    href: Routes.TOOLS_TRUCKING_AUTHORITY,
    icon: TruckingAuthorityIcon,
  },
];

export const loggedOutNavMenu: () => NavGroups[] = () => [
  {
    id: 'menu_account_title',
    label: t(T.menu_account_title),
    content: [
      {
        id: 'menu_account_signin_button',
        label: t(T.menu_account_signin),
        href: AppConfig.getLoginLink(),
        isExternalLink: true,
        icon: SignInIcon,
      },
      {
        id: 'menu_account_register_button',
        label: t(T.menu_account_register),
        href: SIGNUP_LINK,
        isExternalLink: true,
        icon: RegisterIcon,
      },
    ],
  },
  {
    id: 'menu_services_title',
    label: t(T.menu_services_title),
    content: commonServices,
  },
];

const mainNavGroup = (
  announcementsNotificationCount: number,
  communicationsUnreadCount: number,
  isCommunicationEnabled: boolean,
  bidsUnviewedCount: number,
  isBiddingEnabled: boolean,
  isVendorBidsListEnabled: boolean,
  isMenuExpanded?: boolean
): NavGroups => {
  return {
    id: 'menu_main_title',
    label: t(T.menu_main_title),
    content: [
      {
        id: 'menu_main_dashboard_button',
        label: t(T.menu_main_dashboard),
        href: Routes.DASHBOARD,
        icon: DashboardIcon,
        exact: true,
      },
      {
        id: 'menu_main_inbox_button',
        label:
          isMenuExpanded === false
            ? t(T.common_announcements_announcementsNewLine)
            : t(T.common_announcements_announcements),
        href: Routes.ANNOUNCEMENTS,
        icon: AnnouncementsIcon,
        notificationCount: announcementsNotificationCount,
        additionalLinksToMatch: ['/view-announcement/'],
      },
      {
        id: 'menu_main_communication_button',
        label: t(T.menu_main_messages),
        href: generatePath(Routes.COMMUNICATION),
        icon: MessageIcon,
        notificationCount: communicationsUnreadCount,
        additionalLinksToMatch: ['/communication/'],
        isHidden: !isCommunicationEnabled,
      },
      {
        id: 'menu_main_bids_button',
        label: t(T.Header_Bids),
        href: generatePath(Routes.BIDS_BASE_ROUTE, {
          tab: isVendorBidsListEnabled ? BidsTabs.Bids : BidsTabs.InteractiveBids,
        }),
        icon: BidsIcon,
        notificationCount: isBiddingEnabled ? bidsUnviewedCount : 0,
        additionalLinksToMatch: ['/bids/'],
        isHidden: !isBiddingEnabled && !isVendorBidsListEnabled,
      },
    ],
  };
};

const resources = (isBroker?: boolean): NavGroups => ({
  id: 'menu_resources_title',
  label: t(T.menu_resources_title),
  content: [
    {
      id: 'menu_resources_fuel_prices_button',
      label: t(T.menu_resources_fuelPrices),
      href: Routes.TOOLS_FUEL_PRICES,
      icon: FuelPricesIcon,
    },
    {
      id: 'menu_resources_fmsca_search_button',
      label: t(T.menu_resources_fmcsaSearch),
      href: Routes.TOOLS_FMCSA_SEARCH,
      icon: FmcsaSearchIcon,
    },
    {
      id: 'menu_resources_credit_report_button',
      label: t(T.menu_resources_creditReport),
      href: Routes.RESOURCES_CREDIT_REPORT,
      icon: CreditReportIcon,
      isHidden: isBroker,
    },
    {
      id: 'menu_resources_search_directory_button',
      label: t(T.menu_resources_searchDirectory),
      href: Routes.RESOURCES_SEARCH_DIRECTORY_BASE,
      icon: SearchDirectoryIcon,
      exact: false,
      isNewOrUpdated: true,
    },
    {
      id: 'menu_resources_reefer_rates_button',
      label: t(T.menu_resources_reeferRates),
      href: Routes.TOOLS_REEFER_RATES,
      icon: ReeferRatesIcon,
    },
    {
      id: 'menu_resources_border_wait_time_button',
      label: t(T.menu_resources_borderWaitTimes),
      href: Routes.TOOLS_BORDER_WAIT_TIMES,
      icon: BorderWaitTimesIcon,
    },
  ],
});

const moreNavGroup = (): NavGroups => {
  const content: NavItem[] = compact([
    {
      id: 'menu_more_settings_button',
      label: t(T.menu_more_settings),
      href: Routes.MORE_SETTINGS_BASE_ROUTE,
      icon: SettingsIcon,
      exact: false,
    },
    {
      id: 'menu_more_help_button',
      label: t(T.menu_more_help),
      href: Routes.MORE_HELP_BASE_ROUTE,
      icon: HelpIcon,
      exact: false,
    },
    {
      id: 'menu_more_logout_button',
      label: t(T.menu_more_logout),
      href: Routes.MORE_LOGOUT,
      icon: LogoutIcon,
    },
  ]);

  return {
    id: 'menu_more_title',
    label: t(T.menu_more_title),
    content: content,
  };
};

export const createCarrierNavGroup = (
  announcementsNotificationCount: number,
  loadAlertsCount: number,
  unreadMessagesCount: number,
  unreadTruckAlertsCount: number,
  isCommunicationEnabled: boolean,
  bidsUnviewedCount: number,
  isBiddingEnabled: boolean,
  isVendorBidsListEnabled: boolean,
  isTripBuilderEnabled: boolean,
  isLoadPlannerEnabled: boolean,
  isMenuExpanded?: boolean
): NavGroups[] => [
  mainNavGroup(
    announcementsNotificationCount,
    unreadMessagesCount,
    isCommunicationEnabled,
    bidsUnviewedCount,
    isBiddingEnabled,
    isVendorBidsListEnabled,
    isMenuExpanded
  ),
  {
    id: 'menu_loads_title',
    label: t(T.menu_loads_title),
    content: [
      {
        id: 'menu_loads_find_loads_button',
        label: t(T.menu_loads_findLoads),
        href: urlForLoadSearch,
        icon: SearchIcon,
        notificationCount: loadAlertsCount,
      },
      {
        id: 'menu_loads_availability_button',
        label: t(T.menu_loads_loadAvailability),
        href: `${generatePath(Routes.LOADS_LOAD_AVAILABILITY_MAP, {})}/`,
        icon: LoadAvailibilityIcon,
        exact: false,
      },
      {
        id: 'menu_loads_load_planner_button',
        label: t(T.menu_loads_loadPlanner),
        href: generatePath(Routes.LOADS_LOAD_PLANNER),
        icon: LoadPlannerIcon,
        isHidden: !isLoadPlannerEnabled,
      },
      {
        id: 'menu_loads_trip_builder_button',
        label: t(T.menu_loads_tripBuilder),
        href: generatePath(Routes.TRIP_BUILDER_BASE_URL),
        icon: LoadPlannerIcon,
        isHidden: !isTripBuilderEnabled,
        isNewOrUpdated: true,
      },
      {
        id: 'menu_loads_my_loads_button',
        label: t(T.menu_loads_myLoads),
        href: `${generatePath(Routes.LOADS_MY_LOADS, {})}/`,
        icon: MyLoadsIcon,
        exact: false,
      },
      {
        id: 'menu_loads_my_companies_button',
        label: t(T.menu_companies_myCompanies),
        href: `${generatePath(Routes.LOADS_COMPANIES, {})}/`,
        icon: MyCompaniesIcon,
      },
    ],
  },
  {
    id: 'menu_mytrucks_title',
    label: t(T.menu_myTrucks_title),
    content: [
      {
        id: 'menu_mytrucks_post_button',
        label: t(T.menu_myTrucks_post),
        href: Routes.MYTRUCKS_POST_BASE,
        icon: MyTruckPostIcon,
        notificationCount: unreadTruckAlertsCount,
        exact: false,
      },
    ],
  },
  {
    id: 'menu_tools_title',
    label: t(T.menu_tools_title),
    content: [
      {
        id: 'menu_tools_market_rates_button',
        label: t(T.marketRates_searchForm_marketRates),
        href: generatePath(Routes.TOOLS_MARKET_RATES_CARRIER, {}),
        icon: RateCheckIcon,
        isNewOrUpdated: true,
      },
      {
        id: 'menu_tools_profit_calculator_button',
        label: t(T.menu_tools_profitCalculator),
        href: generatePath(Routes.TOOLS_PROFIT_CALCULATOR_PAGE, {}),
        icon: ProfitCalculatorIcon,
      },
      {
        id: 'menu_tools_mileage_calculator_button',
        label: t(T.menu_tools_mileageCalculator),
        href: Routes.TOOLS_MILEAGE_CALCULATOR,
        icon: MileageCalculatorIcon,
      },
      {
        id: 'menu_tools_my_documents_button',
        label: t(T.menu_tools_myDocuments),
        href: generatePath(Routes.TOOLS_MY_DOCUMENTS, { viewType: 'folders' }),
        icon: MyDocumentsIcon,
        exact: false,
      },
    ],
  },
  {
    id: 'menu_services_title',
    label: t(T.menu_services_title),
    content: commonServices,
  },
  resources(),
  moreNavGroup(),
];

export const createBrokerNavGroup = (
  inboxNotificationCount: number,
  communicationsUnreadCount: number,
  isCommunicationEnabled: boolean,
  bidsUnviewedCount: number,
  isBiddingEnabled: boolean,
  isVendorBidsListEnabled: boolean,
  isInternalTruckLocatorEnabled: boolean,
  isCapacityFinderEnabled: boolean,
  loadAlertsCount: number,
  isMenuExpanded?: boolean
): NavGroups[] => [
  mainNavGroup(
    inboxNotificationCount,
    communicationsUnreadCount,
    isCommunicationEnabled,
    bidsUnviewedCount,
    isBiddingEnabled,
    isVendorBidsListEnabled,
    isMenuExpanded
  ),
  {
    id: 'menu_myloads_title',
    label: t(T.menu_myLoads_title),
    content: [
      {
        id: 'menu_myloads_post_button',
        label: t(T.menu_myLoads_post),
        href: `${generatePath(Routes.LOADS_MY_LOADS_POST, {})}/`,
        icon: PostIcon,
        exact: false,
      },
      {
        id: 'menu_myloads_upload_posts_button',
        label: t(T.menu_myLoads_uploadPosts),
        href: Routes.MYLOADS_UPLOADPOSTS,
        icon: UploadPostsIcon,
      },
      {
        id: 'menu_myloads_export_posts_button',
        label: t(T.menu_myLoads_exportPosts),
        href: Routes.MYLOADS_EXPORTPOSTS,
        icon: ExportPostsIcon,
      },
    ],
  },
  {
    id: 'menu_findtrucks_title',
    label: t(T.menu_findTrucks_title),
    content: [
      {
        id: 'menu_internal_findtrucks_search_button',
        label: t(T.Header_Internal_Truck_Locator),
        href: generatePath(Routes.FINDTRUCKS_TRUCKLOCATOR, {
          truckLocatorType: TruckLocatorRoutes.InternalTruckLocator,
        }),
        icon: SearchTrucksIcon,
        exact: false,
        isHidden: !isInternalTruckLocatorEnabled,
      },
      {
        id: 'menu_findtrucks_capacity_finder_button',
        label: t(T.capacityFinder_title),
        href: generatePath(Routes.TOOLS_CAPACITY_FINDER, {}),
        icon: HotLeadsIcon,
        isNewOrUpdated: true,
        isHidden: !isCapacityFinderEnabled,
      },
      {
        id: 'menu_findtrucks_search_button',
        label: t(T.menu_findTrucks_search),
        href: generatePath(Routes.FINDTRUCKS_TRUCKLOCATOR, { truckLocatorType: TruckLocatorRoutes.TruckLocator }),
        icon: SearchTrucksIcon,
        exact: false,
      },
      {
        id: 'menu_tools_hot_leads_button',
        label: t(T.menu_tools_hotLeads),
        href: Routes.TOOLS_HOT_LEADS,
        icon: HotLeadsIcon,
        isHidden: isCapacityFinderEnabled,
      },
      {
        id: 'menu_findtrucks_my_carriers_button',
        label: t(T.menu_findTrucks_myCarriers),
        href: `${generatePath(Routes.LOADS_CARRIERS, {})}/`,
        icon: MyCarriersIcon,
      },
    ],
  },
  {
    id: 'menu_tools_title',
    label: t(T.menu_tools_title),
    content: compact([
      {
        id: 'menu_tools_market_rates_button',
        label: t(T.marketRates_searchForm_marketRates),
        href: generatePath(Routes.TOOLS_MARKET_RATES, {}),
        icon: RateCheckIcon,
        isNewOrUpdated: true,
      },
      {
        id: 'menu_tools_mileage_calculator_button',
        label: t(T.menu_tools_mileageCalculator),
        href: Routes.TOOLS_MILEAGE_CALCULATOR,
        icon: MileageCalculatorIcon,
      },
      {
        id: 'menu_tools_my_documents_button',
        label: t(T.menu_tools_myDocuments),
        href: generatePath(Routes.TOOLS_MY_DOCUMENTS, { viewType: 'folders' }),
        icon: MyDocumentsIcon,
        exact: false,
      },
      {
        id: 'menu_tools_find_loads_button',
        label: t(T.menu_loads_findLoads),
        href: urlForLoadSearch,
        icon: SearchIcon,
        notificationCount: loadAlertsCount,
      },
      {
        id: 'menu_tools_availability_button',
        label: t(T.menu_loads_loadAvailability),
        href: `${generatePath(Routes.LOADS_LOAD_AVAILABILITY_MAP, {})}/`,
        icon: LoadAvailibilityIcon,
        exact: false,
      },
    ]),
  },
  {
    id: 'menu_integrationServices_title',
    label: t(T.menu_integrationServices_title),
    content: [
      {
        id: 'menu_integrationServices_tms_integration_button',
        label: t(T.menu_integrationServices_tmsIntegration),
        href: Routes.INTEGRATION_SERVICES_TMSINT,
        icon: TmsIntegrationIcon,
      },
      {
        id: 'menu_integrationServices_html_embed_code_button',
        label: t(T.menu_integrationServices_htmlEmbed),
        href: Routes.INTEGRATION_SERVICES_HTMLEMBED,
        icon: HtmlEmbedCodeIcon,
      },
      {
        id: 'menu_integrationServices_posting_aid_button',
        label: t(T.menu_integrationServices_postingAid),
        href: Routes.INTEGRATION_SERVICES_POSTINGAID,
        icon: PostingAidIcon,
      },
    ],
  },
  resources(true),
  moreNavGroup(),
];

export interface UserSettingsDetails {
  id: number;
  lastLoginDateUTC: string;
  companyId: number;
  companyUid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  phoneExt: string;
  isAdmin: boolean;
  mobile: string;
  createdOn: string;
  emailValidated: boolean;
  guid: string;
  newEmail: string;
  hubspotId: string;
  signupDeviceId: string;
  status: number;
  primaryPhone: {
    id: string;
    lineType: string;
    lineNumber: string;
    extension: string;
    countryCode: string;
  };
}

export interface UserAddDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isAdmin: boolean;
  isDispatcher: boolean;
}

export interface UserEditDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isAdmin: boolean;
  id?: number;
}

export enum DispatcherStatus {
  None = 'None',
  Active = 'Active',
  Deleted = 'Deleted',
}

export enum DispatcherSource {
  None = 'None',
  Company = 'Company',
  Contact = 'Contact',
  ContactInfo = 'ContactInfo',
  Direct = 'ContactInfo',
}

export interface DispatcherDetail {
  id: string;
  companyId: string;
  name: string;
  code: string;
  phone: {
    lineNumber: string;
    extension: string;
    countryCode: string;
  };
  email: string;
  isDefault: boolean;
  status: DispatcherStatus;
  source: DispatcherSource;
}

export interface UserAddingDetails {
  user: UserAddDetails;
}

export interface UsersSettingsPayload {
  contacts: UserSettingsDetails[];
  count: number;
  totalCount: number;
}
export interface DispatcherPayload {
  dispatchers: DispatcherDetail[];
  count: number;
  totalCount: number;
}

export enum UnitAbbreviation {
  English = 'E',
  Metric = 'M',
}
export interface HotLeadsPayload {
  hotLeads: boolean;
}

export interface AlertsPayload {
  instantLoadMatching: boolean;
}

export interface EmailNotificationsPayload {
  announcements: boolean;
  newsletters: boolean;
  promotions: boolean;
}

export interface ResetPasswordPayload {
  message: string;
  title: string;
}
export enum UserRoles {
  Admin,
  User,
  Dispatcher,
}

export interface MessagesNotificationsPayload {
  deviceId: string;
  token: string;
}

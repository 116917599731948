import { trim } from 'lodash';

import { DispatcherDetail, DispatcherPostResponse, PostLoadFormUserProfile } from '@/model';
import { formatPhoneNumber, valueOrEmptyString } from '@common/helper';
import { Load } from '@common/model';

const DISPATCH_PHONE_NUMBER = 'dispatchPhone.number';
const DISPATCH_PHONE_EXT = 'dispatchPhone.extension';
const EMPTY_USER_DATA = {
  dispatchName: '',
  dispatchEmail: '',
  [DISPATCH_PHONE_NUMBER]: '',
  [DISPATCH_PHONE_EXT]: '',
};

/**
 * While loadPosting we need to set user information in Dispatcher section. This data can have 3 sources of truth (posting contact, dispatcher connected to user and load data)
 * This function goes through all the sources in this priority: Load -> Posting Contact -> Dispatcher. In the case if we have load we use data from there.
 * In other cases we use Posting Contact, Dispatcher information, if Dispatcher data is not complete
 **/
export const getUserInfoForLoadPosting = (
  dispatcher: DispatcherDetail | undefined,
  load?: Load,
  postedContact?: DispatcherPostResponse
) => {
  if (load !== undefined) {
    return getUserInfoFromLoad(load);
  }
  const userInfoFromPostingContact = getUserInfoFromPostingContact(postedContact);
  const userInfoFromDispatcher = getUserInfoFromDispatcher(dispatcher);
  const userInfo = postedContact ? userInfoFromPostingContact : userInfoFromDispatcher;

  return userInfo;
};

export const getUserInfoFromLoad = (load: Load): PostLoadFormUserProfile => ({
  dispatchName: valueOrEmptyString(load?.dispatchName),
  dispatchEmail: valueOrEmptyString(load?.dispatchEmail),
  [DISPATCH_PHONE_NUMBER]: valueOrEmptyString(load?.dispatchPhone?.number),
  [DISPATCH_PHONE_EXT]: valueOrEmptyString(load?.dispatchPhone?.extension),
});

const getUserInfoFromPostingContact = (contact?: DispatcherPostResponse): PostLoadFormUserProfile => {
  if (contact !== undefined) {
    return {
      dispatchName: valueOrEmptyString(trim(`${contact?.firstName} ${contact?.lastName}`)),
      dispatchEmail: valueOrEmptyString(contact.email),
      [DISPATCH_PHONE_NUMBER]: formatPhoneNumber(valueOrEmptyString(contact.phone?.lineNumber)),
      [DISPATCH_PHONE_EXT]: valueOrEmptyString(contact.phone?.extension),
    };
  }
  return EMPTY_USER_DATA;
};

const getUserInfoFromDispatcher = (dispatcher?: DispatcherDetail): PostLoadFormUserProfile => {
  if (dispatcher !== undefined) {
    return {
      dispatchName: valueOrEmptyString(dispatcher.name),
      dispatchEmail: valueOrEmptyString(dispatcher.email),
      [DISPATCH_PHONE_NUMBER]: formatPhoneNumber(valueOrEmptyString(dispatcher.phone?.lineNumber)),
      [DISPATCH_PHONE_EXT]: valueOrEmptyString(dispatcher.phone?.extension),
    };
  }
  return EMPTY_USER_DATA;
};

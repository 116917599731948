import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { LoadDetailsTabs } from '@/model';
import { LoadSearchRequest } from '@common/model';
import { fetchLoadCount } from '@common/redux/epic/loadSearch/CompanySearchEpic';
import { AccordionInfoStatus, ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { default as CompanyLoadsIcon } from '@component/panels/basePanel/loadDetails/company-loads.svg';
import { LinkButton } from '@component/panels/basePanel/loadDetails/LoadDocuments';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

interface Props {
  handleChange: (tab: LoadDetailsTabs, status: boolean) => void;
  isOpened: boolean;
  onViewLoadsClick?: () => void;
  headerBackground?: string;
  companySearchRequest: LoadSearchRequest | undefined;
}

const ExpansionPanelDataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
`;

const TextContent = styled.div`
  color: ${Color.LIGHT_BLACK};
`;

export const CompanyLoads: React.FC<Props> = (props) => {
  const totalLoadsCountSearchForm = useSelector((state) => state.companySearch.totalLoadsCountSearchForm);
  const isFetchingCount = useSelector((state) => state.companySearch.isFetchingCount);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.companySearchRequest) {
      dispatch(fetchLoadCount(props.companySearchRequest));
    }
  }, []);

  return (
    <ExpansionPanel
      id="company_loads"
      titleText={t(T.findLoads_loadDetails_companyLoads)}
      subtitleText={
        totalLoadsCountSearchForm === 1
          ? t(T.backhauls_possibleLoad)
          : t(T.backhauls_possibleLoads, { value: totalLoadsCountSearchForm })
      }
      icon={CompanyLoadsIcon}
      isOpened={props.isOpened}
      handleClick={() => props.handleChange(LoadDetailsTabs.CompanyLoads, !props.isOpened)}
      status={getExpansionPanelStatus(isFetchingCount, totalLoadsCountSearchForm)}
      headerBackground={props.headerBackground}
    >
      <CompanyLoadsContent onViewLoadsClick={props.onViewLoadsClick} />
    </ExpansionPanel>
  );
};

export const CompanyLoadsContent: React.FC<{ onViewLoadsClick?: () => void }> = ({ onViewLoadsClick }) => (
  <ExpansionPanelDataWrapper>
    <TextContent>{t(T.findLoads_loadDetails_companyLoadsDescription)}</TextContent>
    <LinkButton id={'view_loads'} onClick={onViewLoadsClick}>
      {t(T.findLoads_loadDetails_viewLoads)}
    </LinkButton>
  </ExpansionPanelDataWrapper>
);

const getExpansionPanelStatus = (isLoading: boolean, totalResultsCount: number | undefined): AccordionInfoStatus => {
  if (isLoading) {
    return AccordionInfoStatus.LOADING;
  }
  if (!totalResultsCount) {
    return AccordionInfoStatus.UNAVAILABLE;
  }
  return AccordionInfoStatus.AVAILABLE;
};

import { Action } from 'redux';

import { createDefaultLoadPostSearchRequest } from '@common/helper';
import { LoadStatus, PosterLoadSearchRequest } from '@common/model';
import { createReducer } from '@common/redux/ReduxHelper';

const UPDATE_LOAD_POST_SORT_FORM_FIELDS = 'UPDATE_LOAD_POST_SORT_FORM_FIELDS';
const RESET_LOAD_POSTED_SEARCH = 'RESET_LOAD_POSTED_SEARCH';
const SET_FILTER_STATUS = 'SET_FILTER_STATUS';

interface UpdateFormFieldsAction extends Action {
  filters: Partial<PosterLoadSearchRequest>;
}

interface SetFilterStatusAction extends Action {
  status: LoadStatus;
}

export const resetFormFields = () => ({
  type: RESET_LOAD_POSTED_SEARCH,
});

export const updateFormFields = (filters: Partial<PosterLoadSearchRequest>) => ({
  type: UPDATE_LOAD_POST_SORT_FORM_FIELDS,
  filters: filters,
});

export const setFilterStatus = (status: LoadStatus): SetFilterStatusAction => ({
  type: SET_FILTER_STATUS,
  status: status,
});

export interface LoadPostFiltersState {
  filters: PosterLoadSearchRequest;
  status?: LoadStatus;
}

const initialState: LoadPostFiltersState = {
  filters: {
    ...createDefaultLoadPostSearchRequest(),
  },
};

export const filterLoadsReducer = createReducer(initialState, {
  [RESET_LOAD_POSTED_SEARCH]: () => {
    return initialState;
  },
  [UPDATE_LOAD_POST_SORT_FORM_FIELDS]: (state: LoadPostFiltersState, action: UpdateFormFieldsAction) => {
    state.filters = { ...state.filters, ...action.filters };
  },
  [SET_FILTER_STATUS]: (state: LoadPostFiltersState, action: SetFilterStatusAction) => {
    state.status = action.status;
  },
});

import React from 'react';

import styled from 'styled-components';

import { Link } from '@material-ui/core';

import { default as Icon123Connect } from '@/icons/123connect_icon.svg';
import { default as CheckIcon } from '@/icons/icon-bullet-check.svg';
import { GENERAL_CONTACT_PHONE_NUMBER_FORMATTED } from '@common/info';
import { Container, ContentText } from '@component/panels/communication/EmptyConversationsPanel';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import { LINK_123_CONNECT } from '@util/Link';

const ReasonOffset = styled.div`
  margin-bottom: ${Spacing.ElementPaddingVertical}px;
`;

const ReasonBlock = styled.div`
  display: flex;
`;

const Check = styled.img`
  height: ${ElementSize.Icon}px;
  width: ${ElementSize.Icon}px;
  min-height: ${ElementSize.Icon}px;
  min-width: ${ElementSize.Icon}px;
  margin-right: ${Spacing.ElementPaddingHorizontal}px;
`;

const ReasonsLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${Spacing.LargePaddingVertical}px 0 ${Spacing.LargePaddingVertical}px ${Spacing.ElementPaddingHorizontal}px;
`;

export const CommunicationDisabledByBrokerPanel: React.FC = () => (
  <NotFoundPanel
    title={t(T.common_conversations_title123Connect)}
    imageSection={<img id="image" src={Icon123Connect} alt="error" />}
    controlSection={
      <Container>
        <ContentText id="disabled_intro">
          {t(T.common_conversations_disabled_broker_intro)}
          <Link href={LINK_123_CONNECT} color={'secondary'} noWrap={true} target={'_blank'}>
            {t(T.common_conversations_title123Connect)}
          </Link>
          {t(T.common_period)}
        </ContentText>
        <ReasonsLayout>
          {getReasonsText([
            t(T.common_conversations_disabled_broker_reason1),
            t(T.common_conversations_disabled_broker_reason2),
            t(T.common_conversations_disabled_broker_reason3),
          ])}
        </ReasonsLayout>
        <ContentText id="disabled_subtitle">{t(T.common_conversations_disabled_broker_subtitle)}</ContentText>
        <ContentText id="disabled_phone" style={{ alignSelf: 'center' }}>
          {t(T.common_conversations_disabled_broker_callText, {
            number: GENERAL_CONTACT_PHONE_NUMBER_FORMATTED,
          })}
        </ContentText>
      </Container>
    }
  />
);

const getReasonsText = (reasons: string[]) =>
  reasons.map((reason, i) => (
    <ReasonOffset key={`reason_${i}`}>
      <ReasonBlock>
        <Check src={CheckIcon} alt={'check'} />
        <ContentText id={`disabled_reason_${i}`} style={{ textAlign: 'start' }}>
          {reason}
        </ContentText>
      </ReasonBlock>
    </ReasonOffset>
  ));

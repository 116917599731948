import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { Action, applyMiddleware, combineReducers, compose, createStore, Reducer } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localStorage from 'redux-persist/lib/storage';

import {
  applicationSettingsReducer,
  ApplicationSettingsState,
  migrateAppSettings,
} from '@/reduxStore/epic/ApplicationSettingsReducer';
import { capacityFinderReducer, CapacityFinderState } from '@/reduxStore/epic/CapacityFinderEpic';
import { connectionErrorReducer, ConnectionErrorState } from '@/reduxStore/epic/ConnectionErrorEpic';
import { filterLoadsReducer, LoadPostFiltersState } from '@/reduxStore/epic/FilterLoadsEpic';
import { loadVisibilityRulesReducer, LoadVisibilityRulesState } from '@/reduxStore/epic/LoadVisibilityRulesEpic';
import { locationReducer, LocationState } from '@/reduxStore/epic/LocationEpic';
import { LocationPickerReducer, LocationPickerState } from '@/reduxStore/epic/LocationPickerEpic';
import { mileageCalculatorReducer, SelectedLocationsState } from '@/reduxStore/epic/MileageCalculatorEpic';
import { onboardedCarriersReducer, OnboardedCarriersState } from '@/reduxStore/epic/OnboardedCarriersEpic';
import { panelReducer, PanelState } from '@/reduxStore/epic/PanelEpic';
import { postingContactsReducer, PostingContactsState } from '@/reduxStore/epic/PostingContactsEpic';
import { postLoadReducer, PostLoadState } from '@/reduxStore/epic/PostLoadEpic';
import { PostLoadFormState, postLoadsFormReducer } from '@/reduxStore/epic/PostLoadFormEpic';
import { privateNetworkReducer, PrivateNetworkState } from '@/reduxStore/epic/PrivateNetworkEpic';
import { searchDirectoryCompanyReducer, SearchDirectoryCompanyState } from '@/reduxStore/epic/SearchDirectoryEpic';
import { trucksReducer, TruckState } from '@/reduxStore/epic/TruckEpic';
import { urlScrapeReducer, URLScrapeState } from '@/reduxStore/epic/URLScrapeEpic';
import { userActivityReducer, UserActivityState } from '@/reduxStore/epic/UserActivityEpic';
import { usersSettingsReducer, UsersSettingState } from '@/reduxStore/epic/UserSettingsEpic';
import { verifyEmailReducer, VerifyEmailState } from '@/reduxStore/epic/VerifyEmailEpic';
import { history } from '@/reduxStore/History';
import { LoadDetailsTabsState, loadDetailsTabsStateReducer } from '@/reduxStore/reducer/LoadDetailsTabsReducer';
import { loadSearchFormReducer, LoadSearchFormState } from '@/reduxStore/reducer/LoadSearchFormReducer';
import {
  postedLoadsPanelActionsReducer,
  PostedLoadsPanelActionsState,
} from '@/reduxStore/reducer/PostedLoadsPanelActionsReducer';
import {
  postAvailabilityFormReducer,
  TruckAvailabilityFormState,
} from '@/reduxStore/reducer/PostTruckAvailabilityReducer';
import { snackbarReducer, SnackbarState } from '@/reduxStore/reducer/SnackbarReducer';
import { truckLocatorReducer, TruckLocatorState } from '@/reduxStore/reducer/TruckReducer';
import { underDevelopmentReducer, underDevelopmentStateReconciler } from '@/reduxStore/reducer/UnderDevelopmentReducer';
import { ImmutableLRUMap } from '@common/helper';
import { avatarReducer, AvatarState } from '@common/redux/epic/AvatarEpic';
import { bidsReducer } from '@common/redux/epic/bids/BidsEpic';
import { BidsState } from '@common/redux/epic/bids/BidsStateHelper';
import { vendorBidsReducer, VendorBidsState } from '@common/redux/epic/bids/VendorBidsEpic';
import { brokerReducer, BrokerState } from '@common/redux/epic/BrokerEpic';
import { carrierTrackingReducer, CarrierTrackingState } from '@common/redux/epic/CarrierTrackingEpic';
import { communicationReducer, CommunicationState } from '@common/redux/epic/CommunicationEpic';
import { CompaniesAvatarState, companyAvatarReducer } from '@common/redux/epic/CompanyAvatarEpic';
import { companyReducer, CompanyState, contactReducer, ContactState } from '@common/redux/epic/CompanyEpic';
import { documentsReducer, DocumentsState } from '@common/redux/epic/DocumentsEpic';
import { feedbackReducer, FeedbackState } from '@common/redux/epic/FeedbackEpic';
import { ftpReducer, FtpState } from '@common/redux/epic/FtpEpic';
import { identityVerificationReducer, IdentityVerificationState } from '@common/redux/epic/IdentityVerificationEpic';
import { inboxReducer, InboxState } from '@common/redux/epic/InboxEpic';
import { loadAvailabilityReducer, LoadAvailabilityState } from '@common/redux/epic/LoadAvailabilityEpic';
import { loadDetailsReducer, LoadDetailsReducerKey, LoadDetailsState } from '@common/redux/epic/LoadDetailsEpic';
import { loadDetailsMapReducer, LoadDetailsMapState } from '@common/redux/epic/LoadDetailsMapEpic';
import { loadFeedbackReducer, LoadFeedbackState } from '@common/redux/epic/LoadFeedbackEpic';
import { loadInfoReducer, LoadInfoState } from '@common/redux/epic/LoadInfoEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { companySearchReducer, CompanySearchState } from '@common/redux/epic/loadSearch/CompanySearchEpic';
import { backhaulsReducer, LoadBackhaulsState } from '@common/redux/epic/loadSearch/LoadSearchBackhaulsEpic';
import { loadSearchReducer, LoadSearchState } from '@common/redux/epic/loadSearch/LoadSearchEpic';
import { companyMapSearchReducer, mapSearchReducer, MapSearchState } from '@common/redux/epic/loadSearch/MapSearchEpic';
import { loadSearchSettingsReducer, LoadSearchSettingsState } from '@common/redux/epic/LoadSearchSettingsEpic';
import { loadsReducer, LoadsState } from '@common/redux/epic/LoadsEpic';
import { locationMatchReducer, LocationMatchState } from '@common/redux/epic/LocationMatchEpic';
import { marketRatesReducer, MarketRatesState } from '@common/redux/epic/MarketRatesEpic';
import { myCarrierPortalReducer, MyCarrierPortalState } from '@common/redux/epic/MyCarrierPortalEpic';
import { myLoadsReducer, MyLoadsState } from '@common/redux/epic/MyLoadsEpic';
import { paymentReducer, PaymentState } from '@common/redux/epic/PaymentEpic';
import { permissionsReducer, PermissionsState } from '@common/redux/epic/PermissionsEpic';
import { postTruckReducer, PostTruckState } from '@common/redux/epic/PostTruckEpic';
import {
  fuelAdvanceQuoteReducer,
  fuelCardQuoteReducer,
  getPaidFasterQuoteReducer,
  loadQuotesReducer,
  LoadQuotesState,
  QuoteState,
  roadsideAssistQuoteReducer,
  truckingAuthorityQuoteReducer,
} from '@common/redux/epic/QuoteEpic';
import { rateCheckReducer, RateCheckState } from '@common/redux/epic/RateCheckEpic';
import { recentSearchLocationsReducer, RecentSearchLocationsState } from '@common/redux/epic/RecentSearchLocationsEpic';
import { routeReducer, RouteState } from '@common/redux/epic/RoutesEpic';
import { ServiceCodeReducer, ServiceCodeState } from '@common/redux/epic/ServiceCodeEpic';
import { settingsReducer } from '@common/redux/epic/SettingsEpic';
import { SETTINGS_REDUCER_KEY, SettingsState } from '@common/redux/epic/SettingsStateHelper';
import { sharedLoadReducer, SharedLoadState } from '@common/redux/epic/SharedLoadEpic';
import { systemTimeReducer, SystemTimeState } from '@common/redux/epic/SystemTimeEpic';
import { termsAndConditionsReducer, TermsAndConditionsState } from '@common/redux/epic/TermsAndConditionsEpic';
import { tmsReducer, TmsState } from '@common/redux/epic/TmsEpic';
import { tripBuilderReducer, TripBuilderState } from '@common/redux/epic/TripBuilderEpic';
import { TRUCK_ALERTS_REDUCER_KEY, truckAlertsReducer, TruckAlertsState } from '@common/redux/epic/TruckAlertsEpic';
import { uploadFileReducer, UploadState } from '@common/redux/epic/UploadFileEpic';
import { userReducer, UserState } from '@common/redux/epic/UserEpic';
import { userPlanReducer, UserPlanState } from '@common/redux/epic/UserPlanManagementEpic';
import { userTruckReducer, UserTrucksState } from '@common/redux/epic/UserTrucksEpic';
import { verifyPhoneReducer, VerifyPhoneState } from '@common/redux/epic/VerifyPhoneEpic';
import { lastRequestOverrideMiddleware } from '@common/redux/LastRequestOverrideMiddleware';
import { ScreenSharingReducer, ScreenSharingState } from '@common/redux/reducer/ScreenSharingReducer';
import { ribbonReducer, RibbonState } from '@common/redux/reducer/TopRibbonReducer';
import { tutorialReducer, TutorialState } from '@common/redux/reducer/TutorialReducer';
import { UnderDevelopmentState } from '@common/redux/reducer/UnderDevelopmentBaseReducer';
import { membersInfoReducer, MembersInfoState } from '@webApi/MembersEpic';

import { carrierReducer, CarrierState } from './epic/CarrierEpic';
import { feedReducer, FeedState } from './epic/FeedEpic';
import { inboxMessageContentReducer, InboxMessageContentState } from './epic/InboxMessageContentEpic';
import { loadMetricsReducer, LoadMetricsState } from './epic/LoadMetricsEpic';
import { PCMilerReducer, PCMilerState } from './epic/PCMilerEpic';
import { unsubscribeReducer, UnsubscribeState } from './epic/UnsubscribeEpic';
import { welcomebackCampaignReducer, WelcomebackCampaignState } from './epic/WelcomebackCampaignEpic';

const persist = <S, A extends Action = Action>(
  baseReducer: Reducer<S, A>,
  config: Partial<PersistConfig<S>> & { key: string }
) => {
  return persistReducer(
    {
      version: 0,
      stateReconciler: autoMergeLevel2,
      storage: localStorage,
      ...config,
    },
    baseReducer
  );
};

export interface StoreState {
  // Keep this sorted
  applicationSettings: ApplicationSettingsState;
  avatar: AvatarState;
  backhauls: LoadBackhaulsState;
  bids: BidsState;
  bidsVendor: VendorBidsState;
  brokers: BrokerState;
  capacityFinder: CapacityFinderState;
  carriers: CarrierState;
  carrierTracking: CarrierTrackingState;
  communication: CommunicationState;
  companyAvatar: CompaniesAvatarState;
  companyMapSearch: MapSearchState;
  companySearch: CompanySearchState;
  companyState: CompanyState;
  connectionError: ConnectionErrorState;
  contact: ContactState;
  documents: DocumentsState;
  feedState: FeedState;
  feedbackState: FeedbackState;
  ftp: FtpState;
  fuelAdvanceQuote: QuoteState;
  fuelCardQuoteState: QuoteState;
  getPaidFasterQuoteState: QuoteState;
  identifyVerificationState: IdentityVerificationState;
  inboxMessageContent: InboxMessageContentState;
  inboxState: InboxState;
  loadAvailability: LoadAvailabilityState;
  loadDetails: LoadDetailsState;
  loadDetailsBackhauls: LoadDetailsState;
  loadDetailsCompanySearch: LoadDetailsState;
  loadDetailsMap: LoadDetailsMapState;
  loadDetailsTabs: LoadDetailsTabsState;
  loadFeedback: LoadFeedbackState;
  loadInfo: LoadInfoState;
  loadMetrics: LoadMetricsState;
  loadPost: PostLoadState;
  loadPostFilters: LoadPostFiltersState;
  loadQuotes: LoadQuotesState;
  loadSearch: LoadSearchState;
  loadSearchForm: LoadSearchFormState;
  loadSearchSettings: LoadSearchSettingsState;
  loadVisibilityRules: LoadVisibilityRulesState;
  loadsState: LoadsState;
  locationMatches: ImmutableLRUMap<string, LocationMatchState>;
  locationPickerState: LocationPickerState;
  locationState: LocationState;
  mapSearch: MapSearchState;
  marketRates: MarketRatesState;
  membersInfo: MembersInfoState;
  mileageLocationState: SelectedLocationsState;
  myLoads: MyLoadsState;
  myCarrierPortal: MyCarrierPortalState;
  onboardedCarriers: OnboardedCarriersState;
  panelState: PanelState;
  paymentState: PaymentState;
  pcmiler: PCMilerState;
  permissions: PermissionsState;
  postingContacts: PostingContactsState;
  postLoadFields: PostLoadFormState;
  postTruck: PostTruckState;
  [LoadDetailsReducerKey.POSTED]: LoadDetailsState;
  postedLoadsPanelActions: PostedLoadsPanelActionsState;
  privateNetwork: PrivateNetworkState;
  rateCheck: RateCheckState;
  recentSearchLocationsState: RecentSearchLocationsState;
  roadsideAssistQuoteState: QuoteState;
  routeState: RouteState;
  screenSharing: ScreenSharingState;
  searchDirectory: SearchDirectoryCompanyState;
  serviceCode: ServiceCodeState;
  [SETTINGS_REDUCER_KEY]: SettingsState;
  sharedLoadState: SharedLoadState;
  snackbarState: SnackbarState;
  systemTime: SystemTimeState;
  termsAndConditionsState: TermsAndConditionsState;
  tms: TmsState;
  topRibbon: RibbonState;
  tripBuilder: TripBuilderState;
  [TRUCK_ALERTS_REDUCER_KEY]: TruckAlertsState;
  truckAvailabilityForm: TruckAvailabilityFormState;
  truckLocatorState: TruckLocatorState;
  truckState: TruckState;
  truckingAuthorityQuoteState: QuoteState;
  tutorialState: TutorialState;
  underDevelopment: UnderDevelopmentState;
  unsubscribeState: UnsubscribeState;
  uploadFile: UploadState;
  urlScrape: URLScrapeState;
  user: UserState;
  userActivity: UserActivityState;
  userPlan: UserPlanState;
  userTrucks: UserTrucksState;
  usersSettings: UsersSettingState;
  verifyEmail: VerifyEmailState;
  verifyPhone: VerifyPhoneState;
  welcomebackCampaignState: WelcomebackCampaignState;
}

const router = routerMiddleware(history);
const createRootReducer = (connectedHistory: History<any>) =>
  combineReducers({
    // Keep this sorted
    applicationSettings: persist(applicationSettingsReducer, {
      key: 'applicationSettings',
      whitelist: ['uiMenuLayout', 'menuOpen', 'expandedSections', 'sideMenuCollapsedSections'],
      migrate: migrateAppSettings(localStorage),
    }),
    avatar: avatarReducer,
    backhauls: backhaulsReducer,
    bids: bidsReducer,
    bidsVendor: vendorBidsReducer,
    brokers: brokerReducer,
    capacityFinder: persist(capacityFinderReducer, {
      key: 'capacityFinderSearchForm',
      whitelist: ['lastSearchFormData'],
    }),
    carriers: carrierReducer,
    carrierTracking: carrierTrackingReducer,
    communication: persist(communicationReducer, { key: 'communication', whitelist: ['isCommunicationsEnabled'] }),
    companyAvatar: companyAvatarReducer,
    companyMapSearch: companyMapSearchReducer,
    companySearch: companySearchReducer,
    companyState: companyReducer,
    connectionError: connectionErrorReducer,
    contact: contactReducer,
    documents: documentsReducer,
    feedState: feedReducer,
    feedbackState: feedbackReducer,
    ftp: ftpReducer,
    fuelAdvanceQuote: fuelAdvanceQuoteReducer,
    fuelCardQuoteState: fuelCardQuoteReducer,
    getPaidFasterQuoteState: getPaidFasterQuoteReducer,
    identifyVerificationState: identityVerificationReducer,
    inboxMessageContent: inboxMessageContentReducer,
    inboxState: inboxReducer,
    loadAvailability: loadAvailabilityReducer,
    loadDetails: loadDetailsReducer(LoadSearchType.LoadSearch),
    loadDetailsBackhauls: loadDetailsReducer(LoadSearchType.Backhaul),
    loadDetailsCompanySearch: loadDetailsReducer(LoadSearchType.CompanySearch),
    loadDetailsMap: loadDetailsMapReducer,
    loadDetailsTabs: persist(loadDetailsTabsStateReducer, {
      key: 'loadDetailsTabs',
      whitelist: ['openedLoadDetailsTabs'],
    }),
    loadFeedback: loadFeedbackReducer,
    loadInfo: loadInfoReducer,
    loadMetrics: loadMetricsReducer,
    loadPost: postLoadReducer,
    loadPostFilters: filterLoadsReducer,
    loadQuotes: loadQuotesReducer,
    loadSearch: loadSearchReducer,
    loadSearchForm: loadSearchFormReducer,
    loadSearchSettings: persist(loadSearchSettingsReducer, {
      key: 'loadSearchSettings',
      whitelist: ['viewOption'],
    }),
    loadVisibilityRules: loadVisibilityRulesReducer,
    loadsState: loadsReducer,
    locationMatches: locationMatchReducer,
    locationPickerState: persist(LocationPickerReducer, {
      key: 'locationPicker',
      whitelist: ['isStatesPickerOpened', 'isRegionsPickerOpened'],
    }),
    locationState: locationReducer,
    mapSearch: mapSearchReducer,
    marketRates: persist(marketRatesReducer, {
      key: 'marketRates',
      whitelist: ['isAcceptancePointExpansionPanelOpen', 'isCapacityFinderExpansionPanelOpen', 'searchForm'],
    }),
    membersInfo: membersInfoReducer,
    mileageLocationState: mileageCalculatorReducer,
    myLoads: myLoadsReducer,
    myCarrierPortal: myCarrierPortalReducer,
    onboardedCarriers: onboardedCarriersReducer,
    panelState: panelReducer,
    paymentState: paymentReducer,
    pcmiler: PCMilerReducer,
    permissions: permissionsReducer,
    postingContacts: postingContactsReducer,
    postLoadFields: persist(postLoadsFormReducer, {
      key: 'lastUsedPostingContact',
      whitelist: ['lastUsedPostingContact', 'isPostingWithVisibilityRules'],
    }),
    postTruck: postTruckReducer,
    [LoadDetailsReducerKey.POSTED]: loadDetailsReducer(LoadSearchType.Posted),
    postedLoadsPanelActions: postedLoadsPanelActionsReducer,
    privateNetwork: privateNetworkReducer,
    rateCheck: rateCheckReducer,
    recentSearchLocationsState: recentSearchLocationsReducer,
    roadsideAssistQuoteState: roadsideAssistQuoteReducer,
    routeState: routeReducer,
    router: connectRouter(connectedHistory),
    screenSharing: ScreenSharingReducer,
    searchDirectory: searchDirectoryCompanyReducer,
    serviceCode: ServiceCodeReducer,
    [SETTINGS_REDUCER_KEY]: persist(settingsReducer, { key: SETTINGS_REDUCER_KEY, whitelist: ['systemSetting'] }),
    sharedLoadState: sharedLoadReducer,
    snackbarState: snackbarReducer,
    systemTime: systemTimeReducer,
    termsAndConditionsState: termsAndConditionsReducer,
    tms: tmsReducer,
    topRibbon: ribbonReducer,
    tripBuilder: persist(tripBuilderReducer, { key: 'tripBuilder', whitelist: ['form', 'viewType'] }),
    [TRUCK_ALERTS_REDUCER_KEY]: truckAlertsReducer,
    truckAvailabilityForm: persist(postAvailabilityFormReducer, {
      key: 'postedTrucks',
      whitelist: ['lastPostedOrigin'],
    }),
    truckLocatorState: truckLocatorReducer,
    truckState: persist(trucksReducer, { key: 'trucks', whitelist: ['openCarrierDetailsTabs', 'isMapViewSelected'] }),
    truckingAuthorityQuoteState: truckingAuthorityQuoteReducer,
    tutorialState: tutorialReducer,
    underDevelopment: persist(underDevelopmentReducer, {
      key: 'underDevelopment',
      whitelist: ['flags'],
      stateReconciler: underDevelopmentStateReconciler,
    }),
    unsubscribeState: unsubscribeReducer,
    uploadFile: uploadFileReducer,
    urlScrape: urlScrapeReducer,
    user: persist(userReducer, { key: 'last-idversion', whitelist: ['lastMaintenanceIdVersion', 'userType'] }),
    userActivity: userActivityReducer,
    userPlan: userPlanReducer,
    userTrucks: userTruckReducer,
    usersSettings: usersSettingsReducer,
    verifyEmail: verifyEmailReducer,
    verifyPhone: verifyPhoneReducer,
    welcomebackCampaignState: welcomebackCampaignReducer,
  });
export const epicMiddleware = createEpicMiddleware();
const middleware = applyMiddleware(lastRequestOverrideMiddleware, epicMiddleware, router);
const connectedRootReducer = createRootReducer(history);

const composeEnhancers: typeof compose =
  process.env.NODE_ENV === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const doCreateStore = () => {
  if (process.env.NODE_ENV === 'development') {
    // build process will strip out unused code in production,
    // hence the usage of 'require'
    require('@util/ReactotronConfig');
    const Reactotron = require('reactotron-react-js').default;
    return Reactotron.createStore(connectedRootReducer, composeEnhancers(middleware));
  } else {
    return createStore(connectedRootReducer, composeEnhancers(middleware));
  }
};

export const store = doCreateStore();

export const persistor = persistStore(store);

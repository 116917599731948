import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';

import { useSelector } from '@/util/hooks';
import { getInitials } from '@common/helper/UserHelper';
import { fetchSmallAvatar } from '@common/redux/epic/AvatarEpic';
import { Color } from '@style/Color';
import { withTheme } from '@style/WithTheme';

const UserProfileButton = withTheme()(styled(ButtonBase)`
  && {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    width: 74px;
    min-width: 74px;
  }
`);

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: solid 1px #f7f9fb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
`;

const TextContainerSmall = styled.div`
  margin-top: 3px;
  color: ${Color.ORANGE_MAIN};
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
`;

const ImageViewer = styled.img`
  max-width: 40px;
  max-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: white;
`;

interface UserAvatarProps {
  id: string;
  icon: JSX.Element;
  component?: React.ElementType<ButtonBaseProps>;
  href?: string;
  label: string | JSX.Element;
  firstName?: string;
  lastName?: string;
  onClick: () => void;
}

export const UserAvatarContainer: React.SFC<UserAvatarProps> = (props) => {
  const currentAvatar = useSelector((state) => state.avatar.currentAvatarSmall);
  const isFetchingCurrentAvatar = useSelector((state) => state.avatar.isFetchingForSmall);
  const wasAvatarDeleted = useSelector((state) => state.avatar.wasDeleteAvatarCompleted);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((currentAvatar === undefined && !isFetchingCurrentAvatar) || wasAvatarDeleted) {
      dispatch(fetchSmallAvatar());
    }
  }, [currentAvatar, wasAvatarDeleted]);

  return (
    <UserProfileButton component={props.component} href={props.href} onClick={props.onClick} disableRipple={true}>
      {currentAvatar ? (
        <ImageViewer src={currentAvatar} />
      ) : (
        <IconContainer>
          {getInitials({ firstName: props.firstName ?? '', lastName: props.lastName ?? '' })}
        </IconContainer>
      )}
      <TextContainerSmall>{props.label}</TextContainerSmall>
    </UserProfileButton>
  );
};

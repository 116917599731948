import { forEach } from 'lodash';

import * as signalR from '@microsoft/signalr';

import { Api } from '@common/api';
import { CommunicationClient } from '@common/client/CommunicationClient';
import { voidFunction } from '@common/helper';
import { UpdateBidEvent } from '@common/model';
import {
  MessageStatusChanged,
  NewMessageEvent,
  UnreadConversationCountUpdate,
  UpdateMessagesStateEvent,
} from '@common/model/Conversation';

import { SettingsUpdate } from './SignalRSettings';

interface CommunicationConfig {
  onNewMessage?: (newMessage: NewMessageEvent) => void;
  onUpdatedConversations?: (updatedIds: string[]) => void;
  onUpdateConversationCount?: (update: UnreadConversationCountUpdate) => void;
  onMessageStatusChange?: (status: MessageStatusChanged) => void;
  onUpdateClientStatus?: () => void;
  onSettingsUpdate?: (update: SettingsUpdate) => void;
  onUpdateMessagesState?: (update: UpdateMessagesStateEvent) => void;
  onNewBids?: (update: UpdateBidEvent) => void;
  onUpdatedBids?: (update: UpdateBidEvent) => void;
  onNewTruckAlert?: () => void;
}

export enum TopicCategory {
  General = 'general',
  Communication = 'communication',
  Bidding = 'bidding',
}

export type CommunicationStatsUpdate = 'alive' | 'foreground' | 'background' | 'offline';
export enum CommunicationTopic {
  NewMessage = 'NewMessage',
  UpdatedConversations = 'UpdatedConversations',
  UpdateConversationCount = 'UpdateConversationCount',
  MessageStatusChange = 'MessageStatusChange',
  UpdateClientStatus = 'UpdateClientStatus',
  SettingsUpdate = 'SettingsUpdate',
  UpdateMessagesState = 'UpdateMessagesState',
  NewBids = 'NewBids',
  UpdatedBids = 'UpdatedBids',
  TruckAlert = 'TruckAlert',
}

export type ConnectedTopics = Record<TopicCategory, CommunicationTopic[]>;

export const EmptyConnectedTopics: ConnectedTopics = {
  [TopicCategory.General]: [],
  [TopicCategory.Communication]: [],
  [TopicCategory.Bidding]: [],
};

export enum ClientType {
  iOS = 'ios',
  Android = 'android',
  Web = 'web',
}

interface ConnectedTopic {
  name: string;
  connect: () => void;
}

/**
 * @FIXME // IMPORTANT NOTE ON DEBUGGING
 * This class contains a 'connection' object that is assigned to a signalR Connection
 * upon successfully connecting to a signalR socket. When refreshing the connection,
 * we tell the existing connection object to "stop", and then start a new connection. This
 * works well in prod/beta/dev builds, but, if you are running the app in debug mode and you
 * have auto-refresh on (or you manually refresh the JS), a new connection will be started
 * without the previous one being "stopped". This will result in possibly having multiple
 * copies of handlers on each topic. For instance, after refreshing once, you might end
 * up with 2 copies of the 'new message' handler and see 2 copies of each new message
 * appear in your chat screen.
 *
 * Thus, when developing with debug mode, you should turn off auto-refresh JS, and
 * restart the app after each manual JS refresh.
 *
 * This issue should be addressed at some point, but since it only affects debugging,
 * we just have this warning for now.
 */
export class CommunicationService {
  client: CommunicationClient;
  clientType: ClientType;
  minimalLogging: boolean;
  constructor(api: Api, clientType: ClientType, minimalLogging: boolean) {
    this.client = new CommunicationClient(api);
    this.clientType = clientType;
    this.minimalLogging = minimalLogging;
  }

  private connection: signalR.HubConnection | undefined;
  private connectedTopics: Array<ConnectedTopic> = [];
  private pendingConnectedTopics: Array<ConnectedTopic> = [];
  isConnectionPermanentlyClosed = false;
  recentCauseOfConnectionPermanentlyClosed: string | undefined;

  startListening = async () => {
    this.connection?.stop();
    if (this.isConnectionPermanentlyClosed) {
      this.resetIsConnectionPermanentlyClosed();
      this.pendingConnectedTopics = [...this.connectedTopics, ...this.pendingConnectedTopics];
      this.connectedTopics = [];
    }
    const resp = await this.client.negotiate$().toPromise();
    if (!resp) {
      return;
    }
    await resp.result(
      (data) => {
        if (data.url && data.accessToken) {
          return this.startConnection(data.url, data.accessToken);
        } else {
          this.connectionWasPermanentlyClosed('negotiation returned data with undefined url or accessToken');
          return voidFunction;
        }
      },
      (apiError) => {
        this.connectionWasPermanentlyClosed(`${apiError.title}: ${apiError.message}`);
        return Promise.reject();
      }
    );
  };

  startConnection = async (url: string, token: string) => {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => token,
      })
      .configureLogging(this.minimalLogging ? signalR.LogLevel.Critical : signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();
    this.connection.serverTimeoutInMilliseconds = 120000;
    this.connection.onclose(this.onConnectionClosed);
    await this.connection.start();
    forEach(this.pendingConnectedTopics, (topic) => {
      topic.connect();
      this.connectedTopics.push(topic);
    });
    this.pendingConnectedTopics = [];
  };

  resetIsConnectionPermanentlyClosed = () => {
    this.isConnectionPermanentlyClosed = false;
    this.recentCauseOfConnectionPermanentlyClosed = undefined;
  };

  connectionWasPermanentlyClosed = (cause: string) => {
    this.isConnectionPermanentlyClosed = true;
    this.recentCauseOfConnectionPermanentlyClosed = cause;
  };

  /**
   * When signalR gets disconnected permanently (all reconnection attempts have been
   * exhausted) this handler is called.
   */
  onConnectionClosed = (error: Error | undefined) => {
    this.connectionWasPermanentlyClosed(
      error ? `${error.name}: ${error.message}\n${error.stack}` : 'connection closed with no error provided'
    );
  };

  /**
   * Connects to an individual topic.
   */
  connectTo = <T>(topic: string, callback: (value: T) => void) => {
    const connectedTopic: ConnectedTopic = {
      name: topic,
      connect: () => {
        this.connection?.on(topic, callback);
      },
    };
    if (!this.isConnected()) {
      this.pendingConnectedTopics.push(connectedTopic);
    } else {
      connectedTopic.connect();
      this.connectedTopics.push(connectedTopic);
    }
  };

  isConnected = () => this.connection?.state === signalR.HubConnectionState.Connected;

  isPermanentlyDisconnected = () => !this.isConnected() && this.isConnectionPermanentlyClosed;

  connectToTopics = (config: CommunicationConfig) => {
    const connectedTopics: CommunicationTopic[] = [];
    if (config.onNewMessage) {
      this.connectTo(CommunicationTopic.NewMessage, config.onNewMessage);
      connectedTopics.push(CommunicationTopic.NewMessage);
    }
    if (config.onUpdatedConversations) {
      this.connectTo(CommunicationTopic.UpdatedConversations, (data: any) =>
        config.onUpdatedConversations?.(data?.conversationIds || [])
      );
      connectedTopics.push(CommunicationTopic.UpdatedConversations);
    }

    if (config.onUpdateMessagesState) {
      this.connectTo(CommunicationTopic.UpdateMessagesState, config.onUpdateMessagesState);
      connectedTopics.push(CommunicationTopic.UpdateMessagesState);
    }

    if (config.onUpdateConversationCount) {
      this.connectTo(CommunicationTopic.UpdateConversationCount, config.onUpdateConversationCount);
      connectedTopics.push(CommunicationTopic.UpdateConversationCount);
    }

    if (config.onMessageStatusChange) {
      this.connectTo(CommunicationTopic.MessageStatusChange, config.onMessageStatusChange);
      connectedTopics.push(CommunicationTopic.MessageStatusChange);
    }

    if (config.onUpdateClientStatus) {
      this.connection?.on(CommunicationTopic.UpdateClientStatus, config.onUpdateClientStatus);
      connectedTopics.push(CommunicationTopic.UpdateClientStatus);
    }

    if (config.onSettingsUpdate) {
      this.connection?.on(CommunicationTopic.SettingsUpdate, config.onSettingsUpdate);
      connectedTopics.push(CommunicationTopic.SettingsUpdate);
    }

    if (config.onNewBids) {
      this.connection?.on(CommunicationTopic.NewBids, config.onNewBids);
      connectedTopics.push(CommunicationTopic.NewBids);
    }

    if (config.onUpdatedBids) {
      this.connection?.on(CommunicationTopic.UpdatedBids, config.onUpdatedBids);
      connectedTopics.push(CommunicationTopic.UpdatedBids);
    }

    if (config.onNewTruckAlert) {
      this.connection?.on(CommunicationTopic.TruckAlert, config.onNewTruckAlert);
      connectedTopics.push(CommunicationTopic.TruckAlert);
    }

    return connectedTopics;
  };

  disconnectFromTopic = (topic: CommunicationTopic) => {
    this.connection?.off(topic);
  };

  updateClientStatus = (status: CommunicationStatsUpdate) => {
    if (this.connection?.state === signalR.HubConnectionState.Connected) {
      this.connection?.send('UpdateClientStatus', {
        type: this.clientType,
        status: status,
      });
    }
  };

  stopListening = () => {
    if (
      this.connection &&
      this.connection?.state !== signalR.HubConnectionState.Disconnected &&
      this.connection?.state !== signalR.HubConnectionState.Disconnecting
    ) {
      this.connection?.stop();
      this.pendingConnectedTopics = [];
      this.connectedTopics = [];
    }
  };
}

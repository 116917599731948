import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action, bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import { LoadDetailsTabs } from '@/model';
import { StoreState } from '@/reduxStore/Store';
import { formatNumber } from '@common/helper';
import { BackhaulsLoadSearchQuery, Load, LoadBackhaul, LoadSearchRequest } from '@common/model';
import { defaultLoadInfo, fetchLoadBackhauls, LoadBackhaulsResult, LoadInfo } from '@common/redux/epic/LoadInfoEpic';
import {
  backhaulsActions,
  loadSearchQueryFromBackhaulsQuery,
} from '@common/redux/epic/loadSearch/LoadSearchBackhaulsEpic';
import { BackhaulsCard } from '@component/cards/BackhaulsCard';
import { AccordionInfoStatus, ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { ExpansionPanelDataWrapper } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { Text, TextStyle } from '@component/text';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

import { default as BackhaulsIcon } from './backhauls.svg';
import { default as PostTruck } from './post-truck.svg';

interface ReduxProps {
  status: AccordionInfoStatus;
  backhauls: LoadBackhaulsResult | undefined;
}

interface DispatchProps {
  fetchLoadBackhauls(loadID: string, fromNamedSearchId?: string): void;
  backhaulsSetQuery(query: BackhaulsLoadSearchQuery): void;
  setSelectedSearch(search: LoadSearchRequest | undefined): void;
}
const LinkWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: auto;
`;
interface ComponentProps {
  openBackhaulsPanel?: (id: string | number) => void;
  handleChange: (tab: LoadDetailsTabs, status: boolean) => void;
  isOpened: boolean;
  selectedSearch: LoadSearchRequest | undefined;
  isPostedLoad?: boolean;
  loadInfo: LoadInfo | undefined;
  shouldDisplayPostTruck: boolean;
  onPostClick?: () => void;
  load: Load | undefined;
}

type Props = ReduxProps & DispatchProps & ComponentProps & RouteComponentProps<{ loadID: string }>;

class Backhaul extends React.Component<Props> {
  componentDidMount() {
    this.fetchLoadBackhauls();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.selectedSearch && this.props.selectedSearch) {
      this.fetchLoadBackhauls();
    }
  }

  fetchLoadBackhauls = () => {
    const { selectedSearch } = this.props;
    if (this.props.isPostedLoad) {
      this.props.fetchLoadBackhauls(this.props.load?.id ?? '');
    } else {
      this.props.fetchLoadBackhauls(this.props.load?.id ?? '', selectedSearch?.id);
    }
  };

  countPossibleLoads = () => {
    const { backhauls } = this.props;
    let count = 0;
    if (backhauls && backhauls.payload) {
      backhauls.payload.forEach((item: any) => {
        count = count + item.count;
      });
    }
    return formatNumber(count);
  };

  handleClick = (backhaul: LoadBackhaul) =>
    this.props.openBackhaulsPanel
      ? () => {
          if (backhaul.count && backhaul.count > 0 && this.props.openBackhaulsPanel) {
            this.props.backhaulsSetQuery(backhaul.query);
            this.props.setSelectedSearch({
              ...loadSearchQueryFromBackhaulsQuery(backhaul.query),
              id: backhaul.id,
            });
            this.props.openBackhaulsPanel(backhaul.id);
          }
        }
      : undefined;

  handleTabClick = () => {
    this.props.handleChange(LoadDetailsTabs.Backhauls, !this.props.isOpened);
  };
  onPostClick = () => {
    if (this.props.onPostClick) {
      this.props.onPostClick();
    }
  };

  backhaulsPostTruckRow = () => {
    return (
      <ExpansionPanelDataWrapper
        style={{ display: 'flex', flexDirection: 'row', width: '100%', height: ElementSize.ButtonHeight }}
      >
        <LinkWrapper onClick={this.onPostClick}>
          <img
            id="post_truck_icon"
            src={PostTruck}
            className="post-truck-icon"
            alt="post-truck-icon"
            height={ElementSize.Icon}
            style={{ marginRight: Spacing.InterListElement }}
          />
          <Text id="post_truck" textStyle={TextStyle.Link}>
            {t(T.common_postTruck_title)}
          </Text>
        </LinkWrapper>
      </ExpansionPanelDataWrapper>
    );
  };

  render() {
    const { backhauls } = this.props;

    return (
      <ExpansionPanel
        id="backhauls"
        titleText={t(T.backhauls_Backhauls)}
        subtitleText={
          +this.countPossibleLoads() === 1
            ? t(T.backhauls_possibleLoad)
            : t(T.backhauls_possibleLoads, { value: this.countPossibleLoads() })
        }
        icon={BackhaulsIcon}
        isBlocked={!(backhauls && backhauls.success)}
        handleClick={this.handleTabClick}
        isOpened={this.props.isOpened}
        status={this.props.status}
      >
        <ExpansionPanelDataWrapper>
          {backhauls &&
            backhauls.payload &&
            backhauls.payload.map((item: any) => {
              return (
                <BackhaulsCard
                  key={item.id}
                  destinationFrom={item.from}
                  destinationTo={item.to}
                  backhauls={item.count}
                  onClick={this.handleClick(item)}
                  isPostedLoad={this.props.isPostedLoad}
                />
              );
            })}
          {this.props.shouldDisplayPostTruck && this.backhaulsPostTruckRow()}
        </ExpansionPanelDataWrapper>
      </ExpansionPanel>
    );
  }
}

const mapStateToProps = (state: StoreState, props: ComponentProps): ReduxProps => {
  let status: AccordionInfoStatus = AccordionInfoStatus.AVAILABLE;
  const loadInfo = props.loadInfo || defaultLoadInfo;
  if (loadInfo && loadInfo.isLoadingBackhauls) {
    status = AccordionInfoStatus.LOADING;
  } else if ((loadInfo && !loadInfo.backhauls) || (loadInfo && loadInfo.backhauls && !loadInfo.backhauls.payload)) {
    status = AccordionInfoStatus.UNAVAILABLE;
  }

  return {
    status: status,
    backhauls: loadInfo?.backhauls,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      fetchLoadBackhauls: fetchLoadBackhauls,
      backhaulsSetQuery: (query: BackhaulsLoadSearchQuery) => backhaulsActions.backhaulsSetQuery(query, undefined),
      setSelectedSearch: backhaulsActions.setSelectedSearch,
    },
    dispatch
  );

export const Backhauls = connect(mapStateToProps, mapDispatchToProps)(withRouter(Backhaul));

import { find } from 'lodash';
import { Action } from 'redux';

import { BulkLoadRateCheckPreviewResponse, Load, RateCheckPreviewStatus } from '@common/model';
import { ResponseAction } from '@common/redux/Base';

export interface FetchRateCheckPreviewAction extends Action {
  loadIds: string[];
  forSimilarLoads: boolean;
}

export type RateCheckPreviewResponseAction = ResponseAction<BulkLoadRateCheckPreviewResponse>;

export const processRateCheckPreviewResponse = (load: Load, responsePayload: BulkLoadRateCheckPreviewResponse) => {
  const rate = find(responsePayload.rates, (r) => r.loadId === load.id);
  if (rate) {
    if (rate.isRateFound) {
      load.rateCheckPreview = {
        status: RateCheckPreviewStatus.Available,
        ratePerMile: rate.ratePerMile,
        totalRate: rate.totalRate,
      };
    } else {
      load.rateCheckPreview = {
        status: RateCheckPreviewStatus.NotAvailable,
        ratePerMile: rate.ratePerMile,
        totalRate: rate.totalRate,
      };
    }
  } else {
    const skippedRate = find(responsePayload.skippedLoads, (r) => r.loadId === load.id);
    if (skippedRate?.skipReasons) {
      if (skippedRate?.skipReasons[0]?.skipReason === RateCheckPreviewStatus.UnsupportedEquipmentType) {
        load.rateCheckPreview = {
          status: RateCheckPreviewStatus.UnsupportedEquipmentType,
          ratePerMile: undefined,
          totalRate: undefined,
        };
      } else if (skippedRate?.skipReasons[0]?.skipReason === RateCheckPreviewStatus.NoRateCheckAccess) {
        load.rateCheckPreview = {
          status: RateCheckPreviewStatus.NoRateCheckAccess,
          ratePerMile: undefined,
          totalRate: undefined,
        };
      } else {
        load.rateCheckPreview = {
          status: RateCheckPreviewStatus.NotAvailable,
          ratePerMile: undefined,
          totalRate: undefined,
        };
      }
    }
  }
};

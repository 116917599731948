import { CompanyMetadata } from './CompanyMetadata';
import { EquipmentType } from './Equipment';
import { LoadSize } from './Load';
import { RouteDirectionsAddress } from './PCMiler';

export enum NotificationType {
  NONE = 'None', // should never be received, but is nonetheless part of API
  AUTOMATIC_LOAD_MATCH = 'AutomaticLoadMatch',
  CHAT = 'Chat',
  DAILY_DIGEST = 'DailyDigest',
  LOAD_ALERT = 'LoadAlert',
  LOAD_ALERT_EN_ROUTE = 'LoadAlertEnRoute',
  NEWSLETTERS = 'NewsLetters',
  PROMOTIONS = 'Promotions',
  SYSTEM_MESSAGES = 'SystemMessages',
}

interface LoadInfo {
  companyName: string;
  originAddress: Partial<RouteDirectionsAddress>;
  destinationAddress: Partial<RouteDirectionsAddress>;
  pickupDateTime: string;
  loadSize: LoadSize;
  equipmentTypes: EquipmentType;
  age: number;
  active: boolean;
  length?: number;
  weight?: number;
  mileage?: number;
}

export interface Message {
  id: string;
  threadID: string;
  type: NotificationType;
  title: string;
  text: string;
  time: string;
  read: boolean;
  deleted: boolean;
  senderCompanyName?: string;
  companyMetadata?: CompanyMetadata;
  loadId?: string;
  threadMessagesCount?: number;
  threadUnreadMessagesCount?: number;
  loadInfo?: LoadInfo;
}

export interface MessagesResult {
  messages: Message[];
  resultCount: number;
  unreadMessagesCount: number;
}

export type MessagesByLoad = Map<string, Message[]>;

export interface MessageRequest {
  offset: number;
  limit: number;
}

export const createDefaultMessagesRequest = (): MessageRequest => ({
  offset: MESSAGES_REQUEST_OFFSET_DEFAULT,
  limit: MESSAGES_REQUEST_LIMIT_DEFAULT,
});

export const MESSAGES_REQUEST_OFFSET_DEFAULT = 0;

export const MESSAGES_REQUEST_LIMIT_DEFAULT = 20;
export const MESSAGES_REQUEST_LIMIT_APPENDING = 10;

import React from 'react';

import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

interface DispatchProps {
  dispatch: any; //Dispatch<AnyAction>; sadly, can't solve this typescript type mapping issue [JM/BP]
}

type Props = DispatchProps;

let staticDispatch: Dispatch | undefined;

export class ReduxRegistry {
  /** dispatch an action to redux */
  static dispatch(action: Action) {
    if (staticDispatch) {
      staticDispatch(action);
    }
  }
}

class ReduxRegistryComponent extends React.Component<Props> {
  componentDidMount() {
    staticDispatch = this.props.dispatch;
  }

  render() {
    return this.props.children;
  }

  componentWillUnmount() {
    staticDispatch = undefined;
  }
}

export const ReduxRegistryConnect =
  connect(/*
  undefined,
  (dispatch) => ({ dispatch: dispatch })
*/)(ReduxRegistryComponent);

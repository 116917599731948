import React, { RefObject, useEffect, useRef, useState } from 'react';

import { isEmpty, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import EmptyBids from '@/icons/empty-folder.svg';
import NoConversations from '@/icons/no_conversations.svg';
import { getBidsFilterOptions } from '@common/helper';
import { GENERAL_CONTACT_PHONE_NUMBER } from '@common/info';
import { BidStatus, Flag } from '@common/model';
import { BiddingRole } from '@common/model/Bid';
import { fetchBidSummaries } from '@common/redux/epic/bids/BidsEpic';
import { useBiddingSocketUpdates } from '@common/redux/epic/bids/BidsStateHelper';
import { fetchMoreVendorBids, fetchVendorBids } from '@common/redux/epic/bids/VendorBidsEpic';
import { getSystemSetting } from '@common/redux/epic/SettingsEpic';
import { FilterButton } from '@component/buttons/FilterButton';
import { PhoneNumber } from '@component/contact';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { usePushPanel } from '@component/panel/PanelHooks';
import {
  ChipsLine,
  FilterChip,
  FlexContainer,
  ResultAndFilterContainer,
} from '@component/panels/findLoads/searchResultPanel/SearchResultPanelStyle';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { Text, TextStyle } from '@component/text';
import { BidsList } from '@page/bids/BidsList';
import { bidsEncoder } from '@page/bids/BidsPanelRouting';
import { VendorBidsList } from '@page/bids/VendorBidsList';
import { Color } from '@style/Color';
import { FontLineHeight, FontSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

import BidsIcon from './clipart-bids.svg';
import { StyledGrid } from './style/BidsStyleHelper';

interface BidsPanelProps {
  isInteractive?: boolean;
  scrollRef?: RefObject<HTMLInputElement>;
}

const BidsPanel = (props: BidsPanelProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isCarrier = useIsCarrier();

  const isLoading = useSelector((state) => state.bids.bidSummaries.listAll.isLoading);

  const vendorBidsSummary = useSelector((state) => state.bidsVendor.bids);
  const isLoadingVendorBids = useSelector((state) => state.bidsVendor.isLoading);
  const isLastVendorBidPage = useSelector((state) => state.bidsVendor.isLastPage);
  const nextVendorBidsToken = useSelector((state) => state.bidsVendor.nextToken);
  const brokerAcceptsBids = useSelector((state) => state.settings.systemSetting[Flag.BrokerAcceptsBids].value);
  const bidFilters = useSelector((state) => state.bids.bidSummaries.listAll.selectedFilters);
  const isBidFiltersEmpty = isEmpty(bidFilters);
  const bidSummaryIds = useSelector((state) => state.bids.bidSummaries.listAll.ids);

  const innerScrollRef = useRef<HTMLInputElement>(null);
  const scrollContainerRef = props.scrollRef ?? innerScrollRef;

  const loadDetailsSearchParams = bidsEncoder.convertUrlSearchParamsToObject(history.location.search);

  const isCommunicationsOrigin = bidsEncoder.convertUrlSearchParamsToObject(
    history.location.search
  ).redirectCommunicationPath;

  const isStandalone =
    loadDetailsSearchParams?.redirectLoadDetailsPath === undefined && isCommunicationsOrigin === undefined;

  const isErrorScreen = !isLoading && bidSummaryIds === undefined;

  const isInteractiveListEmpty = !isLoading && bidSummaryIds.length === 0;

  const isPartnersBidsListEmpty = !props.isInteractive && vendorBidsSummary.length === 0;

  useBiddingSocketUpdates();

  useEffect(() => {
    if (!isCarrier) {
      dispatch(getSystemSetting(Flag.BrokerAcceptsBids));
    }
  }, []);

  useEffect(() => {
    if ((!isLoading && props.isInteractive) || bidFilters !== undefined) {
      dispatch(fetchBidSummaries(isCarrier ? [BiddingRole.Carrier] : [BiddingRole.Broker], bidFilters));
    } else if (!isLoadingVendorBids && !props.isInteractive) {
      dispatch(fetchVendorBids());
    }
  }, []);

  useEffect(() => {
    if (!props.isInteractive && !isLastVendorBidPage && nextVendorBidsToken) {
      dispatch(fetchMoreVendorBids(nextVendorBidsToken));
    }
  }, [isLastVendorBidPage, nextVendorBidsToken, bidFilters]);

  let content: JSX.Element;
  if (isErrorScreen) {
    content = <ErrorScreen />;
  } else if (props.isInteractive && !isBidFiltersEmpty && isInteractiveListEmpty) {
    content = (
      <>
        <BidsFilterSection />
        <EmptyFiltersScreen />
      </>
    );
  } else if (!isCarrier && !brokerAcceptsBids) {
    content = <BidsDisabledByBrokerScreen />;
  } else if (!props.isInteractive && isPartnersBidsListEmpty && isCarrier) {
    content = <EmptyScreen isCarrier={isCarrier} isInteractive={false} />;
  } else if (props.isInteractive && isInteractiveListEmpty) {
    content = <EmptyScreen isCarrier={isCarrier} isInteractive={props.isInteractive ?? false} />;
  } else if (props.isInteractive || !isCarrier) {
    content = (
      <>
        <BidsFilterSection />
        <BidsList scrollRef={scrollContainerRef} isStandalone={isStandalone} isLoading={isLoading} />
      </>
    );
  } else {
    content = (
      <VendorBidsList
        scrollRef={scrollContainerRef}
        vendorBidsSummary={vendorBidsSummary}
        isLastPage={isLastVendorBidPage}
        isLoading={isLoadingVendorBids}
        isStandalone={isStandalone}
      />
    );
  }

  return <>{content}</>;
};

export default BidsPanel;

const ErrorScreen: React.FC = () => {
  return <NotFoundPanel title="Error" imageSection={<img id="image" src={NoConversations} alt="error" />} />;
};

const ContentText = styled(Text)`
  font-size: ${FontSize.Content}px;
  line-height: ${FontLineHeight.MediumTitle}px;
  font-weight: regular;
  text-align: center;
  margin-bottom: ${Spacing.InterSection}px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${Spacing.DoubleInterSection}px ${Spacing.ElementPaddingHorizontalWide}px ${Spacing.InterSection}px
    ${Spacing.ElementPaddingHorizontalWide}px;
`;

const EmptyScreen: React.FC<{ isCarrier: boolean; isInteractive: boolean }> = (props) => {
  return props.isCarrier ? (
    <NotFoundPanel
      imageSection={<img id="image" src={EmptyBids} alt="error" />}
      title={t(T.common_bids_empty_title)}
      controlSection={
        <Container>
          <ContentText id="carrier_paragraph_1">
            {props.isInteractive ? t(T.common_bids_empty_message_carrier) : t(T.common_vendorBid_empty_message)}
          </ContentText>
        </Container>
      }
    />
  ) : (
    <NotFoundPanel
      title={t(T.common_bids_empty_title)}
      subtitle={t(T.common_bids_empty_message_broker)}
      imageSection={<img id="image" src={EmptyBids} alt="error" />}
    />
  );
};

const EmptyFiltersScreen: React.FC<{}> = () => {
  return (
    <NotFoundPanel
      imageSection={<img id="image" src={EmptyBids} alt="error" />}
      title={t(T.common_bids_noMatches_title)}
      controlSection={
        <Container>
          <ContentText id="carrier_paragraph_1">{t(T.common_bids_noMatches_subtitle)}</ContentText>
        </Container>
      }
    />
  );
};

const ChipsContainer = styled.div`
  padding-left: 14px;
  padding-bottom: 6px;
  margin-top: -8px;
`;

const BidsFilterSection: React.FC = () => {
  const dispatch = useDispatch();
  const isCarrier = useIsCarrier();
  const selectedFilters = useSelector((state) => state.bids.bidSummaries.listAll.selectedFilters);
  const [filterState, setFilterState] = useState(selectedFilters ?? []);

  const filterOptions = getBidsFilterOptions(filterState ?? []);

  const pushPanel = usePushPanel('/filter/');

  useEffect(() => {
    if (selectedFilters !== undefined) {
      setFilterState(selectedFilters);
    }
  }, [selectedFilters]);

  const cancelFilterChip = (filterType: BidStatus) => {
    const newFilters = selectedFilters?.filter((status: BidStatus) => status !== filterType);
    if (newFilters !== undefined) {
      setFilterState(newFilters);
    }
    if (!isEmpty(newFilters)) {
      dispatch(fetchBidSummaries(isCarrier ? [BiddingRole.Carrier] : [BiddingRole.Broker], newFilters));
    } else {
      dispatch(fetchBidSummaries(isCarrier ? [BiddingRole.Carrier] : [BiddingRole.Broker]));
    }
  };

  const openFilterPanel = () => {
    pushPanel();
  };

  return (
    <ResultAndFilterContainer style={{ paddingLeft: '0px' }}>
      <FlexContainer>
        <StyledGrid>
          {filterState.length > 0 ? (
            <Text id="messages_title" textStyle={TextStyle.SubtitleRegular}>
              {t(T.common_bids_filterMessage)}
            </Text>
          ) : null}
        </StyledGrid>
        <FilterButton onClick={openFilterPanel} />
      </FlexContainer>
      <ChipsContainer>
        <ChipsLine>
          {map(filterOptions, (filter) =>
            filter.isSelected ? (
              <FilterChip
                id={filter.title}
                key={filter.title}
                title={filter.title}
                onCancel={() => cancelFilterChip(filter.filterType)}
                onClick={openFilterPanel}
                shouldShowItems={false}
              />
            ) : null
          )}
        </ChipsLine>
      </ChipsContainer>
    </ResultAndFilterContainer>
  );
};
const DisabledBidText = styled(Text)`
  font-size: ${FontSize.Content}px;
  color: ${Color.GRAY_DARK};
  padding: 10px 40px 0 40px;
  line-height: ${FontLineHeight.MediumTitle}px;
  text-align: center;
  cursor: default;
`;

const BidsDisabledByBrokerScreen: React.FC = () => (
  <NotFoundPanel
    imageSection={<img id="image" src={BidsIcon} alt="disabled" />}
    title={t(T.common_bids_title)}
    controlSection={
      <>
        <DisabledBidText id="bids_disabled">
          {t(T.common_bids_disabled_message)}
          <PhoneNumber
            id={'phone_number'}
            phoneNumberValue={GENERAL_CONTACT_PHONE_NUMBER}
            phoneNumberString={t(T.common_bids_disabled_phoneNumber)}
            textStyle={TextStyle.BigLinkBold}
            style={{ fontWeight: 'bold' }}
          />
          {t(T.common_bids_disabled_activate)}
        </DisabledBidText>
      </>
    }
  />
);

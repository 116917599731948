export const validateURL = (url: string): boolean => {
  //@TODO: Check this regex if escaping is necessary.
  const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/; // eslint-disable-line no-useless-escape
  return regex.test(url);
};

export const prefixHttpsIfNecessary = (url: string): string => {
  if (url.startsWith('https://')) {
    return url;
  }
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  }
  return `https://${url}`;
};

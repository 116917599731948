import { useEffect } from 'react';

import { forEach, noop } from 'lodash';

export const useAppVisibilityChange = (
  onVisibilityChange: (isVisible: boolean) => void,
  dependencies: React.DependencyList = []
) => {
  const handleVisibilityChange = () => onVisibilityChange(!document.hidden);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, dependencies);
};

export const useAppIdle = (onIdleChange: () => void, dependencies: React.DependencyList = []) => {
  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'keypress',
    'scroll',
    'touchstart',
    'touchmove',
    'visibilitychange',
  ];
  const handleUserActivity = () => {
    onIdleChange();
  };
  const testPassive = supportPassive();
  const passive = testPassive ? { passive: testPassive } : false;
  useEffect(() => {
    forEach(events, (event) => document.addEventListener(event, handleUserActivity, passive));
    return () => {
      forEach(events, (event) => document.removeEventListener(event, handleUserActivity));
    };
  }, dependencies);
};

export const onPageVisibilityStateChange = (
  isVisible: boolean,
  onPageVisible: () => void,
  onPageHidden: () => void
) => {
  if (isVisible) {
    onPageVisible();
  } else {
    onPageHidden();
  }
};

const supportPassive = () => {
  let supportsPassive = false;
  const opts = Object.defineProperty({}, 'passive', {
    get: () => {
      supportsPassive = true;
    },
  });
  document.addEventListener('testPassive', noop, opts);
  document.removeEventListener('testPassive', noop, opts);
  return supportsPassive;
};

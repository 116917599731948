import { EmptyResponse } from '@common/redux/Base';
import { IntegrationsResponse, LoginSessionsResponse } from '@common/redux/epic/PermissionsEpic';

import { BaseClient } from './BaseClient';

export class PermissionsClient extends BaseClient {
  fetchIntegrations$ = () => this.api.get$<IntegrationsResponse>('/sessions/integrations', {});
  fetchLoginSessions$ = () => this.api.get$<LoginSessionsResponse>('/sessions/user', {});
  invalidateSession$ = (sessionID: string) => this.api.post$<EmptyResponse>(`/sessions/${sessionID}/invalidate`, {});
}

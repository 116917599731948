import { flow, isNaN, toNumber, toString } from 'lodash';

import { dashIfReturnsUndefined, doIfValue } from '@common/helper/Functions';

export class CompactNumber {
  // Uses base 60 with a special charset: not using 'O' (upper case 'o') and 'l' (lower case L) to reduce similarity with other digits.
  // ID is case sensitive, but uses only alphanumeric characters (no special characters) so it remains easier
  // to manipulate.
  private static baseChars = '0123456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

  public static int32toCompactString(value: number): string {
    let v = value >>> 0;

    const b: string = this.baseChars;
    const r: string[] = [];
    do {
      r.push(b[v % 60]);
      v = (v / 60) | 0;
    } while (v > 0);

    return r.reverse().join('');
  }
}

/** interprets undefined as zero. This is to overcome optional data values to mean zero */
export const treatUndefinedAsZero = (v: number | undefined): number => (v === undefined ? 0 : v);

/** interprets zero as undefined. Sometimes the value zero means no value and is thus displayed differently than '0' */
export const treatZeroAsUndefined = (v: number | undefined): number | undefined => (v === 0 ? undefined : v);

export const formatNumber = (value: number, forceDecimals?: boolean) =>
  value.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: forceDecimals ? 2 : 0 });

export const formatNumberWithZeroFormatting = (value: number, forceDecimals?: boolean) => {
  const isNumberZero = value === 0;
  return formatNumber(value, isNumberZero ? false : forceDecimals);
};

export const formatMileage = (value: number) =>
  value.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 0 });

export const safeFormatMileage = doIfValue(formatMileage);

export const formatMileageOrDash = dashIfReturnsUndefined(safeFormatMileage);

export const formatPrice = (value: number, wholeNumber?: boolean) =>
  value.toLocaleString('en', {
    maximumFractionDigits: wholeNumber ? 0 : 2,
    minimumFractionDigits: wholeNumber ? 0 : 2,
  });

export const safeFormatPrice = doIfValue(formatPrice);

export const formatPriceOrDash = dashIfReturnsUndefined(safeFormatPrice);

export const formatTo2Digit = (num: number) => `0${num}`.slice(-2);

export const isNumber = (value: string | number): boolean => value !== '' && !isNaN(Number(toString(value)));

export const cleanNumberOnlyInput = (value: string) => value.replace(/[^0-9]/g, '').replace(/^0/, '');

export const cleanSpaces = (value: string) => value.replace(/\s/g, '');

export const cleanDashes = (value: string) => value.replace(/-/g, '');

export const cleanAlphaCharacters = (value: string) => value.replace(/[a-zA-Z]/g, '');

export const formatThousands = doIfValue((number: number) =>
  number >= 1000 ? `${Math.floor(number / 1000)}k` : number
);

export const removeLeadingZeroes = doIfValue<string, string>(flow(toNumber, toString));

export const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

export const formatNumberDecimals = (num: number, minimumFractionDigits = 2, maximumFractionDigits = 2) =>
  num.toLocaleString('en', {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });

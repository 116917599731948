import React, { Dispatch as ReactDispatch, SetStateAction } from 'react';

import { ListItemText, MenuItem } from '@material-ui/core';

import { T, t } from '@translate';

interface Props {
  onMarkAllMessagesAsRead: () => void;
  setMenuAnchor: ReactDispatch<SetStateAction<undefined>>;
}

export const MenuOptions = ({ onMarkAllMessagesAsRead, setMenuAnchor }: Props) => {
  const closeMenu = (callback: () => void) => () => {
    setMenuAnchor(undefined);
    callback();
  };
  return (
    <MenuItem id="mark_messages_as_read" key={'mark_messages_as_read'} onClick={closeMenu(onMarkAllMessagesAsRead)}>
      <ListItemText id="title" primary={t(T.common_conversations_markAllAsRead_title)} />
    </MenuItem>
  );
};

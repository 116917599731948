import { useDispatch } from 'react-redux';

import { displayCurrencyOrDash, hasDecimal } from '@common/formatter';
import { BidDisplayState, getBidDisplayState } from '@common/helper';
import { BidSummary, Flag } from '@common/model';
import { ackBid } from '@common/redux/epic/bids/BidsEpic';
import { useDidUpdate } from '@common/util/hooks';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

export interface BidTagInfo {
  tagTitle: string;
  tagColor: Color;
}

interface BidSummaryStatusInfo extends BidTagInfo {
  message: string;
  displayState: BidDisplayState;
}

export const useIsBiddingEnabled = () => {
  const isBiddingV1Enabled = useSelector((state) => !state.settings.systemSetting[Flag.BiddingDisabled].value);
  const isBiddingV2Enabled = useSelector((state) => !state.settings.systemSetting[Flag.BiddingV2Disabled].value);
  return isBiddingV1Enabled || isBiddingV2Enabled;
};

export const useIsVendorBidsListEnabled = () =>
  useSelector((state) => !state.settings.systemSetting[Flag.VendorBiddingDisabled].value);

export const getBidTagInfo = (bidDisplayState: BidDisplayState): BidTagInfo => {
  switch (bidDisplayState) {
    case BidDisplayState.Withdrawn:
      return {
        tagTitle: t(T.common_bids_status_withdrawn),
        tagColor: Color.BLACK,
      };
    case BidDisplayState.ActionRequired_SentOfferAccepted:
    case BidDisplayState.ActionRequired_ReceivedOffer:
      return {
        tagTitle: t(T.common_bids_status_actionRequired),
        tagColor: Color.RED_APPLE,
      };
    case BidDisplayState.Pending_SentOffer:
    case BidDisplayState.Pending_ReceivedOfferAccepted:
      return {
        tagTitle: t(T.common_bids_status_pending),
        tagColor: Color.ORANGE_MAIN,
      };
    case BidDisplayState.Accepted:
      return {
        tagTitle: t(T.common_bids_status_accepted),
        tagColor: Color.GREEN_MAIN,
      };
    case BidDisplayState.Rejected_SentOfferRejected:
    case BidDisplayState.Rejected_ReceivedOfferRejected:
      return {
        tagTitle: t(T.common_bids_status_rejected),
        tagColor: Color.PURPLE,
      };
    case BidDisplayState.Confirmed:
      return {
        tagTitle: t(T.common_bids_status_confirmed),
        tagColor: Color.GREEN_MAIN,
      };
    case BidDisplayState.Assigned:
      return {
        tagTitle: t(T.common_bids_status_assigned),
        tagColor: Color.BLUE_AZURE,
      };
    default:
      return { tagTitle: '', tagColor: Color.GREEN_MAIN };
  }
};

export const getBidSummaryStatusInfo = (bidSummary: BidSummary, isCarrier?: boolean): BidSummaryStatusInfo => {
  const bidDisplayState = getBidDisplayState(bidSummary);
  const bidTagInfo = getBidTagInfo(bidDisplayState);
  const otherParty = bidSummary?.otherParty?.company?.name ?? t(T.common_bids_summary_otherParty);
  const amountAndBroker = {
    amount: displayCurrencyOrDash(bidSummary.price, hasDecimal(bidSummary.price)),
    broker: otherParty,
  };
  let message = '';
  switch (bidDisplayState) {
    case BidDisplayState.Withdrawn:
      message = bidSummary.offerMadeByMe
        ? t(T.common_bids_summary_messages_sentOfferWithdrawn, amountAndBroker)
        : t(T.common_bids_summary_messages_sentOfferWithdrawnByBroker, amountAndBroker);
      break;
    case BidDisplayState.ActionRequired_SentOfferAccepted:
      message = isCarrier
        ? t(T.common_bids_summary_messages_sentOfferAccepted, amountAndBroker)
        : t(T.common_bids_bidItem_messages_broker_sentOfferAccepted);
      break;
    case BidDisplayState.ActionRequired_ReceivedOffer:
      message = t(T.common_bids_summary_messages_receivedCounterOffer, amountAndBroker);
      break;
    case BidDisplayState.Pending_SentOffer:
      message = t(T.common_bids_summary_messages_sentOffer, amountAndBroker);
      break;
    case BidDisplayState.Pending_ReceivedOfferAccepted:
      message = isCarrier
        ? t(T.common_bids_summary_messages_receivedOfferAccepted, { broker: otherParty })
        : t(T.common_bids_bidItem_messages_broker_receivedOfferAcceptedListCard, { otherParty: otherParty });
      break;
    case BidDisplayState.Rejected_SentOfferRejected:
      message = t(T.common_bids_summary_messages_sentOfferRejected, amountAndBroker);
      break;
    case BidDisplayState.Rejected_ReceivedOfferRejected:
      message = t(T.common_bids_summary_messages_receivedOfferRejected, amountAndBroker);
      break;
    case BidDisplayState.Confirmed:
      message = t(T.common_bids_summary_messages_carrier_confirmed, amountAndBroker);
      break;
    case BidDisplayState.Assigned:
      message = t(T.common_bids_summary_messages_carrier_assigned, amountAndBroker);
      break;
  }
  return {
    ...bidTagInfo,
    message: message,
    displayState: bidDisplayState,
  };
};

export const getBidStatusInfo = (bid: BidSummary): BidSummaryStatusInfo => {
  const bidDisplayState = getBidDisplayState(bid);
  const bidTagInfo = getBidTagInfo(bidDisplayState);
  let message = '';
  switch (bidDisplayState) {
    case BidDisplayState.ActionRequired_SentOfferAccepted:
      message = t(T.common_bids_bidItem_messages_carrier_sentOfferAccepted);
      break;
    case BidDisplayState.ActionRequired_ReceivedOffer:
      message = t(T.common_bids_bidItem_messages_receivedOffer);
      break;
    case BidDisplayState.Pending_SentOffer:
      message = t(T.common_bids_bidItem_messages_sentOffer);
      break;
    case BidDisplayState.Pending_ReceivedOfferAccepted:
      message = t(T.common_bids_bidItem_messages_carrier_receivedOfferAccepted);
      break;
    case BidDisplayState.Confirmed:
      message = t(T.common_bids_summary_messages_carrier_confirmed);
      break;
    case BidDisplayState.Assigned:
      message = t(T.common_bids_bidItem_messages_carrier_assigned);
  }
  return {
    ...bidTagInfo,
    message: message,
    displayState: bidDisplayState,
  };
};

export const useAckOnBidUpdate = (bidSummaryId: string) => {
  const dispatch = useDispatch();
  const bidSummary = useSelector((state) =>
    bidSummaryId ? state.bids.bidSummaries.summaries.get(bidSummaryId) : undefined
  );
  useDidUpdate(() => {
    if (bidSummary && !bidSummary.isViewed) {
      const ackAction = ackBid(bidSummary);
      if (ackAction) {
        dispatch(ackAction);
      }
    }
  }, [bidSummary?.status]);
};

export const useIsV2BidsActive = () => {
  const isBidV1Disabled = useSelector((state) => state.settings.systemSetting[Flag.BiddingDisabled].value);
  const isBidV2Disabled = useSelector((state) => state.settings.systemSetting[Flag.BiddingV2Disabled].value);
  return isBidV1Disabled && !isBidV2Disabled;
};

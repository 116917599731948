import React, { useEffect } from 'react';

import { includes } from 'lodash';
import { useDispatch } from 'react-redux';

import { getLoadConversations } from '@common/redux/epic/CommunicationEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { Conversations, useConversations } from '@page/communication/Conversations';
import { Props as GlobalConversationsProps } from '@page/communication/GlobalConversations';
import { useSelector } from '@util/hooks';

export enum LoadConversationsOriginUI {
  MessagingWidget = 'MessagingWidget',
  CommunicationPage = 'CommunicationPage',
  MyLoads = 'MyLoads',
  FindLoads = 'FindLoads',
  FindLoadsBackhauls = 'FindLoadsBackhauls',
  FindLoadsMap = 'FindLoadsMap',
  FindLoadsBackhaulsMap = 'FindLoadsBackhaulsMap',
  PostLoadsDetails = 'PostLoadsDetails',
  LoadAvailability = 'LoadAvailability',
  LoadAvailabilityDensityMap = 'LoadAvailabilityDensityMap',
  LoadAvailabilityMap = 'LoadAvailabilityMap',
  LoadAvailabilityBackhauls = 'LoadAvailabilityBackhauls',
  MyDocuments = 'MyDocuments',
  CompanySearch = 'CompanySearch',
  CompanySearchMap = 'CompanySearchMap',
  CompanySearchBackhauls = 'CompanySearchBackhauls',
  TripBuilder = 'TripBuilder',
}

export const mapConversationOriginUIToLoadSearchType = (originUI: LoadConversationsOriginUI): LoadSearchType => {
  switch (originUI) {
    case LoadConversationsOriginUI.FindLoads:
    case LoadConversationsOriginUI.FindLoadsMap:
    case LoadConversationsOriginUI.MessagingWidget:
    case LoadConversationsOriginUI.CommunicationPage:
    case LoadConversationsOriginUI.MyLoads:
    case LoadConversationsOriginUI.MyDocuments:
    case LoadConversationsOriginUI.TripBuilder:
      return LoadSearchType.LoadSearch;
    case LoadConversationsOriginUI.FindLoadsBackhauls:
    case LoadConversationsOriginUI.FindLoadsBackhaulsMap:
    case LoadConversationsOriginUI.LoadAvailabilityBackhauls:
    case LoadConversationsOriginUI.CompanySearchBackhauls:
      return LoadSearchType.Backhaul;
    case LoadConversationsOriginUI.LoadAvailability:
    case LoadConversationsOriginUI.LoadAvailabilityDensityMap:
    case LoadConversationsOriginUI.LoadAvailabilityMap:
      return LoadSearchType.LoadAvailability;
    case LoadConversationsOriginUI.CompanySearch:
    case LoadConversationsOriginUI.CompanySearchMap:
      return LoadSearchType.CompanySearch;
    case LoadConversationsOriginUI.PostLoadsDetails:
      return LoadSearchType.Posted;
  }
};

const LoadConversationsBrokerOriginUIs = [LoadConversationsOriginUI.PostLoadsDetails];

interface Props extends GlobalConversationsProps {
  loadId: string;
  originUI: LoadConversationsOriginUI;
}

export const LoadConversations: React.FC<Props> = (props) => {
  const conversationInfo = useSelector((state) => state.communication.loadConversation.get(props.loadId));
  const conversations = useConversations(conversationInfo?.conversationsListIds || []);

  const dispatch = useDispatch();
  useEffect(() => {
    const inquireBroker = !includes(LoadConversationsBrokerOriginUIs, props.originUI);
    dispatch(getLoadConversations({ loadID: props.loadId, originUI: props.originUI, inquireBroker: inquireBroker }));
  }, [props.loadId]);

  if (!conversationInfo) {
    return null;
  }
  return (
    <Conversations
      originUI={props.originUI}
      conversationId={props.conversationId}
      conversations={conversations}
      currentFilter={props.filter}
      isLoading={conversationInfo.isLoading}
      hasError={conversationInfo.hasError}
      scrollRef={props.scrollRef}
      handleChange={props.handleChange}
    />
  );
};

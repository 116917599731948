import React, { useEffect, useState } from 'react';

import { getInitials } from '@common/helper';
import { ConversationUser } from '@common/model/Conversation';
import { MessageWidget, MessageWidgetType } from '@common/model/messaging/MessageWidget';
import { ConversationUserBubble } from '@component/conversation/ConversationAvatar';
import {
  CurrentUserWidgetBubble,
  MessageAndTimeContainer,
  MessageWrapper,
  OtherUserWidgetBubble,
} from '@component/conversation/ConversationStyles';
import { ConversationDate, ReadAndSentTime } from '@component/conversation/ConversationTime';
import { GenericWidget } from '@component/conversation/widget/GenericWidget';

interface WidgetMessageProps {
  userID: string | undefined;
  otherPartyUserID: string | undefined;
  conversationId: string;
  message: string;
  isFromCurrentUser: boolean;
  user: ConversationUser | undefined;
  read: boolean;
  widget: MessageWidget;
  time?: string;
  readAtTime?: string;
  date?: string;
  messageId?: string;
  isFirstOfGroup?: boolean;
  isLastOfGroup?: boolean;
}
export const WidgetMessageBubble: React.FC<WidgetMessageProps> = (props) => {
  const [isMessageRead, setIsMessageRead] = useState(props.isFromCurrentUser && props.read);
  const [displayTime, setDisplayTime] = useState(props.readAtTime);

  useEffect(() => {
    if (props.isFromCurrentUser && props.readAtTime) {
      setIsMessageRead(true);
      setDisplayTime(props.readAtTime);
    }
  }, [props.readAtTime]);

  let direction: 'row' | 'row-reverse' = 'row';
  let nameBubble: JSX.Element | null = props.isLastOfGroup ? (
    <ConversationUserBubble
      initials={getInitials(props.user)}
      userID={props.userID}
      isFromCurrentUser={props.isFromCurrentUser}
    />
  ) : null;
  if (props.isFromCurrentUser) {
    nameBubble = null;
    direction = 'row-reverse';
  }

  return (
    <>
      {props.date ? <ConversationDate date={props.date} /> : null}
      <MessageWrapper style={{ flexDirection: direction }} isLastOfGroup={props.isLastOfGroup}>
        {nameBubble}
        <MessageAndTimeContainer style={{ alignItems: props.isFromCurrentUser ? 'flex-end' : 'auto' }}>
          <WidgetComponent {...props} />
          {props.isLastOfGroup ? (
            <ReadAndSentTime
              displayTime={displayTime}
              time={props.time}
              isMessageRead={isMessageRead}
              isFromCurrentUser={props.isFromCurrentUser}
            />
          ) : null}
        </MessageAndTimeContainer>
      </MessageWrapper>
    </>
  );
};

const WidgetComponent: React.FC<WidgetMessageProps> = (props) => {
  const WidgetBubble = props.isFromCurrentUser ? CurrentUserWidgetBubble : OtherUserWidgetBubble;
  return (
    <WidgetBubble isFirstOfGroup={props.isFirstOfGroup}>
      <WidgetContent
        widget={props.widget}
        otherPartyUserID={props.otherPartyUserID}
        conversationId={props.conversationId}
        sentBy={props.user?.userId}
      />
    </WidgetBubble>
  );
};

const WidgetContent: React.FC<{
  widget: MessageWidget;
  otherPartyUserID?: string;
  conversationId: string;
  sentBy?: string;
}> = (props) => {
  switch (props.widget.type) {
    case MessageWidgetType.Generic:
      return <GenericWidget {...props} />;
    default:
      return null;
  }
};

import { createAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface ConnectionErrorState {
  isConnectionError: boolean;

  /** will be set to true instead of redirecting to login page
   * after determining it cannot login including failure attempting
   * to refresh the token.
   *
   * true = login either did not occur or was successful.
   * false= login failed (invalid refresh token)
   */
  loginFailed: boolean;
}

const initialConnectionErrorState: ConnectionErrorState = {
  isConnectionError: false,
  loginFailed: false,
};

const setConnectionErrorAction = createAction<boolean>('SET_CONNECTION_ERROR');
const setLoginFailedAction = createAction<boolean>('SET_LOGIN_FAILED');

export const setConnectionError = (isConnectionError: boolean) => setConnectionErrorAction.action(isConnectionError);

export const setLoginFailed = (loginFailedStatus: boolean) => setLoginFailedAction.action(loginFailedStatus);

export const connectionErrorReducer = createMergedReducer(initialConnectionErrorState, [
  setConnectionErrorAction.addCase((state, action) => {
    state.isConnectionError = action.data;
  }),
  setLoginFailedAction.addCase((state, action) => {
    state.loginFailed = action.data;
  }),
]);

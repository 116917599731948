import { every, isEmpty, isEqual, map, sortBy } from 'lodash';

import { LoadPostRequest } from '@/model';
import { convertFormFieldsToLoadPostRequest, PostLoadFormFieldsState } from '@/reduxStore/epic/PostLoadFormEpic';
import {
  areOptionalValuesSemanticallyEqual,
  arePhonesDefinedAndMatching,
  convertISOToRFCAndStripTimeIfMidnight,
  equipmentSpecificationsToCodeStrings,
  isBaseLocationEqual,
} from '@common/helper';
import { BaseLocation, Load, LoadExtraStop, LoadSize, LocationType, PosterLoadSearchRequest } from '@common/model';
import { t, T } from '@translate';

export enum PostedLoadsFilterType {
  Pickup = 'pickup',
  PickupDates = 'pickup_dates',
  Dropoff = 'dropoff',
  DropoffDates = 'dropoff_dates',
  TrailerType = 'trailer_type',
  TrailerSpecifications = 'trailer_specifications',
  TL = 'tl',
  LTL = 'ltl',
  Length = 'length',
  WithLength = 'with_length',
  Weight = 'weight',
  WithWeight = 'with_weight',
}

interface Filter {
  type: PostedLoadsFilterType;
  title: string;
  filterSpecificInput?: {
    label: string;
    values: string[];
  };
}

const doLoadPostRequestLocationsMatchExistingLoad = (loadPostRequest: LoadPostRequest, load: Load) => {
  if (!load.originLocation || !load.destinationLocation) {
    return false;
  }
  const loadOriginLocation: BaseLocation = {
    type: load.originLocation.type,
    address: load.originLocation.address,
    geolocation: load.originLocation.geolocation,
    states: load.originLocation.states,
    radius: load.originLocation.radius,
  };
  const loadDestinationLocation: BaseLocation = {
    type: load.destinationLocation.type,
    address: load.destinationLocation.address,
    geolocation: load.destinationLocation.geolocation,
    states: load.destinationLocation.states,
    radius: load.destinationLocation.radius,
  };
  return (
    isBaseLocationEqual(loadPostRequest.originLocation, loadOriginLocation) &&
    isBaseLocationEqual(loadPostRequest.destinationLocation, loadDestinationLocation)
  );
};

// check if strings where '' is the same as undefined are equal
const doLoadPostRequestOptionalStringsMatchExistingLoad = (loadPostRequest: LoadPostRequest, load: Load) => {
  const loadPostFieldsDeliveryDateTime = loadPostRequest.deliveryDateTime
    ? convertISOToRFCAndStripTimeIfMidnight(loadPostRequest.deliveryDateTime)
    : undefined;
  const optionalStrings: Array<[string | undefined, string | undefined]> = [
    [loadPostRequest.postReference, load.postReference],
    [loadPostFieldsDeliveryDateTime, load.deliveryDateTime],
    [loadPostRequest.commodity, load.commodity],
    [loadPostRequest.notes, load.notes],
    [loadPostRequest.privateLoadNote, load.privateLoadNote],
    [loadPostRequest.dispatchName, load.dispatchName],
    [loadPostRequest.dispatchEmail, load.dispatchEmail],
  ];
  return every(optionalStrings, (value) => areOptionalValuesSemanticallyEqual(value[0], value[1]));
};

const doLoadPostRequestPickupDateTimesMatchExistingLoad = (loadPostRequest: LoadPostRequest, load: Load) => {
  const loadPostFieldsPickupDateTimesRFC = loadPostRequest.pickupDateTimes
    ? map(loadPostRequest.pickupDateTimes, (date: string) => convertISOToRFCAndStripTimeIfMidnight(date))
    : undefined;
  return (
    loadPostFieldsPickupDateTimesRFC &&
    load.pickupDateTimes &&
    isEqual(sortBy(loadPostFieldsPickupDateTimesRFC), sortBy(load.pickupDateTimes))
  );
};

const getExtraStopMainData = (extraStop: LoadExtraStop) => ({
  stopNo: extraStop.stopNo,
  stopPurpose: extraStop.stopPurpose,
  address: {
    city: extraStop.address?.city,
    state: extraStop.address?.state,
  },
  geolocation: {
    latitude: extraStop.geolocation?.latitude,
    longitude: extraStop.geolocation?.longitude,
  },
});

export const doLoadPostRequestExtraStopsMatchExistingLoad = (loadPostRequest: LoadPostRequest, load: Load) => {
  const loadPostRequestStops = map(loadPostRequest.extraStops, (stop) => getExtraStopMainData(stop));
  const loadStops = map(load.extraStops, (stop) => getExtraStopMainData(stop));
  return isEqual(loadPostRequestStops, loadStops);
};

const doesLoadPostRequestMatchExistingLoad = (loadPostRequest: LoadPostRequest, load: Load) =>
  doLoadPostRequestLocationsMatchExistingLoad(loadPostRequest, load) &&
  doLoadPostRequestOptionalStringsMatchExistingLoad(loadPostRequest, load) &&
  doLoadPostRequestPickupDateTimesMatchExistingLoad(loadPostRequest, load) &&
  arePhonesDefinedAndMatching(loadPostRequest.dispatchPhone, load.dispatchPhone) &&
  loadPostRequest.numberOfLoads === load.numberOfLoads &&
  loadPostRequest.loadSize === load.loadSize &&
  loadPostRequest.mileage === load.mileage &&
  loadPostRequest.length === load.length &&
  loadPostRequest.weight === load.weight &&
  loadPostRequest.numberOfStops === load.numberOfStops &&
  areOptionalValuesSemanticallyEqual(loadPostRequest.teamDriving, load.teamDriving) &&
  isEqual(loadPostRequest.rate, load.rate) &&
  isEqual(
    sortBy(loadPostRequest.equipments, ['equipmentType', 'equipmentSpecifications']),
    sortBy(load.equipments, ['equipmentType', 'equipmentSpecifications'])
  ) &&
  doLoadPostRequestExtraStopsMatchExistingLoad(loadPostRequest, load);

export const doPostLoadFormFieldsMatchExistingLoad = (fields: PostLoadFormFieldsState, load: Load | undefined) => {
  if (!load) {
    return false;
  }
  const loadPostRequestFromFields = convertFormFieldsToLoadPostRequest(fields);
  return doesLoadPostRequestMatchExistingLoad(loadPostRequestFromFields, load);
};

export const filtersFromPosterLoadSearch = (posterLoadSearch: PosterLoadSearchRequest) => {
  const filters: Filter[] = [];
  if (posterLoadSearch.origin.type !== LocationType.ANY) {
    filters.push({ type: PostedLoadsFilterType.Pickup, title: t(T.modalPanels_LocationPicker_Pickup) });
  }
  if (!isEmpty(posterLoadSearch.pickupDates)) {
    filters.push({ type: PostedLoadsFilterType.PickupDates, title: t(T.modalPanels_DatesTime_PickUpDatesHeader) });
  }
  if (posterLoadSearch.destination.type !== LocationType.ANY) {
    filters.push({ type: PostedLoadsFilterType.Dropoff, title: t(T.modalPanels_LocationPicker_DropOff) });
  }
  if (!isEmpty(posterLoadSearch.deliveryDates)) {
    filters.push({ type: PostedLoadsFilterType.DropoffDates, title: t(T.modalPanels_DatesTime_DropOffDatesHeader) });
  }
  if (!isEmpty(posterLoadSearch.equipmentTypes)) {
    filters.push({ type: PostedLoadsFilterType.TrailerType, title: t(T.modalPanels_TrailerType_Header) });
  }
  if (posterLoadSearch.equipmentSpecifications && !isEmpty(posterLoadSearch.equipmentSpecifications)) {
    filters.push({
      type: PostedLoadsFilterType.TrailerSpecifications,
      title: t(T.modalPanels_TrailerSpecifications_Header),
      filterSpecificInput: {
        label: t(T.common_load_filter_trailerSpecs),
        values: equipmentSpecificationsToCodeStrings(posterLoadSearch.equipmentSpecifications),
      },
    });
  }
  if (posterLoadSearch.loadSize === LoadSize.TL) {
    filters.push({ type: PostedLoadsFilterType.TL, title: t(T.postLoads_Tl) });
  }
  if (posterLoadSearch.loadSize === LoadSize.LTL) {
    filters.push({ type: PostedLoadsFilterType.LTL, title: t(T.postLoads_Ltl) });
  }
  if (posterLoadSearch.minLength || posterLoadSearch.length) {
    filters.push({ type: PostedLoadsFilterType.Length, title: t(T.common_load_Length) });
  }
  if (posterLoadSearch.includeLoadsWithoutLength === false) {
    filters.push({ type: PostedLoadsFilterType.WithLength, title: t(T.common_load_filter_withLength) });
  }
  if (posterLoadSearch.minWeight || posterLoadSearch.weight) {
    filters.push({ type: PostedLoadsFilterType.Weight, title: t(T.common_load_Weight) });
  }
  if (posterLoadSearch.includeLoadsWithoutWeight === false) {
    filters.push({ type: PostedLoadsFilterType.WithWeight, title: t(T.common_load_filter_withWeight) });
  }

  return filters;
};

export const omitFilterByFilterType = (
  filters: PosterLoadSearchRequest,
  filterType: PostedLoadsFilterType
): PosterLoadSearchRequest => {
  switch (filterType) {
    case PostedLoadsFilterType.Pickup:
      return { ...filters, origin: { type: LocationType.ANY } };
    case PostedLoadsFilterType.PickupDates:
      return { ...filters, pickupDates: [] };
    case PostedLoadsFilterType.Dropoff:
      return { ...filters, destination: { type: LocationType.ANY } };
    case PostedLoadsFilterType.DropoffDates:
      return { ...filters, deliveryDates: undefined };
    case PostedLoadsFilterType.TrailerType:
      return { ...filters, equipmentTypes: undefined };
    case PostedLoadsFilterType.TrailerSpecifications:
      return { ...filters, equipmentSpecifications: undefined };
    case PostedLoadsFilterType.TL:
    case PostedLoadsFilterType.LTL:
      return { ...filters, loadSize: undefined };
    case PostedLoadsFilterType.Length:
      return { ...filters, minLength: undefined, length: undefined };
    case PostedLoadsFilterType.WithLength:
      return { ...filters, includeLoadsWithoutLength: true };
    case PostedLoadsFilterType.Weight:
      return { ...filters, minWeight: undefined, weight: undefined };
    case PostedLoadsFilterType.WithWeight:
      return { ...filters, includeLoadsWithoutWeight: true };
    default:
      return filters;
  }
};

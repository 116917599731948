import React, { useEffect, useRef, useState } from 'react';

import { toNumber, toString } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import { displayCurrencyOrDash, hasDecimal, NO_INFORMATION_SYMBOL } from '@common/formatter';
import { BidDisplayState, formatDateTimeMMMDDhhmma, getBidDisplayState, validateInteractiveBid } from '@common/helper';
import { getValidBid } from '@common/helper/VendorLoadHelper';
import { BidSummary } from '@common/model';
import { postBid } from '@common/redux/epic/bids/BidsEpic';
import { fetchLoadDetails } from '@common/redux/epic/LoadDetailsEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { EXPIRATION_TIMES, ExpirationTime, getExpirationTime } from '@common/util/BidsTimeHelper';
import { MultipurposeButton } from '@component/buttons';
import { FValidatedTextInput, FValidatedTextInputRef } from '@component/input';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { SelectElement } from '@component/select/Select';
import { Text, TextStyle } from '@component/text';
import { InlineTappableText } from '@component/text/TappableTextContent';
import { BidRateCheck } from '@page/bids/BidFormRateCheck';
import { bidsEncoder, BidsRouteParamsProps, BidsTabs } from '@page/bids/BidsPanelRouting';
import { BidFormButton, BidTextContainer, FormContainer } from '@page/bids/style/BidsStyleHelper';
import { BidInfoRibbon } from '@page/bids/summary/BidInfoRibbonSummary';
import { TextContainer } from '@page/unsubscribeAssistant/UnsubscribeStylesHelper';
import { Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

const BidFormContainer = styled.div`
  margin: 16px 8px 16px 8px;
`;

const BidExpirationTimeContainer = styled.div`
  margin-bottom: 16px;
`;

interface BidFormPanelProps {
  isPlaceNewBidPanel: boolean;
  isCarrier: boolean;
  previousBidSummary?: BidSummary;
  shouldShowExpirationDate: boolean;
}

export const BidFormPanel = (props: BidFormPanelProps) => {
  const dispatch = useDispatch();
  const params = useParams<BidsRouteParamsProps>();
  const history = useHistory();

  const bidInputRef = useRef<FValidatedTextInputRef | null>(null);

  const previousBidRate = !props.isPlaceNewBidPanel ? props.previousBidSummary?.price : undefined;

  const isCounterofferPanel = props.previousBidSummary
    ? getBidDisplayState(props.previousBidSummary) === BidDisplayState.ActionRequired_ReceivedOffer
    : false;

  const [offerValue, setOfferValue] = useState<string | ''>('');
  const [expirationTime, setExpirationTime] = useState<ExpirationTime | ''>('');
  const [isExpirationTimeInvalid, setIsExpirationTimeInvalid] = useState(false);

  const [wasBidSent, setWasBidSent] = useState(false);
  const [bidWasSubmitted, setBidWasSubmitted] = useState(false);
  const { openingPopup } = usePopup();

  const userId = useSelector((state) => state.user?.profile?.payload?.id);

  const didPostBidSuccessfully = useSelector((state) => state.bids.postBid.didPostSuccessfully);
  const postedBidId = useSelector((state) => state.bids.postBid.postedBidId);
  const isPostingBid = useSelector((state) => state.bids.postBid.isPosting);
  const isLoadLoading = useSelector((state) => state.loadDetails.responseState.status === 'Loading');
  const load = useSelector((state) => state.loadDetails.currentLoad);

  const loadId = params.loadID;
  const offerId = params.offerID;

  const redirectCommunicationPath = bidsEncoder.convertUrlSearchParamsToObject(
    history.location.search
  ).redirectCommunicationPath;

  const messageData = {
    date: props.previousBidSummary?.mostRecentActivity
      ? formatDateTimeMMMDDhhmma(props.previousBidSummary.mostRecentActivity)
      : '',
    amount: displayCurrencyOrDash(previousBidRate, hasDecimal(previousBidRate)),
  };

  const editMessage = t(T.common_bids_editBid_message, messageData);

  const counterofferMessage = props.isCarrier
    ? t(T.common_bids_placeCounterOffer_messageCarrier, {
        date: formatDateTimeMMMDDhhmma(props.previousBidSummary?.mostRecentActivity ?? ''),
        amount: displayCurrencyOrDash(previousBidRate, hasDecimal(previousBidRate)),
      })
    : t(T.common_bids_placeCounterOffer_message, {
        date: formatDateTimeMMMDDhhmma(props.previousBidSummary?.mostRecentActivity ?? ''),
        amount: displayCurrencyOrDash(previousBidRate, hasDecimal(previousBidRate)),
      });

  const message = isCounterofferPanel ? counterofferMessage : editMessage;

  useEffect(() => {
    if (!loadId) {
      return;
    }

    dispatch(fetchLoadDetails(LoadSearchType.LoadSearch, loadId));
  }, [loadId]);

  useEffect(() => {
    if (wasBidSent || bidWasSubmitted) {
      if (isPostingBid === false && didPostBidSuccessfully === false) {
        setWasBidSent(false);
        openingPopup({ body: <BidFailedPopUp />, width: PopupSizes.SMALL });
      } else if (postedBidId && (postedBidId !== offerId || props.previousBidSummary?.bidId !== offerId)) {
        setWasBidSent(false);

        history.push({
          pathname: `${generatePath(Routes.BIDS, {
            tab: BidsTabs.InteractiveBids,
            summary: 'summary',
            bidID: postedBidId,
            loadID: loadId,
            offerID: postedBidId,
            edit: 'offer',
          })}`,

          search:
            history.location.search ??
            bidsEncoder.convertObjectRequestToUrlParams({
              loadId: loadId,
              redirectCommunicationPath: redirectCommunicationPath,
            }),
        });
      }
    }
  }, [bidWasSubmitted, postedBidId, wasBidSent, isPostingBid, didPostBidSuccessfully]);

  const handlePlaceBid = () => {
    const isBidValid = bidInputRef.current?.performValidation();

    if (expirationTime === '') {
      setIsExpirationTimeInvalid(true);
    }

    if (isBidValid && (!props.shouldShowExpirationDate || expirationTime !== '')) {
      setBidWasSubmitted(true);

      if (loadId && offerValue) {
        setWasBidSent(true);
        dispatch(
          postBid({
            loadId: loadId,
            previousBidId: props.previousBidSummary?.bidId,
            price: toNumber(offerValue),
            carrierId: props.isCarrier ? undefined : props.previousBidSummary?.otherParty?.contactId,
            expirationTime: expirationTime ? getExpirationTime(expirationTime) : undefined,
          })
        );
      }
    }
  };

  const renderRate = () => {
    return props.previousBidSummary?.load?.rate?.amount === undefined ? (
      <Text id="posted_rate_1">{`${t(T.common_dollar_sign)}${NO_INFORMATION_SYMBOL}`}</Text>
    ) : (
      <InlineTappableText
        id="posted_rate_2"
        accent={true}
        onClick={() => {
          const formattedBid = getValidBid(toString(props.previousBidSummary?.load?.rate?.amount));
          if (formattedBid !== undefined) {
            setOfferValue(formattedBid);
          }
        }}
      >
        {`${t(T.common_dollar_sign)}${props.previousBidSummary?.load?.rate?.amount}`}
      </InlineTappableText>
    );
  };

  return (
    <>
      {props.isPlaceNewBidPanel ? (
        <BidInfoRibbon bid={props.previousBidSummary} isViewingOffer={true}></BidInfoRibbon>
      ) : null}
      <BidFormContainer>
        {props.isPlaceNewBidPanel ? (
          <>
            <BidTextContainer id="posted_rate" style={{ marginTop: '32px', marginBottom: '8px' }}>
              {t(T.common_vendorBid_placeBid_postedRate)} {renderRate()}
            </BidTextContainer>

            <BidTextContainer>
              <Text id="place_bid_interactive" textStyle={TextStyle.BodyLarge}>
                {t(T.common_vendorBid_placeBid_enter)}
              </Text>
            </BidTextContainer>
          </>
        ) : (
          <BidTextContainer id="previous_bid_text_message">
            <Text id="form_text_content">{message}</Text>
          </BidTextContainer>
        )}
        <FormContainer style={{ marginRight: `${Spacing.InterSection}`, marginLeft: `${Spacing.InterSection}` }}>
          <FValidatedTextInput
            ref={bidInputRef}
            id="place_bid_offer_input"
            label={t(T.common_vendorBid_placeBid_flatRate)}
            value={offerValue}
            onChange={(event) => {
              const formattedBid = getValidBid(event.target.value);
              if (formattedBid !== undefined) {
                setOfferValue(formattedBid);
              }
            }}
            validator={validateInteractiveBid(previousBidRate ? toNumber(previousBidRate) : 0)}
            shouldIgnoreValidation={bidWasSubmitted}
            autoComplete="off"
          />
          <BidRateCheck
            isLoading={isLoadLoading}
            origin={load?.originLocation}
            destination={load?.destinationLocation}
            equipments={load?.equipments}
            computedMileage={load?.computedMileage}
            rateCheck={load?.rateCheck}
            onClickRateCheck={setOfferValue}
          />
          {props.shouldShowExpirationDate ? (
            <>
              <BidTextContainer>
                <Text id="select_expiration_time_label">{t(T.common_bids_expirationTime_message)}</Text>
              </BidTextContainer>
              <BidExpirationTimeContainer>
                <SelectElement
                  label={t(T.common_bids_expirationTime_label)}
                  options={EXPIRATION_TIMES}
                  id="expiration_time"
                  onChange={(value: ExpirationTime) => {
                    setExpirationTime(value);
                    setIsExpirationTimeInvalid(false);
                  }}
                  helperText={t(T.common_bids_expirationTime_requiredError)}
                  value={expirationTime}
                  error={isExpirationTimeInvalid}
                />
              </BidExpirationTimeContainer>
            </>
          ) : null}
          <BidFormButton
            id="submit_bid"
            onClick={handlePlaceBid}
            variant={'contained'}
            text={props.isPlaceNewBidPanel ? t(T.common_bids_placeBid_title) : t(T.common_submit)}
            color={'primary'}
            isLoading={wasBidSent}
          />
        </FormContainer>
      </BidFormContainer>
    </>
  );
};

const BidFailedPopUp: React.FC = () => {
  const { closingPopup } = usePopup();
  return (
    <BasePopup title={t(T.common_vendorBid_failedToPlaceBid_title)} onClose={closingPopup}>
      <TextContainer>
        <Text id="replace_bid" textStyle={TextStyle.BodyLarge}>
          {t(T.common_vendorBid_failedToPlaceBid_content)}
        </Text>
      </TextContainer>
      <FormContainer>
        <MultipurposeButton
          id="submit_new_bid"
          onClick={() => {
            closingPopup();
          }}
          text={t(T.common_ok)}
          variant={'outlined'}
          color={'secondary'}
        />
      </FormContainer>
    </BasePopup>
  );
};

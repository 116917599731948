import { filter, find, findIndex, forEach, isUndefined, toUpper } from 'lodash';
import { Action } from 'redux';

import { FieldError } from '@common/model';
import { createReducer } from '@common/redux/ReduxHelper';

export enum ValidationType {
  UIError,
  ServerError,
}

enum PostLoadField {
  EQUIPMENTS = 'equipments',
  EQUIPMENT = 'equipment',
}

export type ValidationFieldKey<T> = keyof T;

export interface ValidationField<T> {
  key: ValidationFieldKey<T>;
  type?: ValidationType;
  message?: string;
}

interface SetValidationFieldAction<T> extends Action {
  validationFields: ValidationField<T>[];
}

interface UnsetValidationFieldAction<T> extends Action {
  key: ValidationFieldKey<T>;
}

export interface FormValidationState<T> {
  validationFields: ValidationField<T>[];
}

/**
 *
 * Creates a reducer that is used as sub-reducer for form reducer.
 * Can be useful to determine whether the form should be validated and to retrieve server errors
 *
 * @param config.reducerKey Key used to prefix action names
 *
 * @return object Containing reducer, actionTypes and actions to manage validationFields list.
 *
 * @example Short example about using this reducer with sample form (login, password):
 *
 * Step 1: use Function createFormValidationReducer to extend form reducer with validationFields.
 * Validation fields have a generic type, so it match the interface of the form.
 * You can only pass form field's names as a key for it (e.g. 'login', 'password' here)
 *
 * interface LoginFields {
 *   login: string;
 *   password: string;
 * }
 * we extend form reducer interface to append validation fields
 * interface LoginState extends FormValidationState<LoginFieldsState> {
 *   fields: LoginFields;
 * }
 * const validation = createFormValidationReducer<LoginState>(LOGIN_FORM_REDUCER_KEY);
 *
 * here we define an action creator to add validationFields to your form
 * const addValidationFields = (validationFields: ValidationField<LoginFieldsState>[]) =>
 *   validation.formValidationActions.setValidationFields(validationFields)
 *
 * const initialState: LoginState = {
 *   fields: {
 *     login: 'foo',
 *     password: 'bar',
 *   },
 *   validationFields: [],
 * }
 * const loginReducer = createReducer(initialState, {...actionsMap}, validation.reducer);
 *
 * Step 2: As you can see from above, validationFields are empty by default.
 * This means we have no errors to show by default, so they will not be shown if we open the page. Now if you want to show
 * error, use actions to add error field to your form reducer (e.g. on blur here).
 * shouldValidate function will check if we have any validationField with such name in reducer.
 * If we have one, it means that we should show error message.
 * To add additional validation to the field, you can use 3-rd optional param of shouldValidate.
 *
 * const loginState = useSelector(state => state.loginState);
 *
 * render (
 *  <TextFields
 *    id={login}
 *    value={'foo'}
 *    onBlur={() => addValidationFields([{ key: 'login', type: ValidationType.UIError }])}
 *    error={shouldValidate('login', loginState)} />
 * }
 *
 * */

export const createFormValidationReducer = <Validations, StoreState extends FormValidationState<Validations>>(
  reducerKey: string,
  initialState: StoreState
) => {
  const actionKey = createActionKey(reducerKey);

  const actionTypes = {
    SET_VALIDATION_FIELDS: `${actionKey}SET_VALIDATION_FIELDS`,
    UNSET_VALIDATION_FIELD: `${actionKey}UNSET_VALIDATION_FIELD`,
    DROP_VALIDATION_FIELDS: `${actionKey}DROP_VALIDATION_FIELDS`,
  };

  const actions = {
    setValidationFields: (validationFields: ValidationField<Validations>[]): SetValidationFieldAction<Validations> => ({
      type: actionTypes.SET_VALIDATION_FIELDS,
      validationFields: validationFields,
    }),
    unsetValidationField: (
      validationFieldKey: ValidationFieldKey<Validations>
    ): UnsetValidationFieldAction<Validations> => ({
      type: actionTypes.UNSET_VALIDATION_FIELD,
      key: validationFieldKey,
    }),
    dropValidationFields: () => ({ type: actionTypes.DROP_VALIDATION_FIELDS }),
  };

  const formValidationReducer = createReducer(initialState, {
    [actionTypes.SET_VALIDATION_FIELDS]: (state, action: SetValidationFieldAction<Validations>) => {
      forEach(action.validationFields, (actionField) => {
        const fieldIndex = findIndex(state.validationFields, (field) => field.key === actionField.key);
        (state.validationFields as ValidationField<Validations>[])[
          fieldIndex < 0 ? state.validationFields.length : fieldIndex
        ] = actionField;
      });
    },
    [actionTypes.UNSET_VALIDATION_FIELD]: (state, action: UnsetValidationFieldAction<Validations>) => {
      state.validationFields = filter(state.validationFields, (field) => field.key !== action.key);
    },
    [actionTypes.DROP_VALIDATION_FIELDS]: (state) => {
      state.validationFields = [];
    },
  });

  return {
    actionTypes: actionTypes,
    actions: actions,
    reducer: formValidationReducer,
  };
};

/** @return boolean indicating should we do field validation, or not
 * @param fieldName is used to find validationField by key
 * @param validationState stores a list of validationFields
 * @param isFieldValid is optional param, where we can pass side validation (e.g. isPasswordValid())
 * (e.g. if we open form we should not see any errors, it must be shown only if we interact with a fields)
 * @return boolean should the field be validated or not
 * */
export const shouldValidate = <T>(
  fieldName: keyof T,
  validationState: FormValidationState<T>,
  isFieldValid?: boolean
): boolean => {
  const validationField = find(validationState.validationFields, (field) => field.key === fieldName);
  if (validationField === undefined) {
    return false;
  } else {
    if (validationField.type === ValidationType.ServerError) {
      return true;
    }
    return validationField.type === ValidationType.UIError && (!isFieldValid ?? true);
  }
};

/** @return string error message of specified validationField
 * @param fieldName is used to find validationField by key
 * @param validationState stores a list of validationFields
 * @return string name of error by specified validationField's name
 * */
export const getErrorMessage = <T>(
  fieldName: ValidationFieldKey<T>,
  validationState: FormValidationState<T>
): string | undefined => {
  const validationField = find(validationState.validationFields, (field) => field.key === fieldName);
  if (validationField === undefined) {
    return undefined;
  } else {
    return validationField.message;
  }
};

/** Handle server error field names returned as string with array property encoded to properly construct form element reference */
export const getFieldName = (fieldName: string | undefined = ''): string => {
  // Regex will grab everything before '[' symbol if it exists
  // Or will grab the whole string otherwise
  const result = fieldName?.match(/^[^[\]]*/);
  if (result) {
    switch (result[0]) {
      // This will handle mapping for 'equipments' post load field
      case PostLoadField.EQUIPMENTS:
        return PostLoadField.EQUIPMENT;
      default:
        return result[0];
    }
  } else {
    return fieldName;
  }
};

/** @return array of validation fields from array of server error fields*/
export const mapApiErrorFieldsToFormValidationFields = <T>(fieldErrors: FieldError[]): ValidationField<T>[] => {
  const validationFields: ValidationField<T>[] = [];
  forEach(fieldErrors, (fieldError) => {
    if (!isUndefined(fieldError.name)) {
      const fieldName = getFieldName(fieldError.name);
      validationFields.push({
        key: fieldName as keyof T,
        type: ValidationType.ServerError,
        message: fieldError.message,
      });
    }
  });
  return validationFields;
};

const createActionKey = (reducerKey: string) => `${toUpper(reducerKey)}_`;

import React from 'react';

import styled from 'styled-components';

import { Text, TextDisplay, TextStyle } from '@component/text';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { GA, TrackingCategory } from '@util/trackers/GoogleAnalyticsTracker';

export const LinkButton = styled.div`
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
`;

interface Props {
  id: string;
  phoneNumberValue: string;
  phoneNumberString: string;
  extraText?: string;
  extension?: string;
  style?: React.CSSProperties;
  wrap: boolean;
  onClick?: () => void;
  analyticsActionSuffix: string;
  onShowCarrierContact?: () => void;
  textStyle: TextStyle;
}

export class PhoneNumber extends React.Component<Props> {
  state: { isHidden: boolean } = {
    isHidden: this.props.onShowCarrierContact ? true : false,
  };
  static defaultProps: Partial<Props> = {
    wrap: false,
    analyticsActionSuffix: 'not specified',
    textStyle: TextStyle.Link,
  };

  onPhoneClick = () => {
    if (this.state.isHidden && this.props.onShowCarrierContact) {
      this.props.onShowCarrierContact();
      this.setState({ isHidden: false });
      return;
    }
    window.open(`tel:${this.props.phoneNumberValue}`, '_self');
    GA.track(
      TrackingCategory.UserAction,
      `Call - ${this.props.analyticsActionSuffix}`,
      `number: ${this.props.phoneNumberValue}`
    );
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const props = this.props;
    return (
      <Text id={props.id} style={props.style} noWrap={props.wrap ? false : true} display={TextDisplay.InlineBlock}>
        {/* watch out if modifying this LinkButton. there was a <a/> here previously that was
        causing the API requests in onPhoneClick() to fail */}
        <LinkButton id={`${props.id}_link`} onClick={this.onPhoneClick}>
          <Text id={`${props.id}_link_text`} style={props.style} textStyle={props.textStyle} noWrap={true}>
            {this.state.isHidden ? t(T.showNumber) : props.phoneNumberString}
          </Text>
          {getExtension(props.id, props.extension)}
        </LinkButton>
        {props.extraText ? props.extraText : null}
      </Text>
    );
  }
}

const getExtension = (id: string, extension?: string) =>
  extension ? (
    <>
      , <Text id={id + '_extension'}>{extension}.</Text>
    </>
  ) : null;

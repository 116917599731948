import React, { useEffect, useState } from 'react';

import { round, toString } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { blocking, combineValidators, validateIsGreaterThan, validateStringLengthNotZero } from '@common/helper';
import { fetchRoutesSettings, updateRoutesSettings } from '@common/redux/epic/SettingsEpic';
import { MultipurposeButton } from '@component/buttons';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelFooter, PanelHeader, PanelSize } from '@component/panel';
import { createField, FieldType } from '@component/panels/basePanel/loadDetails/profitCalculator/Field';
import { FValidatedTextInput } from '@component/input';
import { Color } from '@style/Color';
import { ElementSize, FontLineHeight, FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';

import { SETTINGS_PANEL_PADDING } from './FormSettingsStyles';

interface Props {
  layer?: number;
  onClose: () => void;
  shouldCloseAfterSave?: boolean;
  initialMPGValue?: number;
}
export const BottomRow = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;
export const DescriptionText = styled.span`
  color: ${Color.GRAY_DARK};
  font-size: ${FontSize.Content}px;
  line-height: ${FontLineHeight.Content}px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${ElementSize.ButtonWidth}px;
  margin-left: auto;
  margin-right: auto;
  bottom: auto;
`;

export const MPGPanel: React.FC<Props> = ({
  layer = 0,
  onClose,
  shouldCloseAfterSave = true,
  initialMPGValue,
}: Props) => {
  const storedMPG = useSelector((state) => state.settings.routesSettings?.payload?.mpg);
  const initialMPG = storedMPG ? round(storedMPG, 2) : initialMPGValue;
  const updateStatus = useSelector((state) => state.settings.routesSettings);
  const initialMPGString = initialMPG ? toString(initialMPG) : '';
  const [mpg, setMPG] = useState(initialMPGString);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (initialMPG === undefined) {
      dispatch(fetchRoutesSettings());
    }
  }, []);

  useEffect(() => {
    if (initialMPG) {
      setMPG(toString(initialMPG));
    }
  }, [initialMPG]);

  useEffect(() => {
    if (updateStatus?.success !== undefined && isLoading) {
      setIsLoading(false);
      if (shouldCloseAfterSave) {
        onClose();
      }
      dispatch(
        showSnackbar({
          message: updateStatus.success
            ? t(T.settings_preferences_mpg_snackBarSuccess)
            : t(T.settings_preferences_mpg_snackBarFailure),
        })
      );
    }
  }, [updateStatus]);

  const onBlur = () => {
    const mpgField = createField(FieldType.MPG, mpg);
    const fornattedField = mpgField.normalisedText();
    setMPG(fornattedField);
  };

  const onChangeMPG = (mpg: string) => {
    const mpgField = createField(FieldType.MPG, mpg);
    if (!mpgField.doesAllowToType()) {
      return;
    }
    setMPG(mpgField.text ?? '');
    setIsDisabled(!combineValidators(validateStringLengthNotZero, validateIsGreaterThan(0))(mpg).isValid);
  };

  const onSaveMPG = () => {
    dispatch(updateRoutesSettings({ mpg: parseFloat(mpg) }));
    setIsDisabled(true);
    setIsLoading(true);
  };

  return (
    <Panel id="settings_mpg" layer={layer} offset={1} size={PanelSize.small} backgroundColor={PANEL_SHADOW_BACKGROUND}>
      <PanelHeader
        style={{ padding: SETTINGS_PANEL_PADDING }}
        label={t(T.settings_preferences_manageFuelMileage)}
        hasCloseButton={true}
        onClose={onClose}
      ></PanelHeader>
      <div style={{ margin: Spacing.ScreenSide }}>
        <BottomRow>
          <DescriptionText>{t(T.settings_preferences_mpg_description)}</DescriptionText>
        </BottomRow>
        <div style={{ marginTop: Spacing.ScreenSide }}>
          <FValidatedTextInput
            id="MPG_value"
            label={t(T.settings_preferences_mpg_title)}
            value={mpg}
            placeholder={t(T.settings_preferences_mpg_title)}
            focusOnLoad={true}
            onChange={(event) => onChangeMPG(event.target.value)}
            fullWidth={true}
            validator={combineValidators(blocking(validateStringLengthNotZero), validateIsGreaterThan(0))}
            autoComplete="off"
            onBlur={onBlur}
          />
        </div>
      </div>
      <PanelFooter>
        <MultipurposeButton
          id="save_profile"
          variant={'contained'}
          text={t(T.common_save)}
          color={'primary'}
          isLoading={isLoading}
          onClick={onSaveMPG}
          disabled={isDisabled}
        />
      </PanelFooter>
    </Panel>
  );
};

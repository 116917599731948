import { isEmpty, isEqual, toNumber, toString, trim } from 'lodash';

import { ApiErrorCode } from '@common/api';
import { formatFloat } from '@common/formatter/Number';
import { MIN_BID_AMOUNT } from '@common/helper/BidsHelper';
import { VendorBidInfo } from '@common/model';
import { T, t } from '@translate';

import { ValidationResult } from './Validators';

const MAX_DOT_VALUE = 99999999;

export const getValidBid = (bid: string) => {
  if (isEmpty(bid) || isEqual(bid, '.')) {
    return '';
  }

  const bidNumber = formatFloat(bid, 2);
  return toNumber(bidNumber) > 0 ? trim(toString(bidNumber)) : undefined;
};

export const validateBidTooLow = (validatable: string): ValidationResult => ({
  isValid: toNumber(validatable) > MIN_BID_AMOUNT,
  error: [t(T.common_errors_tooLow, { value: MIN_BID_AMOUNT })],
});

export const getValidDOT = (dot: string) => {
  if (isEmpty(dot)) {
    return '';
  }
  const dotNumber = formatFloat(dot, 0);
  return toNumber(dotNumber) > 0 && toNumber(dotNumber) <= MAX_DOT_VALUE ? toString(dotNumber) : undefined;
};

export const getBidButtonAndTitledText = (willResubmitBid: boolean) => {
  return willResubmitBid ? t(T.common_vendorBid_placeBid_reSubmitBid) : t(T.common_vendorBid_placeBid_submit);
};

export const getWillResubmitBid = (bid?: VendorBidInfo) => {
  return bid ? true : false;
};

interface Config {
  title: string;
  content: string;
}

export const getVendorBidFailedPopupConfig = (errorCode?: ApiErrorCode): Config => {
  switch (errorCode) {
    case ApiErrorCode.BID_LOAD_UNAVAILABLE:
      return {
        title: t(T.common_vendorBid_failedToPlaceBid_title),
        content: t(T.common_vendorBid_failedToPlaceBid_unavailable),
      };

    case ApiErrorCode.BID_DISQUALIFIED_CARRIER:
      return {
        title: t(T.common_vendorBid_failedToPlaceBid_disqualified_title),
        content: t(T.common_vendorBid_failedToPlaceBid_disqualified_content),
      };

    case ApiErrorCode.BID_NOT_FOUND_ERROR:
      return {
        title: t(T.common_error),
        content: t(T.common_vendorBid_failedToPlaceBid_not_found),
      };

    case ApiErrorCode.BID_INVALID_DOT_MC_ERROR:
      return {
        title: t(T.common_vendorBid_failedToPlaceBid_invalidDotMC_title),
        content: t(T.common_vendorBid_failedToPlaceBid_invalidDotMC_content),
      };

    default:
      return { title: t(T.common_vendorBid_failedToPlaceBid_title), content: t(T.common_bids_error_sendingFailed) };
  }
};

import { WidgetAction } from './WidgetAction';

//============================================================================
// Message Widgets
//============================================================================
//----- (For more information please see Confluence.) ------------------------
//
// This type system is designed to make processing Widgets and Widget
// Actions simple and efficient by creating 1-to-1 matches between payload types
// and enum identifiers. This way, we can write code that doesn't have to constantly
// verify optional parameters, and we can have accurate, reliable type inference
// in VS Code.
//
//----------------------------------------------------------------------------
// Add a new Widget Type
//----------------------------------------------------------------------------
//
// 1. Add the new type to MessageWidgetType
// 2. Define the payload for your new type
//      interface NewTypeWidgetPayload {
//        ...
//      }
// 3. Add your new Type and Payload to MessageWidget
//      export type MessageWidget =
//        | MessageWidgetPayload<MessageWidgetType.Generic, GenericWidgetPayload>
//        | ...other existing MessageWidget types...
//        | MessageWidgetPayload<MessageWidgetType.NewType, NewTypeWidgetPayload>;
//

export enum MessageWidgetType {
  Generic = 'Generic',
  // Add new types of widgets here, like:
  // Map = 'Map',
}

export interface GenericWidgetPayload {
  iconUrl?: string;
  title: string;
  message: string;
  actions: WidgetAction[];
}

type MessageWidgetPayload<WidgetType, Payload> = {
  type: WidgetType;
} & Payload;

export type MessageWidget = MessageWidgetPayload<MessageWidgetType.Generic, GenericWidgetPayload>;

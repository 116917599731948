import React from 'react';

import { join } from 'lodash';
import styled from 'styled-components';

import { Chip } from '@material-ui/core';

import { Color } from '@style/Color';
import { ElementSize, FontLineHeight, FontSize } from '@style/StyleConstants';

import { default as CancelSVG } from './cancel.svg';

const Container = styled(Chip)`
  && {
    display: flex;
    align-items: center;
    border: 1px solid ${Color.GRAY_SMOKE};
    color: ${Color.GRAY_DARK};
    font-size: ${FontSize.Body}px;
    line-height: ${FontLineHeight.Body}px;
    background-color: ${Color.RED_BANNER_ERROR};
    text-transform: none;
  }
`;

const ItemsContainer = styled(Container)`
  && {
    max-width: ${ElementSize.FilterChipMaxWidth}px;
  }
  && span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
  }
`;

const CloseIconButton = styled.img`
  padding: 2px;
  &:hover {
    background-color: ${Color.TRANSPARENT_0};
    filter: brightness(80%);
  }
`;

interface Props {
  id: string;
  title: string;
  onCancel: () => void;
  onClick: () => void;
  className?: string;
  itemsLabel?: string;
  items?: string[];
  shouldShowItems?: boolean;
  unit?: string;
  range?: string;
  customIcon?: string;
  style?: React.CSSProperties;
}

export const CancelableChip: React.FC<Props> = (props) => {
  if (props.shouldShowItems && props.itemsLabel) {
    const items = join(props.items, ', ');
    return (
      <ItemsContainer
        id={props.id}
        label={`${props.itemsLabel}: ${props.range ?? items} ${props.unit ?? ''}`}
        className={props.className}
        onClick={props.onClick}
        deleteIcon={<CloseIconButton id="icon" src={props.customIcon ?? CancelSVG} />}
        onDelete={props.onCancel}
        variant={'outlined'}
        style={props.style}
      />
    );
  } else
    return (
      <Container
        id={props.id}
        label={props.title}
        className={props.className}
        onClick={props.onClick}
        deleteIcon={<CloseIconButton id="icon" src={props.customIcon ?? CancelSVG} />}
        onDelete={props.onCancel}
        variant={'outlined'}
        style={props.style}
      />
    );
};

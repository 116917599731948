import { produce } from 'immer';
import { filter, isEmpty, isEqual, map, sortBy, split, toString, toUpper, trim } from 'lodash';

import {
  CREDIT_RATING_ANY,
  CREDIT_RATING_DAYS_TO_PAY_ANY,
  isCreditRatingAny,
  isCreditRatingDaysToPayAny,
  isPostedDateAny,
  POSTED_AGE_MINUTES,
} from '@common/helper/CreditRatingHelper';
import { serverRFCDateToYYYYMMDD } from '@common/helper/DateHelper';
import { FilterType, SearchFilter } from '@common/helper/FilterHelper';
import {
  shouldClearLoadLocationRadius,
  shouldClearOriginRadius,
  shouldUpdateDestinationRadius,
  shouldUpdateOriginRadius,
} from '@common/helper/LocationHelper';
import { isTripLengthAny, TRIP_LENGTH_ANY } from '@common/helper/SearchHelper';
import { LocationType, MAX_PLUS_TRUCK_LENGTH, MAX_PLUS_TRUCK_WEIGHT, NearbyLoadsSettings } from '@common/model';
import { EquipmentSpecification, EquipmentType } from '@common/model/Equipment';
import { LoadSize } from '@common/model/Load';
import {
  ANYWHERE_LOCATION,
  BaseLoadSearchRequest,
  CompanyFilterType,
  DEFAULT_LOAD_SEARCH_FIELDS,
  DEFAULT_LOAD_SEARCH_RADIUS,
  EMPTY_ORIGIN_LOCATION,
  INITIAL_SEARCH_LIMIT,
  LegacyPosterLoadSearchRequest,
  LoadLocation,
  LoadLocationZip,
  LoadSearchMetadata,
  LoadSearchRequest,
  OriginLocation,
  PersistentLoadSearchDef,
  PosterLoadSearchRequest,
  SearchMetadataType,
} from '@common/model/LoadSearchRequest';
import { LoadSearchSettings } from '@common/model/LoadSearchSettings';
import { LoadSearchSort } from '@common/model/LoadSearchSort';

export const createLoadSearchMetadata = (metadata?: Partial<LoadSearchMetadata>): LoadSearchMetadata => ({
  type: SearchMetadataType.Regular,
  limit: INITIAL_SEARCH_LIMIT,
  fields: DEFAULT_LOAD_SEARCH_FIELDS,
  ...(metadata ?? {}),
});

const createDefaultBaseLoadSearchRequest = (): BaseLoadSearchRequest => ({
  destination: ANYWHERE_LOCATION,
  pickupDates: [],
  includeLoadsWithoutLength: true,
  includeLoadsWithoutWeight: true,
  metadata: createLoadSearchMetadata(),
});

export const createDefaultLoadSearchRequest = (): LoadSearchRequest => ({
  origin: EMPTY_ORIGIN_LOCATION,
  ...createDefaultBaseLoadSearchRequest(),
});

export const createDefaultLoadPostSearchRequest = (): PosterLoadSearchRequest => ({
  origin: ANYWHERE_LOCATION,
  ...createDefaultBaseLoadSearchRequest(),
  equipmentSpecifications: undefined,
});

export const convertPosterLoadSearchRequestToLegacy = (
  request: PosterLoadSearchRequest
): LegacyPosterLoadSearchRequest => ({
  ...request,
  equipmentSpecifications:
    isEqual(request.equipmentSpecifications, [EquipmentSpecification.All]) || isEmpty(request.equipmentSpecifications)
      ? undefined
      : toString(request.equipmentSpecifications),
});

export const resetMetadata = (searchRequest: LoadSearchRequest): LoadSearchRequest => ({
  ...searchRequest,
  metadata: createLoadSearchMetadata({ sortBy: searchRequest.metadata.sortBy }),
});

export const addLoadSearchFields = <T extends { metadata: LoadSearchMetadata }>(request: T): T =>
  produce(request, (newRequest) => {
    newRequest.metadata.fields = DEFAULT_LOAD_SEARCH_FIELDS;
  });

export const withSorting = (searchRequest: LoadSearchRequest, sortBy?: LoadSearchSort): LoadSearchRequest => {
  const currentSortBy = searchRequest.metadata?.sortBy;
  if (currentSortBy?.direction !== sortBy?.direction || currentSortBy?.field !== sortBy?.field) {
    return {
      ...searchRequest,
      metadata: {
        ...searchRequest.metadata,
        sortBy: sortBy && {
          direction: sortBy.direction,
          field: sortBy.field,
        },
      },
    };
  }
  return searchRequest;
};

export const withFilterSelection = (
  searchRequest: LoadSearchRequest,
  filterType: FilterType,
  isSelected: boolean
): LoadSearchRequest => {
  const updatedSearchRequest = normalizedSearchRequest(searchRequest);

  switch (filterType) {
    case FilterType.OnboardedCompanies:
      return {
        ...updatedSearchRequest,
        isOnboarded: isSelected || undefined,
        company: {
          ...updatedSearchRequest.company,
          isOnboarded: isSelected || undefined,
          types: CompanyFilterType.All,
        },
      };
    case FilterType.FavoriteCompanies:
      return { ...updatedSearchRequest, isFavoriteBroker: isSelected || undefined };
    case FilterType.CreditRating: {
      return {
        ...updatedSearchRequest,
        minTransCreditRating: isSelected
          ? (updatedSearchRequest.minTransCreditRating ?? CREDIT_RATING_ANY.minTransCreditRating)
          : undefined,
        maxTransCreditRating: isSelected
          ? (updatedSearchRequest.maxTransCreditRating ?? CREDIT_RATING_ANY.maxTransCreditRating)
          : undefined,
      };
    }
    case FilterType.DaysToPay: {
      return {
        ...updatedSearchRequest,
        minTransCreditDaysToPay: isSelected
          ? (updatedSearchRequest.minTransCreditDaysToPay ?? CREDIT_RATING_DAYS_TO_PAY_ANY.minTransCreditDaysToPay)
          : undefined,
        maxTransCreditDaysToPay: isSelected
          ? (updatedSearchRequest.maxTransCreditDaysToPay ?? CREDIT_RATING_DAYS_TO_PAY_ANY.maxTransCreditDaysToPay)
          : undefined,
      };
    }
    case FilterType.TripLength: {
      return {
        ...updatedSearchRequest,
        minMileage: isSelected ? (updatedSearchRequest.minMileage ?? TRIP_LENGTH_ANY.minMileage) : undefined,
        maxMileage: isSelected ? (updatedSearchRequest.maxMileage ?? TRIP_LENGTH_ANY.maxMileage) : undefined,
      };
    }
    case FilterType.Shipper:
      return {
        ...updatedSearchRequest,
        company: {
          ...updatedSearchRequest.company,
          types: isSelected ? CompanyFilterType.Shipper : CompanyFilterType.All,
        },
      };
    case FilterType.TrailerSpecifications:
      return {
        ...updatedSearchRequest,
        equipmentSpecifications: isSelected ? (updatedSearchRequest.equipmentSpecifications ?? '') : undefined,
      };
    case FilterType.PostedAge:
      return {
        ...updatedSearchRequest,
        minAge: isSelected ? (updatedSearchRequest.minAge ?? POSTED_AGE_MINUTES.minAge) : undefined,
        maxAge: isSelected ? (updatedSearchRequest.maxAge ?? POSTED_AGE_MINUTES.maxAge) : undefined,
      };
    case FilterType.Commodity:
      return { ...updatedSearchRequest, hasCommodity: isSelected || undefined };
    case FilterType.PostedRate:
      return { ...updatedSearchRequest, hasRate: isSelected || undefined };
    case FilterType.TeamDrivers:
      return { ...updatedSearchRequest, hasTeam: isSelected || undefined };
    case FilterType.Tl:
      return { ...updatedSearchRequest, loadSize: isSelected ? LoadSize.TL : undefined };
    case FilterType.Ltl:
      return { ...updatedSearchRequest, loadSize: isSelected ? LoadSize.LTL : undefined };
    case FilterType.WithWeight:
      return {
        ...updatedSearchRequest,
        includeLoadsWithoutWeight: !isSelected,
      };
    case FilterType.WithLength:
      return {
        ...updatedSearchRequest,
        includeLoadsWithoutLength: !isSelected,
      };
    case FilterType.WithBidding:
      return {
        ...updatedSearchRequest,
        hasBidding: isSelected || undefined,
      };
    case FilterType.WithMessaging:
      return {
        ...updatedSearchRequest,
        hasMessaging: isSelected || undefined,
      };
    default:
      return updatedSearchRequest;
  }
};

export const withoutFilter = (searchRequest: LoadSearchRequest, filterType: FilterType) =>
  withFilterSelection(searchRequest, filterType, false);

export const withFilters = (searchRequest: LoadSearchRequest, searchFilter: SearchFilter): LoadSearchRequest => {
  const isCreditRatingAnyValue = isCreditRatingAny(
    searchFilter.minTransCreditRating,
    searchFilter.maxTransCreditRating
  );
  const isCreditRatingDaysToPayAnyValue = isCreditRatingDaysToPayAny(
    searchFilter.minTransCreditDaysToPay,
    searchFilter.maxTransCreditDaysToPay
  );
  const isTripLengthAnyValue = isTripLengthAny(searchFilter.minMileage, searchFilter.maxMileage);
  const isPostedDateAnyValue = isPostedDateAny(searchFilter.minAge, searchFilter.maxAge);

  return {
    ...searchRequest,
    ...searchFilter,
    saveAsRecentSearch: false,
    metadata: searchRequest.metadata, // FIXME: searchFilter can contain metadata.
    equipmentSpecifications: isEmpty(searchFilter.equipmentSpecifications)
      ? undefined
      : searchFilter.equipmentSpecifications,
    minTransCreditRating: isCreditRatingAnyValue ? undefined : searchFilter.minTransCreditRating,
    maxTransCreditRating: isCreditRatingAnyValue ? undefined : searchFilter.maxTransCreditRating,
    minTransCreditDaysToPay: isCreditRatingDaysToPayAnyValue ? undefined : searchFilter.minTransCreditDaysToPay,
    maxTransCreditDaysToPay: isCreditRatingDaysToPayAnyValue ? undefined : searchFilter.maxTransCreditDaysToPay,
    minMileage: isTripLengthAnyValue ? undefined : searchFilter.minMileage,
    maxMileage: isTripLengthAnyValue ? undefined : searchFilter.maxMileage,
    minAge: isPostedDateAnyValue ? undefined : searchFilter.minAge,
    maxAge: isPostedDateAnyValue ? undefined : searchFilter.maxAge,
  };
};

export const addRadius = (
  searchRequest: LoadSearchRequest,
  settings: LoadSearchSettings | undefined = undefined
): LoadSearchRequest =>
  produce(searchRequest, (draft) => {
    // Origin radius
    if (shouldClearOriginRadius(draft.origin)) {
      draft.origin.radius = undefined;
    }
    if (shouldUpdateOriginRadius(draft.origin)) {
      draft.origin.radius = settings?.origRadius || DEFAULT_LOAD_SEARCH_RADIUS;
    }
    // Destination radius
    if (shouldClearLoadLocationRadius(draft.destination)) {
      draft.destination.radius = undefined;
    }
    if (shouldUpdateDestinationRadius(draft.destination)) {
      draft.destination.radius = settings?.destRadius || DEFAULT_LOAD_SEARCH_RADIUS;
    }
  });

export const withoutNextToken = (searchRequest: LoadSearchRequest): LoadSearchRequest => {
  if (searchRequest.metadata?.nextToken) {
    return {
      ...searchRequest,
      metadata: {
        ...searchRequest.metadata,
        nextToken: undefined,
      },
    };
  }
  return searchRequest;
};

export const withLimit = (searchRequest: LoadSearchRequest, limit: number): LoadSearchRequest => {
  if (searchRequest.metadata?.limit !== limit) {
    return {
      ...searchRequest,
      metadata: {
        ...searchRequest.metadata,
        limit: limit,
      },
    };
  }
  return searchRequest;
};

export const withNearbyLoadsSettings = (
  searchRequest: LoadSearchRequest,
  settings: NearbyLoadsSettings | undefined
): LoadSearchRequest => {
  const withSettings = () => {
    if (settings) {
      const equipmentTypes = settings.equipmentTypes ?? searchRequest.equipmentTypes;
      const length = settings.length === MAX_PLUS_TRUCK_LENGTH ? undefined : settings.length;
      const weight = settings.weight === MAX_PLUS_TRUCK_WEIGHT ? undefined : settings.weight;
      if (
        searchRequest.equipmentTypes !== equipmentTypes ||
        searchRequest.length !== length ||
        searchRequest.weight !== weight
      ) {
        return {
          ...searchRequest,
          equipmentTypes: equipmentTypes,
          length: length,
          weight: weight,
        };
      }
    }
    return searchRequest;
  };
  return withFilteredEquipmentTypes(withSettings());
};

export const withOriginAndDestination = (
  searchRequest: LoadSearchRequest,
  origin: OriginLocation | undefined,
  destination: LoadLocation | undefined
): LoadSearchRequest =>
  origin && destination && (searchRequest.origin !== origin || searchRequest.destination !== destination)
    ? {
        ...searchRequest,
        origin: origin,
        destination: destination,
      }
    : searchRequest;

export const metadataWithLimit = (metadata: LoadSearchMetadata, limit: number): LoadSearchMetadata => {
  if (metadata.limit !== limit) {
    return {
      ...metadata,
      limit: limit,
    };
  }
  return metadata;
};

export const intermediateRequest = (searchRequest: LoadSearchRequest): LoadSearchRequest => {
  // "saveAsRecentSearch" is default true on the server, but no longer required for named-searches (LB-1285)
  return { ...searchRequest, saveAsRecentSearch: false };
};

const withFilteredEquipmentTypes = (searchRequest: LoadSearchRequest): LoadSearchRequest => {
  const equipmentTypes =
    searchRequest.equipmentTypes && searchRequest.equipmentTypes[0] === EquipmentType.All
      ? []
      : searchRequest.equipmentTypes;

  if (searchRequest.equipmentTypes !== equipmentTypes) {
    return {
      ...searchRequest,
      equipmentTypes: equipmentTypes,
    };
  }
  return searchRequest;
};

const normalizedSearchRequest = (searchRequest: LoadSearchRequest): LoadSearchRequest => ({
  ...searchRequest,
  loadSize:
    searchRequest.loadSize && searchRequest.loadSize !== LoadSize.None
      ? (toUpper(searchRequest.loadSize) as LoadSize)
      : undefined,
  equipmentSpecifications:
    searchRequest.equipmentSpecifications !== EquipmentSpecification.None
      ? searchRequest.equipmentSpecifications
      : undefined,
});

export const createApplyFilterRequest = (
  searchRequest: LoadSearchRequest,
  searchFilter: SearchFilter
): LoadSearchRequest => resetMetadata(withFilters(searchRequest, searchFilter));

export const createCancelFilterRequest = (
  searchRequest: LoadSearchRequest,
  filterType: FilterType
): LoadSearchRequest => resetMetadata(withoutFilter(searchRequest, filterType));

const zipCodeNormalize = (origin: OriginLocation): OriginLocation => {
  if (origin.type !== LocationType.ZIP) {
    return origin;
  }
  const newZipOrigin: LoadLocationZip = origin;
  //Match canadian ZIP code without space between 3-symbol parts
  const zipCodePattern = /^\s*([A-Za-z][0-9][A-Za-z])([0-9][A-Za-z][0-9])\s*$/;
  const spacelessCanadianZip = zipCodePattern.exec(origin.zipCode);

  if (spacelessCanadianZip) {
    newZipOrigin.zipCode = `${spacelessCanadianZip[1]} ${spacelessCanadianZip[2]}`;
  }
  return newZipOrigin;
};

export const createRequestFromLoadSearchSettings = (search: PersistentLoadSearchDef): LoadSearchRequest => {
  const loadSize = toUpper(search.loadSize);
  return {
    origin: zipCodeNormalize(search.origin),
    destination: search.destination,
    equipmentTypes: search.equipmentTypes
      ? filter(
          filter(
            map(map(split(search.equipmentTypes, ','), trim), (str) => EquipmentType[str as keyof typeof EquipmentType])
          ),
          (loadType) => loadType !== EquipmentType.All
        )
      : undefined,
    metadata: createLoadSearchMetadata({ sortBy: search.sortBy }),
    weight: search.weight,
    length: search.length,
    minWeight: search.minWeight,
    minLength: search.minLength,
    pickupDates: sortBy(map(search.pickupDates, (date) => serverRFCDateToYYYYMMDD(date))),
    loadSize: loadSize === LoadSize.LTL || loadSize === LoadSize.TL ? loadSize : undefined,
    includeLoadsWithoutLength: search.includeLoadsWithoutLength,
    includeLoadsWithoutWeight: search.includeLoadsWithoutWeight,
    isFavoriteBroker: search.isFavoriteBroker,
    isOnboarded: search.isOnboarded,
    maxMileage: search.maxMileage,
    minMileage: search.minMileage,
  };
};

export const prepareNearbyLoadsSearchRequest = (searchRequest: LoadSearchRequest) => ({
  ...searchRequest,
  loadSize: undefined,
  minLength: searchRequest.minLength ?? 0,
  minWeight: searchRequest.minWeight ?? 0,
});

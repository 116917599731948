import { includes } from 'lodash';
import { StateObservable } from 'redux-observable';

import {
  AlertSettings,
  Config,
  ConfigList,
  Flag,
  LoadSortCategory,
  RoutesSettings,
  SystemSettingsState,
  TruckLocatorSettings,
  UserSettings,
} from '@common/model';
import {
  CostPerDaySetting,
  DEFAULT_DRIVING_HOURS,
  DEFAULT_LOADING_HOURS,
  DEFAULT_WORK_DAYS,
} from '@common/model/CostPerDaySettings';
import { ProfitCalculatorSubMenu } from '@common/model/ProfitCalculatorSettings';
import { BaseState, Response } from '@common/redux/Base';

export const SETTINGS_REDUCER_KEY = 'settings';

export const DEFAULT_TL_SETTINGS: TruckLocatorSettings = {
  loadSortBy: LoadSortCategory.PICKUP_DATE,
  loadSortAsc: true,
  mapView: true,
  loadAlertMessage: '',
  loadWizardStep: 0,
  loadWizardCompleted: false,
  truckWizardStep: 0,
  truckWizardCompleted: false,
};

interface SettingsResponse<T> extends Response<T> {
  recordOnServer: boolean;
}

export type RSResponse = Response<RoutesSettings>;
export type TLSettingsResponse = SettingsResponse<TruckLocatorSettings>;

export interface SettingsState extends BaseState {
  routesSettings?: RSResponse;
  truckLocatorSettings?: TLSettingsResponse;
  truckLocatorUpdatedSettings?: TLSettingsResponse;
  isLoadingUserSettings: boolean;
  isLoadingAlertSettings: boolean;
  isLoadingEmailVerificationSettings: boolean;
  userSettings?: UserSettings;
  alertSettings?: AlertSettings;
  isEmailVerified?: boolean; //Keeping it optional to differ behaviour beteween non initialized and false/true
  isLoadingSystemSetting: boolean;
  systemSetting: SystemSettingsState;
  /** The changeFeedToken returned on the response can be passed back on
   * the next call to get all the settings that have changed. */
  changeFeedToken: string | undefined;
}

/** Extract a type-safe state for the settings redux slice from root Epic StateObservable */
export const getSettingsFromStateObservable = (
  stateObservable: StateObservable<{
    [reducerKey: string]: SettingsState | any;
  }>
) => stateObservable.value[SETTINGS_REDUCER_KEY] as SettingsState;

export const initialSystemSettingsState: SystemSettingsState = {
  [Flag.BiddingDisabled]: { value: true },
  [Flag.BiddingV2Disabled]: { value: true },
  [Flag.BookNowDisabled]: { value: true },
  [Flag.LoadRateCheckPreviewEnabled]: { value: false },
  [Flag.LoadRateCheckPreviewUserDisabled]: { value: false },
  [Flag.VendorBiddingDisabled]: { value: true },
  [Flag.HideParadeContact]: { value: false },
  [Flag.CargoChiefUserDisabled]: { value: false },
  [Flag.GreenscreensRateDisabled]: { value: true },
  [Flag.DisplaySearchLoadCount]: { value: false },
  [Flag.BrokerAcceptsBids]: { value: true },
  [Flag.IntegrationCotaEnabled]: { value: false },
  [Flag.MessageSoundAlertsEnabled]: { value: false },
  [Flag.BookingOptionsEnabled]: { value: false },
  [Flag.CarrierOnboardingBannerEnabled]: { value: false },
  [Flag.CapacityFinderDisabled]: { value: false },
  [Flag.TripBuilderEnabled]: { value: false },
  [Flag.LoadPlannerEnabled]: { value: true },
  [Flag.LoadLocatorEnabled]: { value: false },
  [Flag.BrokerOnboardingLinkUploadEnabled]: { value: false },
  [Flag.LoadToTruckRatioEnabled]: { value: false },
  [Flag.MyCarrierPortalEnabled]: { value: false },
  [Flag.MyCarrierPortalBrokerEnabled]: { value: false },
  [Flag.ExpediteAllMarketRateEnabled]: { value: false },
  [Flag.ExpediteAllPostLoadEnabled]: { value: false },
  [Config.CostPerDaySettings]: {
    value: {
      [CostPerDaySetting.enabled]: true,
      [CostPerDaySetting.truckExpenses]: 0,
      [CostPerDaySetting.homeExpenses]: 0,
      [CostPerDaySetting.workDays]: DEFAULT_WORK_DAYS,
      [CostPerDaySetting.loadingHours]: DEFAULT_LOADING_HOURS,
      [CostPerDaySetting.drivingHours]: DEFAULT_DRIVING_HOURS,
    },
  },
  [Config.MaintenancePopup]: { value: undefined },
  [Config.ProfitCalculatorSettings]: {
    value: {
      [ProfitCalculatorSubMenu.estimatedFuelCostsIsExpanded]: false,
      [ProfitCalculatorSubMenu.tripIsExpanded]: false,
      [ProfitCalculatorSubMenu.additionalCostsIsExpanded]: false,
    },
  },
};

export const isSystemSettingConfig = (setting: string): setting is Config => includes(ConfigList, setting);

export const isRateCheckPreviewEnabled = (settingsState: SettingsState) =>
  settingsState.systemSetting[Flag.LoadRateCheckPreviewEnabled].value &&
  !settingsState.systemSetting[Flag.LoadRateCheckPreviewUserDisabled].value;

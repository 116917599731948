import React, { useEffect } from 'react';

import { first, uniq } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { displayCurrencyOrDash, hasDecimal } from '@common/formatter';
import { BidAction, BidStatus, BidSummary } from '@common/model';
import { actOnBid, fetchLoadBids, getBidLoadIsOnline } from '@common/redux/epic/bids/BidsEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { MoreMenuButton } from '@component/buttons/MoreMenuButton';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Status } from '@component/loadListCard/LoadListCardStyle';
import { DropdownMenu } from '@component/menu/DropdownMenu';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { usePanelBackLocation, usePatchedPushPanel } from '@component/panel/PanelHooks';
import { Text, TextStyle } from '@component/text/Text';
import { BidFormPanel } from '@page/bids/BidFormsPanel';
import { bidsEncoder, BidsRouteParamsProps } from '@page/bids/BidsPanelRouting';
import { useAckOnBidUpdate, useIsV2BidsActive } from '@page/bids/BidStatusHelper';
import { BidInfoRibbon } from '@page/bids/summary/BidInfoRibbonSummary';
import { Color } from '@style/Color';
import { FontLineHeight, FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useIsiPadView, useSelector } from '@util/hooks';

import { BidHistoryList } from './BidHistoryList';
import { ActionBidItem, EndContainer, NoActionBidItem, Price } from './BidListItem';
import {
  BidsPanelType,
  getMenuOptions,
  getPanelLabel,
  getStatusColor,
  getStatusText,
  getTextContent,
  useDropdownMenu,
} from './BidSummaryHelpers';
import { ExpirationTimeBanner } from './ExpirationTimeBanner';
import { OfferButton, PendingOfferActions } from './OfferActions';

interface BidSummaryPanelProps {
  onCloseDetailsPanel?: () => void;
  layer: number;
  panelOffset: number;
  isCarrier: boolean;
}

export const BidSummaryPanel = (props: BidSummaryPanelProps) => {
  const history = useHistory();
  const params = useParams<BidsRouteParamsProps>();
  const dispatch = useDispatch();

  const isIPadView = useIsiPadView();

  useAckOnBidUpdate(params.offerID ?? '');

  const panelType = params.edit ? (params.edit as BidsPanelType) : undefined;
  const closeLocation = usePanelBackLocation(`/${panelType}/`);
  const closeLocationToList = usePanelBackLocation('/summary/');
  const pushOfferPanel = usePatchedPushPanel('/offer/', panelType ?? '');
  const pushPlacedNewOfferPanel = usePatchedPushPanel('/offer/', '/new/');

  const { menuAnchor, onMenuClick, onMenuClose } = useDropdownMenu();

  const searchParams = bidsEncoder.convertUrlSearchParamsToObject(history.location.search);

  // when coming from the chat page, this creates the back link
  const redirectCommunicationPath = searchParams.redirectCommunicationPath;

  const redirectLoadDetailsPath = searchParams.redirectLoadDetailsPath;
  const redirectLoadDetailsSearch = searchParams.redirectLoadDetailsSearch;

  const isBidLoading = useSelector((state) => state.bids.loadBids.listAll.isLoading);
  let bidIds = useSelector((state) => state.bids.loadBids.listAll.ids);

  // @FIXME: remove duplicate ids that is received due to a bug in BidsEpic lines 306 - 311
  // when bug is fixed please remove
  bidIds = uniq(bidIds);

  const isOnlineLoading = useSelector((state) => state.bids.bidLoadDetails[LoadSearchType.LoadSearch].isLoading);
  const isOnline = useSelector((state) => state.bids.bidLoadDetails[LoadSearchType.LoadSearch].isOnline);

  const bidSummary = useSelector((state) => state.bids.bidSummaries.summaries.get(params.offerID ?? ''));
  const bidActions = useSelector((state) => state.bids.bidActions);

  const isLoading = isBidLoading || isOnlineLoading;

  const shouldShowExpirationDate = useIsV2BidsActive();
  const carrierId = props.isCarrier ? 'self' : (params.otherPartyUserID ?? bidSummary?.otherParty?.contactId);

  useEffect(() => {
    if (!params.loadID) {
      return;
    }
    // @FIXME: loadSearchType to be properly defined in LB-4540
    dispatch(getBidLoadIsOnline(LoadSearchType.LoadSearch, params.loadID));
  }, [params.loadID]);

  useEffect(() => {
    if (!params.loadID) {
      return;
    }

    // the following condition prevents unnecessary fetching but allows fetching directly from
    // the bid summary panel when refreshing or opening the bid panel after placing a bid in load details
    // (LB-2251, LB-2608, LB-2687)
    if (bidIds.length > 0 && ((first(bidIds) === params.bidID && props.isCarrier) || !props.isCarrier)) {
      return;
    }

    dispatch(fetchLoadBids({ loadId: params.loadID, carrierId: carrierId }));
  }, [params.loadID, carrierId]);

  useEffect(() => {
    // if status undefined then return
    // if the bid is already viewed then return
    if (!bidSummary?.status || bidSummary?.isViewed) {
      return;
    }

    const ackAction = getAckStatusAction(bidSummary?.status, bidSummary?.offerMadeByMe ?? false);
    if (!ackAction) {
      return;
    }

    dispatch(actOnBid({ bidId: bidSummary?.bidId ?? '', action: ackAction }));
  }, [bidActions.actionsInProgress, bidSummary?.status, bidSummary?.isViewed]);

  const closePanel = () => {
    if (redirectCommunicationPath && props.isCarrier && panelType === 'offer') {
      history.push({
        pathname: `${redirectCommunicationPath}`,
      });
    } else if (redirectLoadDetailsPath && props.isCarrier && panelType === 'offer') {
      history.push({
        pathname: `${redirectLoadDetailsPath}`,
        search: redirectLoadDetailsSearch?.substring(1),
      });
    } else if (panelType === 'offer') {
      if (props.isCarrier) {
        closeLocationToList();
      } else {
        closeLocation();
      }
    } else if (panelType === 'new') {
      pushPlacedNewOfferPanel(params.bidID);
    } else {
      pushOfferPanel(params.bidID);
    }
  };

  let content;
  if (isLoading) {
    content = <LoadingSpinner id="loading" />;
  } else {
    if (panelType === 'counteroffer' || panelType === 'edit') {
      content = (
        <BidFormPanel
          isPlaceNewBidPanel={false}
          isCarrier={props.isCarrier}
          previousBidSummary={bidSummary}
          shouldShowExpirationDate={shouldShowExpirationDate}
        />
      );
    } else if (panelType === 'new') {
      content = (
        <BidFormPanel
          isPlaceNewBidPanel={true}
          isCarrier={props.isCarrier}
          previousBidSummary={bidSummary}
          shouldShowExpirationDate={shouldShowExpirationDate}
        />
      );
    } else if (panelType === 'offer' || (redirectCommunicationPath && panelType === 'offer')) {
      content = (
        <BidSummaryListComponent
          isOnline={isOnline}
          isCarrier={props.isCarrier}
          bidSummary={bidSummary}
          shouldShowExpirationDate={shouldShowExpirationDate}
          otherPartyId={params.otherPartyUserID}
          ids={bidIds}
        />
      );
    }
  }

  return panelType ? (
    <Panel
      id="bid_offer"
      size={PanelSize.small}
      layer={!props.isCarrier && isIPadView ? props.layer + 1 : props.layer}
      offset={props.panelOffset}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        style={{ padding: '0 6px' }}
        label={getPanelLabel(panelType)}
        hasCloseButton={true}
        onClose={() => closePanel()}
        actionElement={
          props.isCarrier && panelType !== 'counteroffer' && panelType !== 'edit' ? (
            <MoreMenuButton ref={menuAnchor} onClick={onMenuClick} />
          ) : undefined
        }
      >
        <DropdownMenu anchor={menuAnchor} onClose={onMenuClose}>
          {getMenuOptions(onMenuClose)}
        </DropdownMenu>
      </PanelHeader>
      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <LoadingSpinner />
        </div>
      ) : (
        content
      )}
    </Panel>
  ) : null;
};

interface BidSummaryListComponentProps {
  isOnline?: boolean;
  isCarrier: boolean;
  bidSummary?: BidSummary;
  otherPartyId?: string;
  ids: string[];
  shouldShowExpirationDate: boolean;
}

const BidSummaryListComponent = (props: BidSummaryListComponentProps) => {
  const dispatch = useDispatch();

  const pushNewBidPanel = usePatchedPushPanel('/new/', '/offer/');
  const pushCounterofferPanel = usePatchedPushPanel('/counteroffer/', '/offer/');
  const pushEditOfferPanel = usePatchedPushPanel('/edit/', '/offer/');

  const bidAction = useSelector((state) => state.bids.bidActions.actionsInProgress.get(props.bidSummary?.bidId ?? ''));

  const hasPlaceNewBidCard =
    (props.bidSummary?.status === BidStatus.Withdrawn || props.bidSummary?.status === BidStatus.Rejected) &&
    props.isCarrier &&
    props.isOnline;

  let content;
  if (hasPlaceNewBidCard) {
    content = (
      <ActionBidItem
        isNewBid={true}
        content={
          <Text id="bid_offer_content" textStyle={TextStyle.Body}>
            {t(T.common_bids_bidItem_messages_carrier_available)}
          </Text>
        }
        offerActions={
          <OfferButton
            id="place_bid_button"
            color="primary"
            onClick={() => pushNewBidPanel(props.bidSummary?.bidId)}
            text={t(T.common_bids_placeBid_title)}
            variant="outlined"
          />
        }
      />
    );
  } else if (
    props.bidSummary?.status === BidStatus.Accepted ||
    props.bidSummary?.status === BidStatus.Withdrawn ||
    props.bidSummary?.status === BidStatus.Rejected
  ) {
    content = (
      <NoActionBidItem
        isCarrier={props.isCarrier}
        offerMadeByMe={props.bidSummary?.offerMadeByMe}
        mostRecentActivity={props.bidSummary?.mostRecentActivity ?? ''}
        price={props.bidSummary?.price ?? 0}
        status={props.bidSummary?.status}
      />
    );
  } else if (props.bidSummary?.status === BidStatus.Pending || props.bidSummary?.status === BidStatus.ActionRequired) {
    const statusColor = getStatusColor(props.bidSummary?.status);
    const statusText = getStatusText(props.bidSummary?.status);
    const textContent = getTextContent({
      status: props.bidSummary?.status,
      isCarrier: props.isCarrier,
      offerMadeByMe: props.bidSummary?.offerMadeByMe,
      price: props.bidSummary?.price,
      brokerName: props.bidSummary?.otherParty?.company.name,
    });

    content = (
      <ActionBidItem
        isCarrier={props.isCarrier}
        offerMadeByMe={props.bidSummary?.offerMadeByMe}
        mostRecentActivity={props.bidSummary?.mostRecentActivity}
        end={
          <EndContainer>
            <Price id="price_value_offer">
              {`${displayCurrencyOrDash(props.bidSummary?.price, hasDecimal(props.bidSummary?.price))}`}
            </Price>
            <Status
              style={{ minWidth: 'max-content', width: '100%', textAlign: 'center' }}
              id="bid_status_offer"
              color={statusColor ?? Color.BLACK}
            >
              {statusText}
            </Status>
          </EndContainer>
        }
        content={
          <Text id="bid_offer_content" style={{ fontSize: FontSize.Body, lineHeight: `${FontLineHeight.SmallBody}px` }}>
            {textContent}
          </Text>
        }
        isNewBid={false}
        offerActions={
          <PendingOfferActions
            isLoadingAction={bidAction !== undefined}
            offerMadeByMe={props.bidSummary.offerMadeByMe}
            onEdit={() => pushEditOfferPanel(props.bidSummary?.bidId)}
            onAccept={() => dispatch(actOnBid({ bidId: props.bidSummary?.bidId ?? '', action: BidAction.Accept }))}
            onCounter={() => pushCounterofferPanel(props.bidSummary?.bidId)}
            onCancel={() => dispatch(actOnBid({ bidId: props.bidSummary?.bidId ?? '', action: BidAction.Withdraw }))}
            onReject={() => dispatch(actOnBid({ bidId: props.bidSummary?.bidId ?? '', action: BidAction.Reject }))}
          />
        }
      />
    );
  }

  return (
    <>
      <BidInfoRibbon bid={props.bidSummary} isViewingOffer={true} />
      {props.shouldShowExpirationDate ? <ExpirationTimeBanner bidId={props.bidSummary?.bidId ?? ''} /> : null}
      {content}
      {props.ids?.length > 0 ? <BidHistoryList bidIds={props.ids} hasPlaceNewBidCard={hasPlaceNewBidCard} /> : null}
    </>
  );
};

const getAckStatusAction = (status: BidStatus, offerMadeByMe: boolean) => {
  switch (status) {
    case BidStatus.Assigned:
    case BidStatus.ActionRequired:
      return offerMadeByMe ? BidAction.AcceptAck : BidAction.OfferAck;
    case BidStatus.Rejected:
      return BidAction.RejectAck;
    case BidStatus.Withdrawn:
      return BidAction.WithdrawAck;
    case BidStatus.Accepted:
      return BidAction.AcceptAck;
  }

  return null;
};

import React from 'react';

import { map } from 'lodash';
import { connect } from 'react-redux';

import { StoreState } from '@/reduxStore/Store';
import { APPSTORE_LINK, GOOGLEPLAY_LINK } from '@common/info';
import {
  CopyrightText,
  Footer,
  FooterIconLink,
  FooterLogo,
  FooterLogoContainer,
  IconsContainer,
  StyledLink,
  VersionFooter,
} from '@component/menu/menuStyles';
import { t, T } from '@translate';
import { AppConfig } from '@util/AppConfig';
import { HOME_PAGE_LINK, PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from '@util/Link';

import AppStoreIcon from './icon/app-store.svg';
import FacebookIcon from './icon/facebook.svg';
import PlayStoreIcon from './icon/google-play.svg';
import InstagramIcon from './icon/instagram.svg';
import LinkedinIcon from './icon/linkedin.svg';
import TwitterIcon from './icon/twitter.svg';
import YoutubeIcon from './icon/youtube.svg';
import { useIsCarrier } from './NavigationMenuHelper';

interface ComponentProps {
  isMenuOpen: boolean;
}

interface ReduxProps {
  currentYear: string;
  isIntegrator: boolean;
}

type Props = ComponentProps & ReduxProps;

const MenuFooterComponent: React.FC<Props> = (props) => {
  const isCarrier = useIsCarrier();

  return (
    <Footer id="footer" opened={props.isMenuOpen}>
      {isCarrier ? (
        <FooterLogoContainer>
          {getComplexLogoButton(AppStoreIcon, APPSTORE_LINK, 'appstore_logo')}
          {getComplexLogoButton(PlayStoreIcon, GOOGLEPLAY_LINK, 'googleplay_logo')}
        </FooterLogoContainer>
      ) : undefined}
      <div>
        <StyledLink id="terms_of_service" href={TERMS_OF_SERVICE_LINK}>
          {t(T.footer_Terms_Of_Service)}
        </StyledLink>
        &nbsp;|&nbsp;
        <StyledLink id="privacy_policy" href={PRIVACY_POLICY_LINK}>
          {t(T.footer_Privacy_Policy)}
        </StyledLink>
      </div>
      <CopyrightText id="copyright" href={HOME_PAGE_LINK}>
        {t(T.footer_Copyright, { year: props.currentYear })}
      </CopyrightText>
      {props.isMenuOpen && !props.isIntegrator ? <SocialMediaIcons /> : null}
      <VersionFooter id="app_version">{AppConfig.getAppVersionDisplay()}</VersionFooter>
    </Footer>
  );
};

const getComplexLogoButton = (logoSrc: string, href: string, id: string) => (
  <FooterIconLink id={id} href={href} target="_blank">
    <FooterLogo src={logoSrc} />
  </FooterIconLink>
);

const SocialMediaIcons: React.FC = () => {
  const socialMediaItems = [
    {
      id: 'facebook',
      link: 'https://www.facebook.com/123loadboard/',
      icon: FacebookIcon,
    },
    {
      id: 'instagram',
      link: 'https://www.instagram.com/123_loadboard/',
      icon: InstagramIcon,
    },
    {
      id: 'linkedin',
      link: 'https://www.linkedin.com/company/123loadboard',
      icon: LinkedinIcon,
    },
    {
      id: 'twitter',
      link: 'https://twitter.com/123loadboard',
      icon: TwitterIcon,
    },
    {
      id: 'youtube',
      link: 'https://www.youtube.com/user/123loadboard',
      icon: YoutubeIcon,
    },
  ];

  const socialMediaIcons = map(socialMediaItems, (socialMediaItem) => (
    <a
      id={socialMediaItem.id}
      href={socialMediaItem.link}
      target="_blank"
      rel="noopener noreferrer"
      key={socialMediaItem.id}
    >
      <img src={socialMediaItem.icon} />
    </a>
  ));
  return <IconsContainer>{socialMediaIcons}</IconsContainer>;
};

const mapStateToProps = (state: StoreState): ReduxProps => {
  return {
    currentYear: state.membersInfo.currentYear,
    isIntegrator: !!state.user.profile?.payload?.isIntegrator,
  };
};

export const MenuFooter = connect(mapStateToProps)(MenuFooterComponent);

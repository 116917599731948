import { Observable } from 'rxjs';

export enum StorageKey {
  RefreshToken = 'RefreshToken',
  MembersReloadingFlag = 'MEMBERS_RELOADING_FLAG',
  ProfitCalculator = 'ProfitCalculator',
  LocationPermission = 'LocationPermission',
}

export type StorageOperation = StorageOperationSuccess | StorageOperationFailure;

interface StorageOperationSuccess {
  success: true;
  data: string | undefined;
}
interface StorageOperationFailure {
  success: false;
  error: any;
}

export type RetrieveObject = <T>(key: StorageKey) => Observable<T | undefined>;

export type SaveObject = <T>(key: StorageKey, value: T) => Observable<boolean>;

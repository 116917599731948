import { findIndex, map, toUpper } from 'lodash';

import { getProgressWithoutExcludedStatuses } from '@common/helper/LoadHelper';
import { LoadMetadata, LoadProgress } from '@common/model';
import { t, T } from '@translate';

interface LoadProgressLabel {
  value: LoadProgress;
  title: string;
}

export const loadProgressLabelMap: { [key in LoadProgress]: string } = {
  [LoadProgress.LoadAvailable]: t(T.common_loadProgress_loadAvailable),
  [LoadProgress.Booked]: t(T.common_loadProgress_booked),
  [LoadProgress.ScheduledForPickup]: t(T.common_loadProgress_booked),
  [LoadProgress.PickupComplete]: t(T.common_loadProgress_pickupComplete),
  [LoadProgress.LoadEnRoute]: t(T.common_loadProgress_pickupComplete),
  [LoadProgress.Delivered]: t(T.common_loadProgress_delivered),
};

export const loadProgressLabel: LoadProgressLabel[] = map(
  Object.keys(loadProgressLabelMap) as LoadProgress[],
  (loadProgress) => ({
    value: loadProgress,
    title: loadProgressLabelMap[loadProgress],
  })
);

export const currentLoadProgressLabelIndex = (metadata?: LoadMetadata) => {
  if (!metadata || !metadata.userdata || !metadata.userdata.progress) {
    return 0;
  }
  const progress = getProgressWithoutExcludedStatuses(metadata.userdata.progress);
  const index = findIndex(loadProgressLabel, (loadProgress) => loadProgress.value === progress);
  return index || 0;
};

export const loadProgressUppercaseLabels = map(loadProgressLabel, (label) => toUpper(label.title));

import { find, map, reduce } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api } from '@common/api';
import { LoadsClient } from '@common/client/LoadsClient';
import { withoutStateHI } from '@common/helper/LoadAvailabilityHelper';
import { KeysSortByAge, loadSortToKeysSortBy } from '@common/helper/SortByKeys';
import {
  EquipmentType,
  FormatedLoadAvailability,
  LoadAvailabilitySortCategory,
  LoadAvailabilityType,
  LoadSearchRequest,
  LoadSearchSort,
  LoadSortField,
  loadSortFor,
  StateLoadAvailabilityResponse,
} from '@common/model';
import { Response } from '@common/redux/Base';

import { initialState as initialLoadSearchState, LoadSearchBaseState } from './loadSearch/HelperFunctions';
import { createLoadSearch, SearchReducerKey } from './loadSearch/LoadSearchShared';

export const LOAD_AVAILABILITY_REDUCER_KEY = SearchReducerKey.LOAD_AVAILABILITY;

const FETCH_LOAD_AVAILABILITIES = 'FETCH_LOAD_AVAILABILITIES';
const LOAD_AVAILABILITIES_FETCHED = 'LOAD_AVAILABILITIES_FETCHED';
const SET_SORT_OPTION = 'SET_SORT_OPTION';
const UPDATE_LAST_SEARCH_AVAILABILITIES = 'UPDATE_LAST_SEARCH_AVAILABILITIES';
const UPDATE_LIST_SEARCH_AVAILABILITIES = 'UPDATE_LIST_SEARCH_AVAILABILITIES';

const loadAvailabilitySearch = createLoadSearch(LOAD_AVAILABILITY_REDUCER_KEY);

interface LoadAvailabilityResponse extends Action {
  response: Response<StateLoadAvailabilityResponse>;
}

interface Filters {
  equipmentTypes: EquipmentType[];
  sortCategory: LoadAvailabilitySortCategory;
  isRefreshing: boolean;
}

interface SetSortOptionAction extends Action {
  sortOption?: LoadSearchSort;
}

interface SetLastSearchAction extends Action {
  lastSearch: LoadSearchRequest;
}

type FetchLoadAvailabilitiesAction = Filters & Action;

export const fetchLoadAvailabilities = (
  equipmentTypes: EquipmentType[],
  sortCategory: LoadAvailabilitySortCategory,
  isRefreshing = false
): FetchLoadAvailabilitiesAction => ({
  type: FETCH_LOAD_AVAILABILITIES,
  equipmentTypes: equipmentTypes,
  sortCategory: sortCategory,
  isRefreshing: isRefreshing,
});

export const setSortOption = (sortOption?: LoadSearchSort): SetSortOptionAction => ({
  type: SET_SORT_OPTION,
  sortOption: sortOption,
});

// Only used for updating last search after doing map searches
export const updateLastSearch = (lastSearch: LoadSearchRequest): SetLastSearchAction => ({
  type: UPDATE_LAST_SEARCH_AVAILABILITIES,
  lastSearch: lastSearch,
});

export const updateListSearch = (lastSearch: LoadSearchRequest): SetLastSearchAction => ({
  type: UPDATE_LIST_SEARCH_AVAILABILITIES,
  lastSearch: lastSearch,
});

export const searchLoads = (searchData?: LoadSearchRequest) => loadAvailabilitySearch.actions.searchLoads(searchData);
export const nextSearchLoads = loadAvailabilitySearch.actions.nextSearchLoads;
export const updateLoadAvailabilityLoads = loadAvailabilitySearch.actions.updateLoads;
export const performAutoRefresh = loadAvailabilitySearch.actions.performAutoRefresh;
export const viewLoadAvailabilityLoad = loadAvailabilitySearch.actions.viewLoad;
export const refreshLoadCount = loadAvailabilitySearch.actions.refreshLoadCount;
export const setSearchFilterDraft = loadAvailabilitySearch.actions.setSearchFilterDraft;
export const fetchLoadCount = loadAvailabilitySearch.actions.fetchLoadCount;

export interface LoadAvailabilityState extends LoadSearchBaseState {
  isLoading: boolean;
  isRefreshing: boolean;
  isRequestSuccessful?: boolean;
  updateTime?: number;
  errorCode?: number;
  loadAvailabilities?: FormatedLoadAvailability[];
  selectedSortOption?: LoadSearchSort;
  outboundAverage: number;
}

export const initialState: LoadAvailabilityState = {
  ...initialLoadSearchState,
  isLoading: false,
  isRefreshing: false,
  selectedSortOption: loadSortFor(LoadSortField.PostedAge),
  sortBy: KeysSortByAge,
  outboundAverage: 0,
};

export const loadAvailabilityReducer = (state = initialState, action: Action): LoadAvailabilityState => {
  switch (action.type) {
    case FETCH_LOAD_AVAILABILITIES: {
      const fetchAction = action as FetchLoadAvailabilitiesAction;
      return {
        ...state,
        isLoading: !fetchAction.isRefreshing,
        isRequestSuccessful: undefined,
        updateTime: undefined,
        isRefreshing: fetchAction.isRefreshing,
      };
    }
    case LOAD_AVAILABILITIES_FETCHED: {
      const availabilitiesAction = action as LoadAvailabilityResponse;
      const { success, payload, error } = availabilitiesAction.response;
      let availabilities = undefined;
      let outboundAverage = 0;
      if (success) {
        availabilities = map(payload?.stateAvailabilities, (stateAvailability) => {
          const filteredStateAvailability = withoutStateHI(stateAvailability);
          const inbound = find(
            filteredStateAvailability.availabilities,
            (availability) => availability.type === LoadAvailabilityType.INBOUND
          );
          const outbound = find(
            filteredStateAvailability.availabilities,
            (availability) => availability.type === LoadAvailabilityType.OUTBOUND
          );
          const intrastate = find(
            filteredStateAvailability.availabilities,
            (availability) => availability.type === LoadAvailabilityType.INTRASTATE
          );
          const outboundQuery = outbound && { ...outbound.query, saveLocations: false, saveAsRecentSearch: false };
          const intrastateQuery = intrastate && {
            ...intrastate.query,
            saveLocations: false,
            saveAsRecentSearch: false,
          };
          const inboundQuery = inbound && { ...inbound.query, saveLocations: false, saveAsRecentSearch: false };
          return {
            state: filteredStateAvailability.state,
            inbound: inbound?.count || 0,
            outbound: outbound?.count || 0,
            intrastate: intrastate?.count || 0,
            outboundQuery: outboundQuery,
            intrastateQuery: intrastateQuery,
            inboundQuery: inboundQuery,
          };
        });

        outboundAverage =
          reduce(availabilities, (totalLoads, availability) => totalLoads + availability.outbound, 0) /
          availabilities.length;
      }

      return {
        ...state,
        isLoading: false,
        isRefreshing: false,
        isRequestSuccessful: success,
        updateTime: Date.now(),
        loadAvailabilities: availabilities,
        outboundAverage: outboundAverage,
        errorCode: error?.code,
      };
    }
    case SET_SORT_OPTION: {
      const sortAction = action as SetSortOptionAction;
      const sortBy = sortAction.sortOption ? loadSortToKeysSortBy(sortAction.sortOption) || state.sortBy : state.sortBy;
      return { ...state, sortBy: sortBy, selectedSortOption: sortAction.sortOption };
    }
    case UPDATE_LAST_SEARCH_AVAILABILITIES: {
      const setLastSearchAction = action as SetLastSearchAction;
      return {
        ...state,
        lastSearchRequest: setLastSearchAction.lastSearch,
        listSearchRequest: setLastSearchAction.lastSearch,
      };
    }
    case UPDATE_LIST_SEARCH_AVAILABILITIES: {
      const setLastSearchAction = action as SetLastSearchAction;
      return { ...state, listSearchRequest: setLastSearchAction.lastSearch };
    }
    default: {
      const loadAvailabilitySearchState = loadAvailabilitySearch.reducer(state, action);
      if (loadAvailabilitySearch) {
        return { ...state, ...loadAvailabilitySearchState };
      }
      return state;
    }
  }
};

const fetchLoadAvailabilitiesEpic$ = (loadsClient: LoadsClient, action$: ActionsObservable<Action>) =>
  action$
    .ofType(FETCH_LOAD_AVAILABILITIES)
    .pipe(
      mergeMap$((action: FetchLoadAvailabilitiesAction) =>
        loadsClient
          .fetchLoadAvailability$(action.equipmentTypes, action.sortCategory)
          .pipe(map$((response) => response.resultDataResponse(LOAD_AVAILABILITIES_FETCHED)))
      )
    );

export const createLoadAvailabilityEpic = (api: Api, isLiveEnvironment: boolean) => {
  const loadsClient = new LoadsClient(api, isLiveEnvironment);

  return (action$: ActionsObservable<Action>, state$: StateObservable<{}>) =>
    merge$(
      fetchLoadAvailabilitiesEpic$(loadsClient, action$),
      loadAvailabilitySearch.createMergedEpic$(loadsClient, action$, state$)
    );
};

import { filter, map } from 'lodash';

import { LoadLocation, LocationType, OriginLocation, StateLoadAvailability } from '@common/model';

export const withoutStateHI = (stateLoadAvailability: StateLoadAvailability): StateLoadAvailability => ({
  ...stateLoadAvailability,
  availabilities: map(stateLoadAvailability.availabilities, (availability) => {
    return {
      ...availability,
      query: {
        ...availability.query,
        origin: filterStatesLocationNoHI(availability.query.origin) as OriginLocation,
        destination: filterStatesLocationNoHI(availability.query.destination),
      },
    };
  }),
});

const filterStatesLocationNoHI = (location: LoadLocation): LoadLocation => {
  if (location.type !== LocationType.STATE) {
    return location;
  }
  return {
    ...location,
    states: filter(location.states, (state) => state !== 'HI'),
  };
};

import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';

const DEFAULT_LOAD_VIEWERS_LIMIT = 5;
const DEFAULT_LOAD_VIEWERS_OFFSET = 0;
const DEFAULT_LOAD_VIEWERS_FIELDS = 'all';

export interface ViewerInfo {
  id?: string;
  contactName?: string;
  companyName?: string;
  isFavorite?: boolean;
  lastViewed?: string;
}

interface Metadata {
  totalResultCount?: number;
  queryTime?: number;
  pageResultCount?: number;
  currentOffset?: number;
}

export interface LoadViewers {
  viewers: ViewerInfo[];
  metadata: Metadata;
}

export const createDefaultLoadViewersRequest = (): PaginatedRequest => ({
  fields: DEFAULT_LOAD_VIEWERS_FIELDS,
  limit: DEFAULT_LOAD_VIEWERS_LIMIT,
  offset: DEFAULT_LOAD_VIEWERS_OFFSET,
});

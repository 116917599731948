import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useSelector } from '@/util/hooks';
import { isLoadBidSent } from '@common/helper';
import { fetchLoadBids } from '@common/redux/epic/bids/BidsEpic';
import { getCompanyInfo } from '@common/redux/epic/CompanyEpic';
import { useMount } from '@common/util/hooks';
import { ContactButtonProps, ContactsButton } from '@component/buttons/ContactsButton';
import { T, t } from '@translate';

import { UnreadNotificationCount } from '../LoadDetailsPanelStyle';
import { default as BidSentIcon } from './icon-bid-sent.svg';
import { default as BidIcon } from './icon-bid.svg';

const BaseBidButton = (
  props: ContactButtonProps & { hasBid: boolean; unreadBidsCount?: number; onClick: () => void }
) => {
  const dispatch = useDispatch();

  const isLoadingCompany = useSelector((state) => state.companyState.isLoadingCompany);

  const icon = props.hasBid ? BidSentIcon : BidIcon;
  const title =
    !props.isFullSize || props.isLabelHidden
      ? undefined
      : props.hasBid && props.isFullSize
        ? t(T.common_vendorBid_bidSent)
        : t(T.common_vendorBid_bid);

  useMount(() => dispatch(getCompanyInfo()));

  return (
    <ContactsButton
      id="bid_button"
      icon={{ regular: icon }}
      title={title}
      isDisabled={isLoadingCompany}
      onClick={props.onClick}
      isFullSize={props.isFullSize}
      iconAccessory={
        props.unreadBidsCount && props.unreadBidsCount > 0 ? (
          <UnreadNotificationCount>{props.unreadBidsCount}</UnreadNotificationCount>
        ) : null
      }
    />
  );
};

export const InteractiveBidButton = (props: ContactButtonProps & { onClick: () => void }) => {
  const dispatch = useDispatch();

  const hasBid = isLoadBidSent(props.load);

  const currentBidLoadId = useSelector((state) => state.bids.loadBids.listAll.loadId);

  useEffect(() => {
    if (hasBid && props.load?.id && currentBidLoadId !== props.load.id) {
      dispatch(fetchLoadBids({ loadId: props.load.id, carrierId: 'self' }));
    }
  }, [props.load?.id, currentBidLoadId]);

  return (
    <BaseBidButton {...props} hasBid={hasBid} unreadBidsCount={props.load?.rateNegotiations?.numberOfUnreadBids} />
  );
};

export const VendorBidButton = (props: ContactButtonProps & { onClick: () => void }) => (
  <BaseBidButton {...props} hasBid={!!props.load?.vendorInfo?.bid} />
);

import { join } from 'lodash';

import { authenticationToApiOptions, LBHeaders } from '@common/api/ApiOptions';
import { Api, withApiVersion } from '@common/api/ApiService';
import { BaseClient } from '@common/client/BaseClient';
import { intermediateRequest } from '@common/helper';
import { addArchivingHeader } from '@common/helper/FlowIDHeaderHelper';
import {
  AlertNotification,
  AspectRatio,
  BookNowResponse,
  CargoChiefRate,
  EquipmentType,
  FuelPrice,
  getStatesFromSortCategory,
  GreenscreensRate,
  Load,
  LoadAvailabilitySortCategory,
  LoadBackhauls,
  LoadProgress,
  LoadRateCheckPreviewResponse,
  LoadSearchGeoResponse,
  LoadSearchMetadata,
  LoadSearchRequest,
  LoadSearchResponse,
  LoadSortDirection,
  NamedSearchRequest,
  NearbyLoadsResponse,
  NearbyLoadsSearchRequest,
  PersistentLoadSearchDef,
  RateCheck,
  SimilarLoadSearchResponse,
  StateLoadAvailabilityResponse,
  StateMileagePayload,
} from '@common/model';
import { LoadCreditRatingsResponse } from '@common/model/LoadCreditRatingsResponse';
import { RouteDirections, RouteInfo } from '@common/model/PCMiler';
import { SharedLoadMessage } from '@common/model/SharedLoadMessage';
import { EmptyResponse, SearchResponseMetadata } from '@common/redux/Base';
import {
  AlertsResponse,
  SearchesResponse,
  UpdateSearchAlertRequest,
  ViewFilter,
} from '@common/redux/epic/loadSearch/HelperFunctions';

export class LoadsClient extends BaseClient {
  private readonly isLiveEnvironment: boolean;
  constructor(api: Api, isLiveEnvironment: boolean) {
    super(api);
    this.isLiveEnvironment = isLiveEnvironment;
  }
  fetchLoadCount$ = (request: LoadSearchRequest) => {
    return this.api.post$<SearchResponseMetadata>('/loads/search/metadata', {
      ...request,
    });
  };

  fetchLoads$ = (companyName?: string) =>
    this.api.get$<Load[]>('/loads', { onlineOnly: true, companyName: companyName }, withApiVersion('1.2'));
  fetchLoadDetails$ = (id: string, archivingFlowID: string | undefined, fields: string, onlineOnly: boolean = true) =>
    this.api.get$<Load>(
      `/loads/${id}`,
      { fields: fields, onlineOnly: onlineOnly },
      addArchivingHeader(archivingFlowID)
    );
  fetchLoadCargoChiefRate$ = (id: string, archivingFlowID: string | undefined) =>
    this.api.get$<CargoChiefRate>(`/loads/${id}/c4rates`, {}, addArchivingHeader(archivingFlowID));
  fetchLoadGreenscreensRate$ = (id: string, archivingFlowID: string | undefined) =>
    this.api.get$<GreenscreensRate>(`/loads/${id}/greenscreensrates`, {}, addArchivingHeader(archivingFlowID));
  fetchSharedLoad$ = (token: string, isPoster?: boolean) =>
    this.api.get$<Load>(
      `/loads/shared/${isPoster ? 'poster/' : ''}${token}`,
      {},
      {},
      authenticationToApiOptions(false)
    );
  fetchPostedLoadsList$ = (fields: string) =>
    this.api.get$('/loads/list', { fields: fields, onlineOnly: true, limit: 0 });
  postAlertNotification$ = (loadId: string, alertNotification: AlertNotification) => {
    if (!this.isLiveEnvironment) {
      return this.api.post$(`/loads/${loadId}/alertdevice`, { ...alertNotification, sendToSelf: true });
    }
    return this.api.post$(`/loads/${loadId}/alertdevice`, alertNotification);
  };

  searchLoads$ = (
    request: LoadSearchRequest,
    shouldSilenceFailures = false,
    archivingFlowId: string | undefined,
    cancelGroup?: string,
    referrer?: string
  ) =>
    this.api.post$<LoadSearchResponse>(
      'loads/search',
      intermediateRequest(request),
      addArchivingHeader(
        archivingFlowId,
        referrer
          ? {
              headers: {
                [LBHeaders.Referrer]: referrer,
              },
            }
          : undefined
      ),
      {
        silentApiFailure: shouldSilenceFailures,
        silentNetworkFailure: shouldSilenceFailures,
        cancelGroup: cancelGroup,
      }
    );
  searchLoadsById$ = (
    id: string,
    request: { metadata: LoadSearchMetadata },
    shouldSilenceFailures = false,
    archivingFlowId: string | undefined
  ) =>
    this.api.post$<LoadSearchResponse>(
      `/loads/named-searches/${id}/search`,
      request.metadata,
      addArchivingHeader(archivingFlowId),
      {
        silentApiFailure: shouldSilenceFailures,
        silentNetworkFailure: shouldSilenceFailures,
      }
    );
  searchGeoLoads$ = (request: LoadSearchRequest, archivingFlowId: string | undefined) =>
    this.api.post$<LoadSearchGeoResponse>(
      'loads/geosearch',
      intermediateRequest(request),
      addArchivingHeader(archivingFlowId)
    );
  searchGeoLoadsById$ = (id: string, request: { metadata: LoadSearchMetadata }, archivingFlowId: string | undefined) =>
    this.api.post$<LoadSearchGeoResponse>(
      `/loads/named-searches/${id}/geosearch`,
      request.metadata,
      addArchivingHeader(archivingFlowId)
    );
  searchSimilarLoads$ = (request: LoadSearchRequest, archivingFlowId: string | undefined) =>
    this.api.post$<SimilarLoadSearchResponse>(
      'loads/search/similar',
      intermediateRequest(request),
      addArchivingHeader(archivingFlowId)
    );

  fetchLoadBackhauls$ = (loadId: string, namedSearchId: string | undefined, archivingFlowID: string | undefined) =>
    this.api.get$<LoadBackhauls>(
      `/loads/${loadId}/backhauls`,
      { fromNamedSearchId: namedSearchId },
      addArchivingHeader(archivingFlowID)
    );
  fetchRouteInfo$ = (loadId: string, archivingFlowID: string | undefined) =>
    this.api.get$<RouteInfo>(`/loads/${loadId}/routeinfo`, {}, addArchivingHeader(archivingFlowID));
  fetchRouteDirections$ = (loadId: string, archivingFlowID: string | undefined) =>
    this.api.get$<RouteDirections>(`/loads/${loadId}/routedirections`, {}, addArchivingHeader(archivingFlowID));
  getRouteStateMileage$ = (loadId: string, archivingFlowID: string | undefined) =>
    this.api.get$<StateMileagePayload>(`/loads/${loadId}/routestatemileage`, {}, addArchivingHeader(archivingFlowID));
  fetchRouteMap$ = (
    loadId: string,
    thumbnail: boolean,
    aspectRatio = AspectRatio.Ratio2_1,
    archivingFlowID: string | undefined
  ) =>
    this.api.get$<string>(
      `/loads/${loadId}/routemap`,
      { thumbnail: thumbnail, aspectRatio: aspectRatio },
      addArchivingHeader(archivingFlowID, { responseType: 'arraybuffer' })
    );
  fetchCreditRatings$ = (loadId: string, archivingFlowID: string | undefined) =>
    this.api.get$<LoadCreditRatingsResponse>(`/loads/${loadId}/creditratings`, {}, addArchivingHeader(archivingFlowID));
  fetchRateCheck$ = (loadId: string, archivingFlowID: string | undefined) =>
    this.api.get$<RateCheck>(`/loads/${loadId}/ratecheck`, {}, addArchivingHeader(archivingFlowID));
  fetchLoadRateCheckPreviews$ = (loadIds: string[], archivingFlowID: string | undefined) =>
    this.api.post$<LoadRateCheckPreviewResponse>(`/loads/ratecheck`, loadIds, addArchivingHeader(archivingFlowID));
  fetchFuelPrice$ = (state: string, country: string | undefined, archivingFlowID: string | undefined) =>
    this.api.get$<FuelPrice>('/fuelPrice', { state: state, country: country }, addArchivingHeader(archivingFlowID));
  setSavedLoad$ = (loadId: string, isSaved: boolean, archivingFlowID: string | undefined) => {
    return this.api.patch$<EmptyResponse>(
      `/loads/${loadId}/userdata`,
      [
        {
          op: 'replace',
          path: '/isSaved',
          value: isSaved,
        },
      ],
      addArchivingHeader(archivingFlowID)
    );
  };
  setHiddenLoad$ = (loadId: string, archivingFlowID: string | undefined, isHidden: boolean = true) => {
    return this.api.patch$<EmptyResponse>(
      `/loads/${loadId}/userdata`,
      [
        {
          op: 'replace',
          path: '/isHidden',
          value: isHidden,
        },
      ],
      addArchivingHeader(archivingFlowID)
    );
  };
  setLoadNote$ = (loadID: string, note: string, archivingFlowID: string | undefined) => {
    return this.api.patch$<EmptyResponse>(
      `/loads/${loadID}/userdata`,
      [
        {
          op: 'replace',
          path: '/note',
          value: note,
        },
      ],
      addArchivingHeader(archivingFlowID)
    );
  };
  setPrivateLoadNote$ = (loadID: string, privateLoadNote: string, archivingFlowID: string | undefined) => {
    return this.api.patch$<EmptyResponse>(
      `/loads/${loadID}/userdata`,
      [
        {
          op: 'replace',
          path: '/privateLoadNote',
          value: privateLoadNote,
        },
      ],
      addArchivingHeader(archivingFlowID)
    );
  };
  setLoadProgress$ = (loadID: string, progress: LoadProgress, archivingFlowID: string | undefined) => {
    return this.api.patch$<EmptyResponse>(
      `/loads/${loadID}/userdata`,
      [
        {
          op: 'replace',
          path: '/progress',
          value: progress,
        },
        {
          op: 'replace',
          path: '/isSaved',
          value: false,
        },
      ],
      addArchivingHeader(archivingFlowID)
    );
  };
  setLoadCall$ = (loadID: string, isCalled: boolean, archivingFlowID: string | undefined) => {
    return this.api.patch$<EmptyResponse>(
      `/loads/${loadID}/userdata`,
      [
        {
          op: 'replace',
          path: '/isCalled',
          value: isCalled,
        },
      ],
      addArchivingHeader(archivingFlowID)
    );
  };
  getSharedLoadUrl$ = (loadID: string, archivingFlowID: string | undefined) => {
    return this.api.get$<SharedLoadMessage>(`/loads/${loadID}/share`, {}, addArchivingHeader(archivingFlowID));
  };

  fetchLoadAvailability$ = (equipmentTypes: EquipmentType[], sortCategory: LoadAvailabilitySortCategory) =>
    this.api.post$<StateLoadAvailabilityResponse>('/loads/availability/search', {
      metadata: {
        includeQueries: true,
      },
      equipmentTypes: join(equipmentTypes, ','),
      statesToInclude: getStatesFromSortCategory(sortCategory),
      sortExpression: 'None',
      sortDirection: LoadSortDirection.Desc,
    });

  searchNearbyLoads$ = (request: NearbyLoadsSearchRequest) =>
    this.api.post$<NearbyLoadsResponse>('/loads/search/nearby', request);

  sendEmailToBroker$ = (loadID: string, comment: string) =>
    this.api.post$<EmptyResponse>(`/loads/${loadID}/sendEmailtoBroker`, { comment: comment });

  getStateMileage$ = (loadID: string, archivingFlowID: string | undefined) =>
    this.api.get$<StateMileagePayload>(`/loads/${loadID}/routestatemileage`, {}, addArchivingHeader(archivingFlowID));

  sendBookNow$ = (loadID: string) => this.api.post$<BookNowResponse>(`/loads/${loadID}/book-now`, {});

  fetchAllSearches$ = (filter?: ViewFilter) =>
    this.api.get$<SearchesResponse>(`/loads/named-searches`, {
      hasAlerts: filter === ViewFilter.ALL ? undefined : filter === ViewFilter.WITH_ALERTS,
    });

  deleteAllSearches$ = (filter?: ViewFilter) =>
    this.api.delete$<{}>('/loads/named-searches', deleteSearchesQueryFromFilter(filter));

  createSearch$ = (request: NamedSearchRequest) =>
    this.api.post$<PersistentLoadSearchDef>('/loads/named-searches', request);

  fetchSearchByID$ = (namedSearchId: string) =>
    this.api.get$<PersistentLoadSearchDef>(`/loads/named-searches/${namedSearchId}`, {});

  editSearchRequest$ = (namedSearchId: string, request: NamedSearchRequest) =>
    this.api.put$<PersistentLoadSearchDef>(`/loads/named-searches/${namedSearchId}`, request);

  deleteSearch$ = (id: string) => this.api.delete$<{}>(`/loads/named-searches/${id}`, {});

  updateSearch$ = (request: UpdateSearchAlertRequest) =>
    this.api.mergePatch$<{}>(`/loads/named-searches/${request.namedSearchId}`, {
      hasAlert: request.hasAlert,
    });

  getSearchAlerts$ = () => this.api.get$<AlertsResponse>('/loads/named-searches/alerts', {});
}

const deleteSearchesQueryFromFilter = (filter?: ViewFilter) => {
  switch (filter) {
    case ViewFilter.WITH_ALERTS:
      return {
        onlyWithAlerts: true,
        onlyWithoutAlerts: undefined,
      };
    case ViewFilter.WITHOUT_ALERTS:
      return {
        onlyWithAlerts: undefined,
        onlyWithoutAlerts: true,
      };
    case ViewFilter.ALL:
    default:
      return {
        onlyWithAlerts: false,
        onlyWithoutAlerts: undefined,
      };
  }
};

import React from 'react';

import createBugsnagClient from 'bugsnag-js';
import Report from 'bugsnag-js/types/report';
import createPlugin from 'bugsnag-react';

import { ApplicationError } from '@common/model';
import { AppConfig } from '@util/AppConfig';

const bugsnagClient = createBugsnagClient({
  apiKey: AppConfig.BUGSNAG_API_KEY,
  appVersion: AppConfig.getAppVersion() + '-' + AppConfig.LBConfig.lbEnvironment,
  beforeSend: (report: Report) => {
    // Example: Disable reporting of errors with a given error message
    const windowError: { error?: ApplicationError } = (report.metaData as any)['window onerror'];
    //when running as development we don't want those errors to be reported.
    if (
      process.env.NODE_ENV === 'development' ||
      (windowError && windowError.error && windowError.error.shouldReport === false)
    ) {
      report.ignore();
    }
  },
  releaseStage: AppConfig.LBConfig.lbEnvironment,
});

//----------------------------------------
// Bootstrapping the Application
//----------------------------------------
export const BugsnagErrorBoundary = bugsnagClient.use(createPlugin(React));

import React, { useRef } from 'react';

import { generatePath, Route, RouteComponentProps, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import { NotificationCount } from '@component/menu/menuStyles';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelSize } from '@component/panel';
import { Tab, Tabs } from '@component/tabs';
import BidsPanel from '@page/bids/BidsPanel';
import { BidsRouteParamsProps, BidsTabs } from '@page/bids/BidsPanelRouting';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

import { BidsPanelHeader } from './BidsPanelHeader';

enum TabNames {
  Bids,
  InteractiveBids,
}

const UnreadCounter = styled(NotificationCount)`
  position: absolute;
  height: 15px;
  font-size: 10px;
  line-height: 15px;
  min-width: 18px;
  padding: 0 4px;
  width: auto;
  box-sizing: border-box;
  right: -2px;
  transform: translate(100%, -100%);
  top: 8px;
  margin: 0;
`;

const TabLabel = styled.span`
  position: relative;
`;

const TabItem = styled(Tab)`
  flex: 1;
`;

interface Props {
  layer: number;
  isStandalone: boolean;
}

const a11yProps = (index: number) => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

const BidsTabsPanel: React.FC<Props & RouteComponentProps> = (props) => {
  const unreadBidsCount = useSelector((state) => state.bids.summary.carrierUnreadBids);

  const panelSize = PanelSize.small;
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const onTabClick = (tabName: TabNames) => () => {
    redirectTab(tabName);
  };

  const redirectTab = (tabName: TabNames): void => {
    switch (tabName) {
      case TabNames.Bids:
        props.history.replace({
          pathname: `${generatePath(Routes.BIDS, { tab: 'bids' })}`,
        });
        break;
      case TabNames.InteractiveBids:
        props.history.replace({
          pathname: `${generatePath(Routes.BIDS, { tab: 'interactive-bids' })}`,
        });
        break;
    }
  };

  return (
    <>
      <Panel
        id="bids"
        size={panelSize}
        layer={props.layer}
        offset={0}
        scrollRef={scrollContainerRef}
        backgroundColor={PANEL_SHADOW_BACKGROUND}
      >
        <BidsPanelHeader
          isStandalone={props.isStandalone}
          subheader={
            <Tabs value={getCurrentTab()} indicatorColor="primary" textColor="primary" variant="fullWidth">
              <TabItem
                label={<TabLabel>{t(T.common_bids_title)}</TabLabel>}
                {...a11yProps(TabNames.Bids)}
                onClick={onTabClick(TabNames.Bids)}
              />
              <TabItem
                label={
                  <TabLabel>
                    {t(T.common_bids_titleInteractive)}
                    {unreadBidsCount > 0 ? (
                      <UnreadCounter opened={true} hasNotifications={true}>
                        <span>{unreadBidsCount}</span>
                      </UnreadCounter>
                    ) : null}
                  </TabLabel>
                }
                {...a11yProps(TabNames.InteractiveBids)}
                onClick={onTabClick(TabNames.InteractiveBids)}
              />
            </Tabs>
          }
        />
        <Switch>
          <Route path={`/bids/${BidsTabs.Bids}/`}>
            <BidsPanel isInteractive={getCurrentTab() === TabNames.InteractiveBids} scrollRef={scrollContainerRef} />
          </Route>
          <Route path={`/bids/${BidsTabs.InteractiveBids}/`}>
            <BidsPanel isInteractive={getCurrentTab() === TabNames.InteractiveBids} scrollRef={scrollContainerRef} />
          </Route>
        </Switch>
      </Panel>
    </>
  );
};

export default withRouter(BidsTabsPanel);

const getCurrentTab = (): TabNames => {
  const match = useRouteMatch<BidsRouteParamsProps>();
  switch (match?.params.tab) {
    case BidsTabs.InteractiveBids:
      return TabNames.InteractiveBids;
    case BidsTabs.Bids:
      return TabNames.Bids;
    default:
      return TabNames.Bids;
  }
};

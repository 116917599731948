import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { LoadsClient } from '@common/client';
import { isLocationCountryOrAnywhere, withSorting } from '@common/helper';
import { LoadSearchRequest } from '@common/model';
import { createAction } from '@common/redux/Base';
import { createLoadSearch, SearchReducerKey } from '@common/redux/epic/loadSearch/LoadSearchShared';
import { createMergedReducer } from '@common/redux/ReduxHelper';

import { getSortByMatchingCompanySearchOrigin } from './CompanySearchHelper';
import { initialState, LoadSearchBaseState } from './HelperFunctions';

export type CompanySearchState = LoadSearchBaseState;

export const COMPANY_SEARCH_REDUCER_KEY = SearchReducerKey.COMPANY_SEARCH;
const companySearch = createLoadSearch(COMPANY_SEARCH_REDUCER_KEY);

export const fetchLoadCount = companySearch.actions.fetchLoadCount;
export const searchLoads = (searchData?: LoadSearchRequest) => companySearch.actions.searchLoads(searchData);
export const searchLoadsById = companySearch.actions.searchLoadsById;
export const setSelectedSearch = companySearch.actions.setSelectedSearch;
export const setSortBy = companySearch.actions.setSortBy;
export const setSearchFilterDraft = companySearch.actions.setSearchFilterDraft;
export const nextSearchLoads = companySearch.actions.nextSearchLoads;
export const updateCompanySearchLoads = companySearch.actions.updateLoads;
export const refreshCompanySearchLoadCount = companySearch.actions.refreshLoadCount;
export const performAutoRefresh = companySearch.actions.performAutoRefresh;

const updateLastSearchAction = createAction<LoadSearchRequest>('UPDATE_LAST_COMPANY_SEARCH');
const updateListSearchAction = createAction<LoadSearchRequest>('UPDATE_LIST_COMPANY_SEARCH');

export const updateLastSearch = updateLastSearchAction.action;
export const updateListSearch = updateListSearchAction.action;

export const companySearchReducer = createMergedReducer(
  initialState,
  [
    updateLastSearchAction.addCase((state, action) => {
      const sortBy = getSortByMatchingCompanySearchOrigin(action.data.origin);
      const newLastSearch = withSorting(action.data, sortBy);
      if (!isLocationCountryOrAnywhere(action.data.destination)) {
        newLastSearch.minMileage = undefined;
        newLastSearch.maxMileage = undefined;
      }
      state.lastSearchRequest = newLastSearch;
      state.listSearchRequest = newLastSearch;
    }),
    updateListSearchAction.addCase((state, action) => {
      state.listSearchRequest = action.data;
    }),
  ],
  (state: CompanySearchState, action) => {
    const newState = companySearch.reducer(state, action);
    if (newState) {
      return { ...state, ...newState };
    }
    return state;
  }
);

export const createCompanySearchEpic = (api: Api, isLiveEnvironment: boolean) => {
  const loadsClient = new LoadsClient(api, isLiveEnvironment);
  return (
    action$: ActionsObservable<Action>,
    state$: StateObservable<{ [COMPANY_SEARCH_REDUCER_KEY]: CompanySearchState }>
  ) => merge$(companySearch.createMergedEpic$(loadsClient, action$, state$));
};

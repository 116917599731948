import React, { useMemo } from 'react';

import { find, map, toNumber } from 'lodash';

import { formatNumberOrDash } from '@common/helper';
import { SelectElement } from '@component/select/Select';
import { T, t } from '@translate';

export const DEFAULT_RADIUS = 150;
export const RADIUS_OPTIONS = [10, 50, 100, 150, 200, 250, 500, 1000];
export const RADIUS_OPTIONS_EXTENDED = [1, ...RADIUS_OPTIONS];

interface Props {
  id: string;
  options: number[];
  radius: number | undefined;
  defaultRadius?: number;
  onChange: (value: string) => void;
}

export const RadiusSelect: React.FC<Props> = (props) => {
  const formattedOptions = useMemo(() => map(props.options, (r: number) => formatNumberOrDash(r)), [props.options]);
  const radius = props.radius ? toNumber(props.radius) : props.radius;
  const initialRadius = props.defaultRadius ?? DEFAULT_RADIUS;
  return (
    <SelectElement
      id={props.id}
      options={formattedOptions}
      label={t(T.findLoads_findLoads_radius)}
      value={formatNumberOrDash(closestOrDefault(radius, props.options, initialRadius))}
      onChange={props.onChange}
    />
  );
};

const closestOrDefault = (radius: number | undefined, options: number[], defaultRadius: number): number => {
  if (!radius || radius < 1) {
    return defaultRadius;
  }
  return find(options, (r: number) => radius <= r) || defaultRadius;
};

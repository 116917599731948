import { isEmpty, replace, trim } from 'lodash';
import moment from 'moment';

import { removeComma } from '@common/formatter/Number';
import { isNumber } from '@common/helper/NumberHelper';

export const isStringValid = (value?: string): boolean => value !== undefined && trim(value).length > 0;

//@TODO: Check if escape is necessary.
export const isEmailValid = (email: string | undefined): boolean =>
  email !== undefined &&
  /^[_A-Za-z0-9-\+]+(?:\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(?:\.[A-Za-z0-9]+)*(?:\.[A-Za-z]{2,})$/.test(email); // eslint-disable-line no-useless-escape

export const isPhoneValid = (phone: string, required: boolean = true): boolean => {
  if (!required && (phone === '' || phone === undefined)) {
    return true;
  }
  if (phone === undefined) {
    return false;
  }

  let numbers = cleanPhoneNumber(phone);

  if (numbers.length > 0 && numbers.charAt(0) === '1') {
    numbers = numbers.substring(1);
  }

  return numbers.length === 10;
};

export const cleanPhoneNumber = (phone: string): string => {
  if (phone) {
    return phone.replace(/[^0-9]/g, '');
  } else {
    return '';
  }
};

export const isNumberFieldValid = (value: string): boolean =>
  isEmpty(value) || (isNumber(removeComma(value)) && Number(removeComma(value)) >= 0);

export const shouldDistanceChange = (distance: string): boolean =>
  isNumberFieldValid(distance) && Number(removeComma(distance)) <= 99999;

export const shouldRateChange = (rate: string): boolean =>
  isNumberFieldValid(rate) && Number(removeComma(rate)) <= 99999;
export const shouldCostPerMileChange = (cost: string): boolean =>
  isNumberFieldValid(cost) && Number(removeComma(cost)) <= 99.99;

export const isTimeFieldValid = (value: string): boolean => {
  if (isEmpty(value)) {
    return true;
  }
  return moment(value, ['HH:mm'], true).isValid();
};

export const isPhoneFieldValid = (phone: string, required: boolean): boolean =>
  /^\d+$/.test(phone) && isPhoneValid(phone, required);

/** partial means: as the user enters the time, we validate the data so far
 */
export const isPartialTimeFieldValid = (value: string) => {
  if (isEmpty(value)) {
    return true;
  }
  return moment(value, ['h:m', 'h', 'h:', ':m'], true).isValid();
};

/**
 * A USDOT# can currently be up to 8 digits, however USDOT numbers are issued sequentially, so this maximum will increase.
 * A USDOT# has a minimum of value of 100. However, any USDOT# can have any number of 0's in front of it, provided it doesn't breach the (current) 8 digit maximum.
 * We suggest not auto-formatting to add 0's, as some carriers include them and some don't. However, the broker should be able to find
 * USDOT numbers with 0 prefixes regardless of whether or not the carrier has included them and vice-versa.
 * @param maybeNumber
 */
export const isValidUsDotNumber = (maybeNumber: string) => {
  const number = parseInt(maybeNumber, 10);
  if (!isFinite(number) || number > 99999999 || number < 100) {
    return false;
  }
  return true;
};

export const parseFloatFromString = (value: string): number => parseFloat(replace(value, /,/g, ''));

import React from 'react';
import styled from 'styled-components';

import { HeaderButton } from '@component/panel';
import { CustomPaper } from '@component/paper';
import { Collapse } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { t, T } from '@translate';

import { default as CloseIcon } from './close.svg';

interface Option {
  title: string;
  icon: string;
  onClick: () => void;
}

const HeaderContainer = withTheme()(styled.div`
  flex-direction: row;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 6px -12px 6px -12px;
  padding-bottom: 12px;
  border-bottom: ${(props: ThemeProps) => `1px solid ${props.theme.palette.background.smoke}`};
`);

const RowsContainer = styled.div`
  margin-top: 12px;
`;

const AttachmentsSectionItem = styled<ButtonProps>(({ ...rest }) => <Button {...rest} />)`
  && {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    height: 36px;
    padding: 6px 0;
    margin-top: 4px;
    margin-bottom: 1px;
    background-color: ${Color.WHITE};
    text-transform: none;
    border-radius: 0;
  }
`;

const AttachmentsSectionTitle = styled.span`
  font-size: ${FontSize.ModalTitle}px;
  font-weight: Bold;
  color: ${Color.GRAY_DARK};
  padding-left: 8px;
`;

const AttachmentsSectionItemName = styled.span`
  font-size: ${FontSize.Content}px;
  font-weight: Regular;
  color: ${Color.GRAY_DARK};
`;

export const AttachmentsBottomMenu: React.FC<{
  handleAttachmentMenu: (isOpen: boolean) => void;
  openAddNewDocument: () => void;
  openMyDocuments: () => void;
  menuTitle: string;
  isAttachmentsMenuOpen: boolean;
  menuOptions: Option[];
}> = (props) => {
  return (
    <Collapse in={props.isAttachmentsMenuOpen}>
      <CustomPaper
        id="menu_attachments"
        allowCollapsedMargins={true}
        elevation={3}
        style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
      >
        <HeaderContainer>
          <HeaderButton
            id="close"
            onClick={() => {
              props.handleAttachmentMenu(false);
            }}
          >
            <img src={CloseIcon} alt={t(T.findLoads_loads_close)} />
          </HeaderButton>
          <AttachmentsSectionTitle>{props.menuTitle}</AttachmentsSectionTitle>
        </HeaderContainer>
        <RowsContainer>
          {props.menuOptions.map((item) => (
            <AttachmentsSectionItem id="attachment_sections" key={item.title} onClick={item.onClick}>
              <img src={item.icon} alt={item.title} style={{ paddingRight: 8, alignSelf: 'center' }} />
              <AttachmentsSectionItemName>{item.title}</AttachmentsSectionItemName>
            </AttachmentsSectionItem>
          ))}
        </RowsContainer>
      </CustomPaper>
    </Collapse>
  );
};

import React from 'react';

import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { SnackbarOrigin } from '@material-ui/core/es/Snackbar';

import { dismissSnackbar, SnackbarState } from '@/reduxStore/reducer/SnackbarReducer';
import { StoreState } from '@/reduxStore/Store';

import SnackbarContent from './SnackbarContent';
import { SnackBarWrapper } from './SnackbarContentStyle';

interface DispatchProps {
  dismissSnackbar: () => void;
}

interface ReduxProps {
  snackbar: SnackbarState;
}
type Props = DispatchProps & ReduxProps;
class Snackbar extends React.Component<Props> {
  handleCloseSnackbar = () => {
    if (this.props.snackbar.dismissSnackbar) {
      this.props.snackbar.dismissSnackbar();
    }
    this.props.dismissSnackbar();
  };

  render() {
    const {
      position = { vertical: 'bottom', horizontal: 'right' },
      message,
      variant = 'large',
      open,
      hideDuration,
    } = this.props.snackbar;

    return (
      <SnackBarWrapper
        open={open}
        anchorOrigin={position as SnackbarOrigin}
        autoHideDuration={hideDuration}
        onClose={this.handleCloseSnackbar}
      >
        <SnackbarContent message={message} variant={variant} onClose={this.handleCloseSnackbar} />
      </SnackBarWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      dismissSnackbar: dismissSnackbar,
    },
    dispatch
  );

const mapStateToProps = (state: StoreState): ReduxProps => {
  return { snackbar: state.snackbarState };
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);

(function(global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined'
    ? factory(exports)
    : typeof define === 'function' && define.amd
    ? define('leafletGestureHandling', ['exports'], factory)
    : factory(((global || window).leafletGestureHandling = {}));
})(this, function(exports) {
  'use strict';

  function hasParent(element, parentSelector) {
    var potentialParents = document.querySelectorAll(parentSelector);
    if (!potentialParents) return false;
    for (var i = 0; i < potentialParents.length; i++) {
      if (potentialParents[i].contains(element)) return true;
    }
    return false;
  }

  var LanguageContent = {
    //English
    en: {
      touch: 'Use two fingers to move the map',
      scroll: 'Use ctrl + scroll to zoom the map',
      scrollMac: 'Use \u2318 + scroll to zoom the map',
    },
  };

  function supportsPassive() {
    //detect if browser support passive events
    // (from https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md)
    var supportsPassive = false;
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: function() {
          supportsPassive = true;
        },
      });
      window.addEventListener('testPassive', null, opts);
      window.removeEventListener('testPassive', null, opts);
    } catch (e) {}
    return supportsPassive;
  }

  /*
   * * Leaflet Gesture Handling **
   * * Version 1.1.5
   */

  L.Map.mergeOptions({
    gestureHandlingOptions: {
      text: {},
      duration: 1000,
    },
  });

  var draggingMap = false;

  var GestureHandling = L.Handler.extend({
    addHooks: function() {
      this._handleTouch = this._handleTouch.bind(this);

      this._setupPluginOptions();
      this._setLanguageContent();
      this._disableInteractions();

      //Uses native event listeners instead of L.DomEvent due to issues with Android touch events
      //turning into pointer events
      var passive = supportsPassive() ? { passive: true } : false;
      this._map._container.addEventListener('touchstart', this._handleTouch, passive);
      this._map._container.addEventListener('touchmove', this._handleTouch, passive);
      this._map._container.addEventListener('touchend', this._handleTouch, passive);
      this._map._container.addEventListener('click', this._handleTouch);

      L.DomEvent.on(this._map._container, 'keydown', this._handleKeyDown, this);
      L.DomEvent.on(this._map._container, 'keyup', this._handleKeyUp, this);
      L.DomEvent.on(this._map._container, 'mousewheel', this._handleScroll, this);
      L.DomEvent.on(this._map, 'mouseover', this._handleMouseOver, this);
      L.DomEvent.on(this._map, 'mouseout', this._handleMouseOut, this);

      // Listen to these events so will not disable dragging if the user moves the mouse out the boundary of the map container whilst actively dragging the map.
      L.DomEvent.on(this._map, 'movestart', this._handleDragging, this);
      L.DomEvent.on(this._map, 'move', this._handleDragging, this);
      L.DomEvent.on(this._map, 'moveend', this._handleDragging, this);
    },

    removeHooks: function() {
      this._enableInteractions();

      this._map._container.removeEventListener('touchstart', this._handleTouch);
      this._map._container.removeEventListener('touchmove', this._handleTouch);
      this._map._container.removeEventListener('touchend', this._handleTouch);
      this._map._container.removeEventListener('click', this._handleTouch);

      L.DomEvent.off(this._map._container, 'keydown', this._handleKeyDown, this);
      L.DomEvent.off(this._map._container, 'keyup', this._handleKeyUp, this);
      L.DomEvent.off(this._map._container, 'mousewheel', this._handleScroll, this);
      L.DomEvent.off(this._map, 'mouseover', this._handleMouseOver, this);
      L.DomEvent.off(this._map, 'mouseout', this._handleMouseOut, this);

      L.DomEvent.off(this._map, 'movestart', this._handleDragging, this);
      L.DomEvent.off(this._map, 'move', this._handleDragging, this);
      L.DomEvent.off(this._map, 'moveend', this._handleDragging, this);
    },

    _handleDragging: function(e) {
      if (e.type == 'movestart' || e.type == 'move') {
        draggingMap = true;
      } else if (e.type == 'moveend') {
        draggingMap = false;
      }
    },

    _disableInteractions: function() {
      this._map.dragging.disable();
      this._map.scrollWheelZoom.disable();
      if (this._map.tap) {
        this._map.tap.disable();
      }
    },

    _enableInteractions: function() {
      this._map.dragging.enable();
      if (this._map.tap) {
        this._map.tap.enable();
      }
    },

    _setupPluginOptions: function() {
      //For backwards compatibility, merge gestureHandlingText into the new options object
      if (this._map.options.gestureHandlingText) {
        this._map.options.gestureHandlingOptions.text = this._map.options.gestureHandlingText;
      }
    },

    _setLanguageContent: function() {
      // use English
      let languageContent = LanguageContent['en'];

      //Check if they're on a mac for display of command instead of ctrl
      var mac = false;
      if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
        mac = true;
      }

      var scrollContent = languageContent.scroll;
      if (mac) {
        scrollContent = languageContent.scrollMac;
      }

      this._map._container.setAttribute('data-gesture-handling-touch-content', languageContent.touch);
      this._map._container.setAttribute('data-gesture-handling-scroll-content', scrollContent);
    },

    _getUserLanguage: function() {
      var lang = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
      return lang;
    },

    _handleTouch: function(e) {
      //Disregard touch events on the minimap if present
      var ignoreList = [
        'leaflet-control-minimap',
        'leaflet-interactive',
        'leaflet-popup-content',
        'leaflet-popup-content-wrapper',
        'leaflet-popup-close-button',
        'leaflet-control-zoom-in',
        'leaflet-control-zoom-out',
      ];

      var ignoreElement = false;
      for (var i = 0; i < ignoreList.length; i++) {
        if (e.target.classList.contains(ignoreList[i])) {
          ignoreElement = true;
        }
      }

      if (!ignoreElement) {
        if (hasParent(e.target, '.leaflet-interactive,.leaflet-popup')) {
          ignoreElement = true;
        }
      }

      if (ignoreElement) {
        if (e.target.classList.contains('leaflet-interactive') && e.type === 'touchmove' && e.touches.length === 1) {
          this._map._container.classList.add('leaflet-gesture-handling-touch-warning');
          this._disableInteractions();
        } else {
          this._map._container.classList.remove('leaflet-gesture-handling-touch-warning');
        }
        return;
      }
      // screenLog(e.type+' '+e.touches.length);

      if ((e.type === 'touchmove' || e.type === 'touchstart') && e.touches.length === 1) {
        e.currentTarget.classList.add('leaflet-gesture-handling-touch-warning');
        this._disableInteractions();
      } else {
        e.target.classList.remove('leaflet-gesture-handling-touch-warning');
      }
    },

    _isScrolling: false,

    _handleKeyDown: function(e) {
      if (e.which === 17 || e.metaKey) {
        this._map.scrollWheelZoom.enable();
      }
    },

    _handleKeyUp: function(e) {
      if (e.metaKey || e.which === 17) {
        this._map.scrollWheelZoom.disable();
      }
    },

    _handleScroll: function(e) {
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        this._map._container.classList.remove('leaflet-gesture-handling-scroll-warning');
        this._map.scrollWheelZoom.enable();
      } else {
        this._map._container.classList.add('leaflet-gesture-handling-scroll-warning');
        this._map.scrollWheelZoom.disable();

        clearTimeout(this._isScrolling);

        // Set a timeout to run after scrolling ends
        this._isScrolling = setTimeout(function() {
          // Run the callback
          var warnings = document.getElementsByClassName('leaflet-gesture-handling-scroll-warning');
          for (var i = 0; i < warnings.length; i++) {
            warnings[i].classList.remove('leaflet-gesture-handling-scroll-warning');
          }
        }, this._map.options.gestureHandlingOptions.duration);
      }
    },

    _handleMouseOver: function(e) {
      this._enableInteractions();
    },

    _handleMouseOut: function(e) {
      if (!draggingMap) {
        this._disableInteractions();
      }
    },

    _isScrolling: false,
  });

  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

  exports.GestureHandling = GestureHandling;
  exports.default = GestureHandling;

  Object.defineProperty(exports, '__esModule', { value: true });
});

import { Phone } from './Phone';
import { ResponseListMetadata } from './ResponseListMetadata';

export interface Quote {
  id: string;
  date: string;
  metadata: QuoteResponseMetadata;
}

export const MISSING_PHONENUMBER = 'PhoneNumber';

export interface QuoteResponseMetadata {
  missingInfo?: string[];
}
export interface QuoteRequest {
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  phone?: Phone;
  loadId?: string;
}

export interface QuoteResult {
  quoteResponses?: Quote[];
  metadata: ResponseListMetadata;
}

export enum QuoteType {
  Factoring = 'Factoring',
  FuelAdvance = 'FuelAdvance',
  FuelCard = 'FuelCard',
  RoadsideAssist = 'RoadsideAssist',
  TruckingAuthority = 'TruckingAuthority',
}

export interface QuoteForLoad {
  type: QuoteType;
  id?: string;
  date?: string;
}

export interface QuoteListResponse {
  quotes: QuoteForLoad[];
}

import { sortBy } from 'lodash';

import { LOCATION_ALL, LocationType } from './';

export type AmericanStates =
  | 'AK'
  | 'AL'
  | 'AR'
  | 'AZ'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'IA'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'MA'
  | 'MD'
  | 'ME'
  | 'MI'
  | 'MN'
  | 'MO'
  | 'MS'
  | 'MT'
  | 'NC'
  | 'ND'
  | 'NE'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NV'
  | 'NY'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VA'
  | 'VT'
  | 'WA'
  | 'WI'
  | 'WV'
  | 'WY';

export type CanadianStates = 'AB' | 'BC' | 'MB' | 'NB' | 'NL' | 'NS' | 'NT' | 'NU' | 'ON' | 'PE' | 'QC' | 'SK' | 'YT';

export type AllStates = AmericanStates | CanadianStates;
export type AllStatesType = AmericanStates[] | CanadianStates[] | typeof LOCATION_ALL | typeof LocationType.NONE;

export interface States {
  canadaStates: CanadianStates[] | typeof LOCATION_ALL | typeof LocationType.NONE;
  usStates: AmericanStates[] | typeof LOCATION_ALL | typeof LocationType.NONE;
}

export const WEST: AmericanStates[] = ['AZ', 'CA', 'ID', 'NV', 'OR', 'UT', 'WA'];
export const SOUTH: AmericanStates[] = ['AL', 'FL', 'GA', 'LA', 'MS', 'NC', 'SC', 'TN', 'VA'];
export const MIDWEST: AmericanStates[] = ['IL', 'IN', 'IA', 'KY', 'MI', 'MN', 'MO', 'OH', 'WI', 'WV'];
export const NORTHEAST: AmericanStates[] = ['CT', 'DC', 'DE', 'MA', 'MD', 'ME', 'NH', 'NJ', 'NY', 'PA', 'RI', 'VT'];
export const PLAINS: AmericanStates[] = ['AR', 'CO', 'KS', 'MT', 'ND', 'NE', 'NM', 'OK', 'SD', 'TX', 'WY'];
export const OTHER: AmericanStates[] = ['AK'];

export const STATES: AmericanStates[] = sortBy([...WEST, ...SOUTH, ...MIDWEST, ...NORTHEAST, ...PLAINS, ...OTHER]);

export const CA_PROVINCES: CanadianStates[] = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'NT',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
];

export const USA_STATES: AmericanStates[] = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

import React from 'react';

import { Checkbox } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';

export enum CheckboxState {
  Unchecked,
  Checked,
  PartiallyChecked,
}

export interface CustomCheckboxProps extends CheckboxProps {
  checkedState: CheckboxState;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CustomCheckbox: React.FC<CustomCheckboxProps> = (props) => {
  const { checkedState, ...rest } = props;
  return (
    <Checkbox
      checked={getCheckedValue(props.checkedState)}
      onChange={props.onChange}
      indeterminate={getIndeterminate(props.checkedState)}
      {...rest}
    />
  );
};

const getCheckedValue = (checkedState: CheckboxState) =>
  checkedState === CheckboxState.Checked || checkedState === CheckboxState.PartiallyChecked ? true : false;

const getIndeterminate = (checkedState: CheckboxState) => checkedState === CheckboxState.PartiallyChecked;

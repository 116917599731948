import moment from 'moment';

import { TimePeriod } from './UserPlan';

export enum InvoiceStatus {
  None = 'None',
  New = 'New',
  SentOn = 'SentOn',
  InProcess = 'InProcess',
  Declined = 'Declined',
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  Cancelled = 'Cancelled',
  Intrial = 'Intrial',
}

export enum InvoiceCurrency {
  None,
  CAD,
  USD,
}

export interface Invoice {
  id: number;
  guid: string;
  amount: number;
  status: InvoiceStatus;
  currency: InvoiceCurrency;
  invoiceNo: number;
  createdOn: string;
}

export interface InvoiceListResponse {
  invoices: Invoice[];
  count: number;
}

export interface InvoicesFilters {
  offset: number;
  limit: number;
  period: TimePeriod;
  fields?: string;
}

export interface InvoicesRequest extends InvoicesFilters {
  startDate: string;
  endDate: string;
}

const INVOICES_LIST_REQUEST_OFFSET_DEFAULT = 0;
export const INVOICES_LIST_REQUEST_LIMIT_DEFAULT = 20;
export const INVOICES_LIST_REQUEST_LIMIT_APPENDING = 10;
export const INVOICES_LIST_REQUEST_OFFSET_INCREMENT = 10;
export const DEFAULT_INVOICE_REQUEST = {
  offset: INVOICES_LIST_REQUEST_OFFSET_DEFAULT,
  limit: INVOICES_LIST_REQUEST_LIMIT_DEFAULT,
  period: TimePeriod.Year,
};

export const getFetchRequest = (fetchRequest: InvoicesFilters, selectedYear: string): InvoicesRequest => ({
  ...fetchRequest,
  startDate: moment([selectedYear]).startOf('year').format('YYYY-MM-DD'),
  endDate: moment([selectedYear]).endOf('year').format('YYYY-MM-DD'),
});

import { Action, AnyAction, Dispatch } from 'redux';

import { ApiAction, ResultResponseActionWithFetchData } from './Base';

/**
 * Map of the response type string to its unique number.
 *
 * Note that the map entries never gets deleted, but it will max
 * to the number of possible response type with the feature turned on.
 */
const lastResponseActionUids: Map<string, number> = new Map();

/** =================================================================
 *
 * Middleware that inhibit (abort) responses from requests that
 * got overriden by another more recent request. Typical use case
 * is a details panel next to a list panel where the user can
 * click many items in a row to make sure only the response data from
 * the last request shows.
 *
 * To use, specify `multiplex:false` to createApiActionWithFetchData()'s options parameter
 *
 * IMPLEMENTATION
 *
 * A unique id is generated for each request/repsonse pair.
 * This is used to compare if it matches the latest one. Keeps track of
 * the latest uid for a specific response's action type.
 *
 * ==================================================================
 */
export const lastRequestOverrideMiddleware = () => (next: Dispatch<AnyAction>) => (action: Action) => {
  if ((action as ResultResponseActionWithFetchData<any, any>).fetchData) {
    if (shouldBlockResponse(action as ResultResponseActionWithFetchData<any, any>)) {
      //alter the action directly and send it anyway. This helps tracking what's going on in reactotron.
      //remove "COMPLETE." (if any) and put "ABORTED." instead.
      action.type = action.type.replace(/^(?:COMPLETE\.)?/, 'ABORTED.');
    }
  } else {
    const metadata = (action as ApiAction<any>).metadata;
    if (metadata?.overrideResponse) {
      lastResponseActionUids.set(metadata.overrideResponse, metadata.uid);
    }
  }

  return next(action);
};

const shouldBlockResponse = (apiAction: ResultResponseActionWithFetchData<any, any>): boolean => {
  const responseUid = apiAction.metadata?.uid;

  if (!responseUid) return false;

  const lastApiAction = lastResponseActionUids.get(apiAction.type);

  // related request action not in this override system
  if (lastApiAction === undefined) return false;

  if (lastApiAction !== responseUid) {
    // overriden response. Stop response action from propagating.
    return true;
  }

  // let the response action proceed as normally
  return false;
};

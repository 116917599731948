import React, { useEffect } from 'react';

import styled from 'styled-components';

import { MainButton } from '@component/buttons';
import { PanelFooter } from '@component/panel';
import { useBackPanelContext } from '@component/panel/BackPanelContext';
import { FontWeight } from '@style/StyleConstants';
import { t, T } from '@translate';

const ApplyButton = styled(MainButton)`
  && {
    font-weight: ${FontWeight.Regular};
  }
`;

interface Props {
  onClick: () => void;
  isDisabled?: boolean;
  shouldSubmitOnEnter?: boolean;
}

export const ApplyButtonFooter: React.FC<Props> = (props) => {
  const { isHighest } = useBackPanelContext();

  const handleEnterButtonClick = (event: KeyboardEvent) => {
    if (event.code === 'Enter' && isHighest && props.shouldSubmitOnEnter) {
      props.onClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEnterButtonClick);
    return () => document.removeEventListener('keydown', handleEnterButtonClick);
  }, [isHighest, props.onClick]);

  return (
    <PanelFooter>
      <ApplyButton id="apply" title={t(T.common_apply)} onClick={props.onClick} isDisabled={props.isDisabled} />
    </PanelFooter>
  );
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { flatMap as flatMap$ } from 'rxjs/operators';

import { Api, ApiResponse123 } from '@common/api';
import { SupportClient } from '@common/client';
import { ServiceCode } from '@common/model';

const SHOULD_REQUEST_SERVICE_CODE = 'SHOULD_REQUEST_SERVICE_CODE';
const SHOULD_DISPOSE_SERVICE_CODE = 'SHOULD_DISPOSE_SERVICE_CODE';
const REQUEST_SERVICE_CODE_REQUEST_COMPLETED = 'REQUEST_SERVICE_CODE_REQUEST_COMPLETED';

export interface ServiceCodeState {
  serviceCode?: string;
  isLoadingServiceCode: boolean;
}

interface ServiceCodeRequestCompleted extends Action {
  serviceCode?: string;
  success: boolean;
}

interface ServiceCodeRequested extends Action {
  isUserSignedIn: boolean;
}

export const serviceCodeRequestCompleted = (success: boolean, serviceCode?: string): ServiceCodeRequestCompleted => ({
  type: REQUEST_SERVICE_CODE_REQUEST_COMPLETED,
  serviceCode: serviceCode,
  success: success,
});

export const shouldRequestServiceCode = (isUserSignedIn: boolean): ServiceCodeRequested => {
  return {
    type: SHOULD_REQUEST_SERVICE_CODE,
    isUserSignedIn: isUserSignedIn,
  };
};

export const shouldDisposeServiceCode = (): Action => ({
  type: SHOULD_DISPOSE_SERVICE_CODE,
});

const initialState: ServiceCodeState = {
  serviceCode: '',
  isLoadingServiceCode: false,
};

export const ServiceCodeReducer = (state: ServiceCodeState = initialState, action: Action): ServiceCodeState => {
  switch (action.type) {
    case SHOULD_REQUEST_SERVICE_CODE: {
      return { ...state, isLoadingServiceCode: true };
    }
    case SHOULD_DISPOSE_SERVICE_CODE: {
      return { ...state, serviceCode: '' };
    }
    case REQUEST_SERVICE_CODE_REQUEST_COMPLETED: {
      const serviceCodeAction = action as ServiceCodeRequestCompleted;
      return {
        ...state,
        serviceCode: serviceCodeAction.serviceCode,
        isLoadingServiceCode: false,
      };
    }
    default:
      return state;
  }
};

const requestServiceCode$ = (supportClient: SupportClient) => {
  return supportClient.requestServiceCode$().pipe(
    flatMap$((response: ApiResponse123<ServiceCode>) => {
      return response.result(
        (data) => of$(serviceCodeRequestCompleted(true, data.serviceCode)),
        () => of$(serviceCodeRequestCompleted(true))
      );
    })
  );
};

const serviceCodeRequest$ = (
  action$: ActionsObservable<Action>,
  unAuthenticatedSupportClient: SupportClient,
  authenticatedSupportClient: SupportClient
) => {
  return action$
    .ofType(SHOULD_REQUEST_SERVICE_CODE)
    .pipe(
      flatMap$((action: ServiceCodeRequested) =>
        requestServiceCode$(action.isUserSignedIn ? authenticatedSupportClient : unAuthenticatedSupportClient)
      )
    );
};

export const createServiceCodeEpic = (rawApi: Api, authenticatedApi: Api) => {
  const unAuthenticatedSupportClient = new SupportClient(rawApi);
  const authenticatedSupportClient = new SupportClient(authenticatedApi);
  return (action$: ActionsObservable<Action>) =>
    merge$(serviceCodeRequest$(action$, unAuthenticatedSupportClient, authenticatedSupportClient));
};

import { reverse } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { mergeAll as mergeAll$ } from 'rxjs/operators';

import { Api } from '@common/api';
import { MileageCalculatorClient } from '@common/client';
import {
  LocationSuggestion,
  MileageRoutesPostRequest,
  MileageRoutesRequest,
  RouteDirections,
  RoutePoints,
  StateMileagePayload,
  UiSettingsPayload,
} from '@common/model';
import { createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface SelectedSingleLocation {
  selectedLocation: LocationSuggestion;
  stopIndex?: number;
}

export enum ViewTypes {
  PickUp = 'PickUp',
  DropOff = 'DropOff',
  Search = 'Search',
  AddStop = 'AddStop',
  EditStop = 'EditStop',
}

const updatePickupLocationAction = createApiAction<SelectedSingleLocation, undefined>('UPDATE_PICKUP_LOCATION');

const updateDropOffLocationAction = createApiAction<SelectedSingleLocation, undefined>('UPDATE_DROPOFF_LOCATION');

const addStopLocationAction = createApiAction<SelectedSingleLocation, undefined>('ADD_STOP_LOCATION');

const editStopLocationAction = createApiAction<SelectedSingleLocation, undefined>('EDIT_STOP_LOCATION');

const deleteStopLocationAction = createApiAction<number, undefined>('DELETE_STOP_LOCATION');

const getRoutesDirectionAction = createApiAction<MileageRoutesRequest, RouteDirections>('GET_ROUTES_DIRECTION');

const postRoutesDirectionAction = createApiAction<
  { route: MileageRoutesPostRequest; isCustomRequest: boolean },
  RouteDirections
>('POST_ROUTES_DIRECTION');

const postRoutePathAction = createApiAction<{ route: MileageRoutesPostRequest }, RoutePoints>('POST_ROUTE_PATH');

const switchPickUpDropOffAction = createApiAction<undefined, undefined>('SWITCH_LOCATION');

const postStateMileageAction = createApiAction<MileageRoutesPostRequest, StateMileagePayload>('POST_STATE_MILEAGE');

const getUiSettingsAction = createApiAction<undefined, UiSettingsPayload>('GET_UI_SETTINGS');

const patchUiSettingsAction = createApiAction<UiSettingsPayload, UiSettingsPayload>('PATCH_UI_SETTINGS');

const getExportReportAction = createApiAction<MileageRoutesPostRequest, string>('GET_EXPORT_REPORT');

export const updatePickUpLocation = (selectedLocation: SelectedSingleLocation) =>
  updatePickupLocationAction.fetchAction(selectedLocation);

export const updateDropOffLocation = (selectedLocation: SelectedSingleLocation) =>
  updateDropOffLocationAction.fetchAction(selectedLocation);

export const addStopLocation = (selectedLocation: SelectedSingleLocation) =>
  addStopLocationAction.fetchAction(selectedLocation);

export const editStopLocation = (selectedLocation: SelectedSingleLocation) =>
  editStopLocationAction.fetchAction(selectedLocation);

export const getRoutesDirection = (routeRequest: MileageRoutesRequest) =>
  getRoutesDirectionAction.fetchAction(routeRequest);

export const deleteStopLocation = (key: number) => deleteStopLocationAction.fetchAction(key);

export const postRoutesDirection = (route: MileageRoutesPostRequest, isCustomRequest: boolean) =>
  postRoutesDirectionAction.fetchAction({ route: route, isCustomRequest: isCustomRequest });

export const postRoutePath = (data: { route: MileageRoutesPostRequest }) => postRoutePathAction.fetchAction(data);

export const switchPickUpDropOff = () => switchPickUpDropOffAction.fetchAction(undefined);

export const postStateMileage = (data: MileageRoutesPostRequest) => postStateMileageAction.fetchAction(data);

export const getUiSettings = () => getUiSettingsAction.fetchAction(undefined);

export const patchUiSettings = (data: UiSettingsPayload) => patchUiSettingsAction.fetchAction(data);

export const getExportReport = (data: MileageRoutesPostRequest) => getExportReportAction.fetchAction(data);

export interface SelectedLocationsState {
  pickupLocation?: LocationSuggestion;
  dropOffLocation?: LocationSuggestion;
  stopsLocations: LocationSuggestion[];
  wasUpdated: boolean;
  isLoadingPath: boolean;
  mileageRoutesPayload?: RouteDirections;
  mileageMap?: RoutePoints;
  isLoadingRoute: boolean;
  wasRouteLoaded?: boolean;
  isSwitching: boolean;
  isLoadingStateMileage: boolean;
  stateMileagePaylod?: StateMileagePayload;
  uiSettings?: UiSettingsPayload;
  wasUiSettingsFetched: boolean;
  wasUiSettingsUpdated: boolean;
  isLoadingUiSettings: boolean;
  isDownloading: boolean;
  wasDownloaded: boolean;
  csvData?: string;
  httpStatus?: number;
}

const initialSelectedLocationState: SelectedLocationsState = {
  wasUpdated: false,
  isLoadingPath: false,
  stopsLocations: [],
  isLoadingRoute: false,
  isSwitching: false,
  isLoadingStateMileage: false,
  wasUiSettingsFetched: false,
  wasUiSettingsUpdated: false,
  isLoadingUiSettings: false,
  isDownloading: false,
  wasDownloaded: false,
};

export const mileageCalculatorReducer = createMergedReducer(initialSelectedLocationState, [
  updatePickupLocationAction.initiateCase((state, action) => {
    state.pickupLocation = action.data.selectedLocation;
  }),
  updateDropOffLocationAction.initiateCase((state, action) => {
    state.dropOffLocation = action.data.selectedLocation;
  }),
  addStopLocationAction.initiateCase((state, action) => {
    if (action.data.stopIndex !== undefined && state.stopsLocations.length >= 1) {
      const index = action.data.stopIndex;
      const stop = action.data.selectedLocation;
      state.stopsLocations.splice(index, 0, stop);
    } else {
      state.stopsLocations.push(action.data.selectedLocation);
    }
  }),
  editStopLocationAction.initiateCase((state, action) => {
    if (action.data.stopIndex !== undefined) {
      const index = action.data.stopIndex;
      const stop = action.data.selectedLocation;
      state.stopsLocations.splice(index, 1, stop);
    }
  }),
  deleteStopLocationAction.initiateCase((state, action) => {
    if (state.stopsLocations[action.data]) {
      state.stopsLocations.splice(action.data, 1);
    }
  }),
  getRoutesDirectionAction.initiateCase((state) => {
    state.isLoadingRoute = true;
  }),
  getRoutesDirectionAction.completeCase((state, action) => {
    state.isLoadingRoute = false;
    if (action.response.success) {
      state.mileageRoutesPayload = action.response.payload;
    }
  }),
  postRoutesDirectionAction.initiateCase((state) => {
    state.isLoadingRoute = true;
    state.wasRouteLoaded = undefined;
  }),
  postRoutesDirectionAction.completeCase((state, action) => {
    state.isLoadingRoute = false;
    if (action.response.success) {
      state.mileageRoutesPayload = action.response.payload;
      state.wasRouteLoaded = true;
    } else {
      state.httpStatus = action.response.error.httpStatus;
      state.wasRouteLoaded = false;
    }
  }),

  postRoutePathAction.initiateCase((state) => {
    state.isLoadingPath = true;
  }),
  postRoutePathAction.completeCase((state, action) => {
    state.isLoadingPath = false;
    if (action.response.success) {
      state.mileageMap = action.response.payload;
    }
  }),

  postStateMileageAction.initiateCase((state) => {
    state.isLoadingStateMileage = true;
  }),

  postStateMileageAction.completeCase((state, action) => {
    state.isLoadingStateMileage = false;
    if (action.response.success) {
      state.stateMileagePaylod = action.response.payload;
    }
  }),

  switchPickUpDropOffAction.initiateCase((state) => {
    state.isSwitching = true;
    const newPickup = state.dropOffLocation;
    const newDropOff = state.pickupLocation;
    state.pickupLocation = newPickup;
    state.dropOffLocation = newDropOff;
    state.stopsLocations = reverse(state.stopsLocations);
  }),
  switchPickUpDropOffAction.completeCase((state) => {
    state.isSwitching = false;
  }),

  getUiSettingsAction.initiateCase((state) => {
    state.isLoadingUiSettings = true;
    state.wasUiSettingsFetched = false;
  }),

  getUiSettingsAction.completeCase((state, action) => {
    state.isLoadingUiSettings = false;
    if (action.response.success) {
      state.wasUiSettingsFetched = true;
      state.uiSettings = action.response.payload;
    }
  }),

  patchUiSettingsAction.initiateCase((state) => {
    state.isLoadingUiSettings = true;
    state.wasUiSettingsUpdated = false;
  }),

  patchUiSettingsAction.completeCase((state, action) => {
    state.isLoadingUiSettings = false;
    if (action.response.success) {
      state.wasUiSettingsUpdated = true;
      state.uiSettings = action.response.payload;
    }
  }),

  getExportReportAction.initiateCase((state) => {
    state.isDownloading = true;
    state.wasDownloaded = false;
  }),

  getExportReportAction.completeCase((state, action) => {
    state.isDownloading = false;
    if (action.response.success) {
      state.wasDownloaded = true;
      state.csvData = action.response.payload;
    }
  }),
]);

export const createMileageCalculatorEpic = (api: Api) => {
  const client = new MileageCalculatorClient(api);
  return (action$: ActionsObservable<Action>) =>
    of(
      postStateMileageAction.createEpic$(action$, (data) => client.postStateMileage$(data)),
      postRoutesDirectionAction.createEpic$(action$, (data) =>
        client.postRouteDirection$(data.route, data.isCustomRequest)
      ),
      postRoutePathAction.createEpic$(action$, (data) => client.postRoutePath$(data)),
      getRoutesDirectionAction.createEpic$(action$, (data) => client.getRoutesDirection$(data)),
      getUiSettingsAction.createEpic$(action$, () => client.getUiSettings$()),
      patchUiSettingsAction.createEpic$(action$, (data) => client.patchUiSettings$(data)),
      getExportReportAction.createEpic$(action$, (data) => client.getExportReport$(data))
    ).pipe(mergeAll$());
};

import React, { BaseSyntheticEvent, useState } from 'react';

import { ListItemText, MenuItem } from '@material-ui/core';

import { displayCurrencyOrDash, hasDecimal } from '@common/formatter';
import { BidStatus } from '@common/model';
import { usePushPanel } from '@component/panel/PanelHooks';
import { Color } from '@style/Color';
import { T, t } from '@translate';

export const useDropdownMenu = () => {
  const [menuAnchor, setMenuAnchor] = useState(undefined);

  const handleClick = (event: BaseSyntheticEvent) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(undefined);
  };

  return { menuAnchor: menuAnchor, onMenuClick: handleClick, onMenuClose: handleClose };
};

export const getMenuOptions = (onMenuClose: () => void) => {
  const pushPanel = usePushPanel('/load-details/');

  const openLoadDetailsPanel = () => () => {
    onMenuClose();
    pushPanel('');
  };

  return [
    <MenuItem id="view_load_details" key={'view_load_details_key'} onClick={openLoadDetailsPanel()}>
      <ListItemText id="view_load" primary={t(T.common_bids_summary_menu_viewLoadDetails)} />
    </MenuItem>,
  ];
};

export type BidsPanelType = 'new' | 'offer' | 'edit' | 'counteroffer';

export const getPanelLabel = (panelType: BidsPanelType) => {
  switch (panelType) {
    case 'new':
      return t(T.common_bids_placeBid_title);
    case 'offer':
      return t(T.common_bids_summary_title);
    case 'edit':
      return t(T.common_bids_editBid_title);
    case 'counteroffer':
      return t(T.common_bids_placeCounterOffer_title);
    default:
      return '';
  }
};

export const getStatusColor = (status: BidStatus) => {
  let statusColor = Color.BLACK;

  if (status === BidStatus.Pending) {
    statusColor = Color.ORANGE_MAIN;
  } else if (status === BidStatus.ActionRequired) {
    statusColor = Color.RED_APPLE;
  }

  return statusColor;
};

export const getStatusText = (status: BidStatus) => {
  let statusText;

  if (status === BidStatus.Pending) {
    statusText = t(T.common_bids_status_pending);
  } else if (status === BidStatus.ActionRequired) {
    statusText = t(T.common_bids_status_actionRequired);
  }

  return statusText;
};

export const getTextContent = (args: {
  status: BidStatus;
  isCarrier: boolean;
  offerMadeByMe: boolean;
  price?: number;
  brokerName?: string;
}) => {
  const { status, isCarrier, offerMadeByMe, price, brokerName } = args;

  let textContent;
  if (status === BidStatus.Pending) {
    textContent = isCarrier
      ? t(T.common_bids_bidItem_messages_sentOffer)
      : t(T.common_bids_bidItem_messages_broker_sentCounteroffer);
  } else if (status === BidStatus.ActionRequired) {
    const tArgs = {
      amount: displayCurrencyOrDash(price, hasDecimal(price)),
      broker: brokerName,
    };

    if (offerMadeByMe) {
      textContent = isCarrier
        ? t(T.common_bids_summary_messages_sentOfferAccepted, tArgs)
        : t(T.common_bids_bidItem_messages_broker_sentOfferAccepted);
    } else {
      textContent = isCarrier
        ? t(T.common_bids_bidItem_messages_carrier_receivedOffer, tArgs)
        : t(T.common_bids_bidItem_messages_receivedOffer);
    }
  }

  return textContent;
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { LoadsClient, MileageCalculatorClient, RouteClient } from '@common/client';
import { MileageRoutesPostRequest, RoutePoints, StateMileagePayload } from '@common/model';
import { RouteDirections } from '@common/model/PCMiler';
import { createApiAction, Response } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const fetchRouteDirectionsAction = createApiAction<MileageRoutesPostRequest, RouteDirections>('FETCH_ROUTE_DIRECTIONS');
const fetchStateMileageAction = createApiAction<{ loadID: string; archivingFlowID?: string }, StateMileagePayload>(
  'FETCH_STATE_MILEAGE'
);
const fetchExportReportAction = createApiAction<MileageRoutesPostRequest, string>('FETCH_EXPORT_REPORT');
const postRoutePathAction = createApiAction<{ route: MileageRoutesPostRequest }, RoutePoints>('POST_ROUTE_PATH');

export const fetchRouteDirections = (stops: MileageRoutesPostRequest) => fetchRouteDirectionsAction.fetchAction(stops);
export const fetchStateMileage = (loadID: string, archivingFlowID?: string) =>
  fetchStateMileageAction.fetchAction({ loadID: loadID, archivingFlowID: archivingFlowID });
export const fetchExportReport = (data: MileageRoutesPostRequest) => fetchExportReportAction.fetchAction(data);
export const postRoutePath = (data: { route: MileageRoutesPostRequest }) => postRoutePathAction.fetchAction(data);

type RouteResponse = Response<RoutePoints>;

export interface PCMilerState {
  isLoading: boolean;
  directions?: RouteDirections;
  map?: string;
  stateMileagePayload?: StateMileagePayload;
  isLoadingStateMileage: boolean;
  isDownloadingReport: boolean;
  isReportDownloaded: boolean;
  csvData?: string;
  route?: RouteResponse;
  isLoadingRoute: boolean;
}

const initialState: PCMilerState = {
  isLoading: false,
  isLoadingStateMileage: false,
  isDownloadingReport: false,
  isReportDownloaded: false,
  isLoadingRoute: false,
};

export const PCMilerReducer = createMergedReducer(initialState, [
  fetchRouteDirectionsAction.initiateCase((state) => {
    state.directions = undefined;
    state.isLoading = true;
  }),
  fetchRouteDirectionsAction.completeCase((state, action) => {
    if (action.response.success) {
      state.directions = action.response.payload;
    }
    state.isLoading = false;
  }),
  fetchStateMileageAction.initiateCase((state) => {
    state.isLoadingStateMileage = true;
  }),
  fetchStateMileageAction.completeCase((state, action) => {
    if (action.response.success) {
      state.stateMileagePayload = action.response.payload;
    }
    state.isLoadingStateMileage = false;
  }),
  fetchExportReportAction.initiateCase((state) => {
    state.isDownloadingReport = true;
    state.isReportDownloaded = false;
  }),
  fetchExportReportAction.completeCase((state, action) => {
    if (action.response.success) {
      state.isReportDownloaded = true;
      state.csvData = action.response.payload;
    }
    state.isDownloadingReport = false;
  }),
  postRoutePathAction.initiateCase((state) => {
    state.isLoadingRoute = true;
  }),
  postRoutePathAction.completeCase((state, action) => {
    if (action.response.success) {
      state.route = action.response;
    }
    state.isLoadingRoute = false;
  }),
]);

export const createPCMilerEpic = (api: Api, isLiveEnvironment: boolean) => {
  const loadsClient = new LoadsClient(api, isLiveEnvironment);
  const mileageClient = new MileageCalculatorClient(api);
  const routeClient = new RouteClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchRouteDirectionsAction.createEpic$(action$, (data) => routeClient.postRouteDirection$(data)),
      fetchStateMileageAction.createEpic$(action$, (data) =>
        loadsClient.getRouteStateMileage$(data.loadID, data.archivingFlowID)
      ),
      fetchExportReportAction.createEpic$(action$, (data) => mileageClient.getExportReport$(data)),
      postRoutePathAction.createEpic$(action$, (data) => routeClient.postRoutePath$(data))
    );
};

import { filter, map } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api, ApiError } from '@common/api';
import { InboxClient } from '@common/client/InboxClient';
import { GetMessagesResponse, InboxMessage, MessageAction } from '@common/model/Inbox';
import { createApiAction, createApiActionWithFetchData, EmptyResponse } from '@common/redux/Base';

import { createMergedReducer } from '../ReduxHelper';

export interface InboxState {
  messages: InboxMessage[];
  currentMessage?: InboxMessage;
  isLoadingCurrentMessage: boolean;
  didFetchMessages: boolean;
  isLoadingMessages: boolean;
  delete: {
    isDeleting: boolean;
    deletedSuccessfully: boolean;
    deletedTime?: number;
    error?: ApiError;
  };
  clear: {
    isClearing: boolean;
    clearedSuccessfully: boolean;
    clearedTime?: number;
    error?: ApiError;
  };
  read: {
    isReading: boolean;
    readSuccessfully: boolean;
    error?: ApiError;
  };
  token?: string;
  hasMore: boolean;
  error?: ApiError;
}

const initialState: InboxState = {
  didFetchMessages: false,
  isLoadingMessages: false,
  isLoadingCurrentMessage: false,
  delete: {
    isDeleting: false,
    deletedSuccessfully: false,
  },
  clear: {
    isClearing: false,
    clearedSuccessfully: false,
  },
  read: {
    isReading: false,
    readSuccessfully: false,
  },
  messages: [],
  hasMore: true,
};

export const fetchInboxMessagesAction = createApiActionWithFetchData<
  { token: string | undefined },
  GetMessagesResponse
>('FETCH_INBOX_MESSAGES');
const fetchInboxMessageAction = createApiAction<{ messageId: string }, InboxMessage>('FETCH_INBOX_MESSAGE');
const deleteInboxMessageAction = createApiActionWithFetchData<{ messageId: string }, EmptyResponse>(
  'DELETE_INBOX_MESSAGE'
);
export const clearInboxMessagesAction = createApiAction<{}, EmptyResponse>('CLEAR_INBOX_MESSAGES');
export const markAsReadAction = createApiActionWithFetchData<{ messageId: string }, EmptyResponse>('MARK_AS_READ');

export const fetchInboxMessages = (token: string | undefined) => fetchInboxMessagesAction.fetchAction({ token: token });
export const fetchInboxMessage = (messageId: string) => fetchInboxMessageAction.fetchAction({ messageId: messageId });
export const deleteInboxMessage = (messageId: string) => deleteInboxMessageAction.fetchAction({ messageId: messageId });
export const clearInboxMessages = () => clearInboxMessagesAction.fetchAction({});
export const markAsRead = (messageId: string) => markAsReadAction.fetchAction({ messageId: messageId });

export const inboxReducer = createMergedReducer(initialState, [
  fetchInboxMessagesAction.initiateCase((state) => {
    state.isLoadingMessages = true;
  }),
  fetchInboxMessagesAction.completeCase((state, action) => {
    state.isLoadingMessages = false;
    state.didFetchMessages = action.response.success;
    if (action.response.success) {
      state.messages = action?.fetchData?.token
        ? [...state.messages, ...action.response.payload.items]
        : action.response.payload.items;
      state.hasMore = action.response.payload.hasMore;
      state.token = action.response.payload.token;
    } else {
      state.error = action.response.error;
    }
  }),
  fetchInboxMessageAction.initiateCase((state) => {
    state.isLoadingCurrentMessage = true;
  }),
  fetchInboxMessageAction.completeCase((state, action) => {
    state.isLoadingCurrentMessage = false;
    if (action.response.success) {
      state.currentMessage = action.response.payload;
    }
  }),
  deleteInboxMessageAction.initiateCase((state) => {
    state.delete.isDeleting = true;
  }),
  deleteInboxMessageAction.completeCase((state, action) => {
    state.delete = {
      isDeleting: false,
      deletedSuccessfully: action.response.success,
    };
    if (action.response.success) {
      state.messages = filter(state.messages, ({ guid }) => guid !== action.fetchData?.messageId);
      state.delete.deletedTime = Date.now();
    } else {
      state.delete.error = action.response.error;
    }
  }),
  clearInboxMessagesAction.initiateCase((state) => {
    state.clear.isClearing = true;
  }),
  clearInboxMessagesAction.completeCase((state, action) => {
    state.clear = {
      isClearing: false,
      clearedSuccessfully: action.response.success,
    };
    if (action.response.success) {
      state.messages = [];
      state.clear.clearedTime = Date.now();
    } else {
      state.clear.error = action.response.error;
    }
  }),
  markAsReadAction.initiateCase((state) => {
    state.read.isReading = true;
  }),
  markAsReadAction.completeCase((state, action) => {
    if (action.response.success) {
      state.messages = map(state.messages, (message) =>
        message.guid === action.fetchData?.messageId ? { ...message, read: true } : message
      );
      if (
        state.currentMessage &&
        state.currentMessage?.guid &&
        action.fetchData?.messageId === state.currentMessage?.guid
      ) {
        state.currentMessage.read = true;
      }
    } else {
      state.read.error = action.response.error;
    }
    state.read.isReading = false;
  }),
]);

export const createInboxEpic = (api: Api) => {
  const client = new InboxClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      deleteInboxMessageAction.createEpic$(action$, (data) =>
        client.updateInboxMessage$({ action: MessageAction.delete, messageIds: [data.messageId] })
      ),
      clearInboxMessagesAction.createEpic$(action$, client.clearAllMessage$),
      markAsReadAction.createEpic$(action$, (data) => client.readMessage$(data.messageId)),
      fetchInboxMessagesAction.createEpic$(action$, (data) => client.fetchAllInboxMessages$(data.token)),
      fetchInboxMessageAction.createEpic$(action$, (data) => client.fetchMessage$(data.messageId))
    );
};

import React from 'react';

import styled from 'styled-components';

import { LoadingProgress } from './LoadingProgress';

const SAFE_SCREEN_HEIGHT = 500;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${SAFE_SCREEN_HEIGHT}px;
  justify-content: center;
  align-items: center;
`;

export const OverlayLoadingProgress: React.SFC<{ id: string }> = (props) => (
  <LoadingContainer>
    <LoadingProgress id={props.id} />
  </LoadingContainer>
);

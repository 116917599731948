import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as observableMerge } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { LoadsClient } from '@common/client';
import { addFetchedTime } from '@common/helper';
import { AlertNotification, Load, PayRateUnit } from '@common/model';
import { BaseState, Response } from '@common/redux/Base';

const FETCH_LOADS = 'FETCH_LOADS';
const LOADS_FETCHED = 'LOADS_FETCHED';
const FETCH_POSTED_LOAD_COUNT = 'FETCH_POSTED_LOAD_COUNT';
const POSTED_LOAD_COUNT_FETCHED = 'POSTED_LOAD_COUNT_FETCHED';
const SEND_ALERT = 'SEND_ALERT';
const ALERT_SENT = 'ALERT_SENT';
const SAVE_LOAD_FILTER = 'SAVE_LOAD_FILTER';
const FETCH_POSTED_LOAD_COUNT_FIELDS = 'metadata{totalResultCount}';

const INITIAL_STATE: LoadsState = {
  isLoading: false,
  numberOfAlertsSent: 0,
  isAlertSending: false,
  alertResponseTimeStamp: 0,
  lastLoadFilter: '',
};
export type LoadsResponse = Response<Load[]>;
export type LoadCountResponse = Response<number>;
export type AlertNotificationResponse = Response<number>;

interface FetchLoadsResponse extends Action {
  loads: LoadsResponse;
}

interface LoadFilterResponse extends Action {
  filter: string;
  sort: LoadSortOrder;
}

interface FetchPostedLoadCountResponse extends Action {
  postedLoadCount: LoadCountResponse;
}

interface AlertNotificationRequest extends Action {
  loadId: string;
  alertNotification: AlertNotification;
}

interface FetchLoadsRequest extends Action {
  /** only used for internal truck locator */
  companyName?: string;
}

interface PostAlertNotificationResponse extends Action {
  response: AlertNotificationResponse;
  numberOfAlertedCarriers: number;
}
/** @param companyName is only used for internal truck locator */
export function fetchLoads(companyName?: string): FetchLoadsRequest {
  return {
    type: FETCH_LOADS,
    companyName: companyName,
  };
}

export function fetchPostedLoadCount(): Action {
  return {
    type: FETCH_POSTED_LOAD_COUNT,
  };
}

export function postAlertNotification(loadId: string, alertNotification: AlertNotification): AlertNotificationRequest {
  return {
    type: SEND_ALERT,
    loadId: loadId,
    alertNotification: alertNotification,
  };
}

export interface LoadSortOrder {
  sortedAsc: boolean;
  category: string;
}

export interface LoadsState extends BaseState {
  loads?: LoadsResponse;
  postedLoadCount?: LoadCountResponse;
  alertResponse?: AlertNotificationResponse;
  numberOfAlertsSent: number;
  isAlertSending: boolean;
  alertResponseTimeStamp: number;
  lastLoadFilter: string;
}

export const fetchLoadsResponse = (response: LoadsResponse): FetchLoadsResponse => ({
  type: LOADS_FETCHED,
  loads: response,
});

export const saveLoadFilter = (filter: string, sort: LoadSortOrder): LoadFilterResponse => ({
  type: SAVE_LOAD_FILTER,
  filter: filter,
  sort: sort,
});

export const fetchPostedLoadCountResponse = (response: LoadCountResponse): FetchPostedLoadCountResponse => ({
  type: POSTED_LOAD_COUNT_FETCHED,
  postedLoadCount: response,
});

export const postAlertNotificationResponse = (
  response: AlertNotificationResponse,
  alertedAmount: number
): PostAlertNotificationResponse => ({
  type: ALERT_SENT,
  response: response,
  numberOfAlertedCarriers: alertedAmount,
});

export const loadsReducer = (state = INITIAL_STATE, action: Action): LoadsState => {
  switch (action.type) {
    case POSTED_LOAD_COUNT_FETCHED: {
      const postedLoadCountAction = action as FetchPostedLoadCountResponse;
      return {
        ...state,
        postedLoadCount: postedLoadCountAction.postedLoadCount,
        isLoading: false,
      };
    }
    case SAVE_LOAD_FILTER: {
      const saveAction = action as LoadFilterResponse;
      return {
        ...state,
        lastLoadFilter: saveAction.filter,
      };
    }
    case LOADS_FETCHED: {
      const loadAction = action as FetchLoadsResponse;
      if (loadAction.loads.payload) {
        loadAction.loads.payload = addFetchedTime(loadAction.loads.payload);
      }
      return {
        ...state,
        loads: loadAction.loads,
        isLoading: false,
      };
    }
    case FETCH_POSTED_LOAD_COUNT:
    case FETCH_LOADS:
      return {
        ...state,
        loads: undefined,
        isLoading: true,
      };
    case SEND_ALERT:
      return {
        ...state,
        isAlertSending: true,
      };
    case ALERT_SENT: {
      const alertAction = action as PostAlertNotificationResponse;
      return {
        ...state,
        alertResponse: alertAction.response,
        isAlertSending: false,
        alertResponseTimeStamp: new Date().getTime(),
        numberOfAlertsSent: alertAction.numberOfAlertedCarriers,
      };
    }

    default:
      return state;
  }
};

const fetchLoadsAction$ = (action$: ActionsObservable<Action>, client: LoadsClient) =>
  action$.ofType(FETCH_LOADS).pipe(
    mergeMap$((action: FetchLoadsRequest) => {
      return client.fetchLoads$(action.companyName).pipe(
        map$((response: ApiResponse123<Load[]>) => {
          if (response.success) {
            const successResp = response as ApiResponseSuccess<Load[]>;
            return fetchLoadsResponse({ success: response.success, payload: successResp.data });
          } else {
            const error = response as ApiError;
            return fetchLoadsResponse({ success: response.success, error: error });
          }
        })
      );
    })
  );

const fetchPostedLoadCountAction$ = (action$: ActionsObservable<Action>, client: LoadsClient) =>
  action$.ofType(FETCH_POSTED_LOAD_COUNT).pipe(
    mergeMap$(() => {
      return client.fetchPostedLoadsList$(FETCH_POSTED_LOAD_COUNT_FIELDS).pipe(
        map$((response: ApiResponse123<{ metadata: { totalResultCount: number } }>) => {
          if (response.success) {
            const successResp = response as ApiResponseSuccess<{ metadata: { totalResultCount: number } }>;
            if (successResp.data) {
              return fetchPostedLoadCountResponse({
                success: response.success,
                payload: successResp.data.metadata.totalResultCount,
              });
            }
          }

          const error = response as ApiError;
          return fetchPostedLoadCountResponse({ success: response.success, error: error });
        })
      );
    })
  );

const postAlertNotificationAction$ = (action$: ActionsObservable<Action>, client: LoadsClient) =>
  action$.ofType(SEND_ALERT).pipe(
    mergeMap$((alertNotificationRequest: AlertNotificationRequest) => {
      return client
        .postAlertNotification$(alertNotificationRequest.loadId, {
          ...alertNotificationRequest.alertNotification,
          amountType: PayRateUnit.Flat,
        })
        .pipe(
          map$((response: ApiResponse123<{ count: number }>) => {
            if (response.success) {
              const successResp = response as ApiResponseSuccess<{ count: number }>;
              if (successResp.data) {
                return postAlertNotificationResponse(
                  {
                    success: response.success,
                    payload: successResp.data.count,
                  },
                  alertNotificationRequest.alertNotification.targets.length
                );
              }
            }

            const error = response as ApiError;
            return postAlertNotificationResponse({ success: response.success, error: error }, 0);
          })
        );
    })
  );

export const createLoadsEpic = (api: Api, isLiveEnvironment: boolean) => {
  const loadsClient = new LoadsClient(api, isLiveEnvironment);
  return (action$: ActionsObservable<Action>) =>
    observableMerge(
      fetchLoadsAction$(action$, loadsClient),
      fetchPostedLoadCountAction$(action$, loadsClient),
      postAlertNotificationAction$(action$, loadsClient)
    );
};

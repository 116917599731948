import React from 'react';

import styled from 'styled-components';

import { displayCurrencyOrDash } from '@common/formatter';
import { GreenscreensRate } from '@common/model';
import { DetailRow } from '@component/panels/basePanel/loadDetails/generalInfo/LoadDetailsInfoBar';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';

import MaxIcon from './max.png';
import MinIcon from './min.png';

interface GreenscreensRateInfoProps {
  greenscreensRate?: GreenscreensRate;
  isEquipmentTypeSupported: boolean;
  isLoading?: boolean;
}

const Text = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  font-style: italic;
  color: ${Color.GRAY_STONE};
`;

const valueStyle = { display: 'flex', alignItems: 'center' };

export const GreenscreensRateInfo: React.FC<GreenscreensRateInfoProps> = (props) => {
  return (
    <>
      <DetailRow
        id="green_rate_max"
        title={t(T.common_marketRate_greenscreens_title)}
        subValue={
          props.isEquipmentTypeSupported ? renderPerMileValue(props.greenscreensRate?.costHighPerMile) : undefined
        }
        value={renderValue(props.isEquipmentTypeSupported, MaxIcon, props.greenscreensRate?.costHigh, props.isLoading)}
        valueStyle={valueStyle}
      />
      {props.isEquipmentTypeSupported && props.greenscreensRate?.costLow ? (
        <DetailRow
          id="green_rate_min"
          title={''}
          subValue={renderPerMileValue(props.greenscreensRate?.costLowPerMile)}
          value={renderValue(props.isEquipmentTypeSupported, MinIcon, props.greenscreensRate?.costLow)}
          valueStyle={valueStyle}
        />
      ) : null}
    </>
  );
};

const renderPerMileValue = (value?: number) =>
  value
    ? displayCurrencyOrDash(value, true, {
        suffix: t(T.common_per_miles_short),
      })
    : undefined;

const renderValue = (isEquipmentTypeSupported: boolean, iconSrc: string, value?: number, isLoading?: boolean) => {
  if (isLoading) {
    return <Text>{t(T.findLoads_loadDetails_loading)}</Text>;
  }
  if (!isEquipmentTypeSupported) {
    return <Text>{t(T.common_marketRate_notAvailableTrailer)}</Text>;
  }

  if (value) {
    return (
      <>
        <div style={{ minWidth: 55 }}>{displayCurrencyOrDash(value, false)}</div>
        {<img src={iconSrc} height={14} width={28} style={{ margin: '-3px 0 0 2px' }} />}
      </>
    );
  }

  return <Text>{t(T.common_marketRate_notAvailableAtThisTime)}</Text>;
};

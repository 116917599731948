import { BaseClient } from '@common/client/BaseClient';
import { METADATA_ONLY } from '@common/helper';
import {
  CustomFolder,
  CustomFoldersRequest,
  CustomFoldersResponse,
  DefaultFoldersResponse,
  Document,
  DocumentFilters,
  DocumentTypes,
  FolderIdentifier,
  FolderResponse,
  MyDocumentsSendRequest,
  NewCustomFolderRequest,
  NewCustomFolderResponse,
} from '@common/model';
import { EmptyResponse } from '@common/redux/Base';
import { DocumentsResponse } from '@common/redux/epic/DocumentListEpic';

export class DocumentsClient extends BaseClient {
  fetchDocuments$ = (request: DocumentFilters) =>
    this.api.get$<DocumentsResponse>('/documents', {
      documentType: request.documentType ? request.documentType.enumName : undefined,
      folderType: request.folder ? request.folder.type : undefined,
      folderId: request.folder ? request.folder.id : undefined,
      fromCreated: request.date ? request.date.dateStart : undefined,
      toCreated: request.date ? request.date.dateEnd : undefined,
      sortBy: request.sorting,
      sortDirection: undefined,
      limit: request.limit,
      offset: request.offset,
      SortDirection: request.sortDirection,
      fields: request.fields,
    });
  fetchDocumentTotal$ = () => this.api.get$<DocumentsResponse>('/documents', METADATA_ONLY);
  fetchFolderByLoad$ = (guid: string) => this.api.get$<FolderResponse>(`/loads/${guid}/folder`, {});
  fetchTypes$ = () => this.api.get$<DocumentTypes>('/documents/types', {});
  fetchDocumentFile$ = (id: string) => this.api.get$<string>(`/documents/${id}`, {}, { responseType: 'arraybuffer' });
  fetchConversationDocumentFile$ = (conversationID: string, messageID: string, docID: string) =>
    this.api.get$<string>(
      `/conversations/${conversationID}/messages/${messageID}/documents/${docID}`,
      {},
      { responseType: 'arraybuffer' }
    );
  sendDocuments$ = (request: MyDocumentsSendRequest) => this.api.post$<Document[]>('/documents/send', request);
  setDocName$ = (id: string, newName: string) =>
    this.api.patch$<Document>(`/documents/${id}`, [
      {
        op: 'replace',
        path: '/displayName',
        value: newName,
      },
    ]);
  deleteDocument$ = (uid: string) =>
    this.api.post$<{ failCount: string; successCount: string }>('/documents/delete', { documentsGuids: [uid] });
  changeDocType$ = (id: string, type: string) => {
    return this.api.patch$<Document>(`/documents/${id}`, [
      {
        op: 'replace',
        path: '/type',
        value: type,
      },
    ]);
  };
  changeDocFolder$ = (id: string, newFolder: FolderIdentifier) => {
    return this.api.patch$<Document>(`/documents/${id}`, [
      {
        op: 'replace',
        path: '/folderType',
        value: newFolder.type,
      },
      {
        op: 'replace',
        path: '/folderId',
        value: newFolder.id,
      },
    ]);
  };
  fetchCustomFolders$ = (request: CustomFoldersRequest) =>
    this.api.get$<CustomFoldersResponse>('/documents/folders/custom', request);
  fetchDefaultFolders$ = () => this.api.get$<DefaultFoldersResponse>('/documents/folders/default', {});
  createCustomFolder$ = (request: NewCustomFolderRequest) =>
    this.api.post$<NewCustomFolderResponse>('/documents/folders/custom', request);
  changeFolderName$ = (id: string, name: string) =>
    this.api.patch$<NewCustomFolderResponse>(`/documents/folders/custom/${id}`, [
      {
        op: 'replace',
        path: '/name',
        value: name,
      },
    ]);
  deleteFolder$ = (id: string) => this.api.delete$<EmptyResponse>(`/documents/folders/custom/${id}`, {});
  getCustomFolder$ = (id: string) => this.api.get$<CustomFolder>(`/documents/folders/custom/${id}`, {});
}

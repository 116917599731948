import { find } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { LoadsClient, RateCheckClient, RouteClient } from '@common/client';
import { formatNumberDecimals, LTLEquipmentTypes, toAddress } from '@common/helper';
import {
  AverageRateInitialExpansionLevel,
  CargoChiefRate,
  EquipmentType,
  ExpediteAllRateRequest,
  ExpediteAllResponse,
  GreenscreensRate,
  LoadSearchRequest,
  LoadToTruckRatioRequest,
  LoadToTruckRatioResponse,
  LocationSuggestion,
  RateCheck,
  RateCheckEquipmentTypes,
  RateCheckLocation,
  RateCheckRequest,
  RouteInfo,
  StopsLocation,
  ThirdPartyLaneRateRequest,
  ThirdPartyLaneRateResponse,
} from '@common/model';
import { createAction, createApiAction } from '@common/redux/Base';
import { MileageRequest } from '@common/redux/epic/PostTruckEpic';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const GREENSSCREENS_SOURCE = 'Greenscreens';
const CARGO_CHIEF_SOURCE = 'CargoChief';

export interface AcceptancePointCalculator {
  postingRate: number;
  estimatedCarrierOffer: number;
  acceptancePoint: number;
  shipperCharge: number;
}

export type MarketRatesEquipmentTypes = RateCheckEquipmentTypes | LTLEquipmentTypes;

export interface MarketRatesSearchForm {
  equipmentType: MarketRatesEquipmentTypes;
  origin?: RateCheckLocation;
  destination?: RateCheckLocation;
  originSuggestion?: LocationSuggestion;
  destinationSuggestion?: LocationSuggestion;
}

const changeMargetRatesSearchFormAction = createAction<Partial<MarketRatesSearchForm>>(
  'CHANGE_MARKET_RATES_SEARCH_FORM'
);
const setAreaExpansionLevelAction = createAction<AverageRateInitialExpansionLevel>('SET_AREA_EXPANSION_LEVEL');
const setTimeIntervalAction = createAction<number>('SET_TIME_INTERVAL');
const resetDataRangeAction = createAction('RESET_DATA_RANGE');
const switchLocationsSearchFormAction = createAction('SWITCH_LOCATIONS_MARKET_RATES_SEARCH_FORM');

const fetchBackhaulsRateCheckAction = createApiAction<RateCheckRequest, RateCheck>('FETCH_BACKHAULS_RATECHECK');
const fetchRouteInfoAction = createApiAction<MileageRequest, RouteInfo>('FETCH_ROUTE_INFO');

const setIsAcceptancePointExpansionPanelOpenAction = createAction<boolean>(
  'SET_IS_ACCEPTANCE_POINT_EXPANSION_PANEL_OPEN'
);
const setIsCapacityFinderExpansionPanelOpenAction = createAction<boolean>(
  'SET_IS_CAPACITY_FINDER_EXPANSION_PANEL_OPEN'
);
const fetchPickupLoadsCountAction = createApiAction<LoadSearchRequest, { totalResultCount: number }>(
  'FETCH_PICKUP_LOADS_COUNT'
);
const fetchAnywherePickupLoadsCountAction = createApiAction<LoadSearchRequest, { totalResultCount: number }>(
  'FETCH_PICKUP_ANYWHERE_LOADS_COUNT'
);
const fetchBackhaulsLoadsCountAction = createApiAction<LoadSearchRequest, { totalResultCount: number }>(
  'FETCH_BACKHAUL_LOADS_COUNT'
);
const fetchAnywhereBackhaulsLoadsCountAction = createApiAction<LoadSearchRequest, { totalResultCount: number }>(
  'FETCH_BACKHAUL_ANYWHERE_LOADS_COUNT'
);
const setAcceptancePointCalculatorAction = createAction<Partial<AcceptancePointCalculator>>(
  'SET_ACCEPTANCE_POINT_CALCULATOR'
);
const fetchThirdPartyLaneRatesAction = createApiAction<ThirdPartyLaneRateRequest, ThirdPartyLaneRateResponse[]>(
  'FETCH_LANE_RATES'
);
const fetchLoadToTruckRatioAction = createApiAction<
  LoadToTruckRatioRequest[],
  { individualResponses: LoadToTruckRatioResponse[] }
>('FETCH_LOAD_TO_TRUCK_RATIO');
const fetchExpediteAllRateAction = createApiAction<ExpediteAllRateRequest, ExpediteAllResponse>(
  'FETCH_EXPEDITE_ALL_RATE'
);
const fetchBackhaulsExpediteAllRateAction = createApiAction<ExpediteAllRateRequest, ExpediteAllResponse>(
  'FETCH_BACKHAULS_EXPEDITE_ALL_RATE'
);

export const changeMarketRatesSearchForm = (form: Partial<MarketRatesSearchForm>) =>
  changeMargetRatesSearchFormAction.action(form);
export const setAreaExpansionLevel = (value: AverageRateInitialExpansionLevel) =>
  setAreaExpansionLevelAction.action(value);
/** @param value - time interval in weeks */
export const setTimeInterval = (value: number) => setTimeIntervalAction.action(value);
export const resetDataRange = () => resetDataRangeAction.action(undefined);
export const switchLocations = () => switchLocationsSearchFormAction.action(undefined);
export const fetchBackhaulsRateCheck = (request: RateCheckRequest) =>
  fetchBackhaulsRateCheckAction.fetchAction(request);
export const fetchRouteInfo = (stops: StopsLocation[]) => fetchRouteInfoAction.fetchAction({ stops: stops });
export const setIsAcceptancePointExpansionPanelOpen = (isOpen: boolean) =>
  setIsAcceptancePointExpansionPanelOpenAction.action(isOpen);
export const setIsCapacityFinderExpansionPanelOpen = (isOpen: boolean) =>
  setIsCapacityFinderExpansionPanelOpenAction.action(isOpen);
export const setAcceptancePointCalculator = (values: Partial<AcceptancePointCalculator>) =>
  setAcceptancePointCalculatorAction.action(values);
export const fetchPickupLoadsCount = (request: LoadSearchRequest) => fetchPickupLoadsCountAction.fetchAction(request);
export const fetchAnywherePickupLoadsCount = (request: LoadSearchRequest) => {
  return fetchAnywherePickupLoadsCountAction.fetchAction(request);
};
export const fetchBackhaulsLoadsCount = (request: LoadSearchRequest) =>
  fetchBackhaulsLoadsCountAction.fetchAction(request);
export const fetchAnywhereBackhaulsLoadsCount = (request: LoadSearchRequest) =>
  fetchAnywhereBackhaulsLoadsCountAction.fetchAction(request);
export const fetchThirdPartyLaneRates = (request: ThirdPartyLaneRateRequest) =>
  fetchThirdPartyLaneRatesAction.fetchAction(request);
export const fetchLoadToTruckRatio = (request: LoadToTruckRatioRequest[]) =>
  fetchLoadToTruckRatioAction.fetchAction(request);
export const fetchExpediteAllRate = (request: ExpediteAllRateRequest) =>
  fetchExpediteAllRateAction.fetchAction(request);
export const fetchBackhaulsExpediteAllRate = (request: ExpediteAllRateRequest) =>
  fetchBackhaulsExpediteAllRateAction.fetchAction(request);

export interface MarketRatesState {
  searchForm: MarketRatesSearchForm;
  isFetchingBackhaulsRateCheck: boolean;
  backhaulsRateCheck?: RateCheck;
  isFetchingRouteInfo: boolean;
  routeInfo?: RouteInfo;
  isAcceptancePointExpansionPanelOpen: boolean;
  isCapacityFinderExpansionPanelOpen: boolean;
  acceptancePointCalculator: AcceptancePointCalculator;
  isFetchingLoadsCount: boolean;
  loadsCount: {
    pickupLoadsCount?: number;
    anywherePickupLoadsCount?: number;
    backhaulLoadsCount?: number;
    anywhereBackhaulLoadsCount?: number;
  };
  cargoChiefRate?: CargoChiefRate;
  greenscreensRate?: GreenscreensRate;
  isCargoChiefGreenscreensRateLoading: boolean;
  isCargoChiefRateFound?: boolean;
  isGreenscreensRateFound?: boolean;
  areaExpansionLevel: AverageRateInitialExpansionLevel;
  /** in weeks, min = 2, max = 52 */
  timeInterval: number;
  isLoadingLoadToTruckRatio: boolean;
  ratioAtOrigin?: string;
  ratioAtDestination?: string;
  isLoadingExpediteAllRate: boolean;
  expediteAllRate?: ExpediteAllResponse;
  errorFetchingExpediteAllRate?: boolean;
  isLoadingExpediteAllRateBackhaul: boolean;
  backhaulsExpediteAllRate?: ExpediteAllResponse;
}

const initialState: MarketRatesState = {
  searchForm: {
    equipmentType: EquipmentType.Flatbed,
  },
  isFetchingBackhaulsRateCheck: false,
  isFetchingRouteInfo: false,
  isAcceptancePointExpansionPanelOpen: true,
  isCapacityFinderExpansionPanelOpen: true,
  acceptancePointCalculator: {
    postingRate: 0,
    estimatedCarrierOffer: 0,
    acceptancePoint: 0,
    shipperCharge: 0,
  },
  isFetchingLoadsCount: false,
  loadsCount: {},
  isCargoChiefGreenscreensRateLoading: false,
  areaExpansionLevel: AverageRateInitialExpansionLevel.City,
  timeInterval: 2,
  isLoadingLoadToTruckRatio: false,
  isLoadingExpediteAllRate: false,
  isLoadingExpediteAllRateBackhaul: false,
};

export const marketRatesReducer = createMergedReducer(initialState, [
  changeMargetRatesSearchFormAction.addCase((state, action) => {
    state.searchForm = { ...state.searchForm, ...action.data };
  }),
  setAreaExpansionLevelAction.addCase((state, action) => {
    state.areaExpansionLevel = action.data;
  }),
  resetDataRangeAction.addCase((state) => {
    state.areaExpansionLevel = initialState.areaExpansionLevel;
    state.timeInterval = initialState.timeInterval;
  }),
  setTimeIntervalAction.addCase((state, action) => {
    state.timeInterval = action.data;
  }),
  switchLocationsSearchFormAction.addCase((state) => {
    const newOrigin = state.searchForm?.destination;
    const newDestination = state.searchForm?.origin;
    const newOriginSuggestion = state.searchForm.destinationSuggestion;
    const newDestinationSuggestion = state.searchForm.originSuggestion;
    state.searchForm = {
      ...state.searchForm,
      origin: newOrigin,
      destination: newDestination,
      originSuggestion: newOriginSuggestion,
      destinationSuggestion: newDestinationSuggestion,
    };
  }),
  fetchBackhaulsRateCheckAction.initiateCase((state) => {
    state.isFetchingBackhaulsRateCheck = true;
    state.backhaulsRateCheck = undefined;
  }),
  fetchBackhaulsRateCheckAction.completeCase((state, action) => {
    state.isFetchingBackhaulsRateCheck = false;
    if (action.response.success) {
      state.backhaulsRateCheck = action.response.payload;
    }
  }),
  fetchPickupLoadsCountAction.initiateCase((state) => {
    state.isFetchingLoadsCount = true;
    state.loadsCount.pickupLoadsCount = undefined;
  }),
  fetchPickupLoadsCountAction.completeCase((state, action) => {
    state.isFetchingLoadsCount = false;
    if (action.response.success) {
      state.loadsCount.pickupLoadsCount = action.response.payload.totalResultCount;
    }
  }),
  fetchAnywherePickupLoadsCountAction.initiateCase((state) => {
    state.isFetchingLoadsCount = true;
    state.loadsCount.anywherePickupLoadsCount = undefined;
  }),
  fetchAnywherePickupLoadsCountAction.completeCase((state, action) => {
    state.isFetchingLoadsCount = false;
    if (action.response.success) {
      state.loadsCount.anywherePickupLoadsCount = action.response.payload.totalResultCount;
    }
  }),
  fetchAnywhereBackhaulsLoadsCountAction.initiateCase((state) => {
    state.isFetchingLoadsCount = true;
    state.loadsCount.anywhereBackhaulLoadsCount = undefined;
  }),
  fetchAnywhereBackhaulsLoadsCountAction.completeCase((state, action) => {
    state.isFetchingLoadsCount = false;
    if (action.response.success) {
      state.loadsCount.anywhereBackhaulLoadsCount = action.response.payload.totalResultCount;
    }
  }),
  fetchBackhaulsLoadsCountAction.initiateCase((state) => {
    state.isFetchingLoadsCount = true;
    state.loadsCount.backhaulLoadsCount = undefined;
  }),
  fetchBackhaulsLoadsCountAction.completeCase((state, action) => {
    state.isFetchingLoadsCount = false;
    if (action.response.success) {
      state.loadsCount.backhaulLoadsCount = action.response.payload.totalResultCount;
    }
  }),
  fetchRouteInfoAction.initiateCase((state) => {
    state.isFetchingRouteInfo = true;
    state.routeInfo = undefined;
  }),
  fetchRouteInfoAction.completeCase((state, action) => {
    state.isFetchingRouteInfo = false;
    if (action.response.success) {
      state.routeInfo = action.response.payload;
    }
  }),
  setIsAcceptancePointExpansionPanelOpenAction.addCase((state, action) => {
    state.isAcceptancePointExpansionPanelOpen = action.data;
  }),
  setIsCapacityFinderExpansionPanelOpenAction.addCase((state, action) => {
    state.isCapacityFinderExpansionPanelOpen = action.data;
  }),
  setAcceptancePointCalculatorAction.addCase((state, action) => {
    state.acceptancePointCalculator = { ...state.acceptancePointCalculator, ...action.data };
  }),
  fetchThirdPartyLaneRatesAction.initiateCase((state) => {
    state.isCargoChiefGreenscreensRateLoading = true;
    state.isCargoChiefRateFound = true;
    state.isGreenscreensRateFound = true;
    state.greenscreensRate = undefined;
    state.cargoChiefRate = undefined;
  }),
  fetchThirdPartyLaneRatesAction.completeCase((state, action) => {
    state.isCargoChiefGreenscreensRateLoading = false;
    if (action.response.success && action.response.payload) {
      const greenscreensRate = find(action.response.payload, { source: GREENSSCREENS_SOURCE });
      const cargoChiefRate = find(action.response.payload, { source: CARGO_CHIEF_SOURCE });
      state.greenscreensRate = greenscreensRate;
      state.cargoChiefRate = {
        allInCost: cargoChiefRate?.cost,
        allInCostHigh: cargoChiefRate?.costHigh,
        allInCostLow: cargoChiefRate?.costLow,
        laneMiles: cargoChiefRate?.laneMiles,
      };
      state.isCargoChiefRateFound = cargoChiefRate?.isRateFound;
      state.isGreenscreensRateFound = greenscreensRate?.isRateFound;
    } else {
      state.isCargoChiefRateFound = false;
      state.isGreenscreensRateFound = false;
    }
  }),
  fetchLoadToTruckRatioAction.initiateCase((state) => {
    state.isLoadingLoadToTruckRatio = true;
  }),
  fetchLoadToTruckRatioAction.completeCase((state, action) => {
    state.isLoadingLoadToTruckRatio = false;
    if (action.response.success) {
      const payload = action.response.payload.individualResponses;
      const originInfo = find(payload, (result) => result.state === toAddress(state.searchForm.origin)?.state);
      const destInfo = find(payload, (result) => result.state === toAddress(state.searchForm.destination)?.state);

      state.ratioAtOrigin = originInfo?.isRatioFound ? formatNumberDecimals(originInfo.ratio, 1, 1) : undefined;
      state.ratioAtDestination = destInfo?.isRatioFound ? formatNumberDecimals(destInfo.ratio, 1, 1) : undefined;
    }
  }),
  fetchExpediteAllRateAction.initiateCase((state) => {
    state.isLoadingExpediteAllRate = true;
    state.errorFetchingExpediteAllRate = undefined;
  }),
  fetchExpediteAllRateAction.completeCase((state, action) => {
    state.isLoadingExpediteAllRate = false;
    state.errorFetchingExpediteAllRate = !action.response.success;
    if (action.response.success) {
      state.expediteAllRate = action.response.payload;
    } else {
      state.expediteAllRate = undefined;
    }
  }),
  fetchBackhaulsExpediteAllRateAction.initiateCase((state) => {
    state.isLoadingExpediteAllRateBackhaul = true;
  }),
  fetchBackhaulsExpediteAllRateAction.completeCase((state, action) => {
    state.isLoadingExpediteAllRateBackhaul = false;
    if (action.response.success) {
      state.backhaulsExpediteAllRate = action.response.payload;
    } else {
      state.backhaulsExpediteAllRate = undefined;
    }
  }),
]);

export const createMarketRatesEpic = (api: Api, isLiveEnvironment: boolean) => {
  const rateCheckClient = new RateCheckClient(api);
  const routeClient = new RouteClient(api);
  const loadClient = new LoadsClient(api, isLiveEnvironment);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchBackhaulsRateCheckAction.createEpic$(action$, rateCheckClient.fetchRateCheck$),
      fetchRouteInfoAction.createEpic$(action$, routeClient.getRouteMileage$),
      fetchPickupLoadsCountAction.createEpic$(action$, (searchRequest) => loadClient.fetchLoadCount$(searchRequest)),
      fetchAnywherePickupLoadsCountAction.createEpic$(action$, (searchRequest) =>
        loadClient.fetchLoadCount$(searchRequest)
      ),
      fetchBackhaulsLoadsCountAction.createEpic$(action$, (searchRequest) => loadClient.fetchLoadCount$(searchRequest)),
      fetchAnywhereBackhaulsLoadsCountAction.createEpic$(action$, (searchRequest) =>
        loadClient.fetchLoadCount$(searchRequest)
      ),
      fetchThirdPartyLaneRatesAction.createEpic$(action$, rateCheckClient.getThirdPartyLaneRates$),
      fetchLoadToTruckRatioAction.createEpic$(action$, rateCheckClient.getLoadToTruckRatio$),
      fetchExpediteAllRateAction.createEpic$(action$, rateCheckClient.getExpediteAllRate$),
      fetchBackhaulsExpediteAllRateAction.createEpic$(action$, rateCheckClient.getExpediteAllRate$)
    );
};

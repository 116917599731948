import { BaseClient } from '@common/client/BaseClient';
import { MimeType } from '@common/helper';
import { Document } from '@common/model';

interface MobileFile {
  uri: string;
  type: string;
}

export interface UploadDocumentData {
  folderType: string;
  folderId?: string;
  type: string;
  displayName: string;
  file: Blob | MobileFile;
  extension: string;
}

export class UploadFileClient extends BaseClient {
  uploadDocument$ = (uploadParams: UploadDocumentData, cancelGroup?: string) => {
    const formData = new FormData();
    if (isFileBlob(uploadParams.file)) {
      // eslint-disable-next-line
      // @ts-ignore: This line is unreachable for mobile, but tsc fails on it for mobile, because append has different declaration.
      formData.append('file', uploadParams.file, `${uploadParams.displayName}.${uploadParams.extension}`);
    } else {
      const file: MobileFile = uploadParams.file;
      formData.append('file', {
        ...file,
        name: `${uploadParams.displayName}.${uploadParams.extension}`,
      } as any);
    }

    return this.api.post$<Document>(
      '/documents',
      formData,
      {
        params: {
          folderType: uploadParams.folderType,
          folderId: uploadParams.folderId,
          type: uploadParams.type,
          displayName: uploadParams.displayName,
        },
        headers: { 'Content-Type': MimeType.formData },
      },
      { shouldPreventLoginRedirect: false, allowUnauthenticated: false, cancelGroup: cancelGroup }
    );
  };
}

const isFileBlob = (file: Blob | MobileFile): file is Blob => (file as MobileFile).uri === undefined;

enum Currency {
  NONE = 'None',
  CAD = 'CAD',
  USD = 'USD',
}

export interface FuelPrice {
  price?: number;
  tax?: number;
  currency?: Currency;
}

import React from 'react';

import styled from 'styled-components';

import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { defaultBorder } from '@component/expansionPanel/ExpansionPanelStyle';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { TextContentDetailDarkBold } from '@component/panels/companyDetails/CompanyDetailsRows';
import {
  CenteringContainer,
  Container,
  DataWrapper,
} from '@component/panels/truckLocator/carrierDetails/CarrierDetailsPanelStyles';
import {
  CarrierRatingDetails,
  CarrierSourceInfoButton,
  CarrierSourceLogo,
  StarRatingLabel,
} from '@page/dashboard/widgets/CarrierRatingUIHelper';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT } from '@util/Constants';
import { useSelector } from '@util/hooks';

import { default as RatingIcon } from './icon-rating.svg';

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledMainContainer = styled(StyledContainer)`
  border-bottom: ${defaultBorder};
  margin: 0 15px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

const Title = styled(TextContentDetailDarkBold)`
  font-size: ${FontSize.ModalTitle}px;
  display: block;
  margin-bottom: 5px;
`;

const NotRatedTitle = styled(TextContentDetailDarkBold)`
  font-size: ${FontSize.MediumTitle}px;
  display: block;
  margin: 10px 0;
`;

interface Props {
  handleChange: () => void;
  isOpened: boolean;
  dotNumber: string;
}

export const CarrierRatingExpansionPanel: React.FC<Props> = (props) => {
  const carrierRating = useSelector((state) => state.companyState.carrierRating);
  const isLoading = useSelector((state) => state.companyState.isLoadingCarrierRating);

  const isRatingAvailable = props.dotNumber && carrierRating;
  const isRated = carrierRating && carrierRating.reviewCount > 0;
  const displayTitle = isRatingAvailable && isRated && !isLoading;
  return (
    <ExpansionPanel
      id="rating_info"
      icon={RatingIcon}
      titleText={`${t(T.common_ratings_title)}${displayTitle ? `: ${carrierRating?.starRating}` : ''}`}
      isOpened={props.isOpened}
      handleClick={props.handleChange}
      isBlocked={!isRatingAvailable && !isLoading}
      subtitleText={generateSubtitleText(!!isRatingAvailable, isLoading, carrierRating?.reviewCount)}
      panelTitleStyle={{ height: COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT }}
    >
      <DataWrapper>
        <>
          {isLoading ? (
            <CenteringContainer style={{ alignItems: 'center' }}>
              <LoadingSpinner />
            </CenteringContainer>
          ) : null}
          {!isLoading && props.dotNumber && carrierRating ? (
            <>
              <StyledMainContainer isWithoutTopLine={true}>
                <Title id="name">{carrierRating.name}</Title>
                {carrierRating.reviewCount === 0 ? (
                  <NotRatedTitle id="not_rated">{t(T.common_ratings_notRated)}</NotRatedTitle>
                ) : (
                  <StarRatingLabel starRating={carrierRating.starRating} reviewCount={carrierRating.reviewCount} />
                )}
              </StyledMainContainer>
              <StyledContainer isWithoutTopLine={true} style={{ margin: '15px 0' }}>
                <CarrierRatingDetails
                  safetyRating={carrierRating.safetyRating}
                  carrierOperation={carrierRating.operation}
                />
                <StyledButtonContainer>
                  <CarrierSourceInfoButton carrierSourceUrl={carrierRating.profileUrl} />
                </StyledButtonContainer>
                <CarrierSourceLogo />
              </StyledContainer>
            </>
          ) : null}
        </>
      </DataWrapper>
    </ExpansionPanel>
  );
};

const generateSubtitleText = (isRatingAvailable: boolean, isLoading?: boolean, reviewCount?: number) => {
  if (isLoading) return t(T.findLoads_loadDetails_loading);

  if (!isRatingAvailable) return t(T.findLoads_loadDetails_notProvided);

  if (reviewCount) {
    return reviewCount > 1
      ? t(T.common_ratings_basedOnReviews, { count: reviewCount })
      : t(T.common_ratings_basedOnReview);
  }

  return reviewCount === 0 ? t(T.common_ratings_notRated) : t(T.findLoads_loadDetails_notProvided);
};

import React from 'react';

import Delay from 'react-delay';
import styled from 'styled-components';

import { InputAdornment } from '@material-ui/core';
import { TextFieldProps as MUITextFieldProps } from '@material-ui/core/TextField';
import { default as CloseIcon } from '@material-ui/icons/Close';
import { default as DoneIcon } from '@material-ui/icons/Done';
import { default as ErrorIcon } from '@material-ui/icons/Error';

import { ConversationTextField } from '@component/input/textField/ConversationTextField';
import {
  MarginlessStyledTextField,
  StartAdornmentIconTextField,
  StyledProgress,
  StyledTextfield,
} from '@component/input/textField/StyledTextField';
import { Text, TextStyle } from '@component/text';
import { Color } from '@style/Color';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { isEnterOrSpaceKeyPressed } from '@util/helper/KeyboardHelper';

const DEFAULT_TEXT_FIELD_TYPE = 'text';
const PASSWORD_TEXT_FIELD_TYPE = 'password';

interface CustomProps {
  id: string;
  shrink?: boolean;
  className?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  done?: boolean;
  readOnly?: boolean;
  dense?: boolean;
  loading?: boolean;
  noMargin?: boolean;
  isVariableHeight?: boolean;
  shrinkOnEmpty?: boolean;
  autoTextSelectionOnFocus?: boolean;
  startAdornmentText?: string;
  startAdornmentIcon?: JSX.Element;
  endAdornmentIcon?: JSX.Element;
  onEndAdormentPress?: (event?: React.MouseEvent<HTMLDivElement>) => void;
  displayCharacterCounter?: boolean;
  focusOnLoad?: boolean;
  onClear?: () => void;
  isWithoutMargin?: boolean;
  shouldShowErrorAdornment?: boolean;
  isErrorMessageMultiline?: boolean;
  isConversation?: boolean;
  shouldFireClickActionWithEnterOrSpace?: boolean;
}
export type TextFieldProps = CustomProps & MUITextFieldProps;

export interface TextFieldState {
  isFocused: boolean;
  shrink: boolean;
  isShowingPassword: boolean;
}

const StartAdornment = styled(InputAdornment)`
  && {
    height: 56px !important;
    margin: 0 !important;
    margin-left: 12px !important;
    padding-bottom: 8px;
    max-height: 56px;
    width: 12px !important;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
  }
`;

const StartAdornmentIcon = withTheme()(styled(InputAdornment)`
  && {
    height: 56px !important;
    margin: 0 !important;
    margin-left: 12px !important;
    padding-bottom: 18px;
    max-height: 56px;
    width: 24px !important;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;

    > svg {
      color: ${(props: TextFieldProps & ThemeProps) => props.theme.palette.background.dark}!important;
    }
  }
`);

const DoneInputAdornment = styled(InputAdornment)`
  && {
    margin-right: 16px;
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const ErrorInputAdornment = styled(InputAdornment)`
  && {
    margin-right: 12px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

const ShowPasswordAdornment = styled(InputAdornment)`
  && {
    width: 38px !important;
    height: 56px !important;
    margin: 0 !important;
    max-height: 56px;
  }
`;

const ClearTextAdornment = styled(InputAdornment)`
  && {
    margin-right: 16px;
    margin-bottom: 18px;
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const ShowPasswordButton = styled.div`
  width: 29px !important;
  height: 13px !important;
  position: absolute;
  right: 10px;
  bottom: 14px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  > span {
    width: 100%;
    text-align: right;
  }
`;

const LoadingInputArdornment = styled(InputAdornment)`
  && {
    margin-right: 12px;
    div {
      height: 20px !important;
      width: 20px !important;
    }
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

const CharacterCounterPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CharacterCounter = styled.div`
  text-align: right;
  width: 100%;
  transform-origin: right bottom;
  transform: scale(0.75);
`;

class CustomTextField extends React.Component<TextFieldProps, TextFieldState> {
  state = {
    isFocused: this.props.focusOnLoad ? this.props.focusOnLoad : false,
    shrink: this.props.value !== '',
    isShowingPassword: false,
  };

  private onClear = (e: React.MouseEvent<HTMLInputElement>) => {
    const input = e.currentTarget.previousElementSibling as HTMLInputElement;
    this.setState({ shrink: true });
    if (this.props.onClear) {
      this.props.onClear();
    }

    if (input != null && input.select) {
      input.select();
    }
    e.preventDefault();
  };

  private errorClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const input = e.currentTarget.previousElementSibling as HTMLInputElement;

    if (input != null && input.select) {
      input.select();
    }
  };

  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }

    if (this.props.autoTextSelectionOnFocus) {
      event.target.select();
    }
    this.setState({ shrink: true, isFocused: true });
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.growLabel();
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  private growLabel() {
    this.setState((prevState: TextFieldState, props: TextFieldProps): TextFieldState | null => {
      if (!props.shrinkOnEmpty) {
        return { ...prevState, shrink: false, isFocused: false };
      }
      return null;
    });
  }

  toggleShowPassword = () => {
    this.setState((prevState: TextFieldState, props: TextFieldProps): TextFieldState | null => {
      if (isPasswordField(props)) {
        return {
          ...prevState,
          isShowingPassword: !prevState.isShowingPassword,
        };
      }

      return null;
    });
  };

  hotjarWhitelist = (props: TextFieldProps) => {
    if (!isPasswordField(props)) {
      return { 'data-hj-whitelist': '' };
    }
    return undefined;
  };

  getTextFieldType = () => {
    return isPasswordField(this.props)
      ? this.state.isShowingPassword
        ? DEFAULT_TEXT_FIELD_TYPE
        : PASSWORD_TEXT_FIELD_TYPE
      : DEFAULT_TEXT_FIELD_TYPE;
  };

  getInputAdornment = () => {
    if (this.props.endAdornmentIcon) {
      return (
        <ClearTextAdornment position="end" onClick={this.props.onEndAdormentPress} style={{ cursor: 'pointer' }}>
          {this.props.endAdornmentIcon}
        </ClearTextAdornment>
      );
    }
    if (this.props.error && this.props.shouldShowErrorAdornment) {
      return (
        <ErrorInputAdornment position="end" onClick={this.errorClick}>
          <ErrorIcon id={this.props.id + '_error_icon'} />
        </ErrorInputAdornment>
      );
    } else if (this.props.done) {
      return (
        <DoneInputAdornment position="end">
          <DoneIcon id={this.props.id + '_done_icon'} />
        </DoneInputAdornment>
      );
    } else if (isPasswordField(this.props) && this.props.value !== '') {
      return (
        <ShowPasswordAdornment position="end">
          <ShowPasswordButton id={this.props.id + '_password_toggle'} onClick={this.toggleShowPassword}>
            <Text id={this.props.id + '_password_toggle_text'} textStyle={TextStyle.InputFieldShow}>
              {this.state.isShowingPassword ? t(T.textfield_Hide_Password) : t(T.textfield_Show_Password)}
            </Text>
          </ShowPasswordButton>
        </ShowPasswordAdornment>
      );
    } else if (this.props.loading) {
      return (
        <LoadingInputArdornment position="end">
          <Delay wait={250}>
            <StyledProgress id={this.props.id + '_progress_icon'} />
          </Delay>
        </LoadingInputArdornment>
      );
    } else if (this.props.onClear) {
      const isXVisible = this.props.value !== '';
      return (
        <ClearTextAdornment
          position="end"
          onMouseDown={isXVisible ? this.onClear : undefined}
          style={{ cursor: isXVisible ? 'pointer' : undefined }}
        >
          <CloseIcon id={this.props.id + '_close_icon'} style={{ color: isXVisible ? Color.GRAY_14 : 'transparent' }} />
        </ClearTextAdornment>
      );
    }
    return null;
  };

  getStartAdornment = () => {
    if (this.props.startAdornmentText && this.props.value) {
      return (
        <StartAdornment id={this.props.id + '_start_adornment_text'} position="start">
          {this.props.startAdornmentText}
        </StartAdornment>
      );
    }

    if (this.props.startAdornmentIcon) {
      return (
        <StartAdornmentIcon id={this.props.id + '_start_adornment_icon'} position="start">
          {this.props.startAdornmentIcon}
        </StartAdornmentIcon>
      );
    }

    return null;
  };

  componentDidUpdate() {
    const isValueEmpty = this.props.value === '';
    if (!isValueEmpty && !this.state.shrink) {
      this.setState({ shrink: true });
    } else if (isValueEmpty && this.state.shrink && !this.state.isFocused) {
      this.setState({ shrink: false });
    }
  }

  handleKeyDown = (e: React.SyntheticEvent<HTMLElement>) => {
    if (
      this.props.shouldFireClickActionWithEnterOrSpace &&
      this.props.readOnly &&
      isEnterOrSpaceKeyPressed(e as React.KeyboardEvent<HTMLDivElement>)
    ) {
      this.props.onClick?.(e as React.MouseEvent<HTMLDivElement>);
    }
  };

  render() {
    const {
      startAdornmentIcon,
      type,
      noMargin,
      displayCharacterCounter,
      done,
      loading,
      dense,
      onFocus,
      onBlur,
      onClear,
      autoTextSelectionOnFocus,
      focusOnLoad,
      value,
      endAdornmentIcon,
      isConversation,
      ...textFieldProps
    } = this.props;

    const { className } = this.props;

    // @FIXME requires a refactor to properly enforce type requirements and allow for passing custom text fields from parent
    const CustomStyledTextField = isConversation
      ? ConversationTextField
      : noMargin
        ? MarginlessStyledTextField
        : startAdornmentIcon
          ? StartAdornmentIconTextField
          : StyledTextfield;

    const customStyledTextField = (
      <CustomStyledTextField
        InputLabelProps={{ shrink: this.state.shrink, focused: this.state.isFocused }}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        autoComplete={'off'}
        className={className}
        type={this.getTextFieldType()}
        InputProps={{
          endAdornment: this.getInputAdornment(),
          startAdornment: this.getStartAdornment(),
        }}
        inputProps={{ ...this.props.inputProps, ...this.hotjarWhitelist(this.props), readOnly: this.props.readOnly }}
        dense={dense ? 1 : 0} // Workaround to eliminate warning for adding unknown prop to DOM element (caused by styled-components)
        // NOTE: https://github.com/mui-org/material-ui/issues/14949
        value={value ?? ''}
        onKeyDown={this.handleKeyDown}
        {...textFieldProps}
      />
    );
    if (displayCharacterCounter && textFieldProps.inputProps && textFieldProps.inputProps.maxLength) {
      return (
        <>
          <CharacterCounterPanel>
            {customStyledTextField}
            <CharacterCounter>
              <Text id={this.props.id + '_counter'} textStyle={TextStyle.InputFieldLabel}>
                {(this.props.value as string).length}/{textFieldProps.inputProps.maxLength}
              </Text>
            </CharacterCounter>
          </CharacterCounterPanel>
        </>
      );
    }

    return customStyledTextField;
  }
}

const isPasswordField = (props: TextFieldProps) => props.type === PASSWORD_TEXT_FIELD_TYPE;

export const TextField = CustomTextField;

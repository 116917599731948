import { filter, map } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { PostTruckClient } from '@common/client/PostTruckClient';
import {
  AvailabilityTypes,
  EquipmentType,
  INITIAL_TRUCK_AVAILABILITY_LIMIT,
  LoadLocation,
  LoadSize,
  MAX_PLUS_TRUCK_LENGTH,
  MAX_PLUS_TRUCK_WEIGHT,
  PostsFilter,
  PostTruckAddress,
  RateCheck,
  RateCheckRequest,
  Statuses,
  TRUCK_AVAILABILITY_LIMIT_APPENDING,
  TruckAvailabilitiesPayload,
  TruckAvailability,
  TruckAvailabilityCounts,
  TruckAvailabilityDeleteRequest,
  TruckAvailabilityResponse,
  Types,
} from '@common/model';
import { createAction, createApiAction, EmptyResponse } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const getTruckAvailabilityCountAction = createApiAction<undefined, TruckAvailabilityCounts>(
  'GET_TRUCK_AVAILABILITY_COUNT'
);
const getTruckAvailabilitiesAction = createApiAction<PostsFilter, TruckAvailabilitiesPayload>(
  'GET_TRUCK_AVAILABILITIES'
);
const getMoreTruckAvailabilitiesAction = createApiAction<PostsFilter, TruckAvailabilitiesPayload>(
  'GET_MORE_TRUCK_AVAILABILITIES'
);
const addNewTruckAvailabilityAction = createApiAction<
  { data: TruckAvailabilityResponse; override?: boolean },
  TruckAvailability
>('ADD_NEW_TRUCK_AVAILABILITY');
const updateTruckAvailabilityAction = createApiAction<
  { update: Partial<TruckAvailabilityResponse>; id: number; override?: boolean },
  undefined
>('UPDATE_NEW_TRUCK_AVAILABILITY');

const deleteTruckAvailabilityAction = createApiAction<{ id: number; screenType: PostsFilter }, undefined>(
  'DELETE_TRUCK_AVAILABILITY'
);
const deleteAllExpiredTrucksAction = createApiAction<number[], undefined>('BULK_DELETE_TRUCK_AVAILABILITIES');
const deleteAllTrucksAction = createApiAction<TruckAvailabilityDeleteRequest, EmptyResponse>(
  'DELETE_TRUCK_AVAILABILITIES'
);

const toggleClearAllAction = createApiAction<undefined>('TOGGLE_CLEAR_ALL');
const setLastRealTimeTruckPostedAction = createAction<LastPostedRealTruck>('SET_LAST_REAL_TIME_TRUCK_POSTED');

const fetchTotalMileageAction = createApiAction<MileageRequest, MileageResponse>('POST_TRUCK_FETCH_MILEAGE');

const fetchRateCheckAction = createApiAction<RateCheckRequest, RateCheck>('POST_TRUCK_FETCH_RATE_CHECK_INFO');

export const getTruckAvailabilityCount = () => getTruckAvailabilityCountAction.fetchAction(undefined);
export const getTruckAvailabilities = (type?: PostsFilter) =>
  getTruckAvailabilitiesAction.fetchAction(type ?? PostsFilter.ScheduledPosts);
export const getMoreTruckAvailabilities = (type?: PostsFilter) =>
  getMoreTruckAvailabilitiesAction.fetchAction(type ?? PostsFilter.ScheduledPosts);
export const addNewTruckAvailability = (availability: TruckAvailabilityResponse, override?: boolean) =>
  addNewTruckAvailabilityAction.fetchAction({ data: availability, override: override });
export const updateTruckAvailability = (
  availability: Partial<TruckAvailabilityResponse>,
  id: number,
  override?: boolean
) => updateTruckAvailabilityAction.fetchAction({ update: availability, id: id, override: override });
export const deleteTruckAvailability = (id: number, screenType: PostsFilter) =>
  deleteTruckAvailabilityAction.fetchAction({ id: id, screenType: screenType });
export const deleteAllExpiredTrucks = (ids: number[]) => deleteAllExpiredTrucksAction.fetchAction(ids);
export const deleteAllTrucks = (request: TruckAvailabilityDeleteRequest) => deleteAllTrucksAction.fetchAction(request);
export const toggleClearAll = () => toggleClearAllAction.fetchAction(undefined);
export const setLastRealTimeTruckPosted = (truck: LastPostedRealTruck) =>
  setLastRealTimeTruckPostedAction.action(truck);

export const fetchTotalMileage = (stops: PostTruckAddress[]) => fetchTotalMileageAction.fetchAction({ stops: stops });

export const fetchRateCheck = fetchRateCheckAction.fetchAction;

interface PartialState {
  postTruck: PostTruckState;
}

interface LastPostedRealTruck {
  equipmentType: EquipmentType | undefined;
  displayName: string | undefined;
  weight: number;
  length: number;
  truckId: string | undefined;
  size: LoadSize;
  anonymous: boolean;
  minRate?: string | null;
  dropoff: LoadLocation | undefined;
}

export interface PostTruckState {
  truckCount: {
    isLoadingCount: boolean;
    wasFetchedSuccessfully: boolean;
    count: TruckAvailabilityCounts;
  };
  truckAvailabilities: {
    isLoading: boolean;
    isLoadingMore: boolean;
    wasFetched: boolean;
    currentTrucks: TruckAvailability[];
    currentAvailabilityId?: number;
    currentFilter?: PostsFilter;
    paginationLimit?: number;
    paginationToken?: string;
    hasMore?: boolean;
  };
  updateAvailability: {
    isLoading: boolean;
    wasUpdated: boolean;
    updatedAvailability?: Partial<TruckAvailabilityResponse>;
    httpError?: number;
  };
  createAvailability: {
    isLoading: boolean;
    wasUpdated: boolean;
    httpError?: number;
    errorMessage?: string;
  };
  deleteAvailability: {
    isLoading: boolean;
    wasDeleted: boolean;
  };
  bulkDelete: {
    isLoading: boolean;
    wereDeleted: boolean;
    shouldInitiateDelete: boolean;
  };
  pcMiler: {
    totalPostMileage?: number;
    mileageHttpError?: number;
    isLoading: boolean;
  };
  rateCheck: {
    avgRateCheck?: number;
    rateCheckHttpError?: number;
    isLoading: boolean;
  };
  lastRealTimeTruckPosted: LastPostedRealTruck | undefined;
}

const postTruckInitialState: PostTruckState = {
  truckCount: {
    isLoadingCount: false,
    wasFetchedSuccessfully: false,
    count: { realTimeCount: 0, scheduledCount: 0, expiredCount: 0, total: 0 },
  },
  truckAvailabilities: {
    currentTrucks: [],
    isLoading: false,
    isLoadingMore: false,
    wasFetched: false,
  },
  updateAvailability: {
    isLoading: false,
    wasUpdated: false,
  },
  createAvailability: {
    isLoading: false,
    wasUpdated: false,
  },
  deleteAvailability: {
    isLoading: false,
    wasDeleted: false,
  },
  bulkDelete: {
    isLoading: false,
    wereDeleted: false,
    shouldInitiateDelete: false,
  },
  pcMiler: {
    totalPostMileage: undefined,
    mileageHttpError: undefined,
    isLoading: false,
  },
  rateCheck: {
    avgRateCheck: undefined,
    rateCheckHttpError: undefined,
    isLoading: false,
  },
  lastRealTimeTruckPosted: undefined,
};

export interface MileageRequest {
  stops: PostTruckAddress[];
}

export interface MileageResponse {
  mileage?: number;
}

export const postTruckReducer = createMergedReducer(postTruckInitialState, [
  getTruckAvailabilityCountAction.initiateCase((state) => {
    state.truckCount.isLoadingCount = true;
    state.truckCount.wasFetchedSuccessfully = false;
  }),
  getTruckAvailabilityCountAction.completeCase((state, action) => {
    state.truckCount.isLoadingCount = false;
    if (action.response.success) {
      state.truckCount.count = action.response.payload;
      const count = state.truckCount.count;
      state.truckCount.count.total = count.expiredCount + count.realTimeCount + count.scheduledCount;
      state.truckCount.wasFetchedSuccessfully = true;
    } else {
      state.truckCount.wasFetchedSuccessfully = false;
    }
  }),
  getTruckAvailabilitiesAction.initiateCase((state, action) => {
    state.truckAvailabilities.isLoading = true;
    state.truckAvailabilities.wasFetched = false;
    state.truckAvailabilities.currentFilter = action.data;
    state.truckAvailabilities.paginationLimit = INITIAL_TRUCK_AVAILABILITY_LIMIT;
    state.truckAvailabilities.paginationToken = undefined;
  }),
  getTruckAvailabilitiesAction.completeCase((state, action) => {
    if (action.response.success) {
      state.truckAvailabilities.currentTrucks = action.response.payload.items;
      state.truckAvailabilities.wasFetched = true;
      state.truckAvailabilities.paginationLimit = TRUCK_AVAILABILITY_LIMIT_APPENDING;
      state.truckAvailabilities.paginationToken = action.response.payload.paginationToken;
      state.truckAvailabilities.hasMore = action.response.payload.hasMore;
    } else {
      state.truckAvailabilities.wasFetched = false;
    }
    state.truckAvailabilities.isLoading = false;
  }),
  getMoreTruckAvailabilitiesAction.initiateCase((state) => {
    state.truckAvailabilities.isLoadingMore = true;
  }),
  getMoreTruckAvailabilitiesAction.completeCase((state, action) => {
    if (action.response.success) {
      state.truckAvailabilities.currentTrucks = [
        ...state.truckAvailabilities.currentTrucks,
        ...action.response.payload.items,
      ];
      state.truckAvailabilities.wasFetched = true;
      state.truckAvailabilities.paginationLimit = TRUCK_AVAILABILITY_LIMIT_APPENDING;
      state.truckAvailabilities.paginationToken = action.response.payload.paginationToken;
      state.truckAvailabilities.hasMore = action.response.payload.hasMore;
    } else {
      state.truckAvailabilities.wasFetched = false;
    }
    state.truckAvailabilities.isLoadingMore = false;
  }),
  addNewTruckAvailabilityAction.initiateCase((state) => {
    state.createAvailability.isLoading = true;
    state.createAvailability.wasUpdated = false;
  }),
  addNewTruckAvailabilityAction.completeCase((state, action) => {
    state.createAvailability.isLoading = false;
    if (action.response.success) {
      state.createAvailability.wasUpdated = true;
    } else {
      state.createAvailability.wasUpdated = false;
      state.createAvailability.httpError = action.response.error.httpStatus;
      state.createAvailability.errorMessage = action.response.error.message;
    }
  }),
  updateTruckAvailabilityAction.initiateCase((state) => {
    state.updateAvailability.isLoading = true;
    state.updateAvailability.wasUpdated = false;
  }),
  updateTruckAvailabilityAction.completeCase((state, action) => {
    state.updateAvailability.isLoading = false;
    if (action.response.success) {
      state.updateAvailability.wasUpdated = true;
    } else {
      state.updateAvailability.wasUpdated = false;
      state.updateAvailability.httpError = action.response.error.httpStatus;
    }
  }),
  deleteTruckAvailabilityAction.initiateCase((state, action) => {
    state.deleteAvailability.isLoading = true;
    state.deleteAvailability.wasDeleted = false;
    state.truckAvailabilities.currentAvailabilityId = action.data.id;
    state.truckAvailabilities.currentFilter = action.data.screenType;
  }),
  deleteTruckAvailabilityAction.completeCase((state, action) => {
    state.deleteAvailability.isLoading = false;
    const currentTrucks = state.truckAvailabilities.currentTrucks;
    if (action.response.success) {
      state.truckAvailabilities.currentTrucks = filter(
        currentTrucks,
        (currentTruck) => currentTruck.id !== state.truckAvailabilities.currentAvailabilityId
      );
      state.truckCount.count = updateCountAfterDelete(state.truckCount.count, state.truckAvailabilities.currentFilter);
      state.deleteAvailability.wasDeleted = true;
    } else {
      state.deleteAvailability.wasDeleted = false;
    }
  }),
  deleteAllExpiredTrucksAction.initiateCase((state) => {
    state.bulkDelete.isLoading = true;
    state.bulkDelete.wereDeleted = false;
  }),
  deleteAllExpiredTrucksAction.completeCase((state, action) => {
    state.bulkDelete.isLoading = false;
    if (action.response.success) {
      state.bulkDelete.wereDeleted = true;
    } else {
      state.bulkDelete.wereDeleted = false;
    }
  }),
  deleteAllTrucksAction.initiateCase((state) => {
    state.bulkDelete.isLoading = true;
    state.bulkDelete.wereDeleted = false;
  }),
  deleteAllTrucksAction.completeCase((state, action) => {
    state.bulkDelete.isLoading = false;
    if (action.response.success) {
      state.bulkDelete.wereDeleted = true;
    } else {
      state.bulkDelete.wereDeleted = false;
    }
  }),
  toggleClearAllAction.initiateCase((state) => {
    state.bulkDelete.shouldInitiateDelete = !state.bulkDelete.shouldInitiateDelete;
  }),
  setLastRealTimeTruckPostedAction.addCase((state, action) => {
    state.lastRealTimeTruckPosted = action.data;
  }),
  fetchTotalMileageAction.initiateCase((state) => {
    state.pcMiler.totalPostMileage = undefined;
    state.pcMiler.mileageHttpError = undefined;
    state.pcMiler.isLoading = true;
  }),
  fetchTotalMileageAction.completeCase((state, action) => {
    state.pcMiler.isLoading = false;
    if (action.response.success) {
      state.pcMiler.totalPostMileage = action.response.payload.mileage;
    } else if (action.response.error) {
      state.pcMiler.mileageHttpError = action.response.error.httpStatus;
    }
  }),
  fetchRateCheckAction.initiateCase((state) => {
    state.rateCheck.avgRateCheck = undefined;
    state.rateCheck.rateCheckHttpError = undefined;
    state.rateCheck.isLoading = true;
  }),
  fetchRateCheckAction.completeCase((state, action) => {
    state.rateCheck.isLoading = false;
    if (action.response.success) {
      state.rateCheck.avgRateCheck = action.response.payload.avgRatePerMile;
    } else if (action.response.error) {
      state.rateCheck.rateCheckHttpError = action.response.error.httpStatus;
    }
  }),
]);

const updateCountAfterDelete = (count: TruckAvailabilityCounts, filterType?: PostsFilter): TruckAvailabilityCounts => {
  if (filterType === PostsFilter.RealTimePosts) {
    return { ...count, realTimeCount: count.realTimeCount - 1, total: count.total - 1 };
  }
  if (filterType === PostsFilter.ExpiredPosts) {
    return { ...count, expiredCount: count.expiredCount - 1, total: count.total - 1 };
  }
  return { ...count, scheduledCount: count.scheduledCount - 1, total: count.total - 1 };
};

const getTruckFetchingData = (postsFilter: PostsFilter): AvailabilityTypes => {
  switch (postsFilter) {
    case PostsFilter.RealTimePosts:
      return { types: Types.Dynamic };
    case PostsFilter.ExpiredPosts:
      return {
        types: Types.Static,
        statuses: Statuses.Expired,
      };
    default:
      return {
        types: Types.Static,
        statuses: Statuses.Active,
      };
  }
};

export const getAvailabilityIdToDelete = (availabilities: TruckAvailability[]): number[] => {
  return map(availabilities, (availability) => availability.id);
};

const getTruckAvailabilities$ =
  (client: PostTruckClient, state$: StateObservable<PartialState>) => (data: PostsFilter) => {
    const { paginationLimit, paginationToken } = state$.value.postTruck.truckAvailabilities;
    const truckData = getTruckFetchingData(data);
    return client.getTruckAvailabilities(truckData.types, truckData.statuses, paginationLimit, paginationToken);
  };

export const createPostTruckEpic = (api: Api) => {
  const client = new PostTruckClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<PartialState>) =>
    merge$(
      getTruckAvailabilityCountAction.createEpic$(action$, client.getTruckAvailabilityCount),
      getTruckAvailabilitiesAction.createEpic$(action$, getTruckAvailabilities$(client, state$)),
      getMoreTruckAvailabilitiesAction.createEpic$(action$, getTruckAvailabilities$(client, state$)),
      addNewTruckAvailabilityAction.createEpic$(action$, (data) =>
        client.addTruckAvailabilities(data.data, true, data.override)
      ),
      updateTruckAvailabilityAction.createEpic$(action$, (data) =>
        client.updateTruckAvailabilities(data.update, data.id, data.override)
      ),
      deleteTruckAvailabilityAction.createEpic$(action$, (data) => client.deleteTruckAvailability(data.id)),
      deleteAllExpiredTrucksAction.createEpic$(action$, (data) => client.bulkDeleteAvailability(data)),
      deleteAllTrucksAction.createEpic$(action$, (data) => client.deleteTruckAvailabilities(data)),
      fetchTotalMileageAction.createEpic$(action$, client.fetchTotalMileage$),
      fetchRateCheckAction.createEpic$(action$, (data) => client.fetchRateCheck$(data))
    );
};

export const formatTruckAvailability = (truckAvailability: Partial<TruckAvailabilityResponse>) => ({
  ...truckAvailability,
  weight: truckAvailability.weight === MAX_PLUS_TRUCK_WEIGHT ? null : truckAvailability.weight,
  length: truckAvailability.length === MAX_PLUS_TRUCK_LENGTH ? null : truckAvailability.length,
});

import { Slider, SliderItem } from 'react-compound-slider';
import styled, { css } from 'styled-components';

import { ThemeProps, withTheme } from '@style/WithTheme';

export const MainWrapper = styled.div`
  display: flex;
  padding: 0 15px;
  width: calc(100% - 30px);
  height: 50px;
`;

export const CustomSliderWrapper = styled(Slider)`
  && {
    width: 100%;
    margin-top: 23px;
    position: relative;
  }
`;

export const SliderRail = withTheme()(styled.div`
  position: absolute;
  width: inherit;
  background: ${(props: ThemeProps) => props.theme.palette.background.smoke};
  border-radius: 1px;
  height: 4px;
  cursor: pointer;
`);

type RailHandleProps = { disabled: boolean; handle: SliderItem } & ThemeProps;

export const RailHandleWithCustomSVG = withTheme()(styled.div`
  left: ${(props: RailHandleProps) => props.handle.percent}%;
  position: absolute;
  margin-top: -10px;
  margin-left: ${(props: RailHandleProps) => (props.handle.percent === 0 ? '-4px' : '-14px')};
  z-index: 2;
  cursor: pointer;
  ${(props: RailHandleProps) =>
    !props.disabled &&
    css`
      &:after {
        display: block;
        content: '';
        height: 18px;
        width: 16px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.2;
        border: 0 solid ${props.theme.palette.primary.main};
        transition: all 0.1s;
      }
    `};

  &:active {
    &:after {
      border-width: 12px;
      top: -10px;
      left: -12px;
    }
  }
`);

export const RailHandle = withTheme()(styled.div`
  left: ${(props: RailHandleProps) => props.handle.percent}%;
  position: absolute;
  margin-left: -12px;
  margin-top: -11px;
  z-index: 2;
  width: 23px;
  height: 23px;
  border: 1px solid
    ${(props: RailHandleProps) =>
      props.disabled ? props.theme.palette.background.smoke : props.theme.palette.primary.main};
  cursor: pointer;
  border-radius: 50%;
  background-color: ${(props: RailHandleProps) => props.theme.palette.background.white};

  ${(props: RailHandleProps) =>
    !props.disabled &&
    css`
      &:after {
        display: block;
        content: '';
        height: 23px;
        width: 23px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.2;
        border: 0 solid ${props.theme.palette.primary.main};
        transition: all 0.1s;
      }
    `};

  &:active {
    &:after {
      border-width: 12px;
      top: -12px;
      left: -12px;
    }
  }
`);

export const RailTrack = withTheme()(styled.div`
  left: ${(props: any) => props.source.percent}%;
  position: absolute;
  z-index: 1;
  width: ${(props: any) => props.target.percent - props.source.percent}%;
  height: 4px;
  cursor: pointer;
  background-color: ${(props: any) =>
    props.disabled ? props.theme.palette.background.smoke : props.theme.palette.primary.main};
`);

import {
  BrokerLoadMetricsHistoryResponse,
  BrokerLoadMetricsResponse,
  CarrierLoadMetricsHistoryResponse,
  CarrierLoadMetricsResponse,
  LoadMetricsHistoryRequest,
} from '@/model/LoadMetrics';
import { BaseClient } from '@common/client/BaseClient';

export class LoadMetricsClient extends BaseClient {
  fetchBrokerBusiestDays$ = (fields: string = 'all') =>
    this.api.get$<BrokerLoadMetricsResponse>('/loads/metrics/broker/busiest-days', {
      fields: fields,
    });
  fetchCarrierBusiestDays$ = (fields: string = 'all') =>
    this.api.get$<CarrierLoadMetricsResponse>('/loads/metrics/carrier/busiest-days', {
      fields: fields,
    });

  fetchBrokerLoadMetricsHistory$ = (loadMetricsRequest: LoadMetricsHistoryRequest) =>
    this.api.get$<BrokerLoadMetricsHistoryResponse>('/loads/metrics/broker/history', loadMetricsRequest);
  fetchCarrierLoadMetricsHistory$ = (loadMetricsRequest: LoadMetricsHistoryRequest) =>
    this.api.get$<CarrierLoadMetricsHistoryResponse>('/loads/metrics/carrier/history', loadMetricsRequest);
}

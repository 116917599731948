import { BaseClient } from '@common/client/BaseClient';
import { AppConfig } from '@util/AppConfig';

const FEED_BASE_PATH = AppConfig.LBConfig.FeedBaseUrl;
const DASHBOARD_FEED_BASE_PATH = AppConfig.LBConfig.DashboardFeedBaseUrl;
const VIDEO_FEED_BASE_PATH = AppConfig.LBConfig.VideoFeedBaseUrl;
const PROMO_BASE_PATH = AppConfig.LBConfig.PromoBaseUrl;
const HOSTING_ROOT = AppConfig.LBConfig.AzureHostingRootUrl;
const BROKER_FEED_PATH = `${FEED_BASE_PATH}feed-brokers.html`;
const CARRIER_FEED_PATH = `${FEED_BASE_PATH}feed-carriers.html`;
const DASHBOARD_FEED_CARRIER_PATH = `${DASHBOARD_FEED_BASE_PATH}dashboard-feed-carriers.html`;
const DASHBOARD_FEED_BROKER_PATH = `${DASHBOARD_FEED_BASE_PATH}dashboard-feed-brokers.html`;

export class FeedClient extends BaseClient {
  getFeed$ = (isBroker: boolean) => this.api.get$<string>(isBroker ? BROKER_FEED_PATH : CARRIER_FEED_PATH, {});
  getDashboardFeed$ = (isBroker: boolean) =>
    this.api.get$<string>(isBroker ? DASHBOARD_FEED_BROKER_PATH : DASHBOARD_FEED_CARRIER_PATH, {});
  getVideoList$ = (videoFileName: string) => this.api.get$<string>(`${VIDEO_FEED_BASE_PATH}${videoFileName}`, {});
  getPromo$ = (promoFileName: string) => this.api.get$<string>(`${PROMO_BASE_PATH}${promoFileName}`, {});
  getMappingFile$ = () =>
    this.api.get$<string>(
      `${HOSTING_ROOT}/ext-mapping.csv`,
      {},
      { headers: { Accept: 'application/json, text/plain, */*' } }
    );
}

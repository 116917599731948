import React, { ReactElement, ReactNode } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { openMenu } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { AppLogo } from '@component/menu/AppLogo';
import { default as IconMenu } from '@component/menu/icon/icon-menu.svg?react';
import { getHeaderLogoSize } from '@component/menu/MenuHeader';
import { AppLogoContainer, MENU_ICON_SIZE, SideMenuIconButton } from '@component/menu/menuStyles';
import { Color } from '@style/Color';
import { ElementSize } from '@style/StyleConstants';
import { useWindowWidth } from '@util/hooks';
import { MediaQueries } from '@util/MediaQueries';

import { MINIMUM_SCREEN_WIDTH } from './MobileDrawer';

const Bar = styled.header`
  height: ${ElementSize.MobileHeaderHeight}px;
  min-height: ${ElementSize.MobileHeaderHeight}px;
  width: 100vw;
  @media ${MediaQueries.xxs} {
    width: ${MINIMUM_SCREEN_WIDTH}px;
  }
  background-color: ${Color.MENU_BLACK};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  display: flex;
`;

/** Top Bar displayed in MobileView */
export const MobileTopBar: React.FC<{ children?: ReactNode; menuButtonDecoration?: ReactElement }> = ({
  children,
  menuButtonDecoration,
}) => {
  const dispatch = useDispatch();

  const onButtonClick = () => dispatch(openMenu());
  const width = useWindowWidth();
  const logoSize = getHeaderLogoSize(width);

  return (
    <Bar>
      <SideMenuIconButton id={'id_close_icon_button'} onClick={onButtonClick}>
        <IconMenu width={MENU_ICON_SIZE} stroke={Color.GRAY_LIGHT} fill={Color.GRAY_LIGHT} />
      </SideMenuIconButton>

      {menuButtonDecoration}

      <AppLogoContainer id="id_logo_button" onClick={onButtonClick}>
        <AppLogo size={logoSize} isInHeader={true} />
      </AppLogoContainer>
      {children}
    </Bar>
  );
};

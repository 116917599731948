import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as observableMerge } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { FtpClient } from '@common/client';
import { Ftp, ModifiedFtp, NewFtp } from '@common/model';
import { BaseState, Response } from '@common/redux/Base';

const FETCH_FTP = 'FETCH_FTP';
const FETCH_FTP_FULFILLED = 'FETCH_FTP_FULFILLED';
const POST_FTP = 'POST_FTP';
const POST_FTP_FULFILLED = 'POST_FTP_FULFILLED';
const UPDATE_FTP = 'UPDATE_FTP';
const UPDATE_FTP_FULFILLED = 'UPDATE_FTP_FULFILLED';
const DELETE_FTP = 'DELETE_FTP';
const DELETE_FTP_FULFILLED = 'DELETE_FTP_FULFILLED';

export type FtpResponse = Response<Ftp[]>;

interface FetchFtpResponse extends Action {
  response: FtpResponse;
}

interface PostFtp extends Action {
  ftp: NewFtp;
}

interface UpdateFtp extends Action {
  ftp: ModifiedFtp;
}

interface DeleteFtp extends Action {
  ftpId: string;
}

interface DatalessResponse extends Action {
  datalessResponse: Response<void>;
}

interface DatalessResponseWithFtps extends DatalessResponse {
  ftps?: Ftp[] | null;
}

export const fetchFtp = (): Action => ({
  type: FETCH_FTP,
});

export const fetchFtpResponse = (response: FtpResponse): FetchFtpResponse => ({
  type: FETCH_FTP_FULFILLED,
  response: response,
});

export const postFtp = (ftpToPost: NewFtp): PostFtp => ({
  type: POST_FTP,
  ftp: ftpToPost,
});

export const postFtpResponse = (response: FtpResponse): DatalessResponseWithFtps => {
  const { payload, ...datalessResponse } = response;
  return {
    type: POST_FTP_FULFILLED,
    datalessResponse: datalessResponse,
    ftps: payload,
  };
};

export const updateFtp = (ftpToUpdate: ModifiedFtp): UpdateFtp => ({
  type: UPDATE_FTP,
  ftp: ftpToUpdate,
});

export const updateFtpResponse = (response: FtpResponse): DatalessResponseWithFtps => {
  const { payload, ...datalessResponse } = response;

  return {
    type: UPDATE_FTP_FULFILLED,
    datalessResponse: datalessResponse,
    ftps: payload,
  };
};

export const deleteFtp = (ftpIdToDelete: string): DeleteFtp => ({
  type: DELETE_FTP,
  ftpId: ftpIdToDelete,
});

export const deleteFtpResponse = (response: Response<void>): DatalessResponse => {
  return {
    type: DELETE_FTP_FULFILLED,
    datalessResponse: response,
  };
};

export interface FtpState extends BaseState {
  ftps: Ftp[];
  saveFtpResponse: DatalessResponseWithFtps;
  deleteFtpResponse: DatalessResponseWithFtps;
  isSaveLoading: boolean;
  isDeleteLoading: boolean;
}

export const ftpReducer = (state = {}, action: Action) => {
  let changedStates = {};

  switch (action.type) {
    case FETCH_FTP_FULFILLED: {
      const fetchFtpAction = action as FetchFtpResponse;
      return {
        ...state,
        ftps: fetchFtpAction.response.payload,
        isLoading: false,
      };
    }
    case POST_FTP_FULFILLED: {
      const postFtpAction = action as DatalessResponseWithFtps;
      if (postFtpAction.ftps) {
        changedStates = {
          ...changedStates,
          ftps: postFtpAction.ftps,
        };
      }
      changedStates = {
        ...changedStates,
        saveFtpResponse: postFtpAction.datalessResponse,
        isSaveLoading: false,
      };
      return {
        ...state,
        ...changedStates,
      };
    }

    case UPDATE_FTP_FULFILLED: {
      const updateFtpAction = action as DatalessResponseWithFtps;
      if (updateFtpAction.ftps) {
        changedStates = {
          ...changedStates,
          ftps: updateFtpAction.ftps,
        };
      }
      changedStates = {
        ...changedStates,
        saveFtpResponse: updateFtpAction.datalessResponse,
        isSaveLoading: false,
      };
      return {
        ...state,
        ...changedStates,
      };
    }

    case DELETE_FTP_FULFILLED: {
      const deleteFtpAction = action as DatalessResponse;
      return {
        ...state,
        deleteFtpResponse: deleteFtpAction.datalessResponse,
        isDeleteLoading: false,
      };
    }
    case FETCH_FTP:
      return {
        ...state,
        isLoading: true,
      };
    case POST_FTP:
    case UPDATE_FTP:
      return {
        ...state,
        isSaveLoading: true,
      };

    case DELETE_FTP:
      return {
        ...state,
        isDeleteLoading: true,
      };

    default:
      return state;
  }
};

export const createFtpEpic = (api: Api) => {
  const ftpClient = new FtpClient(api);
  return (action$: ActionsObservable<Action>) =>
    observableMerge(
      action$.ofType(FETCH_FTP).pipe(
        mergeMap(() => {
          return ftpClient.fetchFtps$().pipe(
            map((response: ApiResponse123<Ftp[]>) => {
              if (response.success) {
                const successResp = response as ApiResponseSuccess<Ftp[]>;
                return fetchFtpResponse({ success: response.success, payload: successResp.data });
              } else {
                const error = response as ApiError;
                return fetchFtpResponse({ success: response.success, error: error });
              }
            })
          );
        })
      ),
      action$.ofType(POST_FTP).pipe(
        mergeMap((data) => {
          return ftpClient.postFtp$((data as PostFtp).ftp).pipe(
            map((response: ApiResponse123<Ftp[]>) => {
              if (response.success) {
                const successResp = response as ApiResponseSuccess<Ftp[]>;
                return postFtpResponse({ success: response.success, payload: successResp.data });
              } else {
                const error = response as ApiError;
                return postFtpResponse({ success: response.success, error: error });
              }
            })
          );
        })
      ),
      action$.ofType(UPDATE_FTP).pipe(
        mergeMap((data) => {
          return ftpClient.patchFtp$((data as UpdateFtp).ftp).pipe(
            map((response: ApiResponse123<Ftp[]>) => {
              if (response.success) {
                const successResp = response as ApiResponseSuccess<Ftp[]>;
                return updateFtpResponse({ success: response.success, payload: successResp.data });
              } else {
                const error = response as ApiError;
                return updateFtpResponse({ success: response.success, error: error });
              }
            })
          );
        })
      ),
      action$.ofType(DELETE_FTP).pipe(
        mergeMap((data) => {
          return ftpClient.deleteFtp$((data as DeleteFtp).ftpId).pipe(
            map((response: ApiResponse123<void>) => {
              if (response.success) {
                return deleteFtpResponse({ success: response.success });
              } else {
                const error = response as ApiError;
                return deleteFtpResponse({ success: response.success, error: error });
              }
            })
          );
        })
      )
    );
};

import React from 'react';

import { map } from 'lodash';
import styled from 'styled-components';

import { DialogActions, DialogContent as DialogContentComponent, DialogTitle } from '@material-ui/core';

import { ActionIcon } from '@component/icon/ActionIcon';
import { default as CloseIcon } from '@component/panels/basePanel/loadDetails/close.svg';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { Spacing } from '@style/StyleConstants';
import { typography } from '@style/TypographyStyle';
import { ThemeProps, withTheme } from '@style/WithTheme';

interface PopupTitleProps {
  title?: string | React.ReactNode;
  onClose?: () => void;
}

interface PopupSubtitleProps {
  subtitle?: string | React.ReactNode;
}

interface PopupButtonsProps {
  buttons?: React.ReactNode[];
}

interface PopupContentProps {
  withoutContentPadding?: boolean;
}

// augmenting specificity with && to allow styling material-ui component
const PopupTitle = styled(DialogTitle)`
  && {
    padding: 16px;
  }
`;

// augmenting specificity with && to allow styling material-ui component
const EmptyPopupTitle = styled(DialogTitle)`
  && {
    padding: 16px 0 0;
  }
`;

// augmenting specificity with && to allow styling material-ui component
export const PopupContentSection = withTheme()(styled(DialogContentComponent)<PopupContentProps>`
  && {
    ${(props: PopupContentProps) =>
      props.withoutContentPadding
        ? // overwrite default
          `
            padding: 0px 0px 0px 0px;
          `
        : `
            padding: 0px 16px 16px 16px;
          `}
    word-break: break-word;
    ${(props: ThemeProps) => typography(props.theme.text.subtitleRegular)}
    line-height: 24px;
  }
`);

const PopupTitleContent = withTheme()(styled.div`
  display: flex;
  align-items: flex-start;
  ${(props: ThemeProps) => typography(props.theme.text.h2)};
  line-height: 32px;
`);

const DialogEndAdornment = styled.div`
  margin-left: auto;
`;

const PopupTitleText = styled.div`
  margin-right: 12px;
  word-break: break-word;
`;

const PopupSubtitle = withTheme()(styled.div`
  margin: ${Spacing.ScreenSideWide}px 0;
  text-align: center;
  ${(props: ThemeProps) => typography(props.theme.text.subtitleBold)};
`);

export const PopupTitleSection: React.FC<PopupTitleProps> = ({ title, onClose }) => {
  const { popup, closingPopup } = usePopup();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    closingPopup();
  };

  return title ? (
    <PopupTitle>
      <PopupTitleContent>
        <PopupTitleText>{title}</PopupTitleText>
        {popup?.hasCloseButton || (!popup?.isDismissible && popup?.hasCloseButton === undefined) ? (
          <DialogEndAdornment>
            <ActionIcon id={'email_form_close'} src={CloseIcon} alt="close" onClick={handleClose} />
          </DialogEndAdornment>
        ) : null}
      </PopupTitleContent>
    </PopupTitle>
  ) : (
    <EmptyPopupTitle />
  );
};

export const PopupSubtitleSection: React.FC<PopupSubtitleProps> = ({ subtitle }) => (
  <PopupSubtitle>{subtitle}</PopupSubtitle>
);

export const PopupActionButtons: React.FC<PopupButtonsProps> = ({ buttons }) =>
  buttons ? <DialogActions>{map(buttons)}</DialogActions> : null;

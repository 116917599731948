import React, { useEffect } from 'react';

import { Location } from 'history';
import { concat, includes, isEqual, map, some, startsWith } from 'lodash';
import { useDispatch } from 'react-redux';
import { match as RouterMatch } from 'react-router-dom';
import styled from 'styled-components';

import { toggleSideMenuSection } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { SideMenuExpansionPanel } from '@component/expansionPanel/SideMenuExpansionPanel';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { LinkWithNotification } from '@component/menu/LinkWithNotification';
import { NavLabel, NavLinkLabel, OldLink, SvgContainer } from '@component/menu/menuStyles';
import { NavGroups, NavItem } from '@component/menu/menuTypes';
import { useSelector } from '@util/hooks';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`;

const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -6px;
`;

interface MenuNavProps {
  navGroups: NavGroups[] | undefined;
  onClick: () => void;
  isMenuOpen: boolean;
}

export const MenuNav: React.FC<MenuNavProps> = (props) => {
  return (
    <div style={{ marginTop: 30 }}>
      {!props.navGroups ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        map(props.navGroups, (navGroup, index) => (
          <MenuSection key={index} navGroup={navGroup} isMenuOpen={props.isMenuOpen} onClick={props.onClick} />
        ))
      )}
    </div>
  );
};

const MenuSection: React.FC<{ navGroup: NavGroups; isMenuOpen: boolean; onClick: () => void }> = ({
  navGroup,
  isMenuOpen,
  onClick,
}) => {
  const dispatch = useDispatch();
  const sideMenuCollapsedSections = useSelector((state) => state.applicationSettings.sideMenuCollapsedSections);
  const isExpanded = !includes(sideMenuCollapsedSections, navGroup.id);
  const hasNotification = some(
    navGroup.content,
    (item: NavItem) => item.notificationCount && item.notificationCount > 0
  );

  const toggleSection = () => {
    dispatch(toggleSideMenuSection(navGroup.id));
  };

  useEffect(() => {
    if (hasNotification && !isExpanded) {
      toggleSection();
    }
  }, [hasNotification]);

  return (
    <SideMenuExpansionPanel
      key={navGroup.id}
      id={navGroup.id}
      titleText={navGroup.label}
      isMenuFullWidth={isMenuOpen}
      isExpanded={isExpanded}
      handleClick={toggleSection}
    >
      <NavItemsContainer>
        {map(navGroup.content, (item) => (
          <NavItems key={item.id} navItem={item} isMenuOpen={isMenuOpen} onClick={onClick} />
        ))}
      </NavItemsContainer>
    </SideMenuExpansionPanel>
  );
};

const NavItems: React.FC<{ navItem: NavItem; isMenuOpen: boolean; onClick: () => void }> = ({
  navItem,
  isMenuOpen,
  onClick,
}) => {
  const isActive = () => {
    return (match: RouterMatch, location: Location) =>
      some(concat([navItem.href], navItem.additionalLinksToMatch), (link) =>
        navItem.exact ? isEqual(location.pathname, link) : startsWith(location.pathname, link)
      );
  };

  if (navItem.isHidden) {
    return null;
  }

  return (
    <>
      {navItem.isExternalLink ? (
        <>
          <OldLink id={navItem.id} href={navItem.href} key={navItem.id}>
            <SvgContainer className={'svg-container'}>{navItem.icon ? <navItem.icon /> : undefined}</SvgContainer>
            <NavLabel>{navItem.label}</NavLabel>
          </OldLink>
          {!isMenuOpen ? <NavLinkLabel>{navItem.label}</NavLinkLabel> : null}
        </>
      ) : (
        <LinkWithNotification
          key={navItem.id}
          item={navItem}
          menuOpen={isMenuOpen}
          onClick={onClick}
          isActive={isActive()}
        />
      )}
    </>
  );
};

import { includes, pull, uniq } from 'lodash';
import { Action } from 'redux';
import { WebStorage } from 'redux-persist';

import { createAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const SET_MENU_LAYOUT = 'SET_MENU_LAYOUT';
const TOGGLE_MENU = 'TOGGLE_MENU';
const OPEN_MENU = 'OPEN_MENU';
const CLOSE_MENU = 'CLOSE_MENU';

export enum UIMenuLayout {
  Carrier = 'carrier_menu',
  BrokerShipper = 'broker_shipper_menu',
}

export enum ExpandableSections {
  TruckLocatorExactAnonymousMatches = 'TruckLocatorExactAnonymousMatches',
  TruckLocatorExactMatches = 'TruckLocatorExactMatches',
  TruckLocatorNonMatches = 'TruckLocatorNonMatches',
  TruckLocatorFilteredOutMatches = 'TruckLocatorFilteredOutMatches',
  ProfitCalculatorTrip = 'ProfitCalculatorTrip',
  ProfitCalculatorFuelCost = 'ProfitCalculatorFuelCost',
  ProfitCalculatorAdditionalCost = 'ProfitCalculatorAdditionalCost',
  SearchDirectoryCompanyBusinessBackground = 'SearchDirectoryCompanyBusinessBackground',
  SearchDirectoryCompanySafety = 'SearchDirectoryCompanySafety',
  SearchDirectoryCompanyAuthority = 'SearchDirectoryCompanyAuthority',
  SearchDirectoryCompanyInsurance = 'SearchDirectoryCompanyInsurance',
  SearchDirectoryCompanyCreditRating = 'SearchDirectoryCompanyCreditRating',
  SearchDirectoryCompanyLoads = 'SearchDirectoryCompanyLoads',
  RateCheck = 'RateCheck',
  CargoChiefRate = 'CargoChiefRate',
  GreenscreensRate = 'GreenscreensRate',
  PostedLoadsLocationAndDate = 'PostedLoadsLocationAndDate',
  PostedLoadsEquipment = 'PostedLoadsEquipment',
  PostedLoadsLoadSize = 'PostedLoadsLoadSize',
  PostedLoadsRateAndDistance = 'PostedLoadsRateAndDistance',
  PostedLoadsPostingContact = 'PostedLoadsPostingContact',
  PostedLoadsLoadVisibility = 'PostedLoadsLoadVisibility',
  PostedLoadsBookingOptions = 'PostedLoadsBookingOptions',
  CarrierDetailsRiskAssessment = 'CarrierDetailsRiskAssessment',
  ExpediteAllRate = 'ExpediteAllRate',
}

interface SetMenuLayoutAction extends Action {
  menuLayout: UIMenuLayout;
}

interface ExpandedSectionsAction {
  section: ExpandableSections;
  expanded: boolean;
}

const setExpandedSectionAction = createAction<ExpandedSectionsAction>('SET_EXPANDED_SECTION');
const toggleSideMenuSectionAction = createAction<string>('TOGGLE_SIDEMENU_SECTION');

/** only for Users with CompanyCategory = BrokerCarrier */
export const setMenuLayout = (menuLayout: UIMenuLayout): SetMenuLayoutAction => ({
  type: SET_MENU_LAYOUT,
  menuLayout: menuLayout,
});

export const toggleMenu = (): Action => ({
  type: TOGGLE_MENU,
});

export const openMenu = (): Action => ({
  type: OPEN_MENU,
});

export const closeMenu = (): Action => ({
  type: CLOSE_MENU,
});

export type ExpandableSectionsState = Record<ExpandableSections, boolean>;

export interface ApplicationSettingsState {
  /** controlled by UI, only available for Users with CompanyCategory = BrokerCarrier */
  uiMenuLayout: UIMenuLayout;
  menuOpen: boolean;
  expandedSections: ExpandableSectionsState;
  /** maintains a list of menu sections that were collapsed by the user, default state is expanded */
  sideMenuCollapsedSections: string[];
}

const initialState: ApplicationSettingsState = {
  uiMenuLayout: UIMenuLayout.Carrier,
  menuOpen: true,
  expandedSections: {
    [ExpandableSections.TruckLocatorExactAnonymousMatches]: false,
    [ExpandableSections.TruckLocatorExactMatches]: true,
    [ExpandableSections.TruckLocatorFilteredOutMatches]: true,
    [ExpandableSections.TruckLocatorNonMatches]: true,
    [ExpandableSections.ProfitCalculatorTrip]: false,
    [ExpandableSections.ProfitCalculatorFuelCost]: false,
    [ExpandableSections.ProfitCalculatorAdditionalCost]: false,
    [ExpandableSections.SearchDirectoryCompanyAuthority]: false,
    [ExpandableSections.SearchDirectoryCompanyLoads]: false,
    [ExpandableSections.SearchDirectoryCompanyInsurance]: false,
    [ExpandableSections.SearchDirectoryCompanyCreditRating]: false,
    [ExpandableSections.SearchDirectoryCompanySafety]: false,
    [ExpandableSections.SearchDirectoryCompanyBusinessBackground]: false,
    [ExpandableSections.RateCheck]: false,
    [ExpandableSections.CargoChiefRate]: false,
    [ExpandableSections.GreenscreensRate]: false,
    [ExpandableSections.PostedLoadsLocationAndDate]: true,
    [ExpandableSections.PostedLoadsEquipment]: true,
    [ExpandableSections.PostedLoadsLoadSize]: true,
    [ExpandableSections.PostedLoadsRateAndDistance]: true,
    [ExpandableSections.PostedLoadsPostingContact]: true,
    [ExpandableSections.PostedLoadsLoadVisibility]: true,
    [ExpandableSections.PostedLoadsBookingOptions]: true,
    [ExpandableSections.CarrierDetailsRiskAssessment]: false,
    [ExpandableSections.ExpediteAllRate]: false,
  },
  sideMenuCollapsedSections: [],
};

export const setExpandedSection = (section: ExpandableSections, expanded: boolean) =>
  setExpandedSectionAction.action({ section: section, expanded: expanded });
export const toggleSideMenuSection = (section: string) => toggleSideMenuSectionAction.action(section);

export const applicationSettingsReducer = createMergedReducer(initialState, [
  setExpandedSectionAction.addCase((state, action) => {
    const { section, expanded } = action.data;
    state.expandedSections[section] = expanded;
  }),
  toggleSideMenuSectionAction.addCase((state, action) => {
    const section = action.data;
    const sectionList = state.sideMenuCollapsedSections;
    if (includes(sectionList, section)) {
      pull(sectionList, section);
    } else {
      sectionList.push(section);
    }
    state.sideMenuCollapsedSections = uniq(sectionList);
  }),
  {
    [SET_MENU_LAYOUT]: (state, action: SetMenuLayoutAction) => {
      state.uiMenuLayout = action.menuLayout;
    },
    [TOGGLE_MENU]: (state) => {
      state.menuOpen = !state.menuOpen;
    },
    [OPEN_MENU]: (state) => {
      state.menuOpen = true;
    },
    [CLOSE_MENU]: (state) => {
      state.menuOpen = false;
    },
  },
]);

export const migrateAppSettings =
  (storage: WebStorage) =>
  async (state: any | undefined): Promise<any | undefined> => {
    // Using 'any' because of issues with redux-persist typings
    // First migration run. Prevent double execute
    if (!state) {
      // Get old settings stored in key = menu
      const oldSettings = await storage.getItem('persist:menu');
      if (oldSettings) {
        const oldMenuSettings = JSON.parse(oldSettings);
        // Remove old auth key
        await storage.removeItem('persist:menu');
        return {
          menuLayout: JSON.parse(oldMenuSettings.menuLayout),
          menuOpen: JSON.parse(oldMenuSettings.menuOpen),
        };
      }
    }
    return state;
  };

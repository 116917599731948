import memoizeOne from 'memoize-one';

import {
  EquipmentType,
  LoadAvailabilitySortCategory,
  LoadSearchMetadata,
  LoadSearchRequest,
  LoadSortDirection,
  LoadSortField,
  ViewField,
} from '@common/model';
import { DefaultEncoder } from '@common/util/encoder/URLDefaultEncoder';
import { parseArrayOfValuesFrom, parseNumber, parseString, parseValueFrom } from '@common/util/parser/ParserUtils';
import { MapData } from '@page/findLoads/LoadSearchRequestEncoder';
import {
  parseCompanySearchRequest,
  parseLoadSearchMetadata,
  parseLoadSearchRequest,
} from '@util/parsers/LoadSearchRequestParsers';
import { parseMapData } from '@util/parsers/MapDataParsers';

export interface LoadAvailabilityRouting {
  equipmentType?: EquipmentType[];
  region?: LoadAvailabilitySortCategory;
  selectedState?: string;
  loadID?: string;
  sortBy?: LoadSortField;
  sortDirection?: LoadSortDirection;
  changeView?: ViewField;
  mapData?: MapData;
  searchData?: LoadSearchRequest;
  mapResults?: string;
  searchId?: number;
  companySearchData?: LoadSearchRequest;
  companyMapData?: MapData;
  companySearchId?: string;
  companyMetadata?: LoadSearchMetadata;
}

class LoadAvailabilityEncoder extends DefaultEncoder<LoadAvailabilityRouting> {
  getVocabulary() {
    return {
      S: 'searchData',
      M: 'mapData',
      reg: 'region',
      e: 'equipmentType',
      ss: 'selectedState',
      id: 'searchId',
      loadID: 'loadID',
      sb: 'sortBy',
      sd: 'sortDirection',
      cv: 'changeView',
      lT: 'latitude',
      lG: 'longitude',
      lt: 'limit',
      p: 'pins',
      ct: 'count',
      d: 'destination',
      t: 'type',
      o: 'origin',
      c: 'city',
      s: 'states',
      r: 'radius',
      m: 'metadata',
      eS: 'equipmentSpecifications',
      eT: 'equipmentTypes',
      wGPD: 'includeWithGreaterPickupDates',
      wGDD: 'includeWithGreaterDeliveryDates',
      sL: 'saveLocations',
      iS: 'includeStatuses',
      CS: 'companySearchData',
      CM: 'companyMapData',
      cy: 'company',
      n: 'name',
      ts: 'types',
      cIds: 'companyIds',
      cm: 'companyMetadata',
      cId: 'companySearchId',
    };
  }
  convertUrlParamsToLoadAvailabilityRouting = memoizeOne((urlParams: string): LoadAvailabilityRouting => {
    const rawObjectRequest = this.convertUrlParamsToObjectRequest(urlParams);
    return {
      equipmentType: parseArrayOfValuesFrom(rawObjectRequest.equipmentType, EquipmentType),
      region: parseValueFrom(rawObjectRequest.region, LoadAvailabilitySortCategory),
      selectedState: parseString(rawObjectRequest.selectedState),
      loadID: parseString(rawObjectRequest.loadID),
      sortBy: parseValueFrom(parseNumber(rawObjectRequest.sortBy), LoadSortField) as LoadSortField,
      sortDirection: parseValueFrom(rawObjectRequest.sortDirection, LoadSortDirection),
      changeView: parseValueFrom(rawObjectRequest.changeView, ViewField),
      mapData: parseMapData(rawObjectRequest.mapData),
      searchData: parseLoadSearchRequest(rawObjectRequest.searchData),
      searchId: parseNumber(rawObjectRequest.searchId),
      companySearchData: parseCompanySearchRequest(rawObjectRequest.companySearchData),
      companyMapData: parseMapData(rawObjectRequest.companyMapData),
      companySearchId: parseString(rawObjectRequest.companySearchId),
      companyMetadata: parseLoadSearchMetadata(rawObjectRequest.metadata),
    };
  });
}

export const loadAvailabilityEncoder = new LoadAvailabilityEncoder();

import { authenticationToApiOptions } from '@common/api/ApiOptions';
import { BaseClient } from '@common/client/BaseClient';
import { addArchivingHeader } from '@common/helper/FlowIDHeaderHelper';
import { Geolocation, MileageRoutesPostRequest, RouteInfo, RoutePoints, UiSettingsPayload } from '@common/model';
import { RouteDirections } from '@common/model/PCMiler';

export class RouteClient extends BaseClient {
  fetchRoute$ = (
    data: {
      origin: Geolocation;
      destination: Geolocation;
      vehTypev?: string;
      hwyOnly?: string;
      distUnits?: string;
      avoidTolls?: string;
      routeOpt?: string;
      dataset?: string;
    },
    redirectOnAuthentication: boolean = true,
    archivingFlowID: string | undefined
  ) =>
    this.api.get$<RoutePoints>(
      '/route/routePath',
      {
        vehTypev: data.vehTypev || 'Truck',
        stops: `${data.origin.longitude},${data.origin.latitude};${data.destination.longitude},${data.destination.latitude}`,
        hwyOnly: data.hwyOnly || 'false',
        distUnits: data.distUnits || 'Miles',
        avoidTolls: data.avoidTolls || 'true',
        routeOpt: data.routeOpt || 'ThruAll',
        dataset: data.dataset || 'Current',
      },
      addArchivingHeader(archivingFlowID),
      authenticationToApiOptions(redirectOnAuthentication)
    );
  //For the three endpoints (POST /routes/direction, /route/routePath, /routes/statemileage):
  //post that acts like a get to be used if we have stops, it returns the apporopriate data for the PC miler
  //isCustom flag is used to track the number of calls to the PcMiler data and count it as new request to the tool
  //isCustomrequest is set to fasle because the search is linked to a load
  //isFollowUp will track the call to the api for the pcMiler but not count it as a new request to the tool
  postRouteDirection$ = (data: MileageRoutesPostRequest) =>
    this.api.post$<RouteDirections>('/routes/direction?isCustomRequest=false', data);
  postRoutePath$ = (data: { route: MileageRoutesPostRequest }) =>
    this.api.post$<RoutePoints>('/route/routePath?isFollowUp=true', data);
  getExportReport$ = (data: MileageRoutesPostRequest) => this.api.post$<string>('/routes/exportReport', data);

  getUiSettings$ = () => this.api.get$<UiSettingsPayload>('/settings/routes/UiSettings', undefined);
  patchUiSettings$ = (data: UiSettingsPayload) =>
    this.api.mergePatch$<UiSettingsPayload>('/settings/routes/UiSettings', data);
  getRouteMileage$ = (data: MileageRoutesPostRequest) => this.api.post$<RouteInfo>('/routes/info', data);
}

import { Spacing } from '@style/StyleConstants';

export enum Width {
  headerSmallerLogo = 359,
  xs = 600,
  mobile = 767,
  sm = 960,
  loadDetailsShouldStack = 1000,
  tablet = 1060,
  md = 1280,
  listSideMenuExansion = 1500,
  sideMenuExpansion = 1366,
  loadDetailsSingleColumnLargeStart = 1300,
  loadDetailsSingleColumnLargeEnd = 1710,
  xl = 1920,
}

export const smallerThan = (width: number) => `(max-width: ${width - 1}px)`;
export const largerThan = (width: number) => `(min-width: ${width}px)`;

export const MediaQueries = {
  /**  [ 0px ... 320px [ */
  xxs: '(max-width: 320px)',
  /**  [ 0px ... 600px [ */
  xs: 'only screen and (max-width: 599px)',
  /**  [ 600px ... 960px [ */
  sm: 'only screen and (min-width: 600px) and (max-width: 959px)',
  /**  [ 768px ... 1024px [ */
  md_ipad: 'only screen and (min-width: 768px) and (max-width: 1024px)',
  /**  [ 960px ... 1280px [ */
  md: 'only screen and (min-width: 960px) and (max-width: 1279px)',
  /**  [ 1280px ... 1920px [ */
  lg: 'only screen and (min-width: 1280px) and (max-width: 1919px)',
  /**  [ 1920px (and more) */
  xl: 'only screen and (min-width: 1920px) and (max-width: 5000px)',

  /** less than 600px */
  lt_sm: 'only screen and (max-width: 599px)',
  /** less than 768px */
  lt_mobile: 'only screen and (max-width: 767px)',
  /** less than 960px */
  lt_md: 'only screen and (max-width: 959px)',
  /** less than 1080px */
  lt_md_lg: 'only screen and (max-width: 1080px)',
  /** less than 1280px */
  lt_lg: 'only screen and (max-width: 1279px)',
  /** less than 1920px */
  lt_xl: 'only screen and (max-width: 1919px)',

  /** 600px and more */
  gt_xs: 'only screen and (min-width: 600px)',
  /** 960px and more */
  gt_sm: 'only screen and (min-width: 960px)',
  /** 1280px and more */
  gt_md: 'only screen and (min-width: 1280px)',
  /** 1920px and more */
  gt_lg: 'only screen and (min-width: 1920px)',

  /** less than 600px + 2 * (screenside padding) */
  lt_sm_plus_padding: `(max-width: ${Width.xs + Spacing.ScreenSide * 2}px)`,
};

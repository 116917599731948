import React from 'react';

import { getMCLink, getUSDOTLink } from '@common/info';
import { Text, TextStyle } from '@component/text';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';

interface BaseProps {
  id: string;
}

interface MCProps extends BaseProps {
  docketNumber?: number;
}

interface DOTProps extends BaseProps {
  dotNumber?: string;
}

export const MC: React.FC<MCProps> = (props) => {
  const mc = props.docketNumber
    ? link(props.id + '_docket_number', props.docketNumber, TextStyle.Link, getMCLink)
    : NO_INFORMATION_SYMBOL;

  return <Text id={props.id}>{mc}</Text>;
};

export const DOT: React.FC<DOTProps> = (props) => {
  const dot = props.dotNumber
    ? link(props.id + '_dot_number', props.dotNumber, TextStyle.Link, getUSDOTLink)
    : NO_INFORMATION_SYMBOL;

  return <Text id={props.id}>{dot}</Text>;
};

const link = <T extends {}>(id: string, value: T, textStyle: TextStyle, hrefURLFormatter: (value: T) => string) => (
  <a id={id + '_link'} href={hrefURLFormatter(value)} target="_blank" rel="noopener noreferrer">
    <Text id={id + '_link_text'} textStyle={textStyle}>
      {value}
    </Text>
  </a>
);

export enum FindLoadsModals {
  Pickup = 'pickup',
  Dropoff = 'dropoff',
  Dates = 'dates',
  TrailerType = 'trailer-type',
  TripLength = 'trip-length',
  Truck = 'my-truck',
  AddTruck = 'create-new-truck',
  EditTruck = 'edit-truck',
}

export enum LoadSearchRegion {
  USA = 'USA',
  Canada = 'Canada',
  Both = 'Both',
}

export interface FindLoadsRouteParams {
  loadID: string;
  modal: FindLoadsModals;
  companySearchModal: FindLoadsModals;
  companySearchMap: string;
  companyID: string;
  results: string;
  filters: string;
  changeView: string;
  companySearchChangeView: string;
  sortBy: string;
  companySearch: string;
  companySearchResult: string;
  companySearchLoad: string;
  loadMap: string;
  backhauls: string;
  backhaulSearchID: string;
  backhaulLoadID: string;
  backhaulLoadMap: string;
  backhaulsSortBy: string;
  backhaulsDates: string;
  communication: string;
  chat: string;
  details: string;
  contact: string;
  load: string;
  postTruck: string;
  subroutes: string;
  truckId: string;
  selectStates: string;
  region: LoadSearchRegion;
}

import { isEqual } from 'lodash';
import { Action } from 'redux';

import { Truck } from '@common/model';

//@FIXME: Remove the file. This reducer and whole logic should be moved into TruckEpic there already exists trucksReducer.

export enum TruckAction {
  FAVORITE = 'FAVORITE_TRUCK',
  HIDE = 'HIDE_TRUCK',
  ALERT = 'SEND_TRUCK_ALERT',
  REFRESH_TRUCKS = 'REFRESH_TRUCKS',
}

interface TruckLocatorAction extends Action {
  trucks?: Truck[];
}

export function triggerTruckAction(action: TruckAction, trucks?: Truck[]): TruckLocatorAction {
  return {
    type: action,
    trucks: trucks,
  };
}

export interface TruckLocatorState {
  action: TruckAction;
  trucks: Truck[];
  toggle: boolean;
}

export const truckLocatorReducer = (state: TruckLocatorState, action: Action) => {
  switch (action.type) {
    case TruckAction.FAVORITE:
    case TruckAction.ALERT:
    case TruckAction.HIDE: {
      const truckAction = action as TruckLocatorAction;
      if (isEqual(state.action, action.type) && isEqual(state.trucks, truckAction.trucks)) {
        state.toggle = !state.toggle;
      }
      return { ...state, action: action.type, trucks: truckAction.trucks };
    }
    case TruckAction.REFRESH_TRUCKS:
      if (isEqual(state.action, action.type)) {
        state.toggle = !state.toggle;
      }
      return { ...state, action: action.type };
  }
  return state ? state : { trucks: [], toggle: false };
};

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { first, forEach, head, includes, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { LoadDetailsTabs } from '@/model';
import {
  PostTruckAvailabilityForm,
  setPostAvailabilityPrefilled,
  updatePostAvailabilityForm,
} from '@/reduxStore/reducer/PostTruckAvailabilityReducer';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { COMMUNICATION_KEY, Routes } from '@/router/Routes';
import {
  convertCityLocationFormatToCapitalize,
  formatDateYYYYMMDD,
  isBrokerBlocked,
  prefixHttpsIfNecessary,
} from '@common/helper';
import { Flag, LoadSearchRequest, LocationType, ViewChoice, ViewField } from '@common/model';
import { LoadDetailsState } from '@common/redux/epic/LoadDetailsEpic';
import {
  callLoad,
  defaultLoadInfo,
  fetchLoadBackhauls,
  fetchLoadCargoChiefRate,
  fetchLoadGreenscreensRate,
  fetchRateCheck,
  fetchRouteInfo,
} from '@common/redux/epic/LoadInfoEpic';
import { fetchMostRecentQuoteForLoad } from '@common/redux/epic/QuoteEpic';
import { clearRateCheck } from '@common/redux/epic/RateCheckEpic';
import { fetchLoadInfoMessages } from '@common/redux/epic/TruckAlertsEpic';
import { useDidUpdate } from '@common/util/hooks';
import { Banner, BannerType } from '@component/banner/Banner';
import { LinkButton } from '@component/buttons/LinkButton';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { OFFSET_FLOATING, Panel, PanelFooter, PanelSize } from '@component/panel';
import { TruckAlertMessage } from '@component/panels/basePanel/loadDetails/TruckAlertMessage';
import { BACKHAULS_LAYER } from '@component/panels/findLoads/backhaulsPanel/BackhaulsPanel';
import { BookNowBanner } from '@component/panels/findLoads/loadDetailsPanel/BookNowBanner';
import { useOnBookNow } from '@component/panels/findLoads/loadDetailsPanel/buttons/BookNowButton';
import { ContactButtons } from '@component/panels/findLoads/loadDetailsPanel/buttons/ContactButtons';
import { Header } from '@component/panels/findLoads/loadDetailsPanel/Header';
import { LoadDetailsActionsBar } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsActionsBar';
import { LoadDetailsData } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsData';
import {
  LoadDetailsExpansionPanels,
  LoadDetailsExpansionPanelsRef,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsExpansionPanels';
import { LoadDetailsContent } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import {
  showEmailPopup,
  showErrorPopup,
  showManagePlanPopup,
  showStartOnboardingPopup,
  SocialNetworksPopup,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPopups';
import { MapRoutes } from '@component/panels/findLoads/loadDetailsPanel/MapRoutes';
import { ResultViewType } from '@component/panels/findLoads/searchPanel/SearchPanelHelper';
import {
  BlockedCompanyPanel,
  ErrorPanel,
  LoadNotAvailablePanel,
} from '@component/panels/notFoundSearches/NotFoundPanels';
import { isPanelSingleColumn } from '@component/panelSizeDetector/PanelSizeDetector';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { FixedHeader } from '@component/scrollView';
import { useScrollIntoView, useScrollToTop } from '@component/scrollView/scrollUtil';
import { usePlaceBid } from '@page/bids/BidNavigationHelper';
import { bidsEncoder, BidsTabs } from '@page/bids/BidsPanelRouting';
import { loadSearchRequestEncoder } from '@page/findLoads/LoadSearchRequestEncoder';
import { FontFamily, FontLineHeight, FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { usePrevious, useSelector } from '@util/hooks';
import { createCallLoadTrackData, track } from '@util/trackers/123lbTracker';

const BannerContent = styled.p`
  font-size: ${FontSize.ContentDetail}px;
  line-height: ${FontLineHeight.ContentDetail}px;
  margin-top: 5px;
`;

const OnboardingUrlButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface LoadDetailsRouteParams {
  results: string;
  loadID: string;
  backhaulLoadID: string;
  loadMap: 'pc-miler-map';
  backhaulLoadMap: 'backhaul-map';
  [COMMUNICATION_KEY]: string;
}

interface LoadDetailsPanelProps {
  loadDetails: LoadDetailsState;
  loadId: string;
  openedTabs: LoadDetailsTabs[];
  viewType: ResultViewType;
  conversationLoadID?: string;
  isBackhaulsDisabled?: boolean;
  isFromMyLoads?: boolean;
  layer?: number; // hack while waiting for a proper panel system
  offset?: number; // hack while waiting for a proper panel system
  selectedSearch?: LoadSearchRequest;
  selectedView?: ViewChoice;
  shouldNotShowHideButton?: boolean;
  fetchLoadDetails: (loadId: string) => void;
  handleTabChange: (tab: LoadDetailsTabs[], status: boolean) => void;
  closeDetailsPanel?: () => void;
  openBackhaulsPanel?: (id: string) => void;
  openCompanySearchPanel?: () => void;
  openMap?: () => void;
  openPostTruckPanel?: () => void;
}

export const LoadDetailsPanel = (props: LoadDetailsPanelProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<LoadDetailsRouteParams>();

  const expansionPanelRef = useRef<LoadDetailsExpansionPanelsRef | null>(null);

  const [profitPerDay, setProfitPerDay] = useState<number>(0);
  const [isProfitCalculatorOpened, setIsProfitCalculatorOpened] = useState(false);

  const currentLoad = props.loadDetails.currentLoad;
  const status = props.loadDetails.responseState.status;

  const loadInfo = useSelector((state) => currentLoad && state.loadInfo.loadInfoMap.get(currentLoad.id));

  const fuelAdvanceQuote = useSelector((state) => state.fuelAdvanceQuote);
  const getPaidFasterQuoteState = useSelector((state) => state.getPaidFasterQuoteState);
  const isMapTollsAvailable = useSelector((state) => state.user.accesses.isMapTollsAvailable);
  const sharedLoadMessage = useSelector((state) => {
    const loadInfo = state.loadInfo.loadInfoMap.get(props.loadId) || defaultLoadInfo;
    return loadInfo.sharedLoadMessage;
  });
  const truckAlertMessages = useSelector((state) => state.truckAlerts.loadInfoMessages.get(props.loadId));

  const topBidId = first(useSelector((state) => state.bids.loadBids.listAll.ids));

  const onboardingBannerEnabled = useSelector(
    (state) => state.settings.systemSetting[Flag.CarrierOnboardingBannerEnabled].value
  );
  const isCargoChiefRateAvailable = useSelector((state) => state.user.accesses.isCargoChiefRateAvailable);
  const isCargoChiefDisabled = useSelector((state) => state.settings.systemSetting[Flag.CargoChiefUserDisabled].value);
  const isGreenscreensRateDisabled = useSelector(
    (state) => state.settings.systemSetting[Flag.GreenscreensRateDisabled].value
  );
  const lastTruckAlertMessage = head(truckAlertMessages);
  const [panelWidth, setPanelWidth] = useState<number | undefined>(undefined);
  const isStatusBarOpened = includes(props.openedTabs, LoadDetailsTabs.Stepper);

  const scrollRef = useRef<HTMLDivElement>(null);
  const statusBarRef = useRef<HTMLDivElement>(null);

  const scrollToTop = useScrollToTop();
  const scrollIntoView = useScrollIntoView();

  const popupContext = usePopup();

  const loadSearchObject = loadSearchRequestEncoder.convertUrlParamsToObjectRequest(location.search);

  useEffect(() => {
    fetchDetails();
    fetchBackhaulInfoForTruckPost();
    dispatch(fetchLoadInfoMessages(props.loadId));
    scrollToTop(scrollRef);

    return () => {
      dispatch(clearRateCheck());
    };
  }, []);

  const hasVisitedConversationLoadDetails = params[COMMUNICATION_KEY] === undefined;

  const prev = usePrevious({
    fuelAdvanceQuote: fuelAdvanceQuote,
    getPaidFasterQuoteState: getPaidFasterQuoteState,
    hasVisitedConversationLoadDetails: hasVisitedConversationLoadDetails,
    loadId: props.loadId,
  });

  useDidUpdate(() => {
    if (
      (hasVisitedConversationLoadDetails !== prev.hasVisitedConversationLoadDetails &&
        hasVisitedConversationLoadDetails) ||
      props.loadId !== prev.loadId
    ) {
      fetchDetails();
      fetchBackhaulInfoForTruckPost();
    }

    if (
      currentLoad &&
      ((!fuelAdvanceQuote.isPostLoading && fuelAdvanceQuote.isPostLoading !== prev.fuelAdvanceQuote.isPostLoading) ||
        (!getPaidFasterQuoteState.isPostLoading &&
          getPaidFasterQuoteState.isPostLoading !== prev.getPaidFasterQuoteState.isPostLoading))
    ) {
      dispatch(fetchMostRecentQuoteForLoad(currentLoad.id));
    }
  });

  useEffect(() => {
    if (currentLoad) {
      dispatch(fetchRateCheck(currentLoad.id));
      dispatch(fetchRouteInfo(currentLoad.id));
      dispatch(fetchMostRecentQuoteForLoad(currentLoad.id));
    }
    scrollToTop(scrollRef); // Scroll to top if load is changed.
  }, [currentLoad?.id]);

  useDidUpdate(() => {
    if (props.loadDetails.hasReachedLimit) {
      dispatch(showSnackbar({ message: t(T.error_limitReached) }));
      props.closeDetailsPanel?.();
    }
  }, [props.loadDetails.hasReachedLimit]);

  useDidUpdate(() => {
    if (status === 'Error') {
      showErrorPopup(popupContext);
    }
  }, [status]);

  const fetchDetails = () => {
    props.fetchLoadDetails(props.loadId);

    if (!isGreenscreensRateDisabled) dispatch(fetchLoadGreenscreensRate(props.loadId));

    if (isCargoChiefRateAvailable && !isCargoChiefDisabled) dispatch(fetchLoadCargoChiefRate(props.loadId));
  };

  const fetchBackhaulInfoForTruckPost = () => {
    if (props.openPostTruckPanel && props.isBackhaulsDisabled && props.viewType !== ResultViewType.Backhaul) {
      dispatch(fetchLoadBackhauls(props.loadId));
    }
  };

  const onUpdateStatusAction = () => {
    props.handleTabChange([LoadDetailsTabs.Stepper], true);
    scrollIntoView(statusBarRef.current);
  };

  const onStatusBarExpand = () => {
    if (!isStatusBarOpened) {
      if (currentLoad) {
        dispatch(fetchMostRecentQuoteForLoad(currentLoad.id));
      }
      scrollIntoView(statusBarRef.current);
    }
    props.handleTabChange([LoadDetailsTabs.Stepper], !isStatusBarOpened);
  };

  const openBackhaulsPanel = (id: string) => {
    if (props.openBackhaulsPanel) {
      return props.openBackhaulsPanel(id);
    }
    return history.push({
      pathname: `${generatePath(Routes.LOADS_FINDLOADS, {
        ...params,
        backhauls: 'backhauls',
        backhaulSearchID: id,
      })}/`,
      search: history.location.search,
    });
  };

  const handleTabChange = (tabName: LoadDetailsTabs, status: boolean) => props.handleTabChange([tabName], status);

  const onClickManagePlan = () => {
    if (popupContext) {
      popupContext.closingPopup();
    }
    history.push(Routes.MORE_SETTINGS_MANAGE_PLAN);
  };

  const openMap = () => {
    if (isMapTollsAvailable) {
      if (props.openMap) {
        return props.openMap();
      }
      const mapParam =
        props.viewType === ResultViewType.Backhaul ? { backhaulLoadMap: 'backhaul-map' } : { loadMap: 'pc-miler-map' };
      return history.push({
        pathname: `${generatePath(Routes.LOADS_FINDLOADS, { ...params, ...mapParam })}/`,
        search: history.location.search,
      });
    } else {
      return showManagePlanPopup(popupContext, onClickManagePlan);
    }
  };

  const onCallClick = () => {
    if (currentLoad && currentLoad.dispatchPhone && currentLoad.dispatchPhone.number) {
      window.open(`tel:${currentLoad.dispatchPhone.number}`, '_self');
      logCall(currentLoad.id);
    }
  };

  const logCall = (loadID: string) => {
    dispatch(callLoad(loadID, true));
    const currentURL = `${history.location.pathname}${history.location.search}`;
    // this call won't show up in reactotron for some reason (any tel: link seems to disconnect reactotron)
    // so if you need to debug, use the browser console network tab
    track(createCallLoadTrackData(loadID, currentURL));
  };

  const openEmailPopup = () => showEmailPopup(popupContext, currentLoad);

  const onPostClick = () => {
    const loadInfoOrDefault = loadInfo || defaultLoadInfo;
    if (loadInfoOrDefault.backhauls?.payload) {
      if (!isEmpty(loadInfoOrDefault.backhauls.payload)) {
        const backhaulsInfo = loadInfoOrDefault.backhauls.payload[0];
        const postTruckPickupDates: string[] = [];
        forEach(backhaulsInfo.query.pickupDates, (date) => {
          postTruckPickupDates.push(formatDateYYYYMMDD(date));
        });

        const origin =
          (backhaulsInfo.query.origin.type === LocationType.CITY ||
            backhaulsInfo.query.origin.type === LocationType.ZIP ||
            backhaulsInfo.query.origin.type === LocationType.GEOLOCATION) &&
          backhaulsInfo.query.origin.city
            ? {
                ...backhaulsInfo.query.origin,
                city: convertCityLocationFormatToCapitalize(backhaulsInfo.query.origin.city) ?? '',
              }
            : backhaulsInfo.query.origin;
        const destination =
          (backhaulsInfo.query.destination.type === LocationType.CITY ||
            backhaulsInfo.query.destination.type === LocationType.GEOLOCATION) &&
          backhaulsInfo.query.destination.city
            ? {
                ...backhaulsInfo.query.destination,
                city: convertCityLocationFormatToCapitalize(backhaulsInfo.query.destination.city) ?? '',
              }
            : backhaulsInfo.query.destination;
        dispatch(
          updateTruckPostField({
            origin: origin,
            destination: destination,
            pickupDates: postTruckPickupDates,
          })
        );
        dispatch(setTruckPostPrefilled(true));
        if (props.openPostTruckPanel) {
          props.openPostTruckPanel();
        }
      }
    }
  };

  const onBookNowClick = useOnBookNow(currentLoad);

  const placeNewBid = usePlaceBid(currentLoad);

  const onInteractiveBidClick = useCallback(() => {
    const hasBid = currentLoad?.rateNegotiations && currentLoad?.rateNegotiations?.numberOfBids > 0;
    if (hasBid) {
      history.push({
        pathname: `${generatePath(Routes.BIDS, {
          tab: BidsTabs.InteractiveBids,
          loadID: currentLoad?.id,
          summary: 'summary',
          edit: 'offer',
          bidID: topBidId,
          offerID: topBidId,
        })}`,
        search: bidsEncoder.convertObjectRequestToUrlParams({
          loadId: currentLoad?.id,
          redirectLoadDetailsPath: history.location.pathname,
          redirectLoadDetailsSearch: history.location.search,
        }),
      });
    } else {
      placeNewBid();
    }
  }, [currentLoad?.rateNegotiations, history, props.loadId, currentLoad?.id, topBidId, placeNewBid]);

  const isLoadDetailsFetched = status !== 'Loading';

  const panelContent = () => {
    const isBlocked = isBrokerBlocked(currentLoad);
    const isSingleColumn = isPanelSingleColumn(panelWidth ?? 0);

    const redirectCommunicationPath = bidsEncoder.convertUrlSearchParamsToObject(
      history.location.search
    ).redirectCommunicationPath;
    const isOriginMessaging =
      props.viewType === ResultViewType.Conversation ||
      history.location.pathname.startsWith('/communication/') ||
      redirectCommunicationPath !== undefined;
    const isOriginBids = history.location.pathname.startsWith('/bids/');

    if (status === 'Error' || status === 'PermissionDenied') {
      return <ErrorPanel />;
    }
    if (status === 'Unavailable') {
      return <LoadNotAvailablePanel />;
    }
    if (!currentLoad || !isLoadDetailsFetched || panelWidth === undefined) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
            height: '100vh',
          }}
        >
          <LoadingSpinner />
        </div>
      );
    }
    if (isBlocked) {
      return <BlockedCompanyPanel company={currentLoad.poster} />;
    }
    const actionBar = (
      <LoadDetailsActionsBar
        load={currentLoad}
        isDisabled={!isLoadDetailsFetched}
        width={panelWidth}
        isOriginMessaging={isOriginMessaging}
        isOriginBids={isOriginBids}
        onUpdateStatusClick={onUpdateStatusAction}
        onCallClick={onCallClick}
        onEmailClick={openEmailPopup}
        onInteractiveBidClick={onInteractiveBidClick}
        onVendorBidClick={placeNewBid}
        onBookNowClick={onBookNowClick}
        onClosePanel={props.closeDetailsPanel}
        shouldShowHideButton={!props.shouldNotShowHideButton}
      />
    );

    const companyName = currentLoad.poster?.name;
    const onboardingUrl = currentLoad.onboardingUrl;
    const onboardingBanner = (
      <Banner
        bannerType={BannerType.Onboarding}
        title={t(T.common_loadDetails_onboarding_title)}
        content={
          <>
            <BannerContent>
              {`${t(T.common_loadDetails_onboarding_getOnboarded_start)}${companyName}${t(
                T.common_loadDetails_onboarding_getOnboarded_end
              )}`}
            </BannerContent>
            <OnboardingUrlButtonContainer>
              <LinkButton
                id="onboardingUrl"
                text={t(T.common_loadDetails_onboarding_start)}
                style={{ fontFamily: `${FontFamily.Main}` }}
                onClick={() => {
                  if (onboardingUrl && companyName) {
                    showStartOnboardingPopup(popupContext, prefixHttpsIfNecessary(onboardingUrl), companyName);
                  }
                }}
              />
            </OnboardingUrlButtonContainer>
          </>
        }
      />
    );

    const showOnboardingBanner = onboardingUrl && !currentLoad.poster?.metaData?.onboarded && onboardingBannerEnabled;
    return (
      <>
        <FixedHeader>{!isSingleColumn && actionBar}</FixedHeader>
        {showOnboardingBanner && onboardingBanner}
        {!isSingleColumn ? <BookNowBanner load={currentLoad} /> : null}
        <MapRoutes loadId={props.loadId} onClick={openMap} />
        {isSingleColumn && actionBar}
        {isSingleColumn ? <BookNowBanner load={currentLoad} /> : null}
        {lastTruckAlertMessage !== undefined ? <TruckAlertMessage message={lastTruckAlertMessage} /> : null}
        <LoadDetailsContent width={panelWidth}>
          <div className="column">
            <LoadDetailsData
              load={currentLoad}
              width={panelWidth}
              profitPerDay={profitPerDay}
              isOriginMessaging={isOriginMessaging}
              isOriginBids={isOriginBids}
              onCallClick={onCallClick}
              onEmailClick={openEmailPopup}
              onInteractiveBidClick={onInteractiveBidClick}
              onVendorBidClick={placeNewBid}
              onBookNowClick={onBookNowClick}
              openProfitCalculator={() => {
                setIsProfitCalculatorOpened(true);
                setTimeout(() => {
                  expansionPanelRef?.current?.scrollTo();
                }, 300);
              }}
            />
          </div>
          <div className="column">
            <LoadDetailsExpansionPanels
              ref={expansionPanelRef}
              load={currentLoad}
              selectedSearch={props.selectedSearch}
              width={panelWidth}
              viewType={props.viewType}
              statusBarRef={statusBarRef}
              openedTabs={props.openedTabs}
              isFromMyLoads={props.isFromMyLoads}
              isBackhaulsDisabled={props.isBackhaulsDisabled}
              isProfitCalculatorOpened={isProfitCalculatorOpened}
              setIsProfitCalculatorOpened={setIsProfitCalculatorOpened}
              isOriginMessaging={isOriginMessaging}
              isOriginBids={isOriginBids}
              shouldShowPost={props.openPostTruckPanel !== undefined}
              onGetProfitPerDay={setProfitPerDay}
              onCallClick={onCallClick}
              onEmailClick={openEmailPopup}
              onInteractiveBidClick={onInteractiveBidClick}
              onVendorBidClick={placeNewBid}
              onBookNowClick={onBookNowClick}
              onMapClick={openMap}
              onPostClick={onPostClick}
              onViewLoadsClick={props.openCompanySearchPanel}
              openBackhaulsPanel={openBackhaulsPanel}
              onStatusBarExpand={onStatusBarExpand}
              onTabChange={handleTabChange}
            />
          </div>
        </LoadDetailsContent>
        {isSingleColumn ? (
          <PanelFooter>
            <ContactButtons
              load={currentLoad}
              path={Routes.LOADS_FINDLOADS}
              isOriginMessaging={isOriginMessaging}
              isOriginBids={isOriginBids}
              onCallClick={onCallClick}
              onEmailClick={openEmailPopup}
              onInteractiveBidClick={onInteractiveBidClick}
              onVendorBidClick={placeNewBid}
              onBookNowClick={onBookNowClick}
              width={panelWidth}
            />
          </PanelFooter>
        ) : null}
      </>
    );
  };

  const findLoadLayer = loadSearchObject.searchId ? 0 : 1;
  return (
    <Panel
      id="load_details"
      layer={props.layer ?? (props.viewType === ResultViewType.LoadSearch ? findLoadLayer : BACKHAULS_LAYER)}
      offset={props.selectedView?.field === ViewField.List ? OFFSET_FLOATING : (props.offset ?? 2)}
      size={PanelSize.medium}
      scrollRef={scrollRef}
    >
      <ReactResizeDetector handleWidth={true} onResize={setPanelWidth} />
      <Header
        load={currentLoad}
        viewType={props.viewType}
        isLoadDetailsFetched={isLoadDetailsFetched}
        onClose={props.closeDetailsPanel}
      />
      {panelContent()}
      {currentLoad ? (
        <SocialNetworksPopup
          loadID={currentLoad.id}
          sharedLoadMessage={sharedLoadMessage}
          isLoading={loadInfo?.isLoadingShareLoadUrl}
          error={loadInfo?.loadSharingError}
        />
      ) : null}
    </Panel>
  );
};

const updateTruckPostField = (form: PostTruckAvailabilityForm) => updatePostAvailabilityForm.action(form);
const setTruckPostPrefilled = (prefilled: boolean) => setPostAvailabilityPrefilled.action(prefilled);

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map as map$, mergeMap as mergeMap$, switchMap as switchMap$ } from 'rxjs/operators';

import { ApiError, ApiResponse123, ApiResponseSuccess, Request$ } from '@common/api';
import { getFlowIDFrom } from '@common/helper/FlowIDHeaderHelper';
import { CaseReducer } from '@reduxjs/toolkit';

//@TODO: Refactor `Response` to be replaced with this. Reasoning is, we should not have to check for payload if success is true.
export type ResultResponse<T> = SuccessResponse<T> | ErrorResponse;
interface SuccessResponse<T> {
  success: true;
  payload: T;
  headers?: { [key: string]: any };
}
interface ErrorResponse {
  success: false;
  error: ApiError;
}

export interface ResultResponseAction<T> extends Action {
  response: ResultResponse<T>;
  flowID?: string;
}

export interface ResultResponseActionWithFetchData<T, E = undefined> extends Action {
  response: ResultResponse<T>;
  flowID?: string;
  fetchData?: E;
  metadata?: ApiActionMetadata;
}

export interface Response<T> {
  success: boolean;
  payload?: T | undefined;
  headers?: { [key: string]: any };
  error?: ApiError | undefined;
}
export interface ResponseAction<T, U = any> extends Action {
  response: Response<T>;
  flowID?: string;
  fetchData?: U;
  metadata?: ApiActionMetadata;
}

export interface BaseState {
  isLoading: boolean;
}

export interface SearchResponseMetadata {
  totalResultCount: number;
  queryTime?: number;
  pageResultCount?: number;
  currentOffset?: number;
}
export interface SearchResponsePayload {
  metadata: SearchResponseMetadata;
}

export type EmptyResponse = Response<void>;
export const createResponseAction =
  <T, U = any>(actionType: string) =>
  (response: Response<T>, fetchData?: U): ResponseAction<T, U> => ({
    type: actionType,
    response: response,
    fetchData: fetchData,
  });

export type ApiResponse$<T> = Observable<ApiResponse123<T>>;

interface ApiActionMetadata {
  uid: number;
  overrideResponse?: string;
}

export interface ApiAction<T> extends Action {
  data: T;
  metadata?: ApiActionMetadata;
}

export const responseActionHandler = <ResponsePayload>(
  responseType: string,
  response: ApiResponse123<ResponsePayload>
): ResultResponseAction<ResponsePayload> => {
  if (response instanceof ApiResponseSuccess) {
    return {
      type: responseType,
      response: { success: true, payload: response.data as ResponsePayload, headers: response.headers },
      flowID: getFlowIDFrom(response),
    };
  }
  return {
    type: responseType,
    response: { success: false, error: response },
    flowID: getFlowIDFrom(response),
  };
};

export const createApiAction = <RequestPayload = undefined, ResponsePayload = undefined, ActionExtend = {}>(
  action: string
) => {
  const fetchType = `INITIATE.${action}`;
  const responseType = `COMPLETE.${action}`;
  const responseAction = (response: ApiResponse123<ResponsePayload>): ResultResponseAction<ResponsePayload> =>
    responseActionHandler(responseType, response);

  return {
    fetchType: fetchType,
    responseType: responseType,
    fetchAction: (fetchData: RequestPayload): ApiAction<RequestPayload> => ({
      type: fetchType,
      data: fetchData,
    }),
    responseAction: responseAction,
    initiateCase: <K>(reducerAction: CaseReducer<K, ApiAction<RequestPayload>>) => ({ [fetchType]: reducerAction }),
    completeCase: <K>(reducerAction: CaseReducer<K, ResultResponseAction<ResponsePayload> & ActionExtend>) => ({
      [responseType]: reducerAction,
    }),
    createEpic$: (action$: ActionsObservable<Action>, request: (data: RequestPayload) => Request$<ResponsePayload>) =>
      action$
        .ofType(fetchType)
        .pipe(mergeMap$((action: ApiAction<RequestPayload>) => request(action.data).pipe(map$(responseAction)))),
    createSwitchEpic$: (
      action$: ActionsObservable<Action>,
      request: (data: RequestPayload) => Request$<ResponsePayload>
    ) =>
      action$
        .ofType(fetchType)
        .pipe(switchMap$((action: ApiAction<RequestPayload>) => request(action.data).pipe(map$(responseAction)))),
  };
};

interface ApiActionOptions {
  /** Allow multiple api request in parallel.
   *
   * **false** to abort previous ongoing api requests.
   *
   * Defaults to *true*.
   *
   * The `COMPLETE` action will be replaced by an `ABORTED` action.
   *
   * **NOTE**: This does NOT stop the underlying HTTP request.
   * The use case is when a user clicks items more quickly than the server sends
   * back a response and it is out of order thus showing the wrong info
   * on the screen. It is not to optimize server/network bandwidth.
   */
  multiplex?: boolean;
}

let uid = 100;

export const createApiActionWithFetchData = <
  RequestPayload = undefined,
  ResponsePayload = undefined,
  ActionExtend = {},
>(
  action: string,
  options?: ApiActionOptions
) => {
  const apiAction = createApiAction<RequestPayload, ResponsePayload, ActionExtend>(action);
  const responseAction = (
    response: ApiResponse123<ResponsePayload>,
    requestAction: ApiAction<RequestPayload>
  ): ResultResponseActionWithFetchData<ResponsePayload, RequestPayload> => {
    return { ...apiAction.responseAction(response), fetchData: requestAction.data, metadata: requestAction.metadata };
  };
  return {
    ...apiAction,
    fetchAction: (fetchData: RequestPayload): ApiAction<RequestPayload> => ({
      type: apiAction.fetchType,
      data: fetchData,
      metadata: {
        uid: uid++,
        overrideResponse: (options?.multiplex ?? true) ? undefined : apiAction.responseType,
      },
    }),
    responseAction: responseAction,
    completeCase: <K>(
      reducerAction: CaseReducer<K, ResultResponseActionWithFetchData<ResponsePayload, RequestPayload> & ActionExtend>
    ) => ({
      [apiAction.responseType]: reducerAction,
    }),
    createEpic$: (action$: ActionsObservable<Action>, request: (data: RequestPayload) => Request$<ResponsePayload>) =>
      action$
        .ofType(apiAction.fetchType)
        .pipe(
          mergeMap$((action: ApiAction<RequestPayload>) =>
            request(action.data).pipe(map$((response) => responseAction(response, action)))
          )
        ),
  };
};

export const createAction = <T = undefined>(type: string) => ({
  action: (data: T): ApiAction<T> => ({ type: type, data: data }),
  addCase: <K>(reducerAction: CaseReducer<K, ApiAction<T>>) => ({ [type]: reducerAction }),
  actionType: type,
});

import React, { useEffect, useState } from 'react';

import { filter, find, isEqual, map } from 'lodash';

import { CA_PROVINCES, STATES } from '@common/model/States';
import { T, t } from '@translate';

import {
  StyledContent,
  StyledDisabledPicker,
  StyledPickerTitle,
  StyledPickerWrapper,
  StyledStatePicker,
  StyledStateSelectedPicker,
} from './CalendarStyles';

interface Props {
  onChange: (values: string[]) => void;
  selectedItems: string[];
  maxCount?: number;
  style?: React.CSSProperties;
}

interface StatePickerProps extends Props {
  states: string[];
  selectedStates: string[];
  setSelectedStates: (states: string[]) => void;
}

const StatePicker: React.FC<StatePickerProps> = ({ maxCount, selectedStates, states, onChange, setSelectedStates }) => {
  return (
    <>
      {map(states, (state) => {
        if (find(selectedStates, (value) => isEqual(value, state))) {
          return (
            <StyledStateSelectedPicker
              id={state}
              aria-selected={true}
              aria-disabled={false}
              key={state}
              onClick={removeSelectedClick(state, selectedStates, onChange, setSelectedStates)}
            >
              {state}
            </StyledStateSelectedPicker>
          );
        } else {
          return maxCount !== undefined && selectedStates.length >= maxCount ? (
            <StyledDisabledPicker id={state} aria-selected={false} aria-disabled={true} key={state}>
              {state}
            </StyledDisabledPicker>
          ) : (
            <StyledStatePicker
              id={state}
              key={state}
              aria-selected={false}
              aria-disabled={false}
              onClick={addSelectedClick(state, selectedStates, maxCount, onChange, setSelectedStates)}
            >
              {state}
            </StyledStatePicker>
          );
        }
      })}
    </>
  );
};

export const StatesCalendar: React.FC<Props> = ({ onChange, selectedItems, maxCount, style }) => {
  const [selectedStates, setSelectedStates] = useState<string[]>(selectedItems);

  useEffect(() => {
    setSelectedStates(selectedItems);
  }, [selectedItems]);

  return (
    <StyledPickerWrapper id="states" style={style}>
      <StyledPickerTitle id="usa">{t(T.findLoads_findLoads_usa)}</StyledPickerTitle>
      <StyledContent>
        <StatePicker
          maxCount={maxCount}
          selectedStates={selectedStates}
          states={STATES}
          onChange={onChange}
          setSelectedStates={setSelectedStates}
          selectedItems={selectedItems}
        />
      </StyledContent>
      <StyledPickerTitle id="canada">{t(T.findLoads_findLoads_canada)}</StyledPickerTitle>
      <StyledContent>
        <StatePicker
          maxCount={maxCount}
          selectedStates={selectedStates}
          states={CA_PROVINCES}
          onChange={onChange}
          setSelectedStates={setSelectedStates}
          selectedItems={selectedItems}
        />
      </StyledContent>
    </StyledPickerWrapper>
  );
};

const addSelectedClick =
  (
    state: string,
    selectedStates: string[],
    maxCount: number | undefined,
    onChange: (values: string[]) => void,
    setSelectedStates: (state: string[]) => void
  ) =>
  () => {
    if ((maxCount !== undefined && selectedStates.length < maxCount) || maxCount === undefined) {
      const newSelectedStates = [...selectedStates, state];
      onChange(newSelectedStates);
      setSelectedStates(newSelectedStates);
    }
  };

const removeSelectedClick =
  (
    state: string,
    selectedStates: string[],
    onChange: (values: string[]) => void,
    setSelectedStates: (state: string[]) => void
  ) =>
  () => {
    if (find(selectedStates, (value) => isEqual(value, state))) {
      const newSelectedStates = filter(selectedStates, (value) => !isEqual(value, state));
      onChange(newSelectedStates);
      setSelectedStates(newSelectedStates);
    }
  };

import React, { useEffect } from 'react';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import {
  BillingIssueBannerContent,
  BillingIssueBannerTracking,
  generateBillingIssueBannerContent,
} from '@common/helper';
import { BillingErrorSeverity, BillingIssue } from '@common/model';
import { TrackingTag } from '@common/model/Tracking';
import { Color } from '@style/Color';
import { FontFamily } from '@style/StyleConstants';
import { useSelector } from '@util/hooks';
import { MediaQueries } from '@util/MediaQueries';
import { track } from '@util/trackers/123lbTracker';
import { GA, TrackingCategory } from '@util/trackers/GoogleAnalyticsTracker';

import { default as WarningIcon } from './warning-icon.svg?react';

interface StyledBannerProps {
  textColor: string;
  bannerColor: string;
}

interface BannerStyle {
  bannerColor: Color;
  textColor: Color;
  linkColor: Color;
}

const StyledBanner = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px 10px 6px 20px;
  align-items: center;

  @media ${MediaQueries.lt_mobile} {
    align-items: start;
  }
  background-color: ${(props: StyledBannerProps) => props.bannerColor};
  color: ${(props: StyledBannerProps) => props.textColor};
`;

const BannerText = styled.div`
  padding-left: 6px;
  font-family: ${FontFamily.Main};
  line-height: 18px;
  @media ${MediaQueries.lt_mobile} {
    font-size: 14px;
  }
  @media ${MediaQueries.xxs} {
    font-size: 12px;
  }
  font-size: 16px;
`;

const BannerActionLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  color: ${(props: { linkColor: string }) => props.linkColor};
`;

const generateBannerStyle = (severity?: BillingErrorSeverity): BannerStyle => {
  if (severity === BillingErrorSeverity.Critical) {
    // Critical severity banner style
    return {
      bannerColor: Color.RED_BANNER,
      textColor: Color.WHITE,
      linkColor: Color.YELLOW_BANNER,
    };
  }

  // Moderate severity banner style
  return {
    bannerColor: Color.YELLOW_BANNER,
    textColor: Color.BLACK,
    linkColor: Color.RED_BANNER,
  };
};

export const BillingIssueBanner: React.FC<{ billingIssue?: BillingIssue }> = (props) => {
  const billingErrorSeverity = useSelector((state) => state.user.profile?.payload?.billingErrorSeverity);
  const isAdmin = useSelector((state) => state.user.profile?.payload?.hasMembershipUpgradeAuthority);
  const isCancellingMembership = useSelector((state) => state.userPlan.membershipData?.isCancelling);
  const expirationDate = useSelector((state) => state.user.profile?.payload?.expirationDate);
  const formattedExpirationDate = expirationDate ? moment(expirationDate).format('ll') : undefined;

  const history = useHistory();

  useEffect(() => {
    GA.track(
      TrackingCategory.Message,
      BillingIssueBannerTracking.BannerDisplayed,
      `${props.billingIssue}_${billingErrorSeverity}${isAdmin ? '_withLink' : ''}`,
      false
    );
  }, []);

  const bannerStyle = generateBannerStyle(billingErrorSeverity);
  const bannerContent: BillingIssueBannerContent = generateBillingIssueBannerContent(
    isAdmin ?? false,
    props.billingIssue,
    formattedExpirationDate,
    isCancellingMembership
  );

  const onClickSetPaymentMethod = () => {
    GA.track(
      TrackingCategory.UserAction,
      BillingIssueBannerTracking.BannerClicked,
      `${props.billingIssue}_${billingErrorSeverity}`
    );
    track({ tag: TrackingTag.NavigateToPaymentMethod, data: { billingIssue: props.billingIssue } });
    history.replace(Routes.MORE_SETTINGS_PAYMENT_METHOD);
  };

  const onClickSelectPlan = () => {
    history.replace(Routes.MORE_SETTINGS_MANAGE_PLAN);
  };

  return (
    <StyledBanner id="payment_decline_banner" textColor={bannerStyle.textColor} bannerColor={bannerStyle.bannerColor}>
      <WarningIcon fill={bannerStyle.textColor} style={{ minWidth: 24 }} />
      <BannerText>
        {bannerContent.message}
        {bannerContent.action && (
          <>
            <BannerActionLink
              id="banner_link"
              linkColor={bannerStyle.linkColor}
              onClick={isCancellingMembership ? onClickSelectPlan : onClickSetPaymentMethod}
            >
              {bannerContent.action}
            </BannerActionLink>
            .
          </>
        )}
        {bannerContent.messageLastPart ? ` ${bannerContent.messageLastPart}` : null}
      </BannerText>
    </StyledBanner>
  );
};

export const useIsAccountExpired = () =>
  useSelector((state) => state.user.profile?.payload?.billingIssue === BillingIssue.AccountExpired);

export const useBillingIssueTrackingData = () => {
  const billingErrorSeverity = useSelector((state) => state.user.profile?.payload?.billingErrorSeverity);
  const billingIssue = useSelector((state) => state.user.profile?.payload?.billingIssue);

  return billingIssue && billingIssue !== BillingIssue.None
    ? `${billingIssue}_${billingErrorSeverity}`
    : 'AccountGoodStanding';
};

import React from 'react';

import styled from 'styled-components';

import { ActionIcon } from '@component/icon/ActionIcon';
import VerifiedBadgeIcon from '@component/menu/icon/checkmark-badge-green.svg';
import NotVerifiedBadgeIcon from '@component/menu/icon/icon-unverified.svg';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { ElementSize } from '@style/StyleConstants';
import { T, t } from '@translate';

interface VerificationInfoBadgeProps {
  isVerified: boolean | undefined;
  testID: string;
  style?: React.CSSProperties;
}

interface Props extends VerificationInfoBadgeProps {
  onRedirect?: () => void;
}

interface IconProps {
  size?: number;
}

const Icon = styled(({ size: size, ...rest }) => <img {...rest} />)`
  height: ${({ size }: IconProps) => size ?? ElementSize.Icon}px;
  width: ${({ size }: IconProps) => size ?? ElementSize.Icon}px;
`;

export const VerificationBadge: React.FC<Props> = ({ isVerified, testID, onRedirect, style }) => {
  const { popup, closingPopup } = usePopup();

  const onClick = () => {
    if (popup) {
      closingPopup();
    }
    onRedirect?.();
  };

  return (
    <ActionIcon
      onClick={onClick}
      id={`${testID}_verify_badge`}
      src={isVerified ? VerifiedBadgeIcon : NotVerifiedBadgeIcon}
      alt={isVerified ? 'Verified-badge' : 'Unverified-badge'}
      size={ElementSize.BadgeIconHeight}
      style={style}
    />
  );
};

export const VerificationInfoBadge: React.FC<VerificationInfoBadgeProps> = ({ isVerified, testID, style }) => {
  return (
    <Icon
      title={t(T.settings_dotVerification_tooltip)}
      id={`${testID}_verify_info_badge`}
      src={isVerified ? VerifiedBadgeIcon : NotVerifiedBadgeIcon}
      alt={isVerified ? 'Verified-badge' : 'Unverified-badge'}
      size={ElementSize.BadgeIconHeight}
      style={style}
    />
  );
};

import React from 'react';

import { find, includes } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ExpandableSections, setExpandedSection } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { displayCurrencyOrDash } from '@common/formatter';
import { EQUIPMENTS_FOR_RATE_CHECK, isEquipmentTypeSupportedForRateCheck } from '@common/helper';
import { Equipment, GreenscreensRate as GreenscreensRateType } from '@common/model';
import { AccordionInfoStatus, ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { Subtitle } from '@component/expansionPanel/ExpansionPanelStyle';
import { default as GreenscreensIcon } from '@component/panels/basePanel/loadDetails/marketRates/greenscreens-logo.png';
import {
  GreenscreensRateContent,
  handleGreenscreensInfoClick,
} from '@component/panels/basePanel/loadDetails/marketRates/GreenscreensRateContent';
import { RateUnavailablePopup } from '@component/panels/basePanel/loadDetails/marketRates/RateUnavailablePopup';
import {
  ExpansionPanelDataWrapper,
  InfoIcon,
  LoadDetailsContainer,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text/Text';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

interface GreenscreensRateProps {
  equipments?: Equipment[];
  greenscreensRate?: GreenscreensRateType;
  isLoading: boolean;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
`;

const FullWidthColumn = styled(Column)`
  width: 100%;
`;

export const GreenscreensRate: React.FC<GreenscreensRateProps> = (props) => {
  const dispatch = useDispatch();
  const popupContext = usePopup();
  const isPanelExpanded = useSelector(
    (state) => state.applicationSettings.expandedSections[ExpandableSections.GreenscreensRate]
  );

  const isSupported = isEquipmentTypeSupportedForRateCheck(props.equipments);
  const routeInfo = getRouteInfo(props.greenscreensRate?.laneMiles, props.equipments);
  const { status, subtitle } = getStatusAndSubtitle(isSupported, props.isLoading, props.greenscreensRate);

  const handleTabClick = () => {
    if (status === AccordionInfoStatus.UNAVAILABLE) {
      if (isSupported) {
        popupContext.openingPopup({
          body: <RateUnavailablePopup closingPopup={popupContext.closingPopup} />,
          width: PopupSizes.SMALL,
          isDismissible: true,
          hasCloseButton: true,
        });
      }
      return;
    }
    dispatch(setExpandedSection(ExpandableSections.GreenscreensRate, !isPanelExpanded));
  };

  return (
    <ExpansionPanel
      id="greenscreens_rate"
      icon={GreenscreensIcon}
      status={status}
      isOpened={isPanelExpanded}
      titleText={t(T.common_marketRate_greenscreens_title)}
      subtitleElement={
        <Subtitle
          id="green_subtitle"
          style={{ fontStyle: status === AccordionInfoStatus.AVAILABLE ? 'normal' : 'italic' }}
        >
          {subtitle}
        </Subtitle>
      }
      handleClick={handleTabClick}
      headerBackground={isSupported ? Color.GRAY_PANEL : Color.GRAY_LIGHT}
      shouldHideRightButton={status === AccordionInfoStatus.UNAVAILABLE}
      iconStyle={{ height: 50, width: 50, marginRight: 10 }}
      panelTitleStyle={{ height: 40 }}
      centerAlignTitleItems={true}
    >
      <ExpansionPanelDataWrapper>
        {status === AccordionInfoStatus.AVAILABLE ? (
          <LoadDetailsContainer>
            <FullWidthColumn style={{ paddingTop: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text
                  textStyle={TextStyle.TextContentDetail}
                  style={{ color: Color.LIGHT_BLACK }}
                  id="green_rate_est_title"
                >
                  {t(T.common_marketRate_cargoChief_rateEst)}
                </Text>
                <InfoIcon
                  id="info"
                  alt="info"
                  src={icons.info_small}
                  style={{ margin: '1px 0 auto 5px' }}
                  onClick={() => handleGreenscreensInfoClick(popupContext.openingPopup, popupContext.closingPopup)}
                />
              </div>
              <Text
                textStyle={TextStyle.SubtitleNormalWeight}
                style={{ color: Color.GRAY_21, whiteSpace: 'pre-wrap' }}
                id="green_more_info"
              >
                {routeInfo}
              </Text>
              <Column>
                <GreenscreensRateContent
                  costLow={props.greenscreensRate?.costLow}
                  costHigh={props.greenscreensRate?.costHigh}
                  costHighPerMile={props.greenscreensRate?.costHighPerMile}
                  costLowPerMile={props.greenscreensRate?.costLowPerMile}
                />
              </Column>
            </FullWidthColumn>
          </LoadDetailsContainer>
        ) : null}
      </ExpansionPanelDataWrapper>
    </ExpansionPanel>
  );
};

const getStatusAndSubtitle = (
  isSupported: boolean,
  isLoading: boolean,
  greenscreensRate?: GreenscreensRateType
): { status: AccordionInfoStatus; subtitle: string } => {
  if (!isSupported) {
    return { status: AccordionInfoStatus.UNAVAILABLE, subtitle: t(T.common_marketRate_notAvailableTrailer) };
  }

  if (isLoading) {
    return { status: AccordionInfoStatus.LOADING, subtitle: t(T.findLoads_loadDetails_loading) };
  }

  if (!greenscreensRate) {
    return { status: AccordionInfoStatus.UNAVAILABLE, subtitle: t(T.common_marketRate_notAvailableAtThisTime) };
  }

  return {
    status: AccordionInfoStatus.AVAILABLE,
    subtitle: t(T.common_marketRate_greenscreens_subtitle, {
      minRate: displayCurrencyOrDash(greenscreensRate?.costLowPerMile, true),
      maxRate: displayCurrencyOrDash(greenscreensRate?.costHighPerMile, true),
    }),
  };
};

export const getRouteInfo = (laneMiles?: number, equipments?: Equipment[]) => {
  const info: string[] = [];

  const supportedEquipment = find(equipments, ({ equipmentType }) =>
    includes(EQUIPMENTS_FOR_RATE_CHECK, equipmentType)
  );

  if (supportedEquipment) {
    info.push(supportedEquipment.equipmentType);
  }
  if (laneMiles) {
    info.push(`${laneMiles} ${t(T.common_miles_short)}`);
  }

  return info.join('  |  ');
};

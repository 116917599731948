import { t, T } from '@translate';

import { EquipmentSpecification, EquipmentType } from './Equipment';
import { LoadLocationAnywhere, LoadLocationCity, LoadLocationZip } from './LoadSearchRequest';
import { LoadProgress } from './LoadUserdata';

export enum TripBuilderPanelModals {
  PickUpLocation = 'pickUpLocation',
  PickUpDate = 'pickUpDate',
  PickUpTime = 'pickUpTime',
  DropOffLocation = 'dropOffLocation',
  DropOffDate = 'dropOffDate',
  DropOffTime = 'dropOffTime',
  TrailerType = 'trailerType',
  TripLength = 'tripLength',
  TrailerSpecification = 'trailerSpecification',
  ExcludedStates = 'excludedStates',
}

export interface TripBuilderRouteParams {
  tripBuilderPanelModals: TripBuilderPanelModals;
}

export enum TripTypeCategory {
  LoadByLoad = 'Leg',
  EntireTrip = 'Route',
}

export enum TripTypeChipCategory {
  LoadByLoad = 'Load-by-load',
  EntireTrip = 'Entire trip',
}

export enum FieldLocationType {
  Pickup = 'Pickup',
  Dropoff = 'Dropoff',
}

export enum TripLoadType {
  DeadHead = 'Deadhead',
  Load = 'Load',
}

export enum TripLoadStatus {
  Online = 'Online',
  Cancelled = 'Cancelled',
}

export enum ProfitType {
  Trip = 'trip',
  Day = 'day',
}

export interface TripChoice {
  id: string;
  title: string;
  subtitle: string;
  field: TripTypeCategory;
}

export interface TripCostRevenue {
  fuel: number;
  rates: number;
  tolls: number;
  profit: number;
}

export const LoadByLoadView: TripChoice = {
  id: '0',
  title: t(T.common_tripBuilder_newTrip_loadByLoadLabel),
  subtitle: t(T.common_tripBuilder_newTrip_loadByLoadDescription),
  field: TripTypeCategory.LoadByLoad,
};
export const EntireTripView: TripChoice = {
  id: '1',
  title: t(T.common_tripBuilder_newTrip_entireTripLabel),
  subtitle: t(T.common_tripBuilder_newTrip_entireTripDescription),
  field: TripTypeCategory.EntireTrip,
};

export const TripKeys: TripChoice[] = [LoadByLoadView, EntireTripView];

export type TripLoad = any;

export interface UserTrip {
  id: string;
  mode: TripTypeCategory;
  search: {
    schedule: {
      origin: TripBuilderLocation;
      destination?: TripBuilderLocation | undefined;
      timeFrame: {
        localStart: string;
        localStop: string;
      };
      area: {
        countries?: string[];
        states?: string[];
      };
    };
    truck: {
      type: EquipmentType;
      mpg: number;
      mpgEmpty: number;
    };
    loadFilter: {
      maxLength: number;
      strictLength: false;
      maxWeight: number;
      strictWeight: false;
      maxAge: number;
      minMileage: number;
      maxMileage: number;
    };
    // maxLoads: number;
    tripLoads: string[];
  };
  loadCount: number;
  mileage: number;
  duration: number;
  profit: {
    rate: number;
    fuel: number;
    toll: number;
  };
  available: boolean;
  loadIds: string[];
}

export interface TripBuilderLocation {
  radius?: number;
  city: string;
  state: string;
  zipCode?: string;
}

export type TripBuilderDestinationLocation = LoadLocationCity | LoadLocationZip | LoadLocationAnywhere;

export interface UserTripResponse {
  id: string;
  mode: TripTypeCategory;
  search: {
    schedule: {
      origin: TripBuilderLocation;
      destination: TripBuilderLocation;
      timeFrame: {
        localStart: string;
        localStop: string;
      };
      area: {
        countries?: string[];
        states?: string[];
      };
    };
    truck: {
      type: EquipmentType;
      mpg: number;
      mpgEmpty: number;
    };
    loadFilter: {
      maxLength: number;
      strictLength: false;
      maxWeight: number;
      strictWeight: false;
      maxAge: number;
      minMileage: number;
      maxMileage: number;
    };
    // maxLoads: number;
    tripLoads: string[];
  };
  loadCount: number;
  mileage: number;
  duration: number;
  profit: {
    rate: number;
    fuel: number;
    toll: number;
  };
  available: boolean;
  loadIds: string[];
  unavailableLoadIds: string[];
}

export interface CreateUserTripRequest {
  mode: TripTypeCategory;
  search: {
    schedule: {
      origin: TripBuilderLocation;
      destination?: TripBuilderLocation;
      timeFrame: {
        localStart: string;
        localStop?: string;
      };
      area: {
        countries?: string[];
        states?: string[];
      };
    };
    truck: {
      type: EquipmentType;
      mpg?: number;
      mpgEmpty?: number;
    };
    loadFilter: {
      maxLength: number;
      strictLength: boolean;
      maxWeight: number;
      strictWeight: boolean;
      maxAge?: number;
      minMileage?: number;
      maxMileage?: number;
    };
    // maxLoads: number;
    tripLoads?: Array<{ id: string }>;
  };
}

export interface NewUserTrip {
  mode: TripTypeCategory;
  search: {
    schedule: {
      origin: TripBuilderLocation;
      destination?: TripBuilderLocation | undefined;
      timeFrame: {
        localStart: string;
        localStop?: string;
      };
      area: {
        countries?: string[];
        states?: string[];
      };
    };
    truck: {
      type: EquipmentType;
      mpg?: number;
      mpgEmpty?: number;
    };
    loadFilter: {
      maxLength: number;
      strictLength: boolean;
      maxWeight: number;
      strictWeight: boolean;
      maxAge?: number;
      minMileage?: number;
      maxMileage?: number;
    };
    // maxLoads: number;
    tripLoads?: Array<{ id: string }>;
  };
}

export interface TripRouteDetails {
  segments: TripSegmentDetails[];
}

export interface TripLoadsDetails {
  loads: Segments[];
}

export interface Segments {
  segments: TripSegmentDetails[];
  unavailableLoadIds: string[];
}

export interface TripSegmentDetails {
  id: string;
  company?: {
    name?: string;
    onboarded: boolean;
    favorite: boolean;
  };
  age: number;
  note?: string;
  saved: boolean;
  contacted: boolean;
  progress: LoadProgress;
  origin: TripBuilderLocation;
  destination: TripBuilderLocation;
  equipments: Array<{ type: EquipmentType; specifications: EquipmentSpecification[] }>;
  pickupDateTime?: string;
  length?: number;
  weight?: number;
  status?: TripLoadStatus;
  dataNotAvailable: boolean;
  type: TripLoadType;
  mileage: number;
  duration: number;
  profit: {
    rate: number;
    fuel: number;
    toll: number;
  };
}

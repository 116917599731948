import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Company } from '@common/model';
import { hideBrokers } from '@common/redux/epic/BrokerEpic';
import { ViewFilter } from '@common/redux/epic/loadSearch/HelperFunctions';
import { MultipurposeButton } from '@component/buttons';
import { ErrorIcon } from '@component/panels/notFoundSearches/ErrorIcon';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

import { default as BlockedLoadIcon } from './loadBlocked.svg';
import { default as NewSearch } from './new-search.svg';
import { default as NotFoundIcon } from './noLoadsFound.svg';
import { NotFoundPanel } from './NotFoundPanel';

interface BlockedCompanyProps {
  company: Company | undefined;
}

interface NotFoundSearchesProps {
  title: string;
  subtitle: string;
}

const ButtonContainer = styled.div`
  display: flex;
  width: ${ElementSize.ButtonWidth}px;
  margin-top: ${Spacing.ScreenSide}px;
`;

export const BlockedCompanyPanel: React.FC<BlockedCompanyProps> = React.memo(({ company }) => {
  const dispatch = useDispatch();
  return (
    <NotFoundPanel
      title={`${t(T.findLoads_findLoads_loadDetailsNotShownForBlockedCompany)}${company && `: ${company.name}`}`}
      imageSection={<img id="image" src={BlockedLoadIcon} alt="companyBlocked" />}
      controlSection={
        <ButtonContainer>
          <MultipurposeButton
            id="unblock"
            variant={'outlined'}
            color={'secondary'}
            text={t(T.findLoads_findLoads_unblock)}
            onClick={() => {
              if (!company) {
                return;
              }

              dispatch(hideBrokers([{ id: company.id, hide: false }], false, undefined));
            }}
          />
        </ButtonContainer>
      }
    />
  );
});

export const ErrorPanel: React.FC<{ subtitle?: string; onClick?: () => void }> = React.memo(({ subtitle, onClick }) => (
  <NotFoundPanel
    title={t(T.common_error)}
    subtitle={subtitle || t(T.errors_systemEncounteredError)}
    imageSection={<ErrorIcon id="image" />}
    controlSection={
      onClick ? (
        <ButtonContainer>
          <MultipurposeButton
            id="unblock"
            variant={'outlined'}
            color={'secondary'}
            text={t(T.common_tryAgain)}
            onClick={onClick}
          />
        </ButtonContainer>
      ) : (
        <></>
      )
    }
  />
));

export const NotFoundSearchesPanel: React.FC<NotFoundSearchesProps> = React.memo(({ title, subtitle }) => (
  <NotFoundPanel
    title={title}
    subtitle={subtitle}
    imageSection={
      <>
        <img src={NotFoundIcon} alt="noLoadsFound" />
      </>
    }
  />
));

export const NotFoundNamedSearchesPanel: React.FC<{ onClickButton: () => void }> = React.memo(({ onClickButton }) => (
  <NotFoundPanel
    title={t(T.common_namedSearch_emptyPanel_title)}
    subtitle={t(T.common_namedSearch_emptyPanel_subtitle)}
    imageSection={<img src={NewSearch} alt="noSearchFound" />}
    controlSection={
      <ButtonContainer>
        <MultipurposeButton
          id="create_new_search_btn"
          variant="contained"
          text={t(T.common_namedSearch_createNewButton)}
          color="primary"
          onClick={onClickButton}
        />
      </ButtonContainer>
    }
  />
));

export const NotFoundAlertSearchesPanel: React.FC<{
  onClickButton: () => void;
  viewFilter?: ViewFilter;
}> = React.memo(({ onClickButton, viewFilter }) => {
  const isViewAlertOnly = viewFilter === ViewFilter.WITH_ALERTS;
  return (
    <NotFoundPanel
      title={
        isViewAlertOnly
          ? t(T.common_namedSearch_view_option_withAlerts)
          : t(T.common_namedSearch_view_option_withoutAlerts)
      }
      subtitle={
        isViewAlertOnly
          ? t(T.common_namedSearch_emptyAlertsPanel_subtitle)
          : t(T.common_namedSearch_emptyAlertsPanel_subtitleWithoutAlerts)
      }
      imageSection={<img src={NewSearch} alt="noSearchFound" />}
      controlSection={
        <ButtonContainer>
          <MultipurposeButton
            id="view_all_btn"
            variant="contained"
            text={t(T.common_namedSearch_emptyAlertsPanel_viewAll)}
            color="primary"
            onClick={onClickButton}
          />
        </ButtonContainer>
      }
    />
  );
});

export const LoadNotAvailablePanel: React.FC = () => (
  <NotFoundPanel
    title={t(T.common_load_loadNoLongerAvailable)}
    imageSection={<img id="image" src={BlockedLoadIcon} alt="companyBlocked" />}
  />
);

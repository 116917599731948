import React, { useCallback } from 'react';

import { generatePath, useHistory } from 'react-router-dom';

import { Routes } from '@/router/Routes';
import { LoadWithDataForBiddingOnly } from '@common/model';
import { showVerifyDOTPopup, verifyDotPopupType } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPopups';
import { FORCE_VERIFICATION_URL_QUERY } from '@component/panels/settings/company/BusinessProfileHelper';
import { EnterDOT } from '@component/popup/EnterDotPopup';
import { PlaceBidPopup } from '@component/popup/PlaceBidPopup';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { useSelector } from '@util/hooks';

export const usePlaceBid = (load: LoadWithDataForBiddingOnly | undefined) => {
  const popupContext = usePopup();
  const history = useHistory();

  const companyDOT = useSelector((state) => state.companyState.company?.usdotNumber);
  const isDOTVerified = useSelector((state) => state.user.profile?.payload?.isUsdotNumberVerified);

  const showPlaceBidPopup = useCallback(() => {
    popupContext.openingPopup({
      body: <PlaceBidPopup load={load} />,
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  }, [popupContext, load]);

  const showDOTVerificationPopup = useCallback(() => {
    showVerifyDOTPopup(
      popupContext,
      () => {
        history.push({
          pathname: generatePath(Routes.MORE_SETTINGS, { filter: Filter.BusinessProfile }),
          search: `${FORCE_VERIFICATION_URL_QUERY}=1`,
        });
      },
      verifyDotPopupType.bid,
      companyDOT
    );
  }, [popupContext, companyDOT]);

  const onBidClick = useCallback(() => {
    if (companyDOT) {
      if (isDOTVerified) {
        showPlaceBidPopup();
      } else {
        showDOTVerificationPopup();
      }
    } else {
      popupContext.openingPopup({
        body: <EnterDOT onSuccess={showDOTVerificationPopup} />,
        isDismissible: true,
        hasCloseButton: true,
        width: PopupSizes.SMALL,
      });
    }
  }, [companyDOT, isDOTVerified, showPlaceBidPopup, showDOTVerificationPopup, popupContext]);

  return onBidClick;
};

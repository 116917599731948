import React, { ReactElement } from 'react';

import { isArray, map, replace } from 'lodash';
import { Route, RouteProps } from 'react-router-dom';

interface RouterProps extends RouteProps {
  baseUrl?: string;
  appendedPath?: string;
}

export const UnitedRoute: React.FC<RouterProps> = (props) => {
  return (
    <>
      {props.children &&
        React.Children.map(props.children, (child: ReactElement) => {
          let newPath = `${child.props.baseUrl ?? props.baseUrl ?? ''}`;
          if (props.appendedPath) {
            newPath = `${newPath}${props.appendedPath}`;
          }
          newPath = removeDoubleSlash(newPath);
          return React.cloneElement(child, { baseUrl: newPath });
        })}
    </>
  );
};

export const NestedRoute: React.FC<RouterProps> = ({ baseUrl, children, path, ...rest }) => {
  //added support for array of paths to use in path prop of <Route />
  const newPath = appendBaseUrlToRoutePath(baseUrl, path);
  return (
    <Route path={newPath} {...rest}>
      {children}
    </Route>
  );
};

export const removeDoubleSlash = (path: string) => replace(path, '//', '/');

const appendBaseUrlToRoutePath = (
  baseUrl: string | undefined,
  paths: string | string[] | undefined
): string | string[] => {
  if (isArray(paths)) {
    return map(paths, (path) => removeDoubleSlash(`${baseUrl ?? ''}${path}`));
  }
  return removeDoubleSlash(`${baseUrl ?? ''}${paths}`);
};

import { round, toNumber } from 'lodash';

import { removeComma } from '@common/formatter';

export const toNumberWithComma = (valueWithComma?: string) => {
  let value = valueWithComma;
  if (value === undefined) {
    value = '0';
  }
  return toNumber(removeComma(value));
};

export const getFuelCosts = (
  mpg: number,
  miles: number,
  costPerGallon: number,
  costPerGallonDiscount: number,
  isFuelDiscountChecked: boolean
) => {
  if (mpg === 0) {
    return 0;
  }
  return round((miles / mpg) * (costPerGallon - (isFuelDiscountChecked ? costPerGallonDiscount : 0)), 2);
};

export const getAdditionalCosts = (
  dispatchFee: number,
  rate: number,
  tollCost: number,
  otherFee: number,
  isDispatchFeeFlatAmount: boolean
) => {
  let updatedDispatchFee = dispatchFee;
  if (!isDispatchFeeFlatAmount) {
    updatedDispatchFee = (rate * dispatchFee) / 100;
  }
  return tollCost + updatedDispatchFee + otherFee;
};

export const getProfitPerMile = (totalProfit: number, miles: number) => (miles === 0 ? 0 : totalProfit / miles);

export const getTotalProfit = (rate: number, fuelCosts: number, additionalCosts: number) => {
  return rate - fuelCosts - additionalCosts;
};

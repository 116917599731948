import { concat, filter, find, map, some } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { mergeMap as mergeMap$ } from 'rxjs/operators';

import { CarrierInfoClient } from '@/client/CarrierInfoClient';
import { CarrierMapInfo } from '@/model/Carrier';
import { Api, ApiError } from '@common/api';
import { CompanyClient } from '@common/client';
import {
  BrokerPaginationRequest,
  CARRIER_REQUEST_LIMIT_APPENDING,
  CARRIER_REQUEST_LIMIT_DEFAULT,
  CARRIER_REQUEST_OFFSET_INCREMENT,
  CarrierContactInfo,
  CarrierSearchRequest,
  CarrierSearchResponse,
  CompanyUserDataPayload,
  CompanyUserDataQuery,
  createDefaultCarriersRequest,
  UpdateCarrierRequest,
  UpdateCarriersRequest,
} from '@common/model';
import {
  createAction,
  createApiAction,
  createApiActionWithFetchData,
  EmptyResponse,
  Response,
  ResultResponseAction,
} from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export enum CarrierActionType {
  Favorite,
  Unfavorite,
  Block,
  Unblock,
}

type CarriersRequest = BrokerPaginationRequest & CarrierSearchRequest;

interface PartialStateCarriers {
  carriers: CarrierState;
}

const fetchCarriersAction = createApiActionWithFetchData<CarrierSearchRequest, CarrierSearchResponse>('FETCH_CARRIERS');
const fetchFavoriteCarriersCountAction = createApiActionWithFetchData<CompanyUserDataQuery, CarrierSearchResponse>(
  'FETCH_FAVORITE_CARRIERS_COUNT'
);
const fetchBlockedCarriersCountAction = createApiActionWithFetchData<CompanyUserDataQuery, CarrierSearchResponse>(
  'FETCH_BLOCKED_CARRIERS_COUNT'
);
const fetchMoreCarriersAction = createApiAction<CarrierSearchRequest, CarrierSearchResponse>('FETCH_MORE_CARRIERS');
const favoriteCarrierAction = createApiActionWithFetchData<UpdateCarrierRequest, EmptyResponse>('FAVORITE_CARRIER');
const blockCarrierAction = createApiActionWithFetchData<UpdateCarrierRequest, EmptyResponse>('BLOCK_CARRIER');
export const favoriteCarriersBulkAction = createApiActionWithFetchData<UpdateCarriersRequest, EmptyResponse>(
  'FAVORITE_CARRIERS'
);
export const blockCarriersBulkAction = createApiActionWithFetchData<UpdateCarriersRequest, EmptyResponse>(
  'BLOCK_CARRIERS'
);
const setFreeTextFilterAction = createAction<string>('SET_FREE_TEXT_FILTER');
const clearCarrierUpdateAction = createAction<undefined>('CLEAR_CARRIER_UPDATE');

export type CompanyUserDataResponse = Response<CompanyUserDataPayload>;

export interface CarrierResponseAction extends Action {
  response: CompanyUserDataResponse;
}

export const fetchCarriers = (carriersRequest: CarrierSearchRequest) =>
  fetchCarriersAction.fetchAction(carriersRequest);
export const fetchFavoriteCarriersCount = (carrierCountRquest: CarrierSearchRequest) =>
  fetchFavoriteCarriersCountAction.fetchAction(carrierCountRquest);
export const fetchBlockedCarriersCount = (carrierCountRquest: CarrierSearchRequest) =>
  fetchBlockedCarriersCountAction.fetchAction(carrierCountRquest);
export const fetchMoreCarriers = (carriersRequest: CarrierSearchRequest) =>
  fetchMoreCarriersAction.fetchAction(carriersRequest);
export const favoriteCarrier = (id: string) =>
  favoriteCarrierAction.fetchAction({ id: id, status: { isFavorite: true } });
export const removeFavoriteCarrier = (id: string) =>
  favoriteCarrierAction.fetchAction({ id: id, status: { isFavorite: false } });
export const favoriteCarriers = (contactIds: string[], isFavorite: boolean) =>
  favoriteCarriersBulkAction.fetchAction({ contactIds: contactIds, status: { isFavorite: isFavorite } });
export const blockCarrier = (id: string) =>
  blockCarrierAction.fetchAction({ id: id, status: { isBlocked: true, isFavorite: false } });
export const unblockCarrier = (id: string) => blockCarrierAction.fetchAction({ id: id, status: { isBlocked: false } });
export const blockCarriers = (contactIds: string[], isBlocked: boolean) =>
  blockCarriersBulkAction.fetchAction({ contactIds: contactIds, status: { isBlocked: isBlocked, isFavorite: false } });
export const setFreeTextFilter = (freeTextFilter: string) => setFreeTextFilterAction.action(freeTextFilter);
export const clearCarrierUpdate = () => clearCarrierUpdateAction.action(undefined);

const carrierMapHistoryApiAction = createApiActionWithFetchData<string, CarrierMapInfo>('CONTACT_CARRIER_MAP_HISTORY');
export const fetchCarrierMapHistory = carrierMapHistoryApiAction.fetchAction;
export interface CarrierState {
  carriersRequest: CarriersRequest;
  isLastResult: boolean;
  isLoadingMore: boolean;
  isLoading: boolean;
  carriers: CarrierContactInfo[];
  carriersUpdate: {
    isLoading: boolean;
    updatedSuccessfully?: boolean;
    error?: ApiError | undefined;
    updateType?: CarrierActionType;
  };
  freeTextFilter: string;
  isLoadedFavoritesCount: boolean;
  isLoadedBlockedCount: boolean;
  favoriteCarriersCount: number;
  blockedCarriersCount: number;
  carrierMapInfo: CarrierMapInfo;
}

const initialState: CarrierState = {
  carriersRequest: createDefaultCarriersRequest(),
  isLastResult: false,
  isLoadingMore: false,
  carriers: [],
  carriersUpdate: {
    isLoading: false,
  },
  isLoading: false,
  freeTextFilter: '',
  favoriteCarriersCount: 0,
  blockedCarriersCount: 0,
  isLoadedFavoritesCount: false,
  isLoadedBlockedCount: false,
  carrierMapInfo: { statesHistory: [] },
};

export const carrierReducer = createMergedReducer(initialState, [
  fetchCarriersAction.initiateCase((state, action) => {
    state.isLoading = true;
    state.carriers = [];
    state.carriersRequest = { ...createDefaultCarriersRequest(), ...action.data };
  }),
  fetchCarriersAction.completeCase((state, action) => fetchedCarriersReducer(state, action, true)),
  fetchMoreCarriersAction.initiateCase((state) => {
    state.carriersRequest = {
      ...state.carriersRequest,
      limit: CARRIER_REQUEST_LIMIT_APPENDING,
      offset:
        state.carriersRequest.offset === 0
          ? CARRIER_REQUEST_LIMIT_DEFAULT
          : state.carriersRequest.offset + CARRIER_REQUEST_OFFSET_INCREMENT,
    };
    state.isLoadingMore = true;
  }),
  fetchMoreCarriersAction.completeCase((state, action) => fetchedCarriersReducer(state, action)),
  setFreeTextFilterAction.addCase((state, action) => {
    state.freeTextFilter = action.data;
  }),
  clearCarrierUpdateAction.addCase((state) => {
    state.carriersUpdate = {
      isLoading: false,
    };
  }),
  favoriteCarrierAction.initiateCase((state, action) => {
    state.carriersUpdate = {
      isLoading: true,
      updateType: action.data.status.isFavorite ? CarrierActionType.Favorite : CarrierActionType.Unfavorite,
    };
  }),
  favoriteCarrierAction.completeCase((state, action) => {
    state.carriersUpdate.isLoading = false;
    state.carriersUpdate.updatedSuccessfully = action.response.success;
    if (!action.response.success) {
      state.carriersUpdate.error = action.response.error;
    }
    if (action.fetchData?.id !== undefined && action.response.success) {
      updateCarriersList(state, [action.fetchData.id], !!state.carriersRequest.isFavorite);
    }
  }),
  favoriteCarriersBulkAction.initiateCase((state, action) => {
    state.carriersUpdate = {
      isLoading: true,
      updateType: action.data.status.isFavorite ? CarrierActionType.Favorite : CarrierActionType.Unfavorite,
    };
  }),
  favoriteCarriersBulkAction.completeCase((state, action) => {
    state.carriersUpdate.isLoading = false;
    state.carriersUpdate.updatedSuccessfully = action.response.success;
    if (!action.response.success) {
      state.carriersUpdate.error = action.response.error;
    }
    if (action.response.success) {
      updateCarriersList(state, action.fetchData?.contactIds ?? [], !!state.carriersRequest.isFavorite);
    }
  }),
  blockCarrierAction.initiateCase((state, action) => {
    state.carriersUpdate = {
      isLoading: true,
      updateType: action.data.status.isBlocked ? CarrierActionType.Block : CarrierActionType.Unblock,
    };
  }),
  blockCarrierAction.completeCase((state, action) => {
    state.carriersUpdate.isLoading = false;
    state.carriersUpdate.updatedSuccessfully = action.response.success;
    if (!action.response.success) {
      state.carriersUpdate.error = action.response.error;
    }
    if (action.fetchData?.id !== undefined && action.response.success) {
      updateCarriersList(state, [action.fetchData.id], !!state.carriersRequest.isBlocked);
    }
  }),
  blockCarriersBulkAction.initiateCase((state, action) => {
    state.carriersUpdate = {
      isLoading: true,
      updateType: action.data.status.isBlocked ? CarrierActionType.Block : CarrierActionType.Unblock,
    };
  }),
  blockCarriersBulkAction.completeCase((state, action) => {
    state.carriersUpdate.isLoading = false;
    state.carriersUpdate.updatedSuccessfully = action.response.success;
    if (!action.response.success) {
      state.carriersUpdate.error = action.response.error;
    }
    if (action.response.success) {
      updateCarriersList(state, action.fetchData?.contactIds ?? [], !!state.carriersRequest.isBlocked);
    }
  }),
  fetchFavoriteCarriersCountAction.initiateCase((state, action) => {
    state.isLoading = true;
    state.isLoadedFavoritesCount = false;
    state.carriersRequest = { ...createDefaultCarriersRequest(), ...action.data };
  }),
  fetchFavoriteCarriersCountAction.completeCase((state, action) => {
    state.isLoadedFavoritesCount = true;
    if (action.response.success) {
      state.favoriteCarriersCount = action.response.payload.totalCount;
    }
  }),
  fetchBlockedCarriersCountAction.initiateCase((state, action) => {
    state.isLoading = true;
    state.isLoadedBlockedCount = false;
    state.carriersRequest = { ...createDefaultCarriersRequest(), ...action.data };
  }),
  fetchBlockedCarriersCountAction.completeCase((state, action) => {
    state.isLoadedBlockedCount = true;
    if (action.response.success) {
      state.blockedCarriersCount = action.response.payload.totalCount;
    }
  }),
  carrierMapHistoryApiAction.initiateCase((state) => {
    state.carrierMapInfo.statesHistory = [];
  }),
  carrierMapHistoryApiAction.completeCase((state, action) => {
    if (action.response.success) {
      state.carrierMapInfo = action.response.payload;
    }
  }),
]);

const updateCarriersCount$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(favoriteCarrierAction.responseType, blockCarrierAction.responseType).pipe(
    mergeMap$(() => {
      return of$(fetchFavoriteCarriersCount({ isFavorite: true }), fetchBlockedCarriersCount({ isBlocked: true }));
    })
  );

/**
 * removes carriers from list by their id
 */
const removeCarriers = (carriers: CarrierContactInfo[], updatedCarriersIds: string[]) =>
  filter(
    carriers,
    (carrier: CarrierContactInfo) =>
      !some(updatedCarriersIds, (updatedCarrierId: string) => updatedCarrierId === carrier.guid)
  );

/**
 * updates carriers list data about Favorite / Onboard / Block status
 */
const updateCarriers = (
  carriers: CarrierContactInfo[],
  updatedCarriersIds: string[],
  updateCriteria: CarrierActionType | undefined
) =>
  map(carriers, (carrier) => {
    const shouldUpdateCarrier =
      find(updatedCarriersIds, (updatedCarrierId) => updatedCarrierId === carrier.guid) !== undefined;
    return shouldUpdateCarrier ? updateCarrier(carrier, updateCriteria) : carrier;
  });

const updateCarrier = (carrier: CarrierContactInfo, updateCriteria: CarrierActionType | undefined) => {
  if (updateCriteria === undefined) {
    return carrier;
  }
  switch (updateCriteria) {
    case CarrierActionType.Favorite:
      return {
        ...carrier,
        isFavorite: true,
      };
    case CarrierActionType.Unfavorite:
      return {
        ...carrier,
        isFavorite: false,
      };
    case CarrierActionType.Block:
      return {
        ...carrier,
        isBlocked: true,
      };
    default:
      return {
        ...carrier,
        isBlocked: false,
      };
  }
};

const updateCarriersList = (state: CarrierState, updatedCarriersIds: string[], shouldRemoveCarriers: boolean) => {
  if (shouldRemoveCarriers) {
    state.carriers = removeCarriers(state.carriers, updatedCarriersIds);
  } else {
    state.carriers = updateCarriers(state.carriers, updatedCarriersIds, state.carriersUpdate.updateType);
  }
};

const fetchedCarriersReducer = (
  state: CarrierState,
  action: ResultResponseAction<CarrierSearchResponse>,
  isInitial: boolean = false
) => {
  if (action.response.success) {
    const newCarriers = action.response.payload.carriers;
    state.carriers = isInitial ? newCarriers : concat(state.carriers, newCarriers);
    state.isLastResult = newCarriers.length < state.carriersRequest.limit;
  }
  state.isLoading = false;
  state.isLoadingMore = false;
};

export const createCarrierEpic = <T extends PartialStateCarriers>(api: Api) => {
  const companyClient = new CompanyClient(api);
  const carrierInfoClient = new CarrierInfoClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<T>) =>
    merge$(
      fetchCarriersAction.createEpic$(action$, (data) =>
        companyClient.searchCarriers$(data, state$.value.carriers.carriersRequest)
      ),
      fetchMoreCarriersAction.createEpic$(action$, (data) =>
        companyClient.searchCarriers$(data, state$.value.carriers.carriersRequest)
      ),
      favoriteCarrierAction.createEpic$(action$, (data) => companyClient.updateCarrier$(data.id, data.status)),
      favoriteCarriersBulkAction.createEpic$(action$, (data) =>
        companyClient.updateCarriers$(data.contactIds, data.status)
      ),
      blockCarrierAction.createEpic$(action$, (data) => companyClient.updateCarrier$(data.id, data.status)),
      blockCarriersBulkAction.createEpic$(action$, (data) =>
        companyClient.updateCarriers$(data.contactIds, data.status)
      ),
      fetchFavoriteCarriersCountAction.createEpic$(action$, (data) =>
        companyClient.searchCarriers$(data, state$.value.carriers.carriersRequest)
      ),
      fetchBlockedCarriersCountAction.createEpic$(action$, (data) =>
        companyClient.searchCarriers$(data, state$.value.carriers.carriersRequest)
      ),
      carrierMapHistoryApiAction.createEpic$(action$, (carrierID) => carrierInfoClient.fetchCarrierMapInfo$(carrierID)),

      updateCarriersCount$(action$)
    );
};

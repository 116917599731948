import React, { useState } from 'react';

import { isEmpty, map } from 'lodash';

import { isLoadBidSent, isLoadCalled, isLoadEmailed, isLoadMessaged } from '@common/helper';
import { BookingStatus, Load, LoadContactOption } from '@common/model';
import { usePushPanel } from '@component/panel/PanelHooks';
import { LoadContactCallbackProps } from '@component/panels/findLoads/loadDetailsPanel/buttons/ContactButtons';
import { renderPhoneNumber } from '@component/panels/findLoads/loadDetailsPanel/buttons/PhoneButton';
import { ActionButtonBold } from '@component/statusBar/StatusBarStyle';
import { T, t } from '@translate';
import { useContactOptions } from '@util/helper/ContactOptionsHelper';

interface Props extends LoadContactCallbackProps {
  load?: Load;
  onVhubBook: () => void;
  isOriginMessaging: boolean;
  isOriginBids: boolean;
}

interface ButtonProps {
  onClick: () => void;
}

export const StatusBarContactOptions: React.FC<Props> = (props) => {
  const contactOptions = useContactOptions(props.load, props.isOriginMessaging, props.isOriginBids, true);
  return isEmpty(contactOptions) ? null : <>{map(contactOptions, (option) => renderContactOption(option, props))}</>;
};

const renderContactOption = (option: LoadContactOption, props: Props) => {
  switch (option) {
    case LoadContactOption.Bid:
      return <BidButton load={props.load} onClick={props.onInteractiveBidClick} />;
    case LoadContactOption.VendorBid:
      return <BidButton load={props.load} onClick={props.onVendorBidClick} />;
    case LoadContactOption.BookNow:
      return <BookNowButton load={props.load} onClick={props.onBookNowClick} />;
    case LoadContactOption.Call:
      return <CallButton load={props.load} onClick={props.onCallClick} />;
    case LoadContactOption.Email:
      return <EmailButton onClick={props.onEmailClick} load={props.load} />;
    case LoadContactOption.Message:
      return <MessageButton load={props.load} />;
    case LoadContactOption.VhubBook:
      return <VhubBookButton onClick={props.onVhubBook} />;
    default:
      return null;
  }
};

const BidButton: React.FC<{ load?: Load } & ButtonProps> = (props) => {
  const isBidSent = isLoadBidSent(props.load) || props.load?.vendorInfo?.bid;
  return (
    <ActionButtonBold
      id="bid"
      text={isBidSent ? t(T.common_vendorBid_bidSent) : t(T.common_bids_buttons_bid)}
      variant={'outlined'}
      color={'secondary'}
      onClick={props.onClick}
    />
  );
};

const BookNowButton: React.FC<{ load?: Load } & ButtonProps> = (props) => {
  const bookingStatus = props.load?.bookNow?.bookingStatus;
  const isBooked = bookingStatus === BookingStatus.Accepted || bookingStatus === BookingStatus.Pending;

  const isBookedText =
    bookingStatus === BookingStatus.Accepted ? t(T.common_bookNow_booked) : t(T.common_bookNow_bookingPending);

  return bookingStatus === BookingStatus.Rejected ? null : (
    <ActionButtonBold
      id="bookNow"
      text={isBooked ? isBookedText : t(T.common_bookNow_title)}
      variant={'outlined'}
      color={'secondary'}
      onClick={props.onClick}
    />
  );
};

const CallButton: React.FC<{ load?: Load } & ButtonProps> = (props) => {
  const [isPhoneShown, setIsPhoneShown] = useState(false);
  const isCalled = isLoadCalled(props.load);
  const onCall = () => {
    if (isPhoneShown && props.onClick) {
      props.onClick();
    }
    setIsPhoneShown(true);
  };
  return (
    <ActionButtonBold
      id="call"
      text={
        isPhoneShown
          ? renderPhoneNumber(props.load?.dispatchPhone)
          : isCalled
            ? t(T.common_load_Call_again)
            : t(T.common_load_Call)
      }
      variant={'outlined'}
      color={'secondary'}
      onClick={onCall}
    />
  );
};

const EmailButton: React.FC<{ load?: Load } & ButtonProps> = (props) => (
  <ActionButtonBold
    id="email"
    text={isLoadEmailed(props.load) ? t(T.common_emailSent) : t(T.common_email)}
    variant={'outlined'}
    color={'secondary'}
    onClick={props.onClick}
  />
);

const MessageButton: React.FC<{ load?: Load }> = (props) => {
  const pushCommunicationPanel = usePushPanel('/communication/');
  const isMessaged = isLoadMessaged(props.load);
  return (
    <ActionButtonBold
      id="message"
      text={isMessaged ? t(T.common_conversations_buttonMessageAgain) : t(T.common_conversations_button)}
      variant={'outlined'}
      color={'secondary'}
      onClick={() => pushCommunicationPanel()}
    />
  );
};

const VhubBookButton: React.FC<ButtonProps> = (props) => (
  <ActionButtonBold
    id="vhubBook"
    text={t(T.common_vHub_vHubBookWithButtonLabel)}
    variant={'outlined'}
    color={'secondary'}
    onClick={props.onClick}
  />
);

import React, { useMemo } from 'react';

import styled from 'styled-components';

import { PDFViewer } from '@page/myDocuments/PDFViewer';
import { PreviewNotAvailable } from '@page/myDocuments/PreviewNotAvailable';
import { objectUrlFromBase64 } from '@util/FileHelper';
import { useWindowHeight, useWindowWidth } from '@util/hooks';

const HEADER_HEIGHT = 57;
const PAGE_PADDING = 0;

interface ContainerProps {
  windowHeight: number;
}

export const Container = styled(({ windowHeight, ...rest }) => <div {...rest} />)`
  padding: ${PAGE_PADDING}px;
  height: ${({ windowHeight }: ContainerProps) => windowHeight - HEADER_HEIGHT}px;
  box-sizing: border-box;
`;

const ImageViewer = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

interface Props {
  documentBase64: string;
  extension: string;
  isCommunicationsAttachments?: boolean;
}

export const ViewDocument: React.FC<Props> = (props) => {
  const windowHeight = useWindowHeight();
  const width = useWindowWidth();

  const objectUrl = useMemo(
    () => (props.extension === 'pdf' ? objectUrlFromBase64(props.documentBase64) : undefined),
    [props.extension, props.documentBase64]
  );

  let content = <PreviewNotAvailable extension={props.extension} isCommunication={props.isCommunicationsAttachments} />;

  if (props.extension === 'pdf') {
    content = <PDFViewer data={objectUrl} width={width} />;
  } else if (props.extension === 'jpg' || props.extension === 'jpeg' || props.extension === 'png') {
    const base64Encoding = `data:image/${props.extension};base64`;
    const imageSrc = `${base64Encoding},${props.documentBase64}`;
    content = <ImageViewer id="file" src={imageSrc} />;
  }

  return <Container windowHeight={windowHeight}>{content}</Container>;
};

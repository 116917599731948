import { PanelSize, PanelWidth } from './PanelSize';

const PANEL_GUTTER_LARGE = 10;
const PANEL_GUTTER_SMALL = 5;
export const OFFSET_FLOATING = -5;

// @FIXME: TEMPORARY HARD-CODED POSITIONS/SIZES UNTIL WE GET A PROPER PANEL SYSTEM (MEM-1965)

// "Soft" breakpoints, delimited by available space instead of window width.

const BREAKPOINT_SMALL_SMALL_MEDIUM_SPACED = 2 * (PanelWidth.small + PANEL_GUTTER_LARGE) + PanelWidth.medium;
const BREAKPOINT_SMALL_SMALL_MEDIUM_TIGHT = 2 * (PanelWidth.small + PANEL_GUTTER_SMALL) + PanelWidth.medium;
const BREAKPOINT_SMALL_SMALL_SMALL_SPACED = 2 * (PanelWidth.small + PANEL_GUTTER_LARGE) + PanelWidth.small;
const BREAKPOINT_SMALL_SMALL_SMALL_TIGHT = 2 * (PanelWidth.small + PANEL_GUTTER_LARGE) + PanelWidth.small;
const BREAKPOINT_SMALL_SMALL_SPACED = PanelWidth.small + PANEL_GUTTER_LARGE + PanelWidth.small;

const gutterSize = (totalWidth: number) => {
  if (totalWidth < BREAKPOINT_SMALL_SMALL_SPACED) {
    return PANEL_GUTTER_SMALL;
  }
  if (totalWidth < BREAKPOINT_SMALL_SMALL_SMALL_TIGHT) {
    return PANEL_GUTTER_LARGE;
  }
  if (totalWidth < BREAKPOINT_SMALL_SMALL_SMALL_SPACED) {
    return PANEL_GUTTER_SMALL;
  }
  if (totalWidth < BREAKPOINT_SMALL_SMALL_MEDIUM_TIGHT) {
    return PANEL_GUTTER_LARGE;
  }
  if (totalWidth < BREAKPOINT_SMALL_SMALL_MEDIUM_SPACED) {
    return PANEL_GUTTER_SMALL;
  }
  return PANEL_GUTTER_LARGE;
};

const panelSizeToMaxWidth = {
  [PanelSize.small]: PanelWidth.small,
  [PanelSize.medium]: PanelWidth.medium,
  [PanelSize.large]: PanelWidth.large,
  [PanelSize.xlarge]: PanelWidth.xlarge,
  [PanelSize.full]: undefined,
};

const fitRemaining = (size: PanelSize, remaining: number) =>
  Math.min(panelSizeToMaxWidth[size] ?? remaining, remaining);

export const getPanelLayout = (
  offset: number,
  size: PanelSize,
  totalWidth: number,
  isMobileView: boolean,
  minSize: number | undefined
) => {
  if (isMobileView) {
    return { position: 0, width: '100%' };
  }
  if (offset === OFFSET_FLOATING) {
    const width = Math.min(panelSizeToMaxWidth[size] ?? totalWidth, totalWidth);
    return {
      position: totalWidth - width,
      width: width,
    };
  }

  const position = offset * (PanelWidth.small + gutterSize(totalWidth));

  const remaining = totalWidth - position;

  if (remaining < (minSize ?? PanelWidth.small)) {
    return { position: 0, width: totalWidth <= PanelWidth.medium ? '100%' : fitRemaining(size, totalWidth) };
  }

  return { position: position, width: fitRemaining(size, remaining) };
};

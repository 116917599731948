import { Config } from '@common/model';
import { calculateWorkDays, getTotalCostPerDay } from '@common/util/CostPerDayHelper';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

export const getCostPerDayTitleData = (percentage: number) => {
  let color = Color.GREEN_MAIN;
  let style = {};
  let title = t(T.common_costPerDay_cpdAbove);

  if (percentage < 0) {
    color = Color.BLUE_TEXT;
    style = { transform: 'rotate(180deg)' };
    title = t(T.common_costPerDay_cpdBelow);

    if (percentage < -10) {
      color = Color.RED_APPLE;
    }
  }

  return {
    color: color,
    title: title,
    percentageText: `${Math.abs(Math.round(percentage))}%`,
    iconStyle: style,
  };
};

export const useCostPerDay = () => {
  const costPerDaySettings = useSelector((state) => state.settings.systemSetting[Config.CostPerDaySettings].value);
  const value = getTotalCostPerDay(
    costPerDaySettings.truckExpenses,
    costPerDaySettings.homeExpenses,
    costPerDaySettings.workDays
  );

  return {
    ...costPerDaySettings,
    value: value,
    isSetupComplete: value > 0,
  };
};

export const useGetWorkDays = (driveTime: number) => {
  const costPerDay = useCostPerDay();
  return calculateWorkDays(driveTime, costPerDay.loadingHours, costPerDay.drivingHours);
};

import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';

import { PanelDetails } from '@component/expansionPanel/ExpansionPanelStyle';
import { Color } from '@style/Color';
import { FontFamily, FontSize } from '@style/StyleConstants';
import THEME from '@style/Theme';

import { default as ArrowDown } from './icon-arrow-down.svg';
import { default as ArrowUp } from './icon-arrow-up.svg';

const PanelWrapper = styled(ExpansionPanel)`
  && {
    margin: ${(props: { isFullWidth: boolean; defaultExpanded: boolean }) =>
      props.isFullWidth ? '10px' : '10px 5px'} !important;
    padding-bottom: ${(props: { isFullWidth: boolean; defaultExpanded: boolean }) =>
      props.defaultExpanded ? '10px' : '0'};
    border-radius: 6px !important;
    box-shadow: none;
  }
`;

const PanelTitle = styled(ExpansionPanelSummary)`
  && {
    min-height: unset !important;
    padding: 0 10px;

    > div {
      display: flex;
      align-items: center;
      margin: 0;
      flex-direction: ${(props: { isFullWidth: boolean }) => (props.isFullWidth ? 'row' : 'column')};
      .title-data {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        span {
          text-transform: uppercase;
          font-family: ${FontFamily.Main};
          text-overflow: ellipsis;
          overflow: hidden;
          padding: ${(props: { isFullWidth: boolean }) => (props.isFullWidth ? '15px 5px' : '10px 12px 0 12px')};
          white-space: ${(props: { isFullWidth: boolean }) => (props.isFullWidth ? 'nowrap' : 'normal')};
          text-align: ${(props: { isFullWidth: boolean }) => (props.isFullWidth ? 'left' : 'center')};
          font-weight: ${THEME.text.header1Tab.fontWeight};
          font-size: ${(props: { isFullWidth: boolean }) => (props.isFullWidth ? FontSize.Body : FontSize.TinyBody)}px;
        }
      }
    }
  }
`;

interface Props {
  id: string;
  titleText: string;
  isExpanded: boolean;
  handleClick: () => void;
  isMenuFullWidth: boolean;
  children?: ReactNode;
}

export const SideMenuExpansionPanel: React.FC<Props> = (props) => {
  const icon = props.isExpanded ? ArrowUp : ArrowDown;

  const rightElement = (
    <img
      id={props.isExpanded ? `${props.id}_collapse` : `${props.id}_expand`}
      alt=">"
      src={icon}
      height={24}
      width={24}
      style={{ padding: 0, marginBottom: 4 }}
    />
  );

  if (!props.children) {
    return null;
  }

  return (
    <PanelWrapper
      id={props.id}
      isFullWidth={props.isMenuFullWidth}
      defaultExpanded={props.isExpanded}
      expanded={props.isExpanded}
      style={{ background: Color.GRAY_MAGNET }}
      onChange={props.handleClick}
    >
      <PanelTitle
        isFullWidth={props.isMenuFullWidth}
        style={{
          color: props.isExpanded ? Color.GRAY_SHARK : Color.GRAY_STEEL,
        }}
        tabIndex={-1}
      >
        <div className="title-data">
          <span id={`${props.id}_title`}>{props.titleText}</span>
        </div>
        {rightElement}
      </PanelTitle>
      <PanelDetails style={{ background: Color.MENU_BLACK }}>{props.children}</PanelDetails>
    </PanelWrapper>
  );
};

import React from 'react';

import styled from 'styled-components';

import { combineValidators, validateEmail, validateMaxLength, validateNotEmpty, validatePhone } from '@common/helper';
import { ValidatedTextInput } from '@component/input';
import { Recaptcha, SiteKeyMapping } from '@component/recaptcha';
import { Text, TextStyle } from '@component/text/Text';

export const FIRST_NAME_MAX_LENGTH = 40;
export const LAST_NAME_MAX_LENGTH = 40;
export const COMPANY_NAME_MAX_LENGTH = 80;
export const EXT_MAX_LENGTH = 5;
export const EMAIL_MAX_LENGTH = 50;

const Container = styled.div`
  margin-top: 20px;
`;

interface Props {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  ext: string;
  email: string;
  renderRecaptcha: boolean;
  recaptchaSiteKeyName?: SiteKeyMapping;
  isDisabled: boolean;

  onFirstNameChange: (value: string, isValid: boolean) => void;
  onLastNameChange: (value: string, isValid: boolean) => void;
  onCompanyChange: (value: string, isValid: boolean) => void;
  onPhoneChange: (value: string, isValid: boolean) => void;
  onExtChange: (value: string, isValid: boolean) => void;
  onEmailChange: (value: string, isValid: boolean) => void;
  onCaptchaValidation: () => void;
  onCaptchaInvalidate: () => void;
}

export const RegisterForm: React.FC<Props> = (props) => (
  <Container>
    <Text id={props.id + '_title'} textStyle={TextStyle.Header3}>
      {props.title}
    </Text>
    <ValidatedTextInput
      id={props.id + '_firstname'}
      required={true}
      label={'First Name'}
      fullWidth={true}
      inputProps={{ maxLength: FIRST_NAME_MAX_LENGTH }}
      autoTextSelectionOnFocus={true}
      value={props.firstName}
      isValidatedOnChange={true}
      disabled={props.isDisabled}
      onChangeWithValidation={props.onFirstNameChange}
      validator={combineValidators(validateNotEmpty, validateMaxLength(FIRST_NAME_MAX_LENGTH))}
    />
    <ValidatedTextInput
      id={props.id + '_lastname'}
      required={true}
      label={'Last Name'}
      fullWidth={true}
      inputProps={{ maxLength: LAST_NAME_MAX_LENGTH }}
      autoTextSelectionOnFocus={true}
      value={props.lastName}
      isValidatedOnChange={true}
      disabled={props.isDisabled}
      onChangeWithValidation={props.onLastNameChange}
      validator={combineValidators(validateNotEmpty, validateMaxLength(LAST_NAME_MAX_LENGTH))}
    />
    <ValidatedTextInput
      id={props.id + '_company'}
      label={'Company Name'}
      required={false}
      fullWidth={true}
      inputProps={{ maxLength: COMPANY_NAME_MAX_LENGTH }}
      autoTextSelectionOnFocus={true}
      value={props.company}
      isValidatedOnChange={true}
      disabled={props.isDisabled}
      validator={validateMaxLength(COMPANY_NAME_MAX_LENGTH)}
      onChangeWithValidation={props.onCompanyChange}
    />
    <ValidatedTextInput
      id={props.id + '_phone'}
      required={true}
      label={'Phone number'}
      inputProps={{ type: 'tel' }}
      fullWidth={true}
      autoTextSelectionOnFocus={true}
      isValidatedOnChange={true}
      disabled={props.isDisabled}
      value={props.phone}
      validator={validatePhone}
      onChangeWithValidation={props.onPhoneChange}
    />
    <ValidatedTextInput
      id={props.id + '_ext'}
      label={'Ext'}
      inputProps={{ type: 'tel' }}
      fullWidth={true}
      required={false}
      autoTextSelectionOnFocus={true}
      value={props.ext}
      isValidatedOnChange={true}
      disabled={props.isDisabled}
      validator={validateMaxLength(EXT_MAX_LENGTH)}
      onChangeWithValidation={props.onExtChange}
    />
    <ValidatedTextInput
      required={true}
      id={`${props.id}_`}
      label={'Email'}
      fullWidth={true}
      inputProps={{ type: 'email', maxLength: EMAIL_MAX_LENGTH }}
      autoTextSelectionOnFocus={true}
      value={props.email}
      isValidatedOnChange={true}
      disabled={props.isDisabled}
      validator={combineValidators(validateNotEmpty, validateEmail, validateMaxLength(EMAIL_MAX_LENGTH))}
      onChangeWithValidation={props.onEmailChange}
    />
    {props.renderRecaptcha && props.recaptchaSiteKeyName !== undefined && !props.isDisabled ? (
      <Recaptcha
        onValidation={props.onCaptchaValidation}
        onExpired={props.onCaptchaInvalidate}
        siteKeyName={props.recaptchaSiteKeyName ?? SiteKeyMapping.FuelCard}
      />
    ) : null}
  </Container>
);

import React from 'react';

import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { closeMenu } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { Routes } from '@/router/Routes';
import SoundOffIcon from '@component/menu/icon/icon-sound-off.svg';
import SoundOnIcon from '@component/menu/icon/icon-sound-on.svg';
import { isWidthLargeEnoughForExpandedMenu } from '@component/menu/SideMenuContent';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { Color } from '@style/Color';
import { ElementSize } from '@style/StyleConstants';

const AlertsIconBadgeWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: ${(props: { isMenuOpen: boolean }) => (props.isMenuOpen ? '14' : '18')}px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-width: 2px;
  border-color: ${Color.GREEN_MAIN};
  border-style: solid;
  transform: translateY(-50%);
  background-color: ${Color.GRAY_DARK};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const AlertsIconBadgeLink = styled(Link)`
  display: inline-flex;
`;

interface Props {
  isAlertsSoundOn: boolean | undefined;
  isMenuOpen: boolean;
}

export const AlertsIconBadge: React.FC<Props> = ({ isAlertsSoundOn, isMenuOpen }) => {
  const dispatch = useDispatch();

  const onBadgeClick = () => {
    if (!isWidthLargeEnoughForExpandedMenu()) {
      dispatch(closeMenu());
    }
  };

  return (
    <AlertsIconBadgeWrapper isMenuOpen={isMenuOpen} onClick={onBadgeClick}>
      {isAlertsSoundOn ? (
        <AlertsIconBadgeLink
          to={`${generatePath(Routes.MORE_SETTINGS, { filter: Filter.AlertsSound })}/`}
          id="alert_sound_on"
        >
          <img src={SoundOnIcon} width={ElementSize.Icon} height={ElementSize.Icon} />
        </AlertsIconBadgeLink>
      ) : (
        <AlertsIconBadgeLink
          to={`${generatePath(Routes.MORE_SETTINGS, { filter: Filter.AlertsSound })}/`}
          id="alert_sound_off"
        >
          <img src={SoundOffIcon} width={ElementSize.Icon} height={ElementSize.Icon} />
        </AlertsIconBadgeLink>
      )}
    </AlertsIconBadgeWrapper>
  );
};

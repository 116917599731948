import { BaseClient } from '@common/client/BaseClient';
import { GetMessagesResponse, InboxMessage, MessageAction } from '@common/model/Inbox';
import { EmptyResponse } from '@common/redux/Base';

const MESSAGES_LIMIT = 10;

const getTokenParam = (token: string | undefined): string => (token ? `&token=${token}` : '');

export class InboxClient extends BaseClient {
  fetchAllInboxMessages$ = (
    token: string | undefined,
    limit: number = MESSAGES_LIMIT,
    deleted: boolean = false,
    count: boolean = false
  ) =>
    this.api.get$<GetMessagesResponse>(
      `/internalmessages?&deleted=${deleted}&limit=${limit}&count=${count}${getTokenParam(token)}`,
      {}
    );

  updateInboxMessage$ = (data: { action: MessageAction; messageIds: string[] }) =>
    this.api.mergePatch$<EmptyResponse>('/internalmessages', {
      patchDocument: { status: data.action },
      ids: data.messageIds,
    });

  readMessage$ = (messageId: string) =>
    this.api.mergePatch$<EmptyResponse>(`/internalmessages`, {
      patchDocument: { read: true },
      ids: [messageId],
    });

  fetchMessage$ = (messageId: string) => this.api.get$<InboxMessage>(`/internalmessages/${messageId}`, {});

  clearAllMessage$ = () =>
    this.api.mergePatch$<EmptyResponse>('/internalmessages/filter', {
      patchDocument: { status: MessageAction.delete },
      excludedIds: [],
    });
}

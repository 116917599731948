import React, { useCallback, useEffect, useRef } from 'react';

import { find, forEach } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { voidFunction } from '@common/helper';
import { BiddingRole, UpdateBidEventType } from '@common/model';
import { getBiddingSummary, receivedBidUpdateEvent } from '@common/redux/epic/bids/BidsEpic';
import {
  getCommunicationStatus,
  getConversationsByID,
  getConversationsSummary,
  getMessagesSummary,
  onNewMessage,
  resetCommunicationsState,
} from '@common/redux/epic/CommunicationEpic';
import { fetchAlertSettings } from '@common/redux/epic/SettingsEpic';
import { newTruckAlert } from '@common/redux/epic/TruckAlertsEpic';
import {
  CommunicationStatsUpdate,
  ConnectedTopics,
  EmptyConnectedTopics,
  TopicCategory,
} from '@common/socket/Communication';
import { mapSettingsUpdateToActions, SettingsUpdate, SettingUpdate } from '@common/socket/SignalRSettings';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { useIsBiddingEnabled } from '@page/bids/BidStatusHelper';
import { useSelector } from '@util/hooks/UseRedux';
import { useAppVisibilityChange } from '@util/hooks/UseVisibility';
import { hasLoginCookies } from '@webApi/CookiesHelper';

import { useCommunicationService } from './AppDependencies';

export const CommunicationHandler: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isUserLoggedIn = hasLoginCookies();
  const isBiddingEnabled = useIsBiddingEnabled();
  const isCarrier = useIsCarrier();
  const signalRService = useCommunicationService();

  const userId = useSelector((state) => state.user.profile?.payload?.id) ?? '';
  const isCommunicationsEnabled = useSelector((state) => state.communication.isCommunicationsEnabled);
  const isProfileFetched = useSelector((state) => state.user.profileFetchedAt !== undefined);

  const connectedTopics = useRef<ConnectedTopics>(EmptyConnectedTopics);
  const userStatusRef = useRef<CommunicationStatsUpdate>('foreground');
  const latestIsCommunicationsEnabled = useRef(isCommunicationsEnabled);
  latestIsCommunicationsEnabled.current = isCommunicationsEnabled;

  const isCommunicationListOpened = () => {
    return history.location.pathname.includes('communication');
  };

  const connectToBiddingTopics = useConnectToBiddingTopics(
    connectedTopics,
    isCarrier ? BiddingRole.Carrier : BiddingRole.Broker
  );
  const latestIsBiddingEnabled = useRef(isBiddingEnabled);
  latestIsBiddingEnabled.current = isBiddingEnabled;

  const disconnectFromTopics = (...topicCategories: TopicCategory[]) => {
    forEach(topicCategories, (category) => {
      if (connectedTopics.current[category]) {
        forEach(connectedTopics.current[category], (topic) => signalRService.disconnectFromTopic(topic));
        connectedTopics.current[category] = [];
      }
    });
  };

  const connectToCommunicationTopics = () => {
    dispatch(getMessagesSummary());
    connectedTopics.current.communication = signalRService.connectToTopics({
      onUpdatedConversations: (updatedIds) => {
        dispatch(getMessagesSummary());
        if (isCommunicationListOpened()) {
          dispatch(getConversationsByID(updatedIds, userId));
        }
      },
      onNewMessage: (message) => {
        dispatch(
          onNewMessage(message.conversationId, {
            documents: message.documents,
            messageId: message.messageId,
            text: message.text,
            sentAt: message.sentAt,
            read: false,
            sentBy: message.sentBy,
            clientMessageId: message.clientMessageId,
            users: [],
            widget: message.widget ?? undefined,
          })
        );
      },
    });
  };

  const connectToGeneralTopics = () => {
    connectedTopics.current.general = signalRService.connectToTopics({
      onUpdateClientStatus: () => {
        signalRService.updateClientStatus(userStatusRef.current);
      },
      onSettingsUpdate: (update: SettingsUpdate) => {
        if (isCommunicationSettingsUpdate(update.settings)) {
          dispatch(getCommunicationStatus());
        }
        //This fix check is only temporary.
        //We are checking if there was any updates made to alerts settings
        //If yes we fetch the new settings
        //Once we have implemented the real time database this will be obsolete - bernard
        if (isAlertsPollIntervalSettingsUpdated(update.settings)) {
          dispatch(fetchAlertSettings());
        }
        const systemSettingFetchActions = mapSettingsUpdateToActions(update);
        forEach(systemSettingFetchActions, (action) => {
          dispatch(action);
        });
      },
      onNewTruckAlert: () => {
        if (isCarrier) {
          dispatch(newTruckAlert());
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getCommunicationStatus());
  }, []);

  useEffect(() => {
    if (isUserLoggedIn && isProfileFetched) {
      signalRService.startListening().then(() => {
        connectToGeneralTopics();
        if (latestIsCommunicationsEnabled.current && connectedTopics.current.communication.length === 0) {
          connectToCommunicationTopics();
          dispatch(getConversationsSummary());
        }
        if (latestIsBiddingEnabled.current && connectedTopics.current.bidding.length === 0) {
          connectToBiddingTopics();
          dispatch(getBiddingSummary(isCarrier ? BiddingRole.Carrier : BiddingRole.Broker));
        }
      });

      return () => {
        signalRService.updateClientStatus('offline');

        disconnectFromTopics(TopicCategory.General, TopicCategory.Communication, TopicCategory.Bidding);

        signalRService.stopListening();
        dispatch(resetCommunicationsState());
      };
    }

    return voidFunction;
  }, [isUserLoggedIn, isProfileFetched]);

  useEffect(() => {
    if (signalRService.isConnected()) {
      if (isCommunicationsEnabled) {
        if (connectedTopics.current.communication.length === 0) {
          connectToCommunicationTopics();
          dispatch(getConversationsSummary());
        }
      } else {
        disconnectFromTopics(TopicCategory.Communication);
      }
    }
  }, [isCommunicationsEnabled]);

  useEffect(() => {
    if (signalRService.isConnected()) {
      if (isBiddingEnabled) {
        if (connectedTopics.current.bidding.length === 0) {
          connectToBiddingTopics();
          dispatch(getBiddingSummary(isCarrier ? BiddingRole.Carrier : BiddingRole.Broker));
        }
      } else {
        disconnectFromTopics(TopicCategory.Bidding);
      }
    }
  }, [isBiddingEnabled]);

  useAppVisibilityChange(
    (isVisible) => {
      if (isCommunicationsEnabled) {
        const currentStatus = isVisible ? 'foreground' : 'background';
        userStatusRef.current = currentStatus;
        signalRService.updateClientStatus(currentStatus);
      } else if (isVisible) {
        dispatch(getCommunicationStatus());
      }
    },
    [isCommunicationsEnabled]
  );

  return null;
};

const useConnectToBiddingTopics = (connectedTopics: React.MutableRefObject<ConnectedTopics>, role: BiddingRole) => {
  const dispatch = useDispatch();
  const signalRService = useCommunicationService();

  return useCallback(() => {
    connectedTopics.current.bidding = signalRService.connectToTopics({
      onNewBids: (event) => {
        dispatch(receivedBidUpdateEvent(UpdateBidEventType.NewBids, event, role));
      },
      onUpdatedBids: (event) => {
        dispatch(receivedBidUpdateEvent(UpdateBidEventType.UpdatedBids, event, role));
      },
    });
  }, [connectedTopics, signalRService]);
};

const isCommunicationSettingsUpdate = (updates: SettingUpdate[]) => {
  return find(updates, (update) => update.name.includes('messaging.client')) ? true : false;
};

const isAlertsPollIntervalSettingsUpdated = (updates: SettingUpdate[]) => {
  return find(
    updates,
    (update) => update.name.includes('load.alerts.pollIntervalSecs') || update.name.includes('load.alerts.active')
  )
    ? true
    : false;
};

import React, { useMemo } from 'react';

import { filter, isEqual, map, sortBy } from 'lodash';
import moment from 'moment';

import { MonthType } from '@component/calendar/Calendar';

import { DatesCalendar } from './DatesCalendar';

interface Props {
  date: MonthType;
  numberOfMonthsDisplayed: number;
  onChange: (values: string[]) => void;
  selectedItems: string[];
  maxCount?: number;
  style?: React.CSSProperties;
}

export interface MonthsArray {
  year: number;
  month?: string;
}

export const CalendarGroup: React.FC<Props> = ({
  selectedItems,
  maxCount,
  numberOfMonthsDisplayed,
  date,
  onChange,
  style,
}) => {
  const monthsArr: MonthsArray[] = [];

  useMemo(() => {
    for (let i = 0; i < numberOfMonthsDisplayed; i++) {
      const newDate = moment({ year: date.year, month: date.month }).add(i, 'months');
      monthsArr.push({ year: newDate.year(), month: newDate.format('MMMM') });
    }
  }, [date]);

  return (
    <div style={style}>
      {map(monthsArr, (date) => (
        <DatesCalendar
          key={date.month}
          date={date}
          handleRemove={removeSelectedClick(selectedItems, onChange)}
          handleSelect={addSelectedClick(selectedItems, maxCount, onChange)}
          selectedItems={selectedItems}
        />
      ))}
    </div>
  );
};

const addSelectedClick =
  (selectedItems: string[], maxCount: number | undefined, onChange: (values: string[]) => void) => (date: string) => {
    if ((maxCount !== undefined && selectedItems.length < maxCount) || maxCount === undefined) {
      const newSelectedDates = sortBy([...selectedItems, date]);
      onChange(newSelectedDates);
    }
    if (maxCount === 1) {
      onChange([date]);
    }
  };

const removeSelectedClick = (selectedItems: string[], onChange: (values: string[]) => void) => (date: string) => {
  const newSelectedDates = filter(selectedItems, (value) => !isEqual(value, date));
  onChange(newSelectedDates);
};

import React from 'react';

import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

import { Color } from '@style/Color';
import { ElementSize } from '@style/StyleConstants';
import { ThemeProps } from '@style/WithTheme';

interface Props {
  id: string;
  src: string;
  alt: string;
  onClick?: (event?: React.MouseEvent) => void;
  size?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
  tabIndex?: number;
}
type IconProps = { size?: number; tabIndex?: number } & ThemeProps;

// augmenting specificity with && to allow styling material-ui component
const ActionIconButton = styled(({ size: size, ...rest }) => <IconButton {...rest} />)`
  && {
    height: ${({ size }: IconProps) => size ?? ElementSize.ActionChipHeight}px;
    width: ${({ size }: IconProps) => size ?? ElementSize.ActionChipHeight}px;
    padding: 0;
    &:focus {
      filter: brightness(85%);
      background: ${Color.TRANSPARENT_HOVER};
    }
  }
`;

const Icon = styled(({ size: size, ...rest }) => <img {...rest} />)`
  height: ${({ size }: IconProps) => size ?? ElementSize.Icon}px;
  width: ${({ size }: IconProps) => size ?? ElementSize.Icon}px;
`;

export const ActionIcon: React.FC<Props> = ({ onClick, id, src, alt, size, style, disabled, tabIndex }) => (
  <ActionIconButton id={id} onClick={onClick} size={size} style={style} disabled={disabled} tabIndex={tabIndex}>
    <Icon src={src} alt={alt} size={size} />
  </ActionIconButton>
);

import React from 'react';

import { some } from 'lodash';
import styled from 'styled-components';

import { useNavGroups } from '@component/menu/NavigationMenuHelper';
import { Color } from '@style/Color';

import { useHasNotifications } from './mainHooks';

const NotificationDot = styled.div`
  border-radius: 100px;
  width: 10px;
  height: 10px;
  background-color: ${Color.RED_APPLE};
  border: solid 1px ${Color.WHITE};
  position: absolute;
  top: 6px;
  left: 53px;
`;

export const TopMenuNotificationDot: React.FC = () => {
  const hasNotifications = useHasNotifications();
  const navGroups = useNavGroups();
  const doesExistNewItems = some(navGroups, (navGroup) => some(navGroup.content, (item) => item.isNewOrUpdated));

  if (hasNotifications || doesExistNewItems) {
    return <NotificationDot />;
  }

  return null;
};

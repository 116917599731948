import React from 'react';

import styled from 'styled-components';

import { Fab } from '@material-ui/core';

import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

import { default as CloseIcon } from './close-white.svg';
import { ScreenSharingPopUp } from './ScreenSharingPopup';

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 50%;
  right: ${Spacing.TwoSpaces}px;
`;

const CloseIconButton = styled(Fab)`
  && {
    width: ${ElementSize.ButtonHeight}px;
    height: ${ElementSize.ButtonHeight}px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ea5b50;
    &:hover {
      background-color: #ea5b50;
    }
  }
`;

const StyledCloseIcon = styled.img`
  height: ${ElementSize.Icon}px;
  width: ${ElementSize.Icon}px;
`;

interface ComponentProps {
  onEndSession: () => void;
}

export const ScreenSharingButton: React.FC<ComponentProps> = (props) => {
  const { openingPopup, closingPopup } = usePopup();
  const onEndButtonClick = () => {
    openingPopup({
      body: (
        <ScreenSharingPopUp
          onClose={closingPopup}
          onLeftButtonClick={() => {
            props.onEndSession();
            closingPopup();
          }}
          onRightButtonClick={closingPopup}
          title={t(T.common_help_screenSharing_endSession_title)}
          description={t(T.common_help_screenSharing_endSession_description)}
          leftButtonText={t(T.common_yes)}
          rightButtonText={t(T.common_no)}
        />
      ),
      width: PopupSizes.SMALL,
      isDismissible: false,
    });
  };
  return (
    <CloseButtonContainer>
      <CloseIconButton onClick={onEndButtonClick}>
        <StyledCloseIcon src={CloseIcon} alt="close" />
      </CloseIconButton>
    </CloseButtonContainer>
  );
};

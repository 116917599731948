import React from 'react';

import { map, slice } from 'lodash';
import styled from 'styled-components';

import { Tooltip } from '@material-ui/core';

import {
  convertTruckAvailabilityToLoadSearch,
  formatDateMMMdd,
  serverRFCDateToYYYYMMDD,
  textFromLocation,
} from '@common/helper';
import { getStringifiedEquipmentTypeAndName } from '@common/helper/SearchHelper';
import { EquipmentType, TruckAvailability, UserTruck } from '@common/model';
import { Container, MainContainerPostTruck } from '@component/cards/CardsStyle';
import { DateChip } from '@component/chip/DateChip';
import { Spacer, Status } from '@component/loadListCard/LoadListCardStyle';
import { useOpenSearchResultPanel } from '@component/panels/findLoads/searchPanel/SearchPanelHelper';
import { Text, TextStyle } from '@component/text/Text';
import { Color } from '@style/Color';
import { ElementSize, FontLineHeight, FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import { displayLength, displayWeight } from '@util/TruckDataHelper';

const DistanceText = styled.span`
  padding-left: ${Spacing.InterElementHorizontal}px;
  line-height: ${FontLineHeight.Content}px;
  color: ${Color.GRAY_STONE};
  font-size: ${FontSize.Body}px;
`;

const OrangeCircleChip = styled.div`
  display: flex;
  height: ${Spacing.ImageElement}px;
  width: ${Spacing.ImageElement}px;
  min-height: ${Spacing.ImageElement}px;
  min-width: ${Spacing.ImageElement}px;
  border-radius: 24px;
  background-color: ${Color.ORANGE_MAIN};
  align-items: center;
  justify-content: center;
`;

const LocationText = styled.span`
  font-size: ${FontSize.Content}px;
  line-height: 22px;
  margin: 0;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LocationContainer = styled.div`
  display: grid;
  grid-template-columns: ${Spacing.ImageElement}px 40px 1fr;
  min-height: ${ElementSize.MobileHeaderHeight}px;
  padding: 16px 16px 0 16px;
`;

const LocationColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const Destination = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
`;

const ChipLetter = styled.div`
  font-size: ${FontSize.Header}px;
  font-weight: 600;
  line-height: 32px;
  color: ${Color.WHITE};
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Color.WHITE};
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
  padding: 4px 8px;

  &:hover {
    background-color: ${Color.WHITE};
  }
`;

const CardFooter = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  padding: 4px 16px 12px;
`;

const MeasurementsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
`;

const DatesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const LinkButton = styled.a`
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
`;

interface TruckPostCardProps {
  showDates?: boolean;
  availability: TruckAvailability;
  truck: UserTruck;
  onPress: () => void;
}

export const TruckPostCard = (props: TruckPostCardProps) => {
  const openSearchResultPanel = useOpenSearchResultPanel();

  const originLocationText = textFromLocation(props.availability.originLocation);
  const destinationLocationText = textFromLocation(props.availability.destinationLocations[0]);

  const handleSearch = () => {
    const search = convertTruckAvailabilityToLoadSearch({
      ...props.availability,
      equipmentType: props.truck?.equipmentType ?? EquipmentType.None,
    });
    openSearchResultPanel({ search: search, id: '' });
  };

  const renderDistanceText = ({ id, distance }: { id: string; distance?: number }) => {
    if (distance === undefined) {
      return undefined;
    }

    return <DistanceText id={id}>{t(T.unit_mi, { value: distance })}</DistanceText>;
  };

  return (
    <Container id="truck_post_card">
      <CardHeader>
        <div style={{ wordBreak: 'break-all', flex: 1 }}>
          <Text id="length" textStyle={TextStyle.SubtitleNormalWeight} style={{ lineHeight: '14pt' }}>
            {getStringifiedEquipmentTypeAndName(props.truck.equipmentType, props.truck.displayName)}
          </Text>
          {props.availability.anonymous ? (
            <Status style={{ margin: 0, marginLeft: 5 }} id="anonymous_info" color={Color.BLACK}>
              {t(T.common_postTruck_AnonymousPost_header)}
            </Status>
          ) : undefined}
        </div>
        <LinkButton id="find_loads_link" onClick={handleSearch}>
          {t(T.common_postTruck_findLoadsLink)}
        </LinkButton>
      </CardHeader>
      <MainContainerPostTruck onClick={props.onPress}>
        <LocationContainer>
          <OrangeCircleChip>
            <ChipLetter>{slice(props.truck.equipmentType, 0, 1)}</ChipLetter>
          </OrangeCircleChip>
          <Spacer>
            <span id="circle" className="circle" />
            <span id="square" className="square" />
            <span id="dots" className="dots" />
          </Spacer>
          <LocationColumn>
            <Destination>
              <Tooltip title={originLocationText} placement="top">
                <LocationText id="location_from">{originLocationText}</LocationText>
              </Tooltip>
              {renderDistanceText({ id: 'origin_radius', distance: props.availability.originLocation?.radius })}
            </Destination>
            <Destination>
              <Tooltip title={destinationLocationText} placement="top">
                <LocationText id="location_from_destination">{destinationLocationText}</LocationText>
              </Tooltip>
              {renderDistanceText({
                id: 'destination_radius',
                distance: props.availability.destinationLocations[0]?.radius,
              })}
            </Destination>
          </LocationColumn>
        </LocationContainer>
        <CardFooterInformation
          showDates={props.showDates}
          dates={getFormattedDates(props.availability.dates)}
          length={displayLength(props.availability.length)}
          weight={displayWeight(props.availability.weight)}
          size={props.availability.size}
          minRate={props.availability.minRate}
        />
      </MainContainerPostTruck>
    </Container>
  );
};

interface FooterProps {
  dates: string[];
  length: string;
  weight: string;
  size: string;
  showDates?: boolean;
  minRate?: number | null;
}

const CardFooterInformation = (props: FooterProps) => (
  <CardFooter>
    {props.showDates ? (
      <DatesContainer>
        {map(props.dates, (date: string, index: number) =>
          date && index < 2 ? <DateChip key={date} id="date_chip1" text={date} /> : null
        )}
        <Text id="extra-count" textStyle={TextStyle.SubtitleNormalWeight}>
          {props.dates.length >= 3 ? `+${props.dates.length - 2}` : ''}
        </Text>
      </DatesContainer>
    ) : null}
    <MeasurementsContainer>
      <Text id="size" textStyle={TextStyle.Body2}>
        {props.size}
      </Text>
      <Text id="length" textStyle={TextStyle.Body2}>
        {props.length}
      </Text>
      <Text id="weight" textStyle={TextStyle.Body2}>
        {props.weight}
      </Text>
      {props.minRate ? (
        <Text id="min_rate" textStyle={TextStyle.Body2}>
          {t(T.common_dollar_sign)}
          {`${props.minRate}`}
          {t(T.common_per_miles_short)}
        </Text>
      ) : null}
    </MeasurementsContainer>
  </CardFooter>
);

export const getFormattedDates = (dates: string[]) => {
  return map(dates, (date: string) => formatDateMMMdd(serverRFCDateToYYYYMMDD(date)));
};

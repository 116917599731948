import { cloneDeep, has, map, pull, reduce } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$, switchMap as switchMap$ } from 'rxjs/operators';

import { Api, ApiErrorCode } from '@common/api';
import { LoadsClient } from '@common/client';
import { isLocationCountryOrAnywhere, prepareNearbyLoadsSearchRequest, withSorting } from '@common/helper';
import { getSortByMatchingLoadSearchOrigin } from '@common/helper/SearchHelper';
import {
  INITIAL_LOAD_SEARCH_LIMIT,
  Load,
  LoadLocation,
  LoadSearchMetadata,
  LoadSearchRequest,
  NamedSearchRequest,
  NearbyLoadsResponse,
  NearbyLoadsReverseGeolocationsResponse,
  NearbyLoadsSearchRequest,
  NearbyLoadsSettings,
  OriginLocation,
  PersistentLoadSearchDef,
} from '@common/model';
import {
  createAction,
  createApiAction,
  createApiActionWithFetchData,
  ResultResponseActionWithFetchData,
  SearchResponseMetadata,
} from '@common/redux/Base';
import { createLoadSearch, SearchReducerKey } from '@common/redux/epic/loadSearch/LoadSearchShared';
import { SETTINGS_REDUCER_KEY, SettingsState } from '@common/redux/epic/SettingsStateHelper';
import { createMergedReducer } from '@common/redux/ReduxHelper';

import { SET_PROGRESS_FULFILLED, SetProgressResponseAction } from '../LoadInfoEpic';
import {
  AlertsResponse,
  convertPersistentSearchFromServer,
  createInitialNormalizedPersistentSearches,
  initialState,
  isRateLimitingResponseOrNoAlertsSet,
  LoadSearchBaseState,
  NormalizedSearchAlertsEntities,
  normalizePersistentSearches,
  PersistentSearch,
  SearchesResponse,
  UpdateSearchAlertRequest,
  ViewFilter,
} from './HelperFunctions';

export enum screensForCustomRecentSearch {
  RateCheck = 'rateCheck',
}

const updateLastSearchAction = createAction<LoadSearchRequest>('UPDATE_LAST_SEARCH');
const updateListSearchAction = createAction<LoadSearchRequest>('UPDATE_LIST_SEARCH');
const setSelectedNamedSearchAction = createAction<PersistentSearch | undefined>('SET_SELECTED_NAMED_SEARCH');
const setSearchReferrerAction = createAction<string | undefined>('SET_SEARCH_REFERRER');
const clearAlertErrorAction = createAction<string | undefined>('CLEAR_ALERT_ERROR');
const clearFetchingErrorAction = createAction<undefined>('CLEAR_FETCHING_ERROR');
const setNearbyLoadsSettingsAction = createAction<NearbyLoadsSettings>('SET_NEARBY_LOADS_SETTINGS');
const setLoadSearchViewFilterAction = createAction<ViewFilter>('SET_SEARCH_VIEW_FILTER');

const fetchAllSearchesAction = createApiAction<ViewFilter, SearchesResponse>('FETCH_SEARCHES');
const deleteAllSearchesAction = createApiActionWithFetchData<ViewFilter, {}>('DELETE_ALL_SEARCHES');
const createSearchAction = createApiAction<NamedSearchRequest, PersistentLoadSearchDef>('CREATE_SEARCH');
const fetchSearchByIDAction = createApiAction<string, PersistentLoadSearchDef>('FETCH_SEARCH_BY_ID');
const editSearchRequestAction = createApiActionWithFetchData<
  { namedSearchId: string; request: NamedSearchRequest },
  PersistentLoadSearchDef
>('EDIT_SEARCH');
const deleteSearchAction = createApiActionWithFetchData<string, {}>('DELETE_SEARCH');
const fetchNearbyLoadsCountAction = createApiAction<NamedSearchRequest, SearchResponseMetadata>(
  'FETCH_NEARBY_LOADS_COUNT'
);
const loadAlerts = createApiAction<undefined, AlertsResponse>('FETCH_LOAD_ALERTS');
const updateSearchAlertAction = createApiActionWithFetchData<UpdateSearchAlertRequest, {}>('UPDATE_SEARCH_ALERT');
export const getNearbyLoadsAction = createApiAction<Partial<NearbyLoadsSearchRequest>, NearbyLoadsResponse>(
  'NEARBY_LOADS'
);
export const fetchNearbyLoadsReverseGeolocationsAction = createApiAction<
  LoadSearchRequest,
  NearbyLoadsReverseGeolocationsResponse
>('FETCH_NEARBY_LOADS_REVERSE_GEOLOCATIONS');
export const fetchNearbyLoadsReverseGeolocations = fetchNearbyLoadsReverseGeolocationsAction.fetchAction;

/** Only used for updating last search after doing map searches on MEM
 * On MOB it is used to update all last searches */
export const updateLastSearch = (lastSearch: LoadSearchRequest) => updateLastSearchAction.action(lastSearch);
/** Only used for updating last list search on MEM */
export const updateListSearch = (listSearch: LoadSearchRequest) => updateListSearchAction.action(listSearch);
export const setSelectedNamedSearch = (search: PersistentSearch | undefined) =>
  setSelectedNamedSearchAction.action(search);
export const setSearchReferrer = (referrer: string | undefined) => setSearchReferrerAction.action(referrer);
export const clearAlertError = (searchId: string | undefined) => clearAlertErrorAction.action(searchId);
export const clearFetchingError = () => clearFetchingErrorAction.action(undefined);
export const setLoadSearchViewFilter = (view: ViewFilter) => setLoadSearchViewFilterAction.action(view);

export const fetchAllSearches = (filter: ViewFilter = ViewFilter.ALL) => fetchAllSearchesAction.fetchAction(filter);
export const deleteAllSearches = (filter: ViewFilter) => deleteAllSearchesAction.fetchAction(filter);
export const createSearch = (request: NamedSearchRequest) => createSearchAction.fetchAction(request);
export const fetchSearchByID = (namedSearchId: string) => fetchSearchByIDAction.fetchAction(namedSearchId);
export const editSearchRequest = (namedSearchId: string, request: NamedSearchRequest) =>
  editSearchRequestAction.fetchAction({ namedSearchId: namedSearchId, request: request });
export const updateSearchAlert = (request: UpdateSearchAlertRequest) => updateSearchAlertAction.fetchAction(request);
export const deleteSearch = (namedSearchId: string) => deleteSearchAction.fetchAction(namedSearchId);
export const getNearbyLoads = getNearbyLoadsAction.fetchAction;
export const setNearbyLoadsSettings = setNearbyLoadsSettingsAction.action;
export const fetchNearbyLoadsCount = fetchNearbyLoadsCountAction.fetchAction;
export const fetchLoadAlerts = () => loadAlerts.fetchAction(undefined);

export const LOAD_SEARCH_REDUCER_KEY = SearchReducerKey.LOAD_SEARCH;

const loadSearch = createLoadSearch(LOAD_SEARCH_REDUCER_KEY);

export const ACTIONS_BLOCKING_GROUP = loadSearch.actionsQueue;

export const fetchLoadCount = loadSearch.actions.fetchLoadCount;
export const nextSearchLoads = loadSearch.actions.nextSearchLoads;
export const resetLoadCount = loadSearch.actions.resetLoadCount;
export const searchLoads = (searchData?: LoadSearchRequest, originatedAppLocation = '') =>
  loadSearch.actions.searchLoads(searchData, undefined, originatedAppLocation, INITIAL_LOAD_SEARCH_LIMIT);
export const searchLoadsById = (id: string, metadata: LoadSearchMetadata) =>
  loadSearch.actions.searchLoadsById(id, metadata, INITIAL_LOAD_SEARCH_LIMIT);
export const showSimilarLoads = loadSearch.actions.showSimilarLoads;
export const updateOriginLocation = loadSearch.actions.updateOriginLocation;
export const updateLoadSearchTruck = loadSearch.actions.updateLoadSearchTruck;
export const updateLoads = loadSearch.actions.updateLoads;
export const refreshLoadCount = loadSearch.actions.refreshLoadCount;
export const loadCountRefreshed = loadSearch.actions.loadCountRefreshed;
export const performAutoRefresh = loadSearch.actions.performAutoRefresh;
export const removeBlockedLoad = loadSearch.actions.removeBlockedLoad;
export const toggleCountingSearches = loadSearch.actions.toggleCountingSearches;
export const setSelectedSearch = loadSearch.actions.setSelectedSearch;
export const viewLoad = loadSearch.actions.viewLoad;
export const clearLoadSearchState = loadSearch.actions.clearLoadSearchState;
export const setSearchFilterDraft = loadSearch.actions.setSearchFilterDraft;
export const setSortBy = loadSearch.actions.setSortBy;

export interface LoadSearchState extends LoadSearchBaseState {
  nearbyLoads: {
    isLoading: boolean;
    isCountLoading: boolean;
    searchRequest?: LoadSearchRequest;
    origin?: OriginLocation;
    destination?: LoadLocation;
    totalCount?: number;
    nearbyLoadsSettings?: NearbyLoadsSettings;
  };
  //@FIXME: We're using this here due to a bug with React-navigation v4. seems to be fixed on V5, remove when updated.
  searchReferrer?: string;
}
const loadSearchInitialState: LoadSearchState = {
  ...initialState,
  nearbyLoads: {
    isLoading: false,
    isCountLoading: false,
  },
};

export const loadSearchReducer = createMergedReducer<LoadSearchState>(
  loadSearchInitialState,
  [
    {
      [SET_PROGRESS_FULFILLED]: (state, action: SetProgressResponseAction) => {
        if (!action.response.success) {
          return state;
        }
        const updatedLoads = map(state.loads, (load: Load) => {
          if (load.id !== action.loadID) {
            return load;
          }
          const updatedLoad = cloneDeep(load);
          if (updatedLoad && updatedLoad.metadata && updatedLoad.metadata.userdata) {
            updatedLoad.metadata.userdata.progress = action.progress;
          }
          return updatedLoad;
        });
        return { ...state, loads: updatedLoads };
      },
    },

    updateLastSearchAction.addCase((state, action) => {
      let lastSearchRequest = { ...state.lastSearchRequest, ...action.data };
      if (state.lastSearchRequest.origin.type !== action.data.origin.type) {
        const updatedSortBy = getSortByMatchingLoadSearchOrigin(action.data.origin, state.sortBy);
        lastSearchRequest = withSorting(lastSearchRequest, updatedSortBy);
      }
      if (!isLocationCountryOrAnywhere(action.data.destination)) {
        lastSearchRequest.minMileage = undefined;
        lastSearchRequest.maxMileage = undefined;
      }
      return { ...state, lastSearchRequest: lastSearchRequest, listSearchRequest: lastSearchRequest };
    }),

    updateListSearchAction.addCase((state, action) => {
      return { ...state, listSearchRequest: action.data };
    }),

    setSelectedNamedSearchAction.addCase((state, action) => {
      state.searchesState.selectedNamedSearch = action.data;
    }),

    setSearchReferrerAction.addCase((state, action) => {
      state.searchReferrer = action.data;
    }),
    clearAlertErrorAction.addCase((state, action) => {
      state.loadSearchAlerts.alertError = undefined;
      if (action.data) {
        state.loadSearchAlerts.entities[action.data] = {
          count: undefined,
          isLoading: false,
        };
      }
    }),

    clearFetchingErrorAction.addCase((state) => {
      state.fetchingError = undefined;
    }),

    setLoadSearchViewFilterAction.addCase((state, action) => {
      state.searchesState.viewFilter = action.data;
    }),

    fetchAllSearchesAction.initiateCase((state) => {
      state.searchesState.isLoadingSearches = true;
      state.searchesState.didSearchesFetchFail = undefined;
    }),
    fetchAllSearchesAction.completeCase((state, action) => {
      state.searchesState.isLoadingSearches = false;
      state.searchesState.didSearchesFetchFail = !action.response.success;
      if (action.response.success) {
        state.searchesState.searches = normalizePersistentSearches(action.response.payload.namedSearches);
      }
    }),

    deleteAllSearchesAction.initiateCase((state) => {
      state.searchesState.isLoadingSearches = true;
    }),
    deleteAllSearchesAction.completeCase((state, action) => {
      state.searchesState.isLoadingSearches = false;
      if (action.response.success) {
        state.searchesState.searches = createInitialNormalizedPersistentSearches();
      }
    }),

    createSearchAction.initiateCase((state) => {
      state.searchesState.isLoadingSearches = true;
      state.searchesState.wasSearchCreated = undefined;
      state.searchesState.wasSearchUpdated = undefined;
    }),
    createSearchAction.completeCase((state, action) => {
      state.searchesState.isLoadingSearches = false;
      state.searchesState.wasSearchCreated = action.response.success;

      if (action.response.success) {
        const persistentNamedSearch = convertPersistentSearchFromServer(action.response.payload);

        state.searchesState.searches.entities = {
          [persistentNamedSearch.id]: persistentNamedSearch,
          ...state.searchesState.searches.entities,
        };
        state.searchesState.searches.ui.unshift(persistentNamedSearch.id);
        state.searchesState.viewFilter = ViewFilter.ALL;
      }
    }),

    fetchSearchByIDAction.initiateCase((state, action) => {
      state.searchesState.searches.entities[action.data].isLoading = true;
    }),
    fetchSearchByIDAction.completeCase((state, action) => {
      if (action.response.success) {
        const persistentSearch = convertPersistentSearchFromServer(action.response.payload);
        state.searchesState.searches.entities[persistentSearch.id] = persistentSearch;
      }
    }),

    editSearchRequestAction.initiateCase((state) => {
      state.searchesState.wasSearchUpdated = undefined;
      state.searchesState.wasSearchCreated = undefined;
    }),
    editSearchRequestAction.completeCase((state, action) => {
      if (action.response.success) {
        state.searchesState.wasSearchUpdated = true;
        if (action.response.payload.id) {
          state.searchesState.searches.entities[action.response.payload.id] = convertPersistentSearchFromServer(
            action.response.payload
          );
          state.searchesState.searches.ui.unshift(
            state.searchesState.searches.ui.splice(
              state.searchesState.searches.ui.indexOf(action.response.payload.id),
              1
            )[0]
          );
          state.searchesState.viewFilter = ViewFilter.ALL;
        }
      } else if (action.response.error.code !== ApiErrorCode.NAMED_LOAD_SEARCH_NOT_FOUND) {
        // if 404 -> create a request, see createSearchOnEditFail
        state.searchesState.wasSearchUpdated = false;
      }
    }),

    updateSearchAlertAction.initiateCase((state, action) => {
      state.searchesState.searches.entities[action.data.namedSearchId].isUpdatingAlert = true;
      state.searchesState.wasAlertUpdated = undefined;
      state.searchesState.error = undefined;
    }),
    updateSearchAlertAction.completeCase((state, action) => {
      if (
        action.fetchData?.namedSearchId &&
        has(state.searchesState.searches.entities, action.fetchData.namedSearchId)
      ) {
        state.searchesState.searches.entities[action.fetchData.namedSearchId].isUpdatingAlert = false;
        state.searchesState.wasAlertUpdated = action.response.success;
        if (action.response.success) {
          state.searchesState.searches.entities[action.fetchData.namedSearchId].hasAlert = action.fetchData.hasAlert;
        } else {
          state.searchesState.error = action.response.error;
        }
      }
    }),

    deleteSearchAction.initiateCase((state) => {
      state.searchesState.isLoadingSearches = true;
      state.searchesState.wasSearchDeleted = undefined;
      state.searchesState.error = undefined;
    }),
    deleteSearchAction.completeCase((state, action) => {
      state.searchesState.wasSearchDeleted = action.response.success;

      if (action.response.success) {
        if (action.fetchData) {
          state.searchesState.isLoadingSearches = false;
          delete state.searchesState.searches.entities[action.fetchData];
          pull(state.searchesState.searches.ui, action.fetchData);
        }
      } else {
        state.searchesState.error = action.response.error;
      }
    }),

    loadAlerts.initiateCase((state) => {
      state.loadSearchAlerts.isLoading = true;
    }),
    loadAlerts.completeCase((state, action) => {
      state.loadSearchAlerts.isLoading = false;
      state.loadSearchAlerts.isRateLimitingResponseOrNoAlertsSet = isRateLimitingResponseOrNoAlertsSet(action.response);
      if (action.response.success) {
        const { totalAlertCount, namedSearchAlertCounts } = action.response.payload;
        state.loadSearchAlerts.totalCount = totalAlertCount;
        state.loadSearchAlerts.entities = reduce(
          namedSearchAlertCounts,
          (acc, alert) => {
            acc[alert.namedSearchId] = { count: alert.alertCount, isLoading: false };
            return acc;
          },
          {} as NormalizedSearchAlertsEntities
        );
      }
    }),
    getNearbyLoadsAction.initiateCase((state) => {
      state.nearbyLoads.isLoading = true;
    }),
    getNearbyLoadsAction.completeCase((state, action) => {
      state.nearbyLoads.isLoading = false;
      if (action.response.success) {
        state.nearbyLoads.searchRequest = prepareNearbyLoadsSearchRequest(action.response.payload.query);
        state.nearbyLoads.origin = state.nearbyLoads.searchRequest.origin;
        state.nearbyLoads.destination = state.nearbyLoads.searchRequest.destination;
        state.nearbyLoads.totalCount = action.response.payload.metadata.totalCount;
      }
    }),
    fetchNearbyLoadsReverseGeolocationsAction.completeCase((state, action) => {
      if (action.response.success) {
        state.nearbyLoads.origin = action.response.payload.origin;
        state.nearbyLoads.destination = action.response.payload.destination;
      }
    }),
    setNearbyLoadsSettingsAction.addCase((state, action) => {
      state.nearbyLoads.nearbyLoadsSettings = { ...state.nearbyLoads.nearbyLoadsSettings, ...action.data };
    }),
    fetchNearbyLoadsCountAction.initiateCase((state) => {
      state.nearbyLoads.isCountLoading = true;
    }),
    fetchNearbyLoadsCountAction.completeCase((state, action) => {
      state.nearbyLoads.isCountLoading = false;
      if (action.response.success) {
        state.nearbyLoads.totalCount = action.response.payload.totalResultCount;
      }
    }),
  ],
  (state: LoadSearchState, action) => {
    const { nearbyLoads, ...rest } = state;
    const newState = loadSearch.reducer(rest, action);
    if (newState !== rest) {
      let referrer = state.searchReferrer;
      if (action.type === loadSearch.actionTypes.LOADS_FETCHED) {
        referrer = undefined;
      }
      return { ...newState, nearbyLoads: nearbyLoads, searchReferrer: referrer };
    }
    return state;
  }
);

const loadAlertPollingEpic$ = (action$: ActionsObservable<Action>, loadsClient: LoadsClient) =>
  action$
    .ofType(loadAlerts.fetchType, updateSearchAlertAction.responseType)
    .pipe(
      switchMap$(() => loadsClient.getSearchAlerts$().pipe(map$((response) => loadAlerts.responseAction(response))))
    );

const fetchSearchesOnListUpdate$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(createSearchAction.responseType, editSearchRequestAction.responseType).pipe(
    mergeMap$((action: ResultResponseActionWithFetchData<undefined, undefined>) => {
      if (action.response.success) {
        return of$(fetchAllSearchesAction.fetchAction(ViewFilter.ALL));
      }
      return of$();
    })
  );

// POST new search request, if PUT request fails with 404
const createSearchOnEditFail$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(editSearchRequestAction.responseType).pipe(
    mergeMap$(
      (action: ResultResponseActionWithFetchData<{}, { namedSearchId: string; request: NamedSearchRequest }>) => {
        if (
          !action.response.success &&
          action.response.error.code === ApiErrorCode.NAMED_LOAD_SEARCH_NOT_FOUND &&
          action.fetchData
        ) {
          return of$(createSearchAction.fetchAction(action.fetchData.request));
        }
        return of$();
      }
    )
  );

export const createLoadSearchEpic = (api: Api, isLiveEnvironment: boolean) => {
  const loadsClient = new LoadsClient(api, isLiveEnvironment);
  return (
    action$: ActionsObservable<Action>,
    state$: StateObservable<{ [LOAD_SEARCH_REDUCER_KEY]: LoadSearchState; [SETTINGS_REDUCER_KEY]: SettingsState }>
  ) => {
    return merge$(
      createSearchAction.createEpic$(action$, loadsClient.createSearch$),
      fetchAllSearchesAction.createEpic$(action$, loadsClient.fetchAllSearches$),
      fetchSearchByIDAction.createEpic$(action$, loadsClient.fetchSearchByID$),
      editSearchRequestAction.createEpic$(action$, ({ namedSearchId, request }) =>
        loadsClient.editSearchRequest$(namedSearchId, request)
      ),
      deleteSearchAction.createEpic$(action$, loadsClient.deleteSearch$),
      deleteAllSearchesAction.createEpic$(action$, loadsClient.deleteAllSearches$),
      updateSearchAlertAction.createEpic$(action$, loadsClient.updateSearch$),
      loadAlertPollingEpic$(action$, loadsClient),
      getNearbyLoadsAction.createEpic$(action$, loadsClient.searchNearbyLoads$),
      loadSearch.createMergedEpic$(
        loadsClient,
        action$,
        state$ as StateObservable<{ [LOAD_SEARCH_REDUCER_KEY]: LoadSearchState }>
      ),
      fetchNearbyLoadsCountAction.createEpic$(action$, loadsClient.fetchLoadCount$),
      fetchSearchesOnListUpdate$(action$),
      createSearchOnEditFail$(action$)
    );
  };
};

export const defaultSearchStateWithLastRequest = (prevState: LoadSearchState): LoadSearchState => {
  return {
    ...loadSearchInitialState,
    lastSearchRequest: prevState.lastSearchRequest,
  };
};

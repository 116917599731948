/*Copyright 2010-2021 Simplemaps.com
html5namapv4.15
Use pursuant to license agreement at https://simplemaps.com/license */

/* shifty - v1.5.3 - http://jeremyckahn.github.io/shifty, embedded within map logic*/
export const simplemaps = function(e = 'simplemaps_namap') {
  var t = {};
  (function() {
    var e,
      t,
      o,
      i,
      n,
      a,
      r,
      s,
      l,
      c,
      m,
      u = this || Function('return this')(),
      p = (function() {
        'use strict';
        var e,
          t,
          o,
          i,
          n,
          a = 'linear',
          r =
            Date.now ||
            function() {
              return +new Date();
            },
          s = 'undefined' != typeof SHIFTY_DEBUG_NOW ? SHIFTY_DEBUG_NOW : r;
        function l() {}
        function c(e, t) {
          for (var o in e) Object.hasOwnProperty.call(e, o) && t(o);
        }
        function m(e, t) {
          return (
            c(t, function(o) {
              e[o] = t[o];
            }),
            e
          );
        }
        function p(e, t) {
          c(t, function(o) {
            void 0 === e[o] && (e[o] = t[o]);
          });
        }
        function d(t, o, i, n, a, r, s) {
          var l,
            c,
            m = t < r ? 0 : (t - r) / a;
          for (l in o)
            o.hasOwnProperty(l) && ((c = 'function' == typeof (c = s[l]) ? c : e[c]), (o[l] = _(i[l], n[l], c, m)));
          return o;
        }
        function _(e, t, o, i) {
          return e + (t - e) * o(i);
        }
        function h(e, t) {
          var o = y.prototype.filter,
            i = e._filterArgs;
          c(o, function(n) {
            void 0 !== o[n][t] && o[n][t].apply(e, i);
          });
        }
        function f(e, t, a, r, l, c, m, u, p, _, f) {
          (n = t + a + r),
            (o = Math.min(f || s(), n)),
            (i = n <= o),
            (n = r - (n - o)),
            e.isPlaying() &&
              (i
                ? (p(m, e._attachment, n), e.stop(!0))
                : ((e._scheduleId = _(e._timeoutHandler, 1e3 / 60)),
                  h(e, 'beforeTween'),
                  o < t + a ? d(1, l, c, m, 1, 1, u) : d(o, l, c, m, r, t + a, u),
                  h(e, 'afterTween'),
                  p(l, e._attachment, n)));
        }
        function v(e, t) {
          var o = {},
            i = typeof t;
          return (
            c(
              e,
              'string' == i || 'function' == i
                ? function(e) {
                    o[e] = t;
                  }
                : function(e) {
                    o[e] || (o[e] = t[e] || a);
                  }
            ),
            o
          );
        }
        function y(e, o) {
          (this._currentState = e || {}),
            (this._configured = !1),
            (this._scheduleFunction = t),
            void 0 !== o && this.setConfig(o);
        }
        return (
          (t =
            ('undefined' != typeof window &&
              (window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                (window.mozCancelRequestAnimationFrame && window.mozRequestAnimationFrame))) ||
            setTimeout),
          (y.prototype.tween = function(e) {
            return this._isTweening
              ? this
              : ((void 0 === e && this._configured) || this.setConfig(e),
                (this._timestamp = s()),
                this._start(this.get(), this._attachment),
                this.resume());
          }),
          (y.prototype.setConfig = function(e) {
            (e = e || {}),
              (this._configured = !0),
              (this._attachment = e.attachment),
              (this._pausedAtTime = null),
              (this._scheduleId = null),
              (this._delay = e.delay || 0),
              (this._start = e.start || l),
              (this._step = e.step || l),
              (this._finish = e.finish || l),
              (this._duration = e.duration || 500),
              (this._currentState = m({}, e.from || this.get())),
              (this._originalState = this.get()),
              (this._targetState = m({}, e.to || this.get()));
            var t = this;
            this._timeoutHandler = function() {
              f(
                t,
                t._timestamp,
                t._delay,
                t._duration,
                t._currentState,
                t._originalState,
                t._targetState,
                t._easing,
                t._step,
                t._scheduleFunction
              );
            };
            var o = this._currentState,
              i = this._targetState;
            return (
              p(i, o),
              (this._easing = v(o, e.easing || a)),
              (this._filterArgs = [o, this._originalState, i, this._easing]),
              h(this, 'tweenCreated'),
              this
            );
          }),
          (y.prototype.get = function() {
            return m({}, this._currentState);
          }),
          (y.prototype.set = function(e) {
            this._currentState = e;
          }),
          (y.prototype.pause = function() {
            return (this._pausedAtTime = s()), (this._isPaused = !0), this;
          }),
          (y.prototype.resume = function() {
            return (
              this._isPaused && (this._timestamp += s() - this._pausedAtTime),
              (this._isPaused = !1),
              (this._isTweening = !0),
              this._timeoutHandler(),
              this
            );
          }),
          (y.prototype.seek = function(e) {
            e = Math.max(e, 0);
            var t = s();
            return (
              this._timestamp + e === 0 ||
                ((this._timestamp = t - e),
                this.isPlaying() ||
                  ((this._isTweening = !0),
                  (this._isPaused = !1),
                  f(
                    this,
                    this._timestamp,
                    this._delay,
                    this._duration,
                    this._currentState,
                    this._originalState,
                    this._targetState,
                    this._easing,
                    this._step,
                    this._scheduleFunction,
                    t
                  ),
                  this.pause())),
              this
            );
          }),
          (y.prototype.stop = function(e) {
            return (
              (this._isTweening = !1),
              (this._isPaused = !1),
              (this._timeoutHandler = l),
              (
                u.cancelAnimationFrame ||
                u.webkitCancelAnimationFrame ||
                u.oCancelAnimationFrame ||
                u.msCancelAnimationFrame ||
                u.mozCancelRequestAnimationFrame ||
                u.clearTimeout
              )(this._scheduleId),
              e &&
                (h(this, 'beforeTween'),
                d(1, this._currentState, this._originalState, this._targetState, 1, 0, this._easing),
                h(this, 'afterTween'),
                h(this, 'afterTweenEnd'),
                this._finish.call(this, this._currentState, this._attachment)),
              this
            );
          }),
          (y.prototype.isPlaying = function() {
            return this._isTweening && !this._isPaused;
          }),
          (y.prototype.setScheduleFunction = function(e) {
            this._scheduleFunction = e;
          }),
          (y.prototype.dispose = function() {
            for (var e in this) this.hasOwnProperty(e) && delete this[e];
          }),
          (y.prototype.filter = {}),
          (e = y.prototype.formula = {
            linear: function(e) {
              return e;
            },
          }),
          m(y, {
            now: s,
            each: c,
            tweenProps: d,
            tweenProp: _,
            applyFilter: h,
            shallowCopy: m,
            defaults: p,
            composeEasingObject: v,
          }),
          'function' == typeof SHIFTY_DEBUG_NOW && (u.timeoutHandler = f),
          (u.Tweenable = y)
        );
      })();
    function d(e) {
      t.each(e, function(t) {
        var o = e[t];
        'string' == typeof o && o.match(s) && (e[t] = f(s, o, _));
      });
    }
    function _(e) {
      return (
        'rgb(' +
        (e = (function(e) {
          return (
            3 === (e = e.replace(/#/, '')).length && (e = (e = e.split(''))[0] + e[0] + e[1] + e[1] + e[2] + e[2]),
            (c[0] = h(e.substr(0, 2))),
            (c[1] = h(e.substr(2, 2))),
            (c[2] = h(e.substr(4, 2))),
            c
          );
        })(e))[0] +
        ',' +
        e[1] +
        ',' +
        e[2] +
        ')'
      );
    }
    function h(e) {
      return parseInt(e, 16);
    }
    function f(e, t, o) {
      var i = t.match(e),
        n = t.replace(e, l);
      if (i) for (var a, r = i.length, s = 0; s < r; s++) (a = i.shift()), (n = n.replace(l, o(a)));
      return n;
    }
    function v(e) {
      for (var t = e.match(n), o = t.length, i = e.match(r)[0], a = 0; a < o; a++) i += parseInt(t[a], 10) + ',';
      return i.slice(0, -1) + ')';
    }
    function y(e, o) {
      t.each(o, function(t) {
        for (var i = b(e[t]), n = i.length, a = 0; a < n; a++) e[o[t].chunkNames[a]] = +i[a];
        delete e[t];
      });
    }
    function g(e, o) {
      t.each(o, function(t) {
        var i = e[t],
          n = (function(e, t) {
            m.length = 0;
            for (var o = t.length, i = 0; i < o; i++) m.push(e[t[i]]);
            return m;
          })(
            (function(e, t) {
              for (var o, i = {}, n = t.length, a = 0; a < n; a++) (i[(o = t[a])] = e[o]), delete e[o];
              return i;
            })(e, o[t].chunkNames),
            o[t].chunkNames
          );
        i = (function(e, t) {
          for (var o = e, i = t.length, n = 0; n < i; n++) o = o.replace(l, +t[n].toFixed(4));
          return o;
        })(o[t].formatString, n);
        e[t] = f(a, i, v);
      });
    }
    function b(e) {
      return e.match(n);
    }
    p.shallowCopy(p.prototype.formula, {
      easeInQuad: function(e) {
        return Math.pow(e, 2);
      },
      easeOutQuad: function(e) {
        return -(Math.pow(e - 1, 2) - 1);
      },
      easeInOutQuad: function(e) {
        return (e /= 0.5) < 1 ? 0.5 * Math.pow(e, 2) : -0.5 * ((e -= 2) * e - 2);
      },
      easeInCubic: function(e) {
        return Math.pow(e, 3);
      },
      easeOutCubic: function(e) {
        return Math.pow(e - 1, 3) + 1;
      },
      easeInOutCubic: function(e) {
        return (e /= 0.5) < 1 ? 0.5 * Math.pow(e, 3) : 0.5 * (Math.pow(e - 2, 3) + 2);
      },
      easeInQuart: function(e) {
        return Math.pow(e, 4);
      },
      easeOutQuart: function(e) {
        return -(Math.pow(e - 1, 4) - 1);
      },
      easeInOutQuart: function(e) {
        return (e /= 0.5) < 1 ? 0.5 * Math.pow(e, 4) : -0.5 * ((e -= 2) * Math.pow(e, 3) - 2);
      },
      easeInQuint: function(e) {
        return Math.pow(e, 5);
      },
      easeOutQuint: function(e) {
        return Math.pow(e - 1, 5) + 1;
      },
      easeInOutQuint: function(e) {
        return (e /= 0.5) < 1 ? 0.5 * Math.pow(e, 5) : 0.5 * (Math.pow(e - 2, 5) + 2);
      },
      easeInSine: function(e) {
        return 1 - Math.cos(e * (Math.PI / 2));
      },
      easeOutSine: function(e) {
        return Math.sin(e * (Math.PI / 2));
      },
      easeInOutSine: function(e) {
        return -0.5 * (Math.cos(Math.PI * e) - 1);
      },
      easeInExpo: function(e) {
        return 0 === e ? 0 : Math.pow(2, 10 * (e - 1));
      },
      easeOutExpo: function(e) {
        return 1 === e ? 1 : 1 - Math.pow(2, -10 * e);
      },
      easeInOutExpo: function(e) {
        return 0 === e
          ? 0
          : 1 === e
          ? 1
          : (e /= 0.5) < 1
          ? 0.5 * Math.pow(2, 10 * (e - 1))
          : 0.5 * (2 - Math.pow(2, -10 * --e));
      },
      easeInCirc: function(e) {
        return -(Math.sqrt(1 - e * e) - 1);
      },
      easeOutCirc: function(e) {
        return Math.sqrt(1 - Math.pow(e - 1, 2));
      },
      easeInOutCirc: function(e) {
        return (e /= 0.5) < 1 ? -0.5 * (Math.sqrt(1 - e * e) - 1) : 0.5 * (Math.sqrt(1 - (e -= 2) * e) + 1);
      },
      easeOutBounce: function(e) {
        return e < 1 / 2.75
          ? 7.5625 * e * e
          : e < 2 / 2.75
          ? 7.5625 * (e -= 1.5 / 2.75) * e + 0.75
          : e < 2.5 / 2.75
          ? 7.5625 * (e -= 2.25 / 2.75) * e + 0.9375
          : 7.5625 * (e -= 2.625 / 2.75) * e + 0.984375;
      },
      easeInBack: function(e) {
        return e * e * (2.70158 * e - 1.70158);
      },
      easeOutBack: function(e) {
        return --e * e * (2.70158 * e + 1.70158) + 1;
      },
      easeInOutBack: function(e) {
        var t = 1.70158;
        return (e /= 0.5) < 1
          ? e * e * ((1 + (t *= 1.525)) * e - t) * 0.5
          : 0.5 * ((e -= 2) * e * ((1 + (t *= 1.525)) * e + t) + 2);
      },
      elastic: function(e) {
        return -1 * Math.pow(4, -8 * e) * Math.sin(((6 * e - 1) * (2 * Math.PI)) / 2) + 1;
      },
      swingFromTo: function(e) {
        var t = 1.70158;
        return (e /= 0.5) < 1
          ? e * e * ((1 + (t *= 1.525)) * e - t) * 0.5
          : 0.5 * ((e -= 2) * e * ((1 + (t *= 1.525)) * e + t) + 2);
      },
      swingFrom: function(e) {
        return e * e * (2.70158 * e - 1.70158);
      },
      swingTo: function(e) {
        return --e * e * (2.70158 * e + 1.70158) + 1;
      },
      bounce: function(e) {
        return e < 1 / 2.75
          ? 7.5625 * e * e
          : e < 2 / 2.75
          ? 7.5625 * (e -= 1.5 / 2.75) * e + 0.75
          : e < 2.5 / 2.75
          ? 7.5625 * (e -= 2.25 / 2.75) * e + 0.9375
          : 7.5625 * (e -= 2.625 / 2.75) * e + 0.984375;
      },
      bouncePast: function(e) {
        return e < 1 / 2.75
          ? 7.5625 * e * e
          : e < 2 / 2.75
          ? 2 - (7.5625 * (e -= 1.5 / 2.75) * e + 0.75)
          : e < 2.5 / 2.75
          ? 2 - (7.5625 * (e -= 2.25 / 2.75) * e + 0.9375)
          : 2 - (7.5625 * (e -= 2.625 / 2.75) * e + 0.984375);
      },
      easeFromTo: function(e) {
        return (e /= 0.5) < 1 ? 0.5 * Math.pow(e, 4) : -0.5 * ((e -= 2) * Math.pow(e, 3) - 2);
      },
      easeFrom: function(e) {
        return Math.pow(e, 4);
      },
      easeTo: function(e) {
        return Math.pow(e, 0.25);
      },
    }),
      (p.setBezierFunction = function(e, t, o, i, n) {
        var a,
          r,
          s,
          l,
          c =
            ((a = t),
            (r = o),
            (s = i),
            (l = n),
            function(e) {
              return (function(e, t, o, i, n, a) {
                var r,
                  s,
                  l,
                  c,
                  m = 0,
                  u = 0,
                  p = 0;
                function d(e) {
                  return ((m * e + u) * e + p) * e;
                }
                function _(e) {
                  return 0 <= e ? e : 0 - e;
                }
                return (
                  (m = 1 - (p = 3 * t) - (u = 3 * (i - t) - p)),
                  (s = 1 - (c = 3 * o) - (l = 3 * (n - o) - c)),
                  (r = (function(e, t) {
                    var o, i, n, a, r, s;
                    for (n = e, s = 0; s < 8; s++) {
                      if (_((a = d(n) - e)) < t) return n;
                      if (_((r = (3 * m * n + 2 * u) * n + p)) < 1e-6) break;
                      n -= a / r;
                    }
                    if ((n = e) < (o = 0)) return o;
                    if ((i = 1) < n) return i;
                    for (; o < i; ) {
                      if (_((a = d(n)) - e) < t) return n;
                      a < e ? (o = n) : (i = n), (n = 0.5 * (i - o) + o);
                    }
                    return n;
                  })(e, (r = 1 / (200 * a)))),
                  ((s * r + l) * r + c) * r
                );
              })(e, a, r, s, l, 1);
            });
        return (c.displayName = e), (c.x1 = t), (c.y1 = o), (c.x2 = i), (c.y2 = n), (p.prototype.formula[e] = c);
      }),
      (p.unsetBezierFunction = function(e) {
        delete p.prototype.formula[e];
      }),
      ((e = new p())._filterArgs = []),
      (p.interpolate = function(t, o, i, n, a) {
        var r = p.shallowCopy({}, t),
          s = a || 0;
        a = p.composeEasingObject(t, n || 'linear');
        return (
          e.set({}),
          ((n = e._filterArgs).length = 0),
          (n[0] = r),
          (n[1] = t),
          (n[2] = o),
          (n[3] = a),
          p.applyFilter(e, 'tweenCreated'),
          p.applyFilter(e, 'beforeTween'),
          (t = t),
          (r = r),
          (o = o),
          (i = i),
          (a = a),
          (s = s),
          (a = p.tweenProps(i, r, t, o, 1, s, a)),
          p.applyFilter(e, 'afterTween'),
          a
        );
      }),
      (t = p),
      (o = /(\d|\-|\.)/),
      (i = /([^\-0-9\.]+)/g),
      (n = /[0-9.\-]+/g),
      (a = new RegExp('rgb\\(' + n.source + /,\s*/.source + n.source + /,\s*/.source + n.source + '\\)', 'g')),
      (r = /^.*\(/),
      (s = /#([0-9]|[a-f]){3,6}/gi),
      (l = 'VAL'),
      (c = []),
      (m = []),
      (t.prototype.filter.token = {
        tweenCreated: function(e, n, a, r) {
          d(e),
            d(n),
            d(a),
            (this._tokenData = (function(e) {
              var n = {};
              return (
                t.each(e, function(t) {
                  var a,
                    r,
                    s = e[t];
                  'string' == typeof s &&
                    ((a = b(s)),
                    (n[t] = {
                      formatString:
                        ((s = (r = s).match(i))
                          ? (1 !== s.length && !r.charAt(0).match(o)) || s.unshift('')
                          : (s = ['', '']),
                        s.join(l)),
                      chunkNames: (function(e, t) {
                        for (var o = [], i = e.length, n = 0; n < i; n++) o.push('_' + t + '_' + n);
                        return o;
                      })(a, t),
                    }));
                }),
                n
              );
            })(e));
        },
        beforeTween: function(e, o, i, n) {
          var a, r;
          (a = n),
            (r = this._tokenData),
            t.each(r, function(e) {
              var t = r[e].chunkNames,
                o = t.length,
                i = a[e];
              if ('string' == typeof i)
                for (var n = i.split(' '), s = n[n.length - 1], l = 0; l < o; l++) a[t[l]] = n[l] || s;
              else for (l = 0; l < o; l++) a[t[l]] = i;
              delete a[e];
            }),
            y(e, this._tokenData),
            y(o, this._tokenData),
            y(i, this._tokenData);
        },
        afterTween: function(e, o, i, n) {
          var a, r;
          g(e, this._tokenData),
            g(o, this._tokenData),
            g(i, this._tokenData),
            (a = n),
            (r = this._tokenData),
            t.each(r, function(e) {
              var t = r[e].chunkNames,
                o = t.length,
                i = a[t[0]];
              if ('string' == typeof i) {
                for (var n = '', s = 0; s < o; s++) (n += ' ' + a[t[s]]), delete a[t[s]];
                a[e] = n.substr(1);
              } else a[e] = i;
            });
        },
      });
  }.call(null),
    (function(e, t) {
      (e = e || 'docReady'), (t = t || window);
      var o = [],
        i = !1,
        n = !1;
      function a() {
        if (!i) {
          i = !0;
          for (var e = 0; e < o.length; e++) o[e].fn.call(window, o[e].ctx);
          o = [];
        }
      }
      function r() {
        'complete' === document.readyState && a();
      }
      t[e] = function(e, t) {
        i
          ? setTimeout(function() {
              e(t);
            }, 1)
          : (o.push({ fn: e, ctx: t }),
            'complete' === document.readyState || (!document.attachEvent && 'interactive' === document.readyState)
              ? setTimeout(a, 1)
              : n ||
                (document.addEventListener
                  ? (document.addEventListener('DOMContentLoaded', a, !1), window.addEventListener('load', a, !1))
                  : (document.attachEvent('onreadystatechange', r), window.attachEvent('onload', a)),
                (n = !0)));
      };
    })('docReady', t),
    (function(e, t, o) {
      (void 0 !== e && void 0 !== e.log) || ((e = {}).log = function() {}),
        'function' != typeof t.create &&
          (t.create = function(e) {
            function t() {}
            return (t.prototype = e), new t();
          }),
        o.prototype.forEach ||
          (o.prototype.forEach = function(e, t) {
            for (var o = 0, i = this.length; o < i; ++o) e.call(t, this[o], o, this);
          });
    })(window.console, window.Object, window.Array),
    (Object.size = function(e) {
      var t,
        o = 0;
      for (t in e) e.hasOwnProperty(t) && o++;
      return o;
    }));
  var o = (function() {
      var e = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera\sMini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return e.Android() || e.BlackBerry() || e.iOS() || e.Opera() || e.Windows();
        },
      };
      function t(e, t) {
        var o = e[0],
          i = e[1],
          n = Raphael.transformPath('M' + o + ',' + i, t).toString(),
          a = /M(-?\d+.?\d*),(-?\d+.?\d*)/.exec(n);
        return [a[1], a[2]];
      }
      function i(e) {
        return Math.min.apply(Math, e);
      }
      function n(e) {
        return Math.max.apply(Math, e);
      }
      return {
        min: i,
        max: n,
        addEvent: function(e, t, o) {
          e.attachEvent
            ? ((e['e' + t + o] = o),
              (e[t + o] = function() {
                e['e' + t + o](window.event);
              }),
              e.attachEvent('on' + t, e[t + o]))
            : e.addEventListener(t, o, !1);
        },
        isMobile: e,
        linePath: function(e, t, o, i) {
          return 'M' + e + ' ' + t + ' L' + o + ' ' + i;
        },
        clone: function e(t) {
          if ('object' != typeof t || null === t) return t;
          var o = t.constructor();
          for (var i in t) o[i] = e(t[i]);
          return o;
        },
        isFunction: function(e) {
          return e && '[object Function]' === {}.toString.call(e);
        },
        findPos: function(e) {
          function t(e, t) {
            if (e.currentStyle) var o = e.currentStyle[t];
            else window.getComputedStyle && (o = window.getComputedStyle(e, null)[t]);
            return o;
          }
          var o = t(document.body, 'position');
          'relative' == o && (document.body.style.position = 'static'), t(document.body, 'position');
          for (var i, n, a = (i = 0), r = e, s = !1; (r = r.parentNode) && r != document.body; )
            (a -= r.scrollLeft || 0), (i -= r.scrollTop || 0), 'fixed' == t(r, 'position') && (s = !0);
          if (s && !window.opera) {
            var l =
              (n = document.getElementsByTagName('html')[0]).scrollTop && document.documentElement.scrollTop
                ? [n.scrollLeft, n.scrollTop]
                : n.scrollTop || document.documentElement.scrollTop
                ? [n.scrollLeft + document.documentElement.scrollLeft, n.scrollTop + document.documentElement.scrollTop]
                : document.body.scrollTop
                ? [document.body.scrollLeft, document.body.scrollTop]
                : [0, 0];
            (a += l[0]), (i += l[1]);
          }
          do {
            (a += e.offsetLeft), (i += e.offsetTop);
          } while ((e = e.offsetParent));
          return (document.body.style.position = o), [a, i];
        },
        replaceAll: function(e, t, o) {
          return e.replace(new RegExp(t, 'g'), o);
        },
        rotate_bbox: function(e, o) {
          var a = [e.x, e.y],
            r = [e.x2, e.y],
            s = [e.x, e.y2],
            l = [e.x2, e.y2],
            c = t(a, o),
            m = t(r, o),
            u = t(s, o),
            p = t(l, o),
            d = [c[0], m[0], u[0], p[0]],
            _ = [c[1], m[1], u[1], p[1]],
            h = i(d),
            f = n(d),
            v = i(_),
            y = n(_);
          return { x: h, y: v, x2: f, y2: y, width: f - h, height: y - v };
        },
        rotate: t,
        bbox_union: function(e) {
          for (var t = [], i = [], n = [], a = [], r = 0; r < e.length; r++) {
            var s = e[r];
            t.push(s.x), i.push(s.x2), a.push(s.y), n.push(s.y2);
          }
          var l = o.min(t),
            c = o.max(i),
            m = o.min(a),
            u = o.max(n);
          return { x: l, x2: c, y: m, y2: u, width: c - l, height: u - m };
        },
        distance: function(e, t) {
          var o = e.x,
            i = e.y,
            n = t.x - o,
            a = t.y - i;
          return Math.sqrt(a * a + n * n);
        },
        x_in_array: function(e, t) {
          for (var o = t.length; o--; ) if (t[o] === e) return !0;
          return !1;
        },
        clear_sets: function(e) {
          for (var t = 0; t < e.length; t++) {
            var o = e[t];
            o.forEach(function(e) {
              e.remove();
            }),
              o.splice(0, o.length);
          }
        },
        delete_element: function(e) {
          e.parentNode && e.parentNode.removeChild(e);
        },
        to_float: function(e) {
          var t = parseFloat(e);
          return !isNaN(t) && t;
        },
        callback_closure: function(e, t) {
          return function() {
            return t(e);
          };
        },
        new_style: function(e) {
          var t = document.getElementsByTagName('head')[0],
            o = document.createElement('style');
          return (
            (o.type = 'text/css'),
            (o.media = 'screen'),
            o.styleSheet ? (o.styleSheet.cssText = e) : o.appendChild(document.createTextNode(e)),
            t.appendChild(o),
            o
          );
        },
      };
    })(),
    i = !!window[e + '_mapdata'] && window[e + '_mapdata'],
    n = !!window[e + '_mapinfo'] && window[e + '_mapinfo'],
    a = e.substring(0, e.length - 3).replace('simplemaps_', ''),
    r = !1,
    s = !1,
    l = !1,
    c = [],
    m = {
      rounded_box:
        'm2.158.263h5.684c1.05 0 1.895.845 1.895 1.895v5.684c0 1.05-.845 1.895-1.895 1.895h-5.684c-1.05 0-1.895-.845-1.895-1.895v-5.684c0-1.05.845-1.895 1.895-1.895z',
      plus:
        'm4.8 1.5c-.111 0-.2.089-.2.2v3h-2.9c-.111 0-.2.134-.2.3 0 .166.089.3.2.3h2.9v3c0 .111.089.2.2.2h.2c.111 0 .2-.089.2-.2v-3h3.1c.111 0 .2-.134.2-.3 0-.166-.089-.3-.2-.3h-3.1v-3c0-.111-.089-.2-.2-.2z',
      minus: 'm1.8 4.7h6.6c.111 0 .2.134.2.3 0 .166-.089.3-.2.3h-6.6c-.111 0-.2-.134-.2-.3 0-.166.089-.3.2-.3',
      arrow:
        'm7.07 8.721c2.874-1.335 2.01-5.762-2.35-5.661v-1.778l-3.445 2.694 3.445 2.843v-1.818c3.638-.076 3.472 2.802 2.35 3.721z',
    },
    u = {
      mapdata: i,
      mapinfo: n,
      load: p,
      hooks: o.clone({
        over_state: !1,
        over_region: !1,
        over_location: !1,
        out_state: !1,
        out_region: !1,
        out_location: !1,
        click_state: !1,
        click_region: !1,
        click_location: !1,
        close_popup: !1,
        zoomable_click_state: !1,
        zoomable_click_region: !1,
        complete: !1,
        refresh_complete: !1,
        zooming_complete: !1,
        back: !1,
        ready: !1,
        click_xy: !1,
      }),
      plugin_hooks: o.clone({
        over_state: [],
        over_region: [],
        over_location: [],
        out_state: [],
        out_region: [],
        out_location: [],
        click_state: [],
        click_region: [],
        click_location: [],
        preclick_state: [],
        preclick_region: [],
        preclick_location: [],
        close_popup: [],
        zoomable_click_state: [],
        zoomable_click_region: [],
        complete: [],
        refresh_complete: [],
        zooming_complete: [],
        back: [],
        ready: [],
        click_xy: [],
      }),
      copy: function() {
        var e = {
          mapdata: o.clone(this.mapdata),
          mapinfo: o.clone(this.mapinfo),
          hooks: o.clone(this.hooks),
          plugin_hooks: o.clone(this.plugin_hooks),
          copy: this.copy,
          load: p,
        };
        return c.push(e), e;
      },
      create: function() {
        var t = {
          mapdata: !!window[e + '_mapdata'] && o.clone(window[e + '_mapdata']),
          mapinfo: !!window[e + '_mapinfo'] && o.clone(window[e + '_mapinfo']),
          hooks: o.clone(this.hooks),
          plugin_hooks: o.clone(this.plugin_hooks),
          copy: this.copy,
          load: p,
        };
        return c.push(t), t;
      },
      mobile_device: !!o.isMobile.any(),
      loaded: !1,
    };
  function p() {
    var e = this,
      i = e.mapdata,
      n = e.mapinfo;
    if (i && n) {
      var map_legend,
        c,
        u,
        p,
        d,
        _,
        h,
        f,
        v,
        y,
        g,
        b,
        x,
        w,
        k,
        z,
        M,
        E,
        A,
        C,
        T,
        S,
        O,
        B,
        F,
        I,
        P,
        R,
        j,
        N,
        L,
        D,
        H,
        q,
        W,
        Y,
        V,
        Q,
        X,
        U,
        Z,
        G,
        J,
        K,
        $,
        ee,
        te,
        oe,
        ie,
        ne,
        ae,
        re,
        se = e.hooks,
        le = e.plugin_hooks,
        ce = void 0 === i.main_settings.div ? 'map' : i.main_settings.div;
      if (!document.getElementById(ce))
        return console.log("Can't find target for map #" + ce + '.  Check mapdata.main_settings.div'), !1;
      var me,
        ue,
        pe,
        de,
        _e,
        he,
        fe,
        ve,
        ye,
        ge,
        be,
        xe,
        we,
        ke,
        ze,
        Me,
        Ee,
        Ae,
        Ce,
        Te,
        Se,
        Oe,
        Be,
        Fe,
        Ie,
        Pe,
        Re,
        je,
        Ne,
        Le,
        De,
        He,
        qe,
        We,
        Ye,
        Ve,
        Qe,
        Xe,
        Ue,
        Ze,
        Ge,
        Je,
        Ke,
        $e,
        et,
        tt,
        ot,
        it,
        nt,
        at,
        rt,
        st,
        lt,
        ct,
        mt,
        ut,
        pt,
        dt,
        _t,
        ht,
        ft,
        vt,
        yt,
        gt,
        bt,
        xt,
        wt,
        kt,
        zt,
        Mt,
        Et,
        At,
        Ct,
        Tt,
        St,
        Ot,
        Bt,
        Ft,
        It,
        Pt,
        Rt,
        jt = !1,
        Nt = !1,
        Lt = !1,
        Dt = !1,
        Ht = !1,
        qt = !1,
        Wt = !1,
        Yt = !1,
        Vt = !1,
        Qt = {
          triangle: 'M -0.57735,.3333 .57735,.3333 0,-.6666 Z',
          diamond: 'M 0,-0.5 -0.4,0 0,0.5 0.4,0 Z',
          marker:
            'm-.015-.997c-.067 0-.13.033-.18.076-.061.054-.099.136-.092.219-.0001.073.034.139.068.201.058.104.122.206.158.32.021.058.039.117.058.175.006.009.011-.004.011-.009.037-.125.079-.249.144-.362.043-.08.095-.157.124-.244.022-.075.016-.161-.026-.229-.048-.08-.134-.136-.227-.146-.013-.0001-.027-.0001-.04-.0001z',
          heart:
            'm-.275-.5c-.137.003-.257.089-.3.235-.073.379.348.539.58.765.202-.262.596-.33.576-.718-.017-.086-.065-.157-.13-.206-.087-.066-.208-.089-.311-.05-.055.02-.106.053-.143.098-.065-.081-.169-.127-.272-.125',
          star:
            'm0-.549c-.044.126-.084.252-.125.379-.135.0001-.271.0001-.405.002.108.078.216.155.323.233-.002.029-.016.057-.023.085-.032.099-.066.199-.097.298.049-.031.095-.068.143-.101.062-.044.124-.089.185-.133.109.077.216.158.326.233-.04-.127-.082-.253-.123-.379.109-.079.219-.156.327-.236-.135-.0001-.27-.002-.405-.003-.042-.126-.081-.252-.125-.377',
        },
        Xt = function(e, t, o, i) {
          null == o && (o = !1),
            null == i && (i = !1),
            i ||
              (i = 'over' == t ? e.sm.over_attributes : 'adjacent' == t ? e.sm.adjacent_attributes : e.sm.attributes),
            !o || e.sm.image ? e.attr(i) : e.animate(i, D);
        },
        Ut = function(e, t, i) {
          if ((null == i && (i = !1), 'state' == i)) var n = e.sm.parent;
          else if ('region' == i) n = ft[e.sm.parent.sm.region];
          else n = Yo(e.sm.parent);
          var a;
          if (
            ((a =
              'over' == t
                ? o.clone(n.sm.over_attributes)
                : 'adjacent' == t
                ? o.clone(n.sm.adjacent_attributes)
                : o.clone(n.sm.attributes)),
            n.sm.image && 'state' == n.sm.type)
          ) {
            var r = tt[n.sm.id];
            a.fill = 'over' == t ? r.hover_color : r.color;
          }
          Xt(e, t, !1, a);
        },
        Zt = !1,
        Gt = function(e, t) {
          if (e) {
            var i = !!(e.sm.image || (me && g));
            if ((Rt.hide(), Vo(e)))
              e.sm.ignore_hover || e.animate(e.sm.attributes, D, n),
                e.animate(e.sm.adjacent_attributes, D, n),
                Qo(e, 'out', !0);
            else {
              if (ie && 'location' == e.sm.type && 'image' == e.sm.shape_type) return;
              if (!e || !e.sm) return;
              e.sm.ignore_hover || (i ? (e.attr(e.sm.attributes), n()) : e.animate(e.sm.attributes, D, n)),
                Qo(e, 'out');
            }
          } else t();
          function n() {
            o.isFunction(t) && t();
          }
        },
        Jt = !1,
        Kt = !1,
        $t = function() {
          So();
        },
        eo = !1,
        to = function(e, t) {
          if (!eo || e || t) {
            eo = !0;
            var i = function(t) {
              var i = ne ? event.clientX + document.documentElement.scrollLeft : t.pageX,
                n = ne ? event.clientY + document.documentElement.scrollTop : t.pageY,
                a = o.findPos(_e),
                r = a[0],
                s = a[1],
                l = vt.sm.zooming_dimensions,
                c = (l.r * be) / we,
                m = (l.r * xe) / we,
                u = l.x / we + (c * (i - r)) / be,
                p = l.y / we + (m * (n - s)) / xe,
                d =
                  'You clicked on\nx: ' +
                  (u = Math.round(1e5 * u) / 1e5) +
                  ',\ny: ' +
                  (p = Math.round(1e5 * p) / 1e5) +
                  ',';
              e && console.log(d), uo('click_xy', [{ x: u, y: p }]);
            };
            t || He.click(i), Qe.click(i);
          }
        },
        oo = function() {
          to(!0);
        },
        io = function(e) {
          (se.click_xy || le.click_xy.length > 0) && to(!1, e);
        },
        no = function(e) {
          var t = e.sm.type;
          'state' == t && uo('over_state', [e.sm.id, e]),
            'location' == t && uo('over_location', [e.sm.id]),
            'region' == t && uo('over_region', [e.sm.id]);
        },
        ao = function(e) {
          var t = e.sm.type;
          'state' == t && uo('out_state', [e.sm.id]),
            'location' == t && uo('out_location', [e.sm.id]),
            'region' == t && uo('out_region', [e.sm.id]);
        },
        ro = function(e, t) {
          var o = e.sm.type;
          'state' == o && uo('click_state', [e.sm.id, t]),
            'region' == o && uo('click_region', [e.sm.id, t]),
            'location' == o && uo('click_location', [e.sm.id, t]);
        },
        so = function(e, t) {
          var o = e.sm.type;
          'state' == o && uo('preclick_state', [e.sm.id, t]),
            'region' == o && uo('preclick_region', [e.sm.id, t]),
            'location' == o && uo('preclick_location', [e.sm.id, t]);
        },
        lo = function(e, t) {
          var o = e.sm.type;
          'state' == o && uo('zoomable_click_state', [e.sm.id, t]),
            'region' == o && uo('zoomable_click_region', [e.sm.id, t]);
        },
        co = !1,
        mo = ((co = !1), !1);
      mo = !1;
      vi(), ti();
    } else console.log('The mapdata or mapinfo object is missing or corrupted.');
    function uo(t, o) {
      (a = se[t]) && a.apply(null, o);
      for (var i = e.plugin_hooks[t], n = 0; n < i.length; n++) {
        var a;
        (a = i[n]) && a.apply(null, o);
      }
    }
    function po() {
      (C = !(!_.background_image_url || 'no' == _.background_image_url) && _.background_image_url),
        (T = !!_.background_image_bbox && _.background_image_bbox),
        (Y = 'yes' == _.background_transparent ? 0 : 1),
        (Q = _.label_size ? _.label_size : 22),
        _.label_color ? _.label_color : '#ffffff',
        (X = 'yes' == _.url_new_tab),
        (U = _.location_opacity ? _.location_opacity : 1),
        'yes' == _.js_hooks,
        (Z = _.border_size ? _.border_size : 1.5),
        (G = _.popup_color ? _.popup_color : '#ffffff'),
        (P = _.popup_orientation ? _.popup_orientation : 'auto'),
        (I = _.popup_centered ? _.popup_centered : 'auto'),
        (K = _.popup_opacity ? _.popup_opacity : 0.9),
        ($ = _.popup_shadow > -1 ? _.popup_shadow : 1),
        (ee = _.popup_corners ? _.popup_corners : 5),
        (te = 'yes' == _.popup_nocss),
        (J = !!_.popup_maxwidth && _.popup_maxwidth),
        (oe = _.popup_font ? _.popup_font : '12px/1.5 Verdana, Arial, Helvetica, sans-serif'),
        (V = 'no' != _.zoom_out_incrementally),
        (W = _.adjacent_opacity ? _.adjacent_opacity : 0.3),
        (S = _.zoom_time ? _.zoom_time : 0.5),
        (B = _.zoom_increment ? _.zoom_increment : 2),
        (O = 'no' != _.zoom_mobile),
        (D = _.fade_time ? 1e3 * _.fade_time : 200),
        (q = i.labels),
        (F = _.custom_shapes ? _.custom_shapes : {}),
        (j = !(!_.initial_back || 'no' == _.initial_back) && _.initial_back),
        (H = 'yes' == _.hide_eastern_labels),
        (N = _.link_text ? _.link_text : 'View Website'),
        !!_.order_number && _.order_number,
        (R = _.zoom_percentage ? _.zoom_percentage : 0.99);
    }
    function _o(e) {
      return 'on_click' == e || !('detect' != e || !me);
    }
    function ho(e) {
      return 'off' == e;
    }
    function fo(e) {
      if (
        ((he.style.width = ''),
        (fe.style.width = ''),
        b
          ? ((be = he.offsetWidth) < 1 && (be = he.parentNode.offsetWidth), (fe.style.width = be + 'px'))
          : ((be = void 0 === _.width ? 800 : _.width), (he.style.width = be + 'px')),
        (be *= 1),
        n.calibrate
          ? (((Me = {}).x = -1 * n.calibrate.x_adjust),
            (Me.y = -1 * n.calibrate.y_adjust),
            (Me.x2 = Me.x + n.calibrate.width),
            (Me.y2 = (Me.x2 - Me.x) / n.calibrate.ratio))
          : (Me = n.initial_view),
        (ke = Me.x2 - Me.x),
        (ze = Me.y2 - Me.y),
        (xe = be / (Ce = ke / ze)),
        (Ee = ke / 1e3),
        (_e.style.height = xe + 'px'),
        !e)
      ) {
        if (((we = be / ke), (Ae = 1), y)) {
          var t = [];
          for (var i in n.state_bbox_array) {
            var a = n.state_bbox_array[i];
            t.push(a);
          }
          var r = o.bbox_union(t),
            s = 0.5 * (r.x2 + r.x) * we,
            l = 0.5 * (r.y2 + r.y) * we;
          ge = 'r' + y + ',' + s + ',' + l;
          var c = o.rotate_bbox(Me, ge);
          (ke = c.width), (ze = c.height);
        }
        (Re = 's' + we + ',' + we + ',0,0'), (Pe = y ? Re + ge : Re);
      }
    }
    function vo() {
      if (r)
        if (location.hostname.match('simplemaps.com')) r = !1;
        else {
          if (Nt) {
            if (Math.random() > 0.05) return;
            o.delete_element(Nt);
          }
          Nt = document.createElement('div');
          var e = s || l ? '20px' : '5px';
          (Nt.style.cssText =
            'overflow: visible !important; clip-path: none !important; display:inline !important; opacity:1 !important; transform: none !important; visibility: visible !important; z-index: 1 !important; right: 5px !important; bottom:' +
            e +
            ' !important; z-index: 1 !important; position: absolute !important; filter: opacity(1) !important;'),
            _e.appendChild(Nt),
            (Nt.innerHTML =
              '<a style="overflow: visible !important; clip-path: none !important; opacity: 1 !important;  filter: opacity(1) !important; transform: none !important; display: block !important; visibility: visible !important; font: 18px Verdana, Arial, Helvetica, sans-serif !important; cursor: pointer !important; font-weight: bold !important; float: right !important; color: #000000 !important; text-decoration: none !important;" href="https://simplemaps.com" title="For evaluation use only.">Simplemaps.com Trial</a>'),
            ne || (503 != Nt.innerHTML.length && (_e.innerHTML = ''));
        }
    }
    function yo() {
      var t,
        o,
        i = _.navigation_color ? _.navigation_color : '#f7f7f7',
        n = _.navigation_border_color ? _.navigation_border_color : '#636363',
        a = _.navigation_opacity ? _.navigation_opacity : 0.8,
        r = _.arrow_color ? _.arrow_color : i,
        s = _.arrow_color_border ? _.arrow_color_border : n,
        l = _.arrow_border_color ? _.arrow_border_color : s,
        u = void 0 === _.navigation_size ? 40 : _.navigation_size,
        p = void 0 === _.navigation_size_mobile ? pe * u : _.navigation_size_mobile,
        d = me ? p : u,
        h = 'yes' == _.arrow_box ? 1 : 0,
        f = (d *= 1),
        v = 0.1 * d,
        y = d / 10;
      !(function() {
        Ue = Te.set();
        var s = d,
          u = d;
        if (c) {
          var p = new Image();
          (p.onload = function() {
            (t = p.width), (o = p.height), _();
          }),
            (p.src = c);
        } else _();
        function _() {
          if (c) {
            var p = (Xe = Raphael(de, t, o)).image(c, 0, 0, t, o);
            (f = o), p.attr({ cursor: 'pointer' }), Ue.push(p), Ue.click(It);
          } else {
            Xe = Raphael(de, s, u);
            var _ = Xe.path(m.rounded_box).attr({
                fill: r,
                'stroke-width': 1,
                stroke: l,
                'stroke-opacity': h,
                'fill-opacity': 0,
                cursor: 'pointer',
              }),
              b = {
                stroke: l,
                'stroke-width': 1.5,
                'stroke-opacity': 1,
                fill: r,
                'fill-opacity': 1,
                cursor: 'pointer',
              },
              k = Xe.path(m.arrow).attr(b);
            (Ue = Te.set()).push(_, k);
            var M = 'S' + y + ',' + y + ',0,0 T0,0';
            Ue.transform(M);
          }
          j || Ue.hide(),
            (de.style.left = v + 'px'),
            (de.style.top = v + 'px'),
            //added this to remove div with 'back' button that appears after zooming in
            // (this div prevents states from clicking because it is "above" state div and always in DOM
            (de.style.display = 'none'),
            g &&
              (function() {
                Je = Raphael(ve, d, 2 * d + v);
                var t = m.plus,
                  o = m.minus,
                  r = m.rounded_box,
                  s = Je.path(r).attr({
                    fill: i,
                    'stroke-width': 1,
                    stroke: n,
                    'stroke-opacity': 1,
                    'fill-opacity': a,
                    cursor: 'pointer',
                  }),
                  l = Je.path(t).attr({
                    'stroke-width': 0,
                    'stroke-opacity': 0,
                    fill: n,
                    'fill-opacity': 1,
                    opacity: 1,
                    cursor: 'pointer',
                  });
                (Ze = Te.set()).push(s, l);
                var c = 'S' + y + ',' + y + ',0,0 T0,0';
                Ze.transform(c);
                var u = Je.path(r).attr({
                    fill: i,
                    'stroke-width': 1,
                    stroke: n,
                    'stroke-opacity': 1,
                    'fill-opacity': a,
                    cursor: 'pointer',
                  }),
                  p = Je.path(o).attr({
                    'stroke-width': 0,
                    'stroke-opacity': 0,
                    fill: n,
                    'fill-opacity': 1,
                    opacity: 1,
                    cursor: 'pointer',
                  });
                (Ge = Te.set()).push(u, p);
                var c = 'S' + y + ',' + y + ',0,0 T0,' + (d + v);
                function _(e, t) {
                  if ((void 0 === t && (t = 'z'), z && 'region' != z.sm.type))
                    St.call(z, !0, function() {
                      (z = !1), _(e);
                    });
                  else {
                    if (
                      'z' == t &&
                      !(function(e) {
                        var t = vt.sm.zooming_dimensions.w / e,
                          o = e < 1;
                        if (-1 != x && ('manual' == vt.sm.type || w)) {
                          var i = ft[x].sm.zooming_dimensions.w,
                            n = t > i - 1;
                          if (o && n) {
                            var a = Io(vt, ft[x]);
                            if (a || w) return Oo(ft[x]), !1;
                          }
                        }
                        if (o && t > ft[-1].sm.zooming_dimensions.w - 1) return w || Oo(ft[-1]), !1;
                        return !0;
                      })(e)
                    )
                      return;
                    var o = { sm: { type: 'manual', zp: 1 } };
                    pt &&
                      (((vt = {
                        sm: { type: 'manual', zp: 1 },
                      }).sm.zooming_dimensions = dt),
                      (vt.sm.bbox = {
                        x: dt.x / we,
                        y: dt.y / we,
                        width: dt.w / we,
                        height: dt.h / we,
                      }));
                    var i = (function(e, t) {
                      var o = vt.sm.zooming_dimensions.w,
                        i = vt.sm.zooming_dimensions.h,
                        n = vt.sm.zooming_dimensions.x,
                        a = vt.sm.zooming_dimensions.y;
                      if ('x' == t) var n = vt.sm.zooming_dimensions.x + vt.sm.zooming_dimensions.w * e;
                      else if ('y' == t) var a = vt.sm.zooming_dimensions.y + vt.sm.zooming_dimensions.h * e;
                      else
                        var o = vt.sm.zooming_dimensions.w / e,
                          i = vt.sm.zooming_dimensions.h / e,
                          n = vt.sm.zooming_dimensions.x + (vt.sm.zooming_dimensions.w - o) / 2,
                          a = vt.sm.zooming_dimensions.y + (vt.sm.zooming_dimensions.h - i) / 2;
                      return { x: n, y: a, w: o, h: i, r: o / (ke * we) };
                    })(e, t);
                    if (!i) return;
                    (o.sm.zooming_dimensions = i), Oo(o);
                  }
                }
                Ge.transform(c),
                  (ve.style.top = f + 2 * v + 'px'),
                  (ve.style.left = v + 'px'),
                  (Ge = Te.set()).push(u, p),
                  (Ke = function() {
                    _(B);
                  }),
                  (et = function(e, t) {
                    _(e, t);
                  }),
                  ($e = function() {
                    _(1 / B);
                  }),
                  (e.zoom_in = Ke),
                  (e.zoom_out = $e),
                  Ze.click(Ke),
                  Ge.click($e),
                  Ze.touchend(Ke),
                  Ge.touchend($e);
              })();
        }
      })();
    }
    function go() {
      (st = i.locations), (tt = []), (ot = []), (at = []), (rt = []), (it = []), (mt = []);
      !(function() {
        var e = { color: !1, hover_color: !1 };
        if (
          ((e.opacity = _.region_opacity ? _.region_opacity : 1),
          (e.hover_opacity = _.region_hover_opacity ? _.region_hover_opacity : 0.6),
          (e.url = !1),
          (e.description = !1),
          (e.description_mobile = !1),
          (e.inactive = !1),
          (e.zoomable = !0),
          (e.hide_name = 'yes' == _.region_hide_name),
          (e.popup = _.region_popups ? _.region_popups : ue),
          (e.cascade = 'yes' == _.region_cascade_all),
          (e.zoom_percentage = R),
          (e.x = !1),
          (e.y = !1),
          (e.x2 = !1),
          (e.y2 = !1),
          E)
        )
          for (var t in E)
            for (var o = 0; o < E[t].states.length; o++) {
              var i = E[t].states[o];
              at[i] = t;
            }
        for (var n in E)
          for (var a in ((it[n] = Object.create(e)), E[n].url && (it[n].zoomable = !1), E[n]))
            'default' != E[n][a] && (it[n][a] = E[n][a]),
              'yes' == E[n][a] && (it[n][a] = !0),
              'no' == E[n][a] && (it[n][a] = !1);
      })(),
        (function() {
          for (var e in ((lt = function(e) {
            var t = {};
            (t.color = _.state_color),
              (t.image_url = !!_.state_image_url && _.state_image_url),
              (t.image_size = _.state_image_size ? _.state_image_size : 'auto'),
              (t.image_position = _.state_image_position ? _.state_image_position : 'center'),
              (t.image_x = _.state_image_x ? _.state_image_x : '0'),
              (t.image_y = _.state_image_y ? _.state_image_y : '0'),
              (t.image_color = !!_.state_image_color && _.state_image_color),
              (t.image_background_opacity = _.image_background_opacity ? _.image_background_opacity : '1'),
              (t.image_hover_url = !!_.state_image_hover_url && _.state_image_hover_url),
              (t.image_hover_size = _.state_image_hover_size ? _.state_image_hover_size : 'auto'),
              (t.image_hover_position = _.state_image_hover_position ? _.state_image_hover_position : 'center'),
              (t.image_hover_x = _.state_image_hover_x ? _.state_image_hover_x : '0'),
              (t.image_hover_y = _.state_image_hover_y ? _.state_image_hover_y : '0'),
              (t.image_hover_color = !!_.state_image_hover_color && _.state_image_hover_color),
              (t.pulse_size = _.state_pulse_size ? _.state_pulse_size : 1.1),
              (t.pulse_speed = _.state_pulse_speed ? _.state_pulse_speed : 1);
            var o = _.state_pulse_color;
            (t.pulse_color = !(!o || 'auto' == o) && o),
              (t.hover_color = _.state_hover_color),
              (t.image_source = !1),
              (t.description = _.state_description),
              (t.url = _.state_url),
              (t.inactive = 'yes' == _.all_states_inactive),
              (t.hide = 'yes' == _.all_states_hidden),
              (t.hide_label = !1),
              (t.hide_name = 'yes' == _.state_hide_name),
              (t.border_color = _.border_color ? _.border_color : '#ffffff'),
              (t.border_hover_color = !!_.border_hover_color && _.border_hover_color),
              (t.border_hover_size = !!_.border_hover_size && _.border_hover_size),
              (t.emphasize = 'yes'),
              (t.zoom_percentage = R),
              (t.zoomable = 'yes' == _.all_states_zoomable),
              (t.popup = _.state_popups ? _.state_popups : ue),
              (t.opacity = _.state_opacity ? _.state_opacity : 1),
              (t.hover_opacity = _.state_hover_opacity ? _.state_hover_opacity : 1),
              (t.description_mobile = !!_.state_description_mobile && state_description_mobile),
              t.pu,
              (t.path = !1);
            var i = !!at[e] && at[e];
            for (var n in (i && it[i].hide && (t.hide = !0),
            i &&
              it[i].cascade &&
              (it[i].color && (t.color = it[i].color),
              it[i].hover_color && (t.hover_color = it[i].hover_color),
              it[i].description && (t.description = it[i].description),
              it[i].url && (t.url = it[i].url),
              it[i].inactive && (t.inactive = it[i].inactive),
              it[i].hide && (t.hide = it[i].hide)),
            (tt[e] = Object.create(t)),
            'us' != a || ('GU' != e && 'PR' != e && 'VI' != e && 'MP' != e && 'AS' != e) || (tt[e].hide = 'yes'),
            'us' != a ||
              !H ||
              ('VT' != e &&
                'NJ' != e &&
                'DE' != e &&
                'DC' != e &&
                'NH' != e &&
                'MA' != e &&
                'CT' != e &&
                'RI' != e &&
                'MD' != e) ||
              (tt[e].hide_label = 'yes'),
            d[e]))
              'default' != d[e][n] && (tt[e][n] = d[e][n]),
                'yes' == d[e][n] && (tt[e][n] = !0),
                'no' == d[e][n] && (tt[e][n] = !1);
            'off' == _.state_hover_color && (tt[e].hover_color = tt[e].color);
          }),
          (nt = {}),
          n.paths))
            nt[e] = !0;
          for (var e in i.state_specific) i.state_specific[e] && i.state_specific[e].path && (nt[e] = !0);
          for (var e in nt) lt(e);
        })(),
        (function() {
          var e = {};
          (e.font_family = _.label_font ? _.label_font : 'arial,sans-serif'),
            (e.color = _.label_color ? _.label_color : 'white'),
            (e.hover_color = _.label_hover_color ? _.label_hover_color : e.color),
            (e.opacity = _.label_opacity || '0' == _.label_opacity ? _.label_opacity : 1),
            (e.hover_opacity = _.label_hover_opacity ? _.label_hover_opacity : e.opacity),
            (e.size = Q),
            (e.hide = 'yes' == _.hide_labels),
            (e.line = !1),
            (e.scale = !!_.label_scale && _.label_scale),
            (e.scale_limit = _.scale_limit ? _.scale_limit : 0.0625),
            (e.rotate = _.label_rotate ? _.label_rotate : 0),
            (e.line_color = _.label_line_color ? _.label_line_color : '#000000'),
            (e.line_size = _.label_line_size ? _.label_line_size : '1'),
            (e.line_x = !1),
            (e.line_y = !1),
            (e.parent_type = 'state'),
            (e.parent_id = !1),
            (e.anchor = _.label_anchor ? _.label_anchor : 'middle'),
            (e.pill = !1),
            (e.width = !!_.pill_width && _.pill_width),
            (e.x = !1),
            (e.y = !1),
            (e.name = 'Not Named'),
            (e.display = !!_.label_display && _.label_display),
            (e.display_ids = !1),
            (e.id = !1);
          var t = 'no' == _.import_labels ? {} : n.default_labels,
            o = function(o) {
              for (var i in ((rt[o] = Object.create(e)), t[o]))
                'default' != t[o][i] && (rt[o][i] = t[o][i]),
                  'yes' == t[o][i] && (rt[o][i] = !0),
                  'no' == t[o][i] && (rt[o][i] = !1);
            },
            i = function(t) {
              for (var o in (rt[t] || (rt[t] = Object.create(e)), q[t]))
                'default' != q[t][o] && (rt[t][o] = q[t][o]),
                  'yes' == q[t][o] && (rt[t][o] = !0),
                  'no' == q[t][o] && (rt[t][o] = !1);
            };
          for (var a in t) o(a);
          for (var a in q) i(a);
          ct = function(e) {
            o(e), i(e);
          };
        })(),
        (function() {
          var e = {},
            t = me ? (_.scale_limit_mobile ? _.scale_limit_mobile : 0.4) : 0.0625;
          (e.scale_limit = _.scale_limit ? _.scale_limit : t),
            (e.color = _.location_color ? _.location_color : '#FF0067'),
            (e.hover_color = !!_.location_hover_color && _.location_hover_color),
            (e.border = _.location_border ? _.location_border : 1.5),
            (e.border_color = _.location_border_color ? _.location_border_color : '#FFFFFF'),
            (e.hover_border = _.location_hover_border ? _.location_hover_border : 2),
            (e.size = _.location_size),
            (e.description = _.location_description),
            (e.description_mobile = !!_.location_description_mobile && location_description_mobile),
            (e.url = _.location_url),
            (e.inactive = 'yes' == _.all_locations_inactive),
            (e.type = _.location_type),
            (e.position = 'top'),
            (e.pulse = 'yes' == _.location_pulse),
            (e.pulse_size = _.location_pulse_size ? _.location_pulse_size : 4),
            (e.pulse_speed = _.location_pulse_speed ? _.location_pulse_speed : 0.5);
          var o = _.location_pulse_color;
          for (var i in ((e.pulse_color = !(!o || 'auto' == o) && o),
          (e.image_source = _.location_image_source ? _.location_image_source : ''),
          (e.hide = _.all_locations_hide ? _.all_locations_hide : 'no'),
          (e.opacity = U),
          (e.scale = !_.location_scale || _.location_scale),
          (e.hover_opacity = !!_.location_hover_opacity && _.location_hover_opacity),
          (e.image_source = _.location_image_source ? _.location_image_source : ''),
          (e.image_url = !!_.location_image_url && _.location_image_url),
          (e.image_position = _.location_image_position ? _.location_image_position : 'center'),
          (e.image_hover_source = _.location_image_hover_source ? _.location_image_hover_source : ''),
          (e.image_hover_url = !!_.location_image_hover_url && _.location_image_hover_url),
          (e.image_hover_position = _.location_image_hover_position ? _.location_image_hover_position : 'center'),
          (e.popup = _.location_popups ? _.location_popups : ue),
          (e.x = !1),
          (e.y = !1),
          (e.display = _.location_display ? _.location_display : 'all'),
          (e.display_ids = !1),
          (e.hide = 'yes' == _.all_locations_hidden),
          (e.hide_name = 'yes' == _.location_hide_name),
          null == e.type && (e.type = 'square'),
          st)) {
            for (var n in ((ot[i] = Object.create(e)), st[i]))
              'overwrite_image_location' != n
                ? ('region' == n && (ot[i].display = 'region'),
                  'default' != st[i][n] && (ot[i][n] = st[i][n]),
                  'yes' == st[i][n] && (ot[i][n] = !0),
                  'no' == st[i][n] && (ot[i][n] = !1))
                : (ot[i].image_url = st[i][n]);
            ot[i].hover_opacity || (ot[i].hover_opacity = ot[i].opacity),
              ot[i].hover_color || (ot[i].hover_color = ot[i].color);
          }
        })(),
        (function() {
          var e = {};
          (e.color = _.line_color ? _.line_color : '#000000'),
            (e.size = _.line_size ? _.line_size : 1.5),
            (e.dash = _.line_dash ? _.line_dash : ''),
            (e.origin_location = !1),
            (e.destination_location = !1),
            (e.angle = !1);
          var t = i.lines ? i.lines : i.borders;
          for (var o in t)
            for (var n in ((mt[o] = Object.create(e)), t[o]))
              'default' != t[o][n] && (mt[o][n] = t[o][n]),
                'yes' == t[o][n] && (mt[o][n] = !0),
                'no' == t[o][n] && (mt[o][n] = !1);
        })();
    }
    function bo(e, t) {
      if ('lambert' == n.proj)
        var i = function(e) {
          var t = 0.017453293,
            o = Math.PI,
            i = e.lat * t,
            n = e.lng * t,
            a = 33 * t,
            r =
              Math.log(Math.cos(a) * (1 / Math.cos(0.785398185))) /
              Math.log(Math.tan(0.25 * o + 0.3926990925) * (1 / Math.tan(0.25 * o + 0.5 * a))),
            s = (Math.cos(a) * Math.pow(Math.tan(0.25 * o + 0.5 * a), r)) / r,
            l = s * Math.pow(1 / Math.tan(0.25 * o + 0.5 * i), r),
            c = s * Math.pow(1 / Math.tan(0.25 * o + 0.3926990925), r);
          return {
            x: l * Math.sin(r * (n - 1.57079637)),
            y: c - l * Math.cos(r * (n - 1.57079637)),
          };
        };
      else if ('xy' == n.proj)
        i = function(e) {
          return { x: e.lng, y: e.lat };
        };
      else if ('robinson_pacific' == n.proj)
        i = function(e) {
          var t = e.lng - 150;
          t < -180 && (t += 360);
          return r({ lat: e.lat, lng: t });
        };
      else if ('mercator' == n.proj)
        i = function(e) {
          var t = Math.log(Math.tan((e.lat / 90 + 1) * (Math.PI / 4))) * (180 / Math.PI);
          return { x: e.lng, y: t };
        };
      else i = r;
      var a = { lat: e, lng: t };
      function r(e) {
        var t,
          o,
          i = function(e) {
            return e < 0 ? -1 : 1;
          },
          n = i(e.lng),
          a = i(e.lat),
          r = Math.abs(e.lng),
          s = Math.abs(e.lat),
          l = ((t = 5), (o = s - 1e-10), Math.floor(o / t) * t),
          c = (l = 0 == s ? 0 : l) / 5,
          m = (l + 5) / 5,
          u = (s - l) / 5,
          p = [
            0.8487,
            0.84751182,
            0.84479598,
            0.840213,
            0.83359314,
            0.8257851,
            0.814752,
            0.80006949,
            0.78216192,
            0.76060494,
            0.73658673,
            0.7086645,
            0.67777182,
            0.64475739,
            0.60987582,
            0.57134484,
            0.52729731,
            0.48562614,
            0.45167814,
          ],
          d = [
            0,
            0.0838426,
            0.1676852,
            0.2515278,
            0.3353704,
            0.419213,
            0.5030556,
            0.5868982,
            0.67182264,
            0.75336633,
            0.83518048,
            0.91537187,
            0.99339958,
            1.06872269,
            1.14066505,
            1.20841528,
            1.27035062,
            1.31998003,
            1.3523,
          ];
        return {
          x: ((p[m] - p[c]) * u + p[c]) * r * 0.017453293 * n * 1,
          y: ((d[m] - d[c]) * u + d[c]) * a * 1,
        };
      }
      var s = n.proj_coordinates;
      function l(e, t, n, a) {
        var r,
          s,
          l,
          c,
          m,
          u,
          p,
          d,
          _,
          h,
          f,
          v,
          y,
          g,
          b,
          x = i(e),
          w = i(t),
          k = i(n),
          z = i(a),
          M = o.distance(x, w),
          E = o.distance(x, k),
          A = o.distance(w, k) / o.distance(t, n),
          C = M / A,
          T = E / A,
          S =
            ((r = t.x),
            (s = t.y),
            (l = C),
            (c = n.x),
            (m = n.y),
            (g = c - r),
            (b = s + ((p = m - s) * (_ = (l * l - (u = T) * u + (d = Math.sqrt(p * p + g * g)) * d) / (2 * d))) / d),
            {
              opt1: {
                x: (h = r + (g * _) / d) + (v = ((f = Math.sqrt(l * l - _ * _)) / d) * -p),
                y: b + (y = g * (f / d)),
              },
              opt2: { x: h - v, y: b - y },
            }),
          O = o.distance(x, z) / A;
        return Math.abs(o.distance(S.opt1, a) - O) < Math.abs(o.distance(S.opt2, a) - O)
          ? { x: S.opt1.x, y: S.opt1.y }
          : { x: S.opt2.x, y: S.opt2.y };
      }
      function c(e, t, i) {
        var n = /lat[ ]?<[-]?(\d+[\.]?\d+)/g.exec(d);
        if (n && e > o.to_float(n[1])) return !1;
        var a = /lat[ ]?>[-]?(\d+[\.]?\d+)/g.exec(d);
        if (a && e < o.to_float(a[1])) return !1;
        var r = /lng[ ]?<[-]?(\d+[\.]?\d+)/g.exec(d);
        if (r && t > o.to_float(r[1])) return !1;
        var s = /lat[ ]?>[-]?(\d+[\.]?\d+)/g.exec(d);
        return !(s && e < o.to_float(s[1]));
      }
      var m = n.proj_rules;
      if (m)
        for (var u = 0; u < m.length; u++) {
          var p = m[u],
            d = p.condition;
          if (c(e, t, p.condition)) {
            var _ = p.points;
            return l(a, s[_[0]], s[_[1]], s[_[2]]);
          }
        }
      return l(a, s[0], s[1], s[2]);
    }
    function xo(e) {
      if (e.sm.zooming_dimensions) return e.sm.zooming_dimensions;
      var t,
        i = o.rotate_bbox(e.sm.bbox, Pe),
        n = i.x,
        a = i.y,
        r = i.width,
        s = i.height,
        l = e.sm.zp,
        c = ke * we,
        m = ze * we;
      return (
        (n -= 0.5 * (r / l - r)),
        (a -= 0.5 * (s / l - s)),
        (r /= l) / (s /= l) > Ce
          ? ((a -= (m * (t = r / c) - s) / 2), (s = r / Ce))
          : ((n -= (c * (t = s / m) - r) / 2), (r = s * Ce)),
        { x: n, y: a, w: r, h: s, r: t }
      );
    }
    function wo(e) {
      if (e) {
        He.stop();
        for (var t = 0; t < e.sm.states.length; ++t) {
          var o = ht[e.sm.states[t]];
          o.attr(o.sm.attributes), Qo(o, 'reset', !1, 'state');
        }
      }
    }
    function ko(e) {
      if (e) {
        e.stop(), e.attr(e.sm.attributes);
        for (var t = 0; t < e.sm.states.length; ++t) {
          Qo(ht[e.sm.states[t]], 'reset', !1, 'region');
        }
      }
    }
    function zo() {
      qe.forEach(function(e) {
        -1 != e.sm.id && ko(e);
      });
    }
    function Mo(e, t) {
      var i = e.sm.display,
        a = t.sm.type;
      if ('all' == i) return !0;
      if ('out' == i && 'out' == a) return !0;
      if ('region' == i && 'region' == a)
        return (function(e, t) {
          var o = e.sm.point0;
          if (e.sm.display_ids) {
            var i = e.sm.display_ids.indexOf(t.sm.id) > -1;
            return i;
          }
          if (Raphael.isPointInsideBBox(t.sm.bbox, o.x, o.y)) return !0;
          return !1;
        })(e, t);
      if ('state' == i && 'state' == a)
        return (function(e, t) {
          var o = e.sm.point0;
          if (e.sm.display_ids) {
            var i = e.sm.display_ids.indexOf(t.sm.id) > -1;
            return i;
          }
          if (Raphael.isPointInsideBBox(t.sm.bbox, o.x, o.y)) {
            var a = n.paths[t.sm.id];
            if (Raphael.isPointInsidePath(a, o.x, o.y)) return !0;
          }
          return !1;
        })(e, t);
      var r = o.to_float(i);
      return !!(r && Ae < r);
    }
    function Eo(e, t, o) {
      var i = { transform: t };
      ie || me || o ? e.attr(i) : e.animate(i, 1e3 * S);
    }
    function Ao(e, t) {
      var i = e.sm.type;
      Ue.hide(),
      //added this to remove div with 'back' button that appears after zooming in
      // (this div prevents states from clicking because it is "above" state div and always in DOM
      (de.style.display = 'none'),
        (function(e, t) {
          We.hide(),
            We.forEach(function(o) {
              o.sm.hide ||
                (Mo(o, e) && o.show(),
                o.sm.scale && Eo(o, qo(o, (Ae > o.sm.scale_limit ? Ae : o.sm.scale_limit) * we), t));
            });
        })(e, t),
        (function(e, t) {
          for (var o in (Qe.hide(), gt)) {
            var i = gt[o];
            if (!i.sm.hide) {
              if ((Mo(i, e) && bt[i.sm.id].show(), i.sm.line)) {
                var n = Ho(i);
                i.sm.line.attr({ path: n, transform: Pe });
              }
              if (i.sm.scale) {
                var a = qo(i, (Ae > i.sm.scale_limit ? Ae : i.sm.scale_limit) * we);
                Eo(i, a, t), i.sm.pill && Eo(wt[i.sm.id], a, t);
              }
            }
          }
        })(e, t),
        o.x_in_array(i, ['state', 'region', 'out']) && zo(),
        'region' == i
          ? wo(e)
          : 'state' == i
          ? wo(ft[e.sm.region])
          : 'manual' == i &&
            qe.forEach(function(e) {
              -1 != e.sm.id && (e.sm.zooming_dimensions.r > Ae && e.sm.zoomable ? wo(e) : ko(e));
            }),
        'out' != i && 'manual' != i
          ? (He.stop(),
            De.stop(),
            He.attr({ 'fill-opacity': W }),
            De.attr({ 'fill-opacity': W }),
            e.stop(),
            e.attr({ 'fill-opacity': 1 }),
            e.sm.labels.forEach(function(e) {
              e.sm && e.sm.pill && (e.sm.pill.stop(), e.sm.pill.attr({ 'fill-opacity': 1 }));
            }),
            e.animate(
              {
                'stroke-width': e.sm.border_hover_size * (be / ke) * Ee * 1.25,
              },
              1e3 * S
            ))
          : (He.attr({ 'fill-opacity': 1 }), De.attr({ 'fill-opacity': 1 })),
        He.animate({ 'stroke-width': Z * (be / ke) * Ee * 1.25 }, 1e3 * S);
    }
    function Co(e) {
      if (w && '-1' != x && 'region' == e.sm.type) {
        if (!j) return;
        Ue.show();
        //added this to show div with 'back' button that appears after zooming in
        (de.style.display = 'block');
      } else 'state' == e.sm.type || 'region' == e.sm.type || j ? (Ue.show(), (de.style.display = 'block')) : g && 'out' != e.sm.type && (Ue.show(), (de.style.display = 'block'));
    }
    function To(e) {
      return { x: e.x, y: e.y, w: e.w, h: e.h };
    }
    function So() {
      z.sm && M && (Rt.hide(), (M = !1), z.sm && St.call(z), (re = !1));
    }
    function Oo(i, n, a) {
      if (!Dt) {
        So(),
          Kt && (Kt.stop(), (Kt = !1)),
          Jt && i != Jt && St.call(Jt),
          (z = !1),
          i,
          Rt.hide(),
          (M = !1),
          (Dt = !0),
          (i.sm.zooming_dimensions = xo(i));
        var r = To(i.sm.zooming_dimensions),
          s = To(vt.sm.zooming_dimensions);
        if (((Ae = i.sm.zooming_dimensions.r), Ao(i, n), ie || (me && !O) || n))
          (dt = r), Te.setViewBox(r.x, r.y, r.w, r.h, !1), c();
        else {
          var l = t.Tweenable ? new t.Tweenable() : new Tweenable();
          pt = l.tween({
            from: s,
            to: r,
            duration: 1e3 * S,
            easing: 'easeOutQuad',
            step: function(e) {
              !(function(e) {
                (dt = e), Te.setViewBox(e.x, e.y, e.w, e.h, !1);
              })(e);
            },
            finish: function() {
              c();
            },
          });
        }
      }
      function c() {
        Co(i),
          (vt = i),
          (Dt = !1),
          (re = !1),
          (e.zoom_level = vt.sm.type),
          (e.zoom_level_id = !!vt.sm.id && vt.sm.id),
          uo('zooming_complete', []),
          o.isFunction(a) && a();
      }
    }
    function Bo(e) {
      var t = '',
        o = {};
      for (var i in n.paths) {
        var a = n.paths[i];
        a = Raphael._pathToAbsolute(a);
        var r,
          s = Raphael.pathBBox(a);
        r = s.x2 - s.x < 10 ? 10 : 1;
        var l = Math.round(s.x * r) / r,
          c = Math.round(s.y * r) / r,
          m = Math.round(s.y2 * r) / r;
        (t += "'" + i + "':{x: " + l + ',y:' + c + ',x2:' + Math.round(s.x2 * r) / r + ',y2:' + m + '},'), (o[i] = s);
      }
      return (
        (t = t.substring(0, t.length - 1)), (t += '}'), e || console.log('The new state_bbox_array is: \n\n{' + t), o
      );
    }
    function Fo(e) {
      var t = e.sm.description,
        i = ie
          ? '<a style="line-height: 1.5" id="xpic_sm_' + ce + '">X</a>'
          : '<a id="xpic_sm_' +
            ce +
            '" href="#"><img src="data:image/svg+xml,%3Csvg%20enable-background%3D%22new%200%200%20256%20256%22%20height%3D%22256px%22%20id%3D%22Layer_1%22%20version%3D%221.1%22%20viewBox%3D%220%200%20256%20256%22%20width%3D%22256px%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cpath%20d%3D%22M137.051%2C128l75.475-75.475c2.5-2.5%2C2.5-6.551%2C0-9.051s-6.551-2.5-9.051%2C0L128%2C118.949L52.525%2C43.475%20%20c-2.5-2.5-6.551-2.5-9.051%2C0s-2.5%2C6.551%2C0%2C9.051L118.949%2C128l-75.475%2C75.475c-2.5%2C2.5-2.5%2C6.551%2C0%2C9.051%20%20c1.25%2C1.25%2C2.888%2C1.875%2C4.525%2C1.875s3.275-0.625%2C4.525-1.875L128%2C137.051l75.475%2C75.475c1.25%2C1.25%2C2.888%2C1.875%2C4.525%2C1.875%20%20s3.275-0.625%2C4.525-1.875c2.5-2.5%2C2.5-6.551%2C0-9.051L137.051%2C128z%22%2F%3E%3C%2Fsvg%3E" style="width: 100%" alt="Close" border="0" /></a>';
      i = '<div class="xmark_sm">' + i + '</div>';
      var n = e.sm.url ? e.sm.url : '',
        a = n,
        r = 'javascript:' == a.substring(0, 11),
        s = 'return (function(e){window.open("' + n + '","_blank"); return false})()',
        l = r
          ? 'return (function(){window.location.href="' + n + '"; return false;})()'
          : 'return (function(){window.top.location.href="' + n + '"; return false;})()',
        c = o.replaceAll(a, "'", '"'),
        m = X ? s : l;
      r && (m = '(function(){' + c + '})()');
      var u = e.sm.description_mobile
        ? e.sm.description_mobile
        : '<div class="tt_mobile_sm"><a tabindex="0" class="btn_simplemaps" href="' +
          n +
          '" onClick=\'' +
          m +
          "'>" +
          N +
          '</a></div>';
      e.sm.on_click || ((i = ''), (u = '')), '' != e.sm.url || e.sm.description_mobile || (u = '');
      var p = '' == t ? (p = '') : '<div class="tt_custom_sm"; />' + t + '</div>';
      return (
        '<div class="tt_sm"><div>' +
        (!!e.sm.hide_name ? '' : '<div class="tt_name_sm">' + e.sm.name + '</div>') +
        i +
        '<div style="clear: both;"></div></div>' +
        p +
        u +
        '</div></div>'
      );
    }
    function Io(e, t) {
      var o = e.sm.zooming_dimensions;
      if (o.w > t.sm.zooming_dimensions.w) return !1;
      var i = t.sm.bbox,
        n = i.x * we,
        a = i.y * we,
        r = i.x2 * we,
        s = i.y2 * we,
        l = o.x + o.w / 2,
        c = o.y + o.h / 2;
      return l > n && c > a && l < r && c < s;
    }
    function Po(e, t) {
      var i = t.hover ? '_hover' : '',
        a = ce + '_pattern_' + e.sm.id + i,
        r = document.getElementById(a);
      r && o.delete_element(r);
      var s = _e.firstChild,
        l = s.namespaceURI,
        c = s.querySelector('defs'),
        m = document.createElementNS(l, 'pattern'),
        u = e.sm.id;
      (m.id = a), m.setAttribute('patternUnits', 'objectBoundingBox');
      var p = document.createElementNS(l, 'image'),
        d = document.createElementNS(l, 'rect'),
        _ = t.image_color ? t.image_color : t.color;
      d.setAttribute('fill', '#ffffff'),
        d.setAttribute('opacity', t.bg_opacity),
        p.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', t.image_url),
        m.appendChild(d),
        m.appendChild(p),
        c.appendChild(m),
        s.appendChild(c);
      var h = t.image_position,
        f = 'auto' == t.image_size,
        v = 'repeat' == h,
        g = 'manual' == h,
        b = 'center' == h,
        x = Raphael.pathBBox(n.paths[u]),
        w = x.x2 - x.x,
        k = x.y2 - x.y,
        z = w / k;
      return (
        Raphael._preload(t.image_url, function() {
          var e = this.offsetWidth,
            o = this.offsetHeight,
            i = e / o;
          var n,
            a,
            r = (function() {
              var n = t.image_size;
              return (
                f
                  ? v || g
                    ? (n = i > 1 ? (e > w ? 1 : e / w) : o > k ? 1 / z : o / k / z)
                    : b && ((n = i / z), (n = i > z ? n : 1 / n))
                  : n > 1 && (n = (t.image_size * Ee) / w),
                n
              );
            })(),
            s = w * r,
            l = s / i,
            c = 0,
            u = 0;
          if (
            (v
              ? ((n = r), (a = (r * z) / i))
              : g
              ? ((n = 1), (a = 1), (c = t.image_x * w), (u = t.image_y * k))
              : b && ((n = 1), (a = 1), (c = 0.5 * (w - s)), (u = 0.5 * (k - l))),
            d.setAttribute('x', 0),
            d.setAttribute('y', 0),
            d.setAttribute('width', w),
            d.setAttribute('height', k),
            d.setAttribute('fill', _),
            d.setAttribute('opacity', '1'),
            m.setAttribute('y', 0),
            m.setAttribute('x', 0),
            m.setAttribute('y', 0),
            m.setAttribute('width', n),
            m.setAttribute('height', a),
            p.setAttribute('x', c),
            p.setAttribute('y', u),
            p.setAttribute('width', s),
            y)
          ) {
            var h = c + 0.5 * s,
              x = u + 0.5 * l;
            p.setAttribute('transform', 'rotate(-' + y + ',' + h + ',' + x + ')');
          }
          p.setAttribute('height', l);
        }),
        'url("#' + m.id + '")'
      );
    }
    function Ro(e) {
      e || ({}, (ht = {})), (Yt = n.state_bbox_array);
      var t = Z * we * Ee * 1.25;
      for (var o in ((_t = function(e) {
        var o = !ht[e],
          i = tt[e],
          a = i.path ? i.path : n.paths[e],
          r = o ? Te.path(a) : ht[e];
        o && (r.sm = { id: e }), ie || r.node.setAttribute('class', 'sm_state_' + e);
        var s = {
            fill: i.color,
            opacity: i.opacity,
            stroke: i.border_color,
            cursor: 'pointer',
            'stroke-opacity': 1,
            'stroke-width': t,
            'stroke-linejoin': 'round',
          },
          l = i.border_hover_color ? i.border_hover_color : _.border_color,
          c = i.border_hover_size ? i.border_hover_size : Z,
          m = c * we * Ee * 1.25,
          u = {
            opacity: i.hover_opacity,
            fill: i.hover_color,
            stroke: l,
            'stroke-width': m,
          };
        if (((r.sm.image = !1), i.image_url && !ie)) {
          var d = Po(r, {
            hover: !1,
            image_url: i.image_url,
            image_size: i.image_size,
            image_position: i.image_position,
            image_x: i.image_x,
            image_y: i.image_y,
            image_color: i.image_color,
            bg_opacity: i.image_background_opacity,
          });
          if (((r.sm.image = !0), (s.fill = d), i.image_hover_url)) {
            d = Po(r, {
              hover: !0,
              image_url: i.image_hover_url,
              image_size: i.image_hover_size,
              image_position: i.image_hover_position,
              image_x: i.image_hover_x,
              image_y: i.image_hover_y,
              image_color: i.image_hover_color,
              bg_opacity: i.image_background_opacity,
            });
            u.fill = d;
          } else u.fill = d;
        }
        i.inactive && (s.cursor = 'default'),
          i.image_source && ((r.sm.ignore_hover = !0), (s.fill = 'url(' + p + i.image_source + ')')),
          (i.border_hover_color || i.border_hover_size) && i.emphasize
            ? (r.sm.emphasizable = !0)
            : (r.sm.emphasizable = !1),
          (r.sm.border_hover_size = c),
          r.attr(s),
          r.transform(Pe),
          (r.sm.attributes = s),
          (r.sm.over_attributes = u),
          (r.sm.description = i.description),
          (r.sm.adjacent_attributes = { 'fill-opacity': W }),
          (r.sm.hide = i.hide),
          (r.sm.hide_label = i.hide_label),
          (r.sm.hide_name = i.hide_name),
          o && (r.sm.region = !1),
          (r.sm.name = i.name ? i.name : n.names[e]),
          r.sm.name || (r.sm.name = e),
          (r.sm.url = i.url),
          (r.sm.inactive = i.inactive),
          (r.sm.on_click = _o(i.popup)),
          (r.sm.popup_off = ho(i.popup)),
          (r.sm.labels = []),
          (r.sm.zp = i.zoom_percentage),
          (r.sm.zoomable = i.zoomable),
          (r.sm.description_mobile = i.description_mobile),
          (r.sm.type = 'state'),
          (r.sm.hide_labels = i.hide_label),
          (r.sm.content = Fo(r));
        var h = Yt[e];
        h || (h = Raphael.pathBBox(n.paths[e]));
        var f = { x: h.x, x2: h.x2, y: h.y, y2: h.y2 };
        (r.sm.bbox = f),
          (r.sm.bbox.width = f.x2 - f.x),
          (r.sm.bbox.height = f.y2 - f.y),
          (r.sm.pulse_speed = i.pulse_speed),
          (r.sm.pulse_size = i.pulse_size),
          (r.sm.pulse_color = i.pulse_color ? i.pulse_color : i.border_color),
          r.sm.hide ? r.hide() : o && Oe.push(r),
          o && ((ht[e] = r), He.push(r));
      }),
      nt))
        _t(o);
      _t[-1], He.hide();
    }
    function jo() {
      Ne.attr({ fill: _.background_color, 'fill-opacity': Y, stroke: 'none' });
    }
    function No(e) {
      if ((e || (ft = {}), E))
        for (var t in E) {
          var i = it[t],
            n = E[t],
            a = e ? ft[t] : Te.set();
          if (!e) {
            if (((a.sm = {}), (a.sm.states = []), ft[t])) {
              console.log('Duplicate Regions');
              continue;
            }
            for (var r = [], s = 0; s < n.states.length; s++) {
              var l = n.states[s],
                c = ht[l];
              c
                ? c.sm.region
                  ? console.log('')
                  : ((c.sm.region = t),
                    a.sm.states.push(l),
                    (ie && c.sm.ignore_hover && (i.color || i.hover_color)) || a.push(c),
                    r.push(c.sm.bbox))
                : console.log(l + ' does not exist');
            }
            i.x && i.y && i.x2 && i.y2 && (r = [{ x: i.x, y: i.y, x2: i.x2, y2: i.y2 }]), (a.sm.bbox = o.bbox_union(r));
          }
          var m = { 'fill-opacity': i.opacity, cursor: 'pointer' },
            u = { 'fill-opacity': i.hover_opacity };
          i.color && (m.fill = i.color),
            i.hover_color && (u.fill = i.hover_color),
            i.inactive && (m.cursor = 'default'),
            (a.sm.attributes = m),
            (a.sm.name = n.name),
            (a.sm.description = i.description),
            (a.sm.description_mobile = i.description_mobile),
            (a.sm.url = i.url),
            (a.sm.labels = Te.set()),
            (a.sm.on_click = _o(i.popup)),
            (a.sm.over_attributes = u),
            (a.sm.hide_name = i.hide_name),
            (a.sm.adjacent_attributes = { 'fill-opacity': W }),
            (a.sm.zoomable = i.zoomable),
            (a.sm.popup_off = ho(i.popup)),
            (a.sm.zp = i.zoom_percentage),
            (a.sm.inactive = i.inactive),
            (a.sm.type = 'region'),
            (a.sm.id = t),
            (a.sm.content = Fo(a)),
            e || (qe.push(a), (ft[t] = a)),
            (a.sm.zooming_dimensions = xo(a));
        }
      if (!e) {
        ft[-1] = {};
        var p = ft[-1];
        (p.sm = {}), (p.sm.type = 'out'), (p.sm.zp = 1);
        var d = o.clone(Me);
        if (
          ((d.width = d.x2 - d.x),
          (d.height = d.y2 - d.y),
          (p.sm.bbox = d),
          (p.sm.zooming_dimensions = xo(p)),
          (vt = p),
          'object' == typeof x
            ? (((yt = {}).sm = { type: 'manual', zp: 1, bbox: x }),
              (yt.sm.zooming_dimensions = xo(yt)),
              (x = -1),
              (w = !1))
            : -1 == x ||
              x in ft ||
              (x in ht ? ((Vt = ht[x]), (w = !1)) : console.log('The initial_zoom is not the id of a region or state'),
              (x = -1)),
          v)
        ) {
          ft[-2] = {};
          var _ = ft[-2];
          _.sm = { type: 'manual', zp: 1 };
          var h = xo(ft[x]),
            f = h.w,
            y = h.h,
            g = h.w * (v - 1) * 0.5,
            b = h.h * (v - 1) * 0.5;
          _.sm.zooming_dimensions = {
            x: h.x - g,
            y: h.y - b,
            w: f * v,
            h: y * v,
            r: v,
          };
        }
      }
    }
    function Lo(e) {
      var t = e.getBBox(!0);
      if (ie) {
        var o = e._getBBox(!0);
        t.height = o.height;
      }
      var i = 0.5 * t.width,
        n = 0.5 * t.height,
        a = e.sm.point0;
      return {
        x: a.x - i,
        y: a.y - n,
        x2: a.x + i,
        y2: a.y + n,
        width: t.width,
        height: t.height,
      };
    }
    function Do() {
      for (var e in (o.clear_sets([Qe, Se, De]),
      (gt = {}),
      (wt = {}),
      (bt = {}),
      (rt = rt.reverse()),
      (xt = function(e) {
        var t = rt[e],
          i = !1,
          a = !1;
        if (rt.hasOwnProperty(e)) {
          var r = !gt[e],
            s = Te.set(),
            l = { x: 1 * t.x, y: 1 * t.y },
            c = {},
            m = !1,
            u = !1;
          if (
            ('state' == t.parent_type
              ? (m = ht[t.parent_id])
              : 'region' == t.parent_type
              ? (m = ft[t.parent_id])
              : 'location' == t.parent_type && (m = kt[t.parent_id]),
            !t.x && !t.y && m)
          )
            if ('location' == m.sm.type)
              (a = !0), (c.x = m.sm.x), (c.y = m.sm.y), (l = m.sm.point0), (i = !0), m.sm.auto_size && (u = !0);
            else if ('state' == m.sm.type) {
              var p = m.sm.bbox;
              if (p.cx && p.cy)
                var d = T,
                  h = S;
              else (d = (p.x + p.x2) / 2), (h = (p.y + p.y2) / 2);
              (t.x = d), (t.y = h), (c.x = d), (c.y = h), (l = { x: d, y: h });
            }
          if ((t.parent_type, m)) {
            if (('Not Named' == t.name && m && (t.name = m.sm.id), r)) {
              if (!a) {
                var f = o.rotate([t.x, t.y], Pe);
                c = { x: f[0], y: f[1] };
              }
              var v = Te.text(c.x, c.y, t.name);
              gt[e] = v;
            } else v = gt[e];
            (v.sm = {}),
              (v.sm.hide = t.hide),
              m && (m.sm.hide_label || m.sm.hide) && (v.sm.hide = !0),
              (v.sm.parent = m),
              m.sm.labels.push(v),
              m.sm.region && ft[m.sm.region].sm.labels.push(v);
            var y = {
                'stroke-width': 0,
                fill: t.color,
                'font-size': t.size,
                'font-weight': 'bold',
                cursor: 'pointer',
                'font-family': t.font_family,
                'text-anchor': t.anchor,
                opacity: t.opacity,
              },
              g = { fill: t.hover_color, opacity: t.hover_opacity },
              b = { fill: t.color, opacity: t.opacity };
            if (
              (m.sm.inactive && (y.cursor = 'default'),
              v.attr(y),
              (v.sm.attributes = y),
              (v.sm.over_attributes = g),
              (v.sm.out_attributes = b),
              (v.sm.type = 'label'),
              (v.sm.id = e),
              (v.sm.scale = i || t.scale),
              (v.sm.scale_limit = t.scale_limit),
              (v.sm.x = c.x),
              (v.sm.y = c.y),
              (v.sm.point0 = l),
              (v.sm.line_x = t.line_x),
              (v.sm.line_y = t.line_y),
              (v.sm.line = !1),
              (v.sm.rotate = t.rotate),
              v.transform(qo(v, we)),
              t.display
                ? (v.sm.display = t.display)
                : 'region' == t.parent_type
                ? (v.sm.display = 'out')
                : 'location' == t.parent_type
                ? (v.sm.display = m.sm.display)
                : (v.sm.display = _.labels_display ? _.labels_display : 'all'),
              (v.sm.display_ids = !!t.display_ids && t.display_ids),
              (t.line || t.pill || u) && (v.sm.bbox = Lo(v)),
              'auto' == t.display && 'state' == t.parent_type)
            ) {
              (v.sm.display = 1e-4), v.sm.bbox || (v.sm.bbox = Lo(v));
              var x = [1.01, 0.501, 0.251, 0.1251, 0.06251, 0.031251],
                w = n.paths[m.sm.id],
                k = m.sm.bbox;
              for (var z in x) {
                var M = x[z],
                  E = Lo(v),
                  A = E.width * M * 0.5,
                  C = E.height * M * 0.5,
                  T = 0.5 * (E.x2 + E.x),
                  S = 0.5 * (E.y2 + E.y),
                  O = T + A,
                  B = ((h = S - C), S + C),
                  F = (d = T - A) > k.x && O < k.x2,
                  I = h > k.y && B < k.y2;
                if (F && I) {
                  var P = Raphael.isPointInsidePath(w, d, h),
                    R = Raphael.isPointInsidePath(w, O, h),
                    j = Raphael.isPointInsidePath(w, d, B),
                    N = Raphael.isPointInsidePath(w, O, B),
                    L = P && R && j && N,
                    D = E.width / M < ke;
                  if (L && D) {
                    v.sm.display = M;
                    break;
                  }
                }
                if (!v.sm.scale) break;
              }
            }
            if (t.line) {
              var H = Ho(v),
                q = Te.path(H),
                W = t.line_size * Ee * we * 1.25,
                Y = {
                  stroke: t.line_color,
                  cursor: 'pointer',
                  'stroke-width': W,
                };
              q.attr(Y),
                (q.sm = {}),
                (q.sm.type = 'label'),
                (v.sm.pill = !1),
                (q.sm.size = t.line_size),
                (q.sm.id = e),
                (v.sm.line = q),
                Se.push(q),
                s.push(q);
            }
            if ('state' == m.sm.type && t.pill) {
              var V = v.sm.bbox,
                Q = 1.45 * V.width,
                X = t.width ? t.width : Q,
                U = 1.15 * V.height,
                Z = ((d = v.sm.x - 0.5 * X), (h = v.sm.y - 0.5 * U), U / 5);
              if (wt[e]) var G = wt[e];
              else {
                G = Te.rect(d, h, X, U, Z);
                wt[e] = G;
              }
              if (
                (G.transform(qo(v, we)),
                (G.sm = {}),
                (G.sm.parent = m),
                (G.sm.attributes = o.clone(m.sm.attributes)),
                m.sm.image && (G.sm.attributes.fill = tt[m.sm.id].color),
                (G.sm.over_attributes = o.clone(m.sm.over_attributes)),
                m.sm.image && (G.sm.over_attributes.fill = tt[m.sm.id].hover_color),
                (G.sm.adjacent_attributes = o.clone(m.sm.adjacent_attributes)),
                G.attr(G.sm.attributes),
                o.x_in_array(v.sm.display, ['state', 'all']) && (m.sm.bbox = o.bbox_union([m.sm.bbox, v.sm.bbox])),
                o.x_in_array(v.sm.display, ['region', 'all']) && m.sm.region)
              ) {
                var J = ft[m.sm.region];
                (J.sm.bbox = o.bbox_union([J.sm.bbox, v.sm.bbox])),
                  (J.sm.zooming_dimensions = !1),
                  (J.sm.zooming_dimensions = xo(J));
              }
              (v.sm.pill = G), De.push(G), s.push(G), s.push(v);
            } else s.push(v);
            if (
              (('out' != v.sm.display && 'all' != v.sm.display) || v.sm.hide ? s.hide() : Ie.push(s),
              'location' != v.sm.parent.sm.type || v.sm.line || Be.push(s),
              Qe.push(s),
              (bt[e] = s),
              ie || v.node.setAttribute('class', 'sm_label_' + e),
              u)
            ) {
              var K = ((_.location_auto_padding ? 1 + 2 * _.location_auto_padding : 1.3) * v.sm.bbox.width) / Ee,
                $ = (oe = v.sm.parent).sm.labels,
                ee = oe.sm.shape_type;
              'triangle' == ee ? (K *= 1.3) : 'star' == ee && (K *= 2);
              var te = oe.sm.id;
              (ot[te].size = K), zt(te);
              var oe = kt[te];
              (v.sm.parent = oe), (oe.sm.labels = $), oe.sm.labels.push(v), (oe.sm.auto_size = !0);
            }
          } else console.log('The following object does not exist: ' + e);
        }
      }),
      rt))
        xt(e);
      Qe.hide();
    }
    function Ho(e) {
      var t = e.sm.bbox,
        i = t.x2 - t.x,
        n = t.y2 - t.y,
        a = e.sm.scale ? Ae : 1,
        r = 0.5 * (1 - a) * i,
        s = 0.5 * (1 - a) * n,
        l = e.sm.line_x,
        c = e.sm.line_y,
        m = !l || !c,
        u = e.sm.parent.sm.type;
      if ('location' == u && m) (l = e.sm.parent.sm.point0.x), (c = e.sm.parent.sm.point0.y);
      else if ('state' == u && m) {
        var p = e.sm.parent.sm.bbox;
        (l = 0.5 * (p.x2 + p.x)), (c = 0.5 * (p.y2 + p.y));
      }
      var d = { x: l, y: c },
        _ = [];
      _.push({ x: t.x2 - r, y: 0.5 * (t.y + t.y2) }),
        _.push({ x: t.x + r, y: 0.5 * (t.y + t.y2) }),
        _.push({ x: 0.5 * (t.x + t.x2), y: t.y + s }),
        _.push({ x: 0.5 * (t.x + t.x2), y: t.y2 - s });
      var h = {};
      for (var f in _) {
        var v = _[f],
          y = o.distance(v, d);
        (0 == f || y < h.distance) && ((h.label = v), (h.location = d), (h.distance = y));
      }
      return o.linePath(h.label.x, h.label.y, h.location.x, h.location.y);
    }
    function qo(e, t, o, i, n, a) {
      var r = void 0 === i ? e.sm.x : i,
        s = void 0 === n ? e.sm.y : n;
      return (
        void 0 === o && (o = '0,0'),
        void 0 === a && (a = e.sm.rotate),
        't ' + o + ' s' + t + ',' + t + ',' + r + ',' + s + 'r' + a
      );
    }
    function Wo(e) {
      for (var t in F) Qt[t] = F[t];
      var i = [];
      for (var t in Qt) i.push(t);
      for (var t in (o.clear_sets([We]),
      (kt = {}),
      (zt = function(e) {
        var t = 'center',
          n = ot[e];
        if ('image' != n.type)
          var a = {
              'stroke-width': n.border * we * Ee,
              stroke: n.border_color,
              fill: n.color,
              opacity: n.opacity,
              cursor: 'pointer',
            },
            r = {
              'stroke-width': n.hover_border * we * Ee,
              stroke: n.border_color,
              fill: n.hover_color,
              opacity: n.hover_opacity,
              cursor: 'pointer',
            };
        else {
          t = n.image_position;
          (a = { cursor: 'pointer' }), (r = { cursor: 'pointer' });
        }
        n.inactive && (a.cursor = 'default');
        var s = ot[e].type,
          l = n.size * Ee;
        if (n.x && n.y) ((c = {}).x = n.x), (c.y = n.y);
        else var c = bo(n.lat, n.lng);
        var m = o.rotate([c.x, c.y], Pe),
          u = { x: m[0], y: m[1] };
        if ('auto' == n.size) {
          var d = { sm: {} };
          return (
            (d.sm.display = n.display),
            (d.sm.auto_size = !0),
            (d.sm.type = 'location'),
            (d.sm.hide_label = !1),
            (d.sm.labels = []),
            (d.sm.point0 = c),
            (d.sm.x = u.x),
            (d.sm.y = u.y),
            (d.sm.shape_type = s),
            (d.sm.id = e),
            void (kt[e] = d)
          );
        }
        if ('circle' == s)
          var _ = Te.circle(u.x, u.y, 0.5 * l),
            h = {
              x: u.x - 0.5 * l * Ae,
              y: u.y - 0.5 * l * Ae,
              x2: u.x + 0.5 * l * Ae,
              y2: u.y + 0.5 * l * Ae,
            };
        else if (o.x_in_array(s, i)) {
          var f = 'S' + l + ',' + l + ',0,0 T' + u.x + ',' + u.y,
            v = Raphael.transformPath(Qt[s], f).toString() + 'Z';
          'marker' == s && (t = 'bottom-center');
          (h = Raphael.pathBBox(v)), (_ = Te.path(v));
        } else if ('image' == s) {
          var y = n.image_url ? n.image_url : p + n.image_source;
          _ = Te.image(y, 0, 0);
          (a.src = y), (_.sm = {});
          h = !1;
          if (
            (Raphael._preload(y, function() {
              var e = this.width / this.height,
                o = l,
                i = o * e,
                n = u.x - i / 2,
                a = 'bottom-center' == t ? u.y - o : u.y - o / 2;
              _.attr({ height: o, width: i, x: n, y: a }), (_.sm.bbox = { x: n, y: a, x2: n + i, y2: a + o });
            }),
            n.image_hover_url || n.image_hover_source)
          ) {
            var g = n.image_hover_url ? n.image_hover_url : p + n.image_hover_source;
            r.src = g;
          }
        } else {
          var b = l,
            x = b,
            w = u.x - x / 2,
            k = u.y - b / 2;
          (_ = Te.rect(w, k, x, b)), (h = { x: w, y: k, x2: w + x, y2: k + xe });
        }
        (_.sm = {}),
          (_.sm.image = 'image' == s),
          (_.sm.attributes = a),
          _.attr(a),
          (_.sm.original_transform = Pe),
          (_.sm.over_attributes = r),
          (_.sm.id = e),
          (_.sm.name = n.name),
          (_.sm.scale = n.scale),
          (_.sm.scale_limit = n.scale_limit),
          (_.sm.position = t),
          (_.sm.url = n.url),
          (_.sm.type = 'location'),
          (_.sm.shape_type = s),
          (_.sm.description = n.description),
          (_.sm.description_mobile = n.description_mobile),
          (_.sm.inactive = n.inactive),
          (_.sm.on_click = _o(n.popup)),
          (_.sm.popup_off = ho(n.popup)),
          (_.sm.pulse = n.pulse);
        var z = 'bottom' == n.position;
        (_.sm.underlay = z),
          (_.sm.pulse_speed = n.pulse_speed),
          (_.sm.pulse_size = n.pulse_size),
          (_.sm.pulse_color = n.pulse_color ? n.pulse_color : n.color),
          (_.sm.x = u.x),
          (_.sm.y = u.y),
          (_.sm.point0 = c),
          (_.sm.bbox = h),
          (_.sm.labels = []),
          (_.sm.size = l),
          (_.sm.hide = n.hide),
          (_.sm.hide_name = n.hide_name),
          (_.sm.display = n.display),
          (_.sm.display_ids = !!n.display_ids && n.display_ids),
          _.transform(qo(_, Ae * we)),
          ('region' == _.sm.display || 'state' == _.sm.display || n.hide) && _.hide(),
          (_.sm.content = Fo(_)),
          z ? Ve.push(_) : Ye.push(_),
          We.push(_),
          (kt[e] = _),
          ie || _.node.setAttribute('class', 'sm_location_' + e);
      }),
      st))
        zt(t);
    }
    function Yo(t) {
      var o = e.zoom_level,
        i = e.zoom_level_id,
        n = !!t.sm.region && ft[t.sm.region];
      return n
        ? 'out' == o
          ? n
          : 'region' == o
          ? i == n.sm.id
            ? t
            : n
          : 'state' == o
          ? ht[i].sm.region === n.sm.id
            ? t
            : n
          : 'manual' == o
          ? Ae > n.sm.zooming_dimensions.r || !n.sm.zoomable
            ? n
            : t
          : void 0
        : t;
    }
    function Vo(t) {
      var o = e.zoom_level,
        i = e.zoom_level_id;
      if ('state' == o) return i != t.sm.id;
      if ('region' != o) return !1;
      var n = !!t.sm.region && ft[t.sm.region];
      return !n || (i != n.sm.id && void 0);
    }
    function Qo(e, t, o, i) {
      e.sm.labels &&
        e.sm.labels.forEach(function(e) {
          if (e.sm) {
            var n = e.sm.pill;
            'over' == t
              ? (e.stop(), Xt(e, 'over'), n && Ut(n, 'over'))
              : ('reset' != t && 'out' != t) || (Xt(e, 'out'), n && (Ut(n, 'out', i), o && Ut(n, 'adjacent', i)));
          }
        });
    }
    function Xo() {
      (Ot = function() {
        this.sm.parent && Tt.call(this.sm.parent);
      }),
        (Bt = function() {
          this.sm.parent && St.call(this.sm.parent);
        }),
        (At = function(e) {
          this.sm.parent && Ct.call(this.sm.parent, e);
        }),
        (Mt = function(e, t) {
          if (e.sm.pulse || t) {
            var o = e.sm.shape_type;
            if (!('location' != e.sm.type || 'image' == o || Ae < 0.05)) {
              var i = e.clone();
              Ye.toFront(), Be.toFront();
              var n = 1 * e.sm.pulse_size,
                a = {
                  'stroke-width': 4 * e.attrs['stroke-width'],
                  'stroke-opacity': 0,
                };
              i.attr({ 'fill-opacity': 0, stroke: e.sm.pulse_color });
              var r = e.sm.scale ? Ae : 1,
                s = 0.5 * (n - 1) * e.sm.size * r * we,
                l = 'bottom-center' == e.sm.position ? qo(e, r * we * n, '0,' + s) : qo(e, r * we * n);
              (a.transform = l),
                i.animate(a, 1e3 * e.sm.pulse_speed, 'ease-out', function() {
                  i.remove();
                });
            }
          }
        }),
        (Et = function(e) {
          if (!ie || !ne)
            for (var t = Array.isArray(e) ? e : [e], o = 0; o < t.length; o++) {
              var i = ht[t[o]];
              if (!i || !i.sm || Ae < 0.05) return;
              i.sm.shape_type;
              var n = i.clone(),
                a = 1 * i.sm.pulse_size,
                r = {
                  'stroke-width': 4 * (i.attrs['stroke-width'] ? i.attrs['stroke-width'] : 1),
                  'stroke-opacity': 0,
                  'fill-opacity': 0,
                };
              n.attr({ 'fill-opacity': 0, stroke: i.sm.pulse_color });
              var s = i.sm.bbox;
              (i.sm.x = (s.x2 + s.x) / 2), (i.sm.y = (s.y2 + s.y) / 2), (i.sm.rotate = 0);
              var l = qo(i, a, '0,0');
              r.transform = Pe + l;
              i.sm.bbox;
              n.animate(r, 1e3 * i.sm.pulse_speed, 'ease-out', function() {
                n.remove();
              });
            }
        }),
        (Tt = function() {
          if ((vo(), io(), ri(), (this.id || 'set' != !this.type) && !Zt && !co)) {
            var e = Yo(this);
            if (e.sm.on_click) var t = !0;
            if (((jt = e.sm.popup_off), !(Ht || qt || Dt || (M && re)))) {
              if (Jt && !k) return !1;
              if (
                ((Jt = this),
                e &&
                  ((function(e) {
                    e.sm.labels &&
                      e.sm.labels.forEach(function(t) {
                        e.sm.inactive ? t.attr({ cursor: 'default' }) : t.attr({ cursor: 'pointer' });
                      });
                  })(e),
                  !e.sm.inactive))
              )
                if (
                  ((function(e) {
                    'state' == e.sm.type &&
                      e.sm.emphasizable &&
                      ((Zt = !0),
                      e.insertBefore(Oe),
                      setTimeout(function() {
                        Zt = !1;
                      }, 1));
                  })(e),
                  no(e),
                  t)
                ) {
                  if (!M) {
                    if ((e.stop(), ie && 'location' == e.sm.type && 'image' == e.sm.shape_type)) return;
                    e.sm.ignore_hover || (Xt(e, 'over'), Mt(e)), Qo(e, 'over');
                  }
                } else {
                  if ((Rt.show(e), e.stop(), ie && 'location' == e.sm.type && 'image' == e.sm.shape_type)) return;
                  e.sm.ignore_hover || (Xt(e, 'over'), Qo(e, 'over'), Mt(e), Et(e));
                }
            }
          }
        }),
        (St = function(e, t) {
          if (((t && 'function' == typeof t) || (t = !1), !Dt && !co && ((Jt = !1), this.id || 'set' != !this.type))) {
            var o = Yo(this);
            if (o && !o.sm.inactive)
              if ((ao(o), re)) (M && !0 !== e) || (Gt(o, t), (Kt = o));
              else {
                if ((Rt.hide(), Vo(o))) {
                  if (Dt) return !1;
                  o.sm.ignore_hover || Xt(o, 'out', !0), Xt(o, 'adjacent', !0), Qo(o, 'out', !0);
                } else {
                  if (ie && 'location' == o.sm.type && 'image' == o.sm.shape_type) return;
                  o.sm.ignore_hover || Xt(o, 'out', !0), Qo(o, 'out');
                }
                Gt(o, t);
              }
          }
        });
      var t = !1;
      (Ct = function(o) {
        if (!(Dt || Ht || qt)) {
          vo(),
            me &&
              ((t = !0),
              setTimeout(function() {
                t = !1;
              }, 500)),
            t || So();
          var i = Yo(this);
          if (!i.sm.inactive && (so(i, o), !e.ignore_clicks)) {
            if (((re = i.sm.on_click), o)) {
              if (M && 'touchend' == o.type) return;
              if (!M && 'touchstart' == o.type) return;
            }
            if (
              (re && Rt.update_pos(),
              (jt = i.sm.popup_off),
              !i.sm.zoomable || ('region' != i.sm.type && vt == i && 'out' != i.sm.type))
            )
              if (re) {
                if (
                  (z == i ||
                    !z ||
                    ((c = z), 'state' == (l = i).sm.type && 'region' == c.sm.type && c.sm.id == l.sm.region) ||
                    ('region' != i.sm.type && 'region' == e.zoom_level) ||
                    Gt(z),
                  ro(i, o),
                  o)
                ) {
                  var n = Uo(o);
                  Rt.pos(o, { l: n.x, u: n.y });
                }
                Rt.show(i),
                  (M = !0),
                  Qo(i, 'over'),
                  Mt(i),
                  (ie && 'location' == i.sm.type && 'image' == i.sm.shape_type) ||
                    i.sm.ignore_hover ||
                    i.attr(i.sm.over_attributes),
                  (z = i);
                var a = document.getElementById('xpic_sm_' + ce);
                a &&
                  (a.onclick = function() {
                    return Rt.hide(), (M = !1), z.sm && St.call(z), (re = !1), uo('close_popup', []), !1;
                  });
              } else {
                ro(i, o);
                var r = i.sm.url;
                if ('' != r && !mo) {
                  var s = 'javascript' == r.substring(0, 10);
                  return !X || s
                    ? void (s ? (window.location.href = r) : (window.top.location.href = r))
                    : (window.open(r, '_blank'), void Rt.hide());
                }
              }
            else
              lo(i, o),
                ri(),
                z && z.sm
                  ? St.call(z, !0, function() {
                      Oo(i), (z = i);
                    })
                  : (Oo(i), (z = i));
            var l, c;
          }
        }
      }),
        (Ft = function(e) {
          if ((void 0 === e && (e = !1), uo('back', []), ('out' == vt.sm.type || ('region' == vt.sm.type && w)) && j))
            window.location.href = 'javascript:' + j;
          else if (V && 'state' == vt.sm.type && vt.sm.region)
            z
              ? St.call(z, !0, function() {
                  Oo(ft[vt.sm.region]);
                })
              : Oo(ft[vt.sm.region], !1, e);
          else {
            var t = Io(vt, ft[x]),
              o = 'manual' == vt.sm.type && t ? ft[x] : ft[-1];
            z && z.sm && 'region' != z.sm.type
              ? St.call(z, !0, function() {
                  Oo(o);
                })
              : Oo(o, !1, e);
          }
        }),
        (It = function() {
          Ft();
        });
    }
    function Uo(e) {
      if (e.touches) {
        var t = e.changedTouches ? e.changedTouches[0] : e.touches[0];
        return { x: t.clientX, y: t.clientY };
      }
      var o = ne ? e.clientY + document.documentElement.scrollTop : e.pageY;
      return {
        x: ne ? e.clientX + document.documentElement.scrollLeft : e.pageX,
        y: o,
      };
    }
    function Zo() {
      var e = !1;
      function t(t) {
        if (!Dt && t.touches && t.touches.length > 1) {
          qt = !0;
          var i = (function(e) {
            var t = { x: e.touches[0].pageX, y: e.touches[0].pageY },
              i = { x: e.touches[1].pageX, y: e.touches[1].pageY };
            return o.distance(t, i);
          })(t);
          if (e) {
            var n = i - e;
            Math.abs(n) > 10 && (n > 0 ? Ke() : $e(), (e = i));
          } else e = i;
        }
      }
      o.addEvent(fe, 'touchstart', t),
        o.addEvent(fe, 'touchmove', t),
        o.addEvent(fe, 'touchend', function(t) {
          (e = !1),
            setTimeout(function() {
              qt = !1;
            }, 100);
        });
    }
    function Go() {
      He.toBack(), Ve.toBack(), je.toBack(), Fe && Fe.toFront(), Qe.toFront(), Ye.toFront(), Be.toFront();
    }
    function Jo(e) {
      if (!e) {
        ae
          ? (He.mouseup(Ct), je.mouseup($t), Ue.mouseup(It), je.mouseup(ri))
          : (He.hover(Tt, St), He.click(Ct), je.click($t), Ue.click(It), je.hover(ri, ri)),
          b &&
            (function() {
              function e() {
                Ko();
              }
              var t;
              (Pt = function() {
                clearTimeout(t), (t = setTimeout(e, 300));
              }),
                window.addEventListener
                  ? (window.addEventListener('resize', Pt, !1), window.addEventListener('orientationchange', Pt, !1))
                  : (window.attachEvent('resize', Pt, !1), window.attachEvent('orientationchange', Pt, !1));
              //added this to listen for mapdiv (he) size changes and forcing resize event (Pt) of map in this case
              const resizeObserver = new ResizeObserver(Pt);
              resizeObserver.observe(he);
              ie &&
                (document.body.onresize = function() {
                  e();
                });
            })(),
          g &&
            (He.touchstart(Ct),
            He.touchend(Ct),
            Ue.touchend(Ft),
            (function() {
              function e(e) {
                var o = Uo(e),
                  a = o.x,
                  r = o.y,
                  s = (i - a) * t.r,
                  l = (n - r) * t.r,
                  c = 5 * t.r;
                return (
                  (Math.abs(s) > c || Math.abs(l) > c) && (Ht = !0), { x: t.x + s, y: t.y + l, w: t.w, h: t.h, r: t.r }
                );
              }
              var t,
                i,
                n,
                a = !1;
              function r(e) {
                if (M) return !1;
                e.preventDefault ? e.preventDefault() : (e.returnValue = !1),
                  (t = {
                    x: dt.x,
                    y: dt.y,
                    w: dt.w,
                    h: dt.h,
                    r: dt.w / ke / we,
                  }),
                  (a = !0);
                var o = Uo(e);
                (i = o.x), (n = o.y), Rt.hide(), Rt.pos(e, { l: i, u: n });
              }
              function s(t) {
                if (a && !(t.touches && t.touches.length > 1)) {
                  var o = e(t);
                  Te.setViewBox(o.x, o.y, o.w, o.h);
                }
              }
              function l(t) {
                if (!a || !Ht) return (Ht = !1), void (a = !1);
                var o = e(t);
                Te.setViewBox(o.x, o.y, o.w, o.h),
                  (dt = o),
                  ((vt = { sm: {} }).sm.zooming_dimensions = dt),
                  (vt.sm.type = 'manual'),
                  (a = !1),
                  setTimeout(function() {
                    Ht = !1;
                  }, 1),
                  Ue.show();
                  //added this to show div with 'back' button that appears after zooming in
                  (de.style.display = 'block');
              }
              o.addEvent(_e, 'mousedown', r),
                o.addEvent(_e, 'mousemove', s),
                o.addEvent(_e, 'mouseup', l),
                o.addEvent(_e, 'mouseleave', l),
                o.addEvent(_e, 'touchstart', r),
                o.addEvent(_e, 'touchmove', s),
                o.addEvent(_e, 'touchend', l);
            })(),
            Zo()),
          (he.mouseIsOver = !1),
          (he.onmouseover = function() {
            this.mouseIsOver = !0;
          }),
          (he.onmouseout = function() {
            this.mouseIsOver = !1;
          }),
          (he.onwheel = function(e) {
            he.mouseIsOver && g && A && (e.preventDefault(), e.deltaY < 0 ? Ke() : $e());
          }),
          o.addEvent(document, 'keyup', function(e) {
            if ('auto' == f) {
              var t = fe.contains(document.activeElement);
              ye.style.display = t ? 'block' : 'none';
            }
          });
      }
      We.hover(Tt, St),
        We.click(Ct),
        Qe.hover(Ot, Bt),
        Qe.click(At),
        g && (We.touchend(Ct), We.touchstart(Ct), Qe.touchend(At));
    }
    function Ko() {
      if (!(he.offsetWidth < 1)) {
        fo(!0), Te.setSize(be, xe);
        var e = Z * (be / ke) * Ee * 1.25;
        He &&
          We &&
          (He.forEach(function(t) {
            t.attr({ 'stroke-width': e }),
              (t.sm.attributes['stroke-width'] = e),
              (t.sm.over_attributes['stroke-width'] = t.sm.border_hover_size * (be / ke) * Ee * 1.25);
          }),
          We.forEach(function(e) {
            'image' != ot[e.sm.id].type &&
              ((e.sm.attributes['stroke-width'] = ot[e.sm.id].border * (be / ke) * Ee * 1.25),
              (e.sm.over_attributes['stroke-width'] = ot[e.sm.id].hover_border * (be / ke) * Ee * 1.25),
              e.attr({ 'stroke-width': e.sm.attributes['stroke-width'] }));
          }),
          Se.forEach(function(e) {
            var t = e.sm.size * (be / ke) * Ee * 1.25;
            e.attr({ 'stroke-width': t });
          }),
          Fe.forEach(function(e) {
            var t = e.sm.size * (be / ke) * Ee * 1.25;
            e.attr({ 'stroke-width': t });
          })),
          vo(),
          (ut = J || (be / 2 > 250 ? be / 2 : 250));
      }
    }
    function $o(e) {
      var t = v ? ft[-2] : ft[x],
        o = ft[x];
      //added this to remove div with 'back' button that appears after zooming in
      // (this div prevents states from clicking because it is "above" state div and always in DOM
      if ((j || (Ue.hide(), (de.style.display = 'none')), !e)) {
        if (Vt) var i = Vt;
        else if (yt) i = yt;
        else i = t;
        Oo(i, (!Vt && !yt) || !v);
      }
      if (w && -1 != x) {
        //added this to remove div with 'back' button that appears after zooming in
        // (this div prevents states from clicking because it is "above" state div and always in DOM
        je.show(), j || (Ue.hide(), (de.style.display = 'none'));
        for (var n = 0; n < o.sm.states.length; n++) {
          var a = o.sm.states[n],
            r = ht[a];
          r.sm.hide || r.show();
        }
        for (var n in gt) {
          var s = gt[n],
            l = bt[n];
          s.sm.parent &&
            'state' == s.sm.parent.sm.type &&
            ((s.sm.parent.sm.region == o.sm.id && s.sm.parent.sm.region) || ((s.sm.hide = !0), l.hide()));
        }
        return (
          Fe.forEach(function(e) {
            Raphael.isPointInsideBBox(o.sm.bbox, e.sm.bbox.x, e.sm.bbox.y) && e.show();
          }),
          void (!e && v && Oo(ft[x]))
        );
      }
      je.show(), Oe.show(), Ie.show(), Fe.show(), e || !v || Vt || yt || Oo(ft[x]);
    }
    function ei(e) {
      po(),
        go(),
        Ro(!0),
        No(!0),
        Wo(),
        Do(),
        jo(),
        Ao(vt, !0),
        Go(),
        Jo(!0),
        Ko(),
        $o(!0),
        Co(vt),
        yi(),
        io(!0),
        uo('refresh_complete', []),
        o.isFunction(e) && e();
    }
    function ti(t) {
      var r, m, S, O, B, F, R, j, N, D;
      ((i = e.mapdata),
      (n = e.mapinfo),
      _e && delete window.paper,
      vi(),
      (function() {
        (d = i.state_specific), (_ = i.main_settings);
        var e = document.getElementsByTagName('script'),
          t = e[e.length - 1].src,
          o = 'no' != _.back_image && _.back_image;
        (c = 'no' != _.back_image_url && _.back_image_url),
          (u = 'default' != _.images_directory && _.images_directory),
          (p = u || t.substring(0, t.lastIndexOf('/usmap.js') + 1) + 'map_images/'),
          !c && o && (c = require('./icon-zoom-out.png'));
      })(),
      (ie = 'VML' == Raphael.type),
      (ne = !!window.document.documentMode),
      (ae = !!o.isMobile.iOS()),
      (me = !!o.isMobile.any()),
      (ue = _.pop_ups ? _.pop_ups : _.popups),
      (pe = void 0 === _.mobile_scaling && 'no' != _.mobile_scaling ? 1 : _.mobile_scaling),
      (re = !1),
      (jt = ho(ue)),
      (ce = void 0 === _.div ? 'map' : _.div),
      (x = void 0 === _.initial_zoom ? -1 : _.initial_zoom),
      (w = 'yes' == _.initial_zoom_solo && -1 != x),
      (v = void 0 !== _.fly_in_from && 'no' != _.fly_in_from && _.fly_in_from),
      (b = 'responsive' == _.width),
      '0' == (y = !!_.rotate && _.rotate) && (y = !1),
      (L = 'no' != _.zoom),
      (g = 'yes' == _.manual_zoom),
      (f = void 0 === _.keyboard_navigation ? 'auto' : _.keyboard_navigation),
      (A = 'no' != _.allow_scrolling),
      (E = !(!n.default_regions || !L) && n.default_regions),
      i.regions && (E = i.regions),
      i.labels && (q = i.labels),
      (k = !1),
      (z = !1),
      (M = !1),
      (h = !1),
      (function() {
        if ('continent' != a) return !1;
        var e = 0;
        for (var t in n.paths) e++;
        return e > 8;
      })())
        ? alert("The continent map can't be used with other data.")
        : (po(),
          (function() {
            if (
              ((he = document.getElementById(ce)),
              (fe = !!document.getElementById(ce + '_holder') && document.getElementById(ce + '_holder')))
            ) {
              he.removeChild(fe);
              var e = document.getElementById('tt_sm_' + ce);
              e && e.parentNode.removeChild(e);
            }
            (fe = document.createElement('div')),
              (de = document.createElement('div')),
              (ye = document.createElement('div')),
              (ve = document.createElement('div')),
              (_e = document.createElement('div')),
              (map_legend = document.createElement('div')),
              (de.id = ce + '_outer'),
              (ve.id = ce + '_zoom'),
              (ye.id = ce + '_access'),
              (map_legend.id = ce + '_legend'),
              (_e.id = ce + '_inner'),
              (fe.id = ce + '_holder'),
              (fe.style.position = 'relative'),
              fe.setAttribute('tabIndex', 0),
              (fe.style.outline = 'none'),
              (_e.style.position = 'relative'),
              (de.style.position = 'absolute'),
              //added this to remove div with 'back' button that appears after zooming in
              // (this div prevents states from clicking because it is "above" state div and always in DOM
              (de.style.display = 'none'),
              (ve.style.position = 'absolute'),
              (ye.style.position = 'absolute'),
              (ye.style.maxWidth = '75%'),
              (map_legend.style.position = 'absolute'),
              (ve.style.zIndex = '1'),
              (de.style.zIndex = '1'),
              (ye.style.zIndex = '1'),
              (ye.style.display = 'yes' == f ? 'block' : 'none'),
              (map_legend.style.zIndex = '1'),
              he.appendChild(fe),
              fe.appendChild(ve),
              fe.appendChild(de),
              fe.appendChild(ye),
              fe.appendChild(map_legend),
              fe.appendChild(_e);
          })(),
          fo(),
          (function() {
            if (
              ((Te = Raphael(_e, be, xe)),
              (je = Te.set()),
              (Ne = Te.rect(Me.x - 2 * ke, Me.y - 2 * ze, 5 * ke, 5 * ze)),
              C)
            ) {
              C.indexOf('/osm/noattr/') > -1 && (l = !0);
              var e = T || Me;
              (Le = Te.image(C, e.x, e.y, e.x2 - e.x, e.y2 - e.y)), je.push(Le);
            }
            je.push(Ne),
              je.transform(Re),
              je.hide(),
              (He = Te.set()),
              (Oe = Te.set()),
              (qe = Te.set()),
              (We = Te.set()),
              (Ye = Te.set()),
              (Ve = Te.set()),
              (Qe = Te.set()),
              (Be = Te.set()),
              (Ie = Te.set()),
              (Fe = Te.set()),
              (De = Te.set()),
              (Se = Te.set()),
              Te.set().push(He, We, je, Qe, Fe);
          })(),
          vo(),
          (function() {
            ((Lt = document.createElement('div')).style.cssText =
              'overflow: visible !important; clip-path: none !important; display:inline !important; opacity:1 !important; transform: none !important; visibility: visible !important; z-index: 1 !important; right: 5px !important; bottom: 5px !important; z-index: 1 !important; position: absolute !important; filter: opacity(1) !important;'),
              _e.appendChild(Lt);
            var e =
              'font: 12px Verdana, Arial, Helvetica, sans-serif !important; cursor: pointer !important; float: right !important; color: #000000 !important; text-decoration: none !important;';
            if (l) {
              var t = document.createElement('a');
              (t.href = 'https://www.openstreetmap.org/copyright'),
                (t.title = 'Background © OpenStreetMap contributors'),
                (t.innerHTML = s ? '| OSM' : '&copy; OSM'),
                (t.style.cssText = e),
                (t.style.marginLeft = '.5em'),
                Lt.appendChild(t);
            }
            if (s) {
              var o = document.createElement('a');
              (o.style.cssText = e),
                (o.href = 'https://simplemaps.com'),
                (o.title = 'Built with with SimpleMaps'),
                (o.innerHTML = '&copy; Simplemaps.com'),
                Lt.appendChild(o);
            }
          })(),
          te ||
            (function() {
              if (!Wt) {
                var e = s(['borderRadius', 'MozBorderRadius', 'WebkitBorderRadius']),
                  t = e ? e + ': ' + (me ? 2 * ee : ee) + 'px;' : '';
                ut = J || (be / 2 > 250 ? be / 2 : 250);
                var i = s(['boxShadow', 'MozBoxShadow', 'WebkitBoxShadow']),
                  n = i ? i + ': ' + 3 * $ + 'px ' + 3 * $ + 'px ' + 4 * $ + 'px rgba(0,0,0,.5);' : '';
                if (($ < 0.01 && (n = ''), me)) {
                  var a = /(\d+)(px|em)(.*)/g.exec(oe);
                  oe = parseFloat(a[1]) * pe + a[2] + a[3];
                }
                var r =
                  '.tt_mobile_sm{margin-top: .4em;} .tt_sm{' +
                  t +
                  n +
                  'z-index: 1000000; background-color: ' +
                  G +
                  '; padding: .6em; opacity:' +
                  K +
                  '; font: ' +
                  oe +
                  '; color: black;} .tt_name_sm{float: left; font-weight: bold} .tt_custom_sm{overflow: hidden;}';
                (r +=
                  '.btn_simplemaps{color: black;text-decoration: none;background: #ffffff;display: inline-block;padding: .5em .5em;margin: 0; width: 100%; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; line-height: 1.43;text-align: center;white-space: nowrap;vertical-align: middle;-ms-touch-action: manipulation;touch-action: manipulation;cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;border: 1px solid;border-radius: .3em;}    .btn_simplemaps:hover{  text-decoration: underline;}'),
                  (r +=
                    '.xmark_sm{float: ' +
                    (ie ? 'left' : 'right') +
                    '; margin-left: .5em; cursor: pointer; line-height: 0px; width: 1.3em !important;}'),
                  o.new_style(r),
                  (Wt = !0);
              }
              function s(e) {
                for (var t = document.documentElement, o = 0; o < e.length; o++)
                  if (e[o] in t.style) {
                    var i = e[o];
                    return (i = (i = (i = (i = (i = (i = i.replace('borderRadius', 'border-radius')).replace(
                      'MozBorderRadius',
                      '-moz-border-radius'
                    )).replace('WebkitBorderRadius', '-webkit-border-radius')).replace(
                      'boxShadow',
                      'box-shadow'
                    )).replace('MozBoxShadow', '-moz-box-shadow')).replace('WebkitBoxShadow', '-webkit-box-shadow'));
                  }
              }
            })(),
          (F = o.findPos(_e)),
          (R = F[0]),
          (j = F[1]),
          (N = 0),
          (D = 0),
          (Rt = {
            create: function() {
              (B = document.createElement('div')).setAttribute('id', 'tt_sm_' + ce),
                (B.style.position = 'absolute'),
                (B.style.display = 'none'),
                _e.appendChild(B),
                (_e.onmousemove = this.pos),
                (B.onmousemove = this.pos);
            },
            show: function(e) {
              jt ||
                ((h = !1),
                null == B && Rt.create(),
                (B.style.display = 'block'),
                (B.style.zIndex = 2),
                (B.style.maxWidth = ut + 'px'),
                (B.innerHTML = e.sm.content),
                Rt.update_pos(e));
            },
            reset_pos: function(e, t, o) {
              null == B && Rt.create(), Rt.set_pos(0 + t, 0 + e, o);
            },
            update_pos: function(e) {
              Rt.set_pos(r, m, e);
            },
            pos: function(e, t) {
              t
                ? ((r = t.u), (m = t.l))
                : ((r = ne ? event.clientY + document.documentElement.scrollTop : e.pageY),
                  (m = ne ? event.clientX + document.documentElement.scrollLeft : e.pageX)),
                (r -= j),
                (m -= R),
                jt || k || h || (M && re) || Rt.set_pos(r, m);
            },
            set_pos: function(e, t, o) {
              if (B) {
                var i = !e || !t;
                if ((o && o.sm.on_click && ('yes' == I || ('auto' == I && be < 401))) || i) {
                  (B.style.top = '-100px'),
                    (B.style.left = '-100px'),
                    (B.style.bottom = 'auto'),
                    (B.style.right = 'auto'),
                    (N = parseInt(B.offsetHeight, 10)),
                    (D = parseInt(B.offsetWidth, 10));
                  var n = be - D > 0 ? 0.5 * (be - D) : 0,
                    a = xe - N > 0 ? 0.5 * (xe - N) : 0;
                  (B.style.top = a + 'px'),
                    (B.style.left = n + 'px'),
                    (B.style.right = 'auto'),
                    (B.style.bottom = 'auto');
                } else {
                  if (((O = 0 + 0.5 * xe), t > (S = 0 + 0.5 * be) && e > O)) r = 4;
                  else if (t < S && e > O) r = 3;
                  else if (t > S && e < O) r = 2;
                  else var r = 1;
                  'below' == P
                    ? (3 == r && (r = 1), 4 == r && (r = 2))
                    : 'above' == P && (1 == r && (r = 3), 2 == r && (r = 4)),
                    1 == r
                      ? ((B.style.bottom = 'auto'),
                        (B.style.top = e + 5 + 'px'),
                        (B.style.left = t + 5 + 5 + 'px'),
                        (B.style.right = 'auto'))
                      : 2 == r
                      ? ((B.style.bottom = 'auto'),
                        (B.style.top = e + 5 + 'px'),
                        (B.style.right = be - t + 5 + 'px'),
                        (B.style.left = 'auto'))
                      : 3 == r
                      ? ((B.style.bottom = xe - e + 5 + 'px'),
                        (B.style.top = 'auto'),
                        (B.style.left = t + 5 + 3 + 'px'),
                        (B.style.right = 'auto'))
                      : ((B.style.bottom = xe - e + 5 + 'px'),
                        (B.style.top = 'auto'),
                        (B.style.right = be - t + 5 + 'px'),
                        (B.style.left = 'auto'));
                }
              }
            },
            hide: function() {
              null != B && (B.style.display = 'none'), (F = o.findPos(_e)) && ((R = F[0]), (j = F[1]));
            },
          }),
          yo(),
          go(),
          jo(),
          Ro(),
          No(),
          setTimeout(function() {
            Wo(),
              Do(),
              (function() {
                var e = i.lines ? i.lines : i.borders;
                if (e) {
                  for (var t in e) {
                    var o = e[t],
                      n = mt[t],
                      a = n.size * (be / ke) * Ee * 1.25,
                      r = !1;
                    if (n.origin_location && n.destination_location) {
                      var s = kt[n.origin_location].sm.point0,
                        l = kt[n.destination_location].sm.point0;
                      if (((r = 'M ' + s.x + ',' + s.y + ' ' + l.x + ',' + l.y + ' Z'), n.angle)) {
                        var c = parseFloat(n.angle);
                        if (c > -61 && c < 61) {
                          s.x <= l.x && (c *= -1);
                          var m = Raphael.transformPath(r, 'R' + c + ',' + s.x + ',' + s.y + 'S2').toString(),
                            u = Raphael.transformPath(r, 'R-90S2').toString(),
                            p = Raphael.pathIntersection(m, u)[0];
                          r =
                            'M ' +
                            s.x +
                            ',' +
                            s.y +
                            ' C' +
                            s.x +
                            ',' +
                            s.y +
                            ',' +
                            p.x +
                            ',' +
                            p.y +
                            ',' +
                            l.x +
                            ',' +
                            l.y;
                        }
                      }
                    }
                    var d = r || o.path,
                      _ = Te.path(d);
                    _.transform(Pe),
                      _.attr({
                        stroke: n.color,
                        fill: 'none',
                        cursor: 'pointer',
                        'stroke-dasharray': [n.dash],
                        'stroke-width': a,
                        'stroke-linejoin': 'round',
                        'stroke-miterlimit': 4,
                      }),
                      (_.sm = {}),
                      (_.sm.size = n.size),
                      (_.sm.bbox = _.getBBox(!0)),
                      ie || _.node.setAttribute('class', 'sm_line_' + t),
                      Fe.push(_);
                  }
                  Fe.hide();
                }
              })(),
              (function() {
                (ye.style.right = '0em'), (ye.style.top = '0em');
                var e = void 0 === _.navigate_title ? 'Navigate' : _.navigate_title,
                  t = void 0 === _.keyboard_omit ? '' : _.keyboard_omit;
                if (!(t.indexOf('navigat') > -1)) {
                  var i = document.createElement('select');
                  i.options.add(new Option(e, '-1')),
                    i.options.add(new Option('Back', 'back')),
                    g &&
                      (i.options.add(new Option('Zoom in', 'in')),
                      i.options.add(new Option('Zoom out', 'out')),
                      i.options.add(new Option('Left', 'left')),
                      i.options.add(new Option('Right', 'right')),
                      i.options.add(new Option('Up', 'up')),
                      i.options.add(new Option('Down', 'down'))),
                    (i.style.marginRight = '.5em'),
                    (i.style.marginTop = '.5em'),
                    (i.style.float = 'left'),
                    ye.appendChild(i),
                    (i.onchange = function(e) {
                      if ('-1' == this.value) return !1;
                      'back' == this.value && Ft(),
                        'out' == this.value && $e(),
                        'in' == this.value && Ke(),
                        'left' == this.value && et('-.25', 'x'),
                        'right' == this.value && et('.25', 'x'),
                        'up' == this.value && et('-.25', 'y'),
                        'down' == this.value && et('.25', 'y'),
                        setTimeout(function() {
                          i.value = '-1';
                        }, 1e3);
                    });
                }
                var n = void 0 === _.states_title ? 'States' : _.states_title,
                  a = void 0 === _.regions_title ? 'Regions' : _.regions_title,
                  r = void 0 === _.locations_title ? 'Locations' : _.locations_title,
                  s = {
                    state: { title: n, array: ht },
                    location: { title: r, array: kt },
                    region: { title: a, array: ft },
                  };
                for (var l in s) {
                  var c = s[l],
                    m = t.indexOf(l) > -1,
                    u = Object.size(c.array);
                  if (!(u < 1 || (u < 2 && 'region' == l) || m)) {
                    var p = document.createElement('select');
                    p.options.add(new Option(c.title, '-1')),
                      (p.style.marginRight = '.5em'),
                      (p.style.marginTop = '.5em'),
                      (p.style.float = 'left');
                    var d = [];
                    for (var h in c.array) d.push(c.array[h]);
                    var f = d.sort(function(e, t) {
                      e.sm.name, t.sm.name;
                    });
                    for (h = 0; h < f.length; h++) {
                      var v = f[h];
                      'out' == v.sm.type || v.sm.inactive || v.sm.hide || p.options.add(new Option(v.sm.name, v.sm.id));
                    }
                    (p.onchange = o.callback_closure({ entry: c, dropdown: p }, function(e) {
                      var t = e.dropdown,
                        o = e.entry;
                      if ('-1' == t.value) return !1;
                      var i = o.array[t.value],
                        n = i.sm.type,
                        a = function() {
                          var e = i.sm.on_click;
                          (i.sm.on_click = !0),
                            (i.sm.content = Fo(i)),
                            si(i.sm.type, i.sm.id, function() {
                              (i.sm.on_click = e), (i.sm.content = Fo(i));
                            });
                        };
                      if ('state' == n) {
                        function r() {
                          i.sm.zoomable || i.sm.region ? ii(i.sm.id, a) : a();
                        }
                        Gt(z, r);
                      } else if ('location' == n) {
                        function r() {
                          ai(i.sm.id, 4, a);
                        }
                        Gt(z, r);
                      } else if ('region' == n) {
                        function r() {
                          ni(i.sm.bbox, 1, a);
                        }
                        Gt(z, r);
                      }
                    })),
                      ye.appendChild(p);
                  }
                }
              })(),
              (function() {
                if (!(!i.legend || !i.legend.entries || i.legend.entries.length < 1 || (ne && ie))) {
                  var e = {};
                  (map_legend.style.left = '0em'),
                    (map_legend.style.bottom = '0em'),
                    (map_legend.style.padding = '.8em'),
                    (map_legend.style['background-color'] = ne ? '#ffffff' : 'rgba(186, 186, 186, 0.2)');
                  var t = document.createElement('ul');
                  (t.style.display = 'inline'),
                    (t.style['list-style-type'] = 'none'),
                    (t.style.margin = '0'),
                    (t.style.padding = '0'),
                    map_legend.appendChild(t);
                  var n =
                    '#' +
                    ce +
                    '_holder .sm_legend_item{float: left; cursor: pointer; margin-right: .75em} #' +
                    ce +
                    '_holder{font: ' +
                    oe +
                    ';}';
                  o.new_style(n);
                  for (var a = i.legend.entries, r = 0; r < a.length; r++) m(r, a[r]);
                  var s = i.legend.html;
                  s && 'no' != s
                    ? (map_legend.innerHTML = s)
                    : (function() {
                        for (var e in a) {
                          var o = a[e];
                          (o.shape = o.shape ? o.shape : 'circle'),
                            (o.color = o.color ? o.color : '#cecece'),
                            (o.type = o.type ? o.type : 'location');
                          var i = document.createElement('li');
                          i.setAttribute('class', 'sm_legend_item'), i.setAttribute('data-id', e);
                          var n = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                          if (
                            (n.setAttributeNS(
                              'http://www.w3.org/2000/xmlns/',
                              'xmlns:xlink',
                              'http://www.w3.org/1999/xlink'
                            ),
                            (n.style.display = 'inline-block'),
                            (n.style['margin-right'] = '.2em'),
                            (n.style.width = '1em'),
                            (n.style.height = '1em'),
                            (n.style.stoke = '#ffffff'),
                            (n.style['stoke-width'] = '3'),
                            (n.style.fill = '#ff0067'),
                            (n.style['vertical-align'] = '-0.15em'),
                            'circle' == o.shape)
                          )
                            (r = document.createElementNS('http://www.w3.org/2000/svg', 'circle')).setAttribute(
                              'cx',
                              '50'
                            ),
                              r.setAttribute('cy', '50'),
                              r.setAttribute('r', '40');
                          else if ('square' == o.shape)
                            (r = document.createElementNS('http://www.w3.org/2000/svg', 'rect')).setAttribute(
                              'x',
                              '10'
                            ),
                              r.setAttribute('y', '10'),
                              r.setAttribute('width', '80'),
                              r.setAttribute('height', '80');
                          else {
                            var r,
                              s = Qt[o.shape];
                            (r = document.createElementNS('http://www.w3.org/2000/svg', 'path')).setAttribute('d', s),
                              n.setAttribute('width', '1.5'),
                              n.setAttribute('height', '1.5'),
                              r.setAttribute('stroke-width', '.1');
                          }
                          ['square', 'circle'].indexOf(o.shape) > -1
                            ? (r.setAttribute('stroke-width', '10'),
                              n.setAttribute('viewBox', '0 0 100 100'),
                              n.setAttribute('width', '100'),
                              n.setAttribute('height', '100'))
                            : ['diamond', 'star'].indexOf(o.shape) > -1
                            ? (n.setAttribute('viewBox', '-.5 -.6 1 1.2'), n.setAttribute('height', '1.8'))
                            : ['triangle'].indexOf(o.shape) > -1
                            ? (n.setAttribute('viewBox', '-.6 -.7 1.2 1.1'),
                              n.setAttribute('width', '1.8'),
                              n.setAttribute('height', '1.8'))
                            : ['heart'].indexOf(o.shape) > -1
                            ? (n.setAttribute('viewBox', '-.7 -.5 1.3 1'), n.setAttribute('width', '2'))
                            : ['marker'].indexOf(o.shape) > -1 &&
                              (n.setAttribute('viewBox', '-.6 -.9 1.1 .8'),
                              n.setAttribute('width', '1.7'),
                              n.setAttribute('height', '1.7')),
                            r.setAttribute('fill', o.color),
                            r.setAttribute('stroke', 'white'),
                            n.appendChild(r),
                            i.appendChild(n);
                          var l = document.createTextNode(o.name);
                          i.appendChild(l), t.appendChild(i);
                        }
                      })();
                  var l = fe.getElementsByClassName('sm_legend_item');
                  for (r = 0; r < l.length; r++) o.addEvent(l[r], 'mouseover', c), o.addEvent(l[r], 'mousedown', c);
                }
                function c() {
                  var t = this.dataset.id,
                    o = e[t];
                  for (var i in o) {
                    var n = o[i].sm;
                    ('state' == n.type ? Et : li)(n.id);
                  }
                }
                function m(t, o) {
                  e[t] = [];
                  var i = 'state' == o.type ? ht : kt;
                  for (var n in i) {
                    var a = i[n];
                    o.ids
                      ? o.ids.split(',').indexOf(a.sm.id) > -1 && e[t].push(a)
                      : a.sm.attributes.fill != o.color || a.sm.hide || e[t].push(a);
                  }
                }
              })(),
              zo(),
              Go(),
              Rt.create(),
              Xo(),
              Jo(),
              $o(),
              yi(),
              uo('complete', []),
              o.isFunction(t) && t(),
              io();
          }, 1));
    }
    function oi(e, t) {
      Oo(ft[e], !1, t);
    }
    function ii(e, t) {
      Oo(ht[e], !1, t);
    }
    function ni(e, t, o) {
      void 0 === t && (t = 4),
        void 0 === o && (o = function() {}),
        Oo({ sm: { type: 'manual', zp: t, bbox: e } }, !1, function() {
          o(), Ue.show();
        });
    }
    function ai(e, t, o) {
      void 0 === t && (t = 4), void 0 === o && (o = function() {});
      var i = { sm: { type: 'manual', zp: t } },
        n = kt[e],
        a = n.sm.size * we * t,
        r = (a * ze) / ke,
        s = n.sm.x - 0.5 * a,
        l = n.sm.y - 0.5 * r,
        c = a / (ke * we);
      (i.sm.zooming_dimensions = { x: s, y: l, w: a, h: r, r: c }),
        Oo(i, !1, function() {
          o(), Ue.show();
        });
    }
    function ri() {
      Jt && St.call(Jt), k && ((k = !1), re || (Rt.hide(), (M = !1)));
    }
    function si(t, o, i) {
      if ((void 0 === i && (i = function() {}), 'state' == t)) var n = ht[o];
      else if ('region' == t) n = ft[o];
      else n = kt[o];
      var a = n.sm.on_click,
        r = vt.sm.zooming_dimensions;
      if ('location' != t) {
        var s = n.sm.bbox,
          l = 0.5 * (s.x + s.x2),
          c = 0.5 * (s.y + s.y2);
        (l *= we), (c *= we);
      } else (l = n.sm.x), (c = n.sm.y);
      var m = (l - r.x) / Ae,
        u = (c - r.y) / Ae,
        p = m > 1.1 * be || u > 1.1 * xe,
        d = !n.sm.region && 'region' == e.zoom_level;
      return p || d
        ? oi('-1', function() {
            si(t, o, i);
          })
        : n.sm.region && 'out' == e.zoom_level
        ? oi(n.sm.region, function() {
            si(t, o, i);
          })
        : ((k = !0), a ? Ct.call(n) : Tt.call(n), Rt.reset_pos(m, u, n), (h = !0), (k = !1), i(), !0);
    }
    function li(e) {
      var t = kt[e];
      t && Mt(t, !0);
    }
    function ci() {
      Rt.hide(), (M = !1), re ? St.call(z) : Jt && St.call(Jt);
    }
    function mi(e, t) {
      lt(e);
      var i = ht[e].sm.labels;
      _t(e);
      for (var n = 0; n < i.length; n++) {
        var a = i[n].sm.id;
        ct(a), xt(a);
      }
      o.isFunction(t) && t();
    }
    function ui() {
      (co = !0), Rt.hide();
    }
    function pi() {
      co = !1;
    }
    function di() {
      mo = !0;
    }
    function _i() {
      mo = !1;
    }
    function hi(e) {
      Ft(e);
    }
    function fi(e) {
      Et(e);
    }
    function vi() {
      (e.calibrate = Bo),
        (e.get_xy = oo),
        (e.proj = bo),
        (e.load = ti),
        (e.region_zoom = oi),
        (e.state_zoom = ii),
        (e.zoom_in = !1),
        (e.zoom_out = !1),
        (e.location_zoom = ai),
        (e.back = hi),
        (e.popup = si),
        (e.pulse = li),
        (e.pulse_state = fi),
        (e.popup_hide = ci),
        (e.zoom_level = 'out'),
        (e.ignore_clicks = !1),
        (e.zoom_level_id = !1),
        (e.disable_urls = di),
        (e.enable_urls = _i),
        (e.disable_popups = ui),
        (e.enable_popups = pi),
        (e.refresh = ei),
        (e.refresh_state = mi),
        (e.loaded = !0),
        (e.trial = r);
    }
    function yi() {
      (e.states = ht), (e.regions = ft), (e.locations = kt), (e.labels = gt), (e.tooltip = Rt);
    }
  }
  (window[e] = u),
    t.docReady(function() {
      if (
        ((function(e, t) {
          (n = u.hooks[e]) && n.apply(null, t);
          for (var o = u.plugin_hooks[e], i = 0; i < o.length; i++) {
            var n;
            (n = o[i]) && n.apply(null, t);
          }
        })('ready'),
        !window[e].loaded)
      )
        for (var t = 0; t < c.length; t++) {
          var o = c[t];
          !(!o || !o.mapdata || 'no' == o.mapdata.main_settings.auto_load) &&
            (function(e) {
              setTimeout(function() {
                e.load();
              }, 1);
            })(o);
        }
    }),
    c.push(u);
};

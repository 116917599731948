import { keys } from 'lodash';

import { CANADA_PROVINCES, USA_STATES } from '@component/simplemap/Regions';
import { LoadSearchRegion } from '@page/findLoads/RouteParams';

const USA_STATES_LOCAL = [...USA_STATES, 'AK_USA'];
const USA_STATES_GLOBAL = [...USA_STATES, 'AK'];

export const USA_REGION = {
  '0': {
    states: USA_STATES_LOCAL,
    name: LoadSearchRegion.USA,
  },
};

export const CANADA_REGION = {
  '1': {
    states: CANADA_PROVINCES,
    name: LoadSearchRegion.Canada,
  },
};

export const GLOBAL_REGION = {
  '2': {
    states: [...USA_STATES_GLOBAL, ...CANADA_PROVINCES],
    name: LoadSearchRegion.Both,
  },
};

export const getRegionIdByName = (name: LoadSearchRegion | undefined) => {
  if (name === LoadSearchRegion.Both) {
    return keys(GLOBAL_REGION)[0];
  }
  if (name === LoadSearchRegion.Canada) {
    return keys(CANADA_REGION)[0];
  }
  return keys(USA_REGION)[0];
};

export const getRegionByName = (name: LoadSearchRegion | undefined) => {
  if (name === LoadSearchRegion.Both) {
    return GLOBAL_REGION;
  }
  if (name === LoadSearchRegion.Canada) {
    return CANADA_REGION;
  }
  return USA_REGION;
};

import { clone, concat, filter, findIndex, forEach, map } from 'lodash';
import moment from 'moment';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$, zip as zip$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api } from '@common/api';
import { CommunicationClient, SendMessageResponse } from '@common/client/CommunicationClient';
import { Document, MessagesSummary } from '@common/model';
import {
  CanMessageResponse,
  CommunicationStatus,
  Conversation,
  ConversationMessage,
  ConversationMessageResponse,
  ConversationMessageUsers,
  ConversationResponse,
  ConversationsListRequest,
  ConversationsSummary,
  ConversationUserStatuses,
  LoadWithDataForConversation,
  MessagePayload,
  MessageStatusChanged,
} from '@common/model/Conversation';
import { createAction, createApiAction, createApiActionWithFetchData, ResultResponseAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const NEW_MESSAGE_ACTION_TYPE = 'NEW_MESSAGE_ACTION_TYPE';
const UPDATE_MESSAGE_ACTION_TYPE = 'UPDATE_MESSAGE_ACTION_TYPE';
const CLEAR_SENT_MESSAGE_RESPONSE = 'CLEAR_SENT_MESSAGE_RESPONSE';
const RESET_COMMUNICATIONS_STATE = 'RESET_COMMUNICATIONS_STATE';
const SET_CONVERSATION_USER_STATUS = 'SET_CONVERSATION_USER_STATUS';
const INIT_CONVERSATIONS_BY_IDS = 'INITIATE.CONVERSATIONS_BY_IDS';
const COMPLETE_CONVERSATIONS_BY_IDS = 'COMPLETE.CONVERSATIONS_BY_IDS';

interface ConversationByIDAction extends Action {
  conversationIds: string[];
  userId: string;
}
interface SetConversationUserStatusAction extends Action {
  conversationID: string;
  userID: string;
  isOnline: boolean;
  mostRecentActivity?: string;
}

type ConversationsByIDResponseAction = ResultResponseAction<Array<Conversation | undefined>> & { userId?: string };

const getConversationLoadDetailsAction = createApiAction<string, LoadWithDataForConversation>(
  'GET_CONVERSATION_LOAD_DETAILS'
);

const conversationsSummaryAction = createApiAction<undefined, ConversationsSummary>('FETCH_CONVERSATIONS_SUMMARY');
const messagesSummaryAction = createApiAction<undefined, MessagesSummary>('FETCH_MESSAGES_SUMMARY');
const communicationStatusApiAction = createApiAction<undefined, CommunicationStatus>('FETCH_CONVERSATIONS_STATUS');
const conversationAction = createApiAction<ConversationsListRequest, ConversationResponse>('FETCH_CONVERSATIONS');
const loadConversationAction = createApiActionWithFetchData<LoadConversationsInfo, ConversationResponse>(
  'FETCH_LOAD_CONVERSATIONS'
);

const messagesAction = createApiActionWithFetchData<{ conversationID: string }, ConversationMessageResponse>(
  'FETCH_MESSAGES'
);
const userStatusApiAction = createApiActionWithFetchData<{ conversationID: string }, ConversationUserStatuses>(
  'COMMUNICATION_USER_STATUS'
);
const messageReadAction = createApiActionWithFetchData<MessageReadPayload, {}>('MESSAGE_READ');
const sendMessageApiAction = createApiActionWithFetchData<SendMessagePayload, SendMessageResponse>('SEND_MESSAGE');

const updateMessageApiAction = createApiActionWithFetchData<MessageStatusChanged, SendMessageResponse>(
  'UPDATE_MESSAGE'
);

const shouldMonitorSocketAction = createAction<{ isEnabled: boolean }>('SET_SHOULD_MONITOR_SOCKET');
const wasSocketPermanentlyDisconnectedAction = createAction<{ isEnabled: boolean }>(
  'SET_WAS_SOCKET_PERMANENTLY_DISCONNECTED'
);
const markAllMessagesAsReadAction = createApiAction<undefined, {}>('MARK_ALL_MESSAGES_AS_READ');

const updateAttachDocumentsFormAction = createAction<Partial<AttachDocumentsForm>>('UPDATE_ATTACH_DOCUMENTS_FORM');

const getCanMessageConversationAction = createApiActionWithFetchData<{ userIDs: string[] }, CanMessageResponse>(
  'CAN_MESSAGE'
);
export const getConversationLoadDetails = getConversationLoadDetailsAction.fetchAction;

interface LoadConversationsInfo {
  loadID: string;
  originUI: string;
  inquireBroker: boolean;
}

interface MessageReadPayload {
  conversationID: string;
  messageID: string;
}
interface SendMessagePayload extends MessagePayload {
  key: string;
  conversationID: string;
  userID: string;
}
interface ConversationIDAction extends Action {
  conversationID: string;
}
interface NewMessageAction extends Action {
  message: ConversationMessage;
  conversationID: string;
}

interface UpdateMessageAction extends Action {
  messageId: string;
  conversationId: string;
  users: ConversationMessageUsers[];
}

export const resetCommunicationsState = (): Action => ({
  type: RESET_COMMUNICATIONS_STATE,
});

export const onNewMessage = (conversationID: string, message: ConversationMessage): NewMessageAction => ({
  type: NEW_MESSAGE_ACTION_TYPE,
  message: message,
  conversationID: conversationID,
});

export const onMessageUpdate = (
  conversationId: string,
  messageId: string,
  users: ConversationMessageUsers[]
): UpdateMessageAction => ({
  type: UPDATE_MESSAGE_ACTION_TYPE,
  messageId: messageId,
  users: users,
  conversationId: conversationId,
});

export const getConversationsSummary = () => conversationsSummaryAction.fetchAction(undefined);
export const getMessagesSummary = () => messagesSummaryAction.fetchAction(undefined);
export const getCommunicationStatus = () => communicationStatusApiAction.fetchAction(undefined);

export const getConversationsByID = (conversationIds: string[], userId: string): ConversationByIDAction => ({
  type: INIT_CONVERSATIONS_BY_IDS,
  conversationIds: conversationIds,
  userId: userId,
});

export const getLoadConversations = loadConversationAction.fetchAction;

export const getConversationList = (unread: boolean, token?: string, limit?: number) =>
  conversationAction.fetchAction({ token: token, limit: limit, unread: unread });

export const getMessagesList = messagesAction.fetchAction;
export const sendMessage = (conversationID: string, userID: string, message: MessagePayload, clientID: string) =>
  sendMessageApiAction.fetchAction({
    ...message,
    conversationID: conversationID,
    key: `${Date.now()}_${clientID}`,
    userID: userID,
  });

export const updateMessage = (conversationId: string, messageId: string, users: ConversationMessageUsers[]) =>
  updateMessageApiAction.fetchAction({
    conversationId: conversationId,
    messageId: messageId,
    users: users,
  });
export const clearSentMessageResponse = (conversationID: string): ConversationIDAction => ({
  type: CLEAR_SENT_MESSAGE_RESPONSE,
  conversationID: conversationID,
});
export const markMessageRead = messageReadAction.fetchAction;
export const getConversationUserStatus = userStatusApiAction.fetchAction;
export const setConversationUserStatus = (
  conversationID: string,
  userID: string,
  isOnline: boolean,
  mostRecentActivity?: string
): SetConversationUserStatusAction => ({
  type: SET_CONVERSATION_USER_STATUS,
  conversationID: conversationID,
  userID: userID,
  isOnline: isOnline,
  mostRecentActivity: mostRecentActivity,
});

export const setShouldMonitorSocket = (shouldMonitor: boolean) =>
  shouldMonitorSocketAction.action({ isEnabled: shouldMonitor });

export const setWasSocketPermanentlyDisconnected = (wasPermanentlyDisconnected: boolean) =>
  wasSocketPermanentlyDisconnectedAction.action({ isEnabled: wasPermanentlyDisconnected });

export const markAllMessagesAsRead = () => markAllMessagesAsReadAction.fetchAction(undefined);

export const updateAttachDocumentsForm = updateAttachDocumentsFormAction.action;

export const resetAttachDocumentsForm = () => updateAttachDocumentsForm(createInitialAttachDocumentsForm());

export const getCanMessage = (userIDs: string[]) => getCanMessageConversationAction.fetchAction({ userIDs: userIDs });

interface UserConversationMessage extends ConversationMessage {
  didFailSending?: boolean;
  isSending?: boolean;
}

export interface ConversationMessageEntry {
  messages: UserConversationMessage[];
  isLoading: boolean;
  response?: {
    success: boolean;
  };
  isSendMessageLoading: boolean;
  isSendMessageSuccess?: boolean;
  userStatus: ConversationUserStatuses;
}

const createEmptyConversationMessageEntry = (): ConversationMessageEntry => ({
  messages: [],
  isLoading: false,
  isSendMessageLoading: false,
  userStatus: { users: [] },
});

interface LoadConversationInfo {
  isLoading: boolean;
  conversationsListIds: string[];
  hasError: boolean;
}
export interface AttachDocumentsForm {
  selectedDocuments: Document[];
  conversationID?: string;
  isAddNewDocument: boolean;
  message: string;
}

const createInitialAttachDocumentsForm = (): AttachDocumentsForm => ({
  conversationID: undefined,
  selectedDocuments: [],
  isAddNewDocument: false,
  message: '',
});

export interface CommunicationState {
  unreadConversationsCount: number;
  unreadMessagesCount: number;
  // if this param is modified, also update persist filters in RootReducer.tsx (MOB) and Store.ts (WEB)
  isCommunicationsEnabled?: boolean;
  isBrokerCommunicationEnabled?: boolean;
  conversationsListIds: string[];
  isLoadingConversations: boolean;
  didFailToLoadConversations: boolean;
  conversations: Map<string, Conversation>;
  conversationMessages: Map<string, ConversationMessageEntry>;
  pendingMessageKeys: Set<string>;
  loadConversation: Map<string, LoadConversationInfo>;
  socketMonitor: {
    // @FIXME signalR socket is now used for more than just Communication
    // this should be moved to a more neutral redux state
    shouldMonitor: boolean;
    wasSocketPermanentlyDisconnected: boolean;
  };
  unread: boolean;
  markAllMessagesAsRead: {
    isLoading: boolean;
    wereAllMarkedAsRead: boolean;
    updateTime: number;
  };
  isLastPage: boolean;
  token?: string;
  attachDocuments: AttachDocumentsForm;
  latestMessageTimestamp: number;
  latestMessageSendBy: string;
  conversationLoadDetails: {
    isLoading: boolean;
    load?: LoadWithDataForConversation;
  };
  canMessageConversation: {
    canMessage: boolean;
    isLoading: boolean;
  };
}

const initialState: CommunicationState = {
  unreadConversationsCount: 0,
  unreadMessagesCount: 0,
  conversationsListIds: [],
  isLoadingConversations: false,
  didFailToLoadConversations: false,

  conversations: new Map(),
  conversationMessages: new Map(),
  pendingMessageKeys: new Set(),
  loadConversation: new Map(),
  socketMonitor: {
    shouldMonitor: false,
    wasSocketPermanentlyDisconnected: false,
  },
  unread: false,
  markAllMessagesAsRead: {
    isLoading: false,
    wereAllMarkedAsRead: false,
    updateTime: Date.now(),
  },
  isLastPage: false,
  attachDocuments: createInitialAttachDocumentsForm(),
  latestMessageTimestamp: 0,
  latestMessageSendBy: '',
  conversationLoadDetails: {
    isLoading: false,
  },
  canMessageConversation: {
    canMessage: false,
    isLoading: false,
  },
};

export const communicationReducer = createMergedReducer(initialState, [
  conversationsSummaryAction.completeCase((state, action) => {
    if (action.response.success) {
      state.unreadConversationsCount = action.response.payload.unreadConversationsCount;
    }
  }),
  messagesSummaryAction.completeCase((state, action) => {
    if (action.response.success) {
      state.unreadMessagesCount = action.response.payload.unreadMessagesCount;
    }
  }),
  getConversationLoadDetailsAction.initiateCase((state) => {
    state.conversationLoadDetails.isLoading = true;
  }),
  getConversationLoadDetailsAction.completeCase((state, action) => {
    state.conversationLoadDetails.isLoading = false;
    if (action.response.success) {
      state.conversationLoadDetails.load = action.response.payload;
    } else {
      state.conversationLoadDetails.load = undefined;
    }
  }),

  conversationAction.initiateCase((state, action) => {
    state.isLoadingConversations = true;
    state.token = action.data.token;
  }),
  conversationAction.completeCase((state, action) => {
    state.isLoadingConversations = false;
    const conversations: string[] = [];
    if (action.response.success) {
      if (state.conversationsListIds.length > 0 && state.token) {
        forEach(action.response.payload.conversations, (conversation) => {
          conversations.push(conversation.conversationId);
          state.conversations.set(conversation.conversationId, conversation);
        });
        state.conversationsListIds = concat(state.conversationsListIds, conversations);
      } else {
        state.conversations = new Map();
        forEach(action.response.payload.conversations, (conversation) => {
          conversations.push(conversation.conversationId);
          state.conversations.set(conversation.conversationId, conversation);
        });
        state.conversationsListIds = conversations;
      }
      state.isLastPage = action.response.payload.isLastPage;
      state.token = action.response.payload.token;
      state.didFailToLoadConversations = false;
    } else {
      state.didFailToLoadConversations = true;
    }
  }),
  loadConversationAction.initiateCase((state, action) => {
    state.loadConversation.set(action.data.loadID, {
      isLoading: true,
      conversationsListIds: [],
      hasError: false,
    });
  }),
  loadConversationAction.completeCase((state, action) => {
    if (!action.fetchData) {
      return;
    }
    const conversationIds: string[] = [];
    if (action.response.success) {
      forEach(action.response.payload.conversations, (conversation) => {
        conversationIds.push(conversation.conversationId);
        state.conversations.set(conversation.conversationId, conversation);
      });
    }

    state.loadConversation.set(action.fetchData.loadID, {
      isLoading: false,
      conversationsListIds: conversationIds,
      hasError: !action.response.success,
    });
  }),
  messagesAction.initiateCase((state, action) => {
    const messageEntry: ConversationMessageEntry =
      state.conversationMessages.get(action.data.conversationID) ?? createEmptyConversationMessageEntry();
    messageEntry.isLoading = true;
    messageEntry.response = undefined;
    state.conversationMessages.set(action.data.conversationID, messageEntry);
  }),
  messagesAction.completeCase((state, action) => {
    const conversationID = action.fetchData?.conversationID;
    if (conversationID) {
      const existingMessagesEntry =
        state.conversationMessages.get(conversationID) ?? createEmptyConversationMessageEntry();
      const newMessagesEntry: ConversationMessageEntry = {
        ...existingMessagesEntry,
        isLoading: false,
        response: {
          success: action.response.success,
        },
        messages: action.response.success ? action.response.payload.messages : [],
      };
      state.conversationMessages.set(conversationID, newMessagesEntry);
    }
  }),
  sendMessageApiAction.initiateCase((state, action) => {
    const messagesEntry: ConversationMessageEntry = state.conversationMessages.get(action.data.conversationID) || {
      isLoading: true,
      isSendMessageLoading: true,
      messages: [],
      userStatus: { users: [] },
    };
    messagesEntry.isSendMessageLoading = true;
    messagesEntry.isSendMessageSuccess = undefined;
    if (messagesEntry) {
      messagesEntry.messages.unshift({
        documents: action.data.documents ?? [],
        sentAt: moment().format(),
        messageId: '',
        read: true,
        sentBy: action.data.userID,
        text: action.data.text?.trim() || '',
        isSending: true,
        clientMessageId: action.data.key,
      });
      state.conversationMessages.set(action.data.conversationID, messagesEntry);
      state.pendingMessageKeys.add(action.data.key);
    }
  }),
  sendMessageApiAction.completeCase((state, action) => {
    if (!action.fetchData) {
      return;
    }
    const messagesEntry: ConversationMessageEntry = state.conversationMessages.get(action.fetchData.conversationID) || {
      isLoading: true,
      isSendMessageLoading: true,
      messages: [],
      userStatus: { users: [] },
    };
    messagesEntry.isSendMessageLoading = false;

    if (messagesEntry) {
      const newMessages = clone(messagesEntry);
      if (action.response.success) {
        newMessages.isSendMessageSuccess = true;
        forEach(messagesEntry.messages, (message, i) => {
          if (message.clientMessageId === action.fetchData?.key) {
            if (!message.isSending) {
              //if not sending means we already received it back from the socket and info is correct.
              return false;
            }
            const updatedMessage: UserConversationMessage = { ...message, isSending: false };
            if (action.response.success) {
              updatedMessage.messageId = action.response.payload.messageId;
              //in the future, when we have a way to re-send failed messages,
              //if the temporary message was detected and Successfully sent we can
              //remove it from the buffer and break the loop
            } else {
              updatedMessage.didFailSending = true;
            }

            newMessages.messages[i] = updatedMessage;

            return false;
          }
          return true;
        });
      } else {
        newMessages.messages = filter(
          newMessages.messages,
          (message) => message.clientMessageId !== action.fetchData?.key
        );
        newMessages.isSendMessageSuccess = false;
      }

      state.conversationMessages.set(action.fetchData.conversationID, newMessages);
    }
  }),
  userStatusApiAction.completeCase((state, action) => {
    if (!action.fetchData) {
      return;
    }
    if (action.response.success) {
      const messagesEntry = state.conversationMessages.get(action.fetchData.conversationID);
      if (messagesEntry) {
        state.conversationMessages.set(action.fetchData.conversationID, {
          ...messagesEntry,
          userStatus: action.response.payload,
        });
      }
    }
  }),
  communicationStatusApiAction.completeCase((state, action) => {
    if (action.response.success) {
      state.isCommunicationsEnabled = !action.response.payload.disabled;
      state.isBrokerCommunicationEnabled = action.response.payload.brokerAcceptsLoadInquiries;
    }
  }),
  markAllMessagesAsReadAction.initiateCase((state) => {
    state.markAllMessagesAsRead.isLoading = true;
    state.markAllMessagesAsRead.wereAllMarkedAsRead = false;
  }),
  markAllMessagesAsReadAction.completeCase((state, action) => {
    state.markAllMessagesAsRead.isLoading = false;
    state.markAllMessagesAsRead.updateTime = Date.now();
    if (action.response.success) {
      state.markAllMessagesAsRead.wereAllMarkedAsRead = action.response.success;
    } else {
      state.markAllMessagesAsRead.wereAllMarkedAsRead = false;
    }
  }),
  updateAttachDocumentsFormAction.addCase((state, action) => {
    state.attachDocuments = {
      ...state.attachDocuments,
      ...action.data,
    };
  }),
  shouldMonitorSocketAction.addCase((state, action) => {
    state.socketMonitor.shouldMonitor = action.data.isEnabled;
  }),
  wasSocketPermanentlyDisconnectedAction.addCase((state, action) => {
    state.socketMonitor.wasSocketPermanentlyDisconnected = action.data.isEnabled;
  }),
  getCanMessageConversationAction.initiateCase((state) => {
    state.canMessageConversation.canMessage = false;
    state.canMessageConversation.isLoading = true;
  }),
  getCanMessageConversationAction.completeCase((state, action) => {
    state.canMessageConversation.isLoading = false;
    if (action.response.success) {
      state.canMessageConversation.canMessage = action.response.payload.canMessage;
    }
  }),
  {
    [RESET_COMMUNICATIONS_STATE]: (state) => ({
      ...initialState,
      isCommunicationsEnabled: state.isCommunicationsEnabled,
      isBrokerCommunicationEnabled: state.isBrokerCommunicationEnabled,
    }),
    [NEW_MESSAGE_ACTION_TYPE]: (state, action: NewMessageAction) => {
      const newMessages: ConversationMessageEntry = state.conversationMessages.get(action.conversationID) || {
        isLoading: false,
        isSendMessageLoading: false,
        messages: [],
        userStatus: { users: [] },
      };
      const messageKey = action.message.clientMessageId || '';
      if (state.pendingMessageKeys.has(messageKey)) {
        state.pendingMessageKeys.delete(messageKey);
        forEach(newMessages.messages, (message, i) => {
          if (message.clientMessageId === messageKey) {
            newMessages.messages[i] = action.message;
            return false;
          }
          return true;
        });
      } else {
        newMessages.messages.unshift(action.message);
      }
      state.conversationMessages.set(action.conversationID, newMessages);
      state.latestMessageTimestamp = Date.now();
      state.latestMessageSendBy = action.message.sentBy;
    },
    [UPDATE_MESSAGE_ACTION_TYPE]: (state, action: UpdateMessageAction) => {
      const newMessages: ConversationMessageEntry = state.conversationMessages.get(action.conversationId) || {
        isLoading: false,
        isSendMessageLoading: false,
        messages: [],
        userStatus: { users: [] },
      };

      const messageKey = action.messageId || '';
      forEach(newMessages.messages, (message, i) => {
        if (message.messageId === messageKey) {
          newMessages.messages[i].users = action.users;
        }
      });

      state.conversationMessages.set(action.conversationId, newMessages);
    },
    [COMPLETE_CONVERSATIONS_BY_IDS]: (state, action: ConversationsByIDResponseAction) => {
      if (action.response.success) {
        forEach(action.response.payload, (conversation) => {
          if (conversation) {
            const index = findIndex(
              state.conversationsListIds,
              (conversationID) => conversation.conversationId === conversationID
            );
            const userId = action.userId ?? '';
            if (index === -1 && !conversation.mostRecentMessageId && userId && conversation.createdBy !== userId) {
              // If another user starts a conversation but does not send a message, API will
              // not include it in calls to GET /conversations, but it will still send us an
              // update over the socket for this new "conversation". In this case, we ignore it.
              // Note that if the logged-in user starts a conversation without sending a message,
              // API will include it in conversations results, so we do not ignore in that case.
              return;
            }
            state.conversations.set(conversation.conversationId, conversation);
            if (index !== -1) {
              state.conversationsListIds.splice(index, 1);
            }
            state.conversationsListIds.unshift(conversation.conversationId);
          }
        });
      }
    },
    [CLEAR_SENT_MESSAGE_RESPONSE]: (state, action: ConversationIDAction) => {
      const messagesEntry = state.conversationMessages.get(action.conversationID);
      if (messagesEntry) {
        state.conversationMessages.set(action.conversationID, {
          ...messagesEntry,
          isSendMessageSuccess: undefined,
        });
      }
    },
    [SET_CONVERSATION_USER_STATUS]: (state, action: SetConversationUserStatusAction) => {
      const messagesEntry = state.conversationMessages.get(action.conversationID);
      if (messagesEntry) {
        const userIndex = findIndex(messagesEntry.userStatus.users, (user) => user.userId === action.userID);
        if (userIndex !== -1) {
          const updatedUsers = clone(messagesEntry.userStatus.users);
          updatedUsers[userIndex].online = action.isOnline;
          state.conversationMessages.set(action.conversationID, {
            ...messagesEntry,
            userStatus: {
              ...messagesEntry.userStatus,
              users: updatedUsers,
            },
          });
        }
      }
    },
  },
]);

const conversationsByID$ = (action$: ActionsObservable<Action>, client: CommunicationClient) =>
  action$.ofType(INIT_CONVERSATIONS_BY_IDS).pipe(
    mergeMap$((action: ConversationByIDAction) => {
      const conversationRequests = map(action.conversationIds, (conversationID) =>
        client.getConversation$(conversationID)
      );
      return zip$(...conversationRequests).pipe(
        map$((responses): ConversationsByIDResponseAction => {
          const conversations = map(responses, (response) =>
            response.result(
              (data) => data,
              () => undefined
            )
          );
          return {
            type: COMPLETE_CONVERSATIONS_BY_IDS,
            userId: action.userId,
            response: {
              success: true,
              payload: conversations,
            },
          };
        })
      );
    })
  );

export const createCommunicationsEpic$ = (api: Api) => {
  const client = new CommunicationClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      conversationsSummaryAction.createEpic$(action$, client.getConversationsSummary$),
      messagesSummaryAction.createEpic$(action$, client.getMessagesSummary$),
      conversationAction.createEpic$(action$, (data) => client.getConversations$(data)),
      loadConversationAction.createEpic$(action$, (data) =>
        client.getLoadConversations$(data.loadID, data.originUI, data.inquireBroker)
      ),
      messagesAction.createEpic$(action$, (data) => client.getMessages$(data.conversationID)),
      sendMessageApiAction.createEpic$(action$, (data) =>
        client.sendMessage$(data.conversationID, {
          text: data.text?.trim(),
          documents: data.documents,
          clientMessageId: data.key,
        })
      ),
      messageReadAction.createEpic$(action$, (data) => client.markMessageRead$(data.conversationID, data.messageID)),
      userStatusApiAction.createEpic$(action$, (data) => client.getUserStatus$(data.conversationID)),
      conversationsByID$(action$, client),
      getConversationLoadDetailsAction.createEpic$(action$, client.getConversationLoadDetails$),
      communicationStatusApiAction.createEpic$(action$, client.getCommunicationStatus$),
      markAllMessagesAsReadAction.createEpic$(action$, client.markAllMessagesAsRead$),
      getCanMessageConversationAction.createEpic$(action$, (data) => client.getCanMessage$(data.userIDs))
    );
};

import { t, T } from '@translate';

export const dayNames: string[] = [
  t(T.common_dates_dayNames_sunday),
  t(T.common_dates_dayNames_monday),
  t(T.common_dates_dayNames_tuesday),
  t(T.common_dates_dayNames_wednesday),
  t(T.common_dates_dayNames_thursday),
  t(T.common_dates_dayNames_friday),
  t(T.common_dates_dayNames_saturday),
];

export const monthNamesShort: string[] = [
  t(T.common_dates_monthNamesShort_january),
  t(T.common_dates_monthNamesShort_february),
  t(T.common_dates_monthNamesShort_march),
  t(T.common_dates_monthNamesShort_april),
  t(T.common_dates_monthNamesShort_may),
  t(T.common_dates_monthNamesShort_june),
  t(T.common_dates_monthNamesShort_july),
  t(T.common_dates_monthNamesShort_august),
  t(T.common_dates_monthNamesShort_september),
  t(T.common_dates_monthNamesShort_october),
  t(T.common_dates_monthNamesShort_november),
  t(T.common_dates_monthNamesShort_december),
];

export const monthNames: string[] = [
  t(T.common_dates_monthNames_january),
  t(T.common_dates_monthNames_february),
  t(T.common_dates_monthNames_march),
  t(T.common_dates_monthNames_april),
  t(T.common_dates_monthNames_may),
  t(T.common_dates_monthNames_june),
  t(T.common_dates_monthNames_july),
  t(T.common_dates_monthNames_august),
  t(T.common_dates_monthNames_september),
  t(T.common_dates_monthNames_october),
  t(T.common_dates_monthNames_november),
  t(T.common_dates_monthNames_december),
];

export const dayNamesShort: string[] = [
  t(T.common_dates_dayNamesShort_sunday),
  t(T.common_dates_dayNamesShort_monday),
  t(T.common_dates_dayNamesShort_tuesday),
  t(T.common_dates_dayNamesShort_wednesday),
  t(T.common_dates_dayNamesShort_thursday),
  t(T.common_dates_dayNamesShort_friday),
  t(T.common_dates_dayNamesShort_saturday),
];

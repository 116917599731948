import React, { createContext, useContext, useLayoutEffect, useMemo, useState } from 'react';

import { every, map, max, noop } from 'lodash';

import { PanelSize } from '@component/panel/PanelSize';

type PanelEntries = { [key: string]: { offset: number; layer: number; size: PanelSize; debugId: string } };

interface WindowScrollViewTracking {
  panels: PanelEntries;
  closingPanel: (uid: string, offset: number, layer: number, debugId: string) => void;
  openingPanel: (uid: string, offset: number, layer: number, size: PanelSize, debugId: string) => void;
  isHighest: (offset: number, layer: number) => boolean;
  getBackdropLayer: () => number | undefined;
  availableWidth: number;
}

export const panelTrackingContext = createContext<WindowScrollViewTracking>({
  panels: {},
  openingPanel: noop,
  closingPanel: noop,
  isHighest: () => false,
  getBackdropLayer: () => undefined,
  availableWidth: 0,
});

/** @FIXME: Temporary workaround until we get a managed Panel system (MEM-1965). File to be deleted.
 * The only purpose is to track which "panel" is currently the "active" one. This
 * track which panels are mounted and get the one with the highest layer & offset value.
 */
export const usePanelTracking = (uid: string, offset: number, layer: number, size: PanelSize, debugId: string) => {
  const windowScrollViewTracking = useContext(panelTrackingContext);

  useLayoutEffect(() => {
    windowScrollViewTracking.openingPanel(uid, offset, layer, size, debugId);
    return () => {
      windowScrollViewTracking.closingPanel(uid, offset, layer, debugId);
    };
  }, [uid, offset, layer]);

  return windowScrollViewTracking;
};

export const usePanelTrackingBackdropLayer = () => {
  const windowScrollViewTracking = useContext(panelTrackingContext);

  return windowScrollViewTracking.getBackdropLayer();
};

/** FIXME: Temporary workaround until we get a managed Panel system (MEM-1965). To be deleted.
 * The only purpose is to track which "panel" is currently the "active" one. This
 * track which panels are mounted and get the one with the highest layer & offset value.
 *
 * Note that we need a UID so panels do not collide into each other. This happens because the order of mounting/unmounting
 * components in React cannot be predetermined. Having two panels with the same offset/layer at the same time is invalid
 * but frequently occurs when transitioning (opening/closing panels).
 */
export const PanelTrackingProvider: React.FC<{ availableWidth: number }> = ({ availableWidth, children }) => {
  const [panels, setPanels] = useState<PanelEntries>({});

  const context = useMemo(
    () => ({
      panels: panels,
      availableWidth: availableWidth,
      openingPanel: (uid: string, offset: number, layer: number, size: PanelSize, debugId: string) => {
        const key = `${layer}_${offset}_${uid}`;
        setPanels((panels) => {
          const newPanels = {
            ...panels,
            [key]: { uid: uid, offset: offset, layer: layer, size: size, debugId: debugId },
          };
          return newPanels;
        });
      },
      closingPanel: (uid: string, offset: number, layer: number) => {
        const key = `${layer}_${offset}_${uid}`;
        setPanels((panels) => {
          const newPanels = { ...panels };
          delete newPanels[key];
          return newPanels;
        });
      },
      isHighest: (offset: number, layer: number) =>
        every(panels, (panel) => panel.layer < layer || (panel.layer === layer && panel.offset <= offset)),
      getBackdropLayer: () => max(map(panels, (panelEntry) => panelEntry.layer)),
    }),
    [panels, availableWidth]
  );

  return <panelTrackingContext.Provider value={context}>{children}</panelTrackingContext.Provider>;
};

import { map } from 'lodash';

import {
  BaseRule,
  LoadVisibilityRuleset,
  LoadVisibilityRulesetRequest,
  LoadVisibilityRulesetResponse,
  RuleUI,
} from '@/model';
import { convertTimeStampToDaysHoursMins, convertUtcToLocalTimestamp } from '@common/helper';
import {
  MinimumAuthorityAgeUI,
  PostLoadVisibilityRule,
  PostLoadVisibilityRulesList,
  PostLoadVisibilityRulesListUI,
  PostLoadVisibilityRuleUI,
  StartDelayUI,
} from '@common/model';

export const convertRulesetFromServer = (ruleset: LoadVisibilityRulesetResponse): LoadVisibilityRuleset => {
  return { ...ruleset, rules: map(ruleset.rules, convertRuleFromServer) };
};

const convertRuleFromServer = (rule: BaseRule): RuleUI => {
  const startDelayUI = convertStartDelayForUI(rule.startDelay);
  const minimumAuthorityAgeUI = convertAuthorityAgeForUI(rule.minimumAuthorityAge);

  return {
    preferredCarriersOnly: rule.preferredCarriersOnly,
    minimumPrimaryInsurance: rule.minimumPrimaryInsurance,
    startDelayUI: startDelayUI,
    minimumAuthorityAgeUI: minimumAuthorityAgeUI,
  };
};

export const convertRulesetForRequest = (updatedRuleset: LoadVisibilityRuleset): LoadVisibilityRulesetRequest => {
  return { ...updatedRuleset, rules: map(updatedRuleset.rules, convertRuleForRequest) };
};

const convertRuleForRequest = (ruleFromUI: RuleUI, index: number): BaseRule => {
  const minimumAuthorityAge = convertAgeToMonths(
    ruleFromUI.minimumAuthorityAgeUI?.years,
    ruleFromUI.minimumAuthorityAgeUI?.months
  );

  const minimumPrimaryInsurance =
    ruleFromUI.minimumPrimaryInsurance === 0 ? undefined : ruleFromUI.minimumPrimaryInsurance;

  let startDelay = convertStartDelayToMins(
    ruleFromUI.startDelayUI?.days,
    ruleFromUI.startDelayUI?.hours,
    ruleFromUI.startDelayUI?.mins
  );

  if (index === 0) {
    // first rule in the set should always start at the posting time
    startDelay = 0;
  } else if (startDelay === 0) {
    // set startDelay to 5 (mins) if it is set to 0 for all the rules except for the first one
    startDelay = 5;
  }

  return {
    preferredCarriersOnly: ruleFromUI.preferredCarriersOnly,
    startDelay: startDelay,
    minimumAuthorityAge: minimumAuthorityAge,
    minimumPrimaryInsurance: minimumPrimaryInsurance,
  };
};

export const convertPostLoadVisibilityRulesetFromServer = (
  ruleset: PostLoadVisibilityRulesList
): PostLoadVisibilityRulesListUI => {
  return { rules: map(ruleset.rules, convertPostLoadVisibilityRuleFromServer) };
};

const convertPostLoadVisibilityRuleFromServer = (rule: PostLoadVisibilityRule): PostLoadVisibilityRuleUI => {
  const startDelayUI = rule.startDelay ? convertStartDelayForUI(rule.startDelay) : undefined;
  const minimumAuthorityAgeUI = convertAuthorityAgeForUI(rule.minimumAuthorityAge);

  return {
    startTime: rule.startTime ? convertUtcToLocalTimestamp(rule.startTime) : undefined,
    preferredCarriersOnly: rule.preferredCarriersOnly,
    minimumPrimaryInsurance: rule.minimumPrimaryInsurance,
    startDelayUI: rule.startTime ? convertTimeStampToDaysHoursMins(rule.startTime) : startDelayUI,
    minimumAuthorityAgeUI: minimumAuthorityAgeUI,
  };
};

export const convertPostLoadVisibilityRulesetForRequest = (
  updatedRuleset: PostLoadVisibilityRulesListUI
): PostLoadVisibilityRulesList => {
  return { rules: map(updatedRuleset.rules, convertPostLoadVisibilityRuleForRequest) };
};

const convertPostLoadVisibilityRuleForRequest = (ruleFromUI: PostLoadVisibilityRuleUI): PostLoadVisibilityRule => {
  const minimumAuthorityAge = convertAgeToMonths(
    ruleFromUI.minimumAuthorityAgeUI?.years,
    ruleFromUI.minimumAuthorityAgeUI?.months
  );

  const minimumPrimaryInsurance =
    ruleFromUI.minimumPrimaryInsurance === 0 ? undefined : ruleFromUI.minimumPrimaryInsurance;

  const startDelay = convertStartDelayToMins(
    ruleFromUI.startDelayUI?.days,
    ruleFromUI.startDelayUI?.hours,
    ruleFromUI.startDelayUI?.mins
  );

  return {
    preferredCarriersOnly: ruleFromUI.preferredCarriersOnly,
    startTime: ruleFromUI.startTime,
    startDelay: ruleFromUI.startTime ? undefined : startDelay,
    minimumAuthorityAge: minimumAuthorityAge,
    minimumPrimaryInsurance: minimumPrimaryInsurance,
  };
};

const convertAgeToMonths = (years: number = 0, months: number = 0) =>
  !years && !months ? undefined : years * 12 + months;

const convertStartDelayToMins = (days: number = 0, hours: number = 0, mins: number = 0) =>
  days * 24 * 60 + hours * 60 + mins;

const convertAuthorityAgeForUI = (minimumAuthorityAge: number = 0): MinimumAuthorityAgeUI | undefined => {
  return minimumAuthorityAge
    ? {
        years: Math.floor(minimumAuthorityAge / 12),
        months: minimumAuthorityAge % 12,
      }
    : undefined;
};

const convertStartDelayForUI = (startDelayInMins: number): StartDelayUI => {
  return {
    days: Math.floor(startDelayInMins / 1440),
    hours: Math.floor((startDelayInMins % 1440) / 60),
    mins: startDelayInMins % 60,
  };
};

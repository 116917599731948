import React from 'react';

import styled from 'styled-components';

import { ThemeProps, withTheme } from '@style/WithTheme';

const StyledList = withTheme()(styled.ol`
  position: relative;
  list-style: none;
  margin: 0;
  padding-left: 15px;
  list-style-type: none;
  counter-reset: item;

  > li {
    margin-top: ${(props: ListProps) => (props.dense ? 0 : 16)}px;
    margin-bottom: 0;
    > span {
      display: block !important;
    }
  }

  > li:before {
    counter-increment: item;
    content: ${(props: ListProps) => props.bulletType};
    position: absolute;
    left: 0;
    font-size: 14px;
    color: ${(props: ListProps & ThemeProps) => props.theme.text.default.color};
  }

  li ol {
    margin-top: 6px;
  }
`);

export enum BulletType {
  Number = 'counter(item) ". "',
  Dot = "'\\25CF'",
  Alpha = 'counter(item, lower-alpha) ") "',
  None = 'none',
}

interface ListProps {
  bulletType?: BulletType;
  dense?: boolean;
}

const ThemelessCustomList: React.SFC<ListProps> = (props) => <StyledList {...props} />;

export const CustomList = withTheme()(ThemelessCustomList);

import React from 'react';

import styled from 'styled-components';

import { Button, CircularProgress } from '@material-ui/core';

import { onButtonClick } from '@component/button/CustomButton';
import { Color } from '@style/Color';
import { ElementSize, FontSize, FontWeight } from '@style/StyleConstants';

const GreenButton = styled(({ disabled, ...rest }) => <Button {...rest} disabled={disabled} />)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    background-color: ${({ disabled }) => (disabled ? Color.GRAY_DISABLED : Color.GREEN_MAIN)};
    font-weight: ${FontWeight.Bold};
    font-size: ${FontSize.Content}px;
    color: ${({ disabled }) => (disabled ? Color.GRAY_STONE : Color.WHITE)};
    &:hover {
      background-color: ${({ disabled }) => (disabled ? Color.GRAY_DISABLED : Color.GREEN_MAIN)};
    }
  }
`;

const Icon = styled.img`
  margin-right: ${(props: { isWithTitle: boolean }) => (props.isWithTitle ? '5px' : 0)};
`;

const Title = styled.span`
  text-transform: none;
`;
const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  id: string;
  title?: string;
  icon?: {
    regular: string;
    disabled?: string;
  };
  iconAccessory?: React.ReactNode;
  isLoading?: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  /**
   *  Don't use className property. It is for supporting styled components.
   */
  className?: string;
  children?: React.ReactNode;
}

export const MainButton: React.FC<Props> = (props) => {
  const isDisabled = props.isDisabled || false;
  const content = (
    <>
      {props.icon ? (
        <IconContainer>
          <Icon
            id="icon"
            src={isDisabled && props.icon.disabled ? props.icon.disabled : props.icon.regular}
            isWithTitle={!!props.title}
          />
          {props.iconAccessory}
        </IconContainer>
      ) : null}
      <Title id="title">{props.title}</Title>
    </>
  );
  return (
    <GreenButton
      style={props.style}
      className={props.className}
      id={props.id}
      disabled={isDisabled}
      onClick={onButtonClick(props.onClick, props.isLoading)}
    >
      {!props.isLoading ? content : <CircularProgress color="inherit" size={ElementSize.Icon} />}
      {props.children}
    </GreenButton>
  );
};

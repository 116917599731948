import React, { CSSProperties, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

import { displayDate } from '@common/formatter/Number';
import { getInitials, stringifyLocation, truncStringIfNeeded } from '@common/helper';
import { formatDateDDDMMdd } from '@common/helper/DateHelper';
import { Load } from '@common/model';
import { Conversation, ConversationUser } from '@common/model/Conversation';
import { fetchCompaniesProfileAvatar } from '@common/redux/epic/AvatarEpic';
import { ConversationUserBubble } from '@component/conversation/ConversationAvatar';
import { NotificationCount } from '@component/menu/menuStyles';
import { Text } from '@component/text';
import { Color } from '@style/Color';
import { FontFamily, FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { ThemeProps } from '@style/WithTheme';
import { T, t } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';
import { useSelector } from '@util/hooks';
import { MediaQueries } from '@util/MediaQueries';

const REF_MAX_CHARS_LENGTH = 13;

export const Card = styled(({ isSelected, hasNewMessages, ...rest }: CardProps) => <Button {...rest} />)`
  && {
    padding: 9px 16px;
    max-height: 146px;
    width: 340px;
    border-radius: 0;
    line-height: 20px;
    box-shadow: 0 2px 4px 0px ${(props: ThemeProps) => props.theme.palette.decorator.border2};
    background: ${(props) => (props.isSelected ? Color.ORANGE_LIGHT : Color.WHITE)};
    text-transform: none;
    font-weight: ${(props) => (props.hasNewMessages && !props.isSelected ? FontWeight.Bold : FontWeight.Regular)};
    border-bottom: ${(props) => (props.isSelected ? SELECTED_BOTTOM_BORDER : BOTTOM_BORDER)};
    margin-bottom: 8px;
    @media ${MediaQueries.lt_md} {
      width: 100%;
    }
  }
`;
const SELECTED_BOTTOM_BORDER = `2px solid ${Color.ORANGE_MAIN}`;
const BOTTOM_BORDER = `1px solid ${Color.GRAY_SMOKE}`;

const CardText = styled.span`
  font-family: ${FontFamily.Main};
  text-align: start;
`;

export const CardContentText = styled(CardText)`
  && {
    font-size: ${FontSize.ContentDetail}px;
    font-weight: normal;
    padding-left: 6px;
    padding-right: 6px;
    max-width: 260px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

interface Props {
  conversation: Conversation;
  onClick: () => void;
  numberOfNewMessages: number;
  isSelected: boolean;
}
interface CardProps extends ButtonProps {
  isSelected: boolean;
  hasNewMessages: boolean;
}
export const ConversationCard: React.FC<Props> = (props) => {
  const currentUserID = useSelector((state) => state.user.profile?.payload?.id);
  const user = props.conversation.users.filter((user) => user.userId !== currentUserID)[0];

  return (
    <Card
      theme={THEME}
      onClick={props.onClick}
      isSelected={props.isSelected}
      hasNewMessages={props.numberOfNewMessages > 0}
      id={props.conversation.conversationId}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <CardText id="title" style={{ fontSize: FontSize.Body, marginBottom: Spacing.InterElementVertical }}>
            {displayDate(props.conversation.mostRecentMessageDate)}
          </CardText>
          <NotificationCount opened={true} hasNotifications={props.numberOfNewMessages > 0} isConversationList={true}>
            <span>{props.numberOfNewMessages}</span>
          </NotificationCount>
        </div>
        <ConversationUserInfo user={user} style={{ marginBottom: Spacing.InterElementVertical }} />
        <ConversationLoadInfo
          style={{ border: `1px solid ${Color.GRAY_SMOKE}` }}
          load={props.conversation.metadata?.load}
          isSelected={props.isSelected}
          hasNewMessages={props.numberOfNewMessages > 0}
        />
      </div>
    </Card>
  );
};

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: ${Spacing.InterElementHorizontal}px;
`;
const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const ConversationUserInfo: React.FC<{ user: ConversationUser; style?: React.CSSProperties }> = (props) => {
  const initials = getInitials(props.user);
  const userAvatar = useSelector(
    (state) => state.avatar.companiesProfileAvatarMap.get(props.user?.userId ?? '')?.companiesProfileAvatar
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.user.userId && userAvatar === undefined) {
      dispatch(fetchCompaniesProfileAvatar(props.user.userId));
    }
  }, [props.user.userId]);

  return (
    <UserInfoContainer style={props.style}>
      <ConversationUserBubble initials={initials} userID={props.user.userId} />
      <NameContainer>
        <CardContentText style={{ fontSize: FontSize.Content }} id="company_name">
          {props.user.company ? props.user.company.name : ''}
        </CardContentText>
        <CardContentText
          style={{ fontSize: FontSize.ContentDetail }}
          id="user_name"
        >{`${props.user.firstName} ${props.user.lastName}`}</CardContentText>
      </NameContainer>
    </UserInfoContainer>
  );
};

const LoadInfoContainer = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: column;
  border-radius: 6px;
`;
const BottomLoadInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ConversationLoadInfo: React.FC<{
  load?: Load;
  style?: CSSProperties;
  hasNewMessages?: boolean;
  isSelected?: boolean;
}> = (props) => {
  if (!props.load) {
    return null;
  }
  const weight = props.hasNewMessages && !props.isSelected ? FontWeight.Bold : FontWeight.Regular;
  const reference = props.load.postReference
    ? truncStringIfNeeded(props.load.postReference, REF_MAX_CHARS_LENGTH)
    : NO_INFORMATION_SYMBOL;
  return (
    <LoadInfoContainer style={props.style} id={props.load.id}>
      <CardContentText id="location" style={{ fontWeight: weight }}>
        {loadLocationString(props.load)}
      </CardContentText>
      <BottomLoadInfoContainer>
        <CardContentText id="pickup" style={{ fontWeight: weight }}>{`${t(T.common_load_Pickup)}: ${
          props.load.pickupDateTime ? formatDateDDDMMdd(props.load.pickupDateTime) : NO_INFORMATION_SYMBOL
        }`}</CardContentText>
        <CardContentText id="reference_number" style={{ fontWeight: weight }}>{`${t(
          T.common_load_Ref
        )} ${reference}`}</CardContentText>
      </BottomLoadInfoContainer>
    </LoadInfoContainer>
  );
};

const loadLocationString = (load: Load) => {
  let origin = NO_INFORMATION_SYMBOL;
  if (load.originLocation) {
    origin = stringifyLocation(load.originLocation?.address).locationName;
  }
  let destination = NO_INFORMATION_SYMBOL;
  if (load.destinationLocation) {
    destination = stringifyLocation(load.destinationLocation?.address).locationName;
  }
  return t(T.common_load_origToDest, { orig: origin, dest: destination });
};

const OfflineWarnContainer = styled.div`
  border: 1px solid ${Color.GRAY_LAVA};
  border-radius: 6px;
  text-align: center;
  padding: ${Spacing.InterElementVertical}px;

  margin: ${Spacing.LargePaddingHorizontal}px ${Spacing.ScreenSide}px ${Spacing.LargePaddingHorizontal}px
    ${Spacing.ScreenSide}px;
  background-color: ${Color.WHITE};
`;
export const OfflineMessage: React.FC<{
  style?: CSSProperties;
  isOffline: boolean;
}> = (props) => {
  if (!props.isOffline) {
    return null;
  }

  return (
    <OfflineWarnContainer style={props.style}>
      <Text style={{ color: Color.LIGHT_BLACK }} id="offline_warning">
        {t(T.common_conversations_userOfflineMessage)}
      </Text>
    </OfflineWarnContainer>
  );
};

export const ContactMessagingDisabled: React.FC<{
  style?: CSSProperties;
}> = (props) => {
  return (
    <OfflineWarnContainer style={props.style}>
      <Text style={{ color: Color.LIGHT_BLACK }} id="disabled_conversation_warning">
        {t(T.common_conversations_userHasMessagingDisabled)}
      </Text>
    </OfflineWarnContainer>
  );
};

const NewConversationContainer = styled.div`
  text-align: start;
  padding: ${Spacing.InterElementVertical}px;
  margin: ${Spacing.LargePaddingHorizontal}px ${Spacing.ScreenSide}px ${Spacing.LargePaddingHorizontal}px
    ${Spacing.ScreenSide}px;
`;

const NoMarginUList = styled.ul`
  margin-block-start: 10px;
  margin-block-end: 0;
  margin-left: -10px;
`;

export const NewConversationBanner: React.FC<{ hasLoad: boolean }> = (props) => {
  return (
    <NewConversationContainer>
      {props.hasLoad ? (
        <>
          <Text id="new_conversation">{t(T.common_conversations_newConversation_mainMessage)}</Text>
          <NoMarginUList>
            <li key={'new_conversation_shared_name'}>
              <Text id="shared_name">{t(T.common_conversations_newConversation_sharedName)}</Text>
            </li>
            <li key={'new_conversation_shared_company'}>
              <Text id="shared_company">{t(T.common_conversations_newConversation_sharedCompany)}</Text>
            </li>
            <li key={'new_conversation_shared_mc'}>
              <Text id="shared_mc">{t(T.common_conversations_newConversation_sharedMC)}</Text>
            </li>
            <li key={'new_conversation_shared_phone_email'}>
              <Text id="shared_phone_email">{t(T.common_conversations_newConversation_sharedPhoneEmail)}</Text>
            </li>
          </NoMarginUList>
        </>
      ) : (
        <Text id="new_conversation">{t(T.common_conversations_newConversation_noLoadMessage)}</Text>
      )}
    </NewConversationContainer>
  );
};

import React from 'react';

import styled from 'styled-components';

import { ButtonTypes, CustomButton } from '@component/button/CustomButton';
import { t, T } from '@translate';
import { validateFileSize, verifyFormat } from '@util/FileHelper';

const HiddenFileUploadBtn = styled.input`
  && {
    display: none;
  }
`;

interface UploadButtonProps {
  id: string;
  buttonLabel: string;
  onChange: (file: FormData) => any;
  accept: string[];
  handleFileInvalid?: (message: string) => void;
  disabled?: boolean;
}

export const UploadFileButton: React.SFC<UploadButtonProps> = (props) => {
  let inputRef: HTMLInputElement;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (verifyFormat(file, props.accept)) {
        if (validateFileSize(file)) {
          const formData = new FormData();
          formData.append('file', file, file.name);
          props.onChange(formData);
        } else {
          inputRef.value = '';
          if (props.handleFileInvalid) {
            props.handleFileInvalid(t(T.dropBox_File_Too_Big));
          }
        }
      } else {
        inputRef.value = '';
        if (props.handleFileInvalid) {
          props.handleFileInvalid(t(T.dropBox_Invalid_FeedBack));
        }
      }
    }
  };

  const handleClick = () => {
    inputRef.click();
  };

  // use of Ref in isolated class because it seems inevitable when using file input
  const setRef = (ref: HTMLInputElement) => {
    inputRef = ref;
  };

  let acceptString = '';

  if (props.accept.length > 0) {
    acceptString = props.accept[0];
  }
  for (let i = 1; i < props.accept.length; i++) {
    acceptString += ', ' + props.accept[i];
  }

  return (
    <CustomButton id={props.id} disabled={props.disabled} onClick={handleClick} buttonType={ButtonTypes.PrimaryButton}>
      {props.buttonLabel}
      <HiddenFileUploadBtn
        id={props.id + '_input'}
        type="file"
        innerRef={setRef}
        accept={acceptString === '' ? undefined : acceptString}
        onChange={handleChange}
      />
    </CustomButton>
  );
};

import { AxiosRequestConfig } from 'axios';
import { find, some } from 'lodash';

import { ErrorUri } from '@common/api';
import { PendingAction, PendingActionType } from '@common/api/PendingAction';
import { getFlowIDFrom } from '@common/helper/FlowIDHeaderHelper';
import { FieldError } from '@common/model';
import { AppErrorCode } from '@common/model/AppErrorCode';
import { Response, ResponseAction } from '@common/redux/Base';

export interface ApiResult<T> {
  readonly success: boolean;
  readonly httpStatus: number;
  url?: string;
  headers?: { [key: string]: any };
  config?: AxiosRequestConfig;
  duration?: number;
  result: <R>(onSuccess: (data: T) => R, onError: (error: ApiError) => R) => R;
  resultWithoutData: <R>(onSuccess: () => R, onError: (error: ApiError) => R) => R;
  resultDataResponse: (actionType: string) => ResponseAction<T>;
}
export class ApiResponseSuccess<T> implements ApiResult<T> {
  readonly success: boolean = true;
  readonly httpStatus: number;
  data: T | undefined;
  headers: {};
  config?: AxiosRequestConfig;
  duration?: number;

  constructor(
    status: number,
    data: T | undefined,
    headers: {} = {},
    config: AxiosRequestConfig | undefined = {},
    duration: number | undefined = 0
  ) {
    this.data = data;
    this.httpStatus = status;
    this.config = config;
    this.headers = headers;
    this.duration = duration;
  }

  result = <R>(onSuccess: (data: T, headers: {}) => R, onError: (error: ApiError, headers: {}) => R): R => {
    if (!this.data) {
      const error = new ApiError(this.httpStatus);
      error.message = 'The response requires data and it was missing.';
      return onError(error, this.headers);
    } else {
      return onSuccess(this.data, this.headers);
    }
  };

  resultDataResponse = (actionType: string): ResponseAction<T> => {
    const response = this.result<Response<T>>(
      (data, headers) => ({ success: true, payload: data, headers: headers }),
      (error, headers) => ({ success: false, error: error, headers: headers })
    );

    return { type: actionType, response: response, flowID: getFlowIDFrom(this) };
  };

  resultWithoutData = <R>(onSuccess: () => R): R => {
    return onSuccess();
  };
}

export class ApiError implements ApiResult<any> {
  readonly success: boolean = false;
  readonly httpStatus: number;
  code: number;
  message: string;
  title: string;
  actions?: PendingAction[];
  cause?: any;
  wasCancelled: boolean;
  headers?: {};
  config?: AxiosRequestConfig;
  duration?: number;
  fieldErrors?: FieldError[];
  error?: string;
  errorDescription?: string;
  error_uri?: ErrorUri;

  constructor(
    httpStatus: number = -1,
    code: number = -1,
    message: string = '',
    title: string = '',
    cause?: any,
    headers: {} | undefined = {},
    config: AxiosRequestConfig | undefined = {},
    duration: number | undefined = 0,
    error_uri?: ErrorUri,
    fieldErrors?: FieldError[],
    error?: '',
    errorDescription?: ''
  ) {
    this.httpStatus = httpStatus;
    this.message = message;
    this.title = title;
    this.code = code;
    this.cause = cause;
    this.wasCancelled = code === AppErrorCode.CANCEL_ERROR;
    this.headers = headers;
    this.config = config;
    this.duration = duration;
    this.fieldErrors = fieldErrors;
    this.error_uri = error_uri;
    this.error = error;
    this.errorDescription = errorDescription;
  }

  result = <R>(onSuccess: (data: any) => R, onError: (error: ApiError) => R): R => {
    return onError(this);
  };
  resultWithoutData = <R>(onSuccess: () => R, onError: (error: ApiError) => R): R => {
    return onError(this);
  };
  resultDataResponse = (actionType: string): ResponseAction<any> => {
    return { type: actionType, response: { success: false, error: this }, flowID: getFlowIDFrom(this) };
  };
}

export type ApiResponse123<T> = (ApiError | ApiResponseSuccess<T>) & ApiResult<T>; // we need  & ApiResult<T> or typescript wont allow to call

export const is401 = <T>(error: ApiResult<T>) => error.httpStatus === 401;

export const is4xxError = (status: number) => status >= 400 && status <= 499;

export const isNetworkError = <T>(response: ApiResponse123<T>) => {
  if (response.httpStatus <= 0) {
    return true;
  }
  const errorCode = (response as ApiError).code;
  if (!errorCode) {
    return false;
  }
  return some(
    [AppErrorCode.TIMEOUT_ERROR, AppErrorCode.CONNECTION_ERROR, AppErrorCode.UNKNOWN_ERROR],
    (code) => errorCode === code
  );
};

export const isRefreshTokenPendingAction = <T>(result: ApiResponse123<T>): boolean =>
  result.httpStatus === 403 &&
  find((result as ApiError).actions, (action: PendingAction) => action.name === PendingActionType.refreshToken) !==
    undefined;

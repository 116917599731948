import {
  cloneDeep,
  concat,
  defaultTo,
  filter,
  find,
  findIndex,
  forEach,
  map,
  reduce,
  sum,
  toUpper,
  uniqBy,
} from 'lodash';
import { Action } from 'redux';

import {
  addFetchedTime,
  metadataWithLimit,
  SetSelectedSearchAction,
  similarLoadsFrom,
  updateLoadsList,
  withLimit,
  withoutNextToken,
} from '@common/helper';
import { SearchFilter } from '@common/helper/FilterHelper';
import {
  isLoadNew,
  LoadUpdate,
  UpdateCategory,
  updateLoad,
  UpdateLoadAction,
  updateLoadActionCreator,
  updateSimilarLoadsList,
} from '@common/helper/LoadHelper';
import { didSortByChange, IKeysSortByLoads, keySortByToLoadSearchSort, KeysSortBy } from '@common/helper/SortByKeys';
import {
  INITIAL_SEARCH_LIMIT,
  Load,
  LoadSearchArchiving,
  LoadSearchMetadata,
  LoadSearchRequest,
  LoadSearchResponse as LoadSearchResponseModel,
  LoadSearchSort,
  LoadStatus,
  OriginLocation,
  PostedLoadSearchResponse as PostedLoadSearchResponseModel,
  RateCheckPreviewStatus,
  SEARCH_LIMIT_APPENDING,
  SearchMetadataType,
  SimilarLoadSearchResponse,
} from '@common/model';
import { MAX_LOAD_SEARCH_TRUCK_WEIGHT, MAX_PLUS_TRUCK_WEIGHT, UserTruck } from '@common/model/Truck';
import { ResponseAction, ResultResponse, ResultResponseAction, SearchResponseMetadata } from '@common/redux/Base';
import {
  FetchRateCheckPreviewAction,
  processRateCheckPreviewResponse,
  RateCheckPreviewResponseAction,
} from '@common/redux/epic/rateCheck/RateCheckPreviewHelper';
import { createMergedReducer } from '@common/redux/ReduxHelper';
import { mergeLoadLists } from '@common/sort/LoadsMerger';

import { initialState, LoadSearchBaseState, SearchState } from './HelperFunctions';

interface SetSortByAction extends Action {
  sortBy: IKeysSortByLoads;
}

interface UpdateOriginLocationAction extends Action {
  location: OriginLocation;
}

interface UpdateTruckAction extends Action {
  truck: Partial<UserTruck>;
}

export interface LoadCountAction extends Action {
  request: LoadSearchRequest;
}

export interface RemoveBlockedLoadAction extends Action {
  loadID: string;
}

export type AutoRefreshResponseAction = ResponseAction<LoadSearchResponseModel> & { sentNextToken?: string };

export type SimilarLoadsResponseAction = ResultResponseAction<SimilarLoadSearchResponse> & LoadSearchArchiving;
export interface FetchLoadResponseAction extends ResultResponseAction<LoadSearchResponseModel | undefined> {
  searchRequest?: LoadSearchRequest;
}

export interface SearchLoadsAction extends Action {
  searchData?: LoadSearchRequest;
  cancelGroup?: string;
  originatedAppLocation: string;
}

export interface SearchLoadsByIdAction extends Action {
  id: string;
  metadata: LoadSearchMetadata;
}

interface NextSearchLoadsAction extends Action {
  limit?: number;
}

interface ViewLoadAction extends Action {
  loadID: string;
}

export interface SetSearchFilterAction extends Action {
  searchFilter: SearchFilter;
}

export type LoadCountResponseAction = ResponseAction<SearchResponseMetadata>;

export const createLoadSearchReducer = (reducerKey: string) => {
  const actionKey = toUpper(reducerKey);
  //Reducer Actions
  const actionTypes = {
    SET_SORT_BY: `${actionKey}.SET_SORT_BY`,
    UPDATE_ORIGIN_LOCATION: `${actionKey}.UPDATE_ORIGIN_LOCATION`,
    FETCH_LOADS: `${actionKey}.FETCH_LOADS`,
    LOADS_FETCHED: `${actionKey}.LOADS_FETCHED`,
    FETCH_LOADS_BY_ID: `${actionKey}.FETCH_LOADS_BY_ID`,
    SHOW_SIMILAR_LOADS: `${actionKey}.SHOW_SIMILAR_LOADS`,
    SIMILAR_LOADS_FETCHED: `${actionKey}.SIMILAR_LOADS_FETCHED`,
    GET_NEXT_SEARCH_LOADS: `${actionKey}.GET_NEXT_SEARCH_LOADS`,
    LAST_RESULT: `${actionKey}.LAST_RESULT`,
    FETCH_LOAD_COUNT: `${actionKey}.FETCH_LOAD_COUNT`,
    LOAD_COUNT_FETCHED: `${actionKey}.LOAD_COUNT_FETCHED`,
    RESET_LOAD_COUNT: `${actionKey}.RESET_LOAD_COUNT`,
    UPDATE_TRUCK: `${actionKey}.UPDATE_TRUCK`,
    UPDATE_LOAD: `${actionKey}.UPDATE_LOAD`,
    REFRESH_LOAD_COUNT: `${actionKey}.REFRESH_LOAD_COUNT`,
    LOAD_COUNT_REFRESHED: `${actionKey}.LOAD_COUNT_REFRESHED`,
    AUTO_REFRESH_LOADS: `${actionKey}.AUTO_REFRESH_LOADS`,
    AUTO_REFRESH_LOADS_COMPLETE: `${actionKey}.LOADS_AUTO_REFRESHED`,
    REMOVE_PENDING_BLOCKED: `${actionKey}.REMOVE_PENDING_BLOCKED`,
    TOGGLE_COUNTING_SEARCHES: `${actionKey}.TOGGLE_COUNTING_SEARCHES`,
    SET_SELECTED_SEARCH: `${actionKey}.SET_SELECTED_SEARCH`,
    SET_SELECTED_BACKHAUL: `${actionKey}.SET_SELECTED_BACKHAUL`,
    SET_ACTIVE_SEARCH: `${actionKey}.SET_ACTIVE_SEARCH`,
    VIEW_LOAD: `${actionKey}.VIEW_LOAD`,
    CLEAR_LOAD_SEARCH_STATE: `${actionKey}.CLEAR_LOAD_SEARCH_STATE`,
    SET_SEARCH_FILTER_DRAFT: `${actionKey}.SET_SEARCH_FILTER_DRAFT`,
    FETCH_RATECHECK_PREVIEW: `${actionKey}.FETCH_RATECHECK_PREVIEW`,
    RATECHECK_PREVIEW_COMPLETE: `${actionKey}.RATECHECK_PREVIEW_COMPLETE`,
  };
  const actions = {
    toggleCountingSearches: (): Action => ({ type: actionTypes.TOGGLE_COUNTING_SEARCHES }),
    setSortBy: (sortBy: IKeysSortByLoads): SetSortByAction => ({
      type: actionTypes.SET_SORT_BY,
      sortBy: sortBy,
    }),
    updateOriginLocation: (location: OriginLocation): UpdateOriginLocationAction => ({
      type: actionTypes.UPDATE_ORIGIN_LOCATION,
      location: location,
    }),
    updateLoadSearchTruck: (truck: Partial<UserTruck>): UpdateTruckAction => ({
      type: actionTypes.UPDATE_TRUCK,
      truck: truck,
    }),
    showSimilarLoads: (): Action => ({
      type: actionTypes.SHOW_SIMILAR_LOADS,
    }),
    fetchLoadCount: (searchData: LoadSearchRequest): LoadCountAction => ({
      type: actionTypes.FETCH_LOAD_COUNT,
      request: searchData,
    }),
    resetLoadCount: (): Action => ({
      type: actionTypes.RESET_LOAD_COUNT,
    }),
    refreshLoadCount: (searchData: LoadSearchRequest): LoadCountAction => ({
      type: actionTypes.REFRESH_LOAD_COUNT,
      request: searchData,
    }),
    performAutoRefresh: (): Action => ({
      type: actionTypes.AUTO_REFRESH_LOADS,
    }),
    searchLoads: (
      searchData?: LoadSearchRequest,
      cancelGroup?: string,
      originatedAppLocation = '',
      limit: number = INITIAL_SEARCH_LIMIT
    ): SearchLoadsAction => ({
      type: actionTypes.FETCH_LOADS,
      searchData: searchData && withoutNextToken(withLimit(searchData, limit)),
      cancelGroup: cancelGroup,
      originatedAppLocation: originatedAppLocation,
    }),
    searchLoadsById: (
      id: string,
      metadata: LoadSearchMetadata,
      limit: number = INITIAL_SEARCH_LIMIT
    ): SearchLoadsByIdAction => ({
      type: actionTypes.FETCH_LOADS_BY_ID,
      id: id,
      metadata: metadataWithLimit(metadata, limit),
    }),
    setSelectedSearch: (search: LoadSearchRequest | undefined): SetSelectedSearchAction => ({
      type: actionTypes.SET_SELECTED_SEARCH,
      search: search,
    }),
    nextSearchLoads: (limit?: number): NextSearchLoadsAction => ({
      type: actionTypes.GET_NEXT_SEARCH_LOADS,
      limit: limit,
    }),
    updateLoads: updateLoadActionCreator(actionTypes.UPDATE_LOAD),
    loadCountRefreshed: (): Action => ({
      type: actionTypes.LOAD_COUNT_REFRESHED,
    }),
    removeBlockedLoad: (loadID: string): RemoveBlockedLoadAction => ({
      type: actionTypes.REMOVE_PENDING_BLOCKED,
      loadID: loadID,
    }),
    similarLoadsResponse: (
      response: ResultResponse<SimilarLoadSearchResponse>,
      flowID: string | undefined
    ): SimilarLoadsResponseAction => ({
      type: actionTypes.SIMILAR_LOADS_FETCHED,
      response: response,
      flowID: flowID,
    }),
    loadsSearchResponse: (
      response: ResultResponse<LoadSearchResponseModel | undefined>,
      flowID: string | undefined,
      searchRequest?: LoadSearchRequest
    ): FetchLoadResponseAction => ({
      type: actionTypes.LOADS_FETCHED,
      response: response,
      searchRequest: searchRequest,
      flowID: flowID,
    }),
    viewLoad: (loadID: string): ViewLoadAction => ({
      type: actionTypes.VIEW_LOAD,
      loadID: loadID,
    }),
    clearLoadSearchState: (): Action => ({
      type: actionTypes.CLEAR_LOAD_SEARCH_STATE,
    }),
    setSearchFilterDraft: (searchFilter: SearchFilter): SetSearchFilterAction => ({
      type: actionTypes.SET_SEARCH_FILTER_DRAFT,
      searchFilter: searchFilter,
    }),
  };

  //Reducer Code

  const updateLoadSearchStateSortBy = (state: LoadSearchBaseState, newSortBy: LoadSearchSort) => {
    const matchingKeySortBy = find(KeysSortBy, (sortBy) => sortBy.field === newSortBy.field);
    if (matchingKeySortBy) {
      const newKeySortBy = {
        ...matchingKeySortBy,
        direction: newSortBy.direction,
      };
      if (didSortByChange(state.sortBy, newKeySortBy)) {
        state.sortBy = newKeySortBy;
      }
    }
  };

  const fetchLoads = (
    state: LoadSearchBaseState,
    searchData: LoadSearchRequest | undefined,
    metadata: LoadSearchMetadata | undefined = undefined
  ) => {
    if (searchData) {
      state.listSearchRequest = cloneDeep(searchData);
      if (searchData.metadata.sortBy) {
        updateLoadSearchStateSortBy(state, searchData.metadata.sortBy);
      }
    } else {
      if (metadata?.sortBy) {
        updateLoadSearchStateSortBy(state, metadata.sortBy);
      }
      state.listSearchRequest.metadata.limit = metadata?.limit ?? INITIAL_SEARCH_LIMIT;
      state.listSearchRequest.metadata.nextToken = undefined;
    }
    state.loads = [];
    state.totalLoadsCount = 0;
    if (state.listSearchRequest.metadata.type === SearchMetadataType.Refresh) {
      state.listSearchRequest.metadata.type = SearchMetadataType.Regular;
    }
    state.listSearchRequest.metadata.sortBy = keySortByToLoadSearchSort(state.sortBy);
    if (state.isCountingSearches) {
      state.searchCount = state.searchCount + 1;
    }
    state.isPerformingSearch = true;
    state.isLastResult = false;
    state.isLastSimilarResult = false;
    state.loadSearchState = SearchState.Loading;
    state.similarLoads = [];
    state.showSimilarLoads = false;
    state.nextTokenSimilarLoads = '';
    state.fetchingError = undefined;
    state.searchesState.error = undefined;
  };

  const loadSearchReducer = createMergedReducer(initialState, [
    {
      [actionTypes.SET_SORT_BY]: (state, action: SetSortByAction) => {
        state.sortBy = action.sortBy;
      },
      [actionTypes.GET_NEXT_SEARCH_LOADS]: (state, action: NextSearchLoadsAction) => {
        state.listSearchRequest.metadata.limit = action.limit ?? SEARCH_LIMIT_APPENDING;
        if (state.isLastResult && (state.isLastSimilarResult || !state.showSimilarLoads)) {
          return;
        }
        state.loadSearchState = SearchState.Loading;
        state.fetchingError = undefined;
        if (state.isLastResult) {
          state.listSearchRequest.metadata.nextToken = state.nextTokenSimilarLoads;
        } else {
          state.listSearchRequest.metadata.nextToken = state.nextToken;
        }
      },
      [actionTypes.LAST_RESULT]: (state) => {
        state.loadSearchState = SearchState.Loaded;
      },

      [actionTypes.TOGGLE_COUNTING_SEARCHES]: (state) => {
        state.isCountingSearches = true;
      },

      [actionTypes.FETCH_LOADS_BY_ID]: (state, action: SearchLoadsByIdAction) => {
        fetchLoads(state, undefined, action.metadata);
        state.archivingFlowID = undefined;
        const alert = state.loadSearchAlerts.entities[action.id];
        if (alert) {
          alert.count = 0;
        }
        state.loadSearchAlerts.totalCount = sum(
          filter(map(state.loadSearchAlerts.entities, (entity) => entity?.count))
        );
      },
      [actionTypes.FETCH_LOADS]: (state, action: SearchLoadsAction) => {
        fetchLoads(state, action.searchData);
      },

      [actionTypes.LOADS_FETCHED]: (state, action: FetchLoadResponseAction) => {
        state.loadSearchState = SearchState.Loaded;
        state.isPerformingSearch = false;
        if (action.response.success && !action.response.payload) {
          return;
        }

        state.listSearchRequest = action.searchRequest ?? state.listSearchRequest;

        state.didLoadFetchFail = !action.response.success;
        state.archivingFlowID = action.flowID;
        if (action.response.success && action.response.payload) {
          const { id, metadata } = action.response.payload;
          if (metadata.sortOrder?.length && !state.listSearchRequest.metadata.sortBy) {
            state.listSearchRequest.metadata.sortBy = metadata.sortOrder[0];
          }
          if (!id) {
            state.loads = getMergedLoads(action.response.payload, state.loads);
            state.newLoadIds = map(getNewLoads(state.loads), (load) => load.id);
          } else {
            state.loads = addFetchedTime(action.response.payload.loads);
            state.newLoadIds = [];
          }
          state.isLastResult = defaultTo(metadata.isLastResult, false);
          state.nextToken = defaultTo(metadata.nextToken, '');
          state.totalLoadsCount = metadata.totalResultCount;
          state.totalLoadsCountSearchForm = metadata.totalResultCount;
          state.fetchingError = undefined;
        } else {
          state.loads = [];
          state.totalLoadsCount = 0;
          state.newLoadIds = [];
        }
        if (!action.response.success && action.response.error) {
          state.searchesState.error = action.response.error;
          state.fetchingError = action.response.error;
          state.loadSearchState = SearchState.Failed;
        }
      },

      [actionTypes.SIMILAR_LOADS_FETCHED]: (state, { response, flowID }: SimilarLoadsResponseAction) => {
        state.archivingFlowID = flowID;
        if (response.success) {
          state.similarLoads = uniqBy(
            concat(state.similarLoads, similarLoadsFrom(response.payload)),
            (similarLoad) => similarLoad.load.id
          );
          state.isLastSimilarResult = response.payload.metadata.isLastResult || false;
          state.nextTokenSimilarLoads = response.payload.metadata.nextToken;
          state.totalSimilarLoadsCount = response.payload.metadata.totalResultCount;
          state.loadSearchState = SearchState.Loaded;
          state.fetchingError = undefined;
        } else {
          state.loadSearchState = SearchState.Failed;
          state.isLastSimilarResult = true;
          state.fetchingError = response.error;
        }
      },

      [actionTypes.SET_SELECTED_SEARCH]: (state, action: SetSelectedSearchAction) => {
        state.selectedSearch = action.search;
      },

      [actionTypes.RESET_LOAD_COUNT]: (state) => {
        state.totalLoadsCountSearchForm = state.totalLoadsCount;
      },

      [actionTypes.FETCH_LOAD_COUNT]: (state) => {
        state.isFetchingCount = true;
        state.totalLoadsCountSearchForm = undefined;
      },

      [actionTypes.LOAD_COUNT_FETCHED]: (state, action: LoadCountResponseAction) => {
        state.isFetchingCount = false;
        state.totalLoadsCountSearchForm = action.response.payload?.totalResultCount;
      },

      [actionTypes.REMOVE_PENDING_BLOCKED]: (state, { loadID }: RemoveBlockedLoadAction) => {
        state.loads = filter(state.loads, (load) => load.id !== loadID);
        state.similarLoads = filter(state.similarLoads, (similarLoad) => similarLoad.load.id !== loadID);
      },

      [actionTypes.UPDATE_ORIGIN_LOCATION]: (state, action: UpdateOriginLocationAction) => {
        state.lastSearchRequest.origin = action.location;
      },

      [actionTypes.UPDATE_TRUCK]: (state, action: UpdateTruckAction) => {
        if (!action.truck.defaultLength || !action.truck.defaultWeight || !action.truck.equipmentType) {
          return;
        }
        state.lastSearchRequest.length = action.truck.defaultLength;
        state.lastSearchRequest.weight =
          action.truck.defaultWeight === MAX_PLUS_TRUCK_WEIGHT
            ? MAX_LOAD_SEARCH_TRUCK_WEIGHT
            : action.truck.defaultWeight;
        state.lastSearchRequest.equipmentTypes = [action.truck.equipmentType];
      },

      [actionTypes.SHOW_SIMILAR_LOADS]: (state) => {
        state.showSimilarLoads = true;
        state.loadSearchState = SearchState.Loading;
        state.fetchingError = undefined;
      },

      [actionTypes.UPDATE_LOAD]: (state, action: UpdateLoadAction) => {
        state.loads = updateLoadsList(state.loads, action.update);
        state.similarLoads = updateSimilarLoadsList(state.similarLoads, action.update);
      },

      [actionTypes.LOAD_COUNT_REFRESHED]: (state, action: LoadCountResponseAction) => {
        state.totalLoadsCount = action.response.payload
          ? action.response.payload.totalResultCount
          : state.totalLoadsCount;
      },

      [actionTypes.VIEW_LOAD]: (state, action: ViewLoadAction) => {
        const loadViewedUpdate: LoadUpdate = {
          category: UpdateCategory.VIEWED,
          loadID: action.loadID,
          poster: undefined,
        };
        const viewedLoadIndex = findIndex(state.loads, (load) => load.id === action.loadID);
        if (viewedLoadIndex !== -1) {
          state.loads[viewedLoadIndex] = updateLoad(state.loads[viewedLoadIndex], loadViewedUpdate);
        } else {
          const viewedSimilarLoadIndex = findIndex(
            state.similarLoads,
            (similarLoad) => similarLoad.load.id === action.loadID
          );
          if (viewedSimilarLoadIndex !== -1) {
            state.similarLoads[viewedSimilarLoadIndex].load = updateLoad(
              state.similarLoads[viewedSimilarLoadIndex].load,
              loadViewedUpdate
            );
          }
        }
      },

      [actionTypes.CLEAR_LOAD_SEARCH_STATE]: (state) => {
        state.loadSearchState = undefined;
      },
      [actionTypes.SET_SEARCH_FILTER_DRAFT]: (state, action: SetSearchFilterAction) => {
        state.searchFilterDraft = action.searchFilter;
      },
      [actionTypes.AUTO_REFRESH_LOADS]: (state) => {
        state.isAutoRefreshing = true;
      },
      [actionTypes.AUTO_REFRESH_LOADS_COMPLETE]: (state, action: AutoRefreshResponseAction) => {
        const sentNextToken = action.sentNextToken;
        const isAutoRefreshStillApplicable = sentNextToken && sentNextToken === state.nextToken;
        // Since the autorefresh actions are not blocking (i.e. not in ACTIONS_BLOCKING_GROUP), it's
        // possible that a sufficiently delayed autorefresh result will be out-of-sync with redux when it
        // returns. To ensure that we don't mess up our data, we check whether the nextTokens match.
        if (isAutoRefreshStillApplicable) {
          if (action.response.success && action.response.payload) {
            const loadsCountChange = reduce(
              action.response.payload.refreshedLoads,
              (loadsCountChange, load) => {
                return load.status === LoadStatus.Deleted ? loadsCountChange - 1 : loadsCountChange + 1;
              },
              0
            );
            state.loads = getMergedLoads(action.response.payload, state.loads);
            state.newLoadIds = map(getNewLoads(state.loads), (load) => load.id);
            state.totalLoadsCount += loadsCountChange;
            if (state.totalLoadsCountSearchForm) {
              state.totalLoadsCountSearchForm += loadsCountChange;
            }
            state.nextToken = action.response.payload.metadata.nextToken;
          }
          state.archivingFlowID = action.flowID;
        }
        state.isAutoRefreshing = false;
      },
      [actionTypes.FETCH_RATECHECK_PREVIEW]: (state, action: FetchRateCheckPreviewAction) => {
        const loadidsSet = new Set(action.loadIds);
        forEach(action.forSimilarLoads ? map(state.similarLoads, (s) => s.load) : state.loads, (load: Load) => {
          if (loadidsSet.has(load.id)) load.rateCheckPreview = { status: RateCheckPreviewStatus.Loading };
        });
      },
      [actionTypes.RATECHECK_PREVIEW_COMPLETE]: (state, action: RateCheckPreviewResponseAction) => {
        const fetchData = action.fetchData as FetchRateCheckPreviewAction;
        if (action.response.success && action.response.payload) {
          const payload = action.response.payload;
          forEach(fetchData.forSimilarLoads ? map(state.similarLoads, (s) => s.load) : state.loads, (load: Load) => {
            processRateCheckPreviewResponse(load, payload);
          });
        } else {
          const loadidsSet = new Set(fetchData.loadIds);
          forEach(fetchData.forSimilarLoads ? map(state.similarLoads, (s) => s.load) : state.loads, (load: Load) => {
            if (loadidsSet.has(load.id)) load.rateCheckPreview = { status: RateCheckPreviewStatus.Error };
          });
        }
      },
    },
  ]);

  return {
    actionTypes: actionTypes,
    actions: actions,
    reducer: loadSearchReducer,
  };
};

export const getMergedLoads = (response: LoadSearchResponseModel, currentLoads: Load[]) => {
  const refreshedLoads = addFetchedTime(response.refreshedLoads);
  const nextLoads = addFetchedTime(response.loads);
  return mergeLoadLists(currentLoads, refreshedLoads, nextLoads, response.metadata.sortOrder);
};

export const getMergedPostedLoads = (response: PostedLoadSearchResponseModel, currentLoads: Load[]) => {
  const refreshedLoads = addFetchedTime(response.refreshedLoads);
  const nextLoads = addFetchedTime(response.loads);
  return mergeLoadLists(currentLoads, refreshedLoads, nextLoads);
};

const getNewLoads = (loads: Load[]) => {
  const currentTime = Date.now();
  return filter(loads, (load: Load) => isLoadNew(load, currentTime));
};

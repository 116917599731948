import { filter, findIndex } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { UserSettingsClient } from '@/client';
import {
  createDefaultDispatchersRequest,
  DispatcherPatchRequest,
  DispatcherPostRequest,
  DispatcherPostResponse,
  DispatchersPayload,
  DispatchersRequest,
} from '@/model';
import { Api } from '@common/api';
import { compareIds } from '@common/helper';
import { createApiAction, createApiActionWithFetchData } from '@common/redux/Base';
import { createPaginatedListReducer, PaginatedListState } from '@common/redux/epic/PaginatedListReducer';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export const POSTING_CONTACTS_REDUCER_KEY = 'postingContacts';

const postingContactsPaginationReducer = createPaginatedListReducer<
  DispatcherPostResponse,
  DispatchersRequest,
  DispatchersPayload,
  PostingContactsStoreState,
  PostingContactsState
>({
  reducerKey: POSTING_CONTACTS_REDUCER_KEY,
  requestConstants: {
    limit: {
      default: 10,
      appending: 10,
    },
    offsetIncrement: 10,
  },
  getEntriesFromResponse: (response) => response.dispatchers,
  areEntriesEqual: compareIds,
});

const fetchDispatcherAction = createApiAction<string, DispatcherPostResponse>('FETCH_DISPATCHER');
export const editDispatcherAction = createApiAction<
  { guid: string; data: DispatcherPatchRequest },
  DispatcherPostResponse
>('EDIT_DISPATCHER');
export const deleteDispatcherAction = createApiActionWithFetchData<string, {}>('DELETE_DISPATCHER');
const addDispatcherAction = createApiAction<DispatcherPostRequest, DispatcherPostResponse>('ADD_DISPATCHER');

export const fetchDispatchers = postingContactsPaginationReducer.actions.fetchEntries;
export const fetchMoreDispatchers = postingContactsPaginationReducer.actions.fetchMoreEntries;
export const fetchDispatcher = (guid: string) => fetchDispatcherAction.fetchAction(guid);
export const editDispatcher = (id: string, data: DispatcherPatchRequest) =>
  editDispatcherAction.fetchAction({ guid: id, data: data });
export const addDispatcher = (dispatcher: DispatcherPostRequest) => addDispatcherAction.fetchAction(dispatcher);
export const deleteDispatcher = (id: string) => deleteDispatcherAction.fetchAction(id);

export interface PostingContactsState extends PaginatedListState<DispatcherPostResponse, DispatchersRequest> {
  selectedPostingContact?: DispatcherPostResponse;
  isLoadingDispatcher: boolean;
  wasAddedSuccessfully?: boolean;
  wasUpdatedSuccessfully?: boolean;
  wasDeletedSuccessfully?: boolean;
}

interface PostingContactsStoreState {
  [POSTING_CONTACTS_REDUCER_KEY]: PostingContactsState;
}

const initialState: PostingContactsState = {
  fetchRequest: createDefaultDispatchersRequest(),
  isLoading: false,
  didLoadingFail: false,
  isLoadingMore: false,
  isRefreshing: false,
  isLastResult: false,
  isLoadingDispatcher: false,
};

export const postingContactsReducer = createMergedReducer(
  initialState,
  [
    fetchDispatcherAction.initiateCase((state) => {
      state.isLoadingDispatcher = true;
    }),
    fetchDispatcherAction.completeCase((state, action) => {
      state.isLoadingDispatcher = false;
      if (action.response.success) {
        state.selectedPostingContact = action.response.payload;
      }
    }),
    addDispatcherAction.initiateCase((state) => {
      unsetSuccessfulFields(state);
    }),
    addDispatcherAction.completeCase((state, action) => {
      if (action.response.success) {
        state.entries = state.entries
          ? [...state.entries.slice(0, 1), action.response.payload, ...state.entries.slice(1)]
          : []; // inserting element to 1 position (after default dispatcher)
      }
      state.wasAddedSuccessfully = action.response.success;
    }),
    editDispatcherAction.initiateCase((state) => {
      unsetSuccessfulFields(state);
    }),
    editDispatcherAction.completeCase((state, action) => {
      if (action.response.success) {
        const dispatcherAfterUpdate = action.response.payload;
        const updatedDispatcherIndex = findIndex(state.entries, (item) => item.id === dispatcherAfterUpdate.id);
        if (state.entries && updatedDispatcherIndex > -1) {
          state.entries[updatedDispatcherIndex] = dispatcherAfterUpdate;
        }
      }
      state.wasUpdatedSuccessfully = action.response.success;
    }),
    deleteDispatcherAction.initiateCase((state) => {
      unsetSuccessfulFields(state);
    }),
    deleteDispatcherAction.completeCase((state, action) => {
      if (action.response.success) {
        state.entries = filter(state.entries, (postingContact) => postingContact.id !== action.fetchData);
      }
      state.wasDeletedSuccessfully = action.response.success;
    }),
  ],
  postingContactsPaginationReducer.reducer
);

const unsetSuccessfulFields = (state: PostingContactsState) => {
  state.wasAddedSuccessfully = undefined;
  state.wasUpdatedSuccessfully = undefined;
  state.wasDeletedSuccessfully = undefined;
};

export const createPostingContactsEpic = (api: Api) => {
  const client = new UserSettingsClient(api);

  return (action$: ActionsObservable<Action>, state$: StateObservable<PostingContactsStoreState>) =>
    merge$(
      postingContactsPaginationReducer.createMergedEpic$(client.getDispatchersList$, action$, state$),
      fetchDispatcherAction.createEpic$(action$, (data) => client.getDispatcher$(data)),
      editDispatcherAction.createEpic$(action$, (data) => client.editDispatcher$(data.guid, data.data)),
      addDispatcherAction.createEpic$(action$, (data) => client.addDispatcher$(data)),
      deleteDispatcherAction.createEpic$(action$, (data) => client.deleteDispatcher$(data))
    );
};

import React from 'react';

import { enableMapSet } from 'immer';
import * as ReactDOM from 'react-dom';
import 'typeface-roboto';

import { BugsnagErrorBoundary } from '@util/BugsnagHelper';

import App from './App';
import MessagingWidgetApp, { isMessagingWidgetView } from './MessagingWidgetApp';
import { registerServiceWorker } from './registerServiceWorker';

enableMapSet();

// only run app in good browsers
if (!('noApp' in window)) {
  let appComponent;
  const isMessagingWidget = isMessagingWidgetView();

  if (isMessagingWidget) {
    const partner = new URL(window.location.href).searchParams.get('partner') ?? '';
    appComponent = <MessagingWidgetApp parentHostname={partner} />;

    document.addEventListener('DOMContentLoaded', function () {
      document.body.classList.remove('app');
      document.body.classList.add('partner-portal');
    });
  } else {
    appComponent = <App />;
  }

  ReactDOM.render(
    <BugsnagErrorBoundary>{appComponent}</BugsnagErrorBoundary>,
    document.getElementById('root') as HTMLElement
  );

  registerServiceWorker();
}

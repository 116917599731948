import React, { useEffect } from 'react';

import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useSelector } from '@/util/hooks';
import { UserTruck } from '@common/model';
import { fetchUserTrucks } from '@common/redux/epic/UserTrucksEpic';
import { MyTruckCard } from '@component/cards/MyTruckCard';
import { LoadingSpinner, LoadingSpinnerSmallPanelContainer } from '@component/loadingSpinner/LoadingSpinner';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { CenteringContainer, NotFoundPanel, PageIcon } from '@component/panels/notFoundSearches/NotFoundPanel';
import { SETTINGS_PANEL_PADDING } from '@component/panels/settings/FormSettingsStyles';
import { Color } from '@style/Color';
import { ElementSize, FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

import mytruckLogo from './my_truck.svg';

interface Props {
  layer?: number;
  onClose: () => void;
  openSecondaryPanel: () => void;
  openSecondaryPanelWithId: (truckId?: string) => void;
  setCurrentTruck?: (truck?: UserTruck) => void;
  isSelect?: boolean;
}

const SectionTitle = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
  padding: ${Spacing.ScreenSide}px;
`;

export const MyTruck: React.FC<Props> = ({
  layer = 0,
  onClose,
  openSecondaryPanel,
  openSecondaryPanelWithId,
  setCurrentTruck,
  isSelect,
}: Props) => {
  const userTrucks = useSelector((state) => state.userTrucks.trucks);
  const isLoading = useSelector((state) => state.userTrucks.isLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserTrucks());
  }, []);

  const openNewTruckPanel = () => {
    openSecondaryPanel();
  };

  const onEditTruck = (truck?: UserTruck) => {
    openSecondaryPanelWithId(truck?.id);
  };

  const onSelectTruck = (truck?: UserTruck) => {
    if (isSelect && setCurrentTruck) {
      setCurrentTruck(truck);
      onClose();
    }
  };

  const displayTruck = () => {
    return userTrucks.length === 0 ? (
      <EmptyTruckPanel />
    ) : (
      <>
        <SectionTitle id="post_truck_info">{t(T.common_postTruck_myTruck_editTruck)}</SectionTitle>
        {map(userTrucks, (truck) => {
          return <MyTruckCard truck={truck} onEdit={onEditTruck} onSelect={onSelectTruck} isSelect={isSelect} />;
        })}
      </>
    );
  };

  return (
    <Panel id="settings_mpg" layer={layer} offset={1} size={PanelSize.small} backgroundColor={PANEL_SHADOW_BACKGROUND}>
      <PanelHeader
        style={{ padding: SETTINGS_PANEL_PADDING }}
        label={t(T.common_postTruck_postForm_myTuck)}
        hasCloseButton={true}
        onClose={onClose}
        actionText={t(T.common_postTruck_myTruck_addNewTruck)}
        handleClick={openNewTruckPanel}
      />
      {isLoading ? (
        <LoadingSpinnerSmallPanelContainer>
          <LoadingSpinner size={ElementSize.loadingSpinnerSize} />
        </LoadingSpinnerSmallPanelContainer>
      ) : (
        displayTruck()
      )}
    </Panel>
  );
};

const EmptyTruckPanel = () => (
  <CenteringContainer>
    <NotFoundPanel
      imageSection={<PageIcon id={`my_truck_icon`} src={mytruckLogo} />}
      title={t(T.common_postTruck_postForm_myTuck)}
      subtitle={t(T.common_postTruck_myTruck_noTrucksText)}
    />
  </CenteringContainer>
);

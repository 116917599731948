import { memberApi } from '@/reduxStore/RootEpic';
import { TrackingClient } from '@common/client/TrackingClient';
import {
  CallCarrierTrackingData,
  CallLoadTrackingData,
  CommonTrackingData,
  CommunicationEvent,
  MessagingPartnerPortalTrackingData,
  TrackingData,
  TrackingDataConversationStatus,
  TrackingTag,
} from '@common/model/Tracking';
import { createTrackAction, TrackAction } from '@common/util/123lbBaseTracker';
import { AppConfig } from '@util/AppConfig';
import { deviceID } from '@webApi/CookiesHelper';

import { AppVersion } from '../AppVersionHelper';

const COMMON_TRACKING_DATA: CommonTrackingData = {
  ApplicationVersion: AppVersion,
  ApplicationOs: 'Web',
  DeviceId: deviceID(),
  ApplicationBuild: AppConfig.LBConfig.buildNumber,
  ApplicationOsVersion: window.navigator.platform,
};

const trackingClient = new TrackingClient(memberApi);

export const track: TrackAction = createTrackAction(trackingClient, COMMON_TRACKING_DATA);

export const createCallLoadTrackData = (
  loadID: string,
  url: string,
  conversationID?: string,
  conversationStatus?: TrackingDataConversationStatus
): TrackingData<CallLoadTrackingData> => ({
  tag: TrackingTag.Communication,
  data: {
    eventName: CommunicationEvent.CallLoad,
    loadID: loadID,
    conversationID: conversationID,
    conversationStatus: conversationStatus,
    url: url,
  },
});

export const createMessagingPartnerPortalTrackData = (
  hostname: string,
  clientID: string,
  conversationsCount: number
): TrackingData<MessagingPartnerPortalTrackingData> => ({
  tag: TrackingTag.MessagingPartnerPortal,
  data: {
    hostname: hostname,
    clientID: clientID,
    conversationsCount: conversationsCount,
  },
});

export const createCallCarrierTrackData = (loadID: string, truckID: string): TrackingData<CallCarrierTrackingData> => ({
  tag: TrackingTag.CallCarrier,
  data: {
    loadID: loadID,
    truckID: truckID,
  },
});

import styled from 'styled-components';

import { Button as MaterialButton } from '@material-ui/core';

export const Button = styled(MaterialButton)`
  && {
    text-transform: none;
    padding: 0px;
    border-radius: 0px;
    text-align: left;
  }
`;

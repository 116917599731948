import React, { useRef, useState } from 'react';

import ReactResizeDetector from 'react-resize-detector';

import { displayCurrency } from '@common/formatter';
import { formatNumberWithZeroFormatting } from '@common/helper';
import { PanelWidth } from '@component/panel';
import {
  BoldText,
  LoadDetailsContainer,
  TitleText,
  TitleWrapper,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

interface TotalProfitProps {
  totalProfit: number;
  profitPerMile: number;
  linehaulRevenue: number;
  fuelCosts: number;
  additionalCosts: number;
}

const TotalProfit = (props: TotalProfitProps) => {
  const [panelWidth, setPanelWidth] = useState(0);
  const { totalProfit, profitPerMile, linehaulRevenue, fuelCosts, additionalCosts } = props;

  const totalProfitRef = useRef<HTMLDivElement | null>(null);
  const fontSize = panelWidth < PanelWidth.small ? FontSize.Body : FontSize.ContentDetail;
  const totalProfitText = totalProfit !== 0 ? displayCurrency(totalProfit) : '$0';
  const perMileProfitText = profitPerMile !== 0 ? displayCurrency(profitPerMile) : '$';
  const areAllValuesZero = linehaulRevenue === 0 && fuelCosts === 0 && additionalCosts === 0;
  const calculationText = areAllValuesZero
    ? ''
    : `${formatNumberWithZeroFormatting(linehaulRevenue)} ${fuelCosts < 0 ? '+' : '-'} ${formatNumberWithZeroFormatting(
        Math.abs(fuelCosts),
        true
      )} - ${formatNumberWithZeroFormatting(additionalCosts, true)} =`;

  return (
    <div ref={totalProfitRef}>
      <ReactResizeDetector handleWidth={true} onResize={setPanelWidth} />
      <LoadDetailsContainer id="total_profit">
        <TitleWrapper>
          <BoldText id="title">{t(T.findLoads_findLoads_totalProfit)}</BoldText>
          <TitleText id="profit_per_mile">{`${perMileProfitText}${t(T.unit_per_mi)}`}</TitleText>
        </TitleWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: Spacing.InterSection,
            justifyContent: 'space-between',
          }}
        >
          <TitleText id="calc" style={{ marginTop: 5, fontSize: fontSize }}>
            {calculationText}
          </TitleText>
          <BoldText
            id="profit"
            style={{ color: totalProfit < 0 ? Color.RED_APPLE : Color.GREEN_MAIN, fontSize: FontSize.ModalTitle }}
          >
            {totalProfitText}
          </BoldText>
        </div>
      </LoadDetailsContainer>
    </div>
  );
};

export default TotalProfit;

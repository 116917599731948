import React, { useEffect } from 'react';

import { BasePopup } from '@component/popup/BasePopup';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

import { PopupPrimaryButton } from './PopupButtons';

export const ConnectionErrorPopup: React.FC = () => {
  const { openingPopup } = usePopup();
  const { isConnectionError } = useSelector((state) => state.connectionError);

  useEffect(() => {
    if (isConnectionError) {
      openingPopup({
        body: (
          <BasePopup
            title={t(T.error_title)}
            buttons={[
              <PopupPrimaryButton
                testID={t(T.common_ok)}
                key={t(T.common_ok)}
                label={t(T.error_reload_label)}
                action={() => window.location.reload()}
              />,
            ]}
          >
            {t(T.error_network_server_error)}
          </BasePopup>
        ),
        width: PopupSizes.SMALL,
        isDismissible: false,
        hasCloseButton: false,
      });
    }
  }, [isConnectionError]);

  return null;
};

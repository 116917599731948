import { LoadSearchRequest } from '@common/model/LoadSearchRequest';
import { CA_PROVINCES, MIDWEST, NORTHEAST, OTHER, PLAINS, SOUTH, STATES, WEST } from '@common/model/States';

export enum LoadAvailabilitySortCategory {
  USA = 'USA',
  Northeast = 'Northeast',
  Midwest = 'Midwest',
  South = 'South',
  West = 'West',
  Plains = 'Plains',
  Other = 'Other',
  Canada = 'Canada',
}

export interface LoadAvailabilitySort {
  field: LoadAvailabilitySortCategory;
}
export interface StateLoadAvailabilityResponse {
  stateAvailabilities: StateLoadAvailability[];
}
export interface StateLoadAvailability {
  country: string;
  state: string;
  availabilities: LoadAvailability[];
}

interface LoadAvailability {
  type: LoadAvailabilityType;
  count: number;
  query: LoadSearchRequest;
}

export enum LoadAvailabilityType {
  NONE = 'None',
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound',
  INTRASTATE = 'Intrastate',
}

export interface FormatedLoadAvailability {
  state: string;
  inbound: number;
  outbound: number;
  intrastate: number;
  outboundQuery?: LoadSearchRequest;
  intrastateQuery?: LoadSearchRequest;
  inboundQuery?: LoadSearchRequest;
}

export const getStatesFromSortCategory = (sortCategory: LoadAvailabilitySortCategory) => {
  switch (sortCategory) {
    case LoadAvailabilitySortCategory.Northeast:
      return NORTHEAST;
    case LoadAvailabilitySortCategory.Midwest:
      return MIDWEST;
    case LoadAvailabilitySortCategory.South:
      return SOUTH;
    case LoadAvailabilitySortCategory.West:
      return WEST;
    case LoadAvailabilitySortCategory.Plains:
      return PLAINS;
    case LoadAvailabilitySortCategory.Other:
      return OTHER;
    case LoadAvailabilitySortCategory.Canada:
      return CA_PROVINCES;
    case LoadAvailabilitySortCategory.USA:
      return STATES;
    default:
      return [];
  }
};

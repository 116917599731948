import React, { Component, Ref, RefObject } from 'react';

import { CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

import { onButtonClick } from '@component/button/CustomButton';
import { StyledButton, StyledImg } from '@component/buttons/MultipurposeButtonStyle';

type InnerRef = Ref<any> & (((instance: any) => void) | RefObject<HTMLElement | SVGElement | Component<{}, {}, any>>);

export interface MultipurposeButtonProps extends Omit<ButtonProps, 'innerRef'> {
  text: string;
  icon?: string;
  isLoading?: boolean;
  spinnerSize?: number;
  innerRef?: InnerRef;
  size?: 'small' | 'medium' | 'large' | undefined;
  iconStyle?: React.CSSProperties;
}

export enum ButtonVariant {
  Text = 'text',
  Flat = 'flat',
  Outlined = 'outlined',
  Contained = 'contained',
  Raised = 'raised',
  Fab = 'fab',
  ExtendedFab = 'extendedFab',
}

export const MultipurposeButton: React.FC<MultipurposeButtonProps> = (props) => {
  const { innerRef, text, isLoading, icon, spinnerSize = 24, onClick, iconStyle, ...rest } = props;

  const content = () => {
    if (isLoading) {
      return <CircularProgress size={spinnerSize} color={'inherit'} />;
    }
    return (
      <>
        {!!icon && <StyledImg src={icon} style={iconStyle} />}
        {!!text?.trim() && text}
      </>
    );
  };

  return (
    <StyledButton innerRef={innerRef} text={text} onClick={onButtonClick(onClick, isLoading)} {...rest}>
      {content()}
    </StyledButton>
  );
};

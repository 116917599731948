import { TypographyStyle } from '@material-ui/core/styles/createTypography';

export const typography = (ts: TypographyStyle) => `
    font-size: ${ts.fontSize};
    font-weight: ${ts.fontWeight};
    font-family: ${ts.fontFamily};
    color: ${ts.color};
    ${ts.lineHeight ? `line-height: ${ts.lineHeight}` : ''};
    ${ts.letterSpacing ? `letter-spacing: ${ts.letterSpacing}` : ''};
    ${ts.textTransform ? `text-transform: ${ts.textTransform}` : ''};
`;

import { Address, EquipmentSpecification, EquipmentType, Geolocation, LoadSize, LocationType } from '@common/model';

export interface LoadSearchSettings {
  origRadius?: number;
  destRadius?: number;
  includeLoadsWithoutWeight?: boolean;
  includeLoadsWithoutLength?: boolean;
  equipTypes?: EquipmentType;
  equipSpecs?: EquipmentSpecification;
  homeAddress?: Address;
  weight?: number;
  length?: number;
  homeLocationType?: LocationType;
  loadSize?: LoadSize;
  homeGeolocation?: Geolocation;
  appUiLayout?: string;
  webUiLayout?: string;
  webAvailabilityUiLayout?: string;
  webMyLoadsUiLayout?: string;
  webBrokerUiLayout?: string;
}
export interface AppThemeSettings {
  mainTheme: AppThemeAPI;
}

export enum AppTheme {
  Light = 'light',
  Dark = 'dark',
}

export enum AppThemeMethod {
  USER_LIGHT = 'light',
  USER_DARK = 'dark',
  OS = 'OS',
}

export enum AppThemeAPI {
  USER_LIGHT = 'light', // default
  USER_DARK = 'dark',
  // Used to keep track of the OS theme, the listener only gets the OS style if it changes or on initial load so this allows us
  // to keep track of the OS theme if the user logs out and another user logs in on the same device
  OS_LIGHT = 'OS_LIGHT',
  OS_DARK = 'OS_DARK',
}

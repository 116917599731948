import { AppConfig } from '@util/AppConfig';

export const HOME_PAGE_LINK = AppConfig.LBConfig.CMSRoot;

export const SIGNUP_LINK = `${HOME_PAGE_LINK}/signup/`;

export const TERMS_OF_SERVICE_LINK = `${HOME_PAGE_LINK}/terms/`;

export const PRIVACY_POLICY_LINK = `${HOME_PAGE_LINK}/privacy/`;

export const SUPPORT_EMAIL_LINK = 'mailto:support@123loadboard.com';

export const LINK_123_CONNECT = 'https://www.123loadboard.com/post-loads/123connect';

export const EMAIL_HOOK = '123://email';

export const LINK_COTA_LOGIN = 'https://cota-client.herokuapp.com/user/sign-in';

export const LINK_COTA_IN_123LB_FAQ_PAGE =
  'https://help.123loadboard.com/hc/en-us/articles/360059009393-How-do-I-export-a-booked-load-to-COTA-';

export const LINK_DISABLE_NOTIFICATIONS_FAQ_PAGE =
  'https://help.123loadboard.com/hc/en-us/articles/360036550434-How-do-I-enable-disable-push-notifications-alerts-';

export const LINK_FAQ_PAGE = 'https://help.123loadboard.com';

export const LINK_YOUTUBE_TUTORIALS = 'https://www.youtube.com/user/123loadboard';

export const LINK_BROWSER_NOTIFICATIONS =
  'https://help.123loadboard.com/hc/en-us/articles/360036550434-How-do-I-enable-push-notifications';

// there is no Reset Password page on CI so we don't
// generate a link for CI builds
export const RESET_PASSWORD_LINK =
  AppConfig.LBConfig.lbEnvironment === 'live' ||
  AppConfig.LBConfig.lbEnvironment === 'beta' ||
  AppConfig.LBConfig.lbEnvironment === 'qa' ||
  AppConfig.LBConfig.lbEnvironment === 'dev'
    ? `${HOME_PAGE_LINK}/login/reset-password/`
    : undefined;

export const VERIFIED_LOGIN_LINK = (query: string) => `${AppConfig.LBConfig.AppMembersRoot}/verifiedLogin/${query}`;

export const LINK_UPLOAD_POST_GUIDELINES =
  'https://help.123loadboard.com/hc/en-us/articles/360036966233-What-are-the-Excel-and-CSV-file-load-posting-guidelines-';

export const MY_CARRIER_PORTAL = 'https://www.mycarrierportal.com/';
export const MY_CARRIER_PORTAL_INTG_TOOLS = 'https://mycarrierpackets.com/IntegrationTools';

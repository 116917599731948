import React from 'react';

import { IconActionButton } from '@component/buttons';
import { T, t } from '@translate';
import icons from '@util/iconsConstants';

interface Props {
  onClick: () => void;
}

export const CurrentLocationButton: React.FC<Props> = (props) => (
  <IconActionButton
    id="current_location"
    title={`${t(T.findLoads_findLoads_currentLocationLabel)}`}
    mainIcon={icons.location}
    onClick={props.onClick}
  />
);

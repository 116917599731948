import { isNil } from 'lodash';

interface ItemWithId {
  id: string;
}

export const extractId = ({ id }: ItemWithId) => id;

const getId = (item: ItemWithId | undefined) => (item ? extractId(item) : undefined);

export const compareIds = (item1: ItemWithId, item2: ItemWithId) =>
  !isNil(item1) && !isNil(item2) && getId(item1) === getId(item2);

export enum UpgradePlanError {
  Failed,
  InvalidPromoCode,
  Unknown,
}

export enum MembershipStatus {
  SignUpInitialization = 'SignUpInitialization',
  Potential = 'Potential',
  MobileSignUpInProgress = 'MobileSignUpInProgress',
  SignUpInProgress = 'SignUpInProgress',
  StoppedShort = 'StoppedShort',
  PendingEmailVerification = 'PendingEmailVerification',
  InTrial = 'InTrial',
  PendingPayment = 'PendingPayment',
  Expired = 'Expired',
  OnHold = 'OnHold',
  Active = 'Active',
}

export enum MembershipType {
  Apple = 'Apple',
  Loadboard = 'Loadboard',
  Guest = 'Guest',
}

export enum UnlockFeaturePopupType {
  DrivingTime = 'DrivingTime ',
  RateCheck = 'RateCheck',
  CargoChiefRate = 'CargoChiefRate',
  CreditRating = 'CreditRating',
  MileageCalculator = 'MileageCalculator',
  DarkMode = 'DarkMode',
  CompanyLoads = 'CompanyLoads',
  MarketRates = 'MarketRates',
  SearchDirectory = 'SearchDirectory',
}

export interface TaxRate {
  province?: string;
  taxLabel1?: string;
  tax1?: number;
  taxLabel2?: string;
  tax2?: number;
}

export interface UserPlan {
  promoCode: string;
  name: string;
  promoLogoUrl?: string;
  packages: LbPlanPackage[];
  taxRate?: TaxRate;
}

export interface LbPlanPackage {
  id: number;
  guid: string;
  name: string;
  subTitle?: string;
  price: number;
  promotionPrice: number;
  promotionDays: number;
  billingIntervalMonth: number;
  isCurrent: boolean;
  isDefault: boolean;
  features: PlanFeature[];
  promotionEndDate: string;
  billingStartDate: string;
  isBillingInfoRequired: boolean;
  startOn: string;
  expireOn: string;
  promoCode: string;
  status: 'Active' | 'Deleted';
  addOns?: AddOn[];
  additionalAddOns?: AddOn[];
}

export interface PlanFeature {
  summary: string;
  isIncluded: boolean;
  additionalInfo?: {
    title: string;
    content: string;
  };
}

export interface PromoCodeInfo {
  promoCode?: string;
  isToRemove?: boolean;
}

export interface AddOn {
  id?: number;
  guid?: string;
  name?: string;
  addOnType?: string;
  isSelected?: boolean;
  price?: number;
  defaultPrice?: number;
  billingIntervalMonth?: number;
  url?: string;
  serviceTypeId?: number;
}

export interface MembershipPackageData {
  id: number;
  guid: string;
  isCurrent: boolean;
  isDefault: boolean;
  name: string;
  subtitle?: string;
  price: number;
  promotionPrice?: number;
  promotionDays?: number;
  promotionEndDate?: Date;
  billingIntervalMonth?: number;
  billingStartDate?: string;
  isBillingInfoRequired: boolean;
  startOn?: Date;
  expireOn?: Date;
  promoCode?: string;
  status?: string;
  features?: PlanFeature[];
  addOns?: AddOn[];
}

export interface MembershipData {
  nextBillingDate: string;
  hasUnsubscribeAuthority: boolean;
  isCancelling: boolean;
  cancellationDate?: string;
  status: MembershipStatus;
  package: MembershipPackageData;

  // The properties below are used to generate the broker paywall
  isBroker?: boolean;
  /** name of the original plan before it expired */
  planName?: string;
  /** price of the original plan before it expired */
  price?: number;
  /** guid of the original plan before it expired */
  brokerPlanId?: string;
}

export interface MembershipTypeResponse {
  membershipType: MembershipType;
}

export interface SelectedMembershipData {
  planName?: string;
  selectedAddOns: string[];
}

export enum TimePeriod {
  Year = 'Year',
  Month = 'Month',
  Day = 'Day',
}

export interface RetentionPlanInfo {
  id: number;
  guid: string;
  name: string;
  price: number;
  promoCode: string;
  nextBillingDate?: string;
  limitedPromoDuration?: number;
  limitedPromoDurationUnit?: TimePeriod;
  originalPurchasePrice?: number;
}

//@TODO: Break this file up into quotes/requests/registration

import { cloneDeep, defaultTo, find } from 'lodash';
import { omit } from 'lodash/fp';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap } from 'rxjs/operators';

import { Api, ApiResponse123 } from '@common/api';
import { ThunderFundingClient } from '@common/client/ThunderFundingClient';
import { compareIds, createPatch } from '@common/helper';
import {
  CustomFolder,
  Document,
  FuelAdvanceQuoteSteps,
  FundingTypeOption,
  GetPaidFasterQuoteSteps,
  PaymentScheduleOption,
} from '@common/model';
import {
  BaseThunderFundingQuote,
  DefaultThunderFundingQuotesRequest,
  FuelAdvanceQuote,
  FuelAdvanceQuoteRequest,
  fuelAdvanceQuoteToRequest,
  GetPaidFasterQuote,
  GetPaidFasterQuoteRequest,
  getPaidFasterQuoteToRequest,
  THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_APPENDING,
  THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_DEFAULT,
  THUNDER_FUNDING_QUOTES_REQUEST_OFFSET_INCREMENT,
  ThunderFundingFuelAdvanceQuotes,
  ThunderFundingGetPaidFasterQuotes,
  ThunderFundingInvoice,
} from '@common/model/ThunderFundingRequest';
import { BaseState, ResponseAction, SearchResponsePayload } from '@common/redux/Base';
import {
  createPaginatedListReducer,
  PaginatedListState,
  PaginatedRequest,
} from '@common/redux/epic/PaginatedListReducer';

export const THUNDER_FUNDING_QUOTES_REDUCER_KEY = 'thunderFundingQuotes';
const FUEL_ADVANCE_QUOTES_REDUCER_KEY = `${THUNDER_FUNDING_QUOTES_REDUCER_KEY}_fuelAdvance`;
const GET_PAID_FASTER_QUOTES_REDUCER_KEY = `${THUNDER_FUNDING_QUOTES_REDUCER_KEY}_getPaidFaster`;

const UPDATE_FUEL_ADVANCE_REQUEST = 'UPDATE_FUEL_ADVANCE_REQUEST';
const UPDATE_GET_PAID_FASTER_REQUEST = 'UPDATE_GET_PAID_FASTER_REQUEST';

const CLEAR_FUEL_ADVANCE_REQUEST = 'CLEAR_FUEL_ADVANCE_REQUEST';
const CLEAR_GET_PAID_FASTER_REQUEST = 'CLEAR_GET_PAID_FASTER_REQUEST';

const UPDATE_GET_PAID_FASTER_NOTE = 'UPDATE_GET_PAID_FASTER_NOTE';
const FETCH_FUEL_ADVANCE_FUNDING_TYPES = 'FETCH_FUEL_ADVANCE_FUNDING_TYPES';
const FETCH_GET_PAID_FASTER_PAYMENT_SCHEDULES = 'FETCH_GET_PAID_FASTER_PAYMENT_SCHEDULES';
const FUNDING_TYPES_RESPONSE = 'FUNDING_TYPES_RESPONSE';
const PAYMENT_SCHEDULES_RESPONSE = 'PAYMENT_SCHEDULES_RESPONSE';

const SEND_FUEL_ADVANCE_INTEGRATION_QUOTE = 'SEND_FUEL_ADVANCE_INTEGRATION_QUOTE';
const SEND_GET_PAID_FASTER_INTEGRATION_QUOTE = 'SEND_GET_PAID_FASTER_INTEGRATION_QUOTE';
export const FUEL_ADVANCE_INTEGRATION_QUOTE_RESPONSE = 'FUEL_ADVANCE_INTEGRATION_QUOTE_RESPONSE';
export const GET_PAID_FASTER_INTEGRATION_QUOTE_RESPONSE = 'GET_PAID_FASTER_INTEGRATION_QUOTE_RESPONSE';
const CLEAR_FUEL_ADVANCE_INTEGRATION_QUOTE_RESPONSE = 'CLEAR_FUEL_ADVANCE_INTEGRATION_QUOTE_RESPONSE';
const CLEAR_GET_PAID_FASTER_INTEGRATION_QUOTE_RESPONSE = 'CLEAR_GET_PAID_FASTER_INTEGRATION_QUOTE_RESPONSE';
const FETCH_FUEL_ADVANCE_QUOTE_BY_ID = 'FETCH_FUEL_ADVANCE_QUOTE_BY_ID';
const FETCH_GET_PAID_FASTER_QUOTE_BY_ID = 'FETCH_GET_PAID_FASTER_QUOTE_BY_ID';
const FUEL_ADVANCE_QUOTE_BY_ID_FETCHED = 'FUEL_ADVANCE_QUOTE_BY_ID_FETCHED';
const GET_PAID_FASTER_QUOTE_BY_ID_FETCHED = 'GET_PAID_FASTER_QUOTE_BY_ID_FETCHED';
const FETCH_TOTAL_THUNDER_FUNDING_QUOTES = 'FETCH_TOTAL_THUNDER_FUNDING_QUOTES';
const QUOTE_COUNT_RESPONSE = 'QUOTE_COUNT_RESPONSE';

interface ThunderFundingStoreState {
  [THUNDER_FUNDING_QUOTES_REDUCER_KEY]: ThunderFundingQuotesState;
}

interface BaseQuoteState extends BaseState {
  id: string | undefined;
}

export interface FuelAdvanceQuoteRequestState extends BaseQuoteState {
  isRequestSuccessful: boolean | undefined;
  steps: FuelAdvanceRequestSteps;
  fundingTypes: FundingTypeOption[];
}

export interface GetPaidFasterQuoteRequestState extends BaseQuoteState {
  isRequestSuccessful: boolean | undefined;
  steps: GetPaidFasterRequestSteps;
  additionalNotes: string;
  paymentSchedules: PaymentScheduleOption[];
}

interface UpdateGetPaidFasterNoteAction extends Action {
  value: string;
}

export type FuelAdvanceQuotesState = PaginatedListState<FuelAdvanceQuote>;
export type GetPaidFasterQuotesState = PaginatedListState<GetPaidFasterQuote>;

interface UpdateFuelAdvanceFormAction extends Action {
  steps: Partial<FuelAdvanceRequestSteps>;
  id?: string;
}

interface UpdateGetPaidFasterFormAction extends Action {
  steps: Partial<GetPaidFasterRequestSteps>;
  id?: string;
}

interface GetQuoteByIdAction extends Action {
  id: string;
}

export interface ThunderFundingQuotesState {
  fuelAdvanceQuotes: FuelAdvanceQuotesState;
  getPaidFasterQuotes: GetPaidFasterQuotesState;
  fuelAdvanceRequest: FuelAdvanceQuoteRequestState;
  getPaidFasterRequest: GetPaidFasterQuoteRequestState;
  hasQuotes: boolean;
}
export interface FuelAdvanceRequestSteps {
  [FuelAdvanceQuoteSteps.desiredAmount]: string | undefined;
  [FuelAdvanceQuoteSteps.fundingType]: FundingTypeOption | undefined;
  [FuelAdvanceQuoteSteps.folder]: CustomFolder | undefined;
  [FuelAdvanceQuoteSteps.rateConfirmation]: Document | undefined;
  [FuelAdvanceQuoteSteps.deliveryDocument]: Document | undefined;
  [FuelAdvanceQuoteSteps.requestSent]: string | undefined;
}

export interface GetPaidFasterRequestSteps {
  [GetPaidFasterQuoteSteps.paymentSchedule]: PaymentScheduleOption | undefined;
  [GetPaidFasterQuoteSteps.folder]: CustomFolder | undefined;
  [GetPaidFasterQuoteSteps.invoiceType]: ThunderFundingInvoice | undefined;
  [GetPaidFasterQuoteSteps.rateConfirmation]: Document | undefined;
  [GetPaidFasterQuoteSteps.billOfLading]: Document | undefined;
  [GetPaidFasterQuoteSteps.requestSent]: string | undefined;
}

const fuelAdvanceQuotesPaginationReducer = createPaginatedListReducer<
  FuelAdvanceQuote,
  PaginatedRequest,
  ThunderFundingFuelAdvanceQuotes,
  ThunderFundingStoreState,
  FuelAdvanceQuotesState
>({
  reducerKey: FUEL_ADVANCE_QUOTES_REDUCER_KEY,
  getPaginatedListStateFromStoreState: (store: ThunderFundingStoreState) =>
    store[THUNDER_FUNDING_QUOTES_REDUCER_KEY].fuelAdvanceQuotes,
  requestConstants: {
    limit: {
      default: THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_DEFAULT,
      appending: THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_APPENDING,
    },
    offsetIncrement: THUNDER_FUNDING_QUOTES_REQUEST_OFFSET_INCREMENT,
  },
  getEntriesFromResponse: (response) => response.quotes,
  areEntriesEqual: compareIds,
});

const getPaidFasterQuotesPaginationReducer = createPaginatedListReducer<
  GetPaidFasterQuote,
  PaginatedRequest,
  ThunderFundingGetPaidFasterQuotes,
  ThunderFundingStoreState,
  GetPaidFasterQuotesState
>({
  reducerKey: GET_PAID_FASTER_QUOTES_REDUCER_KEY,
  getPaginatedListStateFromStoreState: (store: ThunderFundingStoreState) =>
    store[THUNDER_FUNDING_QUOTES_REDUCER_KEY].getPaidFasterQuotes,
  requestConstants: {
    limit: {
      default: THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_DEFAULT,
      appending: THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_APPENDING,
    },
    offsetIncrement: THUNDER_FUNDING_QUOTES_REQUEST_OFFSET_INCREMENT,
  },
  getEntriesFromResponse: (response) => response.quotes,
  areEntriesEqual: compareIds,
});

const createInitialQuotesPaginatedListState = (): PaginatedListState<BaseThunderFundingQuote, PaginatedRequest> => ({
  fetchRequest: cloneDeep(DefaultThunderFundingQuotesRequest),
  isLoading: false,
  didLoadingFail: false,
  isLoadingMore: false,
  isRefreshing: false,
  isLastResult: false,
});

export const fetchQuoteCount = (): Action => ({
  type: FETCH_TOTAL_THUNDER_FUNDING_QUOTES,
});

export const fetchFuelAdvanceFundingTypes = (): Action => ({
  type: FETCH_FUEL_ADVANCE_FUNDING_TYPES,
});

export const sendFuelAdvanceQuote = (): Action => ({
  type: SEND_FUEL_ADVANCE_INTEGRATION_QUOTE,
});

export const sendGetPaidFasterQuote = (): Action => ({
  type: SEND_GET_PAID_FASTER_INTEGRATION_QUOTE,
});

export const clearGetPaidFasterQuoteRequest = (): Action => ({
  type: CLEAR_GET_PAID_FASTER_INTEGRATION_QUOTE_RESPONSE,
});

export const clearFuelAdvanceQuoteRequest = (): Action => ({ type: CLEAR_FUEL_ADVANCE_INTEGRATION_QUOTE_RESPONSE });

export const fetchGetPaidFasterPaymentSchedules = (): Action => ({ type: FETCH_GET_PAID_FASTER_PAYMENT_SCHEDULES });

export const updateFuelAdvanceRequest = (
  steps: Partial<FuelAdvanceRequestSteps>,
  id?: string
): UpdateFuelAdvanceFormAction => ({
  type: UPDATE_FUEL_ADVANCE_REQUEST,
  steps: steps,
  id: id,
});

export const updateGetPaidFasterRequest = (
  steps: Partial<GetPaidFasterRequestSteps>,
  id?: string
): UpdateGetPaidFasterFormAction => ({
  type: UPDATE_GET_PAID_FASTER_REQUEST,
  steps: steps,
  id: id,
});

export const clearFuelAdvanceRequest = (): Action => ({
  type: CLEAR_FUEL_ADVANCE_REQUEST,
});

export const clearGetPaidFasterRequest = (): Action => ({
  type: CLEAR_GET_PAID_FASTER_REQUEST,
});

export const getFuelAdvanceQuoteById = (id: string): GetQuoteByIdAction => ({
  type: FETCH_FUEL_ADVANCE_QUOTE_BY_ID,
  id: id,
});

export const getGetPaidFasterQuoteById = (id: string): GetQuoteByIdAction => ({
  type: FETCH_GET_PAID_FASTER_QUOTE_BY_ID,
  id: id,
});

export const updateGetPaidFasterNote = (newState: { value: string | undefined }): UpdateGetPaidFasterNoteAction => ({
  type: UPDATE_GET_PAID_FASTER_NOTE,
  value: newState.value || '',
});

export const fetchFuelAdvanceQuotes = fuelAdvanceQuotesPaginationReducer.actions.fetchEntries;
export const fetchMoreFuelAdvanceQuotes = fuelAdvanceQuotesPaginationReducer.actions.fetchMoreEntries;

export const fetchGetPaidFasterQuotes = getPaidFasterQuotesPaginationReducer.actions.fetchEntries;
export const fetchMoreGetPaidFasterQuotes = getPaidFasterQuotesPaginationReducer.actions.fetchMoreEntries;

const emptyFuelAdvanceDocuments = {
  rateConfirmation: undefined,
  deliveryDocument: undefined,
};

const emptyFuelAdvanceRequestSteps: Readonly<FuelAdvanceRequestSteps> = {
  ...emptyFuelAdvanceDocuments,
  desiredAmount: undefined,
  fundingType: undefined,
  folder: undefined,
  requestSent: undefined,
};

const emptyGetPaidFasterDocuments = {
  invoiceType: undefined,
  rateConfirmation: undefined,
  billOfLading: undefined,
};

const emptyGetPaidFasterForm: Readonly<GetPaidFasterRequestSteps> = {
  ...emptyGetPaidFasterDocuments,
  paymentSchedule: undefined,
  folder: undefined,
  requestSent: undefined,
};

export const fuelAdvanceInitialState: FuelAdvanceQuoteRequestState = {
  isRequestSuccessful: undefined,
  steps: emptyFuelAdvanceRequestSteps,
  isLoading: false,
  fundingTypes: [],
  id: undefined,
};

export const getPaidFasterInitialState: GetPaidFasterQuoteRequestState = {
  isRequestSuccessful: undefined,
  steps: emptyGetPaidFasterForm,
  additionalNotes: '',
  isLoading: false,
  paymentSchedules: [],
  id: undefined,
};

const initialState: ThunderFundingQuotesState = {
  fuelAdvanceQuotes: createInitialQuotesPaginatedListState(),
  getPaidFasterQuotes: createInitialQuotesPaginatedListState(),
  fuelAdvanceRequest: fuelAdvanceInitialState,
  getPaidFasterRequest: getPaidFasterInitialState,
  hasQuotes: false,
};

export const thunderFundingQuotesReducer = (
  state: ThunderFundingQuotesState = initialState,
  action: Action
): ThunderFundingQuotesState => {
  switch (action.type) {
    case FUEL_ADVANCE_INTEGRATION_QUOTE_RESPONSE: {
      const { response } = action as ResponseAction<FuelAdvanceQuote>;
      return {
        ...state,
        hasQuotes: true,
        fuelAdvanceQuotes: {
          ...state.fuelAdvanceQuotes,
          entries: undefined,
        },
        fuelAdvanceRequest: {
          ...state.fuelAdvanceRequest,
          isRequestSuccessful: response.success,
          isLoading: false,
        },
      };
    }
    case GET_PAID_FASTER_INTEGRATION_QUOTE_RESPONSE: {
      const { response } = action as ResponseAction<GetPaidFasterQuote>;
      return {
        ...state,
        hasQuotes: true,
        fuelAdvanceQuotes: {
          ...state.fuelAdvanceQuotes,
          entries: undefined,
        },
        getPaidFasterRequest: {
          ...state.getPaidFasterRequest,
          isRequestSuccessful: response.success,
          isLoading: false,
        },
      };
    }
    case UPDATE_GET_PAID_FASTER_REQUEST: {
      const { steps: stepsBeingUpdated, id } = action as UpdateGetPaidFasterFormAction;
      return {
        ...state,
        getPaidFasterRequest: {
          ...state.getPaidFasterRequest,
          steps: {
            ...state.getPaidFasterRequest.steps,
            //Reset documents if the folder is changed
            ...(didFolderChange(stepsBeingUpdated, state.getPaidFasterRequest)
              ? emptyGetPaidFasterDocuments
              : undefined),
            ...stepsBeingUpdated,
          },
          id: defaultTo(id, state.getPaidFasterRequest.id),
        },
      };
    }
    case UPDATE_GET_PAID_FASTER_NOTE: {
      const updateAction = action as UpdateGetPaidFasterNoteAction;
      return {
        ...state,
        getPaidFasterRequest: {
          ...state.getPaidFasterRequest,
          additionalNotes: updateAction.value,
        },
      };
    }

    case PAYMENT_SCHEDULES_RESPONSE: {
      const { response } = action as ResponseAction<{ paymentSchedules: PaymentScheduleOption[] }>;
      if (response && response.payload) {
        return {
          ...state,
          getPaidFasterRequest: {
            ...state.getPaidFasterRequest,
            paymentSchedules: response.payload.paymentSchedules || [],
          },
        };
      }

      return state;
    }
    case SEND_GET_PAID_FASTER_INTEGRATION_QUOTE: {
      return {
        ...state,
        getPaidFasterRequest: {
          ...state.getPaidFasterRequest,
          isLoading: true,
          isRequestSuccessful: undefined,
        },
      };
    }
    case CLEAR_GET_PAID_FASTER_REQUEST: {
      return {
        ...state,
        getPaidFasterRequest: {
          ...state.getPaidFasterRequest,
          steps: getPaidFasterInitialState.steps,
          additionalNotes: '',
          id: undefined,
        },
      };
    }
    case CLEAR_GET_PAID_FASTER_INTEGRATION_QUOTE_RESPONSE: {
      return {
        ...state,
        getPaidFasterRequest: { ...state.getPaidFasterRequest, isRequestSuccessful: undefined, id: undefined },
      };
    }
    case UPDATE_FUEL_ADVANCE_REQUEST: {
      const { steps, id } = action as UpdateFuelAdvanceFormAction;
      return {
        ...state,
        fuelAdvanceRequest: {
          ...state.fuelAdvanceRequest,
          steps: {
            ...state.fuelAdvanceRequest.steps,
            //Reset documents if the folder is changed
            ...(didFolderChange(steps, state.fuelAdvanceRequest) ? emptyFuelAdvanceDocuments : undefined),
            ...steps,
          },
          id: defaultTo(id, state.fuelAdvanceRequest.id),
        },
      };
    }
    case FETCH_FUEL_ADVANCE_QUOTE_BY_ID: {
      return { ...state, fuelAdvanceQuotes: { ...state.fuelAdvanceQuotes, entries: undefined, isLoading: true } };
    }
    case FETCH_GET_PAID_FASTER_QUOTE_BY_ID: {
      return { ...state, getPaidFasterQuotes: { ...state.getPaidFasterQuotes, entries: undefined, isLoading: true } };
    }
    case FUEL_ADVANCE_QUOTE_BY_ID_FETCHED: {
      const { response } = action as ResponseAction<FuelAdvanceQuote>;
      if (response && response.payload && response.payload.id) {
        return {
          ...state,
          fuelAdvanceQuotes: {
            ...state.fuelAdvanceQuotes,
            entries: [response.payload],
            isLoading: false,
          },
        };
      }
      return {
        ...state,
        fuelAdvanceQuotes: { ...state.fuelAdvanceQuotes, isLoading: false },
      };
    }
    case GET_PAID_FASTER_QUOTE_BY_ID_FETCHED: {
      const { response } = action as ResponseAction<GetPaidFasterQuote>;
      if (response && response.payload && response.payload.id) {
        return {
          ...state,
          getPaidFasterQuotes: {
            ...state.getPaidFasterQuotes,
            entries: [response.payload],
            isLoading: false,
          },
        };
      }
      return {
        ...state,
        getPaidFasterQuotes: { ...state.getPaidFasterQuotes, isLoading: false },
      };
    }
    case CLEAR_FUEL_ADVANCE_REQUEST: {
      return {
        ...state,
        fuelAdvanceRequest: { ...state.fuelAdvanceRequest, steps: emptyFuelAdvanceRequestSteps, id: undefined },
      };
    }
    case FUNDING_TYPES_RESPONSE: {
      const { response } = action as ResponseAction<{ fundingTypes: FundingTypeOption[] }>;
      if (response.success && response.payload) {
        return {
          ...state,
          fuelAdvanceRequest: { ...state.fuelAdvanceRequest, fundingTypes: response.payload.fundingTypes },
        };
      }
      return state;
    }
    case SEND_FUEL_ADVANCE_INTEGRATION_QUOTE: {
      return {
        ...state,
        fuelAdvanceRequest: { ...state.fuelAdvanceRequest, isLoading: true, isRequestSuccessful: undefined },
      };
    }
    case CLEAR_FUEL_ADVANCE_INTEGRATION_QUOTE_RESPONSE: {
      return {
        ...state,
        fuelAdvanceRequest: { ...state.fuelAdvanceRequest, isRequestSuccessful: undefined, isLoading: false },
      };
    }
    case QUOTE_COUNT_RESPONSE: {
      const { response } = action as ResponseAction<SearchResponsePayload>;
      if (response && response.payload) {
        return {
          ...state,
          hasQuotes: state.hasQuotes || response.payload.metadata.totalResultCount > 0,
        };
      }
      return state;
    }
    default: {
      const alteredFuelAdvanceState = fuelAdvanceQuotesPaginationReducer.reducer(state.fuelAdvanceQuotes, action);
      const alteredGetPaidFasterState = getPaidFasterQuotesPaginationReducer.reducer(state.getPaidFasterQuotes, action);
      if (alteredFuelAdvanceState || alteredGetPaidFasterState) {
        return {
          ...state,
          fuelAdvanceQuotes: alteredFuelAdvanceState ? alteredFuelAdvanceState : state.fuelAdvanceQuotes,
          getPaidFasterQuotes: alteredGetPaidFasterState ? alteredGetPaidFasterState : state.getPaidFasterQuotes,
        };
      }
      return state;
    }
  }
};

const postFuelAdvanceIntegrationQuote$ = (
  client: ThunderFundingClient,
  action$: ActionsObservable<Action>,
  state$: StateObservable<ThunderFundingStoreState>
) =>
  action$
    .ofType(SEND_FUEL_ADVANCE_INTEGRATION_QUOTE)
    .pipe(
      mergeMap(() => {
        const { fuelAdvanceRequest, fuelAdvanceQuotes } = state$.value[THUNDER_FUNDING_QUOTES_REDUCER_KEY];

        if (fuelAdvanceRequest.id) {
          return client.patchFuelAdvanceIntegrationQuote$(
            createFuelAdvancePatch(fuelAdvanceRequest, fuelAdvanceQuotes.entries),
            fuelAdvanceRequest.id
          );
        }
        return client.postFuelAdvanceIntegrationQuote$(createFuelAdvanceQuoteRequest(fuelAdvanceRequest));
      })
    )
    .pipe(map$((response) => response.resultDataResponse(FUEL_ADVANCE_INTEGRATION_QUOTE_RESPONSE)));

const postGetPaidFasterIntegrationQuote$ = (
  client: ThunderFundingClient,
  action$: ActionsObservable<Action>,
  state$: StateObservable<ThunderFundingStoreState>
) =>
  action$
    .ofType(SEND_GET_PAID_FASTER_INTEGRATION_QUOTE)
    .pipe(
      mergeMap(() => {
        const { getPaidFasterRequest, getPaidFasterQuotes } = state$.value[THUNDER_FUNDING_QUOTES_REDUCER_KEY];

        if (getPaidFasterRequest.id) {
          return client.patchGetPaidFasterIntegrationQuote$(
            createGetPaidFasterPatch(getPaidFasterRequest, getPaidFasterQuotes.entries),
            getPaidFasterRequest.id
          );
        }
        return client.postGetPaidFasterIntegrationQuote$(createGetPaidFasterQuoteRequest(getPaidFasterRequest));
      })
    )
    .pipe(
      map$((response: ApiResponse123<GetPaidFasterQuote>) =>
        response.resultDataResponse(GET_PAID_FASTER_INTEGRATION_QUOTE_RESPONSE)
      )
    );

const fetchFundingTypes$ = (client: ThunderFundingClient, action$: ActionsObservable<Action>) =>
  action$
    .ofType(FETCH_FUEL_ADVANCE_FUNDING_TYPES)
    .pipe(
      mergeMap(() =>
        client.getFundingTypes$().pipe(map$((response) => response.resultDataResponse(FUNDING_TYPES_RESPONSE)))
      )
    );

const fetchNumberOfQuotes$ = (client: ThunderFundingClient, action$: ActionsObservable<Action>) =>
  action$.ofType(FETCH_TOTAL_THUNDER_FUNDING_QUOTES).pipe(
    mergeMap(() =>
      client.countFuelAdvanceQuotes$().pipe(map$((response) => response.resultDataResponse(QUOTE_COUNT_RESPONSE)))
    ),
    mergeMap(() =>
      client.countGetPaidFasterQuotes$().pipe(map$((response) => response.resultDataResponse(QUOTE_COUNT_RESPONSE)))
    )
  );

const fetchPaymentSchedules = (client: ThunderFundingClient, action$: ActionsObservable<Action>) =>
  action$
    .ofType(FETCH_GET_PAID_FASTER_PAYMENT_SCHEDULES)
    .pipe(
      mergeMap(() =>
        client.fetchPaymentTypes$().pipe(map$((response) => response.resultDataResponse(PAYMENT_SCHEDULES_RESPONSE)))
      )
    );

const fetchGetPaidFasterQuoteById$ = (client: ThunderFundingClient, action$: ActionsObservable<Action>) =>
  action$
    .ofType(FETCH_GET_PAID_FASTER_QUOTE_BY_ID)
    .pipe(
      mergeMap((action: GetQuoteByIdAction) =>
        client
          .fetchGetPaidFasterQuoteById$(action.id)
          .pipe(map$((response) => response.resultDataResponse(GET_PAID_FASTER_QUOTE_BY_ID_FETCHED)))
      )
    );

const fetchFuelAdvanceQuoteById$ = (client: ThunderFundingClient, action$: ActionsObservable<Action>) =>
  action$
    .ofType(FETCH_FUEL_ADVANCE_QUOTE_BY_ID)
    .pipe(
      mergeMap((action: GetQuoteByIdAction) =>
        client
          .fetchFuelAdvanceQuoteById$(action.id)
          .pipe(map$((response) => response.resultDataResponse(FUEL_ADVANCE_QUOTE_BY_ID_FETCHED)))
      )
    );

export const createThunderFundingQuotesEpic = (api: Api) => {
  const thunderFundingQuotesClient = new ThunderFundingClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<ThunderFundingStoreState>) =>
    merge$(
      fetchFuelAdvanceQuoteById$(thunderFundingQuotesClient, action$),
      fetchGetPaidFasterQuoteById$(thunderFundingQuotesClient, action$),
      fuelAdvanceQuotesPaginationReducer.createMergedEpic$(
        thunderFundingQuotesClient.fetchFuelAdvanceQuotes$,
        action$,
        state$
      ),
      getPaidFasterQuotesPaginationReducer.createMergedEpic$(
        thunderFundingQuotesClient.fetchGetPaidFasterQuotes$,
        action$,
        state$
      ),
      fetchFundingTypes$(thunderFundingQuotesClient, action$),
      fetchPaymentSchedules(thunderFundingQuotesClient, action$),
      postGetPaidFasterIntegrationQuote$(thunderFundingQuotesClient, action$, state$),
      postFuelAdvanceIntegrationQuote$(thunderFundingQuotesClient, action$, state$),
      fetchNumberOfQuotes$(thunderFundingQuotesClient, action$)
    );
};

const createFuelAdvanceQuoteRequest = (request: FuelAdvanceQuoteRequestState): FuelAdvanceQuoteRequest => ({
  folderId: defaultTo(request.steps.folder && request.steps.folder.id, ''),
  desiredAmount: defaultTo(request.steps.desiredAmount, '0'),
  fundingType: defaultTo(request.steps.fundingType && request.steps.fundingType.name, ''),
  bolOrPodDocumentId: getId(request.steps.deliveryDocument),
  rateConfirmationDocumentId: getId(request.steps.rateConfirmation),
});

const createGetPaidFasterQuoteRequest = (request: GetPaidFasterQuoteRequestState): GetPaidFasterQuoteRequest => ({
  folderId: defaultTo(request.steps.folder && request.steps.folder.id, ''),
  tfCreatesInvoice: defaultTo(request.steps.invoiceType && request.steps.invoiceType.isThunderFundingInvoice, false),
  specialInstructions: request.additionalNotes,
  paymentSchedule: defaultTo(request.steps.paymentSchedule && request.steps.paymentSchedule.name, ''),
  bolDocumentId: getId(request.steps.billOfLading),
  rateConfirmationDocumentId: getId(request.steps.rateConfirmation),
  invoiceDocumentId:
    request.steps.invoiceType && !request.steps.invoiceType.isThunderFundingInvoice
      ? getId(request.steps.invoiceType.invoiceDocument)
      : undefined,
});

const createGetPaidFasterPatch = (
  request: GetPaidFasterQuoteRequestState,
  quotes: GetPaidFasterQuote[] | undefined
) => {
  const oldQuote = findQuote(quotes, request);
  if (!oldQuote) {
    return [];
  }

  const oldQuoteRequest = createGetPaidFasterQuoteRequest({
    ...request,
    steps: getPaidFasterQuoteToRequest(oldQuote, request.paymentSchedules),
    additionalNotes: oldQuote.specialInstructions || '',
  });

  const newQuoteRequest = omitIds(createGetPaidFasterQuoteRequest(request));

  return createPatch(oldQuoteRequest, newQuoteRequest);
};

const createFuelAdvancePatch = (request: FuelAdvanceQuoteRequestState, quotes: FuelAdvanceQuote[] | undefined) => {
  const oldQuote = findQuote(quotes, request);
  if (!oldQuote) {
    return [];
  }

  const oldQuoteRequest = createFuelAdvanceQuoteRequest({
    ...request,
    steps: fuelAdvanceQuoteToRequest(oldQuote, request.fundingTypes),
  });

  const newQuoteRequest = omitIds(createFuelAdvanceQuoteRequest(request));

  return createPatch(oldQuoteRequest, newQuoteRequest);
};

const omitIds = omit(['folderId', 'id']);

const findQuote = <T extends { id: string }>(quotes: T[] | undefined, request: { id?: string }) =>
  find(quotes, (quote) => quote.id === request.id);

const getId = (document: Document | undefined): string => (document && document.id) || '';

const didFolderChange = (
  stepsBeingUpdated: Partial<GetPaidFasterRequestSteps | FuelAdvanceRequestSteps>,
  state: GetPaidFasterQuoteRequestState | FuelAdvanceQuoteRequestState
): boolean => {
  if (stepsBeingUpdated.folder && state.steps.folder) {
    return state.steps.folder.id !== stepsBeingUpdated.folder.id;
  }
  return false;
};

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useSelector } from '@/util/hooks';
import { Flag, Load } from '@common/model';
import { getSystemSetting } from '@common/redux/epic/SettingsEpic';
import { LinkButton } from '@component/contact/PhoneNumber';
import { DataGrid, Label } from '@component/panels/basePanel/loadDetails/LoadDetailsMoreInfo';
import { MyNoteTitle, Row } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { T, t } from '@translate';

import { default as CotaSVG } from './cota.svg';
import { getUrlCotaLoadDetails, openCotaNewLoad } from './CotaUrlHelper';

interface Props {
  loadDetails: Load;
}

const IconContainer = styled.span`
  display: flex;
  justify-content: flex-start;
`;

const CotaIconStyled = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const LoadDetailsExportSection: React.FC<Props> = (props) => {
  const loadDetails = props.loadDetails;
  const cotaDetails = getUrlCotaLoadDetails(loadDetails);
  const cotaExportEnabled = useSelector((state) => state.settings.systemSetting[Flag.IntegrationCotaEnabled].value);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSystemSetting(Flag.IntegrationCotaEnabled));
  }, []);

  return cotaExportEnabled ? (
    <Row>
      <MyNoteTitle id="export_details">{t(T.settings_preferences_exportingLoadDetails_title)}</MyNoteTitle>
      <DataGrid style={{ marginTop: '16px', marginBottom: '24px' }}>
        <IconContainer>
          <CotaIconStyled id="cota_icon" src={CotaSVG} />
          <Label>{t(T.settings_preferences_exportingLoadDetails_cota)}</Label>
        </IconContainer>
        <LinkButton onClick={() => openCotaNewLoad(cotaDetails)}>
          {t(T.settings_preferences_exportingLoadDetails_exportLoadDetails)}
        </LinkButton>
      </DataGrid>
    </Row>
  ) : null;
};

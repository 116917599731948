import { LoadVisibilityRuleset, LoadVisibilityRulesetResponse } from '@/model/LoadVisibilityRules';
import { convertRulesetForRequest } from '@/reduxStore/epic/LoadVisibilityRulesHelper';
import { BaseClient } from '@common/client/BaseClient';
import { EmptyResponse } from '@common/redux/Base';

export class LoadVisibilityRulesClient extends BaseClient {
  createRuleset$ = (request: LoadVisibilityRuleset) =>
    this.api.post$<LoadVisibilityRulesetResponse>(
      '/loadvisibility/v1/broker/rulesets',
      convertRulesetForRequest(request)
    );

  getRuleset$ = (id: string) =>
    this.api.get$<LoadVisibilityRulesetResponse>(`/loadvisibility/v1/broker/rulesets/${id}`, {});

  getAllRulesets$ = () =>
    this.api.get$<{ rulesets: LoadVisibilityRulesetResponse[] }>('/loadvisibility/v1/broker/rulesets', {});

  modifyRuleset$ = (request: LoadVisibilityRuleset) =>
    this.api.mergePatch$<LoadVisibilityRulesetResponse>(
      `/loadvisibility/v1/broker/rulesets/${request.id}`,
      convertRulesetForRequest(request)
    );

  deleteRuleset$ = (id: string) => this.api.delete$<EmptyResponse>(`/loadvisibility/v1/broker/rulesets/${id}`, {});
}

import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';

export interface DispatchersRequest extends PaginatedRequest {
  id: string;
}

export const createDefaultDispatchersRequest = (): DispatchersRequest => ({
  id: '',
  offset: 0,
  limit: 10,
  fields: 'all',
});

export interface DispatcherPhoneResponse {
  lineNumber: string | null;
  extension: string | null;
}

export interface DispatcherPostRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneResponse: DispatcherPhoneResponse;
}

export interface DispatcherPostResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: DispatcherPhoneResponse;
  isDefaultForUser: boolean;
}

export interface DispatcherPatchRequest {
  firstName?: string;
  lastName?: string;
  code: string;
  email: string | null;
  isDefault: boolean;
  phone: DispatcherPhoneResponse;
}

export interface DispatchersPayload {
  dispatchers: DispatcherPostResponse[];
  count: number;
  totalCount: number;
}

import React from 'react';

import { DocumentsContext } from '@common/redux/epic/DocumentListEpic';
import { useOpenDocumentWithFolder } from '@component/panels/findLoads/loadDetailsPanel/ManageDocuments';
import { ActionButton, Left, ManageLineContainer, ManageLineTitle, Right } from '@component/statusBar/StatusBarStyle';
import { useSelector } from '@util/hooks';

interface ManageLineProps {
  title: string;
  actionTitle: string;
  isManageAvailable: boolean;
  isFromMyLoads?: boolean;
}

export const ManageLine: React.FC<ManageLineProps> = (props) => {
  const folderIdentifier = useSelector((state) => state.documents.loadDetailsDocuments.loadFolderIdentifier);

  const context = props.isFromMyLoads ? DocumentsContext.STANDALONE_LOADDETAILS : DocumentsContext.DEFAULT;
  const openDocumentsWithFolder = useOpenDocumentWithFolder(props.isFromMyLoads, context);

  const openMyDocuments = () => openDocumentsWithFolder(folderIdentifier);
  return (
    <ManageLineContainer id="manage">
      <Left>
        <ManageLineTitle id="title">{props.title}</ManageLineTitle>
      </Left>
      <Right>
        <ActionButton
          id="action_button"
          text={props.actionTitle}
          variant="outlined"
          color="secondary"
          onClick={openMyDocuments}
          disabled={!props.isManageAvailable || folderIdentifier === undefined}
        />
      </Right>
    </ManageLineContainer>
  );
};

import React from 'react';

import { map } from 'lodash';
import styled from 'styled-components';

import { RegionsList } from '@common/helper';
import { ListItem } from '@component/panels/findLoads/searchPanel/TrailerTypeComponent';
import { Color } from '@style/Color';
import { FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { useIsMobileView } from '@util/hooks';

interface ComponentProps {
  chips: ChipItem[];
  isSingleChoice?: boolean;
}

export interface ChipItem {
  label: string;
  onClick: (state: string) => void;
  isChecked: boolean;
}

interface ChipProps {
  isChecked: boolean;
  isWithoutRegion?: boolean;
}

const StatesBar = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: ${(props: { isWithoutRegion?: boolean }) => (props.isWithoutRegion ? 'wrap' : '')};
`;

const RegionsBar = styled.div`
  padding-left: ${Spacing.ScreenSide}px;
  padding-right: ${Spacing.InterElementHorizontal}px;
  border-right: 1px solid ${Color.GRAY_LAVA};
`;

const Chip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: ${(props: ChipProps) => (props.isChecked ? Color.GREEN_MAIN : Color.GRAY_LAVA)};
  color: ${(props: ChipProps) => (props.isChecked ? Color.WHITE : Color.LIGHT_BLACK)};
  font-size: ${FontSize.ContentDetail}px;
  font-weight: ${FontWeight.Bold};
  cursor: pointer;
  margin-bottom: ${(props: ChipProps) => (props.isWithoutRegion ? '15px' : '0px')};
`;

const StateChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background-color: ${(props: ChipProps) => (props.isChecked ? Color.GREEN_MAIN : Color.GRAY_LAVA)};
  color: ${(props: ChipProps) => (props.isChecked ? Color.WHITE : Color.LIGHT_BLACK)};
  font-size: ${FontSize.ContentDetail}px;
  font-weight: ${FontWeight.Bold};
  cursor: pointer;
  margin-bottom: ${Spacing.InterElementVertical}px;
  margin-right: ${Spacing.InterElementHorizontal}px;
`;

export const StatesRow: React.FC<ComponentProps> = (props) => {
  const isMobileView = useIsMobileView();
  const { chips } = props;

  const getChipRightOffset = (isLastChip: boolean) => {
    const margin = props.isSingleChoice ? 8 : 5;
    const offsetValue = isMobileView ? 2 : margin;
    return isLastChip ? 0 : offsetValue;
  };

  return (
    <ListItem>
      <StatesBar isWithoutRegion={props.isSingleChoice}>
        {map(chips, (chip, index) => (
          <Chip
            isChecked={chip.isChecked}
            onClick={() => chip.onClick(chip.label)}
            style={{ marginRight: getChipRightOffset(index === chips.length - 1) }}
            key={chip.label}
            id={chip.label}
            isWithoutRegion={props.isSingleChoice}
          >
            {chip.label}
          </Chip>
        ))}
      </StatesBar>
    </ListItem>
  );
};

export const RegionsColumn: React.FC<{
  shouldSelectRegion: (state: string[]) => boolean;
  onRegionsSelectionChange: (regionId: string, states: string[]) => void;
  regionsList: RegionsList;
}> = ({ shouldSelectRegion, onRegionsSelectionChange, regionsList }) => {
  return (
    <RegionsBar>
      {map(regionsList, (region) => (
        <ListItem>
          <Chip
            id={`${region.id}_checkbox`}
            isChecked={shouldSelectRegion(region.value)}
            onClick={() => onRegionsSelectionChange(region.id, region.value)}
            key={region.id}
          >
            {region.id}
          </Chip>
        </ListItem>
      ))}
    </RegionsBar>
  );
};

export const StatesGrid: React.FC<ComponentProps> = (props) => {
  const { chips } = props;

  return (
    <div>
      {map(chips, (chip) => (
        <StateChip isChecked={chip.isChecked} onClick={() => chip.onClick(chip.label)} key={chip.label} id={chip.label}>
          {chip.label}
        </StateChip>
      ))}
    </div>
  );
};

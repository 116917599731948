import L, { Coords, DomEvent, DoneCallback, GridLayer as LeafletElement, Util } from 'leaflet';
import { GridLayer, GridLayerProps } from 'react-leaflet';
import { withLeaflet } from 'react-leaflet/es/context';

import { refreshTokenIfNeeded$ } from '@webApi/ResponseHandler';

import RegisterLeafletALKMaps from './alkmaps/alkmaps-reduced.js';

RegisterLeafletALKMaps(L);

const ALKMAPS_ACCESS_STR = 'ALKMaps';

interface MapLayerProps extends GridLayerProps {
  noAuthentication?: boolean;
}

class ALKMapsLayerComponent extends GridLayer<MapLayerProps> {
  createLeafletElement(): LeafletElement {
    const LeafletALKMaps = (L as any)[ALKMAPS_ACCESS_STR];

    const mapLayer = LeafletALKMaps.Layer.baseMap({
      region: 'NA',
      dataset: 'Current',
      style: 'lightness',
    });

    //-------------------------------------------------------------
    // The following fixes an issue in ALKMaps.js. It ensures that
    // only one INIT=true is generated instead of multiple (~9).
    //-------------------------------------------------------------
    const baseGetTileUrl = mapLayer.getTileUrl;
    mapLayer.getTileUrl = function (coords: any) {
      const url = baseGetTileUrl.call(this, coords);
      this._url = mapLayer.getUrl();
      return url;
    };

    mapLayer.createTile = (coords: Coords, done: DoneCallback) => {
      //-------------------------------------------------------------
      // THE FOLLOWING IS FROM LEAFLET-SRC.js (v1.2.0) TileLayer::createTile() (line ~11283)
      // This method is fortunately asynchronous. By creating a tile with no src, we can
      // delay the load/error DomEvents by first resolving Authentication if required.
      // The tile will start to fade in only after `done` is invoked.
      //-------------------------------------------------------------

      // The `done` callback is called when the tile has been loaded.

      const tile = document.createElement('img');

      if (mapLayer.options.crossOrigin) {
        tile.crossOrigin = '';
      }

      /*
       Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
       http://www.w3.org/TR/WCAG20-TECHS/H67
      */
      tile.alt = '';

      /*
       Set role="presentation" to force screen readers to ignore this
       https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
      */
      tile.setAttribute('role', 'presentation');

      const doFetchImage = () => {
        DomEvent.on(tile, 'load', Util.bind(mapLayer._tileOnLoad, mapLayer, done, tile));
        DomEvent.on(tile, 'error', Util.bind(mapLayer._tileOnError, mapLayer, done, tile));
        tile.src = mapLayer.getTileUrl(coords);
      };

      if (this.props.noAuthentication) {
        doFetchImage();
      } else {
        refreshTokenIfNeeded$().subscribe(
          () => {
            doFetchImage();
          },
          (err) => {
            done(err, tile);
          }
        );
      }
      return tile;
    };

    return mapLayer;
  }
}

export const ALKMapsLayer = withLeaflet(ALKMapsLayerComponent);

import moment from 'moment';
import { Observable } from 'rxjs';
import { map as map$ } from 'rxjs/operators';

import { ApiResponse123, ApiResponseSuccess } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';
import { MessageRequest, MessagesResult } from '@common/model';

const SUPORTED_MESSAGE_TYPES = 'LoadAlertEnRoute,AutomaticLoadMatch,LoadAlert'; // @TODO: remove this line when all alert types are supported
export class TruckAlertsClient extends BaseClient {
  fetchTruckAlerts$ = (messageReq: MessageRequest) =>
    this.api.get$<MessagesResult>('/messages', {
      limit: messageReq.limit,
      offset: messageReq.offset,
      messageTypes: SUPORTED_MESSAGE_TYPES,
    });
  fetchUnreadTruckAlerts$ = (messageReq: MessageRequest, startDate: number) =>
    this.api.get$<MessagesResult>('/messages', {
      limit: messageReq.limit,
      offset: messageReq.offset,
      messageTypes: SUPORTED_MESSAGE_TYPES,
      read: false,
      startDate: moment(startDate).toISOString(),
    });
  fetchTruckAlertsCount$ = (): Observable<ApiResponse123<number>> =>
    this.api
      .get$<MessagesResult>('/messages', {
        fields: 'unreadMessagesCount',
        messageTypes: SUPORTED_MESSAGE_TYPES,
      })
      .pipe(
        map$((response) =>
          response.result<ApiResponse123<number>>(
            (data) => new ApiResponseSuccess(response.httpStatus, data.unreadMessagesCount),
            (error) => error
          )
        )
      );
  fetchLoadInfoAlerts$ = (loadId: string) => this.api.get$<MessagesResult>(`/loads/${loadId}/messages`, {});
  readAlert$ = (id: string, isRead: boolean) =>
    this.api.patch$<MessagesResult>(`/messages/${id}`, [{ op: 'replace', path: '/read', value: isRead }]);
  deleteAlert$ = (id: string) =>
    this.api.patch$<MessagesResult>(`/messages/${id}`, [{ op: 'replace', path: '/deleted', value: true }]);
}

import React from 'react';

import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton } from '@component/popup/PopupButtons';
import { t, T } from '@translate';

export const RateUnavailablePopup: React.FC<{ closingPopup: () => void }> = (props) => {
  return (
    <BasePopup
      title={t(T.common_marketRate_rateCheck_rateNotAvailable)}
      buttons={[<PopupOkButton testID="rate_unavailable_ok" key="rate_unavailable_ok" action={props.closingPopup} />]}
    >
      {t(T.common_marketRate_rateCheck_rateNotAvailable_info)}
    </BasePopup>
  );
};

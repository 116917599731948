import { SortDirection } from '@common/model';
import { CompanyType, ResultSortCategory, SearchDirectoryCompanyRequest } from '@common/model/SearchDirectory';
import { createObjectParser, parseString, parseValueFrom } from '@common/util/parser/ParserUtils';

export const parseDirectorySearchRequest = createObjectParser<Partial<SearchDirectoryCompanyRequest> | undefined>(
  (object) => {
    const { companyType, companyName, state, sortExpression, sortDirection } = object;
    const parsedCompany = parseValueFrom(companyType, CompanyType) ?? CompanyType.Carrier;
    const parsedSortExpression = parseValueFrom(sortExpression, ResultSortCategory) ?? ResultSortCategory.Name;
    const parsedSortDirection = parseValueFrom(sortDirection, SortDirection) ?? SortDirection.Ascending;
    const parsedCompanyName = parseString(companyName);
    const parsedState = parseString(state);
    const directorySearchParameters: Partial<SearchDirectoryCompanyRequest> = {
      companyType: parsedCompany,
      companyName: parsedCompanyName ? parsedCompanyName : '',
      state: parsedState ? parsedState : '',
      sortExpression: parsedSortExpression,
      sortDirection: parsedSortDirection,
    };
    return directorySearchParameters;
  }
);

import { Action } from 'redux';

import { createReducer } from '@common/redux/ReduxHelper';

const SET_PANEL_MOBILE_VIEW = 'SET_PANEL_MOBILE_VIEW';

interface SetPanelMobileViewAction extends Action {
  isMobileView: boolean;
}

export const setPanelMobileView = (isMobileView: boolean): SetPanelMobileViewAction => ({
  type: SET_PANEL_MOBILE_VIEW,
  isMobileView: isMobileView,
});

export interface PanelState {
  /** True if showing a single panel taking the full width meant for mobile (single scroll area).
   * This will make that panel use the whole page to scroll instead of a scrolling DIVs.
   * (This remains "true" even if the sidebar menu is showing)
   */
  isMobileView: boolean;
}

const initialState: PanelState = {
  isMobileView: true,
};

export const panelReducer = createReducer(initialState, {
  [SET_PANEL_MOBILE_VIEW]: (state: PanelState, action: SetPanelMobileViewAction) => {
    state.isMobileView = action.isMobileView;
  },
});

import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { ThemeProps, withTheme } from '@style/WithTheme';

export const StyledButton = withTheme()(styled(Button)`
  && {
    flex: 1;
    text-transform: none;
    height: 56px;
  }
`);

export const StyledWrapper = withTheme()(styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border-bottom: 1px solid ${(props: ThemeProps) => props.theme.palette.background.smoke};
  box-shadow: 0px 2px 14px 0px ${(props: ThemeProps) => props.theme.palette.background.smoke};
  background-color: ${(props: ThemeProps) => props.theme.palette.background.white};
`);

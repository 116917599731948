export enum CostPerDaySetting {
  enabled = 'enabled',
  truckExpenses = 'truckExpenses',
  homeExpenses = 'homeExpenses',
  workDays = 'workDays',
  loadingHours = 'loadingHours',
  drivingHours = 'drivingHours',
}

export interface CostPerDaySettings {
  [CostPerDaySetting.enabled]: boolean;
  [CostPerDaySetting.truckExpenses]: number;
  [CostPerDaySetting.homeExpenses]: number;
  [CostPerDaySetting.workDays]: number;
  [CostPerDaySetting.loadingHours]: number;
  [CostPerDaySetting.drivingHours]: number;
}

export const DEFAULT_WORK_DAYS = 20;
export const DEFAULT_LOADING_HOURS = 2;
export const DEFAULT_DRIVING_HOURS = 10;

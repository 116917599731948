import React, { useEffect, useRef, useState } from 'react';

import { every, first, includes, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import EmptyBids from '@/icons/empty-folder.svg';
import { BiddingRole } from '@common/model';
import {
  fetchBiddingSummaryList,
  fetchLoadBids,
  fetchMoreBiddingSummaryList,
  fetchMoreBidSummaries,
} from '@common/redux/epic/bids/BidsEpic';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { usePanelBackLocation } from '@component/panel/PanelHooks';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { InfiniteScrollView } from '@component/scrollView';
import { BidCardSummaryItem } from '@page/bids/BidCards';
import { bidsEncoder, BidsRouteParamsProps } from '@page/bids/BidsPanelRouting';
import { BidInfoRibbon } from '@page/bids/summary/BidInfoRibbonSummary';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

interface BiddingSummaryProps {
  onCloseDetailsPanel?: () => void;
  layer: number;
  panelOffset: number;
  isCarrier: boolean;
  isLoading?: boolean;
}

export const BiddingSummary = (props: BiddingSummaryProps) => {
  const history = useHistory();
  const params = useParams<BidsRouteParamsProps>();

  const loadId = params.loadID;
  const otherPartyId = useSelector((state) => state.bids.loadBids.listAll.carrierId);
  const otherPartyUserID = otherPartyId ?? params.otherPartyUserID;

  const bidId = params.bidID;
  const [shouldDisplayErrorPanel, setShouldDisplayErrorPanel] = useState(false);

  const biddingSummaryIds = useSelector((state) => state.bids.biddingSummary.listAll.ids);
  const bidSummaries = useSelector((state) => state.bids.bidSummaries.listAll.ids);
  const {
    isLoading: isLoadingSummaries,
    isLastPage: isLastPageSummaries,
    nextToken: nextTokenSummaries,
  } = useSelector((state) => state.bids.bidSummaries.listAll);

  const { isLoading, isLastPage, nextToken } = useSelector((state) => state.bids.biddingSummary.listAll);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const panelSize = PanelSize.small;

  const closeLocation = usePanelBackLocation('/summary/');

  const searchParams = bidsEncoder.convertUrlSearchParamsToObject(history.location.search);

  const redirectCommunicationPath = searchParams.redirectCommunicationPath;
  const redirectLoadDetailsPath = searchParams.redirectLoadDetailsPath;

  const dispatch = useDispatch();

  const bids = useSelector((state) => state.bids.loadBids.listAll.ids);
  const topLoadBidId = first(bids) ?? undefined;

  const mostRecentBidID = redirectCommunicationPath ? topLoadBidId : bidId;

  const bidSummary = useSelector((state) => state.bids.bidSummaries.summaries.get(mostRecentBidID ?? bidId ?? ''));

  const hasMore = isLastPage !== undefined ? !isLastPage : false;
  useEffect(() => {
    setShouldDisplayErrorPanel(!isLoading && bidSummary === undefined && biddingSummaryIds === undefined);
  }, [biddingSummaryIds]);

  useEffect(() => {
    if (!every(biddingSummaryIds, (id) => includes(bidSummaries, id))) {
      if (nextTokenSummaries && !isLastPageSummaries && !isLoadingSummaries) {
        dispatch(fetchMoreBidSummaries(nextTokenSummaries, [BiddingRole.Broker]));
      }
    }
  }, [biddingSummaryIds, bidSummaries]);

  useEffect(() => {
    if (!isLoading && bidSummary) {
      if (bidSummary?.load?.id) {
        setShouldDisplayErrorPanel(false);
        dispatch(
          fetchBiddingSummaryList({
            loadId: bidSummary.load.id,
            carrierId: bidSummary?.otherParty?.contactId,
          })
        );
      }
    } else if (!isLoading && bidSummary === undefined && biddingSummaryIds.length === 0) {
      setShouldDisplayErrorPanel(true);
    }
  }, [bidSummary]);

  useEffect(() => {
    if (!isLoading && !isLastPage && nextToken && bidSummary?.load?.id) {
      dispatch(
        fetchMoreBiddingSummaryList({
          loadId: bidSummary.load.id,
          token: nextToken,
          carrierId: bidSummary?.otherParty?.contactId,
        })
      );
    }
  }, [isLastPage, nextToken]);

  useEffect(() => {
    if (bidSummary === undefined && !isLoading && loadId && otherPartyUserID) {
      dispatch(
        fetchBiddingSummaryList({
          loadId: loadId,
          carrierId: otherPartyUserID,
        })
      );
      dispatch(
        fetchLoadBids({
          loadId: loadId,
          carrierId: otherPartyUserID,
        })
      );
    }
  }, [bidSummary, otherPartyUserID, bids]);

  const closePanel = () => {
    if (redirectCommunicationPath) {
      history.push({
        pathname: `${redirectCommunicationPath}`,
      });
    } else if (redirectLoadDetailsPath) {
      history.push({
        pathname: `${redirectLoadDetailsPath}`,
      });
    } else if (closeLocation) {
      closeLocation();
    }
  };

  if (props.isCarrier) {
    return null;
  }

  if (shouldDisplayErrorPanel) {
    return (
      <Panel
        id="error_panel"
        size={panelSize}
        layer={props.layer}
        offset={props.panelOffset}
        scrollRef={scrollContainerRef}
        backgroundColor={PANEL_SHADOW_BACKGROUND}
      >
        <PanelHeader
          style={{ padding: '0 6px' }}
          label={t(T.common_bids_biddingSummary_title)}
          hasCloseButton={true}
          onClose={() => closePanel()}
        ></PanelHeader>
        <NotFoundPanel
          title={t(T.loadAvailability_errorScreen_title)}
          subtitle={t(T.loadAvailability_errorScreen_subtitle)}
          imageSection={<img src={EmptyBids} alt="server issue" />}
        />
      </Panel>
    );
  }

  return (
    <Panel
      id="bidding_summary_panel"
      size={panelSize}
      layer={props.layer}
      offset={props.panelOffset}
      scrollRef={scrollContainerRef}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        style={{ padding: '0 6px' }}
        label={t(T.common_bids_biddingSummary_title)}
        hasCloseButton={true}
        onClose={() => closePanel()}
      ></PanelHeader>
      <>
        <BidInfoRibbon bid={bidSummary} />
        {isLoading || isLoadingSummaries ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <LoadingSpinner />
          </div>
        ) : null}
        <InfiniteScrollView
          id="bids_summary_list"
          hasMore={hasMore}
          rootRef={scrollContainerRef}
          hideLoader={true}
          isLoading={isLoading}
        >
          {map(biddingSummaryIds, (bidId) => (
            <BidCardSummaryItem key={`BidCardSummaryItem_${bidId}`} bidId={bidId} newMessages={0} isSelected={true} />
          ))}
        </InfiniteScrollView>
      </>
    </Panel>
  );
};

import React from 'react';

import { formatDateHHMMA } from '@common/helper';
import {
  ConversationDateText,
  DateContainer,
  IconAndTimeContainer,
  IconContainer,
  MessageReadContainer,
  ReadAndSentTimeText,
} from '@component/conversation/ConversationStyles';
import icons from '@util/iconsConstants';

export const ReadAndSentTime: React.FC<{
  isMessageRead: boolean;
  isFromCurrentUser: boolean;
  displayTime: string | undefined;
  time: string | undefined;
}> = (props) => {
  return (
    <>
      <IconAndTimeContainer>
        {props.isMessageRead && props.displayTime ? (
          <MessageReadContainer>
            <IconContainer>
              <img id="read" src={icons.messageRead} alt={'read'} />
            </IconContainer>
            <ReadAndSentTimeText id="read_at_time">{props.displayTime}</ReadAndSentTimeText>
          </MessageReadContainer>
        ) : null}
        {props.time ? (
          <>
            <IconContainer style={{ marginLeft: props.isFromCurrentUser ? '' : '-4px' }}>
              <img id="sent" src={icons.messageSent} alt={'sent'} />
            </IconContainer>
            <ReadAndSentTimeText id="sent_at_time">{formatDateHHMMA(props.time)}</ReadAndSentTimeText>
          </>
        ) : null}
      </IconAndTimeContainer>
    </>
  );
};

export const ConversationDate: React.FC<{ date: string }> = (props) => {
  return (
    <DateContainer>
      <ConversationDateText id="conversation_date">{props.date}</ConversationDateText>
    </DateContainer>
  );
};

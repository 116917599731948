import {
  AlertsPayload,
  DispatcherDetail,
  DispatcherPatchRequest,
  DispatcherPayload,
  DispatcherPostRequest,
  DispatcherPostResponse,
  DispatchersPayload,
  DispatchersRequest,
  EmailNotificationsPayload,
  HotLeadsPayload,
  MessagesNotificationsPayload,
  ResetPasswordPayload,
  UserAddDetails,
  UserEditDetails,
  UsersSettingsPayload,
} from '@/model';
import { BaseClient } from '@common/client/BaseClient';
import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';

export class UserSettingsClient extends BaseClient {
  addUser$ = (data: UserAddDetails) => this.api.post$<UserAddDetails>('/contacts/requestNew', data);
  getUsers$ = (id: string, offset: number) =>
    this.api.get$<UsersSettingsPayload>(`companies/${id}/contacts`, { offset: offset });
  getDispatchers$ = ({ id, ...rest }: DispatchersRequest) =>
    this.api.get$<DispatcherPayload>(`companies/${id}/dispatchers`, rest);
  getDispatcher$ = (id: string) => this.api.get$<DispatcherPostResponse>(`/dispatchers/${id}`, {});
  getDispatchersList$ = (request: PaginatedRequest) => this.api.get$<DispatchersPayload>('/dispatchers', request);
  addDispatcher$ = (data: DispatcherPostRequest) => this.api.post$<DispatcherPostResponse>('/dispatchers', data);
  deleteDispatcher$ = (id: string) => this.api.delete$<{}>(`/dispatchers/${id}`, {});
  editDispatcher$ = (id: string, data: DispatcherPatchRequest) =>
    this.api.mergePatch$<DispatcherPostResponse>(`/dispatchers/${id}`, data);
  getDefaultDispatcher$ = () => this.api.get$<DispatcherDetail>('/contacts/dispatcher', {});
  updateUser$ = (id: number, userDetails: UserEditDetails) =>
    this.api.put$<UserEditDetails>(`contacts/${id}`, userDetails);
  getUser$ = (id: string) => this.api.get$<UserEditDetails>(`contacts/${id}/info`, {});
  deleteUser$ = (guid: string) => this.api.delete$<undefined>(`contacts/${guid}`, {});
  getHotLeads$ = () => this.api.get$<HotLeadsPayload>(`/settings/privacycontrol`, {});
  updateHotLeads$ = (hotLeads: HotLeadsPayload) =>
    this.api.mergePatch$<HotLeadsPayload>(`/settings/privacycontrol`, hotLeads);
  getAlerts$ = () => this.api.get$<AlertsPayload>(`/settings/alerts`, {});
  updateAlerts$ = (data: AlertsPayload) => this.api.mergePatch$<AlertsPayload>(`/settings/alerts`, data);
  getEmailNotifications$ = () => this.api.get$<EmailNotificationsPayload>(`/settings/emailnotifications `, {});

  updateEmailNotifications$ = (data: EmailNotificationsPayload) =>
    this.api.mergePatch$<EmailNotificationsPayload>(`/settings/emailnotifications`, data);

  resetPasswordWithEmail$ = (data: { email: string }) =>
    this.api.post$<ResetPasswordPayload>('/credentials/ForgottenPassword', data);

  postNotificationToken$ = (data: MessagesNotificationsPayload) =>
    this.api.post$<undefined>(`/settings/notification`, notificationStatusParams(data.deviceId, data.token));

  updateNotificationToken$ = (data: MessagesNotificationsPayload) =>
    this.api.put$<undefined>(
      `/settings/notification/${data.deviceId}`,
      notificationStatusParams(data.deviceId, data.token)
    );

  saveNotificationToken$ = () => this.api.get$<undefined>('/settings', { fields: 'messaging' });
}

const notificationStatusParams = (deviceId: string, token: string) => ({
  deviceId: deviceId,
  token: token,
  os: 'Desktop',
  osVersion: '10',
  enabled: true,
  authorizationStatus: 1,
  notificationVersion: 2,
});

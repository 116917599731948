import React, { PropsWithChildren, ReactNode, useState } from 'react';

import { map, range } from 'lodash';
import styled from 'styled-components';

import { WidthMeasurer } from './WidthMeasurer';

const HiddenRenderableContent = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
  position: relative;
`;

const AbsoluteWidthMeasurer = styled(WidthMeasurer)`
  position: absolute;
  left: 0;
`;

interface Props<T> {
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
  onMeasure: (width: number, item: T, index: number) => void;
}

export const ItemsWidthMeasurer = <T,>(props: PropsWithChildren<Props<T>>) => {
  const onMeasure = (item: T, index: number) => (width: number) => props.onMeasure(width, item, index);
  return (
    <HiddenRenderableContent>
      {map(props.items, (item, index) => (
        <AbsoluteWidthMeasurer onMeasure={onMeasure(item, index)}>
          {props.renderItem(item, index)}
        </AbsoluteWidthMeasurer>
      ))}
    </HiddenRenderableContent>
  );
};

export const useItemsWidthMeasurer = <T,>(
  items: T[],
  renderItem: (item: T, index: number) => ReactNode,
  defaultWidth?: number
): [number[], () => ReactNode] => {
  const [widths, setWidths] = useState<number[]>([]);

  const onItemMeasured = (width: number, item: T, index: number) => {
    setWidths((prevWidths) =>
      prevWidths.length >= index
        ? [...prevWidths.slice(0, index), width, ...prevWidths.slice(index + 1)]
        : [...prevWidths, ...map(range(index - prevWidths.length), () => (defaultWidth ? defaultWidth : 0)), width]
    );
  };

  const renderMeasurement = () => (
    <ItemsWidthMeasurer items={items} onMeasure={onItemMeasured} renderItem={renderItem} />
  );

  return [widths.slice(0, items.length), renderMeasurement];
};

import React from 'react';

import styled from 'styled-components';

import { Dialog as DialogComponent } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { POPUP_ZINDEX } from '@style/StyleConstants';
import { useIsMobileView } from '@util/hooks';

interface PopupProps extends DialogProps {
  width: number;
  isMobile: boolean;
}

// augmenting specificity with && to allow styling material-ui component
// z-index to cover everything (side-drawer, top-bar)
const Dialog = styled<PopupProps>(({ isMobile, width, ...rest }) => <DialogComponent {...rest} />)`
  && {
    position: ${(props: PopupProps) => (props.isMobile ? 'fixed' : 'absolute')};
    height: ${(props: PopupProps) => (props.isMobile ? 'unset' : '100%')};
    z-index: ${POPUP_ZINDEX};
  }
`;

/**
 * This is the main popup component. It is context-driven and opens material-ui dialog then we have a popup instance.
 * Used only in PopupProvider*/
export const Popup: React.FC = () => {
  const { popup, closingPopup } = usePopup();
  const isMobile = useIsMobileView();

  if (popup) {
    const { width, body, isDismissible, onBackdropClick, hideBackdrop } = popup;
    const mobileWidth = width === PopupSizes.MENU_SIZE ? PopupSizes.MENU_SIZE : PopupSizes.SMALL;

    const paperStyle = {
      display: 'flex',
      margin: 8,
      width: isMobile ? mobileWidth : width,
      maxWidth: isMobile ? 'unset' : width,
    };
    const handleBackdropClick = () => {
      if (isDismissible) {
        onBackdropClick?.();
        closingPopup();
      }
      return undefined;
    };
    return (
      <Dialog
        open={!!popup}
        disablePortal={!isMobile}
        onBackdropClick={handleBackdropClick}
        isMobile={isMobile}
        width={width}
        PaperProps={{ style: paperStyle }}
        hideBackdrop={hideBackdrop}
      >
        {body}
      </Dialog>
    );
  }
  return null;
};

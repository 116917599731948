import React from 'react';

import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { Color } from '@style/Color';
import { ThemeProps } from '@style/WithTheme';
import { MediaQueries } from '@util/MediaQueries';

interface LoadViewedProps {
  isViewed: boolean;
}

interface CardProps {
  shouldRemoveBottomShadow?: boolean;
  shouldHaveBottomBorder?: boolean;
}

export type LoadCardProps = { active: boolean; isCompact?: boolean; isDeadhead?: boolean } & LoadViewedProps &
  ThemeProps &
  CardProps;

export const getFontWeight = (props: LoadViewedProps) => (props.isViewed ? 'normal' : 'bold');

export const getCardBackground = (props: LoadCardProps) => {
  if (props.isDeadhead) {
    return Color.BLUE_LIGHT_2;
  } else if (props.active) {
    return props.theme.palette.background.dim;
  } else if (props.isViewed) {
    return Color.GHOST_WHITE;
  } else {
    return props.theme.palette.background.paper;
  }
};

export const CardWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: ${(props: CardProps) => (props.shouldRemoveBottomShadow ? null : '8px')};
  flex: 1;
`;

export const Card = styled(
  ({ active, isViewed, isCompact, isDeadhead, shouldRemoveBottomShadow, shouldHaveBottomBorder, ...rest }) => (
    <Button {...rest} />
  )
)`
  && {
    flex: 1;
    padding: 8px 16px;
    min-height: ${(props: LoadCardProps) => (props.isCompact ? 'unset' : '146px')};
    width: 100%;
    border-radius: 0;
    box-shadow: ${(props: LoadCardProps) =>
      props.shouldRemoveBottomShadow ? null : `0 2px 4px 0px ${props.theme.palette.decorator.border2}`};
    border-bottom: ${(props: LoadCardProps) =>
      props.shouldHaveBottomBorder ? `1px solid ${props.theme.palette.decorator.border2}` : null};
    background: ${(props: LoadCardProps) => getCardBackground(props)};
    ${(props: LoadCardProps) =>
      props.active ? `border-bottom: 2px solid ${props.theme.palette.background.accent};` : ''}

    pointer-events: ${(props: LoadCardProps) => (props.isDeadhead ? 'none' : 'unset')};

    > span {
      display: flex;
      align-self: stretch;
      flex-direction: column;
      justify-content: ${(props: LoadCardProps) => (props.isCompact ? 'space-between' : 'space-around')};
      align-items: stretch;
    }

    @media ${MediaQueries.lt_md} {
      width: 100%;
    }
  }
`;

export const Additional = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  text-transform: none;
  line-height: 16px;
`;

export const Hours = styled.span`
  opacity: 0.5;
  margin: 2px 2px 0 0;
`;

export const Miles = styled.span`
  opacity: 0.5;
  text-transform: none;
`;

export const CardContent = styled.div<{ isCompact?: boolean }>`
  display: flex;
  margin: 8px 0px ${(props) => (props.isCompact ? '0px' : '8px')} 19px;
`;

export const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-transform: none;
`;

export const Day = styled.div`
  font-size: 24px;
  font-weight: ${getFontWeight};
  line-height: 25px;
  text-transform: none;
`;

export const DateGray = styled.div`
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  text-transform: none;
`;

export const Location = styled.div`
  font-weight: ${getFontWeight};
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  text-transform: none;
`;

export const Content = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 2;
`;

export const Spacer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2px 12px;

  .circle {
    display: block;
    height: 9px;
    width: 9px;
    border-radius: 10px;
    border: 2px solid gray;
    z-index: 1;
  }

  .square {
    display: block;
    height: 9px;
    width: 9px;
    border: 2px solid ${Color.ORANGE_MAIN};
    z-index: 1;
  }

  .dots {
    position: absolute;
    border-left: 2px dotted gray;
    opacity: 0.5;
    top: 15px;
    bottom: 15px;
    z-index: 0;
    left: calc(50% - 1px);
  }
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: none;
  > span {
    font-size: 12px;
    font-weight: normal;
  }
`;

export const CardChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 4px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

export const Status = styled.div`
  padding: 0 3px;
  text-transform: uppercase;
  color: white;
  margin: 2px 2px 0px 2px;
  height: 14px;
  display: inline-block;
  line-height: 16px;
  border-radius: 2px;
  font-size: 9px;
  font-weight: bold;
  background: ${(props: { color: string }) => props.color};
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

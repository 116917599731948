import { ASPX_ROUTES, Routes } from '@/router/Routes';
import { t, T } from '@translate';

import {
  aspxGenericOnlineMatchASPXRoute,
  aspxGenericRootMatchASPXRoute,
  exactMatchASPXRoute,
  prefixMatchASPXRoute,
  RouteMatch,
} from './AspxRouteTypes';

export const aspxLinks: RouteMatch[] = [
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_alerts_title),
    reactPath: Routes.ALERTS,
    aspxPath: '/online/loadboard/alerts.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_loadPlanner_title),
    reactPath: Routes.LOADS_LOAD_PLANNER,
    aspxPath: '/online/carrier/planner/',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_myCompanies_title),
    reactPath: Routes.LOADS_MY_COMPANIES,
    aspxPath: '/online/loadboard/companies.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_hotLeads_title),
    reactPath: Routes.TOOLS_HOT_LEADS,
    aspxPath: '/online/broker/tools/hotleads.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_creditReport_title),
    reactPath: Routes.RESOURCES_CREDIT_REPORT,
    aspxPath: '/online/carrier/tools/credit-report.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_managerUsers_title),
    reactPath: Routes.MORE_SETTINGS_MANAGE_USERS,
    aspxPath: '/online/account/manageusers.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_paymentOptions_title),
    reactPath: Routes.MORE_SETTINGS_ACTIVATE_BY_CARD,
    aspxPath: '/online/account/billing/payment-options.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_generalSettings_title),
    reactPath: Routes.MORE_SETTINGS_GENERAL_SETTINGS,
    aspxPath: '/online/account/settings/',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_toolsSettings_title),
    reactPath: Routes.MORE_SETTINGS_TOOLS_SETTINGS,
    aspxPath: '/online/account/settings/trucking-tools.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_alertsSettings_title),
    reactPath: Routes.MORE_SETTINGS_ALERTS_SETTINGS,
    aspxPath: '/online/account/settings/alerts.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_companyWebsite_title),
    reactPath: Routes.MORE_SETTINGS_COMPANY_WEBSITE,
    aspxPath: '/online/account/marketing-tools/',
  }),
  prefixMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_help_title),
    reactPath: Routes.MORE_HELP_BASE_ROUTE,
    aspxPath: ASPX_ROUTES.HELP_PREFIX,
    suffixMatch:
      /^(faqs|faqs-answer|glossary|online-training|topics|tutorial|tutorials|tutorialtopics|video|videos|videotopics)/,
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_accountRedir_title),
    reactPath: Routes.MORE_SETTINGS_ACCOUNT_REDIR,
    aspxPath: '/online/account/redirect2message.aspx',
  }),
  exactMatchASPXRoute({
    defaultTitle: t(T.aspxEmbed_accountNotice_title),
    reactPath: Routes.MORE_SETTINGS_ACCOUNT_NOTICE,
    aspxPath: '/online/account/message.aspx',
  }),
  aspxGenericOnlineMatchASPXRoute,
  aspxGenericRootMatchASPXRoute,
];

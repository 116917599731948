import { clone, findIndex, map, reduce } from 'lodash';

import { ApiError } from '@common/api';
import { SearchFilter } from '@common/helper/FilterHelper';
import { addLoadFetchedTime } from '@common/helper/LoadHelper';
import {
  createDefaultLoadSearchRequest,
  createRequestFromLoadSearchSettings,
} from '@common/helper/LoadSearchRequestFactory';
import { IKeysSortByLoads, KeysSortBy } from '@common/helper/SortByKeys';
import {
  EMPTY_ORIGIN_LOCATION,
  GeolocationGroup,
  Load,
  LoadSearchRequest,
  LoadStatus,
  LocationType,
  PersistentLoadSearchDef,
  SimilarLoad,
} from '@common/model';
import { ResultResponse } from '@common/redux/Base';

export const SEARCH_ALERT_LIMIT = 10;

export enum ViewFilter {
  ALL = 'ALL',
  WITH_ALERTS = 'WITH_ALERTS',
  WITHOUT_ALERTS = 'WITHOUT_ALERTS',
}

export enum SearchState {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Failed = 'Failed',
}

export type AlertsResponse = {
  totalAlertCount: number;
  namedSearchAlertCounts: Array<{ namedSearchId: string; alertCount: number }>;
};

export interface UpdateSearchAlertRequest {
  namedSearchId: string;
  hasAlert?: boolean;
}

export interface SearchesResponse {
  namedSearches: PersistentLoadSearchDef[];
  metadata: {
    totalResultCount: number;
  };
}

export interface PersistentSearch {
  id: string;
  search: LoadSearchRequest;
  isLoading?: boolean;
  isUpdatingAlert?: boolean;
  searchName?: string;
  hasAlert?: boolean;
}

export interface NormalizedPersistentSearches {
  ui: string[];
  entities: { [id: string]: PersistentSearch };
}

export interface NormalizedSearchAlertsEntities {
  [id: string]:
    | {
        count: number | undefined;
        isLoading: boolean;
      }
    | undefined;
}

interface NormalizedSearchAlerts {
  totalCount: number;
  entities: NormalizedSearchAlertsEntities;
  isLoading: boolean;
  alertError?: ApiError | undefined;
  isRateLimitingResponseOrNoAlertsSet: boolean;
}

interface NamedSearchesState {
  searches: NormalizedPersistentSearches;
  isLoadingSearches: boolean;
  didSearchesFetchFail?: boolean;
  wasAlertUpdated?: boolean;
  wasSearchCreated?: boolean;
  wasSearchUpdated?: boolean;
  wasSearchDeleted?: boolean;
  error?: ApiError | undefined;
  viewFilter: ViewFilter;
  /** only used on MOB */
  selectedNamedSearch: PersistentSearch | undefined;
}

export interface LoadSearchBaseState {
  showSimilarLoads: boolean;
  loadSearchState: SearchState | undefined;
  isPerformingSearch: boolean;
  isFetchingCount: boolean;
  isCountingSearches: boolean;
  searchCount: number;
  loads: Load[];
  didLoadFetchFail: boolean;
  similarLoads: SimilarLoad[];
  isLastResult: boolean;
  isAutoRefreshing: boolean;
  isLastSimilarResult: boolean;
  lastSearchRequest: LoadSearchRequest;
  listSearchRequest: LoadSearchRequest;
  nextToken: string;
  nextTokenSimilarLoads: string;
  totalLoadsCount: number;
  totalSimilarLoadsCount: number;
  totalLoadsCountSearchForm?: number;
  newLoadIds: string[];
  archivingFlowID: string | undefined;
  loadSearchAlerts: NormalizedSearchAlerts;
  fetchingError?: ApiError | undefined;
  sortBy: IKeysSortByLoads;
  searchFilterDraft: SearchFilter;
  selectedSearch: LoadSearchRequest | undefined;
  searchesState: NamedSearchesState;
}

export const createInitialNormalizedPersistentSearches = (): NormalizedPersistentSearches => ({
  entities: {},
  ui: new Array<string>(),
});

const createInitialSearchesState = (): NamedSearchesState => ({
  searches: createInitialNormalizedPersistentSearches(),
  selectedNamedSearch: undefined,
  isLoadingSearches: false,
  viewFilter: ViewFilter.ALL,
});

export const initialState: LoadSearchBaseState = {
  showSimilarLoads: false,
  loadSearchState: undefined,
  isCountingSearches: false,
  isPerformingSearch: false,
  searchCount: 0,
  isFetchingCount: false,
  isLastResult: false,
  isLastSimilarResult: false,
  isAutoRefreshing: false,
  didLoadFetchFail: false,
  loads: [],
  similarLoads: [],
  totalLoadsCount: 0,
  totalSimilarLoadsCount: 0,
  newLoadIds: [],
  lastSearchRequest: createDefaultLoadSearchRequest(),
  listSearchRequest: createDefaultLoadSearchRequest(),
  sortBy: KeysSortBy[0],
  nextToken: '',
  nextTokenSimilarLoads: '',
  selectedSearch: undefined,
  archivingFlowID: undefined,
  loadSearchAlerts: { totalCount: 0, entities: {}, isLoading: false, isRateLimitingResponseOrNoAlertsSet: false },
  fetchingError: undefined,
  searchFilterDraft: {},
  searchesState: createInitialSearchesState(),
};

export const getLoadIndex = (loads: Load[], loadId: string) => findIndex(loads, (load) => load.id === loadId);

type UpdatedLoads<T> = { currentLoad: Load; loads: T[] } | undefined;

export const updateOnLoadFetched = (loads: Load[], fetchedLoad: Load): UpdatedLoads<Load> => {
  const index = getLoadIndex(loads, fetchedLoad.id);
  if (index < 0) {
    return undefined;
  }
  const newLoads = clone(loads);
  const updatedLoad = updateLoadData(loads[index], fetchedLoad);
  newLoads[index] = updatedLoad;
  return {
    currentLoad: updatedLoad,
    loads: newLoads,
  };
};
export const updateOnLoadError = (loads: Load[], loadID: string): Load[] => {
  const loadIndex = findIndex(loads, (load) => load.id === loadID);
  if (loadIndex < 0) {
    return loads;
  }
  const newLoads = clone(loads);
  newLoads[loadIndex].status = LoadStatus.Deleted;
  return newLoads;
};

export const updateLoadData = (load: Load, updatedLoad: Load, addFetchedTime = true): Load => {
  const selectedLoad = { ...load, ...updatedLoad };
  if (load && load.metadata && load.metadata.userdata) {
    selectedLoad.metadata = { userdata: { ...load.metadata.userdata } };
  }

  // mark the load as viewed.
  if (selectedLoad.metadata === undefined) {
    selectedLoad.metadata = {};
  }
  if (selectedLoad.metadata.userdata === undefined) {
    selectedLoad.metadata.userdata = {
      isViewed: false,
      isGone: false,
      isLowPayRate: false,
      isHidden: false,
      isCalled: false,
      isEmailed: false,
      isMessaged: false,
      isContacted: false,
      isWrongInfo: false,
      isNotInterested: false,
      isSaved: false,
    };
  }
  if (
    updatedLoad.metadata &&
    updatedLoad.metadata.userdata &&
    (updatedLoad.metadata.userdata.note || updatedLoad.metadata.userdata.note === '')
  ) {
    selectedLoad.metadata.userdata.note = updatedLoad.metadata.userdata.note;
  }
  if (updatedLoad.metadata && updatedLoad.metadata.userdata && updatedLoad.metadata.userdata.progress) {
    selectedLoad.metadata.userdata.progress = updatedLoad.metadata.userdata.progress;
  }
  if (updatedLoad.metadata && updatedLoad.metadata.userdata) {
    selectedLoad.metadata.userdata.isSaved = updatedLoad.metadata.userdata.isSaved;
    selectedLoad.metadata.userdata.isCalled = updatedLoad.metadata.userdata.isCalled;
  }
  selectedLoad.metadata.userdata.isViewed = true;
  if (addFetchedTime) {
    return addLoadFetchedTime(Date.now())(selectedLoad);
  }
  return selectedLoad;
};

/**
 * Checks if a search will return valid results, currently only used to check if origin city is valid
 */
export const isValidSearch = (search: LoadSearchRequest): boolean => {
  switch (search.origin.type) {
    case LocationType.CITY:
      return search.origin.city !== EMPTY_ORIGIN_LOCATION.city && search.origin.states !== EMPTY_ORIGIN_LOCATION.states;
    default:
      return true;
  }
};

export const getSetAlertsCount = (searches: NormalizedPersistentSearches) =>
  reduce(searches.entities, (acc, entity) => acc + (entity?.hasAlert ? 1 : 0), 0);

export const convertPersistentSearchFromServer = (search: PersistentLoadSearchDef): PersistentSearch => ({
  id: search.id || '',
  search: createRequestFromLoadSearchSettings(search),
  hasAlert: search.hasAlert,
  searchName: search.searchName,
  isLoading: false,
});

export const normalizePersistentSearches = (searches: PersistentLoadSearchDef[]): NormalizedPersistentSearches => ({
  ...createInitialNormalizedPersistentSearches(),
  entities: reduce(
    searches,
    (accumulator, currentSearch) => {
      if (currentSearch.id) {
        accumulator[currentSearch.id] = convertPersistentSearchFromServer(currentSearch);
      }
      return accumulator;
    },
    {} as { [key: string]: PersistentSearch }
  ),
  ui: map(searches, (search) => search.id || ''),
});

export const isRateLimitingResponseOrNoAlertsSet = (response: ResultResponse<AlertsResponse>): boolean =>
  (!response.success && response.error.httpStatus === 429) ||
  (response.success === true && Object.keys(response.payload.namedSearchAlertCounts).length === 0);

export const getGroupsTotalLoadsCount = (groups: GeolocationGroup[]): number => {
  return reduce(groups, (sum, { count }) => sum + count, 0);
};

import React, { BaseSyntheticEvent, ReactNode, RefObject, useRef } from 'react';

import { map } from 'lodash';
import styled from 'styled-components';

import { PostedLoadProgress } from '@common/model';
import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import {
  defaultBorder,
  errorBorder,
  LockIcon,
  PanelDetails,
  PanelDetailsTransparentNoMargin,
  PanelTitle,
  PanelWrapper,
  RightButton,
  Subtitle,
  Title,
  TitleContainer,
} from '@component/expansionPanel/ExpansionPanelStyle';
import { ActionIcon } from '@component/icon/ActionIcon';
import { PopupTracking, withPopups } from '@component/popup/PopupTrackingContext';
import { useUnlockFeaturePopup } from '@component/popup/UnlockFeaturePopup';
import { getScrollParent, useScrollToView } from '@component/scrollView';
import { FontSize } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { T, t } from '@translate';
import icons from '@util/iconsConstants';

export enum AccordionInfoStatus {
  LOADING,
  AVAILABLE,
  UNAVAILABLE,
  NOT_PERMITTED,
  OFFLINE,
}

interface Props {
  id: string;
  icon?: string;
  iconStyle?: React.CSSProperties;
  titleText?: string;
  titleElement?: React.ReactNode;
  subtitleText?: string;
  customOfflineText?: string;
  subtitleSecondLineText?: string;
  subtitleThirdLineText?: string;
  subtitleBulletPointsText?: (string | undefined)[];
  loadProgress?: PostedLoadProgress;
  isTextSizeBig?: boolean;
  subtitleElement?: React.ReactNode;
  isBlocked?: boolean;
  isOpened: boolean;
  handleClick: () => void;
  renderTitleFavIcon?: () => React.ReactNode;
  status?: AccordionInfoStatus;
  children?: ReactNode;
  rightButtonProps?: {
    id: string;
    title: string;
    onClick: () => void;
  };
  shouldHideRightButton?: boolean;
  hasError?: boolean;
  hasBorder?: boolean;
  hasCustomizedBorder?: boolean;
  popupContext?: PopupTracking;
  headerBackground?: string;
  innerRef?: RefObject<HTMLDivElement>;
  unlockFeaturePopupType?: UnlockFeaturePopupType;
  panelTitleStyle?: React.CSSProperties;
  centerAlignTitleItems?: boolean;
  hasCustomizedBackground?: boolean;
}

const SubtitleAndIcons = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 5px;
  padding-right: 10px;
  justify-content: flex-start;
`;

const TabSpace = styled.span`
  display: inline-block;
  margin-left: 1em;
`;

const Expansion: React.FC<Props> = (props) => {
  const containerRef_ = useRef<HTMLDivElement | null>(null);
  const containerRef = props.innerRef ? props.innerRef : containerRef_;
  const openUnlockPopup = useUnlockFeaturePopup();
  const scrollContainer = getScrollParent(containerRef.current);

  const scrollToView = useScrollToView(scrollContainer);

  const handleClick = (event?: BaseSyntheticEvent) => {
    if (!props.isBlocked) {
      props.handleClick();
    }

    if (!isExpanded()) {
      setTimeout(() => {
        scrollToView(containerRef.current);
      }, 300); // FIXME remove the timeout and use ExpansionPanel's onExited callback when material-ui will be upgraded.
    }
    event?.stopPropagation();
  };

  const renderSubtitle = (): string => {
    if (props.status !== undefined) {
      let subtitleToDisplay: string;
      switch (props.status) {
        case AccordionInfoStatus.NOT_PERMITTED:
          subtitleToDisplay = t(T.common_membership_unlockThisFeature);
          break;
        case AccordionInfoStatus.LOADING:
          subtitleToDisplay = t(T.findLoads_loadDetails_loading);
          break;
        case AccordionInfoStatus.OFFLINE:
          if (props.customOfflineText) {
            subtitleToDisplay = props.customOfflineText;
            break;
          }
          if (props.loadProgress === PostedLoadProgress.Assigned) {
            subtitleToDisplay = t(T.findLoads_loadDetails_unavailableForAssigned);
          } else if (props.loadProgress === PostedLoadProgress.Delivered) {
            subtitleToDisplay = t(T.findLoads_loadDetails_unavailableForDelivered);
          } else {
            subtitleToDisplay = t(T.findLoads_loadDetails_unavailableForOffline);
          }
          break;
        default:
          subtitleToDisplay = props.isBlocked
            ? t(T.findLoads_loadDetails_notAvailable)
            : props.subtitleText
              ? props.subtitleText
              : '';
          break;
      }
      return subtitleToDisplay;
    }
    return props.isBlocked ? t(T.findLoads_loadDetails_notAvailable) : props.subtitleText;
  };

  const isExpanded = () => (props.isBlocked ? false : props.isOpened);

  const rightElement = () => {
    switch (props.status) {
      case AccordionInfoStatus.NOT_PERMITTED:
        return (
          <RightButton id={`${props.id}_unlock`} onClick={() => openUnlockPopup(props.unlockFeaturePopupType)}>
            {t(T.findLoads_loadDetails_unlock)}
          </RightButton>
        );

      case AccordionInfoStatus.OFFLINE:
        return props.rightButtonProps ? (
          <RightButton id={props.rightButtonProps.id} onClick={props.rightButtonProps.onClick}>
            {props.rightButtonProps.title}
          </RightButton>
        ) : null;
      default:
        return panelIcon();
    }
  };

  const panelIcon = () => {
    let panelIcon;
    let panelIconId;
    if (props.children) {
      panelIcon = props.isOpened ? icons.collapse : icons.expand;
      panelIconId = props.isOpened ? `${props.id}_collapse` : `${props.id}_expand`;
    } else {
      panelIcon = icons.chevronRight;
      panelIconId = 'right';
    }
    return !props.isBlocked ? (
      <ActionIcon id={panelIconId} src={panelIcon} alt=">" onClick={handleClick} tabIndex={-1} style={{ padding: 0 }} />
    ) : null;
  };

  const blockedUser =
    props.status === AccordionInfoStatus.NOT_PERMITTED || props.status === AccordionInfoStatus.OFFLINE;
  const headerBackgroundColor = props.headerBackground ?? 'none';

  const PanelDetailsWithChildren = props.hasCustomizedBackground ? PanelDetailsTransparentNoMargin : PanelDetails;

  return (
    <div ref={containerRef}>
      <PanelWrapper
        id={props.id}
        expanded={isExpanded()}
        style={{ background: blockedUser ? THEME.palette.background.orangeSmoke : headerBackgroundColor }}
      >
        <PanelTitle
          onClick={handleClick}
          centerAlignItems={props.centerAlignTitleItems}
          hasError={props.hasError}
          style={{
            ...props.panelTitleStyle,
            borderBottom: props.hasCustomizedBorder ? 'null' : props.hasError ? errorBorder : defaultBorder,
            marginBottom: props.hasCustomizedBorder && props.isOpened ? '-20px' : '0',
          }}
          tabIndex={-1}
        >
          {props.icon ? (
            <img
              style={props.iconStyle}
              id={`${props.id}_icon`}
              src={props.icon}
              className="title-icon"
              alt={'title-icon'}
            />
          ) : null}
          <div className="title-data">
            <TitleContainer>
              {props.titleText ? (
                <Title
                  id={`${props.id}_title`}
                  style={{ fontSize: props.isTextSizeBig ? `${FontSize.MediumHeader}px` : `${FontSize.Content}px` }}
                >
                  {props.titleText} {props.titleElement ?? null}
                </Title>
              ) : null}

              {!props.titleText && props.titleElement ? props.titleElement : null}

              {blockedUser && <LockIcon id={`${props.id}_blocked`} src={icons.lock} alt="lock" />}
            </TitleContainer>
            {props.subtitleText && !props.subtitleElement ? (
              <SubtitleAndIcons style={{ paddingTop: props.isTextSizeBig ? '8px' : '0' }}>
                <Subtitle
                  id={`${props.id}_subtitle`}
                  style={{
                    fontStyle: props.isBlocked ? 'italic' : 'normal',
                    fontSize: props.isTextSizeBig ? `${FontSize.Content}px` : `${FontSize.ContentDetail}px`,
                    lineHeight: '24px',
                  }}
                >
                  {renderSubtitle()}

                  {props.subtitleBulletPointsText
                    ? map(props.subtitleBulletPointsText, (label) => (
                        <>
                          <br />
                          <TabSpace />
                          {t(T.common_bulletPoint)} {label}
                        </>
                      ))
                    : null}

                  {props.subtitleSecondLineText ? (
                    <>
                      <br /> {props.subtitleSecondLineText}
                    </>
                  ) : null}
                  {props.subtitleThirdLineText ? (
                    <>
                      <br /> {props.subtitleThirdLineText}
                    </>
                  ) : null}
                </Subtitle>
                {props.renderTitleFavIcon && props.renderTitleFavIcon()}
              </SubtitleAndIcons>
            ) : null}
            {props.subtitleElement ?? null}
          </div>
          {!props.shouldHideRightButton && rightElement()}
        </PanelTitle>
        {props.children ? <PanelDetailsWithChildren>{props.children}</PanelDetailsWithChildren> : null}
      </PanelWrapper>
    </div>
  );
};

export const ExpansionPanel = withPopups(Expansion);

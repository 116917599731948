import { useCallback, useEffect, useRef } from 'react';

import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { useManualScrollEventHandler } from '@util/hooks';

export const useContentScrollControl = (isBackPanel: boolean) => {
  const isWindowScrollMode = useIsWindowScrollMode();
  const pauseHandling = isBackPanel || !isWindowScrollMode;
  const scrollPosition = useRef(0);

  const onScrollEnd = useCallback(() => {
    if (!pauseHandling) {
      scrollPosition.current = window.pageYOffset;
    }
  }, [scrollPosition, window.pageYOffset, isBackPanel]);

  useManualScrollEventHandler(window, onScrollEnd, pauseHandling);

  useEffect(() => {
    if (!pauseHandling) {
      window.scrollTo(0, scrollPosition.current);
    }
  }, [pauseHandling]);
};

import React from 'react';

import styled from 'styled-components';

import { Loader } from '@component/loader';
import { Panel, PanelSize } from '@component/panel';
import { Color } from '@style/Color';
import { LoaderSize } from '@style/StyleConstants';
import { useWindowHeight } from '@util/hooks';

const FullHeightContainer = styled(({ windowHeight, ...rest }) => <div {...rest} />)`
  display: flex;
  height: ${({ windowHeight }: { windowHeight: number }) => windowHeight}px;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: ${Color.WHITE};
  border-radius: 16px;
`;

export const LoadingFullscreenPanel: React.FC<{ layer?: number }> = (props) => {
  const windowHeight = useWindowHeight();

  return (
    <Panel
      id="downloading"
      offset={0}
      layer={props.layer ?? 2}
      size={PanelSize.full}
      backgroundColor={Color.TRANSPARENT_GRAY_RGBA}
    >
      <FullHeightContainer windowHeight={windowHeight}>
        <LoaderContainer>
          <Loader size={LoaderSize.large} />
        </LoaderContainer>
      </FullHeightContainer>
    </Panel>
  );
};

export interface RoutesSettings {
  unitsSystem?: string;
  prioritizeMileage?: boolean;
  avoidTolls?: boolean;
  hazmatType?: Hazmat;
  truckWidth96InchesOrLess?: boolean;
  truckWidth102InchesOrMore?: boolean;
  axles?: number;
  mapHeight?: number;
  mapWidth?: number;
  bordersOpen?: boolean;
  mpg?: number;
  truckHeight?: number;
  truckLength?: number;
  truckWeight?: number;
}
export enum Hazmat {
  None = 'None',
  General = 'General',
  Caustic = 'Caustic',
  Explosive = 'Explosives',
  Flammable = 'Flammable',
  Inhalants = 'Inhalants',
  Radioactive = 'Radioactive',
}

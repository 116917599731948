import {
  BrokerLoadsSummaryResponseMetadata,
  BulkPatchLoadsFields,
  createDefaultLoadViewersRequest,
  DispatcherDetail,
  LoadPostRequest,
  LoadViewers,
  PostedLoadStatus,
  PostLoadAddress,
  RateCheckRequest,
} from '@/model';
import { BaseClient } from '@common/client/BaseClient';
import {
  LegacyPosterLoadSearchRequest,
  Load,
  PostedLoadProgress,
  PostedLoadSearchResponse,
  PosterLoadSearchPaginationRequest,
  PosterLoadSearchRequest,
  RateCheck,
} from '@common/model';
import { CarrierInfo } from '@common/model/Carrier';
import { EmptyResponse, SearchResponseMetadata } from '@common/redux/Base';

export class PostLoadsClient extends BaseClient {
  getPostedLoadsMetadata$ = (request: LegacyPosterLoadSearchRequest) =>
    this.api.post$<SearchResponseMetadata>('loads/search/poster/metadata', request);

  getBrokerLoadsSummary$ = (includeAllCompanyLoads: boolean | undefined) =>
    this.api.get$<BrokerLoadsSummaryResponseMetadata>('broker/loads/summary', {
      includeAllCompanyLoads: includeAllCompanyLoads,
    });

  //type of "PosterLoadSearchRequest | PosterLoadSearchPaginationRequest" since the request for more pages should be just the NextToken
  getPostedLoads$ = (request: PosterLoadSearchRequest | PosterLoadSearchPaginationRequest) =>
    this.api.post$<PostedLoadSearchResponse>('broker/loads/search', request);

  setLoadStatus$ = (loadId: string, status: string) =>
    this.api.mergePatch$<PostedLoadStatus>(`/loads/${loadId}`, { status: status });
  deletePostedLoad$ = (loadId: string) => this.api.delete$<EmptyResponse>(`/loads/${loadId}`, {});
  refreshLoadAge$ = (loadId: string) => this.api.post$<Load>(`/loads/${loadId}/refresh`, {});
  fetchLoadViewers$ = (loadId: string) =>
    this.api.get$<LoadViewers>(`/loads/${loadId}/viewers`, createDefaultLoadViewersRequest());
  fetchCarrierInfo$ = (contactGUID: string) => this.api.get$<CarrierInfo>(`/contacts/${contactGUID}/carrierInfo`, {});

  fetchDispatcherInfo$ = () => this.api.get$<DispatcherDetail>('/contacts/dispatcher', {});
  fetchTotalMileage$ = (stops: PostLoadAddress[]) =>
    this.api.post$<{ mileage?: number }>('/routes/info', { stops: stops });

  updateLoads$ = (loadIds: string[], patch: Partial<BulkPatchLoadsFields>) =>
    this.api.mergePatch$<EmptyResponse>('/loads', { loadIds: loadIds, patchDocument: patch });
  updateLoadsWithExcludeIds$ = (
    loadIds: string[],
    patch: Partial<BulkPatchLoadsFields>,
    searchQuery: PosterLoadSearchRequest
  ) =>
    this.api.mergePatch$<EmptyResponse>('/loads/filter', {
      excludeIds: loadIds,
      patchDocument: patch,
      searchQuery: searchQuery,
    });

  updateLoadsAge$ = (loadIds: string[]) => this.api.post$<EmptyResponse>('/loads/refresh', { loadIds: loadIds });
  updateLoadsAgeWithExcludeIds$ = (loadIds: string[], searchQuery: PosterLoadSearchRequest) =>
    this.api.post$<EmptyResponse>('/loads/filter/refresh', { excludeIds: loadIds, searchQuery: searchQuery });

  fetchRateCheck$ = (request: RateCheckRequest) => this.api.post$<RateCheck>('/ratechecks', request);

  postLoad$ = (loadPostRequest: LoadPostRequest) => this.api.post$<Load>('/loads', loadPostRequest);
  editLoad$ = (loadPostRequest: LoadPostRequest, id: string) => this.api.put$<Load>(`/loads/${id}`, loadPostRequest);

  setPostedLoadProgress$ = (data: { loadID: string; progress: PostedLoadProgress; dotNo?: number }) => {
    return this.api.mergePatch$<EmptyResponse>(`/broker/loads/${data.loadID}/progress`, {
      status: data.progress,
      dotNo: data.dotNo ?? 0, // endpoint requires 0 for empty
    });
  };
}

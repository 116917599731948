import { intersection, isEqual } from 'lodash';

import { EquipmentSpecification, EquipmentType } from './Equipment';
import { LoadSize } from './Load';
import { LoadSearchSort } from './LoadSearchSort';
import { LocationType } from './Location';
import { CA_PROVINCES, STATES } from './States';

export const MAX_ORIGIN_STATES = 15;

export const INITIAL_SEARCH_LIMIT = 20;
export const SEARCH_LIMIT_APPENDING = 10;

export const INITIAL_LOAD_SEARCH_LIMIT = 50;
export const LOAD_SEARCH_LIMIT_APPENDING = INITIAL_LOAD_SEARCH_LIMIT;

export const DEFAULT_LOAD_SEARCH_RADIUS = 150;
export const DEFAULT_LOAD_SEARCH_FIELDS = 'all';

export enum LoadSearchMileage {
  MIN = 0,
  MAX_SHORT = 200,
  MAX_MEDIUM = 500,
  MAX_REGIONAL = 1000,
}

export enum CompanyFilterType {
  None = 'None',
  Broker = 'Broker',
  Carrier = 'Carrier',
  Shipper = 'Shipper',
  All = 'All',
}

export interface MapBounds {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
}

export interface CompanyFilter {
  types: CompanyFilterType;
  isOnboarded?: boolean;
  name?: string;
  companyIds?: string[];
}

export interface NearbyLoadsSearchRequest {
  truckId: string | undefined;
  geolocation: {
    latitude: number;
    longitude: number;
  };
}

interface SharedLoadSearchFields {
  destination: LoadLocation;
  loadSize?: LoadSize;
  minWeight?: number;
  weight?: number;
  minLength?: number;
  length?: number;
  minMileage?: number;
  maxMileage?: number;
  includeLoadsWithoutLength?: boolean; //if default sent, should be true
  includeLoadsWithoutWeight?: boolean; //if default sent, should be true
  isOnboarded?: boolean;
  company?: CompanyFilter;
  hasRate?: boolean;
  hasCommodity?: boolean;
  hasTeam?: boolean;
  minTransCreditRating?: number;
  maxTransCreditRating?: number;
  minTransCreditDaysToPay?: number;
  maxTransCreditDaysToPay?: number;
  minAge?: number;
  maxAge?: number;
}

export interface BaseLoadSearchRequest extends SharedLoadSearchFields {
  originLatLongBounds?: MapBounds;
  pickupDates: string[];
  equipmentTypes?: EquipmentType[];
  equipmentSpecifications?: string;
  metadata: LoadSearchMetadata;
  saveAsRecentSearch?: boolean;
  saveLocations?: boolean;
  filter?: string;
  hasMessaging?: boolean;
  hasBidding?: boolean;
  status?: string;
  isFavoriteBroker?: boolean;
}

export interface LoadSearchRequest extends BaseLoadSearchRequest {
  id?: string;
  origin: OriginLocation;
}

export interface NamedSearchRequest extends SharedLoadSearchFields {
  hasAlert?: boolean;
  searchName?: string;
  id?: string;
  origin: OriginLocation;
  pickupDates: string[];
  equipmentTypes?: EquipmentType[];
  equipmentSpecifications?: string;
  saveLocations?: boolean;
}

export interface PersistentLoadSearchDef extends SharedLoadSearchFields {
  id?: string;
  hasAlert?: boolean;
  searchName?: string;
  origin: OriginLocation;
  modifiedOn?: string;
  equipmentSpecifications?: string;
  maxAge?: number;
  minAge?: number;
  maxExtraDrops?: number;
  hasTeam?: boolean;
  hasRate?: boolean;
  minMileage?: number;
  maxMileage?: number;
  minOriginRadius?: number;
  isFavoriteBroker?: boolean;
  isOnboarded?: boolean;
  equipmentTypes?: string;
  pickupDates?: string[];
  includeWithGreaterPickupDates?: boolean;
  sortBy: LoadSearchSort;
}

export interface LegacyPosterLoadSearchRequest extends BaseLoadSearchRequest {
  id?: number;
  contactIds?: string[];
  includeStatuses?: string;
  statusFilter?: string;
  origin: LoadLocation;
  deliveryDates?: string[];
  sortBy?: LoadSearchSort;
  fields?: string;
  token?: string;
  includeAllCompanyLoads?: boolean;
}

export interface PosterLoadSearchRequest extends Omit<LegacyPosterLoadSearchRequest, 'equipmentSpecifications'> {
  // BaseLoadSearchRequest and its extensions use a comma separated string for this,
  // but newer types like PosterLoadSearchRequest require an array
  equipmentSpecifications?: EquipmentSpecification[];
}

export interface PosterLoadSearchPaginationRequest {
  token: string;
}

export type HomeLocation = LoadLocationCity | LoadLocationZip | LoadGeolocation;
export enum SearchMetadataType {
  Regular = 'Regular',

  /** Mainly for poll-based auto-refresh.
   * Only the loads that were modified since the last search are returned
   */
  Refresh = 'Refresh',

  /** The following types are for MAP-related searches only */
  Geo = 'Geo',
  GeoPanning = 'GeoPanning',
  MapSelect = 'MapSelect',
}

export interface LoadSearchMetadata {
  offset?: number;
  nextToken?: string;
  limit?: number;
  fields?: string;
  type: SearchMetadataType;
  sortBy?: LoadSearchSort;
}

/** Types allowed for Load Origin  */
export type OriginLocation = LoadLocationStates | LoadGeolocation | LoadLocationZip | LoadLocationCity;

/** Types allowed for Load Destination  */
export type DestLocation = LoadLocation;

/** Discriminated Union with "type" string enum field */
export type LoadLocation =
  | LoadLocationCity
  | LoadLocationStates
  | LoadLocationZip
  | LoadGeolocation
  | LoadLocationAnywhere;

export interface LoadLocationCity {
  type: LocationType.CITY;
  city: string;
  states: string[];
  radius?: number;
  latitude?: number;
  longitude?: number;
}

export interface LoadLocationStates {
  type: LocationType.STATE;
  states: string[];
  radius?: number;
  city?: string;
}

export interface LoadLocationZip {
  type: LocationType.ZIP;
  zipCode: string;
  city: string;
  states: string[];
  radius?: number;
  latitude?: number;
  longitude?: number;
}

export interface LoadLocationAnywhere {
  type: LocationType.ANY | LocationType.ANY_CA | LocationType.ANY_US;
  radius?: number;
}

export interface LoadGeolocation {
  type: LocationType.GEOLOCATION;
  latitude: number;
  longitude: number;
  radius?: number;
  /** from geocoding */
  formattedAddress?: string;
  city?: string;
  states?: string[];
}

/* --------------------------------------------
Here are typical payloads for load locations
as a reference:

GEOLOCATION EXAMPLE (i.e. CURRENT LOCATION):
{
  "city":"Montréal",
  "latitude":45.5008672,
  "longitude":-73.5589807,
  "radius":10.0,
  "states":[
     "QC"
  ],
  "type":"GeoLocation",
},

ZIP CODE EXAMPLE:
{
    "city":"Washington",
    "radius":150.0,
    "states":[
      "DC"
    ],
    "type":"ZipCode",
    "zipCode":"20202"
},
----------------------------------------------
*/

export const EMPTY_ORIGIN_LOCATION: LoadLocationCity = {
  type: LocationType.CITY,
  city: '',
  states: [],
};

export const ANYWHERE_LOCATION: LoadLocation = {
  type: LocationType.ANY,
};

export const supportsRadius = (location: LoadLocation) => {
  if (!location) {
    return false;
  }
  switch (location.type) {
    case LocationType.CITY:
    case LocationType.GEOLOCATION:
    case LocationType.ZIP:
      return true;
  }
  return false;
};

export const supportsTripLength = (location: LoadLocation) => {
  if (!location) {
    return false;
  }
  // only have tripLength when destination is "Anywhere*".
  switch (location.type) {
    case LocationType.ANY:
    case LocationType.ANY_CA:
    case LocationType.ANY_US:
      return true;
    case LocationType.STATE:
      // synthetic 'Everywhere*' by selecting all states
      if (
        allStatesSelected(location) ||
        allAndOnlyCanadaProvincesSelected(location) ||
        allAndOnlyUsaStatesSelected(location)
      ) {
        return true;
      }
  }
  return false;
};

export const allStatesSelected = (location: LoadLocationStates) =>
  location.states.length === CA_PROVINCES.length + STATES.length;

export const allAndOnlyCanadaProvincesSelected = (location: LoadLocationStates) => {
  return (
    location.states.length === CA_PROVINCES.length &&
    intersection(location.states, CA_PROVINCES).length === CA_PROVINCES.length
  );
};

export const allAndOnlyUsaStatesSelected = (location: LoadLocationStates) => {
  return location.states.length === STATES.length && intersection(location.states, STATES).length === STATES.length;
};

export const isEmptyOriginLocation = (location: OriginLocation) => isEqual(location, EMPTY_ORIGIN_LOCATION);

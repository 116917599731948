import { stateBboxArray } from '@component/simplemap/StateBboxArray';
import { statePaths } from '@component/simplemap/StatePaths';

/* Map path and default settings - you can edit this */

export const SIMPLEMAPS_MAPINFO = {
  map_name: 'na_findloads',
  initial_view: {
    x: -20,
    y: -10,
    x2: 980,
    y2: 506,
  },
  state_bbox_array: stateBboxArray,
  paths: statePaths,
  names: {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    VI: 'Virgin Islands',
    GU: 'Guam',
    PR: 'Puerto Rico',
    MP: 'Northern Mariana Islands',
    AS: 'American Samoa',
  },
  default_regions: {},
  default_labels: {},
  proj: 'lambert',
  proj_rules: [
    {
      condition: 'lat>50 && lng<-125',
      points: [3, 4, 5],
    },
    {
      condition: 'lat<18.5 && lat>17 && lng<-65 && lng>-68',
      points: [9, 10, 11],
    },
    {
      condition: 'lat<24 && lat>17 && lng<-154 && lng>-160',
      points: [6, 7, 8],
    },
  ],
  proj_coordinates: [
    {
      lat: 45.995417,
      lng: -116.915987,
      x: 171.83415,
      y: 90.850238,
    },
    {
      lat: 31.000691,
      lng: -85.002362,
      x: 692.14036,
      y: 444.326,
    },
    {
      lat: 42.05,
      lng: -73.487,
      x: 847.59791,
      y: 171.53721,
    },
    {
      lat: 68.88,
      lng: -166.217,
      x: 70.739,
      y: 450.663,
    },
    {
      lat: 60.306316,
      lng: -141.001862,
      x: 155.698,
      y: 539.706,
    },
    {
      lat: 69.646,
      lng: -141.001,
      x: 155.42539,
      y: 455.01987,
    },
    {
      lat: 21.574919,
      lng: -158.281348,
      x: 274.25329,
      y: 526.50555,
    },
    {
      lat: 18.91,
      lng: -155.681,
      x: 328.69953,
      y: 585.08864,
    },
    {
      lat: 20.634391,
      lng: -156.451664,
      x: 311.812,
      y: 546.523,
    },
    {
      lat: 17.952911638835076,
      lng: -67.80315508659565,
      x: 568.50688,
      y: 530.25711,
    },
    {
      lat: 18.19285310206456,
      lng: -66.45655677324636,
      x: 613.64754,
      y: 523.56889,
    },
    {
      lat: 18.492779931101417,
      lng: -65.37927812256693,
      x: 649.66308,
      y: 513.96978,
    },
  ],
};

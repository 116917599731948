import { isNil } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { filter, flatMap as flatMap$, map as map$ } from 'rxjs/operators';

import { safeGetItem, safeSetItem } from '@/util/LocalDataStorage';
import { Api, ApiResponse123 } from '@common/api';
import { StorageKey } from '@common/model';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';

import { AppVersion } from '../util/AppVersionHelper';
import { MembersClient } from './MembersClient';

const CHECK_APP_VERSION = 'CHECK_APP_VERSION';
const GET_SERVER_TIME = 'GET_SERVER_TIME';
const SERVER_TIME_RESPONSE = 'SERVER_TIME_RESPONSE';

export const checkAppVersion = () => ({ type: CHECK_APP_VERSION });
export const fetchServerTime = () => ({ type: GET_SERVER_TIME });

interface ServerTimeResponseAction extends Action {
  serverTime: number;
}

export interface MembersInfoState {
  currentYear: string;
}
export const membersInfoReducer = (
  state: MembersInfoState = { currentYear: yearFromTimeStamp(Date.now()) },
  action: Action
): MembersInfoState => {
  switch (action.type) {
    case SERVER_TIME_RESPONSE: {
      const responseAction = action as ServerTimeResponseAction;
      const year = yearFromTimeStamp(responseAction.serverTime);

      return { ...state, currentYear: year };
    }
  }
  return state;
};

export const getServerTimeEpic$ = (client: MembersClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    GET_SERVER_TIME,
    () => client.getServerTime$(),
    (response): ServerTimeResponseAction => ({
      type: SERVER_TIME_RESPONSE,
      serverTime: response.payload ? response.payload.time : 0,
    })
  );
};

const checkVersionEpic$ = (client: MembersClient, action$: ActionsObservable<Action>) =>
  action$.ofType(CHECK_APP_VERSION).pipe(
    flatMap$(() => {
      //this is a fallback to avoid getting stuck in a infinite reload cycle case we have any issue with the nodejs server.
      if (hasReloadedRecently()) {
        setReloadingFlag(false);
        return of$(undefined);
      }
      return client.checkAppVersion$(AppVersion).pipe(
        map$((response: ApiResponse123<{}>) => {
          if (!response.success && response.httpStatus === 417) {
            setReloadingFlag(true);
            window.location.reload();
          }
          return undefined;
        })
      );
    }),
    filter((action) => !isNil(action))
  );

export const createMembersEpic = (api: Api) => {
  const client = new MembersClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(checkVersionEpic$(client, action$), getServerTimeEpic$(client, action$));
};

const setReloadingFlag = (hasReloaded: boolean) => {
  safeSetItem(StorageKey.MembersReloadingFlag, JSON.stringify({ hasReloaded: hasReloaded }));
};

const hasReloadedRecently = () => {
  try {
    const item = safeGetItem(StorageKey.MembersReloadingFlag);
    if (!item) {
      return false;
    }
    const parsedItem = JSON.parse(item);
    return parsedItem.hasReloaded;
  } catch (exception) {
    return false;
  }
};

const yearFromTimeStamp = (timestamp: number) => {
  let validTimestap = timestamp;
  if (timestamp <= 0) {
    validTimestap = Date.now();
  }
  return `${new Date(validTimestap).getFullYear()}`;
};

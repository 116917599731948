import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import '@component/simplemap/raphael.js';
import { Region, SimpleMapsCoordinates, SimpleMapsData, SimpleMapsInfo } from '@util/helper/SimpleMapsHelper';
import { useIsMobileView } from '@util/hooks';

import { simplemaps } from './simplemaps';

const SIMPLE_MAP_NAME = 'simplemaps_namap';

const SimpleMapHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const SimpleMapAvailableSpace = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${({ padding }: { padding: number }) => `${padding}px ${padding}px 0 ${padding}px`};
`;

const SimpleMapsPreloader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background: ${(props: { backgroundColor: string | undefined }) => props.backgroundColor ?? 'inherit'};
  height: 100%;
  width: 100%;
  z-index: 100;
`;

interface Props {
  id: string;
  panelWidth: number;
  initialView: SimpleMapsCoordinates;
  selectedRegion: Region;
  isSimpleMapsReadyToRender: boolean;
  setIsSimpleMapsReadyToRender: (isReady: boolean) => void;
  backgroundColor?: string;
  isCarrierHistoryMap?: boolean;
  isPanelSingleColumn?: boolean;
}

export const SimpleMap: React.FC<Props> = ({
  id,
  selectedRegion,
  panelWidth,
  initialView,
  isSimpleMapsReadyToRender,
  setIsSimpleMapsReadyToRender,
  backgroundColor,
  isCarrierHistoryMap,
  isPanelSingleColumn,
  children,
}) => {
  const [computedMapWidth, setComputedMapWidth] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);
  const isMobileView = useIsMobileView();
  const simpleMapsHolderRef = useRef<HTMLDivElement>(null);
  const simpleMapsChildrenRef = useRef<HTMLDivElement>(null);
  const isWidthSmall = isPanelSingleColumn || isMobileView;
  const padding = isWidthSmall || isCarrierHistoryMap ? 10 : 20;

  // sometimes computed width of panel is wider that width of panel, in this case we use panel width size and subtract left and right padding
  const mapWidth = useMemo(
    () => (panelWidth && computedMapWidth > panelWidth ? panelWidth - 2 * padding : computedMapWidth),
    [computedMapWidth, panelWidth, padding]
  );

  useLayoutEffect(() => {
    if (simpleMapsHolderRef.current && simpleMapsChildrenRef.current) {
      //compute free height to fit the map (difference between full height and height of children (footer))
      setMapHeight(simpleMapsHolderRef.current.clientHeight - simpleMapsChildrenRef.current.clientHeight - padding);
    }
  }, [simpleMapsHolderRef?.current?.clientHeight, simpleMapsChildrenRef?.current?.clientHeight, children]);

  useEffect(() => {
    //compute mapWidth according to available map height and simple maps settings (initial view)
    setIsSimpleMapsReadyToRender(false);
    setComputedMapWidth(getMapWidthToFitThePanel(initialView, mapHeight));
  }, [initialView.x, initialView.x2, initialView.y, initialView.y2, mapHeight]);

  useEffect(() => {
    setComputedMapWidth(0);
    setIsSimpleMapsReadyToRender(false);
  }, [selectedRegion]);

  useEffect(() => {
    if (computedMapWidth > 0) {
      setIsSimpleMapsReadyToRender(true);
    }
  }, [computedMapWidth]);

  return (
    <SimpleMapHolder innerRef={simpleMapsHolderRef}>
      <SimpleMapAvailableSpace padding={padding}>
        <div
          style={{
            minHeight: isWidthSmall ? 100 : 0,
            width: isWidthSmall ? '100%' : mapWidth,
            marginLeft: isCarrierHistoryMap && !isWidthSmall ? ' -102px' : '0',
          }}
        >
          <div id={id} style={{ height: '100%' }} />
        </div>
      </SimpleMapAvailableSpace>
      <div id="simple_maps_children" ref={simpleMapsChildrenRef}>
        {children}
      </div>
      {!isSimpleMapsReadyToRender ? <SimpleMapsPreloader backgroundColor={backgroundColor} /> : null}
    </SimpleMapHolder>
  );
};

export const useSimpleMaps = (simpleMapsInitialData: any, simpleMapsInitialInfo: any) => {
  const [isSimpleMapReadyToRender, setIsSimpleMapsReadyToRender] = useState(false);
  const simpleMapsData = useMemo(() => cloneDeep(simpleMapsInitialData), []);
  const simpleMapsInfo = useMemo(() => cloneDeep(simpleMapsInitialInfo), []);

  const init = (simpleMapsDataSettings: SimpleMapsData, simpleMapsInfo: SimpleMapsInfo) => {
    (window as any)[`${SIMPLE_MAP_NAME}_mapinfo`] = simpleMapsInfo;
    (window as any)[`${SIMPLE_MAP_NAME}_mapdata`] = simpleMapsDataSettings;
    simplemaps();
  };
  return {
    simpleMapApi: () => (window as any)[SIMPLE_MAP_NAME],
    simpleMapInit: init,
    simpleMapsData: simpleMapsData,
    simpleMapsInfo: simpleMapsInfo,
    isSimpleMapReadyToRender: isSimpleMapReadyToRender,
    setIsSimpleMapsReadyToRender: setIsSimpleMapsReadyToRender,
  };
};

const getMapWidthToFitThePanel = (initialView: SimpleMapsCoordinates, availableHeight: number) => {
  const originalWidth = initialView.x2 - initialView.x;
  const originalHeight = initialView.y2 - initialView.y;
  const widthToHeight = originalWidth / originalHeight;
  return availableHeight * widthToHeight;
};

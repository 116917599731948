import React, { Component } from 'react';

import { lowerCase, map } from 'lodash';

import { CountriesWithAnywhere } from '@common/helper/LocationPickerHelper';

import { StyledButton, StyledWrapper } from './CountryRadioGroupStyle';

interface Country {
  name: string;
  id: CountriesWithAnywhere;
}

interface ComponentProps {
  countries: Country[];
  handleChange?(value: CountriesWithAnywhere): void;
}

class CountryRadioGroup extends Component<ComponentProps> {
  handleClick = (value: CountriesWithAnywhere) => () => {
    if (this.props.handleChange) {
      this.props.handleChange(value);
    }
  };
  render() {
    return (
      <StyledWrapper>
        {map(this.props.countries, (country) => (
          <StyledButton
            id={lowerCase(country.id)}
            key={country.name}
            color={'secondary'}
            onClick={this.handleClick(country.id)}
          >
            {country.name}
          </StyledButton>
        ))}
      </StyledWrapper>
    );
  }
}

export default CountryRadioGroup;

import { reduce, union, values } from 'lodash';

export enum LoadSearchType {
  LoadSearch,
  Backhaul,
  CompanySearch,
  Posted,
  LoadAvailability,
}

export const createEpics = <TReducer>(
  reducerKeys: { [key: string]: string },
  createReducer: (reducerKey: string) => TReducer
) => {
  const reducers = reduce<string, { [key: string]: TReducer }>(
    union(values(reducerKeys)),
    (acc, reducerKey) => ({ ...acc, [reducerKey]: createReducer(reducerKey) }),
    {}
  );
  return reduce<typeof reducerKeys, { [key: string]: TReducer }>(
    reducerKeys,
    (acc, reducerKey, key) => ({ ...acc, [key]: reducers[reducerKey] }),
    {}
  );
};

import React from 'react';

import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { ExpandableSections, setExpandedSection } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { StoreState } from '@/reduxStore/Store';
import { displayCurrencyOrDash } from '@common/formatter';
import { isEquipmentTypeSupportedForRateCheck } from '@common/helper';
import { Load, RateCheck } from '@common/model';
import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import { defaultLoadInfo, LoadInfo } from '@common/redux/epic/LoadInfoEpic';
import { AccordionInfoStatus, ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { Subtitle } from '@component/expansionPanel/ExpansionPanelStyle';
import { RateCheckHeader } from '@component/panels/basePanel/loadDetails/marketRates/RateCheckHeader';
import { RateUnavailablePopup } from '@component/panels/basePanel/loadDetails/marketRates/RateUnavailablePopup';
import { default as RateCheckIcon } from '@component/panels/basePanel/loadDetails/marketRates/rc-logo.svg';
import { ExpansionPanelDataWrapper } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { PopupSizes, PopupTracking, withPopups } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { t, T } from '@translate';

interface ComponentProps {
  loadInfo: LoadInfo | undefined;
  load: Load | undefined;
  isPermitted: boolean;
  popupContext?: PopupTracking;
}

interface ReduxProps {
  rateCheck: RateCheck | undefined;
  status: AccordionInfoStatus;
  subtitle: string;
  isExpanded: boolean;
  isEquipmentTypeSupported: boolean;
}

interface DispatchProps {
  handleChange: (value: boolean) => void;
}

type Props = ReduxProps & ComponentProps & DispatchProps;

class RateCheckComponent extends React.Component<Props> {
  handleTabClick = () => {
    if (this.props.status === AccordionInfoStatus.UNAVAILABLE) {
      if (this.props.isEquipmentTypeSupported) {
        this.showInfoPopup();
      }
      return;
    }
    this.props.handleChange(!this.props.isExpanded);
  };

  showInfoPopup = () => {
    if (this.props.popupContext) {
      this.props.popupContext.openingPopup({
        body: <RateUnavailablePopup closingPopup={this.props.popupContext.closingPopup} />,
        isDismissible: true,
        hasCloseButton: true,
        width: PopupSizes.SMALL,
      });
    }
  };

  render() {
    const { load, rateCheck, status } = this.props;
    let subtitle = this.props.subtitle;

    if (load && rateCheck) {
      subtitle = displayCurrencyOrDash(rateCheck.avgRatePerMile, true, {
        suffix: t(T.rateCheck_Average_Rate),
      });
    }

    return (
      <ExpansionPanel
        id="rate_check"
        titleText={t(T.rateCheck_Rate_Check)}
        subtitleElement={
          <Subtitle
            id="rate_check_subtitle"
            style={{ fontStyle: status === AccordionInfoStatus.AVAILABLE ? 'normal' : 'italic' }}
          >
            {subtitle}
          </Subtitle>
        }
        icon={RateCheckIcon}
        isOpened={this.props.isExpanded}
        status={status}
        handleClick={this.handleTabClick}
        unlockFeaturePopupType={UnlockFeaturePopupType.RateCheck}
        headerBackground={this.props.isEquipmentTypeSupported ? Color.GRAY_PANEL : Color.GRAY_LIGHT}
        iconStyle={{ height: 50, width: 50, marginRight: 10 }}
        shouldHideRightButton={status === AccordionInfoStatus.UNAVAILABLE}
        panelTitleStyle={{ height: 40, borderTop: `1px solid ${Color.GRAY_SMOKE}` }}
        centerAlignTitleItems={true}
      >
        <ExpansionPanelDataWrapper>
          {rateCheck && load ? (
            <>
              <RateCheckHeader
                loadDetails={load}
                rateCheck={rateCheck}
                loadID={load.id}
                isOpened={this.props.isExpanded}
              />
            </>
          ) : null}
        </ExpansionPanelDataWrapper>
      </ExpansionPanel>
    );
  }
}

const mapStateToProps = (state: StoreState, props: ComponentProps): ReduxProps => {
  let status: AccordionInfoStatus = AccordionInfoStatus.AVAILABLE;
  const loadInfo = props.load ? state.loadInfo.loadInfoMap.get(props.load.id) : defaultLoadInfo;
  const isEquipmentTypeSupported = isEquipmentTypeSupportedForRateCheck(props.load?.equipments);
  let subtitle = '';

  if (!isEquipmentTypeSupported) {
    status = AccordionInfoStatus.UNAVAILABLE;
    subtitle = t(T.common_marketRate_notAvailableTrailer);
  } else {
    if (!props.isPermitted) {
      status = AccordionInfoStatus.NOT_PERMITTED;
      subtitle = t(T.common_membership_unlockThisFeature);
    } else {
      if (loadInfo && loadInfo.isLoadingRateCheck) {
        status = AccordionInfoStatus.LOADING;
        subtitle = t(T.findLoads_loadDetails_loading);
      } else if ((loadInfo && !loadInfo.rateCheck) || (loadInfo && loadInfo.rateCheck && !loadInfo.rateCheck.payload)) {
        status = AccordionInfoStatus.UNAVAILABLE;
        subtitle = t(T.common_marketRate_notAvailableAtThisTime);
      }
    }
  }
  return {
    rateCheck: loadInfo && loadInfo.rateCheck ? loadInfo.rateCheck.payload : undefined,
    status: status,
    subtitle: subtitle,
    isExpanded: state.applicationSettings.expandedSections[ExpandableSections.RateCheck],
    isEquipmentTypeSupported: isEquipmentTypeSupported,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      handleChange: (value: boolean) => setExpandedSection(ExpandableSections.RateCheck, value),
    },
    dispatch
  );

export const RateCheckPanel = connect(mapStateToProps, mapDispatchToProps)(withPopups(RateCheckComponent));

import React from 'react';

import { default as BaseReCapcha } from 'react-recaptcha';
import styled from 'styled-components';

import { AppConfig } from '@util/AppConfig';

const ELEMENT_ID = 'g-recaptcha';

export enum SiteKeyMapping {
  FuelCard = 'FuelCard',
  Factoring = 'Factoring',
  RoadsideAssist = 'RoadsideAssist',
  TruckingAuthority = 'TruckingAuthority',
}

const StyledContainer = styled.div`
  margin: 16px 0 !important;
  margin-bottom: 16px !important;
`;

interface Props {
  onValidation: () => void;
  onExpired: () => void;
  theme?: 'light' | 'dark';
  type?: string;
  size?: 'invisible' | 'compact' | 'normal';
  tabindex?: string;
  siteKeyName: SiteKeyMapping;
}

// hl should be current language
export const Recaptcha: React.SFC<Props> = (props) => {
  const validationCallback = () => {
    props.onValidation();
  };
  return (
    <StyledContainer>
      <BaseReCapcha
        elementID={ELEMENT_ID}
        sitekey={AppConfig.LBConfig.RecaptchaSiteKey[SiteKeyMapping[props.siteKeyName]]}
        verifyCallback={validationCallback}
        expiredCallback={props.onExpired}
        theme={props.theme}
        type={props.type}
        size={props.size}
        tabindex={props.tabindex}
        hl="en"
      />
    </StyledContainer>
  );
};

Recaptcha.defaultProps = {
  theme: 'light',
  type: 'image',
  size: 'normal',
  tabindex: '0',
};

import styled from 'styled-components';

import { ThemeProps, withTheme } from '@style/WithTheme';

export const SectionTitle = withTheme()(styled.label`
  font-family: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.fontSize};
  line-height: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.lineHeight};
  color: ${(props: ThemeProps) => props.theme.palette.background.stone};
`);

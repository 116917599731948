import { memberApi } from '@/reduxStore/RootEpic';
import { Api } from '@common/api';
import { ClientType, CommunicationService } from '@common/socket/Communication';
import { createDependencyManager } from '@common/util/DependencyManager';

interface Dependencies {
  authenticatedAPI: Api;
  communicationService: CommunicationService;
}

export const appDependencies: Dependencies = {
  authenticatedAPI: memberApi,
  communicationService: new CommunicationService(memberApi, ClientType.Web, false),
};

const dependencyManager = createDependencyManager<Dependencies>();
export const DependencyProvider = dependencyManager.provider;

export const useCommunicationService = () => {
  return dependencyManager.hook().communicationService;
};

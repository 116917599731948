import React from 'react';

import { isUndefined } from 'lodash';

import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { useClientHeight } from '@component/scrollView';
import { useYOffsetAfterScroll } from '@util/hooks';

interface Scrollable {
  offsetTop?: number;
  offsetLeft?: number;
  scrollIntoView: (arg?: boolean | ScrollIntoViewOptions) => void;
}

interface ScrollableContainer {
  scrollTop: number;
  scrollTo(options?: ScrollToOptions): void;
}

export type AlignTo = 'start' | 'end';

// Detect browsers that do not support smooth scrolling (Safari, Edge)
const isScrollIntoViewOptionsAvailable = 'scrollBehavior' in document.documentElement.style;

export const scrollToTop = (
  scrollRef: React.RefObject<HTMLElement>,
  isWindowScrollMode: boolean,
  behavior: ScrollBehavior = 'smooth'
) =>
  isWindowScrollMode
    ? scrollWindowToTop(behavior)
    : scrollRef.current
      ? scrollViewContentToTop(scrollRef.current, behavior)
      : undefined;

export const useScrollToTop = () => {
  const isWindowScrollMode = useIsWindowScrollMode();
  return (scrollRef: React.RefObject<HTMLElement>, behavior: ScrollBehavior = 'smooth') =>
    scrollToTop(scrollRef, isWindowScrollMode, behavior);
};

export const scrollIntoView = (
  element: Scrollable | undefined | null,
  isWindowScrollMode: boolean,
  alignTo: AlignTo = 'start',
  behavior: ScrollBehavior = 'smooth',
  scrollOffset?: number
) =>
  element
    ? isWindowScrollMode
      ? scrollWindowIntoView(element, behavior, scrollOffset)
      : scrollParentViewIntoView(element, alignTo, behavior)
    : undefined;

const scrollWindowToTop = (behavior: ScrollBehavior) => scrollWindowTo(0, behavior);

const scrollWindowIntoView = (element: Scrollable, behavior: ScrollBehavior, scrollOffset?: number) =>
  scrollWindowTo((element.offsetTop ?? 0) + (scrollOffset ?? 0), behavior);

export const scrollWindowTo = (offsetTop: number, behavior: ScrollBehavior) =>
  window.scroll({
    top: offsetTop,
    left: 0,
    behavior: behavior,
  });

const scrollParentViewIntoView = (element: Scrollable, alignTo: AlignTo, behavior: ScrollBehavior) =>
  !isScrollIntoViewOptionsAvailable && behavior === 'smooth' && alignTo === 'end'
    ? element.scrollIntoView(false)
    : element.scrollIntoView({ block: alignTo, behavior: behavior });

const scrollViewContentToTop = (element: ScrollableContainer, behavior: ScrollBehavior) =>
  scrollViewContentTo(element, 0, behavior);

const scrollViewContentTo = (element: ScrollableContainer, offsetTop: number, behavior: ScrollBehavior) => {
  if (Object.prototype.hasOwnProperty.call(element, 'scrollTo')) {
    element.scrollTo({ top: offsetTop, left: 0, behavior: behavior });
  } else {
    // Workaround to scroll to the top for Edge and other browsers that don't support scrollTo
    element.scrollTop = offsetTop;
  }
};

const delayedScrollIntoView: typeof scrollIntoView = (...args) => {
  setTimeout(() => scrollIntoView(...args), 100);
  // Hack to fix a race condition where the first call doesn't work because there is a scroll
  // event from the keyboard being focused or blurred
  setTimeout(() => scrollIntoView(...args), 500);
};

const useScrollIntoViewPrivate = (scrollIntoViewFunction: typeof scrollIntoView) => () => {
  const isWindowScrollMode = useIsWindowScrollMode();
  return (
    element: Scrollable | undefined | null,
    alignTo: AlignTo = 'start',
    behavior: ScrollBehavior = 'smooth',
    scrollOffset?: number
  ) => scrollIntoViewFunction(element, isWindowScrollMode, alignTo, behavior, scrollOffset);
};

export const useScrollIntoView = useScrollIntoViewPrivate(scrollIntoView);

/**
 * To trigger scrolling after default scrolling events
 */
export const useDelayedScrollIntoView = useScrollIntoViewPrivate(delayedScrollIntoView);

export const useScrollToView = (scrollContainer: HTMLElement | null) => {
  const scrollYOffset = useYOffsetAfterScroll(scrollContainer);
  const clientHeight = useClientHeight(scrollContainer);
  const isWindowScrollMode = useIsWindowScrollMode();
  return (element: HTMLElement | undefined | null) => {
    const height = element?.offsetHeight;
    const offsetTop = element?.offsetTop;
    if (!element || !scrollContainer || isUndefined(height) || isUndefined(clientHeight) || isUndefined(offsetTop)) {
      return;
    }
    if (height >= clientHeight || scrollYOffset > offsetTop) {
      scrollIntoView(element, isWindowScrollMode, 'start');
      return;
    }
    if (scrollYOffset + clientHeight >= offsetTop + height) {
      return;
    }
    if (isWindowScrollMode) {
      scrollWindowTo(offsetTop, 'smooth');
      return;
    }
    const scrollTop = offsetTop - (clientHeight - height) / 2;
    scrollViewContentTo(scrollContainer, scrollTop, 'smooth');
  };
};

// https://stackoverflow.com/questions/35939886/find-first-scrollable-parent

export const getScrollParent = (element: HTMLElement | undefined | null): HTMLElement | null => {
  const overflowY = element && window.getComputedStyle(element).overflowY;
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
  if (!element) {
    return null;
  } else if (isScrollable && element.scrollHeight >= element.clientHeight) {
    return element;
  }
  return getScrollParent(element.parentElement) || document.body;
};

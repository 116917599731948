export enum ProfitCalculatorSubMenu {
  tripIsExpanded = 'tripIsExpanded',
  estimatedFuelCostsIsExpanded = 'estimatedFuelCostsIsExpanded',
  additionalCostsIsExpanded = 'additionalCostsIsExpanded',
}

export interface ProfitCalculatorSubMenuSetting {
  [ProfitCalculatorSubMenu.tripIsExpanded]: boolean;
  [ProfitCalculatorSubMenu.estimatedFuelCostsIsExpanded]: boolean;
  [ProfitCalculatorSubMenu.additionalCostsIsExpanded]: boolean;
}

import React from 'react';

import styled from 'styled-components';

import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';

//augmenting specificity with && to allow styling material-ui component
const MultiLineTextField: React.ComponentType = withTheme()(styled<ThemeProps & TextFieldProps>((props) => (
  <TextField {...props} />
))`
  && {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid ${Color.GRAY_SMOKE};
    background-color: ${Color.WHITE};
    & > div {
      padding: 0;
    }
    & textarea {
      box-sizing: border-box;
      padding: ${Spacing.ElementPaddingHorizontal}px;
    }
`);

export const MultilineTextField: React.FC<TextFieldProps> = (props) => (
  <MultiLineTextField fullWidth={true} multiline={true} {...props} />
);

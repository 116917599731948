import React, { CSSProperties } from 'react';

import styled from 'styled-components';

import { usePatchedPushPanel } from '@component/panel/PanelHooks';
import { useSelector } from '@util/hooks';

import { AvatarTextWrapper, InitialsBubble } from './ConversationStyles';

const ImageViewer = styled.img`
  display: flex;
  justify-content: center;
  align-self: flex-end;
  border-radius: 100px;
  max-width: 34px;
  max-height: 34px;
  height: 34px;
  width: 34px;
  margin-right: 8px;
  margin-bottom: 3px;
  cursor: pointer;
`;

const UserAvatarViewer = styled(ImageViewer)`
  border-radius: 20px;
  background-color: white;
`;

export const ConversationUserBubble: React.FC<{
  style?: CSSProperties;
  initials: string;
  userID?: string;
  isFromCurrentUser?: boolean;
}> = (props) => {
  const pushUserContactPanel = usePatchedPushPanel('/usercontact/', '/details/');

  const userAvatar = useSelector(
    (state) => state.avatar.companiesProfileAvatarMap.get(props.userID ?? '')?.companiesProfileAvatar
  );

  const onClickAvatar = () => {
    if (props.userID) {
      pushUserContactPanel(props.userID);
    }
  };

  return (
    <>
      {userAvatar ? (
        <UserAvatarViewer src={userAvatar} onClick={onClickAvatar} />
      ) : (
        <InitialsBubble
          style={{
            ...props.style,
            alignSelf: props.userID && !props.isFromCurrentUser ? 'flex-end' : 'auto',
          }}
          onClick={onClickAvatar}
        >
          <AvatarTextWrapper>{props.initials}</AvatarTextWrapper>
        </InitialsBubble>
      )}
    </>
  );
};

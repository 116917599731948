import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { getSetAlertsCount } from '@common/redux/epic/loadSearch/HelperFunctions';
import { fetchLoadAlerts } from '@common/redux/epic/loadSearch/LoadSearchEpic';
import { useSelector } from '@util/hooks';

import { useAutoRefreshManager, useStartAutoRefresh } from './AutoRefreshManager';

const LOAD_ALERT_POLLING_INTERVAL = 30000;

export const useAlertPolling = (shouldStart: boolean = true) => {
  const dispatch = useDispatch();
  const isSearchAlertsActive = useSelector((state) => state.settings.alertSettings?.isSearchAlertsActive ?? false);
  const isLoading = useSelector((state) => state.loadSearch.loadSearchAlerts.isLoading);
  const setAlertsCount = useSelector((state) => getSetAlertsCount(state.loadSearch.searchesState.searches));
  const isRateLimitingResponseOrNoAlertsSet = useSelector(
    (state) => state.loadSearch.loadSearchAlerts.isRateLimitingResponseOrNoAlertsSet
  );

  const interval = useSelector(
    (state) => state.settings.alertSettings?.searchAlertIntervalInSeconds || LOAD_ALERT_POLLING_INTERVAL
  );

  const onRefresh = () => {
    if (!isLoading) {
      dispatch(fetchLoadAlerts());
    }
  };

  const start = shouldStart && setAlertsCount > 0 && isSearchAlertsActive && !isRateLimitingResponseOrNoAlertsSet;

  const manager = useAutoRefreshManager(onRefresh, interval);
  useStartAutoRefresh(manager, start);

  useEffect(() => {
    if (start) {
      onRefresh();
    }
  }, [start]);
};

import { concat, map, trim } from 'lodash';
import memoizeOne from 'memoize-one';
import moment from 'moment';

import { formatLocationToCityState, formatMileage, formatNumber } from '@common/helper';
import { Location, LOCATION_ALL, LocationType } from '@common/model';
import { Option, OptionType } from '@component/multiSelect/MultiSelect';
import { t, T } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';

export const formatDistance = (distance?: number, shouldUseAbbreviation: boolean = false) => {
  if ((!distance && distance !== 0) || distance < 0) {
    return NO_INFORMATION_SYMBOL;
  }
  if (shouldUseAbbreviation) {
    return t(T.unit_mi, { value: formatMileage(distance) });
  }
  if (distance <= 1 && distance !== 0) {
    return t(T.unit_mile, { value: formatMileage(distance) });
  }
  return t(T.unit_mile_plural, { value: formatMileage(distance) });
};

//This function parses the API destination to be displayed in the UI.
//We have to be careful with edge cases, since Anywhere and None can be returned in multiple ways.
export const formatTruckLocation = (location: Location): string[] => {
  let locations: string[] = [];
  switch (location.type) {
    case LocationType.ANY_CA:
      return [t(T.common_load_Popup_AnywhereCA)];
    case LocationType.ANY_US:
      return [t(T.common_load_Popup_AnywhereUS)];
    case LocationType.ZIP:
    case LocationType.CITY:
      return [formatLocationToCityState(location)];
    case LocationType.STATE:
      if (location.states) {
        const canadaProvinces = location.states.canadaStates ? location.states.canadaStates : '';
        const usStates = location.states.usStates ? location.states.usStates : '';
        if (canadaProvinces === LOCATION_ALL) {
          if (location.states.usStates === LOCATION_ALL) {
            return [t(T.common_load_Popup_Anywhere)];
          }
          locations.push(t(T.common_load_Popup_AnywhereCA));
        } else if (canadaProvinces !== LocationType.NONE) {
          locations = concat(locations, canadaProvinces);
        }

        //If we get here, the All / All case has been already checked, so it can't be anywhere.
        if (location.states.usStates === LOCATION_ALL) {
          locations.push(t(T.common_load_Popup_AnywhereUS));
        } else if (usStates !== LocationType.NONE) {
          locations = concat(locations, usStates);
        }

        return locations;
      }
      break;
    case LocationType.ANY:
      return [t(T.common_load_Popup_Anywhere)];
  }
  return [];
};

export const formatStringOrDash = (value: string | undefined): string =>
  value === undefined || trim(value) === '' ? NO_INFORMATION_SYMBOL : value;

export const formatNumberOrDash = (num: number | undefined, units: string = ''): string =>
  num === undefined ? NO_INFORMATION_SYMBOL : formatNumber(num) + units;

export const formatDateToAge = (value: string | undefined): number => moment().diff(moment(value), 'seconds');

export const formatStringsToOptions = memoizeOne((stringOptions: string[], type: OptionType): Option[] =>
  map(stringOptions, (option) => ({ value: option, type: type }))
);

export const createMemoizedFormatStringsToOptions = (type: OptionType) =>
  memoizeOne((stringOptions: string[]) => formatStringsToOptions(stringOptions, type));

export const getButtonResultLabel = (count?: number, shouldHideTotalCount?: boolean) => {
  if (shouldHideTotalCount) {
    return t(T.common_filter);
  }
  if (count === 1) {
    return t(T.common_load_filter_singleResult, { quantity: count });
  }
  if (count === 0) {
    return t(T.common_loadSearch_noLoadsAvailable);
  }
  return t(T.common_load_filter_totalResult, { quantity: count });
};

export const removeEdgeCharacters = (label: string) => {
  let resultString = trim(label);

  const unwantedEdgeChars = [',', '-', '/'];

  let indexOfCharStart = unwantedEdgeChars.indexOf(resultString[0]);
  let indexOfCharEnd = unwantedEdgeChars.indexOf(resultString[resultString.length - 1]);

  while (indexOfCharStart !== -1 || indexOfCharEnd !== -1) {
    resultString =
      unwantedEdgeChars.indexOf(resultString[0]) === 0 ? resultString.substring(1) : resultString.slice(0, -1);
    resultString = trim(resultString);
    indexOfCharStart = unwantedEdgeChars.indexOf(resultString[0]);
    indexOfCharEnd = unwantedEdgeChars.indexOf(resultString[resultString.length - 1]);
  }

  return resultString;
};

import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { isMessagingWidgetView } from '@/MessagingWidgetApp';
import { MobileMenuGrayLayout } from '@component/main/MobileDrawer';
import { useIsMobileView, useSelector } from '@util/hooks';

import { useWindowCollapsibleLogic } from './collapsibleLogic';
import { FixedFooterPortalDestination, FixedHeaderPortalDestination } from './FixedAreaPortalDestination';
import { SafeBottomInsetSpace } from './SafeBottomInsetSpace';

const WindowScrollViewDiv = styled.div`
  left: 0;
  right: 0;
`;

const ChildrenContainer = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: visible;
`;

interface Props {
  style: React.CSSProperties;
  children: ReactNode;
}

/** Defines a scrollable area meant for mobile view (full window scrolling)
 * Use FixedHeader/FixedFooter components to define what is not to be scrolled (header or footer) respectively.
 *
 * This will account for mobile (portrait) iOS' Safari special "BottomBar-that-disappears-when-scrolling" (not on iPads).
 * Scrolling will be disabled completely when showing the side menu on mobile so it's important that it's the
 * first component in the tree for a page.
 */

export const WindowScrollView: React.FC<Props> = ({ style, children }) => {
  const menuOpen = useSelector((store) => store.applicationSettings.menuOpen);
  const isIntegrator = useSelector((state) => state.user.profile?.payload?.isIntegrator);
  const selectedFeedSection = useSelector((state) => state.feedState.selectedFeedSection);
  const isMobileView = useIsMobileView();
  const isMessagingWidget = isMessagingWidgetView();

  const isFeedPopupOpened = isIntegrator ? false : !!selectedFeedSection;

  // FakeScrolling: When the sidemenu is showing the window scrolls the menu only.
  // When the sidemenu is opened, the main content needs to "visually stay" in the same scroll
  // position as before. This fakes the scrolling position with a negative margin.
  const [fakeScrollPosition, setFakeScrollPosition] = useState<number | undefined>(undefined);
  const collapsibleRef = useRef<HTMLDivElement>(null);
  const fakeScrollPositionRef = useRef<number | undefined>(undefined);
  fakeScrollPositionRef.current = fakeScrollPosition;

  useWindowCollapsibleLogic(collapsibleRef, fakeScrollPositionRef);

  useLayoutEffect(() => {
    if (!isMobileView || isMessagingWidget) {
      setFakeScrollPosition(undefined);
      return;
    }

    if (menuOpen || isFeedPopupOpened) {
      // about to open the menu. Reset window.scroll after "faking" window scroll position.
      setFakeScrollPosition(window.pageYOffset);
      window.scroll(0, 0);
    } else {
      window.scroll(0, fakeScrollPosition ?? 0);
      setFakeScrollPosition(undefined);
    }
  }, [menuOpen, isMobileView, isMessagingWidget, isFeedPopupOpened]);

  return (
    <WindowScrollViewDiv
      id="windowscrollview"
      style={{
        position: fakeScrollPosition !== undefined ? 'fixed' : 'relative',
        marginTop: -(fakeScrollPosition ?? 0),
        paddingBottom: fakeScrollPosition ?? 0,
        minHeight: '100%',
        ...style,
      }}
    >
      <FixedHeaderPortalDestination
        id="headers"
        style={{ ...style, marginTop: fakeScrollPosition ?? 0 }}
        collapsibleRef={collapsibleRef}
      />
      <ChildrenContainer>{children}</ChildrenContainer>
      <FixedFooterPortalDestination id="footers" style={style} />
      <SafeBottomInsetSpace />
      {isMessagingWidget ? null : <MobileMenuGrayLayout />}
    </WindowScrollViewDiv>
  );
};

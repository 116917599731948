import styled from 'styled-components';

import { Text } from '@component/text';
import { Color } from '@style/Color';
import { FontFamily, FontSize, FontWeight } from '@style/StyleConstants';

const BUBBLE_BORDER_RADIUS = 20;
const BUBBLE_CORNER_BORDER_RADIUS = 4;

export const MessageWrapper = styled.div`
  margin: 4px 4px ${(props: { isLastOfGroup?: boolean }) => (props.isLastOfGroup ? 20 : 0)}px
    ${(props: { isLastOfGroup?: boolean }) => (props.isLastOfGroup ? 4 : 46)}px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
`;

export const ReadAndSentTimeText = styled(Text)`
  font-size: ${FontSize.Body}px;
  color: ${Color.GRAY_21};
  align-self: center;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 10px 0 10px 0;
`;

export const ConversationDateText = styled(Text)`
  align-content: center;
  padding: 5px 10px 3px;
  color: ${Color.GRAY_21};
  font-size: 14px;
  border: solid 1px ${Color.GRAY_LAVA};
  border-radius: 100px;
`;

export const MessageAndTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  display: flex;
  max-width: 16px;
  max-height: 16px;
  align-self: center;
  margin-left: 4px;
  margin-bottom: 2px;
`;

export const IconAndTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
`;

export const MessageReadContainer = styled.div`
  display: flex;
`;

export const BaseMessageButtonContainer = styled.div`
  padding: 12px 16px 10px 16px;
`;

export const UserMessageContainer = styled(BaseMessageButtonContainer)`
  border-radius: ${BUBBLE_BORDER_RADIUS}px
    ${(props: { isFirstOfGroup?: boolean }) =>
      props.isFirstOfGroup ? BUBBLE_BORDER_RADIUS : BUBBLE_CORNER_BORDER_RADIUS}px
    ${BUBBLE_CORNER_BORDER_RADIUS}px ${BUBBLE_BORDER_RADIUS}px;
  background-color: ${Color.LIGHT_BLACK};
  width: fit-content;
  align-self: flex-end;
  margin-left: 42px;
`;
export const OtherMessageContainer = styled(BaseMessageButtonContainer)`
  border-radius: ${(props: { isFirstOfGroup?: boolean }) =>
    props.isFirstOfGroup ? BUBBLE_BORDER_RADIUS : BUBBLE_CORNER_BORDER_RADIUS}px
    ${BUBBLE_BORDER_RADIUS}px ${BUBBLE_BORDER_RADIUS}px ${BUBBLE_CORNER_BORDER_RADIUS}px;
  background-color: ${Color.GRAY_LAVA};
  width: fit-content;
`;

export const AttachmentsContainerTitle = styled.div`
  align-content: center;
  margin-bottom: 8px;
`;
export const AttachmentsSeparator = styled.div`
  height: 6px;
`;

export const AttachmentLinkButton = styled.div`
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
  font-size: 16px;
`;
interface AttachmentsProps {
  isFromCurrentUser: boolean;
  hasText: boolean;
}
export const AttachmentsWrapper = styled.div`
  margin: -12px -16px;
  background-color: ${Color.WHITE};
  border: 1px solid ${(props: AttachmentsProps) => (props.isFromCurrentUser ? Color.LIGHT_BLACK : Color.GRAY_LAVA)};
  border-bottom-right-radius: ${(props: AttachmentsProps) =>
    props.isFromCurrentUser ? BUBBLE_CORNER_BORDER_RADIUS : BUBBLE_BORDER_RADIUS}px;
  border-bottom-left-radius: ${(props: AttachmentsProps) =>
    props.isFromCurrentUser ? BUBBLE_BORDER_RADIUS : BUBBLE_CORNER_BORDER_RADIUS}px;
  border-top-left-radius: ${(props: AttachmentsProps) => (props.hasText ? 0 : BUBBLE_BORDER_RADIUS)}px;
  border-top-right-radius: ${(props: AttachmentsProps) => (props.hasText ? 0 : BUBBLE_BORDER_RADIUS)}px;
  padding: ${(props: AttachmentsProps) => (props.hasText ? '6px 14px' : '10px 14px')};
`;

export const MessageBubbleDivider = styled.div`
  margin-top: 10px;
  height: 8px;
`;

const BaseWidgetMessageButtonContainer = styled.div`
  padding: 5px;
  border: 1px solid ${Color.GREEN_MAIN};
  background-color: ${Color.WHITE};
`;

export const OtherUserWidgetBubble = styled(BaseWidgetMessageButtonContainer)`
  border-radius: ${(props: { isFirstOfGroup?: boolean }) =>
    props.isFirstOfGroup ? BUBBLE_BORDER_RADIUS : BUBBLE_CORNER_BORDER_RADIUS}px
    ${BUBBLE_BORDER_RADIUS}px ${BUBBLE_BORDER_RADIUS}px ${BUBBLE_CORNER_BORDER_RADIUS}px;
`;

export const CurrentUserWidgetBubble = styled(BaseWidgetMessageButtonContainer)`
  border-radius: ${BUBBLE_BORDER_RADIUS}px
    ${(props: { isFirstOfGroup?: boolean }) =>
      props.isFirstOfGroup ? BUBBLE_BORDER_RADIUS : BUBBLE_CORNER_BORDER_RADIUS}px
    ${BUBBLE_CORNER_BORDER_RADIUS}px ${BUBBLE_BORDER_RADIUS}px;
  margin-left: 42px;
`;
export const InitialsBubble = styled.div`
  background-color: ${Color.GRAY_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  height: 34px;
  border-radius: 100px;
  border: 1px solid ${Color.GRAY_LAVA};
  cursor: pointer;
  margin-right: 6px;
  margin-bottom: 3px;
`;
export const AvatarTextWrapper = styled.span`
  margin-top: 3px;
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Bold};
  font-family: ${FontFamily.Main};
  color: ${Color.GRAY_LIGHT};
`;

import React, { useEffect, useState } from 'react';

import {
  lengthDomain,
  MAX_TRUCK_LENGTH,
  MAX_TRUCK_WEIGHT,
  weightDomain,
  weightDomain500,
  weightDomainMinus500,
} from '@common/model';
import { CustomSlider } from '@component/slider/CustomSlider';
import {
  StyledPaddedColumn,
  StyledSliderResults,
  StyledSubHeader,
} from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { T, t } from '@translate';
import {
  displayLength,
  displayWeight,
  stepLength,
  stepWeight,
  stepWeight500,
  stepWeightMinus500,
} from '@util/TruckDataHelper';

interface WeightLengthSlidersProps {
  weight: number | undefined;
  length: number | undefined;
  onChangeLength: (range: number[]) => void;
  onChangeWeight: (range: number[]) => void;
  areSlidersDisabled: boolean;
  isPowerOnly: boolean;
}

export const WeightLengthSliders: React.FC<WeightLengthSlidersProps> = (props) => {
  const getLengthValue = () => {
    return props.length ? [props.length] : [MAX_TRUCK_LENGTH];
  };
  const getWeightValue = () => {
    return props.weight ? [props.weight] : [MAX_TRUCK_WEIGHT];
  };

  const [weight, setWeight] = useState(getWeightValue());
  const [length, setLength] = useState(getLengthValue());

  useEffect(() => {
    if (props.length) {
      setLength([props.length]);
    }
  }, [props.length]);

  useEffect(() => {
    if (props.weight) {
      setWeight([props.weight]);
    }
  }, [props.weight]);

  return (
    <StyledPaddedColumn style={{ paddingTop: '24px' }}>
      <StyledSubHeader id="length_title">{t(T.findLoads_findLoads_length)}</StyledSubHeader>
      <StyledSliderResults id="length_value">{displayLength(length[0], props.isPowerOnly)}</StyledSliderResults>
      <CustomSlider
        id="length"
        domain={lengthDomain}
        values={getLengthValue()}
        step={stepLength}
        onChange={props.onChangeLength}
        disabled={props.areSlidersDisabled}
        mode={2}
        onUpdate={props.onChangeLength}
      />
      <StyledSubHeader id="weight_title">{t(T.findLoads_findLoads_weight)}</StyledSubHeader>
      <StyledSliderResults id="weight_value">{displayWeight(weight[0], props.isPowerOnly)}</StyledSliderResults>
      <CustomSlider
        id="weight"
        domain={weight[0] <= 500 ? weightDomainMinus500 : weight[0] <= 1000 ? weightDomain500 : weightDomain}
        values={weight}
        step={weight[0] <= 500 ? stepWeightMinus500 : weight[0] <= 1000 ? stepWeight500 : stepWeight}
        onChange={props.onChangeWeight}
        disabled={props.areSlidersDisabled}
        mode={2}
        onUpdate={props.onChangeWeight}
      />
    </StyledPaddedColumn>
  );
};

import { BaseClient } from '@common/client/BaseClient';
import { addArchivingHeader } from '@common/helper/FlowIDHeaderHelper';
import {
  ExpediteAllRateRequest,
  ExpediteAllResponse,
  LoadRateCheckHistoryRequest,
  LoadToTruckRatioRequest,
  LoadToTruckRatioResponse,
  MarketRankingRequest,
  MarketRankingResponse,
  RateCheck,
  RateCheckAverageRequest,
  RateCheckAverageResponse,
  RateCheckFeedbackRequest,
  RateCheckHistoryRequest,
  RateCheckRequest,
  ThirdPartyLaneRateRequest,
  ThirdPartyLaneRateResponse,
} from '@common/model';
import { RateCheckHistory } from '@common/redux/epic/RateCheckEpic';

export class RateCheckClient extends BaseClient {
  fetchRateCheck$ = (request: RateCheckRequest) => this.api.post$<RateCheck>('/ratechecks', request);
  postRateCheckFeedback$ = (feedback: RateCheckFeedbackRequest) =>
    this.api.post$<{}>('/ratechecks/feedbacks', feedback);
  postLoadRateCheckFeedback$ = (feedback: RateCheckFeedbackRequest, id: string, archivingFlowID: string | undefined) =>
    this.api.post$<{}>(`/loads/${id}/ratechecks/feedbacks`, feedback, addArchivingHeader(archivingFlowID));
  getRateCheckHistory$ = (request: RateCheckHistoryRequest) =>
    this.api.get$<RateCheckHistory>('/ratechecks/history', request);
  getRateCheckAverage$ = (request: RateCheckAverageRequest) =>
    this.api.post$<RateCheckAverageResponse>('/ratechecks/averageInPeriod', request);
  getLoadRateCheckHistory$ = (request: LoadRateCheckHistoryRequest, id: string, archivingFlowID: string | undefined) =>
    this.api.get$(`/loads/${id}/ratecheck/history`, request, addArchivingHeader(archivingFlowID));
  getMarketRanking$ = (request: MarketRankingRequest) =>
    this.api.post$<MarketRankingResponse>('/ratechecks/marketRanking', request);
  getThirdPartyLaneRates$ = (request: ThirdPartyLaneRateRequest) =>
    this.api.post$<ThirdPartyLaneRateResponse[]>('/laneRate/getThirdPartyLaneRates', request);
  getLoadToTruckRatio$ = (request: LoadToTruckRatioRequest[]) =>
    this.api.post$<{ individualResponses: LoadToTruckRatioResponse[] }>('/loadtotruckratio/v1/ratio/byState', {
      individualRequests: request,
    });
  getExpediteAllRate$ = (request: ExpediteAllRateRequest) =>
    this.api.post$<ExpediteAllResponse>('/expediteall/v1/rate', request);
}

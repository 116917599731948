import { includes, isNaN, isUndefined, round, toNumber, toString } from 'lodash';
import moment from 'moment';

import { formatDateDDDMMdd, formatMomentYYYYMMDD } from '@common/helper/DateHelper';
import { formatNumber, formatPrice } from '@common/helper/NumberHelper';
import { t, T } from '@translate';

import { NO_INFORMATION_SYMBOL } from './Constants';

interface NumberFormatOptions {
  prefix?: string;
  suffix?: string;
}

const withPrefixSuffix = (v: string, opt?: NumberFormatOptions) => {
  if (opt === undefined) {
    return v;
  }

  return `${opt.prefix === undefined ? '' : opt.prefix}${v}${opt.suffix === undefined ? '' : opt.suffix}`;
};

/** Format currency with potential units, etc. Displays nothing if number is undefined.
 *
 * @param withDecimals Option to show 2 decimals or not (defaults to true - shows 2 decimals)
 * Negative amount will show the negative sign next to the number: '$-5'
 *
 * @param options used to display units (suffix) or a prefix. Will NOT display if number is undefined.
 */
export const displayCurrencyOrNothing = (
  amount: number | undefined,
  withDecimals: boolean = true,
  options?: NumberFormatOptions
) => {
  if (!amount) {
    return '';
  }

  return withPrefixSuffix(displayCurrency(amount, withDecimals), options);
};

/** Format currency with potential units, etc. Displays $- if number is undefined.
 *
 * @param withDecimals Option to show 2 decimals or not (defaults to true - shows 2 decimals)
 * Negative amount will show the negative sign next to the number: '$-5'
 *
 * @param options used to display units (suffix) or a prefix. Will NOT display if number is undefined.
 * Example with `prefix='-'`, `suffix='/mi'` :  `-$4.50/mi`
 */
export const displayCurrencyOrDash = (
  amount: number | undefined,
  withDecimals: boolean = true,
  options?: NumberFormatOptions
): string => {
  if (amount === undefined) {
    return `${t(T.common_dollar_sign)}${NO_INFORMATION_SYMBOL}`;
  }

  return withPrefixSuffix(displayCurrency(amount, withDecimals), options);
};

/** Format currency.
 *
 * @param withDecimals Option to show 2 decimals or not (defaults to true - shows 2 decimals)
 */
export const displayCurrency = (amount: number, withDecimals: boolean = true) => {
  const isNegative = amount < 0;
  return `${isNegative ? '- ' : ''}${t(T.common_dollar_sign)}${formatPrice(Math.abs(amount), !withDecimals)}`;
};

// Returns format 'Today at 11:55 am' if date is today or yesterday, else returns 'DDD, MMM dd'
export const displayDate = (date: string): string => {
  const day = moment(date);
  if (day.isSame(moment(), 'day')) {
    return `${t(T.common_dates_today)} ${t(T.common_at)} ${day.format('hh:mm a')}`;
  }
  if (day.isSame(moment().subtract(1, 'day'), 'day')) {
    return `${t(T.common_dates_yesterday)} ${t(T.common_at)} ${day.format('hh:mm a')}`;
  }
  return formatDateDDDMMdd(date);
};

/** Removes zero at the 1 position, e.g. 01.05 will be converted to 1.05*/
export const removeUnnecessaryZero = (value: string, defaultValue: string | number = ''): string => {
  const [integerPart, fractionPart] = value.split('.');
  const parsedIntegerPart = parseInt(integerPart);
  const newIntegerPart = isNaN(parsedIntegerPart) ? defaultValue : parsedIntegerPart;
  const dotSymbolPart = includes(value, '.') ? '.' : '';
  const newFractionPart = fractionPart ?? '';
  return `${newIntegerPart}${dotSymbolPart}${newFractionPart}`;
};

export const momentDashYYYYMMdd = () => {
  const date = new Date();
  const momentDate = moment(date);
  return formatMomentYYYYMMDD(momentDate);
};

export const removeComma = (text: string) => text.replace(',', '');

/** Truncates number of digits after dot without rounding, casting to string*/
export const formatFloat = (num: number | string, digits: number): string => {
  const strNumber = toString(num);
  const [integerPart, fractionPart] = strNumber.split('.');
  const dotSymbolPart = includes(strNumber, '.') ? '.' : '';
  const newFractionPart = isUndefined(fractionPart) ? '' : fractionPart.substr(0, digits);
  return `${integerPart}${dotSymbolPart}${newFractionPart}`;
};

/** Converts a number to a string by using the 'en' locale*/
export const getNormalizedText = (value: number, forceDecimals?: boolean): string =>
  value !== 0 ? formatNumber(value, forceDecimals) : '';

/**
 * In a case we have really small number e.g. 0.00015 we don't want to display it as 0.00. It is better to show it as 0.0002
 * This function rounds number and shows 4 digits after comma
 */
export const show4DigitsAfterComma = (value: number | string): number | string => {
  const parsedValue = toNumber(value);
  // in case if number will not be visible even if we display 4 digits after comma we display 0.0000...
  if (parsedValue > 0 && parsedValue < 0.0001) {
    const dots = '\u2026';
    return `0.0000${dots}`;
  }
  if (shouldShow4DigitsAfterComma(parsedValue)) {
    //get number of zeros in fraction part of value
    const magnitude = -Math.floor(Math.log(parsedValue) / Math.log(10) + 1);
    return round(parsedValue, magnitude + 1);
  }
  return value;
};

/**
 * In a case we have really small number e.g. 0.00015 we don't want to display it as 0.00. It is better to show it as 0.0002
 * This function checks if we need to show number with 4 digits after comma instead of 2
 */
export const shouldShow4DigitsAfterComma = (value: number | string) => {
  const parsedValue = toNumber(value);
  return parsedValue > 0 && parsedValue < 0.01;
};

/**
 * Check if a value has a decimal part
 *
 */
export const hasDecimal = (value: number | undefined) => {
  if (value !== undefined && value % 1 === 0) {
    return false;
  }
  return true;
};

import React from 'react';

import { AuthenticatedInitialLoading } from '@component/main/AuthenticatedInitialLoading';
import { useSetGoogleAnalyticsUser } from '@util/trackers/GoogleAnalyticsTracker';
import { hasLoginCookies } from '@webApi/CookiesHelper';

import { useShouldDisplayHeader } from './mainHooks';

export const InitialLoading: React.FC = () => {
  useSetGoogleAnalyticsUser();

  const isNotPublicPage = useShouldDisplayHeader();

  return isNotPublicPage && hasLoginCookies() ? <AuthenticatedInitialLoading /> : null;
};

import { createMuiTheme } from '@material-ui/core';
import { TypographyStyle } from '@material-ui/core/styles/createTypography';

import { Color } from '@style/Color';

export enum Breakpoint {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum ThemeColor {
  Default = 'default',
  Inherit = 'inherit',
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum MuiButtonVariant {
  Text = 'text',
  Flat = 'flat',
  Outlined = 'outlined',
  Contained = 'contained',
  Raised = 'raised',
  Fab = 'fab',
}

interface ButtonPalette {
  normalBackground: string;
  hoverBackground: string;
  pressedBackground: string;
  text: string;
  pressedText?: string;
  border?: string;
  borderHover?: string;
  borderPressed?: string;
}

interface TextFieldPalette {
  normalBackground: string;
  lightBackground: string;
  hoverBackground: string;
  disabledBackground: string;
  normalText: string;
  normalLabel: string;
  error: string;
}

interface MultiSelectPalette {
  borderColor: string;
  labelColor: string;
}

interface SelectPalette {
  borderColor: string;
  backgroundColor: string;
  hoverColor: string;
}

interface TextInputPalette {
  borderColor: string;
  backgroundColor: string;
}

interface MapPalette {
  circle: string;
  line: string;
}

interface StatusBar {
  background: string;
}

const PRIMARY_FONT = "'Overpass', sans-serif";
const SECONDARY_FONT = "'Overpass', sans-serif";

interface ThemeText {
  header1Tab: TypographyStyle & { unselectedColor: string };
  header1ProfileTab: TypographyStyle;
  header2Title: TypographyStyle;
  header2Button: TypographyStyle;
  dropBoxTitle: TypographyStyle;
  header3: TypographyStyle;
  redHeader3: TypographyStyle;
  default: TypographyStyle;
  disabled: TypographyStyle;
  defaultLight: TypographyStyle;
  link: TypographyStyle;
  smallLink: TypographyStyle;
  primaryButton: TypographyStyle;
  alert: TypographyStyle;
  alertLarge: TypographyStyle;
  footerLink: TypographyStyle;
  footerText: TypographyStyle;
  bigLink: TypographyStyle;
  bigLinkBold: TypographyStyle;
  hamburgerHeader: TypographyStyle;
  submenu: TypographyStyle;
  version: TypographyStyle;
  compactVersion: TypographyStyle;
  hamburgerTab: TypographyStyle;
  infoFieldLabel: TypographyStyle;
  infoFieldValue: TypographyStyle;
  inputFieldLabel: TypographyStyle & { disabled: string; hover: string };
  inputFieldValue: TypographyStyle & { disabled: string };
  inputFieldShow: TypographyStyle;
  note: TypographyStyle;
  infoTableHeader: TypographyStyle;
  disclaimer: TypographyStyle;
  contactDisclaimer: TypographyStyle;
  menuSeparator: TypographyStyle;
  largerMenuSeparator: TypographyStyle;
  hamburgerMenuSeparator: TypographyStyle;
  tableRow: TypographyStyle;
  tableHeader: TypographyStyle;
  tableSubContent: TypographyStyle;
  tableTitle: TypographyStyle;
  tableSeparator: TypographyStyle;
  midResult: TypographyStyle & { disabled: string };
  bigResult: TypographyStyle;
  biggerResult: TypographyStyle;
  heavyText: TypographyStyle;
  lightText: TypographyStyle;
  dashboardHeader: TypographyStyle;
  dashboardHeaderSmall: TypographyStyle;
  dashboardItemLabel: TypographyStyle;
  dashboardItemLabelSmall: TypographyStyle;
  dashboardPostedLoadCount: TypographyStyle;
  dashboardPostedLoadCountSmall: TypographyStyle;
  error: TypographyStyle;
  errorSmall: TypographyStyle;
  nakedPageTitle: TypographyStyle;
  nakedPageMessage: TypographyStyle;
  detailContent: TypographyStyle;
  detailContentLink: TypographyStyle;
  textContentDetail: TypographyStyle;
  textContentDetailGreen: TypographyStyle;
  h1: TypographyStyle;
  h2: TypographyStyle;
  title: TypographyStyle;
  subtitleRegular: TypographyStyle;
  subtitleBold: TypographyStyle;
  subtitle2Regular: TypographyStyle;
  subtitleNormalWeight: TypographyStyle;
  subtitle2Bold: TypographyStyle;
  body: TypographyStyle;
  bodyLarge: TypographyStyle;
  body3: TypographyStyle;
  body2: TypographyStyle;
  caption: TypographyStyle;
  tableHeaderGreen: TypographyStyle;
  tableHeaderRed: TypographyStyle;
  loadDetailsCompanyName: TypographyStyle;
  verifyEmailHeader: TypographyStyle;
  verifyEmailHeaderSmall: TypographyStyle;
  verifyEmailSubheader: TypographyStyle;
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    text: ThemeText;
  }

  interface ThemeOptions {
    text?: Partial<ThemeText>;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    header: {};
    footer: PaletteColor;
    htmlEmbedTable: PaletteColor;
    dropBox: PaletteColor;
    disabled: PaletteColor;
    icon: PaletteColor;
    smallBox: PaletteColor;
    smallBoxDark: PaletteColor;
    topRibbon: PaletteColor;
    tableCell: PaletteColor;

    primaryButton: ButtonPalette;
    secondaryButton: ButtonPalette;
    primaryHeaderButton: ButtonPalette;
    secondaryHeaderButton: ButtonPalette;
    unlockButton: ButtonPalette;

    multiSelect: MultiSelectPalette;

    select: SelectPalette;

    textInput: TextInputPalette;

    chipButton: ButtonPalette;

    textField: TextFieldPalette;

    map: MapPalette;

    secondaryTab: PaletteColor;

    dropdownMenu: PaletteColor;

    statusBar: StatusBar;

    decorator: {
      border: string;
      border2: string;
      shadow: string;
    };

    status: {
      selected: string;
      unselected: string;
      alert: string;
      confirmation: string;
    };
    inputField: {
      background: string;
      hover: string;
      disabledBackground: string;
      disabledUnderline: string;
    };
  }

  interface PaletteOptions {
    footer?: SimplePaletteColorOptions;
    htmlEmbedTable?: SimplePaletteColorOptions;
    dropBox?: SimplePaletteColorOptions;
    disabled?: SimplePaletteColorOptions;
    icon?: SimplePaletteColorOptions;
    smallBox?: SimplePaletteColorOptions;
    smallBoxDark?: SimplePaletteColorOptions;
    topRibbon?: SimplePaletteColorOptions;
    tableCell?: SimplePaletteColorOptions;

    primaryButton?: ButtonPalette;
    secondaryButton?: ButtonPalette;
    primaryHeaderButton?: ButtonPalette;
    secondaryHeaderButton?: ButtonPalette;
    unlockButton?: ButtonPalette;

    multiSelect?: MultiSelectPalette;

    select?: SelectPalette;

    textInput?: TextInputPalette;

    chipButton?: ButtonPalette;

    textField?: TextFieldPalette;

    map?: MapPalette;

    secondaryTab?: SimplePaletteColorOptions;

    dropdownMenu?: SimplePaletteColorOptions;

    statusBar?: StatusBar;

    decorator?: {
      border: string;
      border2: string;
      shadow: string;
    };

    status?: {
      selected: string;
      unselected: string;
      alert: string;
      confirmation: string;
    };
    inputField?: {
      background: string;
      hover: string;
      disabledBackground: string;
      disabledUnderline: string;
    };
  }

  interface TypeBackground {
    dark: string;
    pale: string;
    paler: string;
    dim: string;
    accent: string;
    strong: string;
    smoke: string;
    stone: string;
    white: string;
    grayLight: string;
    black: string;
    orangeSmoke: string;
  }
}

export const THEME = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['Overpass', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: Color.GREEN_MAIN,
      light: Color.GREEN_LIGHT,
      contrastText: Color.WHITE,
    },
    secondary: {
      main: Color.ORANGE_MAIN,
      dark: Color.ORANGE_DARK,
      light: Color.ORANGE_LIGHT,
      contrastText: Color.WHITE,
    },
    footer: {
      main: Color.GRAY_12,
      contrastText: Color.WHITE,
    },
    background: {
      paper: Color.WHITE,
      default: Color.GRAY_1,
      pale: Color.GRAY_2,
      paler: Color.GRAY_13,
      dim: Color.ORANGE_LIGHT,
      accent: Color.ORANGE_MAIN,
      dark: Color.GRAY_DARK,
      strong: Color.GRAY_17,
      stone: Color.GRAY_STONE,
      grayLight: Color.GRAY_LIGHT,
      smoke: Color.GRAY_SMOKE,
      white: Color.WHITE,
      black: Color.BLACK,
      orangeSmoke: Color.ORANGE_SMOKE,
    },
    decorator: {
      border: Color.GRAY_15,
      border2: Color.GRAY_SMOKE,
      shadow: Color.GRAY_16,
    },
    statusBar: {
      background: Color.GREEN_LIGHT,
    },
    status: {
      selected: Color.WHITE,
      unselected: Color.GREEN_LIGHT,
      alert: Color.RED_2,
      confirmation: Color.GREEN_MAIN,
    },
    inputField: {
      background: Color.GRAY_9,
      hover: Color.GRAY_10,
      disabledBackground: Color.GRAY_1,
      disabledUnderline: Color.GRAY_3,
    },
    text: {
      primary: Color.GRAY_DARK,
      secondary: Color.GRAY_STONE,
    },
    disabled: {
      main: Color.GRAY_DISABLED,
      light: Color.WHITE,
      dark: Color.GRAY_STONE,
    },
    icon: {
      main: Color.ORANGE_MAIN,
      dark: Color.GRAY_11,
      light: Color.GRAY_10,
    },
    primaryButton: {
      normalBackground: Color.ORANGE_MAIN,
      hoverBackground: Color.ORANGE_DARK,
      pressedBackground: Color.ORANGE_MAIN,
      text: Color.WHITE,
    },
    secondaryButton: {
      normalBackground: Color.TRANSPARENT_0,
      hoverBackground: Color.TRANSPARENT_10,
      pressedBackground: Color.TRANSPARENT_30,
      text: Color.GRAY_6,
      border: Color.GRAY_3,
      borderHover: Color.GRAY_3,
      borderPressed: Color.GRAY_4,
    },
    primaryHeaderButton: {
      normalBackground: Color.GREEN_MAIN,
      hoverBackground: Color.GREEN_DARK,
      pressedBackground: Color.GREEN_MAIN,
      text: Color.WHITE,
    },
    secondaryHeaderButton: {
      normalBackground: Color.WHITE,
      hoverBackground: Color.GREEN_LIGHT,
      pressedBackground: Color.GREEN_LIGHT,
      text: Color.GREEN_MAIN,
      border: Color.GREEN_MAIN,
      borderHover: Color.GREEN_DARK,
      borderPressed: Color.GREEN_MAIN,
    },
    unlockButton: {
      normalBackground: 'transparent',
      hoverBackground: 'transparent',
      pressedBackground: Color.ORANGE_MAIN,
      text: Color.ORANGE_MAIN,
      border: Color.ORANGE_MAIN,
      borderHover: Color.ORANGE_DARK,
      borderPressed: Color.ORANGE_MAIN,
    },
    chipButton: {
      normalBackground: Color.WHITE,
      hoverBackground: Color.GRAY_7,
      pressedBackground: Color.GRAY_10,
      text: Color.GRAY_4,
      pressedText: Color.GRAY_6,
      border: Color.GRAY_8,
    },
    textField: {
      normalBackground: Color.GRAY_9,
      hoverBackground: Color.GRAY_10,
      lightBackground: Color.WHITE,
      disabledBackground: Color.GRAY_1,
      normalText: Color.GRAY_6,
      normalLabel: Color.GRAY_3,
      error: Color.RED_1,
    },
    textInput: {
      borderColor: Color.GRAY_SMOKE,
      backgroundColor: Color.WHITE,
    },
    map: {
      circle: Color.GRAY_3,
      line: Color.BLUE_PURE,
    },
    multiSelect: {
      borderColor: Color.GRAY_SMOKE,
      labelColor: Color.GRAY_STONE,
    },
    select: {
      borderColor: Color.GRAY_SMOKE,
      backgroundColor: Color.WHITE,
      hoverColor: Color.GREEN_LIGHT,
    },
    smallBox: {
      main: Color.GRAY_11,
    },
    smallBoxDark: {
      main: Color.GRAY_7,
      dark: Color.GRAY_4,
    },
    htmlEmbedTable: {
      main: Color.BEIGE_1,
      light: Color.WHITE,
    },
    dropBox: {
      main: Color.ORANGE_LIGHT,
      dark: Color.ORANGE_MAIN,
    },
    topRibbon: {
      main: Color.YELLOW_1,
      contrastText: Color.GRAY_6,
    },
    secondaryTab: {
      main: Color.GRAY_4,
      contrastText: Color.WHITE,
      light: Color.GRAY_3,
    },
    dropdownMenu: {
      main: Color.WHITE,
      contrastText: Color.GRAY_6,
      dark: Color.GRAY_10,
    },
    tableCell: {
      main: Color.WHITE,
      dark: Color.ORANGE_LIGHT,
      light: Color.GRAY_9,
    },
  },
  text: {
    header1Tab: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: SECONDARY_FONT,
      lineHeight: '19px',
      color: Color.WHITE,
      unselectedColor: Color.GREEN_LIGHT,
    },
    header1ProfileTab: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      lineHeight: '19px',
      letterSpacing: '1.1px',
      color: Color.WHITE,
    },
    header2Title: {
      fontSize: '21px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      lineHeight: '28px',
      color: Color.GREEN_MAIN,
    },
    header2Button: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: SECONDARY_FONT,
      lineHeight: '19px',
      textTransform: 'uppercase',
      color: Color.GREEN_MAIN,
    },
    dropBoxTitle: {
      fontSize: '18px',
      fontWeight: 500,
      fontFamily: SECONDARY_FONT,
      lineHeight: '19px',
      textTransform: 'uppercase',
      color: Color.ORANGE_MAIN,
    },
    header3: {
      fontSize: '16px',
      fontWeight: 'bold',
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.GRAY_6,
    },
    redHeader3: {
      fontSize: '16px',
      fontWeight: 'bold',
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.RED_3,
    },
    default: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '19px',
      color: Color.GRAY_6,
    },
    disabled: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '19px',
      color: Color.GRAY_STONE,
    },
    defaultLight: {
      fontSize: '14px',
      fontWeight: 300,
      fontFamily: PRIMARY_FONT,
      lineHeight: '19px',
      color: Color.GRAY_6,
    },
    link: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: PRIMARY_FONT,
      lineHeight: '19px',
      color: Color.ORANGE_MAIN,
    },
    bigLink: {
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.ORANGE_MAIN,
    },
    bigLinkBold: {
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.ORANGE_MAIN,
    },
    primaryButton: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      textTransform: 'uppercase',
      color: Color.WHITE,
    },
    alert: {
      fontSize: '10px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      lineHeight: '11px',
      color: Color.WHITE,
    },
    alertLarge: {
      fontSize: '12px',
      fontWeight: 500,
      fontFamily: SECONDARY_FONT,
      lineHeight: '17px',
      color: Color.WHITE,
    },
    footerLink: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: SECONDARY_FONT,
      lineHeight: '17px',
      color: Color.WHITE,
    },
    footerText: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '18px',
      color: Color.GRAY_4,
    },
    hamburgerHeader: {
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: SECONDARY_FONT,
      lineHeight: '22px',
      color: Color.GRAY_6,
    },
    submenu: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: SECONDARY_FONT,
      lineHeight: '19px',
      color: Color.GRAY_6,
    },
    version: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: SECONDARY_FONT,
      lineHeight: '17px',
      color: Color.GRAY_3,
    },
    compactVersion: {
      fontSize: '10px',
      fontWeight: 'normal',
      fontFamily: SECONDARY_FONT,
      lineHeight: '17px',
      color: Color.GRAY_3,
    },
    hamburgerTab: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: SECONDARY_FONT,
      lineHeight: '19px',
      color: Color.GRAY_6,
    },
    infoFieldLabel: {
      fontSize: '10px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '13px',
      color: Color.GRAY_6,
    },
    infoFieldValue: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '19px',
      color: Color.GRAY_6,
    },
    inputFieldLabel: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_3,
      disabled: Color.GRAY_10,
      hover: Color.GRAY_6,
    },
    inputFieldValue: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_6,
      disabled: Color.GRAY_3,
    },
    inputFieldShow: {
      fontSize: '10px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      textTransform: 'uppercase',
      color: Color.GRAY_6,
    },
    note: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '16px',
      color: Color.GRAY_6,
    },
    tableRow: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '19px',
      color: Color.GRAY_6,
    },
    tableHeader: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '16px',
      color: Color.GRAY_11,
    },
    tableSubContent: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.GRAY_11,
    },
    tableTitle: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.GRAY_4,
    },
    tableSeparator: {
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.GRAY_3,
      textTransform: 'uppercase',
    },
    midResult: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.GRAY_6,
      disabled: Color.GRAY_10,
    },
    bigResult: {
      fontSize: '18px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
      color: Color.GRAY_6,
    },
    biggerResult: {
      fontSize: '21px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      lineHeight: '28px',
      color: Color.GRAY_6,
    },
    heavyText: {
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      lineHeight: '24px',
      color: Color.GRAY_6,
    },
    lightText: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '16px',
      color: Color.GRAY_6,
    },
    disclaimer: {
      fontSize: '10px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '13px',
      color: Color.GRAY_6,
    },
    contactDisclaimer: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '13px',
      color: Color.GRAY_8,
    },
    infoTableHeader: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '16px',
      color: Color.GRAY_5,
    },
    menuSeparator: {
      fontSize: '10px',
      fontWeight: 600,
      fontFamily: SECONDARY_FONT,
      color: Color.GREEN_MAIN,
      textTransform: 'uppercase',
    },
    hamburgerMenuSeparator: {
      fontSize: '11px',
      fontWeight: 600,
      fontFamily: SECONDARY_FONT,
      color: Color.GREEN_MAIN,
      textTransform: 'uppercase',
    },
    largerMenuSeparator: {
      fontSize: '12px',
      fontWeight: 500,
      fontFamily: SECONDARY_FONT,
      color: Color.GREEN_MAIN,
      textTransform: 'uppercase',
    },
    dashboardHeader: {
      fontSize: '24px',
      fontWeight: 300,
      fontFamily: PRIMARY_FONT,
      color: Color.GREEN_MAIN,
      lineHeight: '32px',
    },
    dashboardHeaderSmall: {
      fontSize: '18px',
      fontWeight: 300,
      fontFamily: PRIMARY_FONT,
      color: Color.GREEN_MAIN,
      lineHeight: '24px',
    },
    dashboardItemLabel: {
      fontSize: '18px',
      fontWeight: 300,
      fontFamily: SECONDARY_FONT,
      color: Color.GRAY_6,
      lineHeight: '24px',
      textTransform: 'none',
    },
    dashboardItemLabelSmall: {
      fontSize: '16px',
      fontWeight: 300,
      fontFamily: SECONDARY_FONT,
      color: Color.GRAY_6,
      lineHeight: '22px',
      textTransform: 'none',
    },
    dashboardPostedLoadCount: {
      fontSize: '70px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      color: Color.ORANGE_MAIN,
      textTransform: 'none',
    },
    dashboardPostedLoadCountSmall: {
      fontSize: '55px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      color: Color.ORANGE_MAIN,
      textTransform: 'none',
    },
    error: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      color: Color.RED_1,
      lineHeight: '19px',
    },
    errorSmall: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      color: Color.RED_1,
    },
    nakedPageTitle: {
      fontSize: '21px',
      lineHeight: '28px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_4,
    },
    nakedPageMessage: {
      fontSize: '16px',
      lineHeight: '21px',
      fontWeight: 300,
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_4,
    },
    detailContent: {
      fontSize: '12px',
      color: Color.GRAY_STONE,
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '16px',
    },
    detailContentLink: {
      fontSize: '12px',
      color: Color.ORANGE_MAIN,
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '16px',
    },
    textContentDetail: {
      fontSize: '16px',
      color: Color.GRAY_DARK,
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '21px',
    },
    h1: {
      fontSize: '36px',
      fontWeight: 400,
      lineHeight: '40pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    h2: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    title: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '24px',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    subtitleRegular: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    subtitleBold: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    subtitle2Regular: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    subtitleNormalWeight: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '18pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    subtitle2Bold: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '21pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    bodyLarge: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '18pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    body: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    body2: {
      fontSize: '12px',
      fontWeight: 500,
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    body3: {
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: '16.5pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    caption: {
      fontSize: '9px',
      fontWeight: 500,
      lineHeight: '13.5pt',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
    },
    tableHeaderGreen: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '16px',
      color: Color.GREEN_MAIN,
    },
    tableHeaderRed: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: PRIMARY_FONT,
      lineHeight: '16px',
      color: Color.RED_1,
    },
    loadDetailsCompanyName: {
      fontSize: '20px',
      fontWeight: 600,
      fontFamily: PRIMARY_FONT,
      lineHeight: '30px',
      color: Color.GRAY_DARK,
    },
    verifyEmailHeader: {
      fontSize: '44px',
      fontWeight: 'bold',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
      lineHeight: '54px',
    },
    verifyEmailHeaderSmall: {
      fontSize: '32px',
      fontWeight: 'bold',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
      lineHeight: '38px',
    },
    verifyEmailSubheader: {
      fontSize: '20px',
      fontWeight: 'bold',
      fontFamily: PRIMARY_FONT,
      color: Color.GRAY_DARK,
      lineHeight: '30px',
    },
  },
});

export default THEME;

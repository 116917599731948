import { forEach, replace } from 'lodash';

/**
 * Changes paths for specified list of attributes from relative to absolute
 * @param htmlElement element where we should change paths
 * @param attributes list of attributes to search tags where we need to change paths (e.g. ['src', 'href'])
 * @param relativePathRoot root pathname */
export const changeRelativePathsToAbsolute = (
  htmlElement: Document | undefined,
  attributes: string[],
  relativePathRoot: string
) => {
  if (htmlElement) {
    changePath(htmlElement, attributes, relativePathRoot, setAbsolutePathForAttribute);
  }
  return true;
};

/**
 * Changes paths for specified list of attributes from http to https
 * @param htmlElement element where we should change paths
 * @param attributes list of attributes to search tags where we need to change paths (e.g. ['src', 'href'])
 */
export const changePathFromHttpToHttps = (htmlElement: Document | undefined, attributes: string[]) => {
  if (htmlElement) {
    changePath(htmlElement, attributes, null, setHttpsForLinks);
  }
  return true;
};

/**
 * Changes paths for specified list of attributes by handler function
 * @param htmlElement element where we should change paths
 * @param attributes list of attributes to search tags where we need to change paths (e.g. ['src', 'href'])
 * @param relativePathRoot root pathname
 * @param handlerFunction handler to change path */
const changePath = (
  htmlElement: Document,
  attributes: string[],
  relativePathRoot: string | null,
  handlerFunction: (node: Element, attribute: string, relativePathRoot: string | null) => void
) => {
  const collection = htmlElement.getElementsByTagName('*');
  forEach(collection, (element) => {
    forEach(attributes, (attribute) => {
      handlerFunction(element, attribute, relativePathRoot);
    });
  });
};

/**
 * Sets absolute path instead of relative if provided element has required attribute
 * (e.g. <img src='media/image.jpg' /> => <img src='https://relativeRootPath/media/image.jpg' />) */
export const setAbsolutePathForAttribute = (node: Element, attribute: string, relativePathRoot: string) => {
  if (node.hasAttribute(attribute)) {
    const attributeValue = node.getAttribute(attribute);
    if (attributeValue !== null && !isPathAbsolute(attributeValue)) {
      node.setAttribute(attribute, `${relativePathRoot}${attributeValue}`);
    }
  }
};

/**
 * Checks if provided path is absolute */
export const isPathAbsolute = (path: string) => new RegExp('^(?:(?:[a-z]+:)?//|mailto:)', 'i').test(path);

/**
 * Checks if provided path is http */
export const isHttpPath = (path: string) => new RegExp('^http://.+', 'i').test(path);

/**
 * Sets https path instead of http
 * (e.g. <img src='http://relativeRootPath/media/image.jpg' /> => <img src='https://relativeRootPath/media/image.jpg' />) */
export const setHttpsForLinks = (node: Element, attribute: string) => {
  if (node.hasAttribute(attribute)) {
    const attributeValue = node.getAttribute(attribute);
    if (attributeValue !== null && isHttpPath(attributeValue)) {
      node.setAttribute(attribute, replace(attributeValue, 'http://', 'https://'));
    }
  }
};

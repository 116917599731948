import React from 'react';

import styled from 'styled-components';

import { TextField } from '@material-ui/core';

import { TextFieldProps } from '@component/input';
import { Color } from '@style/Color';
import { ThemeProps, withTheme } from '@style/WithTheme';

import { getInputLabelColor } from './StyledTextField';

const LABEL_MARGIN_LEFT = 12;

export const ConversationTextField = withTheme()(styled<TextFieldProps>(({ fullHeight, noMargin, ...rest }) => (
  <TextField {...rest} />
))`
  && {
    margin: 0px !important;
    margin-bottom: ${(props: TextFieldProps) => (props.error && props.helperText ? '-18px' : '0')} !important;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;

    box-shadow: 0px 3px 6px #0000000f;
    textarea:-webkit-autofill {
      -webkit-box-shadow: ${(props: TextFieldProps & ThemeProps) =>
        `0 0 0px 1000px ${props.theme.palette.textField.lightBackground} inset`};
    }

    textarea::-ms-clear {
      display: none;
    }

    > div {
      padding: 0px !important;
      pointer-events: auto;
      z-index: 0;
      background-color: ${(props: TextFieldProps & ThemeProps) =>
        props.disabled
          ? props.theme.palette.textField.disabledBackground
          : props.theme.palette.textField.lightBackground};
      margin-top: 0px !important;
      width: auto;
      border: none !important;
      border-radius: 4px;

      @media (hover: hover) {
        &:hover {
          background-color: ${(props: TextFieldProps & ThemeProps) =>
            props.disabled
              ? props.theme.palette.textField.disabledBackground
              : props.theme.palette.textField.lightBackground};
        }
      }

      &:focus-within {
        background-color: ${(props: TextFieldProps & ThemeProps) => props.theme.palette.textField.lightBackground};
      }
      &:before {
        border-bottom-width: 0;
        border-bottom-style: solid !important;
        border-bottom-color: ${(props) =>
          props.error
            ? `${props.theme.palette.textField.error} !important`
            : props.disabled
              ? props.theme.palette.textField.normalLabel
              : props.theme.palette.textInput.borderColor} !important;
      }
      &:after {
        background-color: ${(props: TextFieldProps & ThemeProps) =>
          props.error ? `${props.theme.palette.textField.error} !important` : props.theme.palette.primary.main};
        border-bottom: 2px solid
          ${(props: TextFieldProps & ThemeProps) =>
            props.error ? `${props.theme.palette.textField.error} !important` : props.theme.palette.primary.main};
      }
    }

    & > label {
      display: inline-block;
      ${(props: TextFieldProps) => (props.InputLabelProps?.shrink ? '' : 'overflow: hidden;')}
      ${(props: TextFieldProps) => (props.InputLabelProps?.shrink ? '' : 'text-overflow: ellipsis;')}
      pointer-events: none;
      margin: 7px 12px 7px 12px;
      z-index: 1;
      height: 21px;
      font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: left;
      white-space: nowrap;
      transform: ${(props: TextFieldProps) => {
        if (props.InputLabelProps !== undefined) {
          return props.InputLabelProps.shrink
            ? 'translate(0, 0px) scale(0.75);'
            : props.dense
              ? 'translate(0, 15px) scale(1);'
              : 'translate(0, 18px) scale(1);';
        } else {
          return undefined;
        }
      }};
      margin: ${(props: TextFieldProps) => {
        if (props.InputLabelProps !== undefined) {
          return props.InputLabelProps.shrink ? '7px 12px 7px 12px' : '0px 0px 0px 12px';
        } else {
          return '0px';
        }
      }};
      margin-left: ${LABEL_MARGIN_LEFT}px;
      color: ${(props: TextFieldProps & ThemeProps) => getInputLabelColor(props)};
      background-color: ${Color.WHITE};
      width: fit-content;
    }

    > .classes-state-root.disabled {
      color: white;
    }

    > div textarea {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 15px;
      // null  if not android and safari wont have the same color text for disabled
      color: ${(props: TextFieldProps & ThemeProps) =>
        props.disabled ? null : props.theme.palette.textField.normalText};
      height: 20px;
      font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      text-align: left;
    }

    ${(props: TextFieldProps) =>
      props.onClear /* hide X on Edge because we are displaying ours */
        ? ' > div textarea::-ms-clear { display: none; }'
        : ''};

    > p {
      margin-top: 2px;
      margin-left: 12px;
      height: ${(props: TextFieldProps) => (props.isErrorMessageMultiline ? 'auto' : '16px')};
      font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: ${(props: TextFieldProps & ThemeProps) =>
        props.error ? props.theme.palette.textField.error : props.theme.palette.textField.normalText};
    }

    > div div {
      margin-top: 27px;
      margin-bottom: 14px;
      margin-left: 14px;
    }

    > div div div {
      margin: 0px;
    }

    > div div svg {
      display: flex;
      align-content: center;
      height: 20px;
      width: 20px;
      color: ${(props: TextFieldProps & ThemeProps) =>
        props.error ? props.theme.palette.textField.error : props.theme.palette.status.confirmation};
    }

    > div div div svg {
      display: flex;
      align-content: center;
      height: 20px;
      width: 20px;
      color: ${(props: TextFieldProps & ThemeProps) =>
        props.error ? props.theme.palette.textField.error : props.theme.palette.status.confirmation};
    }

    > div div textarea {
      width: 100%;
    }
    > div textarea {
      padding: 0;
    }

    textarea::placeholder {
      font-style: italic;
      font-size: 14px;
      color: ${Color.GRAY_STONE};
    }
  }
`);

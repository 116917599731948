import styled from 'styled-components';

import { Button, Checkbox, FormControl, FormLabel, Radio, Switch } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { RadioProps } from '@material-ui/core/Radio';

import { ThemeProps, withTheme } from '@style/WithTheme';

export type StyledFormControlProps = FormControlProps & {
  inLine?: boolean;
};

export const StyledButton = withTheme()(styled(Button)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    flex: 1;
    align-self: stretch;
    box-shadow: none;
    border: 0 !important;
    padding: 2px 0 !important;
    font-size: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontSize};
    line-height: ${(props: ThemeProps) => props.theme.text.subtitleRegular.lineHeight};
    cursor: pointer;
    :hover {
      background-color: transparent;
      border: 0;
    }
    :active {
      box-shadow: none;
    }
    :focus {
      box-shadow: none;
    }
  }
`);

type StyledLabelProps = {
  inLine?: boolean;
};

export const StyledLabelAfter = styled.label<StyledLabelProps>`
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: center;
  color: ${(props: ThemeProps) => props.theme.palette.text.primary};
  text-transform: initial;
  ${(props) => (props.inLine ? '' : 'padding-left: 15px;')}
  white-space: nowrap;
`;

export const StyledLongLabel = styled.label`
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: flex-start;
  text-align: start;
  color: ${(props: ThemeProps) => props.theme.text.default.color};
  font-size: ${(props: ThemeProps) => props.theme.text.default.fontSize};
  line-height: normal;
  text-transform: initial;
`;
export const StyledLabel = styled(StyledLabelAfter)`
  justify-content: ${(p) => (p.inLine ? 'flex-start' : 'space-between')};
  align-self: stretch;
`;

type StyledRadioProps = RadioProps & {
  inLine?: boolean;
};

export const StyledRadio = styled<StyledRadioProps>(Radio)`
  && {
    padding: 8px;
    ${(p) => (p.inLine ? 'padding-left: 0px;' : '')}
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 8px;
  }
`;

export const StyledSwitch = styled(Switch)`
  padding: 0 !important;
  height: 40px !important;
  & > :first-child {
    height: 40px !important;
  }
`;

export const StyledFormControl = styled<StyledFormControlProps>(FormControl)`
  && {
    align-self: stretch;
    width: 100%;
    color: ${(props: ThemeProps) => props.theme.palette.text.secondary};
    flex-direction: ${(props) => (props.inLine ? 'row' : 'column')};
  }
`;

export const StyledFormLabel = styled(FormLabel)`
  padding: 20px 0 20px 20px !important;
`;

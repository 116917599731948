import React from 'react';

import styled from 'styled-components';

import { UnlockFeaturePopupType } from '@common/model/UserPlan';
import { useUnlockFeaturePopup } from '@component/popup/UnlockFeaturePopup';
import { TappableTextContent } from '@component/text/TappableTextContent';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';

interface UnlockLinkProps {
  popupType: UnlockFeaturePopupType;
}

const PlanLink = styled(TappableTextContent)`
  padding: 0px !important;
  min-width: 0;
`;

const PlanText = styled.span`
  text-decoration: none;
  text-transform: none;
  font-weight: bold;
  font-size: ${FontSize.Content}px;
`;

export const UnlockLink: React.FC<UnlockLinkProps> = (props) => {
  const openUnlockPopup = useUnlockFeaturePopup();

  return (
    <PlanLink id={`${props.popupType}_upgrade_plan`} accent={true} onClick={() => openUnlockPopup(props.popupType)}>
      <PlanText>{t(T.postLoads_Unlock)}</PlanText>
    </PlanLink>
  );
};

import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { displayCurrency } from '@common/formatter';
import { NO_INFORMATION_SYMBOL_LONG } from '@common/formatter/Constants';
import { formatMileage, hasRoles, priorityEquipmentAvailableForRateCheck, toAddress } from '@common/helper';
import { LoadLocation, OriginLocation, Roles, UnlockFeaturePopupType } from '@common/model';
import { fetchRateCheck, fetchTotalMileage } from '@common/redux/epic/PostTruckEpic';
import { ActionIcon } from '@component/icon/ActionIcon';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { useUnlockFeaturePopup } from '@component/popup/UnlockFeaturePopup';
import { StyledPaddedRow } from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { Color } from '@style/Color';
import THEME from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

import { default as PCMilerIcon } from './pc_miler.svg';

const TripInfoBar = styled.div`
  background: ${Color.WHITE};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 24px 0;
  border-radius: 4px;
  padding: 0 10px;
  height: 30px;
  box-sizing: border-box;
`;

const TripInfoText = withTheme()(styled.span`
  font-family: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.fontSize};
  line-height: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.lineHeight};
  color: ${(props: ThemeProps) => props.theme.palette.background.stone};
`);

const TripInfoTextValue = withTheme()(styled.span`
  color: ${(props: ThemeProps) => props.theme.text.defaultLight.color};
`);

const TripInfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RightButton = styled.span`
  && {
    font-weight: ${THEME.text.link.fontWeight};
    color: ${Color.ORANGE_MAIN};
    cursor: pointer;
  }
`;

interface MileageProps {
  locationOrigin?: OriginLocation;
  locationDestination?: LoadLocation;
}

interface RateCheckProps {
  locationOrigin?: OriginLocation;
  locationDestination?: LoadLocation;
}

export const PostTruckMileage: React.FC<MileageProps> = (props) => {
  const dispatch = useDispatch();
  const popupContext = usePopup();
  const openUnlockPopup = useUnlockFeaturePopup();
  const { openingPopup, closingPopup } = popupContext;
  const userRoles = useSelector((state) => state.user.profile?.payload);
  const fetchedMileage = useSelector((state) => state.postTruck.pcMiler.totalPostMileage);
  const totalPostMileageError = useSelector((state) => state.postTruck.pcMiler.mileageHttpError);
  const pcMilerIsLoading = useSelector((state) => state.postTruck.pcMiler.isLoading);
  const isTruckFormLoading = useSelector((state) => state.postTruck.truckAvailabilities.isLoading);

  const doesUserHavePcMiler = hasRoles(userRoles?.roles, [Roles.Pcmiler]);
  const mileageOrigin = toAddress(props.locationOrigin);
  const mileageDestination = toAddress(props.locationDestination);
  const totalPostMileage = mileageOrigin && mileageDestination ? fetchedMileage : undefined;

  useEffect(() => {
    if (mileageOrigin && mileageDestination) {
      const stops = [{ address: mileageOrigin }, { address: mileageDestination }];
      dispatch(fetchTotalMileage(stops));
    }
  }, [props.locationOrigin, props.locationDestination]);

  const openFeatureInfoPopup = (title: any, content: any, testID: string) => {
    openingPopup({
      body: (
        <BasePopup title={title} buttons={[<PopupOkButton testID={testID} key={testID} action={closingPopup} />]}>
          {content}
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

  const renderMileage = () => {
    if ((doesUserHavePcMiler && pcMilerIsLoading) || isTruckFormLoading) {
      return (
        <TripInfoTextValue id="avgRate_loading">
          <LoadingSpinner size={15} />
        </TripInfoTextValue>
      );
    } else if (!doesUserHavePcMiler && !isTruckFormLoading) {
      return (
        <RightButton id="mileage_unlock" onClick={() => openUnlockPopup(UnlockFeaturePopupType.MileageCalculator)}>
          {t(T.findLoads_loadDetails_unlock)}
        </RightButton>
      );
    } else if (doesUserHavePcMiler && totalPostMileageError === 404) {
      return <TripInfoTextValue id="mileage_value_not_available">{t(T.common_na)}</TripInfoTextValue>;
    } else {
      return (
        <TripInfoTextValue id="miles">
          {totalPostMileage
            ? totalPostMileage && t(T.postLoads_Miles, { value: formatMileage(totalPostMileage) })
            : NO_INFORMATION_SYMBOL_LONG}
        </TripInfoTextValue>
      );
    }
  };

  return (
    <StyledPaddedRow>
      <TripInfoBar>
        <TripInfoText id="total_trip">
          {`${t(T.postLoads_totalTrip)}: `}
          {renderMileage()}
        </TripInfoText>
        <TripInfoIconWrapper>
          <img id="pc_miler_icon" src={PCMilerIcon} className="icon" alt="pcmiler" />
          <TripInfoText id="pc_miler">{t(T.postLoads_pcMiler)}</TripInfoText>
          <ActionIcon
            onClick={() =>
              openFeatureInfoPopup(
                t(T.common_infoModal_pcMiler_title),
                t(T.common_infoModal_pcMiler_subtitle),
                'pc_miler_info_confirm'
              )
            }
            id={'rate_per_mile_info'}
            src={icons.info_small}
            alt={'info_button'}
            size={20}
            style={{ paddingLeft: 5 }}
          />
        </TripInfoIconWrapper>
      </TripInfoBar>
    </StyledPaddedRow>
  );
};

export const PostTruckRateCheck: React.FC<RateCheckProps> = (props) => {
  const dispatch = useDispatch();
  const popupContext = usePopup();
  const openUnlockPopup = useUnlockFeaturePopup();
  const { openingPopup, closingPopup } = popupContext;
  const isTruckFormLoading = useSelector((state) => state.postTruck.truckAvailabilities.isLoading);
  const trailerType = useSelector((state) => state.truckAvailabilityForm.truckAvailability.truck?.equipmentType);
  const userRoles = useSelector((state) => state.user.profile?.payload);
  const rateCheckError = useSelector((state) => state.postTruck.rateCheck.rateCheckHttpError);
  const avgRate = useSelector((state) => state.postTruck.rateCheck.avgRateCheck);
  const fetchedMileage = useSelector((state) => state.postTruck.pcMiler.totalPostMileage);
  const rateCheckIsLoading = useSelector((state) => state.postTruck.rateCheck.isLoading);

  const [wasMileageFetched, setWasMileageFetched] = useState(false);

  const mileageOrigin = toAddress(props.locationOrigin);
  const mileageDestination = toAddress(props.locationDestination);
  const doesUserHaveRateCheck = hasRoles(userRoles?.roles, [Roles.Ratecheck]);
  const totalPostedMileage = mileageOrigin && mileageDestination ? fetchedMileage : undefined;
  const equipment = trailerType ? priorityEquipmentAvailableForRateCheck([trailerType]) : undefined;

  useEffect(() => {
    if (mileageOrigin && mileageDestination && equipment) {
      dispatch(
        fetchRateCheck({
          from: mileageOrigin,
          to: mileageDestination,
          equipment: equipment,
        })
      );
    }
    if (totalPostedMileage) {
      setWasMileageFetched(true);
    }
  }, [props.locationOrigin, props.locationDestination, equipment]);

  const openFeatureInfoPopup = (title: any, content: any, testID: string) => {
    openingPopup({
      body: (
        <BasePopup title={title} buttons={[<PopupOkButton testID={testID} key={testID} action={closingPopup} />]}>
          {content}
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

  const renderRateCheck = () => {
    if ((doesUserHaveRateCheck && rateCheckIsLoading) || isTruckFormLoading) {
      return (
        <TripInfoTextValue id="avgRate_loading">
          <LoadingSpinner size={15} />
        </TripInfoTextValue>
      );
    } else if (!doesUserHaveRateCheck && !isTruckFormLoading) {
      return (
        <RightButton id="avg_rate_unlock" onClick={() => openUnlockPopup(UnlockFeaturePopupType.RateCheck)}>
          {t(T.findLoads_loadDetails_unlock)}
        </RightButton>
      );
    } else if (
      (doesUserHaveRateCheck && rateCheckError === 404) ||
      (doesUserHaveRateCheck && equipment === undefined && wasMileageFetched === true)
    ) {
      return <TripInfoTextValue id="ratecheck_value_not_available">{t(T.common_na)}</TripInfoTextValue>;
    } else {
      return (
        <TripInfoTextValue id="avgRate">
          {avgRate
            ? t(T.common_postTruck_avgRate_avgRateLabel, { value: displayCurrency(avgRate, true) })
            : NO_INFORMATION_SYMBOL_LONG}
        </TripInfoTextValue>
      );
    }
  };

  return (
    <StyledPaddedRow>
      <TripInfoBar>
        <TripInfoText id="avg_rate_check">
          {`${t(T.postLoads_averageRateByCheck)}: `}
          {renderRateCheck()}
        </TripInfoText>
        <TripInfoIconWrapper>
          <ActionIcon
            onClick={() =>
              openFeatureInfoPopup(
                t(T.common_infoModal_rateCheck_title),
                t(T.common_infoModal_rateCheck_subtitle),
                'avg_rate_info_confirm'
              )
            }
            id={'rate_per_mile_info'}
            src={icons.info_small}
            alt={'info_button'}
            size={20}
            style={{ paddingLeft: 5 }}
          />
        </TripInfoIconWrapper>
      </TripInfoBar>
    </StyledPaddedRow>
  );
};

import React from 'react';

import { CircularProgress } from '@material-ui/core';

import { LoaderSize } from '@style/StyleConstants';

export const Loader = ({
  size,
  className,
  style,
}: {
  size?: LoaderSize;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <div className={className} style={{ padding: '10px', ...style }}>
    <CircularProgress id="loading" color="secondary" size={size || LoaderSize.small} />
  </div>
);

import React from 'react';

import styled from 'styled-components';

import { ButtonTypes, CustomButton } from '@component/button';
import { Text, TextStyle } from '@component/text';
import { ThemeProps, withTheme } from '@style/WithTheme';

import defaultAlertImage from './boxAlert.svg';

const GenericErrorContainer = withTheme()(styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    margin-top: -76px;
  }
`);

const MessageContainer = styled.div`
  text-align: center;
`;

const EmptyPageIcon = withTheme()(styled.img`
  width: 150px;
  height: 150px;

  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    width: 180px;
    height: 180px;
  }

  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoints.values.lg}px) {
    width: 200px;
    height: 200px;
  }

  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoints.values.xl}px) {
    width: 250px;
    height: 250px;
  }
`);

const TextContainer = styled.div`
  margin: 25px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TopPadding = withTheme()(styled.div`
  height: 36px;
  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    height: 92px;
  }
`);

const BottomPadding = withTheme()(styled.div`
  height: 36px;
  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoints.values.sm}px) {
    min-height: 36px;
    max-height: 92px;
    flex: 1 1;
  }
`);

interface Props {
  id: string;
  message: string;
  subMessage: string;
  linkTitle: string;
  link?: string;
  imageSrc?: any;
  onClick?: () => void;
}

export const GenericError: React.FC<Props> = (props) => {
  const imageSrc = props.imageSrc || defaultAlertImage;

  return (
    <GenericErrorContainer id={props.id}>
      <TopPadding />
      <CenteringContainer>
        <EmptyPageIcon id={props.id + '_icon'} src={imageSrc} />
        <TextContainer>
          <Text id={props.id + '_text'} textStyle={TextStyle.NakedPageTitle}>
            {props.message}
          </Text>
          <MessageContainer>
            <Text id={props.id + '_sub_message_text'} textStyle={TextStyle.NakedPageMessage}>
              {props.subMessage}
            </Text>
          </MessageContainer>
        </TextContainer>
        <CustomButton
          id={props.id + '_button'}
          buttonType={ButtonTypes.PrimaryButton}
          href={props.link}
          onClick={props.onClick}
        >
          {props.linkTitle}
        </CustomButton>
      </CenteringContainer>
      <BottomPadding />
    </GenericErrorContainer>
  );
};

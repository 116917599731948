export interface UserAuthentication {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  message?: string;
}

export enum ExternalGrantProvider {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}

import { createDefaultLoadPostSearchRequest, createLoadSearchMetadata } from '@common/helper';
import { LoadSortCategory, LoadSortDirection, LoadStatus, PosterLoadSearchRequest } from '@common/model';

const DEFAULT_RECENT_LOADS_LIMIT = 15;

export const createDefaultRecentPostedLoadsRequest = (): PosterLoadSearchRequest => ({
  ...createDefaultLoadPostSearchRequest(),
  includeStatuses: `${LoadStatus.Online}, ${LoadStatus.Offline}`,
  statusFilter: LoadStatus.Online,
  sortBy: {
    field: LoadSortCategory.BROKER_LAST_UPDATE_AGE,
    direction: LoadSortDirection.Asc,
  },

  metadata: createLoadSearchMetadata({
    offset: 0,
    limit: DEFAULT_RECENT_LOADS_LIMIT,
    sortBy: {
      field: LoadSortCategory.BROKER_LAST_UPDATE_AGE,
      direction: LoadSortDirection.Asc,
    },
  }),
});

export const createDefaultLoadTemplatesRequest = () => ({
  offset: 0,
  limit: DEFAULT_RECENT_LOADS_LIMIT,
});

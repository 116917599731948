import { clone, first, forEach, isEmpty } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { iif as if$, merge as merge$, of as of$ } from 'rxjs';
import { mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiResponse123 } from '@common/api';
import { BidsClient } from '@common/client/BidsClient';
import { convertBidDetailsToBidSummary, getBidAck } from '@common/helper/BidsHelper';
import {
  Bid,
  BidActionRequest,
  BidDetails,
  BiddingRole,
  BiddingSummaryRequest,
  BiddingSummaryResponse,
  BidsSummariesRequest,
  BidStatus,
  BidSummariesResponse,
  CarrierInfo,
  LoadBidsRequest,
  LoadBidsResponse,
  LoadStatus,
  LoadWithDataForBiddingOnly,
  LoadWithStatusOnly,
  PostBidRequest,
  UpdateBidEvent,
  UpdateBidEventType,
} from '@common/model';
import {
  ApiAction,
  createAction,
  createApiAction,
  createApiActionWithFetchData,
  ResultResponseActionWithFetchData,
} from '@common/redux/Base';
import {
  addBidSummaryFromBidDetails,
  BidsState,
  handleBidEditOrCounterOffer,
  processBidUpdate,
  processNewBid,
  updateBidSummaryFromBidDetails,
} from '@common/redux/epic/bids/BidsStateHelper';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { clearUnreferencedEntities, FetchStatus } from '@common/redux/NormalizationHelper';
import { createMergedReducer } from '@common/redux/ReduxHelper';

type BidsStateObservable = StateObservable<{ bids: BidsState }>;
type BidUpdateEventData = { eventType: UpdateBidEventType; event: UpdateBidEvent; role: BiddingRole };
type BidUpdateEventAction = ApiAction<BidUpdateEventData>;
type BidLoadDetailsPayload = { loadSearchType: LoadSearchType; loadId: string };

const RECEIVED_BID_UPDATE = 'RECEIVED_BID_UPDATE';

const getBiddingSummaryAction = createApiActionWithFetchData<BiddingSummaryRequest, BiddingSummaryResponse>(
  'GET_BIDDING_SUMMARY'
);
const getBiddingSummaryListAction = createApiAction<LoadBidsRequest, BidSummariesResponse>('FETCH_BIDDING_SUMMARY');

const getBidSummariesAction = createApiAction<BidsSummariesRequest, BidSummariesResponse>('FETCH_BID_SUMMARIES');
const getBidSummaryAction = createApiAction<{ bidId: string }, BidDetails>('GET_BID_SUMMARY');
const receivedBidUpdateEventAction = createAction<BidUpdateEventData>(RECEIVED_BID_UPDATE);

const incrementViewingBidsCountAction = createAction<undefined>('INCREMENT_VIEWING_COUNT');
const decrementViewingBidsCountAction = createAction<undefined>('DECREMENT_VIEWING_COUNT');

const getLoadBidsAction = createApiActionWithFetchData<LoadBidsRequest, LoadBidsResponse>('FETCH_LOAD_BIDS');
const getBidAction = createApiActionWithFetchData<{ bidId: string; isSilent: boolean; isUpdate: boolean }, BidDetails>(
  'GET_BID'
);

const markAllBidsAsViewedAction = createApiAction<undefined, {}>('MARK_ALL_BIDS_AS_VIEWED');

const clearFailedGetBidAction = createAction<{ bidId: string }>('CLEAR_FAILED_GET_BID');

const getBidLoadIsOnlineAction = createApiActionWithFetchData<BidLoadDetailsPayload, LoadWithStatusOnly>(
  'GET_BID_LOAD_IS_ONLINE'
);
const getBidLoadDetailsAction = createApiActionWithFetchData<BidLoadDetailsPayload, LoadWithDataForBiddingOnly>(
  'GET_BID_LOAD_DETAILS'
);

const actOnBidAction = createApiActionWithFetchData<BidActionRequest, BidDetails>('ACT_ON_BID');
const clearFailedBidActionAction = createAction<{ bidId: string }>('CLEAR_FAILED_BID_ACTION');
const postBidAction = createApiActionWithFetchData<PostBidRequest, BidDetails>('POST_BID');

const getCarrierInfoAction = createApiActionWithFetchData<{ bidId: string }, CarrierInfo>('GET_CARRIER_INFO');

export const PostBidResponseType = postBidAction.responseType;
export type PostBidResponseAction = ResultResponseActionWithFetchData<BidDetails, PostBidRequest>;

export const receivedBidUpdateEvent = (eventType: UpdateBidEventType, event: UpdateBidEvent, role?: BiddingRole) =>
  receivedBidUpdateEventAction.action({ eventType: eventType, event: event, role: role ?? BiddingRole.Carrier });

export const incrementViewingBidsCount = () => incrementViewingBidsCountAction.action(undefined);
export const decrementViewingBidsCount = () => decrementViewingBidsCountAction.action(undefined);

export const fetchBidSummaries = (roles?: BiddingRole[], status?: BidStatus[]) =>
  getBidSummariesAction.fetchAction({ roles: roles ?? [BiddingRole.Carrier], status: status });

export const fetchMoreBidSummaries = (nextToken: string, roles?: BiddingRole[], status?: BidStatus[]) =>
  getBidSummariesAction.fetchAction({ roles: roles ?? [BiddingRole.Carrier], token: nextToken, status: status });

export const fetchBidSummary = getBidSummaryAction.fetchAction;

export const fetchLoadBids = getLoadBidsAction.fetchAction;

export const getBiddingSummary = (role: BiddingRole) => getBiddingSummaryAction.fetchAction({ role: role });

export const fetchBiddingSummaryList = getBiddingSummaryListAction.fetchAction;

export const fetchMoreBiddingSummaryList = getBiddingSummaryListAction.fetchAction;

const updateBid = (bidId: string) => getBidAction.fetchAction({ bidId: bidId, isSilent: false, isUpdate: true });

const silentlyGetBid = (bidId: string, isUpdate: boolean) =>
  getBidAction.fetchAction({ bidId: bidId, isSilent: true, isUpdate: isUpdate });

export const clearFailedGetBid = (bidId: string) => clearFailedGetBidAction.action({ bidId: bidId });

export const getBidLoadIsOnline = (loadSearchType: LoadSearchType, loadId: string) =>
  getBidLoadIsOnlineAction.fetchAction({ loadSearchType: loadSearchType, loadId: loadId });

export const getBidLoadDetails = (loadSearchType: LoadSearchType, loadId: string) =>
  getBidLoadDetailsAction.fetchAction({ loadSearchType: loadSearchType, loadId: loadId });

export const markAllBidsAsViewed = () => markAllBidsAsViewedAction.fetchAction(undefined);

export const actOnBid = actOnBidAction.fetchAction;

export const ackBid = (bid: Bid) => {
  const ack = getBidAck(bid);
  return ack ? actOnBid({ action: ack, bidId: bid.bidId }) : undefined;
};

export const clearFailedBidAction = (bidId: string) => clearFailedBidActionAction.action({ bidId: bidId });

export const postBid = postBidAction.fetchAction;

export const getBidCarrierInfo = (bidId: string) => getCarrierInfoAction.fetchAction({ bidId: bidId });

const createInitialBidLoadDetailsState = () => ({
  isLoading: false,
  loadId: '',
});

const initialState: BidsState = {
  viewingBidsCount: 0,
  summary: {
    carrierUnreadBids: 0,
    brokerUnreadBids: 0,
  },
  bidSummaries: {
    listAll: {
      isLoading: false,
      didFailToLoad: false,
      ids: [],
      isLastPage: false,
    },
    listPerLoad: {
      loadId: '',
      isLoading: false,
      didFailToLoad: false,
      ids: [],
      isLastPage: false,
    },
    isLoadingSingleBidSummary: false,
    summaries: new Map(),
  },
  biddingSummary: {
    listAll: {
      loadId: '',
      isLoading: false,
      didFailToLoad: false,
      isLastPage: false,
      ids: [],
    },
    summaries: new Map(),
    fetchingBids: new Map(),
  },
  loadBids: {
    listAll: {
      loadId: '',
      carrierId: '',
      isLoading: false,
      didFailToLoad: false,
      isLastPage: false,
      ids: [],
    },
    entities: new Map(),
    fetchingBids: new Map(),
  },
  bidLoadDetails: {
    [LoadSearchType.LoadSearch]: createInitialBidLoadDetailsState(),
    [LoadSearchType.Backhaul]: createInitialBidLoadDetailsState(),
    [LoadSearchType.CompanySearch]: createInitialBidLoadDetailsState(),
    [LoadSearchType.LoadAvailability]: createInitialBidLoadDetailsState(),
    [LoadSearchType.Posted]: createInitialBidLoadDetailsState(),
  },
  bidActions: {
    actionsInProgress: new Map(),
    actionsFailed: new Map(),
  },
  postBid: {
    isPosting: false,
  },
  bidsCarrierInfo: {
    isLoading: false,
  },
  markAllBidsAsViewed: {
    isLoading: false,
    wereAllMarkedAsViewed: false,
  },
};

export const bidsReducer = createMergedReducer(initialState, [
  getBiddingSummaryAction.completeCase((state, action) => {
    const biddingRole = action.fetchData?.role;
    if (biddingRole && action.response.success) {
      const countUnread = action.response.payload.offerUpdatesCount;
      if (biddingRole === BiddingRole.Carrier) {
        state.summary.carrierUnreadBids = countUnread;
      } else {
        state.summary.brokerUnreadBids = countUnread;
      }
    }
  }),
  incrementViewingBidsCountAction.addCase((state) => {
    state.viewingBidsCount += 1;
  }),
  decrementViewingBidsCountAction.addCase((state) => {
    state.viewingBidsCount -= 1;
  }),
  getBidSummariesAction.initiateCase((state, action) => {
    state.bidSummaries.listAll.isLoading = true;
    state.bidSummaries.listAll.nextToken = action.data.token;
    state.bidSummaries.listAll.selectedFilters = action.data.status;
  }),
  getBidSummariesAction.completeCase((state, action) => {
    state.bidSummaries.listAll.isLoading = false;
    if (action.response.success) {
      if (state.bidSummaries.listAll.ids.length > 0 && state.bidSummaries.listAll.nextToken) {
        const modifiedBidSummaryIds = clone(state.bidSummaries.listAll.ids);
        forEach(action.response.payload.bids, (bidSummary) => {
          modifiedBidSummaryIds.push(bidSummary.bidId);
          state.bidSummaries.summaries.set(bidSummary.bidId, bidSummary);
        });
        state.bidSummaries.listAll.ids = modifiedBidSummaryIds;
      } else {
        const bidSummariesIds: string[] = [];
        // if this is a new request, clear any newly unreferenced entities from memory
        state.bidSummaries.summaries = clearUnreferencedEntities(
          [...state.bidSummaries.listAll.ids, ...state.bidSummaries.listPerLoad.ids],
          state.bidSummaries.summaries
        );
        forEach(action.response.payload.bids, (bidSummary) => {
          bidSummariesIds.push(bidSummary.bidId);
          state.bidSummaries.summaries.set(bidSummary.bidId, bidSummary);
        });
        state.bidSummaries.listAll.ids = bidSummariesIds;
      }
      state.bidSummaries.listAll.isLastPage = !action.response.payload.hasMore || isEmpty(action.response.payload.bids);
      state.bidSummaries.listAll.nextToken = action.response.payload.token;
      state.bidSummaries.listAll.didFailToLoad = false;
    } else {
      state.bidSummaries.listAll.didFailToLoad = true;
      state.bidSummaries.listAll.selectedFilters = undefined;
    }
  }),
  getBidSummaryAction.initiateCase((state) => {
    state.bidSummaries.isLoadingSingleBidSummary = true;
  }),
  getBidSummaryAction.completeCase((state, action) => {
    state.bidSummaries.isLoadingSingleBidSummary = false;
    if (action.response.success) {
      const bidDetails = action.response.payload;
      state.bidSummaries.summaries.set(bidDetails.bidId, convertBidDetailsToBidSummary(bidDetails));
    }
  }),
  getBiddingSummaryListAction.initiateCase((state, action) => {
    state.biddingSummary.listAll.isLoading = true;
    if (isEmpty(action.data.token)) {
      state.biddingSummary.listAll.loadId = action.data.loadId;
    }
    state.biddingSummary.listAll.nextToken = action.data.token;
  }),
  getBiddingSummaryListAction.completeCase((state, action) => {
    state.biddingSummary.listAll.isLoading = false;

    if (action.response.success) {
      if (state.biddingSummary.listAll.ids.length > 0 && state.biddingSummary.listAll.nextToken) {
        const modifiedBidIds = clone(state.biddingSummary.listAll.ids);
        forEach(action.response.payload.bids, (bid) => {
          modifiedBidIds.push(bid.bidId);
          state.biddingSummary.summaries.set(bid.bidId, bid);
        });
        state.biddingSummary.listAll.ids = modifiedBidIds;
      } else {
        const bidIds: string[] = [];
        state.biddingSummary.summaries = clearUnreferencedEntities(
          state.biddingSummary.listAll.ids,
          state.biddingSummary.summaries
        );
        forEach(action.response.payload.bids, (bid) => {
          bidIds.push(bid.bidId);
          state.biddingSummary.summaries.set(bid.bidId, bid);
        });
        state.biddingSummary.listAll.ids = bidIds;
      }
      state.biddingSummary.listAll.isLastPage =
        !action.response.payload.hasMore || isEmpty(action.response.payload.bids);
      state.biddingSummary.listAll.nextToken = action.response.payload.token;
      state.biddingSummary.listAll.didFailToLoad = false;
    } else {
      state.biddingSummary.listAll.didFailToLoad = true;
    }
  }),

  getLoadBidsAction.initiateCase((state, action) => {
    state.loadBids.listAll.isLoading = true;
    if (isEmpty(action.data.token)) {
      state.loadBids.listAll.loadId = action.data.loadId;
      state.loadBids.listAll.carrierId = action.data.carrierId ?? 'self';
    }
    state.loadBids.listAll.nextToken = action.data.token;
  }),
  getLoadBidsAction.completeCase((state, action) => {
    state.loadBids.listAll.isLoading = false;
    if (action.response.success) {
      // @FIXME: when the pagination for history bids is implemented check if we need to include
      // the token in this flag
      const didLoadChange = state.loadBids.listAll.loadId !== action.fetchData?.loadId;

      if (didLoadChange) {
        return;
      }

      if (state.loadBids.listAll.ids.length > 0 && state.loadBids.listAll.nextToken) {
        const modifiedBidIds = clone(state.loadBids.listAll.ids);
        forEach(action.response.payload.bids, (bid) => {
          modifiedBidIds.push(bid.bidId);
          state.loadBids.entities.set(bid.bidId, bid);
        });
        state.loadBids.listAll.ids = modifiedBidIds;
      } else {
        // if this is a new request, clear any newly unreferenced entities from memory
        state.loadBids.entities = clearUnreferencedEntities(state.loadBids.listAll.ids, state.loadBids.entities);
        const bidIds: string[] = [];
        forEach(action.response.payload.bids, (bid) => {
          bidIds.push(bid.bidId);
          state.loadBids.entities.set(bid.bidId, bid);
        });
        state.loadBids.listAll.ids = bidIds;
        const mostRecentBid = first(action.response.payload.bids);
        if (mostRecentBid) {
          // The bid summary is used by UI to keep track of when to send bid Acks
          // in the Bid Summaries screen. Here, we add a Bid Summary if necessary.
          const matchingBidSummary = state.bidSummaries.summaries.get(mostRecentBid.bidId);
          if (!matchingBidSummary) {
            addBidSummaryFromBidDetails(state, {
              ...mostRecentBid,
              isMostRecentOffer: true,
              isViewed: false,
            });
          }
        }
      }
      state.loadBids.listAll.isLastPage = !action.response.payload.hasMore || isEmpty(action.response.payload.bids);
      state.loadBids.listAll.nextToken = action.response.payload.token;
      state.loadBids.listAll.didFailToLoad = false;
    } else {
      state.loadBids.listAll.didFailToLoad = true;
    }
  }),
  getBidAction.initiateCase((state, action) => {
    if (!action.data.isSilent) {
      state.loadBids.fetchingBids.set(action.data.bidId, FetchStatus.Loading);
    }
  }),
  getBidAction.completeCase((state, action) => {
    if (action.fetchData) {
      const { bidId, isSilent, isUpdate } = action.fetchData;
      if (!isSilent) {
        state.loadBids.fetchingBids.delete(bidId);
      }
      if (action.response.success) {
        if (isUpdate) {
          processBidUpdate(state, action.response.payload);
        } else {
          processNewBid(state, action.response.payload);
        }
      } else if (!isSilent) {
        state.loadBids.fetchingBids.set(bidId, FetchStatus.Failed);
      }
    }
  }),
  markAllBidsAsViewedAction.initiateCase((state) => {
    state.markAllBidsAsViewed.isLoading = true;
    state.markAllBidsAsViewed.wereAllMarkedAsViewed = false;
  }),
  markAllBidsAsViewedAction.completeCase((state, action) => {
    state.markAllBidsAsViewed.isLoading = false;
    state.markAllBidsAsViewed.wereAllMarkedAsViewed = action.response.success;
  }),
  clearFailedGetBidAction.addCase((state, action) => {
    state.loadBids.fetchingBids.delete(action.data.bidId);
  }),
  actOnBidAction.initiateCase((state, action) => {
    state.bidActions.actionsInProgress.set(action.data.bidId, action.data.action);
  }),
  actOnBidAction.completeCase((state, action) => {
    if (action.fetchData) {
      const bidId = action.fetchData.bidId;
      state.bidActions.actionsInProgress.delete(bidId);
      if (action.response.success) {
        const updatedBidData = action.response.payload;
        const existingBidData = state.loadBids.entities.get(bidId);
        state.loadBids.entities.set(bidId, {
          ...existingBidData,
          ...updatedBidData,
        });
        const matchingBidSummary = state.bidSummaries.summaries.get(bidId);
        if (matchingBidSummary) {
          updateBidSummaryFromBidDetails(state, matchingBidSummary, updatedBidData, true);
        }
      } else {
        const bidAction = action.fetchData.action;
        state.bidActions.actionsFailed.set(bidId, bidAction);
      }
    }
  }),
  clearFailedBidActionAction.addCase((state, action) => {
    state.bidActions.actionsFailed.delete(action.data.bidId);
  }),
  postBidAction.initiateCase((state) => {
    state.postBid.isPosting = true;
    state.postBid.didPostSuccessfully = undefined;
    state.postBid.postedBidId = undefined;
  }),
  postBidAction.completeCase((state, action) => {
    state.postBid.isPosting = false;
    if (action.response.success) {
      const newBid = action.response.payload;
      const previousBidId = action.fetchData?.previousBidId;
      if (previousBidId) {
        handleBidEditOrCounterOffer(state, previousBidId, newBid);
      } else {
        addBidSummaryFromBidDetails(state, newBid);
      }
      state.postBid.didPostSuccessfully = true;
      state.postBid.postedBidId = newBid.bidId;
    } else {
      state.postBid.didPostSuccessfully = false;
    }
  }),
  getBidLoadIsOnlineAction.completeCase((state, action) => {
    const loadSearchType = action.fetchData?.loadSearchType;
    if (loadSearchType !== undefined) {
      if (action.response.success) {
        const existingState = state.bidLoadDetails[loadSearchType];
        if (existingState.load && action.response.payload.id !== existingState.load.id) {
          state.bidLoadDetails[loadSearchType].load = undefined;
        }
        state.bidLoadDetails[loadSearchType].loadId = action.response.payload.id;
        state.bidLoadDetails[loadSearchType].isOnline = action.response.payload.status === LoadStatus.Online;
      } else {
        state.bidLoadDetails[loadSearchType].isOnline = undefined;
      }
    }
  }),
  getBidLoadDetailsAction.initiateCase((state, action) => {
    state.bidLoadDetails[action.data.loadSearchType].isLoading = true;
  }),
  getBidLoadDetailsAction.completeCase((state, action) => {
    const loadSearchType = action.fetchData?.loadSearchType;
    if (loadSearchType !== undefined) {
      state.bidLoadDetails[loadSearchType].isLoading = false;
      if (action.response.success) {
        if (action.response.payload.id !== state.bidLoadDetails[loadSearchType].loadId) {
          state.bidLoadDetails[loadSearchType].isOnline = undefined;
        }
        state.bidLoadDetails[loadSearchType].loadId = action.response.payload.id;
        state.bidLoadDetails[loadSearchType].load = action.response.payload;
      }
    }
  }),
  getCarrierInfoAction.initiateCase((state) => {
    state.bidsCarrierInfo.isLoading = true;
  }),
  getCarrierInfoAction.completeCase((state, action) => {
    state.bidsCarrierInfo.isLoading = false;
    if (action.response.success) {
      state.bidsCarrierInfo = action.response.payload;
    }
  }),
]);

const postBid$ = (action$: ActionsObservable<Action>, bidsClient: BidsClient) =>
  action$.ofType(postBidAction.fetchType).pipe(
    mergeMap$((action: ApiAction<PostBidRequest>) =>
      bidsClient.postBid$(action.data).pipe(
        mergeMap$((response: ApiResponse123<BidDetails>) =>
          merge$(
            of$(postBidAction.responseAction(response, action)),
            if$(
              () => !isEmpty(action.data?.previousBidId) && response.success,
              of$(updateBid(action.data?.previousBidId ?? ''))
            )
          )
        )
      )
    )
  );

const receivedBidUpdateEvent$ = (action$: ActionsObservable<Action>, state$: BidsStateObservable) =>
  action$.ofType(RECEIVED_BID_UPDATE).pipe(
    mergeMap$((action: BidUpdateEventAction) => {
      const actions: Action[] = [getBiddingSummary(action.data.role)];
      if (state$.value.bids.viewingBidsCount > 0) {
        forEach(action.data.event.bids, (bid) => {
          const eventBidId = bid.bidId;

          switch (action.data.eventType) {
            case UpdateBidEventType.NewBids: {
              const topLoadBidId = first(state$.value.bids.loadBids.listAll.ids);
              // if we have this bid already at the top of our load bids,
              // which is possible if the user just created it in the app,
              // then we don't have to fetch it again since we have the
              // latest data already
              if (!topLoadBidId || topLoadBidId !== eventBidId) {
                actions.push(silentlyGetBid(eventBidId, false));
              }

              const topLoadBiddingSummaryId = first(state$.value.bids.biddingSummary.listAll.ids);
              if (
                action.data.role === BiddingRole.Broker &&
                (!topLoadBiddingSummaryId || topLoadBiddingSummaryId !== eventBidId)
              ) {
                actions.push(silentlyGetBid(bid.bidId, false));
              }

              break;
            }
            case UpdateBidEventType.UpdatedBids:
              actions.push(silentlyGetBid(bid.bidId, true));
              break;
          }
        });
      }
      return of$(...actions);
    })
  );

export const createBidsEpic = (api: Api) => {
  const client = new BidsClient(api);
  return (action$: ActionsObservable<Action>, state$: BidsStateObservable) =>
    merge$(
      getBiddingSummaryAction.createEpic$(action$, client.getBiddingSummary$),
      getBidSummaryAction.createEpic$(action$, ({ bidId }) => client.getBid$(bidId)),
      receivedBidUpdateEvent$(action$, state$),
      getBidSummariesAction.createEpic$(action$, client.getBidSummaries$),
      getBiddingSummaryListAction.createEpic$(action$, client.getBiddingSummaryList$),
      getLoadBidsAction.createEpic$(action$, client.getBidSummary$),
      getBidAction.createEpic$(action$, ({ bidId }) => client.getBid$(bidId)),
      actOnBidAction.createEpic$(action$, client.actOnBid$),
      postBid$(action$, client),
      getBidLoadIsOnlineAction.createEpic$(action$, ({ loadId }) => client.getLoadIsOnline$(loadId)),
      getBidLoadDetailsAction.createEpic$(action$, ({ loadId }) => client.getBidLoadDetails$(loadId)),
      getCarrierInfoAction.createEpic$(action$, ({ bidId }) => client.getCarrierInfo$(bidId)),
      markAllBidsAsViewedAction.createEpic$(action$, client.markAllBidsAsViewed$)
    );
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as observableMerge } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { FeedbackClient } from '@common/client';
import { FeedbackRequest, PostedLoadsUrl } from '@common/model';
import { BaseState, Response } from '@common/redux/Base';

const REQUEST_FTP_ACCESS = 'REQUEST_FTP_ACCESS';
const REQUEST_URL_SCRAPE = 'REQUEST_URL_SCRAPE';
const REQUEST_UPLOAD_CSV = 'REQUEST_UPLOAD_CSV';
const REQUEST_FEEDBACK = 'REQUEST_FEEDBACK';
const REQUEST_BROKER_FEATURE = 'REQUEST_BROKER_FEATURE';
export const FTP_ACCESS_REQUESTED = 'FTP_ACCESS_REQUESTED';
export const URL_SCRAPE_REQUESTED = 'URL_SCRAPE_REQUESTED';
export const UPLOAD_CSV_REQUESTED = 'UPLOAD_CSV_REQUESTED';
export const FEEDBACK_REQUESTED = 'FEEDBACK_REQUESTED';
export const REQUESTED_BROKER_FEATURE = 'REQUESTED_BROKER_FEATURE';
export type FeedbackResponse = Response<void>;

interface RequestURLScrape extends Action {
  url: PostedLoadsUrl;
}

interface RequestCSVUpload extends Action {
  file: FormData;
}

interface RequestFeedback extends Action {
  feedback: FeedbackRequest;
}

interface RequestFeedbackResponse extends Action {
  response: FeedbackResponse;
  isNewUpload?: boolean;
}
interface RequestFeature extends Action {
  isBroker: boolean;
}

export function requestFTPAccess(): Action {
  return { type: REQUEST_FTP_ACCESS };
}

export function requestBrokerFeature(isBroker: boolean): RequestFeature {
  return {
    type: REQUEST_BROKER_FEATURE,
    isBroker: isBroker,
  };
}

export function requestURLScrape(url: PostedLoadsUrl): RequestURLScrape {
  return {
    type: REQUEST_URL_SCRAPE,
    url: url,
  };
}

export function requestCSVUpload(file: FormData): RequestCSVUpload {
  return {
    type: REQUEST_UPLOAD_CSV,
    file: file,
  };
}

export function requestFeedback(topic: string, feedbackText: string, height?: number, width?: number): RequestFeedback {
  return {
    type: REQUEST_FEEDBACK,
    feedback: {
      topic: topic,
      content: feedbackText,
      extraInfo: height && width ? `screenHeight: ${height}, screenWidth: ${width}` : undefined,
    },
  };
}

export interface FeedbackState extends BaseState {
  responseType: string;
  response: FeedbackResponse;
  isNewUpload?: boolean;
  isFTPLoading: boolean;
  isUploadLoading: boolean;
  isScrapeLoading: boolean;
  isFeedbackLoading: boolean;
  isBrokerFeatureRequestLoading: boolean;
}

const requestFeedbackResponse = (
  response: FeedbackResponse,
  type: string,
  isNewUpload?: boolean
): RequestFeedbackResponse => ({
  type: type,
  response: response,
  isNewUpload: isNewUpload,
});

const initialState: FeedbackState = {
  response: { success: false },
  responseType: '',
  isFTPLoading: false,
  isUploadLoading: false,
  isScrapeLoading: false,
  isFeedbackLoading: false,
  isLoading: false,
  isBrokerFeatureRequestLoading: false,
};

export const feedbackReducer = (state: FeedbackState = initialState, action: Action) => {
  switch (action.type) {
    case URL_SCRAPE_REQUESTED: {
      const scrapeAction = action as RequestFeedbackResponse;
      return {
        ...state,
        responseType: scrapeAction.type,
        response: scrapeAction.response,
        isScrapeLoading: false,
        isLoading: false,
      };
    }
    case FTP_ACCESS_REQUESTED: {
      const FTPAction = action as RequestFeedbackResponse;
      return {
        ...state,
        responseType: FTPAction.type,
        response: FTPAction.response,
        isFTPLoading: false,
        isLoading: false,
      };
    }
    case UPLOAD_CSV_REQUESTED: {
      const uploadCSVAction = action as RequestFeedbackResponse;
      return {
        ...state,
        responseType: uploadCSVAction.type,
        response: uploadCSVAction.response,
        isLoading: false,
        isUploadLoading: false,
        isNewUpload: uploadCSVAction.isNewUpload,
      };
    }
    case FEEDBACK_REQUESTED: {
      const feedbackAction = action as RequestFeedbackResponse;
      return {
        ...state,
        responseType: feedbackAction.type,
        response: feedbackAction.response,
        isLoading: false,
        isFeedbackLoading: false,
      };
    }
    case REQUESTED_BROKER_FEATURE: {
      const feedbackAction = action as RequestFeedbackResponse;
      return {
        ...state,
        responseType: feedbackAction.type,
        response: feedbackAction.response,
        isLoading: false,
        isBrokerFeatureRequestLoading: false,
      };
    }
    case REQUEST_FTP_ACCESS: {
      return {
        ...state,
        isFTPLoading: true,
        isLoading: true,
      };
    }
    case REQUEST_URL_SCRAPE: {
      return {
        ...state,
        isScrapeLoading: true,
        isLoading: true,
      };
    }
    case REQUEST_UPLOAD_CSV: {
      return {
        ...state,
        isLoading: true,
        isUploadLoading: true,
        isNewUpload: false,
      };
    }
    case REQUEST_FEEDBACK: {
      return {
        ...state,
        isLoading: true,
        isFeedbackLoading: true,
      };
    }
    case REQUEST_BROKER_FEATURE: {
      return {
        ...state,
        isLoading: true,
        isBrokerFeatureRequestLoading: true,
      };
    }
    default: {
      return state;
    }
  }
};

const requestFTPAccess$ = (client: FeedbackClient, action$: ActionsObservable<Action>) =>
  action$.ofType(REQUEST_FTP_ACCESS).pipe(
    mergeMap(() => {
      return client.requestFTPIntegration$().pipe(
        map((response: ApiResponse123<{}>) => {
          if (response.success) {
            return requestFeedbackResponse({ success: response.success }, FTP_ACCESS_REQUESTED);
          } else {
            const error = response as ApiError;
            return requestFeedbackResponse({ success: response.success, error: error }, FTP_ACCESS_REQUESTED);
          }
        })
      );
    })
  );

const NEW_UPLOAD_SUCCESS_CODE = 2020002;

const requestBrokerFeature$ = (client: FeedbackClient, action$: ActionsObservable<Action>) =>
  action$.ofType(REQUEST_BROKER_FEATURE).pipe(
    mergeMap((FeatureRequest) => {
      return client.requestBrokerFeature$((FeatureRequest as RequestFeature).isBroker).pipe(
        map((response: ApiResponse123<{}>) => {
          if (response.success) {
            return requestFeedbackResponse({ success: response.success }, REQUESTED_BROKER_FEATURE);
          } else {
            const error = response as ApiError;
            return requestFeedbackResponse({ success: response.success, error: error }, REQUESTED_BROKER_FEATURE);
          }
        })
      );
    })
  );

const requestCSVUpload$ = (client: FeedbackClient, action$: ActionsObservable<Action>) =>
  action$.ofType(REQUEST_UPLOAD_CSV).pipe(
    mergeMap((CVSRequest) => {
      return client.requestCSVUpload$((CVSRequest as RequestCSVUpload).file).pipe(
        map((response: ApiResponse123<{}>) => {
          if (response.success) {
            const successResponse = response as ApiResponseSuccess<{ code: number }>;
            const isNewUpload = successResponse.data ? successResponse.data.code === NEW_UPLOAD_SUCCESS_CODE : false;
            return requestFeedbackResponse({ success: response.success }, UPLOAD_CSV_REQUESTED, isNewUpload);
          } else {
            const error = response as ApiError;
            return requestFeedbackResponse({ success: response.success, error: error }, UPLOAD_CSV_REQUESTED);
          }
        })
      );
    })
  );

const requestURLScrape$ = (client: FeedbackClient, action$: ActionsObservable<Action>) =>
  action$.ofType(REQUEST_URL_SCRAPE).pipe(
    mergeMap((URLRequest) => {
      return client.requestURLScrape$((URLRequest as RequestURLScrape).url).pipe(
        map((response: ApiResponse123<{}>) => {
          if (response.success) {
            return requestFeedbackResponse({ success: response.success }, URL_SCRAPE_REQUESTED);
          } else {
            const error = response as ApiError;
            return requestFeedbackResponse({ success: response.success, error: error }, URL_SCRAPE_REQUESTED);
          }
        })
      );
    })
  );

const requestFeedback$ = (client: FeedbackClient, action$: ActionsObservable<Action>) =>
  action$.ofType(REQUEST_FEEDBACK).pipe(
    mergeMap((Feedback) => {
      return client.requestFeedback$((Feedback as RequestFeedback).feedback).pipe(
        map((response: ApiResponse123<string>) => {
          if (response.success) {
            return requestFeedbackResponse({ success: response.success }, FEEDBACK_REQUESTED);
          } else {
            const error = response as ApiError;
            return requestFeedbackResponse({ success: response.success, error: error }, FEEDBACK_REQUESTED);
          }
        })
      );
    })
  );

export const createFeedbackEpic = (api: Api) => {
  const feedbackClient = new FeedbackClient(api);

  return (action$: ActionsObservable<Action>) =>
    observableMerge(
      requestFTPAccess$(feedbackClient, action$),
      requestCSVUpload$(feedbackClient, action$),
      requestURLScrape$(feedbackClient, action$),
      requestFeedback$(feedbackClient, action$),
      requestBrokerFeature$(feedbackClient, action$)
    );
};

import { useEffect, useRef } from 'react';

import { noop } from 'lodash';

import { voidFunction } from '@common/helper';

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(voidFunction);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return noop;
  }, [delay]);
};

import { cloneDeep, findIndex, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api } from '@common/api';
import { TripBuilderClient } from '@common/client/TripBuilderClient';
import { getTodayDateYYYYMMDD, isDateInThePast, LoadUpdate, UpdateCategory } from '@common/helper';
import { AspectRatio, DestLocation, LocationType, OriginLocation, ViewField } from '@common/model';
import {
  CreateUserTripRequest,
  NewUserTrip,
  Segments,
  TripBuilderLocation,
  TripLoadsDetails,
  TripRouteDetails,
  TripSegmentDetails,
  TripTypeCategory,
  UserTrip,
  UserTripResponse,
} from '@common/model/TripBuilder';
import { TripForm } from '@common/model/TripForm';
import {
  createAction,
  createApiAction,
  createApiActionWithFetchData,
  ResultResponseActionWithFetchData,
} from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export const TRIP_BUILDER_DEFAULT_RADIUS = 100;
export const TRIP_BUILDER_RADIUS_OPTIONS = [10, 50, 100];

export type TripBuilderState = {
  isLoading: boolean;
  isFetchingTripInfo: boolean;
  didFinishFetchingLoadDetails: boolean;
  noUserTripsFetched: boolean;
  wasTripCreationSuccessful: boolean | undefined;
  isDeletingTrip: boolean;
  wasTripDeletionSuccessful: boolean | undefined;
  wasLoadAdditionSuccessful: boolean | undefined;
  loadAdditionErrorMessage: string | undefined;
  wasLoadHiddenSuccessful: boolean | undefined;
  wasLoadRemovalSuccessful: boolean | undefined;
  form: TripForm;
  miniMap: {
    isLoading: boolean;
    isError: boolean;
    data?: string;
  };
  map: {
    isLoading: boolean;
    isError: boolean;
    data?: string;
  };
  userTrips: UserTrip[];
  availableLoads?: TripLoads;
  newestTripId?: string;
  selectedRouteIndex: number;
  tripSegments?: Segments;
  tripLoadDetails?: TripLoadsDetails;
  tripRouteDetails?: TripRouteDetails;
  viewType: ViewField;
};

const initialState: TripBuilderState = {
  isLoading: false,
  isFetchingTripInfo: false,
  didFinishFetchingLoadDetails: false,
  noUserTripsFetched: false,
  wasTripCreationSuccessful: undefined,
  isDeletingTrip: false,
  wasTripDeletionSuccessful: undefined,
  wasLoadAdditionSuccessful: undefined,
  loadAdditionErrorMessage: undefined,
  wasLoadHiddenSuccessful: undefined,
  wasLoadRemovalSuccessful: undefined,
  form: {
    mode: TripTypeCategory.LoadByLoad,
    pickUpLocation: {
      city: '',
      radius: TRIP_BUILDER_DEFAULT_RADIUS,
      state: '',
    },
    dropOffLocation: undefined,
    pickUpDate: getTodayDateYYYYMMDD(),
    pickup: '',
    dropoff: '',
    hasPickedLocation: false,
    mpg: undefined,
    maxLength: 54,
    strictLength: false,
    maxWeight: 49000,
    strictWeight: false,
    countries: ['CA', 'US'],
    excludedStates: [],
    minMileage: undefined,
    maxMileage: undefined,
    equipmentType: undefined,
    maxLoads: 4,
    maxAge: undefined,
  },
  miniMap: {
    isLoading: false,
    isError: false,
  },
  map: {
    isLoading: false,
    isError: false,
  },
  userTrips: [],
  availableLoads: undefined,
  newestTripId: undefined,
  selectedRouteIndex: 0,
  tripSegments: undefined,
  tripLoadDetails: undefined,
  tripRouteDetails: undefined,
  viewType: ViewField.List,
};

export interface TripLoads {
  loads?: Array<{ id: string; next: number }>;
  routes?: Array<{ loadIds: string[] }>;
}

export type MapRequest = {
  thumbnail: boolean;
  aspectRatio: AspectRatio;
  originAddress: {
    city: string;
    state: string;
  };
  loadIds: string[];
  destinationAddress?: {
    city: string;
    state: string;
  };
};

export type MapResponse = string;

export const convertFromTripBuilderLocation = (from: TripBuilderLocation | undefined): OriginLocation | undefined => {
  if (!from) {
    return undefined;
  } else {
    if (from.zipCode) {
      return {
        type: LocationType.ZIP,
        zipCode: from.zipCode,
        city: from.city ?? '',
        states: from.state ? [from.state] : [],
        radius: from.radius,
      };
    } else {
      return {
        type: LocationType.CITY,
        city: from.city ?? '',
        states: from.state ? [from.state] : [],
        radius: from.radius,
      };
    }
  }
};

export const convertFromTripBuilderDestinationLocation = (from: TripBuilderLocation | undefined): DestLocation => {
  if (!from) {
    return {
      type: LocationType.ANY,
    };
  } else {
    if (from.zipCode) {
      return {
        type: LocationType.ZIP,
        zipCode: from.zipCode,
        city: from.city ?? '',
        states: from.state ? [from.state] : [],
        radius: from.radius,
      };
    } else {
      return {
        type: LocationType.CITY,
        city: from.city ?? '',
        states: from.state ? [from.state] : [],
        radius: from.radius,
      };
    }
  }
};

const convertToTripBuilderLocation = (from: OriginLocation | DestLocation): TripBuilderLocation | undefined => {
  if (from.type === LocationType.ZIP) {
    return {
      radius: from.radius ?? 0,
      city: from.city,
      state: from.states.length > 0 ? from.states[0] : '',
      zipCode: from.zipCode,
    };
  } else if (from.type === LocationType.CITY) {
    return {
      radius: from.radius ?? 0,
      city: from.city,
      state: from.states.length > 0 ? from.states[0] : '',
    };
  } else if (from.type === LocationType.ANY) {
    return undefined;
  } else {
    throw new Error(`Unsupported type ${from.type} in trip builder location picker.`);
  }
};

const convertFromTripLocationRequest = (response: UserTripResponse): UserTrip => {
  if (response.search.schedule.destination) {
    return {
      id: response.id,
      loadCount: response.loadCount,
      mode: response.mode,
      search: {
        schedule: {
          origin: response.search.schedule.origin,
          destination: response.search.schedule.destination,
          timeFrame: response.search.schedule.timeFrame,
          area: response.search.schedule.area,
        },
        truck: response.search.truck,
        loadFilter: response.search.loadFilter,
        tripLoads: response.search.tripLoads,
      },
      // maxLoads: response.search.maxLoads,
      profit: response.profit,
      mileage: response.mileage,
      duration: response.duration,
      available: response.available,
      loadIds: response.loadIds,
    };
  } else {
    return {
      id: response.id,
      loadCount: response.loadCount,
      mode: response.mode,
      search: {
        schedule: {
          origin: response.search.schedule.origin,
          timeFrame: response.search.schedule.timeFrame,
          area: response.search.schedule.area,
        },
        truck: response.search.truck,
        loadFilter: response.search.loadFilter,
        // maxLoads: response.search.maxLoads,
        tripLoads: response.search.tripLoads,
      },
      profit: response.profit,
      mileage: response.mileage,
      duration: response.duration,
      available: response.available,
      loadIds: response.loadIds,
    };
  }
};

const convertToTripLocationRequest = (request: NewUserTrip): CreateUserTripRequest => {
  if (request.search.schedule.destination) {
    return {
      mode: request.mode,
      search: {
        schedule: {
          origin: request.search.schedule.origin,
          destination: request.search.schedule.destination,
          timeFrame: request.search.schedule.timeFrame,
          area: request.search.schedule.area,
        },
        truck: request.search.truck,
        loadFilter: request.search.loadFilter,
        // maxLoads: request.search.maxLoads,
        tripLoads: request.search.tripLoads,
      },
    };
  } else {
    return {
      mode: request.mode,
      search: {
        schedule: {
          origin: request.search.schedule.origin,

          timeFrame: request.search.schedule.timeFrame,
          area: request.search.schedule.area,
        },
        truck: request.search.truck,
        loadFilter: request.search.loadFilter,
        // maxLoads: request.search.maxLoads,
        tripLoads: request.search.tripLoads,
      },
    };
  }
};

export const calculateTripLengthDays = (
  startingDeadhead: number,
  firstPickupDatetime: string | undefined,
  lastPickupDatetime: string | undefined,
  lastLoadDuration: number,
  finalDeadheadDuration: number
) => {
  if (!firstPickupDatetime || !lastPickupDatetime) {
    return 0;
  } else {
    const startTime = moment(firstPickupDatetime).subtract(startingDeadhead, 's').startOf('day');
    const endTime = moment(lastPickupDatetime)
      .add(lastLoadDuration, 's')
      .add(finalDeadheadDuration, 's')
      .startOf('day');
    if (endTime.diff(startTime, 'd') === 0) {
      return 1;
    } else {
      return endTime.diff(startTime, 'd');
    }
  }
};

type TripOperationRequest = {
  id: string;
  loadId: string;
  type: TripTypeCategory;
  routeIds?: string[];
};

// Action definitions
const selectViewTypeAction = createAction<ViewField>('SELECT_VIEW_TYPE');
const fetchTripBuilderFormAction = createApiAction<undefined, TripBuilderState>('FETCH_TRIP_FORM');
const pickUpLocationAction = createAction<{ location: OriginLocation }>('SET_TRIP_PICK_UP_LOCATION');
const dropoffLocationAction = createAction<{ location: DestLocation }>('SET_TRIP_DELIVERY_LOCATION');
const switchLocationAction = createAction('SWITCH_LOCATIONS');
const updateFormFieldsAction = createAction<Partial<TripForm>>('UPDATE_TRIP_FORM_FIELDS');
const clearFormAction = createAction('CLEAR_NEW_TRIP_FORM');
const updateTripLoadsAction = createAction<LoadUpdate>('UPDATE_TRIP_LOAD');

const fetchUserTripsAction = createApiAction<undefined, { trips: UserTripResponse[] }>('FETCH_USER_TRIPS');

const createNewTripAction = createApiActionWithFetchData<NewUserTrip, UserTripResponse>('CREATE_NEW_TRIP');

const deleteUserTripAction = createApiActionWithFetchData<string, undefined>('DELETE_TRIP');

const fetchSegmentsAction = createApiActionWithFetchData<string, Segments>('FETCH_TRIP_SEGMENTS', { multiplex: false });

const fetchLoadsAction = createApiActionWithFetchData<
  { id: string; type: TripTypeCategory; index?: number },
  TripLoads
>('FETCH_TRIP_LOADS', { multiplex: false });

const fetchRouteLoadDetailsAction = createApiActionWithFetchData<{ id: string; loadIds: string[] }, TripRouteDetails>(
  'FETCH_TRIP_ROUTE_DETAILS',
  { multiplex: false }
);

const fetchLoadsDetailsAction = createApiActionWithFetchData<{ id: string; loadIds: string[] }, TripLoadsDetails>(
  'FETCH_TRIP_LOADS_DETAILS',
  { multiplex: false }
);

const addLoadAction = createApiActionWithFetchData<TripOperationRequest, UserTripResponse>('ADD_TRIP_LOAD');

const removeLoadAction = createApiActionWithFetchData<TripOperationRequest, UserTripResponse>('REMOVE_TRIP_LOAD');
const removeLoadsAction = createApiAction<{ id: string; loadIds: string[] }, UserTripResponse>('REMOVE_TRIP_LOADS');

const hideLoadAction = createApiActionWithFetchData<
  { loadId: string; id: string; type: TripTypeCategory; routeIds?: string[] },
  {}
>('HIDE_LOAD_ACTION');

const fetchMapAction = createApiActionWithFetchData<MapRequest, MapResponse>('FETCH_TRIP_BUILDER_MAP', {
  multiplex: false,
});

const fetchMiniMapAction = createApiActionWithFetchData<MapRequest, MapResponse>('FETCH_TRIP_BUILDER_MINI_MAP', {
  multiplex: false,
});

const setSelectedRouteIndex = createAction<number>('SET_ROUTE_INDEX');

const sanitizeFormDatesAction = createAction('SANITIZE_TRIP_FORM');

// Action creators
export const selectViewTpe = (viewType: ViewField) => selectViewTypeAction.action(viewType);
export const fetchTripBuilderForm = () => fetchTripBuilderFormAction.fetchAction(undefined);
export const fetchUserTrips = () => fetchUserTripsAction.fetchAction(undefined);

export const setPickUpLocationAction = (location: OriginLocation) =>
  pickUpLocationAction.action({ location: location });

export const setDropoffLocationAction = (location: DestLocation) =>
  dropoffLocationAction.action({ location: location });

export const setSwitchLocationAction = () => switchLocationAction.action(undefined);
export const setUpdateFormFieldsAction = (form: Partial<TripForm>) => updateFormFieldsAction.action(form);
export const setClearFormAction = () => clearFormAction.action(undefined);

export const createNewTrip = (newTrip: NewUserTrip) => createNewTripAction.fetchAction(newTrip);

export const deleteUserTrip = (id: string) => deleteUserTripAction.fetchAction(id);

export const fetchSegments = (id: string) => fetchSegmentsAction.fetchAction(id);

export const fetchLoads = (id: string, type: TripTypeCategory, index?: number) =>
  fetchLoadsAction.fetchAction({ id: id, type: type, index: index });

export const fetchTripLoadsDetails = (request: { id: string; loadIds: string[] }) =>
  fetchLoadsDetailsAction.fetchAction(request);

export const fetchRouteLoadDetails = (request: { id: string; loadIds: string[] }) =>
  fetchRouteLoadDetailsAction.fetchAction(request);

export const addLoad = (request: TripOperationRequest) => addLoadAction.fetchAction(request);

export const removeLoad = (request: TripOperationRequest) => removeLoadAction.fetchAction(request);
export const removeLoads = (request: { id: string; loadIds: string[] }) => removeLoadsAction.fetchAction(request);

export const hideLoad = (request: { loadId: string; id: string; type: TripTypeCategory; routeIds?: string[] }) =>
  hideLoadAction.fetchAction(request);

export const fetchTripBuilderMap = (request: MapRequest) => fetchMapAction.fetchAction(request);

export const fetchTripBuilderMiniMap = (request: MapRequest) => fetchMiniMapAction.fetchAction(request);

export const setRouteIndex = (index: number) => setSelectedRouteIndex.action(index);

export const sanitizeFormDates = () => sanitizeFormDatesAction.action(undefined);

export const updateTripLoads = (update: LoadUpdate) => updateTripLoadsAction.action(update);

// Reducer
export const tripBuilderReducer = createMergedReducer(initialState, [
  updateTripLoadsAction.addCase((state, action) => {
    if (state.tripSegments?.segments) {
      state.tripSegments.segments = updateLoadsList(state.tripSegments.segments, action.data);
    }
    if (state.tripLoadDetails?.loads) {
      forEach(state.tripLoadDetails.loads, (loads) => {
        loads.segments = updateLoadsList(loads.segments, action.data);
      });
    }
    if (state.tripRouteDetails?.segments) {
      state.tripRouteDetails.segments = updateLoadsList(state.tripRouteDetails.segments, action.data);
    }
  }),
  selectViewTypeAction.addCase((state, action) => {
    state.viewType = action.data;
  }),
  pickUpLocationAction.addCase((state, action) => {
    state.form.pickUpLocation = convertToTripBuilderLocation(action.data.location);
    if (state.form.pickUpLocation && state.form.pickUpLocation.radius === undefined) {
      state.form.pickUpLocation.radius = TRIP_BUILDER_DEFAULT_RADIUS;
    }
    state.form.hasPickedLocation = true;
  }),
  dropoffLocationAction.addCase((state, action) => {
    state.form.dropOffLocation = convertToTripBuilderLocation(action.data.location);
    if (state.form.dropOffLocation && state.form.dropOffLocation.radius === undefined) {
      state.form.dropOffLocation.radius = TRIP_BUILDER_DEFAULT_RADIUS;
    }
  }),
  switchLocationAction.addCase((state) => {
    const location = state.form.dropOffLocation;
    state.form.dropOffLocation = state.form.pickUpLocation;
    state.form.pickUpLocation = location;
  }),
  updateFormFieldsAction.addCase((state, action) => {
    state.form.mode = action.data.mode ?? state.form.mode;
    state.form.pickup = action.data.pickup ?? state.form.pickup;
    state.form.pickUpDate = action.data.pickUpDate;
    state.form.pickUpTime = action.data.pickUpTime;
    state.form.dropoff = action.data.dropoff ?? state.form.dropoff;
    state.form.dropOffDate = action.data.dropOffDate;
    state.form.dropOffTime = action.data.dropOffTime;
    state.form.minMileage = action.data.minMileage;
    state.form.maxMileage = action.data.maxMileage;
    state.form.excludedStates = action.data.excludedStates ?? state.form.excludedStates;
    state.form.equipmentType = action.data.equipmentType;
    state.form.mpg = action.data.mpg;
    state.form.maxLoads = action.data.maxLoads ?? state.form.maxLoads;
    state.form.maxLength = action.data.maxLength ?? state.form.maxLength;
    state.form.strictLength = action.data.strictLength ?? state.form.strictLength;
    state.form.maxWeight = action.data.maxWeight ?? state.form.maxWeight;
    state.form.strictWeight = action.data.strictWeight ?? state.form.strictWeight;
  }),
  clearFormAction.addCase((state) => {
    state.form.mode = initialState.form.mode;
    state.form.pickUpLocation = initialState.form.pickUpLocation;
    state.form.pickUpDate = getTodayDateYYYYMMDD();
    state.form.pickUpTime = initialState.form.pickUpTime;
    state.form.dropOffLocation = initialState.form.dropOffLocation;
    state.form.dropOffDate = initialState.form.dropOffDate;
    state.form.dropOffTime = initialState.form.dropOffTime;
    state.form.minMileage = initialState.form.minMileage;
    state.form.maxMileage = initialState.form.maxMileage;
    state.form.excludedStates = initialState.form.excludedStates;
    state.form.equipmentType = initialState.form.equipmentType;
    state.form.maxLoads = initialState.form.maxLoads;
    state.form.maxLength = initialState.form.maxLength;
    state.form.strictLength = initialState.form.strictLength;
    state.form.maxWeight = initialState.form.maxWeight;
    state.form.strictWeight = initialState.form.strictWeight;
  }),
  sanitizeFormDatesAction.addCase((state) => {
    if (isDateInThePast(state.form.dropOffDate)) {
      state.form.dropOffDate = undefined;
    }
    if (isDateInThePast(state.form.pickUpDate)) {
      state.form.pickUpDate = getTodayDateYYYYMMDD();
    }
  }),
  fetchTripBuilderFormAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      state.form.pickUpLocation = data.form?.pickUpLocation;
      state.form.pickUpTime = data.form?.pickUpTime;
      state.form.pickUpDate = data.form?.pickUpDate;
      state.form.dropOffLocation = data.form?.dropOffLocation;
      state.form.dropOffTime = data.form?.dropOffDate;
      state.form.dropOffDate = data.form?.dropOffTime;
      state.form.excludedStates = data.form?.excludedStates;
      state.form.equipmentType = data.form?.equipmentType;
      state.form.mode = data.form?.mode;
      state.form.mpg = data.form?.mpg;
      state.form.maxLength = data.form?.maxLength;
      state.form.strictLength = data.form?.strictLength;
      state.form.maxWeight = data.form?.maxWeight;
      state.form.strictWeight = data.form?.strictWeight;
      state.form.minMileage = data.form.minMileage;
      state.form.maxMileage = data.form.maxMileage;
    }
  }),
  fetchUserTripsAction.initiateCase((state) => {
    state.isLoading = true;
    state.isFetchingTripInfo = true;
    state.newestTripId = undefined;
    state.noUserTripsFetched = false;
  }),
  fetchUserTripsAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      if (!isEmpty(data.trips)) {
        state.userTrips = map(data.trips, convertFromTripLocationRequest);
        state.isFetchingTripInfo = false;
      } else {
        state.noUserTripsFetched = true;
        state.userTrips = initialState.userTrips;
      }
    }
    state.isLoading = false;
  }),
  createNewTripAction.initiateCase((state) => {
    state.isLoading = true;
    state.wasTripCreationSuccessful = initialState.wasTripCreationSuccessful;
  }),
  createNewTripAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      state.userTrips = [convertFromTripLocationRequest(data)];
      state.newestTripId = data.id;
    }
    state.wasTripCreationSuccessful = action.response.success;
    state.isLoading = false;
  }),
  deleteUserTripAction.initiateCase((state) => {
    state.isDeletingTrip = true;
    state.wasTripDeletionSuccessful = initialState.wasTripDeletionSuccessful;
  }),
  deleteUserTripAction.completeCase((state, action) => {
    state.wasTripDeletionSuccessful = action.response.success;
    state.isDeletingTrip = false;
  }),
  fetchSegmentsAction.initiateCase((state) => {
    state.isLoading = true;
    state.tripSegments = initialState.tripSegments;
  }),
  fetchSegmentsAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      state.tripSegments = data;
    }
    state.isLoading = false;
  }),
  fetchLoadsAction.initiateCase((state) => {
    state.isLoading = true;
    state.availableLoads = initialState.availableLoads;
    state.didFinishFetchingLoadDetails = false;
  }),
  fetchLoadsAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      state.availableLoads = data;
    }
    state.isLoading = false;
    state.didFinishFetchingLoadDetails = true;
  }),
  fetchMapAction.initiateCase((state) => {
    state.map.isLoading = true;
    state.map.isError = false;
  }),
  fetchMapAction.completeCase((state, action) => {
    if (action.response.success) {
      const mimeType = action.response.headers?.['content-type'];
      state.map.data = mimeType ? `data:${mimeType};base64,${action.response.payload}` : undefined;
    } else {
      state.map.data = undefined;
      state.map.isError = true;
    }
    state.map.isLoading = false;
  }),
  fetchMiniMapAction.initiateCase((state) => {
    state.miniMap.isLoading = true;
    state.miniMap.isError = false;
  }),
  fetchMiniMapAction.completeCase((state, action) => {
    if (action.response.success) {
      const mimeType = action.response.headers?.['content-type'];
      state.miniMap.data = mimeType ? `data:${mimeType};base64,${action.response.payload}` : undefined;
    } else {
      state.miniMap.data = undefined;
      state.miniMap.isError = true;
    }
    state.miniMap.isLoading = false;
  }),
  fetchLoadsDetailsAction.initiateCase((state) => {
    state.isLoading = true;
    state.didFinishFetchingLoadDetails = false;
    state.newestTripId = undefined;
    state.tripLoadDetails = initialState.tripLoadDetails;
  }),
  fetchLoadsDetailsAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      state.tripLoadDetails = data;
    } else {
      state.tripLoadDetails = undefined;
    }
    state.isLoading = false;
    state.didFinishFetchingLoadDetails = true;
  }),
  fetchRouteLoadDetailsAction.initiateCase((state) => {
    state.isLoading = true;
    state.didFinishFetchingLoadDetails = false;
    state.newestTripId = undefined;
    state.tripRouteDetails = initialState.tripRouteDetails;
  }),
  fetchRouteLoadDetailsAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      state.tripRouteDetails = data;
    } else {
      state.tripRouteDetails = undefined;
    }
    state.isLoading = false;
    state.didFinishFetchingLoadDetails = true;
  }),
  addLoadAction.initiateCase((state) => {
    state.isLoading = true;
    state.loadAdditionErrorMessage = undefined;
    state.wasLoadAdditionSuccessful = initialState.wasLoadAdditionSuccessful;
  }),
  addLoadAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      map(state.userTrips, (trip, index) => {
        if (trip.id === data.id) {
          state.userTrips[index].search.tripLoads = data.loadIds;
        }
      });
    } else {
      state.loadAdditionErrorMessage = action.response.error.message;
    }
    state.wasLoadAdditionSuccessful = action.response.success;
    state.isLoading = false;
  }),
  removeLoadAction.initiateCase((state) => {
    state.isLoading = true;
    state.wasLoadRemovalSuccessful = initialState.wasLoadRemovalSuccessful;
  }),
  removeLoadAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      map(state.userTrips, (trip, index) => {
        if (trip.id === data.id) {
          state.userTrips[index].search.tripLoads = data.loadIds;
        }
      });
    }
    state.wasLoadRemovalSuccessful = action.response.success;
    state.isLoading = false;
  }),
  removeLoadsAction.initiateCase((state) => {
    state.isLoading = true;
    state.wasLoadRemovalSuccessful = initialState.wasLoadRemovalSuccessful;
  }),
  removeLoadsAction.completeCase((state, action) => {
    if (action.response.success) {
      const data = action.response.payload;
      const indexToUpdate = findIndex(state.userTrips, (trip) => trip.id === data.id);
      state.userTrips[indexToUpdate].search.tripLoads = data.loadIds;
    }
    state.wasLoadRemovalSuccessful = action.response.success;
    state.isLoading = false;
  }),
  hideLoadAction.initiateCase((state) => {
    state.isLoading = true;
    state.wasLoadHiddenSuccessful = initialState.wasLoadHiddenSuccessful;
  }),
  hideLoadAction.completeCase((state, action) => {
    state.isLoading = false;
    state.wasLoadHiddenSuccessful = action.response.success;
  }),
  setSelectedRouteIndex.addCase((state, action) => {
    state.selectedRouteIndex = action.data;
  }),
]);

const updateOnRemove$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(removeLoadAction.responseType).pipe(
    mergeMap$((action: ResultResponseActionWithFetchData<UserTripResponse, TripOperationRequest>) => {
      if (action.response.success && action.fetchData) {
        return of$(
          fetchUserTripsAction.fetchAction(undefined),
          fetchSegmentsAction.fetchAction(action.fetchData.id),
          fetchLoadsAction.fetchAction({ id: action.fetchData.id, type: action.fetchData.type })
        );
      }
      return of$();
    })
  );

const updateOnRemoveLoads$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(removeLoadsAction.responseType).pipe(
    mergeMap$((action: ResultResponseActionWithFetchData<undefined, undefined>) => {
      if (action.response.success) {
        return of$(fetchUserTripsAction.fetchAction(undefined));
      }
      return of$();
    })
  );

const updateOnAdd$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(addLoadAction.responseType).pipe(
    mergeMap$((action: ResultResponseActionWithFetchData<UserTripResponse, TripOperationRequest>) => {
      if (action.response.success && action.fetchData) {
        return of$(
          fetchSegmentsAction.fetchAction(action.fetchData.id),
          fetchUserTripsAction.fetchAction(undefined),
          fetchLoadsAction.fetchAction({ id: action.fetchData.id, type: action.fetchData.type })
        );
      }
      return of$();
    })
  );

const updateOnHide$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(hideLoadAction.responseType).pipe(
    mergeMap$((action: ResultResponseActionWithFetchData<UserTripResponse, TripOperationRequest>) => {
      if (action.response.success && action.fetchData) {
        if (action.fetchData?.type === TripTypeCategory.LoadByLoad) {
          return of$(
            fetchUserTripsAction.fetchAction(undefined),
            fetchSegmentsAction.fetchAction(action.fetchData.id),
            fetchLoadsAction.fetchAction({ id: action.fetchData.id, type: action.fetchData.type })
          );
        } else if (action.fetchData?.type === TripTypeCategory.EntireTrip) {
          if (action.fetchData.routeIds) {
            return of$(
              fetchUserTripsAction.fetchAction(undefined),
              fetchSegmentsAction.fetchAction(action.fetchData.id),
              fetchRouteLoadDetailsAction.fetchAction({ id: action.fetchData.id, loadIds: action.fetchData.routeIds })
            );
          }
        }
      }
      return of$();
    })
  );

const updateOnCreateTrip$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(createNewTripAction.responseType).pipe(
    mergeMap$((action: ResultResponseActionWithFetchData<UserTripResponse, undefined>) => {
      if (action.response.success) {
        return of$(fetchUserTripsAction.fetchAction(undefined));
      }
      return of$();
    })
  );

const updateOnDeleteTrip$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(deleteUserTripAction.responseType).pipe(
    mergeMap$((action: ResultResponseActionWithFetchData<undefined, undefined>) => {
      if (action.response.success) {
        return of$(fetchUserTripsAction.fetchAction(undefined));
      }
      return of$();
    })
  );

const onFetchAvailableLoads$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(fetchLoadsAction.responseType).pipe(
    mergeMap$(
      (
        action: ResultResponseActionWithFetchData<TripLoads, { id: string; type: TripTypeCategory; index?: number }>
      ) => {
        if (action.response.success && action.fetchData && action.response.payload) {
          if (action.fetchData.type === TripTypeCategory.EntireTrip) {
            if (!isEmpty(action.response.payload.routes)) {
              return of$(
                fetchRouteLoadDetailsAction.fetchAction({
                  id: action.fetchData.id,
                  loadIds: action.response.payload.routes?.[0].loadIds ?? [],
                })
              );
            } else {
              return of$();
            }
          } else {
            const loadIds = map(action.response.payload.loads, (load) => load.id);
            return of$(fetchLoadsDetailsAction.fetchAction({ id: action.fetchData.id, loadIds: loadIds }));
          }
        }
        return of$();
      }
    )
  );

export const createTripBuilderEpic = (api: Api) => {
  const tripBuilderClient = new TripBuilderClient(api);

  return (action$: ActionsObservable<Action>) =>
    merge$(
      createNewTripAction.createEpic$(action$, (request) =>
        tripBuilderClient.createNewTrip$(convertToTripLocationRequest(request))
      ),
      fetchUserTripsAction.createEpic$(action$, () => tripBuilderClient.fetchUserTrips$()),
      deleteUserTripAction.createEpic$(action$, (id) => tripBuilderClient.deleteUserTrip$(id)),
      fetchSegmentsAction.createEpic$(action$, (id) => tripBuilderClient.fetchSegments$(id)),
      fetchLoadsAction.createEpic$(action$, (request) => tripBuilderClient.fetchLoads$(request)),
      fetchMapAction.createEpic$(action$, (request) => tripBuilderClient.fetchMap$(request)),
      fetchMiniMapAction.createEpic$(action$, (request) => tripBuilderClient.fetchMiniMap$(request)),
      fetchLoadsDetailsAction.createEpic$(action$, (request) => tripBuilderClient.fetchTripLoadsDetails(request)),
      fetchRouteLoadDetailsAction.createEpic$(action$, (request) => tripBuilderClient.fetchRouteLoadDetails$(request)),
      addLoadAction.createEpic$(action$, (request) => tripBuilderClient.addLoad$(request)),
      removeLoadAction.createEpic$(action$, (request) => tripBuilderClient.removeLoad$(request)),
      removeLoadsAction.createEpic$(action$, (request) => tripBuilderClient.removeLoads$(request)),
      hideLoadAction.createEpic$(action$, (request) => tripBuilderClient.hideLoad$(request)),
      updateOnRemove$(action$),
      updateOnAdd$(action$),
      updateOnHide$(action$),
      updateOnCreateTrip$(action$),
      updateOnRemoveLoads$(action$),
      updateOnDeleteTrip$(action$),
      onFetchAvailableLoads$(action$)
    );
};

const updateLoadsList = (loads: TripSegmentDetails[], update: LoadUpdate) => {
  switch (update.category) {
    case UpdateCategory.FAVORITE:
    case UpdateCategory.ONBOARDED:
    case UpdateCategory.HIDDEN:
    case UpdateCategory.BLOCKED:
      return loads;
    default:
      return map(loads, (load: TripSegmentDetails) => {
        if (load.id === update.loadID) {
          const updatedLoad = cloneDeep(load);
          switch (update.category) {
            case UpdateCategory.SAVED:
              updatedLoad.saved = update.isSaved;
              break;
            case UpdateCategory.CALLED:
              updatedLoad.contacted = update.isCalled;
              break;
            case UpdateCategory.EMAILED:
              updatedLoad.contacted = update.isEmailed;
              break;
            case UpdateCategory.PROGRESS:
              updatedLoad.progress = update.progress;
              if (update.isSaved !== undefined) {
                updatedLoad.saved = !!update.isSaved;
              }
              break;
            case UpdateCategory.NOTE:
              updatedLoad.note = update.note;
              break;
          }
          return updatedLoad;
        }
        return load;
      });
  }
};

import { t, T } from '@translate';

export enum ViewField {
  Card = 'Card',
  List = 'List',
  Map = 'Map',
}
export interface ViewChoice {
  id: string;
  title: string;
  field: ViewField;
}

export interface WebViews {
  findLoads: ViewChoice;
  myLoads: ViewChoice;
  loadAvailability: ViewChoice;
  brokerLoads: ViewChoice;
  companySearch: ViewChoice;
}
export enum WebViewPages {
  FindLoads = 'FindLoads',
  MyLoads = 'MyLoads',
  LoadAvailability = 'LoadAvailability',
  BrokerLoads = 'BrokerLoads',
  CompanySearch = 'CompanySearch',
}
export const CardView: ViewChoice = {
  id: '0',
  title: t(T.common_viewKeys_cards),
  field: ViewField.Card,
};
export const ListView: ViewChoice = {
  id: '1',
  title: t(T.common_viewKeys_list),
  field: ViewField.List,
};
export const MapView: ViewChoice = {
  id: '2',
  title: t(T.common_viewKeys_map),
  field: ViewField.Map,
};

export const KeysView: ViewChoice[] = [CardView, ListView, MapView];
export const KeysViewWithoutMap: ViewChoice[] = [CardView, ListView];
export const KeysWithoutList: ViewChoice[] = [CardView, MapView];
export const JustCardKey: ViewChoice[] = [CardView];

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { VerifyEmailClient } from '@/client';
import { Api, ApiErrorCode } from '@common/api';
import { createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export enum VerifyEmailError {
  CodeNotProvided,
  InvalidActivationCode,
  Expired,
  Unknown,
}

export interface VerifyEmailResponsePayload {
  email?: string;
  paymentPromptRequired?: boolean;
}

const verifyEmailApiAction = createApiAction<{ activationCode: string }, VerifyEmailResponsePayload>('VERIFY_EMAIL');
const sendConfirmationEmailApiAction = createApiAction<undefined, {}>('SEND_CONFIRMATION_EMAIL');

const SET_VERIFICATION_CODE_NOT_PROVIDED = 'SET_VERIFICATION_CODE_NOT_PROVIDED';

export const verifyEmail = (activationCode: string) =>
  verifyEmailApiAction.fetchAction({ activationCode: activationCode });
export const resendConfirmationEmail = () => sendConfirmationEmailApiAction.fetchAction(undefined);

export const setErrorNoCodeProvided = (): Action => ({
  type: SET_VERIFICATION_CODE_NOT_PROVIDED,
});

export interface VerifyEmailSuccessfulResponse {
  successful: true;
  validatedEmail?: string;
  isRegistrationFromApp: boolean;
}

export type VerifyEmailResponse =
  | VerifyEmailSuccessfulResponse
  | {
      successful: false;
      error: VerifyEmailError;
    };

export interface VerifyEmailState {
  isLoading: boolean;
  response?: VerifyEmailResponse;
  confirmationEmail: {
    isSending: boolean;
    sentSuccessfully?: boolean;
    sentTime?: number;
  };
}

const initialState: VerifyEmailState = {
  isLoading: false,
  confirmationEmail: {
    isSending: false,
  },
};

export const verifyEmailReducer = createMergedReducer<VerifyEmailState>(initialState, [
  {
    [SET_VERIFICATION_CODE_NOT_PROVIDED]: (state) => {
      state.isLoading = false;
      state.response = {
        successful: false,
        error: VerifyEmailError.CodeNotProvided,
      };
    },
  },
  verifyEmailApiAction.initiateCase((state) => {
    state.isLoading = true;
    state.response = undefined;
  }),
  verifyEmailApiAction.completeCase((state, action) => {
    state.isLoading = false;
    if (action.response.success) {
      state.response = {
        successful: true,
        validatedEmail: action.response.payload.email,
        // check for true explicitly as paymentPromptRequired may not always be returned
        isRegistrationFromApp: action.response.payload.paymentPromptRequired === true,
      };
    } else {
      let error = VerifyEmailError.Unknown;
      if (
        action.response.error.httpStatus === 404 &&
        // there is a case where a valid but expired code returns a 404 which we
        // handle below, so we must not catch it here (see case 2 in API-2776)
        action.response.error.code !== ApiErrorCode.VERIFY_EMAIL_CODE_ALREADY_USED
      ) {
        error = VerifyEmailError.InvalidActivationCode;
      } else if (
        action.response.error.code === ApiErrorCode.VERIFY_EMAIL_CODE_ALREADY_USED ||
        action.response.error.code === ApiErrorCode.VERIFY_EMAIL_CODE_EXPIRED
      ) {
        error = VerifyEmailError.Expired;
      }
      state.response = {
        successful: false,
        error: error,
      };
    }
  }),
  sendConfirmationEmailApiAction.initiateCase((state) => {
    state.confirmationEmail.isSending = true;
    state.confirmationEmail.sentTime = undefined;
    state.confirmationEmail.sentSuccessfully = undefined;
  }),
  sendConfirmationEmailApiAction.completeCase((state, action) => {
    state.confirmationEmail.isSending = false;
    state.confirmationEmail.sentTime = Date.now();
    state.confirmationEmail.sentSuccessfully = action.response.success;
  }),
]);

export const createVerifyEmailEpic = (memberApi: Api, anonymousApi: Api) => {
  const memberClient = new VerifyEmailClient(memberApi);
  const anonymousClient = new VerifyEmailClient(anonymousApi);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      verifyEmailApiAction.createEpic$(action$, (data) => anonymousClient.verifyEmail$(data.activationCode)),
      sendConfirmationEmailApiAction.createEpic$(action$, () => memberClient.resendConfirmationEmail$())
    );
};

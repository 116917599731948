import React, { useEffect } from 'react';

import { filter, map } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { recentLocationFormatter } from '@common/helper';
import { RecentLocation, RecentLocationType } from '@common/model';
import { fetchRecentLocations } from '@common/redux/epic/RecentSearchLocationsEpic';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';

import { LocationsContainer, Title } from './RecentLocationsStyles';

interface Props {
  onLocationSelect: (location: RecentLocation) => void;
  locationTypes?: RecentLocationType[];
}

const LocationItemButton = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 14px 21px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: ${Color.GREEN_LIGHT};
  }
`;

export const RecentSearchLocations: React.FC<Props> = (props: Props) => {
  const recentLocations = useSelector((state) => state.recentSearchLocationsState.recentLocations);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRecentLocations(props.locationTypes));
  }, []);

  const filteredLocations = filter(
    recentLocations,
    (location: RecentLocation) =>
      !(location.type === RecentLocationType.Geolocation && location.address && !location.address.city)
  );
  const locations = map(filteredLocations, (location, index) => (
    <LocationItem location={location} onClick={() => props.onLocationSelect(location)} key={index} />
  ));

  return (
    <LocationsContainer id="recent_locations" isVisible={locations.length > 0}>
      <Title id="title">{t(T.common_recentSearch_title)}</Title>
      {locations}
    </LocationsContainer>
  );
};

const LocationItem: React.FC<{ location: RecentLocation; onClick: () => void }> = ({ location, onClick }) => {
  const locationString = recentLocationFormatter(location);
  return locationString ? (
    <LocationItemButton id="location_item" onClick={onClick}>
      {locationString}
    </LocationItemButton>
  ) : null;
};

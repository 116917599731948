import React, { Component } from 'react';

import { filter, find, indexOf, isEqual, map } from 'lodash';
import styled from 'styled-components';

import { DisplayOption } from '@common/helper/InterfaceHelper';
import { TrailerOptions } from '@common/helper/SearchHelper';
import { EquipmentType } from '@common/model';
import { Checkbox, CheckboxResponse } from '@component/control';
import { ApplyButtonFooter } from '@component/footers';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { Header } from '@component/panels/pickerPanels/PickUpDropOffStyles';
import { ColumnLayout, StyledRow, StyledSection } from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { T, t } from '@translate';

const List = ColumnLayout;

export const ListSubheader = styled(Header)`
  display: flex;
  justify-content: left;
  align-items: center;
  min-height: 48px;
`;

export const ListItem = styled(StyledRow)`
  align-items: center;
  min-height: 48px;
`;

interface ComponentProps {
  layer: number;
  offset: number;
  backRedirect: () => void;
  updateTypes: (newTypes: EquipmentType[]) => void;
  initialEquipmentTypes?: EquipmentType[];
}

interface ComponentState {
  selectedTypes: EquipmentType[];
}

type Props = ComponentProps;

export class TrailerTypePanel extends Component<Props, ComponentState> {
  state: ComponentState = {
    selectedTypes: [],
  };
  componentDidMount(): void {
    const { initialEquipmentTypes } = this.props;
    this.setState({
      selectedTypes:
        initialEquipmentTypes && initialEquipmentTypes.length > 0 ? initialEquipmentTypes : [EquipmentType.All],
    });
  }

  handleCheck = (response: CheckboxResponse) => {
    const { isChecked, name } = response;
    const { selectedTypes: oldSelectedTypes } = this.state;
    if (isChecked && (isEqual(name, EquipmentType.All) || isEqual(name, EquipmentType.None))) {
      this.setState({ selectedTypes: [EquipmentType.All] });
    } else if (isChecked) {
      if (!find(oldSelectedTypes, EquipmentType[name as keyof typeof EquipmentType])) {
        const newSelectedTypes =
          oldSelectedTypes[0] === EquipmentType.All
            ? [EquipmentType[name as keyof typeof EquipmentType]]
            : [...oldSelectedTypes, EquipmentType[name as keyof typeof EquipmentType]];
        this.setState({ selectedTypes: newSelectedTypes });
      }
    } else {
      let newSelectedTypes = filter(oldSelectedTypes, (value) => !isEqual(value, name));
      if (newSelectedTypes.length === 0) {
        newSelectedTypes = [EquipmentType.All];
      }
      this.setState({ selectedTypes: newSelectedTypes });
    }
  };

  handleBackClick = () => {
    this.props.backRedirect();
  };

  applyTrailerType = () => {
    if (this.state.selectedTypes[0] === EquipmentType.All) {
      this.props.updateTypes([]);
    } else {
      this.props.updateTypes(this.state.selectedTypes);
    }
    this.props.backRedirect();
  };

  render() {
    const { selectedTypes } = this.state;

    const trailerOption = (option: DisplayOption<EquipmentType>) => (
      <ListItem>
        <Checkbox
          id={option.id}
          key={option.key}
          name={option.key}
          isChecked={indexOf(selectedTypes, option.key) > -1}
          value={option.value}
          label={option.displayValue}
          handleChange={this.handleCheck}
          containerStyle={{ alignSelf: 'auto' }}
        />
      </ListItem>
    );

    return (
      <Panel
        id="trailer_type"
        layer={this.props.layer}
        offset={this.props.offset}
        size={PanelSize.small}
        backgroundColor={PANEL_SHADOW_BACKGROUND}
      >
        <PanelHeader label={t(T.findLoads_findLoads_trailerType)} hasBackButton={true} onClose={this.handleBackClick} />
        <StyledSection>
          <List>
            <ListSubheader>{t(T.findLoads_findLoads_types)}</ListSubheader>
            {map(TrailerOptions.types, trailerOption)}
            <ListSubheader>{t(T.findLoads_findLoads_popular)}</ListSubheader>
            {map(TrailerOptions.popular, trailerOption)}
            <ListSubheader>{t(T.findLoads_findLoads_other)}</ListSubheader>
            {map(TrailerOptions.other, trailerOption)}
          </List>
        </StyledSection>
        <ApplyButtonFooter onClick={this.applyTrailerType} />
      </Panel>
    );
  }
}

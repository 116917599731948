import { useCallback, useEffect, useMemo, useState } from 'react';

import { noop } from 'lodash';
import moment from 'moment';

export type ExpirationTime = '15m' | '30m' | '45m' | '1h' | '2h' | '4h' | '8h';

export const EXPIRATION_TIMES: ExpirationTime[] = ['15m', '30m', '45m', '1h', '2h', '4h', '8h'];

export const getExpirationTime = (time: ExpirationTime): number => {
  switch (time) {
    case '15m':
      return getDurationInSeconds(15, 'minute');
    case '30m':
      return getDurationInSeconds(30, 'minute');
    case '45m':
      return getDurationInSeconds(45, 'minute');
    case '1h':
      return getDurationInSeconds(1, 'hour');
    case '2h':
      return getDurationInSeconds(2, 'hour');
    case '4h':
      return getDurationInSeconds(4, 'hour');
    case '8h':
      return getDurationInSeconds(8, 'hour');
    default:
      return 0;
  }
};

type Timer = ReturnType<typeof setInterval>;

export const useCountdown = (expirationTime: string, systemTime: string, systemTimeReceivedAt: number) => {
  const [remainingTime, setRemainingTime] = useState<number>();

  // In most cases, when the system time is received, it will be offset (inaccurate) by a couple seconds
  // at most, if the user's device time is correct. Otherwise, if the request is delayed due to latency,
  // it could be inaccurate up to ~15 seconds (our request timeout threshold).
  // However, if the user's device time is incorrect, this offset could be much greater. In order to safeguard
  // against this scenario, we calculate the system time offset based on the device's time when the system
  // time was received.
  const systemTimeOffset = useMemo(
    () => (systemTime && systemTimeReceivedAt ? moment(systemTime).diff(systemTimeReceivedAt) : undefined),
    [systemTime, systemTimeReceivedAt]
  );

  const calculateRemainingTime = useCallback(
    (onComplete?: () => void) => {
      const remaining = moment(expirationTime).diff(moment().add(systemTimeOffset));
      if (remaining < 0) {
        setRemainingTime(0);
        onComplete?.();
      } else {
        setRemainingTime(remaining);
      }
    },
    [expirationTime, systemTimeOffset]
  );

  useEffect(() => {
    if (systemTimeOffset && expirationTime) {
      calculateRemainingTime();
      const interval: Timer = setInterval(() => calculateRemainingTime(() => clearInterval(interval)), 10000);
      return () => {
        clearInterval(interval);
      };
    } else {
      setRemainingTime(undefined);
      return noop;
    }
  }, [systemTimeOffset, expirationTime]);

  return getReturnValues(remainingTime ?? 0);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const duration = moment.duration(countDown);
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return { hours: hours, minutes: minutes, seconds: seconds };
};

const getDurationInSeconds = (duration: number, durationType: 'minute' | 'hour') => {
  return moment.duration(duration, durationType).asSeconds();
};

import React from 'react';
import { T, t } from '@translate';
import { default as notFoundSVG } from '@component/feed/not-found.svg';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { Link } from '@material-ui/core';
import { Spacing, FontSize, FontLineHeight } from '@style/StyleConstants';
import styled from 'styled-components';

export const LOADBOARD_YOUTUBE_ROOT = 'https://youtube.com/user/123loadboard';

const ComingSoonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${Spacing.ElementPaddingHorizontalWide}px;
`;

const ComingSoonContentLine = styled.div`
  text-align: center;
  font-size: ${FontSize.Content}px;
  line-height: ${FontLineHeight.MediumTitle}px;
  margin-top: ${Spacing.InterSection}px;
`;

export const ComingSoon: React.FC = () => {
  return (
    <NotFoundPanel
      title={t(T.feed_comingSoon_title)}
      imageSection={<img id="not_found_image" src={notFoundSVG} alt="not_found" />}
      controlSection={
        <ComingSoonContent>
          <ComingSoonContentLine>{t(T.feed_comingSoon_subtitle0)}</ComingSoonContentLine>
          <ComingSoonContentLine>
            {t(T.feed_comingSoon_subtitle1)}
            <Link
              id={'watch_new_videos'}
              href={LOADBOARD_YOUTUBE_ROOT}
              color={'secondary'}
              noWrap={true}
              target={'_blank'}
            >
              {t(T.feed_comingSoon_linkText)}
            </Link>
            {t(T.feed_comingSoon_subtitle2)}
          </ComingSoonContentLine>
        </ComingSoonContent>
      }
    />
  );
};

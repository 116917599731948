import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Flag, LocationSuggestion, LocationType, RateCheck, RateCheckLocation } from '@common/model';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

export interface RateCheckMinMax {
  min?: number;
  setMin: Dispatch<SetStateAction<number | undefined>>;
  max?: number;
  setMax: Dispatch<SetStateAction<number | undefined>>;
  average?: number;
  setAverage: Dispatch<SetStateAction<number | undefined>>;
  month?: number;
  setMonth: Dispatch<SetStateAction<number | undefined>>;
  resetRates: () => void;
}

export const useRateCheck = () => useSelector((state) => state.rateCheck.rateCheck);
export const useRateCheckRequest = () => useSelector((state) => state.rateCheck.request);

export const useIsRateCheckPreviewEnabled = () =>
  useSelector(
    (state) =>
      state.settings.systemSetting[Flag.LoadRateCheckPreviewEnabled].value &&
      !state.settings.systemSetting[Flag.LoadRateCheckPreviewUserDisabled].value
  );

export const useRateCheckMinMax = (rateCheck: RateCheck | undefined) => {
  const [min, setMin] = useState(rateCheck?.ratePerMileMin);
  const [average, setAverage] = useState(rateCheck?.avgRatePerMile);
  const [max, setMax] = useState(rateCheck?.ratePerMileMax);
  /** This value is using Unix Epoch format */
  const [month, setMonth] = useState(rateCheck?.month);

  const resetRates = useCallback(() => {
    setMin(rateCheck?.ratePerMileMin);
    setAverage(rateCheck?.avgRatePerMile);
    setMax(rateCheck?.ratePerMileMax);
    setMonth(rateCheck?.month);
  }, [rateCheck]);

  useEffect(resetRates, [rateCheck]);

  return {
    min: min,
    setMin: setMin,
    max: max,
    setMax: setMax,
    average: average,
    setAverage: setAverage,
    month: month,
    setMonth: setMonth,
    resetRates: resetRates,
  };
};

export const useFlatRateCheckMinMax = (rateCheck: RateCheck | undefined): RateCheckMinMax => {
  const [min, setMin] = useState(rateCheck?.minRateForMileage);
  const [average, setAverage] = useState(rateCheck?.averageRateForMileage);
  const [max, setMax] = useState(rateCheck?.maxRateForMileage);
  const [month, setMonth] = useState(rateCheck?.month);

  const resetRates = useCallback(() => {
    setMin(rateCheck?.minRateForMileage);
    setAverage(rateCheck?.averageRateForMileage);
    setMax(rateCheck?.maxRateForMileage);
    setMonth(rateCheck?.month);
  }, [rateCheck]);

  useEffect(resetRates, [rateCheck]);

  return {
    min: min,
    setMin: setMin,
    max: max,
    setMax: setMax,
    average: average,
    setAverage: setAverage,
    month: month,
    setMonth: setMonth,
    resetRates: resetRates,
  };
};

export const locationMatchToRateCheckLocation = (locationSuggestion: LocationSuggestion): RateCheckLocation => {
  const isWithGeolocation = locationSuggestion.longitude && locationSuggestion.latitude;
  const isWithZipCode = locationSuggestion.zipCode;
  const mainLocationInformation = {
    states: locationSuggestion.state ? [locationSuggestion.state] : [],
    city: locationSuggestion.city ?? '',
  };
  if (isWithGeolocation) {
    return {
      ...mainLocationInformation,
      type: LocationType.GEOLOCATION,
      longitude: locationSuggestion.longitude,
      latitude: locationSuggestion.latitude,
    };
  }
  if (isWithZipCode) {
    return {
      ...mainLocationInformation,
      type: LocationType.ZIP,
      zipCode: locationSuggestion?.zipCode ?? '',
    };
  }
  return {
    ...mainLocationInformation,
    type: LocationType.CITY,
  };
};

export const extractGeolocationOrUndefined = (location: RateCheckLocation | undefined) => {
  if (!location || location.type !== LocationType.GEOLOCATION) {
    // falsy test: invalid zero numbers or undefined
    return undefined;
  }

  return { latitude: location.latitude, longitude: location.longitude };
};

export const convertWeeksToDisplayableString = (num?: number): string => {
  switch (num) {
    case 4:
      return `1 ${t(T.marketRates_widgets_month)}`;
    case 8:
      return `2 ${t(T.marketRates_widgets_months)}`;
    case 12:
      return `3 ${t(T.marketRates_widgets_months)}`;
    case 52:
      return `1 ${t(T.marketRates_widgets_year)}`;
    case 2:
    default:
      return `14 ${t(T.marketRates_widgets_days)}`;
  }
};

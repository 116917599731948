import { compact, filter, isEmpty, isEqual, isUndefined, map, round, toInteger } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { PostLoadsClient } from '@/client';
import {
  DispatcherDetail,
  DispatcherPostResponse,
  INITIAL_EQUIPMENT,
  LoadEquipment,
  LoadPostRequest,
  PostLoadFormUserProfile,
} from '@/model';
import {
  convertPostLoadVisibilityRulesetForRequest,
  convertPostLoadVisibilityRulesetFromServer,
} from '@/reduxStore/epic/LoadVisibilityRulesHelper';
import { SelectedSingleLocation } from '@/reduxStore/epic/MileageCalculatorEpic';
import { history } from '@/reduxStore/History';
import {
  createFormValidationReducer,
  FormValidationState,
  ValidationField,
  ValidationFieldKey,
} from '@/reduxStore/reducer/FormValidationReducer';
import { Routes } from '@/router/Routes';
import { Api, ApiError, ApiResponse123 } from '@common/api';
import {
  addressToLocationSuggestion,
  convertBaseLocationToLoadLocation,
  convertLoadLocationToBaseLocation,
  extractTimeFromDateTimeIfPresent,
  formatDateYYYYMMDDTHHmm,
  isLoadLocationEqual,
} from '@common/helper';
import { equipmentSpecificationsFrom } from '@common/helper/EquipmentHelper';
import {
  ANYWHERE_LOCATION,
  Equipment,
  EquipmentSpecification,
  ExtraStopLocationType,
  Load,
  LoadExtraStop,
  LoadLocation,
  LoadSize,
  LocationSuggestion,
  LocationType,
  PayRateUnit,
  PostLoadVisibilityRulesListUI,
  StopPurposeType,
  SuggestionTypes,
} from '@common/model';
import {
  ApiAction,
  createAction,
  createApiAction,
  Response,
  ResponseAction,
  responseActionHandler,
} from '@common/redux/Base';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';
import { LoadDetailsReducerKey, LoadDetailsState } from '@common/redux/epic/LoadDetailsEpic';
import { createReducer } from '@common/redux/ReduxHelper';
import { getUserInfoFromLoad } from '@component/panels/basePostPanel/userData';
import { generateLoadPostingRoute, PostLoadsRouteParams, PostType } from '@page/postLoads/RouteParams';
import { equipmentSpecificationsTo } from '@util/helper/EquipmentHelper';
import { doLoadPostRequestExtraStopsMatchExistingLoad } from '@util/helper/PostLoadHelper';
import { parseGeolocation } from '@util/parsers/MapDataParsers';
import { decodeMatchParams } from '@util/UrlParamsHelper';

export const POST_LOAD_FORM_REDUCER_KEY = 'postLoadForm';
/**
 * These names are in the error messages in the API, not the normal "happy path" structure model.
 * We have a generic model for all api errors with the field name stored as a value. In the past we removed an extra
 * superflous mapping because the fields system was already convoluted. So to associate an error from the API with
 * a form data it was decided to use the same field name in interface as it comes from the API in error field */
export const RATE_AMOUNT = 'rate.amount';

const UPDATE_LOAD_POST_FORM_FIELDS = 'UPDATE_LOAD_POST_FORM_FIELDS';
const CLEAR_LOAD_POST_FORM_FIELDS = 'CLEAR_LOAD_POST_FORM_FIELDS';
const POST_LOAD = 'POST_LOAD';
export const POST_LOAD_FULFILLED = 'POST_LOAD_FULFILLED';
const CLONE_LOAD = 'CLONE_LOAD';
export const CLONE_LOAD_FULFILLED = 'CLONE_LOAD_FULFILLED';
const EDIT_LOAD = 'EDIT_LOAD';
export const EDIT_LOAD_FULFILLED = 'EDIT_LOAD_FULFILLED';
const CLEAR_POSING_ERRORS = 'CLEAR_POSTING_ERRORS';
const ADD_POST_LOAD_STOP_LOCATION = 'ADD_POST_LOAD_STOP_LOCATION';
const DELETE_POST_LOAD_STOP_LOCATION = 'DELETE_POST_LOAD_STOP_LOCATION';
const EDIT_POST_LOAD_STOP_LOCATION = 'EDIT_POST_LOAD_STOP_LOCATION';
const SET_LAST_USED_POSTING_CONTACT = 'SET_LAST_USED_POSTING_CONTACT';
const SET_SELECTED_POSTING_CONTACT = 'SET_SELECTED_POSTING_CONTACT';
const SET_POSTING_WITH_VISIBILITY_RULES = 'SET_POSTING_WITH_VISIBILITY_RULES';

export enum TeamDriving {
  Yes = 'Yes',
  No = 'No',
}

interface PartialState {
  postLoadFields: PostLoadFormState;
  [LoadDetailsReducerKey.POSTED]: LoadDetailsState;
}

export const dispatcherInfo = createApiAction<undefined, DispatcherDetail>('DISPATCHER_INFO');

export interface LoadActionResponse extends Action {
  response: Response<Load>;
  returnUrl: string;
  shouldFetchRouteMap?: boolean;
}

interface UpdateFormFieldsAction extends Action {
  fields: Partial<PostLoadFormFieldsState>;
}

interface WithBaseUrlAction extends Action {
  baseUrl?: string;
}

interface EditLoadAction extends WithBaseUrlAction {
  loadId: string;
}

export const updatePostLoadFormFields = (fields: Partial<PostLoadFormFieldsState>): UpdateFormFieldsAction => ({
  type: UPDATE_LOAD_POST_FORM_FIELDS,
  fields: fields,
});

export const clearPostLoadFormFields = () => ({
  type: CLEAR_LOAD_POST_FORM_FIELDS,
});

export const postLoad = (baseUrl?: string): WithBaseUrlAction => ({
  type: POST_LOAD,
  baseUrl: baseUrl,
});

export const cloneLoad = (baseUrl?: string): WithBaseUrlAction => ({
  type: CLONE_LOAD,
  baseUrl: baseUrl,
});

export const editLoad = (loadId: string, baseUrl: string | undefined): EditLoadAction => ({
  type: EDIT_LOAD,
  loadId: loadId,
  baseUrl: baseUrl,
});

export const clearPostingErrors = () => ({
  type: CLEAR_POSING_ERRORS,
});

const setPostingWithVisibilityRulesAction = createAction<boolean>(SET_POSTING_WITH_VISIBILITY_RULES);
const addStopLocationAction = createAction<SelectedSingleLocation>(ADD_POST_LOAD_STOP_LOCATION);
const deleteStopLocationAction = createAction<number>(DELETE_POST_LOAD_STOP_LOCATION);
const editStopLocationAction = createAction<SelectedSingleLocation>(EDIT_POST_LOAD_STOP_LOCATION);
const setLastUsedPostingContactAction = createAction<DispatcherPostResponse | undefined>(SET_LAST_USED_POSTING_CONTACT);
const setSelectedPostingContactAction = createAction<DispatcherPostResponse | undefined>(SET_SELECTED_POSTING_CONTACT);

export const setPostingWithVisibilityRules = (isPostingWithVisibilityRules: boolean) =>
  setPostingWithVisibilityRulesAction.action(isPostingWithVisibilityRules);

export const addStopLocation = (selectedLocation: SelectedSingleLocation) =>
  addStopLocationAction.action(selectedLocation);

export const editStopLocation = (selectedLocation: SelectedSingleLocation) =>
  editStopLocationAction.action(selectedLocation);

export const deleteStopLocation = (key: number) => deleteStopLocationAction.action(key);

export const setValidationFields = (validationFields: ValidationField<PostLoadFormFieldsState>[]) =>
  formValidationActions.setValidationFields(validationFields);

export const dropValidationFields = () => formValidationActions.dropValidationFields();

export const unsetValidationField = (validationField: ValidationFieldKey<PostLoadFormFieldsState>) =>
  formValidationActions.unsetValidationField(validationField);

export const setLastUsedPostingContact = (contact: DispatcherPostResponse | undefined) =>
  setLastUsedPostingContactAction.action(contact);

export const setSelectedPostingContact = (contact: DispatcherPostResponse | undefined) =>
  setSelectedPostingContactAction.action(contact);

export interface PostLoadFormFields {
  originLocation: LoadLocation;
  destinationLocation: LoadLocation;
  pickUpDateTimes: string[];
  deliveryDateTime?: string;
  pickUpTime?: string;
  deliveryTime?: string;
  totalPostMileage?: number;
  equipment: LoadEquipment[];
  loadSize: LoadSize;
  length?: number;
  weight?: number;
  postReference?: string;
  commodity?: string;
  notes?: string;
  privateLoadNote?: string;
  teamDriving?: TeamDriving;
  numberOfLoads?: number;
  numberOfStops?: number;
  miles: number;
  [RATE_AMOUNT]: number;
  rateType: string;
  mileage?: string;
  stopsLocations: LocationSuggestion[];
  extraStops: LoadExtraStop[];
  visibility?: PostLoadVisibilityRulesListUI;
}

export type PostLoadFormFieldsState = PostLoadFormUserProfile & PostLoadFormFields;

export interface PostLoadFormState extends FormValidationState<PostLoadFormFieldsState> {
  fields: PostLoadFormFieldsState;
  meta: PostLoadMeta;
  isPosting: boolean;
  postingError: ApiError | undefined;
  dispatcher?: DispatcherDetail;
  lastUsedPostingContact?: DispatcherPostResponse;
  selectedPostingContact?: DispatcherPostResponse;
  isPostingWithVisibilityRules: boolean;
}

export interface PostLoadMeta {
  shouldRecalculateRateCheck: boolean;
}

export const initialPostLoadState: PostLoadFormState = {
  fields: {
    originLocation: ANYWHERE_LOCATION,
    destinationLocation: ANYWHERE_LOCATION,
    pickUpDateTimes: [],
    equipment: [INITIAL_EQUIPMENT],
    loadSize: LoadSize.TL,
    length: undefined,
    weight: undefined,
    postReference: undefined,
    commodity: '',
    notes: '',
    privateLoadNote: '',
    teamDriving: TeamDriving.No,
    numberOfLoads: 1,
    dispatchName: '',
    'dispatchPhone.number': '',
    'dispatchPhone.extension': '',
    dispatchEmail: '',
    miles: 0,
    [RATE_AMOUNT]: 0,
    rateType: PayRateUnit.Flat,
    mileage: undefined,
    stopsLocations: [],
    extraStops: [],
  },
  validationFields: [],
  isPosting: false,
  postingError: undefined,
  meta: {
    shouldRecalculateRateCheck: false,
  },
  isPostingWithVisibilityRules: false,
};

const shouldRecalculateRate = (
  currentFields: PostLoadFormFieldsState,
  updatedFields: Partial<PostLoadFormFieldsState>
): boolean => {
  // Don't recalculate when edit panel opens and fills its fields first time
  if (updatedFields.originLocation && updatedFields.destinationLocation && updatedFields.equipment) {
    return false;
  }
  if (updatedFields.originLocation) {
    return true;
  }
  if (updatedFields.destinationLocation) {
    return true;
  }
  if (
    updatedFields.equipment &&
    !isEqual(
      map(updatedFields.equipment, (e) => e.equipmentType),
      map(currentFields.equipment, (e) => e.equipmentType)
    )
  ) {
    return true;
  }
  return false;
};

const { reducer: formValidationReducer, actions: formValidationActions } = createFormValidationReducer<
  PostLoadFormFieldsState,
  PostLoadFormState
>(POST_LOAD_FORM_REDUCER_KEY, initialPostLoadState);

export const postLoadsFormReducer = createReducer(
  initialPostLoadState,
  {
    [UPDATE_LOAD_POST_FORM_FIELDS]: (state: PostLoadFormState, action: UpdateFormFieldsAction) => {
      if (shouldRecalculateRate(state.fields, action.fields)) {
        state.meta.shouldRecalculateRateCheck = true;
      }
      state.fields = {
        ...state.fields,
        ...action.fields,
        miles: action.fields.miles ? round(action.fields.miles) : state.fields.miles,
      };
      if (!state.fields.equipment || state.fields.equipment.length === 0) {
        state.fields.equipment = [INITIAL_EQUIPMENT];
      }
    },
    [CLEAR_LOAD_POST_FORM_FIELDS]: (state: PostLoadFormState) => {
      const userProfile: PostLoadFormUserProfile = {
        dispatchName: state.fields.dispatchName,
        dispatchEmail: state.fields.dispatchEmail,
        'dispatchPhone.number': state.fields['dispatchPhone.number'],
        'dispatchPhone.extension': state.fields['dispatchPhone.extension'],
      };
      state.fields = { ...initialPostLoadState.fields, ...userProfile, stopsLocations: [] };
      state.meta.shouldRecalculateRateCheck = false;
      state.validationFields = [];
    },
    [POST_LOAD]: (state: PostLoadFormState) => {
      state.isPosting = true;
      state.postingError = undefined;
    },
    [POST_LOAD_FULFILLED]: (state: PostLoadFormState, action: LoadActionResponse) => {
      state.isPosting = false;
      state.postingError = action.response.error;
    },
    [EDIT_LOAD]: (state: PostLoadFormState) => {
      state.isPosting = true;
      state.postingError = undefined;
    },
    [EDIT_LOAD_FULFILLED]: (state: PostLoadFormState, action: LoadActionResponse) => {
      state.isPosting = false;
      state.postingError = action.response.error;
    },
    [CLONE_LOAD]: (state: PostLoadFormState) => {
      state.isPosting = true;
      state.postingError = undefined;
    },
    [CLONE_LOAD_FULFILLED]: (state: PostLoadFormState, action: LoadActionResponse) => {
      state.isPosting = false;
      state.postingError = action.response.error;
    },
    [CLEAR_POSING_ERRORS]: (state: PostLoadFormState) => {
      state.postingError = undefined;
    },
    [dispatcherInfo.responseType]: (state: PostLoadFormState, action: ResponseAction<DispatcherDetail>) => {
      state.dispatcher = action.response.success ? action.response.payload : undefined;
    },
    [ADD_POST_LOAD_STOP_LOCATION]: (state: PostLoadFormState, action: ApiAction<SelectedSingleLocation>) => {
      if (action.data.stopIndex !== undefined && state.fields.stopsLocations.length >= 1) {
        const index = action.data.stopIndex;
        const stop = action.data.selectedLocation;
        state.fields.stopsLocations.splice(index, 0, stop);
      } else {
        state.fields.stopsLocations.push(action.data.selectedLocation);
      }
    },
    [DELETE_POST_LOAD_STOP_LOCATION]: (state: PostLoadFormState, action: ApiAction<number>) => {
      if (state.fields.stopsLocations[action.data]) {
        state.fields.stopsLocations.splice(action.data, 1);
      }
    },
    [EDIT_POST_LOAD_STOP_LOCATION]: (state: PostLoadFormState, action: ApiAction<SelectedSingleLocation>) => {
      const index = action.data.stopIndex;
      const stop = action.data.selectedLocation;
      if (index !== undefined && state.fields.stopsLocations[index]) {
        state.fields.stopsLocations[index] = stop;
      }
    },
    [SET_LAST_USED_POSTING_CONTACT]: (state, action: ApiAction<DispatcherPostResponse>) => {
      state.lastUsedPostingContact = action.data;
    },
    [SET_SELECTED_POSTING_CONTACT]: (state, action: ApiAction<DispatcherPostResponse>) => {
      state.selectedPostingContact = action.data;
    },
    [SET_POSTING_WITH_VISIBILITY_RULES]: (state, action: ApiAction<boolean>) => {
      state.isPostingWithVisibilityRules = action.data;
    },
  },
  formValidationReducer
);

export const getPostLoadFormFieldsFromLoad = (currentLoad: Load): PostLoadFormFieldsState => {
  const initialFields = initialPostLoadState.fields;
  const originLocation = convertBaseLocationToLoadLocation(currentLoad.originLocation, true);
  const destinationLocation = convertBaseLocationToLoadLocation(currentLoad.destinationLocation, true);
  const stops = compact(
    map(currentLoad.extraStops, (extraStop) => {
      if (extraStop.address) {
        return addressToLocationSuggestion(
          extraStop.address,
          extraStop.geolocation?.latitude,
          extraStop.geolocation?.longitude
        );
      }
      return undefined;
    })
  );

  return {
    originLocation: originLocation ?? ANYWHERE_LOCATION,
    destinationLocation: destinationLocation ?? ANYWHERE_LOCATION,
    ...datesAndTimes(currentLoad),
    equipment: getLoadEquipmentsFromEquipments(currentLoad.equipments),
    loadSize: currentLoad.loadSize ? LoadSize[currentLoad.loadSize] : LoadSize.TL,
    length: currentLoad.length,
    weight: currentLoad.weight,
    postReference: setDefaultField(currentLoad.postReference, initialFields.postReference),
    commodity: setDefaultField(currentLoad.commodity, initialFields.commodity),
    notes: setDefaultField(currentLoad.notes, initialFields.notes),
    privateLoadNote: setDefaultField(currentLoad.privateLoadNote, initialFields.privateLoadNote),
    teamDriving: currentLoad.teamDriving ? TeamDriving.Yes : TeamDriving.No,
    numberOfLoads: setDefaultField(currentLoad.numberOfLoads, initialFields.numberOfLoads),
    numberOfStops: setDefaultField(currentLoad.numberOfStops, initialFields.numberOfStops),
    rateType: currentLoad?.rate?.type ?? PayRateUnit.Flat,
    mileage: undefined,
    ...getUserInfoFromLoad(currentLoad),
    ...getRateCheckFieldsFromLoad(currentLoad),
    stopsLocations: stops,
    extraStops: currentLoad.extraStops ?? [],
    visibility: currentLoad.visibility ? convertPostLoadVisibilityRulesetFromServer(currentLoad.visibility) : undefined,
  };
};

export const getLoadEquipmentsFromEquipments = (equipments: Equipment[] | undefined) =>
  equipments
    ? map(equipments, (equipment) => ({
        equipmentType: equipment.equipmentType,
        equipmentSpecifications: filter(
          equipmentSpecificationsFrom(equipment.equipmentSpecifications),
          (item) => item !== EquipmentSpecification.None
        ),
      }))
    : [INITIAL_EQUIPMENT];

export const datesAndTimes = (currentLoad: Load) => {
  const pickupDateTimes = currentLoad.pickupDateTimes;
  return {
    pickUpDateTimes: pickupDateTimes ? map(pickupDateTimes, (dateTime) => formatDateYYYYMMDDTHHmm(dateTime)) : [],
    deliveryDateTime: currentLoad.deliveryDateTime ? formatDateYYYYMMDDTHHmm(currentLoad.deliveryDateTime) : undefined,
    pickUpTime: pickupDateTimes && extractTimeFromDateTimeIfPresent(pickupDateTimes[0]),
    deliveryTime: extractTimeFromDateTimeIfPresent(currentLoad.deliveryDateTime),
  };
};

export const getRateCheckFieldsFromLoad = (currentLoad?: Load) => {
  let rate;
  const miles = currentLoad?.mileage ?? currentLoad?.computedMileage;
  const milesValue = !isUndefined(miles) ? miles : 0;
  if (currentLoad?.rate?.type === PayRateUnit.Flat) {
    rate = currentLoad?.rate?.amount;
  } else {
    const costPerMile = currentLoad?.rate?.amount;
    rate = costPerMile && miles ? costPerMile * miles : 0;
  }
  const rateValue = !isUndefined(rate) ? rate : 0;
  return {
    miles: milesValue,
    [RATE_AMOUNT]: rateValue,
  };
};

const setDefaultField: <T>(value: T, defaultValue: T, compareValue?: T) => T = (value, defaultValue, compareValue) => {
  if (compareValue) {
    return isEqual(value, compareValue) ? defaultValue : value;
  }
  return value ? value : defaultValue;
};

export const convertFormFieldsToLoadPostRequest = (fields: PostLoadFormFieldsState): LoadPostRequest => {
  const equipments = map(fields.equipment, (item) => {
    let equipmentSpecifications = item.equipmentSpecifications;
    if (isEmpty(item.equipmentSpecifications)) {
      equipmentSpecifications = [EquipmentSpecification.None];
    }
    return {
      equipmentType: item.equipmentType,
      equipmentSpecifications: equipmentSpecificationsTo(equipmentSpecifications),
    };
  });

  const stops: LoadExtraStop[] = map(fields.stopsLocations, (stopLocation, index): LoadExtraStop => {
    let type;
    switch (stopLocation.type) {
      case SuggestionTypes.CityState:
        type = ExtraStopLocationType.CityState;
        break;
      case SuggestionTypes.Geolocation:
        type = ExtraStopLocationType.Geolocation;
        break;
      case SuggestionTypes.ZipCode:
        type = ExtraStopLocationType.ZipCode;
        break;
      default:
        type = ExtraStopLocationType.None;
    }
    const geolocation = parseGeolocation(stopLocation);
    const extraStop: LoadExtraStop = {
      type: type,
      address: {
        city: stopLocation?.city ?? '',
        state: stopLocation?.state ?? '',
      },
      stopNo: index + 1,
      stopPurpose: StopPurposeType.None,
    };
    if (stopLocation?.zipCode !== undefined && extraStop.address) {
      extraStop.address.zipCode = stopLocation.zipCode;
    }
    if (geolocation !== undefined) {
      extraStop.geolocation = {
        latitude: geolocation?.latitude,
        longitude: geolocation?.longitude,
      };
    }
    return extraStop;
  });

  const convertLoadLocationToPostLoadLocation = (loadLocatioin: LoadLocation) => {
    const baseLocation = convertLoadLocationToBaseLocation(loadLocatioin);
    return {
      ...baseLocation,
      type: baseLocation.type === LocationType.CITY ? ExtraStopLocationType.CityState : baseLocation.type,
    };
  };

  return {
    postReference: fields.postReference,
    numberOfLoads: fields.numberOfLoads,
    originLocation: convertLoadLocationToPostLoadLocation(fields.originLocation),
    destinationLocation: convertLoadLocationToPostLoadLocation(fields.destinationLocation),
    pickupDateTimes: fields.pickUpDateTimes,
    deliveryDateTime: fields.deliveryDateTime,
    equipments: equipments,
    loadSize: fields.loadSize,
    mileage: toInteger(fields.miles) ? toInteger(fields.miles) : undefined,
    length: fields.length,
    weight: fields.weight,
    rate: fields[RATE_AMOUNT] // undefined or '0' rate means no rate
      ? {
          amount: fields[RATE_AMOUNT],
          type: PayRateUnit.Flat,
        }
      : undefined,
    numberOfStops: fields.numberOfStops,
    teamDriving: fields.teamDriving === TeamDriving.Yes,
    commodity: fields.commodity,
    notes: fields.notes,
    privateLoadNote: fields.privateLoadNote,
    dispatchPhone: fields['dispatchPhone.number']
      ? {
          number: fields['dispatchPhone.number'],
          extension: fields['dispatchPhone.extension'],
        }
      : undefined,
    dispatchName: fields.dispatchName,
    dispatchEmail: fields.dispatchEmail,
    saveLocations: true,
    extraStops: stops,
    visibility: fields.visibility ? convertPostLoadVisibilityRulesetForRequest(fields.visibility) : undefined,
  };
};

const getLocationParamsForPostBackRedirect = (baseUrl?: string): Partial<PostLoadsRouteParams> => {
  const locationMatch = decodeMatchParams<PostLoadsRouteParams>(
    history.location.pathname,
    baseUrl ?? Routes.LOADS_MY_LOADS_POST
  );
  if (locationMatch?.params) {
    const { editing, actionType, ...match } = locationMatch?.params;
    return actionType === PostType.Single ? {} : { ...match };
  }
  return {};
};

const postLoad$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialState>,
  loadClient: PostLoadsClient
) =>
  simpleApiEpicToAction(
    action$,
    POST_LOAD,
    () => {
      const loadData = convertFormFieldsToLoadPostRequest(state$.value.postLoadFields.fields);
      return loadClient.postLoad$({ ...loadData, saveLoadTemplate: true });
    },
    (response, action: WithBaseUrlAction): LoadActionResponse => ({
      type: POST_LOAD_FULFILLED,
      response: response,
      returnUrl: generateLoadPostingRoute(getLocationParamsForPostBackRedirect(action.baseUrl), action.baseUrl),
    })
  );

const getLocationParamsForBackRedirect = (): Partial<PostLoadsRouteParams> => {
  const locationMatch = decodeMatchParams<PostLoadsRouteParams>(history.location.pathname, Routes.LOADS_MY_LOADS_POST);
  const shouldCloseLoadDetails = locationMatch?.params.actionType === 'offline';
  if (locationMatch?.params !== undefined) {
    const { editing, load, loadID, ...match } = locationMatch.params;
    return shouldCloseLoadDetails ? { ...match } : { ...match, load: load, loadID: loadID };
  }
  return {};
};

const cloneLoad$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialState>,
  loadClient: PostLoadsClient
) =>
  simpleApiEpicToAction(
    action$,
    CLONE_LOAD,
    () => {
      const loadData = convertFormFieldsToLoadPostRequest(state$.value.postLoadFields.fields);
      return loadClient.postLoad$({ ...loadData, saveLoadTemplate: true });
    },
    (response): LoadActionResponse => ({
      type: CLONE_LOAD_FULFILLED,
      response: response,
      returnUrl: generateLoadPostingRoute(getLocationParamsForBackRedirect()),
    })
  );

const editLoad$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialState>,
  loadClient: PostLoadsClient
) =>
  action$.ofType(EDIT_LOAD).pipe(
    mergeMap$((action: EditLoadAction) => {
      const postedLoadDetails = state$.value.postedLoadDetails.currentLoad;
      const currentFormFields = state$.value.postLoadFields.fields;

      const loadPostRequest = convertFormFieldsToLoadPostRequest(currentFormFields);

      let didModifyLocations = false;
      if (postedLoadDetails?.id === action.loadId) {
        const initialFormFields = getPostLoadFormFieldsFromLoad(postedLoadDetails);
        didModifyLocations =
          !isLoadLocationEqual(initialFormFields?.originLocation, currentFormFields.originLocation) ||
          !isLoadLocationEqual(initialFormFields?.destinationLocation, currentFormFields.destinationLocation) ||
          !doLoadPostRequestExtraStopsMatchExistingLoad(loadPostRequest, postedLoadDetails);
      }

      return loadClient.editLoad$(loadPostRequest, action.loadId).pipe(
        map$<ApiResponse123<Load>, LoadActionResponse>((response: ApiResponse123<Load>) => ({
          ...responseActionHandler(EDIT_LOAD_FULFILLED, response),
          returnUrl: generateLoadPostingRoute(getLocationParamsForBackRedirect()),
          shouldFetchRouteMap: didModifyLocations,
        }))
      );
    })
  );

export const createPostLoadFormEpic$ = (api: Api) => {
  const postLoadsClient = new PostLoadsClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<PartialState>) =>
    merge$(
      postLoad$(action$, state$, postLoadsClient),
      cloneLoad$(action$, state$, postLoadsClient),
      editLoad$(action$, state$, postLoadsClient),
      dispatcherInfo.createEpic$(action$, postLoadsClient.fetchDispatcherInfo$)
    );
};

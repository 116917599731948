export const DOWNLOAD_POSTING_AID_LINK = 'https://app.123loadboard.com/download/123Loadboard_Posting_Aid.exe';

export const FACEBOOK_LINK = 'https://www.facebook.com/123loadboard/';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/123loadboard/';
export const YOUTUBE_LINK = 'https://www.youtube.com/user/123loadboard';
export const TWITTER_LINK = 'https://twitter.com/123loadboard';
export const APPSTORE_LINK = 'https://itunes.apple.com/us/app/123loadboard/id827794122?mt=8';
export const GOOGLEPLAY_LINK = 'https://play.google.com/store/apps/details?id=com.app.loadboard';

export const FMCSA_SEARCH = 'http://li-public.fmcsa.dot.gov/LIVIEW/pkg_carrquery.prc_carrlist';
export const SAFER_SEARCH = 'https://safer.fmcsa.dot.gov/CompanySnapshot.aspx';
export const FUEL_PRICES = 'https://www.eia.gov/petroleum/gasdiesel/';

export const ROADSIDE_ASSIST_TERMS_LINK = 'https://www.roadsidemasters.com/partner/123loadboard/#information';
export const GET_ROADSIDE_ASSISTANCE_LINK = 'https://www.roadsidemasters.com/partner/123loadboard/';
export const USDA_MARKETING_SERVICE = 'https://www.ams.usda.gov/';

export const BORDERWAITTIMES_CAN_LINK = 'http://www.cbsa-asfc.gc.ca/bwt-taf/menu-eng.html';
export const BORDERWAITTIMES_USA_LINK = 'https://bwt.cbp.gov/';

export const IDEAS_AHA_FEEDBACK_LINK = 'https://123loadboard.ideas.aha.io/';

export const LOADBOARD_HELP_LOAD_PLANNER_LINK =
  'https://help.123loadboard.com/hc/en-us/sections/360005610933-load-planner';
export const LOADBOARD_LOAD_PLANNER_LINK = 'https://members.123loadboard.com/loads/load-planner';
export const YOUTUBE_LOAD_PLANNER_LINK = 'https://www.youtube.com/watch?v=iy1DVNW2C88 ';

export const LOADBOARD_HELP_MILEAGE_CALCULATOR_LINK =
  'https://help.123loadboard.com/hc/en-us/articles/1500007696581-What-is-Mileage-Calculator-';
export const LOADBOARD_MILEAGE_CALCULATOR_LINK = 'https://members.123loadboard.com/tools/mileage-calculator';
export const YOUTUBE_MILEAGE_CALCULATOR_LINK = 'https://www.youtube.com/watch?v=rXgncLHRMkQ';

export const MAC_OS_X_MANAGE_SUBSCRIPTION = 'itms-apps://apps.apple.com/account/subscriptions';
export const APP_STORE_SETTINGS_URL = 'https://apps.apple.com/account/subscriptions';
export const APP_STORE_UNSUBSCRIBE_HELP_URL = 'https://support.apple.com/en-us/HT202039';
export const FMCSA_UPDATING_REGISTRATION_URL = 'https://www.fmcsa.dot.gov/registration/updating-your-registration';

export const APPLE_PURCHASE_HISTORY_INFO =
  'https://help.123loadboard.com/hc/en-us/articles/4406716884631-How-can-I-see-my-purchase-history-for-subscriptions-purchased-through-Apple-iTunes-';

export const getMCLink = (docketNumber: number | string) =>
  `http://li-public.fmcsa.dot.gov/LIVIEW/pkg_carrquery.prc_carrlist?s_prefix=MC&n_docketno=${docketNumber}`;
export const getUSDOTLink = (dotNumber: number | string) =>
  `https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${dotNumber}`;

export const getWebsiteLink = (website: string) => {
  let link = website;
  if (!/^https?:\/\/.*/.test(website)) {
    link = `http://${website}`;
  }
  return link;
};

export const getAppleMapsLink = (from: string, to: string) =>
  `http://maps.apple.com/?daddr=${to}&dirflg=d&saddr=${from}&z=15`;

export const getGoogleMapsLink = (from: string, to: string) =>
  `https://www.google.com/maps/dir/?api=1&destination=${to}&origin=${from}&travelmode=driving&zoom=15`;

export const DIESEL_PRICE_LINK = 'https://gasprices.aaa.com/';

export const INTEGRATIONS_PORTAL_LINK = 'https://developers-console.123loadboard.com/';

export const MEMBERS_PAYMENT_METHOD_LINK = '/payment_method/';

export const LOADBOARD_HELP_RATE_CHECK =
  'https://help.123loadboard.com/hc/en-us/articles/360036933373-What-is-Rate-Check-';
export const LOADBOARD_HELP_CARGO_CHIEF =
  'https://help.123loadboard.com/hc/en-us/articles/11954761838871-What-is-Cargo-Chief-add-on-';
export const LOADBOARD_HELP_GREENSCREENS = 'https://help.123loadboard.com/hc/en-us/articles/13107693081879';
export const LOADBOARD_HELP_MARKET_RATES = 'https://help.123loadboard.com/hc/en-us/sections/360005610853-Market-Rates';

export const CARRIER_SOURCE_SIGNUP = 'https://www.carriersource.io/carriers/claim/123loadboard';
export const CARRIER_SOURCE_IMPROVE_RATING_FAQ =
  'https://help.123loadboard.com/hc/en-us/articles/21822453496599-How-can-I-increase-my-CarrierSource-score';

export const MY_CARRIER_PORTAL_SUPPORT_EMAIL = 'mailto:help@assureassist.com';

import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import { ViewType } from '@common/helper/DocumentSortingHelper';
import { DocumentsContext } from '@common/redux/epic/DocumentListEpic';
import { createFolder, getFolderByLoad, resetCurrentFolder } from '@common/redux/epic/DocumentsEpic';
import { ChatTextField } from '@component/conversation/composer/ChatTextField';
import { SendAttachment } from '@component/conversation/composer/SendAttachmentButton';
import { FooterSection } from '@component/panel';
import { useOpenDocumentWithFolder } from '@component/panels/findLoads/loadDetailsPanel/ManageDocuments';
import { FixedFooter } from '@component/scrollView';
import { AttachmentsBottomMenu } from '@page/communication/AttachmentsBottomMenu';
import { LoadConversationsOriginUI } from '@page/communication/LoadConversations';
import { myDocumentsEncoder } from '@page/myDocuments/MyDocumentsEncoder';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { AppConfig } from '@util/AppConfig';
import { useSelector } from '@util/hooks';

import { default as AddNewDocumentIcon } from './icon-add-file.svg';
import { default as MyDocumentsIcon } from './icon-folder.svg';

const OPEN_MENU_PARAM = 'openMenu';
const ChatFooterSection = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  position: sticky;
`;
export const ChatFooter: React.FC<{
  conversationID: string;
  originUI?: LoadConversationsOriginUI;
  isLoading: boolean;
  isOtherUserOnline: boolean;
}> = (props) => {
  const urlSearchParams = new URL(window.location.href).searchParams;
  const shouldStartWithAttachmentsMenuOpen = urlSearchParams.get(OPEN_MENU_PARAM) === 'true' || false;

  const [isAttachmentsMenuOpen, setIsAttachmentsMenuOpen] = useState(shouldStartWithAttachmentsMenuOpen);
  const [shouldOpenMyDocuments, setShouldOpenMyDocuments] = useState(false);
  const [shouldAddDocuments, setShouldAddDocuments] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    //special case where we are redirected from the widget to the chat panel with the attachments menu open
    if (urlSearchParams.get(OPEN_MENU_PARAM) === 'true') {
      history.replace({
        search: '',
      });
    }
  }, []);

  const myDocumentsSearchParams = myDocumentsEncoder.convertUrlSearchParamsToObject(history.location.search);

  const handleAttachmentMenu = (isMenuOpen: boolean) => {
    if (props.originUI === LoadConversationsOriginUI.MessagingWidget) {
      window.open(
        `${AppConfig.LBConfig.MembersRoot}/communication/chat/${props.conversationID}?${OPEN_MENU_PARAM}=true`,
        '_blank'
      );
      setIsAttachmentsMenuOpen(false);
    } else {
      setIsAttachmentsMenuOpen(isMenuOpen ?? false);
    }
  };

  const load = useSelector((state) => state.communication.conversations.get(props.conversationID)?.metadata?.load);

  const openDocumentsWithFolder = useOpenDocumentWithFolder(false, DocumentsContext.CONVERSATION_ATTACHMENTS);
  const folderIdentifier = useSelector((state) => state.documents.loadDetailsDocuments.loadFolderIdentifier);
  const isCreateFolderLoading = useSelector((state) => state.documents.folders.createFolderUpdate.isLoading);
  const wasFolderCreated = useSelector((state) => state.documents.folders.createFolderUpdate.response?.success);
  const hadErrorOnCreateFolder = useSelector((state) => state.documents.folders.createFolderUpdate.response?.error);

  useEffect(() => {
    if (load?.id && folderIdentifier?.loadId !== load?.id) {
      dispatch(getFolderByLoad(load.id));
    } else if (!load) {
      dispatch(resetCurrentFolder());
    }
  }, [load?.id, load?.metadata, wasFolderCreated]);

  useEffect(() => {
    if (!isCreateFolderLoading && wasFolderCreated && folderIdentifier) {
      if (shouldAddDocuments) {
        setShouldAddDocuments(false);
        pushAddNewDocumentsPanel();
      } else if (shouldOpenMyDocuments) {
        openDocumentsWithFolder(folderIdentifier);
        setShouldOpenMyDocuments(false);
      }
    } else if (hadErrorOnCreateFolder || !folderIdentifier) {
      if (shouldOpenMyDocuments) {
        setShouldOpenMyDocuments(false);
        openDocumentsWithFolder(undefined);
      }
    }
  }, [hadErrorOnCreateFolder, wasFolderCreated, isCreateFolderLoading, folderIdentifier]);

  const pushAddNewDocumentsPanel = () => {
    history.push({
      pathname: generatePath(Routes.TOOLS_MY_DOCUMENTS, {
        viewType: ViewType.Folders,
        newDocument: 'new-document',
      }),

      search: myDocumentsEncoder.convertObjectRequestToUrlParams({
        ...myDocumentsSearchParams,
        id: folderIdentifier?.id,
        folderName: folderIdentifier?.name,
        type: 'CustomFolder',
        redirectCommunicationPath: history.location.pathname,
        redirectLoadDetailsSearch: history.location.search,
        loadID: load?.id,
        context: DocumentsContext.CONVERSATION_ATTACHMENTS,
      }),
    });
  };

  const openAddNewDocument = () => {
    setShouldAddDocuments(true);
    if (folderIdentifier?.loadId) {
      pushAddNewDocumentsPanel();
      setShouldAddDocuments(false);
    } else if (!folderIdentifier && load?.id) {
      /*
       * create a folder with an empty string, the API will auto-fill
       *  and generate a folder name with the same pattern as the
       *  folders that are generating when booking a load
       */
      dispatch(createFolder('', load.id));
    } else {
      pushAddNewDocumentsPanel();
    }
  };

  const openMyDocuments = () => {
    setShouldOpenMyDocuments(true);

    if (folderIdentifier?.loadId) {
      openDocumentsWithFolder(folderIdentifier);
      setShouldOpenMyDocuments(false);
    } else if (!folderIdentifier && load?.id) {
      dispatch(createFolder('', load.id));
    } else {
      openDocumentsWithFolder(folderIdentifier);
    }
  };

  const buttons = [
    {
      title: t(T.common_conversations_attachmentsDialog_options_select),
      icon: MyDocumentsIcon,
      onClick: openMyDocuments,
    },
    {
      title: t(T.common_conversations_attachmentsDialog_options_add),
      icon: AddNewDocumentIcon,
      onClick: openAddNewDocument,
    },
  ];
  return (
    <ChatFooterSection>
      <FixedFooter>
        <AttachmentsBottomMenu
          menuTitle={t(T.common_conversations_attachmentsDialog_title)}
          isAttachmentsMenuOpen={isAttachmentsMenuOpen}
          menuOptions={buttons}
          handleAttachmentMenu={handleAttachmentMenu}
          openAddNewDocument={openAddNewDocument}
          openMyDocuments={openMyDocuments}
        />
        <FooterSection style={{ flexDirection: 'row', backgroundColor: Color.GRAY_LAVA, padding: '8px 15px' }}>
          <SendAttachment
            conversationID={props.conversationID}
            handleAttachmentMenu={handleAttachmentMenu}
            isAttachmentsMenuOpen={isAttachmentsMenuOpen}
            originUI={props.originUI}
          />
          <ChatTextField isOtherUserOnline={props.isOtherUserOnline} conversationID={props.conversationID} />
        </FooterSection>
      </FixedFooter>
    </ChatFooterSection>
  );
};

import React from 'react';

import { isEqual } from 'lodash';

import { formatNumber } from '@common/helper';
import { LoadLocation, OriginLocation } from '@common/model';
import { DestinationWithRadius } from '@component/cards/Destination';
import { FontSize } from '@style/StyleConstants';
import icons from '@util/iconsConstants';

import { Backhauls, Icon, MainContainerBackhauls, WrapperDestination, WrapperIconSearch } from './CardsStyle';

export interface ComponentProps {
  destinationFrom: OriginLocation | string;
  destinationTo: LoadLocation | string;
  backhauls: number;
  onClick?: () => void;
  isPostedLoad?: boolean;
}
export const BackhaulsCard: React.FC<ComponentProps> = (props) => {
  const { destinationFrom, destinationTo, backhauls, isPostedLoad, onClick } = props;
  const showIcon = () => {
    const { backhauls, isPostedLoad } = props;
    if (isEqual(backhauls, 0) || isPostedLoad) {
      return null;
    }
    return <Icon id="arrow" {...props} src={icons.backhauls} alt={'backhauls'} />;
  };
  return (
    <MainContainerBackhauls id="backhauls_card" onClick={onClick}>
      <WrapperDestination>
        <DestinationWithRadius
          origin={destinationFrom}
          destination={destinationTo}
          styleText={{ fontSize: FontSize.ContentDetail }}
        />
      </WrapperDestination>
      <WrapperIconSearch>
        <Backhauls
          id="loads_count"
          {...props}
          style={isEqual(backhauls, 0) && !isPostedLoad ? { marginRight: 32 } : {}}
          isClickable={onClick !== undefined}
        >
          {formatNumber(backhauls)}
        </Backhauls>
        {showIcon()}
      </WrapperIconSearch>
    </MainContainerBackhauls>
  );
};

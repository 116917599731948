import React, { ReactNode, RefObject } from 'react';

import { useIsWindowScrollMode } from '@component/main/mainHooks';

import { DivScrollView } from './DivScrollView';

interface Props {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  scrollRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
}

/** Defines a scrollable area meant for a Full Page.
 */
export const PageScroll: React.FC<Props> = ({ scrollRef, children }) => {
  const isWindowScrollMode = useIsWindowScrollMode();

  if (isWindowScrollMode) {
    // in window scroll mode, let the main window do the scrolling
    return <>{children}</>;
  }

  // fill the page and use a stylized SimpleBar.
  return (
    <DivScrollView style={{ height: '100%', maxHeight: '100%' }} scrollRef={scrollRef}>
      {children}
    </DivScrollView>
  );
};

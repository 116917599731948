import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { first } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { isMessagingWidgetView } from '@/MessagingWidgetApp';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { Routes } from '@/router/Routes';
import { formatTimeFromNow, getLoadBidOption } from '@common/helper';
import { Flag, LoadContactOption } from '@common/model';
import { fetchLoadBids, getBidLoadDetails } from '@common/redux/epic/bids/BidsEpic';
import { requestOneTimeLocationFromConversation } from '@common/redux/epic/CarrierTrackingEpic';
import { getCompanyInfo } from '@common/redux/epic/CompanyEpic';
import { clearCurrentLoad } from '@common/redux/epic/LoadDetailsEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { useDidUpdate } from '@common/util/hooks';
import { VerificationInfoBadge } from '@component/badge/VerificationBadge';
import { MultipurposeButton } from '@component/buttons';
import { StyledActionChipLight } from '@component/chip/ChipStyle';
import BidIcon from '@component/menu/icon/bid-icon-conversation.svg';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { PanelHeader } from '@component/panel';
import { usePanelBackLocation, usePatchedPushPanel } from '@component/panel/PanelHooks';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupLabelButton } from '@component/popup/PopupButtons';
import { PopupContentSection } from '@component/popup/PopupContentSections';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { FixedHeader } from '@component/scrollView';
import { useIsKeyboardOpen } from '@component/scrollView/KeyboardOpen';
import { trimString } from '@component/table/truckTable/TruckElement';
import { Text } from '@component/text';
import { usePlaceBid } from '@page/bids/BidNavigationHelper';
import { BidsOriginUI } from '@page/bids/BidsList';
import { BIDS_SUMMARY_PANEL_PATH, bidsEncoder, BidsTabs } from '@page/bids/BidsPanelRouting';
import { useIsBiddingEnabled } from '@page/bids/BidStatusHelper';
import { ConversationLoadInfo } from '@page/communication/Conversation';
import {
  useEnableConversationForContact,
  useOtherUserInfo,
  useUserVerificationStatus,
} from '@page/communication/ConversationHelper';
import { Color } from '@style/Color';
import { FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { ThemeProps } from '@style/WithTheme';
import { T, t } from '@translate';
import { AppConfig } from '@util/AppConfig';
import { useSelector } from '@util/hooks';

import { ChatHeaderNameStatus } from './chat/ChatHeaderNameStatus';
import { default as MileageIcon } from './icon-mileage.svg';
import { LoadConversationsOriginUI, mapConversationOriginUIToLoadSearchType } from './LoadConversations';

interface LoadHeaderProps {
  conversationID: string;
  isLoading?: boolean;
  originUI: LoadConversationsOriginUI;
}

const LoadHeaderContainer = styled.div`
  border-bottom: ${(props: ThemeProps) => `1px solid ${props.theme.palette.background.smoke}`};
  display: flex;
  flex-direction: column;
  background-color: ${Color.BLUE_AZURE};
  padding-left: ${Spacing.ElementPaddingHorizontal}px;
  padding-right: ${Spacing.ElementPaddingHorizontal}px;
`;

const ChipContainer = styled.div`
  height: 100%;
  margin-bottom: ${Spacing.InterElementVertical}px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Separator = styled.div`
  width: 4px;
`;

const ButtonsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftButtonContainer = styled.div`
  width: fit-content;
  align-items: flex-end;
`;

const LeftHeaderButton = styled(MultipurposeButton)`
  && {
    flex: 1;
    border-radius: 100px;
    font-size: 12px;
    height: 30px;
    padding: 6px 8px;
  }
`;

const BidHeaderButton = styled(LeftHeaderButton)`
  && {
    width: 70px;
  }
`;

export const ConversationLoadHeader = (props: LoadHeaderProps) => {
  const [isOtherUserOnline, setIsOtherUserOnline] = useState(false);

  const loadSearchType = mapConversationOriginUIToLoadSearchType(props.originUI);

  const isCarrierMenu = useIsCarrier();
  const company = useSelector((state) => state.companyState.company);
  const load = useSelector((state) => state.communication.conversations.get(props.conversationID)?.metadata?.load);
  const currentLoadId = useSelector((state) => state.loadDetails.currentLoad?.id);
  const isLoadLocatorEnabled = useSelector((state) => state.settings.systemSetting[Flag.LoadLocatorEnabled].value);
  const requestLocationFromConversation = useSelector((state) => state.carrierTracking.conversation.requestLocation);

  const bidLoadDetails = useSelector((state) =>
    state.bids.bidLoadDetails[loadSearchType].load?.id === load?.id
      ? state.bids.bidLoadDetails[loadSearchType].load
      : undefined
  );

  const topLoadBidId = first(useSelector((state) => state.bids.loadBids.listAll.ids)) ?? undefined;

  const { isKeyboardOpen } = useIsKeyboardOpen();
  const isMessagingWidget = isMessagingWidgetView();

  const { openingPopup, closingPopup } = usePopup();
  const isBidsEnabled = useIsBiddingEnabled();
  const { isOtherUserUnverified, isLoading } = useUserVerificationStatus(props.conversationID);
  const isConversationEnabledForContact = useEnableConversationForContact(props.conversationID);
  const dispatch = useDispatch();

  const history = useHistory();
  const params = useParams<{ backhaulLoadID?: string }>();
  const closeLocation = usePanelBackLocation('/chat/');
  const pushContactPanel = usePatchedPushPanel('/loadcontact/', '/details/');
  const pushDetailsPanel = usePatchedPushPanel('/details/', '/loadcontact/');
  const pushUserContactPanel = usePatchedPushPanel('/usercontact/', '/details/');
  const otherUserInfo = useOtherUserInfo(props.conversationID);
  const userOnlineStatus = otherUserInfo?.status.online || false;
  const userId = otherUserInfo?.user.userId;

  const lastOnline = formatTimeFromNow(otherUserInfo?.status?.mostRecentActivity) ?? '';

  useEffect(() => {
    dispatch(getCompanyInfo());
  }, []);

  useEffect(() => {
    if (load && (isCarrierMenu || otherUserInfo?.user.userId) && !props.isLoading) {
      dispatch(
        fetchLoadBids({
          loadId: load.id,
          carrierId: isCarrierMenu ? 'self' : otherUserInfo?.user.userId,
        })
      );
    }
  }, [load, otherUserInfo]);

  useEffect(() => {
    setIsOtherUserOnline(userOnlineStatus || lastOnline === '0m');
  }, [userOnlineStatus, lastOnline]);

  useEffect(() => {
    if (
      load?.id &&
      (bidLoadDetails === undefined || bidLoadDetails?.rateNegotiations === undefined || bidLoadDetails?.id !== load.id)
    ) {
      dispatch(getBidLoadDetails(loadSearchType, load.id));
    }
  }, [load, bidLoadDetails, loadSearchType]);

  useDidUpdate(() => {
    if (!requestLocationFromConversation.isLoading) {
      dispatch(
        showSnackbar({
          message: requestLocationFromConversation.wasSuccessful
            ? t(T.locationRequest_success)
            : t(T.locationRequest_failure),
        })
      );
    }
  }, [requestLocationFromConversation]);

  let companyName = '';
  let userName = '';
  if (otherUserInfo) {
    companyName = otherUserInfo.user.company.name;
    userName = `${otherUserInfo.user.firstName} ${otherUserInfo.user.lastName}`;
  }

  const isCarrier = company && load?.poster?.id !== company.id;
  const isBroker = load?.poster?.id === company?.id;

  const onDetailsClick = () => {
    if (props.originUI === LoadConversationsOriginUI.MessagingWidget) {
      window.open(
        `${AppConfig.LBConfig.MembersRoot}/communication/chat/${props.conversationID}/details/${load?.id}`,
        '_blank'
      );
    } else {
      //clear the load details buffer before accessing another load
      if (currentLoadId !== load?.id) {
        if (params.backhaulLoadID) {
          dispatch(clearCurrentLoad(LoadSearchType.Backhaul));
        } else {
          dispatch(clearCurrentLoad(LoadSearchType.LoadSearch));
        }
      }
      pushDetailsPanel(load?.id);
    }
  };

  const onContactInfoClick = () => {
    pushContactPanel();
  };

  const placeNewBid = usePlaceBid(bidLoadDetails);

  const onInteractiveBid = useCallback(() => {
    if (bidLoadDetails?.rateNegotiations && bidLoadDetails.rateNegotiations.numberOfBids > 0) {
      if (isMessagingWidget) {
        if (topLoadBidId) {
          window.open(
            `${AppConfig.LBConfig.MembersRoot}/${BIDS_SUMMARY_PANEL_PATH}/${topLoadBidId}/${bidLoadDetails.id}/offer/${topLoadBidId}/`,
            '_blank'
          );
        }
      } else {
        history.push({
          pathname: `${generatePath(Routes.BIDS, {
            tab: BidsTabs.InteractiveBids,
            bidID: topLoadBidId,
            loadID: bidLoadDetails.id,
            offerID: topLoadBidId,
            summary: 'summary',
            edit: 'offer',
            origin: BidsOriginUI.CommunicationPage,
          })}`,
          search: bidsEncoder.convertObjectRequestToUrlParams({
            loadId: bidLoadDetails.id,
            redirectCommunicationPath: history.location.pathname,
          }),
        });
      }
    } else {
      placeNewBid();
    }
  }, [bidLoadDetails, isMessagingWidget, topLoadBidId, history, placeNewBid]);

  const onClickBid = useMemo(() => {
    if (bidLoadDetails && isCarrier) {
      const loadBidOption = getLoadBidOption(bidLoadDetails, false, isBidsEnabled);
      if (loadBidOption === LoadContactOption.Bid) {
        return onInteractiveBid;
      }
      if (loadBidOption === LoadContactOption.VendorBid) {
        return placeNewBid;
      }
    }
    return undefined;
  }, [bidLoadDetails, isCarrier, isBidsEnabled, onInteractiveBid, placeNewBid]);

  const onRequestLocation = () => {
    dispatch(requestOneTimeLocationFromConversation(props.conversationID));
    closingPopup();
  };

  const onClickLocateLoad = () => {
    openingPopup({
      body: (
        <BasePopup
          title={t(T.locationRequest_title)}
          withoutContentPadding={true}
          buttons={[
            <PopupLabelButton
              testID="conversation_location_button"
              key="conversation_location_button"
              label={t(T.locationRequest_label)}
              action={onRequestLocation}
            />,
          ]}
        >
          <PopupContentSection>
            <Text
              id="conversation_location_popup"
              style={{
                fontFamily: `${FontWeight.Regular}`,
                fontSize: `${FontSize.Content}px`,
              }}
            >
              {t(T.locationRequest_content, { name: otherUserInfo?.user.firstName })}
            </Text>
          </PopupContentSection>
        </BasePopup>
      ),
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  };
  const isDisplayingVerificationInfo = companyName && !isLoading && !isCarrier;
  return (
    <>
      <PanelHeader
        style={{ padding: '0 6px' }}
        label={
          <div
            style={{ display: 'flex', cursor: userId && isDisplayingVerificationInfo ? 'pointer' : 'default' }}
            onClick={userId && isDisplayingVerificationInfo ? () => pushUserContactPanel(userId) : undefined}
          >
            {trimString(companyName, 25)}
            {isDisplayingVerificationInfo ? (
              <VerificationInfoBadge
                testID="dot_badge"
                isVerified={!isOtherUserUnverified}
                style={{ paddingLeft: 4, marginTop: 1 }}
              />
            ) : null}
          </div>
        }
        subtitle={
          props.isLoading && currentLoadId && currentLoadId !== load?.id ? null : (
            <ChatHeaderNameStatus
              name={userName}
              isOnline={isOtherUserOnline}
              lastOnline={lastOnline}
              isLoading={props.isLoading}
              loadId={load?.id}
              isConversationEnabledForContact={isConversationEnabledForContact ?? false}
            />
          )
        }
        hasSubtitle={true}
        hasCloseButton={true}
        onClose={() => closeLocation()}
      />
      <FixedHeader>
        {load && !isKeyboardOpen && props.originUI !== LoadConversationsOriginUI.MessagingWidget ? (
          <LoadHeaderContainer theme={THEME}>
            <ConversationLoadInfo style={{ flex: 1 }} load={load} />
            <ButtonsContainer>
              <ChipContainer>
                <StyledActionChipLight
                  id="load_details"
                  label={t(T.common_conversations_chip_loadDetails)}
                  onClick={onDetailsClick}
                  style={{ border: `solid 1px ${Color.GRAY_SMOKE}` }}
                />
                <Separator />
                <StyledActionChipLight
                  id="contact_info"
                  label={t(T.common_conversations_chip_contactInfo)}
                  onClick={onContactInfoClick}
                  style={{ border: `solid 1px ${Color.GRAY_SMOKE}` }}
                />
              </ChipContainer>
              <LeftButtonContainer>
                {onClickBid ? (
                  <BidHeaderButton
                    id="view_bid"
                    icon={BidIcon}
                    text={t(T.common_bids_buttons_bid)}
                    variant={'contained'}
                    color={'primary'}
                    onClick={onClickBid}
                  />
                ) : null}
                {isBroker && isLoadLocatorEnabled ? (
                  <LeftHeaderButton
                    id="locate_load"
                    icon={MileageIcon}
                    text={t(T.common_conversations_chip_locateLoad)}
                    variant={'contained'}
                    color={'primary'}
                    onClick={onClickLocateLoad}
                  />
                ) : null}
              </LeftButtonContainer>
            </ButtonsContainer>
          </LoadHeaderContainer>
        ) : null}
      </FixedHeader>
    </>
  );
};

import React from 'react';

import { SessionStates } from '@common/redux/reducer/ScreenSharingReducer';
import { PopupSizes } from '@component/popup/PopupTrackingContext';

import { ScreenSharingPopUp } from './ScreenSharingPopup';

export interface PopupText {
  title: string;
  description: string;
  leftButtonText: string;
  rightButtonText: string;
}

// This file is in JS to allow usage of the cobrowse.io Web SDK
const COBROWSE_LICENSE = 'bxwg-2HQIGNi8w';

export const startCobrowse = () => {
  if (!(window as any).__cobrowse_io_loaded) {
    // https://docs.cobrowse.io/sdk-installation/web
    (function () {
      let scriptElement: HTMLScriptElement;
      const promise = new Promise(function (resolve) {
        (window as any).CobrowseIO = {
          client: function () {
            if (!scriptElement) {
              scriptElement = document.createElement('script');
              scriptElement.src = 'https://js.cobrowse.io/CobrowseIO.js';
              scriptElement.async = true;
              const firstScriptElem = document.getElementsByTagName('script')[0];
              firstScriptElem.parentNode?.insertBefore(scriptElement, firstScriptElem);
              scriptElement.onload = function () {
                resolve((window as any).CobrowseIO);
              };
            }
            return promise;
          },
        };
      });
    })();

    (window as any).CobrowseIO.license = COBROWSE_LICENSE;
    (window as any).CobrowseIO.client()
      .then(function () {
        (window as any).CobrowseIO.start();
      })
      .catch((error: any) => console.log(error));
  }
};

export const updateIdentifier = (userEmail: string | undefined, userName: string | undefined) => {
  (window as any).CobrowseIO.customData = {
    device_name: userEmail,
    user_email: userEmail,
    user_id: userName,
  };
};

export const showCode = () => {
  return (window as any).CobrowseIO.client().then(function () {
    return (window as any).CobrowseIO.createSessionCode();
  });
};

export const showConsentPrompt = (openingPopup: any, closingPopup: any, startPopupText: PopupText) => {
  (window as any).CobrowseIO.confirmSession = () => {
    return new Promise((resolve) => {
      openingPopup({
        body: (
          <ScreenSharingPopUp
            onClose={() => {
              resolve(false);
              closingPopup();
            }}
            onRightButtonClick={() => {
              resolve(true);
              closingPopup();
            }}
            onLeftButtonClick={() => {
              resolve(false);
              closingPopup();
            }}
            title={startPopupText.title}
            description={startPopupText.description}
            leftButtonText={startPopupText.leftButtonText}
            rightButtonText={startPopupText.rightButtonText}
          />
        ),
        width: PopupSizes.SMALL,
        isDismissible: false,
      });
    });
  };
};

export const setSessionListeners = (updateSession: any) => {
  (window as any).CobrowseIO.showSessionControls = function () {
    updateSession(SessionStates.active);
  };

  (window as any).CobrowseIO.hideSessionControls = function () {
    updateSession(SessionStates.inactive);
  };
};

export const endSession = () => {
  if ((window as any).CobrowseIO.currentSession) {
    (window as any).CobrowseIO.currentSession.end();
  }
};

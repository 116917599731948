import { createPortal } from 'react-dom';

import { FragmentHolder } from './FragmentHolder';

interface Props {
  fragmentHolder: FragmentHolder;
  children: React.ReactNode;
}

/**
 * React Portal helper to use another React component as the portal target instead
 * of using a DOM element outside of React.
 *
 * FragmentPortalOrigin is where the components are rendered by React.
 *
 * Requires a FragmentHolder to hold the DOM nodes. Obtain with "useFragmentHolder()".
 */
export const FragmentPortalOrigin: React.FC<Props> = ({ fragmentHolder, children }) =>
  fragmentHolder.divElement ? createPortal(children, fragmentHolder.divElement) : null;

import React, { useState } from 'react';

import { isEmpty, map } from 'lodash';
import styled from 'styled-components';

import { isLoadBidSent, isLoadCalled, isLoadEmailed, isLoadMessaged } from '@common/helper';
import { BookingStatus, Load, LoadContactOption, Phone } from '@common/model';
import { LinkButton } from '@component/contact/PhoneNumber';
import { usePushPanel } from '@component/panel/PanelHooks';
import { LoadContactCallbackProps } from '@component/panels/findLoads/loadDetailsPanel/buttons/ContactButtons';
import { renderPhoneNumber } from '@component/panels/findLoads/loadDetailsPanel/buttons/PhoneButton';
import { Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useContactOptions } from '@util/helper/ContactOptionsHelper';

interface Props extends LoadContactCallbackProps {
  load?: Load;

  isOriginMessaging: boolean;
  isOriginBids: boolean;
  onVhubClick: () => void;
}

interface ButtonProps {
  onClick: () => void;
}

interface DataProps {
  load?: Load;
  dispatchPhone?: Phone;
  dispatchEmail?: string;
}

const ContactButton = styled(LinkButton)`
  margin-bottom: ${Spacing.LargePaddingVertical}px;
`;

const ContactData = styled.span`
  font-weight: bold;
  word-break: break-word;
  margin-bottom: 3px;
`;

export const MoreInfoContactButtons = (props: Props) => {
  const contactOptions = useContactOptions(props.load, props.isOriginMessaging, props.isOriginBids);
  return isEmpty(contactOptions) ? null : <>{map(contactOptions, (option) => renderContactOption(option, props))}</>;
};

const renderContactOption = (option: LoadContactOption, props: Props) => {
  switch (option) {
    case LoadContactOption.Bid:
      return <BidButton load={props.load} onClick={props.onInteractiveBidClick} />;
    case LoadContactOption.VendorBid:
      return <BidButton load={props.load} onClick={props.onVendorBidClick} />;
    case LoadContactOption.BookNow:
      return <BookNowButton load={props.load} onClick={props.onBookNowClick} />;
    case LoadContactOption.Call:
      return <CallButton load={props.load} onClick={props.onCallClick} />;
    case LoadContactOption.Email:
      return <EmailButton load={props.load} onClick={props.onEmailClick} />;
    case LoadContactOption.Message:
      return <MessageButton load={props.load} />;
    case LoadContactOption.VhubBook:
      return <VhubBookWithButton onClick={props.onVhubClick} />;
    default:
      return null;
  }
};

const BidButton: React.FC<{ load?: Load } & ButtonProps> = (props) => {
  const hasBid = isLoadBidSent(props.load) || props.load?.vendorInfo?.bid;
  return (
    <ContactButton id="bid" onClick={props.onClick}>
      {hasBid ? t(T.common_vendorBid_bidSent) : t(T.common_bids_buttons_bid)}
    </ContactButton>
  );
};

const BookNowButton: React.FC<{ load?: Load } & ButtonProps> = (props) => {
  const bookingStatus = props.load?.bookNow?.bookingStatus;
  const isBooked = bookingStatus === BookingStatus.Accepted || bookingStatus === BookingStatus.Pending;

  const isBookedText =
    bookingStatus === BookingStatus.Accepted ? t(T.common_bookNow_booked) : t(T.common_bookNow_bookingPending);

  return bookingStatus === BookingStatus.Rejected ? null : (
    <ContactButton id="bookNow" onClick={props.onClick}>
      {isBooked ? isBookedText : t(T.common_bookNow_title)}
    </ContactButton>
  );
};

const CallButton: React.FC<{ load?: Load } & ButtonProps> = (props) => {
  const [isPhoneShown, setIsPhoneShown] = useState(false);
  const isCalled = isLoadCalled(props.load);
  const onCall = () => {
    if (isPhoneShown && props.onClick) {
      props.onClick();
    }
    setIsPhoneShown(true);
  };
  return (
    <ContactButton id="bid" onClick={onCall}>
      {isPhoneShown
        ? renderPhoneNumber(props.load?.dispatchPhone)
        : isCalled
          ? t(T.common_load_Call_again)
          : t(T.common_load_Call)}
    </ContactButton>
  );
};

const EmailButton: React.FC<{ load?: Load } & ButtonProps> = (props) => (
  <ContactButton id="email" onClick={props.onClick}>
    {isLoadEmailed(props.load) ? t(T.common_emailSent) : t(T.common_email)}
  </ContactButton>
);

const MessageButton: React.FC<{ load?: Load }> = (props) => {
  const pushCommunicationPanel = usePushPanel('/communication/');
  const isMessaged = isLoadMessaged(props.load);
  return (
    <ContactButton id="message" onClick={() => pushCommunicationPanel()}>
      {isMessaged ? t(T.common_conversations_buttonMessageAgain) : t(T.common_conversations_button)}
    </ContactButton>
  );
};

const VhubBookWithButton: React.FC<ButtonProps> = (props) => (
  <ContactButton id="vhub_bookwith_link" onClick={props.onClick}>
    {t(T.common_vHub_vHubBookWithButtonLabel)}
  </ContactButton>
);

export const MoreInfoContactData: React.FC<DataProps> = (props) => {
  const phone = renderPhoneNumber(props.load ? props.load?.dispatchPhone : props.dispatchPhone);
  const email = props.load ? props.load.dispatchEmail : props.dispatchEmail;
  return (
    <>
      {phone ? <ContactData>{phone}</ContactData> : null}
      {email ? <ContactData>{email}</ContactData> : null}
    </>
  );
};

export const USA_STATES = [
  'IL',
  'IN',
  'IA',
  'KY',
  'MI',
  'MN',
  'MO',
  'OH',
  'WI',
  'WV',
  'CT',
  'DC',
  'DE',
  'MA',
  'MD',
  'ME',
  'NH',
  'NJ',
  'NY',
  'PA',
  'RI',
  'VT',
  'AR',
  'CO',
  'KS',
  'MT',
  'ND',
  'NE',
  'NM',
  'OK',
  'SD',
  'TX',
  'WY',
  'AL',
  'FL',
  'GA',
  'LA',
  'MS',
  'NC',
  'SC',
  'TN',
  'VA',
  'AZ',
  'CA',
  'ID',
  'NV',
  'OR',
  'UT',
  'WA',
];

export const NORTH_EAST_US_STATES = ['CT', 'DC', 'DE', 'MA', 'MD', 'ME', 'NH', 'NJ', 'NY', 'PA', 'RI', 'VT'];
export const MID_WEST_US_STATES = ['IL', 'IN', 'IA', 'KY', 'MI', 'MN', 'MO', 'OH', 'WI', 'WV'];
export const PLAINS_US_STATES = ['AR', 'CO', 'KS', 'MT', 'ND', 'NE', 'NM', 'OK', 'SD', 'TX', 'WY'];
export const SOUTHERN_US_STATES = ['AL', 'FL', 'GA', 'LA', 'MS', 'NC', 'SC', 'TN', 'VA'];
export const WESTERN_US_STATES = ['AZ', 'CA', 'ID', 'NV', 'OR', 'UT', 'WA'];

export const CANADA_PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

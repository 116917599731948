import React, { RefObject, useEffect, useState } from 'react';

import { isEmpty, map, trim } from 'lodash';
import { useDispatch } from 'react-redux';

import { LocationSuggestion } from '@common/model';
import { fetchLocationMatch, locationCacheKey } from '@common/redux/epic/LocationMatchEpic';
import { DisplayItem, DropdownList } from '@component/dropdown/DropdownList';
import { usePrevious, useSelector } from '@util/hooks';

interface Props {
  id: string;
  title: string;
  value: string;
  hasError: boolean;
  helperText?: string;
  onSelectionChange: (location: LocationSuggestion) => void;
  onInputValueChange: (text: string) => void;
  onClear?: () => void;
  withCustomScroll?: boolean;
  placeholder?: string;
  isWithoutMargin?: boolean;
  shouldIncludeStates: boolean;
  required?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  inputRef?: RefObject<HTMLElement>;
  initialValue?: string;
  shouldAvoidDownshiftStateResetOnBlur?: boolean;
  isDisabled?: boolean;
}

export const LocationDropdownList: React.FC<Props> = (props) => {
  const [hadInitialValue, setHadInitialValue] = useState(false);

  const [suggestions, isLoading] = useSuggestions(props.value, props.shouldIncludeStates, props.initialValue);

  const locationItems = convertLocToListItems(suggestions);

  const onInputValueChange = (value: string) => {
    props.onInputValueChange(value);
    setHadInitialValue(true);
  };

  const onSelectionChange = (selectedItem?: DisplayItem<LocationSuggestion>) => {
    if (selectedItem?.data) {
      props.onSelectionChange(selectedItem.data);
    }
  };

  //@FIXME: Remove hadInitialValue state and shouldDisplayError function.
  //       The DropdownList's hasError should rely only on props.hasError.
  //       Currently hadInitialValue is used for RateCheck inputs' empty cases.
  //       Restructure the RateCheck to be able avoid hadInitialValue state.
  const shouldDisplayError = props.hasError && (hadInitialValue || Boolean(props.required));

  return (
    <DropdownList
      id={props.id}
      title={props.title}
      value={props.value}
      items={locationItems}
      onSelectionChange={onSelectionChange}
      onInputValueChange={onInputValueChange}
      isLoading={isLoading}
      hasError={shouldDisplayError}
      helperText={shouldDisplayError ? props.helperText : undefined}
      onClear={props.onClear}
      withCustomScroll={props.withCustomScroll}
      placeholder={props.placeholder}
      isWithoutMargin={props.isWithoutMargin}
      required={props.required}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      autoFocus={props.autoFocus}
      inputRef={props.inputRef}
      shouldAvoidDownshiftStateResetOnBlur={props.shouldAvoidDownshiftStateResetOnBlur}
      isDisabled={props.isDisabled}
    />
  );
};

const useSuggestions = (
  value: string | undefined,
  shouldIncludeStates: boolean,
  initialValue?: string
): [LocationSuggestion[], boolean] => {
  const dispatch = useDispatch();
  const locationMatches = useSelector((state) => state.locationMatches);
  const prevValue = usePrevious(value);

  useEffect(() => {
    /**
     * There is a scenario where initialValue was changed before changes in actual value.
     * We should not make any request in this case.
     * To prevent this we have a check with previousValue to make sure that it is necessary to fire a request for suggestions */
    const isValueChangedAndNotInitial = !isEmpty(initialValue) && value !== initialValue && prevValue !== value;
    if (!isEmpty(value) && (initialValue === undefined || isValueChangedAndNotInitial)) {
      dispatch(fetchLocationMatch(trim(value), shouldIncludeStates));
    }
  }, [value, shouldIncludeStates, initialValue, prevValue]);

  if (!locationMatches || !value) {
    return [[], false];
  }
  const locationState = locationMatches.get(locationCacheKey(trim(value), shouldIncludeStates));
  return [locationState?.matches.suggestions ?? [], locationState?.isLoading ?? false];
};

const convertLocToListItems = (suggestions: LocationSuggestion[]): DisplayItem<LocationSuggestion>[] =>
  map(suggestions, (location: LocationSuggestion) => ({
    key: location.label ?? '',
    label: location.label ?? '',
    data: location,
  }));

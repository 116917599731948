import React from 'react';

import { find, isEqual, map, toString } from 'lodash';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import { LoadDetailsTabs } from '@/model';
import { StoreState } from '@/reduxStore/Store';
import { getDeadHead, getPricePerMileRate, getRevenue, treatZeroAsUndefined } from '@common/helper';
import { Config, Load, RoutesSettings, SimilarLoad, SystemSetting } from '@common/model';
import { fetchLoadFuelPrice, LoadInfo } from '@common/redux/epic/LoadInfoEpic';
import { fetchRoutesSettings, getSystemSetting, updateRoutesSettings } from '@common/redux/epic/SettingsEpic';
import { getDriveTime } from '@common/util/CostPerDayHelper';
import { TextButton } from '@component/button';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import {
  createField,
  Field,
  FieldType,
  removeComma,
} from '@component/panels/basePanel/loadDetails/profitCalculator/Field';
import FuelCost from '@component/panels/basePanel/loadDetails/profitCalculator/FuelCost';
import LinehaulRevenue from '@component/panels/basePanel/loadDetails/profitCalculator/LinehaulRevenue';
import TotalProfit from '@component/panels/basePanel/loadDetails/profitCalculator/TotalProfit';
import {
  ExpansionPanelDataWrapper,
  LoadDetailsContainer,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import {
  getCosts,
  getPersistedCalculatorData,
  isFieldStatesEqual,
  persistCalculatorData,
  ProfitCalculatorState,
  toNumberWithComma,
} from '@page/profitCalculator/ProfitCalculatorHelper';
import { T, t } from '@translate';

import AdditionalCosts from './AdditionalCosts';
import { CostPerDayCard } from './CostPerDayCard';
import { default as CalculatorIcon } from './profit calculator.svg';

export const ResetButton = styled(TextButton)`
  && {
    width: 40px;
    font-weight: unset;
    align-self: flex-end;
    margin-right: 6px;
  }
`;

const getFields: (state: ProfitCalculatorState) => { [field: string]: Field } = (state) => {
  const costPerMileField = createField(FieldType.CostPerMile, state.costPerMile);
  costPerMileField.unformattedValue =
    parseInt(removeComma(state.rate ?? ''), 10) / parseInt(removeComma(state.miles ?? ''), 10);

  return {
    miles: createField(FieldType.Miles, state.miles),
    costPerMile: costPerMileField,
    rate: createField(FieldType.Rate, state.rate),
    mpg: createField(FieldType.MPG, state.mpg),
    costPerGallon: createField(FieldType.CostPerGallon, state.costPerGallon),
    costPerGallonDiscount: createField(FieldType.CostPerGallonDiscount, state.costPerGallonDiscount),
    tollCost: createField(FieldType.TollCost, state.tollCost),
    dispatchFee: createField(FieldType.DispatchFee, state.dispatchFee, state.isDispatchFeeFlatAmount),
    otherFee: createField(FieldType.OtherFee, state.otherFee),
    deadheadMiles: createField(FieldType.DeadheadMiles, state.deadheadMiles),
  };
};

interface ComponentProps {
  loadInfo: LoadInfo | undefined;
  load: Load;
  isOpened: boolean;
  totalProfitRef?: React.Ref<HTMLDivElement>;
  handleChange(tab: LoadDetailsTabs, status: boolean): void;
  onGetProfitPerDay: (profitPerDay: number) => void;
}

interface DispatchProps {
  fetchRoutesSettings: () => void;
  updateSettings: (settings: RoutesSettings) => void;
  getSystemSetting: (setting: SystemSetting) => void;
  fetchLoadFuelPrice(loadId: string, state: string, country?: string): void;
}

interface ReduxProps {
  mpgResult?: number;
  costPerDayEnabled: boolean;
  isLoading: boolean;
  loads: Load[];
  backhaulsLoads: Load[];
  similarLoads: SimilarLoad[];
}

type Props = ReduxProps & ComponentProps & DispatchProps;

class ProfitCalculatorComponent extends React.PureComponent<Props, ProfitCalculatorState> {
  state = initialState;

  componentDidMount(): void {
    this.props.fetchRoutesSettings();

    if (this.props.load?.originLocation?.address?.state) {
      this.props.fetchLoadFuelPrice(this.props.load.id, this.props.load.originLocation.address.state);
    }

    this.setInitialValues();
  }

  componentWillUnmount(): void {
    persistCalculatorData({
      isDispatchFeeFlatAmount: this.state.isDispatchFeeFlatAmount,
      dispatchFee: this.state.dispatchFee,
      isFuelDiscountChecked: this.state.isFuelDiscountChecked,
      costPerGallonDiscount: this.state.costPerGallonDiscount,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!isEqual(this.props.load.id, prevProps.load.id) && this.props.load?.originLocation?.address?.state) {
      this.props.fetchLoadFuelPrice(this.props.load.id, this.props.load.originLocation.address.state);
    }

    if (!isEqual(prevProps.loadInfo, this.props.loadInfo)) {
      this.setInitialValues();
    }
  }

  // @FIXME: This should not rely on iterating through the results lists. To be fixed by copying MOB implementation which uses WILL_SHOW_DETAILS action, or by normalization once implemented
  getLoadWithDeadhead = () => {
    const similarLoadsList = map(this.props.similarLoads, (similarLoad) => similarLoad.load);
    const loads = [...this.props.loads, ...similarLoadsList, ...this.props.backhaulsLoads];
    return find(loads, (load) => load.id === this.props.load.id);
  };

  getOriginDeadhead = () => {
    const loadWithDeadhead = this.getLoadWithDeadhead();
    const originDeadhead = getDeadHead(loadWithDeadhead);
    return originDeadhead;
  };

  getInitialValues = () => {
    const { load, loadInfo } = this.props;

    const rate = getRevenue(load, loadInfo?.rateCheck?.payload?.linehaulRevenue);
    const miles = load.computedMileage ? toString(load.computedMileage) : undefined;
    const deadheadMilesValue = this.getOriginDeadhead();
    const deadheadMiles = deadheadMilesValue ? toString(deadheadMilesValue) : undefined;
    const rateStr = rate && miles ? toString(rate.toFixed(0)) : undefined;
    const tollCost =
      loadInfo?.routeInfo && treatZeroAsUndefined(loadInfo.routeInfo.toll)
        ? toString(loadInfo.routeInfo.toll)
        : undefined;
    let costPerMile;
    if (getPricePerMileRate(load)) {
      costPerMile = getPricePerMileRate(load);
    } else {
      costPerMile = load?.rateCheck?.avgRatePerMile;
    }
    const costPerMileStr = costPerMile ? toString(costPerMile.toFixed(2)) : undefined;
    const costPerGallonField = createField(
      FieldType.CostPerGallon,
      toString(this.props.loadInfo?.fuelPrice?.payload?.price)
    );
    const initialValues: Partial<ProfitCalculatorState> = {
      rate: rateStr,
      miles: miles,
      tollCost: tollCost,
      costPerMile: costPerMileStr,
      costPerGallon: costPerGallonField.normalisedText(),
      deadheadMiles: deadheadMiles,
    };

    if (this.props.mpgResult) {
      const mpgField = createField(FieldType.MPG, toString(this.props.mpgResult));
      initialValues.mpg = mpgField.normalisedText();
    }

    const persistedData = getPersistedCalculatorData();

    return { ...initialValues, ...persistedData };
  };

  isFiledValuesInitial = () => isFieldStatesEqual(this.state, { ...initialState, ...this.getInitialValues() });

  onResetClick = () => this.setInitialValues();

  render() {
    const fields = getFields(this.state);
    const { fuelCosts, additionalCosts, totalProfit, profitPerMile, rate, miles, fuelCostsForDeadheadMiles } = getCosts(
      this.state
    );

    const driveTime = getDriveTime(
      this.props.loadInfo?.routeInfo?.driveTime ?? 0,
      this.props.load?.computedMileage ?? 0,
      miles
    );

    const hasInitialRateData = !!getRevenue(this.props.load, this.props.loadInfo?.rateCheck?.payload?.linehaulRevenue);
    const hasRateDataFromUserInput = !!this.state.rate && this.state.rate !== '0';

    return (
      <ExpansionPanel
        id="profit_calculator"
        titleText={t(T.common_load_Profit_Calculator)}
        subtitleText={
          totalProfit > 0
            ? t(T.common_load_your_profit_from_load, {
                profit: totalProfit.toFixed(2),
                perMile: profitPerMile.toFixed(2),
              })
            : '$-'
        }
        icon={CalculatorIcon}
        isOpened={this.props.isOpened}
        handleClick={this.handleTabClick}
      >
        {this.props.isLoading ? (
          <div
            style={{ display: 'flex', height: '200px', alignItems: 'center', justifyContent: 'center', width: '100%' }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <ExpansionPanelDataWrapper style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ResetButton onClick={this.onResetClick} disabled={this.isFiledValuesInitial()}>
              {t(T.profitCalculator_reset)}
            </ResetButton>
            <LinehaulRevenue
              milesField={fields.miles}
              costPerMileField={fields.costPerMile}
              rateField={fields.rate}
              focusedField={this.state.focusedField}
              onMilesChange={this.onMilesChange}
              onCostPerMileChange={this.onCostPerMileChange}
              onRateChange={this.onRateChange}
              onFocus={this.onFieldFocus}
              onEndEditing={this.onEndEditing}
            />
            <LoadDetailsContainer id="fuel_costs">
              <FuelCost
                milesField={fields.miles}
                mpgField={fields.mpg}
                costPerGallonField={fields.costPerGallon}
                costPerGallonDiscountField={fields.costPerGallonDiscount}
                deadheadMilesField={fields.deadheadMiles}
                isFuelDiscountChecked={this.state.isFuelDiscountChecked}
                isDeadheadMilesChecked={this.state.isDeadheadMilesChecked}
                fuelCosts={fuelCosts}
                fuelCostsForDeadheadMiles={fuelCostsForDeadheadMiles}
                onMilesChange={this.onMilesChange}
                onMPGChange={this.onMPGChange}
                onCostPerGallonChange={this.onCostPerGallonChange}
                onCostPerGallonDiscountChange={this.onCostPerGallonDiscountChange}
                onDeadheadMilesChange={this.onDeadheadMilesChange}
                onCheckPress={this.onCheckPress}
                onDeadheadMilesCheckPress={this.onDeadheadMilesCheckPress}
                focusedField={this.state.focusedField}
                onFocus={this.onFieldFocus}
                onEndEditing={this.onEndEditing}
              />
            </LoadDetailsContainer>
            <LoadDetailsContainer id="additional_costs">
              <AdditionalCosts
                tollCostField={fields.tollCost}
                dispatchFeeField={fields.dispatchFee}
                otherFeeField={fields.otherFee}
                rate={this.state.rate}
                additionalCosts={additionalCosts}
                isDispatchFeeFlatAmount={this.state.isDispatchFeeFlatAmount}
                onTollCostChange={this.onTollCostChange}
                onDispatchFeeChange={this.onDispatchFeeChange}
                onDispatchFeeTypeChange={this.onDispatchFeeTypeChange}
                onOtherFeeChange={this.onOtherFeeChange}
                focusedField={this.state.focusedField}
                onFocus={this.onFieldFocus}
                onEndEditing={this.onEndEditing}
              />
            </LoadDetailsContainer>
            <div ref={this.props.totalProfitRef}>
              <TotalProfit
                totalProfit={totalProfit}
                profitPerMile={profitPerMile}
                linehaulRevenue={rate}
                fuelCosts={fuelCosts}
                additionalCosts={additionalCosts}
              />
            </div>
            {this.props.costPerDayEnabled ? (
              <CostPerDayCard
                driveTime={driveTime}
                totalProfit={totalProfit}
                fuelCosts={fuelCosts}
                additionalCosts={additionalCosts}
                rate={rate}
                miles={toNumberWithComma(this.state.miles)}
                deadheadMiles={
                  this.state.deadheadMiles && this.state.isDeadheadMilesChecked
                    ? toNumberWithComma(this.state.deadheadMiles)
                    : undefined
                }
                onGetProfitPerDay={this.props.onGetProfitPerDay}
                hasRateData={hasInitialRateData || hasRateDataFromUserInput}
              />
            ) : null}
          </ExpansionPanelDataWrapper>
        )}
      </ExpansionPanel>
    );
  }

  private readonly setInitialValues = () => this.setState({ ...initialState, ...this.getInitialValues() });

  private readonly onMilesChange = (miles: string) => {
    const milesField = createField(FieldType.Miles, miles);
    if (!milesField.doesAllowToType()) {
      return;
    }
    this.setState((prevState: ProfitCalculatorState) => {
      const fields = getFields(prevState);
      if (fields.costPerMile.value()) {
        const newRate = String(milesField.value() * fields.costPerMile.value());
        return {
          ...prevState,
          miles: milesField.text,
          rate: createField(FieldType.Rate, newRate).normalisedText(),
        };
      } else if (fields.rate.value()) {
        const newCostPerMileValue = fields.rate.value() / milesField.value();
        const newCostPerMile = isFinite(newCostPerMileValue) ? String(newCostPerMileValue) : '';
        return {
          ...prevState,
          miles: milesField.text,
          costPerMile: createField(FieldType.Rate, newCostPerMile).normalisedText(),
        };
      } else {
        return {
          ...prevState,
          miles: milesField.text,
          rate: undefined,
          costPerMile: '',
        };
      }
    });
  };

  private readonly onCostPerMileChange = (costPerMile: string) => {
    const costPerMileField = createField(FieldType.CostPerMile, costPerMile);
    if (!costPerMileField.doesAllowToType()) {
      return;
    }
    this.setState((prevState: ProfitCalculatorState) => {
      const fields = getFields(prevState);
      const rate = fields.miles.isEmpty()
        ? prevState.rate
        : String(Number(costPerMileField.normalisedText()) * fields.miles.value());
      if (!rate || isEqual(rate, '0')) {
        return {
          ...prevState,
          costPerMile: costPerMileField.text,
          rate: undefined,
          miles: prevState.miles ?? '',
        };
      } else {
        return {
          ...prevState,
          costPerMile: costPerMileField.text,
          rate: createField(FieldType.Rate, rate).normalisedText(),
        };
      }
    });
  };

  private readonly onRateChange = (rate: string) => {
    const rateField = createField(FieldType.Rate, rate);
    if (!rateField.doesAllowToType()) {
      return;
    }
    this.setState((prevState: ProfitCalculatorState) => {
      const fields = getFields(prevState);
      const costPerMile = fields.miles.isEmpty()
        ? ''
        : String(Number(rateField.normalisedText()) / fields.miles.value());
      if (!costPerMile || isEqual(costPerMile, '0')) {
        return {
          ...prevState,
          rate: rateField.text,
          costPerMile: '',
          miles: prevState.miles ?? '',
        };
      } else {
        return {
          ...prevState,
          rate: rateField.text,
          costPerMile: createField(FieldType.CostPerMile, costPerMile).normalisedText(),
        };
      }
    });
  };

  private saveMPGState(mpg: number) {
    const settings = { mpg: mpg };
    this.props.updateSettings(settings);
  }

  private readonly onMPGChange = (mpg: string) => {
    const mpgField = createField(FieldType.MPG, mpg);
    if (!mpgField.doesAllowToType()) {
      return;
    }

    this.setState({ mpg: mpgField.text });
    this.saveMPGState(parseFloat(mpg));
  };

  private readonly onCostPerGallonChange = (costPerGallon: string) => {
    const costPerGallonField = createField(FieldType.CostPerGallon, costPerGallon);
    if (!costPerGallonField.doesAllowToType()) {
      return;
    }
    this.setState({ costPerGallon: costPerGallonField.text });
  };

  private readonly onCostPerGallonDiscountChange = (costPerGallonDiscount: string) => {
    const costPerGallonDiscountField = createField(FieldType.CostPerGallonDiscount, costPerGallonDiscount);
    if (!costPerGallonDiscountField.doesAllowToType()) {
      return;
    }
    persistCalculatorData({ costPerGallonDiscount: costPerGallonDiscountField.text });
    this.setState({ costPerGallonDiscount: costPerGallonDiscountField.text });
  };

  private readonly onCheckPress = () => {
    persistCalculatorData({ isFuelDiscountChecked: !this.state.isFuelDiscountChecked });
    this.setState((prevState: ProfitCalculatorState) => ({ isFuelDiscountChecked: !prevState.isFuelDiscountChecked }));
  };

  private readonly onDeadheadMilesCheckPress = () => {
    persistCalculatorData({ isDeadheadMilesChecked: !this.state.isDeadheadMilesChecked });
    this.setState((prevState: ProfitCalculatorState) => ({
      isDeadheadMilesChecked: !prevState.isDeadheadMilesChecked,
    }));
  };

  private readonly onTollCostChange = (tollCost: string) => {
    const tollCostField = createField(FieldType.TollCost, tollCost);
    if (!tollCostField.doesAllowToType()) {
      return;
    }
    this.setState({ tollCost: tollCostField.text });
  };

  private readonly onDispatchFeeChange = (dispatchFee: string) =>
    this.setState((prevState: ProfitCalculatorState) => {
      const dispatchFeeField = createField(FieldType.DispatchFee, dispatchFee, prevState.isDispatchFeeFlatAmount);
      if (!dispatchFeeField.doesAllowToType()) {
        return prevState;
      }
      persistCalculatorData({ dispatchFee: dispatchFeeField.text });
      return { ...prevState, dispatchFee: dispatchFeeField.text };
    });

  private readonly onDispatchFeeTypeChange = (type: string) =>
    this.setState((prevState: ProfitCalculatorState) => {
      const isDispatchFeeFlatAmount = type === '$';
      const dispatchFeeField = createField(FieldType.DispatchFee, prevState.dispatchFee, isDispatchFeeFlatAmount);
      persistCalculatorData({ isDispatchFeeFlatAmount: isDispatchFeeFlatAmount });
      return { isDispatchFeeFlatAmount: isDispatchFeeFlatAmount, dispatchFee: dispatchFeeField.normalisedText() };
    });

  private readonly onOtherFeeChange = (otherFee: string) => {
    const otherFeeField = createField(FieldType.OtherFee, otherFee);
    if (!otherFeeField.doesAllowToType()) {
      return;
    }
    this.setState({ otherFee: otherFeeField.text });
  };

  private readonly onDeadheadMilesChange = (miles: string) => {
    const milesField = createField(FieldType.DeadheadMiles, miles);
    if (!milesField.doesAllowToType()) {
      return;
    }
    this.setState(() => {
      return {
        deadheadMiles: milesField.text,
      };
    });
  };

  private readonly onEndEditing = (fieldType: FieldType) => () =>
    this.setState((prevState: ProfitCalculatorState) => {
      const field = getFields(prevState)[fieldType];
      const currentField = toString(fieldType);
      if (currentField === FieldType.DispatchFee) {
        persistCalculatorData({ dispatchFee: field.normalisedText() });
      }
      return {
        [fieldType]: field.normalisedText(),
        focusedField: undefined,
      } as unknown as ProfitCalculatorState;
    });

  private readonly onFieldFocus = (fieldType: FieldType) => () =>
    this.setState((prevState) => {
      const fields = getFields(prevState);
      const text = fields[fieldType].text ? removeComma(fields[fieldType].text ?? '') : undefined;
      return {
        [fieldType]: text,
        focusedField: fieldType,
      } as unknown as ProfitCalculatorState;
    });

  private readonly handleTabClick = () => {
    this.props.handleChange(LoadDetailsTabs.ProfitCalculator, !this.props.isOpened);
  };
}

const mapStateToProps = (state: StoreState, props: ComponentProps): ReduxProps => {
  const mpgResult = state.settings.routesSettings?.payload?.mpg;

  const costPerDayEnabled = state.settings.systemSetting[Config.CostPerDaySettings].value.enabled;

  const currentLoadInfo = state.loadInfo.loadInfoMap.get(props.load.id);
  const isLoadingRateCheck = currentLoadInfo?.isLoadingRateCheck ?? false;
  const isLoadingFuelPrice = currentLoadInfo?.isLoadingFuelPrice ?? false;

  return {
    isLoading: isLoadingRateCheck || isLoadingFuelPrice,
    mpgResult: mpgResult,
    costPerDayEnabled: costPerDayEnabled,
    loads: state.loadSearch.loads,
    similarLoads: state.loadSearch.similarLoads,
    backhaulsLoads: state.backhauls.loads,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      fetchLoadFuelPrice: fetchLoadFuelPrice,
      fetchRoutesSettings: () => fetchRoutesSettings(),
      getSystemSetting: getSystemSetting,
      updateSettings: (settings: RoutesSettings) => updateRoutesSettings(settings),
    },
    dispatch
  );

export const ProfitCalculator = connect(mapStateToProps, mapDispatchToProps)(ProfitCalculatorComponent);

const initialState: ProfitCalculatorState = {
  miles: undefined,
  costPerMile: undefined,
  rate: undefined,
  mpg: undefined,
  costPerGallon: undefined,
  costPerGallonDiscount: '0.40',
  isFuelDiscountChecked: false,
  tollCost: undefined,
  dispatchFee: undefined,
  otherFee: undefined,
  isDispatchFeeFlatAmount: false,
  focusedField: undefined,
  deadheadMiles: undefined,
  isDeadheadMilesChecked: false,
};

import React from 'react';

import { useDispatch } from 'react-redux';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { Routes } from '@/router/Routes';
import { isLoadSaved } from '@common/helper';
import { Load, LoadProgress } from '@common/model';
import { fetchSharedLoad, hideLoad, saveLoad } from '@common/redux/epic/LoadInfoEpic';
import { useDidUpdate } from '@common/util/hooks';
import {
  ContactButtons,
  LoadContactCallbackProps,
} from '@component/panels/findLoads/loadDetailsPanel/buttons/ContactButtons';
import { SaveButton } from '@component/panels/findLoads/loadDetailsPanel/buttons/SaveButton';
import {
  ActionButton,
  ActionButtonTitle,
  ActionButtonWrapper,
  ActionLoadRow,
  ContactButtonWrapper,
  FlexWrap,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { isPanelSingleColumn } from '@component/panelSizeDetector/PanelSizeDetector';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import icons from '@util/iconsConstants';
import { PRINT_LINK } from '@util/OldMemberSiteLink';

import { default as UpdateStatusSVG } from './update-status.svg?react';

interface Props extends LoadContactCallbackProps {
  load: Load | undefined;
  isDisabled: boolean;
  width: number | undefined;

  isOriginMessaging: boolean;
  isOriginBids: boolean;
  shouldShowHideButton: boolean;

  onUpdateStatusClick: () => void;
  onClosePanel?: () => void;
}

export const LoadDetailsActionsBar: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  useDidUpdate(() => {
    if (props.load?.metadata?.userdata?.isHidden) {
      dispatch(showSnackbar({ message: t(T.common_load_Load_hidden) }));
      if (props.onClosePanel) {
        props.onClosePanel();
      }
    }
  }, [props.load?.metadata?.userdata?.isHidden]);

  if (props.width === undefined) {
    return null;
  }

  const loadProgress = props.load?.metadata?.userdata?.progress ?? LoadProgress.LoadAvailable;
  const updateStatusColor = loadProgress === LoadProgress.LoadAvailable ? Color.GRAY_DARK : Color.ORANGE_MAIN;

  const shouldDisplayContactButtons = !isPanelSingleColumn(props.width);

  const print = () => {
    if (props.load) {
      const url = PRINT_LINK + props.load.id;
      return window.open(url, '_blank');
    }
    return null;
  };

  const hide = () => {
    if (props.load) {
      dispatch(hideLoad(props.load.id));
    }
  };

  const shareLoad = () => {
    if (props.load) {
      dispatch(fetchSharedLoad(props.load.id));
    }
  };

  const handleSave = () => {
    if (!props.load) {
      return;
    }
    const isSaved = isLoadSaved(props.load);
    if (isSaved) {
      dispatch(
        showSnackbar({
          message: t(T.common_load_Load_is_unsaved),
        })
      );
    } else {
      dispatch(
        showSnackbar({
          message: t(T.common_load_Load_is_saved),
        })
      );
    }
    dispatch(saveLoad(props.load.id, !isSaved));
  };

  //using safari or firefox we have an issue with the width and position for the action buttons
  //fit-content or other spaces aren't working, with min-content we can properly set this width
  const maxWidth = shouldDisplayContactButtons ? 'min-content' : 'unset';

  return (
    <>
      <ActionLoadRow width={props.width}>
        <ActionButtonWrapper style={{ maxWidth: maxWidth }}>
          {loadProgress === LoadProgress.LoadAvailable ? (
            <SaveButton
              isDisabled={props.isDisabled}
              isSaved={isLoadSaved(props.load)}
              maxWidth={maxWidth}
              onClick={handleSave}
            />
          ) : null}
          <ActionButton
            style={{
              color: updateStatusColor,
              opacity: props.isDisabled ? 0.5 : 1,
              maxWidth: maxWidth,
            }}
            id="update_status"
            disabled={props.isDisabled}
            onClick={props.onUpdateStatusClick}
          >
            <FlexWrap>
              <UpdateStatusSVG style={{ marginRight: '3px' }} stroke={updateStatusColor} />
              <ActionButtonTitle id="title">{t(T.common_loadDetails_updateStatus)}</ActionButtonTitle>
            </FlexWrap>
          </ActionButton>
          <ActionButton id="share" onClick={shareLoad} disabled={props.isDisabled} style={{ maxWidth: maxWidth }}>
            <FlexWrap>
              <img id="icon" src={props.isDisabled ? icons.shareDisabled : icons.share} alt="share" />
              <ActionButtonTitle id="title">{t(T.common_load_Share)}</ActionButtonTitle>
            </FlexWrap>
          </ActionButton>
          <ActionButton id="print" onClick={print} disabled={props.isDisabled} style={{ maxWidth: maxWidth }}>
            <FlexWrap>
              <img id="icon" src={props.isDisabled ? icons.printDisabled : icons.print} alt="print" />
              <ActionButtonTitle id="title">{t(T.common_load_Print)}</ActionButtonTitle>
            </FlexWrap>
          </ActionButton>
          {props.shouldShowHideButton ? (
            <ActionButton id="hide" onClick={hide} disabled={props.isDisabled} style={{ maxWidth: maxWidth }}>
              <FlexWrap>
                <img id="icon" src={icons.hide} alt="hide" />
                <ActionButtonTitle id="title">{t(T.common_load_Hide)}</ActionButtonTitle>
              </FlexWrap>
            </ActionButton>
          ) : null}
        </ActionButtonWrapper>
        {shouldDisplayContactButtons ? (
          <ContactButtonWrapper>
            <ContactButtons
              load={props.load}
              path={Routes.LOADS_FINDLOADS}
              isOriginMessaging={props.isOriginMessaging}
              isOriginBids={props.isOriginBids}
              onCallClick={props.onCallClick}
              onEmailClick={props.onEmailClick}
              onInteractiveBidClick={props.onInteractiveBidClick}
              onVendorBidClick={props.onVendorBidClick}
              onBookNowClick={props.onBookNowClick}
              width={props.width / 2} // takes up half the panel width
            />
          </ContactButtonWrapper>
        ) : null}
      </ActionLoadRow>
    </>
  );
};

import { RefObject, useLayoutEffect, useState } from 'react';

import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { usePreviousWithInitialValue } from '@util/hooks/UseRedux';

export const useLoadMoreIfNecessary = (
  hasMore: boolean,
  isLoading: boolean | undefined,
  observerRef: RefObject<HTMLDivElement>,
  rootRef: RefObject<HTMLDivElement> | undefined,
  isReverse: boolean,
  loadMore?: () => void
) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isVisible] = useIntersectionObserver(observerRef, { root: rootRef?.current ?? null }, isEnabled);
  const [scrollHeight, setScrollHeight] = useState(0);
  // added this to detect transition in changing of isLoading state
  const prevIsLoading = usePreviousWithInitialValue(isLoading, null);
  const isWindowScrollMode = useIsWindowScrollMode();

  useLayoutEffect(() => {
    if (!prevIsLoading && isLoading) {
      // disable observer if we are loading new pack of results
      setIsEnabled(false);
    } else if (hasMore && (prevIsLoading || prevIsLoading === null) && isLoading === false) {
      if (isReverse) {
        // in reversed list we should change scrollTop to display new results on top of the list
        // and create distance from top (to prevent one more loading of results)
        if (isWindowScrollMode) {
          document.documentElement.scrollTop += document.documentElement.scrollHeight - scrollHeight;
        } else if (rootRef?.current) {
          rootRef.current.scrollTop += rootRef.current.scrollHeight - scrollHeight;
        }
      }
      // enable observer if we loaded results and ready to observe new intersection
      setIsEnabled(true);
    }
  }, [isLoading, prevIsLoading, hasMore, isReverse, isWindowScrollMode]);

  useLayoutEffect(() => {
    if (hasMore && isVisible && isEnabled && isLoading === false) {
      loadMore?.();
      if (isReverse) {
        setScrollHeight(
          isWindowScrollMode ? document.documentElement.scrollHeight : (rootRef?.current?.scrollHeight ?? 0)
        );
      }
    }
  }, [hasMore, isVisible, isEnabled, isLoading, isReverse, isWindowScrollMode]);
};

const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  { threshold = 0, root = null, rootMargin = '0px' }: IntersectionObserverInit,
  isEnabled: boolean = true
): [boolean] => {
  const [isVisible, setIsVisible] = useState(false);
  const isWindowScrollMode = useIsWindowScrollMode();

  const callback = ([entry]: IntersectionObserverEntry[]) => {
    setIsVisible(entry?.isIntersecting ?? false);
  };

  useLayoutEffect(() => {
    const node = elementRef?.current;

    if (!node || !isEnabled || (!isWindowScrollMode && !root)) {
      setIsVisible(false);
      return undefined;
    }

    const observerParams = { threshold: threshold, root: root, rootMargin: rootMargin };
    const observer = new IntersectionObserver(callback, observerParams);

    observer.observe(node);

    return () => {
      observer.disconnect();
    };
  }, [elementRef?.current, threshold, root, rootMargin, isEnabled]);

  return [isVisible];
};

import React from 'react';

import styled from 'styled-components';

import InfoIcon from '@/icons/icon_info.svg';
import { BorderWidth, ElementSize, FontSize, Spacing } from '@/style/StyleConstants';
import { Color } from '@style/Color';

const OverlayContainer = styled.div`
  background-color: ${Color.BLUE_AZURE};
  border-bottom: ${BorderWidth.Medium}px solid ${Color.BLUE_SKY};
  margin-bottom: ${Spacing.ScreenSide}px;
`;

const OverlayHeader = styled.div`
  margin-top: 0px;
  margin-left: ${Spacing.ScreenSide}px;
  margin-bottom: ${Spacing.InterSection}px;
  font-size: ${FontSize.ContentDetail}px;
  padding-top: ${Spacing.InterElementVertical}px;
  display: flex;
  flex-wrap: nowrap;
`;

const OverlayContent = styled.div`
  margin: ${Spacing.ScreenSide}px;
  margin-top: 0px;
  font-size: ${FontSize.Content};
`;

const Icon = styled.img`
  height: ${ElementSize.Icon}px;
  width: ${ElementSize.Icon}px;
  margin-left: 0px;
  margin-right: ${Spacing.ScreenSide}px;
  margin-top: ${Spacing.InterListElement}px;
`;

export const InfoRibbon: React.FC<{ headerLabel: string }> = (props) => {
  return (
    <OverlayContainer>
      <OverlayHeader>
        <Icon src={InfoIcon}></Icon>
        {props.headerLabel}
      </OverlayHeader>
      <OverlayContent>{props.children}</OverlayContent>
    </OverlayContainer>
  );
};

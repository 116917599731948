import { startsWith, toLower } from 'lodash';

import { StoreState } from '@/reduxStore/Store';
import { ASPX_ROUTES } from '@/router/Routes';
import { AppConfig } from '@util/AppConfig';

export interface RouteLocation {
  pathname: string;
  search: string;
  hash: string;
}

export interface RouteMatch {
  reactToAspxMatch: (
    reactLocation: RouteLocation,
    storeState: StoreState
  ) => { aspxUrl: string; pageTitle: string } | undefined;
  aspxToReactMatch: (aspxLocation: RouteLocation) => string | undefined;
}

export const appendQueryAndHash = (url: string, location: { search: string; hash: string }) => {
  let targetUrl = url;

  if (location.search) {
    targetUrl += location.search;
  }

  if (location.hash) {
    targetUrl += location.hash;
  }

  return targetUrl;
};

interface StandardAspxRouteData {
  defaultTitle: string;
  reactPath: string;
  aspxPath: string;
}
interface PrefixAspxRouteData extends StandardAspxRouteData {
  suffixMatch?: RegExp;
}

export const exactMatchASPXRoute: (route: StandardAspxRouteData) => RouteMatch = (route) => ({
  reactToAspxMatch: (reactLocation: RouteLocation) => {
    if (reactLocation.pathname !== route.reactPath) {
      return undefined;
    }

    const aspxUrl = AppConfig.LBConfig.ASPEmbedRoot + route.aspxPath;

    return { aspxUrl: appendQueryAndHash(aspxUrl, reactLocation), pageTitle: route.defaultTitle };
  },
  aspxToReactMatch: (aspxLocation: RouteLocation) => {
    // ASPX urls are case insensitive: Work in lowercase when comparing paths
    if (route.aspxPath !== toLower(aspxLocation.pathname)) {
      return undefined;
    }

    return appendQueryAndHash(route.reactPath, aspxLocation);
  },
});

export const prefixMatchASPXRoute: (route: PrefixAspxRouteData) => RouteMatch = (route) => ({
  reactToAspxMatch: (reactLocation: RouteLocation) => {
    const reactPathSuffix = reactLocation.pathname.substr(route.reactPath.length);

    if (!startsWith(reactLocation.pathname, route.reactPath)) {
      return undefined;
    }

    if (route.suffixMatch && !route.suffixMatch.test(reactPathSuffix)) {
      return undefined;
    }

    const aspxUrl = AppConfig.LBConfig.ASPEmbedRoot + route.aspxPath + reactPathSuffix;

    return { aspxUrl: appendQueryAndHash(aspxUrl, reactLocation), pageTitle: route.defaultTitle };
  },
  aspxToReactMatch: (aspxLocation: RouteLocation) => {
    const aspxPath = aspxLocation.pathname;
    const aspxPathSuffix = aspxPath.substr(route.aspxPath.length);

    // ASPX urls are case insensitive: Work in lowercase when comparing paths
    if (!startsWith(toLower(aspxPath), route.aspxPath)) {
      return undefined;
    }

    if (route.suffixMatch && !route.suffixMatch.test(aspxPathSuffix)) {
      return undefined;
    }
    const reactUrl = route.reactPath + aspxPathSuffix;

    return appendQueryAndHash(reactUrl, aspxLocation);
  },
});

export const aspxGenericOnlineMatchASPXRoute: RouteMatch = {
  // GENERIC /online/ case not covered by explicit mappings.
  // we map it to /online/ on the react side (forced lowercase for the "/online/" react part)
  // there is no default title, we'll wait for the ASPX page to report it's title.

  // Note: strictly /online/ and /online/help/ are not to be mapped to ASPX at all.

  reactToAspxMatch: (reactLocation) => {
    if (
      !startsWith(reactLocation.pathname, ASPX_ROUTES.GENERIC_ONLINE_PREFIX) ||
      reactLocation.pathname === ASPX_ROUTES.GENERIC_ONLINE_PREFIX ||
      reactLocation.pathname === ASPX_ROUTES.HELP_PREFIX ||
      reactLocation.pathname === ASPX_ROUTES.FIND_LOADS
    ) {
      return undefined;
    }
    const aspxUrl = AppConfig.LBConfig.ASPEmbedRoot + reactLocation.pathname;

    return { aspxUrl: appendQueryAndHash(aspxUrl, reactLocation), pageTitle: '' };
  },
  aspxToReactMatch: (aspxLocation) => {
    const aspxPath = aspxLocation.pathname;
    // ASPX urls are case insensitive: Work in lowercase when comparing paths
    const aspxPathLowercase = toLower(aspxPath);

    if (!startsWith(aspxPathLowercase, ASPX_ROUTES.GENERIC_ONLINE_PREFIX)) {
      return undefined;
    }

    // keep the original ASPX path casing
    const truncatedAspxPath = aspxPath.substr(ASPX_ROUTES.GENERIC_ONLINE_PREFIX.length);

    return appendQueryAndHash(`${ASPX_ROUTES.GENERIC_ONLINE_PREFIX}${truncatedAspxPath}`, aspxLocation);
  },
};

export const aspxGenericRootMatchASPXRoute: RouteMatch = {
  // GENERIC "/" (aspx root) case not covered by explicit mappings
  // we map it to /onlineroot/ on the react side
  // there is no default title, we'll wait for the ASPX page to report it's title.

  reactToAspxMatch: (reactLocation) => {
    if (!startsWith(reactLocation.pathname, ASPX_ROUTES.GENERIC_ROOT_PREFIX)) {
      return undefined;
    }

    const aspxUrl =
      AppConfig.LBConfig.ASPEmbedRoot + reactLocation.pathname.substr(ASPX_ROUTES.GENERIC_ROOT_PREFIX.length);

    return { aspxUrl: appendQueryAndHash(aspxUrl, reactLocation), pageTitle: '' };
  },
  aspxToReactMatch: (aspxLocation) => {
    const aspxPath = aspxLocation.pathname;
    const truncatedAspxPath = startsWith(aspxPath, '/') ? aspxPath.substr(1) : aspxPath;

    return appendQueryAndHash(`${ASPX_ROUTES.GENERIC_ROOT_PREFIX}${truncatedAspxPath}`, aspxLocation);
  },
};

export const enableRouteMatchIf = (predicate: (state: StoreState) => boolean, routeMatch: RouteMatch) => ({
  reactToAspxMatch: (reactLocation: RouteLocation, state: StoreState) =>
    predicate(state) ? routeMatch.reactToAspxMatch(reactLocation, state) : undefined,
  aspxToReactMatch: routeMatch.aspxToReactMatch,
});

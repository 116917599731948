import { useRef } from 'react';

const SENTINEL = {};

/**
 * `useRefFn` is extension of `useRef` that accepts the initial value as a function.
 *
 * `useRefFn` is useful for saving a class instance and preventing to construct it each time hook function is invoked.
 *
 * @see https://github.com/facebook/react/issues/14490
 *
 * @param init Initial value as a function.
 *
 * @example Example of using `useRefFn`:
 *
 * In this example a new instance will be created each time hook function is invoked.
 *
 * ```
 * const instanceRef = useRef(new Instance())
 * ```
 *
 * In this example a new instance will be created only once.
 *
 * ```
 * const instanceRef = useRefFn(() => new Instance())
 * ```
 */
export const useRefFn = <T>(init: () => T) => {
  const ref = useRef<T | typeof SENTINEL>(SENTINEL);
  if (ref.current === SENTINEL) {
    ref.current = init();
  }
  return ref as React.MutableRefObject<T>;
};

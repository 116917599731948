import styled from 'styled-components';

import { Color } from '@style/Color';

export const LocationsContainer = styled.div`
  display: ${(props: { isVisible: boolean }) => (props.isVisible ? 'block' : 'none')};
  margin-top: 8px;
  width: 100%;
  background-color: ${Color.WHITE};
`;

export const Title = styled.div`
  margin: 12px 16px 21px;
  padding-top: 16px;
  font-size: 14px;
  color: ${Color.GRAY_STONE};
`;

import React from 'react';

import { isEqual } from 'lodash';

import { LoadLocation, OriginLocation } from '@common/model';
import {
  Backhauls,
  Container,
  Icon,
  MainContainerWithActiveUnsave,
  WrapperDestination,
  WrapperIconSearch,
} from '@component/cards/CardsStyle';
import { DestinationWithRadius } from '@component/cards/Destination';
import { FontSize } from '@style/StyleConstants';
import icons from '@util/iconsConstants';

interface ComponentProps {
  destinationFrom: OriginLocation | string;
  destinationTo: LoadLocation | string;
  activeSearch?: number;
  idSearch?: number;
  backhauls: number;
  handleActiveSearch: () => void;
}

export class BackhaulCard extends React.Component<ComponentProps> {
  handleClick = () => {
    return this.props.handleActiveSearch();
  };

  showIcon = () => {
    const { backhauls } = this.props;
    if (isEqual(backhauls, 0)) {
      return null;
    }
    return <Icon {...this.props} src={icons.chevronRight} alt={'backhauls'} />;
  };

  render() {
    const { destinationFrom, destinationTo, backhauls } = this.props;
    return (
      <Container id="backhauls_panel_card">
        <MainContainerWithActiveUnsave {...this.props} onClick={this.handleClick}>
          <WrapperDestination>
            <DestinationWithRadius
              origin={destinationFrom}
              destination={destinationTo}
              styleText={{ fontSize: FontSize.ContentDetail }}
            />
          </WrapperDestination>
          <WrapperIconSearch>
            <Backhauls {...this.props} isClickable={true} style={isEqual(backhauls, 0) ? { marginRight: 32 } : {}}>
              {backhauls}
            </Backhauls>
            {this.showIcon()}
          </WrapperIconSearch>
        </MainContainerWithActiveUnsave>
      </Container>
    );
  }
}

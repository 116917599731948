import React, { useEffect } from 'react';

import { generatePath, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { store } from '@/reduxStore/Store';
import { MEMBERS_PAYMENT_METHOD_LINK, TRUCK_LOCATOR_ACCESS_REQUEST_EXTENSION } from '@common/info';
import { Flag, Roles } from '@common/model';
import { lazy } from '@component/lazyComponent';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { AspxSwitcher } from '@page/aspxPage/AspxSwitcher';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import { withMetadata } from '@util/Metadata';
import { checkAppVersion } from '@webApi/MembersEpic';

import { AuthenticatedRoute } from './AuthenticatedRoute';
import { ASPX_ROUTES, orderedPaths, Routes, TRIP_BUILDER_ROUTES, urlForLoadSearch } from './Routes';

const FORCE_TRAILING_SLASH_REDIRECT = (
  <Route
    exact
    strict
    path="/:url*"
    render={(props) =>
      props.location.search ? (
        <Redirect to={`${props.location.pathname}/${props.location.search}`} />
      ) : (
        <Redirect to={`${props.location.pathname}/`} />
      )
    }
  />
);

const OLD_REDIRECTS = [
  // ReactRouter Switch does not support React Fragments, hence packaging these in an array  https://github.com/ReactTraining/react-router/issues/5785

  <Redirect from={'/brokers/export-posts'} exact={false} to={Routes.MYLOADS_EXPORTPOSTS} key="1" />,
  <Redirect from={'/brokers/html-embed-code'} exact={false} to={Routes.INTEGRATION_SERVICES_HTMLEMBED} key="2" />,
  <Redirect from={'/brokers/posting-aid'} exact={false} to={Routes.INTEGRATION_SERVICES_POSTINGAID} key="3" />,
  <Redirect from={'/brokers/tms'} exact={false} to={Routes.INTEGRATION_SERVICES_TMSINT} key="4" />,
  <Redirect from={'/brokers/truck-locator'} exact={false} to={Routes.FINDTRUCKS_TRUCKLOCATOR} key="5" />,
  <Redirect from={'/brokers/upload-posts'} exact={false} to={Routes.MYLOADS_UPLOADPOSTS} key="6" />,
  <Redirect
    from={'/find-trucks/truck-locator/locateTruck/:id'}
    exact={false}
    to={Routes.FINDTRUCKS_LOCATETRUCK}
    key="7"
  />,
  <Redirect from={ASPX_ROUTES.FIND_LOADS} exact={true} to={`${generatePath(Routes.LOADS_FINDLOADS, {})}`} key="8" />,
  <Redirect from={ASPX_ROUTES.HELP_PREFIX} exact={true} to={Routes.MORE_HELP_BASE_ROUTE} key="9" />,
  <Redirect from={ASPX_ROUTES.GENERIC_ONLINE_PREFIX} exact={false} to={Routes.DASHBOARD} key="10" />,
  <Redirect from={'/loads'} exact={true} to={urlForLoadSearch} key="11" />,
  <Redirect
    from={'/loads/my-loads/post'}
    exact={true}
    to={`${generatePath(Routes.LOADS_MY_LOADS_POST, {})}/`}
    key="12"
  />,
  <Redirect from={'/loads/my-companies/'} exact={false} to={Routes.LOADS_COMPANIES} key="13" />,
  <Redirect from={MEMBERS_PAYMENT_METHOD_LINK} exact={true} to={Routes.MORE_SETTINGS_PAYMENT_METHOD} key="14" />,
  <Redirect from={'/loads/recent/'} exact={false} to={`${generatePath(Routes.LOADS_FINDLOADS, {})}`} key="15" />,
  <Redirect from={'/loads/saved/'} exact={false} to={`${generatePath(Routes.LOADS_FINDLOADS, {})}`} key="16" />,
  <Redirect
    from={'/communication/messages/'}
    exact={false}
    to={`${generatePath(Routes.COMMUNICATION, {})}`}
    key="17"
  />,
  <Redirect
    from={'/loads/carriers/onboarded'}
    exact={false}
    to={generatePath(Routes.MORE_SETTINGS, { filter: Filter.OnboardedCarriers })}
    key="18"
  />,
  <Redirect from={'/post-loads/post/multiple/'} exact={false} to={Routes.MYLOADS_UPLOADPOSTS} key="19" />,
  <Redirect
    from={'/online/carrier/tools/searchdirectory'}
    exact={false}
    to={Routes.RESOURCES_SEARCH_DIRECTORY_BASE}
    key="20"
  />,
  <Redirect from={'/inbox'} exact={false} to={Routes.ANNOUNCEMENTS} key="21" />,
];

// Try to keep a rough ordering here. There are a few groupings and alphabetical sorting within

const NavRoutes: React.FC = React.memo(() => {
  const isBroker = !useIsCarrier();
  const isCapacityFinderEnabled = useSelector(
    (state) => !state.settings.systemSetting[Flag.CapacityFinderDisabled].value
  );

  return (
    <AspxSwitcher>
      <Switch>
        {FORCE_TRAILING_SLASH_REDIRECT}
        {OLD_REDIRECTS}
        {isBroker ? (
          <Redirect
            from={'/tools/rate-check'}
            exact={false}
            to={Routes.TOOLS_MARKET_RATES}
            key="market_rates_redirect"
          />
        ) : (
          <Redirect
            from={'/tools/rate-check'}
            exact={false}
            to={Routes.TOOLS_MARKET_RATES_CARRIER}
            key="market_rates_redirect_carrier"
          />
        )}
        <AuthenticatedRoute
          exact={true}
          path={Routes.DASHBOARD}
          needsProfile={true}
          component={withMetadata(t(T.Header_Dashboard))(
            lazy(() => import(/* webpackChunkName: 'Dashboard' */ '@page/dashboard/Dashboard'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.ANNOUNCEMENTS_VIEW}
          needsProfile={true}
          component={withMetadata(t(T.common_announcements_announcements))(
            lazy(() => import(/* webpackChunkName: 'Announcements' */ '@page/announcements/Announcements'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.LOADS_FINDLOADS}
          component={withMetadata(t(T.dashboard_Find_Loads))(
            lazy(() => import(/* webpackChunkName: 'FindLoads' */ '@page/findLoads/FindLoadsPage'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.LOADS_STANDALONE_LOAD_DETAILS}
          component={withMetadata(t(T.dashboard_Load_Details))(
            lazy(() => import(/* webpackChunkName: 'FindLoads' */ '@page/loadDetailsPage/LoadDetailsPage'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.LOADS_CARRIERS}
          component={withMetadata(t(T.dashboard_Carriers))(
            lazy(() => import(/* webpackChunkName: 'Carriers' */ '@page/carriers/Carriers'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.LOADS_COMPANIES}
          component={withMetadata(t(T.dashboard_Companies))(
            lazy(() => import(/* webpackChunkName: 'Companies' */ '@page/companies/Companies'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={[Routes.LOADS_LOAD_AVAILABILITY_MAP, Routes.LOADS_LOAD_AVAILABILITY]}
          component={withMetadata(t(T.Header_Load_Availability))(
            lazy(() => import(/* webpackChunkName: 'LoadAvailability' */ '@page/loadAvailability/LoadAvailability'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={orderedPaths(TRIP_BUILDER_ROUTES)}
          authorizedRoles={[[Roles.Loadplanner_beta], [Roles.Loadplanner]]}
          shouldShowUpgradeLinkIfRestricted={true}
          component={withMetadata(t(T.menu_loads_tripBuilder))(
            lazy(() => import(/* webpackChunkName: 'TripBuilder' */ '@page/tripBuilder/TripBuilderRouting'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.LOADS_MY_LOADS}
          component={withMetadata(t(T.menu_loads_myLoads))(
            lazy(() => import(/* webpackChunkName: 'MyLoads' */ '@page/myLoads/MyLoads'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.LOADS_MY_LOADS_POST}
          authorizedRoles={[[Roles.Loadposter]]}
          component={withMetadata(t(T.dashboard_My_Loads_Post))(
            lazy(() => import(/* webpackChunkName: 'PostLoads' */ '@page/postLoads/PostLoads'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.COMMUNICATION}
          authorizedRoles={[[Roles.Member]]}
          component={withMetadata(t(T.dashboard_Messages))(
            lazy(() => import(/* webpackChunkName: 'Communication' */ '@page/communication/CommunicationRouting'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={[Routes.BIDS, Routes.BIDS_BASE_ROUTE]}
          authorizedRoles={[[Roles.Member]]}
          component={withMetadata(t(T.Header_Bids))(
            lazy(() => import(/* webpackChunkName: 'BidsPanel' */ '@page/bids/BidsPanelRouting'))
          )}
        />

        {/* ==== FIND TRUCKS pages === */}
        <AuthenticatedRoute
          exact={true}
          path={[Routes.FINDTRUCKS_LOCATETRUCK, Routes.FINDTRUCKS_TRUCKLOCATOR]}
          authorizedRoles={[[Roles.Loadposter, Roles.Sendloadalerts]]}
          pageName={t(T.truckLocator_Truck_Locator)}
          accessRequestPhoneExtension={TRUCK_LOCATOR_ACCESS_REQUEST_EXTENSION}
          component={withMetadata(t(T.truckLocator_Truck_Locator))(
            lazy(() => import(/* webpackChunkName: 'TruckLocator' */ '@page/truckLocator/TruckLocator'))
          )}
        />
        {/* ==== POST Truck page ==== */}
        <AuthenticatedRoute
          exact={true}
          path={Routes.MYTRUCKS_POST}
          component={withMetadata(t(T.truckPost_TruckPost))(
            lazy(() => import(/*webbpackChunkName: 'TruckPosts */ '@page/truckPost/TruckPostingPage'))
          )}
        />
        {/* ==== POST LOADS pages ==== */}
        <AuthenticatedRoute
          exact={true}
          path={Routes.MYLOADS_EXPORTPOSTS}
          needsProfile={true}
          authorizedRoles={[[Roles.Loadposter]]}
          pageName={t(T.exportPosts_Export_Posts)}
          component={withMetadata(t(T.exportPosts_Export_Posts))(
            lazy(() => import(/* webpackChunkName: 'ExportPosts' */ '@page/exportPosts/ExportPosts'))
          )}
        />
        <Route
          exact={true}
          path={Routes.EXPORTPOSTS_TABLE_VIEW}
          component={withMetadata(t(T.exportPosts_sharePostedLoads_exportPostsTableView))(
            lazy(
              () =>
                import(/* webpackChunkName: 'ExportPostsTableView' */ '@page/exportPostsTableView/ExportPostsTableView')
            )
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.INTEGRATION_SERVICES_HTMLEMBED}
          component={withMetadata(t(T.htmlEmbed_HtmlEmbed))(
            lazy(() => import(/* webpackChunkName: 'HTMLEmbed' */ '@page/htmlEmbed/HTMLEmbed'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={[Routes.TOOLS_MILEAGE_CALCULATOR_SEARCH, Routes.TOOLS_MILEAGE_CALCULATOR_MILEAGE_ROUTING]}
          authorizedRoles={[[Roles.Pcmiler]]}
          shouldShowUpgradeLinkIfRestricted={true}
          component={withMetadata(t(T.menu_tools_mileageCalculator))(
            lazy(() => import(/* webpackChunkName: 'MileageCalculator' */ '@page/mileageCalculator/MileageCalculator'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.INTEGRATION_SERVICES_POSTINGAID}
          component={withMetadata(t(T.postingAid_Posting_Aid))(
            lazy(() => import(/* webpackChunkName: 'PostingAid' */ '@page/postingAid/PostingAid'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.INTEGRATION_SERVICES_TMSINT}
          component={withMetadata(t(T.tmsIntegration_TMS_Integration))(
            lazy(() => import(/* webpackChunkName: 'TMSint' */ '@page/tmsIntegration/TMSintegration'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.MYLOADS_UPLOADPOSTS}
          authorizedRoles={[[Roles.Loadposter]]}
          component={withMetadata(t(T.uploadPosts_Upload_Posts))(
            lazy(() => import(/* webpackChunkName: 'UploadPosts' */ '@page/uploadPosts/UploadPosts'))
          )}
        />
        {/* ==== TOOLS pages ========= */}
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_BORDER_WAIT_TIMES}
          component={withMetadata(t(T.borderWaitTimes_BorderWaitTimes))(
            lazy(() => import(/* webpackChunkName: 'BorderWaitTimes' */ '@page/borderWaitTimes/BorderWaitTimes'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_FMCSA_SEARCH}
          component={withMetadata(t(T.fmcsaSearch_FMCSA_Search))(
            lazy(() => import(/* webpackChunkName: 'FmcsaSearch' */ '@page/fmcsaSearch/FmcsaSearch'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_GET_PAID_FASTER}
          requiresAuthentication={false}
          component={withMetadata(t(T.getPaidFaster_title))(
            lazy(() => import(/* webpackChunkName: 'TFQuotes' */ '@page/partnerServices/GetPaidFaster'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_FUEL_PRICES}
          component={withMetadata(t(T.fuelPrices_Title))(
            lazy(() => import(/* webpackChunkName: 'FuelPrices' */ '@page/fuelPrices/FuelPrices'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_PROFIT_CALCULATOR_PAGE}
          component={withMetadata(t(T.profitCalculator_title))(
            lazy(
              () => import(/*webbpackChunkName: 'ProfitCalculatorPage */ '@page/profitCalculator/ProfitCalculatorPage')
            )
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_MARKET_RATES}
          authorizedRoles={[[Roles.Loadposter]]}
          component={withMetadata(t(T.common_marketRate_marketRates))(
            lazy(() => import(/* webpackChunkName: 'MarketRatesPage' */ '@page/marketRates/MarketRatesPage'))
          )}
        />
        {isCapacityFinderEnabled ? (
          <AuthenticatedRoute
            exact={true}
            path={Routes.TOOLS_CAPACITY_FINDER}
            authorizedRoles={[[Roles.Loadposter]]}
            component={withMetadata(t(T.capacityFinder_titleCapitalized))(
              lazy(() => import(/* webpackChunkName: 'CapacityFinderPage' */ '@page/capacityFinder/CapacityFinderPage'))
            )}
          />
        ) : null}
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_MARKET_RATES_CARRIER}
          authorizedRoles={[[Roles.Ratecheck, Roles.Truckposter]]}
          shouldShowUpgradeLinkIfRestricted={true}
          component={withMetadata(t(T.common_marketRate_marketRates))(
            lazy(
              () => import(/* webpackChunkName: 'MarketRatesCarrierPage' */ '@page/marketRates/MarketRatesCarrierPage')
            )
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_REEFER_RATES}
          component={withMetadata(t(T.reeferRates_Reefer_Rates))(
            lazy(() => import(/* webpackChunkName: 'ReeferRates' */ '@page/reeferRates/ReeferRates'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_MY_DOCUMENTS}
          component={withMetadata(t(T.common_myDocuments_title))(
            lazy(() => import(/* webpackChunkName: 'MyDocuments' */ '@page/myDocuments/MyDocuments'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_ROADSIDE_ASSIST}
          requiresAuthentication={false}
          component={withMetadata(t(T.roadsideAssist_Roadside_Assistance))(
            lazy(() => import(/* webpackChunkName: 'RoadsideAssist' */ '@page/roadsideAssist/RoadsideAssist'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_TRUCKING_AUTHORITY}
          requiresAuthentication={false}
          component={withMetadata(t(T.truckingAuthority_Trucking_Authority))(
            lazy(() => import(/* webpackChunkName: 'TruckingAuthority' */ '@page/partnerServices/TruckingAuthority'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.TOOLS_SAVE_AT_THE_PUMP}
          requiresAuthentication={false}
          component={withMetadata(t(T.fuelCard_title))(
            lazy(() => import(/* webpackChunkName: 'TFQuotes' */ '@page/partnerServices/FuelCard'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.RESOURCES_SEARCH_DIRECTORY}
          shouldShowUpgradeLinkIfRestricted={true}
          authorizedRoles={[[Roles.SearchDirectory]]}
          component={withMetadata(t(T.Header_Search_Directory))(
            lazy(() => import(/* webpackChunkName: 'SearchDirectory' */ '@page/searchDirectory/SearchDirectory'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.MEMBERSHIPS_UNSUBSCRIBE_REASONS}
          component={withMetadata(t(T.unsubscribeAssistant_title))(
            lazy(
              () =>
                import(/*webbpackChunkName: 'UnsubscribeAssistant */ '@page/unsubscribeAssistant/UnsubscribeAssistant')
            )
          )}
        />
        {/* ==== SETTINGS pages ============ */}
        <AuthenticatedRoute
          exact={true}
          path={[Routes.MORE_SETTINGS, Routes.MORE_SETTINGS_EDIT_USER, Routes.MORE_SETTINGS_EDIT_TRUCK]}
          component={withMetadata(t(T.Settings))(
            lazy(() => import(/*webbpackChunkName: 'Settings' */ '@page/newSettings/SettingsPage'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          authorizedRoles={[[Roles.Loadposter]]}
          path={Routes.MORE_SETTINGS_PRIVATE_LOADBOARD}
          component={withMetadata(t(T.privateNetwork_title))(
            lazy(() => import(/* webpackChunkName: 'Settings' */ '@page/newSettings/SettingsPage'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.MORE_SETTINGS_ACCOUNT_SETUP_NEW}
          component={withMetadata(t(T.aspxEmbed_accountSetup_title))(
            lazy(() => import(/*webbpackChunkName: 'Settings' */ '@page/accountSetup/AccountSetupRouting'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.MORE_SETTINGS_ACCOUNT_VERIFICATION}
          component={withMetadata(t(T.postLoads_pendingVerification_thankYouForSignUp))(
            lazy(() => import(/*webbpackChunkName: 'Settings' */ '@page/accountVerification/BrokerAccountVerification'))
          )}
        />
        {/* ==== HELP pages ============ */}
        <AuthenticatedRoute
          exact={true}
          path={[Routes.MORE_HELP, Routes.MORE_HELP_SERVICE_CODE, Routes.MORE_HELP_SCREEN_SHARING]}
          component={withMetadata(t(T.Help))(lazy(() => import(/*webbpackChunkName: 'Help' */ '@page/help/HelpPage')))}
        />
        {/* ==== PUBLIC pages ============ */}
        <AuthenticatedRoute
          exact={true}
          path={Routes.PUB_SHARELOAD}
          requiresAuthentication={false}
          component={withMetadata(t(T.shareLoad_title))(
            lazy(() => import(/* webpackChunkName: 'ShareLoad' */ '@page/shareLoad/ShareLoad'))
          )}
        />
        <AuthenticatedRoute
          path={Routes.FLAGS}
          requiresAuthentication={false}
          component={lazy(() => import(/* webpackChunkName: 'Flags' */ '@page/flags/Flags'))}
        />
        <Route
          exact={true}
          path={Routes.MORE_LOGOUT}
          component={lazy(() => import(/* webpackChunkName: 'Logout' */ '@/router/Logout'))}
        />
        <Route
          exact={true}
          path={Routes.VERIFY_EMAIL}
          component={withMetadata(t(T.verifyEmail_title))(
            lazy(() => import(/* webpackChunkName: 'VerifyEmail' */ '@page/verifyEmail/VerifyEmail'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.VERIFY_EMAIL_BLOCKER_PAGE}
          component={withMetadata(t(T.verifyEmail_title))(
            lazy(() => import(/* webpackChunkName: 'VerifyEmail' */ '@page/verifyEmail/VerifyEmailBlockerPage'))
          )}
        />
        <Route
          exact={true}
          path={Routes.VERIFIED_LOGIN}
          component={lazy(() => import(/* webpackChunkName: 'VerifyEmail' */ '@page/verifiedLogin/VerifiedLogin'))}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.WELCOMEBACK_CAMPAIGN}
          requiresAuthentication={false}
          component={withMetadata(t(T.welcomebackCampaign_welcomeback))(
            lazy(() => import(/* webpackChunkName: 'WelcomeBack' */ '@page/welcomebackCampaign/WelcomeBackOfferPage'))
          )}
        />
        <AuthenticatedRoute
          exact={true}
          path={Routes.NOTFOUND}
          requiresAuthentication={false}
          component={withMetadata(t(T.errors_not_found_Title))(
            lazy(() => import(/* webpackChunkName: 'NotFound' */ '@page/error/NotFound'))
          )}
        />
        <Redirect to={Routes.NOTFOUND} />
      </Switch>
    </AspxSwitcher>
  );
});

function useDetectVersionChangeOnUrlChange() {
  const location = useLocation();

  useEffect(() => {
    store.dispatch(checkAppVersion());
  }, [location.pathname]);
}

export const Routing: React.FC<{}> = () => {
  useDetectVersionChangeOnUrlChange();
  return (
    <>
      <NavRoutes />
    </>
  );
};

import { useMemo } from 'react';

import { includes } from 'lodash';

import { AvailabilityType } from '@/model';
import { Routes } from '@/router/Routes';
import { resetMetadata, withSorting } from '@common/helper';
import {
  EquipmentType,
  FormatedLoadAvailability,
  LoadAvailabilitySortCategory,
  LoadSearchRequest,
  LoadSearchSort,
  LoadSortCategory,
  LoadSortDirection,
  LoadSortField,
  ViewField,
} from '@common/model';
import { MapData } from '@page/findLoads/LoadSearchRequestEncoder';

import { loadAvailabilityEncoder } from './LoadAvailabilityEncoder';

export enum LoadAvailabilityTabNames {
  Outbound,
  Intrastate,
  Inbound,
}

export const useRouteQuery = (search: string) => {
  return useMemo(() => {
    const {
      equipmentType = [EquipmentType.All],
      region = LoadAvailabilitySortCategory.USA,
      selectedState,
      sortBy = LoadSortField.PostedAge,
      sortDirection = LoadSortDirection.Asc,
      loadID,
      changeView = ViewField.List,
      mapData,
      searchData,
      searchId,
      companySearchId,
      companySearchData,
      companyMapData,
    } = loadAvailabilityEncoder.convertUrlParamsToLoadAvailabilityRouting(search);
    return {
      equipmentType: equipmentType,
      region: region,
      selectedState: selectedState,
      sortBy: sortBy,
      sortDirection: sortDirection,
      loadID: loadID,
      changeView: changeView,
      mapData: mapData,
      searchData: searchData,
      searchId: searchId,
      companySearchId: companySearchId,
      companySearchData: companySearchData,
      companyMapData: companyMapData,
    };
  }, [search]);
};

export const createURLParams = (
  selectedState: string | undefined,
  region: LoadAvailabilitySortCategory | undefined,
  equipmentType: EquipmentType[] | undefined,
  sortBy: LoadSortField | undefined,
  sortDirection: LoadSortDirection | undefined,
  loadID: string | undefined,
  changeView: ViewField | undefined,
  mapData: MapData | undefined,
  searchData: LoadSearchRequest | undefined,
  searchId: number | undefined,
  companySearchId: string | undefined,
  companySearchData: LoadSearchRequest | undefined,
  companyMapData: MapData | undefined
) =>
  loadAvailabilityEncoder.convertObjectRequestToUrlParams({
    selectedState: selectedState,
    region: region,
    equipmentType: equipmentType,
    sortBy: sortBy,
    sortDirection: sortDirection,
    loadID: loadID,
    changeView: changeView,
    mapData: mapData,
    searchData: searchData,
    searchId: searchId,
    companySearchId: companySearchId,
    companySearchData: companySearchData,
    companyMapData: companyMapData,
  });

export const getRequestQuery = (
  tabIndex: LoadAvailabilityTabNames,
  currentAvailability: FormatedLoadAvailability | undefined,
  sortDirection: LoadSortDirection | undefined,
  loadSort: LoadSearchSort | undefined
): LoadSearchRequest | undefined => {
  const query = getAvailabilityRequestByTabName(tabIndex, currentAvailability);
  return (
    query &&
    resetMetadata(
      withSorting(query, {
        direction: sortDirection || LoadSortDirection.Asc,
        field: loadSort?.field || LoadSortCategory.ORIGIN,
      })
    )
  );
};

export const getIsDensityMap = (pathname: string) => includes(pathname, 'density-map');

export const getPath = (isDensityMap: boolean) =>
  isDensityMap ? Routes.LOADS_LOAD_AVAILABILITY_MAP : Routes.LOADS_LOAD_AVAILABILITY;

export const getTabIndex = (results: string): LoadAvailabilityTabNames => {
  switch (results) {
    case AvailabilityType.inbound:
      return LoadAvailabilityTabNames.Inbound;
    case AvailabilityType.intrastate:
      return LoadAvailabilityTabNames.Intrastate;
    default:
      return LoadAvailabilityTabNames.Outbound;
  }
};

export const getAvailabilityTypeFromTabName = (tabName: LoadAvailabilityTabNames): AvailabilityType => {
  switch (tabName) {
    case LoadAvailabilityTabNames.Inbound:
      return AvailabilityType.inbound;
    case LoadAvailabilityTabNames.Intrastate:
      return AvailabilityType.intrastate;
    default:
      return AvailabilityType.outbound;
  }
};

export const getAvailabilityRequestByTabName = (
  tabName: LoadAvailabilityTabNames,
  currentAvailability: FormatedLoadAvailability | undefined
): LoadSearchRequest | undefined => {
  if (!currentAvailability) {
    return undefined;
  }
  switch (tabName) {
    case LoadAvailabilityTabNames.Inbound:
      return currentAvailability.inboundQuery;
    case LoadAvailabilityTabNames.Intrastate:
      return currentAvailability.intrastateQuery;
    default:
      return currentAvailability.outboundQuery;
  }
};

import React, { useEffect, useState } from 'react';

import { first, forEach, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import InputAdornment from '@material-ui/core/InputAdornment';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { useSelector } from '@/util/hooks';
import {
  DeleteTruckError,
  EquipmentType,
  InternalTruckStatus,
  MAX_TRUCK_LENGTH,
  MAX_TRUCK_NAME_LENGTH,
  MAX_TRUCK_WEIGHT,
  SaveTruckError,
  UserTruck,
} from '@common/model';
import { deleteTruck, editTruck, fetchUserTrucks, saveTruck } from '@common/redux/epic/UserTrucksEpic';
import { MultipurposeButton } from '@component/buttons';
import { TextField } from '@component/input';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelFooter, PanelHeader, PanelSize } from '@component/panel';
import { SETTINGS_PANEL_PADDING } from '@component/panels/settings/FormSettingsStyles';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupCancelButton, PopupDangerButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupEntity, PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { WeightLengthSliders } from '@component/slider/WeightAndLengthSliders';
import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import icons from '@util/iconsConstants';

import { RadioGroupTrailerTypePanel } from './MyTruckTrailerType';

interface Props {
  layer?: number;
  truckId?: string;
  onClose: () => void;
  onCloseAfterSubmit: () => void;
  onCloseTruckInUsePopup?: () => void;
  onSubmit?: (truck: Partial<UserTruck>) => void;
}

const Container = styled.div`
  padding-top: ${Spacing.ScreenSide}px;
  padding-right: ${Spacing.ScreenSide}px;
  padding-left: ${Spacing.ScreenSide}px;
`;

const SectionTitle = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
  padding-bottom: ${Spacing.ScreenSide}px;
`;

const initialTruck: Partial<UserTruck> = {
  defaultLength: MAX_TRUCK_LENGTH,
  defaultWeight: MAX_TRUCK_WEIGHT,
  displayName: '',
  equipmentType: undefined,
  status: InternalTruckStatus.Online,
};

export const AddTruck: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [truck, setTruck] = useState<Partial<UserTruck>>(initialTruck);

  const userTruckState = useSelector((state) => state.userTrucks);
  const {
    saved: { wasSavedSuccessfuly, isSaving, errorCode },
    trucks,
  } = userTruckState;

  useEffect(() => {
    const savedTruck = first(trucks);
    if (wasSavedSuccessfuly && !isSaving && props.onSubmit && savedTruck) {
      props.onSubmit(savedTruck);
    }
  }, [wasSavedSuccessfuly, isSaving]);

  const onSaveTruck = () => {
    dispatch(saveTruck(truck));
  };

  return (
    <TruckForm
      onClose={props.onClose}
      onCloseAfterSubmit={props.onCloseAfterSubmit}
      layer={props.layer ?? 1}
      title={t(T.common_postTruck_myTruck_newTruck)}
      onSave={onSaveTruck}
      isSaving={isSaving}
      wasSavedSuccessfuly={wasSavedSuccessfuly}
      truck={truck}
      setTruck={setTruck}
      buttonLabel={t(T.common_add)}
      successMessage={t(T.common_postTruck_myTruck_toast_savedSuccess)}
      errorMessage={t(T.common_postTruck_myTruck_toast_savedError)}
      httpError={errorCode}
      onCloseTruckInUsePopup={props.onCloseTruckInUsePopup}
    />
  );
};

export const EditTruck: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const userTruckState = useSelector((state) => state.userTrucks);
  const [truck, setTruck] = useState<Partial<UserTruck>>({
    id: props.truckId,
    defaultLength: MAX_TRUCK_LENGTH,
    defaultWeight: MAX_TRUCK_WEIGHT,
    displayName: '',
    equipmentType: undefined,
    status: InternalTruckStatus.Online,
  });
  const [prevTruck, setPrevTruck] = useState<Partial<UserTruck>>();

  useEffect(() => {
    if (userTruckState.trucks) {
      forEach(userTruckState.trucks, (newTruck) => {
        if (newTruck.id === props.truckId) {
          setTruck({
            ...truck,
            defaultLength: newTruck.defaultLength,
            defaultWeight: newTruck.defaultWeight,
            displayName: newTruck.displayName,
            equipmentType: newTruck.equipmentType,
          });
          setPrevTruck({
            ...truck,
            defaultLength: newTruck.defaultLength,
            defaultWeight: newTruck.defaultWeight,
            displayName: newTruck.displayName,
            equipmentType: newTruck.equipmentType,
          });
        }
      });
    }
  }, [userTruckState.trucks]);

  const onSaveTruck = () => {
    if (props.onSubmit) {
      props.onSubmit(truck);
    }
    dispatch(editTruck(truck));
  };

  const isFormValid = () => {
    if (isEqual(prevTruck, truck)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <TruckForm
      onClose={props.onClose}
      onCloseAfterSubmit={props.onCloseAfterSubmit}
      layer={props.layer ?? 1}
      title={t(T.common_postTruck_myTruck_editTruck)}
      onSave={onSaveTruck}
      isSaving={userTruckState.edited.isEditing}
      wasSavedSuccessfuly={userTruckState.edited.wasEditedSuccessfully}
      httpError={userTruckState.edited.errorCode}
      truck={truck}
      setTruck={setTruck}
      isDataValid={isFormValid}
      buttonLabel={t(T.common_update)}
      successMessage={t(T.common_postTruck_myTruck_toast_updateSuccess)}
      errorMessage={t(T.common_postTruck_myTruck_toast_updateError)}
      hasDelete={userTruckState.trucks.length > 1}
      onCloseTruckInUsePopup={props.onCloseTruckInUsePopup}
    />
  );
};

const setNameIfValid = (name: string, setName: (truck: string) => void) => {
  if (name.length <= MAX_TRUCK_NAME_LENGTH) {
    setName(name);
  }
};

interface TruckFormProps {
  onClose: () => void;
  onCloseAfterSubmit: () => void;
  onCloseTruckInUsePopup?: () => void;
  layer: number;
  title: string;
  onSave: () => void;
  isSaving: boolean;
  wasSavedSuccessfuly: boolean;
  truck: Partial<UserTruck>;
  setTruck: (truck: Partial<UserTruck>) => void;
  isDataValid?: () => boolean;
  buttonLabel: string;
  successMessage: string;
  errorMessage: string;
  hasDelete?: boolean;
  httpError?: number;
}

export const TruckForm: React.FC<TruckFormProps> = (props) => {
  const dispatch = useDispatch();
  const { openingPopup, closingPopup } = usePopup();
  const [isTrailerTypeOpen, setIstrailerTypeOpen] = useState(false);
  const [displayTrailerType, setDisplayTrailerType] = useState(t(T.common_select));
  const [areSlidersDisabled, setAreSlidersDisabled] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [isSubmited, setIsSubmitted] = useState(false);
  const deleteState = useSelector((state) => state.userTrucks.deleted);
  const deleteButtonHeader = onDeleteTextHeader(props.hasDelete);

  useEffect(() => {
    onChangeEquipmentType(
      setDisplayTrailerType,
      setAreSlidersDisabled,
      props.setTruck,
      areSlidersDisabled,
      props.truck
    );
  }, [props.truck.equipmentType]);

  useEffect(() => {
    isFormValid();
  }, [props.truck]);

  useEffect(() => {
    if (isDeleteClicked && !deleteState.isDeleting) {
      onActionCompleted(
        deleteState.wasDeletedSuccessfully,
        props.onClose,
        onShowTruckInUsePopUp,
        onShowSnackBar,
        t(T.common_postTruck_myTruck_toast_deleteSuccess),
        t(T.common_postTruck_myTruck_toast_deleteError),
        deleteState.httpError
      );
      dispatch(fetchUserTrucks());
      setIsDeleteClicked(false);
    }
  }, [deleteState.isDeleting, deleteState.wasDeletedSuccessfully]);

  useEffect(() => {
    const success = props.wasSavedSuccessfuly;
    if (!props.isSaving && isSubmited) {
      onActionCompleted(
        success,
        props.onCloseAfterSubmit,
        onShowDuplicateNamePopUp,
        onShowSnackBar,
        props.successMessage,
        props.errorMessage,
        props.httpError
      );
      setIsSubmitted(false);
    }
  }, [props.wasSavedSuccessfuly, props.isSaving]);

  const onShowSnackBar = (message: string) => {
    dispatch(showSnackbar({ message: message }));
  };

  const onShowDuplicateNamePopUp = () => {
    showDuplicateNamePopUp(openingPopup, closingPopup);
  };

  const onShowTruckInUsePopUp = () => {
    showTruckInUsePopUp(openingPopup, closingPopup, () => {
      onDelete(true);
      if (props.onCloseTruckInUsePopup) {
        props.onCloseTruckInUsePopup();
      }
    });
  };

  const onChangeLength = (value: number[]) => {
    props.setTruck({ ...props.truck, defaultLength: value[0] });
  };

  const onChangeWeight = (value: number[]) => {
    props.setTruck({ ...props.truck, defaultWeight: value[0] });
  };

  const showTrailerTypeOptions = () => {
    setIstrailerTypeOpen(true);
  };

  const onChangeName = (name: string) => {
    props.setTruck({ ...props.truck, displayName: name });
  };

  const onChangeTrailerType = (type: EquipmentType) => {
    props.setTruck({ ...props.truck, equipmentType: type });
  };

  const onSaveTruck = () => {
    setIsSubmitted(true);
    props.onSave();
  };

  const isFormValid = () => {
    isValid(setIsSubmitButtonDisabled, props.truck.displayName, props.truck.equipmentType, props.isDataValid);
  };

  const onDelete = (override: boolean = false) => {
    if (props.truck.id) {
      setIsDeleteClicked(true);
      dispatch(deleteTruck(props.truck.id, override));
    }
  };

  const showDeleteTruckPopUp = () => {
    openingPopup({
      body: (
        <BasePopup
          title={t(T.common_postTruck_popUps_deleteTruck_title)}
          buttons={[
            <PopupCancelButton testID={'cancel'} key={'cancel'} action={closingPopup} />,
            <PopupDangerButton
              testID={'delete'}
              key={'delete'}
              label={t(T.common_myDocuments_documentActionSheet_delete)}
              action={() => {
                onDelete();
                closingPopup();
              }}
            />,
          ]}
        >
          {t(T.common_postTruck_popUps_deleteTruck_body)}
        </BasePopup>
      ),
      hasCloseButton: true,
      width: PopupSizes.SMALL,
      isDismissible: true,
    });
  };

  return !isTrailerTypeOpen ? (
    <Panel
      id="truck_form"
      layer={props.layer}
      offset={1}
      size={PanelSize.small}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        style={{ padding: SETTINGS_PANEL_PADDING }}
        label={props.title}
        hasCloseButton={true}
        onClose={props.onClose}
        actionText={deleteButtonHeader}
        handleClick={() => showDeleteTruckPopUp()}
      />
      <Container>
        <SectionTitle id="post_truck_info">{t(T.common_postTruck_myTruck_truckSpacification)}</SectionTitle>
        <TextField
          id="name"
          value={props.truck.displayName}
          onChange={(e) => setNameIfValid(e.target.value, onChangeName)}
          label={`${t(T.common_postTruck_myTruck_truckName)}`}
          fullWidth={true}
          isWithoutMargin={true}
          required={true}
          style={{ paddingBottom: Spacing.ScreenSide }}
        />
        <TextField
          id="equipment"
          value={displayTrailerType}
          label={`${t(T.findLoads_findLoads_trailerType)}`}
          onClick={showTrailerTypeOptions}
          fullWidth={true}
          isWithoutMargin={true}
          required={true}
          endAdornmentIcon={
            <InputAdornment position="end">
              <img id="icon" src={icons.chevronRight} alt="arrow_right" />
            </InputAdornment>
          }
        />
      </Container>
      <WeightLengthSliders
        onChangeWeight={onChangeWeight}
        onChangeLength={onChangeLength}
        weight={props.truck.defaultWeight}
        length={props.truck.defaultLength}
        areSlidersDisabled={areSlidersDisabled}
        isPowerOnly={props.truck.equipmentType === EquipmentType.PowerOnly}
      />
      <PanelFooter>
        <MultipurposeButton
          id="save_profile"
          variant={'contained'}
          text={props.buttonLabel}
          color={'primary'}
          isLoading={props.isSaving}
          onClick={onSaveTruck}
          disabled={isSubmitButtonDisabled || props.isSaving}
        />
      </PanelFooter>
    </Panel>
  ) : (
    <RadioGroupTrailerTypePanel
      layer={props.layer}
      offset={1}
      backRedirect={() => setIstrailerTypeOpen(false)}
      updateEquipment={onChangeTrailerType}
      initialEquipmentTypes={props.truck.equipmentType}
    />
  );
};

const isValid = (
  setIsDisabled: (isDisabled: boolean) => void,
  name?: string,
  equipmentType?: EquipmentType,
  validate?: () => boolean
) => {
  let isDataValid = true;
  if (validate) {
    isDataValid = validate();
  }
  if (name && equipmentType && isDataValid) {
    setIsDisabled(false);
  } else {
    setIsDisabled(true);
  }
};

const onChangeEquipmentType = (
  setDisplayTrailerType: (type: EquipmentType) => void,
  setAreSlidersDisabled: (areSlidersDisabled: boolean) => void,
  setTruck: (truck: Partial<UserTruck>) => void,
  areSlidersDisabled: boolean,
  truck: Partial<UserTruck>
) => {
  if (truck.equipmentType) {
    setDisplayTrailerType(truck.equipmentType);
  }
  if (truck.equipmentType === EquipmentType.PowerOnly) {
    setAreSlidersDisabled(true);
    setTruck({ ...truck, defaultWeight: MAX_TRUCK_WEIGHT, defaultLength: MAX_TRUCK_LENGTH });
  } else if (areSlidersDisabled) {
    setAreSlidersDisabled(false);
  }
};

const showTruckInUsePopUp = (
  openPopup: (popup: PopupEntity) => void,
  dismissAction: () => void,
  clearAction: () => void
) =>
  openPopup({
    body: (
      <BasePopup
        title={t(T.common_postTruck_popUps_truckInUseDelete_title)}
        buttons={[
          <PopupCancelButton testID={'cancel'} key={'cancel'} action={dismissAction} />,
          <PopupDangerButton
            testID={'clear'}
            key={'clear'}
            label={t(T.common_myDocuments_documentActionSheet_delete)}
            action={() => {
              clearAction();
              dismissAction();
            }}
          />,
        ]}
      >
        {t(T.common_postTruck_popUps_truckInUseDelete_body)}
      </BasePopup>
    ),
    hasCloseButton: true,
    width: PopupSizes.SMALL,
    isDismissible: true,
  });

const showDuplicateNamePopUp = (openPopup: (popup: PopupEntity) => void, dismissAction: () => void) =>
  openPopup({
    body: (
      <BasePopup
        title={t(T.common_postTruck_popUps_duplicateName_title)}
        buttons={[
          <PopupPrimaryButton
            testID={'ok'}
            key={'ok'}
            label={t(T.common_ok)}
            action={() => {
              dismissAction();
            }}
          />,
        ]}
      >
        {t(T.common_postTruck_popUps_duplicateName_body)}
      </BasePopup>
    ),
    hasCloseButton: true,
    width: PopupSizes.SMALL,
    isDismissible: true,
  });

const onDeleteTextHeader = (hasDelete?: boolean) =>
  hasDelete ? t(T.common_myDocuments_documentActionSheet_delete) : undefined;

const onActionCompleted = (
  success: boolean,
  onClose: () => void,
  onError: () => void,
  onShowSnackBar: (message: string) => void,
  successMessage: string,
  errorMessage: string,
  error?: number
) => {
  if (success) {
    onShowSnackBar(successMessage);
    onClose();
  } else if (error === SaveTruckError.DuplicateName || error === DeleteTruckError.TruckInUse) {
    onError();
  } else if (!success) {
    onShowSnackBar(errorMessage);
    onClose();
  }
};

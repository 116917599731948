import { Dispatch } from 'react';

import moment from 'moment';
import { Action } from 'redux';

import { fetchTrucksLkl } from '@/reduxStore/epic/TruckEpic';
import {
  convertEquipmentTypeToExpediteAll,
  convertToOriginLocation,
  createDefaultLoadSearchRequest,
  createLoadSearchMetadata,
  LTLEquipmentTypes,
} from '@common/helper';
import {
  Address,
  ANYWHERE_LOCATION,
  EquipmentType,
  ExpediteAllLocation,
  LoadSearchRequest,
  RateCheckEquipmentTypes,
  RateCheckLocation,
  ThirdPartyLaneRateRequest,
  ThirdPartyLaneRateSource,
} from '@common/model';
import {
  fetchAnywhereBackhaulsLoadsCount,
  fetchAnywherePickupLoadsCount,
  fetchBackhaulsExpediteAllRate,
  fetchBackhaulsLoadsCount,
  fetchBackhaulsRateCheck,
  fetchExpediteAllRate,
  fetchPickupLoadsCount,
  MarketRatesEquipmentTypes,
} from '@common/redux/epic/MarketRatesEpic';
import { fetchRateCheck } from '@common/redux/epic/RateCheckEpic';
import { DEFAULT_RADIUS_OPTION } from '@page/truckLocator/locateTruck/LocateTruckEncoder';

export const fetchLoadCountsByRoute = (
  dispatch: Dispatch<Action>,
  originLocation: RateCheckLocation,
  destinationLocation: RateCheckLocation,
  equipmentType: RateCheckEquipmentTypes
) => {
  const searchRequest = getSearchData(equipmentType, originLocation, destinationLocation);
  dispatch(fetchPickupLoadsCount(searchRequest));
  dispatch(fetchAnywherePickupLoadsCount({ ...searchRequest, destination: ANYWHERE_LOCATION }));
  dispatch(
    fetchBackhaulsLoadsCount({
      ...searchRequest,
      destination: searchRequest.origin,
      origin: convertToOriginLocation(destinationLocation),
      pickupDates: getDaysForBackhauls(),
    })
  );
  dispatch(
    fetchAnywhereBackhaulsLoadsCount({
      ...searchRequest,
      origin: convertToOriginLocation(destinationLocation),
      destination: ANYWHERE_LOCATION,
      pickupDates: getDaysForBackhauls(),
    })
  );
};

export const fetchRateCheckInfo = (
  dispatch: Dispatch<Action>,
  originAddress: Address,
  destinationAddress: Address,
  equipmentType: RateCheckEquipmentTypes
) => {
  dispatch(fetchRateCheck({ from: originAddress, to: destinationAddress, equipment: equipmentType }));
  dispatch(fetchBackhaulsRateCheck({ from: destinationAddress, to: originAddress, equipment: equipmentType }));
  dispatch(
    fetchTrucksLkl({
      location: originAddress,
      equipmentTypes: equipmentType,
      radius: DEFAULT_RADIUS_OPTION,
    })
  );
};

export const getThirdPartyLaneRatesRequest = (
  originLocation: RateCheckLocation,
  destinationLocation: RateCheckLocation,
  equipmentType: EquipmentType,
  isCargoChiefRateAvailable: boolean
): ThirdPartyLaneRateRequest => {
  const origin =
    'zipCode' in originLocation
      ? {
          city: originLocation.city,
          zipCode: originLocation.zipCode,
          state: originLocation.states[0],
        }
      : {
          city: originLocation.city,
          state: originLocation.states ? originLocation.states[0] : undefined,
        };

  const destination =
    'zipCode' in destinationLocation
      ? {
          city: destinationLocation.city,
          zipCode: destinationLocation.zipCode,
          state: destinationLocation.states[0],
        }
      : {
          city: destinationLocation.city,
          state: destinationLocation.states ? destinationLocation.states[0] : undefined,
        };

  return {
    sources: isCargoChiefRateAvailable ? [ThirdPartyLaneRateSource.All] : [ThirdPartyLaneRateSource.Greenscreens],
    equipment: equipmentType,
    from: origin,
    to: destination,
  };
};

export const getDaysForBackhauls = (fomat?: string) => [
  moment().format(fomat ?? 'YYYY-MM-DD'),
  moment()
    .add(1, 'days')
    .format(fomat ?? 'YYYY-MM-DD'),
  moment()
    .add(2, 'days')
    .format(fomat ?? 'YYYY-MM-DD'),
];

export const getSearchData = (
  equipmentType: MarketRatesEquipmentTypes,
  originLocation?: RateCheckLocation,
  destinationLocation?: RateCheckLocation
): LoadSearchRequest => {
  const newSearchRequest = createDefaultLoadSearchRequest();
  if (originLocation && destinationLocation) {
    return {
      ...newSearchRequest,
      origin: convertToOriginLocation(originLocation),
      destination: destinationLocation,
      pickupDates: [moment().format('YYYY-MM-DD')],
      equipmentTypes: equipmentType ? [equipmentType as EquipmentType] : [],
      metadata: createLoadSearchMetadata(),
    };
  }
  return newSearchRequest;
};

export const convertAddressToExpediteAllLocation = (address: Address | undefined): ExpediteAllLocation | undefined => {
  if (address && address.geolocation) {
    const { latitude, longitude } = address.geolocation;
    return {
      city: address.city,
      state: address.state,
      country: address.country,
      latitude: latitude,
      longitude: longitude,
    };
  }
  return undefined;
};

export const fetchExpediteAllRateInfo = (
  dispatch: Dispatch<Action>,
  originAddress: Address,
  destinationAddress: Address,
  equipmentType: LTLEquipmentTypes
) => {
  const origin = convertAddressToExpediteAllLocation(originAddress);
  const destination = convertAddressToExpediteAllLocation(destinationAddress);
  const equipment = convertEquipmentTypeToExpediteAll(equipmentType);

  if (origin && destination && equipment) {
    dispatch(fetchExpediteAllRate({ origin: origin, destination: destination, truckType: equipment }));
    dispatch(fetchBackhaulsExpediteAllRate({ origin: destination, destination: origin, truckType: equipment }));
  }
};

import React, { Component, CSSProperties } from 'react';

import { isEqual } from 'lodash';

import { StyledTextInput } from '@component/textInput/TextInputStyle';

interface ComponentProps {
  value?: string;
  label: string;
  error?: string;
  isErrorWithText?: boolean;
  disabled?: boolean;
  helperText?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  style?: CSSProperties;
}

interface ComponentState {
  value?: string;
}

export class NumberValidationInput extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    const { value } = this.props;
    this.state = {
      value: value ? value : '',
    };
  }

  componentDidUpdate(prevProps: Readonly<ComponentProps>): void {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState({ value: this.props.value });
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = event.target.value;
    if (!isNaN(+value.replace(/\s/g, ''))) {
      if (this.props.onChange) {
        this.props.onChange(value.replace(/\s/g, ''));
      } else {
        this.setState({
          value: value.replace(/\s/g, ''),
        });
      }
    }
  };

  render() {
    const { label, error, disabled, helperText, placeholder, onFocus, isErrorWithText, onBlur, style } = this.props;
    const { value } = this.state;
    return (
      <StyledTextInput
        id="text_input"
        label={label}
        error={!!error}
        margin="dense"
        value={value}
        onChange={this.handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        variant="filled"
        disabled={disabled}
        helperText={error ? (isErrorWithText ? error : '') : helperText}
        placeholder={placeholder}
        inputProps={{ id: 'value' }}
        autoComplete={'off'}
        inputMode="numeric"
        type="numeric"
        style={style}
      />
    );
  }
}

import { useCallback, useEffect, useMemo, useState } from 'react';

import { filter, find, findIndex, isEmpty, isNil, last, map, max, maxBy, reduce, values } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { monthNames, monthNamesShort } from '@common/formatter';
import { getMonthIndex } from '@common/helper/DateHelper';
import { RateCheckDataPoint, RateCheckRequest } from '@common/model';
import {
  fetchLoadRateCheckHistory,
  fetchRateCheckHistorical,
  fetchRateCheckHistory,
  RateCheckHistoryInformation,
  RateCheckState,
} from '@common/redux/epic/RateCheckEpic';

import { formatPrice } from './NumberHelper';

export interface BaseRateCheckHistoryGraphComponentProps {
  id: string;
  loadID?: string;
  loadDetailsArchivingFlowID?: string;
}
export interface RateCheckHistoryGraphComponentProps extends BaseRateCheckHistoryGraphComponentProps {
  setMin: (rate: number | undefined) => void;
  setMax: (rate: number | undefined) => void;
  setAverage: (rate: number) => void;
  resetRates: () => void;
  setMonth?: (month: number) => void;
}

export type ChartPaddingBlockProps = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

type RedLineYCoordinatesProps = {
  y1?: number;
  y2?: number;
};

export const useRateCheckHistory = (
  id: string,
  rateCheckRequest: RateCheckRequest | undefined,
  loadID: string | undefined
) => {
  const rateCheckHistory = useSelector<{ rateCheck: RateCheckState }, RateCheckHistoryInformation | undefined>(
    (state) => state.rateCheck.history[id]
  );
  const { rateCheck, isLoading: isLoadingRateCheck } = useSelector<{ rateCheck: RateCheckState }, RateCheckState>(
    (state) => state.rateCheck
  );
  const isRateCheckReady = rateCheck && !isLoadingRateCheck;

  const dispatch = useDispatch();

  useEffect(() => {
    if (rateCheckHistory?.isSuccess || rateCheckHistory?.isLoading || !isRateCheckReady) {
      return;
    }
    if (loadID) {
      dispatch(fetchLoadRateCheckHistory(loadID, undefined));
    } else if (rateCheckRequest && isRateCheckReady) {
      dispatch(fetchRateCheckHistory(rateCheckRequest));
    }
  }, [loadID, rateCheckRequest, isLoadingRateCheck, rateCheck]);

  return {
    max: rateCheckHistory?.data?.maxRatePerMile,
    average: rateCheckHistory?.data?.averageRatePerMileData,
    high: rateCheckHistory?.data?.ratePerMileMaxData,
    low: rateCheckHistory?.data?.ratePerMileMinData,
    flatMax: rateCheckHistory?.data?.maxFlatRate,
    flatAverage: rateCheckHistory?.data?.averageFlatRateForMileageData,
    flatHigh: rateCheckHistory?.data?.maxFlatRateForMileageData,
    flatLow: rateCheckHistory?.data?.minFlatRateForMileageData,
    isLoading: rateCheckHistory?.isLoading,
  };
};

export const useRateCheckHistoryBroker = (id: string, rateCheckRequest: RateCheckRequest | undefined) => {
  // The following values are used to render the main graph:
  const { flatHigh, flatAverage, flatMax, flatLow, isLoading } = useRateCheckHistory(id, rateCheckRequest, undefined);
  // The following value is used to render the widget graph:
  const { rateCheckAverage, isLoadingRateCheckAverage } = useSelector<{ rateCheck: RateCheckState }, RateCheckState>(
    (state) => state.rateCheck
  );
  // To keep y-axis consistency across both graphs,
  // we set the upper bound by choosing the maximum between flatMax and rateCheckAverage.maxTotal.
  const domain = useFlatRateDomain(flatAverage, max([flatMax, rateCheckAverage?.maxTotal]) ?? flatMax);
  const tickValues = useMemo(() => map(flatAverage, (data) => data.date), [flatAverage]);

  return {
    domain: domain,
    tickValues: tickValues,
    boxPlotdata: [...(flatHigh ?? []), ...(flatAverage ?? []), ...(flatLow ?? [])],
    flatAverage: flatAverage,
    isLoading: isLoading,
    isLoadingWidget: isLoadingRateCheckAverage,
    rateCheckAverage: rateCheckAverage,
  };
};

export const getTickLength = (average: RateCheckDataPoint[] | undefined) =>
  average && average[0] && average[1] && average[0].date - average[1].date;

/** This function works with both Unix Epoch format and month format from 0 to 11 */
export const formatMonthTicks = (tick: number, returnLongNames = false) => {
  if (tick <= 12) {
    return returnLongNames ? monthNames[tick % 12] : monthNamesShort[tick % 12];
  }
  return returnLongNames ? monthNames[getMonthIndex(tick)] : monthNamesShort[getMonthIndex(tick)];
};

export const formatPriceTicks = (tick: number) => {
  return `$${formatPrice(tick)}`;
};

export const defaultAnimation = { duration: 500 };

export const useDomain = (
  average: RateCheckDataPoint[] | undefined,
  max: number | undefined,
  isWithFirstTickOffset = true
) => {
  return useMemo(() => {
    const unit = getTickLength(average); // determine tick length
    const firstTickOffset = isWithFirstTickOffset && unit ? 0.6 * unit : 0;
    const startDate = average && average[0].date + firstTickOffset; // offset the first tick
    const lastDataPoint = last(average);
    const endDate = lastDataPoint && lastDataPoint.date;
    return { y: [0, (max || 0) + 2], x: unit && startDate && endDate ? [startDate, endDate] : [1, 10] }; // additional offset on y-axis to display custom legend
  }, [max, average]) as { x: [number, number]; y?: [number, number] };
};

export const useFlatRateDomain = (average: RateCheckDataPoint[] | undefined, max: number | undefined) => {
  return useMemo(() => {
    const startDate = average && average[0].date;
    const lastDataPoint = last(average);
    const endDate = lastDataPoint && lastDataPoint.date;
    return { y: [0, (max || 0) + 1000], x: startDate && endDate ? [startDate, endDate] : [1, 10] }; // additional offset on y-axis to display custom legend
  }, [max, average]) as { x: [number, number]; y?: [number, number] };
};

export const useOnActivated = (
  average: RateCheckDataPoint[] | undefined,
  props: RateCheckHistoryGraphComponentProps,
  low: RateCheckDataPoint[] | undefined,
  high: RateCheckDataPoint[] | undefined
) => {
  return useCallback(
    (points: Array<{ _x: number }>) => {
      const index = findIndex(average, (dataPoint) => points && points[0] && points[0]._x === dataPoint.date);
      if (isNil(index)) {
        return;
      }
      const averageRate = average?.[index]?.rate;
      const lowRate = low?.[index]?.rate;
      const highRate = high?.[index]?.rate;
      /** This value is using Unix Epoch format */
      const averageRateMonth = average?.[index]?.date;
      if (averageRate) {
        props.setAverage(averageRate);
      }
      if (lowRate) {
        props.setMin(lowRate !== averageRate ? lowRate : undefined);
      }
      if (highRate) {
        props.setMax(highRate !== averageRate ? highRate : undefined);
      }
      if (averageRateMonth && props.setMonth) {
        props.setMonth(averageRateMonth);
      }
    },
    [average, low, high]
  );
};

export interface IndicatorLineProps {
  x?: number;
  height?: number;
  setLinePosition: (rate: number) => void;
}

export const chartPadding = { top: 16, bottom: 32, left: 50, right: 16 };
export const boxPlotChartPadding = { top: 5, bottom: 40, left: 55, right: 10 };

export const VORONOI_CONTAINER_PROPS: { voronoiDimension: 'x'; voronoiBlacklist: string[]; labels: () => string } = {
  voronoiDimension: 'x',
  voronoiBlacklist: ['parent'],
  labels: () => ' ', //@FIXME: remove after https://github.com/FormidableLabs/victory/issues/1805#issuecomment-802179487 is resolved
};

export const MONTH_TICK_STYLE = {
  tickLabels: { fontSize: 10, padding: 15, angle: -45, verticalAnchor: 'middle' },
};

export const EXPANSION_LEVEL_TICK_STYLE = {
  tickLabels: { fontSize: 10, fontWeight: 'bold', padding: 15, verticalAnchor: 'middle' },
};

export const PRICE_TICK_STYLE = {
  tickLabels: { fontSize: 12 },
};

// since we are not using the same padding for all graph charts we can use this hook to get information about vertical red line Y coordinates
// chart padding is important because it provides offset to render graph line group svg
export const useBaseRedLineYCoordinates = (
  height: number | undefined,
  chartPadding: number | ChartPaddingBlockProps
): RedLineYCoordinatesProps => ({
  y1: typeof chartPadding === 'number' ? chartPadding : (chartPadding.top ?? 0),
  y2: typeof chartPadding === 'number' ? (height ?? 0) - chartPadding : (height ?? 0) - (chartPadding.bottom ?? 0),
});

/**
 * Historical rates
 * */

export interface RateCheckDataPointWithColor extends RateCheckDataPoint {
  color: string;
  ratePerMile: number;
}

export interface HistoricalRatesByYear {
  [key: string]: RateCheckDataPointWithColor;
}

export interface HistoricalDatesDataWithEpoch {
  epoch: number;
  rateData: HistoricalRatesByYear;
}

export interface HistoricalRatesData {
  historicalRatesData?: HistoricalDatesDataWithEpoch;
  setSelectedMonthIndex: (index: number) => void;
}

export const useHistoricalRates = (id: string, yearsColors: string[]): HistoricalRatesData => {
  const rateCheckHistorical = useSelector<{ rateCheck: RateCheckState }, RateCheckHistoryInformation | undefined>(
    (state) => state.rateCheck.historical[id]
  );
  const dataFlatRate = rateCheckHistorical?.data?.averageFlatRateForMileageData;
  const dataPerMileRate = rateCheckHistorical?.data?.averageRatePerMileData;
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(new Date().getMonth());
  const historicalRatesData = useMemo(() => {
    return breakDownHistoricalRatesByYears(dataFlatRate, dataPerMileRate, selectedMonthIndex, yearsColors);
  }, [selectedMonthIndex, id, dataFlatRate, dataPerMileRate]);

  const setSelectedMonthIndexCallback = useCallback((index) => setSelectedMonthIndex(index), [setSelectedMonthIndex]);

  return {
    historicalRatesData: historicalRatesData,
    setSelectedMonthIndex: setSelectedMonthIndexCallback,
  };
};

export const useRateCheckHistorical = (id: string, rateCheckRequest: RateCheckRequest | undefined) => {
  const rateCheckHistorical = useSelector<{ rateCheck: RateCheckState }, RateCheckHistoryInformation | undefined>(
    (state) => state.rateCheck.historical[id]
  );
  const { rateCheck, isLoading: isLoadingRateCheck } = useSelector<{ rateCheck: RateCheckState }, RateCheckState>(
    (state) => state.rateCheck
  );
  const isRateCheckReady = rateCheck && !isLoadingRateCheck;

  const dispatch = useDispatch();

  useEffect(() => {
    if (rateCheckHistorical?.isSuccess || rateCheckHistorical?.isLoading || !isRateCheckReady) {
      return;
    }
    if (rateCheckRequest && isRateCheckReady) {
      dispatch(fetchRateCheckHistorical(rateCheckRequest));
    }
  }, [rateCheckRequest, isLoadingRateCheck, rateCheck]);

  return {
    max: rateCheckHistorical?.data?.maxRatePerMile,
    average: rateCheckHistorical?.data?.averageRatePerMileData,
    high: rateCheckHistorical?.data?.ratePerMileMaxData,
    low: rateCheckHistorical?.data?.ratePerMileMinData,
    flatMax: rateCheckHistorical?.data?.maxFlatRate,
    flatAverage: rateCheckHistorical?.data?.averageFlatRateForMileageData,
    flatHigh: rateCheckHistorical?.data?.maxFlatRateForMileageData,
    flatLow: rateCheckHistorical?.data?.minFlatRateForMileageData,
    isLoading: rateCheckHistorical?.isLoading,
  };
};

export const useActivatedHistorical = (setSelectedMonthIndex: (index: number) => void) => {
  return useCallback(
    (points: { _x: number }[]) => {
      const monthIndex = points[0] && points[0]._x;
      setSelectedMonthIndex(monthIndex);
    },
    [setSelectedMonthIndex]
  );
};

const isMonthTheSame = (epoch: number, monthIndex: number) => monthIndex === getMonthIndex(epoch);

const getIsDataForCurrentYearPopulated = (
  epoch: number,
  selectedMonthIndex: number,
  flatRatesForMonth: RateCheckDataPoint[] | undefined
) => {
  const isCurrentMonthOrHigher = getMonthIndex(epoch) <= selectedMonthIndex;
  if (!isCurrentMonthOrHigher || isEmpty(flatRatesForMonth)) {
    return true;
  }
  return find(flatRatesForMonth, (rateData) => moment(rateData.date).isSame(epoch, 'year'));
};

const breakDownHistoricalRatesByYears = (
  rateCheckDataPoints: RateCheckDataPoint[] | undefined,
  rateCheckDataPerMilePoints: RateCheckDataPoint[] | undefined,
  monthIndex: number,
  yearsColors: string[]
) => {
  const flatRatesForMonth = filter(rateCheckDataPoints, (rateDataPoint) =>
    isMonthTheSame(rateDataPoint.date, monthIndex)
  );
  const perMileRatesForMonth = filter(rateCheckDataPerMilePoints, (rateDataPoint) =>
    isMonthTheSame(rateDataPoint.date, monthIndex)
  );
  // if we are missing information about month in current year
  const currentMonth = moment().startOf('month').utc().valueOf();
  const isDataForCurrentYearPopulated = getIsDataForCurrentYearPopulated(currentMonth, monthIndex, flatRatesForMonth);
  if (!isDataForCurrentYearPopulated) {
    flatRatesForMonth.push({ rate: 0, date: currentMonth });
    perMileRatesForMonth.push({ rate: 0, date: currentMonth });
  }
  /***
   * breaking data by each year, output format example:
   {
      2019: {rate: 2825.0972153055445, ratePerMile: 1.36804540655484, date: 10, color: '#F1C473'},
      2020: {rate: 3698.802666280325, ratePerMile: 1.79113482181898, date: 10, color: '#9DB9C7'},
      ...
   }
   */
  const rateDataByYears = reduce(
    flatRatesForMonth,
    (acc, item) => {
      const year = moment(item.date).year();
      const currentYearIndex = values(acc).length;
      acc[year] = {
        rate: item.rate,
        ratePerMile: find(perMileRatesForMonth, (perMileItem) => perMileItem.date === item.date)?.rate,
        date: getMonthIndex(item.date),
        color: yearsColors[currentYearIndex],
      };
      return acc;
    },
    {} as { [key: number]: any }
  );
  // This value is using Unix Epoch format
  const averageRateEpoch = flatRatesForMonth?.[0]?.date;
  return { rateData: rateDataByYears, epoch: averageRateEpoch };
};

/***
 * breaking data by each year required for graph, output format example:
 {
    2019: [{rate: 2825.0972153055445, date: 10, color: '#F1C473'}, {rate: 3212.0972153055445, date: 11, color: '#F1C473'},...],
    2020: [{rate: 3698.802666280325, date: 10, color: '#9DB9C7'}, {rate: 2891.802666280325, date: 11, color: '#9DB9C7'},...],
    ...
 }
 */
export const useHistoricalRatesGraphData = (
  rateCheckDataPoints: RateCheckDataPoint[] | undefined,
  yearsColors: string[]
) =>
  useMemo(
    () =>
      values(
        reduce(
          rateCheckDataPoints,
          (acc, item) => {
            const year = moment(item.date).year();
            const currentYearIndex = values(acc).length;
            const rateDataItem = {
              rate: item.rate,
              date: getMonthIndex(item.date),
              color: yearsColors[currentYearIndex],
            };
            if (acc[year]) {
              acc[year] = [...acc[year], rateDataItem];
            } else {
              acc[year] = [rateDataItem];
            }
            return acc;
          },
          {} as { [key: number]: any }
        )
      ),
    [rateCheckDataPoints]
  );

export const useHistoricalRatesDomain = (rateCheckDataPoints: RateCheckDataPoint[] | undefined) => {
  return useMemo(() => {
    const maxRate = maxBy(rateCheckDataPoints, 'rate');
    return { y: [0, (maxRate?.rate ?? 0) + 1000], x: [0, 11] }; // additional offset on y-axis
  }, [rateCheckDataPoints]) as { x: [number, number]; y?: [number, number] };
};

import { useEffect } from 'react';

import { isEmpty, some, uniq } from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { convertBidDetailsToBid, convertBidDetailsToBidSummary } from '@common/helper/BidsHelper';
import { Bid, BidAction, BidDetails, BidStatus, BidSummary, LoadWithDataForBiddingOnly } from '@common/model';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { FetchStatus, NormalizedListState, updateIdsInNormalizedStates } from '@common/redux/NormalizationHelper';

import { decrementViewingBidsCount, incrementViewingBidsCount } from './BidsEpic';

export interface BidsState {
  viewingBidsCount: number;
  summary: {
    carrierUnreadBids: number;
    brokerUnreadBids: number;
  };
  bidSummaries: {
    listAll: NormalizedListState & {
      selectedFilters?: BidStatus[];
    };
    listPerLoad: NormalizedListState & {
      loadId: string;
    };
    isLoadingSingleBidSummary: boolean;
    summaries: Map<string, BidSummary>;
  };
  biddingSummary: {
    listAll: NormalizedListState & {
      loadId: string;
    };
    summaries: Map<string, BidSummary>;
    fetchingBids: Map<string, FetchStatus>;
  };
  loadBids: {
    listAll: NormalizedListState & {
      loadId: string;
      carrierId: string;
    };
    entities: Map<string, Bid>;
    fetchingBids: Map<string, FetchStatus>;
  };
  bidLoadDetails: Record<
    LoadSearchType,
    // @FIXME once load normalization is implemented,
    // this data will be available from there
    {
      loadId: string;
      isLoading: boolean;
      load?: LoadWithDataForBiddingOnly;
      isOnline?: boolean;
    }
  >;
  bidActions: {
    actionsInProgress: Map<string, BidAction>;
    actionsFailed: Map<string, BidAction>;
  };
  postBid: {
    isPosting: boolean;
    didPostSuccessfully?: boolean;
    postedBidId?: string;
  };
  bidsCarrierInfo: {
    isLoading?: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    companyName?: string;
    phone?: string;
    phoneExtension?: string;
    docketNumber?: string;
    usdotNumber?: string;
    isCarrierIdentityVerified?: boolean;
  };
  markAllBidsAsViewed: {
    isLoading: boolean;
    wereAllMarkedAsViewed: boolean;
  };
}

const isNewerBid = (newBid: Bid, existingBid: Bid) => {
  const existingBidDate = existingBid.mostRecentActivity;
  const newBidDate = newBid.mostRecentActivity;
  if (newBidDate && existingBidDate) {
    return moment(newBidDate).diff(moment(existingBidDate)) >= 0;
  }
  return false;
};

const findBidSummaryForSameLoad = (state: BidsState, bidDetails: BidDetails) => {
  for (const bidSummary of state.bidSummaries.summaries.values()) {
    if (
      bidSummary.load?.id &&
      bidSummary.load?.id === bidDetails.load?.id &&
      bidSummary.otherParty?.contactId &&
      bidSummary.otherParty?.contactId === bidDetails.otherParty?.contactId
    ) {
      return state.bidSummaries.summaries.get(bidSummary.bidId);
    }
  }
  return undefined;
};

const getPrecedingBidSummary = (state: BidsState, bidDetails: BidDetails) => {
  const previousBidId = bidDetails.previousBidId;
  let existingBidSummary = previousBidId ? state.bidSummaries.summaries.get(previousBidId) : undefined;
  if (!existingBidSummary) {
    // it's possible our data has become out of sync with API.
    // as a result, we cannot rely on previousBidId, since our id may be older.
    existingBidSummary = findBidSummaryForSameLoad(state, bidDetails);
  }
  return existingBidSummary;
};

/**
 * Process an UPDATE to a Bid.
 */
export const processBidUpdate = (state: BidsState, bidDetails: BidDetails) => {
  if (state.loadBids.entities.has(bidDetails.bidId)) {
    state.loadBids.entities.set(bidDetails.bidId, convertBidDetailsToBid(bidDetails));
  }

  /**
   * For bid updates from API, we have to handle 3 scenarios when it comes to Bid Summaries:
   * Matching Bid Summary:
   *   We have an existing Bid Summary for the Bid in our list already
   * Old Matching Bid Summary (Sync Issue):
   *   We don't have an existing Bid Summary in our list, but we have an outdated version
   * No Matching Bid Summary (Effectively New Bid):
   *   We don't have this Bid Summary in our list, i.e. user has not paginated to it yet
   */
  const existingBidSummary = state.bidSummaries.summaries.get(bidDetails.bidId);

  if (existingBidSummary) {
    if (isNewerBid(bidDetails, existingBidSummary)) {
      updateIdsInBidSummaryLists(state, existingBidSummary.bidId, bidDetails.bidId);
      updateBidSummaryFromBidDetails(state, existingBidSummary, bidDetails);
    }
    // else: if !isNewerBid, we already have a newer copy, so do nothing
  } else {
    const possiblyOutdatedBidSummary = findBidSummaryForSameLoad(state, bidDetails);

    if (possiblyOutdatedBidSummary) {
      if (bidDetails.isMostRecentOffer) {
        // replace our old copy with new details
        updateIdsInBidSummaryLists(state, possiblyOutdatedBidSummary.bidId, bidDetails.bidId);
        updateBidSummaryFromBidDetails(state, possiblyOutdatedBidSummary, bidDetails);
      }
      // else: if !isMostRecentOffer, we already have a newer copy, do nothing
    } else {
      processNewBid(state, bidDetails);
    }
  }
};

/**
 * Process a NEW Bid, after API has notified us of the new bid via signalR.
 */
export const processNewBid = (state: BidsState, bidDetails: BidDetails) => {
  const precedingBidSummary = getPrecedingBidSummary(state, bidDetails);
  if (precedingBidSummary) {
    updateIdsInBidSummaryLists(state, precedingBidSummary.bidId, bidDetails.bidId);
    updateBidSummaryFromBidDetails(state, precedingBidSummary, bidDetails);
  } else {
    addBidSummaryFromBidDetails(state, bidDetails);
    const bidSummariesListAll = state.bidSummaries.listAll;
    if (!isEmpty(bidSummariesListAll.ids) && !some(bidSummariesListAll.ids, (id) => id === bidDetails.bidId)) {
      state.bidSummaries.listAll.ids = [bidDetails.bidId, ...bidSummariesListAll.ids];
    }
    const biddingSummariesListAll = state.biddingSummary.listAll;
    const biddingSummariesListPerLoad = state.biddingSummary.listAll.loadId;
    if (
      !isEmpty(biddingSummariesListAll.ids) &&
      !some(biddingSummariesListAll, (id) => id === bidDetails.bidId) &&
      biddingSummariesListPerLoad === bidDetails.load?.id
    ) {
      state.biddingSummary.listAll.ids = [bidDetails.bidId, ...biddingSummariesListAll.ids];
    }
    const bidSummariesListPerLoad = state.bidSummaries.listPerLoad;
    if (
      bidSummariesListPerLoad.loadId === bidDetails.load?.id &&
      !isEmpty(bidSummariesListPerLoad.ids) &&
      !some(bidSummariesListPerLoad.ids, (id) => id === bidDetails.bidId)
    ) {
      state.bidSummaries.listPerLoad.ids = [bidDetails.bidId, ...bidSummariesListPerLoad.ids];
    }
  }
  if (
    state.loadBids.listAll.loadId === bidDetails.load?.id &&
    !isEmpty(state.loadBids.listAll.ids) &&
    !some(state.loadBids.listAll.ids, (id) => id === bidDetails.bidId)
  ) {
    state.loadBids.entities.set(bidDetails.bidId, convertBidDetailsToBid(bidDetails));
    state.loadBids.listAll.ids = [bidDetails.bidId, ...state.loadBids.listAll.ids];
  }
};

const updateIdsInBidSummaryLists = (state: BidsState, oldBidId: string, newBidId: string) => {
  updateIdsInNormalizedStates(
    oldBidId,
    newBidId,
    state.bidSummaries.listAll,
    state.biddingSummary.listAll,
    state.bidSummaries.listPerLoad
  );
};

export const updateBidSummaryFromBidDetails = (
  state: BidsState,
  existingBidSummary: BidSummary,
  updatedBid: BidDetails,
  forcedIsBidSummaryViewed?: boolean
) => {
  if (existingBidSummary.bidId !== updatedBid.bidId) {
    state.bidSummaries.summaries.delete(existingBidSummary.bidId);
  }
  state.bidSummaries.summaries.set(updatedBid.bidId, {
    ...existingBidSummary,
    ...convertBidDetailsToBid(updatedBid),
    isViewed: forcedIsBidSummaryViewed !== undefined ? forcedIsBidSummaryViewed : updatedBid.isViewed,
  });
  state.biddingSummary.summaries.set(updatedBid.bidId, {
    ...existingBidSummary,
    ...convertBidDetailsToBid(updatedBid),
    isViewed: forcedIsBidSummaryViewed !== undefined ? forcedIsBidSummaryViewed : updatedBid.isViewed,
  });
};

export const addBidSummaryFromBidDetails = (state: BidsState, updatedBid: BidDetails) => {
  state.bidSummaries.summaries.set(updatedBid.bidId, convertBidDetailsToBidSummary(updatedBid));
  state.biddingSummary.summaries.set(updatedBid.bidId, convertBidDetailsToBidSummary(updatedBid));
};

/**
 * Process new Bid that user has just created, either via "editing" a Bid or counter-offering.
 */
export const handleBidEditOrCounterOffer = (state: BidsState, previousBidId: string, newBid: BidDetails) => {
  state.loadBids.entities.set(newBid.bidId, convertBidDetailsToBid(newBid));
  state.loadBids.listAll.ids = uniq([newBid.bidId, ...state.loadBids.listAll.ids]);
  const precedingBidSummary = state.bidSummaries.summaries.get(previousBidId);
  if (precedingBidSummary) {
    updateIdsInBidSummaryLists(state, previousBidId, newBid.bidId);
    updateBidSummaryFromBidDetails(state, precedingBidSummary, newBid, true);
  }
};

export const useBiddingSocketUpdates = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(incrementViewingBidsCount());
    return () => {
      dispatch(decrementViewingBidsCount());
    };
  }, []);
};

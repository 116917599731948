import { Address, Geolocation, States } from './';

export enum LocationType {
  STATE = 'States',
  GEOLOCATION = 'Geolocation',
  CITY = 'City',
  ANY = 'Anywhere',
  ANY_CA = 'AnywhereCanada',
  ANY_US = 'AnywhereUsa',
  ZIP = 'ZipCode',
  NONE = 'None',
}

export const LOCATION_ALL = 'All';

export interface BaseLocationFromServer {
  type: string;
  address?: Address;
  geolocation?: Geolocation;
  states?: { canadaStates?: string; usStates?: string };
  radius?: number;
}

export interface BaseLocation {
  type: string;
  address?: Address;
  geolocation?: Geolocation;
  states?: States;
  radius?: number;
}

export interface Location extends BaseLocation {
  id: number;
  radiusCoverage?: string;
}

export enum LocationPermissionStatus {
  WAITING_FOR_USER = 'WAITING_FOR_USER',
  GRANTED = 'GRANTED',
  ALLOWED_IN_APP = 'ALLOWED_IN_APP',
  DENIED = 'DENIED',
  NEVER_ASK_AGAIN_ANDROID = 'NEVER_ASK_AGAIN_ANDROID',
}

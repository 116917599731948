import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Config } from '@common/model';
import { updateLastId } from '@common/redux/epic/UserEpic';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

import { default as TruckIcon } from './maintenance-truck.svg';

const TextContainer = styled.div`
  margin-top: 16px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 30px;
`;

export const MaintenancePopup: React.FC = () => {
  const { closingPopup, openingPopup } = usePopup();
  const dispatch = useDispatch();
  const maintenanceMessage = useSelector((state) => state.settings.systemSetting[Config.MaintenancePopup].value);
  const lastIdVersion = useSelector((state) => state.user.lastMaintenanceIdVersion);
  const lastIdNumber = lastIdVersion ? lastIdVersion : 0;

  const updateIdVersion = () => {
    if (maintenanceMessage?.idversion) {
      dispatch(updateLastId(maintenanceMessage?.idversion));
      closingPopup();
    }
  };
  useEffect(() => {
    if (maintenanceMessage?.idversion && maintenanceMessage?.idversion > lastIdNumber) {
      if (maintenanceMessage?.enabled) {
        openingPopup({
          body: (
            <BasePopup
              title={' '}
              onClose={updateIdVersion}
              buttons={[
                <PopupPrimaryButton
                  testID="maintenance_ok_button"
                  key={t(T.common_ok)}
                  label={t(T.common_ok)}
                  action={updateIdVersion}
                />,
              ]}
            >
              <FormContainer>
                <img src={TruckIcon}></img>
                <TextContainer>
                  <Text id="maintenance_title" textStyle={TextStyle.SubtitleBold}>
                    {maintenanceMessage?.title}
                  </Text>
                </TextContainer>
                <TextContainer>
                  <Text id="maintenance_description">{maintenanceMessage?.description}</Text>
                </TextContainer>
              </FormContainer>
            </BasePopup>
          ),
          width: PopupSizes.MEDIUM,
        });
      } else {
        closingPopup();
      }
    }
  }, [
    maintenanceMessage?.title,
    maintenanceMessage?.idversion,
    maintenanceMessage?.enabled,
    maintenanceMessage?.description,
    lastIdNumber,
  ]);
  return null;
};

import { cloneDeep, findKey, invert, isEqual, partial, trim } from 'lodash';
import QueryString from 'qs';

const ARRAY_LIMIT = 2000;

interface IVocabulary {
  [key: string]: string;
}

export class DefaultEncoder<T> {
  getVocabulary() {
    return {
      /*
        You should rewrite vocabulary
      */
    };
  }

  convertObjectRequestToUrlParams = (object: T) => {
    /*
    Set vocabulary to shorten keys -> "search" = "S".
    If no vocabulary, keys, except default, wouldn't be shortened.
    */

    return QueryString.stringify(cloneDeep(object), {
      arrayFormat: 'indices', // how arrays will be shown with brackets, indexes or separated by comma
      allowDots: true,
      encoder: (str: string, defaultEncoder: any, charset: any, type: 'key' | 'value') =>
        this.encoder({ str: trim(str), defaultEncoder: defaultEncoder, type: type }, this.getVocabulary()),
    });
  };

  convertUrlParamsToObjectRequest = (urlParams: string) => {
    /*
    If you have used vocabulary at convertObjectRequestToUrlParams,
    you must use the same vocabulary here, otherwise you will get wrong
    keys for object.

    To use vocabulary for converting Url Params To object
    we should revert vocabulary
    */

    const invertedVocabulary = invert(this.getVocabulary());

    return QueryString.parse(urlParams, {
      ignoreQueryPrefix: true,
      allowDots: true,
      arrayLimit: ARRAY_LIMIT,
      decoder: (str: string, defaultEncoder: any, charset: any, type: 'key' | 'value') =>
        this.decoder({ str: str, defaultEncoder: defaultEncoder, type: type }, invertedVocabulary),
    });
  };

  private searchKeyInVocabulary = (vocabulary: IVocabulary, key: string) => findKey(vocabulary, partial(isEqual, key));

  private convertKeys = (vocabulary: IVocabulary, str: string) => {
    if (this.searchKeyInVocabulary(vocabulary, str)) {
      // if key was founded return it as name of param or full key name.
      return this.searchKeyInVocabulary(vocabulary, str);
    } else if (str.includes('.')) {
      /*
      qs convert object key to string, {key: {key: val}} => 'key.key',
      so we need to separate it to shorten from key.key to k.k
      */
      const keyMap = str.split('.');
      keyMap.forEach((part: string, index: number) => {
        if (this.searchKeyInVocabulary(vocabulary, part)) {
          keyMap[index] = String(this.searchKeyInVocabulary(vocabulary, part));
        } else if (part.match(/\w+\[([0-9]+)\]/g)) {
          /*
          Since we use indexes part looks like key[0], so we need to
          cut brackets to shorten key, and return brackets to final string
          key[0] => k[0] or k[0] => key[0]
          */
          const leftBracketPosition = part.indexOf('[');

          keyMap[index] =
            String(this.searchKeyInVocabulary(vocabulary, part.slice(0, leftBracketPosition))) +
            part.slice(leftBracketPosition);
        }
      });
      return keyMap.join('.');
    } else {
      // if no key in vocabulary return default key as param;
      return str;
    }
  };

  private encoder = (data: { str: string; defaultEncoder: any; type: 'key' | 'value' }, vocabulary: IVocabulary) => {
    /*
    str - key or value from Object or URL string;
    type - type of current str -> 'key' or 'value';
    defaultEncoder - default 'qs' encoder;
    */
    const { str, defaultEncoder, type } = data;

    if (type === 'key') {
      return defaultEncoder(this.convertKeys(vocabulary, str));
    } else {
      // default encoder will separate and convert arrays to URL params
      return defaultEncoder(str);
    }
  };

  private decoder = (data: { str: string; defaultEncoder: any; type: 'key' | 'value' }, vocabulary: IVocabulary) => {
    // qs will parse string of params to object;
    const { str, defaultEncoder, type } = data;

    if (type === 'key') {
      return this.convertKeys(vocabulary, defaultEncoder(str));
    } else {
      // default encoder will separate and convert arrays to URL params
      return defaultEncoder(str);
    }
  };
}

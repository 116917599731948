import firebase from 'firebase/app';

import '@firebase/messaging';

import { AppConfig } from '@util/AppConfig';

export enum NotificationPermissionStatus {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export const isBrowserNotificationSupported = () => {
  return firebase.messaging.isSupported();
};

export const initializeFirebase = () => {
  if (firebase.messaging.isSupported()) {
    firebase.initializeApp({
      apiKey: AppConfig.LBConfig.FirebaseKeys.apiKey,
      authDomain: AppConfig.LBConfig.FirebaseKeys.authDomain,
      storageBucket: AppConfig.LBConfig.FirebaseKeys.storageBucket,
      projectId: AppConfig.LBConfig.FirebaseKeys.projectId,
      messagingSenderId: AppConfig.LBConfig.FirebaseKeys.messagingSenderId,
      appId: AppConfig.LBConfig.FirebaseKeys.appId,
    });
  }
};

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();

    await messaging.requestPermission();
    return messaging.getToken({ vapidKey: AppConfig.LBConfig.FirebaseKeys.vapidKey }).then((currentToken) => {
      if (currentToken) {
        firebase.messaging().onTokenRefresh((currentToken) => currentToken);
      }
    });
  } catch (error) {
    return null;
  }
};

export const registerToken = async () => {
  try {
    const messaging = firebase.messaging();

    const token = await messaging.getToken();
    return token;
  } catch (error) {
    return undefined;
  }
  return undefined;
};

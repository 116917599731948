import React from 'react';

import { MobileDrawerContent, MobileDrawerHeader } from '@component/main/MobileDrawer';

import { MenuHeader } from './MenuHeader';
import { SideMenuContent } from './SideMenuContent';

export const MobileMenu: React.FC = () => (
  <>
    <MobileDrawerHeader>
      <MenuHeader isExpanded={true} />
    </MobileDrawerHeader>
    <MobileDrawerContent>
      <SideMenuContent isExpanded={true} />
    </MobileDrawerContent>
  </>
);

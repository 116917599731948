import React, { RefObject, useCallback, useEffect, useRef } from 'react';

import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { useBackPanelContext } from '@component/panel/BackPanelContext';
import { useManualScrollEventHandler } from '@util/hooks';

import { DivScrollView } from './DivScrollView';

interface Props {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  scrollRef?: RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

/** Defines a scrollable area defined inside other components (like a Panel).
 * When in Mobile, the whole page will scroll instead of using a scrolling div (e.g. SimpleBar).
 *
 * Use FixedHeader/FixedFooter components to define what is not to be scrolled (header or footer) respectively.
 *
 * This accounts for iOS' Safari special "BottomBar" that overlays the page (in portrait mode only) and avoids other "unsafe" areas.
 */
export const AreaScrollView: React.FC<Props> = ({ id, style, scrollRef, className, children }) => {
  const isWindowScrollMode = useIsWindowScrollMode();
  const { isBackPanel } = useBackPanelContext();
  const scrollPosition = useRef(0);

  const onScrollEnd = useCallback(() => {
    scrollPosition.current = window.pageYOffset;
  }, [scrollPosition]);

  useManualScrollEventHandler(window, onScrollEnd, isBackPanel || !isWindowScrollMode);

  useEffect(() => {
    if (isWindowScrollMode && !isBackPanel) {
      // reset global window scroll position when panel appearing and/or switching windowScrollMode
      window.scrollTo(0, scrollPosition.current);
    }
  }, [isWindowScrollMode, isBackPanel]);

  return isWindowScrollMode ? (
    <div id={id} className={className} style={style}>
      {children}
    </div>
  ) : (
    <DivScrollView scrollRef={scrollRef} id={id} className={className} style={style}>
      {children}
    </DivScrollView>
  );
};

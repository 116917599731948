import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LoadDetailsTabs } from '@/model';
import { setOpenedTabs } from '@/reduxStore/reducer/LoadDetailsTabsReducer';
import { fetchLoadDetails } from '@common/redux/epic/LoadDetailsEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { fetchLoadInfoMessages } from '@common/redux/epic/TruckAlertsEpic';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { usePanelBackLocation, usePCMilerPushPanel, usePushPanel } from '@component/panel/PanelHooks';
import { LoadDetailsPanel } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanel';
import { ResultViewType } from '@component/panels/findLoads/searchPanel/SearchPanelHelper';
import { SubRoutes } from '@component/panels/truckPost/TruckPostLists';
import { CommunicationBrokerLoadDetails } from '@page/communication/CommunicationBrokerLoadDetails';
import { useSelector } from '@util/hooks';

interface ComponentProps {
  layer: number;
  specialPathname: string;
  panelOffset?: number;
  onCloseDetailsPanel?: () => void;
}

interface RouteParamsProps {
  alertID: string;
  loadID: string;
  backhaulLoadID: string;
  conversationID: string;
}

export const loadIDFromRouteProps = (routeProps: RouteComponentProps<{ loadID: string; backhaulLoadID: string }>) => {
  return routeProps.match.params.loadID;
};

export const backhaulsLoadIDFromRouteProps = (
  routeProps: RouteComponentProps<{ loadID: string; backhaulLoadID: string }>
) => {
  return routeProps.match.params.backhaulLoadID;
};

export const conversationIDFromRouteProps = (routeProps: RouteComponentProps<RouteParamsProps>) =>
  routeProps.match.params.conversationID;

export const alertIDFromRouteProps = (routeProps: RouteComponentProps<RouteParamsProps>) =>
  routeProps.match.params.alertID;

type Props = ComponentProps & RouteComponentProps<RouteParamsProps>;

const CommunicationLoadDetailsComponent: React.FC<Props> = (props) => {
  const openedTabs = useSelector((state) => state.loadDetailsTabs.openedLoadDetailsTabs);
  const [isBroker, setIsBroker] = useState(false);
  const loadID = loadIDFromRouteProps(props);
  const backhaulLoadID = backhaulsLoadIDFromRouteProps(props);
  const conversationID = conversationIDFromRouteProps(props);
  const alertID = alertIDFromRouteProps(props);
  const pushPCMilerMap = usePCMilerPushPanel('', props.specialPathname);
  const pushTruckPostPanel = usePushPanel(`/${SubRoutes.AddPost}/`);
  const closeDetailsPanel = usePanelBackLocation(alertID && !conversationID ? '/alert/' : '/details/');
  const dispatch = useDispatch();

  const handleTabChange = (tabs: LoadDetailsTabs[], status: boolean) => {
    dispatch(setOpenedTabs(tabs, status));
  };

  const openPCMiler = () => {
    pushPCMilerMap('pc-miler-map');
  };

  const currentUserID = useSelector((state) => state.user.profile?.payload?.id);
  const currentCompanyID = useSelector((state) => state.user.profile?.payload?.companyId);
  const conversations = useSelector((state) => state.communication.conversations);
  const loadDetails = useSelector((state) => state.loadDetails);
  const loadDetailsBackhauls = useSelector((state) => state.loadDetailsBackhauls);

  const conversationContactInfo = useMemo(() => {
    const conversation = conversations.get(conversationID);
    return conversation?.metadata?.load;
  }, [conversations]);

  useEffect(() => {
    dispatch(fetchLoadInfoMessages(loadID));
  }, [loadID]);

  useEffect(() => {
    setIsBroker(
      currentUserID === conversationContactInfo?.poster?.id || currentCompanyID === conversationContactInfo?.poster?.id
    );
  }, [currentCompanyID, currentUserID, conversationContactInfo]);

  if (currentUserID !== undefined && isBroker) {
    return <CommunicationBrokerLoadDetails layer={props.layer} loadID={loadID} />;
  }

  return conversationContactInfo === undefined && !alertID ? (
    <LoadingSpinner />
  ) : loadID ? (
    <LoadDetailsPanel
      offset={props.panelOffset ?? 2}
      loadDetails={backhaulLoadID ? loadDetailsBackhauls : loadDetails}
      fetchLoadDetails={(load: string) =>
        backhaulLoadID
          ? dispatch(fetchLoadDetails(LoadSearchType.Backhaul, load))
          : dispatch(fetchLoadDetails(LoadSearchType.LoadSearch, load))
      }
      layer={props.layer}
      loadId={backhaulLoadID ?? loadID}
      conversationLoadID={backhaulLoadID ?? loadID}
      viewType={alertID && !conversationID ? ResultViewType.TruckAlerts : ResultViewType.Conversation}
      isBackhaulsDisabled={true}
      selectedSearch={undefined}
      openedTabs={openedTabs}
      handleTabChange={handleTabChange}
      closeDetailsPanel={() => closeDetailsPanel()}
      openMap={openPCMiler}
      openPostTruckPanel={() => pushTruckPostPanel()}
      shouldNotShowHideButton={true}
    />
  ) : null;
};

export const CommunicationLoadDetails = withRouter(CommunicationLoadDetailsComponent);

import { isNil } from 'lodash';

import { StorageKey } from '@common/model';

const isLocalStorageAvailable: boolean = (() => {
  const TEST_ITEM = 'TEST_ID';
  try {
    localStorage.setItem(TEST_ITEM, TEST_ITEM);
    const testItem = localStorage.getItem(TEST_ITEM);
    localStorage.removeItem(TEST_ITEM);
    const afterRemoval = localStorage.getItem(TEST_ITEM);
    return testItem === TEST_ITEM && isNil(afterRemoval);
  } catch (error) {
    return false;
  }
})();

export const safeGetItem = (id: StorageKey): string | undefined => {
  if (!isLocalStorageAvailable) {
    return undefined;
  }
  return localStorage.getItem(id) || undefined;
};

export const safeSetItem = (id: StorageKey, item: string) => {
  if (!isLocalStorageAvailable) {
    return false;
  }
  localStorage.setItem(id, item);
  return true;
};

import React, { useEffect, useState } from 'react';

import { clone, find, map } from 'lodash';
import { useDispatch } from 'react-redux';

import { persistPostedOrigin, PostTruckAvailabilityForm } from '@/reduxStore/reducer/PostTruckAvailabilityReducer';
import {
  convertBaseLocationToLoadLocation,
  convertBaseLocationToServerBaseLocation,
  convertLoadLocationToBaseLocation,
  convertOriginLocationToBaseLocation,
  convertToOriginLocation,
  serverRFCDateToYYYYMMDD,
} from '@common/helper';
import {
  LoadSize,
  LocationSuggestion,
  TruckAvailability,
  TruckAvailabilityStatus,
  TruckAvailabilityType,
  UserTruck,
} from '@common/model';
import { addNewTruckAvailability, updateTruckAvailability } from '@common/redux/epic/PostTruckEpic';
import { fetchUserTrucks } from '@common/redux/epic/UserTrucksEpic';
import { PostTruckFormUI } from '@component/panels/truckPost/PostTruckFormUI';
import { SubRoutes } from '@component/panels/truckPost/TruckPostLists';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

interface Props {
  onClose: () => void;
  changeView: (view: SubRoutes) => void;
  dropOff?: LocationSuggestion;
  postRequest: PostTruckAvailabilityForm;
  updatePostRequest: (request: PostTruckAvailabilityForm) => void;
  layer?: number;
  offset?: number;
}

export const PostTruckForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const truckForm = useSelector((state) => state.truckAvailabilityForm.truckAvailability);
  const postTruckState = useSelector((state) => state.postTruck.createAvailability);
  const isVerified = useSelector((state) => state.user.profile?.payload?.isUsdotNumberVerified);

  const onPress = () => {
    const newOrigin = clone(truckForm.origin);
    const newDestination = clone(truckForm.destination);
    dispatch(
      addNewTruckAvailability({
        destinationLocations: [
          convertBaseLocationToServerBaseLocation(
            convertLoadLocationToBaseLocation({ ...newDestination, radius: truckForm.destRadius })
          ),
        ],
        originLocation: convertBaseLocationToServerBaseLocation(
          convertOriginLocationToBaseLocation({ ...newOrigin, radius: truckForm.originRadius })
        ),
        equipmentType: truckForm.truck?.equipmentType,
        truckId: truckForm.truck?.id ?? '',
        type: TruckAvailabilityType.Static,
        status: TruckAvailabilityStatus.Active,
        dates: truckForm.pickupDates ?? [],
        size: truckForm.loadSize ?? LoadSize.TL,
        weight: truckForm.weight,
        length: truckForm.length,
        anonymous: truckForm.anonymous ?? false,
        minRate: truckForm.minRate ? parseFloat(truckForm.minRate) : null,
        notes: truckForm.notes,
        teamDriven: truckForm.teamDriven ?? false,
      })
    );
    dispatch(persistPostedOrigin.action(newOrigin));
  };

  return (
    <PostTruckFormUI
      layer={props.layer}
      onClose={props.onClose}
      onSubmit={onPress}
      dropOff={props.dropOff}
      postRequest={props.postRequest}
      updatePostRequest={props.updatePostRequest}
      isLoading={postTruckState.isLoading}
      wasSubmitted={postTruckState.wasUpdated}
      successText={t(T.common_postTruck_snackBar_saved)}
      failureText={t(T.common_postTruck_snackBar_failedToSave)}
      buttonText={t(T.common_postTruck_truckPost_createNewPost)}
      offset={props.offset}
      isVerified={isVerified}
    />
  );
};

export const EditPostedTruckForm: React.FC<Props & { postID: number }> = (props) => {
  const dispatch = useDispatch();
  const truckForm = useSelector((state) => state.truckAvailabilityForm.truckAvailability);
  const truckAvailabilities = useSelector((state) => state.postTruck.truckAvailabilities.currentTrucks);
  const updatePostTruckState = useSelector((state) => state.postTruck.updateAvailability);
  const trucks = useSelector((state) => state.userTrucks.trucks);
  const [availability, setAvailability] = useState<TruckAvailability>();
  const [truck, setTruck] = useState<UserTruck>();

  useEffect(() => {
    dispatch(fetchUserTrucks());
  }, []);

  useEffect(() => {
    if (truckAvailabilities) {
      const newAvailability = find(truckAvailabilities, (truckAvailability) => truckAvailability.id === props.postID);
      setAvailability(newAvailability);
    }
    if (trucks) {
      const newTruck = find(trucks, (item) => item.id === availability?.truckId);
      setTruck(newTruck);
    }
  }, [truckAvailabilities, trucks]);

  useEffect(() => {
    if (availability) {
      const origin = availability?.originLocation
        ? convertToOriginLocation(convertBaseLocationToLoadLocation(availability.originLocation))
        : undefined;
      const destination = availability.destinationLocations[0]
        ? convertBaseLocationToLoadLocation(availability.destinationLocations[0])
        : undefined;
      const dates = map(availability.dates, (date) => serverRFCDateToYYYYMMDD(date));
      props.updatePostRequest({
        ...truckForm,
        weight: availability.weight,
        length: availability.length,
        pickupDates: dates ?? [],
        origin: origin ?? props.postRequest.origin,
        destination: destination ?? props.postRequest.destination,
        destRadius: destination?.radius,
        originRadius: origin?.radius,
        anonymous: availability.anonymous ?? false,
        minRate: availability.minRate ? `${availability.minRate}` : undefined,
        notes: availability.notes,
        teamDriven: availability.teamDriven ?? false,
      });
      dispatch(persistPostedOrigin.action(origin));
    }
  }, [availability]);

  useEffect(() => {
    if (truck) {
      props.updatePostRequest({
        ...truckForm,
        truckId: truck.id,
        truck: truck,
      });
    }
  }, [truck]);

  const onPress = () => {
    const newOrigin = clone(truckForm.origin);
    const newDestination = clone(truckForm.destination);

    if (availability) {
      dispatch(
        updateTruckAvailability(
          {
            destinationLocations: [
              convertBaseLocationToServerBaseLocation(
                convertLoadLocationToBaseLocation({ ...newDestination, radius: truckForm.destRadius })
              ),
            ],
            originLocation: convertBaseLocationToServerBaseLocation(
              convertOriginLocationToBaseLocation({ ...newOrigin, radius: truckForm.originRadius })
            ),
            equipmentType: truckForm.truck?.equipmentType,
            truckId: truckForm.truck?.id ?? '',
            type: TruckAvailabilityType.Static,
            status: TruckAvailabilityStatus.Active,
            dates: truckForm.pickupDates ?? [],
            size: truckForm.loadSize ?? LoadSize.TL,
            weight: truckForm.weight,
            length: truckForm.length,
            anonymous: truckForm.anonymous ?? false,
            minRate: truckForm.minRate ? parseFloat(truckForm.minRate) : null,
            notes: truckForm.notes,
            teamDriven: truckForm.teamDriven ?? false,
          },
          availability?.id
        )
      );
    }
  };

  return (
    <PostTruckFormUI
      onClose={props.onClose}
      onSubmit={onPress}
      dropOff={props.dropOff}
      postRequest={props.postRequest}
      updatePostRequest={props.updatePostRequest}
      isLoading={updatePostTruckState.isLoading}
      wasSubmitted={updatePostTruckState.wasUpdated}
      successText={t(T.common_postTruck_snackBar_updated)}
      failureText={t(T.common_postTruck_snackBar_failedToUpdate)}
      postId={availability?.id}
      buttonText={t(T.common_update)}
    />
  );
};

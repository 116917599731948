import React, { CSSProperties, useMemo, useState } from 'react';

import { reduce, toString } from 'lodash';
import styled from 'styled-components';

import { Table, TableBody, TableCell } from '@material-ui/core';

import { isPhoneVerified } from '@/reduxStore/epic/TruckEpic';
import {
  formatDateMMDDYYYY,
  formatDateStringToMMMDD,
  formatMileage,
  formatPhoneNumber,
  formatTimeFromNowInYears,
  getInitials,
} from '@common/helper';
import { Company, Truck } from '@common/model';
import { VerificationInfoBadge } from '@component/badge/VerificationBadge';
import { PhoneNumber } from '@component/contact';
import { DOT, MC } from '@component/contact/MCUSDOT';
import { ActionIcon } from '@component/icon/ActionIcon';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { MCPOnboardingButton } from '@component/panels/companyDetails/myCarrierPortal/RiskAssessmentExpansionPanel';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton } from '@component/popup/PopupButtons';
import { PopupSizes, PopupTracking, usePopup } from '@component/popup/PopupTrackingContext';
import { FavoriteChip } from '@component/table/truckTable/TruckComponents';
import { getCalculatedDesireRate } from '@component/table/truckTable/TruckElement';
import { Text, TextDisplay, TextStyle } from '@component/text';
import { ShowPhoneButton } from '@page/communication/ContactInfoSections';
import { TruckDestinations } from '@page/truckLocator/locateTruck/TruckDestinations';
import { Color } from '@style/Color';
import { ElementSize, FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { HIDDEN_PHONE_NUMBER, NO_INFORMATION_SYMBOL } from '@util/Constants';
import { formatDistance } from '@util/Formatter';
import icons from '@util/iconsConstants';
import { displayLengthAndWeight } from '@util/TruckDataHelper';

import {
  ButtonDiv,
  CompanyAvatarViewer,
  Container,
  IconContainer,
  IconNameContainer,
  ProfileAvatarViewer,
  RowDetails,
  TextBlock,
} from './CarrierDetailsPanelStyles';

interface Props {
  truck?: Truck;
  company?: Company;
  onFavorite: () => void;
  shouldDisableActions?: boolean;
  isSingleColumn?: boolean;
  companyAvatar?: string | null;
  profileAvatar?: string | null;
  onShowCarrierContact: (truckID?: string) => void;
  shouldHideControls?: boolean;
  selectedLoadMileage: number | undefined;
  isMCPEnabled: boolean;
}

const VerifiedTextLine = styled.span`
  text-transform: capitalize;
  font-size: ${FontSize.ContentDetail}px;
  margin-left: 6px;
  cursor: pointer;
`;

export const CarrierDetailsData: React.FC<Props> = (props) => {
  const [isDestinationPopupOpen, setIsDestinationPopupOpen] = useState(false);
  const popupContext = usePopup();

  const isAnonymous = props.truck?.anonymous;
  const equipment = props.truck?.equipmentTypes;

  const desiredRate = useMemo(() => {
    const flatRate = getCalculatedDesireRate(props.truck?.amount, props.selectedLoadMileage);
    if (flatRate) {
      return `${t(T.common_dollar_sign)}${formatMileage(flatRate)}`;
    }
    return '';
  }, [props.truck?.amount, props.selectedLoadMileage]);

  const onDestinationClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const isOpen = !isDestinationPopupOpen;
    setIsDestinationPopupOpen(isOpen);
    event.stopPropagation();
  };

  const FavoriteOnboardChips = () => {
    return (
      <ButtonDiv>
        {isAnonymous || !props.truck || props.shouldHideControls ? null : (
          <>
            <FavoriteChip
              id="favorite"
              truck={props.truck}
              onClick={props.onFavorite}
              isDisabled={props.shouldDisableActions}
            />
            {props.isMCPEnabled ? (
              <MCPOnboardingButton isOnboarded={!!props.truck.onboarded} contactGuid={props.truck?.contactId} />
            ) : null}
          </>
        )}
      </ButtonDiv>
    );
  };
  return props.truck === undefined ? (
    <LoadingSpinner />
  ) : (
    <Container isWithoutTopLine={true} hasBottomLine={props.isSingleColumn && !props.truck.anonymous}>
      <Table>
        <TableBody>
          <RowDetails>
            <TableCell colSpan={2}>
              {renderCarrier(
                'carrier',
                isAnonymous,
                props.profileAvatar,
                props.truck.contactFirstName,
                props.truck.contactLastName
              )}
              <RenderPhoneNumber
                id={'phone'}
                onClick={showPhoneVerificationPopup(popupContext)}
                rawPhoneNumber={isAnonymous ? HIDDEN_PHONE_NUMBER : props.truck.contactPhone}
                isPhoneVerified={isPhoneVerified(props.truck.contactPhoneVerificationStatus)}
                onShowCarrierContact={isAnonymous ? undefined : () => props.onShowCarrierContact(props.truck?.id)}
                analyticsActionSuffix={'Truck Locator - Truck list item'}
              />
            </TableCell>
          </RowDetails>
          <RowDetails>
            <TableCell colSpan={2}>
              {renderCompany(
                'company',
                props.truck.anonymous ? t(T.common_postTruck_AnonymousPost_header) : props.truck.companyName,
                props.truck.anonymous ? undefined : props.companyAvatar,
                props.truck.isCarrierIdentityVerified
              )}
              <FavoriteOnboardChips />
            </TableCell>
          </RowDetails>
          <RowDetails>
            <TableCell>
              {renderDotNumber(
                'dot',
                showDotVerificationPopup(popupContext),
                props.truck.dotNumber,
                props.truck.isCarrierIdentityVerified
              )}
            </TableCell>
            <TableCell>
              <MCS150
                content={
                  props.truck.mcs150FormDate ? formatDateMMDDYYYY(props.truck.mcs150FormDate) : NO_INFORMATION_SYMBOL
                }
                labelStyle={{ marginBottom: 8 }}
              />
            </TableCell>
          </RowDetails>
          <RowDetails>
            <TableCell>
              {renderTextBlock(
                'mc',
                'MC#',
                isAnonymous ? (
                  truckCompanyAge(props.truck.authorityIssuance)
                ) : (
                  <MC id="mc_number" docketNumber={props.truck.docketNumber} />
                )
              )}
            </TableCell>

            <TableCell>
              {renderTextBlock(
                'authority',
                t(T.common_truck_Authority),
                props.truck.authorityIssuance ?? NO_INFORMATION_SYMBOL
              )}
            </TableCell>
          </RowDetails>

          <RowDetails>
            <TableCell>
              {renderTextBlock(
                'truckCount',
                t(T.common_conversations_contactInfo_powerUnits),
                props.truck.truckCount ? toString(props.truck.truckCount) : NO_INFORMATION_SYMBOL
              )}
            </TableCell>

            <TableCell>
              {renderTextBlock(
                'totalFmcsaInspections',
                t(T.common_conversations_contactInfo_usCanInspections),
                props.truck.totalFmcsaInspections ? toString(props.truck.totalFmcsaInspections) : NO_INFORMATION_SYMBOL,
                undefined,
                false
              )}
            </TableCell>
          </RowDetails>

          <RowDetails>
            <TableCell>
              {renderTextBlock('deadhead', t(T.common_truck_Deadhead_Short), formatDistance(props.truck.deadhead))}
            </TableCell>
            <TableCell>
              {renderTextBlock(
                'destinations',
                t(T.common_load_Popup_Destinations),
                <TruckDestinations
                  id="destinations_list"
                  destinations={props.truck.destinations}
                  onClick={onDestinationClick}
                  open={isDestinationPopupOpen}
                  popupLocation={'right'}
                />
              )}
            </TableCell>
          </RowDetails>
          <RowDetails>
            <TableCell>{renderTextBlock('equipment', t(T.common_truck_Equipment), equipment)}</TableCell>
            <TableCell>
              {renderTextBlock('amount', t(T.common_truck_desiredRate), desiredRate, undefined, false)}
            </TableCell>
          </RowDetails>
          <RowDetails>
            <TableCell colSpan={2}>
              {renderTextBlock(
                'dates',
                t(T.common_truck_Next_Available),
                displayDates(props.truck.availableDates),
                undefined,
                false
              )}
            </TableCell>
          </RowDetails>
          <RowDetails>
            <TableCell>{renderTextBlock('size', t(T.common_truck_Size), props.truck.loadSize)}</TableCell>
            <TableCell>
              {renderTextBlock(
                'available_capacity',
                t(T.common_truck_Available_Capacity),
                displayLengthAndWeight(props.truck.availableLength, props.truck.availableWeight)
              )}
            </TableCell>
          </RowDetails>
          {props.truck.teamDriven ? (
            <RowDetails>
              <TableCell>{renderTextBlock('teamDriven', t(T.postLoads_TeamDriven), t(T.common_yes))}</TableCell>
            </RowDetails>
          ) : null}
        </TableBody>
      </Table>
    </Container>
  );
};

export const RenderPhoneNumber: React.FC<{
  id: string;
  onClick: () => void;
  rawPhoneNumber?: string;
  isPhoneVerified?: boolean;
  onShowCarrierContact?: () => void;
  analyticsActionSuffix?: string;
}> = ({ id, onClick, rawPhoneNumber, isPhoneVerified, onShowCarrierContact, analyticsActionSuffix }) => {
  const [isShowingPhone, setIsShowingPhone] = useState(false);
  let phoneElement: JSX.Element | string = NO_INFORMATION_SYMBOL;

  if (rawPhoneNumber && rawPhoneNumber.length > 0) {
    if (rawPhoneNumber === HIDDEN_PHONE_NUMBER) {
      phoneElement = (
        <Text id={id} noWrap={true} display={TextDisplay.InlineBlock}>
          {formatPhoneNumber(rawPhoneNumber)}
        </Text>
      );
    } else if (onShowCarrierContact || isShowingPhone) {
      phoneElement = (
        <PhoneNumber
          id={id}
          phoneNumberString={formatPhoneNumber(rawPhoneNumber)}
          phoneNumberValue={rawPhoneNumber}
          analyticsActionSuffix={analyticsActionSuffix}
          onShowCarrierContact={onShowCarrierContact}
        />
      );
    } else {
      phoneElement = (
        <ShowPhoneButton onClick={() => setIsShowingPhone(true)} style={{ marginBottom: '8px' }}>
          {t(T.common_conversations_contactInfo_showPhone)}
        </ShowPhoneButton>
      );
    }
  }
  return renderTextBlock(
    id,
    t(T.common_phone),
    phoneElement,
    undefined,
    true,
    <VerifiedText id={`${id}_header_phone`} isVerified={isPhoneVerified} onClick={onClick} />
  );
};

export const renderCompany = (
  id: string,
  companyName: string,
  avatar: string | JSX.Element | undefined | null,
  isDotVerified?: boolean
) => {
  const companyAvatar = typeof avatar === 'string' ? <CompanyAvatarViewer src={avatar} /> : avatar;
  const companyElement = (
    <IconNameContainer>
      {avatar ? companyAvatar : null}
      <Text id={`${id}_label`} textStyle={TextStyle.BigResult}>
        {companyName}
      </Text>
      <VerificationInfoBadge
        testID="dot_verification_badge"
        isVerified={isDotVerified}
        style={{ margin: '-4px 0 0 4px' }}
      />
    </IconNameContainer>
  );
  return renderTextBlock(id, t(T.common_truck_Company), companyElement);
};

export const renderCarrier = (
  id: string,
  isAnonymous: boolean | undefined,
  avatar: string | null | undefined,
  firstName?: string,
  lastName?: string
) => {
  const carrierElement = (
    <IconNameContainer hasBottomPadding={true}>
      {avatar ? (
        <ProfileAvatarViewer src={avatar} />
      ) : (
        <IconContainer>{getInitials({ firstName: firstName ?? '', lastName: lastName ?? '' })}</IconContainer>
      )}
      <Text id={`${id}_name`} textStyle={TextStyle.BigResult}>
        {isAnonymous ? t(T.common_postTruck_AnonymousPost_header) : `${firstName ?? ''} ${lastName ?? ''}`}
      </Text>
    </IconNameContainer>
  );

  return renderTextBlock(id, t(T.common_truck_Carrier), carrierElement, undefined, true);
};

export const showPhoneVerificationPopup = (popupContext: PopupTracking) => () => {
  const { openingPopup, closingPopup } = popupContext;
  return openingPopup({
    body: (
      <BasePopup
        title={t(T.common_phoneVerification_title)}
        buttons={[<PopupOkButton testID={'phone_verification_ok'} key={'ok'} action={closingPopup} />]}
      >
        {t(T.truckLocator_truckTable_carrierDetails_phoneVerificationPopupText)}
      </BasePopup>
    ),
    width: PopupSizes.SMALL,
    isDismissible: true,
    hasCloseButton: true,
  });
};

export const renderDotNumber = (id: string, onClick: () => void, dotNumber?: string, isDotVerified?: boolean) => {
  return renderTextBlock(
    id,
    'DOT#',
    <DOT id="dot_number" dotNumber={dotNumber} />,
    undefined,
    true,
    <VerifiedText id={`${id}_header_dot`} isVerified={isDotVerified} onClick={onClick} />
  );
};

export const showDotVerificationPopup = (popupContext: PopupTracking) => () => {
  const { openingPopup, closingPopup } = popupContext;
  return openingPopup({
    body: (
      <BasePopup
        title={t(T.settings_dotVerification_title)}
        buttons={[<PopupOkButton testID={'dot_verification_ok'} key={'ok'} action={closingPopup} />]}
      >
        {t(T.truckLocator_truckTable_carrierDetails_dotVerificationPopupText)}
      </BasePopup>
    ),
    width: PopupSizes.SMALL,
    isDismissible: true,
    hasCloseButton: true,
  });
};

const VerifiedText: React.FC<{ id: string; isVerified?: boolean; onClick: () => void }> = ({
  id,
  isVerified,
  onClick,
}) => {
  const style = {
    textDecoration: `underline dotted ${isVerified ? 'green' : 'red'}`,
    color: isVerified ? Color.GREEN_MAIN : Color.RED_APPLE,
  };
  const verificationId = `${id}_${isVerified ? 'verified' : 'not_verified'}`;
  return (
    <VerifiedTextLine id={verificationId} style={style} onClick={onClick}>
      {isVerified ? t(T.common_verified) : t(T.common_notVerified)}
    </VerifiedTextLine>
  );
};

export const renderTextBlock = (
  id: string,
  category: string,
  content?: string | JSX.Element,
  contentStyle?: TextStyle,
  noWrap: boolean = true,
  extraHeader?: JSX.Element
) => {
  return (
    <TextBlock id={`${id}_text_block`}>
      <Text
        id={`${id}_header`}
        textStyle={TextStyle.TableHeader}
        noWrap={noWrap}
        style={{ fontSize: '14px', marginBottom: 8 }}
      >
        {category}
        {extraHeader}
      </Text>
      {typeof content === 'string' ? (
        <Text id={`${id}_info`} textStyle={contentStyle || TextStyle.Default} noWrap={noWrap}>
          {content ? content : NO_INFORMATION_SYMBOL}
        </Text>
      ) : (
        content
      )}
    </TextBlock>
  );
};

export const MCS150 = ({ content, labelStyle }: { content: string | JSX.Element; labelStyle?: CSSProperties }) => {
  const popupContext = usePopup();

  const displayInfoPopup = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    popupContext.openingPopup({
      body: <InfoPopup onClickAction={popupContext.closingPopup} />,
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };
  return (
    <TextBlock id={'mcs_text_block'}>
      <TextBlock id="text_info_block" style={{ flexDirection: 'row', margin: 0 }}>
        <Text
          id={'mcs_header'}
          textStyle={TextStyle.TableHeader}
          noWrap={true}
          style={{ fontSize: '14px', ...labelStyle }}
        >
          {t(T.common_truck_MCS150)}
        </Text>
        <ActionIcon
          id="info"
          alt="info"
          src={icons.info_small}
          onClick={displayInfoPopup}
          size={ElementSize.TextIcon}
          style={{ marginLeft: 5, marginBottom: 5 }}
          tabIndex={-1}
        />
      </TextBlock>
      {typeof content === 'string' ? (
        <Text id={'mcs_info'} textStyle={TextStyle.Default} noWrap={true}>
          {content ? content : NO_INFORMATION_SYMBOL}
        </Text>
      ) : (
        content
      )}
    </TextBlock>
  );
};

const truckCompanyAge = (authorityIssuance: string | undefined): string => {
  if (authorityIssuance) {
    const ageInYears = formatTimeFromNowInYears(authorityIssuance);
    return ageInYears === 0
      ? t(T.common_postTruck_AnonymousPost_lessThanOneYear)
      : `${ageInYears} ${t(T.common_postTruck_AnonymousPost_years)}`;
  }
  return NO_INFORMATION_SYMBOL;
};

export const displayDates = (dates?: string[]) => {
  if (!dates || dates.length <= 0) {
    return t(T.common_truck_Now);
  }

  const parsedDates: string = reduce(
    dates,
    (prev: string, nextDate: string) => `${prev}, ${formatDateStringToMMMDD(nextDate)}`,
    ''
  );

  return parsedDates.substring(2, parsedDates.length);
};

const InfoPopup: React.FC<{ onClickAction: () => void }> = ({ onClickAction }) => {
  return (
    <BasePopup
      title={t(T.common_truck_MCS150)}
      buttons={[<PopupOkButton testID="ok" key="ok" action={onClickAction} />]}
    >
      <div style={{ whiteSpace: 'pre-line' }} id="content">
        {t(T.common_truck_MCS150_Popup)}
      </div>
    </BasePopup>
  );
};

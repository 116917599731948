import { BaseClient } from '@common/client/BaseClient';
import { SendOneTimeLocationRequest } from '@common/model/OneTimeLocation';

export class CarrierTrackingClient extends BaseClient {
  requestOneTimeLocationSharing$ = (conversationId: string) =>
    this.api.post$<{}>(`/carrierTracking/requestOneTimeLocation`, { conversationId: conversationId });

  sendOneTimeLocation$ = (request: SendOneTimeLocationRequest) =>
    this.api.post$<{}>('/carrierTracking/sendOneTimeLocation', request);
}

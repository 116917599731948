import React from 'react';

import { StyledDateChip } from './ChipStyle';

type DateChipProps = {
  text: string;
  id: string;
  disabled?: boolean;
  tabIndex?: number;
};

export const DateChip = (props: DateChipProps) => (
  <StyledDateChip id={props.id} label={props.text} clickable={!props.disabled} tabIndex={props.tabIndex} />
);

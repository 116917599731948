import React, { useEffect, useMemo } from 'react';

import Favico from 'favico.js';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import useSound from 'use-sound';

import { Routes } from '@/router/Routes';
import { Flag } from '@common/model';
import SoundEffect from '@component/main/mixkit-positive-notification-951.wav';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { loadSearchRequestEncoder } from '@page/findLoads/LoadSearchRequestEncoder';
import { FindLoadsRouteParams } from '@page/findLoads/RouteParams';
import { AppConfig } from '@util/AppConfig';
import { useSelector } from '@util/hooks';
import { decodeMatchParams } from '@util/UrlParamsHelper';

import { useIsMainTab } from './hooks/OpenedTabsControl';

export const TITLE_META = 'title';
export const TWITTER_TITLE_META = 'twitter:title';
export const OG_TITLE_META = 'og:title';
export const DESCRIPTION_META = 'description';
export const TWITTER_DESCRIPTION_META = 'twitter:description';
export const OG_DESCRIPTION_META = 'og:description';

interface Props {
  title: string;
  description?: string;
}

export const PageMetadata: React.FC<Props> = (props) => {
  const title = getTitle(props.title);
  const { count: notificationNumber, subtitle = '' } = useNotificationWatcher();
  const notificationTitle = useMemo(
    () => (notificationNumber ? `(+${notificationNumber} new${subtitle}) ` : ''),
    [notificationNumber]
  );
  useMessageSoundNotification();
  useEffect(() => {
    const favicon = new Favico();
    favicon.badge(notificationNumber ?? '');
    return () => {
      favicon.badge('');
    };
  }, [notificationNumber]);

  return (
    <>
      <Helmet defer={false}>
        <title>{`${notificationTitle}${title}`}</title>
        <meta name={TITLE_META} content={title} />
        <meta name={TWITTER_TITLE_META} property={TWITTER_TITLE_META} content={title} />
        <meta name={OG_TITLE_META} property={OG_TITLE_META} content={title} />
        {getDescriptionMeta(props.description)}
      </Helmet>
    </>
  );
};

export const withMetadata =
  (title: string, description?: string) =>
  (Component: React.ComponentType): React.FC =>
  (props) => {
    return (
      <>
        <PageMetadata title={title} description={description} />
        <Component {...props} />
      </>
    );
  };

export const withSimpleMetadata =
  (title: string, description?: string) =>
  (Component: React.ComponentType): React.FC =>
  (props) => {
    const fullTitle = getTitle(title);
    return (
      <>
        <Helmet defer={false}>
          <title>{fullTitle}</title>
          <meta name={TITLE_META} content={fullTitle} />
          <meta name={TWITTER_TITLE_META} property={TWITTER_TITLE_META} content={fullTitle} />
          <meta name={OG_TITLE_META} property={OG_TITLE_META} content={fullTitle} />
          {getDescriptionMeta(description)}
        </Helmet>
        <Component {...props} />
      </>
    );
  };

const getDescriptionMeta = (description?: string) => {
  return description
    ? [
        <meta name={DESCRIPTION_META} content={description} key={DESCRIPTION_META} />,
        <meta
          name={TWITTER_DESCRIPTION_META}
          property={TWITTER_DESCRIPTION_META}
          content={description}
          key={TWITTER_DESCRIPTION_META}
        />,
        <meta
          name={OG_DESCRIPTION_META}
          property={OG_DESCRIPTION_META}
          content={description}
          key={OG_DESCRIPTION_META}
        />,
      ]
    : null;
};

const getTitle = (title: string) => title + ' - ' + AppConfig.NAME;

const useNotificationWatcher = (): { count: number; subtitle?: string } => {
  const isBroker = !useIsCarrier();
  const loadAlertsSearchCount = useSelector((state) => state.loadSearch.loadSearchAlerts.entities);
  const selectedSearch = useSelector((state) => state.loadSearch.selectedSearch);
  const loadAlertsCount = useSelector((state) => state.loadSearch.loadSearchAlerts.totalCount);
  const unreadMessagesCount = useSelector((state) => state.communication.unreadMessagesCount);

  const location = useLocation();

  const loadSearchObject = loadSearchRequestEncoder.convertUrlParamsToObjectRequest(location.search);
  const routeParams = decodeMatchParams<FindLoadsRouteParams>(location.pathname, Routes.LOADS_FINDLOADS);

  if (routeParams) {
    const {
      params: { results },
      isExact: isFindLoads,
    } = routeParams;
    const loadSearchId = loadSearchObject.searchId ? loadSearchObject.searchId : selectedSearch?.id;
    if (isFindLoads) {
      if (results) {
        return { count: loadAlertsSearchCount[loadSearchId]?.count ?? 0 };
      }
      return { count: loadAlertsCount };
    }
  } else if (isBroker) {
    return { count: unreadMessagesCount, subtitle: ' messages' };
  }
  return { count: 0 };
};

const useMessageSoundNotification = () => {
  const isBroker = !useIsCarrier();
  const isSoundNotificationTurnedOn = useSelector(
    (state) => state.settings.systemSetting[Flag.MessageSoundAlertsEnabled].value
  );
  const userId = useSelector((state) => state.user.profile?.payload?.id) ?? '';
  const { latestMessageTimestamp, latestMessageSendBy } = useSelector((state) => state.communication);
  const isMainTab = useIsMainTab();

  const [soundNotification] = useSound(SoundEffect, { forceSoundEnabled: false });

  useEffect(() => {
    if (
      latestMessageTimestamp &&
      isSoundNotificationTurnedOn &&
      userId &&
      userId !== latestMessageSendBy &&
      isBroker &&
      isMainTab
    ) {
      soundNotification();
    }
  }, [latestMessageTimestamp]);
};

import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of as observableOf } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';

import { Api } from '@common/api';
import { LocationMatchClient } from '@common/client/LocationMatchClient';
import { ImmutableLRUMap } from '@common/helper/ImmutableLRUMap';
import { LocationSuggestions } from '@common/model';
import { BaseState, Response } from '@common/redux/Base';

const FETCH_LOCATION_MATCH = 'FETCH_LOCATION_MATCH';
const FETCH_LOCATION_MATCH_COMPLETE = 'FETCH_LOCATION_MATCH_COMPLETE';

export type LocationMatchResponse = Response<LocationSuggestions>;

// NOTE: if adding more location options like includeStates,
// adapt the caching key to include those options as well
interface FetchLocationMatchAction extends Action {
  location: string;
  includeStates: boolean;
}

interface FetchLocationMatchCompleteAction extends Action {
  query: string;
  includeStates: boolean;
  response: LocationMatchResponse;
}

export function fetchLocationMatch(location: string, includeStates = false): FetchLocationMatchAction {
  return {
    type: FETCH_LOCATION_MATCH,
    location: location,
    includeStates: includeStates,
  };
}

export interface LocationMatchState extends BaseState {
  matches: LocationSuggestions;
}

export const fetchLocationMatchComplete = (
  query: string,
  includeStates: boolean,
  response: LocationMatchResponse
): FetchLocationMatchCompleteAction => ({
  type: FETCH_LOCATION_MATCH_COMPLETE,
  query: query,
  includeStates: includeStates,
  response: response,
});

export const EMPTY_MATCHES = { suggestions: [] };

export const locationCacheKey = (queryText: string, includeStates: boolean) =>
  (includeStates ? 'S' : '-') + '|' + queryText;

export const locationMatchReducer = (state = new ImmutableLRUMap<string, LocationMatchState>(100), action: Action) => {
  switch (action.type) {
    case FETCH_LOCATION_MATCH_COMPLETE: {
      const locationAction = action as FetchLocationMatchCompleteAction;
      const key = locationCacheKey(locationAction.query, locationAction.includeStates);

      let matches: LocationSuggestions;
      if (locationAction.response.payload) {
        matches = locationAction.response.payload;
      } else {
        matches = EMPTY_MATCHES;
      }
      return state.set(key, { matches: matches, isLoading: false });
    }
    case FETCH_LOCATION_MATCH: {
      const locationAction = action as FetchLocationMatchAction;
      const key = locationCacheKey(locationAction.location, locationAction.includeStates);

      // fetch from our cache
      const locationState: LocationMatchState | undefined = state.get(key);

      if (locationState) {
        if (locationState.isLoading) {
          // already in loading state
          return state;
        } else if (locationState.matches !== EMPTY_MATCHES) {
          // data already present, move the entry as Last Recently Used (LRU)
          // (Note: Using "EMPTY_MATCHES" for potential previous errors)
          state.moveToLastUsed(key);
          return state;
        }
      }

      // this will trigger loading the data in the EPIC
      return state.set(key, { matches: EMPTY_MATCHES, isLoading: true });
    }
    default:
      return state;
  }
};

export const createLocationMatchEpic = (api: Api) => {
  const client = new LocationMatchClient(api);
  return (
    action$: ActionsObservable<Action>,
    state$: StateObservable<{ locationMatches: ImmutableLRUMap<string, LocationMatchState> }>
  ) => {
    return action$.ofType(FETCH_LOCATION_MATCH).pipe(
      debounceTime(150),
      mergeMap((action) => {
        const locationAction = action as FetchLocationMatchAction;
        const query = locationAction.location;
        const includeStates = locationAction.includeStates;

        const locationStatesCache = state$.value.locationMatches;

        // check if we are supposed to be loading it or not
        const locationState: LocationMatchState | undefined = locationStatesCache?.get(
          locationCacheKey(query, includeStates)
        );

        if (!locationState || !locationState.isLoading) {
          // already loaded or store not initialized properly
          return observableOf({ type: '' });
        }

        const locationObs$ = client.fetchLocationMatch$(query, includeStates);
        return locationObs$.pipe(
          map((response) => {
            if (query !== locationAction.location) {
              return { type: '' };
            }
            return response.result(
              (data) => fetchLocationMatchComplete(query, includeStates, { success: true, payload: data }),
              (error) => fetchLocationMatchComplete(query, includeStates, { success: response.success, error: error })
            );
          })
        );
      })
    );
  };
};

//TODO: remove this file, this is a mess. [BP]
import { default as arrowRightGray } from '../icons/arrow-right-gray.svg';
import { default as arrowRightGreen } from '../icons/arrow-right-green.svg';
import { default as arrowRightRed } from '../icons/arrow-right-red.svg';
import { default as arrowRightSilver } from '../icons/arrow-right-silver.svg';
import { default as arrowRight } from '../icons/arrow-right.svg';
import { default as ascending } from '../icons/ascending.svg';
import { default as backhauls } from '../icons/backhaul.svg';
import { default as calendar } from '../icons/calendar.svg';
import { default as callDisabled } from '../icons/call-disabled.svg';
import { default as call } from '../icons/call.svg';
import { default as clearSmall } from '../icons/clear-small.svg';
import { default as close } from '../icons/close.svg';
import { default as closeLight } from '../icons/closeLight.svg';
import { default as collapse } from '../icons/collapse.svg';
import { default as trashOrange } from '../icons/delete-orange.svg';
import { default as trash } from '../icons/delete.svg';
import { default as descending } from '../icons/descending.svg';
import { default as dollar } from '../icons/dollar.svg';
import { default as edit } from '../icons/edit.svg';
import { default as block } from '../icons/error-block.svg';
import { default as expand } from '../icons/expand.svg';
import { default as favorite } from '../icons/favorite.svg';
import { default as chevronRight } from '../icons/fields - arrow right.svg';
import { default as like } from '../icons/heart.png';
import { default as home } from '../icons/home.svg';
import { default as arrowRightOrange } from '../icons/icon-arrow-right-orange.svg';
import { default as hide } from '../icons/icon-hide.svg';
import { default as loadsIcon } from '../icons/icon-loads.svg';
import { default as onboardedOff } from '../icons/icon-onboarded-off.svg';
import { default as onboardedOn } from '../icons/icon-onboarded-on.svg';
import { default as messageSent } from '../icons/icon-send.svg';
import { default as trip } from '../icons/icon-trip.svg';
import { default as messageRead } from '../icons/icon-view.svg';
import { default as zoom } from '../icons/icon-zoom.svg';
import { default as back } from '../icons/icons - back.svg';
import { default as info_small } from '../icons/info_small.svg';
import { default as info_small_gray } from '../icons/info_small_gray.svg';
import { default as info_small_white } from '../icons/info_small_white.svg';
import { default as location } from '../icons/location.svg';
import { default as lock } from '../icons/lock.png';
import { default as logoGray } from '../icons/logo-gray.svg';
import { default as logoOrange } from '../icons/logo-orange.svg';
import { default as mapError } from '../icons/map-error.svg';
import { default as map } from '../icons/map-look-up.svg';
import { default as marker } from '../icons/marker.svg';
import { default as messageBlue } from '../icons/message-blue.svg';
import { default as arrowDown } from '../icons/new-loads-arrow-down.svg';
import { default as arrowUp } from '../icons/new-loads-arrow.svg';
import { default as pointA } from '../icons/point A.svg';
import { default as pointB } from '../icons/point B.svg';
import { default as printDisabled } from '../icons/print-disabled.svg';
import { default as print } from '../icons/print.svg';
import { default as roadSignTwoWayDisabled } from '../icons/road-sign-two-way-disabled.svg';
import { default as roadSignTwoWay } from '../icons/road-sign-two-way.svg';
import { default as save } from '../icons/save search.svg';
import { default as saveLoadDisabled } from '../icons/save-disabled.svg';
import { default as saveLoad } from '../icons/save.svg';
import { default as savedLoad } from '../icons/saved.svg';
import { default as searchTrucks } from '../icons/search-trucks.svg';
import { default as shareDisabled } from '../icons/share-disabled.svg';
import { default as share } from '../icons/share.svg';
import { default as states } from '../icons/states.svg';
import { default as tabAdded } from '../icons/trip-load-tab-added.svg';
import { default as tabAvailable } from '../icons/trip-load-tab-available.svg';
import { default as tabDeadhead } from '../icons/trip-load-tab-deadhead.svg';
import { default as warning } from '../icons/warning.svg';
import { default as warningMenu } from '../icons/warning_menu.svg';

const icons = {
  arrowUp: arrowUp,
  arrowRightGray: arrowRightGray,
  arrowRightGreen: arrowRightGreen,
  arrowRightRed: arrowRightRed,
  arrowRight: arrowRight,
  arrowRightOrange: arrowRightOrange,
  arrowRightSilver: arrowRightSilver,
  arrowDown: arrowDown,
  back: back,
  backhaul: backhauls,
  backhauls: backhauls,
  block: block,
  calendar: calendar,
  call: call,
  clearSmall: clearSmall,
  callDisabled: callDisabled,
  chevronRight: chevronRight,
  close: close,
  closeLight: closeLight,
  collapse: collapse,
  dislike: favorite,
  dollar: dollar,
  edit: edit,
  expand: expand,
  favorite: favorite,
  ascending: ascending,
  hide: hide,
  home: home,
  info_small: info_small,
  info_small_white: info_small_white,
  info_small_gray: info_small_gray,
  like: like,
  loadsIcon: loadsIcon,
  location: location,
  lock: lock,
  logoGray: logoGray,
  logoOrange: logoOrange,
  descending: descending,
  mapError: mapError,
  map: map,
  marker: marker,
  messageBlue: messageBlue,
  messageRead: messageRead,
  messageSent: messageSent,
  onboardedOn: onboardedOn,
  onboardedOff: onboardedOff,
  pointA: pointA,
  pointB: pointB,
  print: print,
  printDisabled: printDisabled,
  roadSignTwoWay: roadSignTwoWay,
  roadSignTwoWayDisabled: roadSignTwoWayDisabled,
  save: save,
  saveLoad: saveLoad,
  saveLoadDisabled: saveLoadDisabled,
  savedLoad: savedLoad,
  searchTrucks: searchTrucks,
  share: share,
  shareDisabled: shareDisabled,
  states: states,
  tabAdded: tabAdded,
  tabAvailable: tabAvailable,
  tabDeadhead: tabDeadhead,
  trash: trash,
  trashOrange: trashOrange,
  warning: warning,
  warningMenu: warningMenu,
  zoom: zoom,
  trip: trip,
};

export default icons;

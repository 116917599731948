export enum Color {
  WHITE = '#ffffff',
  GHOST_WHITE = '#ffffff80',

  //-------------------------------TRANSPARENTS---------------------------------//

  TRANSPARENT_0 = '#00000000',
  TRANSPARENT_10 = '#0000001A',
  TRANSPARENT_30 = '#00000026',
  TRANSPARENT_SHADOW = '#0000000f',
  TRANSPARENT_GRAY_RGBA = 'rgba(0, 0, 0, 0.16)',
  TRANSPARENT_HOVER = '#00000014',

  //-----------------------------------GRAYS------------------------------------//

  GRAY_1 = '#f2f2f2',
  GRAY_2 = '#d9d9d9',
  GRAY_3 = '#acacac',
  GRAY_4 = '#707070',
  GRAY_5 = '#555555',
  GRAY_6 = '#3d3d3d',
  GRAY_7 = '#eaeaea',
  GRAY_8 = '#b4b4b4',
  GRAY_9 = '#f5f5f5',
  GRAY_10 = '#d7d7d7',
  GRAY_11 = '#a0a0a0',
  GRAY_12 = '#2d2a2b',
  GRAY_13 = '#dfdfdf',
  GRAY_14 = '#9e9e9e',
  GRAY_15 = '#e2e2e2',
  GRAY_16 = '#f1f1f1',
  GRAY_17 = '#202020',
  GRAY_19 = '#f7f7f7',
  GRAY_18 = '#9A9C9F',
  GRAY_20 = '#88a4bc',
  GRAY_21 = '#6D7E90',
  GRAY_22 = '#666666',
  GRAY_STONE = '#919191',
  GRAY_LIGHT = '#f7f9fb',
  GRAY_DARK = '#484848',
  GRAY_SMOKE = '#DAE2EA',
  GRAY_LAVA = '#E9EDF2',
  GRAY_GRADIENT_DARK = '#E2E9EE',
  GRAY_GRADIENT_LIGHT = '#F7F9FB',
  GRAY_DISABLED = '#91919126',
  GRAY_TEXT = '#3D3D3D',
  GRAY_PANEL = '#E7EFF7',
  GRAY_OLD = '#EBECEC',
  GRAY_ALUMINIUM = '#828589',
  GRAY_MAGNET = '#2E2E2E',
  GRAY_SHARK = '#999A9C',
  GRAY_STEEL = '#E5E6EC',

  //-----------------------------------GREENS-----------------------------------//

  GREEN_MAIN = '#00b529',
  GREEN_DARK = '#00a726',
  GREEN_LIGHT = '#e4f7e8',
  GREEN_LIGHT_2 = '#B7E7C3',
  GREEN_INACTIVE = '#98E1A8',
  GREEN_LEAF = '#00B5290D',
  GREEN_HOVER = '#009536',
  GREEN_TITLE = '#13B529',
  GREEN_BUTTON_TEXT = '#00B140',
  GREEN_JUNE_BUD = '#BED76B',

  //-----------------------------------CARRIER MAP HISTORY GREENS-----------------------------------//

  MAP_GREEN_DISABLED = '#E5EAEF',
  MAP_GREEN_0_0125 = '#DCEFD9',
  MAP_GREEN_0125_0250 = '#CADFC9',
  MAP_GREEN_0250_0375 = '#B8CFB9',
  MAP_GREEN_0375_0500 = '#A6C0A8',
  MAP_GREEN_0500_0625 = '#94B098',
  MAP_GREEN_0625_0750 = '#829F88',
  MAP_GREEN_0750_0875 = '#688F5A',
  MAP_GREEN_0875_1 = '#00B529',

  //----------------------------------ORANGES-----------------------------------//

  ORANGE_MAIN = '#ff9d1f',
  ORANGE_MAIN_DARK = '#BD7820',
  ORANGE_DARK = '#e5870f',
  ORANGE_LIGHT = '#FFF9F1',
  ORANGE_SMOKE = '#f3efe3',
  ORANGE_FADED = '#F6F3E9',
  ORANGE_WARNING = '#FDF3E1',
  ORANGE_MARIGOLD = '#FCF4E6',
  ORANGE_HIGHLIGHT = '#FEFBD7',
  ORANGE_BANNERBORDER = '#DC940A',
  ORANGE_BIDS_CHAT_BUBBLE = '#FBF3E5',
  ORANGE_BORDER = '#FF9C1E',
  ORANGE_TOPAZ = '#F1C473',

  //-----------------------------------REDS-------------------------------------//

  RED_1 = '#cc0a2e',
  RED_2 = '#cc2525',
  RED_3 = '#ff0000',
  RED_BANNER = '#CF1601',
  RED_APPLE = '#DC2B0A',
  EMAIL = '#d93025',
  RED_PINK = '#FF6550',
  RED_BANNER_ERROR = '#FEF4F2',

  //----------------------------------BLUES-------------------------------------//

  BLUE_PURE = '#0000ff',
  BLUE_LIGHT = '#0ABBDC0D',
  BLUE_LIGHT_2 = '#EBF4FB',
  BLUE_LIGHT_3 = '#E3E8EC',
  BLUE_LIGHT_4 = '#D6E4F0',
  BLUE_LIGHT_5 = '#E2F0FF',
  BLUE_AZURE = '#F2FCFE',
  BLUE_SKY = '#0EBBDC',
  BLUE_DARK = '#3B729F',
  BLUE_COOL = '#0FBBDC',
  BLUE_BONDIE = '#009CBA',
  BLUE_WILD_YONDER = '#9DB9C7',
  BLUE_MAYA = '#76B9E1',
  BLUE_TEXT = '#57B9D8',

  //--------------------------------YELLOWS------------------------------------//

  YELLOW_1 = '#fff5a2',
  YELLOW_BANNER = '#F7DE3A',

  //----------------------------------BEIGES------------------------------------//

  BEIGE_1 = '#e0edd2',
  FACEBOOK = '#657DFF',

  //----------------------------------BLACK------------------------------------//
  BLACK = '#000000',
  LIGHT_BLACK = '#27313C',
  MENU_BLACK = '#232323',

  //----------------------------------PURPLE------------------------------------//
  PURPLE = '#9570E8',
  PASTEL_VIOLET = '#CE9BC8',
}

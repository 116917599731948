import React from 'react';

import styled from 'styled-components';

import { t, T } from '@/translate';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';

export const NewBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 22px;
  background-color: ${Color.GREEN_MAIN};
  border: 1px solid ${Color.GRAY_SMOKE};
  border-radius: 12px;
  text-transform: uppercase;
`;
export const BadgeLabel = styled.span`
  color: ${Color.GRAY_LIGHT};
  font-size: ${FontSize.Body}px;
  margin-top: 2px;

  &:hover {
    color: ${Color.GRAY_LIGHT};
  }
  &:active {
    color: ${Color.GRAY_LIGHT};
  }
`;

export const NewBadge = ({ className }: { className?: string }) => (
  <NewBadgeContainer className={className}>
    <BadgeLabel>{t(T.common_new)}</BadgeLabel>
  </NewBadgeContainer>
);

import { BaseClient } from '@common/client/BaseClient';
import {
  BidActionRequest,
  BidDetails,
  BiddingSummaryRequest,
  BiddingSummaryResponse,
  BidsSummariesRequest,
  BidSummariesResponse,
  CarrierInfo,
  LoadBidsRequest,
  LoadBidsResponse,
  LoadFieldsBiddingOnly,
  LoadWithDataForBiddingOnly,
  LoadWithStatusOnly,
  PostBidRequest,
} from '@common/model';

export class BidsClient extends BaseClient {
  getBidSummaries$ = (request: BidsSummariesRequest) =>
    this.api.get$<BidSummariesResponse>('/bids', {
      ...request,
      roles: request.roles && request.roles.length > 0 ? request.roles.toString() : undefined,
      status: request.status && request.status.length > 0 ? request.status.toString() : undefined,
    });

  getBiddingSummary$ = (request: BiddingSummaryRequest) =>
    this.api.get$<BiddingSummaryResponse>('/bids/summary', {
      bidUpdatesCount: true,
      roles: [request.role],
    });

  getBiddingSummaryList$ = ({ loadId, ...request }: LoadBidsRequest) =>
    this.api.get$<BidSummariesResponse>(`/loads/${loadId}/bids`, request);

  getBidSummary$ = ({ loadId, carrierId, ...request }: LoadBidsRequest) =>
    this.api.get$<LoadBidsResponse>(`/loads/${loadId}/bids/history/${carrierId ?? 'self'}`, request);

  getBid$ = (bidId: string) => this.api.get$<BidDetails>(`/bids/${bidId}`, {});

  getCarrierInfo$ = (bidId: string) => this.api.get$<CarrierInfo>(`/bids/${bidId}/carrierinfo`, {});

  actOnBid$ = ({ bidId, ...request }: BidActionRequest) =>
    this.api.post$<BidDetails>(`/bids/${bidId}/updates`, request);

  postBid$ = ({ loadId, ...request }: PostBidRequest) => this.api.post$<BidDetails>(`/loads/${loadId}/bids`, request);

  getLoadIsOnline$ = (loadId: string) => this.api.get$<LoadWithStatusOnly>(`/loads/${loadId}`, { fields: 'status' });

  getBidLoadDetails$ = (loadId: string) =>
    this.api.get$<LoadWithDataForBiddingOnly>(`/loads/${loadId}`, { fields: LoadFieldsBiddingOnly });

  markAllBidsAsViewed$ = () => this.api.post$<{}>('/bids/mark-as-read', {});
}

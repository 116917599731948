import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api, ApiError } from '@common/api';
import { MyCarrierPortalClient } from '@common/client/MyCarrierPortalClient';
import {
  MCPCarrierAssessment,
  MCPLinkAccount,
  MCPRiskAssessment,
  MCPVerificationResponse,
  SendMCPVerificationData,
} from '@common/model';
import { createAction, createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface MyCarrierPortalState {
  dotVerification: {
    isLoading: boolean;
    isError: boolean;
    verificationAlreadyInitiated: boolean;
    instructionsSent: boolean;
    emailSentDate?: string;
  };
  onboarding: {
    isSendingRequest: boolean;
    onboardingError?: ApiError;
    isOnboarded: boolean;
    isOnboardingRequestSent: boolean;
  };
  linking: {
    isSendingRequest: boolean;
    linkError?: ApiError;
    isBrokerAccountLinkedToMcp?: boolean;
  };
  isLoadingRiskAssessment: boolean;
  carrierRiskAssessment?: MCPRiskAssessment;
}

const sendMcpDotVerificationAction = createApiAction<SendMCPVerificationData, MCPVerificationResponse>(
  'SEND_MCP_DOT_VERIFICATION_REQUEST'
);
const sendOnboardingRequestAction = createApiAction<string, {}>('SEND_ONBOARDING_REQUEST');
const linkMcpAccountAction = createApiAction<MCPLinkAccount, {}>('LINK_MCP_ACCOUNT_REQUEST');
const fetchCarrierRiskAssessmentAction = createApiAction<string, MCPCarrierAssessment>('GET_CARRIER_RISK_ASSESSMENT');
const resetMCPApiErrorAction = createAction('RESET_MCP_API_ERROR');

export const resetMCPApiError = () => resetMCPApiErrorAction.action(undefined);
export const sendOnboardingRequest = (contactGuid: string) => sendOnboardingRequestAction.fetchAction(contactGuid);
export const linkMcpAccount = (params: MCPLinkAccount) => linkMcpAccountAction.fetchAction(params);
export const getCarrierRiskAssessment = (contactGuid: string) =>
  fetchCarrierRiskAssessmentAction.fetchAction(contactGuid);
export const sendMcpDotVerification = (params: SendMCPVerificationData) =>
  sendMcpDotVerificationAction.fetchAction(params);

const initialState: MyCarrierPortalState = {
  dotVerification: {
    isLoading: false,
    isError: false,
    verificationAlreadyInitiated: false,
    instructionsSent: false,
    emailSentDate: undefined,
  },
  onboarding: {
    isSendingRequest: false,
    isOnboarded: false,
    isOnboardingRequestSent: false,
  },
  linking: {
    isSendingRequest: false,
  },
  isLoadingRiskAssessment: false,
  carrierRiskAssessment: undefined,
};

export const myCarrierPortalReducer = createMergedReducer(initialState, [
  resetMCPApiErrorAction.addCase((state) => {
    state.onboarding.onboardingError = undefined;
    state.linking.linkError = undefined;
  }),

  sendMcpDotVerificationAction.initiateCase((state) => {
    state.dotVerification.isLoading = true;
    state.dotVerification.isError = false;
  }),
  sendMcpDotVerificationAction.completeCase((state, action) => {
    if (action.response.success && action.response.payload) {
      state.dotVerification.verificationAlreadyInitiated = action.response.payload.verificationAlreadyInitiated;
      state.dotVerification.instructionsSent = action.response.payload.instructionsSent;
      state.dotVerification.emailSentDate = action.response.payload.emailSentDate;
    }
    state.dotVerification.isError = !action.response.success;
    state.dotVerification.isLoading = false;
  }),

  sendOnboardingRequestAction.initiateCase((state) => {
    state.onboarding.isSendingRequest = true;
    state.onboarding.onboardingError = undefined;
  }),
  sendOnboardingRequestAction.completeCase((state, action) => {
    state.onboarding.isSendingRequest = false;
    if (action.response.success) {
      state.onboarding.isOnboardingRequestSent = true;
    } else {
      state.onboarding.onboardingError = action.response.error;
    }
  }),

  linkMcpAccountAction.initiateCase((state) => {
    state.linking.isSendingRequest = true;
    state.linking.linkError = undefined;
  }),
  linkMcpAccountAction.completeCase((state, action) => {
    state.linking.isSendingRequest = false;
    if (action.response.success) {
      state.linking.isBrokerAccountLinkedToMcp = true;
    } else {
      state.linking.linkError = action.response.error;
    }
  }),

  fetchCarrierRiskAssessmentAction.initiateCase((state) => {
    state.isLoadingRiskAssessment = true;
    state.onboarding.onboardingError = undefined;
    state.linking.linkError = undefined;
    state.linking.isBrokerAccountLinkedToMcp = initialState.linking.isBrokerAccountLinkedToMcp;
    state.onboarding.isOnboardingRequestSent = initialState.onboarding.isOnboardingRequestSent;
    state.onboarding.isOnboarded = initialState.onboarding.isOnboarded;
    state.carrierRiskAssessment = initialState.carrierRiskAssessment;
  }),
  fetchCarrierRiskAssessmentAction.completeCase((state, action) => {
    state.isLoadingRiskAssessment = false;
    if (action.response.success) {
      state.linking.isBrokerAccountLinkedToMcp = action.response.payload.isBrokerAccountLinkedToMcp;
      state.onboarding.isOnboardingRequestSent = action.response.payload.isOnboardingRequestSent;
      state.onboarding.isOnboarded = action.response.payload.isCarrierOnboarded;
      state.carrierRiskAssessment = action.response.payload.riskAssessment;
    }
  }),
]);

export const createMyCarrierPortalEpic = (api: Api) => {
  const client = new MyCarrierPortalClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      sendMcpDotVerificationAction.createEpic$(action$, client.sendMcpDotVerification$),
      sendOnboardingRequestAction.createEpic$(action$, client.sendOnboardingRequest$),
      linkMcpAccountAction.createEpic$(action$, client.linkMcpAccount$),
      fetchCarrierRiskAssessmentAction.createEpic$(action$, client.getCarrierRiskAssessment$)
    );
};

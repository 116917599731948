import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import { LoadDetailsTabs } from '@/model';
import { setOpenedTabs } from '@/reduxStore/reducer/LoadDetailsTabsReducer';
import { fetchLoadDetails } from '@common/redux/epic/LoadDetailsEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { fetchLoadInfoMessages } from '@common/redux/epic/TruckAlertsEpic';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import {
  usePanelBackLocation,
  usePatchedPushPanel,
  usePCMilerPushPanel,
  usePushPanel,
} from '@component/panel/PanelHooks';
import { LoadDetailsPanel } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanel';
import { ResultViewType } from '@component/panels/findLoads/searchPanel/SearchPanelHelper';
import { SubRoutes } from '@component/panels/truckPost/TruckPostLists';
import {
  BID_ID_INDEX,
  BID_LOAD_ID_INDEX,
  bidIDFromRouteProps,
  bidLoadIDFromRouteProps,
  BidsRouteParamsProps,
  offerViewFromRouteProps,
} from '@page/bids/BidsPanelRouting';
import { useSelector } from '@util/hooks';

interface ComponentProps {
  onCloseDetailsPanel?: () => void;
  layer: number;
  isInteractive: boolean;
  panelOffset?: number;
}

type Props = ComponentProps & RouteComponentProps<BidsRouteParamsProps>;

const BidsLoadDetailsComponent: React.FC<Props> = (props: Props) => {
  const openedTabs = useSelector((state) => state.loadDetailsTabs.openedLoadDetailsTabs);

  const history = useHistory();

  const bidLoadIdFromHistoryLocation =
    bidLoadIDFromRouteProps(props) ?? history.location.pathname.split('/')[BID_LOAD_ID_INDEX];

  const bidIdFromHistoryLocation = bidIDFromRouteProps(props) ?? history.location.pathname.split('/')[BID_ID_INDEX];
  const panelType = offerViewFromRouteProps(props);

  const pushPCMilerMap = usePCMilerPushPanel('', 'bids');
  const pushTruckPostPanel = usePushPanel(`/${SubRoutes.AddPost}/`);

  const closeDetailsPanel = usePanelBackLocation(`/${panelType ? 'load-details' : 'summary'}/`);
  const pushPanel = usePatchedPushPanel('/offer/', '/load-details/');

  const dispatch = useDispatch();
  const handleTabChange = (tabs: LoadDetailsTabs[], status: boolean) => {
    dispatch(setOpenedTabs(tabs, status));
  };

  const openPCMiler = () => {
    pushPCMilerMap('pc-miler-map');
  };

  const loadDetails = useSelector((state) => state.loadDetails);

  useEffect(() => {
    dispatch(fetchLoadInfoMessages(bidLoadIdFromHistoryLocation));
  }, [bidLoadIdFromHistoryLocation]);

  const handleCloseDetailsPanel = () => {
    if ((!props.isInteractive && closeDetailsPanel) || panelType) {
      closeDetailsPanel();
    } else {
      pushPanel(bidIdFromHistoryLocation);
    }
  };

  return loadDetails === undefined && !bidLoadIdFromHistoryLocation ? (
    <LoadingSpinner />
  ) : bidLoadIdFromHistoryLocation ? (
    <LoadDetailsPanel
      offset={props.panelOffset ?? 2}
      layer={props.layer}
      loadDetails={loadDetails}
      fetchLoadDetails={(load: string) => dispatch(fetchLoadDetails(LoadSearchType.LoadSearch, load))}
      loadId={bidLoadIdFromHistoryLocation}
      conversationLoadID={bidLoadIdFromHistoryLocation}
      viewType={ResultViewType.LoadSearch}
      isBackhaulsDisabled={true}
      selectedSearch={undefined}
      openedTabs={openedTabs}
      handleTabChange={handleTabChange}
      closeDetailsPanel={handleCloseDetailsPanel}
      openMap={openPCMiler}
      openPostTruckPanel={() => pushTruckPostPanel()}
      shouldNotShowHideButton={true}
    />
  ) : null;
};

export const BidsLoadDetails = withRouter(BidsLoadDetailsComponent);

import React from 'react';

import styled from 'styled-components';

import { Load } from '@common/model';
import { Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';

import { LoadDetailsGeneralInfoHeader } from './LoadDetailsGeneralInfoHeader';
import { LoadDetailsInfoBar } from './LoadDetailsInfoBar';
import { LoadDetailsOriginDestination } from './LoadDetailsOriginDestination';

const LOAD_DETAILS_INFO_PADDING = 20;

interface ComponentProps {
  searchLoads?: Load[];
  backhaulsLoads?: Load[];
  similarLoads?: Load[];
  currentLoadId?: string;
  isPostLoad?: boolean;
  loadDetails?: Load;
  profitPerDay?: number;
  onManualExtraStopClick: () => void;
  openProfitCalculator?: () => void;
}

const Row = styled.div`
  padding: ${Spacing.ScreenSide}px ${LOAD_DETAILS_INFO_PADDING}px 0 ${LOAD_DETAILS_INFO_PADDING}px;
  border-bottom: 1px solid ${THEME.palette.background.smoke};
`;

const ContentData = styled.div`
  padding-top: ${Spacing.InterElementVertical}px;
`;

export const LoadDetailsGeneralInfo: React.FC<ComponentProps> = (props) => {
  if (!props.loadDetails) {
    return null;
  }

  return (
    <>
      <Row>
        <LoadDetailsGeneralInfoHeader isBrokerUi={props.isPostLoad} load={props.loadDetails} />
        <LoadDetailsOriginDestination
          originLocation={props.loadDetails.originLocation}
          destinationLocation={props.loadDetails.destinationLocation}
          pickupDateTimes={props.loadDetails.pickupDateTimes}
          deliveryDateTime={props.loadDetails.deliveryDateTime}
          searchLoads={props.searchLoads}
          backhaulsLoads={props.backhaulsLoads}
          similarLoads={props.similarLoads}
          currentLoadId={props.currentLoadId}
          load={props.loadDetails}
          onManualExtraStopClick={props.onManualExtraStopClick}
        />
      </Row>
      <ContentData>
        <LoadDetailsInfoBar
          id="rate-bar"
          openProfitCalculator={props.openProfitCalculator}
          isBrokerUi={props.isPostLoad}
          loadDetails={props.loadDetails}
          profitPerDay={props.profitPerDay}
        />
      </ContentData>
    </>
  );
};

import styled from 'styled-components';

import { IconButton, Table, TableCell, TableRow } from '@material-ui/core';

import { ThemeProps, withTheme } from '@style/WithTheme';

export const StyledLoadTable = styled(Table)`
  && {
    table-layout: fixed;
    border-collapse: initial;
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    width: 40px;
  }
`;

export const ThemedCell = styled(TableCell)`
  && {
    background-color: transparent;
    padding-left: 5px;
    padding-right: 10px;
    padding-top: 4px;
    width: ${(props: { width?: string }) => (props.width ? props.width : '0px')};
  }
`;

export const ExpandableThemedCell = withTheme()(styled(TableCell)`
  && {
    background-color: ${(props: { expanded: number; selected: boolean } & ThemeProps) =>
      props.expanded && !props.selected ? props.theme.palette.tableCell.light : 'transparent'};
    height: 0px;
    min-height: 0px;
    width: 0px;
    transition: background-color 0.6s ease;
  }
`);

export const NoBorderThemedCell = withTheme()(styled(TableCell)`
  && {
    background-color: ${(props: { expanded: number; selected: boolean } & ThemeProps) =>
      props.expanded && !props.selected ? props.theme.palette.tableCell.light : 'transparent'};
    border: none;
    width: 0px;
    margin-left: 8px;
    transition: background-color 0.6s ease;
    vertical-align: top;
  }
`);

export const ArrowCell = withTheme()(styled(TableCell)`
  && {
    background-color: ${(props: { expanded: number; selected: boolean } & ThemeProps) =>
      props.expanded && !props.selected ? props.theme.palette.tableCell.light : 'transparent'};
    vertical-align: top;
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    border: none;
    transition: background-color 0.6s ease;
    text-align: right;
  }
`);

export const ThemedLargeDiv = withTheme()(styled.div`
  && {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: visible;
    max-height: ${(props: { isLeftCell: boolean; expanded: number }) => (props.expanded ? '300px' : '98px')};
    flex-wrap: nowrap;
    align-items: center;

    transition: max-height 0.6s ease;
  }
`);

export const HiddenDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const MediumCellDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props: { centered: boolean }) => (props.centered ? 'center' : 'flex-start')};
`;

export const MediumCellTopSubDiv = styled.div`
  margin: 13px 0px 0px 0px;
  min-height: 21px;
  width: 100%;
  text-align: center;
`;
export const MediumCellBotSubDiv = styled.div`
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
  align-items: ${(props: { centered: boolean }) => (props.centered ? 'center' : 'flex-start')};
`;

export const ExpandableCellSubDiv = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 37px;
  span {
    opacity: ${(props: { expanded: boolean }) => (props.expanded ? 1 : 0)};
    transition: opacity 0.6s ease;
  }
`;

export const LowerButtonDiv = withTheme()(styled.div`
  padding: ${(props: { expanded: boolean } & ThemeProps) => (props.expanded ? '16px' : '0px 16px')};
  overflow: hidden;
  max-height: ${(props: { expanded: boolean } & ThemeProps) => (props.expanded ? '50px' : '0px')};
  transition: max-height 0.6s ease, padding 0.6s ease;
`);

export const CenteredCell = styled(TableCell)`
  && {
    background-color: transparent;
  }
`;

export const CenteredCellDiv = styled.div`
  && {
    display: flex;
    justify-content: center;
  }
`;

export const ButtonCenteredCell = styled(CenteredCell)`
  && {
    padding-right: 24px !important;
    text-align: right;
    width: 136px;
  }
`;

export const StyledTableRow = withTheme()(styled(TableRow)`
  && {
    background-color: ${(props: ThemeProps & { selected: boolean }) =>
      props.selected ? props.theme.palette.tableCell.dark : props.theme.palette.background.paper} !important;
    height: 56px;
    margin-left: 24px;

    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.background.pale} !important;
      cursor: pointer;
    }
  }
`);

export const LoadTableRow = withTheme()(styled(TableRow)`
  && {
    background-color: ${(props: ThemeProps & { selected: boolean }) =>
      props.selected ? props.theme.palette.tableCell.dark : props.theme.palette.background.paper} !important;
    overflow: hidden;
    min-height: 0px;
    height: 0px;
  }
`);

export const ExpandableTableRow = withTheme()(styled(LoadTableRow)`
  &:hover {
    background-color: ${(props: ThemeProps & { expanded: boolean }) =>
      props.theme.palette.background.pale + (props.expanded ? '' : ' !important')};
    cursor: pointer;
  }
`);

export const RelativeRowDiv = styled.div`
  position: relative;
`;

export const ExpandableIconDiv = styled.div`
  && {
    margin-top: 30px;
    margin-left: -24px;
    width: 48px;
    height: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoListDiv = styled.div`
  position: absolute;
  width: 200%;
  opacity: ${(props: { width: number; expanded: boolean }) => (props.expanded ? 0 : 1)};
  pointer-events: ${(props: { width: number; expanded: boolean }) => (props.expanded ? 'none' : 'auto')};
  z-index: 100;
  top: 49px;
  left: 0px;
  overflow-y: hidden;
  overflow-x: visible;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  max-height: 37px;
  box-sizing: border-box;
  transition: opacity 0.6s ease;

  @media (min-width: 465px) {
    margin-left: 8px;
  }
`;

export const InfoListSubDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
  width: 30%;
  @media (min-width: 650px) and (max-width: 1024px) {
    width: 20%;
  }
  @media (max-width: 465px) {
    width: 35%;
  }
`;

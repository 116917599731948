import React from 'react';

import { Icon, IconOptions, LatLng, Marker as LeafletMarker } from 'leaflet';
import { MapLayer, MapLayerProps, MarkerProps } from 'react-leaflet';
import { LeafletProvider } from 'react-leaflet/es/context';

import { withLeaflet } from './WithLeaflet';

/** This React-Leaflet-based class is for connecting data between the React and Leaflet world through a Marker.
 * This is meant to be used to customize clustered markers.
 */

/** this is the React side */
export interface MarkerWithExtraDataProps<T> extends MarkerProps {
  extraData: T;
}

/** this is the Leaflet side */
export interface MarkerExtraData<T> {
  extraData?: T;
}

interface AddedProps extends MapLayerProps {
  icon?: Icon<IconOptions>;
  draggable?: boolean;
  opacity?: number;
  position: LatLng;
  zIndexOffset?: number;
}

type Props<T> = AddedProps & MarkerExtraData<T>;

class MarkerWithDataComponent<T> extends MapLayer<Props<T>, LeafletMarker> {
  private contextValue: {};
  createLeafletElement(props: Props<T>): LeafletMarker {
    const el = new LeafletMarker(props.position, this.getOptions(props));
    this.contextValue = { ...(props as any).leaflet, popupContainer: el };
    (el as any).extraData = this.props.extraData;
    return el;
  }

  updateLeafletElement(fromProps: Props<T>, toProps: Props<T>) {
    if (toProps.position !== fromProps.position) {
      this.leafletElement.setLatLng(toProps.position);
    }
    if (toProps.icon !== fromProps.icon) {
      // biome-ignore lint/style/noNonNullAssertion: bad third-party types
      this.leafletElement.setIcon(toProps.icon!);
    }
    if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
      // biome-ignore lint/style/noNonNullAssertion: bad third-party types
      this.leafletElement.setZIndexOffset(toProps.zIndexOffset!);
    }
    if (toProps.opacity !== fromProps.opacity) {
      // biome-ignore lint/style/noNonNullAssertion: bad third-party types
      this.leafletElement.setOpacity(toProps.opacity!);
    }
    if (toProps.draggable !== fromProps.draggable) {
      if (toProps.draggable === true) {
        // biome-ignore lint/style/noNonNullAssertion: bad third-party types
        this.leafletElement.dragging!.enable();
      } else {
        // biome-ignore lint/style/noNonNullAssertion: bad third-party types
        this.leafletElement.dragging!.disable();
      }
    }
    if (toProps.extraData !== fromProps.extraData) {
      (this.leafletElement as any).extraData = toProps.extraData;
      // force a cluster icon refresh
      if ((this.leafletElement as any).__parent) {
        (this.leafletElement as any).__parent._group.refreshClusters(this.leafletElement);
      }
    }
  }

  render() {
    const { children } = this.props;
    return children == null || this.contextValue == null ? null : (
      <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
    );
  }
}

export const MarkerWithData = withLeaflet(MarkerWithDataComponent);

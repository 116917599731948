import React, { RefObject, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { treatZeroAsUndefined } from '@common/helper';
import { Load, Phone } from '@common/model';
import { clearEmailSendingState, LoadInfo } from '@common/redux/epic/LoadInfoEpic';
import { EmailConfirmPopup } from '@component/panels/basePanel/loadDetails/EmailPopup';
import { LoadContactCallbackProps } from '@component/panels/findLoads/loadDetailsPanel/buttons/ContactButtons';
import { ApiErrorPopup } from '@component/popup/ApiErrorPopup';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { FontSize, Opacity } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { formatNumberOrDash, formatStringOrDash } from '@util/Formatter';

import { MoreInfoContactButtons, MoreInfoContactData } from './LoadDetailsMoreInfoContact';

// @FIXME: Refactor component so there is a base, broker version, and carrier version instead of isBroker flag
type ConditionalLoadContactCallbackProps =
  | { isBroker: true }
  | ({ isBroker: false; onVhubClick: () => void } & LoadContactCallbackProps);

type ContactProps = {
  dispatchName?: string;
  dispatchPhone?: Phone;
  dispatchEmail?: string;
  loadDetails?: Load;
  isOriginMessaging?: boolean;
  isOriginBids?: boolean;
} & ConditionalLoadContactCallbackProps;

type Props = {
  commodity?: string;
  postReference?: string;
  numberOfLoads?: number;
  notes?: string;
  numberOfStops?: number;
  commodityLabel?: string;
  loadInfo?: LoadInfo;
  customFontSize?: FontSize;
  notesRef: RefObject<HTMLDivElement>;
  teamDriving?: string;
} & ContactProps;

interface InformationSectionProps {
  id: string;
  title?: string;
  value: string;
}

const MoreDetailsWrapper = withTheme()(styled.div`
  font-size: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontSize};

  span {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
      color: ${(props: ThemeProps) => props.theme.palette.secondary.main};
    }
  }
`);

const MoreTitle = styled.span`
  opacity: ${Opacity.translucent};
  font-weight: bold;
  font-size: ${FontSize.ContentDetail}px;
`;

export const Label = styled.span`
  font-size: ${FontSize.ContentDetail}px;
`;

export const DataGrid = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
`;

const Data = styled.span`
  font-weight: bold;
  word-break: break-word;
`;

const NoteData = styled(Data)`
  white-space: pre-wrap;
  word-break: normal;
  word-wrap: break-word;
`;

const ContactInfoData = styled.span`
  margin-bottom: 3px;
`;

const Row: React.FC<InformationSectionProps> = ({ id, title, value }) => (
  <>
    <Label id={`${id}_label`}>{title}</Label>
    <Data id={`${id}_value`}>{value}</Data>
  </>
);

const Note: React.FC<InformationSectionProps & { noteRef?: RefObject<HTMLDivElement> }> = ({
  id,
  title,
  value,
  noteRef,
}) => (
  <>
    <Label id={`${id}_label`} innerRef={noteRef}>
      {title}
    </Label>
    <NoteData id={`${id}_value`}>{value}</NoteData>
  </>
);

const Contacts: React.FC<ContactProps> = (props) => (
  <>
    <Label id="contact_label">{t(T.Contact)}</Label>
    <Data id="contact_value" style={{ marginBottom: 0 }}>
      <ContactInfoData>{props.dispatchName}</ContactInfoData>
      {props.isBroker ? (
        <MoreInfoContactData
          load={props.loadDetails}
          dispatchPhone={props.dispatchPhone}
          dispatchEmail={props.dispatchEmail}
        />
      ) : (
        <MoreInfoContactButtons
          load={props.loadDetails}
          isOriginMessaging={!!props.isOriginMessaging}
          isOriginBids={!!props.isOriginBids}
          onVhubClick={props.onVhubClick}
          onInteractiveBidClick={props.onInteractiveBidClick}
          onVendorBidClick={props.onVendorBidClick}
          onCallClick={props.onCallClick}
          onEmailClick={props.onEmailClick}
          onBookNowClick={props.onBookNowClick}
        />
      )}
    </Data>
  </>
);

export const LoadDetailsMoreInfo: React.FC<Props> = (props) => {
  const popupContext = usePopup();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.loadInfo?.isEmailSentSuccessfully && props.loadDetails) {
      popupContext.openingPopup({
        body: <EmailConfirmPopup companyName={props.loadDetails?.poster?.name} loadID={props.loadDetails.id} />,
        width: PopupSizes.LARGE,
        isDismissible: false,
      });
    }
  }, [props.loadInfo?.isEmailSentSuccessfully]);

  const commodity = formatStringOrDash(props.commodity);
  const isTeamDriven = props.loadDetails?.teamDriving;
  const commodityLabel = props.commodityLabel;
  const postReference = formatStringOrDash(props.postReference);
  const qty = formatNumberOrDash(treatZeroAsUndefined(props.numberOfLoads));
  const stops = formatNumberOrDash(treatZeroAsUndefined(props.numberOfStops));
  const notes = formatStringOrDash(props.notes);
  const dispatchName = formatStringOrDash(props.dispatchName);

  const renderEmailApiErrorPopup = () => {
    if (props.loadDetails) {
      const loadID = props.loadDetails.id;
      return (
        <ApiErrorPopup
          error={props.loadInfo?.emailSendingError}
          onClose={() => dispatch(clearEmailSendingState(loadID))}
        />
      );
    }
    return null;
  };

  const contactCallbacksProps: ConditionalLoadContactCallbackProps = props.isBroker
    ? { isBroker: true }
    : {
        isBroker: false,
        onVhubClick: props.onVhubClick,
        onCallClick: props.onCallClick,
        onEmailClick: props.onEmailClick,
        onInteractiveBidClick: props.onInteractiveBidClick,
        onVendorBidClick: props.onVendorBidClick,
        onBookNowClick: props.onBookNowClick,
      };

  return (
    <MoreDetailsWrapper style={{ fontSize: props.customFontSize ?? FontSize.Content }}>
      <MoreTitle id="more_details">{t(T.common_load_More_details)}</MoreTitle>
      <DataGrid>
        <Contacts
          dispatchName={dispatchName}
          dispatchPhone={props.dispatchPhone}
          dispatchEmail={props.dispatchEmail}
          loadDetails={props.loadDetails}
          isOriginMessaging={props.isOriginMessaging}
          isOriginBids={props.isOriginBids}
          {...contactCallbacksProps}
        />
        <Row id="item" value={commodity} title={commodityLabel} />
        {isTeamDriven ? <Row id="team_driven" value={t(T.common_yes)} title={t(T.postLoads_TeamDriven)} /> : null}
        <Row id="ref" value={postReference} title={t(T.common_load_Ref)} />
        {props.teamDriving ? (
          <Row id="team_driving" value={props.teamDriving} title={t(T.postLoads_TeamDriven)} />
        ) : null}
        <Row id="qty" value={qty} title={t(T.common_load_Qty)} />
        <Row id="stops" value={stops} title={t(T.common_load_ExtraStops)} />
        <Note id="note" value={notes} title={t(T.findLoads_loadDetails_loadNote)} noteRef={props.notesRef} />
      </DataGrid>
      {renderEmailApiErrorPopup()}
    </MoreDetailsWrapper>
  );
};

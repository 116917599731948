import React, { useEffect, useMemo, useState } from 'react';

import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { displayCurrencyOrDash } from '@common/formatter';
import {
  formatSecondsToDuration,
  getFlatRateAmount,
  getPricePerMileRate,
  getRevenue,
  getStringifiedDisplayEquipmentList,
  getStringifiedEquipmentSpecName,
  isCanadianState,
  isEquipmentTypeSupportedForRateCheck,
  treatZeroAsUndefined,
} from '@common/helper';
import { Config, Equipment, Flag, Load } from '@common/model';
import { fetchUser } from '@common/redux/epic/UserEpic';
import { getProfitPercentage } from '@common/util/CostPerDayHelper';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import {
  CostPerDayButton,
  CostPerDayNotAvailableBadge,
} from '@component/panels/basePanel/loadDetails/costPerDay/CostPerDayUIHelper';
import { DrivingTimeInfo } from '@component/panels/basePanel/loadDetails/generalInfo/DrivingTimeInfo';
import { CargoChiefRateInfo } from '@component/panels/basePanel/loadDetails/generalInfo/marketRates/CargoChiefRateInfo';
import { ExpediteAllRateInfo } from '@component/panels/basePanel/loadDetails/generalInfo/marketRates/ExpediteAllRateInfo';
import { GreenscreensRateInfo } from '@component/panels/basePanel/loadDetails/generalInfo/marketRates/GreenscreensRateInfo';
import { RateCheckInfo } from '@component/panels/basePanel/loadDetails/generalInfo/marketRates/RateCheckInfo';
import { useShouldDisplayExpediteAll } from '@component/panels/basePostPanel/PostLoadExpediteAllRateHelper';
import { Color } from '@style/Color';
import { FontSize, Opacity, Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { T, t } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';
import { formatNumberOrDash, formatStringOrDash } from '@util/Formatter';
import { useCostPerDay } from '@util/helper/CostPerDayHelper';
import { useSelector } from '@util/hooks';

interface Props {
  id: string;
  loadDetails?: Load;
  isBrokerUi?: boolean;
  profitPerDay?: number;
  openProfitCalculator?: () => void;
}

interface DetailRowProps {
  id: string;
  title: string;
  value: string | React.ReactElement;
  subValue?: string;
  valueStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

interface LoadDetailsData {
  rate: number | undefined;
  ratePerMile: string;
  distance: string;
  loadsize: string;
  weight: string;
  length: string;
  equipments: Equipment[] | undefined;
  drivingTime: string;
}

const LoadDetailsDataDefault = {
  rate: undefined,
  ratePerMile: NO_INFORMATION_SYMBOL,
  distance: NO_INFORMATION_SYMBOL,
  loadsize: NO_INFORMATION_SYMBOL,
  weight: NO_INFORMATION_SYMBOL,
  length: NO_INFORMATION_SYMBOL,
  equipments: undefined,
  drivingTime: NO_INFORMATION_SYMBOL,
};

const RowGroup = styled.div`
  padding-bottom: ${Spacing.ElementPaddingVertical}px;
`;

const Row = styled.div`
  display: flex;
  padding-bottom: ${Spacing.InterElementVertical}px;
  flex-wrap: nowrap;
`;

const Data = styled.div`
  flex: 2;
  font-weight: bold;
`;

const SubData = styled.div`
  flex: 2;
  font-size: ${FontSize.ContentDetail}px;
  margin-top: 1px;
`;

const Label = styled.div`
  width: 35%;
  font-size: ${FontSize.ContentDetail}px;
`;

const RowWithBorder = styled.div`
  padding: ${Spacing.ScreenSide}px ${Spacing.LargePaddingHorizontal}px;
  border-bottom: 1px solid ${THEME.palette.background.smoke};
`;

const MarketRateTitle = styled.div`
  opacity: ${Opacity.translucent};
  font-weight: bold;
  font-size: ${FontSize.ContentDetail}px;
  padding-bottom: 16px;
`;

export const DetailRow: React.FC<DetailRowProps> = ({ id, title, value, subValue, valueStyle, labelStyle }) => {
  return (
    <Row>
      <Label id={`${id}_label`} style={labelStyle}>
        {title}
      </Label>
      <Data id={`${id}_value`} style={valueStyle}>
        {value}
      </Data>
      {subValue ? <SubData id={`${id}_subValue`}>{subValue}</SubData> : null}
    </Row>
  );
};

const TrailerDetailRow: React.FC<DetailRowProps> = ({ id, title, value, labelStyle }) => {
  return (
    <Row>
      <Label id={`${id}_label`} style={labelStyle}>
        {title}
      </Label>
      <Data id={`${id}_value`} style={{ lineHeight: '22px' }}>
        {value}
      </Data>
    </Row>
  );
};

export const LoadDetailsInfoBar: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const costPerDay = useCostPerDay();
  const [loadDetailData, setLoadDetailData] = useState<LoadDetailsData>(LoadDetailsDataDefault);

  const user = useSelector((state) => state.user.profile?.payload);

  const isCargoChiefDisabled = useSelector((state) => state.settings.systemSetting[Flag.CargoChiefUserDisabled].value);
  const isGreenScreenRateDisabled = useSelector(
    (state) => state.settings.systemSetting[Flag.GreenscreensRateDisabled].value
  );
  const loadDetails = useSelector((state) => state.loadInfo.loadInfoMap.get(props.loadDetails?.id ?? ''));
  const isLoadingFuelPrice = loadDetails?.isLoadingFuelPrice;
  const isLoadingRouteInfo = loadDetails?.isLoadingRouteInfo;
  const isLoadingRateCheck = loadDetails?.isLoadingRateCheck;
  const equipmentTypes = useMemo(
    () => map(loadDetailData.equipments, (item) => item.equipmentType),
    [loadDetailData.equipments]
  );
  const { isEnabled, isExpediteAllType } = useShouldDisplayExpediteAll(equipmentTypes);

  const isLoadingMpgSettings = useSelector((state) => state.settings.isLoading);

  const isLoadingCostPerDaySettings = useSelector(
    (state) =>
      state.settings.isLoadingSystemSetting || state.settings.systemSetting[Config.CostPerDaySettings].isLoading
  );

  const isLoading =
    isLoadingFuelPrice ||
    isLoadingRouteInfo ||
    isLoadingRateCheck ||
    isLoadingMpgSettings ||
    isLoadingCostPerDaySettings;

  const loadInfo = useSelector((state) =>
    props.loadDetails ? state.loadInfo.loadInfoMap.get(props.loadDetails.id) : undefined
  );

  const drivingTime = useMemo(
    () => (loadInfo?.routeInfo ? formatSecondsToDuration(loadInfo.routeInfo.driveTime) : NO_INFORMATION_SYMBOL),
    [loadInfo?.routeInfo]
  );

  const hasRate = useMemo(
    () => (props.loadDetails ? !!getRevenue(props.loadDetails, loadInfo?.rateCheck?.payload?.linehaulRevenue) : false),
    [props.loadDetails, loadInfo?.rateCheck?.payload?.linehaulRevenue]
  );

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, []);

  useEffect(() => {
    if (props.loadDetails) {
      const numericPostedRate = getPricePerMileRate(props.loadDetails);
      let distance;
      //                  no computed      computed = 0      computed > 0
      // ----------------------------------------------------------------------
      // no user    |       -              computed mi       computed mi
      //            |
      // user = 0   |      user mi         computed mi       computed mi
      //            |
      // user > 0   |      user mi          user mi           user mi
      if (props.loadDetails.computedMileage == null && props.loadDetails.mileage == null) {
        distance = NO_INFORMATION_SYMBOL;
      } else if (
        props.loadDetails.computedMileage != null &&
        props.loadDetails.computedMileage >= 0 &&
        (props.loadDetails.mileage == null || props.loadDetails.mileage === 0)
      ) {
        distance = t(T.unit_mi, { value: props.loadDetails.computedMileage });
      } else {
        distance = t(T.unit_mi, { value: props.loadDetails.mileage });
      }

      setLoadDetailData({
        ...loadDetailData,
        rate: getFlatRateAmount(props.loadDetails),
        ratePerMile: numericPostedRate
          ? displayCurrencyOrDash(numericPostedRate, true, {
              suffix: t(T.common_per_miles_short),
            })
          : '',
        distance: distance,
        loadsize: formatStringOrDash(props.loadDetails.loadSize),
        weight: formatNumberOrDash(treatZeroAsUndefined(props.loadDetails.weight), ` ${t(T.unit_pounds)}`),
        length: formatNumberOrDash(treatZeroAsUndefined(props.loadDetails.length), ` ${t(T.unit_feet)}`),
        equipments: props.loadDetails.equipments,
        drivingTime: drivingTime,
      });
    }
  }, [props.loadDetails, drivingTime]);

  const isEquipmentTypeSupported = isEquipmentTypeSupportedForRateCheck(loadDetailData.equipments);
  const shouldShowExpediteAll = props.isBrokerUi && isExpediteAllType && isEnabled;

  const renderCostPerDaySection = () => {
    if (costPerDay.enabled && !props.isBrokerUi) {
      if (isLoading) {
        return (
          <div style={{ margin: Spacing.ScreenSide, width: '100%', textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        );
      }
      if (!hasRate && (props.profitPerDay === undefined || props.profitPerDay <= 0)) {
        return <CostPerDayNotAvailableBadge onClick={props.openProfitCalculator} />;
      }
      return (
        <CostPerDayButton
          isSetupComplete={costPerDay.isSetupComplete}
          percentage={props.profitPerDay ? getProfitPercentage(costPerDay.value, props.profitPerDay) : 0}
          onClick={props.openProfitCalculator}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderCostPerDaySection()}
      <RowWithBorder style={{ paddingBottom: 12 }}>
        <DetailRow
          id="posted_rate"
          title={t(T.shareLoad_loadInformation_Posted_Rate)}
          value={displayCurrencyOrDash(loadDetailData.rate, false)}
          subValue={loadDetailData.ratePerMile}
          valueStyle={loadDetailData.rate ? { color: Color.GREEN_MAIN } : undefined}
        />
      </RowWithBorder>
      <RowWithBorder>
        <MarketRateTitle id="market_rate">{t(T.common_marketRate_title)}</MarketRateTitle>
        {shouldShowExpediteAll ? (
          <ExpediteAllRateInfo />
        ) : (
          <RateCheckInfo
            computedMileage={props.loadDetails?.computedMileage}
            rateCheckAvgRatePerMile={props.loadDetails?.rateCheck?.avgRatePerMile}
            isEquipmentTypeSupported={isEquipmentTypeSupported}
            rateCheckPreview={props.loadDetails?.rateCheckPreview}
          />
        )}
        {!props.isBrokerUi && !isCargoChiefDisabled ? (
          <CargoChiefRateInfo
            cargoChiefRate={loadDetails?.cargoChiefValues?.payload}
            isLoading={loadDetails?.isLoadingCargoChiefRate}
            isEquipmentTypeSupported={isEquipmentTypeSupported}
            isLoadLocationCanada={
              isCanadianState(props.loadDetails?.originLocation?.address?.state ?? '') ||
              isCanadianState(props.loadDetails?.destinationLocation?.address?.state ?? '')
            }
          />
        ) : null}
        {!props.isBrokerUi && !isGreenScreenRateDisabled ? (
          <GreenscreensRateInfo
            isLoading={loadDetails?.isLoadingGreenscreensRate}
            greenscreensRate={loadDetails?.greenscreensValues?.payload}
            isEquipmentTypeSupported={isEquipmentTypeSupported}
          />
        ) : null}
      </RowWithBorder>
      <RowWithBorder>
        <RowGroup>
          <DetailRow id="distance" title={t(T.common_load_Distance)} value={loadDetailData.distance} />
          <DrivingTimeInfo drivingTime={loadDetailData.drivingTime} />
        </RowGroup>
        <RowGroup>
          <TrailerDetailRow
            id="trailerType"
            title={t(T.postLoads_TrailerType)}
            value={getTrailerData(loadDetailData.equipments)}
            labelStyle={{ placeSelf: 'start start' }}
          />
        </RowGroup>
        <DetailRow id="loadSize" title={t(T.loadGeneric_loadSize)} value={loadDetailData.loadsize} />
        <DetailRow id="length" title={t(T.common_load_Length)} value={loadDetailData.length} />
        <DetailRow id="weight" title={t(T.common_load_Weight)} value={loadDetailData.weight} />
      </RowWithBorder>
    </>
  );
};

const getTrailerData = (equipments: Equipment[] | undefined) => {
  return equipments && equipments.length > 0 ? (
    <>
      {map(equipments, ({ equipmentType, equipmentSpecifications }, index) => {
        const formattedEquipmentSpecifications = getStringifiedEquipmentSpecName(equipmentSpecifications);
        return (
          <span id="value" key={equipmentType}>
            <span>{getStringifiedDisplayEquipmentList(equipmentType)}</span>
            {formattedEquipmentSpecifications ? (
              <span style={{ fontWeight: 'normal' }}> ({formattedEquipmentSpecifications})</span>
            ) : null}
            {index !== equipments.length - 1 ? <br style={{ marginBottom: '10px' }} /> : null}
          </span>
        );
      })}
    </>
  ) : (
    <span id="value">{'-'}</span>
  );
};

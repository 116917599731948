import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { Link } from '@material-ui/core';

import { Color } from '@style/Color';
import { FontFamily, FontSize, Spacing } from '@style/StyleConstants';

interface DivProps {
  width?: string;
  margin?: string;
}
export const SETTINGS_PANEL_PADDING = '0 6px';
export const LoadingSpinnerOverlayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 650px;
`;

export const StyledGrid = styled.div<DivProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${({ margin }) => `${margin ? margin : Spacing.InterSection}px`};
`;

export const StyledGridNote = styled.div<DivProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  width: ${(props: DivProps) => `${props.width}px` || '100%'};
`;

export const StyledGridNoteCentered = styled(StyledGridNote)`
  align-items: center;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  & > :first-child {
    margin-right: 15px !important;
  }
`;

export const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${Spacing.InterSection}px;
  min-width: 158px;
`;

export const TextLink = styled(Link)`
  && {
    font-size: 11px;
    font-family: 'Overpass' 'sans-serif';
    font-weight: normal;
    color: ${Color.ORANGE_MAIN};
    text-align: center;
    cursor: pointer;
  }
`;

export const StatusMessage = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  margin: 35px 15px;
`;

export const SectionMessageTitle = styled.span`
  font-size: ${FontSize.ModalTitle}px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  color: ${Color.BLACK};
  margin: 25px;
`;

export const SectionMessage = styled.span`
  font-size: ${FontSize.ContentDetail}px;
  margin: 0px 10px 25px 0px;
  display: flex;
  justify-content: center;
  color: ${Color.GRAY_DARK};
`;

export const StyledMarkDown = styled(Markdown)`
  font-family: ${FontFamily.Main};
  color: ${Color.GRAY_DARK};
  margin: 16px 32px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  & h1 {
    font-size: ${FontSize.Header}px;
  }
  & h2 {
    font-size: ${FontSize.ModalTitle}px;
    margin-bottom: -16px;
  }
  & a {
    color: ${Color.ORANGE_MAIN};
  }
  & p {
    font-size: ${FontSize.Content}px;
  }
  & li {
    font-size: ${FontSize.Content}px;
    margin-bottom: ${Spacing.ScreenSide}px;
  }
  & hr {
    background-color: ${Color.GRAY_DARK};
    height: 1;
  }
  & table {
    margin: ${Spacing.ScreenSide};
    border-width: 1;
    border-color: ${Color.BLACK};
    max-width: 320px;
  }
  & code {
    max-width: 320px;
  }
`;

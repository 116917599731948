import { useEffect, useState } from 'react';

import { findIndex, map } from 'lodash';

/** This hook makes it easy to utilize media queries in your component logic.
 *
 * The example below renders a different number of columns depending on which media query
 * matches the current screen width. The first one to match in the list wins.
 *
 * const columnCount = useMedia(
 *   // Media queries
 *   ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'],
 *   // Column counts (relates to above media queries by array index)
 *   [5, 4, 3],
 *   // Default column count
 *   2
 * );
 *
 * Original code from https://usehooks.com/useMedia
 */
export const useMediaQuery = <T>(queries: string[], values: T[], defaultValue: T) => {
  // Array containing a media query list for each query
  const mediaQueryLists = map(queries, (q) => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = findIndex(mediaQueryLists, (mql) => mql.matches);
    // Return related value or defaultValue if none
    return index >= 0 ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach((mql) => mql.addListener(handler));
      // Remove listeners on cleanup
      return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    },
    [] // Empty array ensures effect is only run on mount and unmount
  );

  return value;
};

import { clone, includes, keys, reduce } from 'lodash';
import { Action } from 'redux';
import { PersistConfig } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { createReducer } from '../ReduxHelper';

const UPDATE_UNDER_DEVELOPMENT_FLAG_STATUSES = 'UPDATE_UNDER_DEVELOPMENT_FLAG_STATUSES';

export interface UnderDevelopmentFlagUpdate {
  flag: string;
  isEnabled: boolean;
}

interface UpdateFlagsStatusAction extends Action {
  flagUpdates: UnderDevelopmentFlagUpdate[];
}

export interface UnderDevelopmentState {
  flags: { [flag: string]: boolean };
}

const initialUnderDevelopmentState = (availableFlags: string[]): UnderDevelopmentState => ({
  flags: reduce(
    availableFlags,
    (accFlags, availableFlag) => {
      accFlags[availableFlag] = false;
      return accFlags;
    },
    {} as { [key: string]: boolean }
  ),
});

/**
 * Since flag values may change between releases, if the user has a flag X persisted
 * from a previous release, we do not want to pull it into the current flags state
 * where X is no longer a valid flag. This state reconciler wraps the redux-persist
 * one and simply filters the inbound (persisted) state for valid flags only before
 * passing it to the default reconciler.
 * @param availableFlags List of currently valid feature flags
 */
const createUnderDevelopmentStateReconciler =
  (availableFlags: string[]) =>
  (
    inboundState: UnderDevelopmentState,
    originalState: UnderDevelopmentState,
    reducedState: UnderDevelopmentState,
    persistConfig: PersistConfig<UnderDevelopmentState>
  ) => {
    const filteredInboundState = {
      ...inboundState,
      flags: reduce(
        keys(inboundState.flags),
        (validPersistedFlags, currentPersistedFlag) => {
          if (includes(availableFlags, currentPersistedFlag)) {
            return {
              ...validPersistedFlags,
              // older versions of the app stored these flags with numbers, so
              // this conversion ensures we pull booleans.
              [currentPersistedFlag]: !!inboundState.flags[currentPersistedFlag],
            };
          }
          return validPersistedFlags;
        },
        {}
      ),
    };
    return autoMergeLevel2(filteredInboundState, originalState, reducedState, persistConfig);
  };

export const createUnderDevelopmentReducer = (availableFlags: string[]) => {
  const reducer = createReducer(initialUnderDevelopmentState(availableFlags), {
    [UPDATE_UNDER_DEVELOPMENT_FLAG_STATUSES]: (state: UnderDevelopmentState, action: UpdateFlagsStatusAction) => {
      const existingFlags = clone(state.flags);
      state.flags = reduce(
        action.flagUpdates,
        (flags, flagUpdate) => {
          flags[flagUpdate.flag] = flagUpdate.isEnabled;
          return flags;
        },
        existingFlags
      );
    },
  });

  const stateReconciler = createUnderDevelopmentStateReconciler(availableFlags);

  const actions = {
    updateUnderDevelopmentFlagStatuses: (flagUpdates: UnderDevelopmentFlagUpdate[]): UpdateFlagsStatusAction => ({
      type: UPDATE_UNDER_DEVELOPMENT_FLAG_STATUSES,
      flagUpdates: flagUpdates,
    }),
  };

  return {
    reducer: reducer,
    actions: actions,
    stateReconciler: stateReconciler,
  };
};

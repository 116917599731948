import { forEach, isEmpty } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { VendorBidsClient } from '@common/client/VendorBidsClient';
import { VendorBid, VendorBidsResponse } from '@common/model/VendorBid';
import { createAction, createApiActionWithFetchData } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const getVendorBidsAction = createApiActionWithFetchData<string | undefined, VendorBidsResponse>('GET_VENDOR_BIDS');
const addVendorBidAction = createAction<VendorBid>('ADD_VENDOR_BID');

export const fetchVendorBids = () => getVendorBidsAction.fetchAction(undefined);
export const fetchMoreVendorBids = (nextToken: string) => getVendorBidsAction.fetchAction(nextToken);

export const addVendorBid = addVendorBidAction.action;

export interface VendorBidsState {
  isLoading: boolean;
  bids: VendorBid[];
  didFailToLoad: boolean;
  isLastPage: boolean;
  nextToken?: string;
}

const initialState: VendorBidsState = {
  isLoading: false,
  bids: [],
  didFailToLoad: false,
  isLastPage: false,
  nextToken: undefined,
};

export const vendorBidsReducer = createMergedReducer(initialState, [
  getVendorBidsAction.initiateCase((state, action) => {
    state.nextToken = action.data;
    state.isLoading = true;
  }),
  getVendorBidsAction.completeCase((state, action) => {
    state.isLoading = false;

    if (action.response.success && action.response.payload) {
      state.didFailToLoad = false;
      state.isLastPage = !action.response.payload.hasMore || isEmpty(action.response.payload.bids);
      state.nextToken = action.response.payload.token;
      if (action.fetchData) {
        // process pagination result
        forEach(action.response.payload.bids, (bid) => {
          state.bids.push(bid);
        });
      } else {
        // process initial result
        state.bids = action.response.payload.bids;
      }
    } else {
      state.didFailToLoad = true;
    }
  }),
  addVendorBidAction.addCase((state, action) => {
    if (!isEmpty(state.bids)) {
      state.bids = [action.data, ...state.bids];
    }
  }),
]);

export const createVendorBidsEpic = (api: Api) => {
  const client = new VendorBidsClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(getVendorBidsAction.createEpic$(action$, client.getVendorBids$));
};

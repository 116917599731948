import React from 'react';

import styled from 'styled-components';

import { LoadDetailsTabs } from '@/model';
import { displayCurrencyOrDash } from '@common/formatter';
import { isCanadianState, isEquipmentTypeSupportedForRateCheck } from '@common/helper';
import { Flag, Load, MembershipType } from '@common/model';
import { defaultLoadInfo, LoadInfo } from '@common/redux/epic/LoadInfoEpic';
import { AccordionInfoStatus, ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { CargoChiefRate } from '@component/panels/basePanel/loadDetails/marketRates/CargoChiefRate';
import { GreenscreensRate } from '@component/panels/basePanel/loadDetails/marketRates/GreenscreensRate';
import { default as RateIcon } from '@component/panels/basePanel/loadDetails/marketRates/rate-check.svg';
import { RateCheckPanel } from '@component/panels/basePanel/loadDetails/marketRates/RateCheckPanel';
import { ExpansionPanelDataWrapper } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { Text, TextStyle } from '@component/text/Text';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';

interface ComponentProps {
  loadInfo: LoadInfo | undefined;
  load: Load | undefined;

  handleChange(tab: LoadDetailsTabs, status: boolean): void;

  isOpened: boolean;
}

export const PageText = styled(Text)`
  display: flex;
  line-height: 21px;
  color: ${Color.LIGHT_BLACK};
  margin: 18px 19px 14px 16px;
`;

export const MarketRates: React.FC<ComponentProps> = (props) => {
  const isRateCheckAvailable = useSelector((state) => state.user.accesses.isRateCheckAvailable);
  const isCargoChiefRateAvailable = useSelector((state) => state.user.accesses.isCargoChiefRateAvailable);
  const isCargoChiefDisabled = useSelector((state) => state.settings.systemSetting[Flag.CargoChiefUserDisabled].value);
  const isCargoChiefAddOnAvailable = useSelector((state) => state.userPlan.membershipType !== MembershipType.Apple);
  const isGreenscreensRateDisabled = useSelector(
    (state) => state.settings.systemSetting[Flag.GreenscreensRateDisabled].value
  );
  const loadInfo =
    useSelector((state) => state.loadInfo.loadInfoMap.get(props.load ? props.load.id : '')) ?? defaultLoadInfo;
  const isEquipmentTypeSupported = isEquipmentTypeSupportedForRateCheck(props.load?.equipments);
  const cargoChiefRate = props.loadInfo?.cargoChiefValues?.payload;
  const greenscreensRate = props.loadInfo?.greenscreensValues?.payload;

  const getSubtitle = (): string => {
    if (!isEquipmentTypeSupported) {
      return t(T.common_marketRate_notAvailable);
    }

    let subtitle = t(T.common_marketRate_available);

    if (!isGreenscreensRateDisabled && greenscreensRate) {
      subtitle = t(T.common_marketRate_byGS, {
        minRate: displayCurrencyOrDash(greenscreensRate.costLowPerMile),
        maxRate: displayCurrencyOrDash(greenscreensRate.costHighPerMile),
      });
    }

    if (!isCargoChiefDisabled && isCargoChiefRateAvailable && cargoChiefRate) {
      const rate = displayCurrencyOrDash(
        cargoChiefRate.allInCost && cargoChiefRate.laneMiles
          ? cargoChiefRate.allInCost / cargoChiefRate.laneMiles
          : undefined
      );
      subtitle = t(T.common_marketRate_byCargoChief, { rate: rate });
    }

    if (isRateCheckAvailable) {
      const rate = displayCurrencyOrDash(loadInfo.rateCheck?.payload?.avgRatePerMile);
      subtitle = t(T.common_marketRate_byRateCheck, { rate: rate });
    }

    return subtitle;
  };

  const handleTabClick = () => {
    props.handleChange(LoadDetailsTabs.MarketRates, !props.isOpened);
  };

  const isLoading =
    loadInfo.isLoadingRateCheck || loadInfo.isLoadingCargoChiefRate || loadInfo.isLoadingGreenscreensRate;

  return (
    <ExpansionPanel
      id="market_rates"
      titleText={t(T.common_marketRate_title)}
      subtitleText={getSubtitle()}
      icon={RateIcon}
      isOpened={props.isOpened}
      handleClick={handleTabClick}
      status={loadInfo && isLoading ? AccordionInfoStatus.LOADING : AccordionInfoStatus.AVAILABLE}
    >
      <ExpansionPanelDataWrapper>
        <PageText id="content" textStyle={TextStyle.Default}>
          {t(T.common_marketRate_content)}
        </PageText>
        <RateCheckPanel load={props.load} loadInfo={props.loadInfo} isPermitted={isRateCheckAvailable} />
        {!isCargoChiefDisabled && isCargoChiefAddOnAvailable ? (
          <CargoChiefRate
            isPermitted={isCargoChiefRateAvailable}
            equipments={props.load?.equipments}
            cargoChiefRate={cargoChiefRate}
            isLoading={loadInfo.isLoadingCargoChiefRate}
            isLoadLocationCanada={
              isCanadianState(props.load?.originLocation?.address?.state ?? '') ||
              isCanadianState(props.load?.destinationLocation?.address?.state ?? '')
            }
          />
        ) : null}
        {!isGreenscreensRateDisabled ? (
          <GreenscreensRate
            greenscreensRate={greenscreensRate}
            equipments={props.load?.equipments}
            isLoading={loadInfo.isLoadingGreenscreensRate}
          />
        ) : null}
      </ExpansionPanelDataWrapper>
    </ExpansionPanel>
  );
};

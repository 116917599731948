import React, { Dispatch as ReactDispatch, SetStateAction } from 'react';

import { ListItemText, MenuItem } from '@material-ui/core';

import { default as LogoutIcon } from '@component/menu/icon/logout-icon.svg?react';
import { SvgContainer } from '@component/menu/menuStyles';
import { T, t } from '@translate';
import { AppConfig } from '@util/AppConfig';
import icons from '@util/iconsConstants';

export const MessagingWidgetMenuOptions = ({
  setMenuAnchor,
}: {
  setMenuAnchor: ReactDispatch<SetStateAction<undefined>>;
}) => {
  const logoutAndClose = () => () => {
    if (window.parent) {
      window.open(AppConfig.getLogoutLink(), '_blank');
      window.parent.postMessage('resize', '*');
    }
  };
  const openMembers = () => {
    setMenuAnchor(undefined);
    if (window.parent) {
      window.open(`${AppConfig.LBConfig.MembersRoot}/communication/`, '_blank');
    }
  };

  return (
    <>
      <MenuItem id="widget_option_members" key={'widget_open_members'} onClick={openMembers}>
        <img src={icons.logoOrange} alt={t(T.open123LB)} />
        <ListItemText id="open_members" primary={t(T.open123LB)} />
      </MenuItem>
      <MenuItem id="widget_option_logout" key={'widget_options_logout'} onClick={logoutAndClose()}>
        <SvgContainer className={'svg-container'}>
          <LogoutIcon />
        </SvgContainer>
        <ListItemText id="logout" primary={t(T.Logout)} />
      </MenuItem>
    </>
  );
};

interface CheckableDOTEntity {
  isChecked: boolean;
  source: string;
}

export interface DOTEntities {
  [id: string]: CheckableDOTEntity;
}

export interface NormalizedList {
  ui: string[];
  entities: DOTEntities;
}

export interface UpdateCheckedStatus {
  usdotNumbers: DOTEntities;
}

export interface ModifyOnboardedCarriersRequest extends OnboardedCarriers {
  invalidList: string[];
}

export interface OnboardedCarriers {
  usdotNumbers: string[];
}

export interface OnboardedCarrier {
  usdotNumber: string;
  source: string;
}

export interface OnboardedCarriersResponse {
  dotNumbers: OnboardedCarrier[];
  totalCount: number;
}

export const DEFAULT_SOURCE = 'Manual';

// map of "known" values
export const SOURCE = {
  Manual: '123Loadboard',
  MCP: 'MyCarrierPortal',
};

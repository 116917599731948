import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { SessionStates, updateScreenSharingSession } from '@common/redux/reducer/ScreenSharingReducer';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

import { ScreenSharingButton } from './ScreenSharingButton';
import {
  endSession,
  setSessionListeners,
  showConsentPrompt,
  startCobrowse,
  updateIdentifier,
} from './ScreenSharingHelper';

export const ScreenSharing: React.FC = () => {
  const { openingPopup, closingPopup } = usePopup();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.user.profile?.payload?.email);
  const userFirstName = useSelector((state) => state.user.profile?.payload?.firstName);
  const userLastName = useSelector((state) => state.user.profile?.payload?.lastName);
  const sessionState = useSelector((state) => state.screenSharing.session?.state);

  useEffect(() => {
    startCobrowse();
    setSessionListeners(updateSession);
  }, []);

  useEffect(() => {
    showConsentPrompt(openingPopup, closingPopup, startPopupText);
  }, []);

  useEffect(() => {
    const userName = `${userFirstName} ${userLastName}`;
    updateIdentifier(userEmail, userName);
  }, [userEmail, userFirstName, userLastName]);

  const startPopupText = {
    title: t(T.common_help_screenSharing_startSession_title),
    description: t(T.common_help_screenSharing_startSession_description),
    leftButtonText: t(T.common_help_screenSharing_startSession_reject),
    rightButtonText: t(T.common_help_screenSharing_startSession_accept),
  };

  const updateSession = (state: SessionStates) => {
    dispatch(updateScreenSharingSession({ state: state }));
  };

  const endScreenSharingSession = () => {
    dispatch(updateScreenSharingSession(undefined));
    endSession();
  };

  const shouldDisplayEndButton = () => {
    return sessionState === SessionStates.active;
  };

  return shouldDisplayEndButton() ? <ScreenSharingButton onEndSession={endScreenSharingSession} /> : null;
};

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { INITIAL_CONVERSATIONS_LIMIT, PAGINATION_CONVERSATIONS_LIMIT } from '@common/model/Conversation';
import { getConversationList } from '@common/redux/epic/CommunicationEpic';
import { ConversationListViewType } from '@page/communication/ConversationListFilter';
import { Conversations, useConversations } from '@page/communication/Conversations';
import { useSelector } from '@util/hooks';

import { LoadConversationsOriginUI } from './LoadConversations';

export interface Props {
  conversationId: string | undefined;
  scrollRef: React.RefObject<HTMLInputElement>;
  filter: ConversationListViewType;
  handleChange?: (filter: ConversationListViewType) => void;
  originUI: LoadConversationsOriginUI;
}

export const GlobalConversations: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const conversationsListIds = useSelector((state) => state.communication.conversationsListIds);
  const isLoading = useSelector((state) => state.communication.isLoadingConversations);
  const hasError = useSelector((state) => state.communication.didFailToLoadConversations);
  const conversations = useConversations(conversationsListIds);
  const token = useSelector((state) => state.communication.token);
  const isLastPage = useSelector((state) => state.communication.isLastPage) ?? false;

  useEffect(() => {
    dispatch(
      getConversationList(props.filter === ConversationListViewType.Unread, undefined, INITIAL_CONVERSATIONS_LIMIT)
    );
  }, [props.filter]);

  useEffect(() => {
    if (
      !isLastPage &&
      !isLoading &&
      props.conversationId &&
      conversationsListIds.indexOf(props.conversationId) === -1
    ) {
      loadMore();
    }
  }, [props.conversationId, conversationsListIds]);

  const loadMore = () => {
    if (token) {
      dispatch(
        getConversationList(props.filter === ConversationListViewType.Unread, token, PAGINATION_CONVERSATIONS_LIMIT)
      );
    }
  };

  return (
    <Conversations
      originUI={props.originUI}
      conversationId={props.conversationId}
      conversations={conversations}
      currentFilter={props.filter}
      isLoading={isLoading && token === undefined}
      hasError={hasError}
      isLastPage={isLastPage}
      scrollRef={props.scrollRef}
      handleChange={props.handleChange}
      loadMore={loadMore}
    />
  );
};

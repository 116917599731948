export enum AccountIssue {
  None = 'None',
  AccountExpiringSoon = 'AccountExpiringSoon',
  AccountExpired = 'AccountExpired',
}

export enum UpgradePlanPaymentResult {
  Success,
  Failed,
  Unknown,
}

export enum PaymentMethodUpdateError {
  InvalidExpiryDate = 'InvalidExpiryDate',
  InvalidSecurityCode = 'InvalidSecurityCode',
  AddressVerificationFailed = 'AddressVerificationFailed',
  InvalidCardNumber = 'InvalidCardNumber',
  Unknown = 'Unknown',
}

export enum DeclineStatus {
  None = 'None',
  SoftDecline = 'SoftDecline',
  HardDecline = 'HardDecline',
}

export enum PaymentType {
  CreditCard = 'CreditCard',
  None = 'None',
}

export enum MembershipReactivateError {
  WrongStatus = 'WrongStatus',
  ReactivationChargeInvoiceError = 'ReactivationChargeInvoiceError',
  Unknown = 'Unknown',
}

export type PaymentFormErrorType = PaymentMethodUpdateError | MembershipReactivateError;

export interface Period {
  date: string;
}

export interface PeriodResponse {
  periods: Period[];
}

export interface CreditCard {
  lastFour: string;
  declineStatus: DeclineStatus;
}

export interface PaymentDecline {
  accountIssue?: AccountIssue;
  creditCards?: CreditCard[];
  daysUntilAccountExpires?: number;
}

export interface PlanPaymentInfo extends PaymentInfo {
  planId: string;
  addOnIds?: string[];
}

export interface PlanCreditCardInfo {
  paymentType: PaymentType;
  creditCardName: string;
  creditCardNumber: string;
  creditCardCvd: string;
  expirationYear: number;
  expirationMonth: number;
}

export interface PaymentInfo {
  paymentInfo: PlanCreditCardInfo;
  address: { addressLine1?: string; city?: string; state?: string; country?: string; zipCode: string };
}

import { BaseClient } from '@common/client/BaseClient';
import {
  MileageRoutesPostRequest,
  MileageRoutesRequest,
  RouteDirections,
  RoutePoints,
  StateMileagePayload,
  UiSettingsPayload,
} from '@common/model';

export class MileageCalculatorClient extends BaseClient {
  getRoutesDirection$ = (data: MileageRoutesRequest) => this.api.get$<RouteDirections>('/routes/direction', data);

  //For the three endpoints (POST /routes/direction, /route/routePath, /routes/statemileage):
  //post that acts like a get to be used if we have stops, it returns the apporopriate data for the PC miler
  //isCustom flag is used to track the number of calls to the PcMiler data and count it as new request to the tool
  //isFollowUp will track the call to the api for the pcMiler but not count it as a new request to the tool
  postRouteDirection$ = (data: MileageRoutesPostRequest, isCustomRequest: boolean) =>
    this.api.post$<RouteDirections>(`/routes/direction?isCustomRequest=${isCustomRequest}`, data);
  postRoutePath$ = (data: { route: MileageRoutesPostRequest }) =>
    this.api.post$<RoutePoints>('/route/routePath?isFollowUp=true', data);
  postStateMileage$ = (data: MileageRoutesPostRequest) =>
    this.api.post$<StateMileagePayload>('/routes/statemileage?isFollowUp=true', data);

  getUiSettings$ = () => this.api.get$<UiSettingsPayload>('/settings/routes/UiSettings', undefined);
  patchUiSettings$ = (data: UiSettingsPayload) =>
    this.api.mergePatch$<UiSettingsPayload>('/settings/routes/UiSettings', data);
  getExportReport$ = (data: MileageRoutesPostRequest) => this.api.post$<string>('/routes/exportReport', data);
}

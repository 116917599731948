import React from 'react';

import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { Color } from '@style/Color';
import { FontLineHeight, FontSize } from '@style/StyleConstants';
import { t, T } from '@translate';

import { default as ArrowUpSVG } from './arrow-up.svg';

const TopButtonElement = styled(Button)`
  && {
    width: 44px;
    height: 44px;
    background-color: ${Color.GREEN_MAIN};
    border-radius: 22px;
    padding: 10px 0 4px 0;
    margin-left: 8px;
    min-width: unset;
    text-transform: none;
    border: 1px solid ${Color.GRAY_SMOKE};
    &:hover {
      background-color: ${Color.GREEN_HOVER};
    }
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: ${Color.WHITE};
  font-size: ${FontSize.SmallBody}px;
  line-height: ${FontLineHeight.SmallBody}px;
`;

interface Props {
  onClick: () => void;
}

export const TopButton: React.FC<Props> = (props) => (
  <TopButtonElement id="top" onClick={props.onClick}>
    <VerticalContainer>
      <img id="arrow_up" src={ArrowUpSVG} style={{ height: 14 }} />
      <Title id="title">{t(T.common_top)}</Title>
    </VerticalContainer>
  </TopButtonElement>
);

import { filter, findIndex } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { ShareableLoadsClient } from '@/client';
import {
  ShareableLoad,
  ShareableLoadList,
  ShareableLoadListResponse,
  ShareableLoadPatchRequest,
  ShareableLoadPostRequest,
  ShareableLoadView,
} from '@/model/URLScrape';
import { Api } from '@common/api';
import { createApiAction, createApiActionWithFetchData, EmptyResponse } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface URLScrapeState {
  shares: ShareableLoadView[];
  count: number;
  isLoadingShareableLoads: boolean;
  isPostingShareableLoads: boolean;
  isEditingShareableLoads: boolean;
  isDeletingShareableLoads: boolean;
  loads: ShareableLoad[];
  loadsCount?: number;
  isLoadingLoads?: boolean;
  isURLAddedSuccessfully?: boolean;
  isURLEditedSuccessfully?: boolean;
  isURLDeletedSuccessfully?: boolean;
}

interface PartialStoreState {
  urlScrape: URLScrapeState;
}

const fetchSharedBrokerLoadsAction = createApiAction<string, ShareableLoadList>('FETCH_SHARED_BROKER_LOADS');
const fetchShareableLoadsAction = createApiAction<{}, ShareableLoadListResponse>('FETCH_SHAREABLE_LOADS');
const postShareableLoadAction = createApiAction<ShareableLoadPostRequest, ShareableLoadView>('POST_SHAREABLE_LOAD');
const updateShareableLoadAction = createApiAction<ShareableLoadPatchRequest, ShareableLoadView>(
  'UPDATE_SHAREABLE_LOAD'
);
const deleteShareableLoadAction = createApiActionWithFetchData<string, EmptyResponse>('DELETE_SHAREABLE_LOAD');

export const fetchShareableLoads = () => fetchShareableLoadsAction.fetchAction({});
export const postShareableLoad = (request: ShareableLoadPostRequest) => postShareableLoadAction.fetchAction(request);
export const updateShareableLoad = (request: ShareableLoadPatchRequest) =>
  updateShareableLoadAction.fetchAction(request);
export const deleteShareableLoad = (id: string) => deleteShareableLoadAction.fetchAction(id);
export const fetchSharedBrokerLoads = (id: string) => fetchSharedBrokerLoadsAction.fetchAction(id);

const initialState: URLScrapeState = {
  shares: [],
  count: 0,
  isLoadingShareableLoads: false,
  isPostingShareableLoads: false,
  isEditingShareableLoads: false,
  isDeletingShareableLoads: false,
  loads: [],
};

export const urlScrapeReducer = createMergedReducer<URLScrapeState>(initialState, [
  fetchShareableLoadsAction.initiateCase((state) => {
    state.isLoadingShareableLoads = true;
  }),
  fetchShareableLoadsAction.completeCase((state, action) => {
    state.isLoadingShareableLoads = false;
    if (action.response.success) {
      state.shares = action.response.payload.shares;
      state.count = action.response.payload.count;
    }
  }),
  postShareableLoadAction.initiateCase((state) => {
    state.isPostingShareableLoads = true;
    state.isURLAddedSuccessfully = undefined;
  }),
  postShareableLoadAction.completeCase((state, action) => {
    if (action.response.success) {
      state.shares = [...state.shares, action.response.payload];
      state.count++;
    }
    state.isURLAddedSuccessfully = action.response.success;
    state.isPostingShareableLoads = false;
  }),
  updateShareableLoadAction.initiateCase((state) => {
    state.isEditingShareableLoads = true;
    state.isURLEditedSuccessfully = undefined;
  }),
  updateShareableLoadAction.completeCase((state, action) => {
    if (action.response.success) {
      const shareableLoad = action.response.payload;
      const index = findIndex(state.shares, (share) => share.id === shareableLoad.id);
      if (index) {
        state.shares[index] = shareableLoad;
      }
    }
    state.isURLEditedSuccessfully = action.response.success;
    state.isEditingShareableLoads = false;
  }),
  deleteShareableLoadAction.initiateCase((state) => {
    state.isDeletingShareableLoads = true;
    state.isURLDeletedSuccessfully = undefined;
  }),
  deleteShareableLoadAction.completeCase((state, action) => {
    if (action.response.success) {
      state.shares = filter(state.shares, (share) => share.id !== action.fetchData);
      state.count--;
    }
    state.isURLDeletedSuccessfully = action.response.success;
    state.isDeletingShareableLoads = false;
  }),
  fetchSharedBrokerLoadsAction.initiateCase((state) => {
    state.isLoadingLoads = true;
  }),
  fetchSharedBrokerLoadsAction.completeCase((state, action) => {
    if (action.response.success) {
      state.loads = action.response.payload.loads;
      state.loadsCount = action.response.payload.count;
    }
    state.isLoadingLoads = false;
  }),
]);

export const createURLScrapeEpic = <T extends PartialStoreState>(api: Api, anonymousMemberApi: Api) => {
  const shareLoadMemberClient = new ShareableLoadsClient(api);
  const shareLoadAnonymousMemberClient = new ShareableLoadsClient(anonymousMemberApi);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchShareableLoadsAction.createEpic$(action$, shareLoadMemberClient.getShareableLoadList$),
      postShareableLoadAction.createEpic$(action$, shareLoadMemberClient.postShareableLoad$),
      updateShareableLoadAction.createEpic$(action$, shareLoadMemberClient.updateShareableLoad$),
      deleteShareableLoadAction.createEpic$(action$, shareLoadMemberClient.deleteShareableLoad$),
      fetchSharedBrokerLoadsAction.createEpic$(action$, shareLoadAnonymousMemberClient.getSharedBrokerLoads$)
    );
};

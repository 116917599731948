import React from 'react';
import { map, split } from 'lodash';
import { Color } from '@style/Color';

function isEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
function findMatches(text: string) {
  const LINK_MAX_LENGTH = 30;
  const pattern =
    /(https?:\/\/)?(www\.)?([-a-z0-9@:%_+~#=]{2,256}\.)+[a-z]{2,6}\/?([-a-z0-9@:%_\\+~#?&/=]*)((\.+([-a-z0-9@:%_\\+~#?&/=]+))*)/gi;

  const matches = [];
  let match = pattern.exec(text);

  while (match) {
    const email = isEmail(match[0]);
    const url = email
      ? `mailto:${match[0]}`
      : match[0].startsWith('http://') || match[0].startsWith('https://')
        ? match[0]
        : `https://${match[0]}`;
    const shortUrl = email ? match[0] : match[0].replace(/(https?:\/\/)?(www\.)?/, '');
    const matchText =
      !email && shortUrl.length > LINK_MAX_LENGTH ? `${shortUrl.slice(0, LINK_MAX_LENGTH)}...` : shortUrl;

    matches.push({
      index: match.index,
      lastIndex: pattern.lastIndex,
      url: url,
      text: matchText,
      type: email ? 'email' : 'url',
    });
    match = pattern.exec(text);
  }

  return matches;
}

class Linkify extends React.Component {
  static MATCH = 'LINKIFY_MATCH';
  static defaultProps = {
    tagName: 'div',
    className: 'Linkify',
    component: 'a',
    properties: {},
  };

  parseCounter = 0;

  getMatches(text: string) {
    return findMatches(text);
  }
  parseString(text: string) {
    const elements: any[] = [];
    if (text === '') {
      return elements;
    }

    const matches = this.getMatches(text);
    if (matches.length === 0) {
      return parseText(text);
    }

    let lastIndex = 0;
    matches.forEach((match, idx) => {
      if (match.index > lastIndex) {
        elements.push(parseText(text.substring(lastIndex, match.index)));
      }
      const props = {
        target: '_blank',
        style: { color: Color.ORANGE_MAIN },
        textDecoration: 'none',
        href: match.url,
        key: `parse${this.parseCounter}match${idx}`,
      };

      elements.push(React.createElement('a', props, match.text));

      lastIndex = match.lastIndex;
    });

    if (lastIndex < text.length) {
      elements.push(parseText(text.substring(lastIndex)));
    }

    return elements.length === 1 ? elements[0] : elements;
  }
  parse(children: {} | null | undefined) {
    let parsed = children;
    if (typeof children === 'string') {
      parsed = this.parseString(children);
    } else if (children instanceof Array) {
      parsed = children.map((child) => {
        return this.parse(child);
      });
    }

    return parsed;
  }

  render() {
    return this.parse(this.props.children);
  }
}
const parseText = (value: React.ReactNode) => {
  const text = value as string;
  const lines = split(text, '\n');
  if (lines.length > 1) {
    return map(lines, (line, index) => {
      if (index === lines.length - 1) {
        return line;
      }
      return (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      );
    });
  }

  return value;
};

export default Linkify;

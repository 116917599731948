import { CompactNumber } from '@common/helper/NumberHelper';

export class GenId {
  private static crypto: any;
  private static buf32: Uint32Array;

  private static random32: () => number = () => {
    // first time setup (reprogram itself)
    GenId.crypto = window.crypto || (window as any).msCrypto;

    if (GenId.crypto) {
      GenId.buf32 = new Uint32Array(1);
      GenId.random32 = GenId.random32Crypto;
    } else {
      GenId.random32 = GenId.random32Classic;
    }

    // do the actual work
    return GenId.random32();
  };

  private static random32Crypto(): number {
    GenId.crypto.getRandomValues(GenId.buf32);
    return GenId.buf32[0] >>> 0;
  }

  private static random32Classic(): number {
    // not-so-great mashup of various (time-based) sources
    let v = (Math.random() * 0xffffffffff) >>> 0;
    v ^= Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      v ^= performance.now() * 0xffff;
    }
    v ^= 0xffffffff; // tend to have more zero bits in the begining hence this
    return (v & 0xffffffff) >>> 0;
  }

  public static generateCorrelationId(): string {
    return 'C' + CompactNumber.int32toCompactString(this.random32());
  }

  public static generateDeviceId(): string {
    return (
      'B' + CompactNumber.int32toCompactString(this.random32()) + CompactNumber.int32toCompactString(this.random32())
    );
  }

  public static generateCacheBustingParameter(): string {
    return CompactNumber.int32toCompactString(this.random32());
  }
}

import { TrackingClient } from '@common/client/TrackingClient';
import {
  CommonTrackingData,
  TrackingData,
  TrackingDataWithDeviceInfo,
  TrackingTag,
  VendorName,
  VendorTrackingData,
  VhubEvent,
} from '@common/model/Tracking';

export type TrackAction = (trackingData: TrackingData) => void;

export const createTrackAction =
  (trackingClient: TrackingClient, commonTrackingData: CommonTrackingData): TrackAction =>
  (trackingData) => {
    const trackingDataWithDeviceInfo: TrackingDataWithDeviceInfo = {
      tag: trackingData.tag,
      data: {
        ...commonTrackingData,
        ...trackingData.data,
      },
    };
    const subscription = trackingClient.trackData$(trackingDataWithDeviceInfo).subscribe({
      complete: () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      },
    });
  };

export const createVhubBookingTrackData = (event: VhubEvent, loadID: string): TrackingData<VendorTrackingData> => ({
  tag: TrackingTag.VendorTracking,
  data: { event: event, loadID: loadID, vendorName: VendorName.VhubBooking },
});

export enum PanelSize {
  /** one column */
  small = 'small',

  /** two columns but can be reduced to one to fit or in mobile view */
  medium = 'medium',

  /** three columns but can be reduced to one to fit or in mobile view */
  large = 'large',

  /** four columns size */
  xlarge = 'xlarge',

  /** 100% of the available space */
  full = 'full',
}

export const PANEL_SIZE = 340;
const PANEL_PADDING = 10;

export enum PanelWidth {
  small = PANEL_SIZE,
  medium = PANEL_SIZE + PANEL_PADDING + PANEL_SIZE,
  large = PANEL_SIZE + PANEL_PADDING + PANEL_SIZE + PANEL_PADDING + PANEL_SIZE,
  xlarge = PANEL_SIZE + PANEL_PADDING + PANEL_SIZE + PANEL_PADDING + PANEL_SIZE + PANEL_PADDING + PANEL_SIZE,
}

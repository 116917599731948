// common english file
export default {
  ok: 'OK',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  remove: 'Remove',
  period: '.',
  colon: ':',
  bulletPoint: '•',
  equals: '=',
  hyphon: '-',
  feet: 'ft',
  lb: 'lb',
  lbs: 'lbs',
  miles: 'miles',
  miles_short: 'mi',
  per_miles_short: '/mi',
  dollar_sign: '$',
  phone_ext: 'ext.',
  error: 'Error',
  active: 'Active',
  warning: 'Warning',
  notApplicable: 'N/A',
  unknown: 'unknown',
  enabled: 'enabled',
  disabled: 'disabled',
  all: 'All',
  any: 'Any',
  or: 'Or',
  at: 'at',
  change: 'Change',
  select: 'Select',
  create: 'Create',
  add: 'Add',
  submit: 'Submit',
  reSubmit: 'Resubmit',
  enter: 'Enter',
  getStarted: 'Get started',
  update: 'Update',
  deactivate: 'Deactivate',
  tryAgain: 'Try again',
  email: 'Email',
  emailSent: 'Email sent',
  phone: 'Phone',
  loading: 'loading',
  noData: 'no data',
  new: 'New',
  success: 'Success',
  continue: 'Continue',
  anywhere: 'Anywhere',
  filter: 'Filter',
  firstName: 'First name',
  lastName: 'Last name',
  fullName: 'Full name',
  verify: 'Verify',
  verified: 'Verified',
  notVerified: 'Not verified',
  password: 'Password',
  save: 'Save',
  address: 'Address',
  address2: 'Address 2',
  city: 'City',
  stateProvince: 'State/Province',
  zip_postal_code: 'Zip/Postal code',
  country: 'Country',
  dispatcher: 'Dispatcher',
  apply: 'Apply',
  clear: 'Clear',
  clearAll: 'Clear all',
  top: 'Top',
  setAlert: 'Set alert',
  removeAlert: 'Remove alert',
  alertOn: 'Alert: On',
  alertOff: 'Alert: Off',
  Off: 'Off',
  On: 'On',
  moreFilters: 'More filters',
  documents: 'Documents',
  manage: 'Manage',
  attached: 'Attached',
  parameters: 'Parameters',
  withDimensions: 'With: {{value}}',
  loadsWith: 'Loads with',
  removeAll: 'Remove all',
  plusSign: '+',
  equalitySign: '=',
  edit: 'Edit',
  delete: 'Delete',
  hide: 'Hide',
  upload: 'Upload',
  mc: 'MC#',
  usDot: 'USDOT#',
  na: 'N/A',
  next: 'Next',
  done: 'Done',
  feedback: 'Feedback',
  day: 'day',
  costPerDay: {
    title: 'Cost-per-day',
    abbreviation: 'CPD',
    description:
      'Activating Cost-per-day calculator will let you know quickly whether or not a load is a good value to you.',
    calculatorText: 'Cost-per-day calculator',
    bannerTitle: 'Cost-per-day calculator',
    bannerContent: `The value of a load isn't just about how many miles you drive, it's also about making sure all of your costs are covered (ie. truck costs, business costs, home costs, etc.)`,
    cardContentFirst:
      'Your cost-per-day is the sum of your truck and home expenses divided by the number of days you would like to work.',
    cardContentSecond: 'In order to be profitable, each working day you will need to make more than:',
    cpdAbove: 'above cost per day',
    cpdBelow: 'below cost per day',
    edit: {
      of: '{{current}} of {{max}}',
      next: 'Next',
      truckExpenses: {
        description:
          'Summarize your monthly truck expenses and make sure to include items such as your truck and trailer payments, insurance, maintenance costs, etc.',
        descriptionStart: 'Summarize your',
        descriptionBold: 'monthly truck expenses',
        descriptionEnd:
          'and make sure to include items such as your truck and trailer payments, insurance, maintenance costs, etc.',
        disclaimer: '* Fuel cost should not be included as it will be calculated on a load-by-load basis.',
        imageAlt: 'Truck image',
        input: {
          label: 'Truck expenses',
        },
      },
      homeExpenses: {
        description:
          'Summarize your monthly home expenses and make sure to include items such as your mortgage or rent, utility costs, car payments, insurance premiums, food costs, etc.',
        descriptionStart: 'Summarize your',
        descriptionBold: 'monthly home expenses',
        descriptionEnd:
          'and make sure to include items such as your mortgage or rent, utility costs, car payments, insurance premiums, food costs, etc.',
        imageAlt: 'Home image',
        input: {
          label: 'Home expenses',
        },
      },
      workDays: {
        description: 'Enter the number of days you would like to work each month.',
        descriptionStart: 'Enter the',
        descriptionBold1: 'number of days',
        descriptionMiddle: 'you would like to',
        descriptionBold2: 'work each month.',
        imageAlt: 'Calendar image',
        input: {
          label: 'Work days',
          errorText: 'number of days',
        },
      },
      loadingHours: {
        description: 'Enter the average summary of loading and unloading hours per load.',
        input: {
          label: 'Loading and unloading hours',
        },
      },
      drivingHours: {
        description: 'Enter the daily average amount of driving hours.',
        input: {
          label: 'Driving hours per day',
          errorText: 'driving hours',
        },
      },
    },
    card: {
      title: 'Cost Per Day Reached',
      notAvailable: 'Cost per day n/a - no rate provided',
      description:
        'This trip will take approximately {{maxDays}} and accounts for your total profit ({{totalProfit}}) after fuel and other expenses.',
      day: 'day',
      days: 'days',
      edit: 'Edit',
      setup: 'Setup',
      profitPerDay: 'Profit per day',
      profitPerHalfDay: 'Profit per half day',
      costPerDay: 'Cost per day',
      costPerHalfDay: 'Cost per half day',
      profitableLoad: 'Yes, profitable load. ',
      footerLoadInfo:
        'To cover your daily costs, the trip rate should be {{tripRate}} ({{tripRatePerMile}}/mi). The base rate for this load is {{postedRate}} ({{postedRatePerMile}}/mile).',
      notProfitableload: 'No, not profitable. ',
      partialProfitabLeLoad: 'Partial, still a decent load. ',
      profitValuePerHalfDay: '{{profit}} profit per half day',
    },
  },
  resetPassword: {
    title: 'Reset password',
    content: 'Instructions will be emailed to "{{email}}"',
    button_cancel: 'Cancel',
    button_reset: 'Reset password',
    toast: {
      error: 'There was an error in reseting your password.',
      success: 'Instructions for resetting your password have been sent to {{email}}.',
    },
  },
  dates: {
    month: 'Month',
    year: 'Year',
    day: 'Day',
    today: 'Today',
    yesterday: 'Yesterday',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    older: 'Older',
    monthNames: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    monthNamesShort: {
      january: 'Jan',
      february: 'Feb',
      march: 'Mar',
      april: 'Apr',
      may: 'May',
      june: 'Jun',
      july: 'Jul',
      august: 'Aug',
      september: 'Sept',
      october: 'Oct',
      november: 'Nov',
      december: 'Dec',
    },
    dayNames: {
      sunday: 'Sun',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
    },
    dayNamesShort: {
      sunday: 'Sun',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
    },
  },
  tripBuilder: {
    tripBuilder: {
      createNewTripButton: 'Create new trip',
      tripCardHeader01: `{{count}} Load added to this trip`,
      tripCardHeader02: `{{count}} Loads added to this trip`,
      tripCardTripText: `Trip {{value}} mi`,
      planYourTrip: 'Plan your trip',
      planTripDescription:
        'Trip builder allows you to plan ahead by building a trip with multiple loads along the way.',
      tripSummary: 'Trip Summary',
      deadhead: 'Deadhead',
      added: 'Added',
      available: 'Available',
      notAvailable: 'Some added loads are unavailable, please remove them to continue building your trip.',
      unavailable: 'Unavailable',
      loadUnavailable: 'This load is no longer available, remove it to continue building your trip.',
      mapNotAvailable: 'Map is not available for this trip.',
      remove: 'Remove',
    },
    newTrip: {
      newTripHeader: 'New trip',
      resultLayout: 'Result layout',
      loadByLoadLabel: 'Load-by-load',
      loadByLoadDescription: 'Build your own trip load-by-load',
      entireTripLabel: 'Entire trip',
      entireTripDescription: 'Automatically builds an entire trip for you.',
      tripParameters: 'Trip parameters',
      originPoint: 'Origin point',
      destination: 'Destination',
      time: 'Time',
      pickupTimeHeader: 'Pickup time',
      dropoffTimeHeader: 'Drop off time',
      date: `Date `,
      pickupDateHeader: 'Pickup date',
      dropoffDateHeader: 'Drop off date',
      excludedStatesOptional: 'Excluded states (optional)',
      excludedStates: 'Excluded states',
      excludedStatesSubHeader: 'Only select states you do not want to travel to.',
      maximumLoadsHeader: 'Maximum loads per trip',
      popup: {
        popupHeader: 'Result layout',
        popupDescription1: 'Load-by-load allows you to build your own trip one load at a time.',
        popupDescription2: 'Entire trip automatically builds a trip by selecting loads matching your parameters.',
      },
    },
  },
  loadSearch: {
    seeCountLoads: 'See {{count}} loads',
    noLoadsAvailable: 'No loads available',
    findLoads: '(Find loads)',
    tripLength: {
      title: 'Trip length',
      short_option: 'Short: 0-200 mi',
      medium_option: 'Medium: 201-500 mi',
      regional_option: 'Regional: 501-1000 mi',
      long_option: 'Long: 1001+ mi',
      short: 'Short',
      regional: 'Regional',
      medium: 'Medium',
      long: 'Long',
      any: 'Any',
      custom: 'Custom',
      accessibility: {
        tripLength: 'Trip length is {{length}}. Press to change length.',
      },
    },
    similarLoads: {
      title: 'Similar loads',
      subtitle: 'More potential loads available for you.',
    },
    clearSearchesPopup: {
      title: 'Clear searches',
      all: 'Clearing all searches will also clear any search alerts you have set.',
      withoutAlerts: 'Only searches without search alerts set will be cleared.',
      withAlerts: 'Only searches with search alerts set will be cleared.',
      buttonLabel: 'Clear',
    },
  },
  load: {
    New: 'New',
    Load: 'Load',
    Alerts: 'Alerts Sent',
    Views: 'Load Views',
    Views_Count: 'Views: {{count}}',
    View: 'View',
    Pickup: 'Pickup',
    Destination: 'Destination',
    Pickup_Date: 'Pickup Date',
    Search_Results: 'Search results',
    No_Loads: 'No loads found',
    No_Matches: 'No matches, check filters',
    noMatchesFound: 'No matches found',
    Search: 'Search',
    Found_Loads: 'Found {{quantity}} matches',
    Found_Load: 'Found 1 match',
    Filter_Applied: '1 filter applied',
    Filters_Applied: '{{quantity}} filters applied',
    Matches_after_filtering: '{{quantity}} matches after filtering',
    Found_Backhaul_Loads: 'Found {{quantity}} backhaul loads',
    Sort_By: 'Sort by',
    loadNoLongerAvailable: 'Load no longer available',
    View_Details: 'View Details',
    Save_Load: 'Save load',
    Saved_Load: 'Saved load',
    Status_updated: 'Status updated',
    Status_not_updated: 'Status is not updated',
    Load_is_saved: 'Load is saved',
    Load_hidden: 'Load hidden',
    Load_unhidden: 'Load unhidden',
    Loads_unhidden: 'Loads unhidden',
    Load_is_unsaved: 'Load is unsaved',
    Loads_are_unsaved: 'Loads are unsaved',
    hidePopup: {
      title: 'Unhide load',
      content: 'To view details for this load, please unhide it.',
    },
    Share: 'Share',
    Print: 'Print',
    Hide: 'Hide',
    Unhide: 'Unhide',
    Bid: 'Bid',
    Call: 'Call',
    Call_again: 'Call again',
    Contacted: 'Contacted',
    Trailer: 'Trailer',
    More_details: 'More details',
    Ref: 'Ref. #',
    Item: 'Item',
    Qty_Stops: 'Qty/Stops',
    Qty: 'Qty',
    ExtraStops: 'Extra stops',
    Note: 'Note',
    PrivateNoteMessage: '*Private notes will only be visible to you. Carriers will not see this note.',
    PrivateNote: 'Private note',
    Profit_Calculator: 'Profit calculator',
    your_profit_from_load: 'Profit {{sign}}${{profit}} ({{secondSign}}${{perMile}}/mi)',
    mi_maps_tolls: 'Mileage, maps, and tolls',
    Company_Name: 'Company name',
    Company_Phone: 'Company phone',
    DOT_number: 'DOT #',
    MC_number: 'MC #',
    Credit_Score: 'Score: {{value}}',
    Toll_Cost: '${{value}} toll cost',
    shipperMCNotRequired: 'Shipper (MC# not required)',
    loadUnavailable: 'Load unavailable',
    origToDest: '{{orig}} to {{dest}}',
    Popup: {
      Destinations: 'Desired destinations',
      Multiple: 'Multiple',
      Anywhere: 'Anywhere',
      AnywhereCA: 'Anywhere in Canada',
      AnywhereUS: 'Anywhere in US',
    },
    anyEquipmentType: 'Any type',
    Equipment: 'Equipment',
    equipmentType: {
      Van: 'Van',
      Reefer: 'Reefer',
      PowerOnly: 'Power only',
      Container: 'Container',
      HopperBottom: 'Hopper bottom',
      Tanker: 'Tanker',
      DoubleDrop: 'Double drop',
      Flatbed: 'Flatbed',
      AllFlatbed: 'All flatbed',
      AllVan: 'All van',
      Landoll: 'Landoll',
      LowBoy: 'Low boy',
      Maxi: 'Maxi',
      RemovableGooseneck: 'Removable gooseneck',
      StepDeck: 'Step deck',
      Auto: 'Auto',
      AnimalCarrier: 'Animal carrier',
      BoatHauler: 'Boat hauler',
      HeavyHaulers: 'Heavy haulers',
      MovingVan: 'Moving van',
      MiniVan: 'Mini van',
      CargoVan: 'Cargo van',
      BoxTruck: 'Straight box truck',
      Sprinter: 'Sprinter',
      DumpTruck: 'Dump truck',
      PilotCars: 'Pilot cars',
    },
    equipmentSpecification: {
      None: 'None',
      AirRide: 'Air ride',
      BlanketWrap: 'Blanket wrap',
      BTrain: 'B-Train',
      Chains: 'Chains',
      Chassis: 'Chassis',
      Conestoga: 'Conestoga',
      Curtains: 'Curtains',
      Double: 'Double',
      ETrack: 'E-Track',
      Extendable: 'Extendable',
      HazMat: 'Hazmat',
      HotShot: 'Hot shot',
      Insulated: 'Insulated',
      Intermodal: 'Intermodal',
      LiftGate: 'Lift gate',
      LoadRamps: 'Load ramps',
      LoadOut: 'Load out',
      Moving: 'Moving',
      OpenTop: 'Open top',
      Oversized: 'Oversized',
      PalletExchange: 'Pallet exchange',
      PlateTrailer: 'Plate trailer',
      SideKit: 'Side kit',
      Tarps: 'Tarps',
      TeamDriver: 'Team driver',
      TempControlled: 'Temp controlled',
      Triple: 'Triple',
      Vented: 'Vented',
      WalkingFloor: 'Walking floor',
      All: 'All',
    },
    Size: 'Size',
    Length: 'Length',
    Weight: 'Weight',
    Distance: 'Distance',
    Radius: 'Radius',
    Age: 'Age',
    equipmentCode: {
      All: 'All',
      Van: 'V',
      Reefer: 'R',
      PowerOnly: 'PO',
      Container: 'C',
      HopperBottom: 'HB',
      Tanker: 'T',
      DoubleDrop: 'DD',
      Flatbed: 'F',
      Landoll: 'LA',
      LowBoy: 'LB',
      Maxi: 'MX',
      RemovableGooseneck: 'RG',
      StepDeck: 'SD',
      Auto: 'Auto',
      AnimalCarrier: 'AC',
      BoatHauler: 'BH',
      HeavyHaulers: 'HH',
      MovingVan: 'MV',
      MiniVan: 'MiniVan',
      CargoVan: 'CV',
      BoxTruck: 'SB',
      Sprinter: 'Sprinter',
      DumpTruck: 'DT',
      PilotCars: 'PilotCars',
      None: 'None',
    },
    equipmentSpecificationCode: {
      None: 'None',
      AirRide: 'A',
      BlanketWrap: 'B',
      BTrain: 'BT',
      Chains: 'CH',
      Chassis: 'CS',
      Conestoga: 'CO',
      Curtains: 'C',
      Double: '2',
      ETrack: 'ET',
      Extendable: 'E',
      HazMat: 'Z',
      HotShot: 'HS',
      Insulated: 'I',
      Intermodal: 'N',
      LiftGate: 'LG',
      LoadRamps: 'LR',
      LoadOut: 'LO',
      Moving: 'MV',
      OpenTop: 'OT',
      Oversized: 'O',
      PalletExchange: 'X',
      PlateTrailer: 'PL',
      SideKit: 'S',
      Tarps: 'T',
      TeamDriver: 'M',
      TempControlled: 'TC',
      Triple: '3',
      Vented: 'V',
      WalkingFloor: 'WF',
      All: 'All',
    },
    filter: {
      onboardedCompanies: 'Onboarded companies',
      companyPreferences: 'Company preferences',
      favoriteCompanies: 'Favorite companies',
      creditRating: 'Credit rating (Transcredit)',
      daysToPay: 'Days-to-pay (Transcredit)',
      creditRatingShort: 'Credit rating',
      daysToPayShort: 'Days-to-pay',
      shipper: 'Shipper',
      loadInfo: 'Load info',
      postedAge: 'Posted age',
      withMessaging: 'With messaging',
      withBidding: 'With bidding',
      trailerSpecifications: 'Trailer specifications',
      commodity: 'Commodity',
      postedRate: 'Posted rate',
      teamDrivers: 'Team drivers',
      loadSize: 'Load size',
      tl: 'TL',
      ltl: 'LTL',
      withWeight: 'With weight',
      withLength: 'With length',
      distance: 'Distance',
      tripLength: 'Trip length',
      trailerSpecs: 'Trailer specs',
      singleResult: '{{quantity}} result',
      totalResult: '{{quantity}} results',
    },
  },
  creditRating: {
    selectCreditRating: 'Select credit rating range',
    pleaseSelect: 'Please select a range of credit scores from 0 to 100.',
    selectDaysToPay: 'Select Days-to-pay range',
    pleaseSelectDaysToPay: 'Please select your preferred range of days-to-pay from 0 to 65+.',
  },
  tripLength: {
    pleaseSelectTriplength: 'Please select the trip length',
  },
  postedAge: {
    selectPostedAge: 'Select posted age range',
    pleaseSelect: 'Please select the posted age of loads you want to see.',
  },
  loadDetails: {
    loadDocuments: {
      title: 'Load documents',
      filesAttached: '{{num}} files attached',
      oneFileAttached: '1 file attached',
      description:
        'Attach rate confirmation, BOL or other documents to this load for your personal reference and/or to send to the poster, Thunder Funding, factoring companies, etc.',
      manageDocuments: 'Manage documents',
    },
    updateStatus: 'Update status',
    status: 'Status',
    manageDocumentsForThisLoad: 'Manage documents for this load',
    rateConfirmationBolEtc: 'Rate confirmation, BOL, etc.',
    didYouBook: {
      title: 'Did you book this load?',
      info: 'If so, click yes, and start uploading your documents.',
    },
    truckAlert: {
      alertSent: 'Alert sent {{time}}',
    },
    onboarding: {
      title: 'Get onboarded',
      start: 'Start onboarding',
      getOnboarded: {
        start: 'Get onboarded with ',
        end: ' to increase your chance of booking this load. You may start the onboarding process at any time.',
      },
      popup: {
        title: 'Get onboarded',
        message: {
          start: 'Continuing will redirect you to a new website to get onboarded with ',
          end: '. This will open a new tab where you will continue with the onboarding process.\n\nPlease note the terms and conditions on this site may differ from 123Loadboard. 123Loadboard is not responsible for any content on third party websites.',
        },
        proceed: 'Proceed',
      },
    },
  },
  bids: {
    title: 'Bids',
    titleInteractive: 'Interactive bids',
    infoDialog: {
      menuOption: 'About bids',
      title: 'About Bids',
      message: {
        vendor:
          'The broker/shipper will respond to your bid via Call, Messaging, or Email. Your bidding history will be available for 2 weeks.',
        interactiveOnly:
          'The broker/shipper will respond to your interactive bid here. Load posters will have the option to Accept or Reject your bid offer and may send counteroffers.',
        introBothActive: 'Depending on the load, bidding on loads will either be interactive or not:\n\nBids: ',
        interactiveBothActive:
          '\n\nInteractive bids: The broker/shipper will respond to your Bid within the “Interactive bids” section of the Bids tool. Load posters will have the option to Accept or Reject your bid offer and may send counteroffers.',
      },
    },
    markAllAsViewed: {
      title: 'Mark all bids as viewed',
      success: 'All unviewed bids have been marked as viewed.',
      failure: 'Failed to mark all unviewed bids as viewed.',
    },
    status: {
      actionRequired: 'Action required',
      confirmed: 'Confirmed',
      assigned: 'Assigned',
      pending: 'Pending',
      rejected: 'Rejected',
      withdrawn: 'Withdrawn',
      unknown: 'Unknown',
      accepted: 'Accepted',
    },
    expirationTime: {
      title: 'Expiration time',
      label: 'Expiration time *',
      message: 'Select expiration time, after which the bid will be automatically withdrawn.',
      requiredError: 'Expiration time is required.',
      bannerText: 'Bid offer will be withdrawn in',
    },
    placeBid: {
      title: 'Place bid',
      message: 'Before you bid, please ensure that you meet these requirements.',
      enterBid: 'Enter your bid amount below.',
    },
    editBid: {
      title: 'Edit offer',
      message:
        'Previous offer was placed on {{date}} for {{amount}}.\n\nIn order to edit this offer, fill in the form below.',
    },
    placeCounterOffer: {
      title: 'Counteroffer',
      message:
        'The carrier placed a bid for {{amount}} on {{date}}.\n\nYou may submit your counteroffer by filling in the form below.',
      messageCarrier:
        'The load poster placed a bid for {{amount}} on {{date}}.\n\nYou may submit your counteroffer by filling in the form below.',
    },
    dotVerification: {
      popup: {
        title: 'Verify your DOT#',
        content: 'Only users with a verified DOT# can place bids.',
        button: 'Verify now',
      },
    },
    empty: {
      title: 'No bids placed',
      message: {
        broker: 'When carriers submit bids, they will appear here.',
        carrier: 'When you place bids on loads with Interactive Bidding enabled, they will appear here.',
      },
    },
    validation: {
      tooLow: 'Flat rate is too low.',
      sameValue: 'New offer must be different value than previous offer.',
    },
    biddingSummary: { title: 'Bidding summary' },
    summary: {
      title: 'Bid summary',
      menu: {
        viewLoadDetails: 'View load details',
      },
      bannerMessage: {
        carrier: {
          placedBid: 'When your bid has gotten a response, you can accept and confirm your availability.',
          confirmed: 'Thank you for confirming your availability.',
          assigned: 'For personal reference, this load has been marked as “Assigned” by the load poster.',
        },
        broker: {
          message: 'When carriers bid on this load, you can accept, reject, or provide a counteroffer.',
        },
      },
      otherParty: 'other party',
      messages: {
        sentOffer: 'Waiting for {{broker}} to accept or reject your {{amount}} bid.',
        sentOfferAccepted: '{{broker}} has accepted your {{amount}} bid. Confirm your availability to haul this load.',
        sentOfferRejected: '{{broker}} has rejected your {{amount}} offer.',
        sentOfferWithdrawn: 'You have withdrawn your {{amount}} offer to {{broker}}',
        sentOfferWithdrawnByBroker: '{{broker}} has withdrawn their {{amount}} offer.',
        receivedOfferAccepted: 'Waiting for {{broker}} to confirm.',
        receivedOfferRejected: 'You have rejected the {{amount}} offer by {{broker}}.',
        receivedCounterOffer: '{{broker}} has counteroffered {{amount}}. Please review and respond.',
        carrier: {
          confirmed: "You have confirmed your availability to haul {{broker}}'s load for {{amount}}.",
          assigned: '{{broker}} has assigned this load to you for {{amount}}',
        },
        broker: {
          sendConfirmation: 'To complete this booking, please send the Rate Confirmation to this carrier.',
        },
        accepted: '{{broker}} has accepted your {{amount}} offer.',
      },
      history: 'History',
    },
    bidItem: {
      titles: {
        offer: 'Your Offer',
        counterOffer: "Load poster's counteroffer",
        available: 'Load available',
        yourOffer: 'Your Offer',
        yourCounteroffer: 'Your counteroffer',
        carrierOffer: `Carrier's offer`,
        loadPostCounteroffer: 'Load poster counter offer',
      },
      failedToUpdate: 'Could not load',
      actions: {
        placeBid: 'Place bid',
        confirm: 'Confirm',
        accept: 'Accept',
        assign: 'Assign',
        counter: 'Counteroffer',
        reject: 'Reject',
        edit: 'Edit',
        cancel: 'Cancel',
      },
      messages: {
        sentOffer:
          'Waiting for load poster to respond to your offer.\n\nYou may edit your bid if necessary. Editing your bid will cause your previous bid to be withdrawn.\n\nIf for any reason you need to cancel this bid offer, please click “Cancel”.',
        receivedOffer: 'Please respond to this offer. You may accept, submit a counteroffer, or reject.',
        carrier: {
          receivedOffer: '{{broker}} has offered {{amount}}. You may accept, submit a counteroffer, or reject.',
          available: 'This load is available for you to place a bid.',
          sentOfferAccepted:
            '{{broker}} has accepted your {{amount}} bid. Confirm your availability to haul this load.',
          receivedOfferAccepted:
            'Waiting for the load poster to confirm that the load is still available.\n\nIf for any reason you need to cancel this bid offer, please click “Cancel”.',
          confirmed: {
            part1: 'You will be contacted by the load poster to complete the booking process.',
            part2:
              'Note: Loads are time-sensitive, so please respond as quickly as possible. Otherwise, you may not be able to haul this load.',
          },
          assigned: 'Drive safely!',
        },
        broker: {
          sentCounteroffer:
            'Waiting for the carrier to confirm your counteroffer.\n\nYou may edit your counteroffer if necessary. Editing will cause your previous counteroffer to be withdrawn.',
          sentOfferAccepted:
            'Your counteroffer was accepted by the carrier. Please confirm that the load is still available.',
          receivedOfferAcceptedListCard: 'Waiting for {{otherParty}} to confirm their availability.',
          receivedOfferAccepted:
            'Waiting for confirmation from the carrier. Once the carrier has confirmed their availability, you can contact the carrier and complete this booking through your normal procedures.\n\nIf for any reason you need to cancel this bid offer, please click “Cancel”.',
          confirmed:
            'To complete this booking, contact the carrier through your normal procedures.\n\nYou may also send any attachments to this carrier at:',
          assigned: 'For your own reference, please check the box when you have received the signed Rate Confirmation.',
        },
      },
    },
    buttons: {
      view: 'View',
      bid: 'Bid',
    },
    error: {
      failedToPlaceBid: 'Failed to place bid',
      sendingFailed: 'An error occurred while sending a bid to the company.',
      statusUnknown: 'There was an error processing this bid. Updating the app may resolve this issue.',
      somethingWentWrong: 'Something went wrong. Please reload the page and try again.',
    },
    disabled: {
      message:
        'Start receiving bids from carriers on your posted loads. Activating bidding will allow you to Accept, Reject, or Counteroffer any bids placed on your loads.\n\n Call ',
      activate: ' to activate this free feature.',
      phoneNumber: '877-875-5301 ex 2',
    },
    filterMessage: 'Filtering may reduce results',
    filterSubtitle: 'Please select to filter your results.',
    noMatches: {
      title: 'No matches',
      subtitle: 'Please refine your filter criteria',
    },
  },
  truck: {
    Company: 'Company',
    Deadhead_Short: 'Deadhead',
    Deadhead_Long: 'Deadhead Miles',
    MC_USDOT: 'MC# / USDOT',
    Authority: 'Authority (MC#)',
    MCS150: 'MCS-150 form date',
    MCS150_Popup:
      'MCS-150 form date refers to the last time this carrier modified their business data with the FMCSA. This date is based on the most recently submitted MCS-150 form.',
    Carrier_History_Map: 'Areas of operation based on this carrier’s load searches.',
    Next_Available: 'Next available',
    Carrier: 'Carrier',
    Equipment: 'Equipment',
    Size: 'Size',
    Available_Capacity: 'Available Capacity',
    Insurance: 'Insurance (USD)',
    Auth_Insurance_Short: 'Auth. insurance',
    Auth_Issuance_Short: 'Auth. issuance',
    Auth_Insurance_Long: 'Authority insurance',
    Auth_Issuance_Long: 'Authority issuance',
    Phone_Number: 'Telephone number',
    Alert_Sent: 'Alert sent',
    desiredRate: 'Desired rate',
    Now: 'Today',
    spec: {
      length: '{{min}} ft - {{max}} ft',
      weight: '{{min}} lbs - {{max}} lbs',
      small_weight: '{{min}} lb - {{max}} lbs',
    },
  },
  postTruck: {
    title: 'Post truck',
    postTruckText: 'Post your truck and brokers/shippers will contact you with offers to haul their loads.',
    findLoadsLink: 'Find loads',
    sectionTitle: 'Posted trucks',
    infoDialogContent:
      'Post your truck so brokers and shippers with available loads can contact you with offers to haul their freight.',
    truckPost: {
      realTimePosts: 'Real-time truck post',
      scheduledPosts: 'Scheduled truck posts',
      expiredPosts: 'Expired truck posts',
      createNewPost: 'Create new post',
    },
    pcMiler: {
      totalTripText: 'Total trip: ',
    },
    avgRate: {
      avgRateText: 'Average rate by rate check: ',
      avgRateLabel: '{{value}}/mi',
    },
    minRate: {
      title: 'Desired rate per mile',
      inputLabel: '/mile($)',
      info: 'If you have a preferred rate-per-mile, you may receive more relevant load alerts sent by brokers and shippers by posting your truck with a desired rate.',
      maxError: 'This field cannot be over ${{max}}',
    },
    additionalInfo: {
      title: 'Additional info',
      info: 'Enter any additional information or special instructions relating to the load that aren’t covered by the other fields.',
      inputLabel: 'Add a note',
      previewDescription: 'Additional info from carrier',
    },
    teamDriven: 'Team driven',
    postForm: {
      myTuck: 'My truck',
      availibleCapacity: 'Available capacity',
      post: 'Post truck',
    },
    AnonymousPost: {
      header: 'Anonymous',
      years: 'years',
      lessThanOneYear: '<1 years',
      AnonymousPopup: {
        title: 'Post anonymously',
        paragraph:
          'Posting your truck anonymously reduces the amount of potentially unwanted calls and gives you the ability to act on the loads you want to haul.',
        listTitle: 'The following details will be hidden from brokers and shippers:',
        listItemOne: 'your name',
        listItemTwo: 'your company name',
        listItemThree: 'MC# & DOT#',
        listItemFour: 'your phone & email',
      },
    },
    noPostedTrucksScreen: {
      realTime: {
        title: 'Real-time truck post',
        subtitle: 'Create a real-time truck post so you can receive load offers wherever you are.',
        goToApp:
          'Real-time truck posting is available on our mobile app only. Download our mobile app and assign this truck to your mobile device to activate Real-time truck posting.',
      },
      scheduled: {
        title: 'Scheduled truck posts',
        subtitle:
          'Create a scheduled truck post when you know you’re going to be somewhere and would like to receive load offers ahead of time.',
      },
      expired: {
        title: 'Expired truck posts',
        subtitle:
          'Truck posts that are past their posted date will appear here until their dates are changed or they are deleted.',
      },
    },
    myTruck: {
      newTruck: 'New truck',
      addTruck: 'Add truck',
      editTruck: 'Edit truck',
      addNewTruck: 'Add new truck',
      noTrucksText: 'You currently have no trucks on your account. To begin posting your truck, please add one.',
      truckSpacification: 'Truck specifications',
      truckName: 'Truck name',
      spec: {
        cardSpec: '{{length}} lbs, {{weight}} ft',
        cardName: '{{type}} ({{name}})',
      },
      toast: {
        updateError: 'There was an error updating your truck specifications',
        updateSuccess: 'Truck specifications saved.',
        deleteError: 'There was an error deleting your truck',
        deleteSuccess: 'Truck deleted.',
        savedSuccess: 'Truck was added successfully',
        savedError: 'There was an error adding your truck',
        nameError: 'Please choose a different name, the truck name you choose is already assigned to another truck.',
      },
    },
    snackBar: {
      updated: 'Post updated',
      failedToUpdate: 'Post failed to update',
      postDeleted: 'Post deleted',
      failedToDelete: 'Post failed to delete',
      saved: 'Post saved',
      failedToSave: 'Post failed to save',
      expiredPostsDeleted: 'All expired were deleted successfully',
      expiredPostsFailedToDelete: 'Failed to delete expired trucks',
    },
    popUps: {
      truckInUseRealTime: {
        title: 'Truck in use',
        body1:
          'This truck is already associated with another device. You can create a new truck post that will not affect other devices.',
        body2: 'If you post this truck, it will be associated to this device instead.',
        button: 'Post',
      },
      truckInUseDelete: {
        title: 'Truck in use',
        body: 'This truck has already been posted. Deleting this truck will also delete all postings it is associated with.',
        button: 'Delete',
      },
      deleteTruck: {
        title: 'Delete truck',
        body: 'Are you sure you would like to delete the selected truck?',
        button: 'Delete',
      },
      deleteTruckPost: {
        title: 'Delete truck post',
        body: 'Are you sure you want to delete this truck post?',
        button: 'Delete',
      },
      duplicateName: {
        title: 'Truck name in use',
        body: 'The display name is already associated to one of your trucks. Please choose another name for your truck.',
        button: 'Ok',
      },
      clearAll: {
        title: 'Clear all expired posts',
        content: 'Are you sure you want to delete all your expired posts?',
      },
    },
    loadDetails: {
      action: 'Post truck if you need a backhaul.',
    },
    alerts: {
      sectionTitle: 'Posted truck load alerts',
      infoDialogContent:
        'Load alerts about available loads are sent by brokers and shippers when they see your posted truck.',
    },
  },
  infoModal: {
    rateCheck: {
      title: 'What is rate check?',
      subtitle:
        'Rate check provides the average market rate to help compare lane rates before accepting loads and better estimate your load revenues.',
    },
    pcMiler: {
      title: 'What is PC*Miler?',
      subtitle:
        'PC*Miler calculates trucking-specific mileage to provide carriers with the most accurate and reliable route data: allowing for better planning and no suprises along the way.',
    },
  },
  loadAlert: {
    title: 'Load alerts',
    emptyTitle: 'No load alerts',
    emptySubtitle: 'To receive alerts, be sure to post your truck.',
    alertLoad: 'Load alert',
    markUnread: 'Mark as unread',
  },
  sortByKeys: {
    deadheadMiles: 'Deadhead miles',
    origin: 'Deadhead',
    pickupDate: 'Pickup date',
    pickupDateWithDate: 'Date:{{date}}',
    distance: 'Trip distance',
    rate: 'Trip rate',
    pricePerMile: 'Rate per mile',
    postedAge: 'Age',
    views: 'Views',
    weight: 'Weight',
    length: 'Length',
    mileage: 'Distance',
    amount: 'Posted Rate',
    rateCheck: 'Rate Check',
    company: 'Company name',
    creditScore: 'Credit score',
    daysToPay: 'Days-to-pay',
    trip: 'Trip (mi)',
    tripRate: 'Rate ($)',
    equipment: 'Equipment',
    size: 'Size',
    weightLb: 'Weight (lb)',
    lengthFt: 'Length (ft)',
    ref: 'Ref#',
    searchDirectory: {
      location: 'Location',
      USDOT: 'USDOT#',
      MCNO: 'MC#',
      phone: 'Phone',
    },
  },
  viewKeys: {
    cards: 'Card',
    list: 'List',
    map: 'Map',
    view: 'View: {{value}}',
  },
  search: {
    age: 'Age',
    pickUp: 'Pick up',
    pickUpLocation: 'Pick up location',
    dropOffLocation: 'Drop off location',
    trip: 'Trip',
    rate: 'Rate',
    avgRate: 'Avg. rate',
    equip: 'Equip',
    size: 'Size',
    company: 'Company',
    status: 'Status',
    ft: 'Ft',
    lb: 'Lb',
  },
  smallTruckLoadsDialog: {
    title: 'Small truck loads',
    content: 'To find the best results for smaller trucks, we recommend that you select the following equipment types:',
  },
  time: {
    minutes: 'minutes',
    oneMinute: '1m',
    abbreviation: {
      Year: 'y',
      Days: 'd',
      Hours: 'h',
      Minutes: 'min',
      MinutesShort: 'm',
      Seconds: 's',
      Month: 'mo',
      yearAlt: 'yr',
    },
  },
  recentSearch: {
    title: 'Recent searches',
  },
  savedSearches: {
    searchSaved: 'Search saved',
    savedSearchRemoved: 'Search removed',
    alertSaved: 'Alert saved',
    alertRemoved: 'Alert removed',
    unableToSave: 'Unable to save',
    unableToSaveReason: 'Your search could not be saved. Please move the map to another location and try again.',
  },
  namedSearch: {
    deletedSearch: 'Deleted search',
    searchDoesNotExist:
      'This search has been deleted in another window or device. Please create a new search to find more loads.',
    cardOption: {
      view: 'View search results',
      alert: 'Turn search alert "{{action}}"',
      edit: 'Edit search',
      delete: 'Delete search',
    },
    view: {
      title: 'Views',
      label: 'View: {{view}}',
      option: {
        all: 'All',
        withAlerts: 'With alerts',
        withoutAlerts: 'Without alerts',
      },
    },
    form: {
      editSearchTitle: 'Edit search',
      newSearchTitle: 'New search',
      availibleCapacityInfo:
        'Your available capacity is your equipment type and the size of the load you are looking to haul.',
      searchNameLabel: 'Name this search',
      searchNamePlaceholder: 'Search name (Optional)',
      searchNameTitle: 'Search name',
      searchNameInfo: 'You can easily manage your searches by giving them custom names.',
    },
    emptyPanel: {
      title: 'Start finding loads',
      subtitle: 'This tool allows you to find and book loads to haul.',
    },
    emptyAlertsPanel: {
      subtitle: 'Set search alerts on search results pages.',
      subtitleWithoutAlerts: 'You have no searches without alerts.',
      viewAll: 'View all',
    },
    createNewButton: 'Create new search',
    searchSaved: 'Search saved',
    searchRemoved: 'Search removed',
    alertSaved: 'Alert saved',
    alertRemoved: 'Alert removed',
    saveSearchMessage:
      'Saving your search allows you to quickly find results for lanes you run regularly and can help save time when returning to this page.',
    saveSearchPopup: {
      title: 'Save search',
      content:
        "Don't forget to save your search before leaving this page to quickly see results again when you return.",
    },
  },
  errors: {
    required: 'This field is required',
    noSpaces: 'This field cannot contain any spaces',
    minLength: 'This field must be at least {{length}} characters',
    email: 'Please enter a valid email.',
    phone: 'Please enter a valid 10-digit phone number',
    state: 'Enter a valid value',
    requiredAsciiOnly: 'This field is required (Characters accepted: A-Z, 0-9)',
    requiredAsciiAndHyphenOnly: 'This field is required (Accepted: A-Z, 0-9, -, _)',
    requiredNumbersOnly: 'This field is required (Characters accepted: 0-9)',
    requiredPrice: 'Please enter a valid per mile rate',
    lessThanValue: 'Please enter a value under {{value}}',
    greaterThanValue: 'Please enter a value greater than {{value}}',
    unableToOpen: 'Unable to open {{appName}} app',
    notSupported: '{{appName}} app not supported',
    empty: 'This field cannot be empty. ',
    tooLow: 'Please enter a value greater than {{value}}',
    max: 'This field cannot be over {{max}} characters',
    mustBeNumber: 'Please enter a valid number',
    validDOT: 'Enter a valid DOT #',
    mapDataFailed: 'Map data failed to load',
    weightErrorLbs: 'Weight must be 132000 pounds or less',
    weightErrorKgs: 'Weight must be 59874 kilos or less',
    lengthErrorMetric: 'Length must be bigger than 2.44m and less than 21m',
    heightErrorMetric: 'Height must be bigger than 1.53m and less than 4.2m',
    lengthErrorFeet: 'Length must be between 8-69f',
    heightErrorFeet: 'Height must be between 5-14f',
    validMC: 'Enter a valid MC #',
    invalidPhoneShortError: 'Invalid phone number',
    streetNumberError: 'Invalid number',
    invalidCardError: 'Invalid card number.',
    genericCardError: 'Credit card verification failed. Please review your information and try again.',
    invalidExpiry: 'Invalid expiry date.',
    invalidSecurityCode: 'Invalid security code.',
    avsFailed: 'Address verification failed.',
    betweenValues: '{{fieldName}} must be between {{min}}-{{max}}.',
    invalidWebProtocol: 'URL must be valid and use https://',
  },
  rateCheck: {
    confirmation: {
      payAmount: 'How much do you expect this lane to pay?',
      isRateAccurate: 'Is this rate accurate?',
      thankYou: 'Thank you for your feedback!',
      errorSubmitting: 'Error submitting feedback',
      ratePerMile: 'Rate per mile',
    },
    postTruck: 'Post truck',
    legendTitle: 'Average rate check for {{month}}',
  },
  userRoles: {
    premium: 'Premium',
    standard: 'Standard',
    basic: 'Basic',
    member: 'Member',
  },
  loadProgress: {
    loadAvailable: 'Load available',
    booked: 'Booked',
    scheduledForPickup: 'Scheduled for pickup',
    pickupComplete: 'Pickup complete',
    loadEnRoute: 'Load en route',
    delivered: 'Delivered',
  },
  loadAlerts: {
    limitReached: 'Search alert limit reached',
    limitReachedContent: 'There is a maximum of 10 search alerts. Please remove a previous alert to set this one.',
  },
  loadAvailability: {
    foundLoads: 'Found {{quantity}} loads, results may vary',
    resultsMayVary: {
      title: 'Results may vary',
      blockedCompanies: 'blocked companies',
      contentBeforeLink:
        'We display loads that are specific to your current profile setup and saved criteria. For example if you ',
      contentAfterLink: ', their loads will be hidden from your results and may affect displayed load count.',
    },
    regionTypes: {
      USA: 'USA',
      Northeast: 'Northeast',
      Midwest: 'Midwest',
      South: 'South',
      West: 'West',
      Plains: 'Plains',
      Other: 'Other',
      Canada: 'Canada',
      US: 'US',
    },
  },
  pcMiler: {
    title: 'PC*MILER map',
    toll: 'Toll:',
    routeInfo: 'Route info',
    summaryRoadInfo: {
      title: 'Summary road info',
      start: 'Start: {{location}}',
      end: 'End: {{location}}',
      totalDistance: 'Total distance:',
      totalDrivingTime: 'Total driving time: {{time}}',
      totalTollCost: 'Total toll cost:',
    },
    routing: {
      title: 'PC*Miler routing',
      departureFrom: 'Departure from:',
    },
    iftaReport: 'IFTA report',
    stateByStateReport: 'State by state mileage report',
    downloadIftaReport: 'Download state mileage report (IFTA)',
    iftaReportDownloadWarning: '(An app supporting CSV is required)',
    summary: {
      start: 'Start: ',
      end: 'End: ',
      totalDistance: 'Total distance: ',
      totalDrivingTime: 'Total driving time: ',
      totalTollCost: 'Total toll cost: ',
    },
    stop: 'Stop {{value}}: ',
    extraStops: 'Extra stops: {{value}}',
    stops: 'Stops',
    arrivalTo: 'Arrival to: ',
  },
  myLoads: {
    loads: 'Loads',
    viewed: 'Recently viewed',
    hidden: 'Hidden',
    potentialLoads: 'Potential loads',
    saved: 'Saved',
    called: 'Called',
    contacted: 'Contacted',
    workingLoads: 'Working loads',
    booked: 'Booked',
    scheduledForPickup: 'Scheduled for pickup',
    pickupComplete: 'Pickup complete',
    loadEnRoute: 'Load en route',
    completedLoads: 'Completed loads',
    delivered: 'Delivered',
    headerTitles: {
      viewedLoads: 'Recently viewed loads',
      savedLoads: 'Saved loads',
      calledLoads: 'Called loads',
      bookedLoads: 'Booked loads',
      scheduledLoads: 'Scheduled loads',
      pickupLoads: 'Pickup loads',
      deliveredLoads: 'Delivered loads',
      contactedLoads: 'Contacted loads',
      hiddenLoads: 'Hidden loads',
    },
    emptyResult: {
      viewed: {
        title: 'No viewed loads',
        content: 'Start viewing loads by performing a load search and clicking on a load.',
      },
      hidden: {
        title: 'No hidden loads',
      },
      saved: {
        title: 'No saved loads',
        content: 'Start saving loads by clicking the ‘Save Load’ link on the load details page.',
      },
      called: {
        title: 'No called loads',
        content: 'Start calling loads by clicking the ‘Call’ button on the load details page.',
      },
      booked: {
        title: 'No booked loads',
      },
      scheduled: {
        title: 'No scheduled loads',
      },
      picked: {
        title: 'No picked up loads',
      },
      enRoute: {
        title: 'No en route loads',
      },
      delivered: {
        title: 'No delivered loads',
      },
      contacted: {
        title: 'No contacted loads',
        content: 'Loads will be listed here when you contact a company through the Load details page.',
      },
      updateStatusContent: 'Update status on the load details page.',
    },
    deleteDialog: {
      title: 'Remove loads',
      content: 'Are you sure you want to unsave these loads?',
    },
    unhideLoads: {
      title: 'Unhide loads',
      content: 'Are you sure you want to unhide these loads?',
    },
    deleteFailedDialog: {
      title: 'Failed to remove',
      content: 'Your loads were unable to be removed.',
    },
  },
  avatar: {
    avatarPopupTitle: 'Remove avatar',
    avatarPopupContent: 'Are you sure you want to remove your avatar?',
    companyAvatarPlaceholderText: 'COMPANY LOGO',
  },
  myDocuments: {
    addNewDocument: 'Add new document',
    addNewFolder: 'Add new folder',
    select: 'Select',
    editFolderName: 'Edit folder name',
    deleteFolder: 'Delete folder',
    title: 'My documents',
    titleShort: 'My docs',
    viewLoadDetails: 'View load details',
    noDocuments: 'Upload and manage your carrier documents, loads, and other documents securely.',
    emptyDocument: {
      title: 'Documents',
      subTitle: 'Start adding documents to see files here',
    },
    tryAgain: 'Try Again',
    sortBy: 'Sort by',
    uploaded: 'Uploaded',
    folder: 'Folder',
    type: 'Type',
    date: 'Date',
    filterByFolder: 'Filter by folder',
    addDocument: 'Add document',
    attachToSendDocument: 'Attach to send',
    byDate: 'By date',
    alphabetical: 'Alphabetical',
    byFolder: 'By folder',
    byType: 'By type',
    downloading: 'Downloading',
    removeAttachment: 'Remove attachment',
    sent: 'Sent',
    selectFolder: {
      title: 'Folders',
      sort: {
        title: 'Sort by',
        name: 'Alphabetical',
        lastAccessedOn: 'Date',
        hasLoad: 'Source',
      },
    },
    createFolder: {
      title: 'Create new folder',
      description: 'Use common naming convention when creating folders to easily browse.',
      placeholder: 'Enter folder name',
      response: {
        alreadyExists: {
          title: 'Folder already exists',
          message: 'A folder with this name already exists. Please enter another name.',
        },
        success: 'New folder created',
        genericError: 'Error creating new folder',
      },
    },
    editFolder: {
      title: 'Edit folder',
      toast: {
        success: 'Folder name was updated successfully',
        error: 'Error updating folder name',
        successDelete: 'Folder was deleted successfully',
        errorDelete: 'Error deleting folder',
      },
      deleteDialog: {
        title: 'Delete folder',
        content:
          "Are you sure you want to delete this folder?\n\nAny documents in this folder will be moved to the 'Unsorted' folder.",
        contentFromMyLoaDetails: '\n\nIf this folder is deleted, you may no longer be able to view Load details.',
        loadFolderContent: '\n\nThe load associated with the folder will be unbooked.',
      },
      error: {
        title: 'Unable to edit folder name',
        message: 'Please try again or come back later.',
      },
    },
    renameDoc: {
      rename: 'Rename',
      description: 'Use common naming conventions when renaming files to easily browse.',
      accesibilityButton: 'Rename file button',
      newName: 'New Name',
      toast: {
        success: 'File name successfully changed',
        error: 'Error renaming file',
      },
    },
    updateFolder: {
      updating: 'Updating...',
      success: 'Document folder updated',
      fail: 'Updating folder was not successful',
    },
    viewDocument: 'View document',
    download: {
      success: 'Download document success',
      failure: 'Download document error',
    },
    fileNotSupported: {
      title: 'Preview not available',
      subTitle: 'A preview is not available for files with the extension ({{extension}}).',
      subTitleDownloadOnly:
        'The file type ({{extension}}) you are trying to view is no longer supported. \n\n To download this file, click on the ‘more actions’ icon.',
    },
    documentError: {
      title: 'Document error',
      subTitle: 'Preview is not available, the document is corrupted.',
    },
    documentTypes: {
      certificateOfInsurance: 'Certificate of Insurance',
      w9: 'W-9',
    },
    addBottomSheet: {
      newDocument: 'New document',
      scanDocument: 'Scan document',
      uploadPhoto: 'Upload photo',
      uploadFile: 'Upload file',
    },
    documentActionSheet: {
      title: 'Document type: {{- documentType}}',
      send: 'Attach to send',
      select: 'Select',
      view: 'View',
      rename: 'Rename',
      renameDocument: 'Rename document',
      changeFolder: 'Change folder',
      changeType: 'Change type',
      download: 'Download',
      downloadDocument: 'Download document',
      delete: 'Delete',
      deleteDocument: 'Delete document',
    },
    documentCapture: {
      title: 'New document',
      backPopup: {
        title: 'Save',
        message: 'Your document is not saved. Would you like to save this document?',
      },
      deletePopup: {
        title: 'Delete',
        message: 'Are you sure you want to delete the selected files?',
      },
      errorOnSave: 'The file could not be saved. Please try again.',
      saveInProgress: 'Generating your document, please wait.',
    },
    toast: {
      deleteSuccess: 'Document deleted',
      deleteError: 'Error in deleting document',
      filePickerError: 'Something went wrong with the file picker',
      fileTooBigError: 'File must be less than 10Mb',
    },
    deleteDialog: {
      title: 'Delete document',
      content: 'Are you sure you want to delete this document?',
    },
    viewTypeOptions: {
      folders: 'Folders',
      dates: 'Dates',
      types: 'Types',
    },
    error: {
      sendDocuments: 'Error sending documents',
      sendDocumentSubtitle: 'Our system encountered an error while sending the documents.',
      createDocument: 'Failed to create document',
      createDocumentSubtitle:
        'An error occurred and your document was not able to be created. Please try again later or choose a different file(s)',
      invalidCharactersDocumentSubtitle:
        'An error occurred and your document was not able to be created. File Name should only contain alphanumeric characters.',
      renameDocument: 'Failed to rename document',
      renameDocumentSubtitle: 'An error occurred and your document was not able to be renamed. Please try again later.',
      downloadDocument: 'Download failed',
      downloadDocumentSubtitle: 'Please try again or come back later',
      invalidImageTypePopUpTitle: 'Upload failed',
      invalidImageTypePopUpContent:
        'The file you have selected failed to upload. Please try again or choose another file.',
      couldNotRetrievePhoto: 'Sorry, there was an error receiving your selected photo.',
    },
    createDocument: {
      newDocument: 'New document',
      title: 'To create a document, enter a name, choose a desired folder, and file type.',
      buttonTitle: 'Create document',
      types: 'Types',
      form: {
        name: 'Enter document name',
        nameSelected: 'Document name',
        folderName: 'Folder name',
        folder: 'Select folder',
        folderSelected: 'Folder',
        type: 'Select type',
        typeSelected: 'Type',
      },
      toast: {
        success: 'Upload was successful',
        error: 'Error uploading',
        successUpdate: 'Update was successful',
        errorUpdate: 'Error updating',
        invalid: 'Invalid file type selected ',
      },
      cancelUpload: {
        title: 'Are you sure you want to cancel?',
        content: 'Leaving this page while the upload is in progress will cancel it',
        confirm: 'Leave',
        dismiss: 'Stay',
      },
      fileTypeError: {
        title: 'File type error',
        subTitle:
          'The file uploader only accepts the following file types: {{fileTypes}}. \n\n Please select a valid file and try again.',
      },
      mimeTypeError: {
        title: 'Upload failed',
        subTitle:
          'We weren’t able to process this file. Please select a different file or use our document scanner to upload another copy of this document.',
        buttonTitle: 'Go back',
        screenTitle: 'Error',
      },
    },
    sendDocuments: {
      description: 'You may send attached documents to any recipient.',
      emptyList: 'No attached files',
      attachedFiles: 'Attached files',
      send: 'Send',
      title: 'Send documents',
      disclaimer: 'Complete the form below to send your operating documents by fax or email at no charge.',
      documentsTitle: 'Documents to send',
      documentsSelected_plural: '{{count}} documents selected',
      sendTo: 'Send to:',
      recipient: 'Recipient',
      recipientPlaceholder: 'Recipient name',
      accountManager: 'Account Manager',
      company: 'Company name',
      companyPlaceholder: 'Company name',
      sendByEmail: 'Send by email',
      emailTitle: 'Email',
      emailPlaceholder: 'Email address',
      sendByFax: 'Send by fax',
      faxTitle: 'Recipient fax',
      faxPlaceholder: 'Fax #',
      fax: 'Fax',
      thunderFunding: 'Thunder Funding',
      getPaidFaster: 'Get paid faster',
      other: 'Other',
      sentDocumentsMessage: 'Your documents have been successfully sent',
      attachFiles: 'Attach files',
      toast: {
        updateError: 'There was an error sending your documents',
        updateSuccess: 'Documents sent',
      },
      error: {
        emailFaxEmpty: 'An email address or fax number is required',
      },
      accessibility: {
        continueToMainPage: 'Go back to My Documents Page',
      },
      button: {
        continue: 'Continue',
      },
      banner: {
        noDocuments: {
          title: 'No documents attached',
          content: 'Please attach 1 or more documents you would like to send.',
        },
        limitExceeded: {
          title: 'Attachment limit exceeded',
          content: 'Too many files selected. Please remove some of these files and send them separately.',
        },
      },
    },
  },
  conversations: {
    title123Connect: '123Connect',
    title: 'Messages',
    typeHereBox: 'Type here',
    button: 'Message',
    buttonMessageAgain: 'Message again',
    newConversation: {
      mainMessage:
        'Start your conversation with this company by asking some questions about this load or about their onboarding procedures. \n\nWe will automatically share the following information with your message:\n',
      sharedName: 'your name',
      sharedCompany: 'your company name',
      sharedMC: 'MC# & DOT#',
      sharedPhoneEmail: 'your phone & email',
      noLoadMessage: 'Start your conversation by typing in the text box below.',
    },
    empty: {
      title: 'No messages',
      unreadTitle: 'No unread messages',
      viewAllMessages: 'View all messages',
      unreadSubtitle: 'Messages that you have not read appear here.',
      subtitleCarrier_part1:
        '123Connect helps you contact brokers faster and easier than ever - before you even need to pick up the phone!',
      subtitleCarrier_part2:
        'You can start a conversation through Load Details when the Message button is available next to the Call button.',
      subtitleCarrier_part3: 'After you start a conversation, new messages will appear here.',

      subtitleBroker: 'Messages will appear here when a Carrier begins a conversation about one of your loads.',
    },
    unavailable: {
      title: 'Conversation unavailable',
      subtitle: 'Sorry for the inconvenience, the conversation you attempted to open is no longer available.',
    },
    attachmentsDialog: {
      title: 'Attachment(s)',
      options: {
        select: 'My documents',
        add: 'Add new document',
      },
    },
    viewAttachment: {
      error: 'Sorry, there was an error opening this attachment.',
    },
    sendAttachments: {
      pickerTitle: 'Select attachments',
      messageTitle: 'Message',
      messagePlaceholder: 'Add message',
      sendAttachmentsTitle: 'Send attachments',
      error: 'Sorry, there was an error sending your attachments.',
    },
    status: {
      lastOnline: ' • Last online {{offlineTime}} ago',
    },
    filter: {
      all: 'All',
      unread: 'Unread',
    },
    chip: {
      loadDetails: 'Load details',
      contactInfo: 'Load contact',
      online: 'Online',
      offline: 'Offline',
      locateLoad: 'Locate load',
    },
    contactInfo: {
      title: 'Load contact',
      agent: 'Agent',
      carrier: 'Carrier',
      phoneNumber: 'Phone',
      authorityList: 'Authority(MC#)',
      powerUnits: 'Power units',
      usCanInspections: 'US/CAN inspections in the last 24 months',
      authorityTypes: {
        common: 'Common',
        contract: 'Contract',
        broker: 'Broker',
      },
      authorityBroker: 'Authority',
      authIssuance: 'Auth. issuance',
      mc: 'MC#',
      usDot: 'DOT#',
      moreDetails: 'More details',
      company: 'Company',
      broker: {
        authority: 'Authority',
      },
      cityState: 'City/State',
      loadContact: 'Contact info',
      loadPoster: 'Load poster info',
      showPhone: 'Show number',
      carrierInfo: 'Carrier contact info',
    },
    notificationBanner: {
      content: 'Notifications turned off',
      activate: 'Activate',
      deniedPopupTitle: 'Push notifications disabled',
      deniedPopupContent: {
        part1: 'Please allow for push notifications in your ',
        part2: 'browser settings',
        part3: '. 123Loadboard uses notifications to send alerts and communications.',
      },
    },
    disabled: {
      broker: {
        title: '123Connect',
        intro:
          'Keep communicating with Carriers using the tools and methods you prefer and never lose communication or miss a message with ',
        reason1: 'Adapts to your current communication methods.',
        reason2: 'Exchange communications in real-time.',
        reason3: 'Respond faster and do more in less time.',
        subtitle:
          'Start communicating directly with Carriers about the loads you post. This allows you to quickly respond to multiple carriers easily and efficiently.',
        callText: 'Call {{number}} ext. 2 to activate this free feature.',
      },
      wholeFeature: {
        title: 'Messages Not Available',
        subtitle: 'This feature is not currently available.',
      },
    },
    errorSendingMessage: 'There was an error sending your message. Please try again.',
    userHasMessagingDisabled: 'Sending messages is not available for this conversation.',
    userOfflineMessage:
      'Messages will be delivered via email if they are unread for more than 1 minute. Please call if the issue is urgent.',
    markAllAsRead: {
      title: 'Mark all messages as read',
      success: 'All unread messages have been marked as read.',
      failure: 'Failed to mark all unread messages as read.',
    },
    locationRequest: {
      confirmation: {
        title: 'Current location',
        content: 'Confirming will send your current location into this conversation.',
      },
      unavailable: {
        title: 'Sending location unavailable',
        content: 'Users responsible for the load are not able to send their location.',
      },
      sent: 'Location sent',
      failed: 'Request failed',
    },
  },
  vendorBid: {
    bid: 'Bid',
    bidSent: 'Bid sent',
    placeBid: {
      bidHeader: 'Before you bid, please ensure that you meet these requirements.',
      title: 'Place bid',
      information: 'In order to submit this bid, fill in the form below.',
      enter: 'Enter your total bid.',
      postedRate: 'Posted rate:',
      flatRate: 'Flat rate ($) *',
      submit: 'Place bid',
      reSubmitBid: 'Resubmit bid',
      previousBidPart1: 'In order to resubmit this bid, fill in the form below.',
      previousBidPart2: 'Your previous bid was placed on {{date}} for ${{amount}}.',
      companyName: 'Company: ',
      companyDOT: 'DOT#: ',
      equipmentTypes: 'Trailer type: ',
      equipmentSpecs: 'Trailer specs: ',
      commodity: 'Item: ',
      equipmentNotes: 'Notes: ',
      submitBidCallMessage:
        "Submit a bid to reveal this company's phone number. Please tell the broker that you found this load on 123Loadboard.",
    },
    bidPlaced: {
      title: 'Bid placed',
      part1: 'Your bid for this load has been sent to the company.',
      part2: 'You will be contacted directly if your bid is selected.',
      button: 'Ok',
    },
    failedToPlaceBid: {
      title: 'Failed to place bid',
      content: 'An error occurred while sending a bid to the company.',
      not_found:
        'An error occurred while making the request. This may occur if the load is already gone, or if your DOT# can not be found in the system. You may call the load poster for more information about this load.',
      unavailable: 'The load is no longer available.',
      disqualified: {
        title: 'Not Approved',
        content:
          "The load poster has determined that you're not qualified to haul this load. This may happen if your authority is too new, or your insurance is not high enough.\n\nIf you believe this is an error, please contact the load poster directly.",
      },
      invalidDotMC: {
        title: 'Invalid DOT or MC number',
        content:
          'The DOT number cannot be the same as the MC number. Please adjust your company information and try again.',
      },
    },
    dotPopUp: {
      title: 'Required',
      info: 'To place a bid, please enter your DOT#.',
      textFieldLabel: 'DOT # *',
      button: 'Submit',
      updateStatus: {
        success: 'DOT # was updated',
        failure: 'DOT # failed to update',
      },
    },
    bidItem: {
      placedOn: 'Bid placed',
      message: "A bid for {{amount}} was placed on {{broker}}'s load.",
    },
    empty: {
      title: 'No bids placed',
      message:
        'When you submit a bid on a load, those offers will appear here.\n\nBid offers will be available for 2 weeks after being placed.',
    },
  },
  bookNow: {
    title: 'Book now',
    booked: 'Booked',
    pending: 'Pending',
    bookingPending: 'Booking pending',
    dialog: {
      information: 'Before you Book now, please ensure that you meet these requirements.',
      content: {
        part1: 'When you “Book now”, you agree that you can haul this load based on the provided load details at:',
        part2: 'You will be contacted by the load poster shortly after booking this load.',
      },
      submit:
        'Book now to reveal this company’s phone number. Please tell the broker that you found this load on 123Loadboard.',
      pendingLong:
        'The load poster will contact you shortly to confirm booking. This normally takes between 15 to 30 minutes.',
      pendingShort:
        'The load poster will contact you shortly to confirm booking. This normally takes between 1 to 2 minutes.',
      alreadyBooked:
        'You already booked this load on {{date}} at the posted rate {{amount}}. Please contact the load poster if necessary.',
      unavailable: 'Booking unavailable',
      unavailable_content: 'The load is no longer available.',
      not_found:
        'An error occurred while making the request. This may occur if the load is already gone, or if your DOT# can not be found in the system. You may call the load poster for more information about this load.',
      recentlyBooked:
        'It’s too soon since you booked your last load, which can be found in the “My loads” section. Please try again later.',
    },
    bookedSuccessfully: 'Booking request sent',
    dotVerification: {
      popup: {
        title: 'Verify your DOT#',
        content: 'Only users with a verified DOT# can use "Book now" to book loads.',
        button: 'Verify now',
      },
    },
    banner: {
      confirmedTitle: 'Booking request confirmed',
      confirmed: '{{company}} will send a rate confirmation to {{email}} shortly.',
      pendingTitle: 'Booking request pending',
      pendingLong:
        'The load poster will contact you shortly to confirm booking. This normally takes between 15 to 30 minutes.',
      pendingShort:
        'The load poster will contact you shortly to confirm booking. This normally takes between 1 to 2 minutes.',
      rejectedTitle: 'Booking request rejected',
      rejected: 'Your booking request has been rejected by the load poster.',
    },
  },
  vHub: {
    vHubMEMPopupHeader: "You're leaving 123Loadboard",
    vHubPopupHeader: "You're leaving \n123Loadboard",
    vHubPopupDetails:
      'This Load will be booked through vHub. Please note that their terms and conditions may differ from 123Loadboard. The website will open in a new tab.',
    vHubPopupSubDetails: '123Loadboard is not responsible for any content on third party websites.',
    vHubPopupButtonLabel: 'Proceed',
    vHubBookWithButtonLabel: 'Book',
  },
  help: {
    title: 'Help',
    faq: 'FAQs',
    videoTutorials: 'Video tutorials',
    serviceCode: {
      serviceCodeTitle: 'Service code',
      generateCode: 'Generate code',
      description:
        'Service codes are a helpful way for our 123Loadboard support team to locate and troubleshoot accounts quicker. If you need technical assistance, please ensure that you are speaking to a representative and have your service code ready.',
    },
    screenSharing: {
      title: 'Screen sharing',
      generateCode: 'Generate code',
      description:
        'Screen sharing will allow a 123Loadboard representative to better help you with troubleshooting. To activate the screen share, please call our Customer Support and provide them with your sharing code.',
      endSession: {
        title: 'End remote support\nsession',
        description: 'Do you want to stop this screen sharing session?',
      },
      startSession: {
        title: 'Support request',
        description: 'A 123Loadboard support agent wants to start a screen sharing session with you. Do you accept?',
        reject: 'Reject',
        accept: 'Accept',
      },
    },
  },
  announcements: {
    clearConfirmation: 'Are you sure you want to clear all announcements?',
    serviceRelated: 'Service Related',
    notice: 'Notice',
    announcements: 'Announcements',
    announcementsNewLine: 'Announce-\nments',
    announcement: 'Announcement',
    newsletter: 'Newsletter',
    promotion: 'Promotion',
    clearSuccess: 'Announcements cleared successfully.',
    clearFailure: 'An error occurred while clearing announcements.',
    deleteSuccess: 'Announcement deleted successfully.',
    deleteFailure: 'An error occurred while deleting the announcement.',
    emptyList: {
      title: 'No announcements available',
      content: "We didn't find anything to show here.",
    },
  },
  phoneVerification: {
    title: 'Phone verification',
    creation: {
      title: 'Verify your primary phone',
      content: 'The verification code will be sent to {{phone}}.',
      selectVerificationMethod: 'Select verification method',
      textMessage: 'Text message (SMS)',
      voiceMessage: 'Voice message',
      sendMessage: 'Send {{type}} message',
      messageTypes: {
        voice: 'voice',
        text: 'text',
      },
      success:
        'Your phone number has been verified. This will make your truck stand out when Brokers are looking for Carriers.',
      error: {
        landline: {
          title: 'Cannot verify landline phone by text',
          content:
            'This phone number is a landline and cannot be verified by text, would you like to send the verification by voice message?',
        },
        other: 'Something went wrong',
        wrongNumber: 'Cannot send verification message to invalid number.',
        tooManyAttempts: 'Too many attempts in a short period of time. Try again in a few minutes.',
        badRequest: 'A problem occurred. Verify that the phone number is valid.',
      },
    },
    codeInput: {
      title: 'Verify your primary phone',
      content: 'Enter the 6-digit code sent to you at {{phone}}',
      expiresIn: 'Expires in {{countdown}} min',
      expired: 'Expired',
      resendCode: 'Resend code',
      verificationCode: 'Verification code',
      verify: 'Verify',
      invalidCode: {
        title: 'Verification failed',
        content: 'The code submitted was incorrect, please try again.',
      },
      toast: {
        expiredCode: 'Code you entered has expired. Press back and try again.',
        tooManyAttempts:
          'Max attempts to check a verification code reached. Please wait until the current verification expires to generate a new code.',
      },
    },
    success: {
      header: 'Phone verified',
      title: 'Verified',
      subtitle: 'Your phone number has been successfully verified.',
    },
  },
  paymentMethod: {
    cardNumber: 'Card number',
    cvv: 'CVV',
    cid: 'CID',
    zip: 'Zip/Postal code',
    cardName: 'Card holder name',
    billingAddress: 'Billing address',
    billingAddressInfo: 'The billing address is the address that appears on your credit card bill or bank statement.',
    securityCode: 'Security Code',
    screenInfo:
      'Enter your current billing information to update your payment method. The existing card on file will be replaced.',
    methodWasUpdated: 'Your payment method has been updated',
    radioList: {
      other: 'Other',
    },
    expirationInfo: {
      title: 'Expiration date',
      description: 'You should be able to find this date on the front of your card, under the card number.',
    },
    CVVinfo: {
      title: 'CVV',
      description: 'A 3-digit code on your credit card, you can find this on the back of your card.',
    },
    CIDinfo: {
      title: 'CID',
      description: 'A 4-digit code located above your account number on the front of your card.',
    },
  },
  membership: {
    title: 'Membership',
    membershipPlan: 'Membership Plan',
    managePlan: 'Manage plans',
    keyFeatues: 'Key features',
    planPrice: '${{price}}/month',
    planPricePerYear: '${{price}}/year',
    unlockThisFeature: 'Unlock this feature',
    plan: {
      standard: 'Standard',
      premium: 'Premium',
      premiumPlus: 'Premium Plus',
    },
    upgradeResultScreen: {
      success: {
        title: 'Your membership plan has been updated.',
        button: 'Continue',
      },
      failure: {
        title: 'There was a problem with your credit card.',
        subtitle: 'Please check your payment method and try again.',
        help: 'Need help? Call {{number}}',
        button: 'Try again',
      },
    },
    upgradePlanTitle: 'Update plan',
    upgradePlanFirstMessage: 'After clicking ',
    upgradePlanSecondMessage: ', your plan will be updated to ',
    section: 'Grow your business by upgrading your plan.',
    learnMore: 'Learn more',
    addOn: {
      title: 'Add-ons',
      sectionTitle: 'Additional features',
      panelTitle: 'Available add-ons for your current plan',
      updated: 'Your Add-ons have been updated.',
      updateFailSub: 'Please check your payment method and try again.',
      popupTitle: 'Unable to update',
      popupDetail: 'The Add-on you have selected is already part of your current plan.',
    },
    openSettings: 'Open settings',
    manageSubscription: 'Manage subscription',
    openAppleManageSubscriptionSettings:
      'Your 123Loadboard account is managed through the App Store. To make changes to your subscription, please do so through the App Store settings on your Apple device settings.',
    applePlanUpgradeDelay:
      'Changes to your plan are effective upon confirmation from Apple, but may take some time to reflect on 123Loadboard.',
  },
  upgradePlanPopup: {
    button: 'Manage plan',
    addOns: 'Add-ons',
    title: 'This feature is not available in your plan',
    drivingTime:
      'Driving time is available as part of the Premium and Premium plus membership plans, or can be added with the PC*Miler add-on.',
    rateCheck:
      'Rate Check is available as part of the Premium Plus membership plans, or can be added with Rate Check add-on.',
    creditRating: 'Credit ratings are only available as part of the Premium and Premium Plus membership plans.',
    mileageCalculator:
      'This feature is available as part of the Premium and Premium Plus membership plans, or can be added with the PC*Miler add-on.',
  },
  profitCalculator: {
    trip: 'Trip',
    tripDescription:
      'If you’re unsure of your trip mileage, enter your Pick up and Drop off locations to pre-fill your miles.',
  },
  paymentDeclinedBanner: {
    actions: {
      fixNow: 'Fix now',
      here: 'here',
      viewDetails: 'View details',
    },
    accountExpired: {
      message: 'Your membership has been downgraded to Basic because of overdue invoices. ',
    },
    accountExpiringSoon: {
      message: 'Your membership is about to be suspended because of an overdue invoice. ',
      messageNonAdmin:
        'Your membership is about to be suspended because of an overdue invoice. Please contact your account administrator.',
    },
    hardDecline: {
      message: 'We are unable to process payments on your account. ',
      messageFirstPart:
        'There was an issue with processing your membership payment. Please update your billing information ',
      messageLastPart: 'You have access until {{date}}, after that your account will be processed to close.',
      messageIOS: 'Your membership may be affected due to a billing issue.',
      messageNonAdmin: 'We are unable to process payments on your account. Please contact your account administrator.',
    },
    softDecline: {
      message: 'We were unable to process a payment on your account. ',
      messageFirstPart:
        'There was an issue with processing your membership payment. Please update your billing information ',
      messageLastPart: 'You have access until {{date}}, after that your account will be processed to close.',
      messageIOS: 'Your membership may be affected due to a billing issue.',
      messageNonAdmin:
        'We were unable to process a payment on your account. Please contact your account administrator.',
    },
    generic: {
      message: 'You have a billing issue on file. ',
    },
    unsubscribeBannerMessageLink: 'click here',
    unsubscribedMessage: 'You have unsubscribed from your membership.',
    accessUntil: 'You will continue to have access until {{date}}.',
    reactivate: 'If you wish to re-activate your account, ',
  },
  businessProfile: {
    lockedCompany: 'To make changes to your company information, please contact our posting department at ',
    dot: 'DOT#',
    title: 'Business profile',
    addressTitle: 'Address:',
    companyPhoneTitle: 'Company phone:',
    companyNameTitle: 'Company name:',
    dotNumberTitle: 'DOT#:',
    mcNumberTitle: 'MC#:',
    dotBusinessProfileOption: {
      noDot: {
        title: 'Use FMCSA company details',
        text: 'Please enter your DOT# above to activate FMCSA company details.',
      },
      noInformationAvailable: {
        title: 'Use FMCSA company details',
        text: 'We were unable to find information for the entered DOT#.',
      },
      customProfile: {
        title: 'Use custom company details',
      },
      volpeProfile: {
        title: 'Use FMCSA company details',
      },
    },
  },
  dotVerification: {
    addExtension: 'Add extension',
    addExtensionText: 'Please enter your extension below to continue DOT# verification.',
    ext: 'Ext.',
    removeExtension: 'Remove extension',
    VerificationViaSMS:
      'Verification via SMS is not available for numbers with extensions. If you wish to verify via text message (SMS), remove the extension from this number.',
    fmcsaBanner: {
      text: 'This information is provided by FMCSA. To edit or update the information, ',
      contactFMCSA: 'contact FMCSA',
      button: 'FMCSA',
    },
    popup: {
      title: 'Verify your DOT# {{dot}}',
      content: 'Only users with a verified DOT# can use "Book now" to book loads.',
      button: 'Verify now',
      addOnTitle: 'To activate add-on, please enter your DOT#.',
    },
    title: 'DOT# verification',
    dotNumberVerified: 'DOT# verified',
    verifyDotNumber: 'Verify DOT#',
    verifyYourDotNumber: 'Verify your DOT#',
    pleaseEnterDot: 'Please enter DOT#.',
    sendRequest: 'Send request',
    phoneListPanelText:
      'Brokers and shippers prefer to work with Carriers that have verified their DOT#. You can verify your DOT# via phone confirmation. Please select a phone number that you have access to.',
    phoneListOptionPanelText:
      'Brokers and shippers prefer to work with Carriers that have verified their DOT#s. \n\nYou can verify your DOT# through your phone number(s) via SMS or voice confirmation',
    verifyThroughMCP: '; or you can verify through MyCarrierPortal.',
    listPanelSelectOption: 'Select preferred option',
    verificationCodeWillBeSentTo: 'You can verify your DOT# with a confirmation code that will be sent to',
    selectActivationMethod: 'Please select your preferred activation method.',
    selectVerificationMethodTitle: 'Select verification method',
    enterCode: 'Enter code',
    errorPanel: {
      title: 'Verification error',
      unableToFindByDot: 'We were unable to find information\n for the entered DOT#:',
      makeSureDotEntered: 'Please make sure your DOT# is\n entered correctly or try again later.',
    },
    resultPanel: {
      successfullyVerified: 'Your DOT number has been successfully verified.',
    },
    myCarrierPortal: {
      title: 'MyCarrierPortal',
      sent: 'Instructions sent',
      instructionsSentTo: 'Verification instructions have been sent to ',
      emailSentOn: 'This email was sent on ',
      instructions:
        'Please open the email and follow the provided instructions. When this process is completed and we receive confirmation from MyCarrierPortal, your DOT# verification status will be updated.',
      instructions2:
        "If you haven't received an email, make sure to check your spam and deleted items folders for any items sent on ",
      verificationInitiated: 'Verification Already Initiated',
      verificationDescription:
        'Your verification request has been previously initiated from another source. Once MCP confirms your verification, your DOT number will be marked as verified.',
      verificationdescription2:
        "Should you have any questions or require further assistance with the process, MCP's team is available to help you understand the process and provide any additional support you might need. You can contact them at (818) 453-8591 or email ",
      MCPEmail: 'help@assureassist.com',
    },
  },
  permissions: {
    title: 'Permissions',
    loginSessions: 'Login Sessions',
    loginSessionsHeader: 'Active login sessions',
    user: `{{platform}} currently logged in on {{userAgent}}`,
    createdOn: 'Created: {{date}}',
    updatedOn: 'Updated: {{date}}',
    ipAddress: 'IP address: {{ip}}',
    currentSessionWarning: 'Attention, this is your current session.',
    logoutDialog: 'You will be logged out of this device ({{device}}) until you log in again.',
    deactivateDialog: 'This integration will no longer be available until you reactivate.',
    integrations: 'Integrations',
    integratorsHeader: 'Active integrations',
    activatedOn: 'Activated on {{date}}',
    integrationsHeader: 'Integration permission details',
    snackbarLogoutSuccess: 'Logged out successfully',
    snackbarLogoutFailure: 'Logout unsuccessfull',
    snackbarDeactivationSuccess: 'Successfully deactivated',
    snackbarDeactivationFailure: 'Deactivation unsuccessfull',
  },
  marketRate: {
    marketRates: 'Market Rates',
    title: 'Market rates',
    notAvailable: 'Not available',
    available: 'Available',
    notAvailableAtThisTime: 'Not available at this time',
    notAvailableTrailer: 'Not available for this trailer type',
    notAvailableOutsideUS: 'Not available for locations outside the US',
    byRateCheck: '{{rate}}/mi avg. rate by Rate Check',
    byCargoChief: '{{rate}}/mi est. rate by Cargo Chief',
    byGS: '{{minRate}}/mi - {{maxRate}}/mi est. rate by GS',
    content:
      'Market rate is the price loads matching the lane and equipment type typically pay. This data can help with rate negotiation.',
    cargoChief: {
      title: 'Cargo Chief',
      popupContent:
        "Cargo Chief's market rate add-on will give you estimates based on the highest, middle, and lowest rates gathered from",
      popupContentBold: ' actual freight bills ',
      popupContent2: `rather than quoted prices.\n\nThis data can be seen by adding the Cargo Chief add-on.`,
      estRate: '{{rate}}/mi estimated rate',
      rateEst: 'Rate estimate',
      perMile: 'Per mile',
      lowEst: 'Low est.',
      highEst: 'High est.',
      est: 'Estimate',
      info: "Cargo Chief's market rate estimates are based on the highest, middle, and lowest rates gathered from actual freight bills rather than quoted prices.",
    },
    greenscreens: {
      title: 'Greenscreens',
      subtitle: '{{minRate}}/mi - {{maxRate}}/mi est. rate',
      valuePerMile: '{{rate}} per mile',
      min: 'MIN',
      max: 'MAX',
      info: 'Greenscreens provides market rate data gathered from a combination of quoted prices and confirmed rates.',
    },
    rateCheck: {
      rateNotAvailable: 'Rate not available',
      rateNotAvailable_info: 'Rate data for this load is not available at this time. Please try again later.',
      title: 'Rate Check',
      popupContent:
        'The Rate Check market rate will give you the highest, lowest, and average market rates with historical data based on posted rates. Many tools on 123Loadboard use Rate Check data to bring you the most accurate information available.',
      popupContentDetails:
        'Rate Check is available as part of the Premium Plus membership plan, or can be added with Rate Check add-on.',
      popupContentDetailsIOSUser: 'Rate Check is available as part of the Premium Plus membership plan.',
    },
    ratesAccess: {
      content1:
        'The Market rates tool gives you lane-specific rate data including historical rates, insights, and average rates.',
      content2:
        'Rate Check is required to have access to the Market rates tool. Many tools on 123Loadboard use Rate Check data to bring you the most accurate information available.',
      content3:
        'Rate Check is available as part of the Premium Plus membership plan or can be added with Rate Check add-on.',
      content3IOSUsers: 'Rate Check is available as part of the Premium Plus membership plan.',
    },
  },
  currentLocationError: 'Error getting current location. Please try again',
  marketRankings: {
    unknown: 'Unknown',
    belowAverage: 'Below avg.',
    average: 'Average',
    aboveAverage: 'Above avg.',
  },
  ratings: {
    title: 'Rating',
    notRated: 'Not rated',
    basedOnReviews: 'Based on {{count}} reviews',
    basedOnReview: 'Based on 1 review',
    safetyRating: 'Safety Rating:',
    carrierOperation: 'Carrier Operation:',
    poweredBy: 'powered by',
    moreDetails: 'More details',
    popupTitle: "You're leaving 123Loadboard",
    popupInfo:
      'More detailed carrier ratings are available on CarrierSource. Please note that their terms and conditions may differ from 123Loadboard. The website will open in a new tab.',
    proceed: 'Proceed',
    moreInfo: '123Loadboard is not responsible for any content on third party websites.',
    ratingsPoweredBy: 'Carrier Ratings powered by CarrierSource',
    newToCarrierSource: 'New to CarrierSource?',
    signUpContent: 'Sign up now and seek reviews from brokers to strengthen your brand.',
    signUpButton: 'Sign up',
    upMyScore: 'Up my score?',
  },
};

import React, { useEffect, useRef, useState } from 'react';

import { find, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { Routes } from '@/router/Routes';
import {
  createDeleteAllExpiredTrucksRequest,
  PostsFilter,
  TruckAvailability,
  TruckAvailabilityCounts,
  UserTruck,
} from '@common/model';
import {
  deleteAllTrucks,
  getMoreTruckAvailabilities,
  getTruckAvailabilities,
  getTruckAvailabilityCount,
} from '@common/redux/epic/PostTruckEpic';
import { fetchUserTrucks } from '@common/redux/epic/UserTrucksEpic';
import { MultipurposeButton } from '@component/buttons';
import { TruckPostCard } from '@component/cards/TruckPostCard';
import { LoadingSpinner, LoadingSpinnerSmallPanelContainer } from '@component/loadingSpinner/LoadingSpinner';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelFooter, PanelHeader, PanelSize } from '@component/panel';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupCancelButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupEntity, PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { InfiniteScrollView } from '@component/scrollView';
import { Text, TextStyle } from '@component/text/Text';
import { ElementSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

import { NoTruckPost } from './NoTruckPost';

const SubtitleContainer = styled.div`
  padding: 16px;
`;

export enum SubRoutes {
  AddPost = 'add-post',
  EditPost = 'edit-post',
  Dates = 'dates',
  Truck = 'truck',
  PickUp = 'pick-up',
  DropOff = 'drop-off',
  EditMyTruck = 'edit-my-truck',
  NewMyTruck = 'new-my-truck',
}

export interface FilterDetails {
  id: PostsFilter;
  name: string;
  subtitle: string;
  count?: number;
  showDates: boolean;
  showAvailabilities: boolean;
  showClear: boolean;
  hasFooter?: boolean;
}

export const getFilterItems = (count?: TruckAvailabilityCounts): FilterDetails[] => [
  {
    id: PostsFilter.ScheduledPosts,
    name: t(T.common_postTruck_noPostedTrucksScreen_scheduled_title),
    subtitle: t(T.common_postTruck_noPostedTrucksScreen_scheduled_subtitle),
    count: count?.scheduledCount,
    showDates: true,
    showAvailabilities: true,
    showClear: false,
    hasFooter: true,
  },
  {
    id: PostsFilter.RealTimePosts,
    name: t(T.common_postTruck_noPostedTrucksScreen_realTime_title),
    subtitle: t(T.common_postTruck_noPostedTrucksScreen_realTime_subtitle),
    count: count?.realTimeCount,
    showDates: false,
    showAvailabilities: false,
    showClear: false,
  },
  {
    id: PostsFilter.ExpiredPosts,
    name: t(T.common_postTruck_noPostedTrucksScreen_expired_title),
    subtitle: t(T.common_postTruck_noPostedTrucksScreen_expired_subtitle),
    count: count?.expiredCount,
    showDates: false,
    showAvailabilities: true,
    showClear: true,
  },
];

interface RouteParams {
  filter: PostsFilter;
}

interface Props {
  onClose: () => void;
}

export const TruckPostsPanel: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const { openingPopup, closingPopup } = usePopup();
  const [areAvailabilitiesFetched, setAreAvailabilitiesFetched] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const filter = params.filter as PostsFilter;
  const filterItem = filterName(filter);
  const showAvailabilities = filterItem?.showAvailabilities;
  const showDates = filterItem?.showDates;
  const availabilities = useSelector((state) => state.postTruck.truckAvailabilities.currentTrucks);
  const isLoadingAvailabilities = useSelector((state) => state.postTruck.truckAvailabilities.isLoading);
  const wasFetchedAvailabilities = useSelector((state) => state.postTruck.truckAvailabilities.wasFetched);
  const bulkDeleteState = useSelector((state) => state.postTruck.bulkDelete);
  const showClearAll =
    filterItem?.showClear && availabilities.length > 0 && !isLoadingAvailabilities && wasFetchedAvailabilities;
  const headerActionText = showClearAll ? t(T.common_clear) : undefined;
  const trucksState = useSelector((state) => state.userTrucks);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchUserTrucks());
  }, []);

  useEffect(() => {
    if (isDeleting && !bulkDeleteState.isLoading) {
      dispatch(
        showSnackbar({
          message: bulkDeleteState.wereDeleted
            ? t(T.common_postTruck_snackBar_expiredPostsDeleted)
            : t(T.common_postTruck_snackBar_expiredPostsFailedToDelete),
        })
      );
      dispatch(getTruckAvailabilities(filterItem?.id));
      dispatch(getTruckAvailabilityCount());
      setIsDeleting(false);
    }
  }, [bulkDeleteState.isLoading, bulkDeleteState.wereDeleted]);

  useEffect(() => {
    dispatch(getTruckAvailabilities(filterItem?.id));
    setAreAvailabilitiesFetched(true);
  }, [filterItem?.id]);

  const onClickCreateNewPost = () => {
    history.push({
      pathname: `${generatePath(Routes.MYTRUCKS_POST, { filter: filter, subroutes: SubRoutes.AddPost })}/`,
    });
  };

  const openEditPostPanel = (postId: number) => {
    history.push({
      pathname: `${generatePath(Routes.MYTRUCKS_POST, {
        filter: filter,
        subroutes: SubRoutes.EditPost,
        postID: postId,
      })}/`,
    });
  };

  const onClearAll = () => {
    dispatch(deleteAllTrucks(createDeleteAllExpiredTrucksRequest()));
    setIsDeleting(true);
  };

  return (
    <Panel
      id="truck_post_lists"
      layer={0}
      offset={1}
      size={PanelSize.small}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
      scrollRef={scrollContainerRef}
    >
      <PanelHeader
        label={filterItem?.name}
        hasCloseButton={true}
        onClose={props.onClose}
        actionText={headerActionText}
        handleClick={() => showBulkDeletePopup(openingPopup, closingPopup, onClearAll)}
      />

      {!isLoadingAvailabilities && areAvailabilitiesFetched && !trucksState.isLoading ? (
        <AvailabilitiesList
          showAvailabilities={showAvailabilities}
          areAvailabilitiesFetched={areAvailabilitiesFetched}
          showDates={showDates}
          availabilities={availabilities}
          filterItem={filterItem}
          trucks={trucksState.trucks}
          onPress={openEditPostPanel}
          scrollRef={scrollContainerRef}
        />
      ) : (
        <LoadingSpinnerSmallPanelContainer>
          <LoadingSpinner size={ElementSize.loadingSpinnerSize} />
        </LoadingSpinnerSmallPanelContainer>
      )}
      {showAvailabilities && filterItem?.hasFooter ? (
        <PanelFooter>
          <MultipurposeButton
            id="save_profile"
            variant={'contained'}
            text={t(T.common_postTruck_truckPost_createNewPost)}
            color={'primary'}
            isLoading={false}
            onClick={onClickCreateNewPost}
          />
        </PanelFooter>
      ) : null}
    </Panel>
  );
};

const AvailabilitiesList: React.FC<{
  showAvailabilities?: boolean;
  areAvailabilitiesFetched: boolean;
  onPress: (id: number) => void;
  showDates?: boolean;
  availabilities?: TruckAvailability[];
  filterItem?: FilterDetails;
  trucks?: UserTruck[];
  scrollRef: React.RefObject<HTMLDivElement>;
}> = (props) => {
  const hasMore = useSelector((state) => state.postTruck.truckAvailabilities.hasMore);
  const isLoadingMore = useSelector((state) => state.postTruck.truckAvailabilities.isLoadingMore);
  const isLoading = useSelector((state) => state.postTruck.truckAvailabilities.isLoading);
  const dispatch = useDispatch();
  const { showAvailabilities, areAvailabilitiesFetched, showDates, availabilities, filterItem, scrollRef } = props;

  const loadMore = () => {
    if (!isLoadingMore && hasMore) {
      dispatch(getMoreTruckAvailabilities(filterItem?.id));
    }
  };

  return (
    <>
      {showAvailabilities && areAvailabilitiesFetched && availabilities && availabilities?.length > 0 ? (
        <>
          <SubtitleContainer>
            <Text id="post_truck_info" textStyle={TextStyle.TextContentDetail}>
              {filterItem?.subtitle}
            </Text>
          </SubtitleContainer>
          <InfiniteScrollView
            id="post_truck_list"
            loadMore={loadMore}
            hasMore={!!hasMore}
            rootRef={scrollRef}
            isLoading={isLoading || isLoadingMore}
          >
            {map(availabilities, (availability) => {
              const linkedTruck = find(props.trucks, (truck) => truck.id === availability.truckId);
              if (linkedTruck) {
                return (
                  <TruckPostCard
                    showDates={showDates}
                    availability={availability}
                    truck={linkedTruck}
                    onPress={() => props.onPress(availability.id)}
                  />
                );
              }
              return null;
            })}
          </InfiniteScrollView>
        </>
      ) : (
        <NoTruckPost postsFilter={filterItem?.id} />
      )}
    </>
  );
};

const showBulkDeletePopup = (
  openPopup: (popup: PopupEntity) => void,
  dismissAction: () => void,
  clearAction: () => void
) =>
  openPopup({
    body: (
      <BasePopup
        title={t(T.common_postTruck_popUps_clearAll_title)}
        buttons={[
          <PopupPrimaryButton
            testID={'clear'}
            key={'clear'}
            label={t(T.common_clear)}
            action={() => {
              clearAction();
              dismissAction();
            }}
          />,
          <PopupCancelButton testID={'cancel'} key={'cancel'} action={dismissAction} />,
        ]}
      >
        {t(T.common_postTruck_popUps_clearAll_content)}
      </BasePopup>
    ),
    width: PopupSizes.SMALL,
    isDismissible: true,
  });

const filterName = (filterId: PostsFilter): FilterDetails | undefined =>
  find(getFilterItems(), (filter) => filter.id === filterId);

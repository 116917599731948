import { useCallback, useEffect, useRef, useState } from 'react';

import { debounce, noop } from 'lodash';

import { useIsWindowScrollMode } from '@component/main/mainHooks';

const useScrollEnd = (element: EventTarget | null, handler: (event: Event) => void) => {
  useEffect(() => {
    if (element) {
      const onScroll = debounce((ev: Event) => handler(ev), 70);
      element.addEventListener('scroll', onScroll);
      return () => element.removeEventListener('scroll', onScroll);
    }
    return noop;
  }, [element, handler]);
};

const useManualScrollStart = (element: EventTarget | null, handler: (event: Event) => void) => {
  useEffect(() => {
    if (element) {
      element.addEventListener('wheel', handler);
      element.addEventListener('touchmove', handler);

      return () => {
        element.removeEventListener('wheel', handler);
        element.removeEventListener('touchmove', handler);
      };
    }
    return noop;
  }, [element, handler]);
};

export const useManualScrollEventHandler = (
  element: EventTarget | null,
  handler: (event: Event) => void,
  pauseHandling = false
) => {
  const isScrolling = useRef(false);

  const onScrollEnd = useCallback(
    (event: Event) => {
      if (isScrolling.current && !pauseHandling) {
        handler(event);
        isScrolling.current = false;
      }
    },
    [isScrolling, isScrolling.current, pauseHandling, handler]
  );

  useScrollEnd(element, onScrollEnd);

  const onScrollStart = useCallback(() => {
    if (!pauseHandling) {
      isScrolling.current = true;
    }
  }, [isScrolling, pauseHandling]);

  useManualScrollStart(element, onScrollStart);
};

export const useThresholdAfterScroll = (scrollElement: HTMLElement | null, scrollThreshold: number) => {
  const isWindowScrollMode = useIsWindowScrollMode();
  const [reached, setReached] = useState(false);
  const element = isWindowScrollMode ? window : scrollElement;

  const onScrollEnd = useCallback(
    isWindowScrollMode
      ? () => setReached(window.pageYOffset > scrollThreshold)
      : () => setReached((scrollElement?.scrollTop ?? 0) > scrollThreshold),
    [isWindowScrollMode, setReached, scrollElement, scrollThreshold]
  );

  useScrollEnd(element, onScrollEnd);
  return reached;
};

export const useYOffsetAfterScroll = (scrollElement: HTMLElement | null) => {
  const isWindowScrollMode = useIsWindowScrollMode();
  const [yOffset, setYOffset] = useState(0);
  const element = isWindowScrollMode ? window : scrollElement;

  const onScrollEnd = useCallback(
    isWindowScrollMode ? () => setYOffset(window.pageYOffset) : () => setYOffset(scrollElement?.scrollTop ?? 0),
    [isWindowScrollMode, setYOffset, scrollElement]
  );

  useScrollEnd(element, onScrollEnd);

  return yOffset;
};

import React from 'react';

import { Routes } from '@/router/Routes';
import { default as CreditReportIcon } from '@component/panels/basePanel/loadDetails/credit report.svg';
import { default as ThunderLogoIcon } from '@component/panels/basePanel/loadDetails/thunder logo icon.svg';
import {
  BigText,
  GetPaidFasterLink,
  RatingDaraWrapper,
  ReportButton,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { T, t } from '@translate';
import * as OldMemberSiteLink from '@util/OldMemberSiteLink';

export const CreditActionButtons: React.FC<{ sourceId?: string }> = ({ sourceId }) => {
  const redirectTo = (url: string) => () => {
    window.open(url, '_blank');
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <ReportButton id="get_paid_faster" color="secondary" onClick={redirectTo(Routes.TOOLS_GET_PAID_FASTER)}>
        <img id="icon" src={ThunderLogoIcon} alt="thunderLogoIcon" />
        <div style={{ marginLeft: 6, textDecoration: 'none' }}>
          <GetPaidFasterLink id="title">{t(T.findLoads_loadDetails_getPaidFaster)}</GetPaidFasterLink>
        </div>
      </ReportButton>
      {sourceId ? (
        <ReportButton
          id="credit_report"
          color="secondary"
          onClick={redirectTo(`${OldMemberSiteLink.LOAD_CREDIT_REPORT_LINK}${sourceId}`)}
        >
          <img id="icon" src={CreditReportIcon} alt="creditReport" />
          <div style={{ marginLeft: 6, textDecoration: 'none' }}>
            <GetPaidFasterLink id="title">{t(T.findLoads_loadDetails_CreditReport)}</GetPaidFasterLink>
          </div>
        </ReportButton>
      ) : null}
    </div>
  );
};

export const CreditRatingData: React.FC<{
  id: string;
  scoreValue: string | number;
  daysToPay: string | number;
  logo?: string;
  logoAlt?: string;
}> = ({ id, scoreValue, logo, daysToPay }) => {
  return (
    <RatingDaraWrapper id={id}>
      <div>
        <span id="credit_score_label" className="title">
          {t(T.findLoads_loadDetails_CreditScore)}
        </span>
        <BigText id="credit_score_value">{scoreValue}</BigText>
      </div>
      <div>
        <span id="days_to_pay_label" className="title">
          {t(T.findLoads_loadDetails_DaysToPay)}
        </span>
        <BigText id="days_to_pay_value">{daysToPay}</BigText>
      </div>
      {logo ? (
        <div>
          <img id="logo" src={logo} alt="thunderLogo" />
        </div>
      ) : null}
    </RatingDaraWrapper>
  );
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { InboxClient } from '@common/client/InboxClient';
import { InboxMessage } from '@common/model/Inbox';
import { createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface InboxMessageWithSource extends InboxMessage {
  parsedContent: Document | undefined;
}

export interface InboxMessageContentState {
  currentMessageParsedContent?: InboxMessageWithSource;
  isLoading: boolean;
}

const initialState: InboxMessageContentState = {
  isLoading: false,
};

const fetchParsedInboxMessageAction = createApiAction<{ messageId: string }, InboxMessage>(
  'FETCH_PARSED_INBOX_MESSAGE'
);

export const fetchParsedInboxMessage = (messageId: string) =>
  fetchParsedInboxMessageAction.fetchAction({ messageId: messageId });

export const inboxMessageContentReducer = createMergedReducer(initialState, [
  fetchParsedInboxMessageAction.initiateCase((state) => {
    state.isLoading = true;
  }),
  fetchParsedInboxMessageAction.completeCase((state, action) => {
    state.isLoading = false;
    if (action.response.success) {
      //casting to workaround a limitation of Immer's Draft<K> dealing with recursive types as in "Document"
      (state as InboxMessageContentState).currentMessageParsedContent = action.response
        .payload as InboxMessageWithSource;
      const innerContent: Document = new window.DOMParser().parseFromString(
        action.response.payload.content,
        'text/html'
      );
      const parsingError = innerContent.getElementsByTagName('parsererror')[0];
      if (parsingError !== undefined) {
        //left console to warn that we have parsing error here
        console.log('Parsing error occurred', parsingError);
      } else {
        //casting to workaround a limitation of Immer's Draft<K> dealing with recursive types as in "Document"
        (state.currentMessageParsedContent as InboxMessageWithSource).parsedContent = innerContent;
      }
    }
  }),
]);

export const createInboxMessageContentEpic = (api: Api) => {
  const client = new InboxClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(fetchParsedInboxMessageAction.createEpic$(action$, (data) => client.fetchMessage$(data.messageId)));
};

import {
  capitalize,
  compact,
  concat,
  every,
  find,
  first,
  forEach,
  includes,
  isEmpty,
  isEqual,
  isUndefined,
  join,
  keys,
  map,
  replace,
  slice,
  sortBy,
  split,
  toString,
  trim,
  uniq,
} from 'lodash';
import { defaultTo } from 'lodash/fp';

import { ImmutableLRUMap } from '@common/helper';
import {
  Address,
  AllStates,
  AllStatesType,
  AmericanStates,
  ANYWHERE_LOCATION,
  BaseLocation,
  BaseLocationFromServer,
  CA_PROVINCES,
  CanadianStates,
  DEFAULT_LOAD_SEARCH_RADIUS,
  DestLocation,
  EMPTY_ORIGIN_LOCATION,
  LoadGeolocation,
  LoadLocation,
  LoadLocationCity,
  LoadLocationZip,
  Location,
  LOCATION_ALL,
  LocationSuggestion,
  LocationType,
  OriginLocation,
  RecentLocation,
  RecentLocationType,
  STATES,
  States,
  SuggestionTypes,
} from '@common/model';
import { locationCacheKey, LocationMatchState } from '@common/redux/epic/LocationMatchEpic';
import { t, T } from '@translate';

const NO_LOCATION = '—';
/** Types allowed for Load Origin  */
export type HomeLocation = LoadGeolocation | LoadLocationZip | LoadLocationCity;

export interface LocationObject {
  locationName: string;
  cityName: string;
  needsTooltip: boolean;
}

export const formatLocationToCityState = (location: Location) => {
  if (location.address) {
    let formattedLocation = location.address.city ? location.address.city : NO_LOCATION;
    formattedLocation += ', ';
    formattedLocation += location.address.state ? location.address.state : NO_LOCATION;

    return formattedLocation;
  }
  return '';
};

export const recentLocationFormatter = (location: RecentLocation) => {
  if (!location.address || !location.type) {
    return undefined;
  }

  if (location.address.states) {
    if (location.type === RecentLocationType.ZipCode && location.address.zipCode) {
      return location.address.city ? `${location.address.zipCode} ${location.address.city}` : location.address.zipCode;
    }
    if (location.address.city) {
      return `${location.address.city}, ${location.address.states[0]}`;
    }
    return replace(toString(location.address.states), /,/g, ', ');
  }

  // It is impossible to have city without state
  return undefined;
};

export function stringifyLocation(address: Address | undefined, lengthLimit?: number): LocationObject {
  let addressStr = '';
  let cityStr = '';
  let needsTooltip = false;

  if (address) {
    cityStr = capitalizeCityName(address.city);
    if (lengthLimit && address.city.length > lengthLimit) {
      needsTooltip = true;
      addressStr += `${cityStr.substring(0, lengthLimit)}...`;
    } else {
      addressStr += cityStr;
    }
  } else {
    addressStr += NO_LOCATION;
  }
  addressStr += ', ';
  if (address) {
    if (address.state) {
      addressStr += address.state;
    } else {
      addressStr += NO_LOCATION;
    }
  } else {
    addressStr += NO_LOCATION;
  }
  return { locationName: addressStr, cityName: cityStr, needsTooltip: needsTooltip };
}

export const capitalizeCityName = (message: string) => {
  if (!message) {
    return '';
  }
  const messageData = message.split(' ');
  let finalString = '';

  forEach(messageData, (data: string) => {
    finalString += `${capitalize(data)} `;
  });
  return finalString.slice(0, -1);
};

export const addressToLocationSuggestion = (
  address: Address,
  latitude: number | undefined,
  longitude: number | undefined
): LocationSuggestion => {
  if (address.zipCode) {
    return {
      type: SuggestionTypes.ZipCode,
      label: `${address.zipCode} ${address.city}`,
      latitude: latitude ? latitude : 0,
      longitude: longitude ? longitude : 0,
      state: address.state,
      city: address.city,
      stateUrlId: '',
      zipCode: address.zipCode,
    } as LocationSuggestion;
  } else {
    return {
      type: SuggestionTypes.CityState,
      label: `${address.city}, ${address.state}`,
      latitude: latitude ? latitude : 0,
      longitude: longitude ? longitude : 0,
      state: address.state,
      city: address.city,
      stateUrlId: '',
    } as LocationSuggestion;
  }
};

export const convertOriginLocationToBaseLocation = (originLocation?: OriginLocation): BaseLocation => {
  if (originLocation) {
    originLocation.radius = originLocation.radius || DEFAULT_LOAD_SEARCH_RADIUS;
    switch (originLocation.type) {
      case LocationType.GEOLOCATION: {
        return {
          type: originLocation.type,
          radius: originLocation.radius,
          geolocation: { latitude: originLocation.latitude, longitude: originLocation.longitude },
        };
      }
      case LocationType.ZIP: {
        return {
          type: originLocation.type,
          radius: originLocation.radius,
          address: {
            zipCode: originLocation.zipCode,
            city: originLocation.city,
            state: first(originLocation.states) || '',
          },
        };
      }
      case LocationType.CITY: {
        return {
          type: originLocation.type,
          radius: originLocation.radius,
          address: { city: originLocation.city, state: first(originLocation.states) || '' },
        };
      }
    }
  }
  return { type: LocationType.NONE };
};

export const convertRecentLocationToLoadLocation = (recentLocation: RecentLocation) =>
  convertBaseLocationToLoadLocation(convertRecentLocationToBaseLocation(recentLocation));

export const isLoadLocationEqual = (left: LoadLocation | undefined, right: LoadLocation | undefined): boolean => {
  if (left === right) {
    return true;
  }
  if (!left || !right || left.type !== right.type) {
    return false;
  }
  const allKeys = uniq(concat(keys(left), keys(right)));
  return every(allKeys, (key) => isEqual((left as any)[key], (right as any)[key]));
};

export const isBaseLocationEqual = (left: BaseLocation, right: BaseLocation): boolean => {
  if (left === right || isEqual(left, right)) {
    return true;
  }
  if (left.type !== right.type) {
    return false;
  }
  if (left.type === LocationType.GEOLOCATION) {
    return isEqual(left.address?.geolocation, right.address?.geolocation);
  }
  if (left.type === LocationType.STATE) {
    return (
      isEqual(left.states?.canadaStates, right.states?.canadaStates) &&
      isEqual(left.states?.usStates, right.states?.usStates)
    );
  }
  if (left.type === LocationType.CITY) {
    return (
      isEqual(left.address?.city, right.address?.city) &&
      isEqual(left.address?.state, right.address?.state) &&
      isEqual(left.radius, right.radius)
    );
  }
  if (left.type === LocationType.ZIP) {
    return isEqual(left.address?.zipCode, right.address?.zipCode) && isEqual(left.radius, right.radius);
  }
  return isEqual(left.address, right.address) && isEqual(left.radius, right.radius);
};

export const isAmericanState = (maybeState: string): maybeState is AmericanStates => includes(STATES, maybeState);
export const isCanadianState = (maybeState: string): maybeState is CanadianStates => includes(CA_PROVINCES, maybeState);

const splitStatesString = (states: string) => split(replace(states, /\s+/g, ''), ',');

export const getAmericanStates = (states: string): AmericanStates[] =>
  sortBy(compact(map(splitStatesString(states), (str) => (isAmericanState(str) ? str : undefined))));
export const getCanadianStates = (states: string): CanadianStates[] =>
  sortBy(compact(map(splitStatesString(states), (str) => (isCanadianState(str) ? str : undefined))));

export const convertServerBaseLocationToBaseLocation = (location: BaseLocationFromServer): BaseLocation => ({
  ...location,
  states: {
    canadaStates: location.states?.canadaStates ? getCanadianStates(location.states?.canadaStates) : [],
    usStates: location.states?.usStates ? getAmericanStates(location.states?.usStates) : [],
  },
});

export const convertBaseLocationToServerBaseLocation = (baseLocation: BaseLocation): BaseLocationFromServer => ({
  ...baseLocation,
  states: {
    canadaStates: !isEmpty(baseLocation.states?.canadaStates)
      ? join(baseLocation.states?.canadaStates, ', ')
      : undefined,
    usStates: !isEmpty(baseLocation.states?.usStates) ? join(baseLocation.states?.usStates, ', ') : undefined,
  },
});

export const convertLoadLocationToBaseLocation = (destLocation?: DestLocation): BaseLocation => {
  if (!destLocation) {
    return { type: LocationType.NONE };
  }
  const destinationRadius = destLocation.radius || DEFAULT_LOAD_SEARCH_RADIUS;
  switch (destLocation.type) {
    case LocationType.STATE: {
      const allStates = {
        usStates: getAmericanStates(join(destLocation.states, ', ')),
        canadaStates: getCanadianStates(join(destLocation.states, ', ')),
      };

      const isAllUSASelected = getIsAllUSASelected(allStates);
      const isAllCASelected = getIsAllCASelected(allStates);
      if (isAllUSASelected && !allStates.canadaStates) {
        return { type: LocationType.ANY_US };
      } else if (isAllCASelected && !allStates.usStates) {
        return { type: LocationType.ANY_CA };
      } else if (isAllCASelected && isAllUSASelected) {
        return { type: LocationType.ANY };
      } else {
        return {
          type: destLocation.type,
          radius: destinationRadius,
          states: allStates,
        };
      }
    }
    case LocationType.GEOLOCATION: {
      return {
        type: destLocation.type,
        radius: destinationRadius,
        geolocation: { latitude: destLocation.latitude, longitude: destLocation.longitude },
      };
    }
    case LocationType.ZIP: {
      return {
        type: destLocation.type,
        radius: destinationRadius,
        address: { zipCode: destLocation.zipCode, city: destLocation.city, state: first(destLocation.states) || '' },
      };
    }
    case LocationType.CITY: {
      return {
        type: destLocation.type,
        radius: destinationRadius,
        address: { city: destLocation.city, state: first(destLocation.states) || '' },
      };
    }
    //FIXME: when api ticket fixes this (API-2127) convert back to appropriate type object (MOB-5198)
    case LocationType.ANY: {
      return {
        type: LocationType.ANY,
      };
    }
    case LocationType.ANY_CA: {
      return { type: LocationType.ANY_CA };
    }
    case LocationType.ANY_US: {
      return { type: LocationType.ANY_US };
    }
    default: {
      return { type: LocationType.NONE };
    }
  }
};

export const convertRecentLocationToBaseLocation = (location: RecentLocation): BaseLocation => {
  let usStates;
  let canadaStates;
  const address = location.address;
  if (location.type === RecentLocationType.States && address?.states) {
    usStates = map(address.states, (addressState) => find(STATES, (state) => state === addressState));
    canadaStates = map(address.states, (addressState) => find(CA_PROVINCES, (state) => state === addressState));
  }

  return {
    ...location,
    states: { canadaStates: canadaStates, usStates: usStates },
  } as BaseLocation;
};

const convertBaseLocationToZip = (location: BaseLocation, isWithGeolocation: boolean): LoadLocation | undefined =>
  location.address && location.address.zipCode
    ? {
        type: LocationType.ZIP,
        radius: location.radius,
        zipCode: location.address.zipCode,
        city: location.address.city,
        states: [location.address.state],
        latitude: isWithGeolocation ? location.geolocation?.latitude : undefined,
        longitude: isWithGeolocation ? location.geolocation?.longitude : undefined,
      }
    : undefined;

export const convertBaseLocationToLoadLocation = (
  location: BaseLocation | undefined,
  shouldConvertCityWithZipToZipLocation: boolean = false
): LoadLocation | undefined => {
  if (isUndefined(location)) {
    return ANYWHERE_LOCATION;
  }
  const isWithGeolocation = !!location.geolocation?.longitude && !!location.geolocation?.latitude;
  switch (location.type) {
    case LocationType.STATE: {
      return {
        type: LocationType.STATE,
        radius: location.radius,
        states: getStatesFromBaseLocation(location),
      };
    }
    case LocationType.GEOLOCATION: {
      if (location.geolocation && location.address) {
        return {
          type: LocationType.GEOLOCATION,
          radius: location.radius,
          latitude: location.geolocation.latitude,
          longitude: location.geolocation.longitude,
          city: location.address.city,
          states: [location.address.state],
        };
      }
      break;
    }
    case LocationType.ZIP: {
      return convertBaseLocationToZip(location, isWithGeolocation);
    }
    case SuggestionTypes.CityState:
    case LocationType.CITY: {
      if (location.address) {
        if (location.address.zipCode && shouldConvertCityWithZipToZipLocation) {
          return convertBaseLocationToZip(location, isWithGeolocation);
        }
        return {
          type: LocationType.CITY,
          radius: location.radius,
          city: location.address.city,
          states: [location.address.state],
          latitude: isWithGeolocation ? location.geolocation?.latitude : undefined,
          longitude: isWithGeolocation ? location.geolocation?.longitude : undefined,
        };
      }
      break;
    }
    case LocationType.ANY: {
      return {
        type: LocationType.ANY,
      };
    }
    case LocationType.ANY_CA: {
      return { type: LocationType.ANY_CA };
    }
    case LocationType.ANY_US: {
      return { type: LocationType.ANY_US };
    }
  }
  return undefined;
};

export const defaultToEmptyCity: (loadLocation: LoadLocation | undefined) => LoadLocation = defaultTo(
  EMPTY_ORIGIN_LOCATION as LoadLocation
); // The only way to get this to pass tslint is to cast the object as a load location

export const convertToOriginLocation = (loadLocation: LoadLocation | undefined): OriginLocation => {
  if (
    loadLocation &&
    (loadLocation.type === LocationType.CITY ||
      loadLocation.type === LocationType.GEOLOCATION ||
      loadLocation.type === LocationType.STATE ||
      loadLocation.type === LocationType.ZIP)
  ) {
    return loadLocation;
  }
  return EMPTY_ORIGIN_LOCATION;
};

export const getStatesFromBaseLocation = (location: BaseLocation) => {
  if (location.states) {
    const usStatesArray = getListOfStatesByCountry(location.states.usStates, STATES);
    const canadaProvincesArray = getListOfStatesByCountry(location.states.canadaStates, CA_PROVINCES);
    const states: AllStates[] = compact([...usStatesArray, ...canadaProvincesArray]);
    return sortBy(states, (state: string) => state);
  }
  return [];
};

const extractStatesArrayFromString = (statesString: AmericanStates[] | CanadianStates[]) => {
  const states = split(`${statesString}`, ',');
  return map(states, (state) => trim(state) as AllStates);
};

const getListOfStatesByCountry = (
  statesString: AllStatesType | undefined,
  listOfAllStatesInCountry: AllStates[]
): AllStates[] => {
  // if we have all location return full list of states, in other case return list that we extracted
  if (statesString === LOCATION_ALL) {
    return listOfAllStatesInCountry;
  }
  if (statesString !== LocationType.NONE && statesString !== undefined) {
    return extractStatesArrayFromString(statesString);
  }
  return [];
};

export const suggestionsForLocation = (
  locationMatches: ImmutableLRUMap<string, LocationMatchState>,
  location: string,
  includeStates: boolean
) => {
  const key = locationCacheKey(location, includeStates);
  const locationMatch = locationMatches.get(key);
  if (locationMatch && locationMatch.matches && locationMatch.matches.suggestions) {
    return locationMatch.matches.suggestions;
  } else {
    return [];
  }
};

export const toAddress = (loadLocation: LoadLocation | undefined): Address | undefined => {
  if (
    !loadLocation ||
    loadLocation.type === LocationType.STATE ||
    loadLocation.type === LocationType.ANY ||
    loadLocation.type === LocationType.ANY_CA ||
    loadLocation.type === LocationType.ANY_US
  ) {
    return undefined;
  }

  const location = loadLocation as LoadLocationCity | LoadLocationZip | LoadGeolocation;

  if (location.city && location.states && location.states[0]) {
    return {
      city: location.city,
      state: location.states[0],
      zipCode: location.type === LocationType.ZIP ? location.zipCode : undefined,
      geolocation:
        location.latitude && location.longitude
          ? {
              latitude: location.latitude,
              longitude: location.longitude,
            }
          : undefined,
    };
  }
  return undefined;
};

export const textFromLocation = (location: BaseLocation | undefined, isDisabled = false): string => {
  let text = '';
  if (location) {
    switch (location.type) {
      case LocationType.GEOLOCATION:
      case LocationType.CITY:
      case LocationType.ZIP:
      case SuggestionTypes.CityState:
        text = formatLocationToCityState({ ...location, id: 0 });
        break;
      case LocationType.ANY:
        text = t(T.loadGeneric_anywhere);
        break;
      case LocationType.ANY_CA:
        text = t(T.loadGeneric_anywhereCanada);
        break;
      case LocationType.ANY_US:
        text = t(T.loadGeneric_anywhereUsa);
        break;
      case LocationType.STATE: {
        const states = getStatesFromBaseLocation(location);
        text = states.length <= 4 ? join(states, ', ') : `${join(slice(states, 0, 4), ', ')}, +${states.length - 4}`;
      }
    }
  } else {
    if (isDisabled) {
      text = t(T.loadGeneric_currentLocationUnknown);
    } else {
      text = t(T.loadGeneric_currentLocation);
    }
  }
  return text;
};

export const isLocationCountryOrAnywhere = (location: LoadLocation) =>
  includes([LocationType.ANY_US, LocationType.ANY_CA, LocationType.ANY], location.type);

export const shouldUpdateOriginRadius = (origin: OriginLocation): boolean => {
  return !origin.radius && origin.type !== LocationType.STATE && !isLocationCountryOrAnywhere(origin);
};

export const shouldUpdateDestinationRadius = (destination: LoadLocation): boolean =>
  !destination.radius &&
  destination.type !== LocationType.STATE &&
  destination.type !== LocationType.ANY &&
  destination.type !== LocationType.ANY_CA &&
  destination.type !== LocationType.ANY_US;

export const shouldClearOriginRadius = (origin: OriginLocation): boolean =>
  origin.radius != null && (origin.type === LocationType.STATE || isLocationCountryOrAnywhere(origin));

export const shouldClearLoadLocationRadius = (location: LoadLocation): boolean =>
  location.radius != null &&
  (location.type === LocationType.STATE ||
    location.type === LocationType.ANY ||
    location.type === LocationType.ANY_CA ||
    location.type === LocationType.ANY_US);

const getIsAllCASelected = (states: States) =>
  states.canadaStates ? states.canadaStates.length === CA_PROVINCES.length : false;

const getIsAllUSASelected = (states: States) => (states.usStates ? states.usStates.length === STATES.length : false);

export const clipLatLongValue = (latLong: number, forceDecimals: number = 6) => Number(latLong.toFixed(forceDecimals));

import { find } from 'lodash';

import { CustomFolder, Document } from '@common/model';
import { SearchResponsePayload } from '@common/redux/Base';
import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';
import { FuelAdvanceRequestSteps, GetPaidFasterRequestSteps } from '@common/redux/epic/ThunderFundingQuotesEpic';

/**
 * Structure of request sent to API to retrieve TF quotes.
 */

export const THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_DEFAULT = 20;
export const THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_APPENDING = 20;
export const THUNDER_FUNDING_QUOTES_REQUEST_OFFSET_INCREMENT = 20;

export const DefaultThunderFundingQuotesRequest: PaginatedRequest = {
  offset: 0,
  limit: THUNDER_FUNDING_QUOTES_REQUEST_LIMIT_DEFAULT,
  fields: 'all',
};

export interface BaseThunderFundingQuote {
  id: string;
  date: string;
  lastModifiedOn?: string;
  folder: CustomFolder;
}

interface FuelAdvanceParameters {
  fundingType: string;
  desiredAmount: string;
}

interface FuelAdvanceQuoteParameters extends FuelAdvanceParameters {
  bolOrPodDocument: Document;
  rateConfirmationDocument: Document;
}

interface FuelAdvanceRequestParameters extends FuelAdvanceParameters {
  bolOrPodDocumentId: string;
  rateConfirmationDocumentId: string;
}

interface GetPaidFasterParameters {
  paymentSchedule: string;
  specialInstructions: string;
  tfCreatesInvoice: boolean;
}

interface GetPaidFasterQuoteParameters extends GetPaidFasterParameters {
  bolDocument: Document;
  rateConfirmationDocument: Document;
  invoiceDocument?: Document;
}

interface GetPaidFasterRequestParameters extends GetPaidFasterParameters {
  bolDocumentId: string;
  rateConfirmationDocumentId: string;
  invoiceDocumentId?: string;
}

export type FuelAdvanceQuote = BaseThunderFundingQuote & Partial<FuelAdvanceQuoteParameters>;
export type GetPaidFasterQuote = BaseThunderFundingQuote & Partial<GetPaidFasterQuoteParameters>;

export type FuelAdvanceQuoteRequest = BaseThunderFundingQuoteRequest & FuelAdvanceRequestParameters;
export type GetPaidFasterQuoteRequest = BaseThunderFundingQuoteRequest & GetPaidFasterRequestParameters;

interface BaseThunderFundingQuoteRequest {
  folderId: string;
}

export interface ThunderFundingFuelAdvanceQuotes extends SearchResponsePayload {
  quotes: FuelAdvanceQuote[];
}

export interface ThunderFundingGetPaidFasterQuotes extends SearchResponsePayload {
  quotes: GetPaidFasterQuote[];
}

export interface ThunderFundingInvoice {
  isThunderFundingInvoice: boolean;
  invoiceDocument: Document | undefined;
}

export const tfQuoteKeyExtractor = (item: BaseThunderFundingQuote) => item.id;

export const fuelAdvanceQuoteToRequest = (
  quote: FuelAdvanceQuote,
  fundingTypes: FundingTypeOption[]
): FuelAdvanceRequestSteps => ({
  // quote.desiredAmount may rarely be "0", which is falsy, so explicitly check for undefined.
  desiredAmount: quote.desiredAmount !== undefined ? `${quote.desiredAmount}` : undefined,
  fundingType: find(fundingTypes, (type) => type.name === quote.fundingType),
  folder: quote.folder,
  rateConfirmation: quote.rateConfirmationDocument,
  deliveryDocument: quote.bolOrPodDocument,
  requestSent: quote.lastModifiedOn,
});

export const getPaidFasterQuoteToRequest = (
  quote: GetPaidFasterQuote,
  paymentSchedules: PaymentScheduleOption[]
): GetPaidFasterRequestSteps => ({
  paymentSchedule: find(paymentSchedules, (type) => type.name === quote.paymentSchedule),
  folder: quote.folder,
  invoiceType:
    quote.invoiceDocument || quote.tfCreatesInvoice
      ? {
          isThunderFundingInvoice: quote.tfCreatesInvoice || false,
          invoiceDocument: quote.invoiceDocument,
        }
      : undefined,
  rateConfirmation: quote.rateConfirmationDocument,
  billOfLading: quote.bolDocument,
  requestSent: quote.lastModifiedOn,
});

export interface ThunderFundingRegistrationStatusResponse {
  isTFIntegrated: boolean;
}

export const TF_REGISTRATION_MISSING_PHONE_NUMBER = 'PhoneNumber';
export interface ThunderFundingRegistrationSubmissionResult {
  id: string;
  date: string;
  metadata: {
    missingInfo?: string[];
  };
}

export interface ThunderFundingRegistrationSubmissionResults {
  quotes: ThunderFundingRegistrationSubmissionResult[];
}

export enum FuelAdvanceQuoteSteps {
  desiredAmount = 'desiredAmount',
  fundingType = 'fundingType',
  folder = 'folder',
  rateConfirmation = 'rateConfirmation',
  deliveryDocument = 'deliveryDocument',
  requestSent = 'requestSent',
}

export enum GetPaidFasterQuoteSteps {
  paymentSchedule = 'paymentSchedule',
  folder = 'folder',
  invoiceType = 'invoiceType',
  rateConfirmation = 'rateConfirmation',
  billOfLading = 'billOfLading',
  requestSent = 'requestSent',
}

interface FormOption {
  id: number;
  name: string;
  friendlyName: string;
}

export type PaymentScheduleOption = FormOption;

export type FundingTypeOption = FormOption;

export type ThunderFundingRegistrationOption = FormOption;

export interface ThunderFundingRegistrationOptionsResponse {
  truckingCompanyStatuses: ThunderFundingRegistrationOption[];
}

export enum ThunderFundingRegistrationSource {
  Factoring = 'Factoring',
  FuelCard = 'FuelCard',
  FuelAdvance = 'FuelAdvance',
}

import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Status } from '@component/loadListCard/LoadListCardStyle';
import { HeaderSubtitle } from '@component/panel';
import { Color } from '@style/Color';
import { ElementSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

const SubtitleStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Props {
  name: string;
  isOnline: boolean;
  lastOnline: string | undefined;
  isLoading: boolean | undefined;
  loadId: string | undefined;
  isConversationEnabledForContact: boolean;
}

const UserStatus: React.FC<Props> = (props) =>
  !props.isOnline && props.lastOnline !== '' ? (
    <HeaderSubtitle style={{ paddingLeft: 3 }}>
      {t(T.common_conversations_status_lastOnline, { offlineTime: props.lastOnline })}
    </HeaderSubtitle>
  ) : (
    <Status
      style={{ marginLeft: Spacing.InterElementHorizontal, height: ElementSize.TextIcon }}
      id="user_status"
      color={props.isOnline ? Color.GREEN_MAIN : Color.GRAY_DISABLED}
    >
      {props.isOnline ? t(T.common_conversations_chip_online) : t(T.common_conversations_chip_offline)}
    </Status>
  );

export const ChatHeaderNameStatus: React.FC<Props> = (props) => {
  const isLoadingConversationLoadDetails = useSelector(
    (state) => state.communication.conversationLoadDetails.isLoading
  );

  const maxWidth = useMemo(
    () => (!props.isOnline && props.lastOnline !== '' ? 150 : 240),
    [props.isOnline, props.lastOnline]
  );

  return (
    <SubtitleStatusContainer>
      <HeaderSubtitle style={{ maxWidth: maxWidth }}>{props.name} </HeaderSubtitle>
      {props.isLoading || isLoadingConversationLoadDetails || !props.isConversationEnabledForContact ? null : (
        <UserStatus {...props} />
      )}
    </SubtitleStatusContainer>
  );
};

import React, { RefObject, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Load } from '@common/model';
import { clearCurrentLoad, fetchLoadDetails } from '@common/redux/epic/LoadDetailsEpic';
import { fetchRouteInfo } from '@common/redux/epic/LoadInfoEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { usePanelBackLocation } from '@component/panel/PanelHooks';
import { LoadDetailsGeneralInfo } from '@component/panels/basePanel/loadDetails/generalInfo/LoadDetailsGeneralInfo';
import { LoadDetailsMoreInfo } from '@component/panels/basePanel/loadDetails/LoadDetailsMoreInfo';
import { ErrorPanel, LoadNotAvailablePanel } from '@component/panels/notFoundSearches/NotFoundPanels';
import { useScrollIntoView } from '@component/scrollView';
import { FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-right: 18px;
`;

interface ComponentProps {
  onCloseDetailsPanel?: () => void;
  layer: number;
  loadID: string;
}

const CommunicationBrokerLoadDetailsComponent: React.FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const closeInfoPanel = usePanelBackLocation('/details/');
  const loadDetails = useSelector((state) => state.loadDetails.currentLoad);
  const loadResponseState = useSelector((state) => state.loadDetails.responseState);
  const scrollIntoView = useScrollIntoView();
  const notesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchLoadDetails(LoadSearchType.LoadSearch, props.loadID));
    dispatch(fetchRouteInfo(props.loadID));
  }, []);

  const status = loadResponseState.status;
  let statusContent: JSX.Element = <LoadingSpinner />;
  if (status === 'Error' || status === 'PermissionDenied') {
    statusContent = <ErrorPanel />;
  }
  if (status === 'Unavailable') {
    statusContent = <LoadNotAvailablePanel />;
  }

  const closePanel = () => {
    closeInfoPanel();
    dispatch(clearCurrentLoad(LoadSearchType.LoadSearch));
  };

  const handleManualExtraStopClick = () => scrollIntoView(notesRef.current);

  return (
    <Panel
      id="contact_info_header"
      size={PanelSize.small}
      offset={2}
      layer={props.layer}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader label={t(T.common_conversations_chip_loadDetails)} hasCloseButton={true} onClose={closePanel} />

      {loadDetails === undefined ? (
        <Container style={{ justifyContent: 'center', alignItems: 'center' }}> {statusContent} </Container>
      ) : (
        <LoadInfoContent
          loadDetails={loadDetails}
          loadID={props.loadID}
          notesRef={notesRef}
          onManualExtraStopClick={handleManualExtraStopClick}
        />
      )}
    </Panel>
  );
};

const Divider = styled.div`
  padding-bottom: ${Spacing.InterSection}px;
`;

const LoadInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadInfoContent: React.FC<{
  loadDetails: Load;
  loadID: string;
  notesRef: RefObject<HTMLDivElement>;
  onManualExtraStopClick: () => void;
}> = (props) => {
  return (
    <LoadInfoWrapper>
      <LoadDetailsGeneralInfo
        isPostLoad={true}
        loadDetails={props.loadDetails}
        currentLoadId={props.loadID}
        onManualExtraStopClick={props.onManualExtraStopClick}
      />
      <Divider />
      <Container>
        <LoadDetailsMoreInfo
          dispatchName={props.loadDetails.dispatchName}
          commodity={props.loadDetails.commodity}
          postReference={props.loadDetails.postReference}
          numberOfLoads={props.loadDetails.numberOfLoads}
          notes={props.loadDetails.notes}
          numberOfStops={props.loadDetails.numberOfStops}
          commodityLabel={t(T.common_load_Item)}
          loadDetails={props.loadDetails}
          customFontSize={FontSize.ContentDetail}
          notesRef={props.notesRef}
          isBroker={true}
          teamDriving={props.loadDetails.teamDriving ? t(T.common_yes) : t(T.common_no)}
        />
      </Container>
    </LoadInfoWrapper>
  );
};

export const CommunicationBrokerLoadDetails = CommunicationBrokerLoadDetailsComponent;

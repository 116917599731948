import React from 'react';

import * as H from 'history';
import { match } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import { formatThousands } from '@common/helper';
import {
  BadgeOverlay,
  MenuItemNewBadge,
  MenuLabel,
  MenuNavLink,
  NavLinkLabel,
  NotificationCount,
  SvgContainer,
} from '@component/menu/menuStyles';
import { NavItem } from '@component/menu/menuTypes';
import { usePopup } from '@component/popup/PopupTrackingContext';

interface Props {
  item: NavItem;
  menuOpen: boolean;
  onClick?: () => void;
  isActive?: <Params extends { [K in keyof Params]?: string }>(match: match<Params>, location: H.Location) => boolean;
}

const NavLinkRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 40px;
`;

export const LinkWithNotification: React.FC<Props> = (props) => {
  const { popup, closingPopup } = usePopup();

  const { item } = props;

  const badge = () => {
    if (!item.notificationCount && item.isNewOrUpdated) {
      return <MenuItemNewBadge opened={props.menuOpen} />;
    }
    return (
      <NotificationCount
        opened={props.menuOpen}
        hasNotifications={item.notificationCount !== undefined && item.notificationCount > 0}
      >
        <span>{formatThousands(item.notificationCount)}</span>
      </NotificationCount>
    );
  };

  const onLinkClick = () => {
    if (popup) {
      closingPopup();
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <>
      <MenuNavLink
        id={item.id}
        to={item.href ? item.href : Routes.NOTFOUND}
        key={item.id}
        onClick={onLinkClick}
        exact={item.exact === undefined ? true : item.exact}
        style={{ position: 'relative' }}
        isActive={props.isActive}
      >
        <NavLinkRow>
          <SvgContainer className={'svg-container'}>{item.icon ? <item.icon /> : undefined}</SvgContainer>
          <MenuLabel>{item.label}</MenuLabel>
          <BadgeOverlay>{badge()}</BadgeOverlay>
        </NavLinkRow>
        {!props.menuOpen ? <NavLinkLabel onClick={onLinkClick}>{item.label}</NavLinkLabel> : null}
      </MenuNavLink>
    </>
  );
};

import { includes } from 'lodash';

import { isLoadBooked } from '@common/helper';
import {
  Bid,
  BidAction,
  BidDetails,
  BidLoadInfo,
  BidLoadRate,
  BidStatus,
  BidSummary,
  Load,
  LoadContactOption,
  LoadRateNegotiationsStatus,
  LoadWithDataForBiddingOnly,
} from '@common/model';
import { Vendor } from '@common/model/VendorBid';
import { T, t } from '@translate';

export const MIN_BID_AMOUNT = 20;
export const MAX_BID_AMOUNT = 100000;

export enum BidDisplayState {
  Unknown,
  Withdrawn,
  Assigned,
  Accepted,
  Pending_SentOffer,
  Pending_ReceivedOfferAccepted,
  ActionRequired_SentOfferAccepted,
  ActionRequired_ReceivedOffer,
  Rejected_SentOfferRejected,
  Rejected_ReceivedOfferRejected,
  Confirmed,
}

export const getBidDisplayState = (bid: Bid): BidDisplayState => {
  const isBidFromViewer = bid.offerMadeByMe;
  switch (bid.status) {
    case BidStatus.Withdrawn:
      return BidDisplayState.Withdrawn;
    // AcceptanceWithdrawn and Canceled to be fully implemented
    // in a later phase
    case BidStatus.Canceled:
      return BidDisplayState.Withdrawn;
    case BidStatus.ActionRequired:
      if (isBidFromViewer) {
        return BidDisplayState.ActionRequired_SentOfferAccepted;
      } else {
        return BidDisplayState.ActionRequired_ReceivedOffer;
      }
    case BidStatus.Pending:
      if (isBidFromViewer) {
        return BidDisplayState.Pending_SentOffer;
      } else {
        return BidDisplayState.Pending_ReceivedOfferAccepted;
      }
    case BidStatus.AcceptanceWithdrawn:
    case BidStatus.Rejected:
      if (isBidFromViewer) {
        return BidDisplayState.Rejected_SentOfferRejected;
      } else {
        return BidDisplayState.Rejected_ReceivedOfferRejected;
      }
    case BidStatus.Confirmed:
      return BidDisplayState.Confirmed;
    case BidStatus.Assigned:
      return BidDisplayState.Assigned;
    case BidStatus.Accepted:
      return BidDisplayState.Accepted;
    default:
      return BidDisplayState.Unknown;
  }
};

export const getBidLoadInfoFromLoad = (load: LoadWithDataForBiddingOnly): BidLoadInfo => ({
  id: load.id,
  postReference: load.postReference,
  rate: load.rate ? (load.rate as BidLoadRate) : undefined,
  originLocation: load.originLocation,
  destinationLocation: load.destinationLocation,
});

export const getBidAck = (bid: Bid): BidAction | undefined => {
  const isBidFromViewer = bid.offerMadeByMe;
  switch (bid.status) {
    case BidStatus.Withdrawn:
      return BidAction.WithdrawAck;
    case BidStatus.AcceptanceWithdrawn:
      return BidAction.WithdrawAcceptanceAck;
    case BidStatus.Canceled:
      return BidAction.CancelAck;
    case BidStatus.ActionRequired:
      return isBidFromViewer ? BidAction.AcceptAck : BidAction.OfferAck;
    case BidStatus.Pending:
      return undefined;
    case BidStatus.Rejected:
      return BidAction.RejectAck;
    case BidStatus.Confirmed:
      return BidAction.ConfirmAck;
    case BidStatus.Assigned:
      return BidAction.ConfirmAck;
    case BidStatus.Accepted:
      return BidAction.AcceptAck;
    default:
      return undefined;
  }
};

export const convertBidDetailsToBid = (bidDetails: BidDetails): Bid => ({
  bidId: bidDetails.bidId,
  fromCarrier: bidDetails.fromCarrier,
  status: bidDetails.status,
  price: bidDetails.price,
  mostRecentActivity: bidDetails.mostRecentActivity,
  offerMadeByMe: bidDetails.offerMadeByMe,
  expirationDate: bidDetails.expirationDate,
});

export const convertBidDetailsToBidSummary = (bidDetails: BidDetails): BidSummary => ({
  bidId: bidDetails.bidId,
  fromCarrier: bidDetails.fromCarrier,
  status: bidDetails.status,
  price: bidDetails.price,
  mostRecentActivity: bidDetails.mostRecentActivity,
  offerMadeByMe: bidDetails.offerMadeByMe,
  load: bidDetails.load,
  isViewed: bidDetails.isViewed,
  otherParty: bidDetails.otherParty,
  expirationDate: bidDetails.expirationDate,
});
export interface BidFilterOption {
  filterType: BidStatus;
  title: string;
  testID: string;
  accessibilityLabel: string;
  isSelected: boolean;
}

export const getBidsFilterOptions = (selected: BidStatus[]): BidFilterOption[] => [
  {
    filterType: BidStatus.ActionRequired,
    title: t(T.common_bids_status_actionRequired),
    testID: 'action_required',
    accessibilityLabel: t(T.common_bids_status_actionRequired),
    isSelected: includes(selected, BidStatus.ActionRequired),
  },
  {
    filterType: BidStatus.Pending,
    title: t(T.common_bids_status_pending),
    testID: 'pending',
    accessibilityLabel: t(T.common_bids_status_pending),
    isSelected: includes(selected, BidStatus.Pending),
  },
  {
    filterType: BidStatus.Rejected,
    title: t(T.common_bids_status_rejected),
    testID: 'rejected',
    accessibilityLabel: t(T.common_bids_status_rejected),
    isSelected: includes(selected, BidStatus.Rejected),
  },
  {
    filterType: BidStatus.Withdrawn,
    title: t(T.common_bids_status_withdrawn),
    testID: 'withdrawn',
    accessibilityLabel: t(T.common_bids_status_withdrawn),
    isSelected: includes(selected, BidStatus.Withdrawn),
  },
  {
    filterType: BidStatus.Accepted,
    title: t(T.common_bids_status_accepted),
    testID: 'accepted',
    accessibilityLabel: t(T.common_bids_status_accepted),
    isSelected: includes(selected, BidStatus.Accepted),
  },
];

export const getLoadBidOption = (
  load: Pick<Load, 'vendorInfo' | 'rateNegotiations' | 'metadata'>,
  shouldHideBidOption: boolean,
  isBiddingEnabled: boolean
): LoadContactOption.VendorBid | LoadContactOption.Bid | undefined => {
  const vendorInfo = load.vendorInfo;
  const rateNegotiationStatus = load.rateNegotiations?.status;
  if (!isLoadBooked(load)) {
    // Vendor loads have a different bid button/endpoint
    // with the exception of McLeod which is part of interactive bidding (LB-1515)
    if (vendorInfo && vendorInfo.vendorId !== Vendor.McLeod) {
      if (vendorInfo.canBid) {
        return LoadContactOption.VendorBid;
      }
    } else if (
      !shouldHideBidOption &&
      isBiddingEnabled &&
      (rateNegotiationStatus === LoadRateNegotiationsStatus.Enabled ||
        (vendorInfo?.vendorId === Vendor.McLeod && vendorInfo.canBid))
    ) {
      return LoadContactOption.Bid;
    }
  }
  return undefined;
};

import { concat, filter, find, forEach, map, some, uniqWith } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { iif as if$, merge as merge$, of as of$ } from 'rxjs';
import { flatMap as flatMap$, map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123 } from '@common/api';
import { CompanyClient, CompanyUserData } from '@common/client';
import { BrokerPaginationRequest, CompanyUserDataPayload, UserDataCompany } from '@common/model';
import { CompanyMetadata } from '@common/model/CompanyMetadata';
import { EmptyResponse, Response } from '@common/redux/Base';
import { standardApiEpic } from '@common/redux/epic/EpicHelper';

const FAVORITE_BROKER = 'FAVORITE_BROKER';
const ONBOARD_BROKER = 'ONBOARD_BROKER';
const HIDE_BROKER = 'HIDE_BROKER';
const FETCH_FAVORITE_BROKERS = 'FETCH_FAVORITE_BROKERS';
const FETCH_MORE_FAVORITE_BROKERS = 'FETCH_MORE_FAVORITE_BROKERS';
const FETCH_BLOCKED_BROKERS = 'FETCH_BLOCKED_BROKERS';
const FETCH_MORE_BLOCKED_BROKERS = 'FETCH_MORE_BLOCKED_BROKERS';
const FETCH_ONBOARDED_BROKERS = 'FETCH_ONBOARDED_BROKERS';
const FETCH_MORE_ONBOARDED_BROKERS = 'FETCH_MORE_ONBOARDED_BROKERS';
const FETCH_FAVORITE_BROKERS_COUNT = 'FETCH_FAVORITE_BROKERS_COUNT';
const FETCH_BLOCKED_BROKERS_COUNT = 'FETCH_BLOCKED_BROKERS_COUNT';
const FETCH_ONBOARDED_BROKERS_COUNT = 'FETCH_ONBOARDED_BROKERS_COUNT';

export const BROKER_FAVORITED = 'BROKER_FAVORITED';
export const BROKER_ONBOARDED = 'BROKER_ONBOARDED';
export const BROKER_HIDDEN = 'BROKER_HIDDEN';
const FAVORITE_BROKERS_FETCHED = 'FAVORITE_BROKERS_FETCHED';
const BLOCKED_BROKERS_FETCHED = 'BLOCKED_BROKERS_FETCHED';
const ONBOARDED_BROKERS_FETCHED = 'ONBOARDED_BROKERS_FETCHED';
const FAVORITE_BROKERS_COUNT_FETCHED = 'FAVORITE_BROKERS_COUNT_FETCHED';
const BLOCKED_BROKERS_COUNT_FETCHED = 'BLOCKED_BROKERS_COUNT_FETCHED';
const ONBOARDED_BROKERS_COUNT_FETCHED = 'ONBOARDED_BROKERS_COUNT_FETCHED';
const SET_FREE_TEXT_FILTER = 'SET_FREE_TEXT_FILTER';
const CLEAR_BROKER_UPDATE = 'CLEAR_BROKER_UPDATE';

export enum BrokerActionType {
  Favorite,
  Unfavorite,
  Onboard,
  Unonboarded,
  Block,
  Unblock,
}

const BROKER_REQUEST_OFFSET_DEFAULT = 0;
const BROKER_REQUEST_OFFSET_INCREMENT = 10;

const BROKER_REQUEST_LIMIT_DEFAULT = 20;
const BROKER_REQUEST_LIMIT_APPENDING = 10;

type UpdateType = 'favourite' | 'block' | 'onboard';

interface PartialStateBrokers {
  brokers: BrokerState;
}

export type CompanyUserDataResponse = Response<CompanyUserDataPayload>;

export interface FavoriteCompany {
  id: string;
  favorite: boolean;
}

export interface OnboardCompany {
  id: string;
  onboarded: boolean;
}

export interface HideCompany {
  id: string;
  hide: boolean;
}

interface FavoriteBrokersAction extends Action {
  brokers: FavoriteCompany[];
  archivingFlowID: string | undefined;
}

interface OnboardBrokersAction extends Action {
  brokers: OnboardCompany[];
  archivingFlowID: string | undefined;
}

interface HideBrokersAction extends Action {
  brokers: HideCompany[];
  isFromBackhauls: boolean;
  archivingFlowID: string | undefined;
}

export interface CompanyResponseAction extends Action {
  response: EmptyResponse;
  updated: Array<CompanyUserData<boolean>>;
}
export interface BlockedCompanyResponseAction extends CompanyResponseAction {
  isFromBackhauls: boolean;
}

export interface BrokerResponseAction extends Action {
  response: CompanyUserDataResponse;
}

interface FetchBrokersAction extends Action {
  isRefreshing?: boolean;
}

interface SetFreeTextFilterAction extends Action {
  companyName: string;
}

export const favoriteBrokers = (
  brokers: FavoriteCompany[],
  archivingFlowID: string | undefined
): FavoriteBrokersAction => {
  return { type: FAVORITE_BROKER, brokers: brokers, archivingFlowID: archivingFlowID };
};

export const onboardBrokers = (
  brokers: OnboardCompany[],
  archivingFlowID: string | undefined
): OnboardBrokersAction => {
  return { type: ONBOARD_BROKER, brokers: brokers, archivingFlowID: archivingFlowID };
};

export const hideBrokers = (
  brokers: HideCompany[],
  isFromBackhauls: boolean = false,
  archivingFlowID: string | undefined
): HideBrokersAction => {
  return {
    type: HIDE_BROKER,
    brokers: brokers,
    isFromBackhauls: isFromBackhauls,
    archivingFlowID: archivingFlowID,
  };
};

export const fetchFavoriteBrokers = (isRefreshing?: boolean): FetchBrokersAction => ({
  type: FETCH_FAVORITE_BROKERS,
  isRefreshing: isRefreshing,
});

export const fetchMoreFavoriteBrokers = (): Action => ({ type: FETCH_MORE_FAVORITE_BROKERS });

export const fetchBlockedBrokers = (isRefreshing?: boolean): FetchBrokersAction => ({
  type: FETCH_BLOCKED_BROKERS,
  isRefreshing: isRefreshing,
});

export const fetchMoreBlockedBrokers = (): Action => ({ type: FETCH_MORE_BLOCKED_BROKERS });

export const fetchOnboardedBrokers = (isRefreshing?: boolean): FetchBrokersAction => ({
  type: FETCH_ONBOARDED_BROKERS,
  isRefreshing: isRefreshing,
});

export const fetchMoreOnboardedBrokers = (): Action => ({ type: FETCH_MORE_ONBOARDED_BROKERS });

export const fetchFavoriteBrokersCount = (): Action => {
  return { type: FETCH_FAVORITE_BROKERS_COUNT };
};

export const fetchBlockedBrokersCount = (): Action => {
  return { type: FETCH_BLOCKED_BROKERS_COUNT };
};

export const fetchOnboardedBrokersCount = (): Action => {
  return { type: FETCH_ONBOARDED_BROKERS_COUNT };
};

export const setFreeTextFilter = (companyName: string): SetFreeTextFilterAction => ({
  type: SET_FREE_TEXT_FILTER,
  companyName: companyName,
});

export const clearBrokerUpdate = (): Action => ({ type: CLEAR_BROKER_UPDATE });

const brokerResponse = (
  response: EmptyResponse,
  type: string,
  updated: Array<CompanyUserData<boolean>>
): CompanyResponseAction => ({
  type: type,
  response: response,
  updated: updated,
});
const blockBrokerResponse = (
  response: EmptyResponse,
  type: string,
  updated: Array<CompanyUserData<boolean>>,
  isFromBackhauls: boolean
): BlockedCompanyResponseAction => ({
  type: type,
  response: response,
  updated: updated,
  isFromBackhauls: isFromBackhauls,
});

const fetchBrokerResponse = (type: string, response: CompanyUserDataResponse): BrokerResponseAction => ({
  type: type,
  response: response,
});

const createDefaultBrokerRequest = () => ({
  offset: BROKER_REQUEST_OFFSET_DEFAULT,
  limit: BROKER_REQUEST_LIMIT_DEFAULT,
});

export interface BrokerState {
  favoriteBrokersRequest: BrokerPaginationRequest;
  blockedBrokersRequest: BrokerPaginationRequest;
  onboardedBrokersRequest: BrokerPaginationRequest;
  isLastResult: boolean;
  isLoadingMore: boolean;
  isRefreshing: boolean;
  isLoadingFavorites: boolean;
  isLoadingOnboarded: boolean;
  isLoadingBlocked: boolean;
  isLoadedFavoritesCount: boolean;
  isLoadedOnboardedCount: boolean;
  isLoadedBlockedCount: boolean;
  favoriteBrokers: UserDataCompany[];
  favoriteBrokersCount: number;
  onboardedBrokers: UserDataCompany[];
  onboardedBrokersCount: number;
  blockedBrokers: UserDataCompany[];
  blockedBrokersCount: number;
  freeTextFilter: string;
  brokersUpdate: {
    isLoading: boolean;
    updatedSuccessfully?: boolean;
    updateTime?: number;
    updateType?: BrokerActionType;
    error?: ApiError | undefined;
  };
}

const initialState: BrokerState = {
  favoriteBrokersRequest: createDefaultBrokerRequest(),
  blockedBrokersRequest: createDefaultBrokerRequest(),
  onboardedBrokersRequest: createDefaultBrokerRequest(),
  isLastResult: false,
  isLoadingMore: false,
  isRefreshing: false,
  favoriteBrokers: [],
  blockedBrokers: [],
  onboardedBrokers: [],
  brokersUpdate: {
    isLoading: false,
  },
  isLoadingFavorites: false,
  isLoadingBlocked: false,
  isLoadingOnboarded: false,
  favoriteBrokersCount: 0,
  blockedBrokersCount: 0,
  onboardedBrokersCount: 0,
  freeTextFilter: '',
  isLoadedFavoritesCount: false,
  isLoadedOnboardedCount: false,
  isLoadedBlockedCount: false,
};

export const brokerReducer = (state = initialState, action: Action): BrokerState => {
  switch (action.type) {
    case FETCH_FAVORITE_BROKERS: {
      const fetchAction = action as FetchBrokersAction;
      return {
        ...state,
        isRefreshing: fetchAction.isRefreshing || false,
        isLoadingFavorites: true,
        favoriteBrokers: [],
      };
    }
    case FETCH_BLOCKED_BROKERS: {
      const fetchAction = action as FetchBrokersAction;
      return { ...state, isRefreshing: fetchAction.isRefreshing || false, isLoadingBlocked: true, blockedBrokers: [] };
    }
    case FETCH_ONBOARDED_BROKERS: {
      const fetchAction = action as FetchBrokersAction;
      return {
        ...state,
        isRefreshing: fetchAction.isRefreshing || false,
        isLoadingOnboarded: true,
        onboardedBrokers: [],
      };
    }
    case FETCH_MORE_FAVORITE_BROKERS:
    case FETCH_MORE_BLOCKED_BROKERS:
    case FETCH_MORE_ONBOARDED_BROKERS:
      return { ...state, isLoadingMore: true };
    case FAVORITE_BROKER:
    case ONBOARD_BROKER:
    case HIDE_BROKER:
      return {
        ...state,
        brokersUpdate: {
          isLoading: true,
        },
        isLoadingBlocked: true,
      };
    case BROKER_HIDDEN: {
      const hideAction = action as CompanyResponseAction;
      const {
        newFavoriteBrokers,
        newFavoriteBrokersCount,
        newBlockedBrokers,
        newBlockedBrokersCount,
        newOnboardBrokers,
        newOnboardBrokersCount,
      } = updateBrokersListsFollowingFavouriteOrBlock(state, hideAction.updated, 'block');
      return {
        ...state,
        isLoadingBlocked: false,
        brokersUpdate: {
          isLoading: false,
          updatedSuccessfully: hideAction.response.success,
          updateTime: Date.now(),
          updateType:
            hideAction.updated.length > 0
              ? hideAction.updated[0].value === false
                ? BrokerActionType.Unblock
                : BrokerActionType.Block
              : undefined,
          error: hideAction.response.error,
        },
        blockedBrokers: newBlockedBrokers,
        blockedBrokersCount: newBlockedBrokersCount,
        favoriteBrokers: newFavoriteBrokers,
        favoriteBrokersCount: newFavoriteBrokersCount,
        onboardedBrokers: newOnboardBrokers,
        onboardedBrokersCount: newOnboardBrokersCount,
        blockedBrokersRequest: {
          ...state.blockedBrokersRequest,
          offset:
            state.favoriteBrokersRequest.offset - hideAction.updated.length > 0 && hideAction.updated[0].value === false
              ? hideAction.updated.length
              : 0,
        },
      };
    }
    case BROKER_FAVORITED: {
      const favoriteAction = action as CompanyResponseAction;
      const {
        newFavoriteBrokers,
        newFavoriteBrokersCount,
        newBlockedBrokers,
        newBlockedBrokersCount,
        newOnboardBrokers,
        newOnboardBrokersCount,
      } = updateBrokersListsFollowingFavouriteOrBlock(state, favoriteAction.updated, 'favourite');
      return {
        ...state,
        isLoadingFavorites: false,
        isLoadingBlocked: false,
        brokersUpdate: {
          isLoading: false,
          updatedSuccessfully: favoriteAction.response.success,
          updateTime: Date.now(),
          updateType:
            favoriteAction.updated.length > 0
              ? favoriteAction.updated[0].value === false
                ? BrokerActionType.Unfavorite
                : BrokerActionType.Favorite
              : undefined,
          error: favoriteAction.response.error,
        },
        favoriteBrokers: newFavoriteBrokers,
        favoriteBrokersCount: newFavoriteBrokersCount,
        blockedBrokers: newBlockedBrokers,
        blockedBrokersCount: newBlockedBrokersCount,
        onboardedBrokers: newOnboardBrokers,
        onboardedBrokersCount: newOnboardBrokersCount,
        favoriteBrokersRequest: {
          ...state.favoriteBrokersRequest,
          offset:
            state.favoriteBrokersRequest.offset - favoriteAction.updated.length > 0 &&
            favoriteAction.updated[0].value === false
              ? favoriteAction.updated.length
              : 0,
        },
      };
    }
    case BROKER_ONBOARDED: {
      const onboardAction = action as CompanyResponseAction;
      const {
        newOnboardBrokers,
        newOnboardBrokersCount,
        newFavoriteBrokers,
        newFavoriteBrokersCount,
        newBlockedBrokers,
        newBlockedBrokersCount,
      } = updateBrokersListsFollowingFavouriteOrBlock(state, onboardAction.updated, 'onboard');
      return {
        ...state,
        isLoadingOnboarded: false,
        isLoadingBlocked: false,
        brokersUpdate: {
          isLoading: false,
          updatedSuccessfully: onboardAction.response.success,
          updateTime: Date.now(),
          updateType:
            onboardAction.updated.length > 0
              ? onboardAction.updated[0].value === false
                ? BrokerActionType.Unonboarded
                : BrokerActionType.Onboard
              : undefined,
          error: onboardAction.response.error,
        },
        onboardedBrokers: newOnboardBrokers,
        onboardedBrokersCount: newOnboardBrokersCount,
        blockedBrokers: newBlockedBrokers,
        blockedBrokersCount: newBlockedBrokersCount,
        favoriteBrokers: newFavoriteBrokers,
        favoriteBrokersCount: newFavoriteBrokersCount,
        onboardedBrokersRequest: {
          ...state.onboardedBrokersRequest,
          offset:
            state.onboardedBrokersRequest.offset - onboardAction.updated.length > 0 &&
            onboardAction.updated[0].value === false
              ? onboardAction.updated.length
              : 0,
        },
      };
    }
    case FAVORITE_BROKERS_FETCHED: {
      const favoriteBrokersAction = (action as BrokerResponseAction).response.payload;
      if (favoriteBrokersAction) {
        const newFavoriteBrokers = state.isRefreshing
          ? favoriteBrokersAction.companies
          : uniqWith(
              concat(state.favoriteBrokers, favoriteBrokersAction.companies),
              (company1: UserDataCompany, company2: UserDataCompany) => company1.id === company2.id
            );
        return {
          ...state,
          isLoadingFavorites: false,
          isLoadingMore: false,
          isRefreshing: false,
          favoriteBrokers: newFavoriteBrokers,
          isLastResult: favoriteBrokersAction.companies.length < state.favoriteBrokersRequest.limit,
        };
      }
      return { ...state, isLoadingFavorites: false, isLoadingMore: false, isRefreshing: false };
    }
    case BLOCKED_BROKERS_FETCHED: {
      const blockedBrokersAction = (action as BrokerResponseAction).response.payload;
      if (blockedBrokersAction) {
        const newBlockedBrokers = state.isRefreshing
          ? blockedBrokersAction.companies
          : uniqWith(
              concat(state.blockedBrokers || [], blockedBrokersAction.companies),
              (company1: UserDataCompany, company2: UserDataCompany) => company1.id === company2.id
            );
        return {
          ...state,
          isLoadingBlocked: false,
          isLoadingMore: false,
          isRefreshing: false,
          blockedBrokers: newBlockedBrokers,
          isLastResult: blockedBrokersAction.companies.length < state.blockedBrokersRequest.limit,
        };
      }
      return { ...state, isLoadingBlocked: false, isLoadingMore: false, isRefreshing: false };
    }
    case ONBOARDED_BROKERS_FETCHED: {
      const onboardedBrokersAction = (action as BrokerResponseAction).response.payload;
      if (onboardedBrokersAction) {
        const newOnboardedBrokers = state.isRefreshing
          ? onboardedBrokersAction.companies
          : uniqWith(
              concat(state.onboardedBrokers || [], onboardedBrokersAction.companies),
              (company1: UserDataCompany, company2: UserDataCompany) => company1.id === company2.id
            );
        return {
          ...state,
          isLoadingOnboarded: false,
          isLoadingMore: false,
          isRefreshing: false,
          onboardedBrokers: newOnboardedBrokers,
          isLastResult: onboardedBrokersAction.companies.length < state.onboardedBrokersRequest.limit,
        };
      }
      return { ...state, isLoadingOnboarded: false, isLoadingMore: false, isRefreshing: false };
    }
    case FETCH_FAVORITE_BROKERS_COUNT:
      return { ...state, isLoadedFavoritesCount: false };
    case FETCH_BLOCKED_BROKERS_COUNT:
      return { ...state, isLoadedBlockedCount: false };
    case FETCH_ONBOARDED_BROKERS_COUNT:
      return { ...state, isLoadedOnboardedCount: false };
    case FAVORITE_BROKERS_COUNT_FETCHED: {
      const favoriteBrokersAction = action as BrokerResponseAction;
      if (favoriteBrokersAction.response.payload) {
        return {
          ...state,
          favoriteBrokersCount: favoriteBrokersAction.response.payload.metadata.totalResultCount,
          isLoadedFavoritesCount: true,
        };
      }
      return state;
    }
    case BLOCKED_BROKERS_COUNT_FETCHED: {
      const blockedBrokersAction = action as BrokerResponseAction;

      if (blockedBrokersAction.response.payload) {
        return {
          ...state,
          blockedBrokersCount: blockedBrokersAction.response.payload.metadata.totalResultCount,
          isLoadedBlockedCount: true,
        };
      }
      return state;
    }
    case ONBOARDED_BROKERS_COUNT_FETCHED: {
      const onboardedBrokersAction = action as BrokerResponseAction;
      if (onboardedBrokersAction.response.payload) {
        return {
          ...state,
          onboardedBrokersCount: onboardedBrokersAction.response.payload.metadata.totalResultCount,
          isLoadedOnboardedCount: true,
        };
      }
      return state;
    }
    case SET_FREE_TEXT_FILTER: {
      const setFreeTextFilterAction = action as SetFreeTextFilterAction;
      return {
        ...state,
        freeTextFilter: setFreeTextFilterAction.companyName,
      };
    }
    case CLEAR_BROKER_UPDATE: {
      return {
        ...state,
        brokersUpdate: {
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};

const favoriteBroker$ = (client: CompanyClient, action$: ActionsObservable<Action>) =>
  action$.ofType(FAVORITE_BROKER).pipe(
    flatMap$((action: FavoriteBrokersAction) => {
      const brokers: Array<CompanyUserData<boolean>> = map(action.brokers, (broker) => ({
        id: broker.id,
        value: broker.favorite,
      }));
      return client.favoriteBrokers$(brokers, action.archivingFlowID).pipe(
        map$((response: ApiResponse123<{}>) => {
          if (response && response.success) {
            return brokerResponse({ success: response.success }, BROKER_FAVORITED, brokers);
          } else {
            const error = response as ApiError;
            return brokerResponse({ success: response.success, error: error }, BROKER_FAVORITED, brokers);
          }
        })
      );
    })
  );

const onboardedBroker$ = (client: CompanyClient, action$: ActionsObservable<Action>) =>
  action$.ofType(ONBOARD_BROKER).pipe(
    flatMap$((action: OnboardBrokersAction) => {
      const brokers: Array<CompanyUserData<boolean>> = map(action.brokers, (broker) => ({
        id: broker.id,
        value: broker.onboarded,
      }));
      return client.onboardedBrokers$(brokers, action.archivingFlowID).pipe(
        map$((response: ApiResponse123<{}>) => {
          if (response && response.success) {
            return brokerResponse({ success: response.success }, BROKER_ONBOARDED, brokers);
          } else {
            const error = response as ApiError;
            return brokerResponse({ success: response.success, error: error }, BROKER_ONBOARDED, brokers);
          }
        })
      );
    })
  );

const updatedBrokers$ = (state$: StateObservable<PartialStateBrokers>, action$: ActionsObservable<Action>) =>
  action$
    .ofType(BROKER_FAVORITED, BROKER_HIDDEN, BROKER_ONBOARDED)
    .pipe(
      mergeMap$(() =>
        if$(
          () => shouldFetchBrokerCountsFromServer(state$.value.brokers),
          of$(fetchFavoriteBrokersCount(), fetchBlockedBrokersCount(), fetchOnboardedBrokersCount())
        )
      )
    );

const hideBroker$ = (client: CompanyClient, action$: ActionsObservable<Action>) =>
  action$.ofType(HIDE_BROKER).pipe(
    flatMap$((action: HideBrokersAction) => {
      const brokers: Array<CompanyUserData<boolean>> = map(action.brokers, (broker) => ({
        id: broker.id,
        value: broker.hide,
      }));
      return client.hideBrokers$(brokers, action.archivingFlowID).pipe(
        map$((response: ApiResponse123<{}>) => {
          if (response.success) {
            return blockBrokerResponse({ success: response.success }, BROKER_HIDDEN, brokers, action.isFromBackhauls);
          } else {
            const error = response as ApiError;
            return blockBrokerResponse(
              { success: response.success, error: error },
              BROKER_HIDDEN,
              brokers,
              action.isFromBackhauls
            );
          }
        })
      );
    })
  );

const fetchFavoriteBrokers$ = (
  client: CompanyClient,
  state$: StateObservable<PartialStateBrokers>,
  action$: ActionsObservable<Action>
) =>
  standardApiEpic(
    action$,
    FETCH_FAVORITE_BROKERS,
    () => {
      const request = state$.value.brokers.favoriteBrokersRequest;
      const companyName = state$.value.brokers.freeTextFilter;
      request.limit = BROKER_REQUEST_LIMIT_DEFAULT;
      request.offset = BROKER_REQUEST_OFFSET_DEFAULT;
      return client.getCompanyUserData({ isFavoriteBroker: true, companyName: companyName }, 'companies', request);
    },
    (response) => fetchBrokerResponse(FAVORITE_BROKERS_FETCHED, { success: response.success, payload: response.data }),
    () => fetchBrokerResponse(FAVORITE_BROKERS_FETCHED, { success: false })
  );

const fetchMoreFavoriteBrokers$ = (
  client: CompanyClient,
  state$: StateObservable<PartialStateBrokers>,
  action$: ActionsObservable<Action>
) =>
  action$.ofType(FETCH_MORE_FAVORITE_BROKERS).pipe(
    mergeMap$(() => {
      if (state$.value.brokers.isLastResult) {
        return of$(
          fetchBrokerResponse(FAVORITE_BROKERS_FETCHED, {
            success: true,
            payload: {
              companies: [],
              id: '',
              metadata: {
                totalResultCount: 0,
                queryTime: 0,
                pageResultCount: 0,
                currentOffset: 0,
              },
            },
          })
        );
      }
      const request = state$.value.brokers.favoriteBrokersRequest;
      const companyName = state$.value.brokers.freeTextFilter;
      request.limit = BROKER_REQUEST_LIMIT_APPENDING;
      request.offset =
        request.offset === 0 ? BROKER_REQUEST_LIMIT_DEFAULT : request.offset + BROKER_REQUEST_OFFSET_INCREMENT;
      return client.getCompanyUserData({ isFavoriteBroker: true, companyName: companyName }, 'companies', request).pipe(
        map$((response) => {
          return response.result(
            (data) =>
              fetchBrokerResponse(FAVORITE_BROKERS_FETCHED, {
                success: true,
                payload: data,
              }),
            (error) =>
              fetchBrokerResponse(FAVORITE_BROKERS_FETCHED, {
                success: false,
                error: error,
              })
          );
        })
      );
    })
  );

const fetchBlockedBrokers$ = (
  client: CompanyClient,
  state$: StateObservable<PartialStateBrokers>,
  action$: ActionsObservable<Action>
) =>
  standardApiEpic(
    action$,
    FETCH_BLOCKED_BROKERS,
    () => {
      const request = state$.value.brokers.blockedBrokersRequest;
      const companyName = state$.value.brokers.freeTextFilter;
      request.limit = BROKER_REQUEST_LIMIT_DEFAULT;
      request.offset = BROKER_REQUEST_OFFSET_DEFAULT;
      return client.getCompanyUserData({ isHiddenBroker: true, companyName: companyName }, 'companies', request);
    },
    (response) => fetchBrokerResponse(BLOCKED_BROKERS_FETCHED, { success: response.success, payload: response.data }),
    () => fetchBrokerResponse(BLOCKED_BROKERS_FETCHED, { success: false })
  );

const fetchMoreBlockedBrokers$ = (
  client: CompanyClient,
  state$: StateObservable<PartialStateBrokers>,
  action$: ActionsObservable<Action>
) =>
  action$.ofType(FETCH_MORE_BLOCKED_BROKERS).pipe(
    mergeMap$(() => {
      if (state$.value.brokers.isLastResult) {
        return of$(
          fetchBrokerResponse(BLOCKED_BROKERS_FETCHED, {
            success: true,
            payload: {
              companies: [],
              id: '',
              metadata: {
                totalResultCount: 0,
                queryTime: 0,
                pageResultCount: 0,
                currentOffset: 0,
              },
            },
          })
        );
      }
      const request = state$.value.brokers.blockedBrokersRequest;
      const companyName = state$.value.brokers.freeTextFilter;
      request.limit = BROKER_REQUEST_LIMIT_APPENDING;
      request.offset =
        request.offset === 0 ? BROKER_REQUEST_LIMIT_DEFAULT : request.offset + BROKER_REQUEST_OFFSET_INCREMENT;
      return client.getCompanyUserData({ isHiddenBroker: true, companyName: companyName }, 'companies', request).pipe(
        map$((response) => {
          return response.result(
            (data) =>
              fetchBrokerResponse(BLOCKED_BROKERS_FETCHED, {
                success: true,
                payload: data,
              }),
            (error) =>
              fetchBrokerResponse(BLOCKED_BROKERS_FETCHED, {
                success: false,
                error: error,
              })
          );
        })
      );
    })
  );

const fetchOnboardedBrokers$ = (
  client: CompanyClient,
  state$: StateObservable<PartialStateBrokers>,
  action$: ActionsObservable<Action>
) =>
  standardApiEpic(
    action$,
    FETCH_ONBOARDED_BROKERS,
    () => {
      const request = state$.value.brokers.onboardedBrokersRequest;
      const companyName = state$.value.brokers.freeTextFilter;
      request.limit = BROKER_REQUEST_LIMIT_DEFAULT;
      request.offset = BROKER_REQUEST_OFFSET_DEFAULT;
      return client.getCompanyUserData({ isOnboarded: true, companyName: companyName }, 'companies', request);
    },
    (response) => fetchBrokerResponse(ONBOARDED_BROKERS_FETCHED, { success: response.success, payload: response.data }),
    () => fetchBrokerResponse(ONBOARDED_BROKERS_FETCHED, { success: false })
  );

const fetchMoreOnboardedBrokers$ = (
  client: CompanyClient,
  state$: StateObservable<PartialStateBrokers>,
  action$: ActionsObservable<Action>
) =>
  action$.ofType(FETCH_MORE_ONBOARDED_BROKERS).pipe(
    mergeMap$(() => {
      if (state$.value.brokers.isLastResult) {
        return of$(
          fetchBrokerResponse(ONBOARDED_BROKERS_FETCHED, {
            success: true,
            payload: {
              companies: [],
              id: '',
              metadata: {
                totalResultCount: 0,
                queryTime: 0,
                pageResultCount: 0,
                currentOffset: 0,
              },
            },
          })
        );
      }
      const request = state$.value.brokers.onboardedBrokersRequest;
      const companyName = state$.value.brokers.freeTextFilter;
      request.limit = BROKER_REQUEST_LIMIT_APPENDING;
      request.offset =
        request.offset === 0 ? BROKER_REQUEST_LIMIT_DEFAULT : request.offset + BROKER_REQUEST_OFFSET_INCREMENT;
      return client.getCompanyUserData({ isOnboarded: true, companyName: companyName }, 'companies', request).pipe(
        map$((response) => {
          return response.result(
            (data) =>
              fetchBrokerResponse(ONBOARDED_BROKERS_FETCHED, {
                success: true,
                payload: data,
              }),
            (error) =>
              fetchBrokerResponse(ONBOARDED_BROKERS_FETCHED, {
                success: false,
                error: error,
              })
          );
        })
      );
    })
  );

const fetchFavoriteBrokersCount$ = (
  client: CompanyClient,
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialStateBrokers>
) =>
  standardApiEpic(
    action$,
    FETCH_FAVORITE_BROKERS_COUNT,
    () =>
      client.getCompanyUserData(
        { isFavoriteBroker: true, companyName: state$.value.brokers.freeTextFilter },
        'metadata'
      ),
    (response) =>
      fetchBrokerResponse(FAVORITE_BROKERS_COUNT_FETCHED, { success: response.success, payload: response.data }),
    () => fetchBrokerResponse(FAVORITE_BROKERS_COUNT_FETCHED, { success: false })
  );

const fetchBlockedBrokersCount$ = (
  client: CompanyClient,
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialStateBrokers>
) =>
  standardApiEpic(
    action$,
    FETCH_BLOCKED_BROKERS_COUNT,
    () =>
      client.getCompanyUserData({ isHiddenBroker: true, companyName: state$.value.brokers.freeTextFilter }, 'metadata'),
    (response) =>
      fetchBrokerResponse(BLOCKED_BROKERS_COUNT_FETCHED, { success: response.success, payload: response.data }),
    () => fetchBrokerResponse(BLOCKED_BROKERS_COUNT_FETCHED, { success: false })
  );

const fetchOnboardedBrokersCount$ = (
  client: CompanyClient,
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialStateBrokers>
) =>
  standardApiEpic(
    action$,
    FETCH_ONBOARDED_BROKERS_COUNT,
    () =>
      client.getCompanyUserData({ isOnboarded: true, companyName: state$.value.brokers.freeTextFilter }, 'metadata'),
    (response) => {
      return fetchBrokerResponse(ONBOARDED_BROKERS_COUNT_FETCHED, {
        success: response.success,
        payload: response.data,
      });
    },
    () => fetchBrokerResponse(ONBOARDED_BROKERS_COUNT_FETCHED, { success: false })
  );

export const createBrokerEpic = <T extends PartialStateBrokers>(api: Api) => {
  const companyClient = new CompanyClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<T>) =>
    merge$(
      favoriteBroker$(companyClient, action$),
      onboardedBroker$(companyClient, action$),
      hideBroker$(companyClient, action$),
      updatedBrokers$(state$, action$),
      fetchFavoriteBrokers$(companyClient, state$, action$),
      fetchMoreFavoriteBrokers$(companyClient, state$, action$),
      fetchBlockedBrokers$(companyClient, state$, action$),
      fetchMoreBlockedBrokers$(companyClient, state$, action$),
      fetchOnboardedBrokers$(companyClient, state$, action$),
      fetchMoreOnboardedBrokers$(companyClient, state$, action$),
      fetchFavoriteBrokersCount$(companyClient, action$, state$),
      fetchBlockedBrokersCount$(companyClient, action$, state$),
      fetchOnboardedBrokersCount$(companyClient, action$, state$)
    );
};

const updateBrokersListsFollowingFavouriteOrBlock = (
  state: BrokerState,
  updatedBrokers: Array<CompanyUserData<boolean>>,
  updateType: UpdateType
) => {
  let newBlockedBrokers = state.blockedBrokers;
  let newBlockedBrokersCount = state.blockedBrokersCount;
  let newFavoriteBrokers = state.favoriteBrokers;
  let newFavoriteBrokersCount = state.favoriteBrokersCount;
  let newOnboardBrokers = state.onboardedBrokers;
  let newOnboardBrokersCount = state.onboardedBrokersCount;
  switch (updateType) {
    case 'block':
      newBlockedBrokers = updateBrokers(updatedBrokers, state.blockedBrokers);
      newFavoriteBrokers = removeBrokers(updatedBrokers, state.favoriteBrokers);
      break;
    case 'favourite':
      newFavoriteBrokers = updateBrokers(updatedBrokers, state.favoriteBrokers);
      newOnboardBrokers = changeRelatedBrokers(updatedBrokers, state.onboardedBrokers, updateType);
      newBlockedBrokers = removeBrokers(updatedBrokers, state.blockedBrokers);
      break;
    case 'onboard':
      newOnboardBrokers = updateBrokers(updatedBrokers, state.onboardedBrokers);
      newFavoriteBrokers = changeRelatedBrokers(updatedBrokers, state.favoriteBrokers, updateType);
      newBlockedBrokers = changeRelatedBrokers(updatedBrokers, state.blockedBrokers, updateType);
      break;
  }
  if (!shouldFetchBrokerCountsFromServer(state)) {
    newBlockedBrokersCount = newBlockedBrokers.length;
    newFavoriteBrokersCount = newFavoriteBrokers.length;
    newOnboardBrokersCount = newOnboardBrokers.length;
  }

  return {
    newBlockedBrokers: newBlockedBrokers,
    newBlockedBrokersCount: newBlockedBrokersCount,
    newFavoriteBrokers: newFavoriteBrokers,
    newFavoriteBrokersCount: newFavoriteBrokersCount,
    newOnboardBrokers: newOnboardBrokers,
    newOnboardBrokersCount: newOnboardBrokersCount,
  };
};

/**
 * If the user never navigates to the My Companies screen, then they
 * will have the broker counts populated, but not the broker arrays.
 * This makes it difficult to update both counts, since, for example,
 * if a broker is favourited, we don't know whether it was previously
 * blocked or not, so we can't be sure whether or not to subtract 1 from the
 * blocked brokers count. For these cases, we forego updating the counts
 * ourselves and just trigger a fresh fetch of the counts from the server.
 */
const shouldFetchBrokerCountsFromServer = (state: BrokerState) =>
  state.favoriteBrokers.length !== state.favoriteBrokersCount ||
  state.blockedBrokers.length !== state.blockedBrokersCount ||
  state.onboardedBrokers.length !== state.onboardedBrokersCount;

/**
 * removes all brokers in the array brokers for which their id equals the
 * id of an entry in updatedBrokers that has value = true.
 */
const removeBrokers = (updatedBrokers: Array<CompanyUserData<boolean>>, brokers: UserDataCompany[]) =>
  filter(
    brokers,
    (broker: UserDataCompany) =>
      !some(
        updatedBrokers,
        (updatedBroker: CompanyUserData<boolean>) => updatedBroker.value && updatedBroker.id === broker.id
      )
  );

const updateBrokers = (updated: Array<CompanyUserData<boolean>>, brokers: UserDataCompany[]) => {
  let newBrokers = brokers;
  forEach(updated, (update) => {
    if (update.value) {
      newBrokers.push({ ...emptyBrokerData, id: update.id });
    } else {
      newBrokers = filter(newBrokers, (broker) => broker.id !== update.id);
    }
  });
  return newBrokers;
};

/**
 * change brokers metadata according to update type (favorite/block/onboard) to update icons in brokers list.
 * */
const changeRelatedBrokers = (
  updatedBrokers: CompanyUserData<boolean>[],
  brokers: UserDataCompany[],
  updateType: UpdateType
) =>
  map(brokers, (broker) => {
    const changedBroker = find(updatedBrokers, (updatedBroker) => updatedBroker.id === broker.id);
    return changedBroker !== undefined ? changeBrokerMetadata(broker, updateType, changedBroker.value) : broker;
  });

const changeBrokerMetadata = (
  broker: UserDataCompany,
  updateType: UpdateType,
  updateValue: boolean
): UserDataCompany => {
  const brokerMetadata: CompanyMetadata = {
    favoriteBroker: broker.metaData?.favoriteBroker ?? false,
    favoriteCarrier: broker.metaData?.favoriteCarrier ?? false,
    hiddenBroker: broker.metaData?.hiddenBroker ?? false,
    hiddenCarrier: broker.metaData?.hiddenCarrier ?? false,
    onboarded: broker.metaData?.onboarded ?? false,
  };
  switch (updateType) {
    case 'block':
      return {
        ...broker,
        metaData: {
          ...brokerMetadata,
          favoriteBroker: updateValue ? false : brokerMetadata.favoriteBroker,
          hiddenBroker: updateValue,
        },
      };
    case 'favourite':
      return {
        ...broker,
        metaData: {
          ...brokerMetadata,
          hiddenBroker: updateValue ? false : brokerMetadata.hiddenBroker,
          favoriteBroker: updateValue,
        },
      };
    case 'onboard':
      return {
        ...broker,
        metaData: {
          ...brokerMetadata,
          onboarded: updateValue,
        },
      };
  }
  return broker;
};

const emptyBrokerData: UserDataCompany = {
  id: '',
  metaData: {
    favoriteBroker: false,
    favoriteCarrier: false,
    hiddenBroker: true,
    hiddenCarrier: false,
    onboarded: false,
  },
  docketNumber: {
    number: 0,
  },
  name: '',
  address: {
    city: '',
    state: '',
  },
  phone: { number: '' },
  mcNumber: 0,
  usdotNumber: 0,
  isOcfp: false,
  onboardingUrl: '',
};

import { BaseClient } from '@common/client/BaseClient';
import { InvoiceListResponse, InvoicesRequest } from '@common/model';
import { PaymentDecline, PaymentInfo, PeriodResponse, PlanPaymentInfo } from '@common/model/Payment';

export class PaymentClient extends BaseClient {
  sendPayment$ = (planPaymentInfo: PlanPaymentInfo) =>
    this.api.post$<{}>('/memberships', planPaymentInfo, undefined, { longTimeout: true });

  reactivatePayment$ = (planPaymentInfo: PlanPaymentInfo) =>
    this.api.post$<{}>('/memberships/reactivate', planPaymentInfo, undefined, { longTimeout: true });

  updatePaymentCard$ = (creditCardInfo: PaymentInfo) =>
    this.api.post$<{}>('/membership/updateCard', creditCardInfo, undefined, { longTimeout: true });

  getPaymentHistory$ = (id: string, request: InvoicesRequest) =>
    this.api.get$<InvoiceListResponse>(`billing/account/${id}/invoices`, request);

  getPaymentYears$ = (id: string) =>
    this.api.get$<PeriodResponse>(`billing/account/${id}/invoices/aggregate`, {
      fields: 'periods{date}',
      period: 'Year',
    });

  fetchPaymentDeclineInfo$ = (id: string) => this.api.get$<PaymentDecline>(`billing/account/${id}/declines`, {});
}

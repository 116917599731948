import React, { Component } from 'react';

import { Menu } from '@material-ui/core';

import { Color } from '@style/Color';
import { MOBILE_MENU_ZINDEX } from '@style/StyleConstants';

interface Props {
  anchor: HTMLElement | undefined;
  onClose: () => void;
}

export class DropdownMenuSelect extends Component<Props> {
  render() {
    const { anchor, onClose } = this.props;
    return (
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="customized-menu"
        anchorEl={anchor}
        keepMounted={true}
        open={!!anchor}
        onClose={onClose}
        disableAutoFocusItem={true}
        style={{ zIndex: MOBILE_MENU_ZINDEX + 10 }}
        PaperProps={{
          style: {
            marginTop: '2px',
            minWidth: '184px',
            boxShadow: `0 2px 8px ${Color.TRANSPARENT_30}`,
          },
        }}
      >
        {this.props.children}
      </Menu>
    );
  }
}

import { pickBy } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123 } from '@common/api';
import { RateCheckClient } from '@common/client';
import { LoadAction } from '@common/helper';
import {
  MarketRankingRequest,
  MarketRankingResponse,
  NegotiationStrength,
  PartialRateCheckFeedback,
  RateCheck,
  RateCheckAverageRequest,
  RateCheckAverageResponse,
  RateCheckFeedback,
  RateCheckFeedbackCollection,
  RateCheckRequest,
  RateCheckRevenueData,
  RateCheckRevenueResponse,
} from '@common/model';
import { BaseState, createApiAction, createApiActionWithFetchData, Response, ResponseAction } from '@common/redux/Base';
import { LOAD_RATE_CHECK_FETCHED, LoadRateCheckResponseAction } from '@common/redux/epic/LoadInfoEpic';
import { createReducer } from '@reduxjs/toolkit';

import {
  createFeedback,
  createLoadRateCheckHistoryRequest,
  createRateCheckHistoricalRequest,
  createRateCheckHistoryRequest,
  filterOldAndFailed,
  parseRateCheckHistory,
  serializeRequest,
} from './rateCheck/RateCheckHelper';

const FETCH_RATE_CHECK = 'FETCH_RATE_CHECK';
const RATE_CHECK_FETCHED = 'RATE_CHECK_FETCHED';
const CLEAR_RATE_CHECK = 'CLEAR_RATE_CHECK';
const UPDATE_RATE_CHECK_FEEDBACK = 'UPDATE_RATE_CHECK_FEEDBACK';
const SEND_RATE_CHECK_FEEDBACK = 'SEND_RATE_CHECK_FEEDBACK';
const SEND_LOAD_RATE_CHECK_FEEDBACK = 'SEND_LOAD_RATE_CHECK_FEEDBACK';
const RATE_CHECK_FEEDBACK_RESPONSE = 'RATE_CHECK_FEEDBACK_RESPONSE';
const CLEAR_RATE_CHECK_FEEDBACK = 'CLEAR_RATE_CHECK_FEEDBACK';
const FETCH_LOAD_RATE_CHECK_HISTORY = 'FETCH_LOAD_RATE_CHECK_HISTORY';
const FETCH_RATE_CHECK_HISTORY = 'FETCH_RATE_CHECK_HISTORY';
const RATE_CHECK_HISTORY_FETCHED = 'RATE_CHECK_HISTORY_FETCHED';

export type RateCheckResponse = Response<RateCheck>;

interface FetchRateCheck extends Action {
  request: RateCheckRequest;
}

interface FetchRateCheckHistory extends FetchRateCheck {
  id: string;
}

type FetchRateCheckResponse = ResponseAction<RateCheck>;

interface SetFeedbackAction extends Action {
  newFeedback: PartialRateCheckFeedback | undefined;
  rateCheckID: string;
}

interface SendRateCheckFeedbackAction extends Action {
  feedback: RateCheckFeedback;
  rateCheckID: string;
  response: RateCheck;
  archivingFlowID: string | undefined;
}

interface FetchLoadRateCheckHistory extends LoadAction {
  archivingFlowID: string | undefined;
}

export type RateCheckHistoryResponse = { revenues: RateCheckRevenueData[]; id: string; success: boolean };

export interface RateCheckHistory {
  revenues: RateCheckRevenueData[];
  fuelCost: number;
  tollCost: number;
  mpg: number;
  mileage: number;
}

interface ClearRateCheckAction extends Action {
  rateCheckID: string;
}

export interface RateCheckHistoryInformation {
  data: RateCheckRevenueResponse | undefined;
  isSuccess: boolean | undefined;
  isLoading: boolean;
  timeAdded: number;
}

export interface RateCheckState extends BaseState {
  rateCheck: RateCheck | undefined;
  rateCheckError: ApiError | undefined;
  feedback: RateCheckFeedbackCollection;
  request: RateCheckRequest | undefined;
  loadRateCheckID: string | undefined;
  history: {
    [id: string]: RateCheckHistoryInformation;
  };
  historical: {
    [id: string]: RateCheckHistoryInformation;
  };
  negotiationStrength?: NegotiationStrength;
  backhaulRateDifference?: number;
  rateCheckAverage?: RateCheckAverageResponse;
  isLoadingRateCheckAverage: boolean;
}

export const fetchRateCheck = (request: RateCheckRequest): FetchRateCheck => ({
  type: FETCH_RATE_CHECK,
  request: request,
});

export const fetchRateCheckHistory = (request: RateCheckRequest): FetchRateCheckHistory => ({
  type: FETCH_RATE_CHECK_HISTORY,
  request: request,
  id: serializeRequest(request),
});

export const fetchLoadRateCheckHistory = (
  loadID: string,
  archivingFlowID: string | undefined
): FetchLoadRateCheckHistory => ({
  type: FETCH_LOAD_RATE_CHECK_HISTORY,
  loadID: loadID,
  archivingFlowID: archivingFlowID,
});

export const clearRateCheck = (): Action => ({
  type: CLEAR_RATE_CHECK,
});

export const updateRateCheckFeedback = (newFeedback: RateCheckFeedback, rateCheckID: string): SetFeedbackAction => ({
  type: UPDATE_RATE_CHECK_FEEDBACK,
  newFeedback: newFeedback,
  rateCheckID: rateCheckID,
});

export const clearRateCheckFeedback = (rateCheckID: string): ClearRateCheckAction => ({
  type: CLEAR_RATE_CHECK_FEEDBACK,
  rateCheckID: rateCheckID,
});

export const sendRateCheckFeedback = (
  rateCheckID: string,
  feedback: RateCheckFeedback,
  response: RateCheck
): SendRateCheckFeedbackAction => ({
  type: SEND_RATE_CHECK_FEEDBACK,
  rateCheckID: rateCheckID,
  feedback: feedback,
  response: response,
  archivingFlowID: undefined,
});

export const sendLoadRateCheckFeedback = (
  rateCheckID: string,
  feedback: RateCheckFeedback,
  response: RateCheck,
  archivingFlowID: string | undefined
): SendRateCheckFeedbackAction => ({
  type: SEND_LOAD_RATE_CHECK_FEEDBACK,
  rateCheckID: rateCheckID,
  feedback: feedback,
  response: response,
  archivingFlowID: archivingFlowID,
});

const fetchMarketRankingsAction = createApiAction<MarketRankingRequest, MarketRankingResponse>('FETCH_MARKET_RANKINGS');
const fetchRateCheckHistoricalAction = createApiActionWithFetchData<
  RateCheckRequest & { id: string },
  RateCheckHistory
>('FETCH_RATE_CHECK_HISTORICAL');
const fetchRateCheckAverageAction = createApiAction<RateCheckAverageRequest, RateCheckAverageResponse>(
  'FETCH_RATECHECK_AVERAGE_IN_PERIOD'
);

export const fetchMarketRankings = (request: MarketRankingRequest) => fetchMarketRankingsAction.fetchAction(request);
export const fetchRateCheckHistorical = (request: RateCheckRequest) =>
  fetchRateCheckHistoricalAction.fetchAction({ ...request, id: serializeRequest(request) });
export const fetchRateCheckAverage = (request: RateCheckAverageRequest) =>
  fetchRateCheckAverageAction.fetchAction(request);

export const initialFeedbackState: Readonly<RateCheckFeedback> = {
  expectedRate: undefined,
  isLoading: false,
  isRateCheckAccurate: undefined,
  isSubmitted: false,
  isSuccess: false,
  response: undefined,
  timeAdded: 0,
  request: undefined,
  loadID: undefined,
};

const initialState: Readonly<RateCheckState> = {
  isLoading: false,
  rateCheckError: undefined,
  feedback: {},
  request: undefined,
  loadRateCheckID: undefined,
  rateCheck: undefined,
  history: {},
  historical: {},
  isLoadingRateCheckAverage: false,
};

const createInitialRateCheckHistory = (): RateCheckHistoryInformation => ({
  data: undefined,
  isSuccess: undefined,
  isLoading: true,
  timeAdded: Date.now(),
});

type ResponseWithRateCheckID = ResponseAction<{}> & {
  rateCheckID: string;
};

const sendFeedbackReducerCase = (
  state: Readonly<RateCheckState>,
  { rateCheckID, feedback }: SendRateCheckFeedbackAction
) => {
  state.feedback[rateCheckID] = state.feedback[rateCheckID]
    ? {
        ...state.feedback[rateCheckID],
        ...feedback,
        isLoading: true,
        isSubmitted: true,
      }
    : newFeedback({ ...feedback, isLoading: true, isSubmitted: true });
};

export const rateCheckReducer = createReducer(initialState, {
  [RATE_CHECK_FETCHED]: (state, { response }: FetchRateCheckResponse) => {
    state.isLoading = false;
    state.rateCheck = response.payload;
    state.rateCheckError = response.error;
  },

  [FETCH_RATE_CHECK]: (state, { request }: FetchRateCheck) => {
    state.rateCheck = undefined;
    state.isLoading = true;
    state.rateCheckError = undefined;
    state.request = request;
    state.loadRateCheckID = undefined;
    state.feedback = filterOldAndFailed(state.feedback);
  },

  [LOAD_RATE_CHECK_FETCHED]: (state, { loadID, response }: LoadRateCheckResponseAction) => {
    state.rateCheck = response.payload;
    state.loadRateCheckID = loadID;
    state.feedback = filterOldAndFailed(state.feedback);
  },

  [UPDATE_RATE_CHECK_FEEDBACK]: (state, { newFeedback, rateCheckID }: SetFeedbackAction) => {
    if (state.feedback[rateCheckID]) {
      state.feedback[rateCheckID] = { ...state.feedback[rateCheckID], ...newFeedback };
    } else {
      // If there is not feedback for this request yet, we need to set the request/load id and response
      state.feedback[rateCheckID] = { ...initialFeedbackState, ...newFeedback, timeAdded: Date.now() };
    }
  },

  [SEND_RATE_CHECK_FEEDBACK]: sendFeedbackReducerCase,
  [SEND_LOAD_RATE_CHECK_FEEDBACK]: sendFeedbackReducerCase,

  [RATE_CHECK_FEEDBACK_RESPONSE]: (state, { response, rateCheckID }: ResponseWithRateCheckID) => {
    state.feedback[rateCheckID] = {
      ...state.feedback[rateCheckID],
      isLoading: false,
      isSuccess: response.success,
    };
  },

  [CLEAR_RATE_CHECK_FEEDBACK]: (state, { rateCheckID }: ClearRateCheckAction) => {
    state.feedback = pickBy(state.feedback, (value, key) => key !== rateCheckID);
  },

  [CLEAR_RATE_CHECK]: (state) => ({
    ...initialState,
    feedback: state.feedback,
    history: state.history,
  }),

  [FETCH_LOAD_RATE_CHECK_HISTORY]: (state, action: LoadAction) => {
    state.history = { ...filterOldAndFailed(state.history), [action.loadID]: createInitialRateCheckHistory() };
  },

  [FETCH_RATE_CHECK_HISTORY]: (state, action: FetchRateCheckHistory) => {
    state.history = { ...filterOldAndFailed(state.history), [action.id]: createInitialRateCheckHistory() };
  },

  [RATE_CHECK_HISTORY_FETCHED]: (state, { revenues, id, success }: RateCheckHistoryResponse) => {
    if (!state.history[id]) {
      state.history[id] = createInitialRateCheckHistory();
    }
    state.history[id].isSuccess = success;
    state.history[id].isLoading = false;
    if (success) {
      state.history[id].data = parseRateCheckHistory(revenues, state.rateCheck);
    }
  },

  [fetchMarketRankingsAction.responseType]: (state, action: ResponseAction<MarketRankingResponse>) => {
    if (action.response.success && action.response.payload) {
      const { negotiationStrength, backhaulRateDifference } = action.response.payload;
      state.negotiationStrength = negotiationStrength;
      state.backhaulRateDifference = backhaulRateDifference;
    }
  },
  [fetchRateCheckHistoricalAction.fetchType]: (state, action) => {
    state.historical = { ...filterOldAndFailed(state.historical), [action.data.id]: createInitialRateCheckHistory() };
  },
  [fetchRateCheckHistoricalAction.responseType]: (state, action: ResponseAction<RateCheckHistory>) => {
    const { id } = action.fetchData;
    const { success, payload } = action.response;
    if (!state.historical[id]) {
      state.historical[id] = createInitialRateCheckHistory();
    }
    state.historical[id].isSuccess = success;
    state.historical[id].isLoading = false;
    if (success && payload) {
      state.historical[id].data = parseRateCheckHistory(payload.revenues, state.rateCheck);
    }
  },
  [fetchRateCheckAverageAction.fetchType]: (state) => {
    state.isLoadingRateCheckAverage = true;
    state.rateCheckAverage = undefined;
  },
  [fetchRateCheckAverageAction.responseType]: (state, action) => {
    state.isLoadingRateCheckAverage = false;
    if (action.response.success && action.response.payload) {
      state.rateCheckAverage = action.response.payload;
    }
  },
});

const feedbackResponse = (rateCheckID: string) =>
  map$((response) => ({
    type: RATE_CHECK_FEEDBACK_RESPONSE,
    rateCheckID: rateCheckID,
    response: response,
  }));

const sendRateCheckFeedback$ = (action$: ActionsObservable<Action>, rateCheckClient: RateCheckClient) =>
  action$
    .ofType(SEND_RATE_CHECK_FEEDBACK)
    .pipe(
      mergeMap$(({ rateCheckID, feedback, response }: SendRateCheckFeedbackAction) =>
        rateCheckClient.postRateCheckFeedback$(createFeedback(feedback, response)).pipe(feedbackResponse(rateCheckID))
      )
    );

const sendLoadRateCheckFeedback$ = (action$: ActionsObservable<Action>, rateCheckClient: RateCheckClient) =>
  action$
    .ofType(SEND_LOAD_RATE_CHECK_FEEDBACK)
    .pipe(
      mergeMap$(({ rateCheckID, feedback, response, archivingFlowID }: SendRateCheckFeedbackAction) =>
        rateCheckClient
          .postLoadRateCheckFeedback$(createFeedback(feedback, response), rateCheckID, archivingFlowID)
          .pipe(feedbackResponse(rateCheckID))
      )
    );

const fetchLoadRateCheckHistory$ = (action$: ActionsObservable<Action>, rateCheckClient: RateCheckClient) =>
  action$.ofType(FETCH_LOAD_RATE_CHECK_HISTORY).pipe(
    mergeMap$((action: FetchLoadRateCheckHistory) =>
      rateCheckClient
        .getLoadRateCheckHistory$(createLoadRateCheckHistoryRequest(), action.loadID, action.archivingFlowID)
        .pipe(
          map$((response: ApiResponse123<RateCheckHistoryResponse>) =>
            response.result(
              (data) => ({
                type: RATE_CHECK_HISTORY_FETCHED,
                revenues: data.revenues,
                id: action.loadID,
                success: true,
              }),
              () => ({ type: RATE_CHECK_HISTORY_FETCHED, success: false, id: action.loadID })
            )
          )
        )
    )
  );

const fetchRateCheck$ = (action$: ActionsObservable<Action>, rateCheckClient: RateCheckClient) =>
  action$
    .ofType(FETCH_RATE_CHECK)
    .pipe(
      mergeMap$((rateCheckRequest: FetchRateCheck) =>
        merge$(
          rateCheckClient
            .fetchRateCheck$(rateCheckRequest.request)
            .pipe(map$((response: ApiResponse123<RateCheck>) => response.resultDataResponse(RATE_CHECK_FETCHED)))
        )
      )
    );

const fetchRateCheckHistory$ = (action$: ActionsObservable<Action>, rateCheckClient: RateCheckClient) =>
  action$.ofType(FETCH_RATE_CHECK_HISTORY).pipe(
    mergeMap$((rateCheckRequest: FetchRateCheckHistory) =>
      merge$(
        rateCheckClient.getRateCheckHistory$(createRateCheckHistoryRequest(rateCheckRequest.request)).pipe(
          map$((response: ApiResponse123<RateCheckHistory>) =>
            response.result(
              (data) => ({
                type: RATE_CHECK_HISTORY_FETCHED,
                revenues: data.revenues,
                id: rateCheckRequest.id,
                success: true,
              }),
              () => ({ type: RATE_CHECK_HISTORY_FETCHED, success: false, id: rateCheckRequest.id })
            )
          )
        )
      )
    )
  );

export const createRateChecksEpic = (api: Api) => {
  const rateCheckClient = new RateCheckClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchRateCheck$(action$, rateCheckClient),
      fetchRateCheckHistory$(action$, rateCheckClient),
      fetchLoadRateCheckHistory$(action$, rateCheckClient),
      sendRateCheckFeedback$(action$, rateCheckClient),
      sendLoadRateCheckFeedback$(action$, rateCheckClient),
      fetchMarketRankingsAction.createEpic$(action$, rateCheckClient.getMarketRanking$),
      fetchRateCheckHistoricalAction.createEpic$(action$, (request) =>
        rateCheckClient.getRateCheckHistory$(createRateCheckHistoricalRequest(request))
      ),
      fetchRateCheckAverageAction.createEpic$(action$, rateCheckClient.getRateCheckAverage$)
    );
};

export const newFeedback = (partialFeedback: Partial<RateCheckFeedback>) => ({
  ...initialFeedbackState,
  ...partialFeedback,
  timeAdded: Date.now(),
});

import { useRef } from 'react';

/** returns a simple id string that is unique within the scope
 * of the page javascript session for a given component instance.
 * (refreshing the page resets the internal counter).
 */
export const useUniqueId = (): string => {
  const idRef = useRef<string | undefined>(undefined);

  if (idRef.current === undefined) {
    idRef.current = generateUniqueId();
  }

  return idRef.current;
};

let uniqueId = 0;

const generateUniqueId = () => {
  uniqueId = uniqueId + 1;
  return uniqueId.toString(16);
};

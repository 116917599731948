import { Action } from 'redux';

const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
const DISMISS_SNACKBAR = 'DISMISS_SNACKBAR';

interface Origin {
  vertical: 'top' | 'bottom' | 'center';
  horizontal: 'left' | 'right' | 'center';
}

export interface SnackbarProps {
  position?: Origin;
  message: string;
  variant?: string;
  hideDuration?: number;
  dismissSnackbar?: () => void;
}

export interface SnackbarState extends SnackbarProps {
  open: boolean;
}

interface ShowSnackbarAction extends Action {
  payload: SnackbarProps;
}

export const dismissSnackbar = (): Action => ({ type: DISMISS_SNACKBAR });

export const showSnackbar = (snacbarContents: SnackbarProps): ShowSnackbarAction => ({
  type: SHOW_SNACKBAR,
  payload: snacbarContents,
});

const snackbarInitialState: SnackbarState = {
  open: false,
  message: '',
  variant: '',
  hideDuration: 4000,
};

export const snackbarReducer = (state: SnackbarState = snackbarInitialState, action: Action): SnackbarState => {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      const snackbarAction = action as ShowSnackbarAction;
      if (snackbarAction.payload !== state && state.open === false) {
        return { ...snackbarInitialState, ...snackbarAction.payload, open: true };
      } else if (snackbarAction.payload !== state) {
        return { ...snackbarInitialState, ...snackbarAction.payload, open: true };
      } else {
        return state;
      }
    }
    case DISMISS_SNACKBAR: {
      return { ...state, open: false };
    }
    default:
      return state;
  }
};

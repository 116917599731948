import React from 'react';

import styled from 'styled-components';

import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { default as AddIcon } from '@material-ui/icons/Add';

import { MuiButtonVariant } from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';

export enum FabType {
  Add,
}

const StyledFab = withTheme()(styled(Button)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.primaryButton.normalBackground};
    border: none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    color: ${(props: ThemeProps) => props.theme.palette.primaryButton.text};
    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.primaryButton.hoverBackground};
    }
    &:active {
      background-color: ${(props: ThemeProps) => props.theme.palette.primaryButton.pressedBackground};
    }
    &:disabled {
      background-color: ${(props: ThemeProps) => props.theme.palette.disabled.main};
      color: ${(props: ThemeProps) => props.theme.palette.disabled.dark};
      box-shadow: none;
    }
  }
`);

const HangingFab = styled(StyledFab)`
  && {
    position: absolute;
    margin: 0 20px;
    top: 0;
    left: 0;
    transform: translate(0, -50%);
  }
`;

interface PlusButtonProps extends ButtonProps {
  fabType: FabType;
  isHanging?: boolean;
}

export const FloatingActionButton: React.FC<PlusButtonProps> = (props) => {
  const { fabType, isHanging, variant, innerRef, ...standardButtonProps } = props;
  const Fab = isHanging ? HangingFab : StyledFab;
  return (
    <Fab variant={MuiButtonVariant.Fab} {...standardButtonProps}>
      {getFabIcon(fabType)}
    </Fab>
  );
};

const getFabIcon = (type: FabType) => {
  switch (type) {
    default:
    case FabType.Add:
      return <AddIcon />;
  }
};

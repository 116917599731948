import { filter, find, intersectionWith, join, map, split } from 'lodash';

import {
  EquipmentSpecification,
  EquipmentType,
  LoadSearchMileage,
  LoadSize,
  LoadSortField,
  LocationType,
  OriginLocation,
} from '@common/model';
import { t, T } from '@translate';

import { DisplayOption } from './InterfaceHelper';
import { IKeysSortByLoads, KeysSortByDeadhead, KeysSortByPickupDate } from './SortByKeys';

export type EquipmentList = Array<DisplayOption<EquipmentType>>;
export type EquipmentListWithMultiSelect = Array<DisplayOption<EquipmentType | EquipmentType[]>>;

interface RangeBooleanObject {
  isShort?: boolean;
  isMedium?: boolean;
  isRegional?: boolean;
  isLong?: boolean;
}

export enum LengthRange {
  /** 0-200 mi */
  short,
  /** 201-500 mi */
  medium,
  /** 501-1000 mi */
  regional,
  /** 1001+ mi */
  long,
}

export interface MinMaxRange {
  minMileage?: number;
  maxMileage?: number;
}

const DisplayEquipmentList: EquipmentList = [
  {
    displayValue: t(T.common_load_equipmentType_AnimalCarrier),
    value: EquipmentType.AnimalCarrier,
    key: EquipmentType.AnimalCarrier,
    id: 'animal_carrier',
  },
  {
    displayValue: t(T.common_load_equipmentType_Auto),
    value: EquipmentType.Auto,
    key: EquipmentType.Auto,
    id: 'auto',
  },
  {
    displayValue: t(T.common_load_equipmentType_BoatHauler),
    value: EquipmentType.BoatHauler,
    key: EquipmentType.BoatHauler,
    id: 'boat_hauler',
  },
  {
    displayValue: t(T.common_load_equipmentType_CargoVan),
    value: EquipmentType.CargoVan,
    key: EquipmentType.CargoVan,
    id: 'cargo_van',
  },
  {
    displayValue: t(T.common_load_equipmentType_Container),
    value: EquipmentType.Container,
    key: EquipmentType.Container,
    id: 'container',
  },
  {
    displayValue: t(T.common_load_equipmentType_DoubleDrop),
    value: EquipmentType.DoubleDrop,
    key: EquipmentType.DoubleDrop,
    id: 'double_drop',
  },
  {
    displayValue: t(T.common_load_equipmentType_DumpTruck),
    value: EquipmentType.DumpTruck,
    key: EquipmentType.DumpTruck,
    id: 'dump_truck',
  },
  {
    displayValue: t(T.common_load_equipmentType_Flatbed),
    value: EquipmentType.Flatbed,
    key: EquipmentType.Flatbed,
    id: 'flatbed',
  },
  {
    displayValue: t(T.common_load_equipmentType_HeavyHaulers),
    value: EquipmentType.HeavyHaulers,
    key: EquipmentType.HeavyHaulers,
    id: 'heavy_haulers',
  },
  {
    displayValue: t(T.common_load_equipmentType_HopperBottom),
    value: EquipmentType.HopperBottom,
    key: EquipmentType.HopperBottom,
    id: 'hopper_bottom',
  },
  {
    displayValue: t(T.common_load_equipmentType_Landoll),
    value: EquipmentType.Landoll,
    key: EquipmentType.Landoll,
    id: 'landoll',
  },
  {
    displayValue: t(T.common_load_equipmentType_LowBoy),
    value: EquipmentType.LowBoy,
    key: EquipmentType.LowBoy,
    id: 'low_boy',
  },
  {
    displayValue: t(T.common_load_equipmentType_Maxi),
    value: EquipmentType.Maxi,
    key: EquipmentType.Maxi,
    id: 'maxi',
  },
  {
    displayValue: t(T.common_load_equipmentType_MovingVan),
    value: EquipmentType.MovingVan,
    key: EquipmentType.MovingVan,
    id: 'moving_van',
  },
  {
    displayValue: t(T.common_load_equipmentType_PowerOnly),
    value: EquipmentType.PowerOnly,
    key: EquipmentType.PowerOnly,
    id: 'power_only',
  },
  {
    displayValue: t(T.common_load_equipmentType_Reefer),
    value: EquipmentType.Reefer,
    key: EquipmentType.Reefer,
    id: 'reefer',
  },
  {
    displayValue: t(T.common_load_equipmentType_RemovableGooseneck),
    value: EquipmentType.RemovableGooseneck,
    key: EquipmentType.RemovableGooseneck,
    id: 'removable_gooseneck',
  },
  {
    displayValue: t(T.common_load_equipmentType_StepDeck),
    value: EquipmentType.StepDeck,
    key: EquipmentType.StepDeck,
    id: 'step_deck',
  },
  {
    displayValue: t(T.common_load_equipmentType_BoxTruck),
    value: EquipmentType.BoxTruck,
    key: EquipmentType.BoxTruck,
    id: 'straight_box_truck',
  },
  {
    displayValue: t(T.common_load_equipmentType_Tanker),
    value: EquipmentType.Tanker,
    key: EquipmentType.Tanker,
    id: 'tanker',
  },
  {
    displayValue: t(T.common_load_equipmentType_Van),
    value: EquipmentType.Van,
    key: EquipmentType.Van,
    id: 'van',
  },
];

export const SearchEquipmentListGrouped: { [key: string]: EquipmentList } = {
  popular: [
    {
      displayValue: `${t(T.common_load_equipmentType_Flatbed)} (${t(T.common_load_equipmentCode_Flatbed)})`,
      value: EquipmentType.Flatbed,
      key: EquipmentType.Flatbed,
      id: 'flatbed',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_Reefer)} (${t(T.common_load_equipmentCode_Reefer)})`,
      value: EquipmentType.Reefer,
      key: EquipmentType.Reefer,
      id: 'reefer',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_Van)} (${t(T.common_load_equipmentCode_Van)})`,
      value: EquipmentType.Van,
      key: EquipmentType.Van,
      id: 'van',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_PowerOnly)} (${t(T.common_load_equipmentCode_PowerOnly)})`,
      value: EquipmentType.PowerOnly,
      key: EquipmentType.PowerOnly,
      id: 'power_only',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_StepDeck)} (${t(T.common_load_equipmentCode_StepDeck)})`,
      value: EquipmentType.StepDeck,
      key: EquipmentType.StepDeck,
      id: 'step_deck',
    },
  ],
  other: [
    {
      displayValue: `${t(T.common_load_equipmentType_AnimalCarrier)} (${t(T.common_load_equipmentCode_AnimalCarrier)})`,
      value: EquipmentType.AnimalCarrier,
      key: EquipmentType.AnimalCarrier,
      id: 'animal_carrier',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_Auto)} (${t(T.common_load_equipmentCode_Auto)})`,
      value: EquipmentType.Auto,
      key: EquipmentType.Auto,
      id: 'auto',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_BoatHauler)} (${t(T.common_load_equipmentCode_BoatHauler)})`,
      value: EquipmentType.BoatHauler,
      key: EquipmentType.BoatHauler,
      id: 'boat_hauler',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_CargoVan)} (${t(T.common_load_equipmentCode_CargoVan)})`,
      value: EquipmentType.CargoVan,
      key: EquipmentType.CargoVan,
      id: 'cargo_van',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_Container)} (${t(T.common_load_equipmentCode_Container)})`,
      value: EquipmentType.Container,
      key: EquipmentType.Container,
      id: 'container',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_DoubleDrop)} (${t(T.common_load_equipmentCode_DoubleDrop)})`,
      value: EquipmentType.DoubleDrop,
      key: EquipmentType.DoubleDrop,
      id: 'double_drop',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_DumpTruck)} (${t(T.common_load_equipmentCode_DumpTruck)})`,
      value: EquipmentType.DumpTruck,
      key: EquipmentType.DumpTruck,
      id: 'dump_truck',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_HeavyHaulers)} (${t(T.common_load_equipmentCode_HeavyHaulers)})`,
      value: EquipmentType.HeavyHaulers,
      key: EquipmentType.HeavyHaulers,
      id: 'heavy_haulers',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_HopperBottom)} (${t(T.common_load_equipmentCode_HopperBottom)})`,
      value: EquipmentType.HopperBottom,
      key: EquipmentType.HopperBottom,
      id: 'hopper_bottom',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_Landoll)} (${t(T.common_load_equipmentCode_Landoll)})`,
      value: EquipmentType.Landoll,
      key: EquipmentType.Landoll,
      id: 'landoll',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_LowBoy)} (${t(T.common_load_equipmentCode_LowBoy)})`,
      value: EquipmentType.LowBoy,
      key: EquipmentType.LowBoy,
      id: 'low_boy',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_Maxi)} (${t(T.common_load_equipmentCode_Maxi)})`,
      value: EquipmentType.Maxi,
      key: EquipmentType.Maxi,
      id: 'maxi',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_MovingVan)} (${t(T.common_load_equipmentCode_MovingVan)})`,
      value: EquipmentType.MovingVan,
      key: EquipmentType.MovingVan,
      id: 'moving_van',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_RemovableGooseneck)} (${t(
        T.common_load_equipmentCode_RemovableGooseneck
      )})`,
      value: EquipmentType.RemovableGooseneck,
      key: EquipmentType.RemovableGooseneck,
      id: 'removable_gooseneck',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_BoxTruck)} (${t(T.common_load_equipmentCode_BoxTruck)})`,
      value: EquipmentType.BoxTruck,
      key: EquipmentType.BoxTruck,
      id: 'straight_box_truck',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_Tanker)} (${t(T.common_load_equipmentCode_Tanker)})`,
      value: EquipmentType.Tanker,
      key: EquipmentType.Tanker,
      id: 'tanker',
    },
  ],
};

export const SearchEquipmentList: EquipmentList = [
  ...SearchEquipmentListGrouped.popular,
  ...SearchEquipmentListGrouped.other,
];

export type SpecificationList = Array<DisplayOption<EquipmentSpecification>>;

export type EquipmentSpec = { displayName: string; displayCode: string; key: EquipmentSpecification; id: string };

export const EquipmentSpecificationList: EquipmentSpec[] = [
  {
    displayName: t(T.common_load_equipmentSpecification_AirRide),
    displayCode: t(T.common_load_equipmentSpecificationCode_AirRide),
    key: EquipmentSpecification.AirRide,
    id: 'air_ride',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_BlanketWrap),
    displayCode: t(T.common_load_equipmentSpecificationCode_BlanketWrap),
    key: EquipmentSpecification.BlanketWrap,
    id: 'blanket_wrap',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_BTrain),
    displayCode: t(T.common_load_equipmentSpecificationCode_BTrain),
    key: EquipmentSpecification.BTrain,
    id: 'b_train',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Chains),
    displayCode: t(T.common_load_equipmentSpecificationCode_Chains),
    key: EquipmentSpecification.Chains,
    id: 'chains',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Chassis),
    displayCode: t(T.common_load_equipmentSpecificationCode_Chassis),
    key: EquipmentSpecification.Chassis,
    id: 'chassis',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Conestoga),
    displayCode: t(T.common_load_equipmentSpecificationCode_Conestoga),
    key: EquipmentSpecification.Conestoga,
    id: 'conestoga',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Curtains),
    displayCode: t(T.common_load_equipmentSpecificationCode_Curtains),
    key: EquipmentSpecification.Curtains,
    id: 'curtains',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Double),
    displayCode: t(T.common_load_equipmentSpecificationCode_Double),
    key: EquipmentSpecification.Double,
    id: 'double',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Extendable),
    displayCode: t(T.common_load_equipmentSpecificationCode_Extendable),
    key: EquipmentSpecification.Extendable,
    id: 'extendable',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_ETrack),
    displayCode: t(T.common_load_equipmentSpecificationCode_ETrack),
    key: EquipmentSpecification.ETrack,
    id: 'e_track',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_HazMat),
    displayCode: t(T.common_load_equipmentSpecificationCode_HazMat),
    key: EquipmentSpecification.HazMat,
    id: 'hazmat',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_HotShot),
    displayCode: t(T.common_load_equipmentSpecificationCode_HotShot),
    key: EquipmentSpecification.HotShot,
    id: 'hot_shot',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Insulated),
    displayCode: t(T.common_load_equipmentSpecificationCode_Insulated),
    key: EquipmentSpecification.Insulated,
    id: 'insulated',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Intermodal),
    displayCode: t(T.common_load_equipmentSpecificationCode_Intermodal),
    key: EquipmentSpecification.Intermodal,
    id: 'intermodal',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_LiftGate),
    displayCode: t(T.common_load_equipmentSpecificationCode_LiftGate),
    key: EquipmentSpecification.LiftGate,
    id: 'lift_gate',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_LoadOut),
    displayCode: t(T.common_load_equipmentSpecificationCode_LoadOut),
    key: EquipmentSpecification.LoadOut,
    id: 'load_out',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_LoadRamps),
    displayCode: t(T.common_load_equipmentSpecificationCode_LoadRamps),
    key: EquipmentSpecification.LoadRamps,
    id: 'load_ramps',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Moving),
    displayCode: t(T.common_load_equipmentSpecificationCode_Moving),
    key: EquipmentSpecification.Moving,
    id: 'moving',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_OpenTop),
    displayCode: t(T.common_load_equipmentSpecificationCode_OpenTop),
    key: EquipmentSpecification.OpenTop,
    id: 'open_top',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Oversized),
    displayCode: t(T.common_load_equipmentSpecificationCode_Oversized),
    key: EquipmentSpecification.Oversized,
    id: 'oversized',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_PalletExchange),
    displayCode: t(T.common_load_equipmentSpecificationCode_PalletExchange),
    key: EquipmentSpecification.PalletExchange,
    id: 'pallet_exchange',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_PlateTrailer),
    displayCode: t(T.common_load_equipmentSpecificationCode_PlateTrailer),
    key: EquipmentSpecification.PlateTrailer,
    id: 'plate_trailer',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_SideKit),
    displayCode: t(T.common_load_equipmentSpecificationCode_SideKit),
    key: EquipmentSpecification.SideKit,
    id: 'side_kit',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Tarps),
    displayCode: t(T.common_load_equipmentSpecificationCode_Tarps),
    key: EquipmentSpecification.Tarps,
    id: 'tarps',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_TeamDriver),
    displayCode: t(T.common_load_equipmentSpecificationCode_TeamDriver),
    key: EquipmentSpecification.TeamDriver,
    id: 'team_driver',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_TempControlled),
    displayCode: t(T.common_load_equipmentSpecificationCode_TempControlled),
    key: EquipmentSpecification.TempControlled,
    id: 'temp_controlled',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Triple),
    displayCode: t(T.common_load_equipmentSpecificationCode_Triple),
    key: EquipmentSpecification.Triple,
    id: 'triple',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_Vented),
    displayCode: t(T.common_load_equipmentSpecificationCode_Vented),
    key: EquipmentSpecification.Vented,
    id: 'vented',
  },
  {
    displayName: t(T.common_load_equipmentSpecification_WalkingFloor),
    displayCode: t(T.common_load_equipmentSpecificationCode_WalkingFloor),
    key: EquipmentSpecification.WalkingFloor,
    id: 'walking_floor',
  },
];

export const SearchEquipmentSpecificationList: SpecificationList = map(EquipmentSpecificationList, (spec) => ({
  displayValue: `${spec.displayName} (${spec.displayCode})`,
  value: spec.key,
  key: spec.key,
  id: spec.id,
}));

export const SearchEquipmentSpecificationListWithAll = [
  {
    displayValue: t(T.common_any),
    value: EquipmentSpecification.All,
    key: EquipmentSpecification.All,
    id: 'all',
  },
  ...SearchEquipmentSpecificationList,
];

export const LoadAvailabilitySortOptions: Array<DisplayOption<LoadSortField>> = [
  {
    displayValue: t(T.common_sortByKeys_postedAge),
    value: LoadSortField.PostedAge,
    key: `${LoadSortField.PostedAge}`,
    id: 'posted_age',
  },
  {
    displayValue: t(T.common_sortByKeys_pickupDate),
    value: LoadSortField.PickupDate,
    key: `${LoadSortField.PickupDate}`,
    id: 'pickup_date',
  },
  {
    displayValue: t(T.common_search_pickUpLocation),
    value: LoadSortField.OriginStateCity,
    key: `${LoadSortField.OriginStateCity}`,
    id: 'pickup_location',
  },
  {
    displayValue: t(T.common_search_dropOffLocation),
    value: LoadSortField.DestinationStateCity,
    key: `${LoadSortField.DestinationStateCity}`,
    id: 'destination_location',
  },
  {
    displayValue: t(T.common_sortByKeys_trip),
    value: LoadSortField.TripDistance,
    key: `${LoadSortField.TripDistance}`,
    id: 'trip_distance',
  },
  {
    displayValue: t(T.common_sortByKeys_tripRate),
    value: LoadSortField.TripRate,
    key: `${LoadSortField.TripRate}`,
    id: 'trip_rate',
  },
  {
    displayValue: t(T.common_sortByKeys_pricePerMile),
    value: LoadSortField.RatePerMile,
    key: `${LoadSortField.RatePerMile}`,
    id: 'rate_per_mile',
  },
  {
    displayValue: t(T.common_sortByKeys_equipment),
    value: LoadSortField.Equipment,
    key: `${LoadSortField.Equipment}`,
    id: 'equipment',
  },
  {
    displayValue: t(T.common_sortByKeys_size),
    value: LoadSortField.Size,
    key: `${LoadSortField.Size}`,
    id: 'size',
  },
  {
    displayValue: t(T.common_sortByKeys_weightLb),
    value: LoadSortField.Weight,
    key: `${LoadSortField.Weight}`,
    id: 'weight',
  },
  {
    displayValue: t(T.common_sortByKeys_lengthFt),
    value: LoadSortField.Length,
    key: `${LoadSortField.Length}`,
    id: 'length',
  },
  {
    displayValue: t(T.common_sortByKeys_company),
    value: LoadSortField.CompanyName,
    key: `${LoadSortField.CompanyName}`,
    id: 'company_name',
  },
  //@TODO add these sorting options when their values are visible to the user in load search
  // {
  //   displayValue: t(T.common_sortByKeys_creditScore),
  //   value: LoadSortField.CreditScore,
  //   key: `${LoadSortField.CreditScore}`,
  //   id: 'credit_score',
  // },
  // {
  //   displayValue: t(T.common_sortByKeys_daysToPay),
  //   value: LoadSortField.DaysToPay,
  //   key: `${LoadSortField.DaysToPay}`,
  //   id: 'days_to_pay',
  // },
];

export const LoadPostSortOptions: Array<DisplayOption<LoadSortField>> = [
  {
    displayValue: t(T.common_search_age),
    value: LoadSortField.PostedAge,
    key: `${LoadSortField.PostedAge}`,
    id: 'posted_age',
  },
  {
    displayValue: t(T.common_sortByKeys_pickupDate),
    value: LoadSortField.PickupDate,
    key: `${LoadSortField.PickupDate}`,
    id: 'pickup_date',
  },
  {
    displayValue: t(T.common_search_pickUpLocation),
    value: LoadSortField.OriginStateCity,
    key: `${LoadSortField.OriginStateCity}`,
    id: 'pickup_location',
  },
  {
    displayValue: t(T.common_search_dropOffLocation),
    value: LoadSortField.DestinationStateCity,
    key: `${LoadSortField.DestinationStateCity}`,
    id: 'dropoff_location',
  },
  {
    displayValue: t(T.common_sortByKeys_trip),
    value: LoadSortField.TripDistance,
    key: `${LoadSortField.TripDistance}`,
    id: 'trip',
  },
  {
    displayValue: t(T.common_sortByKeys_tripRate),
    value: LoadSortField.TripRate,
    key: `${LoadSortField.TripRate}`,
    id: 'rate',
  },
  {
    displayValue: t(T.common_sortByKeys_equipment),
    value: LoadSortField.Equipment,
    key: `${LoadSortField.Equipment}`,
    id: 'equipment',
  },
  {
    displayValue: t(T.common_sortByKeys_size),
    value: LoadSortField.Size,
    key: `${LoadSortField.Size}`,
    id: 'size',
  },
  {
    displayValue: t(T.common_sortByKeys_weightLb),
    value: LoadSortField.Weight,
    key: `${LoadSortField.Weight}`,
    id: 'weight',
  },
  {
    displayValue: t(T.common_sortByKeys_lengthFt),
    value: LoadSortField.Length,
    key: `${LoadSortField.Length}`,
    id: 'length',
  },
  {
    displayValue: t(T.common_sortByKeys_ref),
    value: LoadSortField.PostReference,
    key: `${LoadSortField.PostReference}`,
    id: 'ref',
  },
];

export const TrailerOptions: { [key: string]: EquipmentListWithMultiSelect } = {
  types: [
    {
      displayValue: t(T.common_any),
      value: EquipmentType.All,
      key: EquipmentType.All,
      id: 'all',
    },
  ],
  quickSelect: [
    {
      displayValue: `${t(T.common_load_equipmentType_AllFlatbed)} (${t(T.common_load_equipmentCode_Flatbed)}, ${t(
        T.common_load_equipmentCode_StepDeck
      )}, ${t(T.common_load_equipmentCode_RemovableGooseneck)}, ${t(T.common_load_equipmentCode_DoubleDrop)}, ${t(
        T.common_load_equipmentCode_Maxi
      )}, ${t(T.common_load_equipmentCode_LowBoy)}, ${t(T.common_load_equipmentCode_Landoll)})`,
      value: [
        EquipmentType.Flatbed,
        EquipmentType.StepDeck,
        EquipmentType.RemovableGooseneck,
        EquipmentType.DoubleDrop,
        EquipmentType.Maxi,
        EquipmentType.LowBoy,
        EquipmentType.Landoll,
      ],
      key: 'all_flatbed',
      id: 'all_flatbed',
    },
    {
      displayValue: `${t(T.common_load_equipmentType_AllVan)} (${t(T.common_load_equipmentCode_Van)}, ${t(
        T.common_load_equipmentCode_MovingVan
      )}, ${t(T.common_load_equipmentCode_CargoVan)}, ${t(T.common_load_equipmentCode_BoxTruck)})`,
      value: [EquipmentType.Van, EquipmentType.MovingVan, EquipmentType.CargoVan, EquipmentType.BoxTruck],
      key: 'all_van',
      id: 'all_van',
    },
  ],
  popular: SearchEquipmentListGrouped.popular,
  other: SearchEquipmentListGrouped.other,
};

const SmallTrailerList: EquipmentList = [
  {
    displayValue: `${t(T.common_load_equipmentType_BoxTruck)} (${t(T.common_load_equipmentCode_BoxTruck)})`,
    value: EquipmentType.BoxTruck,
    key: EquipmentType.BoxTruck,
    id: 'straight_box_truck',
  },
  {
    displayValue: `${t(T.common_load_equipmentType_CargoVan)} (${t(T.common_load_equipmentCode_CargoVan)})`,
    value: EquipmentType.CargoVan,
    key: EquipmentType.CargoVan,
    id: 'cargo_van',
  },
  {
    displayValue: `${t(T.common_load_equipmentType_MovingVan)} (${t(T.common_load_equipmentCode_MovingVan)})`,
    value: EquipmentType.MovingVan,
    key: EquipmentType.MovingVan,
    id: 'moving_van',
  },
];

export const SmallTrailerOptions: EquipmentList = [
  ...SmallTrailerList,
  {
    displayValue: `${t(T.common_load_equipmentType_Van)} (${t(T.common_load_equipmentCode_Van)})`,
    value: EquipmentType.Van,
    key: EquipmentType.Van,
    id: 'van',
  },
];

export const isSelectedSmallTrailerOptions = (trailerTypes: EquipmentType[]): boolean => {
  const selectedSmallTrailerTypesLength = intersectionWith(
    SmallTrailerList,
    trailerTypes,
    (smallTrailerType, trailerType) => smallTrailerType.key === trailerType
  ).length;
  return selectedSmallTrailerTypesLength < SmallTrailerList.length && selectedSmallTrailerTypesLength > 0;
};

export const getStringifiedEquipmentTypeAndName = (equipmentType?: EquipmentType, displayName?: string) => {
  const equipment = find(DisplayEquipmentList, (equipType) => equipType.value === equipmentType);
  return equipment && displayName ? `${equipment.displayValue} (${displayName})` : '';
};

/* When it is required to return stringified Equipments Type with Equipment Code */
export const getStringifiedEquipmentType = (equipmentType: EquipmentType) => {
  const equipment = find(SearchEquipmentList, (equipType) => equipType.value === equipmentType);
  return equipment ? equipment.displayValue : '';
};

/* When it is required to return stringified Equipments Type withOut Equipment Code */
export const getStringifiedDisplayEquipmentList = (equipmentType: EquipmentType) => {
  const equipment = find(DisplayEquipmentList, (equipType) => equipType.value === equipmentType);
  return equipment ? equipment.displayValue : '';
};

/* When it is required to return stringified Equipments Specification with Specification Code */
export const getStringifiedEquipmentSpec = (equipmentSpecifications: EquipmentSpecification) => {
  const equipment = find(
    SearchEquipmentSpecificationList,
    (equipSpecifications) => equipSpecifications.value === equipmentSpecifications
  );
  return equipment ? equipment.displayValue : '';
};

/* When it is required to return stringified Equipments Specification withOut Specification Code */
export const getStringifiedEquipmentSpecName = (equipmentSpecifications: string) => {
  if (!equipmentSpecifications || equipmentSpecifications === EquipmentSpecification.None) {
    return '';
  }
  const specs = split(equipmentSpecifications, ', ');
  return join(
    map(specs, (spec) => {
      const equipment = find(EquipmentSpecificationList, (equipSpecifications) => equipSpecifications.key === spec);
      return equipment ? equipment.displayName : '';
    }),
    ', '
  );
};

export const loadSizeToString = (loadSize: LoadSize) => {
  if (loadSize === LoadSize.TL) {
    return t(T.common_load_filter_tl);
  }
  if (loadSize === LoadSize.LTL) {
    return t(T.common_load_filter_ltl);
  }
  return t(T.common_any);
};

export const getSortByMatchingLoadSearchOrigin = (origin: OriginLocation, sortBy?: IKeysSortByLoads) => {
  if (origin.type === LocationType.STATE && (sortBy?.field === KeysSortByDeadhead.field || !sortBy)) {
    return {
      field: KeysSortByPickupDate.field,
      direction: KeysSortByPickupDate.direction,
    };
  } else if (!sortBy) {
    return {
      field: KeysSortByDeadhead.field,
      direction: KeysSortByDeadhead.direction,
    };
  } else {
    return {
      field: sortBy.field,
      direction: sortBy.direction,
    };
  }
};

export const maxMileageRequestValue = (mileage: string | undefined) => {
  if (mileage === '' || mileage === undefined || mileage === '0') {
    return undefined;
  } else {
    return +mileage;
  }
};

export const computeMinMax = (ranges: LengthRange[], bias: boolean = false): MinMaxRange => {
  const { isShort, isMedium, isRegional, isLong } = convertSelectArrayToBooleanObject(ranges);

  // short    : min=   0, max= 200
  // medium   : min= 201, max= 500
  // regional : min= 501, max=1000
  // long:    : min=1001, max=undefined

  let minMileage: number | undefined = LoadSearchMileage.MIN;
  let maxMileage: number | undefined;

  if (!isShort) {
    minMileage = LoadSearchMileage.MAX_SHORT + 1;
    if (!isMedium) {
      minMileage = LoadSearchMileage.MAX_MEDIUM + 1;
      if (!isRegional) {
        minMileage = LoadSearchMileage.MAX_REGIONAL + 1;
        if (!isLong) {
          // None case which will eventually mean Any (but allow all unchecked while editing)
          minMileage = undefined;
        }
      }
    }
  }

  if (!isLong) {
    maxMileage = LoadSearchMileage.MAX_REGIONAL;
    if (!isRegional) {
      maxMileage = LoadSearchMileage.MAX_MEDIUM;
      if (!isMedium) {
        maxMileage = LoadSearchMileage.MAX_SHORT;
        if (!isShort) {
          // None case which will eventually mean Any (but allow all unchecked while editing)
          maxMileage = undefined;
        }
      }
    }
  }

  // special "impossible" case : if the user unchecked in the middle,
  // we resolve this by removing the long range if bias = false (user is unchecking something)
  // we resolve this by putting all the range if bias = true (user is checking something)
  if (!isMedium && isShort && isLong && !bias) {
    maxMileage = LoadSearchMileage.MAX_SHORT;
  }

  return { minMileage: minMileage, maxMileage: maxMileage };
};

export const computeRanges = (minMileage: number | undefined, maxMileage: number | undefined): LengthRange[] => {
  // short    : min=   0, max= 200
  // medium   : min= 201, max= 500
  // regional : min= 501, max=1000
  // long:    : min=1001, max=undefined

  let isShort = true;
  let isMedium = true;
  let isRegional = true;
  let isLong = true;

  if (minMileage === undefined && maxMileage === undefined) {
    // None case which will eventually mean Any (but allow all unchecked while editing)
    isShort = false;
    isMedium = false;
    isRegional = false;
    isLong = false;
  } else {
    if (minMileage && minMileage > LoadSearchMileage.MAX_SHORT) {
      isShort = false;
      if (minMileage > LoadSearchMileage.MAX_MEDIUM) {
        isMedium = false;
        if (minMileage > LoadSearchMileage.MAX_REGIONAL) {
          isRegional = false;
        }
      }
    }
    if (maxMileage && maxMileage <= LoadSearchMileage.MAX_REGIONAL) {
      isLong = false;
      if (maxMileage <= LoadSearchMileage.MAX_MEDIUM) {
        isRegional = false;
        if (maxMileage <= LoadSearchMileage.MAX_SHORT) {
          isMedium = false;
        }
      }
    }
  }

  const result = [];
  if (isShort) {
    result.push(LengthRange.short);
  }
  if (isMedium) {
    result.push(LengthRange.medium);
  }
  if (isRegional) {
    result.push(LengthRange.regional);
  }
  if (isLong) {
    result.push(LengthRange.long);
  }

  return result;
};

const convertSelectArrayToBooleanObject = (ranges: LengthRange[]): RangeBooleanObject => {
  const rangeObject: RangeBooleanObject = {};

  ranges.forEach((range: LengthRange) => {
    switch (range) {
      case LengthRange.short:
        rangeObject.isShort = true;
        break;
      case LengthRange.medium:
        rangeObject.isMedium = true;
        break;
      case LengthRange.regional:
        rangeObject.isRegional = true;
        break;
      case LengthRange.long:
        rangeObject.isLong = true;
    }
  });

  return rangeObject;
};

export const renderMileageText = (
  minMileage?: number,
  maxMileage?: number,
  shouldIncludeMileageValues?: boolean
): string => {
  const { isShort, isMedium, isRegional, isLong } = convertSelectArrayToBooleanObject(
    computeRanges(minMileage, maxMileage)
  );

  if ((!isShort && !isMedium && !isRegional && !isLong) || (isShort && isLong)) {
    // all or nothing means "Any"
    return t(T.common_loadSearch_tripLength_any);
  }
  const flags = [isShort, isMedium, isRegional, isLong];
  const selectedFlagsCount = filter(flags, (flag) => flag).length;
  if (selectedFlagsCount > 1 && selectedFlagsCount < flags.length) {
    return t(T.common_loadSearch_tripLength_custom);
  }

  if (isLong) {
    return `${t(T.common_loadSearch_tripLength_long)}${
      shouldIncludeMileageValues ? `: ${minMileage}+ ${t(T.common_miles_short)}` : ''
    }`;
  }

  if (isRegional) {
    return `${t(T.common_loadSearch_tripLength_regional)}${
      shouldIncludeMileageValues ? `: ${minMileage} - ${maxMileage} ${t(T.common_miles_short)}` : ''
    }`;
  }

  if (isMedium) {
    return `${t(T.common_loadSearch_tripLength_medium)}${
      shouldIncludeMileageValues ? `: ${minMileage} - ${maxMileage} ${t(T.common_miles_short)}` : ''
    }`;
  }

  return `${t(T.common_loadSearch_tripLength_short)}${
    shouldIncludeMileageValues ? `: ${minMileage} - ${maxMileage} ${t(T.common_miles_short)}` : ''
  }`;
};

export interface TripLengthRange {
  type?: string;
  min: number;
  max?: number;
}

export const TRIP_LENGTH_ANY = {
  minMileage: 0,
  maxMileage: undefined,
};

export const isTripLengthAny = (minMileage: number | undefined, maxMileage: number | undefined) =>
  minMileage === TRIP_LENGTH_ANY.minMileage && maxMileage === TRIP_LENGTH_ANY.maxMileage;

export const tripLengthRangeToString = (range: TripLengthRange) =>
  range.max !== undefined ? `${range.min} - ${range.max}` : `${range.min}+`;

import React from 'react';

import styled from 'styled-components';

import { Avatar, Chip } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip';
import { default as DoneIcon } from '@material-ui/icons/Done';

import { Text, TextDisplay } from '@component/text';
import { ThemeProps, withTheme } from '@style/WithTheme';

const UncheckedChip = withTheme()(styled(Chip)`
  && {
    background-color: transparent;
    margin-right: 8px;
    border: solid 1px ${(props: ThemeProps) => props.theme.palette.chipButton.border};
    transition: 200ms ease-in-out;

    > span span {
      color: ${(props: ThemeProps) => props.theme.palette.chipButton.text} !important;
    }

    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.chipButton.hoverBackground};
    }
  }
`);

const CheckedChip = withTheme()(styled(Chip)`
  && {
    margin-right: 8px;
    background-color: ${(props: ThemeProps) => props.theme.palette.chipButton.pressedBackground};

    > div {
      background-color: transparent;
      margin-right: -10px;
    }

    > div svg {
      color: ${(props: ThemeProps) => props.theme.palette.chipButton.pressedText} !important;
      height: 24px;
      width: 24px;
    }

    > span span {
      color: ${(props: ThemeProps) => props.theme.palette.chipButton.pressedText} !important;
    }

    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.chipButton.hoverBackground};
    }
  }
`);

interface Props extends ChipProps {
  id: string;
  isChecked: boolean;
}

export const CheckboxChip: React.SFC<Props> = (props) => {
  const { id, label, isChecked, avatar, component, innerRef, ...otherChipProps } = props;
  const StyledChip = isChecked ? CheckedChip : UncheckedChip;

  return (
    <StyledChip
      id={id}
      {...otherChipProps}
      label={
        <Text id={id + '_label'} display={TextDisplay.InlineBlock} noWrap={true}>
          {label}
        </Text>
      }
      avatar={getIcon(props.isChecked)}
      component={'button' as any}
    />
  );
};

const getIcon = (isChecked: boolean) =>
  isChecked ? (
    <Avatar>
      <DoneIcon />
    </Avatar>
  ) : undefined;

import React from 'react';

import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';
import { default as TextField } from '@material-ui/core/TextField';

import { TextFieldProps } from '@component/input';
import { Color } from '@style/Color';
import { ThemeProps, withTheme } from '@style/WithTheme';

const LABEL_MARGIN_LEFT = 12;

export const StyledProgress = withTheme()(styled(CircularProgress)`
  && {
    color: ${(props: ThemeProps) => props.theme.palette.primary.main};
  }
`);

export const StyledTextfield: any = withTheme()(styled<TextFieldProps>(
  ({ isWithoutMargin, fullHeight, noMargin, ...rest }) => <TextField {...rest} />
)`
  && {
    margin: ${(props: TextFieldProps) => (props.isWithoutMargin ? '0' : !props.noMargin ? '0' : '16px 0 !important')};
    margin-bottom: ${(props: TextFieldProps) =>
      props.error && props.helperText ? '5px' : props.isWithoutMargin ? '0' : '16px'} !important;
    width: ${(props: TextFieldProps) => (props.fullWidth ? '100%' : 'auto')} !important;
    overflow: hidden;

    input:-webkit-autofill {
      -webkit-box-shadow: ${(props: TextFieldProps & ThemeProps) =>
        `0 0 0px 1000px ${props.theme.palette.textField.lightBackground} inset`};
    }

    input::-ms-clear {
      display: none;
    }

    > div {
      pointer-events: auto;
      z-index: 0;
      background-color: ${(props: TextFieldProps & ThemeProps) =>
        props.disabled
          ? props.theme.palette.textField.disabledBackground
          : props.theme.palette.textField.lightBackground};
      margin-top: 0px !important;
      height: ${(props: TextFieldProps) => {
        if (props.multiline) {
          return props.fullHeight ? '100%' : undefined;
        }
        return props.dense ? '50px' : '54px';
      }};
      width: auto;
      border: ${(props: TextFieldProps & ThemeProps) => `1px solid ${props.theme.palette.textInput.borderColor}`};
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      @media (hover: hover) {
        &:hover {
          background-color: ${(props: TextFieldProps & ThemeProps) =>
            props.disabled
              ? props.theme.palette.textField.disabledBackground
              : props.theme.palette.textField.lightBackground};
        }
      }

      &:focus-within {
        background-color: ${(props: TextFieldProps & ThemeProps) => props.theme.palette.textField.lightBackground};
      }
      &:before {
        border-bottom-width: ${(props) => (props.error ? '2px' : '1px')} !important;
        border-bottom-style: solid !important;
        border-bottom-color: ${(props) =>
          props.error
            ? `${props.theme.palette.textField.error} !important`
            : props.disabled
              ? props.theme.palette.textField.normalLabel
              : props.theme.palette.textInput.borderColor} !important;
      }
      &:after {
        background-color: ${(props: TextFieldProps & ThemeProps) =>
          props.error ? `${props.theme.palette.textField.error} !important` : props.theme.palette.primary.main};
        border-bottom: 2px solid
          ${(props: TextFieldProps & ThemeProps) =>
            props.error ? `${props.theme.palette.textField.error} !important` : props.theme.palette.primary.main};
      }
    }

    & > label {
      display: inline-block;
      ${(props: TextFieldProps) => (props.InputLabelProps?.shrink ? '' : 'overflow: hidden;')}
      ${(props: TextFieldProps) => (props.InputLabelProps?.shrink ? '' : 'text-overflow: ellipsis;')}
      pointer-events: none;
      margin: 7px 12px 7px 12px;
      z-index: 1;
      height: 21px;
      font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: left;
      white-space: nowrap;
      transform: ${(props: TextFieldProps) => {
        if (props.InputLabelProps !== undefined) {
          return props.InputLabelProps.shrink
            ? 'translate(0, 0px) scale(0.75);'
            : props.dense
              ? 'translate(0, 15px) scale(1);'
              : 'translate(0, 18px) scale(1);';
        } else {
          return undefined;
        }
      }};
      margin: ${(props: TextFieldProps) => {
        if (props.InputLabelProps !== undefined) {
          return props.InputLabelProps.shrink ? '7px 12px 7px 12px' : '0px 0px 0px 12px';
        } else {
          return '0px';
        }
      }};
      margin-left: ${LABEL_MARGIN_LEFT}px;
      color: ${(props: TextFieldProps & ThemeProps) => getInputLabelColor(props)};
      background-color: ${(props: TextFieldProps) => (props.multiline ? Color.WHITE : 'initial')};
      width: ${(props: TextFieldProps) => (props.multiline ? 'fit-content' : `calc(100% - ${LABEL_MARGIN_LEFT}px)`)};
    }

    > .classes-state-root.disabled {
      color: white;
    }

    > div input {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: ${(props: TextFieldProps) => (props.dense ? '24px' : '27px')};
      // null  if not android and safari wont have the same color text for disabled
      color: ${(props: TextFieldProps & ThemeProps) =>
        props.disabled ? null : props.theme.palette.textField.normalText};
      height: 20px;
      font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      text-align: left;
    }

    ${(props: TextFieldProps) =>
      props.onClear /* hide X on Edge because we are displaying ours */
        ? ' > div input::-ms-clear { display: none; }'
        : ''};

    > p {
      margin-top: 2px;
      margin-left: 12px;
      height: ${(props: TextFieldProps) => (props.isErrorMessageMultiline ? 'auto' : '16px')};
      font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: ${(props: TextFieldProps & ThemeProps) =>
        props.error ? props.theme.palette.textField.error : props.theme.palette.textField.normalText};
    }

    > div div {
      height: ${(props: TextFieldProps) => (props.multiline ? undefined : '20px')};
      width: ${(props: TextFieldProps) => (props.multiline ? undefined : '20px')};
      margin-right: 12px;
      margin-top: ${(props: TextFieldProps) => (props.multiline ? '27px' : props.dense ? '15px' : '18px')};
      margin-bottom: ${(props: TextFieldProps) => (props.multiline ? '14px' : undefined)};
      margin-left: ${(props: TextFieldProps) => (props.multiline ? '14px' : undefined)};
    }

    > div div div {
      margin: 0px;
    }

    > div div svg {
      display: flex;
      align-content: center;
      height: 20px;
      width: 20px;
      color: ${(props: TextFieldProps & ThemeProps) =>
        props.error ? props.theme.palette.textField.error : props.theme.palette.status.confirmation};
    }

    > div div div svg {
      display: flex;
      align-content: center;
      height: 20px;
      width: 20px;
      color: ${(props: TextFieldProps & ThemeProps) =>
        props.error ? props.theme.palette.textField.error : props.theme.palette.status.confirmation};
    }

    > div div textarea {
      width: 100%;
    }
    > div textarea {
      padding: ${(props: TextFieldProps) => (props.isVariableHeight ? '0' : '0 12px')};
    }
  }
`);

export const MarginlessStyledTextField = styled(StyledTextfield)`
  && {
    margin: 0px !important;
    margin-bottom: ${(props: TextFieldProps) => (props.error && props.helperText ? '-18px' : '0')} !important;
  }
`;

export const StartAdornmentIconTextField = styled(StyledTextfield)`
  && {
    > label {
      margin-left: 48px;
    }
  }
`;

export const getInputLabelColor = (props: TextFieldProps & ThemeProps) => {
  if (props.disabled) {
    return props.theme.palette.text.secondary;
  }
  if (props.error) {
    return props.theme.palette.textField.error;
  }
  if (props.InputLabelProps && props.InputLabelProps.shrink) {
    return props.InputLabelProps.focused ? props.theme.palette.primary.main : props.theme.palette.text.secondary;
  }
  return props.theme.palette.text.secondary;
};

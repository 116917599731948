import React from 'react';

import styled from 'styled-components';

import { default as BookWithIcon } from '@/component/panels/findLoads/loadDetailsPanel/buttons/icon-book.svg';
import { default as vHubIcon } from '@/component/panels/findLoads/loadDetailsPanel/buttons/icon-vHub.svg';
import { VhubEvent } from '@common/model/Tracking';
import { createVhubBookingTrackData } from '@common/util/123lbBaseTracker';
import { MultipurposeButton } from '@component/buttons';
import { ContactsButton } from '@component/buttons/ContactsButton';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupContentSection } from '@component/popup/PopupContentSections';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { ElementSize, FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { track } from '@util/trackers/123lbTracker';

const TextContainer = styled.div`
  margin-bottom: ${Spacing.InterSection}px;
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Regular};
`;

const PopUpButton = styled(MultipurposeButton)`
  align-self: flex-end;
  width: ${ElementSize.ButtonWidthLarge}px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${Spacing.InterSection}px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-bottom: 2px;
`;
interface VhubBookWithButtonProps {
  loadID?: string;
  vHubURL?: string;
}

export const VhubBookWithButton: React.FC<VhubBookWithButtonProps> = (props) => {
  const { openingPopup } = usePopup();

  return (
    <ContactsButton
      id="book_with_button"
      title={t(T.common_vHub_vHubBookWithButtonLabel)}
      onClick={() => {
        if (props.loadID) {
          track(createVhubBookingTrackData(VhubEvent.BookItWith, props.loadID));
          openingPopup({
            body: <VhubBookWithPopup loadID={props.loadID} vHubURL={props.vHubURL} />,
            width: PopupSizes.MEDIUM,
          });
        }
      }}
      isFullSize={true}
    >
      <IconContainer>
        <img id="icon" src={BookWithIcon} />
      </IconContainer>
    </ContactsButton>
  );
};

export const VhubBookWithPopup: React.FC<VhubBookWithButtonProps> = (props) => {
  const { closingPopup } = usePopup();

  const linkToVhub = () => {
    if (props.loadID) {
      track(createVhubBookingTrackData(VhubEvent.Proceed, props.loadID));
    }
    closingPopup();
    window.open(props.vHubURL, '_blank');
  };

  return (
    <BasePopup title={t(T.common_vHub_vHubMEMPopupHeader)} onClose={closingPopup} withoutContentPadding={true}>
      <PopupContentSection>
        <ImageContainer>
          <img src={vHubIcon}></img>
        </ImageContainer>
        <FormContainer style={{ marginRight: `${Spacing.InterSection}`, marginLeft: `${Spacing.InterSection}` }}>
          <TextContainer id="vhub_popup_details" style={{ marginBottom: `${Spacing.InterElementVertical}` }}>
            {t(T.common_vHub_vHubPopupDetails)}
          </TextContainer>
          <TextContainer id="vhub_popup_details" style={{ marginBottom: `${Spacing.InterSection}` }}>
            {t(T.common_vHub_vHubPopupSubDetails)}
          </TextContainer>
          <PopUpButton
            id="vHub_proceed_button"
            onClick={linkToVhub}
            variant={'contained'}
            text={t(T.common_vHub_vHubPopupButtonLabel)}
            color={'primary'}
          />
        </FormContainer>
      </PopupContentSection>
    </BasePopup>
  );
};

export const stateBboxArray = {
  MN: {
    y: 488,
    x: 656,
    x2: 716,
    y2: 557,
  },
  WA: {
    y: 462,
    x: 442,
    x2: 504,
    y2: 508,
  },
  BC: {
    y: 296,
    x: 405,
    x2: 527,
    y2: 478,
  },
  DE: {
    y: 579,
    x: 845,
    x2: 855,
    y2: 595,
  },
  DC: {
    y: 591,
    x: 835,
    x2: 837,
    y2: 594,
  },
  WI: {
    y: 515,
    x: 691,
    x2: 739,
    y2: 567,
  },
  WV: {
    y: 578,
    x: 787,
    x2: 829,
    y2: 619,
  },
  HI: {
    y: 605,
    x: 258,
    x2: 326,
    y2: 627,
  },
  HI_USA: {
    y: 605,
    x: 258,
    x2: 326,
    y2: 627,
  },
  FL: {
    y: 694,
    x: 750,
    x2: 835,
    y2: 759,
  },
  YT: {
    y: 178,
    x: 397,
    x2: 494,
    y2: 332,
  },
  WY: {
    y: 529,
    x: 534,
    x2: 599,
    y2: 583,
  },
  NH: {
    y: 508,
    x: 863,
    x2: 878,
    y2: 540,
  },
  SK: {
    y: 352,
    x: 558,
    x2: 628,
    y2: 491,
  },
  NJ: {
    y: 560,
    x: 847,
    x2: 859,
    y2: 588,
  },
  NL: {
    y: 321,
    x: 857,
    x2: 999,
    y2: 451,
  },
  NM: {
    y: 624,
    x: 536,
    x2: 601,
    y2: 690,
  },
  TX: {
    y: 636,
    x: 560,
    x2: 691,
    y2: 763,
  },
  LA: {
    y: 678,
    x: 685,
    x2: 738,
    y2: 723,
  },
  NB: {
    y: 463,
    x: 877,
    x2: 922,
    y2: 505,
  },
  NC: {
    y: 617,
    x: 777,
    x2: 856,
    y2: 653,
  },
  ND: {
    y: 490,
    x: 600,
    x2: 661,
    y2: 528,
  },
  NE: {
    y: 559,
    x: 595,
    x2: 672,
    y2: 597,
  },
  TN: {
    y: 626,
    x: 720,
    x2: 799,
    y2: 654,
  },
  NY: {
    y: 516,
    x: 805,
    x2: 875,
    y2: 568,
  },
  PA: {
    y: 554,
    x: 799,
    x2: 853,
    y2: 589,
  },
  PE: {
    y: 471,
    x: 915,
    x2: 934,
    y2: 479,
  },
  NS: {
    y: 461,
    x: 911,
    x2: 952,
    y2: 514,
  },
  NT: {
    y: 87,
    x: 461,
    x2: 632,
    y2: 358,
  },
  NU: {
    y: 1,
    x: 541,
    x2: 859,
    y2: 446,
  },
  NV: {
    y: 547,
    x: 451,
    x2: 511,
    y2: 637,
  },
  VA: {
    y: 588,
    x: 781,
    x2: 853,
    y2: 629,
  },
  AB: {
    y: 339,
    x: 497,
    x2: 578,
    y2: 484,
  },
  CO: {
    y: 578,
    x: 545,
    x2: 613,
    y2: 631,
  },
  ON: {
    y: 396,
    x: 672,
    x2: 843,
    y2: 566,
  },
  AK_USA: {
    x: 300,
    y: 634,
    x2: 318,
    y2: 800,
  },
  AK: {
    y: 113,
    x: 108,
    x2: 455,
    y2: 377,
  },
  AL: {
    y: 650,
    x: 740,
    x2: 776,
    y2: 708,
  },
  AR: {
    y: 636,
    x: 679,
    x2: 726,
    y2: 679,
  },
  VT: {
    y: 512,
    x: 851,
    x2: 867,
    y2: 541,
  },
  IL: {
    y: 565,
    x: 706,
    x2: 743,
    y2: 630,
  },
  GA: {
    y: 647,
    x: 765,
    x2: 816,
    y2: 699,
  },
  IN: {
    y: 571,
    x: 739,
    x2: 767,
    y2: 620,
  },
  IA: {
    y: 556,
    x: 660,
    x2: 717,
    y2: 592,
  },
  MA: {
    y: 534,
    x: 858,
    x2: 889,
    y2: 551,
  },
  AZ: {
    y: 616,
    x: 481,
    x2: 545,
    y2: 689,
  },
  CA: {
    y: 537,
    x: 418,
    x2: 492,
    y2: 664,
  },
  ID: {
    y: 473,
    x: 486,
    x2: 541,
    y2: 563,
  },
  CT: {
    y: 546,
    x: 859,
    x2: 874,
    y2: 562,
  },
  ME: {
    y: 478,
    x: 868,
    x2: 902,
    y2: 532,
  },
  MD: {
    y: 581,
    x: 813,
    x2: 855,
    y2: 600,
  },
  OK: {
    y: 630,
    x: 600,
    x2: 681,
    y2: 671,
  },
  OH: {
    y: 563,
    x: 763,
    x2: 802,
    y2: 607,
  },
  UT: {
    y: 559,
    x: 499,
    x2: 552,
    y2: 624,
  },
  MO: {
    y: 589,
    x: 668,
    x2: 730,
    y2: 642,
  },
  MB: {
    y: 357,
    x: 624,
    x2: 713,
    y2: 492,
  },
  MI: {
    y: 500,
    x: 711,
    x2: 781,
    y2: 573,
  },
  OR: {
    y: 490,
    x: 425,
    x2: 500,
    y2: 553,
  },
  RI: {
    y: 545,
    x: 873,
    x2: 880,
    y2: 554,
  },
  KS: {
    y: 596,
    x: 610,
    x2: 679,
    y2: 632,
  },
  MT: {
    y: 475,
    x: 509,
    x2: 604,
    y2: 536,
  },
  QC: {
    y: 311,
    x: 772,
    x2: 946,
    y2: 519,
  },
  MS: {
    y: 652,
    x: 710,
    x2: 743,
    y2: 710,
  },
  SC: {
    y: 642,
    x: 787,
    x2: 834,
    y2: 678,
  },
  KY: {
    y: 601,
    x: 727,
    x2: 795,
    y2: 636,
  },
  MX: {
    y: 660,
    x: 438,
    x2: 771,
    y2: 901,
  },
  SD: {
    y: 525,
    x: 597,
    x2: 662,
    y2: 568,
  },
};

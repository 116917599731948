import { SetStateAction, useEffect, useMemo, useState } from 'react';

import { debounce, filter, includes, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { PrivateMemberSortDirection } from '@/model';
import { getDefaultLoadBoard, getWhiteList } from '@/reduxStore/epic/PrivateNetworkEpic';
import { Routes } from '@/router/Routes';
import { removeLeadingZeroes } from '@common/helper/NumberHelper';
import { Roles } from '@common/model';
import { useSelector } from '@util/hooks';

export interface Filter {
  value: string | undefined;
  isValid: boolean;
}

export interface ListComponentProps {
  filteredWhiteList: string[] | undefined;
  loadBoardId: string | undefined;
  page: number;
  setPage: React.Dispatch<SetStateAction<number>>;
}

const MAIN_PATH = generatePath(Routes.MORE_SETTINGS_PRIVATE_LOADBOARD, {});
export const RESULTS_PER_PAGE = 50;

export const PrivateNetworkSubRoutes = {
  main: MAIN_PATH,
  addMembers: `${MAIN_PATH}/add-members/`,
};

export const useLoadBoard = (loadBoardId: string | undefined) =>
  useSelector((state) => (loadBoardId !== undefined ? state.privateNetwork.loadBoards[loadBoardId] : undefined));

export const useDefaultLoadBoard = () => {
  const loadBoardId = useSelector((state) => state.privateNetwork.defaultLoadBoardId);
  const userLoadBoard = useLoadBoard(loadBoardId);

  return { userLoadBoard: userLoadBoard, loadBoardId: loadBoardId };
};

export const useGetDefaultLoadboard = () => {
  const dispatch = useDispatch();
  const isLoadingLoadBoards = useSelector((state) => state.privateNetwork.isLoadingLoadBoards);
  const error = useSelector((state) => state.privateNetwork.loadBoardError);
  const roles = useSelector((state) => state.user.profile?.payload?.roles);
  const { userLoadBoard } = useDefaultLoadBoard();

  useEffect(() => {
    if (includes(roles, Roles.Loadposter) && !userLoadBoard && !isLoadingLoadBoards && !error) {
      dispatch(getDefaultLoadBoard.fetchAction(undefined));
    }
  }, [isLoadingLoadBoards, userLoadBoard, roles]);
};

export const useDefaultLoadBoardWithWhiteList = () => {
  const dispatch = useDispatch();
  const { userLoadBoard, loadBoardId } = useDefaultLoadBoard();

  useEffect(() => {
    if (loadBoardId && !userLoadBoard?.isLoading) {
      dispatch(getWhiteList.fetchAction({ id: loadBoardId, sortOrder: PrivateMemberSortDirection.ascending }));
    }
  }, [loadBoardId]);

  return { userLoadBoard: userLoadBoard, loadBoardId: loadBoardId };
};

export const useDebouncedFilter = (whiteList: string[] | undefined, dotFilter: Filter) => {
  const [filteredList, setFilteredList] = useState(whiteList);
  const debouncedChange = useMemo(
    () =>
      debounce(
        (whiteListItems: string[] | undefined, filterValue: Filter) =>
          setFilteredList(
            filterValue.value
              ? filter(whiteListItems, (item) => includes(item, removeLeadingZeroes(filterValue.value)))
              : whiteListItems
          ),
        250
      ),
    []
  );

  useEffect(() => {
    debouncedChange(whiteList, dotFilter);
  }, [dotFilter, whiteList]);

  useEffect(() => {
    if (isEmpty(filteredList)) {
      setFilteredList(whiteList);
    }
  }, [whiteList]);

  return dotFilter.value ? filteredList : whiteList;
};

export const getPageNumber = (index: number) => Math.floor(index / RESULTS_PER_PAGE);

import { AppConfig } from '@util/AppConfig';

export const LOAD_PLANNER_LINK = `${AppConfig.LBConfig.ASPRoot}/online/carrier/planner/`;
export const MULTIPLE_LOAD_POST_LINK = `${AppConfig.LBConfig.ASPRoot}/online/broker/MyLoads.aspx?DefaultAction=MultiplePost`;
export const MY_DOCS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/carrier/tools/MyDocs.aspx`;
export const MILEAGE_MAP_TOLLS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/carrier/tools/Mileage.aspx`;
export const CREDIT_REPORT_LINK = `${AppConfig.LBConfig.ASPRoot}/online/carrier/tools/Credit-Report.aspx`;
export const CARRIER_MONITOR_LINK = `${AppConfig.LBConfig.ASPRoot}/online/broker/tools/Carrier-Monitor.aspx`;
export const SEARCH_DIRECTORY_LINK = `${AppConfig.LBConfig.ASPRoot}/online/carrier/tools/SearchDirectory.aspx`;
export const SEARCH_DOCS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/broker/tools/SearchDocs.aspx`;
export const POTENTIAL_LOADS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/`;
export const BOOKED_LOADS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/?MyBooked=1`;
export const COMPANY_SEARCH_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/Companies.aspx#Search`;
export const BLOCKED_HIDDEN_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/Companies.aspx#Hidden`;
export const FAVORITES_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/Companies.aspx#Favorites`;
export const LOAD_BIDS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/Bids.aspx#Loads`;
export const MANAGE_ALERTS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/Alerts.aspx#Alerts`;
export const ALERT_SETTINGS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/Alerts.aspx#Preferences`;

export const ACCOUNT_ACTIVATION = `${AppConfig.LBConfig.ASPRoot}/online/account/email_verify.aspx`;
export const MY_ACCOUNT_LINK = `${AppConfig.LBConfig.ASPRoot}/online/account/`;
export const MESSAGE_CENTER_LINK = `${AppConfig.LBConfig.ASPRoot}/online/account/Message/?pid=lm`;
export const ALERTS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/loadboard/Alerts.aspx`;
export const SETTINGS_LINK = `${AppConfig.LBConfig.ASPRoot}/online/account/Settings/?pid=lm`;
export const HELP_LINK = `${AppConfig.LBConfig.ASPRoot}/online/help/`;

export const BASE_SCRAPE_URL = `${AppConfig.LBConfig.ASPRoot}/online/broker/tools/postviewer/?CID=`;
export const EXPORT_TO_CSV_URL = `${AppConfig.LBConfig.ASPEmbedRoot}/online/broker/ExportToCSV.aspx`;
export const LOAD_EXPIRED = `${AppConfig.LBConfig.CMSRoot}/expiredload/`;
export const MANAGE_HIDDEN = `${AppConfig.LBConfig.CMSRoot}/online/loadboard/Companies.aspx?pid=lm#Hidden`;

export const PRINT_LINK = `${AppConfig.LBConfig.ASPRoot}/online/reports/LoadDetails.aspx?GUID=`;
export const LOAD_CREDIT_REPORT_LINK = `${AppConfig.LBConfig.ASPEmbedRoot}/online/reports/CreditReport.aspx?CPGUID=`;

export enum ApiErrorCode {
  PERMISSION_DENIED = 4030003,
  EMAIL_NOT_VERIFIED = 4030004,
  /** email exists, but pending confirmation */
  EMAIL_NOT_CONFIRMED = 4030013,
  EMAIL_ALREADY_EXISTS = 4000007,
  LOAD_LIMIT_REACHED = 4030006,
  LOAD_NOT_FOUND = 4040009,
  NAMED_LOAD_SEARCH_NOT_FOUND = 4042200,
  ACTION_PERMISSION_DENIED = 4030011,
  RATE_CHECK_LIMIT_REACHED = 4030016,
  EMAIL_NOT_FOUND_NO_EMAIL = 4000047,
  EMAIL_NOT_FOUND_NO_USERNAME = 4000360,
  FOLDER_ALREADY_EXISTS = 4001508,
  MCP_INVALID_CREDENTIALS = 4002125,
  MCP_ONBOARDING_CARRIER_FAILED_RISK_ASSESSMENT = 4002126,
  PUSH_NOTIFICATION_TOKEN_NOT_FOUND = 4040022,
  INVALID_UPLOAD_FILE_TYPE = 4001513,
  INVALID_CHARACTERS_FILENAME_TYPE = 4001506,
  UNABLE_TO_RETRIEVE_TF_INTEGRATION_STATUS = 5000936,
  LOAD_SEARCH_SETTINGS_ALREADY_EXIST = 4090012,
  INVALID_VALIDATION_CODE = 4001294,
  EXPIRED_VALIDATION_CODE = 4000341,
  PHONE_IS_LANDLINE = 4006030,
  RATING_NOT_FOUND_FOR_USDOT = 4042608,
  USER_DOES_NOT_HAVE_COMPANY = 4040110,
  PHONEVERIFICATION_TOO_MANY_ATTEMPTS = 4290001,
  VALIDATION_CODE_TOO_MANY_ATTEMPTS = 4290002,
  PHONENUMBER_INVALID = 4040127,
  /** most likely invalid phone number */
  TWILIO_BAD_REQUEST = 4006085,
  PHONENUMBER_BLACKLISTED = 4030041,
  UPGRADE_PROMO_CODE_INVALID = 4040026,
  LOAD_SEARCH_LIMIT_REACHED = 4030005,
  LOAD_VIEW_LIMIT_REACHED = 4030006,
  RATE_CHECK_FAILED_TO_COMPUTE_MILAGE = 4040111,
  RATE_CHECK_RATE_NOT_FOUND = 4040020,
  GEOLOCATION_DOES_NOT_CORRESPOND = 4040112,
  ZIP_CODE_DOES_NOT_CORRESPOND = 4040113,
  VERIFY_EMAIL_CODE_EXPIRED = 4000076,
  VERIFY_EMAIL_CODE_ALREADY_USED = 4040002,
  CREDITCARD_NUMBER_NOT_ACCEPTED = 4006108,
  CREDITCARD_AVS_NOT_ACCEPTED = 4006109,
  CREDITCARD_CVD_NOT_ACCEPTED = 4006110,
  CREDITCARD_EXPIRED = 4006111,
  INVALID_MEMBERSHIP_STATUS = 4090042,
  REACTIVATION_CHARGE_INVOICE_ERROR = 4090043,
  /** Booking/Vendor Bid error codes */
  BOOK_NOW_LOAD_UNAVAILABLE = 4042101,
  BOOK_NOW_NOT_FOUND_ERROR = 4042102,
  BID_LOAD_UNAVAILABLE = 4041102,
  BID_NOT_FOUND_ERROR = 4040141,
  BID_DISQUALIFIED_CARRIER = 4060021,
  BID_INVALID_DOT_MC_ERROR = 4009021, // same dot and mc error from Parade
}

export enum ErrorUri {
  InvalidUserNameOrPassword = '0',
  AccessForbidden = '1',
  ForbiddenAccessForBasic = '2',
  EmailNotConfirmed = '3',
  UserDoesNotExist = '4',
  EmailDoesNotExist = '5',
  EmailNotValid = '6',
  InternalError = '7',
  EmailBounced = '8',
  EmailSoftBounced = '9',
  InvalidVersionHeader = '10',
  VersionNotSupported = '11',
  InvalidVersionFormat = '12',
  OtherLoadboardException = '13',
  MissingUserNameOrPassword = '14',
  MissingGrantType = '15',
  OAuthClientNotFound = '16',
  InvalidClientSecret = '17',
  MissingClientIdOrSecret = '18',
  InactiveApi = '19',
  ExternalEmailMissing = '20',
  ExternalEmailNotConfirmed = '21',
  ExternalLoginNotFound = '22',
  ExternalEmailTooLong = '23',
  EmailDomainNotSupported = '24',
  ExternalFirstNameMissing = '25',
  ExternalLastNameMissing = '26',
  ExternalProfileUnavailable = '27',
}

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { first, includes, isEqual, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import { useCommunicationService } from '@/AppDependencies';
import { default as MessageIcon } from '@/icons/message_locked_icon.svg';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import {
  getFileExtension,
  getIsFirstOfGroup,
  getIsLastOfGroup,
  getMessageReadAt,
  trimFileExtension,
  useAutoRefreshManager,
} from '@common/helper';
import { formatDateDDDll } from '@common/helper/DateHelper';
import { ConversationMessage } from '@common/model/Conversation';
import {
  getCanMessage,
  getConversationLoadDetails,
  getConversationUserStatus,
  getMessagesList,
  markMessageRead,
  onMessageUpdate,
} from '@common/redux/epic/CommunicationEpic';
import { fetchCarrierInfo } from '@common/redux/epic/CompanyEpic';
import { fetchConversationDocumentFile } from '@common/redux/epic/DocumentsEpic';
import { CommunicationTopic } from '@common/socket/Communication';
import { useDidUpdate } from '@common/util/hooks';
import { ConversationContactNotVerified } from '@component/banner/ContactNotVerifiedBanner';
import { MoreMenuButton } from '@component/buttons/MoreMenuButton';
import { ChatFooter } from '@component/conversation/composer/ChatFooter';
import { ConversationAttachmentObject } from '@component/conversation/ConversationAttachments';
import { MessageBubble } from '@component/conversation/ConversationMessageBubble';
import { WidgetMessageBubble } from '@component/conversation/ConversationWidgetBubble';
import { Loader } from '@component/loader';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { InfiniteScrollView, useScrollIntoView } from '@component/scrollView';
import { LoadConversationsOriginUI } from '@page/communication/LoadConversations';
import { ActionsMenu } from '@page/myDocuments/ActionsMenu';
import { LoadingFullscreenPanel } from '@page/myDocuments/LoadingFullscreenPanel';
import { ViewDocument } from '@page/myDocuments/ViewDocument';
import { Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import { downloadBase64File } from '@util/FileHelper';
import { useAppVisibilityChange, usePrevious, useSelector } from '@util/hooks';

import { ConversationLoadHeader } from './CommunicationChatHeader';
import { ContactMessagingDisabled, NewConversationBanner, OfflineMessage } from './Conversation';
import { useConversationUsers, useEnableConversationForContact, useOtherUserInfo } from './ConversationHelper';
import { default as DownloadSVG } from './download.svg?react';

const HasMoreSpace = styled.div`
  height: 20px;
`;

const HasExtraSpace = styled.div`
  height: 80px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-right: 18px;
`;

interface CommunicationParams {
  conversationID: string;
}

interface CommunicationChatProps {
  layer: number;
  originUI: LoadConversationsOriginUI;
  hasLoadID: boolean;
}

export const CommunicationChat = (props: CommunicationChatProps) => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const messageBottomRef = useRef<any>();
  const lastMessageID = useRef<string | undefined>(undefined);
  const hasMarkedAsRead = useRef(false);

  const [isOtherUserOnline, setIsOtherUserOnline] = useState(false);
  const [document, setDocument] = useState<ConversationAttachmentObject>();

  const params = useParams<CommunicationParams>();
  const conversationID = params.conversationID || '';
  const communicationService = useCommunicationService();

  const isPageHidden = useSelector((state) => state.userActivity.isPageHidden);
  const isLoadingFolder = useSelector((state) => state.documents.folders.createFolderUpdate.isLoading);
  const conversationsListIds = useSelector((state) => state.communication.conversationsListIds);
  const conversationInfo = useSelector((state) => state.communication.conversationMessages.get(conversationID));
  const conversationMessages = conversationInfo?.messages || [];
  const load = useSelector((state) => state.communication.conversations.get(conversationID)?.metadata?.load);
  const users = useSelector((state) => state.communication.conversations.get(conversationID)?.users);

  const otherUserInfo = useOtherUserInfo(conversationID);
  const userOnlineStatus = otherUserInfo?.status.online || false;
  const prevOtherUserInfo = usePrevious(otherUserInfo);
  const prevUsers = usePrevious(users);
  const isConversationEnabledForContact = useEnableConversationForContact(conversationID);

  const scrollIntoView = useScrollIntoView();

  const isStandalone =
    props.originUI === LoadConversationsOriginUI.CommunicationPage ||
    props.originUI === LoadConversationsOriginUI.MessagingWidget;

  useAutoRefresh(conversationID);

  useEffect(() => {
    if (otherUserInfo?.user.userId && isConversationEnabledForContact) {
      dispatch(fetchCarrierInfo(otherUserInfo.user.userId));
    }
  }, [otherUserInfo?.user.userId]);

  useEffect(() => {
    setIsOtherUserOnline(userOnlineStatus);
  }, [userOnlineStatus]);

  useEffect(() => {
    communicationService.connectToTopics({
      onUpdateMessagesState: (update) => {
        update.messages?.map((message) =>
          dispatch(onMessageUpdate(conversationID, message.messageId, message.users ?? []))
        );
      },
    });
    return () => {
      communicationService.disconnectFromTopic(CommunicationTopic.UpdateMessagesState);
    };
  }, []);

  useEffect(() => {
    if (conversationID && load) {
      dispatch(getConversationLoadDetails(load.id));
    }
  }, [conversationID, load, conversationInfo?.isLoading]);

  useEffect(() => {
    dispatch(getMessagesList({ conversationID: conversationID }));
  }, [conversationID]);

  useEffect(() => {
    if (!load && !isEqual(users, prevUsers)) {
      const userIds = map(users, (u) => u.userId);
      dispatch(getCanMessage(userIds));
    }
  }, [users]);

  useEffect(() => {
    if (conversationInfo && !conversationInfo.isLoading) {
      if (otherUserInfo && prevOtherUserInfo === undefined) {
        scrollIntoView(messageBottomRef.current);
      } else if (!otherUserInfo) {
        dispatch(getConversationUserStatus({ conversationID: conversationID }));
      }
    }
  }, [conversationID, conversationInfo?.isLoading, otherUserInfo]);

  const [isLastMessageVisible, setLastMessageVisible] = useState(true);

  useEffect(() => {
    const newLastMessage = first(conversationMessages);
    if (newLastMessage && newLastMessage.messageId !== lastMessageID.current) {
      hasMarkedAsRead.current = false;
    }

    if (isLastMessageVisible) {
      scrollIntoView(messageBottomRef.current);
    }
  }, [conversationMessages]);

  useEffect(() => {
    const latestMessage = first(conversationMessages);
    if (
      !isPageHidden &&
      latestMessage &&
      isLastMessageVisible &&
      !hasMarkedAsRead.current &&
      latestMessage.messageId !== lastMessageID.current
    ) {
      lastMessageID.current = latestMessage.messageId;
      hasMarkedAsRead.current = true;
      dispatch(markMessageRead({ conversationID: conversationID, messageID: latestMessage.messageId }));
    }
  }, [conversationMessages, isLastMessageVisible, isPageHidden]);

  let statusContent: JSX.Element = <LoadingSpinner />;
  if (!conversationInfo?.isLoading && !conversationInfo?.response?.success) {
    statusContent = <ErrorScreen />;
  }

  const openAttachmentsPanel = () => {
    if (document) {
      return <ViewAttachmentsPanel layer={props.layer + 1} documentFile={document} setDocument={setDocument} />;
    }

    return null;
  };

  const offlineDisabledUser = !isConversationEnabledForContact ? (
    <ContactMessagingDisabled style={{ marginBottom: Spacing.InterElementVertical }} />
  ) : !isOtherUserOnline ? (
    <OfflineMessage style={{ marginBottom: Spacing.InterElementVertical }} isOffline={!isOtherUserOnline} />
  ) : null;

  const hasMessages = !conversationInfo?.isLoading && conversationInfo?.response?.success;
  const isInvalidConversation = !includes(conversationsListIds, conversationID);

  // hasLoadID is used for determining whether the value is coming from the messaging widget
  // Currently the way this is implemented is not ideal as we are going through the main conversation list
  // and filtering by loadID to only show one id. The ideal would be to hit this url
  // `load/:loadID/communication/chat/:conversationID. That way we do not need to rely on
  // this particular flag and can be removed.
  const shouldShowLoading = !hasMessages || (isInvalidConversation && isStandalone && !props.hasLoadID);

  return (
    <>
      <Panel
        id="conversations_chat"
        size={PanelSize.small}
        layer={props.layer}
        offset={1}
        scrollRef={scrollContainerRef}
        backgroundColor={PANEL_SHADOW_BACKGROUND}
      >
        <ConversationLoadHeader
          conversationID={conversationID}
          isLoading={conversationInfo?.isLoading}
          originUI={props.originUI}
        />
        <HasMoreSpace />
        {shouldShowLoading ? (
          <Container style={{ justifyContent: 'center', alignItems: 'center' }}>{statusContent}</Container>
        ) : (
          <>
            <MessagesList
              scrollRef={scrollContainerRef}
              messages={conversationMessages}
              onLastMessageVisibilityChange={setLastMessageVisible}
              conversationID={conversationID}
              setDocument={setDocument}
            >
              <div ref={messageBottomRef} />
            </MessagesList>

            {conversationMessages.length === 0 ? <NewConversationBanner hasLoad={!!load} /> : null}
            {otherUserInfo && !conversationInfo?.isLoading ? offlineDisabledUser : null}
            {isConversationEnabledForContact ? (
              <ConversationContactNotVerified conversationID={conversationID} />
            ) : null}

            {props.originUI === LoadConversationsOriginUI.MessagingWidget ? <HasExtraSpace /> : null}
            {!isConversationEnabledForContact ? null : (
              <ChatFooter
                conversationID={conversationID}
                originUI={props.originUI}
                isLoading={conversationInfo?.isLoading ?? false}
                isOtherUserOnline={otherUserInfo?.status.online ?? false}
              />
            )}
          </>
        )}
      </Panel>
      {openAttachmentsPanel()}
      {isLoadingFolder ? <LoadingFullscreenPanel layer={props.layer + 1} /> : null}
    </>
  );
};

const Loading = styled(Loader)`
  display: flex;
  justify-content: center;
`;

const ViewAttachmentsPanel: React.FC<{
  layer: number;
  documentFile: ConversationAttachmentObject;
  setDocument: React.Dispatch<React.SetStateAction<ConversationAttachmentObject | undefined>>;
}> = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const menuAnchor = useRef<HTMLDivElement>(null);

  const documentFileData = useSelector((state) => state.documents.viewDocument.documentFileData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDownloading) {
      if (documentFileData && documentFileData?.id === props.documentFile.docId) {
        downloadBase64File(
          documentFileData.file ?? '',
          trimFileExtension(props.documentFile.name),
          getFileExtension(props.documentFile.name)
        );
        setIsDownloading(false);
      }
    }
  }, [isDownloading, documentFileData]);

  const downloadFile = () => {
    dispatch(
      fetchConversationDocumentFile(
        props.documentFile.conversationId,
        props.documentFile.messageId,
        props.documentFile.docId,
        props.documentFile.extension
      )
    );
    setIsDownloading(true);
  };

  const options = [
    {
      title: t(T.common_myDocuments_documentActionSheet_download),
      icon: <DownloadSVG id="download" />,
      action: downloadFile,
    },
  ];

  const viewDocument = () => {
    if (isDownloading || !props.documentFile || props.documentFile.file === '') {
      return <Loading />;
    } else if (props.documentFile.document && props.documentFile.file) {
      return (
        <ViewDocument
          documentBase64={props.documentFile.file}
          extension={props.documentFile.extension}
          isCommunicationsAttachments={true}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Panel
        id="view_attachment"
        offset={2}
        layer={props.layer}
        size={PanelSize.medium}
        backgroundColor={PANEL_SHADOW_BACKGROUND}
      >
        <PanelHeader
          label={props.documentFile.name ?? ''}
          hasCloseButton={true}
          onClose={() => props.setDocument(undefined)}
          actionElement={<MoreMenuButton onClick={() => setIsMenuOpened(true)} ref={menuAnchor} />}
        />
        {viewDocument()}
        {document ? (
          <ActionsMenu
            anchor={isMenuOpened && menuAnchor.current ? menuAnchor.current : undefined}
            onClose={() => setIsMenuOpened(false)}
            documentName={props.documentFile.name}
            options={options}
          />
        ) : null}
      </Panel>
    </>
  );
};

const ErrorScreen: React.FC = () => {
  return (
    <NotFoundPanel
      title={t(T.common_conversations_unavailable_title)}
      subtitle={t(T.common_conversations_unavailable_subtitle)}
      imageSection={<img id="image" src={MessageIcon} alt="error" />}
    />
  );
};

// @TODO: Add pagination.
const MessagesList: React.FC<{
  scrollRef: React.RefObject<HTMLInputElement>;
  onLastMessageVisibilityChange: (isVisible: boolean) => void;
  messages: ConversationMessage[];
  conversationID: string;
  setDocument: React.Dispatch<React.SetStateAction<ConversationAttachmentObject>>;
}> = (props) => {
  const isLoadingConversation = useSelector(
    (state) => state.communication.conversationMessages.get(props.conversationID)?.isLoading !== false
  );
  const otherUserInfo = useOtherUserInfo(props.conversationID);
  const isLoading = isLoadingConversation || !otherUserInfo;
  const messages = useMessageBubbles(
    props.conversationID,
    props.messages,
    props.onLastMessageVisibilityChange,
    props.setDocument
  );

  return (
    <InfiniteScrollView
      id="conversation_list"
      hasMore={false}
      rootRef={props.scrollRef}
      isReverse={true}
      isLoading={isLoading}
    >
      {isLoading ? null : messages}
      {props.children}
    </InfiniteScrollView>
  );
};

const useMessageBubbles = (
  conversationID: string,
  messages: ConversationMessage[],
  onLastMessageVisibilityChange: (isVisible: boolean) => void,
  setDocument: React.Dispatch<React.SetStateAction<ConversationAttachmentObject>>
) => {
  const conversationUsers = useConversationUsers(conversationID);
  const userInfo = useSelector((state) => state.user.profile?.payload);
  const otherUserInfo = useOtherUserInfo(conversationID);

  const requestLocationFromConversation = useSelector((state) => state.carrierTracking.conversation);
  const dispatch = useDispatch();

  useDidUpdate(() => {
    if (!requestLocationFromConversation.sendLocation.isSending) {
      dispatch(
        showSnackbar({
          message: requestLocationFromConversation.sendLocation.wasSuccessful
            ? t(T.locationRequest_sentLocationSuccessfully)
            : t(T.locationRequest_failure),
        })
      );
    }
  }, [requestLocationFromConversation.sendLocation]);

  return useMemo(() => {
    const chatMessages = messages.slice().reverse();

    const datesGroupSet = new Set(map(chatMessages, (msg) => (msg.sentAt ? formatDateDDDll(msg.sentAt) : '')));
    return map(chatMessages, (msg, i, msgs) => {
      const user = conversationUsers.get(msg.sentBy);
      const isFromCurrentUser = user?.userId === userInfo?.id;

      let dateMessage = undefined;
      if (msg.sentAt && datesGroupSet.has(formatDateDDDll(msg.sentAt))) {
        dateMessage = formatDateDDDll(msg.sentAt);
        datesGroupSet.delete(dateMessage);
      }
      const readAtTime = getMessageReadAt(msg);

      const isFirstOfGroup = getIsFirstOfGroup(msg, i === 0 ? undefined : msgs[i - 1], !isFromCurrentUser);
      const isLastOfGroup = getIsLastOfGroup(msg, msgs[i + 1] ?? undefined, !isFromCurrentUser);
      if (msg.widget) {
        return (
          <div key={msg.messageId}>
            <WidgetMessageBubble
              key={msg.messageId}
              message={msg.text}
              isFromCurrentUser={isFromCurrentUser}
              user={user}
              time={msg.sentAt}
              read={msg.read}
              readAtTime={readAtTime}
              userID={user?.userId}
              date={dateMessage}
              messageId={msg.messageId}
              conversationId={conversationID}
              widget={msg.widget}
              otherPartyUserID={otherUserInfo?.user.userId ?? msg.sentBy}
              isFirstOfGroup={isFirstOfGroup}
              isLastOfGroup={isLastOfGroup}
            />
          </div>
        );
      }
      if (chatMessages && i === chatMessages.length - 1) {
        return (
          <div key={msg.messageId}>
            <ReactVisibilitySensor key={i} onChange={onLastMessageVisibilityChange}>
              <MessageBubble
                key={msg.messageId}
                message={msg.text}
                isFromCurrentUser={isFromCurrentUser}
                user={user}
                time={msg.sentAt}
                readAtTime={readAtTime}
                read={msg.read}
                userID={user?.userId}
                date={dateMessage}
                attachments={msg.documents}
                messageId={msg.messageId}
                conversationId={conversationID}
                setDocument={setDocument}
                isFirstOfGroup={isFirstOfGroup}
                isLastOfGroup={isLastOfGroup}
              />
            </ReactVisibilitySensor>
          </div>
        );
      }

      return (
        <div key={msg.messageId}>
          <MessageBubble
            key={msg.messageId}
            message={msg.text}
            isFromCurrentUser={isFromCurrentUser}
            user={user}
            time={msg.sentAt}
            read={msg.read}
            readAtTime={readAtTime}
            userID={user?.userId}
            date={dateMessage}
            attachments={msg.documents}
            messageId={msg.messageId}
            conversationId={conversationID}
            setDocument={setDocument}
            isFirstOfGroup={isFirstOfGroup}
            isLastOfGroup={isLastOfGroup}
          />
        </div>
      );
    });
  }, [conversationUsers, userInfo, messages, onLastMessageVisibilityChange]);
};

const useAutoRefresh = (conversationID: string) => {
  const dispatch = useDispatch();

  const onRefresh = () => dispatch(getConversationUserStatus({ conversationID: conversationID }));

  const autoRefreshManager = useAutoRefreshManager(onRefresh, 30);

  useEffect(() => {
    autoRefreshManager.restart();
  }, [conversationID]);

  useAppVisibilityChange((isVisible) => {
    if (isVisible) {
      autoRefreshManager.restart();
    } else {
      autoRefreshManager.stop();
    }
  });
};

import { LoadEquipment } from '@/model';
import {
  BaseLocation,
  EquipmentType,
  LoadExtraStop,
  LoadSize,
  Phone,
  PostLoadVisibilityRulesList,
  Rate,
} from '@common/model';

export const INITIAL_EQUIPMENT: LoadEquipment = {
  equipmentType: EquipmentType.None,
  equipmentSpecifications: [],
};

interface LoadPostRequestEquipment {
  equipmentType: EquipmentType;
  equipmentSpecifications?: string;
}

export interface LoadPostRequest {
  postReference?: string;
  numberOfLoads?: number;
  originLocation: BaseLocation;
  destinationLocation: BaseLocation;
  pickupDateTimes?: string[];
  deliveryDateTime?: string;
  equipments?: LoadPostRequestEquipment[];
  equipmentInfo?: string;
  loadSize: LoadSize;
  mileage?: number;
  length?: number;
  weight?: number;
  rate?: Rate;
  numberOfStops?: number;
  teamDriving?: boolean;
  commodity?: string;
  notes?: string;
  privateLoadNote?: string;
  dispatchPhone?: Phone;
  dispatchName?: string;
  dispatchEmail?: string;
  saveLocations?: boolean;
  extraStops?: LoadExtraStop[];
  saveLoadTemplate?: boolean;
  visibility?: PostLoadVisibilityRulesList;
}

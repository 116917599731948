import { forEach, reduce, uniq, without } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$, of as of$ } from 'rxjs';
import { mergeMap as mergeMap$ } from 'rxjs/operators';

import { OnboardedCarriersClient } from '@/client';
import {
  DOTEntities,
  ModifyOnboardedCarriersRequest,
  NormalizedList,
  OnboardedCarrier,
  OnboardedCarriers,
  OnboardedCarriersResponse,
  UpdateCheckedStatus,
} from '@/model';
import { Api } from '@common/api';
import { createAction, createApiAction, createApiActionWithFetchData, ResultResponseAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const modifyOnboardedCarriersAction = createApiAction<ModifyOnboardedCarriersRequest, {}>('MODIFY_ONBOARDED_CARRIERS');
const fetchOnboardedCarriersAction = createApiAction<{ offset?: number; limit?: number }, OnboardedCarriersResponse>(
  'FETCH_ONBOARDED_CARRIERS'
);
const deleteOnboardedCarriersAction = createApiActionWithFetchData<OnboardedCarriers, {}>('DELETE_ONBOARDED_CARRIERS');
const changeCheckedStatusAction = createAction<UpdateCheckedStatus>('UPDATE_CHECKED_STATUS');
const clearRecentlyAddedNumbersAction = createAction<undefined>('CLEAR_RECENTLY_ADDED_NUMBERS');
const clearUpdateResultAction = createAction<undefined>('CLEAR_UPDATE_RESULT');

export const fetchOnboardedCarriers = (offset?: number, limit?: number) =>
  fetchOnboardedCarriersAction.fetchAction({ offset: offset, limit: limit });
export const deleteOnboardedCarriers = (request: OnboardedCarriers) =>
  deleteOnboardedCarriersAction.fetchAction(request);
export const modifyOnboardedCarriers = (usdotNumbers: string[], invalidUsdotNumbers: string[]) =>
  modifyOnboardedCarriersAction.fetchAction({ usdotNumbers: usdotNumbers, invalidList: invalidUsdotNumbers });
export const changeCheckedStatus = (request: UpdateCheckedStatus) => changeCheckedStatusAction.action(request);
export const clearRecentlyAddedNumbers = () => clearRecentlyAddedNumbersAction.action(undefined);
export const clearUpdateResult = () => clearUpdateResultAction.action(undefined);

export interface OnboardedCarriersState {
  normalizedList: NormalizedList;
  totalCount: number;
  wasListUpdated?: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  didFetchFail?: boolean;
  recentlyAddedNumbers: { invalid: string[]; valid: string[] };
  recentlyDeletedNumbers: string[];
}

const initialState: OnboardedCarriersState = {
  normalizedList: { ui: [], entities: {} },
  totalCount: 0,
  isLoading: false,
  isDeleting: false,
  wasListUpdated: undefined,
  recentlyAddedNumbers: { valid: [], invalid: [] },
  recentlyDeletedNumbers: [],
};

export const onboardedCarriersReducer = createMergedReducer(initialState, [
  changeCheckedStatusAction.addCase((state, action) => {
    forEach(action.data.usdotNumbers, (checkStatus, usDotNumber) => {
      state.normalizedList.entities[usDotNumber] = checkStatus;
    });
  }),

  clearRecentlyAddedNumbersAction.addCase((state) => {
    state.recentlyAddedNumbers = { valid: [], invalid: [] };
  }),

  clearUpdateResultAction.addCase((state) => {
    state.wasListUpdated = undefined;
  }),

  modifyOnboardedCarriersAction.initiateCase((state, action) => {
    state.isLoading = true;
    state.wasListUpdated = undefined;
    state.recentlyAddedNumbers = {
      valid: without(action.data.usdotNumbers, ...state.normalizedList.ui),
      invalid: action.data.invalidList,
    };
    state.recentlyDeletedNumbers = without(state.normalizedList.ui, ...action.data.usdotNumbers);
  }),
  modifyOnboardedCarriersAction.completeCase((state, action) => {
    state.isLoading = false;
    state.wasListUpdated = action.response.success;
  }),

  fetchOnboardedCarriersAction.initiateCase((state) => {
    state.isLoading = true;
    state.didFetchFail = undefined;
  }),
  fetchOnboardedCarriersAction.completeCase((state, action) => {
    state.isLoading = false;
    if (action.response.success) {
      state.normalizedList = normalizeListFromServer(action.response.payload.dotNumbers, state.normalizedList.entities);
      state.totalCount = action.response.payload.totalCount;
    } else {
      state.didFetchFail = true;
    }
  }),

  deleteOnboardedCarriersAction.initiateCase((state) => {
    state.isDeleting = true;
    state.wasListUpdated = undefined;
  }),
  deleteOnboardedCarriersAction.completeCase((state, action) => {
    state.isDeleting = false;
    state.wasListUpdated = action.response.success;

    if (action.fetchData && action.response.success) {
      state.normalizedList.ui = without(state.normalizedList.ui, ...(action.fetchData.usdotNumbers || []));
      forEach(action.fetchData.usdotNumbers, (id) => {
        delete state.normalizedList.entities[id];
      });
    }
  }),
]);

const fetchOnboardedCarriersOnUpdate$ = (action$: ActionsObservable<Action>) =>
  action$.ofType(modifyOnboardedCarriersAction.responseType).pipe(
    mergeMap$((action: ResultResponseAction<{}>) => {
      if (action.response.success) {
        return of$(fetchOnboardedCarriersAction.fetchAction({}));
      }
      return of$();
    })
  );

export const createOnboardedCarriersEpic = (api: Api) => {
  const client = new OnboardedCarriersClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      modifyOnboardedCarriersAction.createEpic$(action$, (request) =>
        client.modifyOnboardedCarriers$(uniq(request.usdotNumbers))
      ),
      deleteOnboardedCarriersAction.createEpic$(action$, client.deleteOnboardedCarriers$),
      fetchOnboardedCarriersAction.createEpic$(action$, (data) =>
        client.getOnboardedCarriers$(data.offset, data.limit)
      ),
      fetchOnboardedCarriersOnUpdate$(action$)
    );
};

const normalizeListFromServer = (newList: OnboardedCarrier[], existingList: DOTEntities): NormalizedList => ({
  ui: newList.map((item) => item.usdotNumber.toString()),
  entities: reduce(
    newList,
    (accumulator, onboardedCarrier) => {
      accumulator[onboardedCarrier.usdotNumber.toString()] = {
        isChecked: existingList[onboardedCarrier.usdotNumber]?.isChecked || false,
        source: onboardedCarrier.source,
      };
      return accumulator;
    },
    {} as DOTEntities
  ),
});

import React, { useEffect } from 'react';

import { ApiError } from '@common/api';
import { AppErrorCode } from '@common/model/AppErrorCode';
import { PhoneNumber } from '@component/contact/PhoneNumber';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { T, t } from '@translate';

interface Props {
  error?: ApiError;
  onClose?: () => void;
}

/**
 * This is used to handle APiError inside the component and show popup with corresponding message according to an error code
 * @param error ApiError error from the Api to retrieve information
 * @param onClose function This callback will be fired then the popup is closing*/
export const ApiErrorPopup: React.FC<Props> = ({ error, onClose }) => {
  const { openingPopup, closingPopup } = usePopup();

  const closePopup = () => {
    onClose?.();
    closingPopup();
  };

  useEffect(() => {
    if (error) {
      const errorTitle = error.httpStatus >= 500 || !error.title ? t(T.error_genericErrorTitle) : error.title;
      let errorMessage = error.httpStatus >= 500 ? t(T.error_genericErrorMessage) : error.message;
      let secondaryMessage =
        error.httpStatus >= 500 ? <MessageWithPhoneNumber isNetworkError={false} /> : t(T.error_message_retry);
      //in the case then we have no response from server
      if (!error.httpStatus || error.httpStatus <= 0 || error.code < 0 || isApiSauceError(error.code)) {
        errorMessage = undefined;
        secondaryMessage = <MessageWithPhoneNumber isNetworkError={true} />;
      }
      const content = (
        <>
          {errorMessage}
          {errorMessage ? <br /> : null}
          {secondaryMessage}
        </>
      );
      openingPopup({
        body: (
          <BasePopup
            title={errorTitle}
            buttons={[<PopupOkButton testID={t(T.common_ok)} key={t(T.common_ok)} action={closePopup} />]}
          >
            {content}
          </BasePopup>
        ),
        width: PopupSizes.SMALL,
        isDismissible: true,
        onBackdropClick: onClose,
        hasCloseButton: true,
      });
    }
  }, [error]);

  return null;
};

const MessageWithPhoneNumber: React.FC<{ isNetworkError: boolean }> = ({ isNetworkError }) => {
  return (
    <>
      {`${isNetworkError ? t(T.error_connectionErrorMessage) : t(T.error_genericContactSupport)} `}
      <PhoneNumber
        id={'api_error_phone_number'}
        phoneNumberString={t(T.contactSupportPhone)}
        phoneNumberValue={t(T.contactSupportPhone)}
      />
      {t(T.common_period)}
    </>
  );
};

//Find more information about this error codes in MobileApiService.ts
const isApiSauceError = (code: number) => {
  return (
    code === AppErrorCode.TIMEOUT_ERROR ||
    code === AppErrorCode.CONNECTION_ERROR ||
    code === AppErrorCode.NETWORK_ERROR ||
    code === AppErrorCode.UNKNOWN_ERROR
  );
};

import React, { useCallback, useEffect } from 'react';

import { toString } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { Routes } from '@/router/Routes';
import { usePrevious, useSelector } from '@/util/hooks';
import {
  displayCurrencyOrDash,
  displayCurrencyOrNothing,
  hasDecimal,
  NO_INFORMATION_SYMBOL_LONG,
} from '@common/formatter';
import { formatDateMMDDYYYY, formatNumber } from '@common/helper';
import { BookingStatus, BrokerResponseSpeed, Load } from '@common/model';
import { bookLoad } from '@common/redux/epic/LoadInfoEpic';
import { ContactButtonProps, ContactsButton } from '@component/buttons/ContactsButton';
import { FORCE_VERIFICATION_URL_QUERY } from '@component/panels/settings/company/BusinessProfileHelper';
import { BasePopup } from '@component/popup/BasePopup';
import { BidDetailsComponent, BidTrailerDetailsComponent } from '@component/popup/PlaceBidPopup';
import { PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupContentSection } from '@component/popup/PopupContentSections';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text } from '@component/text';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { Color } from '@style/Color';
import { FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';

import { InfoRibbon } from '../InfoRibbon';
import { FormContainer, OverlayContent, PopUpButton, TextContainer } from '../LoadDetailsPanelStyle';
import { VerifyDOTPopup, verifyDotPopupType } from '../LoadDetailsPopups';
import { default as BookNowIcon } from './icon-book-now.svg';
import { default as BookedIcon } from './icon-booked.svg';

interface BookNowProps {
  load?: Load;
  isFullSize?: boolean;
  isLabelHidden?: boolean;
}

const BookAmountText = withTheme()(styled.div`
  font-size: ${(props: ThemeProps) => props.theme.text.h2.fontSize};
  font-weight: ${(props: ThemeProps) => props.theme.text.h2.fontWeight};
  text-align: center;
  margin-top: ${Spacing.ElementPaddingVertical}px;
  margin-bottom: ${Spacing.ElementPaddingVertical}px;
`);

export const BookNowButton: React.FC<ContactButtonProps & { onClick: () => void }> = (props) => {
  const { openingPopup, closingPopup } = usePopup();
  const dispatch = useDispatch();

  const isBooked = props.load?.bookNow?.bookingStatus === BookingStatus.Accepted;
  const isPending = props.load?.bookNow?.bookingStatus === BookingStatus.Pending;

  const loadInfo = useSelector((state) =>
    props.load?.id !== undefined ? state.loadInfo.loadInfoMap.get(props.load.id) : undefined
  );
  const bookNowState = loadInfo?.bookNow;
  const prevIsSendingBookNow = usePrevious(loadInfo?.bookNow?.isSending);

  useEffect(() => {
    if (!bookNowState?.isSending && prevIsSendingBookNow) {
      if (bookNowState?.isSentSuccessfully) {
        closingPopup();
        dispatch(showSnackbar({ message: t(T.common_bookNow_bookedSuccessfully) }));
      } else {
        closingPopup();
        if (bookNowState?.isLoadUnavailable || bookNowState?.isNotFound) {
          openingPopup({
            body: (
              <BasePopup
                title={bookNowState.isLoadUnavailable ? t(T.common_bookNow_dialog_unavailable) : t(T.common_error)}
                buttons={[
                  <PopupPrimaryButton
                    testID="ok_button"
                    key="ok_button"
                    label={t(T.common_ok)}
                    action={closingPopup}
                  />,
                ]}
              >
                {bookNowState.isLoadUnavailable
                  ? t(T.common_bookNow_dialog_unavailable_content)
                  : t(T.common_bookNow_dialog_not_found)}
              </BasePopup>
            ),
            width: PopupSizes.SMALL,
            isDismissible: true,
            hasCloseButton: true,
          });
        } else {
          dispatch(
            showSnackbar({
              message: t(T.error_message_retry),
            })
          );
        }
      }
    }
  }, [bookNowState]);

  const buttonBookedTitle = isBooked ? t(T.common_bookNow_booked) : t(T.common_bookNow_title);
  const buttonTitle = isPending ? t(T.common_bookNow_pending) : buttonBookedTitle;

  return (
    <ContactsButton
      id="book_button"
      icon={{ regular: isBooked || isPending ? BookedIcon : BookNowIcon }}
      title={props.isFullSize && !props.isLabelHidden ? buttonTitle : undefined}
      onClick={props.onClick}
      isFullSize={props.isFullSize}
      style={{ backgroundColor: isPending ? Color.ORANGE_MAIN : '' }}
    />
  );
};

const BookNowPopup: React.FC<BookNowProps> = (props) => {
  const dispatch = useDispatch();

  const { closingPopup } = usePopup();
  const loadInfo = useSelector((state) =>
    props.load?.id !== undefined ? state.loadInfo.loadInfoMap.get(props.load.id) : undefined
  );
  const isSendingBookNow = loadInfo?.bookNow?.isSending;
  const bookNowAmount = props.load?.rate?.amount;
  const bookButtonAndTitledText = t(T.common_bookNow_title);

  const trailerType = props.load?.equipments;

  const onBookNow = () => {
    if (props.load) {
      dispatch(bookLoad(props.load.id));
    }
  };

  return (
    <BasePopup title={bookButtonAndTitledText} onClose={closingPopup} withoutContentPadding={true}>
      <InfoRibbon headerLabel={t(T.common_bookNow_dialog_information)}>
        <OverlayContent>
          <BidTrailerDetailsComponent
            detailsTitle={t(T.common_vendorBid_placeBid_equipmentTypes)}
            detailsType={trailerType}
          />
          <BidDetailsComponent
            detailsTitle={t(T.common_vendorBid_placeBid_commodity)}
            detailsType={props.load?.commodity ? props.load?.commodity : NO_INFORMATION_SYMBOL_LONG}
          />
          <BidDetailsComponent
            detailsTitle={`${t(T.common_load_Length)}: `}
            detailsType={
              props.load?.length ? `${formatNumber(props.load.length)} ${t(T.common_feet)}` : NO_INFORMATION_SYMBOL_LONG
            }
          />
          <BidDetailsComponent
            detailsTitle={`${t(T.common_load_Weight)}: `}
            detailsType={
              props.load?.weight ? `${formatNumber(props.load.weight)} ${t(T.common_lbs)}` : NO_INFORMATION_SYMBOL_LONG
            }
          />
        </OverlayContent>
      </InfoRibbon>

      <PopupContentSection>
        {t(T.common_bookNow_dialog_content_part1)}
        <BookAmountText id="book_now_rate">
          {displayCurrencyOrDash(bookNowAmount, hasDecimal(bookNowAmount))}
        </BookAmountText>
        <TextContainer>{t(T.common_bookNow_dialog_content_part2)}</TextContainer>
        <FormContainer style={{ marginRight: `${Spacing.InterSection}`, marginLeft: `${Spacing.InterSection}` }}>
          <PopUpButton
            id="book_button"
            onClick={onBookNow}
            variant={'contained'}
            text={bookButtonAndTitledText}
            color={'primary'}
            isLoading={isSendingBookNow}
          />
          <Text
            id="posted_call_message"
            style={{
              marginTop: Spacing.InterSection,
              marginBottom: 0,
              color: Color.GRAY_STONE,
              fontFamily: `${FontWeight.Regular}`,
              fontSize: `${FontSize.ContentDetail}`,
            }}
          >
            {t(T.common_bookNow_dialog_submit)}
          </Text>
        </FormContainer>
      </PopupContentSection>
    </BasePopup>
  );
};

const PendingPopup: React.FC<{ load?: Load }> = (props) => {
  const { closingPopup } = usePopup();
  const brokerResponseSpeed = props.load?.bookNow?.responseSpeed;
  return (
    <BasePopup
      title={t(T.common_bookNow_pending)}
      buttons={[
        <PopupPrimaryButton testID={'ok_button'} key={'ok_button'} label={t(T.common_ok)} action={closingPopup} />,
      ]}
    >
      {t(
        brokerResponseSpeed === BrokerResponseSpeed.Fast
          ? T.common_bookNow_dialog_pendingShort
          : T.common_bookNow_dialog_pendingLong
      )}
    </BasePopup>
  );
};

const BookedPopup: React.FC<{ load?: Load }> = (props) => {
  const { closingPopup } = usePopup();
  return (
    <BasePopup
      title={t(T.common_bookNow_booked)}
      buttons={[
        <PopupPrimaryButton testID={'ok_button'} key={'ok_button'} label={t(T.common_ok)} action={closingPopup} />,
      ]}
    >
      {t(T.common_bookNow_dialog_alreadyBooked, {
        date: formatDateMMDDYYYY(props.load?.bookNow?.submittedDateTime ?? ''),
        amount: displayCurrencyOrNothing(props.load?.bookNow?.amount, hasDecimal(props.load?.bookNow?.amount)),
      })}
    </BasePopup>
  );
};

const VerifyDOTPopupWrapper: React.FC<{ dotNumber?: string }> = (props) => {
  const { closingPopup } = usePopup();
  const { dotNumber } = props;
  const history = useHistory();
  return (
    <VerifyDOTPopup
      action={() => {
        history.push({
          pathname: generatePath(Routes.MORE_SETTINGS, { filter: Filter.BusinessProfile }),
          search: `${FORCE_VERIFICATION_URL_QUERY}=1`,
        });
      }}
      type={verifyDotPopupType.bookNow}
      closingPopup={closingPopup}
      dotNumber={dotNumber}
    />
  );
};

const getBookNowPopup = (load?: Load, isDOTVerified?: boolean) => {
  if (load?.bookNow?.bookingStatus === BookingStatus.Pending) {
    return PendingPopup;
  } else if (load?.bookNow?.bookingStatus === BookingStatus.Accepted) {
    return BookedPopup;
  } else {
    if (!isDOTVerified) {
      return VerifyDOTPopupWrapper;
    } else {
      return BookNowPopup;
    }
  }
};

export const useOnBookNow = (load: Load | undefined) => {
  const { openingPopup } = usePopup();

  const isDOTVerified = useSelector((state) => state.user.profile?.payload?.isUsdotNumberVerified);
  const dotNumber = useSelector((state) => state.companyState.company?.usdotNumber);

  return useCallback(() => {
    const Popup = getBookNowPopup(load, isDOTVerified);
    openingPopup({
      body: <Popup load={load} dotNumber={toString(dotNumber)} />,
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  }, [load, isDOTVerified, openingPopup, dotNumber]);
};

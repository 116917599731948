import React from 'react';

import { filter, map, replace } from 'lodash';

import { displayCurrencyOrDash } from '@common/formatter';
import { formatRideTimeToDuration } from '@common/helper';
import { RouteDirections } from '@common/model';
import { RouteDirectionStep } from '@common/model/PCMiler';
import { parseNumber } from '@common/util/parser/ParserUtils';
import {
  PageText,
  PageTextBold,
  PageTextBoldRed,
  StyledCard,
} from '@component/panels/mileageCalculator/MileageCalculatorStyleSheet';
import { TextStyle } from '@component/text/Text';
import { t, T } from '@translate';

export const DirectionsList: React.FC<{ directions: RouteDirections; shouldShowStops: boolean }> = (props) => {
  let stepCounter = 1;
  let tollCounter = 0;
  let tollAtStop = 0;
  const stepsWithoutStops = filter(
    props.directions.directionSteps,
    (step, index) => !step.stop || index === 0 || index === props.directions.directionSteps.length - 1
  );
  const steps = props.shouldShowStops ? props.directions.directionSteps : stepsWithoutStops;

  const isStopOrWarning = (step: RouteDirectionStep, index: number, toll?: number) => {
    if (step.stop) {
      stepCounter = 0;
      tollAtStop = tollCounter;
      tollCounter = 0;
      return (
        <StopDirectionInformation
          index={index}
          step={step}
          directions={props.directions}
          lastSepIndex={steps.length - 1}
          tollAtStop={tollAtStop}
        />
      );
    } else if (step.warning) {
      return <WarningInformation index={index} warning={step.warning} />;
    } else {
      stepCounter = stepCounter + 1;
      if (toll && toll > 0) {
        tollCounter = tollCounter + toll;
      }
      return <StepInformation index={index} step={step} stepCounter={stepCounter} toll={toll} />;
    }
  };

  return (
    <StyledCard>
      {map(steps, (step, i) => {
        const toll = parseNumber(step.toll);
        return isStopOrWarning(step, i, toll);
      })}
    </StyledCard>
  );
};

const arriveAt = (stop: string) => {
  return replace(stop, /(Stop\s\d|Origin|Dest):/, '');
};

const displayTollValue = (toll: number) => {
  return (
    <PageTextBoldRed id="direction_toll" textStyle={TextStyle.TextContentDetail}>
      {` ${t(T.common_pcMiler_toll)} ${displayCurrencyOrDash(toll)}`}
    </PageTextBoldRed>
  );
};

const displayRouteMilesAndTime = (miles: string, time: string) => {
  return `(${miles}mi, ${time})`;
};

const WarningInformation: React.FC<{ warning: string; index: number }> = (props) => {
  return (
    <PageText id="warning" key={props.index} textStyle={TextStyle.TextContentDetail}>
      <PageTextBold id="warning" key={props.index} textStyle={TextStyle.TextContentDetail}>
        {props.warning}
      </PageTextBold>
    </PageText>
  );
};

const StepInformation: React.FC<{ step: RouteDirectionStep; stepCounter: number; index: number; toll?: number }> = (
  props
) => {
  return (
    <PageText id="direction" key={props.index} textStyle={TextStyle.TextContentDetail}>
      {`${props.stepCounter}. ${props.step.instruction} (${props.step.miles}mi, ${formatRideTimeToDuration(
        props.step.time
      )})`}
      {props.toll && props.toll > 0 ? (
        <PageTextBoldRed id="direction_toll" key={props.index} textStyle={TextStyle.TextContentDetail}>
          {` ${t(T.common_pcMiler_toll)} ${displayCurrencyOrDash(props.toll)}`}
        </PageTextBoldRed>
      ) : null}
    </PageText>
  );
};

const StopArrivalInformation: React.FC<{ step: RouteDirectionStep; index: number; tollAtStop: number }> = (props) => {
  return (
    <PageText id="arriving_to" key={props.index} textStyle={TextStyle.TextContentDetail}>
      {t(T.menu_mileageCalculatorPanel_arriavalTo)}
      <PageTextBold id="arriving_to_info" key={props.index} textStyle={TextStyle.TextContentDetail}>
        {arriveAt(props.step.stop)}
        {displayRouteMilesAndTime(props.step.routeMiles, props.step.routeTime)}
        {displayTollValue(props.tollAtStop)}
      </PageTextBold>
    </PageText>
  );
};

const StopDirectionInformation: React.FC<{
  step: RouteDirectionStep;
  index: number;
  directions: RouteDirections;
  tollAtStop: number;
  lastSepIndex: number;
}> = (props) => {
  const isLastIndex = () => {
    if (props.index === props.lastSepIndex) {
      return (
        <>
          {displayRouteMilesAndTime(props.directions.rideMiles, props.directions.rideTime)}
          {props.directions.tollCosts ? displayTollValue(props.directions.tollCosts) : null}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {props.index !== 0 ? (
        <StopArrivalInformation step={props.step} index={props.index} tollAtStop={props.tollAtStop} />
      ) : null}
      <PageText id="stop_route" key={props.index} textStyle={TextStyle.TextContentDetail}>
        <PageTextBold id="stop_name" key={props.index} textStyle={TextStyle.TextContentDetail}>
          {props.step.stop}
          {isLastIndex()}
        </PageTextBold>
      </PageText>
    </>
  );
};

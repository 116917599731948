import { find, values } from 'lodash';
import { useLocation } from 'react-router-dom';

import { PlainRoutes } from '@/router/Routes';
import { useIsBiddingEnabled } from '@page/bids/BidStatusHelper';
import { useIsBrowserWithFloatingScrollbars } from '@util/hooks/FloatingScrollbarsHook';
import { useIsMobileView } from '@util/hooks/MobileViewHook';
import { useSelector } from '@util/hooks/UseRedux';

export const SIDEMENU_OPEN_WIDTH = 300;
export const SIDEMENU_ICON_ONLY_WIDTH = 74;

export const useSideMenuWidth = () =>
  useSelector((store) => (store.applicationSettings.menuOpen ? SIDEMENU_OPEN_WIDTH : SIDEMENU_ICON_ONLY_WIDTH));

export const useHasNotifications = () => {
  const isBiddingEnabled = useIsBiddingEnabled();
  const hasUnreadBids = useSelector((state) =>
    isBiddingEnabled ? state.bids.summary.brokerUnreadBids > 0 || state.bids.summary.carrierUnreadBids > 0 : false
  );
  const hasAlerts = useSelector(
    (store) =>
      (store.user.userAlerts?.payload?.alertsInfo.alertMatchesCount ?? 0) > 0 ||
      (store.communication.unreadMessagesCount ?? 0) > 0 ||
      (store.user.userAlerts?.payload?.messagesInfo.unreadMessagesCount ?? 0) > 0
  );
  return hasUnreadBids || hasAlerts;
};

// TODO: figure this for better support of 'wider' touch-only devices (iPads/tablets or landscape-mode phones) [BP]
// until this is fixed, nested div will be used (causing annoying quirks).
/** true if showing a single scroll view (page/panel) even in non-mobile view */
export const useIsSinglePageScroll = () => false;

/** true if window scroll mode */
export const useIsWindowScrollMode = () => {
  const isMobileView = useIsMobileView();
  const isFloatingScrollbars = useIsBrowserWithFloatingScrollbars();

  const isSinglePageScroll = useIsSinglePageScroll();
  return isFloatingScrollbars && (isMobileView || isSinglePageScroll);
};

/**
 * Return true if current page should show header / navigation menu
 */
export const useShouldDisplayHeader = () => {
  const location = useLocation().pathname;
  const matchedPlainRoute = find(
    values(PlainRoutes),
    // have to check for `${location}/` as well, since re-direct to path with ending
    // slash happens in Routing.tsx only after the page has rendered once already
    (plainRoute) => plainRoute === location || plainRoute === `${location}/`
  );
  return matchedPlainRoute === undefined;
};

import React from 'react';

import { RouteProps, useLocation } from 'react-router-dom';

import { NestedRoute, UnitedRoute } from '@/router/RouteGroup';
import { COMMUNICATION_KEY, Routes } from '@/router/Routes';
import { usePanelBackLocation } from '@component/panel/PanelHooks';
import { CommunicationListPanel } from '@component/panels/communication/CommunicationListPanel';
import { CreateNewTruckPost } from '@component/panels/truckPost/CreateNewTruckPost';
import { SubRoutes } from '@component/panels/truckPost/TruckPostLists';
import { CommunicationChat } from '@page/communication/CommunicationChat';
import { LoadConversationsOriginUI } from '@page/communication/LoadConversations';
import { useSelector } from '@util/hooks';

import { CommunicationLoadDetails } from './CommunicationLoadDetails';
import { CommunicationPCMilerMap } from './CommunicationPCMilerMap';
import { ConversationContactInfo } from './ConversationContactInfoPanel';
import { LoadContactInfo } from './LoadContactInfoPanel';

interface CommunicationRouteProps extends RouteProps {
  originUI: LoadConversationsOriginUI;
  loadIDMatchParamKey?: string;
  loadID?: string;
  isWidgetButton?: boolean;
}

export const CommunicationRoute = (props: CommunicationRouteProps) => {
  const { originUI, loadIDMatchParamKey, loadID, isWidgetButton = true, ...rest } = props;

  const isCommunicationsEnabled = useSelector((state) => state.communication.isCommunicationsEnabled);
  const closePostTruckPanelPath = usePanelBackLocation(`/${SubRoutes.AddPost}/`);

  const isStandalone =
    originUI === LoadConversationsOriginUI.CommunicationPage ||
    (originUI === LoadConversationsOriginUI.MessagingWidget && !isWidgetButton);

  const baseUrl = useGetBaseUrl(isStandalone);

  const panelsLayer = isStandalone ? 0 : 10;

  // Even with communication disabled, the user may attempt to access communication related features via
  // a direct link (i.e. bookmark). In these cases, we still render CommunicationList because it has
  // a message indicating that the feature is turned off.
  const routes = [
    <NestedRoute key="communication_root" path={'/'}>
      <CommunicationListPanel
        shouldHideClose={isStandalone}
        originUI={originUI}
        layer={panelsLayer}
        loadID={loadID}
        loadIDMatchParamKey={loadIDMatchParamKey}
      />
    </NestedRoute>,
  ];

  if (isCommunicationsEnabled) {
    routes.push(
      <UnitedRoute key="communication_chat" appendedPath="/:chat(chat)/:conversationID?">
        <NestedRoute path={'/'}>
          <CommunicationChat layer={panelsLayer} originUI={originUI} hasLoadID={loadID !== undefined} />
        </NestedRoute>
        <UnitedRoute key="united_communication_details" appendedPath="/:details(details)/:loadID?">
          <NestedRoute key="communication_details" path={'/'}>
            <CommunicationLoadDetails layer={panelsLayer} specialPathname={'communication'} />
          </NestedRoute>
          <NestedRoute key="communication_pc_miler_map" path={'/:loadMap(pc-miler-map)?'}>
            <CommunicationPCMilerMap />
          </NestedRoute>
          <CreateNewTruckPost layer={panelsLayer} baseUrl={baseUrl} onClose={() => closePostTruckPanelPath()} />
        </UnitedRoute>
        <NestedRoute key="communication_contact_info" path={'/:loadcontact(loadcontact)'}>
          <LoadContactInfo layer={panelsLayer} />
        </NestedRoute>
        <NestedRoute key="communication_load_poster_info" path={'/:usercontact(usercontact)/:contactID?'}>
          <ConversationContactInfo layer={panelsLayer} />
        </NestedRoute>
      </UnitedRoute>
    );
  }

  return (
    <UnitedRoute appendedPath={`/:communication(${COMMUNICATION_KEY})`} {...rest}>
      {routes}
    </UnitedRoute>
  );
};

// currently used to get the exact url needed incase of isStandalone or nested panels
// @FIXME: when react-router-dom is updated to latest and the routing strings are corrected
// remove this hook and use the <Route> component to implement route switching
const useGetBaseUrl = (isStandalone: boolean) => {
  const location = useLocation();

  if (isStandalone) {
    return Routes.COMMUNICATION;
  }

  if (location.pathname.includes('/loads/search')) {
    return Routes.LOADS_FINDLOADS;
  } else if (location.pathname.includes('/loads/my-loads')) {
    return Routes.LOADS_MY_LOADS;
  }
  return '';
};

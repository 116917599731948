import { every, some } from 'lodash';

import { isNumber } from '@common/helper/NumberHelper';

export class Field {
  constructor(
    readonly type: FieldType,
    readonly text: string | undefined
  ) {}
  readonly defaultText = '';
  private static readonly unallowedChars = [','];
  readonly isDefaultText = () => this.text === this.defaultText;
  isRequiredToFill = () => this.isDefaultText();
  readonly value = () => (this.isValid() ? this.textToNumber() : Number(this.defaultText));
  readonly textToNumber = () => Number(removeComma(this.text ?? this.defaultText));
  maxValue = 99.99;

  readonly isValid = () =>
    this.isEmpty() ||
    (isNumber(removeComma(this.text ?? this.defaultText)) &&
      this.textToNumber() >= 0 &&
      this.textToNumber() <= this.maxValue);
  readonly isEmpty = () => this.text === '' || this.text === undefined;
  normalisedText = () => (this.value() !== 0 ? formatNumber(this.value()) : this.defaultText);
  private readonly reachedToMax = () => this.value() > this.maxValue;
  readonly doesAllowToType = () =>
    !this.reachedToMax() &&
    every(Field.unallowedChars, (char) => (this.text ? this.text.indexOf(char) === -1 : true)) &&
    this.isValid();
  /**
   * This is an actual value that is not formatted or converted to string e.g. 0.00014 will store be in this variable
   * Is required for calculation, because this.value uses this.text which is formatted and will contain 0.00 in this case
   */
  unformattedValue: number;
}

export enum FieldType {
  Miles = 'miles',
  CostPerMile = 'costPerMile',
  Rate = 'rate',
  MPG = 'mpg',
  CostPerGallon = 'costPerGallon',
  CostPerGallonDiscount = 'costPerGallonDiscount',
  TollCost = 'tollCost',
  DispatchFee = 'dispatchFee',
  OtherFee = 'otherFee',
  Inches = 'inches',
  DeadheadMiles = 'deadheadMiles',
}

export const createField = (fieldType: FieldType, text: string | undefined, isDispatchFeeFlatAmount = false) => {
  const field = new Field(fieldType, text);
  switch (fieldType) {
    case 'miles':
    case 'rate':
      field.maxValue = 99999;
      field.normalisedText = () => (field.value() !== 0 ? formatNumber(field.value(), 0, 0) : field.defaultText);
      break;
    case 'deadheadMiles':
      field.maxValue = 99999;
      field.normalisedText = () => (field.value() !== 0 ? formatNumber(field.value(), 0, 0) : field.defaultText);
      field.isRequiredToFill = () => false;
      break;
    case 'dispatchFee': {
      field.maxValue = 99999.99;
      const formattedNumber = isDispatchFeeFlatAmount ? formatNumber(field.value()) : formatNumber(field.value(), 0, 0);
      field.maxValue = isDispatchFeeFlatAmount ? field.maxValue : 100;
      field.normalisedText = () => (field.value() !== 0 ? formattedNumber : field.defaultText);
      field.isRequiredToFill = () => false;
      break;
    }
    case 'inches': {
      field.maxValue = 11;
      field.isRequiredToFill = () => false;
      break;
    }
    case 'costPerGallon': {
      field.normalisedText = () => (field.value() !== 0 ? formatNumber(field.value(), 0, 3) : field.defaultText);
      break;
    }
    case 'costPerGallonDiscount':
      field.isRequiredToFill = () => false;
      field.normalisedText = () => (field.value() !== 0 ? formatNumber(field.value(), 2, 2) : field.defaultText);
      break;
    case 'mpg':
      field.normalisedText = () => (field.value() !== 0 ? formatNumber(field.value(), 0, 1) : field.defaultText);
      break;
    case 'tollCost':
    case 'otherFee':
      field.maxValue = 99999.99;
      field.isRequiredToFill = () => false;
  }
  return field;
};

export const isAnyFieldEmpty = (...fields: Field[]) => {
  return some(fields, (field) => field.isEmpty());
};

export const isAnyFieldInvalid = (...fields: Field[]) => {
  return some(fields, (field) => !field.isValid());
};

export const isAnyFieldValueRequired = (...fields: Field[]) => {
  return some(fields, (field) => field.isRequiredToFill());
};

export const areAllFieldsEmpty = (...fields: Field[]) => {
  return every(fields, (field) => field.isEmpty());
};

export const removeComma = (text: string) => text.replace(',', '');

export const formatNumber = (num: number, minimumFractionDigits = 2, maximumFractionDigits = 2) =>
  num.toLocaleString('en', {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });

import { BaseClient } from '@common/client/BaseClient';
import { addArchivingHeader } from '@common/helper/FlowIDHeaderHelper';
import { LoadFeedbackRequest } from '@common/model/LoadFeedbackRequest';

export class LoadFeedbackClient extends BaseClient {
  sendLoadFeedback$ = (
    loadID: string,
    loadFeedbackRequest: LoadFeedbackRequest,
    archivingFlowID: string | undefined
  ) => {
    const loadFeedbackRequestArray: LoadFeedbackRequest[] = [loadFeedbackRequest];
    return this.api.patch$<{}>(
      `loads/${loadID}/userdata`,
      loadFeedbackRequestArray,
      addArchivingHeader(archivingFlowID)
    );
  };
}

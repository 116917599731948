import { inRange } from 'lodash';

import { T, t } from '@translate';

export const getTotalCostPerDay = (truckExpenses: number, homeExpenses: number, workDays: number) =>
  workDays === 0 ? 0 : (truckExpenses + homeExpenses) / workDays;

export const getProfitPerDay = (totalProfit: number, workDays: number) =>
  workDays === 0 ? 0 : Math.round(totalProfit / workDays);

export const getProfitPercentage = (costPerDay: number, profitPerDay: number) =>
  costPerDay > 0 ? ((profitPerDay - costPerDay) / costPerDay) * 100 : 0;

export const isLoadPartiallyProfitable = (profitPercentage: number) => inRange(profitPercentage, -9, 0);

// A “day” in Cost-per-day will need to be calculated based on
// driving time + (load/unload) hours divided by working hours per day.
// Result should be presented to the user rounded up when the decimal value > 0.2 (ie. [1, 1.3] = 1 day, [1.3, 2] = 2 days)
// The only decimal the user should see is to represent half day (i.e [0, 0.3] = 0.5 day, [0.3, 0.9] = 1 day).
export const calculateWorkDays = (drivingTime: number, loadingHours: number, workingHours: number) => {
  if (workingHours <= 0) {
    return 0;
  }

  const driveTimeInHours = drivingTime / 3600;

  const workDays = (driveTimeInHours + loadingHours) / workingHours;

  const quotient = Math.floor(workDays);
  const remainder = workDays - quotient;

  if (quotient === 0) {
    return remainder >= 0.3 ? 1 : 0.5;
  }
  return remainder >= 0.3 ? Math.ceil(workDays) : Math.floor(workDays);
};

export const formatDay = (day: number) =>
  day > 1 ? `${day} ${t(T.common_costPerDay_card_days)}` : `${day} ${t(T.common_costPerDay_card_day)}`;

const DEFAULT_SPEED = 55 / 3600; //(mile/second)
export const getDriveTime = (driveTimeFromLoad: number, mileageFromLoad: number, mileageFromUserInput: number) => {
  // use default speed in-case the driveTime is missing from the pc-miler
  const speed = driveTimeFromLoad === 0 || mileageFromLoad === 0 ? DEFAULT_SPEED : mileageFromLoad / driveTimeFromLoad;

  return mileageFromUserInput / speed;
};

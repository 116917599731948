import { clone, find, forEach, map } from 'lodash';
import memoizeOne from 'memoize-one';

import { LoadSortField, loadSortFor, SortDirection, ViewChoice } from '@common/model';
import { LoadAvailabilitySort, LoadAvailabilitySortCategory } from '@common/model/LoadAvailability';
import { LoadSearchSort, LoadSortCategory, LoadSortDirection } from '@common/model/LoadSearchSort';
import { ResultSortCategory, SearchDirectoryCompanySort } from '@common/model/SearchDirectory';
import { t, T } from '@translate';

import { DisplayOption } from './InterfaceHelper';

export type IKeysSortByRegion = Sort & LoadAvailabilitySort;
export type IKeysSortBySearchDirectoryCompany = Sort & SearchDirectoryCompanySort;
export type IKeysSortByLoads = Sort & LoadSearchSort;
export type IKeysSortBy = IKeysSortByRegion | IKeysSortByLoads | ViewChoice;

export interface Sort {
  id: string;
  title: string;
}

export const KeysSortByPickupDate: IKeysSortByLoads = {
  id: '0',
  title: t(T.common_sortByKeys_pickupDate),
  field: LoadSortCategory.PICKUP_DATE,
  direction: LoadSortDirection.Asc,
};

export const KeysSortByAge: IKeysSortByLoads = {
  id: '1',
  title: t(T.common_sortByKeys_postedAge),
  field: LoadSortCategory.AGE,
  direction: LoadSortDirection.Asc,
};

export const StateKeysSortBy: IKeysSortByLoads[] = [
  KeysSortByPickupDate,
  KeysSortByAge,
  {
    id: '2',
    title: t(T.common_search_pickUpLocation),
    field: LoadSortCategory.ORIGIN_STATE_CITY,
    direction: LoadSortDirection.Asc,
  },
  {
    id: '3',
    title: t(T.common_search_dropOffLocation),
    field: LoadSortCategory.DESTINATION_STATE_CITY,
    direction: LoadSortDirection.Asc,
  },
  {
    id: '4',
    title: t(T.common_sortByKeys_trip),
    field: LoadSortCategory.MILEAGE,
    direction: LoadSortDirection.Asc,
  },
  {
    id: '5',
    title: t(T.common_sortByKeys_tripRate),
    field: LoadSortCategory.AMOUNT,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '6',
    title: t(T.common_sortByKeys_pricePerMile),
    field: LoadSortCategory.PRICE_PER_MILE,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '7',
    title: t(T.common_sortByKeys_equipment),
    field: LoadSortCategory.EQUIPMENT,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '8',
    title: t(T.common_sortByKeys_size),
    field: LoadSortCategory.SIZE,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '9',
    title: t(T.common_sortByKeys_weightLb),
    field: LoadSortCategory.WEIGHT,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '10',
    title: t(T.common_sortByKeys_lengthFt),
    field: LoadSortCategory.LENGTH,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '11',
    title: t(T.common_sortByKeys_company),
    field: LoadSortCategory.COMPANY,
    direction: LoadSortDirection.Asc,
  },
  // @TODO add these sorting options when their values are visible to the user in load search
  // {
  //   id: '10',
  //   title: t(T.common_sortByKeys_creditScore),
  //   field: LoadSortCategory.CREDIT_RATING,
  //   direction: LoadSortDirection.Desc,
  // },
  // {
  //   id: '11',
  //   title: t(T.common_sortByKeys_daysToPay),
  //   field: LoadSortCategory.DAYS_TO_PAY,
  //   direction: LoadSortDirection.Desc,
  // },
];

const commonSortKeys = () => {
  const keys = clone(StateKeysSortBy);
  return forEach(keys, (key) => {
    key.id = `${+key.id + 1}`;
  });
};

export const KeysSortByDeadhead: IKeysSortByLoads = {
  id: '0',
  title: t(T.common_sortByKeys_deadheadMiles),
  field: LoadSortCategory.ORIGIN,
  direction: LoadSortDirection.Asc,
};

export const KeysSortBy: IKeysSortByLoads[] = [KeysSortByDeadhead, ...commonSortKeys()];

export const RegionKeysSortBy: IKeysSortByRegion[] = [
  {
    id: '0',
    title: t(T.common_loadAvailability_regionTypes_USA),
    field: LoadAvailabilitySortCategory.USA,
  },
  {
    id: '1',
    title: t(T.common_loadAvailability_regionTypes_Northeast),
    field: LoadAvailabilitySortCategory.Northeast,
  },
  {
    id: '2',
    title: t(T.common_loadAvailability_regionTypes_Midwest),
    field: LoadAvailabilitySortCategory.Midwest,
  },
  {
    id: '3',
    title: t(T.common_loadAvailability_regionTypes_Plains),
    field: LoadAvailabilitySortCategory.Plains,
  },
  {
    id: '4',
    title: t(T.common_loadAvailability_regionTypes_South),
    field: LoadAvailabilitySortCategory.South,
  },
  {
    id: '5',
    title: t(T.common_loadAvailability_regionTypes_West),
    field: LoadAvailabilitySortCategory.West,
  },
  {
    id: '6',
    title: t(T.common_loadAvailability_regionTypes_Canada),
    field: LoadAvailabilitySortCategory.Canada,
  },
  {
    id: '7',
    title: t(T.common_loadAvailability_regionTypes_Other),
    field: LoadAvailabilitySortCategory.Other,
  },
];

export const SearchDirectoryKeysSortBy: IKeysSortBySearchDirectoryCompany[] = [
  {
    id: '0',
    title: t(T.common_sortByKeys_company),
    direction: SortDirection.Ascending,
    field: ResultSortCategory.Name,
  },
  {
    id: '1',
    title: t(T.common_sortByKeys_searchDirectory_location),
    direction: SortDirection.Ascending,
    field: ResultSortCategory.Location,
  },
  {
    id: '2',
    title: t(T.common_sortByKeys_searchDirectory_USDOT),
    direction: SortDirection.Ascending,
    field: ResultSortCategory.DotNo,
  },
  {
    id: '3',
    title: t(T.common_sortByKeys_searchDirectory_MCNO),
    direction: SortDirection.Ascending,
    field: ResultSortCategory.MCNo,
  },
  {
    id: '4',
    title: t(T.common_sortByKeys_searchDirectory_phone),
    direction: SortDirection.Ascending,
    field: ResultSortCategory.Phone,
  },
];

export const loadSortToKeysSortBy = memoizeOne((loadSortBy: LoadSearchSort) => {
  const sortBy = find(KeysSortBy, (sortBy) => {
    return sortBy.field === loadSortBy.field;
  });
  if (!sortBy) {
    return undefined;
  }
  return { ...sortBy, direction: loadSortBy.direction };
});

export const keySortByToLoadSearchSort = (keySort: IKeysSortByLoads): LoadSearchSort => ({
  field: keySort.field,
  direction: keySort.direction,
});

export const loadSortFieldToKeysSortBy = (loadSortField: LoadSortField): IKeysSortByLoads | undefined => {
  const loadSort = loadSortFor(loadSortField);
  return loadSort ? loadSortToKeysSortBy(loadSort) : undefined;
};

export const RadioListSortByOptions = (keys: IKeysSortByLoads[]): DisplayOption<IKeysSortByLoads>[] =>
  map(keys, (sortBy) => ({
    id: sortBy.id,
    key: sortBy.id,
    value: sortBy,
    displayValue: sortBy.title,
  }));

export const RadioListSearchDirectoryCompanySortByOptions = (
  keys: IKeysSortBySearchDirectoryCompany[]
): DisplayOption<IKeysSortBySearchDirectoryCompany>[] =>
  map(keys, (sortBy) => ({
    id: sortBy.id,
    key: sortBy.id,
    value: sortBy,
    displayValue: sortBy.title,
  }));

export const didSortByChange = (prevSortBy: IKeysSortByLoads, newSortBy: IKeysSortByLoads) =>
  prevSortBy.id !== newSortBy.id ||
  prevSortBy.field !== newSortBy.field ||
  prevSortBy.direction !== newSortBy.direction ||
  prevSortBy.title !== newSortBy.title;

export const compareSortOptionsByField = <T extends LoadSearchSort>(a: T, b: T) => a.field === b.field;

import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { toNumber, toString } from 'lodash';

import { validateNotEmpty, ValidationResult } from '@common/helper';
import { useDidUpdate } from '@common/util/hooks';
import { TextField } from '@component/input/textField/CustomTextField';

type CustomTextfieldProps = React.ComponentProps<typeof TextField>;

interface ValidationProps {
  validator: (value?: string) => ValidationResult;
  onChangeWithValidation?: (value: string, isValid: boolean) => void;
  isValidatedWhenEmpty?: boolean;
  isValidatedOnChange?: boolean;
  isInitialError?: boolean;
  initialErrorMessage?: string[];
  shouldIgnoreValidation?: boolean;
  externalValidationResult?: ValidationResult;
}

type Props = ValidationProps & CustomTextfieldProps;

interface State {
  isValid: boolean;
  error: string[];
}

/**
 * @deprecated use FValidatedTextInput
 */
class ValidatedTextInput extends React.Component<Props, State> {
  state: State = {
    isValid: this.props.isInitialError ? !this.props.isInitialError : true,
    error: this.props.initialErrorMessage && this.props.isInitialError ? this.props.initialErrorMessage : [''],
  };
  static defaultProps: Partial<Props> = {
    validator: validateNotEmpty,
    isValidatedWhenEmpty: true,
  };

  componentDidMount() {
    this.validateValue(this.props.value as string);
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.isValidatedWhenEmpty && prevProps.value && !this.props.value) {
      this.setState({ isValid: true, error: [''] });
    } else if (prevProps.value !== this.props.value || this.props.isInitialError !== prevProps.isInitialError) {
      if (this.props.isValidatedOnChange || this.props.isInitialError) {
        const result = this.validateValue(this.props.value as string);
        this.setState(result);
      }
    }
    if (this.props.externalValidationResult !== prevProps.externalValidationResult) {
      if (this.props.externalValidationResult) {
        this.setState(this.props.externalValidationResult);
      } else {
        this.setState({ isValid: true, error: [''] });
      }
    }
  }

  public performValidation = (value: string): boolean => {
    const result = this.props.validator(value);
    this.setState({ isValid: result.isValid, error: result.error });
    return result.isValid;
  };

  validateValue = (value: string): ValidationResult => {
    const { isValid, error } = this.props.validator(value);
    if (this.props.onChangeWithValidation) {
      this.props.onChangeWithValidation(value, isValid);
    }
    return { isValid: isValid, error: error };
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let isTextValid = this.state.isValid;
    if (!this.state.isValid) {
      isTextValid = this.props.validator(event.target.value).isValid;
      this.setState({ isValid: isTextValid });
    }
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    if (this.props.onChangeWithValidation) {
      this.props.onChangeWithValidation(event.target.value, isTextValid);
      const { isValid, error } = this.validateValue(event.target.value);
      this.setState({ isValid: isValid, error: error });
    }
  };

  onBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { isValid, error } = this.validateValue(event.target.value);
    // Error only appears on blur
    this.setState({ isValid: isValid, error: error });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  render() {
    const { disabled, shouldIgnoreValidation, onClick, ...textFieldProps } = this.props;

    const isFieldInvalid = !shouldIgnoreValidation && !this.state.isValid;

    return (
      <TextField
        {...textFieldProps}
        onClick={!disabled ? onClick : undefined}
        disabled={disabled}
        onChange={this.onChange}
        onBlur={this.onBlur}
        autoComplete="nope"
        error={isFieldInvalid}
        helperText={isFieldInvalid ? this.state.error : undefined}
        inputProps={{
          style: {
            marginTop: this.props.multiline && this.props.rows && toNumber(this.props.rows) > 1 ? 25 : 0,
            ...this.props.inputProps?.style,
          },
        }}
      />
    );
  }
}

export { ValidatedTextInput };

interface FValidationProps {
  externalValidationResult?: ValidationResult;
  initialErrorMessage?: string[];
  isInitialError?: boolean;
  isValidatedOnChange?: boolean;
  shouldIgnoreValidation?: boolean;
  onChangeWithValidation?: (value: string, isValid: boolean) => void;
  validator: (value?: string) => ValidationResult;
}

type FValidatedTextInputProps = FValidationProps & CustomTextfieldProps;

export type FValidatedTextInputRef = {
  performValidation: () => boolean;
};

export const FValidatedTextInput = forwardRef<FValidatedTextInputRef, FValidatedTextInputProps>((props, ref) => {
  const {
    disabled,
    externalValidationResult,
    initialErrorMessage,
    isInitialError,
    // `isValidatedOnChange` is set to true by default as this is the default behavior
    isValidatedOnChange = true,
    shouldIgnoreValidation,
    value,
    onBlur,
    onChange,
    onChangeWithValidation,
    onClick,
    validator = validateNotEmpty,
    ...textFieldProps
  } = props;

  const [isValid, setIsValid] = useState(!isInitialError);
  const [error, setError] = useState(initialErrorMessage ?? ['']);

  const isFieldInvalid = !shouldIgnoreValidation && !isValid;

  useImperativeHandle<FValidatedTextInputRef, FValidatedTextInputRef>(
    ref,
    () => ({ performValidation: performValidation }),
    [value]
  );

  useDidUpdate(() => {
    if (externalValidationResult) {
      setIsValid(externalValidationResult.isValid);
      setError(externalValidationResult.error);
    } else {
      resetValidation();
    }
  }, [externalValidationResult]);

  useDidUpdate(() => {
    // since `isValidatedOnChange` is only set at the init of the component and never dynamically changed
    // we can remove it from the dependency array
    if (isValidatedOnChange) {
      performValidation();
    } else {
      resetValidation();
    }
  }, [value]);

  const performValidation = (): boolean => {
    const result = validator(toString(value));
    setIsValid(result.isValid);
    setError(result.error);
    return result.isValid;
  };

  const resetValidation = () => {
    setIsValid(true);
    setError(['']);
  };

  const validateValue = (value: string): ValidationResult => {
    const result = validator(value);
    onChangeWithValidation?.(value, result.isValid);
    return { isValid: result.isValid, error: result.error };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let isTextValid = isValid;
    if (!isValid) {
      isTextValid = validator(event.target.value).isValid;
      setIsValid(isTextValid);
    }

    onChange?.(event);

    if (onChangeWithValidation) {
      onChangeWithValidation(event.target.value, isTextValid);
      const result = validateValue(event.target.value);
      setIsValid(result.isValid);
      setError(result.error);
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    performValidation();
    onBlur?.(event);
  };

  return (
    <TextField
      {...textFieldProps}
      value={value}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="nope"
      error={isFieldInvalid}
      helperText={isFieldInvalid ? error : undefined}
      inputProps={{
        style: {
          marginTop: textFieldProps.multiline && textFieldProps.rows && toNumber(textFieldProps.rows) > 1 ? 25 : 0,
          ...textFieldProps.inputProps?.style,
        },
      }}
    />
  );
});

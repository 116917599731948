import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { default as NewTabIcon } from '@component/panels/basePanel/loadDetails/new window.svg';
import { SpecialLink } from '@component/panels/mileageCalculator/MileageCalculatorStyleSheet';

interface Props {
  onClick: () => void;
  title: string;
  subtitle?: string;
  theme?: Theme;
  icon?: string;
  titleStyle?: React.CSSProperties;
}

export const MapLink: React.FC<Props> = ({ onClick, title, subtitle, theme, icon, titleStyle }) => (
  <SpecialLink id="google_map" onClick={onClick} theme={theme}>
    {icon ? <img id="icon" src={icon} className="icon" alt={'maps'} /> : null}
    <div id="title" className="text" style={titleStyle}>
      {title}
      {subtitle ? (
        <span id="subtitle" className="subtitle">
          {subtitle}
        </span>
      ) : null}
    </div>
    <img id="arrow" src={NewTabIcon} alt=">" className="arrow-icon" />
  </SpecialLink>
);

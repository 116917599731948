import styled from 'styled-components';

import { MultipurposeButton } from '@component/buttons';
import { FontSize, FontWeight, Spacing } from '@style/StyleConstants';

export const BIDDING_SUMMARY_CARD_HEIGHT = 80;
export const BIDS_LIST_CARD_HEIGHT = 146;

export const BidTextContainer = styled.div`
  margin: 8px 8px 16px 8px;
`;

export const BidFormButton = styled(MultipurposeButton)`
  flex: 1;
  margin-right: 10px;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

export const OverlayContent = styled.div`
  font-size: ${FontSize.ContentDetail};
`;

export const OverlayContentTextContainer = styled.div`
  margin-bottom: 0px;
`;

export const OverlayContentTitle = styled.text`
  font-size: ${FontSize.ContentDetail}px;
  font-weight: ${FontWeight.Regular};
`;

export const OverlayContentText = styled.text`
  font-size: ${FontSize.ContentDetail}px;
  font-weight: ${FontWeight.Bold};
`;

export const OverlaySubmissionContainer = styled.div`
  margin-left: ${Spacing.InterElementHorizontal};
  margin-bottom: ${Spacing.InterElementHorizontal};
`;

export const StyledGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${Spacing.InterSection}px;
`;

import React from 'react';

import {
  ActionButton,
  ActionButtonTitle,
  FlexWrap,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import icons from '@util/iconsConstants';

interface Props {
  isDisabled: boolean;
  isSaved: boolean;
  maxWidth: 'unset' | 'min-content';
  onClick: () => void;
}

export const SaveButton: React.FC<Props> = (props) =>
  props.isSaved ? (
    <ActionButton
      id="unsave"
      onClick={props.onClick}
      style={{
        color: Color.ORANGE_MAIN,
        opacity: props.isDisabled ? 0.5 : 1,
        maxWidth: props.maxWidth,
      }}
      disabled={props.isDisabled}
    >
      <FlexWrap>
        <img id="icon" src={icons.savedLoad} alt="save" />
        <ActionButtonTitle id="title">{t(T.common_load_Saved_Load)}</ActionButtonTitle>
      </FlexWrap>
    </ActionButton>
  ) : (
    <ActionButton id="save" onClick={props.onClick} disabled={props.isDisabled} style={{ maxWidth: props.maxWidth }}>
      <FlexWrap>
        <img id="icon" src={props.isDisabled ? icons.saveLoadDisabled : icons.saveLoad} alt="save" />
        <ActionButtonTitle id="title">{t(T.common_load_Save_Load)}</ActionButtonTitle>
      </FlexWrap>
    </ActionButton>
  );

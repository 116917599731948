import React from 'react';

import { Link } from 'react-router-dom';

import { ButtonProps } from '@material-ui/core/Button/Button';

import {
  PrimaryButton,
  PrimaryHeaderButton,
  SecondaryButton,
  SecondaryHeaderButton,
  StyledProgress,
  UnlockButton,
} from '@component/button/ButtonThemes';

export enum ButtonTypes {
  PrimaryButton = 'PrimaryButton',
  PrimaryHeaderButton = 'PrimaryHeaderButton',
  SecondaryButton = 'SecondaryButton',
  SecondaryHeaderButton = 'SecondaryHeaderButton',
  UnlockButton = 'UnlockButton',
}

export interface CustomButtonProps extends ButtonProps {
  id: string;
  isLoading?: boolean;
  buttonType: ButtonTypes;
  /** this is to force an external link (reload browser) even if href is starting with a slash '/' */
  externalLink?: boolean;
}

export const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const renderButtonInside = () => {
    if (props.isLoading) {
      return <StyledProgress />;
    } else {
      return props.children;
    }
  };

  const buttonProps: any = {
    id: props.id,
    onClick: onButtonClick(props.onClick, props.isLoading),
    disabled: props.disabled,
    href: props.href,
    target: props.target,
    fullWidth: props.fullWidth,
  };

  // NOTE: lastIndexOf(xx,0) is the fast-compatible version of startsWith(xx)
  if (props.href && props.href.lastIndexOf('/', 0) === 0 && !props.externalLink) {
    // use internal routing (react-router) for internal links unless specified otherwise (externalLink = true)
    buttonProps.to = props.href;
    buttonProps.component = Link;
    buttonProps.href = undefined;
  }

  switch (props.buttonType) {
    case ButtonTypes.PrimaryHeaderButton:
      return <PrimaryHeaderButton {...buttonProps}>{renderButtonInside()}</PrimaryHeaderButton>;
    case ButtonTypes.SecondaryButton:
      return <SecondaryButton {...buttonProps}>{renderButtonInside()}</SecondaryButton>;
    case ButtonTypes.SecondaryHeaderButton:
      return <SecondaryHeaderButton {...buttonProps}>{renderButtonInside()}</SecondaryHeaderButton>;
    case ButtonTypes.UnlockButton:
      return <UnlockButton {...buttonProps}>{renderButtonInside()}</UnlockButton>;
    default:
      return <PrimaryButton {...buttonProps}>{renderButtonInside()}</PrimaryButton>;
  }
};

export const onButtonClick = (
  onClick: ((event: React.MouseEvent<HTMLElement>) => void) | undefined,
  isLoading: boolean | undefined
) => (!isLoading && onClick ? onClick : undefined);

import React from 'react';

import { generatePath, useHistory } from 'react-router';
import styled from 'styled-components';

import { Routes } from '@/router/Routes';
import { VerificationInfoBadge } from '@component/badge/VerificationBadge';
import { Text } from '@component/text';
import { useUserVerificationStatus } from '@page/communication/ConversationHelper';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

const Container = styled.div`
  border-radius: 6px;
  padding: ${Spacing.Mark}px;
  display: flex;
  margin: ${Spacing.InterSection}px ${Spacing.ScreenSide}px;
  background-color: ${Color.ORANGE_MARIGOLD};
  border: 1px solid ${Color.ORANGE_BORDER};
`;

const Banner = styled(Container)`
  flex-direction: column;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid ${Color.ORANGE_BORDER};
  margin: 0 0 ${Spacing.LargePaddingVertical}px 0;
  padding-top: ${Spacing.InterSection}px;
`;

const RightButton = styled.span`
  && {
    padding: 0 !important;
    display: flex;
    font-size: ${FontSize.ContentDetail}px;
    align-self: flex-end;
    color: ${Color.ORANGE_MAIN};
    cursor: pointer;
    margin-top: 5px;
  }
`;

export const ConversationContactNotVerified: React.FC<{ conversationID: string }> = ({ conversationID }) => {
  const { isCurrentUserUnverified, isOtherUserUnverified } = useUserVerificationStatus(conversationID);

  if (isOtherUserUnverified) {
    return (
      <Container>
        <VerificationInfoBadge isVerified={false} testID="not_verified_icon" style={{ marginRight: 4 }} />
        <Text style={{ color: Color.LIGHT_BLACK }} id="not_verified_warning">
          {t(T.settings_dotVerification_carrierNotVerified)}
        </Text>
      </Container>
    );
  }

  if (isCurrentUserUnverified) {
    return (
      <Container style={{ flexDirection: 'column' }}>
        <VerifyDotContent />
      </Container>
    );
  }

  return null;
};

const VerifyDotContent = () => {
  const history = useHistory();

  const onClickVerifyDotNumberButton = () => {
    history.push({
      pathname: `${generatePath(Routes.MORE_SETTINGS, {
        filter: Filter.BusinessProfile,
      })}/`,
    });
  };
  return (
    <>
      <div style={{ display: 'flex' }}>
        <VerificationInfoBadge isVerified={false} testID="not_verified_icon" style={{ marginRight: 4 }} />
        <Text id="not_verified_warning">
          <Text style={{ fontWeight: 'bold' }} id="verify_dot_text">
            {t(T.settings_dotVerification_verifyYourDot)}
          </Text>
          {t(T.settings_dotVerification_betterOpportunities)}
        </Text>
      </div>
      <RightButton id={'verify'} onClick={onClickVerifyDotNumberButton}>
        {t(T.common_dotVerification_verifyDotNumber)}
      </RightButton>
    </>
  );
};

export const VerifyDotBanner = () => {
  return (
    <Banner>
      <VerifyDotContent />
    </Banner>
  );
};

import React, { CSSProperties } from 'react';

import styled from 'styled-components';

import { FontLineHeight, Spacing } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';

const NOT_FOUND_PANEL_TOP_PADDING = 42;
const TITLE_TOP_OFFSET = 22;
const TITLE_HORIZONTAL_PADDING = 20;

interface NotFoundProps {
  imageSection?: React.ReactElement;
  controlSection?: React.ReactElement;
  content?: React.ReactElement;
  title?: string;
  subtitle?: string;
  titleStyle?: CSSProperties;
  subtitleStyle?: CSSProperties;
}

export const CenteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px;
  padding-bottom: 24px;
`;

export const PageIcon = styled.img`
  width: 137px;
  height: 136px;
`;

export const NotFound = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: ${NOT_FOUND_PANEL_TOP_PADDING}px;
`;

const Title = withTheme()(styled.span`
  margin-top: ${TITLE_TOP_OFFSET}px;
  font-size: ${(props: ThemeProps) => props.theme.text.h2.fontSize};
  font-weight: ${(props: ThemeProps) => props.theme.text.h2.fontWeight};
  color: ${(props: ThemeProps) => props.theme.palette.text.primary};
  padding: 0 ${TITLE_HORIZONTAL_PADDING}px;
  text-align: center;
  cursor: default;
`);

export const Subtitle = withTheme()(styled.span`
  font-size: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontSize};
  color: ${(props: ThemeProps) => props.theme.palette.text.primary};
  padding: ${Spacing.ElementPaddingHorizontal}px ${Spacing.ScreenSide}px 0 ${Spacing.ScreenSide}px;
  line-height: ${FontLineHeight.MediumTitle}px;
  text-align: center;
  cursor: default;
`);

export const NotFoundPanel: React.FC<NotFoundProps> = ({
  titleStyle,
  imageSection,
  content,
  controlSection,
  title,
  subtitle,
  subtitleStyle,
}) => (
  <NotFound id="not_found_panel">
    {imageSection}
    <Title style={titleStyle}>{title}</Title>
    <Subtitle style={subtitleStyle}>{subtitle}</Subtitle>
    {content}
    {controlSection}
  </NotFound>
);

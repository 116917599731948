import { AxiosRequestConfig } from 'axios';
import { Observable } from 'rxjs';

import { Api, ApiResponse123, ApiService } from '@common/api';
import { configureApi } from '@webApi/ApiServiceHelper';

export class RawApiService implements Api {
  private api: Api;
  constructor() {
    this.api = new ApiService('/', {}, configureApi);
  }

  get$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    return this.api.get$(url, params, axiosConfig);
  };

  post$ = <T>(url: string, params: {} | null, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    return this.api.post$(url, params, axiosConfig);
  };

  put$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    return this.api.put$(url, params, axiosConfig);
  };

  delete$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    return this.api.delete$(url, params, axiosConfig);
  };

  patch$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    return this.api.patch$(url, params, axiosConfig);
  };

  mergePatch$ = <T>(url: string, params: {}, axiosConfig?: AxiosRequestConfig): Observable<ApiResponse123<T>> => {
    return this.api.mergePatch$(url, params, axiosConfig);
  };
}

import React from 'react';

import { divIcon, icon, LatLng, latLng, latLngBounds, LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { map } from 'lodash';
import { Marker, Polyline, TileLayer } from 'react-leaflet';
import styled from 'styled-components';

import { GestureHandlingMap, GestureHandlingProps } from '@component/map/GestureHandlingMap';
import THEME from '@style/Theme';
import { withResizeDetectorAndRef } from '@util/WithResizeDetectorAndRef';

import { ALKMapsLayer } from './ALKMapsLayer';
import MarkerIcon from './marker.svg';
import MarkerIconGreen from './markerGreen.svg';
import { ShadowRadius } from './ShadowRadius';
import { SimpleTooltip, SimpleTooltipProps } from './SimpleTooltip';

export const DEFAULT_CENTER = latLng(39.1, -94.6);
const MAX_ZOOM = 20;
const DEFAULT_ZOOM = 4;
const MAX_BOUNDS: LatLngExpression[] = [
  [85, -179],
  [-85, 179],
];
const CIRCLE_BOUNDS_PADDING = 0.6;
const DEFAULT_BOUNDS_PADDING = 0.1;

type RadiusCenter = LatLng | [number, number];
type Path = number[][];
export interface BaseMapProps extends GestureHandlingProps {
  id: string;
  innerRef?: React.RefObject<GestureHandlingMap>;
  minHeight?: number;
  flexGrow?: boolean;
  useOpenStreetMap?: boolean;
  zoom?: number;
  center?: LatLng;
  icon?: any;
  path?: Path;
  fitPath?: boolean;
  radiusCenter?: RadiusCenter;
  stops?: Path;
  radius?: number;
  pathTooltip?: SimpleTooltipProps;
  noAuthentication?: boolean;
  gestureHandlingEnabled?: boolean;
  onResize?: () => void;
}

interface StyledMapProps {
  height?: number;
  flexGrow?: boolean;
  minHeight?: number;
  disablePointerEvents?: boolean;
}

const StyledMap = styled(GestureHandlingMap)`
  ${(props: StyledMapProps) => (props.height === undefined ? '' : props.height + 'px')};
  min-height: ${(props: StyledMapProps) => props.minHeight}px;
  flex-grow: ${(props: StyledMapProps) => (props.flexGrow ? '1' : '0')};
  width: 100%;
  z-index: 0;
  > div {
    flex-grow: ${(props: StyledMapProps) => (props.flexGrow ? '1' : '0')};
  }
`;

class BaseMapComponent extends React.Component<BaseMapProps & { height?: number; width?: number }> {
  private baseMap: GestureHandlingMap;

  componentDidUpdate(prevProps: BaseMapProps & { height?: number; width?: number }) {
    if (this.props.height !== prevProps.height || this.props.width !== prevProps.width) {
      /* This needs to run after the layout is finished */
      requestAnimationFrame(() => {
        this.baseMap?.leafletElement.invalidateSize();
        const bounds = this.getBounds(this.props.fitPath, this.props.path);
        if (bounds) {
          this.baseMap?.leafletElement.fitBounds(bounds);
        }
        this.props.onResize?.();
      });
    }
  }

  renderZoom = (radius?: number, zoom?: number) => {
    if (radius) {
      return undefined;
    } else if (zoom) {
      return zoom;
    }
    return DEFAULT_ZOOM;
  };

  renderTileLayer = (id: string, noAuthentication?: boolean, useOpenStreetMap?: boolean) => {
    if (useOpenStreetMap) {
      return <TileLayer id={`${id}_tile_layer`} url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />;
    } else {
      return <ALKMapsLayer id={`${id}_tile_layer`} noAuthentication={noAuthentication} />;
    }
  };

  renderRadius = (id: string, radiusCenter: LatLng, radius?: number) => {
    if (radius) {
      return <ShadowRadius id={`${id}_render_radius`} center={radiusCenter} radius={radius} />;
    } else {
      return undefined;
    }
  };

  renderPath = (id: string, path?: Path, pathTooltip?: SimpleTooltipProps) => {
    if (path) {
      const coordinates: LatLng[] = new Array<LatLng>();
      for (const point of path) {
        if (point.length >= 2) {
          coordinates.push(latLng(point[1], point[0]));
        }
      }
      const pathID = `${id}_path`;
      if (pathTooltip) {
        return (
          <Polyline id={pathID} color={THEME.palette.map.line} positions={coordinates}>
            <SimpleTooltip {...pathTooltip} />
          </Polyline>
        );
      } else {
        return <Polyline id={pathID} color={THEME.palette.map.line} positions={coordinates} />;
      }
    } else {
      return undefined;
    }
  };

  renderStopsMarker = (stopPath?: Path) => {
    const coordinates: LatLng[] = new Array<LatLng>();
    if (stopPath) {
      for (const point of stopPath) {
        if (point.length >= 2) {
          coordinates.push(latLng(point[1], point[0]));
        }
      }

      return coordinates;
    }

    return undefined;
  };

  getBounds = (fitPath?: boolean, path?: Path) => {
    if (path && fitPath && this.verifyPath(path)) {
      const LATITUDE = 1;
      const LONGITUDE = 0;

      return latLngBounds(path.map((point) => latLng(point[LATITUDE], point[LONGITUDE]))).pad(DEFAULT_BOUNDS_PADDING);
    }

    return undefined;
  };

  getMaxBounds = () => latLngBounds(MAX_BOUNDS);

  verifyPath = (path: Path) => path.length >= 2 && path[0].length === 2 && path[path.length - 1].length === 2;

  getCenter = (center?: LatLng) => {
    if (center) {
      return latLng(center.lat, center.lng);
    } else {
      return DEFAULT_CENTER;
    }
  };

  getRadiusCenter = (defaulCenter: LatLng, radius?: number, radiusCenter?: RadiusCenter) => {
    if (radius) {
      if (radiusCenter) {
        return latLng(radiusCenter);
      } else {
        return defaulCenter;
      }
    }
    return latLng(0, 0);
  };

  iconCircle = (stopNumber: number, lastIndex: number) => {
    const style = 'my-div-icon-small';
    if (lastIndex === 0) {
      return icon({
        iconUrl: MarkerIcon,
        iconAnchor: [16, 32],
        iconSize: [32, 32],
      });
    } else if (lastIndex > 0) {
      return icon({
        iconUrl: MarkerIconGreen,
        iconAnchor: [16, 32],
        iconSize: [32, 32],
      });
    }
    return divIcon({
      className: style,
      html: `<div id=pin class="inside-circle-small"><span id=count>${stopNumber}</span></div>`,
      iconAnchor: [16, 16],
    });
  };

  getStopsMarker = (coordinates?: LatLng[]) => {
    if (coordinates) {
      return map(coordinates, (marker, i) => {
        if (i === 0 || i === coordinates.length - 1) {
          return <Marker icon={this.iconCircle(i, i)} position={marker} id="stop_mark" key={i} />;
        }
        return <Marker icon={this.iconCircle(i, -1)} position={marker} id="stop_mark" key={i} />;
      });
    } else {
      return <></>;
    }
  };

  render() {
    const gestureHandlingEnabled =
      this.props.gestureHandlingEnabled !== undefined ? this.props.gestureHandlingEnabled : true;
    const mapCenter = this.getCenter(this.props.center);
    const radiusCenter = this.getRadiusCenter(mapCenter, this.props.radius, this.props.radiusCenter);
    const stopCoordinates = this.renderStopsMarker(this.props.stops);
    const { innerRef, ...rest } = this.props;
    return (
      <StyledMap
        innerRef={(instance) => {
          this.baseMap = instance;
          if (innerRef) {
            (innerRef as React.MutableRefObject<GestureHandlingMap>).current = instance;
          }
        }}
        {...rest}
        onclick={(event) => this.props.onclick?.(event)}
        onmoveend={(event) => this.props.onmoveend?.(event)}
        gestureHandlingEnabled={gestureHandlingEnabled}
        height={this.props.height}
        flexGrow={this.props.flexGrow}
        minHeight={this.props.minHeight}
        center={mapCenter}
        zoom={this.renderZoom(this.props.radius, this.props.zoom)}
        bounds={
          this.props.radius
            ? radiusCenter.toBounds(this.props.radius).pad(CIRCLE_BOUNDS_PADDING)
            : this.getBounds(this.props.fitPath, this.props.path)
        }
        maxBounds={this.getMaxBounds()}
        maxZoom={MAX_ZOOM}
        scrollWheelZoom={true}
        touchZoom={true}
        zoomSnap={0}
      >
        {this.renderTileLayer(this.props.id, this.props.noAuthentication, this.props.useOpenStreetMap)}
        {this.renderRadius(this.props.id, radiusCenter, this.props.radius)}
        {this.renderPath(this.props.id, this.props.path, this.props.pathTooltip)}
        {this.getStopsMarker(stopCoordinates)}
        {this.props.children}
      </StyledMap>
    );
  }
}

export const BaseMap = withResizeDetectorAndRef(BaseMapComponent);

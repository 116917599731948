import { cloneDeep } from 'lodash';

import { SearchResponsePayload } from '@common/redux/Base';

export interface DocumentIdentifier {
  id: string;
  displayName: string;
  extension: string;
}

export interface Document {
  id: string;
  contactId: string;
  type: string;
  displayName: string;
  folderId?: string;
  folderType: FolderType;
  status: string;
  createdOn: string;
  modifiedOn: string;
  folderName: string;
  extension: string;
  fileSize?: number;
}

export interface DocumentsResponse extends SearchResponsePayload {
  documents: Document[];
}
export enum DocumentSendType {
  email = 'Email',
  fax = 'Fax',
  both = 'Both',
}
export interface DocumentType {
  enumName: string;
  description: string;
}
export interface DocumentTypes {
  types: DocumentType[];
}

export interface MyDocumentsSendRequest {
  documents: string[];
  sendBy: DocumentSendType;
  recipientName: string;
  recipientEmail?: string;
  recipientCompanyName: string;
  recipientFax?: string;
  sendToThunderFunding: boolean;
}

export interface DocumentFilters {
  date?: DateRange;
  sorting: DocumentsSortingOption;
  documentType: DocumentType;
  offset: number;
  limit: number;
  sortDirection: SortDirection;
  folder?: FolderIdentifier;
  fields?: string;
}

export enum DocumentsSortingOption {
  date = 'ModifiedDate',
  alphabetical = 'FileName',
  folder = 'FolderName',
  type = 'FileType',
}

export enum CustomFolderStatus {
  None = 'None',
  Active = 'Active',
  Deleted = 'Deleted',
}

export interface DefaultFolder {
  name: string; // max 32 char
  type: FolderType;
  lastFileModifiedOn: string;
  fileCount: number;
}

export interface CustomFolder {
  id: string;
  name: string; // max 32 char
  createdOn: string; // "2019-07-18T14:59:47.752Z"
  status: CustomFolderStatus;
  origCity?: string;
  origState?: string;
  destCity?: string;
  destState?: string;
  fileCount?: number;
  loadId?: string;
  modifiedOn?: string;
  lastAccessedOn?: string;
}

export enum CustomFoldersSort {
  Name = 'Name',
  LastAccessedOn = 'LastAccessedOn',
  HasLoad = 'HasLoad',
}

export enum SortDirection {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export interface CustomFoldersRequest {
  offset: number;
  limit: number;
  sortBy: CustomFoldersSort;
  sortDirection: SortDirection;
}

export interface DateRange {
  title: string;
  dateStart: string;
  dateEnd: string;
}

// since the folder type may be expanded in the future, made a const
// to hold the type 'CustomFolder' - not an enum with all current
// possible values for type (i.e. 'Unsorted', 'CarrierDocuments')
export const FOLDER_TYPE_CUSTOM = 'CustomFolder';
export const FOLDER_TYPE_UNSORTED = 'Unsorted';
export const FOLDER_UNSORTED_IDENTIFIER: FolderIdentifier = {
  type: FOLDER_TYPE_UNSORTED,
  name: FOLDER_TYPE_UNSORTED,
};

export type FolderType = 'CustomFolder' | 'Unsorted' | 'CarrierDocuments';

export interface FolderIdentifier {
  id?: string;
  type: FolderType;
  name: string;
}

export const CUSTOM_FOLDERS_REQUEST_OFFSET_INCREMENT = 30;
export const CUSTOM_FOLDERS_REQUEST_LIMIT_DEFAULT = 30;
export const CUSTOM_FOLDERS_REQUEST_LIMIT_APPENDING = 30;

export const DefaultCustomFoldersRequest: CustomFoldersRequest = {
  offset: 0,
  limit: CUSTOM_FOLDERS_REQUEST_LIMIT_DEFAULT,
  sortBy: CustomFoldersSort.LastAccessedOn,
  sortDirection: SortDirection.Descending,
};

export const createDefaultCustomFoldersRequest = (): CustomFoldersRequest => cloneDeep(DefaultCustomFoldersRequest);

export const resetCustomFoldersRequest = (request: CustomFoldersRequest): CustomFoldersRequest => ({
  offset: 0,
  limit: CUSTOM_FOLDERS_REQUEST_LIMIT_DEFAULT,
  sortBy: request.sortBy,
  sortDirection: request.sortDirection,
});

export const DOCUMENTS_LIST_REQUEST_OFFSET_INCREMENT = 10;
export const DOCUMENTS_LIST_REQUEST_LIMIT_DEFAULT = 20;
export const DOCUMENTS_LIST_REQUEST_LIMIT_APPENDING = 10;

export const DOCUMENT_TYPE_ALL = 'All';
export const DOCUMENT_TYPE_OTHER = 'Other';

export const createDefaultDocumentsRequest = (filters: DocumentFilters): DocumentFilters => ({
  ...filters,
  offset: 0,
  limit: DOCUMENTS_LIST_REQUEST_LIMIT_DEFAULT,
});

export interface CustomFoldersResponse extends SearchResponsePayload {
  customFolders: CustomFolder[];
}

export interface DefaultFoldersResponse {
  folders: DefaultFolder[];
}

export type NewCustomFolderResponse = CustomFolder;
export interface NewCustomFolderRequest {
  name: string;
  loadId?: string;
}

export interface DocumentType {
  enumName: string;
  description: string;
}

export interface FolderResponse {
  fileCount?: number;
  origState?: string;
  destState?: string;
  lastAccessedOn?: string;
  id?: string;
  contactId?: string;
  loadId?: string;
  name?: string;
  status?: CustomFolderStatus;
  createdOn?: string;
  modifiedOn?: string;
}

export enum DownloadState {
  success = 'success',
  failure = 'failure',
}

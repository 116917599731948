import { Patch } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';
import { METADATA_ONLY } from '@common/helper';
import { FundingTypeOption, PaymentScheduleOption } from '@common/model';
import {
  FuelAdvanceQuote,
  FuelAdvanceQuoteRequest,
  GetPaidFasterQuote,
  GetPaidFasterQuoteRequest,
  ThunderFundingFuelAdvanceQuotes,
  ThunderFundingGetPaidFasterQuotes,
  ThunderFundingRegistrationOptionsResponse,
  ThunderFundingRegistrationSource,
  ThunderFundingRegistrationStatusResponse,
  ThunderFundingRegistrationSubmissionResult,
  ThunderFundingRegistrationSubmissionResults,
} from '@common/model/ThunderFundingRequest';
import { SearchResponsePayload } from '@common/redux/Base';
import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';

export class ThunderFundingClient extends BaseClient {
  fetchThunderFundingRegistrationStatus$ = () =>
    this.api.post$<ThunderFundingRegistrationStatusResponse>('/thunderfunding/status', {});

  fetchThunderFundingPreviousRegistration$ = () =>
    this.api.get$<ThunderFundingRegistrationSubmissionResults>('/thunderfunding/leads', {
      fields: 'quotes',
    });

  fetchThunderFundingRegistrationOptions$ = () =>
    this.api.get$<ThunderFundingRegistrationOptionsResponse>('/thunderFunding/leads/truckingCompanyStatus', {});

  postThunderFundingRegistration$ = (option: string, source: ThunderFundingRegistrationSource) =>
    this.api.post$<ThunderFundingRegistrationSubmissionResult>('/thunderfunding/leads', {
      truckingCompanyStatus: option,
      acquisitionChannel: source,
    });

  fetchFuelAdvanceQuotes$ = (request: PaginatedRequest) =>
    this.api.get$<ThunderFundingFuelAdvanceQuotes>('/fueladvance/thunderfunding/quotes', request);

  fetchGetPaidFasterQuotes$ = (request: PaginatedRequest) =>
    this.api.get$<ThunderFundingGetPaidFasterQuotes>('/factoring/thunderfunding/quotes', request);

  countFuelAdvanceQuotes$ = () =>
    this.api.get$<SearchResponsePayload>('/fueladvance/thunderfunding/quotes', METADATA_ONLY);

  countGetPaidFasterQuotes$ = () =>
    this.api.get$<SearchResponsePayload>('/factoring/thunderfunding/quotes', METADATA_ONLY);

  postFuelAdvanceIntegrationQuote$ = (quote: FuelAdvanceQuoteRequest) =>
    this.api.post$<FuelAdvanceQuote>('/fueladvance/thunderfunding/quotes', quote);

  postGetPaidFasterIntegrationQuote$ = (quote: GetPaidFasterQuoteRequest) =>
    this.api.post$<GetPaidFasterQuote>('/factoring/thunderfunding/quotes', quote);

  patchFuelAdvanceIntegrationQuote$ = (patch: Patch[], id: string) =>
    this.api.patch$<FuelAdvanceQuote>(`/fueladvance/thunderfunding/quotes/${id}`, patch);

  patchGetPaidFasterIntegrationQuote$ = (patch: Patch[], id: string) =>
    this.api.patch$<GetPaidFasterQuote>(`/factoring/thunderfunding/quotes/${id}`, patch);

  fetchPaymentTypes$ = () => this.api.get$<PaymentScheduleOption[]>('/factoring/thunderfunding/paymentSchedules', {});

  getFundingTypes$ = () => this.api.get$<FundingTypeOption[]>('/fuelAdvance/thunderfunding/fundingTypes', {});

  fetchGetPaidFasterQuoteById$ = (id: string) =>
    this.api.get$<GetPaidFasterQuote>(`loads/${id}/factoring/thunderFunding/quote`, {});

  fetchFuelAdvanceQuoteById$ = (id: string) =>
    this.api.get$<FuelAdvanceQuote>(`loads/${id}/fuelAdvance/thunderFunding/quote`, {});
}

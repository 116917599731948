import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { default as SendIconOn } from '@/icons/btn-send-on.svg';
import { default as SendIcon } from '@/icons/btn-send.svg';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { voidFunction } from '@common/helper';
import { CommunicationEvent, CommunicationTrackingData, TrackingTag } from '@common/model/Tracking';
import { sendMessage } from '@common/redux/epic/CommunicationEpic';
import { TextField } from '@component/input';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { useIsKeyboardOpen } from '@component/scrollView/KeyboardOpen';
import { t, T } from '@translate';
import { useIsMobileView, useSelector } from '@util/hooks';
import { track } from '@util/trackers/123lbTracker';
import { deviceID } from '@webApi/CookiesHelper';

export const ChatTextField: React.FC<{ isOtherUserOnline: boolean; conversationID: string }> = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const userID = useSelector((state) => state.user.profile?.payload?.id);
  const [text, setText] = useState('');
  const { isKeyboardOpen } = useIsKeyboardOpen();
  const isMobile = useIsMobileView();
  const dispatch = useDispatch();
  const isSendMessageSuccess = useSelector(
    (state) => state.communication.conversationMessages.get(props.conversationID)?.isSendMessageSuccess
  );

  const isSendMessageLoading = useSelector(
    (state) => state.communication.conversationMessages.get(props.conversationID)?.isSendMessageLoading || false
  );

  const inputRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!isSendMessageLoading) {
        inputRef.current?.focus();
      }
    }, 500);
  }, [isSendMessageLoading]);

  useEffect(() => {
    if (!isSendMessageLoading) {
      if (isSendMessageSuccess) {
        setText('');
      } else if (isSendMessageSuccess === false) {
        dispatch(
          showSnackbar({
            message: t(T.error_try_again_error),
          })
        );
      }
    }
    setIsDisabled(isSendMessageLoading);
  }, [isSendMessageLoading, isSendMessageSuccess]);

  const sendUserMessage = () => {
    //when the user was sending a msg spamming enter could resend the request
    if (text.trim() === '') {
      return;
    }
    setIsDisabled(true);
    if (props.isOtherUserOnline === false) {
      //We compare directly to false to avoid sending it in case the info is not loaded or bug in the returned data.
      const data: CommunicationTrackingData = {
        eventName: CommunicationEvent.MessageOffline,
      };
      track({ tag: TrackingTag.Communication, data: data });
    }
    dispatch(
      sendMessage(
        props.conversationID,
        userID || '',
        {
          text: text,
          clientMessageId: '',
        },
        deviceID()
      )
    );
  };

  const isEnterKeyPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    return !event.shiftKey && (event.key === 'Enter' || event.keyCode === 13);
  };
  const handleKeyPress = (target: any) => {
    if (isDisabled) {
      return;
    }
    if (isMobile && isKeyboardOpen) {
      target.blur();
    }

    sendUserMessage();
  };
  return (
    <TextField
      isConversation={true}
      placeholder={t(T.common_conversations_typeHereBox)}
      id="message_input"
      value={text}
      isWithoutMargin={true}
      isVariableHeight={true}
      multiline={true}
      rowsMax={4}
      fullWidth={true}
      onChange={(event) => {
        if (!isDisabled) {
          setText(event.target.value);
        }
      }}
      onKeyDown={(event) => {
        if (isEnterKeyPressed(event)) {
          handleKeyPress(event.target);
        }
      }}
      autoComplete={'off'}
      data-name={'weight'}
      inputProps={{
        style: {
          minHeight: 20,
          marginTop: -14,
          marginBottom: -6,
        },
      }}
      endAdornmentIcon={
        isSendMessageLoading ? (
          <LoadingSpinner />
        ) : (
          <img
            src={text.trim() ? SendIconOn : SendIcon}
            alt="Logo"
            style={{ paddingBottom: 12, cursor: text.trim() ? 'pointer' : 'auto' }}
          />
        )
      }
      onEndAdormentPress={!isSendMessageLoading ? sendUserMessage : voidFunction}
      inputRef={inputRef}
    />
  );
};

import React from 'react';

import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { GENERAL_CONTACT_PHONE_NUMBER } from '@common/info';
import { Color } from '@style/Color';
import { T, t } from '@translate';

interface PopupButtonProps {
  testID: string;
  label?: string;
  action?: () => void;
  disabled?: boolean;
  key?: string;
  style?: React.CSSProperties;
}

// augmenting specificity with && to allow styling material-ui component
const PopupButton = styled(Button)`
  && {
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    min-width: 105px;
    margin-right: 10px;
  }
`;

export const PopupDangerButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, label, key, style }) => (
  <PopupButton id={testID} style={{ ...style, color: Color.RED_APPLE }} onClick={action} disabled={disabled} key={key}>
    {label}
  </PopupButton>
);

export const PopupPrimaryButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, label, key, style }) => (
  <PopupButton id={testID} onClick={action} disabled={disabled} color="secondary" key={key} style={style}>
    {label}
  </PopupButton>
);

export const PopupYesButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, key }) => (
  <PopupButton id={testID} onClick={action} disabled={disabled} color="secondary" key={key}>
    {t(T.common_yes)}
  </PopupButton>
);

export const PopupNoButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, key }) => (
  <PopupButton id={testID} onClick={action} disabled={disabled} color="secondary" key={key}>
    {t(T.common_no)}
  </PopupButton>
);

export const PopupOkButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, key }) => (
  <PopupButton id={testID} onClick={action} disabled={disabled} color="secondary" key={key}>
    {t(T.common_ok)}
  </PopupButton>
);

export const PopupRemoveButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, key }) => (
  <PopupButton id={testID} onClick={action} disabled={disabled} color="secondary" key={key}>
    {t(T.common_remove)}
  </PopupButton>
);

export const PopupCancelButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, key }) => (
  <PopupButton id={testID} onClick={action} disabled={disabled} color="secondary" key={key}>
    {t(T.confirmationDialog_Cancel)}
  </PopupButton>
);

export const PopupAddButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, key }) => (
  <PopupButton id={testID} onClick={action} disabled={disabled} color="secondary" key={key}>
    {t(T.common_add)}
  </PopupButton>
);

export const PopupCallButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, label, key }) => (
  <PopupButton
    id={testID}
    onClick={action}
    disabled={disabled}
    color="secondary"
    key={key}
    href={`tel:${GENERAL_CONTACT_PHONE_NUMBER}`}
  >
    {label}
  </PopupButton>
);

export const PopupLabelButton: React.FC<PopupButtonProps> = ({ testID, action, disabled, label, key }) => (
  <PopupButton id={testID} onClick={action} disabled={disabled} color="secondary" key={key}>
    {label}
  </PopupButton>
);

/** Compact LRU Cache (375 bytes minified)
 * Required browsers with ES2015
 */
export class LRUMap<K, V> {
  /** max size */
  private s: number;

  /** Uses the Map's ability to iterate in insertion order
   *  making first item from an iteration the next one to delete essentially.
   */
  private c: Map<K, V>;

  constructor(max: number) {
    this.s = max;
    this.c = new Map<K, V>();
  }

  get(key: K): V | undefined {
    const cache = this.c;
    const item: V | undefined = cache.get(key);
    if (item) {
      // refresh key
      cache.delete(key);
      cache.set(key, item);
    }
    return item;
  }
  set(key: K, val: V): void {
    const cache = this.c;
    if (cache.set(key, val).size > this.s) {
      // remove oldest
      cache.delete(cache.keys().next().value);
    }
  }
}

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { FeedClient } from '@/client';
import { Api } from '@common/api';
import { createAction, createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export enum FeedSection {
  Feed,
  Videos,
}

const fetchFeedAction = createApiAction<boolean, string>('FETCH_FEED');
const fetchDashboardFeedAction = createApiAction<boolean, string>('FETCH_DASHBOARD_FEED');
const fetchVideoListAction = createApiAction<string, string>('FETCH_VIDEO_LIST');
const fetchMappingFileAction = createApiAction<undefined, string>('FETCH_MAPPING_FILE');
const fetchPromoAction = createApiAction<string, string>('FETCH_PROMO');
const appendFeedLinksAction = createAction('APPEND_FEED_LINKS');
const appendDashboardFeedLinksAction = createAction('APPEND_DASHBOARD_FEED_LINKS');
const appendVideoLinksAction = createAction('APPEND_VIDEO_LINKS');
const appendPromoLinksAction = createAction('APPEND_PROMO_LINKS');
const clearFeedAction = createAction('CLEAR_FEED');
const clearDashboardFeedAction = createAction('CLEAR_DASHBOARD_FEED');
const clearVideosAction = createAction('CLEAR_VIDEOS');
const clearPromoAction = createAction('CLEAR_PROMO');
const setSelectedFeedSectionAction = createAction<FeedSection | undefined>('SET_SELECTED_FEED_SECTION');

export const fetchFeed = (isBroker: boolean) => fetchFeedAction.fetchAction(isBroker);
export const fetchDashboardFeed = (isBroker: boolean) => fetchDashboardFeedAction.fetchAction(isBroker);
export const fetchVideoList = (videoName: string) => fetchVideoListAction.fetchAction(videoName);
export const fetchPromo = (promoFileName: string) => fetchPromoAction.fetchAction(promoFileName);
export const fetchMappingFile = () => fetchMappingFileAction.fetchAction(undefined);
export const clearFeed = () => clearFeedAction.action(undefined);
export const clearDashboardFeed = () => clearDashboardFeedAction.action(undefined);
export const clearVideos = () => clearVideosAction.action(undefined);
export const clearPromo = () => clearPromoAction.action(undefined);
export const appendFeedLinks = () => appendFeedLinksAction.action(undefined);
export const appendDashboardFeedLinks = () => appendDashboardFeedLinksAction.action(undefined);
export const appendVideoLinks = () => appendVideoLinksAction.action(undefined);
export const appendPromoLinks = () => appendPromoLinksAction.action(undefined);
export const setSelectedFeedSection = (feedSection: FeedSection | undefined) =>
  setSelectedFeedSectionAction.action(feedSection);

export interface FeedState {
  feed: Document | undefined;
  dashboardFeed: Document | undefined;
  video: Document | undefined;
  promo: Document | undefined;
  shouldAppendFeedLinks: boolean;
  shouldAppendDashboardFeedLinks: boolean;
  shouldAppendVideoLinks: boolean;
  shouldAppendPromoLinks: boolean;
  selectedFeedSection: FeedSection | undefined;
  mappingFile: string | undefined;
  isMappingFileFetched: boolean;
}

const initialState: FeedState = {
  feed: undefined,
  dashboardFeed: undefined,
  video: undefined,
  promo: undefined,
  shouldAppendFeedLinks: true,
  shouldAppendDashboardFeedLinks: true,
  shouldAppendVideoLinks: true,
  shouldAppendPromoLinks: true,
  selectedFeedSection: undefined,
  mappingFile: undefined,
  isMappingFileFetched: false,
};

export const feedReducer = createMergedReducer(initialState, [
  clearFeedAction.addCase((state) => {
    state.feed = undefined;
  }),
  clearDashboardFeedAction.addCase((state) => {
    state.dashboardFeed = undefined;
  }),
  clearVideosAction.addCase((state) => {
    state.video = undefined;
  }),
  clearPromoAction.addCase((state) => {
    state.promo = undefined;
  }),
  appendFeedLinksAction.addCase((state) => {
    state.shouldAppendFeedLinks = false;
  }),
  appendDashboardFeedLinksAction.addCase((state) => {
    state.shouldAppendDashboardFeedLinks = false;
  }),
  appendVideoLinksAction.addCase((state) => {
    state.shouldAppendVideoLinks = false;
  }),
  appendPromoLinksAction.addCase((state) => {
    state.shouldAppendPromoLinks = false;
  }),
  setSelectedFeedSectionAction.addCase((state, action) => {
    state.selectedFeedSection = action.data;
  }),
  fetchFeedAction.completeCase((state, action) => {
    if (action.response.success) {
      const parsed: Document = new window.DOMParser().parseFromString(action.response.payload, 'text/html');
      const parsingError = parsed.getElementsByTagName('parsererror')[0];
      if (parsingError !== undefined) {
        //left console to warn that we have parsing error here
        console.log('Parsing error occurred', parsingError);
        state.feed = undefined;
      } else {
        //casting to workaround a limitation of Immer's Draft<K> dealing with recursive types as in "Document"
        (state as FeedState).feed = parsed;
      }
    } else {
      state.feed = undefined;
    }
  }),
  fetchDashboardFeedAction.completeCase((state, action) => {
    if (action.response.success) {
      const parsed: Document = new window.DOMParser().parseFromString(action.response.payload, 'text/html');
      const parsingError = parsed.getElementsByTagName('parsererror')[0];
      if (parsingError !== undefined) {
        //left console to warn that we have parsing error here
        console.log('Parsing error occurred', parsingError);
        state.dashboardFeed = undefined;
      } else {
        //casting to workaround a limitation of Immer's Draft<K> dealing with recursive types as in "Document"
        (state as FeedState).dashboardFeed = parsed;
      }
    } else {
      state.dashboardFeed = undefined;
    }
  }),
  fetchVideoListAction.completeCase((state, action) => {
    if (action.response.success) {
      const parsed = new window.DOMParser().parseFromString(action.response.payload, 'text/html');
      const parsingError = parsed.getElementsByTagName('parsererror')[0];
      if (parsingError !== undefined) {
        //left console to warn that we have parsing error here
        console.log('Parsing error occurred', parsingError);
        state.video = undefined;
      } else {
        //casting to workaround a limitation of Immer's Draft<K> dealing with recursive types as in "Document"
        (state as FeedState).video = parsed;
      }
    } else {
      state.video = undefined;
    }
  }),
  fetchPromoAction.completeCase((state, action) => {
    if (action.response.success) {
      const parsed = new window.DOMParser().parseFromString(action.response.payload, 'text/html');
      const parsingError = parsed.getElementsByTagName('parsererror')[0];
      if (parsingError !== undefined) {
        //left console to warn that we have parsing error here
        console.log('Parsing error occurred', parsingError);
        state.promo = undefined;
      } else {
        //casting to workaround a limitation of Immer's Draft<K> dealing with recursive types as in "Document"
        (state as FeedState).promo = parsed;
      }
      state.shouldAppendPromoLinks = true;
    } else {
      state.promo = undefined;
    }
  }),
  fetchMappingFileAction.initiateCase((state) => {
    state.isMappingFileFetched = false;
  }),
  fetchMappingFileAction.completeCase((state, action) => {
    if (action.response.success) {
      state.mappingFile = action.response.payload;
    }
    state.isMappingFileFetched = true;
  }),
]);

export const createFeedEpic = (api: Api) => {
  const feedClient = new FeedClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchFeedAction.createEpic$(action$, (isBroker) => feedClient.getFeed$(isBroker)),
      fetchDashboardFeedAction.createEpic$(action$, (isBroker) => feedClient.getDashboardFeed$(isBroker)),
      fetchVideoListAction.createEpic$(action$, (videoFileName) => feedClient.getVideoList$(videoFileName)),
      fetchPromoAction.createEpic$(action$, (promoFileName) => feedClient.getPromo$(promoFileName)),
      fetchMappingFileAction.createEpic$(action$, () => feedClient.getMappingFile$())
    );
};

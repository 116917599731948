import moment from 'moment';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api, ApiError, ApiErrorCode } from '@common/api';
import { UserClient, VerifyPhoneClient } from '@common/client';
import {
  CreatePhoneVerificationResponse,
  NumberCategory,
  PhoneVerificationMethod,
  VerificationPointsTaskStatus,
} from '@common/model';
import { createApiAction } from '@common/redux/Base';
import { VerifyPhoneCode } from '@common/redux/epic/VerificationPointsEpic';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export enum CreateVerificationError {
  OTHER = 1,
  TOO_MANY_ATTEMPTS_DIFFERENT_PHONE = 2,
}

export interface VerifyPhoneResponse {
  primaryPhone: VerificationPointsTaskStatus;
}

interface CreatePhoneVerificationRequest {
  guid: string;
  verificationMethod: PhoneVerificationMethod;
  category: NumberCategory;
}

interface CreateCodeVerificationRequest {
  guid: string;
  code: string;
  category: NumberCategory;
}

const getVerifiedPhoneApiAction = createApiAction<{}, VerifyPhoneResponse>('GET_VERIFIED_PHONE');
const createPhoneVerificationApiAction = createApiAction<
  CreatePhoneVerificationRequest,
  CreatePhoneVerificationResponse
>('CREATE_PHONE_VERIFICATION');
export const createCodeVerificationApiAction = createApiAction<CreateCodeVerificationRequest, {}>(
  'CREATE_CODE_VERIFICATION'
);

export const getVerifiedPhoneStatus = () => getVerifiedPhoneApiAction.fetchAction({});
export const createPhoneVerification = (
  guid: string,
  verificationMethod: PhoneVerificationMethod,
  category: NumberCategory
) =>
  createPhoneVerificationApiAction.fetchAction({
    guid: guid,
    category: category,
    verificationMethod: verificationMethod,
  });
export const createCodeVerification = (guid: string, code: string, category: NumberCategory) =>
  createCodeVerificationApiAction.fetchAction({ guid: guid, code: code, category: category });

const CLEAR_VERIFY_DATA = 'CLEAR_VERIFY_DATA';
export const clearVerifyData = (): Action => ({
  type: CLEAR_VERIFY_DATA,
});

export interface VerificationRequest {
  isLoading: boolean;
  isRequestSuccessful?: boolean;
  updateTime?: number;
  error?: ApiError;
}

export interface VerifyPhoneState {
  isLoading: boolean;
  verifyPhoneCode: VerifyPhoneCode;
  phoneVerificationType?: PhoneVerificationMethod;
  phoneVerificationExpire?: string;
  phoneVerifiedStatus?: VerificationPointsTaskStatus;
  primaryPhoneVerificationStatus?: VerificationPointsTaskStatus;
  createPhoneVerification: VerificationRequest;
  createCodeVerification: VerificationRequest;
  currentGuid?: string;
  requestGuid?: string;
}

const initialState: VerifyPhoneState = {
  isLoading: false,
  verifyPhoneCode: {
    isLoading: false,
  },
  createPhoneVerification: {
    isLoading: false,
  },
  createCodeVerification: {
    isLoading: false,
  },
};

export const verifyPhoneReducer = createMergedReducer<VerifyPhoneState>(initialState, [
  getVerifiedPhoneApiAction.initiateCase((state) => {
    state.isLoading = true;
  }),

  getVerifiedPhoneApiAction.completeCase((state, action) => {
    state.isLoading = false;
    if (action.response.success) {
      state.primaryPhoneVerificationStatus = action.response.payload.primaryPhone;
    }
  }),

  createPhoneVerificationApiAction.initiateCase((state, action) => {
    if (action.data.verificationMethod) {
      state.phoneVerificationType = action.data.verificationMethod;
    }
    state.createPhoneVerification = {
      isLoading: true,
    };
    state.requestGuid = action.data.guid;
  }),

  createPhoneVerificationApiAction.completeCase((state, action) => {
    state.createPhoneVerification = {
      isLoading: true,
    };
    if (action.response.success) {
      state.createPhoneVerification = {
        isLoading: false,
        isRequestSuccessful: action.response.success,
        updateTime: Date.now(),
      };
      state.phoneVerificationExpire = moment().add(action.response.payload.expiresIn, 'seconds').toString();
      state.currentGuid = action.response.payload.phoneGUID;
    } else {
      let error;
      if (
        action.response.error?.code === ApiErrorCode.PHONEVERIFICATION_TOO_MANY_ATTEMPTS &&
        state.currentGuid !== state.requestGuid
      ) {
        error = { ...action.response.error, code: CreateVerificationError.TOO_MANY_ATTEMPTS_DIFFERENT_PHONE };
      } else {
        error = { ...action.response.error, code: action.response.error?.code || CreateVerificationError.OTHER };
      }

      state.createPhoneVerification = {
        isLoading: false,
        isRequestSuccessful: action.response.success,
        updateTime: Date.now(),
        error: error,
      };
    }
  }),
  createCodeVerificationApiAction.initiateCase((state) => {
    state.createCodeVerification.isLoading = true;
  }),
  createCodeVerificationApiAction.completeCase((state, action) => {
    state.createCodeVerification = {
      isLoading: false,
      isRequestSuccessful: action.response.success,
      updateTime: Date.now(),
    };
    if (action.response.success) {
      state.createCodeVerification.isRequestSuccessful = action.response.success;
      state.phoneVerifiedStatus = VerificationPointsTaskStatus.COMPLETED;
      state.phoneVerificationExpire = undefined;
    } else {
      state.createCodeVerification.error = action.response.error;
    }
  }),
  {
    [CLEAR_VERIFY_DATA]: (state) => {
      state.createCodeVerification = {
        isLoading: false,
        isRequestSuccessful: undefined,
        updateTime: undefined,
        error: undefined,
      };
      state.createPhoneVerification = {
        isLoading: false,
        isRequestSuccessful: undefined,
        updateTime: undefined,
        error: undefined,
      };
    },
  },
]);

export const createVerifyPhoneEpic = (api: Api) => {
  const userClient = new UserClient(api);
  const phoneClient = new VerifyPhoneClient(api);

  return (action$: ActionsObservable<Action>) =>
    merge$(
      getVerifiedPhoneApiAction.createEpic$(action$, () => phoneClient.getPhoneVerificationStatus$()),
      createPhoneVerificationApiAction.createEpic$(action$, (data) =>
        userClient.createPhoneVerification$(data.guid, data.verificationMethod, data.category)
      ),
      createCodeVerificationApiAction.createEpic$(action$, (data) =>
        userClient.verifyPhoneCode$(data.code, data.guid, data.category)
      )
    );
};

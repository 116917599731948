import React from 'react';

import { t } from 'i18next';
import { Dispatch } from 'redux';

import { Link } from '@material-ui/core';

import {
  enableNotificationToken,
  postNotificationToken,
  updateNotificationToken,
} from '@/reduxStore/epic/UserSettingsEpic';
import { registerServiceWorker } from '@/registerServiceWorker';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupOkButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Action } from '@reduxjs/toolkit';
import { T } from '@translate';
import {
  askForPermissionToReceiveNotifications,
  NotificationPermissionStatus,
  registerToken,
} from '@util/firebaseNotification/FirebasePushNotification';
import { LINK_BROWSER_NOTIFICATIONS } from '@util/Link';
import { deviceID } from '@webApi/CookiesHelper';

export const sendNewTokenToServer = (dispatch: Dispatch<Action>, deviceIdForToken: string) => {
  registerToken().then((newToken) => {
    if (newToken !== undefined && newToken !== null) {
      dispatch(
        postNotificationToken({
          deviceId: deviceIdForToken,
          token: newToken,
        })
      );
    }
  });
};

export const updateTokenToServer = (
  dispatch: Dispatch<Action>,
  deviceIdForToken: string,
  didRegisterNewTokenSucceed: boolean
) => {
  registerToken().then((newToken) => {
    if (newToken !== undefined && newToken !== null && didRegisterNewTokenSucceed === false) {
      dispatch(
        updateNotificationToken({
          deviceId: deviceIdForToken,
          token: newToken,
        })
      );
    }
  });
};

export const enableTokenToServer = (dispatch: Dispatch<Action>, deviceIdForToken: string) => {
  registerToken().then((newToken) => {
    if (newToken !== undefined && newToken !== null) {
      dispatch(
        enableNotificationToken({
          deviceId: deviceIdForToken,
          token: newToken,
        })
      );
    }
  });
};

export const useRequestNotificationPermission = (
  permissionGranted: boolean,
  isMobileView: boolean,
  dispatch: Dispatch<Action>,
  setPermissionGranted: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { openingPopup, closingPopup } = usePopup();

  return () => {
    registerServiceWorker();
    if (permissionGranted) {
      sendNewTokenToServer(dispatch, deviceID());
    } else if (!isMobileView) {
      Notification.requestPermission().then((permission) => {
        if (permission === NotificationPermissionStatus.GRANTED) {
          setPermissionGranted(true);
          askForPermissionToReceiveNotifications();
          sendNewTokenToServer(dispatch, deviceID());
        } else if (permission === NotificationPermissionStatus.DENIED) {
          const dialogTitle = t(T.common_conversations_notificationBanner_deniedPopupTitle);
          const dialogContent = getDialogContent();
          openingPopup({
            body: (
              <BasePopup
                title={dialogTitle}
                buttons={[<PopupOkButton testID={'ok'} key={'ok'} action={closingPopup} />]}
              >
                {dialogContent}
              </BasePopup>
            ),
            width: PopupSizes.SMALL,
            isDismissible: true,
            hasCloseButton: true,
          });
        } else {
          Notification.requestPermission();
          setPermissionGranted(false);
        }
      });
    }
  };
};

const getDialogContent = () => {
  return (
    <>
      {t(T.common_conversations_notificationBanner_deniedPopupContent_part1)}
      <Link href={LINK_BROWSER_NOTIFICATIONS} target={'_blank'} color={'secondary'}>
        {t(T.common_conversations_notificationBanner_deniedPopupContent_part2)}
      </Link>
      {t(T.common_conversations_notificationBanner_deniedPopupContent_part3)}
    </>
  );
};

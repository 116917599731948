import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { AvatarClient, UploadAvatarData } from '@common/client/AvatarClient';
import { createApiAction, createApiActionWithFetchData } from '@common/redux/Base';

import { createMergedReducer } from '../ReduxHelper';

export interface Avatar {
  mimeType: string;
  file: Blob;
}

const fetchAvatarAction = createApiAction<undefined, {}>('FETCH_AVATAR');

const fetchSmallAvatarAction = createApiAction<undefined, {}>('FETCH_SMALL_AVATAR');

export const uploadAvatarAction = createApiAction<UploadAvatarData, Avatar>('UPLOAD_AVATAR');

const fetchSmallCompaniesProfileAvatarAction = createApiActionWithFetchData<string, {}>(
  'FETCH_SMALL_COMPANIES_PROFILE_AVATAR'
);

export const deleteAvatarAction = createApiAction<undefined, undefined>('DELETE_AVATAR');

export const uploadAvatar = (documentMetadata: UploadAvatarData) => uploadAvatarAction.fetchAction(documentMetadata);

export const fetchAvatar = () => fetchAvatarAction.fetchAction(undefined);

export const fetchSmallAvatar = () => fetchSmallAvatarAction.fetchAction(undefined);

export const fetchCompaniesProfileAvatar = (id: string) => fetchSmallCompaniesProfileAvatarAction.fetchAction(id);

export const deleteAvatar = () => deleteAvatarAction.fetchAction(undefined);

export interface CompaniesProfileAvatarInfo {
  isLoading: boolean;
  companiesProfileAvatar: string | null;
}

export interface AvatarState {
  isFetching: boolean;
  isFetchingForSmall: boolean;
  isFetchingForFull: boolean;
  /** Null is for if user has no avatar, Undefined means the avatar is not loaded yet. */
  currentAvatarSmall: string | undefined | null;
  currentAvatarFull: string | undefined | null;
  isUploadingAvatar: boolean;
  isUploadError: boolean | undefined;
  companiesProfileAvatarMap: Map<string, CompaniesProfileAvatarInfo>;
  isDeleteAvatarLoading: boolean;
  wasDeleteAvatarCompleted: boolean;
}

const initialState: AvatarState = {
  isFetching: false,
  isFetchingForSmall: false,
  isFetchingForFull: false,
  currentAvatarSmall: undefined,
  currentAvatarFull: undefined,
  isUploadingAvatar: false,
  isUploadError: false,
  companiesProfileAvatarMap: new Map(),
  isDeleteAvatarLoading: false,
  wasDeleteAvatarCompleted: false,
};

export const avatarReducer = createMergedReducer(initialState, [
  fetchSmallCompaniesProfileAvatarAction.initiateCase((state, action) => {
    const companiesProfileAvatarInfo =
      state.companiesProfileAvatarMap.get(action.data) ?? ({} as CompaniesProfileAvatarInfo);
    companiesProfileAvatarInfo.isLoading = true;
    state.companiesProfileAvatarMap.set(action.data, companiesProfileAvatarInfo);
  }),
  fetchSmallCompaniesProfileAvatarAction.completeCase((state, action) => {
    const companiesProfileAvatarInfo =
      state.companiesProfileAvatarMap.get(action.fetchData ?? '') ?? ({} as CompaniesProfileAvatarInfo);
    companiesProfileAvatarInfo.isLoading = false;
    if (action.response.success) {
      const mimeType = action.response.headers?.['content-type'];
      companiesProfileAvatarInfo.companiesProfileAvatar = mimeType
        ? `data:${mimeType};base64,${action.response.payload}`
        : null;
    }
  }),
  fetchAvatarAction.initiateCase((state) => {
    state.isFetchingForFull = true;
  }),

  fetchAvatarAction.completeCase((state, action) => {
    if (action.response.success) {
      const mimeType = action.response.headers?.['content-type'];
      state.currentAvatarFull = mimeType ? `data:${mimeType};base64,${action.response.payload}` : null;
    } else {
      state.currentAvatarFull = null;
    }
    state.isFetchingForFull = false;
  }),

  fetchSmallAvatarAction.initiateCase((state) => {
    state.isFetchingForSmall = true;
  }),

  fetchSmallAvatarAction.completeCase((state, action) => {
    if (action.response.success) {
      const mimeType = action.response.headers?.['content-type'];
      state.currentAvatarSmall = mimeType ? `data:${mimeType};base64,${action.response.payload}` : null;
    } else {
      state.currentAvatarSmall = null;
    }
    state.isFetchingForSmall = false;
  }),

  uploadAvatarAction.initiateCase((state) => {
    state.isUploadingAvatar = true;
    state.isUploadError = undefined;
  }),

  uploadAvatarAction.completeCase((state, action) => {
    if (action.response.success) {
      state.isUploadError = false;
      state.isUploadingAvatar = false;
      state.currentAvatarFull = undefined;
      state.currentAvatarSmall = undefined;
    } else {
      state.isUploadError = true;
      state.isUploadingAvatar = false;
    }
  }),
  deleteAvatarAction.initiateCase((state) => {
    state.isDeleteAvatarLoading = true;
    state.wasDeleteAvatarCompleted = false;
  }),
  deleteAvatarAction.completeCase((state, action) => {
    state.isDeleteAvatarLoading = false;
    if (action.response.success) {
      state.wasDeleteAvatarCompleted = true;
    } else {
      state.wasDeleteAvatarCompleted = false;
    }
  }),
]);

export const createAvatarEpic = (api: Api) => {
  const client = new AvatarClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchAvatarAction.createEpic$(action$, () => client.fetchAvatar$('Full')),
      fetchSmallAvatarAction.createEpic$(action$, () => client.fetchAvatar$('Small')),
      fetchSmallCompaniesProfileAvatarAction.createEpic$(action$, client.fetchOtherUserAvatar$),
      uploadAvatarAction.createEpic$(action$, client.uploadAvatar$),
      deleteAvatarAction.createEpic$(action$, client.deleteAvatar$)
    );
};

import React from 'react';

import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';

import { ElementSize, Spacing } from '@style/StyleConstants';

const LoadingSpinnerOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  visibility: initial;
  align-items: center;
`;

export const LoadingSpinnerSmallPanelContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${Spacing.ScreenSide}px;
`;

export const LoadingSpinnerOverlay: React.FC<{ size?: number; color?: 'primary' | 'secondary' }> = (props) => (
  <LoadingSpinnerOverlayContainer>
    <CircularProgress size={props.size} color={props.color} />
  </LoadingSpinnerOverlayContainer>
);

export const LoadingSpinner: React.FC<{ id?: string; size?: number; color?: 'primary' | 'secondary' }> = (props) => (
  <CircularProgress id={props.id} size={props.size} color={props.color} />
);

LoadingSpinner.defaultProps = { size: ElementSize.loadingSpinnerSize, color: 'secondary' };
LoadingSpinnerOverlay.defaultProps = { size: ElementSize.loadingSpinnerSize, color: 'secondary' };

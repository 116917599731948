import { compact, concat, find, has, isEqual, join, reduce } from 'lodash';

import { CompanyUserData } from '@common/client';
import { formatNumber } from '@common/helper';
import { LoadSearchRequest, MAX_TRUCK_LENGTH, MAX_TRUCK_WEIGHT, PosterLoadSearchRequest, Truck } from '@common/model';
import { t, T } from '@translate';

export enum TruckUpdateActionTypes {
  Favorite = 'Favorite',
  Onboard = 'Onboard',
  Hide = 'Hide',
}

export interface TruckUpdateAction {
  type: TruckUpdateActionTypes;
  updated: Array<CompanyUserData<boolean>>;
}

export const lengthDomain = [0, 54]; //min, max values
export const weightDomain = [0, 49000]; //min, max values
export const stepWeight = 1000;
export const stepWeight500 = 500;
export const stepWeightMinus500 = 499;
export const stepLength = 1;

export const setDefaultTruckData = (filters: LoadSearchRequest | PosterLoadSearchRequest) => {
  const truckData = {
    weights: weightDomain,
    lengths: lengthDomain,
  };
  if (has(filters, 'minLength') && has(filters, 'length')) {
    if (filters.minLength && filters.length) {
      truckData.lengths = [filters.minLength, filters.length];
    }
    if (!filters.minLength && filters.length) {
      truckData.lengths = [lengthDomain[0], filters.length];
    }
    if (filters.minLength && !filters.length) {
      truckData.lengths = [filters.minLength, lengthDomain[1]];
    }
  }
  if (has(filters, 'minWeight') && has(filters, 'weight')) {
    if (filters.minWeight && filters.weight) {
      truckData.weights = [filters.minWeight, filters.weight];
    }
    if (!filters.minWeight && filters.weight) {
      truckData.weights = [weightDomain[0], filters.weight];
    }
    if (filters.minWeight && !filters.weight) {
      truckData.weights = [filters.minWeight, weightDomain[1]];
    }
  }
  return truckData;
};

export const contactIdsToCompanyIds = (companiesUserData: CompanyUserData<boolean>[], trucks: Truck[]) =>
  reduce(
    companiesUserData,
    (userData, company) => {
      const truck = find(trucks, (truck) => truck.contactId === company.id);
      if (truck && truck.companyGuid) {
        return concat(userData, [
          {
            ...company,
            id: truck.companyGuid,
          },
        ]);
      }
      return userData;
    },
    []
  );

export const lengthLabel = (lengthValues: number[]) => {
  if (lengthValues.length == 1) {
    if (lengthValues[0] === lengthDomain[0]) {
      return `${t(T.findLoads_findLoads_any)}`;
    } else if (lengthValues[0] === lengthDomain[1]) {
      return `${t(T.unit_feet, {
        value: lengthValues[0] - stepLength,
      })}+`;
    }

    return `${t(T.unit_feet, { value: lengthValues[0] })}`;
  }

  if (!isEqual(lengthValues[0], 0) && isEqual(lengthValues[1], lengthDomain[1])) {
    return `${t(T.unit_feet, { value: lengthValues[0] })} - ${t(T.unit_feet, {
      value: lengthValues[1] - stepLength,
    })}+`;
  }
  if (isEqual(lengthValues, lengthDomain)) {
    return `${t(T.findLoads_findLoads_any)}`;
  } else {
    return `${t(T.unit_feet, { value: lengthValues[0] })} - ${t(T.unit_feet, {
      value: lengthValues[1],
    })}`;
  }
};

export const weightLabel = (weightValues: number[]) => {
  if (weightValues.length == 1) {
    if (weightValues[0] === weightDomain[0]) {
      return `${t(T.findLoads_findLoads_any)}`;
    } else if (weightValues[0] === weightDomain[1]) {
      return `${t(T.unit_pounds, {
        value: weightValues[0] - stepWeight,
      })}+`;
    }

    return `${t(T.unit_pounds, { value: weightValues[0] })}`;
  }

  if (!isEqual(weightValues[0], 0) && isEqual(weightValues[1], weightDomain[1])) {
    return `${t(T.unit_pounds, { value: weightValues[0].toLocaleString() })} - ${t(T.unit_pounds, {
      value: (weightValues[1] - stepWeight).toLocaleString(),
    })}+`;
  }
  if (isEqual(weightValues, weightDomain)) {
    return `${t(T.findLoads_findLoads_any)}`;
  } else {
    return `${t(T.unit_pounds, { value: weightValues[0].toLocaleString() })} - ${t(T.unit_pounds, {
      value: weightValues[1].toLocaleString(),
    })}`;
  }
};

export const getWeightOrLengthRange = (value: number[], domain: number[]) => {
  if (value[0] !== domain[0] && value[1] === domain[1]) {
    return [value[0], domain[1]];
  }
  if (value[0] === domain[0] && value[1] !== domain[1]) {
    return [domain[0], value[1]];
  }
  if (!isEqual(value, domain)) {
    return [value[0], value[1]];
  }
  return domain;
};

export const getLengthWeightExcludeValues = (
  includeLoadsWithoutLength: boolean | undefined,
  includeLoadsWithoutWeight: boolean | undefined
) => {
  if (
    (includeLoadsWithoutLength || includeLoadsWithoutLength === undefined) &&
    (includeLoadsWithoutWeight || includeLoadsWithoutWeight === undefined)
  ) {
    return undefined;
  } else {
    const lbsValue = includeLoadsWithoutWeight === false ? t(T.common_lbs) : '';
    const ftValue = includeLoadsWithoutLength === false ? t(T.common_feet) : '';
    return join(compact([ftValue, lbsValue]), ', ');
  }
};

export const getLengthWeightExcludeLabel = (
  includeLoadsWithoutLength: boolean | undefined,
  includeLoadsWithoutWeight: boolean | undefined
) => {
  const values = getLengthWeightExcludeValues(includeLoadsWithoutLength, includeLoadsWithoutWeight);
  return values ? t(T.common_withDimensions, { value: values }) : undefined;
};

/**
 * In case if we have slider set from min to max values we should remove them from LoadSearchRequest
 * to ensure there is no unnecessary data in search request*/
export const removeUnnecessaryWeightLengthValues = (search: LoadSearchRequest) => {
  if (isEqual([search.minLength, search.length], lengthDomain)) {
    search.length = undefined;
    search.minLength = undefined;
  }
  if (isEqual([search.minWeight, search.weight], weightDomain)) {
    search.weight = undefined;
    search.minWeight = undefined;
  }
  return search;
};

export const displayLength = (value?: number, isPowerOnly?: boolean) => {
  if (isPowerOnly) {
    return `${t(T.common_any)} ${t(T.common_feet)}`;
  }
  return (value && value > MAX_TRUCK_LENGTH) || value === undefined
    ? `${MAX_TRUCK_LENGTH} ${t(T.common_feet)}${t(T.common_plusSign)}`
    : t(T.unit_feet, { value: formatNumber(value) });
};

export const displayWeight = (value?: number, isPowerOnly?: boolean) => {
  if (isPowerOnly) {
    return `${t(T.common_any)} ${t(T.common_lbs)}`;
  }
  return (value && value > MAX_TRUCK_WEIGHT) || value === undefined
    ? `${formatNumber(MAX_TRUCK_WEIGHT)} ${t(T.common_lbs)}${t(T.common_plusSign)}`
    : value === 1
      ? t(T.unit_pound, { value: formatNumber(value) })
      : t(T.unit_pounds, { value: formatNumber(value) });
};

export const displayLengthAndWeight = (length?: number, weight?: number) =>
  `${displayLength(length)}/${displayWeight(weight)}`;

import { filter, first, isEmpty, isEqual, toString } from 'lodash';

import { NO_INFORMATION_SYMBOL } from '@common/formatter/Constants';
import { Conversation, ConversationMessage, ConversationUser } from '@common/model/Conversation';
import { TrackingDataConversationStatus } from '@common/model/Tracking';
import { ConversationMessageEntry } from '@common/redux/epic/CommunicationEpic';

import { formatDateHHMMA, formatEpochMsToTimeDate } from './DateHelper';
import { getInitials } from './UserHelper';

export interface ConversationUserDisplayDetails {
  userID: string;
  name: string;
  company: string;
  initials: string;
}

export enum ConversationListViewType {
  All = 'All',
  Unread = 'Unread',
}

export const getOtherUserInConversation = (userID: string | undefined, conversation: Conversation | undefined) => {
  if (!userID || !conversation) {
    return undefined;
  }
  return first(filter(conversation.users, (user) => user.userId !== userID));
};

export const contactDetailInfoFrom = (contact: ConversationUser | undefined): ConversationUserDisplayDetails => {
  if (contact) {
    return {
      userID: contact.userId,
      name: `${contact.firstName} ${contact.lastName}`,
      company: contact.company.name,
      initials: getInitials(contact),
    };
  } else {
    return {
      userID: '',
      name: NO_INFORMATION_SYMBOL,
      company: NO_INFORMATION_SYMBOL,
      initials: '',
    };
  }
};

export const getOtherUserDetailsInConversation = (
  userID: string | undefined,
  conversation: Conversation | undefined
): ConversationUserDisplayDetails => {
  const contact = getOtherUserInConversation(userID, conversation);
  return contactDetailInfoFrom(contact);
};

export const getConversationStatusForTracking = (
  messages: ConversationMessageEntry | undefined
): TrackingDataConversationStatus => {
  if (messages && !messages.isLoading && messages.response?.success) {
    if (messages.messages.length > 0) {
      return TrackingDataConversationStatus.Started;
    } else {
      return TrackingDataConversationStatus.Empty;
    }
  }
  return TrackingDataConversationStatus.Inconclusive;
};

export const getMessageReadAt = (message: ConversationMessage) =>
  message.users && message.users.length > 0 ? formatEpochMsToTimeDate(message.users[0].readDate) : undefined;

export const getIsLastOfGroup = (
  message?: ConversationMessage,
  messageBelow?: ConversationMessage,
  isReceivedMessage?: boolean
) => getIsGroupDelimiter(message, messageBelow, isReceivedMessage);

export const getIsFirstOfGroup = (
  message?: ConversationMessage,
  messageAbove?: ConversationMessage,
  isReceivedMessage?: boolean
) => getIsGroupDelimiter(message, messageAbove, isReceivedMessage);

// This function checks if a message is the first or last message of a group (group = messages
// with identical sent/seen timestamp).
// To check if it's last, compare to next message. To check for first, compare to previous message.
// If the message is alone in its group, it will return true for both.
export const getIsGroupDelimiter = (
  targetMessage?: ConversationMessage,
  surroundingMessage?: ConversationMessage,
  isReceivedMessage?: boolean
) =>
  !surroundingMessage ||
  isEmpty(surroundingMessage) ||
  (targetMessage &&
    surroundingMessage &&
    (!isEqual(targetMessage.sentBy, surroundingMessage.sentBy) ||
      isWithinDifferentMinute(targetMessage.sentAt, surroundingMessage.sentAt) ||
      (!isReceivedMessage &&
        isWithinDifferentMinute(getMessageReadAt(targetMessage), getMessageReadAt(surroundingMessage)))));

export const isWithinDifferentMinute = (firstMoment?: Date | string | number, secondMoment?: Date | string | number) =>
  !isEqual(momentToHHMMA(firstMoment), momentToHHMMA(secondMoment));

export const momentToHHMMA = (date?: Date | string | number) => (date ? formatDateHHMMA(toString(date)) : '');

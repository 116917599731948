import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api, ApiError } from '@common/api';
import { RouteClient } from '@common/client';
import { Geolocation, RoutePoints, StopsLocation } from '@common/model';
import { BaseState, createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

interface FetchRouteData {
  origin: Geolocation;
  destination: Geolocation;
  archivingFlowID: string | undefined;
}

interface PostRouteData {
  stops: StopsLocation[];
}

export const fetchRouteAction = createApiAction<FetchRouteData, RoutePoints>('FETCH_ROUTE');
const postRouteAction = createApiAction<PostRouteData, RoutePoints>('POST_ROUTE');
export const fetchRoute = (origin: Geolocation, destination: Geolocation, archivingFlowID: string | undefined) =>
  fetchRouteAction.fetchAction({ origin: origin, destination: destination, archivingFlowID: archivingFlowID });
export const postRoute = (data: PostRouteData) => postRouteAction.fetchAction(data);

export interface RouteState extends BaseState {
  route?: RoutePoints;
  error: ApiError | undefined;
}

const initialState: RouteState = { isLoading: false, error: undefined };

export const routeReducer = createMergedReducer(initialState, [
  fetchRouteAction.initiateCase((state) => {
    state.isLoading = true;
  }),
  fetchRouteAction.completeCase((state, action) => {
    if (action.response.success) {
      state.route = action.response.payload;
    } else {
      state.error = action.response.error;
    }
    state.isLoading = false;
  }),
  postRouteAction.completeCase((state, action) => {
    if (action.response.success) {
      state.route = action.response.payload;
    } else {
      state.error = action.response.error;
    }
    state.isLoading = false;
  }),
  postRouteAction.initiateCase((state) => {
    state.isLoading = true;
  }),
]);

export const createRouteEpic = (api: Api) => {
  const routeClient = new RouteClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchRouteAction.createEpic$(action$, ({ origin, destination, archivingFlowID }) =>
        routeClient.fetchRoute$({ origin: origin, destination: destination }, true, archivingFlowID)
      ),
      postRouteAction.createEpic$(action$, (data) => routeClient.postRoutePath$({ route: data }))
    );
};

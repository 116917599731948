import { BaseClient } from '@common/client/BaseClient';
import { RateCheck, RateCheckRequest, TruckAvailabilityDeleteRequest } from '@common/model';
import {
  TruckAvailabilitiesPayload,
  TruckAvailability,
  TruckAvailabilityCounts,
  TruckAvailabilityResponse,
} from '@common/model/Truck';
import { EmptyResponse } from '@common/redux/Base';
import { formatTruckAvailability, MileageRequest, MileageResponse } from '@common/redux/epic/PostTruckEpic';

export class PostTruckClient extends BaseClient {
  getTruckAvailabilityCount = () => this.api.get$<TruckAvailabilityCounts>('/truckavailability/counts', {});
  getTruckAvailabilities = (types?: string, statuses?: string, paginationLimit?: number, paginationToken?: string) =>
    this.api.get$<TruckAvailabilitiesPayload>('/truckavailability', {
      types: types,
      statuses: statuses,
      paginationLimit: paginationLimit,
      paginationToken: paginationToken,
    });
  updateTruckAvailabilities = (update: Partial<TruckAvailabilityResponse>, id: number, override: boolean = false) =>
    this.api.mergePatch$<undefined>(`/truckavailability/${id}`, {
      ...formatTruckAvailability(update),
      override: override,
    });
  addTruckAvailabilities = (
    truckAvailability: TruckAvailabilityResponse,
    convertStatesToAnywhere: boolean = true,
    override: boolean = false
  ) =>
    this.api.post$<TruckAvailability>(
      `/trucks/${truckAvailability.truckId}/availability`,
      {
        ...formatTruckAvailability(truckAvailability),
        convertStatesToAnywhere: convertStatesToAnywhere,
        override: override,
      },
      {}
    );
  deleteTruckAvailability = (availabilityID: number) =>
    this.api.delete$<undefined>(`/truckavailability/${availabilityID}`, {});
  bulkDeleteAvailability = (ids: number[]) =>
    this.api.post$<undefined>(`/truckavailability/delete`, { availabilityIds: ids });
  deleteTruckAvailabilities = (request: TruckAvailabilityDeleteRequest) =>
    this.api.post$<EmptyResponse>(`/truckavailability/delete/filter`, request);
  fetchTotalMileage$ = (request: MileageRequest) => this.api.post$<MileageResponse>('/routes/info', request);
  fetchRateCheck$ = (request: RateCheckRequest) => this.api.post$<RateCheck>('/ratechecks', request);
}

import { map } from 'lodash';

export const getStopsPoints = (pathStops: number[][][]) => {
  let points = new Array<number[]>();
  map(pathStops, (path, i) => {
    if (path.length >= 1) {
      if (i === 0) {
        points = [path[0], path[path.length - 1]];
      } else {
        const point = path[path.length - 1];
        points.push(point);
      }
    }
  });
  return points;
};

export const getOneDPoints = (pathStops: number[][][]) => {
  let points: number[][] = [[]];
  map(pathStops, (path, i) => {
    if (i === 0) {
      points = path;
    } else {
      points = points.concat(path);
    }
  });
  return points;
};

import React, { useLayoutEffect } from 'react';

import styled from 'styled-components';

import { FragmentPortalOrigin, useFragmentHolder } from '@component/fragmentPortal';
import { useBackPanelContext } from '@component/panel/BackPanelContext';
import { useUniqueId } from '@util/hooks';

import { FixedArea, useFixedAreaRenderContext } from './FixedAreaRenderContext';

interface Props {
  /** true to make the header collapse as the window scrolls */
  collapseOnScroll: boolean;
  update: (key: string, footer: FixedArea | undefined) => void;
}

const FixedEntity: React.FC<Props> = ({ update, collapseOnScroll, children }) => {
  const uid = useUniqueId();
  const fragmentHolder = useFragmentHolder();
  const { isBackPanel } = useBackPanelContext();
  const hasContent = React.Children.count(children) > 0;

  useLayoutEffect(() => {
    if (!hasContent || isBackPanel) {
      update(uid, undefined);
      return;
    }
    update(uid, {
      uid: uid,
      collapseOnScroll: collapseOnScroll,
      fragmentHolder: fragmentHolder,
    });
  });

  useLayoutEffect(() => {
    const unmount = () => update(uid, undefined);
    return unmount;
  }, []);

  if (!hasContent) {
    return null;
  }

  return <FragmentPortalOrigin fragmentHolder={fragmentHolder}>{children}</FragmentPortalOrigin>;
};

export const FixedHeader: React.FC = ({ children }) => {
  const fixedAreaRenderContext = useFixedAreaRenderContext();
  return (
    <FixedEntity update={fixedAreaRenderContext.updateHeader} collapseOnScroll={false}>
      {children}
    </FixedEntity>
  );
};

export const CollapsibleHeader: React.FC = ({ children }) => {
  const fixedAreaRenderContext = useFixedAreaRenderContext();
  return (
    <FixedEntity update={fixedAreaRenderContext.updateHeader} collapseOnScroll={true}>
      {children}
    </FixedEntity>
  );
};

const AbsoluteHeaderContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
`;

export const FloatingHeader: React.FC = ({ children }) => (
  <FixedHeader>
    <div style={{ position: 'relative' }}>
      <AbsoluteHeaderContainer>{children}</AbsoluteHeaderContainer>
    </div>
  </FixedHeader>
);

/** define a fixed Footer to display  */
export const FixedFooter: React.FC = ({ children }) => {
  const fixedAreaRenderContext = useFixedAreaRenderContext();
  return (
    <FixedEntity update={fixedAreaRenderContext.updateFooter} collapseOnScroll={false}>
      {children}
    </FixedEntity>
  );
};

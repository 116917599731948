import { forEach, split, toString } from 'lodash';

import { formatDateYYYYMMDD } from '@common/helper/DateHelper';
import { Equipment, EquipmentSpecification, EquipmentType, Load } from '@common/model';
import { AppConfig } from '@util/AppConfig';

enum CotaTrailerType {
  Van = 'Van',
  Reefer = 'Reefer',
  Flatbed = 'Flatbed',
  Stepdeck = 'Stepdeck',
  DoubleDrop = 'Double Drop',
  RGN = 'RGN',
  Chassis = 'Chassis',
  Stretch = 'Stretch',
  Tanker = 'Tanker',
  Livestock = 'Livestock',
  Hotshot = 'Hotshot',
  Bulk = 'Bulk',
}

enum CotaSpecialEquipment {
  LoadLock = 'Loadlocks',
  ETrack = 'E Track',
  Straps = 'Straps',
  Tarps = 'Tarps',
  AirRide = 'Air-Ride',
}

const enum CountryNames123 {
  US = 'US',
  CA = 'CA',
}

const enum CountryNamesCota {
  US = 'US',
  CA = 'Canada',
}

const cotaTrailerTypeMap: { key: EquipmentType; cotaTrailerType: string }[] = [
  { key: EquipmentType.Van, cotaTrailerType: CotaTrailerType.Van },
  { key: EquipmentType.Reefer, cotaTrailerType: CotaTrailerType.Reefer },
  { key: EquipmentType.Flatbed, cotaTrailerType: `${CotaTrailerType.Flatbed},${CotaTrailerType.Stretch}` },
  { key: EquipmentType.StepDeck, cotaTrailerType: `${CotaTrailerType.Stepdeck}` },
  { key: EquipmentType.DoubleDrop, cotaTrailerType: CotaTrailerType.DoubleDrop },
  { key: EquipmentType.RemovableGooseneck, cotaTrailerType: `${CotaTrailerType.RGN},${CotaTrailerType.Hotshot}` },
  { key: EquipmentType.AnimalCarrier, cotaTrailerType: CotaTrailerType.Livestock },
  { key: EquipmentType.HopperBottom, cotaTrailerType: CotaTrailerType.Bulk },
  { key: EquipmentType.Container, cotaTrailerType: CotaTrailerType.Chassis },
  { key: EquipmentType.Tanker, cotaTrailerType: CotaTrailerType.Tanker },
];

const cotaSpecialEquipmentMap: { key: EquipmentSpecification; cotaSpecialEquipment: string }[] = [
  { key: EquipmentSpecification.ETrack, cotaSpecialEquipment: CotaSpecialEquipment.ETrack },
  { key: EquipmentSpecification.Tarps, cotaSpecialEquipment: CotaSpecialEquipment.Tarps },
  { key: EquipmentSpecification.AirRide, cotaSpecialEquipment: CotaSpecialEquipment.AirRide },
];

interface CotaLoadDetails {
  commodity?: string;
  weight?: string;
  length?: string;
  pickupDate?: string;
  trailerNeeded?: string;
  specialEquipment?: string;
  company?: {
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    phone?: string;
    email?: string;
  };
  contact?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  };
}

const initialCotaLoadDetails = {
  commodity: '',
  weight: '',
  length: '',
  pickupDate: '',
  trailerNeeded: '',
  specialEquipment: '',
  company: {
    name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    phone: '',
    zip: '',
    email: '',
  },
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
};

const getCotaEquipments = (
  equipments: Equipment[] | undefined
): { equipmentType: string; equipmentSpecification: string } => {
  let equipmentsTypes = '';
  let equipmentsSpecifications = '';

  if (equipments === undefined) {
    return { equipmentType: equipmentsTypes, equipmentSpecification: equipmentsSpecifications };
  }

  forEach(equipments, (equipment) => {
    equipmentsTypes = concatinateEquipmentTypes(equipmentsTypes, equipment);
    equipmentsSpecifications = concatinateEquipmentSpecification(equipmentsSpecifications, equipment);
  });

  return { equipmentType: equipmentsTypes, equipmentSpecification: equipmentsSpecifications };
};

const concatinateEquipmentTypes = (equipmentsTypes: string, equipment: Equipment): string => {
  let newEquipmentTypes = equipmentsTypes;
  forEach(cotaTrailerTypeMap, (cotaTrailerType) => {
    if (cotaTrailerType.key === equipment.equipmentType) {
      if (equipmentsTypes !== '') {
        newEquipmentTypes = `${equipmentsTypes},${cotaTrailerType.cotaTrailerType}`;
      } else {
        newEquipmentTypes = cotaTrailerType.cotaTrailerType;
      }
    }
  });
  return newEquipmentTypes;
};

const concatinateEquipmentSpecification = (equipmentsSpecifications: string, equipment: Equipment): string => {
  let newEquipmentsSpecifications = equipmentsSpecifications;
  forEach(cotaSpecialEquipmentMap, (cotaSpecialEquipment) => {
    if (cotaSpecialEquipment.key === equipment.equipmentSpecifications) {
      if (equipmentsSpecifications !== '') {
        newEquipmentsSpecifications = `${equipmentsSpecifications},${cotaSpecialEquipment.cotaSpecialEquipment}`;
      } else {
        newEquipmentsSpecifications = cotaSpecialEquipment.cotaSpecialEquipment;
      }
    }
  });
  return newEquipmentsSpecifications;
};

const getCountryCotaName = (countryName: string | undefined) => {
  switch (countryName) {
    case CountryNames123.US:
      return CountryNamesCota.US;
    case CountryNames123.CA:
      return CountryNamesCota.CA;
    default:
      return '';
  }
};

const getAddressLine = (addressLine1?: string, addressLine2?: string) => {
  const address = addressLine1 ? addressLine1 : '';
  return addressLine2 ? `${address} ${addressLine2}` : address;
};

const isNewValueUndefined = (newValue: string | undefined, oldValue: string): string => {
  if (newValue === undefined || newValue === '') {
    return oldValue;
  }
  return newValue;
};

export const getUrlCotaLoadDetails = (loadDetails: Load): CotaLoadDetails => {
  const pickUpDate = loadDetails.pickupDateTime ? formatDateYYYYMMDD(loadDetails.pickupDateTime) : '';
  const companyDetails = loadDetails.poster;
  const weight = loadDetails.weight ? toString(loadDetails.weight) : undefined;
  const length = loadDetails.length ? toString(loadDetails.length) : undefined;
  const cotaEquipments = getCotaEquipments(loadDetails.equipments);
  const contactNames = split(loadDetails.dispatchName ?? '', ' ');
  const country = getCountryCotaName(companyDetails?.address?.country);
  const addressLine = getAddressLine(companyDetails?.address?.addressLine1, companyDetails?.address?.addressLine2);

  return {
    commodity: isNewValueUndefined(loadDetails.commodity, initialCotaLoadDetails.commodity),
    weight: isNewValueUndefined(weight, initialCotaLoadDetails.weight),
    length: isNewValueUndefined(length, initialCotaLoadDetails.length),
    pickupDate: pickUpDate,
    trailerNeeded: cotaEquipments.equipmentType,
    specialEquipment: cotaEquipments.equipmentSpecification,
    company: {
      name: isNewValueUndefined(companyDetails?.name, initialCotaLoadDetails.company.name),
      address: isNewValueUndefined(addressLine, initialCotaLoadDetails.company.address),
      city: isNewValueUndefined(companyDetails?.address?.city, initialCotaLoadDetails.company.city),
      state: isNewValueUndefined(companyDetails?.address?.state, initialCotaLoadDetails.company.state),
      country: country,
      zip: isNewValueUndefined(companyDetails?.address?.zipCode, initialCotaLoadDetails.company.zip),
      phone: isNewValueUndefined(companyDetails?.phone?.number, initialCotaLoadDetails.company.phone),
      email: initialCotaLoadDetails.company.email,
    },
    contact: {
      firstName: isNewValueUndefined(contactNames[0], initialCotaLoadDetails.contact.firstName),
      lastName: isNewValueUndefined(contactNames[1], initialCotaLoadDetails.contact.lastName),
      email: isNewValueUndefined(loadDetails?.dispatchEmail, initialCotaLoadDetails.contact.email),
      phone: isNewValueUndefined(loadDetails?.dispatchPhone?.number, initialCotaLoadDetails.contact.phone),
    },
  };
};

export const openCotaNewLoad = (details: CotaLoadDetails) => {
  const cotaBaseUrl = AppConfig.LBConfig.CotaBaseUrl;
  const url = `${cotaBaseUrl}/loads/new?commodity=${details.commodity}&weight=${details.weight}&length=${details.length}&pickupDate=${details.pickupDate}&trailerNeeded=${details.trailerNeeded}&specialEquipment=${details.specialEquipment}&name=${details.company?.name}&address=${details.company?.address}&city=${details.company?.city}&zip=${details.company?.zip}&state=${details.company?.state}&country=${details.company?.country}&phone=${details.company?.phone}&email=${details.company?.email}&firstName=${details.contact?.firstName}&lastName=${details.contact?.lastName}&personEmail=${details.contact?.email}&personPhone=${details.contact?.phone}`;
  window.open(url, '_blank');
};

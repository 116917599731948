import React from 'react';

import { GenericError } from '@page/error/GenericError';
import NotFoundImg from '@page/error/notFound.svg';
import { t, T } from '@translate';

export function lazy(importComponent: () => any) {
  class LazyComponent extends React.Component<{}> {
    state = {
      component: null,
    };

    async componentDidMount() {
      let component: any;
      try {
        const { default: lazyComponent } = await importComponent();
        if (!lazyComponent) {
          throw new Error('No default component found in module');
        }
        component = lazyComponent;
      } catch (e) {
        console.error('Lazy Error: ' + e);
        component = ComponentNotFound;
      }

      this.setState({
        component: component,
      });
    }

    render() {
      const Component: any | null = this.state.component;

      return Component ? <Component {...this.props} /> : null;
    }
  }

  return LazyComponent;
}

const ComponentNotFound: React.SFC = () => (
  <GenericError
    id="page_chunk_not_found"
    message={t(T.errors_no_component_Message)}
    subMessage={t(T.errors_no_component_Sub_Message)}
    imageSrc={NotFoundImg}
    linkTitle={t(T.errors_no_component_Button)}
    onClick={reloadWindow}
  />
);

const reloadWindow = () => window.location.reload();

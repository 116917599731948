import React from 'react';

import styled from 'styled-components';

import { default as Icon123Connect } from '@/icons/123connect_icon.svg';
import { default as NoConversations } from '@/icons/no_conversations.svg';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { Text } from '@component/text';
import { FontLineHeight, FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${Spacing.DoubleInterSection}px ${Spacing.ElementPaddingHorizontalWide}px ${Spacing.InterSection}px
    ${Spacing.ElementPaddingHorizontalWide}px;
`;

export const ContentText = styled(Text)`
  font-size: ${FontSize.Content}px;
  line-height: ${FontLineHeight.MediumTitle}px;
  font-weight: regular;
  text-align: center;
  margin-bottom: ${Spacing.InterSection}px;
`;

export const EmptyConversationsPanel: React.FC = () => {
  const isCarrier = useIsCarrier();

  return isCarrier ? (
    <NotFoundPanel
      imageSection={<img id="image" src={Icon123Connect} alt="error" />}
      title={t(T.common_conversations_title123Connect)}
      controlSection={
        <Container>
          <ContentText id="carrier_paragraph_1">{t(T.common_conversations_empty_subtitleCarrier_part1)}</ContentText>
          <ContentText id="carrier_paragraph_2">{t(T.common_conversations_empty_subtitleCarrier_part2)}</ContentText>
          <ContentText id="carrier_paragraph_3" style={{ marginBottom: Spacing.DoubleInterSection }}>
            {t(T.common_conversations_empty_subtitleCarrier_part3)}
          </ContentText>
        </Container>
      }
    />
  ) : (
    <NotFoundPanel
      title={t(T.common_conversations_empty_title)}
      subtitle={t(T.common_conversations_empty_subtitleBroker)}
      imageSection={<img id="image" src={NoConversations} alt="error" />}
    />
  );
};

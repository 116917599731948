import React, { useMemo, useState } from 'react';

import { isUndefined } from 'lodash';

import { LocationTypes } from '@/model';
import { isLocationCountryOrAnywhere } from '@common/helper/LocationHelper';
import { CountriesWithAnywhere, displaySingleStringValueFor } from '@common/helper/LocationPickerHelper';
import { LoadLocation, LocationSuggestion, LocationType, RecentLocation, RecentLocationType } from '@common/model';
import { useDidUpdate } from '@common/util/hooks';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { LocationPicker } from '@component/panels/modalPanel/LocationPicker';
import { HomePick } from '@component/panels/pickerPanels/HomePick';
import { T, t } from '@translate';

interface Props {
  locationType: LocationTypes;
  layer: number;
  offset: number;
  maxStatesCount?: number;
  locationTypes?: RecentLocationType[];
  location: LoadLocation | undefined;
  inputFieldLabel?: string;
  inputFieldPlaceHolder?: string;
  headerLabel?: string;
  onlyShowAnywhere?: boolean;
  onSelectionChange: (location: LocationSuggestion) => void;
  onRecentLocationsChange: (recentLocation: RecentLocation) => void;
  onCountryChange?: (country: CountriesWithAnywhere) => void;
  onHomeLocationChange?: (location: LoadLocation) => void;
  onCurrentLocationChange?: (location: LoadLocation) => void;
  onStatesChange?: (states: string[]) => void;
  onSelectStatesOnMapClick?: () => void;
  onClose: () => void;
}

export const LocationPickerPanel: React.FC<Props> = (props) => {
  const [locationText, setLocationText] = useState(displayLocationText(props.location));
  const [isEditingHome, setIsEditingHome] = useState(false);

  useDidUpdate(() => {
    setLocationText(displayLocationText(props.location));
  }, [props.location]);

  const states = useMemo(
    () => (props.location?.type === LocationType.STATE ? props.location.states : []),
    [props.location]
  );

  const onSelectionChange = (location: LocationSuggestion) => {
    props.onSelectionChange(location);
    props.onClose();
  };

  const onStatesChange = (states: string[]) => {
    props.onStatesChange?.(states);
    props.onClose();
  };

  const toggleHomePick = () => setIsEditingHome(!isEditingHome);

  return isEditingHome && props.onHomeLocationChange ? (
    <HomePick layer={props.layer} toggleHomePick={toggleHomePick} suggestionClick={props.onHomeLocationChange} />
  ) : (
    <Panel
      id={'location_picker_header'}
      layer={props.layer}
      size={PanelSize.small}
      offset={props.offset}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        label={
          props.headerLabel ??
          (props.locationType === LocationTypes.Pickup
            ? t(T.modalPanels_LocationPicker_PickUpHeader)
            : t(T.modalPanels_LocationPicker_DropOffHeader))
        }
        hasBackButton={true}
        onClose={props.onClose}
      />

      <LocationPicker
        locationType={props.locationType}
        value={locationText}
        title={props.inputFieldLabel}
        placeholder={props.inputFieldPlaceHolder}
        states={states}
        maxStatesCount={props.maxStatesCount}
        recentLocationTypes={props.locationTypes}
        onValueChange={setLocationText}
        onSelectionChange={onSelectionChange}
        onRecentLocationSelect={props.onRecentLocationsChange}
        onStatesSelect={props.onStatesChange ? onStatesChange : undefined}
        onCountrySelect={props.onCountryChange}
        onCurrentLocationSelect={props.onCurrentLocationChange}
        onHomeLocationSelect={props.onHomeLocationChange}
        onSelectStatesOnMapClick={props.onSelectStatesOnMapClick}
        toggleHomePick={toggleHomePick}
        onlyShowAnywhere={props.onlyShowAnywhere}
        shouldAvoidDownshiftStateResetOnBlur={true}
      />
    </Panel>
  );
};

const displayLocationText = (location: LoadLocation | undefined) => {
  if (isUndefined(location) || isLocationCountryOrAnywhere(location)) {
    return '';
  }
  return displaySingleStringValueFor(location, true);
};

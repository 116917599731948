export interface BookNow {
  bookingStatus: BookingStatus;
  responseSpeed: BrokerResponseSpeed;
  amount: number;
  submittedDateTime: string;
}

export enum BookingStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export enum BrokerResponseSpeed {
  Unknown = 'Unknown',
  Fast = 'Fast',
  Slow = 'Slow',
}

export type BookNowResponse = BookNow;

import React, { CSSProperties, PropsWithChildren, ReactElement, useState } from 'react';

import styled from 'styled-components';

import { isMessagingWidgetView } from '@/MessagingWidgetApp';
import { GlobalBanner } from '@component/globalBanner/GlobalBanner';
import { MobileMenuGrayLayout } from '@component/main/MobileDrawer';
import { DESKTOP_MENU_TRANSITION_MS, DESKTOP_MENU_TRANSITION_TIMINGFUNC } from '@component/menu/menuStyles';
import { FixedHeader } from '@component/scrollView/FixedArea';
import { BlockHeaderPortalDestination, HeaderHeight } from '@component/scrollView/FixedAreaPortalDestination';
import { FixedAreaRenderProvider } from '@component/scrollView/FixedAreaRenderContext';
import { useSetupBodyFixedForScrolling } from '@component/scrollView/setupBodyFixed';
import { WindowScrollView } from '@component/scrollView/WindowScrollView';
import { useIsMobileView } from '@util/hooks';

import { DESKTOP_BORDER_WIDTH, DesktopThinBlackFrame } from './DesktopThinBlackFrame';
import { useIsWindowScrollMode, useShouldDisplayHeader, useSideMenuWidth } from './mainHooks';
import { MobileTopBar } from './MobileTopBar';

const ContainerForNestedDivScrollViews = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface MainLayoutProps {
  hideHeaders?: boolean;
  menuButtonDecoration?: ReactElement;
  topRightComponent?: ReactElement;
  mobileMenuChildren?: React.ReactNode;
  desktopMenuChildren?: React.ReactNode;
}

/** Application Main layout.
 *
 * Controls the side menu and mobile/desktop main scrolling features
 */

export const MainLayout = (props: PropsWithChildren<MainLayoutProps>) => {
  const { hideHeaders, topRightComponent, mobileMenuChildren, desktopMenuChildren, menuButtonDecoration, children } =
    props;

  const isMobileView = useIsMobileView();
  const isMessagingWidget = isMessagingWidgetView();
  const displayHeader = useShouldDisplayHeader() && !hideHeaders && !isMessagingWidget;
  const sideMenuWidth = useSideMenuWidth();
  const isWindowScrollMode = useIsWindowScrollMode();

  useSetupBodyFixedForScrolling(!isWindowScrollMode);

  const [extraHeaderHeight, setExtraHeaderHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const mainContentMarginRight = isMobileView || !displayHeader ? 0 : DESKTOP_BORDER_WIDTH;
  const mainContentMarginLeft = isMobileView || !displayHeader ? 0 : sideMenuWidth;

  const horizontalSizeStyles: CSSProperties = {
    marginLeft: mainContentMarginLeft,
    transition: `margin-left ${DESKTOP_MENU_TRANSITION_MS}ms ${DESKTOP_MENU_TRANSITION_TIMINGFUNC}`,
    marginRight: mainContentMarginRight,
  };

  const mobileMenuGrayLayout = displayHeader ? null : <MobileMenuGrayLayout />;

  return (
    <HeaderHeight.Provider
      value={{
        headerHeight: extraHeaderHeight + headerHeight,
        setExtraHeaderHeight: setExtraHeaderHeight,
        setHeaderHeight: setHeaderHeight,
      }}
    >
      <FixedAreaRenderProvider>
        <GlobalBanner>
          {displayHeader ? (
            <Menus
              isMobileView={isMobileView}
              topRightComponent={topRightComponent}
              menuButtonDecoration={menuButtonDecoration}
            >
              {isMobileView ? mobileMenuChildren : desktopMenuChildren}
            </Menus>
          ) : null}
          {isWindowScrollMode ? (
            <WindowScrollView style={horizontalSizeStyles}>{children}</WindowScrollView>
          ) : (
            <ContainerForNestedDivScrollViews style={horizontalSizeStyles}>
              <BlockHeaderPortalDestination id="mainheaderfornestedscroll" includesCollapsible={true} />
              <div id="maincontentfornestedscroll" style={{ flex: '1', position: 'relative', overflow: 'visible' }}>
                {children}
              </div>
              {mobileMenuGrayLayout}
            </ContainerForNestedDivScrollViews>
          )}
        </GlobalBanner>
      </FixedAreaRenderProvider>
    </HeaderHeight.Provider>
  );
};

const Menus: React.FC<{
  isMobileView: boolean;
  topRightComponent?: ReactElement;
  menuButtonDecoration?: ReactElement;
}> = ({ isMobileView, topRightComponent, menuButtonDecoration, children }) =>
  isMobileView ? (
    <>
      <FixedHeader>
        <MobileTopBar menuButtonDecoration={menuButtonDecoration}>{topRightComponent}</MobileTopBar>
      </FixedHeader>
      {children}
    </>
  ) : (
    <>
      <FixedHeader>
        <DesktopThinBlackFrame>{topRightComponent}</DesktopThinBlackFrame>
      </FixedHeader>
      {children}
    </>
  );

import React from 'react';

import { IconActionButton } from '@component/buttons';
import { T, t } from '@translate';
import icons from '@util/iconsConstants';

interface Props {
  onClick: () => void;
}

export const StatesProvincesRegionsMapButton: React.FC<Props> = (props) => (
  <IconActionButton
    id="states_provinces"
    title={t(T.findLoads_findLoads_statesProvincesRegionsMap)}
    mainIcon={icons.states}
    onClick={props.onClick}
  />
);

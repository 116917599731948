export const NUMBER_OF_GRID_COLUMNS = 12;
export const THREE_QUARTER_GRID_COLUMNS = 9;
export const TWO_THIRD_GRID_COLUMNS = 8;
export const HALF_GRID_COLUMNS = 6;
export const THIRD_GRID_COLUMNS = 4;
export const QUARTER_GRID_COLUMNS = 3;
export const DEFAULT_PAGE_WIDTH = 1000;
export const DEFAULT_MOBILE_WIDTH = 480;
export const STANDARD_MARGIN = 16;
export const CARD_MARGIN = STANDARD_MARGIN;

export const NO_INFORMATION_SYMBOL = '—';
export const HIDDEN_PHONE_NUMBER = '*'.repeat(10);

export const COMPANY_DETAILS_EXPANSION_PANEL_HEIGHT = 40;

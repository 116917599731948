import { useCallback } from 'react';

import { useFactory } from '@common/util/hooks';

export class UserActivityManager {
  public interval: number;
  private lastActivityTimerStamp = 0;
  private timer: any | undefined;
  private timerSet: boolean;
  private onUserInactive: () => void;
  private SESSION_EXPIRED_INTERVAL: number = 30 * 60000;

  constructor(onUserInactive: () => void, interval: number) {
    this.onUserInactive = onUserInactive;
    this.interval = interval;
    this.lastActivityTimerStamp = Date.now();
  }

  public isUserActivityIntervalSet = () => this.timerSet;

  public setUserActivityInterval = () => {
    if (!this.timerSet) {
      this.timerSet = true;
      this.timer = setInterval(this.onUpdateActivity, this.interval);
    }
  };

  public pauseUserActivityInterval = () => {
    if (this.timerSet) {
      clearInterval(this.timer);
      this.timerSet = false;
    }
  };

  public setLastActivityStamp = (time: number) => {
    this.lastActivityTimerStamp = time;
  };

  public getLastActivityStamp = () => this.lastActivityTimerStamp;

  private onUpdateActivity = () => {
    const currentTime = Date.now();
    //if the user is not looking/intracting with the page for more than 30 min
    //the user is concidered inactive
    if (currentTime - this.lastActivityTimerStamp >= this.SESSION_EXPIRED_INTERVAL) {
      this.onUserInactive();
      this.pauseUserActivityInterval();
    }
  };
}

export const useUserActivityManager = (onUserInactive: () => void, interval: number) => {
  const factory = useCallback(() => new UserActivityManager(onUserInactive, interval), [onUserInactive, interval]);
  return useFactory<UserActivityManager>(
    factory,
    (prevManager, newManager) => {
      newManager.setLastActivityStamp(prevManager.getLastActivityStamp());
      if (prevManager.isUserActivityIntervalSet()) {
        newManager.setUserActivityInterval();
      }
    },
    (manager) => {
      manager.pauseUserActivityInterval();
    }
  );
};

import React, { useEffect, useMemo, useState } from 'react';

import { isUndefined, toString } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { StoreState } from '@/reduxStore/Store';
import { Routes } from '@/router/Routes';
import { formatPhoneNumber, getUserMemberType, userRoleLocalizedText } from '@common/helper';
import { Flag, Roles, VerificationPointsTaskStatus } from '@common/model';
import { getVerifiedPhoneStatus } from '@common/redux/epic/VerifyPhoneEpic';
import { VerificationBadge } from '@component/badge/VerificationBadge';
import { AlertsIconBadge } from '@component/menu/AlertsIconBadge';
import { UserAvatar, UserContact, UserInfoText, UserName, UserProfile } from '@component/menu/menuStyles';
import { usePushPanel } from '@component/panel/PanelHooks';
import { FORCE_VERIFICATION_URL_QUERY } from '@component/panels/settings/company/BusinessProfileHelper';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { Filter } from '@page/newSettings/panelSectionFilter/SettingsHelper';
import { Color } from '@style/Color';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';

import { default as AvatarIcon } from './icon/icon-add-avatar-24px.svg';
import { useIsCarrier } from './NavigationMenuHelper';
import { UserAvatarContainer } from './UserAvatarContainer';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const LinkButton = styled.div`
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
  margin-left: 8px;
`;

interface ReduxProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  roles?: Roles[];
  planDisplayName?: string;
  phoneVerifiedStatus?: VerificationPointsTaskStatus;
}

interface ComponentProps {
  isMenuOpen: boolean;
  onClick: () => void;
  isDisplayingToggle: boolean;
}

type Props = ComponentProps & ReduxProps;

const MenuUserProfileComponent: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { popup, closingPopup } = usePopup();
  const isBroker = !useIsCarrier();
  const isSoundNotificationTurnedOn = useSelector(
    (state) => state.settings.systemSetting[Flag.MessageSoundAlertsEnabled].value
  );
  const isUsdotNumberVerified = useSelector((state) => state.user.profile?.payload?.isUsdotNumberVerified);
  const companyUsDotNumber = useSelector((state) => toString(state.companyState.company?.usdotNumber));
  const company = useSelector((state) => state.companyState.company);
  const isReady = useSelector(
    (state) =>
      state.companyState.didRetrieveCompanyDetailsSuccessfully &&
      !!state.user.profile?.payload &&
      state.verifyPhone.primaryPhoneVerificationStatus
  );
  const [currentUsDot, setCurrentUsDot] = useState(companyUsDotNumber);

  const history = useHistory();

  const pushUserProfilePanel = usePushPanel(generatePath(Routes.MORE_SETTINGS, { filter: Filter.Profile }));
  const pushBusinessProfilePanel = usePushPanel(generatePath(Routes.MORE_SETTINGS, { filter: Filter.BusinessProfile }));

  useEffect(() => {
    dispatch(getVerifiedPhoneStatus());
  }, []);

  useEffect(() => {
    if (company) {
      setCurrentUsDot(companyUsDotNumber);
    }
  }, [companyUsDotNumber, company]);

  const {
    firstName,
    lastName,
    email,
    phone,
    roles,
    isMenuOpen,
    isDisplayingToggle,
    onClick,
    phoneVerifiedStatus,
    planDisplayName,
  } = props;
  const isPrimaryPhoneVerified = useMemo(
    () => phoneVerifiedStatus === VerificationPointsTaskStatus.COMPLETED,
    [phoneVerifiedStatus]
  );
  const userRole = getUserMemberType(roles);

  const onAvatarClick = () => {
    if (popup) {
      closingPopup();
    }
    onClick();
  };

  const openUserProfilePanel = () => {
    pushUserProfilePanel();
    onClick();
  };

  const openUserProfilePanelWithVerification = () => {
    if (isPrimaryPhoneVerified) {
      openUserProfilePanel();
    } else {
      history.push({
        pathname: generatePath(Routes.MORE_SETTINGS, { filter: Filter.Profile, subroutes: 'phone' }),
        search: `${FORCE_VERIFICATION_URL_QUERY}=1`,
      });
      onClick();
    }
  };

  const openBusinessProfile = (pathParam?: string) => {
    pushBusinessProfilePanel(pathParam);
    onClick();
  };

  const openBusinessProfilePanelWithVerification = () => {
    if (isUsdotNumberVerified) {
      openBusinessProfile();
    } else {
      history.push({
        pathname: generatePath(Routes.MORE_SETTINGS, { filter: Filter.BusinessProfile }),
        search: `${FORCE_VERIFICATION_URL_QUERY}=1`,
      });
      onClick();
    }
  };

  return (
    <UserProfile id="user" isDisplayingToggle={isDisplayingToggle} opened={isMenuOpen}>
      <UserAvatar>
        <UserAvatarContainer
          id="UserAvatarContainer"
          component={(buttonProps: any) => (
            <Link
              to={{ pathname: generatePath(Routes.MORE_SETTINGS, { filter: Filter.Profile }) }}
              {...buttonProps}
              onClick={onAvatarClick}
            />
          )}
          icon={<img src={AvatarIcon} alt="avatar_icon" />}
          label={planDisplayName || userRoleLocalizedText(userRole)}
          firstName={firstName}
          lastName={lastName}
          onClick={onAvatarClick}
        />
      </UserAvatar>
      <UserInfoText style={{ display: isReady ? undefined : 'none' }}>
        <UserName id="name" opened={isMenuOpen} onClick={openUserProfilePanel}>
          {firstName} {lastName}
        </UserName>
        {email ? (
          <Row>
            <UserContact title={email} id="email" opened={isMenuOpen} onClick={openUserProfilePanel}>
              {email}
            </UserContact>
            <VerificationBadge
              testID="email"
              isVerified={true}
              onRedirect={openUserProfilePanel}
              style={{ marginBottom: 2 }}
            />
          </Row>
        ) : null}
        {phone ? (
          <Row>
            <UserContact id="phone" opened={isMenuOpen} onClick={openUserProfilePanel}>
              {formatPhoneNumber(phone)}
            </UserContact>
            {phoneVerifiedStatus ? (
              <VerificationBadge
                testID="phone"
                isVerified={isPrimaryPhoneVerified}
                onRedirect={openUserProfilePanelWithVerification}
                style={{ marginBottom: 2 }}
              />
            ) : null}
            {!isPrimaryPhoneVerified ? (
              <LinkButton onClick={openUserProfilePanelWithVerification}>{t(T.common_verify)}</LinkButton>
            ) : null}
          </Row>
        ) : null}
        {!isBroker ? (
          <Row>
            <UserContact title={currentUsDot} id="dot" opened={isMenuOpen} onClick={() => openBusinessProfile()}>
              {`${t(T.settings_businessProfile_dot)} ${currentUsDot}`}
            </UserContact>
            {!currentUsDot ? (
              <LinkButton onClick={() => openBusinessProfile('edit_business_profile')}>{t(T.common_add)}</LinkButton>
            ) : null}
            {currentUsDot ? (
              <VerificationBadge
                testID="dot_verification_badge"
                isVerified={isUsdotNumberVerified}
                onRedirect={openBusinessProfilePanelWithVerification}
                style={{ marginBottom: 2 }}
              />
            ) : null}
            {currentUsDot && !isUsdotNumberVerified ? (
              <LinkButton onClick={openBusinessProfilePanelWithVerification}>{t(T.common_verify)}</LinkButton>
            ) : null}
          </Row>
        ) : null}
      </UserInfoText>
      {isBroker && !isUndefined(isSoundNotificationTurnedOn) ? (
        <AlertsIconBadge isAlertsSoundOn={isSoundNotificationTurnedOn} isMenuOpen={isMenuOpen} />
      ) : null}
    </UserProfile>
  );
};

const mapStateToProps = (state: StoreState): ReduxProps => {
  return {
    firstName: state.user.profile && state.user.profile.payload ? state.user.profile.payload.firstName : undefined,
    lastName: state.user.profile && state.user.profile.payload ? state.user.profile.payload.lastName : undefined,
    email: state.user.profile && state.user.profile.payload ? state.user.profile.payload.email : undefined,
    phone: state.user.profile && state.user.profile.payload ? state.user.profile.payload.phone : undefined,
    roles: state.user.profile && state.user.profile.payload ? state.user.profile.payload.roles : undefined,
    planDisplayName:
      state.user.profile && state.user.profile.payload ? state.user.profile.payload.planDisplayName : undefined,
    phoneVerifiedStatus: state.verifyPhone.primaryPhoneVerificationStatus,
  };
};

export const MenuUserProfile = connect(mapStateToProps)(MenuUserProfileComponent);

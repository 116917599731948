import React, { useEffect, useState } from 'react';

import { words } from 'lodash';
import { useDispatch } from 'react-redux';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TwitterShareButton, XIcon } from 'react-share';
import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';

import { ApiError } from '@common/api';
import { isLoadUnbookedAndAvailable } from '@common/helper/MyLoadsHelper';
import { Load } from '@common/model';
import { SharedLoadMessage } from '@common/model/SharedLoadMessage';
import { VhubEvent } from '@common/model/Tracking';
import { clearShareLoadUrlState, fetchSharedLoad } from '@common/redux/epic/LoadInfoEpic';
import { createVhubBookingTrackData } from '@common/util/123lbBaseTracker';
import { MultipurposeButton } from '@component/buttons';
import { EmailFormPopup } from '@component/panels/basePanel/loadDetails/EmailPopup';
import { VhubBookWithPopup } from '@component/panels/findLoads/loadDetailsPanel/buttons/VhubBookWithButton';
import {
  CopyIcon,
  CopyToClipboardButton,
  CopyToClipboardText,
  IconContainer,
  ShareActionButton,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupCallButton, PopupCancelButton, PopupOkButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupSizes, PopupTracking, usePopup } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { copyToClipboard } from '@util/PageHelper';
import { track } from '@util/trackers/123lbTracker';

interface PopupNetworksProps {
  sharedLoadMessage?: SharedLoadMessage;
  loadID: string;
  error?: ApiError;
  isLoading?: boolean;
}

const LoadSharingError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SocialNetworksPopup: React.FC<PopupNetworksProps> = ({ loadID, sharedLoadMessage, error, isLoading }) => {
  const [isReadyToShowResponse, setIsReadyToShowResponse] = useState(true);
  const { openingPopup, closingPopup } = usePopup();
  const dispatch = useDispatch();
  const handleRetry = () => {
    dispatch(fetchSharedLoad(loadID));
  };
  useEffect(() => {
    if (isLoading) {
      setIsReadyToShowResponse(true);
    }
  }, [isLoading]);
  useEffect(() => {
    const style = {
      display: 'flex',
      flex: 1,
      alignItems: 'flex-end',
      cursor: 'pointer',
      outline: 'none',
    };
    const closePopup = () => {
      dispatch(clearShareLoadUrlState(loadID));
      setIsReadyToShowResponse(false);
      closingPopup();
    };
    if (isReadyToShowResponse && (sharedLoadMessage || isLoading || error)) {
      openingPopup({
        body: (
          <BasePopup
            title={error ? t(T.error_failed_to_load) : t(T.findLoads_loadDetails_shareLoad)}
            buttons={
              error
                ? [
                    <PopupPrimaryButton
                      testID={'retry'}
                      key={'retry'}
                      label={t(T.error_try_again)}
                      action={handleRetry}
                    />,
                    <PopupCancelButton testID={'cancel'} key={'cancel'} action={closePopup} />,
                  ]
                : [<PopupCancelButton testID={'cancel'} key={'cancel'} action={closePopup} />]
            }
          >
            {sharedLoadMessage ? (
              <>
                <ShareActionButton>
                  <EmailShareButton
                    url=""
                    subject={sharedLoadMessage.email.subject}
                    body={sharedLoadMessage.email.message}
                    style={style}
                  >
                    <EmailIcon
                      size={30}
                      round={true}
                      iconFillColor={Color.EMAIL}
                      bgStyle={{ fill: Color.GRAY_LIGHT }}
                    />

                    <div id="email" style={{ marginLeft: 10 }}>
                      {t(T.findLoads_findLoads_email)}
                    </div>
                  </EmailShareButton>
                </ShareActionButton>
                <ShareActionButton>
                  <FacebookShareButton
                    url={sharedLoadMessage.facebook.contentURL}
                    hashtag={sharedLoadMessage.facebook.contentDescription}
                    style={style}
                  >
                    <FacebookIcon
                      size={30}
                      round={true}
                      iconFillColor={Color.FACEBOOK}
                      bgStyle={{ fill: Color.GRAY_LIGHT }}
                    />
                    <div id="facebook" style={{ marginLeft: 10 }}>
                      {t(T.findLoads_findLoads_facebook)}
                    </div>
                  </FacebookShareButton>
                </ShareActionButton>
                <ShareActionButton>
                  <TwitterShareButton
                    url={sharedLoadMessage.twitter.url}
                    title={sharedLoadMessage.twitter.text}
                    via={sharedLoadMessage.twitter.via}
                    hashtags={renderTwitterHashtags(sharedLoadMessage) ?? []}
                    style={style}
                  >
                    <XIcon size={30} round={true} iconFillColor={Color.BLACK} bgStyle={{ fill: Color.GRAY_LIGHT }} />
                    <div id="twitter" style={{ marginLeft: 10 }}>
                      {t(T.findLoads_findLoads_x)}
                    </div>
                  </TwitterShareButton>
                </ShareActionButton>
                <CopyToClipboard textToCopy={sharedLoadMessage.url} />
              </>
            ) : null}
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                <CircularProgress id="progress_bar" size={20} color="secondary" />
              </div>
            ) : null}
            {error && !isLoading ? <LoadSharingError>{t(T.error_try_again_error)}</LoadSharingError> : null}
          </BasePopup>
        ),
        width: PopupSizes.SMALL,
        isDismissible: true,
        onBackdropClick: closePopup,
      });
    }
  }, [isReadyToShowResponse, sharedLoadMessage, isLoading, error]);

  return null;
};

export const showErrorPopup = (popupContext: PopupTracking | undefined) => {
  if (popupContext) {
    popupContext.openingPopup({
      body: (
        <BasePopup
          title={t(T.postLoads_popUps_error_title)}
          buttons={[
            <PopupOkButton
              testID={'server_response_ok'}
              key={'server_response_ok'}
              action={popupContext.closingPopup}
            />,
          ]}
        >
          {t(T.error_message_error)}
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  }
};

export const showEmailPopup = (popupContext: PopupTracking | undefined, load: Load | undefined) => {
  if (load && popupContext) {
    popupContext.openingPopup({
      body: (
        <EmailFormPopup
          loadID={load.id}
          shouldShowOptionsAndBidSection={isLoadUnbookedAndAvailable(load.metadata?.userdata?.progress)}
        />
      ),
      isDismissible: false,
      width: PopupSizes.LARGE,
    });
  }
};

export const showHelpPopup = (popupContext: PopupTracking | undefined) => {
  if (popupContext) {
    popupContext.openingPopup({
      body: (
        <BasePopup
          title={t(T.findLoads_loadDetails_permissions_modal_title)}
          buttons={[
            <PopupCallButton
              testID={'call'}
              key={'call'}
              label={t(T.findLoads_loadDetails_permissions_modal_callSupport)}
            />,
            <PopupCancelButton testID={'cancel'} key={'cancel'} action={popupContext.closingPopup} />,
          ]}
        >
          {t(T.findLoads_loadDetails_permissions_modal_message)}
        </BasePopup>
      ),
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  }
};

export const showManagePlanPopup = (popupContext: PopupTracking | undefined, onClickManagePlan: () => void) => {
  if (popupContext) {
    popupContext.openingPopup({
      body: (
        <BasePopup
          title={t(T.common_upgradePlanPopup_title)}
          buttons={[
            <PopupPrimaryButton
              testID="manage_plan_button"
              key={t(T.common_upgradePlanPopup_button)}
              label={t(T.common_upgradePlanPopup_button)}
              action={onClickManagePlan}
            />,
          ]}
        >
          {t(T.common_upgradePlanPopup_mileageCalculator)}
        </BasePopup>
      ),
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  }
};

export const showVhubBookPopup = (popupContext: PopupTracking | undefined, load: Load | undefined) => {
  if (load && load.vHub) {
    track(createVhubBookingTrackData(VhubEvent.BookItWith, load.id));
    popupContext?.openingPopup({
      body: <VhubBookWithPopup loadID={load.id} vHubURL={load.vHub.url} />,
      width: PopupSizes.MEDIUM,
    });
  }
};

export const onboardedConfirmation = (onConfirm: () => void, popupContext: PopupTracking) => {
  const onOnboardedYes = () => {
    onConfirm();
    popupContext.closingPopup();
  };
  popupContext.openingPopup({
    body: (
      <BasePopup
        title={t(T.findLoads_loadDetails_onBoardedPopUp_header)}
        buttons={[
          <PopupPrimaryButton
            testID="yes_onboarded"
            key="yes_onboarded"
            action={onOnboardedYes}
            label={t(T.findLoads_loadDetails_onBoardedPopUp_button)}
          />,
        ]}
        onClose={popupContext.closingPopup}
      >
        {t(T.findLoads_loadDetails_onBoardedPopUp_content)}
      </BasePopup>
    ),
    width: PopupSizes.SMALL,
    isDismissible: false,
  });
};

export const showStartOnboardingPopup = (popupContext: PopupTracking, onboardingUrl: string, companyName: string) => {
  popupContext.openingPopup({
    body: (
      <BasePopup
        title={t(T.common_loadDetails_onboarding_popup_title)}
        buttons={[
          <MultipurposeButton
            id="proceed"
            variant="contained"
            key={'start_onboarding_proceed'}
            text={t(T.common_loadDetails_onboarding_popup_proceed)}
            color="primary"
            href={onboardingUrl}
            target="_blank"
            onClick={() => {
              popupContext.closingPopup();
            }}
          />,
        ]}
      >
        <div style={{ whiteSpace: 'pre-line' }} id="content">
          {`${t(T.common_loadDetails_onboarding_popup_message_start)}${companyName}${t(
            T.common_loadDetails_onboarding_popup_message_end
          )}`}
        </div>
      </BasePopup>
    ),
    width: PopupSizes.SMALL,
    isDismissible: true,
    hasCloseButton: true,
  });
};
export enum verifyDotPopupType {
  bookNow = 'BookNow',
  bid = 'Bid',
}
export const showVerifyDOTPopup = (
  popupContext: PopupTracking,
  action: () => void,
  type: verifyDotPopupType,
  dotNumber?: string | number
) => {
  popupContext.openingPopup({
    body: <VerifyDOTPopup action={action} type={type} dotNumber={dotNumber} closingPopup={popupContext.closingPopup} />,
    width: PopupSizes.SMALL,
    isDismissible: true,
    hasCloseButton: true,
  });
};

export const VerifyDOTPopup: React.FC<{
  action: () => void;
  type: verifyDotPopupType;
  closingPopup: () => void;
  dotNumber?: string | number;
}> = (props) => {
  const { action, type, dotNumber, closingPopup } = props;
  const isBookNow = type == verifyDotPopupType.bookNow;
  const title = t(
    isBookNow ? T.common_bookNow_dotVerification_popup_title : T.common_bids_dotVerification_popup_title,
    { dot: dotNumber }
  );

  const label = t(
    isBookNow ? T.common_bookNow_dotVerification_popup_button : T.common_bids_dotVerification_popup_button
  );

  const content = t(
    isBookNow ? T.common_bookNow_dotVerification_popup_content : T.common_bids_dotVerification_popup_content
  );

  return (
    <BasePopup
      title={title}
      buttons={[
        <PopupPrimaryButton
          testID={'verify_link_button'}
          key={'verify_link_button'}
          label={label}
          action={() => {
            action();
            closingPopup();
          }}
        />,
      ]}
    >
      {content}
    </BasePopup>
  );
};

const renderTwitterHashtags = (sharedLoadMessage?: SharedLoadMessage) => {
  if (sharedLoadMessage) {
    return words(sharedLoadMessage.twitter.hashtags);
  }
  return null;
};

const CopyToClipboard = ({ textToCopy }: { textToCopy: string }) => {
  const onCopy = () => copyToClipboard(textToCopy);
  return (
    <CopyToClipboardButton id="copy_to_clipboard" onClick={onCopy}>
      <IconContainer>
        <CopyIcon />
      </IconContainer>
      <CopyToClipboardText>{t(T.findLoads_loadDetails_copyToClipboard)}</CopyToClipboardText>
    </CopyToClipboardButton>
  );
};

import { BaseClient } from '@common/client/BaseClient';
import { LoadTemplateResponse } from '@common/model/LoadTemplates';
import { EmptyResponse } from '@common/redux/Base';
import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';

export interface DeleteLoadTemplatesRequest {
  excludeIds: string[];
}

export class LoadTemplatesClient extends BaseClient {
  fetchLoadTemplates$ = (loadTemplatesRequest: PaginatedRequest) =>
    this.api.get$<LoadTemplateResponse>(`/loadTemplates`, loadTemplatesRequest);
  deleteLoadTemplate$ = (templateId: string) => this.api.delete$(`/loadTemplates/${templateId}`, {});
  deleteLoadTemplates$ = (request: DeleteLoadTemplatesRequest) =>
    this.api.post$<EmptyResponse>('/loadTemplates/filter/delete', request);
}

import { capitalize, filter, isEmpty, isNil, join, startCase, toLower } from 'lodash';

import { Address, Company, DocketNumberVerificationDetails } from '@common/model';

export const FORCE_VERIFICATION_URL_QUERY = 'forceVerification';
export const INITIAL_DOT_NUMBER_URL_QUERY = 'initialDotNumber';

export const stringifyAddress = ({ city, state, addressLine1, addressLine2, country, zipCode }: Address) => {
  const addressValues = [
    startCase(toLower(addressLine1)),
    startCase(toLower(addressLine2)),
    capitalize(city),
    state,
    zipCode,
    country,
  ];
  return join(
    filter(addressValues, (item) => !isNil(item) && !isEmpty(item)),
    ', '
  );
};

export const volpeRecordToAddress = (record: DocketNumberVerificationDetails): Address => ({
  city: record.businessAddress_City,
  state: record.businessAddress_StateCode,
  country: record.businessAddress_CountryCode,
  zipCode: record.businessAddress_ZipCode,
  addressLine1: record.businessAddress_POBoxStreet,
  addressLine2: '',
});

export const getCompanyFromVolpeRecord = (volpeRecord: DocketNumberVerificationDetails): Partial<Company> => {
  const address = volpeRecordToAddress(volpeRecord);
  return {
    name: startCase(toLower(volpeRecord.legalName)),
    mcNumber: volpeRecord.docketNumber,
    phone: {
      number: volpeRecord.businessAddress_Telephone,
    },
    address: {
      city: capitalize(address.city),
      state: address.state,
      country: address.country,
      addressLine1: startCase(toLower(address.addressLine1)),
      addressLine2: startCase(toLower(address.addressLine2)),
      zipCode: address.zipCode,
    },
  };
};

import React, { ComponentProps, RefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { concat, isEmpty, map, noop, reduce, sortBy } from 'lodash';
import ReactResizeDetector from 'react-resize-detector';

import { FragmentPortalDestination } from '@component/fragmentPortal';
import { useIsWindowScrollMode } from '@component/main/mainHooks';
import { useWindowHeight } from '@util/hooks';

import { FixedAreaEntries, FixedAreaWithKey, useFixedAreaRenderContext } from './FixedAreaRenderContext';
import { useIsKeyboardOpen } from './KeyboardOpen';
import { SafeBottomInsetSpace } from './SafeBottomInsetSpace';

interface Props {
  id?: string;
  style?: React.CSSProperties;
  collapsibleRef?: RefObject<HTMLDivElement>;
}

export const HeaderHeight = React.createContext({
  headerHeight: 0,
  setHeaderHeight: noop,
  setExtraHeaderHeight: noop,
});

export const useClientHeight = (scrollElement: HTMLElement | null) => {
  const isWindowScrollMode = useIsWindowScrollMode();
  const { headerHeight } = useContext(HeaderHeight);
  const windowHeight = useWindowHeight();
  return isWindowScrollMode ? windowHeight - headerHeight : scrollElement?.clientHeight;
};

export const useTrackResizingHeight = (): [number, (width: number, height: number) => void] => {
  const [height, setHeight] = useState(0);

  const onResize = useCallback((width, height) => {
    setHeight(height);
  }, []);

  return [height, onResize];
};

const ResizeDetectorWithReset: React.FC<ComponentProps<typeof ReactResizeDetector>> = (props) => {
  useEffect(
    () => () => {
      props.onResize?.(0, 0);
    },
    []
  );
  return <ReactResizeDetector {...props} />;
};

const useGetHeaders = (allHeaders: FixedAreaEntries) =>
  useMemo(
    () =>
      reduce(
        sortBy(allHeaders, (item) => item.index),
        (acc, el) => {
          if (el.collapseOnScroll) {
            acc.collapsibleHeaders.push(el);
          } else if (acc.collapsibleHeaders.length === 0) {
            acc.fixedHeaders.push(el);
          } else {
            acc.hangingHeaders.push(el);
          }
          return acc;
        },
        {
          fixedHeaders: [],
          collapsibleHeaders: [],
          hangingHeaders: [],
        } as {
          fixedHeaders: FixedAreaWithKey[];
          collapsibleHeaders: FixedAreaWithKey[];
          hangingHeaders: FixedAreaWithKey[];
        }
      ),
    [allHeaders]
  );

/** Render the headers for Window scroll mode */
export const FixedHeaderPortalDestination: React.FC<Props> = ({ id, style, collapsibleRef }) => {
  const [fixedHeight, onFixedResize] = useTrackResizingHeight();
  const [collapsibleHeight, onCollapsibleResize] = useTrackResizingHeight();
  const [hangingHeaderHeight, onHangingHeaderHeightResize] = useTrackResizingHeight();
  const scopeContext = useFixedAreaRenderContext();

  const { fixedHeaders, collapsibleHeaders, hangingHeaders } = useGetHeaders(scopeContext.headers);
  const { isKeyboardOpen } = useIsKeyboardOpen();

  useHeaderHeight(fixedHeight + collapsibleHeight + hangingHeaderHeight);

  if (isEmpty(fixedHeaders) && isEmpty(collapsibleHeaders)) {
    return null;
  }

  return (
    <>
      <div id={id} style={{ position: isKeyboardOpen ? 'absolute' : 'fixed', zIndex: 2, left: 0, right: 0, ...style }}>
        {!isEmpty(fixedHeaders) ? (
          <div id="fixedheaders">
            <ResizeDetectorWithReset handleHeight={true} onResize={onFixedResize} />
            {map(fixedHeaders, (item) => (
              <FragmentPortalDestination key={item.key} fragmentHolder={item.fragmentHolder} />
            ))}
          </div>
        ) : null}
        {!isEmpty(collapsibleHeaders) ? (
          <div id="collapsibleheaders" style={{ position: 'relative', zIndex: -1 }} ref={collapsibleRef}>
            <ResizeDetectorWithReset handleHeight={true} onResize={onCollapsibleResize} />
            {map(collapsibleHeaders, (item) => (
              <FragmentPortalDestination key={item.key} fragmentHolder={item.fragmentHolder} />
            ))}

            {!isEmpty(hangingHeaders) ? (
              <div id="collapsible_header_suffix" style={{ position: 'absolute', zIndex: -1, width: '100%' }}>
                <ResizeDetectorWithReset handleHeight={true} onResize={onHangingHeaderHeightResize} />
                {map(hangingHeaders, (item) => (
                  <FragmentPortalDestination key={item.key} fragmentHolder={item.fragmentHolder} />
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div
        id={id ? `${id}_shadow` : undefined}
        style={{ height: fixedHeight + collapsibleHeight + hangingHeaderHeight, width: '100%' }}
      />
    </>
  );
};

export const FixedFooterPortalDestination: React.FC<Props> = ({ id, style }) => {
  const [shadowHeight, onResize] = useTrackResizingHeight();
  const scopeContext = useFixedAreaRenderContext();

  const footers = sortBy(scopeContext.footers, (item) => item.index);

  if (isEmpty(footers)) {
    return null;
  }

  return (
    <>
      <div id={id ? `${id}_shadow` : undefined} style={{ height: shadowHeight, width: '100%' }} />
      <div id={id} style={{ position: 'fixed', zIndex: 2, bottom: 0, left: 0, right: 0, ...style }}>
        <ReactResizeDetector handleHeight={true} onResize={onResize} />
        {map(footers, (item) => (
          <FragmentPortalDestination key={item.key} fragmentHolder={item.fragmentHolder} />
        ))}
        <SafeBottomInsetSpace backgroundColor={isEmpty(footers) ? undefined : '#fffe'} />
      </div>
    </>
  );
};

export const CollapsibleHeaderPortalDestination: React.FC<Props> = ({ id, style, collapsibleRef }) => {
  const [collapsibleHeight, onCollapsibleResize] = useTrackResizingHeight();
  const [hangingHeaderHeight, onHangingHeaderHeightResize] = useTrackResizingHeight();

  const scopeContext = useFixedAreaRenderContext();

  useExtraHeaderHeight(collapsibleHeight + hangingHeaderHeight);

  const { collapsibleHeaders, hangingHeaders } = useGetHeaders(scopeContext.headers);

  if (isEmpty(collapsibleHeaders)) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <div id="collapsibleheader" ref={collapsibleRef} style={{ position: 'absolute', zIndex: 1, width: '100%' }}>
        <div id={id} style={{ position: 'relative', zIndex: 1, top: 0, left: 0, right: 0, ...style }}>
          <ResizeDetectorWithReset handleHeight={true} onResize={onCollapsibleResize} />
          {map(collapsibleHeaders, (item) => (
            <FragmentPortalDestination key={item.key} fragmentHolder={item.fragmentHolder} />
          ))}
        </div>
        {!isEmpty(hangingHeaders) ? (
          <div id="collapsible_header_suffix" style={{ position: 'absolute', zIndex: 0, width: '100%' }}>
            <ResizeDetectorWithReset handleHeight={true} onResize={onHangingHeaderHeightResize} />
            {map(hangingHeaders, (item) => (
              <FragmentPortalDestination key={item.key} fragmentHolder={item.fragmentHolder} />
            ))}
          </div>
        ) : null}
      </div>
      <div
        id={id ? `${id}_shadow` : undefined}
        style={{ height: collapsibleHeight + hangingHeaderHeight, width: '100%' }}
      ></div>
    </div>
  );
};

export const BlockHeaderPortalDestination: React.FC<Props & { includesCollapsible: boolean }> = ({
  id,
  style,
  includesCollapsible,
}) => {
  const scopeContext = useFixedAreaRenderContext();
  const [height, onResize] = useTrackResizingHeight();

  const { fixedHeaders, collapsibleHeaders, hangingHeaders } = useGetHeaders(scopeContext.headers);

  const allHeaders = includesCollapsible ? concat(fixedHeaders, collapsibleHeaders, hangingHeaders) : fixedHeaders;

  useHeaderHeight(height);

  if (isEmpty(allHeaders)) {
    return null;
  }

  return (
    <div id={id} style={style}>
      <ResizeDetectorWithReset handleHeight={true} onResize={onResize} />
      {map(allHeaders, (item) => (
        <FragmentPortalDestination key={item.key} fragmentHolder={item.fragmentHolder} />
      ))}
    </div>
  );
};

export const BlockFooterPortalDestination: React.FC<Props> = ({ id, style }) => {
  const scopeContext = useFixedAreaRenderContext();

  const footers = sortBy(scopeContext.footers, (item) => item.index);

  if (isEmpty(footers)) {
    return null;
  }

  return (
    <div id={id} style={{ zIndex: 0, ...style }}>
      {map(footers, (item) => (
        <FragmentPortalDestination key={item.key} fragmentHolder={item.fragmentHolder} />
      ))}
    </div>
  );
};

const useHeaderHeight = (height: number) => {
  const { setHeaderHeight } = useContext(HeaderHeight);

  useEffect(() => {
    setHeaderHeight(height);
  }, [height]);
};

const useExtraHeaderHeight = (height: number) => {
  const { setExtraHeaderHeight } = useContext(HeaderHeight);

  useEffect(() => {
    setExtraHeaderHeight(height);
  }, [height]);
};

import { ApiResponse, ApisauceInstance, create } from 'apisauce';
import { from as from$, Observable } from 'rxjs';
import { map as map$, share as share$, tap } from 'rxjs/operators';

import { LBHeaders } from '@common/api';
import { GenId } from '@util/GenId';
import { defaultMemberApiHeaders } from '@webApi/ApiServiceHelper';
import * as CookieService from '@webApi/CookiesHelper';

let instance: TokenRefresher | null = null;
class TokenRefresher {
  private tokenResponse$: Observable<ApiResponse<any>> | null = null;
  private api: ApisauceInstance;
  constructor() {
    if (!instance) {
      instance = this;
      instance.api = create({
        baseURL: '/',
        headers: defaultMemberApiHeaders,
      });
      if (process.env.NODE_ENV === 'development') {
        // build process will strip out unused code in production
        // hence the dynamic import
        import('@util/ReactotronConfig').then(({ apisauce }) => {
          instance?.api.addMonitor(apisauce);
        });
      }
    }
    return instance;
  }

  public isInProgress = () => !!this.tokenResponse$;

  public getRefreshedToken$ = () => {
    if (!this.tokenResponse$) {
      this.tokenResponse$ = this.refreshToken$()
        .pipe(
          tap({
            complete: () => {
              this.tokenResponse$ = null;
            },
          })
        )
        .pipe(share$());
    }
    return this.tokenResponse$;
  };

  private refreshToken$ = (): Observable<ApiResponse<any>> => {
    const url = addCacheBustingParameter('/refreshToken');
    return from$(
      this.api.post(
        url || '',
        {},
        {
          headers: {
            [LBHeaders.DeviceID]: CookieService.deviceID(),
            '123LB-Correlation-Id': GenId.generateCorrelationId(),
          },
        }
      )
    ).pipe(
      map$((response: ApiResponse<any>) => {
        if (response.ok && response.data && response.data.secondsToExpire) {
          CookieService.setAccessTokenExpiry(response.data.secondsToExpire);
        }
        return response;
      })
    );
  };
}

const addCacheBustingParameter = (url: string) =>
  url == null ? null : url + (url.indexOf('?') > -1 ? '&' : '?') + 'rnd=' + GenId.generateCacheBustingParameter();

export default new TokenRefresher();

import styled from 'styled-components';

import { Tab as MaterialTab } from '@material-ui/core';

import { Color } from '@style/Color';
import { ThemeProps, withTheme } from '@style/WithTheme';

export const Tab = withTheme()(styled(MaterialTab)`
  && {
    text-transform: inherit;
    font-weight: bold;
    font-size: 14px;
    background-color: ${Color.WHITE};
    border-bottom: 2px solid ${Color.GRAY_SMOKE};
    color: ${(props: ThemeProps) => props.theme.palette.text.primary};
    min-width: 0;
  }
`);

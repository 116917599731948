import { clone, intersection } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$, Observable, of as of$ } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { UserClient } from '@common/client/UserClient';
import { CompanyCategory, LoadDetailsRoles, Roles, UserAlerts, UserInfo, UserProfile, UserType } from '@common/model';
import { BaseState, Response } from '@common/redux/Base';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';

const UPDATE_LAST_ID = 'UPDATE_LAST_ID';
const FETCH_USER = 'FETCH_USER';
const USER_ALERTS = 'USER_ALERTS';
const UPDATE_USER = 'UPDATE_USER';
const USER_UPDATED = 'USER_UPDATED';
const USER_ALERTS_FULFILLED = 'USER_ALERTS_FULFILLED';
const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED';
const USER_INBOX_UPDATED = 'USER_INBOX_UPDATED';
const FETCH_GUEST_AVAILABLE_LOAD_VIEW_COUNT = 'FETCH_GUEST_AVAILABLE_LOAD_VIEW_COUNT';
const FETCH_GUEST_AVAILABLE_LOAD_VIEW_COUNT_FULFILLED = 'FETCH_GUEST_AVAILABLE_LOAD_VIEW_COUNT_FULFILLED';

export type UserResponse = Response<UserProfile>;
export type UserAlertsResponse = Response<UserAlerts>;
export type UserUpdateResponse = Response<UserInfo>;

interface UpdateLastId extends Action {
  lastMaintenanceIdVersion: number;
}

interface FetchUser extends Action {
  redirectOnAuthentication: boolean;
  forceRefresh: boolean;
}

interface UpdateUser extends Action {
  userInfo: UserInfo;
}

interface UserUpdatedAction extends Action {
  response: UserUpdateResponse;
}

interface FetchUserProfile extends Action {
  profile: UserResponse;
}

interface UserAlertsFulfilled extends Action {
  userAlerts: UserAlertsResponse;
}

interface UserInboxMessageUpdated extends Action {
  inboxUnreadCount: number;
}

interface UpdateAvailableLoadViewCountAction extends Action {
  response: Response<{ availableLoadViewCount: number }>;
}

export const updateLastId = (response: number): UpdateLastId => ({
  type: UPDATE_LAST_ID,
  lastMaintenanceIdVersion: response,
});
export const fetchUser = (redirectOnAuthentication: boolean = true, forceRefresh: boolean = false): FetchUser => ({
  type: FETCH_USER,
  redirectOnAuthentication: redirectOnAuthentication,
  forceRefresh: forceRefresh,
});

export const fetchAvailableLoadViewCount = () => ({
  type: FETCH_GUEST_AVAILABLE_LOAD_VIEW_COUNT,
});

const fetchAvailableLoadViewCountFulfilled = (
  response: Response<{ availableLoadViewCount: number }>
): UpdateAvailableLoadViewCountAction => ({
  type: FETCH_GUEST_AVAILABLE_LOAD_VIEW_COUNT_FULFILLED,
  response: response,
});

export const updateUserInfo = (userInfo: UserInfo): UpdateUser => ({
  type: UPDATE_USER,
  userInfo: userInfo,
});

export const fetchUserAlerts = () => ({
  type: USER_ALERTS,
});

export const fetchUserFulfilled = (response: UserResponse): FetchUserProfile => ({
  type: FETCH_USER_FULFILLED,
  profile: response,
});

export const fetchUserAlertsFulfilled = (response: UserAlertsResponse): UserAlertsFulfilled => ({
  type: USER_ALERTS_FULFILLED,
  userAlerts: response,
});

export const fetchUserInboxMessageUpdated = (inboxUnreadCount: number): UserInboxMessageUpdated => ({
  type: USER_INBOX_UPDATED,
  inboxUnreadCount: inboxUnreadCount,
});

const userInfoUpdated = (response: UserUpdateResponse): UserUpdatedAction => ({
  type: USER_UPDATED,
  response: response,
});

export interface UserState extends BaseState {
  profile?: UserResponse;
  userAlerts?: UserAlertsResponse;
  roles?: Set<Roles>;
  accesses: LoadDetailsRoles;
  update: {
    isUpdatingInfo: boolean;
    updatedSuccessfully?: boolean;
    updateTime?: number;
  };
  // @FIXME: This should be stored in a Settings-related state, not here
  // (Note that this value is used not only in MOB and MEM, but in Intg as well)
  lastMaintenanceIdVersion: number;
  profileFetchedAt?: number;
  userType: UserType;
}

export const initialUserState = {
  isLoading: false,
  update: {
    isUpdatingInfo: false,
  },
  accesses: {
    isMapTollsAvailable: false,
    isCreditRatingAvailable: false,
    isRateCheckAvailable: false,
    isCargoChiefRateAvailable: false,
    isLoadPlannerAvailable: false,
    isInternalAdmin: false,
  },
  lastMaintenanceIdVersion: 0,
  userType: UserType.Carrier,
};

export const userReducer = (state: UserState = initialUserState, action: Action): UserState => {
  switch (action.type) {
    case FETCH_USER_FULFILLED: {
      const profileAction = action as FetchUserProfile;
      let roles;
      let accesses = state.accesses;
      let userType = state.userType;

      if (profileAction.profile.payload) {
        const payload = profileAction.profile.payload;
        const rolesArr = payload.roles;
        roles = new Set(rolesArr);
        accesses = {
          isMapTollsAvailable: hasRoles(rolesArr, [Roles.Pcmiler]),
          isCreditRatingAvailable: hasRoles(rolesArr, [Roles.Guest, Roles.Premium, Roles.Website]),
          isRateCheckAvailable: hasRoles(rolesArr, [Roles.Guest, Roles.Ratecheck, Roles.Website, Roles.Internal]),
          isCargoChiefRateAvailable: hasRoles(rolesArr, [Roles.CargoChiefRate]),
          isLoadPlannerAvailable: hasRoles(rolesArr, [Roles.Loadplanner_beta, Roles.Loadplanner]),
          isInternalAdmin: hasRoles(rolesArr, [Roles.Admin, Roles.Internal]),
        };
        userType = deriveUserType(payload.companyCategory);
      }

      return {
        ...state,
        profile: profileAction.profile,
        roles: roles,
        isLoading: false,
        accesses: accesses,
        userType: userType,
        profileFetchedAt: Date.now(),
      };
    }
    case USER_ALERTS_FULFILLED: {
      const alertAction = action as UserAlertsFulfilled;
      return {
        ...state,
        userAlerts: alertAction.userAlerts,
        isLoading: false,
      };
    }
    case USER_ALERTS:
    case FETCH_USER:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_USER:
      return {
        ...state,
        update: {
          isUpdatingInfo: true,
          updatedSuccessfully: undefined,
        },
      };
    case USER_UPDATED: {
      const updateAction = action as UserUpdatedAction;
      const stateToUpdate = state;
      //Here we update the existing user profile information
      if (
        updateAction.response.success &&
        updateAction.response.payload &&
        stateToUpdate.profile &&
        stateToUpdate.profile.payload
      ) {
        stateToUpdate.profile.payload = mergeUserInfo(stateToUpdate.profile.payload, updateAction.response.payload);
      }
      return {
        ...stateToUpdate,
        update: {
          isUpdatingInfo: false,
          updatedSuccessfully: updateAction.response.success,
          updateTime: Date.now(),
        },
      };
    }
    case UPDATE_LAST_ID: {
      const updateIdAction = action as UpdateLastId;
      return {
        ...state,
        lastMaintenanceIdVersion: updateIdAction.lastMaintenanceIdVersion,
      };
    }
    case USER_INBOX_UPDATED: {
      const updatedInboxCount = action as UserInboxMessageUpdated;
      const stateToUpdate = state;
      if (stateToUpdate.userAlerts && stateToUpdate.userAlerts?.payload) {
        stateToUpdate.userAlerts.payload.messagesInfo.unreadMessagesCount = updatedInboxCount.inboxUnreadCount;
      }
      return stateToUpdate;
    }
    case FETCH_GUEST_AVAILABLE_LOAD_VIEW_COUNT_FULFILLED: {
      const updateAction = action as UpdateAvailableLoadViewCountAction;
      const stateToUpdate = state;
      if (
        updateAction.response.success &&
        updateAction.response.payload &&
        stateToUpdate.profile &&
        stateToUpdate.profile.payload
      ) {
        const userInfo = clone(stateToUpdate.profile.payload);
        userInfo.availableLoadViewCount = updateAction.response.payload.availableLoadViewCount;
        stateToUpdate.profile.payload = userInfo;
      }
      return stateToUpdate;
    }
    default:
      return state;
  }
};

const fetchUserEpic$ = (
  client: UserClient,
  action$: ActionsObservable<Action>,
  state$: StateObservable<{ user: UserState }>
) => {
  let isFetchingUser = false;
  return action$.ofType(FETCH_USER).pipe(
    mergeMap((action: FetchUser): Observable<Action> => {
      const user = state$.value.user;
      if (!action.forceRefresh && user && user.profile && user.profile.success) {
        return of$(fetchUserFulfilled({ success: user.profile.success, payload: user.profile.payload }));
      }
      if (isFetchingUser) {
        return of$({ type: '' });
      }
      isFetchingUser = true;
      return client.loadProfile$(action.redirectOnAuthentication, action.forceRefresh).pipe(
        map((response) => {
          isFetchingUser = false;
          if (response.success) {
            const successResp = response as ApiResponseSuccess<UserProfile>;
            return fetchUserFulfilled({ success: response.success, payload: successResp.data });
          } else {
            const error = response as ApiError;
            return fetchUserFulfilled({ success: response.success, error: error });
          }
        })
      );
    })
  );
};

const fetchUserAlertsEpic$ = (client: UserClient, action$: ActionsObservable<Action>) => {
  return action$.ofType(USER_ALERTS).pipe(
    mergeMap(() => {
      return client.alerts$().pipe(
        map((response: ApiResponse123<UserAlerts>) => {
          if (response.success) {
            const successResp = response as ApiResponseSuccess<UserAlerts>;
            return fetchUserAlertsFulfilled({ success: response.success, payload: successResp.data });
          } else {
            const error = response as ApiError;
            return fetchUserAlertsFulfilled({ success: response.success, error: error });
          }
        })
      );
    })
  );
};

export const updateUserEpic$ = (userClient: UserClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    UPDATE_USER,
    (action: UpdateUser) => userClient.updateUserInfo$(action.userInfo),
    userInfoUpdated
  );
};

const updateUserAvailableLoadViewCountEpic$ = (userClient: UserClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    FETCH_GUEST_AVAILABLE_LOAD_VIEW_COUNT,
    userClient.fetchAvailableLoadViewCount$,
    fetchAvailableLoadViewCountFulfilled
  );
};

export const createUserEpic = (api: Api) => {
  const userClient = new UserClient(api);

  return (action$: ActionsObservable<Action>, state$: StateObservable<any>) =>
    merge$(
      fetchUserEpic$(userClient, action$, state$),
      fetchUserAlertsEpic$(userClient, action$),
      updateUserEpic$(userClient, action$),
      updateUserAvailableLoadViewCountEpic$(userClient, action$)
    );
};

const mergeUserInfo = (prevUserInfo: UserProfile, userInfo: UserInfo) => {
  // doing merge like this because otherwise new userInfo without optional params
  // does not overwrite populated optional params in old userInfo
  const mergedUserInfo = clone(prevUserInfo);
  mergedUserInfo.firstName = userInfo.firstName;
  mergedUserInfo.lastName = userInfo.lastName;
  mergedUserInfo.email = userInfo.email;
  mergedUserInfo.phone = userInfo.phone;
  mergedUserInfo.phoneExt = userInfo.phoneExt;
  mergedUserInfo.mobile = userInfo.mobile;
  mergedUserInfo.primaryPhone = userInfo.primaryPhone;
  return mergedUserInfo;
};
const hasRoles = (currentRoles: Roles[], validRoles: Roles[]) => intersection(currentRoles, validRoles).length !== 0;

const deriveUserType = (companyCategory?: CompanyCategory) => {
  switch (companyCategory) {
    case CompanyCategory.BrokerCarrier:
      return UserType.BrokerCarrier;
    case CompanyCategory.PL3:
    case CompanyCategory.Broker:
    case CompanyCategory.FreightForwarder:
    case CompanyCategory.ShipperManufacturer:
      return UserType.BrokerShipper;
    default:
      return UserType.Carrier;
  }
};

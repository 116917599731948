import { Patch } from '@common/api';

import { LoadProgress } from './LoadUserdata';

// FIXME: this type shouldn't be like this. It should only be LoadProgress
export type LoadUserMetadataValue = boolean | LoadProgress;

export type LoadFeedbackRequest = Patch<LoadUserMetadataValue>;

export const generateLoadFeedbackRequest = (path: string, value?: LoadUserMetadataValue): LoadFeedbackRequest => ({
  op: 'replace',
  path: path,
  value: value ? value : true,
});

export enum LOAD_FEEDBACK_CATEGORY {
  NOT_INTERESTED = '/isNotInterested',
  NO_LONGER_AVAILABLE = '/isGone',
  PAY_TOO_LOW = '/isLowPayRate',
  DETAILS_INCORRECT = '/isWrongInfo',
  CALLED = '/isCalled',
  EMAILED = '/isEmailed',
  HIDDEN = '/isHidden',
  PROGRESS = '/progress',
}

import { of as of$ } from 'rxjs';
import { mergeMap as mergeMap$ } from 'rxjs/operators';

import { authenticationToApiOptions } from '@common/api/ApiOptions';
import { BaseClient } from '@common/client/BaseClient';
import {
  CreatePhoneVerificationResponse,
  NumberCategory,
  PhoneVerificationMethod,
  UserInfo,
  UserProfile,
  USERPROFILE_FIELDS,
  VerificationPointsReviewData,
  VerificationPointsTasksStatusesResponse,
} from '@common/model';

export class UserClient extends BaseClient {
  loadProfile$ = (redirectOnAuthentication: boolean = true, forceRefreshToken: boolean = false) =>
    this.api.get$<UserProfile>(
      '/profile',
      { fields: USERPROFILE_FIELDS },
      {},
      { ...authenticationToApiOptions(redirectOnAuthentication), forceRefreshToken: forceRefreshToken }
    );
  fetchAvailableLoadViewCount$ = () =>
    this.api.get$<{ availableLoadViewCount: number }>('/profile', { fields: 'availableLoadViewCount' }, {});
  //We need to fetch the contact info to update it (id required). THere is a ticket to create a new endpoint with it, keeping as is until then.
  updateUserInfo$ = (info: UserInfo) =>
    this.api.get$<{ id: number }>('/contacts', {}, {}).pipe(
      mergeMap$((result) => {
        return result.result(
          (data) => this.api.put$<UserInfo>(`/contacts/${data.id}`, info, {}),
          (error) => of$(error)
        );
      })
    );
  alerts$ = (redirectOnAuthentication: boolean = false) =>
    this.api.get$('/info', {}, {}, authenticationToApiOptions(redirectOnAuthentication));
  createPhoneVerification$ = (phoneVerificationGuid: string, type: PhoneVerificationMethod, category: NumberCategory) =>
    this.api.post$<CreatePhoneVerificationResponse>(`/phones/${phoneVerificationGuid}/requestVerificationCode`, {
      channel: type,
      category: category,
    });
  verifyPhoneCode$ = (code: string, guid: string, category: NumberCategory) =>
    this.api.post$<{}>(`/phones/${guid}/verifyCode`, { verificationCode: code, category: category });
  fetchVerificationPointsTasksStatuses$ = () =>
    this.api.get$<VerificationPointsTasksStatusesResponse>('/contacts/verifications', '');
  updateVerificationPointsReviewStatues$ = (data: VerificationPointsReviewData) =>
    this.api.mergePatch$<VerificationPointsTasksStatusesResponse>('/contacts/verifications', data);
}

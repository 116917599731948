import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { CompanyAvatarClient, UploadAvatarData } from '@common/client/CompanyAvatarClient';
import { createApiAction, createApiActionWithFetchData } from '@common/redux/Base';

import { createMergedReducer } from '../ReduxHelper';
import { Avatar } from './AvatarEpic';

const fetchCompanyAvatarAction = createApiAction<undefined, {}>('FETCH_COMPANY_AVATAR');

const fetchSmallCompanyAvatarAction = createApiAction<undefined, {}>('FETCH_SMALL_COMPANY_AVATAR');

const fetchSmallCompaniesAvatarAction = createApiActionWithFetchData<string, {}>('FETCH_SMALL_COMPANIES_AVATAR');

const uploadCompanyAvatarAction = createApiAction<UploadAvatarData, Avatar>('UPLOAD_COMPANY_AVATAR');

const deleteCompanyAvatarAction = createApiAction<undefined, undefined>('DELETE_COMPANY_AVATAR');

export const uploadCompanyAvatar = (documentMetadata: UploadAvatarData) =>
  uploadCompanyAvatarAction.fetchAction(documentMetadata);

export const fetchCompanyAvatar = () => fetchCompanyAvatarAction.fetchAction(undefined);

export const fetchSmallCompanyAvatar = () => fetchSmallCompanyAvatarAction.fetchAction(undefined);

export const fetchCompaniesAvatar = (id: string) => fetchSmallCompaniesAvatarAction.fetchAction(id);

export const deleteCompanyAvatar = () => deleteCompanyAvatarAction.fetchAction(undefined);

export enum AvatarType {
  Company = 'companyAvatar',
  Profile = 'profileAvatar',
}
export interface CompaniesAvatarInfo {
  isLoading: boolean;
  companiesAvatar: string | null;
}
export interface CompaniesAvatarState {
  isFetching: boolean;
  isFetchingForSmall: boolean;
  isFetchingForFull: boolean;
  /** Null is for if user has no avatar, Undefined means the avatar is not loaded yet. */
  currentAvatarSmall: string | undefined | null;
  currentAvatarFull: string | undefined | null;
  isUploadingAvatar: boolean;
  isUploadError: boolean | undefined;
  companiesAvatarMap: Map<string, CompaniesAvatarInfo>;
  isDeleteCompanyAvatarLoading: boolean;
  wasDeleteCompanyAvatarCompleted: boolean;
}

const initialState: CompaniesAvatarState = {
  isFetching: false,
  isFetchingForSmall: false,
  isFetchingForFull: false,
  currentAvatarSmall: undefined,
  currentAvatarFull: undefined,
  isUploadingAvatar: false,
  isUploadError: false,
  companiesAvatarMap: new Map(),
  isDeleteCompanyAvatarLoading: false,
  wasDeleteCompanyAvatarCompleted: false,
};

export const companyAvatarReducer = createMergedReducer(initialState, [
  fetchSmallCompaniesAvatarAction.initiateCase((state, action) => {
    const companiesAvatarInfo = state.companiesAvatarMap.get(action.data) ?? ({} as CompaniesAvatarInfo);
    companiesAvatarInfo.isLoading = true;
    state.companiesAvatarMap.set(action.data, companiesAvatarInfo);
  }),
  fetchSmallCompaniesAvatarAction.completeCase((state, action) => {
    const companiesAvatarInfo = state.companiesAvatarMap.get(action.fetchData ?? '') ?? ({} as CompaniesAvatarInfo);
    companiesAvatarInfo.isLoading = false;
    if (action.response.success) {
      const mimeType = action.response.headers?.['content-type'];
      companiesAvatarInfo.companiesAvatar = mimeType ? `data:${mimeType};base64,${action.response.payload}` : null;
    }
  }),

  fetchCompanyAvatarAction.initiateCase((state) => {
    state.isFetchingForFull = true;
  }),

  fetchCompanyAvatarAction.completeCase((state, action) => {
    if (action.response.success) {
      const mimeType = action.response.headers?.['content-type'];
      state.currentAvatarFull = mimeType ? `data:${mimeType};base64,${action.response.payload}` : null;
      state.isFetchingForFull = false;
    } else {
      state.currentAvatarFull = null;
    }
    state.isFetchingForFull = false;
  }),

  fetchSmallCompanyAvatarAction.initiateCase((state) => {
    state.isFetchingForSmall = true;
  }),

  fetchSmallCompanyAvatarAction.completeCase((state, action) => {
    if (action.response.success) {
      const mimeType = action.response.headers?.['content-type'];
      state.currentAvatarSmall = mimeType ? `data:${mimeType};base64,${action.response.payload}` : null;
      state.isFetchingForSmall = false;
    } else {
      state.currentAvatarSmall = null;
    }
    state.isFetchingForSmall = false;
  }),

  uploadCompanyAvatarAction.initiateCase((state) => {
    state.isUploadingAvatar = true;
    state.isUploadError = undefined;
  }),

  uploadCompanyAvatarAction.completeCase((state, action) => {
    if (action.response.success) {
      state.isUploadError = false;
      state.isUploadingAvatar = false;
      state.currentAvatarFull = undefined;
      state.currentAvatarSmall = undefined;
    } else {
      state.isUploadError = true;
      state.isUploadingAvatar = false;
    }
  }),
  deleteCompanyAvatarAction.initiateCase((state) => {
    state.isDeleteCompanyAvatarLoading = true;
    state.wasDeleteCompanyAvatarCompleted = false;
  }),
  deleteCompanyAvatarAction.completeCase((state, action) => {
    state.isDeleteCompanyAvatarLoading = false;
    if (action.response.success) {
      state.wasDeleteCompanyAvatarCompleted = true;
    } else {
      state.wasDeleteCompanyAvatarCompleted = false;
    }
  }),
]);

export const createCompanyAvatarEpic = (api: Api) => {
  const client = new CompanyAvatarClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchCompanyAvatarAction.createEpic$(action$, () => client.fetchCompanyAvatar$('Full')),
      fetchSmallCompanyAvatarAction.createEpic$(action$, () => client.fetchCompanyAvatar$('Small')),
      fetchSmallCompaniesAvatarAction.createEpic$(action$, client.fetchOtherCompanyAvatar$),
      uploadCompanyAvatarAction.createEpic$(action$, client.uploadCompanyAvatar$),
      deleteCompanyAvatarAction.createEpic$(action$, client.deleteCompanyAvatar$)
    );
};

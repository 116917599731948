import React, { CSSProperties } from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';
import { FontSize, FontWeight } from '@style/StyleConstants';
import { withTheme } from '@style/WithTheme';

import { default as ErrorBlockSVG } from './error-block.svg?react';
import { default as SuccessIcon } from './icon_success.svg?react';
import { default as WarningIcon } from './icon_warning.svg?react';
import { default as InfoSVG } from './info.svg?react';
import { default as OnboardingSVG } from './onboarding.svg?react';

export enum BannerType {
  Information = 'Information',
  Error = 'Error',
  Success = 'Success',
  Warning = 'Warning',
  Onboarding = 'Onboarding',
}

const backgroundColorForBannerType = (bannerType: BannerType) => {
  switch (bannerType) {
    case BannerType.Error:
      return Color.RED_BANNER_ERROR;
    case BannerType.Warning:
      return Color.ORANGE_MARIGOLD;
    case BannerType.Onboarding:
    case BannerType.Success:
      return Color.GREEN_LIGHT;
    default:
      return Color.BLUE_AZURE;
  }
};

const underlineColorForBannerType = (bannerType: BannerType) => {
  switch (bannerType) {
    case BannerType.Error:
      return Color.RED_APPLE;
    case BannerType.Warning:
      return Color.ORANGE_BANNERBORDER;
    case BannerType.Onboarding:
    case BannerType.Success:
      return Color.GREEN_MAIN;
    default:
      return Color.BLUE_SKY;
  }
};

const Container = styled(({ bannerType, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  background-color: ${({ bannerType }: { bannerType: BannerType }) => backgroundColorForBannerType(bannerType)};
`;
interface FlexContainerProps {
  isFromBookNow: boolean;
}

const FlexContainer = withTheme()(styled.div`
  padding: ${(props: FlexContainerProps) => (props.isFromBookNow ? '12px 16px 4px 16px' : '12px 16px')};
  display: flex;
`);

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  margin-left: 16px;
`;

const Title = styled.span`
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 8px;
`;

const Content = styled.span`
  font-size: ${FontSize.ContentDetail}px;
`;

const Underline = styled(({ bannerType, ...rest }) => <div {...rest} />)`
  height: 2px;
  background-color: ${({ bannerType }: { bannerType: BannerType }) => underlineColorForBannerType(bannerType)};
`;

interface Props {
  bannerType: BannerType;
  title?: string;
  content: React.ReactElement;
  isBookNowBanner?: boolean;
  noIcon?: boolean;
  style?: CSSProperties;
}

const iconForBannerType = (bannerType: BannerType) => {
  switch (bannerType) {
    case BannerType.Error:
      return <ErrorBlockSVG id="block" />;
    case BannerType.Warning:
      return <WarningIcon id="warning" />;
    case BannerType.Success:
      return <SuccessIcon id="success" />;
    case BannerType.Onboarding:
      return <OnboardingSVG id="onboarding" />;
    default:
      return <InfoSVG id="info" />;
  }
};

export const Banner: React.FC<Props> = (props) => (
  <Container id="banner" bannerType={props.bannerType}>
    <FlexContainer isFromBookNow={props.isBookNowBanner ?? false}>
      {props.noIcon ? null : <div>{iconForBannerType(props.bannerType)}</div>}
      <InformationContainer style={props.style}>
        {props.title ? <Title id="title">{props.title}</Title> : null}
        <Content id="info">{props.content}</Content>
      </InformationContainer>
    </FlexContainer>
    <Underline bannerType={props.bannerType} />
  </Container>
);

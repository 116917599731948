import { Observable } from 'rxjs';

import { ApiResponse123 } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';
import { TermsAndConditions } from '@common/model/TermsAndConditions';

export class TermsAndConditionsClient extends BaseClient {
  fetchTermsOfService$ = (): Observable<ApiResponse123<TermsAndConditions>> =>
    this.api.get$('/legal/termsofservice/current', {});
  fetchPrivacyPolicy$ = (): Observable<ApiResponse123<TermsAndConditions>> =>
    this.api.get$('/legal/privacypolicy/current', {});
}

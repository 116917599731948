import { createAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

/**
 * Follows Cobrowse's SessionState data structure
 */
export enum SessionStates {
  pending = 'pending',
  authorizing = 'authorizing',
  ended = 'ended',
  active = 'active',
  inactive = 'inactive',
}

/**
 * Follows Cobrowse's SessionLike data structure
 */
export interface ScreenSharingSession {
  id?: string;
  state: SessionStates;
  code?: string;
  full_device?: boolean;
  agent?: {
    name?: string;
    id?: string;
  };
}

export interface ScreenSharingState {
  session?: ScreenSharingSession;
}

const initialState: ScreenSharingState = {
  session: undefined,
};

const updateScreenSharingSessionAction = createAction<ScreenSharingSession | undefined>(
  'UPDATE_SCREEN_SHARING_SESSION'
);
export const updateScreenSharingSession = updateScreenSharingSessionAction.action;

export const ScreenSharingReducer = createMergedReducer(initialState, [
  updateScreenSharingSessionAction.addCase((state, { data: newSession }) => {
    if (state.session && newSession && state.session.id === newSession.id && !newSession.code) {
      // session updates from cobrowse do not always keep the code (if there was one)
      // this appears to be a bug, so we have this workaround to pass it between updates
      state.session = {
        ...newSession,
        code: state.session.code,
      };
    } else {
      state.session = newSession;
    }
  }),
]);

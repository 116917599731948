import { assign, clone, map, round } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { iif as if$, merge as merge$, of as of$ } from 'rxjs';
import { flatMap as flatMap$, map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiErrorCode, ApiResponse123 } from '@common/api';
import { CompanyClient, CompanyUserData } from '@common/client';
import { ContactClient } from '@common/client/ContactClient';
import { phoneNumberWithoutAreaCode } from '@common/helper';
import {
  CarrierRating,
  Company,
  CompanyAuthorityInfo,
  CompanyInfoFields,
  CompanyInsuranceInfo,
  CompanyLicensingInfo,
  CompanyUserDataPayload,
  CompanyVerificationDetailsResponse,
  CreditInfo,
  UserDataCompany,
} from '@common/model';
import { CarrierInfo, ContactBrokerInfo } from '@common/model/Carrier';
import {
  ApiAction,
  BaseState,
  createApiAction,
  createApiActionWithFetchData,
  EmptyResponse,
  Response,
} from '@common/redux/Base';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const FAVORITE_CARRIER = 'FAVORITE_CARRIER';
const HIDE_CARRIER = 'HIDE_CARRIER';
const FETCH_COMPANY = 'FETCH_COMPANY';
const FETCH_COMPANY_AUTHORITY = 'FETCH_COMPANY_AUTHORITY';
const FETCH_COMPANY_AUTHORITY_LICENSING = 'FETCH_COMPANY_AUTHORITY_LICENSING';
const FETCH_COMPANY_INSURANCE = 'FETCH_COMPANY_INSURANCE';
const FETCHED_COMPANY = 'FETCHED_COMPANY';
const FETCHED_COMPANY_AUTHORITY = 'FETCHED_COMPANY_AUTHORITY';
const FETCHED_COMPANY_AUTHORITY_LICENSING = 'FETCHED_COMPANY_AUTHORITY_LICENSING';
const FETCHED_COMPANY_INSURANCE = 'FETCHED_COMPANY_INSURANCE';
const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
const CHANGE_COMPANY_INFO = 'CHANGE_COMPANY_INFO';
const LOAD_COMPANY_INFO = 'LOAD_COMPANY_INFO';
const ONBOARD_CARRIER = 'ONBOARD_CARRIER';
const FETCH_CREDIT_INFO = 'FETCH_CREDIT_INFO';
const CREDIT_INFO_FETCHED = 'CREDIT_INFO_FETCHED';

const COMPANY_INFO_LOADED = 'COMPANY_INFO_LOADED';
const COMPANY_INFO_UPDATED = 'COMPANY_INFO_UPDATED';
export const CARRIER_FAVORITED = 'CARRIER_FAVORITED';
export const CARRIER_HIDDEN = 'CARRIER_HIDDEN';
export const CARRIER_ONBOARDED = 'CARRIER_ONBOARDED';

type CompanyActionResponse = EmptyResponse & { updated: Array<CompanyUserData<boolean>> };
type CompanyResponse = Response<Company>;
type CompanyDetailResponse = Response<UserDataCompany>;
type CompanyPatchResponse = Response<{
  onboardingUrl?: string;
}>;
type CompanyAuthorityResponse = Response<CompanyAuthorityInfo>;
type CompanyAuthorityLicensingResponse = Response<{
  authorityInfo: CompanyAuthorityInfo;
  licensingInfo: CompanyLicensingInfo;
}>;
type CompanyInsuranceResponse = Response<CompanyInsuranceInfo>;
type CreditInfoResponse = Response<CreditInfo>;

export type CompanyUserDataResponse = Response<CompanyUserDataPayload>;

export interface FavoriteCompany {
  id: string;
  favorite: boolean;
}

export interface HideCompany {
  id: string;
  hide: boolean;
}

export interface OnboardCarrier {
  id: string;
  onboard: boolean;
}

interface SetOnboardingURLPayload {
  id: string;
  onboardingUrl: string;
}

interface FavoriteCarriersAction extends Action {
  carriers: FavoriteCompany[];
}

interface HideCarriersAction extends Action {
  carriers: HideCompany[];
}

interface OnboardCarrierAction extends Action {
  carriers: OnboardCarrier[];
}

export interface CompanyResponseAction extends Action {
  response: EmptyResponse;
  updated: Array<CompanyUserData<boolean>>;
}

interface UpdateCompanyInfoAction extends Action {
  updateInfo: Company;
}
interface FetchComanpanyAction extends Action {
  id: string;
}
interface FetchCompanyAuthorityAction extends Action {
  id: string;
}
interface FetchCreditInfoAction extends Action {
  id: string;
}
interface ChangeCompanyInfoAction extends Action {
  updateInfo: Partial<Company>;
}

interface CompanyInfoAction extends Action {
  response: CompanyResponse;
}
interface CompanyDetailResponseAction extends Action {
  response: CompanyDetailResponse;
}

interface CompanyPatchResponseAction extends Action {
  response: CompanyPatchResponse;
}
interface CompanyAuthorityResponseAction extends Action {
  response: CompanyAuthorityResponse;
  companyID: string;
}

interface CompanyAuthorityLicensingResponseAction extends Action {
  response: CompanyAuthorityLicensingResponse;
  companyID: string;
}
interface CompanyInsuranceResponseAction extends Action {
  response: CompanyInsuranceResponse;
  companyID: string;
}
interface CreditInfoResponseAction extends Action {
  response: CreditInfoResponse;
}

export const getCreditInfo = (id: string): FetchCreditInfoAction => ({
  type: FETCH_CREDIT_INFO,
  id: id,
});
export const getCompanyInfo = (): Action => ({
  type: LOAD_COMPANY_INFO,
});
export const getCompanyDetails = (id: string): FetchComanpanyAction => ({
  type: FETCH_COMPANY,
  id: id,
});
export const getCompanyAuthority = (id: string): FetchCompanyAuthorityAction => ({
  type: FETCH_COMPANY_AUTHORITY,
  id: id,
});
export const getCompanyAuthorityLicensing = (id: string): FetchCompanyAuthorityAction => ({
  type: FETCH_COMPANY_AUTHORITY_LICENSING,
  id: id,
});
export const getCompanyInsurance = (id: string): FetchComanpanyAction => ({
  type: FETCH_COMPANY_INSURANCE,
  id: id,
});

export const updateCompanyInfo = (company: Company): UpdateCompanyInfoAction => ({
  type: UPDATE_COMPANY_INFO,
  updateInfo: company,
});

export const changeCompanyInfo = (company: Partial<Company>): ChangeCompanyInfoAction => ({
  type: CHANGE_COMPANY_INFO,
  updateInfo: company,
});

export const favoriteCarriers = (carriers: FavoriteCompany[]): FavoriteCarriersAction => {
  return { type: FAVORITE_CARRIER, carriers: carriers };
};

export const hideCarriers = (carriers: HideCompany[]): HideCarriersAction => {
  return { type: HIDE_CARRIER, carriers: carriers };
};

export const onboardCarriers = (carriers: OnboardCarrier[]): OnboardCarrierAction => {
  return { type: ONBOARD_CARRIER, carriers: carriers };
};

const carrierResponse = (
  response: EmptyResponse,
  type: string,
  updated: Array<CompanyUserData<boolean>>
): CompanyResponseAction => ({
  type: type,
  response: response,
  updated: updated,
});

const getCompanyInformationFromVolpeAction = createApiAction<string, CompanyVerificationDetailsResponse>(
  'COMPANY_INFORMATION_FROM_VOLPE'
);
export const getCompanyInformationFromVolpe = (dotNumber: string) =>
  getCompanyInformationFromVolpeAction.fetchAction(dotNumber);

const getCarrierRatingAction = createApiAction<string, CarrierRating>('FETCH_CARRIER_RATING');
export const getCarrierRating = (dotNumber: string) => getCarrierRatingAction.fetchAction(dotNumber);

const setOnboardingURLAction = createApiAction<SetOnboardingURLPayload, {}>('SET_COMPANY_ONBOARDING_URL');
export const setOnboardingUrl = (id: string, onboardingUrl: string) =>
  setOnboardingURLAction.fetchAction({ id: id, onboardingUrl: onboardingUrl });

export interface CompanyState extends BaseState {
  favoriteResponse?: CompanyActionResponse;
  hideResponse?: CompanyActionResponse;
  onboardResponse?: CompanyActionResponse;
  selectedCompany?: UserDataCompany;
  authorities?: CompanyAuthorityInfo;
  licensing?: CompanyLicensingInfo;
  insurance?: CompanyInsuranceInfo;
  updateCompany: {
    isLoading: boolean;
    updatedSuccessfully?: boolean;
    errorMessage: string;
    updateTime?: number;
  };
  isLoadingCompany: boolean;
  isLoadingCompanyInformation: boolean;
  didRetrieveCompanyDetailsSuccessfully?: boolean;
  company?: Company;
  creditInfo?: CreditInfo;
  isLoadingCreditRating: boolean;
  isLoadingAuthority: boolean;
  isLoadingInsurance: boolean;
  isLoadingVolpeInformation: boolean;
  companyVolpeInformation?: CompanyVerificationDetailsResponse;
  carrierRating?: CarrierRating;
  didCarrierRatingFetchFail?: boolean;
  isLoadingCarrierRating?: boolean;
  isLoadingOnboardingUrl: boolean;
  didSetOnboardingURLSuccessfully?: boolean;
  didSendOnboardingUrl: boolean;
}

const initialState: CompanyState = {
  updateCompany: {
    isLoading: false,
    errorMessage: '',
  },
  isLoadingCompany: false,
  isLoadingCompanyInformation: false,
  isLoading: false,
  isLoadingCreditRating: false,
  isLoadingAuthority: false,
  isLoadingInsurance: false,
  isLoadingVolpeInformation: false,
  isLoadingOnboardingUrl: false,
  didSendOnboardingUrl: false,
};

export const companyReducer = createMergedReducer<CompanyState>(initialState, [
  {
    [FAVORITE_CARRIER]: (state) => {
      state.isLoading = true;
    },
    [ONBOARD_CARRIER]: (state) => {
      state.isLoading = true;
    },
    [HIDE_CARRIER]: (state) => {
      state.isLoading = true;
    },
    [CARRIER_HIDDEN]: (state, action: CompanyResponseAction) => {
      return {
        ...state,
        isLoading: false,
        hideResponse: { ...action.response, updated: action.updated },
      };
    },
    [CARRIER_FAVORITED]: (state, action) => {
      const favoriteAction = action as CompanyResponseAction;
      return {
        ...state,
        isLoading: false,
        favoriteResponse: { ...favoriteAction.response, updated: favoriteAction.updated },
      };
    },
    [CARRIER_ONBOARDED]: (state, action: CompanyResponseAction) => {
      return {
        ...state,
        isLoading: false,
        onboardResponse: { ...action.response, updated: action.updated },
      };
    },
    [LOAD_COMPANY_INFO]: (state) => {
      return {
        ...state,
        updateCompany: {
          isLoading: false,
          errorMessage: '',
        },
        isLoadingCompany: true,
        company: undefined,
      };
    },
    [COMPANY_INFO_LOADED]: (state, action: CompanyInfoAction) => {
      const company = action.response.payload;
      if (company && company.phone) {
        company.phone.number = phoneNumberWithoutAreaCode(company.phone);
      }
      const didReceiveCompany = action.response.success && company !== undefined;
      const didReceiveErrorNoCompany =
        !action.response.success &&
        action.response.error &&
        action.response.error.code === ApiErrorCode.USER_DOES_NOT_HAVE_COMPANY;
      return {
        ...state,
        isLoadingCompany: false,
        company: company,
        didRetrieveCompanyDetailsSuccessfully: didReceiveCompany || didReceiveErrorNoCompany,
      };
    },
    [UPDATE_COMPANY_INFO]: (state) => {
      return {
        ...state,
        updateCompany: {
          isLoading: true,
          errorMessage: '',
        },
      };
    },
    [FETCH_COMPANY]: (state) => {
      state.isLoadingCompanyInformation = true;
    },
    [FETCHED_COMPANY]: (state, action: CompanyDetailResponseAction) => {
      const company = action.response.payload;
      let selectedCompany = undefined;
      if (company) {
        const mcNumber = company.mcNumber || company.brokerMcNumber;
        const docketNumber = mcNumber ? { prefix: CompanyInfoFields.MC, number: mcNumber } : undefined;
        selectedCompany = {
          ...company,
          docketNumber: company.docketNumber ? company.docketNumber : docketNumber,
        };
      }
      state.selectedCompany = selectedCompany;
      state.isLoadingCompanyInformation = false;
    },

    [FETCHED_COMPANY_AUTHORITY]: (state, action: CompanyAuthorityResponseAction) => {
      state.authorities = action.response.payload;
    },

    [FETCH_COMPANY_AUTHORITY_LICENSING]: (state) => {
      state.isLoadingAuthority = true;
      state.authorities = undefined;
      state.licensing = undefined;
    },

    [FETCHED_COMPANY_AUTHORITY_LICENSING]: (state, action: CompanyAuthorityLicensingResponseAction) => {
      const authorities = action.response.payload?.authorityInfo;
      const licensing = action.response.payload?.licensingInfo;
      return {
        ...state,
        authorities: authorities,
        isLoadingAuthority: false,
        licensing: licensing,
      };
    },
    [FETCH_COMPANY_INSURANCE]: (state) => {
      state.isLoadingInsurance = true;
    },
    [FETCHED_COMPANY_INSURANCE]: (state, action: CompanyInsuranceResponseAction) => {
      const insurance = action.response.payload;
      return {
        ...state,
        isLoadingInsurance: false,
        insurance: insurance,
      };
    },
    [CHANGE_COMPANY_INFO]: (state, action: ChangeCompanyInfoAction) => {
      const { id, ...company } = action.updateInfo;
      state.company = assign(clone(state.company), company);
    },
    [COMPANY_INFO_UPDATED]: (state, action: CompanyInfoAction) => {
      const updatedCompany = action.response.payload;
      return {
        ...state,
        updateCompany: {
          isLoading: false,
          updatedSuccessfully: action.response.success,
          errorMessage:
            // same api error code (4040092) for both incorrect DOT and MC number
            action.response.error && action.response.error.code === 4040092 ? action.response.error.message : '',
          updateTime: Date.now(),
        },
        company: updatedCompany ? updatedCompany : state.company,
      };
    },
    [FETCH_CREDIT_INFO]: (state) => {
      state.isLoadingCreditRating = true;
    },
    [CREDIT_INFO_FETCHED]: (state, action: CreditInfoResponseAction) => {
      const creditInfo = action.response.payload;
      return {
        ...state,
        creditInfo: creditInfo,
        isLoadingCreditRating: false,
      };
    },
  },
  getCompanyInformationFromVolpeAction.initiateCase((state) => {
    state.isLoadingVolpeInformation = true;
  }),
  getCompanyInformationFromVolpeAction.completeCase((state, action) => {
    if (action.response.success && action.response.payload) {
      state.companyVolpeInformation = action.response.payload;
    }
    state.isLoadingVolpeInformation = false;
  }),
  getCarrierRatingAction.initiateCase((state) => {
    state.isLoadingCarrierRating = true;
    state.didCarrierRatingFetchFail = undefined;
  }),
  getCarrierRatingAction.completeCase((state, { response }) => {
    if (response.success) {
      state.carrierRating = { ...response.payload, starRating: round(response.payload.starRating, 1) };
    } else {
      state.carrierRating = undefined;
      state.didCarrierRatingFetchFail = response.error.code !== ApiErrorCode.RATING_NOT_FOUND_FOR_USDOT;
    }
    state.isLoadingCarrierRating = false;
  }),
  setOnboardingURLAction.initiateCase((state) => {
    state.isLoadingOnboardingUrl = true;
    state.didSendOnboardingUrl = false;
  }),
  setOnboardingURLAction.completeCase((state, action: CompanyPatchResponseAction) => {
    state.didSetOnboardingURLSuccessfully = action?.response?.success;
    state.isLoadingOnboardingUrl = false;
    state.didSendOnboardingUrl = true;
  }),
]);

const carrierInfoApiAction = createApiActionWithFetchData<string, CarrierInfo>('CONTACT_CARRIER_INFO');
const brokerInfoApiAction = createApiActionWithFetchData<string, ContactBrokerInfo>('CONTACT_BROKER_INFO');

export const fetchCarrierInfo = carrierInfoApiAction.fetchAction;
export const fetchBrokerInfo = brokerInfoApiAction.fetchAction;

export interface ContactInfo {
  isLoading: boolean;
  carrier?: CarrierInfo;
  broker?: ContactBrokerInfo;
}

//@TODO: Merge with company reducer.
export interface ContactState {
  contacts: Map<string, ContactInfo>;
  authority: Map<string, CompanyAuthorityInfo>;
}
const initialContactState: ContactState = {
  contacts: new Map(),
  authority: new Map(),
};
export const contactReducer = createMergedReducer(initialContactState, [
  carrierInfoApiAction.initiateCase((state, action) => {
    const contactInfo = state.contacts.get(action.data) || {
      isLoading: true,
    };
    contactInfo.isLoading = true;
    state.contacts.set(action.data, contactInfo);
  }),
  carrierInfoApiAction.completeCase((state, action) => {
    const contactInfo = state.contacts.get(action.fetchData || '') || {
      isLoading: false,
    };
    contactInfo.isLoading = false;
    if (action.response.success) {
      contactInfo.carrier = action.response.payload;
    }
  }),
  brokerInfoApiAction.initiateCase((state, action) => {
    const contactInfo = state.contacts.get(action.data) || {
      isLoading: true,
    };
    contactInfo.isLoading = true;
    state.contacts.set(action.data, contactInfo);
  }),
  brokerInfoApiAction.completeCase((state, action) => {
    const contactInfo = state.contacts.get(action.fetchData || '') || {
      isLoading: false,
    };
    contactInfo.isLoading = false;
    if (action.response.success) {
      contactInfo.broker = action.response.payload;
    }
  }),

  {
    [FETCHED_COMPANY_AUTHORITY]: (state, action: CompanyAuthorityResponseAction) => {
      if (action.response.success && action.response.payload) {
        state.authority.set(action.companyID, action.response.payload);
      }
    },
  },
]);

const favoriteCarrier$ = (client: CompanyClient, action$: ActionsObservable<Action>) =>
  action$.ofType(FAVORITE_CARRIER).pipe(
    flatMap$((action: FavoriteCarriersAction) => {
      const carriers: Array<CompanyUserData<boolean>> = map(action.carriers, (carrier) => ({
        id: carrier.id,
        value: carrier.favorite,
      }));
      return client.favoriteCarriers$(carriers).pipe(
        map$((response: ApiResponse123<{}>) => {
          if (response.success) {
            return carrierResponse({ success: response.success }, CARRIER_FAVORITED, carriers);
          } else {
            const error = response as ApiError;
            return carrierResponse({ success: false, error: error }, CARRIER_FAVORITED, carriers);
          }
        })
      );
    })
  );

const hideCarrier$ = (client: CompanyClient, action$: ActionsObservable<Action>) =>
  action$.ofType(HIDE_CARRIER).pipe(
    flatMap$((action: HideCarriersAction) => {
      const carriers: Array<CompanyUserData<boolean>> = map(action.carriers, (carrier) => ({
        id: carrier.id,
        value: carrier.hide,
      }));
      return client.hideCarriers$(carriers).pipe(
        map$((response: ApiResponse123<{}>) => {
          if (response.success) {
            return carrierResponse({ success: response.success }, CARRIER_HIDDEN, carriers);
          } else {
            const error = response as ApiError;
            return carrierResponse({ success: response.success, error: error }, CARRIER_HIDDEN, carriers);
          }
        })
      );
    })
  );

const onboardCarrier$ = (client: CompanyClient, action$: ActionsObservable<Action>) =>
  action$.ofType(ONBOARD_CARRIER).pipe(
    flatMap$((action: OnboardCarrierAction) => {
      const carriers: Array<CompanyUserData<boolean>> = map(action.carriers, (carrier) => ({
        id: carrier.id,
        value: carrier.onboard,
      }));
      return client.onboardCarrier$(carriers).pipe(
        map$((response: ApiResponse123<{}>) => {
          if (response.success) {
            return carrierResponse({ success: response.success }, CARRIER_ONBOARDED, carriers);
          } else {
            const error = response as ApiError;
            return carrierResponse({ success: response.success, error: error }, CARRIER_ONBOARDED, carriers);
          }
        })
      );
    })
  );

const updateCompany$ = (client: CompanyClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    UPDATE_COMPANY_INFO,
    (action: UpdateCompanyInfoAction) => client.updateCompanyInfo$(action.updateInfo),
    (response): CompanyInfoAction => ({ type: COMPANY_INFO_UPDATED, response: response })
  );
};

const loadCompanyInfo$ = (client: CompanyClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    LOAD_COMPANY_INFO,
    () => client.getCompanyInfo$(),
    (response): CompanyInfoAction => ({ type: COMPANY_INFO_LOADED, response: response })
  );
};
const fetchCompanyInfo$ = (client: CompanyClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    FETCH_COMPANY,
    (action: FetchComanpanyAction) => client.getCompanyDetails$(action.id),
    (response): CompanyDetailResponseAction => ({ type: FETCHED_COMPANY, response: response })
  );
};

const fetchCreditInfo$ = (client: CompanyClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    FETCH_CREDIT_INFO,
    (action: FetchCreditInfoAction) => client.getCompanyCredit$(action.id),
    (response): CreditInfoResponseAction => ({ type: CREDIT_INFO_FETCHED, response: response })
  );
};

const fetchCompanyAuthority$ = (client: CompanyClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    FETCH_COMPANY_AUTHORITY,
    (action: FetchCompanyAuthorityAction) => client.getCompanyAuthority$(action.id),
    (response, action: FetchCompanyAuthorityAction): CompanyAuthorityResponseAction => ({
      type: FETCHED_COMPANY_AUTHORITY,
      response: response,
      companyID: action.id,
    })
  );
};

const fetchCompanyAuthorityLicensing$ = (client: CompanyClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    FETCH_COMPANY_AUTHORITY_LICENSING,
    (action: FetchCompanyAuthorityAction) => client.getCompanyAuthorityLicensing$(action.id),
    (response, action: FetchCompanyAuthorityAction): CompanyAuthorityLicensingResponseAction => ({
      type: FETCHED_COMPANY_AUTHORITY_LICENSING,
      response: response,
      companyID: action.id,
    })
  );
};
const fetchCompanyInsurance$ = (client: CompanyClient, action$: ActionsObservable<Action>) => {
  return simpleApiEpicToAction(
    action$,
    FETCH_COMPANY_INSURANCE,
    (action: FetchCompanyAuthorityAction) => client.getCompanyInsurance$(action.id),
    (response, action: FetchCompanyAuthorityAction): CompanyInsuranceResponseAction => ({
      type: FETCHED_COMPANY_INSURANCE,
      response: response,
      companyID: action.id,
    })
  );
};

const setOnboardingURL$ = (companyClient: CompanyClient, action$: ActionsObservable<Action>) =>
  action$.ofType(setOnboardingURLAction.fetchType).pipe(
    mergeMap$((action: ApiAction<SetOnboardingURLPayload>) =>
      companyClient.patchCompanyDetails$(action.data.id, action.data.onboardingUrl).pipe(
        mergeMap$((response: ApiResponse123<{}>) =>
          merge$(
            of$(setOnboardingURLAction.responseAction(response)),
            if$(() => response.success, of$(getCompanyInfo()))
          )
        )
      )
    )
  );

export const createCompanyEpic = (api: Api) => {
  const companyClient = new CompanyClient(api);
  const contactClient = new ContactClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      favoriteCarrier$(companyClient, action$),
      hideCarrier$(companyClient, action$),
      onboardCarrier$(companyClient, action$),
      loadCompanyInfo$(companyClient, action$),
      updateCompany$(companyClient, action$),
      fetchCompanyInfo$(companyClient, action$),
      fetchCreditInfo$(companyClient, action$),
      fetchCompanyAuthority$(companyClient, action$),
      fetchCompanyAuthorityLicensing$(companyClient, action$),
      fetchCompanyInsurance$(companyClient, action$),
      carrierInfoApiAction.createEpic$(action$, (carrierID) => contactClient.fetchCarrierInfo$(carrierID)),
      brokerInfoApiAction.createEpic$(action$, (id) => contactClient.fetchBrokerInfo$(id)),
      getCompanyInformationFromVolpeAction.createEpic$(action$, companyClient.fetchVolpeInformationByDotNumber$),
      getCarrierRatingAction.createEpic$(action$, companyClient.fetchCarrierRating$),
      setOnboardingURL$(companyClient, action$)
    );
};

import React from 'react';

import { first, isEmpty } from 'lodash';

import InputAdornment from '@material-ui/core/InputAdornment';

import { PostTruckAvailabilityForm } from '@/reduxStore/reducer/PostTruckAvailabilityReducer';
import { displayValueFor } from '@common/helper/LocationPickerHelper';
import { DestLocation, LoadSize, LocationType, OriginLocation } from '@common/model';
import { ChoiceChips } from '@component/chip';
import { TextField } from '@component/input';
import { MultiSelect, OptionType } from '@component/multiSelect/MultiSelect';
import { usePushPanel } from '@component/panel/PanelHooks';
import { RADIUS_OPTIONS, RadiusSelect } from '@component/panels/basePanel/searchPanel/RadiusSelect';
import { WeightLengthSliders } from '@component/slider/WeightAndLengthSliders';
import {
  StyledBlock,
  StyledGrid,
  StyledGridElement,
  StyledHeader,
  StyledIconsBar,
  StyledPaddedColumn,
  StyledPaddedRow,
  SwitchButton,
} from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { createMemoizedFormatStringsToOptions } from '@util/Formatter';
import icons from '@util/iconsConstants';

import { SubRoutes } from './TruckPostLists';

interface DatesProps {
  pickupDates: string[];
  handleDatesClear: () => void;
  error?: boolean;
}

export const PickUpDates: React.FC<DatesProps> = ({ pickupDates, handleDatesClear, error }) => {
  const openDatesPath = usePushPanel(`/${SubRoutes.Dates}/`);
  const openDates = () => openDatesPath();

  return (
    <StyledBlock>
      <StyledPaddedRow id="pickup_date">
        {pickupDates.length > 0 ? (
          <MultiSelect
            id="date"
            label={`${t(T.findLoads_findLoads_pickupDate)} ${t(T.findLoads_findLoads_asterisk)}`}
            options={formatStringsToPickupDateOptions(pickupDates)}
            mainIcon={icons.calendar}
            control={{ icon: icons.close, id: 'clear' }}
            onClick={openDates}
            onControlClick={handleDatesClear}
          />
        ) : (
          <TextField
            value={`${t(T.common_select)}`}
            label={`${t(T.findLoads_findLoads_pickupDate)}`}
            onClick={openDates}
            id="value"
            fullWidth={true}
            error={error}
            required={true}
            startAdornmentIcon={
              <InputAdornment position="start">
                <img id="icon" src={icons.calendar} alt="m_icon" />
              </InputAdornment>
            }
            isWithoutMargin={true}
          />
        )}
      </StyledPaddedRow>
    </StyledBlock>
  );
};

interface SwitchProps {
  switchLocations: () => void;
}

type LocationSectionProps = OriginDestinationProps & SwitchProps;

export const LocationSection: React.FC<LocationSectionProps> = (props) => {
  const destinationType = props.destination?.type;
  const isLocationSwitchDisabled =
    destinationType === LocationType.ANY ||
    destinationType === LocationType.ANY_CA ||
    destinationType === LocationType.ANY_US ||
    destinationType === LocationType.STATE;

  const shouldDisplayDestRadius =
    destinationType !== LocationType.ANY &&
    destinationType !== LocationType.ANY_CA &&
    destinationType !== LocationType.ANY_US;

  const displayTwoWayIcon = () => {
    if (!isLocationSwitchDisabled) {
      return <img id="switch" src={icons.roadSignTwoWay} alt="twoWaySign" />;
    } else {
      return <img src={icons.roadSignTwoWayDisabled} alt="twoWaySignDisabled" />;
    }
  };

  return (
    <StyledBlock style={{ marginTop: '16px', marginBottom: '8px' }}>
      <StyledPaddedRow>
        <StyledIconsBar>
          <img id="point_a" src={icons.pointA} alt="pointA" />
          <SwitchButton id="location_switch" onClick={props.switchLocations} disabled={isLocationSwitchDisabled}>
            {displayTwoWayIcon()}
          </SwitchButton>
          <img id="point_b" src={icons.pointB} alt="pointB" />
        </StyledIconsBar>
        <OriginDestination
          origin={props.origin}
          destination={props.destination}
          handleDestinationRadiusChange={props.handleDestinationRadiusChange}
          handleOriginRadiusChange={props.handleOriginRadiusChange}
          radius={props.radius}
          shouldDisplayDestRadius={shouldDisplayDestRadius}
          error={props.error}
        />
      </StyledPaddedRow>
    </StyledBlock>
  );
};

interface OriginDestinationProps {
  radius: { origin: number; destination: number };
  handleOriginRadiusChange: (radius: string) => void;
  handleDestinationRadiusChange: (radius: string) => void;
  destination?: DestLocation;
  origin?: OriginLocation;
  shouldDisplayDestRadius?: boolean;
  error?: boolean;
}

const OriginDestination: React.FC<OriginDestinationProps> = (props) => {
  const openPickUpPath = usePushPanel(`/${SubRoutes.PickUp}/`);
  const openPickUp = () => openPickUpPath();
  const openDropOffPath = usePushPanel(`/${SubRoutes.DropOff}/`);
  const openDropOff = () => openDropOffPath();

  const renderDestinationWithoutSates = () => {
    return (
      <StyledGridElement>
        <TextField
          label={t(T.findLoads_findLoads_dropOff)}
          id="drop_off"
          onClick={openDropOff}
          value={props.destination ? first(displayValueFor(props.destination, false)) : ''}
          readOnly={true}
          isWithoutMargin={true}
          style={{ flex: 1 }}
        />
        {props.shouldDisplayDestRadius ? (
          <RadiusSelect
            id="radius"
            options={RADIUS_OPTIONS}
            radius={props.radius.destination}
            onChange={props.handleDestinationRadiusChange}
          />
        ) : null}
      </StyledGridElement>
    );
  };

  return (
    <StyledGrid>
      <StyledGridElement>
        <TextField
          label={`${t(T.findLoads_findLoads_pickUp)} ${t(T.findLoads_findLoads_asterisk)}`}
          id={'pickup'}
          onClick={openPickUp}
          value={
            !isEmpty(props?.origin?.states)
              ? displayValueFor(props.origin as OriginLocation, false)
              : `${t(T.common_select)}`
          }
          readOnly={true}
          isWithoutMargin={true}
          error={props.error}
        />
        <RadiusSelect
          id="radius"
          options={RADIUS_OPTIONS}
          radius={props.radius.origin}
          onChange={props.handleOriginRadiusChange}
        />
      </StyledGridElement>
      {props.destination && props.destination.type === LocationType.STATE ? (
        <MultiSelect
          id="drop_off"
          label={t(T.findLoads_findLoads_dropOff)}
          onClick={openDropOff}
          options={formatStringsToStateOptions(props.destination.states)}
        />
      ) : (
        renderDestinationWithoutSates()
      )}
    </StyledGrid>
  );
};

interface WeightLengthSlidersFormProps {
  postRequest: PostTruckAvailabilityForm;
  updatePostRequest: (request: PostTruckAvailabilityForm) => void;
}

export const WeightLengthSlidersForm: React.FC<WeightLengthSlidersFormProps> = (props) => {
  const weight = props.postRequest.weight;
  const length = props.postRequest.length;
  const onChangeLength = (value: number[]) => handleLengthChange(props.postRequest, props.updatePostRequest, value);
  const onChangeWeight = (value: number[]) => handleWeightChange(props.postRequest, props.updatePostRequest, value);
  return (
    <WeightLengthSliders
      onChangeWeight={onChangeWeight}
      onChangeLength={onChangeLength}
      weight={weight}
      length={length}
      areSlidersDisabled={false}
      isPowerOnly={false}
    />
  );
};

export const AvailableCapacity: React.FC<{
  label?: string;
  loadSize?: LoadSize;
  updatedLoadSize: (size: LoadSize) => void;
  error?: boolean;
}> = ({ label, loadSize, updatedLoadSize, error }) => {
  const openTruckPath = usePushPanel(`/${SubRoutes.Truck}/`);
  const openTruck = () => openTruckPath();
  return (
    <>
      <StyledPaddedColumn>
        <StyledHeader id="available_capacity_title">{t(T.common_postTruck_postForm_availibleCapacity)}</StyledHeader>
        <TextField
          id={'equipment'}
          value={label ?? `${t(T.common_select)}`}
          label={`${t(T.findLoads_findLoads_trailerType)}`}
          onClick={openTruck}
          readOnly={true}
          fullWidth={true}
          isWithoutMargin={true}
          required={true}
          error={error}
          endAdornmentIcon={
            <InputAdornment position="end">
              <img id="icon" src={icons.chevronRight} alt="arrow_right" />
            </InputAdornment>
          }
        />
      </StyledPaddedColumn>
      <StyledPaddedColumn style={{ paddingTop: '16px' }}>
        <StyledHeader id="available_capacity_title">{t(T.common_load_filter_loadSize)}</StyledHeader>
        <ChoiceChips
          items={[
            { label: t(T.common_load_filter_tl), id: 'tl' },
            { label: t(T.common_load_filter_ltl), id: 'tlt' },
          ]}
          value={loadSize}
          handleChange={updatedLoadSize}
          backgroundColor={Color.WHITE}
        />
      </StyledPaddedColumn>
    </>
  );
};

const handleWeightChange = (
  postRequest: PostTruckAvailabilityForm,
  update: (searchRequest: PostTruckAvailabilityForm) => void,
  result: number[]
) => {
  const updatedWeight = {
    ...postRequest,
    weight: result[0],
  };
  update(updatedWeight);
};

const handleLengthChange = (
  postRequest: PostTruckAvailabilityForm,
  update: (searchRequest: PostTruckAvailabilityForm) => void,
  result: number[]
) => {
  const updatedLength = {
    ...postRequest,
    length: result[0],
  };
  update(updatedLength);
};

const formatStringsToStateOptions = createMemoizedFormatStringsToOptions(OptionType.State);
const formatStringsToPickupDateOptions = createMemoizedFormatStringsToOptions(OptionType.Date);

import { forEach, includes, values } from 'lodash';
import QueryString from 'qs';

import { StoreState } from '@/reduxStore/Store';
import {
  createUnderDevelopmentReducer,
  UnderDevelopmentFlagUpdate,
} from '@common/redux/reducer/UnderDevelopmentBaseReducer';
import { parseBoolean } from '@common/util/parser/ParserUtils';
import { useSelector } from '@util/hooks';

//============================================================================
// Flag system to hide/show features currently under development.
//----------------------------------------------------------------------------
//----- (More information please see Confluence.) ----------------------------
//
// Define your feature name in the MembersUnderDevelopmentFlag enum below.
//
// Example usage with useIsUnderDevelopment react hook: (preferred method)
//
// const isEnabled = useIsUnderDevelopmentFlagEnabled(MembersUnderDevelopmentFlag.YourFeature);
//
// Example usage with globally accessible method:
//
// const enabled = isUnderDevelopmentFlagEnabled(MembersUnderDevelopmentFlag.YourFeature);
//
//
// A common use case is enabling/disabling a route when a flag is on/off.
// By default the flag is set set to OFF.
// See the templates below for required modifications in AspxRouteMap.ts and
// Routing.ts
//
// AspxRouteMap.ts:
//
// ...
// enableRouteMatchIf(
//   () => !isUnderDevelopmentFlagEnabled(MembersUnderDevelopmentFlag.YourFlag),
//   exactMatchASPXRoute({
//     defaultTitle: ...,
//     reactPath: ...,
//     aspxPath: ...,
//   })
// ),
// ...
//
// Routing.ts: NavRoutes:
//
// const isYourFeatureEnabled = useIsUnderDevelopmentFlagEnabled(MembersUnderDevelopmentFlag.YourFlag);
// ...
// {isYourFeatureEnabled ? (
//   <AuthenticatedRoute ... />
// ) : null}
//
//============================================================================

/**
 * Add feature flags for MEM here
 * i.e. TestFeature = 'TestFeature'
 */
export enum MembersUnderDevelopmentFlag {
  NewAccountSetup = 'NewAccountSetup',
  InternalTruckLocator = 'InternalTruckLocator',
}

const ACTIVE_FEATURE_FLAGS = values(MembersUnderDevelopmentFlag);

const underDevelopment = createUnderDevelopmentReducer(ACTIVE_FEATURE_FLAGS);

export const underDevelopmentReducer = underDevelopment.reducer;
export const underDevelopmentStateReconciler = underDevelopment.stateReconciler;

export const updateUnderDevelopmentFlagStatuses = underDevelopment.actions.updateUnderDevelopmentFlagStatuses;

/**
 * Parses the /flags query string for flag updates. Filters out invalid flags
 * and invalid values (i.e. not boolean)
 */
export const parseFlagUpdatesQuery = (query: string): UnderDevelopmentFlagUpdate[] => {
  const urlParams = QueryString.parse(query, { ignoreQueryPrefix: true });
  const flags: UnderDevelopmentFlagUpdate[] = [];
  forEach(urlParams, (value, key) => {
    if (includes(ACTIVE_FEATURE_FLAGS, key)) {
      const parsedValue = parseBoolean(value);
      if (parsedValue !== undefined) {
        flags.push({ flag: key, isEnabled: parsedValue });
      }
    }
  });
  return flags;
};

/**
 *  feature under development flag hook
 */
export const useIsUnderDevelopmentFlagEnabled = (flag: MembersUnderDevelopmentFlag) =>
  useSelector((state) => state.underDevelopment.flags[flag]) ?? false;

export const IsUnderDevelopmentFlagEnabled = (state: StoreState, flag: MembersUnderDevelopmentFlag) =>
  state.underDevelopment.flags[flag] ?? false;

import React, { Component } from 'react';

import { map } from 'lodash';

import { DisplayOption } from '@common/helper/InterfaceHelper';
import THEME from '@style/Theme';

import { StyledFormControl, StyledFormLabel } from './ControlStyles';
import { Radio } from './Radio';

interface ComponentProps<T> {
  name?: string;
  options: DisplayOption<T>[];
  handleChange?: (value: T) => void;
  value?: T | undefined;
  inLine?: boolean;
}

export class RadioGroup<T> extends Component<ComponentProps<T>> {
  handleChange = (value: T) => () => {
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange(value);
    }
  };

  handleClick = (value: T) => () => {
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange(value);
    }
  };

  setRadioButtons = () =>
    map(this.props.options, (option) => (
      <Radio
        id={option.id}
        name={`${option.displayValue}${option.key ? option.key : ''}`}
        value={option.displayValue}
        label={option.displayValue}
        key={option.key ? option.key : option.displayValue}
        isChecked={option.value === this.props.value}
        onChange={this.handleChange(option.value)}
        onClick={this.handleClick(option.value)}
        inLine={this.props.inLine}
      />
    ));

  render() {
    const { name, inLine } = this.props;
    return (
      <StyledFormControl theme={THEME} inLine={inLine}>
        {name && <StyledFormLabel id="name">{name}</StyledFormLabel>}
        {this.setRadioButtons()}
      </StyledFormControl>
    );
  }
}

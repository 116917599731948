//------------------------------------------------------------------------
// This file defines the current Application (members) version number
//
//------------------------------------------------------------------------
// NOTES:
// Given that the file will be modified often, we refrained from
// using the number stored in package.json to reduce merge conflicts
// and allow easier git logs analysis.
//
// This file is parsed by the VSTS build process to extract the information.
// Do not add logic or other lines here.
//------------------------------------------------------------------------
// format : 'major.minor.patch'
//
export default '3.111.0';

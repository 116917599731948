import { BaseClient } from '@common/client/BaseClient';
import { ContactsCompanyVerificationResponse, IdentityVerificationPhoneResponse, Phone } from '@common/model';

export class IdentityVerificationClient extends BaseClient {
  getIdentityVerificationCompany$ = () =>
    this.api.get$<ContactsCompanyVerificationResponse>('/identityVerification/company', {});

  addIdentityVerificationPhoneNumber$ = (phone: Phone) =>
    this.api.post$<IdentityVerificationPhoneResponse>('/identityVerification/phoneNumber', {
      phoneNumber: phone.number,
      extension: phone.extension,
    });
}

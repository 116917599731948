import styled from 'styled-components';

import { ThemeProps, withTheme } from '@style/WithTheme';
import { MediaQueries } from '@util/MediaQueries';

export const StyledPickerWrapper = withTheme()(styled.div`
  max-width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
`);

export const StyledPickerTitle = withTheme()(styled.h2`
  font-size: ${(props: ThemeProps) => props.theme.text.subtitleBold.fontSize};
  line-height: ${(props: ThemeProps) => props.theme.text.subtitleBold.lineHeight};
  color: ${(props: ThemeProps) => props.theme.text.subtitleBold.color};
  font-weight: ${(props: ThemeProps) => props.theme.text.subtitleBold.fontWeight};
  margin-bottom: 0;
  margin-top: 0;
`);

export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;

  @media ${MediaQueries.lt_md} {
    margin-left: 0;
  }

  @media ${MediaQueries.lt_sm} {
    margin-left: 5px;
  }

  @media ${MediaQueries.xxs} {
    margin-left: 0;
  }
`;

export const StyledPicker = withTheme()(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  color: ${(props: ThemeProps) => props.theme.palette.background.black};
  font-size: ${(props: ThemeProps) => props.theme.text.subtitle2Bold.fontSize};
  line-height: ${(props: ThemeProps) => props.theme.text.subtitle2Bold.lineHeight};
  font-weight: ${(props: ThemeProps) => props.theme.text.subtitle2Bold.fontWeight};
  margin: 5px;
  cursor: pointer;
  tabindex: 0;
  &:focus {
    outline-color: ${(props: ThemeProps) => props.theme.palette.background.black};
  }
  @media ${MediaQueries.xs} {
    margin: 4px;
  }
`);

export const StyledStatePicker = styled(StyledPicker)`
  && {
    @media ${MediaQueries.lt_md} {
      margin: 5px 4.8px;
    }
  }
`;

export const StyledSelectedPicker = withTheme()(styled(StyledPicker)`
  color: ${(props: ThemeProps) => props.theme.palette.background.white};
  background-color: ${(props: ThemeProps) => props.theme.palette.primary.main};
`);

export const StyledStateSelectedPicker = styled(StyledSelectedPicker)`
  && {
    @media ${MediaQueries.lt_md} {
      margin: 5px 4.8px;
    }
  }
`;

export const StyledDaysContainer = withTheme()(styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  margin-bottom: 5px;
  margin-left: 10px;

  @media ${MediaQueries.lt_md} {
    margin-left: 0;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    margin: 5px;
    font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
    line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
    font-weight: ${(props: ThemeProps) => props.theme.text.body.fontWeight};
    @media ${MediaQueries.xs} {
      margin: 4px;
    }
  }
`);

export const StyledDisabledPicker = withTheme()(styled(StyledPicker)`
  color: ${(props: ThemeProps) => props.theme.palette.background.stone};
`);

export const StyledEmptyPicker = styled.div`
  height: 36px;
  width: 36px;
  margin: 5px;
  @media ${MediaQueries.xs} {
    margin: 4px;
  }
`;

export const StyledCurrentDayPicker = withTheme()(styled(StyledPicker)`
  color: ${(props: ThemeProps) => props.theme.palette.primary.main};
`);

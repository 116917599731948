import L, { LatLngLiteral, LatLngTuple } from 'leaflet';
import { isEqual } from 'lodash';
import { MapLayer, MapLayerProps } from 'react-leaflet';
import { withLeaflet } from 'react-leaflet/es/context';

import './ShadowRadius.css';

type LatLngAlt = LatLngTuple | [number, number, number] | LatLngLiteral | { lat: number; lng: number; alt?: number };

interface LeafletZoomEvent {
  zoom: number;
  center: {};
}

//--------------------------
// Leaflet Layer class
const ShadowRadiusLeafletLayer = L.Layer.extend({
  options: {
    center: undefined,
    radius: 50 * 1000, // meters
  },

  initialize: function (center: LatLngAlt, options: any) {
    L.Util.setOptions(this, options);
    this.setCenter(center);
    this.setRadius(this.options.radius);
  },

  setCenter: function (center: LatLngAlt) {
    this._latlng = L.latLng(center);
  },

  setRadius: function (radius: number) {
    this._radiusMeters = radius;
  },

  onAdd: function (map: L.Map) {
    const pane = map.getPane('overlayPane');

    if (!pane) {
      console.error("[ShadowRadius]: leaflet pane 'overlayPane' not found");
      return;
    }

    this._map_ref = map;
    this._container = L.DomUtil.create('div');
    this._container.className = 'shadow-radius leaflet-zoom-animated';
    this._circleElem = L.DomUtil.create('div');
    this._container.appendChild(this._circleElem);

    pane.appendChild(this._container);

    this.redraw();

    map.on('zoom zoomend move viewreset', this.redraw, this);

    if (map.options.zoomAnimation && L.Browser.any3d) {
      map.on('zoomanim', this._animateZoom, this);
    }
  },

  onRemove: function (map: L.Map): void {
    L.DomUtil.remove(this._container);
    map.off('zoom zoomend move viewreset', this.redraw, this);
    map.off('zoomanim', this._animateZoom, this);
    this._map_ref = undefined;
  },

  _animateZoom: function (e: LeafletZoomEvent): void {
    //There seems to be a bug related to map pane being undefined inside leaflet.
    //This is a blind atempt to prevent zoom to happen if we don't have it.
    //Shouldn't occour.
    if (!this._map_ref._mapPane) {
      return;
    }
    const scale = this._map_ref.getZoomScale(e.zoom);
    const offset = this._map_ref._latLngToNewLayerPoint(this._latlng, e.zoom, e.center);

    L.DomUtil.setTransform(this._container, offset, scale);
  },

  redraw: function () {
    //------------------------------------------------------------------------
    // This computation logic was extracted from leaflet's own Circle Layer
    // (Leaflet version 1.3.3)
    // https://github.com/Leaflet/Leaflet/blob/master/src/layer/vector/Circle.js#L68
    // This create a near-circle (a vertical oval) that represents the area close
    // to the real physical distance on earth in the standard projection.

    const lng = this._latlng.lng;
    const lat = this._latlng.lat;
    const map = this._map;

    // Mean Earth Radius, as recommended for use by
    // the International Union of Geodesy and Geophysics,
    // see http://rosettacode.org/wiki/Haversine_formula
    const earthRadius = 6371000;

    const d = Math.PI / 180;
    const latR = this._radiusMeters / earthRadius / d;
    const top = map.project([lat + latR, lng]);
    const bottom = map.project([lat - latR, lng]);
    const p = top.add(bottom).divideBy(2);
    const lat2 = map.unproject(p).lat;
    let lngR =
      Math.acos(
        (Math.cos(latR * d) - Math.sin(lat * d) * Math.sin(lat2 * d)) / (Math.cos(lat * d) * Math.cos(lat2 * d))
      ) / d;

    if (isNaN(lngR) || lngR === 0) {
      lngR = latR / Math.cos((Math.PI / 180) * lat); // Fallback for edge case, #2425
    }

    this._point = p.subtract(map.getPixelOrigin());
    this._radius = isNaN(lngR) ? 0 : p.x - map.project([lat2, lng - lngR]).x;
    this._radiusY = p.y - top.y;

    //------------------------------------------------------------------------

    L.DomUtil.setPosition(this._container, this._point);

    this._circleElem.style.left = '-' + this._radius + 'px';
    this._circleElem.style.width = this._radius * 2 + 'px';
    this._circleElem.style.top = '-' + this._radiusY + 'px';
    this._circleElem.style.height = this._radiusY * 2 + 'px';

    this._lastmapcenter = this._map.getCenter();
    this._lastmapzoom = this._map.getZoom();
  },
});

export interface ShadowRadiusProps extends MapLayerProps {
  center: L.LatLng | number[];
  /** radius in meters */
  radius: number;
}

class ShadowRadiusComponent extends MapLayer<ShadowRadiusProps> {
  static propTypes = {};

  createLeafletElement(props: ShadowRadiusProps): L.Layer {
    const layer = new ShadowRadiusLeafletLayer();
    layer.initialize(props.center, { radius: props.radius });
    return layer;
  }

  updateLeafletElement(fromProps: ShadowRadiusProps, toProps: ShadowRadiusProps) {
    const shadowRadiusLeafletLayer: any = this.leafletElement;

    if (isEqual(fromProps.center, toProps.center) && isEqual(fromProps.radius, toProps.radius)) {
      return;
    }

    shadowRadiusLeafletLayer.setCenter(toProps.center);
    shadowRadiusLeafletLayer.setRadius(toProps.radius);
    shadowRadiusLeafletLayer.redraw();
  }

  render(): JSX.Element | null {
    return null;
  }
}

export const ShadowRadius = withLeaflet(ShadowRadiusComponent);

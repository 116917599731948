/** detect if we are accidentally inside an iframe and
 * broadcast that fact
 */
if (window !== window.parent) {
  try {
    window.parent.postMessage(
      {
        source: 'members-react',
        uri: window.location.href,
      },
      '*'
    );
  } catch (e) {
    /* */
  }
}

// to flag the compiler that this is a legit module
export default {};

import { SearchResponsePayload } from '@common/redux/Base';

import { Address, Phone } from './';
import { CompanyMetadata } from './CompanyMetadata';

export enum CompanyCategory {
  None = 'None',
  PL3 = 'PL3',
  Broker = 'Broker',
  BrokerCarrier = 'BrokerCarrier',
  CarrierTL = 'CarrierTL',
  FreightForwarder = 'FreightForwarder',
  ShipperManufacturer = 'ShipperManufacturer',
  Other = 'Other',
  OwnerOperator = 'OwnerOperator',
  CarrierLTL = 'CarrierLTL',
}

export interface Company {
  id: string;
  guid?: string;
  name: string;
  phone?: Phone; // Api documentation is wrong, this field is optional
  mcNumber?: number | string;
  brokerMcNumber?: number | string;
  address?: Address;
  metaData?: CompanyMetadata;
  isOcfp?: boolean;
  isTia?: boolean;
  usdotNumber?: number | string;
  dotNumber?: string;
  fax?: {
    number: string;
    extension: string;
  };
  webSite?: string;
  category?: CompanyCategory;
  city?: string;
  state?: string;
  zipCode?: string;
  onboardingUrl?: string;
  isLocked?: boolean;
}

export interface CompanyAuthorityInfo {
  authorities?: AuthorityInfo[];
}

export interface AuthorityInfo {
  type: string;
  status: string;
  pending: boolean;
}

export interface CompanyLicensingInfo {
  property?: boolean;
  passenger?: boolean;
  householdGoods?: boolean;
  private?: boolean;
  enterprise?: boolean;
}

export interface InsuranceCoverageInfo {
  insurerInfo?: {
    name?: string;
    attention?: string;
    address?: string;
    phone?: Phone;
    fax?: Phone;
  };
  type?: string;
  form?: string;
  policyNumber?: string;
  minimumAmount?: number;
  maximumAmount?: number;
  postedDate?: string;
  effectiveDate?: string;
  cancellationDate?: string;
}

export interface CompanyInsuranceInfo {
  requirements?: {
    bipdRequired?: number;
    bipdOnFile?: number;
    cargoRequired?: boolean;
    cargoOnFile?: boolean;
    bondRequired?: boolean;
    bondOnFile?: boolean;
  };
  coverages?: InsuranceCoverageInfo[];
}

export interface UserDataCompany {
  id: string;
  metaData?: CompanyMetadata;
  category?: CompanyCategory;
  docketNumber?: {
    prefix?: string;
    number?: number;
  };
  name: string;
  address?: Address;
  phone: Phone;
  mcNumber?: number;
  brokerMcNumber?: number;
  usdotNumber?: number;
  isOcfp?: boolean;
  isTia?: boolean;
  webSite?: string;
  tollFree?: {
    number: number;
  };
  briefProfile?: string;
  yearFounded?: number;
  fax?: {
    number: string;
    extension: string;
  };
  scac?: string;
  interstate?: string;
  isTeamDriven?: boolean;
  onboardingUrl?: string;
}

export interface CompanyUserDataPayload extends SearchResponsePayload {
  id: string;
  companies: UserDataCompany[];
}

export interface CompanyUserDataQuery {
  isFavoriteBroker?: boolean;
  isFavoriteCarrier?: boolean;
  isOnboarded?: boolean;
  isHiddenBroker?: boolean;
  isHiddenCarrier?: boolean;
  companyName?: string;
}

export interface CompanyUserData extends CompanyUserDataQuery {
  note?: string;
}

export interface BrokerPaginationRequest {
  limit: number;
  offset: number;
}

export enum CompanyInfoFields {
  NAME = 'NAME',
  DOT = 'DOT',
  MC = 'MC',
  PHONE = 'PHONE',
  ADDRESS1 = 'ADDRESS1',
  ADDRESS2 = 'ADDRESS2',
  CITY = 'CITY',
  STATE = 'STATE',
  ZIP = 'ZIP',
}

export interface CarrierRating {
  dotNumber: number;
  reviewCount: number;
  starRating: number;
  name: string;
  profileUrl: string;
  safetyRating: string;
  operation: string;
}

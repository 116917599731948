import React from 'react';

import styled from 'styled-components';

import { LoadDetailsTabs } from '@/model';
import { NO_INFORMATION_SYMBOL } from '@common/formatter';
import { getMCLink, getUSDOTLink } from '@common/info/ExternalLink';
import { Company, CompanyCategory } from '@common/model/Company';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import {
  LoadDetailsValueRow,
  renderLoadDetailsValueRowTextRight,
} from '@component/panels/basePanel/loadDetails/LoadDetailsValueRow';
import {
  CompanyTextWrapper,
  ExpansionPanelDataWrapper,
  IconThunder,
  LoadDetailsContainer,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { TappableTextContent } from '@component/text/TappableTextContent';
import { ElementSize } from '@style/StyleConstants';
import { t, T } from '@translate';

import { default as CompanyIcon } from './company.svg';
import { default as LikeIcon } from './heart.png';
import { default as OnboardIcon } from './onboarded.svg';
import { default as ThunderLogo } from './thunder logo.png';
import { default as TiaIcon } from './tia.png';

const InfoIcon = styled.img`
  height: ${ElementSize.TextIcon}px;
  width: ${ElementSize.TextIcon}px;
`;

interface Props {
  poster?: Company;
  handleChange(tab: LoadDetailsTabs, status: boolean): void;
  isOpened: boolean;
}

export class CompanyInfo extends React.Component<Props, {}> {
  openLink = (link: string) => () => {
    window.open(link, '_blank');
  };

  renderMcNumberLink = (mcNumber?: number | string) => () => {
    if (mcNumber) {
      return (
        <TappableTextContent id="link" onClick={this.openLink(getMCLink(mcNumber))} accent={true}>
          {mcNumber}
        </TappableTextContent>
      );
    }
    if (this.props.poster?.category === CompanyCategory.ShipperManufacturer) {
      return renderLoadDetailsValueRowTextRight(t(T.common_load_shipperMCNotRequired))();
    }
    return renderLoadDetailsValueRowTextRight(NO_INFORMATION_SYMBOL)();
  };

  renderDOTNumberLink = (dotNumber?: number | string) => () => {
    if (dotNumber) {
      return (
        <TappableTextContent id="link" onClick={this.openLink(getUSDOTLink(`${dotNumber}`))} accent={true}>
          {dotNumber}
        </TappableTextContent>
      );
    }
    return renderLoadDetailsValueRowTextRight(NO_INFORMATION_SYMBOL)();
  };

  renderCompanyWebsiteLink = (website: string) => () => {
    let link = website;
    if (!/^https?:\/\/.*/.test(website)) {
      link = `http://${website}`;
    }
    return (
      <TappableTextContent id="link" onClick={this.openLink(link)} accent={true}>
        {website}
      </TappableTextContent>
    );
  };

  renderAddress = (poster?: any) => () => {
    let topLine = '';
    let bottomLine = '';
    if (poster.address) {
      if (poster.address.addressLine1) {
        topLine = poster.address.addressLine1;
      }
      if (poster.address.city) {
        bottomLine = poster.address.city;
      }
      if (poster.address.state) {
        bottomLine = bottomLine + ', ' + poster.address.state;
      }
      if (poster.address.zipCode) {
        bottomLine = bottomLine + ', ' + poster.address.zipCode;
      }
      if (poster.address.country) {
        bottomLine = bottomLine + ', ' + poster.address.country;
      }
    } else {
      return renderLoadDetailsValueRowTextRight(t(T.findLoads_loadDetails_noMatches))();
    }
    return (
      <div style={{ width: '100%' }}>
        <p id="top_line">{renderLoadDetailsValueRowTextRight(topLine)()}</p>
        <p id="bottom_line">{renderLoadDetailsValueRowTextRight(bottomLine)()}</p>
      </div>
    );
  };

  renderMember = (isTia: boolean) => () => {
    return isTia ? (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {renderLoadDetailsValueRowTextRight(t(T.findLoads_loadDetails_yes))()}
        <img id="logo" src={TiaIcon} alt="tia" style={{ height: 29 }} />
      </div>
    ) : (
      <div
        style={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {renderLoadDetailsValueRowTextRight(t(T.findLoads_loadDetails_no))()}
      </div>
    );
  };

  renderFactorable = () => () => {
    return (
      <div style={{ display: 'flex' }}>
        {renderLoadDetailsValueRowTextRight(t(T.findLoads_loadDetails_preApproved))()}
        <IconThunder id="logo" src={ThunderLogo} alt="logo" />
      </div>
    );
  };

  handleTabClick = () => {
    this.props.handleChange(LoadDetailsTabs.Company, !this.props.isOpened);
  };

  renderTitleFavIcon = () => {
    const { poster } = this.props;
    return (
      <>
        {poster?.metaData?.onboarded ? <InfoIcon id="onboarded" src={OnboardIcon} alt="onboarded" /> : null}
        {poster?.metaData?.favoriteBroker ? <InfoIcon id="favorite" src={LikeIcon} alt="like" /> : null}
      </>
    );
  };

  render() {
    const { poster } = this.props;
    return (
      <ExpansionPanel
        id="company_info"
        titleText={t(T.shareLoad_company_Title)}
        subtitleText={poster && poster.name}
        icon={CompanyIcon}
        isOpened={this.props.isOpened}
        handleClick={this.handleTabClick}
        renderTitleFavIcon={this.renderTitleFavIcon}
      >
        <ExpansionPanelDataWrapper>
          <LoadDetailsContainer isWithoutTopLine={true}>
            <CompanyTextWrapper>
              <LoadDetailsValueRow
                id="name"
                title={t(T.findLoads_loadDetails_company)}
                renderRight={renderLoadDetailsValueRowTextRight(poster?.name ?? NO_INFORMATION_SYMBOL)}
              />
            </CompanyTextWrapper>
            <CompanyTextWrapper>
              <LoadDetailsValueRow
                id="address"
                title={t(T.findLoads_loadDetails_address)}
                renderRight={this.renderAddress(poster)}
              />
            </CompanyTextWrapper>
            <CompanyTextWrapper>
              <LoadDetailsValueRow
                id="mc_number"
                title={t(T.common_mc)}
                renderRight={this.renderMcNumberLink(poster?.brokerMcNumber)}
              />
            </CompanyTextWrapper>
            <CompanyTextWrapper>
              <LoadDetailsValueRow
                id="dot_number"
                title={t(T.common_usDot)}
                renderRight={this.renderDOTNumberLink(poster?.usdotNumber)}
              />
            </CompanyTextWrapper>
            {poster?.webSite ? (
              <CompanyTextWrapper>
                <LoadDetailsValueRow
                  id="website"
                  title={t(T.findLoads_loadDetails_website)}
                  renderRight={this.renderCompanyWebsiteLink(poster.webSite)}
                />
              </CompanyTextWrapper>
            ) : null}
            <CompanyTextWrapper>
              <LoadDetailsValueRow
                id="tia_member"
                title={t(T.findLoads_loadDetails_member)}
                renderRight={this.renderMember((poster && poster.isTia) || false)}
              />
            </CompanyTextWrapper>
            {poster && poster.isOcfp && (
              <CompanyTextWrapper>
                <LoadDetailsValueRow
                  id="factorable"
                  title={t(T.findLoads_loadDetails_factorable)}
                  renderRight={this.renderFactorable()}
                />
              </CompanyTextWrapper>
            )}
          </LoadDetailsContainer>
        </ExpansionPanelDataWrapper>
      </ExpansionPanel>
    );
  }
}

import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';

import { GeolocationGroup, LoadSearchMetadata, LoadSearchRequest, ViewField } from '@common/model';
import { createDefaultSearchDirectoryRequest, SearchDirectoryCompanyRequest } from '@common/model/SearchDirectory';
import { DefaultEncoder } from '@common/util/encoder/URLDefaultEncoder';
import { parseString, parseValueFrom } from '@common/util/parser/ParserUtils';
import { parseDirectorySearchRequest } from '@util/parsers/DirectorySearchRequestParsers';
import {
  parseCompanySearchRequest,
  parseLoadSearchMetadata,
  parseLoadSearchRequest,
} from '@util/parsers/LoadSearchRequestParsers';
import { parseMapData } from '@util/parsers/MapDataParsers';

export interface MapData {
  count: number;
  pins: GeolocationGroup[];
}

export interface LoadSearchFilters {
  searchData?: LoadSearchRequest;
  companySearchData?: LoadSearchRequest;
  searchDirectoryData?: SearchDirectoryCompanyRequest;
  mapData?: MapData;
  companyMapData?: MapData;
  searchId?: string;
  companySearchId?: string;
  metadata?: LoadSearchMetadata;
  companyMetadata?: LoadSearchMetadata;
  changeView?: ViewField;
}

class LoadSearchRequestEncoder extends DefaultEncoder<LoadSearchFilters> {
  getVocabulary() {
    return {
      S: 'searchData',
      SD: 'searchDirectoryData',
      M: 'mapData',
      CS: 'companySearchData',
      CM: 'companyMapData',
      cy: 'company',
      n: 'name',
      ts: 'types',
      cIds: 'companyIds',
      d: 'destination',
      t: 'type',
      c: 'city',
      s: 'states',
      o: 'origin',
      m: 'metadata',
      cm: 'companyMetadata',
      wL: 'includeLoadsWithoutLength',
      wW: 'includeLoadsWithoutWeight',
      lS: 'loadSize',
      l: 'length',
      lt: 'limit',
      w: 'weight',
      pD: 'pickupDates',
      eT: 'equipmentTypes',
      mW: 'minWeight',
      mL: 'minLength',
      sB: 'sortBy',
      dr: 'direction',
      fs: 'fields',
      r: 'radius',
      lT: 'latitude',
      lG: 'longitude',
      p: 'pins',
      z: 'zoom',
      g: 'geolocation',
      ct: 'count',
      cr: 'center',
      f: 'field',
      id: 'searchId',
      cId: 'companySearchId',
      cv: 'changeView',
      eS: 'equipmentSpecifications',
      wGPD: 'includeWithGreaterPickupDates',
      wGDD: 'includeWithGreaterDeliveryDates',
      sL: 'saveLocations',
      iS: 'includeStatuses',
      reg: 'region',
      sD: 'state',
      cND: 'companyName',
      cTD: 'companyType',
      seD: 'sortExpression',
      sdD: 'sortDirection',
    };
  }
  didUrlParamsChange(prevUrlParams: string, currUrlParams: string) {
    // in this case we're just checking if the two sets of params are the same, no
    // need to parse to a LoadSearchRequest - that would be unnecessary overhead
    return !isEqual(
      this.convertUrlParamsToObjectRequest(currUrlParams),
      this.convertUrlParamsToObjectRequest(prevUrlParams)
    );
  }
  didCompanyUrlParamsChange(prevUrlParams: string, currUrlParams: string) {
    const currentLoadSearchObject = this.convertUrlParamsToObjectRequest(currUrlParams);
    const previousLoadSearchObject = this.convertUrlParamsToObjectRequest(prevUrlParams);
    return !isEqual(currentLoadSearchObject.companySearchData, previousLoadSearchObject.companySearchData);
  }
  convertUrlParamsToLoadSearchFilters = memoizeOne((urlParams: string): LoadSearchFilters => {
    const rawObjectRequest = this.convertUrlParamsToObjectRequest(urlParams);
    return {
      searchData: parseLoadSearchRequest(rawObjectRequest.searchData),
      companySearchData: parseCompanySearchRequest(rawObjectRequest.companySearchData),
      mapData: parseMapData(rawObjectRequest.mapData),
      companyMapData: parseMapData(rawObjectRequest.companyMapData),
      searchId: parseString(rawObjectRequest.searchId),
      companySearchId: parseString(rawObjectRequest.companySearchId),
      metadata: parseLoadSearchMetadata(rawObjectRequest.metadata),
      companyMetadata: parseLoadSearchMetadata(rawObjectRequest.metadata),
      changeView: parseValueFrom(rawObjectRequest.changeView, ViewField),
    };
  });

  convertUrlParamsToDirectorySearchFilters = memoizeOne((urlParams: string): LoadSearchFilters => {
    const rawObjectRequest = this.convertUrlParamsToObjectRequest(urlParams);
    const defaultSearchDirectoryData = createDefaultSearchDirectoryRequest();
    const searchDirectoryData = parseDirectorySearchRequest(rawObjectRequest.searchDirectoryData);
    return {
      searchDirectoryData: {
        ...defaultSearchDirectoryData,
        ...searchDirectoryData,
      },
      companySearchData: parseCompanySearchRequest(rawObjectRequest.companySearchData),
      mapData: parseMapData(rawObjectRequest.mapData),
      companyMapData: parseMapData(rawObjectRequest.companyMapData),
      companySearchId: parseString(rawObjectRequest.companySearchId),
      metadata: parseLoadSearchMetadata(rawObjectRequest.metadata),
      companyMetadata: parseLoadSearchMetadata(rawObjectRequest.metadata),
      changeView: parseValueFrom(rawObjectRequest.changeView, ViewField),
    };
  });
}

export const loadSearchRequestEncoder = new LoadSearchRequestEncoder();

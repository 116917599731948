import { orderBy, values } from 'lodash';
import { generatePath } from 'react-router-dom';

import { PostsFilter } from '@common/model';

// Keep this file separate from the Routing for cleaner dependencies
// (and avoid an issue with yarn test not understanding async imports used in Routing.tsx)

export const GUID_REGEX = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
export const HEX40_REGEX = '[0-9a-f]{40}';
const CONVERSATION_GUID_REGEX =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export enum UnsubscribeAssistantReasons {
  COST = 'cost',
  LOADS_ISSUE = 'loads-issue',
  DIFFICULTY = 'difficulty',
  MISSING_FUNCTIONALITY = 'missing-functionality',
  NOT_USING = 'not-using',
  OTHER = 'other',
}

export enum TruckLocatorRoutes {
  InternalTruckLocator = 'internal-truck-locator',
  TruckLocator = 'truck-locator',
}

enum PostTruckAlertRoutes {
  Alerts = 'alert-truck',
}

export type PostTruckRoutes = PostsFilter | PostTruckAlertRoutes;
export const PostTruckRoutes = { ...PostsFilter, ...PostTruckAlertRoutes };

export const postedLoadsModalPanels = 'sortBy|filter|status|pickUpDate|view';
export const filterPanelModalPanels =
  'pickUpLocation|pickUpDate|pickUpTime|dropOffLocation|dropOffDate|dropOffTime|trailerType|trailerSpecification|visibilityRules|editRule';
const postLoadsModalPanels = `${filterPanelModalPanels}|recentPost|addStops|editStop`;
export const unsubscribeAssistantReasons = 'cost|loads-issue|difficulty|missing-functionality|not-using|other';

export const PlainRoutes = {
  VERIFY_EMAIL: '/verify/',
  VERIFY_EMAIL_BLOCKER_PAGE: '/verifyEmail/',
  VERIFIED_LOGIN: '/verifiedLogin/',
  EXPORTPOSTS_TABLE_VIEW: '/post-loads/export-posts/table-view/',
};

export const COMMUNICATION_KEY = 'communication';

const COMMUNICATION_MESSAGE = `/:chat(chat)?/:conversationID(${CONVERSATION_GUID_REGEX})?/:details(details)?/:loadcontact(loadcontact)?/:usercontact(usercontact)?/:contactID(${GUID_REGEX})?`;
const COMMUNICATION_NESTED_PANEL = `/:communication(${COMMUNICATION_KEY})?${COMMUNICATION_MESSAGE}`;
const TRUCK_ALERTS = `/:alert(alert)?/:alertID?/:loadDetails(load-details)?/:loadID(${GUID_REGEX})?`;
export const POST_TRUCK = `/:subroutes(add-post|edit-post)?/:postID([0-9]*)?/:panels(dates|truck|pick-up|drop-off|new-my-truck|edit-my-truck)?/:truckID(${GUID_REGEX})?`;
const SEARCH_LOADS_MODALS = '(pickup|dropoff|dates|trailer-type|trip-length|my-truck|create-new-truck|edit-truck)';
export const BACHKHAUL_ROUTE = `/:backhauls(backhauls)?/:backhaulSearchID([0-9]*)?/:backhaulsSortBy(sortBy)?/:backhaulsDates(dates)?/:backhaulLoadID(${GUID_REGEX})?/:backhaulLoadMap(backhaul-map)?`;
export const COMPANY_SEARCH = `/:companySearch(companySearch)?/:companySearchModal${SEARCH_LOADS_MODALS}?/:companySearchMap(companySearchMap)?/:companySearchResult(companySearchResult)?/:companySearchFilters(filters)?/:companySearchSortBy(sortBy)?/:companySearchChangeView(changeView)?/:companySearchLoad(${GUID_REGEX})?/:companyLoadMap(pc-miler-map)?`;
const BIDS_NESTED = `/:bidID?/:loadID?/:edit(edit|offer|counteroffer|new)?/:offerID?/:otherPartyUserID?/:loadMap(pc-miler-map)?`;
export const TRIP_BUILDER_MODALS =
  '(pickUpLocation|pickUpDate|pickUpTime|dropOffLocation|dropOffDate|dropOffTime|trailerType|trailerSpecification|excludedStates|tripLength)';

const TRIP_BUILDER_BASE_URL = '/loads/trip-builder/';
const TRIP_BUILDER_LOAD_DETAILS = `${TRIP_BUILDER_BASE_URL}trips/:tripId/:operation(details|add-load)/loads/:loadId`;

export const TRIP_BUILDER_ROUTES = {
  LOADS_TRIP_BUILDER_TRIP_GENERAL: `${TRIP_BUILDER_BASE_URL}:filter(trips|new-trip)/:tripId?/:subRoute(add-load|loads)?/:loadId?/:subsubroutes(pc-miler-map)?${COMPANY_SEARCH}${BACHKHAUL_ROUTE}${POST_TRUCK}/`,
  TRIP_BUILDER_BASE_URL: TRIP_BUILDER_BASE_URL,
  LOADS_TRIP_BUILDER_MODAL: `${TRIP_BUILDER_BASE_URL}new-trip/:modal${TRIP_BUILDER_MODALS}?/:selectStates(selectStates)?/:region(USA|Canada|Both)?/`,
  LOADS_TRIP_BUILDER_NEW_TRIP: `${TRIP_BUILDER_BASE_URL}new-trip/`,
  LOADS_TRIP_BUILDER_TRIP: `${TRIP_BUILDER_BASE_URL}trips/:tripId/details/`,
  LOADS_TRIP_BUILDER_TRIP_MAP: `${TRIP_BUILDER_BASE_URL}trips/:tripId/details/trip-map/:routeIndex?/`,
  LOADS_TRIP_BUILDER_ADD_LOAD: `${TRIP_BUILDER_BASE_URL}trips/:tripId/add-load/`,
  LOADS_TRIP_BUILDER_LOAD_DETAILS: `${TRIP_BUILDER_LOAD_DETAILS}/`,
  LOADS_TRIP_BUILDER_LOAD_DETAILS_MAP: `${TRIP_BUILDER_LOAD_DETAILS}/pc-miler-map/`,
  LOADS_TRIP_BUILDER_LOAD_DETAILS_COMMUNICATION: `${TRIP_BUILDER_LOAD_DETAILS}${COMMUNICATION_NESTED_PANEL}`,
  LOADS_TRIP_BUILDER_LOAD_DETAILS_POST_TRUCK: `${TRIP_BUILDER_LOAD_DETAILS}${POST_TRUCK}`,
  LOADS_TRIP_BUILDER_LOAD_DETAILS_BACKHAUL: `${TRIP_BUILDER_LOAD_DETAILS}${BACHKHAUL_ROUTE}`,
  LOADS_TRIP_BUILDER_LOAD_DETAILS_COMPANY_SEARCH: `${TRIP_BUILDER_LOAD_DETAILS}${COMPANY_SEARCH}`,
  LOADS_TRIP_BUILDER_LOAD_DETAILS_COMPANY_SEARCH_BACKHAUL: `${TRIP_BUILDER_LOAD_DETAILS}${COMPANY_SEARCH}${BACHKHAUL_ROUTE}`,
};
const POSTING_CONTACTS = `/:postingContacts(posting_contacts)?/:postingContactsModal(add_contact|edit_contact)?/:contactID(${GUID_REGEX})?`;

export const Routes = {
  DASHBOARD: '/',
  ALERTS: '/alerts/',
  BIDS_BASE_ROUTE: `/bids/:tab(bids|interactive-bids)?/`,
  BIDS: `/bids/:filter(filter)?/:tab(bids|interactive-bids)?/:summary(summary)?/:loadDetails(load-details)?${BIDS_NESTED}${POST_TRUCK}`,
  ANNOUNCEMENTS: '/announcements/',
  ANNOUNCEMENTS_VIEW: `/announcements/:viewAnnouncement(view-announcement)?/:id(${GUID_REGEX})?/`,
  COMMUNICATION: `/communication${COMMUNICATION_MESSAGE}/:loadMap(pc-miler-map)?${POST_TRUCK}`,
  FLAGS: '/flags/',

  LOADS_FINDLOADS: `/loads/search/:search(new|edit)?/:modal${SEARCH_LOADS_MODALS}?/:selectStates(selectStates)?/:region(USA|Canada|Both)?/:truckId(${GUID_REGEX})?/:map(map)?/:results(results)?/:filters(filters)?/:sortBy(sortBy)?/:changeView(changeView)?/:loadID(${GUID_REGEX})?/:loadMap(pc-miler-map)?${COMPANY_SEARCH}${BACHKHAUL_ROUTE}${COMMUNICATION_NESTED_PANEL}${POST_TRUCK}`,

  LOADS_LOAD_AVAILABILITY: `/loads/load-availability/:filter(equipment-type|region)?/:results(outbound|intrastate|inbound)?/:map(map)?/:searchFilter(searchFilter)?/:mapResults(results)?/:sortBy(sortBy)?/:load(load)?/:loadID(${GUID_REGEX})?/:changeView(changeView)?/:loadMap(pc-miler-map)?${POST_TRUCK}${COMPANY_SEARCH}${BACHKHAUL_ROUTE}${COMMUNICATION_NESTED_PANEL}`,
  LOADS_LOAD_AVAILABILITY_MAP: `/loads/load-availability/density-map/:filter(equipment-type|region)?/:results(outbound|intrastate|inbound)?/:map(map)?/:searchFilter(searchFilter)?/:mapResults(results)?/:sortBy(sortBy)?/:load(load)?/:loadID(${GUID_REGEX})?/:changeView(changeView)?/:loadMap(pc-miler-map)?${POST_TRUCK}${COMPANY_SEARCH}${BACHKHAUL_ROUTE}${COMMUNICATION_NESTED_PANEL}`,
  LOADS_LOAD_PLANNER: '/loads/load-planner/',
  ...TRIP_BUILDER_ROUTES,
  LOADS_MY_LOADS: `/loads/my-loads/:filter(viewed|hidden|note|saved|called|bids|booked|scheduled_for_pickup|pickup_complete|load_en_route|delivered|contacted)?/:map(map)?/:results(results)?/:changeView(changeView)?/:loadID(${GUID_REGEX})?/:loadMap(pc-miler-map)?${COMPANY_SEARCH}${BACHKHAUL_ROUTE}${COMMUNICATION_NESTED_PANEL}${POST_TRUCK}`,
  LOADS_CARRIERS: '/loads/carriers/:filter(favorite|blocked-hidden)?/:carrierID?/:company(company)?/:companyID?',
  LOADS_COMPANIES: `/loads/companies/:filter(onboarded|favorite|blocked-hidden)?/:companyID?${COMPANY_SEARCH}${BACHKHAUL_ROUTE}${COMMUNICATION_NESTED_PANEL}${POST_TRUCK}`,
  LOADS_MY_COMPANIES: '/loads/my-companies/',
  LOADS_MY_LOADS_POST:
    '/post-loads/post/:actionType(single|multiple|online|offline|assigned|delivered)?' +
    `/:postedPanelModals(${postedLoadsModalPanels})?/:filterPanelModals(${filterPanelModalPanels})?` +
    `/:load(load)?/:loadID(${GUID_REGEX})?/:loadMap(loadMap)?/:editing(edit|clone)?/:postPanelModals(${postLoadsModalPanels})?/:ruleIndex([0-9]*)?${COMMUNICATION_NESTED_PANEL}${POSTING_CONTACTS}`,
  LOADS_STANDALONE_LOAD_DETAILS: `/load/:loadID(${GUID_REGEX})/:loadMap(pc-miler-map)?${COMPANY_SEARCH}${BACHKHAUL_ROUTE}${COMMUNICATION_NESTED_PANEL}${POST_TRUCK}`,
  MYLOADS_EXPORTPOSTS: '/post-loads/export-posts/',
  MYLOADS_UPLOADPOSTS: '/post-loads/upload-posts/',

  MYTRUCKS_POST_BASE: '/trucks/post-truck/',
  MYTRUCKS_POST: `/trucks/post-truck/:filter(${PostTruckRoutes.RealTimePosts}|${PostTruckRoutes.ScheduledPosts}|${PostTruckRoutes.ExpiredPosts}|${PostTruckRoutes.Alerts})?${TRUCK_ALERTS}/:loadMap(pc-miler-map)?${POST_TRUCK}${COMMUNICATION_NESTED_PANEL}`,
  FINDTRUCKS_LOCATETRUCK: `/find-trucks/:truckLocatorType(${TruckLocatorRoutes.InternalTruckLocator}|${TruckLocatorRoutes.TruckLocator})/:id/:from(postLoad)?/:truck(viewtruck)?/:truckID?`,
  FINDTRUCKS_TRUCKLOCATOR: `/find-trucks/:truckLocatorType(${TruckLocatorRoutes.InternalTruckLocator}|${TruckLocatorRoutes.TruckLocator})/`,

  INTEGRATION_SERVICES_TMSINT: '/post-loads/tms-integration/',
  INTEGRATION_SERVICES_HTMLEMBED: '/post-loads/html-embed-code/',
  INTEGRATION_SERVICES_POSTINGAID: '/post-loads/posting-aid/',

  PUB_SHARELOAD: '/pub/load/:poster(poster)?/:id',

  TOOLS_BORDER_WAIT_TIMES: '/tools/border-wait-times/',
  TOOLS_FMCSA_SEARCH: '/tools/fmcsa-search/',
  TOOLS_FUEL_PRICES: '/tools/fuel-prices/',
  TOOLS_GET_PAID_FASTER: '/tools/get-paid-faster/',
  TOOLS_PROFIT_CALCULATOR_PAGE: '/tools/profit-calculator/:modal(pickup|dropoff)?/',
  TOOLS_MARKET_RATES: `/tools/market-rates/:editing(post)?/:modalPanels(capacity-filters|capacity-sort)?/:filterPanelModals(${filterPanelModalPanels})?/:postPanelModals(${postLoadsModalPanels})?${POSTING_CONTACTS}`,
  TOOLS_CAPACITY_FINDER: '/tools/capacity-finder/:map(map)?/:modalPanels(equipment|capacity-sort|capacity-filters)?',
  TOOLS_MARKET_RATES_CARRIER: `/tools/market-rates-carrier${POST_TRUCK}`,
  TOOLS_MILEAGE_CALCULATOR: '/tools/mileage-calculator/',
  TOOLS_MILEAGE_CALCULATOR_SEARCH: '/tools/mileage-calculator/:search(stops)?',
  TOOLS_MILEAGE_CALCULATOR_MILEAGE_ROUTING: '/tools/mileage-calculator/mileage_and_routing/',
  TOOLS_REEFER_RATES: '/tools/reefer-rates/',
  TOOLS_ROADSIDE_ASSIST: '/tools/roadside-assistance/',
  TOOLS_TRUCKING_AUTHORITY: '/tools/trucking-authority/',
  TOOLS_SAVE_AT_THE_PUMP: '/tools/save-at-the-pump/',
  TOOLS_MY_DOCUMENTS:
    '/tools/my-documents/:viewType(folders|dates|types)/:documents(documents)?/:documentsSortBy(documents-sort-by)?' +
    '/:newDocument(new-document)?/:sendDocuments(send-documents)?' +
    `/:feedback(succeed)?/:viewDocument(view-document)?/:select(folder|type)?/:panel(sort-by|view)?/:loadID(${GUID_REGEX})?/:loadMap(pc-miler-map)?${POST_TRUCK}${COMMUNICATION_NESTED_PANEL}/:renameDocument(rename-document)?` +
    '/:folder(create|edit)?/:failure(failed-document-send|failed-document-creation|failed-folder-exists|failed-folder-creation|failed-folder-edit|failed-document-rename|failed-document-download|invalid-characters-failure)?/',
  TOOLS_HOT_LEADS: '/tools/hot-leads/',

  RESOURCES_CREDIT_REPORT: '/resources/credit-report/',
  RESOURCES_SEARCH_DIRECTORY_BASE: '/resources/search-directory/',
  RESOURCES_SEARCH_DETAILS_DIRECTORY: '/resources/search-directory/details/',
  RESOURCES_SEARCH_DIRECTORY: `/resources/search-directory/:modal(location)?/:results(results)?/:sortBy(sortBy)?/:changeView(changeView)?/:companyId([0-9]+)?${COMPANY_SEARCH}${BACHKHAUL_ROUTE}${COMMUNICATION_NESTED_PANEL}${POST_TRUCK}`,

  MORE_SETTINGS_MANAGE_USERS: '/more/settings/manage-users/',
  MORE_SETTINGS_PAYMENT_OPTIONS: '/more/settings/payment-options/',
  MORE_SETTINGS_GENERAL_SETTINGS: '/more/settings/general-settings/',
  MORE_SETTINGS_TOOLS_SETTINGS: '/more/settings/tools-settings/',
  MORE_SETTINGS_ALERTS_SETTINGS: '/more/settings/alerts-settings/',
  MORE_SETTINGS_COMPANY_WEBSITE: '/more/settings/company-website/',
  MORE_SETTINGS_ACCOUNT_REDIR: '/more/settings/account-redir/',
  MORE_SETTINGS_ACCOUNT_SETUP: '/more/settings/account-setup/',
  MORE_SETTINGS_ACCOUNT_NOTICE: '/more/settings/account-notice/',
  MORE_SETTINGS_ACCOUNT_VERIFICATION: '/more/settings/account-verification/',
  MORE_SETTINGS_ACCOUNT_SETUP_NEW:
    '/more/settings/account_setup/:modal(add_on|manage_plan)?/:subroutes(standard|premium|premium_plus)?',
  MORE_SETTINGS_BASE_ROUTE: '/more/settings/',
  // /more/settings/business_profile/ is referenced in intg (see intg-*-client.js files)
  MORE_SETTINGS:
    '/more/settings/:filter(cost_per_day|load_visibility_rules|onboarded_carriers|manage_plan|profile|billing_summary|my_truck|business_profile|users|dispatchers|private_load_board|payment_history|payment_method|notifications|alerts_sound|mileage_and_routing|manage_fuel_mileage|privacy_control|search_results_settings|login_sessions|integrators|terms_of_service|privacy_policy|open_source_liscence|exporting_load_details|posting_contacts)?' +
    '/:sort_by(sort_by_year)?/:subroutes(edit_cost_per_day|review_carriers|edit_carriers|create_rule_set|edit_rule_set|type|add_user|edit_user|edit_dispatcher|add_members|integrations|new_truck|edit_truck|full_name|phone|standard|premium|premium_plus|add_on|invoice|edit_business_profile)?' +
    `/:ruleSetId([0-9]*)?/:modal(statePicker|edit_rule|add_rule)?/:ruleId([0-9]*)?/:id(${GUID_REGEX})?/:integrationId(${HEX40_REGEX})?${POSTING_CONTACTS}`,

  // /more/settings/profile/ is referenced in intg (see intg-*-client.js files)
  MORE_SETTINGS_PROFILE_BASE: '/more/settings/profile/',
  MORE_SETTINGS_PAYMENT_HISTORY_BASE: '/more/settings/payment_history/',

  MORE_SETTINGS_PAYMENT_HISTORY: `/more/settings/payment_history/:sort_by(sort_by_year)?/:subroutes(invoice)?/:id(${GUID_REGEX})?`,
  MORE_SETTINGS_ACTIVATE_BY_CARD: '/more/settings/account-activate-by-credit-card/',
  MORE_SETTINGS_PAYMENT_METHOD: '/more/settings/payment_method/',
  MORE_SETTINGS_MANAGE_PLAN: '/more/settings/manage_plan/',
  MORE_SETTINGS_MILEAGE_ROUTING: '/more/settings/mileage_and_routing/',
  MORE_SETTINGS_USER: `/more/settings/users`,
  MORE_SETTINGS_EDIT_USER: `/more/settings/users/edit_user/:id(${GUID_REGEX})?`,
  MORE_SETTINGS_EDIT_TRUCK: `/more/settings/my_truck/edit_truck/:id(${GUID_REGEX})?`,
  MORE_SETTINGS_PRIVATE_LOADBOARD: `/more/settings/private_load_board/:subRoute(add-members)?/`,

  MORE_HELP_BASE_ROUTE: '/more/help/',
  MORE_HELP: `/more/help/:filter(service_code|screen_sharing)?`,
  MORE_HELP_SERVICE_CODE: '/more/help/service_code/',
  MORE_HELP_SCREEN_SHARING: '/more/help/screen_sharing/',

  //new web unsubscribe assistant
  MEMBERSHIPS_UNSUBSCRIBE: '/unsubscribe/',
  MEMBERSHIPS_UNSUBSCRIBE_REASONS: `/unsubscribe/:reasons?/:reason(${unsubscribeAssistantReasons})?`,

  MORE_LOGOUT: '/logout/',

  NOTFOUND: '/not-found/',

  WELCOMEBACK_CAMPAIGN: '/welcomeback/',

  // integrations portal-specific routes
  CLIENTS: `/clients/:env(DEV|LIVE)/:guid(${GUID_REGEX})?/`,
  CLIENTS_DEV: '/clients/DEV/',
  CLIENTS_LIVE: '/clients/LIVE/',

  ...PlainRoutes,
};
export const urlForLocateTruck = (loadid: string, truckLocatorType: string): string =>
  `${generatePath(Routes.FINDTRUCKS_LOCATETRUCK, { truckLocatorType: truckLocatorType, id: loadid })}/`;
export const urlForLoadSearch = `${generatePath(Routes.LOADS_FINDLOADS)}/`;
export const orderedPaths = (routes: { [key: string]: string } | string[]) =>
  orderBy(values(routes), undefined, 'desc');

export const ASPX_ROUTES = {
  GENERIC_ONLINE_PREFIX: '/online/',
  GENERIC_ROOT_PREFIX: '/onlineroot/',
  HELP_PREFIX: '/online/help/',
  FIND_LOADS: '/online/carrier/',
};

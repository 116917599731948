import React, { CSSProperties } from 'react';

import { first } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath, matchPath, RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

import { Routes } from '@/router/Routes';
import { displayCurrencyOrDash, hasDecimal, NO_INFORMATION_SYMBOL } from '@common/formatter';
import { formatDateDotSeparatedWithTime, textFromLocation } from '@common/helper';
import { BidLoadInfo, BidStatus, BidSummary, PayRateUnit } from '@common/model';
import { VendorBid } from '@common/model/VendorBid';
import { fetchLoadBids } from '@common/redux/epic/bids/BidsEpic';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { usePushPanel, usePushPanelWithPathParams } from '@component/panel/PanelHooks';
import { Text } from '@component/text/Text';
import {
  BID_ID_INDEX,
  BID_LOAD_ID_INDEX,
  bidIDFromRouteProps,
  bidLoadIDFromRouteProps,
  BIDS_SUMMARY_PANEL_PATH,
  bidsEncoder,
  BidsRouteParamsProps,
  BidsTabs,
} from '@page/bids/BidsPanelRouting';
import { getBidSummaryStatusInfo } from '@page/bids/BidStatusHelper';
import { BIDDING_SUMMARY_CARD_HEIGHT, BIDS_LIST_CARD_HEIGHT } from '@page/bids/style/BidsStyleHelper';
import { CompanyLogo } from '@page/truckLocator/locateTruck/CompaniesAvatarLogo';
import { Color } from '@style/Color';
import { FontFamily, FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';
import { MediaQueries } from '@util/MediaQueries';

interface CardProps extends ButtonProps {
  isSelected: boolean;
  hasNewMessages: boolean;
}

interface BidCardsProps {
  bidId: string;
  isSelected: boolean;
  newMessages: number;
  isStandalone?: boolean;
  currentBid?: BidSummary;
  vendorBidSummary?: VendorBid;
}

type Props = BidCardsProps & RouteComponentProps<BidsRouteParamsProps>;

const SELECTED_BOTTOM_BORDER = `2px solid ${Color.ORANGE_MAIN}`;
const BOTTOM_BORDER = `1px solid ${Color.GRAY_SMOKE}`;

const CardContainer = styled(({ isSelected, hasNewMessages, ...rest }: CardProps) => <Button {...rest} />)`
  && {
    padding: 8px 16px;
    min-height: ${BIDS_LIST_CARD_HEIGHT}px;
    width: 340px;
    display: inline;
    border-radius: 0;
    line-height: 20px;
    background: ${(props) => (props.isSelected ? Color.ORANGE_LIGHT : Color.WHITE)};
    text-transform: none;
    font-weight: ${(props) => (props.hasNewMessages && !props.isSelected ? FontWeight.Bold : FontWeight)};
    border-bottom: ${(props) => (props.isSelected ? SELECTED_BOTTOM_BORDER : BOTTOM_BORDER)};
    margin-bottom: 4px;
    @media ${MediaQueries.lt_md} {
      width: 100%;
    }
  }
`;

const CardText = styled(Text)`
  font-family: ${FontFamily.Main};
  text-align: start;
  color: ${(props: { isGreyedOut?: boolean }) => (props.isGreyedOut ? Color.GRAY_21 : Color.GRAY_TEXT)};
`;

const CardCompanyNameText = styled(CardText)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    max-width: 160px;
  }
`;

const CardContentText = styled(CardText)`
  && {
    font-size: ${FontSize.ContentDetail}px;
    font-weight: normal;
    padding-left: 6px;
    padding-right: 6px;
    max-width: 260px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Status = styled.div`
  padding: 0 3px;
  color: white;
  margin: 2px 2px 0px 2px;
  height: 16px;
  display: flex;
  flex-direction: column;
  line-height: 16px;
  border-radius: 2px;
  font-size: 9px;
  font-weight: bold;
  min-width: 60px;
  margin-left: ${Spacing.InterElementHorizontal}px;
  align-items: center;
  align-self: flex-end;
  background: ${(props: { color: string }) => props.color};
  text-transform: uppercase;
`;

const DateStatusWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const NotificationDotWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const BidsNotificationDot = withTheme()(styled.div`
  border-radius: 100px;
  background-color: ${Color.RED_APPLE};
  border: solid 1px ${Color.WHITE};
  position: relative;
  height: 16px;
  width: 16px;
  top: 0;
  left: 0;
  right: 0;
`);

const BidCardListItemComponent = (props: Props) => {
  const history = useHistory();
  const bidID = useBidID();
  const dispatch = useDispatch();
  const isCarrier = useIsCarrier();

  const bidSummary = useSelector((state) => state.bids.bidSummaries.summaries.get(props.bidId));

  const pushPanel = usePushPanel(`/summary/`);

  const loadDetailsSearchParams = bidsEncoder.convertUrlSearchParamsToObject(history.location.search);

  if (!props.isStandalone && isCarrier && bidSummary?.load?.id !== loadDetailsSearchParams.loadId) {
    //Displays only the current card in the list
    return null;
  }

  const openOfferViewPanel = () => {
    const bidId = bidSummary?.bidId;
    const loadId = bidSummary?.load?.id;

    if (!bidId || !loadId) {
      return;
    }

    if (!isCarrier) {
      pushPanel(`${bidId}/${loadId}`);
    } else {
      dispatch(fetchLoadBids({ loadId: loadId, carrierId: 'self' }));
      pushPanel(`${bidId}/${loadId}/offer/${bidId}`);
    }
  };

  let bidSummaryInfo;
  if (bidSummary) {
    bidSummaryInfo = getBidSummaryStatusInfo(bidSummary);
  }
  if (!bidSummaryInfo) {
    return null;
  }

  return (
    <CardContainer
      key={bidID}
      theme={THEME}
      hasNewMessages={!bidSummary?.isViewed ?? false}
      isSelected={bidID === bidSummary?.bidId || loadDetailsSearchParams.bidId === bidSummary?.bidId}
      onClick={openOfferViewPanel}
      style={{ padding: '0 16px' }}
      id={bidSummary?.bidId}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <CardHeaderContainer>
          <DateStatusWrapper>
            {formatDateDotSeparatedWithTime(bidSummary?.mostRecentActivity ?? '')}
            <Status id="bid_status" color={bidSummaryInfo?.tagColor}>
              {bidSummaryInfo.tagTitle}
            </Status>
          </DateStatusWrapper>
          {!bidSummary?.isViewed ? (
            <NotificationDotWrapper>
              <BidsNotificationDot />
            </NotificationDotWrapper>
          ) : null}
        </CardHeaderContainer>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <CardText
            id="bid_summary_info_message"
            style={{
              fontSize: FontSize.ContentDetail,
              fontWeight: bidSummary?.isViewed ? FontWeight.Regular : FontWeight.Bold,
              marginBottom: Spacing.InterElementVertical,
              lineHeight: '16px',
            }}
          >
            {bidSummaryInfo?.message}
          </CardText>

          <BidLoadInfoBanner
            style={{ border: `1px solid ${Color.GRAY_SMOKE}` }}
            load={bidSummary?.load ?? undefined}
            isSelected={props.isSelected}
            hasNewMessages={!bidSummary?.isViewed ?? false}
          />
        </div>
      </div>
    </CardContainer>
  );
};
export const BidCardListItem = withRouter(BidCardListItemComponent);

const VendorBidCardListItemComponent: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const bidID = useBidID();

  const bidSummary = props.vendorBidSummary;

  const pushPanel = usePushPanel(`/summary/`);

  const loadDetailsSearchParams = bidsEncoder.convertUrlSearchParamsToObject(history.location.search);

  if (!props.isStandalone && bidSummary?.loadId !== loadDetailsSearchParams.loadId) {
    //Displays only the current card in the list
    return null;
  }

  const openLoadDetails = () => {
    pushPanel(`${bidSummary?.loadId}/${bidSummary?.loadId}/load-details/`);
  };

  const bidSummaryLoad = {
    id: bidSummary?.loadId,
    postReference: bidSummary?.referenceNumber,
    rate: bidSummary?.rate?.amount,
    originLocation: bidSummary?.originLocation,
    destinationLocation: bidSummary?.destinationLocation,
  };
  return (
    <CardContainer
      theme={THEME}
      hasNewMessages={false}
      isSelected={bidID === bidSummary?.loadId || loadDetailsSearchParams.bidId === bidSummary?.loadId}
      onClick={() => {
        openLoadDetails();
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <CardHeaderContainer>
          <DateStatusWrapper>{formatDateDotSeparatedWithTime(bidSummary?.createdOn ?? '')}</DateStatusWrapper>
        </CardHeaderContainer>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <CardText
            id="bid_summary_info_message"
            style={{
              fontSize: FontSize.Body,
              fontWeight: FontWeight.Regular,
              marginBottom: Spacing.InterElementVertical,
              lineHeight: '16px',
            }}
          >
            {t(T.common_vendorBid_bidItem_message, {
              amount: displayCurrencyOrDash(bidSummary?.rate?.amount, hasDecimal(bidSummary?.rate?.amount)),
              broker: bidSummary?.loadCompanyName,
            })}
          </CardText>

          <BidLoadInfoBanner
            style={{ border: `1px solid ${Color.GRAY_SMOKE}` }}
            load={bidSummaryLoad as BidLoadInfo}
            isSelected={props.isSelected}
            hasNewMessages={false}
          />
        </div>
      </div>
    </CardContainer>
  );
};
export const VendorBidCardListItem = withRouter(VendorBidCardListItemComponent);

const useBidID = () => {
  const location = useLocation();
  const match = matchPath<BidsRouteParamsProps>(location.pathname, {
    path: Routes.BIDS,
  });

  return match?.params.bidID;
};

const LoadInfoContainer = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: column;
  border-radius: 6px;
  line-height: 16px;
`;
const BottomLoadInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BidLoadInfoBanner: React.FC<{
  load: BidLoadInfo | undefined;
  rateAmount?: number | undefined;
  rateType?: PayRateUnit | undefined;
  style?: CSSProperties;
  hasNewMessages?: boolean;
  isSelected?: boolean;
}> = (props) => {
  const weight = props.hasNewMessages && !props.isSelected ? FontWeight.Bold : FontWeight.Regular;

  const locations = `${textFromLocation(props.load?.originLocation)} ${t(T.To)} ${textFromLocation(
    props.load?.destinationLocation
  )}`;
  const refNumber = `${t(T.common_load_Ref)}: ${props.load?.postReference || NO_INFORMATION_SYMBOL}`;
  const postedRate =
    props.load?.rate?.amount && props.load.rate.type === PayRateUnit.Flat
      ? `${t(T.loadGeneric_postedRate)}: ${displayCurrencyOrDash(
          props.load?.rate?.amount,
          hasDecimal(props.load?.rate?.amount)
        )}`
      : undefined;

  return (
    <LoadInfoContainer style={props.style} id={props.load?.id}>
      <CardContentText id="location" style={{ fontSize: FontSize.ContentDetail, fontWeight: weight }}>
        {locations}
      </CardContentText>
      <BottomLoadInfoContainer>
        <CardContentText id="reference_number" style={{ fontSize: FontSize.ContentDetail, fontWeight: weight }}>
          {refNumber}
        </CardContentText>
        {postedRate ? (
          <CardContentText id="posted_rate" style={{ fontSize: FontSize.ContentDetail, fontWeight: weight }}>
            {postedRate}
          </CardContentText>
        ) : null}
      </BottomLoadInfoContainer>
    </LoadInfoContainer>
  );
};

const IconAndCompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const InfoStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
`;

const NameDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  width: 100%;
`;

const BiddingValueStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px;
  padding-bottom: 4px;

  line-height: 16px;
`;

const BidCardSummaryItemComponent: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const bids = useSelector((state) => state.bids.loadBids.listAll.ids);
  const topLoadBidId = first(bids) ?? undefined;

  const bidSummary = useSelector((state) => state.bids.bidSummaries.summaries.get(props.bidId ?? topLoadBidId));

  const bidIdFromHistoryLocation = history.location.pathname.split('/')[BID_ID_INDEX];

  const redirectCommunicationPath = bidsEncoder.convertUrlSearchParamsToObject(
    history.location.search
  ).redirectCommunicationPath;

  const mostRecentBidID = redirectCommunicationPath ? topLoadBidId : bidIdFromHistoryLocation;
  const bidIdFromPropsOrPathname = mostRecentBidID ?? bidIDFromRouteProps(props);

  const bidLoadIdFromHistoryLocation = history.location.pathname.split('/')[BID_LOAD_ID_INDEX];
  const bidLoadIdFromPropsOrPathname = bidLoadIDFromRouteProps(props) ?? bidLoadIdFromHistoryLocation;

  const pushViewOfferPanel = usePushPanelWithPathParams();

  if (bidSummary === undefined) {
    return null;
  }

  const openOfferFromLoad = () => {
    dispatch(
      fetchLoadBids({
        loadId: bidSummary?.load?.id ?? bidLoadIdFromPropsOrPathname,
        carrierId: bidSummary?.otherParty?.contactId,
      })
    );

    const bidsParams = {
      tab: BidsTabs.InteractiveBids,
      summary: 'summary',
      bidID: bidSummary?.bidId,
      loadID: bidSummary?.load?.id ?? bidLoadIdFromPropsOrPathname,
      offerID: bidSummary?.bidId,
      edit: 'offer',
    };

    if (bidIdFromPropsOrPathname === bidSummary?.bidId || bidLoadIdFromHistoryLocation === bidSummary?.load?.id) {
      pushViewOfferPanel(
        `/${BIDS_SUMMARY_PANEL_PATH}/${bidSummary?.bidId}/${
          bidSummary?.load?.id ?? bidLoadIdFromPropsOrPathname
        }/offer/${bidSummary?.bidId}`,
        bidsParams
      );
    } else {
      history.push({
        pathname: `${generatePath(Routes.BIDS, bidsParams)}`,
      });
    }
  };

  let bidSummaryInfo;
  if (bidSummary) {
    bidSummaryInfo = getBidSummaryStatusInfo(bidSummary);
  }
  if (!bidSummaryInfo) {
    return null;
  }

  return (
    <CardContainer
      theme={THEME}
      hasNewMessages={!bidSummary?.isViewed}
      isSelected={bidIdFromPropsOrPathname === bidSummary?.bidId}
      onClick={openOfferFromLoad}
      style={{
        height: `${BIDDING_SUMMARY_CARD_HEIGHT}px`,
        minHeight: `${BIDDING_SUMMARY_CARD_HEIGHT}px`,
        padding: '0 16px',
      }}
    >
      {!bidSummary?.isViewed ? (
        <NotificationDotWrapper>
          <BidsNotificationDot style={{ position: 'absolute', top: '4px', left: '4px' }} />
        </NotificationDotWrapper>
      ) : null}
      <InfoStatusContainer style={{ marginTop: bidSummary.status === BidStatus.ActionRequired ? '4px' : '0' }}>
        <IconAndCompanyInfoContainer>
          <CompanyLogo companyId={bidSummary?.otherParty?.company.id} shouldUsePlaceholderIcon={true} />

          <NameDateContainer>
            <CardCompanyNameText
              id="title_company_name"
              style={{
                fontSize: FontSize.Content,
                fontWeight: FontWeight.Bold,
              }}
            >
              {bidSummary?.otherParty?.company.name}
            </CardCompanyNameText>
            <CardText id="subtitle_date" style={{ fontSize: FontSize.Body }} isGreyedOut={true}>
              {formatDateDotSeparatedWithTime(bidSummary?.mostRecentActivity ?? '')}
            </CardText>
          </NameDateContainer>
        </IconAndCompanyInfoContainer>
        <BiddingValueStatusContainer
          style={{
            backgroundColor: bidSummary.status === BidStatus.ActionRequired ? Color.RED_APPLE : undefined,
            height: bidSummary.status === BidStatus.ActionRequired ? '44px' : 'unset',
          }}
        >
          <CardText
            id="price"
            style={{
              fontSize: FontSize.ModalTitle,
              fontWeight: FontWeight.Bold,
              color: bidSummary.status === BidStatus.ActionRequired ? Color.WHITE : undefined,
            }}
          >
            {`${displayCurrencyOrDash(bidSummary?.price, hasDecimal(bidSummary?.price))}`}
          </CardText>
          <Status style={{ width: 'max-content' }} id="bid_status" color={bidSummaryInfo.tagColor}>
            {bidSummaryInfo.tagTitle}
          </Status>
        </BiddingValueStatusContainer>
      </InfoStatusContainer>
    </CardContainer>
  );
};

export const BidCardSummaryItem = withRouter(BidCardSummaryItemComponent);

import React from 'react';

import { isEmpty, map } from 'lodash';
import styled from 'styled-components';

import { FormControl, Input, MenuItem, Select } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { SelectProps } from '@material-ui/core/Select';

import { DisplayItem } from '@component/dropdown/DropdownList';
import { Text, TextStyle } from '@component/text';
import { ThemeProps, withTheme } from '@style/WithTheme';

const StyledFormControl = withTheme()(styled(FormControl)`
  && {
    height: 56px;
    width: 100%;
    background-color: ${(props: ThemeProps & FormControlProps) =>
      props.disabled ? props.theme.palette.inputField.disabledBackground : props.theme.palette.inputField.background};
    &:hover {
      background-color: ${(props: ThemeProps & FormControlProps) =>
        props.disabled ? props.theme.palette.inputField.disabledBackground : props.theme.palette.inputField.hover};
    }
  }
`);

const ReadOnlyStyledFormControl = withTheme()(styled(StyledFormControl)`
  && {
    &:hover {
      background-color: ${(props: ThemeProps & FormControlProps) =>
        props.disabled ? props.theme.palette.inputField.disabledBackground : props.theme.palette.inputField.background};
    }
  }
`);

const SelectContainer = styled.div`
  height: calc(100% - 7px);
  margin-top: 7px;
  position: relative;
`;

const SelectLabel = withTheme()(styled.label`
  margin-left: 12px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  padding: 0;
  transform: ${(props: ThemeProps & { isLabelShrinked: boolean; isDisabled?: boolean; error?: boolean }) =>
    props.isLabelShrinked ? 'translate(0, 0) scale(0.75)' : 'translate(0, 12px) scale(1)'};
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${(props: ThemeProps & { isLabelShrinked: boolean; isDisabled?: boolean; error?: boolean }) =>
    props.isDisabled
      ? props.theme.text.inputFieldLabel.disabled
      : props.error
        ? props.theme.palette.status.alert
        : props.theme.text.inputFieldLabel.color};
`);

const StyledSelect = withTheme()(styled(Select)`
  && {
    height: calc(100% + 7px);
    margin-top: -7px;

    > div {
      > div {
        height: 100%;
        padding: 27px 36px 7px 12px;
        box-sizing: border-box;
      }
      > svg {
        right: 12px;
        color: ${(props: ThemeProps & SelectProps) =>
          props.disabled ? props.theme.text.inputFieldValue.disabled : props.theme.text.inputFieldValue.color};
      }
    }
    &:before {
      border-bottom-width: ${(props: ThemeProps & SelectProps) => (props.error ? '2px' : '1px')} !important;
      border-bottom-style: solid !important;
      border-bottom-color: ${(props: ThemeProps & SelectProps) =>
        props.error
          ? props.theme.palette.status.alert
          : props.disabled
            ? props.theme.palette.inputField.disabledUnderline
            : props.theme.text.inputFieldValue.color} !important;
    }
    &:after {
      background-color: ${(props: ThemeProps & SelectProps) =>
        props.error ? props.theme.palette.status.alert : props.theme.text.inputFieldValue.color};
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: ${(props: ThemeProps & SelectProps) =>
        props.error ? props.theme.palette.status.alert : props.theme.text.inputFieldValue.color} !important;
    }
  }
`);

const ReadOnlyStyledSelect = styled(StyledSelect)`
  && {
    pointer-events: none;
    > div svg {
      display: none;
    }
  }
`;

export interface CustomSelectProps extends SelectProps {
  id: string;
  label?: string;
  options?: DisplayItem[];
  readOnly?: boolean;
}

class ThemelessCustomSelect extends React.PureComponent<CustomSelectProps> {
  state = {
    isLabelShrinked: false,
  };

  componentDidMount() {
    if (!isEmpty(this.props.value)) {
      this.shrinkLabel();
    }
  }

  mapSelectOptions = (id: string) => {
    return map(this.props.options, (option: DisplayItem, index: number) => {
      return (
        <MenuItem id={id + '_item_' + index} value={option.key} key={index}>
          {option.label}
        </MenuItem>
      );
    });
  };

  shrinkLabel = () => {
    this.setState({ isLabelShrinked: true });
  };

  growLabel = () => {
    this.setState({ isLabelShrinked: false });
  };

  onFocus = () => {
    this.shrinkLabel();
  };

  onBlur = () => {
    if (isEmpty(this.props.value)) {
      this.growLabel();
    }
  };

  getInput = () => (this.props.readOnly ? <Input /> : undefined);

  public render() {
    const CustomStyledSelect = this.props.readOnly ? ReadOnlyStyledSelect : StyledSelect;
    const CustomStyledFormControl = this.props.readOnly ? ReadOnlyStyledFormControl : StyledFormControl;
    return (
      <CustomStyledFormControl disabled={this.props.disabled}>
        <SelectContainer id={this.props.id}>
          <SelectLabel
            isLabelShrinked={this.state.isLabelShrinked}
            isDisabled={this.props.disabled}
            error={this.props.error}
          >
            <Text id={this.props.id + '_selector_label'} textStyle={TextStyle.InputFieldLabel}>
              {this.props.label}
            </Text>
          </SelectLabel>
          <CustomStyledSelect
            id={this.props.id + '_selector_options'}
            value={this.props.value}
            fullWidth={true}
            onChange={this.props.onChange}
            inputProps={this.props.inputProps}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={this.props.disabled}
            error={this.props.error}
          >
            {this.mapSelectOptions(this.props.id + '_selector_options')}
          </CustomStyledSelect>
        </SelectContainer>
      </CustomStyledFormControl>
    );
  }
}

export const CustomSelect = ThemelessCustomSelect;

import React from 'react';

import { map } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

import { VendorBid } from '@common/model/VendorBid';
import { InfiniteScrollView } from '@component/scrollView';
import { VendorBidCardListItem } from '@page/bids/BidCards';
import { BID_ID_INDEX, BidsRouteParamsProps } from '@page/bids/BidsPanelRouting';

interface VendorBidsListProps {
  vendorBidsSummary: VendorBid[];
  isStandalone: boolean;
  scrollRef: React.RefObject<HTMLInputElement>;
  isLoading?: boolean;
  isLastPage?: boolean;
}

export const VendorBidsList = (props: VendorBidsListProps) => {
  const history = useHistory();
  const params = useParams<BidsRouteParamsProps>();

  const bidIdFromHistoryLocation = history.location.pathname.split('/')[BID_ID_INDEX] ?? params.bidID;

  const hasMore = props.isLastPage !== undefined ? !props.isLastPage : false;

  return (
    <InfiniteScrollView id="vendor_bids_list" hasMore={hasMore} rootRef={props.scrollRef} isLoading={!!props.isLoading}>
      {map(props.vendorBidsSummary, (bid, index) =>
        bid !== undefined ? (
          <VendorBidCardListItem
            key={`VendorBidCardListItem_${bid}_${index}`}
            newMessages={0}
            isSelected={bidIdFromHistoryLocation === bid.loadId}
            bidId={bid.loadId}
            vendorBidSummary={bid}
            isStandalone={props.isStandalone}
          />
        ) : null
      )}
    </InfiniteScrollView>
  );
};

import React, { Fragment, useState } from 'react';

import { isEmpty, map } from 'lodash';
import styled from 'styled-components';

import { useContactOptions } from '@/util/helper/ContactOptionsHelper';
import { Load, LoadContactOption } from '@common/model';
import { InteractiveBidButton, VendorBidButton } from '@component/panels/findLoads/loadDetailsPanel/buttons/BidButton';
import { EmailButton } from '@component/panels/findLoads/loadDetailsPanel/buttons/EmailButton';
import { MessageButton } from '@component/panels/findLoads/loadDetailsPanel/buttons/MessageButton';
import { PhoneButton } from '@component/panels/findLoads/loadDetailsPanel/buttons/PhoneButton';
import { VhubBookWithButton } from '@component/panels/findLoads/loadDetailsPanel/buttons/VhubBookWithButton';

import { BookNowButton } from './BookNowButton';

const MIN_WIDTH_FOR_BUTTON_TEXT = 340;
const MESSAGE_MAX_CROP_WIDTH = 450;
const CALL_MAX_CROP_WIDTH = 325;
const ContactButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: end;
`;

export interface LoadContactCallbackProps {
  onCallClick: () => void;
  onEmailClick: () => void;
  onInteractiveBidClick: () => void;
  onVendorBidClick: () => void;
  onBookNowClick: () => void;
}

interface Props extends LoadContactCallbackProps {
  load: Load | undefined;
  isOriginMessaging: boolean;
  isOriginBids: boolean;
  path: string;
  width: number;
}

interface ButtonProps extends Props {
  isPhoneShown: boolean;
  onCloseCall: () => void;
}

export const ContactButtons: React.FC<Props> = (props) => {
  const [isPhoneShown, setIsPhoneShown] = useState(false);

  const onCallClick = () => {
    if (isPhoneShown) {
      props.onCallClick();
    }
    setIsPhoneShown(true);
  };

  const onCloseCall = () => {
    setIsPhoneShown(false);
  };

  const contactOptions = useContactOptions(props.load, props.isOriginMessaging, props.isOriginBids);

  return (
    <ContactButtonsContainer>
      {isEmpty(contactOptions)
        ? null
        : map(contactOptions, (option, index, options) => (
            <Fragment key={option}>
              <ContactOption
                {...props}
                isPhoneShown={isPhoneShown}
                onCallClick={onCallClick}
                onCloseCall={onCloseCall}
                option={option}
                index={index}
                options={options}
              />
              {index < options.length - 1 && !isPhoneShown ? <div style={{ width: '4px' }} /> : null}
            </Fragment>
          ))}
    </ContactButtonsContainer>
  );
};

const ContactOption = (
  props: ButtonProps & { option: LoadContactOption; index: number; options: LoadContactOption[] }
) => {
  if (props.isPhoneShown && props.option !== LoadContactOption.Call) {
    return null;
  }

  const isSmallDevice = props.width < MIN_WIDTH_FOR_BUTTON_TEXT;

  const buttonProps = {
    load: props.load,
    isFullSize: getIsFullSize(props.index, props.options.length, isSmallDevice),
    isLabelHidden: props.options.length === 5 && isSmallDevice,
  };

  switch (props.option) {
    case LoadContactOption.Bid:
      return <InteractiveBidButton {...buttonProps} onClick={props.onInteractiveBidClick} />;
    case LoadContactOption.VendorBid:
      return <VendorBidButton {...buttonProps} onClick={props.onVendorBidClick} />;
    case LoadContactOption.BookNow:
      return <BookNowButton {...buttonProps} onClick={props.onBookNowClick} />;
    case LoadContactOption.Call:
      return (
        <PhoneButton
          isPhoneButtonMaximized={props.isPhoneShown}
          onClick={props.onCallClick}
          onClose={props.onCloseCall}
          shouldCropLabel={props.options.length > 2 && props.width < CALL_MAX_CROP_WIDTH}
          {...buttonProps}
        />
      );
    case LoadContactOption.Email:
      return (
        <EmailButton
          {...buttonProps}
          isFullSize={props.options.length === 1 || (props.options.length === 5 && isSmallDevice)}
        />
      );
    case LoadContactOption.Message:
      return (
        <MessageButton
          path={props.path}
          shouldCropLabel={props.options.length > 2 && props.width < MESSAGE_MAX_CROP_WIDTH}
          {...buttonProps}
        />
      );
    case LoadContactOption.VhubBook:
      return <VhubBookWithButton loadID={props.load?.id} vHubURL={props.load?.vHub?.url} />;
    default:
      return null;
  }
};

const getIsFullSize = (index: number, numOptions: number, isSmallDevice: boolean) => {
  if (isSmallDevice && numOptions === 5) {
    return true;
  }
  switch (numOptions) {
    case 1:
    case 2:
      return true;
    case 3:
      return index >= 1;
    case 4:
      return (index === 2 && !isSmallDevice) || index === 3;
    case 5:
      return index === 4;
    default:
      return false;
  }
};

import React, { RefObject, useRef } from 'react';

import styled from 'styled-components';

import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Spacing } from '@style/StyleConstants';
import { useLoadMoreIfNecessary } from '@util/hooks/UseLoadMoreIfNecessary';

const DEFAULT_SPACE_HEIGHT = 60;

interface Props {
  id?: string;
  loadMore?: () => void;
  hasMore: boolean;
  hideLoader?: boolean;
  rootRef?: RefObject<HTMLDivElement>;
  isReverse?: boolean;
  isLoading: boolean | undefined;
  threshold?: string;
  bottomSpace?: number;
}

export const ProgressSpacer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${Spacing.ElementPaddingHorizontal}px;
`;

/** InfiniteScroll wrapper that will
 *
 *  - switches to Window scroll automatically
 *  - generate our standard loading spinner
 *  - generate a "spacer" at the end (to signify end of list to the user)
 */
export const InfiniteScrollView: React.FC<Props> = ({
  id,
  loadMore,
  hasMore,
  children,
  hideLoader,
  isLoading,
  rootRef,
  isReverse = false,
  threshold = '300px',
  bottomSpace = DEFAULT_SPACE_HEIGHT,
}) => {
  const observerRef = useRef<HTMLDivElement>(null);
  useLoadMoreIfNecessary(hasMore, isLoading, observerRef, rootRef, isReverse, loadMore);
  const spaceHeight = isReverse ? DEFAULT_SPACE_HEIGHT : bottomSpace;

  let topSpacer: JSX.Element | null = null;
  let bottomSpacer: JSX.Element | null = null;
  if (hasMore) {
    const spacer = (
      <div style={{ position: 'relative', height: spaceHeight }}>
        <div
          ref={observerRef}
          style={{
            position: 'absolute',
            bottom: isReverse ? undefined : threshold,
            top: isReverse ? threshold : undefined,
            height: 1,
            width: 1,
            pointerEvents: 'none',
          }}
        />
        {isLoading && !hideLoader ? (
          <ProgressSpacer key={0}>
            <LoadingSpinner id="loading_progress" />
          </ProgressSpacer>
        ) : null}
      </div>
    );
    if (isReverse) {
      topSpacer = spacer;
    } else {
      bottomSpacer = spacer;
    }
  }

  return (
    <div id={id}>
      {topSpacer}
      {children}
      {bottomSpacer}
    </div>
  );
};

import { OnboardedCarriers, OnboardedCarriersResponse } from '@/model';
import { BaseClient } from '@common/client/BaseClient';

export class OnboardedCarriersClient extends BaseClient {
  modifyOnboardedCarriers$ = (usdotNumbers: string[]) =>
    this.api.put$<{}>('/broker/preferredCarriers', { usdotNumbers: usdotNumbers });

  deleteOnboardedCarriers$ = (request: OnboardedCarriers) =>
    this.api.post$<{}>('/broker/preferredCarriers/delete', request);

  //@TODO: MEM-3786 remove custom limit and add pagination support
  /** default values on the server - offset = 0, limit = 20 */
  getOnboardedCarriers$ = (offset?: number, limit: number = 10000) =>
    this.api.get$<OnboardedCarriersResponse>('/broker/preferredCarriers', { offset: offset, limit: limit });
}

import React from 'react';

import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { formatThousands } from '@common/helper/NumberHelper';
import { NotificationCount } from '@component/menu/menuStyles';
import { Color } from '@style/Color';
import { FontWeight } from '@style/StyleConstants';

const WidgetButton = styled(Button)`
  flex: 1;
  line-height: 1.5;
  justify-content: space-between;
  background-color: ${Color.WHITE};
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  max-width: 150px;
  max-height: 50px;
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: ${Color.WHITE};
    font-weight: ${FontWeight.Regular};
    font-size: 18px;
    color: #038fe5;
    &:hover {
      background-color: ${Color.WHITE};
    }
  }
`;

const Icon = styled.img`
  width: 36px;
  margin-right: 5px;
`;

const Title = styled.span`
  text-transform: none;
`;
const IconContainer = styled.div`
  position: relative;
  display: flex;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-direction: row;
`;

const UnreadCounter = styled(NotificationCount)`
  height: 34px;
  font-size: 16px;
  line-height: 36px;
  width: 28px;
  max-height: 36px;
  padding: 0 4px;
  border-radius: 4px;
  margin: 0 5px 0 0;
  justify-content: center;
  align-self: center;
`;

interface Props {
  id: string;
  title: string;
  icon?: string;
  notificationCount?: number;
  onClick: () => void;
}

export const MessageWidgetButton: React.FC<Props> = (props) => (
  <WidgetButton id={props.id} onClick={props.onClick}>
    <Content>
      <IconContainer>
        {props.icon ? (
          <Icon id="icon" src={props.icon} />
        ) : (
          <UnreadCounter opened={true} hasNotifications={true} isMessageWidget={true}>
            <span>{formatThousands(props.notificationCount)}</span>
          </UnreadCounter>
        )}
      </IconContainer>

      <Title id="title">{props.title}</Title>
    </Content>
  </WidgetButton>
);

import React from 'react';

interface Props {
  image: string;
  alt?: string;
  className?: string;
  onClick?: () => void;
}

export const ImageBase64 = (props: Props) => (
  <img
    className={props.className}
    src={`data:image/png;base64, ${props.image}`}
    alt={props.alt}
    onClick={props.onClick}
  />
);

import { first } from 'lodash';

import { clipLatLongValue } from '@common/helper';
import {
  DestLocation,
  LoadGeolocation,
  LoadLocation,
  LoadLocationCity,
  LoadLocationStates,
  LoadLocationZip,
  LocationSuggestion,
  LocationType,
  OriginLocation,
  SuggestionTypes,
} from '@common/model';
import { t, T } from '@translate';

/** types are exclusively for the form support with Strings.
 *  When is of type string, it represents the text the user is typing in the textfield.
 */
export type LoadPickup = string | OriginLocation;
export type LoadDropoff = string | DestLocation;

export type SetLocation = (focus: Focus, location: LoadLocation | string) => void;

export const enum Countries {
  CA = 'CANADA',
  USA = 'USA',
}

export enum CountriesWithAnywhere {
  CA = 'CANADA',
  US = 'USA',
  ANYWHERE = 'ANYWHERE',
}

export enum Focus {
  pickup,
  dropoff,
  home,
}

export const displaySingleStringValueFor = (value: LoadLocation | string, forEditing: boolean): string =>
  displayValueFor(value, forEditing).join(', ');

export const displayValueFor = (value: LoadLocation | string, forEditing: boolean): string[] => {
  if (typeof value === 'string') {
    return [value];
  }
  switch (value.type) {
    case LocationType.ZIP: {
      if (value.zipCode) {
        let displayValue = value.zipCode;
        if (!forEditing && value.city && value.city.length > 0) {
          displayValue = `${displayValue} ${value.city}`;
        }
        return [displayValue];
      }
      return [addressFrom(value)];
    }
    case LocationType.CITY:
      return [addressFrom(value)];
    case LocationType.GEOLOCATION:
      if (value.formattedAddress && value.formattedAddress.length > 0) {
        return [value.formattedAddress];
      }
      return [addressFrom(value)];
    case LocationType.STATE:
      return value.states;
    case LocationType.ANY:
      return [t(T.loadGeneric_anywhere)];
    case LocationType.ANY_US:
      return [t(T.loadGeneric_anywhereUsa)];
    case LocationType.ANY_CA:
      return [t(T.loadGeneric_anywhereCanada)];
  }
  return [];
};

const addressFrom = (value: LoadGeolocation | LoadLocationCity | LoadLocationZip): string => {
  let displayValue: string;
  if (value.city) {
    displayValue = value.city;
    if (value.states && value.states.length > 0) {
      displayValue = `${displayValue}, ${first(value.states) || ''}`;
    }
    return displayValue;
  }
  return value.city ? value.city : '';
};

export const pickupValueFrom = (location?: LoadLocation): LoadPickup => {
  if (!location) {
    return '';
  }
  switch (location.type) {
    case LocationType.STATE:
    case LocationType.CITY:
    case LocationType.GEOLOCATION:
    case LocationType.ZIP:
      return location;
  }
  return '';
};

export const loadLocationFromCountrySelection = (location: CountriesWithAnywhere): LoadLocation => {
  switch (location) {
    case CountriesWithAnywhere.US: {
      return {
        type: LocationType.ANY_US,
      };
    }
    case CountriesWithAnywhere.CA: {
      return {
        type: LocationType.ANY_CA,
      };
    }
    default: {
      return {
        type: LocationType.ANY,
      };
    }
  }
};

export const locationMatchToLoadLocation = (
  locationSuggestion: LocationSuggestion
): LoadLocationCity | LoadLocationStates | LoadLocationZip | LoadGeolocation => {
  const isWithGeolocation = locationSuggestion.longitude && locationSuggestion.latitude;
  switch (locationSuggestion.type) {
    case SuggestionTypes.State:
      return {
        type: LocationType.STATE,
        states: [locationSuggestion.state],
      };
    case SuggestionTypes.ZipCode:
      return {
        type: LocationType.ZIP,
        zipCode: locationSuggestion.zipCode,
        city: locationSuggestion.city,
        states: [locationSuggestion.state],
        longitude: isWithGeolocation ? clipLatLongValue(locationSuggestion.longitude) : undefined,
        latitude: isWithGeolocation ? clipLatLongValue(locationSuggestion.latitude) : undefined,
      };
    case SuggestionTypes.Geolocation:
      return {
        type: LocationType.GEOLOCATION,
        longitude: clipLatLongValue(locationSuggestion.longitude),
        latitude: clipLatLongValue(locationSuggestion.latitude),
        city: locationSuggestion?.city,
        states: locationSuggestion?.state ? [locationSuggestion.state] : undefined,
      };
    default:
      return {
        type: LocationType.CITY,
        states: [locationSuggestion.state],
        city: locationSuggestion.city,
        longitude: isWithGeolocation ? clipLatLongValue(locationSuggestion.longitude) : undefined,
        latitude: isWithGeolocation ? clipLatLongValue(locationSuggestion.latitude) : undefined,
      };
  }
};

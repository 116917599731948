import React from 'react';

import styled from 'styled-components';

import { formatDollarsPerMile } from '@common/redux/epic/rateCheck/RateCheckHelper';
import { Text } from '@component/text/Text';
import { Color } from '@style/Color';
import { ElementSize, FontLineHeight, FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import { MediaQueries } from '@util/MediaQueries';

import { default as downArrow } from './down-arrow.svg';
import { default as upArrow } from './up-arrow.svg';

const TEST_ID = 'rate_check';

export interface MinMaxComponentProps {
  min: number | undefined;
  max: number | undefined;
  average: number | undefined;
  style?: React.CSSProperties;
  isSmall?: boolean;
}

interface LowHighColumnComponentProps {
  id: string;
  title: string;
  value: number | undefined;
  color: Color;
  isSmall: boolean | undefined;
}

interface BottomTextProps {
  isSmall: boolean | undefined;
  color: Color;
}

const MinMaxContainer = styled.div`
  display: flex;
  border: solid 1px ${Color.GRAY_11};
  width: calc(100% + 2px);
  padding: ${(props: { isSmall?: boolean }) => (props.isSmall ? 5 : Spacing.InterElementHorizontal)}px;
  justify-content: space-around;
  box-sizing: border-box;
  height: ${ElementSize.ActionLoadRowHeight}px;
  margin: -1px;
  background-color: ${Color.WHITE};
  @media ${MediaQueries.xxs} {
    padding: ${Spacing.InterElementHorizontal}px 0;
  }
`;

const MinMaxValueColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px;
`;

const ArrowImage = styled.img`
  height: ${ElementSize.SmallIcon}px;
  width: ${ElementSize.SmallIcon}px;
  margin: auto 0;
`;

const TopText = styled(Text)`
  font-size: ${(props: { isSmall: boolean | undefined }) => (props.isSmall ? FontSize.Caption : FontSize.Body)}px;
  color: ${Color.GRAY_STONE};
  font-weight: ${FontWeight.Regular};
  line-height: ${FontLineHeight.SmallBody}px;
`;

const BottomText = styled(Text)`
  font-size: ${(props: BottomTextProps) => (props.isSmall ? FontSize.Body : FontSize.Content)}px;
  color: ${(props: BottomTextProps) => props.color};
  font-weight: ${FontWeight.Regular};
  line-height: ${FontLineHeight.ContentDetail}px;
`;

export const MinMaxBox: React.FC<MinMaxComponentProps> = (props) => (
  <MinMaxContainer style={props.style} isSmall={props.isSmall}>
    <MinMaxValue
      id={`${TEST_ID}_low_value`}
      title={t(T.rateCheck_revenueBox_Low)}
      value={props.min}
      color={Color.BLACK}
      isSmall={props.isSmall}
    />
    <ArrowImage id={`${TEST_ID} _down_arrow`} src={downArrow} />
    <MinMaxValue
      id={`${TEST_ID}_average_value`}
      title={t(T.rateCheck_revenueBox_Average)}
      value={props.average}
      color={Color.BLACK}
      isSmall={props.isSmall}
    />
    <ArrowImage id={`${TEST_ID} _up_arrow`} src={upArrow} />
    <MinMaxValue
      id={`${TEST_ID}_high_value`}
      title={t(T.rateCheck_revenueBox_High)}
      value={props.max}
      color={Color.BLACK}
      isSmall={props.isSmall}
    />
  </MinMaxContainer>
);

const MinMaxValue: React.FC<LowHighColumnComponentProps> = (props) => (
  <MinMaxValueColumn>
    <TopText id={`${props.id}_${props.title}`} isSmall={props.isSmall}>
      {props.title}
    </TopText>
    <BottomText id={`${props.id}_${props.title}_number`} color={props.color} isSmall={props.isSmall}>
      {formatDollarsPerMile(props.value)}
    </BottomText>
  </MinMaxValueColumn>
);

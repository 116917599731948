import React from 'react';

import { Text, TextDisplay, TextStyle } from '@component/text';

interface Props {
  id: string;
  linkLabel: string;
  wrap: boolean;
  isLarge: boolean;
  onClick?: () => void;
}

export const UpgradeLink: React.FC<Props> = (props: Props) => {
  const phoneTextStyle = props.isLarge ? TextStyle.BigLink : TextStyle.Link;
  return (
    <Text id={props.id} noWrap={props.wrap ? false : true} display={TextDisplay.InlineBlock}>
      <a id={`${props.id}_link`} style={{ cursor: 'pointer' }} onClick={props.onClick}>
        <Text id={`${props.id}_link_text`} textStyle={phoneTextStyle} noWrap={true}>
          {props.linkLabel}
        </Text>
      </a>
    </Text>
  );
};

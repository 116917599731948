import React from 'react';

import styled from 'styled-components';

import { Checkbox, Table, TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { default as ExpandLessIcon } from '@material-ui/icons/ExpandMore';
import { default as ExpandMoreIcon } from '@material-ui/icons/KeyboardArrowRight';

import { CustomCheckbox } from '@component/input';
import { THEME } from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';

import { Warning } from './Warning';

const getBorder = (theme: Theme, hasBorder?: number) => `${hasBorder ? '1px' : '0px'} solid
${theme.palette.background.paler};`;
export const IteractableDiv = styled.div`
  cursor: ${(props: { clickable?: number }) => (props.clickable ? 'pointer' : 'auto')};
`;

export const StyledTruckTable = styled(Table)`
  && {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
`;

export const SeparatorCell = withTheme()(styled(TableCell)`
  background-color: ${(props: ThemeProps) => props.theme.palette.tableCell.light};
  color: grey !important;
  font-weight: bold;
`);

export const SeparatorCheckboxCell = withTheme()(styled(TableCell)`
  background-color: ${(props: ThemeProps) => props.theme.palette.tableCell.light};
  color: grey !important;
  font-weight: bold;
`);

export const HeaderDenseCell = withTheme()(styled(TableCell)`
  && {
    background-color: ${(props: ThemeProps & { width?: string }) => props.theme.palette.background.paper};
    color: ${(props: ThemeProps & { width?: string }) => props.theme.palette.icon.light} !important;
    width: ${(props: ThemeProps & { width?: string }) => (props.width ? props.width : '40px')};
    text-align: center;
  }
`);

export const StyledSeparatorCheckbox = withTheme()(styled(CustomCheckbox)`
  && {
    width: 40px;

    svg {
      color: ${(props: ThemeProps) => props.theme.palette.icon.dark};
    }
  }
`);

export const StyledCheckbox = withTheme()(styled(Checkbox)`
  && {
    max-width: 50px;
    width: 40px;

    svg {
      color: ${(props: ThemeProps & CheckboxProps) =>
        props.checked ? props.theme.palette.icon.main : props.theme.palette.icon.light};
    }
  }
`);

export const ExpandLess = withTheme()(styled(ExpandLessIcon)`
  color: ${(props: ThemeProps) => props.theme.palette.secondary.main};
`);

export const ExpandMore = withTheme()(styled(ExpandMoreIcon)`
  color: ${(props: ThemeProps) => props.theme.palette.secondary.main};
`);

export const VerticalCellRow = styled(IteractableDiv)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const VerticalCellColumn = styled(IteractableDiv)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NonMatchesMargin = styled.div`
  min-width: 40px;
  width: 40px;
  min-height: 20px;
`;

interface CellProps extends ThemeProps {
  width?: string;
  disabled?: number;
  clickable?: number;
}

export const ThemedCell = withTheme()(styled(TableCell)`
  && {
    transition: background-color 0.6s ease;
    background-color: transparent;
    border-style: none;
    width: ${(props: CellProps) => (props.width ? props.width : '0px')};
    cursor: ${(props: CellProps) => (props.clickable ? 'pointer' : 'auto')};
    overflow: hidden;
    text-overflow: ellipsis;
    > span {
      opacity: ${(props: CellProps) => (props.disabled ? 0.4 : 1)};
    }
  }
`);

interface DenseProps extends ThemeProps {
  clickable?: number;
}
export const DenseCell = withTheme()(styled(TableCell)`
  && {
    transition: background-color 0.6s ease;
    background-color: transparent;
    max-width: 50px;
    width: 40px;
    border-style: none;
    text-align: center;
    cursor: ${(props: DenseProps) => (props.clickable ? 'pointer' : 'auto')};
    button {
      padding: 0px;
      max-width: 30px;
      min-width: 30px;
      width: 30px;
      height: 30px;
      min-height: 30px;
      margin-right: 7px;
    }
  }
`);
interface StyledRowProps extends ThemeProps {
  vertical: number;
  isSelected: number;
  hasBorder?: number;
}

export const StyledRow = withTheme()(styled<StyledRowProps & React.ComponentProps<typeof TableRow>>(
  ({ isSelected, hasBorder, vertical, ...rest }) => <TableRow {...rest} />
)`
  && {
    height: ${(props: StyledRowProps) => (props.vertical ? '106px' : 'none')};
    background-color: ${(props: StyledRowProps) =>
      props.isSelected ? props.theme.palette.tableCell.dark : props.theme.palette.tableCell.main};
    border-bottom: ${(props: StyledRowProps) => getBorder(props.theme, props.hasBorder)};
  }
`);

export const openStyle = () => ({
  height: 'auto',
  maxHeight: '300px',
  backgroundColor: 'transparent',
  transition: 'max-height 0.6s ease, background-color 0.6s ease, border-color 0.6s ease',
});

export const closeStyle = {
  height: 'unset',
  maxHeight: '0px',
  overflowY: 'hidden',
  backgroundColor: THEME.palette.tableCell.main,
  transition: 'max-height 0.6s ease, background-color 0.6s ease, border-color 0.6s ease',
};
interface RowDetailsProps extends ThemeProps {
  hasBorder?: number;
  isSelected: number;
}

export const RowDetails = withTheme()(styled<RowDetailsProps & React.ComponentProps<typeof TableRow>>(
  ({ isSelected, hasBorder, ...rest }) => <TableRow {...rest} />
)`
  && {
    background-color: ${(props: RowDetailsProps) =>
      props.isSelected ? THEME.palette.tableCell.dark : THEME.palette.tableCell.main};
    padding: 0px !important;
    border-bottom: ${(props: RowDetailsProps) => getBorder(props.theme, props.hasBorder)};
    > td {
      padding-top: 0px;
      padding-bottom: 0px;
      border: 0px;
    }
  }
`);

export const ButtonSeparator = styled.div`
  && {
    min-width: 16px;
    min-height: 16px;
  }
`;

export const CellDetails = withTheme()(styled(TableCell)`
  && {
    border-collapse: collapse;
    vertical-align: top;
  }
`);

export const HiddenDivVertical = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  overflow: visible;
`;
//@TODO: CHECK THIS BOOLEANS AS NUMBERS.
export const ButtonDiv = styled.div`
  margin: ${(props: { vertical: boolean; sideways: boolean }) =>
    props.sideways ? '8px 24px 8px 0px' : '8px 16px 8px 16px'};
  display: flex;
  flex-direction: ${(props: { vertical: boolean; sideways: boolean }) => (props.vertical ? 'column' : 'row')};
  flex-wrap: nowrap;
  align-items: ${(props: { vertical: boolean; sideways: boolean }) => (props.vertical ? 'stretch' : 'center')};
  justify-content: space-between;
`;

export const ButtonLeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0px;
  justify-content: flex-start;
`;

export const ButtonRightDiv = styled.div`
  display: flex;
  flex-direction: ${(props: { vertical: boolean }) => (props.vertical ? 'column' : 'row-reverse')};
  flex-wrap: nowrap;
  margin: 8px 0px;
`;
export const CenteredCellDiv = styled.div`
  && {
    display: flex;
    justify-content: center;
  }
`;

export const TruckTableHeader = styled(TableHead)`
  background-color: white;
`;

export const ExpandableIconDiv = styled.div`
  && {
    background-color: ${THEME.palette.tableCell.light};
  }
`;

export const ArrowCell = withTheme()(styled(TableCell)`
  && {
    vertical-align: top;
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    transition: background-color 0.6s ease;
    text-align: right;
    overflow: hidden;
  }
`);

export const WarningMessage = styled(Warning)`
  padding: 20px 20px;
`;

import { Observable, of as of$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { BaseClient } from '@common/client/BaseClient';
import { LoadsClient } from '@common/client/LoadsClient';
import { RouteClient } from '@common/client/RouteClient';
import { RoutePoints, SharedLoad as SharedLoadModel } from '@common/model';

export interface SharedLoad {
  load: SharedLoadModel;
  route?: RoutePoints;
}
export class SharedLoadClient extends BaseClient {
  private loadClient: LoadsClient;
  private routeClient: RouteClient;

  constructor(api: Api, isLiveEnvironment: boolean) {
    super(api);
    this.loadClient = new LoadsClient(api, isLiveEnvironment);
    this.routeClient = new RouteClient(api);
  }

  fetchSharedLoad$ = (token: string, isPoster?: boolean): Observable<ApiResponse123<SharedLoad>> => {
    const loadResponse$ = this.loadClient.fetchSharedLoad$(token, isPoster);
    return loadResponse$.pipe(
      mergeMap$((loadResponse: ApiResponse123<SharedLoadModel>) => {
        return loadResponse.result(
          (load) => {
            if (
              load.originLocation &&
              load.originLocation.geolocation &&
              load.destinationLocation &&
              load.destinationLocation.geolocation
            ) {
              return this.routeClient
                .fetchRoute$(
                  { origin: load.originLocation.geolocation, destination: load.destinationLocation.geolocation },
                  false,
                  undefined
                )
                .pipe(
                  map$((routeResponse: ApiResponse123<RoutePoints>) => {
                    if (loadResponse.success) {
                      const route = (routeResponse as ApiResponseSuccess<RoutePoints>).data;
                      return new ApiResponseSuccess<SharedLoad>(routeResponse.httpStatus, {
                        load: load,
                        route: route,
                      });
                    }
                    return routeResponse as ApiError;
                  })
                );
            } else {
              //Sending 206 as it's a partial content.
              const shareLoadResponse = new ApiResponseSuccess<SharedLoad>(206, {
                load: load,
                route: undefined,
              });
              return of$(shareLoadResponse);
            }
          },
          (error) => of$(error)
        );
      })
    );
  };
}

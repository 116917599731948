import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Tooltip from '@material-ui/core/Tooltip';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { isBrokerFavorite, isOnboarded, shortStringifyAge } from '@common/helper';
import { Load } from '@common/model';
import { favoriteBrokers, hideBrokers, onboardBrokers } from '@common/redux/epic/BrokerEpic';
import { onboardedConfirmation } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPopups';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { ElementSize, Opacity } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { t, T } from '@translate';
import icons from '@util/iconsConstants';
import { loadAgeProvider } from '@util/LoadAgeProvider';

import { default as LikeIconOff } from './icon-favorite-off.svg';
import { default as LikeIconOn } from './icon-favorite-on.svg';
import { default as OnboardedIconOff } from './icon-onboarded-off.svg';
import { default as OnboardedIconOn } from './icon-onboarded-on.svg';

interface Props {
  load: Load;
  age?: number;
  isBrokerUi?: boolean;
}

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const CompanyName = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-content: stretch;
  flex-direction: row;
  font-weight: ${THEME.text.loadDetailsCompanyName.fontWeight};
  text-transform: none;
  font-size: ${THEME.text.loadDetailsCompanyName.fontSize};
  line-height: ${THEME.text.loadDetailsCompanyName.lineHeight};
`;

const LightText = styled.div`
  font-size: ${THEME.text.body.fontSize};
  opacity: ${Opacity.translucent};
  margin-left: auto;
`;

export const LoadDetailsGeneralInfoHeaderComponent: React.FC<Props> = (props) => {
  return (
    <>
      <Title>
        {props.load.poster && (
          <Tooltip title={props.load.poster.name} placement="top">
            <CompanyName id="company">{props.load.poster.name}</CompanyName>
          </Tooltip>
        )}
        {props.isBrokerUi === undefined || !props.isBrokerUi ? (
          <>
            <OnboardedButtton isOnboarded={isOnboarded(props.load)} loadId={props.load?.poster?.id} />
            <FavoriteButton isFavorite={isBrokerFavorite(props.load)} loadId={props.load?.poster?.id} />
          </>
        ) : null}
      </Title>
      {props.age ? <LightText id="age">{shortStringifyAge(props.age)}</LightText> : null}
    </>
  );
};

export const CircleContainer = styled.div`
  display: flex;
  background-color: ${Color.WHITE};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.27);
  height: 34px;
  width: 34px;
  border-radius: 17px;
  margin-left: 8px;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
`;

export const FavoriteButton: React.FC<{ loadId: string | undefined; isFavorite: boolean }> = (props) => {
  const dispatch = useDispatch();

  const handleFavoriteClick = () => {
    if (props.loadId) {
      dispatch(favoriteBrokers([{ id: props.loadId, favorite: !props.isFavorite }], undefined));
    }
    dispatch(
      showSnackbar({
        message: !props.isFavorite ? t(T.snackbars_companyFavorited) : t(T.snackbars_companyUnfavorited),
      })
    );
  };

  return (
    <Tooltip
      title={props.isFavorite ? t(T.findLoads_findLoads_removeFavorite) : t(T.findLoads_findLoads_favoriteCompany)}
      placement="top"
    >
      <CircleContainer>
        <img
          id="favorite"
          src={props.isFavorite ? LikeIconOff : LikeIconOn}
          alt="like"
          onClick={handleFavoriteClick}
          style={{ height: ElementSize.Icon, width: ElementSize.Icon, alignSelf: 'center' }}
        />
      </CircleContainer>
    </Tooltip>
  );
};

export const OnboardedButtton: React.FC<{ loadId: string | undefined; isOnboarded: boolean }> = (props) => {
  const dispatch = useDispatch();
  const popupTracking = usePopup();
  const onOnboardedClick = () => {
    if (props.loadId) {
      dispatch(onboardBrokers([{ id: props.loadId, onboarded: !props.isOnboarded }], undefined));
    }
    dispatch(
      showSnackbar({
        message: !props.isOnboarded
          ? t(T.findLoads_findLoads_company_added_onboarded)
          : t(T.findLoads_findLoads_company_removed_onboarded),
      })
    );
  };

  const handleTagAsOnboarded = () => {
    onboardedConfirmation(onOnboardedClick, popupTracking);
  };

  const handleOnboardedClick = () => {
    if (props.isOnboarded) {
      onOnboardedClick();
    } else {
      handleTagAsOnboarded();
    }
  };

  return (
    <Tooltip
      title={props.isOnboarded ? t(T.findLoads_findLoads_removeOnboardedTag) : t(T.findLoads_findLoads_tagAsOnboarded)}
      placement="top"
    >
      <CircleContainer>
        <img
          id="onboarded"
          src={props.isOnboarded ? OnboardedIconOff : OnboardedIconOn}
          alt="like"
          onClick={handleOnboardedClick}
          style={{ height: ElementSize.Icon, width: ElementSize.Icon, alignSelf: 'center' }}
        />
      </CircleContainer>
    </Tooltip>
  );
};

export const BlockButton: React.FC<{ loadId: string | undefined; isHidden: boolean }> = (props) => {
  const dispatch = useDispatch();

  const handleBlockClick = () => {
    if (props.loadId) {
      dispatch(hideBrokers([{ id: props.loadId, hide: !props.isHidden }], false, undefined));
    }
    dispatch(
      showSnackbar({
        message: !props.isHidden ? t(T.snackbars_companyBlocked) : t(T.snackbars_companyUnblocked),
      })
    );
  };

  return (
    <CircleContainer>
      <img
        id="block"
        src={props.isHidden ? icons.block : icons.block}
        alt="block"
        onClick={handleBlockClick}
        style={{ height: ElementSize.Icon, width: ElementSize.Icon, alignSelf: 'center' }}
      />
    </CircleContainer>
  );
};

export const LoadDetailsGeneralInfoHeader = loadAgeProvider(LoadDetailsGeneralInfoHeaderComponent);

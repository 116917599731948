import React, { useRef } from 'react';

import { find, isEmpty, isNil, map } from 'lodash';
import memoizeOne from 'memoize-one';
import styled from 'styled-components';

import { Tooltip } from '@material-ui/core';

import {
  formatTime,
  isTimePresentInDate,
  isWithManualPostedExtraStops,
  parseMomentDate,
  serverGMTRFCToUserLocalTZ,
  stringifyLocation,
} from '@common/helper';
import { Load, LoadExtraStop, Location } from '@common/model';
import { LocationsSpacer } from '@component/locationsSpacer/LocationsSpacer';
import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

interface ComponentProps {
  originLocation?: Location;
  destinationLocation?: Location;
  pickupDateTimes?: string[];
  deliveryDateTime?: string;
  searchLoads?: Load[];
  backhaulsLoads?: Load[];
  similarLoads?: Load[];
  currentLoadId?: string;
  load: Load;
  onManualExtraStopClick?: () => void;
}

const LocationsWithSpacer = styled.div`
  display: grid;
  margin-top: 20px;
  grid-template-columns: 10px 90%;
  grid-column-gap: ${Spacing.ElementPaddingHorizontal}px;
`;

const Locations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DetailsDestination = styled.div`
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  white-space: nowrap;
  margin-bottom: 16px;
  text-transform: none;
`;

const ManualPostedStopsCount = styled(DetailsDestination)`
  color: ${Color.ORANGE_MAIN};
`;

const Deadhead = styled.span`
  display: flex;
  align-self: center;
  font-size: 12px;
  opacity: 0.5;
  font-weight: normal;
  margin-left: 10px;
  text-transform: none;
`;

const DateContainer = styled.div`
  flex-direction: column;
`;
const DateTime = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 5px;
  font-weight: normal;
  min-height: 15px;
  max-width: 280px;
  flex-wrap: wrap;
`;

const LocationsSpacerOffsetWrapper = styled.div`
  margin-top: 3px;
`;

const DestinationWithDeadhead = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ExtraStopsCount = styled.div`
  font-weight: normal;
  margin-right: 5px;
  float: left;
`;

const LocationText = styled.div`
  white-space: normal;
  word-wrap: break-word;
`;

export const LoadDetailsOriginDestination: React.FC<ComponentProps> = (props) => {
  const pickupRef = useRef<HTMLDivElement>(null);
  const dropoffRef = useRef<HTMLDivElement>(null);
  const stopsRefs = useRef<(HTMLDivElement | null)[]>([]);

  // @FIXME: Find a way to do this by normalizing the data instead of iterating through the lists
  const getLoadWithDeadhead = memoizeOne(() => {
    if (!props.currentLoadId) {
      return undefined;
    }

    const idMatches = (load: Load) => load.id === props.currentLoadId;

    return (
      find(props.searchLoads, idMatches) ?? find(props.similarLoads, idMatches) ?? find(props.backhaulsLoads, idMatches)
    );
  });

  const loadWithDeadhead = () => {
    let loadWithDeadhead;
    if (props.searchLoads && props.backhaulsLoads && props.similarLoads) {
      loadWithDeadhead = getLoadWithDeadhead();
    }
    const originDeadhead = loadWithDeadhead?.metadata?.userdata?.originDeadhead?.value;
    const destinationDeadhead = loadWithDeadhead?.metadata?.userdata?.destinationDeadhead?.value;

    return {
      originDeadhead: originDeadhead,
      destinationDeadhead: destinationDeadhead,
    };
  };

  const originLocationText = props.originLocation ? formatLocation(props.originLocation) : undefined;
  const destinationLocationText = props.destinationLocation ? formatLocation(props.destinationLocation) : undefined;

  return (
    <LocationsWithSpacer>
      <LocationsSpacerOffsetWrapper>
        <LocationsSpacer startPositionRef={pickupRef} endPositionRef={dropoffRef} stopsPositionRef={stopsRefs} />
      </LocationsSpacerOffsetWrapper>

      <Locations>
        <DetailsDestination id="from">
          <DestinationWithDeadhead id="location" className="destination">
            <Tooltip title={originLocationText}>
              <LocationText innerRef={pickupRef}>{originLocationText}</LocationText>
            </Tooltip>
            {loadWithDeadhead().originDeadhead ? (
              <Deadhead id="deadhead">{t(T.unit_mi, { value: loadWithDeadhead().originDeadhead })}</Deadhead>
            ) : null}
          </DestinationWithDeadhead>
          {displayTimeDate(props.pickupDateTimes)}
        </DetailsDestination>
        <ExtraStops load={props.load} onManualExtraStopClick={props.onManualExtraStopClick} stopsRefs={stopsRefs} />
        <DetailsDestination id="to">
          <DestinationWithDeadhead id="location" className="destination">
            <Tooltip title={destinationLocationText}>
              <LocationText innerRef={dropoffRef}>{destinationLocationText}</LocationText>
            </Tooltip>
            {loadWithDeadhead().destinationDeadhead ? (
              <Deadhead id="deadhead">{t(T.unit_mi, { value: loadWithDeadhead().destinationDeadhead })}</Deadhead>
            ) : null}
          </DestinationWithDeadhead>
          {displayTimeDate(props.deliveryDateTime ? [props.deliveryDateTime] : undefined)}
        </DetailsDestination>
      </Locations>
    </LocationsWithSpacer>
  );
};

const ExtraStops: React.FC<{
  load: Load;
  stopsRefs: React.RefObject<(HTMLDivElement | null)[]>;
  onManualExtraStopClick?: () => void;
}> = ({ load, onManualExtraStopClick, stopsRefs }) => {
  const isWithManualExtraStops = isWithManualPostedExtraStops(load.extraStops, load.numberOfStops);
  if (!isWithManualExtraStops && isEmpty(load.extraStops)) {
    return null;
  }
  const setRef = (index: number) => (element: HTMLDivElement | null) => {
    if (stopsRefs.current) {
      stopsRefs.current[index] = element;
    }
  };
  return isWithManualExtraStops ? (
    <ManualPostedStopsCount>
      <ExtraStopsCount onClick={onManualExtraStopClick} style={{ cursor: 'pointer' }}>
        {t(T.findLoads_loadDetails_extraStops, { value: load.numberOfStops })}
      </ExtraStopsCount>
    </ManualPostedStopsCount>
  ) : (
    <>
      {map(load.extraStops, (stop, index) =>
        stop ? (
          <DetailsDestination>
            <DestinationWithDeadhead>
              <Tooltip title={formatStopLocation(stop)}>
                <LocationText innerRef={setRef(index)}>
                  <ExtraStopsCount>{t(T.findLoads_loadDetails_stop, { value: index + 1 })}</ExtraStopsCount>
                  {formatStopLocation(stop)}
                </LocationText>
              </Tooltip>
            </DestinationWithDeadhead>
          </DetailsDestination>
        ) : null
      )}
    </>
  );
};

const displayTimeDate = (dates: string[] | undefined) => {
  if (!dates || dates.length === 0) {
    return null;
  }
  const dateWithTime = dates[0];
  const dateObjWithTime = isTimePresentInDate(dateWithTime) ? serverGMTRFCToUserLocalTZ(dateWithTime) : undefined;

  return (
    <DateContainer>
      <DateTime id="date">
        {map(dates, (date, index) => (
          <div key={index} style={{ marginRight: 2 }}>{`${formatDate(date)}${
            index < dates.length - 1 ? ',' : ''
          }`}</div>
        ))}
      </DateTime>
      {!isNil(dateObjWithTime) ? <DateTime id="time">{formatTime(dateObjWithTime)}</DateTime> : null}
    </DateContainer>
  );
};

const formatLocation = (location: Location) => stringifyLocation(location.address).locationName;
const formatStopLocation = (stop: LoadExtraStop) => stringifyLocation(stop.address).locationName;
const formatDate = (date: string) => parseMomentDate(date, 'DD MMM YYYY HH:mm:SS')?.format('MMM D') ?? '';

import React, { useMemo } from 'react';

import { find, includes, intersection, isEmpty, map, uniq, without } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { toggleRegionsPicker } from '@/reduxStore/epic/LocationPickerEpic';
import { CanadaRegionOptions, RegionsList, USARegionOptions } from '@common/helper';
import { CA_PROVINCES, STATES } from '@common/model';
import { IconActionButton } from '@component/buttons';
import { ClearButton } from '@component/buttons/ClearButton';
import { StyledPickerTitle } from '@component/calendar/CalendarStyles';
import { CheckboxResponse } from '@component/control';
import { ChipItem, RegionsColumn, StatesRow } from '@component/panels/modalPanel/RegionPickerParts';
import { StatesMaxSelectionWarning } from '@component/panels/modalPanel/StatesMaxSelectionWarning';
import { StyledRow, StyledSubHeader } from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

const Container = styled.div`
  background-color: ${Color.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Color.GRAY_GRADIENT_LIGHT};
`;

const StyledPaddedRow = styled(StyledRow)`
  padding: 15px 16px 14px 16px;
  align-items: center;
`;

const StatesColumn = styled.div`
  flex: 1;
  padding-left: ${Spacing.ElementPaddingHorizontal}px;
  padding-right: ${Spacing.ScreenSide}px;
`;

const StateRegionsBar = styled.div`
  display: flex;
  width: 100%;
`;

interface Props {
  states: string[];
  onChange: (states: string[]) => void;
  maxCount: number | undefined;
}

export const StatesPicker = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const dispatch = useDispatch();

  const isPickerOpened = useSelector((state) => state.locationPickerState.isRegionsPickerOpened);

  const isMaxSelectedStatesCountReached = props.maxCount !== undefined && props.states.length > props.maxCount;

  const isSingleChoice = useMemo(() => props.maxCount === 1, [props.maxCount]);

  const onRegionsSelectionChange = (regions: RegionsList, response: Partial<CheckboxResponse>) => {
    const { isChecked, name } = response;
    const found = find(regions, (region) => region.id === name);
    const statesWithinRegion = found?.value ?? [];
    const newSelectedStatesArray = isChecked
      ? [...props.states, ...statesWithinRegion]
      : without(props.states, ...statesWithinRegion);
    props.onChange(uniq(newSelectedStatesArray));
  };

  const onStateChange = (state: string) => {
    let newSelectedStatesArray = [];
    if (isSingleChoice) {
      newSelectedStatesArray = includes(props.states, state) ? [] : [state];
    } else {
      newSelectedStatesArray = includes(props.states, state) ? without(props.states, state) : [...props.states, state];
    }
    props.onChange(newSelectedStatesArray);
  };

  const onClear = () => {
    props.onChange([]);
  };

  return (
    <div ref={ref}>
      <IconActionButton
        id="states_provinces_regions"
        title={
          isSingleChoice ? t(T.findLoads_findLoads_stateProvince) : t(T.findLoads_findLoads_statesProvincesRegions)
        }
        mainIcon={icons.states}
        controlIcon={isPickerOpened ? icons.collapse : icons.expand}
        onClick={() => dispatch(toggleRegionsPicker(!isPickerOpened))}
        onControlClick={() => dispatch(toggleRegionsPicker(!isPickerOpened))}
      />
      {isPickerOpened ? (
        <Container>
          <StyledPaddedRow>
            {isSingleChoice ? null : (
              <StatesMaxSelectionWarning
                maxCount={props.maxCount}
                hasReachedMaxCount={isMaxSelectedStatesCountReached}
                selectedCount={props.states.length}
              />
            )}
            <ClearButton onClear={onClear} disabled={isEmpty(props.states)} />
          </StyledPaddedRow>
          <StyledPickerTitle id="usa_regions">
            {isSingleChoice ? t(T.findLoads_findLoads_usa) : t(T.findLoads_findLoads_usaRegions)}
          </StyledPickerTitle>
          <StyledSubHeader
            id="select_regions_state_subtitle_usa"
            style={{ color: Color.GRAY_DARK, marginBottom: Spacing.ElementPaddingVertical }}
          >
            {isSingleChoice ? t(T.findLoads_findLoads_selectState) : t(T.findLoads_findLoads_selectRegionsOrStates)}
          </StyledSubHeader>
          <StateRegionsBar>
            {isSingleChoice ? (
              <StatesColumn>
                <StatesRow chips={getChipsList(STATES, props.states, onStateChange)} isSingleChoice={isSingleChoice} />
              </StatesColumn>
            ) : (
              <>
                <RegionsColumn
                  regionsList={USARegionOptions}
                  shouldSelectRegion={(states: string[]) => shouldSelectRegion(states, props.states)}
                  onRegionsSelectionChange={(regionId: string, states: string[]) =>
                    onRegionsSelectionChange(USARegionOptions, {
                      name: regionId,
                      isChecked: !shouldSelectRegion(states, props.states),
                    })
                  }
                />
                <StatesColumn>
                  {map(USARegionOptions, (region) => (
                    <StatesRow key={region.key} chips={getChipsList(region.value, props.states, onStateChange)} />
                  ))}
                </StatesColumn>
              </>
            )}
          </StateRegionsBar>
          <StyledPickerTitle id="canada_regions" style={{ marginTop: Spacing.ScreenSide }}>
            {isSingleChoice ? t(T.findLoads_findLoads_canada) : t(T.findLoads_findLoads_canadaRegions)}
          </StyledPickerTitle>
          <StyledSubHeader
            id="select_regions_state_subtitle_canada"
            style={{ color: Color.GRAY_DARK, marginBottom: Spacing.ElementPaddingVertical }}
          >
            {isSingleChoice ? t(T.findLoads_findLoads_selectProvince) : t(T.findLoads_findLoads_selectRegionsOrStates)}
          </StyledSubHeader>
          <StateRegionsBar>
            {isSingleChoice ? (
              <StatesColumn>
                <StatesRow
                  chips={getChipsList(CA_PROVINCES, props.states, onStateChange)}
                  isSingleChoice={isSingleChoice}
                />
              </StatesColumn>
            ) : (
              <>
                <RegionsColumn
                  regionsList={CanadaRegionOptions}
                  shouldSelectRegion={(states: string[]) => shouldSelectRegion(states, props.states)}
                  onRegionsSelectionChange={(regionId: string, states: string[]) =>
                    onRegionsSelectionChange(CanadaRegionOptions, {
                      name: regionId,
                      isChecked: !shouldSelectRegion(states, props.states),
                    })
                  }
                />
                <StatesColumn>
                  {map(CanadaRegionOptions, (region) => (
                    <StatesRow key={region.key} chips={getChipsList(region.value, props.states, onStateChange)} />
                  ))}
                </StatesColumn>
              </>
            )}
          </StateRegionsBar>
        </Container>
      ) : null}
    </div>
  );
});

const getChipsList = (
  statesWithinRegion: string[],
  selectedStates: string[],
  onClick: (state: string) => void
): ChipItem[] =>
  map(statesWithinRegion, (state) => ({
    label: state,
    isChecked: includes(selectedStates, state),
    onClick: onClick,
  }));

const shouldSelectRegion = (statesWithinRegion: string[], selectedStates: string[]) =>
  intersection(selectedStates, statesWithinRegion).length === statesWithinRegion.length;

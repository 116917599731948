import { useEffect } from 'react';

import './bodyfixed.css';

const BODYFIXED_CLASS = 'bodyfixed';

/**  Toggles a special CSS class on the page's body tag.
 *
 * This is to reduce window overscrolls (visible on iPads)
 */
export const useSetupBodyFixedForScrolling = (enableFixedBody: boolean) => {
  useEffect(() => {
    const bodyElem = document.body;
    if (enableFixedBody) {
      bodyElem.classList.add(BODYFIXED_CLASS);
      return () => bodyElem.classList.remove(BODYFIXED_CLASS);
    } else {
      bodyElem.classList.remove(BODYFIXED_CLASS);
      return () => bodyElem.classList.add(BODYFIXED_CLASS);
    }
  }, [enableFixedBody]);
};

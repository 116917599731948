import { useEffect, useState } from 'react';

import { debounce } from 'lodash';

/** This hook returns whether the current browser has "zero-width floating scrollbars" (i.e. mobile). This used to know
 * if we are on mobile and/or touch-only device for the purpose of avoiding using SimpleBar when scrolling the whole page.
 * The logic here is if the browser has already fancy zero-width scrollbars, our 'SimpleBar' is just making things worse.
 *
 * Normally this never changes within a normal usage. The only case is toggling Google Chrome's "device" mode or
 * if the measurement failed once because of some rendering quirk of the browser.
 * So here we sometimes re-check just in case (only after a window resize AND a global debounced timer). Note that this
 * hook is used many times in a page.
 */
export const useIsBrowserWithFloatingScrollbars = <T>() => {
  const [floating, setFloating] = useState(detectFloatingScrollbars());

  useEffect(() => {
    function handleResize() {
      setFloating(detectFloatingScrollbars());
    }

    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize);
  }, [setFloating]);

  return floating;
};

// global debounce
const detectFloatingScrollbars = debounce(
  () => {
    document.body.insertAdjacentHTML(
      'beforeend',
      `<div id='floatingscrollbartest' style="visibility:hidden;position:absolute;top:0;left:0;width:100px;height:100px;overflow-y:scroll"></div>`
    );

    const elem = document.getElementById('floatingscrollbartest');

    if (elem == null) {
      return false;
    }

    const scrollBarWidth = elem.offsetWidth - elem.clientWidth;

    elem.remove();

    return scrollBarWidth === 0;
  },
  2000, // don't always recheck as this is a relatively slow process (waits for DOM reflow)
  { leading: true, trailing: false }
);

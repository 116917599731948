import React, { useEffect, useState } from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { Provider, ReactReduxContext, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';

import { MuiThemeProvider } from '@material-ui/core';

import { history } from '@/reduxStore/History';
import { persistor, store } from '@/reduxStore/Store';
import { Flag } from '@common/model';
import { fetchLoadId } from '@common/redux/epic/LoadDetailsEpic';
import { getSystemSetting } from '@common/redux/epic/SettingsEpic';
import { fetchUnreadTruckAlertsCount } from '@common/redux/epic/TruckAlertsEpic';
import { fetchUser } from '@common/redux/epic/UserEpic';
import { MessageWidgetButton } from '@component/buttons/MessageWidgetButton';
import { MainLayout } from '@component/main/MainLayout';
import { PageWithPanels } from '@component/panel';
import '@page/aspxPage/AspxSelfEmbedProtection';
import { CommunicationRoute } from '@page/communication/CommunicationRoute';
import { LoadConversationsOriginUI } from '@page/communication/LoadConversations';
import THEME from '@style/Theme';
import { t, T } from '@translate';
import { AppConfig } from '@util/AppConfig';
import { useAppVisibilityChange, useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';
import { hideMainSpinner } from '@util/MainSpinnerHelper';
import {
  DESCRIPTION_META,
  OG_DESCRIPTION_META,
  OG_TITLE_META,
  TITLE_META,
  TWITTER_DESCRIPTION_META,
  TWITTER_TITLE_META,
} from '@util/Metadata';
import { createMessagingPartnerPortalTrackData, track } from '@util/trackers/123lbTracker';
import { hasLoginCookies } from '@webApi/CookiesHelper';

import { appDependencies, DependencyProvider } from './AppDependencies';
import { CommunicationHandler } from './CommunicationHandler';
import { Routes } from './router/Routes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled(Container)<{ isWidgetButton?: boolean }>`
  justify-content: center;
  height: 100%;
  width: ${(props) => (props.isWidgetButton ? '' : '100%')};
  align-items: center;
  background: ${(props) => (props.isWidgetButton ? 'transparent' : 'white')};
`;

type PartnerPortalMessage =
  | { type: 'buttonWidget'; isWidgetButton: boolean }
  | { type: 'openWidget'; isWidgetOpen: boolean }
  | { type: 'reference'; referenceId?: string };
interface MessagingWidgetAppProps {
  parentHostname: string;
}

const MessagingWidgetApp = (props: MessagingWidgetAppProps) => {
  const { parentHostname } = props;
  const [referenceId, setReferenceId] = useState('');
  const [isWidgetButton, setIsWidgetButton] = useState(false);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(hasLoginCookies());

  const dispatch = useDispatch();

  const fetchSystemSettings = () => {
    dispatch(getSystemSetting(Flag.BiddingDisabled));
    dispatch(getSystemSetting(Flag.BiddingV2Disabled));
    dispatch(getSystemSetting(Flag.VendorBiddingDisabled));
    dispatch(getSystemSetting(Flag.HideParadeContact));
    dispatch(getSystemSetting(Flag.BookNowDisabled));
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchSystemSettings();
    }
    hideMainSpinner();
    if (window.parent) {
      window.parent.postMessage('alive', '*');
    }
    const handleMessage = (event: MessageEvent) => {
      const data = event.data as PartnerPortalMessage | undefined;
      if (data?.type === 'reference') {
        setReferenceId(data?.referenceId ?? '');
      }
      if (data?.type === 'buttonWidget') {
        setIsWidgetButton(data?.isWidgetButton);
      }
      if (data?.type === 'openWidget') {
        setIsWidgetOpen(data?.isWidgetOpen);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useAppVisibilityChange((isVisible) => {
    if (isVisible) {
      if (hasLoginCookies()) {
        fetchSystemSettings();
      }
      setIsLoggedIn(hasLoginCookies());
    }
  }, []);

  const handleLogin = () => {
    window.open(
      AppConfig.getLoginLink(
        `${AppConfig.LBConfig.MembersRoot}/partner-widget/?partner=${parentHostname}&isWidgetOpen=false`
      ),
      '_blank'
    );
  };

  return (
    <>
      <Helmet>
        <title>{AppConfig.NAME}</title>
        <meta name={TITLE_META} content={AppConfig.NAME} />
        <meta name={TWITTER_TITLE_META} property={TWITTER_TITLE_META} content={AppConfig.NAME} />
        <meta name={OG_TITLE_META} property={OG_TITLE_META} content={AppConfig.NAME} />
        <meta name={DESCRIPTION_META} content={t(T.Meta_Description)} />
        <meta name={TWITTER_DESCRIPTION_META} property={TWITTER_DESCRIPTION_META} content={t(T.Meta_Description)} />
        <meta name={OG_DESCRIPTION_META} property={OG_DESCRIPTION_META} content={t(T.Meta_Description)} />
      </Helmet>

      <Provider store={store} context={ReactReduxContext}>
        <PersistGate persistor={persistor}>
          <DependencyProvider container={appDependencies}>
            <ConnectedRouter history={history} context={ReactReduxContext}>
              <MuiThemeProvider theme={THEME}>
                <MainLayout hideHeaders={true}>
                  {isLoggedIn ? (
                    <>
                      <CommunicationHandler />
                      <RenderMessagesButton
                        parentHostname={parentHostname}
                        isWidgetOpen={isWidgetOpen}
                        referenceId={referenceId}
                        isWidgetButton={isWidgetButton}
                      />
                    </>
                  ) : (
                    <ButtonContainer isWidgetButton={isWidgetButton}>
                      <MessageWidgetButton
                        id="sign_in"
                        title={t(T.menu_account_signin)}
                        icon={icons.logoOrange}
                        onClick={handleLogin}
                      />
                    </ButtonContainer>
                  )}
                </MainLayout>
              </MuiThemeProvider>
            </ConnectedRouter>
          </DependencyProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

interface RenderMessagesButtonProps {
  parentHostname: string;
  isWidgetOpen: boolean;
  referenceId?: string;
  isWidgetButton?: boolean;
}

const RenderMessagesButton = (props: RenderMessagesButtonProps) => {
  const { parentHostname, isWidgetOpen, referenceId, isWidgetButton } = props;

  const dispatch = useDispatch();
  const unreadMessagesCount = useSelector((state) => state.communication.unreadMessagesCount);

  const userId = useSelector((state) => state.user.profile?.payload?.id);
  const loadID = useSelector((state) => state.loadDetails.widgetLoadId);

  useEffect(() => {
    dispatch(fetchUnreadTruckAlertsCount());
    if (!userId) {
      dispatch(fetchUser());
    }
  }, []);

  const handleClick = () => {
    if (window.parent) {
      window.parent.postMessage('resize', '*');
    }

    if (userId) {
      track(createMessagingPartnerPortalTrackData(parentHostname, userId, unreadMessagesCount));
    }
  };

  useEffect(() => {
    if (referenceId !== undefined && userId) {
      dispatch(fetchLoadId(referenceId, userId));
    }
  }, [referenceId, userId]);

  useEffect(() => {
    if (isWidgetOpen && userId) {
      track(createMessagingPartnerPortalTrackData(parentHostname, userId, unreadMessagesCount));
      history.push(generatePath(Routes.COMMUNICATION));
    }
  }, [userId, isWidgetOpen, loadID]);

  let content =
    unreadMessagesCount > 0 ? (
      <MessageWidgetButton
        id="read_messages"
        title={t(T.menu_main_messages)}
        notificationCount={unreadMessagesCount}
        onClick={handleClick}
      />
    ) : (
      <MessageWidgetButton
        id="no_messages"
        title={t(T.menu_main_messages)}
        icon={icons.messageBlue}
        onClick={handleClick}
      />
    );

  if (isWidgetOpen) {
    content = (
      <PageWithPanels>
        <CommunicationRoute
          key="widget"
          originUI={LoadConversationsOriginUI.MessagingWidget}
          loadID={loadID}
          isWidgetButton={isWidgetButton}
        />
      </PageWithPanels>
    );
  }

  return <Container id="layout_container">{content}</Container>;
};

export const isMessagingWidgetView = () => {
  return (
    window.name === 'lb123connect' ||
    (window.location !== window.parent.location && window.location.pathname.includes('/partner-widget/'))
  );
};

export default MessagingWidgetApp;

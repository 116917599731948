import {
  clone,
  cloneDeep,
  compact,
  filter,
  find,
  findIndex,
  first,
  forEach,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isUndefined,
  map,
  nth,
  parseInt,
  pullAll,
  trim,
} from 'lodash';
import moment from 'moment';
import { Action } from 'redux';

import { ApiErrorCode } from '@common/api';
import { CompanyUserData } from '@common/client/CompanyClient';
import { extractTimeFromDateTimeIfPresent, parseMomentDate, stripTimeIfMidnight } from '@common/helper/DateHelper';
import {
  BookingStatus,
  BookNowResponse,
  Company,
  EquipmentType,
  Geolocation,
  Load,
  LoadBackhaul,
  LoadExtraStop,
  LoadFields,
  LoadGeolocation,
  LoadMatch,
  LoadProgress,
  LoadSearchRequest,
  LoadStatus,
  Location,
  LocationType,
  NonMatchingLoad,
  PostedLoadProgress,
  ReverseGeolocationResult,
  SimilarLoad,
  SimilarLoadSearchResponse,
  UserDataCompany,
  VendorBidInfo,
} from '@common/model';
import { Response } from '@common/redux/Base';

import { stringifyLocation } from './LocationHelper';

export const NEW_LOAD_SECONDS_THRESHOLD = 300;
export const ONE_SECOND = 1;
export const ONE_MINUTE_IN_SECONDS = 60 * ONE_SECOND;
export const ONE_HOUR_IN_SECONDS = 60 * ONE_MINUTE_IN_SECONDS;

export enum UpdateCategory {
  SAVED,
  VIEWED,
  CALLED,
  NOTE,
  PRIVATELOADNOTE,
  PROGRESS,
  HIDDEN,
  BLOCKED,
  FAVORITE,
  AGE,
  AGE_BULK,
  PICKUP_DATES_BULK,
  STATUS_BULK,
  EMAILED,
  ONBOARDED,
  STATUS,
  VENDOR_BID_SENT,
  BOOK_NOW_SENT,
  INITIAL_BID_SENT,
  POSTED_PROGRESS,
}

interface BaseLoadUpdate {
  category: UpdateCategory;
}

interface LoadSpecificUpdate extends BaseLoadUpdate {
  loadID: string;
}

interface LoadListUpdate extends BaseLoadUpdate {
  loadIDs: string[];
  isExcluded: boolean;
}

interface LoadProgressUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.PROGRESS;
  progress: LoadProgress;
  isSaved?: boolean;
}

interface LoadSavedUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.SAVED;
  isSaved: boolean;
}

interface LoadViewedAndPosterUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.VIEWED;
  poster: Company | undefined;
}

interface LoadCalledUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.CALLED;
  isCalled: boolean;
}

interface LoadEmailedUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.EMAILED;
  isEmailed: boolean;
}

interface LoadNoteUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.NOTE;
  note: string;
}

interface PrivateLoadNoteUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.PRIVATELOADNOTE;
  privateLoadNote: string;
}

export interface LoadHiddenUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.HIDDEN;
  isHidden: boolean;
}

export interface LoadBlockUpdate extends BaseLoadUpdate {
  category: UpdateCategory.BLOCKED;
  posterID: string;
  isBlocked: boolean;
  shouldRemoveLoad?: boolean;
}

interface LoadFavoriteUpdate extends BaseLoadUpdate {
  category: UpdateCategory.FAVORITE;
  posterID: string;
  isFavorited: boolean;
}

interface LoadOnboardedUpdate extends BaseLoadUpdate {
  category: UpdateCategory.ONBOARDED;
  posterID: string;
  isOnboarded: boolean;
}

interface LoadAgeUpdate extends BaseLoadUpdate {
  category: UpdateCategory.AGE;
  loadID: string;
  age: number;
}

interface LoadStatusUpdate extends BaseLoadUpdate {
  category: UpdateCategory.STATUS;
  loadID: string;
  status: LoadStatus;
}

interface LoadStatusBulkUpdate extends LoadListUpdate {
  category: UpdateCategory.STATUS_BULK;
  status: LoadStatus;
}

interface LoadPickupDatesBulkUpdate extends LoadListUpdate {
  category: UpdateCategory.PICKUP_DATES_BULK;
  dates: string[];
}

interface LoadRefreshAgeBulkUpdate extends LoadListUpdate {
  category: UpdateCategory.AGE_BULK;
  age: number;
}

interface LoadVendorBidUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.VENDOR_BID_SENT;
  bid: VendorBidInfo | undefined;
}

interface LoadBookUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.BOOK_NOW_SENT;
  bookNow: BookNowResponse | undefined;
  errorCode?: number;
}

interface LoadBidUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.INITIAL_BID_SENT;
}

interface LoadPostedProgressUpdate extends LoadSpecificUpdate {
  category: UpdateCategory.POSTED_PROGRESS;
  progress: PostedLoadProgress | undefined;
  dotNo: number | undefined;
}

export type LoadBulkUpdate = LoadPickupDatesBulkUpdate | LoadRefreshAgeBulkUpdate | LoadStatusBulkUpdate;

export type LoadUpdate =
  | LoadProgressUpdate
  | LoadSavedUpdate
  | LoadViewedAndPosterUpdate
  | LoadCalledUpdate
  | LoadNoteUpdate
  | PrivateLoadNoteUpdate
  | LoadHiddenUpdate
  | LoadBlockUpdate
  | LoadFavoriteUpdate
  | LoadOnboardedUpdate
  | LoadAgeUpdate
  | LoadEmailedUpdate
  | LoadStatusUpdate
  | LoadVendorBidUpdate
  | LoadBookUpdate
  | LoadBidUpdate
  | LoadPostedProgressUpdate;

export interface LoadAction extends Action {
  loadID: string;
}

export interface LoadFieldAction extends LoadAction {
  fields: string;
}

export interface SnackbarAction extends Action {
  state: boolean;
  text?: string;
}

export interface SetSelectedSearchAction extends Action {
  search: LoadSearchRequest | undefined;
}

export interface SetSelectedBackhaulAction extends Action {
  backhaul: LoadBackhaul | undefined;
}

export interface LoadResponse extends LoadAction {
  response: Response<{}>;
}

export interface UpdateLoadAction extends Action {
  update: LoadUpdate;
}

export const updateLoadActionCreator =
  (actionType: string) =>
  (update: LoadUpdate): UpdateLoadAction => ({
    type: actionType,
    update: update,
  });

export const createLoadFilter = (filterInput: string) => {
  return (load: Load) => {
    if (filterInput === undefined || filterInput === '') {
      return true;
    } else {
      const comparableInput = normifyString(trim(filterInput.toLowerCase()));
      return (
        compareLocation(comparableInput, load.destinationLocation) ||
        compareLocation(comparableInput, load.originLocation)
      );
    }
  };
};

const compareLocation = (locationToFilter: string, location?: Location): boolean => {
  if (location && location.address) {
    const locationName = normifyString(stringifyLocation(location.address).locationName.toLowerCase());

    return locationName.includes(locationToFilter);
  }
  return false;
};

const normifyString = (str: string) => {
  // biome-ignore lint/suspicious/noMisleadingCharacterClass: remove special characters
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const updateBlockedBrokers = (loads: Load[], blocked: Array<CompanyUserData<boolean>>): Load[] =>
  map(loads, (load) => updateLoadBlockedBrokers(load, blocked));
const updateSimilarLoadsBlockedBrokers = (
  loads: SimilarLoad[],
  blocked: Array<CompanyUserData<boolean>>
): SimilarLoad[] =>
  map(loads, (similarLoad) => {
    const updatedLoad = updateLoadBlockedBrokers(similarLoad.load, blocked);
    if (updatedLoad !== similarLoad.load) {
      return { ...similarLoad, load: updatedLoad };
    }
    return similarLoad;
  });

export const updateLoadFavoriteBrokers = (load: Load, favorites: Array<CompanyUserData<boolean>>) => {
  const matchingUpdatedValue = getMatchingUpdatedCompanyValue(load, favorites);
  if (matchingUpdatedValue !== undefined) {
    // load.poster validated with matchingUpdatedValue !== undefined
    let updatedLoad = cloneAndEnsurePosterMetadata(load);
    updatedLoad = setFavoriteBroker(updatedLoad, matchingUpdatedValue);
    if (matchingUpdatedValue) {
      updatedLoad = setHiddenBroker(updatedLoad, false);
    }
    return updatedLoad;
  }
  return load;
};

const updateLoadOnboarded = (load: Load, onboarded: Array<CompanyUserData<boolean>>) => {
  const matchingUpdatedValue = getMatchingUpdatedCompanyValue(load, onboarded);
  if (matchingUpdatedValue !== undefined) {
    // load.poster validated with matchingUpdatedValue !== undefined
    let updatedLoad = cloneAndEnsurePosterMetadata(load);
    updatedLoad = setOnboarded(updatedLoad, matchingUpdatedValue);
    if (matchingUpdatedValue) {
      updatedLoad = setHiddenBroker(updatedLoad, false);
    }
    return updatedLoad;
  }
  return load;
};

export const updateLoadAge = (load: Load, age: number) => {
  const updatedLoad = clone(load);
  updatedLoad.age = age;
  return updatedLoad;
};

export const updateLoadBlockedBrokers = (load: Load, blocked: Array<CompanyUserData<boolean>>) => {
  const matchingUpdatedValue = getMatchingUpdatedCompanyValue(load, blocked);
  if (matchingUpdatedValue !== undefined) {
    // load.poster validated with matchingUpdatedValue !== undefined
    let updatedLoad = cloneAndEnsurePosterMetadata(load);
    updatedLoad = setHiddenBroker(updatedLoad, matchingUpdatedValue);
    if (matchingUpdatedValue) {
      updatedLoad = setFavoriteBroker(updatedLoad, false);
    }
    return updatedLoad;
  }
  return load;
};

const cloneAndEnsurePosterMetadata = (load: Load) =>
  load.poster && !load.poster.metaData
    ? {
        ...load,
        poster: {
          ...load.poster,
          metaData: {
            favoriteBroker: false,
            favoriteCarrier: false,
            hiddenBroker: false,
            hiddenCarrier: false,
            onboarded: false,
          },
        },
      }
    : load;

const setHiddenBroker = (load: Load, value: boolean) =>
  load.poster?.metaData
    ? {
        ...load,
        poster: {
          ...load.poster,
          metaData: {
            ...load.poster.metaData,
            hiddenBroker: value,
          },
        },
      }
    : load;

const setFavoriteBroker = (load: Load, value: boolean) =>
  load.poster?.metaData
    ? {
        ...load,
        poster: {
          ...load.poster,
          metaData: {
            ...load.poster.metaData,
            favoriteBroker: value,
          },
        },
      }
    : load;

const setOnboarded = (load: Load, value: boolean) =>
  load.poster?.metaData
    ? {
        ...load,
        poster: {
          ...load.poster,
          metaData: {
            ...load.poster.metaData,
            onboarded: value,
          },
        },
      }
    : load;

export const updateLoad = (load: Load, update: LoadUpdate) => {
  if (
    update.category !== UpdateCategory.BLOCKED &&
    update.category !== UpdateCategory.FAVORITE &&
    update.category !== UpdateCategory.ONBOARDED &&
    update.loadID !== load.id
  ) {
    return load;
  }

  if (update.category === UpdateCategory.POSTED_PROGRESS) {
    const updatedLoad = createLoadPosterMetadata(load);
    if (updatedLoad && updatedLoad.posterMetadata) {
      updatedLoad.posterMetadata.progress = update.progress;

      if (update.dotNo) {
        updatedLoad.posterMetadata.assignedTo = { dotNo: update.dotNo };
      } else if (update.progress === PostedLoadProgress.Unassigned) {
        updatedLoad.posterMetadata.assignedTo = undefined;
      }
      if (update.progress === PostedLoadProgress.Assigned) {
        updatedLoad.status = LoadStatus.Offline;
      }
    }

    return updatedLoad;
  }
  const updatedLoad = createLoadMetadata(load);
  if (updatedLoad && updatedLoad.metadata && updatedLoad.metadata.userdata) {
    switch (update.category) {
      case UpdateCategory.SAVED:
        updatedLoad.metadata.userdata.isSaved = update.isSaved;
        break;
      case UpdateCategory.VIEWED:
        updatedLoad.metadata.userdata.isViewed = true;
        if (updatedLoad.poster && updatedLoad.poster.name === '' && update.poster) {
          updatedLoad.poster = update.poster;
        }
        break;
      case UpdateCategory.CALLED:
        updatedLoad.metadata.userdata.isCalled = update.isCalled;
        updatedLoad.metadata.userdata.isContacted = update.isCalled;
        break;
      case UpdateCategory.EMAILED:
        updatedLoad.metadata.userdata.isEmailed = update.isEmailed;
        updatedLoad.metadata.userdata.isContacted = update.isEmailed;
        break;
      case UpdateCategory.PROGRESS:
        updatedLoad.metadata.userdata.progress = update.progress;
        if (update.isSaved !== undefined) {
          updatedLoad.metadata.userdata.isSaved = !!update.isSaved;
        }
        break;
      case UpdateCategory.NOTE:
        updatedLoad.metadata.userdata.note = update.note;
        break;
      case UpdateCategory.PRIVATELOADNOTE:
        updatedLoad.metadata.userdata.privateLoadNote = update.privateLoadNote;
        break;
      case UpdateCategory.HIDDEN:
        updatedLoad.metadata.userdata.isHidden = update.isHidden;
        break;
      case UpdateCategory.BLOCKED:
        if (updatedLoad.poster && updatedLoad.poster.metaData && updatedLoad.poster.id === update.posterID) {
          updatedLoad.poster.metaData.hiddenBroker = update.isBlocked;
        }
        break;
      case UpdateCategory.STATUS:
        updatedLoad.status = update.status;
        break;
      case UpdateCategory.VENDOR_BID_SENT:
        updatedLoad.metadata.userdata.isContacted = true;
        if (updatedLoad.vendorInfo && update.bid) {
          updatedLoad.vendorInfo = { ...updatedLoad.vendorInfo, bid: update.bid };
        }
        break;
      case UpdateCategory.BOOK_NOW_SENT:
        if (update.errorCode === ApiErrorCode.BOOK_NOW_LOAD_UNAVAILABLE) {
          updatedLoad.status = LoadStatus.Offline;
        } else if (!update.errorCode) {
          updatedLoad.metadata.userdata.isContacted = true;
          updatedLoad.bookNow = update.bookNow;
        }
        break;
      case UpdateCategory.INITIAL_BID_SENT:
        if (updatedLoad.rateNegotiations) {
          updatedLoad.rateNegotiations.numberOfBids = updatedLoad.rateNegotiations.numberOfBids + 1;
        }
        break;
      case UpdateCategory.FAVORITE:
        return updateLoadFavoriteBrokers(updatedLoad, [{ id: update.posterID, value: update.isFavorited }]);
      case UpdateCategory.ONBOARDED:
        return updateLoadOnboarded(updatedLoad, [{ id: update.posterID, value: update.isOnboarded }]);
      case UpdateCategory.AGE:
        return updateLoadAge(updatedLoad, update.age);
    }
  }
  return updatedLoad;
};

const createLoadMetadata = (load: Load) => {
  const updatedLoad = cloneDeep(load);
  if (updatedLoad) {
    if (!updatedLoad.metadata) {
      updatedLoad.metadata = {};
    }
    if (!updatedLoad.metadata.userdata) {
      updatedLoad.metadata.userdata = {
        isGone: false,
        isViewed: false,
        isLowPayRate: false,
        isHidden: false,
        isCalled: false,
        isEmailed: false,
        isMessaged: false,
        isContacted: false,
        isWrongInfo: false,
        isNotInterested: false,
        isSaved: false,
      };
    }
  }
  return updatedLoad;
};

const createLoadPosterMetadata = (load: Load) => {
  const updatedLoad = cloneDeep(load);
  if (updatedLoad) {
    if (!updatedLoad.posterMetadata) {
      updatedLoad.posterMetadata = {
        progress: undefined,
        assignedTo: {
          dotNo: 0,
        },
      };
    }
  }
  return updatedLoad;
};

export const updateLoadsListAfterBulkUpdate = (loads: Load[], update: LoadBulkUpdate): void => {
  let selectedLoads: Load[];
  if (update.isExcluded) {
    selectedLoads = filter(loads, (load) => !includes(update.loadIDs, load.id));
  } else {
    selectedLoads = filter(loads, (load) => includes(update.loadIDs, load.id));
  }
  switch (update.category) {
    case UpdateCategory.STATUS_BULK: {
      pullAll(loads, selectedLoads);
      break;
    }
    case UpdateCategory.PICKUP_DATES_BULK: {
      forEach(loads, (load) => {
        if (includes(selectedLoads, load)) {
          const loadPickupDate = load.pickupDateTime
            ? moment(load.pickupDateTime, 'ddd, DD MMM YYYY HH:mm:SS')
            : moment();
          const newPickupDay = parseInt(moment(first(update.dates)).format('D'), 10);
          const newPickupMonth = parseInt(moment(first(update.dates)).format('M'), 10);
          const { fetchedAt } = addLoadFetchedTime(Date.now())(load);
          //have to decrement here, since moment sets month with offset. E.g. moment.set('month', 3) gives April
          loadPickupDate.set('month', newPickupMonth - 1);
          loadPickupDate.set('date', newPickupDay);
          load.pickupDateTime = loadPickupDate.format('ddd, DD MMM YYYY HH:mm:SS');
          load.pickupDateTimes = [loadPickupDate.format('ddd, DD MMM YYYY HH:mm:SS')];
          load.age = 1;
          load.fetchedAt = fetchedAt;
        }
      });
      break;
    }
    case UpdateCategory.AGE_BULK: {
      forEach(loads, (load) => {
        if (includes(selectedLoads, load)) {
          const { fetchedAt } = addLoadFetchedTime(Date.now())(load);
          load.age = update.age;
          load.fetchedAt = fetchedAt;
        }
      });
      break;
    }
  }
};

export const updateLoadsList = (loads: Load[], update: LoadUpdate): Load[] => {
  switch (update.category) {
    case UpdateCategory.HIDDEN:
      return removeLoadsMatchingID(loads, update.loadID);
    case UpdateCategory.BLOCKED:
      if (update.isBlocked && update.shouldRemoveLoad) {
        return filter(loads, (load) => !load.poster || load.poster.id !== update.posterID);
      }
      return updateBlockedBrokers(loads, [{ id: update.posterID, value: update.isBlocked }]);
    case UpdateCategory.FAVORITE:
      return map(loads, (load: Load) => {
        return updateLoadFavoriteBrokers(load, [{ id: update.posterID, value: update.isFavorited }]);
      });
    case UpdateCategory.ONBOARDED:
      return map(loads, (load: Load) => {
        return updateLoadOnboarded(load, [{ id: update.posterID, value: update.isOnboarded }]);
      });
    default:
      return map(loads, (load: Load) => {
        if (load.id === update.loadID) {
          return updateLoad(load, update);
        }
        return load;
      });
  }
};

export const updateSimilarLoadsList = (loads: SimilarLoad[], update: LoadUpdate): SimilarLoad[] => {
  switch (update.category) {
    case UpdateCategory.HIDDEN:
      return removeSimilarLoadsMatchingID(loads, update.loadID);
    case UpdateCategory.BLOCKED:
      if (update.isBlocked && update.shouldRemoveLoad) {
        return filter(
          loads,
          (similarLoad) => !similarLoad.load.poster || similarLoad.load.poster.id !== update.posterID
        );
      }
      return updateSimilarLoadsBlockedBrokers(loads, [{ id: update.posterID, value: update.isBlocked }]);
    case UpdateCategory.FAVORITE:
      return map(loads, (similarLoad: SimilarLoad) => {
        const updatedLoad = updateLoadFavoriteBrokers(similarLoad.load, [
          { id: update.posterID, value: update.isFavorited },
        ]);
        if (updatedLoad !== similarLoad.load) {
          return { ...similarLoad, load: updatedLoad };
        }
        return similarLoad;
      });
    case UpdateCategory.ONBOARDED:
      return map(loads, (similarLoad: SimilarLoad) => {
        const updatedLoad = updateLoadOnboarded(similarLoad.load, [{ id: update.posterID, value: update.isOnboarded }]);
        if (updatedLoad !== similarLoad.load) {
          return { ...similarLoad, load: updatedLoad };
        }
        return similarLoad;
      });
    default:
      return map(loads, (similarLoad: SimilarLoad) => {
        if (similarLoad.load.id === update.loadID) {
          return {
            ...similarLoad,
            load: updateLoad(similarLoad.load, update),
          };
        }
        return similarLoad;
      });
  }
};

export const isLoadViewed = (load: Load | undefined): boolean => load?.metadata?.userdata?.isViewed ?? false;

export const isLoadSaved = (load: Load | undefined): boolean => load?.metadata?.userdata?.isSaved ?? false;

export const isLoadHidden = (load: Load | undefined): boolean => load?.metadata?.userdata?.isHidden ?? false;

export const isLoadCalled = (load: Load | undefined): boolean => load?.metadata?.userdata?.isCalled ?? false;

export const doesNoteExist = (load: Load | undefined) => !!load?.metadata?.userdata?.note;

export const doesPrivateLoadNoteExist = (load: Load | undefined) => !!load?.metadata?.userdata?.privateLoadNote;

export const isLoadBooked = (load: Pick<Load, 'metadata'> | undefined): boolean => {
  const progress = load?.metadata?.userdata?.progress;
  return !!progress && progress !== LoadProgress.LoadAvailable;
};

export const isLoadEmailed = (load: Load | undefined): boolean => !!load?.metadata?.userdata?.isEmailed;

export const isLoadMessaged = (load: Load | undefined): boolean => !!load?.metadata?.userdata?.isMessaged;

export const isLoadBidSent = (load: Load | undefined): boolean =>
  !!(load?.rateNegotiations?.numberOfBids && load?.rateNegotiations?.numberOfBids > 0);

export const isLoadBookingRejected = (load: Load | undefined): boolean =>
  load?.bookNow?.bookingStatus === BookingStatus.Rejected;

export const getLoadMatchFrom = (load: Load | SimilarLoad): LoadMatch | undefined => {
  const possibleLoad = load as Load;
  if (possibleLoad.id !== undefined) {
    return {
      load: possibleLoad,
      nonMatching: {
        fields: [],
        equipments: [],
      },
    };
  }
  const similarLoad = load as SimilarLoad;
  if (similarLoad.load) {
    return similarLoad;
  }
  return undefined;
};

export const removeLoadsMatchingID = (loads: Load[] | undefined, loadID: string): Load[] =>
  filter(loads, (load: Load) => !isEqual(load.id, loadID));
export const removeSimilarLoadsMatchingID = (loads: SimilarLoad[] | undefined, loadID: string): SimilarLoad[] =>
  filter(loads, (load: SimilarLoad) => !isEqual(load.load.id, loadID));

export const getLoadBrokerStatus = (
  load: Load | undefined
): { isFavorite: boolean; isBlocked: boolean; onboarded: boolean } => {
  let isFavorite = false;
  let isBlocked = false;
  let isOnboarded = false;
  if (load && load.poster && load.poster.metaData) {
    isFavorite = load.poster.metaData.favoriteBroker;
    isBlocked = load.poster.metaData.hiddenBroker;
    isOnboarded = load.poster.metaData.onboarded;
  }
  return { isFavorite: isFavorite, isBlocked: isBlocked, onboarded: isOnboarded };
};

export const isBrokerFavorite = (load: Load | undefined): boolean => {
  if (load && load.poster && load.poster.metaData) {
    return load.poster.metaData.favoriteBroker;
  }
  return false;
};

export const isOnboarded = (load: Load | undefined): boolean => {
  if (load && load.poster && load.poster.metaData) {
    return load.poster.metaData.onboarded;
  }
  return false;
};

export const isBrokerBlocked = (load: Load | undefined): boolean => {
  if (load && load.poster && load.poster.metaData) {
    return load.poster.metaData.hiddenBroker;
  }
  return false;
};

const isMatching = <T>(field: T, nonMatchingFields: T[] | undefined): boolean => !includes(nonMatchingFields, field);

export const isMatchingField = (field: LoadFields, nonMatching: NonMatchingLoad | undefined) =>
  isMatching(field, nonMatching?.fields);

export const isMatchingEquipment = (equipment: EquipmentType, nonMatching: NonMatchingLoad | undefined) =>
  isMatching(equipment, nonMatching?.equipments);

export const similarLoadsFrom = (response: SimilarLoadSearchResponse): SimilarLoad[] => {
  const now = Date.now();
  return map(response.loads, (load, index) => {
    const nonMatching: NonMatchingLoad = nth(response.nonMatchingFields, index) ?? {
      fields: [],
      equipments: [],
    };
    return {
      load: addLoadFetchedTime(now)(load),
      nonMatching: nonMatching,
    };
  });
};

export const loadGeolocationFrom = (
  geolocation: Geolocation,
  reversedGeolocation: ReverseGeolocationResult
): LoadGeolocation => ({
  type: LocationType.GEOLOCATION,
  latitude: geolocation.latitude,
  longitude: geolocation.longitude,
  city: reversedGeolocation.city,
  states: reversedGeolocation.stateShortName ? [reversedGeolocation.stateShortName] : undefined,
});

export const isPosterBlocked = (load: Load | undefined, posters: UserDataCompany[]) => {
  if (load && load.poster) {
    return findIndex(posters, (poster) => poster.id === load.poster?.id) >= 0;
  }
  return false;
};

const getMatchingUpdatedCompanyValue = (load: Load, posters: Array<CompanyUserData<boolean>>): boolean | undefined => {
  if (load.poster) {
    const matchingUpdatedCompanyValue = find(posters, (poster) => poster.id === load.poster?.id);
    if (matchingUpdatedCompanyValue) {
      return matchingUpdatedCompanyValue.value;
    }
  }
  return undefined;
};

export const isLoadAvailable = (load: Load | undefined): boolean => load?.status === LoadStatus.Online;

export const addLoadFetchedTime = (now: number) => (load: Load) => ({ ...load, fetchedAt: Math.floor(now / 1000) });

export const addFetchedTime = (loads: Load[]): Load[] => {
  const now = Date.now();
  return map(loads, addLoadFetchedTime(now));
};

export const loadComputedAge = (load: Load, currentTime: number): number | undefined => {
  if (isNil(load.age) || !load.fetchedAt) {
    return undefined;
  }
  return load.age + (Math.floor(currentTime / 1000) - load.fetchedAt);
};

export const isLoadNew = (load: Load, currentTime: number) =>
  isNewLoadPrivate(load, () => loadComputedAge(load, currentTime));

export const isLoadNewWithAge = (load: Load, age: number | undefined) => isNewLoadPrivate(load, () => age);

const isNewLoadPrivate = (load: Load, getAge: () => number | undefined) => {
  if (!!load.isFromAutorefresh && isLoadAvailable(load) && !isLoadViewed(load)) {
    const age = getAge();
    return !isUndefined(age) && age < NEW_LOAD_SECONDS_THRESHOLD;
  }
  return false;
};

/** Flat Rate Amount is the total amount.
 * returns undefined if there is no amount defined on the load
 */
export const getFlatRateAmount = (load: Pick<Load, 'rate' | 'mileage' | 'computedMileage'>): number | undefined => {
  const { rate, mileage, computedMileage } = load;

  if (rate === undefined || rate.amount === undefined || rate.amount === 0) {
    return undefined;
  }

  if (rate.type === 'PerMile') {
    // use broker set mileage, if it is missing or 0 use pc miler's provided mileage
    if (mileage) {
      return rate.amount * mileage;
    }
    return computedMileage ? rate.amount * computedMileage : undefined;
  }

  return rate.amount;
};

export const getPricePerMileRate = (load: Load): number | undefined => {
  if (load.pricePerMile) {
    return load.pricePerMile;
  }
  const rate = load.rate;
  if (rate === undefined) {
    return undefined;
  }

  if (rate.amount === undefined || rate.amount === 0) {
    return undefined;
  }

  if (rate.type === 'PerMile') {
    return rate.amount;
  }
  if (load.computedMileage === undefined || load.computedMileage === 0) {
    return undefined;
  }
  return rate.amount / load.computedMileage;
};

export const getLoadProgress = (currentLoad: Load | undefined): LoadProgress => {
  if (currentLoad && currentLoad.metadata && currentLoad.metadata.userdata && currentLoad.metadata.userdata.progress) {
    return getProgressWithoutExcludedStatuses(currentLoad.metadata.userdata.progress);
  }
  return LoadProgress.LoadAvailable;
};

//it was added because of existing in database loads with excluded progress statuses
export const getProgressWithoutExcludedStatuses = (progress: LoadProgress): LoadProgress => {
  switch (progress) {
    case LoadProgress.LoadEnRoute:
      return LoadProgress.PickupComplete;
    case LoadProgress.ScheduledForPickup:
      return LoadProgress.Booked;
    default:
      return progress;
  }
};

export const getNote = (load: Load) =>
  load.metadata && load.metadata.userdata && load.metadata.userdata.note ? load.metadata.userdata.note : '';

export const getPrivateLoadNote = (load: Load) =>
  load.metadata && load.metadata.userdata && load.metadata.userdata.privateLoadNote
    ? load.metadata.userdata.privateLoadNote
    : '';

export const getRefreshRate = (load: Load, time: number): number => {
  const age = loadComputedAge(load, time);
  // If there are no loads return an arbitrary high number
  if (isUndefined(age)) {
    return ONE_HOUR_IN_SECONDS;
  }
  // Refresh once a second when there are any loads under a minute in age
  if (age < ONE_MINUTE_IN_SECONDS) {
    return ONE_SECOND;
  }
  if (age < ONE_HOUR_IN_SECONDS) {
    return ONE_MINUTE_IN_SECONDS;
  }
  return ONE_HOUR_IN_SECONDS;
};

/** Changes pickupDateTimes and deliveryDateTime to have no time if value is midnight.
 * Does not modify the Load passed in parameter.
 */
export const removeLoadDatesTimesIfMidnight = (load: Load): Load => ({
  ...load,
  pickupDateTime: stripTimeIfMidnight(load.pickupDateTime),
  pickupDateTimes: compact(map(load.pickupDateTimes, (dateTime) => stripTimeIfMidnight(dateTime))),
  deliveryDateTime: stripTimeIfMidnight(load.deliveryDateTime),
});

/** API-3330 "fixed" dates centrally but then broke the UI in various places. This will detect the presence of the fix and
 * "revert" the pickup/delivery Date times. Once the API is fixed this will have no effect. Remove when API fully deployed. [BP]
 */
export const hackToRevertAPI3330 = (load: Load): Load => {
  if (load.pickupDateTime && extractTimeFromDateTimeIfPresent(load.pickupDateTime)?.startsWith('16:')) {
    const remove4Hours = (dateTime: string): string => {
      const momentDate = parseMomentDate(dateTime, 'ddd, DD MMM YYYY HH:mm:SS');
      if (momentDate === undefined) {
        // issue parsing: pass through, do nothing.
        return dateTime;
      }
      momentDate.add(-4, 'hour');
      return `${momentDate.format('ddd, DD MMM YYYY HH:mm:SS')} GMT`; // replicate server format
    };

    return {
      ...load,
      pickupDateTime: remove4Hours(load.pickupDateTime),
      pickupDateTimes: map(load.pickupDateTimes, (dateTime) => remove4Hours(dateTime)),
      deliveryDateTime:
        load.deliveryDateTime == undefined ? load.deliveryDateTime : remove4Hours(load.deliveryDateTime),
    };
  }

  return load;
};

export const isWithManualPostedExtraStops = (
  extraStops: LoadExtraStop[] | undefined,
  numberOfStops: number | undefined
): boolean => {
  if (numberOfStops === undefined || numberOfStops === 0) {
    return false;
  }
  if (isEmpty(extraStops) || extraStops === undefined) {
    return numberOfStops > 1;
  } else {
    return numberOfStops - extraStops.length > 0;
  }
};

export const getRevenue = (load: Load, linehaulRevenue?: number) => {
  const flatAmount = getFlatRateAmount(load);
  if (!flatAmount) {
    return linehaulRevenue;
  }
  return flatAmount;
};

import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { default as Logo } from '@/component/popup/logo-mycarrierportal.png';
import { ApiErrorCode } from '@common/api';
import { validateNotEmpty } from '@common/helper';
import { getCarrierRiskAssessment, linkMcpAccount, resetMCPApiError } from '@common/redux/epic/MyCarrierPortalEpic';
import { useDidUpdate, useUnmount } from '@common/util/hooks';
import { MainButton } from '@component/buttons';
import { FValidatedTextInput } from '@component/input';
import { BasePopup } from '@component/popup/BasePopup';
import { MCPPopupBody, SetupIntegrationInfoPopup } from '@component/popup/myCarrierPortal/SetupIntegrationInfoPopup';
import { PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

interface Props {
  isOnboarded: boolean;
  contactGuid?: string;
}

export const LinkBrokerAccountPopup = ({ isOnboarded, contactGuid }: Props) => {
  const dispatch = useDispatch();
  const { closingPopup, openingPopup } = usePopup();

  const [username, setUsername] = useState({ value: '', isValid: false });
  const [password, setPassword] = useState({ value: '', isValid: false });
  const linkingState = useSelector((state) => state.myCarrierPortal.linking);
  const isSendingRequest = linkingState.isSendingRequest;
  const isLinked = linkingState.isBrokerAccountLinkedToMcp;
  const areCredentialsInvalid = linkingState.linkError?.code === ApiErrorCode.MCP_INVALID_CREDENTIALS;

  useUnmount(() => {
    dispatch(resetMCPApiError());
  });

  useDidUpdate(() => {
    if (isLinked) {
      closingPopup();
      if (contactGuid) {
        dispatch(getCarrierRiskAssessment(contactGuid));
      }
    }
  }, [isLinked]);

  const linkAccount = () => {
    dispatch(linkMcpAccount({ username: username.value, password: password.value }));
  };

  const button = (
    <PopupPrimaryButton
      testID={t(T.myCarrierPortal_integration_back)}
      key={t(T.myCarrierPortal_integration_back)}
      label={t(T.myCarrierPortal_integration_back)}
      action={() =>
        openingPopup({
          body: <SetupIntegrationInfoPopup isOnboarded={isOnboarded} />,
          isDismissible: true,
          hasCloseButton: true,
          width: PopupSizes.SMALL,
        })
      }
      style={{ width: '100%', marginLeft: 11 }}
    />
  );

  return (
    <BasePopup
      title={t(T.myCarrierPortal_integration_title)}
      onClose={closingPopup}
      withoutContentPadding={true}
      buttons={[button]}
    >
      <MCPPopupBody>
        <div className="container-padding">
          <img src={Logo} style={{ width: 260 }} />
          <p style={{ color: Color.GRAY_DARK }}>{t(T.myCarrierPortal_integration_credentialsDescription)}</p>
          {areCredentialsInvalid ? (
            <p style={{ color: Color.RED_APPLE }}> {t(T.myCarrierPortal_integration_incorrectCredentialsMessage)}</p>
          ) : null}
          <FValidatedTextInput
            id="MCP_username_input"
            label={t(T.myCarrierPortal_integration_userName)}
            value={username.value}
            onChangeWithValidation={(value, isValid) => {
              if (value !== undefined) {
                setUsername({ value: value, isValid: isValid });
              }
            }}
            validator={validateNotEmpty}
            fullWidth
            autoComplete="off"
          />
          <FValidatedTextInput
            id="MCP_password_input"
            label={t(T.myCarrierPortal_integration_password)}
            value={password.value}
            onChangeWithValidation={(value, isValid) => {
              if (value !== undefined) {
                setPassword({ value: value, isValid: isValid });
              }
            }}
            validator={validateNotEmpty}
            fullWidth
            autoComplete="off"
          />
          <MainButton
            id="link"
            title={t(T.myCarrierPortal_integration_linkAccount)}
            onClick={linkAccount}
            isDisabled={!password.isValid || !username.isValid || isSendingRequest}
            isLoading={isSendingRequest}
            style={{ width: '100%' }}
          />
        </div>
      </MCPPopupBody>
    </BasePopup>
  );
};

import React from 'react';

import styled from 'styled-components';

const StyledFieldset = styled.fieldset`
  text-align: left;
  border-radius: 2px;
  box-sizing: border-box;
  margin-right: 0;
  margin-left: 0;
  margin-top: ${(props: FieldsetProps) => (props.alignVertical ? '-6px' : '0')};
  margin-bottom: ${(props: FieldsetProps) => (props.isFocused ? '-1px' : '0')};
  padding: ${(props: FieldsetProps) => (props.isFocused ? '0 9px' : '0 10px')};
  border-style: solid;
  border-color: ${(props: FieldsetProps) => props.color};
  border-width: ${(props: FieldsetProps) => (props.isFocused ? '2px' : '1px')};
  width: ${(props: FieldsetProps) => (props.fullWidth ? '100%' : 'inherit')};
  outline: none;
`;

const StyledLegend = styled.legend`
  font-size: 10px;
  padding: 0 4px;
  opacity: 0.7;
  color: ${(props: { color?: string }) => (props.color ? props.color : '')};
`;

export interface FieldsetProps {
  id: string;
  label?: string;
  color?: string;
  fullWidth?: boolean;
  isFocused?: boolean;
  alignVertical?: boolean;
}

const Fieldset: React.SFC<FieldsetProps> = (props) => {
  return (
    <StyledFieldset {...props}>
      <StyledLegend id={props.id + '_legend'} color={props.color}>
        {props.label}
      </StyledLegend>
      {props.children}
    </StyledFieldset>
  );
};

export default Fieldset;

import { default as CardValidator } from 'card-validator';
import { capitalize, forEach, isFinite, replace, toNumber, trim } from 'lodash';

import { cleanDashes, parseFloatFromString, validateURL } from '@common/helper';
import { MAX_BID_AMOUNT, MIN_BID_AMOUNT } from '@common/helper/BidsHelper';
import { isAmericanState, isCanadianState } from '@common/helper/LocationHelper';
import { cleanSpaces, removeLeadingZeroes } from '@common/helper/NumberHelper';
import { isEmailValid, isPhoneValid, isStringValid, isValidUsDotNumber } from '@common/helper/ValidationHelper';
import { t, T } from '@translate';

export interface ValidationResult {
  isValid: boolean;
  blocking?: boolean; // if true, stop other validators until this one is satisfied.
  error: string[];
}

const DEFAULT_VALIDATION_RESULT = (): ValidationResult => ({ isValid: true, error: [] });

export const blocking = (
  validator: (validatable: string) => ValidationResult
): ((validatable: string) => ValidationResult) => {
  return (validatable: string) => ({ ...validator(validatable), blocking: true });
};

export const validateIsNumber = (validatable: string): ValidationResult => ({
  isValid: isFinite(toNumber(replace(validatable, /,/g, ''))),
  error: [t(T.common_errors_mustBeNumber)],
});

export const validateStringLengthNotZeroAndAsciiOnly = (validatable: string): ValidationResult => ({
  isValid: trim(validatable).length > 0 && /^[a-zA-Z0-9 ]*$/.test(validatable),
  error: [t(T.common_errors_requiredAsciiOnly)],
});

export const validateStringLengthNotZeroAndAsciiAndHyphen = (validatable: string): ValidationResult => ({
  isValid: trim(validatable).length > 0 && /^[a-zA-Z0-9_-]*$/.test(validatable),
  error: [t(T.common_errors_requiredAsciiAndHyphenOnly)],
});

export const validateStringLengthNotZeroAndNumberOnly = (validatable: string): ValidationResult => ({
  isValid: trim(validatable).length > 0 && /^[0-9 ]*$/.test(validatable),
  error: [t(T.common_errors_requiredNumbersOnly)],
});

const validatePriceDollarsAndCents = (validatable: string): ValidationResult => ({
  isValid: trim(validatable).length > 0 && /(^[0-9]*\.[0-9]{1,2}$|^[0-9]*$)/.test(validatable),
  error: [t(T.common_errors_requiredPrice)],
});

export const validateIsGreaterThan =
  (value: number) =>
  (validatable: string): ValidationResult => ({
    isValid: parseFloatFromString(validatable) > value,
    error: [t(T.common_errors_greaterThanValue, { value: value })],
  });

export const validateBetween =
  (min: number, max: number, fieldName: string) =>
  (validatable: string): ValidationResult => ({
    isValid: parseFloatFromString(validatable) >= min && parseFloatFromString(validatable) <= max,
    error: [t(T.common_errors_betweenValues, { fieldName: capitalize(fieldName), min: min, max: max })],
  });

export const validateStringLengthNotZero = (validatable: string): ValidationResult => ({
  isValid: trim(validatable).length > 0,
  error: [t(T.common_errors_required)],
});

export const validateStringNoWhitespace = (validatable: string): ValidationResult => ({
  isValid: validatable.search(/\s/) === -1,
  error: [t(T.common_errors_noSpaces)],
});

export const validateStringSecureWebProtocol = (validatable: string): ValidationResult => {
  return {
    isValid: validatable.startsWith('https://') && validateURL(validatable),
    error: [t(T.common_errors_invalidWebProtocol)],
  };
};

export const validateLengthGreaterThanOrEqualTo =
  (lowerBound: number) =>
  (validatable: string): ValidationResult => ({
    isValid: validatable.length >= lowerBound,
    error: [t(T.common_errors_minLength, { length: lowerBound })],
  });

export const validateEmail = (email: string | undefined): ValidationResult => ({
  isValid: isEmailValid(trim(email)),
  error: [t(T.common_errors_email)],
});

export const validatePhone = (phone: string): ValidationResult => {
  return {
    isValid: isPhoneValid(phone),
    error: [t(T.common_errors_phone)],
  };
};

export const validateEmailCanBeEmpty = (email: string): ValidationResult => ({
  isValid: isEmailValid(trim(email)) || email == '',
  error: [t(T.common_errors_email)],
});

export const validatePhoneCanBeEmpty = (phone: string): ValidationResult => {
  return {
    isValid: isPhoneValid(phone) || phone == '',
    error: [t(T.common_errors_phone)],
  };
};

export const validateNotEmpty = (value?: string): ValidationResult => ({
  isValid: isStringValid(value),
  error: [t(T.common_errors_empty)],
});

export const validateMaxLength =
  (max: number) =>
  (value?: string): ValidationResult => ({
    isValid: trim(value).length <= max,
    error: [t(T.common_errors_max, { max: max })],
  });

export const validateDOTNumber = (validatable: string): ValidationResult => {
  const withoutLeadingZeroes = removeLeadingZeroes(validatable);
  return {
    isValid: !withoutLeadingZeroes || withoutLeadingZeroes.length <= 3 || isValidUsDotNumber(validatable),
    error: [t(T.common_errors_validDOT)],
  };
};

export const validateState = (validatable: string): ValidationResult => {
  const validate = isCanadianState(validatable) || isAmericanState(validatable) || validatable == '';

  return {
    isValid: validate,
    error: [t(T.common_errors_state)],
  };
};

export const validatedStreetNumber = (validatable: string): ValidationResult => {
  const validate = validatable.length <= 6;
  return {
    isValid: validate,
    error: [t(T.common_errors_streetNumberError)],
  };
};

export const validateTruckWeight = (weight: string, isEnglish: boolean): ValidationResult => {
  let errorMessage: string;
  let limit: number;
  let isValid: boolean;
  if (weight === '') {
    isValid = true;
    errorMessage = '';
  } else if (isEnglish) {
    limit = 132000;
    errorMessage = t(T.common_errors_weightErrorLbs);
    isValid = trim(weight).length >= 0 && /^[0-9.]*$/.test(weight) && toNumber(weight) <= limit;
  } else {
    limit = 59874;
    errorMessage = t(T.common_errors_weightErrorKgs);
    isValid = trim(weight).length >= 0 && /^[0-9.]*$/.test(weight) && toNumber(weight) <= limit;
  }

  return {
    isValid: isValid,
    error: [errorMessage],
  };
};

export const validateStringLengthNotZeroAndNumberOnlyMetricLength = (validatable: string): ValidationResult => ({
  isValid:
    (trim(validatable).length > 0 &&
      /^[0-9.]*$/.test(validatable) &&
      toNumber(validatable) <= 21 &&
      toNumber(validatable) >= 2.4384) ||
    validatable === '',
  error: [t(T.common_errors_lengthErrorMetric)],
});
export const validateStringLengthNotZeroAndNumberOnlyMetricHeight = (validatable: string): ValidationResult => ({
  isValid:
    (trim(validatable).length > 0 &&
      /^[0-9,.]*$/.test(validatable) &&
      toNumber(replace(validatable, ',', '')) <= 4.2 &&
      toNumber(replace(validatable, ',', '')) >= 1.524) ||
    validatable === '',
  error: [t(T.common_errors_heightErrorMetric)],
});
export const validateStringLengthNotZeroAndNumberOnlyFeetHeight = (validatable: string): ValidationResult => ({
  isValid:
    (trim(validatable).length > 0 &&
      /^[0-9,.]*$/.test(validatable) &&
      toNumber(replace(validatable, ',', '')) <= 14 &&
      toNumber(replace(validatable, ',', '')) >= 5) ||
    validatable === '',
  error: [t(T.common_errors_heightErrorFeet)],
});
export const validateStringLengthNotZeroAndNumberOnlyFeetLength = (validatable: string): ValidationResult => ({
  isValid:
    (trim(validatable).length > 0 &&
      /^[0-9,.]*$/.test(validatable) &&
      toNumber(replace(validatable, ',', '')) <= 69 &&
      toNumber(replace(validatable, ',', '')) >= 8) ||
    validatable === '',
  error: [t(T.common_errors_lengthErrorFeet)],
});

export const validateZipCode = (validatable: string): ValidationResult => {
  const length = cleanDashes(cleanSpaces(validatable)).length;
  const validate = length === 5 || length === 6 || length === 9 || validatable === '' ? true : false;

  return {
    isValid: validate,
    error: [t(T.common_errors_state)],
  };
};

export const validateMCNumber = (validatable: string): ValidationResult => {
  const withoutLeadingZeroes = removeLeadingZeroes(validatable);
  return {
    isValid: !withoutLeadingZeroes || withoutLeadingZeroes.length <= 3 || isValidUsDotNumber(validatable),
    error: [t(T.common_errors_validMC)],
  };
};

export const validatePhoneShortMessage = (phone: string): ValidationResult => {
  return {
    isValid: isPhoneValid(phone),
    error: [t(T.common_errors_invalidPhoneShortError)],
  };
};

export const validateCardNumber = (cardNumber: string): ValidationResult => {
  return {
    isValid: CardValidator.number(cardNumber).isValid,
    error: [t(T.common_errors_invalidCardError)],
  };
};

export const combineValidators =
  (...functions: Array<(value: string) => ValidationResult>) =>
  (value: string): ValidationResult => {
    let result: ValidationResult = DEFAULT_VALIDATION_RESULT();
    forEach(functions, (func: (value: string) => ValidationResult) => {
      const validationResult = func(value); // Make async and add await her more complex validations
      if (!validationResult.isValid) {
        result = {
          isValid: false,
          error: [...result.error, ...validationResult.error],
        };
        if (validationResult.blocking) {
          return false;
        }
      }
      return true;
    });
    return result;
  };

export const validateIsLessThan =
  (value: number) =>
  (validatable: string): ValidationResult => ({
    isValid: parseFloatFromString(validatable) < value,
    error: [t(T.common_errors_lessThanValue, { value: value })],
  });

export const isPriceAndLessThanOneHundred = combineValidators(
  blocking(validatePriceDollarsAndCents),
  validateIsGreaterThan(0),
  validateIsLessThan(100)
);

export const validateIsNumberAndLessThan = (number: number) =>
  combineValidators(blocking(validateIsNumber), validateIsLessThan(number));

export const validateEmailBlockingEmpty = combineValidators(blocking(validateStringLengthNotZero), validateEmail);

const validateMinFlatRate =
  (value: number) =>
  (validatable: string): ValidationResult => {
    return {
      isValid: isStringValid(validatable) && value < parseFloatFromString(validatable),
      error: [t(T.common_bids_validation_tooLow)],
    };
  };
export const validateBidIsNumberAndBoundaries = combineValidators(
  blocking(validateIsNumber),
  blocking(validateMinFlatRate(MIN_BID_AMOUNT)),
  validateIsLessThan(MAX_BID_AMOUNT)
);
export const validateDifferentOffer =
  (value?: number) =>
  (validatable: string): ValidationResult => {
    return {
      isValid: !value || value !== parseFloatFromString(validatable),
      error: [t(T.common_bids_validation_sameValue)],
    };
  };

export const validateInteractiveBid = (prevOffer?: number) =>
  combineValidators(
    blocking(validateNotEmpty),
    blocking(validateBidIsNumberAndBoundaries),
    validateDifferentOffer(prevOffer)
  );

export const validateBid = combineValidators(blocking(validateNotEmpty), blocking(validateBidIsNumberAndBoundaries));

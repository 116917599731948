import { useEffect, useMemo, useRef } from 'react';

import { isUndefined } from 'lodash';
import { useDispatch, useSelector as baseUseSelector } from 'react-redux';

import { fetchRouteMap, LoadDetailsMapState } from '@common/redux/epic/LoadDetailsMapEpic';

type StoreState = { loadDetailsMap: LoadDetailsMapState };
const useSelector = <T>(selector: (storeState: StoreState) => T) => baseUseSelector<StoreState, T>(selector);

export const useMapImage = (
  loadId: string,
  archivingFlowID: string | undefined,
  shouldFetch = true
): [string | undefined, boolean] => {
  const dispatch = useDispatch();
  const currentMapState = useSelector((state) => state.loadDetailsMap);

  const mapStateRef = useRef<LoadDetailsMapState>();

  useEffect(() => {
    if (currentMapState.loadID === loadId) {
      mapStateRef.current = currentMapState;
    }
  }, [currentMapState, mapStateRef]);

  const map = useMemo(() => {
    if (currentMapState.loadID === loadId) {
      return currentMapState;
    }
    if (mapStateRef.current?.loadID === loadId) {
      return mapStateRef.current;
    }
    return undefined;
  }, [loadId, currentMapState, mapStateRef]);

  useEffect(() => {
    if (!currentMapState.isLoading && isUndefined(map) && shouldFetch) {
      dispatch(fetchRouteMap(loadId, archivingFlowID));
    }
  }, [loadId, currentMapState.isLoading, shouldFetch]);

  return [map?.map, map?.isLoading ?? true];
};

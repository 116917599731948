import React from 'react';

import styled from 'styled-components';

import { displayDate } from '@common/formatter';
import { Message } from '@common/model';
import { Color } from '@style/Color';
import { FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

import { default as AlertSvg } from './icon-alerts.svg';

interface Props {
  message: Message;
}

const TruckAlertMessageBar = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 70px;
  background-color: ${Color.ORANGE_MARIGOLD};
  border-bottom: 2px solid ${Color.ORANGE_MAIN};
  padding: 10px ${Spacing.ScreenSide}px;
  box-sizing: border-box;
`;

const TruckAlertMessageInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

const TruckAlertMessageSentTime = styled.div`
  margin-top: 3px;
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.BLACK};
`;

const TruckAlertMessageText = styled.div`
  margin-top: ${Spacing.InterElementVertical}px;
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.BLACK};
`;

export const TruckAlertMessage: React.FC<Props> = ({ message }) => {
  return (
    <TruckAlertMessageBar>
      <img style={{ marginRight: Spacing.ScreenSide }} src={AlertSvg} alt="bell" />
      <TruckAlertMessageInformation>
        <TruckAlertMessageSentTime>
          {message.time
            ? t(T.common_loadDetails_truckAlert_alertSent, { time: displayDate(message.time) })
            : t(T.common_notApplicable)}
        </TruckAlertMessageSentTime>
        <TruckAlertMessageText>{message.text}</TruckAlertMessageText>
      </TruckAlertMessageInformation>
    </TruckAlertMessageBar>
  );
};

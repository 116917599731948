import { BidLoadRate } from './Bid';
import { BaseLocation } from './Location';

export enum Vendor {
  Loadsmart = 'Loadsmart',
  McLeod = 'McLeod',
  Parade = 'Parade',
  VHub = 'VHub',
}

interface VendorBidInfo {
  loadId: string;
  referenceNumber?: string;
  loadCompanyName?: string;
  rate?: BidLoadRate;
  originLocation?: BaseLocation;
  destinationLocation?: BaseLocation;
}
export interface VendorBid extends VendorBidInfo {
  createdOn: string;
}

export interface PostVendorBidRequest {
  loadId: string;
  amount: number;
  vendorId: Vendor;
}

export interface PostVendorBidResponse {
  submittedOn: string;
}

export interface VendorBidsResponse {
  bids: VendorBid[];
  hasMore: boolean;
  token?: string;
}

import React from 'react';

import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { t, T } from '@translate';

import { default as FilterSVG } from './filter.svg';

const ButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  && {
    text-transform: none;
    box-shadow: none;
    padding: 2px 4px;
  }
`;

const Label = styled.span`
  font-size: ${FontSize.Content}px;
  color: ${Color.WHITE};
  padding-right: 4px;
`;

interface Props {
  onClick: () => void;
}

export const FilterButton: React.FC<Props> = (props) => (
  <ButtonContainer variant={'contained'} color={'primary'} id="filter" onClick={props.onClick} size={'small'}>
    <img src={FilterSVG} />
    <Label>{t(T.common_filter)}</Label>
  </ButtonContainer>
);

import styled from 'styled-components';

import { TextField } from '@material-ui/core';

import { ThemeProps, withTheme } from '@style/WithTheme';

export const StyledTextInput = withTheme()(styled(TextField)`
  && {
    flex: 1;
    align-self: stretch;
    min-width: 158px;
    margin: 0px;
    border-bottom: 2px;
    & > label {
      font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
    }
    & > div {
      height: 54px !important;
      background-color: ${(props: any) => (props.disabled ? '#EDEFF1' : props.theme.palette.textInput.backgroundColor)};
      border: 1px solid ${(props: ThemeProps) => props.theme.palette.textInput.borderColor};
      height: 56px;
      font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
      font-size: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontSize};
      line-height: ${(props: ThemeProps) => props.theme.text.subtitleRegular.lineHeight};
      ::before {
        border-color: ${(props: ThemeProps) => props.theme.palette.textInput.borderColor} !important;
      }
      & > input {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-top: 16px;
      }
    }
  }
`);

import { clone, map, toNumber, toString } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { CompanyClient } from '@common/client';
import { IKeysSortBySearchDirectoryCompany } from '@common/helper/SortByKeys';
import { CardView, ViewChoice } from '@common/model';
import {
  CompanyType,
  createDefaultSearchDirectoryRequest,
  DirectoryResponse,
  SEARCH_DIRECTORY_REQUEST_LIMIT_APPENDING,
  SEARCH_DIRECTORY_REQUEST_LIMIT_DEFAULT,
  SEARCH_DIRECTORY_REQUEST_OFFSET_INCREMENT,
  SearchDirectoryCompanyRequest,
  SearchDirectoryCompanyResponse,
  TableSource,
} from '@common/model/SearchDirectory';
import { createAction } from '@common/redux/Base';
import { createPaginatedListReducer, PaginatedListState } from '@common/redux/epic/PaginatedListReducer';
import { createMergedReducer } from '@common/redux/ReduxHelper';

const SEARCH_DIRECTORY_REDUCER_KEY = 'searchDirectory';

export interface SearchDirectoryCompanyState
  extends PaginatedListState<DirectoryResponse, SearchDirectoryCompanyRequest> {
  view: ViewChoice;
  isLoading: boolean;
}

const initialState: SearchDirectoryCompanyState = {
  view: CardView,
  fetchRequest: createDefaultSearchDirectoryRequest(),
  isLoading: false,
  didLoadingFail: false,
  isLoadingMore: false,
  isRefreshing: false,
  isLastResult: false,
};

const searchDirectoryPaginationReducer = createPaginatedListReducer<
  DirectoryResponse,
  SearchDirectoryCompanyRequest,
  SearchDirectoryCompanyResponse,
  SearchDirectoryCompanyState,
  SearchDirectoryCompanyState
>({
  reducerKey: SEARCH_DIRECTORY_REDUCER_KEY,
  requestConstants: {
    limit: {
      default: SEARCH_DIRECTORY_REQUEST_LIMIT_DEFAULT,
      appending: SEARCH_DIRECTORY_REQUEST_LIMIT_APPENDING,
    },
    offsetIncrement: SEARCH_DIRECTORY_REQUEST_OFFSET_INCREMENT,
  },
  getEntriesFromResponse: (response) => response.directories,
  areEntriesEqual: (firstItem, secondItem) => areEntriesEqual(firstItem, secondItem),
  transformFetchedEntries: (entries) => map(entries, transformFetchedEntry),
  defaultEntries: [],
});

const changeViewAction = createAction<ViewChoice>('CHANGE_VIEW_ACTION');
const changeSortByAction = createAction<IKeysSortBySearchDirectoryCompany>('CHANGE_SORT_BY_ACTION');
const updateSearchFormAction = createAction<Partial<SearchDirectoryCompanyRequest>>('UPDATE_SEARCH_FORM');
const setDirectoryViewedStatusAction = createAction<string>('SET_DIRECTORY_VIEWED');

export const changeView = (view: ViewChoice) => changeViewAction.action(view);
export const changeSortBy = (sortBy: IKeysSortBySearchDirectoryCompany) => changeSortByAction.action(sortBy);
export const updateSearchForm = (fetchRequest: Partial<SearchDirectoryCompanyRequest>) =>
  updateSearchFormAction.action(fetchRequest);
export const setDirectoryViewedStatus = (id: string) => setDirectoryViewedStatusAction.action(id);

export const fetchDirectories = searchDirectoryPaginationReducer.actions.fetchEntries;
export const fetchMoreDirectories = searchDirectoryPaginationReducer.actions.fetchMoreEntries;

export const searchDirectoryCompanyReducer = createMergedReducer(
  initialState,
  [
    changeViewAction.addCase((state, action) => {
      state.view = action.data;
    }),
    changeSortByAction.addCase((state, action) => {
      state.fetchRequest = {
        ...state.fetchRequest,
        sortExpression: action.data.field,
        sortDirection: action.data.direction,
        offset: 0,
      };
    }),
    updateSearchFormAction.addCase((state, action) => {
      state.fetchRequest = { ...state.fetchRequest, ...action.data, offset: 0 };
    }),

    setDirectoryViewedStatusAction.addCase((state, action) => {
      state.entries = map(state.entries, (directory) =>
        toString(directory.companyId) === action.data ? { ...directory, isViewed: true } : directory
      );
    }),
  ],
  searchDirectoryPaginationReducer.reducer
);

export const createSearchDirectoryCompanyEpic = (api: Api) => {
  const companiesClient = new CompanyClient(api);

  return (action$: ActionsObservable<Action>, state$: StateObservable<SearchDirectoryCompanyState>) =>
    merge$(
      searchDirectoryPaginationReducer.createMergedEpic$(
        (request) => companiesClient.getSearchDirectoriesCompanies$(fillTableSource(request)),
        action$,
        state$
      )
    );
};

const fillTableSource = (request: SearchDirectoryCompanyRequest): SearchDirectoryCompanyRequest => {
  return {
    ...request,
    tableSource: request.companyType === CompanyType.Shipper ? TableSource.CompanyTable : TableSource.VolpeTable,
  };
};

const areEntriesEqual = (firstItem: DirectoryResponse, secondItem: DirectoryResponse) => {
  if (firstItem.companyGuid && secondItem.companyGuid) {
    return firstItem.companyGuid === secondItem.companyGuid;
  }
  return transformFetchedEntry(firstItem).companyId === transformFetchedEntry(secondItem).companyId;
};

const transformFetchedEntry = (entry: DirectoryResponse) => {
  if (!entry.companyId) {
    const newEntry = clone(entry);
    // concating the usDotNumber with the docketNumber to create a unique ID
    newEntry.companyId = toNumber(`${entry.details?.usDotNumber}${getDocketNumber(entry)}`);
    // clearing the usDotNumber if it contains only zeros.
    if (newEntry.details?.usDotNumber && new RegExp('^0*$').test(newEntry.details.usDotNumber)) {
      newEntry.details.usDotNumber = '';
    }
    return newEntry;
  }
  return entry;
};

export const getDocketNumber = (searchDirectoryRes: DirectoryResponse) => {
  if (
    searchDirectoryRes.details?.businessType.includes(CompanyType.Broker) &&
    searchDirectoryRes.details?.brokerMcNumber
  ) {
    return searchDirectoryRes.details.brokerMcNumber.number;
  }
  if (
    searchDirectoryRes.details?.businessType.includes(CompanyType.Carrier) &&
    searchDirectoryRes.details?.carrierMcNumber
  ) {
    return searchDirectoryRes.details.carrierMcNumber.number;
  }
  return '';
};

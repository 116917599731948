import React from 'react';

import { capitalize, clamp, floor, map } from 'lodash';
import styled from 'styled-components';

import { MultipurposeButton } from '@component/buttons';
import { TextContentDetailDarkBold } from '@component/panels/companyDetails/CompanyDetailsRows';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text';
import { Color } from '@style/Color';
import { FontSize, FontWeight } from '@style/StyleConstants';
import { T, t } from '@translate';

import { default as CarrierSourceIcon } from './logo-carriersource.svg';
import { default as StarIcon } from './star.svg';

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 68px;
`;

const RatingLabel = styled.p`
  font-size: 54px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.GRAY_DARK};
`;

const ReviewLabel = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
`;

const RatingTypeLabel = styled.span`
  font-size: ${FontSize.Content}px;
  color: ${Color.GRAY_DARK};
`;

const StarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  max-height: 68px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const RatingDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const RatingDetailRow = styled.div`
  flex-direction: row;
  display: flex;
`;

const RatingLabelBold = styled(RatingTypeLabel)`
  font-weight: bold;
  margin-left: 5px;
`;

const NotRatedTitle = styled(TextContentDetailDarkBold)`
  font-size: ${FontSize.MediumTitle}px;
  display: block;
  margin: 10px 0;
`;

export const StarRatingLabel: React.FC<{ starRating: number; reviewCount: number }> = ({ starRating, reviewCount }) => {
  return (
    <RatingContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {reviewCount === 0 ? (
          <NotRatedTitle id="not_rated">{t(T.common_ratings_notRated)}</NotRatedTitle>
        ) : (
          <>
            <RatingLabel>{starRating}</RatingLabel>
            <StarContainer>
              {renderStars(starRating)}
              <ReviewLabel style={{ paddingBottom: 10 }}>
                {reviewCount > 1
                  ? t(T.common_ratings_basedOnReviews, { count: reviewCount })
                  : t(T.common_ratings_basedOnReview)}
              </ReviewLabel>
            </StarContainer>
          </>
        )}
      </div>
    </RatingContainer>
  );
};

export const CarrierSourceInfoButton: React.FC<{ carrierSourceUrl: string }> = ({ carrierSourceUrl }) => {
  const popupContent = usePopup();

  const displayInfoPopup = () => {
    popupContent.openingPopup({
      body: <InfoPopup openCarrierSource={() => window.open(carrierSourceUrl, '_blank')} />,
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

  return (
    <MultipurposeButton
      id="more_details"
      variant="contained"
      text={t(T.common_ratings_moreDetails)}
      color="primary"
      onClick={displayInfoPopup}
      style={{ maxWidth: 160, width: 160, maxHeight: 44 }}
    />
  );
};

export const CarrierRatingDetails: React.FC<{ safetyRating: string; carrierOperation: string }> = ({
  safetyRating,
  carrierOperation,
}) => (
  <RatingDetailContainer>
    <RatingDetailRow>
      <RatingTypeLabel>{t(T.common_ratings_safetyRating)}</RatingTypeLabel>
      <RatingLabelBold>{capitalize(safetyRating)}</RatingLabelBold>
    </RatingDetailRow>
    <RatingDetailRow style={{ paddingTop: 10 }}>
      <RatingTypeLabel>{t(T.common_ratings_carrierOperation)}</RatingTypeLabel>
      <RatingLabelBold> {capitalize(carrierOperation)}</RatingLabelBold>
    </RatingDetailRow>
  </RatingDetailContainer>
);

export const CarrierSourceLogo = () => (
  <LogoContainer>
    <ReviewLabel>{t(T.common_ratings_poweredBy)}</ReviewLabel>
    <img style={{ marginLeft: 5 }} src={CarrierSourceIcon} alt="carrier-source-logo" />
  </LogoContainer>
);

const InfoPopup: React.FC<{ openCarrierSource: () => void }> = ({ openCarrierSource }) => {
  return (
    <BasePopup title={t(T.common_ratings_popupTitle)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img
          style={{ alignSelf: 'center', width: 200, paddingBottom: 25 }}
          src={CarrierSourceIcon}
          alt="carrier-source-logo"
        />
        <Text id="info" textStyle={TextStyle.TextContentDetail} style={{ marginBottom: 10, display: 'block' }}>
          {t(T.common_ratings_popupInfo)}
        </Text>
        <Text id="more" textStyle={TextStyle.TextContentDetail}>
          {t(T.common_ratings_moreInfo)}
        </Text>
        <MultipurposeButton
          id="proceed"
          variant="contained"
          text={t(T.common_ratings_proceed)}
          color="primary"
          onClick={openCarrierSource}
          style={{ marginTop: 25 }}
        />
      </div>
    </BasePopup>
  );
};

const renderStars = (rating: number) => {
  // This function renders stars based on a rating,
  // For example for a 3.4 star rating it will show:
  // - 3 full stars of 22px width
  // - A "partial" star of 9px width (i.e. ~0.4 stars)
  // note: lower bound for fractional rating is 0.4 (UI Enhancement)

  const fullStars = map(Array.from({ length: floor(rating) }), (_, index) => (
    <img key={index} height={21} width={22} src={StarIcon} alt="star" style={index > 0 ? { paddingLeft: 5 } : {}} />
  ));
  const remaningRating = rating - floor(rating);
  const partialStar =
    remaningRating > 0 ? (
      <div style={{ width: 22 * clamp(remaningRating, 0.4, 0.9), overflow: 'hidden', paddingLeft: 5 }}>
        <img height={21} width={22} src={StarIcon} alt="star" />
      </div>
    ) : null;

  return (
    <div style={{ display: 'flex', marginBottom: 4 }}>
      {fullStars}
      {partialStar}
    </div>
  );
};

import React from 'react';

import { useHistory } from 'react-router-dom';

import { PageWithPanels, Panel, PanelHeader, PanelSize } from '@component/panel';

import { AspxEmbedIframe } from './AspxEmbedIframe';

interface Props {
  iframeSrc: string;
  newSettings: boolean;
  label?: string;
  onBack?: () => void;
}

export const AspxPage: React.FC<Props> = (props) => {
  const history = useHistory();
  const onBack = () => {
    if (props.onBack) {
      props.onBack();
    } else {
      history.goBack();
    }
  };
  return (
    <PageWithPanels>
      <Panel id="aspx" offset={0} layer={0} size={PanelSize.full} contentHeightFill={true}>
        {props.newSettings ? (
          <PanelHeader style={{ padding: '0 6px' }} label={props.label} hasBackButton={true} onClose={onBack} />
        ) : null}
        <AspxEmbedIframe src={props.iframeSrc} />
      </Panel>
    </PageWithPanels>
  );
};

import React from 'react';

import { useDispatch } from 'react-redux';

import { default as Logo } from '@/component/popup/logo-mycarrierportal.png';
import { sendOnboardingRequest } from '@common/redux/epic/MyCarrierPortalEpic';
import { useDidUpdate } from '@common/util/hooks';
import { MainButton } from '@component/buttons';
import { BasePopup } from '@component/popup/BasePopup';
import { MCPPopupBody } from '@component/popup/myCarrierPortal/SetupIntegrationInfoPopup';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

interface Props {
  contactGuid: string;
}

export const MCPOnboardingPopUp = ({ contactGuid }: Props) => {
  const { closingPopup } = usePopup();
  const dispatch = useDispatch();
  const isSendingRequest = useSelector((state) => state.myCarrierPortal.onboarding.isSendingRequest);
  const didSendRequest = useSelector((state) => state.myCarrierPortal.onboarding.isOnboardingRequestSent);

  useDidUpdate(() => {
    if (didSendRequest) {
      closingPopup();
    }
  }, [didSendRequest]);

  const sendOnboardRequest = () => {
    dispatch(sendOnboardingRequest(contactGuid));
  };

  return (
    <BasePopup title={t(T.myCarrierPortal_onboarding_onboard)} onClose={closingPopup} withoutContentPadding={true}>
      <MCPPopupBody>
        <div className="container-padding">
          <img src={Logo} style={{ width: 260 }} />
          <p style={{ color: Color.GRAY_DARK }}>{t(T.myCarrierPortal_onboarding_description)}</p>
          <p style={{ color: Color.GRAY_DARK }}>{t(T.myCarrierPortal_onboarding_description2)}</p>
          <MainButton
            id="result"
            title={t(T.myCarrierPortal_onboarding_send)}
            onClick={sendOnboardRequest}
            isLoading={isSendingRequest}
            isDisabled={isSendingRequest}
            style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
          />
        </div>
      </MCPPopupBody>
    </BasePopup>
  );
};

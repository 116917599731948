import React from 'react';

import styled from 'styled-components';

import { SearchEquipmentList } from '@common/helper/SearchHelper';
import { EquipmentType } from '@common/model';
import { RadioGroup } from '@component/control';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { Color } from '@style/Color';
import { FontSize, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

const SectionTitle = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
  padding: ${Spacing.ScreenSide}px;
`;

const RadioGroupSection = styled.div`
  padding-bottom: ${Spacing.InterElementVertical}px;
`;

interface Props {
  layer: number;
  offset: number;
  backRedirect: () => void;
  updateEquipment: (newType: EquipmentType) => void;
  initialEquipmentTypes?: EquipmentType;
}

export const RadioGroupTrailerTypePanel: React.FC<Props> = (props) => {
  const handleCheck = (response: EquipmentType) => {
    props.updateEquipment(response);
    props.backRedirect();
  };

  return (
    <Panel
      id="trailer_type"
      layer={props.layer}
      offset={props.offset}
      size={PanelSize.small}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader label={t(T.findLoads_findLoads_trailerType)} hasBackButton={true} onClose={props.backRedirect} />
      <SectionTitle id="post_truck_info">{t(T.modalPanels_TrailerType_Header)}</SectionTitle>
      <RadioGroupSection>
        <RadioGroup options={SearchEquipmentList} value={props.initialEquipmentTypes} handleChange={handleCheck} />
      </RadioGroupSection>
    </Panel>
  );
};

import { Location } from 'history';

import { StoreState } from '@/reduxStore/Store';
import { findMatch } from '@common/helper';
import { AppConfig } from '@util/AppConfig';

import { aspxLinks } from './AspxRouteMap';

/**  Map React URL to ASPX Embed Url
 */
export const mapReactUrlToAspxUrl = (reactUrl: Location, state: StoreState) =>
  findMatch(aspxLinks, (route) => route.reactToAspxMatch(reactUrl, state));

/**  Map ASPX Embed URL to React Url
 */
export const mapAspxUrlToReactUrl = (aspxUrl: string) => {
  const embedAspxUrl = new URL(aspxUrl, AppConfig.LBConfig.ASPEmbedRoot);

  const matchedRoute = findMatch(aspxLinks, (route) => route.aspxToReactMatch(embedAspxUrl));

  if (matchedRoute === undefined) {
    throw new Error(`Bug: non-matching ASPX route. AspxUrl: ${aspxUrl}`);
  }

  return matchedRoute;
};

import { findIndex, forEach, map } from 'lodash';

export interface NormalizedListState {
  ids: string[];
  isLoading: boolean;
  didFailToLoad: boolean;
  isLastPage: boolean;
  nextToken?: string;
}

export enum FetchStatus {
  Loading,
  Failed,
}

export const clearUnreferencedEntities = (referencedIds: string[], entities: Map<string, any>) => {
  for (const key of entities.keys()) {
    if (findIndex(referencedIds, (id) => id === key) === -1) {
      entities.delete(key);
    }
  }
  return entities;
};

export const updateIdsInNormalizedStates = (
  oldId: string,
  newId: string,
  ...normalizedStates: NormalizedListState[]
) => {
  forEach(normalizedStates, (normalizedState) => {
    normalizedState.ids = map(normalizedState.ids, (id) => {
      if (id === oldId) {
        return newId;
      }
      return id;
    });
  });
};

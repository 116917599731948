import React, { PropsWithChildren, useEffect } from 'react';

import { replace } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Link from '@material-ui/core/Link';

import { BorderWidth, FontSize, Spacing } from '@/style/StyleConstants';
import { getMCLink, getUSDOTLink } from '@common/info';
import { BidSummary } from '@common/model';
import { getBidCarrierInfo } from '@common/redux/epic/bids/BidsEpic';
import { VerificationBadge } from '@component/badge/VerificationBadge';
import { PhoneNumber } from '@component/contact';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { Text, TextStyle } from '@component/text';
import { CompanyProfileLogo } from '@page/truckLocator/locateTruck/CompaniesProfileAvatarLogo';
import { Color } from '@style/Color';
import { T, t } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';
import { useSelector } from '@util/hooks';

import { BidLoadInfoBanner } from '../BidCards';

const OverlayContainer = styled.div`
  background-color: ${Color.BLUE_AZURE};
  border-bottom: ${BorderWidth.Medium}px solid ${Color.BLUE_SKY};
  margin-top: ${Spacing.InterSection}px;
  margin-bottom: ${Spacing.InterSection}px;
`;

const OverlayContent = styled.div`
  margin: 0px ${Spacing.ScreenSide}px ${Spacing.ElementPaddingVertical}px ${Spacing.ScreenSide}px;
  font-size: ${FontSize.Content}px;
`;

export const BidInfoRibbonSummary = (props: PropsWithChildren<{}>) => {
  return (
    <OverlayContainer>
      <OverlayContent>{props.children}</OverlayContent>
    </OverlayContainer>
  );
};

export const BidInfoRibbon: React.FC<{
  bid: BidSummary | undefined;
  isViewingOffer?: boolean;
}> = (props) => {
  const isCarrier = useIsCarrier();

  const getRibbonContent = () => {
    if (!props.bid) {
      return null;
    }

    if (!isCarrier && props.isViewingOffer) {
      return (
        <OtherPartyContactInfo
          bidId={props.bid.bidId || ''}
          contactId={props.bid.otherParty?.contactId || ''}
          companyId={props.bid.otherParty?.company.id || ''}
        />
      );
    } else {
      return (
        <BidLoadInfoBanner
          rateAmount={props.bid.load?.rate?.amount}
          rateType={props.bid.load?.rate?.type}
          style={{ border: `1px solid ${Color.GRAY_SMOKE}` }}
          load={props.bid?.load}
        />
      );
    }
  };

  const content = getRibbonContent();

  return <BidInfoRibbonSummary>{content}</BidInfoRibbonSummary>;
};

const AvatarAndContactInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const OverlayContentTextContainer = styled.div`
  margin-bottom: 0px;
`;

const InfoBoldText = styled(Text)`
  font-size: ${FontSize.ContentDetail};
  font-weight: bold;
`;

const LabelText = styled(Text)`
  font-size: ${FontSize.ContentDetail};
`;
const OtherPartyContactInfo: React.FC<{ bidId: string; contactId: string; companyId: string }> = (props) => {
  const carrierInfo = useSelector((state) => state.bids.bidsCarrierInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBidCarrierInfo(props.bidId));
  }, [props.bidId]);

  const getCarrierName = () => {
    return carrierInfo.firstName && carrierInfo.lastName
      ? `${carrierInfo?.firstName} ${carrierInfo?.lastName}`
      : NO_INFORMATION_SYMBOL;
  };

  const replaceMCSuffix = (mcNumber: string | undefined): string | undefined => {
    if (mcNumber === undefined) {
      return undefined;
    }
    return replace(mcNumber, 'MC', '');
  };

  const mcNumber = replaceMCSuffix(carrierInfo?.docketNumber);

  return (
    <AvatarAndContactInfoContainer>
      <CompanyProfileLogo contactId={props.contactId} shouldUsePlaceholderIcon={true} />

      <OverlayContent>
        {carrierInfo.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <OverlayContentTextContainer>
              <InfoBoldText id="name_contact">{getCarrierName()}</InfoBoldText>
            </OverlayContentTextContainer>

            <OverlayContentTextContainer style={{ marginBottom: '6px' }}>
              <LabelText id="phone">{`${t(T.common_phone)}: `}</LabelText>
              {carrierInfo.phone ? (
                <PhoneNumber
                  id="contact_phone"
                  phoneNumberValue={carrierInfo?.phone}
                  phoneNumberString={carrierInfo?.phone}
                  key={t(T.contactSupportPhone)}
                  textStyle={TextStyle.BigLinkBold}
                />
              ) : (
                NO_INFORMATION_SYMBOL
              )}
            </OverlayContentTextContainer>

            <OverlayContentTextContainer>
              <InfoBoldText id="company_name">{carrierInfo?.companyName ?? NO_INFORMATION_SYMBOL}</InfoBoldText>
            </OverlayContentTextContainer>

            <OverlayContentTextContainer>
              <LabelText id="dot_number">{`${t(T.common_load_DOT_number)}: `}</LabelText>
              {carrierInfo?.usdotNumber ? (
                <InfoBoldText id="dot_number_link">
                  <Link href={getUSDOTLink(carrierInfo?.usdotNumber)} color={'secondary'} target={'_blank'}>
                    {carrierInfo?.usdotNumber}
                  </Link>
                  {carrierInfo.isCarrierIdentityVerified ? (
                    <VerificationBadge
                      testID="bid_summary_badge"
                      isVerified={true}
                      style={{ marginTop: '-4px', marginLeft: '2px' }}
                    />
                  ) : null}
                </InfoBoldText>
              ) : (
                NO_INFORMATION_SYMBOL
              )}
            </OverlayContentTextContainer>

            <OverlayContentTextContainer>
              <LabelText id="mc_number">{`${t(T.common_load_MC_number)}: `}</LabelText>
              {mcNumber ? (
                <InfoBoldText id="mc_number_link">
                  <Link href={getMCLink(mcNumber)} color={'secondary'} target={'_blank'}>
                    {mcNumber}
                  </Link>
                </InfoBoldText>
              ) : (
                NO_INFORMATION_SYMBOL
              )}
            </OverlayContentTextContainer>
          </>
        )}
      </OverlayContent>
    </AvatarAndContactInfoContainer>
  );
};

import React from 'react';

import styled from 'styled-components';

import { BasePopup } from '@component/popup/BasePopup';
import { PopupCancelButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupSizes, PopupTracking } from '@component/popup/PopupTrackingContext';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { t, T } from '@translate';

const Subtitle = styled.span`
  font-size: ${FontSize.Content}px;
  color: ${Color.GRAY_DARK};
  white-space: pre-wrap;
`;

const showDialogCreator =
  (title: string, subtitle: string) => (popupContext: PopupTracking, deleteAction: () => void) => {
    const { openingPopup, closingPopup } = popupContext;

    const onDelete = () => {
      deleteAction();
      if (popupContext) {
        popupContext.closingPopup();
      }
    };

    openingPopup({
      body: (
        <BasePopup
          title={title}
          buttons={[
            <PopupCancelButton testID="cancel" key="cancel" action={closingPopup} />,
            <PopupPrimaryButton
              testID="delete"
              key="delete"
              label={t(T.common_myDocuments_documentCapture_deletePopup_title)}
              action={onDelete}
            />,
          ]}
        >
          <Subtitle>{subtitle}</Subtitle>
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

export const showDeleteDocumentPopup = showDialogCreator(
  t(T.common_myDocuments_deleteDialog_title),
  t(T.common_myDocuments_deleteDialog_content)
);
export const showDeleteFolderPopup = showDialogCreator(
  t(T.common_myDocuments_editFolder_deleteDialog_title),
  t(T.common_myDocuments_editFolder_deleteDialog_content)
);

export const showDeleteCustomFolderPopup = showDialogCreator(
  t(T.common_myDocuments_editFolder_deleteDialog_title),
  `${t(T.common_myDocuments_editFolder_deleteDialog_content)}${t(
    T.common_myDocuments_editFolder_deleteDialog_contentFromMyLoaDetails
  )}`
);

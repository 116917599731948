import React from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';
import { FontSize, FontWeight } from '@style/StyleConstants';

interface Props {
  id: string;
  text: string;
  tabIndex?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const StyledButton = styled.button`
  cursor: pointer;
  color: ${Color.ORANGE_MAIN};
  border: 0;
  background: transparent;
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Bold};
  padding: 0;
  outline: none;
  &:focus {
    color: ${Color.ORANGE_MAIN_DARK};
  }
  &:hover {
    color: ${Color.ORANGE_MAIN_DARK};
  }
`;

export const LinkButton: React.FC<Props> = ({ text, id, tabIndex, onClick, style }) => (
  <StyledButton id={id} tabIndex={tabIndex} onClick={onClick} style={style}>
    {text}
  </StyledButton>
);

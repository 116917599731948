import React, { BaseSyntheticEvent, useState } from 'react';

import { slice } from 'lodash';
import styled from 'styled-components';

import { IconButton, ListItemText, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import icons from '@/util/iconsConstants';
import { getStringifiedEquipmentTypeAndName } from '@common/helper/SearchHelper';
import { UserTruck } from '@common/model';
import { Container, SmallMainContainer } from '@component/cards/CardsStyle';
import { DropdownMenu } from '@component/menu/DropdownMenu';
import { Color } from '@style/Color';
import { FontLineHeight, FontSize, Spacing } from '@style/StyleConstants';
import { displayLength, displayWeight } from '@util/TruckDataHelper';

const OrangeCircleChip = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: ${Color.ORANGE_MAIN};
  align-items: center;
  justify-content: center;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const LocationColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${Spacing.ElementPaddingHorizontal}px;
`;

const MeasuresText = styled.div`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
  line-height: ${FontLineHeight.ContentDetail}px;
`;

const NameText = styled.div`
  font-size: ${FontSize.Content}px;
  color: ${Color.BLACK};
  font-weight: 600;
  line-height: ${FontLineHeight.MediumTitle}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
`;

const ChipLetter = styled.div`
  font-size: ${FontSize.Header}px;
  font-weight: 600;
  line-height: ${FontLineHeight.Header}px;
  color: ${Color.WHITE};
`;

interface Props {
  truck: UserTruck;
  onEdit: (truck?: UserTruck) => void;
  onSelect?: (truck?: UserTruck) => void;
  isSelect?: boolean;
}

export const MyTruckCard: React.FC<Props> = ({ truck, onEdit, isSelect, onSelect }) => {
  const truckName = getStringifiedEquipmentTypeAndName(truck.equipmentType, truck.displayName);
  const weightLength = `${displayLength(truck.defaultLength)}, ${displayWeight(truck.defaultWeight)}`;
  const id = truck.id;
  const [menuAnchor, setMenuAnchor] = useState(undefined);

  const handleMenuClick = (event: BaseSyntheticEvent) => {
    setMenuAnchor(event.currentTarget);
  };

  const onClickCard = () => {
    if (isSelect && onSelect) {
      onSelect(truck);
    } else {
      onEdit(truck);
    }
  };

  const onEditTruck = () => {
    onEdit(truck);
    setMenuAnchor(undefined);
  };
  const onSelectTRuck = () => {
    if (onSelect) {
      onSelect(truck);
    }
  };

  return (
    <>
      <Container id={`my_truck_card_${id}`} style={{ paddingBottom: '2px' }}>
        <SmallMainContainer>
          <LocationContainer onClick={() => onClickCard()} style={{ cursor: 'pointer' }}>
            <OrangeCircleChip>
              <ChipLetter id={`my_truck_letter_${id}`}>{slice(truckName, 0, 1)}</ChipLetter>
            </OrangeCircleChip>
            <LocationColumn>
              <NameText id={`name_${id}`}>{truckName}</NameText>
              <MeasuresText id={`weight_${id}`}>{weightLength}</MeasuresText>
            </LocationColumn>
          </LocationContainer>
          {isSelect ? (
            <div style={{ justifySelf: 'flex-end', alignSelf: 'center' }}>
              <IconButton id="menu" key="menu" color="inherit" onClick={handleMenuClick}>
                <MoreVert aria-controls="customized-menu" aria-haspopup="true" />
              </IconButton>
              <DropdownMenu
                anchor={menuAnchor}
                onClose={() => {
                  setMenuAnchor(undefined);
                }}
              >
                {getMenuOptions(onEditTruck, onSelectTRuck)}
              </DropdownMenu>
            </div>
          ) : (
            <div style={{ display: 'flex' }} onClick={() => onClickCard()}>
              <img id={`arrow_right_${id}`} src={icons.arrowRight} alt={'arrow'} style={{ width: '100%' }} />
            </div>
          )}
        </SmallMainContainer>
      </Container>
    </>
  );
};

const getMenuOptions = (onEdit: () => void, onSelect: () => void) => {
  return [
    <MenuItem id="onboard_option" key={'onboard_option'} onClick={onSelect}>
      <ListItemText id="title" primary={'Select'} />
    </MenuItem>,
    <MenuItem id="block_option" key={'block_option'} onClick={onEdit}>
      <ListItemText id="title" primary={'Edit'} />
    </MenuItem>,
  ];
};

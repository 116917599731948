import { MediaQueries } from '@util/MediaQueries';

import { useMediaQuery } from './MediaQueryHook';

/** This hook returns true if currently showing the mobile view.
 * Mobile View is showing 1 panel always and this will control if we
 * should be scrolling the whole page or not. It is expected that
 * mobile views shows the bottom bars. (iPads do not)
 */
export const useIsMobileView = () => useMediaQuery([MediaQueries.lt_mobile], [true], false);
export const useIsSmallerThanMediumDesktopView = () => useMediaQuery([MediaQueries.lt_md], [true], false);
export const useIsSmallerThanMediumLargeDesktopView = () => useMediaQuery([MediaQueries.lt_md_lg], [true], false);
export const useIsiPhone5Series = () => useMediaQuery([MediaQueries.xxs], [true], false);
export const useIsiPadView = () => useMediaQuery([MediaQueries.md_ipad], [true], false);

import { AxiosRequestConfig } from 'axios';
import { toLower } from 'lodash';

import { ApiResponse123 } from '@common/api';

const FLOW_ID_HEADER = '123LB-FD';

export const addArchivingHeader = (
  archivingID: string | undefined,
  config?: AxiosRequestConfig
): AxiosRequestConfig | undefined => ({
  ...config,
  headers: { ...config?.headers, [FLOW_ID_HEADER]: archivingID || '' },
});

export const getFlowIDFrom = (response: ApiResponse123<any>) => {
  if (response.headers) {
    let flowIDHeader: string | undefined = response.headers[FLOW_ID_HEADER];
    if (!flowIDHeader) {
      flowIDHeader = response.headers[toLower(FLOW_ID_HEADER)];
    }
    return flowIDHeader;
  }
  return undefined;
};

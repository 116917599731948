import React, { Component } from 'react';

import { map } from 'lodash';
import { GetHandleProps, Handles, Rail, SliderItem, Tracks } from 'react-compound-slider';

import {
  CustomSliderWrapper,
  MainWrapper,
  RailHandle,
  RailHandleWithCustomSVG,
  RailTrack,
  SliderRail,
} from './CustomSliderStyles';

interface ComponentState {
  values: number[];
  domain: number[];
  step: number;
  disabled?: boolean;
  mode?: number;
}

interface ComponentProps {
  id: string;
  values: number[];
  domain: number[];
  step?: number;
  disabled?: boolean;
  onChange?: (values: number[]) => void;
  onUpdate?: (values: number[]) => void;
  mode?: number;
  customHandleComponent?: JSX.Element;
}

const Track = (props: any) => <RailTrack {...props} />;

export class CustomSlider extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      values: this.props.values,
      domain: this.props.domain,
      step: this.props.step ? this.props.step : 1,
      disabled: this.props.disabled,
      mode: this.props.mode ?? 2,
    };
  }

  static getDerivedStateFromProps(props: ComponentProps) {
    return {
      values: props.values,
      step: props.step,
      domain: props.domain,
    };
  }

  handleUpdate = (values: number[]) => {
    if (this.props.onUpdate) {
      this.props.onUpdate(values);
    }
  };

  handleChange = (values: number[]) => {
    if (this.props.onChange) {
      this.props.onChange(values);
    }
  };

  render() {
    return (
      <MainWrapper id={`${this.props.id}_slider`}>
        <CustomSliderWrapper
          onUpdate={this.handleUpdate}
          onChange={this.handleChange}
          mode={this.state.mode}
          values={this.state.values}
          domain={this.state.domain}
          step={this.state.step}
          disabled={this.props.disabled}
        >
          <Rail>{({ getRailProps }) => <SliderRail {...getRailProps()} />}</Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {map(handles, (handle) => (
                  <Handle
                    key={handle.id}
                    id={`handle_${handle.id}`}
                    handle={handle}
                    getHandleProps={getHandleProps}
                    disabled={this.props.disabled}
                    customHandleComponent={this.props.customHandleComponent}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false} left={this.state.values.length === 1}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {map(tracks, ({ id, source, target }) => (
                  <Track {...getTrackProps()} key={id} source={source} target={target} disabled={this.props.disabled} />
                ))}
              </div>
            )}
          </Tracks>
        </CustomSliderWrapper>
      </MainWrapper>
    );
  }
}

interface HandleProps {
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  id: string;
  disabled?: boolean;
  customHandleComponent?: JSX.Element;
}

const Handle = (props: HandleProps) => {
  const componentProps = {
    id: props.id,
    disabled: !!props.disabled,
    handle: props.handle,
    ...props.getHandleProps(props.handle.id),
  };

  if (props.customHandleComponent) {
    return <RailHandleWithCustomSVG {...componentProps}>{props.customHandleComponent}</RailHandleWithCustomSVG>;
  }
  return <RailHandle {...componentProps} />;
};

import React from 'react';

import styled from 'styled-components';

import { MENU_HEADER_HEIGHT } from '@component/menu/menuStyles';
import { Color } from '@style/Color';
import { MENU_TOP_BAR_ZINDEX } from '@style/StyleConstants';

export const DESKTOP_BORDER_WIDTH = 5;

const FRAME_ZINDEX = MENU_TOP_BAR_ZINDEX;
const BORDER_RADIUS = 17;
const BORDER_RADIUS_XTRA = BORDER_RADIUS + 1;

// NOTE: Small (1px) negative margin/position values is to avoid a (sporadic) tiny white visual "gap"
// between the bar, the corners and the side menu
const TopBar = styled.div`
  position: relative;
  left: 0;
  right: 0;
  margin-left: -1px;
  margin-right: -1px;
  height: ${MENU_HEADER_HEIGHT}px;
  background-color: ${Color.MENU_BLACK};
  z-index: ${FRAME_ZINDEX};
`;

const ThinRightBorder = styled.div`
  pointer-events: none;
  margin-right: ${DESKTOP_BORDER_WIDTH}px;
  z-index: ${FRAME_ZINDEX};
  position: fixed;
  height: 100vh;
  top: 0px;
  right: ${-DESKTOP_BORDER_WIDTH}px;
  width: ${DESKTOP_BORDER_WIDTH}px;
  background-color: ${Color.MENU_BLACK};
`;

const TopLeftCornerSvg = styled.svg`
  height: ${BORDER_RADIUS_XTRA}px;
  width: ${BORDER_RADIUS_XTRA}px;
  position: absolute;
  left: 0px;
  top: ${MENU_HEADER_HEIGHT - 1}px;
  z-index: ${FRAME_ZINDEX};
  pointer-events: none;
`;

const TopRightCornerSvg = styled.svg`
  height: ${BORDER_RADIUS_XTRA}px;
  width: ${BORDER_RADIUS_XTRA}px;
  position: absolute;
  right: 0px;
  top: ${MENU_HEADER_HEIGHT - 1}px;
  z-index: ${FRAME_ZINDEX};
  pointer-events: none;
`;

/** Renders a non-scrollable visual "frame" floating on top and on the right of the content for the "desktop mode" along
 * with overlapping rounded corner at the top left & top right (with pointer-event=none)
 * children are meant for extra topRightComponents
 */
export const DesktopThinBlackFrame: React.FC = ({ children }) => (
  <TopBar>
    <TopLeftCornerSvg>
      <filter id="shadow">
        <feDropShadow dx="2" dy="-2" stdDeviation="2" floodColor={Color.MENU_BLACK} floodOpacity="0.5" />
      </filter>
      <path
        filter="url(#shadow)"
        fill={Color.MENU_BLACK}
        d={`M1,${BORDER_RADIUS_XTRA} a${BORDER_RADIUS},${BORDER_RADIUS} 0 0 1 ${BORDER_RADIUS},-${BORDER_RADIUS} v-1 h-${BORDER_RADIUS_XTRA} v${BORDER_RADIUS_XTRA}z`}
      />
    </TopLeftCornerSvg>
    <TopRightCornerSvg>
      <path
        fill={Color.MENU_BLACK}
        d={`M0 1a${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 1 ${BORDER_RADIUS} ${BORDER_RADIUS} h1 v-${BORDER_RADIUS_XTRA} h-${BORDER_RADIUS_XTRA}z`}
      />
    </TopRightCornerSvg>
    {children}
    <ThinRightBorder />
  </TopBar>
);

import React, { CSSProperties } from 'react';

import { StyledTextChip, StyledTextChipWithoutPadding } from './ChipStyle';

interface Props {
  id: string;
  text: string;
  style?: CSSProperties;
  tabIndex?: number;
}

export const TextChip: React.FC<Props> = ({ id, text, style, tabIndex }) => (
  <StyledTextChip id={id} label={text} style={style} tabIndex={tabIndex} />
);

export const TextChipWithoutPadding: React.FC<Props> = ({ id, text, style, tabIndex }) => (
  <StyledTextChipWithoutPadding id={id} label={text} style={style} tabIndex={tabIndex} />
);

import { forEach } from 'lodash';

export enum FileFormats {
  csv = 'csv',
  xls = 'xls',
  xlsx = 'xlsx',
}

export const verifyFormat = (file: File, formats: string[]) => {
  const data = file.name.split('.');
  let isFound = false;
  forEach(formats, (format: string) => {
    const formatData = format.split('.');
    if (data.length > 1 && formatData.length > 1) {
      // Grab the last extension in the file name
      if (data[data.length - 1] === formatData[1]) {
        isFound = true;
      }
    }
  });
  return isFound;
};

export const validateFileSize = (file: File) => {
  const nameData = file.name.split('.');
  const extension = nameData[nameData.length - 1];
  if (extension !== FileFormats.csv && extension !== FileFormats.xls && extension !== FileFormats.xlsx) {
    return true;
  }
  const fileSizeLimit = 4194304;
  const filesize = file.size;
  if (filesize <= 0 || filesize > fileSizeLimit) {
    return false;
  }
  return true;
};

export const downloadBase64File = (fileBase64: string, fileName: string, extension: string) => {
  const link = document.createElement('a');
  link.download = `${fileName}.${extension}`;
  link.href = `data:application/octet-stream;base64, ${fileBase64}`;
  link.click();
};

export const objectUrlFromBase64 = (fileBase64: string, contentType = 'application/pdf') => {
  const byteCharacters = atob(fileBase64);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: contentType });
  return URL.createObjectURL(blob);
};

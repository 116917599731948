import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getUiSettings, patchUiSettings } from '@/reduxStore/epic/MileageCalculatorEpic';
import {
  fetchExportReport,
  fetchRouteDirections,
  fetchStateMileage,
  postRoutePath,
} from '@/reduxStore/epic/PCMilerEpic';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { isWithManualPostedExtraStops } from '@common/helper';
import { convertLoadToMileageRoutesPostRequest, getSummaryRoadInfo } from '@common/helper/PCMilerHelper';
import { Load, MileageRoutesPostRequest } from '@common/model';
import { fetchLoadDetails } from '@common/redux/epic/LoadDetailsEpic';
import { LoadSearchType } from '@common/redux/epic/loadSearch';
import { ExpansionPanel } from '@component/expansionPanel/ExpansionPanel';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { BaseMap } from '@component/map';
import { default as MileageIcon } from '@component/panels/basePanel/loadDetails/mileage.svg';
import { default as PCMilerIcon } from '@component/panels/basePanel/loadDetails/pc miler.svg';
import { DirectionsList } from '@component/panels/mileageCalculator/DirectionList';
import { IftaFuelTax } from '@component/panels/mileageCalculator/IftaFuelTax';
import { GoogleMapsLink, RouteSummary } from '@component/panels/mileageCalculator/MapRoutingTollSections';
import { getOneDPoints, getStopsPoints } from '@component/panels/mileageCalculator/MileageCalculatorHelper';
import { createBorder, ExpansionPanelSection } from '@component/panels/mileageCalculator/MileageCalculatorStyleSheet';
import { THEME } from '@style/Theme';
import { t, T } from '@translate';
import { NO_INFORMATION_SYMBOL } from '@util/Constants';
import { usePrevious, useSelector } from '@util/hooks';

const emptyMileageRoutePostRequest: MileageRoutesPostRequest = {
  stops: [],
};

interface ComponentProps {
  loadDetails?: Load;
  fetchLoadDetails?: (loadId: string) => void;
  loadId?: string;
}

type Props = ComponentProps & RouteComponentProps<{ loadID: string }>;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  background: ${THEME.palette.background.paper};
  color: ${THEME.palette.text.primary};
  border-bottom: ${createBorder()};
  @media only screen and (max-width: 700px) {
    && {
      flex-direction: column;
    }
  }
`;

const PcMilerExpansionPanels = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${THEME.palette.background.paper};
  color: ${THEME.palette.text.primary};
`;

const PCMilerComponent: React.FC<Props> = (props) => {
  const [isIftaOpened, setIsIftaOpened] = useState(false);
  const [isRoutingOpened, setIsRoutingOpened] = useState(false);
  const route = useSelector((state) => state.pcmiler.route?.payload);
  const isLoadingRoute = useSelector((state) => state.pcmiler.isLoadingRoute);
  const isLoading = useSelector((state) => state.pcmiler.isLoading);
  const stateMileagePayload = useSelector((state) => state.pcmiler.stateMileagePayload);
  const isReportDownloading = useSelector((state) => state.pcmiler.isDownloadingReport);
  const isReportDownloaded = useSelector((state) => state.pcmiler.isReportDownloaded);
  const csvData = useSelector((state) => state.pcmiler.csvData);
  const previousIsLoading = usePrevious(isLoading);
  const directions = useSelector((state) => state.pcmiler.directions);
  const load = useSelector((state) => state.loadDetails.currentLoad);
  const uiSettings = useSelector((state) => state.mileageLocationState.uiSettings);
  const currentLoad = props.loadDetails ?? load;
  const dispatch = useDispatch();
  const routePath = route?.geometry.coordinates;
  const { mileage, tollCost } = getSummaryRoadInfo(currentLoad, directions);
  const routesPostRequest =
    currentLoad !== undefined ? convertLoadToMileageRoutesPostRequest(currentLoad) : emptyMileageRoutePostRequest;
  const isWithManualPostedStops = isWithManualPostedExtraStops(currentLoad?.extraStops, currentLoad?.numberOfStops);
  useEffect(() => {
    dispatch(getUiSettings());
    if (currentLoad === undefined) {
      fetchDetails();
    } else {
      initRoutes();
    }
  }, []);

  useEffect(() => {
    if (uiSettings) {
      setIsIftaOpened(uiSettings.iftaFuelTaxIsExpanded);
    }
  }, [uiSettings]);

  useEffect(() => {
    initRoutes();
    if (currentLoad) {
      dispatch(fetchStateMileage(currentLoad.id));
    }
  }, [currentLoad]);

  useEffect(() => {
    if (previousIsLoading !== isLoading && !isLoading && !directions) {
      dispatch(showSnackbar({ message: t(T.findLoads_loadDetails_routeDirectionsRequestError) }));
    }
  }, [isLoading, directions]);

  const fetchDetails = () => {
    if (props.fetchLoadDetails && props.loadId) {
      props.fetchLoadDetails(props.loadId);
    } else {
      dispatch(fetchLoadDetails(LoadSearchType.LoadSearch, props.match.params.loadID));
    }
  };

  const initRoutes = () => {
    if (currentLoad !== undefined) {
      const routesRequest = isWithManualPostedStops
        ? convertLoadToMileageRoutesPostRequest({ ...currentLoad, extraStops: [] })
        : routesPostRequest;
      dispatch(fetchRouteDirections(routesPostRequest));
      dispatch(postRoutePath({ route: routesRequest }));
    }
  };

  const handleIftaClick = () => {
    setIsIftaOpened(!isIftaOpened);
    if (uiSettings) {
      const newSettings = {
        ...uiSettings,
        iftaFuelTaxIsExpanded: !isIftaOpened,
      };
      dispatch(patchUiSettings(newSettings));
    }
  };

  if (isLoading || isLoadingRoute) {
    return (
      <div id="loading" style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
        <LoadingSpinner />
      </div>
    );
  }

  return directions && routePath && stateMileagePayload ? (
    <>
      <BaseMap
        id={'map'}
        flexGrow={true}
        useOpenStreetMap={false}
        path={getOneDPoints(routePath)}
        stops={getStopsPoints(routePath)}
        fitPath={true}
        style={{ height: '300px' }}
      />
      <StyledRow>
        <RouteSummary
          routePayload={directions}
          mileageData={{
            tollCosts: tollCost,
            rideTime: directions?.rideTime ?? NO_INFORMATION_SYMBOL,
            rideMiles: mileage,
          }}
          load={currentLoad}
        />
        <PcMilerExpansionPanels>
          <ExpansionPanelSection>
            <ExpansionPanel
              id="ifta_calculator_panel"
              handleClick={handleIftaClick}
              isOpened={isIftaOpened}
              titleText={t(T.menu_mileageCalculatorPanel_iftaReport)}
              icon={MileageIcon}
              subtitleText={t(T.menu_mileageCalculatorPanel_stateMileageReport)}
            >
              <IftaFuelTax
                stateMileage={stateMileagePayload}
                wasDownloaded={isReportDownloaded}
                isDownloading={isReportDownloading}
                csvData={csvData}
                getExtraReport={() => dispatch(fetchExportReport(routesPostRequest))}
              />
            </ExpansionPanel>
          </ExpansionPanelSection>
        </PcMilerExpansionPanels>
      </StyledRow>
      <div style={{ marginTop: '6px' }}>
        <ExpansionPanelSection>
          <ExpansionPanel
            id="pc_miler_panel"
            handleClick={() => setIsRoutingOpened(!isRoutingOpened)}
            isOpened={isRoutingOpened}
            titleText={t(T.findLoads_loadDetails_pcMiler)}
            icon={PCMilerIcon}
            subtitleText={t(T.menu_mileageCalculatorPanel_routeInfo)}
          >
            <DirectionsList directions={directions} shouldShowStops={!isWithManualPostedStops} />
          </ExpansionPanel>
        </ExpansionPanelSection>
        <GoogleMapsLink route={directions} shouldShowStops={!isWithManualPostedStops} />
      </div>
    </>
  ) : null;
};

export const PCMilerRoute = withRouter(PCMilerComponent);

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import { clearVideos, fetchMappingFile, fetchVideoList, setSelectedFeedSection } from '@/reduxStore/epic/FeedEpic';
import { getFeedMappingDataForUrl } from '@component/feed/FeedMappingHelper';
import { ColorType, FeedPopupButtons } from '@component/feed/FeedPopupButtons';
import { useCsv, useSelector } from '@util/hooks';

const FeedButtonsHolder = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: inherit;
`;

const FeedControllerComponent: React.FC<RouteComponentProps> = ({ location }) => {
  const mappingFile = useSelector((state) => state.feedState.mappingFile);
  const csvData = useCsv(mappingFile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedFeedSection(undefined));
    dispatch(fetchMappingFile());
  }, [location.pathname]);

  useEffect(() => {
    const data = getFeedMappingDataForUrl(csvData, location.pathname);
    if (data?.videoTabSourceRoute) {
      dispatch(fetchVideoList(data?.videoTabSourceRoute));
    } else {
      dispatch(clearVideos());
    }
  }, [csvData, location.pathname]);

  return (
    <FeedButtonsHolder>
      <FeedPopupButtons colorType={ColorType.Dark} isWithBorder={false} isWithButtonsAnimation={true} />
    </FeedButtonsHolder>
  );
};

export const FeedController = withRouter(FeedControllerComponent);

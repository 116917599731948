import { defaultTo, Dictionary, join, map, mapValues, pickBy, reduce, uniqBy } from 'lodash';
import moment from 'moment';

import {
  formatMomentYYYYMMDD,
  getTodayDateYYYYMMDD,
  MILLISECONDS_IN_ONE_DAY,
  parseAPIDateWithoutTime,
} from '@common/helper/DateHelper';
import { addSuffix, dashIfUndefined } from '@common/helper/Functions';
import { safeFormatPrice } from '@common/helper/NumberHelper';
import {
  Address,
  LoadRateCheckHistoryRequest,
  PartialRateCheckFeedback,
  PayRateUnit,
  RateCheck,
  RateCheckExpansionLevel,
  RateCheckFeedback,
  RateCheckFeedbackCollection,
  RateCheckFeedbackRequest,
  RateCheckHistoryRequest,
  RateCheckRequest,
  RateCheckRevenueData,
  RateCheckRevenueResponse,
  RateCheckTimeSpan,
} from '@common/model';
import { t, T } from '@translate';

export const createFeedback = (feedback: PartialRateCheckFeedback, response: RateCheck): RateCheckFeedbackRequest => {
  return {
    request: typeof feedback.request === 'string' ? undefined : feedback.request,
    amountType: feedback.expectedRate ? PayRateUnit.PerMile : undefined,
    expectedRate: feedback.expectedRate,
    userAccurate: feedback.isRateCheckAccurate,
    response: response,
  };
};

export const serializeRequest = (request: RateCheckRequest) =>
  `${stringifyAddress(request.from)}-${stringifyAddress(request.to)}-${request.equipment}`;

const defaultToEmpty = (str: string): string => defaultTo(str, '');

const stringifyAddress = ({ city, state, addressLine1, addressLine2, country, geolocation, zipCode }: Address) =>
  join(map([city, state, addressLine1, addressLine2, country, geolocation, zipCode], defaultToEmpty), '');

type WithTimeAndSuccess = { timeAdded: number; isSuccess: boolean | undefined };

export const filterOldAndFailed = <T extends WithTimeAndSuccess>(
  feedbackSubmittedFor: Dictionary<T>
): Dictionary<T> => {
  const now = Date.now();
  return pickBy(
    feedbackSubmittedFor,
    (route) => now - route.timeAdded < MILLISECONDS_IN_ONE_DAY && route.isSuccess === true
  );
};

export const updateFeedback = (
  feedbacks: RateCheckFeedbackCollection,
  rateCheckID: string,
  newData: PartialRateCheckFeedback | undefined,
  initialFeedbackState: RateCheckFeedback
): RateCheckFeedbackCollection => {
  if (feedbacks[rateCheckID]) {
    return mapValues(feedbacks, (feedback, rateCheckID) => {
      if (rateCheckID === rateCheckID) {
        return {
          ...feedback,
          ...newData,
        };
      }
      return feedback;
    });
  }
  return {
    ...feedbacks,
    [rateCheckID]: {
      ...initialFeedbackState,
      ...newData,
      timeAdded: Date.now(),
    },
  };
};

export const getRateCheckID = (loadID: undefined | string, request: RateCheckRequest | undefined) => {
  if (loadID) {
    return loadID;
  }
  if (request) {
    return serializeRequest(request);
  }
  return 'unknown';
};

export const createLoadRateCheckHistoryRequest = (): LoadRateCheckHistoryRequest => ({
  expansionLevel: RateCheckExpansionLevel.CityToCity,
  startDate: formatMomentYYYYMMDD(moment().subtract(1, 'year')),
  endDate: getTodayDateYYYYMMDD(),
  timeSpan: RateCheckTimeSpan.Monthly,
});

const createLoadRateCheckHistoricalRequest = (yearsAgo: number = 1): LoadRateCheckHistoryRequest => ({
  expansionLevel: RateCheckExpansionLevel.CityToCity,
  startDate: formatMomentYYYYMMDD(moment().subtract(yearsAgo, 'year').startOf('year')),
  endDate: getTodayDateYYYYMMDD(),
  timeSpan: RateCheckTimeSpan.Monthly,
});

export const createRateCheckHistoryRequest = (request: RateCheckRequest): RateCheckHistoryRequest => {
  const baseRequest = createLoadRateCheckHistoryRequest();
  return createRateHistoryRequestBody(baseRequest, request);
};

export const createRateCheckHistoricalRequest = (request: RateCheckRequest): RateCheckHistoryRequest => {
  const baseRequest = createLoadRateCheckHistoricalRequest(4);
  return createRateHistoryRequestBody(baseRequest, request);
};

const createRateHistoryRequestBody = (baseRequest: LoadRateCheckHistoryRequest, request: RateCheckRequest) => ({
  request: {
    timeSpan: baseRequest.timeSpan,
    expansionLevel: baseRequest.expansionLevel,
    destination: request.to,
    origin: request.from,
    equipment: request.equipment,
    endDate: baseRequest.endDate,
    startDate: baseRequest.startDate,
  },
});

export const parseRateCheckHistory = (
  response: RateCheckRevenueData[],
  rateCheck: RateCheck | undefined
): RateCheckRevenueResponse => {
  const withCurrent: RateCheckRevenueData[] = uniqBy(
    /* Check for uniques since the API sometimes gives duplicate resutls */
    rateCheck && rateCheck.avgRatePerMile && rateCheck.averageRateForMileage
      ? [
          ...response,
          /* We add one more point on the end of the rate check graph with the current avg/min/max */
          {
            avgRatePerMile: rateCheck.avgRatePerMile,
            ratePerMileMax: rateCheck.ratePerMileMax,
            ratePerMileMin: rateCheck.ratePerMileMin,
            averageRateForMileage: rateCheck.averageRateForMileage,
            minRateForMileage: rateCheck.minRateForMileage,
            maxRateForMileage: rateCheck.maxRateForMileage,
            date: formatMomentYYYYMMDD(moment().startOf('month')),
            endDate: formatMomentYYYYMMDD(moment().endOf('month')),
          },
        ]
      : response,
    'date'
  );

  return {
    averageRatePerMileData: map(withCurrent, (data) => ({
      rate: data.avgRatePerMile,
      date: parseAPIDateWithoutTime(data.date).getTime(),
    })),
    maxRatePerMile: reduce(
      withCurrent,
      (max, data) => {
        if (data.ratePerMileMax && data.ratePerMileMax > max) {
          return data.ratePerMileMax;
        } else if (data.avgRatePerMile > max) {
          return data.avgRatePerMile;
        }
        return max;
      },
      0
    ),
    minRatePerMile:
      reduce(
        withCurrent,
        (min, data) => (data.ratePerMileMin && min && data.ratePerMileMin < min ? data.ratePerMileMin : min),
        withCurrent[0].ratePerMileMin
      ) || 0,
    ratePerMileMaxData: map(withCurrent, (data) => ({
      rate: data.ratePerMileMax || data.avgRatePerMile,
      date: parseAPIDateWithoutTime(data.date).getTime(),
    })),
    ratePerMileMinData: map(withCurrent, (data) => ({
      rate: data.ratePerMileMin || data.avgRatePerMile,
      date: parseAPIDateWithoutTime(data.date).getTime(),
    })),
    averageFlatRateForMileageData: map(withCurrent, (data) => ({
      rate: data.averageRateForMileage,
      date: parseAPIDateWithoutTime(data.date).getTime(),
    })),
    maxFlatRateForMileageData: map(withCurrent, (data) => ({
      rate: data.maxRateForMileage || data.averageRateForMileage,
      date: parseAPIDateWithoutTime(data.date).getTime(),
    })),
    minFlatRateForMileageData: map(withCurrent, (data) => ({
      rate: data.minRateForMileage || data.averageRateForMileage,
      date: parseAPIDateWithoutTime(data.date).getTime(),
    })),
    maxFlatRate: reduce(
      withCurrent,
      (max, data) => {
        if (data.maxRateForMileage && data.maxRateForMileage > max) {
          return data.maxRateForMileage;
        } else if (data.averageRateForMileage > max) {
          return data.averageRateForMileage;
        }
        return max;
      },
      0
    ),
  };
};

export const formatDollarsPerMile = (value: number | undefined): string =>
  dashIfUndefined(
    addSuffix(t(T.common_per_miles_short))(value !== undefined ? `$${safeFormatPrice(value)}` : undefined)
  );

export const getInitialAverage = (feedback: RateCheckFeedback) =>
  (feedback.response && feedback.response.avgRatePerMile && safeFormatPrice(feedback.response.avgRatePerMile)) ||
  '0.00';

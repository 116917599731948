import React from 'react';

import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Checkbox from '@material-ui/core/Checkbox';

import { ExpandableSections, setExpandedSection } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { formatNumberWithZeroFormatting } from '@common/helper';
import { DIESEL_PRICE_LINK } from '@common/info';
import {
  PanelDetailsTransparent,
  PanelTitleTransparent,
  PanelWrapperTransparent,
} from '@component/expansionPanel/ExpansionPanelStyle';
import { Cost } from '@component/panels/basePanel/loadDetails/profitCalculator/Cost';
import {
  Field,
  FieldType,
  isAnyFieldEmpty,
  isAnyFieldInvalid,
  isAnyFieldValueRequired,
} from '@component/panels/basePanel/loadDetails/profitCalculator/Field';
import TextInputBox from '@component/panels/basePanel/loadDetails/profitCalculator/TextInputBox';
import {
  FuelDiscount,
  InputWrapper,
  TitleText,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { Color } from '@style/Color';
import { FontFamily, FontWeight, Spacing } from '@style/StyleConstants';
import { withTheme } from '@style/WithTheme';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

import { Calculation } from './Calculation';

interface FuelCostProps {
  milesField: Field;
  mpgField: Field;
  costPerGallonField: Field;
  costPerGallonDiscountField: Field;
  deadheadMilesField: Field;
  isFuelDiscountChecked: boolean;
  isDeadheadMilesChecked: boolean;
  fuelCosts: number;
  fuelCostsForDeadheadMiles: number;
  focusedField: FieldType | undefined;
  onMilesChange: (text: string) => void;
  onMPGChange: (text: string) => void;
  onCostPerGallonChange: (text: string) => void;
  onCostPerGallonDiscountChange: (text: string) => void;
  onDeadheadMilesChange: (text: string) => void;
  onCheckPress: () => void;
  onDeadheadMilesCheckPress: () => void;
  onFocus: (fieldType: FieldType) => () => void;
  onEndEditing: (fieldType: FieldType) => () => void;
}

const PerGal = styled.pre`
  margin: 0;
  font-family: ${FontFamily.Main};
`;

const PerGalLink = styled(Link)`
  color: ${Color.ORANGE_MAIN};
  text-decoration: none;
  font-weight: ${FontWeight.Bold};
  &:hover {
    color: ${Color.ORANGE_MAIN};
  }
`;

const Sign = styled.span`
  margin: 0 10px;
`;

const ExpansionTitleContainer = withTheme()(styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`);

const ExpandIcon = styled.img`
  padding-right: 5px !important;
`;

const CheckBoxBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${Spacing.LargePaddingVertical}px;
`;

const FuelCost: React.FC<FuelCostProps> = (props: FuelCostProps) => {
  const dispatch = useDispatch();
  const isFuelCostExpanded = useSelector(
    (state) => state.applicationSettings.expandedSections[ExpandableSections.ProfitCalculatorFuelCost]
  );

  const isCheckboxDisabled = () => props.costPerGallonField.value() < 0.4;
  const costPerGallonFormattedValue = isEmpty(props.costPerGallonField.normalisedText())
    ? '0'
    : props.costPerGallonField.normalisedText();
  const costPerGallonText = props.isFuelDiscountChecked
    ? `(${costPerGallonFormattedValue} - ${formatNumberWithZeroFormatting(
        props.costPerGallonDiscountField.value(),
        true
      )})`
    : costPerGallonFormattedValue;

  const costPerGallon = (
    <PerGal id="cost_per_gal">
      <PerGalLink id="per_gal_link" to={{ pathname: DIESEL_PRICE_LINK }} target={'blank'}>
        {` ${costPerGallonText} `}
      </PerGalLink>
    </PerGal>
  );

  const milesText = props.isDeadheadMilesChecked
    ? `(${formatNumberWithZeroFormatting(props.milesField.value())} + ${formatNumberWithZeroFormatting(
        props.deadheadMilesField.value()
      )})`
    : formatNumberWithZeroFormatting(props.milesField.value());
  let fuelCalculationText = (
    <div id="estimated_fuel_cost" style={{ display: 'flex' }}>
      <div id="miles_field">{`${milesText} / ${props.mpgField.normalisedText()} *`}</div>
      {costPerGallon}
      <div id="total_fuel_cost">{`= ${formatNumberWithZeroFormatting(props.fuelCosts, true)}`}</div>
    </div>
  );

  let isFuelCostsFieldsValid = true;
  if (isAnyFieldInvalid(props.milesField, props.mpgField, props.costPerGallonField, props.costPerGallonDiscountField)) {
    fuelCalculationText = t(T.findLoads_findLoads_fieldsInvalid);
    isFuelCostsFieldsValid = false;
  }
  if (isAnyFieldEmpty(props.milesField, props.mpgField, props.costPerGallonField)) {
    fuelCalculationText = t(T.findLoads_findLoads_pleaseEnterValues);
  }
  if (isAnyFieldValueRequired(props.milesField, props.mpgField, props.costPerGallonField)) {
    fuelCalculationText = t(T.findLoads_findLoads_fieldsRequiredOrInvalid);
    isFuelCostsFieldsValid = false;
  }

  const deadheadMilesCalculationText = (
    <div id="deadhead_fuel_cost" style={{ display: 'flex' }}>
      <div id="deadhead_miles_field">{`${formatNumberWithZeroFormatting(
        props.deadheadMilesField.value()
      )} / ${props.mpgField.normalisedText()} *`}</div>
      {costPerGallon}
      <div id="total_deadhead_fuel_cost">{`= ${formatNumberWithZeroFormatting(
        props.fuelCostsForDeadheadMiles,
        true
      )}`}</div>
    </div>
  );

  const handleSectionClick = () => {
    dispatch(setExpandedSection(ExpandableSections.ProfitCalculatorFuelCost, !isFuelCostExpanded));
  };

  return (
    <>
      <PanelWrapperTransparent expanded={isFuelCostExpanded}>
        <PanelTitleTransparent onClick={handleSectionClick}>
          <ExpansionTitleContainer>
            <TitleText id="title">{t(T.findLoads_findLoads_Fuel_Costs)}</TitleText>
            <Cost value={props.fuelCosts} />
          </ExpansionTitleContainer>
          <ExpandIcon
            id={`${isFuelCostExpanded ? 'collapse' : 'expand'}`}
            src={isFuelCostExpanded ? icons.collapse : icons.expand}
            alt=">"
          />
        </PanelTitleTransparent>
        <PanelDetailsTransparent>
          <div style={{ width: '100%' }}>
            <Calculation id="calculation_text" isValid={isFuelCostsFieldsValid} text={fuelCalculationText} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <InputWrapper id="miles">
                <TextInputBox
                  field={props.milesField}
                  onChangeText={props.onMilesChange}
                  placeholder={t(T.findLoads_findLoads_Miles)}
                  isInFocus={props.focusedField === FieldType.Miles}
                  onFocus={props.onFocus}
                  onBlur={props.onEndEditing(FieldType.Miles)}
                />
              </InputWrapper>
              <Sign id="division">/</Sign>
              <InputWrapper id="mpg">
                <TextInputBox
                  field={props.mpgField}
                  onChangeText={props.onMPGChange}
                  placeholder={t(T.findLoads_findLoads_MPG)}
                  isInFocus={props.focusedField === FieldType.MPG}
                  onFocus={props.onFocus}
                  onBlur={props.onEndEditing(FieldType.MPG)}
                />
              </InputWrapper>
              <Sign id="multiplication">*</Sign>
              <InputWrapper id="fuel_price">
                <TextInputBox
                  field={props.costPerGallonField}
                  onChangeText={props.onCostPerGallonChange}
                  placeholder={t(T.findLoads_findLoads_perGal)}
                  isInFocus={props.focusedField === FieldType.CostPerGallon}
                  onFocus={props.onFocus}
                  onBlur={props.onEndEditing(FieldType.CostPerGallon)}
                />
              </InputWrapper>
            </div>
            <CheckBoxBar>
              <FuelDiscount
                id="apply_deadhead_miles"
                control={
                  <Checkbox
                    id="checkbox_deadhead_miles"
                    color="primary"
                    onChange={props.onDeadheadMilesCheckPress}
                    checked={props.isDeadheadMilesChecked}
                    style={{ paddingLeft: 0 }}
                  />
                }
                label={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div id="apply_deadhead_miles">{t(T.findLoads_findLoads_applyDeadheadMiles)}</div>
                    <div style={{ display: 'flex' }}>
                      <Calculation
                        id="deadhead_calculation_text"
                        isValid={true}
                        text={deadheadMilesCalculationText}
                        style={{ margin: 0 }}
                      />
                    </div>
                  </div>
                }
              />
              <InputWrapper id="deadhead_miles" style={{ width: '100%', marginTop: Spacing.InterElementVertical }}>
                <TextInputBox
                  field={props.deadheadMilesField}
                  onChangeText={props.onDeadheadMilesChange}
                  placeholder={t(T.findLoads_findLoads_Miles)}
                  isDisabled={!props.isDeadheadMilesChecked}
                  isInFocus={props.focusedField === FieldType.DeadheadMiles}
                  onFocus={props.onFocus}
                  onBlur={props.onEndEditing(FieldType.DeadheadMiles)}
                  style={{ width: '100%' }}
                />
              </InputWrapper>
            </CheckBoxBar>
            <CheckBoxBar>
              <FuelDiscount
                id="apply_fuel_discount"
                control={
                  <Checkbox
                    id="checkbox"
                    value="get fuel"
                    color="primary"
                    onChange={props.onCheckPress}
                    checked={props.isFuelDiscountChecked}
                    disabled={isCheckboxDisabled()}
                    style={{ paddingLeft: 0 }}
                  />
                }
                label={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                      <p id="discount_up_to">{`${t(T.findLoads_findLoads_applyDiscount)} ${t(
                        T.findLoads_findLoads_discountValue
                      )}`}</p>
                    </div>
                    <Link id="get_fuel_card" to={'/tools/save-at-the-pump/'} target={'blank'}>
                      {t(T.findLoads_findLoads_getFuelCard)}
                    </Link>
                  </div>
                }
              />
              <InputWrapper id="discount" style={{ width: '100%', marginTop: Spacing.InterElementVertical }}>
                <TextInputBox
                  field={props.costPerGallonDiscountField}
                  onChangeText={props.onCostPerGallonDiscountChange}
                  placeholder={t(T.findLoads_findLoads_perGal)}
                  isDisabled={!props.isFuelDiscountChecked}
                  isInFocus={props.focusedField === FieldType.CostPerGallonDiscount}
                  onFocus={props.onFocus}
                  onBlur={props.onEndEditing(FieldType.CostPerGallonDiscount)}
                  style={{ width: '100%' }}
                />
              </InputWrapper>
            </CheckBoxBar>
          </div>
        </PanelDetailsTransparent>
      </PanelWrapperTransparent>
    </>
  );
};

export default FuelCost;

import React, { useMemo } from 'react';

import { map } from 'lodash';
import styled from 'styled-components';

import { default as NoConversations } from '@/icons/no_conversations.svg';
import { Conversation } from '@common/model/Conversation';
import { Loader } from '@component/loader';
import { EmptyConversationsPanel } from '@component/panels/communication/EmptyConversationsPanel';
import { NoUnreadMessagesPanel } from '@component/panels/communication/NoUnreadMessagesPanel';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { ConversationList } from '@page/communication/ConversationList';
import { ConversationListViewType } from '@page/communication/ConversationListFilter';
import { LoaderSize } from '@style/StyleConstants';
import { useSelector } from '@util/hooks';

import { LoadConversationsOriginUI } from './LoadConversations';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  conversationId: string | undefined;
  conversations: Conversation[];
  currentFilter: ConversationListViewType | undefined;
  isLoading: boolean;
  hasError: boolean;
  scrollRef: React.RefObject<HTMLInputElement>;
  isLastPage?: boolean;
  handleChange?: (filter: ConversationListViewType) => void;
  loadMore?: () => void;
  originUI: LoadConversationsOriginUI;
}

export const Conversations: React.FC<Props> = (props) => {
  if (props.hasError) {
    return <ErrorPanel />;
  }
  if (props.isLoading) {
    return <CommunicationLoader />;
  }

  if (props.conversations.length <= 0) {
    if (props.currentFilter === ConversationListViewType.Unread) {
      return (
        <NoUnreadMessagesPanel
          onClick={() => {
            props.handleChange?.(ConversationListViewType.All);
          }}
        />
      );
    } else {
      return <EmptyConversationsPanel />;
    }
  }

  return (
    <ConversationList
      originUI={props.originUI}
      conversationId={props.conversationId}
      conversations={props.conversations}
      isLoading={props.isLoading}
      scrollRef={props.scrollRef}
      isLastPage={props.isLastPage}
      loadMore={props.loadMore}
    />
  );
};

const ErrorPanel: React.FC = () => (
  <NotFoundPanel title="Error" imageSection={<img id="image" src={NoConversations} alt="error" />} />
);

const CommunicationLoader: React.FC = () => (
  <LoaderContainer>
    <Loader size={LoaderSize.medium} />
  </LoaderContainer>
);

export const useConversations = (conversationIds: string[]) => {
  const conversationMap = useSelector((state) => state.communication.conversations);
  // biome-ignore lint/style/noNonNullAssertion: Map typing
  return useMemo(() => map(conversationIds, (id) => conversationMap.get(id)!), [conversationIds, conversationMap]);
};

import React from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';

interface Props {
  id: string;
  accent?: boolean;
  children?: React.ReactNode;
  onClick: () => void;
  style?: React.CSSProperties;
  isDisabled?: boolean;
}

const Wrapper = styled.div`
  && {
    font-weight: bold;
    cursor: ${({ isDisabled }: { isDisabled?: boolean }) => (isDisabled ? 'default' : 'pointer')};
  }
`;

const InlineWrapper = styled.div`
  && {
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const TappableTextContent: React.FC<Props> = (props: Props) => {
  const { onClick, isDisabled, accent } = props;
  let color = accent === true ? Color.ORANGE_MAIN : Color.GRAY_DARK;
  if (isDisabled) {
    color = Color.GRAY_STONE;
  }
  return (
    <Wrapper id={props.id} onClick={isDisabled ? undefined : onClick} isDisabled={isDisabled}>
      <div style={{ color: color, ...props.style }}>{props.children}</div>
    </Wrapper>
  );
};

export const InlineTappableText: React.FC<Props> = (props: Props) => {
  const { onClick } = props;
  return (
    <InlineWrapper id={props.id} onClick={onClick}>
      <span style={props.accent === true ? { color: Color.ORANGE_MAIN } : { color: Color.GRAY_DARK }}>
        {props.children}
      </span>
    </InlineWrapper>
  );
};

import React from 'react';

import { CustomButton, CustomButtonProps } from '@component/button';
import { GA, TrackingCategory } from '@util/trackers/GoogleAnalyticsTracker';

interface Props extends CustomButtonProps {
  phoneNumberValue: string;
  analyticsActionSuffix: string;
}

export class CallButton extends React.Component<Props> {
  onPhoneClick = (event: any) => {
    GA.track(
      TrackingCategory.UserAction,
      `Call - ${this.props.analyticsActionSuffix}`,
      `number: ${this.props.phoneNumberValue}`
    );

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const { phoneNumberValue, analyticsActionSuffix, href, onClick, ...buttonProps } = this.props;

    return (
      <CustomButton {...buttonProps} href={'tel:' + this.props.phoneNumberValue} onClick={this.onPhoneClick}>
        {this.props.children}
      </CustomButton>
    );
  }
}

export enum TrackingTag {
  LoadAction = 'LoadActionTracking',
  IOSLocationPermissionPopupAction = 'MobileActionTracking',
  MembershipUpgrade = 'MembershipUpgradeEvent',
  Communication = 'CommunicationTracking',
  VendorTracking = 'VendorTracking',
  MessagingPartnerPortal = 'MessagingPartnerPortal',
  CallCarrier = 'CallCarrier',
  NavigateToPaymentMethod = 'NavigateToPaymentMethod',
}

export enum LoadActionName {
  Booked = 'Booked',
  NotBooked = 'NotBooked',
  Unbooked = 'Unbooked',
  TryAgain = 'WillTryAgain',
  FueldAdvance = 'WantFuelAdvance',
}
export enum PromptTrackingActionName {
  Prompt1Dismiss = 'Prompt1Dismiss',
  Prompt2Dismiss = 'Prompt2Dismiss',
  Prompt3Dismiss = 'Prompt3Dismiss',
  Prompt3DontPrompt = 'Prompt3DontPrompt',
  Prompt3Settings = 'Prompt3Settings',
}

export enum UpgradeSource {
  MoreHeader = 'MoreHeader',
  Settings = 'Settings',
  LoadSearchLimitReached = 'LoadSearchLimitReached',
  LoadViewLimitReached = 'LoadViewLimitReached',
  RateCheckLimitReached = 'RateCheckLimitReached',
  FeatureNotInPlan = 'FeatureNotInPlan',
  LoadViewLimitReachedBanner = 'LoadViewLimitReachedBanner',
}

export enum UpgradeEvent {
  UpgradeNow = 'UpgradeNow',
  Call = 'Call',
  NavigateToList = 'NavigateToList',
  NavigateToPlan = 'NavigateToPlan',
  NavigateToPayment = 'NavigateToPayment',
}

export interface CommonTrackingData {
  ApplicationVersion: string; //version.version.version.buildnumber
  ApplicationOs: 'Android' | 'iOS' | 'Web';
  DeviceId: string;
  ApplicationBuild: number;
  ApplicationOsVersion: string;
}

export interface LoadActionTrackingData {
  LoadUid: string;
  LoadActionName: string;
}

export interface IOSLocationPermissionPopupTrackingData {
  actionName: PromptTrackingActionName;
}

export interface AndroidUpgradeTrackingData {
  upgradeSource?: UpgradeSource;
  eventName: UpgradeEvent | string;
  planGuid?: string;
}

export enum CommunicationEvent {
  MessageOffline = 'MessageOffline',
  CallLoad = 'CallLoad',
}

export interface CommunicationTrackingData {
  eventName: CommunicationEvent;
}

export enum TrackingDataConversationStatus {
  Started = 'Started',
  Empty = 'Empty',
  // it's possible the user may perform a call before conversation
  // messages are fully loaded, or messages failed to load. in these
  // cases, we specify the status is inconclusive
  Inconclusive = 'Inconclusive',
}

export interface CallLoadTrackingData extends CommunicationTrackingData {
  loadID: string;
  conversationID?: string;
  conversationStatus?: TrackingDataConversationStatus;
  // used on MOB
  previousScreen?: string;
  currentScreen?: string;
  // used on MEM
  url?: string;
}

export enum VhubEvent {
  BookItWith = 'BookItWith',
  Proceed = 'ThirdPartyRedirect',
}

export enum VendorName {
  VhubBooking = 'VhubBooking',
}

export interface VendorTrackingData {
  event: VhubEvent;
  loadID: string;
  vendorName: string;
}

export interface MessagingPartnerPortalTrackingData {
  hostname: string;
  clientID: string;
  conversationsCount: number;
}
export interface CallCarrierTrackingData {
  loadID: string;
  truckID: string;
}
export interface TrackingData<T extends object = {}> {
  tag: TrackingTag;
  data: T;
}

export type TrackingDataWithDeviceInfo<T extends CommonTrackingData = CommonTrackingData> = TrackingData<T>;

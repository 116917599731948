import React, { useEffect, useState } from 'react';

import { getInitials } from '@common/helper';
import { ConversationAttachment, ConversationUser } from '@common/model';
import { ConversationAttachmentObject, ConversationAttachments } from '@component/conversation/ConversationAttachments';
import { ConversationUserBubble } from '@component/conversation/ConversationAvatar';
import {
  MessageAndTimeContainer,
  MessageWrapper,
  OtherMessageContainer,
  UserMessageContainer,
} from '@component/conversation/ConversationStyles';
import { ConversationDate, ReadAndSentTime } from '@component/conversation/ConversationTime';
import { Text } from '@component/text';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import Linkify from '@util/linkify/Linkify';

interface MessageProps {
  userID: string | undefined;
  conversationId: string;
  message: string;
  isFromCurrentUser: boolean;
  user: ConversationUser | undefined;
  read: boolean;
  time?: string;
  readAtTime?: string;
  date?: string;
  attachments?: ConversationAttachment[];
  messageId?: string;
  setDocument?: React.Dispatch<React.SetStateAction<ConversationAttachmentObject>>;
  isLastOfGroup?: boolean;
  isFirstOfGroup?: boolean;
}

export const MessageBubble: React.FC<MessageProps> = (props) => {
  const [isMessageRead, setIsMessageRead] = useState(props.isFromCurrentUser && props.read);
  const [displayTime, setDisplayTime] = useState(props.readAtTime);
  useEffect(() => {
    if (props.isFromCurrentUser && props.readAtTime) {
      setIsMessageRead(true);
      setDisplayTime(props.readAtTime);
    }
  }, [props.readAtTime]);

  let MessageContainer = OtherMessageContainer;
  let direction: 'row' | 'row-reverse' = 'row';
  let textColor = Color.GRAY_DARK;
  let nameBubble: JSX.Element | null = props.isLastOfGroup ? (
    <ConversationUserBubble
      initials={getInitials(props.user)}
      userID={props.userID}
      isFromCurrentUser={props.isFromCurrentUser}
    />
  ) : null;
  if (props.isFromCurrentUser) {
    nameBubble = null;
    direction = 'row-reverse';
    textColor = Color.WHITE;
    MessageContainer = UserMessageContainer;
  }

  return (
    <>
      {props.date ? <ConversationDate date={props.date} /> : null}
      <MessageWrapper style={{ flexDirection: direction }} isLastOfGroup={props.isLastOfGroup}>
        {nameBubble}
        <MessageAndTimeContainer style={{ alignItems: props.isFromCurrentUser ? 'flex-end' : 'auto' }}>
          <MessageContainer isFirstOfGroup={props.isFirstOfGroup}>
            <Text style={{ color: textColor, fontSize: FontSize.Content }} id="message">
              <Linkify>{props.message}</Linkify>
            </Text>
            {props.attachments && props.attachments.length > 0 ? (
              <ConversationAttachments
                attachments={props.attachments}
                setDocument={props.setDocument}
                isFromCurrentUser={props.isFromCurrentUser}
                hasMessage={!!props.message}
                messageId={props.messageId}
                conversationId={props.conversationId}
              />
            ) : null}
          </MessageContainer>
          {props.isLastOfGroup ? (
            <ReadAndSentTime
              displayTime={displayTime}
              time={props.time}
              isMessageRead={isMessageRead}
              isFromCurrentUser={props.isFromCurrentUser}
            />
          ) : null}
        </MessageAndTimeContainer>
      </MessageWrapper>
    </>
  );
};

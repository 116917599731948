import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

import { Color } from '@style/Color';
import { ThemeProps, withTheme } from '@style/WithTheme';

export const StyledPanelWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: ${Color.GRAY_GRADIENT_LIGHT};
`;

export const StyledHeader = withTheme()(styled.label`
  font-family: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.fontSize};
  line-height: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.lineHeight};
  color: ${(props: ThemeProps) => props.theme.palette.background.stone};
  margin-bottom: 15px;
`);

export const StyledSubHeader = withTheme()(styled.label`
  font-family: ${(props: ThemeProps) => props.theme.text.body.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
  line-height: ${(props: ThemeProps) => props.theme.text.body.lineHeight};
  color: ${(props: ThemeProps) => props.theme.palette.background.stone};
`);

export const StyledBlock = styled.div`
  margin-bottom: 30px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  align-self: stretch;
`;

export const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
`;

export const CenteredColumnLayout = styled(ColumnLayout)`
  align-items: center;
`;

export const StyledIconsBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 10px;
  align-items: center;
  align-self: stretch;
  > img {
    width: 12px;
  }
  & > :first-child {
    width: 16px;
  }
  & > :last-child {
    width: 16px;
  }
`;

export const StyledGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 30px);
  & > :first-child {
    margin-bottom: 5px;
  }
  & > :last-child {
    margin-top: 5px;
  }
`;

export const StyledGridElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  & > :first-child {
    margin-right: 5px;
    min-width: 158px;
    margin: 0 !important;
  }
  & > :last-child {
    margin-left: 5px;
  }
`;

export const StyledSliderResults = withTheme()(styled.div`
  font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontSize};
  line-height: ${(props: ThemeProps) => props.theme.text.subtitleRegular.lineHeight};
  color: ${(props: ThemeProps) => props.theme.text.subtitleRegular.color};
`);

export const StyledSection = withTheme()(styled.section`
  padding-top: 15px;
  margin-bottom: 5px;
  width: 100%;

  &::after {
    content: '';
    height: 8px;
    width: 100%;
    background: transparent linear-gradient(180deg, #e2e9ee 0%, ${Color.GRAY_LIGHT} 100%) 0% 0% no-repeat padding-box;
    position: absolute;
  }
`);

export const StyledPaddedRow = styled(StyledRow)`
  padding: 0 15px;
`;

export const StyledPaddedColumn = styled(ColumnLayout)`
  padding: 0 15px;
`;

export const StyledTripHeader = withTheme()(styled.div`
  padding-top: 15px;
  margin-left: 7px;
`);

export const CheckboxRow = withTheme()(styled.div`
  padding: 15px 0;
`);

export const SwitchButton = withTheme()(styled(IconButton)`
  && {
    position: absolute;
    color: ${Color.ORANGE_LIGHT};
    &:hover {
      background-color: ${Color.ORANGE_DARK};
    }
    &:active {
      background-color: ${Color.ORANGE_MAIN};
    }
  }
`);

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: ${Color.WHITE};
`;

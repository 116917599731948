import styled from 'styled-components';

import { Icon, Snackbar, SnackbarContent } from '@material-ui/core';

import { ThemeProps, withTheme } from '@style/WithTheme';
import { MediaQueries } from '@util/MediaQueries';

export const Message = styled.span`
  text-align: left;
  font-size: 16px;
`;

export const Submit = withTheme()(styled.span`
  margin: 0 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: ${(props: ThemeProps) => props.theme.palette.background.accent};
`);

export const Content = withTheme()(styled(SnackbarContent)`
  && {
    background-color: ${(props: ThemeProps) => props.theme.palette.background.strong};
    display: grid;
    grid-template-columns: 1fr auto;
  }
`);

export const SnackBarWrapper = styled(Snackbar)`
  && {
    @media ${MediaQueries.sm} {
      left: auto;
      right: 24px;
      bottom: 24px;
      > div {
        min-width: 288px;
        max-width: 468px;
        border-radius: 4px;
      }
    }
  }
`;

export const CustomIcon = styled(Icon)`
  opacity: 0.9;
  font-size: 20;
`;

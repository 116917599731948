import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { UnsubscribeClient } from '@/client/UnsubscribeClient';
import {
  ContactSupportData,
  SystemTimeData,
  TrackingUnsubscribeData,
  TrialExtensionData,
  UnsubscribeData,
} from '@/model/Unsubscribe';
import { Api, ApiResponse123 } from '@common/api';
import {} from '@common/model';
import { BaseState, Response } from '@common/redux/Base';
import { createReducer } from '@common/redux/ReduxHelper';

export type TrialExtensionResponse = Response<TrialExtensionData>;
export type ContactSupportResponse = Response<ContactSupportData>;
export type UnsubscribeResponse = Response<UnsubscribeData>;
export type TrackingUnsubscribeDataResponse = Response<TrackingUnsubscribeData>;
export type SystemTimeResponse = Response<SystemTimeData>;

interface ActionWithOptionalAuth extends Action {
  withAuthentication: boolean;
}

interface TrialExtensionAction extends Action {
  request: TrialExtensionData;
}

interface UnsubscribeAction extends Action {
  request: UnsubscribeData;
}

interface TrackingUnsubscribeDataAction extends Action {
  request: TrackingUnsubscribeData;
}

interface ContactSupportAction extends Action {
  request: ContactSupportData;
}

interface SystemTimeDataResponse extends Action {
  response: SystemTimeResponse;
}

interface TEResponseAction extends Action {
  response: TrialExtensionResponse;
}
interface CSResponseAction extends Action {
  response: ContactSupportResponse;
}

interface UnsubscribeResponseAction extends Action {
  response: UnsubscribeResponse;
}
interface TrackingUnsubscribeDataResponseAction extends Action {
  response: TrackingUnsubscribeDataResponse;
}

export interface UnsubscribeState extends BaseState {
  postTrialExtensionResponse?: TrialExtensionResponse;
  postContactSupportResponse?: ContactSupportResponse;
  postUnsubscribeResponse?: UnsubscribeResponse;
  postTrackingUnsubscribeDataResponse?: TrackingUnsubscribeDataResponse;
  systemTimeResponse?: SystemTimeResponse;
  isLoadingUnsubscribe: boolean;
  isLoadingContactSupport: boolean;
  isLoadingExtension: boolean;
}

const initialState: UnsubscribeState = {
  isLoading: false,
  isLoadingUnsubscribe: false,
  isLoadingContactSupport: false,
  isLoadingExtension: false,
};

//create reducer begin
const createUnsubscribeReducer = () => {
  //@FIXME  use createApiAction to avoid boilerplate code
  const actionTypes = {
    FETCH_SYSTEM_TIME: 'FETCH_SYSTEM_TIME',
    SYSTEM_TIME_FETCHED: 'SYSTEM_TIME_FETCHED',
    POST_TRIAL_EXTENSION: 'POST_TRIAL_EXTENSION',
    POST_TRIAL_EXTENSION_FULFILLED: 'POST_TRIAL_EXTENSION_FULFILLED',
    POST_CONTACT_SUPPORT: 'POST_CONTACT_SUPPORT',
    POST_CONTACT_SUPPORT_FULFILLED: 'POST_CONTACT_SUPPORT_FULFILLED',
    POST_UNSUBSCRIBE: 'POST_UNSUBSCRIBE',
    POST_UNSUBSCRIBE_FULFILLED: 'POST_UNSUBSCRIBE_FULFILLED',
    POST_TRACKING_DATA: 'POST_TRACKING_DATA',
    POST_TRACKING_DATA_FULFILLED: 'POST_TRACKING_DATA_FULFILLED',
  };

  const actions = {
    fetchSystemTime: (): ActionWithOptionalAuth => ({
      type: actionTypes.FETCH_SYSTEM_TIME,
      withAuthentication: false,
    }),
    postTrialExtension: (request: TrialExtensionData): TrialExtensionAction => ({
      type: actionTypes.POST_TRIAL_EXTENSION,
      request: request,
    }),
    postContactSupport: (request: ContactSupportData): ContactSupportAction => ({
      type: actionTypes.POST_CONTACT_SUPPORT,
      request: request,
    }),
    postUnsubscribe: (request: UnsubscribeData): UnsubscribeAction => ({
      type: actionTypes.POST_UNSUBSCRIBE,
      request: request,
    }),
    postTrackingUnsubscribeData: (request: TrackingUnsubscribeData): TrackingUnsubscribeDataAction => ({
      type: actionTypes.POST_TRACKING_DATA,
      request: request,
    }),
    systemTimeDataResponse: (response: SystemTimeResponse): SystemTimeDataResponse => ({
      type: actionTypes.SYSTEM_TIME_FETCHED,
      response: response,
    }),
    postTrialExtensionResponse: (response: TrialExtensionResponse): TEResponseAction => ({
      type: actionTypes.POST_TRIAL_EXTENSION_FULFILLED,
      response: response,
    }),
    postCSResponse: (response: ContactSupportResponse): CSResponseAction => ({
      type: actionTypes.POST_CONTACT_SUPPORT_FULFILLED,
      response: response,
    }),
    postUnsubscribeResponse: (response: UnsubscribeResponse): UnsubscribeResponseAction => ({
      type: actionTypes.POST_UNSUBSCRIBE_FULFILLED,
      response: response,
    }),
    postTrackingUnsubscribeDataResponse: (
      response: TrackingUnsubscribeDataResponse
    ): TrackingUnsubscribeDataResponseAction => ({
      type: actionTypes.POST_TRACKING_DATA_FULFILLED,
      response: response,
    }),
  };

  const unsubscribeReducer = createReducer(initialState, {
    [actionTypes.FETCH_SYSTEM_TIME]: (state) => {
      state.isLoading = true;
      state.systemTimeResponse = undefined;
    },
    [actionTypes.SYSTEM_TIME_FETCHED]: (state, action: SystemTimeDataResponse) => {
      state.isLoading = false;
      state.systemTimeResponse = action.response;
    },
    [actionTypes.POST_TRIAL_EXTENSION]: (state) => {
      state.isLoadingExtension = true;
      state.postTrialExtensionResponse = undefined;
    },
    [actionTypes.POST_TRIAL_EXTENSION_FULFILLED]: (state, action: TEResponseAction) => {
      state.isLoadingExtension = false;
      state.postTrialExtensionResponse = action.response;
    },
    [actionTypes.POST_CONTACT_SUPPORT]: (state) => {
      state.isLoadingContactSupport = true;
      state.postContactSupportResponse = undefined;
    },
    [actionTypes.POST_CONTACT_SUPPORT_FULFILLED]: (state, action: CSResponseAction) => {
      state.isLoadingContactSupport = false;
      state.postContactSupportResponse = action.response;
    },
    [actionTypes.POST_UNSUBSCRIBE]: (state) => {
      state.isLoadingUnsubscribe = true;
      state.postUnsubscribeResponse = undefined;
    },
    [actionTypes.POST_UNSUBSCRIBE_FULFILLED]: (state, action: UnsubscribeResponseAction) => {
      state.isLoadingUnsubscribe = false;
      state.postUnsubscribeResponse = action.response;
    },
    [actionTypes.POST_TRACKING_DATA]: (state) => {
      state.isLoading = true;
      state.postTrackingUnsubscribeDataResponse = undefined;
    },
    [actionTypes.POST_TRACKING_DATA_FULFILLED]: (state, action: TrackingUnsubscribeDataResponseAction) => {
      state.isLoading = false;
      state.postTrackingUnsubscribeDataResponse = action.response;
    },
  });

  return { actions: actions, reducer: unsubscribeReducer, actionTypes: actionTypes };
};
//create reducer end

const memberships = createUnsubscribeReducer();
export const unsubscribeReducer = memberships.reducer;

export const fetchSystemTime = memberships.actions.fetchSystemTime;
export const postTrialExtension = memberships.actions.postTrialExtension;
export const postContactSupport = memberships.actions.postContactSupport;
export const postUnsubscribe = memberships.actions.postUnsubscribe;
export const postTrackingUnsubscribeData = memberships.actions.postTrackingUnsubscribeData;
export const systemTimeDataResponse = memberships.actions.systemTimeDataResponse;
export const postTrialExtensionResponse = memberships.actions.postTrialExtensionResponse;
export const postCSResponse = memberships.actions.postCSResponse;
export const postUnsubscribeResponse = memberships.actions.postUnsubscribeResponse;
export const postTrackingUnsubscribeDataResponse = memberships.actions.postTrackingUnsubscribeDataResponse;

export const createUnsubscribeEpic = (api: Api) => {
  const client = new UnsubscribeClient(api);

  const fetchSystemTime$ = (action$: ActionsObservable<Action>, client: UnsubscribeClient) =>
    action$.ofType(memberships.actionTypes.FETCH_SYSTEM_TIME).pipe(
      mergeMap(() => {
        return client.getSystemTime$().pipe(
          map((response: ApiResponse123<SystemTimeData>) => {
            return response.result(
              (data) =>
                systemTimeDataResponse({
                  success: true,
                  payload: data,
                }),
              (error) =>
                systemTimeDataResponse({
                  success: false,
                  error: error,
                })
            );
          })
        );
      })
    );

  const doPostTrialExtension$ = (action$: ActionsObservable<Action>, client: UnsubscribeClient) =>
    action$.ofType(memberships.actionTypes.POST_TRIAL_EXTENSION).pipe(
      mergeMap((data: TrialExtensionAction) => {
        return client.requestTrialExtension$(data.request).pipe(
          map((response: ApiResponse123<TrialExtensionData>) => {
            return response.result(
              (data) =>
                postTrialExtensionResponse({
                  success: true,
                  payload: data,
                }),
              (error) =>
                postTrialExtensionResponse({
                  success: error.httpStatus === 204,
                  error: error,
                })
            );
          })
        );
      })
    );

  const doPostContactSupport$ = (action$: ActionsObservable<Action>, client: UnsubscribeClient) =>
    action$.ofType(memberships.actionTypes.POST_CONTACT_SUPPORT).pipe(
      mergeMap((data: ContactSupportAction) => {
        return client.contactSupport$(data.request).pipe(
          map((response: ApiResponse123<ContactSupportData>) => {
            return response.result(
              (data) =>
                postCSResponse({
                  success: true,
                  payload: data,
                }),
              (error) =>
                postCSResponse({
                  success: error.httpStatus === 204,
                  error: error,
                })
            );
          })
        );
      })
    );

  const doPostUnsubscribe$ = (action$: ActionsObservable<Action>, client: UnsubscribeClient) =>
    action$.ofType(memberships.actionTypes.POST_UNSUBSCRIBE).pipe(
      mergeMap((data: UnsubscribeAction) => {
        return client.unsubscribeRequest$(data.request).pipe(
          map((response: ApiResponse123<UnsubscribeData>) => {
            return response.result(
              (data) =>
                postUnsubscribeResponse({
                  success: true,
                  payload: data,
                }),
              (error) =>
                postUnsubscribeResponse({
                  success: false,
                  error: error,
                })
            );
          })
        );
      })
    );

  const doPostTrackingUnsubscribeData$ = (action$: ActionsObservable<Action>, client: UnsubscribeClient) =>
    action$.ofType(memberships.actionTypes.POST_TRACKING_DATA).pipe(
      mergeMap((data: TrackingUnsubscribeDataAction) => {
        return client.trackingUnsubscribeData$(data.request).pipe(
          map((response: ApiResponse123<TrackingUnsubscribeData>) => {
            return response.result(
              (data) =>
                postTrackingUnsubscribeDataResponse({
                  success: true,
                  payload: data,
                }),
              (error) =>
                postTrackingUnsubscribeDataResponse({
                  success: false,
                  error: error,
                })
            );
          })
        );
      })
    );

  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchSystemTime$(action$, client),
      doPostTrialExtension$(action$, client),
      doPostContactSupport$(action$, client),
      doPostUnsubscribe$(action$, client),
      doPostTrackingUnsubscribeData$(action$, client)
    );
};

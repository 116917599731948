import React, { CSSProperties, PropsWithChildren, RefObject, useState } from 'react';

import Downshift, { DownshiftState, GetInputPropsOptions, GetItemPropsOptions, StateChangeOptions } from 'downshift';
import { isEmpty, map } from 'lodash';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';

import { MenuItem } from '@material-ui/core';

import { TextField, TextFieldProps } from '@component/input';
import { THEME } from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { MediaQueries } from '@util/MediaQueries';

const StyledList = withTheme()(styled.ul`
  && {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    position: absolute;
    z-index: 100;
    max-height: 384px;
    overflow-y: auto;
    top: 72px;
    min-width: 100%;
    box-shadow: ${(props: ThemeProps) => '5px 5px 10px 0px ' + props.theme.palette.background.pale};
  }
`);

const DropdownDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

const SimpebarWrapper = styled.div`
  & [class*='simplebar-wrapper'] {
    height: unset !important;
  }
`;

const ItemsContainer = styled(SimpleBar)`
  max-height: 384px;
  @media ${MediaQueries.lt_sm} {
    max-height: 230px;
  }
`;
interface MinHeightForDropdownListProps {
  isLoadPostingForm?: boolean;
}
/** This is a hack to workaround panels that are too "short" and cut the dropdown.
 * Defined here because of the previous "hack" in ItemsContainer above.
 * FIXME: (MEM-1450) redo this completely and cleanly (downshift has Hooks now to do this).
 * Also think about the UX mobile case a bit more as having a scrollbar in the
 * page is NOT GOOD for scrolling on mobile at all.
 */
export const MinHeightForDropdownList = withTheme()(styled.div`
  min-height: ${(props: MinHeightForDropdownListProps) => (props.isLoadPostingForm ? 'auto' : '500px')};
  min-width: ${(props: MinHeightForDropdownListProps) => (props.isLoadPostingForm ? '100%' : 'auto')};
  @media ${MediaQueries.lt_sm} {
    min-height: ${(props: MinHeightForDropdownListProps) => (props.isLoadPostingForm ? 'auto' : '400px')};
  }
`);

export interface DisplayItem<Data = any> {
  key: string;
  label: string;
  data?: Data;
}

interface ListItemProps<Data> {
  index: number;
  item: DisplayItem<Data>;
  style: CSSProperties;
}

interface Props<Data = any> {
  id: string;
  title: string;
  value: string;
  items: DisplayItem<Data>[];
  onSelectionChange: (selectedItem?: DisplayItem<Data>) => void;
  onInputValueChange: (text: string) => void;
  isLoading: boolean;
  hasError: boolean;
  helperText?: string;
  onClear?: () => void;
  withCustomScroll?: boolean;
  placeholder?: string;
  isWithoutMargin?: boolean;
  required?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  inputRef?: RefObject<HTMLElement>;
  shouldAvoidDownshiftStateResetOnBlur?: boolean;
  isDisabled?: boolean;
}

type InputProps = Common<TextFieldProps, GetInputPropsOptions>;

export const DropdownList = <Data,>(props: PropsWithChildren<Props<Data>>) => {
  const [isFocused, setIsFocused] = useState(false);

  const canErase = !isEmpty(props.value);

  const handleOuterClick = () => props.onSelectionChange(undefined);

  const stateReducer = (
    state: DownshiftState<DisplayItem<Data>>,
    changes: StateChangeOptions<DisplayItem<Data>>
  ): Partial<StateChangeOptions<DisplayItem<Data>>> => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.touchEnd:
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.blurInput:
        return {
          ...changes,
          inputValue: props.value,
        };
      default:
        return changes;
    }
  };

  return (
    <Downshift
      id={props.id}
      onChange={props.onSelectionChange}
      itemToString={valueForItem}
      onOuterClick={handleOuterClick}
      defaultHighlightedIndex={0}
      inputValue={props.value}
      onInputValueChange={props.onInputValueChange}
      stateReducer={props.shouldAvoidDownshiftStateResetOnBlur ? stateReducer : undefined}
    >
      {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex, selectedItem, closeMenu, openMenu }) => (
        <div style={{ flex: 1 }}>
          <DropdownDiv>
            <TextField
              {...(getInputProps({
                onFocus: () => {
                  openMenu();
                  props.onFocus?.();
                  setIsFocused(true);
                },
                onBlur: () => {
                  closeMenu();
                  props.onBlur?.();
                  setIsFocused(false);
                },
                onKeyDown: (ev: KeyboardEvent) => {
                  const highlightedItem = props.items[highlightedIndex ?? 0];
                  if (ev.key === 'Tab' && highlightedItem && highlightedItem.label !== props.value) {
                    props.onSelectionChange(highlightedItem);
                    ev.preventDefault();
                  }
                },
              }) as InputProps)}
              inputRef={props.inputRef}
              id={props.id}
              label={props.title}
              fullWidth={true}
              loading={props.isLoading}
              autoTextSelectionOnFocus={canErase}
              error={!isFocused ? props.hasError : false}
              helperText={!isFocused ? props.helperText : undefined}
              onClear={canErase ? props.onClear : undefined}
              focusOnLoad={true}
              placeholder={props.placeholder}
              isWithoutMargin={props.isWithoutMargin}
              autoComplete={'off'}
              required={props.required}
              autoFocus={props.autoFocus}
              disabled={props.isDisabled}
            />
            {props.withCustomScroll ? (
              <SimpebarWrapper>
                <StyledList {...getMenuProps({ refKey: 'innerRef' })}>
                  {isOpen && !isEmpty(props.items) ? (
                    <ItemsContainer>
                      {renderItems(props.items, getItemProps, highlightedIndex ?? 0, selectedItem)}
                    </ItemsContainer>
                  ) : null}
                </StyledList>
              </SimpebarWrapper>
            ) : (
              <StyledList {...getMenuProps({ refKey: 'innerRef' })}>
                {isOpen ? renderItems(props.items, getItemProps, highlightedIndex ?? 0, selectedItem) : null}
              </StyledList>
            )}
          </DropdownDiv>
        </div>
      )}
    </Downshift>
  );
};

const valueForItem = <Data,>(item: DisplayItem<Data>) => (item ? item.label : '');

const renderItems = <Data,>(
  items: DisplayItem<Data>[],
  getItemProps: (options: GetItemPropsOptions<DisplayItem<Data>>) => ListItemProps<Data>,
  highlightedIndex: number,
  selectedItem: DisplayItem<Data> | undefined | null
) =>
  map(items, (item, index) => {
    const itemProps = getItemProps({
      key: item.key,
      index: index,
      item: item,
      style: {
        backgroundColor:
          highlightedIndex === index ? THEME.palette.textField.hoverBackground : THEME.palette.background.paper,
        fontWeight: selectedItem?.key === item.key ? 'bold' : 'normal',
      },
    });
    return (
      <MenuItem {...itemProps} key={item.key}>
        {item.label}
      </MenuItem>
    );
  });

import { BaseClient } from '@common/client/BaseClient';
import { Loads, LoadSearchGeoResponse, MyLoadsRequest } from '@common/model';
import { LoadProgress, LoadUserdata } from '@common/model/LoadUserdata';
import { EmptyResponse } from '@common/redux/Base';

export interface SearchQuery {
  isGone: boolean;
  isViewed: boolean;
  isLowPayRate: boolean;
  isHidden: boolean;
  isCalled: boolean;
  isEmailed: boolean;
  isMessaged: boolean;
  isContacted: boolean;
  isWrongInfo: boolean;
  isNotInterested: boolean;
  isSaved: boolean;
  isNoted: boolean;
  isHiddenAfterCall: boolean;
  includeHidden: boolean;
  progress: LoadProgress;
  isBooked: boolean;
}

export interface PatchLoadsUserdataRequest {
  searchQuery: Partial<SearchQuery>;
  patchDocument: Partial<LoadUserdata>;
  excludeIds: string[];
}

export class MyLoadsClient extends BaseClient {
  fetchMyLoads$ = (request: MyLoadsRequest) => this.api.get$<Loads>('/loads/search/userdata', request);
  fetchMyGeoLoads$ = (request: MyLoadsRequest) =>
    this.api.get$<LoadSearchGeoResponse>('/loads/geosearch/userdata', request);

  patchLoadsUserdata$ = (request: PatchLoadsUserdataRequest) => {
    return this.api.mergePatch$<EmptyResponse>('/loads/userdata/filter', request);
  };
}

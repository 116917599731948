export const SCROLLABLE_CONTAINER_ID = 'Scrollable_Container';
export const SNACKBAR_DISPLAY_DURATION = 4000;

export const getScrollTop = () => {
  if (window.pageYOffset) {
    return window.pageYOffset;
  }
  if (document.documentElement) {
    return document.documentElement.scrollTop;
  }
  if (document.body) {
    return document.body.scrollTop;
  }
  return 0;
};

export const copyToClipboard = (text: string) => {
  const range = document.createRange();
  const selection = document.getSelection();
  if (!selection) {
    return;
  }
  selection.removeAllRanges();

  const mark = document.createElement('span');
  mark.textContent = text;
  mark.style.position = 'fixed';
  mark.style.top = '0';
  mark.style.clip = 'rect(0, 0, 0, 0)';
  mark.style.whiteSpace = 'pre';
  mark.style.webkitUserSelect = 'text';
  mark.style.msUserSelect = 'text';
  mark.style.userSelect = 'text';

  document.body.appendChild(mark);

  range.selectNodeContents(mark);
  selection.addRange(range);

  const successful = document.execCommand('copy');
  if (!successful) {
    //TODO: Display error message.
    return;
  }

  selection.removeAllRanges();
  document.body.removeChild(mark);
};

import { CompanyCategory } from './Company';

export enum UserType {
  Carrier = 'Carrier',
  BrokerShipper = 'BrokerShipper',
  BrokerCarrier = 'BrokerCarrier',
}

export enum PhoneTypes {
  None = 'None',
  Mobile = 'Mobile',
  Landline = 'Landline',
  Voip = 'Voip',
}

interface Phone {
  // ID is the verificationGuid of the phone number
  id?: string;
  countryCode?: string;
  lineNumber?: string;
  extension?: string;
  lineType?: PhoneTypes;
}

/*
  In this model we have: phone, phoneExt, and mobile which will eventually be deprecated
  for the primaryPhone and mobilePhone attributes but both are being returned at this time and
  the phone numbers are the same
*/
export interface UserInfo {
  primaryPhone?: Phone;
  mobilePhone?: Phone;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneExt?: string;
  mobile?: string;
}

export const USERPROFILE_FIELDS =
  'id,roles,paymentPromptRequired,primaryPhone,firstName,lastName,email,phone,phoneExt,mobile,companyId,companyName,companyCity,companyState,companyCountry,canUpgradeMembership,planDisplayName,appReviewPromptRequired,hasMembershipUpgradeAuthority,billingAccountEmail,isIntegrator,areIntegrationsAvailable,billingIssue,billingErrorSeverity,isUsdotNumberVerified,availableLoadViewCount,billingProfileStatus,expirationDate,companyCategory';

// UserProfile: Only keep the strict necessary.
// Add fields when required to both the structure and USERPROFILE_FIELDS
//
// PERF NOTE: as of this writing 'paymentPromptRequired' is used only in /web/ and should be
// removed from the USER_PROFILE_FIELDS on /mobile/ and its field made optional. [BP]

export interface UserProfile extends UserInfo {
  id: string;
  companyId?: string;
  companyName?: string;
  companyCity?: string;
  companyState?: string;
  companyCountry?: string;
  isIntegrator?: boolean;
  areIntegrationsAvailable?: boolean;
  roles: Roles[];
  paymentPromptRequired: boolean;
  canUpgradeMembership?: boolean;
  planDisplayName: string;
  appReviewPromptRequired?: boolean;
  isBillingAccountOwner?: boolean;
  billingAccountEmail?: string;
  hasMembershipUpgradeAuthority?: boolean;
  billingIssue?: BillingIssue;
  billingErrorSeverity?: BillingErrorSeverity;
  isUsdotNumberVerified?: boolean;
  availableLoadViewCount?: number;
  billingProfileStatus?: BillingStatus;
  expirationDate?: string;
  companyCategory?: CompanyCategory;
}

export enum BillingIssue {
  None = 'None',
  SoftDecline = 'SoftDecline',
  HardDecline = 'HardDecline',
  AccountExpiringSoon = 'AccountExpiringSoon',
  AccountExpired = 'AccountExpired',
}

export enum BillingErrorSeverity {
  Moderate = 'Moderate',
  Critical = 'Critical',
}

export enum Roles {
  Loadposter = 'loadposter',
  Loadplanner_beta = 'loadplanner_beta',
  Loadplanner = 'loadplanner',
  Truckposter = 'truckposter',
  Member = 'member',
  Guest = 'guest',
  Basic = 'basic',
  Standard = 'standard',
  Premium = 'premium',
  SearchDirectory = 'search_directory',
  Pcmiler = 'pcmiler',
  Ratecheck = 'ratecheck',
  CargoChiefRate = 'c4rateaddon',
  Website = 'website',
  Controlcenter = 'controlcenter',
  Admin = 'admin',
  Hotleads = 'hotleads',
  Scoopmonkey = 'scoopmonkey',
  View_profiles = 'view_profiles',
  Internal = 'internal',
  Sysadmin = 'sysadmin',
  Apibrowser = 'apibrowser',
  Developer = 'developer',
  Signup = 'signup',
  Cms_public = 'cms_public',
  Cms = 'cms',
  Company_admin = 'company_admin',
  Sendloadalerts = 'sendloadalerts',
  Impersonator = 'impersonator',
  Posteverywhere = 'posteverywhere',
  Tester = 'tester',
  Clienttheme = 'clienttheme',
  Messaging = 'messaging',
  IntegratorContact = 'integrator_contact',
}

export interface LoadDetailsRoles {
  isMapTollsAvailable: boolean;
  isCreditRatingAvailable: boolean;
  isRateCheckAvailable: boolean;
  isCargoChiefRateAvailable: boolean;
  isLoadPlannerAvailable: boolean;
  isInternalAdmin: boolean;
}

export enum BillingStatus {
  None = 'None',
  PromptAtLogin = 'PromptAtLogin',
  PromptAtTrialExpiry = 'PromptAtTrialExpiry',
  PendingVerification = 'PendingVerification',
  PaymentDeclined = 'PaymentDeclined',
  Ready = 'Ready',
  OnHold = 'OnHold',
  Closed = 'Closed',
  Deleted = 'Deleted',
}

import React from 'react';

import { map } from 'lodash';
import styled from 'styled-components';

import { MenuItem, Select } from '@material-ui/core';
import { default as ExpandIcon } from '@material-ui/icons/ArrowDropDown';

import { DisplayItem } from '@component/dropdown/DropdownList';
import { ThemeProps, withTheme } from '@style/WithTheme';

import { CustomSelectProps } from './CustomSelect';
import Fieldset from './Fieldset';

const StyledSelect = styled(Select)`
  && {
    color: ${(props: { color: string }) => props.color};
    display: flex;
    box-sizing: border-box;
    height: 36px;
    margin-top: -6px;
  }

  > div div {
    line-height: 19px;
    font-size: 14px;
    padding-left: 4px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 9px;
  }

  > div div:focus {
    background: rgba(0, 0, 0, 0);
  }

  > div svg {
    right: -2px;
  }
`;

const StyledDropdownIcon = withTheme()(styled(ExpandIcon)`
  && {
    color: ${(props: ThemeProps) => props.theme.palette.primary.main};
  }
`);

const StyledMenuItem = styled(MenuItem)`
  && {
    color: ${(props) => props.color};
  }
`;

interface BorderedSelectProps extends CustomSelectProps {
  alignVertical?: boolean;
}

class ThemelessBorderedSelect extends React.PureComponent<BorderedSelectProps & ThemeProps> {
  state = {
    isFocused: false,
  };

  mapSelectOptions = (id: string) => {
    return map(this.props.options, (option: DisplayItem, index: number) => {
      return (
        <StyledMenuItem
          id={id + '_item_' + index}
          value={option.key}
          color={this.props.theme.palette.primary.main}
          key={index}
        >
          {option.label}
        </StyledMenuItem>
      );
    });
  };

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  public render() {
    return (
      <Fieldset
        id={this.props.id + '_fieldset'}
        label={this.props.label}
        color={this.props.theme.palette.primary.main}
        fullWidth={true}
        isFocused={this.state.isFocused}
        alignVertical={this.props.alignVertical}
      >
        <StyledSelect
          id={this.props.id}
          value={this.props.value}
          disableUnderline={true}
          fullWidth={true}
          onChange={this.props.onChange}
          inputProps={this.props.inputProps}
          color={this.props.theme.palette.primary.main}
          IconComponent={StyledDropdownIcon}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        >
          {this.mapSelectOptions(this.props.id + '_options')}
        </StyledSelect>
      </Fieldset>
    );
  }
}

export const BorderedSelect = withTheme()(ThemelessBorderedSelect);

import React from 'react';

import { LatLngBounds, Marker } from 'leaflet';
import 'leaflet.markercluster';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';

import { withLeaflet } from '@component/map/WithLeaflet';

export interface MarkerCluster {
  getAllChildMarkers(storageArray?: Marker[]): Marker[];
  getChildCount(): number;
  getBounds(): LatLngBounds;
}

// sadly, the MarkerClusterGroup has no usable types to work with
class CustomMarkerClusterGroupComponent extends React.Component<any> {
  private clusterGroupRef: React.RefObject<React.Component>;

  constructor(props: any) {
    super(props);
    this.clusterGroupRef = React.createRef<any>();
  }

  public render() {
    return (
      <MarkerClusterGroup
        ref={this.clusterGroupRef}
        onClick={this.onClusterClick}
        zoomToBoundsOnClick={false}
        spiderfyOnMaxZoom={false}
        {...this.props}
      >
        {this.props.children}
      </MarkerClusterGroup>
    );
  }

  private onClusterClick = (ev: any) => {
    // Original code taken from  _zoomOrSpiderfy()
    // https://github.com/Leaflet/Leaflet.markercluster/blob/master/src/MarkerClusterGroup.js#L840
    // It normally implements the zoomToBoundsOnClick and spiderfyOnMaxZoom features
    // It was modified to suit our needs.
    const cluster = ev.layer;
    if (!cluster.spiderfy) {
      // sometimes getting a click on a non-cluster icon (?)
      return;
    }

    // always spiderfy for now. This can become bad if too many trucks. see @TODO below
    cluster.spiderfy();

    /*
    Commented code is left over from the original version & modified to add 90 pixel padding.

    @TODO  allow zooming when the zoom level too high or the number of trucks is too high.
           Should check if the number of items are > 1 at max zoom else no need to zoom.
           also when doing a fitBounds() -- attempt to cap it to a maxZoom (like 8).
           That way the user won't be "stuck" at max zoom levels if not necessary.

    const clusterGroupRef = this.clusterGroupRef.current;
    const markerClusterLayer = (clusterGroupRef as any).leafletElement;

    let bottomCluster = cluster;

    while (bottomCluster._childClusters.length === 1) {
      bottomCluster = bottomCluster._childClusters[0];
    }


    if (bottomCluster._zoom === markerClusterLayer._maxZoom && bottomCluster._childCount === cluster._childCount) {
      // All child markers are contained in a single cluster from this._maxZoom to this cluster.
      cluster.spiderfy();
    } else {
      cluster._map.fitBounds(cluster.getBounds(), {
        padding: [90, 90],
      });
    }

    // Focus the map again for keyboard users.
    if (ev.originalEvent && ev.originalEvent.keyCode === 13) {
      cluster._map._container.focus();
    }
    */
  };
}

export const CustomMarkerClusterGroup = withLeaflet(CustomMarkerClusterGroupComponent);

import React from 'react';

import { clone, map, slice } from 'lodash';
import styled from 'styled-components';

import { displayCurrencyOrDash, hasDecimal } from '@common/formatter';
import { formatDateDotSeparatedWithTime } from '@common/helper';
import { BidStatus } from '@common/model';
import { Status } from '@component/loadListCard/LoadListCardStyle';
import BidIconPale from '@component/menu/icon/bid-icon-pale.svg';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { SectionHeader } from '@component/panels/basePanel/panelFiltersSection/PanelFiltersList';
import { Color } from '@style/Color';
import { FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

import { EndContainer, ListItem, Price, Subtitle, Title } from './BidListItem';

interface BidHistoryListProps {
  bidIds: string[];
  hasPlaceNewBidCard?: boolean;
}

const HistorySectionHeader = styled(SectionHeader)`
  height: 40px;
  padding: 0 16px;
`;

const HistorySectionTitle = styled.span`
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.GRAY_21};
`;

const HistoryTitle = styled(Title)`
  color: ${Color.GRAY_21};
`;

const HistorySubtitle = styled(Subtitle)`
  color: ${Color.GRAY_21};
`;

const HistoryPrice = styled(Price)`
  color: ${Color.GRAY_21};
`;

export const BidHistoryList: React.FC<BidHistoryListProps> = ({ bidIds, hasPlaceNewBidCard }) => {
  const isCarrier = useIsCarrier();
  const updatedBidIds = hasPlaceNewBidCard ? clone(bidIds) : slice(bidIds, 1);

  // @FIXME: when the pagination for history bids is implemented update the `didLoadChange` flag
  // in BidsEpic to include the token for pagination
  return (
    <>
      {updatedBidIds.length > 0 ? (
        <HistorySectionHeader>
          <HistorySectionTitle id="title">{t(T.common_bids_summary_history)}</HistorySectionTitle>
        </HistorySectionHeader>
      ) : null}

      {map(updatedBidIds, (bidId, index) => (
        <BidHistoryItem key={`${bidId}_${index}`} bidId={bidId} isCarrier={isCarrier} />
      ))}
    </>
  );
};

const BidHistoryItem = (props: { isCarrier: boolean; bidId: string }) => {
  const bid = useSelector((state) => state.bids.loadBids.entities.get(props.bidId));

  if (!bid) {
    return null;
  }

  if (
    bid.status === BidStatus.Pending ||
    bid.status === BidStatus.ActionRequired ||
    bid.status === BidStatus.Accepted
  ) {
    return null;
  }

  let cardTitle = '';
  if (props.isCarrier) {
    if (bid.offerMadeByMe) {
      cardTitle = t(T.common_bids_bidItem_titles_yourOffer);
    } else {
      cardTitle = t(T.common_bids_bidItem_titles_counterOffer);
    }
  } else {
    if (bid.offerMadeByMe) {
      cardTitle = t(T.common_bids_bidItem_titles_yourCounteroffer);
    } else {
      cardTitle = t(T.common_bids_bidItem_titles_carrierOffer);
    }
  }

  return (
    <div
      style={{
        padding: Spacing.ScreenSide,
        background: Color.GRAY_LIGHT,
        borderBottom: `1px solid ${Color.GRAY_SMOKE}`,
      }}
      id="bid_summary_item"
    >
      <ListItem
        image={<img id="pale_bid_icon" src={BidIconPale} />}
        title={<HistoryTitle id="title_offer_card">{cardTitle}</HistoryTitle>}
        subtitle={
          <HistorySubtitle id="subtitle_offer_date">
            {formatDateDotSeparatedWithTime(bid.mostRecentActivity ?? '')}
          </HistorySubtitle>
        }
        end={
          <EndContainer>
            <HistoryPrice id="price_value_offer">{`${displayCurrencyOrDash(
              bid.price,
              hasDecimal(bid.price)
            )}`}</HistoryPrice>
            <Status style={{ width: '100%', textAlign: 'center' }} id="bid_status_offer" color={Color.GRAY_21}>
              {bid.status}
            </Status>
          </EndContainer>
        }
      />
    </div>
  );
};

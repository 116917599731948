import React from 'react';

import { StyledTimeChip } from './ChipStyle';

export const TimeChip = ({
  text,
  id,
  disabled,
  tabIndex,
}: {
  text: string;
  id: string;
  disabled?: boolean;
  tabIndex?: number;
}) => <StyledTimeChip id={id} label={text} clickable={!disabled} tabIndex={tabIndex} />;

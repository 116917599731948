import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import { default as NoConversations } from '@/icons/no_conversations.svg';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { Routes } from '@/router/Routes';
import { hasRoles } from '@common/helper';
import { Roles } from '@common/model';
import { markAllMessagesAsRead } from '@common/redux/epic/CommunicationEpic';
import { MoreMenuButton } from '@component/buttons/MoreMenuButton';
import { LoadingSpinner, LoadingSpinnerSmallPanelContainer } from '@component/loadingSpinner/LoadingSpinner';
import { DropdownMenu } from '@component/menu/DropdownMenu';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { usePanelBackLocation } from '@component/panel/PanelHooks';
import { CommunicationDisabledByBrokerPanel } from '@component/panels/communication/CommunicationDisabledByBrokerPanel';
import { ConversationListFilters } from '@component/panels/communication/ConversationListFilters';
import { MenuOptions } from '@component/panels/communication/header/MenuOptions';
import { MessagingWidgetMenuOptions } from '@component/panels/communication/header/MessagingWidgetMenuOptions';
import { NotFoundPanel } from '@component/panels/notFoundSearches/NotFoundPanel';
import { ConversationListFilter, ConversationListViewType } from '@page/communication/ConversationListFilter';
import { GlobalConversations } from '@page/communication/GlobalConversations';
import { LoadConversations, LoadConversationsOriginUI } from '@page/communication/LoadConversations';
import { NotificationWarningBanner } from '@page/communication/NotificationWarningBanner';
import {
  enableTokenToServer,
  sendNewTokenToServer,
  updateTokenToServer,
  useRequestNotificationPermission,
} from '@page/communication/RegisterBrowserToFirebaseNotification';
import { T, t } from '@translate';
import {
  isBrowserNotificationSupported,
  NotificationPermissionStatus,
} from '@util/firebaseNotification/FirebasePushNotification';
import { useIsMobileView, useSelector } from '@util/hooks';
import { deviceID } from '@webApi/CookiesHelper';

interface RouteParams {
  loadID: string;
  backhaulLoadID: string;
  conversationID?: string;
}

interface Props {
  layer: number;
  shouldHideClose?: boolean;
  originUI: LoadConversationsOriginUI;
  loadIDMatchParamKey?: string;
  loadID?: string;
}

export const CommunicationListPanel: React.FC<Props> = (props) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [messagesView, setMessagesView] = useState(ConversationListViewType.All);
  const [menuAnchor, setMenuAnchor] = useState(undefined);
  const params = useParams<{ [key: string]: string }>();
  let panelSize = PanelSize.small;
  const loadID = props.loadID ?? params[props.loadIDMatchParamKey ?? 'loadID'];
  const { backhaulLoadID } = useParams<RouteParams>();

  const closeLocation = usePanelBackLocation('/communication'); // TODO: !!!
  const dispatch = useDispatch();
  const isMobileView = useIsMobileView();
  const isCarrier = useIsCarrier();

  const isLoadingProfile = useSelector((state) => state.user.profile === undefined);
  const isCommunicationsEnabled = useSelector((state) => state.communication.isCommunicationsEnabled);
  const isBrokerCommunicationEnabled = useSelector((state) => state.communication.isBrokerCommunicationEnabled);
  const markAsReadstate = useSelector((state) => state.communication.markAllMessagesAsRead);
  const wasTokenSaved = useSelector((state) => state.usersSettings.userNotificationToken.wasSavedSuccessfully);

  const [messagesMarkedAsRead, setMessagesMarkedAsRead] = useState(false);

  const scrollContainerRef = useRef<HTMLInputElement>(null);

  const conversationId = useConversationID();

  const doesUserHaveMessagingRole = hasRoles(
    useSelector((state) => state.user.profile?.payload?.roles),
    [Roles.Messaging]
  );
  const shouldShowNotificationBanner =
    isCarrier && !permissionGranted && isBrowserNotificationSupported() && !isMobileView;

  const isPermissionGrantedAndBrowserSupported = isBrowserNotificationSupported()
    ? Notification.permission === NotificationPermissionStatus.GRANTED && !isMobileView
    : true;

  const isLoading =
    isCommunicationsEnabled === undefined ||
    (!isCarrier && isBrokerCommunicationEnabled === undefined) ||
    isLoadingProfile; // fetching roles
  const isValidConversation = doesUserHaveMessagingRole && (isCarrier || isBrokerCommunicationEnabled);
  const shouldShowListFilters = isValidConversation && props.originUI === LoadConversationsOriginUI.CommunicationPage;

  useEffect(() => {
    if (!markAsReadstate.isLoading && messagesMarkedAsRead) {
      if (markAsReadstate.wereAllMarkedAsRead) {
        dispatch(showSnackbar({ message: t(T.common_conversations_markAllAsRead_success) }));
      } else {
        dispatch(showSnackbar({ message: t(T.common_conversations_markAllAsRead_failure) }));
      }
      setMessagesMarkedAsRead(false);
    }
  }, [markAsReadstate]);

  useEffect(() => {
    if (isPermissionGrantedAndBrowserSupported) {
      setPermissionGranted(true);
      sendNewTokenToServer(dispatch, deviceID());
    } else {
      setPermissionGranted(false);
    }
  }, []);

  useEffect(() => {
    if (wasTokenSaved === true) {
      enableTokenToServer(dispatch, deviceID());
    } else {
      updateTokenToServer(dispatch, deviceID(), false);
    }
  }, [wasTokenSaved]);

  const requestPermission = useRequestNotificationPermission(
    permissionGranted,
    isMobileView,
    dispatch,
    setPermissionGranted
  );

  const openFilterPanel = () => setIsFilterPanelOpen(true);

  const handleChange = (filter: ConversationListViewType) => {
    setIsFilterPanelOpen(false);
    if (filter) {
      setMessagesView(filter);
    }
  };

  const getConversationComponent = () => {
    const filter = messagesView ?? ConversationListViewType.All;
    return loadID ? (
      <LoadConversations
        loadId={backhaulLoadID ?? loadID}
        originUI={props.originUI}
        conversationId={conversationId}
        scrollRef={scrollContainerRef}
        filter={filter}
        handleChange={handleChange}
      />
    ) : (
      <GlobalConversations
        originUI={props.originUI}
        conversationId={conversationId}
        scrollRef={scrollContainerRef}
        filter={filter}
        handleChange={handleChange}
      />
    );
  };

  let content: JSX.Element;
  if (isLoading) {
    content = (
      <LoadingSpinnerSmallPanelContainer>
        <LoadingSpinner id="loading" />
      </LoadingSpinnerSmallPanelContainer>
    );
  } else if (!isCommunicationsEnabled) {
    content = <CommunicationDisabledPanel />;
  } else if (isValidConversation) {
    content = getConversationComponent();
  } else {
    //In a single special case where the menu layout selected is Broker and the communication is disabled,
    //we will present the content in a "tablet view", which means that the panel size should be medium
    if (!isMobileView && !isCarrier) {
      panelSize = PanelSize.medium;
    }
    content = <CommunicationDisabledByBrokerPanel />;
  }

  const onCloseMessagingWidget = () => {
    if (window.parent) {
      window.parent.postMessage('resize', '*');
    }
  };

  const handleMenuClick = (event: BaseSyntheticEvent) => {
    setMenuAnchor(event.currentTarget);
  };

  const onMarkAllMessagesAsRead = () => {
    dispatch(markAllMessagesAsRead());
    setMessagesMarkedAsRead(true);
  };

  if (isFilterPanelOpen) {
    return <ConversationListFilter layer={0} offset={0} initialView={messagesView} onClose={handleChange} />;
  }

  return (
    <Panel
      id="conversations"
      size={panelSize}
      layer={props.layer}
      offset={0}
      scrollRef={scrollContainerRef}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        hasCloseButton={!props.shouldHideClose}
        onClose={() =>
          props.originUI === LoadConversationsOriginUI.MessagingWidget ? onCloseMessagingWidget() : closeLocation()
        }
        style={{ padding: '0 6px' }}
        label={t(T.common_conversations_title)}
        actionElement={<MoreMenuButton ref={menuAnchor} onClick={handleMenuClick} />}
      >
        <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(undefined)}>
          {props.originUI === LoadConversationsOriginUI.MessagingWidget ? (
            <MessagingWidgetMenuOptions setMenuAnchor={setMenuAnchor} />
          ) : (
            <MenuOptions onMarkAllMessagesAsRead={onMarkAllMessagesAsRead} setMenuAnchor={setMenuAnchor} />
          )}
        </DropdownMenu>
      </PanelHeader>
      {shouldShowNotificationBanner ? (
        <NotificationWarningBanner
          buttonText={t(T.common_conversations_notificationBanner_activate)}
          onPress={requestPermission}
          headerText={t(T.common_conversations_notificationBanner_content)}
        />
      ) : null}
      {shouldShowListFilters ? (
        <ConversationListFilters messagesView={messagesView} openFilterPanel={openFilterPanel} />
      ) : null}
      {content}
    </Panel>
  );
};

const CommunicationDisabledPanel: React.FC = () => (
  <NotFoundPanel
    imageSection={<img id="image" src={NoConversations} alt="error" />}
    title={t(T.common_conversations_disabled_wholeFeature_title)}
    subtitle={t(T.common_conversations_disabled_wholeFeature_subtitle)}
  />
);

const useConversationID = () => {
  const location = useLocation();
  const match = matchPath<RouteParams>(location.pathname, {
    path: Routes.COMMUNICATION,
  });

  if (match === null) {
    const nestedMatch = matchPath<RouteParams>(location.pathname, {
      path: Routes.LOADS_FINDLOADS,
    });

    return nestedMatch?.params.conversationID;
  }
  return match?.params.conversationID;
};

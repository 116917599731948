import { useMemo } from 'react';

import { find, forEach } from 'lodash';

import { ConversationUser } from '@common/model';
import { useSelector } from '@util/hooks';

export const useEnableConversationForContact = (conversationID: string) => {
  const currentUser = useSelector((state) => state.user.profile?.payload);
  const currentConversation = useSelector((state) => state.communication.conversations.get(conversationID));
  const conversationLoadDetails = useSelector((state) => state.communication.conversationLoadDetails.load);
  const allowMessagingOnLoads = useSelector((state) => state.communication.isBrokerCommunicationEnabled);
  const canMessage = useSelector((state) => state.communication.canMessageConversation);

  const canInitiateConversation = conversationLoadDetails?.conversation?.canInitiateConversation;
  const conversationMetadataLoad = currentConversation?.metadata?.load;
  const conversationCreatedBy = currentConversation?.createdBy;

  const isLoadPoster =
    currentUser?.id !== conversationCreatedBy && conversationMetadataLoad?.poster?.id === currentUser?.companyId;

  const isCarrierConversationEnabled =
    (conversationLoadDetails &&
      conversationLoadDetails.id === conversationMetadataLoad?.id &&
      canInitiateConversation) ||
    (conversationLoadDetails === undefined && conversationMetadataLoad !== undefined);

  if (conversationMetadataLoad) {
    // if the conversation has a load, check values in load details/settings
    return !isLoadPoster ? isCarrierConversationEnabled : allowMessagingOnLoads;
  }
  // if there is no load, use the "canMessage" value
  return !canMessage.isLoading && canMessage.canMessage;
};

export const useOtherUserInfo = (conversationID: string) => {
  const userID = useSelector((state) => state.user.profile?.payload?.id);
  const messagesEntry = useSelector((state) => state.communication.conversationMessages.get(conversationID));
  const users = useConversationUsers(conversationID);
  return useMemo(() => {
    const userStatus = find(messagesEntry?.userStatus.users, (user) => user.userId !== userID);
    if (!userStatus) {
      return undefined;
    }
    const user = users.get(userStatus.userId);
    if (!user) {
      return undefined;
    }
    return {
      user: user,
      status: userStatus,
    };
  }, [userID, messagesEntry, users]);
};

export const useConversationUsers = (conversationID: string) => {
  const conversations = useSelector((state) => state.communication.conversations);
  return useMemo(() => {
    const conversation = conversations.get(conversationID);
    const userMap = new Map<string, ConversationUser>();
    forEach(conversation?.users, (user) => {
      userMap.set(user.userId, user);
    });
    return userMap;
  }, [conversationID, conversations]);
};

export const useUserVerificationStatus = (conversationID: string) => {
  const otherUserInfo = useOtherUserInfo(conversationID);
  const currentConversation = useSelector((state) => state.communication.conversations.get(conversationID));
  const currentUser = useSelector((state) => state.user.profile?.payload);
  const isLoadingCurrentUser = useSelector((state) => state.user.isLoading);
  const isCarrierIdentityVerified = useSelector(
    (store) => store.contact.contacts.get(otherUserInfo?.user.userId ?? '')?.carrier?.isCarrierIdentityVerified
  );
  const isLoading = useSelector((store) => store.contact.contacts.get(otherUserInfo?.user.userId ?? '')?.isLoading);

  const conversationMetadataLoad = currentConversation?.metadata?.load;
  const conversationCreatedBy = currentConversation?.createdBy;
  const isUserLoadPoster =
    currentUser?.id !== conversationCreatedBy && conversationMetadataLoad?.poster?.id === currentUser?.companyId;

  if (isUserLoadPoster) {
    return {
      isCurrentUserUnverified: false,
      isOtherUserUnverified: isCarrierIdentityVerified === false,
      isLoading: isLoading,
    };
  }

  return {
    isCurrentUserUnverified: currentUser ? !currentUser.isUsdotNumberVerified : false,
    isOtherUserUnverified: false,
    isLoading: isLoadingCurrentUser,
  };
};

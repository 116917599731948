import { clone, find } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { iif as iif$, merge as merge$, of as of$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiError, ApiErrorCode, ApiResponse123 } from '@common/api';
import { SettingsClient } from '@common/client';
import { toAddress } from '@common/helper';
import { HomeLocation, LoadLocationCity, LoadSearchSettings, LocationType } from '@common/model';
import {
  CardView,
  KeysView,
  KeysViewWithoutMap,
  KeysWithoutList,
  ListView,
  ViewChoice,
  WebViewPages,
  WebViews,
} from '@common/model/LoadView';
import { ResponseAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export const HOME_LOCATION_REDUCER_KEY = 'HOME_LOCATION_REDUCER_KEY';
const SET_HOME_LOCATION = 'SET_HOME_LOCATION';
const LOAD_SEARCH_SETTINGS_SET = 'LOAD_SEARCH_SETTINGS_SET';
const FETCH_LOAD_SEARCH_SETTINGS = 'FETCH_LOAD_SEARCH_SETTINGS';
const SET_VIEW_OPTION = 'SET_VIEW_OPTION';
const SET_LAST_VISITED_PAGE = 'SET_LAST_VISITED_PAGE';
export const LOAD_SEARCH_SETTINGS_FETCHED = 'LOAD_SEARCH_SETTINGS_FETCHED';
const SET_MORE_FILTERS_EXPANDED_STATE = 'SET_MORE_FILTERS_EXPANDED_STATE';

export interface LoadSearchSettingsState {
  hasSettings: boolean;
  viewOption: WebViews;
  appViewOption: ViewChoice;
  previousViewOption: WebViews | undefined;
  homeLocation?: HomeLocation;
  lastViewedPage?: WebViewPages;
  isMoreFiltersExpanded: boolean;
}

interface PartialState {
  loadSearchSettings: LoadSearchSettingsState;
}

interface SetHomeLocationAction extends Action {
  location: LoadLocationCity;
}
interface LoadSearchSettingsSetAction extends Action {
  loadSearchSettings?: LoadSearchSettings;
  error?: ApiError;
}
interface SetViewOptionAction extends Action {
  viewOption: ViewChoice;
  webPage?: WebViewPages;
}
interface LastViewPageAction extends Action {
  webPage: WebViewPages;
}

interface SetMoreFiltersExpandedStateAction extends Action {
  isExpanded: boolean;
}

type LoadSearchSettingsResponse = ResponseAction<LoadSearchSettings>;

export const setLocation = (location: HomeLocation) => ({
  type: SET_HOME_LOCATION,
  location: location,
});

export const fetchLoadSearchSettings = () => ({
  type: FETCH_LOAD_SEARCH_SETTINGS,
});

export const setLastVisitedPage = (page: WebViewPages): LastViewPageAction => ({
  type: SET_LAST_VISITED_PAGE,
  webPage: page,
});

export const setViewOption = (viewOption: ViewChoice, webPage?: WebViewPages): SetViewOptionAction => ({
  type: SET_VIEW_OPTION,
  viewOption: viewOption,
  webPage: webPage,
});

export const setMoreFiltersExpandedState = (isExpanded: boolean): SetMoreFiltersExpandedStateAction => ({
  type: SET_MORE_FILTERS_EXPANDED_STATE,
  isExpanded: isExpanded,
});

const InitialView: WebViews = {
  findLoads: ListView,
  myLoads: CardView,
  loadAvailability: CardView,
  brokerLoads: ListView,
  companySearch: CardView,
};

const loadSearchSettingsInitialState: LoadSearchSettingsState = {
  hasSettings: false,
  homeLocation: undefined,
  viewOption: InitialView,
  appViewOption: CardView,
  previousViewOption: undefined,
  lastViewedPage: undefined,
  isMoreFiltersExpanded: false,
};
const createViewOption = (payload: LoadSearchSettings) => {
  const viewFindLoads = find(KeysView, { title: payload.webUiLayout });
  const viewMyLoads = find(KeysView, {
    title: payload.webMyLoadsUiLayout,
  });
  const viewLoadAvailability = find(KeysView, {
    title: payload.webAvailabilityUiLayout,
  });
  const viewBrokerLoads = find(KeysViewWithoutMap, {
    title: payload.webBrokerUiLayout,
  });
  return {
    findLoads: viewFindLoads ? viewFindLoads : ListView,
    myLoads: viewMyLoads ?? CardView,
    loadAvailability: viewLoadAvailability ? viewLoadAvailability : CardView,
    brokerLoads: viewBrokerLoads ? viewBrokerLoads : ListView,
  };
};
const updateViewField = (currentViewOption: WebViews, action: SetViewOptionAction) => {
  const viewOption = clone(currentViewOption);
  switch (action.webPage) {
    case WebViewPages.FindLoads:
      {
        viewOption.findLoads = action.viewOption;
      }
      break;
    case WebViewPages.MyLoads:
      {
        viewOption.myLoads = action.viewOption;
      }
      break;
    case WebViewPages.LoadAvailability:
      {
        viewOption.loadAvailability = action.viewOption;
      }
      break;
    case WebViewPages.BrokerLoads:
      {
        viewOption.brokerLoads = action.viewOption;
      }
      break;
    case WebViewPages.CompanySearch:
      {
        viewOption.companySearch = action.viewOption;
      }
      break;
  }
  return viewOption;
};

export const loadSearchSettingsReducer = createMergedReducer<LoadSearchSettingsState>(loadSearchSettingsInitialState, [
  {
    [LOAD_SEARCH_SETTINGS_FETCHED]: (state, action) => {
      const loadSearchSettingsAction = action as LoadSearchSettingsResponse;
      if (loadSearchSettingsAction.response.success && loadSearchSettingsAction.response.payload) {
        const viewOption = createViewOption(loadSearchSettingsAction.response.payload);
        return {
          ...state,
          hasSettings: true,
          homeLocation: locationFromResponse(loadSearchSettingsAction.response.payload),
          viewOption: { ...viewOption, companySearch: state.viewOption.companySearch },
          appViewOption:
            find(KeysWithoutList, { title: loadSearchSettingsAction.response.payload.appUiLayout }) ?? CardView,
          previousViewOption: state.viewOption,
        };
      }
      return state;
    },
    [LOAD_SEARCH_SETTINGS_SET]: (state, action) => {
      const { loadSearchSettings, error } = action as LoadSearchSettingsSetAction;

      if (error || !loadSearchSettings) {
        return state;
      }

      const viewOption = createViewOption(loadSearchSettings);
      return {
        ...state,
        hasSettings: true,
        homeLocation: locationFromResponse(loadSearchSettings),
        viewOption: { ...viewOption, companySearch: state.viewOption.companySearch },
        appViewOption: find(KeysWithoutList, { title: loadSearchSettings.appUiLayout }) ?? CardView,
        previousViewOption: state.viewOption,
      };
    },
    [SET_LAST_VISITED_PAGE]: (state, action) => {
      const setViewOptionAction = action as LastViewPageAction;
      if (setViewOptionAction.webPage) {
        return {
          ...state,
          lastViewedPage: setViewOptionAction.webPage,
        };
      }
      return state;
    },
    [SET_VIEW_OPTION]: (state, action) => {
      const setViewOptionAction = action as SetViewOptionAction;
      if (setViewOptionAction.viewOption) {
        return setViewOptionAction.webPage
          ? {
              ...state,
              viewOption: updateViewField(state.viewOption, setViewOptionAction),
              previousViewOption: state.viewOption,
            }
          : { ...state, appViewOption: setViewOptionAction.viewOption };
      }
      return state;
    },
    [SET_MORE_FILTERS_EXPANDED_STATE]: (state, action) => {
      const setAction = action as SetMoreFiltersExpandedStateAction;
      return { ...state, isMoreFiltersExpanded: setAction.isExpanded };
    },
  },
]);

const locationFromResponse = (response: LoadSearchSettings): HomeLocation | undefined => {
  const locationType = response.homeLocationType as LocationType;
  switch (locationType) {
    case LocationType.CITY:
      if (response.homeAddress) {
        return {
          type: LocationType.CITY,
          city: response.homeAddress.city,
          states: [response.homeAddress.state],
        };
      }
      break;
    case LocationType.ZIP:
      if (response.homeAddress) {
        return {
          type: LocationType.ZIP,
          zipCode: response.homeAddress.zipCode || '',
          city: response.homeAddress.city,
          states: [response.homeAddress.state],
        };
      }
      break;
    case LocationType.GEOLOCATION:
      if (response.homeGeolocation) {
        let city = '';
        let state = '';
        if (response.homeAddress) {
          city = response.homeAddress.city;
          state = response.homeAddress.state;
        }
        return {
          type: LocationType.GEOLOCATION,
          latitude: response.homeGeolocation.latitude,
          longitude: response.homeGeolocation.longitude,
          city: city,
          states: [state],
        };
      }
      break;
  }
  return undefined;
};
const loadSearchSettingResponseMapping = (response: ApiResponse123<LoadSearchSettings>) =>
  response.result<LoadSearchSettingsSetAction>(
    (result) => ({ type: LOAD_SEARCH_SETTINGS_SET, loadSearchSettings: result }),
    (error) => ({ type: LOAD_SEARCH_SETTINGS_SET, error: error })
  );

const setHomeLocation$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialState>,
  settingsClient: SettingsClient
) =>
  action$.ofType(SET_HOME_LOCATION).pipe(
    mergeMap$((action: SetHomeLocationAction) =>
      settingsClient.setHomeLocation$(action.location, state$.value.loadSearchSettings.hasSettings).pipe(
        mergeMap$((response: ApiResponse123<LoadSearchSettings>) => {
          if (!response.success && (response as ApiError).code === ApiErrorCode.LOAD_SEARCH_SETTINGS_ALREADY_EXIST) {
            return settingsClient.setHomeLocation$(action.location, true);
          }
          return of$(response);
        }),
        map$(loadSearchSettingResponseMapping)
      )
    )
  );

const setWebUiLayout$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialState>,
  settingsClient: SettingsClient
) =>
  action$.ofType(SET_VIEW_OPTION).pipe(
    mergeMap$((action: SetViewOptionAction) =>
      settingsClient
        .setUiLayout$(action.viewOption.field, state$.value.loadSearchSettings.hasSettings, action.webPage)
        .pipe(
          mergeMap$((response: ApiResponse123<LoadSearchSettings>) => {
            if (!response.success && (response as ApiError).code === ApiErrorCode.LOAD_SEARCH_SETTINGS_ALREADY_EXIST) {
              return settingsClient.setUiLayout$(action.viewOption.field, true, action.webPage);
            }
            return of$(response);
          }),
          map$(loadSearchSettingResponseMapping)
        )
    )
  );

const fetchSettings$ = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<PartialState>,
  settingsClient: SettingsClient
) =>
  action$.ofType(FETCH_LOAD_SEARCH_SETTINGS).pipe(
    mergeMap$(() => settingsClient.fetchLoadSearchSettings$()),
    mergeMap$((response) =>
      iif$(
        () => !response.success && response.httpStatus === 404,
        settingsClient
          .createLoadSearchSettings$({
            homeLocationType: state$.value.loadSearchSettings.homeLocation?.type,
            homeAddress: toAddress(state$.value.loadSearchSettings.homeLocation),
            webUiLayout: state$.value.loadSearchSettings.viewOption.findLoads.field,
            webAvailabilityUiLayout: state$.value.loadSearchSettings.viewOption.loadAvailability.field,
            webMyLoadsUiLayout: state$.value.loadSearchSettings.viewOption.myLoads.field,
            webBrokerUiLayout: state$.value.loadSearchSettings.viewOption.brokerLoads.field,
          })
          .pipe(
            map$((createSettingResponse) => createSettingResponse.resultDataResponse(LOAD_SEARCH_SETTINGS_FETCHED))
          ),
        of$(response.resultDataResponse(LOAD_SEARCH_SETTINGS_FETCHED))
      )
    )
  );

export const createHomeLocationEpic = (api: Api) => {
  const settingsClient = new SettingsClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<PartialState>) =>
    merge$(
      setHomeLocation$(action$, state$, settingsClient),
      fetchSettings$(action$, state$, settingsClient),
      setWebUiLayout$(action$, state$, settingsClient)
    );
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { PermissionsClient } from '@common/client/PermissionsClient';

import { createApiAction, EmptyResponse } from '../Base';
import { createMergedReducer } from '../ReduxHelper';

export interface Integrations {
  createdOn?: string;
  name?: string;
  id?: string;
  scopes?: [
    {
      name: string;
      description: string;
    },
  ];
}

export interface IntegrationsResponse {
  sessions: Integrations[] | undefined;
}

export interface LoginSessions {
  createdOn?: string;
  name?: string;
  id?: string;
  userAgent?: string;
  ip?: string;
  lastUsedOn?: string;
  isCurrentSession?: boolean;
}

export interface LoginSessionsResponse {
  sessions: LoginSessions[] | undefined;
}

export interface PermissionsState {
  integrations: Integrations[];
  sessions: LoginSessions[];
  isFetchingIntegrations: boolean;
  isFetchingSessions: boolean;
  isInvalidatingSession: boolean;
  invalidationError?: boolean;
}

const initialState: PermissionsState = {
  integrations: [],
  sessions: [],
  isFetchingIntegrations: false,
  isFetchingSessions: false,
  isInvalidatingSession: false,
  invalidationError: false,
};

const fetchIntegrationsAction = createApiAction<undefined, IntegrationsResponse>('FETCH_INTEGRATIONS');

const fetchLoginSessionsAction = createApiAction<undefined, LoginSessionsResponse>('FETCH_LOGIN_SESSIONS');

const invalidateSessionAction = createApiAction<string, EmptyResponse>('INVALIDATE_LOGIN_SESSION');

export const fetchIntegrations = () => fetchIntegrationsAction.fetchAction(undefined);

export const fetchLoginSessions = () => fetchLoginSessionsAction.fetchAction(undefined);

export const invalidateSession = (sessionID: string) => invalidateSessionAction.fetchAction(sessionID);

export const permissionsReducer = createMergedReducer(initialState, [
  fetchIntegrationsAction.initiateCase((state) => {
    state.isFetchingIntegrations = true;
  }),

  fetchIntegrationsAction.completeCase((state, action) => {
    if (action.response.success) {
      state.integrations = action.response.payload.sessions ?? [];
    } else {
      state.integrations = [];
    }
    state.isFetchingIntegrations = false;
  }),

  fetchLoginSessionsAction.initiateCase((state) => {
    state.isFetchingSessions = true;
  }),

  fetchLoginSessionsAction.completeCase((state, action) => {
    if (action.response.success) {
      state.sessions = action.response.payload.sessions ?? [];
    } else {
      state.sessions = [];
    }
    state.isFetchingSessions = false;
  }),

  invalidateSessionAction.initiateCase((state) => {
    state.isInvalidatingSession = true;
  }),

  invalidateSessionAction.completeCase((state, action) => {
    state.invalidationError = !action.response.success;
    state.isInvalidatingSession = false;
  }),
]);

export const createPermissionsEpic = (api: Api) => {
  const client = new PermissionsClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchIntegrationsAction.createEpic$(action$, client.fetchIntegrations$),
      fetchLoginSessionsAction.createEpic$(action$, client.fetchLoginSessions$),
      invalidateSessionAction.createEpic$(action$, (request) => client.invalidateSession$(request))
    );
};

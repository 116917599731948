import React from 'react';

import styled from 'styled-components';

import { treatUndefinedAsZero } from '@common/helper';
import { StyledHeader } from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';

const StyledPickerWarning = styled(StyledHeader)`
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${Color.GRAY_DARK};
  font-size: ${FontSize.Body}px;
`;

const StyledPickerError = styled(StyledPickerWarning)`
  color: ${Color.RED_APPLE};
`;

interface Props {
  hasReachedMaxCount: boolean;
  maxCount: number | undefined;
  selectedCount: number | undefined;
}

export const StatesMaxSelectionWarning: React.FC<Props> = ({ hasReachedMaxCount, maxCount, selectedCount }) => {
  const warningMessage = t(T.modalPanels_LocationPicker_WarningMessage, {
    selectedCount: treatUndefinedAsZero(selectedCount),
    maxCount: maxCount,
  });
  if (maxCount !== undefined) {
    return hasReachedMaxCount ? (
      <StyledPickerError id="max_selection_error">{warningMessage}</StyledPickerError>
    ) : (
      <StyledPickerWarning id="max_selection_warning">{warningMessage}</StyledPickerWarning>
    );
  }
  return null;
};

import { Action } from 'redux';

import { RibbonMessage } from '@common/model';

import { createReducer } from '../ReduxHelper';

const OPEN_RIBBON = 'OPEN_RIBBON';

const CLOSE_RIBBON = 'CLOSE_RIBBON';
export enum RibbonMessageType {
  Info,
  Error,
  Warning,
  Success,
}
interface OpenRibbon extends Action {
  messages: RibbonMessage[];
  messageType: RibbonMessageType;
}

export const openRibbon = (
  msg: RibbonMessage[],
  messageType: RibbonMessageType = RibbonMessageType.Warning
): OpenRibbon => ({
  type: OPEN_RIBBON,
  messages: msg,
  messageType: messageType,
});

export function closeRibbon(): Action {
  return {
    type: CLOSE_RIBBON,
  };
}

export interface RibbonState {
  isOpened: boolean;
  messages: RibbonMessage[];
  messageType: RibbonMessageType;
}

const initialState: RibbonState = {
  isOpened: false,
  messages: [{ message: '', isPhoneLink: false }],
  messageType: RibbonMessageType.Warning,
};

export const ribbonReducer = createReducer(initialState, {
  [OPEN_RIBBON]: (state, action: OpenRibbon) => {
    state.isOpened = true;
    state.messages = action.messages;
    state.messageType = action.messageType;
  },
  [CLOSE_RIBBON]: (state) => {
    state.isOpened = false;
  },
});

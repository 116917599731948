import { PayRateUnit } from './';
import { Load } from './Load';
import { BaseLocation } from './Location';

//@TODO: clean up states that don't exist in V2
export enum BidStatus {
  ActionRequired = 'ActionRequired',
  Pending = 'Pending',
  Accepted = 'Accepted',
  Assigned = 'Assigned',
  Withdrawn = 'Withdrawn',
  Rejected = 'Rejected',
  Confirmed = 'Confirmed',
  AcceptanceWithdrawn = 'AcceptanceWithdrawn',
  Canceled = 'Canceled',
}

export enum BidAction {
  OfferAck = 'OfferAck',
  Accept = 'Accept',
  AcceptAck = 'AcceptAck',
  Reject = 'Reject',
  RejectAck = 'RejectAck',
  Withdraw = 'Withdraw',
  WithdrawAck = 'WithdrawAck',

  WithdrawAcceptance = 'WithdrawAcceptance',
  WithdrawAcceptanceAck = 'WithdrawAcceptanceAck',
  Cancel = 'Cancel',
  CancelAck = 'CancelAck',
  Confirm = 'Confirm',
  ConfirmAck = 'ConfirmAck',
}

export enum BiddingRole {
  Carrier = 'Carrier',
  Broker = 'Broker',
}

interface BidContactInfo {
  contactId: string;
  company: {
    id: string;
    name: string;
  };
}

export interface BidLoadRate {
  amount: number;
  type: PayRateUnit;
}

export interface BidLoadInfo {
  id: string;
  postReference?: string;
  rate?: BidLoadRate;
  originLocation?: BaseLocation;
  destinationLocation?: BaseLocation;
}

export interface Bid {
  bidId: string;
  fromCarrier: boolean;
  status: BidStatus;
  price?: number;
  expirationDate?: string;
  mostRecentActivity?: string;
  offerMadeByMe: boolean;
}

export interface BidSummary extends Bid {
  isViewed: boolean;
  load?: BidLoadInfo;
  otherParty?: BidContactInfo;
}

export interface BidDetails extends BidSummary {
  previousBidId?: string;
  isMostRecentOffer: boolean;
}

export interface BidsSummariesRequest {
  token?: string;
  roles?: Array<BiddingRole>;
  status?: Array<BidStatus>;
}

export interface BidSummariesResponse {
  bids: BidSummary[];
  hasMore: boolean;
  token?: string;
}

export interface LoadBidsRequest {
  loadId: string;
  token?: string;
  carrierId?: string;
}

export interface LoadBidsResponse {
  bids: Bid[];
  hasMore: boolean;
  token?: string;
}

export interface BidActionRequest {
  bidId: string;
  action: BidAction;
}

export interface PostBidRequest {
  loadId: string;
  previousBidId?: string;
  carrierId?: string;
  price?: number;
  expirationTime?: number;
}

export interface BiddingSummaryRequest {
  role: BiddingRole;
}

export interface BiddingSummaryResponse {
  offerUpdatesCount: number;
}

export interface CarrierInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  companyName?: string;
  phone?: string;
  phoneExtension?: string;
  docketNumber?: string;
  usdotNumber?: string;
  isCarrierIdentityVerified?: boolean; // true if user is DOT verified
}

export const LoadFieldsBiddingOnly =
  'equipments,commodity,notes,rate,mileage,rateCheck,metadata,postReference,computedMileage,rateNegotiations,vendorInfo,originLocation,destinationLocation';

export type LoadWithDataForBiddingOnly = Pick<
  Load,
  | 'id'
  | 'equipments'
  | 'commodity'
  | 'notes'
  | 'rate'
  | 'mileage'
  | 'rateCheck'
  | 'metadata'
  | 'postReference'
  | 'computedMileage'
  | 'rateNegotiations'
  | 'vendorInfo'
  | 'originLocation'
  | 'destinationLocation'
>;

export type UpdateBidEvent = { bids: Array<{ loadId: string; bidId: string }> };

export enum UpdateBidEventType {
  NewBids,
  UpdatedBids,
}

import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled, { css, keyframes } from 'styled-components';

import { default as CloseIcon } from '@/icons/close.svg';
import { FeedSection, setSelectedFeedSection } from '@/reduxStore/epic/FeedEpic';
import { Routes } from '@/router/Routes';
import { getFeedMappingDataForUrl } from '@component/feed/FeedMappingHelper';
import { ActionIcon } from '@component/icon/ActionIcon';
import { Color } from '@style/Color';
import { ElementSize, FontLineHeight, FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useCsv, useIsSmallerThanMediumDesktopView, useSelector, useWindowWidth } from '@util/hooks';
import { LINK_FAQ_PAGE } from '@util/Link';
import { Width } from '@util/MediaQueries';

import { default as FeedIconDark } from './icon-feed-dark.svg?react';
import { default as FeedIconSelected } from './icon-feed-selected.svg?react';
import { default as FeedIcon } from './icon-feed.svg?react';
import { default as HelpIconDark } from './icon-help-dark.svg?react';
import { default as HelpIconSelected } from './icon-help-selected.svg?react';
import { default as HelpIcon } from './icon-help.svg?react';
import { default as YoutubeIcon } from './icon-youtube.svg?react';

const BUTTONS_TRANSITION_MS = 700;

export enum ColorType {
  Dark,
  Light,
}

interface PopupButtonsProps {
  isWithBorder: boolean;
}

interface FeedButtonProps {
  isMobileView: boolean;
  isButtonOpened: boolean;
  isWithAnimation: boolean;
}

interface ComponentProps extends PopupButtonsProps {
  colorType: ColorType;
  isWithButtonsAnimation: boolean;
  shouldRemovePopupTitle?: boolean;
}

type Props = ComponentProps & RouteComponentProps;

const FeedControllerHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: ${ElementSize.ListRowHeight}px;
  border-bottom: ${(props: PopupButtonsProps) => (props.isWithBorder ? `1px solid ${Color.GRAY_SMOKE}` : 0)};
`;

const FeedControllerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const show = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const FeedSectionButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props: FeedButtonProps) => (props.isMobileView ? Spacing.TwoSpaces : Spacing.InterSection)}px;
  cursor: pointer;
  ${(props: FeedButtonProps) => getButtonsAnimation(props)};
`;

const FeedSectionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${Spacing.ElementPaddingHorizontal}px;
`;

const TitleText = styled.div`
  font-size: ${FontSize.ModalTitle}px;
  font-weight: ${FontWeight.Bold};
  line-height: ${FontLineHeight.Header}px;
`;

const FeedPopupButtonsComponent: React.FC<Props> = ({
  colorType,
  location,
  isWithBorder,
  isWithButtonsAnimation,
  shouldRemovePopupTitle,
}) => {
  const selectedFeedSection = useSelector((state) => state.feedState.selectedFeedSection);
  const mappingFile = useSelector((state) => state.feedState.mappingFile);
  const isMappingFileFetched = useSelector((state) => state.feedState.isMappingFileFetched);
  const [isFeedButtonDisplayed, setIsFeedButtonDisplayed] = useState(false);
  const [isVideoButtonDisplayed, setIsVideoButtonDisplayed] = useState(false);
  const [isHelpButtonDisplayed, setIsHelpButtonDisplayed] = useState(false);
  const csvData = useCsv(mappingFile);
  const dispatch = useDispatch();
  const isSmallerThanMediumDesktop = useIsSmallerThanMediumDesktopView();
  const width = useWindowWidth();
  const shouldHideButtonLabels = width < Width.xs;

  const isDashboardAndMobileView = Routes.DASHBOARD === location.pathname && isSmallerThanMediumDesktop;
  const selectFeedSection = (feedSection: FeedSection | undefined) => () => {
    dispatch(setSelectedFeedSection(feedSection));
  };
  const mappingData = useMemo(() => {
    return getFeedMappingDataForUrl(csvData, location.pathname);
  }, [csvData, location.pathname]);
  useEffect(() => {
    setIsFeedButtonDisplayed(
      isSmallerThanMediumDesktop ||
        shouldShowFeedButton(isMappingFileFetched, mappingData?.isFeedButtonEnabled, isFeedButtonDisplayed)
    );
    setIsVideoButtonDisplayed(
      shouldShowFeedButton(isMappingFileFetched, mappingData?.isVideoButtonEnabled, isVideoButtonDisplayed)
    );
    setIsHelpButtonDisplayed(
      shouldShowFeedButton(isMappingFileFetched, mappingData?.isHelpButtonEnabled, isHelpButtonDisplayed)
    );
  }, [isMappingFileFetched, mappingData, isSmallerThanMediumDesktop]);

  return (
    <FeedControllerHeader isWithBorder={isWithBorder}>
      {colorType === ColorType.Light ? (
        <FeedSectionTitle>
          <ActionIcon
            id={'close_feed+icon'}
            src={CloseIcon}
            alt={'close_feed'}
            onClick={selectFeedSection(undefined)}
            style={{ marginRight: 6 }}
          />
          {shouldRemovePopupTitle ? null : <TitleText>{getFeedPopupTitle(selectedFeedSection)}</TitleText>}
        </FeedSectionTitle>
      ) : null}
      <FeedControllerButtons>
        {isFeedButtonDisplayed ? (
          <Delayed>
            <FeedSectionButton
              onClick={selectFeedSection(FeedSection.Feed)}
              isMobileView={isSmallerThanMediumDesktop}
              isButtonOpened={isDashboardAndMobileView || isFeedButtonDisplayed}
              isWithAnimation={isWithButtonsAnimation}
            >
              {getSectionIcon(
                colorType,
                selectedFeedSection === FeedSection.Feed,
                FeedIcon,
                FeedIconDark,
                FeedIconSelected
              )}

              <div style={{ color: getTextColor(colorType, FeedSection.Feed, selectedFeedSection), marginLeft: 3 }}>
                {shouldHideButtonLabels ? null : t(T.feed_Feed)}
              </div>
            </FeedSectionButton>
          </Delayed>
        ) : null}
        {isVideoButtonDisplayed ? (
          <Delayed>
            <FeedSectionButton
              onClick={selectFeedSection(FeedSection.Videos)}
              isMobileView={isSmallerThanMediumDesktop}
              isButtonOpened={isVideoButtonDisplayed}
              isWithAnimation={isWithButtonsAnimation}
            >
              <YoutubeIcon />

              <div style={{ color: getTextColor(colorType, FeedSection.Videos, selectedFeedSection), marginLeft: 3 }}>
                {shouldHideButtonLabels ? null : t(T.feed_Videos)}
              </div>
            </FeedSectionButton>
          </Delayed>
        ) : null}
        {isHelpButtonDisplayed ? (
          <Delayed>
            <FeedSectionButton
              onClick={() => window.open(mappingData?.helpTabSourceRoute ?? LINK_FAQ_PAGE, '_blank')}
              isMobileView={isSmallerThanMediumDesktop}
              isButtonOpened={isHelpButtonDisplayed}
              isWithAnimation={isWithButtonsAnimation}
            >
              {getSectionIcon(colorType, false, HelpIcon, HelpIconDark, HelpIconSelected)}

              <div style={{ color: colorType === ColorType.Dark ? Color.WHITE : Color.LIGHT_BLACK, marginLeft: 3 }}>
                {shouldHideButtonLabels ? null : t(T.feed_Help)}
              </div>
            </FeedSectionButton>
          </Delayed>
        ) : null}
      </FeedControllerButtons>
    </FeedControllerHeader>
  );
};

const Delayed: React.FC = (props) => {
  const [shouldDisplay, setShouldDisplay] = useState(false);
  useEffect(() => {
    const timeoutid = setTimeout(() => {
      setShouldDisplay(true);
    }, 1000);
    return () => {
      clearTimeout(timeoutid);
    };
  }, []);
  return shouldDisplay ? <>{props.children}</> : null;
};

const getTextColor = (colorType: ColorType, section: FeedSection, selectedSection: FeedSection | undefined) => {
  if (colorType === ColorType.Dark) {
    return Color.WHITE;
  }
  if (section === selectedSection) {
    return Color.ORANGE_MAIN;
  }
  return Color.LIGHT_BLACK;
};

const getSectionIcon = (
  colorType: ColorType,
  isSelected: boolean,
  LightIcon: React.FC,
  DarkIcon: React.FC,
  SelectedIcon: React.FC
) => {
  if (colorType === ColorType.Dark) {
    return <DarkIcon />;
  }
  if (isSelected) {
    return <SelectedIcon />;
  }
  return <LightIcon />;
};

const getFeedPopupTitle = (feedSection: FeedSection | undefined): string => {
  switch (feedSection) {
    case FeedSection.Feed:
      return t(T.feed_Feed);
    case FeedSection.Videos:
      return t(T.feed_Videos);
    default:
      return '';
  }
};

const shouldShowFeedButton = (
  isMappingFileFetched: boolean,
  buttonDataFromMapping: boolean | undefined,
  isCurrentlyDisplayed: boolean
): boolean => {
  if (!isMappingFileFetched && isCurrentlyDisplayed) {
    return true;
  }
  if (isMappingFileFetched && buttonDataFromMapping === undefined) {
    return true;
  }
  return isMappingFileFetched && !!buttonDataFromMapping;
};

const getButtonsAnimation = (buttonProps: FeedButtonProps) => {
  if (buttonProps.isWithAnimation) {
    return css`
      animation: ${show} ${BUTTONS_TRANSITION_MS}ms linear;
    `;
  }
  return '';
};

export const FeedPopupButtons = withRouter(FeedPopupButtonsComponent);

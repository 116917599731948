import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { closeMenu, setMenuLayout } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { UserType } from '@common/model';
import { useSelector } from '@util/hooks';
import { Width } from '@util/MediaQueries';
import { hasLoginCookies } from '@webApi/CookiesHelper';

import { MenuFooter } from './MenuFooter';
import { MenuNav } from './MenuNav';
import { MenuPreferenceSelect } from './MenuPreferenceSelect';
import { MenuUserProfile } from './MenuUserProfile';
import { useNavGroups } from './NavigationMenuHelper';

interface Props {
  isExpanded: boolean;
}

export const SideMenuContent: React.FC<Props> = ({ isExpanded }) => {
  const navGroups = useNavGroups(isExpanded);
  const loginFailed = useSelector((state) => state.connectionError.loginFailed);
  const uiMenuLayout = useSelector((state) => state.applicationSettings.uiMenuLayout);
  const isIntegrator = useSelector((state) => state.user.profile?.payload?.isIntegrator);
  const isBrokerCarrier = useSelector((state) => state.user.userType === UserType.BrokerCarrier);

  const isLoggedIn = hasLoginCookies() && !loginFailed;
  const isDisplayingToggle = isLoggedIn && isBrokerCarrier;

  const dispatch = useDispatch();

  const closeMenuIfPossible = () => {
    if (!isWidthLargeEnoughForExpandedMenu()) {
      dispatch(closeMenu());
    }
  };

  useEffect(() => {
    closeMenuIfPossible();
  }, []);

  return (
    <>
      {isDisplayingToggle ? (
        <MenuPreferenceSelect
          setMenuLayout={(menuLayout) => dispatch(setMenuLayout(menuLayout))}
          menuLayout={uiMenuLayout}
          isMenuOpen={isExpanded}
          isIntegrator={isIntegrator}
          onClose={closeMenuIfPossible}
        />
      ) : null}
      {isLoggedIn ? (
        <MenuUserProfile
          isDisplayingToggle={isDisplayingToggle}
          isMenuOpen={isExpanded}
          onClick={closeMenuIfPossible}
        />
      ) : null}
      <MenuNav navGroups={navGroups} isMenuOpen={isExpanded} onClick={closeMenuIfPossible} />
      <MenuFooter isMenuOpen={isExpanded} />
    </>
  );
};

export const isWidthLargeEnoughForExpandedMenu = (minWidth?: number) =>
  window.innerWidth > (minWidth ? minWidth : Width.sideMenuExpansion);

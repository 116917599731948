import { createDefaultMyLoadsRequest, LoadProgress, MyLoadsRequest } from '@common/model';
import { TotalCountState } from '@common/redux/epic/MyLoadsEpic';
import { t, T } from '@translate';

export enum Filter {
  Viewed = 'viewed',
  Saved = 'saved',
  Called = 'called',
  Booked = 'booked',
  ScheduledForPickup = 'scheduled_for_pickup',
  PickupComplete = 'pickup_complete',
  LoadEnRoute = 'load_en_route',
  Delivered = 'delivered',
  Contacted = 'contacted',
  Hidden = 'hidden',
}

export const MYLOADS_FILTERS_LIST = [
  Filter.Saved,
  Filter.Contacted,
  Filter.Booked,
  Filter.PickupComplete,
  Filter.Delivered,
  Filter.Hidden,
];

const applyMyLoadsFilter = (request: MyLoadsRequest, filter: Filter) => {
  switch (filter) {
    case Filter.Viewed:
      request.isViewed = true;
      break;
    case Filter.Saved:
      request.isSaved = true;
      break;
    case Filter.Called:
      request.isCalled = true;
      break;
    case Filter.Contacted:
      request.isContacted = true;
      break;
    case Filter.Booked:
      request.progressList = [LoadProgress.Booked, LoadProgress.ScheduledForPickup];
      break;
    case Filter.ScheduledForPickup:
      request.progress = LoadProgress.ScheduledForPickup;
      break;
    case Filter.PickupComplete:
      request.progressList = [LoadProgress.PickupComplete, LoadProgress.LoadEnRoute];
      break;
    case Filter.LoadEnRoute:
      request.progress = LoadProgress.LoadEnRoute;
      break;
    case Filter.Delivered:
      request.progress = LoadProgress.Delivered;
      break;
    case Filter.Hidden:
      request.isHidden = true;
  }

  return request;
};

export const myLoadsRequestFromFilter = (filter: Filter): MyLoadsRequest => {
  const request = createDefaultMyLoadsRequest();
  return applyMyLoadsFilter(request, filter);
};

export const filterToPropertyKey = (filter: Filter) => {
  switch (filter) {
    default:
    case Filter.Viewed:
      return 'viewed';
    case Filter.Saved:
      return 'saved';
    case Filter.Called:
      return 'called';
    case Filter.Contacted:
      return 'contacted';
    case Filter.Booked:
      return 'booked';
    case Filter.ScheduledForPickup:
      return 'scheduledForPickup';
    case Filter.PickupComplete:
      return 'pickupComplete';
    case Filter.LoadEnRoute:
      return 'loadEnRoute';
    case Filter.Delivered:
      return 'delivered';
    case Filter.Hidden:
      return 'hidden';
  }
};

export const totalCountForFilter = (filter: Filter, totalCountState: TotalCountState) => {
  return totalCountState[filterToPropertyKey(filter)];
};

export const headerNameFromFilter = (filter: Filter) => {
  switch (filter) {
    case Filter.Viewed:
      return t(T.common_myLoads_headerTitles_viewedLoads);
    case Filter.Saved:
      return t(T.common_myLoads_headerTitles_savedLoads);
    case Filter.Called:
      return t(T.common_myLoads_headerTitles_calledLoads);
    case Filter.Contacted:
      return t(T.common_myLoads_headerTitles_contactedLoads);
    case Filter.Booked:
      return t(T.common_myLoads_headerTitles_bookedLoads);
    case Filter.ScheduledForPickup:
      return t(T.common_myLoads_headerTitles_scheduledLoads);
    case Filter.PickupComplete:
      return t(T.common_myLoads_headerTitles_pickupLoads);
    case Filter.LoadEnRoute:
      return t(T.common_myLoads_loadEnRoute);
    case Filter.Delivered:
      return t(T.common_myLoads_headerTitles_deliveredLoads);
    case Filter.Hidden:
      return t(T.common_myLoads_headerTitles_hiddenLoads);
    default:
      return '';
  }
};

export const isLoadUnbookedAndAvailable = (loadProgress: LoadProgress | undefined): boolean =>
  loadProgress === LoadProgress.LoadAvailable;

export const getFilterFromLoadProgress = (loadProgress: LoadProgress | undefined): Filter | undefined => {
  switch (loadProgress) {
    case LoadProgress.Booked:
      return Filter.Booked;
    case LoadProgress.ScheduledForPickup:
      return Filter.ScheduledForPickup;
    case LoadProgress.LoadEnRoute:
      return Filter.LoadEnRoute;
    case LoadProgress.Delivered:
      return Filter.Delivered;
    case LoadProgress.PickupComplete:
      return Filter.PickupComplete;
    default:
      return undefined;
  }
};

/** Standard Request Options */
export const authenticationToApiOptions = (authenticated: boolean): ApiOptions => ({
  allowUnauthenticated: !authenticated,
  shouldPreventLoginRedirect: !authenticated,
});

export enum LBHeaders {
  Referrer = '123LB-Referrer',
  FlowID = '123LB-FD',
  ApiVersion = '123LB-Api-Version',
  CorrelationId = '123LB-Correlation-Id',
  BackgroundStatus = '123LB-is-background',
  DeviceID = '123LB-BID',
}

export interface ApiOptions {
  /** If true: Use the access token if possible.
   *
   * If user not logged in, does not attempt to login.
   * NOTE: Access token needs to be refreshed if possible.
   * If the refresh failed, does as if the user was logged out. (no access token)
   * Used on endpoints that supports both authenticated and anonymous access.
   * Meant to be used in areas like the 'Shared Loads' public web page where
   * the user might optionally be logged in.
   *
   * Defaults to false
   */
  allowUnauthenticated?: boolean;

  /** If true: Do not redirect to login on 401 failure.
   *
   * Refresh token might need refresh, but the 'final' failure should not redirect.
   * Used on endpoints that require authentication in public pages
   * where the user might optionally be logged in (like /profile)
   *
   * Defaults to false
   */
  shouldPreventLoginRedirect?: boolean;

  /**
   * true to "silently stop" ongoing requests (with matching cancelTag) before starting this one.
   *
   */
  //cancelAllPrevious?: boolean;

  /** If true: Do not automatically show Api failures messages.
   *
   * Else it will show a popup with the error message from the API.
   * This ONLY applies to errors coming from the Api. Most 5xx and 4xx.
   * It does not apply to Network failures.
   *
   * NOTE: Pending Actions will be executed (and shown) regardless of this value.
   *
   * Defaults to false
   */
  silentApiFailure?: boolean;

  /** If true: Do not automatically show Network failures messages.
   *
   * Else it will show a popup with a network unavailable / timeout error message.
   * This ONLY applies when there is essentially no response from the server.
   * Either a Timeout or some network issue like internet connectivity issues, Host unreachable, etc.
   * It does not apply to failures coming from the API (with an http status code).
   *
   * Defaults to false
   */
  silentNetworkFailure?: boolean;

  /** if true: Allow extended time to perform this request.
   *
   * Extended time is 70 seconds. If false, it's 18 seconds.
   *
   * Defaults to false
   */
  longTimeout?: boolean;

  /** if true: force a refresh token before calling the end point.
   * Defaults to false
   */
  forceRefreshToken?: boolean;

  /** if true: do not automatically handle special 503 errors. It will handle
   *  it following the silentNetworkFailure option.
   *
   * 503 errors typically means the whole infrastructure is down.
   * At that point, the DNS might have been redirected into another
   * temporary host that contains information about the issue.
   * The api should be "pinged" in the background until a non-503 response is received at which
   * point, you stop showing the webview and "route" the app to the begining.
   *
   * Defaults to false
   */
  //no503Handling?: boolean;
  cancelGroup?: string;
}

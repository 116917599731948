import { PostTruckAvailabilityForm } from '@/reduxStore/reducer/PostTruckAvailabilityReducer';
import { LoadSearchRequest } from '@common/model';

export type FormPickersRequest = LoadSearchRequest | PostTruckAvailabilityForm;

export enum PickerViewType {
  PickUp = 'PickUp',
  DropOff = 'DropOff',
  Dates = 'Dates',
}

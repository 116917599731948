import { AppVersion } from './AppVersionHelper';
import { LBConfigType } from './LBConfigType';

const ClientLBConfiguration = (await import('../build_src/clientConfig.mjs')).default as any;

export class AppConfig {
  public static LBConfig: LBConfigType = ClientLBConfiguration;
  public static MC_URL = 'http://li-public.fmcsa.dot.gov/LIVIEW/pkg_carrquery.prc_carrlist?s_prefix=MC&n_docketno=';
  public static USDOT =
    'http://www.safersys.org/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&original_query_param=NAME&query_string=';
  public static NAME = '123Loadboard'; // TODO: Should also validate the location of the constant with Bernard.
  public static APPLICATION = 'Website-React';
  public static BUGSNAG_API_KEY = '1d2e9b8cbba22946c2b7d596686afb9e';

  /** use this to display the version to the user */
  public static getAppVersionDisplay(): string {
    // use 4 zeros for layout purposes
    return AppVersion + '.' + (AppConfig.LBConfig.buildNumber || '0000');
  }

  /** use this when the version is required as an internal "key" (e.g. bugsnag) */
  public static getAppVersion(): string {
    return AppVersion + '.' + AppConfig.LBConfig.buildNumber;
  }

  public static getLoginLink(redirect?: string): string {
    const url = new URL(AppConfig.LBConfig.LoginUrl);
    url.searchParams.append('rd', redirect ?? window.location.href);
    return url.toString();
  }

  public static getLoginLinkWithEmail(email: string) {
    const url = new URL(AppConfig.LBConfig.LoginUrl);
    if (email) url.searchParams.append('email_address', email);
    return url.toString();
  }

  // This is the 'external' logout URL, normally the "Logout" menu entry
  // should go to "/logout" which would clear the local cookies then
  // redirect to the external logout
  // (handled in member-server.js)
  public static getLogoutLink(redirectURL?: string) {
    const url = new URL(AppConfig.LBConfig.LogoutUrl);
    if (redirectURL) url.searchParams.append('rd', redirectURL);
    return url.toString();
  }
}

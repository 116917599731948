import React from 'react';

import styled from 'styled-components';

import { NO_INFORMATION_SYMBOL } from '@common/formatter';
import { formatPhoneNumber, isLoadCalled } from '@common/helper';
import { Load, Phone } from '@common/model';
import { MainButton } from '@component/buttons';
import { ContactButtonProps, ContactsButton } from '@component/buttons/ContactsButton';
import { T, t } from '@translate';
import icons from '@util/iconsConstants';

import { default as IconCalled } from './icon-called.svg';

interface ButtonProps extends PhoneProps {
  isPhoneButtonMaximized: boolean;
}
interface PhoneProps extends ContactButtonProps {
  onClick: () => void;
  onClose: () => void;
  shouldCropLabel: boolean;
}

const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Label = styled.span`
  font-weight: normal;
  margin-top: 3px;
  width: 100%;
  text-transform: none;
  white-space: pre-line;
  padding-right: 20px;
`;

const Icon = styled.img`
  margin-left: 2px;
`;
const PhoneNumberButton: React.FC<PhoneProps> = (props) => (
  <>
    <ButtonContent>
      <MainButton
        id="close"
        onClick={props.onClose}
        style={{ width: '40px', minWidth: '40px', borderRadius: '4px 0px 0px 4px' }}
      >
        <Icon src={icons.closeLight} />
      </MainButton>
      <MainButton
        id="call"
        onClick={props.onClick}
        style={{ maxWidth: '100%', width: '100%', borderRadius: '0px 4px 4px 0px' }}
      >
        <Label>{renderPhoneNumber(props.load?.dispatchPhone)}</Label>
      </MainButton>
    </ButtonContent>
  </>
);

export const PhoneButton: React.FC<ButtonProps> = (props) =>
  props.isPhoneButtonMaximized ? (
    <PhoneNumberButton {...props} />
  ) : (
    <ContactsButton
      id="ContactsButton"
      isFullSize={props.isFullSize}
      icon={{ regular: isLoadCalled(props.load) ? IconCalled : icons.call, disabled: icons.callDisabled }}
      title={renderCall(props.load, props.isFullSize, props.isLabelHidden, props.shouldCropLabel)}
      onClick={props.onClick}
    />
  );

export const renderPhoneNumber = (phone: Phone | undefined) => {
  if (phone && phone.number) {
    const phoneNumber = formatPhoneNumber(phone.number);
    const phoneExtension = phone.extension ? ` ${t(T.common_phone_ext)} ${phone.extension}` : '';

    return `${phoneNumber}${phoneExtension}`;
  } else {
    return `${NO_INFORMATION_SYMBOL}`;
  }
};

export const renderCall = (
  loadDetails: Load | undefined,
  isFullSize: boolean,
  isLabelHidden?: boolean,
  shouldCropLabel?: boolean
) => {
  return !isFullSize || isLabelHidden
    ? undefined
    : isLoadCalled(loadDetails) && !shouldCropLabel
      ? t(T.common_load_Call_again)
      : t(T.common_load_Call);
};

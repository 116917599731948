import { clone, findIndex, map } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { flatMap as flatMap$, map as map$ } from 'rxjs/operators';

import { ApiResponse123 } from '@common/api';
import { Api } from '@common/api/ApiService';
import { TrucksClient } from '@common/client/TrucksClient';
import {
  MAX_LOAD_SEARCH_TRUCK_WEIGHT,
  MAX_PLUS_TRUCK_LENGTH,
  MAX_PLUS_TRUCK_WEIGHT,
  MAX_TRUCK_LENGTH,
  MAX_TRUCK_WEIGHT,
  UserTruck,
  UserTruckFromServer,
} from '@common/model/Truck';
import { Response } from '@common/redux/Base';
import { standardApiEpic } from '@common/redux/epic/EpicHelper';

const FETCH_USER_TRUCKS = 'FETCH_USER_TRUCKS';
const USER_TRUCKS_FETCHED = 'USER_TRUCKS_FETCHED';
const FETCH_SINGLE_TRUCK = 'FETCH_SINGLE_TRUCK';
const SINGLE_TRUCK_FETCHED = 'SINGLE_TRUCK_FETCHED';
const SAVE_TRUCK = 'SAVE_TRUCK';
const EDIT_TRUCK = 'EDIT_TRUCK';
const DELETE_TRUCK = 'DELETE_TRUCK';
export const TRUCK_DELETED = 'TRUCK_DELETED';
const SET_ACTIVE_TRUCK = 'SET_ACTIVE_TRUCK';
const SET_SELECTED_TRUCK = 'SET_SELECTED_TRUCK';
export const TRUCK_SAVED = 'TRUCK_SAVED';
export const TRUCK_EDITED = 'TRUCK_EDITED';

type FetchTrucksResponse = Response<UserTruckFromServer[]>;
type TruckResponse = Response<UserTruckFromServer>;
type DeleteTruckResponse = Response<{ id: string; override?: boolean }>;

interface FetchUserTrucksResponseAction extends Action {
  response: FetchTrucksResponse;
}

interface FetchTrucksWithDeviceIdAction extends Action {
  withDeviceId?: boolean;
}

interface FetchSingleTruckAction extends Action {
  id: string;
}

interface DeleteSingleTruckAction extends Action {
  id: string;
  override?: boolean;
}
export interface DeleteSingleTruckResponseAction extends Action {
  response: DeleteTruckResponse;
}

interface SetCurrentTruckAction extends Action {
  truck: UserTruck | undefined;
}
export interface SingleTruckResponseAction extends Action {
  response: TruckResponse;
}

interface TruckMutatorAction extends Action {
  truck: Partial<UserTruck>;
}

export const fetchUserTrucks = (withDeviceId?: boolean): FetchTrucksWithDeviceIdAction => ({
  type: FETCH_USER_TRUCKS,
  withDeviceId: withDeviceId,
});
const fetchTrucksResponse = (response: FetchTrucksResponse): FetchUserTrucksResponseAction => ({
  type: USER_TRUCKS_FETCHED,
  response: response,
});
export const fetchTruck = (id: string): FetchSingleTruckAction => ({ type: FETCH_SINGLE_TRUCK, id: id });
const fetchTruckResponse = (response: TruckResponse): SingleTruckResponseAction => ({
  type: SINGLE_TRUCK_FETCHED,
  response: response,
});
export const saveTruck = (truck: Partial<UserTruck>): TruckMutatorAction => ({
  type: SAVE_TRUCK,
  truck: truck,
});

const saveTruckResponse = (response: TruckResponse): SingleTruckResponseAction => ({
  type: TRUCK_SAVED,
  response: response,
});

export const editTruck = (truck: Partial<UserTruck>): TruckMutatorAction => ({
  type: EDIT_TRUCK,
  truck: truck,
});

const editTruckResponse = (response: TruckResponse): SingleTruckResponseAction => ({
  type: TRUCK_EDITED,
  response: response,
});

export const deleteTruck = (id: string, override?: boolean): DeleteSingleTruckAction => ({
  type: DELETE_TRUCK,
  id: id,
  override: override,
});

const deletedTruckResponse = (response: DeleteTruckResponse): DeleteSingleTruckResponseAction => ({
  type: TRUCK_DELETED,
  response: response,
});

export const setActiveTruck = (truck: UserTruck): SetCurrentTruckAction => ({ type: SET_ACTIVE_TRUCK, truck: truck });

export const setSelectedTruck = (truck: UserTruck | undefined): SetCurrentTruckAction => ({
  type: SET_SELECTED_TRUCK,
  truck: truck,
});

export interface UserTrucksState {
  trucks: UserTruck[];
  activeTruck?: UserTruck;
  selectedTruck?: UserTruck | undefined;
  isLoading: boolean;
  didTrucksLoadSuccessfully?: boolean;
  saved: {
    isSaving: boolean;
    wasSavedSuccessfuly: boolean;
    savedTime?: number;
    httpError?: number;
    errorCode?: number;
    errorMessage?: string;
  };
  edited: {
    isEditing: boolean;
    wasEditedSuccessfully: boolean;
    editedTime?: number;
    httpError?: number;
    errorCode?: number;
    errorMessage?: string;
  };
  deleted: {
    isDeleting: boolean;
    wasDeletedSuccessfully: boolean;
    deletedTime?: number;
    httpError?: number;
  };
}

const initialState: UserTrucksState = {
  isLoading: false,
  trucks: [],
  saved: {
    isSaving: false,
    wasSavedSuccessfuly: false,
  },
  edited: { isEditing: false, wasEditedSuccessfully: false },
  deleted: {
    isDeleting: false,
    wasDeletedSuccessfully: false,
  },
};

export const userTruckReducer = (state: UserTrucksState = initialState, action: Action): UserTrucksState => {
  switch (action.type) {
    case FETCH_USER_TRUCKS: {
      return { ...state, isLoading: true };
    }
    case USER_TRUCKS_FETCHED: {
      const trucksResponse = (action as FetchUserTrucksResponseAction).response;
      const newState = { ...state, isLoading: false, didTrucksLoadSuccessfully: false };
      if (trucksResponse.success && trucksResponse.payload) {
        newState.trucks = map(trucksResponse.payload, parseTruck);
        newState.didTrucksLoadSuccessfully = true;
      }
      return newState;
    }
    case SAVE_TRUCK: {
      return { ...state, saved: { isSaving: true, wasSavedSuccessfuly: false } };
    }
    case TRUCK_SAVED: {
      const saveAction = action as SingleTruckResponseAction;
      const wasSuccessful = saveAction.response.success;
      const newTruck = saveAction.response.payload;
      let userTrucks = state.trucks;
      if (wasSuccessful && newTruck) {
        userTrucks = clone(state.trucks);
        userTrucks.unshift(parseTruck(newTruck));
      }
      return {
        ...state,
        trucks: userTrucks,
        saved: {
          isSaving: false,
          wasSavedSuccessfuly: wasSuccessful,
          savedTime: Date.now(),
          httpError: saveAction.response.error?.httpStatus,
          errorCode: saveAction.response.error?.code,
          errorMessage: saveAction.response.error?.message,
        },
      };
    }
    case EDIT_TRUCK: {
      return { ...state, edited: { isEditing: true, wasEditedSuccessfully: false } };
    }
    case TRUCK_EDITED: {
      const saveAction = action as SingleTruckResponseAction;
      const wasSuccessful = saveAction.response.success;
      const modifiedTruck = saveAction.response.payload;
      let userTrucks = state.trucks;
      if (wasSuccessful && modifiedTruck) {
        const matchingTruckIndex = findIndex(
          state.trucks,
          (truck) => truck.id !== undefined && truck.id === modifiedTruck.id
        );
        if (matchingTruckIndex !== -1) {
          userTrucks = clone(state.trucks);
          userTrucks[matchingTruckIndex] = parseTruck(modifiedTruck);
        }
      }
      return {
        ...state,
        trucks: userTrucks,
        edited: {
          isEditing: false,
          wasEditedSuccessfully: wasSuccessful,
          editedTime: Date.now(),
          httpError: saveAction.response.error?.httpStatus,
          errorCode: saveAction.response.error?.code,
          errorMessage: saveAction.response.error?.message,
        },
      };
    }
    case FETCH_SINGLE_TRUCK: {
      return { ...state, isLoading: true, activeTruck: undefined };
    }
    case SINGLE_TRUCK_FETCHED: {
      const singleTruckAction = action as SingleTruckResponseAction;
      return {
        ...state,
        isLoading: false,
        activeTruck: singleTruckAction.response?.payload ? parseTruck(singleTruckAction.response.payload) : undefined,
      };
    }
    case SET_ACTIVE_TRUCK: {
      const setActiveTruckAction = action as SetCurrentTruckAction;
      return {
        ...state,
        activeTruck: setActiveTruckAction.truck,
      };
    }
    case SET_SELECTED_TRUCK: {
      const setSelectedTruckAction = action as SetCurrentTruckAction;
      return {
        ...state,
        selectedTruck: setSelectedTruckAction.truck,
      };
    }
    case DELETE_TRUCK: {
      return { ...state, deleted: { isDeleting: true, wasDeletedSuccessfully: false } };
    }
    case TRUCK_DELETED: {
      const deletedTruckAction = action as DeleteSingleTruckResponseAction;
      return {
        ...state,
        deleted: {
          isDeleting: false,
          wasDeletedSuccessfully: deletedTruckAction.response.success,
          httpError: deletedTruckAction.response.error?.httpStatus,
          deletedTime: Date.now(),
        },
      };
    }

    default:
      return state;
  }
};

const fetchTrucksEpic$ = (trucksClient: TrucksClient, action$: ActionsObservable<Action>) =>
  standardApiEpic(
    action$,
    FETCH_USER_TRUCKS,
    () => trucksClient.fetchTrucks$(undefined),
    (response) => {
      return fetchTrucksResponse({ success: true, payload: response.data });
    },
    (error) => {
      return fetchTrucksResponse({ success: false, error: error });
    }
  );

const fetchTruckEpic$ = (trucksClient: TrucksClient, action$: ActionsObservable<Action>) =>
  standardApiEpic(
    action$,
    FETCH_SINGLE_TRUCK,
    (action: FetchSingleTruckAction) => trucksClient.fetchTruck$(action.id),
    (response) => {
      return fetchTruckResponse({ success: true, payload: response.data });
    },
    (error) => {
      return fetchTruckResponse({ success: false, error: error });
    }
  );

const saveTruckEpic$ = (trucksClient: TrucksClient, action$: ActionsObservable<Action>) =>
  standardApiEpic(
    action$,
    SAVE_TRUCK,
    (action: TruckMutatorAction) => trucksClient.createTruck$(formatTruck(action.truck)),
    (response) => {
      return saveTruckResponse({ success: true, payload: response.data });
    },
    (error) => {
      return saveTruckResponse({ success: false, error: error });
    }
  );

const editTruckEpic$ = (trucksClient: TrucksClient, action$: ActionsObservable<Action>) =>
  standardApiEpic(
    action$,
    EDIT_TRUCK,
    (action: TruckMutatorAction) => trucksClient.updateTruck$(formatTruck(action.truck)),
    (response) => {
      return editTruckResponse({ success: true, payload: response.data });
    },
    (error) => {
      return editTruckResponse({ success: false, error: error });
    }
  );

const deleteTruckEpic$ = (trucksClient: TrucksClient, action$: ActionsObservable<Action>) =>
  action$.ofType(DELETE_TRUCK).pipe(
    flatMap$((action: DeleteSingleTruckAction) =>
      trucksClient.deleteTruck$(action.id, action.override).pipe(
        map$((response: ApiResponse123<{}>) =>
          response.resultWithoutData(
            () =>
              deletedTruckResponse({
                success: response.success,
                payload: { id: action.id, override: action.override },
              }),
            (error) => deletedTruckResponse({ success: false, error: error })
          )
        )
      )
    )
  );

const parseTruck = (truck: UserTruckFromServer): UserTruck => ({
  ...truck,
  defaultLength: truck.defaultLength ?? MAX_PLUS_TRUCK_LENGTH,
  defaultWeight: truck.defaultWeight ?? MAX_PLUS_TRUCK_WEIGHT,
});

interface PartialStoreState {
  userTrucks: UserTrucksState;
}
export const createUserTrucksEpic = <T extends PartialStoreState>(api: Api) => {
  const trucksClient = new TrucksClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchTrucksEpic$(trucksClient, action$),
      fetchTruckEpic$(trucksClient, action$),
      saveTruckEpic$(trucksClient, action$),
      editTruckEpic$(trucksClient, action$),
      deleteTruckEpic$(trucksClient, action$)
    );
};

const formatTruck = (truck: Partial<UserTruck>) => ({
  ...truck,
  defaultWeight: truck.defaultWeight === MAX_PLUS_TRUCK_WEIGHT ? null : truck.defaultWeight,
  defaultLength: truck.defaultLength === MAX_PLUS_TRUCK_LENGTH ? null : truck.defaultLength,
});

export const formatDefaultLengthWeight = (truck: UserTruck | undefined, isForLoadSearch: boolean = false) => ({
  defaultLength: formatDefaultLength(truck),
  defaultWeight: formatDefaultWeight(truck, isForLoadSearch),
});

export const formatDefaultLength = (truck: UserTruck | undefined) =>
  truck?.defaultLength || (truck?.lengthIsSet ? MAX_PLUS_TRUCK_LENGTH : MAX_TRUCK_LENGTH);

export const formatDefaultWeight = (truck: UserTruck | undefined, isForLoadSearch: boolean = false) =>
  truck?.defaultWeight ||
  (truck?.weightIsSet ? (isForLoadSearch ? MAX_LOAD_SEARCH_TRUCK_WEIGHT : MAX_PLUS_TRUCK_WEIGHT) : MAX_TRUCK_WEIGHT);

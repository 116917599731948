import React from 'react';

import styled from 'styled-components';

import { Button } from '@component/button/Button';
import { BasePopup } from '@component/popup/BasePopup';
import { Spacing } from '@style/StyleConstants';

const PopupButton = styled(Button)`
  && {
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${Spacing.TwoSpaces}px;
`;
export interface ComponentProps {
  onClose?: () => void;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
  title: string;
  description?: string;
  leftButtonText: string;
  rightButtonText: string;
}

export const ScreenSharingPopUp: React.FC<ComponentProps> = (props) => {
  return (
    <BasePopup title={props.title} onClose={props.onClose}>
      {props.description}
      <ButtonContainer>
        <PopupButton id="left_button_popup" key="left_button_popup" onClick={props.onLeftButtonClick} color="secondary">
          {props.leftButtonText}
        </PopupButton>
        <PopupButton
          id="right_button_popup"
          key="right_button_popup"
          onClick={props.onRightButtonClick}
          color="secondary"
        >
          {props.rightButtonText}
        </PopupButton>
      </ButtonContainer>
    </BasePopup>
  );
};

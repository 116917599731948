import { BaseClient } from '@common/client/BaseClient';
import { Geolocation, ReverseGeolocationResult } from '@common/model';

export class GeocodingClient extends BaseClient {
  locationFor$ = (geolocation: Geolocation) =>
    this.api.post$<ReverseGeolocationResult>('/locations/geocode/reverse', {
      location: {
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
      },
      allowFallback: true,
    });
}

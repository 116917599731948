import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import {
  CapacitiesRequest,
  CapacitiesResponse,
  Capacity,
  CapacityFinderKeysSortBy,
  CapacityFinderSearchFilter,
  DEFAULT_SEARCH_FILTER_DRAFT,
  IKeysSortByCapacityFinder,
} from '@/model/CapacityFinder';
import { Api, ApiError } from '@common/api';
import { EquipmentType, LocationSuggestion } from '@common/model';
import { createAction, createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';
import { CapacityFinderClient } from '@webApi/CapacityFinderClient';

export interface CapacityFinderSearchForm {
  equipments?: EquipmentType[];
  origin?: LocationSuggestion;
  originText?: string;
  destination?: LocationSuggestion;
  destinationText?: string;
}

export interface CapacityFinderState {
  searchForm: CapacityFinderSearchForm;
  searchFilterDraft: CapacityFinderSearchFilter;
  selectedSearchFilter: CapacityFinderSearchFilter;
  sortBy: IKeysSortByCapacityFinder;
  isLoading: boolean;
  isLoadingMore: boolean;
  capacitiesResponse?: CapacitiesResponse;
  selectedCapacity?: Capacity;
  lastSearchFormData?: CapacityFinderSearchForm;
  capacities: Capacity[];
  fetchingError?: ApiError | undefined;
}

const fetchCapacitiesAction = createApiAction<CapacitiesRequest, CapacitiesResponse>('FETCH_CAPACITIES');
const fetchMoreCapacitiesAction = createApiAction<CapacitiesRequest, CapacitiesResponse>('FETCH_MORE_CAPACITIES');
const setSelectedCapacityAction = createAction<Capacity | undefined>('SET_SELECTED_CAPACITY');
const updateCapacityFinderSearchFormAction = createAction<Partial<CapacityFinderSearchForm>>(
  'UPDATE_CAPACITY_FINDER_SEARCH_FORM'
);
const updateCapacityFinderLastSearchFormAction = createAction<Partial<CapacityFinderSearchForm>>(
  'UPDATE_CAPACITY_FINDER_LAST_SEARCH_FORM'
);
const switchLocationsSearchFormAction = createAction('SWITCH_LOCATIONS_CAPACITY_FINDER_SEARCH_FORM');
const setSortByOptionAction = createAction<IKeysSortByCapacityFinder>('SET_CAPACITY_FINDER_SORT_BY_OPTION');
const updateSearchFilterDraftAction = createAction<Partial<CapacityFinderSearchFilter>>(
  'UPDATE_CAPACITY_FINDER_SEARCH_FILTER_DRAFT'
);
const clearSearchFilterDraftAction = createAction('CLEAR_CAPACITY_FINDER_SEARCH_FILTER_DRAFT');
const setSelectedSearchFilterAction = createAction<CapacityFinderSearchFilter>('SET_SELECTED_SEARCH_FILTER');

export const fetchCapacities = (capacitiesRequest: CapacitiesRequest) =>
  fetchCapacitiesAction.fetchAction(capacitiesRequest);
export const fetchMoreCapacities = (capacitiesRequest: CapacitiesRequest) =>
  fetchMoreCapacitiesAction.fetchAction(capacitiesRequest);
export const setSelectedCapacity = (capacity: Capacity | undefined) => setSelectedCapacityAction.action(capacity);
export const updateCapacityFinderSearchForm = (searchForm: Partial<CapacityFinderSearchForm>) =>
  updateCapacityFinderSearchFormAction.action(searchForm);
export const updateCapacityFinderLastSearchForm = (searchForm: Partial<CapacityFinderSearchForm>) =>
  updateCapacityFinderLastSearchFormAction.action(searchForm);
export const switchLocations = () => switchLocationsSearchFormAction.action(undefined);
export const setSortByOption = (sortOption: IKeysSortByCapacityFinder) => setSortByOptionAction.action(sortOption);
export const updateSearchFilterDraft = (filter: Partial<CapacityFinderSearchFilter>) =>
  updateSearchFilterDraftAction.action(filter);
export const clearSearchFilterDraft = () => clearSearchFilterDraftAction.action(undefined);
export const setSelectedSearchFilter = (searchFilter: CapacityFinderSearchFilter) =>
  setSelectedSearchFilterAction.action(searchFilter);

const initialState: CapacityFinderState = {
  sortBy: CapacityFinderKeysSortBy[0],
  searchFilterDraft: DEFAULT_SEARCH_FILTER_DRAFT,
  selectedSearchFilter: DEFAULT_SEARCH_FILTER_DRAFT,
  searchForm: {},
  isLoading: false,
  isLoadingMore: false,
  capacities: [],
};

export const capacityFinderReducer = createMergedReducer(initialState, [
  fetchCapacitiesAction.initiateCase((state) => {
    state.isLoading = true;
    state.fetchingError = undefined;
  }),
  fetchCapacitiesAction.completeCase((state, action) => {
    state.isLoading = false;
    if (action.response.success) {
      state.capacitiesResponse = action.response.payload;
      state.capacities = action.response.payload.capacities;
    } else {
      state.fetchingError = action.response.error;
    }
  }),
  fetchMoreCapacitiesAction.initiateCase((state) => {
    state.isLoadingMore = true;
    state.fetchingError = undefined;
  }),
  fetchMoreCapacitiesAction.completeCase((state, action) => {
    state.isLoadingMore = false;
    if (action.response.success) {
      state.capacitiesResponse = action.response.payload;
      state.capacities = [...state.capacities, ...action.response.payload.capacities];
    } else {
      state.fetchingError = action.response.error;
    }
  }),
  setSelectedCapacityAction.addCase((state, action) => {
    state.selectedCapacity = action.data;
  }),
  updateCapacityFinderSearchFormAction.addCase((state, action) => {
    state.searchForm = { ...state.searchForm, ...action.data };
  }),
  updateCapacityFinderLastSearchFormAction.addCase((state, action) => {
    state.lastSearchFormData = { ...state.searchForm, ...action.data };
  }),
  switchLocationsSearchFormAction.addCase((state) => {
    const newOrigin = state.searchForm?.destination;
    const newDestination = state.searchForm?.origin;
    const newOriginText = state.searchForm?.destinationText;
    const newDestinationText = state.searchForm?.originText;
    state.searchForm = {
      ...state.searchForm,
      origin: newOrigin,
      originText: newOriginText,
      destination: newDestination,
      destinationText: newDestinationText,
    };
  }),
  setSortByOptionAction.addCase((state, action) => {
    state.sortBy = action.data;
  }),
  updateSearchFilterDraftAction.addCase((state, action) => {
    state.searchFilterDraft = { ...state.searchFilterDraft, ...action.data };
  }),
  clearSearchFilterDraftAction.addCase((state) => {
    state.searchFilterDraft = DEFAULT_SEARCH_FILTER_DRAFT;
  }),
  setSelectedSearchFilterAction.addCase((state, action) => {
    state.selectedSearchFilter = action.data;
  }),
]);

export const createCapacityFinderEpic = (api: Api) => {
  const capacityFinderClient = new CapacityFinderClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchCapacitiesAction.createEpic$(action$, capacityFinderClient.fetchCapacities$),
      fetchMoreCapacitiesAction.createEpic$(action$, capacityFinderClient.fetchCapacities$)
    );
};

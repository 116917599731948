import React from 'react';

import './safeinsets.css';

interface Props {
  backgroundColor?: string;
}

/** Spacer for a "safe area" in recent iPhones. This avoids the thin bottom "action bar" */
export const SafeBottomInsetSpace: React.FC<Props> = ({ backgroundColor }) => {
  return <div className="safebottom" style={{ width: '100%', backgroundColor: backgroundColor }} />;
};

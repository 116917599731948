import { clone, map } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { LoadsClient } from '@common/client';
import { serverRFCDateToYYYYMMDD } from '@common/helper';
import { createLoadSearchMetadata } from '@common/helper/LoadSearchRequestFactory';
import { BackhaulsLoadSearchQuery, LoadSearchRequest } from '@common/model';
import { createLoadSearch, SearchReducerKey } from '@common/redux/epic/loadSearch/LoadSearchShared';
import { createReducer } from '@common/redux/ReduxHelper';

import { initialState as initialStateLoadState, LoadSearchBaseState } from './HelperFunctions';

export const LOAD_BACKHAULS_REDUCER_KEY = SearchReducerKey.BACKHAULS;
export const COMPANY_SEARCH_BACKHAULS_REDUCER_KEY = SearchReducerKey.COMPANY_SEARCH_BACKHAULS;

interface SetBackhaulsQueryAction extends Action {
  query: BackhaulsLoadSearchQuery;
  archivingFlowID: string | undefined;
}

interface UpdateListSearchAction extends Action {
  search: LoadSearchRequest;
}

export type LoadBackhaulsState = LoadSearchBaseState;

const initialState: LoadBackhaulsState = {
  ...initialStateLoadState,
};

const createBackhaulsEpic = (reducerKey: string) => {
  //Actions
  const baseActionKey = `${reducerKey}_`;
  const actionsKeys = {
    BACKHAULS_SET_QUERY: `${baseActionKey}BACKHAULS_SET_QUERY`,
    CLEAR_BACKHAULS: `${baseActionKey}CLEAR_BACKHAULS`,
    BACKHAULS_UPDATE_LIST_SEARCH: `${baseActionKey}BACKHAULS_UPDATE_LIST_SEARCH`,
  };
  const backhauls = createLoadSearch(reducerKey);

  const exportedActions = {
    /** computes a new backhauls "lastSearch" from the selected loads backhauls entry.
     * The filter values will be extracted from this request through a ReducerSync.
     * The actual search is performed later in the fetchBackhaulsLoads
     * upon going into the backhauls loads results screen
     * Filters changes are re-applied to the request upon modification.
     */
    backhaulsSetQuery: (
      query: BackhaulsLoadSearchQuery,
      archivingFlowID: string | undefined
    ): SetBackhaulsQueryAction => ({
      type: actionsKeys.BACKHAULS_SET_QUERY,
      query: query,
      archivingFlowID: archivingFlowID,
    }),
    clearBackhauls: (): Action => ({
      type: actionsKeys.CLEAR_BACKHAULS,
    }),
    updateListSearch: (search: LoadSearchRequest): UpdateListSearchAction => ({
      type: actionsKeys.BACKHAULS_UPDATE_LIST_SEARCH,
      search: search,
    }),
    fetchBackhaulsLoadCount: backhauls.actions.fetchLoadCount,
    nextSearchLoads: backhauls.actions.nextSearchLoads,
    resetBackhaulsLoadCount: backhauls.actions.resetLoadCount,
    searchLoads: backhauls.actions.searchLoads,
    searchLoadsById: backhauls.actions.searchLoadsById,
    showBackhaulsSimilarLoads: backhauls.actions.showSimilarLoads,
    updateBackhaulsOriginLocation: backhauls.actions.updateOriginLocation,
    updateBackhaulsLoadSearchTruck: backhauls.actions.updateLoadSearchTruck,
    updateBackhaulLoads: backhauls.actions.updateLoads,
    refreshBackhaulsLoadCount: backhauls.actions.refreshLoadCount,
    performAutoRefresh: backhauls.actions.performAutoRefresh,
    setSelectedSearch: backhauls.actions.setSelectedSearch,
    viewBackhaulLoad: backhauls.actions.viewLoad,
    setSortBy: backhauls.actions.setSortBy,
  };

  //Reducer
  const reducer = createReducer(
    initialState,
    {
      [actionsKeys.BACKHAULS_SET_QUERY]: (state, action: SetBackhaulsQueryAction) => {
        // compute loadsearch query from backhaul query
        const lastSearch = loadSearchQueryFromBackhaulsQuery(action.query);
        state.lastSearchRequest = lastSearch;
        state.listSearchRequest = lastSearch;
        state.archivingFlowID = action.archivingFlowID;
      },
      [actionsKeys.CLEAR_BACKHAULS]: () => {
        return initialState;
      },
      [actionsKeys.BACKHAULS_UPDATE_LIST_SEARCH]: (state, action: UpdateListSearchAction) => {
        state.listSearchRequest = action.search;
      },
    },
    (state: LoadBackhaulsState, action) => {
      const backhaulsState = backhauls.reducer(state, action);
      if (backhaulsState) {
        return { ...state, ...backhaulsState };
      }
      return state;
    }
  );

  const createEpic$ = (api: Api, isLiveEnvironment: boolean) => {
    const loadsClient = new LoadsClient(api, isLiveEnvironment);
    return (
      action$: ActionsObservable<Action>,
      state$: StateObservable<{ [reducerKey: string]: LoadBackhaulsState }>
    ) => merge$(backhauls.createMergedEpic$(loadsClient, action$, state$));
  };

  return {
    reducer: reducer,
    createEpic$: createEpic$,
    actions: exportedActions,
    actionKeys: actionsKeys,
    actionsQueue: backhauls.actionsQueue,
  };
};

export const loadSearchQueryFromBackhaulsQuery = (query: BackhaulsLoadSearchQuery): LoadSearchRequest => {
  // Trust the server to create a useful request that matches it's own load search

  // IMPORTANT: At runtime, there will be more fields than we see in TypeScript
  // as we do not normally use them within the App. We want these to be copied over.

  const request = clone(query) as LoadSearchRequest;

  // convert date formats
  request.pickupDates = map(request.pickupDates, serverRFCDateToYYYYMMDD);

  // There is never a metadata structure from the backhauls query.
  // Use our defaults metadata
  request.metadata = createLoadSearchMetadata();

  return request;
};

const backhaulsEpic = createBackhaulsEpic(LOAD_BACKHAULS_REDUCER_KEY);

export const backhaulsReducer = backhaulsEpic.reducer;
export const backhaulsEpicCreator$ = backhaulsEpic.createEpic$;
export const backhaulsActions = backhaulsEpic.actions;
export const backhaulsActionKeys = backhaulsEpic.actionKeys;
export const ACTIONS_BLOCKING_GROUP = backhaulsEpic.actionsQueue;

import { stateSpecific } from '@component/panels/basePanel/searchPanel/findLoadsSimpleMap/StateSpecific';
import { stateLabels } from '@component/simplemap/StateLabels';
import { Color } from '@style/Color';

export const SIMPLEMAPS_CARRIER_MAPDATA = {
  main_settings: {
    //General settings
    width: 'responsive', //'700' or 'responsive'
    background_color: Color.GRAY_LIGHT,
    background_transparent: 'yes',
    border_color: Color.WHITE,
    popups: 'off',

    //State defaults
    state_description: '',
    state_color: Color.MAP_GREEN_DISABLED,
    state_hover_color: Color.MAP_GREEN_DISABLED,
    state_url: '',
    border_size: 1.5,
    all_states_inactive: 'yes',
    all_states_zoomable: 'no',

    //Location defaults
    location_description: 'Location description',
    location_color: Color.WHITE,
    location_opacity: 0.8,
    location_hover_opacity: 1,
    location_url: '',
    location_size: 25,
    location_type: 'square',
    location_image_source: 'frog.png',
    location_border_color: Color.WHITE,
    location_border: 2,
    location_hover_border: 2.5,
    all_locations_inactive: 'no',
    all_locations_hidden: 'no',

    //Label defaults
    label_color: Color.WHITE,
    label_hover_color: Color.WHITE,
    label_size: 14,
    label_font: 'Overpass',
    hide_labels: 'no',
    manual_zoom: 'no',
    arrow_color: '#cecece',
    arrow_color_border: '#808080',
    initial_back: 'no',
    initial_zoom: -1,
    initial_zoom_solo: 'yes',
    region_opacity: 1,
    region_hover_opacity: 0.6,
    zoom_out_incrementally: 'yes',
    zoom_percentage: 0.99,
    zoom_time: 0.5,

    //Popup settings
    popup_color: 'white',
    popup_opacity: 0.9,
    popup_shadow: 1,
    popup_corners: 5,
    popup_font: '12px/1.5 Verdana, Arial, Helvetica, sans-serif',
    popup_nocss: 'no',

    //Advanced settings
    div: 'selectStatesMap',
    auto_load: 'no',
    url_new_tab: 'no',
    images_directory: './',
    fade_time: 0.1,
    import_labels: 'no',
    link_text: 'View Website',
    state_image_url: '',
    state_image_position: '',
    location_image_url: '',
  },
  state_specific: stateSpecific,
  locations: {},
  labels: stateLabels,
  regions: {},
};

import { createAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface LocationPickerState {
  isRegionsPickerOpened: boolean;
  selectedStates: string[];
}

const toggleRegionsPickerAction = createAction<boolean>('TOGGLE_REGIONS_PICKER');
const setSelectedStatesAction = createAction<string[]>('SET_SELECTED_STATES');

export const toggleRegionsPicker = (isOpened: boolean) => toggleRegionsPickerAction.action(isOpened);
export const setSelectedStates = (states: string[]) => setSelectedStatesAction.action(states);

const initialState: LocationPickerState = {
  isRegionsPickerOpened: false,
  selectedStates: [],
};

export const LocationPickerReducer = createMergedReducer(initialState, [
  toggleRegionsPickerAction.addCase((state, action) => {
    state.isRegionsPickerOpened = action.data;
  }),
  setSelectedStatesAction.addCase((state, action) => {
    state.selectedStates = action.data;
  }),
]);

import React from 'react';

import { map } from 'lodash';
import styled from 'styled-components';

import { Collapse } from '@material-ui/core';

import { Warning } from '@component/table/truckTable/Warning';
import { Color } from '@style/Color';
import { FontSize, px } from '@style/StyleConstants';
import { ThemeProps, withTheme } from '@style/WithTheme';

import { ExpandIcon } from './ExpandIcon';
import { ShrinkIcon } from './ShrinkIcon';

const TransitionSpeed = '0.2s';

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 1100;
  pointer-events: none;
  padding: 0 16px;
  box-sizing: border-box;
`;

const LegendContentContainer = withTheme()(styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: ${(props: ThemeProps) => props.theme.palette.background.paper};
  opacity: 0.9;

  @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.values.md - 1}px) {
    flex-grow: 1;
  }
`);

const LegendIcon = withTheme()(styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  > svg {
    height: 16px;
    width: 16px;
  }
`);

const LegendList = withTheme()(styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: ${(props: ThemeProps & { collapsed: boolean }) => (props.collapsed ? 0 : 1)};
  transition: opacity ${TransitionSpeed}, height ${TransitionSpeed}, min-height ${TransitionSpeed};
  padding-left: 8px;
  flex-wrap: wrap;

  @media (max-width: ${(props: ThemeProps & { collapsed: boolean }) => props.theme.breakpoints.values.sm - 1}px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
  }
`);

const LegendListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-right: 11px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-size: 14px;
  white-space: nowrap;
  box-sizing: border-box;
`;

const LegendItemIcon = styled.div`
  padding-right: 10px;
  margin-bottom: -6px;
`;

const LegendExpandIconButton = styled.div`
  fill: white;
  background-color: ${Color.GRAY_12};
  border-radius: 2px;
  pointer-events: auto;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledLink = styled.a`
  margin-left: 8px;
  cursor: pointer;
  pointer-events: auto;
  color: ${Color.GREEN_MAIN} !important;
`;

const LegendWarning = styled(Warning)`
  min-height: 40px;
  font-size: ${px(FontSize.ContentDetail)};
  padding: 0 14px 0 14px;
  && {
    > span {
      margin-left: 14px;
    }
  }
`;

interface MapLegendProps {
  id: string;
  items: MapLegendItem[];
  shouldDisplayWarning: boolean;
  warningMessage: string;
}

export interface MapLegendItem {
  label: string;
  icon: JSX.Element;
  link?: boolean;
  linkLabel?: string;
  onLinkClick?: () => void;
}

export class MapLegend extends React.PureComponent<MapLegendProps> {
  state = {
    isExpanded: true,
  };

  handleToggleExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  public render() {
    return (
      // for map interactivity interop (see gesture-handling.js), we need to set this class
      <LegendContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <LegendContentContainer id={this.props.id} className="leaflet-interactive">
            <LegendExpandIconButton id={this.props.id + '_expand_buton'} onClick={this.handleToggleExpanded}>
              <LegendIcon id={this.props.id + '_expand_buton_icon'}>{this.getLegendIcon()}</LegendIcon>
            </LegendExpandIconButton>
            <Collapse in={this.state.isExpanded} collapsedHeight={'16px'}>
              {this.getLegendContent()}
            </Collapse>
          </LegendContentContainer>
          {this.props.shouldDisplayWarning ? <LegendWarning message={this.props.warningMessage} /> : null}
        </div>
      </LegendContainer>
    );
  }

  private getLegendContent(): JSX.Element {
    return (
      <LegendList id={this.props.id + '_list'} collapsed={!this.state.isExpanded}>
        {this.mapItemsToLegendItemComponents(this.props.id + '_list')}
      </LegendList>
    );
  }

  private getLegendIcon(): JSX.Element {
    return this.state.isExpanded ? <ShrinkIcon /> : <ExpandIcon />;
  }

  private mapItemsToLegendItemComponents(id: string): JSX.Element[] {
    return map(this.props.items, (item, index) => {
      const ITEM_ID_PREFIX = id + '_item_' + index;
      return (
        <LegendListItem id={ITEM_ID_PREFIX} key={index}>
          <LegendItemIcon id={ITEM_ID_PREFIX + '_icon'}>{item.icon}</LegendItemIcon>
          {item.label}
          {item.link ? <StyledLink onClick={item.onLinkClick}>{item.linkLabel}</StyledLink> : undefined}
        </LegendListItem>
      );
    });
  }
}

import styled from 'styled-components';

import { Button, IconButton } from '@material-ui/core';

import { ThemeProps, withTheme } from '@style/WithTheme';

import { MultipurposeButtonProps } from './MultipurposeButton';

// there is no focus effect when focusing at this button programmatically
// so I added the same box-shadow effect applied by Material when we get focus at this button through tabbing process
export const StyledButton = styled(Button)`
  && {
    font-size: 16px;
    text-transform: inherit;
    flex: 1;
    height: ${(props: MultipurposeButtonProps) =>
      props.size === undefined ? '44px' : props.size === 'medium' ? '32px' : 'unset'};
    box-shadow: none;
    align-items: center;
    color: ${(props: MultipurposeButtonProps) => props.variant === 'contained' && '#FFF'};
    &:focus {
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
  }
`;

export const StyledImg = styled.img`
  width: 24px;
  height: 24px;
`;

export const StyledActionButtonWrapper = withTheme()(styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  height: 56px;
  border-bottom: 1px solid ${(props: ThemeProps) => props.theme.palette.background.smoke};
  cursor: pointer;
  background-color: ${(props: ThemeProps) => props.theme.palette.background.white};
`);

export const StyledIcon = styled.img`
  padding: 12px;
  padding-right: 16px;
`;

export const StyledControl = styled(IconButton)`
  && {
    margin-left: auto;
  }
`;

export const StyledContent = withTheme()(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-family: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontFamily};
  > :first-child {
    font-size: ${(props: ThemeProps) => props.theme.text.subtitleRegular.fontSize};
  }
  > :last-child {
    font-size: ${(props: ThemeProps) => props.theme.text.body.fontSize};
  }
`);

import React, { useRef, useState } from 'react';

import { isEmpty, toNumber, trim } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { clearEmailSendingState, sendEmailToBroker } from '@common/redux/epic/LoadInfoEpic';
import { MultipurposeButton } from '@component/buttons';
import { MultilineTextField, TextField, TextFieldProps } from '@component/input';
import { BasePopup } from '@component/popup/BasePopup';
import { usePopup } from '@component/popup/PopupTrackingContext';
import { Text } from '@component/text';
import { Color } from '@style/Color';
import { ElementSize, FontLineHeight, FontSize, Spacing } from '@style/StyleConstants';
import { withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { useIsMobileView } from '@util/hooks';

const MAX_CHAR_COUNT = 2000;
const MAX_BID_AMOUNT = 99999;

interface EmailFormProps {
  loadID: string;
  shouldShowOptionsAndBidSection: boolean;
}

interface EmailConfirmProps {
  companyName?: string;
  loadID: string;
}

interface StyleProps {
  isMobile: boolean;
}

const EmailDialogContent = withTheme()(styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`);

const EmailDialogSubtitle = styled.div`
  text-align: center;
  margin-bottom: ${Spacing.InterSection}px;
`;

const EmailDialogSubmit = styled.div`
  display: flex;
  margin-top: ${Spacing.DoubleInterSection}px;
  width: 180px;
`;

const OptionsAndBidSection = styled.div`
  display: grid;
  width: 100%;
  ${(props: StyleProps) => !props.isMobile && 'grid-template-columns: 1fr auto 1fr'};
  ${(props: StyleProps) => props.isMobile && `row-gap: ${Spacing.ElementPaddingHorizontal}px`};
  column-gap: ${Spacing.DoubleInterSection}px;
  margin-bottom: ${Spacing.TitleTopDistance}px;
  margin-top: ${Spacing.ElementPaddingVertical}px;
`;

const OptionsList = styled.div`
  display: grid;
  row-gap: ${Spacing.ElementPaddingHorizontal}px;
`;

const Option = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: end;
    width: ${(props: StyleProps) => (props.isMobile ? '100%' : '290px')};
    height: ${ElementSize.OptionBar}px;
    border: 1px solid ${Color.GRAY_DARK};
    border-radius: 100px;
    text-align: center;
    font-size: ${FontSize.Body}px;
    line-height: ${FontLineHeight.Body}px;
    text-transform: initial;
  }
`;

const DimText = styled(Text)`
  font-size: ${FontSize.ContentDetail}px;
  line-height: ${FontLineHeight.Content}px;
  color: ${Color.GRAY_STONE};
  align-self: center;
  justify-self: center;
`;

const BidTextField = styled<StyleProps & TextFieldProps>(({ isMobile, ...rest }) => <TextField {...rest} />)`
  align-self: center;
  max-width: ${(props: StyleProps) => (props.isMobile ? '100%' : '290px')};
`;

export const EmailFormPopup: React.FC<EmailFormProps> = ({ loadID, shouldShowOptionsAndBidSection }) => {
  const [text, setText] = useState('');
  const [bid, setBid] = useState('');
  const { closingPopup } = usePopup();
  const isMobile = useIsMobileView();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const getBidAndCommentValue = () => {
    if (isEmpty(bid)) {
      return text;
    }
    return `${t(T.findLoads_loadDetails_emailFormPopup_bidAmount, { bid: bid })}\n${text}`;
  };

  return (
    <BasePopup title={t(T.findLoads_loadDetails_emailFormPopup_header)}>
      <EmailDialogContent>
        <EmailDialogSubtitle>{t(T.findLoads_loadDetails_emailFormPopup_subtitle)}</EmailDialogSubtitle>
        {shouldShowOptionsAndBidSection ? (
          <>
            <DimText id={'options_title'}>{t(T.findLoads_loadDetails_emailFormPopup_options_title)}</DimText>
            <OptionsAndBidSection isMobile={isMobile}>
              <OptionsList>
                <Option
                  isMobile={isMobile}
                  onClick={() =>
                    insertAtCursor(inputRef.current, t(T.findLoads_loadDetails_emailFormPopup_options_option1), setText)
                  }
                >
                  {t(T.findLoads_loadDetails_emailFormPopup_options_option1)}
                </Option>
                <Option
                  isMobile={isMobile}
                  onClick={() =>
                    insertAtCursor(inputRef.current, t(T.findLoads_loadDetails_emailFormPopup_options_option2), setText)
                  }
                >
                  {t(T.findLoads_loadDetails_emailFormPopup_options_option2)}
                </Option>
                <Option
                  isMobile={isMobile}
                  onClick={() =>
                    insertAtCursor(inputRef.current, t(T.findLoads_loadDetails_emailFormPopup_options_option3), setText)
                  }
                >
                  {t(T.findLoads_loadDetails_emailFormPopup_options_option3)}
                </Option>
                <Option
                  isMobile={isMobile}
                  onClick={() =>
                    insertAtCursor(inputRef.current, t(T.findLoads_loadDetails_emailFormPopup_options_option4), setText)
                  }
                >
                  {t(T.findLoads_loadDetails_emailFormPopup_options_option4)}
                </Option>
              </OptionsList>
              <DimText id={'or'}>{t(T.common_or)}</DimText>
              <BidTextField
                id="bid_input"
                label={t(T.findLoads_loadDetails_emailFormPopup_options_placeholder)}
                value={bid}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  isBidValid(event.target.value) && setBid(event.target.value)
                }
                isWithoutMargin={true}
                isMobile={isMobile}
              />
            </OptionsAndBidSection>
          </>
        ) : null}
        <MultilineTextField
          id={'comment_input'}
          inputRef={inputRef}
          autoFocus={isMobile ? false : true}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setText(event.target.value)}
          value={text}
          placeholder={t(T.findLoads_loadDetails_emailFormPopup_placeholder)}
          inputProps={{ maxLength: MAX_CHAR_COUNT }}
          rows={isMobile ? 4 : 5}
        />
        <EmailDialogSubmit>
          <MultipurposeButton
            id={'email_form_submit'}
            color={'primary'}
            disabled={isEmpty(text) && isEmpty(bid)}
            onClick={() => {
              closingPopup();
              dispatch(sendEmailToBroker(loadID, getBidAndCommentValue()));
            }}
            text={t(T.findLoads_loadDetails_emailFormPopup_submitText)}
            variant={'contained'}
          />
        </EmailDialogSubmit>
      </EmailDialogContent>
    </BasePopup>
  );
};

export const EmailConfirmPopup: React.FC<EmailConfirmProps> = ({ companyName, loadID }) => {
  const { closingPopup } = usePopup();
  const dispatch = useDispatch();
  return (
    <BasePopup
      title={t(T.findLoads_loadDetails_emailConfirmPopup_header)}
      subTitle={t(T.findLoads_loadDetails_emailConfirmPopup_title)}
      onClose={() => dispatch(clearEmailSendingState(loadID))}
    >
      <EmailDialogContent>
        <EmailDialogSubtitle>
          <span>
            {t(T.findLoads_loadDetails_emailConfirmPopup_subtitle_sentTo, {
              companyName: companyName ?? t(T.confirmationDialog_broker),
            })}
          </span>
          <br />
          <span>{t(T.findLoads_loadDetails_emailConfirmPopup_subtitle_note)}</span>
        </EmailDialogSubtitle>
        <EmailDialogSubmit>
          <MultipurposeButton
            id={'email_confirm_submit'}
            color={'primary'}
            onClick={() => {
              dispatch(clearEmailSendingState(loadID));
              closingPopup();
            }}
            text={t(T.findLoads_loadDetails_emailConfirmPopup_submitText)}
            variant={'contained'}
          />
        </EmailDialogSubmit>
      </EmailDialogContent>
    </BasePopup>
  );
};

const isBidValid = (bid: string): boolean => {
  if (isEmpty(bid)) {
    return true;
  }
  const bidNumber = toNumber(bid);
  return bidNumber > 0 && bidNumber <= MAX_BID_AMOUNT;
};

const insertAtCursor = (
  textArea: HTMLTextAreaElement | null,
  myValue: string,
  setTextValue: (value: string) => void
) => {
  if (textArea !== null) {
    const { selectionStart, selectionEnd } = textArea;
    if (selectionStart || selectionStart === 0) {
      const valueBeforeCaret = textArea.value.substring(0, selectionStart);
      const valueAfterCaret = textArea.value.substring(selectionEnd, textArea.value.length);
      const newValue = `${valueBeforeCaret} ${myValue} ${valueAfterCaret}`;
      setTextValue(trim(newValue));
      textArea.selectionStart = selectionStart + myValue.length;
      textArea.selectionEnd = selectionStart + myValue.length;
    } else {
      setTextValue(`${textArea.value}${myValue}`);
    }
  }
};

import React from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';
import { FontSize, FontWeight } from '@style/StyleConstants';
import { T, t } from '@translate';

import { default as ErrorSVG } from './error.svg?react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
`;

const Title = styled.span`
  margin-top: 54px;
  font-size: ${FontSize.Header}px;
  color: ${Color.GRAY_DARK};
  font-weight: ${FontWeight.Bold};
  text-align: center;
`;

const Subtitle = styled.div`
  font-size: ${FontSize.Content}px;
  color: ${Color.GRAY_DARK};
  margin-top: 20px;
  text-align: center;
  white-space: pre-wrap;
`;

interface Props {
  extension: string;
  isCommunication?: boolean;
}

export const PreviewNotAvailable: React.FC<Props> = (props) => (
  <Container>
    <ErrorSVG id="error" />
    <Title id="title">{t(T.common_myDocuments_fileNotSupported_title)}</Title>
    <Subtitle id="subtitle">
      {props.isCommunication
        ? t(T.common_myDocuments_fileNotSupported_subTitleDownloadOnly, { extension: `.${props.extension}` })
        : t(T.common_myDocuments_fileNotSupported_subTitle, { extension: `.${props.extension}` })}
    </Subtitle>
  </Container>
);

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { CarrierTrackingClient } from '@common/client/CarrierTrackingClient';
import { SendOneTimeLocationRequest } from '@common/model/OneTimeLocation';
import { createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';

export interface CarrierTrackingState {
  conversation: {
    requestLocation: {
      wasSuccessful?: boolean;
      isLoading: boolean;
    };
    sendLocation: {
      wasSuccessful?: boolean;
      isSending: boolean;
    };
  };
}

const requestOneTimeLocationFromConversationAction = createApiAction<string, {}>(
  'REQUEST_ONE_TIME_LOCATION_FROM_CONVERSATION'
);

const sendOneTimeLocationInConversationAction = createApiAction<SendOneTimeLocationRequest, {}>(
  'SEND_ONE_TIME_LOCATION_IN_CONVERSATION'
);

export const requestOneTimeLocationFromConversation = (conversationId: string) =>
  requestOneTimeLocationFromConversationAction.fetchAction(conversationId);

export const sendOneTimeLocationInConversation = (request: SendOneTimeLocationRequest) =>
  sendOneTimeLocationInConversationAction.fetchAction(request);

const initialState: CarrierTrackingState = {
  conversation: {
    requestLocation: {
      isLoading: false,
    },
    sendLocation: {
      isSending: false,
    },
  },
};

export const carrierTrackingReducer = createMergedReducer(initialState, [
  requestOneTimeLocationFromConversationAction.initiateCase((state: CarrierTrackingState) => {
    state.conversation.requestLocation.isLoading = true;
  }),
  requestOneTimeLocationFromConversationAction.completeCase((state: CarrierTrackingState, action) => {
    state.conversation.requestLocation.isLoading = false;
    state.conversation.requestLocation.wasSuccessful = action.response.success;
  }),
  sendOneTimeLocationInConversationAction.initiateCase((state: CarrierTrackingState) => {
    state.conversation.sendLocation.isSending = true;
  }),
  sendOneTimeLocationInConversationAction.completeCase((state: CarrierTrackingState, action) => {
    state.conversation.sendLocation.isSending = false;
    state.conversation.sendLocation.wasSuccessful = action.response.success;
  }),
]);

export const createCarrierTrackingEpic = (api: Api) => {
  const carrierTrackingClient = new CarrierTrackingClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      requestOneTimeLocationFromConversationAction.createEpic$(
        action$,
        carrierTrackingClient.requestOneTimeLocationSharing$
      ),
      sendOneTimeLocationInConversationAction.createEpic$(action$, carrierTrackingClient.sendOneTimeLocation$)
    );
};

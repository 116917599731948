import { concat, includes } from 'lodash';

import { FMCSAAuthorityStatus, Truck, TruckTableItem } from '@common/model';

import { Sort } from './Sort';

export interface Matches {
  truckList: Truck[];
  anonymousList: Truck[];
  nonList: Truck[];
}

export interface FilteredMatches extends Matches {
  filteredList: Truck[];
}

export interface TruckItemMatches {
  anonymousMatches: TruckTableItem[];
  exactMatches: TruckTableItem[];
  nonMatches: TruckTableItem[];
  filteredOutMatches: TruckTableItem[];
}

export interface TruckFilters {
  radius: number;
  favorite: boolean;
  onboarded: boolean;
  gpsVerified: boolean;
  activeAuthority: boolean;
}

export enum TruckSortCategories {
  AGE = 'age',
  COMPANY = 'companyName',
  RATING = 'rating',
  DEADHEAD = 'deadhead',
  EQUIPMENT = 'equipmentTypes',
  SIZE = 'size',
  AVAILABLE_CAPACITY = 'availableCapacity',
  AVAILABILITY = 'availableDates',
  ALERT_SENT = 'alertSent',
  AMOUNT = 'amount',
}

class SortTruck extends Sort<TruckTableItem, TruckSortCategories> {
  baseSortCategories = [TruckSortCategories.AVAILABILITY, TruckSortCategories.DEADHEAD, TruckSortCategories.ALERT_SENT];

  valueForCategory = (category: string, item: TruckTableItem, sortAsc: boolean) => {
    switch (category) {
      case TruckSortCategories.AVAILABILITY:
        return this.parseTruckDate(item.truck, sortAsc);
      case TruckSortCategories.DEADHEAD:
        return this.parseTruckDeadhead(item.truck, sortAsc);
      case TruckSortCategories.RATING:
        return item.truck[TruckSortCategories.RATING]?.starRating;
      default:
        return (item.truck as any)[category];
    }
  };

  categoryIsNumber = (category: string) => {
    switch (category) {
      case TruckSortCategories.AVAILABILITY:
      case TruckSortCategories.DEADHEAD:
      case TruckSortCategories.ALERT_SENT:
      case TruckSortCategories.AMOUNT:
      case TruckSortCategories.RATING:
      case TruckSortCategories.AGE:
        return true;
    }
    return false;
  };

  private parseTruckDate = (truck: Truck, isSortAsc: boolean): number | undefined => {
    if (truck.availableDates && truck.availableDates.length > 0) {
      return new Date(truck.availableDates[0]).getTime();
    } else {
      return this.handleUndefinedReturn(isSortAsc);
    }
  };

  private parseTruckDeadhead = (truck: Truck, isSortAsc: boolean): number | undefined => {
    if (truck.deadhead !== undefined) {
      return truck.deadhead;
    } else {
      return this.handleUndefinedReturn(isSortAsc);
    }
  };
}

const TruckItemSort: Sort<TruckTableItem, TruckSortCategories> = new SortTruck();

export const sortTruckMatches = (
  list: TruckItemMatches,
  isSortAsc: boolean,
  category: TruckSortCategories
): TruckItemMatches => ({
  exactMatches: TruckItemSort.sort(list.exactMatches, isSortAsc, category),
  anonymousMatches: TruckItemSort.sort(list.anonymousMatches, isSortAsc, category),
  nonMatches: TruckItemSort.sort(list.nonMatches, isSortAsc, category),
  filteredOutMatches: TruckItemSort.sort(list.filteredOutMatches, isSortAsc, category),
});

const filterTrucks = (
  trucks: Truck[],
  filters: TruckFilters,
  onboardedCarriersDotNumbersList: string[]
): {
  unfilteredTrucks: Truck[];
  filteredTrucks: Truck[];
  filteredAnonymousTrucks: Truck[];
  unfilteredAnonymousTrucks: Truck[];
} => {
  const unfilteredTrucks = new Array<Truck>();
  const filteredTrucks = new Array<Truck>();
  const unfilteredAnonymousTrucks = new Array<Truck>();
  const filteredAnonymousTrucks = new Array<Truck>();
  for (const truck of trucks) {
    if (
      truck.matchResult.withinRadius &&
      (!filters.favorite || truck.isFavoriteCarrier) &&
      (!filters.onboarded || includes(onboardedCarriersDotNumbersList, truck.dotNumber)) &&
      (!filters.gpsVerified || truck.truckAvailabilityTypeId === 2) &&
      (!filters.activeAuthority ||
        truck.commonAuthorityStatus === FMCSAAuthorityStatus.Active ||
        truck.contractAuthorityStatus === FMCSAAuthorityStatus.Active)
    ) {
      if (truck.anonymous) {
        unfilteredAnonymousTrucks.push(truck);
      } else {
        unfilteredTrucks.push(truck);
      }
    } else {
      if (truck.anonymous) {
        filteredAnonymousTrucks.push(truck);
      } else {
        filteredTrucks.push(truck);
      }
    }
  }
  return {
    unfilteredTrucks: unfilteredTrucks,
    filteredTrucks: filteredTrucks,
    filteredAnonymousTrucks: filteredAnonymousTrucks,
    unfilteredAnonymousTrucks: unfilteredAnonymousTrucks,
  };
};

export const filterTruckMatches = (
  matches: Matches,
  filters: TruckFilters,
  onboardedCarriersDotNumbersList: string[]
): FilteredMatches => {
  const filtered = filterTrucks(
    concat(matches.truckList, matches.anonymousList),
    filters,
    onboardedCarriersDotNumbersList
  );
  return {
    truckList: filtered.unfilteredTrucks,
    anonymousList: filtered.unfilteredAnonymousTrucks,
    filteredList: concat(filtered.filteredTrucks, filtered.filteredAnonymousTrucks),
    nonList: matches.nonList,
  };
};

// splits the trucks into 4 category: matches, anonymousMatches, nonMatches and filteredMatches
export const splitTrucks = (trucks: Truck[]): Matches => {
  const matches = new Array<Truck>();
  const anonymousMatches = new Array<Truck>();
  const nonMatches = new Array<Truck>();

  for (const truck of trucks) {
    if (truck.id != null && truck.isAvailable) {
      if (truck.matchResult.overallMatch) {
        if (truck.anonymous) {
          anonymousMatches.push(truck);
        } else {
          matches.push(truck);
        }
      } else {
        nonMatches.push(truck);
      }
    }
  }
  return { truckList: matches, anonymousList: anonymousMatches, nonList: nonMatches };
};

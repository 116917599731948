import { cloneDeep } from 'lodash';

import { AllStates, AuthorityInfo, CompanyInsuranceInfo, CompanyLicensingInfo, CreditInfo } from '@common/model';
import { SortDirection } from '@common/model/Document';
import { PaginatedRequest } from '@common/redux/epic/PaginatedListReducer';

export const SEARCH_DIRECTORY_REQUEST_OFFSET_INCREMENT = 10;
export const SEARCH_DIRECTORY_REQUEST_LIMIT_DEFAULT = 20;
export const SEARCH_DIRECTORY_REQUEST_LIMIT_APPENDING = 10;

export enum ResultSortCategory {
  Name = 'Name',
  Location = 'Location',
  DotNo = 'DotNo',
  MCNo = 'MCNo',
  Phone = 'Phone',
}

export enum CompanyType {
  Carrier = 'Carrier',
  Broker = 'Broker',
  Shipper = 'Shipper',
}

export enum TableSource {
  /** Data comes from Dako.dbo.Companies */
  CompanyTable = 'CompanyTable',
  /** Data comes from Volpe.dbo.Carrier table */
  VolpeTable = 'VolpeTable',
}

enum DocketPrefix {
  None = 'None',
  DOT = 'DOT',
  MC = 'MC',
  FF = 'FF',
  MX = 'MX',
}

export interface SearchDirectoryCompanySort {
  direction: SortDirection;
  field: ResultSortCategory;
}

export interface SearchDirectoryCompanyRequest extends PaginatedRequest {
  companyType: CompanyType;
  companyName: string;
  state: string;
  sortExpression: ResultSortCategory;
  sortDirection: SortDirection;
  tableSource: TableSource;
}

const DefaultSearchDirectoryCompanyRequest: SearchDirectoryCompanyRequest = {
  companyType: CompanyType.Carrier,
  tableSource: TableSource.VolpeTable,
  companyName: '',
  state: '',
  sortExpression: ResultSortCategory.Name,
  sortDirection: SortDirection.Ascending,
  offset: 0,
  limit: SEARCH_DIRECTORY_REQUEST_LIMIT_DEFAULT,
};

export const createDefaultSearchDirectoryRequest = (): SearchDirectoryCompanyRequest =>
  cloneDeep(DefaultSearchDirectoryCompanyRequest);

export interface SearchDirectoryCompanyResponse {
  directories: DirectoryResponse[];
  count: number;
  totalCount: number;
}

export interface DirectoryResponse {
  companyGuid?: string;
  companyId?: number;
  isViewed?: boolean;
  details?: Details;
  address?: SearchDirectoryAddress;
  contactDetails?: ContactDetails;
  companyProfile?: CompanyProfile;
  authority?: Authority;
  insuranceInfo?: CompanyInsuranceInfo;
  creditInfo?: CreditInfo;
}

interface Details {
  name: string;
  businessType: CompanyType;
  carrierMcNumber: { prefix: DocketPrefix; number: number };
  brokerMcNumber: { prefix: DocketPrefix; number: number };
  usDotNumber: string;
  scacCode: string;
  interstateNumber: string;
}

export interface SearchDirectoryAddress {
  street: string;
  city: string;
  state: AllStates;
  zipCode: string;
  country: string;
}

interface ContactDetails {
  tel: string;
  tollFree: string;
  fax: string;
  website: string;
}

export interface CompanyProfile {
  yearFounded: string;
  isP3: boolean;
  isTia: boolean;
  isOcfp: boolean;
  briefProfile: string;
}

interface Authority {
  authorityNumbers?: { mcNumber: string; dotNumber: string };
  cargoAuthorization?: CompanyLicensingInfo;
  authorityInfo?: { authorities: AuthorityInfo[] };
}

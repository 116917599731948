import React from 'react';

import { Location } from 'history';
import { produce } from 'immer';
import { has, size } from 'lodash';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { setSearchFormPrefilled } from '@/reduxStore/reducer/LoadSearchFormReducer';
import { Routes } from '@/router/Routes';
import { ApiErrorCode } from '@common/api';
import { getSortByMatchingLoadSearchOrigin } from '@common/helper';
import { IKeysSortByLoads } from '@common/helper/SortByKeys';
import { SearchMetadataType, ViewField } from '@common/model';
import { PersistentSearch, SEARCH_ALERT_LIMIT } from '@common/redux/epic/loadSearch/HelperFunctions';
import {
  fetchAllSearches,
  setSelectedSearch,
  updateLastSearch,
  updateSearchAlert,
} from '@common/redux/epic/loadSearch/LoadSearchEpic';
import { mapSearchActions } from '@common/redux/epic/loadSearch/MapSearchEpic';
import { useDidUpdate } from '@common/util/hooks';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupCancelButton, PopupOkButton, PopupPrimaryButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { loadSearchRequestEncoder } from '@page/findLoads/LoadSearchRequestEncoder';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';

export enum ViewType {
  Search = 'Search',
  PickUp = 'PickUp',
  DropOff = 'DropOff',
  TrailerType = 'TrailerType',
  Dates = 'Dates',
  Home = 'Home',
  TripLength = 'TripLength',
}

export enum ResultViewType {
  Backhaul = 'Backhaul',
  LoadSearch = 'LoadSearch',
  Conversation = 'Conversation',
  TruckAlerts = 'TruckAlerts',
}

export const useOpenSearchResultPanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMapVisible = useSelector((state) => state.loadSearchSettings.viewOption.findLoads.field === ViewField.Map);

  return (persistentNamedSearch: PersistentSearch) => {
    const { id, search } = persistentNamedSearch;

    if (isMapVisible && id) {
      dispatch(mapSearchActions.fetchGeoLoadsById(id, { type: SearchMetadataType.Geo }));
    }

    dispatch(setSelectedSearch(search));
    dispatch(updateLastSearch(search));
    dispatch(setSearchFormPrefilled(false));

    const filter = loadSearchRequestEncoder.convertObjectRequestToUrlParams({
      metadata: search.metadata,
      searchData: search,
      searchId: id,
    });

    history.push({
      pathname: `${generatePath(Routes.LOADS_FINDLOADS, {
        ...(isMapVisible ? { map: 'map' } : { results: 'results' }),
      })}/`,
      search: filter,
    });
  };
};

export const useUpdateSearchAlert = () => {
  const dispatch = useDispatch();
  const popupContext = usePopup();
  const alerts = useSelector((state) => state.loadSearch.loadSearchAlerts.entities);

  return (namedSearchId?: string) => {
    if (namedSearchId) {
      if (alerts[namedSearchId]) {
        dispatch(updateSearchAlert({ namedSearchId: namedSearchId, hasAlert: false }));
      } else if (size(alerts) >= SEARCH_ALERT_LIMIT) {
        const { openingPopup, closingPopup } = popupContext;
        openingPopup({
          body: (
            <BasePopup
              title={t(T.common_loadAlerts_limitReached)}
              buttons={[<PopupOkButton testID={'ok'} key={'ok'} action={closingPopup} />]}
            >
              {t(T.common_loadAlerts_limitReachedContent)}
            </BasePopup>
          ),
          width: PopupSizes.SMALL,
          isDismissible: true,
          hasCloseButton: true,
        });
      } else {
        dispatch(updateSearchAlert({ namedSearchId: namedSearchId, hasAlert: true }));
      }
    }
  };
};

export const useOpenEditSearchPanel = () => {
  const history = useHistory();
  const selectedSearch = useSelector((state) => state.loadSearch.selectedSearch);
  const searches = useSelector((state) => state.loadSearch.searchesState.searches.entities);

  return (searchId?: string) => {
    let filter = loadSearchRequestEncoder.convertObjectRequestToUrlParams({
      searchData: selectedSearch,
      searchId: selectedSearch?.id,
    });

    if (searchId && has(searches, searchId) && selectedSearch?.id !== searchId) {
      filter = loadSearchRequestEncoder.convertObjectRequestToUrlParams({
        searchData: searches[searchId].search,
        searchId: searchId,
      });
    }

    history.push({
      pathname: `${generatePath(Routes.LOADS_FINDLOADS, { search: 'edit' })}/`,
      search: filter,
    });
  };
};

export const useDisplaySearchNotFoundDialogOnError = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openingPopup, closingPopup } = usePopup();
  const searchesState = useSelector((state) => state.loadSearch.searchesState);

  const onDismissDialog = () => {
    closingPopup();
    dispatch(fetchAllSearches(searchesState.viewFilter));
    history.replace({
      pathname: `${generatePath(Routes.LOADS_FINDLOADS)}/`,
    });
  };

  const displaySearchNotFoundDialog = () =>
    openingPopup({
      body: (
        <BasePopup
          title={t(T.common_namedSearch_deletedSearch)}
          buttons={[<PopupOkButton testID="ok" key="ok" action={onDismissDialog} />]}
        >
          {t(T.common_namedSearch_searchDoesNotExist)}
        </BasePopup>
      ),
      isDismissible: false,
      hasCloseButton: false,
      width: PopupSizes.SMALL,
    });

  useDidUpdate(() => {
    if (searchesState.error?.code === ApiErrorCode.NAMED_LOAD_SEARCH_NOT_FOUND) {
      displaySearchNotFoundDialog();
    }
  }, [searchesState.error?.code]);
};

export const useDisplaySaveSearchPopup = () => {
  const { openingPopup, closingPopup } = usePopup();

  return (onSave: () => void, onCancel: () => void) => {
    openingPopup({
      body: (
        <BasePopup
          title={t(T.common_namedSearch_saveSearchPopup_title)}
          buttons={[
            <PopupCancelButton
              testID="cancel"
              key="cancel"
              action={() => {
                onCancel();
                closingPopup();
              }}
            />,
            <PopupPrimaryButton
              testID="save"
              key="save"
              label={t(T.common_save)}
              action={() => {
                onSave();
                closingPopup();
              }}
            />,
          ]}
        >
          {t(T.common_namedSearch_saveSearchPopup_content)}
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };
};

export const isMapVisible = (location: Location) => location.pathname.includes('/map/');

export const getSearchWithSortBy = (search: PersistentSearch, sortBy: IKeysSortByLoads): PersistentSearch => {
  return produce(search, (newSearch) => {
    newSearch.search.metadata.sortBy = getSortByMatchingLoadSearchOrigin(newSearch.search.origin, sortBy);
  });
};

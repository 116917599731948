import { BaseClient } from '@common/client/BaseClient';
import { MembershipData, MembershipTypeResponse, RetentionPlanInfo, UserPlan } from '@common/model/UserPlan';

export class UserPlanManagementClient extends BaseClient {
  getUserPlans$ = (promoCode?: string, getCurrentPlan: boolean = true) =>
    this.api.get$<UserPlan>('/plans', { promoCode: promoCode, getCurrent: getCurrentPlan });

  postSelectAddOns$ = (addOnIds: string[]) => this.api.post$<{}>('/membership/selectAddOns', { addOnIds: addOnIds });

  postDeselectAddOns$ = (addOnIds: string[]) =>
    this.api.post$<{}>('/membership/deselectAddOns', { addOnIds: addOnIds });

  fetchMembershipsData$ = () => this.api.get$<MembershipData>('/membership', {});

  changeMembershipPlan$ = (planID: string) => this.api.post$<{}>(`/membership/changePlan?planId=${planID}`, {});

  createMembership$ = () => this.api.post$<{}>('/memberships/signUpInProgress', {});

  fetchMembershipType$ = () => this.api.get$<MembershipTypeResponse>('/memberships/type', {});

  getRetentionPlans$ = () => this.api.get$<RetentionPlanInfo>('/plans/retention', {});
}

import React, { useEffect, useState } from 'react';

import { round, toString } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { displayCurrencyOrDash } from '@common/formatter';
import {
  convertBaseLocationToLoadLocation,
  hasRoles,
  priorityEquipmentAvailableForRateCheck,
  toAddress,
} from '@common/helper';
import {
  BaseLocation,
  Equipment,
  LoadWithDataForBiddingOnly,
  RateCheck,
  Roles,
  UnlockFeaturePopupType,
} from '@common/model';
import { fetchRateCheck } from '@common/redux/epic/RateCheckEpic';
import { ActionIcon } from '@component/icon/ActionIcon';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { BasePopup } from '@component/popup/BasePopup';
import { PlaceBidPopup } from '@component/popup/PlaceBidPopup';
import { PopupOkButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { useUnlockFeaturePopup } from '@component/popup/UnlockFeaturePopup';
import { Text, TextStyle } from '@component/text';
import { Color } from '@style/Color';
import { Spacing } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

const TripInfoBar = styled.div`
  background: ${Color.GRAY_LIGHT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${Spacing.InterElementHorizontal}px;
  margin-top: -${Spacing.InterElementHorizontal}px;
  padding: 0 10px;
  border-radius: 4px;
  height: 30px;
  box-sizing: border-box;
`;

const TripInfoText = withTheme()(styled.span`
  font-family: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.fontSize};
  line-height: ${(props: ThemeProps) => props.theme.text.subtitle2Regular.lineHeight};
  color: ${(props: ThemeProps) => props.theme.palette.background.stone};
`);

const TripInfoTextValue = withTheme()(styled.span`
  color: ${(props: ThemeProps) => props.theme.text.defaultLight.color};
`);

const TripInfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RightButton = styled.span`
  && {
    font-weight: ${THEME.text.link.fontWeight};
    color: ${Color.ORANGE_MAIN};
    cursor: pointer;
  }
`;

interface PostBidRateCheckProps {
  load?: LoadWithDataForBiddingOnly;
  onClickRateCheck: (value: string) => void;
}

export const PostBidRateCheck = (props: PostBidRateCheckProps) => {
  const { openingPopup } = usePopup();

  const onCloseInfoPopup = () => {
    openingPopup({
      body: <PlaceBidPopup load={props.load} />,
      isDismissible: true,
      hasCloseButton: true,
      width: PopupSizes.SMALL,
    });
  };

  return (
    <BidRateCheck
      origin={props.load?.originLocation}
      destination={props.load?.destinationLocation}
      equipments={props.load?.equipments}
      computedMileage={props.load?.computedMileage}
      rateCheck={props.load?.rateCheck}
      onCloseInfoPopup={onCloseInfoPopup}
      onClickRateCheck={props.onClickRateCheck}
    />
  );
};

interface BidRateCheckProps {
  isLoading?: boolean;
  origin?: BaseLocation;
  destination?: BaseLocation;
  equipments?: Equipment[];
  computedMileage?: number;
  rateCheck?: RateCheck;
  onCloseInfoPopup?: () => void;
  onClickRateCheck: (value: string) => void;
}

export const BidRateCheck = (props: BidRateCheckProps) => {
  const {
    isLoading,
    origin,
    destination,
    equipments = [],
    computedMileage,
    rateCheck,
    onCloseInfoPopup,
    onClickRateCheck,
  } = props;

  const dispatch = useDispatch();
  const { openingPopup, closingPopup } = usePopup();
  const openUnlockPopup = useUnlockFeaturePopup();

  const [wasMileageFetched, setWasMileageFetched] = useState(false);

  const userRoles = useSelector((state) => state.user.profile?.payload);
  const doesUserHaveRateCheck = hasRoles(userRoles?.roles, [Roles.Ratecheck]);

  const rateCheckIsLoading = useSelector((state) => isLoading ?? state.rateCheck.isLoading);
  const rateCheckError = useSelector((state) => state.rateCheck.rateCheckError?.code);

  const originLocation = convertBaseLocationToLoadLocation(origin);
  const destinationLocation = convertBaseLocationToLoadLocation(destination);

  const equipment =
    equipments.length > 0 ? priorityEquipmentAvailableForRateCheck([equipments[0].equipmentType]) : undefined;

  const rateCheckValue =
    rateCheck?.avgRatePerMile && computedMileage ? rateCheck.avgRatePerMile * computedMileage : undefined;

  useEffect(() => {
    const mileageOrigin = toAddress(originLocation);
    const mileageDestination = toAddress(destinationLocation);

    if (mileageOrigin && mileageDestination && equipment) {
      dispatch(
        fetchRateCheck({
          from: mileageOrigin,
          to: mileageDestination,
          equipment: equipment,
        })
      );
    }

    const totalPostedMileage = mileageOrigin && mileageDestination ? computedMileage : undefined;
    if (totalPostedMileage) {
      setWasMileageFetched(true);
    }
  }, [origin, destination, equipment]);

  const handleClickRate = (value?: number) => {
    if (value) {
      onClickRateCheck(toString(round(value)));
    }
  };

  const openFeatureInfoPopup = (title: string, content: string, testID: string) => {
    openingPopup({
      body: (
        <BasePopup
          title={title}
          buttons={[<PopupOkButton testID={testID} key={testID} action={onCloseInfoPopup ?? closingPopup} />]}
          onClose={onCloseInfoPopup ?? closingPopup}
        >
          {content}
        </BasePopup>
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

  const renderRateCheck = () => {
    if (rateCheckIsLoading && doesUserHaveRateCheck) {
      return (
        <TripInfoTextValue id="avgRate_loading">
          <LoadingSpinner size={15} />
        </TripInfoTextValue>
      );
    } else if (!doesUserHaveRateCheck) {
      return (
        <RightButton id="avg_rate_unlock" onClick={() => openUnlockPopup(UnlockFeaturePopupType.RateCheck)}>
          {t(T.findLoads_loadDetails_unlock)}
        </RightButton>
      );
    } else if (
      (doesUserHaveRateCheck && rateCheckError === 404) ||
      (doesUserHaveRateCheck && equipment === undefined && wasMileageFetched === true)
    ) {
      return <TripInfoTextValue id="ratecheck_value_not_available">{t(T.common_na)}</TripInfoTextValue>;
    } else {
      return (
        <span style={{ cursor: 'pointer' }} onClick={() => handleClickRate(rateCheckValue)}>
          <Text textStyle={TextStyle.Link} id="avgRate">
            {displayCurrencyOrDash(rateCheckValue, false)}
          </Text>
        </span>
      );
    }
  };

  return (
    <TripInfoBar>
      <TripInfoText id="avg_rate_check">
        {`${t(T.postLoads_averageRateByCheck)}: `}
        {renderRateCheck()}
      </TripInfoText>
      <TripInfoIconWrapper>
        <ActionIcon
          onClick={() =>
            openFeatureInfoPopup(
              t(T.common_infoModal_rateCheck_title),
              t(T.common_infoModal_rateCheck_subtitle),
              'avg_rate_info_confirm'
            )
          }
          id={'rate_per_mile_info'}
          src={icons.info_small}
          alt={'info_button'}
          size={20}
          style={{ paddingLeft: 5 }}
        />
      </TripInfoIconWrapper>
    </TripInfoBar>
  );
};

import { Action } from 'redux';

export enum TutorialAction {
  START_LOAD_TUTORIAL = 'START_LOAD_TUTORIAL',
  STOP_LOAD_TUTORIAL = 'STOP_LOAD_TUTORIAL',
  START_TRUCK_TUTORIAL = 'START_TRUCK_TUTORIAL',
  STOP_TRUCK_TUTORIAL = 'STOP_TRUCK_TUTORIAL',
}

export function toggleTutorial(action: TutorialAction): Action {
  return {
    type: action,
  };
}

export interface TutorialState {
  runLoadTutorial: boolean;
  runTruckTutorial: boolean;
}

export const tutorialReducer = (state: TutorialState, action: Action) => {
  switch (action.type) {
    case TutorialAction.START_LOAD_TUTORIAL:
      return { ...state, runLoadTutorial: true };
    case TutorialAction.STOP_LOAD_TUTORIAL:
      return { ...state, runLoadTutorial: false };
    case TutorialAction.START_TRUCK_TUTORIAL:
      return { ...state, runTruckTutorial: true };
    case TutorialAction.STOP_TRUCK_TUTORIAL:
      return { ...state, runTruckTutorial: false };
  }

  return state ? state : { runLoadTutorial: false, runTruckTutorial: false };
};

import React from 'react';

import styled from 'styled-components';

import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';

interface Props {
  id: string;
  title: string;
  renderRight: () => React.ReactNode;
}

const Container = styled.div`
  word-wrap: break-word;
  align-items: baseline;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-column-gap: 8px;
`;

const BaseText = styled.span`
  color: ${Color.GRAY_DARK};
  line-height: 20px;
`;

const TextContentDetailDark = styled(BaseText)`
  font-size: ${FontSize.ContentDetail}px;
`;

const TextContentDetailDarkBold = styled(TextContentDetailDark)`
  font-weight: bold;
`;

export const renderLoadDetailsValueRowTextRight = (text: string) => () => {
  return <TextContentDetailDarkBold id="value">{text}</TextContentDetailDarkBold>;
};

export const LoadDetailsValueRow: React.FC<Props> = (props: Props) => {
  const { title, renderRight } = props;
  return (
    <Container id={props.id}>
      <TextContentDetailDark id="title">{title}</TextContentDetailDark>
      <div>{renderRight && renderRight()}</div>
    </Container>
  );
};

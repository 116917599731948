import React, { useLayoutEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { closeMenu } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { MENU_HEADER_HEIGHT, MenuSimpleBar } from '@component/menu/menuStyles';
import { Color } from '@style/Color';
import { MOBILE_MENU_ZINDEX, Opacity } from '@style/StyleConstants';
import { ThemeProps } from '@style/WithTheme';
import { useIsMobileView, useSelector } from '@util/hooks';
import { MediaQueries } from '@util/MediaQueries';

import { useIsWindowScrollMode } from './mainHooks';

export const MINIMUM_SCREEN_WIDTH = 320;
const MOBILE_MENU_TRANSITION_MS = 150;
const MOBILE_MENU_TRANSITION_TIMING_FUNC = 'cubic-bezier(0.765, 0.005, 0.755, 0.605)';

interface HeaderProps extends ThemeProps {
  opened: boolean;
  style?: any;
}

interface ContentProps {
  opened: boolean;
  isWindowScrollingDrawer: boolean;
  fakeScrollTop: number;
}

const MobileDrawerBaseDiv = styled<HeaderProps>(({ opened, ...rest }) => <div {...rest} />)`
  position: fixed;
  left: 0;
  width: 300px;
  @media ${MediaQueries.xxs} {
    width: ${MINIMUM_SCREEN_WIDTH}px;
  }
  overflow: hidden;
  transform: translateX(${({ opened }: HeaderProps) => (opened ? '0%' : '-100%')});
  transition: transform ${MOBILE_MENU_TRANSITION_MS}ms ${MOBILE_MENU_TRANSITION_TIMING_FUNC};
  background-color: ${Color.MENU_BLACK};
`;

const MobileDrawerHeaderDiv = styled(MobileDrawerBaseDiv)`
  height: ${MENU_HEADER_HEIGHT}px;
  min-height: ${MENU_HEADER_HEIGHT}px;
  z-index: ${MOBILE_MENU_ZINDEX + 1};
`;

/** overscroll background is what you see when the page is scrolled "beyond"
 * the end or begining of the page (iOS). Without this, we see the page underneath.
 */
const MobileDrawerOverscrollBackground = styled(MobileDrawerBaseDiv)`
  height: 100vh;
  min-height: 100vh;
  z-index: ${MOBILE_MENU_ZINDEX - 1};
`;

const MobileDrawerContentDiv = styled<ContentProps>(({ isWindowScrollingDrawer, fakeScrollTop, ...rest }) => (
  <MobileDrawerBaseDiv {...rest} />
))`
  position: ${({ isWindowScrollingDrawer: isWindowScrollingDrawer }) =>
    isWindowScrollingDrawer ? 'absolute' : 'fixed'};
  margin-top: ${({ fakeScrollTop }) => -fakeScrollTop}px;
  padding-bottom: ${({ fakeScrollTop }) => fakeScrollTop}px;
  padding-top: ${MENU_HEADER_HEIGHT}px;
  min-height: 100vh;
  z-index: ${MOBILE_MENU_ZINDEX};
`;

const MobileMenuBackdrop = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${Color.MENU_BLACK};
  opacity: ${Opacity.light};
  z-index: ${MOBILE_MENU_ZINDEX - 1};
`;

export const MobileMenuGrayLayout: React.FC = () => {
  const isMobile = useIsMobileView();
  const isMenuOpened = useSelector((store) => store.applicationSettings.menuOpen);
  const dispatch = useDispatch();

  return isMobile && isMenuOpened ? <MobileMenuBackdrop onClick={() => dispatch(closeMenu())} /> : null;
};

export const MobileDrawerHeader: React.FC = ({ children }) => {
  const menuOpen = useSelector((store) => store.applicationSettings.menuOpen);

  return (
    <>
      <MobileDrawerOverscrollBackground opened={menuOpen} />
      <MobileDrawerHeaderDiv opened={menuOpen}>{children}</MobileDrawerHeaderDiv>
    </>
  );
};

export const MobileDrawerContent: React.FC = ({ children }) => {
  const menuOpen = useSelector((store) => store.applicationSettings.menuOpen);
  const isWindowScrollMode = useIsWindowScrollMode();
  const simpleBarRef = useRef(null);
  const [isWindowScrollingDrawer, setWindowScrollingDrawer] = useState(false);
  const [fakeScrollTop, setFakeScrollTop] = useState(0);

  useLayoutEffect(() => {
    if (isWindowScrollMode) {
      if (menuOpen) {
        // about to open the menu. Reset window.scroll after "faking" window scroll position.
        setFakeScrollTop(0);
        setWindowScrollingDrawer(true);
      } else {
        setFakeScrollTop(window.pageYOffset);
        window.scroll(0, 0);
        setWindowScrollingDrawer(false);
      }
    }
  }, [menuOpen]);

  return (
    <MobileDrawerContentDiv
      opened={menuOpen}
      fakeScrollTop={fakeScrollTop}
      isWindowScrollingDrawer={isWindowScrollingDrawer}
    >
      {isWindowScrollMode ? (
        children
      ) : (
        <MenuSimpleBar innerRef={simpleBarRef} opened={menuOpen}>
          {children}
        </MenuSimpleBar>
      )}
    </MobileDrawerContentDiv>
  );
};

import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { default as FileCopy } from '@material-ui/icons/FileCopy';

import { MultipurposeButton } from '@component/buttons';
import { singleDoubleColumnSelect } from '@component/panelSizeDetector/PanelSizeDetector';
import { Color } from '@style/Color';
import { BorderWidth, ElementSize, FontSize, FontWeight } from '@style/StyleConstants';
import THEME from '@style/Theme';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { MediaQueries } from '@util/MediaQueries';

const lineOrUndefined = (isWithoutTopLine = false) => (isWithoutTopLine ? undefined : `1px solid ${Color.GRAY_SMOKE}`);
export const ActionLoadRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 2px;
  height: ${ElementSize.ActionLoadRowHeight}px;
  justify-content: ${singleDoubleColumnSelect('space-around', 'space-between')};
  border-bottom: ${singleDoubleColumnSelect('none', `${BorderWidth.Thin}px solid ${Color.GRAY_SMOKE}`)};
  background-color: ${Color.WHITE};
  @media ${MediaQueries.xxs} {
    padding: 0 6px 0 2px;
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  padding: 0 2px 0 2px;
`;

export const ContactButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 345px;
`;

export const ActionButton = withTheme()(styled(Button)`
  && {
    display: flex;
    align-items: center;
    font-size: ${(props: ThemeProps) => props.theme.text.note.fontSize};
    text-transform: none;
    margin-right: 1px;
    min-width: 55px;
    img {
      margin-right: 3px;
    }
    @media ${MediaQueries.xxs} {
      padding: 6px 6px;
    }
  }
`);

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  wrap-content: center;
`;

export const ActionButtonTitle = styled.span`
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  align-self: center;
`;

export const LoadDetailsContent = styled.div`
  display: flex;
  flex-grow: 1;
  & > :first-child {
    border-right: 1px solid ${THEME.palette.background.smoke};
  }
  .column {
    width: ${singleDoubleColumnSelect('auto', '50%')};
  }

  background: ${THEME.palette.background.paper};
  border-top: 1px solid ${THEME.palette.background.smoke};

  flex-direction: ${singleDoubleColumnSelect('column', 'initial')};
`;

export const LoadData = styled.div`
  & > :last-child {
    border-bottom: ${singleDoubleColumnSelect(`1px solid ${THEME.palette.background.smoke}`, 'none')};
  }
`;

export const Row = styled.div`
  padding: 15px 20px 0 20px;
  border-bottom: 1px solid ${THEME.palette.background.smoke};
`;

export const TitleText = styled.span`
  display: block;
  font-size: 16px;
`;

export const ModalTitleBold = styled.span`
  font-size: ${FontSize.ModalTitle}px;
  font-weight: ${FontWeight.Bold};
`;

export const BoldText = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

export const Flex = styled.div`
  display: flex;
`;

export const AlignBaselineRow = styled(Flex)`
  align-items: baseline;
  align-self: stretch;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RateCheckDataValue = styled(Flex)`
  flex: 0.6;
`;

export const RateCheckDataInfo = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const PostedRate = styled(ModalTitleBold)`
  color: ${Color.GRAY_STONE};
`;

export const RateDiff = styled(({ rateDiff, ...rest }) => <PostedRate {...rest} />)`
  color: ${({ rateDiff }) => (rateDiff < 0 ? Color.RED_APPLE : Color.GREEN_MAIN)};
`;

export const FullWidthColumn = styled(Flex)`
  align-items: baseline;
  flex-direction: column;
  justify-self: stretch;
  justify-content: space-between;
`;

export const ExpansionPanelDataWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Color.GRAY_SMOKE};
`;

export const LoadDetailsContainer = styled(({ isWithoutTopLine, ...rest }) => <div {...rest} />)`
  border-top: ${({ isWithoutTopLine }) => lineOrUndefined(isWithoutTopLine)};
  padding: 15px;
`;

export const PostedRateDifferenceContainer = styled(LoadDetailsContainer)`
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightAlignBold = styled.span`
  font-weight: bold;
  text-align: right;
`;

export const InfoIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 12px;
  cursor: pointer;
`;

export const BigText = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

export const ContentDetailText = styled.span`
  font-size: ${FontSize.ContentDetail}px;
`;

export const TwoColumnTextGrid = styled.div`
  font-size: 14px;
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const GetFuelCardLink = withTheme()(styled(Link)`
  color: ${(props: ThemeProps) => props.theme.palette.secondary.main};
  text-decoration: none;
  font-weight: 700;
`);

export const CompanyTextWrapper = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
`;

export const IconThunder = styled.img`
  width: 66px;
  margin-left: 7px;
`;

export const RatingDaraWrapper = styled.div`
  display: grid;
  grid-template-columns: 25% 35% 40%;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      font-size: 12px;
      opacity: 0.5;
    }

    img {
      max-width: 105px;
    }
  }
`;

export const ReportButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    img {
      height: 24px;
      width: 24px;
      margin-right: 5px;
    }
  }
`;

export const GetPaidFasterLink = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${THEME.palette.secondary.main};
  text-transform: none;
`;

export const SpecialLink = styled.a`
  padding: 0 15px;
  border-top: 1px solid ${Color.GRAY_SMOKE};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  height: 75px;
  text-decoration: none;

  :visited {
    color: ${(props: ThemeProps) => props.theme.palette.text.primary};
  }

  .arrow-icon {
    padding: 0 5px 0 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .icon {
    height: 24px;
    width: 24px;
    object-fit: contain;
    margin-right: 25px;
  }
`;

export const FuelDiscount = withTheme()(styled(FormControlLabel)`
  && {
    margin: 0;
    align-items: flex-start;
    margin-right: 37px;
    p {
      margin: 0;
    }
    > :first-child {
      padding-top: 0;
    }
    > :last-child {
      a {
        color: ${(props: ThemeProps) => props.theme.palette.secondary.main};
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
`);

export const InputWrapper = styled.div`
  && {
    > div {
      min-width: 0;
    }
  }
`;

export const CopyToClipboardButton = styled(Button)`
  && {
    width: 100%;
    margin: 0;
    text-transform: none;
    font-size: ${FontSize.Content}px;
    justify-content: start;
    box-sizing: border-box;
  }
`;

export const ShareActionButton = styled(CopyToClipboardButton)`
  && {
    > span > div {
      width: 100%;
    }
  }
`;

export const CopyToClipboardText = styled.span`
  margin-left: 8px;
  text-align: start;
`;

export const CopyIcon = styled(FileCopy)`
  && {
    width: 14px;
    height: 14px;
    padding: 8px;
  }
`;
export const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${Color.GRAY_LIGHT};
`;

export const MyNoteTitle = styled.span`
  font-size: ${FontSize.ContentDetail}px;
  color: ${Color.GRAY_STONE};
`;

export const UnreadNotificationCount = styled.div`
  font-family: 'overpass', sans-serif;
  color: ${Color.GRAY_LIGHT};
  background-color: ${Color.RED_APPLE};
  border: solid 1px ${Color.GRAY_SMOKE};
  position: absolute;
  height: 18px;
  width: 18px;
  top: -5px;
  right: 0px;
  border-radius: 10px;
  line-height: 1.5;
`;

export const TextContainer = styled.div`
  margin-bottom: 16px;
`;

export const PopUpButton = styled(MultipurposeButton)`
  flex: 1;
  margin-right: 10px;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

export const OverlayContent = styled.div`
  font-size: ${FontSize.ContentDetail};
`;

export const OverlayContentTextContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  line-height: 18px;
`;

export const OverlayContentTitle = styled.text`
  font-size: ${FontSize.ContentDetail}px;
  font-weight: ${FontWeight.Regular};
  width: 81px;
  min-width: 81px;
`;

export const OverlayContentText = styled.text`
  font-size: ${FontSize.ContentDetail}px;
  font-weight: ${FontWeight.Bold};
`;

export const OverlayTextDetail = styled.text`
  font-size: ${FontSize.ContentDetail}px;
  font-weight: ${FontWeight.Light};
`;

import { useEffect, useRef } from 'react';

import { filter, includes, last } from 'lodash';
import { useDispatch } from 'react-redux';

import { useAppVisibilityChange } from '@util/hooks/UseVisibility';

import { setCurrentTabKey, setTabControlInitialization } from '../../reduxStore/epic/UserSettingsEpic';
import { useSelector } from './UseRedux';

const OPENED_TABS_KEY = 'openedTabs';
const ACTIVE_TAB_KEY = 'activeTab';

export const useOpenedTabsControl = () => {
  const { currentTabKey } = useSelector((state) => state.usersSettings.openedTabsControl);
  const latestCurrentTabKey = useRef(currentTabKey);
  latestCurrentTabKey.current = currentTabKey;

  const dispatch = useDispatch();

  const onPageVisibilityChange = (isVisible: boolean) => {
    if (latestCurrentTabKey.current && isVisible) {
      if (getActiveTab() !== latestCurrentTabKey.current) {
        setActiveTab(latestCurrentTabKey.current);
      }
      const openedTabs = getOpenedTabs();
      if (!includes(openedTabs, latestCurrentTabKey.current)) {
        setOpenedTabs([...openedTabs, latestCurrentTabKey.current]);
      }
    }
  };

  useAppVisibilityChange(onPageVisibilityChange);

  const onCloseTab = () => {
    const openedTabs = getOpenedTabs();
    const activeTab = getActiveTab();
    const updatedTabsList = filter(openedTabs, (key) => key !== latestCurrentTabKey.current);
    setOpenedTabs(updatedTabsList);
    if (activeTab === latestCurrentTabKey.current || updatedTabsList.length === 0) {
      setActiveTab('');
    }
  };

  const tabInitialization = () => {
    const uniqueTabKey = Date.now().toString();
    const openedTabs = getOpenedTabs();
    dispatch(setTabControlInitialization(true));
    dispatch(setCurrentTabKey(uniqueTabKey));
    const newWindowArray = [...openedTabs, uniqueTabKey];
    setOpenedTabs(newWindowArray);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onCloseTab);
    window.addEventListener('unload', onCloseTab);
    tabInitialization();
    return () => {
      window.removeEventListener('beforeunload', onCloseTab);
      window.removeEventListener('unload', onCloseTab);
    };
  }, []);
};

export const useIsMainTab = () => {
  const { isOpenedTabsControlInitialized, currentTabKey } = useSelector(
    (state) => state.usersSettings.openedTabsControl
  );

  const getIsTabMainOrActive = (): boolean => {
    const openedTabs = getOpenedTabs();
    const activeTab = getActiveTab();
    if (isOpenedTabsControlInitialized) {
      if (activeTab) {
        if (activeTab === currentTabKey) {
          return true;
        }
        return false;
      }
      if (openedTabs.length <= 1 || last(openedTabs) === currentTabKey) {
        return true;
      }
      return false;
    } else {
      if (openedTabs.length === 0) {
        return true;
      }
      return false;
    }
  };

  return getIsTabMainOrActive();
};

const getOpenedTabs = () => {
  const openedTabs = window.localStorage.getItem(OPENED_TABS_KEY);
  return openedTabs ? JSON.parse(openedTabs) : [];
};

const setOpenedTabs = (data: string[]) => {
  window.localStorage.setItem(OPENED_TABS_KEY, JSON.stringify(data));
};

const getActiveTab = () => {
  const activeTab = window.localStorage.getItem(ACTIVE_TAB_KEY);
  return activeTab ? activeTab : '';
};

const setActiveTab = (tabKey?: string) => {
  window.localStorage.setItem(ACTIVE_TAB_KEY, tabKey ? tabKey : '');
};

import React, { useEffect, useState } from 'react';

import { isEqual, map } from 'lodash';

import { FormHelperText, InputLabel } from '@material-ui/core';
import FilledInput from '@material-ui/core/FilledInput';
import { MenuItemProps } from '@material-ui/core/MenuItem/MenuItem';
import { KeyboardArrowDown } from '@material-ui/icons';

import { StyledMenuItem, StyledSelect, StyledSelectWrapper } from '@component/select/SelectStyles';
import { Color } from '@style/Color';

export interface SelectElementProps {
  id: string;
  options: string[];
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export const SelectElement = (props: SelectElementProps) => {
  const [value, setValue] = useState(props.value);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }

    setValue(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setIsOpened(false);
      return;
    }
  };

  return (
    <>
      <StyledSelectWrapper
        id={props.id}
        style={props.style}
        variant="filled"
        onMouseDown={props.onClick}
        onKeyDown={handleKeyDown}
        disabled={props.disabled}
      >
        {props.label && (
          <InputLabel error={props.error} id="title" htmlFor="filled-simple">
            {props.label}
          </InputLabel>
        )}
        <StyledSelect
          value={value}
          // @FIXME: This is a temporary fix. This allows the popup to have some breathing room on the right
          // on screens smaller than 770px. The breakpoints from Material-UI doesn't work as they are not
          // set in the THEME object. Ideally we would like to do it with css. Fix when trying to add errors.
          MenuProps={{ style: screen.width < 770 ? { left: -8 } : { left: 0 } }}
          onChange={handleChange}
          input={<FilledInput error={props.error} name={props.label} id="filled-simple" />}
          IconComponent={KeyboardArrowDown}
          label={props.label}
          disabled={props.disabled}
          open={isOpened}
          onOpen={() => {
            setIsOpened(true);
          }}
          onClose={(e: React.ChangeEvent<{}>) => {
            setIsOpened(false);
            e.preventDefault();
          }}
        >
          {map(props.options, (option, index) => (
            <SelectOnTabMenuItem
              key={index}
              value={option}
              style={isEqual(value, option) ? { backgroundColor: Color.GREEN_MAIN } : undefined}
              id={`item_${option}`}
              aria-selected={isEqual(value, option)}
              onChange={handleChange}
            >
              {option}
            </SelectOnTabMenuItem>
          ))}
        </StyledSelect>
      </StyledSelectWrapper>
      {props.error ? (
        <FormHelperText style={{ marginTop: 5, marginLeft: 12 }} error={props.error}>
          {props.helperText}
        </FormHelperText>
      ) : undefined}
    </>
  );
};

const SelectOnTabMenuItem = React.forwardRef(
  (
    { 'data-value': valueForEvent, value, onChange, ...other }: MenuItemProps & any,
    ref: React.RefObject<HTMLElement>
  ) => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Tab') {
        event.persist();
        // Getting the value from the "data-value" prop is necessary
        // due to the manner in which Material-UI clones the MenuItem during
        // display of the Select: https://github.com/mui-org/material-ui/blob/v4.5.1/packages/material-ui/src/Select/SelectInput.js#L226
        event.target = { value: valueForEvent };
        onChange?.(event);
      }
    };
    return <StyledMenuItem ref={ref} value={value} onKeyDown={handleKeyDown} {...other} />;
  }
);

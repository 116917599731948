import { Company } from '@common/model';

export const CARRIER_REQUEST_OFFSET_DEFAULT = 0;
export const CARRIER_REQUEST_OFFSET_INCREMENT = 10;

export const CARRIER_REQUEST_LIMIT_DEFAULT = 20;
export const CARRIER_REQUEST_LIMIT_APPENDING = 10;

export interface CarrierFeedback {
  onboarded?: boolean;
}

export interface CarrierStatus {
  isFavorite?: boolean;
  isBlocked?: boolean;
}

export interface CarrierSearchRequest extends CarrierStatus {
  isOnboarded?: boolean;
  companyName?: string;
}

export interface UpdateCarrierRequest {
  id: string;
  status: CarrierStatus;
}

export interface UpdateCarrierFeedbackRequest {
  id: string;
  feedback: CarrierFeedback;
}

export interface UpdateCarriersRequest {
  contactIds: string[];
  status: CarrierStatus;
}

export interface UpdateCarriersFeedbackRequest {
  contactIds: string[];
  feedback: CarrierFeedback;
}

export interface CarrierSearchResponse {
  carriers: CarrierContactInfo[];
  totalCount: number;
  count: number;
}

interface Phone {
  id: string;
  lineType: string;
  lineNumber: string;
  extension: string;
  countryCode: string;
}

export interface CarrierContactInfo {
  id: string;
  company: Company;
  guid: string;
  onboarded: boolean;
  isFavorite: boolean;
  isBlocked: boolean;
  email: string;
  companyId: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneExt: string;
  mobile: string;
  mobilePhone: Phone;
  primaryPhone: Phone;
}

export const createDefaultCarriersRequest = () => ({
  offset: CARRIER_REQUEST_OFFSET_DEFAULT,
  limit: CARRIER_REQUEST_LIMIT_DEFAULT,
});

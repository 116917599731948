import { ExpediteAllEquipmentType } from '@common/helper';
import { PayRateUnit, RateCheckPreviewStatus } from '@common/model';

import { Address } from './Address';
import { EquipmentType } from './Equipment';
import { LoadGeolocation, LoadLocationCity, LoadLocationZip } from './LoadSearchRequest';

export interface RateCheck {
  avgRatePerMile?: number;
  ratePerMileMin?: number;
  ratePerMileMax?: number;
  averageRateForMileage?: number;
  minRateForMileage?: number;
  maxRateForMileage?: number;
  loadsIncluded?: number;
  fuelCost?: number;
  totalMileage?: number;
  linehaulRevenue?: number;
  tollCosts?: number;
  mpg?: number;
  fuelSurcharge?: number;
  rateIncludesFuelSurcharge?: boolean;
  month: number;
}

export type RateCheckEquipmentTypes =
  | EquipmentType.Flatbed
  | EquipmentType.Reefer
  | EquipmentType.Van
  | EquipmentType.StepDeck;

export interface RateCheckRequest {
  from: Address;
  to: Address;
  equipment: RateCheckEquipmentTypes;
}

export interface MarketRankingRequest {
  origin: Address;
  destination: Address;
  mileage?: number;
  rate?: number;
  equipment?: RateCheckEquipmentTypes;
}

export enum NegotiationStrength {
  Unknown = 'Unknown',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export interface MarketRankingResponse {
  negotiationStrength: NegotiationStrength;
  backhaulRateDifference: number;
}

export interface ThirdPartyLaneRateResponse {
  source: string;
  cost: number;
  costLow: number;
  costHigh: number;
  costPerMile: number;
  costLowPerMile: number;
  costHighPerMile: number;
  fuelCost: number;
  fuelCostPerMile: number;
  laneMiles: number;
  lineHaulCost: number;
  lineHaulCostHigh: number;
  lineHaulCostLow: number;
  isRateFound?: boolean;
  error?: string;
}

interface LaneRateAddress {
  state?: string;
  city?: string;
  zipCode?: string;
}

export enum ThirdPartyLaneRateSource {
  All = 'All',
  Greenscreens = 'Greenscreens',
  CargoChief = 'Cargo Chief',
}

export interface ThirdPartyLaneRateRequest {
  equipment: EquipmentType;
  sources: ThirdPartyLaneRateSource[];
  from: LaneRateAddress;
  to: LaneRateAddress;
}

export interface RateCheckFeedbackRequest {
  request?: RateCheckRequest;
  response: RateCheck;
  userAccurate?: boolean;
  expectedRate?: number;
  amountType?: PayRateUnit.PerMile; // The API will also accept 'Flat', but there is no front end implementation to differentiate yet
}

export type RateCheckLocation = LoadGeolocation | LoadLocationZip | LoadLocationCity;

export interface RateCheckHistoryRequest {
  request: {
    origin: Address;
    destination: Address;
    equipment: RateCheckEquipmentTypes;
    startDate: string;
    endDate: string;
    expansionLevel: RateCheckExpansionLevel;
    timeSpan?: RateCheckTimeSpan;
  };
}

export interface LoadRateCheckHistoryRequest {
  startDate: string;
  endDate: string;
  expansionLevel: RateCheckExpansionLevel;
  timeSpan?: RateCheckTimeSpan;
}

export enum RateCheckExpansionLevel {
  None = 'None',
  CityToCity = 'CityToCity',
  CityToCounty = 'CityToCounty',
  CountyToCity = 'CountyToCity',
  CountyToCounty = 'CountyToCounty',
  CityToState = 'CityToState',
  StateToCity = 'StateToCity',
  CountyToState = 'CountyToState',
  StateToCounty = 'StateToCounty',
  StateToState = 'StateToState',
  NationalAverage = 'NationalAverage',
}

export enum AverageRateInitialExpansionLevel {
  City = 'City',
  County = 'County',
  State = 'State',
}

export enum RateCheckTimeSpan {
  Monthly = 'Monthly',
  Daily = 'Daily',
  Weekly = 'Weekly',
}

export interface RateCheckDataPoint {
  rate: number;
  date: number;
}

export interface RateCheckRevenueResponse {
  averageRatePerMileData: RateCheckDataPoint[];
  ratePerMileMinData: RateCheckDataPoint[];
  ratePerMileMaxData: RateCheckDataPoint[];
  averageFlatRateForMileageData: RateCheckDataPoint[];
  minFlatRateForMileageData: RateCheckDataPoint[];
  maxFlatRateForMileageData: RateCheckDataPoint[];
  maxRatePerMile: number;
  minRatePerMile: number;
  maxFlatRate: number;
}

export interface RateCheckFeedback {
  timeAdded: number;
  isSuccess: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  expectedRate: number | undefined;
  isRateCheckAccurate: boolean | undefined;
  response: RateCheck | undefined;
  request: RateCheckRequest | undefined;
  loadID: string | undefined;
}

export interface RateCheckFeedbackCollection {
  [route: string]: RateCheckFeedback;
}

export type PartialRateCheckFeedback = Partial<RateCheckFeedback>;

export interface RateCheckRevenueData {
  date: string;
  endDate: string;
  avgRatePerMile: number;
  ratePerMileMin?: number;
  ratePerMileMax?: number;
  averageRateForMileage: number;
  minRateForMileage?: number;
  maxRateForMileage?: number;
  loadsIncluded?: number;
}

export interface LoadRateCheckPreviewResponse {
  loadId: string;
  isRateFound: boolean;
  ratePerMile?: number;
  totalRate?: number;
  searchedEquipment?: string;
}

interface LoadRateCheckPreviewSkipReason {
  loadId: string;
  skipReasons: Array<{ skipReason: RateCheckPreviewStatus; description: string }>;
}

export interface BulkLoadRateCheckPreviewResponse {
  rates?: LoadRateCheckPreviewResponse[];
  skippedLoads?: LoadRateCheckPreviewSkipReason[];
}

export interface RateCheckAverageRequest {
  origin: Address;
  destination: Address;
  equipment: RateCheckEquipmentTypes;
  initialExpansionLevel: AverageRateInitialExpansionLevel;
  /** min = 2, max = 52 */
  lookBackWeeks: number;
}

export interface RateCheckAverageResponse {
  mileage: number;
  minPerMile: number;
  meanPerMile: number;
  maxPerMile: number;
  minTotal: number;
  meanTotal: number;
  maxTotal: number;
  basedOnLoadCount: number;
}

export interface LoadToTruckRatioRequest {
  /** US/Canada 2 letter state code */
  state: string;
  equipmentType: RateCheckEquipmentTypes;
}

export interface LoadToTruckRatioResponse extends LoadToTruckRatioRequest {
  ratio: number;
  isRatioFound?: boolean;
}

export interface ExpediteAllLocation {
  city?: string;
  state?: string;
  country?: string;
  longitude: number;
  latitude: number;
}

export interface ExpediteAllRateRequest {
  origin: ExpediteAllLocation;
  destination: ExpediteAllLocation;
  truckType: ExpediteAllEquipmentType;
}

export interface ExpediteAllResponse {
  isRateFound: boolean;
  laneMiles?: number;
  estRate?: number;
  estRatePerMile?: number;
  minRate?: number;
  minRatePerMile?: number;
  maxRate?: number;
  maxRatePerMile?: number;
}

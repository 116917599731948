import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { RateCheck } from '@common/model';
import {
  newFeedback,
  sendLoadRateCheckFeedback,
  sendRateCheckFeedback,
  updateRateCheckFeedback,
} from '@common/redux/epic/RateCheckEpic';
import { Flex } from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { FontSize } from '@style/StyleConstants';
import { t, T } from '@translate';

const RateCheckIsAccurateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  margin: 4px 0;
`;
const QuestionContainer = styled(Flex)`
  justify-content: flex-start;
`;

const QuestionContainerQuestion = styled(QuestionContainer)`
  justify-content: flex-end;
`;

const QuestionContainerText = styled.span`
  font-size: ${FontSize.ContentDetail}px;
  padding: 0 16px;
`;

interface ComponentProps {
  rateCheckID: string;
  loadID?: string;
  rateCheck: RateCheck;
  setIsEditingFeedback: (isEditing: boolean) => void;
}

export const RateCheckIsAccurate: React.FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const feedbackParams = { response: props.rateCheck, loadID: props.loadID };
  const sendFeedback = props.loadID ? sendLoadRateCheckFeedback : sendRateCheckFeedback;
  return (
    <RateCheckIsAccurateContainer id="accurate">
      <QuestionContainerQuestion>
        <QuestionContainerText id="question">{t(T.common_rateCheck_confirmation_isRateAccurate)}</QuestionContainerText>
      </QuestionContainerQuestion>
      <QuestionContainer>
        <Button
          id="yes"
          color="secondary"
          onClick={() => {
            const feedback = newFeedback({
              isRateCheckAccurate: true,
              ...feedbackParams,
            });
            dispatch(sendFeedback(props.rateCheckID, feedback, props.rateCheck, undefined));
          }}
          style={{ fontSize: FontSize.Content, textTransform: 'capitalize' }}
        >
          {t(T.common_yes)}
        </Button>
        <Button
          id="no"
          color="secondary"
          onClick={() => {
            props.setIsEditingFeedback(true);
            dispatch(
              updateRateCheckFeedback(newFeedback({ isRateCheckAccurate: false, ...feedbackParams }), props.rateCheckID)
            );
          }}
          style={{ fontSize: FontSize.Content, textTransform: 'capitalize' }}
        >
          {t(T.common_no)}
        </Button>
      </QuestionContainer>
    </RateCheckIsAccurateContainer>
  );
};

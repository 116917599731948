import { filter, findIndex, forEach, map, reduce, some } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { forkJoin as forkJoin$, merge as merge$, of as of$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiResponse123, ApiResponseSuccess } from '@common/api';
import { LoadsClient } from '@common/client';
import { MyLoadsClient } from '@common/client/MyLoadsClient';
import {
  addFetchedTime,
  compareIds,
  treatZeroAsUndefined,
  UpdateCategory,
  UpdateLoadAction,
  updateLoadActionCreator,
  updateLoadsList,
} from '@common/helper';
import {
  Filter,
  filterToPropertyKey,
  MYLOADS_FILTERS_LIST,
  myLoadsRequestFromFilter,
} from '@common/helper/MyLoadsHelper';
import {
  DEFAULT_LOAD_SEARCH_RADIUS,
  Geolocation,
  GeolocationGroup,
  Load,
  LoadProgress,
  Loads,
  LoadSearchGeoResponse,
  MapBounds,
  RateCheckPreviewStatus,
} from '@common/model';
import {
  createDefaultMyLoadsRequest,
  MY_LOADS_REQUEST_LIMIT_APPENDING,
  MY_LOADS_REQUEST_LIMIT_DEFAULT,
  MY_LOADS_REQUEST_OFFSET_INCREMENT,
  MyLoadsRequest,
} from '@common/model/MyLoadsRequest';
import {
  ApiAction,
  createApiAction,
  createResponseAction,
  EmptyResponse,
  ResponseAction,
  responseActionHandler,
} from '@common/redux/Base';
import { createPaginatedListReducer, PaginatedListState } from '@common/redux/epic/PaginatedListReducer';
import {
  FetchRateCheckPreviewAction,
  processRateCheckPreviewResponse,
  RateCheckPreviewResponseAction,
} from '@common/redux/epic/rateCheck/RateCheckPreviewHelper';
import {
  getSettingsFromStateObservable,
  isRateCheckPreviewEnabled,
  SettingsState,
} from '@common/redux/epic/SettingsStateHelper';

import { createReducer } from '../ReduxHelper';
import { simpleApiEpicToAction } from './EpicHelper';

export const MY_LOADS_REDUCER_KEY = 'myLoads';

const MY_LOADS_UPDATE_LOAD = 'MY_LOADS_UPDATE_LOAD';
const FETCH_SAVED_LOADS_COUNT = 'FETCH_SAVED_LOADS_COUNT';
const SAVED_LOADS_COUNT_FETCHED = 'SAVED_LOADS_COUNT_FETCHED';
const CLEAR_MY_LOADS = 'CLEAR_MY_LOADS';
const FETCH_MY_LOADS_TOTAL_COUNTS = 'FETCH_MY_LOADS_TOTAL_COUNTS';
const MY_LOADS_TOTAL_COUNTS_FETCHED = 'MY_LOADS_TOTAL_COUNTS_FETCHED';
const MY_LOADS_FETCH_RATECHECK_PREVIEW = 'MY_LOADS_FETCH_RATECHECK_PREVIEW';
const MY_LOADS_RATECHECK_PREVIEW_FETCHED = 'MY_LOADS_RATECHECK_PREVIEW_FETCHED';

type FetchWorkingLoadsAction = ApiAction<MyLoadsRequest>;

export interface TotalCountState {
  viewed: number | undefined;
  saved: number | undefined;
  called: number | undefined;
  contacted: number | undefined;
  booked: number | undefined;
  scheduledForPickup: number | undefined;
  pickupComplete: number | undefined;
  loadEnRoute: number | undefined;
  delivered: number | undefined;
  hidden: number | undefined;
}

interface UnableToClearLoads {
  hidden: boolean | undefined;
  saved: boolean | undefined;
}

interface GeoSearch {
  pins: GeolocationGroup[];
  center?: Geolocation;
  radius?: number;
  latLongBounds?: MapBounds;
  isLoadingPins: boolean;
  lastMapSearchID?: string;
}

export interface MyLoadsState extends PaginatedListState<Load, MyLoadsRequest> {
  savedLoadsCount: number;
  isLoadingTotalCount: boolean;
  totalCount: TotalCountState;
  unableToClearLoads: UnableToClearLoads;
  isLoadingWorkingLoads: boolean;
  workingLoad: Load | undefined;
  wasUnhideMyLoadsSuccessful: boolean;
  isLoadUnhided: boolean;
  geoSearch: GeoSearch;
}

interface FetchMyLoadsTotalCountAction extends Action {
  filters: Filter[];
}

interface FetchMyLoadsTotalCountResponse extends Action {
  responses: ApiResponse123<Loads>[];
  filters: Filter[];
}

interface MyLoadsStoreState {
  [MY_LOADS_REDUCER_KEY]: MyLoadsState;
}

type MyLoadsStateObservable = StateObservable<{
  [reducerKey: string]: MyLoadsState | SettingsState;
}>;

const getMyLoadsState = (stateObservable: MyLoadsStateObservable) =>
  stateObservable.value[MY_LOADS_REDUCER_KEY] as MyLoadsState;

const myLoadsPaginationReducer = createPaginatedListReducer<
  Load,
  MyLoadsRequest,
  Loads,
  MyLoadsStoreState,
  MyLoadsState
>({
  reducerKey: MY_LOADS_REDUCER_KEY,
  requestConstants: {
    limit: {
      default: MY_LOADS_REQUEST_LIMIT_DEFAULT,
      appending: MY_LOADS_REQUEST_LIMIT_APPENDING,
    },
    offsetIncrement: MY_LOADS_REQUEST_OFFSET_INCREMENT,
  },
  getEntriesFromResponse: (response) => response.loads,
  areEntriesEqual: compareIds,
  transformFetchedEntries: addFetchedTime,
});

const initialState: MyLoadsState = {
  fetchRequest: createDefaultMyLoadsRequest(),
  isLoading: false,
  isLoadingWorkingLoads: false,
  workingLoad: undefined,
  didLoadingFail: false,
  isLoadingMore: false,
  isRefreshing: false,
  isLastResult: false,
  savedLoadsCount: 0,
  isLoadingTotalCount: false,
  geoSearch: {
    isLoadingPins: false,
    pins: [],
  },
  unableToClearLoads: {
    saved: undefined,
    hidden: undefined,
  },
  totalCount: {
    viewed: undefined,
    saved: undefined,
    called: undefined,
    contacted: undefined,
    booked: undefined,
    scheduledForPickup: undefined,
    pickupComplete: undefined,
    loadEnRoute: undefined,
    delivered: undefined,
    hidden: undefined,
  },
  wasUnhideMyLoadsSuccessful: false,
  isLoadUnhided: false,
};

export const fetchMyLoads = myLoadsPaginationReducer.actions.fetchEntries;
export const fetchMoreMyLoads = myLoadsPaginationReducer.actions.fetchMoreEntries;

export const fetchSavedLoadsCount = (): Action => ({
  type: FETCH_SAVED_LOADS_COUNT,
});

export const updateMyLoads = updateLoadActionCreator(MY_LOADS_UPDATE_LOAD);

export const clearMyLoads = (): Action => ({ type: CLEAR_MY_LOADS });

export const fetchSavedLoadsCountResponse = createResponseAction<Loads>(SAVED_LOADS_COUNT_FETCHED);

export const fetchMyLoadsTotalCount = (filters: Filter[]) => ({ type: FETCH_MY_LOADS_TOTAL_COUNTS, filters: filters });

const unsaveMyLoadsAction = createApiAction<undefined, EmptyResponse>('PATCH_LOADS_USERDATA');
const fetchWorkingLoadAction = createApiAction<MyLoadsRequest, Loads>('FETCH_WORKING_LOAD');
const fetchMyGeoLoadsAction = createApiAction<MyLoadsRequest, LoadSearchGeoResponse>('FETCH_MY_GEO_LOADS');
export const unsaveMyLoads = () => unsaveMyLoadsAction.fetchAction(undefined);
export const fetchWorkingLoads = (request: MyLoadsRequest) => fetchWorkingLoadAction.fetchAction(request);
export const fetchMyGeoLoads = (request: MyLoadsRequest) => fetchMyGeoLoadsAction.fetchAction(request);
const unhideMyLoadsAction = createApiAction<undefined, EmptyResponse>('UNHIDE_MY_LOADS');
export const unhideMyLoads = () => unhideMyLoadsAction.fetchAction(undefined);
const unhideMyLoadAction = createApiAction<string, EmptyResponse>('UNHIDE_MY_LOAD');
export const unhideMyLoad = (loadID: string) => unhideMyLoadAction.fetchAction(loadID);

const fetchMyLoadsTotalCountResponse = (
  responses: ApiResponse123<Loads>[],
  filters: Filter[]
): FetchMyLoadsTotalCountResponse => ({
  type: MY_LOADS_TOTAL_COUNTS_FETCHED,
  responses: responses,
  filters: filters,
});

export const myLoadsReducer = createReducer(
  initialState,
  {
    [MY_LOADS_UPDATE_LOAD]: (state: MyLoadsState, action: UpdateLoadAction) => {
      let updatedSavedLoadsCount = state.savedLoadsCount;
      if (action.update.category === UpdateCategory.SAVED) {
        updatedSavedLoadsCount = action.update.isSaved ? updatedSavedLoadsCount + 1 : updatedSavedLoadsCount - 1;
      }
      let newState: MyLoadsState = {
        ...state,
        savedLoadsCount: updatedSavedLoadsCount,
      };
      if (state.entries) {
        newState = {
          ...newState,
          entries: updateLoadsList(state.entries, action.update),
          fetchRequest: {
            ...newState.fetchRequest,
            offset: getNewOffset(state, action),
          },
        };
      }
      return newState;
    },
    [SAVED_LOADS_COUNT_FETCHED]: (state: MyLoadsState, action: ResponseAction<Loads>) => {
      const response = action.response;
      if (response.payload && response.payload.metadata.totalResultCount !== undefined) {
        return { ...state, savedLoadsCount: response.payload.metadata.totalResultCount };
      }
      return state;
    },
    [CLEAR_MY_LOADS]: (state: MyLoadsState) => {
      return {
        ...state,
        fetchRequest: createDefaultMyLoadsRequest(),
        entries: [],
        isLoading: false,
        didLoadingFail: false,
        isLoadingMore: false,
        isRefreshing: false,
        isLastResult: false,
      };
    },
    [FETCH_MY_LOADS_TOTAL_COUNTS]: (state: MyLoadsState) => {
      return { ...state, isLoadingTotalCount: true };
    },
    [MY_LOADS_TOTAL_COUNTS_FETCHED]: (state: MyLoadsState, action: FetchMyLoadsTotalCountResponse) => {
      if (some(action.responses, (response) => !response.success)) {
        return { ...state, isLoadingTotalCount: false };
      }
      const totalCountState = reduce(
        action.responses,
        (countState, response: ApiResponseSuccess<Loads>, index) => ({
          ...countState,
          ...responseToTotalCountState(response, action.filters[index]),
        }),
        state.totalCount
      );
      return { ...state, isLoadingTotalCount: false, totalCount: totalCountState };
    },
    [unsaveMyLoadsAction.fetchType]: (state: MyLoadsState) => {
      return {
        ...state,
        isLoading: true,
        unableToClearLoads: { ...state.unableToClearLoads, [Filter.Saved]: false },
      };
    },
    [unsaveMyLoadsAction.responseType]: (state: MyLoadsState, action: ResponseAction<EmptyResponse>) => {
      return {
        ...state,
        isLoading: false,
        unableToClearLoads: { ...state.unableToClearLoads, [Filter.Saved]: !action.response.success },
      };
    },
    [fetchWorkingLoadAction.fetchType]: (state: MyLoadsState) => {
      return {
        ...state,
        isLoadingWorkingLoads: true,
      };
    },
    [fetchWorkingLoadAction.responseType]: (state: MyLoadsState, action: ResponseAction<Loads>) => {
      return {
        ...state,
        isLoadingWorkingLoads: false,
        workingLoad: action.response.payload?.loads[0],
      };
    },
    [unhideMyLoadsAction.fetchType]: (state: MyLoadsState) => {
      return {
        ...state,
        unableToClearLoads: { ...state.unableToClearLoads, [Filter.Hidden]: false },
        isLoading: true,
        wasUnhideMyLoadsSuccessful: false,
      };
    },
    [unhideMyLoadsAction.responseType]: (state: MyLoadsState, action: ResponseAction<EmptyResponse>) => {
      return {
        ...state,
        unableToClearLoads: { ...state.unableToClearLoads, [Filter.Hidden]: !action.response.success },
        isLoading: false,
        wasUnhideMyLoadsSuccessful: action.response.success,
      };
    },
    [unhideMyLoadAction.fetchType]: (state: MyLoadsState) => {
      return {
        ...state,
        isLoadUnhided: false,
      };
    },
    [unhideMyLoadAction.responseType]: (state: MyLoadsState, action: ResponseAction<EmptyResponse>) => {
      return {
        ...state,
        isLoadUnhided: action.response.success,
      };
    },
    [fetchMyGeoLoadsAction.fetchType]: (state: MyLoadsState) => {
      return {
        ...state,
        geoSearch: {
          ...state.geoSearch,
          isLoadingPins: true,
          radius: state.geoSearch.radius ?? DEFAULT_LOAD_SEARCH_RADIUS,
        },
      };
    },
    [fetchMyGeoLoadsAction.responseType]: (state: MyLoadsState, action: ResponseAction<LoadSearchGeoResponse>) => {
      if (action.response.success && action.response.payload) {
        const { id, groups, metadata } = action.response.payload;
        return {
          ...state,
          geoSearch: {
            ...state.geoSearch,
            pins: groups,
            lastMapSearchID: id,
            center: metadata.center,
            radius: getRadius(metadata.radius, metadata.requestParams?.origin.radius, state),
            latLongBounds: metadata.latLongBounds,
            isLoadingPins: false,
          },
        };
      }
      return {
        ...state,
        geoSearch: {
          ...state.geoSearch,
          isLoadingPins: false,
        },
      };
    },
    [MY_LOADS_FETCH_RATECHECK_PREVIEW]: (state: MyLoadsState, action: FetchRateCheckPreviewAction) => {
      const loadidsSet = new Set(action.loadIds);
      map(state.entries, (load: Load) => {
        if (loadidsSet.has(load.id)) load.rateCheckPreview = { status: RateCheckPreviewStatus.Loading };
      });
    },
    [MY_LOADS_RATECHECK_PREVIEW_FETCHED]: (state: MyLoadsState, action: RateCheckPreviewResponseAction) => {
      const fetchData = action.fetchData as FetchRateCheckPreviewAction;
      if (action.response.success && action.response.payload) {
        const payload = action.response.payload;
        forEach(state.entries, (load: Load) => {
          processRateCheckPreviewResponse(load, payload);
        });
      } else {
        const loadidsSet = new Set(fetchData.loadIds);
        forEach(state.entries, (load: Load) => {
          if (loadidsSet.has(load.id)) load.rateCheckPreview = { status: RateCheckPreviewStatus.Error };
        });
      }
    },
  },
  myLoadsPaginationReducer.reducer
);

const getNewOffset = (state: MyLoadsState, action: UpdateLoadAction) => {
  const loadID =
    action.update.category !== UpdateCategory.BLOCKED &&
    action.update.category !== UpdateCategory.FAVORITE &&
    action.update.category !== UpdateCategory.ONBOARDED
      ? action.update.loadID
      : undefined;
  const index = findIndex(state.entries, (load: Load) => load.id === loadID);
  let offset = state.fetchRequest.offset;

  if (index === -1) {
    return offset;
  }
  if (action.update.category === UpdateCategory.SAVED && state.fetchRequest.isSaved) {
    // case: user is viewing a load from Saved Loads ---
    // if the user unsaves the load, we have to adjust the offset accordingly so that we
    // do not 'skip' loads on subsequent pagination requests. since the user may un-save
    // and re-save a load when viewing from load details, we account for both cases.
    //
    // note also that the updateLoadsList function above does not remove un-saved loads
    // from the list - it just marks them as 'unsaved' so that the view does not
    // render them. this way, if the user decides to re-save the load while viewing it,
    // we still have all the load details stored in the myloads list.
    offset += action.update.isSaved ? 1 : -1;
  } else if (action.update.category === UpdateCategory.PROGRESS && state.fetchRequest.isBooked) {
    // case: user is viewing a load from Booked Loads ---
    // same logic as for Saved Loads above.
    if (action.update.progress === LoadProgress.LoadAvailable) {
      offset -= 1;
    }
    if (action.update.progress === LoadProgress.Booked) {
      offset += 1;
    }
  } else if (action.update.category === UpdateCategory.HIDDEN) {
    offset -= 1;
  }
  return offset;
};

const responseToTotalCountState = (response: ApiResponseSuccess<Loads>, filter: Filter) => {
  const totalResultCount = response.data?.metadata.totalResultCount;
  return { [filterToPropertyKey(filter)]: totalResultCount };
};

const fetchSavedLoadsCount$ = (action$: ActionsObservable<Action>, client: MyLoadsClient) =>
  simpleApiEpicToAction(
    action$,
    FETCH_SAVED_LOADS_COUNT,
    () => {
      const request = createDefaultMyLoadsRequest();
      request.isSaved = true;
      request.fields = 'metadata{totalResultCount}';
      return client.fetchMyLoads$(request);
    },
    fetchSavedLoadsCountResponse
  );

const fetchMyLoadsTotalCount$ = (action$: ActionsObservable<Action>, client: MyLoadsClient) =>
  action$.ofType(FETCH_MY_LOADS_TOTAL_COUNTS).pipe(
    mergeMap$((action: FetchMyLoadsTotalCountAction) =>
      forkJoin$(
        map(action.filters, (filter) => {
          const totalCountRequest = myLoadsRequestFromFilter(filter);
          totalCountRequest.fields = 'metadata';
          return client.fetchMyLoads$(totalCountRequest);
        })
      ).pipe(map$((responses) => fetchMyLoadsTotalCountResponse(responses, action.filters)))
    )
  );

const unsaveMyLoads$ = (action$: ActionsObservable<Action>, client: MyLoadsClient) =>
  merge$(
    unsaveMyLoadsAction.createEpic$(action$, () =>
      client.patchLoadsUserdata$({
        searchQuery: { isSaved: true },
        patchDocument: { isSaved: false },
        excludeIds: [],
      })
    ),
    action$.ofType(unsaveMyLoadsAction.responseType).pipe(
      map$((action: ResponseAction<{}>) => {
        if (action.response.success) {
          return fetchMyLoadsTotalCount([Filter.Saved]);
        }
        return of$();
      })
    )
  );
const unhideMyLoads$ = (action$: ActionsObservable<Action>, client: MyLoadsClient) =>
  merge$(
    unhideMyLoadsAction.createEpic$(action$, () =>
      client.patchLoadsUserdata$({
        searchQuery: { isHidden: true },
        patchDocument: { isHidden: false },
        excludeIds: [],
      })
    ),
    action$.ofType(unhideMyLoadsAction.responseType).pipe(
      map$((action: ResponseAction<{}>) => {
        if (action.response.success) {
          return fetchMyLoadsTotalCount(MYLOADS_FILTERS_LIST);
        }
        return of$();
      })
    )
  );
const unhideMyLoad$ = (action$: ActionsObservable<Action>, client: LoadsClient) =>
  merge$(
    unhideMyLoadAction.createEpic$(action$, (data) => client.setHiddenLoad$(data, undefined, false)),
    action$.ofType(unhideMyLoadAction.responseType).pipe(
      map$((action: ResponseAction<{}>) => {
        if (action.response.success) {
          return fetchMyLoadsTotalCount(MYLOADS_FILTERS_LIST);
        }
        return of$();
      })
    )
  );

const fetchWorkingLoads$ = (action$: ActionsObservable<Action>, client: MyLoadsClient) =>
  action$
    .ofType(fetchWorkingLoadAction.fetchType)
    .pipe(
      mergeMap$((action: FetchWorkingLoadsAction) =>
        client.fetchMyLoads$(action.data).pipe(map$(fetchWorkingLoadAction.responseAction))
      )
    );

const rateCheckPreviewEpic$ = (
  action$: ActionsObservable<Action>,
  state$: MyLoadsStateObservable,
  loadsClient: LoadsClient
) =>
  merge$(
    action$.ofType(myLoadsPaginationReducer.actionTypes.ENTRIES_FETCHED).pipe(
      mergeMap$(() => {
        if (!isRateCheckPreviewEnabled(getSettingsFromStateObservable(state$))) {
          return of$();
        }

        const storeState = getMyLoadsState(state$);

        const loadids = map(
          filter(storeState.entries, (load) => !load.rateCheckPreview),
          (load) => load.id
        );

        if (loadids.length === 0) {
          return of$();
        }

        return of$({
          type: MY_LOADS_FETCH_RATECHECK_PREVIEW,
          loadIds: loadids,
        } as FetchRateCheckPreviewAction);
      })
    ),

    action$.ofType(MY_LOADS_FETCH_RATECHECK_PREVIEW).pipe(
      mergeMap$((action: FetchRateCheckPreviewAction) => {
        return loadsClient.fetchLoadRateCheckPreviews$(action.loadIds, undefined).pipe(
          map$((response) => ({
            ...responseActionHandler(MY_LOADS_RATECHECK_PREVIEW_FETCHED, response),
            fetchData: action,
          }))
        );
      })
    )
  );

export const createMyLoadsEpic = (api: Api, isLiveEnvironment: boolean) => {
  const myLoadsClient = new MyLoadsClient(api);
  const loadsClient = new LoadsClient(api, isLiveEnvironment);

  return (action$: ActionsObservable<Action>, state$: MyLoadsStateObservable) =>
    merge$(
      myLoadsPaginationReducer.createMergedEpic$(
        myLoadsClient.fetchMyLoads$,
        action$,
        // Complex downcast not working
        state$ as unknown as StateObservable<MyLoadsStoreState>
      ),
      fetchMyGeoLoadsAction.createEpic$(action$, (data) => myLoadsClient.fetchMyGeoLoads$(data)),
      fetchSavedLoadsCount$(action$, myLoadsClient),
      fetchMyLoadsTotalCount$(action$, myLoadsClient),
      unsaveMyLoads$(action$, myLoadsClient),
      fetchWorkingLoads$(action$, myLoadsClient),
      unhideMyLoads$(action$, myLoadsClient),
      unhideMyLoad$(action$, loadsClient),
      rateCheckPreviewEpic$(action$, state$, loadsClient)
    );
};

const getRadius = (
  requestMetadataRadius: number | undefined,
  originRadius: number | undefined,
  state: MyLoadsState
) => {
  return treatZeroAsUndefined(requestMetadataRadius) ?? treatZeroAsUndefined(originRadius) ?? state.geoSearch.radius;
};

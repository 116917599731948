import { useEffect, useRef } from 'react';

import { useRefFn } from '@common/util/hooks/UseRefFunction';

/**
 * Creates an instance using a factory function and updates it if the factory function is changed.
 *
 * @param factory A function that returns an instance of type T.
 * @param init A functioin that initialize the new instance from the previous one.
 * @param deinit A function that deinitialize the instance.
 *
 *
 * @example Short example of using useFactory:
 *
 * const factory = useCallback(() => new A(arg1, arg2), [arg1, arg2]);
 * return useFactory<A>(
 *   factory,
 *   (prevInstance, newInstance) => {
 *     newInstance.setParam(prevManager.getParam());
 *     if (prevManager.isSomething()) {
 *       newInstance.setSomething();
 *     }
 *   },
 *   (manager) => {
 *     manager.clear();
 *   }
 * );
 */

export const useFactory = <T>(
  factory: () => T,
  init: (prevInstance: T, newInstance: T) => void,
  deinit: (instance: T) => void
) => {
  const instanceRef = useRefFn<T>(factory);
  const currentFactory = useRef(factory);
  if (currentFactory.current !== factory) {
    const prevInstance = instanceRef.current;
    instanceRef.current = factory();
    init(prevInstance, instanceRef.current);
    currentFactory.current = factory;
    deinit(prevInstance);
  }
  useEffect(() => {
    const instance = instanceRef.current;
    return () => {
      deinit(instance);
    };
  }, []);
  return instanceRef.current;
};

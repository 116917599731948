import { useMemo } from 'react';

import { UIMenuLayout } from '@/reduxStore/epic/ApplicationSettingsReducer';
import { MembersUnderDevelopmentFlag } from '@/reduxStore/reducer/UnderDevelopmentReducer';
import { hasRoles } from '@common/helper';
import { Flag, Roles, UserType } from '@common/model';
import { useIsBiddingEnabled, useIsVendorBidsListEnabled } from '@page/bids/BidStatusHelper';
import { useSelector } from '@util/hooks';
import { hasLoginCookies } from '@webApi/CookiesHelper';

import { createBrokerNavGroup, createCarrierNavGroup, loggedOutNavMenu } from './menuList';
import { NavGroups } from './menuTypes';

const useBrokerMenu = (isMenuExpanded?: boolean) => {
  const isBiddingEnabled = useIsBiddingEnabled();
  const isVendorBidsListEnabled = useIsVendorBidsListEnabled();

  const inboxNotificationCount =
    useSelector((state) => state.user.userAlerts?.payload?.messagesInfo.unreadMessagesCount) ?? 0;
  const loadAlertsCount = useSelector((state) => state.loadSearch.loadSearchAlerts.totalCount);
  const isCommunicationEnabled = useSelector((state) => state.communication.isCommunicationsEnabled);
  const unreadMessagesCount = useSelector((state) => state.communication.unreadMessagesCount);
  const isCapacityFinderEnabled = useSelector(
    (state) => !state.settings.systemSetting[Flag.CapacityFinderDisabled].value
  );
  const bidsUnviewedCountBroker = useSelector((state) => state.bids.summary.brokerUnreadBids);
  const isInternalAdmin = useSelector((state) => state.user.accesses.isInternalAdmin);
  const isInternalTruckLocatorEnabled = useSelector(
    (state) => state.underDevelopment.flags[MembersUnderDevelopmentFlag.InternalTruckLocator]
  );

  return useMemo(
    () =>
      createBrokerNavGroup(
        inboxNotificationCount,
        unreadMessagesCount,
        isCommunicationEnabled ?? false,
        bidsUnviewedCountBroker,
        isBiddingEnabled ?? false,
        isVendorBidsListEnabled ?? false,
        (isInternalAdmin && isInternalTruckLocatorEnabled) ?? false,
        isCapacityFinderEnabled,
        loadAlertsCount,
        isMenuExpanded
      ),
    [
      inboxNotificationCount,
      unreadMessagesCount,
      isCommunicationEnabled,
      bidsUnviewedCountBroker,
      isBiddingEnabled,
      isVendorBidsListEnabled,
      isInternalAdmin,
      isInternalTruckLocatorEnabled,
      isCapacityFinderEnabled,
      loadAlertsCount,
      isMenuExpanded,
    ]
  );
};

const useCarrierMenu = (isMenuExpanded?: boolean) => {
  const isBiddingEnabled = useIsBiddingEnabled();
  const isVendorBidsListEnabled = useIsVendorBidsListEnabled();

  const inboxNotificationCount = useSelector(
    (state) => state.user.userAlerts?.payload?.messagesInfo.unreadMessagesCount ?? 0
  );
  const loadAlertsCount = useSelector((state) => state.loadSearch.loadSearchAlerts.totalCount);
  const isCommunicationEnabled = useSelector((state) => state.communication.isCommunicationsEnabled);
  const unreadMessagesCount = useSelector((state) => state.communication.unreadMessagesCount);
  const unreadTruckAlertsCount = useSelector((state) => state.truckAlerts.unreadMessagesCount);
  const bidsUnviewedCountCarrier = useSelector((state) => state.bids.summary.carrierUnreadBids);
  const userRoles = useSelector((state) => state.user.profile?.payload);
  const isTripBuilderEnabled = useSelector(
    (state) =>
      state.settings.systemSetting[Flag.TripBuilderEnabled].value &&
      hasRoles(userRoles?.roles, [Roles.Loadplanner_beta, Roles.Loadplanner])
  );
  const isLoadPlannerEnabled = useSelector((state) => state.settings.systemSetting[Flag.LoadPlannerEnabled].value);

  return useMemo(
    () =>
      createCarrierNavGroup(
        inboxNotificationCount,
        loadAlertsCount,
        unreadMessagesCount,
        unreadTruckAlertsCount,
        isCommunicationEnabled ?? false,
        bidsUnviewedCountCarrier,
        isBiddingEnabled ?? false,
        isVendorBidsListEnabled ?? false,
        isTripBuilderEnabled ?? false,
        isLoadPlannerEnabled ?? false,
        isMenuExpanded
      ),
    [
      inboxNotificationCount,
      loadAlertsCount,
      unreadMessagesCount,
      unreadTruckAlertsCount,
      isCommunicationEnabled,
      bidsUnviewedCountCarrier,
      isBiddingEnabled,
      isVendorBidsListEnabled,
      isTripBuilderEnabled,
      isLoadPlannerEnabled,
      isMenuExpanded,
    ]
  );
};

export const isCarrier = (userType: UserType, uiMenuLayout: UIMenuLayout) => {
  if (userType === UserType.BrokerCarrier) {
    // users with BrokerCarrier category can toggle between Menus, so we rely on uiMenuLayout
    return uiMenuLayout === UIMenuLayout.Carrier;
  }

  return userType === UserType.Carrier;
};

export const useIsCarrier = () => {
  // controlled by api, based on CompanyCategory
  const userType = useSelector((state) => state.user.userType);
  // controlled by UI
  const uiMenuLayout = useSelector((state) => state.applicationSettings.uiMenuLayout);

  return isCarrier(userType, uiMenuLayout);
};

const useSelectBrokerCarrierMenu = (isMenuExpanded?: boolean) => {
  const isCarrier = useIsCarrier();

  const carrierMenu = useCarrierMenu(isMenuExpanded);
  const brokerMenu = useBrokerMenu(isMenuExpanded);

  const isProfileFetched = useSelector((state) => state.user.profileFetchedAt !== undefined);

  if (!isProfileFetched) {
    return undefined;
  }

  return isCarrier ? carrierMenu : brokerMenu;
};

const useIsLoggedIn = () => {
  const loginFailed = useSelector((state) => state.connectionError.loginFailed);
  return hasLoginCookies() && !loginFailed;
};

export const useNavGroups = (isMenuExpanded?: boolean): NavGroups[] | undefined => {
  const isLoggedIn = useIsLoggedIn();
  const brokerCarrierMenu = useSelectBrokerCarrierMenu(isMenuExpanded);

  return !isLoggedIn ? loggedOutNavMenu() : brokerCarrierMenu;
};

import React, { useEffect, useState } from 'react';

import { cloneDeep, toInteger, toString } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import {
  cleanNumberOnlyInput,
  combineValidators,
  validateDOTNumber,
  validateMaxLength,
  validateNotEmpty,
} from '@common/helper';
import { LoadWithDataForBiddingOnly } from '@common/model';
import { getCompanyInfo, updateCompanyInfo } from '@common/redux/epic/CompanyEpic';
import { MultipurposeButton } from '@component/buttons';
import { ActionIcon } from '@component/icon/ActionIcon';
import { ValidatedTextInput } from '@component/input';
import { default as CloseIcon } from '@component/panels/basePanel/loadDetails/close.svg';
import { BasePopup } from '@component/popup/BasePopup';
import { EnterDOT } from '@component/popup/EnterDotPopup';
import { PlaceBidPopup } from '@component/popup/PlaceBidPopup';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { Text, TextStyle } from '@component/text';
import { FIRST_NAME_MAX_LENGTH } from '@page/partnerServices/RegisterForm';
import { typography } from '@style/TypographyStyle';
import { ThemeProps, withTheme } from '@style/WithTheme';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';

const PopupTitle = withTheme()(styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${(props: ThemeProps) => typography(props.theme.text.h2)};
  padding-bottom: 16px;
`);

const PopupContent = styled.div`
  padding: 0 16px 16px 16px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

interface Props {
  load?: LoadWithDataForBiddingOnly;
}

export const EditCompanyPopup: React.FC<Props> = (props) => {
  const { closingPopup, openingPopup } = usePopup();

  const company = useSelector((state) => state.companyState.company);
  const [companyName, setCompanyName] = useState({ value: '', isValid: false });
  const [companyDot, setCompanyDot] = useState({ value: '', isValid: false });
  const updateStatus = useSelector((state) => state.companyState.updateCompany.updatedSuccessfully);
  const [wasCompanyFetched, setWasCompanyFetched] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompanyUpdated, setIsCompanyUpdated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && isCompanyUpdated) {
      closingPopup();
      openingPopup({
        body: company?.usdotNumber ? (
          <PlaceBidPopup load={props.load} />
        ) : (
          <EnterDOT
            onSuccess={() =>
              openingPopup({
                body: <PlaceBidPopup load={props.load} />,
                width: PopupSizes.SMALL,
              })
            }
          />
        ),
        width: PopupSizes.SMALL,
      });
    }
    setIsCompanyUpdated(false);
  }, [isLoading, isCompanyUpdated]);
  useEffect(() => {
    if (company) {
      setCompanyName({ value: company.name, isValid: true });
      setCompanyDot({ value: company.usdotNumber ? toString(company.usdotNumber) : '', isValid: true });
      setWasCompanyFetched(true);
    } else {
      dispatch(getCompanyInfo());
    }
  }, [company]);

  useEffect(() => {
    if (updateStatus !== undefined && isLoading) {
      setIsLoading(false);
      if (updateStatus) {
        dispatch(showSnackbar({ message: t(T.settings_account_companyPanel_snackBarSuccess) }));
        setIsCompanyUpdated(true);
      } else {
        dispatch(showSnackbar({ message: t(T.settings_account_companyPanel_snackBarFailure) }));
        setIsDisabled(false);
      }
    }
  }, [updateStatus]);

  useEffect(() => {
    checkIfFormIsValid();
  }, [companyName, companyDot]);

  const wasFormUpdated = () => {
    if (company) {
      if (company.name === companyName.value && (toString(company.usdotNumber) || '') === companyDot.value) {
        return false;
      }
    }
    return true;
  };

  const checkIfFormIsValid = () => {
    if (wasCompanyFetched) {
      setWasCompanyFetched(false);
    } else if (companyName.isValid && companyDot.isValid && wasFormUpdated()) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const updateCompany = () => {
    if (!company) {
      return;
    }

    const newCompany = cloneDeep(company);
    newCompany.name = companyName.value;
    newCompany.usdotNumber = toInteger(companyDot.value);

    dispatch(updateCompanyInfo(newCompany));

    setIsDisabled(false);
    setIsLoading(true);
  };

  const onClose = () => {
    setIsCompanyUpdated(true);
  };

  return (
    <BasePopup withoutContentPadding={true}>
      <PopupContent>
        <PopupTitle>
          {t(T.findLoads_loadDetails_editCompanyPopup_title)}
          <ActionIcon id="close" src={CloseIcon} alt="close" onClick={onClose} />
        </PopupTitle>
        <div style={{ marginBottom: '16px' }}>
          <Text id="enter_name_dot" textStyle={TextStyle.TextContentDetail}>
            {t(T.findLoads_loadDetails_editCompanyPopup_content)}
          </Text>
        </div>
        <FormContainer>
          <ValidatedTextInput
            label={t(T.common_load_Company_Name)}
            fullWidth={true}
            id="company_name"
            value={companyName.value}
            required={true}
            isValidatedOnChange={true}
            onChangeWithValidation={(name, isValid) => {
              setCompanyName({ value: name, isValid: isValid });
              checkIfFormIsValid();
            }}
            isWithoutMargin={false}
            validator={combineValidators(validateNotEmpty, validateMaxLength(FIRST_NAME_MAX_LENGTH))}
          />
          <ValidatedTextInput
            label={t(T.common_load_DOT_number)}
            fullWidth={true}
            value={companyDot.value}
            onChangeWithValidation={(dot, isValid) => {
              setCompanyDot({ value: cleanNumberOnlyInput(dot), isValid: isValid });
              checkIfFormIsValid();
            }}
            id="dot"
            isWithoutMargin={false}
            validator={combineValidators(validateDOTNumber)}
          />
          <MultipurposeButton
            key="update_company"
            id="updte_company"
            variant={'contained'}
            text={t(T.common_update)}
            color={'primary'}
            isLoading={isLoading}
            onClick={updateCompany}
            disabled={isDisabled}
          />
        </FormContainer>
      </PopupContent>
    </BasePopup>
  );
};

import React, { Component } from 'react';

import {
  StyledActionButtonWrapper,
  StyledContent,
  StyledControl,
  StyledIcon,
} from '@component/buttons/MultipurposeButtonStyle';

interface ComponentProps {
  id: string;
  onClick?: () => void;
  mainIcon: string;
  controlIcon?: string;
  title: string;
  subTitle?: string;
  onControlClick?: () => void;
}

export class IconActionButton extends Component<ComponentProps> {
  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };

  handleControlClick = (event: any) => {
    const { onControlClick } = this.props;
    event.stopPropagation();
    if (onControlClick) {
      onControlClick();
    }
  };

  render() {
    const { controlIcon, mainIcon, title, subTitle, id, onControlClick } = this.props;
    return (
      <StyledActionButtonWrapper id={id} onClick={this.handleClick}>
        <StyledIcon id="icon" src={mainIcon} alt="m_icon" />
        <StyledContent>
          <span id="title">{title}</span>
          <span id="subtitle">{subTitle}</span>
        </StyledContent>
        {onControlClick ? (
          <StyledControl id="control" onClick={this.handleControlClick}>
            {controlIcon && <img src={controlIcon} alt="c_icon" />}
          </StyledControl>
        ) : null}
      </StyledActionButtonWrapper>
    );
  }
}

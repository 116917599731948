import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { Api } from '@common/api';
import { LoadFeedbackClient } from '@common/client';
import { LoadResponse } from '@common/helper';
import {
  generateLoadFeedbackRequest,
  LOAD_FEEDBACK_CATEGORY,
  LoadFeedbackRequest,
  LoadUserMetadataValue,
} from '@common/model/LoadFeedbackRequest';
import { BaseState, Response } from '@common/redux/Base';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';

import { getLoadDetailsArchivingFlowID } from './LoadDetailsEpic';

type LoadFeedbackResponse = Response<{}>;

/* Actions: Flow: Send -> Receive */
const SEND_LOAD_FEEDBACK = 'SEND_LOAD_FEEDBACK';
export const RECEIVE_LOAD_FEEDBACK_RESPONSE = 'RECEIVE_LOAD_FEEDBACK_RESPONSE';
export const HIDE_LOAD = 'HIDE_LOAD';

export interface SendLoadFeedbackAction extends Action {
  loadID: string;
  loadFeedbackRequest: LoadFeedbackRequest;
}

export interface HideLoadAction extends Action {
  loadID: string;
}

export interface ReceiveLoadFeedbackResponse extends LoadResponse {
  category: LOAD_FEEDBACK_CATEGORY;
  value: LoadUserMetadataValue;
}

export interface LoadFeedbackState extends BaseState {
  response?: LoadFeedbackResponse;
}

/* Action Dispatches */
export const sendLoadFeedback = (loadID: string, loadFeedbackRequest: LoadFeedbackRequest): SendLoadFeedbackAction => ({
  type: SEND_LOAD_FEEDBACK,
  loadID: loadID,
  loadFeedbackRequest: loadFeedbackRequest,
});

export const hideLoad = (loadID: string): HideLoadAction => ({
  type: HIDE_LOAD,
  loadID: loadID,
});

export const receiveLoadFeedbackResponse = (
  loadID: string,
  response: LoadFeedbackResponse,
  loadFeedbackRequest: LoadFeedbackRequest
): ReceiveLoadFeedbackResponse => ({
  type: RECEIVE_LOAD_FEEDBACK_RESPONSE,
  response: response,
  category: categoryFor(loadFeedbackRequest.path),
  value: loadFeedbackRequest.value,
  loadID: loadID,
});

/* Reducer */
export const loadFeedbackReducer = (
  state: LoadFeedbackState = { isLoading: false, response: undefined },
  action: Action
): LoadFeedbackState => {
  switch (action.type) {
    case HIDE_LOAD:
    case SEND_LOAD_FEEDBACK:
      return {
        ...state,
        isLoading: true,
        response: undefined,
      };
    case RECEIVE_LOAD_FEEDBACK_RESPONSE: {
      const loadFeedbackResponseAction = action as ReceiveLoadFeedbackResponse;
      return {
        ...state,
        isLoading: false,
        response: loadFeedbackResponseAction.response,
      };
    }
    default:
      return state;
  }
};
//@FIXME: state$.value.loadInfo.loadDetailsKey is being used as a hack here.
//We already have this load progress in loadInfoEpic. This should be centralized and removed from here.
const sendLoadFeedbackEpic$ = (
  action$: ActionsObservable<Action>,
  client: LoadFeedbackClient,
  state$: StateObservable<any>
) =>
  simpleApiEpicToAction(
    action$,
    SEND_LOAD_FEEDBACK,
    (action: SendLoadFeedbackAction) =>
      client.sendLoadFeedback$(
        action.loadID,
        action.loadFeedbackRequest,
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: SendLoadFeedbackAction) =>
      receiveLoadFeedbackResponse(action.loadID, response, action.loadFeedbackRequest)
  );

const hideLoadEpic$ = (action$: ActionsObservable<Action>, client: LoadFeedbackClient, state$: StateObservable<any>) =>
  simpleApiEpicToAction(
    action$,
    HIDE_LOAD,
    (action: HideLoadAction) =>
      client.sendLoadFeedback$(
        action.loadID,
        generateLoadFeedbackRequest(LOAD_FEEDBACK_CATEGORY.HIDDEN),
        getLoadDetailsArchivingFlowID(state$, state$.value.loadInfo.loadDetailsKey)
      ),
    (response, action: HideLoadAction) =>
      receiveLoadFeedbackResponse(action.loadID, response, generateLoadFeedbackRequest(LOAD_FEEDBACK_CATEGORY.HIDDEN))
  );

export const createLoadFeedbackEpic = (api: Api) => {
  const client = new LoadFeedbackClient(api);
  return (action$: ActionsObservable<Action>, state$: StateObservable<any>) =>
    merge$(sendLoadFeedbackEpic$(action$, client, state$), hideLoadEpic$(action$, client, state$));
};

const categoryFor = (path: string): LOAD_FEEDBACK_CATEGORY => {
  switch (path) {
    case LOAD_FEEDBACK_CATEGORY.CALLED:
    case LOAD_FEEDBACK_CATEGORY.DETAILS_INCORRECT:
    case LOAD_FEEDBACK_CATEGORY.HIDDEN:
    case LOAD_FEEDBACK_CATEGORY.NO_LONGER_AVAILABLE:
    case LOAD_FEEDBACK_CATEGORY.PAY_TOO_LOW:
    case LOAD_FEEDBACK_CATEGORY.PROGRESS:
      return path;
    default:
      return LOAD_FEEDBACK_CATEGORY.NOT_INTERESTED;
  }
};

import React, { useState } from 'react';

import moment from 'moment';

import { FormPickersRequest } from '@/model';
import { Calendar, PickerType } from '@component/calendar';
import { ApplyButtonFooter } from '@component/footers';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { PanelSingleSection } from '@component/section';
import { CenteredColumnLayout } from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { t, T } from '@translate';

interface ComponentProps {
  backRedirect: () => void;
  offset?: number;
  layer?: number;
  formState: FormPickersRequest;
  updateFormState: (reuqest: FormPickersRequest) => void;
}

type Props = ComponentProps;

export const Dates: React.FC<Props> = ({ backRedirect, layer, offset, formState, updateFormState }) => {
  const [selectedDates, setSelectedDates] = useState(formState.pickupDates);

  const today = moment();
  const month = today.month();
  const year = today.year();

  const actionText = selectedDates.length > 0 ? t(T.findLoads_findLoads_clear) : undefined;

  const applyDates = () => {
    updateFormState({ ...formState, pickupDates: selectedDates });
    backRedirect();
  };

  return (
    <Panel
      id="date"
      layer={layer ?? 0}
      offset={offset ?? 0}
      size={PanelSize.small}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        label={t(T.findLoads_findLoads_selectDates)}
        hasBackButton={true}
        onClose={backRedirect}
        actionText={actionText}
        handleClick={() => setSelectedDates([])}
      />
      <PanelSingleSection>
        <CenteredColumnLayout>
          <Calendar
            type={PickerType.Dates}
            date={{ year: year, month: month }}
            selectedItems={selectedDates}
            onChange={setSelectedDates}
          />
        </CenteredColumnLayout>
      </PanelSingleSection>
      <ApplyButtonFooter onClick={applyDates} />
    </Panel>
  );
};

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import {
  BrokerLoadMetricsHistoryResponse,
  BrokerLoadMetricsResponse,
  CarrierLoadMetricsHistoryResponse,
  CarrierLoadMetricsResponse,
  LoadMetricsHistoryRequest,
} from '@/model';
import { Api } from '@common/api';
import { createApiAction } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';
import { LoadMetricsClient } from '@webApi/LoadMetricsClient';

export interface LoadMetricsState {
  carrierBusiestDayResponse?: CarrierLoadMetricsResponse;
  brokerBusiestDayResponse?: BrokerLoadMetricsResponse;
  brokerMonthlyLoadsResponse?: BrokerLoadMetricsHistoryResponse;
  carrierMonthlyLoadsResponse?: CarrierLoadMetricsHistoryResponse;
  isLoadingMonthlyLoads: boolean;
  isLoadingBusiestDays: boolean;
}

const fetchBrokerBusiestDaysAction = createApiAction<string, BrokerLoadMetricsResponse>('FETCH_BROKER_BUSIEST_DAYS');
const fetchCarrierBusiestDaysAction = createApiAction<string, CarrierLoadMetricsResponse>('FETCH_CARRIER_BUSIEST_DAYS');

const fetchBrokerMetricsHistoryAction = createApiAction<LoadMetricsHistoryRequest, BrokerLoadMetricsHistoryResponse>(
  'FETCH_BROKER_METRICS_HISTORY'
);
const fetchCarrierMetricsHistoryAction = createApiAction<LoadMetricsHistoryRequest, CarrierLoadMetricsHistoryResponse>(
  'FETCH_CARRIER_METRICS_HISTORY'
);

export const fetchBrokerBusiestDays = (fields: string = 'all') => fetchBrokerBusiestDaysAction.fetchAction(fields);
export const fetchCarrierBusiestDays = (fields: string = 'all') => fetchCarrierBusiestDaysAction.fetchAction(fields);
export const fetchBrokerMetricsHistory = (metricsHistoryRequest: LoadMetricsHistoryRequest) =>
  fetchBrokerMetricsHistoryAction.fetchAction(metricsHistoryRequest);
export const fetchCarrierMetricsHistory = (metricsHistoryRequest: LoadMetricsHistoryRequest) =>
  fetchCarrierMetricsHistoryAction.fetchAction(metricsHistoryRequest);

const initialState: LoadMetricsState = {
  brokerBusiestDayResponse: undefined,
  carrierBusiestDayResponse: undefined,
  brokerMonthlyLoadsResponse: undefined,
  isLoadingMonthlyLoads: false,
  isLoadingBusiestDays: false,
};

export const loadMetricsReducer = createMergedReducer(initialState, [
  fetchBrokerBusiestDaysAction.initiateCase((state) => {
    state.brokerBusiestDayResponse = undefined;
    state.isLoadingBusiestDays = true;
  }),
  fetchBrokerBusiestDaysAction.completeCase((state, action) => {
    if (action.response.success && action.response.payload) {
      state.brokerBusiestDayResponse = action.response.payload;
    }
    state.isLoadingBusiestDays = false;
  }),
  fetchCarrierBusiestDaysAction.initiateCase((state) => {
    state.carrierBusiestDayResponse = undefined;
    state.isLoadingBusiestDays = true;
  }),
  fetchCarrierBusiestDaysAction.completeCase((state, action) => {
    if (action.response.success && action.response.payload) {
      state.carrierBusiestDayResponse = action.response.payload;
    }
    state.isLoadingBusiestDays = false;
  }),

  fetchBrokerMetricsHistoryAction.initiateCase((state) => {
    state.isLoadingMonthlyLoads = true;
    state.brokerMonthlyLoadsResponse = undefined;
  }),
  fetchBrokerMetricsHistoryAction.completeCase((state, action) => {
    if (action.response.success && action.response.payload) {
      state.brokerMonthlyLoadsResponse = action.response.payload;
    }
    state.isLoadingMonthlyLoads = false;
  }),
  fetchCarrierMetricsHistoryAction.initiateCase((state) => {
    state.isLoadingMonthlyLoads = true;
    state.carrierMonthlyLoadsResponse = undefined;
  }),
  fetchCarrierMetricsHistoryAction.completeCase((state, action) => {
    if (action.response.success && action.response.payload) {
      state.carrierMonthlyLoadsResponse = action.response.payload;
    }
    state.isLoadingMonthlyLoads = false;
  }),
]);

export const createLoadMetricEpic = (api: Api) => {
  const metricsClient = new LoadMetricsClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchBrokerBusiestDaysAction.createEpic$(action$, (data) => metricsClient.fetchBrokerBusiestDays$(data)),
      fetchCarrierBusiestDaysAction.createEpic$(action$, (data) => metricsClient.fetchCarrierBusiestDays$(data)),
      fetchBrokerMetricsHistoryAction.createEpic$(action$, (data) =>
        metricsClient.fetchBrokerLoadMetricsHistory$(data)
      ),
      fetchCarrierMetricsHistoryAction.createEpic$(action$, (data) =>
        metricsClient.fetchCarrierLoadMetricsHistory$(data)
      )
    );
};

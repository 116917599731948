import React, { useEffect, useMemo, useState } from 'react';

import { find } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { NO_INFORMATION_SYMBOL_LONG } from '@common/formatter';
import { getConversationStatusForTracking } from '@common/helper/ConversationHelper';
import { getMCLink, getUSDOTLink } from '@common/info';
import { Company, Load } from '@common/model';
import { fetchCompaniesAvatar } from '@common/redux/epic/CompanyAvatarEpic';
import { ContactInfo, fetchBrokerInfo, getCompanyAuthority, getCompanyDetails } from '@common/redux/epic/CompanyEpic';
import { VerificationInfoBadge } from '@component/badge/VerificationBadge';
import { LoadingSpinner } from '@component/loadingSpinner/LoadingSpinner';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelHeader, PanelSize } from '@component/panel';
import { usePanelBackLocation } from '@component/panel/PanelHooks';
import { Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';
import { useSelector } from '@util/hooks';
import { createCallLoadTrackData, track } from '@util/trackers/123lbTracker';

import { getStatusColor } from './ContactInfoDetails';
import {
  CompanyAvatarAndName,
  ContactInfoLink,
  ContactInfoNameText,
  ContactInfoText,
  ContactInfoTwoColumns,
  Container,
  getCompanyCityState,
  LoadContactInfoRow,
  phoneNumberOrEmpty,
  SectionTitle,
  ShowPhoneButton,
} from './ContactInfoSections';
import { default as ThunderLogo } from './icon-thunder-funding.png';
import { default as TiaLogo } from './icon-tia-logo.svg';

interface ContactInfoDataProps {
  contactInfo?: ContactInfo;
  company?: Company;
  load?: Load;
  onPhoneClicked?: () => void;
}

interface ComponentProps {
  onCloseDetailsPanel?: () => void;
  layer: number;
}

interface RouteParams {
  conversationID: string;
  contactID: string;
}
type Props = ComponentProps;

const CommunicationContactInfoComponent: React.FC<Props> = (props: Props) => {
  const { conversationID } = useParams<RouteParams>();
  const conversations = useSelector((state) => state.communication.conversations);
  const dispatch = useDispatch();
  const closeInfoPanel = usePanelBackLocation('/loadcontact/');

  const conversationLoad = useMemo(() => {
    const conversation = conversations.get(conversationID);
    return conversation?.metadata?.load;
  }, [conversations]);

  const conversationContactID = useMemo(() => {
    const conversation = conversations.get(conversationID);
    return find(conversation?.users, (user) => user.company.companyId == conversationLoad?.poster?.id)?.userId || '';
  }, [conversations, conversationLoad]);

  //Undefined means the info is not there yet (first load), so we check for !== false
  const isLoading = useSelector((state) => state.contact.contacts.get(conversationContactID)?.isLoading !== false);

  useEffect(() => {
    if (conversationContactID) {
      dispatch(fetchBrokerInfo(conversationContactID));
    }
  }, [conversationContactID]);

  let content: JSX.Element;
  if (isLoading) {
    content = (
      <Container style={{ justifyContent: 'center', alignItems: 'center' }}>
        <LoadingSpinner />
      </Container>
    );
  } else {
    content = <ContactInfoContent load={conversationLoad} contactID={conversationContactID} />;
  }
  return (
    <Panel
      id="contact_info_header"
      size={PanelSize.small}
      offset={2}
      layer={props.layer}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
    >
      <PanelHeader
        label={t(T.common_conversations_contactInfo_title)}
        hasCloseButton={true}
        onClose={() => closeInfoPanel()}
      />

      {content}
    </Panel>
  );
};

const ContactInfoContent: React.FC<{
  load?: Load;
  contactID: string;
}> = (props) => {
  const { conversationID } = useParams<RouteParams>();
  const contactInfo = useSelector((store) => store.contact.contacts.get(props.contactID));
  const company = useSelector((store) => store.companyState.selectedCompany);
  const conversationMessages = useSelector((state) => state.communication.conversationMessages.get(conversationID));
  const history = useHistory();
  const dispatch = useDispatch();

  const onPhoneClicked = () => {
    const conversationStatus = getConversationStatusForTracking(conversationMessages);
    const currentURL = `${history.location.pathname}${history.location.search}`;
    // this call won't show up in reactotron for some reason (any tel: link seems to disconnect reactotron)
    // so if you need to debug, use the browser console network tab
    track(createCallLoadTrackData(props.load?.id ?? '', currentURL, conversationID, conversationStatus));
  };

  useEffect(() => {
    if (props.load?.poster?.id) {
      dispatch(getCompanyAuthority(props.load?.poster?.id));
      dispatch(getCompanyDetails(props.load?.poster?.id));
    }
  }, [props.load]);

  if (!props.load) {
    return null;
  }

  return (
    <ContactInfoData contactInfo={contactInfo} load={props.load} company={company} onPhoneClicked={onPhoneClicked} />
  );
};

export const ContactInfoData: React.FC<ContactInfoDataProps> = ({ load, contactInfo, company, onPhoneClicked }) => {
  const [isShowingPhone, setIsShowingPhone] = useState(false);

  const companyAvatar = useSelector((state) =>
    state.companyAvatar.companiesAvatarMap.get(company?.id ?? '')
  )?.companiesAvatar;
  const dispatch = useDispatch();

  const contactName = load?.dispatchName;
  const brokerAuthority = contactInfo?.broker?.brokerAuthority;

  let link = company?.webSite;
  if (company?.webSite && !/^https?:\/\/.*/.test(company?.webSite)) {
    link = `http://${company?.webSite}`;
  }

  const isContactInfoVerified =
    contactInfo?.broker?.isCarrierIdentityVerified || contactInfo?.carrier?.isCarrierIdentityVerified;

  useEffect(() => {
    if (!companyAvatar && company?.id) {
      dispatch(fetchCompaniesAvatar(company.id));
    }
  }, [companyAvatar, company?.id]);
  return (
    <Container>
      <SectionTitle>{t(T.common_conversations_contactInfo_loadContact)}</SectionTitle>
      <ContactInfoNameText
        style={{ marginBottom: Spacing.LargePaddingVertical, marginTop: Spacing.LargePaddingVertical }}
      >
        {contactName}
      </ContactInfoNameText>
      <LoadContactInfoRow
        label={t(T.common_conversations_contactInfo_phoneNumber)}
        value={
          isShowingPhone ? (
            phoneNumberOrEmpty(load?.dispatchPhone, onPhoneClicked)
          ) : (
            <ShowPhoneButton onClick={() => setIsShowingPhone(true)}>
              {t(T.common_conversations_contactInfo_showPhone)}
            </ShowPhoneButton>
          )
        }
      />
      <SectionTitle style={{ marginTop: Spacing.InterElementVertical }}>
        {t(T.common_conversations_contactInfo_company)}
      </SectionTitle>
      <CompanyAvatarAndName companyName={company?.name ?? NO_INFORMATION_SYMBOL_LONG} image={companyAvatar} />
      <LoadContactInfoRow
        label={t(T.common_conversations_contactInfo_usDot)}
        value={
          <>
            {company?.usdotNumber ? (
              <ContactInfoLink link={getUSDOTLink(company?.usdotNumber)}>{company?.usdotNumber}</ContactInfoLink>
            ) : (
              NO_INFORMATION_SYMBOL_LONG
            )}
            {company?.usdotNumber && isContactInfoVerified ? (
              <VerificationInfoBadge
                testID="dot_verification_badge"
                isVerified={isContactInfoVerified}
                style={{ marginLeft: 4, marginTop: 2 }}
              />
            ) : null}
          </>
        }
      />
      <LoadContactInfoRow
        label={t(T.common_conversations_contactInfo_mc)}
        value={
          company?.brokerMcNumber ? (
            <ContactInfoLink link={getMCLink(company.brokerMcNumber)}>{company.brokerMcNumber}</ContactInfoLink>
          ) : (
            NO_INFORMATION_SYMBOL_LONG
          )
        }
      />
      <LoadContactInfoRow
        label={t(T.common_conversations_contactInfo_authorityList)}
        value={
          <ContactInfoText style={{ color: getStatusColor(brokerAuthority?.status ?? '') }}>
            {brokerAuthority?.status ?? NO_INFORMATION_SYMBOL_LONG}
          </ContactInfoText>
        }
      />
      <LoadContactInfoRow
        label={t(T.common_conversations_contactInfo_cityState)}
        value={getCompanyCityState(company?.address?.city, company?.address?.state)}
      />
      <LoadContactInfoRow
        label={t(T.findLoads_loadDetails_website)}
        value={
          company?.webSite ? (
            <ContactInfoLink link={link}>{company.webSite}</ContactInfoLink>
          ) : (
            NO_INFORMATION_SYMBOL_LONG
          )
        }
      />
      <LoadContactInfoRow
        label={t(T.findLoads_loadDetails_member)}
        value={
          <ContactInfoTwoColumns
            left={t(company?.isTia ? T.common_yes : T.common_no)}
            right={<img id="logo" src={TiaLogo} alt="logo" />}
          />
        }
      />
      <LoadContactInfoRow
        label={t(T.findLoads_loadDetails_factorable)}
        value={
          <ContactInfoTwoColumns
            left={company?.isOcfp ? t(T.common_yes) : t(T.common_no)}
            right={
              company?.isOcfp ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {t(T.findLoads_loadDetails_preApproved)}
                  <img id="logo" src={ThunderLogo} alt="logo" />
                </div>
              ) : (
                <></>
              )
            }
          />
        }
      />
    </Container>
  );
};

export const LoadContactInfo = CommunicationContactInfoComponent;

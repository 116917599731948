import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { isPriceAndLessThanOneHundred } from '@common/helper';
import { RateCheck, RateCheckFeedback } from '@common/model';
import { getInitialAverage } from '@common/redux/epic/rateCheck/RateCheckHelper';
import {
  clearRateCheckFeedback,
  sendLoadRateCheckFeedback,
  sendRateCheckFeedback,
  updateRateCheckFeedback,
} from '@common/redux/epic/RateCheckEpic';
import { ValidatedTextInput } from '@component/input';
import { ElementSize, FontSize, Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

import { default as Clear } from './fields-clear.svg';

const OPACITY_ANIMATION_DURATION = 200;
const OPACITY_TRANSITION = `opacity ${OPACITY_ANIMATION_DURATION}ms ease`;

const StyledIcon = styled.img`
  height: ${ElementSize.Icon};
  width: ${ElementSize.Icon};
  position: relative;
  top: 4px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: ${Spacing.InterElementHorizontal}px ${Spacing.ScreenSide}px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 ${Spacing.ScreenSide}px;
`;

interface ComponentProps {
  rateCheckFeedback: RateCheckFeedback;
  rateCheckID: string;
  loadID?: string;
  rateCheck: RateCheck;
  setIsEditingFeedback: (isEditing: boolean) => void;
}

export const RateConfirmationInput: React.FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(getInitialAverage(props.rateCheckFeedback));
  const [opacity, setOpacity] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const sendFeedback = props.loadID ? sendLoadRateCheckFeedback : sendRateCheckFeedback;

  useEffect(() => {
    setTimeout(setOpacity, OPACITY_ANIMATION_DURATION, 1);
  }, []);

  const dissapearThen = (fn: () => void) => () => {
    setOpacity(0);
    setTimeout(fn, OPACITY_ANIMATION_DURATION);
  };

  return (
    <>
      <TextContainer style={{ opacity: opacity, transition: OPACITY_TRANSITION, willChange: 'opacity' }}>
        <span
          style={{
            fontSize: FontSize.ContentDetail,
            padding: '2px 0',
          }}
        >
          {t(T.common_rateCheck_confirmation_payAmount)}
        </span>
        <StyledIcon
          src={Clear}
          onClick={dissapearThen(() => {
            dispatch(clearRateCheckFeedback(props.rateCheckID));
            props.setIsEditingFeedback(false);
          })}
        ></StyledIcon>
      </TextContainer>
      <InputContainer style={{ opacity: opacity, transition: OPACITY_TRANSITION, willChange: 'opacity' }}>
        <ValidatedTextInput
          onChangeWithValidation={(event, isValid) => {
            setValue(event);
            setIsFormValid(isValid);
          }}
          id="rate_confirmation_input"
          label={t(T.common_rateCheck_confirmation_ratePerMile)}
          error={!isFormValid}
          style={{
            flex: 1,
            marginLeft: Spacing.InterElementHorizontal,
          }}
          value={value}
          validator={isPriceAndLessThanOneHundred}
          isValidatedOnChange={true}
          isWithoutMargin={true}
        />
        <Button
          id="submit"
          variant="outlined"
          color="secondary"
          style={{
            height: `${ElementSize.ButtonHeight}px`,
            margin: `0 16px 0 ${Spacing.InterElementHorizontal}px`,
          }}
          disabled={!isFormValid}
          onClick={dissapearThen(() => {
            const updatedFeedback = { ...props.rateCheckFeedback, expectedRate: parseFloat(value) };
            dispatch(updateRateCheckFeedback(updatedFeedback, props.rateCheckID));
            dispatch(sendFeedback(props.rateCheckID, updatedFeedback, props.rateCheck, undefined));
            props.setIsEditingFeedback(false);
          })}
        >
          {t(T.common_submit)}
        </Button>
      </InputContainer>
    </>
  );
};

import React from 'react';

import styled from 'styled-components';

import { FixedFooter } from '@component/scrollView';
import { Color } from '@style/Color';

export const FooterSection = styled.section`
  background-color: ${Color.WHITE};
  border-top: 1px solid ${Color.GRAY_SMOKE};
  padding: 10px 15px 10px 15px;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex: 1;
  box-sizing: border-box;
`;

export const PanelFooter: React.FC = (props) => (
  <FixedFooter>
    <FooterSection>{props.children}</FooterSection>
  </FixedFooter>
);

import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';
import { map as map$, mergeMap as mergeMap$ } from 'rxjs/operators';

import { Api, ApiResponse123 } from '@common/api';
import { QuoteClient } from '@common/client';
import { Quote, QuoteListResponse, QuoteRequest, QuoteResult } from '@common/model';
import { ApiResponse$, createResponseAction, Response, ResponseAction } from '@common/redux/Base';
import { simpleApiEpicToAction } from '@common/redux/epic/EpicHelper';

import { createReducer } from '../ReduxHelper';

const FETCH_LOAD_RECENT_QUOTES = 'FETCH_LOAD_RECENT_QUOTES';
const LOAD_RECENT_QUOTES_FETCHED = 'LOAD_RECENT_QUOTES_FETCHED';

export type QuoteResultResponse = Response<QuoteResult>;
export type QuoteResponse = Response<Quote>;

interface ActionWithOptionalAuth extends Action {
  withAuthentication: boolean;
}

interface FetchQuoteResponse extends Action {
  response: QuoteResultResponse;
}

interface PostQuote extends ActionWithOptionalAuth {
  request: QuoteRequest;
}

interface PostQuoteResponse extends Action {
  response: QuoteResponse;
}

interface MostRecentQuoteForLoadAction extends Action {
  guid: string;
}

export const fetchMostRecentQuoteForLoad = (guid: string): MostRecentQuoteForLoadAction => ({
  type: FETCH_LOAD_RECENT_QUOTES,
  guid: guid,
});

export interface QuoteState {
  fetchQuoteResponse?: QuoteResultResponse;
  postQuoteResponse?: QuoteResponse;
  isPostLoading: boolean;
  isFetchLoading: boolean;
}
const initialState: QuoteState = {
  isPostLoading: false,
  isFetchLoading: false,
};

const createQuoteReducer = (key: string) => {
  const actionTypes = {
    FETCH_QUOTE: `${key}FETCH_QUOTE`,
    FETCH_QUOTE_FULFILLED: `${key}FETCH_QUOTE_FULFILLED`,
    POST_QUOTE: `${key}POST_QUOTE`,
    POST_QUOTE_FULFILLED: `${key}POST_QUOTE_FULFILLED`,
  };

  const actions = {
    fetchQuotes: (): ActionWithOptionalAuth => ({
      type: actionTypes.FETCH_QUOTE,
      withAuthentication: false,
    }),
    postQuote: (request: QuoteRequest): PostQuote => ({
      type: actionTypes.POST_QUOTE,
      withAuthentication: false,
      request: request,
    }),

    fetchQuotesResponse: (response: QuoteResultResponse): FetchQuoteResponse => ({
      type: actionTypes.FETCH_QUOTE_FULFILLED,
      response: response,
    }),
    postQuoteResponse: (response: QuoteResponse): PostQuoteResponse => ({
      type: actionTypes.POST_QUOTE_FULFILLED,
      response: response,
    }),
  };

  const quoteReducer = createReducer(initialState, {
    [actionTypes.FETCH_QUOTE]: (state) => {
      state.isFetchLoading = true;
      state.fetchQuoteResponse = undefined;
    },
    [actionTypes.FETCH_QUOTE_FULFILLED]: (state, action: FetchQuoteResponse) => {
      state.isFetchLoading = false;
      state.fetchQuoteResponse = action.response;
    },
    [actionTypes.POST_QUOTE]: (state) => {
      state.isPostLoading = true;
      state.postQuoteResponse = undefined;
    },
    [actionTypes.POST_QUOTE_FULFILLED]: (state, action: PostQuoteResponse) => {
      state.isPostLoading = false;
      state.postQuoteResponse = action.response;
    },
  });

  return { actions: actions, reducer: quoteReducer, actionTypes: actionTypes };
};

export const quoteAlreadySubmitted = (response?: QuoteResultResponse): boolean | undefined => {
  if (response && response.success && response.payload) {
    return response.payload.metadata && response.payload.metadata.totalResultCount > 0;
  }

  return undefined;
};

const genericEpic = (config: {
  fetchActionType: string;
  postActionType: string;
  fetchQuotes$: (withAuthentication: boolean) => ApiResponse$<QuoteResult>;
  postQuote$: (quote: QuoteRequest, withAuthentication: boolean) => ApiResponse$<Quote>;
  fetchQuotesResponse: (response: QuoteResultResponse) => FetchQuoteResponse;
  postQuoteResponse: (response: QuoteResponse) => PostQuoteResponse;
}) => {
  const doFetchQuote = (action$: ActionsObservable<Action>) =>
    action$.ofType(config.fetchActionType).pipe(
      mergeMap$((data: ActionWithOptionalAuth) => {
        return config.fetchQuotes$(data.withAuthentication).pipe(
          map$((response: ApiResponse123<QuoteResult>) => {
            return response.result(
              (data) =>
                config.fetchQuotesResponse({
                  success: true,
                  payload: data,
                }),
              (error) =>
                config.fetchQuotesResponse({
                  success: false,
                  error: error,
                })
            );
          })
        );
      })
    );

  const doPostQuote = (action$: ActionsObservable<Action>) =>
    action$.ofType(config.postActionType).pipe(
      mergeMap$((data: PostQuote) => {
        return config.postQuote$(data.request, data.withAuthentication).pipe(
          map$((response: ApiResponse123<Quote>) => {
            return response.result(
              (data) =>
                config.postQuoteResponse({
                  success: true,
                  payload: data,
                }),
              (error) =>
                config.postQuoteResponse({
                  success: false,
                  error: error,
                })
            );
          })
        );
      })
    );
  return (action$: ActionsObservable<Action>) => merge$(doFetchQuote(action$), doPostQuote(action$));
};

const getPaidFaster = createQuoteReducer('Factoring');
export const getPaidFasterQuoteReducer = getPaidFaster.reducer;

const fuelCard = createQuoteReducer('FuelCard');
export const fuelCardQuoteReducer = fuelCard.reducer;

const roadsideAssist = createQuoteReducer('RoadsideAssist');
export const roadsideAssistQuoteReducer = roadsideAssist.reducer;

const truckingAuthority = createQuoteReducer('TruckingAuthority');
export const truckingAuthorityQuoteReducer = truckingAuthority.reducer;

const fuelAdvance = createQuoteReducer('FuelAdvance');
export const fuelAdvanceQuoteReducer = fuelAdvance.reducer;

export const fetchTruckingAuthorityQuotes = truckingAuthority.actions.fetchQuotes;
export const postTruckingAuthorityQuote = truckingAuthority.actions.postQuote;

export const fetchRoadsideAssistQuote = roadsideAssist.actions.fetchQuotes;
export const postRoadsideAssistQuote = roadsideAssist.actions.postQuote;

export const fetchGetPaidFasterQuote = getPaidFaster.actions.fetchQuotes;
export const postGetPaidFasterQuote = getPaidFaster.actions.postQuote;

export const fetchFuelCardQuote = fuelCard.actions.fetchQuotes;
export const postFuelCardQuote = fuelCard.actions.postQuote;

export const postFuelAdvanceQuote = fuelAdvance.actions.postQuote;
export const postFuelAdvanceQuoteResponse = fuelAdvance.actions.postQuoteResponse;

//responses used in the QuoteEpic.test
export const fetchGetPaidFasterQuotesResponse = getPaidFaster.actions.fetchQuotesResponse;
export const fetchFuelCardQuotesResponse = fuelCard.actions.fetchQuotesResponse;
export interface LoadQuotesState {
  loadQuotes: QuoteListResponse;
  isLoading: boolean;
}

export const loadQuotesReducer = (
  state: LoadQuotesState = { loadQuotes: { quotes: [] }, isLoading: false },
  action: Action
): LoadQuotesState => {
  switch (action.type) {
    case FETCH_LOAD_RECENT_QUOTES:
      return {
        ...state,
        loadQuotes: { quotes: [] },
        isLoading: true,
      };
    case LOAD_RECENT_QUOTES_FETCHED: {
      const quotesPayload = (action as ResponseAction<QuoteListResponse>).response.payload;
      if (quotesPayload) {
        return {
          ...state,
          loadQuotes: { quotes: quotesPayload.quotes },
          isLoading: false,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

const fetchLoadRecentQuotes$ = (client: QuoteClient, action$: ActionsObservable<Action>) =>
  simpleApiEpicToAction(
    action$,
    FETCH_LOAD_RECENT_QUOTES,
    (action: MostRecentQuoteForLoadAction) => client.fetchMostRecentQuotes$(action.guid),
    createResponseAction(LOAD_RECENT_QUOTES_FETCHED)
  );

export const createQuotesEpic = (api: Api) => {
  const quoteClient = new QuoteClient(api);
  const getPaidFasterEpic = genericEpic({
    fetchActionType: getPaidFaster.actionTypes.FETCH_QUOTE,
    fetchQuotes$: quoteClient.fetchFactoringQuotes$,
    fetchQuotesResponse: getPaidFaster.actions.fetchQuotesResponse,
    postActionType: getPaidFaster.actionTypes.POST_QUOTE,
    postQuote$: quoteClient.postFactoringQuote$,
    postQuoteResponse: getPaidFaster.actions.postQuoteResponse,
  });
  const fuelCardEpic = genericEpic({
    fetchActionType: fuelCard.actionTypes.FETCH_QUOTE,
    fetchQuotes$: quoteClient.fetchFuelCardQuotes$,
    fetchQuotesResponse: fuelCard.actions.fetchQuotesResponse,
    postActionType: fuelCard.actionTypes.POST_QUOTE,
    postQuote$: quoteClient.postFuelCardQuote$,
    postQuoteResponse: fuelCard.actions.postQuoteResponse,
  });
  const roadsideAssistEpic = genericEpic({
    fetchActionType: roadsideAssist.actionTypes.FETCH_QUOTE,
    fetchQuotes$: quoteClient.fetchRoadsideAssistQuotes$,
    fetchQuotesResponse: roadsideAssist.actions.fetchQuotesResponse,
    postActionType: roadsideAssist.actionTypes.POST_QUOTE,
    postQuote$: quoteClient.postRoadsideAssistQuote$,
    postQuoteResponse: roadsideAssist.actions.postQuoteResponse,
  });
  const truckingAuthorityEpic = genericEpic({
    fetchActionType: truckingAuthority.actionTypes.FETCH_QUOTE,
    fetchQuotes$: quoteClient.fetchTruckingAuthorityQuotes$,
    fetchQuotesResponse: truckingAuthority.actions.fetchQuotesResponse,
    postActionType: truckingAuthority.actionTypes.POST_QUOTE,
    postQuote$: quoteClient.postTruckingAuthorityQuote$,
    postQuoteResponse: truckingAuthority.actions.postQuoteResponse,
  });

  const fuelAdvanceEpic = genericEpic({
    fetchActionType: fuelAdvance.actionTypes.FETCH_QUOTE,
    fetchQuotes$: quoteClient.fetchFactoringQuotes$,
    fetchQuotesResponse: fuelAdvance.actions.fetchQuotesResponse,
    postActionType: fuelAdvance.actionTypes.POST_QUOTE,
    postQuote$: quoteClient.postFuelAdvanceQuote$,
    postQuoteResponse: fuelAdvance.actions.postQuoteResponse,
  });

  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchLoadRecentQuotes$(quoteClient, action$),
      getPaidFasterEpic(action$),
      fuelCardEpic(action$),
      roadsideAssistEpic(action$),
      truckingAuthorityEpic(action$),
      fuelAdvanceEpic(action$)
    );
};

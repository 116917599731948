import React, { useEffect, useRef, useState } from 'react';

import { isEmpty, map } from 'lodash';
import { CSVLink } from 'react-csv';

import { momentDashYYYYMMdd } from '@common/formatter/Number';
import { StateMileage, StateMileagePayload } from '@common/model';
import {
  MileageColumn,
  MileageRow,
  PageText,
  PageTextBold,
  PcMilerContent,
} from '@component/panels/mileageCalculator/MileageCalculatorStyleSheet';
import { TextStyle } from '@component/text/Text';
import { Spacing } from '@style/StyleConstants';
import { t, T } from '@translate';

import { default as DownloadIcon } from './icon-download.svg';

const IFTA_REPORT_HEADER = `${t(T.menu_mileageCalculatorPanel_iftaReport)}\n`;

interface Props {
  stateMileage: StateMileagePayload;
  isDownloading: boolean;
  wasDownloaded: boolean;
  csvData?: string;
  getExtraReport: () => void;
}

export const IftaFuelTax: React.FC<Props> = (props) => {
  const csvLink = useRef<{ link?: HTMLAnchorElement }>(null);
  const [wasClicked, setWasClicked] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const date = momentDashYYYYMMdd();

  useEffect(() => {
    if (!props.isDownloading && wasClicked) {
      setIsReady(true);
    }
  }, [props.isDownloading]);

  useEffect(() => {
    if (csvLink.current?.link && isReady) {
      csvLink.current.link.click();
      setIsReady(false);
      setWasClicked(false);
    }
  }, [csvLink, isReady]);

  const onClickDownload = () => {
    if (!wasClicked) {
      props.getExtraReport();
      setWasClicked(true);
    }
  };

  return (
    <PcMilerContent>
      <StateMileageComponent stateMileage={props.stateMileage} />
      <div style={{ margin: '14px 28px 32px' }}>
        <img src={DownloadIcon} style={{ verticalAlign: 'middle' }} />
        <a onClick={onClickDownload} style={{ cursor: 'pointer', marginLeft: '16px' }}>
          <PageText id="link_to_download" textStyle={TextStyle.Link}>
            {t(T.menu_mileageCalculatorPanel_downloadCSV)}
          </PageText>
        </a>
        {props.csvData && props.wasDownloaded ? (
          <CSVLink
            data={`${IFTA_REPORT_HEADER}${props.csvData}`}
            filename={`trip-mileage-report-${date}.csv`}
            target="_blank"
            ref={csvLink}
          />
        ) : null}
      </div>
    </PcMilerContent>
  );
};

const StateMileageMapping: React.FC<{ mileages: StateMileage[] }> = (props) => (
  <>
    {map(props.mileages, (state, index) => {
      return (
        <MileageRow>
          <PageText id="stop_information_us" key={index} textStyle={TextStyle.Subtitle2Regular}>
            {state.state}
          </PageText>
          <PageText
            id="mileage_information_us"
            style={{ textAlign: 'right' }}
            key={index}
            textStyle={TextStyle.Subtitle2Regular}
          >
            {t(T.postLoads_Miles, { value: String(state.mileage) })}
          </PageText>
        </MileageRow>
      );
    })}
  </>
);

const StateMileageComponent: React.FC<{ stateMileage: StateMileagePayload }> = (props) => (
  <MileageColumn>
    {props.stateMileage?.us && !isEmpty(props.stateMileage.us.mileages) ? (
      <>
        <PageText
          id="stop_information"
          textStyle={TextStyle.TextContentDetail}
          style={{ marginBottom: `${Spacing.ElementPaddingVertical}px` }}
        >
          <PageTextBold id="stop_label">{t(T.findLoads_findLoads_usa)}</PageTextBold>
        </PageText>
        <StateMileageMapping mileages={props.stateMileage.us.mileages} />
      </>
    ) : null}
    {props.stateMileage?.canada && !isEmpty(props.stateMileage.canada.mileages) ? (
      <>
        <PageText
          id="stop_information"
          textStyle={TextStyle.TextContentDetail}
          style={{
            marginBottom: `${Spacing.ElementPaddingVertical}px`,
            marginTop: '6px',
          }}
        >
          <PageTextBold id="stop_label">{t(T.findLoads_findLoads_canada)}</PageTextBold>
        </PageText>
        <StateMileageMapping mileages={props.stateMileage.canada.mileages} />
      </>
    ) : null}
  </MileageColumn>
);

import React, { useEffect, useRef, useState } from 'react';

import { clone, includes, isEmpty, toNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { PostTruckAvailabilityForm } from '@/reduxStore/reducer/PostTruckAvailabilityReducer';
import { showSnackbar } from '@/reduxStore/reducer/SnackbarReducer';
import { isNumber, validateIsNumberAndLessThan, validateMaxLength } from '@common/helper';
import { getStringifiedEquipmentTypeAndName } from '@common/helper/SearchHelper';
import {
  DestLocation,
  EquipmentType,
  isEmptyOriginLocation,
  LoadSize,
  LocationSuggestion,
  LocationType,
  OriginLocation,
  PostsFilter,
} from '@common/model';
import {
  deleteTruckAvailability,
  getTruckAvailabilities,
  getTruckAvailabilityCount,
} from '@common/redux/epic/PostTruckEpic';
import { VerifyDotBanner } from '@component/banner/ContactNotVerifiedBanner';
import { MultipurposeButton } from '@component/buttons';
import { Checkbox, CheckboxResponse } from '@component/control/Checkbox';
import { ActionIcon } from '@component/icon/ActionIcon';
import { ValidatedTextInput } from '@component/input';
import { BulletType, CustomList, CustomListItem } from '@component/list';
import { Panel, PANEL_SHADOW_BACKGROUND, PanelFooter, PanelHeader, PanelSize } from '@component/panel';
import { DEFAULT_RADIUS } from '@component/panels/basePanel/searchPanel/RadiusSelect';
import { BasePopup } from '@component/popup/BasePopup';
import { PopupCancelButton, PopupDangerButton, PopupOkButton } from '@component/popup/PopupButtons';
import { PopupSizes, usePopup } from '@component/popup/PopupTrackingContext';
import { useScrollToTop } from '@component/scrollView';
import { Text } from '@component/text';
import {
  CheckboxRow,
  StyledBlock,
  StyledHeader,
  StyledPaddedColumn,
  StyledPaddedRow,
} from '@component/truckAndLoadForm/TruckAndLoadFormStyles';
import { Color } from '@style/Color';
import { FontSize } from '@style/StyleConstants';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';
import icons from '@util/iconsConstants';

import { AvailableCapacity, LocationSection, PickUpDates, WeightLengthSlidersForm } from './PostTruckFormSections';
import { PostTruckMileage, PostTruckRateCheck } from './PostTruckUIPlanFeatures';

const PostItem = styled.div`
  margin-bottom: 20px;
`;
const PostListItemTitle = styled.div`
  margin-bottom: 5px;
`;
const PostListItem = styled.div`
  margin-left: 10px;
  justify-content: center;
`;
const ErrorMessage = styled.div`
  font-size: ${FontSize.Body}px;
  color: ${Color.RED_APPLE};
  text-align: center;
  margin: 14px 0 0px 0;
`;

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  dropOff?: LocationSuggestion;
  postRequest: PostTruckAvailabilityForm;
  updatePostRequest: (request: PostTruckAvailabilityForm) => void;
  isLoading?: boolean;
  wasSubmitted?: boolean;
  successText: string;
  failureText: string;
  postId?: number;
  buttonText: string;
  layer?: number;
  offset?: number;
  isVerified?: boolean;
}

const MAX_ADDITIONAL_INFO = 250;
const MAX_ADDITIONAL_INFO_ROWS = 6;
const MAX_DESIRED_RATE = 99.99;

const PostTruckForm: React.FC<Props & RouteComponentProps<{ filter: PostsFilter }>> = (props) => {
  const dispatch = useDispatch();
  const popupContext = usePopup();
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const truckForm = useSelector((state) => state.truckAvailabilityForm.truckAvailability);
  const [anonymous, setAnonymous] = useState(truckForm.anonymous ?? false);
  const [teamDriven, setTeamDriven] = useState(truckForm.teamDriven ?? false);
  const deletingState = useSelector((state) => state.postTruck.deleteAvailability);
  const filter = props.match.params.filter;
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false);
  const panelref = useRef<HTMLDivElement>(null);
  const scrollToTop = useScrollToTop();

  useEffect(() => {
    if (getIsFormValid(truckForm)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
    setAnonymous(truckForm.anonymous ?? false);
    setTeamDriven(truckForm.teamDriven ?? false);
  }, [truckForm]);

  useEffect(() => {
    if (isActionCompleted(wasSubmitted, props.isLoading ?? false)) {
      const message = getMessage(props.successText, props.failureText, props.wasSubmitted);
      dispatch(showSnackbar({ message: message }));
      setWasSubmitted(false);
      dispatch(getTruckAvailabilities(filter));
      dispatch(getTruckAvailabilityCount());
      props.onClose();
    }
  }, [props.isLoading, props.wasSubmitted]);

  useEffect(() => {
    if (isActionCompleted(isDeleting, deletingState.isLoading)) {
      const message = getMessage(
        t(T.common_postTruck_snackBar_postDeleted),
        t(T.common_postTruck_snackBar_failedToDelete),
        deletingState.wasDeleted
      );
      dispatch(
        showSnackbar({
          message: message,
        })
      );
      setIsDeleting(false);
      dispatch(getTruckAvailabilityCount());
      props.onClose();
    }
  }, [deletingState.isLoading, deletingState.wasDeleted]);

  const setLocations = (newOrigin: OriginLocation, newDestination: DestLocation) => {
    props.updatePostRequest({
      ...props.postRequest,
      origin: newOrigin,
      destination: newDestination,
      destRadius: truckForm.originRadius,
      originRadius: truckForm.destRadius,
    });
  };

  const switchLocations = () => {
    onSwitchLocations(setLocations, truckForm.origin, truckForm.destination);
  };

  const handleOriginRadiusChange = (newRadius: string) => {
    props.updatePostRequest({
      ...props.postRequest,
      originRadius: toNumber(newRadius.replace(/,/g, '')),
    });
  };

  const handleDestinationRadiusChange = (newRadius: string) => {
    props.updatePostRequest({
      ...props.postRequest,
      destRadius: toNumber(newRadius.replace(/,/g, '')),
    });
  };

  const handleDatesClear = () => {
    props.updatePostRequest({ ...props.postRequest, pickupDates: [] });
  };

  const onPress = () => {
    if (getIsFormValid(truckForm)) {
      setShouldShowErrorMessage(false);
      setIsFormValid(true);
      setWasSubmitted(true);
      props.onSubmit();
    } else {
      setShouldShowErrorMessage(true);
      setIsFormValid(false);
      scrollToTop(panelref);
    }
  };

  const fieldsValidated = areFieldsValidated(truckForm.origin, truckForm.pickupDates, truckForm?.truck?.equipmentType);

  const onDelete = () => {
    const { openingPopup, closingPopup } = popupContext;
    openingPopup({
      body: (
        <ConfirmationDeletePopup
          closingPopup={closingPopup}
          onDelete={() => {
            if (props.postId) {
              setIsDeleting(true);
              dispatch(deleteTruckAvailability(props.postId, filter));
            }
          }}
        />
      ),
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

  const handleAnonymousStatusChange = (response: CheckboxResponse) => {
    const { isChecked } = response;
    setAnonymous(isChecked);
    props.updatePostRequest({ ...props.postRequest, anonymous: isChecked });
  };

  const onClickAnonymousInfo = () => {
    const { openingPopup, closingPopup } = popupContext;
    openingPopup({
      body: <AnonymousTruckPopupInfo closingPopup={closingPopup} />,
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

  const onClickAdditionalInfoPopup = () => {
    const { openingPopup, closingPopup } = popupContext;
    openingPopup({
      body: <NotesTruckPopupInfo closingPopup={closingPopup} />,
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

  const handleMinRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.updatePostRequest({ ...props.postRequest, minRate: event.target.value });
  };
  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.updatePostRequest({ ...props.postRequest, notes: event.target.value });
  };
  const handleIsTeamDrivenChange = (response: CheckboxResponse) => {
    const { isChecked } = response;
    setTeamDriven(isChecked);
    props.updatePostRequest({ ...props.postRequest, teamDriven: isChecked });
  };

  const onClickRatePerMileInfo = () => {
    const { openingPopup, closingPopup } = popupContext;
    openingPopup({
      body: <MinRatePopupInfo closingPopup={closingPopup} />,
      width: PopupSizes.SMALL,
      isDismissible: true,
      hasCloseButton: true,
    });
  };

  return (
    <Panel
      id="truck_post_lists"
      layer={props.layer ?? 1}
      offset={props.offset ?? 1}
      size={PanelSize.small}
      backgroundColor={PANEL_SHADOW_BACKGROUND}
      scrollRef={panelref}
    >
      {!isFormValid && shouldShowErrorMessage && !fieldsValidated.allValid ? (
        <ErrorMessage id="fill_mandatory_fields">{t(T.postLoads_fillMandatoryFields)}</ErrorMessage>
      ) : null}
      <PanelHeader
        label={t(T.truckPost_TruckPost)}
        hasCloseButton={true}
        onClose={props.onClose}
        actionText={props.postId ? t(T.common_myDocuments_documentActionSheet_delete) : undefined}
        handleClick={onDelete}
      />
      {props.isVerified === false ? <VerifyDotBanner /> : null}
      <LocationSection
        origin={truckForm.origin}
        destination={truckForm.destination}
        handleDestinationRadiusChange={handleDestinationRadiusChange}
        handleOriginRadiusChange={handleOriginRadiusChange}
        radius={{
          origin: truckForm.originRadius ?? DEFAULT_RADIUS,
          destination: truckForm.destRadius ?? DEFAULT_RADIUS,
        }}
        switchLocations={switchLocations}
        error={shouldShowErrorMessage && !fieldsValidated.fields.origin}
      />
      <PostTruckMileage locationOrigin={truckForm.origin} locationDestination={truckForm.destination} />
      <PickUpDates
        pickupDates={truckForm.pickupDates}
        handleDatesClear={handleDatesClear}
        error={shouldShowErrorMessage && !fieldsValidated.fields.dates}
      />
      <StyledBlock>
        <AvailableCapacity
          label={
            truckForm.truck
              ? getStringifiedEquipmentTypeAndName(truckForm.truck.equipmentType, truckForm.truck.displayName)
              : undefined
          }
          loadSize={truckForm.loadSize}
          updatedLoadSize={(size: LoadSize) => props.updatePostRequest({ ...truckForm, loadSize: size })}
          error={shouldShowErrorMessage && !fieldsValidated.fields.truckId}
        />
        <WeightLengthSlidersForm postRequest={props.postRequest} updatePostRequest={props.updatePostRequest} />
        <StyledPaddedColumn>
          <StyledHeader id="rate_per_mile_label">
            {t(T.common_postTruck_minRate_title)}
            <ActionIcon
              onClick={onClickRatePerMileInfo}
              id={'rate_per_mile_info'}
              src={icons.info_small}
              alt={'info_button'}
              size={20}
              style={{ paddingLeft: 5 }}
            />
          </StyledHeader>
        </StyledPaddedColumn>
        <StyledPaddedRow id="rate_per_mile" style={{ height: '64px' }}>
          <ValidatedTextInput
            label={t(T.common_postTruck_minRate_inputLabel)}
            value={truckForm.minRate ?? ''}
            isValidatedWhenEmpty={false}
            onChange={handleMinRateChange}
            validator={validateIsNumberAndLessThan(MAX_DESIRED_RATE)}
            isValidatedOnChange={false}
            isWithoutMargin={false}
            fullWidth={true}
            isErrorMessageMultiline={true}
          />
        </StyledPaddedRow>
        <PostTruckRateCheck locationOrigin={truckForm.origin} locationDestination={truckForm.destination} />
        <StyledPaddedColumn>
          <StyledHeader id="additional_info_post_title">
            {t(T.common_postTruck_additionalInfo_title)}
            <ActionIcon
              onClick={onClickAdditionalInfoPopup}
              id={'additional_info_info'}
              src={icons.info_small}
              alt={'info_button'}
              size={20}
              style={{ paddingLeft: 5 }}
            />
          </StyledHeader>
        </StyledPaddedColumn>
        <StyledPaddedRow id="additional_info">
          <ValidatedTextInput
            label={t(T.common_postTruck_additionalInfo_inputLabel)}
            value={truckForm.notes ?? ''}
            isValidatedWhenEmpty={false}
            onChange={handleNotesChange}
            validator={validateMaxLength(MAX_ADDITIONAL_INFO)}
            isValidatedOnChange={false}
            isWithoutMargin={false}
            fullWidth={true}
            isErrorMessageMultiline={true}
            multiline={true}
            rows={MAX_ADDITIONAL_INFO_ROWS}
          />
        </StyledPaddedRow>
        <CheckboxRow>
          <Checkbox
            id="post_team_driven"
            labelPosition="after"
            name="post_team_driven"
            handleChange={handleIsTeamDrivenChange}
            isChecked={teamDriven}
            label={t(T.common_postTruck_teamDriven)}
          />
        </CheckboxRow>
        <StyledPaddedColumn>
          <StyledHeader id="anonymous_post_title" style={{ marginBottom: 0 }}>
            {t(T.common_postTruck_AnonymousPost_header)}
            <ActionIcon
              onClick={onClickAnonymousInfo}
              id={'anonymous_info'}
              src={icons.info_small}
              alt={'info_button'}
              size={20}
              style={{ paddingLeft: 5 }}
            />
          </StyledHeader>
        </StyledPaddedColumn>
        <Checkbox
          id="post_anonymously"
          labelPosition="after"
          name="post_anonymously"
          handleChange={handleAnonymousStatusChange}
          isChecked={anonymous}
          label={t(T.common_postTruck_AnonymousPost_AnonymousPopup_title)}
        />
      </StyledBlock>
      <PanelFooter>
        <MultipurposeButton
          id="save_profile"
          variant={'contained'}
          text={props.buttonText}
          color={'primary'}
          isLoading={props.isLoading ?? false}
          onClick={onPress}
          disabled={false}
        />
      </PanelFooter>
    </Panel>
  );
};

const areFieldsValidated = (pickupOrigin?: OriginLocation, pickupDates?: string[], postTruckId?: EquipmentType) => {
  const validationFields = {
    origin: false,
    dates: false,
    truckId: false,
  };

  if (!isEmpty(pickupOrigin?.states)) {
    validationFields.origin = true;
  }
  if (!isEmpty(pickupDates)) {
    validationFields.dates = true;
  }
  if (!isEmpty(postTruckId)) {
    validationFields.truckId = true;
  }

  return { allValid: !includes(validationFields, false), fields: validationFields };
};

const onSwitchLocations = (
  updateLocation: (origin: OriginLocation, destination: DestLocation) => void,
  origin?: OriginLocation,
  destination?: DestLocation
) => {
  let newOrigin;
  let newDestination;
  if (
    destination &&
    (destination?.type === LocationType.CITY ||
      destination?.type === LocationType.ZIP ||
      destination.type === LocationType.GEOLOCATION)
  ) {
    newOrigin = clone(destination);
  }
  if (
    origin &&
    (origin.type === LocationType.CITY ||
      origin?.type === LocationType.ZIP ||
      origin?.type === LocationType.GEOLOCATION)
  ) {
    newDestination = clone(origin);
  }
  if (newOrigin && newDestination) {
    updateLocation(newOrigin, newDestination);
  }
};

const getIsFormValid = (form: PostTruckAvailabilityForm) => {
  if (
    form.minRate &&
    (!isNumber(form.minRate) || (isNumber(form.minRate) && parseFloat(form.minRate) >= MAX_DESIRED_RATE))
  ) {
    return false;
  }
  if (form.pickupDates.length <= 0 || isEmptyOriginLocation(form.origin) || form.truck === undefined) {
    return false;
  } else {
    return true;
  }
};

const isActionCompleted = (wasSubmitted: boolean, isLoading: boolean) => {
  return wasSubmitted && !isLoading;
};

const getMessage = (successMessage: string, failureMessage: string, success?: boolean) => {
  return success ? successMessage : failureMessage;
};

const NotesTruckPopupInfo = (props: { closingPopup: () => void }) => (
  <BasePopup
    title={t(T.common_postTruck_additionalInfo_title)}
    buttons={[<PopupOkButton testID={'notes_confirm'} key={'notes_confirm'} action={props.closingPopup} />]}
  >
    <PostItem>{t(T.common_postTruck_additionalInfo_info)}</PostItem>
  </BasePopup>
);

const AnonymousTruckPopupInfo = (props: { closingPopup: () => void }) => (
  <BasePopup
    title={t(T.common_postTruck_AnonymousPost_AnonymousPopup_title)}
    buttons={[
      <PopupOkButton testID={'anonymous_info_confirm'} key={'anonymous_confirm'} action={props.closingPopup} />,
    ]}
  >
    <PostItem>{t(T.common_postTruck_AnonymousPost_AnonymousPopup_paragraph)}</PostItem>
    <PostListItemTitle>{t(T.common_postTruck_AnonymousPost_AnonymousPopup_listTitle)}</PostListItemTitle>
    <PostListItem>
      <Text id="anonymous_popup_list">
        <CustomList bulletType={BulletType.Dot}>
          <CustomListItem id={'anonymous_popup_list_one'}>
            {t(T.common_postTruck_AnonymousPost_AnonymousPopup_listItemOne)}
          </CustomListItem>
          <CustomListItem id={'anonymous_popup_list_two'}>
            {t(T.common_postTruck_AnonymousPost_AnonymousPopup_listItemTwo)}
          </CustomListItem>
          <CustomListItem id={'anonymous_popup_list_three'}>
            {t(T.common_postTruck_AnonymousPost_AnonymousPopup_listItemThree)}
          </CustomListItem>
          <CustomListItem id={'anonymous_popup_list_four'}>
            {t(T.common_postTruck_AnonymousPost_AnonymousPopup_listItemFour)}
          </CustomListItem>
        </CustomList>
      </Text>
    </PostListItem>
  </BasePopup>
);

const MinRatePopupInfo = (props: { closingPopup: () => void }) => (
  <BasePopup
    title={t(T.common_postTruck_minRate_title)}
    buttons={[
      <PopupOkButton testID={'min_rate_info_confirm'} key={'min_rate_info_confirm'} action={props.closingPopup} />,
    ]}
  >
    <PostItem>{t(T.common_postTruck_minRate_info)}</PostItem>
  </BasePopup>
);

const ConfirmationDeletePopup = (props: { closingPopup: () => void; onDelete: () => void }) => (
  <BasePopup
    title={t(T.common_postTruck_popUps_deleteTruckPost_title)}
    buttons={[
      <PopupCancelButton testID={'cancel'} key={'cancel'} action={props.closingPopup} />,
      <PopupDangerButton
        testID={'delete'}
        key={'delete'}
        label={t(T.common_postTruck_popUps_deleteTruckPost_button)}
        action={() => {
          props.onDelete();
          props.closingPopup();
        }}
      />,
    ]}
  >
    <PostItem>{t(T.common_postTruck_popUps_deleteTruckPost_body)}</PostItem>
  </BasePopup>
);

export const PostTruckFormUI = withRouter(PostTruckForm);

import React from 'react';

import moment from 'moment';
import styled from 'styled-components';

import { displayCurrencyOrDash, hasDecimal } from '@common/formatter';
import { formatDateDotSeparatedWithTime } from '@common/helper';
import { BidStatus } from '@common/model';
import { Status } from '@component/loadListCard/LoadListCardStyle';
import BidIconPale from '@component/menu/icon/bid-icon-pale.svg';
import { Text } from '@component/text/Text';
import { Color } from '@style/Color';
import { FontFamily, FontSize, FontWeight, Spacing } from '@style/StyleConstants';
import { T, t } from '@translate';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 6px;
  margin-right: 10px;
  align-self: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const HeaderText = styled(Text)`
  font-family: ${FontFamily.Main};
  max-width: 150px;
`;

export const Title = styled(HeaderText)`
  font-size: ${FontSize.Content}px;
  font-weight: ${FontWeight.Bold};
`;

export const Subtitle = styled(HeaderText)`
  font-size: ${FontSize.Body}px;
  margin-top: 2px;
`;

export const Price = styled(HeaderText)`
  font-size: ${FontSize.ModalTitle}px;
  font-weight: ${FontWeight.Bold};
`;

export const EndContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
`;

interface ListItemProps {
  image: JSX.Element;
  title: JSX.Element;
  subtitle: JSX.Element;
  end?: JSX.Element;
}

export const ListItem = (props: ListItemProps) => (
  <Container>
    <ImageContainer>{props.image}</ImageContainer>
    <TextContainer>
      {props.title}
      {props.subtitle}
    </TextContainer>
    {props.end}
  </Container>
);

interface NoActionBidItemProps {
  isCarrier: boolean;
  offerMadeByMe: boolean;
  mostRecentActivity: string;
  price: number;
  status: BidStatus.Accepted | BidStatus.Withdrawn | BidStatus.Rejected;
}

export const NoActionBidItem = (props: NoActionBidItemProps) => {
  const { isCarrier, offerMadeByMe, mostRecentActivity, price, status } = props;

  let cardTitle = '';
  if (isCarrier) {
    if (offerMadeByMe) {
      cardTitle = t(T.common_bids_bidItem_titles_yourOffer);
    } else {
      cardTitle = t(T.common_bids_bidItem_titles_counterOffer);
    }
  } else {
    if (offerMadeByMe) {
      cardTitle = t(T.common_bids_bidItem_titles_yourCounteroffer);
    } else {
      cardTitle = t(T.common_bids_bidItem_titles_carrierOffer);
    }
  }

  let statusColor;
  let statusText;
  if (status === BidStatus.Accepted) {
    statusColor = Color.GREEN_MAIN;
    statusText = t(T.common_bids_status_accepted);
  } else if (status === BidStatus.Withdrawn) {
    statusColor = Color.BLACK;
    statusText = t(T.common_bids_status_withdrawn);
  } else if (status === BidStatus.Rejected) {
    statusColor = Color.PURPLE;
    statusText = t(T.common_bids_status_rejected);
  }

  return (
    <div
      style={{
        padding: Spacing.ScreenSide,
        background: Color.WHITE,
        borderBottom: `1px solid ${Color.GRAY_SMOKE}`,
      }}
      id="bid_summary_item"
    >
      <ListItem
        image={<img id="pale_bid_icon" src={BidIconPale} />}
        title={<Title id="title_offer_card">{cardTitle}</Title>}
        subtitle={
          <Subtitle id="subtitle_offer_date">{formatDateDotSeparatedWithTime(mostRecentActivity ?? '')}</Subtitle>
        }
        end={
          <EndContainer>
            <Price id="price_value_offer">{`${displayCurrencyOrDash(price, hasDecimal(price))}`}</Price>
            <Status
              style={{ width: '100%', textAlign: 'center' }}
              id="bid_status_offer"
              color={statusColor ?? Color.BLACK}
            >
              {statusText}
            </Status>
          </EndContainer>
        }
      />
    </div>
  );
};

interface ActionBidItemProps {
  isNewBid?: boolean;
  isCarrier?: boolean;
  offerMadeByMe?: boolean;
  mostRecentActivity?: string;
  offerActions: JSX.Element;
  content: JSX.Element;
  end?: JSX.Element;
}

export const ActionBidItem = (props: ActionBidItemProps) => {
  const { isNewBid, isCarrier, offerMadeByMe, mostRecentActivity, offerActions, content, end } = props;

  let title = t(T.common_bids_bidItem_titles_available);
  let subtitle = formatDateDotSeparatedWithTime(moment());

  if (!isNewBid) {
    subtitle = formatDateDotSeparatedWithTime(mostRecentActivity ?? '');

    if (isCarrier) {
      if (offerMadeByMe) {
        title = t(T.common_bids_bidItem_titles_yourOffer);
      } else {
        title = t(T.common_bids_bidItem_titles_counterOffer);
      }
    } else {
      if (offerMadeByMe) {
        title = t(T.common_bids_bidItem_titles_yourCounteroffer);
      } else {
        title = t(T.common_bids_bidItem_titles_carrierOffer);
      }
    }
  }

  return (
    <div
      style={{
        padding: Spacing.ScreenSide,
        background: Color.WHITE,
        borderBottom: `1px solid ${Color.GRAY_SMOKE}`,
      }}
      id="bid_summary_item"
    >
      <ListItem
        image={<img id="pale_bid_icon" src={BidIconPale} />}
        title={<Title id="title_offer_card">{title}</Title>}
        subtitle={<Subtitle id="subtitle_offer_date">{subtitle}</Subtitle>}
        end={end}
      />
      <div style={{ marginTop: 12, marginBottom: 8 }}>{content}</div>
      {offerActions}
    </div>
  );
};

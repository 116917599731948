import React from 'react';

import { Layer, Map } from 'leaflet';
import { LayerContainer } from 'react-leaflet';
import { withLeaflet as withLeafletOri } from 'react-leaflet/es/context';

// Temporary. React-leaflet types doesn't seem to provide the LeafletContext type yet
export interface LeafletContext {
  map?: Map;
  pane?: string;
  layerContainer?: LayerContainer;
  popupContainer?: Layer;
}

export interface LeafletProps {
  leaflet: LeafletContext;
}

// Redirecting the import so that we can import both the context interface and the withLeaflet() from the same directory
export const withLeaflet = (component: React.ComponentType) => withLeafletOri(component);

import firebase from 'firebase/app';

import '@firebase/messaging';

import { AppConfig } from '@util/AppConfig';

import {
  askForPermissionToReceiveNotifications,
  initializeFirebase,
  NotificationPermissionStatus,
} from './util/firebaseNotification/FirebasePushNotification';

const serviceWorkerName = (await import('./build_src/service-worker.name.js')) as unknown as string;

export function unregisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      registrations.forEach(function (registration) {
        registration.unregister();
      });
    });
  }
}

export function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(serviceWorkerName)
        .then((registration) => {
          if (firebase.messaging.isSupported()) {
            initializeFirebase();

            const messaging = firebase.messaging();
            // biome-ignore lint/correctness/useHookAtTopLevel: false positive
            messaging.useServiceWorker(registration);
            if (Notification.permission === NotificationPermissionStatus.GRANTED) {
              messaging
                .getToken({
                  vapidKey: AppConfig.LBConfig.FirebaseKeys.vapidKey,
                })
                .then((currentToken) => {
                  if (currentToken) {
                    firebase.messaging().onTokenRefresh((currentToken) => currentToken);
                  } else {
                    // Show permission request.
                    askForPermissionToReceiveNotifications();
                  }
                })
                .catch(() => {
                  return;
                });
            }
          }
        })
        .catch(() => {
          return;
        });
    });
  }
}

import React from 'react';

import { clone, map, split } from 'lodash';
import styled from 'styled-components';

import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { ThemeProps, withTheme } from '@style/WithTheme';

export enum TextStyle {
  Header1Tab = 'header1Tab',
  Header1ProfileTab = 'header1ProfileTab',
  Header2Title = 'header2Title',
  Header2Button = 'header2Button',
  DropBoxTitle = 'dropBoxTitle',
  Header3 = 'header3',
  RedHeader3 = 'redHeader3',
  Default = 'default',
  DefaultLight = 'defaultLight',
  Link = 'link',
  BigLink = 'bigLink',
  BigLinkBold = 'bigLinkBold',
  PrimaryButton = 'primaryButton',
  Alert = 'alert',
  AlertLarge = 'alertLarge',
  FooterLink = 'footerLink',
  FooterText = 'footerText',
  HamburgerHeader = 'hamburgerHeader',
  Submenu = 'submenu',
  Version = 'version',
  CompactVersion = 'compactVersion',
  HamburgerTab = 'hamburgerTab',
  InfoFieldLabel = 'infoFieldLabel',
  InfoFieldValue = 'infoFieldValue',
  InputFieldLabel = 'inputFieldLabel',
  InputFieldValue = 'inputFieldValue',
  InputFieldShow = 'inputFieldShow',
  Note = 'note',
  InfoTableHeader = 'infoTableHeader',
  Disclaimer = 'disclaimer',
  ContactDisclaimer = 'contactDisclaimer',
  HamburgerMenuSeparator = 'hamburgerMenuSeparator',
  LargerMenuSeparator = 'largerMenuSeparator',
  MenuSeparator = 'menuSeparator',
  TableRow = 'tableRow',
  TableHeader = 'tableHeader',
  TableSubContent = 'tableSubContent',
  TableTitle = 'tableTitle',
  TableSeparator = 'tableSeparator',
  MidResult = 'midResult',
  BigResult = 'bigResult',
  BiggerResult = 'biggerResult',
  HeavyText = 'heavyText',
  LightText = 'lightText',
  DashboardHeader = 'dashboardHeader',
  DashboardHeaderSmall = 'dashboardHeaderSmall',
  DashboardItemLabel = 'dashboardItemLabel',
  DashboardItemLabelSmall = 'dashboardItemLabelSmall',
  DashboardPostedLoadCount = 'dashboardPostedLoadCount',
  DashboardPostedLoadCountSmall = 'dashboardPostedLoadCountSmall',
  Error = 'error',
  ErrorSmall = 'errorSmall',
  NakedPageTitle = 'nakedPageTitle',
  NakedPageMessage = 'nakedPageMessage',
  DetailContent = 'detailContent',
  DetailContentLink = 'detailContentLink',
  TextContentDetail = 'textContentDetail',
  TextContentDetailGreen = 'textContentDetailGreen',
  TableHeaderGreen = 'tableHeaderGreen',
  TableHeaderRed = 'tableHeaderRed',
  VerifyEmailHeader = 'verifyEmailHeader',
  VerifyEmailHeaderSmall = 'verifyEmailHeaderSmall',
  VerifyEmailSubheader = 'verifyEmailSubheader',
  SubtitleBold = 'subtitleBold',
  SubtitleRegular = 'subtitleRegular',
  Subtitle2Regular = 'subtitle2Regular',
  Subtitle2Bold = 'subtitle2Bold',
  SubtitleNormalWeight = 'subtitleNormalWeight',
  BodyLarge = 'bodyLarge',
  Body = 'body',
  Body2 = 'body2',
  Disabled = 'disabled',
  H2 = 'h2',
}

export enum TextDisplay {
  InlineFlex = 'inline-flex',
  InlineBlock = 'inline-block',
  Inline = 'inline',
}

const getTextStyle = (textStyle?: TextStyle) => (textStyle ? textStyle : TextStyle.Default);

const getFontSize = (theme: Theme, textStyle: TextStyle) => (textStyle ? theme.text[textStyle].fontSize : null);
const getFontFamily = (theme: Theme, textStyle: TextStyle) => (textStyle ? theme.text[textStyle].fontFamily : null);
const getFontWeight = (theme: Theme, textStyle: TextStyle) => (textStyle ? theme.text[textStyle].fontWeight : null);
const getLineHeight = (theme: Theme, textStyle: TextStyle) => (textStyle ? theme.text[textStyle].lineHeight : null);
const getLetterSpacing = (theme: Theme, textStyle: TextStyle) =>
  textStyle ? theme.text[textStyle].letterSpacing : null;
const getTextTransform = (theme: Theme, textStyle: TextStyle) =>
  textStyle ? theme.text[textStyle].textTransform : null;
const getColor = (theme: Theme, textStyle: TextStyle) => (textStyle ? theme.text[textStyle].color : null);

const StyledText = withTheme()(styled.span`
  white-space: ${(props: ThemeProps & TextProps) => (props.noWrap ? 'nowrap' : 'normal')};
  display: ${(props: ThemeProps & TextProps) => (props.display ? props.display : TextDisplay.Inline)};
  align-items: ${(props: ThemeProps & TextProps) => (props.center ? 'center' : 'flex-start')};
  font-size: ${(props: ThemeProps & TextProps) => getFontSize(props.theme, getTextStyle(props.textStyle))};
  font-weight: ${(props: ThemeProps & TextProps) => getFontWeight(props.theme, getTextStyle(props.textStyle))};
  font-family: ${(props: ThemeProps & TextProps) => getFontFamily(props.theme, getTextStyle(props.textStyle))};
  line-height: ${(props: ThemeProps & TextProps) => getLineHeight(props.theme, getTextStyle(props.textStyle))};
  text-transform: ${(props: ThemeProps & TextProps) => getTextTransform(props.theme, getTextStyle(props.textStyle))};
  letter-spacing: ${(props: ThemeProps & TextProps) => getLetterSpacing(props.theme, getTextStyle(props.textStyle))};
  color: ${(props: ThemeProps & TextProps) => {
    if (props.isDisabled) {
      return props.theme.text.disabled.color;
    }
    return !props.isSelected && props.textStyle === TextStyle.Header1Tab
      ? props.theme.text.header1Tab.unselectedColor
      : getColor(props.theme, getTextStyle(props.textStyle));
  }};

  > a {
    text-decoration: none;
  }
  align-content: center;
  white-space: ${(props: ThemeProps & TextProps) => (props.noWrap ? 'nowrap' : 'normal')};
  overflow: ${(props: ThemeProps & TextProps) => (props.truncateOverflow ? 'hidden' : '')};
  text-overflow: ${(props: ThemeProps & TextProps) => (props.truncateOverflow ? 'ellipsis' : '')};
  -webkit-text-size-adjust: 100%;
`);

export interface TextProps {
  id: string;
  textStyle?: TextStyle;
  isSelected?: boolean;
  display?: TextDisplay;
  center?: boolean;
  noWrap?: boolean;
  truncateOverflow?: boolean;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

export const Text: React.FC<TextProps> = (props) => <StyledText {...props}>{parseChildren(props.children)}</StyledText>;

const parseChildren = (children: React.ReactNode) => {
  const nodes = clone(children);
  if (nodes instanceof Array) {
    for (let i = 0; i < nodes.length; i++) {
      nodes[i] = parseText(nodes[i]);
    }
    return nodes;
  }
  if (typeof nodes === 'string') {
    return parseText(nodes);
  }
  return children;
};

const parseText = (value: React.ReactNode) => {
  if (typeof value === 'string') {
    const text = value as string;
    const lines = split(text, '\n');
    if (lines.length > 1) {
      return map(lines, (line, index) => {
        //we dont want an extra line break on the last sentence.
        if (index === lines.length - 1) {
          return line;
        }
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        );
      });
    }
  }
  return value;
};

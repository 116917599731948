import React from 'react';

import { displayCurrencyOrDash } from '@common/formatter';
import {
  AlignBaselineRow,
  ContentDetailText,
  PostedRate,
  RateCheckDataInfo,
  RateCheckDataValue,
  RateDiff,
} from '@component/panels/findLoads/loadDetailsPanel/LoadDetailsPanelStyle';
import { t, T } from '@translate';

interface ComponentProps {
  numericPostedRate: number | undefined;
  postedRate: string | undefined;
  rateDiff: number | undefined;
}

export const RateCheckPostedRateDifference: React.FC<ComponentProps> = (props) => {
  return props.numericPostedRate ? (
    <>
      <AlignBaselineRow>
        <RateCheckDataValue>
          <PostedRate id="posted_rate_value">{props.postedRate}</PostedRate>
        </RateCheckDataValue>
        <RateCheckDataInfo>
          <ContentDetailText id="posted_rate_label">{t(T.findLoads_findLoads_postedRate)}</ContentDetailText>
        </RateCheckDataInfo>
      </AlignBaselineRow>
      {props.rateDiff ? (
        <AlignBaselineRow>
          <RateCheckDataValue>
            <RateDiff id="difference_value" rateDiff={props.rateDiff}>
              {displayCurrencyOrDash(props.rateDiff, true, { suffix: t(T.common_per_miles_short) })}
            </RateDiff>
          </RateCheckDataValue>
          <RateCheckDataInfo>
            <ContentDetailText id="difference_label">{t(T.findLoads_findLoads_difference)}</ContentDetailText>
          </RateCheckDataInfo>
        </AlignBaselineRow>
      ) : null}
    </>
  ) : null;
};

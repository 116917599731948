import { filter, find, flatten, map, reduce } from 'lodash';

import { FilterOptionSpecificInput } from '@common/helper/FilterHelper';
import { DisplayOption } from '@common/helper/InterfaceHelper';
import { Sort } from '@common/helper/SortByKeys';
import {
  EquipmentType,
  FMCSAAuthorityStatus,
  LoadSortDirection,
  Location,
  VerificationPointsTaskStatus,
} from '@common/model';
import { T, t } from '@translate';

export const DEFAULT_AGE = 48;
export const DEFAULT_CAPACITY_FINDER_RADIUS = 150;
export const MIN_AGE = 1;
export const MAX_AGE = 99;

/**
 * Request & Response types
 * */

export enum CapacityType {
  Availability = 'Availability',
  Search = 'Search',
}

enum CapacityAvailabilityType {
  None = 'None',
  Inbound = 'Inbound',
  Intrastate = 'Intrastate',
  Outbound = 'Outbound',
}

export enum CapacityLocationType {
  ZipCode = 'ZipCode',
  City = 'City',
}

export enum CapacityFilterType {
  IsOnboarded = 'IsOnboarded',
  IsFavorite = 'IsFavorite',
  IsGPSVerified = 'IsGPSVerified',
  IsTeamDriven = 'IsTeamDriven',
  ActiveAuthorityMC = 'ActiveAuthorityMC',
}

export interface CapacitiesRequest {
  pickup: CapacityLocation;
  dropOff: CapacityLocation;
  equipmentTypes: EquipmentType[];
  includesUnspecifiedSize: boolean;
  loadSize: CapacityLoadSize;
  maxAge: number;
  filter: CapacityFilterType[];
  sort: CapacitySort;
  token?: string;
}

export interface CapacityLocation {
  type: CapacityLocationType;
  city?: string;
  state?: string;
  zipCode?: string;
  radius: number;
}

interface CapacitySort {
  direction: LoadSortDirection;
  criteria: CapacityFinderSortCategory;
}

export interface CapacitiesResponse {
  capacities: Capacity[];
  metadata: CapacityMetadata;
}

export interface Capacity {
  type: CapacityType;
  capacityReference: string;
  equipmentTypes: EquipmentType[];
  desiredRate: number;
  isOnboarded: boolean;
  isFavoriteCarrier: boolean;
  isCarrierIdentityVerified: boolean;
  isLocationTrackingEnabled: boolean;
  loadSize: CapacityLoadSize;
  contact: CapacityContact;
  company: CapacityCompany;
  availability: CapacityAvailability;
  lastKnownLocation: CapacityLastKnownLocation;
  authorityStatus: CapacityAuthorityStatus;
  destinations: Location[];
  totalMileage?: number;
}

export interface CapacityMetadata {
  limit: number;
  totalResultCount: number;
  queryTime: number;
  pageResultCount: number;
  token: string;
  isLastResult: boolean;
}

interface CapacityContact {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneExt: string;
  phoneVerificationStatus: VerificationPointsTaskStatus;
}

interface CapacityCompany {
  id: string;
  name: string;
  dotNumber: string;
  docketNumber: number;
  rating?: CarrierRating;
}

interface CarrierRating {
  reviewCount: number;
  starRating: number;
}

interface CapacityAvailability {
  age: number;
  availableLength: number;
  availableWeight: number;
  type: CapacityAvailabilityType;
  isAvailable: number;
  availableDates: string[];
  deadhead: number;
  isTeamDriven: boolean;
  notes?: string;
}

interface CapacityLastKnownLocation {
  latitude: number;
  longitude: number;
  date: string;
}

interface CapacityAuthorityStatus {
  commonAuthorityStatus: FMCSAAuthorityStatus;
  contractAuthorityStatus: FMCSAAuthorityStatus;
  brokerAuthorityStatus: FMCSAAuthorityStatus;
  authorityIssuance: string;
  bipdOnFile: number;
}

/**
 * Sorting
 * */

export interface CapacityFinderSort {
  direction: LoadSortDirection;
  field: CapacityFinderSortCategory;
}

export type IKeysSortByCapacityFinder = Sort & CapacityFinderSort;

export enum CapacityFinderSortCategory {
  AGE = 'Age',
  COMPANY = 'Company',
  RATING = 'Rating',
  DEADHEAD = 'Deadhead',
  EQUIPMENT = 'Equipment',
  SIZE = 'Size',
  AVAILABLE_CAPACITY = 'AvailableCapacity',
  RATE = 'Rate',
  PHONE = 'Phone',
  NEXT_AVAILABLE = 'NextAvailable',
}

export const CapacityFinderKeysSortBy: IKeysSortByCapacityFinder[] = [
  {
    id: '1',
    title: t(T.capacityFinder_sorting_age),
    field: CapacityFinderSortCategory.AGE,
    direction: LoadSortDirection.Asc,
  },
  {
    id: '2',
    title: t(T.capacityFinder_sorting_company),
    field: CapacityFinderSortCategory.COMPANY,
    direction: LoadSortDirection.Asc,
  },
  {
    id: '3',
    title: t(T.capacityFinder_sorting_rating),
    field: CapacityFinderSortCategory.RATING,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '4',
    title: t(T.capacityFinder_sorting_deadhead),
    field: CapacityFinderSortCategory.DEADHEAD,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '5',
    title: t(T.capacityFinder_sorting_size),
    field: CapacityFinderSortCategory.SIZE,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '6',
    title: t(T.capacityFinder_sorting_availableCapacity),
    field: CapacityFinderSortCategory.AVAILABLE_CAPACITY,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '7',
    title: t(T.capacityFinder_sorting_desiredRate),
    field: CapacityFinderSortCategory.RATE,
    direction: LoadSortDirection.Desc,
  },
  {
    id: '8',
    title: t(T.capacityFinder_sorting_phone),
    field: CapacityFinderSortCategory.PHONE,
    direction: LoadSortDirection.Asc,
  },
  {
    id: '9',
    title: t(T.capacityFinder_sorting_nextAvailable),
    field: CapacityFinderSortCategory.NEXT_AVAILABLE,
    direction: LoadSortDirection.Asc,
  },
];

export const CapacityFinderSortByOptions = map(CapacityFinderKeysSortBy, (sortBy) => ({
  id: sortBy.id,
  key: sortBy.id,
  value: sortBy,
  displayValue: sortBy.title,
}));

export const CapacityFinderListSortByOptions = (
  keys: IKeysSortByCapacityFinder[]
): DisplayOption<IKeysSortByCapacityFinder>[] =>
  map(keys, (sortBy) => ({
    id: sortBy.id,
    key: sortBy.id,
    value: sortBy,
    displayValue: sortBy.title,
  }));

export const compareCapacityFinderSortOptionsByField = <T extends IKeysSortByCapacityFinder>(a: T, b: T) =>
  a.field === b.field;

export const capacityFinderKeyFromSortCategory = (category: CapacityFinderSortCategory) =>
  find(CapacityFinderKeysSortBy, (key) => key.field === category);

/**
 * Filtering
 * */

export interface CapacityFinderSearchFilter {
  pickupRadius: number;
  dropoffRadius: number;
  isOnboardedCarriers?: boolean;
  isFavoriteCarriers?: boolean;
  isGpsVerified?: boolean;
  isActiveAuthority?: boolean;
  age: number;
  isTeamDrivers?: boolean;
  loadSize?: CapacityLoadSize;
}

export enum CapacityLoadSize {
  Any = 'Any',
  LTL = 'LTL',
  TL = 'TL',
}

export enum CapacityFinderFilterType {
  PickupRadius = 'pickup_radius',
  DropoffRadius = 'dropoff_radius',
  OnboardedCarriers = 'onboarded_carriers',
  FavoriteCarriers = 'favorite_carriers',
  GpsVerified = 'gps_verified',
  ActiveAuthority = 'active_authority',
  Age = 'age',
  TeamDrivers = 'team_drivers',
  LTL = 'ltl',
}

export interface CapacityFinderFilterOption {
  filterType: CapacityFinderFilterType;
  title: string;
  testID: string;
  isSelected: boolean;
  filterSpecificInput?: FilterOptionSpecificInput;
}

export const capacityFinderFilterOptionsFromSearchFilterDraft = (
  searchFilterDraft: CapacityFinderSearchFilter
): CapacityFinderFilterOption[][] => {
  return [
    [
      {
        filterType: CapacityFinderFilterType.OnboardedCarriers,
        title: t(T.capacityFinder_filtering_onboardedCarriers),
        testID: `${CapacityFinderFilterType.OnboardedCarriers}_${Boolean(searchFilterDraft.isOnboardedCarriers)}`,
        isSelected: Boolean(searchFilterDraft.isOnboardedCarriers),
      },
      {
        filterType: CapacityFinderFilterType.FavoriteCarriers,
        title: t(T.capacityFinder_filtering_favoriteCarriers),
        testID: `${CapacityFinderFilterType.FavoriteCarriers}_${Boolean(searchFilterDraft.isFavoriteCarriers)}`,
        isSelected: Boolean(searchFilterDraft.isFavoriteCarriers),
      },
      {
        filterType: CapacityFinderFilterType.GpsVerified,
        title: t(T.capacityFinder_filtering_gpsVerified),
        testID: `${CapacityFinderFilterType.GpsVerified}_${Boolean(searchFilterDraft.isGpsVerified)}`,
        isSelected: Boolean(searchFilterDraft.isGpsVerified),
      },
      {
        filterType: CapacityFinderFilterType.ActiveAuthority,
        title: t(T.capacityFinder_filtering_activeAuthority),
        testID: `${CapacityFinderFilterType.ActiveAuthority}_${Boolean(searchFilterDraft.isActiveAuthority)}`,
        isSelected: Boolean(searchFilterDraft.isActiveAuthority),
      },
      {
        filterType: CapacityFinderFilterType.TeamDrivers,
        title: t(T.common_load_filter_teamDrivers),
        testID: `${CapacityFinderFilterType.TeamDrivers}_${Boolean(searchFilterDraft.isTeamDrivers)}`,
        isSelected: Boolean(searchFilterDraft.isTeamDrivers),
      },
      {
        filterType: CapacityFinderFilterType.LTL,
        title: t(T.capacityFinder_filtering_ltlOnly),
        testID: `${CapacityFinderFilterType.LTL}_${Boolean(searchFilterDraft.loadSize)}`,
        isSelected: searchFilterDraft.loadSize === CapacityLoadSize.LTL,
      },
    ],
  ];
};

export const selectCapacityFinderFilterOption = (
  searchFilter: CapacityFinderSearchFilter,
  filterOption: CapacityFinderFilterOption
): Partial<CapacityFinderSearchFilter> => {
  switch (filterOption.filterType) {
    case CapacityFinderFilterType.OnboardedCarriers: {
      return {
        isOnboardedCarriers: !filterOption.isSelected,
      };
    }
    case CapacityFinderFilterType.FavoriteCarriers: {
      return {
        isFavoriteCarriers: !filterOption.isSelected,
      };
    }
    case CapacityFinderFilterType.GpsVerified: {
      return {
        isGpsVerified: !filterOption.isSelected,
      };
    }
    case CapacityFinderFilterType.ActiveAuthority: {
      return {
        isActiveAuthority: !filterOption.isSelected,
      };
    }
    case CapacityFinderFilterType.Age: {
      return {
        age: searchFilter.age,
      };
    }
    case CapacityFinderFilterType.TeamDrivers: {
      return {
        isTeamDrivers: !filterOption.isSelected,
      };
    }
    case CapacityFinderFilterType.LTL: {
      return {
        loadSize: !filterOption.isSelected ? CapacityLoadSize.LTL : undefined,
      };
    }
    default: {
      return {};
    }
  }
};

export const DEFAULT_SEARCH_FILTER_DRAFT: CapacityFinderSearchFilter = {
  pickupRadius: DEFAULT_CAPACITY_FINDER_RADIUS,
  dropoffRadius: DEFAULT_CAPACITY_FINDER_RADIUS,
  age: DEFAULT_AGE,
};

const selectedCapacityFinderFiltersCount = (selectedFilterSections: CapacityFinderFilterOption[][]): number => {
  return reduce(flatten(selectedFilterSections), (count, option) => (option.isSelected ? count + 1 : count), 0);
};

export const shouldHideClearFiltersButton = (searchFilterDraft: CapacityFinderSearchFilter) => {
  const { pickupRadius, dropoffRadius } = searchFilterDraft;
  const sections = capacityFinderFilterOptionsFromSearchFilterDraft(searchFilterDraft);
  const selectedSectionsCount = selectedCapacityFinderFiltersCount(sections);
  return (
    selectedSectionsCount === 0 &&
    pickupRadius === DEFAULT_CAPACITY_FINDER_RADIUS &&
    dropoffRadius === DEFAULT_CAPACITY_FINDER_RADIUS
  );
};

export const selectedCapacityFinderFilterOptions = (
  selectedSearchFilter: CapacityFinderSearchFilter
): CapacityFinderFilterOption[] => {
  const existingSections = flatten(capacityFinderFilterOptionsFromSearchFilterDraft(selectedSearchFilter));
  return [
    {
      filterType: CapacityFinderFilterType.PickupRadius,
      title: t(T.capacityFinder_filtering_pickupRadiusWithMi, { radius: selectedSearchFilter.pickupRadius }),
      testID: `${CapacityFinderFilterType.PickupRadius}_${Boolean(selectedSearchFilter.pickupRadius)}`,
      isSelected: Boolean(selectedSearchFilter.pickupRadius),
    },
    {
      filterType: CapacityFinderFilterType.DropoffRadius,
      title: t(T.capacityFinder_filtering_dropoffRadiusWithMi, { radius: selectedSearchFilter.dropoffRadius }),
      testID: `${CapacityFinderFilterType.DropoffRadius}_${Boolean(selectedSearchFilter.dropoffRadius)}`,
      isSelected: Boolean(selectedSearchFilter.dropoffRadius),
    },
    {
      filterType: CapacityFinderFilterType.Age,
      title: t(T.capacityFinder_filtering_age, { age: selectedSearchFilter.age }),
      testID: `${CapacityFinderFilterType.Age}_${Boolean(selectedSearchFilter.age?.valueOf)}`,
      isSelected: Boolean(selectedSearchFilter.age),
    },
    ...filter(existingSections, (option) => option.isSelected),
  ];
};

export const omitFilterByCapacityFinderFilterType = (
  filters: CapacityFinderSearchFilter,
  filterType: CapacityFinderFilterType
) => {
  switch (filterType) {
    case CapacityFinderFilterType.OnboardedCarriers:
      return { ...filters, isOnboardedCarriers: undefined };
    case CapacityFinderFilterType.FavoriteCarriers:
      return { ...filters, isFavoriteCarriers: undefined };
    case CapacityFinderFilterType.GpsVerified:
      return { ...filters, isGpsVerified: undefined };
    case CapacityFinderFilterType.ActiveAuthority:
      return { ...filters, isActiveAuthority: undefined };
    case CapacityFinderFilterType.Age:
      return { ...filters, minAge: undefined, maxAge: undefined };
    case CapacityFinderFilterType.TeamDrivers:
      return { ...filters, isTeamDrivers: undefined };
    case CapacityFinderFilterType.LTL:
      return { ...filters, loadSize: undefined };
    default:
      return filters;
  }
};

export const shouldConstantlyDisplayFilter = (filterType: CapacityFinderFilterType) =>
  filterType === CapacityFinderFilterType.PickupRadius ||
  filterType === CapacityFinderFilterType.DropoffRadius ||
  filterType === CapacityFinderFilterType.Age;

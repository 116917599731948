import { concat, filter, isEmpty, map, reduce, some } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { merge as merge$ } from 'rxjs';

import { UserSettingsClient } from '@/client/UserSettingsClient';
import {
  AlertsPayload,
  DispatcherDetail,
  DispatcherPayload,
  EmailNotificationsPayload,
  HotLeadsPayload,
  MessagesNotificationsPayload,
  ResetPasswordPayload,
  UserAddDetails,
  UserEditDetails,
  UserSettingsDetails,
  UsersSettingsPayload,
} from '@/model/UserManagement';
import { Api } from '@common/api';
import { createAction, createApiAction, ResultResponse } from '@common/redux/Base';
import { createMergedReducer } from '@common/redux/ReduxHelper';
import { editUsers, TypeUsers } from '@page/newSettings/panelSectionFilter/SettingsHelper';

interface SelectedFilter {
  selectedSettingUser: TypeUsers;
}

const postNotificationTokenAction = createApiAction<MessagesNotificationsPayload>('POST_NOTIFICATION_TOKEN');
const enableNotificationTokenAction = createApiAction<MessagesNotificationsPayload>('SAVE_NOTIFICATION_TOKEN');
const updateNotificationTokenAction = createApiAction<MessagesNotificationsPayload>('UPDATE_NOTIFICATION_TOKEN');

const addUserAction = createApiAction<UserAddDetails, UserAddDetails>('ADD_USER');
const fetchSettingUsers = createApiAction<{ id: string; offset: number }, UsersSettingsPayload>('FETCH_SETTING_USER');
const sortSettingUsersAction = createApiAction<SelectedFilter, undefined>('SORT_SETTING_USER');
const updateUserAction = createApiAction<{ id: number; userDetails: UserEditDetails }, UserEditDetails>('UPDATE_USER');
const fetchUserAction = createApiAction<{ id: string }, UserEditDetails>('FETCH_USER');
const deleteUserAction = createApiAction<{ guid: string }, undefined>('DELETE_USER');
const fetchHotLeads = createApiAction<undefined, HotLeadsPayload>('FETCH_HOT_LEADS');
const updateHotLeadsAction = createApiAction<HotLeadsPayload, HotLeadsPayload>('UPDATE_HOT_LEADS');
const fetchAlertsAction = createApiAction<undefined, AlertsPayload>('FETCH_ALERTS');
const updateAlertsAction = createApiAction<AlertsPayload, AlertsPayload>('UPDATE_ALERTS');
const fetchEmailNotificationsAction = createApiAction<undefined, EmailNotificationsPayload>(
  'FETCH_EMAIL_NOTIFICATIONS'
);
const updateEmailNotificationsAction = createApiAction<EmailNotificationsPayload, EmailNotificationsPayload>(
  'UPDATE_EMAIL_NOTIFICATIONS'
);
const resetPasswordWithEmailAction = createApiAction<{ email: string }, ResetPasswordPayload>('RESET_PASSWORD');
const setTabControlInitializationAction = createAction<{ isInitialized: boolean }>('SET_TAB_CONTROL_INITIALIZATION');
const setCurrentTabKeyAction = createAction<{ tabKey: string }>('SET_CURRENT_TAB_KEY');

export const postNotificationToken = (data: MessagesNotificationsPayload) =>
  postNotificationTokenAction.fetchAction(data);
export const enableNotificationToken = (data: MessagesNotificationsPayload) =>
  enableNotificationTokenAction.fetchAction(data);
export const updateNotificationToken = (data: MessagesNotificationsPayload) =>
  updateNotificationTokenAction.fetchAction(data);

export const getSettingUsers = (id: string, offset: number) =>
  fetchSettingUsers.fetchAction({ id: id, offset: offset });
export const getHotleads = () => fetchHotLeads.fetchAction(undefined);
export const updateHotLeads = (hotLeadsPayload: HotLeadsPayload) => updateHotLeadsAction.fetchAction(hotLeadsPayload);
export const addUser = (userDetails: UserAddDetails) => addUserAction.fetchAction(userDetails);
export const fetchAlerts = () => fetchAlertsAction.fetchAction(undefined);
export const updateAlerts = (data: AlertsPayload) => updateAlertsAction.fetchAction(data);
export const fetchEmailNotifications = () => fetchEmailNotificationsAction.fetchAction(undefined);
export const updateEmailNotifications = (data: EmailNotificationsPayload) =>
  updateEmailNotificationsAction.fetchAction(data);

export const updateUser = (id: number, userDetails: UserEditDetails) =>
  updateUserAction.fetchAction({ id: id, userDetails: userDetails });

export const fetchUser = (id: string) => fetchUserAction.fetchAction({ id: id });

export const deleteUser = (guid: string) => deleteUserAction.fetchAction({ guid: guid });

export const resetPasswordWithEmail = (data: { email: string }) => resetPasswordWithEmailAction.fetchAction(data);

export const sortSettingUsers = (selectedFilter: SelectedFilter) => sortSettingUsersAction.fetchAction(selectedFilter);

export const setTabControlInitialization = (isInitialized: boolean) =>
  setTabControlInitializationAction.action({ isInitialized: isInitialized });
export const setCurrentTabKey = (tabKey: string) => setCurrentTabKeyAction.action({ tabKey: tabKey });

export interface UsersSettingState {
  users: {
    usersPayload?: UsersSettingsPayload;
    isLoading: boolean;
    wasUpdatedSuccessfully?: boolean;
    offset: number;
  };
  dispatchers: {
    dispatcherPayload?: DispatcherPayload;
    isLoading: boolean;
    wasUpdatedSuccessfully?: boolean;
    offset: number;
    isAuthorized: boolean;
  };
  dispatcher: {
    selectedDispatcher?: DispatcherDetail;
    isLoading: boolean;
    wasSuccessful?: boolean;
  };
  hotLeadsState?: ResultResponse<HotLeadsPayload>;
  userState: {
    wasDeletedSuccesfully?: boolean;
    wasAddedSuccesfully?: boolean;
    wasUpdatedSuccessfully?: boolean;
    userWasFetchedSuccessfully: boolean;
    userPayload?: UserEditDetails;
    selectedUserId?: string;
    userWasDeleted?: boolean;
    isLoading: boolean;
  };
  notificationsState?: ResultResponse<EmailNotificationsPayload>;
  alertsState?: ResultResponse<AlertsPayload>;
  selectedUserType: TypeUsers;
  resetPasswordState?: ResultResponse<ResetPasswordPayload>;

  userNotificationToken: {
    tokenValue?: ResultResponse<MessagesNotificationsPayload>;
    wasSavedSuccessfully: boolean;
    wasEnabledSuccessfully: boolean;
  };

  openedTabsControl: {
    isOpenedTabsControlInitialized: boolean;
    currentTabKey?: string;
  };
}

export const initialState: UsersSettingState = {
  users: {
    isLoading: false,
    offset: 0,
  },
  dispatchers: {
    isLoading: false,
    offset: 1,
    isAuthorized: true,
  },
  dispatcher: {
    isLoading: false,
  },
  userState: {
    wasDeletedSuccesfully: undefined,
    wasAddedSuccesfully: undefined,
    wasUpdatedSuccessfully: false,
    userWasFetchedSuccessfully: false,
    userWasDeleted: undefined,
    isLoading: false,
  },
  selectedUserType: TypeUsers.All,

  userNotificationToken: {
    tokenValue: undefined,
    wasSavedSuccessfully: false,
    wasEnabledSuccessfully: false,
  },

  openedTabsControl: {
    isOpenedTabsControlInitialized: false,
    currentTabKey: '',
  },
};

interface Entity<T> {
  id: T;
}

const isRedundant = <T>(entities: Entity<T>[], newEntities: Entity<T>[]) =>
  isEmpty(filter(newEntities, (newEntity) => !some(entities, (entity) => entity.id === newEntity.id)));

const getUpdatedContacts = (contacts: UserSettingsDetails[], newContacts: UserSettingsDetails[]) =>
  reduce(
    newContacts,
    (resultContacts, newContact) =>
      map(resultContacts, (contact) => (contact.id === newContact.id ? newContact : contact)),
    contacts
  );

export const usersSettingsReducer = createMergedReducer(initialState, [
  fetchSettingUsers.initiateCase((state) => {
    state.users.isLoading = true;
  }),
  fetchSettingUsers.completeCase((state, action) => {
    state.users.isLoading = false;
    if (action.response.success) {
      state.users.wasUpdatedSuccessfully = true;
      const contacts = state.users.usersPayload?.contacts ?? [];
      const isRedundantResponse = isRedundant(contacts, action.response.payload.contacts);
      const newContacts = isRedundantResponse
        ? getUpdatedContacts(contacts, action.response.payload.contacts)
        : concat(contacts, action.response.payload.contacts);

      state.users.usersPayload = {
        ...action.response.payload,
        contacts: newContacts,
      };
      if (!isRedundantResponse) {
        state.users.offset = state.users.offset + action.response.payload.count;
      }
    } else {
      state.users.wasUpdatedSuccessfully = false;
    }
  }),

  sortSettingUsersAction.initiateCase((state, action) => {
    state.selectedUserType = action.data.selectedSettingUser;
  }),

  fetchHotLeads.initiateCase((state) => {
    state.users.isLoading = true;
  }),

  fetchHotLeads.completeCase((state, action) => {
    state.users.isLoading = false;
    if (action.response.success) {
      state.hotLeadsState = action.response;
    }
  }),

  updateHotLeadsAction.initiateCase((state) => {
    state.users.isLoading = true;
    if (state.hotLeadsState) {
      state.hotLeadsState.success = false;
    }
  }),

  updateHotLeadsAction.completeCase((state, action) => {
    state.users.isLoading = false;
    if (action.response.success) {
      state.hotLeadsState = action.response;
    }
  }),

  addUserAction.initiateCase((state) => {
    state.userState.wasAddedSuccesfully = false;
    state.users.isLoading = true;
  }),

  addUserAction.completeCase((state, action) => {
    state.users.isLoading = false;
    if (action.response.success) {
      state.userState.wasAddedSuccesfully = true;
    } else {
      state.userState.wasAddedSuccesfully = false;
    }
  }),

  fetchEmailNotificationsAction.completeCase((state, action) => {
    if (action.response.success) {
      state.notificationsState = action.response;
    }
  }),

  fetchAlertsAction.completeCase((state, action) => {
    if (action.response.success) {
      state.alertsState = action.response;
    }
  }),

  updateEmailNotificationsAction.initiateCase((state) => {
    state.users.isLoading = true;
    if (state.notificationsState) {
      state.notificationsState.success = false;
    }
  }),

  updateEmailNotificationsAction.completeCase((state, action) => {
    state.users.isLoading = false;
    if (action.response.success) {
      state.notificationsState = action.response;
    }
  }),

  updateEmailNotificationsAction.initiateCase((state) => {
    state.users.isLoading = true;
    if (state.alertsState) {
      state.alertsState.success = false;
    }
  }),

  updateAlertsAction.completeCase((state, action) => {
    state.users.isLoading = false;
    if (action.response.success) {
      state.alertsState = action.response;
    }
  }),

  updateUserAction.initiateCase((state) => {
    state.users.isLoading = true;
    state.userState.wasUpdatedSuccessfully = undefined;
  }),

  updateUserAction.completeCase((state, action) => {
    state.users.isLoading = false;
    if (action.response.success) {
      state.userState.wasUpdatedSuccessfully = true;
      state.users.usersPayload = state.users.usersPayload
        ? {
            ...state.users.usersPayload,
            contacts: editUsers(action.response.payload, state.users.usersPayload.contacts),
          }
        : undefined;
    } else {
      state.userState.wasUpdatedSuccessfully = false;
      if (action.response.error.httpStatus === 403) {
        state.userState.wasUpdatedSuccessfully = false;
      }
    }
  }),

  fetchUserAction.initiateCase((state, action) => {
    state.userState.userWasFetchedSuccessfully = false;
    state.userState.isLoading = true;
    state.userState.selectedUserId = action.data.id;
  }),

  fetchUserAction.completeCase((state, action) => {
    state.userState.isLoading = false;
    if (action.response.success) {
      state.userState.userWasFetchedSuccessfully = true;
      state.userState.userPayload = action.response.payload;
    } else {
      state.userState.userWasFetchedSuccessfully = false;
    }
  }),

  deleteUserAction.initiateCase((state) => {
    state.users.isLoading = true;
    state.userState.userWasDeleted = undefined;
  }),

  deleteUserAction.completeCase((state, action) => {
    state.users.isLoading = false;
    if (action.response.success) {
      state.userState.userWasDeleted = true;
    } else {
      state.userState.userWasDeleted = false;
    }
  }),

  resetPasswordWithEmailAction.completeCase((state, action) => {
    if (action.response) {
      state.resetPasswordState = action.response;
    }
  }),

  postNotificationTokenAction.initiateCase((state) => {
    state.userNotificationToken.tokenValue = undefined;
  }),

  postNotificationTokenAction.completeCase((state, action) => {
    if (action.response.success) {
      state.userNotificationToken.tokenValue = action.response.payload;
      state.userNotificationToken.wasSavedSuccessfully = true;
    } else {
      state.userNotificationToken.wasSavedSuccessfully = false;
    }
  }),

  enableNotificationTokenAction.initiateCase((state) => {
    state.userNotificationToken.wasEnabledSuccessfully = false;
  }),

  enableNotificationTokenAction.completeCase((state, action) => {
    if (action.response.success) {
      state.userNotificationToken.wasEnabledSuccessfully = true;
    } else {
      state.userNotificationToken.wasEnabledSuccessfully = false;
    }
  }),

  updateNotificationTokenAction.initiateCase((state) => {
    state.userNotificationToken.tokenValue = undefined;
  }),

  updateNotificationTokenAction.completeCase((state, action) => {
    if (action.response.success) {
      state.userNotificationToken.tokenValue = action.response.payload;
      state.userNotificationToken.wasSavedSuccessfully = true;
    } else {
      state.userNotificationToken.wasSavedSuccessfully = false;
    }
  }),

  setTabControlInitializationAction.addCase((state, action) => {
    state.openedTabsControl.isOpenedTabsControlInitialized = action.data.isInitialized;
  }),
  setCurrentTabKeyAction.addCase((state, action) => {
    state.openedTabsControl.currentTabKey = action.data.tabKey;
  }),
]);

export const createUserSettingsEpic = (api: Api) => {
  const client = new UserSettingsClient(api);
  return (action$: ActionsObservable<Action>) =>
    merge$(
      fetchSettingUsers.createEpic$(action$, (data) => client.getUsers$(data.id, data.offset)),
      fetchHotLeads.createEpic$(action$, client.getHotLeads$),
      fetchEmailNotificationsAction.createEpic$(action$, client.getEmailNotifications$),
      fetchAlertsAction.createEpic$(action$, client.getAlerts$),
      updateEmailNotificationsAction.createEpic$(action$, client.updateEmailNotifications$),
      updateAlertsAction.createEpic$(action$, client.updateAlerts$),
      updateHotLeadsAction.createEpic$(action$, client.updateHotLeads$),
      addUserAction.createEpic$(action$, client.addUser$),
      updateUserAction.createEpic$(action$, (data) => client.updateUser$(data.id, data.userDetails)),
      fetchUserAction.createEpic$(action$, (data) => client.getUser$(data.id)),
      deleteUserAction.createEpic$(action$, (data) => client.deleteUser$(data.guid)),
      resetPasswordWithEmailAction.createEpic$(action$, client.resetPasswordWithEmail$),
      postNotificationTokenAction.createEpic$(action$, client.postNotificationToken$),
      enableNotificationTokenAction.createEpic$(action$, client.saveNotificationToken$),
      updateNotificationTokenAction.createEpic$(action$, client.updateNotificationToken$)
    );
};

export interface RecentSearchLocationsResponse {
  locations?: RecentLocation[];
  metadata?: SavedLocationsResponseMetadata;
}

export enum RecentLocationType {
  None = 'None',
  City = 'City',
  ZipCode = 'ZipCode',
  States = 'States',
  Geolocation = 'Geolocation',
  All = 'All',
}

export interface RecentLocation {
  type?: RecentLocationType;
  address?: RecentAddress;
  geolocation?: Geolocation;
}

interface SavedLocationsResponseMetadata {
  totalResultCount?: number;
  queryTime?: number;
  pageResultCount?: number;
  currentOffset?: number;
}

interface RecentAddress {
  streetAddress?: string;
  city?: string;
  state?: string;
  states?: string[];
  zipCode?: string;
}

interface Geolocation {
  latitude?: number;
  longitude?: number;
}

import { filter, find, map, range, reduce, reverse } from 'lodash';
import memoizeOne from 'memoize-one';

import { formatAsServerDate } from '@common/helper';
import { CustomFoldersSort, DateRange, DocumentsSortingOption, DocumentType } from '@common/model';
import { PeriodDataCount } from '@common/redux/epic/DocumentListEpic';
import { t, T } from '@translate';

const DATE_RANGE_SORTING_OPTIONS = 'date_range_sorting_options';
export const ORIGIN_YEAR = 2019; // Controls which year the range filter start at

export enum ViewType {
  Folders = 'folders',
  Dates = 'dates',
  Types = 'types',
}

interface DateRangeSortOption {
  title: string;
  onPress: () => any;
  key: string;
  filesCount?: number;
}

type UpdateDateFilter = (dateRange: DateRange) => void;

interface DateRangeSortingParams {
  currentDate: Date;
  updateDateFilter: UpdateDateFilter;
  selectedDateTitle: string;
  originYear: number;
  periodDataCounts: PeriodDataCount[];
}

/**
 * Creates an array where the index is the month and the value is the quarter
 */
const quarters = map(range(12), (month: number) => {
  switch (true) {
    case month < 3:
      return 1;
    case month < 6:
      return 2;
    case month < 9:
      return 3;
    default:
      return 4;
  }
});

const getQuarterStart = (year: number, quarter: number): string =>
  [
    formatAsServerDate(year, 0, 1),
    formatAsServerDate(year, 3, 1),
    formatAsServerDate(year, 6, 1),
    formatAsServerDate(year, 9, 1),
  ][quarter];

const getQuarterEnd = (year: number, quarter: number): string =>
  [
    formatAsServerDate(year, 2, 31),
    formatAsServerDate(year, 5, 30),
    formatAsServerDate(year, 8, 30),
    formatAsServerDate(year, 11, 31),
  ][quarter];

const getFormattedLastMonthStart = (today: Date): string => {
  const { year, month } = getLastMonthStart(today);
  return formatAsServerDate(year, month, 1);
};

const getLastMonthStart = (today: Date) => {
  const year = today.getFullYear();
  const month = today.getMonth();
  if (month === 0) {
    return { month: 11, year: year - 1 };
  }
  return { month: month - 1, year: year };
};

const getLastMonthEnd = (today: Date): string => {
  const year = today.getFullYear();
  const month = today.getMonth();
  return formatAsServerDate(year, month, 1);
};

const getQuarterListItem =
  (year: number, updateDateFilter: UpdateDateFilter, periodDataCounts: PeriodDataCount[]) => (index: number) => {
    const quarter = index + 1;
    return {
      title: `Q${quarter} ${year}`,
      onPress: () =>
        updateDateFilter({
          title: `Q${quarter} ${year}`,
          dateStart: getQuarterStart(year, index),
          dateEnd: getQuarterEnd(year, index),
        }),
      filesCount: reduce(
        periodDataCounts,
        (count, periodDataCount) => {
          if (periodDataCount.year === undefined || periodDataCount.month === undefined) {
            return count;
          }
          let doesMonthBelongToQuarter = false;
          switch (index) {
            case 0:
              doesMonthBelongToQuarter = periodDataCount.month >= 1 && periodDataCount.month < 4;
              break;
            case 1:
              doesMonthBelongToQuarter = periodDataCount.month >= 4 && periodDataCount.month < 7;
              break;
            case 2:
              doesMonthBelongToQuarter = periodDataCount.month >= 7 && periodDataCount.month < 10;
              break;
            case 3:
              doesMonthBelongToQuarter = periodDataCount.month >= 10 && periodDataCount.month <= 12;
          }
          return periodDataCount.year === year && doesMonthBelongToQuarter
            ? count + (periodDataCount.count ?? 0)
            : count;
        },
        0
      ),
      key: `${DATE_RANGE_SORTING_OPTIONS}_q${quarter}`,
    };
  };

const getYearListItem =
  (originYear: number, updateDateFilter: UpdateDateFilter, periodDataCounts: PeriodDataCount[]) => (index: number) => {
    const year = originYear + index;
    return {
      title: `${year}`,
      onPress: () =>
        updateDateFilter({
          title: `${year}`,
          dateStart: formatAsServerDate(year, 0, 1),
          dateEnd: formatAsServerDate(year, 11, 31),
        }),
      filesCount: reduce(
        periodDataCounts,
        (count, periodDataCount) => (periodDataCount.year === year ? count + (periodDataCount.count ?? 0) : count),
        0
      ),
      key: `${DATE_RANGE_SORTING_OPTIONS}_y${year}`,
    };
  };

export const generateDateRangeSortingOptions = ({
  currentDate,
  updateDateFilter,
  selectedDateTitle,
  originYear,
  periodDataCounts,
}: DateRangeSortingParams): DateRangeSortOption[] => {
  const currentYear = currentDate.getFullYear();
  const currentQuarter = quarters[currentDate.getMonth()];
  // TODO: remove the filter in year 2020. In 2019 we don't want to show Q1 and Q2, after that the filter is redundant
  const quartersToDisplay = filter(
    map(reverse(range(currentQuarter)), getQuarterListItem(currentYear, updateDateFilter, periodDataCounts)),
    (option: DateRangeSortOption) => option.title !== 'Q2 2019' && option.title !== 'Q1 2019'
  );
  const yearsToDisplay = map(
    reverse(range(currentYear - originYear)),
    getYearListItem(originYear, updateDateFilter, periodDataCounts)
  );
  const defaultSortingOptions = [
    {
      title: t(T.common_dates_thisMonth),
      onPress: () =>
        updateDateFilter({
          title: t(T.common_dates_thisMonth),
          dateStart: formatAsServerDate(currentDate.getFullYear(), currentDate.getMonth(), 1),
          dateEnd: formatAsServerDate(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
        }),
      filesCount:
        find(
          periodDataCounts,
          (periodData) =>
            periodData.year === currentDate.getFullYear() && periodData.month === currentDate.getMonth() + 1
        )?.count ?? 0,
      key: 'this_month',
    },
    {
      title: t(T.common_dates_lastMonth),
      onPress: () =>
        updateDateFilter({
          title: t(T.common_dates_lastMonth),
          dateStart: getFormattedLastMonthStart(currentDate),
          dateEnd: getLastMonthEnd(currentDate),
        }),
      filesCount:
        find(periodDataCounts, (periodData) => {
          const { year, month } = getLastMonthStart(currentDate);
          return periodData.year === year && periodData.month === month + 1;
        })?.count ?? 0,
      key: 'last_month',
    },
  ];
  const older = {
    title: t(T.common_dates_older),
    onPress: () =>
      updateDateFilter({
        title: t(T.common_dates_older),
        dateStart: formatAsServerDate(2012, 0, 0),
        dateEnd: formatAsServerDate(originYear, 6, 0),
      }),
    filesCount: reduce(
      periodDataCounts,
      (count, periodDataCount) =>
        (periodDataCount.year !== undefined && periodDataCount.year >= 2012 && periodDataCount.year < originYear) ||
        (periodDataCount.month !== undefined && periodDataCount.year === originYear && periodDataCount.month - 1 < 6)
          ? count + (periodDataCount.count ?? 0)
          : count,
      0
    ),
    key: 'older',
  };
  return map([...defaultSortingOptions, ...quartersToDisplay, ...yearsToDisplay, older], (option) => ({
    ...option,
    isSelected: option.title === selectedDateTitle,
  }));
};

export const VIEW_OPTIONS = [
  {
    id: ViewType.Folders,
    key: ViewType.Folders,
    value: ViewType.Folders,
    displayValue: t(T.common_myDocuments_viewTypeOptions_folders),
  },

  {
    id: ViewType.Dates,
    key: ViewType.Dates,
    value: ViewType.Dates,
    displayValue: t(T.common_myDocuments_viewTypeOptions_dates),
  },
  {
    id: ViewType.Types,
    key: ViewType.Types,
    value: ViewType.Types,
    displayValue: t(T.common_myDocuments_viewTypeOptions_types),
  },
];

export const displayValueFromViewType = (viewType: ViewType) =>
  find(VIEW_OPTIONS, (option) => option.value === viewType)?.displayValue;

export const displayNameOfDocumentType = memoizeOne(
  (documentType: string, types: DocumentType[]) =>
    find(types, (docType) => docType.enumName === documentType)?.description
);

export const formatFolderSortOption = (sortOption: CustomFoldersSort | undefined) => {
  switch (sortOption) {
    case CustomFoldersSort.LastAccessedOn:
      return t(T.common_myDocuments_selectFolder_sort_lastAccessedOn);
    case CustomFoldersSort.Name:
      return t(T.common_myDocuments_selectFolder_sort_name);
    default:
      return t(T.common_myDocuments_selectFolder_sort_hasLoad);
  }
};

export const documentsSortingOptionToString = (option: DocumentsSortingOption): string => {
  switch (option) {
    case DocumentsSortingOption.date:
      return t(T.common_myDocuments_date);
    case DocumentsSortingOption.alphabetical:
      return t(T.common_myDocuments_alphabetical);
    case DocumentsSortingOption.type:
      return t(T.common_myDocuments_type);
    case DocumentsSortingOption.folder:
      return t(T.common_myDocuments_folder);
    default:
      return '';
  }
};

import { gt, map, toLower, union } from 'lodash';

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortCategory<T> {
  category: T;
  order: SortOrder;
}

export function direction(sortAsc: boolean): string {
  if (sortAsc) {
    return SortOrder.ASC;
  } else {
    return SortOrder.DESC;
  }
}

export abstract class Sort<T, U> {
  abstract baseSortCategories: U[];

  sort = (list: T[], isSortAsc: boolean, category: U) => {
    const sortCategories: Array<SortCategory<U>> = map(union([category], this.baseSortCategories), (categoryType) => ({
      category: categoryType,
      order: isSortAsc ? SortOrder.ASC : SortOrder.DESC,
    }));
    return this.sortMultiple(list, sortCategories);
  };

  compare = (sortingCategories: Array<SortCategory<U>>, value1: T, value2: T): number => {
    for (const category of sortingCategories) {
      const result = this.compareValue(value1, value2, category.category, category.order === SortOrder.ASC);
      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };

  sortMultiple = (list: T[], categories: Array<SortCategory<U>>) => {
    const sortedList = [...list].sort((obj1: T, obj2: T) => {
      return this.compare(categories, obj1, obj2);
    });
    return sortedList;
  };

  // Can't be an arrow function due to JS restrictions (defaults to prototype)
  protected compareValue(obj1: T, obj2: T, category: U, isSortAsc: boolean) {
    if (this.categoryIsNumber(category)) {
      const value1 = this.normalizeNumberValue(this.valueForCategory(category, obj1, isSortAsc) as number, isSortAsc);
      const value2 = this.normalizeNumberValue(this.valueForCategory(category, obj2, isSortAsc) as number, isSortAsc);
      return isSortAsc ? value1 - value2 : value2 - value1;
    } else {
      const value1 = this.normalizeStringValue(this.valueForCategory(category, obj1, isSortAsc) as string);
      const value2 = this.normalizeStringValue(this.valueForCategory(category, obj2, isSortAsc) as string);

      if (value1 === value2) {
        return 0;
      }
      const cmp = gt(value1, value2) ? 1 : -1;

      return isSortAsc ? cmp : -cmp;
    }
  }

  /** This only works for number types */
  protected handleUndefinedReturn = (isSortAsc: boolean) => {
    if (isSortAsc) {
      return Number.MAX_SAFE_INTEGER;
    } else {
      return Number.MIN_SAFE_INTEGER;
    }
  };

  protected normalizeNumberValue = (value: number | undefined, isSortAsc: boolean) => {
    return value === undefined ? this.handleUndefinedReturn(isSortAsc) : value;
  };

  protected normalizeStringValue = (value: string | undefined) => {
    // lodash's toLower() converts undefined values to empty strings
    return toLower(value);
  };

  protected abstract valueForCategory: (category: U, value: T, sortAsc: boolean) => string | number | undefined;
  protected abstract categoryIsNumber: (category: U) => boolean;
}

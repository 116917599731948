import { MessageType } from '@common/model/Inbox';
import { t, T } from '@translate';

export const getTagTitle = (type: MessageType | undefined): string => {
  switch (type) {
    case MessageType.Announcement:
      return t(T.common_announcements_announcement);
    case MessageType.Notice:
      return t(T.common_announcements_notice);
    case MessageType.ServiceRelated:
      return t(T.common_announcements_serviceRelated);
    case MessageType.Promotion:
      return t(T.common_announcements_promotion);
    case MessageType.Newsletter:
      return t(T.common_announcements_newsletter);
    default:
      return t(T.common_announcements_notice);
  }
};

import React from 'react';

import { generatePath } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';

import { history } from '@/reduxStore/History';
import {
  isAccountSetupRequiredForBillingStatus,
  isAccountSetupRequiredForMembershipStatus,
} from '@/router/RouteHelper';
import { Routes } from '@/router/Routes';
import { Flag, MembershipStatus } from '@common/model';
import { useIsCarrier } from '@component/menu/NavigationMenuHelper';
import { T, t } from '@translate';
import { useSelector } from '@util/hooks';
import { PageMetadata } from '@util/Metadata';
import { decodeMatchParams } from '@util/UrlParamsHelper';

import { mapReactUrlToAspxUrl } from './AspxMapper';
import { AspxPage } from './AspxPage';

const titleSuffixRemovalRegex = /^(.*?)\s*[|]\s*123Loadboard.*/;
const paymentOption = /payment-options/;
const paymentHistory = /payment-history/;
const membershipProfile = /billing/;

/** Will display an AspxPage OR the normal React application (children) depending on current location.
 * This component was necessary to avoid iframe-reloading issues happening with React-Router if
 * normal "Route" objects were used instead.
 */

export const AspxSwitcher: React.FC = ({ children }) => {
  const location = useLocation();
  const isCarrier = useIsCarrier();
  const aspxMap = useSelector((state) => mapReactUrlToAspxUrl(location, state));
  const locationMatch = decodeMatchParams<{ modal: string; subroutes: string }>(
    history.location.pathname,
    Routes.MORE_SETTINGS_ACCOUNT_SETUP_NEW
  );

  const { isMembershipDataFetched, membershipError, membershipData } = useSelector((state) => state.userPlan);
  const profile = useSelector((state) => state.user.profile);
  const currentRoute = history.location.pathname;
  const isLoadPlannerEnabled = useSelector((state) => state.settings.systemSetting[Flag.LoadPlannerEnabled].value);

  if (!aspxMap) {
    // NO ASPX ROUTE MATCHED:
    // Proceed with displaying the normal app
    return <>{children}</>;
  }

  if (isMembershipDataFetched && membershipData?.status && !membershipError) {
    const shouldUserChangeMembershipPlan = isAccountSetupRequiredForMembershipStatus(membershipData.status);
    if (shouldUserChangeMembershipPlan && !locationMatch?.isExact) {
      return <Redirect to={generatePath(Routes.MORE_SETTINGS_ACCOUNT_SETUP_NEW)} />;
    }
  }

  if (!isLoadPlannerEnabled && currentRoute.startsWith(Routes.LOADS_LOAD_PLANNER)) {
    return <Redirect to={Routes.TRIP_BUILDER_BASE_URL} />;
  }

  if (
    profile?.success &&
    isMembershipDataFetched &&
    !membershipError &&
    isAccountSetupRequiredForBillingStatus(profile.payload?.billingProfileStatus, membershipData?.isCancelling) &&
    !locationMatch?.isExact
  ) {
    return <Redirect to={generatePath(Routes.MORE_SETTINGS_ACCOUNT_SETUP_NEW)} />;
  }

  if (
    !isCarrier &&
    isMembershipDataFetched &&
    membershipData?.status === MembershipStatus.Potential &&
    !membershipError
  ) {
    return <Redirect to={Routes.MORE_SETTINGS_ACCOUNT_VERIFICATION} />;
  }

  const { aspxUrl, pageTitle: defaultPageTitle } = aspxMap;

  let pageTitle;
  let aspxPage = false;
  let label = '';
  let onBack;

  if (location.state && location.state.aspxPageTitle) {
    pageTitle = location.state.aspxPageTitle;

    const match = titleSuffixRemovalRegex.exec(pageTitle);

    if (paymentOption.exec(aspxUrl)) {
      aspxPage = true;
      label = t(T.settings_billing_paymentMethod);
      onBack = () => {
        history.push({ pathname: generatePath(Routes.MORE_SETTINGS_ACCOUNT_SETUP) });
      };
    } else if (paymentHistory.exec(aspxUrl)) {
      aspxPage = true;
      label = t(T.settings_billing_paymentHistory);
    } else if (membershipProfile.exec(aspxUrl)) {
      aspxPage = true;
      label = t(T.common_membership_managePlan);
    }
    if (aspxPage) {
      pageTitle = label;
    } else if (match) {
      pageTitle = match[1];
    }
  } else {
    pageTitle = defaultPageTitle;
  }

  return (
    <>
      <PageMetadata title={pageTitle} />
      <AspxPage iframeSrc={aspxUrl} newSettings={aspxPage} label={label} onBack={onBack} />
    </>
  );
};
